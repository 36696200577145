/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
let gfaPanzoom = null;
let satPanzoom = null;
let progPanzoom = null;

function resetPanZoom(pz) {
  if (pz != null) {
    pz.moveTo(0, 0);
    pz.zoomAbs(0, 0, 1);
  }
}

function init_GFA() {
  $.mobile.changePage($('#GFA_Page'), {
    changeHash: false,
  });

  $('#exitGFApage').unbind('click', exitGFApage).click(exitGFApage);

  $('#exitGFAImagepage').unbind('click', exitGFAImagepage).click(exitGFAImagepage);

  $('#GFApopBTN').click(enhanceGFA);

  $('#GFAMAP').html('<img src="images/gfa-couleur-e.png" usemap="#Map" style="max-height:100%; max-width:100%">');

  if (gfaPanzoom == null) {
    $('map').imageMapResize();
    gfaPanzoom = panzoom(document.getElementById('gfa-content-panzoom-parent'), {
      maxZoom: 2,
      minZoom: 1,
      smoothScroll: false,
    });
  }
}

function htmlButtonsFromSpec(spec, type = 'application/pdf') {
  let html = '';
  for (const short in spec) {
    if (!spec.hasOwnProperty(short)) {
      continue;
    }

    const long = spec[short];

    html += `<button onClick="load_eCFS('${short}', '${type}');" class="ui-link ui-btn ui-shadow ui-corner-all" role="button" data-role="button">${long}</button>`;
  }

  return html;
}

function eCFSgenerateContent() {
  $('#eCFScontent').html(
    htmlButtonsFromSpec({
      'e-CFS-WAS-en.pdf': 'WATER AERODROME SUPPLEMENT',
      'e-CFS-WAS-fr.pdf': 'SUPPLÉMENT HYDROAÉRODROMES',
      'eCFS1_.pdf': 'Yukon, NWT, and Nunavut',
      'eCFS2_.pdf': 'BC',
      'eCFS3_.pdf': 'Alberta, Saskatchewan, Manitoba',
      'eCFS4_.pdf': 'Ontario',
      'eCFS5_.pdf': 'Quebec [English]',
      'eCFS6_.pdf': 'Quebec [French]',
      'eCFS7_.pdf': 'Atlantic',
    })
  );
}

function vtaBacksgenerateContent() {
  //https://static.air-suite.com/av/Legends/
  $('#vtaBackscontent').html(
    htmlButtonsFromSpec(
      {
        '1900_Toronto_VTA_Back.png': 'Toronto VTA Back',
        '1901_Vancouver_VTA_Back.png': 'Vancouver VTA Back',
        '1902_Winnipeg_VTA_Back.png': 'Winnipeg VTA Back',
        '1904_Calgary_VTA_Back.png': 'Calgary VTA Back',
        '1906_Edmonton_VTA_Back.png': 'Edmonton VTA Back',
      },
      'image/png'
    )
  );
}

function CAPgenerateContent() {
  $('#CAPcontent').html(
    htmlButtonsFromSpec({
      'CAP_1.pdf': 'CAP 1: Yukon, NWT, NUN',
      'CAP_2.pdf': 'CAP 2: British Columbia',
      'CAP_3.pdf': 'CAP 3: Alta, Sask, Man',
      'CAP_4.pdf': 'CAP 4: Ontario',
      'CAP_5.pdf': 'CAP 5: Quebec (english)',
      'CAP_6.pdf': 'CAP 6: Quebec (french)',
      'CAP_7.pdf': 'CAP 7: Atlantic',
      'CAP_8.pdf': 'CAP GEN: General Pages',
      'CAP_Changes_Page.pdf': 'Change Pages',
    })
  );
}

function exitGFApage() {
  $.mobile.changePage($('#Planning_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function exitGFAImagepage() {
  $.mobile.changePage($('#GFA_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function getGFA(GFA) {
  console.log(GFA);
  $('#GFA_Content').html('<h1>Retrieving Data...</h1>');
  $.mobile.changePage($('#GFA_Image_Page'), {
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Planning-AJAX.php?action=getGFA&region=' + encodeURIComponent(GFA), function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#GFA_Content').html('<h1>Offline Cannot Retrieve File</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'getGFA');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
    } else {
      console.log('Received GFA Data');
      GFA_Pop(json_data);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#GFA_Content').html('<h1>Connection Error!</h1>');
  });

  resetPanZoom(gfaPanzoom);
}

function GFA_Pop(data) {
  console.log(data);
  var CW = '<p><b>Clouds & Weather<b></p><fieldset data-role="controlgroup" data-type="vertical" data-mini="true">';
  var IT =
    '<p><b>Icing, Turbulence & Freezing level<b></p><fieldset data-role="controlgroup" data-type="vertical" data-mini="true">';
  var type = $('input:radio[name=GFAtype]:checked').val();
  for (var i in data) {
    var I = data[i];
    switch (I.Type) {
      case 'CW':
        if (i == 0 && type == 'CW') {
          CW += '<input type="radio" name="GFAsel" value="' + I.URL + '" id="GFA' + i + '" checked="checked">';
          $('#GFA_Content').html("<img id='GFAimg' src='" + I.URL + "'  width='758' height='608'/>");
        } else {
          CW += '<input type="radio" name="GFAsel" value="' + I.URL + '" id="GFA' + i + '" >';
        }
        CW += '<label for="GFA' + i + '">' + I.Title + '</label>';
        break;
      case 'IT':
        if (i == 3 && type == 'IT') {
          IT += '<input type="radio" name="GFAsel" value="' + I.URL + '" checked="checked" id="GFA' + i + '" >';
          $('#GFA_Content').html("<img id='GFAimg' src='" + I.URL + "'   width='758' height='608' />");
        } else {
          IT += '<input type="radio" name="GFAsel" value="' + I.URL + '" id="GFA' + i + '" >';
        }
        IT += '<label for="GFA' + i + '">' + I.Title + '</label>';
        break;
    }
  }
  CW += '</fieldset>';
  IT += '</fieldset>';

  var html = CW + IT;
  $('#GFApopup_Content').html(html);
  $('#GFApopup').trigger('create');
  for (i in data) {
    $('#GFA' + i).click(setGFA);
  }
}

function enhanceGFA() {
  $('#GFApopup_Content').enhanceWithin();
}

function setGFA() {
  $('#GFApopup').popup('close');
  var URL = this.value;
  $('#GFA_Content').html(`<img id="GFAimg" src="${URL}" width="758" height="608" />`);
}

function init_SAT() {
  $.mobile.changePage($('#SAT_Page'), {
    changeHash: false,
  });

  $('#exitSATpage').unbind('click', exitSATpage).click(exitSATpage);

  $('#exitSATImagepage').unbind('click', exitSATImagepage).click(exitSATImagepage);

  $('#SATpopBTN').click(enhanceSAT);

  $('#SATani').unbind('click', SATani).click(SATani);

  if (satPanzoom == null) {
    satPanzoom = panzoom(document.getElementById('sat-content-panzoom-parent'), {
      maxZoom: 2,
      minZoom: 1,
      smoothScroll: false,
    });
  }
}

function exitSATpage() {
  $.mobile.changePage($('#Planning_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function exitSATImagepage() {
  $.mobile.changePage($('#SAT_Page'), {
    reverse: true,
    changeHash: false,
  });
}

var SATtype;
var SATarea;
var SATanimate = false;

function getSAT(SAT) {
  switch(SAT){
    case 'ecan':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Eastern Canada');
      break;
      case 'wcan':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Western Canada');
      break;
    case 'nwc':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('North Western Canada');
      break;
    case 'hur':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Great Lakes');
      break;
    case 'bc2':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('BC Interior');
      break;
    case 'na0':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('North America');
      break;
    case 'wna':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Western North America');
      break;
    case 'ena':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Eastern North America');
      break;
    case 'azo':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Azores');
      break;
    case 'epa':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Eastern Pacific');
      break;
    case 'arccom':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('Arctic Composite');
      break;
    case 'higharctic':
      document.getElementById('GFA_Header_Title2').innerHTML = iTrans('High Arctic');
      break;
  }


  SATanimate = false;
  console.log(SAT);
  SATarea = SAT;
  SATtype = $('input:radio[name=SATtype]:checked').val();
  $('#SAT_Content').html('<h1>Retrieving Data...</h1>');
  $.mobile.changePage($('#SAT_Image_Page'), {
    changeHash: false,
  });
  $.getJSON(
    BASEURL +
      'menu-Planning-AJAX.php?action=getCAsatellite&type=' +
      encodeURIComponent(SATtype) +
      '&area=' +
      encodeURIComponent(SATarea),
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#SAT_Content').html('<h1>Offline Cannot Retrieve File</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'getSAT');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      } else {
        console.log('Received open itinerary Data');
        SAT_Pop(json_data[0]);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#SAT_Content').html('<h1>Connection Error!</h1>');
  });

  resetPanZoom(gfaPanzoom);
}

function enhanceSAT() {
  $('#SATpopup_Content').enhanceWithin();
}

function updateSatBTN_State(e){
  switch(e.value){
    case 'ir':
      $('#SAThur').prop('disabled',true);
      $('#SATbc2').prop('disabled',true);
      $('#SATNA0').prop('disabled',true);
      $('#SATep').prop('disabled',false);
      $('#SATarccom').prop('disabled',false);
      $('#SAThigharctic').prop('disabled',false);
      break;
    case 'vis':
      $('#SAThur').prop('disabled',false);
      $('#SATbc2').prop('disabled',false);
      $('#SATNA0').prop('disabled',false);
      $('#SATep').prop('disabled',true);
      $('#SATarccom').prop('disabled',true);
      $('#SAThigharctic').prop('disabled',true);
      break;
  }
}
function SAT_Pop(data) {
  $('#SAT_Content').html('<img src="' + data.URL + '" width="800" height="800" />');
  // Reset Panzoom?
}

function SATani() {
  if (SATanimate) {
    getSAT(SATarea);
  } else {
    SATanimate = true;
    $('#SAT_Content').html('<img src="images/loading4.gif" width="800" height="800" />');
    $.ajaxq('cam', {
      timeout: 60000,
      url:
        BASEURL +
        'images/SatCA/' +
        'getSat.php?type=' +
        encodeURIComponent(SATtype) +
        '&area=' +
        encodeURIComponent(SATarea),
      data: {},
      success: function (data) {
        $('#SAT_Content').html(
          '<img src="images/SatCA/Img' +
            encodeURIComponent(SATtype) +
            encodeURIComponent(SATarea) +
            '.gif" width="800" height="800" />'
        );
      },
    }).error(function () {
      alert('Error Loading Satellite Image');
    });
  }
}

function init_PROG() {
  $.mobile.changePage($('#PROG_Page'), {
    changeHash: false,
  });
  $('#exitPROGpage').unbind('click', exitGFApage);
  $('#exitPROGpage').click(exitGFApage);
  if (progPanzoom == null) {
    progPanzoom = panzoom(document.getElementById('prog-content-panzoom-parent'), {
      maxZoom: 2,
      minZoom: 1,
      smoothScroll: false,
    });
  }
  getPROGimage();
}

function exitPROGpage() {
  $.mobile.changePage($('#Planning_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function getPROGimage() {
  $('#PROG_Content').html('<h1>Retrieving Data...</h1>');
  var time = $('input:radio[name=PROGtype]:checked').val();
  var url;
  switch (time) {
    case '0':
      url = 'http://www.aviationweather.gov/data/products/progs/F000_wpc_sfc.gif';
      break;
    case '1':
      url = 'http://www.aviationweather.gov/data/products/progs/F006_wpc_prog.gif';
      break;
    case '2':
      url = 'http://www.aviationweather.gov/data/products/progs/F012_wpc_prog.gif';
      break;
    case '3':
      url = 'http://www.aviationweather.gov/data/products/progs/F018_wpc_prog.gif';
      break;
    case '4':
      url = 'http://www.aviationweather.gov/data/products/progs/F024_wpc_prog.gif';
      break;
    case '5':
      url = 'http://www.aviationweather.gov/data/products/progs/F030_wpc_prog.gif';
      break;
    case '6':
      url = 'http://www.aviationweather.gov/data/products/progs/F036_wpc_prog.gif';
      break;
    case '7':
      url = 'http://www.aviationweather.gov/data/products/progs/F048_wpc_prog.gif';
      break;
  }
  $('#PROG_Content').html('<img src="' + url + '" width="640" height="480" />');
  resetPanZoom(progPanzoom);
}

function CA_CFS_GEN() {
  var html =
    '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>CFS - GENERAL PAGES - SECTIONS(ENGLISH)</h5><ul id="cfsEnglish1" data-role="listview" > <li style="white-space:normal; font-size: small;">';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN\')">CFS - GENERAL PAGES (All Sections PDF~10MB)</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN1\')">Table of Contents</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN2\')">Special Notices</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN3\')">General Section</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN4\')">Planning</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN5\')">Radio Nav & Comm</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN6\')">Military Data & Procedures</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN7\')">EMERGENCY</button>';
  html += '</li></ul></div>';
  html +=
    '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>CFS - PAGES GÉNÉRALES - SECTIONS(FRENCH)</h5><ul id="cfsFrench1" data-role="listview" > <li style="white-space:normal; font-size: small;">';
  html +=
    '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR\')">CFS - PAGES GÉNÉRALES (All Sections PDF~10MB)</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR1\')">Table des matières</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR2\')">AVIS SPÉCIAUX</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR3\')">SECTION GÉNÉRALE</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR4\')">PLANIFICATION</button>';
  html +=
    '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR5\')">AIDES DE RADIONAVIGATION ET DE COMMUNICATIONS</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR6\')">DONNÉES ET PROCÉDURES DE VOL MILITAIRE</button>';
  html += '<button onClick="loadOfflineCFSGEN(\'e-CFS_GEN_FR7\')">URGENCE</button>';
  html += '</li></ul></div>';
  $('#CFSBTNhldr').html(html);
  $('#CFSBTNhldr').enhanceWithin();
}

function US_DCS_GEN() {
  var html =
    '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>USA Digital Chart Supplements</h5><ul id="cfsEnglish1" data-role="listview" > <li style="white-space:normal; font-size: small;">';

  html += '<button onClick="loadOfflineDCSGEN(\'AK_front\')">Alaska Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'AK_rear\')">Alaska Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'EC_front\')">East Central Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'EC_rear\')">East Central Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'NC_front\')">North Central Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'NC_rear\')">North Central Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'NE_front\')">Northeast Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'NE_rear\')">Northeast Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'NW_front\')">Northwest Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'NW_rear\')">Northwest Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'PAC_front\')">Pacific Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'PAC_rear\')">Pacific Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'SC_front\')">South Central Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'SC_rear\')">South Central Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'SE_front\')">Southeast Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'SE_rear\')">Southeast Rear</button>';

  html += '<button onClick="loadOfflineDCSGEN(\'SW_front\')">Southwest Front</button>';
  html += '<button onClick="loadOfflineDCSGEN(\'SW_rear\')">Southwest Rear</button>';

  html += '</li></ul></div>';
  $('#DCSBTNhldr').html(html);
  $('#DCSBTNhldr').enhanceWithin();
}

function load_eCFS(fileName, type) {
  $fileUrl = `${MBTILE_URL}/${fileName}`;
  console.log('loading eCFS from', $fileUrl);
  if (!ONLINE) {
    window.toaster.show(iTrans('Cannot Download e-CFS When Offline.'), 5);
    return;
  }

  if (CORDOVA) {
    Downloader_getBigFile(fileName, $fileUrl, type);
    $(window).scrollTop(0);
    return;
  }

  window.open($fileUrl, '_blank');
}

function loadOfflineCFSGEN(pdf) {
  console.log(pdf);
  if (ONLINE) {
    var URL = AVDATA_URL + '/CFS/' + encodeURIComponent(pdf) + '.pdf';
    if (IOS) {
      //PGdownloadFile(URL);
      Downloader_getBigFile(pdf + '.pdf', URL, 'application/pdf');
      $(window).scrollTop(0);
    } else if (ANDROID) {
      //cordova.InAppBrowser.open(URL, '_system', 'location=yes');
      Downloader_getBigFile(pdf + '.pdf', URL, 'application/pdf');
      $(window).scrollTop(0);
    } else {
      window.open(URL, '_blank');
    }
  } else {
    LoadMobolizeFile(pdf, null, 'CA', 'application/pdf');
  }
}

function loadOfflineDCSGEN(pdf) {
  console.log(pdf);
  if (ONLINE) {
    var URL = AVDATA_URL + '/US_PDF/' + encodeURIComponent(pdf) + '.pdf';
    if (IOS) {
      PGdownloadFile(URL);
    } else if (ANDROID) {
      cordova.InAppBrowser.open(URL, '_system', 'location=yes');
    } else {
      window.open(URL, '_blank');
    }
  } else {
    LoadMobolizeFile(pdf, pdf, 'US', 'application/pdf');
  }
}
