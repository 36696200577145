/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var Report_Data;
WELLFORMED_CSV_COLUMNS = -1;
var ADMIN_GROUP_AC_TYPE_DATA = {};
function toEnterpriseReportsPage() {
  VueBridge.enterEnterpriseReports('Reports_Page');
}
function Populate_GROUP_AC_TYPE_DATA() {
  cirroDB.query('AC', 'ident != ?', '', function (aircraft) {
    ADMIN_GROUP_AC_TYPE_DATA = {};
    for (let i in aircraft) {
      let I = aircraft[i].AC_data;
      if (I.AcType == 'Bell 206L4T') {
        I.List_Type = 'Bell 206L4T Series';
      } else if (I.List_Type == 'Bell 206 Series' || I.List_Type == 'Bell 206L Series') {
        I.List_Type = 'Bell 206/206L Series';
      } else if (
        (I.AcType == 'King Air 300' || I.AcType == 'King Air B300' || I.AcType == 'King Air B200') &&
        COUNTRY == 'UNITED STATES'
      ) {
        I.List_Type = I.AcType + ' Series';
      } else if ((GROUP_DATA.group == 'transportcanada' || GPS_DEBUG) && I.AcType == 'Cessna Model 550') {
        I.List_Type = 'Cessna CJ 550 Series';
      } else if ((GROUP_DATA.group == 'transportcanada' || GPS_DEBUG) && I.AcType == 'King Air C90') {
        I.List_Type = 'King Air C90 Series';
      }
      ADMIN_GROUP_AC_TYPE_DATA[I.List_Type] = I.List_Type;
    }
  });
}
function init_Report(Report, queryVar) {
  var query = queryVar || '';
  $.mobile.changePage($('#Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $('#Report_Content').html("<h3 class='limit-table'>" + iTrans('Retrieving Data (this may take awhile)') + '...</h3>');
  $('#Report_Controls').html('');
  SUMMARY_ON = false;
  if (Report == 'Report_Logs') {
    var FromDate = new Date();
    FromDate.setMonth(FromDate.getMonth() - 1);
    var ToDate = new Date();
    FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
    ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
    query = '&FromDate=' + FromDate + '&ToDate=' + ToDate;
  }
  if (Report == 'Report_PaxManifesting') {
    query = '&Lastname=&Firstname=';
  }

  $.ajaxSetup({
    timeout: 6000000,
  });
  $.getJSON(BASEURL + 'menu-Administration-Reports-AJAX.php?report=' + Report + query, function (json_data) {
    $.ajaxSetup({
      timeout: 120000,
    });
    var GotRecords = true;
    if (json_data[0].ServerResponse == 'No Records') {
      $('#Report_Content').html('<h1>' + iTrans('No Records') + '</h1>');
      GotRecords = false;
    }
    Report_Data = json_data;
    console.log(Report_Data);
    var Controls = '';
    switch (Report) {
      case 'Report_PaxManifesting':
        $('#Title_Report_Name').html(iTrans('Passenger Manifesting Reports'));
        Controls += '<div class="ui-grid-a  noprint" id="RPT_TDG_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" /></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date') +
          '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls += '<div class="ui-grid-a" id="RPT_TDG_Filter2">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_PaxLastname">' +
          iTrans('Lastname') +
          '</label><input type="text" id="RPT_PaxLastName" data-mini="true" /></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_PaxFirstname">' +
          iTrans('Firstname') +
          '</label><input type="text" id="RPT_PaxFirstname" data-mini="true" /></div>';
        Controls +=
          '<button class="greenbg" data-mini="true" onClick="update_Pax_Data();">' +
          iTrans('Update Results') +
          '</button></div>';

        $('#Report_Controls').html(Controls);
        var fromdate = new Date();
        fromdate.setDate(fromdate.getDate() - 30);
        //document.getElementById('RPT_FromDate').valueAsDate = fromdate;
        $('#RPT_FromDate').val(fromdate.toISOString().substring(0, 10));
        //document.getElementById('RPT_ToDate').valueAsDate = new Date();
        $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
        $('#Report_Controls').enhanceWithin();
        if (GotRecords) refreshPaxManifestReport();
        break;
      case 'Report_TimeSheets':
        var now = new Date();
        now.setDate(now.getDate() - 7);
        Controls += '<div class="ui-grid-a noprint" id="RPT_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" value="2014-01-01"/></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date') +
          '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls +=
          '<button class="greenbg" data-mini="true" onClick="update_TimeSheet_Data();">' +
          iTrans('Update Results') +
          '</button></div>';

        $('#Report_Controls').html(Controls);
        //document.getElementById('RPT_FromDate').valueAsDate = now;
        $('#RPT_FromDate').val(now.toISOString().substring(0, 10));
        //document.getElementById('RPT_ToDate').valueAsDate = new Date();
        $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
        $('#Report_Controls').enhanceWithin();
        Report_Data = json_data[0].List;
        $.mobile.loading('show', {
          theme: 'a',
        });
        REPORT_USER_DATA = [];
        if (GotRecords) getTimeSheet(0);
        break;
      // HANDLED BY REVAMP NOW
      // case 'Report_Certs':
      //   Controls += "<table class='limit-table noprint'><tr><td>";
      //   if (AdminEditON == true)
      //     Controls +=
      //       '<label for="RPT_Edit">' +
      //       iTrans('Enable Updating') +
      //       '</label><input type="checkbox" id="RPT_Edit" data-mini="true" checked="checked"/>';
      //   else
      //     Controls +=
      //       '<label for="RPT_Edit">' +
      //       iTrans('Enable Updating') +
      //       '</label><input type="checkbox" id="RPT_Edit" data-mini="true" />';
      //   Controls +=
      //     '</td><td><button onClick="Report_Certs_User_List_View()" class="greenbg" data-mini="true">View User List</button></td></tr></table>';
      //   Controls += '<div class="ui-grid-a"><div class="ui-block-a">';
      //   Controls +=
      //     "<label for='RPT_Cert_Category'>Certificate Category</label><select id='RPT_Cert_Category' data-mini='true'><option value='All' selected>" +
      //     iTrans('All') +
      //     '</option></select>';
      //   Controls += '</div><div class="ui-block-b">';
      //   Controls +=
      //     '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true"><legend>' +
      //     iTrans('Other Filters') +
      //     '</legend><label for="RPT_Required">' +
      //     iTrans('Required') +
      //     '</label><input type="checkbox" id="RPT_Required" />';
      //   Controls +=
      //     '<label for="RPT_Upcoming">' + iTrans('Upcoming') + '</label><input type="checkbox" id="RPT_Upcoming" />';
      //   Controls +=
      //     '<label for="RPT_Expired">' +
      //     iTrans('Expired') +
      //     '</label><input type="checkbox" id="RPT_Expired" /></fieldset>';
      //   Controls += '</div>';
      //   Controls += '</div>';
      //   Controls +=
      //     '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
      //     '<legend>' +
      //     iTrans('User Type Filter') +
      //     ':</legend>' +
      //     '<input type="radio" name="RPT_UserType" id="RPT_Type" value="All" checked="checked">' +
      //     '<label for="RPT_Type">' +
      //     iTrans('All') +
      //     '</label>' +
      //     '<input type="radio" name="RPT_UserType" id="RPT_pilotType" value="Pilot" >' +
      //     '<label for="RPT_pilotType">' +
      //     iTrans('Pilot') +
      //     '</label>' +
      //     '<input type="radio" name="RPT_UserType" id="RPT_engineerType" value="Engineer">' +
      //     '<label for="RPT_engineerType">' +
      //     iTrans('Engineer') +
      //     '</label>' +
      //     '<input type="radio" name="RPT_UserType" id="RPT_adminType" value="Admin">' +
      //     '<label for="RPT_adminType">' +
      //     iTrans('Admin') +
      //     '</label>' +
      //     '<input type="radio" name="RPT_UserType" id="RPT_attendantType" value="Attendant">' +
      //     '<label for="RPT_attendantType">' +
      //     iTrans('Flight Attendant') +
      //     '</label>' +
      //     '<input type="radio" name="RPT_UserType" id="RPT_groundType" value="Ground">' +
      //     '<label for="RPT_groundType">' +
      //     iTrans('Ground') +
      //     '</label>' +
      //     '</fieldset>';
      //   $('#Report_Controls').html(Controls);
      //   $('#Report_Controls').enhanceWithin();
      //   $('#RPT_Cert_Category').change(Report_Certs);
      //   $('#RPT_Upcoming').click(Report_Certs);
      //   $('#RPT_Expired').click(Report_Certs);
      //   $('#RPT_Required').click(Report_Certs);
      //   $('#RPT_pilotType').click(Report_Certs);
      //   $('#RPT_Type').click(Report_Certs);
      //   $('#RPT_engineerType').click(Report_Certs);
      //   $('#RPT_adminType').click(Report_Certs);
      //   $('#RPT_attendantType').click(Report_Certs);
      //   $('#RPT_groundType').click(Report_Certs);
      //   $('#RPT_Edit').click(Report_Certs);
      //
      //   if (CURRENT_CERT_VIEW.view == 'Matrix') {
      //     if (GotRecords) Report_Certs();
      //   }
      //   if (CURRENT_CERT_VIEW.view == 'User') {
      //     if (GotRecords) Report_Certs_User(CURRENT_CERT_VIEW.User_PK, CURRENT_CERT_VIEW.chatname);
      //   }
      //   if (CURRENT_CERT_VIEW.view == 'UserList') {
      //     if (GotRecords) Report_Certs_User_List_View();
      //   }
      //   break;
      case 'Report_Itin':
        Controls += '<div class="ui-grid-a  noprint" id="RPT_TDG_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" /></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date') +
          '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls += '<div class="ui-grid-a" id="RPT_TDG_Filter2">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_Pilots">' +
          iTrans('Filter Pilots') +
          '</label><select id="RPT_Pilots" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_Idents">' +
          iTrans('Filter Ident') +
          '</label><select id="RPT_Idents" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<button class="greenbg" data-mini="true" onClick="update_Itin_Data();">' +
          iTrans('Update Results') +
          '</button></div>';
        $('#Report_Controls').html(Controls);
        var fromdate = new Date();
        fromdate.setDate(fromdate.getDate() - 30);
        //document.getElementById('RPT_FromDate').valueAsDate = fromdate;
        $('#RPT_FromDate').val(fromdate.toISOString().substring(0, 10));
        //document.getElementById('RPT_ToDate').valueAsDate = new Date();
        $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
        for (i in PILOT_data) {
          $('#RPT_Pilots').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
        }
        for (i in ALLAC_data) {
          $('#RPT_Idents').append($('<option />').val(ALLAC_data[i].ident).text(ALLAC_data[i].ident));
        }
        $('#Report_Controls').enhanceWithin();
        //$("#RPT_FromDate").change(update_Itin_Data);
        //$("#RPT_ToDate").change(update_Itin_Data);
        $('#RPT_Pilots').change(update_Itin_Data);
        $('#RPT_Idents').change(update_Itin_Data);
        if (GotRecords) Report_Itin();
        break;
      case 'Report_RA':
        $('#Title_Report_Name').html(iTrans('Risk Assessment Reports'));

        Controls += '<div class="ui-grid-a  noprint" id="RPT_TDG_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" /></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date') +
          '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls += '<div class="ui-grid-a" id="RPT_TDG_Filter2">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_Pilots">' +
          iTrans('Filter Staff Name') +
          '</label><select id="RPT_Pilots" data-mini="true" ><option value="0" selected>All</option></select></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_Status">' +
          iTrans('Filter Approval Status') +
          '</label><select id="RPT_Status" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_RiskLevel">' +
          iTrans('Filter Risk Level') +
          '</label><select id="RPT_RiskLevel" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_OperationType">' +
          iTrans('Filter Operation') +
          '</label><select id="RPT_OperationType" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FormName">' +
          iTrans('Filter Form Name') +
          '</label><select id="RPT_FormName" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<button class="greenbg" data-mini="true" onClick="update_RA_Data();">' +
          iTrans('Update Results') +
          '</button>';
        Controls += `<button data-icon="arrow-d" id="exportRACSV" disabled>${iTrans('Download CSV Data')}</button>`;
        Controls += '</div>';
        $('#Report_Controls').html(Controls);

        populateRiskAssessmentFilterControls();
        $('#Report_Controls').enhanceWithin();

        if (GotRecords) {
          Report_RA();
        } else {
          $('#exportRACSV').prop('disabled', true);
        }
        break;
      case 'Report_BI':
        $('#Report_Controls').html('');
        $('#Report_Controls').enhanceWithin();
        $('#Report_Content').html('');
        if (GotRecords) Report_BI();
        break;
      case 'Report_EXPENSES':
        $('#Report_Controls').html('');
        $('#Report_Controls').enhanceWithin();
        $('#Report_Content').html('');
        if (GotRecords) Report_EXPENSES();
        break;
      case 'Report_TDG':
        Report_Data = Report_Data[0];
        Controls +=
          '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true" class="noprint">' +
          '<legend>' +
          iTrans('TDG Report Type') +
          '</legend>' +
          '<input type="radio" name="RPT_TDGtype" id="RPT_Manifests" value="Manifests" checked="checked">' +
          '<label for="RPT_Manifests">' +
          iTrans('Manifests') +
          '</label>' +
          '<input type="radio" name="RPT_TDGtype" id="RPT_Rejected" value="Rejected" >' +
          '<label for="RPT_Rejected">' +
          iTrans('Rejected Packages') +
          '</label>' +
          '</fieldset>';
        Controls += '<div class="ui-grid-a noprint" id="RPT_TDG_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" value="2014-01-01"/></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date') +
          '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls += '<div class="ui-grid-a" id="RPT_TDG_Filter2">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_Pilots">' +
          iTrans('Filter Pilots') +
          '</label><select id="RPT_Pilots" data-mini="true" ><option value="All" selected>All</option></select></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_Idents">' +
          iTrans('Filter Ident') +
          '</label><select id="RPT_Idents" data-mini="true" ><option value="All" selected>All</option></select></div></div>';
        $('#Report_Controls').html(Controls);
        //document.getElementById('RPT_ToDate').valueAsDate = new Date();
        $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
        for (i in PILOT_data) {
          $('#RPT_Pilots').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
        }
        for (i in ALLAC_data) {
          $('#RPT_Idents').append($('<option />').val(ALLAC_data[i].ident).text(ALLAC_data[i].ident));
        }
        $('#Report_Controls').enhanceWithin();
        $('#RPT_FromDate').change(update_TDG_Data);
        $('#RPT_ToDate').change(update_TDG_Data);
        $('#RPT_Manifests').click(update_TDG_Data);
        $('#RPT_Rejected').click(update_TDG_Data);
        $('#RPT_Pilots').change(Report_TDG);
        $('#RPT_Idents').change(Report_TDG);
        if (GotRecords) Report_TDG();
        break;
      case 'Report_DutyV2':
        var now = new Date();
        now.setMonth(now.getMonth() - 12);
        Controls += '<div class="ui-grid-a noprint" id="RPT_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" value="2014-01-01"/></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date<') +
          '/label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls +=
          '<button class="greenbg" data-mini="true" onClick="update_Duty_Data();">' +
          iTrans('Update Results') +
          '</button></div>';
        Controls +=
          '<label for="hideWarnings">' +
          iTrans('Hide Warnings') +
          '</label><input type="checkbox" id="hideWarnings" onClick="refresh_Duty_Data()" data-mini="true" />';
        Controls +=
          '<label for="hideExperience">' +
          iTrans('Hide Experience') +
          '</label><input type="checkbox" id="hideExperience" onClick="refresh_Duty_Data()" data-mini="true" checked/>';
        //Controls += '<label for="showLifeExperience">Display Lifetime Experience</label><input type="checkbox" id="showLifeExperience" onClick="refresh_Duty_Data()" data-mini="true" />';
        Controls += '<div id="DutyExperienceOpts"></div>';
        $('#Report_Controls').html(Controls);
        //document.getElementById('RPT_FromDate').valueAsDate = now;
        $('#RPT_FromDate').val(now.toISOString().substring(0, 10));
        //document.getElementById('RPT_ToDate').valueAsDate = new Date();
        $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
        $('#Report_Controls').enhanceWithin();
        HideFDTwarnings = false;
        Report_Data = json_data[0].Pilots;
        REPORT_USER_DATA = [];

        if (GotRecords) {
          Report_Duty_ListPilots();
        }
        break;
      case 'Report_Assets':
        filterAssets = 'null';
        if (GotRecords) initReport_Assets();
        break;
      case 'Report_Logs':
        var now = new Date();
        now.setMonth(now.getMonth() - 1);
        Controls += '<div class="ui-grid-a noprint" id="RPT_Filter1">';
        Controls +=
          '<div class="ui-block-a"><label for="RPT_FromDate">' +
          iTrans('From Date') +
          '</label><input type="Date" id="RPT_FromDate" data-mini="true" value="2014-01-01"/></div>';
        Controls +=
          '<div class="ui-block-b"><label for="RPT_ToDate">' +
          iTrans('To Date') +
          '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
        Controls += '<div id="ReportLogsRecords"></div>';
        Controls +=
          '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
          '<legend>' +
          iTrans('Notification Log Type') +
          '</legend>' +
          '<input type="radio" name="RPT_NotifyType" id="RPT_NoItin" value="NoItin" />' +
          '<label for="RPT_NoItin">' +
          iTrans('No Itinerary') +
          '</label>' +
          '<input type="radio" name="RPT_NotifyType" id="RPT_Notification" value="Notify" checked="checked" />' +
          '<label for="RPT_Notification">' +
          iTrans('Notification Emails') +
          '</label>' +
          '</fieldset>';
        $('#Report_Controls').html(Controls);
        //document.getElementById('RPT_FromDate').valueAsDate = now;
        $('#RPT_FromDate').val(now.toISOString().substring(0, 10));
        //document.getElementById('RPT_ToDate').valueAsDate = new Date();
        $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
        $('#Report_Controls').enhanceWithin();
        $('#RPT_FromDate').change(update_Log_Data);
        $('#RPT_ToDate').change(update_Log_Data);
        $('#RPT_NoItin').click(Report_Logs);
        $('#RPT_Notification').click(Report_Logs);
        if (GotRecords) Report_Logs();
        break;
      case 'Report_PilotInfoV2':
        Report_Data = json_data[0].Pilots;
        REPORT_USER_DATA = [];

        if (GotRecords) Report_PIS_ListPilots();
        break;
    }
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    $.ajaxSetup({
      timeout: 120000,
    });
    AJAXFAIL(error);
  });
}

function handleTdgReportClick() {
  VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/reports/dangerous-goods?from-legacy=Administration_Page`);
}

function populateRiskAssessmentFilterControls() {
  let fromdate = new Date();
  fromdate.setDate(fromdate.getDate() - 30);
  //document.getElementById('RPT_FromDate').valueAsDate = fromdate;
  $('#RPT_FromDate').val(fromdate.toISOString().substring(0, 10));
  //document.getElementById('RPT_ToDate').valueAsDate = new Date();
  $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
  for (i in STAFF_LIST) {
    $('#RPT_Pilots').append($('<option />').val(STAFF_LIST[i].PrimaryKey).text(STAFF_LIST[i].chatname));
  }

  let RAStatusList = ['Pending', 'Approved', 'Denied'];
  for (i in RAStatusList) {
    $('#RPT_Status').append($('<option />').val(RAStatusList[i]).text(iTrans(RAStatusList[i])));
  }

  let RARiskLevelList = ['Exceeded', 'Warning', 'Passed', 'Exceeded Required', 'Denied', 'Archived'];
  for (i in RARiskLevelList) {
    $('#RPT_RiskLevel').append($('<option />').val(RARiskLevelList[i]).text(iTrans(RARiskLevelList[i])));
  }

  $.getJSON(BASEURL + `api/v2/groups/${window.vueApp.groupKey}/reports/risk-assessment/filter-data`, function (json_data) {
    for (const name of json_data.form_names) {
      $('#RPT_FormName').append($('<option />').val(name).text(name));
    }

    for (const operation of json_data.operation_types) {
      $('#RPT_OperationType').append($('<option />').val(operation).text(operation));
    }
  }).fail(function (jqXHR, status, error) {
    verbose.log('Failed to get Risk Assessment filterReport Data', error);
    AJAXFAIL(error);
  });
}

function getLocalDateTime(datetime, nowDate = null, withLineBreak = true) {
  const convertedDate = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(datetime);

  if (convertedDate == null)
    return {
      text: 'NULL',
      date: null,
      FromNow: null,
    };

  if (nowDate == null) {
    var now = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(moment().utc());
  } else {
    var now = nowDate;
  }

  const breakPointString = withLineBreak ? '<br />' : ' ';

  var Filed = convertedDate?.format(
    `${VueBridge.dateTimeUtils.formattableDateFormats[0]}${breakPointString}${VueBridge.dateTimeUtils.timeFormat}`
  );

  var daysAgo = Math.floor((now - convertedDate.toDate()) / (1000 * 60 * 60 * 24));
  var FromNow = daysAgo + ' ' + iTrans('Days ago');
  return {
    text: Filed,
    date: convertedDate.toDate(),
    FromNow: FromNow,
    daysAgo: daysAgo,
  };
}

function Report_Duration(datetime1, datetime2) {
  if (datetime1 == null || datetime2 == null) return 'Unknown';
  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var distance;
  if (datetime1 > datetime2) distance = datetime1 - datetime2;
  else distance = datetime2 - datetime1;
  var days = Math.floor(distance / _day);
  var hours = Math.floor((distance % _day) / _hour);
  var minutes = Math.floor((distance % _hour) / _minute);
  var seconds = Math.floor((distance % _minute) / _second);

  var timestring = '';
  if (days > 0) timestring += days + ' ' + iTrans('Days') + ' ';
  if (hours > 0) timestring += hours + ' H ';
  timestring += minutes + ' M';

  return timestring;
}

function Report_DayDiff(Now, target, Threshold, Class) {
  var Diff = {};
  if (Now > target) {
    Diff.Text = '' + iTrans('Expired') + ' ' + Math.round((Now - target) / (1000 * 60 * 60 * 24)) + ' Days';
    Diff.Diff = Math.round((Now - target) / (1000 * 60 * 60 * 24));
    Diff.Expired = true;
  } else Diff.Expired = false;
  if (target > Now) {
    Diff.Text = Math.round((target - Now) / (1000 * 60 * 60 * 24)) + ' Days';
    Diff.Diff = Math.round((target - Now) / (1000 * 60 * 60 * 24));
  }
  if (Diff.Diff != undefined) {
    if (Diff.Diff < Threshold && Class == '') {
      Diff.Upcoming = true;
      Diff.Class = 'yellowRecord';
    } else {
      Diff.Upcoming = false;
      Diff.Class = Class;
    }
    return Diff;
  } else {
    Diff.Diff = 0;
    Diff.Text = '';
    Diff.Upcoming = false;
    Diff.Expired = false;
    if (Class == '') Diff.Class = 'greyRecord';
    else Diff.Class = Class;
    return Diff;
  }
}

function MakeFeature(coords, type, stroke, marker) {
  /*
    ############   EXAMPLE INPUT COORDS
    $coords = array();
    $points = array();
    $points[] = -89.2461;
    $points[] = 48.3822;
    $coords[] =  $points;
    $points = array();
    $points[] = -89.0461;
    $points[] = 48.0822;
    $coords[] =  $points;
    $features = array();
    $features[] = MakeFeature($points,"Point","#ffff00");
    $features[] = MakeFeature($coords,"LineString","#ff0000");
    */
  if (type == 'Point') {
    var geometry = {};
    geometry.type = 'Point';
    geometry.coordinates = coords;
    var properties = {};
    properties['marker-size'] = 'small';
    properties['marker-symbol'] = marker;
    properties['marker-color'] = stroke;
    var feature = {};
    feature.type = 'Feature';
    feature.geometry = geometry;
    feature.properties = properties;
    return feature;
  }
  if (type == 'LineString') {
    var geometry = {};
    geometry['type'] = 'LineString';
    geometry['coordinates'] = coords;
    var properties = {};
    properties['stroke'] = stroke;
    properties['stroke-width'] = 2;
    var feature = {};
    feature['type'] = 'Feature';
    feature['geometry'] = geometry;
    feature['properties'] = properties;
    return feature;
  }
}

function getMapURL(features, width, height, Lat, Long, Zoom) {
  var json = {};
  json.type = 'FeatureCollection';
  json.features = features;
  json = JSON.stringify(json);
  json = encodeURIComponent(json);
  var Url =
    'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson(' +
    json +
    ')/' +
    Long +
    ',' +
    Lat +
    ',' +
    Zoom +
    '/' +
    width +
    'x' +
    height +
    '.png?access_token=' +
    MAPBOX_TOKEN;

  return Url;
}

// Takes an array of values and returns them as escaped CSV
// @param _arrValues The value array
function IterateCSV(_arrValues, _CSV) {
  // Check if the csv will be well-formed. Good for debugging.
  // if(_CSV.length <= 0)
  // {
  // 	WELLFORMED_CSV_COLUMNS = _arrValues.length;
  // } else	if(_arrValues.length != WELLFORMED_CSV_COLUMNS) {
  // 	console.log("--------------------------------------------------------------------------");
  // 	console.error('CSV generator wanted ' + WELLFORMED_CSV_COLUMNS +
  // 								' columns but got ' + _arrValues.length);
  //
  // 	console.log (_arrValues);
  // 	debugger;
  // 	console.log("--------------------------------------------------------------------------");
  // }

  var csvLine = '';

  for (var i = 0; i < _arrValues.length; i++) {
    strCsvVal = _arrValues[i];
    csvLine += FormatCSV(strCsvVal) + ',';
  }

  // Remove the trailing comma
  csvLine = csvLine.substr(0, csvLine.length - 1);
  csvLine += '\n';

  return csvLine;
}

// Takes a value and escapes it to CSV standards
// @param {mixed} _entry
function FormatCSV(_entry) {
  //console.log(_entry);
  if (typeof _entry != 'string') {
    return _entry;
  }
  _entry = _entry.replace(/(<p>|<\/p>)/g, '. ');
  _entry = _entry.replace(/(<br \/>)/g, '. ');
  var booWrapInQuotes = false;

  _entry = _entry.trim();

  var hasNewLine = /\r|\n/.test(_entry);

  if (_entry.indexOf(',') > -1 || hasNewLine) {
    // escape commas
    booWrapInQuotes = true;
  } else if (_entry.indexOf('"') > -1) {
    // Escape quotes
    _entry = _entry.replace('"', '""');
    booWrapInQuotes = true;
  }

  if (booWrapInQuotes) {
    return '"' + _entry + '"';
  }

  return _entry;
}

function update_Pax_Data() {
  $('#Report_Content').html('<h2>' + iTrans('Getting Records') + '...</h2>');
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  $.ajaxSetup({
    timeout: 6000000,
  });
  $.getJSON(
    BASEURL +
      'menu-Administration-Reports-AJAX.php?report=Report_PaxManifesting&FromDate=' +
      FromDate +
      '&ToDate=' +
      ToDate +
      '&Firstname=' +
      encodeURIComponent($('#RPT_PaxFirstname').val()) +
      '&Lastname=' +
      encodeURIComponent($('#RPT_PaxLastName').val()),
    function (json_data) {
      $.ajaxSetup({
        timeout: 120000,
      });
      if (json_data[0].ServerResponse == 'No Records') {
        $('#RPT_PageControl').html('');
        $('#Title_Report_Name').html(iTrans('Passenger Manifest Report'));
        $('#Report_Content').html('<h2>' + iTrans('No Records') + '</h2>');
      } else {
        Report_Data = json_data;
        refreshPaxManifestReport();
      }
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    $.ajaxSetup({
      timeout: 120000,
    });
    AJAXFAIL(error);
  });
}

function refreshPaxManifestReport() {
  var Report = Report_Data[0].ServerResponse;
  console.log(R);
  var CSV = '';
  CSV += IterateCSV(
    [
      iTrans('Itin#'),
      iTrans('Itin Filed'),
      iTrans('Itin Closed'),
      iTrans('Lastname'),
      iTrans('Firstname'),
      iTrans('Ident'),
      iTrans('Pilot'),
      iTrans('SIC'),
      iTrans('FR#'),
      iTrans('Itin Details'),
      iTrans('Personal Use'),
    ],
    CSV
  );
  var HTML =
    '<div style="width: 100%; overflow: auto"><table id="Report_Table" data-filter-"true" class="wrap">' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Itin') +
    '#</th>' +
    '<th data-priority="0">' +
    iTrans('Itin Filed') +
    '</th>' +
    '<th>' +
    iTrans('Itin Closed') +
    '</th>' +
    '<th>' +
    iTrans('Lastname') +
    '</th>' +
    '<th>' +
    iTrans('Firstname') +
    '</th>' +
    '<th>' +
    iTrans('Ident') +
    '</th>' +
    '<th>' +
    iTrans('Pilot') +
    '</th>' +
    '<th>' +
    iTrans('SIC') +
    '</th>' +
    '<th>' +
    iTrans('FR') +
    '#</th>' +
    '<th>' +
    iTrans('Itin Details') +
    '</th>' +
    '<th>' +
    iTrans('Personal Use') +
    '</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';

  const dateFormat = `${VueBridge.dateTimeUtils.formattableDateFormats[0]} ${VueBridge.dateTimeUtils.timeFormat}`;

  for (var i in Report) {
    var R = Report[i];
    var FR = '';
    if (R.flight_report_id != null) FR = R.flight_report_id;
    else if (R.Billable == 1) FR = iTrans('Report Pending');
    else FR = 'N/A';
    HTML +=
      '<tr><td>' +
      R.ItinPK +
      '</td><td>' +
      VueBridge.dateTimeUtils.convertUtcToUsersTimezone(R.filed)?.format(dateFormat) +
      '</td><td>' +
      VueBridge.dateTimeUtils.convertUtcToUsersTimezone(R.timeback)?.format(dateFormat) +
      '</td><td>' +
      R.lastname +
      '</td><td>' +
      R.firstname +
      '</td><td>' +
      R.ident +
      '</td><td>' +
      R.pilot +
      '</td><td>' +
      R.sic +
      '</td><td>' +
      FR +
      '</td><td>' +
      R.Details +
      '</td><td>' +
      R.PersonalUse +
      '</td></tr>';
    CSV += IterateCSV(
      [R.ItinPK, R.filed, R.timeback, R.lastname, R.firstname, R.ident, R.pilot, R.sic, FR, R.Details, R.PersonalUse],
      CSV
    );
  }

  HTML += '</tbody></table></div>';
  var DownloadCSV =
    '<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportManifest_CSV_BTN">' +
    iTrans('Download CSV Data') +
    '</a></div>';
  $('#Report_Content').html(DownloadCSV + HTML);
  $('#Report_Content').enhanceWithin();
  AddTableSorter('Report_Table');
  DownloadCSV_DATA = CSV;
  $('#exportManifest_CSV_BTN').on('click', function (event) {
    var data;
    var now = new Date();
    var filename =
      'Cirro_PaxManifest_Report_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '.csv';
    if (device.platform.toUpperCase() === 'ANDROID') {
      console.log('Opening base64 Android');
      event.preventDefault();
      LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
    } else if (IOS) {
      console.log('Opening base64 IOS');
      event.preventDefault();
      LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
    } else {
      console.log('Opening base64 Universal URL');
      var a = document.createElement('a');

      if (typeof a.download != 'undefined') {
        data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_blank',
          download: filename,
        });
        // let the click go through
      } else {
        // download attribute is not supported
        data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_system',
        });
      }
    }
  });
}
