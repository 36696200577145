/**
 * Page class for the trends view
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_TrendsView extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_AD_TrendsView', SAMA.pages.MenuHome);

    this.bufferedDataSets = [];

    this.header = new cSAMA_PageHeader('Trends', this.dom.page);
    this.dom.keywordInput = this.dom.page.find('#sama_trendsKwds');
    this.dom.kwdUpdateButton = this.dom.page.find('#sama_trendsUpdateKwdGraph');
    this.dom.graphType = this.dom.page.find('#sama-trend-graphType');
    this.dom.reportType = this.dom.page.find('#sama-trend-report');
    this.dom.graphSection = this.dom.page.find('#hSamaTrendGraph');
    this.dom.noDataSection = this.dom.page.find('#hSamaTrendNoData');

    this.dom.graph = document.getElementById('sama_kwdGraph');
  }

  /**
   * Function called when the user enters the page.
   * @override
   */
  EnterPage() {
    this.dom.graphSection.hide();

    let requests = [new cSAMA_Request('Preferences', 'getData'), new cSAMA_Request('Trends', 'getGraphableKeywords')];

    cSAMA_Request.Dispatch({
      reqs: requests,
      name: 'GetGraphPreferences',
      clientErrorCode: 'SAMA 60 0E DF 69',
      goBackOnError: false,
      fnSuccess: (_r) => this.SetUpGui(_r),
    });
  }

  SetUpGui(_requests) {
    SAMA.settings.preferences = new mSAMA_Preferences(_requests[0]);

    if (_requests[1] != null && _requests[1].hasOwnProperty('categories')) {
      cSAMA_GeneralInputs.Keyword(this.dom.keywordInput, false, false, _requests[1]);
    } else {
      this.dom.keywordInput.hide();
    }

    this.dom.kwdUpdateButton.on('click.samaevt', () => this.GetNewData());
    this.dom.keywordInput.on('change.samaevt', () => this.ToggleUpdateButton());
    this.dom.reportType.on('change.samaevt', () => this.ToggleUpdateButton());
    this.dom.graphType.on('change.samaevt', () => this.ChangeGraphType());

    SAMA.Navigator.FinishedLoading(this.header);
  }

  /**
   * Hides / Shows the update button depending on the outcome of input validation
   */
  ToggleUpdateButton() {
    this.dom.kwdUpdateButton.prop('disabled', !this.InputsValid());
  }

  /**
   * Check if updating the graph is possible.
   * @return {boolean}
   */
  InputsValid() {
    let keywords = [];

    try {
      keywords = this.dom.keywordInput.catTagger('getSelectedTags')[0];
    } catch (_e) {
      //
    }

    // Keywords selected?
    let hasKeywords = keywords.length > 0;
    let hasReportType = this.dom.reportType.find(':radio:checked').val() > 0;

    return hasKeywords || hasReportType;
  }

  /**
   * Retrieve new Data from the server
   */
  GetNewData() {
    if (!this.InputsValid()) {
      // Ignore because data is missing
      return;
    }

    let keywords = [];

    try {
      keywords = this.dom.keywordInput.catTagger('getSelectedTags')[0];
    } catch (_e) {
      //
    }

    let requests = [];

    if (keywords.length > 0) {
      let pks = [];

      for (let k = 0; k < keywords.length; k++) {
        pks.push(keywords[k].tagID);
      }

      let ra = new cSAMA_Request('Trends', 'getKeywordSeries');
      ra.Set('keywordPks', pks);
      requests.push(ra);
    }

    let reportType = this.dom.reportType.find(':radio:checked').val();
    if (reportType > 0) {
      let rb = new cSAMA_Request('Trends', 'getReportSeries');
      rb.Set('reportType', reportType);
      requests.push(rb);
    }

    cSAMA_Request.Dispatch({
      reqs: requests,
      name: 'GetGraphData',
      clientErrorCode: 'SAMA 60 0E DF 69',
      goBackOnError: false,
      fnSuccess: (_r) => this.UpdateGraph(_r),
    });
  }

  UpdateGraph(_result) {
    let dataSets = [];

    for (let r = 0; r < _result.length; r++) {
      let svData = _result[r];
      if (svData.hasOwnProperty('keywordSeries')) {
        dataSets = this.ProcessKeywordSeries(svData.keywordSeries);
      } else if (svData.hasOwnProperty('reportSeries')) {
        dataSets.push(this.ProcessReportSeries(svData.reportSeries));
      }
    }

    if (dataSets[0] == null && dataSets[1] == null) {
      this.dom.noDataSection.show();
      this.dom.graphSection.hide();
      return;
    }
    this.dom.noDataSection.hide();
    this.dom.graphSection.show();

    this.bufferedDataSets = dataSets;
    this.ChangeGraphType();
  }

  ChangeGraphType() {
    let isAbs = +this.dom.graphType.find(':radio:checked').val() > 0;
    let workingDataset = [];
    let layout = {};

    $.extend(true, workingDataset, this.bufferedDataSets);

    if (isAbs) {
      workingDataset.forEach(pSAMA_TrendsView.MakeAbsolute);
    } else {
      workingDataset.forEach(pSAMA_TrendsView.ImproveDifferential);
      layout.barmode = 'stack';
    }

    layout.yaxis = {
      tick0: 0,
      dtick: 1,
      fixedrange: true,
      tickmode: 'linear',
    };

    let returnScroll = cSAMA_Utils.GetScrollTop();

    Plotly.purge('sama_kwdGraph');
    Plotly.newPlot('sama_kwdGraph', workingDataset, layout, { showLink: false });

    cSAMA_Utils.ScrollTo(returnScroll);
  }

  static ImproveDifferential(_dataset) {
    for (let s = 0; s < _dataset.x.length; s++) {
      let m = moment(_dataset.x[s]);
      m.minute(0).second(0).millisecond(0);
      _dataset.x[s] = m.format(SAMA.defaults.DateInputFormat + ' ' + SAMA.defaults.TimeInputFormat);
    }
  }

  static MakeAbsolute(_dataset) {
    _dataset.line = {
      color: _dataset.marker.color,
    };
    delete _dataset.marker;
    _dataset.type = 'line';

    let runningTotal = 0;
    for (let s = 0; s < _dataset.y.length; s++) {
      runningTotal += _dataset.y[s];
      _dataset.y[s] = runningTotal;
    }
  }

  /**
   *
   * @param _results
   * @return {Array}
   */
  ProcessKeywordSeries(_results) {
    let dataSets = {};
    let previousTkw = -1;
    let seriesColor = '';
    let subSeriesColor = '';
    for (let r = 0; r < _results.length; r++) {
      let dataPoint = _results[r];
      if (previousTkw !== dataPoint.tkw_kwdPk) {
        if (seriesColor === dataPoint.tkw_color) {
          subSeriesColor = cSAMA_Utils.ColorShade(subSeriesColor, 0.05);
        } else {
          subSeriesColor = '#' + dataPoint.tkw_color;
        }
        seriesColor = dataPoint.tkw_color;

        // New Series!
        dataSets[dataPoint.tkw_kwdPk] = {
          name: dataPoint.tkw_kwdName,
          text: [],
          type: 'bar',
          marker: {
            color: subSeriesColor,
          },
          x: [],
          y: [],
        };

        previousTkw = dataPoint.tkw_kwdPk;
      }

      let m = moment.utc(dataPoint.tkw_hour);
      let dt = m.local().format(SAMA.defaults.DateInputFormat + ' ' + SAMA.defaults.TimeInputFormat);
      dataSets[dataPoint.tkw_kwdPk].text.push(dataPoint.tkw_reports);
      dataSets[dataPoint.tkw_kwdPk].x.push(dt);
      dataSets[dataPoint.tkw_kwdPk].y.push(dataPoint.tkw_count);
    }

    return Object.values(dataSets);
  }

  /**
   *
   * @param _series
   * @return {object|null}
   */
  ProcessReportSeries(_series) {
    let dataSet = {
      name: 'Reports',
      text: [],
      type: 'bar',
      marker: {
        color: '#555',
      },
      x: [],
      y: [],
    };

    for (let i = 0; i < _series.length; i++) {
      let m = moment.utc(_series[i].rep_approvedDate);
      let dt = m.local().format(SAMA.defaults.DateInputFormat + ' ' + SAMA.defaults.TimeInputFormat);

      dataSet.text.push(_series[i].rep_nameArr);
      dataSet.x.push(dt);
      dataSet.y.push(_series[i].rep_count);
    }

    return dataSet;
  }

  /**
   * Function called when the user leaves the page.
   * @override
   */
  LeavePage() {
    this.dom.kwdUpdateButton.off('.samaevt');
    this.dom.keywordInput.off('.samaevt');
    this.dom.reportType.off('.samaevt');

    try {
      this.dom.keywordInput.catTagger('destroy');
    } catch (_E) {
      //
    }

    SAMA.Navigator.FinishedLoading();
  }
}
