/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
// Duty times starts below, above code will become synchronization engine utilizing webworker
//defaults to be set from last database record for new inserts
var Standby = 0;
var IFR = 0;
var Heli = 0;
var DHC = 0;
var Dual = 0;
var Logging = 0;
var AerialApp = 0;
var Rule_ID = 0;
var TimezoneHB = 'America/Toronto';
var AverageFlightTime = 1;
var FDTdefault_Timezone = 'America/Toronto';
var FDTdefault_travelTime = 0;
var FDTdefault_homeBase = 0;
var FDTdefault_homeBase2 = 0;
var FDTdefault_Sectors = 0;
var FDTdefault_Medevac = 0;
//var Rule = This has a default of Rule = SetDefaultFDT_Rule(); //in sync-IOS-FDT.js
var FDT_FORCE_SYNC = false; //temporary global to force FDT resync after using revamp FDT
var ROLL24DATA = '{"FDT135":[]}';
var ROLL24TOT = 0;
var ROLL_MIN = '0000';
var FDT_REPORT = false;
var FDT_ALLJOBS = [];
var FDT_JOBNUMS = [];
var FDT_LAST_IDENT = 'No Ident';
var REC_EDITED = false;
var FDT_RuleKEY = {
  700: 'Private Pilot',
  406: '406 Flight Training Unit',
  604: '604 Private Operator',
  702: '702 Aerial Work',
  703: '703 Air Taxi',
  704: '704 Air Commuter Operations',
  705: '705 Airline Operations',
  91: 'Part 91',
  133: 'Part 133 - External Loads',
  135: 'Part 135.267 - Non-Scheduled',
  131: 'Part 135.271 - HEMS',
  137: 'Part 137 - Agricultural Operation',
  1: 'AU Non Ag',
  2: 'AU Aerial Ag',
  3: 'CASR 135',
  4: 'Kenya EAAC',
  5: 'DGAC DAN 137',
};
var FDT_SPECIALTY = {
  CS1: 'Cls D',
  CS2: 'Spray',
  CS3: 'Heli Ski',
  CS4: 'LowVis',
  CS5: 'CS5',
  CS6: 'CS6',
  CS7: 'CS7',
  CS8: 'CS8',
  CS9: 'CS9',
  CS10: 'S10',
  CS11: 'CS11',
  CS12: 'CS12',
  CS13: 'CS13',
  CS14: 'CS14',
  CS15: 'CS15',
  CS16: 'CS16',
  CS17: 'CS17',
  CS18: 'CS18',
  CS19: 'CS19',
  CS20: 'CS20',
};

function userShouldHaveFDT(userType) {
  return userType === 'Pilot' || userType === 'NASP SO' || userType === 'Rescue Specialist';
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param ident
 */
function FDT_checkAcIdent_and_select(ident) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_checkAcIdent_and_select', '2024-06-26', 'bldarosa');
  var acidentexists = false;
  $('#FDTIDENT_exp').each(function () {
    if (this.value == ident) {
      acidentexists = true;
      return false;
    }
  });
  if (acidentexists === false) {
    $('#FDTIDENT_exp').append($('<option />').val(ident).text(ident));
    $('#FDTIDENT_exp').prop('disabled', true);
  }
  $('#FDTIDENT_exp').val(ident);
  //$("#FDTIDENT_exp").selectmenu('refresh');
}
var FDT_SPECIALTYV3 = {
  CS_Recs: [{ Category: 'Specialty', Fields: [], Collapsable: 0 }],
  Pilot_Days: [],
  Staff_Days: [],
};

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function FDT_CheckSplitDutylabel(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_CheckSplitDutylabel', '2024-06-26', 'bldarosa');
  if (e.checked) {
    $('#SplitTXT').html(iTrans('Mid Day Rest'));
  } else {
    $('#SplitTXT').html(iTrans('Split Duty'));
    $('#E4').hide();
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Location
 */
function FDT_SelectLocation(Location) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_SelectLocation', '2024-06-26', 'bldarosa');
  Location = Location.toString();
  switch (Location) {
    case '1':
      //Start Location
      $('#FDT_BegLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
    case '2':
      //Start + End
      $('#FDT_BegLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      $('#FDT_EndLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
    case '3':
      //End Location
      $('#FDT_EndLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
  }
  html =
    '<label for="FDT_SelectLocationIDENT">Search by ICAO / Name</label><input type="text" id="FDT_SelectLocationIDENT" />';
  html += '<div id="FDT_SelectedLocationTXT"></div>';
  html += '<input type="hidden" id="FDT_SelectedLocationLAT" />';
  html += '<input type="hidden" id="FDT_SelectedLocationLONG" />';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Location Options'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('OK')]: {
        click: function () {
          //fail
          var UserDevice = {
            Lat: parseFloat($('#FDT_SelectedLocationLAT').val()),
            Long: parseFloat($('#FDT_SelectedLocationLAT').val()),
          };
          updateFDTLocation(Location, UserDevice);
        },
      },
      [iTrans('Clear Position')]: {
        click: function () {
          var UserDevice = {
            Lat: 0,
            Long: 0,
          };
          updateFDTLocation(Location, UserDevice);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
    callbackOpen: function () {
      $('#FDT_SelectLocationIDENT').autocomplete({
        select: FDT_SelectAirportICAO,
        source: FDT_SearchAirportICAO,
      });
    },
  });
}

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @type {*[]}
 */
var FDT_AirportList = [];

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param term
 * @param callback
 */
function FDT_SearchAirportICAO(term, callback) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_SearchAirportICAO', '2024-06-26', 'bldarosa');
  console.log(term);
  var ICAO = term.term.toLowerCase();
  if (ICAO.length < 2) {
    return;
  }
  if (ICAO.length <= 4) {
    cirroDB.query('Airports', 'ICAO LIKE ? ', [ICAO + '%'], function (e) {
      if (e === false) {
        e = [];
      }
      FDT_AirportList = computeBearingAndDistance(e);

      FDT_ShowAirportOptions();
    });
  } else {
    cirroDB.query('Airports', 'Name LIKE ? ', ['%' + ICAO + '%'], function (e) {
      if (e === false) {
        e = [];
      }
      FDT_AirportList = computeBearingAndDistance(e);
      FDT_ShowAirportOptions();
    });
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function FDT_ShowAirportOptions() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_ShowAirportOptions', '2024-06-26', 'bldarosa');
  FDT_AirportList.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  var html = '<div style="max-height:250px; overflow:scroll;"><table>';
  if (FDT_AirportList.length == 1) {
    FDT_SelectAirportICAO(0);
    return;
  }
  for (var i in FDT_AirportList) {
    html +=
      '<tr onClick="FDT_SelectAirportICAO(' +
      i +
      ')"><td class="directToTxt">' +
      FDT_AirportList[i].ICAO +
      '</td><td style="font-size:10pt;">' +
      FDT_AirportList[i].Name +
      '</td></tr>';
    if (i > 25) {
      break;
    }
  }
  html += '</table></div>';
  $('#FDT_SelectedLocationTXT').html(html);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param index
 */
function FDT_SelectAirportICAO(index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_SelectAirportICAO', '2024-06-26', 'bldarosa');
  var item = FDT_AirportList[index];
  var UserDevice = {
    Lat: item.Lat,
    Long: item.Long,
  };
  $('#FDT_SelectLocationIDENT').val(item.ICAO);
  $('#FDT_SelectedLocationTXT').html('<b>' + item.Name + '</b><br />' + formatFDTlocation(UserDevice));
  $('#FDT_SelectedLocationLAT').val(item.Lat);
  $('#FDT_SelectedLocationLONG').val(item.Long);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Location
 */
function getFDTLocation(Location) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.getFDTLocation', '2024-06-26', 'bldarosa');
  Location = Location.toString();
  switch (Location) {
    case '1':
      //Start Location
      $('#FDT_BegLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
    case '2':
      //Start + End
      $('#FDT_BegLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      $('#FDT_EndLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
    case '3':
      //End Location
      $('#FDT_EndLocationTXT').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
    case 'FuelCache':
      $('#FC_UPDATE_PosHLDR_Loader').html('<img src="./images/loading_processmaker.gif" width="50px"/>');
      break;
  }
  navigator.geolocation.getCurrentPosition(
    function (position) {
      console.log('Got Device Location');
      UserDevice.Lat = position.coords.latitude;
      UserDevice.Long = position.coords.longitude;
      UserDevice.Accuracy = position.coords.accuracy;
      UserDevice.Altitude = position.coords.altitude;
      UserDevice.AltitudeAccuracy = position.coords.altitudeAccuracy;
      UserDevice.Heading = position.coords.heading;
      UserDevice.Speed = position.coords.speed;
      UserDevice.Timestamp = position.timestamp;
      //todo populate fdt location now
      updateFDTLocation(Location, UserDevice);
    },
    function (error) {
      console.log('Device Location ERROR');
      UserDevice.Lat = 0;
      UserDevice.Long = 0;
      updateFDTLocation(Location, UserDevice);
    },
    {
      maximumAge: 600000,
    }
  );
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Location
 * @param UserDevice
 */
function updateFDTLocation(Location, UserDevice) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.updateFDTLocation', '2024-06-26', 'bldarosa');
  console.log(UserDevice);
  switch (Location) {
    case '1':
      //Start Location
      $('#FDT_begLat').val(UserDevice.Lat);
      $('#FDT_begLong').val(UserDevice.Long);
      $('#FDT_BegLocationTXT').html(formatFDTlocation(UserDevice));
      break;
    case '2':
      //Start + End
      $('#FDT_begLat').val(UserDevice.Lat);
      $('#FDT_begLong').val(UserDevice.Long);
      $('#FDT_endLat').val(UserDevice.Lat);
      $('#FDT_endLong').val(UserDevice.Long);
      $('#FDT_BegLocationTXT').html(formatFDTlocation(UserDevice));
      $('#FDT_EndLocationTXT').html(formatFDTlocation(UserDevice));
      break;
    case '3':
      //End Location
      $('#FDT_begLat').val(UserDevice.Lat);
      $('#FDT_begLong').val(UserDevice.Long);
      $('#FDT_EndLocationTXT').html(formatFDTlocation(UserDevice));
      break;
    case 'FuelCache':
      $('#FC_UPDATE_PosHLDR_Loader').html('<h3>' + iTrans('Moveable Fuel Cache') + '</h3>');
      $('#FC_UPDATE_Lat').val(UserDevice.Lat);
      $('#FC_UPDATE_Long').val(UserDevice.Long);
      break;
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param UserDevice
 * @returns {string}
 */
function formatFDTlocation(UserDevice) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.formatFDTlocation', '2024-06-26', 'bldarosa');
  var LatCoordTXT;
  var LongCoordTXT;
  if (LOCALSTORAGE.CoordFormat == 'UTM') {
    var utmOK = getUTM(UserDevice.Lat, UserDevice.Long);
    LatCoordTXT = utmzone + ' 0' + utmlat;
    LongCoordTXT = 'UTM ' + utmlong;
  } else {
    LongCoordTXT = ConvertDD_User(UserDevice.Long, 'Long');
    LatCoordTXT = ConvertDD_User(UserDevice.Lat, 'Lat');
  }
  return LatCoordTXT + '<br >' + LongCoordTXT;
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param approved
 * @returns {string}
 */
function getLocationSelector(approved) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.getLocationSelector', '2024-06-26', 'bldarosa');
  var html = '';
  if (COUNTRY == 'CANADA') {
    html +=
      '<select id="FDT_LOC_sel" data-mini="false" data-role="none" ' +
      approved +
      ' onChange="UpdateFDT_loc(this);"><option selected value="">Select from list OR type below</option>';
    html += '<option value="Canada">Canada</option>';
    html += '<option value="USA">USA</option>';
    html += '<option value="Alberta">Alberta</option>';
    html += '<option value="British Columbia">British Columbia</option>';
    html += '<option value="Manitoba">Manitoba</option>';
    html += '<option value="New Brunswick">New Brunswick</option>';
    html += '<option value="Newfoundland and Labrador">Newfoundland and Labrador</option>';
    html += '<option value="Northwest Territories">Northwest Territories</option>';
    html += '<option value="Nova Scotia">Nova Scotia</option>';
    html += '<option value="Nunavut">Nunavut</option>';
    html += '<option value="Ontario">Ontario</option>';
    html += '<option value="Prince Edward Island">Prince Edward Island</option>';
    html += '<option value="Quebec">Quebec</option>';
    html += '<option value="Saskatchewan">Saskatchewan</option>';
    html += '<option value="Yukon">Yukon</option>';
    html += '</select>';
    return html;
  }
  if (COUNTRY == 'UNITED STATES') {
    html +=
      '<select id="FDT_LOC_sel" data-mini="false" data-role="none" ' +
      approved +
      ' onChange="UpdateFDT_loc(this);"><option selected value="">Select from list OR type below</option>';
    html += '<option value="Canada">Canada</option>';
    html += '<option value="USA">USA</option>';
    html += '<option value="Alberta">Alberta</option>';
    html += '<option value="British Columbia">British Columbia</option>';
    html += '<option value="Manitoba">Manitoba</option>';
    html += '<option value="New Brunswick">New Brunswick</option>';
    html += '<option value="Newfoundland and Labrador">Newfoundland and Labrador</option>';
    html += '<option value="Northwest Territories">Northwest Territories</option>';
    html += '<option value="Nova Scotia">Nova Scotia</option>';
    html += '<option value="Nunavut">Nunavut</option>';
    html += '<option value="Ontario">Ontario</option>';
    html += '<option value="Prince Edward Island">Prince Edward Island</option>';
    html += '<option value="Quebec">Quebec</option>';
    html += '<option value="Saskatchewan">Saskatchewan</option>';
    html += '<option value="Yukon">Yukon</option>';
    html += '</select>';
    return html;
  }

  html +=
    '<select id="FDT_LOC_sel" data-mini="false" data-role="none" ' +
    approved +
    ' onChange="UpdateFDT_loc(this);"><option selected value="">' +
    iTrans('Select from list OR type below') +
    '</option>';
  html += '<option value="Canada">Canada</option>';
  html += '<option value="USA">USA</option>';
  html += '</select>';
  return html;
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function UpdateFDT_loc(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.UpdateFDT_loc', '2024-06-26', 'bldarosa');
  $('#FDT_LOC').val(e.value);
  $('#FDT_LOC_sel').val('');
}

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @type {string}
 */
var FDT_IdentList = '[]';

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function add_FDT_IdentList(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.add_FDT_IdentList', '2024-06-26', 'bldarosa');
  //console.log(e.value);
  var Idents = [];
  try {
    Idents = JSON.parse(FDT_IdentList);
    if (Idents == null) {
      Idents = [];
    }
  } catch (error) {
    Idents = [];
  }
  Idents.push(e.value);
  FDT_IdentList = JSON.stringify(Idents);
  $('#FDTIDENT').val('Select');
  pop_FDT_IdentList();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param index
 */
function remove_FDT_IdentList(index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.remove_FDT_IdentList', '2024-06-26', 'bldarosa');
  var Idents = JSON.parse(FDT_IdentList);
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      iTrans('Are you sure you want to remove') +
      ' <b>' +
      Idents[index] +
      '</b> ' +
      iTrans('from your timesheet') +
      '?',
    buttons: {
      [iTrans('Remove')]: {
        click: function () {
          Idents.splice(index, 1);
          FDT_IdentList = JSON.stringify(Idents);
          pop_FDT_IdentList();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param approved
 */
function pop_FDT_IdentList(approved) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.pop_FDT_IdentList', '2024-06-26', 'bldarosa');
  try {
    var Idents = JSON.parse(FDT_IdentList);
    if (Idents.length == 0) {
      $('#FDT_IdentList').html('No Idents');
      return;
    }
    var html = '<b' + iTrans('Aircraft Ident(s)') + '></b><br />';
    for (var i in Idents) {
      if (approved == 'disabled') {
        html += Idents[i] + '    ';
      } else {
        html += '<a href="#" onclick="remove_FDT_IdentList(' + i + ')">' + Idents[i] + '</a>    ';
      }
    }
    $('#FDT_IdentList').html(html);
  } catch (e) {
    $('#FDT_IdentList').html(iTrans('No Idents'));
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function GetAllJobs() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.GetAllJobs', '2024-06-26', 'bldarosa');
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e === false) {
      e = [];
    }
    FDT_ALLJOBS = e;
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param text
 * @param callback
 */
function FDTJobNumAutoComplete(text, callback) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDTJobNumAutoComplete', '2024-06-26', 'bldarosa');
  var options = [];
  for (var i in FDT_ALLJOBS) {
    var I = FDT_ALLJOBS[i];

    var label = I.JobNum + ': ' + I.Name + ' [ ' + I.Business + ']';
    if (label.toLowerCase().indexOf(text.term.toLowerCase()) !== -1) {
      options.push({ label: label, value: i });
    }
  }
  callback(options);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param event
 * @param ui
 */
function FDTJobNumAutoCompleteSelect(event, ui) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDTJobNumAutoCompleteSelect', '2024-06-26', 'bldarosa');
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  var I = FDT_ALLJOBS[value];
  if (!Array.isArray(FDT_JOBNUMS)) FDT_JOBNUMS = [];
  FDT_JOBNUMS.push({ JobNum: I.JobNum, Name: I.Name, Business: I.Business });
  $('#TodayBillingJobNums').html(FDT_MakeJobNumTable());
  $('#TodayBillingJobNums').enhanceWithin();
  updateFDT_Day_Rec_Modified();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param index
 */
function FDT_RemoveJobNum(index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_RemoveJobNum', '2024-06-26', 'bldarosa');
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: '<h3>' + iTrans('Are you sure you want to remove this Job Number from your timesheet') + '?',
    buttons: {
      [iTrans('Remove')]: {
        click: function () {
          FDT_JOBNUMS.splice(index, 1);
          $('#TodayBillingJobNums').html(FDT_MakeJobNumTable());
          $('#TodayBillingJobNums').enhanceWithin();
          updateFDT_Day_Rec_Modified();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //Close Dialog
        },
      },
    },
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @returns {string}
 */
function FDT_MakeJobNumTable() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_MakeJobNumTable', '2024-06-26', 'bldarosa');
  var html;
  if (FDT_JOBNUMS.length == 0 || FDT_JOBNUMS == 0) {
    html = '<h3>' + iTrans('No Job Numbers For This Date') + '</h3>';
  } else {
    html =
      '<table class="limit-table second-td-fill" style="table-layout:auto;"><thead><tr><th>' +
      iTrans('Job') +
      '#</th><th>' +
      iTrans('Job Name') +
      '</th><th>Del</th></tr></thead><tbody>';
    for (var j in FDT_JOBNUMS) {
      var J = FDT_JOBNUMS[j];
      var DelJobNum =
        "<div class='vertical-center-container'><button onClick='FDT_RemoveJobNum(" +
        j +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
      html += '<tr><td>' + J.JobNum + '</td><td>' + J.Name + '</td><td>' + DelJobNum + '</td></tr>';
    }
    html += '</tbody></table>';
  }
  return html;
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function toggleASDP(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.toggleASDP', '2024-06-26', 'bldarosa');
  if (e.checked) {
    $('#Roll24').html('');
    ROLL24DATA = '{"FDT135":[]}';
    $('#FT').prop('disabled', false);
    if (!$('#CHK_ClockPicker').is(':checked')) {
      $('#FT').clockpicker({
        autoclose: true,
        decimal: true,
      });
    }
  } else {
    addRoll24();
    $('#FT').prop('disabled', true);
    if (!$('#CHK_ClockPicker').is(':checked')) {
      $('#FT').clockpicker('remove');
    }
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function toggleSpecialty(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.toggleSpecialty', '2024-06-26', 'bldarosa');
  if (e.checked) {
    localStorageDB.setItem('Specialty', 1);
  } else {
    localStorageDB.setItem('Specialty', 0);
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function toggleFDTConditions(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.toggleFDTConditions', '2024-06-26', 'bldarosa');
  if (e.checked) {
    localStorageDB.setItem('FDTConditions', 1);
  } else {
    localStorageDB.setItem('FDTConditions', 0);
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function toggleFDTatl(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.toggleFDTatl', '2024-06-26', 'bldarosa');
  if (e.checked) {
    localStorageDB.setItem('FDTatl', 1);
  } else {
    localStorageDB.setItem('FDTatl', 0);
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 * @param useMax
 */
function ExperienceUpdate(e, useMax = true) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ExperienceUpdate', '2024-06-26', 'bldarosa');
  var maxTime = parseFloat($('#FDT_FTT').val());
  maxTime = round10(maxTime);
  if (useMax) $('#' + e.id).val(maxTime);
  var PIC = parseFloat($('#FDT_PIC').val());
  var SIC = parseFloat($('#FDT_SIC').val());
  var DR = parseFloat($('#FDT_DR').val());
  if (e.id == 'FDT_PIC' || e.id == 'FDT_SIC' || e.id == 'FDT_DR') {
    var Max_PIC_SIC_Dual = PIC + SIC + DR;
    if (Max_PIC_SIC_Dual > maxTime) {
      switch (e.id) {
        case 'FDT_PIC':
          $('#FDT_PIC').val(round10(maxTime - SIC - DR));
          break;
        case 'FDT_SIC':
          $('#FDT_SIC').val(round10(maxTime - PIC - DR));
          break;
        case 'FDT_DR':
          $('#FDT_DR').val(round10(maxTime - PIC - SIC));
          break;
      }
    }
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function ExperienceUpdateFT(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ExperienceUpdateFT', '2024-06-26', 'bldarosa');
  var maxTime = parseFloat($('#FT').val());
  if ($('#FT2').val() != undefined) {
    maxTime += parseFloat($('#FT2').val());
  }
  maxTime = round10(maxTime);
  $('#' + e.id).val(maxTime);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 * @param index
 */
function CheckMaxExperienceFTT(e, index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.CheckMaxExperienceFTT', '2024-06-26', 'bldarosa');
  var maxTime = parseFloat($('#FT').val());
  if ($('#FT2').val() != undefined) {
    maxTime += parseFloat($('#FT2').val());
  }
  maxTime = round10(maxTime);
  var FTT = 0;
  FTT = maxTime;
  for (var i in CurrentFDT_Recs) {
    if (i != index) {
      FTT -= CurrentFDT_Recs[i].FTT;
    }
  }
  FTT = round10(FTT);
  $('#' + e.id).val(FTT);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 * @param index
 */
function ValidateMaxExperienceFTT(e, index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ValidateMaxExperienceFTT', '2024-06-26', 'bldarosa');
  if (GROUP_DATA.FDT_Valid_FT_Off == 1) {
    SaveFDT_Exp(index, false);
    return;
  }
  var maxTime = parseFloat($('#FT').val());
  if ($('#FT2').val() != undefined) {
    maxTime += parseFloat($('#FT2').val());
  }
  maxTime = round10(maxTime);
  var AvailFTT = 0;
  AvailFTT = maxTime;
  for (var i in CurrentFDT_Recs) {
    if (i != index) {
      AvailFTT -= CurrentFDT_Recs[i].FTT;
    }
  }
  var newFTT = round10(parseFloat($('#FDT_FTT').val()));
  AvailFTT = round10(AvailFTT);
  if (newFTT > AvailFTT) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Validation Error'),
      headerClose: false,
      buttonPrompt:
        iTrans('The experience of all aircraft exceeds the total flight time for this day.  There are') +
        ' <b>' +
        round10(AvailFTT) +
        '</b> ' +
        iTrans('experience hours remaining for this day'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
    $('#FDT_FTT').val(AvailFTT);
    return;
  }
  SaveFDT_Exp(index, false);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function ExperienceUpdate1(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ExperienceUpdate1', '2024-06-26', 'bldarosa');
  var curval = $('#' + e.id).val();
  curval++;
  $('#' + e.id).val(curval);
  $('#' + e.id).select();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function removeRoll24() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.removeRoll24', '2024-06-26', 'bldarosa');
  var Legs = '';
  if (ROLL24DATA != undefined && ROLL24DATA != '') {
    var Extra = JSON.parse(ROLL24DATA);
    var Eitems = Extra.FDT135;
    if (Eitems.length > 0) {
      Eitems.pop();
    }
    if (Eitems.length > 0) {
      ROLL24DATA = JSON.stringify({
        FDT135: Eitems,
      });
    } else {
      ROLL24DATA = '';
      ROLL_MIN = $('#Start').val();
    }
  }
  var FT = '0.0';
  var Start = '0000';
  var TotalFT = 0;
  var Reg = 135;
  if (ROLL24DATA != undefined && ROLL24DATA != '') {
    var Extra = JSON.parse(ROLL24DATA);
    var Eitems = Extra.FDT135;
    ROLL_MIN = '0000';
    for (var E in Eitems) {
      if (Eitems[E].Reg != undefined) Reg = Eitems[E].Reg;
      else Eitems[E].Reg = Reg;
      if (E == 0) {
        var Start = parseInt($('#Start').val());
        var firstLeg = parseInt(Eitems[E].Start);
        if (Start > firstLeg) {
          $('#Start').val(Eitems[E].Start);
        }
      }
      if (E != Eitems.length - 1) {
        Legs +=
          '<tr><td>' + Eitems[E].Start + '</td><td>' + Eitems[E].FT + '</td><td>Part ' + Eitems[E].Reg + '</td></tr>';
      } else {
        FT = Eitems[E].FT;
        Start = Eitems[E].Start;
        ROLL_MIN = Start;
      }
      TotalFT += parseFloat(Eitems[E].FT);
    }
  } else {
    ROLL_MIN = $('#Start').val();
  }
  TotalFT = Math.round(TotalFT * 10) / 10;
  maxTime =
    //console.log(ROLL24DATA);
    $('#Roll24').html('');
  var html = '';
  var maxTime = parseFloat($('#FT').val());
  if ($('#Split').is(':checked')) {
    maxTime += parseFloat($('#FT2').val());
  }
  maxTime = Math.round(maxTime * 10) / 10;

  var regOptions = '';
  if (Reg == 91) regOptions += '<option value="91" selected>Part 91</option>';
  else regOptions += '<option value="91">Part 91</option>';
  if (Reg == 133) regOptions += '<option value="133" selected>Part 133</option>';
  else regOptions += '<option value="133">Part 133</option>';
  if (Reg == 135) regOptions += '<option value="135" selected>Part 135</option>';
  else regOptions += '<option value="135">Part 135</option>';
  if (Reg == 137) regOptions += '<option value="137" selected>Part 137</option>';
  else regOptions += '<option value="137">Part 137</option>';

  html += "<div id='Roll24'><b>" + iTrans('Enter Flight Legs') + "<table class='wrap' border='1'>";
  html +=
    '<thead><tr><th>' +
    iTrans('Start') +
    "</th><th><div id='Roll24T'>Flight Duration (" +
    TotalFT +
    '/' +
    maxTime +
    ')</div></th><th>' +
    iTrans('Reg') +
    '</th></tr></thead><tbody>';
  html += Legs;
  html +=
    "<tr><td><input type='number' id='Roll_Start' value='" +
    Start +
    "' onfocus='selectRoll24(this)' onChange='updateRoll24(this)'/></td><td><input type='number' id='Roll_FT' min='0' max='24' value='" +
    FT +
    "' onfocus='selectRoll24(this)' onChange='updateRoll24(this)'/></td>" +
    "<td><select data-role='none' id='Roll_Reg' onChange='updateRoll24(this)'>" +
    regOptions +
    '</select></td></tr>';
  html +=
    "<tr><td><button onClick='removeRoll24()'  data-icon='minus' data-mini='true' class='redbg'>" +
    iTrans('Remove Flight Leg') +
    "</button></td><td><button onClick='addRoll24()' data-icon='plus' data-mini='true' class='greenbg'>" +
    iTrans('Add Flight Leg') +
    '</button></td><td></td></tr>';
  html += '</tbody></table>';
  $('#Roll24').html(html);
  $('#Roll24').enhanceWithin();
  ROLL24TOT = TotalFT;
  Roll24InputType();
  updateFDT_Day_Rec_Modified();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function Roll24InputType() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.Roll24InputType', '2024-06-26', 'bldarosa');
  var type = LOCALSTORAGE.TimeInput;
  if (type == undefined) {
    type = 'Clockpicker';
  }
  if (type == 'Keypad') {
    $.fn.numpad.defaults.gridTpl = '<table class="numpad"></table>';
    $.fn.numpad.defaults.backgroundTpl = '<div class="ui-popup-screen ui-overlay-a"></div>';
    $.fn.numpad.defaults.displayTpl = '<input data-theme="b" type="text"/>';
    $.fn.numpad.defaults.buttonNumberTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.buttonFunctionTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.onKeypadCreate = function () {
      $(this).enhanceWithin();
    };
    $('#Roll_Start').numpad({
      hidePlusMinusButton: true,
      hideDecimalButton: true,
    });
    $('#Roll_FT').numpad({
      hidePlusMinusButton: true,
      hideDecimalButton: false,
    });
    $('#Roll_Start').removeAttr('onFocus');
    $('#Roll_FT').removeAttr('onFocus');
  }
  if (type == 'Clockpicker') {
    $('#Roll_Start').removeAttr('onFocus');
    $('#Roll_FT').removeAttr('onFocus');
    $('#Roll_Start').focus(ClockPickerBlur);
    $('#Roll_FT').focus(ClockPickerBlur);
    $('#Roll_Start').clockpicker({
      autoclose: true,
      align: 'right',
    });
    $('#Roll_FT').clockpicker({
      autoclose: true,
      decimal: true,
    });
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function addRoll24() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.addRoll24', '2024-06-26', 'bldarosa');
  var Legs = '';
  //console.log(ROLL24DATA);
  var TotalFT = 0;
  var nextTime = '0000';
  var Reg = 135;
  if (ROLL24DATA != undefined && ROLL24DATA != '') {
    try {
      var Extra = JSON.parse(ROLL24DATA);
      var Eitems = Extra.FDT135;
      if (Eitems.length > 0) {
        var lastStart = '0000';
        var lastTime = '0.0';
        for (var E in Eitems) {
          if (Eitems[E].Reg != undefined) Reg = Eitems[E].Reg;
          else Eitems[E].Reg = Reg;
          if (E == 0) {
            var Start = parseInt($('#Start').val());
            var firstLeg = parseInt(Eitems[E].Start);
            if (Start > firstLeg) {
              $('#Start').val(Eitems[E].Start);
            }
          }
          TotalFT += parseFloat(Eitems[E].FT);
          if (Eitems[E].FT != '0.0') {
            Legs +=
              '<tr><td>' +
              Eitems[E].Start +
              '</td><td>' +
              Eitems[E].FT +
              '</td><td>Part ' +
              Eitems[E].Reg +
              '</td></tr>';
            lastStart = Eitems[E].Start;
            lastTime = Eitems[E].FT;
          } else {
            Eitems.splice(E, 1);
          }
        }

        nextTime = subDecToTime(lastStart, -parseFloat(lastTime));
        ROLL_MIN = nextTime;
        Extra.FDT135.push({
          Start: nextTime,
          FT: '0.0',
          Reg: Reg,
        });
        ROLL24DATA = JSON.stringify({
          FDT135: Extra.FDT135,
        });
      }
    } catch (e) {
      ROLL24DATA = '';
    }
  }
  $('#Roll24').html('');
  if (parseInt(nextTime) > 2359) {
    nextTime = '2359';
  }
  var html = '';
  TotalFT = Math.round(TotalFT * 10) / 10;
  var maxTime = parseFloat($('#FT').val());
  if ($('#Split').is(':checked')) {
    maxTime += parseFloat($('#FT2').val());
  }
  $('#FDT_FTT').val(maxTime);
  html += "<div id='Roll24'><b>" + iTrans('Enter Flight Legs') + "<table class='wrap' border='1'>";
  maxTime = Math.round(maxTime * 10) / 10;

  var regOptions = '';
  if (Reg == 91) regOptions += '<option value="91" selected>Part 91</option>';
  else regOptions += '<option value="91">Part 91</option>';
  if (Reg == 133) regOptions += '<option value="133" selected>Part 133</option>';
  else regOptions += '<option value="133">Part 133</option>';
  if (Reg == 135) regOptions += '<option value="135" selected>Part 135</option>';
  else regOptions += '<option value="135">Part 135</option>';
  if (Reg == 137) regOptions += '<option value="137" selected>Part 137</option>';
  else regOptions += '<option value="137">Part 137</option>';

  html +=
    '<thead><tr><th>' +
    iTrans('Start') +
    "</th><th><div id='Roll24T'>" +
    iTrans('Flight Duration') +
    ' (' +
    TotalFT +
    '/' +
    maxTime +
    ')</div></th><th>' +
    iTrans('Reg') +
    '</th></tr></thead><tbody>';
  html += Legs;
  html +=
    "<tr><td><input type='number' id='Roll_Start' value='" +
    nextTime +
    "' onfocus='selectRoll24(this)' onChange='updateRoll24(this)'/></td><td><input type='number' id='Roll_FT' min='0' max='24' value='0.0' onfocus='selectRoll24(this)' onChange='updateRoll24(this)'/></td>" +
    "<td><select data-role='none' id='Roll_Reg' onChange='updateRoll24(this)'>" +
    regOptions +
    '</select></td></tr>';
  html +=
    "<tr><td><button onClick='removeRoll24()'  data-icon='minus' data-mini='true' class='redbg'>" +
    iTrans('Remove Flight Leg') +
    "</button></td><td><button onClick='addRoll24()' data-icon='plus' data-mini='true' class='greenbg'>" +
    iTrans('Add Flight Leg') +
    '</button></td><td></td></tr>';
  html += '</tbody></table>';
  $('#Roll24').html(html);
  $('#Roll24').enhanceWithin();
  ROLL24TOT = TotalFT;
  var EndTime = parseInt($('#End').val());
  var EndTime2 = parseInt($('#End2').val());
  var checkEnd = parseInt(nextTime);
  if ($('#Split').is(':checked')) {
    if (EndTime2 < checkEnd) {
      $('#End2').val(nextTime);
    }
  } else {
    if (EndTime < checkEnd) {
      $('#End').val(nextTime);
    }
  }
  Roll24InputType();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function updateFDT_Day_Rec_Modified() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.updateFDT_Day_Rec_Modified', '2024-06-26', 'bldarosa');
  var Sdate = GetCurFDT_Date_String();
  var record = {};
  record.Date = Sdate;
  //console.log(record);
  record.Modified = dateToStringObject(new Date());
  var table = '';

  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }

  cirroDB.insert(table, record, null, function (e) {
    console.log('Updated: ' + Sdate + ' Modified Timestamp');
    update_Sync('LastFDTsync', new Date());
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function selectRoll24(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.selectRoll24', '2024-06-26', 'bldarosa');
  $('#' + e.id).select();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function updateRoll24(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.updateRoll24', '2024-06-26', 'bldarosa');
  var field = e.id;

  if (field == 'Roll_Start') {
    if (!validateTime($('#Roll_Start').val(), 'Roll_Start')) {
      return;
    }
  }
  if ($('#Split').is(':checked')) {
    var maxTime = parseFloat($('#FT').val()) + parseFloat($('#FT2').val()) - parseFloat(ROLL24TOT);
  } else {
    var maxTime = parseFloat($('#FT').val());
  }

  maxTime = Math.round(maxTime * 10) / 10;

  if (field == 'Roll_FT' && parseFloat($('#Roll_FT').val()) < 0) {
    $('#Roll_FT').val(0);
  }
  if (field == 'Roll_Start' || field == 'Roll_FT' || field == 'Roll_Reg') {
    if (parseFloat($('#Roll_FT').val()) > 24) {
      $('#Roll_FT').val(24);
    }
    if (parseInt($('#Roll_Start').val()) > 2359) {
      $('#Roll_Start').val('2359');
    }
    var Legs = '';
    var LegTime = parseInt($('#Roll_Start').val());
    //console.log("LegTime: " + LegTime);
    //console.log("RollMin: " + ROLL_MIN);
    if (parseInt(ROLL_MIN) > LegTime) {
      $('#Roll_Start').val(ROLL_MIN);
    }

    if (ROLL24DATA == undefined || ROLL24DATA == '') {
      ROLL24DATA = JSON.stringify({
        FDT135: [
          {
            Start: $('#Roll_Start').val(),
            FT: $('#Roll_FT').val(),
            Reg: 135,
          },
        ],
      });
    } else {
      var Extra = JSON.parse(ROLL24DATA);

      Extra.FDT135[Extra.FDT135.length - 1] = {
        Start: $('#Roll_Start').val(),
        FT: $('#Roll_FT').val(),
        Reg: parseInt($('#Roll_Reg').val()),
      };
      ROLL24DATA = JSON.stringify({
        FDT135: Extra.FDT135,
      });
      //console.log(ROLL24DATA);
    }
  }
  var TotalFT = 0;
  if (ROLL24DATA != undefined && ROLL24DATA != '') {
    var Extra = JSON.parse(ROLL24DATA);
    var Eitems = Extra.FDT135;
    for (var E in Eitems) {
      if (E == Eitems.length - 1) {
        break;
      }
      TotalFT += parseFloat(Eitems[E].FT);
    }
  }
  if (field == 'Roll_FT') {
    TotalFT += parseFloat($('#Roll_FT').val());
    if (field == 'Roll_FT' && parseFloat(TotalFT) > maxTime) {
      maxTime = Math.round(TotalFT * 10) / 10;
      if ($('#Split').is(':checked')) {
        $('#FT2').val(maxTime);
      } else {
        $('#FT').val(maxTime);
      }
    }
    TotalFT = Math.round(TotalFT * 10) / 10;
    $('#Roll24T').html(iTrans('Flight Duration') + ' (' + TotalFT + '/' + maxTime + ')');
    //addRoll24();
  }
  updateFDT_Day_Rec_Modified();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function validateHours(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.validateHours', '2024-06-26', 'bldarosa');
  if (e.value > 24) {
    $('#' + e.id).val(24);
  }
  if (e.value < 0) {
    $('#' + e.id).val(0);
  }
}

function validateTime(e, id, nofocus, allowBlank = 0) {
  nofocus = nofocus || false;
  e = e.replace(':', ''); //strip colons in time
  if (allowBlank == 1 && e == '') return true;
  $('#' + id).val(e); //populate input again without colon
  if (isNaN(e)) {
    $('#' + id).val('0000');
    return false;
  }
  if (e == '') {
    $('#' + id).val('0000');
    if (!nofocus) {
      $('#' + id).focus();
    }
    return false;
  }
  if (e.length > 4) {
    $('#' + id).val('0000');
    if (!nofocus) {
      $('#' + id).focus();
    }
    return false;
  }
  if (e < 0) {
    $('#' + id).val('0000');
    if (!nofocus) {
      $('#' + id).focus();
    }
    return false;
  }
  // //console.log(e.length);
  if (e.length == 4) {
    //console.log(e.substring(2, 4));
    //console.log(e.substring(0, 2));
    $('#' + id).val(validateF2(e.substring(0, 2)) + validateL2(e.substring(2, 4)));
  }
  if (e.length == 3) {
    $('#' + id).val(validateF2(e.substring(0, 1)) + validateL2(e.substring(1, 3)));
  }
  if (e.length == 2 || e.length == 1) {
    $('#' + id).val('00' + validateL2(e));
  }
  return true;
}

function validateF2(F2) {
  // //console.log(F2);
  if (F2 > 23) {
    return '23';
  } else if (F2 < 10) {
    return '0' + parseInt(F2);
  } else if (F2 == 0) {
    return '00';
  } else {
    return F2.toString();
  }
}

function validateL2(L2) {
  // //console.log(L2);
  if (L2 > 59) {
    return '59';
  } else if (L2 < 10 && L2 != 0) {
    return '0' + parseInt(L2);
  } else if (L2 == 0) {
    return '00';
  } else {
    return L2.toString();
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function FDT_UpdateWorkStats() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_UpdateWorkStats', '2024-06-26', 'bldarosa');
  var TotalHours =
    dutytime_Calc($('#Start').val(), $('#End').val()) + dutytime_Calc($('#Start2').val(), $('#End2').val());
  var UnpaidHours = parseFloat($('#FDT_Unpaid').val());
  if (UnpaidHours > TotalHours) {
    alert(iTrans('Unpaid Hours cannot be greater than Total Hours. Resetting Unpaid to 0.'));
    UnpaidHours = 0;
    $('#FDT_Unpaid').val(0);
  }
  var TotalWorked = round10(TotalHours - UnpaidHours);
  var Overtime = round10(parseFloat($('#FDT_Overtime').val()));
  if (Overtime > TotalWorked) {
    alert(iTrans('Overtime Hours cannot be greater than Total Worked Hours.  Resetting Overtime to 0.'));
    Overtime = 0;
    $('#FDT_Overtime').val(0);
  }
  var TotalRegular = round10(TotalWorked - Overtime);

  var html = '<b>' + iTrans('Total Hours') + ': </b>' + TotalHours + '<br />';
  html += '<b>' + iTrans('Unpaid Hours') + ': </b>' + UnpaidHours + '<br />';
  html += '<b>' + iTrans('Total Worked Hours') + ': </b>' + TotalWorked + '<br />';
  html += '<b>' + iTrans('Paid Regular Hours') + ': </b>' + TotalRegular + '<br />';
  html += '<b>' + iTrans('Paid Overtime Hours') + ': </b>' + Overtime + '<br />';
  $('#FDT_WorkStats').html(html);
  $('#FDT_WorkStats2').html(html);
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

var FDT_CUSTOM_RULES_LOADED = false;

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function initDutyTimes() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.initDutyTimes', '2024-06-26', 'bldarosa');
  if (FDT_CUSTOM_RULES_LOADED == false) {
    LoadFDTRules();
  }
  localStorageDB.getItem('Specialty', function (e) {
    if (e == 1) {
      $('#CHK_Specialty').prop('checked', true);
    }
  });

  localStorageDB.getItem('FDTConditions', function (e) {
    if (e == 1) {
      $('#CHK_FDTConditions').prop('checked', true);
    }
  });

  localStorageDB.getItem('FDTatl', function (e) {
    if (e == 1) {
      $('#CHK_FDTatl').prop('checked', true);
    }
  });

  localStorageDB.getItem('ClockPicker', function (e) {
    if (e == 1) {
      $('#CHK_ClockPicker').prop('checked', true);
    }
  });

  if (GROUP_DATA.FlightReports == 1) {
    GetAllJobs(); //populate all jobs for JobNum Lookups
  }
  GetCurrency_RecData(Rule, true);
  if (USER_RIGHTS.FDT_NvgCur == 1 || (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)) {
    CheckNVGCurrency(new Date(), true);
  }
  if (USER_RIGHTS.FDT_IfrCur == 1 || (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1)) {
    CheckIFRCurrency(new Date(), true);
  }
  DUTYTIME = 'Calendar';
  $('#calendar').html(''); //empty calendar div
  //get aircraft list
  var ALLAC_data2 = [];

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    var data = e;
    if (e === false) {
      data = [];
    } else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data2.push(item);
      }
    }
    ALLAC_data = ALLAC_data2;
    var AClist = '';
    for (i in ALLAC_data) {
      AClist += "<option value='" + ALLAC_data[i].ident + "' >" + ALLAC_data[i].ident + '</option>';
    }
    FDTacList = AClist;
    display_Data();
  });
}

function initDutyTimesV4() {
  if (FDT_CUSTOM_RULES_LOADED == false) {
    LoadFDTRules();
  }

  DUTYTIME = 'Calendar';

  var ALLAC_data2 = [];

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    var data = e;
    if (e === false) {
      data = [];
    } else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data2.push(item);
      }
    }
    ALLAC_data = ALLAC_data2;
    var AClist = '';
    for (i in ALLAC_data) {
      AClist += "<option value='" + ALLAC_data[i].ident + "' >" + ALLAC_data[i].ident + '</option>';
    }
    FDTacList = AClist;
    checkLastFDTdateV4(); //Check last date in duty times is today
  });
}

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 */
var PBAR;

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 * @type {boolean}
 */
var PBAR_Close = false;

var FDTacList;

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Title
 * @param Message
 * @param Max
 * @param unknown
 */
function progressbar(Title, Message, Max, unknown) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.progressbar', '2024-06-26', 'bldarosa');
  PBAR = jQMProgressBar('ProgressBar')
    .setOuterTheme('b')
    .setInnerTheme('e')
    .isMini(true)
    .setMax(Max)
    .setStartFrom(0)
    .showCounter(true)
    .isIndefinite(unknown)
    .build();
  $('#Duty_Status').html(Title + '<br />' + Message);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param AClist
 */
function display_Data(AClist) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.display_Data', '2024-06-26', 'bldarosa');
  $('#calendar').show(); //show calendar div
  $('#calendar').addClass('limit-table');
  //console.log("Successfully Opened for Calendar");
  if (FDT_FORCE_SYNC) {
    ForceStart_FDTCalendar = true;
    syncFDT();
  } else {
    checkLastFDTdate(); //Check last date in duty times is today
  }
}

function changeDefault2(e) {
  var id = e.currentTarget.id;
  ////console.log(e);

  switch (id) {
    case 'CHK_Standby2':
      if (this.checked) {
        Standby = 1;
      } else {
        Standby = 0;
      }
      break;
    case 'CHK_IFR2':
      if (this.checked) {
        IFR = 1;
      } else {
        IFR = 0;
      }
      break;
    case 'CHK_Heli2':
      if (this.checked) {
        $('#CHK_DHC2').prop('checked', false);
        $('#CHK_DHC2').prop('disabled', true).checkboxradio('refresh');

        Heli = 1;
        DHC = 0;
      } else {
        $('#CHK_DHC2').prop('disabled', false).checkboxradio('refresh');
        Heli = 0;
      }
      break;
    case 'CHK_DHC2':
      if (this.checked) {
        $('#CHK_Heli2').prop('checked', false);
        $('#CHK_Logging2').prop('checked', false);
        $('#CHK_Heli2').prop('disabled', true).checkboxradio('refresh');
        $('#CHK_Logging2').prop('disabled', true).checkboxradio('refresh');

        DHC = 1;
        Heli = 0;
        Logging = 0;
      } else {
        $('#CHK_Heli2').prop('disabled', false).checkboxradio('refresh');
        $('#CHK_Logging2').prop('disabled', false).checkboxradio('refresh');
        DHC = 0;
      }
      break;
    case 'CHK_Dual2':
      if (this.checked) {
        Dual = 1;
      } else {
        Dual = 0;
      }
      break;
    case 'CHK_Logging2':
      if (this.checked) {
        $('#CHK_DHC2').prop('checked', false);
        $('#CHK_DHC2').prop('disabled', true).checkboxradio('refresh');

        Logging = 1;
        DHC = 0;
      } else {
        $('#CHK_DHC2').prop('disabled', false).checkboxradio('refresh');
        Logging = 0;
      }
      break;
    case 'CHK_AerialApp2':
      if (this.checked) {
        Rule = 702;
        AerialApp = 1;
        $('#SELECT_Rule2').val(Rule).selectmenu('refresh');
      } else {
        AerialApp = 0;
      }
      break;
    case 'SELECT_Rule2':
      Rule = $('#SELECT_Rule2').val();
      //console.log("Rule:" + Rule);
      break;
    case 'SELECT_Rule_ID2':
      Rule_ID = $('#SELECT_Rule_ID2').val();
      break;
    case 'FDT_DefaultTravelTime':
      FDTdefault_travelTime = $('#FDT_DefaultTravelTime').val();
      if (FDTdefault_Medevac == 0 && FDTdefault_travelTime > 1) {
        $('#FDT_DefaultTravelTime').val(1);
        FDTdefault_travelTime = 1;
      }
      if (FDTdefault_Medevac == 1 && FDTdefault_travelTime > 24) {
        $('#FDT_DefaultTravelTime').val(24);
        FDTdefault_travelTime = 24;
      }
      if (FDTdefault_travelTime < 0) {
        $('#FDT_DefaultTravelTime').val(0);
        FDTdefault_travelTime = 0;
      }
      break;
    case 'SELECT_AvgFlt2':
      AverageFlightTime = $('#SELECT_AvgFlt2').val();
      break;
    case 'SELECT_TimezoneHB2':
      FDTdefault_Timezone = $('#SELECT_TimezoneHB2').val();
      break;
    case 'CHK_Medevac2':
      FDTdefault_Medevac = $('#CHK_Medevac2').is(':checked') ? 1 : 0;
      if (FDTdefault_Medevac == 1) {
        $('#FDT_DefaultTravelTimeLBL').html(iTrans('Travel Time to Suitable Accomodations (hrs)'));
      } else {
        $('#FDT_DefaultTravelTimeLBL').html(iTrans('Travel Time to Home Base (1 hr max)'));
      }
      break;
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function checkLastFDTdate() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.checkLastFDTdate', '2024-06-26', 'bldarosa');
  //SELECT * FROM tablename ORDER BY column DESC LIMIT 1;

  var today = moment().utc().startOf('d');

  var table = '';
  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }

  cirroDB.query(table, 'Date != ? ORDER BY Date DESC LIMIT 1', [''], function (e) {
    var result = e;
    if (result === false || result.length == 0) {
      console.log('No More Records');
      var recdate = new Date();
      recdate.setDate(recdate.getDate() - 30);
      findMissingDates(recdate);
    } else {
      //plugin.settings.events.push(result.value);
      var data = result[0];
      var recdate = new Date(data.Date + 'T12:00:00Z');
      //alert(recdate);
      //Set next insert defaults;
      Standby = 0;
      IFR = data.IFR;
      Heli = data.Heli;
      DHC = data.DHC;
      Dual = data.Dual;
      Logging = data.Logging;
      AerialApp = data.AerialApp;
      FDTdefault_travelTime = data.travelTime;
      FDTdefault_Medevac = data.Medevac;
      AverageFlightTime = data.ET2;
      FDTdefault_Timezone = data.TimezoneEnd;
      Rule = SetDefaultFDT_Rule(data.Rule); //in sync-IOS-FDT.js
      $('#SELECT_Rule').val(Rule);
      Rule_ID = data.Rule_ID;

      //Set default FDT rules here Initially instead of daily now
      if (IFR == 1) {
        $('#CHK_IFR').prop('checked', true);
      } else {
        $('#CHK_IFR').prop('checked', false);
      }
      if (Heli == 1) {
        $('#CHK_Heli').prop('checked', true);
      } else {
        $('#CHK_Heli').prop('checked', false);
      }
      if (DHC == 1) {
        $('#CHK_DHC').prop('checked', true);
      } else {
        $('#CHK_DHC').prop('checked', false);
      }
      if (Dual == 1) {
        $('#CHK_Dual').prop('checked', true);
      } else {
        $('#CHK_Dual').prop('checked', false);
      }
      if (Logging == 1) {
        $('#CHK_Logging').prop('checked', true);
      } else {
        $('#CHK_Logging').prop('checked', false);
      }
      if (AerialApp == 1 && Rule == 702) {
        $('#CHK_AerialApp').prop('checked', true);
      } else {
        $('#CHK_AerialApp').prop('checked', false);
      }

      if (recdate >= today) {
        StartFDTCalender();
      } else {
        findMissingDates(recdate);
      }
    }
  });
}

function checkLastFDTdateV4() {
  //SELECT * FROM tablename ORDER BY column DESC LIMIT 1;

  var today = moment.utc().startOf('day').toDate();
  var table = '';

  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }
  cirroDB.query(table, 'Date != ? ORDER BY Date DESC LIMIT 1', [''], function (result) {
    if (!Array.isArray(result) || result.length <= 0) {
      console.log('No More Records');
      var recdate = moment.utc().subtract(30, 'd').toDate();
      findMissingDatesV4(recdate);
      return;
    }

    var data = result[0];
    var recdate = moment.utc(data.Date).startOf('day').toDate();
    console.log('Loading Default FDT Rec Settings', data);

    // Set next insert defaults;
    Standby = 0;
    IFR = data.IFR;
    Heli = data.Heli;
    DHC = data.DHC;
    Dual = data.Dual;
    Logging = data.Logging;
    AerialApp = data.AerialApp;
    TimezoneHB = data.TimezoneHB;
    FDTdefault_homeBase = data.homeBase;
    FDTdefault_homeBase2 = data.homeBase2;
    FDTdefault_Timezone = data.Timezone;
    FDTdefault_travelTime = data.travelTime;
    FDTdefault_Sectors = data.Sectors;
    FDTdefault_Medevac = data.medevac;

    Rule = SetDefaultFDT_Rule(data.Rule); //in sync-IOS-FDT.js
    Rule_ID = data.Rule_ID;
    AverageFlightTime = data.ET2;
    setFDT_Defaults();

    if (recdate > today) {
      console.log('Starting Calendar', recdate, '>', today);
      StartFDTCalenderV4();
    } else {
      console.log('Find Missing Dates', recdate, '>', today);
      findMissingDatesV4(recdate);
    }
  });
}

var missingFDT_data = [];
var missingFDT_data2 = [];
var ForceStart_FDTCalendar = false;

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param LastDate
 */
function findMissingDates(LastDate) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.findMissingDates', '2024-06-26', 'bldarosa');
  update_Sync('LastFDTsync', new Date());
  if (ONLINE && ForceStart_FDTCalendar == false) {
    //trigger background sync and message user to try again after sync.
    $('#calendar').html('<h3>' + iTrans('Synchronizing Data Please Standby') + '...</h3>');
    ForceStart_FDTCalendar = true;
    BackgroundSync(true); // to sync potential changes to the server;
  } else {
    ForceStart_FDTCalendar = false;
    var today = moment().utc().startOf('d');

    var modified = new Date();
    modified.setMilliseconds(0); //to prevent problems with syncing
    modified.setFullYear(modified.getFullYear() - 100);

    var AddedDates = false;
    while (LastDate <= today) {
      //console.log("Inserting New Date");
      LastDate.setDate(LastDate.getDate() + 1);
      var SetLastDate = new Date(LastDate);
      var record;
      var record2 = {};
      var NewPK = USER_PK;
      if (FDT_ADMIN_UPDATE) NewPK = FDT_OPK;
      if (userShouldHaveFDT(USERTYPE)) {
        record = Blank_FDT_DaysV4(NewPK, SetLastDate.toISOString().substr(0, 10));

        record.Modified = dateToStringObject(modified);
      } else {
        record = Blank_FDT_StaffV3(NewPK, SetLastDate.toISOString().substr(0, 10));
        record.Modified = dateToStringObject(modified);
      }
      missingFDT_data.push(record);
      missingFDT_data2.push(record2);

      AddedDates = true;

      if (LastDate < today) {
        addMissingDates(record, false);
      } else {
        addMissingDates(record, true);
      }
    }

    if (!AddedDates) {
      StartFDTCalender();
    }
  }
}

function findMissingDatesV4(LastDate) {
  update_Sync('LastFDTsync', new Date());
  if (ONLINE && ForceStart_FDTCalendar == false && !FDT_ADMIN_UPDATE) {
    //trigger background sync and message user to try again after sync.
    ForceStart_FDTCalendar = true;
    BackgroundSync(true); // to sync potential changes to the server;
  } else {
    ForceStart_FDTCalendar = false;
    var today = moment.utc().startOf('d');

    var modified = new Date();
    modified.setMilliseconds(0); //to prevent problems with syncing
    modified.setFullYear(modified.getFullYear() - 100);
    var AddedDates = false;

    while (LastDate <= today) {
      LastDate.setDate(LastDate.getDate() + 1);
      var SetLastDate = new Date(LastDate);
      var record;
      var record2 = {};
      var NewPK = USER_PK;
      if (FDT_ADMIN_UPDATE) NewPK = FDT_OPK;
      if (userShouldHaveFDT(USERTYPE)) {
        record = Blank_FDT_DaysV4(NewPK, SetLastDate.toISOString().substr(0, 10));
        record.Modified = dateToStringObject(modified);
      } else {
        record = Blank_FDT_StaffV3(NewPK, SetLastDate.toISOString().substr(0, 10));
        record.Modified = dateToStringObject(modified);
      }
      missingFDT_data.push(record);
      missingFDT_data2.push(record2);
      AddedDates = true;
    }

    addMissingDatesV4(missingFDT_data);

    if (!AddedDates) {
      StartFDTCalenderV4();
    }
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param FDT_rec
 * @param last
 */
function addMissingDates(FDT_rec, last) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.addMissingDates', '2024-06-26', 'bldarosa');
  var table = '';

  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }

  cirroDB.insert(table, FDT_rec, null, function (e) {
    if (last) {
      //console.log("All Missing Dates Added");
      StartFDTCalender();
    }
  });
}

function addMissingDatesV4(missingDates) {
  var table = '';

  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }

  cirroDB.insert(table, missingDates, null, function (e) {
    console.log('All Missing Dates Added.  Last Added: ' + missingDates.pop().Date);
    StartFDTCalenderV4();
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function StartFDTCalender() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.StartFDTCalender', '2024-06-26', 'bldarosa');
  ForceStart_FDTCalendar = false; //so this doesn't ever get stuck
  $('#calendar').jqmCalendar({
    events: [],
    AClist: FDTacList,
    //Comment: "TestDefaultComment",
    db: null,
    months: [
      iTrans('January'),
      iTrans('February'),
      iTrans('March'),
      iTrans('April'),
      iTrans('May'),
      iTrans('June'),
      iTrans('July'),
      iTrans('August'),
      iTrans('September'),
      iTrans('October'),
      iTrans('November'),
      iTrans('December'),
    ],
    days: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    startOfWeek: 0,
  });
}

function StartFDTCalenderV4() {
  ForceStart_FDTCalendar = false; //so this doesn't ever get stuck
  if (FDT_MODULE_VERSION == 3) {
    StartFDTCalender();
  } else {
    if (FDT_ADMIN_UPDATE) {
      VueBridge.enterFlightDuty(ADMIN_FDT_DATA.returnpage);
    } else {
      VueBridge.enterFlightDuty('MainMenu');
    }
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function populateExperience() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.populateExperience', '2024-06-26', 'bldarosa');
  if (ONLINE) {
    $.getJSON(BASEURL + 'sync-AJAX.php?action=getExperienceV3&RecOnly=0&OPK=' + FDT_OPK, function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data.ServerResponse == 'Success') {
        DisplayExperience(json_data);
      } else {
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'getSpots');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      }
    }).fail(function (jqXHR, status, error) {
      AJAXFAIL(error);
    });
  } else {
    $('#ExperienceTrackingHLDR').html('<h1>' + iTrans('Experience Tracking not Available Offline') + '</h1>');
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Date
 */
function populateFlightReportDetails(Date) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.populateFlightReportDetails', '2024-06-26', 'bldarosa');
  $('#TodayBillingData').html('');
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'menu-Billing-AJAX.php?action=getDutyTimes&Date=' +
        Date +
        '&OPK=' +
        (FDT_ADMIN_UPDATE === true ? FDT_OPK : 0),
      function (json_data) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Success') {
          var data = json_data[0]['Report'];
          var html = '<h4>Flight Report Data Preview</h4>';
          if (data.OvernightReport != undefined) {
            if (data.OvernightReport.Overnight == true) {
              html +=
                '<b>' +
                iTrans('Note') +
                ': </b>' +
                iTrans('One or more flight reports has overnight data') +
                '.  ' +
                iTrans('The times listed will reflect flight times up to midnight on this reporting day') +
                '.<br /><br />';
            }
          }
          var ReportItems = '';
          if (data.FR.FR.length > 0) {
            for (var i in data.FR.FR) {
              var I = data.FR.FR[i];
              var ref = '<a id="DTFR' + i + '" href="#" onclick="ViewFlightReportPK(' + I + ');">#' + I + '</a>, ';
              ReportItems += ref;
            }
            if (userShouldHaveFDT(USERTYPE)) {
              if (HOBBS == 1) {
                html +=
                  "<table class='limit-table wrap'><thead><tr><th>" +
                  iTrans('Reports') +
                  '</th><th>PIC</th><th>SIC</th><th>Rev</th><th>NonRev</th><th>Hobbs</th><th>' +
                  iTrans('Total') +
                  '</th></thead><tbody>';
                html +=
                  '<tr><td>' +
                  ReportItems +
                  '</td><td>' +
                  round10(data.PIC) +
                  '</td><td>' +
                  round10(data.SIC) +
                  '</td><td>' +
                  round10(data.FR.Revenue) +
                  '</td><td>' +
                  round10(data.FR.NonRevenue) +
                  '</td><td>' +
                  round10(data.FR.HobbsHours) +
                  '</td><td>' +
                  round10(data.FR.Total) +
                  '</td></tr></tbody></table>';
              } else {
                html +=
                  '<table><thead><tr><th>' +
                  iTrans('Reports') +
                  '</th><th>PIC</th><th>SIC</th><th>Rev</th><th>NonRev</th><th>' +
                  iTrans('Total') +
                  '</th></thead><tbody>';
                html +=
                  '<tr><td>' +
                  ReportItems +
                  '</td><td>' +
                  round10(data.PIC) +
                  '</td><td>' +
                  round10(data.SIC) +
                  '</td><td>' +
                  round10(data.FR.Revenue) +
                  '</td><td>' +
                  round10(data.FR.NonRevenue) +
                  '</td><td>' +
                  round10(data.FR.Total) +
                  '</td></tr></tbody></table>';
              }
              html +=
                '<button data-icon="bars" onClick="BI_Get_DutyTimes(\'' +
                Date +
                '\');" data-mini="true" >Import Times from Flight Reports</button>';
            } else {
              html +=
                '<table><thead><tr><th>' +
                iTrans('Reports') +
                '</th><th>Rev</th><th>NonRev</th><th>' +
                iTrans('Total') +
                '</th></thead><tbody>';
              html +=
                '<tr><td>' +
                ReportItems +
                '</td><td>' +
                round10(data.FR.Revenue) +
                '</td><td>' +
                round10(data.FR.NonRevenue) +
                '</td><td>' +
                round10(data.FR.Total) +
                '</td></tr></tbody></table>';
            }
          } else {
            var html = '<h3>' + iTrans('No Flight Report Data For This Date') + '</h3>';
          }
          $('#TodayBillingData').html(html);
          $('#TodayBillingData').enhanceWithin();
        } else {
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            ServerResponse(json_data[0], 'getSpots');
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
          $('#TodayBillingData').html('<h3>' + iTrans('Flight Report Details not Available Offline') + '</h3>');
        }
      }
    ).fail(function (jqXHR, status, error) {
      AJAXFAIL(error);
    });
  } else {
    $('#TodayBillingData').html('<h3>' + iTrans('Flight Report Details not Available Offline') + '</h3>');
  }
}

var ExitShowCurrentFlightReport = 'DutyTimes';

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param PK
 */
function ViewFlightReportPK(PK) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ViewFlightReportPK', '2024-06-26', 'bldarosa');
  $.mobile.loading('show', {
    theme: 'a',
  });
  ExitShowCurrentFlightReport = 'DutyTimes';
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getFR&PK=' + PK, function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    $.mobile.loading('hide');
    if (json_data[0].ServerResponse == 'Success') {
      CURRENT_FLIGHT_REPORT = json_data[0]['Report'];
      if (CURRENT_FLIGHT_REPORT.Crew != '') {
        if (Array.isArray(CURRENT_FLIGHT_REPORT.Crew)) {
          BILLING_FLIGHTREPORT_CREW = CURRENT_FLIGHT_REPORT.Crew;
        } else {
          BILLING_FLIGHTREPORT_CREW = JSON.parse(CURRENT_FLIGHT_REPORT.Crew);
          CURRENT_FLIGHT_REPORT.Crew = BILLING_FLIGHTREPORT_CREW;
        }
      } else {
        BILLING_FLIGHTREPORT_CREW = [];
        CURRENT_FLIGHT_REPORT.Crew = [];
      }
      ShowCurrentFlightReport();
    } else {
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'getSpots');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
      alert(iTrans('Access Denied: You do not have permission to view this Flight Report'));
    }
  }).fail(function (jqXHR, status, error) {
    $.mobile.loading('hide');
    AJAXFAIL(error);
  });
}

function exitShowCurrentFlightReport() {
  $('#exitSubReportPage').unbind('click', exitShowCurrentFlightReport);
  $.mobile.changePage($('#' + ExitShowCurrentFlightReport), {
    reverse: true,
    changeHash: false,
  });
}

function ShowCurrentFlightReport() {
  //console.log("show FR");
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html(iTrans('Flight Report'));
  $('#exitSubReportPage').click(exitShowCurrentFlightReport);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  var clientPK = 0;
  if (CURRENT_FLIGHT_REPORT.Job.customCompany == 1) {
    clientPK = CURRENT_FLIGHT_REPORT.Job.ClientPK;
  }
  cirroDB.query('BillingClient', 'clientPK = ?', clientPK, function (json_data) {
    var headerData = makeHeaderData(CURRENT_FLIGHT_REPORT, json_data[0].data);
    //console.log(json_data);
    //MAKE FLIGHT REPORT
    CURRENT_FLIGHT_REPORT.BLURB = json_data[0].data.flight_report_blurb;
    CURRENT_FLIGHT_REPORT.headerData = headerData;
    //CURRENT_FLIGHT_REPORT contains all flight report, job and flight legs for review.
    //console.log(CURRENT_FLIGHT_REPORT);
    var FlightReport = BILLING_FLIGHT_REPORT_HTML(
      CURRENT_FLIGHT_REPORT,
      true,
      true,
      CURRENT_FLIGHT_REPORT.BLURB,
      CURRENT_FLIGHT_REPORT.headerData
    );
    $('#Sub_Report_Content').html(FlightReport);
    $('#Sub_Report_Content').enhanceWithin();
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param data
 */
function DisplayExperience(data) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.DisplayExperience', '2024-06-26', 'bldarosa');
  var html = '<br />';
  html += '<h1>' + iTrans('Update Initial Experience Tracking') + '</h1>';
  html +=
    '<h3>' +
    iTrans('Note') +
    ':  ' +
    iTrans(
      'All time entered will be used as a baseline value.  All entries made in the flight duty times module with an associated aircraft ident will have that time applied to the baseline for total time and time on type'
    ) +
    '.<br />' +
    iTrans('TOTAL TIME = INITIAL EXPERIENCE + FDT GENERATED EXPERIENCE') +
    '</h3>';
  html +=
    '<label for="EXP_FromDate">' +
    iTrans('Experience As Of') +
    '</label><input type="date" id="EXP_FromDate" onChange="verifyEAOdate()" />';
  html +=
    '<div id="EXP_IPC_DateHLDR"><label for="EXP_IPC_Date">' +
    iTrans('Date of last Instrument Proficiency Check') +
    '</label><input type="date" id="EXP_IPC_Date" /></div>';
  html +=
    '<div id="EXP_NVIS_Initial_DateHLDR"><label for="EXP_NVIS_Initial_Date">' +
    iTrans('Date of Initial NVG Competancy Flight') +
    '</label><input type="date" id="EXP_NVIS_Initial_Date" /></div>';
  html +=
    '<div id="EXP_NVIS_DateHLDR"><label for="EXP_NVIS_Date">' +
    iTrans('Date of last NVG Competancy Flight') +
    '</label><input type="date" id="EXP_NVIS_Date" /><div id="EXP_NVIS_GndHLDR"><label for="EXP_NVIS_Gnd_Date">' +
    iTrans('Date of last NVG Ground Briefing') +
    '</label><input type="date" id="EXP_NVIS_Gnd_Date" /></div></div>';
  if (data.Experience == null) {
    data.Experience = {
      PrimaryKey: 0,
      User_PK: 0,
      FromDate: '2000-01-01',
      IPC_Date: '2000-01-01',
      NVIS_Date: '2000-01-01',
      NVIS_Gnd_Date: '2000-01-01',
      NVIS_Initial_Date: '2000-01-01',
      TypeExperience: '',
      DR: 0,
      PIC: 0,
      SIC: 0,
      FI: 0,
      DAY: 0,
      NIGHT: 0,
      XC: 0,
      AI: 0,
      SI: 0,
      GA: 0,
      DAT: 0,
      NT: 0,
      IA: 0,
      DL: 0,
      NL: 0,
      LL: 0,
      WB: 0,
      MF: 0,
      OT: 0,
      FTO: 0,
      FLD: 0,
      RGR: 0,
      NVG_HRS: 0,
      NVG_OPS: 0,
      NVG_TO: 0,
      NVG_LD: 0,
      NVG_HOV: 0,
      NVG_DEP: 0,
      NVG_ARR: 0,
      NVG_TRAN: 0,
      CS1: 0,
      CS2: 0,
      CS3: 0,
      CS4: 0,
      CS5: 0,
      CS6: 0,
      CS7: 0,
      CS8: 0,
      CS9: 0,
      CS10: 0,
      CS11: 0,
      CS12: 0,
      CS13: 0,
      CS14: 0,
      CS15: 0,
      CS16: 0,
      CS17: 0,
      CS18: 0,
      CS19: 0,
      CS20: 0,
      CS21: 0,
      CS22: 0,
      CS23: 0,
      CS24: 0,
      CS25: 0,
      CS26: 0,
      CS27: 0,
      CS28: 0,
      CS29: 0,
      CS30: 0,
      CS31: 0,
      CS32: 0,
      CS33: 0,
      CS34: 0,
      CS35: 0,
      CS36: 0,
      CS37: 0,
      CS38: 0,
      CS39: 0,
      CS40: 0,
      CS41: 0,
      CS42: 0,
      CS43: 0,
      CS44: 0,
      CS45: 0,
      CS46: 0,
      CS47: 0,
      CS48: 0,
      CS49: 0,
      CS50: 0,
      CS51: 0,
      CS52: 0,
      CS53: 0,
      CS54: 0,
      CS55: 0,
      CS56: 0,
      CS57: 0,
      CS58: 0,
      CS59: 0,
      CS60: 0,
      CS61: 0,
      CS62: 0,
      CS63: 0,
      CS64: 0,
      CS65: 0,
      CS66: 0,
      CS67: 0,
      CS68: 0,
      CS69: 0,
      CS70: 0,
      CS71: 0,
      CS72: 0,
      CS73: 0,
      CS74: 0,
      CS75: 0,
      CS76: 0,
      CS77: 0,
      CS78: 0,
      CS79: 0,
      CS80: 0,
      CS81: 0,
      CS82: 0,
      CS83: 0,
      CS84: 0,
      CS85: 0,
      CS86: 0,
      CS87: 0,
      CS88: 0,
      CS89: 0,
      CS90: 0,
      CS91: 0,
      CS92: 0,
      CS93: 0,
      CS94: 0,
      CS95: 0,
      CS96: 0,
      CS97: 0,
      CS98: 0,
      CS99: 0,
      CS100: 0,
      CNT1: 0,
      CNT2: 0,
      CNT3: 0,
      CNT4: 0,
      CNT5: 0,
      CNT6: 0,
      CNT7: 0,
      CNT8: 0,
      CNT9: 0,
      CNT10: 0,
    };
  }
  var D = data.Experience;

  var Aeroplanes =
    '<table data-filter="true" class="limit-table"><thead><tr><th>' +
    iTrans('Manufacturer') +
    '</th><th>' +
    iTrans('Model') +
    '</th><th>' +
    iTrans('PIC') +
    '</th><th>' +
    iTrans('SIC') +
    '</th><th>' +
    iTrans('DUAL') +
    '</th></tr></thead><tbody>';
  var Helicopters =
    '<table data-filter="true" class="limit-table"><thead><tr><th>' +
    iTrans('Manufacturer') +
    '</th><th>' +
    iTrans('Model') +
    '</th><th>' +
    iTrans('PIC') +
    '</th><th>' +
    iTrans('SIC') +
    '</th><th>' +
    iTrans('DUAL') +
    '</th></tr></thead><tbody>';
  for (var i in data.AcTypes) {
    var A = data.AcTypes[i];
    if (A.Class == 'Aeroplane') {
      Aeroplanes +=
        '<tr><td>' +
        A.Manufacturer +
        '</td><td>' +
        A.AcTypes +
        '</td><td><input onFocus="selectInputContent(this)" type="number" id="EXP_' +
        A.id +
        '_Hours" value="0"/></td><td><input onFocus="selectInputContent(this)" type="number" id="EXP_' +
        A.id +
        '_SIC" value="0"/></td><td><input onFocus="selectInputContent(this)" type="number" id="EXP_' +
        A.id +
        '_DUAL" value="0"/></td></tr>';
    } else {
      Helicopters +=
        '<tr><td>' +
        A.Manufacturer +
        '</td><td>' +
        A.AcTypes +
        '</td><td><input onFocus="selectInputContent(this)" type="number" id="EXP_' +
        A.id +
        '_Hours" value="0"/></td><td><input onFocus="selectInputContent(this)" type="number" id="EXP_' +
        A.id +
        '_SIC" value="0"/></td><td><input onFocus="selectInputContent(this)" type="number" id="EXP_' +
        A.id +
        '_DUAL" value="0"/></td></tr>';
    }
  }
  Aeroplanes += '</tbody></table>';
  Helicopters += '</tbody></table>';

  html += '<h2>' + iTrans('Enter Specific time on aircraft type') + '</h2>';
  html +=
    '<div data-role="collapsible-set" data-theme="b" data-content-theme="d"><div data-role="collapsible"><h3>' +
    iTrans('Aeroplanes') +
    '</h3><ul data-role="listview"><li>' +
    Aeroplanes +
    '</li></ul></div><div data-role="collapsible"><h3>' +
    iTrans('Helicopters') +
    '</h3><ul data-role="listview"><li>' +
    Helicopters +
    '</li></ul></div> </div>';

  html += '<b>' + iTrans('Total Type of Piloting Time') + '</b><br />';
  html +=
    '' +
    iTrans('Note') +
    ': ' +
    iTrans("All time entered here will be attributed to an 'unknown' aircraft type") +
    '.  ' +
    iTrans("If you know your total time on type go to 'Enter specific time on aircraft type' below") +
    '.<br />';
  html += "<table class='limit-table wrap' border='1'>";
  html +=
    '<thead><tr><th>' +
    iTrans('Dual') +
    '</th><th>PIC</th><th>SIC</th><th>' +
    iTrans('Instruct') +
    '</th></tr></thead><tbody>';
  html +=
    "<tr><td><input onfocus='this.select();' type='number' id='EXP_DR' value='" +
    D.DR +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_PIC' value='" +
    D.PIC +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_SIC' value='" +
    D.SIC +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_FI' value='" +
    D.FI +
    "' ></td>";
  html += '</tbody></table><br />';

  html += '<b>' + iTrans('Total Flight Condition Time') + "</b><table class='limit-table wrap' border='1'>";
  html +=
    '<thead><tr><th>' +
    iTrans('Day') +
    '</th><th>' +
    iTrans('Night') +
    '</th><th>XC</th><th>IFR</th><th>SIM</th></tr></thead><tbody>';
  html +=
    "<tr><td><input onfocus='this.select();' type='number' id='EXP_DAY' value='" +
    D.DAY +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_NIGHT' value='" +
    D.NIGHT +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_XC' value='" +
    D.XC +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_AI' value='" +
    D.AI +
    "' ></td><td><input onfocus='this.select();' type='number' id='EXP_SI' value='" +
    D.SI +
    "' ></td>";
  html += '</tbody></table><br />';

  html +=
    '<b>' +
    iTrans('Total') +
    ': ' +
    iTrans('Approach / Takeoff / Landing Stats') +
    "</b><table class='limit-table wrap' border='1'>";
  html +=
    '<thead><tr><th>Prec Appr</th><th>' +
    iTrans('Day') +
    ' T/O</th><th>' +
    iTrans('Night') +
    ' T/O</th></tr></thead><tbody>';
  html +=
    "<tr><td><input type='number' min='0' max='99999' step='1' id='EXP_GA' value='" +
    D.GA +
    "' onClick='ExperienceUpdate1(this);selectInputContent(this)'></td><td><input type='number' min='0' max='99999' step='1' id='EXP_DAT' value='" +
    D.DAT +
    "' onClick='ExperienceUpdate1(this);selectInputContent(this)'></td><td><input type='number' min='0' max='99999' step='1' id='EXP_NT' value='" +
    D.NT +
    "' onClick='ExperienceUpdate1(this);selectInputContent(this)'></td>";
  html += '</tbody></table>';
  html += "<table class='limit-table wrap' border='1'>";
  html +=
    '<thead><tr><th>NP Appr</th><th>' +
    iTrans('Day') +
    ' LDG</th><th>' +
    iTrans('Night') +
    ' LDG</th></tr></thead><tbody>';
  html +=
    "<tr><td><input type='number' min='0' max='99999' step='1' id='EXP_IA' value='" +
    D.IA +
    "' onClick='ExperienceUpdate1(this);selectInputContent(this)'></td><td><input type='number' min='0' max='99999' step='1' id='EXP_DL' value='" +
    D.DL +
    "' onClick='ExperienceUpdate1(this);selectInputContent(this)'></td><td><input type='number' min='0' max='99999' step='1' id='EXP_NL' value='" +
    D.NL +
    "' onClick='ExperienceUpdate1(this);selectInputContent(this)'></td>";
  html += '</tbody></table><br />';

  if (USER_RIGHTS.FDT_NvgCur == 1 || (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)) {
    html += '<b>Total: Night Vision Goggle Experience</b>';
    html += "<table class='wrap' border='1'>";
    html += '<thead><tr><th>Hrs</th><th>Ops</th><th>T/O</th><th>LDG</th></tr></thead><tbody>';
    html +=
      "<tr><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_HRS' value='" +
      D.NVG_HRS +
      "'></td><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_OPS' value='" +
      D.NVG_OPS +
      "' ></td><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_TO' value='" +
      D.NVG_TO +
      "' ></td><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_LD' value='" +
      D.NVG_LD +
      "' ></td>";
    html += '</tbody></table></div>';

    html += "<table class='wrap' border='1'>";
    html += '<thead><tr><th>HOV</th><th>DEP</th><th>ARR</th><th>TRANS</th></tr></thead><tbody>';
    html +=
      "<tr><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_HOV' value='" +
      D.NVG_HOV +
      "'></td><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_DEP' value='" +
      D.NVG_DEP +
      "' ></td><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_ARR' value='" +
      D.NVG_ARR +
      "' ></td><td><input type='number' onClick='ExperienceUpdate1(this);selectInputContent(this)' id='EXP_NVG_TRAN' value='" +
      D.NVG_TRAN +
      "' ></td>";
    html += '</tbody></table></div><br />';
  }

  html += GenerateFDT_SpecialtyExpereinceHTML(D);

  html +=
    '<br /><button data-icon="check" onClick="ShowMyPilotInfoSheet()">' +
    iTrans('Display Pilot Info Sheet') +
    '</button>';

  html +=
    '<br /><a data-role="button" href="' +
    BASEURL +
    'menu-Account-AJAX.php?action=ExportFDT&OPK=' +
    FDT_OPK +
    '" data-icon="user" target="_blank">' +
    iTrans('Export Cirro FDT File') +
    '</a>';

  html +=
    '<br /><a data-role="button" href="' +
    BASEURL +
    'menu-Account-AJAX.php?action=ExportFDT_CSV&OPK=' +
    FDT_OPK +
    '" data-icon="user" target="_blank">' +
    iTrans('Export FDT as CSV (Excel) ') +
    '</a>';

  html +=
    '<br /><button data-icon="cloud" onClick="ImportDutyTimes()">' + iTrans('Import Cirro FDT File') + '</button>';

  html +=
    '<br /><button data-icon="check" class="greenbg" onClick="UpdatePilotExperience()" id="UpdateExperienceBTN">' +
    iTrans('Update My Experience') +
    '</button>';

  $('#ExperienceTrackingHLDR').html(html);
  if (COUNTRY != 'CANADA' && COUNTRY != 'UNITED STATES') {
    $('#EXP_IPC_DateHLDR').hide();
    $('#EXP_NVIS_DateHLDR').hide();
    $('#EXP_NVIS_Initial_DateHLDR').hide();
  } else {
    if (
      (USER_RIGHTS.FDT_IfrCur == 1 && !FDT_ADMIN_UPDATE) ||
      (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1)
    ) {
      $('#EXP_IPC_DateHLDR').show();
    } else {
      $('#EXP_IPC_DateHLDR').hide();
    }
    if (
      (USER_RIGHTS.FDT_NvgCur == 1 && !FDT_ADMIN_UPDATE) ||
      (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)
    ) {
      $('#EXP_NVIS_DateHLDR').show();
      if (COUNTRY == 'CANADA') {
        $('#EXP_NVIS_Initial_DateHLDR').show();
        $('#EXP_NVIS_GndHLDR').show();
      } else {
        $('#EXP_NVIS_GndHLDR').hide();
        $('#EXP_NVIS_Initial_DateHLDR').hide();
      }
    } else {
      $('#EXP_NVIS_DateHLDR').hide();
      $('#EXP_NVIS_Initial_DateHLDR').hide();
    }
  }
  $('#EXP_FromDate').val(D.FromDate);
  $('#EXP_IPC_Date').val(D.IPC_Date);
  $('#EXP_NVIS_Date').val(D.NVIS_Date);
  $('#EXP_NVIS_Gnd_Date').val(D.NVIS_Gnd_Date);
  $('#EXP_NVIS_Initial_Date').val(D.NVIS_Initial_Date);
  $('#ExperienceTrackingHLDR').enhanceWithin();
  //remember to populate D.TypeExperience:"" values after load
  if (D.TypeExperience != '') {
    var ET = JSON.parse(D.TypeExperience);
    for (var e in ET) {
      var E = ET[e];
      $('#EXP_' + E.id + '_Hours').val(E.Hours);
      if (E.SIC != undefined) {
        $('#EXP_' + E.id + '_SIC').val(E.SIC);
      } else {
        $('#EXP_' + E.id + '_SIC').val(0);
      }
      if (E.DUAL != undefined) {
        $('#EXP_' + E.id + '_DUAL').val(E.DUAL);
      } else {
        $('#EXP_' + E.id + '_DUAL').val(0);
      }
    }
  }
  EXPERIENCE_DATA = data;
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param I
 * @returns {string}
 */
function GenerateFDT_SpecialtyExpereinceHTML(I) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.GenerateFDT_SpecialtyExpereinceHTML', '2024-06-26', 'bldarosa');
  var html = '';
  var hidden = '';
  for (var i in FDT_SPECIALTYV3.CS_Recs) {
    var C = FDT_SPECIALTYV3.CS_Recs[i];
    if (C.Collapsable == 1) {
      html +=
        '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>' + iTrans(C.Category) + '</h5><div>';
    } else {
      html += '<b>' + iTrans(C.Category) + '</b>';
    }
    var Fields = C.Fields;
    var column = 0;
    var row = '';
    var head = '';
    for (var f in C.Fields) {
      var F = C.Fields[f];
      if (F.Field == 'FTO' || F.Field == 'FLD') {
        if (
          (USER_RIGHTS.FDT_FloatRating == 1 && userShouldHaveFDT(USER_RIGHTS.staffType)) ||
          (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_FloatRating == 1)
        ) {
          F.Used = 1;
        } else {
          F.Used = 0;
        }
      }
      if (F.Used == 0) {
        if (I[F.Field] == undefined) {
          hidden += "<input type='hidden' id='EXP_" + F.Field + "' value='0'>";
        } else {
          hidden +=
            "<input onfocus='selectInputContent(this);' type='hidden' id='EXP_" +
            F.Field +
            "' value='" +
            I[F.Field] +
            "'>";
        }
      } else {
        head += '<th>' + F.Label + '</th>';
        if (F.DataType == 'Hours') {
          row +=
            "<td><input onfocus='selectInputContent(this);' type='number' id='EXP_" +
            F.Field +
            "' value='" +
            I[F.Field] +
            "'></td>";
        }
        if (F.DataType == 'Count') {
          row +=
            "<td><input onfocus='selectInputContent(this);' type='number' id='EXP_" +
            F.Field +
            "' value='" +
            I[F.Field] +
            "'></td>";
        }
        column++;
      }
      if (column == 4) {
        html += '<table class="wrap" border="1"><thead><tr>' + head + '</tr></thead><tbody>';
        html += '<tr>' + row + '</tr></tbody></table>';
        head = '';
        row = '';
        column = 0;
      }
    }
    if (column != 0) {
      html += '<table class="wrap" border="1"><thead><tr>' + head + '</tr></thead><tbody>';
      html += '<tr>' + row + '</tr></tbody></table>';
      head = '';
      row = '';
    }
    if (C.Collapsable == 1) {
      html += '</div></div>';
    }
  }
  return html + hidden;
}

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 */
var EXPERIENCE_DATA;

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function verifyEAOdate() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.verifyEAOdate', '2024-06-26', 'bldarosa');
  var FromDate = $('#EXP_FromDate').val();
  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  var todayTxt = now.getFullYear() + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day);
  console.log(todayTxt);
  var eoa = new Date(FromDate + 'T00:00:00');
  var today = new Date(todayTxt + 'T00:00:00');
  if (eoa.getTime() >= today.getTime()) {
    today.setDate(today.getDate() - 1);
    month = today.getMonth() + 1;
    day = today.getDate();
    todayTxt = today.getFullYear() + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day);
    $('#EXP_FromDate').val(todayTxt);
    alert('Experience as of Date must be before today');
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function UpdatePilotExperience() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.UpdatePilotExperience', '2024-06-26', 'bldarosa');
  $('#UpdateExperienceBTN').prop('disabled', true);
  var keys = Object.keys(EXPERIENCE_DATA.Experience);
  for (var i in keys) {
    var Field = keys[i];
    //console.log(Field);
    var VAL = $('#EXP_' + Field).val();
    if (Field != 'TypeExperience' && VAL != undefined) {
      EXPERIENCE_DATA.Experience[Field] = VAL;
    }
  }
  var AC = EXPERIENCE_DATA.AcTypes;
  var TypeExperience = [];
  for (var I in AC) {
    var A = AC[I].id;
    var val = parseFloat($('#EXP_' + A + '_Hours').val());
    var SIC = parseFloat($('#EXP_' + A + '_SIC').val());
    var DUAL = parseFloat($('#EXP_' + A + '_DUAL').val());
    if (isNaN(val)) val = 0;
    if (isNaN(SIC)) SIC = 0;
    if (isNaN(DUAL)) DUAL = 0;
    if (val != 0 || SIC != 0 || DUAL != 0) {
      //console.log(val);
      TypeExperience.push({
        id: A,
        AcTypes: AC[I].AcTypes,
        Class: AC[I].Class,
        List_Type: AC[I].List_Type,
        Manufacturer: AC[I].Manufacturer,
        Hours: val,
        SIC: SIC,
        DUAL: DUAL,
      });
    }
  }
  EXPERIENCE_DATA.Experience.TypeExperience = JSON.stringify(TypeExperience);
  //console.log(EXPERIENCE_DATA);

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'sync-AJAX.php?action=updateExperienceV3&OPK=' + FDT_OPK,
    data: EXPERIENCE_DATA.Experience,
  })
    .success(function (data) {
      $('#UpdateExperienceBTN').prop('disabled', false);
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      //console.log("ServerResponse: check");
      //console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'BILLING_RemoveClient()');
      }
      if (data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Experience Updated Successfully'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem updating your experience.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      $('#UpdateExperienceBTN').prop('disabled', false);
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem updating your experience.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function NewFDTrec() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.NewFDTrec', '2024-06-26', 'bldarosa');
  if (CurrentFDT_Recs.length > 0) {
    SaveFDT_Exp($('#FDT_Exp_INDEX').val(), false);
  } //to save experience edits
  var AClist = '';
  var NoIdent = false;
  for (var i in ALLAC_data) {
    var found = false;
    for (var b in CurrentFDT_Recs) {
      var B = CurrentFDT_Recs[b];
      if (B.IDENT == ALLAC_data[i].ident) {
        found = true;
      }
      if (B.IDENT == 'No Ident') {
        NoIdent = true;
      }
    }
    if (!found) {
      if (ALLAC_data[i].Hibrenated == 0)
        AClist += "<option value='" + ALLAC_data[i].ident + "' >" + ALLAC_data[i].ident + '</option>';
      else AClist += "<option value='" + ALLAC_data[i].ident + "' disabled>" + ALLAC_data[i].ident + '</option>';
    }
  }
  if (!NoIdent) {
    AClist = '<option value="No Ident">' + iTrans('No Ident') + '</option>' + AClist;
  }

  var identList =
    '<label for="FDTIDENT_new">' +
    iTrans('Aircraft Ident') +
    '</label><select id="FDTIDENT_new" data-mini="false" >' +
    AClist +
    '</select>';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Aircraft'),
    headerClose: false,
    buttonPrompt: identList,
    buttons: {
      [iTrans('Use This Ident')]: {
        click: function () {
          NewFDTrec_IDENT($('#FDTIDENT_new').val());
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //Close Dialog
        },
      },
    },
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param IDENT
 */
function NewFDTrec_IDENT(IDENT) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.NewFDTrec_IDENT', '2024-06-26', 'bldarosa');
  var Sdate = GetCurFDT_Date_String();
  var index = CurrentFDT_Recs.length - 1;
  var record = {};
  //Disable legacy FR import
  if (!FDT_REPORT || true) {
    var DayFT = round10(parseFloat($('#FT').val()) + parseFloat($('#FT2').val()));
    var FTT = 0;
    if (CurrentFDT_Recs.length == 0) {
      FTT = DayFT;
    } else {
      FTT = DayFT;
      for (var i in CurrentFDT_Recs) {
        FTT -= CurrentFDT_Recs[i].FTT;
      }
    }
    FDT_REPORT = false;
    if (FDT_ADMIN_UPDATE) record = Blank_FDT_RecV3(FDT_OPK, Sdate);
    else record = Blank_FDT_RecV3(USER_PK, Sdate);
    record.FTT = FTT;
    record.PIC = FTT;
    record.IDENT = IDENT;

    for (var a in ALLAC_data) {
      var A = ALLAC_data[a];
      if (A.ident == record.IDENT) {
        record.List_Type = A.List_Type;
        record.AcType = A.AcType;
        record.Class = A.Class;
        record.Class2 = A.Class2;
        break;
      }
    }
    console.log(record);
    if ((record.AcType == '[Unknown]' && record.IDENT == 'No Ident') || record.AcType == undefined) {
      //prompt to select an aircraft type
      var AcTypes = LOCALSTORAGE.AcTypes;
      var html =
        '<label for="FDT_SEL_AcType">' +
        iTrans('Select Aircraft Type') +
        '</label><select id="FDT_SEL_AcType" data-mini="true">';
      html += '<option value="-1">[Unknown]</option>';
      for (var a in AcTypes) {
        html += '<option value="' + a + '">' + AcTypes[a].AcType + '</option>';
      }
      html += '</select>';
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Enter AC Type'),
        headerClose: false,
        buttonPrompt: iTrans('Select an aircraft type for this experience entry.') + ' .<br />' + html,
        buttons: {
          [iTrans('Continue')]: {
            click: function () {
              var TypeIndex = $('#FDT_SEL_AcType').val();
              if (TypeIndex != '-1') {
                record.List_Type = AcTypes[TypeIndex].List_Type;
                record.AcType = AcTypes[TypeIndex].AcType;
                record.Class = AcTypes[TypeIndex].Class;
                record.Class2 = AcTypes[TypeIndex].Class2;
              }

              cirroDB.insert('FDT_RecsV3', record, null, function (e) {
                index++;
                populate_FDT_Rec(index);
              });
            },
          },
        },
      });

      return;
    }

    cirroDB.insert('FDT_RecsV3', record, null, function (e) {
      index++;
      populate_FDT_Rec(index);
      GetCurrency_RecData(Rule); //reresh AC_Currency list
    });
  } else {
    //this should no longer be triggered for V2 FR import, kept in case I need to revers this immediately
    if (FDT_REPORT.OvernightReport.Overnight == false) {
      cirroDB.Delete('FDT_RecsV3', 'Date = ?', [Sdate], function (e) {
        //console.log("Deleting old FDT Exp Recs");
      });
      CurrentFDT_Recs = [];
    } else {
      //insert experience recs for next day
      UpdateNextDayExperience(FDT_REPORT.OvernightReport);
    }
    for (var i in FDT_REPORT.Experience) {
      var I = FDT_REPORT.Experience[i];
      //console.log(I);
      var totalFlt = I.Total;
      totalFlt = round10(totalFlt);
      record = false;
      for (var e in CurrentFDT_Recs) {
        var E = CurrentFDT_Recs[e];
        if (E.IDENT == i) {
          record = E;
          break;
        }
      }
      if (!record) {
        if (FDT_ADMIN_UPDATE) record = Blank_FDT_RecV3(FDT_OPK, Sdate);
        else record = Blank_FDT_RecV3(USER_PK, Sdate);
        record.FTT = totalFlt;
        record.DR = round10(I.DUAL);
        record.PIC = round10(I.PIC);
        record.SIC = round10(I.SIC);
        record.DFR = String(I.DFR);
        record.IDENT = i;

        for (var a in ALLAC_data) {
          var A = ALLAC_data[a];
          if (A.ident == record.IDENT) {
            record.List_Type = A.List_Type;
            record.AcType = A.AcType;
            record.Class = A.Class;
            record.Class2 = A.Class2;
            break;
          }
        }

        cirroDB.insert('FDT_RecsV3', record, null, function (e) {
          //console.log(e);
        });
      } else {
        //console.log("Combine Record Times");
        //console.log(record);
        if (record.DFR.indexOf(I.DFR) == -1) {
          //if DFR not already imported then add it to the record;
          record.FTT += totalFlt;
          record.FTT = round10(record.FTT);
          record.DR += I.DUAL;
          record.DR = round10(record.DR);
          record.PIC += I.PIC;
          record.PIC = round10(record.PIC);
          record.SIC += I.SIC;
          record.SIC = round10(record.SIC);
          record.DFR += ', ' + String(I.DFR);

          cirroDB.update('FDT_RecsV3', record, i, function (e) {
            //console.log(e);
            GetCurrency_RecData(Rule); //reresh AC_Currency list
          });
        }
      }
    }
    FDT_REPORT = false;
    populate_FDT_Rec(index);
  }

  //console.log(record);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Report
 */
function UpdateNextDayExperience(Report) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.UpdateNextDayExperience', '2024-06-26', 'bldarosa');
  var Sdate = GetCurFDT_Date_String(true);
  var CurrentFDT_Recs = [];

  cirroDB.query('FDT_RecsV3', 'Date = ? AND DELETED = 0 ORDER BY rowid ASC', [Sdate], function (Recs) {
    if (Recs === false) {
      CurrentFDT_Recs = [];
    } else if (Recs.length == 0) {
      CurrentFDT_Recs = [];
    }
    for (var i in Report.Experience) {
      var I = Report.Experience[i];
      //console.log(I);
      var totalFlt = I.PIC + I.SIC;
      totalFlt = round10(totalFlt);
      record = false;
      for (var e in CurrentFDT_Recs) {
        var E = CurrentFDT_Recs[e];
        if (E.IDENT == i) {
          record = E;
          break;
        }
      }
      if (!record) {
        if (FDT_ADMIN_UPDATE) record = Blank_FDT_RecV3(FDT_OPK, Sdate);
        else record = Blank_FDT_RecV3(USER_PK, Sdate);
        record.FTT = totalFlt;
        record.DR = I.DUAL;
        record.PIC = I.PIC;
        record.SIC = I.SIC;
        record.DFR = String(I.DFR);
        record.IDENT = i;
        if (Report.Overnight) {
          record.Overnight = 1;
        }
        cirroDB.insert('FDT_RecsV3', record, null, function (e) {
          //console.log(e);
        });
      } else {
        //console.log("Combine Record Times");
        //console.log(record);
        if (record.DFR.indexOf(I.DFR) == -1) {
          //if DFR not already imported then add it to the record;
          record.FTT += totalFlt;
          record.FTT = round10(record.FTT);
          record.DR += I.DUAL;
          record.DR = round10(record.DR);
          record.PIC += I.PIC;
          record.PIC = round10(record.PIC);
          record.SIC += I.SIC;
          record.SIC = round10(record.SIC);
          record.DFR += ', ' + String(I.DFR);
          if (Report.Overnight) {
            record.Overnight = 1;
          }
          cirroDB.update('FDT_RecsV3', record, i, function (e) {
            //console.log(e);
            GetCurrency_RecData(Rule); //reresh AC_Currency list
          });
        }
      }
    }
  });
  var table = '';

  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }

  cirroDB.query(table, 'Date = ?', [Sdate], function (e) {
    if (e === false) {
      e = [];
    }
    var record = {};
    var End = Report.LEGS[Report.LEGS.length - 1].Time_Down;
    if (Report.LEGS[Report.LEGS.length - 1].Time_Stop != 0) {
      End = Report.LEGS[Report.LEGS.length - 1].Time_Stop;
    }
    End = subDecToTime(End, -0.5);
    if (e.length == 0) {
      var NewPK = USER_RIGHTS.PrimaryKey;
      if (FDT_ADMIN_UPDATE) NewPK = FDT_OPK;
      if (userShouldHaveFDT(USERTYPE)) {
        record = Blank_FDT_DaysV4(NewPK, Sdate);
        record.Modified = dateToStringObject(new Date());
        record.End = End;
        record.FT = Report.TOTAL;
        if (Report.Overnight) {
          record.Overnight = 1;
        }
      } else {
        //usertype is staff
        record = Blank_FDT_StaffV3(NewPK, Sdate);
        record.Modified = dateToStringObject(new Date());
        record.FT = Report.TOTAL;
      }
    } else {
      record = e[0];
      record.Start = '0000';
      record.End = End;
      record.Modified = dateToStringObject(new Date());
      record.FT = Report.TOTAL;
      if (Report.Overnight && userShouldHaveFDT(USERTYPE)) {
        record.Overnight = 1;
      }
    }

    cirroDB.insert(table, record, null, function (e) {});
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param index
 * @param reload
 */
function SaveFDT_Exp(index, reload = true) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.SaveFDT_Exp', '2024-06-26', 'bldarosa');
  var Sdate = GetCurFDT_Date_String();

  ValideExperienceRec();
  var Max_PIC_SIC_Dual =
    parseFloat($('#FDT_PIC').val()) + parseFloat($('#FDT_SIC').val()) + parseFloat($('#FDT_DR').val());
  var maxTotal = parseFloat($('#FDT_FTT').val());
  if (Max_PIC_SIC_Dual > maxTotal) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Validation Failed'),
      headerClose: false,
      buttonPrompt: iTrans('The total PIC, SIC and Dual times are greater than the Total Hours'),
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
    return;
  }
  var NewPK = USER_PK;
  if (FDT_ADMIN_UPDATE) NewPK = FDT_OPK;
  var record = {
    User_PK: NewPK,
    Modified: dateToStringObject(new Date()),
    Date: Sdate,
    FTT: $('#FDT_FTT').val(),
    DR: $('#FDT_DR').val(),
    PIC: $('#FDT_PIC').val(),
    SIC: $('#FDT_SIC').val(),
    FI: $('#FDT_FI').val(),
    DAY: $('#FDT_DAY').val(),
    NIGHT: $('#FDT_NIGHT').val(),
    XC: $('#FDT_XC').val(),
    AI: $('#FDT_AI').val(),
    SI: $('#FDT_SI').val(),
    IA: $('#FDT_IA').val(),
    DL: $('#FDT_DL').val(),
    NL: $('#FDT_NL').val(),
    GA: $('#FDT_GA').val(),
    DAT: $('#FDT_DAT').val(),
    NT: $('#FDT_NT').val(),
    LL: $('#FDT_LL').val(),
    WB: $('#FDT_WB').val(),
    MF: $('#FDT_MF').val(),
    OT: $('#FDT_OT').val(),
    FTO: $('#FDT_FTO').val(),
    FLD: $('#FDT_FLD').val(),
    RGR: $('#FDT_RGR').val(),
    NVG_HRS: $('#FDT_NVG_HRS').val(),
    NVG_OPS: $('#FDT_NVG_OPS').val(),
    NVG_TO: $('#FDT_NVG_TO').val(),
    NVG_LD: $('#FDT_NVG_LD').val(),
    NVG_HOV: $('#FDT_NVG_HOV').val(),
    NVG_DEP: $('#FDT_NVG_DEP').val(),
    NVG_ARR: $('#FDT_NVG_ARR').val(),
    NVG_TRAN: $('#FDT_NVG_TRAN').val(),
    CS1: $('#FDT_CS1').val(),
    CS2: $('#FDT_CS2').val(),
    CS3: $('#FDT_CS3').val(),
    CS4: $('#FDT_CS4').val(),
    CS5: $('#FDT_CS5').val(),
    CS6: $('#FDT_CS6').val(),
    CS7: $('#FDT_CS7').val(),
    CS8: $('#FDT_CS8').val(),
    CS9: $('#FDT_CS9').val(),
    CS10: $('#FDT_CS10').val(),
    CS11: $('#FDT_CS11').val(),
    CS12: $('#FDT_CS12').val(),
    CS13: $('#FDT_CS13').val(),
    CS14: $('#FDT_CS14').val(),
    CS15: $('#FDT_CS15').val(),
    CS16: $('#FDT_CS16').val(),
    CS17: $('#FDT_CS17').val(),
    CS18: $('#FDT_CS18').val(),
    CS19: $('#FDT_CS19').val(),
    CS20: $('#FDT_CS20').val(),
    CS21: $('#FDT_CS21').val(),
    CS22: $('#FDT_CS22').val(),
    CS23: $('#FDT_CS23').val(),
    CS24: $('#FDT_CS24').val(),
    CS25: $('#FDT_CS25').val(),
    CS26: $('#FDT_CS26').val(),
    CS27: $('#FDT_CS27').val(),
    CS28: $('#FDT_CS28').val(),
    CS29: $('#FDT_CS29').val(),
    CS30: $('#FDT_CS30').val(),
    CS31: $('#FDT_CS31').val(),
    CS32: $('#FDT_CS32').val(),
    CS33: $('#FDT_CS33').val(),
    CS34: $('#FDT_CS34').val(),
    CS35: $('#FDT_CS35').val(),
    CS36: $('#FDT_CS36').val(),
    CS37: $('#FDT_CS37').val(),
    CS38: $('#FDT_CS38').val(),
    CS39: $('#FDT_CS39').val(),
    CS40: $('#FDT_CS40').val(),
    CS41: $('#FDT_CS41').val(),
    CS42: $('#FDT_CS42').val(),
    CS43: $('#FDT_CS43').val(),
    CS44: $('#FDT_CS44').val(),
    CS45: $('#FDT_CS45').val(),
    CS46: $('#FDT_CS46').val(),
    CS47: $('#FDT_CS47').val(),
    CS48: $('#FDT_CS48').val(),
    CS49: $('#FDT_CS49').val(),
    CS50: $('#FDT_CS50').val(),
    CS51: $('#FDT_CS51').val(),
    CS52: $('#FDT_CS52').val(),
    CS53: $('#FDT_CS53').val(),
    CS54: $('#FDT_CS54').val(),
    CS55: $('#FDT_CS55').val(),
    CS56: $('#FDT_CS56').val(),
    CS57: $('#FDT_CS57').val(),
    CS58: $('#FDT_CS58').val(),
    CS59: $('#FDT_CS59').val(),
    CS60: $('#FDT_CS60').val(),
    CS61: $('#FDT_CS61').val(),
    CS62: $('#FDT_CS62').val(),
    CS63: $('#FDT_CS63').val(),
    CS64: $('#FDT_CS64').val(),
    CS65: $('#FDT_CS65').val(),
    CS66: $('#FDT_CS66').val(),
    CS67: $('#FDT_CS67').val(),
    CS68: $('#FDT_CS68').val(),
    CS69: $('#FDT_CS69').val(),
    CS70: $('#FDT_CS70').val(),
    CS71: $('#FDT_CS71').val(),
    CS72: $('#FDT_CS72').val(),
    CS73: $('#FDT_CS73').val(),
    CS74: $('#FDT_CS74').val(),
    CS75: $('#FDT_CS75').val(),
    CS76: $('#FDT_CS76').val(),
    CS77: $('#FDT_CS77').val(),
    CS78: $('#FDT_CS78').val(),
    CS79: $('#FDT_CS79').val(),
    CS80: $('#FDT_CS80').val(),
    CS81: $('#FDT_CS81').val(),
    CS82: $('#FDT_CS82').val(),
    CS83: $('#FDT_CS83').val(),
    CS84: $('#FDT_CS84').val(),
    CS85: $('#FDT_CS85').val(),
    CS86: $('#FDT_CS86').val(),
    CS87: $('#FDT_CS87').val(),
    CS88: $('#FDT_CS88').val(),
    CS89: $('#FDT_CS89').val(),
    CS90: $('#FDT_CS90').val(),
    CS91: $('#FDT_CS91').val(),
    CS92: $('#FDT_CS92').val(),
    CS93: $('#FDT_CS93').val(),
    CS94: $('#FDT_CS94').val(),
    CS95: $('#FDT_CS95').val(),
    CS96: $('#FDT_CS96').val(),
    CS97: $('#FDT_CS97').val(),
    CS98: $('#FDT_CS98').val(),
    CS99: $('#FDT_CS99').val(),
    CS100: $('#FDT_CS100').val(),
    CNT1: $('#FDT_CNT1').val(),
    CNT2: $('#FDT_CNT2').val(),
    CNT3: $('#FDT_CNT3').val(),
    CNT4: $('#FDT_CNT4').val(),
    CNT5: $('#FDT_CNT5').val(),
    CNT6: $('#FDT_CNT6').val(),
    CNT7: $('#FDT_CNT7').val(),
    CNT8: $('#FDT_CNT8').val(),
    CNT9: $('#FDT_CNT9').val(),
    CNT10: $('#FDT_CNT10').val(),
    DFR: $('#DFR_exp').val(),
    IDENT: $('#FDTIDENT_exp').val(),
    List_Type: $('#FDT_List_Type').val(),
    AcType: $('#FDT_AcType').val(),
    Class: $('#FDT_Class').val(),
    Class2: $('#FDT_Class2').val(),
    RecComment: $('#RecComment').val(),
    DATA: '',
    DELETED: 0,
  };
  if (IOS) record.RecComment = record.RecComment.replace(/'/g, '`');
  for (var a in ALLAC_data) {
    var A = ALLAC_data[a];
    if (A.ident == record.IDENT) {
      record.List_Type = A.List_Type;
      record.AcType = A.AcType;
      record.Class = A.Class;
      record.Class2 = A.Class2;
      break;
    }
  }

  //console.log(record);

  var PrevIdent = CurrentFDT_Recs[index].IDENT;

  cirroDB.update('FDT_RecsV3', record, PrevIdent, function (e) {
    if (reload) {
      populate_FDT_Rec(index);
    }
    GetCurrency_RecData(Rule); //reresh AC_Currency list
  });
  update_Sync('LastFDTsync', new Date());
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function ValideExperienceRec() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ValideExperienceRec', '2024-06-26', 'bldarosa');
  if (GROUP_DATA.FDT_Valid_FT_Off == 1) {
    return;
  }
  var maxTime = parseFloat($('#FDT_FTT').val());

  var maxTotal = parseFloat($('#FT').val());
  if ($('#FT2').val() != undefined) {
    maxTotal += parseFloat($('#FT2').val());
  }
  maxTime = Math.round(maxTime * 10) / 10;
  maxTotal = Math.round(maxTotal * 10) / 10;
  if (parseFloat($('#FDT_FTT').val()) > maxTotal) {
    $('#FDT_FTT').val(maxTotal);
  }
  if (parseFloat($('#FDT_DR').val()) > maxTime) {
    $('#FDT_DR').val(maxTime);
  }
  if (parseFloat($('#FDT_PIC').val()) > maxTime) {
    $('#FDT_PIC').val(maxTime);
  }
  if (parseFloat($('#FDT_SIC').val()) > maxTime) {
    $('#FDT_SIC').val(maxTime);
  }
  if (parseFloat($('#FDT_FI').val()) > maxTime) {
    $('#FDT_FI').val(maxTime);
  }

  if (parseFloat($('#FDT_DAY').val()) > maxTime) {
    $('#FDT_DAY').val(maxTime);
  }
  if (parseFloat($('#FDT_NIGHT').val()) > maxTime) {
    $('#FDT_NIGHT').val(maxTime);
  }
  if (parseFloat($('#FDT_XC').val()) > maxTime) {
    $('#FDT_XC').val(maxTime);
  }
  if (parseFloat($('#FDT_AI').val()) > maxTime) {
    $('#FDT_AI').val(maxTime);
  }
  if (parseFloat($('#FDT_SI').val()) > maxTime) {
    $('#FDT_SI').val(maxTime);
  }

  if (parseFloat($('#FDT_LL').val()) > maxTime) {
    $('#FDT_LL').val(maxTime);
  }
  if (parseFloat($('#FDT_WB').val()) > maxTime) {
    $('#FDT_WB').val(maxTime);
  }
  if (parseFloat($('#FDT_MF').val()) > maxTime) {
    $('#FDT_MF').val(maxTime);
  }
  if (parseFloat($('#FDT_OT').val()) > maxTime) {
    $('#FDT_OT').val(maxTime);
  }

  if (parseFloat($('#FDT_DR').val()) < 0) {
    $('#FDT_DR').val(0);
  }
  if (parseFloat($('#FDT_FI').val()) < 0) {
    $('#FDT_FI').val(0);
  }
  if (parseFloat($('#FDT_DAY').val()) < 0) {
    $('#FDT_DAY').val(0);
  }
  if (parseFloat($('#FDT_NIGHT').val()) < 0) {
    $('#FDT_NIGHT').val(0);
  }
  if (parseFloat($('#FDT_XC').val()) < 0) {
    $('#FDT_XC').val(0);
  }
  if (parseFloat($('#FDT_AI').val()) < 0) {
    $('#FDT_AI').val(0);
  }
  if (parseFloat($('#FDT_SI').val()) < 0) {
    $('#FDT_SI').val(0);
  }

  if (parseFloat($('#FDT_LL').val()) < 0) {
    $('#FDT_LL').val(0);
  }
  if (parseFloat($('#FDT_WB').val()) < 0) {
    $('#FDT_WB').val(0);
  }
  if (parseFloat($('#FDT_MF').val()) < 0) {
    $('#FDT_MF').val(0);
  }
  if (parseFloat($('#FDT_OT').val()) < 0) {
    $('#FDT_OT').val(0);
  }

  if (parseFloat($('#FDT_NVG_HRS').val()) > maxTime) {
    $('#FDT_NVG_HRS').val(maxTime);
  }

  if (parseFloat($('#FDT_CS1').val()) < 0) {
    $('#FDT_CS1').val(0);
  }
  if (parseFloat($('#FDT_CS2').val()) < 0) {
    $('#FDT_CS2').val(0);
  }
  if (parseFloat($('#FDT_CS3').val()) < 0) {
    $('#FDT_CS3').val(0);
  }
  if (parseFloat($('#FDT_CS4').val()) < 0) {
    $('#FDT_CS4').val(0);
  }
  if (parseFloat($('#FDT_CS5').val()) < 0) {
    $('#FDT_CS5').val(0);
  }
  if (parseFloat($('#FDT_CS6').val()) < 0) {
    $('#FDT_CS6').val(0);
  }
  if (parseFloat($('#FDT_CS7').val()) < 0) {
    $('#FDT_CS7').val(0);
  }
  if (parseFloat($('#FDT_CS8').val()) < 0) {
    $('#FDT_CS8').val(0);
  }
  if (parseFloat($('#FDT_CS9').val()) < 0) {
    $('#FDT_CS9').val(0);
  }
  if (parseFloat($('#FDT_CS10').val()) < 0) {
    $('#FDT_CS10').val(0);
  }
  if (parseFloat($('#FDT_CS11').val()) < 0) {
    $('#FDT_CS11').val(0);
  }
  if (parseFloat($('#FDT_CS12').val()) < 0) {
    $('#FDT_CS12').val(0);
  }
  if (parseFloat($('#FDT_CS13').val()) < 0) {
    $('#FDT_CS13').val(0);
  }
  if (parseFloat($('#FDT_CS14').val()) < 0) {
    $('#FDT_CS14').val(0);
  }
  if (parseFloat($('#FDT_CS15').val()) < 0) {
    $('#FDT_CS15').val(0);
  }
  if (parseFloat($('#FDT_CS16').val()) < 0) {
    $('#FDT_CS16').val(0);
  }
  if (parseFloat($('#FDT_CS17').val()) < 0) {
    $('#FDT_CS17').val(0);
  }
  if (parseFloat($('#FDT_CS18').val()) < 0) {
    $('#FDT_CS18').val(0);
  }
  if (parseFloat($('#FDT_CS19').val()) < 0) {
    $('#FDT_CS19').val(0);
  }
  if (parseFloat($('#FDT_CS20').val()) < 0) {
    $('#FDT_CS20').val(0);
  }
  if (parseFloat($('#FDT_CS21').val()) < 0) {
    $('#FDT_CS21').val(0);
  }
  if (parseFloat($('#FDT_CS22').val()) < 0) {
    $('#FDT_CS22').val(0);
  }
  if (parseFloat($('#FDT_CS23').val()) < 0) {
    $('#FDT_CS23').val(0);
  }
  if (parseFloat($('#FDT_CS24').val()) < 0) {
    $('#FDT_CS24').val(0);
  }
  if (parseFloat($('#FDT_CS25').val()) < 0) {
    $('#FDT_CS25').val(0);
  }
  if (parseFloat($('#FDT_CS26').val()) < 0) {
    $('#FDT_CS26').val(0);
  }
  if (parseFloat($('#FDT_CS27').val()) < 0) {
    $('#FDT_CS27').val(0);
  }
  if (parseFloat($('#FDT_CS28').val()) < 0) {
    $('#FDT_CS28').val(0);
  }
  if (parseFloat($('#FDT_CS29').val()) < 0) {
    $('#FDT_CS29').val(0);
  }
  if (parseFloat($('#FDT_CS30').val()) < 0) {
    $('#FDT_CS30').val(0);
  }
  if (parseFloat($('#FDT_CS31').val()) < 0) {
    $('#FDT_CS31').val(0);
  }
  if (parseFloat($('#FDT_CS32').val()) < 0) {
    $('#FDT_CS32').val(0);
  }
  if (parseFloat($('#FDT_CS33').val()) < 0) {
    $('#FDT_CS33').val(0);
  }
  if (parseFloat($('#FDT_CS34').val()) < 0) {
    $('#FDT_CS34').val(0);
  }
  if (parseFloat($('#FDT_CS35').val()) < 0) {
    $('#FDT_CS35').val(0);
  }
  if (parseFloat($('#FDT_CS36').val()) < 0) {
    $('#FDT_CS36').val(0);
  }
  if (parseFloat($('#FDT_CS37').val()) < 0) {
    $('#FDT_CS37').val(0);
  }
  if (parseFloat($('#FDT_CS38').val()) < 0) {
    $('#FDT_CS38').val(0);
  }
  if (parseFloat($('#FDT_CS39').val()) < 0) {
    $('#FDT_CS39').val(0);
  }
  if (parseFloat($('#FDT_CS40').val()) < 0) {
    $('#FDT_CS40').val(0);
  }
  if (parseFloat($('#FDT_CS41').val()) < 0) {
    $('#FDT_CS41').val(0);
  }
  if (parseFloat($('#FDT_CS42').val()) < 0) {
    $('#FDT_CS42').val(0);
  }
  if (parseFloat($('#FDT_CS43').val()) < 0) {
    $('#FDT_CS43').val(0);
  }
  if (parseFloat($('#FDT_CS44').val()) < 0) {
    $('#FDT_CS44').val(0);
  }
  if (parseFloat($('#FDT_CS45').val()) < 0) {
    $('#FDT_CS45').val(0);
  }
  if (parseFloat($('#FDT_CS46').val()) < 0) {
    $('#FDT_CS46').val(0);
  }
  if (parseFloat($('#FDT_CS47').val()) < 0) {
    $('#FDT_CS47').val(0);
  }
  if (parseFloat($('#FDT_CS48').val()) < 0) {
    $('#FDT_CS48').val(0);
  }
  if (parseFloat($('#FDT_CS49').val()) < 0) {
    $('#FDT_CS49').val(0);
  }
  if (parseFloat($('#FDT_CS50').val()) < 0) {
    $('#FDT_CS50').val(0);
  }
  if (parseFloat($('#FDT_CS51').val()) < 0) {
    $('#FDT_CS51').val(0);
  }
  if (parseFloat($('#FDT_CS52').val()) < 0) {
    $('#FDT_CS52').val(0);
  }
  if (parseFloat($('#FDT_CS53').val()) < 0) {
    $('#FDT_CS53').val(0);
  }
  if (parseFloat($('#FDT_CS54').val()) < 0) {
    $('#FDT_CS54').val(0);
  }
  if (parseFloat($('#FDT_CS55').val()) < 0) {
    $('#FDT_CS55').val(0);
  }
  if (parseFloat($('#FDT_CS56').val()) < 0) {
    $('#FDT_CS56').val(0);
  }
  if (parseFloat($('#FDT_CS57').val()) < 0) {
    $('#FDT_CS57').val(0);
  }
  if (parseFloat($('#FDT_CS58').val()) < 0) {
    $('#FDT_CS58').val(0);
  }
  if (parseFloat($('#FDT_CS59').val()) < 0) {
    $('#FDT_CS59').val(0);
  }
  if (parseFloat($('#FDT_CS60').val()) < 0) {
    $('#FDT_CS60').val(0);
  }
  if (parseFloat($('#FDT_CS61').val()) < 0) {
    $('#FDT_CS61').val(0);
  }
  if (parseFloat($('#FDT_CS62').val()) < 0) {
    $('#FDT_CS62').val(0);
  }
  if (parseFloat($('#FDT_CS63').val()) < 0) {
    $('#FDT_CS63').val(0);
  }
  if (parseFloat($('#FDT_CS64').val()) < 0) {
    $('#FDT_CS64').val(0);
  }
  if (parseFloat($('#FDT_CS65').val()) < 0) {
    $('#FDT_CS65').val(0);
  }
  if (parseFloat($('#FDT_CS66').val()) < 0) {
    $('#FDT_CS66').val(0);
  }
  if (parseFloat($('#FDT_CS67').val()) < 0) {
    $('#FDT_CS67').val(0);
  }
  if (parseFloat($('#FDT_CS68').val()) < 0) {
    $('#FDT_CS68').val(0);
  }
  if (parseFloat($('#FDT_CS69').val()) < 0) {
    $('#FDT_CS69').val(0);
  }
  if (parseFloat($('#FDT_CS70').val()) < 0) {
    $('#FDT_CS70').val(0);
  }
  if (parseFloat($('#FDT_CS71').val()) < 0) {
    $('#FDT_CS71').val(0);
  }
  if (parseFloat($('#FDT_CS72').val()) < 0) {
    $('#FDT_CS72').val(0);
  }
  if (parseFloat($('#FDT_CS73').val()) < 0) {
    $('#FDT_CS73').val(0);
  }
  if (parseFloat($('#FDT_CS74').val()) < 0) {
    $('#FDT_CS74').val(0);
  }
  if (parseFloat($('#FDT_CS75').val()) < 0) {
    $('#FDT_CS75').val(0);
  }
  if (parseFloat($('#FDT_CS76').val()) < 0) {
    $('#FDT_CS76').val(0);
  }
  if (parseFloat($('#FDT_CS77').val()) < 0) {
    $('#FDT_CS77').val(0);
  }
  if (parseFloat($('#FDT_CS78').val()) < 0) {
    $('#FDT_CS78').val(0);
  }
  if (parseFloat($('#FDT_CS79').val()) < 0) {
    $('#FDT_CS79').val(0);
  }
  if (parseFloat($('#FDT_CS80').val()) < 0) {
    $('#FDT_CS80').val(0);
  }
  if (parseFloat($('#FDT_CS81').val()) < 0) {
    $('#FDT_CS81').val(0);
  }
  if (parseFloat($('#FDT_CS82').val()) < 0) {
    $('#FDT_CS82').val(0);
  }
  if (parseFloat($('#FDT_CS83').val()) < 0) {
    $('#FDT_CS83').val(0);
  }
  if (parseFloat($('#FDT_CS84').val()) < 0) {
    $('#FDT_CS84').val(0);
  }
  if (parseFloat($('#FDT_CS85').val()) < 0) {
    $('#FDT_CS85').val(0);
  }
  if (parseFloat($('#FDT_CS86').val()) < 0) {
    $('#FDT_CS86').val(0);
  }
  if (parseFloat($('#FDT_CS87').val()) < 0) {
    $('#FDT_CS87').val(0);
  }
  if (parseFloat($('#FDT_CS88').val()) < 0) {
    $('#FDT_CS88').val(0);
  }
  if (parseFloat($('#FDT_CS89').val()) < 0) {
    $('#FDT_CS89').val(0);
  }
  if (parseFloat($('#FDT_CS90').val()) < 0) {
    $('#FDT_CS90').val(0);
  }
  if (parseFloat($('#FDT_CS91').val()) < 0) {
    $('#FDT_CS91').val(0);
  }
  if (parseFloat($('#FDT_CS92').val()) < 0) {
    $('#FDT_CS92').val(0);
  }
  if (parseFloat($('#FDT_CS93').val()) < 0) {
    $('#FDT_CS93').val(0);
  }
  if (parseFloat($('#FDT_CS94').val()) < 0) {
    $('#FDT_CS94').val(0);
  }
  if (parseFloat($('#FDT_CS95').val()) < 0) {
    $('#FDT_CS95').val(0);
  }
  if (parseFloat($('#FDT_CS96').val()) < 0) {
    $('#FDT_CS96').val(0);
  }
  if (parseFloat($('#FDT_CS97').val()) < 0) {
    $('#FDT_CS97').val(0);
  }
  if (parseFloat($('#FDT_CS98').val()) < 0) {
    $('#FDT_CS98').val(0);
  }
  if (parseFloat($('#FDT_CS99').val()) < 0) {
    $('#FDT_CS99').val(0);
  }
  if (parseFloat($('#FDT_CS100').val()) < 0) {
    $('#FDT_CS100').val(0);
  }
  if (parseFloat($('#FDT_CNT1').val()) < 0) {
    $('#FDT_CNT1').val(0);
  }
  if (parseFloat($('#FDT_CNT2').val()) < 0) {
    $('#FDT_CNT2').val(0);
  }
  if (parseFloat($('#FDT_CNT3').val()) < 0) {
    $('#FDT_CNT3').val(0);
  }
  if (parseFloat($('#FDT_CNT4').val()) < 0) {
    $('#FDT_CNT4').val(0);
  }
  if (parseFloat($('#FDT_CNT5').val()) < 0) {
    $('#FDT_CNT5').val(0);
  }
  if (parseFloat($('#FDT_CNT6').val()) < 0) {
    $('#FDT_CNT6').val(0);
  }
  if (parseFloat($('#FDT_CNT7').val()) < 0) {
    $('#FDT_CNT7').val(0);
  }
  if (parseFloat($('#FDT_CNT8').val()) < 0) {
    $('#FDT_CNT8').val(0);
  }
  if (parseFloat($('#FDT_CNT9').val()) < 0) {
    $('#FDT_CNT9').val(0);
  }
  if (parseFloat($('#FDT_CNT10').val()) < 0) {
    $('#FDT_CNT10').val(0);
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param index
 */
function DeleteExperienceRec(index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.DeleteExperienceRec', '2024-06-26', 'bldarosa');
  var Sdate = GetCurFDT_Date_String();

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm Action'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to remove this aircraft ident from your experience?'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          cirroDB.Delete('FDT_RecsV3', 'Date = ? AND IDENT = ?', [Sdate, CurrentFDT_Recs[index].IDENT], function (e) {
            populate_FDT_Rec(0);
            GetCurrency_RecData(Rule); //reresh AC_Currency list
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //Close Dialog
        },
      },
    },
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param nextday
 * @returns {string}
 */
function GetCurFDT_Date_String(nextday = false) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.GetCurFDT_Date_String', '2024-06-26', 'bldarosa');
  var DATE = new Date(refreshDATE);
  if (nextday) {
    DATE.setDate(DATE.getDate() + 1);
  }
  var Syear = DATE.getFullYear();
  var SMonth = DATE.getMonth() + 1;
  var Sday = DATE.getDate();
  if (SMonth < 10) {
    SMonth = '0' + SMonth;
  }
  if (Sday < 10) {
    Sday = '0' + Sday;
  }
  var Sdate = Syear + '-' + SMonth + '-' + Sday;
  return Sdate;
}

var refreshDATE;

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @type {*[]}
 */
var CurrentFDT_Recs = [];

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function validate_FDT_EXP(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.validate_FDT_EXP', '2024-06-26', 'bldarosa');
  var val = $('#' + e.id).val();
  if (e.id.indexOf('FDT_CS') != -1) {
    if (val > 9999.9) {
      $('#' + e.id).val(9999.9);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('The value entered is beyond the maximum value of 99999.9'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //do nothing
            },
          },
        },
      });
    }
  }
  if (e.id.indexOf('FDT_CNT') != -1) {
    if (val > 999) {
      $('#' + e.id).val(999);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('The value entered is beyond the maximum value of 999'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //do nothing
            },
          },
        },
      });
    }
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param index
 */
function populate_FDT_Rec(index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.populate_FDT_Rec', '2024-06-26', 'bldarosa');
  var Sdate = GetCurFDT_Date_String();
  if (index == -1) {
    index = 0;
  }

  cirroDB.query('FDT_RecsV3', 'Date = ? AND DELETED = 0 ORDER BY rowid ASC', [Sdate], function (Recs) {
    if (Recs === false) {
      CurrentFDT_Recs = [];
      $('#FDT_EXPERIENCE_INPUT').html('<h2>' + iTrans('No Aircraft Experience Tracked For This Date') + '</h2>');
      return;
    }
    if (Recs.length == 0) {
      CurrentFDT_Recs = [];
      $('#FDT_EXPERIENCE_INPUT').html('<h2>' + iTrans('No Aircraft Experience Tracked For This Date') + '</h2>');

      return;
    }

    CurrentFDT_Recs = Recs;
    if (CurrentFDT_Recs.length - 1 < index) {
      index = CurrentFDT_Recs.length - 1;
    }
    if (Recs.length > 0) {
      var I = Recs[0];
      var Ident = I.IDENT;
      var rowid = I.rowid;
      var html = '' + iTrans('Tracked Aircraft') + ': ';
      var DayIdentList = '';
      if (USER_RIGHTS.BASED_IN == undefined) {
        USER_RIGHTS.BASED_IN = '';
      }
      for (var i in Recs) {
        if (index == i) {
          I = Recs[i];
          Ident = I.IDENT;
          rowid = I.rowid;
        }
        DayIdentList += Recs[i].IDENT + ', ';
        html +=
          '<a href="#" data-ajax="false" onClick="SaveFDT_Exp(' +
          index +
          ', false); populate_FDT_Rec(' +
          i +
          ');">' +
          Recs[i].IDENT +
          '</a>, ';
      }
      var AClist = '';
      var AClist2 = '';
      var NoIdent = false;
      var RGR_AC = false;
      for (var i in ALLAC_data) {
        var found = false;
        var Item = '';
        for (var b in CurrentFDT_Recs) {
          var B = CurrentFDT_Recs[b];
          if (B.IDENT == ALLAC_data[i].ident && B.IDENT != Ident) {
            found = true;
          }
          if (B.IDENT == 'No Ident' && B.IDENT != Ident) {
            NoIdent = true;
          }
        }
        if (!found) {
          if (ALLAC_data[i].Hibrenated == 1) {
            Item = "<option value='" + ALLAC_data[i].ident + "' disabled>" + ALLAC_data[i].ident + '</option>';
          } else {
            Item = "<option value='" + ALLAC_data[i].ident + "' >" + ALLAC_data[i].ident + '</option>';
          }
          if (FDT_ADMIN_UPDATE) {
            if (ALLAC_data[i].BASED_IN == ADMIN_FDT_DATA.CurUser.BASED_IN) {
              AClist += Item;
            } else {
              AClist2 += Item;
            }
          } else {
            if (ALLAC_data[i].BASED_IN == USER_RIGHTS.BASED_IN) {
              AClist += Item;
            } else {
              AClist2 += Item;
            }
          }
        }
        if (ALLAC_data[i].List_Type == 'DHC-6 Series' && ALLAC_data[i].ident == Ident) {
          RGR_AC = true;
        }
      }
      AClist += AClist2;
      $('#DayIdents').html(DayIdentList);
      if (!NoIdent) {
        AClist = '<option value="No Ident">' + iTrans('No Ident') + '</option>' + AClist;
      }

      html += '<br /><br />';
      html += '<input type="hidden" id="FDT_Exp_INDEX" value="' + index + '" />';
      var identList =
        '<select id="FDTIDENT_exp" data-mini="false" data-role="none" onChange="SaveFDT_Exp(' +
        index +
        ');">' +
        AClist +
        '</select>';

      html +=
        '<table><thead><tr><th>' +
        I.AcType +
        '<br />' +
        iTrans('Aircraft Ident') +
        '</th><th>DFR</th><th>' +
        iTrans('Total Hours') +
        '</th></tr></thead>';

      html +=
        '<tbody><tr><td><div class="vertical-center-container"><button data-mini="true" class="action-btn" onClick="DeleteExperienceRec(' +
        index +
        ');"><img src="./images/delete.svg"></button>     ' +
        identList +
        '</div></td><td><input type="text" onChange="SaveFDT_Exp(' +
        index +
        ');" id="DFR_exp" value="' +
        I.DFR +
        '" /></td><td><input type="number" onChange="ValidateMaxExperienceFTT(this, ' +
        index +
        ');" id="FDT_FTT" value="' +
        I.FTT +
        '" onfocus="CheckMaxExperienceFTT(this, ' +
        index +
        ')"></td></tr></tbody></table>';
      html += '<b>' + iTrans('Type of Piloting Time') + "</b><table class='wrap' border='1'>";
      html +=
        '<thead><tr><th>' +
        iTrans('Dual') +
        '</th><th>PIC</th><th>SIC</th><th>' +
        iTrans('Instruct') +
        '</th></tr></thead><tbody>';
      html +=
        "<tr><td><input type='number' onChange='ExperienceUpdate(this,false);SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_DR' value='" +
        I.DR +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='ExperienceUpdate(this,false);SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_PIC' value='" +
        I.PIC +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='ExperienceUpdate(this,false);SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_SIC' value='" +
        I.SIC +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_FI' value='" +
        I.FI +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td>";
      html += '</tbody></table>';

      if ($('#CHK_FDTatl').is(':checked')) {
        html += '<div style="display:none;">';
      } else {
        html += '<div>';
      }
      html += '<b>' + iTrans('Conditions of Flight') + "</b><table class='wrap' border='1'>";
      html +=
        '<thead><tr><th>' +
        iTrans('Day') +
        '</th><th>' +
        iTrans('Night') +
        '</th><th>XC</th><th>IFR</th><th>SIM</th></tr></thead><tbody>';
      html +=
        "<tr><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_DAY' value='" +
        I.DAY +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_NIGHT' value='" +
        I.NIGHT +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_XC' value='" +
        I.XC +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_AI' value='" +
        I.AI +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' id='FDT_SI' value='" +
        I.SI +
        "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
        index +
        ", false);'></td>";
      html += '</tbody></table></div>';

      if ($('#CHK_FDTConditions').is(':checked')) {
        html += '<div style="display:none;">';
      } else {
        html += '<div>';
      }

      html += '<b>' + iTrans('Approach / Takeoff / Landing Stats') + "</b><table class='wrap' border='1'>";

      html +=
        '<thead><tr><th>Prec Appr</th><th>' +
        iTrans('Day') +
        ' T/O</th><th>' +
        iTrans('Night') +
        ' T/O</th></tr></thead><tbody>';
      html +=
        "<tr><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' min='0' max='99' step='1' id='FDT_GA' value='" +
        I.GA +
        "' onClick='ExperienceUpdate1(this);selectInputContent(this); SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' min='0' max='99' step='1' id='FDT_DAT' value='" +
        I.DAT +
        "' onClick='ExperienceUpdate1(this);selectInputContent(this); SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' min='0' max='99' step='1' id='FDT_NT' value='" +
        I.NT +
        "' onClick='ExperienceUpdate1(this);selectInputContent(this); SaveFDT_Exp(" +
        index +
        ", false);'></td>";
      html += '</tbody></table>';
      html += "<table class='wrap' border='1'>";
      html +=
        '<thead><tr><th>NP Appr</th><th>' +
        iTrans('Day') +
        ' LDG</th><th>' +
        iTrans('Night') +
        ' LDG</th></tr></thead><tbody>';
      html +=
        "<tr><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' min='0' max='99' step='1' id='FDT_IA' value='" +
        I.IA +
        "' onClick='ExperienceUpdate1(this);selectInputContent(this); SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' min='0' max='99' step='1' id='FDT_DL' value='" +
        I.DL +
        "' onClick='ExperienceUpdate1(this);selectInputContent(this); SaveFDT_Exp(" +
        index +
        ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
        index +
        ", false);' min='0' max='99' step='1' id='FDT_NL' value='" +
        I.NL +
        "' onClick='ExperienceUpdate1(this);selectInputContent(this); SaveFDT_Exp(" +
        index +
        ", false);'></td>";
      html += '</tbody></table></div>';

      if (RGR_AC) {
        html +=
          '<label for="FDT_RGR">Reduce Ground Roll Take Offs</label><input type="number" id="FDT_RGR" onclick="ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(' +
          index +
          ', false);" value="' +
          I.RGR +
          '"/>';
      } else {
        html +=
          '<input type="hidden" id="FDT_RGR" onclick="ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(' +
          index +
          ', false);"  value="' +
          I.RGR +
          '"/>';
      }

      if ($('#CHK_Specialty').is(':checked')) {
        html += '<div style="display:none;">';
      } else {
        html += '<div>';
      }

      html += GenerateFDT_SpecialtyTrackingHTML(I, index);
      if (USER_RIGHTS.FDT_NvgCur == 1 || (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)) {
        console.log(I);
        html += '<b>Night Vision Goggle Experience</b>';
        html += "<table class='wrap' border='1'>";
        html += '<thead><tr><th>Hrs</th><th>Ops</th><th>T/O</th><th>LDG</th></tr></thead><tbody>';
        html +=
          "<tr><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_HRS' value='" +
          I.NVG_HRS +
          "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
          index +
          ", false);'></td><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_OPS' value='" +
          I.NVG_OPS +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_TO' value='" +
          I.NVG_TO +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_LD' value='" +
          I.NVG_LD +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td>";
        html += '</tbody></table></div>';

        html += "<table class='wrap' border='1'>";
        html += '<thead><tr><th>HOV</th><th>DEP</th><th>ARR</th><th>TRANS</th></tr></thead><tbody>';
        html +=
          "<tr><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_HOV' value='" +
          I.NVG_HOV +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_DEP' value='" +
          I.NVG_DEP +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_ARR' value='" +
          I.NVG_ARR +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td><td><input type='number' onChange='SaveFDT_Exp(" +
          index +
          ", false);' id='FDT_NVG_TRAN' value='" +
          I.NVG_TRAN +
          "' onclick='ExperienceUpdate1(this);selectInputContent(this);SaveFDT_Exp(" +
          index +
          ", false); '></td>";
        html += '</tbody></table></div><br />';
      }

      html += '<label for="RecComment">' + iTrans('Experience Comment') + '</label>';
      html +=
        '<textarea id="RecComment"  onChange="SaveFDT_Exp(' + index + ', false);">' + I.RecComment + '</textarea>';

      $('#FDT_EXPERIENCE_INPUT').html(html);
      $('#FDT_EXPERIENCE_INPUT').enhanceWithin();
      FDT_checkAcIdent_and_select(Ident);
      //$('#FDTIDENT_exp').val(Ident);
    } else {
      $('#FDT_EXPERIENCE_INPUT').html('<h2>' + iTrans('No Aircraft Experience Tracked For This Date') + '</h2>');
      if (!FDT_REPORT || true) {
        NewFDTrec_IDENT(FDT_LAST_IDENT);
      } else {
        NewFDTrec_IDENT(FDT_REPORT.Aircraft);
      }
      return;
    }
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param I
 * @param index
 * @returns {string}
 */
function GenerateFDT_SpecialtyTrackingHTML(I, index) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.GenerateFDT_SpecialtyTrackingHTML', '2024-06-26', 'bldarosa');
  var html = '';
  var hidden = '';
  for (var i in FDT_SPECIALTYV3.CS_Recs) {
    var C = FDT_SPECIALTYV3.CS_Recs[i];
    if (C.Collapsable == 1) {
      html +=
        '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>' + iTrans(C.Category) + '</h5><div>';
    } else {
      html += '<b>' + iTrans(C.Category) + '</b>';
    }
    var Fields = C.Fields;
    var column = 0;
    var row = '';
    var head = '';
    for (var f in C.Fields) {
      var F = C.Fields[f];
      if (F.Field == 'FTO' || F.Field == 'FLD') {
        if (
          (USER_RIGHTS.FDT_FloatRating == 1 && userShouldHaveFDT(USER_RIGHTS.staffType)) ||
          (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_FloatRating == 1)
        ) {
          F.Used = 1;
        } else {
          F.Used = 0;
        }
      }
      if (F.Used == 0) {
        if (I[F.Field] == undefined) {
          hidden += "<input type='hidden' id='FDT_" + F.Field + "' value='0'>";
        } else {
          hidden += "<input type='hidden' id='FDT_" + F.Field + "' value='" + I[F.Field] + "'>";
        }
      } else {
        head += '<th>' + F.Label + '</th>';
        if (F.DataType == 'Hours') {
          row +=
            "<td><input type='number' onChange='validate_FDT_EXP(this); SaveFDT_Exp(" +
            index +
            ", false);' id='FDT_" +
            F.Field +
            "' value='" +
            I[F.Field] +
            "' onfocus='ExperienceUpdate(this);SaveFDT_Exp(" +
            index +
            ", false);'></td>";
        } else if (F.DataType == 'Count') {
          row +=
            "<td><input type='number' onChange='validate_FDT_EXP(this); SaveFDT_Exp(" +
            index +
            ", false);' id='FDT_" +
            F.Field +
            "' value='" +
            I[F.Field] +
            "' onClick='ExperienceUpdate1(this);SaveFDT_Exp(" +
            index +
            ", false);'></td>";
        } else {
          row +=
            "<td><input type='number' onChange='SaveFDT_Exp(" +
            index +
            ", false);' id='FDT_" +
            F.Field +
            "' value='" +
            I[F.Field] +
            "' ></td>";
        }
        column++;
      }
      if (column == 4) {
        html += '<table class="wrap" border="1"><thead><tr>' + head + '</tr></thead><tbody>';
        html += '<tr>' + row + '</tr></tbody></table>';
        head = '';
        row = '';
        column = 0;
      }
    }
    if (column != 0) {
      html += '<table class="wrap" border="1"><thead><tr>' + head + '</tr></thead><tbody>';
      html += '<tr>' + row + '</tr></tbody></table>';
      head = '';
      row = '';
    }
    if (C.Collapsable == 1) {
      html += '</div></div>';
    }
  }
  return html + hidden;
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function ShowMyPilotInfoSheet() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ShowMyPilotInfoSheet', '2024-06-26', 'bldarosa');
  REPORT_USER_DATA = [];
  $.mobile.loading('show', {
    theme: 'a',
  });
  DUTYTIME = 'REPORT'; //global to prevent client database record marking when it doesn't exist (menu-Duty-SHARED)
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=GetMyPilotInfoSheet&OPK=' + FDT_OPK,
    function (json_data) {
      REPORT_USER_DATA.push(json_data);
      $.mobile.loading('hide');
      var FromDate = new Date();
      FromDate.setFullYear(FromDate.getFullYear() - 1);
      //FromDate = FromDate.toISOString().substring(0, 10);
      var ToDate = new Date();
      for (var i in REPORT_USER_DATA) {
        var User = REPORT_USER_DATA[i];
        var D = UserDuty(
          REPORT_USER_DATA[i].Duty,
          REPORT_USER_DATA[i].LastSync,
          REPORT_USER_DATA[i].experience,
          REPORT_USER_DATA[i].TotalExperience,
          REPORT_USER_DATA[i].List_TypeExperience,
          REPORT_USER_DATA[i].AcTypeExperience,
          REPORT_USER_DATA[i].ClassExperience,
          FromDate,
          ToDate,
          REPORT_USER_DATA[i].TotalExperience12,
          REPORT_USER_DATA[i].List_TypeExperience12,
          REPORT_USER_DATA[i].ClassExperience12,
          REPORT_USER_DATA[i].AcTypeExperience12,
          REPORT_USER_DATA[i]
        );
        REPORT_USER_DATA[i].D = D;
        console.log(REPORT_USER_DATA);
        var Ltotal = {
          PIC: 0,
          SIC: 0,
          Total: 0,
        };
        for (var le in D.Life_TypeExperience) {
          var LE = D.Life_TypeExperience[le];
          if (LE.PIC != 0 || LE.SIC != 0 || LE.FTT != 0) {
            var LFT = LE.FTT;
            Ltotal.PIC += LE.PIC;
            Ltotal.SIC += LE.SIC;
            Ltotal.Total += LFT;
            var lbgclass = '';
          }
        }

        var Ytotal = {
          PIC: 0,
          SIC: 0,
          Total: 0,
        };
        for (var ye in D.TypeExperience) {
          var YE = D.TypeExperience[ye];
          if (YE.PIC != 0 || YE.SIC != 0 || YE.FTT != 0) {
            var YFT = YE.FTT;
            Ytotal.PIC += YE.PIC;
            Ytotal.SIC += YE.SIC;
            Ytotal.Total += YFT;
          }
        }
      }
      Report_PilotInfoV2(0, true);
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function ExitMyInfoSheet() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ExitMyInfoSheet', '2024-06-26', 'bldarosa');
  $('#exitSubReportPage').unbind('click', ExitMyInfoSheet);
  $.mobile.changePage($('#DutyDefaults'), {
    reverse: true,
    changeHash: false,
  });
  DUTYTIME = '';
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function ImportDutyTimes() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ImportDutyTimes', '2024-06-26', 'bldarosa');
  $.mobile.changePage($('#DutyImport'), {
    reverse: false,
    changeHash: false,
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function ExitImportDutytimes() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ExitImportDutytimes', '2024-06-26', 'bldarosa');
  $('#FDT_Import_HLDR').html('');
  document.getElementById('FDTFileSelector').value = '';
  $.mobile.changePage($('#DutyDefaults'), {
    reverse: true,
    changeHash: false,
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function loadFDTfile() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.loadFDTfile', '2024-06-26', 'bldarosa');
  $.mobile.loading('show', {
    theme: 'a',
  });
  $('#FDT_Import_HLDR').html('<h3>' + iTrans('Verifying FDT Records') + '</h3>');
  var file = document.getElementById('FDTFileSelector').files[0];
  var reader = new FileReader();
  reader.onload = function (e) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Account-AJAX.php?action=VerifyFDT&OPK=' + FDT_OPK,
      timeout: 120000,
      data: {
        File: e.target.result,
      },
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        //console.log("ServerResponse: check");
        //console.log(data);
        //ensure data[0].ServerResponse == Success
        $.mobile.loading('hide');
        if (data[0].ServerResponse == 'Failed') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Failed!'),
            headerClose: false,
            buttonPrompt: iTrans('The file selected is not a valid Cirro FDT backup archive'),
            buttons: {
              [iTrans('Close')]: {
                click: function () {
                  document.getElementById('FDTFileSelector').value = '';
                  $('#FDT_Import_HLDR').html('');
                },
              },
            },
          });
        } else if (data[0].ServerResponse == 'Success') {
          ShowFDTOptions(data[0].data);
        } else {
          if (data.ServerResponse == 'Uncaught Exception') {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Failed!'),
              headerClose: false,
              buttonPrompt: data.Exception,
              buttons: {
                [iTrans('Close')]: {
                  click: function () {},
                },
              },
            });
          }
        }
      })
      .fail(function (data) {
        //data.status, data.readyState, data.responseText
        //console.log(data);
        $.mobile.loading('hide');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem verifying the data please select file and try again'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                document.getElementById('FDTFileSelector').value = '';
                $('#FDT_Import_HLDR').html('');
              },
            },
          },
        });
      });
  };
  reader.onerror = function (e) {
    $.mobile.loading('hide');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Failed!'),
      headerClose: false,
      buttonPrompt: iTrans('There was a problem reading the file data please select file and try again'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            document.getElementById('FDTFileSelector').value = '';
            $('#FDT_Import_HLDR').html('');
          },
        },
      },
    });
  };
  reader.readAsDataURL(file);
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param data
 */
function ShowFDTOptions(data) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.ShowFDTOptions', '2024-06-26', 'bldarosa');
  FDT_IMPORT_DATA = data;
  var html = '<h3>' + iTrans('Importing Date Range') + ': ' + data.Days.Start + ' ----> ' + data.Days.End + '</h3>';
  html += '<h3>' + iTrans('Map old FDT custom fields to new group') + '</h3>';
  html +=
    '<table class="limit-table"><tr><td><b>' +
    iTrans('Notes') +
    ': </b><br /><br /><br /><br /></td><td>' +
    iTrans('Match the [CS] Field numbers on the Left to the [Index] numbers on the right') +
    '.<br />' +
    iTrans("Enter '-1' for indexes that you do not have existing FDT data for") +
    '.<br />' +
    iTrans('You cannot assign an index to a [New Fields] label that is blank') +
    '.<br />' +
    iTrans(
      'If you have additional fields that cannot be mapped to the [New Fields] hold on to your import file as we will be improving this tool in the future to accomodate these scenarios'
    ) +
    '.</td></tr></table><br />';

  html +=
    '<table><thead><tr><th>Index</th><th>' +
    iTrans('Old Fields') +
    '</th><th>----></th><th>' +
    iTrans('New Fields') +
    '</th><th>Index</th></tr></thead><tbody>';

  var iFDT_CS = data.FDT_CS;
  var cFDT_CS = JSON.parse(GROUP_DATA.FDT_CSV3);
  var CS_Recs = {};
  var Categories = cFDT_CS.CS_Recs;
  for (var c in Categories) {
    var C = Categories[c];
    for (var f in C.Fields) {
      var F = C.Fields[f];
      if (F.Field.substr(0, 2) == 'CS' || F.Field.substr(0, 2) == 'CN') {
        CS_Recs[F.Field] = F.Label;
      }
    }
  }
  var count = 0;
  var iFDT_CS_convert = {};
  for (var i in iFDT_CS.CS_Recs) {
    var I = iFDT_CS.CS_Recs[i];
    for (var f in I.Fields) {
      iFDT_CS_convert[I.Fields[f].Field] = I.Fields[f].Label;
    }
  }
  for (var i in CS_Recs) {
    var O = iFDT_CS[i];
    if (O == undefined) {
      O = iFDT_CS_convert[i];
    }
    var N = CS_Recs[i];
    if (O == undefined) {
      if (N == '') {
        html +=
          '<tr><td><b>' +
          count +
          '</b></td><td>N/A</td><td>----></td><td>' +
          N +
          ' (' +
          count +
          ')</td><td><b>To </b><input style="width:35px;"type="number" id="I_FDT_' +
          i +
          '" value="-1" data-role="none" step=1 max=109 min=-1 onChange="FDT_ImportVerify(this);" disabled/></td></tr>';
      } else {
        html +=
          '<tr><td><b>' +
          count +
          '</b></td><td>N/A</td><td>----></td><td>' +
          N +
          ' (' +
          count +
          ')</td><td><b>To </b><input style="width:35px;"type="number" id="I_FDT_' +
          i +
          '" value="-1" data-role="none" step=1 max=109 min=-1 onChange="FDT_ImportVerify(this);" disabled/></td></tr>';
      }
    } else {
      if (N == '') {
        html +=
          '<tr><td><b>' +
          count +
          '</b></td><td>' +
          O +
          '</td><td>----></td><td>' +
          N +
          ' (' +
          count +
          ')</td><td><b>To </b><input style="width:35px;"type="number" id="I_FDT_' +
          i +
          '" value="-1" data-role="none" step=1 max=109 min=-1 onChange="FDT_ImportVerify(this);" disabled/></td></tr>';
      } else {
        html +=
          '<tr><td><b>' +
          count +
          '</b></td><td>' +
          O +
          '</td><td>----></td><td>' +
          N +
          ' (' +
          count +
          ')</td><td><b>To </b><input style="width:35px;"type="number" id="I_FDT_' +
          i +
          '" value="' +
          count +
          '" data-role="none" step=1 max=109 min=-1 onChange="FDT_ImportVerify(this);"/></td></tr>';
      }
    }
    count++;
  }
  html += '</tbody></table>';

  html +=
    '<label for="FDT_Import_Delete">' +
    iTrans('Delete All existing FDT records before import') +
    '</label><input data-mini="true" type="checkbox" id="FDT_Import_Delete" />';

  html +=
    '<label for="FDT_Import_Experience">' +
    iTrans('Import Experience Records') +
    '</label><input data-mini="true" type="checkbox" id="FDT_Import_Experience" checked/>';

  html +=
    '<label for="FDT_Import_UserData">' +
    iTrans('Import my Personal Information and Emergency Contacts') +
    '</label><input data-mini="true" type="checkbox" id="FDT_Import_UserData" checked/>';

  html +=
    '<button data-mini="true" data-icon="check" onClick="FDT_Import_Finalize();">' + iTrans('Import Now') + '</button>';

  $('#FDT_Import_HLDR').html(html);
  $('#FDT_Import_HLDR').enhanceWithin();
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param e
 */
function FDT_ImportVerify(e) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_ImportVerify', '2024-06-26', 'bldarosa');
  if (e.value < 0 || e.value == '') {
    $('#' + e.id).val(0);
  }
  if (e.value > 116) {
    alert(iTrans('index must be between 0 and 116'));
    $('#' + e.id).val(0);
  }
}

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @type {null}
 */
var FDT_IMPORT_DATA = null;

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function FDT_Import_Finalize() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_Import_Finalize', '2024-06-26', 'bldarosa');
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      iTrans('Make sure you backup your existing FDT data prior to continuing') +
      '. ' +
      iTrans('These changes are') +
      ' <b>' +
      iTrans('destructive') +
      '</b> ' +
      iTrans('to your existing data') +
      '. ' +
      iTrans(
        'If you have existing records in the system they will be updated with this import data unless you have selected the'
      ) +
      ' [' +
      iTrans('Delete All existing FDT records') +
      '] checkbox.',
    buttons: {
      [iTrans('Continue')]: {
        click: function () {
          FDT_Import_I_AM_SURE();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function FDT_Import_I_AM_SURE() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_Import_I_AM_SURE', '2024-06-26', 'bldarosa');
  $.mobile.loading('show', {
    theme: 'a',
  });
  var cFDT_CS = JSON.parse(GROUP_DATA.FDT_CSV3);
  var CS_Recs = {};
  var Categories = cFDT_CS.CS_Recs;
  for (var c in Categories) {
    var C = Categories[c];
    for (var f in C.Fields) {
      var F = C.Fields[f];
      if (F.Field.substr(0, 2) == 'CS' || F.Field.substr(0, 2) == 'CN') {
        CS_Recs[F.Field] = F.Label;
      }
    }
  }
  var DeleteAll = $('#FDT_Import_Delete').is(':checked');
  var PersonalData = $('#FDT_Import_UserData').is(':checked');
  var Experience = $('#FDT_Import_Experience').is(':checked');
  var MapFields = [];
  for (var i in CS_Recs) {
    var N = CS_Recs[i];
    N = $('#I_FDT_' + i).val();
    MapFields.push(N);
  }
  var file = document.getElementById('FDTFileSelector').files[0];
  var reader = new FileReader();
  reader.onload = function (e) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Account-AJAX.php?action=ImportFDT&OPK=' + FDT_OPK,
      timeout: 120000,
      data: {
        File: e.target.result,
        Fields: MapFields,
        DeleteAll: DeleteAll,
        PersonalData: PersonalData,
        Experience: Experience,
      },
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        //console.log("ServerResponse: check");
        //console.log(data);
        //ensure data[0].ServerResponse == Success
        $.mobile.loading('hide');
        if (data[0].ServerResponse == 'Failed') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Failed!'),
            headerClose: false,
            buttonPrompt: iTrans('The file selected is not a valid Cirro FDT backup archive'),
            buttons: {
              [iTrans('Close')]: {
                click: function () {
                  document.getElementById('FDTFileSelector').value = '';
                  $('#FDT_Import_HLDR').html('');
                },
              },
            },
          });
        } else if (data[0].ServerResponse == 'Success') {
          //do something
          update_Sync('LastSYSTEMsync', new Date()); //To ensure pilot list is updated in case chatname has changed
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt: iTrans('Import completed successfully.  Triggering synchronization now.'),
            buttons: {
              [iTrans('OK')]: {
                click: function () {
                  FDT_IMPORTING_FILE = true;
                  syncFDT(LegacySyncCallBack);
                },
              },
            },
          });
          cirroDB.clearAll('FDT_DaysV4', null);
          cirroDB.clearAll('FDT_RecsV3', null);
        } else {
          if (data.ServerResponse == 'Uncaught Exception') {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Failed!'),
              headerClose: false,
              buttonPrompt: data.Exception,
              buttons: {
                [iTrans('Close')]: {
                  click: function () {},
                },
              },
            });
          }
        }
      })
      .fail(function (data) {
        //data.status, data.readyState, data.responseText
        //console.log(data);
        $.mobile.loading('hide');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem verifying the data please try again'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      });
  };
  reader.onerror = function (e) {
    $.mobile.loading('hide');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Failed!'),
      headerClose: false,
      buttonPrompt: iTrans('There was a problem reading the file data please select file and try again'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            document.getElementById('FDTFileSelector').value = '';
            $('#FDT_Import_HLDR').html('');
          },
        },
      },
    });
  };
  reader.readAsDataURL(file);
}

/**
 * @Deprecated 2024-06-26 This variable was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @type {{doneSync: LegacySyncCallBack.doneSync}}
 */
var LegacySyncCallBack = {
  doneSync: function (status) {
    FDT_IMPORTING_FILE = false;
    goToPage('#DutyTimes');
    initDutyTimes(); //menu-Duty.js
  },
};

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param fdtdate
 */
function FDT_LoadExpenses(fdtdate) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_LoadExpenses', '2024-06-26', 'bldarosa');
  //switch to admin page and get data from server
  FDT_Expense = true;
  var Date = dateToStringObject(fdtdate).substr(0, 10);
  $('#FDT_ExpenseView').html('<h2>' + iTrans('Retrieving Expenses') + '</h2>');
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getUserFDTExpenses&Date=' + Date, function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          const hasPermission = window.vueApp.authTools.authHasPermission(
            window.vueApp.groupKey,
            'billing.expenses.use'
          );
          let disabledText = '';
          if (!hasPermission) {
            disabledText = 'disabled';
          }
          $('#FDT_ExpenseView').html(
            `<h2>${iTrans('Offline cannot retrieve expenses')}</h2>
            <button ${disabledText} class="greenbg" data-icon="plus"  onClick="BILLING_NewExpense();">
            ${iTrans('Create New Expense')}
            </button>`
          );
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'TS_LoadExpenses');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      } else {
        BILLING_EXPENSES = json_data[0];
        FDT_ShowUserExpenses();
      }
    } else {
      const hasPermission = window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, 'billing.expenses.use');
      let disabledText = '';
      if (!hasPermission) {
        disabledText = 'disabled';
      }

      $('#FDT_ExpenseView').html(`<h2>
          ${iTrans('No Expenses Recorded')}
          </h2><button ${disabledText} class="greenbg" data-mini="true"  data-icon="plus" onClick="BILLING_NewExpense();">
          ${iTrans('Create New Expense')}
          </button>`);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

// TODO@Bailey
var FDT_Expense = false;

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function FDT_ShowUserExpenses() {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_ShowUserExpenses', '2024-06-26', 'bldarosa');
  //display list of expenses for users in BILLING_ADMINEXPENSES
  var E = BILLING_EXPENSES.UserExpenses;
  var total = 0;

  if (E.length > 0) {
    var html =
      '<h3>' +
      iTrans('Expenses') +
      '</h3><center><b>' +
      iTrans('Flags') +
      ': A </b>= ' +
      iTrans('Approved') +
      ', <b>P</b> = ' +
      iTrans('Paid') +
      ', <b>B</b> = ' +
      iTrans('Billable') +
      ', <b>I</b> = ' +
      iTrans('Invoiced') +
      ', <b>R</b> = ' +
      iTrans('Reimburseable') +
      '</center>';
    html +=
      '<table id="FDT_ExpensesTable" class="first-td-fill wrap"><thead><tr><th>' +
      iTrans('Job') +
      '</th><th>' +
      iTrans('Client') +
      '</th><th>' +
      iTrans('Date') +
      '</th><th>' +
      iTrans('Category') +
      '</th><th>' +
      iTrans('Merchant') +
      '</th><th>' +
      iTrans('Amount') +
      '</th><th>' +
      iTrans('Flags') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var i in E) {
      var e = E[i];

      //approved
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_ViewExpenseFDT(" +
        i +
        ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></button><button onClick='BILLING_DeleteExpense(" +
        e.ExpensePK +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";

      var bgclass = '';

      if (e.Approved == 0 && e.Reimburse == 1) {
        bgclass = 'redRecord';
      }
      if (e.Approved == 1 && e.Reimburse == 1 && e.Paid == 0) {
        bgclass = 'orangeRecord';
      }
      if ((e.Paid == 1 && e.Reimburse == 1 && e.Approved == 1) || (e.Approved == 1 && e.Reimburse == 0)) {
        bgclass = 'greenRecord';
      }

      //needs to be marked embursed or paid
      if (e.Paid == 0 && e.Approved == 1 && e.Reimburse == 1) {
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_ViewExpenseFDT(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
      }

      //needs approval
      if ((e.Approved == 0 && e.Billable == 1) || (e.Approved == 0 && e.Reimburse == 1)) {
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_EditExpenseFDT(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteExpense(" +
          e.ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
      }

      if (e.Approved == 1 && e.Paid == 1) {
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_ViewExpenseFDT(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
      }

      var A = '';
      if (e.Approved == 1) {
        A = 'A';
      }
      var P = '';
      if (e.Paid == 1) {
        P = 'P';
      }
      var B = '';
      if (e.Billable == 1) {
        B = 'B';
      }
      var I = '';
      if (e.Invoiced == 1) {
        I = 'I';
      }
      var R = '';
      if (e.Reimburse == 1) {
        R = 'R';
      }
      html +=
        '<tr class="' +
        bgclass +
        '"><td>' +
        e.Name +
        '</td><td>' +
        e.Business +
        '</td><td>' +
        e.Date +
        '</td><td>' +
        e.Category +
        '</td><td>' +
        e.Merchant +
        '</td><td>' +
        e.Amount.toLocaleString('en-US', CUR) +
        ' ' +
        e.Currency +
        '</td><td>' +
        A +
        ' ' +
        P +
        ' ' +
        B +
        ' ' +
        I +
        ' ' +
        R +
        '</td><td>' +
        actions +
        '</td></tr>';
      total += e.Amount;
    }
    html += '</table>';

    const hasPermission = window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, 'billing.expenses.use');
    let disabledText = '';
    if (!hasPermission) {
      disabledText = 'disabled';
    }
    html += `<button ${disabledText} class="greenbg" data-icon="plus" data-mini="true" onClick="BILLING_NewExpense();">
          ${iTrans('Create New Expense')}
          </button>`;
    $('#FDT_ExpenseView').html(html);
    $('#FDT_ExpenseView').enhanceWithin();
    AddTableSorter('FDT_ExpensesTable');
  } else {
    const hasPermission = window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, 'billing.expenses.use');
    let disabledText = '';
    if (!hasPermission) {
      disabledText = 'disabled';
    }
    $('#FDT_ExpenseView').html(`<h2>
          ${iTrans('No Expenses Recorded')}
          </h2><button ${disabledText} class="greenbg" data-icon="plus" data-mini="true" onClick="BILLING_NewExpense();">
          ${iTrans('Create New Expense')}
          </button>`);

    $('#FDT_ExpenseView').enhanceWithin();
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param date
 * @returns {string}
 */
function FDT_formatDate_Time(date) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty.FDT_formatDate_Time', '2024-06-26', 'bldarosa');
  var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  var Hours = date.getHours();
  var Minutes = date.getMinutes();
  if (Hours < 10) {
    Hours = '0' + Hours;
  }
  if (Minutes < 10) {
    Minutes = '0' + Minutes;
  }

  return monthNames[monthIndex] + ' ' + day + ', ' + year + ' @ ' + Hours + ':' + Minutes;
}
