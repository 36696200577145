/**
 * Format a template string
 * "Hello, {name}, are you feeling {adjective}?".formatTemplate({name:"Mike", adjective: "OK"});
 */
String.prototype.formatTemplate =
  String.prototype.formatTemplate ||
  function () {
    'use strict';
    let str = this.toString();
    if (arguments.length) {
      let t = typeof arguments[0];
      let key;
      let args = 'string' === t || 'number' === t ? Array.prototype.slice.call(arguments) : arguments[0];

      for (key in args) {
        if (args.hasOwnProperty(key)) {
          str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
        }
      }
    }

    return str;
  };

/**
 * Replaces '%'s in strings with the argument at the same index.
 */
String.prototype.tr =
  String.prototype.tr ||
  function () {
    'use strict';
    let str = this.toString();
    if (arguments.length) {
      for (let key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          str = str.replace(new RegExp('%', 'i'), arguments[key]);
        }
      }
    }

    return str;
  };
