class DatabaseTdg {
  /** @type {SqliteConnection} */
  connection;

  async open() {
    this.connection = await window.vueApp.utilities.sqlite.open('TDG_2021.mbtiles');
  }

  /**
   *
   * @param {string} table
   * @param {string} query
   * @param {string|any[]} optional
   * @returns {Promise<{}[]|false>}
   */
  async select(table, query, optional) {
    await this.open();

    try {
      switch (table) {
        case 'TDG_Identification':
          return this.tdgIdentification(query, optional);
        case 'UN':
          // The Omnissiah help the poor soul who accidentally auto-formats this query
          // @formatter:off
          return this.connection.query({
            q: `select "UN", "Description", "Forbidden", "Class_Div", "P_Group", "Labels", "LQ_PI", "LQ_Max", "PA_PI", "PA_Max", "CA_PI", "CA_Max", "SP_id", "ERG_Num", "ERG2012", "ERG_fr", "PI_LQ", "PI_PA", "PI_CA", "SP" from ( select "UN", "Description", "Forbidden", "Class_Div", "P_Group", "Labels", "LQ_PI", "LQ_Max", "PA_PI", "PA_Max", "CA_PI", "CA_Max", "SP_id", "ERG_Num", "ERG2012", "ERG_fr", "PI_LQ", "PI_PA", "PI" as PI_CA from ( select "UN", "Description", "Forbidden", "Class_Div", "P_Group", "Labels", "LQ_PI", "LQ_Max", "PA_PI", "PA_Max", "CA_PI", "CA_Max", "SP_id", "ERG_Num", "ERG2012", "ERG_fr", "PI_LQ", "PI" as PI_PA from ( select "UN", "Description", "Forbidden", "Class_Div", "P_Group", "Labels", "LQ_PI", "LQ_Max", "PA_PI", "PA_Max", "CA_PI", "CA_Max", "SP_id", "ERG_Num", "ERG2012", "ERG_fr", "PI" as PI_LQ from ( select * from "TDG_Identification" where "PrimaryKey" = ? limit 1 ) as ident inner join "TDG_PI" on "ident"."LQ_PI" = "TDG_PI"."PI_Num" ) as ident2 inner join "TDG_PI" on "ident2"."PA_PI" = "TDG_PI"."PI_Num" ) as ident3 inner join "TDG_PI" on "ident3"."CA_PI" = "TDG_PI"."PI_Num" ) as ident4 inner join "TDG_SP" on "ident4"."SP_id" = "TDG_SP"."SP_Num"`,
            v: [data],
          });
        // @formatter:on
      }
    } catch (e) {
      verbose.error('SQL', 'Error in select', e);
      return false;
    }
  }

  /**
   * @param data
   * @param optional
   * @returns {Promise<any[]>}
   */
  async tdgIdentification(data, optional) {
    switch (optional) {
      case 'Text':
        return this.connection.query({
          q: 'select * from TDG_Identification where Description like ? and UN != 0 limit 100',
          v: [`%${data}%`],
        });

      case 'UN':
        return this.connection.query({
          q: 'select * from TDG_Identification where UN = ? limit 100',
          v: [data],
        });

      case 'PK':
        return this.connection.query({
          q: 'select * from TDG_Identification where PrimaryKey = ? limit 100',
          v: [data],
        });
    }
  }
}
