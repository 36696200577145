/**
 *
 */
class MbTilesTable extends AbstractDatabaseTable {
  constructor() {
    super('Mbtiles', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey  integer primary key,
            Country     text,
            Date        text,
            Edition     text,
            File        text,
            Mbtiles     text,
            Name        text,
            Pub         text,
            coordinates text,
            id          text,
            layers      text,
            type        text
        )
    `;
    //#endregion
  }
}
