/**
 * This encapsulates all the markup and event handling for forms in legacy.
 * Just make a new FormPickerHelper, then append its dom.body to a holder div with class="grid-container-fluid"
 */
class FormPickerHelper extends iSAMA_AbstractObservable {
  constructor(editLevel, linkTarget, linkTargetId) {
    super();
    this.linkTarget = linkTarget;
    this.linkTargetId = linkTargetId;
    this.editLevel = editLevel;

    this.dom = {
      body: this.generateInputElement(),
      rows: {},
      table: null,
      container: null,
      emptyMessage: null,
    };

    this.dom.table = this.dom.body.find('.form-picker__table').first();
    this.dom.container = this.dom.table.find('tbody').first();
    this.dom.emptyMessage = this.dom.body.find('.form-picker__empty-msg').first();
  }

  generateInputElement() {
    //#region const template = `...`;
    const template = `
    <div class="grid-container-fluid sama-styleScope">
      <div class="grid-row">
        <div class="grid-col-sm-12">
          <h2>${iTrans('Attached Forms')}</h2>
        </div>
      </div>

      <div class="grid-row form-picker__attach-button-container">
        <div class="grid-col-sm-6">
          <button class="form-picker__attach-filled-btn ui-btn ui-shadow ui-corner-all ui-mini" data-mini="true" data-enhanced="true">
            <i class="fal fa-paperclip"></i>
            <span>${iTrans('Attach Filled Form')}</span>
          </button>
        </div>
        <div class="grid-col-sm-6">
          <button class="form-picker__attach-new-btn ui-btn ui-shadow ui-corner-all ui-mini" data-mini="true" data-enhanced="true">
            <i class="fal fa-plus"></i>
            <span>${iTrans('New Form')}</span>
          </button>
        </div>
      </div>
      
      <div class="grid-row form-picker__empty-msg">
        <div class="grid-col-sm-12">
          <em>${iTrans('There are no forms attached')}</em>
        </div>
      </div>

      <div class="grid-row form-picker__table" style="display:none;">
        <div class="grid-col-sm-12">
          <table class="fill-width">
            <thead>
              <tr>
                <th>${iTrans('Form Name')}</th>
                <th>${iTrans('QC Status')}</th>
                <th class="align-right">${iTrans('Actions')}</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    `;
    //#endregion

    const jBody = $(template);

    const linkBtnContainer = jBody.find('.form-picker__attach-button-container').first();
    const linkFilledBtn = linkBtnContainer.find('.form-picker__attach-filled-btn').first();
    const linkNewBtn = linkBtnContainer.find('.form-picker__attach-new-btn').first();

    if (this.editLevel === FormBridge.EL_ADMIN) {
      linkFilledBtn.on('click', () => this.UpdateObservers('linkFilled'));
      linkNewBtn.on('click', () => this.UpdateObservers('linkNew'));
    } else {
      linkBtnContainer.remove();
    }

    return jBody;
  }

  addForm(formId, formName, qcStatus, lockedAt) {
    if (this.hasForm(formId)) {
      return;
    }

    let qcText = '';
    let rowClass = '';
    switch (qcStatus) {
      case 'rejected':
        qcText = 'Rejected';
        rowClass = `class="redbg"`;
        break;
      case 'edited':
      case 'pending':
        qcText = 'Pending';
        rowClass = `class="yellowbg"`;
        break;
      case 'approved':
        qcText = 'Approved';
        break;
    }

    const lockIcon = lockedAt != null ? '<i class="sama-softText icon-margins fas fa-lock"></i>' : '';

    //#region const rowTpl = `...`;
    const rowTpl = `
    <tr ${rowClass}>
      <td>${lockIcon}${formName}</td>
      <td>${qcText}</td>
      <td>
        <div class="form-picker__form-list__button-container vertical-center-container flex-justify-end">
        </div>
      </td>
    </tr>`;
    //#endregion

    const jRow = $(rowTpl);
    const btnContainer = jRow.find('.form-picker__form-list__button-container').first();

    if (lockedAt != null) {
      this.makeButton('fal fa-eye')
        .on('click', () => this.UpdateObservers('viewItem', formId))
        .appendTo(btnContainer);
    } else {
      if (this.editLevel >= FormBridge.EL_FILL) {
        this.makeButton('fal fa-pencil')
          .on('click', () => this.UpdateObservers('editItem', formId))
          .appendTo(btnContainer);
      }

      if (this.editLevel === FormBridge.EL_ADMIN) {
        this.makeButton('fal fa-unlink')
          .on('click', () => this.UpdateObservers('unlinkItem', formId))
          .appendTo(btnContainer);
      }
    }

    this.dom.container.append(jRow);
    this.dom.rows[formId] = jRow;
    this.refresh();
  }

  /**
   *
   * @param formId
   * @return {boolean}
   */
  hasForm(formId) {
    return this.dom.rows.hasOwnProperty(formId);
  }

  /**
   *
   * @return {string[]}
   */
  getLoadedFormIds() {
    return Object.keys(this.dom.rows);
  }

  makeButton(icon) {
    const markup = `<button data-enhanced="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini">
    <i class="${icon}"></i>
    </button>`;

    return $(markup);
  }

  removeForm(formId) {
    if (!this.dom.rows.hasOwnProperty(formId)) {
      return;
    }

    this.dom.rows[formId].remove();
    delete this.dom.rows[formId];
    this.refresh();
  }

  refresh() {
    if (Object.keys(this.dom.rows).length > 0) {
      this.dom.emptyMessage.hide();
      this.dom.table.show();
      return;
    }

    this.dom.emptyMessage.show();
    this.dom.table.hide();
  }

  destroy() {
    this.dom.body.remove();
  }

  clear() {
    this.dom.rows = {};
    this.dom.container.html('');
  }
}
