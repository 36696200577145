/**
 *
 */
class SchedulerBookingsTable extends AbstractDatabaseTable {
  constructor() {
    super('Scheduler_Bookings', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"   integer primary key ,
            "ADMIN_ONLY"   integer,
            "Associated"   text,
            "Booked_By"    text,
            "Booked_Date"  text,
            "Client_ID"    integer,
            "Client_Text"  text,
            "Flight_Time"  numeric,
            "Job_ID"       integer,
            "Job_Text"     text,
            "Status"       text,
            "TYPE"         text,
            "UNIT"         text,
            "Updated_By"   text,
            "Updated_Date" text,
            "description"  text,
            "end"          text,
            "group"        text,
            "start"        text
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const isForSync = values[0] === 'SYNC';

    if (isForSync) {
      columns = ['PrimaryKey', 'Updated_Date'];
      values = null;
    }

    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    if (isForSync) {
      const result = {};
      for (let row of rows) {
        //this is a temporary fix for iOS but should't be removed for some time
        row.PrimaryKey = parseInt(row.PrimaryKey);

        result['PK' + row.PrimaryKey] = row;
      }

      return result;
    }

    const parsed = [];
    const parseFailures = [];
    for (let row of rows) {
      //this is a temporary fix for iOS but should't be removed for some time
      row.PrimaryKey = parseInt(row.PrimaryKey);
      try {
        row.Associated = this.parseJson(row.Associated);
      } catch (e) {
        parseFailures.push({ row, e });
        row.Associated = {
          Attachments: [],
          BookedAC: [],
          BookedStaff: [],
          BookedV: [],
          FlightReports: [],
          Forms: [],
          HistoryList: [],
          Label: '',
          MultiBooking: false,
        };
      }

      parsed.push(row);
    }

    if (parseFailures.length > 0) {
      CLogger.error('SCHEDULER', 'Failed to parse Associated on bookings:', parseFailures);
      let formattedError =
        'Sorry, Cirro was unable to process some scheduler bookings. You may need to re-sync your data.';
      formattedError += '\n\n';
      formattedError += parseFailures
        .map((pf) => {
          return `- Booking ID: ${pf.row.PrimaryKey}\nError: ${pf.e.message}`;
        })
        .join('\n');

      alert(formattedError);
    }

    return parsed;
  }
}
