class vSAMA_ListItemReport extends vSAMA_AbstractListViewItem {
  constructor(_report, _highRiskBound, _mediumRiskBound, _readOnly) {
    super(_report);

    this.readOnly = _readOnly === true;
    let formatter = {};

    $.extend(true, formatter, this.model.sync);

    // Report Type
    if (!this.model.IsProactive()) {
      formatter.rep_type = 'Occurrence';
      formatter.rep_occurredObserved = 'Occurred:';
      formatter.rep_typeIcon = 'warning';
      formatter.rep_dateTime = cSAMA_Utils.NiceDate(formatter.rep_dateTime, true, true, true);
    } else {
      formatter.rep_type = 'Hazard';
      formatter.rep_occurredObserved = 'Observed:';
      formatter.rep_typeIcon = 'unlink';
      formatter.rep_dateTime = cSAMA_Utils.NiceDate(formatter.rep_dateTime, true, false, true);
    }

    // Risk Score
    if (formatter.rep_approvedDate == null) {
      // If unapproved, the report won't have current risk score yet
      formatter.rep_riskScoreCurrent = formatter.rep_riskScoreInitial;
      this.searchObject.rep_riskScoreCurrent = this.searchObject.rep_riskScoreInitial;
    }

    formatter.rep_riskClass = 'low';
    if (formatter.rep_riskScoreCurrent == null) {
      formatter.rep_riskScoreCurrent = '0.00';
      formatter.rep_riskClass = 'none';
      this.searchObject.rep_riskScoreCurrent = 0;
    } else if (formatter.rep_riskScoreCurrent >= _highRiskBound) {
      formatter.rep_riskClass = 'high';
    } else if (formatter.rep_riskScoreCurrent >= _mediumRiskBound) {
      formatter.rep_riskClass = 'medium';
    }

    if (formatter.dep_name == null) {
      formatter.dep_name = 'Company Wide';
      formatter.dep_color = '#ffffff';
    }

    if (formatter.rep_riskScoreInitial == null) {
      formatter.rep_riskScoreInitial = '0.00';
      this.searchObject.rep_riskScoreInitial = 0;
    }

    if (formatter.rep_reporterName == null || formatter.rep_reporterName === LOCALSTORAGE.chatname) {
      formatter.rep_reporterName = 'Yourself';
    }

    formatter.rep_submittedDate =
      formatter.rep_submittedDate == null
        ? 'Not yet'
        : cSAMA_Utils.NiceDate(formatter.rep_submittedDate, true, true, true);

    this.SetUpGui(SAMA.Templates.tpl.reports.listItem, formatter);

    this.dom.permissionsBar = this.dom.body.find('.sama-list-item-permissions');
    this.CreateButtons(formatter);

    this.searchObject.rep_summary = '[' + this.searchObject.rep_groupReportId + '] ' + this.searchObject.rep_summary;
    this.searchObject.dep_name = this.searchObject.dep_name == null ? 'Company Wide' : this.searchObject.dep_name;

    this.dom.buttonBar.enhanceWithin();
  }

  CreateButtons(_data) {
    if (!this.readOnly) {
      switch (_data.rep_accessRights) {
        case SAMA.enums.ACCESS.DELETE:
          this.SetBorderColor('#8894de', 'DELETE');
          this.ButtonView();
          this.ButtonDelete({
            label: 'Delete Report',
            message:
              'Assigned staff and the reporter will be notified.<br/>' +
              'Please enter a comment about why you are deleting this report:',
          });
          break;

        case SAMA.enums.ACCESS.EDIT:
          this.SetBorderColor('#7db5d6', 'EDIT');
          this.ButtonEditDraft();
          this.ButtonDelete({
            label: 'Delete Draft',
            message: 'Are you sure you want to delete this draft report?',
            draft: true,
          });
          break;

        case SAMA.enums.ACCESS.REVIEW_WITH_GRANT:
          this.SetBorderColor('#ba96ff', 'MANAGE');
          this.ButtonReview();
          this.ButtonDelete({
            label: 'Delete Report',
            message:
              'Assigned staff and the reporter will be notified.<br/>' +
              'Please enter a comment about why you are deleting this report:',
          });
          break;

        case SAMA.enums.ACCESS.REVIEW:
          this.SetBorderColor('#ffa8f9', 'REVIEW');
          this.ButtonReview();
          break;

        case SAMA.enums.ACCESS.VIEW:
          this.SetBorderColor('#bdbdbd', 'VIEW');
          this.ButtonView();
          break;

        default:
          throw 'There is no access right named ' + _data.rep_accessRights;
      }

      if (this.model.sync.rep_restrictToAdmins > 0) {
        this.SetBorderColor('#ff7e79', 'RESTRICTED');
      }
    }
  }

  SetBorderColor(_color, _text) {
    this.dom.permissionsBar.css('background', _color).find('div').text(_text);
  }

  ButtonView() {
    let btn = new eSAMA_Button({
      label: 'View Details...',
      icon: 'fa-eye',
    });

    btn.GetDom().on('click', () => {
      SAMA.Navigator.GoPage(SAMA.pages.ReportsEdit, {
        reportPk: this.model.GetPk(),
        backPage: SAMA.pages.ReportsList,
      });
    });

    this.AddButton(btn);
  }

  /**
   * Creates a Delete button
   * @param {string} _p.label Label of the button, title of the confirm dialog
   * @param {string} _p.message Message if the confirm dialog
   * @param {boolean} [_p.draft] Whether this is deleting a draft
   * @return {{label: *, icon: string, classes: string, fnClick: fnClick}}
   */
  ButtonDelete(_p) {
    let btn = new eSAMA_Button({
      label: _p.label,
      icon: 'fa-times',
      classes: 'sama-btn-delete',
    });

    btn.GetDom().on('click', () => {
      if (_p.draft === true) {
        this.ConfirmDeleteDraft(_p);
        return;
      }

      this.ConfirmDeleteReport(_p);
    });

    this.AddButton(btn);
  }

  ConfirmDeleteDraft(_p) {
    cSAMA_Dialog.ShowConfirm({
      title: _p.label,
      message: _p.message,
      yesFunc: () => {
        coSAMA_CommonReports.NotifyReportMinimal({
          operand: 'deleted',
          data: {
            reportPk: this.model.GetPk(),
          },
          backAfter: false,
          fn: () => {
            this.Destroy();
          },
        });
      },
    });
  }

  ConfirmDeleteReport(_p) {
    cSAMA_Dialog.ShowTextBox({
      title: _p.label,
      message: _p.message,
      yesFunc: (_comment) => {
        coSAMA_CommonReports.NotifyReportMinimal({
          operand: 'deleted',
          data: {
            reportPk: this.model.GetPk(),
            comment: _comment,
          },
          backAfter: false,
          fn: () => {
            this.Destroy();
          },
        });
      },
    });
  }

  ButtonReview() {
    let btn = new eSAMA_Button({
      label: 'Review...',
      icon: 'fa-check-circle',
      classes: 'adminbg',
    });

    btn.GetDom().on('click', () => {
      SAMA.Navigator.GoPage(SAMA.pages.ReportsEdit, {
        reportPk: this.model.sync.rep_primaryKey,
        matrixVersion: this.model.sync.rep_mtxVersion,
        backPage: SAMA.pages.ReportsList,
      });
    });

    this.AddButton(btn);
  }

  ButtonEditDraft() {
    let btn = new eSAMA_Button({
      label: 'Edit Draft...',
      icon: 'fa-pencil',
    });

    btn.GetDom().on('click', () => {
      SAMA.Navigator.GoPage(SAMA.pages.ReportsEdit, {
        reportPk: this.model.sync.rep_primaryKey,
        matrixVersion: this.model.sync.rep_mtxVersion,
        backPage: SAMA.pages.ReportsList,
      });
    });

    this.AddButton(btn);
  }
}
