/**
 * Class handling editing specific display tasks for risk matrix
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_RiskMatrixEditor extends vSAMA_AbstractEditor {
  /**
   *
   * @param {mSAMA_RiskMatrix}     _riskMatrix
   */
  constructor(_riskMatrix) {
    super();
    this.riskMatrix = _riskMatrix;
  }

  OnChangeGui() {
    let bounds = this.riskMatrix.GetBounds();

    if (+bounds.high <= +bounds.medium) {
      bounds.high = +bounds.medium + 1;

      $('input[data-sref="mtx_boundsHigh"]').val(bounds.high).slider('refresh');
    }

    this.UpdateObservers('MatrixChanged');
  }

  GetDom() {}
}
