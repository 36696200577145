/* ################################ Copyright © 2016 AirSuite Inc. All Rights Reserved   ###################### */
// retrieveRiskForms("Admin" || "User"); Two options to initialize risk assessment system;
var RAform = null; //global holder for current form being edited
var RISK_FORMS = []; //global holder for all risk forms from server
var RISK_TEST = false;
var RISK_DONE_LINK = 'Itinerary';
var RISK_SMS = false;
var RISK_LIST = [];
var RISK_STAFF = [];
var RISK_APPROVE = false;
var RISK_CURRENT_DIV = 'RAformLoad';
function newForm(Item) {
  var Blank_Flight_Risk_Form = {
    PrimaryKey: 0,
    Hibrenated: 0,
    SubmitPrimaryKey: 0,
    Name: '',
    Version: 1,
    Modified: new Date().toISOString(),
    Required: 0,
    DateTime: '',
    Departure: '',
    Destination: '',
    Operation_Type: 'General',
    Risk_Total: 0,
    Flight_Total: 0,
    Status: 'Template',
    Med_Risk_Value: 15,
    Max_Risk_Value: 15,
    Mitigate_Med_Risk: 1,
    FormProcessorVersion: 1,
    FlightReleaseReq: 0,
    FlightReleaseReq_LEG: 0,
    NoGoIfMax: 0,
    HideValues: 0,
    SignatureReq: 0,
    SignatureData: '',
    SignatureName: '',
    Signed: 0,
    SectionLimit: 'none',
    CurLeg: 0,
    HidePerFlight: 0,
    HidePerLeg: 1,
    ItinActive: 0,
    EmailList: [],
    LEGS: [],
    Form: [
      {
        Category: 'New Category Name here',
        Med_Risk_Value: 0,
        Max_Risk_Value: 0,
        Mitigate_Med_Risk: 0,
        Risk_Total: 0,
        Flight_Total: 0,
        Required: 0,
        PerLeg: 0,
        Questions: [
          {
            Selected: 0,
            Risk_Question: 'New Question Example Text',
            Risk_Value: 1,
            Risk_Flight_Value: 0,
            Risk_Mitigation_Value: 0,
            Risk_Mitigation_text: '',
            Risk_Mitigation_Option: 'None',
            Risk_Mitigation_Index: -1,
            Risk_Selected: 0,
            Risk_LockUnselect: 0,
            Risk_Email: 0,
            Admin_Mitigation_Req: 0,
            Risk_Mitigation_Options: [
              {
                text: 'Other',
                value: 1,
              },
            ],
          },
        ],
      },
    ],
  };
  if (Item == 'Form') return Blank_Flight_Risk_Form;
  if (Item == 'Category') return Blank_Flight_Risk_Form.Form[0];
  if (Item == 'Question') return Blank_Flight_Risk_Form.Form[0].Questions[0];
}

function SMS_Menu() {
  RISK_SMS = true;
  RISK_TEST = false;
  $.mobile.changePage($('#SMS_Home'), {
    reverse: false,
    changeHash: false,
  });
}

function exitAdminRA() {
  RISK_TEST = false;
  $.mobile.changePage($('#Administration_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function exitSMS_Menu() {
  $.mobile.changePage($('#MainMenu'), {
    reverse: true,
    changeHash: false,
  });
}

function returnSMSmenu() {
  $('#RA_Form_Approve').remove();
  $('#RA_Form_Deny').remove();
  $.mobile.changePage($('#SMS_Home'), {
    reverse: true,
    changeHash: false,
  });
}

function changeRiskReq() {
  var checked = 0;
  if ($('#RiskRequired').is(':checked')) {
    checked = 1;
  }
  RISK_REQ = checked;
  localStorageDB.setItem('RISK_REQ', RISK_REQ);
  $.getJSON(BASEURL + 'menu-RA-AJAX.php?action=setReq&Checked=' + checked, function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'retrieveTemplate()');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data[0].ServerResponse == 'Offline') {
        if (!VueBridge.canAccessAdminToolMenu()) {
          SAMA.Navigator.GoPage(SAMA.pages.MenuHome, true);
        } else {
          VueBridge.enterAdminToolMenu();
        }
      }
      if (json_data[0].ServerResponse == 'Success') {
        //do nothing everything is good;
      } else {
        //Undo checkmark change not saved for group
        undochangeRiskReq(checked);
      }
    }
  }).fail(function (jqXHR, status, error) {
    //undo checkmark change not saved for group
    undochangeRiskReq(checked);
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Connection Error',
      headerClose: false,
      buttonPrompt: 'There was an error Retrieving the data please try again.',
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}

function undochangeRiskReq(checked) {
  if (checked == 1) {
    $('#RiskRequired').prop('checked', false).checkboxradio('refresh');
    RISK_REQ = 0;
    localStorageDB.setItem('RISK_REQ', RISK_REQ);
  } else {
    $('#RiskRequired').prop('checked', true).checkboxradio('refresh');
    RISK_REQ = 1;
    localStorageDB.setItem('RISK_REQ', RISK_REQ);
  }
}

function retrieveRiskForms(action) {
  if (action == 'Admin') {
    RISK_TEST = true;
    RISK_SMS = false;
    $.mobile.changePage($('#RA_Home'), {
      reverse: false,
      changeHash: false,
    });
    if (RISK_REQ == 1) $('#RiskRequired').prop('checked', true).checkboxradio('refresh');
    else $('#RiskRequired').prop('checked', false).checkboxradio('refresh');
    $('#RAriskForms').html(`<h3>${iTrans('Loading Data...')}</h3>`);
  }
  if (action == 'User') {
    RISK_SMS = false;
    RISK_TEST = false;
    $.mobile.changePage($('#RA_Form'), {
      reverse: false,
      changeHash: false,
    });
    $('#RAform').html(`<h3>${iTrans('Loading Data...')}</h3>`);
  }
  if (action == 'SMS' || action == 'APPROVE') {
    $.mobile.changePage($('#RA_Form_SMS'), {
      reverse: false,
      changeHash: false,
    });
    $('#RAformSMS').html(`<h3>${iTrans('Loading Data...')}</h3>`);
  }
  if (action == 'APPROVE') RISK_APPROVE = true;
  else RISK_APPROVE = false;
  //get risk data from server and populate RISK_FORMS variable
  if (ONLINE) {
    if (LOW_BANDWIDTH) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Low Bandwidth Enabled'),
        headerClose: false,
        buttonPrompt: iTrans(
          'If you were waiting for a Risk Assessment approval click check now.  Otherwise select continue.'
        ),
        buttons: {
          [iTrans('Check Now')]: {
            click: function () {
              retrieveRiskFormsREFRESH(action);
            },
          },
          [iTrans('Continue')]: {
            click: function () {
              retrieveRiskFormsOFFLINE(action);
            },
          },
        },
      });
    } else {
      retrieveRiskFormsREFRESH(action);
    }
  } else {
    retrieveRiskFormsOFFLINE(action);
  }
}
function retrieveRiskFormsREFRESH(action) {
  $.getJSON(BASEURL + 'menu-RA-AJAX.php?action=getUserForms', function (json_data) {
    if (json_data.Forms == undefined) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Connection Error'),
        headerClose: false,
        buttonPrompt: iTrans('There was an error Retrieving the data please try again.'),
        buttons: {
          Close: {
            click: function () {},
          },
        },
      });
      return;
    }
    storeRiskForms(json_data);
    gotForms(json_data, action);
  }).fail(function (jqXHR, status, error) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Connection Error'),
      headerClose: false,
      buttonPrompt: iTrans('There was an error Retrieving the data please try again.'),
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}
function storeRiskForms(json_data) {
  var data = {
    PrimaryKey: 1,
    data: JSON.stringify(json_data),
  };
  cirroDB.insert('RiskForms', data, null, function (e) {});
}

function retrieveRiskFormsOFFLINE(action) {
  cirroDB.query('RiskForms', 'PrimaryKey = ?', 1, function (e) {
    if (e === false || e.length == 0) {
      var empty = {
        Approve: [],
        Forms: [],
        List: [],
        Staff: [],
      };
      gotForms(empty, action);
    } else {
      json_data = JSON.parse(e[0].data);
      gotForms(json_data, action);
    }
  });
}

function gotForms(json_data, action) {
  RISK_FORMS = json_data.Forms;
  if (RISK_APPROVE) RISK_LIST = json_data.Approve;
  else RISK_LIST = json_data.List;
  RISK_STAFF = json_data.Staff;
  if (action == 'Admin') loadRiskForms();
  if (action == 'User' || action == 'SMS' || action == 'APPROVE') RAselect();
}
function loadRiskForms() {
  $('#RAriskForms').html('');
  var html = `<h3>${iTrans('Risk Assessment Forms')}</h3>`;
  if (RISK_FORMS.length > 0) {
    html += `<table class="wrap"><thead><tr><th>${iTrans('Form Name')}</th><th>${iTrans('Operation')}</th><th>${iTrans(
      'Action'
    )}</th></tr></thead><tbody>`;
    for (var i in RISK_FORMS) {
      var Item = RISK_FORMS[i];
      var fOpts =
        '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="copyRiskForm(\'' +
        i +
        '\')" ><img src="./images/copyicon.png" width="20px" height="20px" /></button><button class="action-btn" data-mini="true" onClick="editRiskForm(\'' +
        i +
        '\')" ><img src="./images/edit.svg" /></button><button class="action-btn" data-mini="true" onClick="deleteRiskForm(\'' +
        i +
        '\')" ><img src="./images/delete.svg" /></button></div>';
      html +=
        '<tr class="' +
        (Item.Hibrenated == 1 ? 'yellowbg' : '') +
        '"><td>' +
        Item.Name +
        '</td><td>' +
        Item.Operation_Type +
        '</td><td>' +
        fOpts +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html += iTrans('No Existing Forms');
  }

  $('#RAriskForms').html(html);
  $('#RAriskForms').enhanceWithin();
}

function returnRiskHome() {
  $.mobile.changePage($('#RA_Home'), {
    reverse: true,
    changeHash: false,
  });
  retrieveRiskForms('Admin');
}

function returnRiskForm() {
  $.mobile.changePage($('#RA_Edit'), {
    reverse: true,
    changeHash: false,
  });
  loadRACategories(RAform);
}

function createRiskForm() {
  $('#EditRiskForm').html(iTrans('Loading Form...'));

  var F = newForm('Form');

  RAform = F;
  editRiskFormMaster();
}

function LoadFAATemplate() {
  RAform = RISK_TEMPLATES[parseInt($('#RAselectTemplate').val())].Form;
  editRiskFormMaster();
}

function editRiskForm(F) {
  var index = parseInt(F);
  RAform = RISK_FORMS[index];
  editRiskFormMaster();
}

function copyRiskForm(F) {
  var index = parseInt(F);
  RAform = JSON.parse(JSON.stringify(RISK_FORMS[index]));
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm Copy'),
    headerClose: false,
    buttonPrompt: `${iTrans('Are you sure you want to make a copy of')}"` + RAform.Name + '"?',
    buttons: {
      Yes: {
        click: function () {
          RAform.PrimaryKey = 0;
          RAform.SubmitPrimaryKey = 0;
          RAform.Name += ' (COPY)';
          editRiskFormMaster();
        },
      },
      Cancel: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function editRiskFormMaster() {
  $.mobile.changePage($('#RA_Edit'), {
    reverse: false,
    changeHash: false,
  });
  $('#EditRiskForm').html('Loading Form');
  var html = '<label for="RA_Hibrenate">' + iTrans('Hibernate Risk Assessment') + '</label>';
  html += '<input type="checkbox" id="RA_Hibrenate" ' + (RAform.Hibrenated == 1 ? 'checked' : '') + '/>';
  html += `<button onClick="saveRAForm()" data-mini="true" data-icon="check" class="greenbg">${iTrans(
    'Save Changes'
  )}</button>`;

  html +=
    `<label for="RAname">${iTrans('Form Name')}</label><input type="text" id="RAname" data-mini="true" value="` +
    RAform.Name +
    '"/>';

  html +=
    `<label for="RAoperation">${iTrans(
      'Operation Type'
    )}</label><input type="text" id="RAoperation" data-mini="true" value="` +
    RAform.Operation_Type +
    '" />';

  html +=
    `<label for="RAver">${iTrans('Version')}</label><input type="number" id="RAver" data-mini="true" value="` +
    RAform.Version +
    '"/>';

  if (RAform.Med_Risk_Value == undefined) RAform.Med_Risk_Value = RAform.Max_Risk_Value;
  html +=
    `<label for="RAmedrisk"><div id="SuggestedTotal2">${iTrans(
      'Medium Risk Score'
    )}</div></label><input type="number" id="RAmedrisk" data-mini="true" value="` +
    RAform.Med_Risk_Value +
    '"/>';

  if (RAform.Mitigate_Med_Risk == undefined) RAform.Mitigate_Med_Risk = 1;
  if (RAform.Mitigate_Med_Risk == 0)
    html += `<label for="RAmitMed">${iTrans(
      'Medium Risk Requires Flight Release'
    )}</label><input type="checkbox" id="RAmitMed" data-mini="true" />`;
  else
    html += `<label for="RAmitMed">${iTrans(
      'Medium Risk Requires Flight Release'
    )}</label><input type="checkbox" id="RAmitMed" data-mini="true" checked/>`;

  html +=
    '<label for="RAmaxrisk"><div id="SuggestedTotal"></div></label><input type="number" id="RAmaxrisk" data-mini="true" value="' +
    RAform.Max_Risk_Value +
    '"/>';
  if (RAform.Required == 0)
    html += `<label for="RAreq">${iTrans(
      'Low Risk Required to Continue Activity'
    )}</label><input type="checkbox" id="RAreq" data-mini="true" />`;
  else
    html += `<label for="RAreq">${iTrans(
      'Low Risk Required to Continue Activity'
    )}</label><input type="checkbox" id="RAreq" data-mini="true" checked/>`;

  if (RAform.FlightReleaseReq == undefined) RAform.FlightReleaseReq = 0;
  if (RAform.FlightReleaseReq == 0)
    html += `<label for="RArelease">${iTrans(
      'Flight Release always required'
    )}</label><input type="checkbox" id="RArelease" data-mini="true" />`;
  else
    html += `<label for="RArelease">${iTrans(
      'Flight Release always required'
    )}</label><input type="checkbox" id="RArelease" data-mini="true" checked/>`;

  if (RAform.NoGoIfMax == undefined) RAform.NoGoIfMax = 0;
  if (RAform.NoGoIfMax == 0)
    html += `<label for="RANoGoIfMax">${iTrans(
      'Prevent Activity if Beyond Max Score'
    )}</label><input type="checkbox" id="RANoGoIfMax" data-mini="true" />`;
  else
    html += `<label for="RANoGoIfMax">${iTrans(
      'Prevent Activity if Beyond Max Score'
    )}</label><input type="checkbox" id="RANoGoIfMax" data-mini="true" checked/>`;

  if (RAform.HideValues == undefined) RAform.HideValues = 0;
  if (RAform.HideValues == 0)
    html += `<label for="RAHideValues">${iTrans(
      'Hide Question And Mitigation Values'
    )}</label><input type="checkbox" id="RAHideValues" data-mini="true" />`;
  else
    html += `<label for="RAHideValues">${iTrans(
      'Hide Question And Mitigation Values'
    )}</label><input type="checkbox" id="RAHideValues" data-mini="true" checked/>`;

  if (RAform.SignatureReq == undefined) RAform.SignatureReq = 0;
  if (RAform.SignatureReq == 0)
    html += `<label for="RASignatureReq">${iTrans(
      'Signature Required'
    )}</label><input type="checkbox" id="RASignatureReq" data-mini="true" />`;
  else
    html += `<label for="RASignatureReq">${iTrans(
      'Signature Required'
    )}</label><input type="checkbox" id="RASignatureReq" data-mini="true" checked/>`;

  if (RAform.EmailList == undefined) RAform.EmailList = [];
  html += `<h3>${iTrans('Approval Personnel')}</h3>`;
  html += '<div id="RAemailList"></div>';
  html += `<button onClick="RAaddUsers()" class="greenbg" data-icon="plus" data-mini="true">${iTrans(
    'Add user to approval list'
  )}</button>`;

  html += '<br />';
  html += '<div id="RAedit"></div>';

  $('#EditRiskForm').html(html);
  $('#EditRiskForm').enhanceWithin();
  loadEmailList();
  loadRACategories(RAform);
}

function RAaddUsers() {
  var html = '';
  for (var u in RISK_STAFF) {
    var U = RISK_STAFF[u];
    var found = false;
    for (var e in RAform.EmailList) {
      var E = RAform.EmailList[e];
      if (E.PrimaryKey == U.PrimaryKey) found = true;
    }
    if (!found)
      html +=
        '<button onClick="RAaddSelectedUser(' +
        u +
        ', false);" data-mini="true" data-icon="plus" class="greenbg">' +
        U.chatname +
        '</button>';
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Add User'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function RAaddSelectedUser(e, edit) {
  if (!edit) $.mobile.sdCurrentDialog.close();
  var html = `<h2>${iTrans('Email Options')}</h2>`;
  var checkedCC = 'checked';
  var checkedSEL = 'checked';
  var checkedMED = 'checked';
  var checkedMAX = 'checked';
  var checkedAPP = 'checked';
  var U;
  if (edit) {
    U = RAform.EmailList[e];
    //check for per version 1.33.1 missing item
    if (U.EmailOpts != undefined) {
      if (!U.EmailOpts.CC) checkedCC = '';
      if (!U.EmailOpts.SEL) checkedSEL = '';
      if (!U.EmailOpts.MED) checkedMED = '';
      if (!U.EmailOpts.MAX) checkedMAX = '';
      if (!U.EmailOpts.APP) checkedAPP = '';
    }
  }
  html +=
    `<label for="RAemailOPT_CC">${iTrans(
      'Always email (CC)'
    )}</label><input type="checkbox" id="RAemailOPT_CC" data-mini="true" ` +
    checkedCC +
    '/>';
  html +=
    `<label for="RAemailOPT_SEL">${iTrans(
      'Send if Selected (SEL)'
    )}</label><input type="checkbox" id="RAemailOPT_SEL" data-mini="true" ` +
    checkedSEL +
    '/>';
  html +=
    `<label for="RAemailOPT_MED">${iTrans(
      'Med Risk Score (MED)'
    )}</label><input type="checkbox" id="RAemailOPT_MED" data-mini="true" ` +
    checkedMED +
    '/>';
  html +=
    `<label for="RAemailOPT_MAX">${iTrans(
      'Max Risk Score (MAX)'
    )}</label><input type="checkbox" id="RAemailOPT_MAX" data-mini="true" ` +
    checkedMAX +
    '/>';
  html +=
    `<label for="RAemailOPT_APP">${iTrans(
      'Approve RA by Email/SMS (APPR)'
    )}</label><input type="checkbox" id="RAemailOPT_APP" data-mini="true" ` +
    checkedAPP +
    '/>';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Add User'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      'Add User': {
        click: function () {
          var EmailOpts = {
            CC: $('#RAemailOPT_CC').is(':checked'),
            SEL: $('#RAemailOPT_SEL').is(':checked'),
            MED: $('#RAemailOPT_MED').is(':checked'),
            MAX: $('#RAemailOPT_MAX').is(':checked'),
            APP: $('#RAemailOPT_APP').is(':checked'),
          };
          if (edit) {
            U.EmailOpts = EmailOpts;
          } else {
            RISK_STAFF[e].EmailOpts = EmailOpts;
            RAform.EmailList.push(RISK_STAFF[e]);
          }
          $('#RAemailList').html(`<h3>${iTrans('Adding User...')}</h3>`);
          setTimeout(function () {
            loadEmailList();
          }, 500);
        },
      },
      Cancel: {
        click: function () {},
      },
    },
  });
}

function RAremoveUser(e) {
  RAform.EmailList.splice(e, 1);
  loadEmailList();
}

function loadEmailList() {
  $('#RAemailList').html('');
  if (RAform.EmailList.length == 0) {
    $('#RAemailList').html(`<h4>${iTrans('No Users Listed')}</h4>`);
    return;
  }
  var html = `<table class="limit-table first-td-fill" ><thead><tr><th>${iTrans('Name')}</th><th>${iTrans(
    'Email'
  )}</th><th>${iTrans('Opts')}</th><th>${iTrans('Action')}</th></tr></thead><tbody>`;
  for (var e in RAform.EmailList) {
    var E = RAform.EmailList[e];
    var opts = '';
    if (E.EmailOpts != undefined) {
      if (E.EmailOpts.CC) opts += 'CC ';
      if (E.EmailOpts.SEL) opts += 'SEL ';
      if (E.EmailOpts.MED) opts += 'MED ';
      if (E.EmailOpts.MAX) opts += 'MAX ';
      if (E.EmailOpts.APP) opts += 'APPR ';
    } else {
      opts = 'CC SEL MED MAX APPR ';
    }
    var action =
      '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="RAaddSelectedUser(' +
      e +
      ', true)" ><img src="./images/edit.svg" /></button><button class="action-btn" data-mini="true" onClick="RAremoveUser(' +
      e +
      ')" ><img src="./images/delete.svg" /></button></div>';
    html +=
      '<tr><td>' + E.chatname + '</td><td>' + E.username + '</td><td>' + opts + '</td><td>' + action + '</td></tr>';
  }
  html += '</tbody></table>';
  $('#RAemailList').html(html);
  $('#RAemailList').enhanceWithin();
}

function RA_Order_DOWN(i, q) {
  if (q == RAform.Form[i].Questions.length - 1) return;
  q = parseInt(q);
  nq = q + 1;
  RAform.Form[i].Questions = RA_array_move(RAform.Form[i].Questions, q, nq);
  loadRACategories(RAform);
}
function RA_Order_UP(i, q) {
  if (q == 0) return;
  q = parseInt(q);
  nq = q - 1;
  RAform.Form[i].Questions = RA_array_move(RAform.Form[i].Questions, q, nq);
  loadRACategories(RAform);
}
function RA_array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}
function loadRACategories(F) {
  var Forms = F.Form;
  var html = '';
  if (Forms.length > 0) {
    var Total = 0;
    var CatTotal = [];
    for (var i in Forms) {
      var totalRisk = 0;
      var Form = Forms[i];
      if (Form.PerLeg == undefined) Form.PerLeg = 0;
      var disableCHK = '';
      if (Form.PerLeg == 1) disableCHK = 'disabled';
      html += '<center><h1><----------' + `${iTrans('New Category')}` + '----------></h1></center>';
      html +=
        '<label for="RAcat-' +
        i +
        `">${iTrans('Category')}</label><input id="RAcat-` +
        i +
        '" type="text" value="' +
        Form.Category +
        '" data-mini="true" onChange="updateRAcat(\'' +
        i +
        '\')"/>';

      if (Form.Med_Risk_Value == undefined) Form.Med_Risk_Value = 0;
      html +=
        '<label for="RAmedrisk-' +
        i +
        '"><div id="SuggestedTotal2-' +
        i +
        `">${iTrans(
          'Category Medium Risk Score (Enter 0 to disable)'
        )}</div></label><input type="number" id="RAmedrisk-` +
        i +
        '" data-mini="true" value="' +
        Form.Med_Risk_Value +
        '" onChange="updateRAcat(\'' +
        i +
        '\')"/>';

      if (Form.Mitigate_Med_Risk == undefined || Form.PerLeg == 1) Form.Mitigate_Med_Risk = 0;
      if (Form.Mitigate_Med_Risk == 0)
        html +=
          '<label for="RAmitMed-' +
          i +
          `">${iTrans('Category Medium Risk Requires Flight Release')}</label><input type="checkbox" id="RAmitMed-` +
          i +
          '" data-mini="true" onClick="updateRAcat(\'' +
          i +
          '\')" ' +
          disableCHK +
          '/>';
      else
        html +=
          '<label for="RAmitMed-' +
          i +
          `">${iTrans('Category Medium Risk Requires Flight Release')}</label><input type="checkbox" id="RAmitMed-` +
          i +
          '" data-mini="true" onClick="updateRAcat(\'' +
          i +
          '\')" checked/>';

      if (Form.Max_Risk_Value == undefined) Form.Max_Risk_Value = 0;
      html +=
        '<label for="RAmaxrisk-' +
        i +
        '"><div id="SuggestedTotal-' +
        i +
        `">${iTrans(
          'Category Maximum Risk Total (Enter 0 to disable)'
        )}</div></label><input type="number" id="RAmaxrisk-` +
        i +
        '" data-mini="true" value="' +
        Form.Max_Risk_Value +
        '" onChange="updateRAcat(\'' +
        i +
        '\')"/>';

      if (Form.Required == undefined || Form.PerLeg == 1) Form.Required = 0;
      if (Form.Required == 0)
        html +=
          '<label for="RAreq-' +
          i +
          `">${iTrans('Category Low Risk Required to Continue Activity')}</label><input type="checkbox" id="RAreq-` +
          i +
          '" data-mini="true" onClick="updateRAcat(\'' +
          i +
          '\')" ' +
          disableCHK +
          '/>';
      else
        html +=
          '<label for="RAreq-' +
          i +
          `">${iTrans('Category Low Risk Required to Continue Activity')}</label><input type="checkbox" id="RAreq-` +
          i +
          '" data-mini="true" onClick="updateRAcat(\'' +
          i +
          '\')" checked/>';

      if (Form.PerLeg == 0)
        html +=
          '<label for="RAperleg-' +
          i +
          `">${iTrans('Per Flight Leg')}</label><input type="checkbox" id="RAperleg-` +
          i +
          '" data-mini="true" onClick="updateRAcat(\'' +
          i +
          '\')" />';
      else
        html +=
          '<label for="RAperleg-' +
          i +
          `">${iTrans('Per Flight Leg')}</label><input type="checkbox" id="RAperleg-` +
          i +
          '" data-mini="true" onClick="updateRAcat(\'' +
          i +
          '\')" checked/>';

      html += `<table class='first-td-fill'><thead><tr><th>${iTrans('Question')}</th><th>${iTrans(
        'Value'
      )}</th><th>${iTrans('Action')}</th><th>${iTrans('Order')}</th></tr></thead><tbody>`;
      for (var q in Form.Questions) {
        var Q = Form.Questions[q];
        var orderOpts =
          '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="RA_Order_UP(' +
          i +
          ',' +
          q +
          ')" ><img src="./images/Plus-icon.png" widht="20px" height="20px" /></button><button class="action-btn" data-mini="true" onClick="RA_Order_DOWN(' +
          i +
          ',' +
          q +
          ')" ><img src="./images/Minus-icon.png" widht="20px" height="20px" /></button></div>';

        var qOpts =
          '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="loadRAQuestion(\'' +
          i +
          '-' +
          q +
          '\')" ><img src="./images/edit.svg" /></button><button class="action-btn" data-mini="true" onClick="deleteRAQuestion(\'' +
          i +
          '-' +
          q +
          '\')" ><img src="./images/delete.svg" /></button></div>';

        html +=
          '<tr><td>' +
          Q.Risk_Question +
          '</td><td>' +
          Q.Risk_Value +
          '</td><td>' +
          qOpts +
          '</td><td>' +
          orderOpts +
          '</td></tr>';
        if (parseInt(Q.Risk_Value) > 0) totalRisk += parseInt(Q.Risk_Value);
      }
      html +=
        `<tr><td style="text-align:right;"><b>${iTrans('Category Total')}</b></td><td><b>` +
        totalRisk +
        '</b></td><td><button onclick="newRAQuestion(\'' +
        i +
        `\')" data-mini="true" data-icon="plus" class="greenbg">${iTrans('New')}</button>`;
      html += '</tbody></table><br />';
      Total += totalRisk;
      CatTotal.push(totalRisk);
      totalRisk = 0;
    }
    $('#SuggestedTotal').html(`${iTrans('Maximum Risk Total')} / ` + Total);
    $('#SuggestedTotal2').html(`${iTrans('Medium Risk Total')} / ` + Total);
  } else {
    $('#SuggestedTotal').html(iTrans('Maximum Risk Total'));
    $('#SuggestedTotal2').html(iTrans('Medium Risk Total'));
    html += `<h3>${iTrans('There are no Categories or Questions')}</h3>`;
  }
  html += `<button onClick="newRACat()" data-mini="true" data-icon="plus">${iTrans('New Category')}</button>`;
  $('#RAedit').html(html);
  $('#RAedit').enhanceWithin();
  for (var i in Forms) {
    $('#SuggestedTotal-' + i).html(
      `${iTrans('Category Maximum Risk Total')} / ` + CatTotal[i] + ` ${iTrans('(Enter 0 to disable)')}`
    );
    $('#SuggestedTotal2-' + i).html(
      `${iTrans('Category Medium Risk Total')} / ` + CatTotal[i] + ` ${iTrans('(Enter 0 to disable)')}`
    );
  }
}

function updateRAcat(C) {
  var index = parseInt(C);
  var I = RAform.Form[index];
  I.Category = $('#RAcat-' + C).val();
  I.Med_Risk_Value = $('#RAmedrisk-' + C).val();

  if ($('#RAmitMed-' + C).is(':checked')) I.Mitigate_Med_Risk = 1;
  else I.Mitigate_Med_Risk = 0;

  I.Max_Risk_Value = $('#RAmaxrisk-' + C).val();

  if ($('#RAreq-' + C).is(':checked')) I.Required = 1;
  else I.Required = 0;

  if ($('#RAperleg-' + C).is(':checked')) I.PerLeg = 1;
  else I.PerLeg = 0;
  loadRACategories(RAform);
}

function loadRAQuestion(Q) {
  $.mobile.changePage($('#RA_EditQ'), {
    reverse: false,
    changeHash: false,
  });
  var RAitem = Q.split('-');
  console.log(RAform);
  RAform.CurForm = parseInt(RAitem[0]);
  RAform.CurQuestion = parseInt(RAitem[1]);
  var Item = RAform.Form[parseInt(RAitem[0])].Questions[parseInt(RAitem[1])];
  if (Item.Risk_Selected == undefined) Item.RiskSelected = 0;
  if (Item.Risk_LockUnselect == undefined) Item.Risk_LockUnselect = 0;
  if (Item.Risk_Email == undefined) Item.Risk_Email = 0;

  $('#RAquestion').val(Item.Risk_Question);
  $('#RAvalue').val(Item.Risk_Value);
  if (Item.Admin_Mitigation_Req == 1) $('#RAadminreq').prop('checked', true).checkboxradio('refresh');
  else $('#RAadminreq').prop('checked', false).checkboxradio('refresh');
  if (Item.Risk_Selected == 1) $('#RAprechecked').prop('checked', true).checkboxradio('refresh');
  else $('#RAprechecked').prop('checked', false).checkboxradio('refresh');
  if (Item.Risk_LockUnselect == 1) $('#RAquestionlock').prop('checked', true).checkboxradio('refresh');
  else $('#RAquestionlock').prop('checked', false).checkboxradio('refresh');
  if (Item.Risk_Email == 1) $('#RAemailifchecked').prop('checked', true).checkboxradio('refresh');
  else $('#RAemailifchecked').prop('checked', false).checkboxradio('refresh');
  loadRAMitigations(Item.Risk_Mitigation_Options);
}

function saveRAquestion() {
  var Item = RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion];
  Item.Risk_Question = $('#RAquestion').val();
  Item.Risk_Value = $('#RAvalue').val();
  if ($('#RAadminreq').is(':checked')) Item.Admin_Mitigation_Req = 1;
  else Item.Admin_Mitigation_Req = 0;
  if ($('#RAprechecked').is(':checked')) Item.Risk_Selected = 1;
  else Item.Risk_Selected = 0;
  if ($('#RAquestionlock').is(':checked')) Item.Risk_LockUnselect = 1;
  else Item.Risk_LockUnselect = 0;
  if ($('#RAemailifchecked').is(':checked')) Item.Risk_Email = 1;
  else Item.Risk_Email = 0;
  if (Item.Risk_Selected == 1) Item.Selected = 1;
  else Item.Selected = 0;
  //Check if new mitigation option was typed but not saved
  var text = $('#RAnewMtext').val();
  var value = $('#RAnewMvalue').val();
  if (text != '' && value != '')
    RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options.push({
      text: text,
      value: value,
    });
  //Send newly saved form to server and return to previous page
  returnRiskForm();
  saveRAForm(false);
}
function RAM_Order_DOWN(q) {
  if (q == RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options.length - 1) return;
  q = parseInt(q);
  nq = q + 1;
  RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options = RA_array_move(
    RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options,
    q,
    nq
  );
  loadRAMitigations(RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options);
}
function RAM_Order_UP(q) {
  if (q == 0) return;
  q = parseInt(q);
  nq = q - 1;
  RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options = RA_array_move(
    RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options,
    q,
    nq
  );
  loadRAMitigations(RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options);
}
function loadRAMitigations(M) {
  $('#RAmitigationlist').html(`${iTrans('Loading Mitigating Options')}`);
  var html = `<table class="first-td-fill"><thead><tr><th>${iTrans('Option')}</th><th>${iTrans(
    'Value'
  )}</th><th>${iTrans('Action')}</th><th>${iTrans('Order')}</th></tr></thead><tbody>`;
  for (var i in M) {
    var mOpts;
    var orderOpts =
      '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="RAM_Order_UP(' +
      i +
      ')" ><img src="./images/Plus-icon.png" widht="20px" height="20px" /></button><button class="action-btn" data-mini="true" onClick="RAM_Order_DOWN(' +
      i +
      ')" ><img src="./images/Minus-icon.png" widht="20px" height="20px" /></button></div>';

    if (M.length == 1)
      mOpts =
        '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="editRAmitigation(\'' +
        i +
        '\')" ><img src="./images/edit.svg" /></button><button class="action-btn" data-mini="true" onClick="deleteRAmitigation(\'' +
        i +
        '\')" disabled ><img src="./images/delete.svg" /></button></div>';
    else
      mOpts =
        '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="editRAmitigation(\'' +
        i +
        '\')" ><img src="./images/edit.svg" /></button><button class="action-btn" data-mini="true" onClick="deleteRAmitigation(\'' +
        i +
        '\')" ><img src="./images/delete.svg" /></button></div>';
    html +=
      '<tr><td>' + M[i].text + '</td><td>' + M[i].value + '</td><td>' + mOpts + '</td><td>' + orderOpts + '</td></tr>';
  }
  html += `<tr><td><input type="text" id="RAnewMtext" placeholder="New Mitigation Option"/></td><td><input type="number" id="RAnewMvalue" value="1" style="width:50px;"/></td><td><button data-icon="plus" data-mini="true" class="greenbg" onClick="newMitigationOption()">${iTrans(
    'New'
  )}</button></td></tr>`;
  html += '</tbody></table>';
  $('#RAmitigationlist').html(html);
  $('#RAmitigationlist').enhanceWithin();
}

function editRAmitigation(M) {
  $('#RAmitigationlist').html(iTrans('Editing Mitigating Options'));
  RAform.CurMitigation = parseInt(M);
  var Item = RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options[RAform.CurMitigation];
  var html = `<table class="first-td-fill"><thead><tr><th>${iTrans('Option')}</th><th>${iTrans(
    'Value'
  )}</th><th>${iTrans('Action')}</th></tr></thead><tbody>`;
  html +=
    '<tr><td><input type="text" id="RAnewMtext" value="' +
    Item.text +
    '"/></td><td><input type="number" id="RAnewMvalue" value="' +
    Item.value +
    `" style="width:50px;"/></td><td><button data-icon="check" data-mini="true" class="greenbg" onClick="saveMitigationOption()">${iTrans(
      'Done'
    )}</button></td></tr>`;
  html += '</tbody></table>';
  $('#RAmitigationlist').html(html);
  $('#RAmitigationlist').enhanceWithin();
}

function saveMitigationOption() {
  var Item = RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options[RAform.CurMitigation];
  Item.text = $('#RAnewMtext').val();
  Item.value = $('#RAnewMvalue').val();
  loadRAMitigations(RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options);
}

function newMitigationOption() {
  var text = $('#RAnewMtext').val();
  var value = $('#RAnewMvalue').val();
  if (text == '') return;
  if (value == '') return;
  RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options.push({
    text: text,
    value: value,
  });
  loadRAMitigations(RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options);
}

function deleteRAmitigation(i) {
  var index = parseInt(i);
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this?'),
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options.splice(index, 1);
          loadRAMitigations(RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion].Risk_Mitigation_Options);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function newRAQuestion(Q) {
  RAform.CurForm = parseInt(Q);
  var NewQuestion = newForm('Question');
  RAform.Form[RAform.CurForm].Questions.push(NewQuestion);
  var Index = RAform.Form[RAform.CurForm].Questions.length - 1;
  loadRAQuestion(RAform.CurForm + '-' + Index);
}

function newRACat() {
  var NewCat = newForm('Category');
  RAform.Form.push(NewCat);
  loadRACategories(RAform);
}

function deleteRAQuestion(Q) {
  var RAitem = Q.split('-');
  console.log(RAform);
  RAform.CurForm = parseInt(RAitem[0]);
  RAform.CurQuestion = parseInt(RAitem[1]);

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this?'),
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          RAform.Form[RAform.CurForm].Questions.splice(RAform.CurQuestion, 1);
          if (RAform.Form[RAform.CurForm].Questions.length == 0) RAform.Form.splice(RAform.CurForm, 1);
          loadRACategories(RAform);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function deleteRiskForm(R) {
  var index = parseInt(R);
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this?'),
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          var PrimaryKey = RISK_FORMS[index].PrimaryKey;
          console.log('Delete Risk Form PK: ' + PrimaryKey);
          //Send server request to delete record and upon success do
          $.getJSON(BASEURL + 'menu-RA-AJAX.php?action=deleteForm&PrimaryKey=' + PrimaryKey, function (json_data) {
            if (json_data[0].ServerResponse != undefined) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Success') {
                RISK_FORMS.splice(index, 1);
                retrieveRiskForms('Admin');
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'deleteRiskForm()');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Offline') {
                $.mobile.changePage($('#MainMenu'), {
                  reverse: true,
                  changeHash: false,
                });
              }
            }
          }).fail(function (jqXHR, status, error) {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Connection Error'),
              headerClose: false,
              buttonPrompt: iTrans('There was an error deleting the data please try again.'),
              buttons: {
                Close: {
                  click: function () {},
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function saveRAForm(goback = true) {
  //Add validation for name and operation types

  RAform.Name = $('#RAname').val();
  RAform.Version = $('#RAver').val();
  RAform.Med_Risk_Value = $('#RAmedrisk').val();
  RAform.Max_Risk_Value = $('#RAmaxrisk').val();

  RAform.Operation_Type = $('#RAoperation').val();
  RAform.Modified = new Date().toISOString();

  if ($('#RAmitMed').is(':checked')) RAform.Mitigate_Med_Risk = 1;
  else RAform.Mitigate_Med_Risk = 0;

  if ($('#RAreq').is(':checked')) RAform.Required = 1;
  else RAform.Required = 0;

  if ($('#RArelease').is(':checked')) RAform.FlightReleaseReq = 1;
  else RAform.FlightReleaseReq = 0;

  if ($('#RAHideValues').is(':checked')) RAform.HideValues = 1;
  else RAform.HideValues = 0;

  if ($('#RASignatureReq').is(':checked')) RAform.SignatureReq = 1;
  else RAform.SignatureReq = 0;

  if (RAform.Status == 'Template') RISK_FORMS.push(RAform);
  RAform.Status = 'Ready';
  //Todo Send updated form to server
  RAform.Hibrenated = $('#RA_Hibrenate').is(':checked') ? 1 : 0;
  RAform.NoGoIfMax = $('#RANoGoIfMax').is(':checked') ? 1 : 0;
  var Form_Data = JSON.stringify(RAform);

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-RA-AJAX.php?action=saveForm',
    data: {
      PrimaryKey: RAform.PrimaryKey,
      Name: RAform.Name,
      Operation: RAform.Operation_Type,
      Version: RAform.Version,
      Form: Form_Data,
      Hibrenated: RAform.Hibrenated,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'saveRAForm()');
        //document.getElementById("saveFlightTemplate").disabled = false; //update for save template btn
      }
      if (data[0].ServerResponse == 'Success') {
        if (RAform.PrimaryKey == 0) RAform.PrimaryKey = data[0].PrimaryKey;
        if (goback) returnRiskHome();
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving your form.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            Close: {
              click: function () {
                //document.getElementById("saveFlightTemplate").disabledd = false; //update for save template btn
              },
            },
            Report: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving your form.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          Close: {
            click: function () {
              //document.getElementById("saveFlightTemplate").disabled = false;  //update for dave template btn
            },
          },
          Report: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

/******************************************** Start Risk Assessment *******************************/

function RAselect() {
  RAform = null; //reset form to null
  var div = 'RAform';
  if (RISK_SMS) div = 'RAformSMS';
  $('#RAform').html('');
  $('#RAformSMS').html('');
  $('#RA_Form_Approve').remove();
  $('#RA_Form_Deny').remove();
  var html = '<div id="RAformSelectDIV">';
  RAform = null;
  if (RISK_TEST) {
    div = 'RAform';
    $.mobile.changePage($('#RA_Form'), {
      reverse: false,
      changeHash: false,
    });
    $('#RISKTEST').hide();
  }
  if (!RISK_APPROVE) {
    if (RISK_FORMS.length > 0) {
      if (RISK_REQ == 1) document.getElementById('RISKNEXT').disabled = true;
      else document.getElementById('RISKNEXT').disabled = false;
      html += `<label for="RAformSelect" data-mini="true">${iTrans(
        'Select Operation Type'
      )}</label><select data-testid="risk-assesment-form-select" id="RAformSelect">`;
      html += `<option value="-1" selected>${iTrans('Nothing Selected')}</option>`;
      for (var i in RISK_FORMS) {
        var Item = RISK_FORMS[i];
        if (Item.Hibrenated == 1) continue;
        html += '<option value="' + i + '">' + Item.Operation_Type + '</option>';
      }
      html += '</select>';
    } else {
      html += `<h3>${iTrans('There are no Risk Assessments to Choose!')}</h3>`;
      document.getElementById('RISKNEXT').disabled = false;
    }
  }
  if (!RISK_APPROVE && !RISK_TEST) {
    if (RISK_LIST.length > 0) {
      html += `<h3>${iTrans('Select Existing Risk Assessment')}</h3>`;
      html += `<table class="limit-table"><thead><tr><th>${iTrans('ID')}</th><th>${iTrans('Comment')}</th><th>${iTrans(
        'Operation'
      )}</th><th>${iTrans('Date')}</th><th>${iTrans('Status')}</th><th>${iTrans('Action')}</th></tr></thead><tbody>`;
      for (var l in RISK_LIST) {
        var L = RISK_LIST[l];

        var icon = 'edit';
        var status = 'Pending';
        if (L.Form_Data.Locked == 1) icon = 'inspect';
        if (L.Form_Data.Approved == 1) {
          if (L.Form_Data.ApprovedBy != undefined) status = `${iTrans('Approved By')}<br />` + L.Form_Data.ApprovedBy;
          else status = 'Approved';
        } else {
          if (L.Form_Data.ApprovedBy != undefined) status = `${iTrans('Denied By')}<br />` + L.Form_Data.ApprovedBy;
          else if (L.Form_Data.Status == 'Denied') status = 'Denied';
        }
        var disableBTN = '';
        if (RISK_DONE_LINK == 'Itinerary') disableBTN = 'disabled';
        var Actions =
          '<div class="vertical-center-container"><button data-mini="true" class="action-btn" onClick="RAeditUserForm(' +
          l +
          ')" ><img src="./images/' +
          icon +
          '.svg" /></button><button data-mini="true" class="action-btn" onClick="RAarchiveUserForm(' +
          l +
          ')" ' +
          disableBTN +
          '><img src="./images/delete.svg" /></button></div>';
        var Risk = 'greenbg';
        if (L.Form_Data.Flight_Total >= L.Form_Data.Med_Risk_Value) Risk = 'yellowbg';
        if (L.Form_Data.Flight_Total >= L.Form_Data.Max_Risk_Value) Risk = 'redbg';
        html +=
          '<tr class="' +
          Risk +
          '"><td>' +
          L.PrimaryKey +
          '</td><td>' +
          L.Form_Data.Comment +
          '</td><td>' +
          L.Operation +
          '</td><td>' +
          cSAMA_Utils.NiceDate(L.DateTime, true, true, true) +
          '</td><td>' +
          status +
          '</td><td>' +
          Actions +
          '</td></tr>';
      }

      html += '</tbody></table>';
    } else {
      html += `<h3>${iTrans('You have no editable Risk Assessments.')}</h3>`;
    }
  } else {
    if (!RISK_TEST) {
      if (RISK_LIST.length > 0) {
        html += `<h3>${iTrans('Select Risk Assessment')}</h3>`;
        html += `<table data-testid="risk-assesment-table" class="limit-table" data-filter="true" ><thead><tr><th>${iTrans(
          'ID'
        )}</th><th>${iTrans('Employee')}</th><th>${iTrans('Comment')}</th><th>${iTrans('Operation')}</th><th>${iTrans(
          'Date'
        )}</th><th>${iTrans('Status')}</th><th>${iTrans('Action')}</th></tr></thead><tbody>`;
        for (var l in RISK_LIST) {
          var L = RISK_LIST[l];
          var bg = '';
          var status = 'Pending';
          if (L.Form_Data.Locked == 1) icon = 'inspect';
          if (L.Form_Data.Approved == 1) {
            if (L.Form_Data.ApprovedBy != undefined) status = `${iTrans('Approved By')}<br />` + L.Form_Data.ApprovedBy;
            else status = 'Approved';
          } else {
            if (L.Form_Data.ApprovedBy != undefined) status = `${iTrans('Denied By')}<br />` + L.Form_Data.ApprovedBy;
            else if (L.Form_Data.Status == 'Denied') status = 'Denied';
          }
          var Actions =
            '<div class="vertical-center-container"><button data-mini="true" class="action-btn" data-testid="risk-assessment-user-' +
            L.Employee +
            '-operation-' +
            L.Operation +
            '" onClick="RAeditUserForm(' +
            l +
            ')" ><img src="./images/inspect.svg" /></button><button data-mini="true" class="action-btn" onClick="RAarchiveUserForm(' +
            l +
            ')" ><img src="./images/delete.svg" /></button></div>';

          var Risk = 'greenbg';
          if (L.Form_Data.Flight_Total >= L.Form_Data.Med_Risk_Value) Risk = 'yellowbg';
          if (L.Form_Data.Flight_Total >= L.Form_Data.Max_Risk_Value) Risk = 'redbg';
          html +=
            '<tr class="' +
            Risk +
            '"><td>' +
            L.PrimaryKey +
            '</td><td>' +
            L.Employee +
            '</td><td>' +
            L.Form_Data.Comment +
            '</td><td>' +
            L.Operation +
            '</td><td>' +
            cSAMA_Utils.NiceDate(L.DateTime, true, true, true) +
            '</td><td>' +
            status +
            '</td><td>' +
            Actions +
            '</td></tr>';
        }

        html += '</tbody></table>';
      } else {
        html += `<h3>${iTrans('You have no editable Risk Assessments.')}</h3>`;
      }
    }
  }
  html += '</div></div>';
  html += '<div id="RAformLoad"></div>';
  RISK_CURRENT_DIV = 'RAformLoad';
  $('#' + div).html(html);
  $('#' + div).enhanceWithin();
  $('#RAformSelect').change(RAselectOperation);
}

function RAeditUserForm(e) {
  $('#RAformSelectDIV').html(
    `<button onClick="RAselect()" class="redbg" data-icon="refresh">${iTrans(
      'Select Different Risk Assessment'
    )}</button>`
  );
  $('#RAformSelectDIV').enhanceWithin();
  RAform = RISK_LIST[e].Form_Data;
  RAoperation();
}

function RAarchiveUserForm(e) {
  var FormID = RISK_LIST[e].PrimaryKey;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you wish to archive this risk assessment?'),
    buttons: {
      Archive: {
        click: function () {
          //document.getElementById("saveFlightTemplate").disabledd = false; //update for save template btn
          $.getJSON(BASEURL + 'menu-RA-AJAX.php?action=archiveRA&ItemPK=' + FormID, function (json_data) {
            if (json_data[0].ServerResponse != undefined) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'RArchiveUserForm()');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Offline') {
                $.mobile.changePage($('#MainMenu'), {
                  reverse: true,
                  changeHash: false,
                });
              }
              if (json_data[0].ServerResponse == 'Success') {
                //do nothing everything is good;
                //returnSMSmenu()
                retrieveRiskForms('APPROVE');
              } else {
                //Undo checkmark change not saved for group
              }
            }
          }).fail(function (jqXHR, status, error) {
            //undo checkmark change not saved for group

            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Connection Error'),
              headerClose: false,
              buttonPrompt: iTrans('There was an error Retrieving the data please try again.'),
              buttons: {
                Close: {
                  click: function () {},
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function RAselectOperation(e) {
  console.log(e.target.value);
  var index = parseInt(e.target.value);
  $('#RAformLoad').html('');
  if (index == -1) {
    if (RISK_REQ == 1) document.getElementById('RISKNEXT').disabled = true;
    else document.getElementById('RISKNEXT').disabled = false;
    RAform = null;
    return;
  }
  $('#RAformSelectDIV').html(
    `<button onClick="RAselect()" class="redbg" data-icon="refresh">${iTrans(
      'Select Different Risk Assessment'
    )}</button>`
  );
  $('#RAformSelectDIV').enhanceWithin();
  RAform = RISK_FORMS[index];
  RAoperation();
}

function RAtoggleFlightview(e) {
  if (e.checked) RAform.HidePerFlight = 1;
  else RAform.HidePerFlight = 0;
  RAoperation();
}
function RAtoggleLegview(e) {
  if (e.checked) RAform.HidePerLeg = 1;
  else RAform.HidePerLeg = 0;
  RAoperation();
}

function canShowHeaderApproveRA() {
  if (RAform?.Approved !== 0 || RAform?.Status === 'Denied') {
    return false;
  }

  // Check if the user is an admin, or they're on the risk assessment approval list
  return VueBridge.hasPermission('safetyManagement.flightRiskAssessments.administrate') ||
    RAform?.EmailList.find((user) => user.PrimaryKey === window.vueApp.authTools.authGetCurrentUser()?.id) != null;
}

function showHeaderApproveRAButtons() {
  const header = $(`#${CurPageID()} .headernav tbody > tr`);

  if (!canShowHeaderApproveRA() || header.find('#RA_Form_Approve').length > 0 || header.find('#RA_Form_Deny').length > 0) {
    return;
  }

  const headerButtonHtml =
    `<td>
      <button data-mini="true" id="RA_Form_Approve" onclick="showApproveDialog()" class="cirro-btn ui-btn ui-shadow ui-corner-all ui-mini"><div class="vertical-center-container"><i class="icon-margins fal fa-circle-check"></i><span v-t="app.buttons.approve">Approve</span></div>
      </button>
    </td>
    <td>
      <button data-mini="true" id="RA_Form_Deny" onclick="showDenyDialog()" class="cirro-btn ui-btn ui-shadow ui-corner-all ui-mini"><div class="vertical-center-container"><i class="icon-margins fal fa-circle-xmark"></i><span v-t="app.buttons.deny">Deny</span></div>
      </button>
    </td>`;


  header.children().eq(header.children().length - 1).before(headerButtonHtml);
}

function showApproveDialog() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Approve Flight Release'),
    headerClose: false,
    buttonPrompt: `
      ${iTrans('Are you sure you want to approve this Risk Assessment?')}
      <label for="RAallowEdits">${iTrans('Allow future edits')}</label>
      <input type="checkbox" id="RAallowEdits" />
    `,
    buttons: {
      [window.i18n.t('app.buttons.approve')]: {
        click: function () {
          Approve_RA();
        },
      },
      [window.i18n.t('app.buttons.cancel')]: {
        click: function () {},
      },
    },
  });
}

function showDenyDialog() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Deny Flight Release'),
    headerClose: false,
    buttonPrompt: `
      ${iTrans('Are you sure you want to deny this Risk Assessment?')}
      <label for="RAallowEdits">${iTrans('Allow future edits')}</label>
      <input type="checkbox" id="RAallowEdits" />
    `,
    buttons: {
      [window.i18n.t('app.buttons.deny')]: {
        click: function () {
          Deny_RA();
        },
      },
      [window.i18n.t('app.buttons.cancel')]: {
        click: function () {},
      },
    },
  });
}

function RAoperation(isReport = false) {
  $('#RAformLoad').html('');
  $('#RA_ItinLegDIV').html('');
  $('#WB_RA_TAB_CONTENT').html('');
  if (RAform.HideValues == undefined) RAform.HideValues = 0; //for legacy RA's that don't have it < V1.33.1
  if (RAform.SignatureReq == undefined) RAform.SignatureReq = 0; //for legacy RA's that don't have it < V1.33.1
  if (RAform.CurLeg == undefined) RAform.CurLeg = 0; //for legacy RA's that don't have it < V1.33.1
  if (RAform.LEGS == undefined) RAform.LEGS = []; //for legacy RA's that don't have it < V1.33.1
  if (RAform.HidePerFlight == undefined) RAform.HidePerFlight = 0; //for legacy RA's that don't have it < V1.33.1
  if (RAform.HidePerLeg == undefined) RAform.HidePerLeg = 0; //for legacy RA's that don't have it < V1.33.1
  if (RAform.ItinActive == undefined) RAform.ItinActive = 0;
  if (RAform.Signed == undefined) RAform.Signed = 0;
  if (RAform.SignatureData == undefined) RAform.SignatureData = '';
  RAform.SectionLimit = 'none';
  if (RAform.Approved == undefined) RAform.Approved = 0;
  if (RAform.Locked == undefined) RAform.Locked = 0;
  var html = '';
  var CHKpf = '';
  if (RAform.HidePerFlight == 1) CHKpf = 'checked';
  var CHKpl = '';
  if (RAform.HidePerLeg == 1) CHKpl = 'checked';

  if (RAform.Locked == 1) html += `<h1 class="yellowbg"><center>${iTrans('Locked for Flight Release')}</center></h1>`;

  let completionString = 'No';
  let approvalString = 'No';

  if (RAform.Approved === 1) {
    approvalString = RAform.ApprovedBy == null ? 'Yes' : RAform.ApprovedBy;
    if (RAform.ApprovedDate != null) {
      approvalString += '<br/>' + cSAMA_Utils.NiceDate(RAform.ApprovedDate, true, true, true);
    }
  }

  showHeaderApproveRAButtons();

  // Attempt to extract the completing employee + date from the list
  let listInfo = RISK_LIST.filter((_e) => _e.PrimaryKey === RAform.SubmitPrimaryKey);
  if (listInfo.length > 0) {
    RAform.CompletedBy = listInfo[0].Employee;
    RAform.CompletedDate = listInfo[0].DateTime;
  }

  if (RAform.CompletedBy != null) {
    completionString = RAform.CompletedBy + '<br/>' + cSAMA_Utils.NiceDate(RAform.CompletedDate, true, true, true);
  }

  // region Table Markup
  let table = `
<table style="width:100%;" class="sama-form-spacer-bottom">
    <tbody>
    <tr>
        <td style="width: 15%;">
            <b>${iTrans('Operation')}:</b>
        </td>
        <td style="width: 35%;">
            ${RAform.Operation_Type}
        </td>
        <td style="width: 25%;">
            <b>${iTrans('Completed')}:</b>
        </td>
        <td style="width: 25%;">
            ${completionString}
        </td>
    </tr>
    <tr>
        <td>
            <b>${iTrans('Form')}:</b>
        </td>
        <td>
            ${RAform.Name}
        </td>
        <td>
            <b>${iTrans('Approved')}:</b>
        </td>
        <td>
            ${approvalString}
        </td>
    </tr>
    <tr>
        <td>
            <b>${iTrans('Version')}:</b>
        </td>
        <td>
            ${RAform.Version}
        </td>
        <td>
            <b>${iTrans('Form Last Modified')}:</b>
        </td>
        <td>
            ${cSAMA_Utils.NiceDate(RAform.Modified, true, true, true)}
        </td>
    </tr>
    </tbody>
</table>`;
  // endregion

  html += table;

  if (RAform.SubmitPrimaryKey == undefined) html += `<h2><center>${iTrans('Not Submitted')}</center></h2>`;
  else html += `<h2><center>${iTrans('Form ID')}: ` + RAform.SubmitPrimaryKey + '</center></h2>';

  if (RAform.Med_Risk_Value == undefined) RAform.Med_Risk_Value = RAform.Max_Risk_Value; //set default med risk values for legacy data missing field
  var Leghtml = `<h2>${iTrans('Flight Leg')}# ` + (parseInt(RAform.CurLeg) + 1) + ` ${iTrans('Categories')}</h2>`;

  if (RAform.CurLeg > 0 && isReport) {
    html = `<h1>${iTrans('Flight Leg')}` + (parseInt(RAform.CurLeg) + 1) + ` ${iTrans('Totals')}</h1>`;
    Leghtml = '';
  }
  if (RAform.CurLeg == 0 && isReport)
    Leghtml = `<h1>${iTrans('Flight Leg')}# ` + (parseInt(RAform.CurLeg) + 1) + ` ${iTrans('Totals')}</h1>`;
  if (RAform.Required == 0) html += `<h2><center>${iTrans('Optional Form')}</center></h2>`;
  else html += `<h2><center>* ${iTrans('Low Risk Required to Continue Activity')} *</center></h2>`;
  var Forms = RAform.Form;
  var Total_Risk_Value = 0;
  var Total_Risk_ValueF = 0;
  var maxR = parseInt(RAform.Max_Risk_Value);
  var medR = parseInt(RAform.Med_Risk_Value);
  var OverMax = false;

  var Statichtml = '';
  if (RAform.ItinActive == 1 && !isReport) Statichtml += `<h1>${iTrans('Per Flight Risk Items Locked')}</h1>`;
  if (Forms.length > 0) {
    if (RAform.LEGS[RAform.CurLeg] == undefined) {
      RAform.LEGS[RAform.CurLeg] = {
        Approved: 0,
        Submitted: 0,
        Comment: '',
        Completed: '',
        Locked: 0,
        enabled: 0,
        SignatureData: '',
        SignatureName: '',
        Signed: 0,
      };
      for (var i in Forms) {
        if (Forms[i].PerLeg == 1) RAform.LEGS[RAform.CurLeg][i] = JSON.parse(JSON.stringify(Forms[i]));
      }
    }
    var HasPerLeg = false;
    for (var i in Forms) {
      var CatHtml = '';
      var Form = Forms[i];
      if (Form.PerLeg == undefined) Form.PerLeg = 0;
      if (Form.Max_Risk_Value == undefined) Form.Max_Risk_Value = 0;
      if (Form.Med_Risk_Value == undefined) Form.Med_Risk_Value = 0;
      if (Form.Mitigate_Med_Risk == undefined) Form.Mitigate_Med_Risk = 0;
      if (Form.Required == undefined) Form.Required = 0;
      var sectionTotal = 0;
      var sectionTotalF = 0;
      CatHtml +=
        "<table class='first-td-fill'><thead><tr><th>" +
        Form.Category +
        `</th><th>${iTrans('Value')}</th><th>${iTrans('Sel')}</th><th>${iTrans('Action')}</th></tr></thead><tbody>`;
      for (var q in Form.Questions) {
        var disabled = '';
        var checked = '';
        var selected = '';
        var Q = Form.Questions[q];
        if (Form.PerLeg == 1) {
          HasPerLeg = true;
          Q = RAform.LEGS[RAform.CurLeg][i].Questions[q];
        }
        //Add defaults for pre V1.33.1
        if (Q.Risk_Selected == undefined) Q.Risk_Selected = 0;
        if (Q.Risk_LockUnselect == undefined) Q.Risk_LockUnselect = 0;
        if (Q.Risk_Email == undefined) Q.Risk_Email = 0;

        if (Q.Admin_Mitigation_Req == 1 || Q.Selected != 1 || RAform.Locked == 1) disabled = 'disabled';
        if (RISK_APPROVE) {
          if (Q.Selected != 1) disabled = 'disabled';
          else disabled = ''; //admin risk approvals can access all items only if item selected
        }
        var Risk_Flight_Value = parseInt(Q.Risk_Value);
        if (Q.Selected == 1) {
          Risk_Flight_Value = parseInt(Q.Risk_Mitigation_Value);
          if (Q.Risk_Mitigation_Index == -1) Risk_Flight_Value = parseInt(Q.Risk_Value);
          sectionTotal += parseInt(Q.Risk_Value);
          sectionTotalF += Risk_Flight_Value;
          checked = 'checked';
          selected = 'greenbg';
          Total_Risk_Value += parseInt(Q.Risk_Value);
          Total_Risk_ValueF += Risk_Flight_Value;
          if (Total_Risk_ValueF >= maxR) selected = 'redbg';
          else if (Total_Risk_ValueF >= medR) selected = 'yellowbg';
        }
        var disableCheck = '';
        if (
          (RAform.Locked == 1 && !RISK_APPROVE) ||
          (RAform.Approved == 1 && Form.PerLeg == 0 && RAform.Locked == 1) ||
          (Form.PerLeg == 1 && Q.Approved == 1)
        )
          disableCheck = 'disabled';
        if (!RISK_APPROVE && Q.Risk_LockUnselect == 1 && Q.Selected == 1) disableCheck = 'disabled';

        if (isReport || (Form.PerLeg == 0 && RAform.ItinActive == 1)) {
          disableCheck = 'disabled';
          disabled = 'disabled';
        }
        var qOpts =
          '<input data-testid="risk-assessment-check-item-' +
          i +
          '-' +
          q +
          '" data-role="none" type="checkbox" data-mini="true" id="RACHK_' +
          i +
          '-' +
          q +
          '" onClick="RAupdateScore(\'' +
          i +
          '-' +
          q +
          '\')" ' +
          checked +
          ' ' +
          disableCheck +
          '/>';

        var mOpts =
          '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="mitigateRAQuestion(\'' +
          i +
          '-' +
          q +
          "'," +
          isReport +
          ')" ' +
          disabled +
          '><img src="./images/edit.svg" /></button></div>';
        if (Q.Admin_Mitigation_Req == 1)
          mOpts =
            '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="mitigateRAQuestion(\'' +
            i +
            '-' +
            q +
            "'," +
            isReport +
            ')" ' +
            disabled +
            '><img src="./images/inspect.svg" /></button></div>';
        if (isReport && checked == 'checked')
          mOpts =
            '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="mitigateRAQuestion(\'' +
            i +
            '-' +
            q +
            "'," +
            isReport +
            ',' +
            RAform.CurLeg +
            ')"><img src="./images/inspect.svg" /></button></div>';
        if (isReport && checked == '')
          mOpts =
            '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onClick="mitigateRAQuestion(\'' +
            i +
            '-' +
            q +
            "'," +
            isReport +
            ')" disabled><img src="./images/inspect.svg" /></button></div>';

        var Risk_Value = '';

        if (isReport || RAform.HideValues == 0 || RISK_APPROVE) Risk_Value = Q.Risk_Value;
        if (Q.Risk_Mitigation_Index != -1 && Q.Selected == 1) {
          var Mvalue = '';
          if (isReport || RAform.HideValues == 0 || RISK_APPROVE) Mvalue = '(' + Risk_Flight_Value + ')';
          CatHtml +=
            '<tr class="' +
            selected +
            '"><td class="wrap">' +
            Q.Risk_Question +
            `<br /><b>${iTrans('Mitigated by')}: </b>` +
            Q.Risk_Mitigation_Option +
            ' ' +
            Q.Risk_Mitigation_text +
            '</td><td>' +
            Risk_Value +
            ' ' +
            Mvalue +
            '</td><td>' +
            qOpts +
            '</td><td>' +
            mOpts +
            '</td></tr>';
        } else {
          CatHtml +=
            '<tr class="' +
            selected +
            '"><td class="wrap">' +
            Q.Risk_Question +
            '</td><td>' +
            Risk_Value +
            '</td><td>' +
            qOpts +
            '</td><td>' +
            mOpts +
            '</td></tr>';
        }
        Q.Risk_Flight_Value = Risk_Flight_Value;
      }
      var CatSummaryTxt = `<b>${iTrans('Section Score')}</b>`;
      var CatClass = '';

      if (Form.Med_Risk_Value != 0 && Form.Med_Risk_Value <= sectionTotalF) {
        CatClass = 'yellowbg';
        CatSummaryTxt = `<b>${iTrans('WARNING: Medium Risk Section Score')}</b>`;
        if (RAform.SectionLimit == 'none' && Form.Mitigate_Med_Risk == 1) {
          RAform.SectionLimit = 'mediumRelease';
        }
        if (RAform.SectionLimit == 'none' && Form.Required == 1) {
          RAform.SectionLimit = 'medium';
        }
      }
      if (Form.Max_Risk_Value != 0 && Form.Max_Risk_Value <= sectionTotalF) {
        CatClass = 'redbg';
        CatSummaryTxt = `<b>${iTrans('WARNING: Maximum Risk Section Score')}</b>`;
        RAform.SectionLimit = 'maximum';
      }
      if (isReport || RAform.HideValues == 0 || RISK_APPROVE) {
        if (sectionTotal != sectionTotalF)
          CatHtml +=
            '<tr class="' +
            CatClass +
            '"><td style="text-align:right;">' +
            CatSummaryTxt +
            '</td><td><b>' +
            sectionTotal +
            ' (' +
            sectionTotalF +
            ')</b></td><td></td><td></td></tr>';
        else
          CatHtml +=
            '<tr class="' +
            CatClass +
            '"><td style="text-align:right;">' +
            CatSummaryTxt +
            '</td><td><b>' +
            sectionTotal +
            '</b></td><td></td><td></td></tr>';
      } else {
        CatHtml +=
          '<tr class="' +
          CatClass +
          '"><td style="text-align:right;">' +
          CatSummaryTxt +
          '</td><td></td><td></td><td></td></tr>';
      }
      CatHtml += '</tbody></table><br />';

      if (RAform.CurLeg > 0 && isReport && Form.PerLeg == 0) continue;
      if (Form.PerLeg == 0) Statichtml += CatHtml;
      else Leghtml += CatHtml;
    }

    if (RAform.Comment == undefined) RAform.Comment = '';
    if (RAform.ItinActive == 1) disableCheck = 'disabled';
    Statichtml +=
      `<label for="RAcomment">${iTrans('Comment')}</label><input type="text" id="RAcomment" value="` +
      escapeHtml(RAform.Comment) +
      '" onChange="RA_UpdateComment()" ' +
      disableCheck +
      '/><br />';
    if (RAform.SignatureReq == 1) {
      if (RAform.Signed == 0)
        Statichtml += `<button class="redbg" onClick="RA_CAPTURE_SIGNATURE(0);">${iTrans(
          'Sign Risk Assessment'
        )}</button>`;
      else {
        if (RAform.SignatureData != '')
          Statichtml +=
            '<center><div style="display: inline-block;"><img src="' +
            RAform.SignatureData +
            '" width="321px" height="150px"/><br />';
        else Statichtml += `<center><div style="display: inline-block;">${iTrans('[SIGNATURE IMAGE ON REPORT]')}<br />`;
        Statichtml +=
          `<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>${iTrans('Signed By')}: ` +
          RAform.SignatureName +
          '</b></center></td></tr></table></div></center>';
      }
    }

    if (!isReport)
      html +=
        `<label for="RAhideperflight">${iTrans(
          'Hide Per Flight Risk Assessment'
        )}</label><input type="checkbox" data-mini="true" id="RAhideperflight"  onClick="RAtoggleFlightview(this)" ` +
        CHKpf +
        '/>';

    if (RAform.HidePerFlight == 0) html += Statichtml;

    if (HasPerLeg && !isReport)
      html +=
        `<label for="RAhidperleg">${iTrans(
          'Hide Per Leg Risk Assessment'
        )}</label><input type="checkbox" data-mini="true" id="RAhidperleg" onClick="RAtoggleLegview(this)" ` +
        CHKpl +
        '/>';
    if (RAform.HidePerLeg == 0 && HasPerLeg) html += Leghtml;

    if (RAform.LEGS.length > 0) {
      if (RAform.SignatureReq == 1 && HasPerLeg) {
        if (RAform.LEGS[RAform.CurLeg].Signed != 1) {
          if (!isReport)
            html +=
              `<button class="redbg" onClick="RA_CAPTURE_SIGNATURE(1);">${iTrans('Sign Risk Assessment Leg')}# ` +
              (parseInt(RAform.CurLeg) + 1) +
              '</button>';
        } else {
          if (RAform.LEGS[RAform.CurLeg].SignatureData != '')
            html +=
              '<center><div style="display: inline-block;"><img src="' +
              RAform.LEGS[RAform.CurLeg].SignatureData +
              '" width="321px" height="150px"/><br />';
          else html += `<center><div style="display: inline-block;">${iTrans('[SIGNATURE IMAGE ON REPORT]')}<br />`;
          html +=
            `<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>${iTrans('Signed By')}: ` +
            RAform.LEGS[RAform.CurLeg].SignatureName +
            '</b></center></td></tr></table></div></center>';
        }
      }
    }

    if (isReport || RAform.HideValues == 0 || RISK_APPROVE) {
      html +=
        `<h3 data-testid="risk-assessment-medium-risk-trigger">${iTrans('Medium risk triggered at')}: ` +
        RAform.Med_Risk_Value +
        `</h3><h3>${iTrans('Net Evaluated Flight Risk')}: ` +
        Total_Risk_ValueF +
        ` ${iTrans('out of a maximum of')} ` +
        RAform.Max_Risk_Value +
        '</h3>';
    }
    var SignatureNeeded = false;
    if (RAform.SignatureReq == 1) {
      if (RAform.Signed == 0) SignatureNeeded = true;
      if (RAform.LEGS.length > 0 && HasPerLeg) {
        if (RAform.LEGS[RAform.CurLeg].Signed != 1 && !RISK_SMS) SignatureNeeded = true;
      }
    }
    if (SignatureNeeded) document.getElementById('RISKNEXT').disabled = true;
    if (Total_Risk_ValueF >= maxR || RAform.SectionLimit == 'maximum') {
      if (RAform.Required == 1 || RAform.SectionLimit == 'maximum' || RAform.FlightReleaseReq == 1) {
        RAform.Status = 'Exceeded Required';
        if (RAform.FlightReleaseReq == 1 && RAform.Approved == 0) {
          html += `<h3 data-testid="risk-assessment-evaluation-exceeded-require" class="redbg">${iTrans(
            'Flight Release Required'
          )}</h3>`;
        }
      } else {
        if (RAform.NoGoIfMax == 1) {
          html += `<h3 class="redbg">${iTrans(
            'You have exceeded the maximum allowable risk.  Risk mitigation is required!'
          )}</h3>`;
        } else
          html += `<h3 data-testid="risk-assessment-evaluation-max-exceeded-mitigate" class="redbg">${iTrans(
            'You have exceeded the maximum allowable risk.  Risk mitigation is strongly suggested!'
          )}</h3>`;

        RAform.Status = 'Exceeded';
      }

      OverMax = true;
      if (OverMax && RAform.NoGoIfMax == 1) {
        document.getElementById('RISKNEXT').disabled = true;
      }
    } else if (Total_Risk_ValueF >= medR || RAform.SectionLimit == 'medium' || RAform.SectionLimit == 'mediumRelease') {
      RAform.Status = 'Warning';
      if (RAform.Required == 1 || RAform.SectionLimit == 'mediumRelease' || RAform.Mitigate_Med_Risk == 1) {
        if (RAform.Required == 0) {
          html += `<h3 data-testid="risk-assessment-evaluation-warning-approaching-max" class="yellowbg">${iTrans(
            'WARNING: You are approaching the maximum score'
          )}</h3>`;
          if (RAform.Mitigate_Med_Risk == 1 || RAform.FlightReleaseReq == 1 || RAform.SectionLimit == 'mediumRelease') {
            html += `<h3 data-testid="risk-assessment-evaluation-warning-release-require" class="redbg">${iTrans(
              'Flight Release Required'
            )}</h3>`;
          }
        }
      } else {
        RAform.Status = 'Exceeded';
        html += `<h3 data-testid="risk-assessment-evaluation-exceeded" class="yellowbg">${iTrans(
          'Risk is in the moderate range'
        )}</h3>`;
        if (
          (RAform.Mitigate_Med_Risk == 1 && Total_Risk_ValueF >= medR) ||
          RAform.FlightReleaseReq == 1 ||
          RAform.SectionLimit == 'mediumRelease'
        ) {
          html += `<h3 class="redbg">${iTrans('Flight Release Required')}</h3>`;
        }
      }
    } else {
      html += `<h3 data-testid="risk-assessment-evaluation-acceptable" class="greenbg">${iTrans(
        'Your evaluated risk is acceptable'
      )}.</h3>`;
      RAform.Status = 'Passed';
    }
    RAform.Risk_Total = Total_Risk_Value;
    RAform.Flight_Total = Total_Risk_ValueF;
    RAform.DateTime = new Date().toISOString();
    if (RISK_APPROVE && !RISK_TEST && RAform.ItinActive == 0 && RAform.Locked == 0) {
      html += `<label for="RAallowEdits">${iTrans(
        'Allow future edits'
      )}</label><input type="checkbox" id="RAallowEdits" data-mini="true"/>`;
      if (Total_Risk_ValueF >= maxR && RAform.Required == 1)
        html += `<button onClick="Approve_RA()" data-icon="check" data-testid="risk-assessment-approve-flight-release" class="greenbg" disabled>${iTrans(
          'Approve Flight Release'
        )}</button>`;
      else
        html += `<button onClick="Approve_RA()" data-icon="check" data-testid="risk-assessment-approve-flight-release" class="greenbg">${iTrans(
          'Approve Flight Release'
        )}</button>`;
      html += `<button onClick="Deny_RA()" data-icon="forbidden" class="redbg">${iTrans(
        'Deny Flight Release'
      )}</button>`;
    } else {
      if (RAform.Locked == 0) html += RA_GetActionOpts(SignatureNeeded, isReport, OverMax);
    }
  } else {
    html += `<h3>${iTrans('There are no Categories or Questions')}</h3>`;
  }
  if (isReport) {
    return html;
  } else {
    $('#Sub_Report_Content').html('');
    $('#' + RISK_CURRENT_DIV).html(html);
    $('#' + RISK_CURRENT_DIV).enhanceWithin();
  }
  if (RA_ScrollTop != 0) {
    document.documentElement.scrollTop = document.body.scrollTop = RA_ScrollTop;
    RA_ScrollTop = 0;
  }
}

function RA_GetActionOpts(SignatureNeeded, isReport, OverMax) {
  var html = '';
  if (RISK_TEST) return `<h1>${iTrans('TESTING RISK ASSESSMENT')}</h1>`;

  if (RAform.Required == 1 && RAform.Status != 'Passed') {
    html += `<h3 class="redbg">${iTrans(
      'LOW RISK SCORE REQUIRED: A low risk score is required to continue your activity'
    )}</h3>`;
    document.getElementById('RISKNEXT').disabled = true;
    return html;
  }
  if (RAform.Locked == 1 && !RISK_APPROVE) {
    html += `<h1>${iTrans('Form is currently Locked')}</h1>`;
    if (RAform.Approved != 1) return html;
  }

  if (SignatureNeeded && !isReport) {
    document.getElementById('RISKNEXT').disabled = true;
    html += `<h1>${iTrans('Signature Required to Continue')}</h1>`;
    return html;
  }
  if (!RISK_APPROVE && !isReport) {
    if (RISK_SMS) {
      html += `<button onClick="Submit_RA()" data-testid="risk-assessment-submit-flight-risk" id="RAsubmitBTN" data-icon="check" class="greenbg">${iTrans(
        'Submit Risk Assessment'
      )}</button>`;
    } else {
      if (!OverMax || (OverMax && RAform.NoGoIfMax != 1)) {
        document.getElementById('RISKNEXT').disabled = false;
        html += `<button onClick="RAcompleted()" data-icon="check" class="greenbg">${iTrans(
          'Continue Activity'
        )}</button>`;
      }
    }
  }
  return html;
}

function RA_UpdateComment() {
  RAform.Comment = $('#RAcomment').val();
}

function Approve_RA() {
  RAform.Approved = 1;
  if ($('#RAallowEdits').is(':checked')) RAform.Locked = 0;
  else RAform.Locked = 1;
  Submit_RA();
}

function Deny_RA() {
  RAform.Approved = 0;
  if ($('#RAallowEdits').is(':checked')) RAform.Locked = 0;
  else RAform.Locked = 1;
  RAform.Status = 'Denied';
  Submit_RA();
}

function Submit_RA() {
  $('#RAsubmitBTN').prop('disabled', true);
  if (RAform.SubmitPrimaryKey == undefined) RAform.SubmitPrimaryKey = 0;
  console.log(RAform);

  var maxR = parseInt(RAform.Max_Risk_Value);
  var medR = parseInt(RAform.Med_Risk_Value);
  var AdminApproving = 0;
  if (RISK_APPROVE) AdminApproving = 1;
  else {
    if (RAform.Flight_Total >= medR && RAform.Mitigate_Med_Risk == 1) RAform.Locked = 1;
    if (RAform.Flight_Total >= maxR) RAform.Locked = 1;
    if (RAform.FlightReleaseReq == 1) RAform.Locked = 1;
    if (RAform.Approved == undefined) RAform.Approved = 0;
    if (RAform.Locked == 0 && RAform.FlightReleaseReq == 0 && RAform.Required == 0) RAform.Approved = 1;
  }
  var Form_Data = JSON.stringify(RAform);

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-RA-AJAX.php?action=submitForm',
    data: {
      PrimaryKey: RAform.SubmitPrimaryKey,
      Operation: RAform.Operation_Type,
      Status: RAform.Status,
      Form: Form_Data,
      AdminApproving: AdminApproving,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      $('#RAsubmitBTN').prop('disabled', false);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'saveRAForm()');
        //document.getElementById("saveFlightTemplate").disabled = false; //update for save template btn
      }
      if (data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Your Risk Assessment has been successfully submitted.'),
          buttons: {
            Continue: {
              click: function () {
                //document.getElementById("saveFlightTemplate").disabledd = false; //update for save template btn
                exitSMS_Menu();
              },
            },
          },
          callbackOpen: function () {
            setTimeout(function () {
              $('a.ui-btn:contains("Continue")').attr(
                'data-testid',
                'risk-assessment-new-flight-risk-dialog-continue-button'
              );
            }, 100);
          },
        });
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem submitting your form.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            Close: {
              click: function () {
                //document.getElementById("saveFlightTemplate").disabledd = false; //update for save template btn
              },
            },
            Report: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      $('#RAsubmitBTN').prop('disabled', false);
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem submitting your form.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          Close: {
            click: function () {
              //document.getElementById("saveFlightTemplate").disabled = false;  //update for dave template btn
            },
          },
          Report: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function RAupdateScore(Q) {
  var RAitem = Q.split('-');
  RAform.CurForm = parseInt(RAitem[0]);
  RAform.CurQuestion = parseInt(RAitem[1]);
  var Item = RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion];
  if (RAform.Form[RAform.CurForm].PerLeg == 1)
    Item = RAform.LEGS[RAform.CurLeg][RAform.CurForm].Questions[RAform.CurQuestion];
  if ($('#RACHK_' + RAitem[0] + '-' + RAitem[1]).is(':checked')) Item.Selected = 1;
  else Item.Selected = 0;
  //console.log(RAform.LEGS[RAform.CurLeg][RAform.CurForm].Questions[RAform.CurQuestion]);
  RA_RemoveSignature(RAform.Form[RAform.CurForm].PerLeg);
}
var RA_ScrollTop = 0;
function mitigateRAQuestion(Q, isReport, CurLeg = false) {
  RA_ScrollTop = window.pageYOffset || document.documentElement.scrollTop;
  $('#RAformmitigate').html('');
  $.mobile.changePage($('#RA_Form_Mitigate'), {
    reverse: false,
    changeHash: false,
  });
  var RAitem = Q.split('-');
  RAform.CurForm = parseInt(RAitem[0]);
  RAform.CurQuestion = parseInt(RAitem[1]);
  var Item = RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion];
  if (CurLeg == false) {
    if (RAform.Form[RAform.CurForm].PerLeg == 1)
      Item = RAform.LEGS[RAform.CurLeg][RAform.CurForm].Questions[RAform.CurQuestion];
  } else {
    if (RAform.Form[RAform.CurForm].PerLeg == 1)
      Item = RAform.LEGS[CurLeg][RAform.CurForm].Questions[RAform.CurQuestion];
  }
  var Options = Item.Risk_Mitigation_Options;
  var html = `<h3>${iTrans('Question')}</h3>` + Item.Risk_Question + '<br /><br />';
  html += `<table class="first-td-fill"><thead><tr><th>${iTrans('Mitigation')}</th><th>${iTrans(
    'Value'
  )}</th><th>${iTrans('Sel')}</th></tr></thead><tbody>`;
  var rIndex = Item.Risk_Mitigation_Index;
  var selected = '';
  if (rIndex == -1) {
    selected = 'checked';
    Item.Risk_Mitigation_Value = Item.Risk_Value;
  }
  var RiskValue = '';
  if (isReport || RAform.HideValues == 0 || RISK_APPROVE) RiskValue = Item.Risk_Value;
  html +=
    `<tr><td>${iTrans('None')}</td><td>` +
    RiskValue +
    '</td><td><input data-role="none" type="radio" name="RAradioM" value="-1" ' +
    selected +
    '/>';
  selected = '';
  var disabled = '';
  if (isReport == true) disabled = 'disabled';
  for (var i in Options) {
    var Mvalue = '';
    if (isReport || RAform.HideValues == 0 || RISK_APPROVE) Mvalue = '(' + Options[i].value + ')';
    if (rIndex == i) selected = 'checked';
    else selected = '';
    html +=
      '<tr><td>' +
      Options[i].text +
      '</td><td>' +
      Mvalue +
      '</td><td><input data-role="none" type="radio" name="RAradioM" value="' +
      i +
      '" ' +
      selected +
      ' ' +
      disabled +
      '/>';
  }
  html += '</tbody></table>';
  if (isReport != true) {
    html +=
      `<label for="RAmitigationTEXT">${iTrans(
        'Description (optional)'
      )}</label><input type="text" id="RAmitigationTEXT" value="` +
      Item.Risk_Mitigation_text +
      '"/>';
    html += `<button onClick="SaveMitigation()" data-icon="check" data-mini="true" class="greenbg" >${iTrans(
      'Update Changes'
    )}</button>`;
  }
  $('#RAformmitigate').html(html);
  $('#RAformmitigate').enhanceWithin();
}

function SaveMitigation() {
  //Find selected Radio Value;
  var value = parseInt($('input[name=RAradioM]:checked').val());
  var Item = RAform.Form[RAform.CurForm].Questions[RAform.CurQuestion];
  if (RAform.Form[RAform.CurForm].PerLeg == 1)
    Item = RAform.LEGS[RAform.CurLeg][RAform.CurForm].Questions[RAform.CurQuestion];
  Item.Risk_Mitigation_Index = value;
  //properties not set in RA med prior to V1.33.1
  if (Item.Risk_Selected == undefined) Item.RiskSelected = 0;
  if (Item.Risk_LockUnselect == undefined) Item.Risk_LockUnselect = 0;
  if (Item.Risk_Email == undefined) Item.Risk_Email = 0;
  if (value != -1) {
    Item.Risk_Mitigation_Option = Item.Risk_Mitigation_Options[value].text;
    Item.Risk_Mitigation_Value = parseInt(Item.Risk_Mitigation_Options[value].value);
    Item.Risk_Mitigation_text = $('#RAmitigationTEXT').val();
  } else {
    Item.Risk_Mitigation_Option = 'None';
    Item.Risk_Mitigation_Value = Item.Risk_Value;
    Item.Risk_Mitigation_text = '';
  }
  RA_RemoveSignature(RAform.Form[RAform.CurForm].PerLeg, false);
  returnmitigateRAQuestion();
}

function returnmitigateRAQuestion() {
  if (RAform.isReport == true) {
    $.mobile.changePage($('#Sub_Report_Page'), {
      reverse: true,
      changeHash: false,
    });
    return;
  }
  if (RISK_CURRENT_DIV == 'WB_RA_TAB_CONTENT') {
    $.mobile.changePage($('#WB_Page2'), {
      reverse: true,
      changeHash: false,
    });
  } else {
    if (RISK_SMS)
      $.mobile.changePage($('#RA_Form_SMS'), {
        reverse: true,
        changeHash: false,
      });
    else
      $.mobile.changePage($('#RA_Form'), {
        reverse: true,
        changeHash: false,
      });
  }
  RAoperation();
}

function RAcompleted() {
  if (RISK_DONE_LINK == 'Itinerary') itinNAVnext();
}

function returnRiskAssessment() {
  if (RISK_TEST) {
    $('#RISKTEST').show();
    returnRiskHome(); //Form is being tested under admin flag
  } else {
    if (RISK_DONE_LINK == 'Itinerary') itinNAVprev();
  }
}

var Template1 = {
  PrimaryKey: 0,
  Name: 'Pre Flight Risk Assessment - Fixed Wing',
  Version: '1',
  Modified: '2016-11-11T00:04:11.775Z',
  Required: 0,
  DateTime: '',
  Departure: '',
  Destination: '',
  Operation_Type: 'Pre Flight - Fixed Wing',
  Risk_Total: 0,
  Flight_Total: 0,
  Status: 'Ready',
  Med_Risk_Value: '20',
  Max_Risk_Value: '20',
  FormProcessorVersion: 1,
  Mitigate_Med_Risk: 1,
  FormProcessorVersion: 1,
  Required: 0,
  FlightReleaseReq: 0,
  EmailList: [],
  Form: [
    {
      Category: 'Pilot Qualifications and Experience',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Captain with <200 Hours on A/C Type',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Captain flying with First Officer w/ >200 Hours on A/C Type',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'First Officer with <200 Hours on Type',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Captain of flight has greater than 200 hours on aircraft type',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Single Pilot Flight',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: '1',
            },
            {
              text: 'Captain has more than 500 hours single pilot time',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Captain with <100 Hours in Last 90 Days',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'First Officer with less than 100 hours last 90 days',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Duty day greater than 12 hours ',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: '12 or more hours of uninterrupted Crew Rest prior to reporting to duty',
              value: '2',
            },
            {
              text: '10 to 12 hours of uninterrupted Crew Rest immediately prior to reporting to duty',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Flight Time >8 Hours in the Duty Day',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Crew Rest < 10 hours Prior to the Duty Day) ',
          Risk_Value: '1',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
      ],
    },
    {
      Category: 'Operating Environment',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'VOR/GPS/LOC/ADF (Best approach available w/o vertical guidance) ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: '1',
            },
            {
              text: 'ADF/VOR',
              value: '3',
            },
            {
              text: 'LOC',
              value: '2',
            },
            {
              text: 'GPS/ILS',
              value: '1',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Circling approach (as best available approach) ',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'No published approaches',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Mountainous airport',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Control tower not operational at ETA or ETD ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Uncontrolled airport ',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Alternate airport not selected ',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Elevation of primary airport greater than 5000 ft. MSL',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Wet Runway',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: ' Contaminated runway ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Winter operation',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Twilight Operation ',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Night Operation',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Stopping distance greater than 80% of available runway ',
          Risk_Value: '1',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Repositioning flight (no passengers or cargo) ',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Pop up trip (Less than 4 hours crew notice) ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'International operation ',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'No weather reporting at destination',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Thunderstorms at departure and/or destination ',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Severe Turbulence',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Ceiling & visibility at destination less than 500 ft. / 2 sm ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Heavy rain at departure and/or destination ',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Frozen precipitation at departure and/or destination ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Icing (moderate-severe) ',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Surface winds greater than 30 knots ',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Crosswinds greater than 15 knots ',
          Risk_Value: '4',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Runway braking action less than good ',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
      ],
    },
    {
      Category: 'Equipment',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Special Flight Permit Operation (ferry permit) ',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'MEL / CDL Items (items related to safety of flight) ',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Special flight limitations based on AFM equipment limitations ',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
          ],
        },
      ],
    },
  ],
  CurForm: 1,
  CurQuestion: 1,
  CurMitigation: 1,
};
var Template2 = {
  PrimaryKey: 0,
  Name: 'Pre Flight Risk Assessment - Rotary Wing',
  Version: '1',
  Modified: '2016-11-16T15:41:40.100Z',
  Required: 0,
  DateTime: '',
  Departure: '',
  Destination: '',
  Operation_Type: 'Pre Flight - Rotary Wing',
  Risk_Total: 0,
  Flight_Total: 0,
  Status: 'Ready',
  Med_Risk_Value: '16',
  Max_Risk_Value: '16',
  FormProcessorVersion: 1,
  Mitigate_Med_Risk: 1,
  FormProcessorVersion: 1,
  Required: 0,
  FlightReleaseReq: 0,
  EmailList: [],
  Form: [
    {
      Category: 'Personal Conditions',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Illness - Headache, cold, fever, etc.',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Nuisance, not completely in shape.',
              value: '3',
            },
            {
              text: 'No problems. Physically in shape.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Medication - Prescription medication. Attention and driving impairing medication.',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Over the counter medication.',
              value: '3',
            },
            {
              text: 'No medications in the last 24 hours.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Stress - Highly stressed and/or demotivated to do the job.',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Light stress and/or routinely job.',
              value: '3',
            },
            {
              text: 'No stress or positive stress. Good motivation to do the job.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question:
            'Alcohol - Substantial consumption within the last 12 hours or any consumption in the last 8 hours.',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Moderate consumption within the last 12 hours',
              value: '3',
            },
            {
              text: 'No alcohol consumption in the last 12 hours',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Fatigue - Mentally or physically fatigued or Flight after a day of work.',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Moderate fatigue.',
              value: '3',
            },
            {
              text: 'No fatigue.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Emotion - Emotionally stressed. Legal, financial or family problems.',
          Risk_Value: '5',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Emotionally involved. Little private problems.',
              value: '3',
            },
            {
              text: 'Not emotionally involved.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question:
            'Food & Drink - More than 6 hour from last meal. More than 4 hours without drinking. Hot weather and no drinking water on board.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Flight conducted during breakfast, lunch or dinner time. 4 to 6 hours without eating. 2 to 4 hours without drinking.',
              value: '1',
            },
            {
              text: 'Adequately nourished and hydrated.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Physiologic - Long mission duration with no rest facilities available.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Medium mission duration with no rest facilities available.',
              value: '1',
            },
            {
              text: 'Physiologically relieved.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Sleep - Poor sleep.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Moderate sleep or no sleep in the last 13 hours.',
              value: '1',
            },
            {
              text: 'Well slept.',
              value: '0',
            },
          ],
        },
      ],
    },
    {
      Category: 'Recency',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Below 1500 hours total flight time.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Involve other pilot with 1500 - 3000 total time in flight planning',
              value: '1',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Below 100 hours flight time on type.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Involve pilot with 300+ hours on type in flight planning',
              value: '1',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Last flight on type - over 3 months ago.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Review AFM prior to departure.',
              value: '1',
            },
          ],
        },
      ],
    },
    {
      Category: 'Mission',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Planning - Rushed or inadequate.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Approximate or experience based planning with another pilot',
              value: '1',
            },
            {
              text: 'Adequate. Thorough planning.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Mission Scheduling - Immediate flight.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Not scheduled flight requested with some planning time allowed.',
              value: '1',
            },
            {
              text: 'Well before scheduled flight.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Mission Complexity - Complex flight. Never flown mission typology.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Review with company pilot who has familiarity with the mission.',
              value: '1',
            },
            {
              text: 'Easy flight. Well known mission type.',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Flight Rules - IFR or Low VFR.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Review route and weather.  Conditions forecast to improve.',
              value: '1',
            },
          ],
        },
      ],
    },
    {
      Category: 'Aircraft',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Helicopter is NOT equipped for the mission.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Partially equipped',
              value: '1',
            },
            {
              text: 'Fully equipped',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Deferred Aircraft Defects - More than 2',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Rectified  to less than 2 defects',
              value: '1',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Loadsheet - Not done and near aircraft limitation',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Not done, but supposed within limits by experience.',
              value: '1',
            },
            {
              text: 'Done',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Performance (weight/density altitude) - Close to limits during operations.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Close to limits only in take-off and landing',
              value: '1',
            },
            {
              text: 'Adjusted flight parameters - weight, time of day, etc. - Well under limits.',
              value: '0',
            },
          ],
        },
      ],
    },
    {
      Category: 'Environment',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Time of Flight - Night',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Dawn or dusk.',
              value: '1',
            },
            {
              text: 'Day',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Meteorological Conditions - IMC',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Marginal VMC',
              value: '1',
            },
            {
              text: 'Good',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Wind - Strong and Gusting',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Sustained',
              value: '1',
            },
            {
              text: 'Light',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Rain - Showers or thunderstorms',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Light rain',
              value: '1',
            },
            {
              text: 'No rain',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Snow (Deflectors Installed) - Heavy Snow',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Light snow',
              value: '1',
            },
            {
              text: 'No snow',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Clouds - Flight between or around the clouds. Hilltops and obstacles obscured.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Marginal ceiling',
              value: '1',
            },
            {
              text: 'No clouds within working altitude',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Icing - Probable icing if entering in visible moisture conditions.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Marginal icing conditions',
              value: '1',
            },
            {
              text: 'No icing conditions',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Terrain - Mountainous, dense forest, overwater, tropical forest, desert…',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'City, forested, rolling hills',
              value: '1',
            },
            {
              text: 'Rural (flat…)',
              value: '0',
            },
          ],
        },
      ],
    },
    {
      Category: 'External Pressure',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Mission Importance - High value. To be completed as soon as possible.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Medium value mission but alternate transport or flight delay are feasible.',
              value: '1',
            },
            {
              text: 'Low value mission. Easily cancelable flight.',
              value: '0',
            },
          ],
        },
      ],
    },
    {
      Category: 'Operating Location',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Operating Base - New base or first four shifts on that base.',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Partially known. Some times on shift. Last shift less than three months.',
              value: '1',
            },
            {
              text: 'Well known. Regularly on shift. Last shift less than a month.',
              value: '0',
            },
          ],
        },
      ],
    },
  ],
  CurForm: 0,
  CurQuestion: 5,
  CurMitigation: 1,
};
var Template3 = {
  PrimaryKey: 0,
  Name: 'Degraded Visual Environment',
  Version: '1',
  Modified: '2016-11-08T21:35:51.206Z',
  Required: 1,
  DateTime: '',
  Departure: '',
  Destination: '',
  Operation_Type: 'Low Visibility Operations',
  Risk_Total: 0,
  Flight_Total: 0,
  Status: 'Ready',
  Med_Risk_Value: '15',
  Max_Risk_Value: '15',
  FormProcessorVersion: 1,
  Mitigate_Med_Risk: 1,
  FormProcessorVersion: 1,
  Required: 0,
  FlightReleaseReq: 0,
  EmailList: [],
  Form: [
    {
      Category: 'Conditions',
      Questions: [
        {
          Selected: 0,
          Risk_Question: 'Wind',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Strong or gusting',
              value: '2',
            },
            {
              text: 'Sustained',
              value: '1',
            },
            {
              text: 'Light/calm',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Performance (weight/density altitude)',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Close to limits during operations',
              value: '2',
            },
            {
              text: 'Close to limits only during take off or landing',
              value: '1',
            },
            {
              text: 'Well under limits',
              value: '0',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Hours PIC',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 1,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: '500+ hours and low vis trained',
              value: '1',
            },
            {
              text: '500+ Hours',
              value: '2',
            },
            {
              text: 'Less then 500 Hours PIC',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Low Visibility Training',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Within 6 month',
              value: '1',
            },
            {
              text: 'Between 6 months and 1 year',
              value: '2',
            },
            {
              text: 'Over 1 year (expired)',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'VFR Navigation',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Planned with consideration of GPS.',
              value: '1',
            },
            {
              text: 'Over-flight of a rural, unpopulated area or large expanse of water. GPS available',
              value: '2',
            },
            {
              text: 'The navigation will be by map and visual reference only',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Obstacle Separation',
          Risk_Value: '2',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Flight altitude 1000 ft above the highest obtacle',
              value: '0',
            },
            {
              text: 'Flight altitude close to the highest obstacle',
              value: '1',
            },
            {
              text: 'Flight below the highest obstacle',
              value: '2',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Terrain Texture',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Well defined recognizable texture (cities, lakes, coastlines)',
              value: '1',
            },
            {
              text: 'Some landmarks available',
              value: '2',
            },
            {
              text: 'Over-flight of a rural, unpopulated area or large expanse of water',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Time of flight',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'Day',
              value: '0',
            },
            {
              text: 'Dawn or dusk.',
              value: '1',
            },
            {
              text: 'Night',
              value: '2',
            },
            {
              text: 'Night with few illuminated visual references or over water',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Meteorological Conditions',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'More than 3 SM visibility AND ceiling above 1000 ft',
              value: '1',
            },
            {
              text: '2-3 SM visibility OR cloud base between 750 and 1000 ft',
              value: '2',
            },
            {
              text: 'Less than 1 SM visibility OR cloud base less than 500 ft',
              value: '3',
            },
          ],
        },
        {
          Selected: 0,
          Risk_Question: 'Rain/snow',
          Risk_Value: '3',
          Risk_Flight_Value: 0,
          Risk_Mitigation_Value: 0,
          Risk_Mitigation_text: '',
          Risk_Mitigation_Option: 'None',
          Risk_Mitigation_Index: -1,
          Admin_Mitigation_Req: 0,
          Risk_Mitigation_Options: [
            {
              text: 'Other',
              value: 1,
            },
            {
              text: 'No rain/snow',
              value: '0',
            },
            {
              text: 'Light rain/snow',
              value: '1',
            },
            {
              text: 'Heavy showers/snow',
              value: '2',
            },
            {
              text: 'Thunderstorms, freezing rain',
              value: '3',
            },
          ],
        },
      ],
    },
  ],
  CurForm: 0,
  CurQuestion: 9,
};
var RISK_TEMPLATES = [
  {
    Name: 'Pre Flight Risk Assessment - Fixed Wing',
    Operation_Type: 'Pre Flight - Fixed Wing',
    Form: Template1,
  },
  {
    Name: 'Pre Flight Risk Assessment - Rotary Wing',
    Operation_Type: 'Pre Flight - Rotary Wing',
    Form: Template2,
  },
  {
    Name: 'Degraded Visual Environment',
    Operation_Type: 'Low Visibility Operations',
    Form: Template3,
  },
];

var RA_Signature;
var RA_Signature_DATA = '';
function RA_CAPTURE_SIGNATURE(leg) {
  //goto signature page
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  $.mobile.changePage($('#RA_Signature'), {
    changeHash: false,
    reverse: false,
  });
  $('#ra-signature-name').val(chatname);
  $('#ra-signature-pad-wrapper').html('');
  $('#ra-signature-pad-wrapper').html('<canvas id="ra-signature-pad" class="signature-pad"></canvas>');
  var canvas = document.getElementById('ra-signature-pad');
  RA_Signature = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    RA_Signature.clear(); // otherwise isEmpty() might return incorrect value
  }
  window.addEventListener('resize', resizeCanvas);
  resizeCanvas();
  RA_Signature.leg = leg;
  RA_Signature.page = page;
}
function RA_ClearSignature() {
  RA_Signature.clear();
  if (RAform.ItinActive == 0) {
    RAform.SignatureData = '';
    RAform.SignatureName = '';
    RAform.Signed = 0;
  } else {
    RAform.LEGS[RAform.CurLeg].SignatureData = '';
    RAform.LEGS[RAform.CurLeg].SignatureName = '';
    RAform.LEGS[RAform.CurLeg].Signed = 0;
  }
}
function RA_RemoveSignature(IsLeg = 0, refresh = true) {
  if (IsLeg == 0) {
    RAform.SignatureData = '';
    RAform.SignatureName = '';
    RAform.Signed = 0;
  } else {
    RAform.LEGS[RAform.CurLeg].SignatureData = '';
    RAform.LEGS[RAform.CurLeg].SignatureName = '';
    RAform.LEGS[RAform.CurLeg].Signed = 0;
  }
  if (refresh) RAoperation();
}
function RA_returnSignature() {
  var SignatureName = $('#ra-signature-name').val();
  if (SignatureName.length == 0 && !RA_Signature.isEmpty()) {
    alert('Signature Name cannot be blank!');
    return;
  }
  $.mobile.changePage($('#' + RA_Signature.page), {
    changeHash: false,
    reverse: true,
  });
  if (SignatureName.length > 0 && RA_Signature.isEmpty()) {
    return;
  }

  if (!RA_Signature.isEmpty()) {
    var data = RA_Signature.toDataURL('image/svg+xml');
    if (RA_Signature.leg == 0) {
      RAform.SignatureData = data;
      RAform.SignatureName = SignatureName;
      RAform.Signed = 1;
    } else {
      RAform.LEGS[RAform.CurLeg].SignatureData = data;
      RAform.LEGS[RAform.CurLeg].SignatureName = SignatureName;
      RAform.LEGS[RAform.CurLeg].Signed = 1;
    }
    RAoperation();
  }
}

function RA_returnToAdminToolMenu() {
  if (VueBridge.canAccessAdminToolMenu()) {
    VueBridge.enterAdminToolMenu();
  } else {
    SAMA.Navigator.TransitionFromFRA(SAMA.pages.MenuAdmin);
  }
}
