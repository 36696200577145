/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

var GroupSyncERROR = false;
var GroupSyncError_TXT = '';

async function syncGROUP() {
  GroupSyncERROR = false;
  GroupSyncError_TXT = '';

  await AcTable.query().delete('true', []);

  IOS_postMessage({
    Message: 'ACtext',
    text: 'Downloading',
  });

  syncGROUP2();
}

function syncGROUP2() {
  $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AC', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      GroupSyncERROR = true;
      GroupSyncError_TXT += 'Offline';
    } else {
      if (json_data[0].ServerResponse == 'No Aircraft') {
        var AC_data = [];
      } else var AC_data = json_data;
      syncGroupAsync(AC_data, 0);
    }
  }).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'SyncStatus',
      status: 'GroupSyncFail',
    });
    GroupSyncERROR = true;
    GroupSyncError_TXT += 'No AC Data';
  });
}

function syncGroupAsync(AC_data, counter) {
  if (counter != AC_data.length) {
    IOS_postMessage({
      Message: 'ACtext',
      text: 'Retrieving ' + AC_data[counter].ident,
    });
    syncGroupLOOP(AC_data, counter);
  } else {
    if (GroupSyncERROR)
      IOS_postMessage({
        Message: 'ACtext',
        text: 'Complete: Error-' + GroupSyncError_TXT,
      });
    else {
      IOS_postMessage({
        Message: 'ACtext',
        text: 'Complete',
      });
      update_Sync('LastGROUPsync', LastGROUPsync);
    }
    //

    //todo delete all existing aircraft in db
    if (GROUP_SYNCING) {
      GROUP_SYNCING = false;
      SYNC_STATUS('GROUP');
    }
  }
}

function failGroupSync(acIdent, errorInfo, counter) {
  GroupSyncERROR = true;
  GroupSyncError_TXT += 'XHR Failed get WB: ' + acIdent.ident;
  console.error(acIdent, errorInfo);
  IOS_postMessage({
    Message: 'SyncStatus',
    status: 'GroupSyncFailAC',
    ident: acIdent,
  });
  syncGroupAsync(AC_data, counter);
}

async function syncGroupLOOP(AC_data, counter) {
  const acIdent = AC_data[counter].ident;

  counter++;

  let GroupRecord = null;
  try {
    GroupRecord = await RemoteData.get(
      'menu-Maintenance-AJAX.php?action=GetAC_WB&AcIdent=' + encodeURIComponent(acIdent)
    );
  } catch (e) {
    failGroupSync(acIdent, e, counter);
  }

  const serverResponse = GroupRecord.ServerResponse;

  if (serverResponse !== 'Success') {
    failGroupSync(acIdent, GroupRecord, counter);
    return;
  }

  await AcTable.query().insert(GroupRecord.data);

  syncGroupAsync(AC_data, counter);
}

function insert_GroupRecord(Record, Store, last) {
  cirroDB.insert('AC', Record, null, function (e) {});
}
