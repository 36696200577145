/**
 * Data class for custom fields
 */

class mSAMA_CustomFieldValue extends mSAMA_AbstractModel {
  /**
   *
   * @param {mSAMA_RiskFactorValue} _riskFactorValueParent
   * @param {mSAMA_CustomField}     _customField
   * @param {object}                [_databaseData]
   */
  constructor(_riskFactorValueParent, _customField, _databaseData) {
    super('mcfv_primaryKey');
    this.customField = _customField;
    this.riskFactorValue = _riskFactorValueParent;
    this.valueNames = [];

    this.sync = {
      mcfv_primaryKey: null,
      mcfv_mcfPk: this.customField.sync.mcf_primaryKey,
      mcfv_mrfvPk: null,
      mcfv_value: '',
      modified: false,
    };

    this.validateSettings = {};

    this.syncReset = {};

    $.extend(true, this.sync, _databaseData);

    if (this.GetPk() == null) {
      this.SetPk(cSAMA_Utils.GetUID());
    }

    this.InstallValidator(this.customField.sync.mcf_type);

    // Stash the initial settings for the sync object
    this.ConfirmValueChanges();
  }

  /**
   *
   * @param {SAMA.enums.CUSTFIELDTYPES.} _mcfType
   */
  InstallValidator(_mcfType) {
    let validator;
    switch (_mcfType) {
      case SAMA.enums.CUSTFIELDTYPES.TextField:
        validator = {
          type: SAMA.enums.VALIDATORS.STRING,
          minLength: 3,
          maxLength: SAMA.enums.TEXTLENGTHS.Medium,
        };
        break;

      case SAMA.enums.CUSTFIELDTYPES.GearSelect:
        validator = {
          type: SAMA.enums.VALIDATORS.KEYWORD,
          maxTags: 10,
          tagName: 'item',
        };
        break;

      case SAMA.enums.CUSTFIELDTYPES.CraftSelect:
        validator = {
          type: SAMA.enums.VALIDATORS.KEYWORD,
          maxTags: 10,
          tagName: 'vehicle',
        };
        break;

      case SAMA.enums.CUSTFIELDTYPES.StaffSelect:
        validator = {
          type: SAMA.enums.VALIDATORS.KEYWORD,
          maxTags: 10,
          tagName: 'employee',
        };
        break;

      case SAMA.enums.CUSTFIELDTYPES.BaseSelect:
        validator = {
          type: SAMA.enums.VALIDATORS.KEYWORD,
          maxTags: 5,
          tagName: 'base',
        };
        break;

      default:
        throw 'Unknown field type ' + this.customField.sync.mcf_type;
    }

    this.validateSettings = {
      mcfv_value: new cSAMA_Validator(validator),
    };
  }

  /**
   * Makes the field required at a certain risk level
   * @param _level
   */
  SetRequiredLevel(_level) {
    if (this.validateSettings.mcfv_value == null) {
      return;
    }

    let required = _level >= this.customField.sync.mcf_isMandatory && this.customField.sync.mcf_isMandatory > 1;

    this.validateSettings.mcfv_value.options.required = required;
    this.validateSettings.mcfv_value.options.minTags = required ? 1 : null;
  }

  /**
   * Gets the Associated Custom Field
   * @return {mSAMA_CustomField}
   */
  GetCustomField() {
    return this.customField;
  }

  SetValueNames(_valueNames) {
    this.valueNames = _valueNames;
  }

  /**
   * Changes the modified state
   * @param {boolean} _state
   * @override
   */
  SetModified(_state) {
    this.sync.modified = _state;
    this.riskFactorValue.SetModified(_state);
  }

  /**
   * Resets the changes made to the value object
   */
  DiscardValueChanges() {
    // console.log('SAMA', 'Discarding', this.sync, 'in favour of', this.syncReset);
    $.extend(true, this.sync, this.syncReset);
  }

  /**
   * Confirms the changes made to the value object
   */
  ConfirmValueChanges() {
    $.extend(true, this.syncReset, this.sync);
  }
}
