class ProgressDialog {
  constructor() {
    this._title = '';
  }

  show(title) {
    this._title = title;

    if (IOS) {
      progress.show(title);
    } else if (ANDROID) {
      cordova.plugin.pDialog
        .init({
          theme: 'HOLO_DARK',
          progressStyle: 'HORIZONTAL',
          cancelable: false,
          title: title,
          message: 'Preparing...',
        })
        .setProgress(0);
    }
  }

  update(_msg, _progressPercentage = 0) {
    if (IOS) {
      progress.update(`${this._title} - ${_msg} (${_progressPercentage}%)`);
    } else if (ANDROID) {
      cordova.plugin.pDialog.setMessage(_msg).setProgress(_progressPercentage);
    }
  }

  hide() {
    if (IOS) {
      progress.hide();
    } else if (ANDROID) {
      cordova.plugin.pDialog.dismiss();
    }
  }
}

window.progressDialog = new ProgressDialog();
