/**
 *
 */
class WbTable extends AbstractDatabaseTable {
  constructor() {
    super('WB', 'LegNum');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            LegNum integer primary key not null,
            data text
        )
    `;
    //#endregion
  }
}
