/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

function ExitEditFC() {
  $.mobile.changePage($('#FCadmin_Page'), {
    reverse: true,
    changeHash: false,
  });
}

async function downloadFuelCacheCSV() {
  $.mobile.loading('show');
  const url = `${BASEURL}menu-Administration-FC-AJAX.php`;

  const params = {
    action: 'ExportFC_CSV',
  };

  try {
    const fileName = window.vueApp.utilities.file.getTimestampedFileName('FuelCaches', 'csv');
    await window.vueApp.utilities.download.fromUrl(url).setUrlParams(params).toSaveAs(null, fileName);
  } catch (e) {
    window.vueApp.sentry.captureException(e);
  }
  $.mobile.loading('hide');
}
function updateFCLabel() {
  if (this.value == 1) {
    $("label[for='FCfull']").text('Capacity');
    $("label[for='FCempty']").text('% Remaining');
  } else {
    $("label[for='FCfull']").text('Full');
    $("label[for='FCempty']").text('Empty');
  }
}

var FCList = [];
var FC_CurIndex = -1;

var FC_Uplift = {
  div: '',
  curSearchID: '',
  curCache: null,
  curTarget: null,
  legIndex: -1,
  upLiftList: {},
};

function resetFC_Uplifts() {
  $('#EditItinFuelUplift').html('');
  $('#ItinFuelUplift').html('');
  $('#FRFuelUplift-1').html('');
  FC_Uplift = {
    div: '',
    curSearchID: '',
    curCache: null,
    curTarget: null,
    legIndex: -1,
    upLiftList: {},
  };
}

function setFC_Uplift_SearchID(id) {
  FC_Uplift.curSearchID = id;
  if (id != '') {
    FC_Uplift.legIndex = id;
  }
}

function FC_Uplift_SearchClosest() {
  navigator.geolocation.getCurrentPosition(FC_Uplift_SortLocations, FC_Uplift_SortLocationsERROR, {
    maximumAge: 600000,
  });
}

function FC_Uplift_SortLocationsERROR(error) {
  alert(iTrans('Device Location Unavailable'));
}
function FC_Uplift_SortLocations(position) {
  UserDevice.Lat = position.coords.latitude;
  UserDevice.Long = position.coords.longitude;
  UserDevice.Accuracy = position.coords.accuracy;
  UserDevice.Altitude = position.coords.altitude;
  UserDevice.AltitudeAccuracy = position.coords.altitudeAccuracy;
  UserDevice.Heading = position.coords.heading;
  UserDevice.Speed = position.coords.speed;
  UserDevice.Timestamp = position.timestamp;
  FC_SortListByDistance();
}

function FC_SortListByDistance() {
  var point1 = turf.point([UserDevice.Long, UserDevice.Lat]);
  var FC_DistData = [];
  for (var i in FCdata) {
    var F = FCdata[i];
    var point2 = turf.point([F.Long, F.Lat]);
    var bearing = turf.bearing(point1, point2).toFixed(0);
    F.distance = turf.distance(point1, point2, { units: 'meters' });
    if (bearing < 0) bearing = 360 + parseInt(bearing);
    F.bearing = bearing;
    FC_DistData.push(F);
  }
  FC_DistData.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  $('#FC_UpliftLocation' + FC_Uplift.curSearchID).empty();
  $('#FC_UpliftLocation' + FC_Uplift.curSearchID).append('<option value="0">' + iTrans('No Fuel Loaded') + '</option>');
  var selectedPK = 0;
  for (var i in FC_DistData) {
    var F = FC_DistData[i];
    if (i == 0) {
      selectedPK = F.PrimaryKey;
    }
    $('#FC_UpliftLocation' + FC_Uplift.curSearchID).append(
      '<option value="' +
        F.PrimaryKey +
        '">' +
        F.location +
        ' [' +
        F.FuelType +
        '] ' +
        getDisplayDistance(F.distance) +
        '</option>'
    );
  }
  $('#FC_UpliftSearch' + FC_Uplift.curSearchID).val('');
  FC_UpliftSelected(selectedPK);
}
function FC_UpliftHTML(div, id) {
  if (GROUP_DATA.FuelCache == 0) {
    return;
  }
  FC_Uplift.curSearchID = id;
  $('#' + div).html('');
  var html = '<h3>' + iTrans('Record Fuel Loaded') + '</h3>';
  html += '<table class="first-td-fill"><tr><td>';
  html += '<label for="FC_UpliftSearch' + id + '" >' + iTrans('Search Fuel Caches') + '</label>';
  html +=
    '<input type="text" id="FC_UpliftSearch' +
    id +
    '" data-mini="true" onFocus="setFC_Uplift_SearchID(\'' +
    id +
    '\')"/></td><td>';
  html +=
    '<button class="action-btn" data-mini="true" onClick="FC_Uplift_SearchClosest()"><img src="./images/searchicon.png" width="35px"/></button></td></tr></table>';
  html += '<label for="FC_UpliftLocation' + id + '" >' + iTrans('Fuel Cache Tracking') + '</label>';
  html +=
    '<select id="FC_UpliftLocation' +
    id +
    '" data-mini="true" onFocus="setFC_Uplift_SearchID(\'' +
    id +
    '\')" onChange="FC_UpliftSelected(this.value);">';
  html += '<option value="0">' + iTrans('No Fuel Loaded') + '</option>';
  for (var i in FCdata) {
    var F = FCdata[i];
    html += '<option value="' + F.PrimaryKey + '">' + F.location + ' [' + F.FuelType + ']</option>';
  }
  html += '</select>';
  html += '<div id="FC_curUplift' + id + '"></div>';
  $('#' + div)
    .html(html)
    .enhanceWithin();
  $('#FC_UpliftSearch' + id).autocomplete({
    select: FC_UpliftSelect,
    source: FC_UpliftSearch,
  });
}

function FC_UpliftSearch(term, callback) {
  var text = term.term;
  var Users = FC_UpliftFilter(FCdata, text, callback);
}

function FC_UpliftFilter(OrigArray, keywords, callback) {
  var userFiltered = OrigArray;
  userFiltered = OrigArray.filter(function (item) {
    if (item.location.toLowerCase().indexOf(keywords.toLowerCase()) > -1) {
      return true;
    } else {
      return false;
    }
  });
  var options = [];
  for (var i in userFiltered) {
    var I = userFiltered[i];
    options.push({
      label: escapeHtml(I.location) + ' [' + escapeHtml(I.FuelType) + ']',
      value: I.PrimaryKey,
    });
  }
  callback(options);
}

function FC_UpliftSelect(event, ui) {
  var FCPK = ui.item.value;
  setTimeout(function () {
    $('#FC_UpliftSearch' + FC_Uplift.curSearchID).val('');
    FC_UpliftSelected(FCPK);
    $('#FC_UpliftSearch' + FC_Uplift.curSearchID).blur();
  }, 500);
}

function FC_showCurUplift() {
  var disabled = '';
  if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
    var FC = FC_Uplift.upLiftList[FC_Uplift.legIndex];
    if (FC.historyPK != null) {
      var units = iTrans('Pounds');
      switch (FC.Units) {
        case 'pounds':
          units = iTrans('Pounds');
          break;
        case 'litres':
          units = iTrans('Litres');
          break;
        case 'gallons':
          units = iTrans('US Gallons');
          break;
        case 'kg':
          units = iTrans('Kilograms');
          break;
        case 'drums':
          units = iTrans('Drums');
          break;
      }
      var html = '<h3>' + iTrans('Fuel Loading Record') + '</h3>';
      html +=
        '<table style="margin:0;"><tr><td>' + iTrans('Fuel Cache') + '</td><td>' + FC.location_name + '</td></tr>';
      html += '<tr><td>' + iTrans('Amount Taken') + '</td><td>' + FC.Amount + ' ' + units + '</td></tr>';
      html += '<tr><td>' + iTrans('Comment') + '</td><td>' + FC.comment + '</td></tr></table>';
      html +=
        '<p><i>' +
        iTrans('Note') +
        ': ' +
        iTrans('Fuel Cache has been auto updated successfully and this record is now locked.') +
        '</i></p>';
      $('#' + FC_Uplift.div)
        .html(html)
        .enhanceWithin();
      return;
    }
  }

  var html = '<table class="first-td-fill"><tr><td>';
  html += '<label for="FC_Uplift_Amount' + FC_Uplift.curSearchID + '">' + iTrans('Fuel Amount Taken') + '</label>';
  html +=
    '<input type="number" id="FC_Uplift_Amount' +
    FC_Uplift.curSearchID +
    '" onFocus="setFC_Uplift_SearchID(\'' +
    FC_Uplift.curSearchID +
    '\')" data-mini="true" inputmode="numeric" onChange="updateFC_Uplift()" ' +
    disabled +
    '/>';
  html += '</td><td>';
  html += '<label for="FC_Uplift_Units' + FC_Uplift.curSearchID + '">' + iTrans('Units') + '</label>';
  html +=
    '<select id="FC_Uplift_Units' +
    FC_Uplift.curSearchID +
    '"  onFocus="setFC_Uplift_SearchID(\'' +
    FC_Uplift.curSearchID +
    '\')" onChange="updateFC_Uplift(false)" data-mini="true" ' +
    disabled +
    '>';
  html += '<option value="litres">' + iTrans('Litres') + '</option>';
  html += '<option value="usgallons">' + iTrans('US Gallons') + '</option>';
  html += '<option value="gallons">' + iTrans('IMP Gallons') + '</option>';
  html += '<option value="pounds">' + iTrans('Pounds') + '</option>';
  html += '<option value="kg">' + iTrans('Kilograms') + '</option>';
  if (FC_Uplift.curCache.bulk == 0) {
    html += '<option value="drums">' + iTrans('Drums') + '</option>';
  }
  html += '</select>';
  html += '</td></tr></table>';
  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Convert To Units') +
    '</th><th>' +
    iTrans('Converted Value') +
    '</th></tr></thead><tbody><tr><td style="width:130px;">';
  html +=
    '<select id="FC_Uplift_convertTo' +
    FC_Uplift.curSearchID +
    '"  onFocus="setFC_Uplift_SearchID(\'' +
    FC_Uplift.curSearchID +
    '\')" onChange="updateFC_convertTo(this)" data-mini="true" ' +
    disabled +
    '>';
  html += '<option value="">' + iTrans('None') + '</option>';
  html += '<option value="litres">' + iTrans('Litres') + '</option>';
  html += '<option value="usgallons">' + iTrans('US Gallons') + '</option>';
  html += '<option value="gallons">' + iTrans('IMP Gallons') + '</option>';
  html += '<option value="pounds">' + iTrans('Pounds') + '</option>';
  html += '<option value="kg">' + iTrans('Kilograms') + '</option>';
  html += '<option value="drums">' + iTrans('Drums') + '</option>';
  html += '</select>';
  html += '</td><td>';
  html +=
    '<span id="FC_Uplift_converted' +
    FC_Uplift.curSearchID +
    '" onClick="setFC_Uplift_SearchID(\'' +
    FC_Uplift.curSearchID +
    '\')"></span>';
  html += '</td></tr></tbody></table>';
  html += '<label for="FC_Uplift_Comment' + FC_Uplift.curSearchID + '">' + iTrans('Comment') + '</label>';
  html +=
    '<input type="text" id="FC_Uplift_Comment' +
    FC_Uplift.curSearchID +
    '" onFocus="setFC_Uplift_SearchID(\'' +
    FC_Uplift.curSearchID +
    '\')" onChange="updateFC_Uplift(false)" ' +
    disabled +
    '/>';
  html +=
    '<label for="FC_Uplift_autoUpdate' +
    FC_Uplift.curSearchID +
    '">' +
    iTrans('Auto Update Fuel Cache History') +
    '</label>';
  html +=
    '<input type="checkbox" id="FC_Uplift_autoUpdate' +
    FC_Uplift.curSearchID +
    '" onClick="setFC_Uplift_SearchID(\'' +
    FC_Uplift.curSearchID +
    '\');updateFC_Uplift(false)" data-mini="true" ' +
    disabled +
    '/>';
  $('#FC_curUplift' + FC_Uplift.curSearchID)
    .html(html)
    .enhanceWithin();
  updateFC_convertTo();
  if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
    var values = FC_Uplift.upLiftList[FC_Uplift.legIndex];
    $('#FC_Uplift_Amount' + FC_Uplift.curSearchID).val(values.Amount);
    $('#FC_Uplift_Units' + FC_Uplift.curSearchID)
      .val(values.Units)
      .selectmenu('refresh');
    $('#FC_Uplift_autoUpdate' + FC_Uplift.curSearchID)
      .prop('checked', values.autoUpdate == 1)
      .checkboxradio('refresh');
    $('#FC_Uplift_Comment' + FC_Uplift.curSearchID).val(values.comment);
  } else {
    $('#FC_Uplift_Amount' + FC_Uplift.curSearchID).val('');
    $('#FC_Uplift_Units' + FC_Uplift.curSearchID)
      .val(AC_UNITS == 'Imperial' ? 'pounds' : 'kg')
      .selectmenu('refresh');
    $('#FC_Uplift_Comment' + FC_Uplift.curSearchID).val('');
  }
}

function updateFC_convertTo(e = null) {
  var value = '';
  if (e !== null) {
    value = e.value;
  }
  if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
    var UL_FC = FC_Uplift.upLiftList[FC_Uplift.legIndex];
    UL_FC.convertTo = value;
    var FuelCache = FCdata.filter(function (item) {
      if (item.PrimaryKey == FC_Uplift.upLiftList[FC_Uplift.legIndex].FC_PK) {
        return true;
      } else {
        return false;
      }
    });
    if (FuelCache.length > 0) {
      var FC = FuelCache[0];
      FC_Uplift.curCache = FC;
      var convertedValue = convertFC_ToUnits(FC.FuelType, UL_FC.Amount, UL_FC.Units, UL_FC.convertTo);
      if (convertedValue !== false) {
        $('#FC_Uplift_converted' + FC_Uplift.curSearchID).html(round10(convertedValue).toLocaleString('en-US'));
      } else {
        $('#FC_Uplift_converted' + FC_Uplift.curSearchID).html('N/A');
      }
    } else {
      $('#FC_Uplift_converted' + FC_Uplift.curSearchID).html('N/A');
    }
  } else {
    $('#FC_Uplift_converted' + FC_Uplift.curSearchID).html('N/A');
  }
}

function updateFC_Uplift(updateModified = true) {
  var id = FC_Uplift.curSearchID;
  var FC = FC_Uplift.curCache;
  if (FC == null) {
    if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
      if (FC_Uplift.upLiftList[FC_Uplift.legIndex].PrimaryKey != null) {
        FC_Uplift.upLiftList[FC_Uplift.legIndex].FC_PK = 0;
        FC_Uplift.upLiftList[FC_Uplift.legIndex].Amount = 0;
        FC_Uplift.upLiftList[FC_Uplift.legIndex].location_name = 0;
      } else {
        delete FC_Uplift.upLiftList[FC_Uplift.legIndex];
      }
    }
    return;
  }
  if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
    var Uplift = FC_Uplift.upLiftList[FC_Uplift.legIndex];
    Uplift.FC_PK = FC.PrimaryKey;
    Uplift.location_name = FC.location;
    Uplift.comment = $('#FC_Uplift_Comment' + FC_Uplift.curSearchID).val();
    Uplift.Amount = Math.abs(
      $('#FC_Uplift_Amount' + FC_Uplift.curSearchID).val() == ''
        ? 0
        : $('#FC_Uplift_Amount' + FC_Uplift.curSearchID).val()
    );
    Uplift.Units = $('#FC_Uplift_Units' + FC_Uplift.curSearchID).val();
    Uplift.autoUpdate = $('#FC_Uplift_autoUpdate' + FC_Uplift.curSearchID).is(':checked') ? 1 : 0;
    Uplift.legIndex = FC_Uplift.legIndex;
    if (updateModified) Uplift.datetime = dateToStringObject(new Date());
    Uplift.FuelType = FC.FuelType;
    Uplift.convertTo = $('#FC_Uplift_convertTo' + FC_Uplift.curSearchID).val();
  } else {
    var Uplift = {
      PrimaryKey: null,
      location_name: FC.location,
      comment: $('#FC_Uplift_Comment' + FC_Uplift.curSearchID).val(),
      datetime: dateToStringObject(new Date()),
      FC_PK: FC.PrimaryKey,
      Itin_PK: null,
      Itin_OfflinePK: null,
      FR_PK: null,
      FR_UID: null,
      legIndex: FC_Uplift.legIndex,
      Amount: Math.abs(
        $('#FC_Uplift_Amount' + FC_Uplift.curSearchID).val() == ''
          ? 0
          : $('#FC_Uplift_Amount' + FC_Uplift.curSearchID).val()
      ),
      Units: $('#FC_Uplift_Units' + FC_Uplift.curSearchID).val(),
      FuelType: FC.FuelType,
      convertTo: $('#FC_Uplift_convertTo' + FC_Uplift.curSearchID).val(),
      autoUpdate: $('#FC_Uplift_autoUpdate' + FC_Uplift.curSearchID).is(':checked') ? 1 : 0,
      historyPK: null,
    };
    FC_Uplift.upLiftList[FC_Uplift.legIndex] = Uplift;
  }
  updateFC_convertTo({ value: $('#FC_Uplift_convertTo' + FC_Uplift.curSearchID).val() });
}

function FC_UpliftSelected(FCPK) {
  var FuelCache = FCdata.filter(function (item) {
    if (item.PrimaryKey == FCPK) {
      return true;
    } else {
      return false;
    }
  });
  if (FuelCache.length > 0) {
    var FC = FuelCache[0];
    FC_Uplift.curCache = FC;
    $('#FC_UpliftLocation' + FC_Uplift.curSearchID)
      .val(FC_Uplift.curCache.PrimaryKey)
      .selectmenu('refresh');
    if (FC.FuelType == 'Unknown') {
      $('#FC_Uplift_autoUpdate' + FC_Uplift.curSearchID)
        .prop('checked', false)
        .prop('disabled', true)
        .checkboxradio('refresh');
    }
    if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
      var UL_FC = FC_Uplift.upLiftList[FC_Uplift.legIndex];
      if (UL_FC.FC_PK != FC.PrimaryKey) {
        UL_FC.FC_PK = FC.PrimaryKey;
        UL_FC.location_name = FC.location;
        UL_FC.FuelType = FC.FuelType;
        UL_FC.historyPK = null;
      }
    }
    FC_showCurUplift();
  } else {
    FC_Uplift.curCache = null;
    $('#FC_UpliftLocation' + FC_Uplift.curSearchID)
      .val(0)
      .selectmenu('refresh');
    $('#FC_curUplift' + FC_Uplift.curSearchID).html('');
    if (FC_Uplift.upLiftList[FC_Uplift.legIndex] != undefined) {
      if (FC_Uplift.upLiftList[FC_Uplift.legIndex].PrimaryKey != null) {
        FC_Uplift.upLiftList[FC_Uplift.legIndex].FC_PK = 0;
        FC_Uplift.upLiftList[FC_Uplift.legIndex].Amount = 0;
        FC_Uplift.upLiftList[FC_Uplift.legIndex].location_name = 0;
      } else {
        delete FC_Uplift.upLiftList[FC_Uplift.legIndex];
      }
    }
  }
}

function FC_UpliftLegSelect() {
  if (GROUP_DATA.FuelCache == 0 || GROUP_DATA.FC_Itin_Capture == 0) {
    return;
  }
  FC_Uplift.legIndex = WB_LEG_INDEX;
  if (FC_Uplift.upLiftList == null) {
    FC_Uplift.upLiftList = {};
  }
  FC_UpliftHTML(FC_Uplift.div, '');
  if (FC_Uplift.upLiftList[WB_LEG_INDEX] != undefined) {
    FC_UpliftSelected(FC_Uplift.upLiftList[WB_LEG_INDEX].FC_PK);
  } else {
    FC_UpliftSelected(0);
  }
}

function FR_UpliftLegSelect(legIndex) {
  if (GROUP_DATA.FuelCache == 0) {
    return;
  }
  console.log('legIndex', legIndex);
  if (FC_Uplift.upLiftList == null) {
    FC_Uplift.upLiftList = {};
  }
  FC_Uplift.legIndex = legIndex;
  FC_UpliftHTML(FC_Uplift.div, legIndex);
  if (FC_Uplift.upLiftList[legIndex] != undefined) {
    FC_UpliftSelected(FC_Uplift.upLiftList[legIndex].FC_PK);
  } else {
    FC_UpliftSelected(0);
  }
}

function updateFC_CaptureOpts() {
  var itinOpt = $('#enableFC_Itinerary').is(':checked') ? 1 : 0;
  var frOpt = $('#enableFC_FlightReport').is(':checked') ? 1 : 0;
  $.getJSON(
    BASEURL + 'menu-Administration-FC-AJAX.php?action=updateCaptureOpts&itinOpt=' + itinOpt + '&frOpt=' + frOpt,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'getFClist');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse == 'Success') {
          window.toaster.show(iTrans('FC Capture Settings Updated Successfully'), 2);
          GROUP_DATA.FC_Itin_Capture = itinOpt;
          GROUP_DATA.FC_FR_Capture = frOpt;
        }
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function getFClist() {
  $('#enableFC_Itinerary')
    .prop('checked', GROUP_DATA.FC_Itin_Capture == 1)
    .checkboxradio('refresh');
  $('#enableFC_FlightReport')
    .prop('checked', GROUP_DATA.FC_FR_Capture == 1)
    .checkboxradio('refresh');
  $('#FCList').html("<h3 class='limit-table'>" + iTrans('Getting Fuel Cache List') + '</h3>');
  $.getJSON(BASEURL + 'menu-Administration-FC-AJAX.php?action=getFClistV2', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'No Records') {
        $('#FCList').html('');
        $('#FCList').html('<h3>' + iTrans('There are no Fuel Caches in the system') + '</h3>');
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'getFClist');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
    } else {
      console.log('Received Fuel Cache Data');
      FCList = json_data;
      displayFC2(json_data);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function displayFC2(FCList) {
  $('#FCList').html('');
  var drumHTML =
    '<h2 class="limit-table">Drum Caches</h2><div style="width: 100%; overflow: auto"><table id="FCList_Drum" data-filter="true" class="limit-table first-td-fill" >' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Unique ID') +
    '</th><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Fuel Type') +
    '</th><th>' +
    iTrans('Pump') +
    '</th><th>' +
    iTrans('AC_Power') +
    '</th><th>' +
    iTrans('APU_Power') +
    '</th><th>' +
    iTrans('Bad_Fuel') +
    '</th><th>' +
    iTrans('U/S') +
    '</th><th>' +
    iTrans('Inspected') +
    '</th><th>' +
    iTrans('Full') +
    '</th><th>' +
    iTrans('Full_Alarm') +
    '</th><th>' +
    iTrans('Empty') +
    '</th><th>' +
    iTrans('Updated') +
    '</th><th>' +
    iTrans('By') +
    '</th><th>' +
    iTrans('Lat') +
    '/' +
    iTrans('Long') +
    '</th><th>' +
    iTrans('Moveable') +
    '</th><th>' +
    iTrans('Comment') +
    '</th><th>' +
    iTrans('Update_Comment') +
    '</th><th>Action</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';

  var bulkHTML =
    '<h2 class="limit-table">Bulk Tank Caches</h2><div style="width: 100%; overflow: auto"><table id="FCList_Bulk" data-filter="true" class="limit-table first-td-fill" >' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Unique ID') +
    '</th><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Fuel Type') +
    '</th><th>' +
    iTrans('Pump') +
    '</th><th>' +
    iTrans('AC_Power') +
    '</th><th>' +
    iTrans('APU_Power') +
    '</th><th>' +
    iTrans('Bad_Fuel') +
    '</th><th>' +
    iTrans('U/S') +
    '</th><th>' +
    iTrans('Inspected') +
    '</th><th>' +
    iTrans('Updated') +
    '</th><th>' +
    iTrans('By') +
    '</th><th>' +
    iTrans('Lat') +
    '/' +
    iTrans('Long') +
    '</th><th>' +
    iTrans('Moveable') +
    '</th><th>' +
    iTrans('Capacity') +
    '</th><th>' +
    iTrans('Capacity_Alarm') +
    '</th><th>' +
    iTrans('Remaining') +
    '</th><th>' +
    iTrans('Comment') +
    '</th><th>' +
    iTrans('Update_Comment') +
    '</th><th>Action</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';
  for (var i in FCList) {
    var FC = FCList[i];

    var rowClass = '';
    if (FC.bulk == 1 && parseInt(FC.remaining) <= parseInt(FC.capacity_Alarm)) {
      rowClass = 'yellowbg';
    }
    if (FC.bulk == 0 && parseFloat(FC.full) <= parseFloat(FC.full_Alarm)) {
      rowClass = 'yellowbg';
    }

    if (FC.hasBad_Fuel != 0) {
      rowClass = 'redbg';
    }
    if (FC.bulk == 0) {
      drumHTML +=
        '<tr class="rowHighlight ' +
        rowClass +
        '"><td onClick="EditFCItem(' +
        i +
        ')">' +
        FC.PrimaryKey +
        '</td><td>' +
        FC.location +
        '</td><td>' +
        FC.FuelType +
        '</td><td>' +
        (FC.hasFuelPump == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasAC_Power == 1 || FC.hasAC_Power == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasAC_Power == 2 || FC.hasAC_Power == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasBad_Fuel == 1 || FC.hasBad_Fuel == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasBad_Fuel == 2 || FC.hasBad_Fuel == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.inspected == '1980-01-01' ? '<span class="yellowbg">Never</span>' : FC.inspected) +
        '</td><td>' +
        FC.full +
        '</td><td>' +
        FC.full_Alarm +
        '</td><td>' +
        FC.empty +
        '</td><td>' +
        FC.datetime +
        '</td><td>' +
        FC.whoupdate +
        '</td><td class="nowrap">' +
        ConvertDD_User(FC.Lat, 'Lat') +
        '<br />' +
        ConvertDD_User(FC.Long, 'Long') +
        '</td><td>' +
        (FC.moveable == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        FC.Comment +
        '</td><td>' +
        FC.Update_Comment +
        '</td>' +
        "<td><div class='vertical-center-container'><button onClick='EditFCItem(" +
        i +
        ");' name='" +
        i +
        "' class='action-btn' data-mini='true'><img src='./images/edit.svg' /></button><button onClick='deleteFCListV2(" +
        FC.PrimaryKey +
        ");' name='" +
        i +
        "' PrimaryKey = 'test'  class='action-btn' data-mini='true'><img src='./images/delete.svg' /></button></div></td></tr>";
    } else {
      bulkHTML +=
        '<tr class="rowHighlight ' +
        rowClass +
        '"><td onClick="EditFCItem(' +
        i +
        ')">' +
        FC.PrimaryKey +
        '</td><td>' +
        FC.location +
        '</td><td>' +
        FC.FuelType +
        '</td><td>' +
        (FC.hasFuelPump == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasAC_Power == 1 || FC.hasAC_Power == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasAC_Power == 2 || FC.hasAC_Power == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasBad_Fuel == 1 || FC.hasBad_Fuel == 3 ? 'Yes' : 'No') +
        '</td><td>' +
        (FC.hasBad_Fuel == 2 || FC.hasBad_Fuel == 3 ? 'Yes' : 'No') +
        '</td><td class="nowrap">' +
        (FC.inspected == '1980-01-01' ? '<span class="yellowbg">Never</span>' : FC.inspected) +
        '</td><td class="nowrap">' +
        FC.datetime +
        '</td><td>' +
        FC.whoupdate +
        '</td><td class="nowrap">' +
        ConvertDD_User(FC.Lat, 'Lat') +
        '<br />' +
        ConvertDD_User(FC.Long, 'Long') +
        '</td><td>' +
        (FC.moveable == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        FC.capacity +
        '</td><td>' +
        FC.capacity_Alarm +
        '</td><td>' +
        FC.remaining +
        '</td><td>' +
        FC.Comment +
        '</td><td>' +
        FC.Update_Comment +
        '</td>' +
        "<td><div class='vertical-center-container'><button onClick='EditFCItem(" +
        i +
        ");' name='" +
        i +
        "' class='action-btn' data-mini='true'><img src='./images/edit.svg' /></button><button onClick='deleteFCListV2(" +
        FC.PrimaryKey +
        ");' name='" +
        i +
        "' PrimaryKey = 'test'  class='action-btn' data-mini='true'><img src='./images/delete.svg' /></button></div></td></tr>";
    }
  }

  drumHTML += '</tbody></table></div>';
  bulkHTML += '</tbody></table></div>';

  $('#FCList').html(drumHTML + bulkHTML);
  $('#FCList').enhanceWithin();

  AddTableSorter('FCList_Drum');
  AddTableSorter('FCList_Bulk');
}

function ShowFC_History(Item) {
  try {
    var FCList = JSON.parse(JSON.stringify(Item.History));
  } catch (e) {
    var FCList = [];
  }
  var Keys = Object.keys(Item);
  //loop through and highligh changed fields
  if (FCList.length == 0) {
    return '<h3>' + iTrans('No Fuel Cache History') + '</h3>';
  }
  for (var i in FCList) {
    var P = FCList[i];
    var C = Item;
    if (i == 0) {
      continue;
    }
    if (i > 0) {
      C = FCList[parseInt(i) - 1];
    }
    for (var k in Keys) {
      var K = Keys[k];
      switch (K) {
        case 'bulk':
        case 'DELETED':
        case 'Image':
        case 'PrimaryKey':
        case 'datetime':
          continue;
          break;
        case 'hasFuelPump':
          if (P[K] != C[K]) {
            C[K] = '<span class="yellowbg">' + (C[K] == 1 ? 'Yes' : 'No') + '</span>';
          } else {
            C[K] = C[K] == 1 ? 'Yes' : 'No';
          }
          continue;
          break;
        case 'hasBad_Fuel':
        case 'hasAC_Power':
          continue;
          break;
      }
      if (P[K] != C[K] && K != 'hasAC_Power' && K != 'hasBad_Fuel') {
        C[K] = '<span class="yellowbg">' + C[K] + '</span>';
      }
    }
  }
  var drumHTML =
    '<div style="width: 100%; overflow: auto"><table id="FCList_DrumHistory" data-filter="true" class="limit-table first-td-fill" >' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Fuel Type') +
    '</th><th>' +
    iTrans('Pump') +
    '</th><th>' +
    iTrans('AC_Power') +
    '</th><th>' +
    iTrans('APU_Power') +
    '</th><th>' +
    iTrans('Bad_Fuel') +
    '</th><th>' +
    iTrans('U/S') +
    '</th><th>' +
    iTrans('Inspected') +
    '</th><th>' +
    iTrans('Full') +
    '</th><th>' +
    iTrans('Full_Alarm') +
    '</th><th>' +
    iTrans('Empty') +
    '</th><th>' +
    iTrans('Updated') +
    '</th><th>' +
    iTrans('By') +
    '</th><th>' +
    iTrans('Lat') +
    '</th><th>' +
    iTrans('Long') +
    '</th><th>' +
    iTrans('Comment') +
    '</th><th>' +
    iTrans('Update_Comment') +
    '</th>';
  '</tr>' + '</thead>' + '<tbody>';
  var bulkHTML =
    '<div style="width: 100%; overflow: auto"><table id="FCList_BulkHistory" data-filter="true" class="limit-table first-td-fill" >' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Fuel Type') +
    '</th><th>' +
    iTrans('Pump') +
    '</th><th>' +
    iTrans('AC_Power') +
    '</th><th>' +
    iTrans('APU_Power') +
    '</th><th>' +
    iTrans('Bad_Fuel') +
    '</th><th>' +
    iTrans('U/S') +
    '</th><th>' +
    iTrans('Inspected') +
    '</th><th>' +
    iTrans('Updated') +
    '</th><th>' +
    iTrans('By') +
    '</th><th>' +
    iTrans('Lat') +
    '</th><th>' +
    iTrans('Long') +
    '</th><th>' +
    iTrans('Moveable') +
    '</th><th>' +
    iTrans('Capacity') +
    '</th><th>' +
    iTrans('Capacity_Alarm') +
    '</th><th>' +
    iTrans('Remaining') +
    '</th><th>' +
    iTrans('Comment') +
    '</th><th>' +
    iTrans('Update_Comment') +
    '</th>';
  '</tr>' + '</thead>' + '<tbody>';
  for (var i in FCList) {
    var FC = FCList[i];

    var hasAC_Power = FC.hasAC_Power == 1 || FC.hasAC_Power == 3 ? 'Yes' : 'No';
    var hasAPU_Power = FC.hasAC_Power == 2 || FC.hasAC_Power == 3 ? 'Yes' : 'No';

    if (FCList[parseInt(i) + 1] != undefined) {
      var NFC = FCList[parseInt(i) + 1];
      if (
        ((FC.hasAC_Power == 1 || FC.hasAC_Power == 3) && (NFC.hasAC_Power == 0 || NFC.hasAC_Power == 2)) ||
        ((FC.hasAC_Power == 0 || FC.hasAC_Power == 2) && (NFC.hasAC_Power == 1 || NFC.hasAC_Power == 3))
      ) {
        hasAC_Power = '<span class="yellowbg">' + hasAC_Power + '</span>';
      }
      if (
        ((FC.hasAC_Power == 2 || FC.hasAC_Power == 3) && (NFC.hasAC_Power == 0 || NFC.hasAC_Power == 1)) ||
        (FC.hasAC_Power == 0 && (NFC.hasAC_Power == 2 || NFC.hasAC_Power == 3))
      ) {
        hasAPU_Power = '<span class="yellowbg">' + hasAPU_Power + '</span>';
      }
    }

    var hasBad_Fuel = FC.hasBad_Fuel == 1 || FC.hasBad_Fuel == 3 ? 'Yes' : 'No';
    var outOfService = FC.hasBad_Fuel == 2 || FC.hasBad_Fuel == 3 ? 'Yes' : 'No';

    if (FCList[parseInt(i) + 1] != undefined) {
      var NFC = FCList[parseInt(i) + 1];
      if (
        ((FC.hasBad_Fuel == 1 || FC.hasBad_Fuel == 3) && (NFC.hasBad_Fuel == 0 || NFC.hasBad_Fuel == 2)) ||
        ((FC.hasBad_Fuel == 0 || FC.hasBad_Fuel == 2) && (NFC.hasBad_Fuel == 1 || NFC.hasBad_Fuel == 3))
      ) {
        hasAC_Power = '<span class="yellowbg">' + hasAC_Power + '</span>';
      }
      if (
        ((FC.hasBad_Fuel == 2 || FC.hasBad_Fuel == 3) && (NFC.hasBad_Fuel == 0 || NFC.hasBad_Fuel == 1)) ||
        (FC.hasBad_Fuel == 0 && (NFC.hasBad_Fuel == 2 || NFC.hasBad_Fuel == 3))
      ) {
        outOfService = '<span class="yellowbg">' + outOfService + '</span>';
      }
    }

    var rowClass = '';
    if (FC.bulk == 0) {
      drumHTML +=
        '<tr><td>' +
        FC.location +
        '</td><td>' +
        FC.FuelType +
        '</td><td>' +
        FC.hasFuelPump +
        '</td><td>' +
        hasAC_Power +
        '</td><td>' +
        hasAPU_Power +
        '</td><td>' +
        hasBad_Fuel +
        '</td><td>' +
        outOfService +
        '</td><td>' +
        (FC.inspected == '1980-01-01' ? '<span class="yellowbg">Never</span>' : FC.inspected) +
        '</td><td>' +
        FC.full +
        '</td><td>' +
        FC.full_Alarm +
        '</td><td>' +
        FC.empty +
        '</td><td>' +
        FC.datetime +
        '</td><td>' +
        FC.whoupdate +
        '</td><td>' +
        FC.Lat +
        '</td><td>' +
        FC.Long +
        '</td><td>' +
        FC.Comment +
        '</td><td>' +
        FC.Update_Comment +
        '</td></tr>';
    } else {
      bulkHTML +=
        '<tr><td>' +
        FC.location +
        '</td><td>' +
        FC.FuelType +
        '</td><td>' +
        FC.hasFuelPump +
        '</td><td>' +
        hasAC_Power +
        '</td><td>' +
        hasAPU_Power +
        '</td><td>' +
        hasBad_Fuel +
        '</td><td>' +
        outOfService +
        '</td><td>' +
        (FC.inspected == '1980-01-01' ? '<span class="yellowbg">Never</span>' : FC.inspected) +
        '</td><td>' +
        FC.datetime +
        '</td><td>' +
        FC.whoupdate +
        '</td><td>' +
        FC.Lat +
        '</td><td>' +
        FC.Long +
        '</td><td>' +
        FC.moveable +
        '</td><td>' +
        FC.capacity +
        '</td><td>' +
        FC.capacity_Alarm +
        '</td><td>' +
        FC.remaining +
        '</td><td>' +
        FC.Comment +
        '</td><td>' +
        FC.Update_Comment +
        '</td></tr>';
    }
  }
  drumHTML += '</tbody></table></div>';
  bulkHTML += '</tbody></table></div>';

  if (Item.bulk == 1) {
    return bulkHTML;
  } else {
    return drumHTML;
  }
}

function deleteFCListV2(index) {
  console.log('DeleteFC');
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Delete Fuel Cache'),
    headerClose: false,
    buttonPrompt: iTrans('Fuel Cache will be permanently Deleted! Are you Sure?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Administration-FC-AJAX.php?action=deleteFCV2&FCPK=' + index, function (json_data) {
            if (json_data[0].ServerResponse == 'Offline') {
              var msg;
              msg.ServerResponse = 'Connection Error';
              ServerResponse(msg, 'Deleting Fuel Cache');
            } else {
              getFClist();
            }
          }).fail(function (jqXHR, status, error) {
            var msg;
            msg.ServerResponse = 'Connection Error';
            ServerResponse(msg, 'Deleting Fuel Cache');
          });
        },
      },
      [iTrans('CANCEL')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function ChangeFC_Type(e) {
  if (e.value == 1) {
    $('#FC-DRUMHLDR').hide();
    $('#FC-BULKHLDR').show();
    FCList[FC_CurIndex].bulk = 1;
  } else {
    $('#FC-DRUMHLDR').show();
    $('#FC-BULKHLDR').hide();
    FCList[FC_CurIndex].bulk = 0;
  }
  setTimeout(function () {
    editFCListV2(FCList[FC_CurIndex]);
  }, 100);
}

function EditFCItem(index) {
  FC_CurIndex = index;
  editFCListV2(FCList[index]);
}

var FC_AlertList = [];

function editFCListV2(Item) {
  console.log('EditFC');
  $.mobile.changePage($('#Edit_FC_PageV2'), {
    changeHash: false,
  });
  $('#FCEditHeader').html(Item.location);

  var html = '';
  FC_AlertList = Item.AlertList;
  html += '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true"><legend>Type</legend>';
  if (Item.bulk == 1) {
    html +=
      '<input type="radio" name="FCTYPE" id="FCDRUM" value="0" onClick="updateFCitemV2(true);ChangeFC_Type(this);">';
  } else {
    html +=
      '<input type="radio" name="FCTYPE" id="FCDRUM" value="0" onClick="updateFCitemV2(true);ChangeFC_Type(this);" checked>';
  }
  html += '<label for="FCDRUM" id="FCDRUMTXT">' + iTrans('Drum') + '</label>';
  if (Item.bulk == 1) {
    html +=
      '<input type="radio" name="FCTYPE" id="FCBULK" value="1" onClick="updateFCitemV2(true);ChangeFC_Type(this);" checked>';
  } else {
    html +=
      '<input type="radio" name="FCTYPE" id="FCBULK" value="1" onClick="updateFCitemV2(true);ChangeFC_Type(this);">';
  }
  html += '<label for="FCBULK"  id="FCBULKTXT">' + iTrans('Bulk') + '</label>';
  html += '</fieldset>';

  html +=
    '<label for="FC-PrimaryKey">' +
    iTrans('Unique ID') +
    '</label><input type="text" id="FC-PrimaryKey" value="' +
    escapeHtml(Item.PrimaryKey) +
    '" disabled/>';

  html +=
    '<label for="FC-location">' +
    iTrans('Name') +
    '</label><input type="text" id="FC-location"data-mini="true" value="' +
    escapeHtml(Item.location) +
    '"/>';
  html +=
    '<label for="FC-FuelType">' +
    iTrans('Fuel Type') +
    '</label><select id="FC-FuelType" data-mini="true" data-mini="true">';
  var FuelTypes = ['Unknown', 'JET-A', 'JET-B', '100LL', 'Diesel', 'Gasoline'];
  for (var i in FuelTypes) {
    html += '<option value="' + FuelTypes[i] + '">' + FuelTypes[i] + '</option>';
  }
  html += '</select>';
  if (Item.hasFuelPump == 1) {
    html +=
      '<label for="FC-hasFuelPump">' +
      iTrans('Has a Fuel Pump') +
      '</label><input type="checkbox" id="FC-hasFuelPump" data-mini="true" checked/>';
  } else {
    html +=
      '<label for="FC-hasFuelPump">' +
      iTrans('Has a Fuel Pump') +
      '</label><input type="checkbox" id="FC-hasFuelPump" data-mini="true"/>';
  }

  html +=
    '<label for="FC-hasAC_Power">' +
    iTrans('Has A/C Power') +
    '</label><input type="checkbox" id="FC-hasAC_Power" data-mini="true" ' +
    (Item.hasAC_Power == 1 || Item.hasAC_Power == 3 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="FC-hasAPU_Power">' +
    iTrans('Has APU Power') +
    '</label><input type="checkbox" id="FC-hasAPU_Power" data-mini="true" ' +
    (Item.hasAC_Power == 2 || Item.hasAC_Power == 3 ? 'checked' : '') +
    '/>';

  html +=
    '<label for="FC-hasBad_Fuel">' +
    iTrans('Has Bad Fuel') +
    '</label><input type="checkbox" id="FC-hasBad_Fuel" data-mini="true" ' +
    (Item.hasBad_Fuel == 1 || Item.hasBad_Fuel == 3 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="FC-outOfService">' +
    iTrans('Out Of Service') +
    '</label><input type="checkbox" id="FC-outOfService" data-mini="true" ' +
    (Item.hasBad_Fuel == 2 || Item.hasBad_Fuel == 3 ? 'checked' : '') +
    '/>';

  html += '<div id="FC-AlertListHLDR"></div>';

  html +=
    '<label for="FC-inspected">' +
    iTrans('Inspected') +
    '</label><input type="date" id="FC-inspected" data-mini="true" value="' +
    escapeHtml(Item.inspected == '1980-01-01' ? '' : Item.inspected) +
    '"/>';
  html += '<div id="FC-DRUMHLDR">';
  html +=
    '<label for="FC-full">' +
    iTrans('Full') +
    '</label><input type="text" id="FC-full" data-mini="true" value="' +
    escapeHtml(Item.full) +
    '"/>';
  html +=
    '<label for="FC-full_Alarm">' +
    iTrans('Full_Alarm') +
    '</label><input type="text" id="FC-full_Alarm" data-mini="true" value="' +
    escapeHtml(Item.full_Alarm) +
    '"/>';
  html +=
    '<label for="FC-empty">' +
    iTrans('Empty') +
    '</label><input type="text" id="FC-empty" data-mini="true" value="' +
    escapeHtml(Item.empty) +
    '"/>';
  html += '</div>';

  html +=
    '<label for="FC-datetime">' +
    iTrans('Last updated') +
    '</label><input type="text" id="FC-datetime" data-mini="true" value="' +
    escapeHtml(Item.datetime) +
    '" disabled/>';
  html +=
    '<label for="FC-whoupdate">' +
    iTrans('Who Updated') +
    '</label><input type="text" id="FC-whoupdate" data-mini="true" value="' +
    escapeHtml(Item.whoupdate) +
    '" disabled/>';
  html +=
    '<label for="FC-Lat">' +
    iTrans('Lat') +
    ' (DDD.DDDDD°)</label><input type="text" id="FC-Lat" data-mini="true" value="' +
    escapeHtml(Item.Lat) +
    '"/>';
  html +=
    '<label for="FC-Long">' +
    iTrans('Long') +
    ' (DDD.DDDDD°)</label><input type="text" id="FC-Long" data-mini="true" value="' +
    escapeHtml(Item.Long) +
    '"/>';
  html +=
    '<button data-role="none" class="mapboxgl-ctrl-icon" onclick="SelectPointOnMap(\'Edit_FC_PageV2\')"><div class="vertical-center-container"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 561 561" style="enable-background:new 0 0 561 561;" xml:space="preserve"><g>	<path d="M280.5,185.8c-52.1,0-94.7,42.6-94.7,94.7s42.6,94.7,94.7,94.7s94.7-42.6,94.7-94.7S332.6,185.8,280.5,185.8z M491.3,256.8		c-11.8-99.5-90-177.6-187.1-187.1V20h-47.4v49.7c-99.5,9.5-177.6,87.6-187.1,187.1H20v47.4h49.7c11.8,99.5,90,177.6,187.1,187.1		V541h47.4v-49.7c99.5-11.8,177.6-90,187.1-187.1H541v-47.4H491.3z M280.5,446.3c-92.4,0-165.8-73.4-165.8-165.8		s73.4-165.8,165.8-165.8s165.8,73.4,165.8,165.8S372.9,446.3,280.5,446.3z"></path></g><circle id="gpsONicon" cx="280.5" cy="280.5" r="75"></circle></svg> Select Location From Map</div></button>';
  if (Item.moveable == 1) {
    html +=
      '<label for="FC-moveable">' +
      iTrans('Moveable') +
      '</label><input type="checkbox" id="FC-moveable" data-mini="true" checked/>';
  } else {
    html +=
      '<label for="FC-moveable">' +
      iTrans('Moveable') +
      '</label><input type="checkbox" id="FC-moveable" data-mini="true"/>';
  }

  html += '<input type="hidden" id="FC-bulk" data-mini="true" value="' + escapeHtml(Item.bulk) + '"/>';
  html += '<div id="FC-BULKHLDR">';
  html +=
    '<label for="FC-capacity">' +
    iTrans('Capacity') +
    '</label><input type="text" id="FC-capacity" data-mini="true" value="' +
    escapeHtml(Item.capacity) +
    '"/>';
  html +=
    '<label for="FC-capacity_Alarm">' +
    iTrans('Capacity_Alarm') +
    '</label><input type="text" id="FC-capacity_Alarm" data-mini="true" value="' +
    escapeHtml(Item.capacity_Alarm) +
    '"/>';
  html +=
    '<label for="FC-remaining">' +
    iTrans('Remaining') +
    '</label><input type="text" id="FC-remaining" data-mini="true" value="' +
    escapeHtml(Item.remaining) +
    '"/>';

  if (Item.bulk == 1) {
    html +=
      '<label for="FC-MeasureType">' +
      iTrans('Measurement Type') +
      '</label><select id="FC-MeasureType" data-mini="true">';
    var MeasureTypes = ['Estimated', 'Meter', 'Dip Stick', 'Count', 'Unknown'];
    for (var i in MeasureTypes) {
      html += '<option value="' + MeasureTypes[i] + '">' + iTrans(MeasureTypes[i]) + '</option>';
    }
    html += '</select>';

    html +=
      '<label for="FC-DipChartUnits">' +
      iTrans('Dip Chart Units') +
      '</label><select id="FC-DipChartUnits" data-mini="true">';
    html += '<option value="cm">cm</option>';
    html += '<option value="in">in</option>';
    html += '<option value="mm">mm</option>';
    html += '</select>';

    html += '<div id="DipChartDATA"></div>';
  } else {
    html += '<input type="hidden" id="FC-MeasureType" value="' + escapeHtml(Item.MeasureType) + '" />';
    html += '<input type="hidden" id="FC-DipChartUnits" value="' + escapeHtml(Item.DipChartUnits) + '" />';
  }
  html +=
    '<label for="FC-Units">' +
    iTrans('Bulk Tank Units') +
    '</label><select type="text" id="FC-Units" data-mini="true">';
  html += '<option value="litres">Litres</option>';
  html += '<option value="usgallons">US Gallons</option>';
  html += '<option value="gallons">IMP Gallons</option>';
  html += '</select>';

  html += '<input type="hidden" id="FC-DipChart" value="' + escapeHtml(Item.DipChart) + '"/>';

  html += '</div>';

  html +=
    '<label for="FC-ImageDateTime">' +
    iTrans('ImageDateTime') +
    '</label><input type="text" id="FC-ImageDateTime" data-mini="true" value="' +
    escapeHtml(Item.ImageDateTime) +
    '" disabled/>';
  if (Item.ImageDateTime == '1980-01-01 00:00:00') {
    html += '<div id="FC-Image_DIV"><h3>No Image On File</h3></div>';
  } else {
    html += '<div id="FC-Image_DIV"></div>';
  }
  if (Item.Image == undefined) {
    Item.Image = '';
  }
  html += '<input type="hidden" id="FC-Image" value="' + Item.Image + '"/>';

  html +=
    '<input type="file" id="FC-Image_File" onChange="base64File(\'FC_Admin\')" accept="image/*; capture=camera"/>';
  if (ANDROID) {
    html +=
      '<div id="AndroidFC-Image_AdminHLDR" style="display: none;"><button onclick="getPic(\'FC_Admin\');" id="AndroidFC-Image_File" data-mini="true" data-icon="camera" >' +
      iTrans('Take Picture') +
      '</button></div>';
  }

  html +=
    '<label for="FC-Comment">' +
    iTrans('Comment') +
    '</label><textarea id="FC-Comment">' +
    escapeHtml(Item.Comment) +
    '</textarea>';

  html +=
    '<label for="FC-Update_Comment">' +
    iTrans('Update Comment') +
    '</label><textarea type="text" id="FC-Update_Comment"></textarea>';
  html += '<input type="hidden" id="FC-inspection_Doc_ID" value="' + escapeHtml(Item.inspection_Doc_ID) + '"/>';
  html += '<input type="hidden" id="FC-DELETED" value="' + escapeHtml(Item.DELETED) + '"/>';
  html +=
    '<button v-t="menu_Administration_FC_LANG.updateFCBTN" id="updateFCBTN" data-mini="true" data-icon="check" onclick="updateFCitemV2();">Save</button>';
  html += '<h2>' + iTrans('History') + '</h2>';
  html += ShowFC_History(Item);

  $('#FC_EditHLDRV2').html(html);
  $('#FC_EditHLDRV2').enhanceWithin();

  $('#FC-FuelType').val(Item.FuelType).selectmenu('refresh');

  if (Item.ImageDateTime != '1980-01-01 00:00:00') {
    $('#FC-Image_DIV').html('<img src="./images/loading_processmaker.gif" width="75px" />');
    GetFC_Image('FC-Image_DIV', Item.PrimaryKey);
  }
  if (Item.bulk == 1) {
    $('#FC-DipChartUnits').val(Item.DipChartUnits).selectmenu('refresh');
    $('#FC-Units').val(Item.Units).selectmenu('refresh');
    ShowFC_DipChart();
    $('#FC-DRUMHLDR').hide();
  } else {
    $('#FC-BULKHLDR').hide();
  }
  ShowFC_AlertList();
}

function ShowFC_AlertList() {
  var html = '<h3>' + iTrans('Alert List') + '</h3>';
  if (FC_AlertList.length > 0) {
    html +=
      '<table><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Email') +
      '</th><th>' +
      iTrans('Phone') +
      '</th><th>' +
      iTrans('Alert') +
      '</th><th>' +
      iTrans('Update') +
      '</th></tr></thead><tbody>';
    for (var i in FC_AlertList) {
      var I = FC_AlertList[i];
      if (I.DELETED == 1) {
        continue;
      }
      html +=
        '<tr><td>' +
        I.ExternalContact +
        '</td><td>' +
        I.ExternalEmail +
        '</td><td>' +
        I.ExternalPhone +
        '</td><td>' +
        (I.alert_Alarm == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.alert_Update == 1 ? 'Yes' : 'No') +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html = '<h3>' + iTrans('No Entries For Alert List') + '</h3>';
  }
  html += '<button data-mini="true" onClick="EditFC_AlertList();">' + iTrans('Edit Alert List') + '</button>';
  $('#FC-AlertListHLDR').html(html);
  $('#FC-AlertListHLDR').enhanceWithin();
}

function EditFC_AlertList() {
  var html = '<h3>' + iTrans('Alert List') + '</h3>';
  if (FC_AlertList.length > 0) {
    html +=
      '<table><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Email') +
      '</th><th>' +
      iTrans('Phone') +
      '</th><th>' +
      iTrans('Alert') +
      '</th><th>' +
      iTrans('Update') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in FC_AlertList) {
      var I = FC_AlertList[i];
      if (I.DELETED == 1) {
        continue;
      }
      html +=
        '<tr><td>' +
        I.ExternalContact +
        '</td><td>' +
        I.ExternalEmail +
        '</td><td>' +
        I.ExternalPhone +
        '</td><td>' +
        (I.alert_Alarm == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.alert_Update == 1 ? 'Yes' : 'No') +
        '</td><td><div class="vertical-center-container"><button onclick="deleteAlertListIndex(' +
        i +
        ');" class="action-btn" data-mini="true"><img src="./images/delete.svg"></button></div></td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html = '<h3>' + iTrans('No Entries For Alert List') + '</h3>';
  }
  html +=
    '<table><tr><td><button data-mini="true" onClick="AddFC_AlertList_External()" class="greenbg" data-icon="plus">' +
    iTrans('External User') +
    '</button></td>';
  html +=
    '<td><button data-mini="true" onClick="AddFC_AlertList_System()" data-icon="plus" class="greenbg">' +
    iTrans('System User') +
    '</button></td>';
  html +=
    '<td><button data-mini="true" onClick="ShowFC_AlertList()" class="yellowbg" data-icon="alert">' +
    iTrans('Save Edits') +
    '</button></td></tr></table>';
  $('#FC-AlertListHLDR').html(html);
  $('#FC-AlertListHLDR').enhanceWithin();
}

function deleteAlertListIndex(index) {
  if (FC_AlertList[index].PrimaryKey == 'NEW') {
    FC_AlertList.splice(index, 1);
  } else {
    FC_AlertList[index].DELETED = 1;
  }
  EditFC_AlertList();
}

function FC_UpdateRowClass(index, e) {
  if (e.checked) {
    $('#FC_AlertRow-' + index).addClass('greenbg');
  } else {
    $('#FC_AlertRow-' + index).removeClass('greenbg');
  }
}

function AddFC_AlertList_System() {
  var html =
    '<div style="overflow:scroll; height: 300px;"><table class="limit-table" data-filter="true"><thead><tr><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Select') +
    '</th></tr></thead><tbody>';
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    if (FC_AlertList.find((o) => o.User_PK == I.PrimaryKey) != undefined) {
      continue;
    }
    var check =
      '<input type="checkbox" data-role="none" id="FC_AlertStaffSelect-' +
      i +
      '" onClick="FC_UpdateRowClass(' +
      i +
      ', this);" />';
    html +=
      '<tr id="FC_AlertRow-' +
      i +
      '"><td>' +
      I.chatname +
      '</td><td>' +
      I.staffType +
      '</td><td>' +
      check +
      '</td></tr>';
  }
  html += '</tbody></table>';
  html += '</div>';
  html +=
    '<label for="FC_Alert_alertAlarm">' +
    iTrans('Low Level Alert') +
    '</label><input type="checkbox" id="FC_Alert_alertAlarm" data-mini="true" />';
  html +=
    '<label for="FC_Alert_alertUpdate">' +
    iTrans('Alert on Update') +
    '</label><input type="checkbox" id="FC_Alert_alertUpdate" data-mini="true" />';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Add System User'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          //acknolwedge
          var alert_Alarm = $('#FC_Alert_alertAlarm').is(':checked') ? 1 : 0;
          var alert_Update = $('#FC_Alert_alertUpdate').is(':checked') ? 1 : 0;
          for (var i in STAFF_LIST) {
            var I = STAFF_LIST[i];
            if ($('#FC_AlertStaffSelect-' + i).is(':checked')) {
              FC_AlertList.push({
                PrimaryKey: 'NEW',
                group: GROUP_DATA.group,
                FC_PK: parseInt($('#FC-PrimaryKey').val()),
                User_PK: I.PrimaryKey,
                ExternalContact: I.chatname,
                ExternalEmail: '[Linked]',
                ExternalPhone: '[Linked]',
                alert_Alarm: alert_Alarm,
                alert_Update: alert_Update,
                DELETED: 0,
              });
            }
          }
          EditFC_AlertList();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
    width: 'auto',
  });
}

function AddFC_AlertList_External() {
  var html = '';
  html +=
    '<label for="FC_Alert_ExternalContact">' +
    iTrans('Name') +
    '</label><input type="text" id="FC_Alert_ExternalContact" data-mini="true" />';
  html +=
    '<label for="FC_Alert_ExternalEmail">' +
    iTrans('Email Address') +
    '</label><input type="text" id="FC_Alert_ExternalEmail" data-mini="true" />';
  html +=
    '<label for="FC_Alert_ExternalPhone">' +
    iTrans('Phone Number') +
    '</label><input type="text" id="FC_Alert_ExternalPhone" data-mini="true" />';

  html +=
    '<label for="FC_Alert_alertAlarm">' +
    iTrans('Low Level Alert') +
    '</label><input type="checkbox" id="FC_Alert_alertAlarm" data-mini="true" />';
  html +=
    '<label for="FC_Alert_alertUpdate">' +
    iTrans('Alert on Update') +
    '</label><input type="checkbox" id="FC_Alert_alertUpdate" data-mini="true" />';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Add External User'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          //acknolwedge
          var alert_Alarm = $('#FC_Alert_alertAlarm').is(':checked') ? 1 : 0;
          var alert_Update = $('#FC_Alert_alertUpdate').is(':checked') ? 1 : 0;

          FC_AlertList.push({
            PrimaryKey: 'NEW',
            group: GROUP_DATA.group,
            FC_PK: parseInt($('#FC-PrimaryKey').val()),
            User_PK: 0,
            ExternalContact: $('#FC_Alert_ExternalContact').val(),
            ExternalEmail: $('#FC_Alert_ExternalEmail').val(),
            ExternalPhone: $('#FC_Alert_ExternalPhone').val(),
            alert_Alarm: alert_Alarm,
            alert_Update: alert_Update,
            DELETED: 0,
          });
          EditFC_AlertList();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
    width: 'auto',
  });
}

function ShowFC_DipChart() {
  var DipChart = $('#FC-DipChart').val();
  try {
    var Chart = JSON.parse(DipChart);
    if (Chart.length == 0) {
      $('#DipChartDATA').html(
        '<button data-mini="true" onClick="EditFC_DipChart()" >' + iTrans('Add Dip Chart Data') + '</button>'
      );
    } else {
      var html =
        '<h3>' +
        iTrans('Dip Chart Data') +
        '</h3><table><thead><tr><th>' +
        iTrans('Measure') +
        ' ' +
        $('#FC-DipChartUnits').val() +
        '</th><th>' +
        iTrans('Amount') +
        ' ' +
        $('#FC-Units').val() +
        '</th></tr></thead><tbody>';
      for (var i in Chart) {
        html += '<tr><td>' + Chart[i].Measure + '</td><td>' + Chart[i].Amount + '</td></tr>';
      }
      html += '</tbody></table>';
      html += '<button data-mini="true" onClick="EditFC_DipChart()" >' + iTrans('Edit Chart Data') + '</button>';
      $('#DipChartDATA').html(html);
    }
  } catch (e) {
    $('#DipChartDATA').html(
      '<button data-mini="true" onClick="EditFC_DipChart()" >' + iTrans('Add Dip Chart Data') + '</button>'
    );
  }
  $('#DipChartDATA').enhanceWithin();
}

function EditFC_DipChart() {
  var DipChart = $('#FC-DipChart').val();
  var Chart;
  try {
    Chart = JSON.parse(DipChart);
  } catch (e) {
    Chart = [];
  }
  var html =
    '<h3>' +
    iTrans('Dip Chart Data') +
    '</h3><table><thead><tr><th>' +
    iTrans('Measure') +
    ' ' +
    $('#FC-DipChartUnits').val() +
    '</th><th>' +
    iTrans('Amount') +
    ' ' +
    $('#FC-Units').val() +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in Chart) {
    html +=
      '<tr><td>' +
      Chart[i].Measure +
      '</td><td>' +
      Chart[i].Amount +
      '</td><td><div class="vertical-center-container"><button onclick="deleteDipChartIndex(' +
      i +
      ');" class="action-btn data-mini="true"><img src="./images/delete.svg"></button></div></td></tr>';
  }
  html += '</tbody></table>';

  html +=
    '<table><tr><td><label for="FC_UPDATE_DipChartMeasure">' +
    iTrans('Measure') +
    '</label><input type="number" data-mini="true" id="FC_UPDATE_DipChartMeasure" /></td>';
  html +=
    '<td><label for="FC_UPDATE_DipChartAmount">' +
    iTrans('Amount') +
    '</label><input type="number" data-mini="true" id="FC_UPDATE_DipChartAmount" /></td></tr></table>';
  html +=
    '<table><tr><td><button data-mini="true" onClick="AddFC_DipChartData()" class="greenbg" data-icon="plus">' +
    iTrans('Add Dip Chart Data Point') +
    '</button></td>';
  html +=
    '<td><button data-mini="true" onClick="ShowFC_DipChart()" >' +
    iTrans('Done Editing') +
    '</button></td></tr></table>';
  $('#DipChartDATA').html(html);
  $('#DipChartDATA').enhanceWithin();
}

function deleteDipChartIndex(index) {
  var DipChart = $('#FC-DipChart').val();
  var Chart;
  try {
    Chart = JSON.parse(DipChart);
    Chart.splice(index, 1);
  } catch (e) {
    Chart = [];
  }
  $('#FC-DipChart').val(JSON.stringify(Chart));
  EditFC_DipChart();
}

function AddFC_DipChartData() {
  var measure = parseFloat($('#FC_UPDATE_DipChartMeasure').val());
  var amount = parseFloat($('#FC_UPDATE_DipChartAmount').val());
  var max = parseFloat($('#FC-capacity').val());
  if (measure < 0 || amount < 0 || measure == null || amount == null) {
    alert(iTrans('Measure and Amount cannot be less than 0 or blank'));
    return;
  }
  if (amount > max) {
    alert(iTrans('Amount cannot be greater than bulk tank capacity'));
    return;
  }
  var DipChart = $('#FC-DipChart').val();
  var Chart;
  try {
    Chart = JSON.parse(DipChart);
  } catch (e) {
    Chart = [];
  }
  Chart.push({ Measure: measure, Amount: amount });

  function compare(a, b) {
    a = a.Amount;
    b = b.Amount;
    if (a > b) {
      return -1;
    }
    if (b > a) {
      return 1;
    }

    return 0;
  }

  Chart.sort(compare);
  $('#FC-DipChart').val(JSON.stringify(Chart));
  EditFC_DipChart();
}

function GetFC_Image(id, PrimaryKey, Store = false) {
  $.getJSON(BASEURL + 'sync-AJAX.php?action=fcDataV2_ImageData&FCPK=' + PrimaryKey, function (json_data) {
    if (json_data[0].ServerResponse == 'Success') {
      FC_rec = json_data[0].data;
      $('#' + id).html('<img src="' + json_data[0].data.Image + '" width="400"/>');
      if (Store == true) {
        $('#FC-Image').val(json_data[0].data.Image);
      }
    } else {
      $('#' + id).html('<h3>' + iTrans('Failed to Load Image') + '</h3>');
    }
  }).fail(function (jqXHR, status, error) {
    $('#' + id).html('<h3>' + iTrans('Failed to Load Image') + '</h3>');
  });
}

function NewFC() {
  var NewCache = {
    PrimaryKey: 'NEW',
    location: '',
    FuelType: 'Unknown',
    hasFuelPump: 0,
    hasAC_Power: 0,
    hasBad_Fuel: 0,
    inspected: '1980-01-01',
    full: 0,
    full_Alarm: 0,
    empty: 0,
    datetime: '1980-01-01 00:00:00',
    whoupdate: '',
    Lat: 0,
    Long: 0,
    moveable: 0,
    bulk: 0,
    capacity: 0,
    capacity_Alarm: 0,
    remaining: 0,
    MeasureType: 'Estimated',
    Units: 'litres',
    DipChart: '',
    DipChartUnits: 'cm',
    Image: '',
    ImageDateTime: '1980-01-01 00:00:00',
    Comment: '',
    Update_Comment: '',
    inspection_Doc_ID: 0,
    DELETED: 0,
    History: [],
    AlertList: [],
  };
  FC_CurIndex = -1;
  FCList[FC_CurIndex] = NewCache;
  editFCListV2(NewCache);
}

function updateFCitemV2(changeFCtype = false) {
  console.log('Update Fuel Cache item');
  let hasAC_Power = 0;
  if ($('#FC-hasAC_Power').is(':checked') && !$('#FC-hasAPU_Power').is(':checked')) {
    hasAC_Power = 1;
  }
  if (!$('#FC-hasAC_Power').is(':checked') && $('#FC-hasAPU_Power').is(':checked')) {
    hasAC_Power = 2;
  }
  if ($('#FC-hasAC_Power').is(':checked') && $('#FC-hasAPU_Power').is(':checked')) {
    hasAC_Power = 3;
  }

  let hasBad_Fuel = 0;
  if ($('#FC-hasBad_Fuel').is(':checked') && !$('#FC-outOfService').is(':checked')) {
    hasBad_Fuel = 1;
  }
  if (!$('#FC-hasBad_Fuel').is(':checked') && $('#FC-outOfService').is(':checked')) {
    hasBad_Fuel = 2;
  }
  if ($('#FC-hasBad_Fuel').is(':checked') && $('#FC-outOfService').is(':checked')) {
    hasBad_Fuel = 3;
  }
  var FuelCache = {
    PrimaryKey: $('#FC-PrimaryKey').val(),
    group: $('#FC-group').val(),
    location: $('#FC-location').val(),
    FuelType: $('#FC-FuelType').val(),
    hasFuelPump: $('#FC-hasFuelPump').is(':checked') ? 1 : 0,
    hasAC_Power: hasAC_Power,
    hasBad_Fuel: hasBad_Fuel,
    inspected: $('#FC-inspected').val() == '' ? '1980-01-01' : $('#FC-inspected').val(),
    full: $('#FC-full').val(),
    full_Alarm: $('#FC-full_Alarm').val(),
    empty: $('#FC-empty').val(),
    datetime: $('#FC-datetime').val(),
    whoupdate: $('#FC-whoupdate').val(),
    Lat: $('#FC-Lat').val(),
    Long: $('#FC-Long').val(),
    moveable: $('#FC-moveable').is(':checked') ? 1 : 0,
    bulk: $('#FC-bulk').val(),
    capacity: $('#FC-capacity').val(),
    capacity_Alarm: $('#FC-capacity_Alarm').val(),
    remaining: $('#FC-remaining').val(),
    MeasureType: $('#FC-MeasureType').val(),
    Units: $('#FC-Units').val(),
    DipChart: $('#FC-DipChart').val(),
    DipChartUnits: $('#FC-DipChartUnits').val(),
    Image: $('#FC-Image').val(),
    ImageDateTime: $('#FC-ImageDateTime').val(),
    Comment: $('#FC-Comment').val(),
    Update_Comment: $('#FC-Update_Comment').val(),
    inspection_Doc_ID: $('#FC-inspection_Doc_ID').val(),
    DELETED: $('#FC-DELETED').val(),
    AlertList: FC_AlertList,
  };
  if (changeFCtype) {
    FCList[FC_CurIndex] = FuelCache;
    return;
  }
  $.mobile.loading('show', { theme: 'a' });
  $.post(
    BASEURL + 'menu-Administration-FC-AJAX.php?action=updateFCV2',
    {
      data: FuelCache,
    },
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'UpdateSpot');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
      if (json_data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Successfully Added/Updated'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //acknolwedge
                $('#UpdateFCBTN').prop('disabled', false);
                $.mobile.changePage($('#FCadmin_Page'), {
                  reverse: true,
                  changeHash: false,
                });
                getFClist();
                BackgroundSync(false, true);
              },
            },
          },
        });
      }
      if (json_data[0].ServerResponse == 'Nothing Changed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Message'),
          headerClose: false,
          buttonPrompt: iTrans('Nothing Changed'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //acknolwedge
                $('#UpdateFCBTN').prop('disabled', false);
                $.mobile.changePage($('#FCadmin_Page'), {
                  reverse: true,
                  changeHash: false,
                });
                getFClist();
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $.mobile.loading('hide');
    AJAXFAIL(error);
  });
}
