/**
 * Class handling the display and real-time modification of risk matrices
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_RiskMatrixView extends iSAMA_AbstractObservable {
  /**
   * @param {jQuery}           _location   Where the matrix should be rendered on the page
   * @param {mSAMA_RiskMatrix} _riskMatrix The risk matrix to render
   */
  constructor(_location, _riskMatrix) {
    super();
    this.riskMatrix = _riskMatrix;
    this.dom = {
      location: _location.hide().parent(),
      body: $(SAMA.Templates.tpl.riskMatrix.view),
    };

    this.dom.headers = this.dom.body.find('[data-riskMatrix-header]');
    this.dom.cells = this.dom.body.find('[data-riskMatrix-risk]');

    this.dom.location.append(this.dom.body);
    this.UpdateGui();
  }

  /**
   * Updates the Matrix's display with new colors
   */
  UpdateGui() {
    this.dom.headers.toArray().forEach((_el) => {
      let element = $(_el);
      let attr = element.attr('data-riskMatrix-header');
      element.text(this.riskMatrix.sync[attr]);
    });

    this.dom.cells.toArray().forEach((_el) => {
      let element = $(_el);
      let split = element.attr('data-riskMatrix-risk').split('-');

      let level = +split[0] + +split[1];
      let bounds = this.riskMatrix.GetBounds();

      if (level >= bounds.high) {
        element.text('High').parent().removeClass('sama-low sama-medium').addClass('sama-high');
      } else if (level >= bounds.medium) {
        element.text('Medium').parent().removeClass('sama-high sama-low').addClass('sama-medium');
      } else {
        element.text('Low').parent().removeClass('sama-high sama-medium').addClass('sama-low');
      }
    });
  }

  /**
   * Change the selected cell
   * @param {int} _p.risk
   * @param {int} _p.frequency
   */
  ChangeSelection(_p) {
    if (this.selectedCell != null) {
      this.selectedCell.removeClass('sama-select');
      this.selectedCell = null;
    }

    if (_p.frequency === 0 || _p.risk === 0) {
      return;
    }

    _p.risk = Math.ceil(_p.risk);
    _p.frequency = Math.ceil(_p.frequency);

    let filterTerm = '[data-riskmatrix-risk="%-%"]'.tr(_p.risk, _p.frequency);

    this.selectedCell = this.dom.cells.filter(filterTerm).parent();
    this.selectedCell.addClass('sama-select');
  }

  Destroy() {
    this.dom.body.remove();
  }
}
