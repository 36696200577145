/**
 * Page class for the department editor page
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */
class pSAMA_DepartmentEditor extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_AD_DepartmentEdit', SAMA.pages.NotInSama);
    this.dropdownDialogs = [];
    this.resolvedUsers = [];
    this.departments = [];

    this.header = new cSAMA_PageHeader('Departments', this.dom.page);
    this.dom.deptEdit = this.dom.page.find('#hir-dept-editor');
    this.resLock = new cSAMA_ResourceLock({
      what: 'DEPTS',
      name: 'SMS Departments',
      pk: 'group',
      fnOpen: () => this.GetData(),
      fnLocked: () => this.PageLoadFailed(),
    });
  }

  EnterPage() {
    this.resLock.BeginLocking();
  }

  GetData() {
    let requests = [];
    requests[0] = new cSAMA_Request('Departments', 'getData');
    requests[1] = new cSAMA_Request('MultiSelect', 'getStaffData');

    cSAMA_Request.Dispatch({
      name: 'GetDepartmentsPage',
      reqs: requests,
      clientErrorCode: 'SAMA FH EU 78 97',
      fnSuccess: (_r) => this.SetUpGui(_r),
    });
  }

  /**
   * Set up GUI and input links
   * @param {object} _serverData Data from Server
   */
  SetUpGui(_serverData) {
    let departmentData = _serverData[0];
    this.staffSource = _serverData[1];

    this.header.ButtonSaveDiscard();

    this.viewDepTabs = new vSAMA_TabbedEditor({
      noDataRider: 'No Departments found',
      noDataBody: "No departments were loaded from the server. That's an error.",
      addButtonLabel: 'New Department...',
      readOnly: false,
    });

    this.viewDepTabs.GetAddButton().on('click.samaevt', () => {
      this.OnNewDepartment();
      this.viewDepTabs.Refresh();
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    });
    this.viewDepTabs.GetDom().appendTo(this.dom.deptEdit);

    for (let d = 0; d < departmentData.length; d++) {
      this.OnNewDepartment(departmentData[d]);
    }
    this.viewDepTabs.Refresh();

    SAMA.Navigator.FinishedLoading(this.header);
  }

  OnNewDepartment(_databaseData) {
    let newDept = new mSAMA_Department(_databaseData);
    let newDepTab = new vSAMA_TabDepartment(newDept, this.staffSource, false);

    this.Observe(newDepTab, 'DepartmentDeleted', (_fdep) => this.OnBeginReassignReports(_fdep));

    this.viewDepTabs.AddTab(newDepTab);
    this.departments.push(newDept);
  }

  OnBeginReassignReports(_fromDepartment) {
    if (_fromDepartment.IsNew()) {
      // Don't need to reassign anything for new departments.
      return;
    }

    cSAMA_Dialog.ShowDropdown({
      title: 'Reassign Department Reports',
      message: `Reports in "${_fromDepartment.sync.dep_name}" that have no responsible person must be reassigned to another department.`,
      hasCancel: false,
      options: this.GetReportMoveOptions(),
      onAccept: (_choice) => this.MoveReports(_fromDepartment, _choice),
    });
  }

  GetReportMoveOptions() {
    let options = [];

    for (let d = 0; d < this.departments.length; d++) {
      let dept = this.departments[d];

      // Don't use deleted departments
      if (!dept.IsDeleted()) {
        options.push({
          value: dept.GetPk(),
          name: dept.sync.dep_name,
        });
      }
    }

    return options;
  }

  MoveReports(_fromDept, _toPk) {
    if (_toPk === 0) {
      throw "DepPk can't be null tho";
    }

    _fromDept.sync.giveReportsTo = _toPk;

    let targetDep = this.viewDepTabs.GetTabFromModelPk(_toPk);
    targetDep.SetAllowDelete(false);
  }

  /**
   * Assigns server side primary keys to new clientside departments
   * @param _newPks
   */
  UpdatePks(_newPks) {
    _newPks = _newPks[0];

    for (let d = 0; d < this.departments.length; d++) {
      let dep = this.departments[d];
      dep.SetModified(false);

      if (!dep.IsNew()) {
        continue;
      }

      let dpPk = dep.GetPk();
      if (_newPks.hasOwnProperty(dpPk)) {
        dep.SetPk(_newPks[dpPk]);
      }
    }

    let tabs = this.viewDepTabs.GetTabs();
    for (let t = 0; t < tabs.length; t++) {
      tabs[t].ResetDeletedStaff();
      tabs[t].SetAllowDelete(true);
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  /**
   * The function which is run when the user clicks the 'Save Changes' button
   * @param {boolean} _afterDialogs True after deleted users have been reassigned.
   */
  SaveHandler(_afterDialogs) {
    if (_afterDialogs !== true) {
      // Check who/if someone was deleted unless we're coming back from doing just that
      let tabs = this.viewDepTabs.GetTabs();
      let deletedUsers = [];
      for (let t = 0; t < tabs.length; t++) {
        let del = tabs[t].GetDeletedStaff();
        deletedUsers = deletedUsers.concat(del);
      }

      if (deletedUsers.length > 0) {
        // If users were deleted we might need to re-assign their duties before continuing.
        this.ShowDeletedUserChoices(deletedUsers);
        return;
      }
    }

    /// Only send modified departments & users
    let readyDepts = [];
    for (let d = 0; d < this.departments.length; d++) {
      let t = this.departments[d].GetTransmissible();
      if (t != null) {
        readyDepts.push(t);
      }
    }

    let readyUsers = this.resolvedUsers.filter((_u) => {
      // Don't need to transmit users who'll keep their assignments.
      return _u.solution !== -1;
    });

    if (readyDepts.length <= 0 && readyUsers.length <= 0) {
      // Nothing to save.
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
      return;
    }

    let request = new cSAMA_Request('Departments', 'setData');
    request.Set('users', readyUsers);
    request.Set('departments', readyDepts);

    cSAMA_Request.Dispatch({
      name: 'UpdateDepartments',
      reqs: request,
      clientErrorCode: 'SAMA 59 C1 23 D7',
      goBackOnError: false,
      fnSuccess: (_r) => this.UpdatePks(_r),
    });
  }

  LeavePage() {
    this.resLock.StopLocking();
    SAMA.Navigator.FinishedLoading();

    if (this.loaded === false) {
      // Page load failed
      return;
    }

    this.viewDepTabs.Destroy();
    this.viewDepTabs = null;

    this.departments = [];
  }

  ShowDeletedUserChoices(_usrTags) {
    this.dropdownDialogs = [];
    this.resolvedUsers = _usrTags;

    for (let u = 0; u < this.resolvedUsers.length; u++) {
      this.resolvedUsers[u].solution = -1;
      let deletedUser = this.resolvedUsers[u];

      // Build the select
      let selObj = [
        {
          value: '-1',
          name: 'Keep assigned to ' + deletedUser.tagName,
        },
      ];

      // Grab all users
      for (let du = 0; du < this.staffSource.tags.length; du++) {
        let departmentUser = this.staffSource.tags[du];
        let department = this.GetDeptFromID(departmentUser.catID);
        let deptName = department == null ? 'Unassigned' : department.sync.dep_name;

        let newDHead = deletedUser.hasOwnProperty('newGuy') && +deletedUser.newGuy.tagID === +departmentUser.id;
        let name = 'Assign to ' + departmentUser.name;

        if (newDHead) {
          name += ', the new head';
        }

        name += ` (${deptName})`;

        selObj.push({
          value: departmentUser.id,
          name: name,
          selected: newDHead, // Prefer the new department head
        });
      }
      // Queue the select
      this.dropdownDialogs[u] = this.GetDropDownDialog(u, _usrTags[u].tagName, selObj);
    }

    cSAMA_Dialog.ShowDropdown(this.dropdownDialogs[0]);
  }

  GetDeptFromID(_catID) {
    if (_catID === 0) {
      return null;
    }

    let dep = this.departments.find((_e) => +_e.GetPk() === +_catID);

    if (dep == null || dep.IsDeleted()) {
      return null;
    }

    return dep;
  }

  GetDropDownDialog(_u, _name, _selObj) {
    return {
      title: 'Re-Assign User duties?',
      message:
        '% was removed from their department.<br>'.tr(_name) +
        'If they are responsible for any corrective actions or reports you can give them to another user.<br>' +
        'What would you like to do?',
      options: _selObj,
      onAccept: (_val) => {
        this.resolvedUsers[_u].solution = +_val;

        if (_u < this.dropdownDialogs.length - 1) {
          // Show next dialog
          cSAMA_Dialog.ShowDropdown(this.dropdownDialogs[_u + 1]);
        } else {
          // Try saving again.
          this.SaveHandler(true);
        }
      },
    };
  }
}
