/**
 *
 */
class ScheduleTable extends AbstractDatabaseTable {
  constructor() {
    super('Schedule', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey integer primary key ,
            data       text
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    const parsed = [];
    for (let row of rows) {
      parsed.push(this.parseJson(row.data));
    }

    return parsed;
  }
}
