/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var ONLINE = true;
var INIT_DONE = false;
var MOBILE = false;

function closePopup() {
  $('#' + this.id).popup('close');
}

function cirroHOME() {
  FDT_ADMIN_UPDATE = false;
  clear_FollowMaps_STATE();
  $.mobile.changePage($('#MainMenu'), {
    reverse: true,
    changeHash: false,
  });
  updateMenuIconBadges();
  if (CORDOVA && !ELECTRON) {
    window.plugins.insomnia.allowSleepAgain();
  } // allow the app to sleep again
  CheckOfflineSendItems();
}

function scheduler() {
  $.mobile.changePage($('#Schedule_Page'), {
    reverse: false,
    changeHash: false,
  });
  if (USER_RIGHTS.SCHfilters != undefined) {
    try {
      if (USER_RIGHTS.SCHfilters != 'null') {
        LOCALSTORAGE.SCHfilters = JSON.parse(USER_RIGHTS.SCHfilters);
      }
    } catch (e) {
      console.log('Could Not Load SCHfilters');
    }
  }

  //If we have a saved scheduler view, set the scheduler state to use that and check it
  if (LOCALSTORAGE['scheduler-view'] != undefined) {
    SCHSTATE.view = LOCALSTORAGE['scheduler-view'];
    $('#SCH_VIEW_' + LOCALSTORAGE['scheduler-view']).prop('checked', true);
  }

  //If we have resource mode saved, set it in the SCHsTATE and
  if (LOCALSTORAGE['scheduler-view-resource'] != undefined) {
    var showResourceView = LOCALSTORAGE['scheduler-view-resource'] == 'true';
    SCHSTATE.resource = showResourceView;
    $('#SCH_VIEW_Resource').prop('checked', showResourceView);
  }

  SCH_PopBaseFilter(LOCALSTORAGE.BaseFilter);
  SCH_PopulateSCH_Aircraft();
  Populate_GROUP_AC_TYPE_DATA();
  SCH_PopulateCategories();
  createScheduler(SCHSTATE.view);
}

document.addEventListener('deviceready', onDeviceReady, false);
window.addEventListener('cirro-storage-ready', () => {
  // For storage to be ready, deviceready has fired and done some work in revamp
  APP_READY_STATE++;
  PHONEGAP_READY();
});

$(document).ready(function () {
  APP_READY_STATE++;

  $.mobile.changePage.defaults.changeHash = false;
  $.mobile.hashListeningEnabled = false;
  $.mobile.ignoreContentEnabled = true;
  $('#ITIN_ResponsiblePersonSearch').autocomplete({
    select: ItinResponsiblePersonSelect,
    source: searchItinResponsiblePerson,
  });
  $('#ITIN_JOB_SEARCH').autocomplete({
    select: ItinJobNameSelect,
    source: searchItinJobName,
  });
  $('#ERG_SEARCH').autocomplete({
    select: ERGAutoCompleteSelect,
    source: ERGAutoComplete,
  });

  $('#SCH_SearchClientJob').autocomplete({
    select: SCHsearchClientJobsSELECT,
    source: SCHsearchClientJobs,
  });
  $('#BIFR_SearchClientJob').autocomplete({
    select: BIFRsearchClientJobsSELECT,
    source: BIFRsearchClientJobs,
  });

  $('#BMQ_SearchClientJob').autocomplete({
    source: function (request, response) {
      BMQsearchClientJobs(request, function (options) {
        response(
          $.map(options, function (item) {
            return {
              label: item.label,
              value: item.value,
              'data-testid': item['data-testid'], // Pass data-testid here
            };
          })
        );
      });
    },
    select: BMQsearchClientJobsSELECT,
    create: function () {
      // Override the default rendering of the autocomplete menu items
      $(this).data('ui-autocomplete')._renderItem = function (ul, item) {
        // Create the list item with the data-testid attribute
        return $('<li>')
          .attr('data-testid', item['data-testid']) // Add the data-testid here
          .append($('<div>').text(item.label))
          .appendTo(ul);
      };
    },
  });

  $('#BMQL_FromICAO').autocomplete({
    select: BILLING_FromICAO_select,
    source: BILLING_FromSearchAirportsICAO,
  });

  $('#BMQL_FromICAO_Name').autocomplete({
    select: BILLING_FromICAO_select,
    source: BILLING_SearchAirportsNameCity,
  });

  $('#BMQL_ToICAO').autocomplete({
    select: BILLING_ToICAO_select,
    source: BILLING_ToSearchAirportsICAO,
  });

  $('#BMQL_ToICAO_Name').autocomplete({
    select: BILLING_ToICAO_select,
    source: BILLING_SearchAirportsNameCity,
  });

  $('#BMQ_EntryTimezone_TZSearch').autocomplete({
    select: TimezoneInputSearchSelect,
    source: TimezoneInputSearch,
  });

  // check for enter button press on login form
  $('#loginBTN').on('click', function (e) {
    e.preventDefault();
    Login();
  });

  // check for enter button press on login form
  $('#loginForm').on('keydown', function (e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      Login();
    }
  });

  $.support.cors = true;
  $.ajaxSetup({ timeout: 120000 });
  $.mobile.allowCrossDomainPages = true;
  $.mobile.defaultPageTransition = 'none';
  $('#ExpensePicBTN').hide();

  VueBridge.Initialize();

  if (CORDOVA) {
    return;
  }

  // Only continues for web version (ELECTRON is also a CORDOVA build)
  document.dispatchEvent(new CustomEvent('deviceready'));

  PHONEGAP_READY();

  window.onmousemove = function (e) {
    if (!e) {
      return;
    }
    SCHSTATE.ctrl = e.ctrlKey;
  };

  updateVersionString();
});

function onDeviceReady() {
  ELECTRON = window.hasOwnProperty('cordova') && cordova.platformId === 'electron';
  ANDROID = window.hasOwnProperty('cordova') && cordova.platformId === 'android';
  IOS = window.hasOwnProperty('cordova') && cordova.platformId === 'ios';

  if (CORDOVA) {
    NATIVEFILES = getNativeFilesDir();
    getCNcss();

    cordova.plugins.backgroundMode.on('activate', function () {
      cordova.plugins.backgroundMode.disableWebViewOptimizations();
    });

    handleExternalURLs();

    if (ANDROID) {
      document.addEventListener('backbutton', onBackKeyDown, false); //Listen to the User clicking on the back button
    }

    document.addEventListener('resume', appReturnedFromBackground, false);
    document.addEventListener('pause', appToBackground, false);
  }

  // Mock device.platform property if not available
  if (!window.device) {
    window.device = { platform: 'Browser' };
  }

  checkNotificationPerms();
  showFailedFDTSyncToast();
}

function updateSplash() {
  $('#initDialog').text('Securing Connection...');
  $('.cirro-version').text(`Cirro ${VERSION_NAME}`);

  localStorageDB.getAll(function (e) {
    LOCALSTORAGE = e;
    GOTLOCALSTORAGE = true;
    if (LOCALSTORAGE.INITIAL_SYNC != null) {
      if (LOCALSTORAGE.INITIAL_SYNC > 0) {
        INITIAL_SYNC = true;
      }
    }

    if (LOCALSTORAGE.username != null && LOCALSTORAGE.username !== '') {
      $('#clearUserData').show();
    }

    if (LOCALSTORAGE.authToken) {
      // Migrate the token to vuex
      window.vueApp.authTools.authSetToken(LOCALSTORAGE.authToken);

      // Remove old storage keys
      localStorageDB.clearItem('authToken');
      LOCALSTORAGE.authToken = null;
    }

    init();
  });
}

function init() {
  if (INIT_DONE) {
    return;
  }

  INIT_DONE = true;
  if (LOCALSTORAGE.TERMS != 1) {
    goToPage('#Terms_Page');
    return;
  }

  if (AutoCheck == false) {
    //trigger autologin at least once so user work offline if necessary
    AutoCheck = true;
    AutoLogin(); //attempt autologin to confirm credentials
    return;
  }

  if (To_Login == '1') {
    //delete FDT Databases for good measure...
    cirroDB.clearAll('FDT', null);
    cirroDB.clearAll('FDT_DaysV4', null);
    cirroDB.clearAll('FDT_RecsV3', null);

    verbose.log('DEBUG', 'Switching to Login Page');
    VueBridge.enterVue('/login');
  }
}

function hrefHandler() {
  switch (this.id) {
    case 'LogoutBTNToast':
    case 'LogoutBTN':
      var html = '';
      var Items = 0;
      var I = OfflineItemStatus;
      Items += I.Itinerary;
      Items += I.ItineraryLegData;
      Items += I.FlightReports;
      Items += I.Expenses;
      if (Items > 0) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'WARNING',
          headerClose: false,
          buttonPrompt:
            '<center><b>You still have offline data that needs to be sent to the server.  If you logout these items will be gone forever.</b><br />If you would like to completely remove all Cirro data from the device click <a href="#" onClick="Logout(\'RemoveProfile\');">here.</a> </center>',
          buttons: {
            'Logout Anyways': {
              click: function () {
                Logout('KeepGroupData'); //menu-Authentication.js
              },
            },
            'Cancel and Sync Now': {
              click: function () {
                SendOfflineItemsNow();
              },
            },
          },
        });
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'WARNING',
          headerClose: false,
          buttonPrompt:
            '<center>Logout - Company data will be kept on device for next login<br /><br /> --- THIS WILL DISABLE --- <br />--- OFFLINE MODE ---</p>If you would like to completely remove all Cirro data from the device click <a href="#" onClick="Logout(\'RemoveProfile\');">here.</a> <h3>Are you Sure?</h3></center>',
          buttons: {
            Logout: {
              click: function () {
                Logout('KeepGroupData'); //menu-Authentication.js
              },
            },
            Cancel: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }

      break;
    case 'exitFilePage':
      returnDatePicker.destroy(); //remove datepicker handlers
      $('#exitWBSummaryPage').unbind('click', hrefHandler);
      $('#showWBSummaryPage').unbind('click', hrefHandler);
      $.mobile.changePage($('#Itinerary_Page'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitWBSummaryPage':
      $.mobile.changePage($('#ITIN-REVIEWBALANCE'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'showWBSummaryPage':
      $.mobile.changePage($('#DESCRIPTION'), {
        reverse: false,
        changeHash: false,
      });
      break;
    case 'exitAccountPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitFollowPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitPlanningpage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitWBpage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitItineraryPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitDutyPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitDocumentsPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitAdministrationPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitDocAdminPage':
      $.mobile.changePage($('#Administration_Page'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitUserAdminPage':
      $.mobile.changePage($('#Administration_Page'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitDutyDefaults':
      $.mobile.changePage($('#DutyTimes'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitDutyTimes':
      $('#calendar').hide(); //empty calendar div
      $('#calendar').html('');
      $('#calendar').removeClass();
      updateMenuIconBadges();
      if (FDT_ADMIN_UPDATE) {
        cirroDB.clearAll('FDT_DaysV4', function (e) {
          cirroDB.clearAll('FDT_RecsV3', function (e) {
            cirroDB.clearAll('FDT_StaffV3', function (e) {
              console.log('ALL LOCAL FDT RECORDS DELETED RESTORING USER RECS');
              USERTYPE = USER_RIGHTS.staffType;
              FDT_ADMIN_UPDATE = false;
              FDT_OPK = 0;
              localStorageDB.setItem('AdminFDT_Edit', FDT_OPK, function (e) {
                LOCALSTORAGE.AdminFDT_Edit = FDT_OPK;
                LOCALSTORAGE.INIT_Login = 1;
                SYNCING = true;
                VueBridge.LegacyAppSyncing(SYNCING);
                FDT_SYNCING = true;
                syncFDT();
                Admin_Update_FDT();
              });
            });
          });
        });
      } else {
        $.mobile.changePage($('#MainMenu'), {
          reverse: true,
          changeHash: false,
        });
        BackgroundSync(true);
      }
      break;
    case 'DutyDefaultsPage':
      $.mobile.changePage($('#DutyDefaults'), {
        reverse: false,
        changeHash: false,
      });
      $('#CHK_IFR').checkboxradio('refresh', true);
      $('#CHK_Heli').checkboxradio('refresh', true);
      $('#CHK_DHC').checkboxradio('refresh', true);
      $('#CHK_Dual').checkboxradio('refresh', true);
      $('#CHK_Logging').checkboxradio('refresh', true);
      $('#CHK_AerialApp').checkboxradio('refresh', true);
      $('#SELECT_Rule').selectmenu('refresh', true);
      $('#SELECT_Rule_ID').selectmenu('refresh', true);
      if (Rule == 702) {
        $('#CHK_AerialApp').checkboxradio('enable');
      } else {
        $('#CHK_AerialApp').checkboxradio('disable');
      }
      if (DHC == 1) {
        $('#CHK_Heli').checkboxradio('disable');
      } else {
        $('#CHK_Heli').checkboxradio('enable');
      }
      if (Heli == 1) {
        $('#CHK_DHC').checkboxradio('disable');
      } else {
        $('#CHK_DHC').checkboxradio('enable');
      }
      break;
    case 'BTN_StopSync':
      PBAR_Close = true; //to prevent further updates to progress bar that will prevent proper closing
      SyncWorker.postMessage({
        Message: 'STOP',
      }); //menu-sync.js
      $('#Duty_Status').html('Offline Since: ' + LastFDTsync);
      $('#StopSync').hide(); //empty div holding work offline button
      PBAR.destroy(); //menu-Duty.js
      $('#ProgressBar').html('');
      initDutyTimes(); //menu-Duty.js
      break;
    //Aircraft Maintenance and Weight and Balance
    case 'exitMaintenance':
      //popup warning dialog of reason for refresh app
      if (ACRebuild == true || ACRefresh == true) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'App Restart',
          headerClose: false,
          buttonPrompt: '<p>Restarting application to sync aircraft configuration changes</p>',
          buttons: {
            Restart: {
              click: function () {
                if (SYNCING == false) {
                  splashScreen(true);
                  localStorageDB.setItem('ManualSync', 1, function (e) {
                    restartCirro();
                  });
                }
              },
            },
          },
        });
      } else {
        $.mobile.changePage($('#Administration_Page'), {
          reverse: true,
          changeHash: false,
        });
      }
      break;
    case 'exitWBEdit':
      const page = VueBridge.canAccessAdminToolMenu() ? '#AC_Edit_Page' : '#WB_MaintenancePage';
      $.mobile.changePage($(page), {
        reverse: true,
        changeHash: false,
      });

      break;
    case 'exitACPage':
      //popup warning dialog of reason for refresh app
      if (ACRebuild == false && ACRefresh == false) {
        if (VueBridge.canAccessAdminToolMenu()) {
          VueBridge.enterAdminToolMenu();
        } else {
          $.mobile.changePage($('#WB_MaintenancePage'), {
            reverse: true,
            changeHash: false,
          });
        }
        break;
      }

      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('App Restart'),
        headerClose: false,
        buttonPrompt:
          '<p v-t="status_LANG.Restarting_application_to_sync_aircraft_configuration_changes">Restarting application to sync aircraft configuration changes</p>',
        buttons: {
          Restart: {
            click: function () {
              if (SYNCING == false) {
                splashScreen(true);
                localStorageDB.setItem('ManualSync', 1, function (e) {
                  restartCirro();
                });
              }
            },
          },
        },
      });
      break;
    case 'exitELPage':
      $.mobile.changePage($('#WB_MaintenancePage'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitACEdit':
      if (AC_REBUILD == true && LITE == 0) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Rebuild Required',
          headerClose: false,
          buttonPrompt:
            'You have made changes to this aircraft that requires a rebuild.  You can rebuild the aircraft now to distribute the changes or if you still have further changes you can rebuild later.',
          buttons: {
            'Rebuild Now': {
              click: function () {
                $.mobile.changePage($('#WB_Edit_Page'), {
                  changeHash: false,
                });

                $('#tcWBMENU').prop('checked', true).checkboxradio('refresh');
                $('#ammendWBMENU').prop('checked', false).checkboxradio('refresh');
                $('#installWBMENU').prop('checked', false).checkboxradio('refresh');
                $('#ammendWBMENU').prop('disabled', true).checkboxradio('refresh');
                $('#installWBMENU').prop('disabled', true).checkboxradio('refresh');
                $('#tcWBMENU').prop('disabled', true).checkboxradio('refresh');
                RuneditTC = true;
                editAEL();
                AC_REBUILD = false;
              },
            },
            'Exit anyway': {
              click: function () {
                $.mobile.changePage($('#AC_Page'), {
                  reverse: true,
                  changeHash: false,
                });
                aclist();
              },
            },
          },
        });
      } else {
        $.mobile.changePage($('#AC_Page'), {
          reverse: true,
          changeHash: false,
        });
        aclist();
      }
      //$.mobile.changePage( $("#WB_MaintenancePage"), {  reverse: true, changeHash: false });
      break;
    case 'editELPage':
      $.mobile.changePage($('#EL_EDIT_Page'), {
        changeHash: false,
      });
      editChecked();
      break;
    case 'exitEL_EDIT_Page':
      equiplist();
      break;
    case 'exitItinEditPage':
      $.mobile.changePage($('#Following_Page'), {
        reverse: true,
        changeHash: false,
      });
      getFollowData();
      if (EDITMAP != null) {
        EDITMAP.remove();
        EDITMAP = null;
      }
      break;
    case 'ExitSpotPage':
      VueBridge.enterCorrectAdminToolMenu();
      break;
    case 'ExitFCadminPage':
      VueBridge.enterCorrectAdminToolMenu();
      break;

    //TDG Menu stuff
    case 'exitTDG_AW':
      $.mobile.changePage($('#TDG_Home'), {
        reverse: true,
        changeHash: false,
      });

      break;
    case 'exitTDG_AT':
      $.mobile.changePage($('#TDG_Home'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitTDG_ADD':
      //redirect back to previous Aerial work / Air Taxi page
      $.mobile.changePage($('#TDG_AT'), {
        reverse: true,
        changeHash: false,
      });

      break;
    case 'exitUNDisplay':
      $.mobile.changePage($('#TDG_ADD'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitERGDisplay':
      $.mobile.changePage($('#TDG_ADD'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitTDGAdminPage':
      $.mobile.changePage($('#Administration_Page'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitAssetPage':
      $.mobile.changePage($('#MainMenu'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'exitnewAssetPage':
      $.mobile.changePage($('#AssetPage'), {
        reverse: true,
        changeHash: false,
      });
      break;
    case 'addnewAssetPage':
      $.mobile.changePage($('#newAssetPage'), {
        changeHash: false,
      });
      break;
    case 'exitReportsPagebtn':
      VueBridge.enterCorrectAdminToolMenu();
      break;
    case 'exitReportPage':
      if (VueBridge.canAccessAdminToolMenu()) {
        VueBridge.enterAdminToolMenu();
      } else {
        $('#RPT_PageControl').html('');
        $.mobile.changePage($('#Reports_Page'), {
          reverse: true,
          changeHash: false,
        });
      }
      break;
    case 'ExitWPadminPage':
      VueBridge.enterCorrectAdminToolMenu();
      break;
  }
}

function exitAdminTemplates() {
  VueBridge.enterCorrectAdminToolMenu();
}

/**
 * Load the revamp fdt module using default init logic, or a custom callback
 *
 * @param {function} fdtLoadCallback Overrides the default fdt loading logic
 */
function loadFdtModule(fdtLoadCallback = null) {
  if (fdtLoadCallback != null && typeof fdtLoadCallback === 'function') {
    fdtLoadCallback();
    return;
  }

  // If a custom function to load the fdt module is not provided, use the default
  FDT_MODULE_VERSION = 4;
  initDutyTimesV4();
}

/**
 * Shows the dialog for selecting the fdt beta if the user has the option or falls back to the old implementation
 *
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param {function} p.onSelectOld Overrides the old/default actions
 * @param {function} p.onSelectBeta Overrides the beta actions
 */
function fdt_betaSelect(p = {}) {
  VueBridge.deprecationUtils.markDeprecated('menu.fdt_betaSelect', '2024-06-26', 'bldarosa');
  let clickOld = p.onSelectOld;
  if (clickOld == null)
    clickOld = () => {
      FDT_MODULE_VERSION = 3;
      if (FDT_REPORT !== false) {
        import_FR_FDTV2();
      } else {
        goToPage('#DutyTimes');
        initDutyTimes(); //menu-Duty.js
      }
    };

  let clickBeta = p.onSelectBeta;
  if (clickBeta == null) {
    clickBeta = () => {
      // Check the extend checkbox for user
      FDT_MODULE_VERSION = 4;
      initDutyTimesV4();
    };
  }

  if (USERTYPE === 'Pilot' || USERTYPE === 'NASP SO' || USERTYPE === 'Rescue Specialist') {
    if (VueBridge.hasFeature('fdtRevampForce')) {
      FDT_MODULE_VERSION = 4;
      clickBeta();
      return;
    }

    switch (VueBridge.getPreference('features.useFdtRevamp')) {
      case 'always':
        clickBeta();
        return;
      case 'never':
        clickOld();
        return;
    }
  }

  // If the user isn't a pilot,
  // or we're not online
  // or we have neither debug mode nor the feature flag,
  // we fall back to the old FDT version without asking
  if (
    (USERTYPE !== 'Pilot' && USERTYPE !== 'NASP SO' && USERTYPE !== 'Rescue Specialist') ||
    !VueBridge.hasFeature('fdtRevamp')
  ) {
    clickOld();
    return;
  }

  // Pop the question. Will u marry beta fdt and keep him in good times and bad?

  const yesFunc = (dontAskAgain) => {
    handleAskAgain(dontAskAgain, 'never');
    clickOld();
  };

  const noFunc = (dontAskAgain) => {
    handleAskAgain(dontAskAgain, 'always');
    clickBeta();
  };

  cSAMA_Dialog.ShowConfirmWithRememberOption({
    title: iTrans('FDT Beta'),
    message: iTrans('Would you like to use the Legacy FDT module or test the New BETA FDT module') + '?',
    yesLabel: iTrans('Old FDT Module'),
    yesFunc,
    noLabel: iTrans('New BETA FDT Module'),
    noFunc,
    canCheck: true,
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param dontAskAgain
 * @param value
 */
function handleAskAgain(dontAskAgain, value) {
  VueBridge.deprecationUtils.markDeprecated('menu.handleAskAgain', '2024-06-26', 'bldarosa');
  console.log({ dontAskAgain });

  if (dontAskAgain) {
    RemoteData.patch(`api/v2/groups/${GROUP_DATA['group']}/users/${USER_PK}/preferences/features.useFdtRevamp`, {
      setting_value: value,
    }).then(() => VueBridge.setPreference('features.useFdtRevamp', value));
  }
}

async function clickHandler(e) {
  //Handle menu clicks
  switch (e) {
    case 'ManualSync':
      //Wipe the sync time, but give vuex a second to do it's job
      //Switch to the splash screen so users don't think the app is frozen
      splashScreen(true);
      await window.vueApp.app.$store.commit('efb/WIPE_SYNC_TIME');

      setTimeout(() => {
        localStorageDB.setItem('ManualSync', 1, function (e) {
          RemoteData.get('api/v2/users/me/invalidate')
            .catch(() => {
              console.log('Failed to invalidate Session.');
              restartCirro();
            })
            .then(() => {
              console.log('Session invalidated.');
              restartCirro();
            });
        });
      }, 500);
      break;
    case 'manageWPTS':
      $.mobile.changePage($('#WPadmin_Page'), {
        changeHash: false,
      });
      $('#ExitWPadminPage').unbind('click', hrefHandler);
      $('#ExitWPadminPage').click(hrefHandler);
      admingetWPlist();
      break;
    case 'adminFlightTemplates':
      $.mobile.changePage($('#adminFlightTemplates'), {
        changeHash: false,
      });
      popFlightTemplates();
      break;
    case 'customFlightReports':
      window.location = '/admin/custom_fields';
      break;
    case 'Reports':
      getPilotList();
      $.mobile.changePage($('#Reports_Page'), {
        changeHash: false,
      });
      $('#exitReportsPagebtn').unbind('click', hrefHandler);
      $('#exitReportsPagebtn').click(hrefHandler);
      $('#exitReportPage').unbind('click', hrefHandler);
      $('#exitReportPage').click(hrefHandler);
      PopulateReportAircraftTypes();
      break;
    case 'assets':
      $.mobile.changePage($('#AssetPage'), {
        changeHash: false,
      });
      $('#exitAssetPage').unbind('click', hrefHandler);
      $('#exitAssetPage').click(hrefHandler);
      $('#addnewAssetPage').unbind('click', newAsset);
      $('#addnewAssetPage').click(newAsset);
      $('#exitnewAssetPage').unbind('click', hrefHandler);
      $('#exitnewAssetPage').click(hrefHandler);
      init_Assets();
      break;
    case 'TDGAdmin':
      if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
        VueBridge.enterVue(
          `/groups/${window.vueApp.groupKey}/dangerous-goods/settings?from-legacy=Administration_Page`
        );
        break;
      }
      VueBridge.enterVue(
        `/groups/${window.vueApp.groupKey}/dangerous-goods/consignors?from-legacy=Administration_Page`
      );
      break;
    case 'Fuel':
      $.mobile.changePage($('#FCadmin_Page'), {
        changeHash: false,
      });
      $('#ExitFCadminPage').unbind('click', hrefHandler);
      $('#ExitFCadminPage').click(hrefHandler);
      $('#NewFC').unbind('click', NewFC);
      $('#NewFC').click(NewFC);
      getFClist();
      break;
    case 'SpotDevices':
      $.mobile.changePage($('#Spot_Page'), {
        changeHash: false,
      });
      $('#ExitSpotPage').unbind('click', hrefHandler);
      $('#ExitSpotPage').click(hrefHandler);
      $('#NewSpot').unbind('click', NewSpot);
      $('#NewSpot').click(NewSpot);
      getSpots();
      break;
    case 'Following':
      getFCdata(true);
      $.mobile.changePage($('#Following_Page'), {
        changeHash: false,
      });
      $('#FollowOpen').unbind('click', showFollowOpen);
      $('#FollowOpen').click(showFollowOpen);
      $('#FollowAirborne').unbind('click', showFollowAirborne);
      $('#FollowAirborne').click(showFollowAirborne);
      $('#FollowStatus').unbind('click', showFollowStatus);
      $('#FollowStatus').click(showFollowStatus);
      $('#exitItinEditPage').unbind('click', hrefHandler);
      $('#exitItinEditPage').click(hrefHandler);
      SYNC_FOLLOW = false;
      getFollowData();
      break;
    case 'flightreports':
      window.location = '/itineraries';
      break;
    case 'Planning':
      $.mobile.changePage($('#Planning_Page'), {
        changeHash: false,
      });
      if (!LOW_BANDWIDTH) syncMbtiles(false);
      else {
        popOfflineDataStatus();
      }
      break;
    case 'Billing':
      window.location = '/admin/billing';
      break;
    case 'Administration':
      $.mobile.changePage($('#Administration_Page'), {
        changeHash: false,
      });
      break;
    case 'WB':
      getFCdata(true);
      $.mobile.changePage($('#WB_Page'), {
        changeHash: false,
      });
      WB_Init();
      break;
    case 'Itinerary':
      if (ITIN_InProgress) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'WARNING',
          headerClose: false,
          buttonPrompt:
            'There is an in-progress itinerary detected.  Would you like to start a new Itinerary or continue your existing one?',
          buttons: {
            'New Itin': {
              click: function () {
                resetItineraryData();
                clickHandler('Itinerary');
              },
            },
            'Continue Previous': {
              click: function () {
                checkReqCerts();
                $.mobile.changePage($('#' + itinNOW), {
                  reverse: false,
                  changeHash: false,
                });
              },
            },
          },
        });
      } else {
        $.mobile.changePage($('#Itinerary_Page'), {
          changeHash: false,
        });
        resetItineraryData();
        ITIN_InProgress = true;
        itin_UpdateHeadingIdent(iTrans('Select Aircraft'));
        fileaclist(); //menu-Itinerary.js
        checkReqCerts();
      }
      break;
    case 'NewJob':
      window.location = railsLink('/admin/jobs/new', {
        DIRECT_FROM_LEGACY: 'true',
      });
      break;
    case 'myexpenses':
      window.location = '/expenses';
      break;
    case 'dutytimes':
      if (FDT_SYNCING || RULE_SYNCING) {
        alert('Synchronization in progress. Please try again once it has completed.');
        return;
      }

      if (USERTYPE !== 'Pilot' && USERTYPE !== 'NASP SO' && USERTYPE !== 'Rescue Specialist') {
        goToPage('#DutyTimes');
        initDutyTimes(); //menu-Duty.js
        return;
      }

      loadFdtModule();

      break;
    case 'documents':
      VueBridge.enterLibrary();
      break;
    case 'Aircraft':
      $.mobile.changePage($('#WB_MaintenancePage'), {
        changeHash: false,
      });
      $.ajaxSetup({
        cache: false,
      });
      $('#installWBMENU').unbind('click', WBMENU).click(WBMENU);
      $('#ammendWBMENU').unbind('click', WBMENU).click(WBMENU);
      $('#tcWBMENU').unbind('click', WBMENU).click(WBMENU);
      $('#exitWBEdit').unbind('click', hrefHandler).click(hrefHandler);
      $('#exitACEdit').unbind('click', hrefHandler).click(hrefHandler);
      $('#exitACPage').unbind('click', hrefHandler).click(hrefHandler);
      $('#exitELPage').unbind('click', hrefHandler).click(hrefHandler);
      $('#editELPage').unbind('click', hrefHandler).click(hrefHandler);
      $('#exitEL_EDIT_Page').unbind('click', hrefHandler).click(hrefHandler);
      $('#editWB').unbind('click', editAC2).click(editAC2);
      $('#printTC').unbind('click', printWB).click(printWB);
      $('#newACPage').unbind('click', addAC).click(addAC);
      $('#exitMaintenance').unbind('click', hrefHandler).click(hrefHandler);
      break;
    //Map ********************
    case 'Map':
      $.mobile.changePage($('#Map_Page'), {
        changeHash: false,
      });
      INIT_MAPB();
      break;
    case 'close':
      $('#popupPanel').popup('close');
      break;
    case 'closelayersPanel':
      $('#layersPanel').popup('close');
      break;
    case 'closeacInfo':
      $('#acInfo').popup('close');
      break;
    case 'updatefcInfo':
      $('#fcInfo').popup('close');
      updateFuelCache('qty');
      break;
    case 'closefireInfo':
      $('#fireInfo').popup('close');
      break;
    case 'closeairportInfo':
      $('#airportInfo').popup('close');
      break;
    //Aircraft maintenance and weight and balance
    case 'ACL':
      $.mobile.changePage($('#AC_Page'), {
        reverse: false,
        changeHash: false,
      });
      if (VueBridge.canAccessAdminToolMenu()) {
        $.ajaxSetup({
          cache: false,
        });
        $('#installWBMENU').unbind('click', WBMENU).click(WBMENU);
        $('#ammendWBMENU').unbind('click', WBMENU).click(WBMENU);
        $('#tcWBMENU').unbind('click', WBMENU).click(WBMENU);
        $('#exitWBEdit').unbind('click', hrefHandler).click(hrefHandler);
        $('#exitACEdit').unbind('click', hrefHandler).click(hrefHandler);
        $('#exitACPage').unbind('click', hrefHandler).click(hrefHandler);
        $('#exitELPage').unbind('click', hrefHandler).click(hrefHandler);
        $('#editELPage').unbind('click', hrefHandler).click(hrefHandler);
        $('#exitEL_EDIT_Page').unbind('click', hrefHandler).click(hrefHandler);
        $('#editWB').unbind('click', editAC2).click(editAC2);
        $('#printTC').unbind('click', printWB).click(printWB);
        $('#newACPage').unbind('click', addAC).click(addAC);
        $('#exitMaintenance').unbind('click', hrefHandler).click(hrefHandler);
      }
      aclist();
      break;
    case 'EL':
      equiplist();
      break;
    case 'exitPrint':
      Printview = false;
      $('body').css('overflow', '');
      $('#Print_Page').hide();
      $('#WBMENU').prop('checked', false);
      $('#PRINT').html('');
      $('#PrintA').html('');
      $('#PrintTC').html('');
      $('#CHARTS').html('');
      $('#tcWBMENU').prop('checked', true).checkboxradio('refresh');
      $('#ammendWBMENU').prop('checked', false).checkboxradio('refresh');
      $('#installWBMENU').prop('checked', false).checkboxradio('refresh');
      $('#WB_Aircraft').enhanceWithin();
      $.mobile.changePage($('#WB_Edit_Page'), {
        reverse: true,
        changeHash: false,
      });
      break;

    case 'AirTaxi':
      tdgType = 'Air Taxi';
      //populateTemplates();
      var AW = $('#CHK_AW').is(':checked');
      if (AW) {
        tdgType = 'Aerial Work';
      }
      if ($('#CHK_CC').is(':checked')) {
        $('#CCcheck2BTN').show();
      } else {
        $('#CCcheck2BTN').hide();
      }
      $.mobile.changePage($('#TDG_AT'), {
        changeHash: false,
      });
      $('#AT_Template').selectmenu('refresh');
      break;
    case 'AddTDG':
      $('#search-UN').unbind('keyup', searchListing);
      $('#search-UN').keyup(searchListing);
      //$("#search-Description").unbind("keyup",searchListing);
      //$("#search-Description").keyup(searchListing);
      //clickHandler("searchListing");
      $.mobile.changePage($('#TDG_ADD'), {
        changeHash: false,
      });
      break;
    case 'searchListingKeyword':
      //validate input data prior to sending request
      searchListingKeyword();
      break;
    case 'CCcheck':
      checkList(0);
      break;
    case 'PlanTDG':
      if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
        VueBridge.enterVue(`groups/${GROUP_DATA.group}/dangerous-goods`);
        break;
      }

      $.mobile.changePage($('#TDG_Home'), {
        changeHash: false,
      });
      init_TDG('Planning');
      break;
  }
}

function toggleHiddenModules() {
  $('#hiddenModules').toggle();
}

function enterAdminToolMenu() {
  if (VueBridge.hasFeature('adminMenuRevamp')) {
    VueBridge.enterAdminToolMenu();
  } else {
    clickHandler('Administration');
  }
}
