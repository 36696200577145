/**
 * Base class for Real-Time, clientside filtering of AbstractFilterable objects
 * @author falko@air-suite.com
 * @copyright (c) 2018 AirSuite, All Rights Reserved.
 */
class cSAMA_AbstractFilter {
  /**
   * @param {string}                       _p.filterTemplate
   * @param {*}                            _p.orderTerms
   * @param {*}                            _p.showTerms
   * @param {vSAMA_AbstractFilterableView} _p.view
   * @param {boolean}                      [_p.frameless]
   */
  constructor(_p) {
    this.filterInputs = [];
    this.orderTerms = _p.orderTerms;
    this.showTerms = _p.showTerms;
    this.filteredView = _p.view;
    _p.frameless = _p.frameless == null ? false : _p.frameless;

    this.dom = {
      wrapper: $('<div />'),
      filters: $(_p.filterTemplate),
    };

    if (!_p.frameless) {
      this.dom.wrapper.attr('data-collapsed', 'true').attr('data-role', 'collapsible');

      $('<h4/>').text('Filters').appendTo(this.dom.wrapper);
    }

    this.dom.filters.appendTo(this.dom.wrapper);

    this.InstallEventHandlers();
    this.dom.resetButton = this.dom.wrapper.find('button.sama-btn-delete');
    this.dom.resetButton.on('click', () => this.ResetFilters());
  }

  /**
   * Sets up event handling for the filter inputs
   */
  InstallEventHandlers() {
    let filterInputs = this.dom.filters.find('[name]').toArray();
    for (let f = 0; f < filterInputs.length; f++) {
      let filterInput = $(filterInputs[f]);

      if (filterInput.hasClass('sama-ignore')) {
        return;
      }

      if (filterInput.attr('type') === 'text' || filterInput.attr('type') === 'date') {
        filterInput.on('input', () => this.RunFilters());
      } else {
        filterInput.on('change', () => this.RunFilters());
      }

      this.filterInputs.push(filterInput);
    }
  }

  RunFilters() {
    let iVals = this.NormalizeInputs();
    this.GenerateTerms(iVals);
    this.ApplyFilters();
  }

  /**
   * Translates the all-over-the-place data from the inputs into something usable
   */
  NormalizeInputs() {
    let inputValues = {};

    for (let i = 0; i < this.filterInputs.length; i++) {
      let currentInput = this.filterInputs[i];

      let inputVal = currentInput.val();
      let inputName = currentInput.attr('name');
      let inputType = currentInput.attr('type');
      let isEmulatedDateInput = currentInput.attr('emulate-type') === 'date';
      let inputTag = currentInput.prop('tagName');
      let inputChecked = currentInput.prop('checked');

      if (inputType != null) {
        inputType = inputType.toLowerCase();
      }

      if (inputTag != null) {
        inputTag = inputTag.toLowerCase();
      }

      if (inputType === 'text' && !isEmulatedDateInput) {
        inputValues[inputName] = inputVal;
      } else if ((inputType === 'date' || isEmulatedDateInput) && inputVal.length > 0) {
        inputValues[inputName] = inputVal;
      } else if (inputTag === 'select' && currentInput.hasClass('sama-asString')) {
        inputValues[inputName] = inputVal;
      } else if (inputTag === 'select' || inputType === 'range' || inputType === 'number') {
        inputValues[inputName] = +inputVal;
      } else if (inputType === 'radio') {
        if (inputChecked) {
          inputValues[inputName] = +inputVal;
        }
      } else {
        inputValues[inputName] = inputChecked;
      }
    }

    return inputValues;
  }

  /**
   * Generate Filter functions from input values.
   * @abstract
   */
  GenerateTerms(_inputValues) {}

  /**
   * Recomputes the outcomes of all filter functions
   */
  ApplyFilters() {
    let visibleItems = [];
    let items = this.filteredView.GetItems();

    // Fist determine which items will actually be shown
    for (let c = 0; c < items.length; c++) {
      let item = items[c];
      let visible = true;

      for (let _prop in this.showTerms) {
        if (this.showTerms.hasOwnProperty(_prop) && this.showTerms[_prop] != null) {
          let propVal = item.GetProperty(_prop);
          visible = this.showTerms[_prop].Run(propVal);
        }

        if (!visible) {
          break;
        }
      }

      if (!visible) {
        // Finding a single reason to hide the item is good enough
        item.Hide();
        continue;
      }

      // If we can't find a reason not to show the item, show it.
      item.Show();
      visibleItems.push(item);
    }

    // Now lets sort the visible items.
    visibleItems.sort((_itemA, _itemB) => {
      for (let _order in this.orderTerms) {
        if (this.orderTerms.hasOwnProperty(_order) && this.orderTerms[_order] != null) {
          let propA = _itemA.GetProperty(_order);
          let propB = _itemB.GetProperty(_order);
          if (propA === null || propB === null) {
            continue;
          }

          let order = this.orderTerms[_order].Run(propA, propB);
          if (order !== 0) {
            // The first sort was already successful in separating the two values.
            return order;
          }
        }
      }

      return 0;
    });

    this.filteredView.OnSortItems(visibleItems);
  }

  /**
   * Clears the filter's inputs
   */
  ResetFilters() {
    for (let f = 0; f < this.filterInputs.length; f++) {
      let input = this.filterInputs[f];

      if (input.attr('type') === 'text' || input.attr('type') === 'date') {
        input.val('');
      } else if (input.prop('tagName') === 'SELECT') {
        input.val('0').selectmenu('refresh');
      } else if (input.attr('type') === 'radio' && input.hasClass('sama-default')) {
        input.prop('checked', true);
        input.closest('fieldset').controlgroup('refresh');
      } else if (input.attr('type') === 'radio') {
        input.prop('checked', false);
        input.closest('fieldset').controlgroup('refresh');
      } else {
        input.click();
      }
    }

    this.RunFilters();
  }

  GetDom() {
    return this.dom.wrapper;
  }
}
