/**
 * Page class for the trends view
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_TrendMonRuleEdit extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_AD_TrendsEdit', SAMA.pages.MenuAdmin);

    this.rules = [];
    this.departmentSelect = $('<select />');
    this.keywordCache = [];
    this.staffCache = [];

    this.header = new cSAMA_PageHeader('Report Monitoring Rules', this.dom.page);

    this.resLock = new cSAMA_ResourceLock({
      what: 'TRMON',
      name: 'SMS Trends Monitoring',
      pk: 'group',
      fnOpen: () => this.GetData(),
      fnLocked: () => this.PageLoadFailed(),
    });
  }

  EnterPage() {
    this.resLock.BeginLocking();
  }

  GetData() {
    cSAMA_Utils.WaitForCatTaggerLoad();

    this.header.ButtonSaveDiscard();

    let requests = [
      new cSAMA_Request('Trends', 'getData'),
      new cSAMA_Request('Departments', 'getData'),
      new cSAMA_Request('MultiSelect', 'getStaffData'),
      new cSAMA_Request('Keywords', 'getData'),
    ];

    cSAMA_Request.Dispatch({
      name: 'GetTrendsPage',
      reqs: requests,
      clientErrorCode: 'SAMA 24 58 87 98',
      fnSuccess: (_r) => this.SetUpGui(_r),
    });
  }

  SetUpGui(_response) {
    this.GenerateDepartmentsDropdown(_response[1]);

    this.staffCache = _response[2];
    this.keywordCache = _response[3];

    // Load rule editor
    this.LoadRuleEditor(_response[0]);

    SAMA.Navigator.FinishedLoading(this.header);
  }

  GenerateDepartmentsDropdown(_response) {
    this.departmentSelect = $('<select />').attr('data-sref', 'tru_depPk').attr('data-mini', 'true');

    $('<option />').val('-1').text('Any').appendTo(this.departmentSelect);

    $('<option />').val('0').text('Company Wide').appendTo(this.departmentSelect);

    for (let r = 0; r < _response.length; r++) {
      let deptData = _response[r];

      $('<option />').val(deptData.dep_primaryKey).text(deptData.dep_name).appendTo(this.departmentSelect);
    }
  }

  LoadRuleEditor(_rules) {
    this.viewRuleEdit = new vSAMA_TabbedEditor({
      noDataRider: 'No Rules',
      noDataBody: 'No monitoring rules exist.',
      addButtonLabel: 'Create Rule',
      readOnly: false,
    });

    this.viewRuleEdit.GetAddButton().on('click.samaevt', () => this.RuleEditNew());
    this.viewRuleEdit.GetDom().appendTo(this.dom.page.find('#sama_trendRuleBuilder'));

    for (let rc = 0; rc < _rules.length; rc++) {
      let r = new mSAMA_TrendRule(0, _rules[rc]);
      let rEd = new vSAMA_TabTrendRule(r, this.departmentSelect, this.staffCache, this.keywordCache, false);

      this.viewRuleEdit.AddTab(rEd);
      this.rules.push(r);
    }

    this.viewRuleEdit.Refresh();
  }

  RuleEditNew() {
    let r = new mSAMA_TrendRule(this.rules.length + 1);
    let rEd = new vSAMA_TabTrendRule(r, this.departmentSelect, this.staffCache, this.keywordCache, false);

    this.rules.push(r);
    this.viewRuleEdit.AddTab(rEd);
    this.viewRuleEdit.Refresh();
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  SaveHandler() {
    let req = new cSAMA_Request('Trends', 'setData');
    let ruuuuur = [];
    for (let rc = 0; rc < this.rules.length; rc++) {
      let rur = this.rules[rc].GetTransmissible();
      if (rur == null) {
        return;
      }
      ruuuuur.push(rur);
    }

    req.Set('rules', ruuuuur);

    cSAMA_Request.Dispatch({
      name: 'SaveTrendsRules',
      reqs: req,
      goBackOnError: false,
      clientErrorCode: 'SAMA 89 D5 87 3E',
      fnSuccess: (_r) => this.UpdatePks(_r),
    });
  }

  UpdatePks(_results) {
    for (let r = 0; r < this.rules.length; r++) {
      let rule = this.rules[r];
      let pk = rule.GetPk();

      if (_results[0]['rulePks'].hasOwnProperty(pk)) {
        rule.SetPk(_results[0]['rulePks'][pk]);
      }
      rule.SetModified(false);
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  /**
   * Function called when the user leaves the page.
   * @override
   */

  LeavePage() {
    this.resLock.StopLocking();
    SAMA.Navigator.FinishedLoading();

    if (this.loaded === false) {
      return;
    }

    this.rules = [];
    $('body').off('.samaevt');

    this.viewRuleEdit.Destroy();
    this.viewRuleEdit = null;
  }
}
