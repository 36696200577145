function Report_PIS_ListPilots() {
  var html = `<table data-filter="true">
  <thead>
  <tr>
    <th><input type="checkbox" data-role="none" onClick="SELECT_ALL_PIS_PILOTS(this)" checked/></th>
    <th>${iTrans('CD')}</th>
    <th>${iTrans('Pilot Name')}</th>
    <th>${iTrans('Last Active')}</th>
    <th>${iTrans('Last FDT Sync')}</th>
  </tr>
  </thead>
  <tbody>`;

  for (var i in Report_Data) {
    var I = Report_Data[i];
    var CHK = `<input type="checkbox" data-role="none" id="PIS_RPT_CHK-${i}" value="${i}" onClick="SELECT_PIS_PILOT(this)" checked />`;
    if (I.checked === false) {
      CHK = `<input type="checkbox" data-role="none" id="PIS_RPT_CHK-${i}" value="${i}" onClick="SELECT_PIS_PILOT(this)" />`;
    }

    html +=
      `<tr>
      <td>${CHK}</td>
      <td>${I.crew_code == null ? '-' : I.crew_code}</td>
      <td>${I.chatname}</td>
      <td>` +
      (I.LastActive != null ? getLocalDateTime(I.LastActive, null, false).text : 'Never') +
      '</td><td>' +
      (I.LastFDTsync != null ? getLocalDateTime(I.LastFDTsync, null, false).text : 'Never') +
      '</td><tr>';

    I.checked = true;
  }
  html += '</tbody></table>';
  var select = '<label for="PIS_DateRange">' + iTrans('Select Report Period') + '</label>';
  select += '<select id="PIS_DateRange">';
  select += '<option value="24">24 Months</option>';
  select += '<option value="18">18 Months</option>';
  select += '<option value="12" selected>12 Months</option>';
  select += '<option value="6">6 Months</option>';
  select += '<option value="3">3 Months</option>';
  select += '<option value="1">1 Month</option>';
  select += '</select>';

  $('#Report_Content').html(
    '<div class="limit-table"><button data-mini="true" class="greenbg" onClick="Gen_PilotInfo();">' +
      iTrans('Generate Pilot Info Sheets') +
      '</button><h3>' +
      select +
      iTrans('Select Pilots for Report') +
      '</h3>' +
      html +
      '</div>'
  );
  $('#Report_Content').enhanceWithin();
}
var PIS_DateRange = 12;
function SELECT_ALL_PIS_PILOTS(e) {
  if (e.checked) {
    for (var i in Report_Data) {
      var I = Report_Data[i];
      $('#PIS_RPT_CHK-' + i).prop('checked', true);
      I.checked = true;
    }
  } else {
    for (var i in Report_Data) {
      var I = Report_Data[i];
      $('#PIS_RPT_CHK-' + i).prop('checked', false);
      I.checked = false;
    }
  }
}

function SELECT_PIS_PILOT(e) {
  if (e.checked) {
    Report_Data[e.value].checked = true;
  } else {
    Report_Data[e.value].checked = false;
  }
}

function Gen_PilotInfo() {
  PIS_DateRange = $('#PIS_DateRange').val();
  var count = 0;
  for (var i in Report_Data) {
    var I = Report_Data[i];
    if (I.checked) count++;
  }
  $('#Report_Content').html(
    '<div class="limit-table"><h3 >' +
      iTrans('Processing Report') +
      ' <insettxt id="Report_Content_COUNT">0</insettxt> ' +
      iTrans('of') +
      ' ' +
      count +
      '</h3><button OnClick="CANCEL_PIS_ReportGen()" data-mini="true" class="redbg">' +
      iTrans('Cancel Report Generation') +
      '</button></div>'
  );
  $('#Report_Content').enhanceWithin();
  Report_PilotInfo(0);
}

var CANCEL_PIS_ReportGen_STATUS = false;
function CANCEL_PIS_ReportGen() {
  CANCEL_PIS_ReportGen_STATUS = true;
  $('#Report_Content').html('<div class="limit-table"><h3>' + iTrans('Cancelling Report Generation') + '</h3></div>');
}

function Report_PilotInfo(index) {
  if (CANCEL_PIS_ReportGen_STATUS) {
    Report_PIS_ListPilots();
    CANCEL_PIS_ReportGen_STATUS = false;
    return;
  }
  DUTYTIME = 'REPORT'; //global to prevent client database record marking when it doesn't exist (menu-Duty-SHARED)
  console.log(`Processing Index: ${index}`);
  const num = parseInt(index) + 1;

  if (index === Report_Data.length) {
    $('#Report_Content').html('<h3>' + iTrans('Processing Records') + '</h3>');
    Report_PilotInfoList();
  } else {
    if (Report_Data[index].checked) {
      var curCount = parseInt($('#Report_Content_COUNT').html());
      curCount++;
      $('#Report_Content_COUNT').html(curCount);
      $.getJSON(
        `${BASEURL}menu-Administration-Reports-AJAX.php?report=Report_PilotInfoV2_Pilot&UserPK=${Report_Data[index].PrimaryKey}&month=${PIS_DateRange}`,
        function (json_data) {
          REPORT_USER_DATA[index] = json_data;
          const next = parseInt(index) + 1;
          Report_PilotInfo(next);
        }
      ).fail(function (jqXHR, status, error) {
        console.log('Failed to get Report Data');
        AJAXFAIL(error);
        $.mobile.loading('hide');
      });
    } else {
      const next = parseInt(index) + 1;
      Report_PilotInfo(next);
    }
  }
}

function Report_PilotInfoList() {
  const dom_reportContent = $('#Report_Content');
  dom_reportContent.html('<h3>' + iTrans('Generating Report') + '</h3>');

  $('#Title_Report_Name').html(iTrans('Pilot Information Sheets'));

  $.mobile.loading('hide');
  //console.log(Report_Data);
  let html = '<button onClick="Report_PilotInfoV2(-1)">' + iTrans('Report All Pilots') + '</button>';
  html += "<h2 class='limit-table'>" + iTrans('Pilots') + '</h2>';
  if (COUNTRY === 'UNITED STATES') {
    html +=
      '<table  data-filter="true" class="limit-table first-td-fill" border=1><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('AcType') +
      ' | PIC | SIC | FT ' +
      iTrans('Total') +
      '</th><th>' +
      iTrans('Last Login') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
  } else {
    html +=
      '<table  data-filter="true" class="limit-table first-td-fill" border=1><thead><tr><th>' +
      iTrans('Name') +
      '</th><th></th><th>' +
      iTrans('Last Login') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
  }
  const FromDate = new Date();
  FromDate.setFullYear(FromDate.getFullYear() - 1);
  //FromDate = FromDate.toISOString().substring(0, 10);
  const ToDate = new Date();

  for (const i in Report_Data) {
    if (!Report_Data.hasOwnProperty(i) || Report_Data[i].checked === false) {
      continue;
    }

    const User = Report_Data[i];
    const D = UserDuty(
      REPORT_USER_DATA[i].Duty,
      REPORT_USER_DATA[i].LastSync,
      REPORT_USER_DATA[i].experience,
      REPORT_USER_DATA[i].TotalExperience,
      REPORT_USER_DATA[i].List_TypeExperience,
      REPORT_USER_DATA[i].AcTypeExperience,
      REPORT_USER_DATA[i].ClassExperience,
      FromDate,
      ToDate,
      REPORT_USER_DATA[i].TotalExperience12,
      REPORT_USER_DATA[i].List_TypeExperience12,
      REPORT_USER_DATA[i].ClassExperience12,
      REPORT_USER_DATA[i].AcTypeExperience12,
      REPORT_USER_DATA[i]
    );

    REPORT_USER_DATA[i].D = D;
    let LifeExperienceSummary = '<center><b>' + iTrans('Life Experience') + '</b></center><table><tbody>';
    const Ltotal = {
      PIC: 0,
      SIC: 0,
      Total: 0,
    };
    for (const le in D.Life_TypeExperience) {
      if (!D.Life_TypeExperience.hasOwnProperty(le)) {
        continue;
      }

      const LE = D.Life_TypeExperience[le];
      if (LE.PIC != 0 || LE.SIC != 0 || LE.FTT != 0) {
        const LFT = parseFloat(LE.FTT);
        Ltotal.PIC += parseFloat(LE.PIC);
        Ltotal.SIC += parseFloat(LE.SIC);
        Ltotal.Total += LFT;
        let lbgclass = '';
        if (LFT >= 250) {
          lbgclass = 'greenbg';
        }
        //else lbgclass = "yellowbg";
        LifeExperienceSummary += `<tr class="${lbgclass}"><td>${le}</td><td>${round10(LE.PIC)}</td><td>${round10(
          LE.SIC
        )}</td><td>${round10(LFT)}</td></tr>`;
      }
    }
    if (Ltotal.Total !== 0) {
      LifeExperienceSummary += `<tr style="border-top: thick solid #afafaf;"><td><b>${iTrans(
        'Totals'
      )}</b></td><td><b>${round10(Ltotal.PIC)}</b></td><td><b>${round10(Ltotal.SIC)}</b></td><td><b>${round10(
        Ltotal.Total
      )}</b></td></tr>`;
      LifeExperienceSummary += '</tbody></table>';
    } else {
      LifeExperienceSummary = '';
    }
    let YearExperienceSummary =
      '<center><b>' +
      iTrans('Last') +
      ' ' +
      $('#PIS_DateRange').val() +
      ' ' +
      iTrans('Months') +
      '</b></center><table><tbody>';
    const Ytotal = {
      PIC: 0,
      SIC: 0,
      Total: 0,
    };
    for (const ye in D.TypeExperience) {
      if (!D.TypeExperience.hasOwnProperty(ye)) {
        continue;
      }

      const YE = D.TypeExperience[ye];
      if (YE.PIC != 0 || YE.SIC != 0 || YE.FTT != 0) {
        const YFT = parseFloat(YE.FTT);
        Ytotal.PIC += parseFloat(YE.PIC);
        Ytotal.SIC += parseFloat(YE.SIC);
        Ytotal.Total += YFT;
        let bgclass = '';
        if (YFT >= 100) {
          bgclass = 'greenbg';
        }
        //else bgclass = "yellowbg";
        YearExperienceSummary += `<tr class="${bgclass}"><td>${ye}</td><td>${round10(YE.PIC)}</td><td>${round10(
          YE.SIC
        )}</td><td>${round10(YFT)}</td></tr>`;
      }
    }
    if (Ytotal.Total !== 0) {
      YearExperienceSummary += `<tr style="border-top: thick solid #afafaf;"><td><b>${iTrans(
        'Totals'
      )}</b></td><td><b>${round10(Ytotal.PIC)}</b></td><td><b>${round10(Ytotal.SIC)}</b></td><td><b>${round10(
        Ytotal.Total
      )}</b></td></tr>`;
      YearExperienceSummary += '</tbody></table>';
    } else {
      YearExperienceSummary = '';
    }

    if (COUNTRY !== 'UNITED STATES') {
      LifeExperienceSummary = '';
      YearExperienceSummary = '';
    }
    const action = `<button id="timesheet${i}" class="action-btn" onClick="Report_PilotInfoV2(${i})"><img src="./images/inspect.svg"></button>`;
    html +=
      `<tr><td>${User.chatname}</td><td>${LifeExperienceSummary}${YearExperienceSummary}</td><td>` +
      (User.LastLogin != null ? getLocalDateTime(User.LastLogin, null, false).text : 'Never') +
      `</td><td>${action}</td></tr>`;
  }
  html += '</tbody></table>';
  dom_reportContent.html(html).enhanceWithin();
}

function Report_PilotInfoV2(index, individual = false, revampReport = false) {
  const dom_subReport = $('#Sub_Report_Content');

  dom_subReport.html('<h3>' + iTrans('Generating Report') + '</h3>');
  if (!revampReport) {
    const curpage = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
    if (curpage !== 'DutyDefaults') {
      $('#exitSubReportPage').click(exitshowTimeSheet);
    } else {
      $('#exitSubReportPage').click(ExitMyInfoSheet);
    }
  }

  //Hide this report for regular Lite Users
  $('#Title_Sub_Report_Name').html(iTrans('Pilot Information Sheets'));

  DUTYTIME = 'REPORT'; //global to prevent client database record marking when it doesn't exist (menu-Duty-SHARED)
  dom_subReport.html('');

  $('#DutyExperienceOpts').html(''); //empty div for future selection of custom aircraft types;
  let html;
  const Style_Types = 'border-right: 1px solid #afafaf; width: 120px;font-size: smaller;';
  const Style_Regular = 'border-right: 1px solid #afafaf; width: 30px;font-size: smaller;';
  const Style_Total = 'border-right: 1px solid #afafaf; width: 35px;font-size: smaller;';
  const Style_Row_Total = 'border-top: 1px solid #afafaf;font-size: smaller;';
  const Style_font_size = 'font-size: smaller;';
  const Style_CertNames = 'width: 150px;';

  let ExperienceHeaders = '';
  const ExperienceTypes = {
    PIC: 'PIC',
    SIC: 'SIC',
    DR: iTrans('Dual'),
    FI: 'Inst',
    XC: 'XC',
    AI: 'IFR',
    SI: 'SIM',
    GA: 'Prec Apr',
    IA: 'NonP Apr',
    DAY: iTrans('Day'),
    DAT: 'D TO',
    DL: 'D LND',
    NIGHT: iTrans('Night'),
    NT: 'N TO',
    NL: 'N LND',
    NVG_ARR: 'NVG Arr',
    NVG_DEP: 'NVG Dep',
    NVG_HOV: 'NVG Hov',
    NVG_HRS: 'NVG Hrs',
    NVG_LD: 'NVG Lnd',
    NVG_OPS: 'NVG Ops',
    NVG_TO: 'NVG TO',
    NVG_TRAN: 'NVG Tran',
  };
  const ExpKeys = Object.keys(ExperienceTypes);
  let FloatRating = 0;
  if (!individual) {
    FloatRating = Report_Data[index].FDT_FloatRating;
  } else {
    FloatRating = USER_RIGHTS.FDT_FloatRating;
  }
  if (FDT_ADMIN_UPDATE) {
    FloatRating = Report_Data[index].FDT_FloatRating;
  }
  let NVG_Tracking = 0;
  if (!individual) {
    NVG_Tracking = Report_Data[index].FDT_NvgCur;
  } else {
    NVG_Tracking = USER_RIGHTS.FDT_NvgCur;
  }
  if (FDT_ADMIN_UPDATE) {
    NVG_Tracking = Report_Data[index].FDT_NvgCur;
  }

  for (const H in ExperienceTypes) {
    if (H == 'NVG_ARR' && NVG_Tracking == 0) break;
    ExperienceHeaders += `<th style="${Style_Regular}"><b>${ExperienceTypes[H]}</b></th>`;
  }
  //optional renaming possible in future for some custom fields in specialty tracking
  let SpecialtyHeaders = '';
  let Specialty = {};

  for (const c in FDT_SPECIALTYV3.CS_Recs) {
    const C = FDT_SPECIALTYV3.CS_Recs[c];
    for (const f in C.Fields) {
      if ((C.Fields[f].Field == 'FTO' && FloatRating == 1) || (C.Fields[f].Field == 'FLD' && FloatRating == 1)) {
        C.Fields[f].Used = 1; //to force showing Float landing and Takeoffs in admin reports
      }
      if (C.Fields[f].Used == 1) {
        SpecialtyHeaders += `<th style="${Style_Regular}">${C.Fields[f].Label}</th>`;
        Specialty[C.Fields[f].Field] = C.Fields[f].Label;
      }
    }
  }

  const FromDate = new Date();
  FromDate.setFullYear(FromDate.getFullYear() - 1);
  const ToDate = new Date();
  if (!revampReport) {
    html =
      '<button data-mini="true" onClick="GeneratePDF()">' +
      iTrans('Generate PDF') +
      '</button> <div id="PilotSummaryDIV">';
  } else {
    html = '<div id="PilotSummaryDIV">';
  }

  if (REPORT_USER_DATA.length <= 0) {
    if (!revampt) {
      return dom_subReport.html('<h3>' + iTrans('No Pilot Flight Duty Times Available') + '!</h3>');
    } else {
      return '<h3>' + iTrans('No Pilot Flight Duty Times Available') + '!</h3>';
    }
  }
  let PIS_REVISION_NO = '';
  for (const i in REPORT_USER_DATA) {
    let I = REPORT_USER_DATA[i];
    if (index !== -1) {
      I = REPORT_USER_DATA[index];
    }
    const D = I.D;

    if (D !== false) {
      let e;
      html += '<page><div>';
      html += `<h2>${iTrans('Pilot Name')}: ${I.chatname}</h2>`;

      //Personal Data and Emergency Contact Info;
      let column1 = '' + iTrans('No Contact Data') + '!';
      let column2 = '';
      let license = '<h3>' + iTrans('Licence') + ': ' + iTrans('None Given') + ' </h3>';

      if (I.USER_DATA != null) {
        license = `<h3>${iTrans('Licence')}: ${I.USER_DATA.PersonalInfo.USER_LICENCE}</h3>`;
        PIS_REVISION_NO =
          I.chatname + ' Licence# ' + I.USER_DATA.PersonalInfo.USER_LICENCE + ' [' + new Date().toISOString() + ']';
        const Column1Keys = {
          USER_ADDRESS: iTrans('Address'),
          USER_ADDRESS2: iTrans('Address 2'),
          USER_CITY: iTrans('City'),
          USER_STATE: iTrans('State/Province'),
          USER_COUNTRY: iTrans('Country'),
          USER_ZIP: iTrans('Zip/Postal Code'),
          USER_EMAIL: iTrans('Email'),
          USER_EMAIL2: iTrans('Email 2'),
        };
        const Column2Keys = {
          USER_PHONE: iTrans('Phone'),
          USER_PHONE2: iTrans('Cell'),
          USER_FAX: iTrans('Fax'),
          USER_PHONEOTHER: iTrans('Other Phone'),
          USER_ALLERGIES: iTrans('Allergies'),
          USER_BIRTHDAY: iTrans('Birthday'),
        };

        const PI = I.USER_DATA.PersonalInfo;
        let DataKeys = Object.keys(Column1Keys);
        column1 = '<table class="wrap fdtsummary">';
        for (const P in DataKeys) {
          column1 += `<tr><td style="text-align: right;"><b>${Column1Keys[DataKeys[P]]}: </b></td><td>${
            PI[DataKeys[P]]
          }</td></tr>`;
        }
        column1 += '</table>';

        DataKeys = Object.keys(Column2Keys);
        column2 = '<table class="wrap fdtsummary">';
        for (const P in DataKeys) {
          column2 += `<tr><td style="text-align: right;"><b>${Column2Keys[DataKeys[P]]}: </b></td><td>${
            PI[DataKeys[P]]
          }</td></tr>`;
        }
        if (I.USER_DATA.PersonalInfo.USER_BIRTHDAY !== '') {
          let Years = getAge(I.USER_DATA.PersonalInfo.USER_BIRTHDAY);
          column2 += `<tr><td style="text-align: right;"><b>${iTrans('Age')}: </b></td><td>${Years}</td></tr>`;
        }
        column2 += '</table>';
      }

      html += license;

      //Specialty Experience Summary
      let Spec_Summary = `<h2>${iTrans(
        'Specialty Experience'
      )}</h2><table class="wrap fdtsummary"><thead><tr><th style="${Style_CertNames}"><b>${iTrans(
        'Experience'
      )}</b></th><th style="${Style_Total}"><b>${iTrans(
        'Total'
      )}</b></th><th style="${Style_Total}"><b>12 mths</b></th></tr></thead><tbody>`;
      for (const H in Specialty) {
        Spec_Summary += `<tr><td style="${Style_font_size}${Style_CertNames}">${
          Specialty[H]
        }</td><td style="${Style_Total}">${
          Math.round(D.Life_TotalExperience[H] * 10) / 10
        }</td><td style="${Style_Total}">${Math.round(D.TotalExperience[H] * 10) / 10}</td></tr>`;
      }

      Spec_Summary += '</tbody></table>';

      //Lifetime and last 12 months experience on aircraft types / series
      let Total_Exp_Summary = '<h2>' + iTrans('Life Experience') + '</h2>';

      let Exp_Summary = '<h2>' + iTrans('Last') + ' ' + PIS_DateRange + ' ' + iTrans('Months') + '</h2>';

      Total_Exp_Summary += `<table class="wrap fdtsummary"><thead><tr class="wrap fdtsummary"><th style="${Style_Types}"><b>${iTrans(
        'Types'
      )}</b></th>${ExperienceHeaders}<th style="${Style_Total}"><b>${iTrans('FT Total')}</b></th></tr></thead><tbody>`;

      Exp_Summary += `<table class="wrap fdtsummary"><thead><tr class="wrap fdtsummary"><th style="${Style_Types}"><b>${iTrans(
        'Types'
      )}</b></th>${ExperienceHeaders}<th style="${Style_Total}"><b>${iTrans('FT Total')}</b></th></tr></thead><tbody>`;

      const TypeKeys = Object.keys(D.Life_TypeExperience);
      for (const H in TypeKeys) {
        Total_Exp_Summary += '<tr>';
        Exp_Summary += '<tr>';
        //Show Total Life on Type Experience
        Total_Exp_Summary += `<td style="${Style_Types}">${TypeKeys[H]}</td>`;
        Exp_Summary += `<td style="${Style_Types}">${TypeKeys[H]}</td>`;

        let TFTT = D.Life_TypeExperience[TypeKeys[H]].FTT;

        TFTT = Math.round(TFTT * 10) / 10;
        for (const T in ExpKeys) {
          if (ExpKeys[T] == 'NVG_ARR' && NVG_Tracking == 0) break;
          let Exp_VAL = D.Life_TypeExperience[TypeKeys[H]][ExpKeys[T]];
          Exp_VAL = Math.round(Exp_VAL * 10) / 10;
          Total_Exp_Summary += `<td style="${Style_Regular}">${Exp_VAL}</td>`;
        }
        Total_Exp_Summary += `<td style="${Style_Total}">${TFTT}</td>`;
        Total_Exp_Summary += '</tr>';
        //Show Total Experience Past 12 Months;
        let TFTR = 0;
        if (D.TypeExperience[TypeKeys[H]] != null) {
          TFTR = D.TypeExperience[TypeKeys[H]].FTT;
          TFTR = Math.round(TFTR * 10) / 10;

          for (const E in ExpKeys) {
            if (ExpKeys[E] == 'NVG_ARR' && NVG_Tracking == 0) break;
            let Exp_VAL = D.TypeExperience[TypeKeys[H]][ExpKeys[E]];
            Exp_VAL = Math.round(Exp_VAL * 10) / 10;
            Exp_Summary += `<td style="${Style_Regular}">${Exp_VAL}</td>`;
          }
        } else {
          for (const E in ExpKeys) {
            if (ExpKeys[E] == 'NVG_ARR' && NVG_Tracking == 0) break;
            Exp_Summary += `<td style="${Style_Regular}">0</td>`;
          }
        }
        Exp_Summary += `<td style="${Style_Types}${Style_Total}">${TFTR}</td>`;
        Exp_Summary += '</tr>';
      }
      verbose.log('Life_TypeExperience', D.Life_TotalExperience);
      //Show Total Life Experience;
      Total_Exp_Summary += '<tr>';
      Total_Exp_Summary += `<td style="text-align:right;${Style_Types}"><b>Total</b></td>`;

      let TotalTime = D.Life_TotalExperience.FTT;
      //console.log(D.Life_TotalExperience);
      TotalTime = Math.round(TotalTime * 10) / 10;
      for (const t in ExpKeys) {
        if (ExpKeys[t] == 'NVG_ARR' && NVG_Tracking == 0) break;
        let Exp_VAL = D.Life_TotalExperience[ExpKeys[t]];
        Exp_VAL = Math.round(Exp_VAL * 10) / 10;
        Total_Exp_Summary += `<td style="${Style_Regular}${Style_Row_Total}"><b>${Exp_VAL}</b></td>`;
      }
      Total_Exp_Summary += `<td style="${Style_Total}${Style_Row_Total}"><b>${TotalTime}</b></td>`;

      Total_Exp_Summary += '</tbody></table><br />';

      Exp_Summary += '<tr>';
      Exp_Summary += `<td style="text-align:right;${Style_Types}"><b>${iTrans('Total')}</b></td>`;

      //Show Total Life Experience;
      let TFTS = D.TotalExperience.FTT;
      TFTS = Math.round(TFTS * 10) / 10;

      for (e in ExpKeys) {
        if (ExpKeys[e] == 'NVG_ARR' && NVG_Tracking == 0) break;
        let Exp_VAL = D.TotalExperience[ExpKeys[e]];

        Exp_VAL = Math.round(Exp_VAL * 10) / 10;
        Exp_Summary += `<td style="${Style_Regular}${Style_Row_Total}"><b>${Exp_VAL}</b></td>`;
      }
      Exp_Summary += `<td style="${Style_Total}${Style_Row_Total}"><b>${TFTS}</b></td>`;
      Exp_Summary += '</tr>';
      Exp_Summary += '</tbody></table><br />';
      html += `<table class="wrap fdtsummary">
  <tr>
    <td style="vertical-align: top; width: 400px;">
      <h2>${iTrans('Contact Information')} </h2>
      <table class="wrap fdtsummary">
        <tr>
          <td>${column1}</td>
          <td style="vertical-align: top;">${column2}</td>
        </tr>
      </table>
      <p>${getEmergencyContactsReport(I.emergencyContacts)}</p>
      <p>${getCertsReport(I.certificateData)}</p>
      <p>${Spec_Summary}</p></td>
  </tr>
</table>`;

      html += Total_Exp_Summary + Exp_Summary;
      html += '</div>';
      html += '</page>';
    } else {
      html += `<page><h3>${iTrans('No Duty Times for')}: ${I.chatname}, ${iTrans(
        'Cannot generate report'
      )}</h3></page>`;
    }
    if (index !== -1) {
      break;
    }
  }

  html += '</div>';

  GENERATE_PDF.filename = 'PilotInfoSheet';
  GENERATE_PDF.showPDF = 1;
  GENERATE_PDF.subject = 'Pilot Info Sheet';
  GENERATE_PDF.email = '';
  GENERATE_PDF.div = 'PilotSummaryDIV';
  GENERATE_PDF.ClientEmail = '';
  GENERATE_PDF.Footer = PIS_REVISION_NO;
  GENERATE_PDF.Header = '';
  GENERATE_PDF.Type = 'PIS';
  if (revampReport) {
    return html;
  }
  dom_subReport.html(html);

  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });

  dom_subReport.enhanceWithin();
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString + 'T00:00:00');
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function getCertsReport(_data) {
  if (_data == null || _data.length <= 0) {
    return '';
  }

  const certTable = [];

  certTable.push('<table class="wrap fdtsummary">');
  certTable.push('<thead>' + _certRow() + '</thead>');
  certTable.push('<tbody>');

  _data.forEach((e) => {
    certTable.push(_certRow(e.certifiable_name, e.cert_valid_from, e.cert_valid_till));
  });

  certTable.push('</tbody>');
  certTable.push('</table>');

  return '<h2>' + iTrans('Certificates') + '</h2>' + certTable.join('');
}

function _certRow(certifiable_name, cert_valid_from, cert_valid_till) {
  if (certifiable_name == null) {
    return `
  <tr>
  <th>${iTrans('Certificate')}</th>
  <th>${iTrans('Valid Since')}</th>
  <th>${iTrans('Valid Until')}</th>
  </tr>
  `;
  }

  if (cert_valid_from == null) {
    cert_valid_from = iTrans('Not on file');
    cert_valid_till = '--';
  } else {
    cert_valid_from = cert_valid_from.split(' ')[0];
  }

  if (cert_valid_till == null) {
    cert_valid_till = iTrans('Does not expire');
  } else {
    cert_valid_till = cert_valid_till.split(' ')[0];
  }

  return `
  <tr>
  <td style="font-size:smaller;">${certifiable_name}</td>
  <td style="font-size:smaller; width:100px;">${cert_valid_from}</td>
  <td style="font-size:smaller; width:100px;">${cert_valid_till}</td>
  </tr>
  `;
}

function getEmergencyContactsReport(_data) {
  phoneHumanStrings = {
    text: iTrans('(Text)'),
    text_call: iTrans('(Call or text)'),
    call: iTrans('(Call)'),
  };

  if (_data == null || _data.length <= 0) {
    return '';
  }

  const emergTable = [];
  emergTable.push('<table class="wrap fdtsummary">');
  emergTable.push('<tbody><tr>');

  _data.forEach((e) => {
    emergTable.push(_emergRow(e, e.phone_numbers));
  });

  emergTable.push('</tr></tbody>');
  emergTable.push('</table>');

  return '<h3>' + iTrans('Emergency Contacts') + '</h3>' + emergTable.join('');
}

let phoneHumanStrings = {};

function _emergRow(contact, phoneNumbers) {
  let phoneRows = '';
  phoneNumbers.forEach((p) => {
    const formattedNumber = '+' + p.phn_country_code + p.phn_number + ' ' + phoneHumanStrings[p.phn_use_for];

    phoneRows += `
    <tr>
      <td><b>${p.label}</b></td> 
      <td>${formattedNumber}</td> 
    </tr>
    `;
  });

  const formattedName = `${contact.first_name || ''} ${contact.last_name || ''} `;
  return `
  <td>
    <table>
      <tbody>
        <tr>
          <td><b>${iTrans('Name')}</b></td>
          <td>${formattedName}</td>
        </tr>
        <tr>
          <td><b>${iTrans('Relation')}</b></td>
          <td>${contact.relation || ''}</td>
        </tr>
        <tr>
          <td><b>${iTrans('Address')}</b></td>
          <td>${contact.addr_street || ''}</td>
        </tr>
        <tr>
          <td><b>${iTrans('Address 2')}</b></td>
          <td>${contact.addr_street_second || ''}</td>
        </tr>
        <tr>
          <td><b>${iTrans('City')}</b></td>
          <td>${contact.addr_city || ''}</td>
        </tr>
        <tr>
          <td><b>${iTrans('State/Province')}</b></td>
          <td>${contact.addr_state || ''}</td>
        </tr>
        ${phoneRows}
      </tbody>
    </table>
  </td>
  `;
}
