class CirroValidationRule
{
    /**
     * Runs the validation
     * @abstract
     */
    Run(_inputValue)
    {
    }

    /**
     * Gets the Validation Failed message
     * @abstract
     */
    GetMessage()
    {
    }
}

class CirroVRRequiredField extends CirroValidationRule
{
    constructor()
    {
        super();
    }

    Run(_inputValue)
    {
        return !(_inputValue == null || _inputValue.length < 0);
    }

    GetMessage()
    {
        return 'required';
    }
}

class CirroVRTextLength extends CirroValidationRule
{
    constructor(_minLength = 1, _maxLength = 36000)
    {
        super();
        this._minLength = _minLength;
        this._maxLength = _maxLength;
        this._tooLong   = false;
    }

    Run(_inputValue)
    {
        let chars     = _inputValue.length;
        this._tooLong = (this._maxLength < chars);

        return (typeof _inputValue === 'string') &&
            !this._tooLong &&
            (this._minLength <= chars);
    }

    GetMessage()
    {
        if (this._tooLong)
        {
            return `must be less than ${this._maxLength} characters long`;
        }

        return `can't be shorter than ${this._minLength} characters`;
    }
}

class CirroVRValueEqual extends CirroValidationRule
{
    constructor(_value, _inverse = false)
    {
        super();
        this._value   = _value;
        this._inverse = _inverse;
        this._message = 'has an invalid value';
    }

    Run(_inputValue)
    {
        if (this._inverse)
        {
            return _inputValue != this._value;
        }
        return _inputValue == this._value;
    }

    SetMessage(_message)
    {
        this._message = _message;
        return this;
    }

    GetMessage()
    {
        return this._message;
    }
}

class CirroValidator
{

    constructor()
    {
        this._trackedInputs = [];
        this._errors        = {};
        this._rules         = [];
        this._valid         = {};
    }

    /**
     * @param {CirroValidationRule} _rule
     */
    AddRule(_rule)
    {
        this._rules.push(_rule);
        return this;
    }

    /**
     *
     * @param {string|jQuery} _selector
     * @returns {CirroValidator}
     */
    AddInput(_selector)
    {
        let input = (typeof _selector === 'string') ? $(_selector) : _selector;

        if (input.length <= 0)
        {
            throw `Input "${_selector}" not found.`;
        }

        this._trackedInputs.push(input);

        let id = this._trackedInputs.length - 1;

        this._errors[id] = $('<span></span>').addClass('sama-form-error').hide();

        input.closest('[class*=col]').append(this._errors[id]);

        this._AssignEventHandler(input, id);
        return this;
    }

    /**
     *
     * @param {jQuery} _input  The input
     * @param {int}    _id     The id of the validated input
     * @private
     */
    _AssignEventHandler(_input, _id)
    {
        let tagName = _input.prop('tagName');
        let tagType = _input.attr('type');

        if (tagType === 'text' || tagName === 'TEXTAREA' || tagType === 'date')
        {
            this._Validate(_input, _id);
            _input.on('input.cevent validate.cevent', () => this._Validate(_input, _id));
        }
        if (tagName === 'FIELDSET')
        {
            this._Validate(_input.find(':checked').first(), _id);
            _input.on('change.cevent validate.cevent', (_e) =>
            {
                this._Validate($(_e.target), _id);
            });
        }
        else
        {
            this._Validate(_input, _id);
            _input.on('change.cevent validate.cevent', () => this._Validate(_input, _id));
        }
    }

    /**
     * Validates _input via the registered rules
     * @param _input
     * @param _id
     * @private
     */
    _Validate(_input, _id)
    {
        this._valid[_id] = true;
        let messages     = [];

        for (let r = 0; r < this._rules.length; r++)
        {
            let ipVal = (_input.length === 1) ? _input.val() : null;

            let valid = this._rules[r].Run(ipVal);

            if (valid === false)
            {
                messages.push(this._rules[r].GetMessage());
            }

            this._valid[_id] &= valid;
        }

        if (this._valid[_id] === true)
        {
            this._errors[_id].text('').hide();
        }
        else
        {
            this._errors[_id].text(messages.join(', ')).slideDown(50);
        }
    }

    /**
     * Indicates whether all inputs tracked by this validator are valid
     * @returns {boolean}
     */
    AllValid()
    {
        for (let i = 0; i < this._trackedInputs.length; i++)
        {
            this._trackedInputs[i].trigger('validate');
        }

        return true; //this._valid.reduce((t, n) => t &= n);
    }

    /**
     * Cleans up validation additions
     * @destructor
     */
    Destroy()
    {
        for (let i = 0; i < this._trackedInputs.length; i++)
        {
            this._trackedInputs[i].off('.cevent');
        }

        for (let e in this._errors)
        {
            if (this._errors.hasOwnProperty(e))
            {
                this._errors[e].remove();
            }
        }

        this._trackedInputs = null;
        this._rules         = null;
        this._errors        = null;
    }
}