var WXcamsMarkers;
var WXCAM;
var WXID = '';
var WXCAM_Ani = false;
var WxImages = [];

function CAcamsChange(e) {
  if (e.checked) {
    LoadWXcams();
  } else {
    //CAcamsMarkers.clearLayers();
    if (WxImages.length > 0) {
      for (var W in WxImages) {
        mapb.removeLayer(WxImages[W]);
        mapb.removeSource(WxImages[W]);
        console.log('todo remove images to swap modes');
      }
      WxImages = []; //global;
    }
    WXCAM_Ani == false;
    WXID = '';
    mapb.off('click', AddWxCam);
    mapb.removeLayer('WX_CAMS');
    mapb.removeSource('WX_CAMS');
  }
}

function LoadWXcams() {
  WXcamsMarkers = {
    type: 'FeatureCollection',
    features: [],
  };
  //US Weather Cameras
  for (i in WX_Cams) {
    I = WX_Cams[i];
    var data = I.data;
    //id, Lat, Long, ICAO,name,elev,index
    //addUScam(I[0], I[5], I[6], I[3],I[1],I[4],i);
    WXcamsMarkers.features.push({
      type: 'Feature',
      properties: {
        id: data.siteId,
        Lat: I.latitude,
        Long: I.longitude,
        ICAO: data.siteIdentifier,
        name: data.siteName,
        index: i,
        data: I.data,
      },
      geometry: {
        type: 'Point',
        coordinates: [parseFloat(I.longitude), parseFloat(I.latitude)],
      },
    });
  }
  console.log(WXcamsMarkers);
  mapb.addSource('WX_CAMS', {
    type: 'geojson',
    data: WXcamsMarkers,
  });
  mapb.addLayer({
    id: 'WX_CAMS',
    type: 'symbol',
    source: 'WX_CAMS',
    layout: {
      'icon-size': 1.5,
      'icon-image': 'WxCams',
      'icon-padding': 0,
      visibility: 'visible',
    },
  });
  mapb.on('click', AddWxCam);
}

function AddWxCam(e) {
  var features = mapb.queryRenderedFeatures(e.point, {
    layers: ['WX_CAMS'],
  });
  if (features.length) {
    //Cam selected
    console.log(features[0]);
    var p = features[0].properties;
    if (WXID != p.id) {
      WXCAM_Ani = false; //global
      WXID = p.id; //global
      getCAWXcam(p.id, p.Lat, p.Long, p.ICAO, p.name, p.Country, p.index, p.cameras);
    } else {
      //Cam already loadede sencond click to animate
      if (WXCAM_Ani == false) {
        WXCAM_Ani = true; //global
        getCAWXcam(p.id, p.Lat, p.Long, p.ICAO, p.name, p.Country, p.index, p.cameras);
      } else {
        WXCAM_Ani == false;
        WXID = '';
        if (WxImages.length > 0) {
          for (var W in WxImages) {
            mapb.removeLayer(WxImages[W]);
            mapb.removeSource(WxImages[W]);
            console.log('todo remove images to swap modes');
          }
          WxImages = []; //global;
        }
      }
    }
  }
}
//getUSWXcam(id, Lat, Long, ICAO,name,elev,index)
function getCAWXcam(id, Lat, Long, ICAO, name, Country, index, cameras) {
  //Calculate offsets to correct for projection distortion when overlaying images
  var Offset = 68.629039;
  Offset = Offset - Lat;
  Offset = Offset / 20.25596 + 1;
  var Oprime = 0.002663 * Offset;
  var DLat = Oprime / 2;
  //var DLat = 0.005327/2; //static only good for cyqt
  var DLong = 0.010152 / 2;
  var CLat = parseFloat(Lat);
  var CLong = parseFloat(Long);
  var SElat = CLat - DLat;
  var NWlat = CLat + DLat;
  var SElong = CLong - DLong;
  var NWlong = CLong + DLong;
  //var cambounds = L.latLngBounds([[SELat,SELong],[NWLat,NWLong]]);
  var cambounds = [
    [parseFloat(SElong), parseFloat(NWlat)],
    [parseFloat(NWlong), parseFloat(NWlat)],
    [parseFloat(NWlong), parseFloat(SElat)],
    [parseFloat(SElong), parseFloat(SElat)],
  ];
  if (!WXCAM_Ani) WxImage = 'images/compass_stop.png';
  else WxImage = 'images/compass_remove.png';
  if (WxImages.length > 0) {
    for (var W in WxImages) {
      mapb.removeLayer(WxImages[W]);
      mapb.removeSource(WxImages[W]);
      console.log('todo remove images to swap modes');
    }
    WxImages = []; //global;
  }
  mapb.addSource('WxImage', {
    type: 'image',
    url: WxImage,
    coordinates: cambounds,
  });
  mapb.addLayer(
    {
      id: 'WxImage',
      source: 'WxImage',
      type: 'raster',
      paint: {
        'raster-opacity': 1,
      },
    },
    'WX_CAMS'
  );

  var BNWlat = CLat - DLat * 3;
  var BNWlong = CLong + DLong * 3;
  var BSElat = CLat + DLat * 3;
  var BSElong = CLong - DLong * 3;
  //var SiteBounds = L.latLngBounds([[BSELat,BSELong],[BNWLat,BNWLong]]);
  var SiteBounds = [
    [parseFloat(BSElong), parseFloat(BNWlat)],
    [parseFloat(BNWlong), parseFloat(BNWlat)],
    [parseFloat(BNWlong), parseFloat(BSElat)],
    [parseFloat(BSElong), parseFloat(BSElat)],
  ];
  var WxImage;

  WxImages = [];
  WxImages.push('WxImage');

  if (mapb.getZoom() < 14) {
    var v1 = new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(SiteBounds[0][0], SiteBounds[0][1]),
      new mapboxgl.LngLat(SiteBounds[2][0], SiteBounds[2][1])
    );
    mapb.fitBounds(v1, { animate: !MAPSTATE.OfflineDataOn });
  }

  $.getJSON(TILE_URL + '/CamUS/' + 'getUSCam.php?type=png&id=' + id, function (json_data) {
    WXCAM = json_data; //global
    for (var i in WXCAM) {
      var I = WXCAM[i];

      switch (I.dir) {
        case 'North':
          SELat = CLat + DLat * 3;
          NWLat = CLat + DLat;
          NWLong = CLong - DLong;
          SELong = CLong + DLong;
          break;
        case 'NorthEast':
          SELat = CLat + DLat * 3;
          NWLat = CLat + DLat;
          SELong = CLong + DLong * 3;
          NWLong = CLong + DLong;
          break;
        case 'East':
          NWLat = CLat - DLat;
          SELat = CLat + DLat;
          SELong = CLong + DLong * 3;
          NWLong = CLong + DLong;
          break;
        case 'SouthEast':
          NWLat = CLat - DLat * 3;
          SELat = CLat - DLat;
          SELong = CLong + DLong * 3;
          NWLong = CLong + DLong;
          break;
        case 'South':
          NWLat = CLat - DLat * 3;
          SELat = CLat - DLat;
          NWLong = CLong - DLong;
          SELong = CLong + DLong;
          break;
        case 'SouthWest':
          NWLat = CLat - DLat * 3;
          SELat = CLat - DLat;
          NWLong = CLong - DLong * 3;
          SELong = CLong - DLong;
          break;
        case 'West':
          NWLat = CLat - DLat;
          SELat = CLat + DLat;
          NWLong = CLong - DLong * 3;
          SELong = CLong - DLong;
          break;
        case 'NorthWest':
          SELat = CLat + DLat * 3;
          NWLat = CLat + DLat;
          NWLong = CLong - DLong * 3;
          SELong = CLong - DLong;
          break;
      }

      //cambounds = L.latLngBounds([[SELat,SELong],[NWLat,NWLong]]);
      cambounds = [
        [parseFloat(NWLong), parseFloat(SELat)],
        [parseFloat(SELong), parseFloat(SELat)],
        [parseFloat(SELong), parseFloat(NWLat)],
        [parseFloat(NWLong), parseFloat(NWLat)],
      ];
      var name = id + I.dir;
      if (WXCAM_Ani == false) {
        console.log('todo Loading Static');
        //WxImage = L.imageOverlay(img, cambounds, {opacity:1}).addTo(map);
        mapb.addSource('WxImage' + name, {
          type: 'image',
          url: BASEURL + 'images/CamUS/' + name + '.png',
          coordinates: cambounds,
        });
        mapb.addLayer({
          id: 'WxImage' + name,
          source: 'WxImage' + name,
          type: 'raster',
          paint: {
            'raster-opacity': 1,
          },
        });
        WxImages.push('WxImage' + name);
      } else {
        LoadwebmCam(I.url, name, cambounds, 'WxImage' + name, I.CamID, id);
      }
    }
  });
}

function LoadwebmCam(url, name, cambounds, layerID, id, siteId) {
  //var CamImage = L.imageOverlay(LoaderImage, cambounds, {opacity:0.4}).addTo(map);
  var imageUrl = TILE_URL + '/CamUS/' + 'getUSCam.php?type=webm&id=' + id + '&name=' + name + '&siteId=' + siteId;
  var loaderImage = 'images/loading4.mp4'; //preloader image
  //var RadarImage = L.imageOverlay(loaderImage, radarbounds, {opacity:0.4}).addTo(map);
  mapb.addSource(layerID, {
    type: 'video',
    urls: [loaderImage],
    coordinates: cambounds,
  });
  mapb.addLayer({
    id: layerID,
    source: layerID,
    type: 'raster',
    paint: {
      'raster-opacity': 0.4,
    },
  });
  WxImages.push(layerID);
  //use ajaxQ so multiple request do not overload and drop are insted run in sequence not parallel

  $.ajaxq('radar', {
    timeout: 60000,
    url: imageUrl,
    success: function (data) {
      mapb.removeLayer(layerID);
      mapb.removeSource(layerID); //remove loader icon;
      mapb.addSource(layerID, {
        type: 'video',
        urls: [data],
        coordinates: cambounds,
      });
      mapb.addLayer({
        id: layerID,
        source: layerID,
        type: 'raster',
        paint: {
          'raster-opacity': 1,
        },
      });
    },
  }).error(function () {
    alert('Error Loading Radar Overlay!');
    mapb.removeLayer(layerID);
    mapb.removeSource(layerID); //remove loader icon;
  });
}
