class vSAMA_TabKwdCategory extends vSAMA_AbstractTab {
  constructor(_category, _readOnly) {
    super(_category);

    this.SetUpGui('<li><a data-oref="kwc_name"></a></li>', SAMA.Templates.tpl.keywords.categoryEdit, _readOnly);

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    if (cSAMA_Utils.IsTruthy(this.model.sync.kwc_locked)) {
      this.dom.deleteButton.prop('disabled', true);
    }

    this.dom.colorInput = this.dom.body.find('.jscolor');
    this.dom.colorRef = cSAMA_GeneralInputs.Color(this.dom.colorInput);
    this.dom.colorInput.on('change', () => {
      this.model.sync.kwc_color = this.dom.colorRef.toHEXString();
      this.model.SetModified(true);
      this.OnColorChange();
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    });

    this.dom.body.find('[data-sref]').on('change input', () => this.OnValueChange());

    this.dom.body.enhanceWithin();

    this.OnColorChange();
  }

  OnValueChange() {
    this.UpdateObservers('CategoryEdited');
  }

  OnDelete() {
    this.UpdateObservers('AttemptCategoryDelete', this);
    this.owner.Refresh();
  }

  OnColorChange() {
    this.dom.rider.find('a').attr('style', 'color: % !important'.tr(this.model.sync.kwc_color));
  }

  /**
   * If a change was made that requires we toggle the delete button, check if it was our model that
   * was altered and if so, turn off the delete button.
   * @param _categoryPk
   */
  CheckDisableDelete(_categoryPk) {
    if (_categoryPk !== this.model.GetPk()) {
      return;
    }

    this.DisableDeleteButton();
  }

  DisableDeleteButton() {
    // Can't delete a category that got tags moved to it
    this.dom.deleteButton.prop('disabled', true);

    this.dom.body.find('.sama-tinyHint').removeClass('hidden');
  }

  EnableDeleteButton() {
    this.dom.body.find('.sama-tinyHint').addClass('hidden');

    if (cSAMA_Utils.IsTruthy(this.model.sync.kwc_locked)) {
      // Don't show if the category is locked
      return;
    }

    this.dom.deleteButton.prop('disabled', false);
  }

  ConfirmDelete() {
    super.OnDelete();
  }
}
