/**
 *
 */
class WaypointGroupsTable extends AbstractDatabaseTable {
  constructor() {
    super('WaypointGroups', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"   integer primary key,
            "group"        text,
            "datasetType"  text,
            "Name"         text,
            "Description"  text,
            "created"      text,
            "modified"     text,
            "whoupdate"    text,
            "ownerPK"      integer,
            "isLocked"     integer,
            "isShared"     integer,
            "forJob_PK"    integer,
            "featureCount" integer,
            "minLat"       numeric,
            "maxLat"       numeric,
            "minLong"      numeric,
            "maxLong"      numeric,
            "Icon"         text,
            "Point"        text,
            "Point_Fill"   text,
            "Polyline"     text,
            "Polygon"      text,
            "Polygon_Fill" text,
            "DELETED"      integer
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    if (whereClause === 'PrimaryKey != ?') {
      columns = ['rowid', 'PrimaryKey', 'Name', 'datetime'];
      orderByClause = 'order by "Name" asc';
    }

    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    for (const row of rows) {
      row.data = this.parseJson(row.data);
    }

    return rows;
  }
}
