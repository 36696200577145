/**
 * Page class for reports listing
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @class
 */
class pSAMA_ReportsList extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_ReportsList', SAMA.pages.MenuHome);

    this.dom.repList = this.dom.page.find('#SamaReportList');
    this.dom.repFilter = this.dom.page.find('#SamaReportFilter');

    this.header = new cSAMA_PageHeader('Reports', this.dom.page);

    this.viewRepList = new vSAMA_ListView('No reports found');
    this.viewRepList.GetDom().appendTo(this.dom.repList);

    this.filterRep = new cSAMA_FilterReports(this.viewRepList);
    this.filterRep.GetDom().appendTo(this.dom.repFilter);

    this.dom.stage = this.dom.page.find('input[name="stage"]');
    this.dom.stage.on('change', () => this.GetReports());
    this.lastStageLoaded = -1;
  }

  GetReports() {
    let stage = this.dom.stage.filter(':checked').val();

    if (this.lastStageLoaded === stage) {
      // Don't get the same stage twice.
      return;
    }

    let r = new cSAMA_Request('Reports', 'getList');
    r.Set('stage', stage);

    cSAMA_Request.Dispatch({
      name: 'FetchReports',
      reqs: r,
      clientErrorCode: 'SAMA 34 22 CC EE',
      fnSuccess: (_r) => {
        this.lastStageLoaded = stage;
        this.ReplaceItems(_r);
      },
      goBackOnError: false,
    });
  }

  ReplaceItems(_data) {
    this.viewRepList.Clear();

    if (_data.length <= 0) {
      return;
    }

    _data = _data[0];
    for (let d = 0; d < _data.length; d++) {
      let rep = new mSAMA_Report(_data[d]);
      let liViIt = new vSAMA_ListItemReport(rep, _data[d].mtx_boundsHigh, _data[d].mtx_boundsMedium);

      this.viewRepList.AddItem(liViIt);
    }

    this.filterRep.RunFilters();
  }

  /**
   * Function to call when the user enters the page.
   * @override
   */
  EnterPage() {
    this.GetReports();
    this.filterRep.GetReportCategory();
    SAMA.Navigator.FinishedLoading(this.header);
  }

  LeavePage() {
    this.lastStageLoaded = -1;
    this.viewRepList.Clear();
    super.LeavePage();
  }
}
