function Report_Logs() {
  var Category = Report_Data[2];
  var NotifyType = $('input:radio[name=RPT_NotifyType]:checked').val();
  var CurCat = 'All';
  if ($('#RPT_CatFilter').length) {
    CurCat = $('#RPT_CatFilter').val();
  }
  //$("#Report_Content").html("");
  var CategoryFilter =
    '<select id="RPT_CatFilter" data-mini="true"><option value="All" selected>' + iTrans('All') + '</option>';
  for (var c in Category) {
    CategoryFilter += '<option value="' + Category[c].Type + '">' + Category[c].Type + '</option>';
  }
  CategoryFilter += '</select>';
  console.log('Log Reports');
  $('#Title_Report_Name').html(iTrans('Notification Report'));
  var HTML = '';
  var i;

  if (NotifyType == 'NoItin') {
    HTML +=
      '<h3>' +
      iTrans('Aircraft Detected Airborne Without Itinerary') +
      '</h3><div style="width: 100%; overflow: auto"><table data-filter="true">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('On') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Ident') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Type') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var NoItin = Report_Data[0];
    for (i in NoItin) {
      var I = NoItin[i];
      var date = I.Datetime;
      date = getLocalDateTime(date);
      HTML += '<tr><td>' + date.text + '</td><td>' + I.ident + '</td><td>' + I.AcType + '</td></tr>';
    }
    HTML += '</tbody></table></div>';
    $('#Report_Content').html(HTML);
    $('#Report_Content').enhanceWithin();
  }
  if (NotifyType == 'Notify') {
    HTML +=
      '<h3 class="limit-table">' +
      iTrans('Notifications Sent') +
      '</h3><div style="width: 100%; overflow: auto"><table data-filter="true">' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('Sent') +
      '</th>' +
      '<th>' +
      iTrans('User') +
      '</th>' +
      '<th>' +
      iTrans('Email') +
      '</th>' +
      '<th>' +
      iTrans(CategoryFilter) +
      '</th>' +
      '<th>' +
      iTrans('Message') +
      '</th>' +
      '<th>' +
      iTrans('Status') +
      '</th>' +
      '<th>' +
      iTrans('Additional Info') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var Notify = Report_Data[1];
    for (i in Notify) {
      I = Notify[i];
      date = I.Initiated;
      date = getLocalDateTime(date);
      var Message = '<b>' + I.subject + '</b><br />' + I.html;
      var Status = iTrans('Delivered');
      var lineClass = '';
      if (I.Success == 0) {
        Status = iTrans('Failed');
        lineClass = 'redRecord';
      }
      console.log(CurCat);
      if (CurCat == 'All' || I.Type == CurCat)
        HTML +=
          '<tr class="' +
          lineClass +
          '"><td>' +
          date.text +
          '</td><td>' +
          I.chatname +
          '</td><td>' +
          I.Recipient +
          '</td><td>' +
          I.Type +
          '</td><td>' +
          Message +
          '</td><td>' +
          Status +
          '</td><td>' +
          I.Reason +
          '</td></tr>';
    }
    HTML += '</tbody></table></div>';
    $('#Report_Content').html(HTML);
    $('#Report_Content').enhanceWithin();
    $('#RPT_CatFilter').val(CurCat).selectmenu('refresh');
    $('#RPT_CatFilter').change(Report_Logs);
    var RecordCounts = '';
    var itinCount = Report_Data[0].length;
    var notifyCount = Report_Data[1].length;
    if (itinCount == 200)
      RecordCounts =
        iTrans('More than 200') +
        ' [' +
        iTrans('No Itinerary') +
        '] ' +
        iTrans('Notifications for Date Range') +
        ': <b>' +
        iTrans('Displaying most recent 200 only') +
        '!</b><br />';
    else
      RecordCounts =
        itinCount + ' [' + iTrans('No Itinerary') + '] ' + iTrans('Notifications for Date Range') + '<br />';
    if (notifyCount == 200)
      RecordCounts +=
        iTrans('More than 200') +
        ' [' +
        iTrans('Notification Emails') +
        '] ' +
        iTrans('for Date Range') +
        ': <b>' +
        iTrans('Displaying most recent 200 only') +
        '!</b>';
    else RecordCounts += notifyCount + ' [' + iTrans('Notification Emails') + '] ' + iTrans('for Date Range') + '';
    $('#ReportLogsRecords').html(RecordCounts);
  }
}

function update_Log_Data() {
  $('#Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=Report_Logs&FromDate=' + FromDate + '&ToDate=' + ToDate,
    function (json_data) {
      Report_Data = json_data;
      Report_Logs();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    AJAXFAIL(error);
  });
}
