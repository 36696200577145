/**
 * Page class for viewing and editing reports
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_ReportsEdit extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_ReportsEditor', SAMA.pages.ReportsList);

    this.header = new cSAMA_PageHeader('View Report', this.dom.page);
    this.notifiedOfNoTags = -1;

    this.dom.matrixLocation = this.dom.page.find('div[data-sama-template="riskMatrix-placeholder"]');
    this.dom.consequenceEditorLocation = this.dom.page.find('#rep-consequence-editor');
    this.dom.modalLocation = this.dom.page.find('#sama_modalLocation').parent();
    this.dom.reportData = this.dom.page.find('#sama_rep_reportData');
    this.dom.timeLine = this.dom.page.find('#sama_rep_timeline');
  }

  /**
   * Function called when the user enters the page.
   * @param {boolean} [_preseed.proactive]      Whether to make a new proactive report or a reactive one
   * @param {int}     [_preseed.reportPk]       The report to load from the server
   * @param {int}     [_preseed.matrixVersion]  The risk matrix version to load from the server
   * @param {int}     [_preseed.backPage]       The page to return to from this one
   * @override
   */
  EnterPage(_preseed) {
    this.reassignConfirmResult = 0;
    cSAMA_Utils.WaitForCatTaggerLoad();

    this.permissions = SAMA.enums.ACCESS.EDIT;
    this.isReadOnly = false;

    if (_preseed == null) {
      _preseed = {};
    }

    if (_preseed.backPage != null) {
      this.SetTempPrevPage(_preseed.backPage);
    }
    this.currentPreseed = _preseed;

    let requests = [
      new cSAMA_Request('PoliciesObjectives', 'getData'),
      new cSAMA_Request('Preferences', 'getData'),
      new cSAMA_Request('Departments', 'getData'),
    ];

    if (_preseed.hasOwnProperty('reportPk')) {
      let repCatReq = new cSAMA_Request('ReportCategory', 'getData');
      repCatReq.Set('reportPk', _preseed.reportPk);
      // We've been given a reportPK -> download report and the matrix version
      // used to create it from the server
      let repReq = new cSAMA_Request('Reports', 'getData');
      repReq.Set('reportPk', _preseed.reportPk);

      requests.push(repCatReq);
      requests.push(repReq);
    } else {
      requests.push(new cSAMA_Request('ReportCategory', 'getData'));
      // Get the latest version of the matrix and make a new report
      requests.push(new cSAMA_Request('RiskMatrix', 'getData'));
    }

    cSAMA_Request.Dispatch({
      name: 'GetReportPage',
      reqs: requests,
      clientErrorCode: 'SAMA 72 BF E1 BB',
      fnSuccess: (_r) => this.CheckLockState(_r),
    });
  }

  CheckLockState(_response) {
    SAMA.settings.policyObject = new mSAMA_Policy(_response[0]);
    SAMA.settings.preferences = new mSAMA_Preferences(_response[1]);
    this.departments = _response[2];
    this.categories = _response[3];

    if (_response[4].hasOwnProperty('report')) {
      // Also have report data
      this.riskMatrix = new mSAMA_RiskMatrix(_response[4].riskMatrix);
      this.report = mSAMA_Report.ReportFromRemoteData(_response[4].report, this.riskMatrix);
      this.permissions = _response[4].report.report.rep_accessRights;
      this.events = _response[4].events;
    } else {
      // Don't have report data -> make new
      this.riskMatrix = new mSAMA_RiskMatrix(_response[4]);
      this.events = [];
      this.report = new mSAMA_Report(
        {
          rep_isProactive: this.currentPreseed.proactive === true,
        },
        this.riskMatrix.sync.mtx_version,
        SAMA.settings.preferences
      );
    }

    this.resLock = new cSAMA_ResourceLock({
      what: 'REPOR',
      name: this.report.sync.rep_summary,
      pk: this.report.GetPk(),
      fnOpen: () => this.SetUpGui(),
      fnLocked: () => this.PageLoadFailed(),
    });

    if (
      (this.report.WasDownloaded() && this.permissions === SAMA.enums.ACCESS.EDIT) ||
      this.permissions === SAMA.enums.ACCESS.REVIEW ||
      this.permissions === SAMA.enums.ACCESS.REVIEW_WITH_GRANT
    ) {
      // Lock the record if we're in edit mode and if it exists on the server
      this.resLock.BeginLocking();
      return;
    }
    this.SetUpGui();
  }

  /**
   * Puts together the user interface
   */
  SetUpGui() {
    this.dom.reportData.hide();
    let lock = SAMA.GeneralGui.LockPageState('REPEdit.SetUpGui');

    this.viewReportsEditor = new vSAMA_ReportsEditor(
      this.dom.page,
      this.report,
      this.departments,
      this.categories,
      this.riskMatrix,
      this.permissions
    );

    this.Observe(this.viewReportsEditor, 'TypeChange', () => this.GuiTypeChange());

    this.viewRiskMatrix = new vSAMA_RiskMatrixView(this.dom.matrixLocation, this.riskMatrix);
    this.viewRiskMatrix.Observe(this.viewReportsEditor, 'RiskChange', (_p) => this.viewRiskMatrix.ChangeSelection(_p));
    this.viewTimeLine = new vSAMA_ListView('No history exists for this report');

    for (let i = 0; i < this.events.length; i++) {
      let tiLiIt = new mSAMA_TimeLineEntry(this.events[i]);
      let liViIt = new vSAMA_ListItemTimeLine(tiLiIt);

      this.viewTimeLine.AddItem(liViIt);
    }
    this.viewTimeLine.Refresh();
    this.viewTimeLine.GetDom().appendTo(this.dom.timeLine);

    this.GuiTypeChange();

    this.viewReportsEditor.GuiSetUpGeneral();
    this.viewReportsEditor.GuiSetUpSimple();

    switch (this.permissions) {
      case SAMA.enums.ACCESS.DELETE:
        this.header.AddButton(coSAMA_CommonReports.ButtonDelete(this.report.GetPk()));
      case SAMA.enums.ACCESS.VIEW:
        this.isReadOnly = true;
        this.header.AddButton(coSAMA_CommonReports.ButtonGetPdf(this.report));
        this.viewReportsEditor.GuiSetUpForView();
        break;

      case SAMA.enums.ACCESS.REVIEW_WITH_GRANT:
        this.viewReportsEditor.AllowGrant();
      case SAMA.enums.ACCESS.REVIEW:
        this.header.ButtonSaveDiscard();

        if (this.permissions === SAMA.enums.ACCESS.REVIEW_WITH_GRANT) {
          // Keep buttons in correct order
          this.header.AddButton(coSAMA_CommonReports.ButtonDelete(this.report.GetPk()));
        }

        if (!this.report.IsApproved()) {
          this.header.AddButton(this.ButtonReject());
          this.header.AddButton(this.ButtonApprove());
        }

        this.header.AddButton(coSAMA_CommonReports.ButtonGetPdf(this.report));

        this.viewReportsEditor.GuiSetUpForReview();
        break;

      case SAMA.enums.ACCESS.EDIT:
        this.header.ButtonSaveDiscard();
        this.header.AddButton(this.ButtonSubmit());
        this.viewReportsEditor.GuiSetUpForEdit();
        break;

      default:
        throw 'Unknown permissions ' + this.permissions;
    }

    this.GenerateConsequenceView();

    if (this.report.WasDownloaded()) {
      this.ShowReportDataSection();
    }

    SAMA.GeneralGui.UnlockPageState(lock);
    SAMA.Navigator.FinishedLoading(this.header);
    this.viewReportsEditor.ResetItinInputs();
    this.viewReportsEditor.ResetReviewerOnlyInputs();
  }

  ShowReportDataSection() {
    let formatter = {
      sync: {
        rep_primaryKey: '[' + this.report.sync.rep_groupReportId + ']',
        rep_reporterName:
          this.report.sync.rep_reporterName != null && this.report.sync.rep_reporterName.length > 0
            ? this.report.sync.rep_reporterName
            : 'Anonymous',
        rep_submittedDate: 'Not yet',
        rep_approvedDate: 'Not yet',
        rep_approvedLabel: 'Approved',
        rep_responsibleStaffName:
          this.report.sync.rep_responsibleStaffName != null && this.report.sync.rep_responsibleStaffName.length > 0
            ? this.report.sync.rep_responsibleStaffName
            : 'Nobody',
      },
    };

    if (this.report.sync.rep_submittedDate != null) {
      formatter.sync.rep_submittedDate = cSAMA_Utils.NiceDate(this.report.sync.rep_submittedDate, true, false, true);
    }

    if (this.report.sync.rep_approvedDate != null) {
      formatter.sync.rep_approvedDate = cSAMA_Utils.NiceDate(this.report.sync.rep_approvedDate, true, false, true);
    } else if (this.report.sync.rep_lastAction === 'reject') {
      formatter.sync.rep_approvedLabel = 'Rejected';
      formatter.sync.rep_approvedDate = this.report.sync.rep_lastActionDate;
    }

    cSAMA_GeneralInputs.FillMarkup(formatter, this.dom.reportData);
    this.dom.reportData.show();
  }

  GenerateConsequenceView() {
    this.viewConsTabs = new vSAMA_TabbedEditor({
      noDataRider: 'No Consequences',
      noDataBody: 'There are no consequences. You must add at least one.',
      addButtonLabel: 'New Consequence...',
      readOnly: this.isReadOnly,
    });

    this.viewConsTabs.GetDom().appendTo(this.dom.consequenceEditorLocation);

    // Insert preset consequences
    let consequences = this.report.GetConsequences();
    for (let c = 0; c < consequences.length; c++) {
      let cons = consequences[c];
      let viewTab = new vSAMA_TabConsequence(cons, this.riskMatrix, this.dom.modalLocation, this.isReadOnly);
      this.viewReportsEditor.Observe(viewTab, 'RiskChange', () => this.viewReportsEditor.UpdateReportRisk());

      this.SetConsequencePermissions(viewTab);
      this.viewConsTabs.AddTab(viewTab);
    }

    this.viewConsTabs.Refresh();
    this.viewReportsEditor.UpdateReportRisk();

    if (!this.isReadOnly) {
      this.viewConsTabs.GetAddButton().on('click', () => this.OnNewConsequence());
    }
  }

  OnNewConsequence() {
    let newCons = new mSAMA_Consequence(this.report, this.riskMatrix);
    let viewTab = new vSAMA_TabConsequence(newCons, this.riskMatrix, this.dom.modalLocation, this.isReadOnly);
    this.viewReportsEditor.Observe(viewTab, 'RiskChange', () => this.viewReportsEditor.UpdateReportRisk());

    this.SetConsequencePermissions(viewTab);

    this.report.AddConsequence(newCons);
    this.viewConsTabs.AddTab(viewTab);
    this.viewConsTabs.Refresh();
    this.viewReportsEditor.UpdateReportRisk();
  }

  SetConsequencePermissions(_viewTab) {
    switch (this.permissions) {
      case SAMA.enums.ACCESS.EDIT:
        _viewTab.ToggleCorrectiveActionButton(false);
        break;
      case SAMA.enums.ACCESS.DELETE:
      case SAMA.enums.ACCESS.VIEW:
        _viewTab.ToggleCorrectiveActionButton(true);
        _viewTab.SetCorrectiveActionsViewOnly();
        break;
      case SAMA.enums.ACCESS.REVIEW_WITH_GRANT:
      case SAMA.enums.ACCESS.REVIEW:
        _viewTab.ToggleCorrectiveActionButton(true);
        _viewTab.delMessage = 'Deleting a consequence will also remove all connected corrective actions!';
        break;
    }
  }

  /**
   * Gets a reject-button for use in the header
   */
  ButtonReject() {
    let btnRj = new eSAMA_Button({
      label: 'Reject',
      icon: 'fa-stop-circle',
      classes: 'adminbg',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnRj.GetDom().on('click', () => this.ConfirmReject());

    return btnRj;
  }

  ConfirmReject() {
    cSAMA_Dialog.ShowTextBox({
      title: 'Reject Report',
      message:
        'If you reject the report, the reporter will be able to edit it further or delete it on their end.<br/>' +
        'You will no longer be able to see or edit it.<br/>' +
        "<b>This will delete all corrective actions that you've already assigned.</b>",
      yesFunc: (_comment) => {
        coSAMA_CommonReports.NotifyReportMinimal({
          operand: 'rejected',
          data: {
            reportPk: this.report.GetPk(),
            comment: _comment,
          },
        });
      },
    });
  }

  /**
   * Gets an approve-button for use in the header
   */
  ButtonApprove() {
    let btnAppr = new eSAMA_Button({
      label: 'Approve',
      icon: 'fa-dot-circle',
      classes: 'adminbg',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnAppr.GetDom().on('click', () => this.ApproveReport());

    return btnAppr;
  }

  ButtonSubmit() {
    let btnSub = new eSAMA_Button({
      label: 'Submit',
      icon: 'fa-check',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnSub.GetDom().on('click', () => this.ConfirmSubmit());

    return btnSub;
  }

  ConfirmSubmit() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Submit Report',
      message:
        'You will no longer be able to edit this report unless you are ' +
        'responsible for its approval or the responsible person rejects it. <br>' +
        'Do you want to submit it now?',
      yesFunc: () => {
        coSAMA_CommonReports.NotifyReportMinimal({
          operand: 'submitted',
          data: {
            reportPk: this.report.GetPk(),
          },
        });
      },
    });
  }

  ApproveReport() {
    if (this.report.GetNumActiveConsequences() <= 0) {
      this.NotifyHasNoCONs();
      return;
    }

    if ($('.sama-form-error').length > 0) {
      cSAMA_Dialog.ShowDialog({
        title: "Can't submit",
        message: 'There are errors in this form. Please fix them before submitting.',
      });
      return;
    }

    let allCorrAct = true;
    let consTabs = this.viewConsTabs.GetTabs();
    for (let c = 0; c < consTabs.length; c++) {
      if (!consTabs[c].GetModel().HasCorrectiveAction()) {
        allCorrAct = false;
        consTabs[c].ErrorInModal('mca', true);
      }
    }

    if (!allCorrAct) {
      this.NotifyHasNoCOAs();
      return;
    }

    this.ConfirmApprove();
  }

  NotifyHasNoCONs() {
    cSAMA_Dialog.ShowDialog({
      title: 'No Consequences',
      message: 'Please make sure to add at least one consequence and a corrective action to this report.',
      closeFn: () => {
        $('html, body').animate(
          {
            scrollTop: $('#sama_rep_estimation_end').offset().top,
          },
          0
        );
      },
    });
  }

  NotifyHasNoCOAs() {
    cSAMA_Dialog.ShowDialog({
      title: 'Corrective Actions',
      message:
        'Please make sure to create a corrective action for all<br/>' +
        'consequences before approving this report.<br/>',
      closeFn: () => {
        $('html, body').animate(
          {
            scrollTop: $('#sama_rep_estimation_end').offset().top,
          },
          0
        );
      },
    });
  }

  NotifyHasNoTags() {
    $('html, body').animate(
      {
        scrollTop: $('#sama_hReportKeywords').offset().top,
      },
      0
    );

    cSAMA_Dialog.ShowConfirm({
      title: 'Report has no Keywords',
      message:
        'Please consider adding keywords to this report.<br/>' +
        'They make it easy to find, monitor and group issues.<br/>' +
        'Do you want to add tags now?',
      yesFunc: () => {
        this.notifiedOfNoTags = 1;
      },
      noFunc: () => {
        this.notifiedOfNoTags = 0;
        this.SaveHandler();
      },
    });
  }

  ConfirmApprove() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Approve Report',
      message:
        'This will expose the report to all staff.<br/>' +
        'The reporter and all the assignees will be informed.<br/>' +
        '<b>After this step, the report can no longer be rejected!</b>',
      yesFunc: () => {
        coSAMA_CommonReports.NotifyReportMinimal({
          operand: 'approved',
          data: {
            reportPk: this.report.GetPk(),
          },
          backAfter: true,
        });
      },
    });
  }

  /**
   * Override local Pks with the ones from the server
   * @param {{keywords:object, photos:boolean|int[], report:int, cons:int[], rfvals:int[], cfvals:[] }}_payload
   */
  UpdatePks(_payload) {
    this.viewReportsEditor.SetRemoteKeys(_payload.keywords, _payload.photos, _payload.files);

    if (_payload.report['duplicateId'] > 0) {
      cSAMA_Dialog.ShowDialog({
        title: 'ID Duplicated',
        message:
          'This Report ID is being used by a different report already.<br/>' +
          'If this is not what you intend, you should change the ID and re-save the report.',
      });
    }

    // Report
    if (this.report.GetPk() < 0) {
      this.report.SetPk(_payload.report['pk']);
      this.report.SetModified(false);
    }

    let consequences = this.report.GetConsequences();
    for (let i = 0; i < consequences.length; i++) {
      // Consequences of report
      let con = consequences[i];
      pSAMA_ReportsEdit.InnerAssignPks(con, _payload['cons']);
      if (con.HasCorrectiveAction() && _payload['corac'] != null) {
        const newOPks = _payload['corac'];
        const cap = con.GetCorrectiveAction();

        if (newOPks.hasOwnProperty(cap.GetPk())) {
          if (newOPks[cap.GetPk()]['duplicateId'] > 0) {
            cSAMA_Dialog.ShowDialog({
              title: 'CAP ID Duplicated',
              message:
                'ID [' +
                cap.sync.coa_groupId +
                '] for the Corrective Action ' +
                cap.sync.coa_name +
                ' is being used by a different corrective action already. ' +
                'If this is not what you intend, you should change the ID.',
            });
          }
          cap.SetPk(newOPks[cap.GetPk()]['newPk']);
          cap.SetModified(false);
        }

        if (cap.sync.coa_groupId.length <= 0) {
          cap.sync.coa_groupId = cap.GetPk();
        }
      }

      let riskFactorValues = con.GetRiskFactorValues();
      for (let f = 0; f < riskFactorValues.length; f++) {
        // Risk factors of consequence
        let rfVal = riskFactorValues[f];
        pSAMA_ReportsEdit.InnerAssignPks(rfVal, _payload['rfvals']);

        let customFieldValues = rfVal.GetCustomFieldValues();
        for (let c = 0; c < customFieldValues.length; c++) {
          pSAMA_ReportsEdit.InnerAssignPks(customFieldValues[c], _payload['cfvals']);
        }
      }
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  /**
   * Wraps the actions to assign a new Pk
   * @param {mSAMA_AbstractModel} _element
   * @param {int[]} _newPks
   */
  static InnerAssignPks(_element, _newPks) {
    if (_newPks.hasOwnProperty(_element.GetPk())) {
      _element.SetPk(_newPks[_element.GetPk()]);
      _element.SetModified(false);
    }
  }

  /**
   * Save handler
   */
  SaveHandler() {
    let reportData = this.report.GetTransmissible();

    let currentDepartment = this.viewReportsEditor.GetSelectedDepartment();
    let initialDepartment = this.report.GetInitialDepartment();

    if (
      (this.permissions === SAMA.enums.ACCESS.REVIEW || this.permissions === SAMA.enums.ACCESS.REVIEW) &&
      this.reassignConfirmResult === 0 &&
      currentDepartment !== initialDepartment
    ) {
      cSAMA_Dialog.ShowConfirm({
        title: 'Really Reassign Report?',
        message:
          'If you change the department, the report will be saved and you will not be ' +
          "able to edit it any further unless you are in control of the department you've selected.",
        yesFunc: () => {
          this.reassignConfirmResult = 1;
          this.report.sync.depChange = true;
          this.SaveHandler();
        },
        noFunc: () => {
          this.reassignConfirmResult = 0;
          this.viewReportsEditor.SetDepartment(initialDepartment);
        },
      });

      // Because simple dialog is non-blocking, we gotta stop saving but come back to it.
      return;
    }

    let r = new cSAMA_Request('Reports', 'setData');
    let photos = this.viewReportsEditor.GetSelectedPhotos();
    let deletedPhotos = [];
    let photoDescriptions = {};

    for (let p = 0; p < photos.length; p++) {
      let photo = photos[p];
      if (!photo.deleted && photo.uid < 0 && !photo.preloaded) {
        // Submit new photos that were not deleted locally or came from the server
        r.AddFile('p|' + photo.uid, photo.file);
      } else if (photo.deleted && photo.uid > 0) {
        // Submit photos that were deleted locally but loaded from the server previously or stored & deleted in the
        // same session
        deletedPhotos.push(photo.uid);
      }

      if (photo.descriptionChanged === true) {
        photoDescriptions[photo.uid] = photo.description;
      }
    }

    let reportFiles = this.viewReportsEditor.GetSelectedFiles();
    let deletedReportFiles = [];
    let fileNames = {};

    for (let f = 0; f < reportFiles.length; f++) {
      const file = reportFiles[f];

      if (file.deleted && file.uid > 0) {
        deletedReportFiles.push(file.uid);
      } else if (!file.deleted && file.uid < 0 && !file.preloaded) {
        fileNames[file.uid] = file.name;
        r.AddFile('f|' + file.uid, file.data);
      }
    }

    let keywords = this.viewReportsEditor.GetSelectedKeywords();

    const isAdmin = [SAMA.enums.ACCESS.REVIEW, SAMA.enums.ACCESS.REVIEW_WITH_GRANT].indexOf(this.permissions) > -1;

    if (isAdmin && keywords.length <= 0 && this.notifiedOfNoTags === -1) {
      this.NotifyHasNoTags();
      return;
    }

    r.Set('report', reportData);
    r.Set('keywords', keywords);

    r.Set('deletedPhotos', deletedPhotos);
    r.Set('photoDescriptions', photoDescriptions);

    r.Set('deletedFiles', deletedReportFiles);
    r.Set('fileNames', fileNames);

    cSAMA_Request.Dispatch({
      name: 'SaveReport',
      reqs: r,
      clientErrorCode: 'SAMA 59 CE 83 DA',
      fnSuccess: (_r) => this.AfterSave(_r),
      goBackOnError: false,
      keepStateOnError: true,
    });
  }

  AfterSave(_payload) {
    this.UpdatePks(_payload[0]);

    if (this.reassignConfirmResult === 1) {
      SAMA.Navigator.GoBack();
    }
  }

  /**
   * Function called when the user leaves the page.
   * @override
   */
  LeavePage() {
    SAMA.Navigator.FinishedLoading();

    if (this.resLock != null) {
      this.resLock.StopLocking();
    }

    this.dom.reportData.hide();

    if (this.loaded === false) {
      // Looks like the page load failed.
      return;
    }

    $('body').off('.samaevt');

    if (this.viewConsTabs != null) {
      this.viewConsTabs.Destroy();
      this.viewConsTabs = null;
    }

    if (this.viewReportsEditor != null) {
      this.viewReportsEditor.Destroy();
      this.viewReportsEditor = null;
    }

    if (this.viewRiskMatrix != null) {
      this.viewRiskMatrix.Destroy();
      this.viewRiskMatrix = null;
    }

    if (this.viewTimeLine != null) {
      this.viewTimeLine.Destroy();
      this.viewTimeLine = null;
    }

    this.riskMatrix = null;
    this.report = null;
    this.events = null;
  }

  GuiTypeChange() {
    if (this.report.IsProactive()) {
      this.header.SetTitle('Hazard Report');
      this.viewReportsEditor.GuiSetUpForProactive();
      return;
    }

    this.header.SetTitle('Occurrence Report');
    this.viewReportsEditor.GuiSetUpForReactive();
  }
}
