class coSAMA_CorrectiveActions {
  static ButtonComplete(_coaPk, _fnReload) {
    let btnCompl = new eSAMA_Button({
      label: 'Complete',
      icon: 'fa-check',
      classes: 'greenbg',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnCompl.GetDom().on('click', () => coSAMA_CorrectiveActions.ConfirmComplete(_coaPk, _fnReload));
    return btnCompl;
  }

  static ButtonClose(_coaPk, _fnReload) {
    let btnClose = new eSAMA_Button({
      label: 'Close',
      icon: 'fa-lock',
      classes: 'greenbg',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnClose.GetDom().on('click', () => coSAMA_CorrectiveActions.ConfirmClose(_coaPk, _fnReload));

    return btnClose;
  }

  static ButtonReOpen(_coaPk, _fnReload) {
    let btnReOpen = new eSAMA_Button({
      label: 'Re-Open',
      icon: 'fa-bullhorn',
      classes: 'adminbg',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnReOpen.GetDom().on('click', () => coSAMA_CorrectiveActions.ConfirmReOpen(_coaPk, _fnReload));
    return btnReOpen;
  }

  static ConfirmComplete(_coaPk, _fnReload) {
    cSAMA_Dialog.ShowTextBox({
      title: 'Really Complete?',
      message:
        "Responsible Staff will be informed. Please give a short report on the actions you've taken if they are different from the resolution.",
      yesFunc: (_text) => coSAMA_CorrectiveActions.PerformMiniOp('complete', _text, _coaPk, _fnReload),
    });
  }

  static ConfirmClose(_coaPk, _fnReload) {
    cSAMA_Dialog.ShowConfirm({
      title: 'Really Close?',
      message:
        'Responsible Staff will be informed. Closing a corrective action will prevent any further edits. It can also not be reopened.',
      yesFunc: (_text) => coSAMA_CorrectiveActions.PerformMiniOp('close', null, _coaPk, _fnReload),
    });
  }

  static ConfirmReOpen(_coaPk, _fnReload) {
    cSAMA_Dialog.ShowTextBox({
      title: 'Re-Open Corrective Action?',
      message: 'Please leave a comment as to why the corrective action is being re-opened.',
      yesFunc: (_text) => coSAMA_CorrectiveActions.PerformMiniOp('reopen', _text, _coaPk, _fnReload),
    });
  }

  static PerformMiniOp(_operand, _comment, _coaPk, _fnReload) {
    let r = new cSAMA_Request('CorrectiveActions', 'miniOp');
    r.Set('corrActPk', _coaPk);
    r.Set('operand', _operand);
    r.Set('comment', _comment);

    cSAMA_Request.Dispatch({
      name: 'Coa' + _operand,
      reqs: r,
      clientErrorCode: 'SAMA 78 C4 BD F0',
      fnSuccess: () => {
        cSAMA_Dialog.ShowDialog({
          title: 'Success!',
          message: 'The corrective action was updated sucessfully.',
          closeFn: () => {
            if (_fnReload !== null) {
              _fnReload();
            }
          },
        });
      },
      goBackOnError: false,
    });
  }
}
