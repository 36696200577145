/**
 *
 */
class PointOfInterestSearchIndexTable extends AbstractDatabaseTable {
  constructor() {
    super('SearchIndex', 'Name');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('POI_Index.mbtiles');
  }

  /**
   * This table is a mystery...
   * @returns {string}
   */
  tableCreationQuery() {
    //#region return ...
    return `select true;`;
    //#endregion
  }
}
