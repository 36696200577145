/**
 * This snippet is used to build the 'Fit to Fly' table
 */
class FitToFlyReportBuilder {
  /**
   *
   * Originally 'getCert_CurrencyHTML'
   * @param Memos
   * @param Certs
   * @param checkDate
   * @returns {{html: string, expiredCert: (boolean)}}
   */
  static build(Memos = [], Certs = [], checkDate = null) {
    let html = '';

    Certs = Certs ? Certs : [];
    Memos = Memos ? Memos : [];

    const certInfo = FitToFlyReportBuilder.buildTableForList(iTrans('Certificates'), Certs, checkDate);
    const memoInfo = FitToFlyReportBuilder.buildTableForList(iTrans('Memos'), Memos, checkDate);

    return {
      html: html + memoInfo.html + certInfo.html,
      expiredCert: certInfo.expired || memoInfo.expired,
    };
  }

  /**
   * Check if the certificate is expired
   * originally `CheckCert_Currency`
   * @param {string} issue
   * @param {string} expiry
   * @param {Date} checkDate
   * @returns {boolean}
   */
  static isCurrent(issue, expiry, checkDate = null) {
    if (issue == null) {
      return false;
    }

    if (expiry == null) {
      // If the issue date is not null, but the expiry date is null,
      // the cert is valid forever
      return true;
    }

    const expyDate = new Date(expiry);
    expyDate.setHours(0);
    expyDate.setMinutes(0);
    expyDate.setSeconds(0);

    let now;
    if (checkDate != null) {
      now = checkDate;
    } else {
      now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setMilliseconds(0);
    }

    return now <= expyDate;
  }

  /**
   *
   * Originally `makeCurrencyHtml`
   *
   * @param title
   * @param listing
   * @param checkDate
   * @returns {{expired: boolean, html: string}}
   */
  static buildTableForList(title, listing, checkDate) {
    let html = '';
    let expired = false;

    listing.sort((a, b) => {
      if (a.certifiable_name < b.certifiable_name) {
        return -1;
      }
      if (a.certifiable_name > b.certifiable_name) {
        return 1;
      }
      return 0;
    });

    if (listing.length <= 0) {
      return { html, expired };
    }

    html += `<table class="limit-table">
      <thead>
        <tr>
          <th>${title}</th>
          <th>${iTrans('Since')}</th>
          <th>${iTrans('Valid Until')}</th>
        </tr>
      </thead>
      <tbody>`;

    for (let cert of listing) {
      const isCurrent = FitToFlyReportBuilder.isCurrent(cert.cert_valid_from, cert.cert_valid_till, checkDate);

      if (isCurrent) {
        continue;
      }

      expired = true;
      if (cert.cert_valid_from != null) {
        html += `<tr class="redbg">
                  <td>${cert.certifiable_name}</td>
                  <td>${cert.cert_valid_from.substring(0, 10)}</td>
                  <td>${cert.cert_valid_till.substring(0, 10)}
                    <strong>${iTrans('Expired')}</strong>
                  </td>
                </tr>`;
      } else {
        html += `<tr class="redbg">
                    <td>${cert.certifiable_name}</td>
                    <td>
                      <strong>${iTrans('Not Completed')}</strong>
                    </td>
                    <td>&nbsp;</td>
                  </tr>`;
      }
    }

    html += '</tbody></table>';

    return { html, expired };
  }
}
