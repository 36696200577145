/**
 *
 */
class ObjectivesTable extends AbstractDatabaseTable {
  constructor() {
    super('Objectives', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"  text primary key,
            "ADMIN_ONLY"  integer,
            "Associated"  text,
            "Job_Text"    text,
            "Status"      text,
            "description" text,
            "TYPE"        text,
            "Client_Text" text,
            "end"         text,
            "group"       integer,
            "start"       text
        )`;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const isForSync = values[0] === 'SYNC';

    if (isForSync) {
      columns = ['PrimaryKey'];
      values = null;
    }

    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    if (isForSync) {
      const result = {};
      for (let row of rows) {
        result['PK' + row.PrimaryKey] = row;
      }

      return result;
    }

    const parsed = [];
    for (let row of rows) {
      row.UNIT = '';
      row.Associated = {
        BookedAC: [],
        BookedV: [],
        BookedStaff: [],
        Attachments: [],
        HistoryList: [],
        LastEdit: {},
        MultiBooking: '',
        Reminder: 0,
        Extra: {
          Base: '',
          LockCrew: '',
          LockAC: '',
        },
        Forms: [],
        FlightReports: [],
      };

      parsed.push(row);
    }

    return parsed;
  }
}
