let SAMA_SetUp = () => {
  if (SAMA.setup === true) {
    return;
  }

  // Determines the load order of classes
  SAMA.Templates = new cSAMA_TemplateManager();
  SAMA.PageHeader = null; // Gets set on every SAMA page.
  SAMA.GeneralGui = new cSAMA_GeneralGui();
  SAMA.Navigator = new cSAMA_Navigator();

  // jQuery Defaults
  // region CatTagger
  $.fn.catTagger.defaults.remote.method = 'POST';
  $.fn.catTagger.defaults.remote.timeout = 600000;
  $.fn.catTagger.defaults.useIDs = true;
  $.fn.catTagger.defaults.remote.payloadScope = 'Payload';
  $.fn.catTagger.defaults.remote.url = cSAMA_Utils.GetAjaxPage();
  $.fn.catTagger.defaults.messages.tag = 'keyword';
  $.fn.catTagger.defaults.lengths.cats.maxChars = 15;
  $.fn.catTagger.defaults.lengths.tags.maxChars = 60;
  // endregion

  // Determines the load order of pages
  SAMA.pages.NotInSama = new pSAMA_GenericView('NotInSama', null, '');
  SAMA.pages.MenuHome = new pSAMA_GenericView('SAMA_Home', SAMA.pages.NotInSama, 'Home', null, 'SMS');
  SAMA.pages.MenuAdmin = new pSAMA_GenericView('SAMA_AD_HomeMenu', SAMA.pages.NotInSama, 'Administration', null, 'SMS');

  SAMA.pages.CorrectiveActionsList = new pSAMA_CorrectiveActionsList();
  SAMA.pages.CorrectiveActionsView = new pSAMA_CorrectiveActionsEdit();

  SAMA.pages.ReportsList = new pSAMA_ReportsList();
  SAMA.pages.ReportsEdit = new pSAMA_ReportsEdit();

  SAMA.pages.PolicyObjectivesEditor = new pSAMA_PolicyObjectivesEditor();
  SAMA.pages.PolicyObjectivesView = new pSAMA_PolicyObjectivesView();

  SAMA.pages.DepartmentEditor = new pSAMA_DepartmentEditor();
  SAMA.pages.TrendsView = new pSAMA_TrendsView();
  SAMA.pages.TrendsEdit = new pSAMA_TrendMonRuleEdit();
  SAMA.pages.PreferencesEditor = new pSAMA_PreferencesEditor();
  SAMA.pages.RiskMatrixEditor = new pSAMA_RiskMatrixEditor();

  SAMA.Navigator.SetInitPage(SAMA.pages.NotInSama);
  SAMA.setup = true;

  // Identify the environment when not in produciton mode
  try {
    if (window.APP_ENV_BANNER != null) {
      const envFlag = document.createElement('div');
      envFlag.classList.add('environment-flag');
      envFlag.innerHTML = window.APP_ENV_BANNER;
      document.body.appendChild(envFlag);
    }
  } catch (_) {}

  $('body').trigger('samaReady');
};
