/**
 * Class handling the display and real-time modification of SAMA consequences in TABs
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_TabConsequence extends vSAMA_AbstractTab {
  /**
   * @param {mSAMA_Consequence} _consequence
   * @param {mSAMA_RiskMatrix}  _riskMatrix
   * @param {jQuery}            _modalLocation
   * @param {boolean}           _readOnly
   */
  constructor(_consequence, _riskMatrix, _modalLocation, _readOnly) {
    super(_consequence);

    this.errorList = {
      ca: false,
      ra: false,
      mca: false,
    };

    this.dom.modalLocation = _modalLocation;
    this.deleteMessage = 'Do you really want to delete this consequence?';
    this.conModal = new vSAMA_ModalConsequenceEditor(this.model, _riskMatrix, _readOnly);

    this.Observe(this.conModal, 'Close', (_e) => this.ErrorInModal('ra', _e));

    this.conModal.GetDom().prependTo(this.dom.modalLocation);
    this.Observe(this.conModal, 'RiskChange', () => this.UpdateRiskDisplay());

    this.SetUpGui(SAMA.Templates.tpl.consequences.editorRider, SAMA.Templates.tpl.consequences.editorTab, _readOnly);

    this.dom.updateInput = this.dom.body.find('input[type="hidden"]');
    this.dom.corActModalButton = this.dom.body.find('.sama-btn-showCoaModal');
    this.dom.conModalButton = this.dom.body.find('.sama-btn-showConModal');

    this.AddFrequencySelect(_riskMatrix);

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    this.dom.body.enhanceWithin();

    this.dom.conModalButton.on('click', () => {
      this.conModal.Open();
    });

    this.AddRiskFactorValueEditors();
  }

  set delMessage(_delMsg) {
    this.deleteMessage = _delMsg;
  }

  AddRiskFactorValueEditors() {
    let riskFactorValues = this.model.GetRiskFactorValues();

    for (let rfv = 0; rfv < riskFactorValues.length; rfv++) {
      let newEditor = new vSAMA_RiskFactorValueEditor(riskFactorValues[rfv], this.conModal, this.readOnly);

      this.conModal.AddRiskFactorValueEditor(newEditor);
    }
  }

  ErrorInModal(_which, _hasError) {
    this.errorList[_which] = _hasError;

    if (this.errorList.ra === true) {
      this.dom.conModalButton.addClass('sama-form-error');
      this.dom.conModalButton.html('<i class="fal fa-ban"></i> There\'s an error in this Risk Estimation');
    } else {
      this.dom.conModalButton.removeClass('sama-form-error');
      this.dom.conModalButton.html('<i class="fal fa-circle"></i> Risk Estimation');
    }

    if (this.errorList.mca === true) {
      this.dom.corActModalButton.addClass('sama-form-error');
      this.dom.corActModalButton.html('<i class="fal fa-ban"></i> Please create a Corrective Action');
    } else if (this.errorList.ca === true) {
      this.dom.corActModalButton.addClass('sama-form-error');
      this.dom.corActModalButton.html('<i class="fal fa-ban"></i> There\'s an error in this Corrective Action');
    } else {
      this.dom.corActModalButton.removeClass('sama-form-error');
      this.dom.corActModalButton.html('<i class="fal fa-dot-circle-o"></i> Edit Corrective Action</button>');
    }

    if (this.errorList.ra || this.errorList.mca || this.errorList.ca) {
      this.dom.title.addClass('sama-form-error');
    } else {
      this.dom.title.removeClass('sama-form-error');
    }
  }

  /**
   * Show or hide the COA button
   * @param _show
   */
  ToggleCorrectiveActionButton(_show) {
    if (_show) {
      this.dom.corActModalButton.show().on('click', () => {
        this.OnCreateCorrectiveAction();
      });
    } else {
      this.dom.corActModalButton.hide();
    }
  }

  /**
   * Turns the 'create COA' button into a 'view coa' button
   */
  SetCorrectiveActionsViewOnly() {
    this.dom.corActModalButton.html('<i class="fal fa-dot-circle-o"></i> Corrective Action').removeClass('adminbg');
  }

  /**
   * Adds the frequency selector
   */
  AddFrequencySelect(_riskMatrix) {
    let formatted = SAMA.Templates.tpl.consequences.freqDropdown.formatTemplate(_riskMatrix.sync);

    let freqSelect = $(formatted);
    this.dom.body.find('[data-sama-template="frequencySelect-placeholder"]').replaceWith(freqSelect);

    let select = freqSelect.find('select');
    let hint = freqSelect.find('.sama-form-elaboration');

    select
      .val(this.model.sync.con_frequency)
      .on('change', () => {
        hint.find('li.sama-select').removeClass('sama-select');

        hint.find(`li[data-value="${select.val()}"]`).addClass('sama-select');

        this.model.sync.con_frequency = +select.val();
        this.model.UpdateRiskCalculation();

        this.UpdateRiskDisplay();
      })
      .trigger('change');
  }

  UpdateRiskDisplay() {
    let newRisk = this.model.GetRiskScore();

    this.dom.updateInput.val(newRisk.toFixed(2)).trigger('input');

    this.UpdateObservers('RiskChange');
  }

  /**
   * Will create a new corrective action if the consequence doesn't already have one
   * as well as creating and showing its modal editor
   */
  OnCreateCorrectiveAction() {
    this.ErrorInModal('mca', false);
    if (!this.model.HasCorrectiveAction()) {
      let coa = new mSAMA_CorrectiveAction(null, this.model);
      this.model.SetCorrectiveAction(coa);
    }

    if (this.corActModal == null) {
      this.corActModal = new vSAMA_ModalCorrectiveActionsEditor(
        this.model.GetCorrectiveAction(),
        this.conModal,
        this.readOnly
      );
      this.corActModal.GetDom().appendTo(this.dom.modalLocation);
      this.Observe(this.corActModal, 'Close', (_e) => this.ErrorInModal('ca', _e));
    }

    this.corActModal.Open();
  }

  OnDelete() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Delete Consequence',
      message: this.deleteMessage,
      yesFunc: () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        super.OnDelete();
        this.UpdateObservers('RiskChange');
      },
    });
  }

  DestroySilently() {
    if (this.corActModal != null) {
      this.corActModal.Destroy();
    }
    this.conModal.Destroy();
    super.DestroySilently();
  }
}
