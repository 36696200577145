/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
function ExitEditSpot() {
  console.log('ExitSpotEdit');
  $.mobile.changePage($('#Spot_Page'), {
    reverse: true,
    changeHash: false,
  });
  getSpots();
}

function getSpots() {
  $('#SpotList').html('<h3>' + iTrans('Getting Spot Device List') + '</h3>');
  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=getTrackers', function (json_data) {
    if (json_data.ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data.ServerResponse == 'Failed') {
        $('#SpotList').html('');
        $('#SpotList').html('<h3>' + json_data.data + '</h3>');
      }
      if (json_data.ServerResponse == 'Uncaught Exception') ServerResponse(json_data.data, 'getSpots');
      if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data.ServerResponse == 'Success') {
        displaySpots(json_data.data);
      }
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function displaySpots(Spots) {
  $('#SpotList').html('');
  var html = '';
  html +=
    '<div style="width: 100%; overflow: auto"><table id="Tracker_table" data-filter="true" class="first-td-fill">' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Name') +
    '</th>' +
    '<th>' +
    iTrans('Base') +
    '</th>' +
    '<th>' +
    iTrans('Provider') +
    '</th>' +
    '<th>ID/ESN</th>' +
    '<th>' +
    iTrans('Show On Map') +
    '</th>' +
    '<th>' +
    iTrans('Action') +
    '</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody><tr>';
  for (var i in Spots) {
    var I = Spots[i];
    html +=
      '<tr><td>' +
      I.Name +
      '</td><td>' +
      I.Base +
      '</td><td>' +
      I.Provider +
      '</td><td>' +
      I.esn +
      '</td><td>' +
      (I.showOnMap == 1 ? 'Yes' : 'No ') +
      "</td><td><div class='vertical-center-container'><button class='action-btn' onClick='editSpotDevice(" +
      i +
      ")'><img src='./images/edit.svg' /></button><button class='action-btn' onClick='deleteSpotDevice(" +
      I.PrimaryKey +
      ")'><img src='./images/delete.svg' /></button></div></td></tr>";
  }
  html += '</tbody></table>';
  $('#SpotList').html(html);
  $('#SpotList').enhanceWithin();
  SPOT_List = Spots;
}
var SPOT_List;

function deleteSpotDevice(PK) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Delete Tracker Device'),
    headerClose: false,
    buttonPrompt: iTrans('Tracker Device will be permanently Deleted! Are you Sure?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=deleteTracker&PK=' + PK, function (json_data) {
            if (json_data.ServerResponse == 'Success') {
              getSpots();
            }
            if (json_data.ServerResponse == 'Failed') {
              ServerResponse(json_data.data, iTrans('Deleting Tracker'));
            }
          }).fail(function (jqXHR, status, error) {
            ServerResponse(iTrans('Connection Error'), iTrans('Deleting Tracker'));
          });
        },
      },
      [iTrans('CANCEL')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function editSpotDevice(index) {
  $.mobile.changePage($('#Edit_Spot_Page'), {
    changeHash: false,
  });
  $('#EditSpotHeader').html('<h1>' + iTrans('Edit Tracker Device') + '</h1>');
  var S = SPOT_List[index];

  editTrackerDevice(S);
}

function newTrackerDevice() {
  $.mobile.changePage($('#Edit_Spot_Page'), {
    changeHash: false,
  });
  $('#EditSpotHeader').html('<h1>' + iTrans('New Tracker Device') + '</h1>');
  var Item = {
    PrimaryKey: 'NEW',
    group: '',
    Base: '',
    Name: '',
    esn: '',
    Provider: 'InReach',
    showOnMap: 0,
    Username: '',
    Password: '',
  };
  editTrackerDevice(Item);
}

function editTrackerDevice(Item) {
  var html = '';
  html +=
    '<label for="Edit_T_PrimaryKey">' +
    iTrans('Unique ID') +
    '</label><input type="text" id="Edit_T_PrimaryKey" data-mini="true" value="' +
    Item.PrimaryKey +
    '" disabled />';
  html +=
    '<label for="Edit_T_Name">' +
    iTrans('Name') +
    '</label><input type="text" id="Edit_T_Name" data-mini="true" value="' +
    escapeHtml(Item.Name) +
    '" />';
  html +=
    '<div id="Edit_T_UsernameHLDR"><label for="Edit_T_Username">' +
    iTrans('Username') +
    '</label><input type="text" id="Edit_T_Username" data-mini="true" value="' +
    escapeHtml(Item.Username) +
    '" /></div>';
  html +=
    '<label for="Edit_T_Password">' +
    iTrans('Password') +
    ' (' +
    iTrans('Leave Blank If None') +
    ')' +
    '</label><input type="text" id="Edit_T_Password" data-mini="true" value="' +
    escapeHtml(Item.Password) +
    '" />';
  html +=
    '<label for="Edit_T_esn">' +
    iTrans('Tracker ESN or Spot Share ID') +
    '</label><input type="text" id="Edit_T_esn" data-mini="true" value="' +
    escapeHtml(Item.esn) +
    '" />';
  html += '<label for="Edit_T_Base">' + iTrans('Base') + '</label><select id="Edit_T_Base" >';
  html += '<option value="">' + iTrans('N/A') + '</option>';
  for (var i in GROUP_DATA.Bases) {
    html +=
      '<option value="' +
      escapeHtml(GROUP_DATA.Bases[i].name) +
      '">' +
      escapeHtml(GROUP_DATA.Bases[i].name) +
      '</option>';
  }
  html += '</select>';

  html +=
    '<label for="Edit_T_showOnMap">' +
    iTrans('Show On Map') +
    '</label><input type="checkbox" id="Edit_T_showOnMap" data-mini="true" ' +
    (Item.showOnMap == 1 ? 'checked' : '') +
    '/>';

  html +=
    '<label for="Edit_T_Provider">' +
    iTrans('GPS Tracker Type') +
    '</label><select id="Edit_T_Provider" data-mini="true" onChange="AdminTrackerTypeChange()">';
  html += '<option value="InReach">InReach</option>';
  html += '<option value="SPOT">Spot</option>';
  html += '</select>';

  html +=
    '<button data-mini="true" id="Edit_T_SaveBTN" class="greenbg" data-icon="check" onClick="saveTrackerDevice()">' +
    iTrans('Save Device') +
    '</button>';

  $('#TrackerEditHLDR').html(html);
  $('#TrackerEditHLDR').enhanceWithin();
  $('#Edit_T_Base').val(Item.Base).selectmenu('refresh');
  $('#Edit_T_Provider').val(Item.Provider).selectmenu('refresh');
  AdminTrackerTypeChange();
}

function AdminTrackerTypeChange() {
  if ($('#Edit_T_Provider').val() == 'SPOT') {
    $('#Edit_T_UsernameHLDR').hide();
  } else {
    $('#Edit_T_UsernameHLDR').show();
  }
}

function saveTrackerDevice() {
  $('#Edit_T_SaveBTN').prop('disabled', true);
  var Tracker = {
    PrimaryKey: $('#Edit_T_PrimaryKey').val(),
    Base: $('#Edit_T_Base').val(),
    Name: $('#Edit_T_Name').val(),
    Username: $('#Edit_T_Username').val(),
    Password: $('#Edit_T_Password').val(),
    esn: $('#Edit_T_esn').val(),
    Provider: $('#Edit_T_Provider').val(),
    showOnMap: $('#Edit_T_showOnMap').is(':checked') ? 1 : 0,
  };

  $.mobile.loading('show', { theme: 'a' });
  $.post(
    BASEURL + 'menu-Tracker-AJAX.php?action=editTracker',
    {
      data: Tracker,
    },
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.ServerResponse == 'Uncaught Exception') {
        $('#Edit_T_SaveBTN').prop('disabled', false);
        AJAXFAIL(json_data.data);
      }
      if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data.ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Successfully Added/Updated'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //acknolwedge
                ExitEditSpot();
              },
            },
          },
        });
      }
      if (json_data.ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Message'),
          headerClose: false,
          buttonPrompt: json_data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //acknolwedge
                $('#Edit_T_SaveBTN').prop('disabled', false);
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $.mobile.loading('hide');
    $('#Edit_T_SaveBTN').prop('disabled', false);
    AJAXFAIL(error);
  });
}
