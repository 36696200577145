function PULSAR_GetScheduleBubbleInfo(data, divID) {
  var User = {
    PrimaryKey: 0,
    chatname: '',
    StaffType: 'Pilot',
  };
  for (var i in data.raw.Associated.BookedStaff) {
    if (data.raw.Associated.BookedStaff[i].chatname == data.resource) {
      User = data.raw.Associated.BookedStaff[i];
      break;
    }
  }
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'API-Pulsar-FRMS-AJAX.php?action=FRMS&startDate=' +
        encodeURIComponent(data.raw.start) +
        '&endDate=' +
        encodeURIComponent(data.end) +
        '&BookingPK=' +
        data.raw.PrimaryKey +
        '&UserPK=' +
        User.PrimaryKey +
        '&UserType=' +
        User.StaffType +
        '&chatname=' +
        encodeURIComponent(User.chatname) +
        '&timezone=' +
        SCHSTATE.timezone,
      function (json_data) {
        if (json_data.status == 'success') {
          var F = {
            fatigue: 0,
            fatigue_toc: 0,
          };
          if (json_data.results.length > 0) {
            F = json_data.results[json_data.results.length - 1];
          }
          var link = json_data.images[0].url;
          $('#' + divID).html(
            '<b>Fatigue: </b>' +
              PULSAR_CheckFatigueWarning(F.fatigue) +
              '<br /><b>Fatigue TOC: </b>' +
              PULSAR_CheckFatigueWarning(F.fatigue_toc) +
              "<br /><a href='" +
              link +
              "' target='_blank'>View Fatigue Meter</a>"
          );
        } else {
          $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
        }
      }
    ).fail(function (jqXHR, status, error) {
      $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
    });
  } else {
    $('#' + divID).html('<i>OFFLINE Cannot Retrieve FRMS data</i>');
  }
}

function PULSAR_Get_Date(date, divID, graph = false) {
  var date = date.toISOString();
  resetITIN_FRMS();

  var UserPK = USER_RIGHTS.PrimaryKey;
  var chatname = USER_RIGHTS.chatname;
  var staffType = USER_RIGHTS.staffType;
  if (FDT_ADMIN_UPDATE) {
    UserPK = ADMIN_FDT_DATA.CurUser.PrimaryKey;
    chatname = ADMIN_FDT_DATA.CurUser.chatname;
    staffType = ADMIN_FDT_DATA.CurUser.staffType;
  }
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'API-Pulsar-FRMS-AJAX.php?action=FRMS&startDate=' +
        encodeURIComponent(date.substr(0, 10) + ' ' + date.substr(11, 8)) +
        '&endDate=' +
        encodeURIComponent(date.substr(0, 10) + ' ' + date.substr(11, 8)) +
        '&BookingPK=' +
        0 +
        '&UserPK=' +
        UserPK +
        '&UserType=' +
        staffType +
        '&chatname=' +
        encodeURIComponent(chatname) +
        '&timezone=' +
        0,
      function (json_data) {
        ITIN_FRMS = json_data;
        if (json_data.status == 'success') {
          var F = {
            fatigue: 0,
            fatigue_toc: 0,
          };
          if (json_data.results.length > 0) {
            F = json_data.results[json_data.results.length - 1];
          }
          var link = json_data.images[0].url;
          if (graph) {
            $('#' + divID).html(
              '<b>Fatigue: </b>' +
                PULSAR_CheckFatigueWarning(F.fatigue) +
                '<br /><b>Fatigue TOC: </b>' +
                PULSAR_CheckFatigueWarning(F.fatigue_toc) +
                "<br /><div style='min-width:800px; overflow-x:scroll;'><img width='800' src='" +
                link +
                "'/></div>"
            );
          } else {
            $('#' + divID).html(
              '<b>Fatigue: </b>' +
                PULSAR_CheckFatigueWarning(F.fatigue) +
                '<br /><b>Fatigue TOC: </b>' +
                PULSAR_CheckFatigueWarning(F.fatigue_toc) +
                "<br /><a href='" +
                link +
                "' target='_blank'>View Fatigue Meter</a>"
            );
          }
        } else {
          $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
        }
      }
    ).fail(function (jqXHR, status, error) {
      $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
    });
  } else {
    $('#' + divID).html('<i>OFFLINE Cannot Retrieve FRMS data</i>');
  }
}

function PULSAR_GetScheduleStaffBooked(BookedStaff, divBaseID, index) {
  if (index != BookedStaff.length) PULSAR_GetScheduleStaffBooked_INDEX(BookedStaff, divBaseID, index);
}

function PULSAR_GetScheduleStaffBooked_INDEX(BookedStaff, divBaseID, index) {
  var divID = divBaseID + index;
  var User = BookedStaff[index];
  index++;
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'API-Pulsar-FRMS-AJAX.php?action=FRMS&startDate=' +
        encodeURIComponent(User.start) +
        '&endDate=' +
        encodeURIComponent(User.end) +
        '&BookingPK=' +
        User.Booking_ID +
        '&UserPK=' +
        User.PrimaryKey +
        '&UserType=' +
        User.StaffType +
        '&chatname=' +
        encodeURIComponent(User.chatname) +
        '&timezone=' +
        SCHSTATE.timezone,
      function (json_data) {
        if (json_data.status == 'success') {
          var F = {
            fatigue: 0,
            fatigue_toc: 0,
          };
          if (json_data.results.length > 0) {
            F = json_data.results[json_data.results.length - 1];
          }
          var link = json_data.images[0].url;
          $('#' + divID).html(
            '<b>Fatigue: </b>' +
              PULSAR_CheckFatigueWarning(F.fatigue) +
              '<br /><b>Fatigue TOC: </b>' +
              PULSAR_CheckFatigueWarning(F.fatigue_toc) +
              "<br /><a href='" +
              link +
              "' target='_blank'>View Fatigue Meter</a>"
          );
        } else {
          $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
        }
        PULSAR_GetScheduleStaffBooked(BookedStaff, divBaseID, index);
      }
    ).fail(function (jqXHR, status, error) {
      $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
      PULSAR_GetScheduleStaffBooked(BookedStaff, divBaseID, index++);
    });
  } else {
    $('#' + divID).html('<i>OFFLINE Cannot Retrieve FRMS data</i>');
    PULSAR_GetScheduleStaffBooked(BookedStaff, divBaseID, index++);
  }
}

function PULSAR_GetScheduleStaff_FRMS(User, divID, initExpandableID = false) {
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'API-Pulsar-FRMS-AJAX.php?action=FRMS&startDate=' +
        encodeURIComponent(User.start) +
        '&endDate=' +
        encodeURIComponent(User.end) +
        '&BookingPK=' +
        User.Booking_ID +
        '&UserPK=' +
        User.PrimaryKey +
        '&UserType=' +
        User.StaffType +
        '&chatname=' +
        encodeURIComponent(User.chatname) +
        '&timezone=' +
        SCHSTATE.timezone,
      function (json_data) {
        if (json_data.status == 'success') {
          var F = {
            fatigue: 0,
            fatigue_toc: 0,
          };
          if (json_data.results.length > 0) {
            F = json_data.results[json_data.results.length - 1];
          }
          var link = json_data.images[0].url;
          var html =
            '<h3>PULSAR Fatigue Risk Management</h3><table class="limit-table"><thead><tr><th>Name</th><th>' +
            iTrans('Fatigue Score') +
            '</th><th>' +
            iTrans('Top Of Curve') +
            '</th><th>' +
            iTrans('Link') +
            '</th></tr></thead><tbody>';
          html +=
            '<tr><td>' +
            F.name +
            '</td><td>' +
            PULSAR_CheckFatigueWarning(F.fatigue) +
            '</td><td>' +
            PULSAR_CheckFatigueWarning(F.fatigue_toc) +
            '</td><td>' +
            "<a href='" +
            link +
            "' target='_blank'>" +
            iTrans('View Fatigue Meter') +
            '</a>' +
            '</td></tr></tbody></table>';

          $('#' + divID).html(html);
          if (initExpandableID !== false) {
            InitCollapsables(initExpandableID);
          }
        } else {
          $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
          if (initExpandableID !== false) {
            InitCollapsables(initExpandableID);
          }
        }
      }
    ).fail(function (jqXHR, status, error) {
      $('#' + divID).html('<i>Failed to Retrieve FRMS data</i>');
      if (initExpandableID !== false) {
        InitCollapsables(initExpandableID);
      }
    });
  } else {
    $('#' + divID).html('<i>OFFLINE Cannot Retrieve FRMS data</i>');
    if (initExpandableID !== false) {
      InitCollapsables(initExpandableID);
    }
  }
}

function PULSAR_CheckFatigueWarning(F) {
  if (F < GROUP_DATA.PULSAR_LOW) return '<span class="greenbg">' + F + ' [Low]</span>';
  if (F >= GROUP_DATA.PULSAR_LOW && F < GROUP_DATA.PULSAR_HIGH)
    return '<span class="orangebg">' + F + ' [Moderate]</span>';
  if (F >= GROUP_DATA.PULSAR_HIGH) return '<span class="redbg">' + F + ' [Elevated]</span>';
}
