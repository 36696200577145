/**
 * General functions for use throughout SAMA
 * @author falko@air-suite.com
 * @copyright (c) 2018 AirSuite, All Rights Reserved.
 */
class cSAMA_Utils {
  /**
   * Gets the ajax target with the baseurl and manifest
   * @return {string}
   */
  static GetAjaxPage() {
    return BASEURL + 'menu-SAMA-AJAX.php';
  }

  /**
   * Escapes regex characters
   * @param _str
   */
  static EscapeRegExp(_str) {
    if (typeof _str !== 'string') {
      return '';
    }

    return _str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
  }

  /**
   * Replace breakline with newline
   * @param _str
   * @returns {string}
   * @constructor
   */
  static ReplaceNewline(_str){
    if(typeof _str !== 'string'){
      _str = String(_str);
    }

    return _str.replace(/<br>/g, '\n');
  }

  /**
   * Decode htmlentities, returns null if _text is null.
   * @param _text
   * @return {string|null}
   */
  static DecodeEntities(_text) {
    if (_text == null) {
      return null;
    }

    let convdiv = $('<div />');
    let r = convdiv.html(_text).text();
    convdiv.remove();
    return r;
  }

  /**
   * Determines if _v is IN ANY WAY interpretable as true
   * @param {*} _v variable to check
   * @return {boolean}
   */
  static IsTruthy(_v) {
    return _v === 1 || _v === '1' || _v === true || _v === 'true';
  }

  /**
   * Get the date and time in a user's desired timezone from a server-side UTC timestamp
   * @param {string|null} _time UTC datetime string
   */
  static getInputDateTimeFromSQLDateTime(_time) {
    return VueBridge.dateTimeUtils.convertUtcToUsersTimezone(_time);
  }

  /**
   * Get the time in a user's desired timezone from a server-side UTC timestamp
   * @param {string|null} _time UTC time string
   */
  static GetInputTimeFromSQLTime(_time) {
    return this.getInputDateTimeFromSQLDateTime(_time)?.format(SAMA.defaults.TimeInputFormat);
  }

  /**
   * Get the local date from a server-side UTC timestamp
   * @param {string|null} _time       UTC time string
   * @param {boolean}    [_asMoment] Gets the moment rather than a formatted string
   */
  static GetInputDateFromSQLDate(_time, _asMoment) {
    const dateTime = this.getInputDateTimeFromSQLDateTime(_time);

    if (_asMoment === true) {
      return dateTime;
    }

    return dateTime?.format(SAMA.defaults.DateInputFormat);
  }

  /**
   * Combines a date and time and gets the moment or an ISO time string
   * @param {string} _date
   * @param {string} [_time] The time part of the stamp. If ommitted, will return the date at midnight.
   * @param {boolean} [_asMoment] Gets the resulting moment rather than a ISO time string
   * @return {string|moment}
   */
  static CombineDateTime(_date, _time, _asMoment) {
    _asMoment = _asMoment == null ? false : _asMoment;

    let m = moment(_date);

    if (_time != null && _time.length > 0) {
      let splitTime = _time.split(':');
      m.hours(+splitTime[0]);
      m.minutes(+splitTime[1]);

      if (splitTime[2] != null) {
        m.seconds(+splitTime[2]);
      }
    } else {
      m.hours(0);
      m.minutes(0);
      m.seconds(0);
    }

    if (!_asMoment) {
      return m.format();
    }

    return m;
  }

  /**
   * Produces a time based, negative Identifier
   * @returns {int}
   */
  static GetUID() {
    return -Math.round(window.performance.now());
  }

  /**
   * Gets the pretty-printed version of _stamp.
   * @param {string}  _stamp
   * @param {boolean} _date   If true, outputs the date portion of the stamp
   * @param {boolean} _time   If true, outputs the time portion of the stamp
   * @param {boolean} _isUTC  If true, assumes that _stamp is in UTC format and that the returned string should be in
   *   local time
   */
  static NiceDate(_stamp, _date, _time, _isUTC) {
    if ((_date == null && _time == null) || (_date === false && _time === false)) {
      _date = true;
      _time = false;
    }

    let m = _isUTC === true ? moment.utc(_stamp).local() : moment(_stamp);

    let fmt = '';

    if (_time === true) {
      fmt = SAMA.defaults.TimePrettyFormat;
    }

    if (_date === true) {
      fmt += _time === true ? ' - ' : '';
      fmt += SAMA.defaults.DatePrettyFormat;
    }

    return m.format(fmt);
  }

  static NiceTimeFromMikeTime(_stamp) {
    _stamp = '' + _stamp;
    let h;
    let m;

    if (_stamp.length === 3) {
      h = _stamp.substr(0, 1);
      m = _stamp.substr(1);
    } else if (_stamp.length === 4) {
      h = _stamp.substr(0, 2);
      m = _stamp.substr(2);
    } else {
      return '--:--';
    }

    let mo = moment.utc();
    mo.hour(h).minute(m);

    return mo.format(SAMA.defaults.TimePrettyFormat + ' (UTC)');
  }

  static EllipseString(_string, _maxLength) {
    if (typeof _string === 'string' && _string.length > _maxLength - 3) {
      return _string.substr(0, _maxLength) + '...';
    }

    return _string;
  }

  /**
   * Indicates if the script is running on a mobile device
   * @returns {boolean}
   */
  static IsMobile() {
    return window.MOBILE;
  }

  /**
   * Locks the clean state when a Tagger instance is loading.
   */
  static WaitForCatTaggerLoad() {
    $('body')
      .on('cttrStarted.samaevt', (_evt) => {
        SAMA.GeneralGui.LockPageState('Tagger', _evt.taggerID);
      })
      .on('cttrReady.samaevt', (_evt) => {
        SAMA.GeneralGui.UnlockPageState(_evt.taggerID);
        _evt.origin.trigger('validate');
      });
  }

  /**
   * Get the scroll position of the html or body element, depending on which is larger
   * @return {int}
   */
  static GetScrollTop() {
    let scrtop = $('html').scrollTop();
    scrtop = scrtop > 0 ? scrtop : $('body').scrollTop();
    return scrtop;
  }

  /**
   * Scrolls the view back to where we left it.
   * @param {int} _height The place to scroll back to
   */
  static ScrollTo(_height) {
    $('html, body').animate(
      {
        scrollTop: _height,
      },
      0
    );
  }

  static ColorShade(_color, _percent) {
    let f = parseInt(_color.slice(1), 16);
    let t = _percent < 0 ? 0 : 255;
    let p = _percent < 0 ? _percent * -1 : _percent;

    let R = f >> 16;
    let G = (f >> 8) & 0x00ff;
    let B = f & 0x0000ff;

    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }

  /**
   * @param {string} _b64 Base64 image
   * @param {string} _type Mime-descriptor for the file (such as image/png)
   * @returns {Blob}
   */
  static Base64ToFile(_b64, _type) {
    let blobBin = atob(_b64.split(',')[1]);
    let buffer = [];
    for (let i = 0; i < blobBin.length; i++) {
      buffer.push(blobBin.charCodeAt(i));
    }
    return new Blob([new Uint8Array(buffer)], {
      type: _type,
    });
  }

  static GetCapTermName(_termId) {
    switch (_termId) {
      case 1:
        return 'Immediate';
      case 2:
        return 'Short term';
      case 3:
        return 'Long term';
      case 4:
        return 'Medium Term';
      case 0:
        return 'Exact Due date';
    }
  }

  /**
   * Create html for the table rows and columns
   * @param _data
   * @param _fixWidthList
   * @param _max
   * @returns {string}
   * @constructor
   */
  static GenerateTableContent(_data, _fixWidthList, _max = 0)
  {
    const $tbody = $('<tbody></tbody>');

    _data.forEach(report =>
    {
      const $row = $('<tr></tr>');

      $.each(report, (key, value) => {
        let $cell;

        if (_fixWidthList.includes(key)) {
          $cell = $('<td></td>')
              .css('white-space', 'nowrap')
              .text(value);
          $row.append($cell);
          return;
        }

        // Handle 'riskFactor' separately
        if (key === 'riskFactor') {
          for (let i = 0; i < _max; i++) {
            $cell = $('<td></td>').text(i < value.length ? value[i].riskFactorValue : '');
            $row.append($cell);
          }
          return;
        }

        if (key === 'photoPks') {
          $cell = $(this.GeneratePhotoUrl(value));
        } else if (key === 'documents') {
          $cell = $(this.GenerateDocumentUrl(value));
        } else {
          $cell = $('<td></td>').text(value);
        }

        $row.append($cell);
      });

      $tbody.append($row);
    });

    return $tbody.html();
  }

  static GeneratePhotoUrl(_photoPks)
  {
    return this.GenerateLinks(
        _photoPks,
        BASEURL,
        'getReportPhoto',
        'photoLink',
        'Image',
        'repPhotoPk',
        'repPhotoName'
    );
  }

  static GenerateDocumentUrl(_files)
  {
    return this.GenerateLinks(
        _files,
        BASEURL,
        'getReportDocument',
        'fileLink',
        '',
        'repDocumentPk',
        'repDocumentName'
    );
  }

  static GenerateLinks(
      dataArray,
      baseUrl,
      actionType,
      idPrefix,
      namePrefix,
      primaryKey,
      nameKey
  )
  {
    const $table = $('<td></td>');

    if (dataArray && dataArray.length > 0)
    {
      dataArray.forEach((item, index) =>
      {
        const linkId = `${idPrefix}_${index}`;
        const href   = `${baseUrl}/menu-SAMA-AJAX.php?action=${actionType}&${primaryKey}=${item[primaryKey]}&${nameKey}=${item[nameKey]}`;

        const $link = $('<a></a>')
            .attr('target', '_blank')
            .attr('href', href)
            .attr('id', linkId)
            .text(item[nameKey]);

        $table.append($link).append('<br>');
      });
    }

    return $table.prop('outerHTML');
  }
}
