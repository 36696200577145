/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var LOW_BANDWIDTH = false;

function ToggleLowBandwidth(e) {
  if (e.checked) {
    LOW_BANDWIDTH = true;
    localStorageDB.setItem('LOW_BANDWIDTH', '1');
    $('#LowBandwidthCHK_TXT').html(iTrans('Low Bandwidth Mode'));
  } else {
    LOW_BANDWIDTH = false;
    localStorageDB.setItem('LOW_BANDWIDTH', '0');
    $('#LowBandwidthCHK_TXT').html(iTrans('Low Bandwidth Mode'));
  }
}

var OfflineItemStatus = {
  Itinerary: 0,
  ItineraryLegData: 0,
  FlightReports: 0,
  Expenses: 0,
};

function CheckOfflineSendItems() {
  try {
    cirroDB.query('Itinerary', 'PrimaryKey != ?', 0, function (e) {
      if (e === false) e = [];
      OfflineItemStatus.Itinerary = e.length;
      if (e.length > 0) WAIT_FOR_OFFLINE_UPLOAD = true;
      UpdateOfflineSendItems();
    });
  } catch (e) {
    OfflineItemStatus.Itinerary = 0;
  }

  try {
    cirroDB.query('WB', 'LegNum != ? ORDER BY LegNum ASC', '', function (e) {
      if (e === false) e = [];
      OfflineItemStatus.ItineraryLegData = e.length;
      if (e.length > 0) WAIT_FOR_OFFLINE_UPLOAD = true;
      UpdateOfflineSendItems();
    });
  } catch (e) {
    OfflineItemStatus.ItineraryLegData = 0;
  }

  BillingDB('SELECT', 'OFFLINE_SYNC', null, function (Reports) {
    if (Reports.length == undefined) OfflineItemStatus.FlightReports = 0;
    else OfflineItemStatus.FlightReports = Reports.length;
    UpdateOfflineSendItems();
  });

  BillingDB('SELECT', 'OFFLINE_SYNC_EXPENSE', null, function (Expenses) {
    if (Expenses.length == undefined) OfflineItemStatus.Expenses = 0;
    else OfflineItemStatus.Expenses = Expenses.length;
    UpdateOfflineSendItems();
  });

  UpdateOfflineSendItems();
}

function UpdateOfflineSendItems() {
  var html = '';
  var Items = 0;
  var I = OfflineItemStatus;
  Items += I.Itinerary;
  Items += I.ItineraryLegData;
  Items += I.FlightReports;
  Items += I.Expenses;

  if (Items > 0) {
    if (Items === 1) {
      html = `${Items} ${iTrans('Offline Item')}`;
    } else {
      html = `${Items} ${iTrans('Offline Items')}`;
    }
    if (ONLINE) {
      html += `<a class="ui-btn ui-corner-all ui-mini" href="#" onClick="SendOfflineItemsNow()">
          ${iTrans('Send Now')}
          </a>`;
    }

    $('#OfflineItemStatusHLDR').html(html);
    $('#offlineItemCountWrapper').show();
  } else {
    $('#offlineItemCountWrapper').hide();
  }
}

function SendOfflineItemsNow() {
  $('#OfflineItemStatusHLDR').html(iTrans('Sending Items') + '...');
  var I = OfflineItemStatus;
  if (I.Itinerary > 0) syncOfflineItinerary();
  if (I.ItineraryLegData > 0) syncOfflineWB();
  if (I.FlightReports > 0 || I.Expenses > 0) BILLING_sync_data();
}
var DAILY_SYNC;
var AJAXPB;

function progressLoader(e) {
  if (e == 0) {
    $(
      [
        '<div id="AJAXPB-overlay" class="ui-jqm-progressbar-overlay" style="z-index:9999; opacity:0.85">',
        '<div class="ui-jqm-progressbar-overlay-box" style="opacity:1;">',
        '<div class="ui-jqm-progressbar-overlay-box-corner-top ui-jqm-progressbar-overlay-box-corner-bottom ui-jqm-progressbar-overlay-box-content ui-jqm-progressbar-overlay-box-body-c" style="opacity:1;">',
        '<center><h3>Please Standby</h3></center>',
        '<center><p>Sending Data To Server...</p></center>',
        '<div id="AJAXprogressbar"></div>',
        '</div>',
        '</div>',
        '</div>',
      ].join('')
    )
      .appendTo($.mobile.pageContainer)
      .show(function () {
        AJAXPB = jQMProgressBar('AJAXprogressbar')
          .setOuterTheme('b')
          .setInnerTheme('e')
          .isMini(true)
          .setMax(100)
          .setStartFrom(0)
          .showCounter(true)
          .isIndefinite(false)
          .build();
      });
  }
  AJAXPB.setValue(e);
  if (e == 100 || e == 'hide') {
    AJAXPB.destroy();
    $('#AJAXPB-overlay').remove();
  }
}

function skipSync() {
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (page == 'Initialization_Page' || page == 'Login_Page') {
    $('#initDialog').text('Preparing');
    setTimeout('setStartupPage()', 2000);
  }
}

function restoreDatabase() {
  $('#skipSync').hide();
  $('#restoreDatabase').prop('disabled', true);
  $('#retrySync').hide();
  $('#initDialog').text('Clearing All Data...');
  clearUserData();
}
var syncTimer;

function SyncToolLong() {
  clearTimeout(syncTimer);
  $('#Status_Text').html('Sync Error!');
  $('#restoreDatabase').show();
  $('#retrySync').show();
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (page == 'Initialization_Page' || page == 'Login_Page') {
    $('<div>').simpledialog2({
      themeDialog: 'a',
      mode: 'button',
      animate: false,
      headerText: 'Sync Warning',
      headerClose: false,
      buttonPrompt:
        'The synchronization process is taking longer than usual.  [Retry Sync] or [Restore Database] options now available. Try restoring the database only if the problem persists.',
      buttons: {
        OK: {
          click: function () {
            //do stuff
          },
        },
      },
    });
  } else {
    $('<div>').simpledialog2({
      themeDialog: 'a',
      mode: 'button',
      animate: false,
      headerText: 'Sync Warning',
      headerClose: false,
      buttonPrompt:
        'The synchronization process is taking longer than usual. Try restoring the database only if the problem persists.',
      buttons: {
        'Wait Longer': {
          click: function () {
            if (SYNCING == true) {
              if (syncTimer != null) clearTimeout(syncTimer);
              syncTimer = setTimeout('SyncToolLong()', 120000);
            }
            //do stuff
          },
        },
        'View Progress': {
          click: function () {
            $.mobile.changePage($('#Initialization_Page'), {
              reverse: true,
              changeHash: false,
            });
          },
        },
        'Retry Sync': {
          click: function () {
            //BackgroundSync();
            restartCirro();
          },
        },
        'Rebuild Database': {
          click: function () {
            restoreDatabase();
          },
        },
        'Stop Sync': {
          click: function () {
            IOS_postMessage({
              Message: 'SyncText',
              text: 'Complete! Launching...',
            });
            IOS_postMessage({
              Message: 'SyncStatus',
              status: 'COMPLETED',
            });
          },
        },
      },
    });
  }
}

function DailySyncBackgroundSync() {
  if (DAILY_SYNC != null) clearTimeout(DAILY_SYNC);
  DAILY_SYNC = setTimeout('DailySyncBackgroundSync()', 86400000);
  if (!LOW_BANDWIDTH) BackgroundSync();
}

var SYNC_FDT_ONLY = false;
var SYNC_FC_ONLY = false;
function BackgroundSync(FDT_ONLY = false, FC_ONLY = false) {
  FDT_MODULE_CALLBACK = null;
  SYNC_FDT_ONLY = FDT_ONLY;
  SYNC_FC_ONLY = FC_ONLY;
  if (To_Login == '1') return; //do not sync if not authenticated
  if (!ONLINE) {
    if (GROUP_DATA.FlightReports == 1 && !BILLING_SCRIPTS_LOADED) BILLING_LoadScripts();
    return;
  }
  if (toasterID_SynchronizationError != null) {
    window.toaster.remove(toasterID_SynchronizationError);
    toasterID_SynchronizationError = null;
  }
  if (SYNCING == false) {
    if (DAILY_SYNC != null) clearTimeout(DAILY_SYNC);
    DAILY_SYNC = setTimeout('DailySyncBackgroundSync()', 86400000);
    if (
      CurPageID() == 'Map_Page' ||
      CurPageID() == 'WB_Page' ||
      CurPageID() == 'WB_DA_Page' ||
      CurPageID() == 'WB_Page2'
    ) {
      if (!FC_ONLY && !FDT_ONLY) return;
    }

    $('#ManualSync').prop('disabled', true);
    $('#LogoutBTN').prop('disabled', true);

    if (syncTimer != null) clearTimeout(syncTimer);
    clearTimeout(DAILY_SYNC);
    syncTimer = null;
    syncTimer = setTimeout('SyncToolLong()', 120000);
    SYNCING = true;
    VueBridge.LegacyAppSyncing(SYNCING);

    if (FC_ONLY) {
      SyncIOS('FCsync');
      return;
    }
    if (FDT_ONLY) {
      $('#syncIndicator').show();
      SyncIOS('FDTsync');
      return;
    }
    if (CERTS == 1) {
      SyncMyReqCerts();
    }
    SyncStaffList();
    syncMbtiles(true);
    //Sync risk forms if the database doesnt exist
    if (RISK_MGMT == 1) {
      cirroDB.query('RiskForms', 'PrimaryKey = ?', 1, function (e) {
        if (e === false || e.length == 0) {
          SYNCING = true;
          VueBridge.LegacyAppSyncing(SYNCING);
          SYSTEM_SYNCING = true;
          SystemSyncError = true; //set to error state unless completed successfully
        }
      });
    }
    if (GROUP_DATA.FlightReports == 1 || GROUP_DATA.FlightReports == 1) BILLING_sync_data(); //Send offline flight reports etc to server
    $('#syncIndicator').show();
    $('#Status_Text').html('Syncing...');
    $('#SyncProgressText').show();

    //browser detection IOS safari does not support IndexedDB inside Web Workers
    var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
    var is_explorer = navigator.userAgent.indexOf('MSIE') > -1;
    var is_firefox = navigator.userAgent.indexOf('Firefox') > -1;
    var is_safari = navigator.userAgent.indexOf('Safari') > -1;
    var is_Opera = navigator.userAgent.indexOf('Presto') > -1;
    if (is_chrome && is_safari) {
      is_safari = false;
    }

    //console.log(navigator.userAgent);
    if (is_safari || window.navigator.standalone) {
      //SYNCING = true;
      $('#cancelSync').hide();
      //console.log("Single Thread Sync Started");
      SyncIOS('FullSync');
    } else {
      $('#skipSync').show();
      $('#retrySync').hide();
      $('#restoreDatabase').hide();
      /*
                if (typeof SyncWorker != 'undefined') SyncWorker.terminate();
                //console.log("Web Worker Background Sync Started");
                create_syncWorker();
                SyncWorker.postMessage({Message:"FullSync"});
                //$("#StopSync").show(); //hide div holding work offline button

                ////console.log("Test IOS Sync Single Thred");
                */
      SyncIOS('FullSync'); //Force IOS sync for missing session variables sent in requests through web workers
    }
  }
}
var SyncWorker;
var LastFDTsync = 'Never';
var LastFCsync,
  LastWPsync,
  LastGROUPsync,
  LastRULEsync,
  LastSYSTEMsync,
  LastSCHEDULEsync,
  LastLIBRARYsync,
  LastMANIFESTsync,
  LastPASSENGERsync;
var SYNCING = false;

function WWfdtContinue() {
  $.mobile.changePage($('#Initialization_Page'), {
    reverse: true,
    changeHash: false,
  });
  $('#CHK_DHC2').unbind('click', changeDefault2);
  $('#CHK_Dual2').unbind('click', changeDefault2);
  $('#CHK_Heli2').unbind('click', changeDefault2);
  $('#CHK_IFR2').unbind('click', changeDefault2);
  $('#CHK_Logging2').unbind('click', changeDefault2);
  $('#CHK_AerialApp2').unbind('click', changeDefault2);
  $('#CHK_Standby2').unbind('click', changeDefault2);
  $('#SELECT_Rule2').unbind('change', changeDefault2);
  $('#SetDutySetup').unbind('click', WWfdtContinue);
  var startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 2);
  var date = startDate.toISOString().substr(0, 10);
  var message = {
    Message: 'FDTcontinue',
    Standby: Standby,
    IFR: IFR,
    Heli: Heli,
    DHC: DHC,
    Dual: Dual,
    Logging: Logging,
    AerialApp: AerialApp,
    Rule_ID: Rule_ID,
    Rule: Rule,
    date: date,
  };
  //console.log(message);
  SyncWorker.postMessage(message);
}

function IOSfdtContinue() {
  $.mobile.changePage($('#Initialization_Page'), {
    reverse: true,
    changeHash: false,
  });
  $('#CHK_DHC2').unbind('click', changeDefault2);
  $('#CHK_Dual2').unbind('click', changeDefault2);
  $('#CHK_Heli2').unbind('click', changeDefault2);
  $('#CHK_IFR2').unbind('click', changeDefault2);
  $('#CHK_Logging2').unbind('click', changeDefault2);
  $('#CHK_AerialApp2').unbind('click', changeDefault2);
  $('#CHK_Standby2').unbind('click', changeDefault2);
  $('#SELECT_Rule2').unbind('change', changeDefault2);
  $('#SetDutySetup').unbind('click', WWfdtContinue);
  var date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  FDT_MissingDates = date;
  Rule = $('#SELECT_Rule2').val();

  FDT_MODULE_VERSION = 4;
  checkMissingDates(date, 'Stage2');
}
var FDT_MissingDates = null;
function IOS_postMessage(e) {
  var Message = e.Message;
  var data = e;

  switch (Message) {
    case 'No Server Data':
      if (USERTYPE == 'Pilot' || USERTYPE == 'NASP SO' || USERTYPE == 'Rescue Specialist') {
        $.mobile.changePage($('#DutySetup'), {
          changeHash: false,
        });
        $('#SELECT_TimezoneHB2').empty();
        let TZList = getTimezoneList();
        for (let i in TZList) {
          let tz = TZList[i];
          $('#SELECT_TimezoneHB2').append('<option value="' + tz.value + '">' + tz.label + '</option>');
        }
        FDTdefault_Timezone = CurrentTimeZone;
        $('#SELECT_TimezoneHB2').val(CurrentTimeZone);
        $('#SELECT_TimezoneHB2').selectmenu('refresh');
        $('#CHK_DHC2').unbind('click', changeDefault2);
        $('#CHK_DHC2').click(changeDefault2);
        $('#CHK_Dual2').unbind('click', changeDefault2);
        $('#CHK_Dual2').click(changeDefault2);
        $('#CHK_Heli2').unbind('click', changeDefault2);
        $('#CHK_Heli2').click(changeDefault2);
        $('#CHK_IFR2').unbind('click', changeDefault2);
        $('#CHK_IFR2').click(changeDefault2);
        $('#CHK_Logging2').unbind('click', changeDefault2);
        $('#CHK_Logging2').click(changeDefault2);
        $('#CHK_AerialApp2').unbind('click', changeDefault2);
        $('#CHK_AerialApp2').click('click', changeDefault2);
        $('#CHK_Standby2').unbind('click', changeDefault2);
        $('#CHK_Standby2').click(changeDefault2);
        $('#SELECT_Rule2').unbind('change', changeDefault2);
        $('#SELECT_Rule2').change(changeDefault2);

        $('#SELECT_TimezoneHB2').unbind('change', changeDefault2);
        $('#SELECT_TimezoneHB2').change(changeDefault2);
        $('#SELECT_AvgFlt2').unbind('change', changeDefault2);
        $('#SELECT_AvgFlt2').change(changeDefault2);
        $('#CHK_Medevac2').unbind('click', changeDefault2);
        $('#CHK_Medevac2').click(changeDefault2);

        $('#FDT_DefaultTravelTime').unbind('change', changeDefault2);
        $('#FDT_DefaultTravelTime').change(changeDefault2);
        cirroDB.clearAll('FDT_DaysV4', function (e) {});

        var now = new Date();
        now.setFullYear(now.getFullYear() - 1);
        $('#INPUT_FDTstart').unbind('change', changeDefault2);
        $('#INPUT_FDTstart').change(changeDefault2);
        $('#SetDutySetup').unbind('click', IOSfdtContinue);
        $('#SELECT_Rule2').val(SetDefaultFDT_Rule(GROUP_DATA.FDT_Rule)).selectmenu('refresh');
        $('#SetDutySetup').click(IOSfdtContinue);
      } else {
        if (TIMESHEETS == '1') {
          var date = new Date();
          date.setDate(date.getDate() - 30);
          date.setMilliseconds(0);
          checkMissingDates(date, 'Stage2');
        }
      }
      break;
    case 'SyncDate':
      //not used for IOS sync. variables already global
      break;
    case 'SyncStatus':
      var SyncStatus = e.status;
      ////console.log("Last Sync Date times Received");
      //e.status list
      //GroupSyncFail - connection error or group sync did not complete successfully and aborted
      //GroupSyncFailAC - connection error, individual aircraft not added to local database SETS: e.ident and continues sync
      //Group_Complete - sync complete sets e.SyncError true if sync was incomplete
      //Group_Insert - aircraft successfully added to database
      //WriteFailed - failed to write updated sync date to local db
      //FCSyncFail - fc sync failed and did not complete successfully
      //FC_Complete - fc sync completed successfully
      //COMPLETED - All sync tasks completed upate SYNCING flag to false;
      //console.log(e.status); //
      switch (SyncStatus) {
        case 'COMPLETED':
          $('#ManualSync').prop('disabled', false);
          $('#LogoutBTN').prop('disabled', false);
          $('#syncIndicator').hide();
          clearTimeout(syncTimer);
          syncTimer = null;
          DAILY_SYNC = setTimeout('DailySyncBackgroundSync()', 86400000);
          $('#Status_Text').html('Status: Online');
          SYNCING = false;
          VueBridge.LegacyAppSyncing(SYNCING);
          //$("#StopSync").hide();
          //$("#Duty_Status").html("Data: Synced");
          SYNC_FOLLOW = true;
          if (FDT_MODULE_CALLBACK == null && !SYNC_FDT_ONLY && !SYNC_FC_ONLY) {
            if (TDGACCESS == 1) DownloadOfflineGroupTDG(false);
            syncOfflineWB();
            syncOfflineItinerary();
            syncOfflineTrackingClients();
            //Delay fetching of follow data to see if offline itinerary or WB needs to be synced first
            setTimeout(function () {
              getFollowData(); //create copy of local follow data
            }, 5000);
          }
          if (SYNC_FC_ONLY) {
            getFCdata();
          }
          var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;

          if (FDT_MODULE_VERSION == 4 && ForceStart_FDTCalendar) {
            StartFDTCalenderV4();
          } else {
            if (page == 'DutyTimes' && ForceStart_FDTCalendar) {
              FDT_FORCE_SYNC = false;
              initDutyTimes(); //menu-Duty.js
            }
          }
          if (page == 'Initialization_Page' || page == 'Login_Page') {
            $('#initDialog').text(iTrans('Launching') + '...');
            //$("#SyncProgressText").hide();
            setTimeout('setStartupPage()', 1000);
          } else {
            //toaster.show("Background Sync Completed",2);
          }
          try {
            VueBridge.onSyncFinished();
          } catch (e) {
            console.log('For Mikes Dev Box');
          }
          if (FC_FORCE_RESYNC == true) {
            update_Sync('LastFCsync', new Date());
            BackgroundSync(false, true);
          }
          console.log('Dispatching cirro-sync-complete event');
          window.dispatchEvent(new Event('cirro-sync-complete'));
          break;
        case 'GroupSyncFail':
          break;
        case 'BadConnection':
          SYNCING = false;
          VueBridge.LegacyAppSyncing(SYNCING);
          GROUP_SYNCING = false;
          FDT_SYNCING = false;
          FC_SYNCING = false;
          WP_SYNCING = false;
          RULE_SYNCING = false;
          SYSTEM_SYNCING = false;
          PDF_SYNCING = false;
          SCHEDULE_SYNCING = false;
          $('#ManualSync').prop('disabled', false);
          $('#LogoutBTN').prop('disabled', false);
          $('#syncIndicator').hide();
          clearTimeout(syncTimer);
          syncTimer = null;
          DAILY_SYNC = setTimeout('DailySyncBackgroundSync()', 86400000);
          $('#Status_Text').html('Status: Offline');
          SYNCING = false;
          VueBridge.LegacyAppSyncing(SYNCING);
          SYNC_FOLLOW = false;
          getFollowData(); //create copy of local follow data

          var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
          if (FDT_MODULE_VERSION == 4 && ForceStart_FDTCalendar) {
            StartFDTCalenderV4();
          } else {
            if (page == 'DutyTimes' && ForceStart_FDTCalendar) {
              FDT_FORCE_SYNC = false;
              initDutyTimes(); //menu-Duty.js
            }
          }

          if (page == 'Initialization_Page' || page == 'Login_Page') {
            $('#initDialog').text(iTrans('Launching Offline...'));
            toaster.show(iTrans('Sync Cancelled. Bad Connection.'), 2);
            setTimeout('setStartupPage()', 1000);
          } else {
            toaster.show(iTrans('Background Sync Cancelled. Bad Connection'), 2);
          }
          OfflineTriggered('BadConnection menu-sync');
          CheckOfflineSendItems();
          try {
            VueBridge.onSyncFinished();
          } catch (e) {
            console.log('For Mikes Dev Box');
          }
          break;
      }
      break;

    case 'DutySynced':
      if (data.Con_Flag) {
        //Remote Data was Received
        if (data.Status == 'Success' || data.Status == 'SuccessNoUpdate') {
          //Update Sent to Server and Succeded OR No no new data to send to server
          //if (data.Status == "Success") initDutyTimes(); //refresh if already opened
          $('#Duty_Status').html('Data: Synced');
        } else {
          //Update Sent and Failed
          $('#Duty_Status').html('Last Sync: ' + LastFDTsync);
          //Prompt user to try again becaue of intermittent connection?
        }
      } else {
        //Remote Data Unavailable
        if (data.Status == 'Success') {
          //Update Sent to Server and Succeded
          $('#Duty_Status').html('Last Sync: ' + LastFDTsync);
        } else {
          //Update Sent and Failed
          $('#Duty_Status').html('Last Sync: ' + LastFDTsync);
          //Prompt user to try again becaue of intermittent connection?
        }
      }
      //$("#StopSync").hide(); //hide div holding work offline button
      break;
    case 'Offline_Blank_DB':
      //$("#StopSync").hide(); //hide div holding work offline button
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'OFFLINE',
        headerClose: false,
        buttonPrompt:
          '<p>Unable to retrieve duty times from server, local database was not found. Duty times will be unavailable until an initial sync is accomplished.</p>',
        buttons: {
          'Return to Menu': {
            click: function () {
              setStartupPage();
            },
          },
        },
      });
      break;
    case 'ProgressBar':
      progressbar(data.Title, data.Text, data.Max, data.Indefinite);
      break;
    case 'RemovePB':
      PBAR.destroy();
      $('#ProgressBar').html('');
      break;
    case 'ProgressText':
      $('#Duty_Status').html('Syncing Data<br />' + data.Text);
      break;
    case 'Progress':
      if (!PBAR_Close) PBAR.setValue(data.Progress);
      break;
    case 'PDFSync':
      //console.log("Progress: " + data.Item); //filename completed
      break;
    case 'Stext':
      $('#Stext').html(data.text);
      break;
    case 'FDTtext':
      $('#FDTtext').html(data.text);
      //$("#calendar").html("<h3 class='limit-table'>Syncing Standby...<br />" + data.text + "</h3>");
      break;
    case 'FCtext':
      $('#FCtext').html(data.text);
      break;
    case 'WPtext':
      $('#WPtext').html(data.text);
      break;
    case 'Ptext':
      $('#Ptext').html(data.text);
      break;
    case 'ACtext':
      $('#ACtext').html(data.text);
      break;
    case 'Rtext':
      $('#Rtext').html(data.text);
      break;
    case 'PDFtext':
      $('#PDFtext').html(data.text);
      break;
    case 'SCHtext':
      $('#SCHtext').html(data.text);
      break;
    case 'MANIFESTtext':
      $('#MANIFESTtext').html(data.text);
      break;
    case 'PAXtext':
      $('#PAXtext').html(data.text);
      break;
  }
}

function syncOfflineTrackingClients() {
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getClients', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'None') {
        $('#itinClients2').hide();
        localStorageDB.setItem('itinClients', '[]');
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getDisabled');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      localStorageDB.setItem('itinClients', JSON.stringify(json_data));
    }
    //popItinAssetTemplates();
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
  });
}

async function update_Sync(Sitem, date) {
  return SyncTable.query().insert({
    SyncItem: Sitem,
    Date: dateToStringObject(date),
  });
}

function storeOfflineWB(data, callback) {
  if (data.WB_OfflinePK != 0 && data.WB_PK == 0) {
    //Offline create new flight that has not been uploaded to server.  update new flight db instead so its all together for upload
    cirroDB.query('Itinerary', 'OfflinePK = ?', data.WB_OfflinePK, function (e) {
      if (e.length == 1) {
        var Itin = e[0];
        var Data = JSON.parse(Itin.data);

        Data.LEGS[data.LegNum] = data;
        ItineraryTable.query()
          .raw({
            q: 'UPDATE Itinerary SET data = ? WHERE PrimaryKey = ?',
            v: [JSON.stringify(Data), Itin.PrimaryKey],
          })
          .then(() => {
            callback();
          });
      } else {
        //Failed this is bad store using old framework to be safe
        storeofflineWB_OLD(data, callback);
      }
    });
  } else {
    //use old offline framework only if we're not totally offline from create new flight;
    storeofflineWB_OLD(data, callback);
  }
}
function storeofflineWB_OLD(data, callback) {
  var Leg = {
    LegNum: data.LegNum,
    data: JSON.stringify(data),
  };

  cirroDB.insert('WB', Leg, null, function (e) {
    callback();
  });
}

function deleteWBDatabase() {
  cirroDB.clearAll('WB', function (e) {
    //console.log("Deleted KFM_OfflineWB database successfully");
    SYNCING_OFFLINE_WB = false;
    if (CurPageID() == 'BILLING_FlightReportsPage') BILLING_NewFlightReport(true);
  });
}
function deleteItineraryDatabase() {
  cirroDB.clearAll('Itinerary', function (e) {
    //console.log("Deleted KFM_OfflineWB database successfully");
    SYNCING_OFFLINE_ITINERARY = false;
    if (CurPageID() == 'BILLING_FlightReportsPage') BILLING_NewFlightReport(true);
  });
}

var SYNCING_OFFLINE_WB = false;
function syncOfflineWB() {
  if (SYNCING_OFFLINE_WB) return;
  try {
    cirroDB.query('WB', 'LegNum != ? ORDER BY LegNum ASC', '', function (e) {
      if (e === false) e = [];
      if (e.length == 0) {
        deleteWBDatabase();
      } else {
        SYNCING_OFFLINE_WB = true;
        sendOfflineArray(e, 0, false, 0);
      }
    });
  } catch (e) {
    //console.log("Error syncOfflineWB");
  }
  toaster.remove(toasterID_OfflineLeg);
}

var SYNCING_OFFLINE_ITINERARY = false;
function syncOfflineItinerary() {
  if (SYNCING_OFFLINE_ITINERARY) return;
  try {
    cirroDB.query('Itinerary', 'PrimaryKey != ?', 0, function (e) {
      if (e === false) e = [];
      if (e.length == 0) {
        // Ensure offline data is synced when there aren't any itineraries to sync
        VueBridge.DataCreatedOfflineSync();

        deleteItineraryDatabase();
      } else {
        SYNCING_OFFLINE_ITINERARY = true;
        sendOfflineArray(e, 0, false, 1);
      }
    });
  } catch (e) {
    //console.log("Error syncOfflineWB");
  }
  toaster.remove(toasterID_OfflineItinerary);
}

function sendOfflineArray(data, i, failed, itin) {
  //console.log("Sending Offline WB Data: " + i);
  //console.log(data);
  var last = false;
  if (i == data.length - 1) last = true;
  if (itin == 0) {
    UploadOfflineArray(data, data[i], i, last, failed, itin);
  } else {
    cirroDB.query('Itinerary', 'OfflinePK = ?', data[i].OfflinePK, function (e) {
      UploadOfflineArray(data, e[0], i, last, failed, itin);
    });
  }
}
var toasterID_OfflineItinerary = null;
var toasterID_OfflineLeg = null;

function UploadOfflineArray(WBdata, item, i, last, failed, itin) {
  i++; //increment record
  if (!ONLINE) {
    if (!last) sendOfflineArray(WBdata, i, true, itin);
    if (last) getFollowData();
    return;
  }
  var url = BASEURL + 'menu-Itinerary-AJAX.php?action=updateItinWB';
  var uploadItem = item;
  if (itin == 1) {
    url = BASEURL + 'menu-Itinerary-AJAX.php?action=OfflineItinerary';
    var parseData = JSON.parse(item.data);
    if (parseData.offlineEdit != undefined) {
      url =
        BASEURL +
        'menu-Following-AJAX.php?action=editItin&offlineEdit=1&ItinPK=' +
        parseData.PrimaryKey +
        '&Details=' +
        encodeURIComponent(parseData.Details) +
        '&timeback=' +
        encodeURIComponent(parseData.timeback) +
        '&timezone=' +
        parseData.timezone +
        '&survivalkit=' +
        parseData.survivalkit +
        '&liferaft=' +
        parseData.liferaft +
        '&lifejackets=' +
        parseData.lifejackets;
      item = {
        File: null,
        Filename: '',
        Private: '0',
        Cfields: parseData.Cfields,
        Pax_Manifest: parseData.Pax_Manifest,
      };
      console.log(item);
    } else {
      uploadItem = { data: item.data };
    }
    toasterID_OfflineItinerary = window.toaster.show('Uploading Offline Itinerary ' + i + ' of ' + WBdata.length);
  } else {
    uploadItem = JSON.parse(item.data);
    toasterID_OfflineLeg = window.toaster.show('Uploading Offline Leg Data ' + i + ' of ' + WBdata.length);
  }
  console.log(uploadItem);
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: url,
    data: uploadItem,
    timeout: 10000,
  })
    .success(function (data) {
      if (data[0].ServerResponse == 'Uncaught Exception') {
        //console.log("Server Side Exception Syncing offline WB");
        alert('ERROR: 854 - ' + data[0].Exception);
        if (!last) sendOfflineArray(WBdata, i, true, itin);
      }
      if (data[0].ServerResponse == 'Success') {
        if (last && failed) {
          if (itin == 1) {
            SYNCING_OFFLINE_ITINERARY = false;
            url = BASEURL + 'menu-Itinerary-AJAX.php?action=OfflineItinerary';
            toasterID_OfflineItinerary = window.toaster.show(
              "Failed to Upload 1 or more Offline Itinerary. <a href='#' onClick='syncOfflineItinerary()';>Try Again Now</a>",
              10
            );
          } else {
            SYNCING_OFFLINE_WB = false;
            toasterID_OfflineLeg = window.toaster.show(
              "Failed to Upload 1 or more Offline Leg Data. <a href='#' onClick='syncOfflineWB()';>Try Again Now</a>",
              10
            );
          }
        } else {
          if (itin == 1) {
            window.toaster.remove(toasterID_OfflineItinerary);
          } else {
            window.toaster.remove(toasterID_OfflineLeg);
          }
        }
        if (last && !failed) {
          WAIT_FOR_OFFLINE_UPLOAD = false;
          if (itin == 0) {
            toasterID_OfflineLeg = window.toaster.show('Offline Itinerary Leg Data Successfully Uploaded.');
            deleteWBDatabase(); //delete offline database as all data has been synced
          } else {
            toasterID_OfflineItinerary = window.toaster.show('Offline Itinerary Successfully Uploaded.');
            deleteItineraryDatabase();
            VueBridge.DataCreatedOfflineSync();
          }
        } else if (!last) sendOfflineArray(WBdata, i, failed, itin);
      }
      if (data[0].ServerResponse == 'Offline') {
        //console.log("Still Offline nothing synced");
        if (!last) sendOfflineArray(WBdata, i, true, itin);
      }
      if (last) {
        getFollowData();
        CheckOfflineSendItems();
      }
    })
    .fail(function (data) {
      //console.log(data);
      //console.log("Connection Error Syncing offline WB");
      if (!last) {
        sendOfflineArray(WBdata, i, true, itin);
      }
      if (last) {
        if (itin == 1) {
          SYNCING_OFFLINE_ITINERARY = false;
          toasterID_OfflineItinerary = window.toaster.show(
            "Failed to Upload Offline Itinerary. <a href='#' onClick='syncOfflineItinerary()';>Try Again Now</a>",
            10
          );
        } else {
          SYNCING_OFFLINE_WB = false;
          toasterID_OfflineLeg = window.toaster.show(
            "Failed to Upload Offline Itinerary Leg Data. <a href='#' onClick='syncOfflineWB()';>Try Again Now</a>",
            10
          );
        }
        getFollowData();
        CheckOfflineSendItems();
      }
    });
}

function LoadFDTRules() {
  $('#SELECT_Rule_ID').empty();
  $('#SELECT_Rule_ID').append("<option value='0' selected='selected' >None</option>");
  $('#SELECT_Rule_ID2').empty();
  $('#SELECT_Rule_ID2').append("<option value='0' selected='selected' >None</option>");

  cirroDB.query('FDT_Rule', 'PrimaryKey != ? ORDER BY PrimaryKey ASC', '', function (e) {
    if (e === false) e = [];
    if (e.length == 0) {
      //deleteWBDatabase();
    } else {
      //console.log(e);
      for (var i in e) {
        var Item = e[i];
        $('#SELECT_Rule_ID').append("<option value='" + Item.PrimaryKey + "' >" + Item.Name + '</option>');
        $('#SELECT_Rule_ID2').append("<option value='" + Item.PrimaryKey + "' >" + Item.Name + '</option>');
        registerFunction(Item.PrimaryKey, Item.Method);
      }
    }
    FDT_CUSTOM_RULES_LOADED = true;
  });
}

var StaffListToast = null;
function SyncStaffList() {
  if (StaffListToast != null) toaster.remove(StaffListToast);
  $.getJSON(BASEURL + 'sync-AJAX.php?action=getStaffList', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      //lets do nothing here
    } else {
      if (json_data[0].ServerResponse == 'No Staff') {
        STAFF_LIST = [];
      } else {
        STAFF_LIST = json_data;
      }
      localStorageDB.setItem('STAFF_LIST', JSON.stringify(STAFF_LIST), function (e) {
        localStorageDB.getItem('STAFF_LIST', LoadStaffList);
      });
    }
  }).fail(function (jqXHR, status, error) {
    localStorageDB.getItem('STAFF_LIST', LoadStaffList);
    StaffListToast = window.toaster.show(
      iTrans('Failed to download Staff List.') +
        " <a href='#' onClick='SyncStaffList();'>" +
        iTrans('Try Again Now') +
        '</a>',
      0
    );
  });
}

async function SyncMyReqCerts() {
  try {
    const json_data = await RemoteData.get('menu-Administration-Reports-AJAX.php', { report: 'getMyReqCerts' });
    if (json_data[0].ServerResponse !== 'Success') {
      return;
    }

    LOCALSTORAGE.ReqCerts = json_data[0].Certs;
    LOCALSTORAGE.ReqMemos = json_data[0].Memos;

    localStorageDB.setItem('ReqCerts', JSON.stringify(json_data[0].Certs));
    localStorageDB.setItem('ReqMemos', JSON.stringify(json_data[0].Memos));
  } catch (e) {
    //
  }
}

/**
 * pops a dialog if the user has any expired certificates or upcoming ones coming due
 */
async function checkReqCerts() {
  if (
    USER_RIGHTS.staffType !== 'Pilot' &&
    USER_RIGHTS.staffType !== 'NASP SO' &&
    USER_RIGHTS.staffType !== 'Rescue Specialist'
  ) {
    return;
  }

  const canSelfDispatch = window.vueApp.models.userPreferences.getOrDefault('pilot.currency.selfDispatch', 0);
  if (CERTS <= 0 || canSelfDispatch < 1) {
    return;
  }

  if (ONLINE) {
    await SyncMyReqCerts();
  }

  let certCheck = FitToFlyReportBuilder.build(LOCALSTORAGE.ReqMemos, LOCALSTORAGE.ReqCerts, new Date());
  if (certCheck.expiredCert) {
    let title = iTrans('Incomplete & Expired Essential Items');

    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: title,
      headerClose: false,
      buttonPrompt: certCheck.html,
      width: '350px',
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
      },
    });
  }
}

function LoadStaffList(data) {
  STAFF_LIST = JSON.parse(data);
  LOCALSTORAGE.STAFF_LIST = STAFF_LIST;
}

function CheckOfflineDataToSend() {
  BillingDB('SELECT', 'OFFLINE_SYNC', null, CheckOfflineItinLegData); // Check for offline flight reports to sync.
}
function CheckOfflineItinLegData(Reports) {
  try {
    cirroDB.query('WB', 'LegNum != ? ORDER BY LegNum ASC', '', function (e) {
      if (e === false) e = [];
      OfflineItinLegs = e.length;
      CheckOfflineItinData(Reports.length, e.length);
    });
  } catch (e) {
    CheckOfflineItinData(Reports.length, 0);
  }
}
function CheckOfflineItinData(Reports, Legs) {
  var OfflineItins = 0;
  try {
    cirroDB.query('Itinerary', 'PrimaryKey != ?', 0, function (e) {
      if (e === false) e = [];
      ToastOfflineDataToSend(Reports, Legs, e.length);
    });
  } catch (e) {
    ToastOfflineDataToSend(Reports, Legs, 0);
  }
}

function ToastOfflineDataToSend(Reports, Legs, Itins) {
  if (Reports > 0 || Legs > 0 || Itins > 0) {
    var ToastHTML = '';
    if (Reports > 0 && !OFFLINE_BILLING_SYNCING_Flightreports)
      ToastHTML += 'There are ' + Reports + ' Offline Flight Reports pending upload.';
    if (Itins > 0 && SYNCING_OFFLINE_ITINERARY == false) {
      if (ToastHTML != '') ToastHTML += '<br />';
      ToastHTML += 'There are ' + Itins + ' Offline Flight Itinerary pending upload';
    }
    if (Legs > 0 && SYNCING_OFFLINE_WB == false) {
      if (ToastHTML != '') ToastHTML += '<br />';
      ToastHTML += 'There are ' + Legs + ' Offline Flight Itinerary Legs pending upload';
    }
    if (SYNCING_OFFLINE_ITINERARY || SYNCING_OFFLINE_WB || SYNCING_OFFLINE_WB) return;

    if (ONLINE)
      ToastHTML +=
        "<br /><a href='#' onClick='BILLING_SyncStuffNow(" +
        Reports +
        ',' +
        Legs +
        ',' +
        Itins +
        ")' >Try Sending Now</a>";
    else ToastHTML += "<br /><b>Currently Offline we'll try to send these later...</b>";
    ToastHTML = '<div>' + ToastHTML + '</div>';
    window.toaster.show(ToastHTML, 10);
  }
}

function BILLING_SyncStuffNow(Reports, Legs, Itins) {
  if (Reports > 0 && !OFFLINE_BILLING_SYNCING_Flightreports) {
    BillingDB('SELECT', 'OFFLINE_SYNC', null, OfflineReportSending); // Check for offline flight reports to sync.
  }
  if (Legs > 0 && SYNCING_OFFLINE_WB == false) {
    syncOfflineWB();
  }
  if (Itins > 0 && SYNCING_OFFLINE_ITINERARY == false) {
    syncOfflineItinerary();
  }
}
