/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var AC_data;
var ALLAC_data = [];
var AC_TECH_DISPATCH = {};
var TC_data;
var WB_data;
var PILOT_data = [];
var LOADABLE_data;
var FUEL_data;
var eacweight;
var eLat;
var eLong;
var ecofgLat;
var ecofgLong;
var error;
var searchtime;
var FuelType = 'Standard';
var FuelStandard = 'Standard';
var SeatType = 'Standard';
var SeatStandard = 'Standard';
var GraphName = 'Standard';
var GraphStandard = 'Standard';
var Loading = [];
var optLoad = [];
var tcNum;
var tcIndex;
var PaXnum = 0;
var SN;
var acIdent;
var acType;
var dbGroup;
var groupversion = 1;
var tcDesc = '';
var itinNOW = 'Itinerary_Page';
var TEMPLATE_LOAD = false;
var TEMPLATE_FUEL;
var TEMPLATE_PAX = false;
var ITIN_Pax_Manifest = {};
var SCHEDULER_OVERRIDE = false;
var ITIN_BookingPK = 0;
var ITIN_SCH_AircraftPK = 0;
var ITIN_TIMEZONE = 0;
var ITIN_TIMEZONE_UTCmode = 'Local';
var ITIN_ENTRY_TIMEZONE = '';

var SCH_DATA = {
  isLoading: false,
  returnToScheduler: false,
  LegIndex: 0,
  JobID: 0,
  ClientID: 0,
  PIC: '',
  SIC: '',
  ReturnTime: '',
  ReturnDate: '',
  SCH_AircraftPK: 0,
  SCH_BookingPK: 0,
  Flight_Data: null,
  Template: null,
  LEGS: [],
  noMap: false,
};
function resetSCH_DATA() {
  ITIN_BookingPK = 0;
  ITIN_SCH_AircraftPK = 0;
  SCH_DATA = {
    isLoading: false,
    returnToScheduler: false,
    LegIndex: 0,
    JobID: 0,
    ClientID: 0,
    PIC: '',
    SIC: '',
    ReturnTime: '',
    ReturnDate: '',
    SCH_AircraftPK: 0,
    SCH_BookingPK: 0,
    Flight_Data: null,
    Template: null,
    LEGS: [],
    noMap: false,
  };
}
var ITIN_Cfields = [];
var ITIN_InProgress = false;
var ITIN_Files = [];
var ITIN_FRMS = {
  images: null,
  results: null,
  status: 'unset',
};
var ITIN_GETSCHEDULED = false;
function resetITIN_FRMS() {
  ITIN_FRMS = {
    images: null,
    results: null,
    status: 'unset',
  };
}
function ResetITIN_PaxManifest() {
  ITIN_Pax_Manifest = {
    DefaultList: [],
    Pax_Legs: [],
    CurLeg: -1,
    Editing: false,
    scrollTo: 0,
    Scheduler: false,
  };
}

function toggleItineraryTZ(e) {
  if (e.value == ITIN_TIMEZONE_UTCmode) {
    return;
  }
  var itinDate = $('#returnDate').val();
  var itinTime = $('#returnTime').val();
  if (itinTime != '') {
    var curDateTime = itinDate + ' ' + itinTime.substr(0, 2) + ':' + itinTime.substr(2, 2) + ':00';
  }
  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    ITIN_TIMEZONE_UTCmode = 'Local';
    var now = new Date();
    ITIN_TIMEZONE = now.getTimezoneOffset();
    if (itinTime != '') {
      var newDateTime = toUTCmode_offset(curDateTime, ITIN_TIMEZONE);
      $('#returnDate').val(newDateTime.substr(0, 10));
      $('#returnTime').val(newDateTime.substr(11, 2) + newDateTime.substr(14, 2));
    }
    $('#returnTimeTXT').html(iTrans('ETA Time') + ' - ' + getLocalTZLabel());
  } else {
    ITIN_TIMEZONE_UTCmode = 'UTC';
    if (itinTime != '') {
      var now = new Date();
      ITIN_TIMEZONE = now.getTimezoneOffset();
      var newDateTime = toUTCmode_offset(curDateTime, -ITIN_TIMEZONE);
      $('#returnDate').val(newDateTime.substr(0, 10));
      $('#returnTime').val(newDateTime.substr(11, 2) + newDateTime.substr(14, 2));
    }
    ITIN_TIMEZONE = 0;
    $('#returnTimeTXT').html(iTrans('ETA Time') + ' - UTC (GMT+00)');
  }
  dateChanged();
}
function resetItineraryData() {
  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    ITIN_TIMEZONE = 0;
    $('#returnTimeTXT').html(iTrans('ETA Time') + ' - UTC (GMT+00)');
  } else {
    var now = new Date();
    ITIN_TIMEZONE = now.getTimezoneOffset();
    $('#returnTimeTXT').html(iTrans('ETA Time') + ' - ' + getLocalTZLabel());
  }
  $('#ITIN_UTCmode_LocalLBL').html(getLocalTZLabel());

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    TdgManifestClearForCurrentItinerary();
  }

  ITIN_InProgress = false;
  resetSCH_DATA();
  ResetITIN_PaxManifest();
  resetITIN_FRMS();
  getFCdata(true);
  resetFC_Uplifts();
  ITIN_Files = [];
  ITIN_Cfields = [];
  BILLING_BACKUP_Cfields = null;
  clearAllCfieldHolders();
  TEMPLATE_LOAD = false;
  TEMPLATE_FUEL = false;
  TEMPLATE_PAX = false;
  RAform = null;
  RISK_APPROVE = false;
  ItinResponsiblePersonPK = 0;
  Itin_JobName = '';
  Itin_ClientName = '';
  Itin_ClientPK = -1;
  ItinJobDefault = 0;
}

async function TdgManifestClearForCurrentItinerary() {
  if (!window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS) {
    return;
  }

  VueBridge.TdgManifestClear(window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS);
  window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS = [];
}

function convertACToUserUnits(value, type, from, dec = 0) {
  value = parseFloat(value);
  var to = 'Imperial';
  if (LOCALSTORAGE.UserUNITS == 'Metric') {
    to = 'Metric';
  }
  if (type == 'Weight') return convertToUserUNITS(value, type, from, to, dec) + ' ' + WBUnitLbl[type];
  else return convertToUserUNITS(value, type, from, to, dec) + WBUnitLbl[type];
}
function convertToUserUNITS(value, type, from, to, dec = 0) {
  if (from.toLowerCase() == to.toLowerCase()) {
    return Number(value).toFixed(dec);
  } else {
    if (type == 'Weight') {
      if (to.toLowerCase() == 'imperial') value = Number(value * 2.20462).toFixed(dec);
      else value = Number(value * 0.453592).toFixed(dec);
      return value;
    }
    if (type == 'Length' || type == 'Arm') {
      if (to.toLowerCase() == 'imperial') value = Number(value * 0.0393701).toFixed(dec);
      else value = Number(value * 25.4).toFixed(dec);
      return value;
    }
    return value;
  }
}

function itin_UpdateHeadingIdent(acIdent) {
  //because something is not allowing the ident to be updated maybe timing issue I dunno
  setTimeout(function () {
    $('.i18n-new-flight-ident').html(iTrans('New Flight') + ' | ' + acIdent);
  }, 500);
}

function itin_ClearAttachmentIndex(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to remove the File Attachmenht: ') + ITIN_Files[index].Filename + '?',
    buttons: {
      [iTrans('Remove')]: {
        click: function () {
          ITIN_Files.splice(index, 1);
          itin_RefreshAttachmentList();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function itin_MarkAttachmentIndex(index, e) {
  ITIN_Files[index].Private = e.checked ? 1 : 0;
  if (e.checked) {
    $('#FR_PrvCLASS_' + index).addClass('yellowbg');
  } else {
    $('#FR_PrvCLASS_' + index).removeClass('yellowbg');
  }
}

function itin_RefreshAttachmentList() {
  if (ITIN_Files.length == 0) {
    $('#ITIN-FILE_LIST').html(iTrans('No Files Attached'));
    return;
  }
  var html = '<h3>' + iTrans('Attachments') + '</h3>';
  html +=
    '<table class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Filename') +
    '</th><th>' +
    iTrans('Private') +
    '</th><th>' +
    iTrans('Size') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in ITIN_Files) {
    var I = ITIN_Files[i];
    var PrivateCHK =
      '<input type="checkbox" data-role="none" id="FR_PrvCHK_' +
      i +
      '" onClick="itin_MarkAttachmentIndex(' +
      i +
      ', this)" ' +
      (I.Private == 0 ? '' : 'checked') +
      '/>';
    var action =
      '<div class="vertical-center-container"><button class="action-btn" data-mini="true" onclick="itin_ClearAttachmentIndex(' +
      i +
      ')"><i class="fal fa-times"></i></button></div>';
    html +=
      '<tr id="FR_PrvCLASS_' +
      i +
      '" class="' +
      (I.Private == 0 ? '' : 'yellowbg') +
      '"><td>' +
      I.Filename +
      '</td><td>' +
      PrivateCHK +
      '</td><td>' +
      I.Size +
      '</td><td>' +
      action +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#ITIN-FILE_LIST').html(html);
  $('#ITIN-FILE_LIST').enhanceWithin();

  document.getElementById('ITINERARY_Attachment').value = '';
}

function ITIN_ValidateCfields() {
  var CF = ITIN_Cfields;
  if (CF != null) {
    if (CF.length > 0) {
      for (var i in CF) {
        var I = CF[i];
        if (I.Aircraft != acIdent && I.Aircraft != '') {
          continue;
        }
        if (!checkCfieldType('ITIN', I)) continue;
        if (I.PerLeg == 0) {
          var checked = 0;
          if (I.type == 'checkbox') {
            if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
            I.Value = checked;
            if (I.reqItin == 1 && checked == 0) {
              BillingValidation('[' + I.Label + '] is required to be checked.');
              return false;
            }
          } else {
            I.Value = $('#BI_CF_' + I.PrimaryKey).val();
            if (I.Value == undefined) I.Value = '';
            if (
              (I.Value == '' && I.reqItin == 1) ||
              (I.Value == 'false' && I.reqItin == 1 && I.type == 'signature') ||
              (I.Value == null && I.reqItin == 1) ||
              (I.Value == ' ' && I.reqItin == 1)
            ) {
              BillingValidation('[' + I.Label + '] is a required field and is empty.');
              return false;
            }
          }
        }
      }
    }
  }
  return true;
}

async function itinNAVnext() {
  if (AC_data == null || AC_data.TechDispatch == null) AC_data.TechDispatch = 1; //for companies that havn't rebuilt all aircraft to use this option;
  var page;
  switch (itinNOW) {
    case 'Itinerary_Page':
      //flightdocs and wandb handled in line 452 this is never triggered here

      if (
        (FLIGHTDOCS == 1 && AC_data.TechDispatch == 1) ||
        (GROUP_DATA.WinAir == 1 && AC_data.TechDispatch == 1) ||
        (GROUP_DATA.Traxxall == 1 && AC_data.TechDispatch == 1) ||
        (GROUP_DATA.CAMP == 1 && AC_data.TechDispatch == 1)
      )
        page = 'ITIN-TECH-DISPATCH';
      else if (WANDB == '1') page = 'ITIN-OPTIONAL';
      else if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
      else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
      else if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
      else page = 'ITIN-INPUT';
      if ((GROUP_DATA.group == 'transportcanada' && SCH_DATA.isLoading) || (GPS_DEBUG && SCH_DATA.isLoading)) {
        page = 'ITIN-INPUT';
      }
      break;
    case 'ITIN-TECH-DISPATCH':
      if (WANDB == '1') page = 'ITIN-OPTIONAL';
      else if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
      else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
      else if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
      else page = 'ITIN-INPUT';
      if ((GROUP_DATA.group == 'transportcanada' && SCH_DATA.isLoading) || (GPS_DEBUG && SCH_DATA.isLoading)) {
        page = 'ITIN-INPUT';
      }
      break;
    case 'ITIN-OPTIONAL':
      page = 'ITIN-WEIGHTBALANCE';
      break;
    case 'ITIN-WEIGHTBALANCE':
      var graphImg = document.getElementById('Canvas').innerHTML;
      $('#wbReportGraph').html(graphImg);
      page = 'ITIN-REVIEWBALANCE';
      break;
    case 'ITIN-REVIEWBALANCE':
      //Set route plan fuel and fuelRemain for aircraft;
      ACFuel = $('#Fuel').val();
      FuelRemain = ACFuel;

      // if the user has acess and they aren't using the new tdg module
      if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
      else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
      else if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
      else page = 'ITIN-INPUT';
      break;
    case 'ITIN-TDG':
      if ($('#TDG').is(':checked')) {
        await window.vueApp.utilities.capabilities.addRequiredCapabilitiesToAction(
          'CreateFlightWithTdg',
          getPilotRequiredDGCapabilities()
        );
      }

      if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
      else if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
      else page = 'ITIN-INPUT';
      break;
    case 'ITIN-ASSETS':
      if (GROUP_DATA.PULSAR_FRMS == 1 && !SCHEDULER_OVERRIDE) {
        page = 'FRMS-DISPLAY';
      } else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) {
        page = 'RA_Form';
      } else if (!SCHEDULER_OVERRIDE) page = 'ITIN-INPUT';
      else page = 'ITIN-ROUTEMAP';
      break;
    case 'FRMS-DISPLAY':
      if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) {
        page = 'RA_Form';
      } else if (!SCHEDULER_OVERRIDE) page = 'ITIN-INPUT';
      else page = 'ITIN-ROUTEMAP';
      break;
    case 'RA_Form':
      page = 'ITIN-INPUT';
      if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
      break;
    case 'ITIN-INPUT':
      if (GROUP_DATA.Pax_Man == 1) {
        if (!PAX_verifyBriefingCompleted()) {
          return;
        }
      }
      if (GROUP_DATA.FlightReports == 1) {
        if (!ITIN_ValidateCfields()) return;
      }

      // Navigate to TDG revamp if you have the permission
      if (TDGACCESS == '1' && VueBridge.hasFeature('TransportationOfDangerousGoods')) {
        page = 'ITIN-TDG-REVAMP';
        break;
      }

      page = 'ITIN-ROUTEMAP';
      break;
    case 'ITIN-TDG-REVAMP':
      page = 'ITIN-ROUTEMAP';
      break;
    case 'ITIN-ROUTEMAP':
      page = 'ITIN-FINALIZE';
      if (SCHEDULER_OVERRIDE) page = 'ItinLegPlanner';
      if (GROUP_DATA['OFP'] == 1 && AC_data.OFP_REQ == 1) page = 'ITIN-OFP';
      break;
    case 'ITIN-OFP':
      page = 'ITIN-FINALIZE';
      if (SCHEDULER_OVERRIDE) page = 'ItinLegPlanner';
      break;
    case 'ITIN-FINALIZE':
      page = 'ITIN-CONFIRMATION';
      break;
    case 'ITIN-CONFIRMATION':
      break;
  }

  displayCapabilityDialogOrNavigate(itinNOW, page);

  $('#' + page).enhanceWithin();
  itinNOW = page;
  if (page == 'ITIN-TECH-DISPATCH') {
    if (FLIGHTDOCS == 1) popTechDispatch();
    if (GROUP_DATA.WinAir == 1) popTechDispatch_WinAir();
    if (GROUP_DATA.Traxxall == 1) popTechDispatch_Traxxall();
    if (GROUP_DATA.CAMP == 1) popTechDispatch_CAMP();
  }
  if (page == 'ITIN-INPUT') {
    INIT_ITIN_PAGE();
    $('#itinClients').selectmenu('refresh', true);
    $('#Pilots').selectmenu('refresh', true);
    $('#ITIN_ResponsiblePerson').selectmenu('refresh', true);
    $('#SICs').selectmenu('refresh', true);
    GROUP_DATA.FR_ITIN_showStartEndTZ == 0 || GROUP_DATA.FR_ITIN_showStartEndTZ == 2
      ? $('#ITIN_TimezoneHLDR').hide()
      : $('#ITIN_TimezoneHLDR').show();

    GROUP_DATA.ITIN_showRespPerson == 0 ? $('#ITIN_RespPersonHLDR').hide() : $('#ITIN_RespPersonHLDR').show();

    GROUP_DATA.ITIN_showFlightDetails == 0 ? $('#ITINERARY_Details_HLDR').hide() : $('#ITINERARY_Details_HLDR').show();

    if (GROUP_DATA.ITIN_showVIP == 0 && GROUP_DATA.ITIN_showGenFRNum == 0) {
      $('#ITIN_VIP_GENFR_HLDR').hide();
    } else {
      $('#ITIN_VIP_GENFR_HLDR').show();
      GROUP_DATA.ITIN_showVIP == 0 ? $('#ITIN_VIP_HLDR').hide() : $('#ITIN_VIP_HLDR').show();
      GROUP_DATA.ITIN_showGenFRNum == 0 ? $('#ITINERARY_FRNUM_HLDR').hide() : $('#ITINERARY_FRNUM_HLDR').show();
    }
    GROUP_DATA.ITIN_showEmergEQ == 0 ? $('#ITINERARY_EmergEQ_HLDR').hide() : $('#ITINERARY_EmergEQ_HLDR').show();
  }
  if (page == 'FRMS-DISPLAY') {
    $('#ITIN_FRMS_HLDR').html('<h2><i>' + iTrans('Loading FRMS Data') + '...</i></h2>');
    PULSAR_Get_Date(new Date(), 'ITIN_FRMS_HLDR', true);
  }
  if (page == 'RA_Form') {
    RISK_TEST = false;
    if (RAform == null) retrieveRiskForms('User');
    else {
      RAform.ItinActive = 0;
      RISK_CURRENT_DIV = 'RAformLoad';
      RAoperation();
    }
  }
  if (page == 'ITIN-ASSETS') {
    $('#select_Itin_Assets').selectmenu('refresh');
  }
  if (page == 'ITIN-TDG') {
    $('#TDG').checkboxradio('refresh');
  }

  if (page == 'ITIN-TDG-REVAMP') {
    $('#TDG-REVAMP-CAPSTXT-USER').hide();
    $('#TDG-REVAMP-CAPSTXT-PILOT').hide();
    $('#TDG-REVAMP-CAPSTXT-PILOT-USER').hide();
    $('#TDG-REVAMP-CAPSTXT-CAP-REQUIRED').hide();

    const pilotName = $('#Pilots').val();
    const pilotEmail = PILOT_data.find((pilot) => pilot.chatname === pilotName).username;

    const pilotCapabilitiesActionable = await hasCapabilitiesToCreateTdgManifest(pilotEmail);
    const userCapabilitiesActionable = await hasCapabilitiesToCreateTdgManifest(window.vueApp.user.id);

    if (pilotCapabilitiesActionable && userCapabilitiesActionable) {
      $('#TDG-REVAMP').checkboxradio('enable');
    } else {
      $('#TDG-REVAMP').checkboxradio('disable');
      $('#TDG-REVAMP-CAPSTXT-CAP-REQUIRED').show();

      if (!pilotCapabilitiesActionable && !userCapabilitiesActionable) {
        $('#TDG-REVAMP-CAPSTXT-PILOT-USER').show();
      } else if (!pilotCapabilitiesActionable) {
        $('#TDG-REVAMP-CAPSTXT-PILOT').show();
      } else {
        $('#TDG-REVAMP-CAPSTXT-USER').show();
      }
    }

    if (!$('#TDG-REVAMP').is(':checked')) {
      $('#TTDG-REVAMP-ManifestEdit').prop('disabled', true);
    }

    $('#TDG-REVAMP').checkboxradio('refresh');
  }

  if (page == 'ITIN-ROUTEMAP') {
    initSCHLegPlanner(); //initialize leg entry tool
    var LastR = RoutePlans.length;
    if (undefined !== RoutePlans[LastR - 1] && RoutePlans[LastR - 1].length) {
      if (SCH_DATA.isLoading && SCH_DATA.Flight_Data.Quote != null) {
        checkQuoteLegPlanning(true);
      } else {
        loadLegacyRoutePlan();
      }
    } else {
      RoutePlans = []; //clear existing route plans
      $('#RoutePlanHldr').html('');
      PostRoute = null;
      $('#RoutePlanCHK').attr('checked', false).checkboxradio('refresh');
      $('#ReviewItinLegsBTN').prop('disabled', true);
      if (SCH_DATA.isLoading && SCH_DATA.Flight_Data.Quote != null) {
        checkQuoteLegPlanning(false);
      }
    }
  }

  if (page == 'ITIN-OFP') {
    //populate Operation Flight Plan Fields with info
    var G = GROUP_DATA;

    $('#OFP_OperatorName').val(G.Company_Name);
    $('#OFP_Registration').val(acIdent);
    $('#OFP_TailNumber').val();
    cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
      var AC = e[0].AC_data;
      $('#OFP_TypeModel').val(AC.AcType);
    });

    $('#OFP_PIC').val($('#Pilots').val());
    $('#OFP_DepartureIDENT').val();
    $('#OFP_DestinationIDENT').val();
    $('#OFP_DestinationROUTE').val();
    $('#OFP_EnrouteWaypoints').val();
    $('#OFP_GroundSpeed').val();
    $('#OFP_Distance').val();
    $('#OFP_FuelBurnEnroute').val();
    //total fuel on board;
    //zero fuel weight, and
    //planned maximum take-off weight;
    var WeightTXT = 'Fuel weight: ' + convertToUserUNITS(fuelwt, 'Weight', AC_UNITS, WBUnitLbl.Type) + WBUnitLbl.Weight;
    WeightTXT +=
      '\nZero Fuel Weight: ' +
      convertToUserUNITS(facweight - fuelwt, 'Weight', AC_UNITS, WBUnitLbl.Type) +
      WBUnitLbl.Weight;
    WeightTXT +=
      '\nMax take-off Weight: ' + convertToUserUNITS(facweight, 'Weight', AC_UNITS, WBUnitLbl.Type) + WBUnitLbl.Weight;
    $('#OFP_Weights').val(WeightTXT);

    var OnBoard = paxnum;
    OnBoard++;
    if ($('#SICs').val() != '') OnBoard++;
    $('#OFP_PersonsOnBoard').val(OnBoard);

    var DepartureIdent = '';
    var DestinationIdent = '';
    var AlternateIdents = '';
    var DestinationRouting = '';
    var AlternateRouteing = '';
    var EnrouteWaypoints = '';
    var FP = false;
    var AltFP = false;
    var Calcs = {
      Wind: 0,
      WindDir: 0,
      GroundSpeed: 0,
      Bearing: 0,
      Temp: 0,
      ETE: 0,
      ETE_Dst_Alt: 0,
      Dist: 0,
      Dist_Dst_Alt: 0,
      FuelEnroute: 0,
      FuelEnroute_Dst_Alt: 0,
      FuelRequired: {
        taxi: 0,
        holding: 0,
        additional: 0,
      },
    };
    MEASURE.LEGS = JSON.parse(JSON.stringify(FuelPlans));
    var FuelPlanHTML = '<h3>Trip Details</h3>';
    for (var i in FuelPlans) {
      MEASURE.CurLeg = parseInt(i);
      var I = FuelPlans[i];
      MEASURE.geojson = MEASURE.LEGS[i];
      FuelPlanHTML += getFlightPlanDetails(MEASURE, false);
      FuelPlanHTML += '<br />';
      var Leg = getMeasureFeature(I.features, true);
      if (i == 0) {
        DepartureIdent = Leg.Origin;
        FP = Leg.FP;
        Calcs = Leg.Calcs;
      } else {
        Calcs.Dist += Leg.Calcs.Dist;
        Calcs.Dist_Dst_Alt += Leg.Calcs.Dist_Dst_Alt;
        Calcs.ETE += Leg.Calcs.ETE;
        Calcs.ETE_Dst_Alt += Leg.Calcs.ETE_Dst_Alt;
        Calcs.FuelEnroute += Leg.Calcs.FuelEnroute;
        Calcs.FuelEnroute_Dst_Alt += Leg.Calcs.FuelEnroute_Dst_Alt;
      }

      if (i == FuelPlans.length - 1) {
        DestinationIdent = Leg.Dest;
        DestinationRouting += Leg.Routing;
        AlternateIdents = Leg.Alt;
        AltFP = Leg.AltFP;
      } else {
        DestinationRouting += Leg.Routing + Leg.Dest;
      }
    }

    $('#OFP_DepartureIDENT').val(DepartureIdent);
    $('#OFP_DestinationROUTE').val(DestinationRouting);
    $('#OFP_DestinationIDENT').val(DestinationIdent);
    $('#OFP_AlternateIDENTs').val(AlternateIdents);
    $('#OFP_DestCruiseAlt').val(FP.ACalt);
    if (AltFP !== false) {
      $('#OFP_AltCruiseAlt').val(AltFP.ACalt);
    } else {
      $('#OFP_AltCruiseAlt').val(0);
    }
    $('#OFP_CruiseIAS').val(FP.AirSpeed);

    $('#OFP_WindsAtCruise').val(Calcs.WindDir + ' @ ' + getDisplaySpeed(Calcs.Wind, true));

    $('#OFP_TempAtCruise').val(Calcs.Temp);

    $('#OFP_GroundSpeed').val(Calcs.GroundSpeed);

    $('#OFP_ETE').val(secondsToHms(Calcs.ETE, true));

    $('#OFP_TimeDestToAlt').val(secondsToHms(Calcs.ETE_Dst_Alt, true));

    $('#OFP_Distance').val(getDisplayDistance(Calcs.Dist));
    $('#OFP_DistDestToAlt').val(getDisplayDistance(Calcs.Dist_Dst_Alt));

    $('#OFP_FuelBurnEnroute').val(ShowFuelDisplayUnits(Math.round(Calcs.FuelEnroute + Calcs.FuelEnroute_Dst_Alt)));

    $('#OFP_FuelRequired').val(
      'Enroute: ' +
        ShowFuelDisplayUnits(Calcs.FuelEnroute) +
        '\n' +
        'Alternate: ' +
        ShowFuelDisplayUnits(Calcs.FuelEnroute_Dst_Alt) +
        '\n' +
        'Reserve: ' +
        ShowFuelDisplayUnits(FP.ReserveAmount) +
        '\n' +
        'Taxi: ' +
        ShowFuelDisplayUnits(Calcs.FuelRequired.taxi) +
        '\n' +
        'Holding: ' +
        ShowFuelDisplayUnits(Calcs.FuelRequired.holding) +
        '\n' +
        'Additional: ' +
        ShowFuelDisplayUnits(Calcs.FuelRequired.additional) +
        '\n'
    );

    $('#OFP_FuelPlanHLDR').html(FuelPlanHTML);

    if ($('#OFP_PilotSignature').html() == '') {
      $('#ITIN-OFP_NEXT_BTN').prop('disabled', true);
      $('#OFP_PilotSignatureBTN').show();
    } else {
      $('#ITIN-OFP_NEXT_BTN').prop('disabled', false);
      $('#OFP_PilotSignatureBTN').hide();
    }
  }

  if (page == 'ITIN-WEIGHTBALANCE') {
    $("input[name='slider']").unbind('slidestop', weightChange);
    $("input[name='slider']").unbind('change', weightChangeP);
    $("input[name='slider']").unbind('keyup', weightChange);

    $("input[name='VariableLongslider']").unbind('slidestop', LongChange);
    $("input[name='VariableLongslider']").unbind('keyup', LongChange);

    $("input[name='VariableLatslider']").unbind('slidestop', LatChange);
    $("input[name='VariableLatslider']").unbind('keyup', LatChange);

    $("input[name='slider']").bind('slidestop', weightChange);
    $("input[name='slider']").bind('change', weightChangeP);
    $("input[name='slider']").bind('keyup', weightChange);

    $("input[name='VariableLongslider']").bind('slidestop', LongChange);
    $("input[name='VariableLongslider']").bind('keyup', LongChange);

    $("input[name='VariableLatslider']").bind('slidestop', LatChange);
    $("input[name='VariableLatslider']").bind('keyup', LatChange);

    var PilotWeight = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === USER_PK);
    if (PilotWeight != undefined) {
      PilotWeight = PilotWeight.bodyWeight;
    } else {
      PilotWeight = 0;
    }

    var oldlabel = $("label[for='Pilot']")[0].innerHTML;
    oldlabel = oldlabel.split('<div class="WBSTATION"');

    $("label[for='Pilot']").html(
      '<font size="-2">Pilot<br />' +
        convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', WBUnitLbl.Type) +
        ' ' +
        WBUnitLbl.Weight +
        '<div id="Pilot_NAME"></div>' +
        '<div class="WBSTATION"' +
        oldlabel[1]
    );

    $("input[name='slider']").val(convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', WBUnitLbl.Type));
    $('#slider').attr('max', convertToUserUNITS(350, 'Weight', 'Imperial', WBUnitLbl.Type)).slider('refresh');
    $("input[name='slider']").slider('refresh');
    if (GROUP_DATA.FuelCache == 1 && GROUP_DATA.FC_Itin_Capture == 1) {
      FC_Uplift.div = 'ItinFuelUplift';
      FC_Uplift.legIndex = -1;
      FC_Uplift.curTarget = 'Itinerary';
      FC_UpliftHTML(FC_Uplift.div, '');
    }
  }
  if (page == 'ITIN-REVIEWBALANCE') {
    var elements = document.getElementById('Canvas');
    elements.getElementsByTagName('svg')[0].id = 'svgLongCanvas';
    var latgraph = true;
    if (elements.getElementsByTagName('svg')[1] != undefined)
      elements.getElementsByTagName('svg')[1].id = 'svgLatCanvas';
    else latgraph = false;
    svgAsDataUri(document.getElementById('svgLongCanvas'), 1, function (uriLong) {
      var imageLong = new Image();
      imageLong.src = uriLong;
      imageLong.onload = function () {
        if (latgraph) {
          svgAsDataUri(document.getElementById('svgLatCanvas'), 1, function (uriLat) {
            var imageLat = new Image();
            imageLat.src = uriLat;
            imageLat.onload = function () {
              var canvas = document.createElement('canvas');
              canvas.width = 300;
              canvas.height = 220;
              var context = canvas.getContext('2d');
              context.drawImage(imageLong, 0, 0);
              context.drawImage(imageLat, 150, 0);
              var png = canvas.toDataURL('image/png');

              WandB_PNG = png;
            };
          });
        } else {
          var canvas = document.createElement('canvas');
          canvas.width = 150;
          canvas.height = 220;
          var context = canvas.getContext('2d');
          context.drawImage(imageLong, 0, 0);
          var png = canvas.toDataURL('image/png');

          WandB_PNG = png;
        }
      };
    });
  }
}

function CoPilotChange() {
  var sicName = $('#SICs').val();
  var PilotWeight = STAFF_LIST.find(({ chatname }) => chatname === sicName);
  if (PilotWeight != undefined) {
    PilotWeight = PilotWeight.bodyWeight;
  } else {
    return;
  }
  var POS = 'FwdPax';
  if ($('#Co-Pilot').val() != undefined) {
    POS = 'Co-Pilot';
  } else if ($('#CoPilot').val() != undefined) {
    POS = 'CoPilot';
  } else if ($('#FwdPax').val() == undefined) {
    return;
  }

  var oldlabel = $("label[for='" + POS + "']")[0].innerHTML;
  oldlabel = oldlabel.split('<div class="WBSTATION"');

  $("label[for='" + POS + "']").html(
    '<font size="-2">' +
      POS +
      '<br />' +
      convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', WBUnitLbl.Type) +
      ' ' +
      WBUnitLbl.Weight +
      '<div id="FwdPax_NAME">' +
      '</div>' +
      '<div class="WBSTATION"' +
      oldlabel[1]
  );
  $('#' + POS).val(convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS));
  calculateWB();
}

async function displayCapabilityDialogOrNavigate(currentPage, nextPage) {
  console.log('displayCapabilityDialogOrNavigate');
  const navigateFunc = () => {
    $.mobile.changePage($('#' + nextPage), {
      reverse: false,
      changeHash: false,
    });
  };

  let tdgNotSelected = !$('#TDG').is(':checked');

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    tdgNotSelected = !$('#TDG-REVAMP').is(':checked');
  }

  if (tdgNotSelected) {
    navigateFunc();
    return;
  }

  let notOnTDG = currentPage !== 'ITIN-TDG';
  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    notOnTDG = currentPage !== 'ITIN-TDG-REVAMP';
  }

  if (notOnTDG) {
    navigateFunc();
    return;
  }

  const noInvalidCapabilities = !(await window.vueApp.utilities.capabilities.oneOrMoreCapabilityIsInvalid(
    'CreateFlightWithTdg'
  ));

  if (noInvalidCapabilities) {
    navigateFunc();
    return;
  }

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('invalid capabilities'),
    headerClose: false,
    buttonPrompt: iTrans('one or more capability'),
    buttons: {
      Okay: {
        click: function () {
          navigateFunc();
        },
      },
    },
  });
}

function INIT_ITIN_PAGE() {
  itin_RefreshAttachmentList();
  popTimezoneList('#ITIN_StartTimezone');
  popTimezoneList('#ITIN_EndTimezone');
  if (SCH_DATA.Flight_Data == null) {
    $('#ITIN_StartTimezone').val(CurrentTimeZone).selectmenu('refresh');
    $('#ITIN_EndTimezone').val(CurrentTimeZone).selectmenu('refresh');
  } else {
    if (SCH_DATA.Flight_Data.Quote != null) {
      $('#ITIN_StartTimezone').val(SCH_DATA.Flight_Data.Quote.StartTimezone).selectmenu('refresh');
      $('#ITIN_EndTimezone').val(SCH_DATA.Flight_Data.Quote.EndTimezone).selectmenu('refresh');
    } else {
      $('#ITIN_StartTimezone').val(CurrentTimeZone).selectmenu('refresh');
      $('#ITIN_EndTimezone').val(CurrentTimeZone).selectmenu('refresh');
    }
  }

  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    $('#returnTimeTXT').html(iTrans('ETA Time') + ' - UTC (GMT+00)');
  } else {
    $('#returnTimeTXT').html(iTrans('ETA Time') + ' - ' + getLocalTZLabel());
  }

  $('#itinClients').selectmenu('refresh', true);
  if (
    (userShouldHaveFDT(USER_RIGHTS.staffType) && $('#Pilots').val() == null) ||
    (userShouldHaveFDT(USER_RIGHTS.staffType) && $('#Pilots').val() == '')
  ) {
    $('#Pilots').val(chatname);
  } else {
    if (!SCH_DATA.isLoading) {
      if ($('#Pilots').val() != chatname) $('#Pilots').val('');
    }
  }
  $('#Pilots').selectmenu('refresh', true);
  $('#ITIN_ResponsiblePerson').val(ItinResponsiblePersonPK).selectmenu('refresh', true);
  $('#SICs').selectmenu('refresh', true);
  ITIN_PopJobs(true);
  timeChanged();
  if (GROUP_DATA.Pax_Man == 1) {
    PAX_Init_ITIN_Manifesting(); //in menu-PaxManifesting.js
  }

  if (FORMS > 0) {
    FormsInItineraryCreator.instance();
  }

  if (GROUP_DATA.FlightReports == 1) {
    $('#ITINERARY_FRNUM_HLDR').show();
  } else {
    $('#ITINERARY_FRNUM_HLDR').hide();
  }
  if (GROUP_DATA.ITIN_hideDetails == 1) {
    $('#ITINERARY_Details_HLDR').hide();
  } else {
    $('#ITINERARY_Details_HLDR').show();
  }
}

function itinNAVprev() {
  var page;
  switch (itinNOW) {
    case 'ITIN-OPTIONAL':
      if (SCHEDULER_OVERRIDE) {
        //return to scheduler page
        page = 'Schedule_Item';
      } else {
        if ((FLIGHTDOCS == 1 && AC_data.TechDispatch == 1) || (GROUP_DATA.WinAir == 1 && AC_data.TechDispatch == 1))
          page = 'ITIN-TECH-DISPATCH';
        else page = 'Itinerary_Page';
      }
      break;
    case 'ITIN-TECH-DISPATCH':
      page = 'Itinerary_Page';
      break;
    case 'ITIN-WEIGHTBALANCE':
      if (WANDB == '1') page = 'ITIN-OPTIONAL';
      else if ((FLIGHTDOCS == 1 && AC_data.TechDispatch == 1) || (GROUP_DATA.WinAir == 1 && AC_data.TechDispatch == 1))
        page = 'ITIN-TECH-DISPATCH';
      else page = 'Itinerary_Page';
      break;
    case 'ITIN-REVIEWBALANCE':
      page = 'ITIN-WEIGHTBALANCE';
      break;
    case 'ITIN-TDG':
      if (WANDB == '1') page = 'ITIN-REVIEWBALANCE';
      else page = 'Itinerary_Page';

      break;
    case 'ITIN-ASSETS':
      if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
      else {
        if (WANDB == '1') page = 'ITIN-REVIEWBALANCE';
        else page = 'Itinerary_Page';
      }
      break;
    case 'FRMS-DISPLAY':
      if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else {
        if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
        else {
          if (WANDB == '1') page = 'ITIN-REVIEWBALANCE';
          else page = 'Itinerary_Page';
        }
      }
      break;
    case 'RA_Form':
      if (GROUP_DATA.PULSAR_FRMS == 1 && !SCHEDULER_OVERRIDE) page = 'FRMS-DISPLAY';
      else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else {
        if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
        else {
          if (WANDB == '1') page = 'ITIN-REVIEWBALANCE';
          else page = 'Itinerary_Page';
        }
      }
      break;
    case 'ItinLegPlanner':
      if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
      else page = 'ITIN-ROUTEMAP';
      break;
    case 'ITIN-INPUT':
      if (RISK_MGMT == 1) page = 'RA_Form';
      else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
      else if (WANDB == '1') page = 'ITIN-REVIEWBALANCE';
      else page = 'Itinerary_Page';
      break;
    case 'ITIN-TDG-REVAMP':
      page = 'ITIN-INPUT';
      break;
    case 'ITIN-ROUTEMAP':
      page = 'ITIN-INPUT';
      if (!SCHEDULER_OVERRIDE && TDGACCESS == '1' && VueBridge.hasFeature('TransportationOfDangerousGoods'))
        page = 'ITIN-TDG-REVAMP';
      else if (SCHEDULER_OVERRIDE && ASSETTRACKING == 1) page = 'ITIN-ASSETS';
      else if (SCHEDULER_OVERRIDE && TDGACCESS == 1 && !VueBridge.hasFeature('TransportationOfDangerousGoods'))
        page = 'ITIN-TDG';
      else if (SCHEDULER_OVERRIDE && WANDB == 1) page = 'ITIN-REVIEWBALANCE';
      else if (SCHEDULER_OVERRIDE) page = 'Schedule_Item';
      break;

    case 'ITIN-OFP':
      page = 'ITIN-ROUTEMAP';
      break;
    case 'ITIN-FINALIZE':
      page = 'ITIN-ROUTEMAP';
      if (GROUP_DATA['OFP'] == 1 && AC_data.OFP_REQ == 1) page = 'ITIN-OFP';
      break;
    case 'ITIN-CONFIRMATION':
      page = 'ITIN-FINALIZE';
      break;
  }
  if (page == 'Itinerary_Page' && SCH_DATA.isLoading && SCH_DATA.returnToScheduler) {
    page = 'Schedule_Page';
  }
  $.mobile.changePage($('#' + page), {
    reverse: true,
    changeHash: false,
  });
  itinNOW = page;
}

var Itin_Pilot_Signature;
function ITIN_Pilot_Signature() {
  //goto signature page
  $.mobile.changePage($('#ITIN-SignaturePage'), {
    transition: 'slide',
    changeHash: false,
    reverse: false,
  });
  $('#ITIN-signature-pad-wrapper').html('');
  $('#ITIN-signature-pad-wrapper').html('<canvas id="ITIN-signature-pad" class="signature-pad"></canvas>');
  var canvas = document.getElementById('ITIN-signature-pad');
  Itin_Pilot_Signature = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    Itin_Pilot_Signature.clear(); // otherwise isEmpty() might return incorrect value
  }
  window.addEventListener('resize', resizeCanvas);
  resizeCanvas();
}

function ITIN_ClearSignature() {
  Itin_Pilot_Signature.clear();
}

function ITIN_ReturnOFP() {
  $.mobile.changePage($('#ITIN-OFP'), {
    transition: 'slide',
    changeHash: false,
    reverse: true,
  });
  if (!Itin_Pilot_Signature.isEmpty()) {
    var data = Itin_Pilot_Signature.toDataURL('image/png');
    signatureDATA.data = data;
    signatureDATA.text = chatname;
    var html = '<center><img src="' + data + '" width="321px" height="150px"/><br />';
    html += '<b>' + iTrans('Pilot') + ': ' + chatname + '</b></center>';
    $('#OFP_PilotSignature').html(html);
    $('#OFP_PilotSignatureBTN').hide();
    $('#ITIN-OFP_NEXT_BTN').prop('disabled', false);
    setTimeout(function () {
      window.scrollTo(0, 5000);
    }, 500);
  } else {
    signatureDATA.data = '';
    signatureDATA.text = '';
    $('#PilotSignatureHLDR').html('');
    $('#OFP_PilotSignatureBTN').show();
    $('#ITIN-OFP_NEXT_BTN').prop('disabled', true);
  }
}

function fileaclist() {
  $('#OFP_PilotSignature').html('');
  signatureDATA.data = '';
  signatureDATA.text = '';

  resetMeasureData();
  UpdateWBUnitLBL(LOCALSTORAGE.UserUNITS);
  ItinJobDefault = 0;
  SCHEDULER_OVERRIDE = false;
  $('#MobilizeRoutePlanBTN').prop('disabled', true);
  $('#RoutePlanCHK').attr('checked', false);
  //if (flightRoutes !== null) map.removeLayer(flightRoutes);
  RoutePlans = []; //clear existing route plans
  ASSET_ADDED = []; //clear assets array
  $('#Itin_Assets2').html('');
  $('#ITIN_JOB_PDF').html(''); //clear PDF
  $('#RoutePlanHldr').html('');
  PostRoute = null;
  $('#TDG-REVAMP').prop('checked', false);
  $('#TDG').prop('checked', false);
  $('#TDG_Itin_Manifest').html('');
  $('#select_Itin_Assets option:contains(No Template Selected)').attr('selected', 'selected');
  getDeviceLocation(); //Get device location
  $('#FileItinerary').html("<h1 class='limit-table'>" + iTrans('Retrieving Aircraft Status') + '...</h1>');
  if (ONLINE) {
    $('#FileItineraryNote').html('');
    if (LOW_BANDWIDTH) {
      Itin_StartOfflineMode();
      $('#FileItineraryNote').html(
        '<h3>' +
          iTrans('Low Bandwidth Mode Enabled') +
          '</h3>' +
          iTrans(
            'If you would like to check for updated aircraft status, scheduled aircraft, templates and clients click on the update button below'
          ) +
          '.<button data-icon="refresh" data-mini="true" onClick="Itin_RetrieveData(true);" >' +
          iTrans('Manually Check For Changes Now') +
          '</button>'
      );
      $('#FileItineraryNote').enhanceWithin();
    } else {
      Itin_RetrieveData();
    }
  } else {
    Itin_StartOfflineMode();
  }
}
function Itin_RetrieveData(manual = false) {
  if (manual) {
    $('#FileItineraryNote').html('');
    $('#FileItinerary').html("<h3 class='limit-table'>" + iTrans('Checking Now') + '...</h3>');
    ITIN_GETSCHEDULED = true;
  }
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getDisabled', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'None') {
        var empty = [];
        compareOpen(empty);
      }
      if (json_data[0].ServerResponse == 'Offline') {
        $('#FileItinerary').html('<h1>' + iTrans('Offline Cannot File') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getDisabled');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      compareOpen(json_data);
    }
    popItinAssetTemplates();
  }).fail(function (jqXHR, status, error) {
    Itin_StartOfflineMode();
    Itin_PopulateClientDataFeed();
  });
  Itin_PopulateClientDataFeed();
}

function Itin_PopulateClientDataFeed(refreshPK = null) {
  const itinClientFeedSelect = $('#itinClients');

  try {
    itinClientFeedSelect.selectmenu('refresh');
  } catch (e) {
    console.log('Failed to refresh the itin client select, so it will be recreated.');
    itinClientFeedSelect.selectmenu();
  }

  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getClients', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'None') {
        $('#itinClients2').hide();
        localStorageDB.setItem('itinClients', '[]');
      }
      if (json_data[0].ServerResponse == 'Offline') {
        if (LOCALSTORAGE.itinClients == undefined) return;
        var json_data = LOCALSTORAGE.itinClients;
        itinClientFeedSelect.empty();
        itinClientFeedSelect.append($('<option />').val(0).text('Not Enabled'));
        for (var i in json_data) {
          if (json_data[i].Enabled == 1)
            itinClientFeedSelect.append($('<option />').val(json_data[i].ClientPK).text(json_data[i].Client));
        }
        if (refreshPK != null) {
          itinClientFeedSelect.val(refreshPK).selectmenu('refresh');
          if (refreshPK != 0) itinClientFeedSelect.selectmenu('disable');
        } else {
          itinClientFeedSelect.selectmenu('enable');
        }
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getDisabled');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      $('#itinClients2').show();
      localStorageDB.setItem('itinClients', JSON.stringify(json_data));

      itinClientFeedSelect.empty().append($('<option />').val(0).text('Not Enabled'));

      for (let i in json_data) {
        if (json_data[i].Enabled > 0) {
          itinClientFeedSelect.append($('<option />').val(json_data[i].ClientPK).text(json_data[i].Client));
        }
      }

      if (refreshPK != null) {
        itinClientFeedSelect.val(refreshPK).selectmenu('refresh');
        if (refreshPK != 0) {
          itinClientFeedSelect.selectmenu('disable');
        }
      } else {
        itinClientFeedSelect.selectmenu('enable');
      }
    }
    //popItinAssetTemplates();
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    if (LOCALSTORAGE.itinClients == undefined) return;
    var json_data = LOCALSTORAGE.itinClients;
    itinClientFeedSelect.empty();
    itinClientFeedSelect.append($('<option />').val(0).text('Not Enabled'));
    for (var i in json_data) {
      if (json_data[i].Enabled == 1)
        itinClientFeedSelect.append($('<option />').val(json_data[i].ClientPK).text(json_data[i].Client));
    }
    if (refreshPK != null) {
      itinClientFeedSelect.val(refreshPK).selectmenu('refresh');
      if (refreshPK != 0) itinClientFeedSelect.selectmenu('disable');
    } else {
      itinClientFeedSelect.selectmenu('enable');
    }
  });
}

function Itin_StartOfflineMode() {
  $('#FileItineraryNote').html(
    '<b>' +
      iTrans(
        'You are currently offline. If you create a new flight that overlaps with an existing open itinerary in the system, when this itinerary is synchronized to the server it will be flagged as closed.'
      ) +
      '</b>'
  );
  $('#itinClients').empty();
  $('#itinClients').append($('<option />').val(0).text(iTrans('Not Enabled')));
  if (LOCALSTORAGE.itinClients != undefined) {
    var itinClients = LOCALSTORAGE.itinClients;
    for (var i in itinClients) {
      $('#itinClients').append($('<option />').val(itinClients[i].ClientPK).text(itinClients[i].Client));
    }
  }
  var disabledList = [];
  for (var a in Follow_Data) {
    var A = Follow_Data[a];
    if (A.Itin != null) {
      disabledList.push({
        ident: A.AC.ident,
        status: 'OPEN',
      });
    }
  }
  compareOpen(disabledList);
}

function Itin_getScheduled(openData) {
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-Scheduler-AJAX.php?action=getSchedAC', function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'None') {
          $('#ITIN_SCHEDULED').hide();
        }
        if (json_data[0].ServerResponse == 'Offline') {
          $('#ITIN_SCHEDULED').html('<h1>Offline Cannot File</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getDisabled');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        $('#ITIN_SCHEDULED').show();
        $('#ITIN_SCHEDULED').html(
          '<h3 class="limit-table">Scheduled Flights</h3><div style="width: 100%; overflow: auto"><table id="AC_Table_Itinerary_Scheduled" class="limit-table last-td-fill">' +
            '<thead>' +
            '<tr>' +
            '<th>' +
            iTrans('Aircraft') +
            '</th>' +
            '<th>' +
            iTrans('Client') +
            '</th>' +
            '<th>' +
            iTrans('Job') +
            '</th>' +
            '<th>' +
            iTrans('Model') +
            '</th>' +
            '</tr>' +
            '</thead>' +
            '<tbody><tr>'
        );
        SCHEDULED_TEMPLATES = json_data;
        var schedACID = [];
        for (var i in json_data) {
          //if (json_data[i].Client_ID != 0) $("#itinClients").append($("<option />").val(json_data[i].Client_ID).text(json_data[i].Client));

          if (json_data[i].Associated != null) {
            var A = json_data[i].Associated;
            if (A.BookedAC.length > 0) {
              for (var I in A.BookedAC) {
                var B = A.BookedAC[I];
                var disabled = '';
                for (var x in openData) {
                  if (B.ident == openData[x].ident) {
                    disabled = 'disabled';
                  }
                }

                $('#AC_Table_Itinerary_Scheduled tr:last').after(
                  "<tr><td><button onClick='LoadScheduleTemplate(" +
                    i +
                    ',' +
                    I +
                    ", false)' data-mini='true' class='cirro-btn' " +
                    disabled +
                    "><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
                    B.ident +
                    ' ' +
                    SCH_GetACLegTXT(B) +
                    '</div></button></td><td>' +
                    json_data[i].Client_Text +
                    '</td><td>' +
                    json_data[i].Job_Text +
                    '</td><td>' +
                    B.AcType +
                    '</td></tr>'
                );
              }
            } else {
              $('#ITIN_SCHEDULED').hide();
            }
          }
        }
        $('#ITIN_SCHEDULED').enhanceWithin();
      }
      //popItinAssetTemplates();
    }).fail(function (jqXHR, status, error) {
      //AJAXFAIL(error);
      $('#ITIN_SCHEDULED').hide();
    });
  } else {
    $('#ITIN_SCHEDULED').hide();
  }
}
var SCHEDULED_TEMPLATES = [];
var ITINERARY_DEFAULT_PAX_MANIFEST = {
  DefaultList: [],
  Pax_Legs: [],
  HasVIP: 0,
  NoResult: 1,
};
function SetItinJob(i, index) {
  ItinJobDefault = i;
  ITINERARY_DEFAULT_PAX_MANIFEST = SCHEDULED_TEMPLATES[index];
}
function LoadScheduleTemplate(i, I, returnToScheduler = true) {
  resetItineraryData();
  var Itinerary = '';
  if (SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].Template != '') {
    Itinerary = JSON.parse(SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].Template);
    SCH_DATA.Template = Itinerary;
    SCH_DATA.LEGS = Itinerary.LEGS;
    if (Itinerary.FP != undefined) {
      try {
        FuelPlans = JSON.parse(Itinerary.FP);
      } catch (e) {
        FuelPlans = [];
      }
    } else FuelPlans = [];
  }

  SCH_DATA.isLoading = true;
  SCH_DATA.returnToScheduler = returnToScheduler;
  SCH_DATA.Flight_Data = SCHEDULED_TEMPLATES[i].Associated.BookedAC[I];
  BILLING_BACKUP_Cfields = JSON.parse(JSON.stringify(SCHEDULED_TEMPLATES[i].Associated.Cfields));
  SCH_DATA.ReturnTime = '';
  SCH_DATA.ReturnDate = '';
  if (SCH_DATA.Flight_Data.Quote != null) {
    if (SCH_DATA.Flight_Data.Quote.Legs.length > 0) {
      var lastLeg = SCH_DATA.Flight_Data.Quote.Legs[SCH_DATA.Flight_Data.Quote.Legs.length - 1];
      let returnTZ = adjustTimezone(lastLeg.ArrivalDate, lastLeg.ArrivalTime, lastLeg.Timezone, CurrentTimeZone);
      SCH_DATA.ReturnDate = returnTZ.date;
      SCH_DATA.ReturnTime = returnTZ.time;
    }
  }
  var BookedStaff = SCHEDULED_TEMPLATES[i].Associated.BookedStaff;
  SCH_DATA.PIC = '';
  SCH_DATA.SIC = '';
  if (userShouldHaveFDT(USER_RIGHTS.staffType)) {
    SCH_DATA.PIC = USER_RIGHTS.chatname;
  }
  for (var s in BookedStaff) {
    var S = BookedStaff[s];
    if (userShouldHaveFDT(S.StaffType)) {
      if (S.AC_ID == 0) {
        if (SCH_DATA.PIC == '') {
          SCH_DATA.PIC = S.chatname;
        }
      }
      if (S.AC_ID == SCH_DATA.Flight_Data.PrimaryKey) {
        if (S.Role == 'PIC') {
          SCH_DATA.PIC = S.chatname;
        }
        if (S.Role == 'SIC') {
          SCH_DATA.SIC = S.chatname;
        }
      }
    }
  }
  SCH_DATA.JobID = SCHEDULED_TEMPLATES[i].Job_ID;
  SCH_DATA.ClientID = SCHEDULED_TEMPLATES[i].Client_ID;
  SCH_DATA.SCH_AircraftPK = SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].ItemPrimaryKey;
  $('#ITIN_JOBClientID').val(SCHEDULED_TEMPLATES[i].Client_ID);
  SCH_DATA.SCH_BookingPK = SCHEDULED_TEMPLATES[i].PrimaryKey;

  ITINERARY_DEFAULT_PAX_MANIFEST = SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].Pax_Manifest;
  ITIN_Pax_Manifest.DefaultList = JSON.parse(JSON.stringify(ITINERARY_DEFAULT_PAX_MANIFEST.DefaultList));
  ITIN_Pax_Manifest.Pax_Legs = JSON.parse(JSON.stringify(ITINERARY_DEFAULT_PAX_MANIFEST.Pax_Legs));
  ITIN_Pax_Manifest.HasVIP = ITINERARY_DEFAULT_PAX_MANIFEST.HasVIP;
  ITIN_BookingPK = SCHEDULED_TEMPLATES[i].PrimaryKey;
  ITIN_SCH_AircraftPK = SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].ItemPrimaryKey;

  LoadSCHTemplate(SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].ident);
  //selectTemplate(Itinerary);
}

function popItinAssetTemplates() {
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getAssetTemplates', function (json_data) {
    if (json_data[0] != undefined) {
      if (json_data[0].ServerResponse != undefined) {
        if (json_data[0].ServerResponse == 'Offline') {
          $('#FileItinerary').html('<h1>' + iTrans('Offline Cannot File') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'popItinAssetTemplates');
          $('#FileItinerary').html('<h3>' + iTrans('Unable to revreive aircraft data') + '</h3>');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        var Templates = json_data;
        $('#select_Itin_Assets').empty();
        $('#select_Itin_Assets').append(
          '<option selected="selected" value="-1">' + iTrans('No Template Selected') + '</option>'
        );
        for (i in Templates) {
          $('#select_Itin_Assets').append('<option value="' + i + '">' + Templates[i].Name + '</option>');
        }
        ItinAssetTemplates = json_data;
      }
    }
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $('#FileItinerary').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}
var ItinAssetTemplates = null;

async function compareOpen(openData) {
  $('#FileItinerary').html('');

  const acRows = await AcTable.query().select('', [], ['AC_data']);

  ALLAC_data = acRows.map((row) => row.AC_data);

  //do the loop

  var RecentAC = [];
  if (LOCALSTORAGE.RecentAC != undefined) RecentAC = LOCALSTORAGE.RecentAC;
  else localStorageDB.setItem('RecentAC', '[]');

  $('#FileItinerary').html('<div id="ITIN_SCHEDULED"></div><div id="ITIN_RecentList"></div>');
  if ((SCHEDULER == 1 && !LOW_BANDWIDTH) || (SCHEDULER == 1 && ITIN_GETSCHEDULED)) {
    ITIN_GETSCHEDULED = false;
    Itin_getScheduled(openData); //load list of aircraft scheduled with or without a template
  } else $('#ITIN_SCHEDULED').hide();
  $('#ITIN_RecentList').append(
    '<h3 class="limit-table">' +
      iTrans('Recent Aircraft') +
      '</h3><div style="width: 100%; overflow: auto"><table id="AC_Table_Itinerary_Recent" class="limit-table last-td-fill nowrap wrap-description">' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('New Flight') +
      '</th>' +
      '<th>' +
      iTrans('Load Template') +
      '</th>' +
      '<th>' +
      iTrans('Model') +
      '</th>' +
      '<th>' +
      iTrans('Status') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody><tr>'
  );
  $('#FileItinerary').append(
    '<h3 class="limit-table">' +
      iTrans('Aircraft List') +
      '</h3><div style="width: 100%; overflow: auto"><table id="AC_Table_Itinerary" data-filter="true" class="limit-table last-td-fill nowrap wrap-description">' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('New Flight') +
      '</th>' +
      '<th>' +
      iTrans('Load Template') +
      '</th>' +
      '<th>' +
      iTrans('Model') +
      '</th>' +
      '<th>' +
      iTrans('Status') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody><tr>'
  );
  var ShowRecent = false;
  for (i in ALLAC_data) {
    var Iopen = false;
    var status = null;
    for (x in openData) {
      if (ALLAC_data[i].ident == openData[x].ident) {
        Iopen = true;
        status = openData[x].status;
      }
    }
    var Recent = false;
    var RecentList = [];
    for (var a in RecentAC) {
      if (RecentAC[a] == ALLAC_data[i].ident) {
        Recent = true;
        ShowRecent = true;
      }
    }
    var ACD = ALLAC_data[i];
    if (ACD.Hibrenated == 1) {
      if (!Recent)
        $('#FileItinerary tr:last').after(
          "<tr><td><button id='itinAC" +
            i +
            "' value='" +
            ALLAC_data[i].ident +
            "' data-mini='true' class='cirro-btn' disabled> " +
            ALLAC_data[i].ident +
            ' (' +
            iTrans('Hibernated') +
            ")</div></button></td><td><button id='loaditinAC" +
            i +
            "' value='" +
            ALLAC_data[i].ident +
            "' data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
            iTrans('Import Flight') +
            '</div></button></td><td>' +
            ALLAC_data[i].AcType +
            '<td>' +
            ACD.ServiceReason +
            '</td></tr>'
        );
      else
        $('#ITIN_RecentList tr:last').after(
          "<tr><td><button data-mini='true' class='cirro-btn' disabled> " +
            ALLAC_data[i].ident +
            ' (' +
            iTrans('Hibernated') +
            ")</div></button></td><td><button data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
            iTrans('Import Flight') +
            '</div></button></td><td>' +
            ALLAC_data[i].AcType +
            '<td>' +
            ACD.ServiceReason +
            '</td></tr>'
        );
      continue;
    }
    if (ACD.Serviceable == 0) {
      if (!Recent)
        $('#FileItinerary tr:last').after(
          "<tr><td><button id='itinAC" +
            i +
            "' value='" +
            ALLAC_data[i].ident +
            "' data-mini='true' class='redbg cirro-btn' disabled> " +
            ALLAC_data[i].ident +
            "</div></button></td><td><button id='loaditinAC" +
            i +
            "' value='" +
            ALLAC_data[i].ident +
            "' data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
            iTrans('Import Flight') +
            '</div></button></td><td>' +
            ALLAC_data[i].AcType +
            '<td>' +
            ACD.ServiceReason +
            '</td></tr>'
        );
      else
        $('#ITIN_RecentList tr:last').after(
          "<tr><td><button data-mini='true' class='redbg cirro-btn' disabled> " +
            ALLAC_data[i].ident +
            "</div></button></td><td><button data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
            iTrans('Import Flight') +
            '</div></button></td><td>' +
            ALLAC_data[i].AcType +
            '<td>' +
            ACD.ServiceReason +
            '</td></tr>'
        );
      continue;
    }
    if (Iopen) {
      if (ALLAC_data[i].AcType == 'CUSTOM') {
        if (!Recent)
          $('#FileItinerary tr:last').after(
            "<tr><td><button id='itinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button id='loaditinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].Custom_Type +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        else
          $('#ITIN_RecentList tr:last').after(
            "<tr><td><button data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].Custom_Type +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
      } else {
        if (!Recent)
          $('#FileItinerary tr:last').after(
            "<tr><td><button id='itinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button id='loaditinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].AcType +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        else
          $('#ITIN_RecentList tr:last').after(
            "<tr><td><button data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button data-mini='true' class='cirro-btn' disabled><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].AcType +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
      }
    } else {
      if (ALLAC_data[i].AcType == 'CUSTOM') {
        if (!Recent)
          $('#FileItinerary tr:last').after(
            "<tr><td><button id='itinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button id='loaditinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' value='" +
              ALLAC_data[i].ident +
              "'><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].Custom_Type +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        else
          $('#ITIN_RecentList tr:last').after(
            "<tr><td><button id='itinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button id='loaditinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' value='" +
              ALLAC_data[i].ident +
              "'><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].Custom_Type +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
      } else {
        if (!Recent)
          $('#FileItinerary tr:last').after(
            "<tr><td><button id='itinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button id='loaditinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' value='" +
              ALLAC_data[i].ident +
              "'><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].AcType +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        else
          $('#ITIN_RecentList tr:last').after(
            "<tr><td><button id='itinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
              ALLAC_data[i].ident +
              "</div></button></td><td><button id='loaditinAC" +
              i +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' value='" +
              ALLAC_data[i].ident +
              "'><div class='vertical-center-container'><img src='./images/import.svg' /> " +
              iTrans('Import Flight') +
              '</div></button></td><td>' +
              ALLAC_data[i].AcType +
              '<td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
      }
      if (Recent) {
        $('#itinAC_R' + i).click(fileaclistSelect);
        $('#loaditinAC_R' + i).click(getFlightTemplates);
      }
      $('#itinAC' + i).click(fileaclistSelect);
      $('#loaditinAC' + i).click(getFlightTemplates);
    }
  }
  if (!ShowRecent) $('#ITIN_RecentList').html('');
  $('#FileItinerary tr:last').after('</tbody></table></div>');
  $('#FileItinerary').enhanceWithin();
  //todo Load Scheduler Triggered Event
}

function SCHnewFlightAircraft(e, AC_PK) {
  resetItineraryData();
  SCHEDULER_OVERRIDE = true;

  //SCHEVENTDATA.PrimaryKey -- holds event primary Key
  SCH_DATA.Booking_ID = SCHEVENTDATA.PrimaryKey;
  SCH_DATA.AC_PK = AC_PK;
  SCH_DATA.Ident = e.value;
  SCH_DATA.LegIndex = 0;
  SCH_DATA.JobID = SCHEVENTDATA.Job_ID;
  SCH_DATA.ClientID = SCHEVENTDATA.Client_ID;
  SCH_DATA.LEGS = [];
  SCH_DATA.Pilot = '';
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e === false) e = [];
    REPORT_JOBS = e;
    var CF = JSON.parse(JSON.stringify(REPORT_JOBS[0].Cfields));
    Itin_JobName = 'Non Revenue';
    Itin_ClientName = 'Company';
    for (var f in REPORT_JOBS) {
      if (REPORT_JOBS[f].JobID == SCH_DATA.JobID) {
        CF = JSON.parse(JSON.stringify(REPORT_JOBS[f].Cfields));
        Itin_JobName = CF.Name;
        Itin_ClientName = CF.Business;
      }
    }
    ITIN_Cfields = CF;
  });
  for (var i in SCHEVENTDATA.Associated.BookedAC) {
    if (SCHEVENTDATA.Associated.BookedAC[i].ident == SCH_DATA.Ident)
      SCH_DATA.Template = SCHEVENTDATA.Associated.BookedAC[i].Template;
  }
  for (i in SCHEVENTDATA.Associated.BookedStaff) {
    var C = SCHEVENTDATA.Associated.BookedStaff[i];
    if (userShouldHaveFDT(C.StaffType) && C.AC_ID == AC_PK) SCH_DATA.Pilot = C.chatname;
  }
  if (SCH_DATA.Template == '') {
    //no template data start from scratch prompt to load an existing template for the aircraft
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Option'),
      headerClose: false,
      buttonPrompt: iTrans('Would you like to start a new itinerary template or load from an existing template.'),
      buttons: {
        [iTrans('Start New')]: {
          click: function () {
            fileaclistSelect(e.value); //continue and create new template from process
          },
        },
        [iTrans('Load Template')]: {
          click: function () {
            getFlightTemplates(SCH_DATA.Ident);
          },
        },
      },
    });
  } else {
    SCH_DATA.Template = JSON.parse(SCH_DATA.Template);
    SCH_DATA.LEGS = SCH_DATA.Template.LEGS;

    LoadSCHTemplate(SCH_DATA.Ident);
  }
}

function LoadSCHTemplate(ACIDENT) {
  acIdent = ACIDENT;
  PLANAC = acIdent;
  itin_UpdateHeadingIdent(acIdent);
  AC_data = '';
  TC_data = '';
  WB_data = '';
  PILOT_data = [];
  LOADABLE_data = '';
  FUEL_data = '';
  eacweight = '';
  eLat = '';
  eLong = '';
  ecofgLat = '';
  ecofgLong = '';
  error = '';
  searchtime = '';
  FuelType = 'Standard';
  FuelStandard = 'Standard';
  SeatType = 'Standard';
  SeatStandard = 'Standard';
  GraphName = 'Standard';
  GraphStandard = 'Standard';
  Loading = [];
  optLoad = [];
  tcNum = '';
  tcIndex = '';
  PaXnum = 0;
  SN = '';

  $('#WB_OPT').html('');
  $('#TCNUM').html('');
  $('#LongCanvas').html('');
  $('#LatCanvas').html('');
  $('#Seating').html('');
  $('#fuelLoad').html('');
  $('#bagLoad').html('');
  $('#optLoad').html('');
  $('#Pilots').empty();
  $('#ITIN_ResponsiblePerson').empty();
  $('#ITIN_ResponsiblePerson').append($('<option />').val('0').text(iTrans('System Tier 1 Users')));
  $('#PaX').html('');
  $('#Itin_Assets').html('');

  cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
    var data = e[0];

    TC_data = data.TC_data;
    WB_data = data.WB_Data;
    LOADABLE_data = data.LOADABLE_data;
    AC_data = data.AC_data;
    if (AC_data.Units != undefined) {
      AC_UNITS = AC_data.Units;
    }
    $('#WB_EQHTML').html('<b>' + iTrans('Aircraft Temp Config Rebuild Required') + '!</b>');
    if (AC_data.EQ_HTML != undefined) $('#WB_EQHTML').html(AC_data.EQ_HTML);
    acType = AC_data.AcType;
    PilotsTable.query()
      .select()
      .then(function (e) {
        if (e === false) PILOT_data = [];
        else PILOT_data = e;
        var Itinerary = SCH_DATA.Template;
        if (Itinerary != null) {
          if (Itinerary.FP != undefined) {
            try {
              FuelPlans = JSON.parse(Itinerary.FP);
            } catch (e) {
              FuelPlans = [];
            }
          } else FuelPlans = [];
        } else {
          FuelPlans = [];
        }
        selectTemplate(Itinerary);
      });
  });
}

function fileaclistSelect(ident, jobID = null, clientID = null, i = null, I = null) {
  ResetITIN_PaxManifest();
  if (typeof ident === 'object') {
    acIdent = this.value;
  } else {
    acIdent = ident;
  }

  if (jobID != null) {
    ItinJobDefault = jobID;
    ItinClientDefault = clientID;
    SCH_DATA.ClientID = clientID;
    SCH_DATA.JobID = jobID;
    SCH_DATA.isLoading = true;
    ITIN_BookingPK = 0;
  } else {
    SCH_DATA.ClientID = 0;
    SCH_DATA.JobID = 0;
    SCH_DATA.isLoading = false;
    SCH_DATA.LEGS = [];
    ItinJobDefault = 0;
    ItinClientDefault = 0;
    ITIN_BookingPK = 0;
  }
  if (i != null) {
    ITINERARY_DEFAULT_PAX_MANIFEST = SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].Pax_Manifest;
    ITIN_Pax_Manifest.DefaultList = JSON.parse(JSON.stringify(ITINERARY_DEFAULT_PAX_MANIFEST.DefaultList));
    ITIN_Pax_Manifest.Pax_Legs = JSON.parse(JSON.stringify(ITINERARY_DEFAULT_PAX_MANIFEST.Pax_Legs));
    ITIN_Pax_Manifest.HasVIP = ITINERARY_DEFAULT_PAX_MANIFEST.HasVIP;
    ITIN_BookingPK = SCH_DATA.SCH_BookingPK;
    ITIN_SCH_AircraftPK = SCHEDULED_TEMPLATES[i].Associated.BookedAC[I].ItemPrimaryKey;
  } else {
    ITIN_BookingPK = 0;
    ITIN_SCH_AircraftPK = 0;
  }
  ASSET_ADDED = [];
  $('#Itin_Assets2').html('');
  $('#Itin_Assets').html('');
  cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
    var data = e[0];

    try {
      var RecentAC = [];
      if (LOCALSTORAGE.RecentAC != undefined) RecentAC = LOCALSTORAGE.RecentAC;
      RecentAC.unshift(acIdent);
      RecentAC.length = 3;
      localStorageDB.setItem('RecentAC', JSON.stringify(RecentAC));
      LOCALSTORAGE.RecentAC = RecentAC;
      itin_UpdateHeadingIdent(acIdent);
      AC_data = '';
      TC_data = '';
      WB_data = '';
      PILOT_data = [];
      LOADABLE_data = '';
      FUEL_data = '';
      eacweight = '';
      eLat = '';
      eLong = '';
      ecofgLat = '';
      ecofgLong = '';
      error = '';
      searchtime = '';
      FuelType = 'Standard';
      FuelStandard = 'Standard';
      SeatType = 'Standard';
      SeatStandard = 'Standard';
      GraphName = 'Standard';
      GraphStandard = 'Standard';
      Loading = [];
      optLoad = [];
      tcNum = '';
      tcIndex = '';
      PaXnum = 0;
      SN = '';

      $('#WB_OPT').html('');
      $('#TCNUM').html('');
      $('#LongCanvas').html('');
      $('#LatCanvas').html('');
      $('#Seating').html('');
      $('#fuelLoad').html('');
      $('#bagLoad').html('');
      $('#optLoad').html('');
      $('#Pilots').empty();
      $('#Pilots').append($('<option />').val('').text(iTrans('Select Pilot for Aircraft')));
      $('#ITIN_ResponsiblePerson').empty();
      $('#ITIN_ResponsiblePerson').append($('<option />').val('0').text(iTrans('System Tier 1 Users')));
      $('#SICs').empty();
      $('#Clients').empty();
      $('#PaX').html('');
      TC_data = data.TC_data;
      WB_data = data.WB_Data;
      LOADABLE_data = data.LOADABLE_data;
      AC_data = data.AC_data;
      if (AC_data.Units != undefined) {
        AC_UNITS = AC_data.Units;
      }
      RAform = null;
      //alert(this.value);
      RISK_SMS = false; //don't display risk assessments as standalone
      //acIdent = this.value;
      FuelPlan = [];
      FuelPlans = [];
      RoutePlan = [];
      FuelRadius = null;
      var page;
      if (
        (FLIGHTDOCS == 1 && AC_data.TechDispatch == 1) ||
        (GROUP_DATA.WinAir == 1 && AC_data.TechDispatch == 1) ||
        (GROUP_DATA.Traxxall == 1 && AC_data.TechDispatch == 1) ||
        (GROUP_DATA.CAMP == 1 && AC_data.TechDispatch == 1)
      )
        page = 'ITIN-TECH-DISPATCH';
      else if (WANDB == '1') page = 'ITIN-OPTIONAL';
      else if (TDGACCESS == '1') page = 'ITIN-TDG';
      else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
      else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
      else if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
      else page = 'ITIN-INPUT';
      if ((GROUP_DATA.group == 'transportcanada' && SCH_DATA.isLoading) || (GPS_DEBUG && SCH_DATA.isLoading)) {
        page = 'ITIN-INPUT';
      }
      itinNOW = page;
      if (GROUP_DATA.Trackers == undefined) GROUP_DATA.Trackers = [];
      if (AC_data.Dynamic_Tracker == 1 && GROUP_DATA.Trackers.length > 0 && !SCHEDULER_OVERRIDE) {
        var TrackerHTML = '<select id="AC_TRACKER">';
        for (var i in GROUP_DATA.Trackers) {
          var T = GROUP_DATA.Trackers[i];
          TrackerHTML += '<option value="' + T.esn + '">' + escapeHtml(T.Name) + '</option>';
        }
        TrackerHTML += '</select>';
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Select Tracker Unit'),
          headerClose: false,
          buttonPrompt: TrackerHTML,
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                Itin_TrackerSelected(page, $('#AC_TRACKER').val());
              },
            },
          },
          callbackOpen: function () {
            if ($("#AC_TRACKER option[value='" + AC_data.esn + "']").length == 0) {
              $('#AC_TRACKER').append(new Option('Existing ESN', AC_data.esn));
            }
            $('#AC_TRACKER').val(AC_data.esn).selectmenu('refresh');
          },
        });
      } else {
        Itin_TrackerSelected(page, AC_data.esn);
      }
    } catch (error) {
      console.log(error);
    }
  });
}
function Itin_TrackerSelected(page, tracker) {
  AC_data.esn = tracker;
  $.mobile.changePage($('#' + page), {
    changeHash: false,
  });

  $('#' + page).enhanceWithin();

  $('#WB_EQHTML').html('<b>' + iTrans('Aircraft Temp Config Rebuild Required') + '!</b>');
  if (AC_data.EQ_HTML != undefined) $('#WB_EQHTML').html(AC_data.EQ_HTML);
  acType = AC_data.AcType;
  PilotsTable.query()
    .select()
    .then(function (e) {
      PILOT_data = e;
      getActiveTC(acIdent, page);
      if (page == 'ITIN-TECH-DISPATCH') {
        if (FLIGHTDOCS == 1) popTechDispatch();
        if (GROUP_DATA.WinAir == 1) popTechDispatch_WinAir();
        if (GROUP_DATA.Traxxall == 1) popTechDispatch_Traxxall();
        if (GROUP_DATA.CAMP == 1) popTechDispatch_CAMP();
      }
      if (page == 'RA_Form') {
        retrieveRiskForms('User');
      }
    });
}
function popTechDispatch() {
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#BILLING_ACTIMES_Content').html(''); //to prevent duplicate div ids
  $('#ITIN-FD_content').html('<h2>Populating</h2>');
  $('#itinFD_BTN_next').prop('disabled', true);
  $.getJSON(BASEURL + 'FD-AJAX.php?action=getIdent&Ident=' + encodeURIComponent(acIdent), function (json_data) {
    $('#itinFD_BTN_next').prop('disabled', false);
    if (json_data.ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data.ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data, 'popTechDispatch');
        var refreshBTN =
          '<button data-mini="ture" onclick="popTechDispatch();" data-icon="refresh">' +
          iTrans('Try Again') +
          '</button>';
        $('#ITIN-FD_content').html(
          '<h2>' +
            iTrans('No Aircraft Data Retrieved') +
            '!</h2><b>' +
            iTrans(
              'There was either an error contacting the Flightdocs Server OR the requested aircraft does not exist in the maintenance system'
            ) +
            '.</b>' +
            refreshBTN
        );
        $('#ITIN-FD_content').enhanceWithin();
      }
      if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      AC_TECH_DISPATCH.RAW = json_data;

      var AC = json_data.Aircraft;
      if (AC.Landings == null) AC.Landings = 0;
      if (AC.RIN == null) AC.RIN = 0;
      if (AC.TotalTime == null) AC.TotalTime = 0;
      if (AC.LastUpdated == null) AC.LastUpdated = '1970-01-01';
      if (AC.ReportedDate == null) AC.ReportedDate = '1970-01-01';
      var html = '<h3>' + iTrans('Aircraft Summary') + '</h3>';
      html +=
        '<table class="limit-table wrap"><thead><tr><th>' +
        iTrans('Item') +
        '</th><th>' +
        iTrans('Status') +
        '</th><th>' +
        iTrans('Next Due In') +
        '</th><th>' +
        iTrans('Item') +
        '</th></tr></thead><tbody>';
      html +=
        '<tr><td>' +
        iTrans('Last Updated') +
        '</td><td>' +
        new Date(AC.LastUpdated).toDateString() +
        '</td><td><div id="VTD_expDate">N/A</div></td><td><div id="VTD_expDateDESC"></div></td></tr>';
      html +=
        '<tr><td>Landings</td><td>' +
        AC.Landings.toLocaleString('en-US') +
        '</td><td><div id="VTD_expLandings">N/A</div></td><td><div id="VTD_expLandingsDESC"></div></td></tr>';
      if (AC.RIN != null)
        html +=
          '<tr><td>RIN</td><td>' +
          AC.RIN.toLocaleString('en-US') +
          '</td><td><div id="VTD_expRIN">N/A</div></td><td><div id="VTD_expRINDESC"></div></td></tr>';
      html +=
        '<tr><td>Cycle</td><td><div id="VTD_expCycleTXT">N/A</div></td><td><div id="VTD_expCycle">N/A</div></td><td><div id="VTD_expCycleDESC"></div></td></tr>';
      html +=
        '<tr><td>Total Time</td><td>' +
        AC.TotalTime.toLocaleString('en-US') +
        '</td><td><div id="VTD_expHours">N/A</div></td><td><div id="VTD_expHoursDESC"></div></td></tr>';
      html += '</tbody></table>';

      html += '<h3>' + iTrans('Equipment times') + '</h3>';
      html +=
        '<table class="limit-table wrap"><thead><tr><th>' +
        iTrans('Type') +
        '</th><th>' +
        iTrans('Cycles') +
        '</th><th>' +
        iTrans('Hours') +
        '</th></tr></thead><tbody>';
      for (var t in AC.AircraftEquipmentTimes) {
        var T = AC.AircraftEquipmentTimes[t];
        var tCycles = T.Cycles;
        if (tCycles != null) tCycles = tCycles.toLocaleString('en-US');
        var tHours = T.Hours;
        if (tHours != null) tHours = tHours.toLocaleString('en-US');
        /*
				var tC1C = T.C1C;
				if (tC1C != null) tC1C = tC1C.toLocaleString('en-US');
				var tC2C = T.C2C;
				if (tC2C != null) tC2C = tC2C.toLocaleString('en-US');
				*/
        html += '<tr><td>' + T.Type + '</td><td>' + tCycles + '</td><td>' + tHours + '</td></tr>';
      }
      html += '</tbody></table>';

      var DL = json_data.DueList;
      html += '<h3>' + iTrans('Due List') + '</h3>';
      html +=
        '<table class="limit-table wrap"><thead><tr><th>' +
        iTrans('Description') +
        '</th><th>' +
        iTrans('Tracked By') +
        '</th><th style="width:100px;">' +
        iTrans('Due In') +
        '</th></tr></thead><tbody>';

      var expCycles = {
        cycles: null,
        item: null,
        curcycles: null,
      };
      var expDate = {
        date: null,
        item: null,
      };
      var expHours = {
        hours: null,
        item: null,
      };
      var expRIN = {
        rin: null,
        item: null,
      };
      var expLandings = {
        landings: null,
        item: null,
      };
      for (var d in DL) {
        var D = DL[d];
        var lineBG = '';
        var OverdueTxt = '';
        switch (D.DueStatus) {
          case 1:
            lineBG = '';
            break;
          case 2:
            lineBG = 'greenbg';
            break;
          case 3:
            lineBG = 'yellowbg';
            break;
          case 4:
            lineBG = 'orangebg';
            break;
          case 5:
            lineBG = 'redbg';
            OverdueTxt = 'Overdue: ';
            break;
        }

        if (D.NextDueCycles != null && D.CyclesOnPart != null) {
          var LeftCycles = D.NextDueCycles - D.CyclesOnPart;
          LeftCycles = Math.round(LeftCycles * 10) / 10;
          //Due += LeftCycles.toLocaleString('en-US') + ' Cycles<br />';
          if (LeftCycles < expCycles.cycles || expCycles.cycles == null)
            expCycles = {
              cycles: LeftCycles,
              item: D.ItemDescription,
              curcycles: D.CyclesOnPart,
              lineBG: lineBG,
              txt: OverdueTxt,
            };
        }
        if (D.NextDueDate != null) {
          var dueDate = new Date(D.NextDueDate + 'T00:00:00Z');
          var now = new Date();
          var timeinmilisec = dueDate.getTime() - now.getTime();
          var leftDate = Math.ceil(timeinmilisec / (1000 * 60 * 60 * 24));
          //Due += leftDate + ' Days</br />';
          if (leftDate < expDate.date || expDate.date == null)
            expDate = {
              date: leftDate,
              item: D.ItemDescription,
              lineBG: lineBG,
              txt: OverdueTxt,
            };
        }
        if (D.NextDueHours != null && D.HoursOnPart != null) {
          var dueHours = D.NextDueHours;
          var leftHours = dueHours - D.HoursOnPart;
          leftHours = Math.round(leftHours * 10) / 10;
          //Due += leftHours.toLocaleString('en-US') + ' Hours<br />';

          if (leftHours < expHours.hours || expHours.hours == null)
            expHours = {
              hours: leftHours,
              item: D.ItemDescription,
              lineBG: lineBG,
              txt: OverdueTxt,
            };
        }
        if (D.NextDueLandings != null && D.LandingsOnPart != null) {
          var dueLandings = D.NextDueLandings;
          var leftLandings = dueLandings - D.LandingsOnPart;
          leftLandings = Math.round(leftLandings * 10) / 10;
          //Due += leftLandings.toLocaleString('en-US') + ' Landings<br />';

          if (leftLandings < expLandings.landings || expLandings.landings == null)
            explandings = {
              landings: leftLandings,
              item: D.ItemDescription,
              lineBG: lineBG,
              txt: OverdueTxt,
            };
        }
        if (D.NextDueRIN != null && D.RINOnPart != null) {
          var dueRIN = D.NextDueRIN;
          var leftRIN = dueRIN - D.RINOnPart;
          leftRIN = Math.round(leftRIN);
          //Due += leftRIN.toLocaleString('en-US') + ' RIN<br />';

          if (leftRIN < expRIN.rin || expRIN.rin == null)
            expRIN = {
              rin: leftRIN,
              item: D.ItemDescription,
              lineBG: lineBG,
              txt: OverdueTxt,
            };
        }

        html +=
          '<tr class="' +
          lineBG +
          '"><td>' +
          D.ItemDescription +
          '</td><td>' +
          D.TrackedByFormatted +
          '</td><td>' +
          D.RemainingFormatted;
        +'</td></tr>';
      }
      html += '</tbody></table>';
      $('#ITIN-FD_content').html(html);
      if (expDate.date != null) {
        $('#VTD_expDate').html("<b class='" + expDate.lineBG + "'>" + expDate.txt + expDate.date + '</b>');

        $('#VTD_expDateDESC').html(expDate.item);
      }
      if (expLandings.landings != null) {
        $('#VTD_expLandings').html(
          "<b class='" + expLandings.lineBG + "'>" + expLandings.txt + expLandings.landings + '</b>'
        );

        $('#VTD_expLandingsDESC').html(expLandings.item);
      }
      if (expRIN.rin != null) {
        $('#VTD_expRIN').html("<b class='" + expRIN.lineBG + "'>" + expRIN.txt + expRIN.rin + '</b>');

        $('#VTD_expRINDESC').html(expRIN.item);
      }
      if (expCycles.cycles != null) {
        $('#VTD_expCyclesTXT').html(expCycles.curcycles);
        $('#VTD_expCycles').html("<b class='" + expCycles.lineBG + "'>" + expCycles.txt + expCycles.cycles + '</b>');

        $('#VTD_expCyclesDESC').html(expCycles.item);
      }
      if (expHours.hours != null) {
        $('#VTD_expHours').html("<b class='" + expHours.lineBG + "'>" + expHours.txt + expHours.hours + '</b>');

        $('#VTD_expHoursDESC').html(expHours.item);
      }
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="popTechDispatch();" data-icon="refresh">' + iTrans('Try Again') + '</button>';
    $('#ITIN-FD_content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved') +
        '!</h2><b>' +
        iTrans(
          'There was either an error contacting the Flightdocs Server OR the requested aircraft does not exist in the maintenance system'
        ) +
        '.</b>' +
        refreshBTN
    );
    $('#ITIN-FD_content').enhanceWithin();
    $('#itinFD_BTN_next').prop('disabled', false);
  });
}

function popTechDispatch_WinAir() {
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#BILLING_ACTIMES_Content').html(''); //to prevent duplicate div ids
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#itinFD_BTN_next').prop('disabled', true);
  $.getJSON(
    BASEURL + 'WinAir-AJAX.php?action=getIdent&Ident=' + encodeURIComponent(acIdent) + '&FR_PK=0',
    function (json_data) {
      $('#itinFD_BTN_next').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="popTechDispatch_WinAir();" data-icon="refresh">Try Again</button>';
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retrieved') +
              '!</h2><b>' +
              iTrans(
                'There was either an error contacting the WinAir Server OR the requested aircraft does not exist in the maintenance system'
              ) +
              '.</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available'
              ) +
              '.' +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAircraft') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retrieved') +
              '!</h2><b>' +
              iTrans('The requested aircraft does not exist in the maintenance system') +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'AircraftDisabled') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('Aircraft Disabled') +
              '</h2><b>' +
              iTrans('The requested aircraft has been disabled in the maintenance system') +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'APIaccessdenied') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('API Access Denied') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retrieved from the maintenance system because the API key provided does not have the required permissions'
              ) +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAPIkey') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('Invalid API key') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retrieved from the maintenance system because the API key provided is not valid'
              ) +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        var html = '<h2>' + acIdent + ' - ' + iTrans('Technical Records') + ' - ';
        if (json_data.LastEntry == 'Unknown') html += '' + iTrans('Updated') + ': <b>' + iTrans('Never') + '</b></h2>';
        else html += '' + iTrans('Updated') + ': ' + new Date(json_data.LastEntry).toDateString() + '</h2>';

        var Recent = json_data.MostRecentInspections;
        if (Recent != null) {
          if (Recent.length > 0) {
            html += '<h3>' + iTrans('Recent Inspections') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>' +
              iTrans('Completed') +
              '</th></tr></thead><tbody>';
            for (var i in Recent) {
              var R = Recent[i];
              html += '<tr><td>' + R.task + '</td><td>' + R.description + '</td><td>' + R.dateCompleted + '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Recent Inspections') + '</h3>';
          }
        }
        var Next = json_data.NextDueInspections;
        if (Next != null) {
          if (Next.length > 0) {
            html += '<h3>' + iTrans('Next Due Inspections') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>' +
              iTrans('Projected') +
              '</th></tr></thead><tbody>';

            var now = new Date().getTime();
            for (var i in Next) {
              var N = Next[i];
              var pDueDate = new Date(N.projectedDueDate + 'T23:59:59').getTime();
              var Nclass = '';
              if (pDueDate < now) Nclass = 'redbg';
              html +=
                '<tr class="' +
                Nclass +
                '"><td>' +
                N.task +
                '</td><td>' +
                N.description +
                '</td><td>' +
                N.projectedDueDate +
                '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Next Due Inspections') + '</h3>';
          }
        }
        var NextC = json_data.NextDueItems;
        if (NextC != null) {
          if (NextC.length > 0) {
            html += '<h3>' + iTrans('Next Due Maintenance Per Counter') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>' +
              iTrans('Counter') +
              '</th><th>' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>' +
              iTrans('Due At') +
              '</th><th>' +
              iTrans('Remaining') +
              '</th></tr></thead><tbody>';

            for (var i in NextC) {
              var NC = NextC[i];
              var NCclass = '';
              if (parseFloat(NC.remaining) < 0) NCclass = 'redbg';
              html +=
                '<tr class="' +
                NCclass +
                '"><td>' +
                NC.counterName +
                '</td><td>' +
                NC.taskCheck +
                '</td><td>' +
                NC.counterDescription +
                '</td><td>' +
                NC.dueAt +
                '</td><td>' +
                NC.remaining +
                '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Next Due Maintenance Per Counter') + '</h3>';
          }
        }
        var Deferred = json_data.DefferedTasks;
        if (Deferred != null) {
          if (Deferred.length > 0) {
            html += '<h3>' + iTrans('Deferred Tasks') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>WO-' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>Mel ' +
              iTrans('Reference') +
              '</th><th>Mel ' +
              iTrans('Category') +
              '</th><th>' +
              iTrans('Effective Date') +
              '</th><th>' +
              iTrans('Due Date') +
              '</th><th>' +
              iTrans('Deferral Days') +
              '</th><th>' +
              iTrans('Remaining Days') +
              '</th></tr></thead><tbody>';

            for (var i in Deferred) {
              var D = Deferred[i];
              var Dclass = '';
              if (parseInt(D.remainingDays) < 0) Dclass = 'redbg';
              html +=
                '<tr class="' +
                Dclass +
                '"><td>' +
                D.woTask +
                '</td><td>' +
                D.description +
                '</td><td>' +
                D.melReference +
                '</td><td>' +
                D.melCategory +
                '</td><td>' +
                D.effectiveDate +
                '</td><td>' +
                D.dueDate +
                '</td><td>' +
                D.deferralDays +
                '</td><td>' +
                D.remainingDays +
                '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Deferred Tasks') + '</h3>';
          }
        }
        html += '<h3>' + iTrans('Current Equipment times') + '</h3>';
        var IP = json_data.Fields;
        var CT = json_data.CurrentTimes;
        html +=
          '<table class="limit-table first-td-fill"><thead><tr><th>' +
          iTrans('Description') +
          '</th><th>' +
          iTrans('Cur Value') +
          '</th></tr></thead><tbody>';
        var Hours = decimalToHHMM(CURRENT_FLIGHT_REPORT.HoursFlown);
        for (var i in IP) {
          var InputHtml = '';

          html +=
            '<tr><td>' +
            IP[i].inputDescription +
            ' (' +
            IP[i].inputFormat +
            ')</td><td><b>' +
            CT[i].inputCurrentValue +
            '</b></td></tr>';
        }
        html += '</tbody></table>';

        $('#ITIN-FD_content').html(html);
        $('#ITIN-FD_content').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="popTechDispatch_WinAir();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#ITIN-FD_content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved') +
        '!</h2><b>' +
        iTrans('There was an error contacting the WinAir Server') +
        '</b><br />' +
        refreshBTN
    );
    $('#ITIN-FD_content').enhanceWithin();
    $('#itinFD_BTN_next').prop('disabled', false);
  });
}

function popTechDispatch_Traxxall() {
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#BILLING_ACTIMES_Content').html(''); //to prevent duplicate div ids
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#itinFD_BTN_next').prop('disabled', true);
  $('#NewFlightIdent1').html(iTrans('New Flight') + ' | ' + acIdent);
  //var acIdent = "ES-DEMO1"; //todo remove this in production
  $.getJSON(
    BASEURL + 'Traxxall-AJAX.php?action=getIdent&Ident=' + encodeURIComponent(acIdent) + '&FR_PK=0',
    function (json_data) {
      $('#itinFD_BTN_next').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="popTechDispatch_Traxxall();" data-icon="refresh">Try Again</button>';
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!</h2><b>' +
              iTrans(
                'There was either an error contacting the Traxxall Server OR the requested aircraft does not exist in the maintenance system'
              ) +
              '.</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available'
              ) +
              '.' +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAircraft') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!</h2><b>' +
              iTrans('The requested aircraft does not exist in the maintenance system') +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'AircraftDisabled') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('Aircraft Disabled') +
              '</h2><b>' +
              iTrans('The requested aircraft has been disabled in the maintenance system') +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'APIaccessdenied') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('API Access Denied') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retreived from the maintenance system because the API key provided does not have the required permissions'
              ) +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAPIkey') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('Invalid API key') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retreived from the maintenance system because the API key provided is not valid'
              ) +
              '.</b>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        var html = '<h2>' + acIdent + ' - ' + iTrans('Technical Records') + ' - ';
        var UpdatedDate = iTrans('Unknown');
        try {
          UpdatedDate = json_data.Actuals[0].values[0].asOfDate;
        } catch (e) {
          UpdatedDate = 'Error';
        }
        html += '' + iTrans('Updated') + ': <b>' + UpdatedDate + '</b></h2>';
        // Last Cirro Updated Code.  Maybe reuse later
        //if (json_data.LastEntry == 'Unknown') html += '' + iTrans('Updated') + ': <b>' + UpdatedDate + '</b></h2>';
        //else html += '' + iTrans('Updated') + ': ' + new Date(json_data.LastEntry).toDateString() + '</h2>';

        html += '<h3>' + iTrans('Next Due Items') + '</h3>';
        html += '<table><thead><tr><th>Description</th><th>Earliest Due</th><th>Remaining</th></tr></thead><tbody>';
        var DueList = json_data.DueList;
        try {
          if (DueList.message.indexOf('Not Found') != -1) {
            $('#ITIN-FD_content').html(
              '<h2>' +
                iTrans('No Aircraft Data Retreived') +
                '!</h2><b>' +
                iTrans('The requested aircraft does not exist in the maintenance system') +
                '.</b>'
            );
            return;
          }
        } catch (e) {
          console.log(e);
        }
        for (var i in DueList) {
          var I = DueList[i];
          var lineClass = '';
          if (I['aircraft Color'] == 'Red') lineClass = 'redbg';
          html +=
            '<tr class="' +
            lineClass +
            '"><td class="wrap">' +
            I.description +
            '</td><td>' +
            I['earliest Due'] +
            '</td><td>' +
            I['remaining Time'] +
            '</td></tr>';
        }
        html += '</tbody></table>';

        html += '<h3>' + iTrans('Current Aircraft Status') + '</h3>';
        html += '<table><thead><tr><th>Description</th><th>Status</th><th>As Of</th></tr></thead><tbody>';
        var Actuals = json_data.Actuals;
        for (var i in Actuals) {
          var I = Actuals[i];

          var vhtml = '';
          for (var ii in I.values) {
            var V = I.values[ii];
            if (ii > 0) vhtml += '<br />';
            vhtml += V.value + ' ' + V.unitOfMeasure;
          }
          html +=
            '<tr><td>' +
            I.majorAssemblyDescription +
            '</td><td>' +
            vhtml +
            '</td><td>' +
            I.majorAssemblyActualDate +
            '</td></tr>';
        }
        html += '</tbody></table>';

        $('#ITIN-FD_content').html(html);
        $('#ITIN-FD_content').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="popTechDispatch_Traxxall();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#ITIN-FD_content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retreived') +
        '!</h2><b>' +
        iTrans('There was an error contacting the Traxxall Server') +
        '</b><br />' +
        refreshBTN
    );
    $('#ITIN-FD_content').enhanceWithin();
    $('#itinFD_BTN_next').prop('disabled', false);
  });
}

function popTechDispatch_CAMP() {
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#BILLING_ACTIMES_Content').html(''); //to prevent duplicate div ids
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#itinFD_BTN_next').prop('disabled', true);
  $('#NewFlightIdent1').html(iTrans('New Flight') + ' | ' + acIdent);
  //var acIdent = "ES-DEMO1"; //todo remove this in production
  $.getJSON(
    BASEURL + 'CAMP-AJAX.php?action=getIdent&ident=' + encodeURIComponent(acIdent) + '&FR_PK=0',
    function (json_data) {
      $('#itinFD_BTN_next').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="popTechDispatch_CAMP();" data-icon="refresh">Try Again</button>';
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!</h2><b>' +
              iTrans(
                'There was either an error contacting the CAMP Server OR the requested aircraft does not exist in the maintenance system'
              ) +
              '.</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available'
              ) +
              '.' +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.data.Status == 'Not Found') {
          $('#ITIN-FD_content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!<br />' +
              iTrans('The requested aircraft does not exist in the maintenance system') +
              '.</h2>'
          );
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.data.Status == 'SoapFault') {
          $('#ITIN-FD_content').html('<h2>' + iTrans('SoapFault') + '</h2><b>' + json_data.data.faultstring + '.</b>');
          $('#ITIN-FD_content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        var data = json_data.data;

        var html = CAMP_EarliestDueTimes_HTML(data);

        var openAicraftDescrepancies = data.openAicraftDescrepancies;

        if (openAicraftDescrepancies.length > 0) {
          html += '<h3>' + iTrans('Open Aircraft Descrepancies') + '</h3>';
          html +=
            '<table class="limit-table"><thead><tr><th>Number</th><th>Description</th><th>Type</th><th>Log Unit</th><th>Log Value</th></tr></thead><tbody>';
          for (var i in openAicraftDescrepancies) {
            var I = openAicraftDescrepancies[i];
            var logBook = I.logBook;
            var logUnit = '';
            var logValue = '';
            for (var n in logBook) {
              if (logBook[n] == null) continue; //i've seen these null for some reason follow up with Steve
              logUnit += (n > 0 ? '<br />' : '') + getCAMP_UnitTXT(logBook[n].unit);
              var logItemValue = logBook[n].value;
              switch (logBook[n].unit) {
                case 'HRS':
                  logItemValue = (parseFloat(logBook[n].value) / 60).toFixed(1);
                  break;
                case 'DAYS':
                  logItemValue = getDaysTillDue(logBook[n].value);
                  break;
              }
              logValue += (n > 0 ? '<br />' : '') + logItemValue;
            }
            html +=
              '<tr><td>' +
              I.discrepancyNbr +
              '</td><td class="wrap">' +
              I.description +
              '</td><td>' +
              I.discrepancyType +
              '</td><td>' +
              logUnit +
              '</td><td>' +
              logValue +
              '</td></tr>';
          }
          html += '</tbody></table>';
        } else {
          html += '<h3>' + iTrans('Open Aircraft Descrepencies') + ': ' + iTrans('None') + '</h3>';
        }

        html += '<h3>' + iTrans('Latest Aircraft Times') + '</h3>';
        html +=
          '<table class="limit-table first-td-fill"><thead><tr><th>Description</th><th>Last Reported</th><th>Position</th><th>Unit</th><th>Value</th><th>Remaining</th><th>Max Ext</th></tr></thead><tbody>';
        var Actuals = data.latestAircraftTimes.timesInfo;
        for (var i in Actuals) {
          var lineClass = '';
          var I = Actuals[i];
          var remaining = '';
          var maxRemaining = '';
          var foundItem = false;
          for (var r in data.aicraftState.earliestDueTimes) {
            var R = data.aicraftState.earliestDueTimes[r];
            if (R == null) continue; //i've seen these null for some reason follow up with Steve
            var dueTasks = R.dueTasks;
            for (var t in dueTasks) {
              var T = dueTasks[t];
              if (I.profileType == T.ProfileType && I.position == T.childPosition) {
                // is same part now loop through nextDue and maxNextDue to get Remaing and Max Ext values
                var nextDue = T.nextDue;
                for (var n in nextDue) {
                  var ND = nextDue[n];
                  if (I.unit == ND.unit) {
                    foundItem = true;
                    remaining = parseFloat(ND.value) - parseFloat(I.lastReportedValue);
                    if (I.unit == 'HRS') remaining = (remaining / 60).toFixed(1);
                    if (remaining < 0) lineClass = 'redbg';
                  }
                }
                var maxNextDue = T.maxNextDue;
                for (var m in maxNextDue) {
                  var MD = maxNextDue[m];
                  if (I.unit == MD.unit) {
                    maxRemaining = parseFloat(MD.value) - parseFloat(I.lastReportedValue);
                    if (I.unit == 'HRS') maxRemaining = (maxRemaining / 60).toFixed(1);
                    if (maxRemaining < 0) {
                      lineClass = 'redbg';
                    } else {
                      if (lineClass != '') lineClass = 'orangebg';
                    }
                  }
                }
              }
              if (foundItem) {
                break;
              }
            }
            if (foundItem) {
              break;
            }
          }
          html +=
            '<tr class="' +
            lineClass +
            '"><td>' +
            I.profileType +
            ' - ' +
            getCAMP_UnitTXT(I.unit) +
            '</td><td>' +
            I.lastReportedDate +
            '</td><td>' +
            I.position +
            '</td><td style="text-align: center;">' +
            I.unit +
            '</td><td style="text-align: right;">' +
            (I.unit == 'HRS' ? (parseFloat(I.lastReportedValue) / 60).toFixed(1) : I.lastReportedValue) +
            '</td><td style="text-align: right;">' +
            remaining +
            '</td><td style="text-align: right;">' +
            maxRemaining +
            '</td></tr>';
        }
        html += '</tbody></table>';

        var closedWorkOrders = data.closedWorkOrders;

        if (closedWorkOrders.workorderHeaderInfo != null) {
          html += '<h3>' + iTrans('Recent Work Orders') + '</h3>';

          for (var i in closedWorkOrders.workorderHeaderInfo) {
            var I = closedWorkOrders.workorderHeaderInfo[i];
            html +=
              '<h4>Date Closed: ' +
              I.baseDateClosed +
              ' (' +
              getDaysTillDue(I.baseDateClosed) +
              ')' +
              '<br />Location: ' +
              I.airportICAO +
              '<br />Job Type: ' +
              I.jobType +
              '<br />Description: ' +
              I.description +
              '</h4>';
            html +=
              '<table><thead><tr><th>Technician</th><th>Description</th><th>Corrective Action</th></tr></thead><tbody>';

            var workorderDetailInfo = I.workorderDetailInfo;
            for (var d in workorderDetailInfo) {
              var D = workorderDetailInfo[d];
              html +=
                '<tr><td>' +
                D.technicianName +
                '</td><td class="wrap">' +
                D.description +
                '</td><td>' +
                D.correctiveAction +
                '</td></tr>';
            }
            html += '</tbody></table>';
          }
        } else {
          html += '<h3>' + iTrans('Recent Work Orders') + ': ' + iTrans('None') + '</h3>';
        }

        $('#ITIN-FD_content').html(html);
        $('#ITIN-FD_content').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="popTechDispatch_CAMP();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#ITIN-FD_content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retreived') +
        '!</h2><b>' +
        iTrans('There was an error contacting the CAMP Server') +
        '</b><br />' +
        refreshBTN
    );
    $('#ITIN-FD_content').enhanceWithin();
    $('#itinFD_BTN_next').prop('disabled', false);
  });
}

function getDaysTillDue(DueDate) {
  try {
    var _second = 1000;
    var _minute = _second * 60;
    var _hour = _minute * 60;
    var _day = _hour * 24;
    var now = new Date();
    var end = new Date(DueDate);
    var distance = end - now;
    var days = Math.floor(distance / _day);
    if (days > 0) return days + ' ' + iTrans('Days from now');
    else return Math.abs(days) + ' ' + iTrans('Days ago');
  } catch (e) {
    return 'Unknown';
  }
}
function getActiveTC(Ident, page = false) {
  PLANAC = Ident;
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=get_opt_Status&acident=' + Ident, function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getActiveTC');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          var TCNUM = 0;
          if (json_data[0].noTC == 1) {
            AC_data.opt_Status = json_data[0].Result;
          } else {
            TCNUM = json_data[0].Result;
          }
          Itinerary_initialize(TCNUM);
          if (page == 'ITIN-INPUT') {
            INIT_ITIN_PAGE();
            PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
            $('#itinClients').selectmenu('refresh', true);
            $('#Pilots').selectmenu('refresh', true);
            $('#ITIN_ResponsiblePerson').selectmenu('refresh', true);
            $('#SICs').selectmenu('refresh', true);
          }
        }
      } else {
        Itinerary_initialize(0);
        if (page == 'ITIN-INPUT') {
          INIT_ITIN_PAGE();
          PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
          $('#itinClients').selectmenu('refresh', true);
          $('#Pilots').selectmenu('refresh', true);
          $('#ITIN_ResponsiblePerson').selectmenu('refresh', true);
          $('#SICs').selectmenu('refresh', true);
        }
      }
    }).fail(function (jqXHR, status, error) {
      AJAXFAIL(error);
    });
  } else {
    Itinerary_initialize(0);
    if (page == 'ITIN-INPUT') {
      INIT_ITIN_PAGE();
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
      $('#itinClients').selectmenu('refresh', true);
      $('#Pilots').selectmenu('refresh', true);
      $('#ITIN_ResponsiblePerson').selectmenu('refresh', true);
      $('#SICs').selectmenu('refresh', true);
    }
  }
}
var MAPB_OBSTACLES = true;
var RETURN_ITIN = false;
function routePlanToggle(e) {
  if ($(e).is(':checked')) {
    resetMeasureData();

    PLANAC = acIdent;
    RETURN_ITIN = true;
    RoutePlanningON = true;
    FuelRemain = ACFuel;
    $('#FuelPlanCancelBTN').prop('disabled', true);

    if (AC_data.OFP_REQ == 1) {
      //Aircraft requires an Operational Flight Plan.  Map Use is forced to create a proper fuel plan
      MAPB_OBSTACLES = false;
      SCH_DATA.LegIndex = 0;
      //Attach Route Plan is now checked goto map and routeplan
      $('#exitMapPage2').html(
        '<div class="vertical-center-container"><img src="./images/Check_mark_green.svg" /> ' +
          iTrans('Itinerary') +
          ' </div>'
      );
      MANUAL_LEG = false;
      startRoutePlan();
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Select'),
        headerClose: false,
        buttonPrompt: iTrans('How would you like to input aircraft routing?'),
        buttons: {
          [iTrans('Map')]: {
            click: function () {
              MAPB_OBSTACLES = false;
              SCH_DATA.LegIndex = 0;
              //Attach Route Plan is now checked goto map and routeplan
              $('#exitMapPage2').html(
                '<div class="vertical-center-container"><img src="./images/Check_mark_green.svg" /> ' +
                  iTrans('Itinerary') +
                  ' </div>'
              );
              MANUAL_LEG = false;
              startRoutePlan();
            },
          },
          [iTrans('Manual')]: {
            click: function () {
              SCH_DATA.LegIndex = 0;
              MAPB_OBSTACLES = false;
              MANUAL_LEG = true;
              FuelPlan = [];
              FuelPlans = [];
              RoutePlans = [];
              FuelRemain = ACmaxFuel; //so route planning uses full fuel
              startRoutePlan();
            },
          },
        },
      });
    }
  } else {
    //if (flightRoutes !== null) map.removeLayer(flightRoutes);
    initSCHLegPlanner();
    RETURN_ITIN = false;
    FuelPlan = [];
    FuelPlans = [];
    RoutePlans = [];
    SCH_DATA.LegIndex = 0;
    SCH_DATA.LEGS = [];
    FuelRemain = 0;
    RoutePlanningON = false;
    resetMeasureData();
    $('#MeasureControl').prop('disabled', false);
    $('#RoutePlanHldr').html('');
    PostRoute = null;
    MAPB_OBSTACLES = true;
    $('#exitMapPage2').html(
      '<div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i> ' +
        iTrans('Leave Map') +
        '</div>'
    );
    $.mobile.changePage($('#ITIN-ROUTEMAP'), {
      allowSamePageTransition: true,

      reloadPage: true,
      changeHash: false,
    });
    //Todo clear static map when initialized
    //$("#MobilizeRoutePlanBTN").prop("disabled", true);
    $('#FuelPlanCancelBTN').prop('disabled', false);
  }
}
var staticmapb = null;

function FinishAllLegs() {
  RoutePlanningON = false;
  ITIN_Save_Leg();

  FuelPlans = JSON.parse(JSON.stringify(MEASURE.LEGS));
  $('#MeasureControl').prop('disabled', false);
  StopMeasure();
  ItinReturn();
  MANUAL_LEG = false;
}
function LoadLegacyRoutePlan(FuelPlans) {
  var NewFuelPlan = [];
  for (var i in FuelPlans) {
    var Features = {
      type: 'FeatureCollection',
      features: [],
    };
    for (var f in FuelPlans[i]) {
      var I = FuelPlans[i][f];
      var point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [I.latlng.lng, I.latlng.lat],
        },
        properties: {
          id: parseInt(f),
          type: 'point',
          Lat: I.latlng.lat,
          Long: I.latlng.lng,
          NavType: 'waypoint',
        },
      };
      Features.features.push(point);
    }
    NewFuelPlan.push(Features);
  }
  MEASURE.LEGS = JSON.parse(JSON.stringify(NewFuelPlan));
  MEASURE.CurLeg = 0;
  MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[0]));
  return NewFuelPlan;
}

function checkQuoteLegPlanning(hadFuelPlan) {
  if (SCH_DATA.Flight_Data.Quote.Legs.length > 0) {
    if (hadFuelPlan) {
      //Prompt to choose fuel plan if hadFuelPlan
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Choose Route Plan',
        headerClose: false,
        buttonPrompt:
          iTrans('An existing route has already been planned.') +
          '. ' +
          iTrans('Would you like to update it with the quoted one') +
          '?',
        buttons: {
          ['Use Original']: {
            click: function () {
              loadLegacyRoutePlan();
            },
          },
          [iTrans('Use Quoted')]: {
            click: function () {
              makeQuoteRoutePlan();
            },
          },
        },
      });
    } else {
      makeQuoteRoutePlan();
    }
  } else {
    if (hadFuelPlan) {
      loadLegacyRoutePlan();
    }
  }
}
function loadLegacyRoutePlan() {
  $('#ReviewItinLegsBTN').prop('disabled', false);
  LoadPlannedRoute();
}
var SCH_QUOTE_FuelPlan = {
  LEGS: [],
  QuoteLegs: [],
  FromICAO: null,
  ToICAO: null,
  Origin: null,
  Dest: null,
  LegIndex: 0,
};

function makeQuoteRoutePlan() {
  SCH_QUOTE_FuelPlan = {
    LEGS: [],
    QuoteLegs: SCH_DATA.Flight_Data.Quote.Legs,
    FromICAO: null,
    ToICAO: null,
    Origin: null,
    Dest: null,
    LegIndex: 0,
    noMap: true,
  };
  SCH_DATA.LegIndex = 0;
  SCH_DATA.LEGS = [];

  resetMeasureData();
  PLANAC = acIdent;
  MAPB_OBSTACLES = false;
  MANUAL_LEG = true;
  FuelPlan = [];
  FuelPlans = [];
  RoutePlans = [];
  FuelRemain = ACmaxFuel; //so route planning uses full fuel

  makeQuoteLEGS();
  MAPSTATE.Measuring = true;
  makeQuoteRouteLegs();
  $.mobile.loading('show', {
    theme: 'a',
  });
}

function makeQuoteRouteLegs() {
  if (SCH_QUOTE_FuelPlan.QuoteLegs.length > SCH_QUOTE_FuelPlan.LegIndex) {
    MEASURE.geojson.features = [];
    var Leg = SCH_QUOTE_FuelPlan.QuoteLegs[SCH_QUOTE_FuelPlan.LegIndex];
    if (Leg.FromICAO.length == 4 && Leg.ToICAO.length == 4) {
      SCH_QUOTE_FuelPlan.FromICAO = Leg.FromICAO;
      SCH_QUOTE_FuelPlan.ToICAO = Leg.ToICAO;
      getQuoteLegICAO(SCH_QUOTE_FuelPlan.FromICAO, setQuoteOrigin);
    } else {
      console.log('Missing ICAO Identifier');
      //todo prompt that icao ident not found or not entered correcly
      showQuoteRoutePlan();
    }
  } else {
    showQuoteRoutePlan();
  }
}
function setQuoteOrigin(data) {
  SCH_QUOTE_FuelPlan.Origin = JSON.parse(JSON.stringify(AUTOCOMPLETE.data[0]));
  var point = makeQuoteLeg(JSON.parse(JSON.stringify(AUTOCOMPLETE.data[0])));
  point.properties.id = MEASURE.geojson.features.length;
  point.properties.NavType = 'Airport';
  MEASURE.geojson.features.push(point);
  RoutePlans[SCH_QUOTE_FuelPlan.LegIndex] = [SCH_QUOTE_FuelPlan.Origin.Lat, SCH_QUOTE_FuelPlan.Origin.Long];
  getQuoteLegICAO(SCH_QUOTE_FuelPlan.ToICAO, setQuoteDest);
}
function getQuoteLegICAO(text, callback) {
  if (CORDOVA) SearchMobileDB(text, callback);
  else SearchOnlineDB(text, callback);
}

function setQuoteDest(data) {
  SCH_QUOTE_FuelPlan.Dest = JSON.parse(JSON.stringify(AUTOCOMPLETE.data[0]));
  RoutePlans[SCH_QUOTE_FuelPlan.LegIndex] = [SCH_QUOTE_FuelPlan.Dest.Lat, SCH_QUOTE_FuelPlan.Dest.Long];
  var point = makeQuoteLeg(JSON.parse(JSON.stringify(AUTOCOMPLETE.data[0])));
  point.properties.id = MEASURE.geojson.features.length;
  point.properties.type = 'point';
  point.properties.NavType = 'Airport';
  MEASURE.geojson.features.push(point);
  FinishFuelPlan(GetDefaultFuelPlan(), true, SCH_QUOTE_FuelPlan.LegIndex);
  SCH_QUOTE_FuelPlan.LegIndex++;
}
function showQuoteRoutePlan() {
  console.log('Show the Quote Routeplan');
  SCH_QUOTE_FuelPlan.noMap = false;
  RoutePlanningON = false;
  MAPSTATE.Measuring = false;
  MAPB_OBSTACLES = true;
  MANUAL_LEG = false;
  FuelPlans = JSON.parse(JSON.stringify(MEASURE.LEGS));
  loadLegacyRoutePlan();
  VueBridge.wait(500).then(() => $.mobile.loading('hide'));
}

function makeQuoteLeg(data) {
  var point = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [data.Long, data.Lat],
    },
    properties: data,
  };
  return point;
}

function makeQuoteLEGS() {
  for (var l in SCH_QUOTE_FuelPlan.QuoteLegs) {
    var L = SCH_QUOTE_FuelPlan.QuoteLegs[l];

    var Index = l;
    var now = new Date();
    now = now.toISOString().substring(0, 16) + 'Z';
    var WBSummary = '';
    var otherwt = 0;
    for (var i in Loading) {
      if (Loading[i].Variable != undefined) {
        WBSummary +=
          Loading[i].Abr +
          ':' +
          $('#' + Loading[i].Abr).val() +
          ':' +
          Loading[i].LoadLong +
          ':' +
          Loading[i].LoadLat +
          ',';
      } else {
        if (Loading[i].VariableArms == 1) {
          WBSummary +=
            Loading[i].Abr +
            ':' +
            $('#' + Loading[i].Abr).val() +
            ':' +
            Loading[i].LoadLong +
            ':' +
            Loading[i].LoadLat +
            ',';
        } else {
          WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr).val() + ',';
        }
      }
      otherwt += parseFloat($('#' + Loading[i].Abr).val());
    }
    for (var i in optLoad) {
      if (optLoad[i].VariableArms == 1) {
        WBSummary +=
          optLoad[i].Abr +
          ':' +
          $('#' + optLoad[i].Abr).val() +
          ':' +
          optLoad[i].LoadLong +
          ':' +
          optLoad[i].LoadLat +
          ',';
      } else {
        WBSummary += optLoad[i].Abr + ':' + $('#' + optLoad[i].Abr).val() + ',';
      }
      otherwt += parseFloat($('#' + optLoad[i].Abr).val());
    }
    var depart = adjustTimezone(L.DepartDate, L.DepartTime, CurrentTimeZone, L.Timezone);
    var depart2 = adjustTimezone(L.DepartDate, L.DepartTime, CurrentTimeZone, L.Timezone, L.TaxiTimeOrigin);
    var arrive = adjustTimezone(L.ArrivalDate, L.ArrivalTime, CurrentTimeZone, L.Timezone, -L.TaxiTimeDest);
    var arrive2 = adjustTimezone(L.ArrivalDate, L.ArrivalTime, CurrentTimeZone, L.Timezone);
    SCH_DATA.LEGS[Index] = {
      LegNum: Index,
      Changed: now,
      LegStartE: depart.time,
      LegStart: depart2.time,
      LegEnd: arrive2.time,
      LegEndE: arrive.time,
      LegETA: 0,
      LegOrigin: L.FromICAO + ': ' + L.FromICAO_Name,
      LegRouting: '',
      LegDest: L.ToICAO + ': ' + L.ToICAO_Name,
      flightCatID: L.flightCatID,
      Pilot: $('#Pilots').val(),
      sic: $('#SICs').val(),
      PaxNum: L.PaxNum,
      Description: '',
      acwt: facweight,
      fuelwt: fuelwt,
      otherwt: otherwt,
      CargoWeight: $('#ITIN_CARGO_TOTAL').val(),
      cofglat: fcofgLat,
      cofglong: fcofgLong,
      tempconfig: tcNum,
      wbDate: TC_data[tcIndex].Date,
      Eweight: eacweight,
      Ehmom: TC_data[tcIndex].TCHorzMom,
      Elmom: TC_data[tcIndex].TCLatMom,
      TCdesc: tcDesc,
      Summary: WBSummary,
      GRAPH: '',
      Cfields: makeItinPerLegCfields(ITIN_Cfields),
    };
  }
}

function makeItinPerLegCfields(ITIN_Cfields) {
  var PerLegFields = [];
  var CF = JSON.parse(JSON.stringify(ITIN_Cfields));
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
        PerLegFields.push(I);
      }
    }
  }
  return PerLegFields;
}

function ItinReturn() {
  RETURN_ITIN = false;

  MAPB_OBSTACLES = true;
  UpdateNavVisibility(false);
  if (updateTimeout != null) {
    clearTimeout(updateTimeout);
    updateTimeout = null;
  }
  mapboxgl.accessToken = MAPBOX_TOKEN;
  if (staticmapb != null) {
    staticmapb.remove();
    staticmapb = null;
  }
  //map.removeControl(drawControl); //remove drawing toolbar from map
  //map.removeControl(fileControl); //remove file loader Control from map
  $.mobile.changePage($('#ITIN-ROUTEMAP'), {
    reverse: true,
    changeHash: false,
  });
  $('#RoutePlanCHK').prop('checked', 'true').checkboxradio('refresh');

  $('#exitMapPage2').html(
    '<div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i> ' +
      iTrans('Leave Map') +
      '</div>'
  );
  //map.off('draw:created', newRoute);
  //map.off('draw:edited', editRoute);
  //if (flightRoutes !== null) map.removeLayer(flightRoutes);
  PostRoute = JSON.stringify(FuelPlans); //string to post to database as array insert

  RoutePlans = [];
  if (FuelPlans.length == 0) {
    //no route plan uncheck box
    $('#RoutePlanCHK').attr('checked', false).checkboxradio('refresh');
    $('#MobilizeRoutePlanBTN').prop('disabled', true);
    $('#ReviewItinLegsBTN').prop('disabled', true);
  } else {
    if (FuelPlans[0].features == undefined) {
      FuelPlans = LoadLegacyRoutePlan(FuelPlans);
    }
    if (FuelPlans[0].features.length > 1) {
      $('#ReviewItinLegsBTN').prop('disabled', false);
      $('#RoutePlanHldr').html('<div id="STATICMAP"></div>');
      staticmapb = new mapboxgl.Map({
        container: 'STATICMAP', // container id
        style: 'mapbox://styles/airsuite/cizg733jt00fi2sqiskxfjukc',
        center: [-89.2461, 48.3822], // starting position
        zoom: 3, // starting zoom
        minzoom: 1,
        pitch: 0,
        bearing: 0,
      });
      staticmapb.on('load', function () {
        var lines = {
          type: 'FeatureCollection',
          features: [],
        };
        for (var L in FuelPlans) {
          var coords = [];
          var RouteCoords = [];
          for (var f in FuelPlans[L].features) {
            var C = FuelPlans[L].features[f].geometry.coordinates;
            coords.push(C);
            RouteCoords.push([C[1], C[0]]);
          }
          RoutePlans.push(RouteCoords);
          var feature = {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: coords,
            },
            properties: {
              Label: '',
            },
          };
          if (coords.length < 2) continue;
          feature.properties.Label = getRouteDistance(feature);
          lines.features.push(feature);
        }
        staticmapb.addSource('ROUTEPLAN-LINES', {
          type: 'geojson',
          data: lines,
        });
        staticmapb.addLayer({
          id: 'ROUTEPLAN-LINES',
          type: 'line',
          source: 'ROUTEPLAN-LINES',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#ff7800',
            'line-width': 4,
            'line-opacity': 0.7,
          },
        });
        staticmapb.addLayer({
          id: 'ROUTEPLAN-LINES-LABELS',
          type: 'symbol',
          source: 'ROUTEPLAN-LINES',
          layout: {
            visibility: 'visible',
            'symbol-placement': 'line',
            'text-field': '{Label}',
            'text-size': TEXT_SIZE,
            'text-justify': 'center',
            'text-anchor': 'top',
            'text-offset': [0, 1],
          },
          paint: {
            'text-halo-color': TEXT_HALO_COLOR,
            'text-halo-width': TEXT_HALO_WIDTH,
            'text-halo-blur': TEXT_HALO_BLUR,
            'text-color': TEXT_COLOR,
          },
        });
        var bbox = turf.bbox(lines);
        staticmapb.fitBounds(bbox, {
          padding: 50,
        });
        CheckRouteObstacles(staticmapb, false);
      });
    } else {
      //no route plan uncheck box
      $('#RoutePlanCHK').attr('checked', false).checkboxradio('refresh');
      $('#MobilizeRoutePlanBTN').prop('disabled', true);
      $('#ReviewItinLegsBTN').prop('disabled', true);
    }
  }
  $('html, body').animate({
    scrollTop: $('#RoutePlanCHK').offset().top,
  }); //scroll back to route planning section of itinerary
}
var STATICMAP;
var PostRoute = null;
var returnDatePicker;
var ofpDatePicker;

function tdgToggle() {
  if ($('#TDG').is(':checked')) {
    //search for available templates for this aircraft.
    $.mobile.changePage($('#TDG_Home'), {
      changeHash: false,
    });
    init_TDG('Itinerary');
    if (TDG_Manifest.length > 0) populateManifest();
  } else {
    $('#TDG_Itin_Manifest').html('');
    $('#TDGNEXT').prop('disabled', false);
    //calculateWB();
  }
}

function LoadPlannedRoute() {
  ItinReturn();
}
var ITIN_INIT = false; //True disables pinch zoom features on itinerary wb graph
function Itinerary_initialize(TCNUM) {
  $('#RoutePlanCHK').prop('checked', false);
  FuelStandard = AC_data.FuelType;
  FuelType = FuelStandard;
  $('#AC_MAX_PERF').val(''); //so it will be automatically set at max gross when initialized
  $('#itin-flight-description').unbind('keyup', calculateWB).keyup(calculateWB);
  $('#male').unbind('click', maleWeight).click(maleWeight);
  $('#female').unbind('click', femaleWeight).click(femaleWeight);
  $('#emptySeat').unbind('click', emptySeatWeight).click(emptySeatWeight);
  if (ITIN_INIT == false) {
    var maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 30);
    returnDatePicker = new Pikaday({
      field: $('#returnDate')[0],
      firstDay: 1,
      minDate: new Date(),
      maxDate: maxDate,
      yearRange: [2014, 2114],
      defaultDate: new Date(),
      setDefaultDate: true,
      onSelect: dateChanged,
    });
    ofpDatePicker = new Pikaday({
      field: $('#OFP_Date')[0],
      firstDay: 1,
      minDate: new Date(),
      maxDate: maxDate,
      yearRange: [2014, 2114],
      defaultDate: new Date(),
      setDefaultDate: true,
      onSelect: dateChanged,
    });
  } else {
    //update min and max dates and set today's date by default
    var maxDate = new Date();
    var minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);
    maxDate.setDate(maxDate.getDate() + 30);
    returnDatePicker.setDate(new Date());
    returnDatePicker.setMinDate(minDate);
    returnDatePicker.setMaxDate(maxDate);

    ofpDatePicker.setDate(new Date());
    ofpDatePicker.setMinDate(minDate);
    ofpDatePicker.setMaxDate(maxDate);
  }
  ITIN_INIT = true;

  if ($('#TDG-REVAMP').length) {
    $('#TDG-REVAMP').unbind('click');
    $('#TDG-REVAMP').click(async (event) => {
      if ($('#TDG-REVAMP').prop('checked')) {
        $('#TDG-REVAMP-ManifestEdit').prop('disabled', false);
        const name = $('#Pilots').val();
        const pilotEmail = PILOT_data.find((pilot) => pilot.chatname === name).username;
        VueBridge.enterVue(
          `groups/${GROUP_DATA.group}/dangerous-goods/manifest?pilotEmail=${encodeURIComponent(pilotEmail)}`
        );
        return;
      }
      const confirmed = await uncheckDangerousGoodsWarningDialog();
      if (confirmed) {
        $('#TDG-REVAMP').prop('checked', false).checkboxradio('refresh');
        $('#TDG-REVAMP-ManifestEdit').prop('disabled', true);
        TdgManifestClearForCurrentItinerary();
        return;
      }
      $('#TDG-REVAMP').prop('checked', true).checkboxradio('refresh');
    });
  }

  if ($('#TDG').length) {
    //TDG element exists initialize TDG plugin

    $('#TDG').unbind('click', tdgToggle);
    $('#TDG').click(tdgToggle);
    $('#TDG').prop('checked', false);
  }
  $('#itin-flight-description').val('');
  $('#survival').attr('checked', false);
  $('#liferaft').attr('checked', false);
  /*
    $("#returnTime").clockpicker({
    		autoclose:true,
    		align:"right"
    	});
    	*/
  $('#exitWBSummaryPage').click(hrefHandler);
  $('#showWBSummaryPage').click(hrefHandler);
  passengers = 0;
  $('#WBH2').text(acIdent);

  $('#returnTime').val('');
  dateChanged();

  //$.getJSON('itinerary-AJAX.php?action=getPilots', function (json_data) {
  //PILOT_data = json_data;
  //$("#Pilots").append($("<option />").val("").text("None"));
  $('#SICs').append($('<option />').val('').text('None'));
  for (var i in PILOT_data) {
    $('#Pilots').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
    $('#SICs').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
    if (PILOT_data[i].chatname == chatname) username = PILOT_data[i].username;
  }
  for (var r in STAFF_LIST) {
    $('#ITIN_ResponsiblePerson').append($('<option />').val(STAFF_LIST[r].PrimaryKey).text(STAFF_LIST[r].chatname));
  }
  $('#ITIN_ResponsiblePerson').val(ItinResponsiblePersonPK);
  if (userShouldHaveFDT(USER_RIGHTS.staffType)) {
    $('#Pilots').val(chatname);
  } else {
    $('#Pilots').val('');
  }
  if (SCH_DATA.isLoading) {
    $('#Pilots').val(SCH_DATA.PIC);
    $('#SICs').val(SCH_DATA.SIC);
    if (SCH_DATA.ReturnDate != '') {
      $('#returnDate').val(SCH_DATA.ReturnDate);
      $('#returnTime').val(SCH_DATA.ReturnTime);
    }
  }
  if (CurPageID() == 'ITIN-INPUT') {
    $('#Pilots').selectmenu('refresh');

    $('#ITIN_ResponsiblePerson').selectmenu('refresh');
  }

  ACFuelBurn = AC_data.Consumption;
  ACcruise = AC_data.Cruise;
  acType = AC_data.AcType;
  SN = parseInt(AC_data.SN);
  if (AC_data.StartStop == 1) {
    $('#LegStartEHLDR').show();
    $('#LegEndEHLDR').show();
  } else {
    $('#LegStartEHLDR').hide();
    $('#LegEndEHLDR').hide();
  }

  var bagString = '<center><fieldset id="POS" data-role="controlgroup" data-type="horizontal" >';
  for (a in WB_data.Baggage) {
    //Each Baggage Compartment
    var bagRow = WB_data.Baggage[a].Row;
    bagString += '<table class="itintable"><tr class="itintable">';
    for (b in WB_data.Baggage[a].Bags) {
      var Bags = WB_data.Baggage[a].Bags[b];
      var Bag = Bags.Baggage;
      var bWT = Bags.WT;
      var bLat = Bags.Lat;
      var bLong = Bags.Long;
      var VariableBaggage = null;
      if (Bags.Variable != undefined) {
        VariableBaggage = Bags.Variable;
        bLat = VariableBaggage[0].CGL;
        bLong = VariableBaggage[0].CG;
      }
      var bStation =
        "<div class='WBSTATION'>" +
        convertToUserUNITS(bLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        ' | ' +
        convertToUserUNITS(bLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        '</div>';
      Loading.push({
        Abr: Bag,
        MaxWT: bWT,
        LoadLat: bLat,
        LoadLong: bLong,
        Variable: VariableBaggage,
      });
      bagString +=
        '<td class="itintable"><input type="radio" onClick="selectSliderInput()" name="Position" id="' +
        Bag +
        '" value="0" alt="Bag" data-longdefault="' +
        bLong +
        '" data-latdefault="' +
        bLat +
        '"/><label for="' +
        Bag +
        '"><font size="-2">' +
        Bag +
        '<br />0 ' +
        WBUnitLbl.Weight +
        bStation +
        '</font></label></td>';
    }
    bagString += '</tr></table>';
  }
  $('#bagLoad').append(bagString + '</fieldset>');
  FUEL_data = WB_data.Fuel;
  minFuel = null;
  for (f in FUEL_data) {
    if (
      FUEL_data[f].Type == FuelStandard &&
      SN >= parseInt(FUEL_data[f].SN_Low) &&
      SN <= parseInt(FUEL_data[f].SN_High)
    ) {
      if (minFuel === null) {
        minFuel = Math.ceil(parseFloat(FUEL_data[f].Tank[0].WT));
      }
      maxFuel = parseInt(FUEL_data[f].Capacity);
      ACmaxFuel = maxFuel;
    }
  }
  var DefaultFuel = Math.round(maxFuel / 2);
  if (AC_data.DefaultFuel != undefined) {
    if (AC_data.DefaultFuel <= maxFuel && AC_data.DefaultFuel >= 0) {
      DefaultFuel = AC_data.DefaultFuel > minFuel ? AC_data.DefaultFuel : minFuel;
    }
    if (AC_data.DefaultFuel > maxFuel) DefaultFuel = maxFuel;
  }
  var fStation =
    "<div class='WBSTATION'>" +
    convertToUserUNITS(0, 'Arm', AC_UNITS, WBUnitLbl.Type) +
    ' | ' +
    convertToUserUNITS(0, 'Arm', AC_UNITS, WBUnitLbl.Type) +
    '</div>';
  $('#fuelLoad').append(
    '<fieldset id="POS" data-role="controlgroup" data-type="horizontal" ><table class="itintable">     <tr class="itintable">        <td class="itintable"><input type="radio" onClick="selectSliderInput()" name="Position" id="Fuel" value="' +
      DefaultFuel +
      '" />        <label for="Fuel"><font size="-2">Fuel<br />' +
      convertToUserUNITS(DefaultFuel, 'Weight', AC_UNITS, WBUnitLbl.Type) +
      ' ' +
      WBUnitLbl.Weight +
      fStation +
      '</font></label></td></tr>        </table></fieldset>'
  );
  if (AC_data.noTC == 1) {
    noTC_ChangeOpt(true);
  } else {
    if (TC_data.length > 1)
      $('#WB_OPT').append(
        '<fieldset data-role="controlgroup"><legend><b>' + iTrans('Installed Optional Equipment') + '</b></legend>'
      );
    else if (WANDB == '1') $('#WB_OPT').append('<h2 class="limit-table">' + iTrans('No Optional Equipment') + '</h2>');
    tcDesc = '';
    for (var i in TC_data) {
      if (TC_data[i].TCNum == TCNUM) {
        //find active temp configuration for initial setup
        tcNum = TC_data[i].TCNum;
        tcIndex = i;
        if (WANDB == '1') {
          if (AC_UNITS == 'Imperial') {
            $('#TCNUM').html(iTrans('Temp Config') + ' #' + tcNum);
            $('#ItinWBUnitsLbl').html(
              iTrans('Temp Config') +
                ' #' +
                tcNum +
                '<br /><b>' +
                iTrans('Aircraft Graph Units') +
                ': </b>' +
                iTrans('Imperial') +
                ' lbs / in'
            );
          } else {
            $('#TCNUM').html(iTrans('Temp Config') + ' #' + tcNum);
            $('#ItinWBUnitsLbl').html(
              iTrans('Temp Config') + ' #' + tcNum + '<br /><b>' + iTrans('Aircraft Graph Units') + ': </b>kg / mm'
            );
          }
        } else $('#SEQ_Label2').html('');
        //TODO update weight and balance variables and reset basket weight to 0 if removed or added
        eacweight = parseFloat(TC_data[i].TCWeight);
        eLat = parseFloat(TC_data[i].TCLatMom);
        eLong = parseFloat(TC_data[i].TCHorzMom);
        ecofgLong = parseFloat(eLong / eacweight).toFixed(2);
        ecofgLat = parseFloat(eLat / eacweight).toFixed(2);
        for (var t in TC_data[i].Optional) {
          var opt = TC_data[i].Optional[t];
          //add optional equipment with initial setting
          tcDesc += opt.Abr + ',';
          $('#WB_OPT').append(
            '<input type="checkbox" name="opt_' +
              opt.Abr +
              '" id="opt_' +
              opt.Abr +
              '" data-mini="true" /><label for="opt_' +
              opt.Abr +
              '">' +
              opt.Description +
              '</label>'
          );

          $('#opt_' + opt.Abr).click(TCchange);
        }
        var optLoadString =
          '<fieldset id="POS" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable">';
        for (var t in TC_data[i].Optional) {
          opt = TC_data[i].Optional[t];
          if (opt.status == 1) {
            if (opt.Seating == 1) {
              SeatType = opt.SeatType;
            }
            $('#opt_' + opt.Abr).attr('checked', 'checked');
            if (opt.Loadable == 1) {
              if (opt.Fuel != 1) {
                var oStation =
                  '<div id="' +
                  opt.Abr +
                  '_NAME"></div>' +
                  "<div class='WBSTATION'>" +
                  convertToUserUNITS(opt.LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                  ' | ' +
                  convertToUserUNITS(opt.LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                  '<br />' +
                  opt.Description +
                  '</div>';
                if (opt.VariableArms == undefined) {
                  opt.VariableArms = 0;
                  opt.LoadLatMax = 0;
                  opt.LoadLongMax = 0;
                  opt.LoadLatMin = 0;
                  opt.LoadLongMin = 0;
                }
                optLoadString +=
                  '<td class="itintable"><input type="radio" onClick="selectSliderInput()" name="Position" id="' +
                  opt.Abr +
                  '" value="0" data-variable="' +
                  opt.VariableArms +
                  '" data-latmax="' +
                  opt.LoadLatMax +
                  '" data-latmin="' +
                  opt.LoadLatMin +
                  '" data-longmax="' +
                  opt.LoadLongMax +
                  '" data-longmin="' +
                  opt.LoadLongMin +
                  '"  data-latdefault="' +
                  opt.LoadLat +
                  '" data-longdefault="' +
                  opt.LoadLong +
                  '" /><label for="' +
                  opt.Abr +
                  '"><font size="-2">' +
                  opt.Abr +
                  '<br />0 ' +
                  WBUnitLbl.Weight +
                  oStation +
                  '</font></label></td>';
                optLoad.push({
                  Abr: opt.Abr,
                  MaxWT: opt.MaxWT,
                  LoadLat: opt.LoadLat,
                  LoadLong: opt.LoadLong,
                  ExternalLoad: opt.ExternalLoad,
                  VariableArms: opt.VariableArms,
                  LoadLatMax: opt.LoadLatMax,
                  LoadLongMax: opt.LoadLongMax,
                  LoadLatMin: opt.LoadLatMin,
                  LoadLongMin: opt.LoadLongMin,
                });
              }
            }
            //alert("conflict: "+opt.Abr);
            for (c in TC_data[i].Optional) {
              //search for conflict item and disable checkbox
              if (TC_data[i].Optional[c].conflictwith != null) {
                var con = TC_data[i].Optional[c].conflictwith.split('|');
                for (d in con) {
                  if (con[d] == opt.PrimaryKey) {
                    $('#opt_' + TC_data[i].Optional[c].Abr).prop('disabled', true); //disable match
                  }
                }
              }
            }
            if (opt.Fuel == 1) {
              for (f in FUEL_data) {
                if (
                  FUEL_data[f].Type == opt.Type &&
                  SN >= parseInt(FUEL_data[f].SN_Low) &&
                  SN <= parseInt(FUEL_data[f].SN_High)
                ) {
                  maxFuel = parseInt(FUEL_data[f].Capacity);
                  ACmaxFuel = maxFuel;
                  FuelType = opt.Type;
                }
              }
            }
          }
        }
      }
    }
  }

  for (var i in LOADABLE_data) {
    if (LOADABLE_data[i].Fuel == 1) {
      //plusFuel += parseInt(LOADABLE_data[i].MaxWT);
      for (f in FUEL_data) {
        if (
          FUEL_data[f].Type == LOADABLE_data[i].Type &&
          SN >= parseInt(FUEL_data[f].SN_Low) &&
          SN <= parseInt(FUEL_data[f].SN_High)
        ) {
          maxFuel = parseInt(FUEL_data[f].Capacity);
          ACmaxFuel = maxFuel;
          FuelStandard = LOADABLE_data[i].Type;
        }
      }
    }
    if (LOADABLE_data[i].Fuel != 1 && LOADABLE_data[i].Loadable == 1) {
      var lStation =
        '<div id="' +
        LOADABLE_data[i] +
        '_NAME"></div>' +
        "<div class='WBSTATION'>" +
        convertToUserUNITS(LOADABLE_data[i].LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        ' | ' +
        convertToUserUNITS(LOADABLE_data[i].LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        '<br />' +
        LOADABLE_data[i].Description +
        '</div>';
      if (LOADABLE_data[i].VariableArms == undefined) {
        LOADABLE_data[i].VariableArms = 0;
        LOADABLE_data[i].LoadLatMax = 0;
        LOADABLE_data[i].LoadLongMax = 0;
        LOADABLE_data[i].LoadLatMin = 0;
        LOADABLE_data[i].LoadLongMin = 0;
      }
      optLoadString +=
        '<td class="itintable"><input type="radio" onClick="selectSliderInput()" name="Position" id="' +
        LOADABLE_data[i].Abr +
        '" value="0" alt="Opt" data-variable="' +
        LOADABLE_data[i].VariableArms +
        '" data-latmax="' +
        LOADABLE_data[i].LoadLatMax +
        '" data-latmin="' +
        LOADABLE_data[i].LoadLatMin +
        '" data-longmax="' +
        LOADABLE_data[i].LoadLongMax +
        '" data-longmin="' +
        LOADABLE_data[i].LoadLongMin +
        '"  data-latdefault="' +
        LOADABLE_data[i].LoadLat +
        '" data-longdefault="' +
        LOADABLE_data[i].LoadLong +
        '"/><label for="' +
        LOADABLE_data[i].Abr +
        '"><font size="-2">' +
        LOADABLE_data[i].Abr +
        '<br />0 ' +
        WBUnitLbl.Weight +
        lStation +
        '</font></label></td>';
      optLoad.push({
        Abr: LOADABLE_data[i].Abr,
        MaxWT: LOADABLE_data[i].MaxWT,
        LoadLat: LOADABLE_data[i].LoadLat,
        LoadLong: LOADABLE_data[i].LoadLong,
        ExternalLoad: LOADABLE_data[i].ExternalLoad,
        VariableArms: LOADABLE_data[i].VariableArms,
        LoadLatMax: LOADABLE_data[i].LoadLatMax,
        LoadLongMax: LOADABLE_data[i].LoadLongMax,
        LoadLatMin: LOADABLE_data[i].LoadLatMin,
        LoadLongMin: LOADABLE_data[i].LoadLongMin,
      });
    }
    if (LOADABLE_data[i].cgRestrict == 1) {
      //set cofg graph standard for what is fixed to aircraft
      GraphName = LOADABLE_data[i].cgGraph;
      GraphStandard = GraphName;
    }
    if (LOADABLE_data[i].Seating == 1) {
      SeatStandard = LOADABLE_data[i].SeatType;
      if (SeatType == 'Standard') SeatType = SeatStandard; //prevent overriding possible optional selected
    }
  }

  //$("#Seating").append();
  var seatString = '<center><fieldset id="POS" data-role="controlgroup" data-type="horizontal" data-mini="true">';
  PaXnum = -1;
  for (var a in WB_data.Seating) {
    //Each Row
    if (WB_data.Seating[a].Type == SeatType) {
      var row = WB_data.Seating[a].Row;
      seatString += "<table class='itintable'><tr class='itintable'>";
      for (b in WB_data.Seating[a].Seats) {
        var Seats = WB_data.Seating[a].Seats[b];
        var Seat = Seats.Seat;
        var WT = Seats.WT;
        var Lat = Seats.Lat;
        var Long = Seats.Long;
        Loading.push({
          Abr: Seat,
          MaxWT: WT,
          LoadLat: Lat,
          LoadLong: Long,
        });
        var pStation =
          '<div id="' +
          Seat +
          '_NAME"></div>' +
          "<div class='WBSTATION'>" +
          convertToUserUNITS(Seats.Long, 'Arm', AC_UNITS, WBUnitLbl.Type) +
          ' | ' +
          convertToUserUNITS(Seats.Lat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
          '</div>';
        if (Seat == 'Pilot') {
          var PilotWeight = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === USER_PK);
          if (PilotWeight != undefined) {
            PilotWeight = PilotWeight.bodyWeight;
          } else {
            PilotWeight = 0;
          }
          seatString +=
            '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
            Seat +
            '" value="' +
            convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
            '" alt="Pax"/><label for="' +
            Seat +
            '"><font size="-2">' +
            Seat +
            '<br />' +
            convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
            WBUnitLbl.Weight +
            pStation +
            '</font></label></td>';
        } else {
          seatString +=
            '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
            Seat +
            '" value="0" alt="Pax"/><label for="' +
            Seat +
            '"><font size="-2">' +
            Seat +
            '<br />0 ' +
            WBUnitLbl.Weight +
            pStation +
            '</font></label></td>';
        }
        PaXnum++;
      }
      seatString += '</tr></table>';
    }
  }
  $('#PaX').html(itin_generatePaxInput(PaXnum, +TEMPLATE_PAX)).enhanceWithin();
  $('#Seating').append(seatString + '</fieldset>');

  $('#optLoad').append(optLoadString + '</tr></table></fieldset>');
  $("input[name='Position']").bind('click', positionChange);
  $('#Pilot').attr('checked', true);

  if (TEMPLATE_LOAD !== false) {
    var WBS = TEMPLATE_LOAD;
    WBS = WBS.split(',');
    for (var w in WBS) {
      var W = WBS[w];
      if (W != null) {
        var LID = W.split(':');
        var LWT = parseFloat(LID[1]);
        if (LID[2] != undefined) {
          //RESTORE VARIABLE ARM ITEM*************************************************
          //****************************************************
          var varItem = $('#' + LID[0])[0];
          varItem.dataset.longdefault = LID[2];
          varItem.dataset.latdefault = LID[3];

          if ($('#WBstations').is(':checked')) {
            $("label[for='" + LID[0] + "']").html(
              '<font size="-2">' +
                LID[0] +
                '<br />' +
                convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
                '<div id="' +
                LID[0] +
                '_NAME"></div>' +
                '<div class="WBSTATION" style="display: block">' +
                LID[2] +
                ' | ' +
                LID[3] +
                '</div></font>'
            );
          } else {
            $("label[for='" + LID[0] + "']").html(
              '<font size="-2">' +
                LID[0] +
                '<br />' +
                convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
                '<div id="' +
                LID[0] +
                '_NAME"></div>' +
                '<div class="WBSTATION">' +
                LID[2] +
                ' | ' +
                LID[3] +
                '</div></font>'
            );
          }
          $("input[id='" + LID[0] + "']").val(LWT);
        } else {
          $("label[for='" + LID[0] + "']").html(
            '<font size="-2">' + LID[0] + '<br />' + convertACToUserUnits(LWT, 'Weight', AC_UNITS)
          );
        }
        $("input[id='" + LID[0] + "']").val(LWT);
      }
    }
    $("label[for='Fuel']").html('<font size="-2">Fuel<br />' + convertACToUserUnits(TEMPLATE_FUEL, 'Weight', AC_UNITS));
    $('#Fuel').val(TEMPLATE_FUEL);
  }

  const unit = LOCALSTORAGE.UserUNITS === 'Imperial' ? 'Lbs' : 'Kg';
  const labelText = `${iTrans('Total Cargo')} (${unit})`;
  const inputHTML =
    '<input type="number" min="0" max="999999" v-t="menu_Itinerary_LANG.WB_CARGO_TOTAL" id="WB_CARGO_TOTAL" value="0" />';
  $('#WB_CARGO').html(`<label for="WB_CARGO_TOTAL">${labelText}</label> ${inputHTML}`);

  $('#ITIN-WEIGHTBALANCE').enhanceWithin();
  $('#WB_OPT').enhanceWithin();
  if ($('#WBstations').is(':checked')) {
    $('.WBSTATION').show();
  }
  $('#VariableArmHLDR').hide(); //hide variable arm by default
  calculateWB();
  updatePaxSeatingNames();
  //});
  //});
  //});
  //});
}

function TCchange() {
  if (AC_data.noTC == 1) {
    noTC_ChangeOpt();
    return;
  }
  var oldTC = tcNum;
  FuelType = FuelStandard;
  GraphName = GraphStandard;
  var SeatChange = false;
  var optSeatChange = false;
  //SeatType = SeatStandard;
  if (SeatType != SeatStandard) SeatChange = true;
  for (var f in FUEL_data) {
    if (FUEL_data[f].Type == FuelType && SN >= parseInt(FUEL_data[f].SN_Low) && SN <= parseInt(FUEL_data[f].SN_High)) {
      maxFuel = parseInt(FUEL_data[f].Capacity);
      ACmaxFuel = maxFuel;
    }
  }
  $('#optLoad').html('');
  for (var i in TC_data[0].Optional) {
    var ena = TC_data[0].Optional[i].Abr;
    $('#opt_' + ena).checkboxradio('enable'); //enabled all checkboxes
  }
  for (var i in TC_data) {
    var match = true;
    for (var b in TC_data[i].Optional) {
      var opt = TC_data[i].Optional[b];
      if (
        (opt.status == 0 && $('#opt_' + opt.Abr).is(':checked') == true) ||
        (opt.status == 1 && $('#opt_' + opt.Abr).is(':checked') == false)
      )
        match = false;
    }
    if (match == true) {
      optLoad = [];
      var optLoadString =
        '<fieldset id="POS" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable">';
      for (var t in TC_data[i].Optional) {
        opt = TC_data[i].Optional[t];
        if ($('#opt_' + opt.Abr).is(':checked')) {
          for (var c in TC_data[i].Optional) {
            //search for conflict item and disable checkbox
            //var con = TC_data[i].Optional[c].conflictwith;
            //if (con == opt.Description) $("#opt_"+TC_data[i].Optional[c].Abr).checkboxradio("disable"); //disable match
            if (opt.conflictwith !== null) {
              var con = opt.conflictwith.split('|');
              for (var e in con) {
                if (con[e] == TC_data[i].Optional[c].PrimaryKey) {
                  $('#opt_' + TC_data[i].Optional[c].Abr).checkboxradio('disable'); //disable match
                }
              }
            }
          }
        }
        if (opt.status == 1) {
          if (opt.Loadable == 1) {
            if (opt.Fuel != 1) {
              var oStation =
                '<div id="' +
                opt.Abr +
                '_NAME"></div>' +
                "<div class='WBSTATION'>" +
                convertToUserUNITS(opt.LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                ' | ' +
                convertToUserUNITS(opt.LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                '<br />' +
                opt.Description +
                '</div>';
              if (opt.VariableArms == undefined) {
                opt.VariableArms = 0;
                opt.LoadLatMax = 0;
                opt.LoadLongMax = 0;
                opt.LoadLatMin = 0;
                opt.LoadLongMin = 0;
              }
              optLoadString +=
                '<td class="itintable"><input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
                opt.Abr +
                '" value="0" alt="Opt" data-variable="' +
                opt.VariableArms +
                '" data-latmax="' +
                opt.LoadLatMax +
                '" data-latmin="' +
                opt.LoadLatMin +
                '" data-longmax="' +
                opt.LoadLongMax +
                '" data-longmin="' +
                opt.LoadLongMin +
                '"  data-latdefault="' +
                opt.LoadLat +
                '" data-longdefault="' +
                opt.LoadLong +
                '"/><label for="' +
                opt.Abr +
                '"><font size="-2">' +
                opt.Abr +
                '<br />0 ' +
                WBUnitLbl.Weight +
                oStation +
                '</font></label></td>';
              optLoad.push({
                Abr: opt.Abr,
                MaxWT: opt.MaxWT,
                LoadLat: opt.LoadLat,
                LoadLong: opt.LoadLong,
                ExternalLoad: opt.ExternalLoad,
                VariableArms: opt.VariableArms,
                LoadLatMax: opt.LoadLatMax,
                LoadLongMax: opt.LoadLongMax,
                LoadLatMin: opt.LoadLatMin,
                LoadLongMin: opt.LoadLongMin,
              });
            }
          }
          if (opt.Seating == 1) {
            if (SeatType != opt.SeatType) {
              //seating configuration has changed
              SeatChange = true;
              SeatType = opt.SeatType;
              optSeatChange = true;
            } else {
              //seating configuration is the same
              SeatChange = false;
              optSeatChange = true;
            }
          }

          if (opt.Fuel == 1) {
            //plusFuel += parseInt(opt.MaxWT)
            for (f in FUEL_data) {
              if (
                FUEL_data[f].Type == opt.Type &&
                SN >= parseInt(FUEL_data[f].SN_Low) &&
                SN <= parseInt(FUEL_data[f].SN_High)
              ) {
                maxFuel = parseInt(FUEL_data[f].Capacity);
                ACmaxFuel = maxFuel;
                FuelType = opt.Type;
              }
            }
          }
        }
      }

      for (var L in LOADABLE_data) {
        if (LOADABLE_data[L].Fuel == 1) {
          //plusFuel += parseInt(LOADABLE_data[L].MaxWT);
          for (var f in FUEL_data) {
            if (
              FUEL_data[f].Type == LOADABLE_data[L].Type &&
              SN >= parseInt(FUEL_data[f].SN_Low) &&
              SN <= parseInt(FUEL_data[f].SN_High)
            ) {
              maxFuel = parseInt(FUEL_data[f].Capacity);
              FuelStandard = LOADABLE_data[L].Type;
            }
          }
        }
        if (LOADABLE_data[L].Fuel != 1 && LOADABLE_data[L].Loadable == 1) {
          var lStation =
            '<div id="' +
            LOADABLE_data[L].Abr +
            '_NAME"></div>' +
            "<div class='WBSTATION'>" +
            convertToUserUNITS(LOADABLE_data[L].LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            ' | ' +
            convertToUserUNITS(LOADABLE_data[L].LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            '<br />' +
            LOADABLE_data[L].Description +
            '</div>';
          if (LOADABLE_data[L].VariableArms == undefined) {
            LOADABLE_data[L].VariableArms = 0;
            LOADABLE_data[L].LoadLatMax = 0;
            LOADABLE_data[L].LoadLongMax = 0;
            LOADABLE_data[L].LoadLatMin = 0;
            LOADABLE_data[L].LoadLongMin = 0;
          }
          optLoadString +=
            '<td class="itintable"><input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
            LOADABLE_data[L].Abr +
            '" value="0" alt="Opt" data-variable="' +
            LOADABLE_data[L].VariableArms +
            '" data-latmax="' +
            LOADABLE_data[L].LoadLatMax +
            '" data-latmin="' +
            LOADABLE_data[L].LoadLatMin +
            '" data-longmax="' +
            LOADABLE_data[L].LoadLongMax +
            '" data-longmin="' +
            LOADABLE_data[L].LoadLongMin +
            '"  data-latdefault="' +
            LOADABLE_data[L].LoadLat +
            '" data-longdefault="' +
            LOADABLE_data[L].LoadLong +
            '"/><label for="' +
            LOADABLE_data[L].Abr +
            '"><font size="-2">' +
            LOADABLE_data[L].Abr +
            '<br />0 ' +
            WBUnitLbl.Weight +
            lStation +
            '</font></label></td>';
          optLoad.push({
            Abr: LOADABLE_data[L].Abr,
            MaxWT: LOADABLE_data[L].MaxWT,
            LoadLat: LOADABLE_data[L].LoadLat,
            LoadLong: LOADABLE_data[L].LoadLong,
            ExternalLoad: LOADABLE_data[L].ExternalLoad,
            VariableArms: LOADABLE_data[L].VariableArms,
            LoadLatMax: LOADABLE_data[L].LoadLatMax,
            LoadLongMax: LOADABLE_data[L].LoadLongMax,
            LoadLatMin: LOADABLE_data[L].LoadLatMin,
            LoadLongMin: LOADABLE_data[L].LoadLongMin,
          });
        }
        if (LOADABLE_data[L].cgRestrict == 1) {
          //set cofg graph standard for what is fixed to aircraft
          GraphName = LOADABLE_data[L].cgGraph;
          GraphStandard = GraphName;
        }
        if (LOADABLE_data[L].Seating == 1) {
          SeatStandard = LOADABLE_data[L].SeatType;
          if (SeatType == 'Standard') SeatType = SeatStandard; //prevent overriding possible optional selected
        }
      }

      if (SeatChange && !optSeatChange) SeatType = SeatStandard;
      if (SeatChange) {
        $('#PaX').html('');
        $('#Seating').html('');
        Loading = [];

        var seatString = '<center><fieldset id="POS" data-role="controlgroup" data-type="horizontal" data-mini="true">';
        PaXnum = 0;
        for (a in WB_data.Seating) {
          //Each Row
          if (WB_data.Seating[a].Type == SeatType) {
            var row = WB_data.Seating[a].Row;
            seatString += "<table class='itintable'><tr class='itintable'>";
            for (b in WB_data.Seating[a].Seats) {
              var Seats = WB_data.Seating[a].Seats[b];
              var Seat = Seats.Seat;
              var WT = Seats.WT;
              var Lat = Seats.Lat;
              var Long = Seats.Long;
              Loading.push({
                Abr: Seat,
                MaxWT: WT,
                LoadLat: Lat,
                LoadLong: Long,
              });
              var pStation =
                '<div id="' +
                Seat +
                '_NAME"></div>' +
                "<div class='WBSTATION'>" +
                convertToUserUNITS(Seats.Long, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                ' | ' +
                convertToUserUNITS(Seats.Lat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                '</div>';
              if (Seat == 'Pilot') {
                var PilotWeight = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === USER_PK);
                if (PilotWeight != undefined) {
                  PilotWeight = PilotWeight.bodyWeight;
                } else {
                  PilotWeight = 0;
                }
                seatString +=
                  '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
                  Seat +
                  '" value="' +
                  convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
                  '" alt="Pax"/><label for="' +
                  Seat +
                  '"><font size="-2">' +
                  Seat +
                  '<br /> ' +
                  convertACToUserUnits(PilotWeight, 'Weight', 'Imperial') +
                  pStation +
                  '</font></label></td>';
              } else {
                seatString +=
                  '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
                  Seat +
                  '" value="0" alt="Pax"/><label for="' +
                  Seat +
                  '"><font size="-2">' +
                  Seat +
                  '<br />0 ' +
                  WBUnitLbl.Weight +
                  pStation +
                  '</font></label></td>';
              }
              PaXnum++;
            }
            seatString += '</tr></table>';
          }
        }
        $('#PaX').html(itin_generatePaxInput(PaXnum, paxnum)).enhanceWithin();
        $('#Seating').append(seatString + '</fieldset>');
        //Re Add baggage to Loading Var
        for (var ab in WB_data.Baggage) {
          for (var bb in WB_data.Baggage[ab].Bags) {
            var Bags = WB_data.Baggage[ab].Bags[bb];
            var Bag = Bags.Baggage;
            var bWT = Bags.WT;
            var bLat = Bags.Lat;
            var bLong = Bags.Long;
            var VariableBaggage = null;
            if (Bags.Variable != undefined) {
              VariableBaggage = Bags.Variable;
              bLat = VariableBaggage[0].CGL;
              bLong = VariableBaggage[0].CG;
            }
            Loading.push({
              Abr: Bag,
              MaxWT: bWT,
              LoadLat: bLat,
              LoadLong: bLong,
              Variable: VariableBaggage,
            });
          }
        }
      }
      if ($('#Fuel').val() > maxFuel) {
        $('#Fuel').val(maxFuel);
        $("label[for='Fuel']").html('<font size="-2">Fuel<br />' + convertACToUserUnits(maxFuel, 'Weight', AC_UNITS));
      }
      if ($('#Fuel').is(':checked'))
        $('#slider').attr('max', convertToUserUNITS(maxFuel, 'Weight', AC_UNITS, WBUnitLbl.Type)).slider('refresh');
      $('#optLoad').append(optLoadString + '</tr></table></fieldset>');
      $("input[name='Position']").bind('click', positionChange);
      $('#Pilot').attr('checked', true);
      //$("#optLoad").enhanceWithin();
      $('#ITIN-WEIGHTBALANCE').enhanceWithin();
      //$("#WB_OPT").enhanceWithin();
      tcNum = TC_data[i].TCNum;
      tcIndex = i;
      $('#TCNUM').html('<h2>' + iTrans('Temp Config') + ' #' + tcNum + '</h2>');

      if (AC_UNITS == 'Imperial') {
        $('#TCNUM').html(iTrans('Temp Config') + ' #' + tcNum);
        $('#ItinWBUnitsLbl').html(
          iTrans('Temp Config') +
            ' #' +
            tcNum +
            '<br /><b>' +
            iTrans('Aircraft Graph Units') +
            ': </b>' +
            iTrans('Imperial') +
            ' lbs / in'
        );
      } else {
        $('#TCNUM').html(iTrans('Temp Config') + ' #' + tcNum);
        $('#ItinWBUnitsLbl').html(
          iTrans('Temp Config') + ' #' + tcNum + '<br /><b>' + iTrans('Aircraft Graph Units') + ': </b>kg / mm'
        );
      }

      $.getJSON(
        BASEURL + 'menu-Itinerary-AJAX.php?action=updateTC&acident=' + acIdent + '&oldTC=' + oldTC + '&newTC=' + tcNum,
        function (json_data) {
          if (json_data[0].ServerResponse != 'Success') alert('Problem Saving Temp Config ' + json_data[0].Exception);
        }
      );
      eacweight = parseFloat(TC_data[i].TCWeight);
      eLat = parseFloat(TC_data[i].TCLatMom);
      eLong = parseFloat(TC_data[i].TCHorzMom);
      ecofgLong = parseFloat(eLong / eacweight).toFixed(2);
      ecofgLat = parseFloat(eLat / eacweight).toFixed(2);
      calculateWB();
      updatePaxSeatingNames();
      break;
    }
  }
}

function noTC_ChangeOpt(setDefault = false) {
  var oldTC = tcNum;
  tcDesc = '';
  FuelType = FuelStandard;
  GraphName = GraphStandard;
  var SeatChange = false;
  var optSeatChange = false;
  //SeatType = SeatStandard;
  if (SeatType != SeatStandard) SeatChange = true;
  for (var f in FUEL_data) {
    if (FUEL_data[f].Type == FuelType && SN >= parseInt(FUEL_data[f].SN_Low) && SN <= parseInt(FUEL_data[f].SN_High)) {
      maxFuel = parseInt(FUEL_data[f].Capacity);
      ACmaxFuel = maxFuel;
    }
  }
  $('#optLoad').html('');
  var Optional = JSON.parse(AC_data.opt_Items);
  var Defaults = {};
  if (AC_data.opt_Status != undefined && AC_data.opt_Status != 'null') {
    Defaults = JSON.parse(AC_data.opt_Status);
  } else {
    for (var i in Optional) {
      Defaults[Optional[i].Abr] = 0;
    }
  }
  var Amendment = JSON.parse(AC_data.Amendment);
  var TC_data = {
    Date: Amendment.Date,
    ANum: Amendment.ANum,
    TCNum: 'N/A',
    TCWeight: Number(Amendment.Weight),
    TCHorzMom: Number(Amendment.HorzMom),
    TCLatMom: Number(Amendment.LatMom),
    Active: 1,
    Optional: Optional,
  };
  if (setDefault === true) {
    if (Optional.length > 0)
      $('#WB_OPT').append(
        '<fieldset data-role="controlgroup"><legend><b>' + iTrans('Installed Optional Equipment') + '</b></legend>'
      );
    else if (WANDB == '1') $('#WB_OPT').append('<h2 class="limit-table">' + iTrans('No Optional Equipment') + '</h2>');
  }
  for (var i in Optional) {
    var O = Optional[i];
    if (setDefault === true) {
      $('#WB_OPT').append(
        '<input type="checkbox" name="opt_' +
          O.Abr +
          '" id="opt_' +
          O.Abr +
          '" data-mini="true" /><label for="opt_' +
          O.Abr +
          '">' +
          O.Description +
          '</label>'
      );
      $('#opt_' + O.Abr).click(noTC_ChangeOpt);
    }
    if (O.Kit == 1) {
      var K = GetKitTotalChange(O);
      O.Weight = K.Weight;
      O.HorzArm = K.HorzArm;
      O.LatArm = K.LatArm;
      O.HorzMom = K.HorzMom;
      O.LatMom = K.LatMom;
    } else {
      O.HorzMom = roundNum(O.Weight * O.HorzArm, 2);
      O.LatMom = roundNum(O.Weight * O.LatArm);
    }
    if (setDefault !== true) {
      $('#opt_' + O.Abr).checkboxradio('enable'); //enabled all checkboxes
    }
    if ($('#opt_' + O.Abr).is(':checked') == true) {
      if (O.Optional == 'Removed') {
        TC_data.TCWeight += Number(O.Weight);
        TC_data.TCHorzMom += Number(O.HorzMom);
        TC_data.TCLatMom += Number(O.LatMom);
      }
    } else {
      if (O.Optional == 'Installed') {
        TC_data.TCWeight -= Number(O.Weight);
        TC_data.TCHorzMom -= Number(O.HorzMom);
        TC_data.TCLatMom -= Number(O.LatMom);
      }
    }
  }
  eacweight = parseFloat(TC_data.TCWeight);
  if (setDefault === true) {
    for (var i in Defaults) {
      var D = Defaults[i];
      if (D == 1) tcDesc += i + ',';
      $('#opt_' + i).prop('checked', D == 1);
      try {
        $('#opt_' + i).checkboxradio('refresh');
      } catch (e) {
        //do nothing
      }
    }
  } else {
    for (var i in Defaults) {
      if ($('#opt_' + i).is(':checked')) {
        tcDesc += i + ',';
        Defaults[i] = 1;
      } else {
        Defaults[i] = 0;
      }
    }
    AC_data.opt_Status = JSON.stringify(Defaults);
  }

  optLoad = [];
  var optLoadString =
    '<fieldset id="POS" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable">';
  $('#WB_OPT').enhanceWithin();
  for (var t in TC_data.Optional) {
    var opt = TC_data.Optional[t];
    if ($('#opt_' + opt.Abr).is(':checked')) {
      opt.status = 1;
      for (var c in TC_data.Optional) {
        if (opt.Conflict !== null) {
          var con = opt.Conflict.split('|');
          for (var e in con) {
            if (con[e] == TC_data.Optional[c].PrimaryKey) {
              $('#opt_' + TC_data.Optional[c].Abr).checkboxradio('disable'); //disable match
            }
          }
        }
      }
    } else {
      opt.status = 0;
    }
    if (opt.status == 1) {
      if (opt.Loadable == 1) {
        if (opt.Fuel != 1) {
          var oStation =
            '<div id="' +
            opt.Abr +
            '_NAME"></div>' +
            "<div class='WBSTATION'>" +
            convertToUserUNITS(opt.LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            ' | ' +
            convertToUserUNITS(opt.LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            '<br />' +
            opt.Description +
            '</div>';
          if (opt.VariableArms == undefined) {
            opt.VariableArms = 0;
            opt.LoadLatMax = 0;
            opt.LoadLongMax = 0;
            opt.LoadLatMin = 0;
            opt.LoadLongMin = 0;
          }
          optLoadString +=
            '<td class="itintable"><input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
            opt.Abr +
            '" value="0" alt="Opt" data-variable="' +
            opt.VariableArms +
            '" data-latmax="' +
            opt.LoadLatMax +
            '" data-latmin="' +
            opt.LoadLatMin +
            '" data-longmax="' +
            opt.LoadLongMax +
            '" data-longmin="' +
            opt.LoadLongMin +
            '"  data-latdefault="' +
            opt.LoadLat +
            '" data-longdefault="' +
            opt.LoadLong +
            '"/><label for="' +
            opt.Abr +
            '"><font size="-2">' +
            opt.Abr +
            '<br />0 ' +
            WBUnitLbl.Weight +
            oStation +
            '</font></label></td>';
          optLoad.push({
            Abr: opt.Abr,
            MaxWT: opt.MaxWT,
            LoadLat: opt.LoadLat,
            LoadLong: opt.LoadLong,
            ExternalLoad: opt.ExternalLoad,
            VariableArms: opt.VariableArms,
            LoadLatMax: opt.LoadLatMax,
            LoadLongMax: opt.LoadLongMax,
            LoadLatMin: opt.LoadLatMin,
            LoadLongMin: opt.LoadLongMin,
          });
        }
      }
      if (opt.Seating == 1) {
        if (SeatType != opt.SeatType) {
          //seating configuration has changed
          SeatChange = true;
          SeatType = opt.SeatType;
          optSeatChange = true;
        } else {
          //seating configuration is the same
          SeatChange = false;
          optSeatChange = true;
        }
      }

      if (opt.Fuel == 1) {
        //plusFuel += parseInt(opt.MaxWT)
        for (f in FUEL_data) {
          if (
            FUEL_data[f].Type == opt.Type &&
            SN >= parseInt(FUEL_data[f].SN_Low) &&
            SN <= parseInt(FUEL_data[f].SN_High)
          ) {
            maxFuel = parseInt(FUEL_data[f].Capacity);
            ACmaxFuel = maxFuel;
            FuelType = opt.Type;
          }
        }
      }
    }
  }

  for (var L in LOADABLE_data) {
    if (LOADABLE_data[L].Fuel == 1) {
      //plusFuel += parseInt(LOADABLE_data[L].MaxWT);
      for (var f in FUEL_data) {
        if (
          FUEL_data[f].Type == LOADABLE_data[L].Type &&
          SN >= parseInt(FUEL_data[f].SN_Low) &&
          SN <= parseInt(FUEL_data[f].SN_High)
        ) {
          maxFuel = parseInt(FUEL_data[f].Capacity);
          FuelStandard = LOADABLE_data[L].Type;
        }
      }
    }
    if (LOADABLE_data[L].Fuel != 1 && LOADABLE_data[L].Loadable == 1) {
      var lStation =
        '<div id="' +
        LOADABLE_data[L].Abr +
        '_NAME"></div>' +
        "<div class='WBSTATION'>" +
        convertToUserUNITS(LOADABLE_data[L].LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        ' | ' +
        convertToUserUNITS(LOADABLE_data[L].LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        '<br />' +
        LOADABLE_data[L].Description +
        '</div>';
      if (LOADABLE_data[L].VariableArms == undefined) {
        LOADABLE_data[L].VariableArms = 0;
        LOADABLE_data[L].LoadLatMax = 0;
        LOADABLE_data[L].LoadLongMax = 0;
        LOADABLE_data[L].LoadLatMin = 0;
        LOADABLE_data[L].LoadLongMin = 0;
      }
      optLoadString +=
        '<td class="itintable"><input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
        LOADABLE_data[L].Abr +
        '" value="0" alt="Opt" data-variable="' +
        LOADABLE_data[L].VariableArms +
        '" data-latmax="' +
        LOADABLE_data[L].LoadLatMax +
        '" data-latmin="' +
        LOADABLE_data[L].LoadLatMin +
        '" data-longmax="' +
        LOADABLE_data[L].LoadLongMax +
        '" data-longmin="' +
        LOADABLE_data[L].LoadLongMin +
        '"  data-latdefault="' +
        LOADABLE_data[L].LoadLat +
        '" data-longdefault="' +
        LOADABLE_data[L].LoadLong +
        '"/><label for="' +
        LOADABLE_data[L].Abr +
        '"><font size="-2">' +
        LOADABLE_data[L].Abr +
        '<br />0 ' +
        WBUnitLbl.Weight +
        lStation +
        '</font></label></td>';
      optLoad.push({
        Abr: LOADABLE_data[L].Abr,
        MaxWT: LOADABLE_data[L].MaxWT,
        LoadLat: LOADABLE_data[L].LoadLat,
        LoadLong: LOADABLE_data[L].LoadLong,
        ExternalLoad: LOADABLE_data[L].ExternalLoad,
        VariableArms: LOADABLE_data[L].VariableArms,
        LoadLatMax: LOADABLE_data[L].LoadLatMax,
        LoadLongMax: LOADABLE_data[L].LoadLongMax,
        LoadLatMin: LOADABLE_data[L].LoadLatMin,
        LoadLongMin: LOADABLE_data[L].LoadLongMin,
      });
    }
    if (LOADABLE_data[L].cgRestrict == 1) {
      //set cofg graph standard for what is fixed to aircraft
      GraphName = LOADABLE_data[L].cgGraph;
      GraphStandard = GraphName;
    }
    if (LOADABLE_data[L].Seating == 1) {
      SeatStandard = LOADABLE_data[L].SeatType;
      if (SeatType == 'Standard') SeatType = SeatStandard; //prevent overriding possible optional selected
    }
  }

  if (SeatChange && !optSeatChange) SeatType = SeatStandard;
  if (SeatChange) {
    $('#PaX').html('');
    $('#Seating').html('');
    Loading = [];

    var seatString = '<center><fieldset id="POS" data-role="controlgroup" data-type="horizontal" data-mini="true">';
    PaXnum = 0;
    for (var a in WB_data.Seating) {
      //Each Row
      if (WB_data.Seating[a].Type == SeatType) {
        var row = WB_data.Seating[a].Row;
        seatString += "<table class='itintable'><tr class='itintable'>";
        for (b in WB_data.Seating[a].Seats) {
          var Seats = WB_data.Seating[a].Seats[b];
          var Seat = Seats.Seat;
          var WT = Seats.WT;
          var Lat = Seats.Lat;
          var Long = Seats.Long;
          Loading.push({
            Abr: Seat,
            MaxWT: WT,
            LoadLat: Lat,
            LoadLong: Long,
          });
          var pStation =
            '<div id="' +
            Seat +
            '_NAME"></div>' +
            "<div class='WBSTATION'>" +
            convertToUserUNITS(Seats.Long, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            ' | ' +
            convertToUserUNITS(Seats.Lat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            '</div>';
          if (Seat == 'Pilot') {
            var PilotWeight = 206;
            if (LOCALSTORAGE.PilotWeight != undefined && !isNaN(LOCALSTORAGE.PilotWeight)) {
              PilotWeight = LOCALSTORAGE.PilotWeight;
            }
            seatString +=
              '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
              Seat +
              '" value="' +
              convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
              '" alt="Pax"/><label for="' +
              Seat +
              '"><font size="-2">' +
              Seat +
              '<br /> ' +
              convertACToUserUnits(PilotWeight, 'Weight', 'Imperial') +
              pStation +
              '</font></label></td>';
          } else {
            seatString +=
              '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
              Seat +
              '" value="0" alt="Pax"/><label for="' +
              Seat +
              '"><font size="-2">' +
              Seat +
              '<br />0 ' +
              WBUnitLbl.Weight +
              pStation +
              '</font></label></td>';
          }
          PaXnum++;
        }
        seatString += '</tr></table>';
      }
    }
    $('#PaX').html(itin_generatePaxInput(PaXnum, paxnum)).enhanceWithin();
    $('#Seating').append(seatString + '</fieldset>');
    //Re Add baggage to Loading Var
    for (var ab in WB_data.Baggage) {
      for (var bb in WB_data.Baggage[ab].Bags) {
        var Bags = WB_data.Baggage[ab].Bags[bb];
        var Bag = Bags.Baggage;
        var bWT = Bags.WT;
        var bLat = Bags.Lat;
        var bLong = Bags.Long;
        var VariableBaggage = null;
        if (Bags.Variable != undefined) {
          VariableBaggage = Bags.Variable;
          bLat = VariableBaggage[0].CGL;
          bLong = VariableBaggage[0].CG;
        }
        Loading.push({
          Abr: Bag,
          MaxWT: bWT,
          LoadLat: bLat,
          LoadLong: bLong,
          Variable: VariableBaggage,
        });
      }
    }
  }
  if ($('#Fuel').val() > maxFuel) {
    $('#Fuel').val(maxFuel);
    $("label[for='Fuel']").html('<font size="-2">Fuel<br />' + convertACToUserUnits(maxFuel, 'Weight', AC_UNITS));
  }
  if ($('#Fuel').is(':checked'))
    $('#slider').attr('max', convertToUserUNITS(maxFuel, 'Weight', AC_UNITS, WBUnitLbl.Type)).slider('refresh');
  $('#optLoad').append(optLoadString + '</tr></table></fieldset>');
  $("input[name='Position']").bind('click', positionChange);
  $('#Pilot').attr('checked', true);
  //$("#optLoad").enhanceWithin();
  $('#ITIN-WEIGHTBALANCE').enhanceWithin();
  //$("#WB_OPT").enhanceWithin();
  tcNum = 'N/A';
  tcIndex = 0;
  $('#TCNUM').html('<h2>' + iTrans('Temp Config') + ' #' + tcNum + '</h2>');

  if (AC_UNITS == 'Imperial') {
    $('#TCNUM').html(iTrans('Temp Config') + ' #' + tcNum);
    $('#ItinWBUnitsLbl').html(
      iTrans('Temp Config') +
        ' #' +
        tcNum +
        '<br /><b>' +
        iTrans('Aircraft Graph Units') +
        ': </b>' +
        iTrans('Imperial') +
        ' lbs / in'
    );
  } else {
    $('#TCNUM').html(iTrans('Temp Config') + ' #' + tcNum);
    $('#ItinWBUnitsLbl').html(
      iTrans('Temp Config') + ' #' + tcNum + '<br /><b>' + iTrans('Aircraft Graph Units') + ': </b>kg / mm'
    );
  }

  eacweight = parseFloat(TC_data.TCWeight);
  eLat = parseFloat(TC_data.TCLatMom);
  eLong = parseFloat(TC_data.TCHorzMom);
  ecofgLong = parseFloat(eLong / eacweight).toFixed(2);
  ecofgLat = parseFloat(eLat / eacweight).toFixed(2);
  if (setDefault !== true) {
    save_opt_Status(Defaults, AC_data.ident);
    calculateWB();
    updatePaxSeatingNames();
  }
}

var goodWB = 0;
var minTime;

function timeChanged() {
  if ($('#returnTime').val() != '') {
    var Diff = time_Diff(minTime, $('#returnTime').val());
    if (Diff < 0) {
      $('#returnTime').val(minTime);
      $('#returnTime').focus();
      return;
    }
  }
  calculateWB();
}

function dateChanged() {
  var dateObj = new Date();
  var timeHours = dateObj.getHours();
  var timeMinutes = dateObj.getMinutes();
  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    timeHours = dateObj.getUTCHours();
    timeMinutes = dateObj.getUTCMinutes();
  }
  if (timeHours < 10) timeHours = '0' + timeHours;
  if (timeMinutes < 10) timeMinutes = '0' + timeMinutes;
  timeHours = timeHours.toString();
  timeMinutes = timeMinutes.toString();
  var todaytime = timeHours + timeMinutes;
  //var selectedDate = $('#returnDate').val();
  var selectedDate = returnDatePicker.getDate();

  if (
    selectedDate.getMonth() > dateObj.getMonth() ||
    (selectedDate.getMonth() == dateObj.getMonth() && selectedDate.getDate() > dateObj.getDate()) ||
    (selectedDate.getMonth() < dateObj.getMonth() && selectedDate.getFullYear() > dateObj.getFullYear())
  ) {
    minTime = '0000';
  } else {
    var returnTime = $('#returnTime').val();
    if (returnTime !== '') {
      //only change time if returnTime is less than todaytime
      var Diff = time_Diff(todaytime, $('#returnTime').val());
      if (Diff < 0) {
        $('#returnTime').val(todaytime);
        $('#returnTime').focus();
        return;
      }
    }
    minTime = todaytime;
  }
}

var curPosition = 'Pilot';
var maxFuel = 0;
var minFuel = null;
var maxHook;

function positionChange() {
  $('#slider').blur();
  if (this.id == 'Fuel')
    $('#slider').attr('max', convertToUserUNITS(maxFuel, 'Weight', AC_UNITS, WBUnitLbl.Type)).slider('refresh');
  for (i in Loading) {
    if (this.id == Loading[i].Abr)
      $('#slider')
        .attr('max', convertToUserUNITS(Loading[i].MaxWT, 'Weight', AC_UNITS, WBUnitLbl.Type))
        .slider('refresh');
  }
  for (i in optLoad) {
    if (this.id == optLoad[i].Abr)
      $('#slider')
        .attr('max', convertToUserUNITS(optLoad[i].MaxWT, 'Weight', AC_UNITS, WBUnitLbl.Type))
        .slider('refresh');
  }
  curPosition = this.id;
  $("input[name='slider']").val(convertToUserUNITS($(this).val(), 'Weight', AC_UNITS, WBUnitLbl.Type));
  $("input[name='slider']").slider('refresh');
  var id = this.alt;
  if (id == 'Pax') {
    $('#male').removeClass('ui-disabled');
    $('#female').removeClass('ui-disabled');
    checkPaxSeatingPos();
  } else {
    $('#male').addClass('ui-disabled');
    $('#female').addClass('ui-disabled');
  }
  weightChangeP();
  //TraceBox for Testing
  //var traceVal = this.id;
  //$("input[type=text]").val(traceVal).trace2("refresh");
  //if (ANDROID) $("#slider").select();

  if (this.dataset.variable != undefined) {
    if (this.dataset.variable == 1) {
      $('#VariableArmHLDR').show();
      $('#VariableLongslider').attr('max', this.dataset.longmax).slider('refresh');
      $('#VariableLongslider').attr('min', this.dataset.longmin).slider('refresh');

      $('#VariableLatslider').attr('max', this.dataset.latmax).slider('refresh');
      $('#VariableLatslider').attr('min', this.dataset.latmin).slider('refresh');

      $("input[name='VariableLongslider']").val(this.dataset.longdefault);
      $("input[name='VariableLongslider']").slider('refresh');
      $("input[name='VariableLatslider']").val(this.dataset.latdefault);
      $("input[name='VariableLatslider']").slider('refresh');
    } else {
      $('#VariableArmHLDR').hide();
    }
  } else {
    $('#VariableArmHLDR').hide();
  }
}

function checkPaxSeatingPos() {
  var seatPos = curPosition;
  var hasEmpty = true;
  if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
    var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
    if (passengers.length == 0) {
      return;
    }
    for (var p in passengers) {
      let P = passengers[p];
      if (P.seatPos == seatPos) {
        return;
      }
      if (P.seatPos == '') {
        hasEmpty = true;
      }
    }
    if (hasEmpty) {
      selectPaxSeatPos();
    }
  }
}

function selectPaxSeatPos() {
  var seatPos = curPosition;
  if (seatPos == 'Pilot') {
    return;
  }
  var html =
    '<table class="first-td-fill"><thead><tr><th>' +
    iTrans('Last, First') +
    '</th><th>' +
    iTrans('Weight') +
    ' ' +
    (WBUnitLbl.Type == 'Imperial' ? 'lbs' : 'kg') +
    '</th><th>' +
    iTrans('Add') +
    '</th></tr></thead><tbody>';
  var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
  var hasAvailable = false;
  for (var p in passengers) {
    let P = passengers[p];
    if (P.seatPos == '' || P.seatPos == undefined) {
      hasAvailable = true;
      var Units = 'Metric';
      if (P.units == 'imperial') {
        Units = 'Imperial';
      }
      html +=
        '<tr><td>' +
        P.lastname +
        ', ' +
        P.firstname +
        '</td><td>' +
        convertToUserUNITS(P.weight, 'Weight', Units, WBUnitLbl.Type) +
        '</td><td><button onclick="assignPaxSeatPos(' +
        p +
        ');" data-mini="true" class="action-btn"><img src="./images/Check_mark_green.svg"></button></td></tr>';
    }
  }
  html += '</tbody></table>';
  if (hasAvailable) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: seatPos,
      headerClose: false,
      buttonPrompt: html,
      width: 400,
      buttons: {
        [iTrans('Cancel')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
  }
}
function assignPaxSeatPos(index) {
  $.mobile.sdCurrentDialog.close();
  var seatPos = curPosition;
  var passenger = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][index];
  passenger.seatPos = seatPos;
  ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][index].seatPos = seatPos;
  var Units = 'Metric';
  if (passenger.units == 'imperial') {
    Units = 'Imperial';
  }
  $("input[name='slider']").val(convertToUserUNITS(passenger.weight, 'Weight', Units, WBUnitLbl.Type));
  $("input[name='slider']").slider('refresh');
  weightChange();
  updatePaxSeatingNames();
}
function updatePaxSeatingNames() {
  if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
    var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
    for (var p in passengers) {
      let P = passengers[p];
      if (P.seatPos != '' || P.seatPos == undefined) {
        $('#' + P.seatPos + '_NAME').html(P.lastname + ', ' + P.firstname);
      }
    }
  }
}

function emptySeatWeight() {
  $("input[name='slider']").val(convertToUserUNITS(0, 'Weight', 'Imperial', WBUnitLbl.Type));
  $("input[name='slider']").slider('refresh');
  var seatPos = curPosition;
  if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
    var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
    for (var p in passengers) {
      let P = passengers[p];
      if (P.seatPos == seatPos) {
        P.seatPos = '';
        ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][p].seatPos = '';
        break;
      }
    }
  }
  weightChange();
  updatePaxSeatingNames();
}
function SlidernumpadChange(value) {
  $("input[name='slider']").val(value);
  $("input[name='slider']").slider('refresh');
  weightChange();
}
var paxnum = 0;
var curName = '';

function passengerChange(input) {
  paxnum = +input.value;
}

function femaleWeight() {
  $("input[name='slider']").val(convertToUserUNITS(GROUP_DATA.FemaleWeight, 'Weight', 'Imperial', WBUnitLbl.Type));
  $("input[name='slider']").slider('refresh');
  weightChange();
  updatePaxSeatingNames();
}

function maleWeight() {
  $("input[name='slider']").val(convertToUserUNITS(GROUP_DATA.MaleWeight, 'Weight', 'Imperial', WBUnitLbl.Type));
  $("input[name='slider']").slider('refresh');
  weightChange();
  updatePaxSeatingNames();
}

function weightChange() {
  //update label
  var oldlabel = $("label[for='" + curPosition + "']")[0].innerHTML;
  oldlabel = oldlabel.split('<div class="WBSTATION"');

  var weight = document.getElementById('slider').value;
  weight = convertToUserUNITS(weight, 'Weight', WBUnitLbl.Type, AC_UNITS);
  if (weight == '') {
    weight = 0;
    document.getElementById('slider2').value = convertToUserUNITS(weight, 'Weight', 'Imperial', WBUnitLbl.Type);
  } else weight = parseInt(weight);

  $("label[for='" + curPosition + "']").html(
    '<font size="-2">' +
      curPosition +
      '<br />' +
      convertACToUserUnits(weight, 'Weight', AC_UNITS) +
      '<div id="' +
      curPosition +
      '_NAME"></div>' +
      '<div class="WBSTATION"' +
      oldlabel[1]
  );
  $("input[id='" + curPosition + "']").val(weight);
  $('#ITIN-WEIGHTBALANCE').enhanceWithin();

  var alt = $('#' + curPosition).attr('alt');

  if (alt == 'Bag') {
    //addCargoWeight();
    for (var i in Loading) {
      if (Loading[i].Abr == curPosition) {
        if (Loading[i].Variable != undefined) {
          var CGL = null;
          var CG = null;
          for (var l in Loading[i].Variable) {
            var L = Loading[i].Variable[l];
            if (weight >= L.WT) {
              CGL = L.CGL;
              CG = L.CG;
              Loading[i].LoadLat = CGL;
              Loading[i].LoadLong = CG;
            }
          }
          if (CGL != null) {
            var varItem = $('#' + curPosition);
            varItem[0].dataset.longdefault = CG;
            varItem[0].dataset.latdefault = CGL;
            if ($('#WBstations').is(':checked')) {
              $("label[for='" + curPosition + "']").html(
                '<font size="-2">' +
                  curPosition +
                  '<br />' +
                  convertACToUserUnits(weight, 'Weight', AC_UNITS) +
                  '<div id="' +
                  curPosition +
                  '_NAME"></div>' +
                  '<div class="WBSTATION" style="display: block;">' +
                  CG +
                  ' | ' +
                  CGL +
                  '</div>'
              );
            } else {
              $("label[for='" + curPosition + "']").html(
                '<font size="-2">' +
                  curPosition +
                  '<br />' +
                  convertACToUserUnits(weight, 'Weight', AC_UNITS) +
                  '<div id="' +
                  curPosition +
                  '_NAME"></div>' +
                  '<div class="WBSTATION">' +
                  CG +
                  ' | ' +
                  CGL +
                  '</div>'
              );
            }
          }
        } else {
          break;
        }
      }
    }
  }
  calculateWB();
  updatePaxSeatingNames();
}

function LongChange() {
  var arm = document.getElementById('VariableLongslider').value;
  if (arm == '') {
    arm = 0;
    document.getElementById('VariableLongslider').value = arm;
  } else arm = parseFloat(arm);
  var LoadLong = 0;
  var LoadLat = 0;
  for (var i in optLoad) {
    if (optLoad[i].Abr == curPosition) {
      if (arm > optLoad[i].LoadLongMax) arm = optLoad[i].LoadLongMax;
      if (arm < optLoad[i].LoadLongMin) arm = optLoad[i].LoadLongMin;
      optLoad[i].LoadLong = arm;
      LoadLong = arm;
      LoadLat = optLoad[i].LoadLat;
    }
  }
  var weight = parseInt($('#slider').val());
  if ($('#WBstations').is(':checked')) {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        curPosition +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION" style="display: block;">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  } else {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        curPosition +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  }

  $('#ITIN-WEIGHTBALANCE').enhanceWithin();
  var varItem = $('#' + curPosition);
  varItem[0].dataset.longdefault = arm;
  calculateWB();
}

function LatChange() {
  var arm = document.getElementById('VariableLatslider').value;
  if (arm == '') {
    arm = 0;
    document.getElementById('VariableLatslider').value = arm;
  } else arm = parseFloat(arm);
  var LoadLong = 0;
  var LoadLat = 0;
  for (var i in optLoad) {
    if (optLoad[i].Abr == curPosition) {
      if (arm > optLoad[i].LoadLatMax) arm = optLoad[i].LoadLatMax;
      if (arm < optLoad[i].LoadLatMin) arm = optLoad[i].LoadLatMin;
      optLoad[i].LoadLat = arm;
      LoadLat = arm;
      LoadLong = optLoad[i].LoadLong;
    }
  }
  var weight = parseInt($('#slider').val());
  if ($('#WBstations2').is(':checked')) {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        curPosition +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION" style="display: block;">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  } else {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        curPosition +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  }
  $('#ITIN-WEIGHTBALANCE').enhanceWithin();
  var varItem = $('#' + curPosition);
  varItem[0].dataset.latdefault = arm;
  calculateWB();
}

function ShowLoadingStations(e) {
  if (e.checked) $('.WBSTATION').show();
  else $('.WBSTATION').hide();
}

function weightChangeP() {
  var value = document.getElementById('slider').value;
  if (curPosition == 'Fuel') {
    if (value < minFuel) {
      value = minFuel;
      document.getElementById('slider').value = value;
    }
  }
  if (value < 0) {
    value = 0;
    document.getElementById('slider').value = 0;
  }
  var m = $('#slider').attr('max');
  value = Math.floor((value / m) * 100);
  $("label[for='slider']").html('<b>' + iTrans('Weight') + ':<br />' + value + '%</b>');
}

function addCargoWeight() {
  var Total = 0;
  for (var a in WB_data.Baggage) {
    //Each Baggage Compartment
    var bagRow = WB_data.Baggage[a].Row;
    for (b in WB_data.Baggage[a].Bags) {
      var Bags = WB_data.Baggage[a].Bags[b];
      var Bag = Bags.Baggage;
      Total += parseFloat($('#' + Bag).val());
    }
    Total = Math.round(Total * 10) / 10;
    $('#WB_CARGO_TOTAL').val(Total);
  }
}
var WBSummary;
var WBCharts;

function submitItinerary() {
  const fileItinUIActions = () => {
    $('#cancelFileItineraryBTN').hide();
    $('#ITIN_File_Progress').html('');
    $('#saveFlightTemplate').prop('disabled', false);
    $('#itinsubmit').prop('disabled', false);
    $('#ExtraItinInfoTXT').html('');
  };

  const onValidCallback = async () => {
    fileItinUIActions();
    return await fileItinerary(WandB_PNG, 'OPEN');
  };

  const onAdminOverrideRequestCallback = async () => {
    fileItinUIActions();
    return await fileItinerary(WandB_PNG, 'PENDING');
  };

  VueBridge.showInvalidCapabilitiesDialog('CreateFlightWithTdg', onValidCallback, onAdminOverrideRequestCallback);
}

var WandB_PNG;

var ITINERARY_XHR_REQ = null;
function cancelFileItinerary() {
  if (ITINERARY_XHR_REQ != null) {
    ITINERARY_XHR_REQ.abort();
  }
  ITINERARY_XHR_REQ = null;
  $('#saveFlightTemplate').prop('disabled', false);
  document.getElementById('itinsubmit').disabled = false;
  document.getElementById('itinsubmitback').disabled = false;
  $('#cancelFileItineraryBTN').hide();
  $('#ITIN_File_Progress').html(iTrans('Cancelled!'));
  $('#ExtraItinInfoTXT').html('');
}

function initCountdownItineraryAbort() {
  $('#ExtraItinInfoTXT').html('<h3>' + iTrans('Submit Timeout in') + ' <span id="itineraryAbortTime">4:00</span></h3>');
  startAbortTimer();
}
function startAbortTimer() {
  if ($('#itineraryAbortTime').html() == undefined) {
    return;
  }
  var presentTime = document.getElementById('itineraryAbortTime').innerHTML;
  var timeArray = presentTime.split(/[:]+/);
  var m = timeArray[0];
  var s = checkAbortSecond(timeArray[1] - 1);
  if (s == 59) {
    m = m - 1;
  }
  if (m < 0) {
    return;
  }
  document.getElementById('itineraryAbortTime').innerHTML = m + ':' + s;
  setTimeout(startAbortTimer, 1000);
}

function checkAbortSecond(sec) {
  if (sec < 10 && sec >= 0) {
    sec = '0' + sec;
  } // add zero in front of numbers < 10
  if (sec < 0) {
    sec = '59';
  }
  return sec;
}
async function fileItinerary(Graph, status = 'OPEN') {
  document.getElementById('itinsubmit').disabled = true;
  document.getElementById('itinsubmitback').disabled = true;

  var RoutePlan, FP, SurvivalKit, LifeRaft, LifeJackets, VIP, Details, ReturnDate, ReturnTime, Pilot, SIC;

  let TDGRevamp = 0;
  let TDG = 0;

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    if ($('#TDG-REVAMP').is(':checked')) TDGRevamp = 1;
  } else {
    if ($('#TDG').is(':checked')) TDG = 1;
  }

  if ($('#RoutePlanCHK').is(':checked')) RoutePlan = 1;
  else RoutePlan = 0;
  if ($('#survival').is(':checked')) SurvivalKit = 1;
  else SurvivalKit = 0;
  if ($('#liferaft').is(':checked')) LifeRaft = 1;
  else LifeRaft = 0;

  if ($('#lifejackets').is(':checked')) LifeJackets = 1;
  else LifeJackets = 0;

  if ($('#Itin_IsVIP').is(':checked')) VIP = 1;
  else VIP = 0;
  var genFRNum;
  if ($('#ITINERARY_MakeFRCHK').is(':checked')) genFRNum = 1;

  Details = $('#itin-flight-description').val();
  if (Details == null) Details = '';
  var ReturnDate = returnDatePicker.getDate();
  var ReturnTime = $('#returnTime').val();
  var JobID = $('#ITIN_JOB').val();
  var JobClientID = $('#ITIN_JOBClientID').val();
  Pilot = $('#Pilots').val();
  var StartTimezone = $('#ITIN_StartTimezone').val();
  var EndTimezone = $('#ITIN_EndTimezone').val();
  var PilotWeight = convertToUserUNITS(parseInt($('#Pilot').val()), 'Weight', AC_UNITS, 'Imperial');
  LOCALSTORAGE.PilotWeight = PilotWeight;
  localStorageDB.setItem('PilotWeight', PilotWeight);
  SIC = $('#SICs').val();
  var timeback = null; // create a timeback datetime string from returndate and returntime

  var ReturnMonth = ReturnDate.getMonth() + 1;
  if (ReturnMonth < 10) ReturnMonth = '0' + ReturnMonth;
  var ReturnDay = ReturnDate.getDate();
  if (ReturnDay < 10) ReturnDay = '0' + ReturnDay;
  var ReturnHour = ReturnTime.substr(0, 2);
  var ReturnMin = ReturnTime.substr(2, 2);
  var timeback =
    ReturnDate.getFullYear() + '-' + ReturnMonth + '-' + ReturnDay + ' ' + ReturnHour + ':' + ReturnMin + ':00';
  var WB = WANDB; //make edits to allow for optional weight and balance for filing itinerary
  var otherwt = 0;
  var ESN = AC_data.esn;
  //var otherwt = attachedwt; //add array of passenger weights and baggage weights and attached weights
  var wbDate = TC_data[tcIndex].Date; //get wbDate from weight and balance stuff
  var Ehmom = TC_data[tcIndex].TCHorzMom;
  var Elmom = TC_data[tcIndex].TCLatMom;
  var TCdesc = tcDesc;
  var WBSummary = '';
  var CargoWeight = $('#WB_CARGO_TOTAL').val(); //total baggage weight or user overriede value
  for (var i in Loading) {
    if (Loading[i].Variable != undefined) {
      WBSummary +=
        Loading[i].Abr +
        ':' +
        $('#' + Loading[i].Abr).val() +
        ':' +
        Loading[i].LoadLong +
        ':' +
        Loading[i].LoadLat +
        ',';
    } else {
      if (Loading[i].VariableArms == 1) {
        WBSummary +=
          Loading[i].Abr +
          ':' +
          $('#' + Loading[i].Abr).val() +
          ':' +
          Loading[i].LoadLong +
          ':' +
          Loading[i].LoadLat +
          ',';
      } else {
        WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr).val() + ',';
      }
    }
    otherwt += parseFloat($('#' + Loading[i].Abr).val());
  }
  for (var i in optLoad) {
    if (optLoad[i].VariableArms == 1) {
      WBSummary +=
        optLoad[i].Abr +
        ':' +
        $('#' + optLoad[i].Abr).val() +
        ':' +
        optLoad[i].LoadLong +
        ':' +
        optLoad[i].LoadLat +
        ',';
    } else {
      WBSummary += optLoad[i].Abr + ':' + $('#' + optLoad[i].Abr).val() + ',';
    }
    otherwt += parseFloat($('#' + optLoad[i].Abr).val());
  }

  var timezone = ITIN_TIMEZONE;
  var TDG_Details = '';
  var TDG_Data = '';
  var TDG_Origin = '';
  var TDG_Dest = '';
  var TDG_signature = '';
  var TDG_signed_by = '';
  if (TDG == 1) {
    TDG_Details = 'unknown';
    if ($('#TDG_Loader').length) {
      TDG_Details = $('#TDG_Loader').val();
    } else {
      TDG_Details = 'Pilot';
    }
    TDG_Origin = $('#ITIN_TDG_Origin').val();
    TDG_Dest = $('#ITIN_TDG_Dest').val();
    TDG_signature = TDGsignatureDATA.data;
    TDG_signed_by = TDGsignatureDATA.text;
    TDG_Data = TDG_Manifest;
  }
  var EmailItin;
  if ($('#EmailItin').is(':checked')) EmailItin = 1;
  else EmailItin = 0;
  var EmailSummary = $('#wbReport').html() + '<br />';

  EmailSummary += $('#WB_summary').html();
  var Form_Data = JSON.stringify(RAform);
  var RA = 1;
  var SubmitPrimaryKey = 0;
  if (RAform == null) RA = 0;
  else {
    if (RAform.SubmitPrimaryKey != 0) SubmitPrimaryKey = RAform.SubmitPrimaryKey;
  }
  var Index = $('#select_Itin_Assets').val();
  var AssetData = [];
  var AssetDetails = '';
  var ASSET = 0;
  if (Index != -1) {
    //Asset Tracking is on
    ASSET = 1;
    AssetDetails = $('#select_Itin_Assets option:selected').text();
    var AssetStatus = $('input[type="checkbox"]')
      .filter('.ItinAssets')
      .map(function () {
        var name = $(this).attr('name');
        if ($(this).is(':checked'))
          return {
            name: name,
            status: true,
          };
        else
          return {
            name: name,
            status: false,
          };
      })
      .get();
    for (i in AssetStatus) {
      if (AssetStatus[i].status) {
        ItinAssetTemplates[Index].Assets[i].Status = $('#Asset_Status-' + i).val();
        ItinAssetTemplates[Index].Assets[i].Lat = UserDevice.Lat;
        ItinAssetTemplates[Index].Assets[i].Long = UserDevice.Long;
        //AssetData.push(ItinAssetTemplates[Index].Assets[i]);
        var duplicate = false;
        for (var s in ASSET_ADDED) {
          if (ASSET_ADDED[s].PrimaryKey == ItinAssetTemplates[Index].Assets[i].PrimaryKey) duplicate = true;
        }
        if (!duplicate) {
          ItinAssetTemplates[Index].Assets[i].TemplateName = ItinAssetTemplates[Index].Name;
          ASSET_ADDED.push(ItinAssetTemplates[Index].Assets[i]);
        }
      } else {
        for (var s in ASSET_ADDED) {
          if (ASSET_ADDED[s].PrimaryKey == ItinAssetTemplates[Index].Assets[i].PrimaryKey) {
            //remove Item from ASSET_ADDED
            ASSET_ADDED.splice(s, 1);
          }
        }
      }
    }
    AssetData = ASSET_ADDED;
  }
  if (FuelPlans.length > 0) FP = JSON.stringify(FuelPlans);
  else FP = 'None';
  var LEGS = '';
  if (SCH_DATA.LEGS != undefined) {
    if (SCH_DATA.LEGS.length > 0) LEGS = JSON.stringify(SCH_DATA.LEGS);
  }
  var Client = $('#itinClients').val();
  var PerReportFields = [];
  var CF = ITIN_Cfields;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != acIdent && I.Aircraft != '') {
        continue;
      }
      if (checkCfieldType('FR', I)) {
        PerReportFields.push(I);
        continue;
      }
      if (I.PerLeg == 0) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
      }
      PerReportFields.push(I);
    }
  }

  var OFP = {
    OperatorName: '',
    Date: '',
    Registration: '',
    TailNumber: '',
    TypeModel: '',
    FlightNumber: 0,
    FlightType: '',
    PICName: '',
    DispatcherName: '',
    DepartureIDENT: '',
    DestinationIDENT: '',
    AlternateIDENTs: '',
    DestinationROUTE: '',
    AlternateROUTE: '',
    EnrouteWaypoints: '',
    DestCruiseAlt: 0,
    AltCruiseAlt: 0,
    CruiseIAS: '',
    WindsAtCruise: '',
    TempAtCruise: 0,
    GroundSpeed: 0,
    ETE: '',
    TimeDestToAlt: '',
    Distance: '',
    DistDestToAlt: '',
    FuelBurnEnroute: '',
    FuelRequired: '',
    Weights: '',
    PersonsOnBoard: 0,
    PilotSignature: '',
    DispatchSignature: '',
    html: '',
  };
  var hasOFP = 0;
  if (GROUP_DATA.OFP == 1 && AC_data.OFP_REQ == 1) {
    var OFP_Date = ofpDatePicker.getDate();
    var OFPMonth = OFP_Date.getMonth() + 1;
    if (OFPMonth < 10) OFPMonth = '0' + OFPMonth;
    var OFPDay = OFP_Date.getDate();
    if (OFPDay < 10) OFPDay = '0' + OFPDay;
    var OFP_Date_Formatted = OFP_Date.getFullYear() + '-' + OFPMonth + '-' + OFPDay;
    OFP = {
      OperatorName: $('#OFP_OperatorName').val(),
      Date: OFP_Date_Formatted,
      Registration: $('#OFP_Registration').val(),
      TailNumber: $('#OFP_TailNumber').val(),
      TypeModel: $('#OFP_TypeModel').val(),
      FlightNumber: $('#OFP_FlightNumber').val(),
      FlightType: $('#OFP_FlightType').val(),
      PICName: $('#OFP_PIC').val(),
      DispatcherName: $('#OFP_DispatcherName').val(),
      DepartureIDENT: $('#OFP_DepartureIDENT').val().substr(0, 4),
      DestinationIDENT: $('#OFP_DestinationIDENT').val().substr(0, 4),
      AlternateIDENTs: $('#OFP_AlternateIDENTs').val(),
      DestinationROUTE: $('#OFP_DestinationROUTE').val(),
      AlternateROUTE: $('#OFP_AlternateROUTE').val(),
      EnrouteWaypoints: $('#OFP_EnrouteWaypoints').val(),
      DestCruiseAlt: $('#OFP_DestCruiseAlt').val(),
      AltCruiseAlt: $('#OFP_AltCruiseAlt').val(),
      CruiseIAS: $('#OFP_CruiseIAS').val(),
      WindsAtCruise: $('#OFP_WindsAtCruise').val(),
      TempAtCruise: $('#OFP_TempAtCruise').val(),
      GroundSpeed: $('#OFP_GroundSpeed').val(),
      ETE: $('#OFP_ETE').val(),
      TimeDestToAlt: $('#OFP_TimeDestToAlt').val(),
      Distance: $('#OFP_Distance').val(),
      DistDestToAlt: $('#OFP_DistDestToAlt').val(),
      FuelBurnEnroute: $('#OFP_FuelBurnEnroute').val(),
      FuelRequired: $('#OFP_FuelRequired').val(),
      Weights: $('#OFP_Weights').val(),
      PersonsOnBoard: $('#OFP_PersonsOnBoard').val(),
      PilotSignature: signatureDATA.data,
      DispatchSignature: '',
      html: $('#OFP_FuelPlanHLDR').html(),
    };
    hasOFP = 1;
  } else {
    OFP = {};
  }

  var Pax_Manifest = null;
  if (GROUP_DATA.Pax_Man == 1) {
    if (ITIN_Pax_Manifest.DefaultList != undefined) {
      var hasVIP = 0;
      for (var p in ITIN_Pax_Manifest.DefaultList) {
        var P = ITIN_Pax_Manifest.DefaultList[p];
        if (P.VIP == 1) hasVIP = 1;
      }
      if (ITIN_Pax_Manifest.Pax_Legs.length == 0) {
        ITIN_Pax_Manifest.Pax_Legs.push(JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList)));
      }
      var ObjLegs = {};
      for (var l in ITIN_Pax_Manifest.Pax_Legs) {
        for (var p in ITIN_Pax_Manifest.Pax_Legs[l]) {
          if (ITIN_Pax_Manifest.Pax_Legs[l][p].PersonalUse == undefined) {
            ITIN_Pax_Manifest.Pax_Legs[l][p].PersonalUse = 0;
          }
        }
        ObjLegs[l] = ITIN_Pax_Manifest.Pax_Legs[l];
      }
      if (ITIN_BookingPK == 0) {
        Pax_Manifest = {
          DefaultList: JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList)),
          Pax_Legs: ObjLegs,
          HasVIP: hasVIP,
        };
      } else {
        Pax_Manifest = {
          DefaultList: JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList)),
          Pax_Legs: ObjLegs,
          HasVIP: hasVIP,
        };
      }
    }
  }

  var FuelUplifts = null;
  if (GROUP_DATA.FuelCache == 1 && GROUP_DATA.FC_Itin_Capture == 1) {
    if (FC_Uplift.upLiftList[-1] != undefined) {
      FuelUplifts = FC_Uplift.upLiftList;
    }
  }

  var Cfields = JSON.stringify(PerReportFields);
  var itinData = {
    Cfields: Cfields,
    FC_Uplifts: '{}',
    Files: ITIN_Files,
    emailitin: EmailItin,
    emailsummary: EmailSummary,
    timezone: timezone,
    StartTimezone: StartTimezone,
    EndTimezone: EndTimezone,
    EntryTimezone: CurrentTimeZone,
    ident: acIdent,
    esn: ESN,
    pilot: Pilot,
    sic: SIC,
    paxnum: paxnum,
    timeback: timeback,
    survivalkit: SurvivalKit,
    liferaft: LifeRaft,
    lifejackets: LifeJackets,
    VIP: VIP,
    hasOFP: hasOFP,
    TDG: TDG,
    TDGRevamp: TDGRevamp,
    Details: Details,
    RoutePlan: RoutePlan,
    WB: WB,
    Route: PostRoute,
    acwt: facweight,
    fuelwt: fuelwt,
    otherwt: otherwt,
    CargoWeight: CargoWeight,
    cofglat: fcofgLat,
    cofglong: fcofgLong,
    tempconfig: tcNum,
    wbDate: wbDate,
    Eweight: eacweight,
    maxperfweight: maxperfweight,
    Ehmom: Ehmom,
    Elmom: Elmom,
    TCdesc: TCdesc,
    Summary: WBSummary,
    GRAPH: Graph,
    TDGdetails: TDG_Details,
    TDGdata: TDG_Data,
    TDG_Origin: TDG_Origin,
    TDG_Dest: TDG_Dest,
    TDG_signature: TDG_signature,
    TDG_signed_by: TDG_signed_by,
    ASSET: ASSET,
    AssetDetails: AssetDetails,
    AssetData: AssetData,
    FP: FP,
    ClientPK: Client,
    RAform: Form_Data,
    RA: RA,
    JobID: JobID,
    JobClientID: JobClientID,
    SubmitPrimaryKey: SubmitPrimaryKey,
    LEGS: LEGS,
    Pax_Manifest: Pax_Manifest,
    OFP: OFP,
    genFRNum: genFRNum,
    BookingPK: ITIN_BookingPK,
    SCH_AircraftPK: ITIN_SCH_AircraftPK,
    ResponsablePK: ItinResponsiblePersonPK,
    FuelUplifts: FuelUplifts,
    Status: status,
  };

  let capabilityActionData = {};

  $('#cancelFileItineraryBTN').show();
  $('#saveFlightTemplate').prop('disabled', true);
  //ProgressItinFiling **********************************************************************************
  initCountdownItineraryAbort();

  try {
    const data = await $.ajax({
      xhr: function () {
        ITINERARY_XHR_REQ = new window.XMLHttpRequest();
        ITINERARY_XHR_REQ.upload.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
              if (percentComplete == 100) {
                $('#ITIN_File_Progress').html('<h2>' + iTrans('Processing') + '...</h2>');
              } else {
                $('#ITIN_File_Progress').html(
                  '<h2>' + iTrans('Sending Itinerary and Attachments') + ': ' + percentComplete + '%</h2>'
                );
              }
            }
          },
          false
        );
        return ITINERARY_XHR_REQ;
      },
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Itinerary-AJAX.php?action=fileItinerary',
      timeout: 240000,
      data: itinData,
    });

    ITINERARY_XHR_REQ = null;
    $('#ExtraItinInfoTXT').html('');
    $('#cancelFileItineraryBTN').hide();
    $('#saveFlightTemplate').prop('disabled', false);
    if (data[0].ServerResponse == 'Uncaught Exception') {
      capabilityActionData = {
        success: false,
        associatedData: {},
      };
      ServerResponse(data[0], 'fileItinerary()');
      document.getElementById('itinsubmit').disabled = false;
      document.getElementById('itinsubmitback').disabled = false;
    }

    if (data[0].ServerResponse == 'Success') {
      if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
        VueBridge.TdgManifestLinkToItinerary(data[0].id);
      }

      $('#ITIN_File_Progress').html('');
      $.mobile.changePage($('#ITIN-CONFIRMATION'), {
        reverse: false,
        changeHash: false,
      });

      capabilityActionData = {
        success: true,
        associatedData: {
          itineraryId: data[0].id,
          offline: false,
        },
      };
      if (GROUP_DATA.FuelCache == 1 && FuelUplifts != null) {
        BackgroundSync(false, true);
      }

      document.getElementById('itinsubmit').disabled = false;
      document.getElementById('itinsubmitback').disabled = false;
      $('#exitWBSummaryPage').unbind('click', hrefHandler);
      $('#showWBSummaryPage').unbind('click', hrefHandler);

      if (data[0].PENDING == 'OPEN')
        $('#FileItinStatusText').html(iTrans('Cirro successfully logged your flight') + '!');
      else {
        $('#FileItinStatusText').html(
          iTrans('Your Itinerary has been filed successfully in the PENDING state') +
            '.  ' +
            iTrans(
              'Please wait for the appropriate person to approve your Risk Assessment and or Capabilities and open your itinerary'
            ) +
            '.'
        );
      }

      $('#LongCanvas').html('');
      $('#LatCanvas').html('');
      ITIN_Files = [];
      SYNC_FOLLOW = true;
      ITIN_InProgress = false;
      if (FORMS > 0) {
        FormsInItineraryCreator.instance().handleSave(data[0].id);
      }
      getFollowData(true); //create copy of local follow data
      if (Pax_Manifest != null) {
        PAX_syncPassengers(true);
      }
    }
    if (data[0].ServerResponse == 'Failed') {
      capabilityActionData = await fileErrorItinerary(itinData, Graph, status);
    }
    if (data[0].ServerResponse == 'Already Open') {
      capabilityActionData = {
        success: false,
        associatedData: {},
      };

      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: iTrans('There is already an open itinerary for this aircraft OR pilot.'),
        buttons: {
          [iTrans('Main Menu')]: {
            click: function () {
              $('#ITIN_File_Progress').html('');
              $('#exitWBSummaryPage').unbind('click', hrefHandler);
              $('#showWBSummaryPage').unbind('click', hrefHandler);
              document.getElementById('itinsubmit').disabled = false;
              document.getElementById('itinsubmitback').disabled = false;
              $.mobile.changePage($('#ITIN-FINALIZE'), {
                reverse: true,
                changeHash: false,
              });
            },
          },
        },
      });
    }
    if (data[0].ServerResponse == 'Invalid Timeback') {
      capabilityActionData = {
        success: false,
        associatedData: {},
      };

      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: iTrans('ETA selected has already expired!'),
        buttons: {
          [iTrans('Review Itinerary')]: {
            click: function () {
              $('#ITIN_File_Progress').html('');
              document.getElementById('itinsubmit').disabled = false;
              document.getElementById('itinsubmitback').disabled = false;
              itinNOW = 'ITIN-INPUT';
              $.mobile.changePage($('#ITIN-INPUT'), {
                reverse: true,
                changeHash: false,
              });
            },
          },
        },
      });
    }
  } catch (error) {
    ITINERARY_XHR_REQ = null;
    $('#ExtraItinInfoTXT').html('');
    $('#cancelFileItineraryBTN').hide();
    capabilityActionData = await fileErrorItinerary(itinData, Graph, status);
  }

  return capabilityActionData;
}

async function fileErrorItinerary(itinData, Graph, status) {
  return new Promise((resolve) => {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Connection Error'),
      headerClose: false,
      buttonPrompt:
        iTrans('There was a connection error when attempting to file your itinerary') +
        '.  <br />' +
        iTrans(
          'You can file this itinerary in OFFLINE mode, it will be saved locally and synchronized when you are back online'
        ) +
        '.  <br />' +
        iTrans(
          'If there is an itinerary already open for this aircraft or the ETA for this itinerary has already expired when synchronization happens: the itinerary will be immediatly put in a closed status'
        ),
      buttons: {
        [iTrans('Try Again')]: {
          click: async function () {
            $('#saveFlightTemplate').prop('disabled', true);
            $('#itinsubmit').prop('disabled', true);
            resolve(await fileItinerary(Graph, status));
          },
        },
        [iTrans('File Offline')]: {
          click: function () {
            $('#saveFlightTemplate').prop('disabled', true);
            $('#itinsubmit').prop('disabled', true);
            resolve(fileOfflineItinerary(itinData));
          },
        },
        Cancel: {
          click: function () {
            //Cancel file Itin
            $('#saveFlightTemplate').prop('disabled', false);
            $('#itinsubmit').prop('disabled', false);
            document.getElementById('itinsubmitback').disabled = false;

            resolve({
              success: false,
              associatedData: {},
            });
          },
        },
      },
    });
  });
}

function fileOfflineItinerary(itinData) {
  //save itinerary offline;
  var ReturnDate = returnDatePicker.getDate();
  var ReturnTime = $('#returnTime').val();
  var ReturnMonth = ReturnDate.getMonth() + 1;
  if (ReturnMonth < 10) ReturnMonth = '0' + ReturnMonth;
  var ReturnDay = ReturnDate.getDate();
  if (ReturnDay < 10) ReturnDay = '0' + ReturnDay;
  var ReturnHour = ReturnTime.substr(0, 2);
  var ReturnMin = ReturnTime.substr(2, 2);
  var st = new Date(
    ReturnDate.getFullYear() + '-' + ReturnMonth + '-' + ReturnDay + 'T' + ReturnHour + ':' + ReturnMin + ':00Z'
  );
  st.setMinutes(st.getMinutes() + st.getTimezoneOffset());

  var OfflinePK = new Date().getTime();
  itinData.OfflinePK = OfflinePK;

  let capabilityActionData = {
    success: true,
    associatedData: {
      itineraryId: OfflinePK,
      offline: true,
    },
  };

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    VueBridge.TdgManifestLinkToItinerary(OfflinePK);
    VueBridge.TdgSyncItineraryCreatedOfflineToItinModel(itinData);
  }

  var filed = dateToStringObject(new Date());
  itinData.filed = filed;
  itinData.searchtime = dateToStringObject(st);
  itinData.RA_Data = RAform;
  itinData.status = 'OFFLINE';
  var FD = new FollowingArray(AC_data, itinData);
  itinData.LEGS = FD.WB;
  var data = {
    pilot: $('#Pilots').val(),
    OfflinePK: OfflinePK,
    filed: filed,
    ident: acIdent,
    status: 'OFFLINE',
    searchtime: dateToStringObject(st),
    data: JSON.stringify(itinData),
    PrimaryKey: OfflinePK,
  };
  cirroDB.insert('Itinerary', data, null, function (e) {
    document.getElementById('itinsubmitback').disabled = false;
    $('#exitWBSummaryPage').unbind('click', hrefHandler);
    $('#showWBSummaryPage').unbind('click', hrefHandler);
    $.mobile.changePage($('#ITIN-CONFIRMATION'), {
      reverse: false,
      changeHash: false,
    });
    $('#LongCanvas').html('');
    $('#LatCanvas').html('');
    SYNC_FOLLOW = true;
    ITIN_Files = [];
    //search for aircraft ident in follow_data
    var Push = true;
    for (var i in Follow_Data) {
      var F = Follow_Data[i];
      if (F.AC.ident == acIdent) {
        var GPS = null;
        if (F.GPS != null) {
          GPS = JSON.parse(JSON.stringify(F.GPS));
        }
        Follow_Data[i] = FD;
        if (GPS != null) Follow_Data[i].GPS = GPS;
        Push = false;
        break;
      }
    }
    if (Push) Follow_Data.push(FD);
    updateFollowData(Follow_Data);
    ITIN_InProgress = false;
  });
  return capabilityActionData;
}

function pnpoly(nvert, vertx, verty, testx, testy) {
  var i,
    j,
    c = false;
  for (i = 0, j = nvert - 1; i < nvert; j = i++) {
    if (
      verty[i] > testy != verty[j] > testy &&
      testx < ((vertx[j] - vertx[i]) * (testy - verty[i])) / (verty[j] - verty[i]) + vertx[i]
    )
      c = !c;
  }
  return c;
}

function calculateWB() {
  tident = acIdent;
  var ZFW = WB_data.ZFW;
  var MLW = 0;
  if (WB_data.MLW != undefined) {
    MLW = WB_data.MLW;
  }
  fuelwtArray = [];
  fuelcgArray = [];
  fuelcglArray = [];
  for (var i in FUEL_data) {
    if (FUEL_data[i].Type == FuelType && SN >= parseInt(FUEL_data[i].SN_Low) && SN <= parseInt(FUEL_data[i].SN_High)) {
      var Tank = FUEL_data[i].Tank;
      var Critical = FUEL_data[i].Critical;
      for (a in Tank) {
        fuelwtArray.push(parseFloat(Tank[a].WT));
        fuelcgArray.push(parseFloat(Tank[a].CG));
        fuelcglArray.push(parseFloat(Tank[a].CGL));
      }
    }
  }
  for (var i in TC_data) {
    if (tcNum == TC_data[i].TCNum) {
      for (b in TC_data[i].Optional) {
        var tcItem = TC_data[i].Optional[b];
        if (tcItem.cgRestrict == 1 && tcItem.status == 1) GraphName = tcItem.cgGraph;
      }
    }
  }

  var wbgraph = WB_data.Graph[0];
  var wbset = WB_data;
  var LatGraphType = 'weight';
  for (var i in WB_data.Graph) {
    if (WB_data.Graph[i].Type == GraphName) {
      wbgraph = WB_data.Graph[i];
      LatGraphType = WB_data.Graph[i].LatGraphType;
    }
  }

  x = [];
  y = [];
  var minx = parseFloat(wbgraph.LongGraph[0].x);
  var miny = parseFloat(wbgraph.LongGraph[0].y);
  var maxx = minx;
  var maxy = miny;
  for (var i in wbgraph.LongGraph) {
    var Lx = parseFloat(wbgraph.LongGraph[i].x);
    var Ly = parseFloat(wbgraph.LongGraph[i].y);
    x.push(Lx);
    y.push(Ly);
    if (Lx > maxx) maxx = Lx;
    if (Lx < minx) minx = Lx;
    if (Ly > maxy) maxy = Ly;
    if (Ly < miny) miny = Ly;
  }
  xlabel = [];
  for (var i in wbgraph.xLabel) {
    xlabel.push(wbgraph.xLabel[i].xlabel);
  }
  ylabel = [];
  for (var i in wbgraph.yLabel) {
    ylabel.push(wbgraph.yLabel[i].ylabel);
  }
  x2 = [];
  y2 = [];
  maxLong = 0;
  minLing = 0;
  maxLeft = 0;
  maxRight = 0;
  if (wbgraph.LatGraph != null) {
    var minx2 = parseFloat(wbgraph.LatGraph[0].x);
    var miny2 = parseFloat(wbgraph.LatGraph[0].y);
    var maxx2 = minx2;
    var maxy2 = miny2;
    for (i in wbgraph.LatGraph) {
      var Lx2 = parseFloat(wbgraph.LatGraph[i].x);
      var Ly2 = parseFloat(wbgraph.LatGraph[i].y);
      x2.push(Lx2);
      y2.push(Ly2);
      if (Lx2 > maxx2) maxx2 = Lx2;
      if (Lx2 < minx2) minx2 = Lx2;
      if (Ly2 > maxy2) maxy2 = Ly2;
      if (Ly2 < miny2) miny2 = Ly2;
    }

    maxLong = maxx;
    minLong = minx;
    maxLeft = miny2;
    maxRight = maxy2;
  }
  maxweight = parseFloat(wbgraph.maxweight);
  maxperfweight = maxweight;
  maxExternal = parseFloat(wbgraph.maxExternal);
  if (maxExternal == undefined || maxExternal == 0) maxExternal = maxperfweight;
  if ($('#AC_MAX_PERF').val() == '') {
    if (maxExternal > maxweight) {
      $('#AC_MAX_PERF').val(maxExternal);
      maxperfweight = maxExternal;
    } else $('#AC_MAX_PERF').val(maxweight);
  } else {
    maxperfweight = parseInt($('#AC_MAX_PERF').val());
    if (maxperfweight >= maxExternal) {
      maxperfweight = maxExternal;
      $('#AC_MAX_PERF').val(maxExternal);
    }
  }
  var MaxPerfLine = [];
  MaxPerfLine.push({ x: maxx, y: maxperfweight });
  MaxPerfLine.push({ x: minx, y: maxperfweight });

  var MLWLine = [];
  if (MLW > 0) {
    MLWLine.push({ x: maxx, y: MLW });
    MLWLine.push({ x: minx, y: MLW });
  }

  maxExternal = parseFloat(wbgraph.maxExternal);
  error = '';

  var fLong = 0;
  var fLat = 0;
  var addwt = 0;

  var EXTLong = 0;
  var EXTLat = 0;
  var EXTwt = 0;
  var ExternalCalc = false;
  for (var i in Loading) {
    fLong += parseFloat(Loading[i].LoadLong) * parseInt($('#' + Loading[i].Abr).val());
    fLat += parseFloat(Loading[i].LoadLat) * parseInt($('#' + Loading[i].Abr).val());
    addwt += parseInt($('#' + Loading[i].Abr).val());
  }
  for (var i in optLoad) {
    if (optLoad[i].ExternalLoad != 1) {
      fLat += parseFloat(optLoad[i].LoadLat) * parseInt($('#' + optLoad[i].Abr).val());
      fLong += parseFloat(optLoad[i].LoadLong) * parseInt($('#' + optLoad[i].Abr).val());
      addwt += parseInt($('#' + optLoad[i].Abr).val());
    } else {
      EXTwt += parseInt($('#' + optLoad[i].Abr).val());
      if (EXTwt > 0) {
        EXTLat += parseFloat(optLoad[i].LoadLat) * parseInt($('#' + optLoad[i].Abr).val());
        EXTLong += parseFloat(optLoad[i].LoadLong) * parseInt($('#' + optLoad[i].Abr).val());
        ExternalCalc = true;
      }
    }
  }

  fuelwt = parseInt(document.getElementById('Fuel').value);
  if (fuelwt < minFuel) {
    fuelwt = minFuel;
  }

  FuelRemain = fuelwt;

  addwt += fuelwt;
  for (var i in fuelwtArray) {
    if (fuelwtArray[i] <= fuelwt) {
      fuelLong = fuelcgArray[i];
      fuelLat = fuelcglArray[i];
    }
  }

  //Static Weight and Balance Load Poisitions
  fLong = fLong + fuelLong * fuelwt;
  fLat = fLat + fuelLat * fuelwt;

  //initial takeoff cofg calculation
  facweight = eacweight + addwt;
  zfacweight = facweight - fuelwt;
  fLat = eLat + fLat;
  fLong = eLong + fLong;
  fcofgLat = fLat / facweight;
  fcofgLong = fLong / facweight;
  nofLat = fuelwt * fuelLat;
  nofLong = fuelwt * fuelLong;
  nofLat = fLat - nofLat;
  nofLong = fLong - nofLong;
  nofwt = facweight - fuelwt;
  nofcofg = nofLong / nofwt;
  nofcofgLat = nofLat / nofwt;
  //start graphing calculations
  Mac = nofwt * nofcofg;
  MacL = nofwt * nofcofgLat;
  criticalCG = [];
  criticalLabel = [];
  criticalWT = [];
  criticalLat = [];
  criticalLat.push(nofcofgLat);
  criticalCG.push(nofcofg);
  criticalWT.push(nofwt);
  criticalLabel.push('No Fuel');
  var Latline = null;
  Longline = [
    {
      x: nofcofg,
      y: nofwt,
    },
  ];

  if (LatGraphType == 'weight')
    Latline = [
      {
        x: nofcofgLat,
        y: nofwt,
      },
    ];
  if (LatGraphType == 'cg')
    Latline = [
      {
        x: nofcofgLat,
        y: nofcofg,
        w: nofwt,
      },
    ];

  for (var i in fuelwtArray) {
    if (fuelwtArray[i] <= fuelwt) {
      cg = fuelcgArray[i];
      cgl = fuelcglArray[i];
      Mfuel = cg * fuelwtArray[i];
      MfuelL = cgl * fuelwtArray[i];
      Mtotal = Mfuel + Mac;
      MtotalL = MfuelL + MacL;
      Wtotal = fuelwtArray[i] + nofwt;
      lasti = i;
      Tcofg = Mtotal / Wtotal;
      TcofgL = MtotalL / Wtotal;
      for (a in Critical) {
        if (parseFloat(Critical[a].WT) == fuelwtArray[i]) {
          criticalLat.push(fLat / Wtotal);
          criticalCG.push(Tcofg);
          criticalLabel.push(Critical[a].Label);
          criticalWT.push(Wtotal);
          if (LatGraphType == 'cg')
            Latline.push({
              x: TcofgL,
              y: Tcofg,
              w: Wtotal,
            });
        }
      }
      Longline.push({
        x: Tcofg,
        y: Wtotal,
      });
      if (LatGraphType == 'weight')
        Latline.push({
          x: TcofgL,
          y: Wtotal,
        });
    }
  }
  cg = fuelcgArray[lasti];
  Mfuel = cg * fuelwt;
  Mtotal = Mfuel + Mac;
  Wtotal = fuelwt + nofwt;
  Tcofg = Mtotal / Wtotal;
  TcofgL = MtotalL / Wtotal;
  Longline.push({
    x: Tcofg,
    y: Wtotal,
  });
  if (LatGraphType == 'weight')
    Latline.push({
      x: TcofgL,
      y: Wtotal,
    });
  if (LatGraphType == 'cg')
    Latline.push({
      x: TcofgL,
      y: Tcofg,
      w: Wtotal,
    });
  criticalLat.push(fLat / Wtotal);
  criticalCG.push(Tcofg);
  criticalLabel.push('Takeoff');
  criticalWT.push(Wtotal);

  error =
    "<table class='limit-table wrap'><thead><tr><th>" + iTrans('Weight & Balance Overview') + '</th></tr></thead>';

  for (var i in criticalCG) {
    fcofgLong = criticalCG[i];
    errorLabel = criticalLabel[i];
    facweight = criticalWT[i];
    fcofgLat = criticalLat[i];

    if (pnpoly(x.length, x, y, fcofgLong, facweight) == false) {
      error +=
        '<tr class="yellowbg"><td>' +
        iTrans('WARNING') +
        '! - ' +
        errorLabel +
        ' CofG ' +
        iTrans('BEYOND LIMITS') +
        '</td></tr>';
    }
    if (LatGraphType != 'cg' && wbgraph.LatGraph != null) {
      if (pnpoly(x2.length, x2, y2, facweight, fcofgLat) == false) {
        error +=
          '<tr class="yellowbg"><td>' +
          iTrans('WARNING') +
          '! - ' +
          errorLabel +
          ' CofG ' +
          iTrans('BEYOND LATERAL LIMITS') +
          '</td></tr>';
      }
    } else {
      if (pnpoly(x2.length, x2, y2, fcofgLong, fcofgLat) == false && wbgraph.LatGraph != null) {
        error +=
          '<tr class="yellowbg"><td>' +
          iTrans('WARNING') +
          '! - ' +
          errorLabel +
          ' CofG ' +
          iTrans('BEYOND LATERAL LIMITS') +
          '</td></tr>';
      }
    }
  } // end for loop for criticalCG
  if (facweight > maxweight)
    error +=
      '<tr class="yellowbg"><td>' +
      iTrans('WARNING') +
      '! - ' +
      iTrans('Over maximum internal weight limits') +
      '</td></tr>';
  if (ZFW != null)
    if (zfacweight > ZFW)
      error +=
        '<tr class="yellowbg"><td>' +
        iTrans('WARNING') +
        '! - ' +
        iTrans('Over maximum Zero Fuel Weight') +
        '</td></tr>';
  facweight = Math.round(facweight);
  fcofgLong = Math.round(fcofgLong * 100) / 100;
  fcofgLat = Math.round(fcofgLat * 100) / 100;
  externalMax = maxExternal - facweight;
  UsefulLoad = maxweight - facweight;
  SafetyPCT = Math.round((UsefulLoad / maxweight) * 100);
  goodWB = 0;

  var ELongline = null;
  var ELatline = null;
  var ELongArm = '';
  var ELatArm = '';
  if (EXTwt > 0) {
    ELongline = JSON.parse(JSON.stringify(Longline));
    if (Latline != null) ELatline = JSON.parse(JSON.stringify(Latline));
    for (var e in ELongline) {
      var E = ELongline[e];
      var ELongMoment = E.x * E.y + EXTLong;
      var ELongWeight = EXTwt + E.y;
      E.x = ELongMoment / ELongWeight;
      E.y = ELongWeight;
      ELongArm = E.x;
    }
    if (Latline != null) {
      for (var e in ELatline) {
        var E = ELatline[e];
        if (LatGraphType == 'cg') {
          var ELatMoment = E.x * E.w + EXTLat;
          var ELatWeight = EXTwt + E.w;
          E.x = ELatMoment / ELatWeight;
          if (e == 0) E.y = ELongline[0].x;
          else E.y = ELongline[ELongline.length - 1].x;
          ELatArm = E.x;
        } else {
          var ELatMoment = E.x * E.y + EXTLat;
          var ELatWeight = EXTwt + E.y;
          E.x = ELatMoment / ELatWeight;
          E.y = ELatWeight;
          ELatArm = E.x;
        }
      }
    }
    if (facweight + EXTwt > maxExternal && AC_data.Class == 'Helicopter')
      error +=
        '<tr class="yellowbg"><td>' +
        iTrans('WARNING') +
        '! - ' +
        iTrans('Over maximum external weight limits') +
        '</td></tr>';
    var xLongE = [];
    var yLongE = [];
    var xLatE = [];
    var yLatE = [];
    if (wbgraph.ExternalLongGraph != undefined) {
      for (var i in wbgraph.ExternalLongGraph) {
        xLongE.push(parseFloat(wbgraph.ExternalLongGraph[i].x));
        yLongE.push(parseFloat(wbgraph.ExternalLongGraph[i].y));
      }
    }
    if (wbgraph.ExternalLatGraph != undefined) {
      for (var i in wbgraph.ExternalLatGraph) {
        xLatE.push(parseFloat(wbgraph.ExternalLatGraph[i].x));
        yLatE.push(parseFloat(wbgraph.ExternalLatGraph[i].y));
      }
    }
    var ExternalLongFailed = false;
    var ExternalLatFailed = false;
    for (var i in ELongline) {
      var eLongCofG = ELongline[i].x;
      var eWeightCofG = ELongline[i].y;
      var eLatCofG;
      if (Latline != null) {
        if (ELatline[i] == undefined) eLatCofG = false;
        else eLatCofG = ELatline[i].x;
      } else {
        eLatCofG = false;
      }

      if (
        pnpoly(xLongE.length, xLongE, yLongE, eLongCofG, eWeightCofG) == false &&
        !ExternalLongFailed &&
        wbgraph.ExternalLongGraph != undefined
      ) {
        ExternalLongFailed = true;
        error +=
          '<tr class="yellowbg"><td>' +
          iTrans('WARNING') +
          '! - ' +
          iTrans('External Load CofG BEYOND LIMITS') +
          '</td></tr>';
      }
      if (LatGraphType != 'cg' && wbgraph.LatGraph != null && wbgraph.ExternalLatGraph != undefined) {
        if (
          pnpoly(xLatE.length, xLatE, yLatE, eWeightCofG, eLatCofG) == false &&
          !ExternalLatFailed &&
          eLatCofG !== false
        ) {
          ExternalLatFailed = true;
          error +=
            '<tr class="yellowbg"><td>' +
            iTrans('WARNING') +
            '! - ' +
            iTrans('External Load') +
            ' CofG ' +
            iTrans('BEYOND LATERAL LIMITS') +
            '</td></tr>';
        }
      } else {
        if (
          pnpoly(xLatE.length, xLatE, yLatE, eLongCofG, eLatCofG) == false &&
          wbgraph.LatGraph != null &&
          !ExternalLatFailed &&
          wbgraph.ExternalLatGraph != undefined &&
          eLatCofG !== false
        ) {
          ExternalLatFailed = true;
          error +=
            '<tr class="yellowbg"><td>' +
            iTrans('WARNING') +
            '! - ' +
            iTrans('External Load') +
            ' CofG ' +
            iTrans('BEYOND LATERAL LIMITS') +
            '</td></tr>';
        }
      }
    } // end for loop for criticalCG
  }
  if ($('#WBstations').is(':checked')) {
    $("label[for='Fuel']").html(
      '<font size="-2">Fuel<br />' +
        convertACToUserUnits(fuelwt, 'Weight', AC_UNITS) +
        '<div class="WBSTATION" style="display: block;">' +
        fuelLong +
        ' | ' +
        fuelLat +
        '</div>'
    );
  } else {
    $("label[for='Fuel']").html(
      '<font size="-2">Fuel<br />' +
        convertACToUserUnits(fuelwt, 'Weight', AC_UNITS) +
        '<div class="WBSTATION">' +
        fuelLong +
        ' | ' +
        fuelLat +
        '</div>'
    );
  }
  var WarningTXT = '';
  var WarningTXT2 = '';
  if (
    error ==
    "<table class='limit-table wrap'><thead><tr><th>" + iTrans('Weight & Balance Overview') + '</th></tr></thead>'
  )
    goodWB = 1;
  var totalExternalSummary = '';

  if (EXTwt > 0) {
    totalExternalSummary =
      '<tr><td>' +
      iTrans('Total aircraft weight') +
      ': ' +
      convertACToUserUnits(EXTwt + facweight, 'Weight', AC_UNITS, 0) +
      '</td></tr>';
    var AllUpEXTwt = EXTwt + facweight;
    var EXTSafetyPCT = Math.round(100 - (AllUpEXTwt / maxperfweight) * 100);
    var EXTUsefulLoad = maxperfweight - AllUpEXTwt;
    if (EXTUsefulLoad < 0) {
      var EXTOverBy = parseInt(AllUpEXTwt - maxperfweight);
      if (AC_data.restrict_MaxPerfExt == 1) {
        goodWB = 0;
        WarningTXT =
          iTrans('WARNING') +
          ' -  ' +
          iTrans('External Load') +
          ' ' +
          convertACToUserUnits(EXTOverBy, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('beyond maximum weight for density altitude');
        totalExternalSummary += "<tr class='yellowbg'><td>" + WarningTXT + '.</td></tr>';
      } else {
        WarningTXT =
          iTrans('WARNING') +
          ' -  ' +
          iTrans('External Load') +
          ' ' +
          convertACToUserUnits(EXTOverBy, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('beyond maximum weight for density altitude');
        totalExternalSummary += "<tr class='yellowbg'><td>" + WarningTXT + '.</td></tr>';
      }
    } else {
      totalExternalSummary +=
        '<tr><td>' +
        iTrans('External Load Safety Margin of') +
        ' ' +
        EXTSafetyPCT +
        '% ' +
        iTrans('or') +
        ' ' +
        convertACToUserUnits(EXTUsefulLoad, 'Weight', AC_UNITS, 0) +
        ' ' +
        iTrans('from maximum weight for density altitude') +
        '.</td></tr>';
    }
  }

  if (WANDB == '1') {
    error +=
      '<tr><td>' +
      iTrans('Total aircraft internal weight') +
      ': ' +
      convertACToUserUnits(facweight, 'Weight', AC_UNITS, 2) +
      '</td></tr>' +
      '<tr><td>' +
      iTrans('Maximum Weight for DA') +
      ': ' +
      convertACToUserUnits(maxperfweight, 'Weight', AC_UNITS, 2) +
      '</td></tr>' +
      totalExternalSummary +
      '<tr><td>' +
      iTrans('Longitudinal') +
      ' C of G: ' +
      convertACToUserUnits(fcofgLong, 'Arm', AC_UNITS, 2) +
      '</td></tr><tr><td>' +
      iTrans('Lateral') +
      ' C of G: ' +
      convertACToUserUnits(fcofgLat, 'Arm', AC_UNITS, 2) +
      '</td></tr><tr><td>C of G ' +
      iTrans('Within Limits for all critical fuel burn positions') +
      '.</td></tr>';

    if (AC_data.Class == 'Helicopter')
      error +=
        '<tr><td> + ' +
        iTrans('Maximum External Load') +
        ': ' +
        convertACToUserUnits(externalMax, 'Weight', AC_UNITS, 0) +
        '</td></tr>';

    error +=
      '<tr><td>' +
      iTrans('Internal Load Safety Margin of') +
      ' ' +
      SafetyPCT +
      '% or ' +
      convertACToUserUnits(UsefulLoad, 'Weight', AC_UNITS, 0) +
      ' ' +
      iTrans('from maximum certified allowable') +
      '.</td></tr>';
    if (maxweight > maxperfweight) {
      var SafetyPCT2 = Math.round(100 - (facweight / maxperfweight) * 100);
      var UsefulLoad2 = maxperfweight - facweight;
      if (UsefulLoad2 < 0) {
        var OverBy = parseInt(facweight - maxperfweight);
        if (AC_data.restrict_MaxPerf == 1) {
          goodWB = 0;
          WarningTXT2 =
            iTrans('WARNING') +
            ' -  ' +
            iTrans('Internal Load') +
            ' ' +
            convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
            ' ' +
            iTrans('beyond maximum weight for density altitude');
          error += "<tr class='yellowbg'><td>" + WarningTXT2 + '.</td></tr>';
        } else {
          WarningTXT2 =
            iTrans('WARNING') +
            ' -  ' +
            iTrans('Internal Load') +
            ' ' +
            convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
            ' ' +
            iTrans('beyond maximum weight for density altitude');
          error += "<tr class='yellowbg'><td>" + WarningTXT2 + '.</td></tr>';
        }
      } else {
        error +=
          '<tr><td>' +
          iTrans('Internal Load Safety Margin of') +
          ' ' +
          SafetyPCT2 +
          '% ' +
          iTrans('or') +
          ' ' +
          convertACToUserUnits(UsefulLoad2, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('from maximum weight for density altitude') +
          '.</td></tr>';
      }
    }
    if (tcNum != 'N/A') {
      error +=
        '<tr><td>' +
        iTrans('ENSURE TEMP CONFIG') +
        ' # ' +
        tcNum +
        ' ' +
        iTrans('IS ACTIVE ON AIRCRAFT TECHNICAL DISPATCH CARD') +
        '</td></tr>';
    }
  } else {
    error +=
      '<tr><td>' +
      iTrans('Aircraft takeoff weight') +
      ': ' +
      convertACToUserUnits(facweight, 'Weight', AC_UNITS, 0) +
      ' lbs</td></tr><tr><td>' +
      iTrans('Longitudinal') +
      ' C of G: ' +
      convertACToUserUnits(fcofgLong, 'Arm', AC_UNITS, 0) +
      '</td></tr><tr><td>' +
      iTrans('Lateral') +
      ' C of G: ' +
      convertACToUserUnits(fcofgLat, 'Arm', AC_UNITS, 0) +
      '</td></tr><tr><td>C of G ' +
      iTrans('Within Limits for all critical fuel burn positions') +
      '.</td></tr>';

    if (AC_data.Class == 'Helicopter')
      error +=
        '<tr><td>' +
        iTrans('Maximum External Load') +
        ': ' +
        convertACToUserUnits(externalMax, 'Weight', AC_UNITS, 0) +
        '</td></tr><tr>';

    error +=
      '<td>' +
      iTrans('Internal Load Safety Margin of') +
      ' ' +
      SafetyPCT +
      '% or ' +
      convertACToUserUnits(UsefulLoad, 'Weight', AC_UNITS, 0) +
      ' ' +
      iTrans('from maximum certified allowable') +
      '.</td></tr>';
    if (maxweight > maxperfweight) {
      var SafetyPCT2 = Math.round(100 - (facweight / maxperfweight) * 100);
      var UsefulLoad2 = maxperfweight - facweight;
      if (UsefulLoad2 < 0) {
        var OverBy = parseInt(facweight - maxperfweight);
        error +=
          "<tr class='yellowbg'><td>" +
          iTrans('WARNING') +
          ' -  ' +
          iTrans('Internal Load') +
          ' ' +
          convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('beyond maximum weight for density altitude') +
          '.</td></tr>';
      } else {
        error +=
          '<tr><td>' +
          iTrans('Internal Load Safety Margin of') +
          ' ' +
          SafetyPCT2 +
          '% ' +
          iTrans('or') +
          ' ' +
          convertACToUserUnits(UsefulLoad2, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('from maximum weight for density altitude') +
          '.</td></tr>';
      }
    }
  }
  if (goodWB == 1) {
    document.getElementById('WBNEXT').disabled = false;
    document.getElementById('fileBTN').disabled = false;
    document.getElementById('TDGNEXT').disabled = false;
    document.getElementById('itinsubmit').disabled = false;
    //Update File itinerary Text - W&B Problem!
    if (WarningTXT == '' && WarningTXT2 == '')
      $('#WBERROR').html(
        '<h2>' +
          iTrans('No W&B Problems') +
          '</h2>' +
          convertACToUserUnits(UsefulLoad, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('Remaining')
      );
    else $('#WBERROR').html('<h2>' + iTrans('W&B WARNINGS') + '</h2>' + WarningTXT + '<br />' + WarningTXT2);
    //if (itinNOW == "ITIN-WEIGHTBALANCE") $("#WBNEXT").button(refresh);
  } else {
    error = '<div style="color:red;">' + error + '</div>';
    goodWB = 0;
    if (GROUP_DATA.WB_Warn_Off != 1) document.getElementById('WBNEXT').disabled = true;
    //Update File itinerary Text - W&B Problem!
    $('#WBERROR').html(error + '</table>');
    //if (itinNOW == "ITIN-WEIGHTBALANCE") $("#WBNEXT").button(refresh);
  }
  if (
    $('#itin-flight-description').val().length == 0 &&
    GROUP_DATA.ITIN_hideDetails != 1 &&
    GROUP_DATA.ITIN_showFlightDetails == 1
  ) {
    goodWB = 0;
    document.getElementById('fileBTN').disabled = true;
    $('#itin-flight-description').unbind('keyup', calculateWB).bind('keyup', calculateWB);
    //Update File itinerary Text - W&B Problem!
    //$("#fileBTN").button("refresh");
  } else {
    $('#itin-flight-description').unbind('keyup', calculateWB).bind('keyup', calculateWB);
  }
  if ($('#returnTime').val() == '') {
    document.getElementById('fileBTN').disabled = true;
    //Update File itinerary Text - W&B Problem!
    //$("#fileBTN").button("refresh");
  }

  if (!$('#Pilots').val()) {
    document.getElementById('fileBTN').disabled = true;
  }

  if ($('#TDG_Loader').length && $('#TDG').is(':checked')) {
    if ($('#TDG_Loader').val() == 'null') {
      goodWB = 0;
      document.getElementById('TDGNEXT').disabled = true;
      //Update File itinerary Text - W&B Problem!
    }
  } else {
    document.getElementById('TDGNEXT').disabled = false;
  }
  if ($('#CHK_NOTOC').length) {
    if (!$('#CHK_NOTOC').is(':checked')) {
      goodWB = 0;
      document.getElementById('TDGNEXT').disabled = true;
      //Update File itinerary Text - W&B Problem!
    }
  }
  for (var t in TDG_Manifest) {
    if (TDG_Manifest[t].Exemption == '(12.9)') {
      if ($('ITIN_TDG_Origin').val() == '' || $('#ITIN_TDG_Dest').val() == '' || TDGsignatureDATA.data == '') {
        goodWB = 0;
        document.getElementById('TDGNEXT').disabled = true;
      }
      break;
    }
  }
  //if (GROUP_DATA.WB_Warn_Off == 1) WANDB = 0;

  let rt = $('#returnTime').val() || '';
  let desc = $('#itin-flight-description').val() || '';
  rt.trim();
  desc.trim();
  if (rt.length > 0 && desc.length > 0 && (WANDB < 1 || GROUP_DATA.WB_Warn_Off > 0)) {
    document.getElementById('fileBTN').disabled = false;
  }

  $('#WB_summary').html('');
  var Summary = '';
  Summary +=
    '<h4>' +
    acIdent +
    ' ' +
    iTrans('Amendment') +
    ' #' +
    TC_data[tcIndex].ANum +
    '<br />' +
    iTrans('Dated') +
    ': ' +
    TC_data[tcIndex].Date +
    '<br />' +
    iTrans('Temp Configuration') +
    ' # ' +
    tcNum +
    '<br />' +
    iTrans('Detailed Loading Info') +
    ':<br /></h4>';
  Summary +=
    '<div style="width: 100%; overflow: auto"><table class="last-td-fill">' +
    '<thead>' +
    '<tr>' +
    '<th data-priority="0">' +
    iTrans('Position') +
    '</th>' +
    '<th data-priority="1">' +
    iTrans('Weight') +
    ' (' +
    WBUnitLbl.Weight +
    ')</th>' +
    '<th data-priority="2">Long (' +
    WBUnitLbl.Arm +
    ')</th>' +
    '<th data-priority="persist">Lat (' +
    WBUnitLbl.Arm +
    ')</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';
  Summary +=
    '<tr><td>' +
    iTrans('Aircraft') +
    "</td><td style='text-align: right;'>" +
    convertACToUserUnits(eacweight, 'Weight', AC_UNITS, 2) +
    '</td><td>' +
    convertACToUserUnits(ecofgLong, 'Arm', AC_UNITS, 2) +
    '</td><td>' +
    convertACToUserUnits(ecofgLat, 'Arm', AC_UNITS, 2) +
    '</td></tr>';
  Summary +=
    '<tr><td>' +
    iTrans('Fuel') +
    "</td><td style='text-align: right;'>" +
    convertACToUserUnits(fuelwt, 'Weight', AC_UNITS, 2) +
    '</td><td>' +
    convertACToUserUnits(fuelLong, 'Arm', AC_UNITS, 2) +
    '</td><td>' +
    convertACToUserUnits(fuelLat, 'Arm', AC_UNITS, 2) +
    '</td></tr>';
  for (i in Loading) {
    Summary +=
      '<tr><td>' +
      Loading[i].Abr +
      '</td><td style="text-align: right;">' +
      convertACToUserUnits($('#' + Loading[i].Abr).val(), 'Weight', AC_UNITS, 2) +
      '</td><td>' +
      convertACToUserUnits(Loading[i].LoadLong, 'Arm', AC_UNITS, 2) +
      '</td><td>' +
      convertACToUserUnits(Loading[i].LoadLat, 'Arm', AC_UNITS, 2) +
      '</td></tr>';
  }
  for (i in optLoad) {
    if (optLoad[i].ExternalLoad != 1)
      Summary +=
        '<tr><td>' +
        optLoad[i].Abr +
        '</td><td style="text-align: right;">' +
        convertACToUserUnits($('#' + optLoad[i].Abr).val(), 'Weight', AC_UNITS, 2) +
        '</td><td>' +
        optLoad[i].LoadLong +
        '</td><td>' +
        optLoad[i].LoadLat +
        '</td></tr>';
    else
      ExternalSummary =
        '<tr><td>' +
        optLoad[i].Abr +
        '</td><td style="text-align: right;">' +
        $('#' + optLoad[i].Abr).val() +
        '</td><td>' +
        convertACToUserUnits(optLoad[i].LoadLong, 'Arm', AC_UNITS, 2) +
        '</td><td>' +
        convertACToUserUnits(optLoad[i].LoadLat, 'Arm', AC_UNITS, 2) +
        '</td></tr>';
  }
  Summary +=
    '<tr><td><h4>' +
    iTrans('Total Internal') +
    "</h4></td><td style='text-align: right;'><h4>" +
    convertACToUserUnits(facweight, 'Weight', AC_UNITS, 2) +
    '</h4></td><td><h4>' +
    convertACToUserUnits(fcofgLong, 'Arm', AC_UNITS, 2) +
    '</h4></td><td><h4>' +
    convertACToUserUnits(fcofgLat, 'Arm', AC_UNITS, 2) +
    '</h4></td></tr></tbody></table>';
  if (EXTwt > 0) {
    Summary += '<tr><td><h3>' + iTrans('External Load Items') + '</h3></td><td></td><td></td><td></td>';
    Summary += ExternalSummary;
    ELongArm = Math.round(ELongArm * 10) / 10;
    ELatArm = Math.round(ELatArm * 10) / 10;
    Summary +=
      '<tr><td><h4>' +
      iTrans('Total All Up Weight') +
      '</h4></td><td><h4>' +
      convertACToUserUnits(EXTwt + facweight, 'Weight', AC_UNITS, 2) +
      "</h4></td><td style='text-align: right;'><h4>" +
      convertACToUserUnits(ELongArm, 'Arm', AC_UNITS, 2) +
      '</h4></td><td><h4>' +
      convertACToUserUnits(ELatArm, 'Arm', AC_UNITS, 2) +
      '</h4></td></tr></tbody></table>';
  }
  $('#WB_summary').html(Summary);
  $('#wbReport').html(error + '</table>');

  var longgraphArray = [];
  for (i in wbgraph.eLongGraph) {
    longgraphArray.push(wbgraph.eLongGraph[i].Graph);
    for (b in wbgraph.eLongGraph[i].Graph) {
      var elonX = parseFloat(wbgraph.eLongGraph[i].Graph[b].x);
      var elonY = parseFloat(wbgraph.eLongGraph[i].Graph[b].y);
      if (elonX > maxx) maxx = elonX;
      if (elonX < minx) minx = elonX;
      if (elonY > maxy) maxy = elonY;
      if (elonY < miny) miny = elonY;
    }
  }
  var latgraphArray = [];
  for (i in wbgraph.eLatGraph) {
    latgraphArray.push(wbgraph.eLatGraph[i].Graph);
    for (b in wbgraph.eLatGraph[i].Graph) {
      var elatX = parseFloat(wbgraph.eLatGraph[i].Graph[b].x);
      var elatY = parseFloat(wbgraph.eLatGraph[i].Graph[b].y);
      if (elatX > maxx2) maxx2 = elatX;
      if (elatX < minx2) minx2 = elatX;
      if (elatY > maxy2) maxy2 = elatY;
      if (elatY < miny2) miny2 = elatY;
    }
  }
  var xLabel = [];
  for (i in wbgraph.xLabel) {
    xLabel.push(wbgraph.xLabel[i].xlabel);
  }
  var yLabel = [];
  for (i in wbgraph.yLabel) {
    yLabel.push(wbgraph.yLabel[i].ylabel);
  }
  var xLabel2 = [];
  for (i in wbgraph.xLabel2) {
    xLabel2.push(wbgraph.xLabel2[i].xlabel2);
  }
  var yLabel2 = [];
  for (i in wbgraph.yLabel2) {
    yLabel2.push(wbgraph.yLabel2[i].ylabel2);
  }
  $('#LongCanvas').html('');
  drawGraph(
    '#LongCanvas',
    wbgraph.LongGraph,
    longgraphArray,
    Longline,
    ELongline,
    [minx, maxx],
    [miny, maxy],
    150,
    200,
    'Long',
    xlabel,
    yLabel,
    MaxPerfLine,
    MLWLine
  );

  var MLWLine2 = [];
  $('#LatCanvas').html('');
  if (wbgraph.LatGraph != null)
    drawGraph(
      '#LatCanvas',
      wbgraph.LatGraph,
      latgraphArray,
      Latline,
      ELatline,
      [miny2, maxy2],
      [minx2, maxx2],
      150,
      200,
      'Lat',
      yLabel2,
      xLabel2,
      null,
      MLWLine2
    );
}

function drawGraph(
  divID,
  poly,
  externalpoly,
  line,
  line2,
  xdomain,
  ydomain,
  width,
  height,
  type,
  xLabel,
  yLabel,
  MaxPerfLine,
  MLWLine
) {
  function formatTick(d) {
    return parseFloat(d);
  }
  var vis = d3.select(divID).append('svg').attr('width', width).attr('height', height),
    scaleX = d3.scale
      .linear()
      .domain(xdomain)
      .range([5, width - 50]),
    scaleY = d3.scale
      .linear()
      .domain(ydomain)
      .range([height - 25, 10]);

  var yAxis = d3.svg.axis().scale(scaleY).orient('right').tickValues(yLabel).tickFormat(formatTick);
  var xAxis = d3.svg.axis().scale(scaleX).orient('bottom').tickValues(xLabel).tickFormat(formatTick);

  var polies = {
    Polygons: [],
  };
  for (i in externalpoly) {
    polies.Polygons.push({
      name: i,
      points: externalpoly[i],
      color: '#FF5555',
    });
  }
  polies.Polygons.push({
    name: 'Limits',
    points: poly,
    color: '#999999',
  });

  vis
    .selectAll('polygon')
    .data(polies.Polygons)
    .enter()
    .append('polygon')
    .attr('points', function (d) {
      if (type == 'Long')
        return d.points
          .map(function (d) {
            return [scaleX(d.x), scaleY(d.y)].join(',');
          })
          .join(' ');
      if (type == 'Lat')
        return d.points
          .map(function (d) {
            return [scaleX(d.y), scaleY(d.x)].join(',');
          })
          .join(' ');
    })
    .attr('stroke', 'black')
    .attr('fill', function (d) {
      return d.color;
    })
    .attr('fill-opacity', 0.8)
    .attr('stroke-width', 1);

  var lineFunction = d3.svg
    .line()
    .x(function (d) {
      return scaleX(d.x);
    })
    .y(function (d) {
      return scaleY(d.y);
    })
    .interpolate('linear');

  var lineGraph = vis
    .append('path')
    .attr('d', lineFunction(line))
    .attr('stroke', '#00FFFF')
    .attr('stroke-width', 1.5)
    .attr('fill', 'none');
  if (line2 != null) {
    var lineGraph2 = vis
      .append('path')
      .attr('d', lineFunction(line2))
      .attr('stroke', '#FFFF00')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none');
  }

  if (MaxPerfLine != null) {
    var lineGraph2 = vis
      .append('path')
      .attr('d', lineFunction(MaxPerfLine))
      .attr('stroke', '#FF0000')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none');
  }
  if (MLWLine.length > 0) {
    var lineGraph2 = vis
      .append('path')
      .attr('d', lineFunction(MLWLine))
      .attr('stroke', '#FF00FF')
      .attr('stroke-width', 1.5)
      .attr('fill', 'none');
  }
  vis
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + (height - 23) + ')')
    .call(xAxis);

  vis
    .append('g')
    .attr('class', 'y axis')
    .attr('transform', 'translate(' + (width - 45) + ',0)')
    .call(yAxis)
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 1)
    .attr('dy', '.71em')
    .style('text-anchor', 'end')
    .text('');
}
var ASSET_ADDED = [];
var ASSET_INDEX = -1;
function StoreCheckedAssets() {
  if (ASSET_INDEX == -1) return;
  var AssetStatus = $('input[type="checkbox"]')
    .filter('.ItinAssets')
    .map(function () {
      var name = $(this).attr('name');
      if ($(this).is(':checked'))
        return {
          name: name,
          status: true,
        };
      else
        return {
          name: name,
          status: false,
        };
    })
    .get();
  for (i in AssetStatus) {
    if (AssetStatus[i].status) {
      ItinAssetTemplates[ASSET_INDEX].Assets[i].Status = $('#Asset_Status-' + i).val();
      ItinAssetTemplates[ASSET_INDEX].Assets[i].Lat = UserDevice.Lat;
      ItinAssetTemplates[ASSET_INDEX].Assets[i].Long = UserDevice.Long;
      var duplicate = false;
      for (var s in ASSET_ADDED) {
        if (ASSET_ADDED[s].PrimaryKey == ItinAssetTemplates[ASSET_INDEX].Assets[i].PrimaryKey) duplicate = true;
      }
      if (!duplicate) {
        ItinAssetTemplates[ASSET_INDEX].Assets[i].TemplateName = ItinAssetTemplates[ASSET_INDEX].Name;
        ASSET_ADDED.push(ItinAssetTemplates[ASSET_INDEX].Assets[i]);
      }
    } else {
      for (var s in ASSET_ADDED) {
        if (ASSET_ADDED[s].PrimaryKey == ItinAssetTemplates[ASSET_INDEX].Assets[i].PrimaryKey) {
          //remove Item from ASSET_ADDED
          ASSET_ADDED.splice(s, 1);
        }
      }
    }
  }
}

function change_Itin_Assets() {
  StoreCheckedAssets();

  $('#Itin_Assets').html('');
  var Index = $('#select_Itin_Assets').val();
  ASSET_INDEX = Index;
  if (ASSET_INDEX == null) ASSET_INDEX = -1;

  if (Index != -1) {
    var AssetsHTML = '';
    var iAssets = ItinAssetTemplates[Index].Assets;
    if (iAssets.length > 0) {
      AssetsHTML =
        '<div style="width: 100%; overflow: auto"><table id="ItinAssetTemplate_Table" class="last-td-fill">' +
        '<thead>' +
        '<tr>' +
        '<th data-priority="0">' +
        "<input type='checkbox' data-role='none' style='width:25px; height:25px;' className='ItinAssets' id='itinAsset-SelectAll' onClick='itinAssetSelectAll(this)'/>" +
        '</th>' +
        '<th data-priority="0">' +
        iTrans('Category') +
        '</th>' +
        '<th data-priority="0">' +
        iTrans('Name') +
        '</th>' +
        '<th data-priority="0">SN</th>' +
        '<th data-priority="0">' +
        iTrans('Status') +
        '</th>' +
        '<th data-priority="0">' +
        iTrans('Info') +
        '</th>' +
        '<th data-priority="2">' +
        iTrans('Updated') +
        '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';
      for (i in iAssets) {
        var Item = iAssets[i];
        var AssetStatus =
          "<select id='Asset_Status-" +
          i +
          "' data-mini='true'><option disabled='disabled' selected='selected' value='null'>" +
          iTrans('Unknown') +
          "</option><option value='Defective'>" +
          iTrans('Defective') +
          "</option><option value='Inspected'>" +
          iTrans('Inspected - FUL') +
          "L</option><option value='InspectedPreFlight'>" +
          iTrans('Inspected Pre-Flight') +
          "</option><option value='Pending'>" +
          iTrans('Pending Inspection') +
          "</option><option value='Missing'>" +
          iTrans('Missing') +
          '</option></select>';
        var Updated = getDaysDiff(Item.Datetime);
        Updated += ' ' + iTrans('Days Ago');
        var Info = '';
        var Class = '';
        switch (Item.Status) {
          case 'Defective':
            Class = 'redRecord';
            break;
          case 'Missing':
            Class = 'orangeRecord';
            break;
          case 'Pending':
            Class = 'yellowRecord';
            break;
        }
        switch (Item.Status2) {
          case 'Expired':
            Class = 'redRecord';
            Info += '<p>[' + iTrans('EXPIRED') + ']</p>';
            break;
          case 'Inspection Due':
            Class = 'yellowRecord';
            Info += '<p>[' + iTrans('INSPECTION REQUIRED') + ']</p>';
            break;
        }
        if (Item.BeingUsed == 1) {
          Class = 'greyRecord';
          if (ONLINE) Info += '<p>[' + iTrans('IN USE') + ']</p>';
          else Info += '<p>[' + iTrans('IN USE (When last online)') + ']</p>';
        }
        var lineBG2 = '';
        var disableCHK = '';
        switch (Item.Status_Cycles) {
          case 'Approaching Cycle Limit':
            Info +=
              '<p>' +
              iTrans('Approaching Cycle Limit') +
              ' ' +
              Math.round(Item.cycle_Limit - Item.curCycles) +
              ' ' +
              iTrans('Cycles Remaining') +
              '</p>';
            if (Class != 'redRecord') Class = 'yellowRecord';
            break;
          case 'Cycle Limit Reached':
            Info += '<p>' + iTrans('Cycle Limit Reached') + '</p>';
            Class = 'redRecord';
            disableCHK = 'disabled';
            break;
        }
        switch (Item.Status_Usage) {
          case 'Approaching Hour Limit':
            Info +=
              '<p>' +
              iTrans('Approaching Hour Limit') +
              ' ' +
              round10(Item.Usage_Limit - Item.curUsage) +
              ' ' +
              iTrans('Hours Remaining') +
              '</p>';
            if (Class != 'redRecord') Class = 'yellowRecord';
            break;
          case 'Hour Limit Reached':
            Info += '<p>' + iTrans('Hour Limit Reached') + '</p>';
            Class = 'redRecord';
            disableCHK = 'disabled';
            break;
        }
        var Checked = '';
        for (var s in ASSET_ADDED) {
          if (ASSET_ADDED[s].PrimaryKey == Item.PrimaryKey) Checked = 'checked';
        }
        AssetsHTML +=
          "<tr class='" +
          Class +
          "'><td><input type='checkbox' data-ajax='false' class='ItinAssets' id='itinAsset-" +
          i +
          "' name='" +
          i +
          "' " +
          Checked +
          ' ' +
          disableCHK +
          '/></td><td>' +
          Item.Category +
          '</td><td>' +
          Item.Name +
          '</td><td>' +
          Item.SN +
          '</td><td>' +
          AssetStatus +
          '</td><td>' +
          Info +
          '</td><td>' +
          Updated +
          '</td></tr>';
      }
      AssetsHTML += '</tbody></table></div>';
      //Add checkbox listeners
      $('#Itin_Assets').html(AssetsHTML);
      $('#Itin_Assets').enhanceWithin();
      for (i in iAssets) {
        if (iAssets[i].Status != null)
          $('#Asset_Status-' + i)
            .val(iAssets[i].Status)
            .selectmenu('refresh');
        if (
          iAssets[i].Status2 == 'Expired' ||
          iAssets[i].Status == 'Defective' ||
          (iAssets[i].BeingUsed == 1 && ONLINE)
        ) {
          $('#Asset_Status-' + i)
            .selectmenu('disable')
            .selectmenu('refresh');
          $('#itinAsset-' + i).prop('disabled', true);
        }
      }
    } else {
      AssetsHTML = '<h3>' + iTrans('No Assets In Selected Template') + '</h3>';
      $('#Itin_Assets').html(AssetsHTML);
      $('#Itin_Assets').enhanceWithin();
    }
  }
  var SelectedAssetsHTML = '';
  if (ASSET_ADDED.length > 0) {
    SelectedAssetsHTML += '<br /><h3>' + iTrans('Previously Added Items') + '</h3>';
    SelectedAssetsHTML +=
      '<div style="width: 100%; overflow: auto"><table class="last-td-fill">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Category') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Name') +
      '</th>' +
      '<th data-priority="0">SN</th>' +
      '<th data-priority="0">' +
      iTrans('Status') +
      '</th>' +
      '<th>Template</th>' +
      '<th data-priority="2">' +
      iTrans('Updated') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    for (var a in ASSET_ADDED) {
      var A = ASSET_ADDED[a];
      var Updated2 = getDaysDiff(A.Datetime);
      Updated2 += ' ' + iTrans('Days Ago');
      SelectedAssetsHTML +=
        '<tr><td>' +
        A.Category +
        '</td><td>' +
        A.Name +
        '</td><td>' +
        A.SN +
        '</td><td>' +
        A.Status +
        '</td><td>' +
        A.TemplateName +
        '</td><td>' +
        Updated2 +
        '</td></tr>';
    }
    SelectedAssetsHTML += '</tbody></table>';
  }
  $('#Itin_Assets2').html(SelectedAssetsHTML);
  $('#Itin_Assets2').enhanceWithin();
}

function itinAssetSelectAll(e) {
  var iAssets = ItinAssetTemplates[ASSET_INDEX].Assets;
  for (var i in iAssets) {
    var I = iAssets[i];
    $('#itinAsset-' + i).prop('checked', e.checked);
  }
}

function saveFlightTemplate() {
  var html = "<label for='itinName'>" + iTrans('Name') + "</label><input type='text' id='itinName' />";
  html +=
    "<label for='identOnly'>" +
    iTrans('For This Aircraft Ident Only') +
    "</label><input type='checkbox' id='identOnly' />";

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Save Flight Template'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Save/Update')]: {
        click: function () {
          document.getElementById('saveFlightTemplate').disabled = true; //update for save template btn
          sendFlightTemplate();
          //Update File Now Text - W&B Problem
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          document.getElementById('saveFlightTemplate').disabled = false;
        },
      },
    },
  });
}

function sendFlightTemplate() {
  var name = $('#itinName').val();
  var identOnly = $('#identOnly').is(':checked');
  if (identOnly == false) identOnly = 'false';
  else identOnly = 'true';
  var RoutePlan, SurvivalKit, LifeRaft, LifeJackets, VIP, Details, ReturnDate, ReturnTime, Pilot;

  let TDGRevamp = 0;
  let TDG = 0;

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    if ($('#TDG-REVAMP').is(':checked')) TDGRevamp = 1;
  } else {
    if ($('#TDG').is(':checked')) TDG = 1;
  }

  if ($('#RoutePlanCHK').is(':checked')) RoutePlan = 1;
  else RoutePlan = 0;
  if ($('#survival').is(':checked')) SurvivalKit = 1;
  else SurvivalKit = 0;
  if ($('#liferaft').is(':checked')) LifeRaft = 1;
  else LifeRaft = 0;

  if ($('#lifejackets').is(':checked')) LifeJackets = 1;
  else LifeJackets = 0;

  if ($('#Itin_IsVIP').is(':checked')) VIP = 1;
  else VIP = 0;

  Details = $('#itin-flight-description').val();
  Pilot = $('#Pilots').val();
  var PilotWeight = convertToUserUNITS(parseInt($('#Pilot').val()), 'Weight', AC_UNITS, 'Imperial');
  LOCALSTORAGE.PilotWeight = PilotWeight;
  localStorageDB.setItem('PilotWeight', PilotWeight);
  var JobID = $('#ITIN_JOB').val();
  var WB = WANDB; //make edits to allow for optional weight and balance for filing itinerary
  var otherwt = 0;
  var ESN = AC_data.esn;
  //var otherwt = attachedwt; //add array of passenger weights and baggage weights and attached weights
  var wbDate = TC_data[tcIndex].Date; //get wbDate from weight and balance stuff
  var Ehmom = TC_data[tcIndex].TCHorzMom;
  var Elmom = TC_data[tcIndex].TCLatMom;
  var TCdesc = tcDesc;
  var WBSummary = '';
  for (var i in Loading) {
    if (Loading[i].Variable != undefined) {
      WBSummary +=
        Loading[i].Abr +
        ':' +
        $('#' + Loading[i].Abr).val() +
        ':' +
        Loading[i].LoadLong +
        ':' +
        Loading[i].LoadLat +
        ',';
    } else {
      if (Loading[i].VariableArms == 1) {
        WBSummary +=
          Loading[i].Abr +
          ':' +
          $('#' + Loading[i].Abr).val() +
          ':' +
          Loading[i].LoadLong +
          ':' +
          Loading[i].LoadLat +
          ',';
      } else {
        WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr).val() + ',';
      }
    }
    if (!isNaN(parseFloat($('#' + Loading[i].Abr).val()))) {
      otherwt += parseFloat($('#' + Loading[i].Abr).val());
    }
  }
  for (var i in optLoad) {
    if (optLoad[i].VariableArms == 1) {
      WBSummary +=
        optLoad[i].Abr +
        ':' +
        $('#' + optLoad[i].Abr).val() +
        ':' +
        optLoad[i].LoadLong +
        ':' +
        optLoad[i].LoadLat +
        ',';
    } else {
      WBSummary += optLoad[i].Abr + ':' + $('#' + optLoad[i].Abr).val() + ',';
    }
    if (!isNaN(parseFloat($('#' + optLoad[i].Abr).val()))) {
      otherwt += parseFloat($('#' + optLoad[i].Abr).val());
    }
  }
  var TDG_Details = '';
  var TDG_Data = '';
  if (TDG == 1) {
    TDG_Details = 'unknown';
    if ($('#TDG_Loader').length) {
      TDG_Details = $('#TDG_Loader').val();
    } else {
      TDG_Details = 'Pilot';
    }
    TDG_Data = TDG_Manifest;
  }

  var Index = $('#select_Itin_Assets').val();
  var AssetData = [];
  var AssetDetails = '';
  var ASSET = 0;
  if (Index != -1) {
    //Asset Tracking is on
    ASSET = 1;
    AssetDetails = $('#select_Itin_Assets option:selected').text();
    var AssetStatus = $('input[type="checkbox"]')
      .filter('.ItinAssets')
      .map(function () {
        var name = $(this).attr('name');
        if ($(this).is(':checked'))
          return {
            name: name,
            status: true,
          };
        else
          return {
            name: name,
            status: false,
          };
      })
      .get();
    for (i in AssetStatus) {
      if (AssetStatus[i].status) {
        ItinAssetTemplates[Index].Assets[i].Status = $('#Asset_Status-' + i).val();
        ItinAssetTemplates[Index].Assets[i].Lat = UserDevice.Lat;
        ItinAssetTemplates[Index].Assets[i].Long = UserDevice.Long;
        AssetData.push(ItinAssetTemplates[Index].Assets[i]);
      }
    }
  }
  if (FuelPlans.length > 0) FP = JSON.stringify(FuelPlans);
  else FP = 'None';
  var MLobslider = LOCALSTORAGE.MLobslider;
  if (MLobslider == undefined) MLobslider = 10;
  var Client = $('#itinClients').val();
  var PerReportFields = [];
  var CF = ITIN_Cfields;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != acIdent && I.Aircraft != '') {
        continue;
      }
      if (checkCfieldType('FR', I)) {
        PerReportFields.push(I);
        continue;
      }
      if (I.PerLeg == 0) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
      }
      PerReportFields.push(I);
    }
  }
  var Cfields = JSON.stringify(PerReportFields);
  var Pax_Manifest = null;
  if (GROUP_DATA.Pax_Man == 1) {
    if (ITIN_Pax_Manifest.DefaultList != undefined) {
      var hasVIP = 0;
      for (var p in ITIN_Pax_Manifest.DefaultList) {
        var P = ITIN_Pax_Manifest.DefaultList[p];
        if (P.VIP == 1) hasVIP = 1;
      }
      var ObjLegs = {};
      for (var l in ITIN_Pax_Manifest.Pax_Legs) {
        for (var p in ITIN_Pax_Manifest.Pax_Legs[l]) {
          if (ITIN_Pax_Manifest.Pax_Legs[l][p].PersonalUse == undefined) {
            ITIN_Pax_Manifest.Pax_Legs[l][p].PersonalUse = 0;
          }
        }
        ObjLegs[l] = ITIN_Pax_Manifest.Pax_Legs[l];
      }
      if (ITIN_BookingPK == 0) {
        Pax_Manifest = {
          DefaultList: JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList)),
          Pax_Legs: ObjLegs,
          HasVIP: hasVIP,
        };
      } else {
        Pax_Manifest = {
          DefaultList: JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList)),
          Pax_Legs: ObjLegs,
          HasVIP: hasVIP,
        };
      }
    }
  }
  Itinerary = {
    Cfields: Cfields,
    FC_Uplifts: '{}',
    File: '',
    Files: [],
    Filename: '',
    pilot: Pilot,
    paxnum: paxnum,
    survivalkit: SurvivalKit,
    liferaft: LifeRaft,
    lifejackets: LifeJackets,
    VIP: VIP,
    TDG: TDG,
    Details: Details,
    RoutePlan: RoutePlan,
    WB: WANDB,
    Route: RoutePlans,
    acwt: facweight,
    fuelwt: fuelwt,
    otherwt: otherwt,
    cofglat: fcofgLat,
    cofglong: fcofgLong,
    tempconfig: tcNum,
    wbDate: wbDate,
    Eweight: eacweight,
    maxperfweight: maxperfweight,
    Ehmom: Ehmom,
    Elmom: Elmom,
    TCdesc: TCdesc,
    Summary: WBSummary,
    TDGdetails: TDG_Details,
    TDGdata: TDG_Data,
    ASSET: ASSET,
    AssetDetails: AssetDetails,
    AssetData: AssetData,
    Pax_Manifest: Pax_Manifest,
    FP: FP,
    ClientPK: Client,
    NM: MLobslider,
    JobID: JobID,
    SCH_DATA: SCH_DATA,
    TDGRevamp: TDGRevamp,
  };
  Itinerary = JSON.stringify(Itinerary);

  $.ajax({
    timeout: 240000,
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Itinerary-AJAX.php?action=saveTemplate',
    data: {
      name: name,
      identOnly: identOnly,
      acType: acType,
      acIdent: acIdent,
      Itinerary: Itinerary,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'fileItinerary()');
        document.getElementById('saveFlightTemplate').disabled = false; //update for save template btn
      }
      if (data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Flight Template was Saved Successfully.'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                document.getElementById('saveFlightTemplate').disabled = false; //update for save template btn
                ITIN_InProgress = false;
              },
            },
          },
        });
      }
      if (data[0].ServerResponse == 'Updated') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Flight Template was Updated Successfully.'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                document.getElementById('saveFlightTemplate').disabled = false; //update for save template btn
              },
            },
          },
        });
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt:
            iTrans('There was a problem saving your template') +
            '.  ' +
            iTrans('Please try again') +
            '. ' +
            iTrans('If this continues to happen please submit a bug report') +
            '.',
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                document.getElementById('saveFlightTemplate').disabledd = false; //update for save template btn
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText

      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt:
          iTrans('There was a problem saving your template') +
          '.  ' +
          iTrans('Please try again') +
          '. ' +
          iTrans('If this continues to happen please submit a bug report') +
          '.',
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              document.getElementById('saveFlightTemplate').disabledd = false; //update for save template btn
            },
          },
        },
      });
    });
}

function getFlightTemplates(ident = false) {
  ASSET_ADDED = [];
  $('#Itin_Assets2').html('');
  if (this.value != undefined) acIdent = this.value;
  else acIdent = ident;
  PLANAC = acIdent;
  itin_UpdateHeadingIdent(acIdent);
  AC_data = '';
  TC_data = '';
  WB_data = '';
  PILOT_data = [];
  LOADABLE_data = '';
  FUEL_data = '';
  eacweight = '';
  maxperfweight = '';
  eLat = '';
  eLong = '';
  ecofgLat = '';
  ecofgLong = '';
  error = '';
  searchtime = '';
  FuelType = 'Standard';
  FuelStandard = 'Standard';
  SeatType = 'Standard';
  SeatStandard = 'Standard';
  GraphName = 'Standard';
  GraphStandard = 'Standard';
  Loading = [];
  optLoad = [];
  tcNum = '';
  tcIndex = '';
  PaXnum = 0;
  SN = '';

  $('#WB_OPT').html('');
  $('#TCNUM').html('');
  $('#LongCanvas').html('');
  $('#LatCanvas').html('');
  $('#Seating').html('');
  $('#fuelLoad').html('');
  $('#bagLoad').html('');
  $('#optLoad').html('');
  $('#Pilots').empty();
  $('#ITIN_ResponsiblePerson').empty();
  $('#ITIN_ResponsiblePerson').append($('<option />').val('0').text(iTrans('System Tier 1 Users')));
  $('#PaX').html('');
  $('#Itin_Assets').html('');
  $('#AC_MAX_PERF').val('');

  cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
    var data = e[0];
    TC_data = data.TC_data;
    WB_data = data.WB_Data;
    LOADABLE_data = data.LOADABLE_data;
    AC_data = data.AC_data;
    if (AC_data.Units != undefined) {
      AC_UNITS = AC_data.Units;
    }
    $('#WB_EQHTML').html('<b>' + iTrans('Aircraft Temp Config Rebuild Required') + '!</b>');
    if (AC_data.EQ_HTML != undefined) $('#WB_EQHTML').html(AC_data.EQ_HTML);
    acType = AC_data.AcType;

    PilotsTable.query()
      .select()
      .then(function (e) {
        if (e === false) PILOT_data = [];
        else PILOT_data = e;
        if (GROUP_DATA.Trackers == undefined) GROUP_DATA.Trackers = [];
        if (AC_data.Dynamic_Tracker == 1 && GROUP_DATA.Trackers.length > 0 && !SCHEDULER_OVERRIDE) {
          var TrackerHTML = '<select id="AC_TRACKER">';
          for (var i in GROUP_DATA.Trackers) {
            var T = GROUP_DATA.Trackers[i];
            TrackerHTML += '<option value="' + T.esn + '">' + escapeHtml(T.Name) + '</option>';
          }
          TrackerHTML += '</select>';
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Select Tracker Unit'),
            headerClose: false,
            buttonPrompt: TrackerHTML,
            buttons: {
              [iTrans('OK')]: {
                click: function () {
                  ContinueTemplateLoad($('#AC_TRACKER').val());
                },
              },
            },
            callbackOpen: function () {
              if ($("#AC_TRACKER option[value='" + AC_data.esn + "']").length == 0) {
                $('#AC_TRACKER').append(new Option('Existing ESN', AC_data.esn));
              }
              $('#AC_TRACKER').val(AC_data.esn).selectmenu('refresh');
            },
          });
        } else {
          ContinueTemplateLoad(AC_data.esn);
        }
      });
  });
}
function ContinueTemplateLoad(esn) {
  AC_data.esn = esn;
  retrieveTemplate();
}
var ItineraryDATA = false;

function retrieveTemplate() {
  PLANAC = acIdent;
  $.getJSON(
    BASEURL +
      'menu-Itinerary-AJAX.php?action=getTemplate&acIdent=' +
      encodeURIComponent(acIdent) +
      '&acType=' +
      encodeURIComponent(acType),
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'retrieveTemplate()');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Offline') {
          $.mobile.changePage($('#MainMenu'), {
            reverse: true,
            changeHash: false,
          });
        }
        if (json_data[0].ServerResponse == 'None') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('No Templates'),
            headerClose: false,
            buttonPrompt: iTrans('There are no templates available for this aircraft type or ident.'),
            buttons: {
              [iTrans('Close')]: {
                click: function () {},
              },
            },
          });
        }
      } else {
        var templates = json_data;
        var html = "<select id='importTemplate'>";

        for (i in templates) {
          html += "<option value='" + i + "'>" + templates[i].name + '</option>';
        }
        html += '</select>';
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Select Template'),
          headerClose: false,
          buttonPrompt: html,
          buttons: {
            [iTrans('Select')]: {
              click: function () {
                ResetITIN_PaxManifest();
                var index = $('#importTemplate').val();

                var Itinerary = JSON.parse(templates[index].Itinerary);

                if (Itinerary.SCH_DATA != undefined) {
                  SCH_DATA = Itinerary.SCH_DATA;
                }
                if (Itinerary.FP != undefined) {
                  try {
                    FuelPlans = JSON.parse(Itinerary.FP);
                    var UpdateWinds = false;
                    var timestamp = new Date();
                    var now = new Date();
                    now.setMinutes(now.getMinutes() + 30);
                    var Hours = now.getHours();
                    if (Hours < 10) Hours = '0' + Hours;
                    var Minutes = now.getMinutes();
                    if (Minutes < 10) Minutes = '0' + Minutes;
                    Hours = String(Hours);
                    Minutes = String(Minutes);
                    $('#rpTime').val(Hours + Minutes);
                    if (timestamp.getHours() > Hours) {
                      timestamp.setDate(timestamp.getDate() + 1);
                    } else if (timestamp.getHours() == parseInt(Hours)) {
                      if (timestamp.getMinutes() > parseInt(Minutes)) {
                        timestamp.setDate(timestamp.getDate() + 1);
                      }
                    }
                    timestamp.setHours(parseInt(Hours));
                    timestamp.setMinutes(parseInt(Minutes));
                    timestamp = timestamp.getTime();
                    if (FuelPlans[0].features != undefined) {
                      for (var i in FuelPlans) {
                        for (var f in FuelPlans[i].features) {
                          var F = FuelPlans[i].features[f].properties;
                          if (F.FuelPlan != undefined) {
                            var FP = F.FuelPlan;
                            UpdateWinds = true;
                            FP.Updated = false;
                            FP.Wind = 0;
                            FP.WindDir = 0;
                            FP.Temp = 0;
                            FP.Forecast = '00';
                            FP.timestamp = timestamp;
                            if (i == 0) {
                              $('#rpAlt').val(FP.ACalt);
                              $('#rpFuelBurn').val(FP.BurnRate);
                              $('#rpCruise').val(FP.AirSpeed);
                            }
                          }
                        }
                      }
                      MEASURE.LEGS = JSON.parse(JSON.stringify(FuelPlans));
                      MEASURE.CurLeg = 0;
                      MEASURE.geojson = JSON.parse(JSON.stringify(FuelPlans[0]));

                      if (UpdateWinds) UpdateFuelPlanWinds();
                    }
                  } catch (e) {
                    console.log(e);
                    FuelPlans = [];
                  }
                } else FuelPlans = [];
                selectTemplate(Itinerary);
              },
            },
            [iTrans('Cancel')]: {
              click: function () {
                //File bug report
                TEMPLATE_LOAD = false;
                TEMPLATE_FUEL = false;
                TEMPLATE_PAX = false;
              },
            },
          },
        });
        //WB_initialize(TCNUM);
      }
    }
  ).fail(function (jqXHR, status, error) {
    TCNUM = 0;

    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Connection Error'),
      headerClose: false,
      buttonPrompt: iTrans('There was an error Retrieving the data please try again.'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
      },
    });
  });
}

function selectTemplate(Itinerary) {
  var page;
  if (
    (FLIGHTDOCS == 1 && AC_data.TechDispatch == 1) ||
    (GROUP_DATA.WinAir == 1 && AC_data.TechDispatch == 1) ||
    (GROUP_DATA.Traxxall == 1 && AC_data.TechDispatch == 1) ||
    (GROUP_DATA.CAMP == 1 && AC_data.TechDispatch == 1)
  )
    page = 'ITIN-TECH-DISPATCH';
  else if (WANDB == '1') page = 'ITIN-OPTIONAL';
  else if (TDGACCESS == '1' && !VueBridge.hasFeature('TransportationOfDangerousGoods')) page = 'ITIN-TDG';
  else if (ASSETTRACKING == '1') page = 'ITIN-ASSETS';
  else if (RISK_MGMT == 1 && !SCHEDULER_OVERRIDE) page = 'RA_Form';
  else if (SCHEDULER_OVERRIDE) page = 'ITIN-ROUTEMAP';
  else page = 'ITIN-INPUT';
  if ((GROUP_DATA.group == 'transportcanada' && SCH_DATA.isLoading) || (GPS_DEBUG && SCH_DATA.isLoading)) {
    page = 'ITIN-INPUT';
  }
  itinNOW = page;
  $.mobile.changePage($('#' + page), {
    changeHash: false,
  });
  $('#' + page).enhanceWithin();
  if (Itinerary != null) {
    if (Itinerary.RoutePlan == 1) {
      RoutePlans = Itinerary.Route;
      PostRoute = JSON.stringify(RoutePlans); //string to post to database as array insert
    }

    TEMPLATE_LOAD = Itinerary.Summary;
    TEMPLATE_FUEL = Itinerary.fuelwt;
    TEMPLATE_PAX = Itinerary.paxnum;
    if (Itinerary.maxperfweight != undefined) {
      $('#AC_MAX_PERF2').val(Itinerary.maxperfweight);
    }

    Itinerary_initialize(Itinerary.tempconfig);
    if (Itinerary.TDG == 1 && !VueBridge.hasFeature('TransportationOfDangerousGoods')) {
      $.getJSON(BASEURL + 'menu-TDG-AJAX.php?action=getGroup', function (json_data) {
        if (json_data[5].length != 0) {
          userTDG = json_data[5][0];
          TDG_UserList = json_data[6];
          TDG_Manifest = Itinerary.TDGdata;
          TDG_Itin_Manifest();
          //$("#TDG").attr("checked",true);
          $('#TDG').prop('checked', true);
        }
      }).fail(function (jqXHR, status, error) {
        console.log(error);
      });
    }
    if (Itinerary.JobID != undefined) {
      ItinJobDefault = Itinerary.JobID;
      SCH_DATA.JobID = Itinerary.JobID;
      $('#itinClients').val(Itinerary.ClientPK);
      SCH_DATA.ClientID = Itinerary.ClientPK;
      //$("#ITIN_JOB").append('<option value="0" selected>'+ iTrans("None") +' - ['+ iTrans("Non-Rev") +']</option>');
    }
    if (Itinerary.Cfields != undefined) {
      BILLING_BACKUP_Cfields = JSON.parse(Itinerary.Cfields);
    } else {
      BILLING_BACKUP_Cfields = null;
    }
    if (Itinerary.ASSET == 1) {
      $('#select_Itin_Assets option:contains(' + Itinerary.AssetDetails + ')').prop('selected', 'selected');
      change_Itin_Assets();
    }
    $('#itin-flight-description').val(Itinerary.Details);
    if (Itinerary.survivalkit == 1) $('#survival').prop('checked', true);
    else $('#survival').prop('checked', false);
    if (Itinerary.liferaft == 1) $('#liferaft').prop('checked', true);
    else $('#liferaft').prop('checked', false);
    if (Itinerary.lifejackets == 1) $('#lifejackets').prop('checked', true);
    else $('#lifejackets').prop('checked', false);
    if (Itinerary.Pax_Manifest != undefined) {
      var PaxLegs = [];
      for (var l in Itinerary.Pax_Manifest.Pax_Legs) {
        PaxLegs[l] = Itinerary.Pax_Manifest.Pax_Legs[l];
      }
      ITIN_Pax_Manifest.DefaultList = Itinerary.Pax_Manifest.DefaultList;
      ITIN_Pax_Manifest.Pax_Legs = PaxLegs;
      ITIN_Pax_Manifest.HasVIP = Itinerary.Pax_Manifest.HasVIP;
    }
  } else {
    ItinJobDefault = SCH_DATA.JobID;
    ItinClientDefault = SCH_DATA.ClientID;
    $('#survival').prop('checked', false);
    $('#liferaft').prop('checked', false);
    $('#lifejackets').prop('checked', false);
    RoutePlans = [];
    PostRoute = null;
    $('#itin-flight-description').val('');
    TEMPLATE_LOAD = false;
    TEMPLATE_FUEL = undefined;
    TEMPLATE_PAX = false;
    var Ident = SCH_DATA.Flight_Data.ident;
    if (ONLINE) {
      $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=get_opt_Status&acident=' + Ident, function (json_data) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getActiveTC');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          if (json_data[0].ServerResponse == 'Success') {
            var TCNUM = 0;
            if (json_data[0].noTC == 1) {
              AC_data.opt_Status = json_data[0].Result;
            } else {
              TCNUM = json_data[0].Result;
            }
            Itinerary_initialize(TCNUM);
          }
        } else {
          Itinerary_initialize(0);
        }
      }).fail(function (jqXHR, status, error) {
        Itinerary_initialize(0);
      });
    } else {
      Itinerary_initialize(0);
    }
  }

  $('#itin-flight-description').unbind('keyup', calculateWB);

  if (page == 'ITIN-TECH-DISPATCH') {
    if (FLIGHTDOCS == 1) popTechDispatch();
    if (GROUP_DATA.WinAir == 1) popTechDispatch_WinAir();
    if (GROUP_DATA.Traxxall == 1) popTechDispatch_Traxxall();
    if (GROUP_DATA.CAMP == 1) popTechDispatch_CAMP();
  }
  if (page == 'ITIN-INPUT') {
    INIT_ITIN_PAGE();
    if (CurPageID() == 'ITIN-INPUT') {
      $('#itinClients').selectmenu('refresh', true);
      $('#Pilots').selectmenu('refresh', true);
      $('#ITIN_ResponsiblePerson').selectmenu('refresh', true);
      $('#SICs').selectmenu('refresh', true);
    }
  }
  if (page == 'RA_Form') {
    RISK_TEST = false;
    if (RAform == null) retrieveRiskForms('User');
    else {
      RAform.ItinActive = 0;
      RISK_CURRENT_DIV = 'RAformLoad';
      RAoperation();
    }
  }
}
var ItinJobDefault = 0;
var ItinClientDefault = 0;

var ItinResponsiblePersonPK = 0;
function ITIN_UpdtateResponsiblePerson() {
  ItinResponsiblePersonPK = $('#ITIN_ResponsiblePerson').val();
}
function searchItinResponsiblePerson(term, callback) {
  var text = term.term;
  var Users = FilterItin_ResponsiblePerson(STAFF_LIST, text, callback);
}
function FilterItin_ResponsiblePerson(OrigArray, keywords, callback) {
  var userFiltered = OrigArray;
  userFiltered = OrigArray.filter(function (item) {
    if (item.chatname.toLowerCase().indexOf(keywords.toLowerCase()) > -1) return true;
    else return false;
  });
  var options = [];
  for (var i in userFiltered) {
    var I = userFiltered[i];
    options.push({
      label: '[' + escapeHtml(I.staffType) + '] - ' + escapeHtml(I.chatname),
      value: I.PrimaryKey,
    });
  }
  callback(options);
}
function ItinResponsiblePersonSelect(event, ui) {
  $('#ITIN_ResponsiblePerson').val(ui.item.value).selectmenu('refresh');
  ItinResponsiblePersonPK = ui.item.value;
  setTimeout(function () {
    $('#ITIN_ResponsiblePersonSearch').val('');
    $('#ITIN_ResponsiblePersonSearch').blur();
  }, 500);
}
function searchItinJobName(term, callback) {
  var text = term.term;
  var Users = FilterItin_JobName(REPORT_JOBS, text, callback);
}
function FilterItin_JobName(OrigArray, keywords, callback) {
  var userFiltered = OrigArray;
  const currentUserId = window.vueApp.authTools.authGetCurrentUser()?.id;

  userFiltered = OrigArray.filter(function (item) {
    // Check if any items match the passed keywords
    if (
      item.Business.toLowerCase().indexOf(keywords.toLowerCase()) === -1 &&
      item.Name.toLowerCase().indexOf(keywords.toLowerCase()) === -1 &&
      item.JobID.toString().indexOf(keywords.toLowerCase()) === -1
    ) {
      return false;
    }

    // If the job is locked to a crew, check if the current user is part of that crew
    if (item.LockCrew == 1) {
      return item.Assigned_Crew.find((crew) => crew.UserPK == currentUserId) != null;
    }

    return true;
  });
  var options = [];
  for (var i in userFiltered) {
    var I = userFiltered[i];
    options.push({
      label: '[' + escapeHtml(I.Business) + '] - ' + escapeHtml(I.Name) + ' #' + I.JobNum,
      value: I.ClientPK + '|' + I.JobID,
    });
  }
  callback(options);
}
function ItinJobNameSelect(event, ui) {
  var parts = ui.item.value.split('|');
  ItinClientDefault = parts[0];
  ItinJobDefault = parts[1];
  $('#ITIN_JOBClientID').val(ItinClientDefault);
  if (ItinJobDefault == null) {
    ItinJobDefault = 0;
    ItinClientDefault = 0;
  }
  ITIN_PopJobs(true);
  setTimeout(function () {
    $('#ITIN_JOB_SEARCH').val('');
    //ITIN_ChangeJob();
    $('#ITIN_JOB_SEARCH').blur();
  }, 500);
}

function ITIN_LockScheduledJob() {
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    var Jobs = e;
    for (var i in Jobs) {
      var Job = Jobs[i];
      if (Job.JobID == SCH_DATA.JobID && (Job.ClientPK == SCH_DATA.ClientID || SCH_DATA.ClientID == null)) {
        $('#ITIN_JOB').empty();
        var Summary =
          '<b>' +
          iTrans('Client') +
          ': </b>' +
          Job.Business +
          '<br /><b>' +
          iTrans('Job Name') +
          ': </b>' +
          Job.Name +
          '<br /><b>' +
          iTrans('Description') +
          ': </b>' +
          Job.Description;
        $('#ITIN_JOB').append(
          '<option value="' +
            Job.JobID +
            '" data-desc="' +
            escapeHtml(Summary) +
            '" data-pk="' +
            Job.ClientPK +
            '" data-filename="' +
            Job.Filename +
            '" data-clientpk="' +
            Job.ClientPK +
            '" selected>' +
            '[' +
            escapeHtml(Job.Business) +
            '] - ' +
            escapeHtml(Job.Name) +
            ' #' +
            Job.JobNum +
            '</option>'
        );

        $('#ITIN_JOB_DESC_HIDE').show();
        $('#ITIN_JOB_DESC').html(Summary);
        $('#ITIN_JOB').selectmenu('disable').selectmenu('refresh', true);
        $('#ITIN_JOB_SEARCH').prop('disabled', true);
        ITIN_ChangeJob(Job);
        break;
      }
    }
  });
}
let previousPilot = null;

async function ITIN_PopJobs(pilotChange = false) {
  const Pilot = $('#Pilots').val();

  const previousPilotSelected = await checkPilotCapabilitiesAndHandlePilotSelection();
  if (previousPilotSelected) {
    return;
  }

  previousPilot = Pilot;

  if (SCH_DATA.isLoading) {
    if (SCH_DATA.ClientID != 0 && SCH_DATA.JobID != 0) {
      ITIN_LockScheduledJob();
      return;
    }
  }
  $('#ITIN_JOB_SEARCH').prop('disabled', false);
  if (GROUP_DATA.FlightReports == '0') return; //Billing module required to use this item

  //ItinJobDefault = $("#ITIN_JOB").val();
  $('#ITIN_JOB_DESC_HIDE').hide();
  $('#ITIN_JOB_DESC').html('');
  Itin_ClientPK = -1;
  ITIN_Pax_Manifest.ClientPK = 0;
  var AC = acIdent;
  var dateTime = new Date().toISOString();
  //Get list of jobs for client and populate ITIN_JOB dropdown select
  $.mobile.loading('show', {
    theme: 'a',
  });

  BillingDB('SELECT', 'JobsAll', null, function (Jobs) {
    if (Jobs === false) {
      Jobs = [];
    }

    REPORT_JOBS = Jobs;

    // to prevent duplicate field IDs sence reusing method
    $('#BILLING_CUSTOM_FILEDS_FR').html('');

    $('#ITIN_JOB').empty();

    if (Jobs.length == 0) {
      $('#ITIN_JOB')
        .append('<option value="0">' + iTrans('None') + ' - [' + iTrans('Non-Rev') + ']</option>')
        .selectmenu('disable')
        .selectmenu('refresh', true);

      $('#ITIN_JOB_DESC_HIDE').hide();
      $('#ITIN_JOB_DESC').html('');

      Itin_ClientPK = -1;

      ITIN_Pax_Manifest.ClientPK = 0;

      $.mobile.loading('hide');
      return;
    }

    var JobDefault = 0;
    var ClientDefault = 0;

    if (ItinJobDefault != 0) {
      JobDefault = ItinJobDefault;
      ClientDefault = ItinClientDefault;
    }

    var PilotPK = 0;
    if (LOCALSTORAGE.STAFF_LIST.length > 0) {
      if (LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === Pilot) !== undefined) {
        PilotPK = LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === Pilot).PrimaryKey;
      }
    }

    //todo add lookup on scheduler data to get a default job pk that is scheduled for today

    if (JobDefault == 0 && SCH_DATA.JobID == null)
      $('#ITIN_JOB').append(
        '<option value="0" data-desc="" data-clientpk="0" selected>' +
          iTrans('None') +
          ' - [' +
          iTrans('Non-Rev') +
          ']</option>'
      );
    else
      $('#ITIN_JOB').append(
        '<option value="0" data-desc="" data-clientpk="0">' + iTrans('None') + ' - [' + iTrans('Non-Rev') + ']</option>'
      );
    var selectedJobID = 0;
    for (var i in Jobs) {
      var Job = Jobs[i];
      var selected = '';
      if (Job.Assigned_Crew == undefined && (Job.LockCrew == 1 || !pilotChange)) continue;
      if (Job.Assigned_Crew.length == 0 && (Job.LockCrew == 1 || !pilotChange)) continue;
      if (Job.Assigned_Crew.find((x) => x.UserPK === PilotPK) === undefined && (Job.LockCrew == 1 || !pilotChange))
        continue; //if pilot not assigned to job don't list it

      if (
        (SCH_DATA.JobID == Job.JobID && SCH_DATA.ClientID == Job.ClientPK) ||
        (JobDefault == Job.JobID && ClientDefault == Job.ClientPK)
      ) {
        selected = 'selected';
        selectedJobID = Job.JobID;
        Itin_ClientPK = Job.ClientPK; //todo Global jobs do not have the proper associated client PK,  need to add SCH_DATA.ClientPK when loading scheduler templates

        ITIN_Pax_Manifest.ClientPK = Itin_ClientPK;
        if (ITIN_Pax_Manifest.ClientPK == -1) ITIN_Pax_Manifest.ClientPK = 0;
      }

      var Summary = `
      <b>${iTrans('Client')}: </b>${Job.Business}<br />
      <b>${iTrans('Job Name')}: </b>${Job.Name}<br />
      <b>${iTrans('Description')}: </b>${Job.Description}
      `;
      $('#ITIN_JOB').append(
        `<option 
        value="${Job.JobID}" 
        data-desc="${escapeHtml(Summary)}" 
        ${selected} 
        data-pk="${Job.ClientPK}" 
        data-filename="${Job.Filename}" 
        data-clientpk="${Job.ClientPK}">
          [${escapeHtml(Job.Business)}] - ${escapeHtml(Job.Name)} #${Job.JobNum}
        </option>`
      );
      if (
        (SCH_DATA.JobID == Job.JobID && SCH_DATA.ClientID == Job.ClientPK) ||
        (JobDefault == Job.JobID && ClientDefault == Job.ClientPK)
      ) {
        $('#ITIN_JOB_DESC_HIDE').show();
        $('#ITIN_JOB_DESC').html(Summary);
      }
    }
    $('#ITIN_JOB').selectmenu('enable');
    $('#ITIN_JOB').selectmenu('refresh', true);
    $.mobile.loading('hide');
    SCH_ChangeJob();

    ITIN_ChangeJob(null, Itin_ClientPK);
  });
  calculateWB();
}

var Itin_JobName = '';
var Itin_ClientName = '';
var Itin_ClientPK = -1;
function ITIN_ChangeJob(Job = null, ClientPK = null) {
  //backup company wide Cfields so we can repopulate them later
  if (REPORT_JOBS.length > 0 && BILLING_BACKUP_Cfields == null && Job == null) {
    BILLING_BACKUP_Cfields = JSON.parse(JSON.stringify(REPORT_JOBS[0].Cfields));
    for (var i in BILLING_BACKUP_Cfields) {
      var I = BILLING_BACKUP_Cfields[i];
      I.Value = $('#BI_CF_' + I.PrimaryKey).val();
    }
  }

  if ($('#ITIN_JOB').val() == 0) {
    //alert("none");
    $('#ITIN_JOB_DESC_HIDE').hide();
    $('#ITIN_JOB_DESC').html('');
    Itin_ClientPK = -1;
    ITIN_Pax_Manifest.ClientPK = 0;
    //$("#ITIN_JOB").addClass("redbg");
    $('#ITIN_JOB-button').addClass('redbg');
    Itin_PopulateClientDataFeed(0);
    $('#ITIN_JOBClientID').val(0);
  } else {
    const select = document.getElementById('ITIN_JOB');
    const selectedOption = select?.options?.[select.selectedIndex];
    // Get the client pk for the selected job, this fixes the issue with global jobs not having the proper client pk
    Itin_ClientPK = selectedOption?.dataset?.clientpk;
    ITIN_Pax_Manifest.ClientPK = Itin_ClientPK;
    $('#ITIN_JOB_DESC').html(selectedOption?.dataset?.desc);
    $('#ITIN_JOB_DESC_HIDE').show();
    //$("#ITIN_JOB").removeClass("redbg");
    $('#ITIN_JOB-button').removeClass('redbg');
  }
  ClientPK = ClientPK == null && Itin_ClientPK != -1 ? Itin_ClientPK : ClientPK;
  PAX_Init_ITIN_Manifesting(); //to update briefing status
  //$("#ITIN_JOB").selectmenu('refresh', true);
  var JobID = $('#ITIN_JOB').val();
  clearAllCfieldHolders();
  var CF;
  if (Job == null) {
    CF = JSON.parse(JSON.stringify(REPORT_JOBS[0].Cfields));
    Itin_JobName = 'Non Revenue';
    Itin_ClientName = 'Company';
    var Itin_ClientDataPK = 0;
    for (var f in REPORT_JOBS) {
        if (REPORT_JOBS[f].JobID != JobID) {
            continue;
        }

        CF = JSON.parse(JSON.stringify(REPORT_JOBS[f].Cfields));
        Itin_JobName = CF.Name;
        Itin_ClientName = CF.Business;
        Itin_ClientDataPK = CF.clientDataFeedPK;
        if (ClientPK != null) {
          $('#ITIN_JOBClientID').val(ClientPK);
        } else {
          $('#ITIN_JOBClientID').val(CF.ClientPK);
        }
    }
  } else {
    CF = JSON.parse(JSON.stringify(Job.Cfields));
    Itin_JobName = Job.Name;
    Itin_ClientName = Job.Business;
    Itin_ClientDataPK = Job.clientDataFeedPK;
    $('#ITIN_JOBClientID').val(ClientPK ?? Job.ClientPK);
  }

  Itin_PopulateClientDataFeed(Itin_ClientDataPK);
  var html =
    '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>' +
    iTrans('Additional Flight Information') +
    '</h5><div>';
  var requiredCF = '';
  if (CF.length > 0) {
    //html += "<h3>"+ iTrans("Additional Fields") +"</h3>";
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != acIdent && I.Aircraft != '') {
        continue;
      }
      if (!checkCfieldType('ITIN', I)) continue;
      if (I.PerLeg == 0) {
        if (BILLING_BACKUP_Cfields == null) {
          if (I.reqItin == 1) {
            requiredCF += createCustomField('', I, false, 'Itin');
          } else {
            html += createCustomField('', I, false, 'Itin');
          }
        } else {
          var foundVal = false;
          var oldVal = '';
          for (var c in BILLING_BACKUP_Cfields) {
            var C = BILLING_BACKUP_Cfields[c];
            if (C.PrimaryKey == I.PrimaryKey) {
              foundVal = true;
              oldVal = C.Value;
            }
          }
          if (foundVal) {
            if (I.reqItin == 1) requiredCF += createCustomField('', I, oldVal, 'Itin');
            else html += createCustomField('', I, oldVal, 'Itin');
          } else {
            if (I.reqItin == 1) requiredCF += createCustomField('', I, false, 'Itin');
            else html += createCustomField('', I, false, 'Itin');
          }
        }
      }
    }
    html += '</div></div>';
  } else {
    html = '';
  }
  ITIN_Cfields = CF;
  BILLING_BACKUP_Cfields = null;
  $('#SCH_BookingCfieldsHLDR').html('');
  $('#ITINERARY_CUSTOM_FILEDS_PR').html(requiredCF + html);
  $('#ITINERARY_CUSTOM_FILEDS_PR').enhanceWithin();

  CheckClientPDF();
}

function CheckClientPDF() {
  if ($('#ITIN_JOB').val() == 0) {
    $('#ITIN_JOB_PDF').html('');
  } else {
    var Filename = $('#ITIN_JOB option:selected').attr('data-filename');
    if (Filename != '') {
      var PK = $('#ITIN_JOB option:selected').attr('data-pk');
      $('#ITIN_JOB_PDF').html(
        '<a href="' +
          BASEURL +
          'menu-Billing-AJAX.php?action=getClientPDF&OPEN=1&PrimaryKey=' +
          PK +
          '" target="_blank" class="greenbg" data-role="button">' +
          iTrans('Client Itinerary') +
          ' PDF: ' +
          Filename +
          '</a>'
      );
      $('#ITIN_JOB_PDF').enhanceWithin();
    } else {
      $('#ITIN_JOB_PDF').html('');
    }
  }
}

function sendSCHTemplate() {
  document.getElementById('SCHsendTemplateBTN1').disabled = true; //update for save template btn
  document.getElementById('SCHsendTemplateBTN2').disabled = true; //update for save template btn
  var TDG, RoutePlan, SurvivalKit, LifeRaft, Details, ReturnDate, ReturnTime, Pilot;
  if ($('#TDG').is(':checked')) TDG = 1;
  else TDG = 0;
  if ($('#RoutePlanCHK').is(':checked')) RoutePlan = 1;
  else RoutePlan = 0;
  if ($('#survival').is(':checked')) SurvivalKit = 1;
  else SurvivalKit = 0;
  if ($('#liferaft').is(':checked')) LifeRaft = 1;
  else LifeRaft = 0;

  if ($('#lifejackets').is(':checked')) LifeJackets = 1;
  else LifeJackets = 0;

  if ($('#Itin_IsVIP').is(':checked')) VIP = 1;
  else VIP = 0;

  Details = SCHEVENTDATA.Description;

  var Pilot = SCH_DATA.Pilot;

  var JobID = SCHEVENTDATA.Job_ID;
  var Client_ID = SCHEVENTDATA.Client_ID;
  var WB = 1; //make edits to allow for optional weight and balance for filing itinerary
  var otherwt = 0;
  var ESN = AC_data.esn;
  //var otherwt = attachedwt; //add array of passenger weights and baggage weights and attached weights
  var wbDate = TC_data[tcIndex].Date; //get wbDate from weight and balance stuff
  var Ehmom = TC_data[tcIndex].TCHorzMom;
  var Elmom = TC_data[tcIndex].TCLatMom;
  var TCdesc = tcDesc;
  var WBSummary = '';
  for (var i in Loading) {
    if (Loading[i].Variable != undefined) {
      WBSummary +=
        Loading[i].Abr +
        ':' +
        $('#' + Loading[i].Abr).val() +
        ':' +
        Loading[i].LoadLong +
        ':' +
        Loading[i].LoadLat +
        ',';
    } else {
      if (Loading[i].VariableArms == 1) {
        WBSummary +=
          Loading[i].Abr +
          ':' +
          $('#' + Loading[i].Abr).val() +
          ':' +
          Loading[i].LoadLong +
          ':' +
          Loading[i].LoadLat +
          ',';
      } else {
        WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr).val() + ',';
      }
      WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr).val() + ',';
    }
    otherwt += parseFloat($('#' + Loading[i].Abr).val());
  }
  for (var i in optLoad) {
    if (optLoad[i].VariableArms == 1) {
      WBSummary +=
        optLoad[i].Abr +
        ':' +
        $('#' + optLoad[i].Abr).val() +
        ':' +
        optLoad[i].LoadLong +
        ':' +
        optLoad[i].LoadLat +
        ',';
    } else {
      WBSummary += optLoad[i].Abr + ':' + $('#' + optLoad[i].Abr).val() + ',';
    }
    otherwt += parseFloat($('#' + optLoad[i].Abr).val());
  }
  var TDG_Details = '';
  var TDG_Data = '';
  if (TDG == 1) {
    TDG_Details = 'unknown';
    if ($('#TDG_Loader').length) {
      TDG_Details = $('#TDG_Loader').val();
    } else {
      TDG_Details = 'Pilot';
    }
    TDG_Data = TDG_Manifest;
  }

  var Index = $('#select_Itin_Assets').val();
  var AssetData = [];
  var AssetDetails = '';
  var ASSET = 0;
  if (Index != -1) {
    //Asset Tracking is on
    ASSET = 1;
    AssetDetails = $('#select_Itin_Assets option:selected').text();
    var AssetStatus = $('input[type="checkbox"]')
      .filter('.ItinAssets')
      .map(function () {
        var name = $(this).attr('name');
        if ($(this).is(':checked'))
          return {
            name: name,
            status: true,
          };
        else
          return {
            name: name,
            status: false,
          };
      })
      .get();
    for (i in AssetStatus) {
      if (AssetStatus[i].status) {
        ItinAssetTemplates[Index].Assets[i].Status = $('#Asset_Status-' + i).val();
        ItinAssetTemplates[Index].Assets[i].Lat = UserDevice.Lat;
        ItinAssetTemplates[Index].Assets[i].Long = UserDevice.Long;
        AssetData.push(ItinAssetTemplates[Index].Assets[i]);
      }
    }
  }
  if (FuelPlans.length > 0) FP = JSON.stringify(FuelPlans);
  else FP = 'None';
  var MLobslider = LOCALSTORAGE.MLobslider;
  if (MLobslider == undefined) MLobslider = 10;
  var Client = $('#itinClients').val();
  var PerReportFields = [];
  var CF = ITIN_Cfields;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != acIdent && I.Aircraft != '') {
        continue;
      }
      if (checkCfieldType('FR', I)) {
        PerReportFields.push(I);
        continue;
      }
      if (I.PerLeg == 0) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
      }
      PerReportFields.push(I);
    }
  }
  var Cfields = JSON.stringify(PerReportFields);

  var Itinerary = {
    Cfields: Cfields,
    Files: [],
    pilot: Pilot,
    paxnum: paxnum,
    survivalkit: SurvivalKit,
    liferaft: LifeRaft,
    lifejackets: LifeJackets,
    VIP: VIP,
    TDG: TDG,
    Details: Details,
    RoutePlan: RoutePlan,
    WB: WB,
    Route: RoutePlans,
    acwt: facweight,
    fuelwt: fuelwt,
    otherwt: otherwt,
    cofglat: fcofgLat,
    cofglong: fcofgLong,
    tempconfig: tcNum,
    wbDate: wbDate,
    Eweight: eacweight,
    maxperfweight: maxperfweight,
    Ehmom: Ehmom,
    Elmom: Elmom,
    TCdesc: TCdesc,
    Summary: WBSummary,
    TDGdetails: TDG_Details,
    TDGdata: TDG_Data,
    ASSET: ASSET,
    AssetDetails: AssetDetails,
    AssetData: AssetData,
    FP: FP,
    ClientPK: Client,
    NM: MLobslider,
    JobID: JobID,
    LEGS: SCH_DATA.LEGS,
    Client_ID: Client_ID,
    Pax_Manifest: ITIN_Pax_Manifest,
  };
  Itinerary = JSON.stringify(Itinerary);

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Scheduler-AJAX.php?action=saveSCHTemplate',
    data: {
      Ident: SCH_DATA.Ident,
      Booking_ID: SCH_DATA.Booking_ID,
      AC_ID: SCH_DATA.AC_PK,
      Itinerary: Itinerary,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'saveSCHtemplate()');
        document.getElementById('SCHsendTemplateBTN1').disabled = false; //update for save template btn
        document.getElementById('SCHsendTemplateBTN2').disabled = false; //update for save template btn
      }
      if (data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Flight Template was Saved Successfully.'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                document.getElementById('SCHsendTemplateBTN1').disabled = false; //update for save template btn
                document.getElementById('SCHsendTemplateBTN2').disabled = false; //update for save template btn
                $.mobile.changePage($('#Schedule_Item'), {
                  reverse: true,
                  changeHash: false,
                });
                ITIN_InProgress = false;
                SCHbookAssociated();
              },
            },
          },
        });
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt:
            iTrans('There was a problem saving your template') +
            '.  ' +
            iTrans('Please try again') +
            '. ' +
            iTrans('If this continues to happen please submit a bug report') +
            '.',
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                document.getElementById('SCHsendTemplateBTN1').disabled = false; //update for save template btn
                document.getElementById('SCHsendTemplateBTN2').disabled = false; //update for save template btn
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt:
          iTrans('There was a problem saving your template') +
          '.  ' +
          iTrans('Please try again') +
          '. ' +
          iTrans('If this continues to happen please submit a bug report') +
          '.',
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              document.getElementById('SCHsendTemplateBTN1').disabled = false; //update for save template btn
              document.getElementById('SCHsendTemplateBTN2').disabled = false; //update for save template btn
            },
          },
        },
      });
    });
}

function GetOFP_Html(OFP, FuelPlan = '') {
  var html = '<h4>' + iTrans('Operational Flight Plan') + '</h4><table>';
  var keys = Object.keys(OFP);
  for (var i in keys) {
    if (keys[i] == 'PrimaryKey' || keys[i] == 'ItinPK' || keys[i] == 'group') continue;
    if (keys[i] == 'PilotSignature' || (keys[i] == 'DispatchSignature' && OFP[keys[i]] != null)) {
      html +=
        "<tr style='background-color: white;'><td style='vertical-align:top;'><h3>Trip Details</h3></td><td>" +
        FuelPlan +
        '</td></tr>';
      html +=
        '<tr><td>' +
        iTrans(keys[i]) +
        "</td><td><img src='" +
        OFP[keys[i]] +
        " ' height='150px'/><br />" +
        OFP.PICName +
        '</td></tr>';
    } else {
      if (keys[i] == 'DispatchSignature' && OFP[keys[i]] == null) OFP[keys[i]] = '<b>Unsigned</b>';
      html += '<tr><td>' + iTrans(keys[i]) + '</td><td>' + OFP[keys[i]] + '</td></tr>';
    }
  }
  html += '</table>';
  return html;
}

function selectSliderInput() {
  $('#slider').focus().select();
}

function itin_editTdgManifest() {
  // if there is more than one manifest which only occurs for air ambulance we select the dynamic one to edit
  VueBridge.enterVue(
    `groups/${GROUP_DATA.group}/dangerous-goods/manifest/${window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS[0]}`
  );
}

function itin_generatePaxInput(maxPassengers, currentValue) {
  return `<div class="grid-row">
    <div class="grid-col-sm-12">
      <label for="passenger-count">${iTrans('Number of Passengers')}</label>
      <div class="sama-icon-input rev">
        <input onfocus="selectInputContent(this)" onchange="clampInput(this)" oninput="passengerChange(this)" id="passenger-count" value="${currentValue}" type="number" min="0" max="${maxPassengers}">
        <span class="sama-icon">${iTrans('of')} ${maxPassengers}</span>
      </div>
    </div>
  </div>`;
}

function uncheckDangerousGoodsWarningDialog() {
  return new Promise((resolve) => {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Warning'),
      headerClose: false,
      buttonPrompt: iTrans(
        'I acknowledge dangerous goods are no longer being transported. Any dangerous good manifest data will be removed. This cannot be undone.'
      ),
      buttons: {
        [iTrans('Remove DG')]: {
          click: function () {
            resolve(true);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            resolve(false);
          },
        },
      },
    });
  });
}
function checkPilotCapabilitiesForTdgManifest() {
  const pilotName = $('#Pilots').val();
  const pilotEmail = PILOT_data.find((pilot) => pilot.chatname === pilotName).username;
  return hasCapabilitiesToCreateTdgManifest(pilotEmail);
}

async function checkPilotCapabilitiesAndHandlePilotSelection() {
  if (typeof CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS === 'undefined') {
    return false;
  }

  const pilotHasTdgCapabilities = await checkPilotCapabilitiesForTdgManifest();
  if (pilotHasTdgCapabilities) {
    return false;
  }

  const cancelSelection = await showNoCapabilitiesDialogForPilotSelection();
  if (cancelSelection) {
    $('#Pilots').val(previousPilot).change();
    return true;
  }

  $('#TDG-REVAMP').prop('checked', false).checkboxradio('refresh');
  $('#TDG-REVAMP-ManifestEdit').prop('disabled', true);
  TdgManifestClearForCurrentItinerary();
  return false;
}
