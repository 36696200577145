var BILLING_AIRPORT_RESULTS = [];

function BILLING_GetTodayDate() {
  var date = new Date();
  //date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); //because new dates are sometimes not right probalby hnew TZ conversion shit
  return date.toISOString().substr(0, 10);
}

function BILLING_FromSearchAirportsICAO(term, callback) {
  BILLING_SearchAirports_Type(term, callback, 'ICAO_Q', 'BMQL_FromICAO');
}

function BILLING_ToSearchAirportsICAO(term, callback) {
  BILLING_SearchAirports_Type(term, callback, 'ICAO_Q', 'BMQL_ToICAO');
}

function BILLING_SearchAirportsNameCity(term, callback) {
  BILLING_SearchAirports_Type(term, callback, 'NameCity', '');
}

function BILLING_SearchAirports_Type(term, callback, type, id, otherList = []) {
  var txt = term.term.toLowerCase();
  var options = [];
  if (txt.length < 2) {
    callback(options);
    return;
  }
  if (txt.length > 4 && id != '') {
    $('#' + id).val(txt.substr(0, 4));
    return;
  }
  $.getJSON(
    BASEURL + 'menu-Map-AJAX.php?action=searchAirports&Type=' + type + '&ICAO=' + encodeURIComponent(txt),
    function (data) {
      //local database is empty and offline fail with message
      var result = data.features;
      BILLING_AIRPORT_RESULTS = result;
      for (var J in result) {
        var I = result[J].properties;
        var label = I.ICAO + ': ' + I.Name + ' [' + I.City + ']';
        options.push({ label: label, value: J });
        if (result.length == 1 && id != '') {
          if (id == 'BMQL_FromICAO') {
            callback([]);
            $('#BMQL_FromICAO').val(I.ICAO);
            $('#BMQL_FromICAO_Name').val(I.Name);
            $('#BMQL_FromDESC').val(I.City + ', ' + I.Country);
            BILLING_CheckLegAirportDistance();
            CURRENT_QUOTE.DepartAirport = JSON.parse(JSON.stringify(I));
            BILLING_UpdateQuoteLegTimes('local');
            return;
          }
          if (id == 'BMQL_ToICAO') {
            callback([]);
            $('#BMQL_ToICAO').val(I.ICAO);
            $('#BMQL_ToICAO_Name').val(I.Name);
            $('#BMQL_ToDESC').val(I.City + ', ' + I.Country);
            BILLING_CheckLegAirportDistance();
            CURRENT_QUOTE.ArrivalAirport = JSON.parse(JSON.stringify(I));
            BILLING_UpdateQuoteLegTimes('local');
            return;
          }
          if (id == 'TZSearch' && I.keywords != '') {
            otherList.unshift({ label: '[' + I.ICAO + '] ' + I.Name + ' ' + I.keywords, value: I.keywords });
            callback(otherList);
            return;
          }
        } else {
          if (id == 'TZSearch' && I.keywords != '') {
            otherList.unshift({ label: '[' + I.ICAO + '] ' + I.Name + ' ' + I.keywords, value: I.keywords });
          }
        }
      }
      if (id == 'TZSearch') {
        callback(otherList);
        return;
      }
      callback(options);
    }
  ).fail(function (jqXHR, status, error) {
    if (id == 'TZSearch') {
      callback(otherList);
      return;
    }
    callback(options);
  });
}

function BILLING_FromICAO_select(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  var C = BILLING_AIRPORT_RESULTS[value].properties;
  $('#BMQL_FromICAO').val(C.ICAO);
  $('#BMQL_FromICAO_Name').val(C.Name);
  $('#BMQL_FromDESC').val(C.City + ', ' + C.Country);
  BILLING_CheckLegAirportDistance();
}

function BILLING_ToICAO_select(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  var C = BILLING_AIRPORT_RESULTS[value].properties;
  $('#BMQL_ToICAO').val(C.ICAO);
  $('#BMQL_ToICAO_Name').val(C.Name);
  $('#BMQL_ToDESC').val(C.City + ', ' + C.Country);
  BILLING_CheckLegAirportDistance();
}

function BILLING_CheckLegAirportDistance() {
  var From = $('#BMQL_FromICAO').val();
  var To = $('#BMQL_ToICAO').val();
  if (From.length == 4 && To.length == 4) {
    BILLING_GetAirportDistance(From, To, 'NM', function (Distance) {
      $('#BMQL_Distance').val(Distance);
      BILLING_CalculateETE_ETA();
    });
  }
}

function BILLING_GetAirportDistance(ICAO1, ICAO2, Units, callback) {
  $.getJSON(
    BASEURL +
      'menu-Map-AJAX.php?action=ICAOtoICAO&ICAO1=' +
      encodeURIComponent(ICAO1) +
      '&ICAO2=' +
      encodeURIComponent(ICAO2) +
      '&Units=' +
      Units,
    function (data) {
      callback(Math.round(data.Distance));
    }
  ).fail(function (jqXHR, status, error) {
    callback(0);
  });
}

function BILLING_VerifyLegTime(e) {
  var id = e.id;
  $('#' + id).val(FormatLegTime(e.value));
  BILLING_Convert_Times(e);
}
function BILLING_Convert_Times(e) {
  var id = e.id;
  switch (id) {
    case 'BMQL_Cruise':
    case 'BMQL_ETE':
      BILLING_UpdateQuoteLegTimes('local');
      break;
    case 'BMQL_DepartTime':
      BILLING_UpdateQuoteLegTimes('local');
      break;
    case 'BMQL_DepartTimeUTC':
      BILLING_UpdateQuoteLegTimes('UTC');
      break;
    case 'BMQL_DepartTimeHome':
      BILLING_UpdateQuoteLegTimes('Home');
      break;
    case 'BMQL_ArrivalTime':
      BILLING_UpdateQuoteLegTimes('local');
      break;
    case 'BMQL_ArrivalTimeUTC':
      BILLING_UpdateQuoteLegTimes('UTC');
      break;
    case 'BMQL_ArrivalTimeHome':
      BILLING_UpdateQuoteLegTimes('Home');
      break;
  }
}

function BILLING_VerifyLegDate(e) {
  var id = e.id;
  switch (id) {
    case 'BMQL_DepartDate':
      BILLING_UpdateQuoteLegTimes('local');
      break;
    case 'BMQL_DepartDateUTC':
      BILLING_UpdateQuoteLegTimes('UTC');
      break;
    case 'BMQL_DepartDateHome':
      BILLING_UpdateQuoteLegTimes('Home');
      break;
    case 'BMQL_ArrivalDate':
      BILLING_UpdateQuoteLegTimes('local');
      break;
    case 'BMQL_ArrivalDateUTC':
      BILLING_UpdateQuoteLegTimes('UTC');
      break;
    case 'BMQL_ArrivalDateHome':
      BILLING_UpdateQuoteLegTimes('Home');
      break;
  }
}
function BILLING_UpdateQuoteLegTimes(input) {
  console.log(input);
  switch (input) {
    case 'local':
      var DepartDateTime = new Date($('#BMQL_DepartDate').val() + 'T' + $('#BMQL_DepartTime').val() + ':00Z');
      DepartDateTime.setMinutes(
        DepartDateTime.getMinutes() + Get_TZ_Difference($('#BMQ_EntryTimezone').val(), 'UTC', true)
      );
      var UTC_DepartDate = DepartDateTime.toISOString().substr(0, 10);
      var UTC_DepartTime = DepartDateTime.toISOString().substr(11, 5);
      $('#BMQL_DepartDateUTC').val(UTC_DepartDate);
      $('#BMQL_DepartTimeUTC').val(UTC_DepartTime);
      var ArrivalDateTime = new Date($('#BMQL_ArrivalDate').val() + 'T' + $('#BMQL_ArrivalTime').val() + ':00Z');
      ArrivalDateTime.setMinutes(
        ArrivalDateTime.getMinutes() + Get_TZ_Difference($('#BMQ_EntryTimezone').val(), 'UTC', true)
      );
      var UTC_ArrivaltDate = ArrivalDateTime.toISOString().substr(0, 10);
      var UTC_ArrivalTime = ArrivalDateTime.toISOString().substr(11, 5);
      $('#BMQL_ArrivalDateUTC').val(UTC_ArrivaltDate);
      $('#BMQL_ArrivalTimeUTC').val(UTC_ArrivalTime);

      $('#BMQL_DepartDateHome').val('');
      $('#BMQL_DepartTimeHome').val('');
      $('#BMQL_DepartTZ').html(iTrans('Unknown Timezone'));
      $('#BMQL_ArrivalTZ').html(iTrans('Unknown Timezone'));
      $('#BMQL_DepartDateHome').prop('disabled', true);
      $('#BMQL_DepartTimeHome').prop('disabled', true);
      if (CURRENT_QUOTE.DepartAirport != null) {
        var DepartHomeTZ = CURRENT_QUOTE.DepartAirport;
        if (DepartHomeTZ.keywords != '') {
          $('#BMQL_DepartTZ').html(DepartHomeTZ.keywords);
          DepartDateTime.setMinutes(
            DepartDateTime.getMinutes() + Get_TZ_Difference('UTC', DepartHomeTZ.keywords, true)
          );
          UTC_DepartDate = DepartDateTime.toISOString().substr(0, 10);
          UTC_DepartTime = DepartDateTime.toISOString().substr(11, 5);
          $('#BMQL_DepartDateHome').prop('disabled', false);
          $('#BMQL_DepartTimeHome').prop('disabled', false);
          $('#BMQL_DepartDateHome').val(UTC_DepartDate);
          $('#BMQL_DepartTimeHome').val(UTC_DepartTime);
        }
      }

      $('#BMQL_ArrivalDateHome').val('');
      $('#BMQL_ArrivalTimeHome').val('');
      $('#BMQL_ArrivalDateHome').prop('disabled', true);
      $('#BMQL_ArrivalTimeHome').prop('disabled', true);
      if (CURRENT_QUOTE.ArrivalAirport != null) {
        var ArrivalHomeTZ = CURRENT_QUOTE.ArrivalAirport;
        if (ArrivalHomeTZ.keywords != '') {
          $('#BMQL_ArrivalTZ').html(ArrivalHomeTZ.keywords);
          ArrivalDateTime.setMinutes(
            ArrivalDateTime.getMinutes() + Get_TZ_Difference('UTC', ArrivalHomeTZ.keywords, true)
          );
          UTC_ArrivalDate = ArrivalDateTime.toISOString().substr(0, 10);
          UTC_ArrivalTime = ArrivalDateTime.toISOString().substr(11, 5);
          $('#BMQL_ArrivalDateHome').prop('disabled', false);
          $('#BMQL_ArrivalTimeHome').prop('disabled', false);
          $('#BMQL_ArrivalDateHome').val(UTC_ArrivalDate);
          $('#BMQL_ArrivalTimeHome').val(UTC_ArrivalTime);
        }
      }
      break;
    case 'UTC':
      var UTC_DepartDateTime = new Date($('#BMQL_DepartDateUTC').val() + 'T' + $('#BMQL_DepartTimeUTC').val() + ':00Z');
      UTC_DepartDateTime.setMinutes(
        UTC_DepartDateTime.getMinutes() - Get_TZ_Difference($('#BMQ_EntryTimezone').val(), 'UTC', true)
      );
      var DepartDate = UTC_DepartDateTime.toISOString().substr(0, 10);
      var DepartTime = UTC_DepartDateTime.toISOString().substr(11, 5);
      $('#BMQL_DepartDate').val(DepartDate);
      $('#BMQL_DepartTime').val(DepartTime);
      var UTC_ArrivalDateTime = new Date(
        $('#BMQL_ArrivalDateUTC').val() + 'T' + $('#BMQL_ArrivalTimeUTC').val() + ':00Z'
      );
      UTC_ArrivalDateTime.setMinutes(
        UTC_ArrivalDateTime.getMinutes() - Get_TZ_Difference($('#BMQ_EntryTimezone').val(), 'UTC', true)
      );
      var ArrivaltDate = UTC_ArrivalDateTime.toISOString().substr(0, 10);
      var ArrivalTime = UTC_ArrivalDateTime.toISOString().substr(11, 5);
      $('#BMQL_ArrivalDate').val(ArrivaltDate);
      $('#BMQL_ArrivalTime').val(ArrivalTime);

      UTC_DepartDateTime = new Date($('#BMQL_DepartDateUTC').val() + 'T' + $('#BMQL_DepartTimeUTC').val() + ':00Z');

      $('#BMQL_DepartDateHome').val('');
      $('#BMQL_DepartTimeHome').val('');
      $('#BMQL_DepartTZ').html(iTrans('Unknown Timezone'));
      $('#BMQL_ArrivalTZ').html(iTrans('Unknown Timezone'));
      $('#BMQL_DepartDateHome').prop('disabled', true);
      $('#BMQL_DepartTimeHome').prop('disabled', true);
      if (CURRENT_QUOTE.DepartAirport != null) {
        var DepartHomeTZ = CURRENT_QUOTE.DepartAirport;
        if (DepartHomeTZ.keywords != '') {
          $('#BMQL_DepartTZ').html(DepartHomeTZ.keywords);
          UTC_DepartDateTime.setMinutes(
            UTC_DepartDateTime.getMinutes() + Get_TZ_Difference(DepartHomeTZ.keywords, 'UTC', true)
          );
          var Home_DepartDate = UTC_DepartDateTime.toISOString().substr(0, 10);
          var Home_DepartTime = UTC_DepartDateTime.toISOString().substr(11, 5);
          $('#BMQL_DepartDateHome').prop('disabled', false);
          $('#BMQL_DepartTimeHome').prop('disabled', false);
          $('#BMQL_DepartDateHome').val(Home_DepartDate);
          $('#BMQL_DepartTimeHome').val(Home_DepartTime);
        }
      }
      UTC_ArrivalDateTime = new Date($('#BMQL_ArrivalDateUTC').val() + 'T' + $('#BMQL_ArrivalTimeUTC').val() + ':00Z');
      $('#BMQL_ArrivalDateHome').val('');
      $('#BMQL_ArrivalTimeHome').val('');
      $('#BMQL_ArrivalDateHome').prop('disabled', true);
      $('#BMQL_ArrivalTimeHome').prop('disabled', true);
      if (CURRENT_QUOTE.ArrivalAirport != null) {
        var ArrivalHomeTZ = CURRENT_QUOTE.ArrivalAirport;
        if (ArrivalHomeTZ.keywords != '') {
          $('#BMQL_ArrivalTZ').html(ArrivalHomeTZ.keywords);
          UTC_ArrivalDateTime.setMinutes(
            UTC_ArrivalDateTime.getMinutes() + Get_TZ_Difference(ArrivalHomeTZ.keywords, 'UTC', true)
          );
          var Home_ArrivalDate = UTC_ArrivalDateTime.toISOString().substr(0, 10);
          var Home_ArrivalTime = UTC_ArrivalDateTime.toISOString().substr(11, 5);
          $('#BMQL_ArrivalDateHome').prop('disabled', false);
          $('#BMQL_ArrivalTimeHome').prop('disabled', false);
          $('#BMQL_ArrivalDateHome').val(Home_ArrivalDate);
          $('#BMQL_ArrivalTimeHome').val(Home_ArrivalTime);
        }
      }
      break;
    case 'Home':
      var Home_DepartDateTime = new Date(
        $('#BMQL_DepartDateHome').val() + 'T' + $('#BMQL_DepartTimeHome').val() + ':00Z'
      );
      Home_DepartDateTime.setMinutes(
        Home_DepartDateTime.getMinutes() +
          Get_TZ_Difference(CURRENT_QUOTE.DepartAirport.keywords, $('#BMQ_EntryTimezone').val(), true)
      );
      var DepartDate = Home_DepartDateTime.toISOString().substr(0, 10);
      var DepartTime = Home_DepartDateTime.toISOString().substr(11, 5);
      $('#BMQL_DepartDate').val(DepartDate);
      $('#BMQL_DepartTime').val(DepartTime);

      var Home_ArrivalDateTime = new Date(
        $('#BMQL_ArrivalDateHome').val() + 'T' + $('#BMQL_ArrivalTimeHome').val() + ':00Z'
      );
      Home_ArrivalDateTime.setMinutes(
        Home_ArrivalDateTime.getMinutes() +
          Get_TZ_Difference(CURRENT_QUOTE.ArrivalAirport.keywords, $('#BMQ_EntryTimezone').val(), true)
      );
      var ArrivaltDate = Home_ArrivalDateTime.toISOString().substr(0, 10);
      var ArrivalTime = Home_ArrivalDateTime.toISOString().substr(11, 5);
      $('#BMQL_ArrivalDate').val(ArrivaltDate);
      $('#BMQL_ArrivalTime').val(ArrivalTime);

      BILLING_UpdateQuoteLegTimes('local');

      break;
  }
}
function BILLING_Update_QuoteAirportTimezones() {
  $('#BMQL_HomeDate').html(iTrans('Date') + ' (' + iTrans('Local') + ')');
  $('#BMQL_HomeTime').html(iTrans('Time') + ' (' + iTrans('Local') + ')');
  if (CORDOVA) SearchMobileDB($('#BMQL_FromICAO').val(), BILLING_UpdateQuoteLegTimesHomeDepart);
  else SearchOnlineDB($('#BMQL_FromICAO').val(), BILLING_UpdateQuoteLegTimesHomeDepart);
}
function BILLING_UpdateQuoteLegTimesHomeDepart() {
  if (AUTOCOMPLETE.data.length > 0) {
    var AirportDepartInfo = JSON.parse(JSON.stringify(AUTOCOMPLETE.data[0]));
    CURRENT_QUOTE.DepartAirport = AirportDepartInfo;
  } else {
    //disable Home TZ Depart date/time inputs
    CURRENT_QUOTE.DepartAirport = null;
  }
  if (CORDOVA) SearchMobileDB($('#BMQL_ToICAO').val(), BILLING_UpdateQuoteLegTimesHomeArrival);
  else SearchOnlineDB($('#BMQL_ToICAO').val(), BILLING_UpdateQuoteLegTimesHomeArrival);
}

function BILLING_UpdateQuoteLegTimesHomeArrival() {
  if (AUTOCOMPLETE.data.length > 0) {
    var AirportArrivalInfo = JSON.parse(JSON.stringify(AUTOCOMPLETE.data[0]));
    CURRENT_QUOTE.ArrivalAirport = AirportArrivalInfo;
    BILLING_UpdateQuoteLegTimes('local');
  } else {
    //disable Home TZ Arrival date/time inputs
    CURRENT_QUOTE.ArrivalAirport = null;
  }
}

function BILLING_decimalToHHMM(val) {
  var HH = Math.floor(val);
  var MM = Math.round((val % 1) * 60);
  if (MM < 10) MM = '0' + MM.toString();
  var value = HH.toString() + ':' + MM.toString();
  return FormatLegTime(value);
}

function FormatLegTime(value) {
  value = value.replace(/[^0-9]/, '');
  value = parseInt(value);
  value = value.toString();
  var HH = '00';
  var MM = '00';
  if (value.length == 4) {
    HH = value.substr(0, 2);
    MM = value.substr(2, 2);
  }
  if (value.length == 3) {
    HH = value.substr(0, 1);
    MM = value.substr(1, 2);
  }
  if (value.length == 2) {
    HH = '00';
    MM = value;
  }
  if (value.length == 1) {
    HH = '00';
    MM = '0' + value;
  }
  MM = parseInt(MM);
  HH = parseInt(HH);
  if (MM > 59) {
    HH++;
    MM = MM - 60;
  }
  if (MM < 10) {
    MM = MM.toString();
    MM = '0' + MM;
  } else MM = MM.toString();

  if (HH > 24) HH = 24; //set maximum 24 hour flight time
  if (HH < 10) HH = '0' + HH.toString();
  value = HH + ':' + MM;
  return value;
}

function BILLING_VerifyTaxiTime(e) {
  var value = e.value;
  if (e.value < 0) value = 0;
  if (e.value > 99) value = 99;
  $('#' + e.id).val(value);
}

function BILLING_UpdateLegBillable(e) {
  var L = QUOTE_CURRENT_LEG;
  if (e.checked) {
    L.Billable = 1;
  } else {
    L.Billable = 0;
  }
  BILLING_CalculateACLegAmount();
}

function BILLING_CalculateETE_ETA() {
  //take distance and update ETE and Destination Arrival Time
  var Distance = parseInt($('#BMQL_Distance').val());
  var Cruise = parseInt($('#BMQL_Cruise').val());
  var ETE = '00:00';
  var TaxiTime = (parseInt($('#BMQL_TaxiTimeOrigin').val()) + parseInt($('#BMQL_TaxiTimeDest').val())) / 60;

  if (Cruise > 0 && Distance > 0) {
    ETE = BILLING_decimalToHHMM(Distance / Cruise + TaxiTime);
  }
  $('#BMQL_ETE').val(ETE);
  //Calculate Leg Amount
  BILLING_CalculateACLegAmount();
  BILLING_CalcDestTime();
  BILLING_UpdateQuoteLegTimes('local');
}
function BILLING_UpdateAircraftLegRate(e) {
  var L = QUOTE_CURRENT_LEG;
  L.Rate = parseFloat(e.value);
  BILLING_CalculateACLegAmount();
}
function BILLING_UpdateAircraftLegTax(e) {
  var L = QUOTE_CURRENT_LEG;
  L.Tax = roundNum(parseFloat(e.value),2);
  BILLING_CalculateACLegAmount();
}
function BILLING_UpdatePassengerCount(e) {
  var L = QUOTE_CURRENT_LEG;
  L.PaxNum = parseInt(e.value);
  BILLING_CalculateACLegAmount();
}
function BILLING_CalculateACLegAmount() {
  var L = QUOTE_CURRENT_LEG;
  var NumAircraft = 1;
  if (L.QACPK == 0) {
    var A = CURRENT_QUOTE.Aircraft;
    NumAircraft = 0;
    for (var i in A) {
      if (A[i].UniqueLegs == 0) {
        NumAircraft++;
      }
    }
  }
  var Distance = parseInt($('#BMQL_Distance').val());
  var Cruise = parseInt($('#BMQL_Cruise').val());
  var Weight = parseInt($('#BMQL_Weight').val());
  var TaxiTime = (parseInt($('#BMQL_TaxiTimeOrigin').val()) + parseInt($('#BMQL_TaxiTimeDest').val())) / 60;
  var Amount = 0;
  if (CURRENT_QUOTE.Job != undefined) {
    if (CURRENT_QUOTE.Job.BlockTime == 0) {
      TaxiTime = 0;
    }
  }
  var rateLBL = L.Rate_Unit;
  if (L.Billable) {
    switch (L.Rate_Unit) {
      case 'Hour':
        var Hours = roundNum(Distance / Cruise + TaxiTime,1);
        $('#BMQL_Quantity').html(Hours  + ' ' + iTrans('Hours'));
        L.Amount = roundNum(NumAircraft * Hours * L.Rate ,2);
        break;
      case 'nm':
        $('#BMQL_Quantity').html(roundNum(Distance,1) + ' nm');
        L.Amount = roundNum(NumAircraft * Distance * L.Rate ,2);
        break;
      case 'sm':
        var convDistance = roundNum(Distance * 1.15078,1);
        $('#BMQL_Quantity').html(convDistance + ' sm');
        L.Amount = roundNum(NumAircraft * convDistance* L.Rate ,2);
        break;
      case 'km':
        var convDistance = roundNum(Distance * 1.852,1);
        $('#BMQL_Quantity').html(convDistance+ ' km');
        L.Amount = roundNum(NumAircraft * convDistance * L.Rate ,2);
        break;
      case 'kg':
        var convWeight = roundNum(Weight,1);
        $('#BMQL_Quantity').html(convWeight + ' kg');
        L.Amount = roundNum(NumAircraft * convWeight * L.Rate ,2);
        break;
      case 'lb':
        var convWeight = roundNum(Weight,1);
        $('#BMQL_Quantity').html(convWeight+ ' lb');
        L.Amount = roundNum(NumAircraft * convWeight * L.Rate ,2);
        break;
      case 'litres':
        rateLBL = 'Litre';
        var convWeight = roundNum(Weight,1);
        $('#BMQL_Quantity').html(convWeight+ ' Litres');
        L.Amount = roundNum(NumAircraft * convWeight * L.Rate ,2);
        break;
      case 'gallonsus':
        rateLBL = 'US Gallon';
        var convWeight = roundNum(Weight,1);
        $('#BMQL_Quantity').html(convWeight + ' US Gallons');
        L.Amount = roundNum(NumAircraft * convWeight * L.Rate ,2);
        break;
      case 'gallonsimp':
        rateLBL = 'Imp Gallon';
        var convWeight = roundNum(Weight,1);
        $('#BMQL_Quantity').html(convWeight + ' Imp Gallons');
        L.Amount = roundNum(NumAircraft * convWeight * L.Rate ,2);
        break;
    }
  } else {
    $('#BMQL_Quantity').html('N/A - ' + iTrans('Non Billable'));
    L.Amount = 0;
  }
  if (isNaN(L.Amount)) L.Amount = 0;
  $('#BMQL_Amount').html(L.Amount.toLocaleString('en-US', CUR));
  $('#BMQL_Rate_Unit').html(L.Currency + ' / ' + rateLBL + ' * ' + NumAircraft + ' Aircraft');
  BILLING_TotalCfieldItems('BMQ_CFL');
}

function BILLING_CalcDestTime() {
  var Origin = new Date($('#BMQL_DepartDate').val() + 'T' + $('#BMQL_DepartTime').val() + ':00Z');
  var ETE = $('#BMQL_ETE').val();
  var Hours = parseInt(ETE.substr(0, 2));
  var Minutes = parseInt(ETE.substr(3, 2));
  if (!isNaN(Hours)) Origin.setHours(Origin.getHours() + Hours);
  if (!isNaN(Minutes)) Origin.setMinutes(Origin.getMinutes() + Minutes);
  var date = Origin.toISOString().substr(0, 10);
  var Time = Origin.toISOString().substr(11, 5);
  $('#BMQL_ArrivalDate').val(date);
  $('#BMQL_ArrivalTime').val(Time);
}
function BILLING_CalcOriginTime() {
  var Origin = new Date($('#BMQL_ArrivalDate').val() + 'T' + $('#BMQL_ArrivalTime').val() + ':00Z');
  var ETE = $('#BMQL_ETE').val();
  var Hours = parseInt(ETE.substr(0, 2));
  var Minutes = parseInt(ETE.substr(3, 2));
  if (!isNaN(Hours)) Origin.setHours(Origin.getHours() - Hours);
  if (!isNaN(Minutes)) Origin.setMinutes(Origin.getMinutes() - Minutes);
  var date = Origin.toISOString().substr(0, 10);
  var Time = Origin.toISOString().substr(11, 5);
  $('#BMQL_DepartDate').val(date);
  $('#BMQL_DepartTime').val(Time);
}
