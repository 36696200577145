/**
 * This is a wrapper to make jquery's AJAX calls awaitable.
 * Would prefer to use axios, but feels kinda wrong to add another doubled up dependency.
 * -- Falko
 */
class RemoteData {
  static async post(url, data, timeout = 120000, type = 'json') {
    return RemoteData._internalPost('POST', url, data, timeout, type);
  }

  static async patch(url, data, timeout = 120000, type = 'json') {
    return RemoteData._internalPost('PATCH', url, data, timeout, type);
  }

  static async delete(url, timeout = 120000) {
    return RemoteData._internalPost('DELETE', url, null, timeout);
  }

  static async _internalPost(method, url, data, timeout, type = 'json') {
    verbose.log('NET', method, url);
    return new Promise((resolve, reject) => {
      $.ajax({
        type: method,
        url: BASEURL + url,
        timeout: timeout,
        data: data,
        dataType: type,
      })
        .success((jsonData) => {
          let serverResponse = jsonData;
          if (Object.hasOwnProperty.call(jsonData, '0')) {
            serverResponse = jsonData[0];
          }

          if (Object.hasOwnProperty.call(serverResponse, 'ServerResponse')) {
            if (serverResponse['ServerResponse'] === 'Uncaught Exception') {
              let exceptionMessage = serverResponse['Exception'] ? serverResponse['Exception'] : 'Unknown Error';
              reject(new Error(exceptionMessage));
              return;
            }
            if (serverResponse['ServerResponse'] === 'Not Logged In') {
              NOTLOGGEDIN();
              resolve([]);
              return;
            }
          }

          // It's an actual success!
          resolve(jsonData);
        })
        .fail((jqXHR, textStatus, errorThrown) => {
          verbose.error('NET', { method, url, data, timeout, type }, { jqXHR, textStatus, errorThrown });

          if (!(errorThrown instanceof Error)) {
            if (typeof errorThrown === 'string') {
              errorThrown = errorThrown.length > 0 ? errorThrown : 'Unknown Error';
            }
            errorThrown = new Error(errorThrown);
          }

          errorThrown.netObject = jqXHR;

          reject(errorThrown);
        });
    });
  }

  static async get(url, data, timeout = 120000, type = 'json') {
    verbose.log('NET', 'GETing url', url);

    url += this.buildQueryString(data);

    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: BASEURL + url,
        timeout: timeout,
        success: resolve,
        dataType: type,
      }).fail((jqXHR, textStatus, errorThrown) => {
        verbose.error('NET', 'GET', { url, data, timeout, type }, { jqXHR, textStatus, errorThrown });
        reject(errorThrown);
      });
    });
  }

  /**
   * @param queryObjects
   * @return {string}
   */
  static buildQueryString(queryObjects) {
    const queryString = [];
    for (const c in queryObjects) {
      if (!queryObjects.hasOwnProperty(c)) {
        return;
      }
      const uenc = encodeURIComponent(queryObjects[c]);
      queryString.push(`${c}=${uenc}`);
    }

    if (queryString.length <= 0) {
      return '';
    }

    return '?' + queryString.join('&');
  }
}
