class CirroToaster
{
  constructor()
  {
    this.toasts    = {};
    this.numToasts = 0;
  }

  /**
   * @param {string} message HTML to place inside the toast
   * @param {number} timeout Number of seconds until the toast is closed automatically or 0 to keep it there until
   * the user closes it manually
   * @return {number} The ID of the toast, can be used to remove it
   */
  show(message, timeout = 5)
  {
    this.numToasts++;
    this.toasts[this.numToasts] = new CirroToast(this.numToasts, this, message, timeout);
    return this.numToasts;
  }

  /**
   * Removes a given toast
   * @param id
   */
  remove(id)
  {
    if (this.toasts.hasOwnProperty(id))
    {
      this.toasts[id]._remove();
    }
  }

  _unlink(id)
  {
    if (this.toasts.hasOwnProperty(id))
    {
      delete this.toasts[id];
    }
  }

  getToast(id)
  {
    if (this.toasts.hasOwnProperty(id))
    {
      return this.toasts[id];
    }
    return null;
  }
}

class CirroToast
{
  constructor(id, owner, message, timeout)
  {
    this.owner = owner;
    this.id    = id;
    this.dom   = $('<div>').addClass('cirro-toast')
                           .css('z-index', 100000 + this.id)
                           .hide();

    const closeContainer = $('<div>').addClass('cirro-toast_close')
                                     .on('click', () => this._remove())
                                     .appendTo(this.dom);

    $('<i>')
        .addClass('fal fa-times')
        .appendTo(closeContainer);

    this.body = $('<div>')
        .addClass('cirro-toast_content')
        .html(message);

    this.body.appendTo(this.dom);
    $('body').prepend(this.dom);

    this.dom.fadeIn(150);

    if (timeout > 0)
    {
      this.timeout = window.setTimeout(() => this._remove(), timeout * 1000);
    }
  }

  setContent(_newContent)
  {
    this.body.html(_newContent);
  }

  _remove()
  {
    if (this.timeout != null)
    {
      window.clearTimeout(this.timeout);
    }
    this.timeout = null;

    this.dom.fadeOut(150, () => this.dom.remove());
    this.owner._unlink(this.id);
  }
}

window.toaster = new CirroToaster();
