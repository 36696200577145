var HIDDEN_LIST = [];
function ActivateHideable() {
  HIDDEN_LIST = [];
  $('.hideable').on('click', ELE_Hide_Item);
}
function De_ActivateHideable() {
  HIDDEN_LIST = [];
  $('.hideable').off('click', ELE_Hide_Item);
}
function ELE_Hide_Item() {
  HIDDEN_LIST.push(this);
  $(this).fadeOut();
}
function ELE_Remove_Items() {
  for (var i in HIDDEN_LIST) {
    HIDDEN_LIST[i].parentNode.removeChild(HIDDEN_LIST[i]);
  }
  HIDDEN_LIST = [];
}
function ToggleInvoiceEdit(e) {
  if (e.id == 'EditInvoiceElements') {
    BILLING_INVOICE_DATA.Edited = true;
    var Noedit = 'true';
    if (e.checked) {
      Noedit = 'false';
      BILLING_INVOICE_DATA.needSave = true;
      $('#InvoicePDF_BTN').prop('disabled', true);
      $('#InvoicePDF_EmailBTN').prop('disabled', true);
      $('#InvoiceFinalize_BTN').prop('disabled', true);
      $('#submitInvoiceBTN').prop('disabled', true);
      $('#BILLING_INVOIE_BackBTN').prop('disabled', true);
    } else {
      $('#submitInvoiceBTN').prop('disabled', false);
      $('#BILLING_INVOIE_BackBTN').prop('disabled', false);
      BILLING_InvoiceSaveStatus();
    }
    var editable_elements = document.querySelectorAll('[contenteditable=' + Noedit + ']');
    for (var i = 0; i < editable_elements.length; i++) editable_elements[i].setAttribute('contenteditable', e.checked);
  }
}

/************************************** Student Balances **************************************/
function BILLING_ShowMyAccountBalance() {
  $.mobile.changePage($('#BILLING_StudentBalancePage'), {
    changeHash: false,
  });
  ExchangeQuoteData('GetMyBalance', null, BILLING_DisplayAccountBalanceRecs);
}

function BILLING_DisplayAccountBalanceRecs(data) {
  var ClientBalance = data[0].ClientBalance;

  var CurBalance = parseFloat(ClientBalance[0].CurBalance);
  var html = '<h3>Current Balance: ' + CurBalance.toLocaleString('en-US', CUR) + '</h3>';
  if (CurBalance < 0) {
    html = '<h3>Current Balance: (' + CurBalance.toLocaleString('en-US', CUR) + ')</h3>';
  }
  html +=
    '<h3>' +
    iTrans('Last 100 Transactions<') +
    '/h3><table data-filter="true" class="limit-table wrap"><thead><tr><th>' +
    iTrans('UTC Timestamp') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Amount') +
    '</th><th>' +
    iTrans('Cur Balance') +
    '</th><th>' +
    iTrans('Notes') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in ClientBalance) {
    var iClass = '';
    var I = ClientBalance[i];
    if (I.CurBalance < 0) iClass = 'redbg';
    var Action = '';
    if (I.InvoicePK != 0) {
      Action =
        "<div class='vertical-center-container'><button onClick='BILLING_DisplayUserInvoicePK(" +
        I.InvoicePK +
        ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
    }
    html +=
      '<tr><td>' +
      I.Entered +
      '</td><td>' +
      I.Action +
      '</td><td>' +
      I.Amount.toLocaleString('en-US', CUR) +
      '</td><td class="' +
      iClass +
      '">' +
      I.CurBalance.toLocaleString('en-US', CUR) +
      '</td><td>' +
      I.Notes +
      '</td><td>' +
      Action +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#BILLING_STUDENTBALANCEHLDR').html(html);
}

function BILLING_DisplayUserInvoicePK(PK) {
  EDIT_INVOICE = false;
  BILLING_REVIEW = true;
  BILLING_GETINVOICE_USER(PK);
  $('#submitInvoiceBTN').prop('disabled', true);
  BILLING_RETURN_USER_BALANCE = true;
}
function BILLING_GETINVOICE_USER(PrimaryKey) {
  signatureDATA = {
    data: '',
    text: '',
  };
  $('#BILLING_INVOICEPREVIEW').html('<h2>' + iTrans('Retrieving Invoice') + '</h2>');
  //Get big INVOICE data;
  if (BILLING_REVIEW) {
    $.mobile.changePage($('#BILLING_INVOICEPREVIEWPage'), {
      changeHash: false,
    });
  }
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getINVOICE_USER&PrimaryKey=' + PrimaryKey, function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Get Invoice Data') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'BILLING_GETINVOICE');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        BILLING_INVOICE_DATA = json_data[0];
        CURRENCY_RATES = json_data[2];
        if (EDIT_INVOICE) BILLING_AdminInvoiceComplete(false);
        else BILLING_PreviewInvoice(json_data[1]);
      }
    } else {
      $('#BILLING_INVOICEPREVIEW').html('<h2>' + iTrans('No Invoices Available to View') + '</h2>');
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

var Cfield_Signature;
var Cfield_SignatureStatus = {
  PrimaryKey: 0,
  Leg: false,
  returnPageID: '',
  CfieldSigID: '',
  CfieldID: '',
};

function BILLING_REMOVE_SIGNATURE(PK, Leg) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this Signature?'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          var Pax = false;
          if (PAX_MANIFESTING.UnlockSignature) {
            Cfield_SignatureStatus.CfieldSigID = 'MAN_CF_SIGN_' + PK;
            Cfield_SignatureStatus.CfieldID = 'MAN_CF_' + PK;
          } else if (Cfield_SignatureStatus.returnPageID == 'PAX_PaxBriefingsPage') {
            Cfield_SignatureStatus.CfieldSigID = 'Pax_CF_SIGN_' + PK;
            Cfield_SignatureStatus.CfieldID = 'Pax_CF_' + PK;
            Pax = 1;
          } else if (Cfield_SignatureStatus.returnPageID == 'PAX_NewPassengerPage') {
            Cfield_SignatureStatus.CfieldSigID = 'PAX_CF_SIGN_' + PK;
            Cfield_SignatureStatus.CfieldID = 'PAX_CF_' + PK;
            for (var i in CUR_PASSENGER.Cfields) {
              var I = CUR_PASSENGER.Cfields[i];
              if (I.PrimaryKey == PK) {
                I.Value = '';
                break;
              }
            }
            Pax = 1;
          } else if (Cfield_SignatureStatus.returnPageID == 'PAX_PaxdoBriefingsPage') {
            Cfield_SignatureStatus.CfieldSigID = 'ITIN_BRIEF_CF_SIGN_' + PK;
            Cfield_SignatureStatus.CfieldID = 'ITIN_BRIEF_CF_' + PK;
            Pax = 2;
          } else if (Leg === false) {
            Cfield_SignatureStatus.CfieldSigID = 'BI_CF_SIGN_' + PK;
            Cfield_SignatureStatus.CfieldID = 'BI_CF_' + PK;
          } else {
            Cfield_SignatureStatus.CfieldSigID = 'BI_CF_SIGN' + Leg + '_' + PK;
            Cfield_SignatureStatus.CfieldID = 'BI_CF' + Leg + '_' + PK;
          }

          var SignatureHTML =
            '<button class="yellowbg" style="width: 80px;" data-mini="true" id="' +
            Cfield_SignatureStatus.CfieldSigID +
            '" onClick="BILLING_CAPTURE_SIGNATURE(' +
            PK +
            ', ' +
            Leg +
            ', ' +
            Pax +
            ');">Sign Now</button>';
          $('#' + Cfield_SignatureStatus.CfieldSigID).html(SignatureHTML);
          $('#' + Cfield_SignatureStatus.CfieldSigID).enhanceWithin();
          $('#' + Cfield_SignatureStatus.CfieldID).val('');
          if (Cfield_SignatureStatus.Leg !== false) updateFlightLegData(Cfield_SignatureStatus.Leg);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_CAPTURE_SIGNATURE(PK, Leg, Pax = false) {
  //goto signature page
  Cfield_SignatureStatus.returnPageID = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  Cfield_SignatureStatus.PrimaryKey = PK;
  Cfield_SignatureStatus.Leg = Leg;
  if (Leg === false) {
    Cfield_SignatureStatus.CfieldSigID = 'BI_CF_SIGN_' + PK;
    Cfield_SignatureStatus.CfieldID = 'BI_CF_' + PK;
  } else {
    Cfield_SignatureStatus.CfieldSigID = 'BI_CF_SIGN' + Leg + '_' + PK;
    Cfield_SignatureStatus.CfieldID = 'BI_CF' + Leg + '_' + PK;
  }
  if (Pax === 1) {
    Cfield_SignatureStatus.CfieldSigID = 'PAX_CF_SIGN_' + PK;
    Cfield_SignatureStatus.CfieldID = 'PAX_CF_' + PK;
  }
  if (PAX_MANIFESTING.UnlockSignature && Cfield_SignatureStatus.returnPageID !== 'PAX_NewPassengerPage') {
    Cfield_SignatureStatus.CfieldSigID = 'MAN_CF_SIGN_' + PK;
    Cfield_SignatureStatus.CfieldID = 'MAN_CF_' + PK;
  }

  $.mobile.changePage($('#BILLING_CFIELD_Signature'), {
    transition: 'slide',
    changeHash: false,
    reverse: false,
  });
  $('#cfield-signature-name').val('');
  $('#cfield-signature-name').prop('disabled', false);

  if (PK == 0) $('#cfield-signature-name').val(CURRENT_FLIGHT_REPORT.Pilot);
  if (
    (Pax === 2 && !PAX_MANIFESTING.UnlockSignature) ||
    (BRIEFING_MANIFEST.briefing && !PAX_MANIFESTING.UnlockSignature)
  ) {
    Cfield_SignatureStatus.CfieldSigID = 'ITIN_BRIEF_CF_SIGN_' + PK;
    Cfield_SignatureStatus.CfieldID = 'ITIN_BRIEF_CF_' + PK;
    $('#cfield-signature-name').val(ITIN_Pax_Briefing.name);
    $('#cfield-signature-name').prop('disabled', true);
  }

  $('#cfield-signature-pad-wrapper').html('');
  $('#cfield-signature-pad-wrapper').html('<canvas id="cfield-signature-pad" class="signature-pad"></canvas>');
  var canvas = document.getElementById('cfield-signature-pad');
  Cfield_Signature = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    if (Cfield_Signature.isEmpty()) {
      setTimeout(function () {
        CFIELD_ResizeSignaturePad();
      }, 1000);
    }
  }
  window.addEventListener('orientationchange', resizeCanvas);
  CFIELD_ResizeSignaturePad();
}
function CFIELD_ResizeSignaturePad() {
  var canvas = document.getElementById('cfield-signature-pad');
  var ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
  Cfield_Signature.clear(); // otherwise isEmpty() might return incorrect value
  window.scrollTo(0, 0);
}
function CFIELD_ClearSignature() {
  CFIELD_ResizeSignaturePad();
}

function CFIELD_returnSignature() {
  var SignatureName = $('#cfield-signature-name').val();
  if (SignatureName.length == 0 && !Cfield_Signature.isEmpty()) {
    alert(iTrans('Signature Name cannot be blank!'));
    return;
  }

  if (SignatureName.length > 0 && Cfield_Signature.isEmpty()) {
    alert(iTrans('Signature Cannot be blank!'));
    return;
  }
  if (!Cfield_Signature.isEmpty()) {
    var data = Cfield_Signature.toDataURL('image/svg+xml');
    if (Cfield_SignatureStatus.CfieldSigID == 'BI_CF_SIGN_0') {
      var html = '<img src="' + data + '" width="200px" height="50px"/>';
      $('#' + Cfield_SignatureStatus.CfieldSigID).html(html);
    } else {
      var html = '<div style="display: inline-block;"><img src="' + data + '" width="321px" height="150px"/><br />';
      html +=
        '<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>' +
        SignatureName +
        '</b></td><td><button onClick="BILLING_REMOVE_SIGNATURE(' +
        Cfield_SignatureStatus.PrimaryKey +
        ', ' +
        Cfield_SignatureStatus.Leg +
        ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></td></tr></table></center></div>';
      $('#' + Cfield_SignatureStatus.CfieldSigID).html(html);
      $('#' + Cfield_SignatureStatus.CfieldID).val(JSON.stringify({ data: data, SignatureName: SignatureName }));
      if (Cfield_SignatureStatus.Leg !== false) updateFlightLegData(Cfield_SignatureStatus.Leg);
    }
  }
  $.mobile.changePage($('#' + Cfield_SignatureStatus.returnPageID), {
    transition: 'slide',
    changeHash: false,
    reverse: true,
  });
}
