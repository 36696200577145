/**
 * Get category list then
 * show the report using the default filters
 */
function getCAReportData() {
  // Get the filters
  const getReportCategoryReq = new cSAMA_Request('ReportCategory', 'getData');
  getFilterCAData('GetReportCategories', getReportCategoryReq, vCAReportCategoryFilter);

  // Get the reports
  filterCAReportData();
}

/**
 * Gets all the reports using the
 * selected filters
 */
function filterCAReportData() {
  // Get the report filters
  const reportFilters = getSelectedCAFilters();

  const getCAReportReq = new cSAMA_Request('CorrectiveActionsReport', 'getData');
  getCAReportReq.Set('reportFilters', reportFilters);

  getFilterCAData('GetCAReport', getCAReportReq, showCAReportPreview);
}

/**
 * Sets the table headers and
 * populate the table rows
 * @param _caData
 */
function showCAReportPreview(_caData) {
  let caReportData = _caData.caReportData;

  // Extracting the last element which contains the risk value headers
  // and removing it from caReportData
  let riskValueHeaders = caReportData.pop();

  const emptyStringsCount = riskValueHeaders?.length;
  const emptyStringsArray = new Array(emptyStringsCount).fill('');

  // Defining headers array
  const headers = [
    [
      'Linked SMS Report Info',
      '',
      '',
      '',
      '',
      'Consequences|Risk Estimation',
      '',
      '',
      '',
      '',
      '',
      ...emptyStringsArray,
      'Root Cause Analysis',
      '',
      '',
      '',
      '',
      'Corrective Action',
      '',
      '',
      '',
      '',
      '',
      '',
      'Status',
      '',
      '',
      '',
    ],
  ];

  const headersColspan = {
    linkedSMSColspan: 5,
    riskValueColspan: 6 + emptyStringsArray.length,
    rootCauseColspan: 5,
    correctiveActionColspan: 7,
    statusColspan: 4,
  };

  // Defining other header components
  const headersBeforeRiskValHeaders = [
    'Report ID',
    'Title',
    'Submitted Date',
    'Report Type',
    'Report Category',
    'Description',
    'Status (Already Happened)',
    'Frequency',
  ];
  const headersAfterRiskValHeaders = [
    'CAP Risk',
    'Estimated Risk',
    'Mitigated Risk',
    'Why1',
    'Why2',
    'Why3',
    'Why4',
    'Why5',
    'Resolution',
    'CAP Title',
    'CAP ID',
    'Responsible Staff ID',
    'Responsible Staff Name',
    'Term',
    'Due Date',
    'Status (In Progress|Completed)',
    'Completed By',
    'Completed Date',
    'Comments',
  ];

  riskValueHeaders = riskValueHeaders || [];
  // Combine headers into one array
  headers[1] = [...headersBeforeRiskValHeaders, ...riskValueHeaders, ...headersAfterRiskValHeaders];

  // Populate the table
  const tableBody = $('#previewCAReport');
  const caReportHtml = formatCAReport(headers, caReportData, riskValueHeaders.length, headersColspan);
  tableBody.html(caReportHtml);
  AddTableSorter('CA_Report_Table');

  // Handle CSV download
  $('#downloadCACsvBtn')
    .off('click')
    .on('click', function () {
      exportCACSV(caReportData, headers, riskValueHeaders);
    });

  // Show the modal
  $('#caReportModal').show();
  $('#ca-report-overlay').show();
}

/**
 * Hide the report modal
 */
function hideCAReportPreview() {
  $('#caReportModal').hide();
  $('#ca-report-overlay').hide();
}

/**
 * Create the html table for the report
 * @param headers
 * @param data
 * @param riskHeadersCount
 * @param caColspan
 * @returns {string}
 */
function formatCAReport(headers = null, data = null, riskHeadersCount = 0, caColspan = null) {
  const propAutoWidth = ['rep_dueDate'];

  let table = '<div class="table-container"><table id="CA_Report_Table" data-filter-"true" class="wrap"><thead>';

  if (headers) {
    table += '<tr>';
    table += `<th colspan="${caColspan.linkedSMSColspan}"         style="text-align: center">Linked SMS Report Info</th>`;
    table += `<th colspan="${caColspan.riskValueColspan}"         style="text-align: center">Consequences | Risk Estimation</th>`;
    table += `<th colspan="${caColspan.rootCauseColspan}"         style="text-align: center">Root Cause Analysis</th>`;
    table += `<th colspan="${caColspan.correctiveActionColspan}"  style="text-align: center">Corrective Action</th>`;
    table += `<th colspan="${caColspan.statusColspan}"            style="text-align: center">Status</th>`;
    table += '</tr>';
    table += '<tr>';

    headers[1].forEach((header) => {
      table += '<th>' + iTrans(header) + '</th>';
    });
    table += '</tr>';
  }

  table += '</tr></thead><tbody>';

  if (data.length > 0) {
    // Create table td from the data
    table += cSAMA_Utils.GenerateTableContent(data, propAutoWidth, riskHeadersCount);
  } else {
    table += '<tr>';
    table += '<td colspan=25>No results found.</td>';
    table += '</tr>';
  }

  table += '</tbody></table></div>';

  return table;
}

/**
 *
 * @param _data
 * @param _headers
 * @param _riskValueHeaders
 */
function exportCACSV(_data, _headers, _riskValueHeaders) {
  const flattenedData = _data.map((item) => {
    let flattenedItem = {
      'Report ID': item.rep_primaryKey,
      Title: item.rep_summary,
      'Submitted Date': item.rep_submittedDate,
      'Report Type': item.rep_reportType,
      'Report Category': item.rep_category,
      Description: item.rep_consqDescription,
      'Status (Already Happened)': item.rep_happenedVal,
      Frequency: item.rep_frequency,
    };

    // Add the risk value headers
    _riskValueHeaders.forEach((header, index) => {
      flattenedItem[header] = item.riskFactor && item.riskFactor[index] ? item.riskFactor[index].riskFactorValue : '';
    });

    const remainingFields = {
      'CAP Risk': item.rep_coaRisk,
      'Estimated Risk': item.rep_estRisk,
      'Mitigated Risk': item.rep_mitRisk,
      Why1: item.rep_why1,
      Why2: item.rep_why2,
      Why3: item.rep_why3,
      Why4: item.rep_why4,
      Why5: item.rep_why5,
      Resolution: item.rep_resolution,
      'CAP Title': item.rep_capTitle,
      'CAP ID': item.rep_capId,
      'Responsible Staff ID': item.rep_resStaffId,
      'Responsible Staff Name': item.rep_resStaffName,
      Term: item.rep_term,
      'Due Date': item.rep_dueDate,
      'Status (In Progress/Completed)': item.rep_caStatus,
      'Completed By': item.rep_completedBy,
      'Completed Date': item.rep_completedDate,
      Comments: item.rep_comments,
    };

    flattenedItem = { ...flattenedItem, ...remainingFields };
    return flattenedItem;
  });

  const fields = [
    'Report ID',
    'Title',
    'Submitted Date',
    'Report Type',
    'Report Category',
    'Description',
    'Status (Already Happened)',
    'Frequency',
  ];

  const combinedFields = [
    ...fields,
    ..._riskValueHeaders,
    'CAP Risk',
    'Estimated Risk',
    'Mitigated Risk',
    'Why1',
    'Why2',
    'Why3',
    'Why4',
    'Why5',
    'Resolution',
    'CAP Title',
    'CAP ID',
    'Responsible Staff ID',
    'Responsible Staff Name',
    'Term',
    'Due Date',
    'Status (In Progress/Completed)',
    'Completed By',
    'Completed Date',
    'Comments',
  ];

  window.vueApp.utilities.blobUtils.downloadAsCSVMultHeader(
    flattenedData,
    combinedFields,
    _headers,
    'Cirro_Corrective_Actions_Report'
  );
}

/**
 * Populate the category options filter
 * @param repCategory
 */
function vCAReportCategoryFilter(repCategory) {
  const reportCatSel = $('#ca-report-category');
  const reportCatVal = reportCatSel.val();

  reportCatSel.empty().append(
    $('<option>', {
      value: 0,
      text: 'Any',
    })
  );

  repCategory.forEach((category) => {
    reportCatSel.append(
      $('<option>', {
        value: category.rc_primaryKey,
        text: category.rc_name,
      })
    );
  });

  if (reportCatVal === null) {
    reportCatSel.val(0);
  } else {
    reportCatSel.val(reportCatVal);
  }
}

/**
 * Sends request to the server
 * @param _reqName
 * @param _request
 * @param _callback
 */
function getFilterCAData(_reqName, _request, _callback) {
  cSAMA_Request.Dispatch({
    name: _reqName,
    reqs: _request,
    clientErrorCode: 'SAMA 34 22 CC EE',
    fnSuccess: (_result) => {
      _callback(_result[0]);
    },
  });
}

/**
 * Get the selected filters
 * @returns {{
 * fromDate: (*|string|jQuery),
 * sortByRisk: (*|string|jQuery),
 * toDate: (*|string|jQuery),
 * id: (*|string|jQuery),
 * type: (*|string|jQuery),
 * category: (*|string|jQuery),
 * department: (*|string|jQuery),
 * status: (*|string|jQuery),
 * sortByDate: (*|string|jQuery)
 * }}
 */
function getSelectedCAFilters() {
  return {
    criteria: $('#ca-report-criteria').val(),
    stage: $('#ca-report-stage').val(),
    category: $('#ca-report-category').val(),
    capId: $('#ca-cap-id-title').val(),
    reportId: $('#ca-report-id-title').val(),
    filterMinRisk: $('#ca-report-min-risk').val(),
    filterMaxRisk: $('#ca-report-max-risk').val(),
    filterFromDate: $('#ca-from-date').val(),
    filterToDate: $('#ca-to-date').val(),
    sortByDue: $('#ca-sort-due').val(),
    sortByRisk: $('#ca-sort-risk').val(),
  }
}

/**
 * Toggle Filter for mobile view
 */
function toggleCAFilter() {
  const filterContainer = $('.caReportModal .filter-container');
  filterContainer.toggleClass('collapsed');
}

/**
 * Hide report modal onload
 */
$(document).ready(function () {
  $('#caReportModal').hide();
  $('#ca-report-overlay').hide();
});
