class mSAMA_TrendRule extends mSAMA_AbstractModel {
  /**
   * Class dealing with the syncing and displaying of trend rules
   * @param {int}            _num How many trend rules are in the works?
   * @param {object}         [_databaseData]
   */
  constructor(_num, _databaseData) {
    super('tru_primaryKey');

    this.sync = {
      tru_primaryKey: null,
      tru_creatorPk: null,
      tru_name: null,
      tru_repType: -1,
      tru_andKwds: null,
      tru_orKwds: null,
      tru_numReps: 0,
      tru_numTimeUnits: 0,
      tru_timeUnit: 1,
      tru_reporterPk: null,
      tru_assignedUsers: null,
      tru_depPk: null,
    };

    this.validateSettings = {
      tru_name: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 3,
        maxLength: SAMA.enums.TEXTLENGTHS.Small,
      }),
      tru_assignedUsers: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.KEYWORD,
        minTags: 1,
        maxTags: 10,
        tagName: 'user',
      }),
      tru_numReps: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.NUMBER,
        minimum: 0,
        mustBeInteger: true,
      }),
      tru_numTimeUnits: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.NUMBER,
        minimum: 0,
        mustBeInteger: true,
      }),
    };

    $.extend(true, this.sync, _databaseData);

    if (this.IsNew()) {
      this.SetPk(cSAMA_Utils.GetUID());
      this.sync.tru_name = 'Untitled Trend Rule #' + _num;
    }

    if (this.sync.tru_depPk == null) {
      this.sync.tru_depPk = -1;
    }
  }
}
