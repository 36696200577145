class vSAMA_TabEditReportCategory extends vSAMA_AbstractTab {
  constructor(_category, _readOnly) {
    super(_category);
    this.SetUpGui('<li><a data-oref="rc_name"></a></li>', SAMA.Templates.tpl.reportCategory.edit, _readOnly);

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    if (cSAMA_Utils.IsTruthy(this.model.sync.kwc_locked)) {
      this.dom.deleteButton.prop('disabled', true);
    }

    this.dom.body.find('[data-sref]').on('change input', () => this.OnValueChange());

    this.dom.body.enhanceWithin();
  }

  OnValueChange() {
    this.UpdateObservers('CategoryEdited');
  }

  OnDelete() {
    this.UpdateObservers('AttemptCategoryDelete', this);
    this.owner.Refresh();
  }

  EnableDeleteButton() {
    this.dom.body.find('.sama-tinyHint').addClass('hidden');

    this.dom.deleteButton.prop('disabled', false);
  }

  ConfirmDelete() {
    super.OnDelete();
  }
}
