/**
 * Base class for all page classes to inherit from
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */
class cSAMA_Request {
  constructor(_topic, _action) {
    this.sync = {
      topic: _topic,
      action: _action,
      data: {},
    };

    this.data = {};

    this.files = [];
  }

  Set(_key, _value) {
    this.sync.data[_key] = _value;
  }

  SetData(_key, _value) {
    this.data[_key] = _value;
  }

  AddFile(_name, _file) {
    this.files.push({ name: _name, data: _file });
  }

  /**
   * Gets the JSON representation of the sync object
   * @return {string}
   */
  GetJson() {
    return JSON.stringify(this.sync);
  }

  /**
   * Gets the JSON representation of the data object
   * @return {string}
   */
  GetData() {
    return JSON.stringify(this.data);
  }

  /**
   * @param {cSAMA_Request[]|cSAMA_Request}   _r.reqs
   * @param {string}                          _r.clientErrorCode
   * @param {function}                        _r.fnSuccess
   * @param {function}                        [_r.fnFail]
   * @param {boolean}                         [_r.showLoading]
   * @param {int}                             [_r.timeout]
   * @param {string}                          [_r.name]               Name the dispatch. Shows up neatly in the
   *   debugger
   * @param {boolean}                         [_r.goBackOnError]      Whether to automatically call GoBack if the
   *   request fails.
   * @param {boolean}                         [_r.keepStateOnError]   Whether to keep the pagestate or change it to
   *   'Ready' when an error occurs.
   * @param {boolean}                         [_r.showErrors]         Whether to annoy the user with error codes if the
   *   transaction fails
   * @param {string}                           [_r.module]            The module the request targets. Ex: 'certificates'
   * @param {string}                           [_r.object]            The object the request targets. Ex: 'subscription'
   * @param {string}                           [_r.action]            The action the request executes.Ex: 'create'
   * @param {boolean}                          [_r.data]              Whether to use the data form data
   */
  static Dispatch(_r) {
    _r.timeout = _r.timeout == null ? 60 : _r.timeout;
    _r.showLoading = _r.showLoading == null ? true : _r.showLoading;
    _r.name = _r.name == null ? 'request' : _r.name;
    _r.goBackOnError = _r.goBackOnError == null ? true : _r.goBackOnError;
    _r.keepStateOnError = _r.keepStateOnError == null ? false : _r.keepStateOnError;
    _r.showErrors = _r.showErrors == null ? false : _r.showErrors;
    _r.module = _r.module == null ? null : _r.module;
    _r.object = _r.object == null ? null : _r.object;
    _r.action = _r.action == null ? null : _r.action;
    _r.data = _r.data == null ? false : _r.data;

    if (!Array.isArray(_r.reqs)) {
      _r.reqs = [_r.reqs];
    }

    if (_r.reqs.length <= 0) {
      console.error('Tried to dispatch an empty request object...');
      return false;
    }

    let fd = new FormData();
    for (let rId = 0; rId < _r.reqs.length; rId++) {
      let req = _r.reqs[rId];
      for (let n = 0; n < req.files.length; n++) {
        // If the request contains files, add them to the form-data object and associate their ID with the request
        // to restore the association server side.
        fd.append('r%f%'.tr(rId, n), req.files[n].data, req.files[n].name);
      }
      fd.append('request[]', req.GetJson());

      if (_r.data) {
        fd.append('data', req.GetData());
      }
    }

    // Check if not SAMA req (if module/object/action exists)
    let ajaxUrl = '';
    if (_r.module !== null) {
      ajaxUrl = `${BASEURL}api/v1/${_r.module}/${_r.object}/${_r.action}`;
    } else {
      ajaxUrl = cSAMA_Utils.GetAjaxPage() + '?r=' + _r.name;
    }

    let ajaxOptions = {
      url: ajaxUrl,
      timeout: _r.timeout * 1000,
      type: 'POST',
      data: fd,
      cache: false,
      contentType: false,
      processData: false,
    };

    if (_r.showLoading) {
      SAMA.GeneralGui.ShowLoadIndicator(_r.name);
    }

    $.ajax(ajaxOptions)
      .complete(() => cSAMA_Request.OnDone(_r))
      .success((_payload) => cSAMA_Request.OnSuccess(_r, _payload))
      .fail((_jqXHR, _status, _error) => cSAMA_Request.OnFail(_r, _jqXHR, _status, _error));
  }

  static OnDone(_r) {
    if (_r.showLoading) {
      SAMA.GeneralGui.HideLoadIndicator(_r.name);
    }
  }

  static OnSuccess(_r, _payload) {
    // Looks like we got a very basic response...
    if (typeof _payload === 'string') {
      try {
        _payload = JSON.parse(_payload);
      } catch (_e) {
        _payload = {
          ServerResponse: 'Exception',
          Payload: null,
        };
      }
    }

    // Looks like we didnt even get an error code from the server!
    if (_payload.Payload == null) {
      _payload.Payload = 'SAMA 59 D2 31 4F';
    }

    // Yay got a response!
    if (_payload.ServerResponse === 'Ok') {
      try {
        if (_r.fnSuccess != null) {
          _r.fnSuccess(_payload.Payload);
        }
      } catch (_e) {
        console.error('SAMA', _e);
        if (_r.showErrors) {
          return;
        }

        cSAMA_Dialog.ShowDialog({
          title: 'Error',
          message:
            'Could not run receiver method! Please reload the app and report this error to AirSuite. Include this error code: ' +
            _r.clientErrorCode,
        });
      }
      return;
    }

    if (_payload.ServerResponse === 'Not Logged In') {
      NOTLOGGEDIN();
      try {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
      } catch (_e) {}
      return;
    }

    if (!_r.showErrors) {
      return;
    }

    cSAMA_Request.ServerSideError(_r, _payload);
  }

  static ServerSideError(_r, _payload) {
    if (_payload == null || !_payload.hasOwnProperty('Payload')) {
      _payload = {
        Payload: 'E6 B5 DD 87',
      };
    }

    // Lets annoy the user.
    cSAMA_Dialog.ShowDialog({
      title: 'Server Exception',
      message:
        'Sorry, but there was a server side error.<br/>' +
        'Let us help you; please report this to AirSuite and include these error codes:<br/>C / %<br/>S / %'.tr(
          _r.clientErrorCode,
          _payload.Payload
        ),
      closeFn: () => {
        try {
          if (!_r.keepStateOnError) {
            SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
          }
          if (_r.goBackOnError) {
            SAMA.Navigator.GoBack();
          }
        } catch (_e) {
          // Do nothing, just close the dialog.
        }
      },
    });
  }

  static OnFail(_r, _jqXHR, _status, _error) {
    if (_r.fnFail != null) {
      _r.fnFail(_r, _jqXHR, _status, _error);
      return;
    }

    if (!_r.showErrors) {
      return;
    }

    if (!_r.keepStateOnError) {
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
    }

    if (_status === 'error' && _jqXHR.status !== 0) {
      cSAMA_Request.ServerSideError(_r, _jqXHR.responseJSON);
      return;
    }

    let message =
      'Sorry, but there was a network error.<br/>' +
      'Please report this to AirSuite and include this error code:<br/>' +
      _error;

    if (_status === 'timeout') {
      message =
        'It looks like your connection has timed out.<br/>' +
        'Please look for a stable connection before trying again.<br/>';
    }

    cSAMA_Dialog.ShowDialog({
      title: 'Network Failure',
      message: message,
      closeFn: () => {
        try {
          if (!_r.keepStateOnError) {
            SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
          }
          if (_r.goBackOnError) {
            SAMA.Navigator.GoBack();
          }
        } catch (_e) {
          // Do nothing, just close the dialog.
        }
      },
    });
  }
}
