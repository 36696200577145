class vSAMA_ListItemCorrectiveAction extends vSAMA_AbstractListViewItem {
  constructor(_correctiveAction, _highRiskBound, _mediumRiskBound, _readOnly) {
    super(_correctiveAction);

    this.readOnly = _readOnly === true;
    let formatter = {};

    $.extend(true, formatter, this.model.sync);

    this.searchObject.coa_name = '[' + this.searchObject.coa_groupId + '] ' + this.searchObject.coa_name;
    this.searchObject.rep_summary = '[' + this.searchObject.rep_groupReportId + '] ' + this.searchObject.rep_summary;

    formatter.coa_riskClass = 'low';
    if (formatter.coa_riskScore > formatter.mtx_boundsHigh) {
      formatter.coa_riskClass = 'high';
    } else if (formatter.coa_riskScore > formatter.mtx_boundsMedium) {
      formatter.coa_riskClass = 'medium';
    }

    let completed = formatter.coah_action === 'COMPL' || formatter.coah_action === 'CLOSD';
    if (!completed) {
      this.searchObject.coah_action = 'PROGR';
    }

    let m = moment.utc(formatter.coa_dueDate).local();
    let overdue = m.isBefore(moment(), 'week');
    let dueThisWeek = m.isSame(moment(), 'week');

    formatter.coa_dueClass = 'none';
    this.searchObject.coa_overdue = false;
    if (overdue && !completed) {
      formatter.coa_dueClass = 'high';
      this.searchObject.coa_overdue = true;
    } else if (dueThisWeek && !completed) {
      formatter.coa_dueClass = 'medium';
    }

    formatter.coa_dueDate = cSAMA_Utils.NiceDate(formatter.coa_dueDate, true, false, true);

    if (formatter.coa_completedDate == null) {
      formatter.coa_completedDate = 'Pending';
    } else {
      formatter.coa_completedDate = cSAMA_Utils.NiceDate(formatter.coa_completedDate, true, false, true);
    }

    if (formatter.coah_action === 'COMPL') {
      // REOPEN
      formatter.coa_stageDescription = 'Completed';
      formatter.coa_stageIcon = 'check';
    } else if (formatter.coah_action === 'CLOSD') {
      // VIEW
      formatter.coa_stageDescription = 'Closed';
      formatter.coa_stageIcon = 'lock';
    } else {
      // EDIT
      formatter.coa_stageDescription = 'In Progress';
      formatter.coa_stageIcon = 'cog';

      if (formatter.coa_completedDate !== 'Pending') {
        formatter.coa_completedDate = 'Re-Opened';
      }
    }

    this.SetUpGui(SAMA.Templates.tpl.correctiveAction.listItem, formatter);

    if (!this.readOnly) {
      this.CreateButtons(formatter);
    }

    this.dom.buttonBar.enhanceWithin();
  }

  CreateButtons(_data) {
    const mememeString = 'Me Myself I ' + LOCALSTORAGE.chatname;
    const completed = _data.coah_action === 'COMPL' || _data.coah_action === 'CLOSD';

    switch (_data.coa_accessRights) {
      case SAMA.enums.ACCESS.VIEW:
        this.ButtonView();
        break;

      case SAMA.enums.ACCESS.COMPLETE:
        // User can COMPLETE but not EDIT, CLOSE, REOPEN
        this.ButtonView();
        if (!completed) {
          this.AddButton(coSAMA_CorrectiveActions.ButtonComplete(this.model.GetPk(), () => this.NotifyChanged()));
        }
        break;

      case SAMA.enums.ACCESS.EDIT:
        // User can complete, edit, reopen, close
        if (_data.coah_action === 'COMPL') {
          this.ButtonEdit();
          this.AddButton(coSAMA_CorrectiveActions.ButtonReOpen(this.model.GetPk(), () => this.NotifyChanged()));
          this.AddButton(coSAMA_CorrectiveActions.ButtonClose(this.model.GetPk(), () => this.NotifyChanged()));
        } else if (_data.coah_action === 'CLOSD') {
          this.ButtonView();
        } else {
          this.ButtonEdit();
          this.AddButton(coSAMA_CorrectiveActions.ButtonComplete(this.model.GetPk(), () => this.NotifyChanged()));
        }
        break;

      default:
        throw 'There is no access right named ' + _data.coa_accessRights;
    }
  }

  NotifyChanged() {
    this.UpdateObservers('ItemChanged');
  }

  /**
   * Creates a 'View' button
   */
  ButtonView() {
    let btnView = new eSAMA_Button({
      label: 'View',
      icon: 'fa-eye',
    });

    btnView.GetDom().on('click', () => {
      SAMA.Navigator.GoPage(SAMA.pages.CorrectiveActionsView, {
        corrActPk: this.model.GetPk(),
        backPage: SAMA.pages.CorrectiveActionsList,
      });
    });

    this.AddButton(btnView);
  }

  /**
   * Creates an 'Edit' button
   */
  ButtonEdit() {
    let btnEdit = new eSAMA_Button({
      label: 'Edit',
      icon: 'fa-pencil',
      classes: 'adminbg',
    });

    btnEdit.GetDom().on('click', () => {
      SAMA.Navigator.GoPage(SAMA.pages.CorrectiveActionsView, {
        corrActPk: this.model.GetPk(),
        backPage: SAMA.pages.CorrectiveActionsList,
      });
    });

    this.AddButton(btnEdit);
  }
}
