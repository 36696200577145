var dp = null;
var nav = null;
var EventNav = null;
var dpCOPY = null;
var SCHEVENTDATA;
var SCHLASTSAVED;

Date.prototype.stdTimezoneOffset = function () {
  var jan = new Date(this.getFullYear(), 0, 1);
  var jul = new Date(this.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.isDstObserved = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset();
};
Date.prototype.toFutureUTC = function () {
  this.setMinutes(this.getMinutes() + (this.getTimezoneOffset() - this.stdTimezoneOffset()));
};

function SCH_convertUtcToTimezone(timestamp) {
  return VueBridge.dateTimeUtils.convertUtcToTimezone(timestamp, LOCALSTORAGE.SCH_UTCmode);
}

function SCH_getHumanTime(timestamp, convertToTimeZone = true) {
  const date = SCH_convertUtcToTimezone(timestamp);
  return VueBridge.dateTimeUtils.humanTime(date);
}

const SCH_getSchedulerDateString = (date) => SCH_convertUtcToTimezone(date)?.format('YYYY-MM-DD HH:mm:ss');

function BK_timeOffset(timestring) {
  if (SCHSTATE.timezone == 0) {
    return timestring;
  }
  var time = new Date(timestring);
  var now = new Date();
  if ((time.isDstObserved() && now.isDstObserved()) || (!time.isDstObserved() && !now.isDstObserved())) {
    return timestring;
  }
  if (now.isDstObserved && !time.isDstObserved()) {
    time.setHours(time.getHours() + 1);
  } else {
    time.setHours(time.getHours() - 1);
  }
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  if (month < 10) {
    month = '0' + month;
  }
  var day = time.getDate();
  if (day < 10) {
    day = '0' + day;
  }
  var hour = time.getHours();
  if (hour < 10) {
    hour = '0' + hour;
  }
  var minute = time.getMinutes();
  if (minute < 10) {
    minute = '0' + minute;
  }

  return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':00';
}

var SCHSTATE = {
  view: 'Month',
  date: null,
  ctrl: false,
  filter: 0,
  resource: false,
  associated: false,
  groupAC: true,
  groupStaff: true,
  timezone: 0,
  ScrollX: 0,
  ScrollY: 0,
  ResACInfo: false,
  ResStaffInfo: false,
  ACLIST: [],
  activeToast: null,
  BookingAddTo: null,
};

function SCHchangeView(type) {
  //console.log(type);
  localStorageDB.setItem('scheduler-view', type);
  SCHSTATE.view = type;
  createScheduler(type);
}

function CloseSCHFilterSettings() {
  //$('#filter').val('');
  $('#SCHfilterHLDR').hide();
  //dp.events.filter(null);
  $('#SCHFilterHideBTN').hide();
  $('#SCHFilterBTN').show();
  return false;
}

function SCHchangeViewResource() {
  //console.log($('#SCH_VIEW_Resource').is(':checked'));
  if ($('#SCH_VIEW_Resource').is(':checked')) {
    if (nav != null) {
      SCHSTATE.date = nav.selectionStart.value;
    }
    localStorageDB.setItem('scheduler-view-resource', true);
    SCHSTATE.resource = true;
  } else {
    localStorageDB.setItem('scheduler-view-resource', false);
    SCHSTATE.resource = false;
  }
  createScheduler(SCHSTATE.view);
}

function SCHchangeViewAssociated() {
  //console.log($('#SCH_VIEW_Resource').is(':checked'));
  if ($('#SCH_VIEW_Associated').is(':checked')) {
    SCHSTATE.associated = true;
  } else {
    SCHSTATE.associated = false;
  }
  createScheduler(SCHSTATE.view);
}

function SCH_FilterOptions() {
  $.mobile.changePage($('#Schedule_FilterOptions'), {
    reverse: false,
    changeHash: false,
  });
  if (GROUP_DATA.Bases != undefined) {
    //console.log('Received Bases Data');
    $('#SCH_Book_Location').empty();
    $('#SCH_Book_Location').append('<option value="" selected>N/A</option>');
    var BF = '';
    for (var i in GROUP_DATA.Bases) {
      var bases = GROUP_DATA.Bases[i];
      $('#SCH_Book_Location').append('<option value="' + bases.name + '">' + bases.name + '</option>');
      BF += '<label for="SCH_BaseFilter-' + i + '" >' + bases.name + '</label>';
      BF += '<input type="checkbox" id="SCH_BaseFilter-' + i + '" value="' + bases.name + '" />';
    }
    $('#SCH_Filter_BaseHLDR').html(BF);
    $('#SCH_Filter_BaseHLDR').enhanceWithin();
    if (LOCALSTORAGE.BaseFilter !== undefined) {
      if ($("#SCH_Filter_Base option[value='" + LOCALSTORAGE.BaseFilter + "']").length > 0) {
        $('#SCH_Filter_Base').val(LOCALSTORAGE.BaseFilter);
      } else {
        localStorageDB.setItem('BaseFilter', '');
        LOCALSTORAGE.BaseFilter = '';
      }
    }
  } else {
    $('#SCH_Filter_BaseHLDR').html('<b>No Bases Defined</b>');
  }

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    if (e === false) {
      $('#SCH_Filter_ACTypesHLDR').html('<b>No Aircraft In System</b>');
    } else {
      var listtypes = {};
      for (var i in e) {
        var D = e[i];
        if (D.AC_data.Hibrenated == 1) {
          continue;
        }
        listtypes[D.AC_data.AcType] = D.AC_data.AcType;
      }
      var types = {};
      Object.keys(listtypes)
        .sort()
        .forEach(function (key) {
          types[key] = listtypes[key];
        });
      var AF = '';
      var index = 0;
      for (var a in types) {
        AF += '<label for="SCH_ACFilter-' + index + '" >' + types[a] + '</label>';
        AF += '<input type="checkbox" id="SCH_ACFilter-' + index + '" value="' + types[a] + '"/>';
        index++;
      }
      $('#SCH_Filter_ACTypesHLDR').html(AF);
      $('#SCH_Filter_ACTypesHLDR').enhanceWithin();
    }
    RestoreSCH_FilterOptions();
  });
}

function RestoreSCH_FilterOptions() {
  console.log('LoadFilter');

  if (LOCALSTORAGE.SCHfilters != undefined) {
    console.log('RestoreSettings');
    var F = LOCALSTORAGE.SCHfilters;

    if (F.Tentative == 1) {
      $('#SCH_Status_Tentative').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Status_Tentative').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Booked == 1) {
      $('#SCH_Status_Booked').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Status_Booked').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Cancelled == 1) {
      $('#SCH_Status_Cancelled').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Status_Cancelled').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Status == 1) {
      $('#SCH_Section_Status').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Section_Status').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Aircraft == 1) {
      $('#SCH_Section_Aircraft').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Section_Aircraft').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Staff == 1) {
      $('#SCH_Section_Staff').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Section_Staff').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Vehicles == 1) {
      $('#SCH_Section_Vehicles').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Section_Vehicles').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Admin == 1) {
      $('#SCH_StaffType_Admin').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Admin').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Pilot == 1) {
      $('#SCH_StaffType_Pilot').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Pilot').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Engineer == 1) {
      $('#SCH_StaffType_Engineer').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Engineer').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Ground == 1) {
      $('#SCH_StaffType_Ground').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Ground').prop('checked', false).checkboxradio('refresh');
    }

    if (F['Flight Follower'] == 1) {
      $('#SCH_StaffType_Flight_Follower').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Flight_Follower').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Maintenance == 1) {
      $('#SCH_StaffType_Maintenance').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Maintenance').prop('checked', false).checkboxradio('refresh');
    }

    if (F.Medic == 1) {
      $('#SCH_StaffType_Medic').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_StaffType_Medic').prop('checked', false).checkboxradio('refresh');
    }

    if (F.AllBases == 1) {
      $('#SCH_Filter_Base_ALL').prop('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Filter_Base_ALL').prop('checked', false).checkboxradio('refresh');
    }

    if (GROUP_DATA.Bases != undefined) {
      for (var i in GROUP_DATA.Bases) {
        var filteron = false;
        for (var b in F.Bases) {
          if (F.Bases[b] == GROUP_DATA.Bases[i].name) {
            filteron = true;
          }
        }
        if (filteron) {
          $('#SCH_BaseFilter-' + i)
            .prop('checked', true)
            .checkboxradio('refresh');
        } else {
          $('#SCH_BaseFilter-' + i)
            .prop('checked', false)
            .checkboxradio('refresh');
        }
      }
    }
    cirroDB.query('AC', 'ident != ?', '', function (e) {
      if (e !== false) {
        var listtypes = {};
        for (var i in e) {
          var D = e[i];
          if (D.AC_data.Hibrenated == 1) {
            continue;
          }
          listtypes[D.AC_data.AcType] = D.AC_data.AcType;
        }
        var types = {};
        Object.keys(listtypes)
          .sort()
          .forEach(function (key) {
            types[key] = listtypes[key];
          });
        var index = 0;
        for (var a in types) {
          var filteron = false;
          for (var i in F.AcTypes) {
            if (F.AcTypes[i] == types[a]) {
              filteron = true;
            }
          }
          if (filteron) {
            $('#SCH_ACFilter-' + index)
              .prop('checked', true)
              .checkboxradio('refresh');
          } else {
            $('#SCH_ACFilter-' + index)
              .prop('checked', false)
              .checkboxradio('refresh');
          }
          index++;
        }
      }
    });
  } else {
    var F = {
      Tentative: 1,
      Booked: 1,
      Cancelled: 1,
      Status: 1,
      Aircraft: 1,
      Vehicles: 1,
      Staff: 1,
      Admin: 1,
      Pilot: 1,
      Ground: 1,
      Engineer: 1,
      Attendant: 1,
      AllBases: 1,
      Bases: [],
      AcTypes: [],
    };
    if (GROUP_DATA.Bases != undefined) {
      for (var i in GROUP_DATA.Bases) {
        var bases = GROUP_DATA.Bases[i];
        F.Bases.push(bases.name);
      }
    }
    cirroDB.query('AC', 'ident != ?', '', function (e) {
      if (e !== false) {
        var listtypes = {};
        for (var i in e) {
          var D = e[i];
          if (D.AC_data.Hibrenated == 1) {
            continue;
          } else {
            listtypes[D.AC_data.AcType] = D.AC_data.AcType;
          }
        }
        var types = {};
        Object.keys(listtypes)
          .sort()
          .forEach(function (key) {
            types[key] = listtypes[key];
          });
        var AF = '';
        var index = 0;
        for (var a in types) {
          F.AcTypes.push(types[a]);
        }
      }
      LOCALSTORAGE.SCHfilters = F;
      USER_RIGHTS.SCHfilters = JSON.stringify(F);

      RestoreSCH_FilterOptions();
    });
  }
}

function SCH_CheckFilter(Item) {
  var F = {
    Tentative: 1,
    Booked: 1,
    Cancelled: 1,
    Status: 1,
    Aircraft: 1,
    Vehicles: 1,
    Staff: 1,
    Admin: 1,
    Pilot: 1,
    Ground: 1,
    Engineer: 1,
    Attendant: 1,
    AllBases: 1,
    Bases: [],
    AcTypes: [],
  };
  if (LOCALSTORAGE.SCHfilters != undefined) {
    F = LOCALSTORAGE.SCHfilters;
    //console.log(Item);
    var failed = false;
    if (F.Tentative == 0 && Item.Status == 'Tentative') {
      failed = true;
    }
    if (F.Booked == 0 && Item.Status == 'Booked') {
      failed = true;
    }
    if (F.Cancelled == 0 && Item.Status == 'Cancelled') {
      failed = true;
    }
    if (F.AllBases == 0) {
      var FoundBase = false;
      for (var b in F.Bases) {
        if (F.Bases[b] == Item.UNIT) {
          FoundBase = true;
        }
      }
      if (!FoundBase) {
        failed = true;
      }
    }

    if (failed) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function SaveSCH_FilterOptions() {
  var F = {
    Bases: [],
    AcTypes: [],
  };

  if ($('#SCH_Status_Tentative').is(':checked')) {
    F.Tentative = 1;
  } else {
    F.Tentative = 0;
  }

  if ($('#SCH_Status_Booked').is(':checked')) {
    F.Booked = 1;
  } else {
    F.Booked = 0;
  }

  if ($('#SCH_Status_Cancelled').is(':checked')) {
    F.Cancelled = 1;
  } else {
    F.Cancelled = 0;
  }

  if ($('#SCH_Section_Status').is(':checked')) {
    F.Status = 1;
  } else {
    F.Status = 0;
  }

  if ($('#SCH_Section_Aircraft').is(':checked')) {
    F.Aircraft = 1;
  } else {
    F.Aircraft = 0;
  }

  if ($('#SCH_Section_Staff').is(':checked')) {
    F.Staff = 1;
  } else {
    F.Staff = 0;
  }

  if ($('#SCH_Section_Vehicles').is(':checked')) {
    F.Vehicles = 1;
  } else {
    F.Vehicles = 0;
  }

  if ($('#SCH_StaffType_Admin').is(':checked')) {
    F.Admin = 1;
  } else {
    F.Admin = 0;
  }

  if ($('#SCH_StaffType_Pilot').is(':checked')) {
    F.Pilot = 1;
  } else {
    F.Pilot = 0;
  }

  if ($('#SCH_StaffType_Engineer').is(':checked')) {
    F.Engineer = 1;
  } else {
    F.Engineer = 0;
  }

  if ($('#SCH_StaffType_Ground').is(':checked')) {
    F.Ground = 1;
  } else {
    F.Ground = 0;
  }

  if ($('#SCH_StaffType_Attendant').is(':checked')) {
    F.Attendant = 1;
  } else {
    F.Attendant = 0;
  }

  if ($('#SCH_StaffType_Flight_Follower').is(':checked')) {
    F['Flight Follower'] = 1;
  } else {
    F['Flight Follower'] = 0;
  }

  if ($('#SCH_StaffType_Maintenance').is(':checked')) {
    F.Maintenance = 1;
  } else {
    F.Maintenance = 0;
  }

  if ($('#SCH_StaffType_Medic').is(':checked')) {
    F.Medic = 1;
  } else {
    F.Medic = 0;
  }

  if ($('#SCH_Filter_Base_ALL').is(':checked')) {
    F.AllBases = 1;
  } else {
    F.AllBases = 0;
  }

  if (GROUP_DATA.Bases != undefined) {
    for (var i in GROUP_DATA.Bases) {
      if ($('#SCH_BaseFilter-' + i).is(':checked')) {
        F.Bases.push(GROUP_DATA.Bases[i].name);
      }
    }
  } else {
    F.Bases = [];
  }

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    if (e !== false) {
      var listtypes = {};
      for (var i in e) {
        var D = e[i];
        if (D.AC_data.Hibrenated == 1) {
          continue;
        }
        listtypes[D.AC_data.AcType] = D.AC_data.AcType;
      }
      var types = {};
      Object.keys(listtypes)
        .sort()
        .forEach(function (key) {
          types[key] = listtypes[key];
        });
      var index = 0;
      for (var a in types) {
        if ($('#SCH_ACFilter-' + index).is(':checked')) {
          F.AcTypes.push(types[a]);
        }
        index++;
      }
      console.log(F);
      LOCALSTORAGE.SCHfilters = F;
      USER_RIGHTS.SCHfilters = JSON.stringify(F);
      localStorageDB.setItem('SCHfilters', JSON.stringify(F));
      SCHSTATE.ResourceState = undefined;
      SCHschedulerBack();
      //createScheduler(SCHSTATE.view);
      //Manual_ResyncSCHEDULE();
      scheduler();
    }
  });
}

function SCH_FilterOptionsReturn() {
  SaveSCH_FilterOptions();
}

function SCHrefresh() {
  createScheduler(SCHSTATE.view);
}

function SCHnavPREV() {
  switch (SCHSTATE.view) {
    case 'Month':
      dp.startDate = dp.startDate.addMonths(-1).firstDayOfMonth();
      break;
    case 'Week':
      dp.startDate = dp.startDate.addDays(-7).firstDayOfWeek();
      break;
    case 'Day':
      dp.startDate = dp.startDate.addDays(-1);
      break;
  }
  SCHSTATE.date = dp.startDate;
  dp.events.list = [];
  dp.update();
  SCHSTATE.visibleStart = dp.visibleStart().getTime();
  SCHSTATE.visibleEnd = dp.visibleEnd().getTime();
  if (nav !== null) {
    nav.select(dp.startDate);
  } else {
    SCH_LoadEvents();
  }
}

function SCHnavNEXT() {
  switch (SCHSTATE.view) {
    case 'Month':
      dp.startDate = dp.startDate.addMonths(1).firstDayOfMonth();
      break;
    case 'Week':
      dp.startDate = dp.startDate.addDays(7).firstDayOfWeek();
      break;
    case 'Day':
      dp.startDate = dp.startDate.addDays(1);
      break;
  }
  SCHSTATE.date = dp.startDate;
  dp.events.list = [];
  dp.update();
  SCHSTATE.visibleStart = dp.visibleStart().getTime();
  SCHSTATE.visibleEnd = dp.visibleEnd().getTime();
  if (nav !== null) {
    nav.select(dp.startDate);
  } else {
    SCH_LoadEvents();
  }
}

function SCHschedulerBack() {
  $('#SCH_ViewItem').html('');
  $.mobile.changePage($('#Schedule_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function SCHupdate(sback) {
  if (sback === true) {
    SCHschedulerBack();
    dp.events.list = [];
    dp.update();
    Manual_ResyncSCHEDULE();
  } else {
    //console.log('Updating');
    dp.events.list = [];
    dp.update();
    SCH_LoadEvents();
  }
}

function SCHupdateSettings() {
  SCHschedulerBack();
  createScheduler(SCHSTATE.view);
}

function SHC_POP_ACLIST() {
  cirroDB.query('AC', 'ident != ?', '', function (e) {
    ALLAC_data = [];
    if (e === false) {
      e = [];
    } else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data.push(item);
      }
    }
    SCHSTATE.ACLIST = ALLAC_data;
  });
}

var SCH_CatLookup = {};
var SCH_CatNameLookup = {};
function SCH_PopulateCategories() {
  var cats = GROUP_DATA.SchedulerCategories;
  var html = '';
  var legend = '';
  SCH_CatLookup = { 0: '#eeeeee' };
  SCH_CatNameLookup = { 0: 'Not Assigned' };
  if (cats.length > 0) {
    legend +=
      '<table><thead><tr><th>' + iTrans('Category') + '</th><th>' + iTrans('Color') + '</th></tr></thead><tbody>';
    html += '<label for="SCH_CatFilter">' + iTrans('Category') + '</label>';
    html += '<select id="SCH_CatFilter" data-mini="true" onChange="SCH_UpdateCatColor()">';
    html += '<option value="0">' + iTrans('Not Assigned') + '</option>';
    for (var i in cats) {
      var I = cats[i];
      legend += '<tr><td>' + I.category + '</td><td style="background-color:' + I.color + '"></td></tr>';
      SCH_CatLookup[I.PrimaryKey] = I.color;
      SCH_CatNameLookup[I.PrimaryKey] = I.category;
      html +=
        '<option value="' + I.PrimaryKey + '" style="background-color=' + I.color + '">' + I.category + '</option>';
    }
    legend += '</tbody></table><br />';
    html += '</select>';
  }
  $('#SCH_CategorySelectorLegend').html(legend);
  $('#SCH_CategorySelectorHLDR').html(html);
  SCH_UpdateCatColor();
}
function SCH_UpdateCatColor() {
  var cat = $('#SCH_CatFilter').val();
  $('#SCH_CatFilter-button').css('background-color', SCH_CatLookup[cat]);
}
function createSchedulerNav(type) {
  var showMonths = 2;
  var height = window.innerHeight > 0 ? window.innerHeight : screen.height;
  if ((SCHSTATE.resource && height >= 860) || !SCHSTATE.resource) {
    showMonths = 3;
  }
  switch (type) {
    case 'Month':
      nav = new DayPilot.Navigator('nav');
      nav.showMonths = showMonths;
      nav.selectMode = 'month';
      nav.onTimeRangeSelected = function (args) {
        //console.log(args);
        dp.startDate = args.start;
        dp.update();
      };
      nav.onBeforeCellRender = function (args) {
        if (args.cell.isCurrentMonth) {
          args.cell.cssClass = 'current-month';
        }
      };
      nav.init();
      break;
    case 'Week':
      nav = new DayPilot.Navigator('nav');
      nav.showMonths = showMonths;
      nav.selectMode = 'week';
      nav.onTimeRangeSelected = function (args) {
        dp.startDate = args.start;
        dp.update();
      };
      nav.onBeforeCellRender = function (args) {
        if (args.cell.isCurrentMonth) {
          args.cell.cssClass = 'current-month';
        }
      };
      nav.init();
      break;
    case 'Day':
      nav = new DayPilot.Navigator('nav');
      nav.showMonths = showMonths;
      nav.selectMode = 'day';
      nav.onTimeRangeSelected = function (args) {
        dp.startDate = args.start;
        dp.update();
      };
      nav.onBeforeCellRender = function (args) {
        if (args.cell.isCurrentMonth) {
          args.cell.cssClass = 'current-month';
        }
      };
      nav.init();
      break;
  }
  nav.select(SCHSTATE.date);
  nav.onTimeRangeSelected = function (args) {
    //console.log(args);
    SCHSTATE.date = args.start;
    //createScheduler(SCHSTATE.view)
    dp.startDate = args.start;
    dp.events.list = [];
    dp.update();
    SCH_LoadEvents();
  };
}

function SCH_setTimezoneScheduler(refresh = false) {
  const timezone = $('#SCH_Timezone').val();

  const tzData = VueBridge.timezones.default.filter((tz) => tz.id === timezone)[0];
  SCHSTATE.timezoneLabel = tzData.text;
  LOCALSTORAGE.SCH_UTCmode = tzData.id;

  localStorageDB.setItem('SCH_UTCmode', tzData.id);

  // time zone offset
  // this needs to be reversed since moment use
  SCHSTATE.timezone = tzData.offset;

  if (refresh === true) {
    dp.events.list = [];
    dp.update();
    SCH_LoadEvents();
  }

  SCH_SetQuickPickBasedOnTz();
}

function SCH_setTimezoneBooking() {
  const priorTimezone = $('#SCH_Timezone').val();

  const timezone = $('#SCH_Timezone_Booking').val();
  $('#SCH_Timezone').val(timezone).selectmenu('refresh');

  SCH_setTimezoneScheduler(false);

  const bookingData = SCHEVENTDATA;

  const startString = $('#SCH_Book_sDate').val() + ' ' + $('#SCH_Book_sTime').val();
  const utcStartTime = VueBridge.dateTimeUtils.convertTimezoneToUtc(startString, priorTimezone);
  bookingData.start = SCH_getSchedulerDateString(utcStartTime, timezone);

  const endString = $('#SCH_Book_eDate').val() + ' ' + $('#SCH_Book_eTime').val();
  const utcEndTime = VueBridge.dateTimeUtils.convertTimezoneToUtc(endString, priorTimezone);
  bookingData.end = SCH_getSchedulerDateString(utcEndTime, timezone);

  SCHEVENTDATA.TYPE = 'BOOKING';
  var newStart = bookingData.start.split(' ');
  $('#SCH_Book_sDate').val(newStart[0]);
  $('#SCH_Book_sTime').val(newStart[1].substring(0, 5));

  var newEnd = bookingData.end.split(' ');
  $('#SCH_Book_eDate').val(newEnd[0]);
  $('#SCH_Book_eTime').val(newEnd[1].substring(0, 5));

  SCHLASTSAVED = bookingData;
  SCHSaveBooking2(true, false, true);

  SCH_SetQuickPickBasedOnTz(true);
}

function SCH_populateTimeZones() {
  const mainSchedDropdown = $('#SCH_Timezone');
  const bookingDropdown = $('#SCH_Timezone_Booking');

  const tzs = VueBridge.timezones.default;
  for (const tz of tzs) {
    const option = `<option value="${tz.id}">${tz.text}</option>`;
    mainSchedDropdown.append(option);
    bookingDropdown.append(option);
  }

  let timezone = LOCALSTORAGE.SCH_UTCmode;

  const lowerTz = timezone?.toLowerCase();
  if (lowerTz == 'local') {
    // for old setting compatibility
    timezone = moment.tz.guess();
  } else if (lowerTz == 'utc') {
    timezone = 'Etc/UTC';
  } else if (!LOCALSTORAGE.SCH_UTCmode) {
    timezone = VueBridge.dateTimeUtils.currentUsersTimezonePreference();
  }

  mainSchedDropdown.val(timezone).selectmenu('refresh');
  LOCALSTORAGE.SCH_UTCmode = timezone;
  localStorageDB.setItem('SCH_UTCmode', timezone);

  $('[name="tz_quickpick"]').prop('checked', false).checkboxradio('refresh');
  $('#SCH_Timezone_Preference').prop('checked', true).checkboxradio('refresh');

  SCH_SetQuickPickBasedOnTz();
}

function SCH_TZ_Quickpick(quickpick, isBooking = false) {
  // default to the users prefered timezone
  let timezone = null;

  if (quickpick === 'preference') {
    timezone = VueBridge.dateTimeUtils.currentUsersTimezonePreference();
  }

  if (quickpick === 'local') {
    timezone = moment.tz.guess();
  }

  if (quickpick === 'utc') {
    timezone = 'Etc/UTC';
  }

  if (isBooking) {
    $('#SCH_Timezone_Booking').val(timezone).selectmenu('refresh');
    SCH_setTimezoneBooking(false);
  } else {
    $('#SCH_Timezone').val(timezone).selectmenu('refresh');
    SCH_setTimezoneScheduler(true, false);
  }
}

function SCH_SetQuickPickBasedOnTz(isBooking = false) {
  $('[name="tz_quickpick"]').prop('checked', false).checkboxradio('refresh');

  if (isBooking) {
    $('[name="tz_quickpick_booking"]').prop('checked', false).checkboxradio('refresh');
  }

  if (LOCALSTORAGE.SCH_UTCmode === VueBridge.dateTimeUtils.currentUsersTimezonePreference()) {
    $('#SCH_Timezone_Preference').prop('checked', true).checkboxradio('refresh');
    if (isBooking) {
      $('#SCH_Timezone_Preference_Booking').prop('checked', true).checkboxradio('refresh');
    }
    return;
  }

  if (LOCALSTORAGE.SCH_UTCmode === moment.tz.guess()) {
    $('#SCH_Timezone_Local').prop('checked', true).checkboxradio('refresh');
    if (isBooking) {
    }
    return;
  }

  if (LOCALSTORAGE.SCH_UTCmode === 'Etc/UTC') {
    $('#SCH_Timezone_UTC').prop('checked', true).checkboxradio('refresh');
    if (isBooking) {
      $('#SCH_Timezone_UTC_Booking').prop('checked', true).checkboxradio('refresh');
    }
    return;
  }
}

function createScheduler(type) {
  if (LOCALSTORAGE.SyncLength == undefined) {
    LOCALSTORAGE.SyncLength = 2;
  }
  $('#SCH_SyncLength').val(LOCALSTORAGE.SyncLength).selectmenu('refresh');
  if (SCHSTATE.ACLIST.length == 0) {
    SHC_POP_ACLIST();
  }
  if (LOCALSTORAGE.SCHResourceDays == undefined) {
    LOCALSTORAGE.SCHResourceDays = 31;
    localStorageDB.setItem('SCHResourceDays', 31);
  }
  if (LOCALSTORAGE.SCH_UTCmode == undefined) {
    LOCALSTORAGE.SCH_UTCmode = 'Local';
    localStorageDB.setItem('SCH_UTCmode', 'Local');
  }

  SCH_populateTimeZones();
  SCH_setTimezoneScheduler(LOCALSTORAGE.SCH_UTCmode);

  if (dp != null) {
    if (SCHSTATE.resource && SCHSTATE.ResourceState != undefined && dp.resources != undefined) {
      SCHSTATE.ResourceState = dp.resources;
    }
    dp.dispose();
  }
  if (nav != null) {
    nav.dispose();
  }
  var width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  if (width > 800 && !CORDOVA) {
    $('#nav').css({
      float: 'left',
      width: '150px',
    });
    $('#Scheduler').css({
      'margin-right': '-15px',
      'margin-left': '215px',
    });
  } else {
    $('#Scheduler').css({
      'margin-right': '-15px',
      'margin-left': '-15px',
    });
  }
  var ResourceHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  switch (type) {
    case 'Month':
      if (SCHSTATE.resource) {
        dp = new DayPilot.Scheduler('Scheduler');
        dp.theme = 'scheduler_traditional';
        dp.days = LOCALSTORAGE.SCHResourceDays;
        //dp.cellDuration = 1440; // one day
        dp.scale = 'Day';
        dp.heightSpec = 'Max';
        dp.eventHeight = parseInt(LOCALSTORAGE.SCHrheight);
        if (IOS) {
          dp.height = '100%';
        } else {
          dp.height = ResourceHeight - 190;
        }

        dp.rowMarginBottom = 15;
        dp.timeHeaders = [
          {
            groupBy: 'Month',
            format: 'MMMM yyyy',
          },
          {
            groupBy: 'Cell',
            format: 'ddd d',
          },
        ];
        //dp.cellWidthSpec = 'Auto';
        if (width > 1100 && LOCALSTORAGE.SCHResourceDays <= 31) {
          dp.cellWidthSpec = 'Auto';
        } else {
          dp.cellWidth = 40;
        }
        dp.onTimeHeaderClicked = function (args) {
          //console.log(args);
          SCHSTATE.date = args.header.start.value;
          SCHSTATE.view = 'Day';
          $('input[name=SCHVIEW][value=Month]').prop('checked', false).checkboxradio('refresh');
          $('input[name=SCHVIEW][value=Day]').prop('checked', true).checkboxradio('refresh');
          $('input[name=SCHVIEW][value=Week]').prop('checked', false).checkboxradio('refresh');
          createScheduler('Day');
        };
      } else {
        dp = new DayPilot.Month('Scheduler');
        dp.theme = 'month_traditional';
        //dp.eventStackingMode = "Cell"; //only works for 1 day fixed height events
      }
      break;
    case 'Week':
      if (SCHSTATE.resource) {
        dp = new DayPilot.Scheduler('Scheduler');
        dp.theme = 'scheduler_traditional';
        dp.days = 7;
        dp.scale = 'Day';
        dp.rowMarginBottom = 15;
        dp.heightSpec = 'Max';
        dp.eventHeight = parseInt(LOCALSTORAGE.SCHrheight);
        if (IOS) {
          dp.height = '100%';
        } else {
          dp.height = ResourceHeight - 160;
        }
        dp.timeHeaders = [
          {
            groupBy: 'Month',
            format: 'MMMM yyyy',
          },
          {
            groupBy: 'Cell',
            format: 'ddd d',
          },
        ];
        dp.floatingTimeHeaders = true;
        if (width > 800 && !CORDOVA) {
          dp.cellWidthSpec = 'Auto';
        } else {
          dp.cellWidth = 200;
        }

        dp.onTimeHeaderClicked = function (args) {
          //console.log(args);
          SCHSTATE.date = args.header.start.value;
          SCHSTATE.view = 'Day';
          $('input[name=SCHVIEW][value=Month]').prop('checked', false).checkboxradio('refresh');
          $('input[name=SCHVIEW][value=Day]').prop('checked', true).checkboxradio('refresh');
          $('input[name=SCHVIEW][value=Week]').prop('checked', false).checkboxradio('refresh');
          createScheduler('Day');
        };
      } else {
        dp = new DayPilot.Calendar('Scheduler');
        dp.theme = 'calendar_traditional';
        dp.viewType = 'Week';
        dp.heightSpec = 'Full';
        dp.headerDateFormat = 'ddd MMM d';
        dp.columnMarginRight = 15;
        if (width < 800) {
          dp.width = '800px';
        }
        dp.onHeaderClicked = function (args) {
          //console.log(args);
          SCHSTATE.date = args.header.start.value;
          SCHSTATE.view = 'Day';
          $('input[name=SCHVIEW][value=Month]').prop('checked', false).checkboxradio('refresh');
          $('input[name=SCHVIEW][value=Day]').prop('checked', true).checkboxradio('refresh');
          $('input[name=SCHVIEW][value=Week]').prop('checked', false).checkboxradio('refresh');
          createScheduler('Day');
        };
      }
      break;
    case 'Day':
      if (SCHSTATE.resource) {
        dp = new DayPilot.Scheduler('Scheduler');
        dp.theme = 'scheduler_traditional';
        dp.days = 1;
        dp.scale = 'Hour';
        if (width > 800 && !CORDOVA) {
          dp.cellWidthSpec = 'Auto';
        } else {
          dp.cellWidth = 25;
        }
        dp.scale = 'CellDuration';
        dp.heightSpec = 'Max';
        dp.eventHeight = parseInt(LOCALSTORAGE.SCHrheight);
        if (IOS) {
          dp.height = '100%';
        } else {
          dp.height = ResourceHeight - 160;
        }
        dp.timeHeaderCellDuration = 15;
        dp.cellDuration = 15;
        dp.cellHeight = 30;
        dp.hourWidth = 75;
        dp.rowMarginBottom = 15;
        dp.timeHeaders = [
          {
            groupBy: 'Day',
            format: 'ddd-M/d/yyyy',
          },
          {
            groupBy: 'Hour',
          },
          {
            groupBy: 'Cell',
          },
        ];
      } else {
        dp = new DayPilot.Calendar('Scheduler');
        dp.theme = 'calendar_traditional';
        dp.scale = 'Day';
        dp.heightSpec = 'Full';
        dp.cellDuration = 15;
        dp.cellHeight = 30;
        dp.columnMarginRight = 15;
        dp.headerDateFormat = 'ddd MMM d';
      }
      break;
  }

  $('#filter').keyup(function () {
    var query = $(this).val();
    dp.events.filter(query); // see dp.onEventFilter below
  });

  dp.sortDirections = ['desc', 'desc'];
  dp.onEventFilter = function (args) {
    if (args.e.text().toUpperCase().indexOf(args.filter.toUpperCase()) === -1) {
      args.visible = false;
    }
  };

  dp.onBeforeCellRender = function (args) {
    if (SCHSTATE.resource) {
      if (args.cell.resource == 'Aircraft' || args.cell.resource == 'Staff' || args.cell.resource == 'Vehicles') {
        args.cell.html =
          "<div style='white-space: nowrap;text-align: center;'>" + args.cell.start.toString('ddd d') + '</div>';
      }
    }
    if (args.cell.start.getDayOfWeek() === 0 || args.cell.start.getDayOfWeek() === 6) {
      args.cell.backColor = 'WHITESMOKE';
    }
    if (args.cell.start.getDayOfWeek() === 0) {
      args.cell.headerHtml = args.cell.start.toString('MMMM d');
    }
    if (args.cell.start.getTime() === new DayPilot.Date().getDatePart().getTime()) {
      args.cell.backColor = 'rgba(46,165,255,1)';
      ////console.log(args);
    }
  };

  if (SCHSTATE.date == null) {
    dp.startDate = new DayPilot.Date.today();
    SCHSTATE.date = new DayPilot.Date.today();
  } else {
    dp.startDate = SCHSTATE.date;
  }

  dp.bubble = new DayPilot.Bubble({
    cssOnly: true,
    cssClassPrefix: 'bubble_default',
    onLoad: function (args) {
      var ev = args.source;
      args.async = true; // notify manually using .loaded()
      console.log(args);
      var id = args.source.data.id;
      //selectEvents(id);
      // simulating slow server-side load
      setTimeout(function () {
        //console.log('here');
        //console.log(args.source.data.TYPE);
        var start = ev.start().toString('HH:mm');
        var end = ev.end().toString('HH:mm');
        var text = ev.text();

        if (ev.data.overrideBubbleHtml != null) {
          args.html = ev.data.overrideBubbleHtml;
        } else {
          args.html = '<b>' + start + ' --> ' + end + '</b>' + '<br />' + text;
          if (GROUP_DATA.PULSAR_FRMS == 1 && args.source.data.TYPE == 'STAFF') {
            //console.log("Getting FRMS");
            args.html += '<div id="PULSAR_FRMS_BUBBLE"><b>Loading FRMS...</b><br /><br /><br /></div>';
            PULSAR_GetScheduleBubbleInfo(args.source.data, 'PULSAR_FRMS_BUBBLE');
          }
        }

        args.loaded();
      }, 300);
    },
  });
  //dp.layout = "TableBased";
  //dp.RowHeaderWidthAutoFit = true;
  //dp.headerFontSize = "5pt";
  //dp.headerFontFamily = "Titillium Web";
  //dp.theme = "airsuite";
  updateSchedulerListeners(); //update scheduler listeners
  initscheduleSettings(); //load user device specific settings
  dp.allowMultiSelect = true;
  if (!SCHSTATE.resource) {
    dp.init();
    dp.events.filter($('#filter').val());
    SCHSTATE.visibleStart = dp.visibleStart().getTime();
    SCHSTATE.visibleEnd = dp.visibleEnd().getTime();
    setTimeout(function () {
      SCH_LoadEvents();
    }, 100);
  } else {
    setTimeout(function () {
      SCHloadResources();
    }, 100);
  }
  if (hasSchedulerAccess()) {
    dp.contextMenu = new DayPilot.Menu({
      items: [],
      onShow: (args) => {
        let e = args.source;
        args.menu.items = SCH_Get_RC_Options(e.data.raw);
      },
    });
  }
  if (width > 800 && !CORDOVA) {
    setTimeout(function () {
      createSchedulerNav(type);
    }, 150);
  } else {
    nav = null;
  }
}

function SCH_DeleteItem(args) {
  var data = args.source.data;
  var raw = data.raw;
  console.log(data);
  SCHEVENTDATA = raw;
  if (!SCHSTATE.resource) {
    SCHDeleteBooking();
    return;
  }
  SCHSTATE.ScrollX = dp.getScrollX();
  SCHSTATE.ScrollY = dp.getScrollY();
  var Type = data.TYPE;
  if (Type == 'BOOKING') {
    SCHDeleteBooking();
  }

  if (Type == 'STAFF') {
    var StaffList = raw.Associated.BookedStaff;
    var StaffPK = 0;
    for (var i in StaffList) {
      if (StaffList[i].ItemPrimaryKey == data.PrimaryKey) {
        StaffPK = StaffList[i].PrimaryKey;
      }
    }
    SCHremoveStaff(StaffPK, data.PrimaryKey, true);
  }
  if (Type == 'AIRCRAFT') {
    var AcList = raw.Associated.BookedAC;
    var ACPK = 0;
    for (var i in AcList) {
      if (AcList[i].ItemPrimaryKey == data.PrimaryKey) {
        ACPK = AcList[i].PrimaryKey;
      }
    }
    SCHremoveAircraft(ACPK, data.PrimaryKey, true);
  }
  if (Type == 'VEHICLES') {
    var VList = raw.Associated.BookedV;
    var VPK = 0;
    for (var i in VList) {
      if (VList[i].ItemPrimaryKey == data.PrimaryKey) {
        VPK = VList[i].PrimaryKey;
      }
    }
    SCHremoveVehicle(VPK, data.PrimaryKey, true);
  }
  if (Type == undefined) {
    SCHDeleteBookingSTAFF(true);
  }
}

function selectEvents(id) {
  id = id.substr(1);
  var old = {};
  var Color = '#42f4eb';
  var e = dp.events.find('B' + id);
  if (e != null) {
    old.e = e.data.backColor;
    e.data.backColor = Color;
    dp.events.update(e);
  }
  var a = dp.events.find('A' + id);
  if (a != null) {
    old.a = a.data.backColor;
    a.data.backColor = Color;
    dp.events.update(a);
  }
  var s = dp.events.find('S' + id);
  if (s != null) {
    old.s = s.data.backColor;
    s.data.backColor = Color;
    dp.events.update(s);
  }

  setTimeout(function () {
    clearEvents(id, old);
  }, 1000);
}

function clearEvents(id, Old) {
  if (Old.e != undefined) {
    var e = dp.events.find('B' + id);
    e.data.backColor = Old.e;
    dp.events.update(e);
  }
  if (Old.a != undefined) {
    var a = dp.events.find('A' + id);
    a.data.backColor = Old.a;
    dp.events.update(a);
  }
  if (Old.s != undefined) {
    var s = dp.events.find('S' + id);
    s.data.backColor = Old.s;
    dp.events.update(s);
  }
}

function updateSchedulerListeners() {
  dp.onEventClicked = function (args) {
    const { raw } = args.e.data;

    if (raw != null) {
      switch (raw.TYPE) {
        case 'COA':
          // Corrective Actions time, baybe
          SAMA.Navigator.GoPage(SAMA.pages.CorrectiveActionsView, {
            corrActPk: raw.id,
            backPage: scheduler,
          });
          return;
        case 'POG':
          VueBridge.enterVue(
            `/groups/${window.vueApp.groupKey}/safety/policies/objectives/${args.e.data.raw.PrimaryKey}?from-legacy=Schedule_Page`
          );
          return;
        case 'Task':
          let taskId = args.e.data.raw.PrimaryKey;
          if (isNaN(taskId)) {
            taskId = taskId.substring(0, taskId.indexOf('-'));
          }
          VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/tasks/${taskId}/view?from-legacy=Schedule_Page`);
          return;
      }
    }

    if (SCHSTATE.resource) {
      SCHSTATE.ScrollX = dp.getScrollX();
      SCHSTATE.ScrollY = dp.getScrollY();
      //console.log(SCHSTATE);
    } else {
      SCHSTATE.ScrollX = 0;
      SCHSTATE.ScrollY = document.documentElement.scrollTop;
    }

    if (args.e.data.resource != undefined) {
      resource = args.e.data.resource;

      if (ONLINE) {
        if (bookingBelongsToAUser(resource)) {
          if ((chatname == resource && args.e.data.TYPE == undefined) || hasSchedulerAccess('timeoff')) {
            SCHEditEvent(args);
          } else {
            SCHViewEvent(args);
          }
        } else {
          SCHViewEvent(args);
        }
      } else {
        SCHViewEvent(args);
      }
    } else {
      SCHViewEvent(args);
    }
  };

  dp.eventDoubleClickHandling = 'Enabled';
  dp.tapAndHoldTimeout = 300;
  if (ONLINE) {
    dp.onEventDoubleClicked = function (args) {
      if (SCHSTATE.resource) {
        SCHSTATE.ScrollX = dp.getScrollX();
        SCHSTATE.ScrollY = dp.getScrollY();
      } else {
        SCHSTATE.ScrollX = 0;
        SCHSTATE.ScrollY = document.documentElement.scrollTop;
      }

      // Check if the user has admin scheduler permissions
      if (hasSchedulerAccess('admin')) {
        SCHSTATE.resizing = false;
        SCHEditEvent(args);
        return;
      }

      // Make sure a admin or booking manager has access to the current booking on the other pages
      if (!SCHSTATE.resource) {
        if (hasSchedulerAccess('booking')) {
          SCHEditEvent(args);
          return;
        }

        SCHViewEvent(args);
        return;
      }

      // Make sure a non admin user has access to the current booking on the resource page
      if (args.e.data.resource != undefined) {
        if (bookingBelongsToAUser(args.e.data.resource)) {
          if (hasSchedulerAccess('timeoff', args.e.data.resource)) {
            SCHEditEvent(args);
          }
        } else if (hasSchedulerAccess('booking')) {
          SCHEditEvent(args);
        } else {
          SCHViewEvent(args);
        }
      }
    };

    dp.onTimeRangeSelected = function (args) {
      // Check if we're in resource view
      if (SCHSTATE.resource) {
        // Check if the user has any elevated scheduler permissions i.e. admin || timeoff/booking management
        if (hasSchedulerAccess()) {
          getAdminTimeRangeResourceView(args);
        } else {
          getUserTimeRangeResourceView(args);
        }
      } else if (hasSchedulerAccess('booking')) {
        getDefaultBookingDialog(args);
      }
      dp.clearSelection();
    };

    dp.onEventResize = function (args) {
      if (!hasSchedulerAccess()) {
        args.preventDefault();
        return;
      }

      if (SCHSTATE.resource) {
        SCHSTATE.ScrollX = dp.getScrollX();
        SCHSTATE.ScrollY = dp.getScrollY();
        //console.log(SCHSTATE);
      } else {
        SCHSTATE.ScrollX = 0;
        SCHSTATE.ScrollY = document.documentElement.scrollTop;
      }
      SCHSTATE.resizing = true;
      SCHEventResize(args);
    };

    dp.onEventMove = function (args) {
      if (!hasSchedulerAccess('', args.e.data.raw.Booked_By)) {
        args.preventDefault();
        return;
      }

      SCHSTATE.resizing = false;
      if (SCHSTATE.resource) {
        SCHSTATE.ScrollX = dp.getScrollX();
        SCHSTATE.ScrollY = dp.getScrollY();
        //console.log(SCHSTATE);
      } else {
        SCHSTATE.ScrollX = 0;
        SCHSTATE.ScrollY = document.documentElement.scrollTop;
      }
      if (args.ctrl) {
        args.preventDefault();
        SCHcopyEvent(args);
      } else {
        SCHEventResize(args);
      }
    };

    dp.onTimeRangeSelecting = function (args) {
      if (SCHSTATE.resource) {
        args.cssClass = 'range';
        if (SCHSTATE.view == 'Day') {
          var Hours = args.duration.totalHours();
          args.right.enabled = true;
          args.right.html = Hours + ' Hours';
        } else {
          var Days = args.duration.totalDays();
          args.right.enabled = true;
          args.right.html = Days + ' Days';
          //args.allowed = false;
        }
      }
    };

    if (hasSchedulerAccess('admin')) {
      $('#SCH_NewMobileBTN').prop('disabled', false);
    } else {
      //regular users only
      $('#SCH_NewMobileBTN').prop('disabled', true);
    }
  } else {
    //OFFLINE REMOVE LISTENERS!!!
    if (hasSchedulerAccess('admin')) {
      $('#SCH_NewMobileBTN').prop('disabled', true);

      dp.onEventResize = function (args) {
        args.preventDefault();
      };
      dp.onEventMove = function (args) {
        args.preventDefault();
      };
    } else {
      //regular users only
      $('#SCH_NewMobileBTN').prop('disabled', true);
      dp.onEventResize = null;
      dp.onEventMove = null;
    }

    dp.onEventDoubleClicked = null;
    dp.onTimeRangeSelected = null;
    dp.onTimeRangeSelecting = null;
  }
}

function getAdminTimeRangeResourceView(args) {
  if (!args) {
    dp.clearSelection();
    return;
  }

  // Check if the user is an actual admin otherwise follow the flow of a regular user
  if (!hasSchedulerAccess('admin')) {
    getUserTimeRangeResourceView(args);
    return;
  }

  if (args.resource == 'Booked' || args.resource == 'Cancelled' || args.resource == 'Tentative') {
    getDefaultBookingDialog(args);
    return;
  }

  //pop dialog to confirm
  SCHSTATE.resizing = false;

  // Perform admin related duties to create a booking
  //todo query database for scheduled items in range to select from
  var start = args.start.addMinutes(SCHSTATE.timezone).toDate().toISOString().substring(0, 19);
  var end = args.end.addMinutes(SCHSTATE.timezone).toDate().toISOString().substr(0, 19);

  start = start.replace('T', ' ');
  end = end.replace('T', ' ');
  //console.log(start);
  //console.log(end);
  //LOCALSTORAGE.BaseFilter d.UNIT
  //if (LOCALSTORAGE.BaseFilter == "N/A"){}

  //todo improve query time by selecting bases
  var whereClause = null;
  var queryValues = [];
  var FilterVal = $('#SCH_Filter_Base').val();
  if (FilterVal != 'N/A' && FilterVal != undefined && FilterVal != '') {
    whereClause =
      '"UNIT" = ? and (`start` >= ? AND `start` < ? OR `end` < ? AND `end` > ? OR `start` < ? AND `end` > ?)';
    queryValues = [FilterVal, start, end, end, start, start, end];
  } else {
    whereClause = '`start` >= ? AND `start` < ? OR `end` < ? AND `end` > ? OR `start` < ? AND `end` > ?';
    queryValues = [start, end, end, start, start, end];
  }
  SCH_ControlBlock(true);
  cirroDB.query('Scheduler_Bookings', whereClause, queryValues, function (e) {
    SCH_ControlBlock(false);
    var Bookings = e;
    if (e === false) {
      Bookings = [];
    }

    var Bselect = '<label for="SCHAddtoBookingID">Select Action</label><select id="SCHAddtoBookingID">';
    var Rtype = false;
    var type = '';
    if (args.resource != undefined) {
      resource = args.resource;
      type = dp.rows.find(resource).parent().id;
      if (bookingBelongsToAUser(args.resource)) {
        Rtype = true;
      }
    }

    if (!Rtype) {
      Bselect += '<option value="-1">Create New Booking</option>';
    } else {
      Bselect += '<option value="-1">New Staff Availability</option>';
    }

    console.log({ Bookings, args, type });

    for (var i in Bookings) {
      //set default booking 'Extra' parameters if missing from legacy bookings.
      if (Bookings[i].Associated.Extra == undefined) {
        Bookings[i].Associated.Extra = { Base: null };
      }
      if (Bookings[i].Associated.Extra.LockCrew == undefined) {
        Bookings[i].Associated.Extra.LockCrew = false;
        Bookings[i].Associated.Extra.LockAC = false;
      }

      if (Rtype) {
        if (Bookings[i].Associated.Extra.LockCrew == false) {
          Bselect += `<option value="${i}">${Bookings[i].PrimaryKey}: ${Bookings[i].Client_Text} [${Bookings[i].Job_Text}]</option>`;
        } else {
          for (var IC in Bookings[i].Associated.Extra.LockCrew) {
            var CrewPK = Bookings[i].Associated.Extra.LockCrew[IC].UserPK;
            var CrewFound = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === CrewPK);
            if (CrewFound != undefined && CrewFound.chatname == resource) {
              Bselect +=
                '<option value="' +
                i +
                '">' +
                Bookings[i].PrimaryKey +
                ': ' +
                Bookings[i].Client_Text +
                ' [' +
                Bookings[i].Job_Text +
                ']</option>';
            }
          }
        }
      } else if (Bookings[i].Associated.Extra.LockAC != false) {
        for (var AC in Bookings[i].Associated.Extra.LockAC) {
          var AcType = Bookings[i].Associated.Extra.LockAC[AC].AcType;
          var TypeFound = SCHSTATE.ACLIST.filter((item) => item.AcType == AcType);

          if (TypeFound != undefined) {
            for (var TF in TypeFound) {
              if (TypeFound[TF].ident == resource) {
                Bselect += `<option value="${i}">${Bookings[i].PrimaryKey}: ${Bookings[i].Client_Text} [${Bookings[i].Job_Text}]</option>`;
              }
            }
          }
        }
      } else {
        Bselect += `<option value="${i}">${Bookings[i].PrimaryKey}: ${Bookings[i].Client_Text} [${Bookings[i].Job_Text}]</option>`;
      }
    }
    Bselect += '</select>';

    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Select Action',
      headerClose: false,
      buttonPrompt: 'What would you like to do?' + Bselect,
      buttons: {
        Proceed: {
          click: function () {
            if (SCHSTATE.resource) {
              SCHSTATE.ScrollX = dp.getScrollX();
              SCHSTATE.ScrollY = dp.getScrollY();
              //console.log(SCHSTATE);
            } else {
              SCHSTATE.ScrollX = 0;
              SCHSTATE.ScrollY = document.documentElement.scrollTop;
            }
            var index = $('#SCHAddtoBookingID').val();

            if (index == -1) {
              SCH_RangeSelected(args);
            } else {
              var Booking = Bookings[index];
              SCHSTATE.BookingAddTo = Booking;
              var BookingPK = Booking.PrimaryKey;
              console.log(Booking);
              console.log(Rtype);
              if (Booking.Associated.BookedAC.length > 0 && Rtype == true) {
                //prompt to select aircraft to add staff member to
                SCH_PromptSelectAircraftAssignment(args, Booking);
              } else {
                //check if staff assigned to booking and prompt to assign if there is at least one
                if (Rtype == true || Booking.Associated.BookedStaff.length == 0) {
                  SCH_AttachToBooking(args, BookingPK, 0);
                } else {
                  SCH_PromptSelectStaffAssignment(args, Booking);
                }
              }
            }
          },
        },
        Cancel: {
          click: function () {
            //File bug report
            dp.clearSelection();
          },
        },
      },
    });
  });
}

function getDefaultBookingDialog(args) {
  if (!args) {
    dp.clearSelection();
    return;
  }

  // Show a dialog if no specific action must take place to create a booking i.e admin specific activities
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'New Booking',
    headerClose: false,
    buttonPrompt: 'Do you wish to create a new booking?',
    buttons: {
      Yes: {
        click: function () {
          if (SCHSTATE.resource) {
            SCHSTATE.ScrollX = dp.getScrollX();
            SCHSTATE.ScrollY = dp.getScrollY();
            //console.log(SCHSTATE);
          } else {
            SCHSTATE.ScrollX = 0;
            SCHSTATE.ScrollY = document.documentElement.scrollTop;
          }
          SCH_RangeSelected(args);
          dp.clearSelection();
        },
      },
      Cancel: {
        click: function () {
          //File bug report
          dp.clearSelection();
        },
      },
    },
  });
}

function getUserTimeRangeResourceView(args) {
  if (!args) {
    dp.clearSelection();
    return;
  }

  //only allow editing of self
  if (SCHSTATE.resource) {
    SCHSTATE.ScrollX = dp.getScrollX();
    SCHSTATE.ScrollY = dp.getScrollY();
  } else {
    SCHSTATE.ScrollX = 0;
    SCHSTATE.ScrollY = document.documentElement.scrollTop;
  }

  if (args.resource != undefined) {
    if (bookingBelongsToAUser(args.resource)) {
      if (hasSchedulerAccess('timeoff', args.resource)) {
        // Create a booking for a person if the current user matches the user in the scheduler or if they have time off
        // management permissions
        SCH_RangeSelected(args);
      }
    } else if (hasSchedulerAccess('booking')) {
      // Create a booking for a non user if the current user has booking management permissions
      getDefaultBookingDialog(args);
    }
  }
  dp.clearSelection();
}

function bookingBelongsToAUser(eventResource) {
  if (!eventResource) {
    return false;
  }

  // Get the booking type from the daypilot object
  let type = dp.rows.find(eventResource).parent().id;

  return (
    type == 'Pilot' ||
    type == 'NASP SO' ||
    type == 'Rescue Specialist' ||
    type == 'Engineer' ||
    type == 'Admin' ||
    type == 'Attendant' ||
    type == 'Staff' ||
    type == 'Ground' ||
    type == 'Flight Follower' ||
    type == 'Maintenance' ||
    type == 'Medic'
  );
}

function hasSchedulerAccess(accessType = '', eventResource = null) {
  // Check if a resource aka username was passed and compare the current user to the booking user
  if (eventResource) {
    return chatname == eventResource ? true : hasSchedulerAccess(accessType);
  }

  // Check if an accesstype wasn't supplied and return true for any higher level permissions
  if (!accessType) {
    return SCHEDULER_ACCESS == 1 || TIMEOFF_ACCESS == 1 || BOOKING_ACCESS == 1;
  }

  let access = false;

  switch (accessType.toLowerCase()) {
    case 'timeoff':
      access = TIMEOFF_ACCESS == 1;
      break;
    case 'booking':
      access = BOOKING_ACCESS == 1;
      break;
    case 'admin':
      return SCHEDULER_ACCESS == 1;
  }

  return SCHEDULER_ACCESS == 1 || access;
}

function SCHloadResources() {
  $.mobile.loading('show', {
    theme: 'a',
  });

  dbSchedulerData();
}

function SCHsearch() {
  $('#SCHfilterHLDR').show();
  $('#SCHFilterHideBTN').show();
  $('#SCHFilterBTN').hide();
}

function initscheduleSettings() {
  //INIT Settings ************************************************************
  if (LOCALSTORAGE.SCHResourceDays == undefined) {
    localStorageDB.setItem('SCHResourceDays', 31);
    LOCALSTORAGE.SCHResourceDays = 31;
  }
  var font = LOCALSTORAGE.SCHfont;
  if (font == undefined) {
    font = 10;
    localStorageDB.setItem('SCHfont', font);
  }
  SCHSTATE.font = font;

  var width = LOCALSTORAGE.SCHwidth;
  if (width == undefined) {
    width = 40;
  }
  //dp.columnWidth = parseInt(width);
  //dp.columnWidthSpec = "Fixed";

  var height = LOCALSTORAGE.SCHheight;
  if (height == undefined) {
    height = 100;
  }

  var rheight = LOCALSTORAGE.SCHrheight;
  if (rheight == undefined) {
    rheight = 100;
    localStorageDB.setItem('SCHrheight', rheight);
  }
  if (SCHSTATE.resource) {
    dp.eventHeight = 25;
  } else {
    dp.eventHeight = parseInt(height);
  }

  dp.headerHeight = 20;

  var beginHour = LOCALSTORAGE.SCHstart;
  if (beginHour != undefined) {
    beginHour = beginHour.substring(0, 2);
  } else {
    beginHour = 0;
  }
  var endHour = LOCALSTORAGE.SCHend;
  if (endHour != undefined) {
    endHour = endHour.substring(0, 2);
  } else {
    endHour = 24;
  }
  dp.dayBeginsHour = parseInt(beginHour);
  dp.dayEndsHour = parseInt(endHour);
  dp.businessBeginsHour = parseInt(beginHour);
  dp.businessEndsHour = parseInt(endHour);

  var showNB = LOCALSTORAGE.SCHbushour;
  if (showNB == 'true') {
    dp.showNonBusiness = false;
    dp.businessWeekends = true;
  } else {
    dp.showNonBusiness = true;
  }

  var MyEvents = LOCALSTORAGE.SCHMyEvents;
  if (MyEvents == 'true') {
    SCHSTATE.filter = 1;
  } else {
    SCHSTATE.filter = 0;
  }

  var showClient = LOCALSTORAGE.SCHshowClient;
  if (showClient == undefined) {
    showClient = 'true';
    localStorageDB.setItem('SCHshowClient', true);
  }
  SCHSTATE.showClient = showClient;

  var showJob = LOCALSTORAGE.SCHshowJob;
  if (showJob == undefined) {
    showJob = 'true';
    localStorageDB.setItem('SCHshowJob', true);
  }
  SCHSTATE.showJob = showJob;

  var showUNIT = LOCALSTORAGE.SCHshowUNIT;
  if (showUNIT == undefined) {
    showUNIT = 'true';
    localStorageDB.setItem('SCHshowUNIT', true);
  }
  SCHSTATE.showUNIT = showUNIT;

  var showFlightReports = LOCALSTORAGE.SCHshowFlightReports;
  if (showFlightReports == undefined) {
    showFlightReports = 'true';
    localStorageDB.setItem('showFlightReports', true);
  }
  SCHSTATE.showFlightReports = showFlightReports;

  var showAircraft = LOCALSTORAGE.SCHshowAircraft;
  if (showAircraft == undefined) {
    showAircraft = 'true';
    localStorageDB.setItem('SCHshowAircraft', true);
  }
  SCHSTATE.showAircraft = showAircraft;

  var showAircraftType = LOCALSTORAGE.SCHshowAircraftType;
  if (showAircraftType == undefined) {
    showAircraftType = 'true';
    localStorageDB.setItem('SCHshowAircraftType', true);
  }
  SCHSTATE.showAircraftType = showAircraftType;

  var showStaff = LOCALSTORAGE.SCHshowStaff;
  if (showStaff == undefined) {
    showStaff = 'true';
    localStorageDB.setItem('SCHshowStaff', true);
  }
  SCHSTATE.showStaff = showStaff;

  var showStaffAC = LOCALSTORAGE.SCHshowStaffAC;
  if (showStaffAC == undefined) {
    showStaffAC = 'true';
    localStorageDB.setItem('SCHshowStaffAC', true);
  }
  SCHSTATE.showStaffAC = showStaffAC;

  var showVehicles = LOCALSTORAGE.SCHshowVehicles;
  if (showVehicles == undefined) {
    showVehicles = 'true';
    localStorageDB.setItem('SCHshowVehicles', true);
  }
  SCHSTATE.showVehicles = showVehicles;

  if (SAMA_ENABLED != '0') {
    SCHSTATE.SAMACorrActs = LOCALSTORAGE.SCHSAMACorrActs;
    if (SCHSTATE.SAMACorrActs == undefined) {
      SCHSTATE.SAMACorrActs = true;
      localStorageDB.setItem('SCHSAMACorrActs', SCHSTATE.SAMACorrActs);
    }

    SCHSTATE.SAMAPog = LOCALSTORAGE.SCHSAMAPog;
    if (SCHSTATE.SAMAPog == undefined) {
      SCHSTATE.SAMAPog = true;
      localStorageDB.setItem('SCHSAMAPog', SCHSTATE.SAMAPog);
    }
  } else {
    SCHSTATE.SAMACorrActs = false;
    SCHSTATE.SAMAPog = false;

    localStorageDB.setItem('SCHSAMACorrActs', SCHSTATE.SAMAPog);
    localStorageDB.setItem('SCHSAMACorrActs', SCHSTATE.SAMACorrActs);
  }

  var groupAC = LOCALSTORAGE.SCHgroupAC;
  if (groupAC == undefined) {
    groupAC = 'true';
    localStorageDB.setItem('SCHgroupAC', true);
  }
  SCHSTATE.groupAC = groupAC;

  var groupStaff = LOCALSTORAGE.SCHgroupStaff;
  if (groupStaff == undefined) {
    groupStaff = 'true';
    localStorageDB.setItem('SCHgroupStaff', true);
  }
  SCHSTATE.groupStaff = groupStaff;

  var ResACInfo = LOCALSTORAGE.SCHACInfo;
  if (ResACInfo == undefined) {
    ResACInfo = 'false';
    localStorageDB.setItem('SCHACInfo', false);
  }
  SCHSTATE.ResACInfo = ResACInfo;

  var ResStaffInfo = LOCALSTORAGE.SCHStaffInfo;
  if (ResStaffInfo == undefined) {
    ResStaffInfo = 'false';
    localStorageDB.setItem('SCHStaffInfo', false);
  }
  SCHSTATE.ResStaffInfo = ResStaffInfo;
  //refresh local storage
  localStorageDB.getAll(function (e) {
    LOCALSTORAGE = e;
  });
}

function SCH_ApplySettings() {
  localStorageDB.getAll(function (e) {
    LOCALSTORAGE = e;
    initscheduleSettings();
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Success',
      headerClose: false,
      buttonPrompt: 'Scheduler options applied successfully.',
      buttons: {
        OK: {
          click: function () {
            SCHupdateSettings();
          },
        },
      },
    });
  });
}

function scheduleSettings() {
  $.mobile.changePage($('#Schedule_Options'), {
    reverse: false,
    changeHash: false,
  });
  localStorageDB.getAll(function (e) {
    LOCALSTORAGE = e;

    $('#schedSettingContent').html('');
    var html = '<table class="limit-table"><tr><td>';

    html += '<button onClick="SCH_ApplySettings();" data-mini="true" data-icon="refresh">Apply Changes</button>';
    //html += '<label for="SCHwidth"><b>Event Width:</b></label><input type="range" name="SCHwidth" id="SCHwidth" value="'+dp.columnWidth+'" min="10" max="2000" data-highlight="false" />';

    html +=
      '<label for="SCHfont"><b>Font Size:</b></label><input type="range" name="SCHfont" id="SCHfont" value="' +
      SCHSTATE.font +
      '" min="5" max="13" step="0.1" data-highlight="false" />';
    if (SCHSTATE.resource) {
      html +=
        '<label for="SCHheight"><b>Resource Height:</b></label><input type="range" name="SCHheight" id="SCHheight" value="' +
        LOCALSTORAGE.SCHrheight +
        '" min="25" max="300" data-highlight="false" />';
    } else {
      html +=
        '<label for="SCHheight"><b>Event Height:</b></label><input type="range" name="SCHheight" id="SCHheight" value="' +
        dp.eventHeight +
        '" min="25" max="300" data-highlight="false" />';
    }

    var BH = LOCALSTORAGE.SCHbushour;
    if (BH == 'true') {
      html +=
        '<label for="SCHbushour">Show Business Hours Only</label><input type="checkbox" data-mini="true"  id="SCHbushour" onClick="SCHbushour()" Checked>';
    } else {
      html +=
        '<label for="SCHbushour">Show Business Hours Only</label><input type="checkbox" data-mini="true" id="SCHbushour" onClick="SCHbushour()">';
    }

    var Start = LOCALSTORAGE.SCHstart;
    var End = LOCALSTORAGE.SCHend;
    html += '<div id="SCHbushourON">';

    if (Start != null) {
      html += '<label for="SCHstart">Daily Start Time</label><input type="time" id="SCHstart" value="' + Start + '">';
    } else {
      html += '<label for="SCHstart">Daily Start Time</label><input type="time" id="SCHstart">';
    }

    if (End != null) {
      html += '<label for="SCHend">Daily End Time</label><input type="time" id="SCHend" value="' + End + '">';
    } else {
      html += '<label for="SCHend">Daily End Time</label><input type="time" id="SCHend">';
    }

    html += '</div>';

    var MyEvents = LOCALSTORAGE.SCHMyEvents;
    if (MyEvents == 'true') {
      html +=
        '<label for="SCHMyEventsCHK">Show My Bookings Only</label><input type="checkbox" data-mini="true" id="SCHMyEventsCHK" onClick="SCHMyEvents()" Checked>';
    } else {
      html +=
        '<label for="SCHMyEventsCHK">Show My Bookings Only</label><input type="checkbox" data-mini="true" id="SCHMyEventsCHK" onClick="SCHMyEvents()">';
    }

    var VisibleOnly = LOCALSTORAGE.SCHVisibleOnly;
    if (VisibleOnly == 'true') {
      html +=
        '<label for="SCHVisibleOnlyCHK">Show Visible Assignments Only</label><input type="checkbox" data-mini="true" id="SCHVisibleOnlyCHK" onClick="SCHVisibleOnly()" Checked>';
    } else {
      html +=
        '<label for="SCHVisibleOnlyCHK">Show Visible Assignments Only</label><input type="checkbox" data-mini="true" id="SCHVisibleOnlyCHK" onClick="SCHVisibleOnly()">';
    }

    var ShowUniqueID = LOCALSTORAGE.SCHShowUniqueID;
    if (ShowUniqueID == 'true') {
      html +=
        '<label for="SCHShowUniqueIDCHK">Show Booking #</label><input type="checkbox" data-mini="true" id="SCHShowUniqueIDCHK" onClick="SCHShowUniqueID()" Checked>';
    } else {
      html +=
        '<label for="SCHShowUniqueIDCHK">Show Booking #</label><input type="checkbox" data-mini="true" id="SCHShowUniqueIDCHK" onClick="SCHShowUniqueID()">';
    }

    html += '<h3>Displayed Booking Info</h3>';

    if (SCHSTATE.showClient == 'true') {
      html +=
        '<label for="SCHshowClient">Client Name</label><input type="checkbox" data-mini="true" id="SCHshowClient" onClick="SCHshowClient()" Checked>';
    } else {
      html +=
        '<label for="SCHshowClient">Client Name</label><input type="checkbox" data-mini="true" id="SCHshowClient" onClick="SCHshowClient()">';
    }

    if (SCHSTATE.showJob == 'true') {
      html +=
        '<label for="SCHshowJob">Job Name</label><input type="checkbox" data-mini="true" id="SCHshowJob" onClick="SCHshowJob()" Checked>';
    } else {
      html +=
        '<label for="SCHshowJob">Job Name</label><input type="checkbox" data-mini="true" id="SCHshowJob" onClick="SCHshowJob()">';
    }

    if (SCHSTATE.showUNIT == 'true') {
      html +=
        '<label for="SCHshowUNIT">Company Base</label><input type="checkbox" data-mini="true" id="SCHshowUNIT" onClick="SCHshowUNIT()" Checked>';
    } else {
      html +=
        '<label for="SCHshowUNIT">Company Base</label><input type="checkbox" data-mini="true" id="SCHshowUNIT" onClick="SCHshowUNIT()">';
    }

    if (SCHSTATE.showFlightReports == 'true') {
      html +=
        '<label for="SCHshowFlightReports">Flight Reports</label><input type="checkbox" data-mini="true" id="SCHshowFlightReports" onClick="SCHshowFlightReports()" Checked>';
    } else {
      html +=
        '<label for="SCHshowFlightReports">Flight Reports</label><input type="checkbox" data-mini="true" id="SCHshowFlightReports" onClick="SCHshowFlightReports()">';
    }

    if (SCHSTATE.showAircraft == 'true') {
      html +=
        '<label for="SCHshowAircraft">Aircraft Ident</label><input type="checkbox" data-mini="true" id="SCHshowAircraft" onClick="SCHshowAircraft()" Checked>';
    } else {
      html +=
        '<label for="SCHshowAircraft">Aircraft Ident</label><input type="checkbox" data-mini="true" id="SCHshowAircraft" onClick="SCHshowAircraft()">';
    }

    if (SCHSTATE.showAircraftType == 'true') {
      html +=
        '<label for="SCHshowAircraftType">Aircraft Type</label><input type="checkbox" data-mini="true" id="SCHshowAircraftType" onClick="SCHshowAircraftType()" Checked>';
    } else {
      html +=
        '<label for="SCHshowAircraftType">Aircraft Type</label><input type="checkbox" data-mini="true" id="SCHshowAircraftType" onClick="SCHshowAircraftType()">';
    }

    if (SCHSTATE.showStaff == 'true') {
      html +=
        '<label for="SCHshowStaff">Staff Name</label><input type="checkbox" data-mini="true" id="SCHshowStaff" onClick="SCHshowStaff()" Checked>';
    } else {
      html +=
        '<label for="SCHshowStaff">Staff Name</label><input type="checkbox" data-mini="true" id="SCHshowStaff" onClick="SCHshowStaff()">';
    }

    if (SCHSTATE.showStaffAC == 'true') {
      html +=
        '<label for="SCHshowStaffAC">Staff Aircraft</label><input type="checkbox" data-mini="true" id="SCHshowStaffAC" onClick="SCHshowStaffAC()" Checked>';
    } else {
      html +=
        '<label for="SCHshowStaffAC">Staff Aircraft</label><input type="checkbox" data-mini="true" id="SCHshowStaffAC" onClick="SCHshowStaffAC()">';
    }

    if (SCHSTATE.showVehicles == 'true') {
      html +=
        '<label for="SCHshowVehicles">Vehicle Ident</label><input type="checkbox" data-mini="true" id="SCHshowVehicles" onClick="SCHshowVehicles()" Checked>';
    } else {
      html +=
        '<label for="SCHshowVehicles">Vehicle Ident</label><input type="checkbox" data-mini="true" id="SCHshowVehicles" onClick="SCHshowVehicles()">';
    }

    if (SAMA_ENABLED != '0') {
      html += '<h3>Displayed SMS Dates</h3>';

      html +=
        '<label for="SCHSAMACorrActs">Display Dates for Corrective Actions</label><input type="checkbox" data-mini="true" id="SCHSAMACorrActs" onClick="SCHSetSamaState()" % >'.tr(
          SCHSTATE.SAMACorrActs == 'true' ? 'checked' : ''
        );

      html +=
        '<label for="SCHSAMAPog">Display Dates for Policy Objectives</label><input type="checkbox" data-mini="true" id="SCHSAMAPog" onClick="SCHSetSamaState()" % >'.tr(
          SCHSTATE.SAMAPog == 'true' ? 'checked' : ''
        );
    }

    html += '<h3>Resource Mode Options</h3>';

    if (SCHSTATE.groupAC == 'true') {
      html +=
        '<label for="SCHgroupAC">Group Aircraft Types</label><input type="checkbox" data-mini="true" id="SCHgroupAC" onClick="SCHgroupAC()" Checked>';
    } else {
      html +=
        '<label for="SCHgroupAC">Group Aircraft Types</label><input type="checkbox" data-mini="true" id="SCHgroupAC" onClick="SCHgroupAC()">';
    }

    if (SCHSTATE.groupStaff == 'true') {
      html +=
        '<label for="SCHgroupStaff">Group Staff Types</label><input type="checkbox" data-mini="true" id="SCHgroupStaff" onClick="SCHgroupStaff()" Checked>';
    } else {
      html +=
        '<label for="SCHgroupStaff">Group Staff Types</label><input type="checkbox" data-mini="true" id="SCHgroupStaff" onClick="SCHgroupStaff()">';
    }

    if (SCHSTATE.ResACInfo == 'true') {
      html +=
        '<label for="SCHACInfo">Display Booking Info for Aircraft</label><input type="checkbox" data-mini="true" id="SCHACInfo" onClick="SCHResACInfo()" Checked>';
    } else {
      html +=
        '<label for="SCHACInfo">Display Booking Info for Aircraft</label><input type="checkbox" data-mini="true" id="SCHACInfo" onClick="SCHResACInfo()">';
    }

    if (SCHSTATE.ResStaffInfo == 'true') {
      html +=
        '<label for="SCHStaffInfo">Display Booking Info for Staff</label><input type="checkbox" data-mini="true" id="SCHStaffInfo" onClick="SCHResStaffInfo()" Checked>';
    } else {
      html +=
        '<label for="SCHStaffInfo">Display Booking Info for Staff</label><input type="checkbox" data-mini="true" id="SCHStaffInfo" onClick="SCHResStaffInfo()">';
    }

    html +=
      '<label for="SCHResourceDays">Number of days to display in resource Month view</label><input id="SCHResourceDays" type="number" min="31" max="180" value="' +
      LOCALSTORAGE.SCHResourceDays +
      '" onChange="SCHResDays()" />';

    html += '</tr></td></table>';
    $('#schedSettingContent').html(html);
    if (BH != 'true') {
      $('#SCHbushourON').hide();
    }
    $('#schedSettingContent').enhanceWithin();
    //$("#SCHwidth").on("change",SCHcellWidth);
    $('#SCHfont').on('change', SCHfont);
    $('#SCHheight').on('change', SCHeventHeight);
    $('#SCHstart').on('change', SCHstart);
    $('#SCHend').on('change', SCHend);
  });
}

function SCHResDays() {
  var days = $('#SCHResourceDays').val();
  if (days == '') {
    days = 31;
  }
  if (days < 31) {
    days = 31;
  }
  if (days > 180) {
    days = 180;
  }
  LOCALSTORAGE.SCHResourceDays = days;
  localStorageDB.setItem('SCHResourceDays', days);
}

function SCHSetSamaState() {
  var coaState = $('#SCHSAMACorrActs').is(':checked');
  var pogState = $('#SCHSAMAPog').is(':checked');

  localStorageDB.setItem('SCHSAMACorrActs', coaState);
  localStorageDB.setItem('SCHSAMAPog', pogState);
}

function SCHResACInfo() {
  localStorageDB.setItem('SCHACInfo', $('#SCHACInfo').is(':checked'));
}

function SCHResStaffInfo() {
  localStorageDB.setItem('SCHStaffInfo', $('#SCHStaffInfo').is(':checked'));
}

function SCHshowClient() {
  localStorageDB.setItem('SCHshowClient', $('#SCHshowClient').is(':checked'));
}

function SCHshowJob() {
  localStorageDB.setItem('SCHshowJob', $('#SCHshowJob').is(':checked'));
}

function SCHshowUNIT() {
  localStorageDB.setItem('SCHshowUNIT', $('#SCHshowUNIT').is(':checked'));
}

function SCHshowFlightReports() {
  localStorageDB.setItem('SCHshowFlightReports', $('#SCHshowFlightReports').is(':checked'));
}

function SCHshowAircraft() {
  localStorageDB.setItem('SCHshowAircraft', $('#SCHshowAircraft').is(':checked'));
}

function SCHshowVehicles() {
  localStorageDB.setItem('SCHshowVehicles', $('#SCHshowVehicles').is(':checked'));
}

function SCHshowAircraftType() {
  localStorageDB.setItem('SCHshowAircraftType', $('#SCHshowAircraftType').is(':checked'));
}

function SCHshowStaff() {
  localStorageDB.setItem('SCHshowStaff', $('#SCHshowStaff').is(':checked'));
}

function SCHshowStaffAC() {
  localStorageDB.setItem('SCHshowStaffAC', $('#SCHshowStaffAC').is(':checked'));
}

function SCHMyEvents() {
  localStorageDB.setItem('SCHMyEvents', $('#SCHMyEventsCHK').is(':checked'));
}

function SCHVisibleOnly() {
  localStorageDB.setItem('SCHVisibleOnly', $('#SCHVisibleOnlyCHK').is(':checked'));
}

function SCHShowUniqueID() {
  localStorageDB.setItem('SCHShowUniqueID', $('#SCHShowUniqueIDCHK').is(':checked'));
}

function SCHgroupAC() {
  localStorageDB.setItem('SCHgroupAC', $('#SCHgroupAC').is(':checked'));
  SCHSTATE.ResourceState = undefined;
}

function SCHgroupStaff() {
  localStorageDB.setItem('SCHgroupStaff', $('#SCHgroupStaff').is(':checked'));
  SCHSTATE.ResourceState = undefined;
}

function SCHstart() {
  var start = this.value;
  localStorageDB.setItem('SCHstart', start);
}

function SCHend() {
  var end = this.value;
  localStorageDB.setItem('SCHend', end);
}

function SCHbushour() {
  if ($('#SCHbushour').is(':checked')) {
    $('#SCHbushourON').show();
  } else {
    $('#SCHbushourON').hide();
  }
  localStorageDB.setItem('SCHbushour', $('#SCHbushour').is(':checked'));
}

function SCHfont() {
  var newFont = parseInt(this.value);
  localStorageDB.setItem('SCHfont', newFont);
}

function SCHcellWidth() {
  //var start = dp.getViewPort().start;
  var newWidth = parseInt(this.value);
  localStorageDB.setItem('SCHwidth', newWidth);
}

function SCHeventHeight() {
  //var start = dp.getViewPort().start;
  var newHeight = parseInt(this.value);
  if (SCHSTATE.resource) {
    localStorageDB.setItem('SCHrheight', newHeight);
  } else {
    localStorageDB.setItem('SCHheight', newHeight);
  }
}

function SCHViewEvent(args) {
  setTimeout(function () {
    SCHViewEventDelayed(args);
  }, 100);
}

function SCHViewEventDelayed(args, _scrollTo = 0) {
  $('#SCH_Book_Aircraft').html('');
  $('#SCH_Book_Staff').html('');

  $.mobile.changePage($('#Schedule_ViewItem'), {
    reverse: false,
    changeHash: false,
  });

  window.scrollTo(0, _scrollTo);
  var raw = args.e.data.raw;
  SCHEVENTDATA = raw;
  var d = raw;

  var html = '<table class="limit-table"><tr><td>';
  html += GetDetailMarkup(d);

  if (d.Associated.BookedAC.length > 0) {
    html += '<h3>Aircraft</h3>';
    html +=
      '<table class="limit-table last-td-fill" id="SCH_AircraftTBL"><thead><tr><th>Ident</th><th>Type</th><th>Dates</th>' +
      (GROUP_DATA.Quoting == 1 ? '<th>Legs</th>' : '') +
      (GROUP_DATA.Pax_Man == 1 ? '<th>Pax</th>' : '') +
      '<th>Status</th></tr></thead><tbody>';

    for (var a in d.Associated.BookedAC) {
      var ac_Color = '';
      var ac = d.Associated.BookedAC[a];
      if (ac.ConflictV2 == 1 || ac.Serviceable == 0) {
        ac_Color = 'redbg';
      }
      var LegClass = '';
      if (ac.Quote != null) {
        if (ac.Quote.Legs.length > 0) {
          LegClass = ' greenbg';
        }
      }
      var SCHlegs = '';
      var SCHLegTXT = '';
      if (GROUP_DATA.Quoting == 1) {
        SCHlegs =
          "<td><button class='action-btn" +
          LegClass +
          "' data-mini='true' onClick='SCH_LegEntry(" +
          a +
          ", true)' " +
          (LegClass == '' ? 'Disabled' : '') +
          "><img class='" +
          LegClass +
          "' src='./images/NewLeg.svg'/></button></td>";
        SCHLegTXT = SCH_GetACLegTXT(ac);
      }
      var paxMan = '';
      if (GROUP_DATA.Pax_Man == 1) {
        var paxManClass = '';
        if (ac.Pax_Manifest != undefined) {
          if (ac.Pax_Manifest.DefaultList.length > 0 || ac.Pax_Manifest.Pax_Legs.length > 0) {
            paxManClass = 'greenbg';
            if (ac.Pax_Manifest.HasVIP == 1) paxManClass = 'vip-following';
          }
        }
        paxMan =
          "<td><button class='action-btn' data-mini='true' onClick='SCH_LegEntry(" +
          a +
          ", true)' " +
          (paxManClass == '' ? 'disabled' : '') +
          "><img class='" +
          paxManClass +
          "' src='./images/paxman.svg'/></button></td>";
      }

      html +=
        '<tr class="' +
        ac_Color +
        '"><td>' +
        ac.ident +
        SCHLegTXT +
        '</td><td>' +
        ac.AcType +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Aircraft\',' +
        a +
        ')" onmouseout="HideEventNav()">' +
        SCH_getHumanTime(ac.start) +
        '↵<br />' +
        SCH_getHumanTime(ac.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="AircraftEventNav' +
        a +
        '"></div></div></td>' +
        SCHlegs +
        paxMan +
        '<td>' +
        ac.Status +
        ' ' +
        ac.ServiceReason +
        '</td></tr>';
    }

    html += '</tbody></table>';
  }
  if (d.Associated.BookedV == undefined) {
    d.Associated.BookedV = [];
  }
  if (d.Associated.BookedV.length > 0) {
    html += '<h3>Vehicles</h3>';
    html +=
      '<table class="limit-table last-td-fill" id="SCH_AircraftTBL"><thead><tr><th>Label</th><th>Plate</th><th>Dates</th><th>Status</th></tr></thead><tbody>';

    for (var a in d.Associated.BookedV) {
      var ac_Color = '';
      var ac = d.Associated.BookedV[a];
      if (ac.ConflictV2 == 1 || ac.Serviceable == 0) {
        ac_Color = 'redbg';
      }
      html +=
        '<tr class="' +
        ac_Color +
        '"><td>' +
        ac.Label +
        '</td><td>' +
        ac.licenceplate +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Vehicle\',' +
        a +
        ')" onmouseout="HideEventNav()">' +
        SCH_getHumanTime(ac.start) +
        '↵<br />' +
        SCH_getHumanTime(ac.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="VehicleEventNav' +
        a +
        '"></div></div></td><td>' +
        ac.Status +
        ' ' +
        ac.ServiceReason +
        '</td></tr>';
    }

    html += '</tbody></table>';
  }

  if (d.Associated.BookedStaff.length > 0) {
    html += '<h3>Staff</h3>';
    html +=
      '<table class="limit-table last-td-fill" id="SCH_StaffBookTBL"><thead><tr><th>Name</th><th>Phone</th><th>Type</th><th>Role</th><th>Assignment</th><th>Dates</th><th>Status</th></tr></thead><tbody>';
  }
  var selfAck = false;
  var canEditForms = hasSchedulerAccess('admin');
  var disabled = '';
  if (!ONLINE) {
    disabled = 'disabled';
  }
  for (var s in d.Associated.BookedStaff) {
    var staff = d.Associated.BookedStaff[s];
    if (staff.chatname === chatname) {
      canEditForms = true;
    }
    selfAck = false; //reset self ACK for loop
    var s_Color = '';
    var s_Status = 'Acknowledged.';
    if (staff.ACK == 0) {
      if (staff.chatname == chatname) {
        selfAck = true;
      }
      s_Color = 'yellowbg';
      if (!selfAck) {
        s_Status = 'Not Acknowledged.';
      } else {
        s_Status = '';
      }
    }
    if (staff.ConflictV2 == 1) {
      s_Color = 'redbg';
      s_Status += ' Scheduling Conflict';
    }
    var staff_AC = 'N/A';
    if (staff.AC_ID != 0) {
      for (var A in d.Associated.BookedAC) {
        if (d.Associated.BookedAC[A].PrimaryKey == staff.AC_ID) {
          staff_AC = d.Associated.BookedAC[A].ident;
        }
      }
    }
    var extra = '';
    if (GROUP_DATA.PULSAR_FRMS == 1) {
      var divID = 'PULSAR_STAFF_BOOKED_VIEWONLY_' + s;
      extra = '<div id="' + divID + '"><i>Loading FRMS Data...</i></div>';
    }
    if (selfAck) {
      html +=
        '<tr class="' +
        s_Color +
        '"><td>' +
        '<span style="font-size:larger;"><b>' +
        staff.chatname +
        '</b></span>' +
        extra +
        '</td><td>' +
        staff.phone +
        '</td><td>' +
        staff.StaffType +
        '</td><td>' +
        staff.Role +
        '</td><td>' +
        staff_AC +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Staff\',' +
        s +
        ')" onmouseout="HideEventNav()">' +
        SCH_getHumanTime(staff.start) +
        '↵<br />' +
        SCH_getHumanTime(staff.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="StaffEventNav' +
        s +
        '"></div></div></td><td>' +
        s_Status +
        '<button onClick="SCH_userUPdate(\'Acknowledge\');" data-mini="true" data-icon="check" class="greenbg" ' +
        disabled +
        '>Acknowledge</button></td></tr>';
    } else {
      html +=
        '<tr class="' +
        s_Color +
        '"><td>' +
        '<span style="font-size:larger;"><b>' +
        staff.chatname +
        '</b></span>' +
        extra +
        '</td><td>' +
        staff.phone +
        '</td><td>' +
        staff.StaffType +
        '</td><td>' +
        staff.Role +
        '</td><td>' +
        staff_AC +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Staff\',' +
        s +
        ')" onmouseout="HideEventNav()">' +
        SCH_getHumanTime(staff.start) +
        '↵<br />' +
        SCH_getHumanTime(staff.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="StaffEventNav' +
        s +
        '"></div></div></td><td>' +
        s_Status +
        '</td></tr>';
    }
  }

  if (d.Associated.BookedStaff.length > 0) {
    html += '</tbody></table>';
  }
  if (d.Associated.Attachments.length > 0) {
    html += '<h3>Attachments</h3>';
    html +=
      '<table class="limit-table first-td-fill" id="SCH_AttachmentTBL"><thead><tr><th>Filename</th><th>View</th></tr></thead><tbody>';
  }
  for (var A in d.Associated.Attachments) {
    var Attachment = d.Associated.Attachments[A];
    html += `<tr>
  <td>${Attachment.filename}</td>
  <td>
    <a 
      href="${BASEURL}menu-Scheduler-AJAX.php?action=viewAttachment&PrimaryKey=${Attachment.PrimaryKey}&BookingPK=${SCHEVENTDATA.PrimaryKey}" 
      data-ajax="false" 
      target="_blank" 
      data-role="button" 
      class="action-btn" 
      data-mini="true">
      <img src='./images/inspect.svg'/>
    </a>
  </td>
</tr>
`;
  }
  if (d.Associated.Attachments.length > 0) {
    html += '</tbody></table>';
  }

  html += '<div id="bookingViewerFormPickerHolder" class="grid-row"></div>';
  html += '<div class="white-space-please"></div>';
  if (hasSchedulerAccess('booking') || GROUP_DATA.SCH_StatusUpdate == 0) {
    if (d.Status != 'Cancelled') {
      html +=
        '<button data-mini="true" data-icon="delete" class="redbg" onClick="SCH_userUPdate(\'Cancelled\')" data-mini="true" ' +
        disabled +
        '>Cancel Booking</button>';
    }
    if (d.Status != 'Tentative') {
      html +=
        '<button data-mini="true" data-icon="info" class="yellowbg" onClick="SCH_userUPdate(\'Tentative\')" data-mini="true" ' +
        disabled +
        '>Mark Booking as Tentative</button>';
    }
    if (d.Status != 'Booked') {
      html +=
        '<button data-mini="true" data-icon="check" class="greenbg" onClick="SCH_userUPdate(\'Booked\')" data-mini="true" ' +
        disabled +
        '>Confirm Booking</button>';
    }
  }
  html += '<br />';
  if (hasSchedulerAccess('booking')) {
    html +=
      '<button data-mini="true" data-icon="edit" onClick="SCH_EditThis()" data-mini="true" ' +
      disabled +
      '>Edit Booking</button>';
  }

  html += '<div class="white-space-please"></div>';
  html += GetHistoryMarkup(d.Associated.HistoryList);
  html += '</td></tr></table>';

  SCHEDITTHIS = args;
  const schViewItem = $('#SCH_ViewItem');
  schViewItem.html(html).enhanceWithin();

  if (FORMS > 0 && d.Associated.hasOwnProperty('Forms') && d.Associated.Forms.length > 0) {
    FormsInBookings.clearInstance();
    FormsInBookings.instance(
      FormsInBookings.PAGE_VIEWER,
      '#bookingViewerFormPickerHolder',
      d.Associated.Forms,
      SCHEVENTDATA.PrimaryKey,
      canEditForms ? FormBridge.EL_FILL : FormBridge.EL_VIEW
    );
  }

  AddTableSorter('SCH_AttachmentTBL');
  AddTableSorter('SCH_StaffBookTBL');
  AddTableSorter('SCH_AircraftTBL');
  if (GROUP_DATA.PULSAR_FRMS == 1) {
    PULSAR_GetScheduleStaffBooked(d.Associated.BookedStaff, 'PULSAR_STAFF_BOOKED_VIEWONLY_', 0);
  }
}

var SCHEDITTHIS;

function SCH_EditThis() {
  $('#SCH_ViewItem').html('');
  $('#SCH_Book_Aircraft').html('');
  $('#SCH_Book_Staff').html('');

  SCHEditEvent(SCHEDITTHIS);
}

function SCH_userUPdate(action) {
  //console.log(action);
  var prompt = 'Are you sure you want to update this booking status to ' + action + '?';
  if (action == 'Acknowledge') {
    prompt = 'Are you sure you want to acknowledge this booking?';
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: prompt,
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          $.mobile.loading('show', {
            theme: 'a',
          });
          var PrimaryKey = SCHEVENTDATA.PrimaryKey;
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=userUPdate&Status=' +
              action +
              '&BookingPK=' +
              PrimaryKey +
              '&timezone=' +
              SCHSTATE.timezone,
            function (json_data) {
              $.mobile.loading('hide');
              if (json_data[0].ServerResponse == 'Offline') {
                //console.log('Offline Scheduler Not Available');
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception') {
                ServerResponse(json_data[0], 'SCH_PopClients');
              }
              if (json_data[0].ServerResponse == 'Not Logged In') {
                NOTLOGGEDIN();
              }
              if (json_data[0].ServerResponse == 'Success') {
                LastSCHEDULEsync = stringToDateObject(json_data[0].LastSCHEDULEsync);
                SCHupdate(true);
              }
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error Retrieving events');
            alert('Not Updated');
            SCHupdate(false);
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCH_RangeSelected(args) {
  if (SCHSTATE.ctrl) {
    switch (SCHSTATE.view) {
      case 'Month':
        SCHSTATE.view = 'Day';
        SCHSTATE.date = args.start.value;
        $('#SCH_Client_Text').show();
        $('#SCH_Job_Text').show();
        $('input[name=SCHVIEW][value=Month]').prop('checked', false).checkboxradio('refresh');
        $('input[name=SCHVIEW][value=Day]').prop('checked', true).checkboxradio('refresh');
        createScheduler('Day');
        break;
    }
  } else {
    $('#SCH_Client_Text').show();
    $('#SCH_Client_Text').prop('disabled', false);
    $('#SCH_Job_Text').show();
    $('#SCH_Job_Text').prop('disabled', false);
    newScheduling(args);
  }
}

function SCH_LoadEvents() {
  let start = dp.visibleStart().addMinutes(SCHSTATE.timezone).toDate().toISOString().substring(0, 19);
  let end = dp.visibleEnd().addMinutes(SCHSTATE.timezone).toDate().toISOString().substring(0, 19);
  var timestamp = new Date();
  if (LOCALSTORAGE.SyncLength == undefined) {
    LOCALSTORAGE.SyncLength = 2;
  }
  timestamp.setMonth(timestamp.getMonth() - LOCALSTORAGE.SyncLength);
  var View = new Date(start).getTime();
  if (View < timestamp.getTime()) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'WARNING',
      headerClose: false,
      buttonPrompt:
        'The requested Scheduler data is beyond the events synced to this device.<br />' +
        'Loading from the server may fail if too many events exist.',
      buttons: {
        'Load From Server': {
          click: function () {
            SCH_Load_Archived_Events();
          },
        },
        Cancel: {
          click: function () {
            //File bug report
          },
        },
      },
    });
    return;
  }
  $.mobile.loading('show', {
    theme: 'a',
  });
  start = start.replace('T', ' ');
  end = end.replace('T', ' ');
  //console.log(start);
  //console.log(end);
  //LOCALSTORAGE.BaseFilter d.UNIT
  //if (LOCALSTORAGE.BaseFilter == "N/A"){}

  //todo improve query time by selecting bases
  var whereClause = null;
  var queryValues = [];
  var FilterVal = $('#SCH_Filter_Base').val();
  if (FilterVal != 'N/A' && FilterVal != undefined && FilterVal != '') {
    whereClause =
      '`start` >= ? AND `start` <= ? AND `UNIT` = ? OR `end` <= ? AND `end` >= ? AND `UNIT` = ? OR `start` <= ? AND `end` >= ? AND `UNIT` = ?';
    queryValues = [start, end, FilterVal, end, start, FilterVal, start, end, FilterVal];
  } else {
    whereClause = '`start` >= ? AND `start` <= ? OR `end` <= ? AND `end` >= ? OR `start` <= ? AND `end` >= ?';
    queryValues = [start, end, end, start, start, end];
  }
  SCH_ControlBlock(true);
  cirroDB.query('Scheduler_Bookings', whereClause, queryValues, function (Bookings) {
    if (Bookings === false) {
      Bookings = [];
    }

    cirroDB.query(
      'Scheduler_Individual',
      '`start` >= ? AND `start` <= ? OR `end` <= ? AND `end` >= ? OR `start` <= ? AND `end` >= ?',
      [start, end, end, start, start, end],
      function (Individual) {
        //var sEvent = '<div style="color:DARKGOLDENROD;">ABC Drilling Inc - Tentative<br />Slave Lake Job</div><div style="color:BLACK;">C-FBHM</div><div style="color:MAROON;">Mike Kleywegt - Pending</div><div style="color:DARKGREEN;">Doug Carlson - Acknowledged</div>';
        //var details

        if (Individual === false) {
          Individual = [];
        }
        var showme = false;
        if (LOCALSTORAGE.SCHMyEvents == 'true') {
          showme = true;
          $('#SCH_BANNER').html(' My Bookings Only');
        } else {
          $('#SCH_BANNER').html(' Schedule');
        }
        //console.log(Bookings);
        for (var i in Bookings) {
          var d = Bookings[i];
          if (SCH_CheckFilter(d)) {
            var BStaff = d.Associated.BookedStaff;
            var BAircraft = d.Associated.BookedAC;
            if (showme) {
              if (SCHSTATE.resource) {
                var MyEvent_Booking = {};
                for (var B in BStaff) {
                  if (BStaff[B].Staff_ID == USER_RIGHTS.PrimaryKey && MyEvent_Booking[d.PrimaryKey] == undefined) {
                    SCHaddEvent(Check_SCH_Conflicts(Bookings, i), false);
                    MyEvent_Booking[d.PrimaryKey] = d.PrimaryKey;
                  }
                }
              } else {
                //Calendar view display as multiple bookings with start and end times of pilot
                for (var B in BStaff) {
                  if (BStaff[B].Staff_ID == USER_RIGHTS.PrimaryKey) {
                    if (d.start2 == undefined && d.end2 == undefined) {
                      d.start2 = d.start;
                      d.end2 = d.end;
                    }
                    d.start = BStaff[B].start;
                    d.end = BStaff[B].end;
                    //SCHaddEvent(Check_SCH_Conflicts(Bookings, i), false);
                    SCHaddEvent(JSON.parse(JSON.stringify(Check_SCH_Conflicts(Bookings, i))), false);
                  }
                }
              }
            } else {
              if (SCHSTATE.associated && !SCHSTATE.resource) {
                console.log(d.start);
                for (var B in BStaff) {
                  if (d.start2 == undefined && d.end2 == undefined) {
                    d.start2 = d.start;
                    d.end2 = d.end;
                  }
                  d.start = BStaff[B].start;
                  d.end = BStaff[B].end;
                  d.Associated.Label =
                    '<b>' + BStaff[B].chatname + '</b><br />' + d.Client_Text + '<br />' + d.Job_Text;
                  SCHaddEvent(Check_SCH_Conflicts(Bookings, i), false);
                }
                for (var A in BAircraft) {
                  if (d.start2 == undefined && d.end2 == undefined) {
                    d.start2 = d.start;
                    d.end2 = d.end;
                  }
                  d.start = BAircraft[A].start;
                  d.end = BAircraft[A].end;
                  d.Associated.Label =
                    '<b>' +
                    BAircraft[A].ident +
                    ' [' +
                    BAircraft[A].AcType +
                    ']</b><br />' +
                    d.Client_Text +
                    '<br />' +
                    d.Job_Text;
                  SCHaddEvent(Check_SCH_Conflicts(Bookings, i), false);
                }
              } else {
                SCHaddEvent(Check_SCH_Conflicts(Bookings, i), false);
              }
            }
          }
        }

        if (SCHSTATE.resource) {
          for (var I in Individual) {
            var html = '<div style="font-size:' + SCHSTATE.font + 'pt;font-weight:bold;">';
            var htmlfull = '<div style="font-size:10pt;font-weight:bold;">';
            var ii = Individual[I];
            ii.Individual = 1;
            var startI = new DayPilot.Date(ii.start).addMinutes(-SCHSTATE.timezone);
            var endI = new DayPilot.Date(ii.end).addMinutes(-SCHSTATE.timezone);
            ii.start = startI.toString('yyyy-MM-dd HH:mm:ss');
            ii.end = endI.toString('yyyy-MM-dd HH:mm:ss');
            var backColor = 'YELLOWGREEN';
            var Avail = 'Not Available.';
            if (ii.Avail == 0) {
              backColor = 'INDIANRED';
            }
            if (ii.Approved == 0) {
              backColor = 'ORANGE';
            }
            if (ii.Avail == 0 && ii.Approved == 0) {
              Avail = 'Not Available - Pending Approval';
            }
            if (ii.Avail == 1) {
              backColor = 'YELLOWGREEN';
              Avail = 'Available.';
            }
            if (ii.Status == 'Workover') {
              ii.Status = '';
              backColor = 'magenta';
              Avail = 'Workover';
            }
            html += ii.cname + ' [' + Avail + '] ' + ii.Status + '</div>';
            htmlfull += ii.cname + '-' + Avail + ' ' + ii.Status + '</div>';
            var e3 = new DayPilot.Event({
              start: startI,
              end: endI,
              id: DayPilot.guid(),
              text: htmlfull,
              html: html,
              barColor: '#3a3e5b',
              barHidden: false,
              backColor: backColor,
              raw: ii,
              resource: ii.cname,
              moveHDisabled: true,
              moveVDisabled: true,
              height: 25,
              clickDisabled: false,
              moveDisabled: false,
              resizeDisabled: false,
              sort: [html, startI],
            });
            dp.events.add(e3);
          }
        }

        $.mobile.loading('hide');
        if (SCHSTATE.resource) {
          dp.setScrollX(SCHSTATE.ScrollX);
          dp.setScrollY(SCHSTATE.ScrollY);
        } else {
          setTimeout(function () {
            window.scrollTo(0, SCHSTATE.ScrollY);
          }, 500);
        }
        SCH_Load_Tasks_and_Objectives(start, end);
      }
    );
  });
  if (ONLINE) {
    if (SAMA_ENABLED != '0') {
      cSAMA_Scheduler.AddEvents(
        dp.visibleStart().value,
        dp.visibleEnd().value,
        SCHSTATE.timezone,
        SCHSTATE.SAMACorrActs == 'true',
        SCHSTATE.SAMAPog == 'true',
        dp,
        SCHSTATE.font
      );
    }
  }
}

function SCH_Load_Tasks_and_Objectives(start, end) {
  try {
    const taskQuerystring = '`start` >= ? AND `start` <= ? OR `end` <= ? AND `end` >= ? OR `start` <= ? AND `end` >= ?';
    const taskQueryarray = [start, end, end, start, start, end];
    cirroDB.query('Tasks', taskQuerystring, taskQueryarray, function (e) {
      let tasks = e;
      for (var i in tasks) {
        var d = tasks[i];
        SCHaddEvent(d, false);
      }
      if (SCHSTATE.SAMAPog == 'true') {
        cirroDB.query('Objectives', taskQuerystring, taskQueryarray, function (e) {
          let objectives = e;
          for (var i in objectives) {
            var d = objectives[i];
            SCHaddEvent(d, false);
          }
          SCH_ControlBlock(false);
        });
      } else {
        SCH_ControlBlock(false);
      }
    });
  } catch (e) {
    SCH_ControlBlock(false);
    console.log('ERROR: SCH_Load_Tasks_and_Objectives', e);
  }
}

function Check_SCH_Conflicts(Bookings, index) {
  if (LOCALSTORAGE.SCHMyEvents == 'true') {
  }
  var B_AC = Bookings[index].Associated.BookedAC;
  var B_Staff = Bookings[index].Associated.BookedStaff;
  for (var i in Bookings) {
    if (i == index) {
      continue;
    }
    if (Bookings[i].Status == 'Cancelled') {
      continue;
    }
    if (Bookings[i].TYPE == 'Task') {
      continue;
    }
    var AC = Bookings[i].Associated.BookedAC;
    for (var bA in B_AC) {
      Compare_And_Flag(B_AC[bA], AC);
    }

    var Staff = Bookings[i].Associated.BookedStaff;
    for (var bS in B_Staff) {
      Compare_And_Flag(B_Staff[bS], Staff);
    }
  }

  return Bookings[index];
}

function Compare_And_Flag(Item, List) {
  var Istart = stringToDateObject(Item.start).getTime();
  var Iend = stringToDateObject(Item.end).getTime();
  if (Item.ConflictV2 == undefined) {
    Item.ConflictV2 = 0;
  }
  if (Item.ConflictV2_ID == undefined) {
    Item.ConflictV2_ID = '';
  }
  var Iname = '';
  if (Item.TYPE == 'AIRCRAFT') {
    Iname = Item.PrimaryKey;
  }
  if (Item.TYPE == 'STAFF') {
    Iname = Item.Staff_ID;
  }
  if (Item.TYPE == 'VEHICLE') {
    Iname = Item.PrimaryKey;
  }
  for (var i in List) {
    var L = List[i];
    var Lname = '';
    if (L.TYPE == 'AIRCRAFT') {
      Lname = L.PrimaryKey;
    }
    if (L.TYPE == 'STAFF') {
      Lname = L.Staff_ID;
    }
    if (L.TYPE == 'VEHICLE') {
      Lname = L.PrimaryKey;
    }
    var Lstart = stringToDateObject(L.start).getTime();
    var Lend = stringToDateObject(L.end).getTime();
    //whereClause = "`start` >= ? AND `start` <= ? OR `end` <= ? AND `end` >= ? OR `start` <= ? AND `end` >= ?";
    //queryValues = [ start, end, end, start, start, end ];

    if (Iname == Lname) {
      ////console.log(Iname + " | " + Lname);
      ////console.log(Istart + " | " + Lstart);
      if (
        (Istart >= Lstart && Istart <= Lend) ||
        (Iend <= Lend && Iend >= Lstart) ||
        (Istart <= Lstart && Iend >= Lend)
      ) {
        //has a conflict flag it
        Item.ConflictV2 = 1;
        Item.Conflict_ID = L.Booking_ID;
      }
    }
  }
}

function SCH_Load_Archived_Events() {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  //alert(ONLINE);
  //https://dev.air-suite.com/menu-Scheduler-AJAX.php?action=getEvents&filter=0&start=2018-07-01T00%3A00%3A00&end=2018-08-01T00%3A00%3A00&timezone=240
  $.mobile.loading('show', {
    theme: 'a',
  });
  SCH_ControlBlock(true);
  $.ajaxSetup({ timeout: 120000 });
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=getEvents&filter=' +
      SCHSTATE.filter +
      '&start=' +
      encodeURIComponent(dp.visibleStart().value) +
      '&end=' +
      encodeURIComponent(dp.visibleEnd().value) +
      '&timezone=' +
      SCHSTATE.timezone,
    function (json_data) {
      SCH_ControlBlock(false);
      $.ajaxSetup({ timeout: 30000 });
      if (json_data[0].ServerResponse != undefined) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Loading...');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'SCH_PopClients');
          SCHschedulerBack();
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse == 'None') {
        } //Do Nothing
      } else {
        //console.log('Received Bookings');
        //var sEvent = '<div style="color:DARKGOLDENROD;">ABC Drilling Inc - Tentative<br />Slave Lake Job</div><div style="color:BLACK;">C-FBHM</div><div style="color:MAROON;">Mike Kleywegt - Pending</div><div style="color:DARKGREEN;">Doug Carlson - Acknowledged</div>';
        //var details
        var Bookings = json_data[0].Bookings;
        var Individual = json_data[0].Individual;
        for (var i in Bookings) {
          var d = Bookings[i];
          SCHaddEvent(Check_SCH_Conflicts(Bookings, i), false);
        }

        if (SCHSTATE.resource) {
          for (var I in Individual) {
            var html = '<div style="font-size:' + SCHSTATE.font + 'pt;font-weight:bold;">';
            var htmlfull = '<div style="font-size:10pt;font-weight:bold;">';
            var ii = Individual[I];
            ii.Individual = 1;
            var start = new DayPilot.Date(ii.start).addMinutes(-SCHSTATE.timezone);
            var end = new DayPilot.Date(ii.end).addMinutes(-SCHSTATE.timezone);
            ii.start = start.toString('yyyy-MM-dd HH:mm:ss');
            ii.end = end.toString('yyyy-MM-dd HH:mm:ss');
            var backColor = 'YELLOWGREEN';
            var Avail = 'Not Available.';
            if (ii.Avail == 0) {
              backColor = 'INDIANRED';
            }
            if (ii.Approved == 0) {
              backColor = 'ORANGE';
            }
            if (ii.Avail == 0 && ii.Approved == 0) {
              Avail = 'Not Available - Pending Approval';
            }
            if (ii.Avail == 1) {
              backColor = 'YELLOWGREEN';
              Avail = 'Available.';
            }
            html += ii.cname + ' [' + Avail + '] ' + ii.Status + '</div>';
            htmlfull += ii.cname + '-' + Avail + ' ' + ii.Status + '</div>';
            var e3 = new DayPilot.Event({
              start: start,
              end: end,
              id: DayPilot.guid(),
              text: htmlfull,
              html: html,
              barColor: '#3a3e5b',
              barHidden: false,
              backColor: backColor,
              raw: ii,
              resource: ii.cname,
              moveHDisabled: true,
              moveVDisabled: true,
              height: 25,
              clickDisabled: false,
              moveDisabled: false,
              resizeDisabled: false,
              sort: [html, start],
            });
            dp.events.add(e3);
          }
        }
      }
      $.mobile.loading('hide');
      if (SCHSTATE.resource) {
        dp.setScrollX(SCHSTATE.ScrollX);
        dp.setScrollY(SCHSTATE.ScrollY);
      }
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $.ajaxSetup({ timeout: 30000 });
    //console.log('Communication Error Retrieving events');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Failed!',
      headerClose: false,
      buttonPrompt:
        'There was a problem loading bookings.  Please try again. If this continues to happen please submit a bug report.',
      buttons: {
        Close: {
          click: function () {},
        },
        Report: {
          click: function () {
            //File bug report
          },
        },
      },
    });

    SCHschedulerBack();
    $.mobile.loading('hide');
  });

  if (SAMA_ENABLED != '0') {
    cSAMA_Scheduler.AddEvents(
      dp.visibleStart().value,
      dp.visibleEnd().value,
      SCHSTATE.timezone,
      SCHSTATE.SAMACorrActs == 'true',
      SCHSTATE.SAMAPog == 'true',
      dp,
      SCHSTATE.font
    );
  }
}

function ViewFlightReportPK_SCH(PK, returnpage) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  ExitShowCurrentFlightReport = returnpage;
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getFR&PK=' + PK, function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    $.mobile.loading('hide');

    const response = json_data[0].ServerResponse;

    switch (response) {
      case 'Success':
        CURRENT_FLIGHT_REPORT = json_data[0]['Report'];

        BILLING_FLIGHTREPORT_CREW = [];
        CURRENT_FLIGHT_REPORT.Crew = [];

        if (CURRENT_FLIGHT_REPORT.Crew != '') {
          if (Array.isArray(CURRENT_FLIGHT_REPORT.Crew)) {
            BILLING_FLIGHTREPORT_CREW = CURRENT_FLIGHT_REPORT.Crew;
          } else {
            BILLING_FLIGHTREPORT_CREW = JSON.parse(CURRENT_FLIGHT_REPORT.Crew);
            CURRENT_FLIGHT_REPORT.Crew = BILLING_FLIGHTREPORT_CREW;
          }
        }
        ShowCurrentFlightReport();
        return;

      case 'Uncaught Exception':
        ServerResponse(json_data[0], 'getSpots');
        break;

      case 'Not Logged In':
        NOTLOGGEDIN();
        break;
    }

    alert("Error: Couldn't locate flight report");
  }).fail(function (jqXHR, status, error) {
    $.mobile.loading('hide');
    AJAXFAIL(error);
  });
}

function showVisibleAssociatedDateRange(Item) {
  // TODO this probably won't work with tz changes
  if (LOCALSTORAGE.SCHVisibleOnly == undefined) {
    return true;
  }
  if (LOCALSTORAGE.SCHVisibleOnly == 'true') {
    var Bstart = Item.start;
    var Bend = Item.end;
    var start = new Date(Bstart.replace(' ', 'T') + 'Z').getTime();
    var end = new Date(Bend.replace(' ', 'T') + 'Z').getTime();
    if (start >= SCHSTATE.visibleEnd || end <= SCHSTATE.visibleStart) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}

function SCHaddEvent(d, update) {
  if (update) {
    SCHupdate(true); //reload
    return;
  }
  if (LOCALSTORAGE.BaseFilter == undefined || LOCALSTORAGE.BaseFilter == '') {
    LOCALSTORAGE.BaseFilter = 'N/A';
  }
  if (!['Task', 'POG'].includes(d.TYPE) && LOCALSTORAGE.BaseFilter != 'N/A' && LOCALSTORAGE.BaseFilter != d.UNIT) {
    return;
  }
  d.RolesFilled = SCHsortBookedStaff(d.Associated.BookedStaff);

  var job_Color = 'RGBA(222,222,222,1)';
  if (d.Status == 'Tentative') {
    job_Color = 'RGBA(247,247,213,1)';
  }
  if (d.Status == 'Cancelled') {
    job_Color = 'RGBA(242,197,183,1)';
  }
  if (d.TYPE === 'POG') {
    job_Color = '#99e7ff';
  }
  if (d.Associated.categoryID != 0) {
    if (SCH_CatLookup[d.Associated.categoryID] != undefined) {
      job_Color = SCH_CatLookup[d.Associated.categoryID];
    }
  }
  var RoleTXT = '';
  if (d.RolesFilled !== true && d.TYPE !== 'POG' && d.TYPE !== 'Task') {
    RoleTXT += '<div style="color:red;font-weight:bold;">Missing Roles:<br />';
    for (var R in d.RolesFilled) {
      if (R == 0) {
        RoleTXT += '- ' + d.RolesFilled[R];
      } else {
        RoleTXT += '<br />- ' + d.RolesFilled[R];
      }
    }
    RoleTXT += '</div>';
    job_Color = 'RGBA(255,255,0,1)';
  }
  var AttachIcon = ' ';
  var conflict = 0;
  var ack = 0;
  if (d.Associated.Attachments.length > 0 || (d.Associated.hasOwnProperty('Forms') && d.Associated.Forms.length > 0)) {
    AttachIcon = '@ ';
  }

  var html = '<div style="font-size:' + SCHSTATE.font + 'pt;">';
  var htmlfull = '<div style="font-size:10pt;">';
  html += RoleTXT;
  htmlfull += RoleTXT;
  if (SCHSTATE.showJob == 'true') {
    html += '<div style="color:black;">' + d.Job_Text + '</div>';
  }
  htmlfull += '<div style="color:black;">' + d.Job_Text + '</div>';
  if (SCHSTATE.showUNIT == 'true') {
    html += '<div style="color:black;">' + d.UNIT + '</div>';
  }
  htmlfull += '<div style="color:black;">' + d.UNIT + '</div>';
  if (SCHSTATE.showFlightReports == 'true') {
    if (GROUP_DATA.FlightReports == 1 && d.Associated.FlightReports != undefined) {
      if (d.Associated.FlightReports.length > 0) {
        htmlfull += '<div style="color:black;"><b>Flight Reports: </b>';
        html += '<div style="color:black;"><b>Flight Reports: </b>';
      }
      var count = 0;
      for (var f in d.Associated.FlightReports) {
        var SCHFRPK = d.Associated.FlightReports[f].PrimaryKey;
        var ref =
          '<a href="#" onclick="event.stopPropagation();event.preventDefault();ViewFlightReportPK_SCH(' +
          SCHFRPK +
          ",'Schedule_Page');\">#" +
          SCHFRPK +
          '</a> ';
        count++;
        if (count == 5) {
          ref += '<br />';
          count = 0;
        }
        htmlfull += ref;
        html += ref;
      }
      if (d.Associated.FlightReports.length > 0) {
        htmlfull += '</div>';
        html += '</div>';
      }
    }
  }
  var AC_Processed = {};
  for (var a in d.Associated.BookedAC) {
    var ac_Color = 'Black;';
    var ac = d.Associated.BookedAC[a];
    if (showVisibleAssociatedDateRange(ac) == false) {
      continue;
    }
    var AC_Conflict = {};
    var AC_Count = {};
    AC_Count[ac.ident] = 0;
    AC_Conflict[ac.ident] = false;
    for (var dc in d.Associated.BookedAC) {
      if (d.Associated.BookedAC[dc].ident == ac.ident) {
        AC_Count[ac.ident]++;
        if (ac.ConflictV2 == 1 || ac.Serviceable == 0) {
          AC_Conflict[ac.ident] = true;
        }
      }
    }

    if (AC_Processed[ac.ident] == undefined) {
      if (ac.ConflictV2 == 1 || ac.Serviceable == 0 || AC_Conflict[ac.ident]) {
        ac_Color = 'MAROON; font-weight: bold;';
        conflict++;
      }
      if (d.Associated.MultiBooking) {
        htmlfull +=
          '<div style="color:' +
          ac_Color +
          ';">' +
          ac.ident +
          ' [' +
          ac.AcType +
          '] ' +
          SCH_GetACLegTXT(ac) +
          ' <b>X ' +
          AC_Count[ac.ident] +
          '</b></div>';
      } else {
        htmlfull +=
          '<div style="color:' + ac_Color + ';">' + ac.ident + ' [' + ac.AcType + '] ' + SCH_GetACLegTXT(ac) + '</div>';
      }
      if (SCHSTATE.showAircraft == 'true' && SCHSTATE.showAircraftType == 'true') {
        if (d.Associated.MultiBooking) {
          html +=
            '<div style="color:' +
            ac_Color +
            ';">' +
            ac.ident +
            ' [' +
            ac.AcType +
            '] ' +
            SCH_GetACLegTXT(ac) +
            ' <b>X ' +
            AC_Count[ac.ident] +
            '</b></div>';
        } else {
          html +=
            '<div style="color:' +
            ac_Color +
            ';">' +
            ac.ident +
            ' [' +
            ac.AcType +
            '] ' +
            SCH_GetACLegTXT(ac) +
            '</div>';
        }
      } else {
        if (SCHSTATE.showAircraft == 'true') {
          if (d.Associated.MultiBooking) {
            html += '<div style="color:' + ac_Color + '">' + ac.ident + ' <b>X ' + AC_Count[ac.ident] + '</b></div>';
          } else {
            html += '<div style="color:' + ac_Color + '">' + ac.ident + '</div>';
          }
        }
        if (SCHSTATE.showAircraftType == 'true') {
          if (d.Associated.MultiBooking) {
            html += '<div style="color:' + ac_Color + '">[' + ac.AcType + '] <b>X ' + AC_Count[ac.ident] + '</b></div>';
          } else {
            html += '<div style="color:' + ac_Color + '">[' + ac.AcType + ']</div>';
          }
        }
      }
      AC_Processed[ac.ident] = ac.ident;
    }
  }
  var Staff_Processed = {};

  for (var s in d.Associated.BookedStaff) {
    var staff = d.Associated.BookedStaff[s];
    if (showVisibleAssociatedDateRange(staff) == false) {
      continue;
    }
    var Staff_Count = {};
    var Staff_Role = {};
    var Staff_Conflict = {};
    Staff_Count[staff.chatname] = 0;
    Staff_Role[staff.chatname] = {};
    Staff_Conflict[staff.chatname] = false;

    for (var sc in d.Associated.BookedStaff) {
      if (d.Associated.BookedStaff[sc].chatname == staff.chatname) {
        Staff_Count[staff.chatname]++;
        if (staff.ConflictV2 == 1) {
          Staff_Conflict[staff.chatname] = true;
        }
      }
      Staff_Role[staff.chatname][staff.Role] = staff.Role;
    }
    var Roles = '';
    var RoleArray = Object.keys(Staff_Role[staff.chatname]);
    var rcount = 0;
    for (var R in RoleArray) {
      if (RoleArray[R] == 'None' || RoleArray[R] == undefined) {
        continue;
      }
      if (rcount == 0) {
        Roles += RoleArray[R];
      } else {
        Roles += ', ' + RoleArray[R];
      }
      rcount++;
    }
    if (rcount > 0) {
      Roles += ': ';
    }

    var s_Color = 'Black;';
    if (staff.ACK == 0) {
      s_Color = 'TEAL; font-weight: bold;';
      ack++;
    }
    if (staff.ConflictV2 == 1 || Staff_Conflict[staff.chatname]) {
      s_Color = 'MAROON; font-weight: bold;';
      conflict++;
    }
    var staff_AC = '';
    if (staff.AC_ID != 0) {
      var StaffAC_Used = {};
      for (var A in d.Associated.BookedAC) {
        if (
          d.Associated.BookedAC[A].PrimaryKey == staff.AC_ID &&
          StaffAC_Used[d.Associated.BookedAC[A].ident] == undefined
        ) {
          staff_AC += ' [' + d.Associated.BookedAC[A].ident + ']';
          StaffAC_Used[d.Associated.BookedAC[A].ident] = true;
        }
      }
    }
    if (Staff_Processed[staff.chatname] == undefined) {
      if (SCHSTATE.showStaff == 'true' && SCHSTATE.showStaffAC == 'true') {
        if (d.Associated.MultiBooking) {
          html +=
            '<div style="color:' +
            s_Color +
            '">' +
            Roles +
            staff.chatname +
            staff_AC +
            ' <b>X ' +
            Staff_Count[staff.chatname] +
            '</b></div>';
        } else {
          html += '<div style="color:' + s_Color + '">' + staff.chatname + staff_AC + '</div>';
        }
      } else if (SCHSTATE.showStaff == 'true') {
        if (d.Associated.MultiBooking) {
          html +=
            '<div style="color:' +
            s_Color +
            '">' +
            Roles +
            staff.chatname +
            ' <b>X ' +
            Staff_Count[staff.chatname] +
            '</b></div>';
        } else {
          html += '<div style="color:' + s_Color + '">' + staff.chatname + '</div>';
        }
      }
      if (d.Associated.MultiBooking) {
        htmlfull +=
          '<div style="color:' +
          s_Color +
          '">' +
          Roles +
          staff.chatname +
          staff_AC +
          ' <b>X ' +
          Staff_Count[staff.chatname] +
          '</b></div>';
      } else {
        htmlfull += '<div style="color:' + s_Color + '">' + Roles + staff.chatname + staff_AC + '</div>';
      }
      Staff_Processed[staff.chatname] = staff.chatname;
    }
  }

  var V_Processed = {};
  if (d.Associated.BookedV == undefined) {
    d.Associated.BookedV = [];
  }
  for (var a in d.Associated.BookedV) {
    var ac_Color = 'Black;';
    var ac = d.Associated.BookedV[a];
    ac.Label = ac.Label.toString();
    if (showVisibleAssociatedDateRange(ac) == false) {
      continue;
    }
    var AC_Conflict = {};
    var AC_Count = {};
    AC_Count[ac.Label] = 0;
    AC_Conflict[ac.Label] = false;
    for (var dc in d.Associated.BookedV) {
      if (d.Associated.BookedV[dc].ident == ac.Label) {
        AC_Count[ac.Label]++;
        if (ac.ConflictV2 == 1 || ac.Serviceable == 0) {
          AC_Conflict[ac.Label] = true;
        }
      }
    }

    if (V_Processed[ac.Label] == undefined) {
      if (ac.ConflictV2 == 1 || ac.Serviceable == 0 || AC_Conflict[ac.Label]) {
        ac_Color = 'MAROON; font-weight: bold;';
        conflict++;
      }
      if (d.Associated.MultiBooking) {
        htmlfull +=
          '<div style="color:' +
          ac_Color +
          ';">' +
          ac.Label +
          ' [' +
          ac.licenceplate +
          '] <b>X ' +
          AC_Count[ac.Label] +
          '</b></div>';
      } else {
        htmlfull += '<div style="color:' + ac_Color + ';">' + ac.Label + ' [' + ac.licenceplate + ']</div>';
      }
      if (SCHSTATE.showVehicles == 'true') {
        if (d.Associated.MultiBooking) {
          html +=
            '<div style="color:' +
            ac_Color +
            ';">' +
            ac.Label +
            ' [' +
            ac.licenceplate +
            '] <b>X ' +
            AC_Count[ac.Label] +
            '</b></div>';
        } else {
          html += '<div style="color:' + ac_Color + ';">' + ac.Label + ' [' + ac.licenceplate + ']</div>';
        }
      } else {
        if (SCHSTATE.showVehicles == 'true') {
          if (d.Associated.MultiBooking) {
            html += '<div style="color:' + ac_Color + '">' + ac.Label + ' <b>X ' + AC_Count[ac.Label] + '</b></div>';
          } else {
            html += '<div style="color:' + ac_Color + '">' + ac.Label + '</div>';
          }
        }
        if (SCHSTATE.showAircraftType == 'true') {
          if (d.Associated.MultiBooking) {
            html +=
              '<div style="color:' + ac_Color + '">[' + ac.licenceplate + '] <b>X ' + AC_Count[ac.Label] + '</b></div>';
          } else {
            html += '<div style="color:' + ac_Color + '">[' + ac.licenceplate + ']</div>';
          }
        }
      }
      V_Processed[ac.Label] = ac.Label;
    }
  }

  var description = d.description;
  if (description.length > 128) {
    description = description.substr(0, 128) + '...';
  }
  htmlfull += '<div style="color:black;">' + description + '</div>';
  htmlfull += '</div>';
  html += '</div>';

  var background = '';
  if (ack > 0) {
    background = 'background-color: DARKKHAKI;';
  }
  if (conflict > 0) {
    background = 'background-color: INDIANRED;';
  }
  var rheight = parseInt(LOCALSTORAGE.SCHrheight);
  var height = parseInt(LOCALSTORAGE.SCHheight);

  var htmlHeader =
    '<div style="color:black;height:' +
    (SCHSTATE.resource ? rheight : height) +
    'px;background-color: ' +
    job_Color +
    '; width:100%">';
  var htmlfullHeader = '<div style="font-size:10pt;' + background + '">';
  htmlHeader += '<b style="font-size:' + SCHSTATE.font + 'pt;' + background + '">' + AttachIcon;
  if (d.TYPE === 'POG') {
    htmlHeader += `<div class="sama-daypilot-title" style="font-size:{fontSize}pt;">
          Policy Objective
        </div>`;
  } else if (d.TYPE === 'Task') {
    htmlHeader += `<div class="sama-daypilot-title" style="font-size:{fontSize}pt;">
          Task
        </div>`;
  }
  htmlfullHeader += '<div style="color:black;"><b>' + AttachIcon;
  if (SCHSTATE.showClient == 'true') {
    if (LOCALSTORAGE.SCHShowUniqueID == undefined || LOCALSTORAGE.SCHShowUniqueID == 'false') {
      htmlHeader += d.Client_Text + '</b>';
    } else {
      htmlHeader += d.Client_Text + ' #' + d.PrimaryKey + '</b>';
    }
  } else {
    htmlHeader += '</b>';
  }
  if (LOCALSTORAGE.SCHShowUniqueID == undefined || LOCALSTORAGE.SCHShowUniqueID == 'false') {
    htmlfullHeader += d.Client_Text + '</b></div></div>';
  } else {
    htmlfullHeader += d.Client_Text + ' #' + d.PrimaryKey + '</b></div></div>';
  }

  htmlfull = htmlfullHeader + htmlfull;
  if (SCHSTATE.associated && !SCHSTATE.resource) {
    html = d.Associated.Label;
  } else {
    html = htmlHeader + html + '</div>';
  }

  var e;
  if (d.TYPE === 'Task' || d.TYPE === 'POG') {
    var start = new DayPilot.Date(d.start);
    var end = new DayPilot.Date(d.end);
  } else {
    var start = new DayPilot.Date(d.start).addMinutes(-SCHSTATE.timezone);
    var end = new DayPilot.Date(d.end).addMinutes(-SCHSTATE.timezone);
  }
  d.start = start.toString('yyyy-MM-dd HH:mm:ss');
  d.end = end.toString('yyyy-MM-dd HH:mm:ss');
  if (d.start2 != undefined && d.end2 != undefined) {
    var start2 = new DayPilot.Date(d.start2).addMinutes(-SCHSTATE.timezone);
    var end2 = new DayPilot.Date(d.end2).addMinutes(-SCHSTATE.timezone);
    d.start2 = start2.toString('yyyy-MM-dd HH:mm:ss');
    d.end2 = end2.toString('yyyy-MM-dd HH:mm:ss');
  }

  if (SCHSTATE.resource) {
    // Handles the tentative, booked, and cancelled bookings that show above all aircraft bookings
    e = new DayPilot.Event({
      start: start,
      end: end,
      id: DayPilot.guid(),
      text: htmlfull,
      html: html,
      barColor: '#3a3e5b',
      barHidden: false,
      backColor: job_Color,
      raw: d,
      TYPE: 'BOOKING',
      PrimaryKey: d.PrimaryKey,
      resource: d.Status,
      moveHDisabled: true,
      moveVDisabled: false,
      moveDisabled: hasSchedulerAccess('booking', d.Booked_By) ? false : true,
      height: rheight,
      sort: [html, start],
    });
    dp.events.add(e);

    // Handles all aircraft bookings
    for (var acR in d.Associated.BookedAC) {
      var acR = d.Associated.BookedAC[acR];
      var acBG = '';
      if (ac.ConflictV2 == 1 || ac.Serviceable == 0) {
        acBG = 'background-color: INDIANRED; font-weight: bold;';
      }
      var acHeader = '<div style="font-size:' + SCHSTATE.font + 'pt;' + acBG + '">' + acR.ident + '</div>';
      var acHeaderHeight = 25;
      if (SCHSTATE.ResACInfo == 'true') {
        acHeader += html;
        acHeaderHeight = rheight + acHeaderHeight;
      }
      var ACstart = new DayPilot.Date(acR.start).addMinutes(-SCHSTATE.timezone);
      var ACend = new DayPilot.Date(acR.end).addMinutes(-SCHSTATE.timezone);
      var e2 = new DayPilot.Event({
        start: ACstart.toString('yyyy-MM-dd HH:mm:ss'),
        end: ACend.toString('yyyy-MM-dd HH:mm:ss'),
        id: DayPilot.guid(),
        text: htmlfull,
        html: '<div style="background:' + job_Color + ';">' + acHeader + '</div>',
        barColor: '#3a3e5b',
        barHidden: false,
        backColor: job_Color,
        //raw: acR, in the future when we allow editing individual assocaited items
        raw: d,
        TYPE: 'AIRCRAFT',
        PrimaryKey: acR.ItemPrimaryKey,
        resource: acR.ident,
        moveHDisabled: false,
        moveVDisabled: false,
        moveDisabled: hasSchedulerAccess('booking', d.Booked_By) ? false : true,
        height: acHeaderHeight,
        sort: [html, start],
      });
      dp.events.add(e2);
    }

    // Handles all staff bookings
    for (var ss in d.Associated.BookedStaff) {
      var sn = d.Associated.BookedStaff[ss];
      var snBG = '';
      if (sn.ACK == 0) {
        snBG = 'background-color: PALETURQUOISE; font-weight: bold;';
      }
      if (sn.ConflictV2 == 1) {
        snBG = 'background-color: INDIANRED; font-weight: bold;';
      }
      var Sstaff_AC = '';
      var Sstaff_Used = {};
      for (var AB in d.Associated.BookedAC) {
        if (
          d.Associated.BookedAC[AB].PrimaryKey == sn.AC_ID &&
          Sstaff_Used[d.Associated.BookedAC[AB].ident] == undefined
        ) {
          Sstaff_AC += ' [' + d.Associated.BookedAC[AB].ident + ']';
          Sstaff_Used[d.Associated.BookedAC[AB].ident] = true;
        }
      }
      var snHeader =
        '<div style="font-size:' + SCHSTATE.font + 'pt;' + snBG + '">' + sn.chatname + Sstaff_AC + '</div>';
      var snHeaderHeight = 25;
      if (SCHSTATE.ResStaffInfo == 'true') {
        snHeader += html;
        snHeaderHeight = rheight;
      }
      var Sstart = new DayPilot.Date(sn.start).addMinutes(-SCHSTATE.timezone);
      var Send = new DayPilot.Date(sn.end).addMinutes(-SCHSTATE.timezone);
      var e3 = new DayPilot.Event({
        start: Sstart.toString('yyyy-MM-dd HH:mm:ss'),
        end: Send.toString('yyyy-MM-dd HH:mm:ss'),
        id: DayPilot.guid(),
        text: htmlfull,
        html: '<div>' + snHeader + '</div>',
        barColor: '#3a3e5b',
        barHidden: false,
        backColor: job_Color,
        //raw: sn, //in the future when we allow editing individual assocaited items
        raw: d,
        TYPE: 'STAFF',
        PrimaryKey: sn.ItemPrimaryKey,
        resource: sn.chatname,
        moveHDisabled: false,
        moveVDisabled: false,
        moveDisabled: hasSchedulerAccess('timeoff', d.Booked_By) ? false : true,
        height: snHeaderHeight,
        sort: [html, start],
      });
      dp.events.add(e3);
    }
    if (d.Associated.BookedV == undefined) {
      d.Associated.BookedV = [];
    }
    for (var acR in d.Associated.BookedV) {
      var acR = d.Associated.BookedV[acR];
      var acBG = '';
      if (ac.ConflictV2 == 1 || ac.Serviceable == 0) {
        acBG = 'background-color: INDIANRED; font-weight: bold;';
      }
      var acHeader = '<div style="font-size:' + SCHSTATE.font + 'pt;' + acBG + '">' + acR.Label + '</div>';
      var acHeaderHeight = 25;
      if (SCHSTATE.ResACInfo == 'true') {
        acHeader += html;
        acHeaderHeight = rheight;
      }
      var ACstart = new DayPilot.Date(acR.start).addMinutes(-SCHSTATE.timezone);
      var ACend = new DayPilot.Date(acR.end).addMinutes(-SCHSTATE.timezone);
      var e2 = new DayPilot.Event({
        start: ACstart.toString('yyyy-MM-dd HH:mm:ss'),
        end: ACend.toString('yyyy-MM-dd HH:mm:ss'),
        id: DayPilot.guid(),
        text: htmlfull,
        html: '<div>' + acHeader + '</div>',
        barColor: '#3a3e5b',
        barHidden: false,
        backColor: job_Color,
        //raw: acR, in the future when we allow editing individual assocaited items
        raw: d,
        TYPE: 'VEHICLE',
        PrimaryKey: acR.ItemPrimaryKey,
        resource: acR.Label,
        moveHDisabled: false,
        moveVDisabled: false,
        moveDisabled: hasSchedulerAccess('booking', d.Booked_By) ? false : true,
        height: acHeaderHeight,
        sort: [html, start],
      });
      dp.events.add(e2);
    }
  } else {
    var height = LOCALSTORAGE.SCHheight;
    if (height == undefined) {
      height = 100;
    }
    if (LOCALSTORAGE.SCHMyEvents == 'true' || SCHSTATE.associated) {
      e = new DayPilot.Event({
        start: start,
        end: end,
        id: DayPilot.guid(),
        text: htmlfull,
        html: html,
        bubbleHtml: htmlfull,
        barColor: '#3a3e5b',
        barHidden: true,
        backColor: job_Color,
        raw: d,
        TYPE: 'BOOKING',
        PrimaryKey: d.PrimaryKey,
        sort: [html, start],
        resizeDisabled: true,
        moveDisabled: true,
        height: height,
      });
    } else {
      e = new DayPilot.Event({
        start: start,
        end: end,
        id: DayPilot.guid(),
        text: htmlfull,
        html: html,
        bubbleHtml: htmlfull,
        barColor: '#3a3e5b',
        barHidden: true,
        backColor: job_Color,
        raw: d,
        TYPE: 'BOOKING',
        PrimaryKey: d.PrimaryKey,
        moveDisabled: hasSchedulerAccess('booking', d.Booked_By) ? false : true,
        sort: [html, start],
        height: height,
      });
    }
    dp.events.add(e);
  }
}

function SCH_NewMobile_Booking() {
  var now = new Date();
  var Syear = now.getFullYear();
  var Smonth = now.getMonth() + 1;
  var Sday = now.getDate();
  if (Smonth < 10) {
    Smonth = '0' + Smonth;
  }
  if (Sday < 10) {
    Sday = '0' + Sday;
  }

  var Enow = new Date();
  Enow.setDate(Enow.getDate() + 1);
  var Eyear = Enow.getFullYear();
  var Emonth = Enow.getMonth() + 1;
  var Eday = Enow.getDate();
  if (Emonth < 10) {
    Emonth = '0' + Emonth;
  }
  if (Eday < 10) {
    Eday = '0' + Eday;
  }
  var args = {
    start: new DayPilot.Date(Syear + '-' + Smonth + '-' + Sday + 'T00:00:00'),
    end: new DayPilot.Date(Eyear + '-' + Emonth + '-' + Eday + 'T00:00:00'),
  };

  newScheduling(args);
}

function SCH_PromptSelectStaffAssignment(args, Booking) {
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=getAssociated&start=' +
      encodeURIComponent(Booking.start) +
      '&end=' +
      encodeURIComponent(Booking.end) +
      '&PK=' +
      Booking.PrimaryKey +
      '&timezone=' +
      SCHSTATE.timezone,
    function (json_data) {
      if (json_data.length > 0) {
        //something bad happened just continue without prompting to add staff to the aircraft booking
        SCH_AttachToBooking(args, Booking.PrimaryKey, 0);
      } else {
        //display the data
        var BookedStaff = json_data.BookedStaff;
        var FreeStaff = json_data.FreeStaff;
        if (FreeStaff.length > 0) {
          var Bselect = '<label for="SCHAddStafftoACID">Select Staff</label><select id="SCHAddStafftoACID">';
          Bselect += '<option value="0">No Staff</option>';
          var hasStaff = false;
          var BookedStaffList = {};
          for (var i in BookedStaff) {
            if (BookedStaff[i].BASED_IN == Booking.UNIT || Booking.UNIT == '') {
              if (BookedStaffList[BookedStaff[i].PrimaryKey] == undefined) {
                Bselect +=
                  '<option value="' + BookedStaff[i].PrimaryKey + '">[B] ' + BookedStaff[i].chatname + '</option>';
                hasStaff = true;
                BookedStaffList[BookedStaff[i].PrimaryKey] = true;
              }
            }
          }
          for (var i in FreeStaff) {
            if (FreeStaff[i].BASED_IN == Booking.UNIT || Booking.UNIT == '') {
              Bselect += '<option value="' + FreeStaff[i].PrimaryKey + '">[F] ' + FreeStaff[i].chatname + '</option>';
              hasStaff = true;
            }
          }
          Bselect += '</select>';
          if (hasStaff) {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Associate To Staff?',
              headerClose: false,
              buttonPrompt: 'What Staff member would you like to associate this aircraft to?' + Bselect,
              buttons: {
                Proceed: {
                  click: function () {
                    if (SCHSTATE.resource) {
                      SCHSTATE.ScrollX = dp.getScrollX();
                      SCHSTATE.ScrollY = dp.getScrollY();
                      //console.log(SCHSTATE);
                    } else {
                      SCHSTATE.ScrollX = 0;
                      SCHSTATE.ScrollY = document.documentElement.scrollTop;
                    }
                    var AC_ID = $('#SCHAddStafftoACID').val();
                    SCH_AttachToBooking(args, Booking.PrimaryKey, AC_ID);
                    dp.clearSelection();
                  },
                },
                Cancel: {
                  click: function () {
                    //File bug report
                    dp.clearSelection();
                  },
                },
              },
            });
          } else {
            //don't prompt to select since list is empty
            SCH_AttachToBooking(args, Booking.PrimaryKey, 0);
          }
        } else {
          SCH_AttachToBooking(args, Booking.PrimaryKey, 0);
        }
      }
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //something bad happened just continue without prompting to add staff to the aircraft booking
    SCH_AttachToBooking(args, Booking.PrimaryKey, 0);
  });
}

function SCH_PromptSelectAircraftAssignment(args, Booking) {
  var Bselect = '<label for="SCHAddStafftoACID">Select Aircraft</label><select id="SCHAddStafftoACID">';
  Bselect += '<option value="0">No Aircraft</option>';
  var AcList = {};
  var HasAC = false;
  for (var i in Booking.Associated.BookedAC) {
    if (AcList[Booking.Associated.BookedAC[i].ident] != undefined) {
      continue;
    }
    Bselect +=
      '<option value="' +
      Booking.Associated.BookedAC[i].PrimaryKey +
      '">' +
      Booking.Associated.BookedAC[i].ident +
      '</option>';
    AcList[Booking.Associated.BookedAC[i].ident] = true;
    HasAC = true;
  }
  Bselect += '</select>';
  if (HasAC) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Associate To Aircraft?',
      headerClose: false,
      buttonPrompt: 'What aircraft would you like to associate this staff member to?' + Bselect,
      buttons: {
        Proceed: {
          click: function () {
            if (SCHSTATE.resource) {
              SCHSTATE.ScrollX = dp.getScrollX();
              SCHSTATE.ScrollY = dp.getScrollY();
              //console.log(SCHSTATE);
            } else {
              SCHSTATE.ScrollX = 0;
              SCHSTATE.ScrollY = document.documentElement.scrollTop;
            }
            var AC_ID = $('#SCHAddStafftoACID').val();
            SCH_AttachToBooking(args, Booking.PrimaryKey, AC_ID);
            dp.clearSelection();
          },
        },
        Cancel: {
          click: function () {
            //File bug report
            dp.clearSelection();
          },
        },
      },
    });
  } else {
    //no aircraft to select from just add to booking
    SCH_AttachToBooking(args, Booking.PrimaryKey, 0);
    dp.clearSelection();
  }
}

function SCH_AttachToBooking(args, BookingPK, AC_ID) {
  console.log(args);
  var startRange = new Date(args.start.value + 'Z');
  var endRange = new Date(args.end.value + 'Z');
  var rangeHours = Math.abs(endRange - startRange) / 36e5;

  var BA = SCHSTATE.BookingAddTo;
  var BA_start = stringToDateObject(BA.start); //UTC
  var BA_end = stringToDateObject(BA.end); //UTC
  //BA_start.setMinutes(BA_start.getMinutes() + timezoneOffset); //Local Time
  //BA_end.setMinutes(BA_end.getMinutes() + timezoneOffset); //Local Time

  var BA_Same_Local_Day = false;
  var BA_Same_Local_Start_Day = false;
  var BA_Same_Local_End_Day = false;
  var timezoneOffset = SCHSTATE.timezone;
  if (
    BA_start.getDate() == BA_end.getDate() &&
    BA_start.getDay() == BA_end.getDay() &&
    BA_start.getFullYear() == BA_end.getFullYear()
  ) {
    BA_Same_Local_Day = true;
  }

  var BA_startRange = new Date(args.start.value);
  var BA_endRange = new Date(args.end.value);
  if (SCHSTATE.view == 'Month' || SCHSTATE.view == 'Week') {
    BA_endRange.setMinutes(BA_endRange.getMinutes() - 1);
  }

  if (
    BA_start.getDate() == BA_startRange.getDate() &&
    BA_start.getDay() == BA_startRange.getDay() &&
    BA_start.getFullYear() == BA_startRange.getFullYear()
  ) {
    BA_Same_Local_Start_Day = true;
  }
  if (
    BA_end.getDate() == BA_endRange.getDate() &&
    BA_end.getDay() == BA_endRange.getDay() &&
    BA_end.getFullYear() == BA_endRange.getFullYear()
  ) {
    BA_Same_Local_End_Day = true;
  }
  //Use Default Behavour for MultiBookings
  var e = args;
  var dt = e.start.value;
  SCHSTATE.date = dt;
  dt = dt.split('T');
  var sd = dt[0];

  var st = dt[1];

  dt = e.end.value;
  dt = dt.split('T');
  /*
     if (SCHSTATE.resource){
     if (dt[1] == "00:00:00"){
     dt = e.end.addDays(-1).value;
     dt = dt.split("T");
     }
     }*/
  //console.log(SCHSTATE.view);
  //console.log(SCHSTATE.resource);
  if ((SCHSTATE.view == 'Week' && SCHSTATE.resource == false) || SCHSTATE.view == 'Day') {
    //do nothing
  } else {
    dt = e.end.addDays(-1).value;
    dt = dt.split('T');
  }
  if (SCHSTATE.view == 'Day') {
    dt = e.end.addMinutes(-1).value;
    dt = dt.split('T');
  }
  var ed = dt[0];
  var et = '23:59:00'; //default end time same day
  if ((SCHSTATE.view != 'Month' && SCHSTATE.view != 'Week') || (SCHSTATE.view == 'Week' && !SCHSTATE.resource)) {
    et = dt[1];
  }
  var start = e.start.value;
  var end = ed + 'T' + et;
  //END DEFAULT Behavour

  if (
    (SCHSTATE.view == 'Month' || SCHSTATE.view == 'Week') &&
    SCHSTATE.resource == true &&
    BA_Same_Local_Day == true &&
    rangeHours == 24
  ) {
    //Since we are in resource and Month view adding a resource to a booking that has set hours for the day the booking gets unintentionally expanded when turned into a multi booking
    //Even if multi booking use same start and end time as the booking when we attach staff or aircraft to it
    var start = BA.start;
    start = start.replace(' ', 'T');
    var end = BA.end;
    end = end.replace(' ', 'T');
    timezoneOffset = 0; //since booking is in UTC
  } else {
    if (
      (SCHSTATE.view == 'Month' || SCHSTATE.view == 'Week') &&
      SCHSTATE.resource == true &&
      BA_Same_Local_Start_Day == true
    ) {
      //Adjust associated start time to match booking start time
      start = BA.start;
      start = new Date(start.replace(' ', 'T') + 'Z');
      start.setMinutes(start.getMinutes() - timezoneOffset);
      start = start.toISOString().substr(0, 19);
    }
    if (
      (SCHSTATE.view == 'Month' || SCHSTATE.view == 'Week') &&
      SCHSTATE.resource == true &&
      BA_Same_Local_End_Day == true
    ) {
      //Adjust associated end time to match booking end time
      end = BA.end;
      end = new Date(end.replace(' ', 'T') + 'Z');
      end.setMinutes(end.getMinutes() - timezoneOffset);
      end = end.toISOString().substr(0, 19);
    }
  }
  var DN = $('#SHC_DisableNotifications').is(':checked');
  var checked = '';
  if (DN == true) {
    checked = 'checked';
  }

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Send Notification',
    headerClose: false,
    buttonPrompt: iTrans('Would you like to send a Notifications to the Staff Members') + '?',
    buttons: {
      [iTrans('Send Notifications')]: {
        click: function () {
          SCH_AddToBooking(start, end, args, timezoneOffset, BookingPK, AC_ID, 0);
        },
      },
      [iTrans('Do Not Notify')]: {
        click: function () {
          SCH_AddToBooking(start, end, args, timezoneOffset, BookingPK, AC_ID, 1);
        },
      },
    },
  });
}

function SCH_AddToBooking(start, end, args, timezoneOffset, BookingPK, AC_ID, DN) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=AddtoBooking&start=' +
      encodeURIComponent(start) +
      '&end=' +
      encodeURIComponent(end) +
      '&resource=' +
      encodeURIComponent(args.resource) +
      '&timezone=' +
      timezoneOffset +
      '&BookingPK=' +
      BookingPK +
      '&AC_ID=' +
      AC_ID +
      '&DN=' +
      DN,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Not Available');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse == 'Success') {
          var conflicts = json_data[0].Conflicts;
          for (var i in conflicts) {
            var I = conflicts[i];
            cirroDB.Delete(
              'Scheduler_Individual',
              'PrimaryKey = ? OR PrimaryKey LIKE ?',
              [I.PrimaryKey, I.PrimaryKey + '_%'],
              function (e) {
                if (e === false) console.log('Failed to delete SCH: ' + I.PrimaryKey);
                else console.log('Deleted SCH: ' + I.PrimaryKey);
              }
            );
          }
          SCHupdate(true);
        }
        if (json_data[0].ServerResponse == 'Failed' || json_data[0].Reason == 'Conflict') {
          //pop conflict dialog!
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Action Cancelled: Conflict',
            headerClose: false,
            buttonPrompt: '<p>Aircraft assigned to the same booking cannot have scheduling conflicts!</p>',
            buttons: {
              Close: {
                click: function () {},
              },
            },
          });
        }
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //console.log('Communication Error adding new event');
    SCHschedulerBack();
    $.mobile.loading('hide', {
      theme: 'a',
    });
  });
}

function newScheduling(args) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  SCHEVENTDATA = undefined;
  //console.log(args);
  //todo empty all existing fields on nwe Scheduling item
  var e = args;
  var dt = e.start.value;
  SCHSTATE.date = dt;
  dt = dt.split('T');
  var sd = dt[0];
  $('#SCH_Book_sDate').val(sd);
  $('#SCH_Book_sDateSTAFF').val(sd);
  var st = dt[1].substr(0, 5);
  $('#SCH_Book_sTime').val(st);
  $('#SCH_Book_sTimeSTAFF').val(st);

  dt = e.end.value;
  dt = dt.split('T');
  /*
     if (SCHSTATE.resource){
     if (dt[1] == "00:00:00"){
     dt = e.end.addDays(-1).value;
     dt = dt.split("T");
     }
     }*/
  //console.log(SCHSTATE.view);
  //console.log(SCHSTATE.resource);
  if ((SCHSTATE.view == 'Week' && SCHSTATE.resource == false) || SCHSTATE.view == 'Day') {
    //do nothing
  } else {
    dt = e.end.addDays(-1).value;
    dt = dt.split('T');
  }
  if (SCHSTATE.view == 'Day') {
    dt = e.end.addMinutes(-1).value;
    dt = dt.split('T');
  }
  var ed = dt[0];
  $('#SCH_Book_eDate').val(ed);
  $('#SCH_Book_eDateSTAFF').val(ed);
  //var et = dt[1];
  var et = '23:59:00'; //default end time same day
  if ((SCHSTATE.view != 'Month' && SCHSTATE.view != 'Week') || (SCHSTATE.view == 'Week' && !SCHSTATE.resource)) {
    et = dt[1];
  }
  $('#SCH_Book_eTime').val(et.substr(0, 5));
  $('#SCH_Book_eTimeSTAFF').val(et);
  var start = e.start.value;
  var end = ed + 'T' + et;
  var showNB = LOCALSTORAGE.SCHbushour;
  if (showNB == 'true' && SCHSTATE.view == 'Month') {
    var beginHour = LOCALSTORAGE.SCHstart;
    if (beginHour != null) {
      beginHour = beginHour.substring(0, 2);
      var Sstart = e.start.value.split('T');
      start = Sstart[0] + 'T' + beginHour + ':00:00';
      $('#SCH_Book_sTime').val(beginHour + ':00');
      $('#SCH_Book_sTimeSTAFF').val(beginHour + ':00:00');
    }
    var endHour = LOCALSTORAGE.SCHend;
    if (endHour != null) {
      endHour = endHour.substring(0, 2);
      end = ed + 'T' + endHour + ':00:00';
      $('#SCH_Book_eTime').val(endHour + ':00');
      $('#SCH_Book_eTimeSTAFF').val(endHour + ':00:00');
    }
  }

  var resource = '';
  var StaffSched = false;
  if (args.resource != undefined) {
    resource = args.resource;

    if (bookingBelongsToAUser(args.resource)) {
      StaffSched = true;
    }
  }
  if (StaffSched) {
    $.getJSON(
      BASEURL +
        'menu-Scheduler-AJAX.php?action=newBookingSTAFF&start=' +
        encodeURIComponent(start) +
        '&end=' +
        encodeURIComponent(end) +
        '&resource=' +
        encodeURIComponent(resource) +
        '&timezone=' +
        SCHSTATE.timezone,
      function (json_data) {
        if (json_data[0].ServerResponse != undefined) {
          if (json_data[0].ServerResponse == 'Offline') {
            //console.log('Offline Scheduler Not Available');
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            ServerResponse(json_data[0], 'SCH_PopClients');
            dp.update();
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
          if (json_data[0].ServerResponse == 'Failed') {
            if (json_data[0].Reason == 'Conflict') {
              alert(iTrans('Conflicts with existing availability event'));
            }
            dp.update();
          }
          if (json_data[0].ServerResponse == 'Success') {
            $.mobile.changePage($('#Schedule_Staff'), {
              reverse: false,
              changeHash: false,
            });
            if (!hasSchedulerAccess('timeoff')) {
              $('#SCH_StaffApproved').attr('disabled', true).checkboxradio('refresh');
            }
            $('#SCH_StaffName').html('<h3>' + resource + '</h3>');
            $('#SCH_StaffAvail').attr('checked', false).checkboxradio('refresh');
            $('#SCH_StaffApproved').attr('checked', false).checkboxradio('refresh');
            $('#SCH_StaffStatus').val('');
            $('#SCH_StaffApproved').show();
            SCHApprovedShow();
            //console.log('New Staff Booking Added Successfully');
            var d = {};
            d.PrimaryKey = json_data[0].insertID;
            d.start = start;
            d.end = end;
            d.resource = resource;
            d.cname = resource;
            SCHEVENTDATA = d;
          }
        }
        $.mobile.loading('hide');
      }
    ).fail(function (jqXHR, status, error) {
      //AJAXFAIL(error);
      //console.log('Communication Error adding new Staff event');
      SCHschedulerBack();
      $.mobile.loading('hide', {
        theme: 'a',
      });
    });
  } else {
    if (hasSchedulerAccess('booking')) {
      $.mobile.changePage($('#Schedule_Item'), {
        reverse: false,
        changeHash: false,
      });
      $('#SCH_Timezone_Booking').val(LOCALSTORAGE.SCH_UTCmode).selectmenu('refresh');
      SCH_SetQuickPickBasedOnTz(true);

      var resourceSEL = 'Tentative';
      if (resource == 'Booked' || resource == 'Cancelled' || resource == 'Tentative') {
        resourceSEL = resource;
      }

      $('#SCH_Book_AircraftBTN').show();
      $('#SCH_Book_AircraftBTN2').show();
      $('#SCH_Book_StaffBTN').show();
      $('#SCH_Book_StaffBTN2').show();
      $('#SCH_Book_VehiclesBTN').show();
      $('#SCH_Book_VehiclesBTN2').show();
      if (resourceSEL == 'Booked') {
        $('input[name=BOOKSTATUS][value=Booked]').prop('checked', true).checkboxradio('refresh');
      } else {
        $('input[name=BOOKSTATUS][value=Booked]').prop('checked', false).checkboxradio('refresh');
      }
      if (resourceSEL == 'Cancelled') {
        $('input[name=BOOKSTATUS][value=Cancelled]').prop('checked', true).checkboxradio('refresh');
      } else {
        $('input[name=BOOKSTATUS][value=Cancelled]').prop('checked', false).checkboxradio('refresh');
      }
      if (resourceSEL == 'Tentative') {
        $('input[name=BOOKSTATUS][value=Tentative]').prop('checked', true).checkboxradio('refresh');
      } else {
        $('input[name=BOOKSTATUS][value=Tentative]').prop('checked', false).checkboxradio('refresh');
      }
      $('#SCH_BookingPK_TXT').html('New Booking');
      $('#SCH_Client_Text').val('');
      $('#SCH_Job_Text').val('');
      $('#SCH_Job_Desc').val('');
      $('#SCH_notes').val('');
      $('#SCH_CategorySelectorHLDR').enhanceWithin();
      try {
        $('#SCH_CatFilter').val(0).selectmenu('refresh');
      } catch (e) {
        console.log(e);
      }
      $('#SCH_AircraftMins').val(0);
      $('#SCH_CrewMins').val(0);

      SCH_UpdateCatColor();
      $('#SCH_FlightTime').val(0);
      $('#SCH_Client').val(0).selectmenu('refresh');
      $('#SCH_Job').val(0).selectmenu('refresh');

      //populate Client List if billing module enabled
      if (GROUP_DATA.FlightReports == 1) {
        SCH_PopClients();
        $('#SCH_FlightReportLinks').html('');
        $('#SCH_FlightReportLinks').show();
      } else {
        //Hide Client and Job Select dropdowns
        $('#SCHhideClient').hide();
        $('#SCHhideJob').hide();
        $('#SCH_SearchClientJob').hide();
        $('#SCH_FlightReportLinks').hide();
      }
      //get bases or company unit list
      SCH_PopBases();

      $('#SCH_Book_Admin').attr('checked', false).checkboxradio('refresh');

      $.getJSON(
        BASEURL +
          'menu-Scheduler-AJAX.php?action=newBooking&start=' +
          encodeURIComponent(start) +
          '&end=' +
          encodeURIComponent(end) +
          '&resource=' +
          encodeURIComponent(resource) +
          '&timezone=' +
          SCHSTATE.timezone,
        function (json_data) {
          if (json_data[0].ServerResponse != undefined) {
            if (json_data[0].ServerResponse == 'Offline') {
              //console.log('Offline Scheduler Not Available');
            }
            if (json_data[0].ServerResponse == 'Uncaught Exception') {
              ServerResponse(json_data[0], 'SCH_PopClients');
            }
            if (json_data[0].ServerResponse == 'Not Logged In') {
              NOTLOGGEDIN();
            }
            if (json_data[0].ServerResponse == 'Success') {
              //console.log('New Booking Added Successfully');
              var Associated = {
                Attachments: [],
                Forms: [],
                BookedAC: [],
                BookedStaff: [],
              };
              var d = {};
              d.PrimaryKey = json_data[0].insertID;
              d.Status = 'Tentative';
              d.Client_ID = '0';
              d.Client_Text = '';
              d.Job_ID = '';
              d.Job_Text = '';
              d.description = '';
              d.Flight_Time = 0;
              d.start = start;
              d.end = end;
              d.ADMIN_ONLY = 0;
              d.UNIT = '';
              d.Associated = Associated;
              d.Booked_Date = new DayPilot.Date.today().value;
              d.Updated_Date = 'Never';
              SCHEVENTDATA = d;
              BILLING_BACKUP_Cfields = null;
              SCHaddEvent(d, false);
              $('#SCH_Book_Location').val($('#SCH_Filter_Base').val()).selectmenu('refresh');
              SCH_getJobCfields(0);
              SCHbookAssociated();
            }
          }
          $.mobile.loading('hide');
        }
      ).fail(function (jqXHR, status, error) {
        //AJAXFAIL(error);
        //console.log('Communication Error adding new event');
        SCHschedulerBack();
        $.mobile.loading('hide', {
          theme: 'a',
        });
      });
    }
  }
}

function SCHEditEventStaff(args) {
  var e = args.e;
  console.log('args', args);
  var raw = args.e.data.raw;
  $.mobile.changePage($('#Schedule_Staff'), {
    reverse: false,
    changeHash: false,
  });
  var start = raw.start;
  start = start.split(' ');
  $('#SCH_Book_sDateSTAFF').val(start[0]);
  $('#SCH_Book_sTimeSTAFF').val(start[1]);

  var end = raw.end;
  end = end.split(' ');
  $('#SCH_Book_eDateSTAFF').val(end[0]);
  $('#SCH_Book_eTimeSTAFF').val(end[1]);
  $('#SCH_StaffName').html('<h2>' + raw.cname + '</h2>');
  //console.log(raw);
  //console.log('Available: ' + raw.Avail);

  if (!hasSchedulerAccess('timeoff')) {
    $('#SCH_StaffApproved').checkboxradio({
      disabled: true,
    });
  }
  //console.log(SCHEDULER_ACCESS);
  if (raw.Avail == 0) {
    $('#SCH_StaffAvail').attr('checked', false).checkboxradio('refresh');
  } else {
    $('#SCH_StaffAvail').attr('checked', true).checkboxradio('refresh');
  }
  if (raw.Approved == 0) {
    $('#SCH_StaffApproved').attr('checked', false).checkboxradio('refresh');
  } else {
    $('#SCH_StaffApproved').attr('checked', true).checkboxradio('refresh');
  }
  $('#SCH_StaffStatus').val(raw.Status);
  SCHApprovedShow();
}

function SCHSaveBookingSTAFF(data, DN) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  if (data == false) {
    var resource = SCHEVENTDATA.cname;
    var Avail = 0;
    if ($('#SCH_StaffAvail').is(':checked')) {
      Avail = 1;
    }
    var Notify = 1;
    if ($('#SCH_DisableStaffNotification').is(':checked')) {
      Notify = 0;
    }
    var Approved = 0;
    if ($('#SCH_StaffApproved').is(':checked')) {
      Approved = 1;
    }
    var start = $('#SCH_Book_sDateSTAFF').val() + ' ' + $('#SCH_Book_sTimeSTAFF').val();
    var end = $('#SCH_Book_eDateSTAFF').val() + ' ' + $('#SCH_Book_eTimeSTAFF').val();
    if ($('#SCH_Book_sTimeSTAFF').val().length == 5) {
      start += ':00';
    }
    if ($('#SCH_Book_eTimeSTAFF').val().length == 5) {
      end += ':00';
    }
    var Status = $('#SCH_StaffStatus').val();
    var EventPK = SCHEVENTDATA.PrimaryKey;
  } else {
    var raw = data.e.data.raw;
    console.log(raw);
    console.log(data.newEnd);
    var oldstart = data.e.start().value;
    var oldend = data.e.end().value;

    var newEnd = data.newEnd.value;
    if (SCHSTATE.view == 'Month' && SCHSTATE.resource == false) {
      //console.log(oldend);
      //console.log(newEnd);
      var OE = oldend.split('T');
      var NE = newEnd.split('T');
      newEnd = NE[0] + 'T' + OE[1];
    }
    if (newEnd.replace('T', ' ') != raw.end) {
      if (SCHSTATE.resource && SCHSTATE.view == 'Month' && SCHSTATE.resizing) {
        newEnd = data.newEnd.addMinutes(-1).value;
      }
      if (SCHSTATE.view == 'Day' && SCHSTATE.resizing) {
        newEnd = data.newEnd.addMinutes(-1).value;
      }
    }
    var end = newEnd;
    var start = data.newStart.value;
    var Status = raw.Status;
    var Avail = raw.Avail;
    var Approved = 0;
    if (hasSchedulerAccess('admin')) {
      Approved = raw.Approved;
    }
    var Notify = DN;
    var EventPK = raw.PrimaryKey;
    var resource = raw.cname;
  }

  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=editBookingSTAFF&start=' +
      encodeURIComponent(start) +
      '&end=' +
      encodeURIComponent(end) +
      '&resource=' +
      encodeURIComponent(resource) +
      '&Status=' +
      encodeURIComponent(Status) +
      '&Avail=' +
      Avail +
      '&Approved=' +
      Approved +
      '&PrimaryKey=' +
      EventPK +
      '&timezone=' +
      SCHSTATE.timezone +
      '&Notify=' +
      Notify,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Not Available');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'SCH_PopClients');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse == 'Failed') {
          SCHschedulerBack();
        }
        if (json_data[0].ServerResponse == 'Success') {
          LastSCHEDULEsync = stringToDateObject(json_data[0].LastSCHEDULEsync);
          SCHupdate(true);
        }
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //console.log('Communication Error adding new Staff event');
    SCHschedulerBack();
    $.mobile.loading('hide');
  });
}

function SCHDeleteBookingSTAFF(silent = false) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  var comment = $('#SCH_StaffStatus').val();
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 'checked';
  } else {
    DN = '';
  }
  var prompt =
    '<label for="SHC_DisableNotification2">Disable Notification</label><input type="checkbox" id="SHC_DisableNotification2" data-mini="true" ' +
    DN +
    '/>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: prompt + 'Are you sure you want to deny/delete this staff availability event?',
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          var DN = $('#SHC_DisableNotification2').is(':checked');
          if (DN) {
            DN = 1;
          } else {
            DN = 0;
          }
          $.mobile.loading('show', {
            theme: 'a',
          });
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=deleteBookingSTAFF&PK=' +
              SCHEVENTDATA.PrimaryKey +
              '&resource=' +
              encodeURIComponent(SCHEVENTDATA.cname) +
              '&timezone=' +
              SCHSTATE.timezone +
              '&comment=' +
              encodeURIComponent(comment) +
              '&DN=' +
              DN,
            function (json_data) {
              if (json_data[0].ServerResponse != undefined) {
                if (json_data[0].ServerResponse == 'Offline') {
                  //console.log('Offline Scheduler Not Available');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  ServerResponse(json_data[0], 'SCH_PopClients');
                }
                if (json_data[0].ServerResponse == 'Not Logged In') {
                  NOTLOGGEDIN();
                }
                if (json_data[0].ServerResponse == 'Failed') {
                  $('<div>').simpledialog2({
                    mode: 'button',
                    animate: false,
                    headerText: 'Failed!',
                    headerClose: false,
                    buttonPrompt:
                      'There was a problem removing the staff booking.  Please try again. If this continues to happen please submit a bug report.',
                    buttons: {
                      Close: {
                        click: function () {},
                      },
                      Report: {
                        click: function () {
                          //File bug report
                        },
                      },
                    },
                  });
                }
                if (json_data[0].ServerResponse == 'Success') {
                  var eventPK = SCHEVENTDATA.PrimaryKey.toString();
                  if (eventPK.indexOf('_') > -1) {
                    eventPK = eventPK.split('_')[0];
                  }
                  cirroDB.Delete(
                    'Scheduler_Individual',
                    'PrimaryKey = ? OR PrimaryKey LIKE ?',
                    [eventPK, eventPK + '_%'],
                    function (e) {
                      if (e === false) console.log('Failed to delete SCH: ' + eventPK);
                      else console.log('Deleted SCH: ' + eventPK);
                    }
                  );
                  LastSCHEDULEsync = stringToDateObject(json_data[0].LastSCHEDULEsync);
                  if (!silent) {
                    SCHupdate(true);
                  } else {
                    dp.events.list = [];
                    dp.update();
                    Manual_ResyncSCHEDULE();
                  }
                }
              }
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error removing booking');
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt:
                'There was a problem removing the staff booking.  Please try again. If this continues to happen please submit a bug report.',
              buttons: {
                Close: {
                  click: function () {},
                },
                Report: {
                  click: function () {
                    //File bug report
                  },
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCHApprovedShow() {
  //console.log($('#SCH_StaffAvail').is(':checked'));
  if ($('#SCH_StaffAvail').is(':checked')) {
    $('#SCH_StaffApprovedHLDR').hide();
  } else {
    $('#SCH_StaffApprovedHLDR').show();
  }
}

function SCHEditEvent(args, refresh = false, _scrollTo = 0, _manualData = null) {
  if (_manualData == null && args == null) {
    verbose.error('Scheduler', 'Could not edit an unspecified event!');
    cirroHOME();
    VueBridge.wait(500).then(() =>
      VueBridge.showDialog(
        'Error',
        'Sorry, but the Scheduler failed to process this action. Please try again or contact support.',
        'error'
      )
    );
    return;
  }

  let raw;
  if (!refresh) {
    raw = _manualData || args.e.data.raw;
    SCHEVENTDATA = raw;
    if (SCHEVENTDATA.Individual !== 1) {
      if (SCHEVENTDATA.Associated.Cfields == undefined) {
        SCHEVENTDATA.Associated.Cfields = [];
      }
      BILLING_BACKUP_Cfields = JSON.parse(JSON.stringify(SCHEVENTDATA.Associated.Cfields));
      SCH_popCfields(SCHEVENTDATA.Associated.Cfields);
    }
    console.log(args);
  } else {
    raw = args;
    SCHEVENTDATA = args;
    if (SCHEVENTDATA.Individual !== 1) {
      if (SCHEVENTDATA.Associated.Cfields == undefined) {
        SCHEVENTDATA.Associated.Cfields = [];
      }
    }
  }
  if (SCHEVENTDATA.Individual == 1) {
    SCHEditEventStaff(args);
    return;
  }

  if (!SCHSTATE.resource || SCHEVENTDATA.TYPE == 'BOOKING') {
    $.mobile.changePage($('#Schedule_Item'), {
      reverse: false,
      changeHash: false,
    });

    $('#SCH_Timezone_Booking').val(LOCALSTORAGE.SCH_UTCmode).selectmenu('refresh');
    SCH_SetQuickPickBasedOnTz(true);

    window.scrollTo(0, _scrollTo);
    SCH_DisableNote_BG();
    $('#SCH_Book_AircraftBTN').show();
    $('#SCH_Book_StaffBTN').show();
    $('#SCH_Book_AircraftBTN2').show();
    $('#SCH_Book_StaffBTN2').show();
    $('#SCH_Book_VehiclesBTN').show();
    $('#SCH_Book_VehiclesBTN2').show();
    $('#SCHSaveBookingBTN').attr('disabled', false);

    //populate Client List if billing module enabled
    if (GROUP_DATA.FlightReports == 1) {
      SCH_PopClients(raw, true);
      var SCHFR = '';
      if (raw.Associated.FlightReports != undefined) {
        if (raw.Associated.FlightReports.length > 0) {
          SCHFR = '<h3>Flight Reports: ';
        }
        for (var f in raw.Associated.FlightReports) {
          var SCHFRPK = raw.Associated.FlightReports[f].PrimaryKey;
          var ref =
            '<a href="#" onclick="ViewFlightReportPK_SCH(' + SCHFRPK + ", 'Schedule_Item');\">#" + SCHFRPK + '</a>, ';
          SCHFR += ref;
        }
        if (raw.Associated.FlightReports.length > 0) {
          SCHFR += '</h3>';
        }
        $('#SCH_FlightReportLinks').html(SCHFR);
      }

      $('#SCH_FlightReportLinks').show();
    } else {
      //Hide Client and Job Select dropdowns
      $('#SCHhideClient').hide();
      $('#SCHhideJob').hide();
      $('#SCH_FlightReportLinks').hide();
    }

    //populate from raw data
    //remember cannot populate client and job dropdowns they are being populated async
    $('input[name=BOOKSTATUS][value=Tentative]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKSTATUS][value=Booked]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKSTATUS][value=Cancelled]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKSTATUS][value=' + raw.Status + ']')
      .prop('checked', true)
      .checkboxradio('refresh');
    if (LOCALSTORAGE.SCHShowUniqueID == undefined || LOCALSTORAGE.SCHShowUniqueID == false) {
      $('#SCH_BookingPK_TXT').html('Booking');
    } else {
      $('#SCH_BookingPK_TXT').html('Booking #' + raw.PrimaryKey);
    }

    if (raw.Associated.Reminder == undefined) {
      raw.Associated.Reminder = 0;
    }
    if (raw.Associated.Extra == undefined) {
      raw.Associated.Extra = { Base: '', LockCrew: false, LockAC: false };
    }

    $('input[name=BOOKREMINDER][value=0]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKREMINDER][value=1]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKREMINDER][value=3]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKREMINDER][value=5]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKREMINDER][value=7]').prop('checked', false).checkboxradio('refresh');
    $('input[name=BOOKREMINDER][value=' + raw.Associated.Reminder + ']')
      .prop('checked', true)
      .checkboxradio('refresh');

    $('#SCH_Client_Text').val(raw.Client_Text);
    $('#SCH_Job_Text').val(raw.Job_Text);
    $('#SCH_Job_Desc').val(raw.description);
    if (raw.Associated.notes != undefined) {
      $('#SCH_notes').val(raw.Associated.notes);
    } else {
      $('#SCH_notes').val('');
    }
    if (raw.Associated.AircraftMins != undefined) {
      $('#SCH_AircraftMins').val(raw.Associated.AircraftMins);
    } else {
      $('#SCH_AircraftMins').val(0);
    }
    if (raw.Associated.CrewMins != undefined) {
      $('#SCH_CrewMins').val(raw.Associated.CrewMins);
    } else {
      $('#SCH_CrewMins').val(0);
    }
    $('#SCH_CategorySelectorHLDR').enhanceWithin();
    if (raw.Associated.categoryID != undefined) {
      $('#SCH_CatFilter').val(raw.Associated.categoryID).selectmenu('refresh');
      SCH_UpdateCatColor();
    } else {
      $('#SCH_CatFilter').val(0).selectmenu('refresh');
      SCH_UpdateCatColor();
    }
    $('#SCH_FlightTime').val(raw.Flight_Time);
    $('#SCHfileprogress').html('');
    var start = raw.start;
    if (raw.start2 != undefined) {
      start = raw.start2;
    }
    start = start.split(' ');
    $('#SCH_Book_sDate').val(start[0]);
    $('#SCH_Book_sTime').val(start[1].substr(0, 5));

    var end = raw.end;
    if (raw.end2 != undefined) {
      end = raw.end2;
    }
    end = end.split(' ');
    $('#SCH_Book_eDate').val(end[0]);
    $('#SCH_Book_eTime').val(end[1].substr(0, 5));

    SCH_PopBases(raw); //pass raw to preselect company unit when populated$ ("#SCH_Book_Location").val()...

    if (raw.ADMIN_ONLY == 1) {
      $('#SCH_Book_Admin').attr('checked', true).checkboxradio('refresh');
    } else {
      $('#SCH_Book_Admin').attr('checked', false).checkboxradio('refresh');
    }
    SCH_BackupCfields();
    SCHbookAssociated(); //load associated items
  }
}

function SCHcopyEvent(args) {
  if (SCHSTATE.resource) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Error',
      headerClose: false,
      buttonPrompt: 'You cannot copy bookings in resource view.',
      buttons: {
        Close: {
          click: function () {
            //reload events
            Manual_ResyncSCHEDULE();
          },
        },
      },
    });
  } else {
    if (args.e.data.raw.Status == 'Tentative') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Confirmation',
        headerClose: false,
        buttonPrompt: 'Are you sure you want to copy this event?',
        buttons: {
          Yes: {
            click: function () {
              SCHcopyEvent2(args, 1);
            },
          },
          'Cancel Change': {
            click: function () {
              //reload events
              Manual_ResyncSCHEDULE();
            },
          },
        },
      });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Notifications',
        headerClose: false,
        buttonPrompt:
          'Copying Event.<br />Would you like to send notifications to applicable personnel about this change?',
        buttons: {
          Yes: {
            click: function () {
              SCHcopyEvent2(args, 0);
            },
          },
          No: {
            click: function () {
              SCHcopyEvent2(args, 1);
            },
          },
          'Cancel Change': {
            click: function () {
              //reload events
              Manual_ResyncSCHEDULE();
            },
          },
        },
      });
    }
  }
}

function SCHcopyEvent2(args, DN) {
  console.log(args);
  $.mobile.loading('show', {
    theme: 'a',
  });
  var PrimaryKey = args.e.data.raw.PrimaryKey;
  if (SCHSTATE.view == 'Month' && SCHSTATE.resource) {
    var endTime = args.e.data.end.value.split('T');
    var newEnd = args.newEnd.value;
    var copyEnd = new Date(newEnd + 'Z');
  } else if (SCHSTATE.view == 'Month' && !SCHSTATE.resource) {
    var endTime = args.e.data.end.value.split('T');
    var newEnd = args.newEnd.value;
    var copyEnd = new Date(newEnd + 'Z');
  } else {
    copyEnd = new Date(args.newEnd.value + 'Z');
  }
  var copyStart = new Date(args.newStart.value + 'Z');
  if (LOCALSTORAGE.SCH_UTCmode != 'UTC') {
    var oldStartDST = new Date(args.e.data.start.value);
    var newStartDST = new Date(args.newStart.value);
    if (oldStartDST.isDstObserved() === false && newStartDST.isDstObserved() === true) {
      //remove 1 hours from newStart value
      //copyStart.setUTCHours(copyStart.getUTCHours() - 1);
    }
    if (oldStartDST.isDstObserved() === true && newStartDST.isDstObserved() === false) {
      //add 1 hours from newStart value
      copyStart.setUTCHours(copyStart.getUTCHours() + 1);
    }
    var oldEndDST = new Date(args.e.data.end.value);
    var newEndDST = new Date(args.newEnd.value);
    if (oldEndDST.isDstObserved() === false && newEndDST.isDstObserved() === true) {
      //remove 1 hours from newEnd value
      //copyEnd.setUTCHours(copyEnd.getUTCHours() - 1);
    }
    if (oldEndDST.isDstObserved() === true && newEndDST.isDstObserved() === false) {
      //add 1 hours from newEnd value
      copyEnd.setUTCHours(copyEnd.getUTCHours() + 1);
    }
  }
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=copyBooking&BookingPK=' +
      PrimaryKey +
      '&start=' +
      encodeURIComponent(copyStart.toISOString().replace('T', ' ').substr(0, 19)) +
      '&end=' +
      encodeURIComponent(copyEnd.toISOString().replace('T', ' ').substr(0, 19)) +
      '&DN=' +
      DN +
      '&timezone=' +
      SCHSTATE.timezone,
    function (json_data) {
      if (json_data[0].ServerResponse == 'Offline') {
        //console.log('Offline Scheduler Not Available');
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'SCH_PopClients');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
      if (json_data[0].ServerResponse == 'Success') {
        dp.events.list = [];
        dp.update();
        //SCH_LoadEvents();
        LastSCHEDULEsync = stringToDateObject(json_data[0].LastSCHEDULEsync);
        Manual_ResyncSCHEDULE();
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //console.log('Communication Error getting bases');
    $.mobile.loading('hide');
  });
}

function SCHEventResize(args) {
  var newresource = '';
  var oldresource = '';
  if (args.newResource != undefined) {
    newresource = args.newResource;
    oldresource = args.e.resource();
  } else {
    if (SCHSTATE.resource) {
      newresource = args.e.data.resource;
      oldresource = args.e.data.resource;
    }
  }
  if (args.e.data.raw.TYPE === 'Task') {
    let taskId = args.e.data.raw.PrimaryKey;
    if (isNaN(taskId)) {
      taskId = taskId.substring(0, taskId.indexOf('-'));
    }
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/tasks/${taskId}/view?from-legacy=Schedule_Page`);
  } else if (args.e.data.raw.TYPE === 'POG') {
    VueBridge.enterVue(
      `/groups/${window.vueApp.groupKey}/safety/policies/objectives/${args.e.data.raw.PrimaryKey}?from-legacy=Schedule_Page`
    );
  } else {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Notifications',
      headerClose: false,
      buttonPrompt: 'Would you like to send notifications to applicable personnel about this change?',
      buttons: {
        Yes: {
          click: function () {
            SCHEventResize2(args, 0, newresource, oldresource);
          },
        },
        No: {
          click: function () {
            SCHEventResize2(args, 1, newresource, oldresource);
          },
        },
        'Cancel Change': {
          click: function () {
            //reload events
            Manual_ResyncSCHEDULE();
          },
        },
      },
    });
  }
}

function SCHEventResize2(args, DN, newresource, oldresource) {
  console.log(args);
  if (args.e.data.raw.Booking_ID == 0) {
    SCHSaveBookingSTAFF(args, DN);
    return;
  }
  if (args.e.data.raw.Associated.MultiBooking === true) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'WARNING',
      headerClose: false,
      buttonPrompt:
        'This is a multi booking, resizing the event could result in existing associated items being either <b>clipped or deleted</b> to fit the new start and end times.  Are you sure you wish to do this?',
      buttons: {
        Yes: {
          click: function () {
            SCHEventResize3(args, DN, newresource, oldresource);
          },
        },
        Cancel: {
          click: function () {
            //reload events
            Manual_ResyncSCHEDULE();
          },
        },
      },
    });
  } else {
    SCHEventResize3(args, DN, newresource, oldresource);
  }
}

function SCHEventResize3(args, DN, newresource, oldresource) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  console.log(args);
  var oldstart = args.e.start().value;
  var oldend = args.e.end().value;

  var newEnd = args.newEnd.value;
  var newStart = args.newStart.value;
  if (SCHSTATE.view == 'Month' && SCHSTATE.resource == false) {
    //console.log(oldend);
    //console.log(newEnd);
    var OE = oldend.split('T');
    var NE = newEnd.split('T');
    newEnd = NE[0] + 'T' + OE[1];
  }
  if (
    (SCHSTATE.resource && SCHSTATE.view == 'Month' && SCHSTATE.resizing) ||
    (SCHSTATE.resource && SCHSTATE.view == 'Week' && SCHSTATE.resizing)
  ) {
    newEnd = args.newEnd.addMinutes(-1).value;
    var OE = args.e.data.raw.end.split(' ');
    var NE = newEnd.split('T');
    newEnd = NE[0] + 'T' + OE[1];

    var OS = args.e.data.raw.start.split(' ');
    var NS = newStart.split('T');
    newStart = NS[0] + 'T' + OS[1];
  }
  if (SCHSTATE.view == 'Day' && SCHSTATE.resizing) {
    newEnd = args.newEnd.addMinutes(-1).value;
  }

  $.mobile.loading('show', {
    theme: 'a',
  });
  if (args.e.data.raw.TYPE === 'Task') {
    dp.events.update(args.e);
    SCHupdate(true);
  } else {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Scheduler-AJAX.php?action=moveBooking&DN=' + DN + '&timezone=' + SCHSTATE.timezone,
      data: {
        PK: args.e.data.raw.PrimaryKey,
        start: BK_timeOffset(newStart),
        end: BK_timeOffset(newEnd),
        newresource: newresource,
        oldresource: oldresource,
        ItemPrimaryKey: args.e.data.PrimaryKey,
      },
    })
      .success(function (data) {
        if (data[0].ServerResponse == 'Uncaught Exception') {
          args.e.start(oldstart);
          args.e.end(oldend);
          if (args.newResource != undefined) {
            args.e.resource(oldresource);
          }
          dp.events.update(args.e);
          ServerResponse(data[0], 'SCHSAveBooking()');
        }
        if (data[0].ServerResponse == 'Success') {
          var conflicts = data[0].Conflicts;
          for (var i in conflicts) {
            var I = conflicts[i];
            cirroDB.Delete(
              'Scheduler_Individual',
              'PrimaryKey = ? OR PrimaryKey LIKE ?',
              [I.PrimaryKey, I.PrimaryKey + '_%'],
              function (e) {
                if (e === false) console.log('Failed to delete SCH: ' + I.PrimaryKey);
                else console.log('Deleted SCH: ' + I.PrimaryKey);
              }
            );
          }
          LastSCHEDULEsync = stringToDateObject(data[0].LastSCHEDULEsync);
          SCHupdate(true);
        }
        if (data[0].ServerResponse == 'Failed') {
          args.e.start(oldstart);
          args.e.end(oldend);
          if (args.newResource != undefined) {
            args.e.resource(oldresource);
          }
          dp.events.update(args.e);
        }
        if (data[0].ServerResponse == 'Lock') {
          args.e.start(oldstart);
          args.e.end(oldend);
          if (args.newResource != undefined) {
            args.e.resource(oldresource);
          }
          dp.events.update(args.e);
          $.mobile.loading('hide');
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Locked Option',
            headerClose: false,
            buttonPrompt: data[0].LockReason,
            buttons: {
              Close: {
                click: function () {},
              },
            },
          });
        }
      })
      .fail(function (data) {
        //data.status, data.readyState, data.responseText
        //console.log(data);
        args.e.start(oldstart);
        args.e.end(oldend);
        if (args.newResource != undefined) {
          args.e.resource(oldresource);
        }
        dp.events.update(args.e);
        $.mobile.loading('hide');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed!',
          headerClose: false,
          buttonPrompt:
            'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
          buttons: {
            Close: {
              click: function () {},
            },
            'Discard Changes': {
              click: function () {
                //File bug report
                SCHschedulerBack();
              },
            },
          },
        });
      });
  }
}

function SCH_PopBases(raw) {
  if (GROUP_DATA.Bases != undefined) {
    //console.log('Received Bases Data');
    $('#SCH_Book_Location').empty();
    $('#SCH_Book_Location').append('<option value="" selected>N/A</option>');
    for (var i in GROUP_DATA.Bases) {
      var bases = GROUP_DATA.Bases[i];
      $('#SCH_Book_Location').append('<option value="' + bases.name + '">' + bases.name + '</option>');
    }
    if (raw !== undefined) {
      if ($("#SCH_Book_Location option[value='" + raw.UNIT + "']").length > 0) {
        $('#SCH_Book_Location').val(raw.UNIT);
      }
    }
    //todo check raw data for selected base and update
    $('#SCH_Book_Location').selectmenu('refresh', true);
  }
}

function SCH_PopBaseFilter(BaseFilter) {
  if (GROUP_DATA.Bases != undefined) {
    $('#SCH_Filter_Base').empty();
    $('#SCH_Filter_Base').append('<option value="" selected>Show All Company Bases</option>');

    for (var i in GROUP_DATA.Bases) {
      var bases = GROUP_DATA.Bases[i];
      $('#SCH_Filter_Base').append('<option value="' + bases.name + '">' + bases.name + '</option>');
    }

    if (BaseFilter !== undefined) {
      if ($("#SCH_Filter_Base option[value='" + BaseFilter + "']").length > 0) {
        $('#SCH_Filter_Base').val(BaseFilter);
      } else {
        localStorageDB.setItem('BaseFilter', '');
        LOCALSTORAGE.BaseFilter = '';
      }
    }
  } else {
    $('#SCH_Filter_Base').empty();
    $('#SCH_Filter_Base').append('<option value="" selected>Show All Company Bases</option>');
  }
  $('#SCH_Filter_Base').selectmenu('refresh', true);
}

function SCH_UpdateSyncLength(e) {
  console.log(e.value);
  if (LOCALSTORAGE.SyncLength == undefined) {
    LOCALSTORAGE.SyncLength = 2;
  }
  var oldSyncLength = LOCALSTORAGE.SyncLength;
  localStorageDB.setItem('SyncLength', e.value);
  LOCALSTORAGE.SyncLength = e.value;
  if (oldSyncLength < LOCALSTORAGE.SyncLength) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Updated',
      headerClose: false,
      buttonPrompt: 'Would you like to sync now?',
      buttons: {
        'Sync Now': {
          click: function () {
            update_Sync('LastSCHEDULEsync', '2020-01-01 00:00:00');
            clickHandler('ManualSync');
          },
        },
        No: {
          click: function () {
            //File bug report
          },
        },
      },
    });
  }
}

function UpdateBaseFilter(e) {
  localStorageDB.setItem('BaseFilter', e.value);
  LOCALSTORAGE.BaseFilter = e.value;
  //console.log(e.value);
  //SCHrefresh();
  SCHSTATE.ResourceState = undefined;
  //dp.events.list = [];
  //dp.update();
  //dbSchedulerData();
  SCHrefresh();
}

function SCH_PopClients(raw, init = false) {
  $.mobile.loading('show', {
    theme: 'a',
  });

  $.getJSON(BASEURL + 'menu-Scheduler-AJAX.php?action=getClients&timezone=' + SCHSTATE.timezone, function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      if (json_data[0].ServerResponse == 'Offline') {
        //console.log('Offline Scheduler Not Available');
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'SCH_PopClients');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
    } else {
      //console.log('Received Clients Data');
      $('#SCH_Client').empty();
      $('#SCH_Client').append('<option value="0" selected>N/A</option>');
      for (var i in json_data) {
        var Client = json_data[i];
        $('#SCH_Client').append('<option value="' + Client.PrimaryKey + '">' + Client.Business + '</option>');
      }
      //todo check raw data for selected client and update
      //console.log(raw);
      $('#SCH_Client').selectmenu('refresh', true);
      if (raw !== undefined) {
        if ($("#SCH_Client option[value='" + raw.Client_ID + "']").length > 0) {
          $('#SCH_Client').val(raw.Client_ID);
          SCH_ChangeClient(raw, init);
        } else {
          $('#SCH_Client_Text').val(raw.Client_Text);
          $('#SCH_Job_Text').val(raw.Job_Text);
        }
      }
      $('#SCH_Client').selectmenu('refresh', true);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //console.log('Communication Error getting clients');
    if (raw !== undefined) {
      if ($("#SCH_Client option[value='" + raw.Client_ID + "']").length > 0) {
        $('#SCH_Client').val(raw.Client_ID);
        SCH_ChangeClient(raw, init);
      } else {
        $('#SCH_Client').empty();
        $('#SCH_Client').append('<option value="0" selected>N/A</option>');
        $('#SCH_Client').append('<option value="' + raw.Client_ID + '">' + raw.Client_Text + '</option>');
        $('#SCH_Client_Text').val(raw.Client_Text);
        $('#SCH_Job_Text').val(raw.Job_Text);
        SCH_ChangeClient(raw, init);
      }
    } else {
      alert('Failed to load Client List');
      $('#SCH_Client').empty();
      $('#SCH_Client').append('<option value="0" selected>N/A</option>');
      $('#SCH_Client').selectmenu('refresh', true);
    }
    $.mobile.loading('hide');
  });
}

function SCH_ChangeClient(raw, init = false) {
  //console.log('Client Selected');
  //console.log(raw);
  if ($('#SCH_Client').val() == 0) {
    $('#SCH_Job').empty();
    $('#SCH_Job').append('<option value="0" selected>N/A</option>');
    $('#SCH_Job').selectmenu('disable');
    $('#SCH_Job_Text').show();
    $('#SCH_Job').selectmenu('refresh', true);
    $('#SCH_Client_Text').prop('disabled', false);
    $('#SCH_Job_Text').prop('disabled', false);
    $('#SCH_Client_Text').show();
    if (raw !== undefined) {
      $('#SCH_Job_Text').val(raw.Job_Text);
      $('#SCH_Client_Text').val(raw.Client_Text);
    }
    return;
  }
  $('#SCH_Client_Text').val($('#SCH_Client option:selected').text());
  $('#SCH_Client_Text').prop('disabled', true);
  $('#SCH_Client_Text').hide();
  //Get list of jobs for client and populate SCH_Job dropdown select
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL + 'menu-Scheduler-AJAX.php?action=getJobs&PK=' + $('#SCH_Client').val() + '&timezone=' + SCHSTATE.timezone,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Not Available');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'SCH_PopClients');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      } else {
        //console.log('Received Clients Data');
        $('#SCH_Job').empty();
        $('#SCH_Job').append('<option value="0" data-desc="" data-crewmins="0" data-acmins="0" selected>N/A</option>');
        var FoundAvailJob = false;
        var Bases = {};
        for (var i in json_data) {
          var Job = json_data[i];
          Bases[Job.Base] = Job.Base;
          if (
            Job.Base == '' ||
            Job.Base == $('#SCH_Book_Location').val() ||
            Job.Base == undefined ||
            $('#SCH_Book_Location').val() == ''
          ) {
            FoundAvailJob = true;
            $('#SCH_Job').append(
              '<option value="' +
                Job.PrimaryKey +
                '" data-desc="' +
                Job.Description +
                '"  data-crewmins="' +
                Job.CrewMins +
                '" data-acmins="' +
                Job.Min_Hours +
                '">' +
                Job.Name +
                '</option>'
            );
          }
        }
        if (!FoundAvailJob) {
          $('#SCH_Job').empty();
          var BaseList = '';
          for (var i in Bases) {
            BaseList += '[' + Bases[i] + '] ';
          }
          if (BaseList != '') {
            $('#SCH_Job').append(
              '<option value="0" data-desc="" data-crewmins="0" data-acmins="0" selected>N/A - Only Available for Base(s): ' +
                BaseList +
                '</option>'
            );
          } else {
            $('#SCH_Job').append(
              '<option value="0" data-desc="" data-crewmins="0" data-acmins="0" selected>N/A - No Available Jobs</option>'
            );
          }
        }
        //todo check raw data nd select job
        if (raw !== undefined) {
          if ($("#SCH_Job option[value='" + raw.Job_ID + "']").length > 0) {
            $('#SCH_Job').val(raw.Job_ID);
            SCH_ChangeJob(raw, init);
          } else {
            $('#SCH_Job').append(
              '<option value="' +
                raw.Job_ID +
                '" data-desc="' +
                raw.description +
                '" data-crewmins="' +
                raw.CrewMins +
                '" data-acmins="' +
                raw.Min_Hours +
                '">' +
                raw.Job_Text +
                '</option>'
            );
            SCH_ChangeJob(raw, init);
          }
        }
        $('#SCH_Job').selectmenu('enable');
        $('#SCH_Job').selectmenu('refresh', true);
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //console.log('Communication Error getting clients');
    if (raw !== undefined) {
      if ($("#SCH_Job option[value='" + raw.Job_ID + "']").length > 0) {
        $('#SCH_Job').val(raw.Job_ID);
        SCH_ChangeJob(raw, init);
      } else {
        $('#SCH_Job').append(
          '<option value="' +
            raw.Job_ID +
            '" data-desc="' +
            raw.description +
            '" data-crewmins="' +
            raw.CrewMins +
            '" data-acmins="' +
            raw.Min_Hours +
            '">' +
            raw.Job_Text +
            '</option>'
        );
        SCH_ChangeJob(raw, init);
      }
    } else {
      alert('Failed to load Job List');
      $('#SCH_Job').empty();
      $('#SCH_Job').append('<option value="0" selected>N/A</option>');
      $('#SCH_Job').selectmenu('refresh', true);
    }

    $.mobile.loading('hide');
  });
}

function SCH_ChangeJob(raw, init = false) {
  console.log('Job Changed', raw, init);
  SCH_BackupCfields();
  if ($('#SCH_Job').val() == 0) {
    if (raw != undefined) {
      $('#SCH_Job_Text').val(raw.Job_Text);
    }
    $('#SCH_Job_Text').prop('disabled', false);
    $('#SCH_Job_Text').show();
    SCHSaveBooking2(true, true);
    return;
  }
  $('#SCH_Job_Text').val($('#SCH_Job option:selected').text());
  $('#SCH_AircraftMins').val($('#SCH_Job option:selected').attr('data-acmins'));
  $('#SCH_CrewMins').val($('#SCH_Job option:selected').attr('data-crewmins'));
  $('#SCH_Job_Text').prop('disabled', true);
  $('#SCH_Job_Text').hide();
  if (raw != undefined) {
    if (raw.description != '') {
      $('#SCH_Job_Desc').val(raw.description);
    } else {
      $('#SCH_Job_Desc').val($('#SCH_Job option:selected').attr('data-desc'));
    }
  } else {
    $('#SCH_Job_Desc').val($('#SCH_Job option:selected').attr('data-desc'));
  }
  //Save booking and refresh Associated Items in case LockAC or LockCrew enabled for client;
  if (!init) {
    //BILLING_BACKUP_Cfields = null;
    SCH_getJobCfields($('#SCH_Client').val(), true);
  }
}

function SCH_getJobCfields(ClientPK, recreshAssoc) {
  if (SCHEVENTDATA.Associated.Cfields == undefined) {
    SCHEVENTDATA.Associated.Cfields = [];
  }
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    var Jobs = e;
    if (Jobs.length > 0) {
      SCHEVENTDATA.Associated.Cfields = Jobs[0].Cfields;
      for (var i in Jobs) {
        var Job = Jobs[i];
        if (Job.ClientPK == ClientPK) {
          SCHEVENTDATA.Associated.Cfields = Job.Cfields;
          console.log('Cfields', i, Job.Cfields);
          SCH_popCfields(Job.Cfields);
          if (recreshAssoc) {
            SCHSaveBooking2(true, recreshAssoc);
          }
          return;
        }
      }
      SCH_popCfields(Jobs[0].Cfields);
    } else {
      SCHEVENTDATA.Associated.Cfields = [];
      BILLING_BACKUP_Cfields = null;
      $('#ITINERARY_CUSTOM_FILEDS_PR').html('');
      $('#SCH_BookingCfieldsHLDR').html('');
      $('#SCH_BookingCfieldsHLDR').enhanceWithin();
    }
    if (recreshAssoc) {
      SCHSaveBooking2(true, recreshAssoc);
    }
  });
}

function SCH_BackupCfields() {
  if (SCHEVENTDATA.Associated.Cfields == undefined) {
    SCHEVENTDATA.Associated.Cfields = [];
  }
  if (SCHEVENTDATA.Associated.Cfields.length > 0) {
    BILLING_BACKUP_Cfields = JSON.parse(JSON.stringify(SCHEVENTDATA.Associated.Cfields));
    for (var i in BILLING_BACKUP_Cfields) {
      var I = BILLING_BACKUP_Cfields[i];
      if (I.type == 'checkbox') {
        I.Value = $('#BI_CF_' + I.PrimaryKey).is(':checked') ? 1 : 0;
      } else {
        I.Value = $('#BI_CF_' + I.PrimaryKey).val();
      }

    }
  } else {
    BILLING_BACKUP_Cfields = null;
  }
}

function SCH_popCfields(CF) {
  var html = '<h2>' + iTrans('Additional Flight Information') + '<h2>';
  var requiredCF = '';
  if (CF.length > 0) {
    //html += "<h3>"+ iTrans("Additional Fields") +"</h3>";
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != '') {
        continue;
      }
      if (!checkCfieldType('SCH', I) && !checkCfieldType('SCHA', I, hasSchedulerAccess('admin'))) continue;
      if (I.PerLeg == 0) {
        if (BILLING_BACKUP_Cfields == null) {
          if (I.reqSch == 1) {
            requiredCF += createCustomField('', I, false, 'Sch');
          } else {
            html += createCustomField('', I, false, 'Sch');
          }
        } else {
          var foundVal = false;
          var oldVal = '';
          for (var c in BILLING_BACKUP_Cfields) {
            var C = BILLING_BACKUP_Cfields[c];
            if (C.PrimaryKey == I.PrimaryKey) {
              foundVal = true;
              oldVal = C.Value;
            }
          }
          if (foundVal) {
            if (I.reqSch == 1) {
              requiredCF += createCustomField('', I, oldVal, 'Sch');
            } else {
              html += createCustomField('', I, oldVal, 'Sch');
            }
          } else {
            if (I.reqSch == 1) requiredCF += createCustomField('', I, false, 'Sch');
            else html += createCustomField('', I, false, 'Sch');
          }
        }
      }
    }
    html += '</div></div>';
  } else {
    html = '';
  }
  SCHEVENTDATA.Associated.Cfields = CF;
  if (BILLING_BACKUP_Cfields != null) {
    console.log('restoreBAckupCfields');
    SCHEVENTDATA.Associated.Cfields = JSON.parse(JSON.stringify(BILLING_BACKUP_Cfields));
  }
  BILLING_BACKUP_Cfields = null;
  $('#ITINERARY_CUSTOM_FILEDS_PR').html('');
  $('#SCH_BookingCfieldsHLDR').html(requiredCF + html);
  $('#SCH_BookingCfieldsHLDR').enhanceWithin();
}

function SCHSaveBooking(SilentSave, refreshAssoc = false, _next = null) {
  if (SCHEVENTDATA.Associated.MultiBooking === true) {
    var start = $('#SCH_Book_sDate').val() + ' ' + $('#SCH_Book_sTime').val() + ':00';
    var end = $('#SCH_Book_eDate').val() + ' ' + $('#SCH_Book_eTime').val() + ':00';
    if (SCHEVENTDATA.start != start || SCHEVENTDATA.end != end) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'WARNING',
        headerClose: false,
        buttonPrompt:
          'This is a multi booking, resizing the event could result in existing associated items being either <b>clipped or deleted</b> to fit the new start and end times.  Are you sure you wish to do this?',
        buttons: {
          Yes: {
            click: function () {
              SCHSaveBooking2(SilentSave, refreshAssoc, false, _next);
            },
          },
          Cancel: {
            click: function () {
              //do nothing
            },
          },
        },
      });
    } else {
      SCHSaveBooking2(SilentSave, refreshAssoc, false, _next);
    }
  } else {
    SCHSaveBooking2(SilentSave, refreshAssoc, false, _next);
  }
}

function SCH_ValidateCfields(CF) {
  if (CF != null) {
    if (CF.length > 0) {
      for (var i in CF) {
        var I = CF[i];
        if (!checkCfieldType('SCH', I) && !checkCfieldType('SCHA', I, hasSchedulerAccess('admin'))) continue;
        if (I.PerLeg == 0) {
          var checked = 0;
          if (I.type == 'checkbox') {
            if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
            I.Value = checked;
            if (I.reqSch == 1 && checked == 0) {
              BillingValidation('[' + I.Label + '] is required to be checked.');
              return false;
            }
          } else {
            I.Value = $('#BI_CF_' + I.PrimaryKey).val();
            if (I.Value == undefined) I.Value = '';
            if (
              (I.Value == '' && I.reqSch == 1) ||
              (I.Value == 'false' && I.reqSch == 1 && I.type == 'signature') ||
              (I.Value == null && I.reqSch == 1) ||
              (I.Value == ' ' && I.reqSch == 1)
            ) {
              BillingValidation('[' + I.Label + '] is a required field and is empty.');
              return false;
            }
          }
        }
      }
    }
  }
  return true;
}

function SCHSaveBooking2(SilentSave, refreshAssoc = false, refresh = false, _next = null) {
  if (SCHEVENTDATA == undefined) {
    return;
  }
  if (SCHSTATE.activeToast != null) {
    toaster.remove(SCHSTATE.activeToast);
  }
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  // Save booking information
  //Add applicable Aircraft
  //Add Applicable Staff
  //Add Attachments
  var PrimaryKey = SCHEVENTDATA.PrimaryKey;
  var Client_Text = $('#SCH_Client_Text').val();
  var Client_ID = $('#SCH_Client').val();
  var Job_Text = $('#SCH_Job_Text').val();
  var Job_ID = $('#SCH_Job').val();
  var Job_Desc = $('#SCH_Job_Desc').val();
  var notes = $('#SCH_notes').val();
  var Category = $('#SCH_CatFilter').val();
  var FlightTime = $('#SCH_FlightTime').val();
  var CrewMins = $('#SCH_CrewMins').val();
  var AircraftMins = $('#SCH_AircraftMins').val();
  var start = $('#SCH_Book_sDate').val() + ' ' + $('#SCH_Book_sTime').val() + ':00';
  var end = $('#SCH_Book_eDate').val() + ' ' + $('#SCH_Book_eTime').val() + ':00';
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  if ($('#SCH_Book_sTime').val().length == 5) {
    start += ':00';
  }
  if ($('#SCH_Book_eTime').val().length == 5) {
    end += ':00';
  }
  var ADMIN_ONLY = 0;
  if ($('#SCH_Book_Admin').is(':checked')) {
    ADMIN_ONLY = 1;
  }
  var UNIT = $('#SCH_Book_Location').val();
  var Status = $("input[type='radio'][name='BOOKSTATUS']:checked").val();
  var Reminder = $("input[type='radio'][name='BOOKREMINDER']:checked").val();

  var PerReportFields = [];
  if (SCHEVENTDATA.Associated.Cfields == undefined) {
    SCHEVENTDATA.Associated.Cfields = [];
  }
  var CF = SCHEVENTDATA.Associated.Cfields;
  if (CF.length > 0) {
    if (!SilentSave) {
      if (!SCH_ValidateCfields(CF)) {
        return;
      }
    }
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != '') {
        continue;
      }
      if (!checkCfieldType('SCH', I) && !checkCfieldType('SCHA', I, hasSchedulerAccess('admin'))) {
        PerReportFields.push(I);
        continue;
      }
      if (I.PerLeg == 0) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
      }
      PerReportFields.push(I);
    }
  }
  var Cfields = JSON.stringify(PerReportFields);
  //check if multibooking and time has changed

  $.mobile.loading('show', {
    theme: 'a',
  });
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Scheduler-AJAX.php?action=updateBooking&DN=' + DN + '&timezone=' + SCHSTATE.timezone,
    data: {
      BookingPK: PrimaryKey,
      Status: Status,
      Client_ID: Client_ID,
      Client_Text: Client_Text,
      Reminder: Reminder,
      Job_ID: Job_ID,
      Job_Text: Job_Text,
      Job_Desc: Job_Desc,
      notes: notes,
      AircraftMins: AircraftMins,
      CrewMins: CrewMins,
      Cfields: Cfields,
      Category: Category,
      FlightTime: FlightTime,
      start: start,
      end: end,
      ADMIN_ONLY: ADMIN_ONLY,
      UNIT: UNIT,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      //console.log('ServerResponse: check');
      //console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'SCHSAveBooking()');
      }
      if (data[0].ServerResponse == 'Success') {
        //todo update event with new data
        var conflicts = data[0].Conflicts;
        for (var i in conflicts) {
          var I = conflicts[i];
          cirroDB.Delete(
            'Scheduler_Individual',
            'PrimaryKey = ? OR PrimaryKey LIKE ?',
            [I.PrimaryKey, I.PrimaryKey + '_%'],
            function (e) {
              if (e === false) console.log('Failed to delete SCH: ' + I.PrimaryKey);
              else console.log('Deleted SCH: ' + I.PrimaryKey);
            }
          );
        }

        var d = SCHEVENTDATA;
        d.Status = Status;
        d.Client_ID = Client_ID;
        d.Client_Text = Client_Text;
        d.Job_ID = Job_ID;
        d.Job_Text = Job_Text;
        d.description = Job_Desc;
        d.Flight_Time = FlightTime;
        d.start = start;
        d.end = end;
        d.ADMIN_ONLY = ADMIN_ONLY;
        d.UNIT = UNIT;

        SCHLASTSAVED = d;

        if (!SilentSave) {
          //$('label[for=\'SHC_DisableNotifications\']').removeClass('redbg');
          //$('#SHC_DisableNotifications').attr('checked', false).checkboxradio('refresh');
          SCHaddEvent(d, true);
        } else {
          if (refreshAssoc) {
            SCH_BackupCfields();
            SCHbookAssociated(true);
          }
          if (refresh) {
            SCHEditEvent(d, true);
          }
          if (typeof _next === 'function') {
            _next();
          }
        }
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed!',
          headerClose: false,
          buttonPrompt:
            'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
          buttons: {
            Close: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            'Discard Changes': {
              click: function () {
                //File bug report
                SCHschedulerBack();
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $.mobile.loading('hide');
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Failed!',
        headerClose: false,
        buttonPrompt:
          'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
        buttons: {
          Close: {
            click: function () {},
          },
          'Discard Changes': {
            click: function () {
              //File bug report
              SCHschedulerBack();
            },
          },
        },
      });
    });
}

function SCHDeleteBooking(silent = false) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  var DN = $('#SHC_DisableNotifications').is(':checked');
  var checked = '';
  if (DN == true) {
    checked = 'checked';
  }

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt:
      '<label for="SCHDisableNotifyCHK">Silence Delete Notifications</label><input type="checkbox" id="SCHDisableNotifyCHK" data-mini="true" ' +
      checked +
      '/><br />Are  you sure you want to delete this booking?',
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          var DN = $('#SCHDisableNotifyCHK').is(':checked');
          if (DN) {
            DN = 1;
            $('#SHC_DisableNotifications').prop('checked', true);
          } else {
            DN = 0;
            $('#SHC_DisableNotifications').prop('checked', false);
          }
          $.mobile.loading('show', {
            theme: 'a',
          });
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=removeBooking&DN=' +
              DN +
              '&PK=' +
              SCHEVENTDATA.PrimaryKey +
              '&timezone=' +
              SCHSTATE.timezone,
            function (json_data) {
              if (json_data[0].ServerResponse != undefined) {
                if (json_data[0].ServerResponse == 'Offline') {
                  //console.log('Offline Scheduler Not Available');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  ServerResponse(json_data[0], 'SCH_PopClients');
                }
                if (json_data[0].ServerResponse == 'Not Logged In') {
                  NOTLOGGEDIN();
                }
                if (json_data[0].ServerResponse == 'Failed') {
                  $('<div>').simpledialog2({
                    mode: 'button',
                    animate: false,
                    headerText: 'Failed!',
                    headerClose: false,
                    buttonPrompt:
                      'There was a problem removing the booking.  Please try again. If this continues to happen please submit a bug report.',
                    buttons: {
                      Close: {
                        click: function () {},
                      },
                      Report: {
                        click: function () {
                          //File bug report
                        },
                      },
                    },
                  });
                }
                if (json_data[0].ServerResponse == 'Success') {
                  LastSCHEDULEsync = stringToDateObject(json_data[0].LastSCHEDULEsync);
                  if (!silent) {
                    SCHupdate(true);
                  } else {
                    dp.events.list = [];
                    dp.update();
                    Manual_ResyncSCHEDULE();
                  }
                }
              }
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error removing booking');
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt:
                'There was a problem removing the booking.  Please try again. If this continues to happen please submit a bug report.',
              buttons: {
                Close: {
                  click: function () {},
                },
                Report: {
                  click: function () {
                    //File bug report
                  },
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCHbookAssociated(refreshAssoc = false) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    $.mobile.loading('hide');
    return;
  }
  $('#SCH_Book_AircraftBTN').show();
  $('#SCH_Book_StaffBTN').show();
  $('#SCH_Book_AircraftBTN2').show();
  $('#SCH_Book_StaffBTN2').show();
  $('#SCH_Book_VehiclesBTN').show();
  $('#SCH_Book_VehiclesBTN2').show();
  var start = $('#SCH_Book_sDate').val() + ' ' + $('#SCH_Book_sTime').val() + ':00';
  var end = $('#SCH_Book_eDate').val() + ' ' + $('#SCH_Book_eTime').val() + ':00';
  //get list of aircraft from server
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=getAssociated&start=' +
      encodeURIComponent(start) +
      '&end=' +
      encodeURIComponent(end) +
      '&PK=' +
      SCHEVENTDATA.PrimaryKey +
      '&timezone=' +
      SCHSTATE.timezone,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Not Available');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'SCHbookAircraft');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      } else {
        //display the data
        SCHEVENTDATA.Associated = json_data;
        if (refreshAssoc == false) {
          SCHdisplayAssociated();
        } else {
          SCH_getJobCfields($('#SCH_Client').val());
        }
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $.mobile.loading('hide');
    //console.log('Communication Error getting data');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Failed!',
      headerClose: false,
      buttonPrompt:
        'There was a problem Retrieving aircraft list.  Please try again. If this continues to happen please submit a bug report.',
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}

function SCHsortBookedStaff(BookedStaff) {
  var RolesFilled = true;
  var ReqRoles = {};
  for (var i in GROUP_DATA.SCH_Roles) {
    if (GROUP_DATA.SCH_Roles[i].Req == true) {
      ReqRoles[i] = false;
    }
  }
  for (var i in BookedStaff) {
    if (BookedStaff[i].Role == undefined || BookedStaff[i].Role == 'None') {
      BookedStaff[i].Role == 'None';
      BookedStaff[i].SortIndex = -1;
    } else {
      var Role = (element) => element.Label == BookedStaff[i].Role;
      BookedStaff[i].SortIndex = GROUP_DATA.SCH_Roles.findIndex(Role);
      if (ReqRoles[BookedStaff[i].SortIndex] != undefined) {
        ReqRoles[BookedStaff[i].SortIndex] = true;
      }
    }
  }

  function sortName(a, b) {
    return a.chatname.localeCompare(b.chatname);
  }

  function sortIndex(a, b) {
    if (a.SortIndex < b.SortIndex) {
      return -1;
    }
    if (a.SortIndex > b.SortIndex) {
      return 1;
    }
    return 0;
  }

  BookedStaff.sort(sortName);
  BookedStaff.sort(sortIndex);
  var MissingRoles = [];
  for (var i in ReqRoles) {
    if (ReqRoles[i] == false) {
      RolesFilled = false;
      MissingRoles.push(GROUP_DATA.SCH_Roles[i].Label);
    }
  }
  if (!RolesFilled) {
    return MissingRoles;
  }
  return RolesFilled;
}

function SCHdisplayAssociated() {
  var data = SCHEVENTDATA.Associated;
  $('#SCH_Book_Aircraft').html('');
  var BookedAC = data.BookedAC;
  var BookedV = data.BookedV;
  var FreeAC = data.FreeAC;
  var BookedStaff = data.BookedStaff;
  var FreeStaff = data.FreeStaff;
  var Attachments = data.Attachments;
  var Forms = data.Forms;

  var html = '';
  if (BookedAC.length) {
    html =
      "<h3>Aircraft associated with booking</h3><table class='second-td-fill' id='SCH_Book_AircraftTBL'><thead><tr><th>Ident</th><th>Status</th><th>Dates</th><th>Type</th><th>Itin</th><th>Action</th></tr></thead><tbody>";
    for (var i in BookedAC) {
      var AC = BookedAC[i];
      var lclass = '';
      var ServiceTXT = AC.ServiceReason;
      if (AC.Serviceable == 0) {
        ServiceTXT = 'NOT-SERVICEABLE: ' + AC.ServiceReason;
      }
      if (AC.Conflict == 1 || AC.Serviceable == 0) {
        lclass = 'redbg';
      }
      if (AC.Template != '') {
        AC.Status = 'Itinerary Planned';
      } else {
        AC.Status = 'No Plan';
      }

      var LegClass = '';
      if (AC.Quote != null) {
        if (AC.Quote.Legs.length > 0) {
          LegClass = ' greenbg';
        }
      }
      var SCHlegs = '';
      var SCHLegTXT = '';
      if (GROUP_DATA.Quoting == 1) {
        SCHlegs =
          "<button class='action-btn" +
          LegClass +
          "' data-mini='true' onClick='SCH_LegEntry(" +
          i +
          ")'><img src='./images/NewLeg.svg'/></button>";
        SCHLegTXT = SCH_GetACLegTXT(AC) + '<br />';
      }
      var paxMan = '';

      if (GROUP_DATA.Pax_Man == 1) {
        var paxManClass = '';
        if (AC.Pax_Manifest != undefined) {
          if (AC.Pax_Manifest.DefaultList.length > 0 || AC.Pax_Manifest.Pax_Legs.length > 0) {
            paxManClass = 'greenbg';
            if (AC.Pax_Manifest.HasVIP == 1) paxManClass = 'vip-following';
          }
        }
        paxMan =
          "<button class='action-btn' data-mini='true' onClick='SCH_LegEntry(" +
          i +
          ")'><img class='" +
          paxManClass +
          "' src='./images/paxman.svg'/></button>";
      }
      html +=
        "<tr class='" +
        lclass +
        "'><td style='width:100px;'>" +
        AC.ident +
        '</td><td class="wrap">' +
        SCHLegTXT +
        ServiceTXT +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Aircraft\', \'' +
        i +
        '\')" onmouseout="HideEventNav()">' +
        SCH_getHumanTime(AC.start) +
        '↵<br />' +
        SCH_getHumanTime(AC.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="AircraftEventNav' +
        i +
        '"></div></div></td><td>' +
        AC.AcType +
        '</td><td>' +
        AC.Status +
        "</td><td><div class='vertical-center-container'>" +
        SCHlegs +
        paxMan +
        "<button style='background-color: #f05a40 !important;' class='action-btn' data-mini='true' value='" +
        AC.ident +
        "' onClick='SCHnewFlightAircraft(this," +
        AC.PrimaryKey +
        ")' ><img src='./images/svg-icons/new-itin-airplane.svg' /></button><button class='action-btn' data-mini='true' onClick='SCHremoveAircraft(" +
        AC.PrimaryKey +
        ', ' +
        AC.ItemPrimaryKey +
        ")' ><img src='./images/delete.svg' /></button></div></td></tr>";
    }
    html += '</tbody></table>';
  } else {
    html = '<h3>No aircraft added to booking</h3>';
  }

  $('#SCH_Book_Aircraft').html(html);
  $('#SCH_Book_Aircraft').enhanceWithin();
  AddTableSorter('SCH_Book_AircraftTBL');

  if (BookedV.length) {
    html =
      "<h3>Vehicles associated with booking</h3><table class='second-td-fill' id='SCH_Book_VehiclesTBL'><thead><tr><th>Label</th><th>Status</th><th>Dates</th><th>Plate</th><th>Action</th></tr></thead><tbody>";
    for (var i in BookedV) {
      var AC = BookedV[i];
      var lclass = '';
      var ServiceTXT = AC.ServiceReason;
      if (AC.Serviceable == 0) {
        ServiceTXT = 'NOT-SERVICEABLE: ' + AC.ServiceReason;
      }
      if (AC.Conflict == 1 || AC.Serviceable == 0) {
        lclass = 'redbg';
      }
      if (AC.Template != '') {
        AC.Status = 'Itinerary Planned';
      } else {
        AC.Status = 'No Plan';
      }
      html +=
        "<tr class='" +
        lclass +
        "'><td style='width:100px;'>" +
        AC.Label +
        '</td><td class="wrap">' +
        ServiceTXT +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Vehicle\',' +
        i +
        ')" onmouseout="HideEventNav()">' +
        SCH_getHumanTime(AC.start) +
        '↵<br />' +
        SCH_getHumanTime(AC.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="VehicleEventNav' +
        i +
        '"></div></div></td><td>' +
        AC.licenceplate +
        "</td><td><div class='vertical-center-container'><button class='action-btn' data-mini='true' onClick='SCHremoveVehicle(" +
        AC.PrimaryKey +
        ', ' +
        AC.ItemPrimaryKey +
        ")' ><img src='./images/delete.svg' /></button></div></td></tr>";
    }
    html += '</tbody></table>';
  } else {
    html = '<h3>No vehicles added to booking</h3>';
  }
  $('#SCH_Book_Vehicles').html(html);
  $('#SCH_Book_Vehicles').enhanceWithin();
  AddTableSorter('SCH_Book_VehiclesTBL');

  //todo display staff
  var RoleTXT = '';
  var html2 = '';
  var RolesFilled = SCHsortBookedStaff(BookedStaff);
  if (RolesFilled !== true) {
    RoleTXT += '<h3 class="yellowbg">Missing Role Assignments:<br />';
    for (var R in RolesFilled) {
      if (R == 0) {
        RoleTXT += '- ' + RolesFilled[R];
      } else {
        RoleTXT += '<br />- ' + RolesFilled[R];
      }
    }
    RoleTXT += '</h3>';
  }
  if (BookedStaff.length) {
    html2 +=
      '<h3>Staff associated with booking</h3>' +
      RoleTXT +
      "<table class='first-td-fill' id='SCH_BookedStaffTBL'><thead><tr><th>Name</th><th>Dates</th><th>Type</th><th>Role</th><th>Aircraft</th><th>Del</th></tr></thead><tbody>";
    for (var s in BookedStaff) {
      var Staff = BookedStaff[s];
      let AircraftOpt = `<select id='BS-${Staff.PrimaryKey}' data-role='none' onChange='SCHacStaff(${Staff.PrimaryKey}, ${Staff.ItemPrimaryKey})'>`;
      AircraftOpt += '<option value="0">N/A</option>';
      for (var ac of BookedAC) {
        AircraftOpt += "<option value='" + ac.PrimaryKey + "'>" + ac.ident + '</option>';
      }
      AircraftOpt += '</select>';

      let RoleOpt = `<select id='RoleS-${Staff.PrimaryKey}' data-role='none' onChange='SCHroleStaff(${Staff.PrimaryKey}, ${Staff.ItemPrimaryKey})'>`;
      RoleOpt += '<option value="None">None</option>';
      for (var R in GROUP_DATA.SCH_Roles) {
        RoleOpt +=
          "<option value='" + GROUP_DATA.SCH_Roles[R].Label + "'>" + GROUP_DATA.SCH_Roles[R].Label + '</option>';
      }
      RoleOpt += '</select>';

      var sclass = '';
      var extra = '';
      if (Staff.ACK == 0) {
        sclass = 'yellowbg';
        extra = ' [' + iTrans('Not Acknowledged') + ']<br />';
      } else {
        extra = ' [Ack' + Staff.ACK_Date + ']<br />';
      }
      if (Staff.Conflict == 1) {
        sclass = 'redbg';
      }

      var StaffExpand =
        '<button data-role="none" class="collapsibleMK" id="SCHADMINSTAFFSTATUS_BTN_' +
        Staff.PrimaryKey +
        '"><b>' +
        Staff.chatname +
        '</b> <span id="SCHADMINSTAFFSTATUS_status_' +
        Staff.PrimaryKey +
        '"><i>*</i></span></button><div id="SCHADMINSTAFFSTATUS_' +
        Staff.PrimaryKey +
        '" class="contentMK"></div>';

      html2 +=
        "<tr class='" +
        sclass +
        '\'><td id="SCHADMINSTAFFSTATUS_TR_' +
        Staff.PrimaryKey +
        '">' +
        StaffExpand +
        '</td><td style="position:relative;" onmouseover="ShowEventNav(\'Staff\',' +
        s +
        ')" onmouseout="HideEventNav()">' +
        extra +
        SCH_getHumanTime(Staff.start) +
        '↵<br />' +
        SCH_getHumanTime(Staff.end) +
        '<div style="position:absolute;top:0;pointer-events: none;z-index:2000;"><div id="StaffEventNav' +
        s +
        '"></div></div></td><td>' +
        Staff.StaffType +
        '</td><td>' +
        RoleOpt +
        '</td><td id="SCHADMINSTAFFSTATUS_TD_AC_' +
        Staff.PrimaryKey +
        '">' +
        AircraftOpt +
        "</td><td><div class='vertical-center-container'><button class='action-btn' data-mini='true' onClick='SCHremoveStaff(" +
        Staff.PrimaryKey +
        ', ' +
        Staff.ItemPrimaryKey +
        ")' ><img src='./images/delete.svg' /></button></div></td></tr>";
    }
    html2 += '</tbody></table>';
  } else {
    html2 += '<h3>No staff added to booking</h3>' + RoleTXT;
  }

  $('#SCH_Book_Staff').html(html2);
  AddTableSorter('SCH_BookedStaffTBL');
  //
  for (var staff of BookedStaff) {
    $('#BS-' + staff.PrimaryKey).val(staff.AC_ID);

    if (staff.Role != undefined) {
      if ($(`#RoleS-${staff.PrimaryKey} option[value='${staff.Role}']`).length > 0) {
        $('#RoleS-' + staff.PrimaryKey).val(staff.Role);
      } else {
        $('#RoleS-' + staff.PrimaryKey).val('None');
      }
    }
  }
  $('#SCH_Book_Staff').enhanceWithin();

  //todo display attachments
  var html3 = '';
  if (Attachments.length) {
    html3 =
      "<h3>Attachments associated with booking</h3><table class='first-td-fill' id='SCH_AttachmentsTBL'><thead><tr><th>Filename</th><th>View</th><th>Del</th></tr></thead><tbody>";
    for (var a in Attachments) {
      var Attachment = Attachments[a];
      html3 +=
        '<tr><td>' +
        Attachment.filename +
        '</td><td>' +
        '<a href="' +
        BASEURL +
        'menu-Scheduler-AJAX.php?action=viewAttachment&PrimaryKey=' +
        Attachment.PrimaryKey +
        '&BookingPK=' +
        SCHEVENTDATA.PrimaryKey +
        '" data-ajax="false" target="_blank" data-role="button" class="action-btn" data-mini="true">' +
        "<img src='./images/inspect.svg' /></a></td><td><div class='vertical-center-container'><button class='action-btn' data-mini='true' onClick='SCHremoveAttachment(" +
        Attachment.PrimaryKey +
        ")' ><img src='./images/delete.svg' /></button></div></td></tr>";
    }
    html3 += '</tbody></table>';
  } else {
    html3 = '<h3>No attachments added to booking</h3>';
  }

  $('#SCH_Book_Attachment').html(html3);
  AddTableSorter('SCH_AttachmentsTBL');
  if (GROUP_DATA.PULSAR_FRMS == 1) {
    //PULSAR_GetScheduleStaffBooked(BookedStaff, 'SCHBOOKING_Users', 0);
  }
  SCH_PopStatus_BookedStaff('SCHADMINSTAFFSTATUS');

  // display forms if they have form Module
  if (FORMS > 0) {
    FormsInBookings.clearInstance();
    FormsInBookings.instance(
      FormsInBookings.PAGE_EDITOR,
      '#bookingFormPickerHolder',
      Forms,
      SCHEVENTDATA.PrimaryKey,
      FormBridge.EL_ADMIN
    );
  }
}

function SCH_LegEntry(index, view = false, editLeg = false) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  var AC = SCHEVENTDATA.Associated.BookedAC[index];
  BILLING_Reset_CURRENT_QUOTE();
  CURRENT_QUOTE.Scheduler = true;
  CURRENT_QUOTE.SCH_AircraftPK = AC.ItemPrimaryKey;
  CURRENT_QUOTE.flightCatID = 0;
  CURRENT_QUOTE.SCH_INDEX = index;
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e == false) {
      e = [];
    }
    for (var i in e) {
      if (e[i].JobID == SCHEVENTDATA.Job_ID && e[i].ClientPK == SCHEVENTDATA.Client_ID) {
        CURRENT_QUOTE.flightCatID = e[i].flightCatID;
        CURRENT_QUOTE.Job = e[i];
        break;
      }
    }
    CURRENT_QUOTE.JobPK = SCHEVENTDATA.Job_ID;
    CURRENT_QUOTE.ClientPK = SCHEVENTDATA.Client_ID;
    CURRENT_QUOTE.SCH_AC_DATA = JSON.parse(JSON.stringify(AC));
    $('#UniqueLegBTN').prop('disabled', false);
    ITIN_Pax_Manifest.CurLeg = -1;
    if (AC.Quote == null && !view) {
      var Aircraft = {
        PrimaryKey: 0,
        AC_Ident: AC.ident,
        AcType: AC.AcType,
        Cruise: AC.Cruise,
        Currency: AC.hourly_rate_currency,
        EndTimezone: CurrentTimeZone,
        Legs: [],
        QuotePK: 0,
        Rate: AC.hourly_rate_cents,
        Rate_Unit: 'Hour',
        SCH_AircraftPK: AC.ItemPrimaryKey,
        StartTimezone: CurrentTimeZone,
        Tax: '0.00',
        TaxiTime: 0,
        UniqueLegs: 1,
        group: AC.group,
        Pax_Manifest: { DefaultList: [], HasVIP: 0, NoResult: false, Pax_Legs: [] },
      };
      ExchangeQuoteData('UpdateQuoteAircraft', Aircraft, function (data) {
        if (Aircraft.PrimaryKey == 0) {
          Aircraft.PrimaryKey = data[0].InsertPK;
        }
        CURRENT_QUOTE.Aircraft[0] = Aircraft;
        $.mobile.loading('hide');
        ITIN_Pax_Manifest.Scheduler = true;
        BILLING_AddQuoteLegs();
        if (editLeg !== false) {
          BILLING_editQuoteLeg(editLeg, -1);
        }
      });
    } else {
      $.mobile.loading('hide');
      CURRENT_QUOTE.Aircraft[0] = AC.Quote;
      if (view) {
        var html = '';
        if (AC.Quote.Legs.length > 0) {
          var UniqueACIdent = '<h4>' + AC.Quote.AC_Ident + ' (' + AC.Quote.AcType + ')</h4>';
          var UniqueLegList = BILLING_GenerateLegList(
            CURRENT_QUOTE,
            BILLING_getTimezoneHTML(AC.Quote),
            AC.Quote.Legs,
            false,
            false,
            AC.Pax_Manifest.Pax_Legs,
            true
          );
          html = UniqueACIdent + UniqueLegList;
        }
        if (GROUP_DATA.Pax_Man == 1) {
          var legPaxTotalWeight = 0;
          var paxSummary = '';
          if (CURRENT_QUOTE.SCH_AC_DATA.Pax_Manifest != undefined) {
            var PaxLeg = CURRENT_QUOTE.SCH_AC_DATA.Pax_Manifest.DefaultList;
            if (PaxLeg.length > 0) {
              paxSummary += '<h3>' + iTrans('Default Passenger Manifest List') + '</h3>';
              for (var p in PaxLeg) {
                let weightUnit = 'Imperial';
                if (PaxLeg[p].units != 'imperial') {
                  weightUnit = 'Metric';
                }
                let paxVIPclass = '';
                if (PaxLeg[p].VIP == 1) paxVIPclass = 'yellowbg';
                paxSummary +=
                  '<span class="' +
                  paxVIPclass +
                  '">' +
                  PaxLeg[p].lastname +
                  ', ' +
                  PaxLeg[p].firstname +
                  ' (' +
                  PaxLeg[p].weight +
                  (PaxLeg[p].units == 'imperial' ? ' lbs' : ' kg') +
                  ')' +
                  '</span><br />';
                legPaxTotalWeight += parseInt(convertToUserUNITS(PaxLeg[p].weight, 'Weight', weightUnit, 'Imperial'));
              }
              html +=
                '<br /><b>' +
                iTrans('Pax Manifest') +
                ': ' +
                '(' +
                legPaxTotalWeight +
                ' lbs ' +
                ' ' +
                iTrans('Total') +
                ')</b><br />';
              html += paxSummary;
            }
          }
        }
        $.mobile.changePage($('#Quote_ViewLegs'), {
          reverse: false,
          changeHash: false,
        });
        $('#Quote_ViewLegContent').html(html).enhanceWithin();
      } else {
        ITIN_Pax_Manifest.Scheduler = true;
        BILLING_AddQuoteLegs();
        if (editLeg !== false) {
          BILLING_editQuoteLeg(editLeg, -1);
        }
      }
    }
  });
}

function SCH_GetACLegTXT(AC) {
  if (AC.Quote != null) {
    var Legs = AC.Quote.Legs;
    if (Legs.length > 0) {
      var TXT = '(' + Legs[0].FromICAO;
      for (var i in Legs) {
        var L = Legs[i];
        TXT += ' --> ' + L.ToICAO;
      }
      TXT += ')';
      return TXT;
    }
  }
  return '';
}

function SCH_ViewItemBack() {
  $('#Quote_ViewLegContent').html('');
  $.mobile.changePage($('#Schedule_ViewItem'), {
    reverse: false,
    changeHash: false,
  });
}

function SCH_QuoteLegsComplete() {
  SCH_EditItemBack();
  SCHbookAssociated(); //reload new data and display
}

function SCH_EditItemBack() {
  $.mobile.changePage($('#Schedule_Item'), {
    reverse: false,
    changeHash: false,
  });
  $('#SCH_Timezone_Booking').val(LOCALSTORAGE.SCH_UTCmode).selectmenu('refresh');
  SCH_SetQuickPickBasedOnTz(true);
}

function SCH_PopStatus_BookedStaff(idHead) {
  var BookedStaff = SCHEVENTDATA.Associated.BookedStaff;
  if (BookedStaff.length) {
    for (var i in BookedStaff) {
      var User = BookedStaff[i];
      $('#' + idHead + '_status_' + User.PrimaryKey).html(
        '<i>' + iTrans('Updating FDT and Qualification Status') + '...</i>'
      );
      SCH_GetPilotFDT(idHead, User);
    }
  }
}

function SCH_VerifTime(e) {
  var id = e.id;
  $('#' + e.id).val(FormatLegTime(e.value));
}

function ShowEventNav(type, index) {
  var Item;
  var start;
  var end;
  var div;
  if (type == 'Staff') {
    Item = SCHEVENTDATA.Associated.BookedStaff[index];
    start = new DayPilot.Date(Item.start).addMinutes(-SCHSTATE.timezone);
    end = new DayPilot.Date(Item.end).addMinutes(-SCHSTATE.timezone);
    div = 'StaffEventNav' + index;
  }
  if (type == 'Aircraft') {
    Item = SCHEVENTDATA.Associated.BookedAC[index];
    start = new DayPilot.Date(Item.start).addMinutes(-SCHSTATE.timezone);
    end = new DayPilot.Date(Item.end).addMinutes(-SCHSTATE.timezone);
    div = 'AircraftEventNav' + index;
  }
  if (type == 'Vehicle') {
    Item = SCHEVENTDATA.Associated.BookedV[index];
    start = new DayPilot.Date(Item.start).addMinutes(-SCHSTATE.timezone);
    end = new DayPilot.Date(Item.end).addMinutes(-SCHSTATE.timezone);
    div = 'VehicleEventNav' + index;
  }
  //console.log(Item);
  if (EventNav != null) {
    EventNav.dispose();
  }
  EventNav = new DayPilot.Navigator(div);
  EventNav.startDate = start;
  EventNav.showMonths = 1;
  EventNav.selectMode = 'month';
  EventNav.selectionStart = start;
  EventNav.selectionEnd = end;
  EventNav.events.list = [{ start: start, end: end }];
  EventNav.onBeforeCellRender = function (args) {
    if (args.cell.isCurrentMonth) {
      args.cell.cssClass = 'current-month';
    }
  };
  EventNav.init();
}

function HideEventNav() {
  if (EventNav != null) {
    EventNav.dispose();
  }
  EventNav = null;
}

function SCHremoveAttachment(i) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete this attachment?',
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          $.mobile.loading('show', {
            theme: 'a',
          });
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=bookingDeleteFile&PrimaryKey=' +
              i +
              '&BookingPK=' +
              SCHEVENTDATA.PrimaryKey +
              '&timezone=' +
              SCHSTATE.timezone,
            function (json_data) {
              if (json_data.length > 0) {
                if (json_data[0].ServerResponse == 'Offline') {
                  //console.log('Offline Scheduler Not Available');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  ServerResponse(json_data[0], 'SCHbookAircraft');
                }
                if (json_data[0].ServerResponse == 'Not Logged In') {
                  NOTLOGGEDIN();
                }
                if (json_data[0].ServerResponse == 'Success') {
                  SCHbookAssociated();
                } //reload associated data for booking
              }
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error getting data');
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt:
                'There was a problem Retrieving aircraft list.  Please try again. If this continues to happen please submit a bug report.',
              buttons: {
                Close: {
                  click: function () {},
                },
                Report: {
                  click: function () {
                    //File bug report
                  },
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCHacStaff(staffPK, itemPK) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  var data = SCHEVENTDATA.Associated;
  var Staff_ID = staffPK;
  var AC_ID = $('#BS-' + staffPK).val();
  var DN = $('#SHC_DisableNotifications').is(':checked');
  var ItemPrimaryKey = itemPK;
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=addStaffAircraft&Staff_ID=' +
      Staff_ID +
      '&AC_ID=' +
      AC_ID +
      '&BookingPK=' +
      SCHEVENTDATA.PrimaryKey +
      '&DN=' +
      DN +
      '&timezone=' +
      SCHSTATE.timezone +
      '&ItemPrimaryKey=' +
      ItemPrimaryKey,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Not Available');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'SCHbookAircraft');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse == 'Success') {
          SCH_BackupCfields();
          SCHbookAssociated(true);
        } //reload associated data for booking
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $.mobile.loading('hide');
    //console.log('Communication Error getting data');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Failed!',
      headerClose: false,
      buttonPrompt:
        'There was a problem Retrieving aircraft list.  Please try again. If this continues to happen please submit a bug report.',
      buttons: {
        Close: {
          click: function () {},
        },
        Report: {
          click: function () {
            //File bug report
          },
        },
      },
    });
  });
}

function SCHroleStaff(staffPK, itemPK) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  var data = SCHEVENTDATA.Associated;
  var Staff_ID = staffPK;
  var Role = encodeURIComponent($('#RoleS-' + staffPK).val());
  var DN = $('#SHC_DisableNotifications').is(':checked');
  var ItemPrimaryKey = itemPK;
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Scheduler-AJAX.php?action=addStaffRole&Staff_ID=' +
      Staff_ID +
      '&Role=' +
      Role +
      '&BookingPK=' +
      SCHEVENTDATA.PrimaryKey +
      '&DN=' +
      DN +
      '&timezone=' +
      SCHSTATE.timezone +
      '&ItemPrimaryKey=' +
      ItemPrimaryKey,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log('Offline Scheduler Not Available');
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'SCHbookAircraft');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse == 'Success') {
          SCH_BackupCfields();
          SCHbookAssociated(true);
        } //reload associated data for booking
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $.mobile.loading('hide');
    //console.log('Communication Error getting data');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Failed!',
      headerClose: false,
      buttonPrompt:
        'There was a problem Retrieving aircraft list.  Please try again. If this continues to happen please submit a bug report.',
      buttons: {
        Close: {
          click: function () {},
        },
        Report: {
          click: function () {
            //File bug report
          },
        },
      },
    });
  });
}

function SCHremoveAircraft(i, iPK, silent = false) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to remove this aircraft?',
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          $.mobile.loading('show', {
            theme: 'a',
          });
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=removeAircraft&AC_ID=' +
              i +
              '&BookingPK=' +
              SCHEVENTDATA.PrimaryKey +
              '&timezone=' +
              SCHSTATE.timezone +
              '&ItemPrimaryKey=' +
              iPK,
            function (json_data) {
              if (json_data.length > 0) {
                if (json_data[0].ServerResponse == 'Offline') {
                  //console.log('Offline Scheduler Not Available');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  ServerResponse(json_data[0], 'SCHbookAircraft');
                }
                if (json_data[0].ServerResponse == 'Not Logged In') {
                  NOTLOGGEDIN();
                }
                if (json_data[0].ServerResponse == 'Success') {
                  if (!silent) {
                    SCH_BackupCfields();
                    SCHbookAssociated();
                  } else {
                    dp.events.list = [];
                    dp.update();
                    Manual_ResyncSCHEDULE();
                  }
                } //reload associated data for booking
              }
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error getting data');
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt:
                'There was a problem Retrieving aircraft list.  Please try again. If this continues to happen please submit a bug report.',
              buttons: {
                Close: {
                  click: function () {},
                },
                Report: {
                  click: function () {
                    //File bug report
                  },
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCHremoveVehicle(i, iPK, silent = false) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to remove this vehicle?',
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          $.mobile.loading('show', {
            theme: 'a',
          });
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=removeVehicle&V_ID=' +
              i +
              '&BookingPK=' +
              SCHEVENTDATA.PrimaryKey +
              '&timezone=' +
              SCHSTATE.timezone +
              '&ItemPrimaryKey=' +
              iPK,
            function (json_data) {
              if (json_data.length > 0) {
                if (json_data[0].ServerResponse == 'Offline') {
                  //console.log('Offline Scheduler Not Available');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  ServerResponse(json_data[0], 'SCHbookVehicle');
                }
                if (json_data[0].ServerResponse == 'Not Logged In') {
                  NOTLOGGEDIN();
                }
                if (json_data[0].ServerResponse == 'Success') {
                  if (!silent) {
                    SCH_BackupCfields();
                    SCHbookAssociated();
                  } else {
                    dp.events.list = [];
                    dp.update();
                    Manual_ResyncSCHEDULE();
                  }
                } //reload associated data for booking
              }
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error getting data');
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt:
                'There was a problem Retrieving vehicle list.  Please try again. If this continues to happen please submit a bug report.',
              buttons: {
                Close: {
                  click: function () {},
                },
                Report: {
                  click: function () {
                    //File bug report
                  },
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCHbookAircraft(filter) {
  SCHSaveBooking(true); //trigger a silent save of current event so notifications work properly
  $('#SCH_Book_Aircraft').html('');
  var data = SCHEVENTDATA.Associated;
  var html = '';
  var FreeAC = data.FreeAC;
  $('#SCH_Book_AircraftBTN').hide();
  $('#SCH_Book_AircraftBTN2').hide();
  $('#SCH_Book_VehiclesBTN').hide();
  $('#SCH_Book_VehiclesBTN2').hide();
  $('#SCH_Book_StaffBTN').hide();
  $('#SCH_Book_StaffBTN2').hide();
  $('#SCHSaveBookingBTN').attr('disabled', true);
  var curBase = $('#SCH_Book_Location').val();

  if (FreeAC.length) {
    html =
      "<table class='first-td-fill wrap' data-filter='true' id='SCH_Book_AircraftTBL'><thead><tr><th>Ident</th><th>Type</th><th>Base</th><th>Status</th><th>Add</th></tr></thead><tbody>";
    for (var i in FreeAC) {
      var AC = FreeAC[i];
      var lclass = '';
      if (AC.Hibrenated == 1 || (AC.BASED_IN != curBase && filter)) {
        continue;
      }
      if (AC.ConflictV2 == 1 || AC.Serviceable == 0) {
        lclass = 'redbg';
      }
      html +=
        "<tr class='" +
        lclass +
        "'><td>" +
        AC.ident +
        '</td><td>' +
        AC.AcType +
        '</td><td>' +
        AC.BASED_IN +
        '</td><td>' +
        AC.ServiceReason +
        "</td><td><input type='checkbox' data-role='none' id='SCH-" +
        i +
        '\'  style="width:20px;height:20px;"/></td></tr>';
    }
    html += '</tbody></table>';
    html +=
      '<button data-icon="plus" onClick="SCH_AddAC()" class="greenbg" data-mini="true">Add Selected Aircraft</button><br />';
  } else {
    html =
      '<h3>No aircraft available to add to this job</h3><b class="yellowbg">Note: If the job has been locked to approved Aircraft Types only you will need to add a new aircraf type from the "Clients and Jobs" Admin module.</b><br />';
  }
  $('#SCH_Book_Aircraft').html(html);
  $('#SCH_Book_Aircraft').enhanceWithin();
  AddTableSorter('SCH_Book_AircraftTBL');
}

function SCHbookVehicle(filter) {
  SCHSaveBooking(true); //trigger a silent save of current event so notifications work properly
  $('#SCH_Book_Vehicles').html('');
  var data = SCHEVENTDATA.Associated;
  var html = '';
  var FreeAC = data.FreeV;
  $('#SCH_Book_AircraftBTN').hide();
  $('#SCH_Book_AircraftBTN2').hide();
  $('#SCH_Book_VehiclesBTN').hide();
  $('#SCH_Book_VehiclesBTN2').hide();
  $('#SCH_Book_StaffBTN').hide();
  $('#SCH_Book_StaffBTN2').hide();
  $('#SCHSaveBookingBTN').attr('disabled', true);
  var curBase = $('#SCH_Book_Location').val();

  if (FreeAC.length) {
    html =
      "<table class='first-td-fill wrap' data-filter='true' id='SCH_Book_AircraftTBL'><thead><tr><th>Ident</th><th>Type</th><th>Base</th><th>Status</th><th>Add</th></tr></thead><tbody>";
    for (var i in FreeAC) {
      var AC = FreeAC[i];
      var lclass = '';
      if (AC.Hibrenated == 1 || (AC.BASED_IN != curBase && filter)) {
        continue;
      }
      if (AC.ConflictV2 == 1 || AC.Serviceable == 0) {
        lclass = 'redbg';
      }
      html +=
        "<tr class='" +
        lclass +
        "'><td>" +
        AC.Label +
        '</td><td>' +
        AC.licenceplate +
        '</td><td>' +
        AC.BASED_IN +
        '</td><td>' +
        AC.ServiceReason +
        "</td><td><input type='checkbox' data-role='none' id='SCH-" +
        i +
        '\'  style="width:20px;height:20px;"/></td></tr>';
    }
    html += '</tbody></table>';
    html +=
      '<button data-icon="plus" onClick="SCH_AddV()" class="greenbg" data-mini="true">Add Selected Vehicle</button><br />';
  } else {
    html = '<h3>No vehicles available to add to this job</h3><br />';
  }
  $('#SCH_Book_Aircraft').html(html);
  $('#SCH_Book_Aircraft').enhanceWithin();
  AddTableSorter('SCH_Book_AircraftTBL');
}

function SCH_AddAC() {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  $('#SCH_Book_AircraftBTN').show();
  $('#SCH_Book_AircraftBTN2').show();
  $('#SCH_Book_VehiclesBTN').show();
  $('#SCH_Book_VehiclesBTN2').show();
  $('#SCH_Book_StaffBTN').show();
  $('#SCH_Book_StaffBTN2').show();
  $('#SCHSaveBookingBTN').attr('disabled', false);
  //console.log('Adding Aircraft To Booking');
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  var FreeAC = SCHEVENTDATA.Associated.FreeAC;
  var PrimaryKey = SCHEVENTDATA.PrimaryKey;
  var Add = [];
  for (var i in FreeAC) {
    if ($('#SCH-' + i).is(':checked')) {
      Add.push({
        Ident: FreeAC[i].ident,
        PK: FreeAC[i].PrimaryKey,
      });
    }
  }
  if (Add.length > 0) {
    //Send new ac to server and reload Min
    //console.log(Add);

    $.mobile.loading('show', {
      theme: 'a',
    });
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Scheduler-AJAX.php?action=updateBookingAC&DN=' + DN + '&timezone=' + SCHSTATE.timezone,
      data: {
        BookingPK: PrimaryKey,
        Aircraft: JSON.stringify(Add),
      },
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        //console.log('ServerResponse: check');
        //console.log(data);
        //ensure data[0].ServerResponse == Success
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'SCHSAveBooking()');
          $.mobile.loading('hide');
        }
        if (data[0].ServerResponse == 'Success') {
          SCH_BackupCfields();
          SCHbookAssociated(); //reload new data and display
        }
        if (data[0].ServerResponse == 'Failed') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Failed!',
            headerClose: false,
            buttonPrompt:
              'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
            buttons: {
              Close: {
                click: function () {
                  //Update File Now Text - W&B Problem
                },
              },
              'Discard Changes': {
                click: function () {
                  //File bug report
                  SCHschedulerBack();
                },
              },
              Report: {
                click: function () {
                  //File bug report
                },
              },
            },
          });
          $.mobile.loading('hide');
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        //data.status, data.readyState, data.responseText
        //console.log(data);
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed!',
          headerClose: false,
          buttonPrompt:
            'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
          buttons: {
            Close: {
              click: function () {},
            },
            'Discard Changes': {
              click: function () {
                //File bug report
                SCHschedulerBack();
              },
            },
            Report: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      });
  } else {
    SCHdisplayAssociated(); //reload original data without selecting aircraft
  }
}

function SCH_AddV() {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  $('#SCH_Book_AircraftBTN').show();
  $('#SCH_Book_AircraftBTN2').show();
  $('#SCH_Book_VehiclesBTN').show();
  $('#SCH_Book_VehiclesBTN2').show();
  $('#SCH_Book_StaffBTN').show();
  $('#SCH_Book_StaffBTN2').show();
  $('#SCHSaveBookingBTN').attr('disabled', false);
  //console.log('Adding Aircraft To Booking');
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  var FreeAC = SCHEVENTDATA.Associated.FreeV;
  var PrimaryKey = SCHEVENTDATA.PrimaryKey;
  var Add = [];
  for (var i in FreeAC) {
    if ($('#SCH-' + i).is(':checked')) {
      Add.push({
        Label: FreeAC[i].Label,
        PK: FreeAC[i].PrimaryKey,
      });
    }
  }
  if (Add.length > 0) {
    //Send new ac to server and reload Min
    //console.log(Add);

    $.mobile.loading('show', {
      theme: 'a',
    });
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Scheduler-AJAX.php?action=updateBookingV&DN=' + DN + '&timezone=' + SCHSTATE.timezone,
      data: {
        BookingPK: PrimaryKey,
        Vehicle: JSON.stringify(Add),
      },
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        //console.log('ServerResponse: check');
        //console.log(data);
        //ensure data[0].ServerResponse == Success
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'SCHSAveBooking()');
          $.mobile.loading('hide');
        }
        if (data[0].ServerResponse == 'Success') {
          SCH_BackupCfields();
          SCHbookAssociated(); //reload new data and display
        }
        if (data[0].ServerResponse == 'Failed') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Failed!',
            headerClose: false,
            buttonPrompt:
              'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
            buttons: {
              Close: {
                click: function () {
                  //Update File Now Text - W&B Problem
                },
              },
              'Discard Changes': {
                click: function () {
                  //File bug report
                  SCHschedulerBack();
                },
              },
              Report: {
                click: function () {
                  //File bug report
                },
              },
            },
          });
          $.mobile.loading('hide');
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        //data.status, data.readyState, data.responseText
        //console.log(data);
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed!',
          headerClose: false,
          buttonPrompt:
            'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
          buttons: {
            Close: {
              click: function () {},
            },
            'Discard Changes': {
              click: function () {
                //File bug report
                SCHschedulerBack();
              },
            },
            Report: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      });
  } else {
    SCHdisplayAssociated(); //reload original data without selecting aircraft
  }
}

function SCHbookStaff(filter) {
  SCHSaveBooking(true); //trigger a silent save of current event so notifications work properly
  $('#SCH_Book_AircraftBTN').hide();
  $('#SCH_Book_StaffBTN').hide();
  $('#SCH_Book_AircraftBTN2').hide();
  $('#SCH_Book_StaffBTN2').hide();
  $('#SCH_Book_VehiclesBTN').hide();
  $('#SCH_Book_VehiclesBTN2').hide();
  $('#SCHSaveBookingBTN').attr('disabled', true);
  $('#SCH_Book_Staff').html('');
  var curBase = $('#SCH_Book_Location').val();
  var data = SCHEVENTDATA.Associated;
  var html = '';
  var FreeStaff = data.FreeStaff;
  //console.log(FreeStaff);
  if (FreeStaff.length) {
    html = '<h1>Select Staff for booking</h1>';
    if (DUTYTIMES == 1) {
      html +=
        '<div id="SCHdutytimesBTNHLDR"><button onClick="SCH_PopPilotFDT();" data-mini="true" data-icon="refresh">' +
        iTrans('Update All Staff Status') +
        '</button></div>';
    }
    html +=
      "<table class='first-td-fill' data-filter='true' id='SCH_Book_StaffTBL'><thead><tr><th>Name</th><th>Base</th><th>Type</th><th>Add</th></tr></thead><tbody>";
    for (var i in FreeStaff) {
      var Staff = FreeStaff[i];
      var lclass = '';
      if (Staff.BASED_IN != curBase && filter) {
        continue;
      }
      var conflictReason = '';
      if (Staff.Conflict == 1) {
        lclass = 'redbg';

        if (Staff.Conflict_Booking_ID == 0 && Staff.Avail == 0) {
          if (Staff.Approved == 1) {
            conflictReason = ' - [' + iTrans('Approved Time Off') + ']';
          } else {
            conflictReason = ' - [' + iTrans('Time Off Approval Pending') + ']';
          }
        } else {
          conflictReason = ' - [' + iTrans('Conflict with booking') + '#' + Staff.Conflict_Booking_ID + ']';
        }
      }

      if (userShouldHaveFDT(Staff.StaffType)) {
        html +=
          "<tr class='" +
          lclass +
          '\' style="vertical-align: top;" id="SCHFDT_TR_' +
          Staff.PrimaryKey +
          '"><td><button data-role="none" class="collapsibleMK" id="SCHFDT_BTN_' +
          Staff.PrimaryKey +
          '"><b>' +
          Staff.chatname +
          conflictReason +
          '</b> <span id="SCHFDT_status_' +
          Staff.PrimaryKey +
          '"><i>*</i></span></button><div id="SCHFDT_' +
          Staff.PrimaryKey +
          '" class="contentMK"></div></td><td>' +
          Staff.BASED_IN +
          '</td><td>' +
          Staff.StaffType +
          "</td><td><input type='checkbox' data-role='none' id='SCH2-" +
          i +
          '\' value="' +
          Staff.PrimaryKey +
          '" onClick="SCH_PopPilotFDT_Individual(this)" style="width:20px;height:20px;"/></td></tr>';
      } else {
        html +=
          "<tr class='" +
          lclass +
          '\' style="vertical-align: top;" id="SCHFDT_TR_' +
          Staff.PrimaryKey +
          '"><td><button data-role="none" class="collapsibleMK" id="SCHFDT_BTN_' +
          Staff.PrimaryKey +
          '"><b>' +
          Staff.chatname +
          '</b> <span id="SCHFDT_status_' +
          Staff.PrimaryKey +
          '"><i>*</i></span></button><div id="SCHFDT_' +
          Staff.PrimaryKey +
          '" class="contentMK"></div></td><td>' +
          Staff.BASED_IN +
          '</td><td>' +
          Staff.StaffType +
          "</td><td><input type='checkbox' data-role='none' id='SCH2-" +
          i +
          '\' value="' +
          Staff.PrimaryKey +
          '" onClick="SCH_PopPilotFDT_Individual(this)" style="width:20px;height:20px;"/></td></tr>';
      }
    }
    html += '</tbody></table>';
    html +=
      '<button data-icon="plus" onClick="SCH_AddStaff()" class="greenbg" data-mini="true">Add Selected Staff</button><br />';
    //if (DUTYTIMES == 1 || CERTS == 1) SCH_PopPilotFDT();
  } else {
    html =
      '<h3>No staff available to add to this job</h3><b class="yellowbg">Note: If the job has been locked to approved Crew only you will need to add new Crew to the job from the "Clients and Jobs" Admin module.</b><br />';
  }
  $('#SCH_Book_Staff').html(html);
  $('#SCH_Book_Staff').enhanceWithin();
  AddTableSorter('SCH_Book_StaffTBL');
  //SCH_PopPilotFDT();
}

function SCH_PopPilotFDT() {
  var FreeStaff = SCHEVENTDATA.Associated.FreeStaff;
  if (FreeStaff.length) {
    for (var i in FreeStaff) {
      var User = FreeStaff[i];
      User.Booking_ID = SCHEVENTDATA.PrimaryKey;
      User.start = SCHEVENTDATA.start;
      User.end = SCHEVENTDATA.end;
      if ($('#SCHFDT_status_' + User.PrimaryKey).html() == '<i>*</i>') {
        $('#SCHFDT_status_' + User.PrimaryKey).html(
          '<i>' + iTrans('Updating FDT and Qualification Status') + '...</i>'
        );
        SCH_GetPilotFDT('SCHFDT', User);
      }
    }
  }
}

function SCH_PopPilotFDT_Individual(e) {
  //console.log(e);
  if (e.checked) {
    var FreeStaff = SCHEVENTDATA.Associated.FreeStaff;
    var User;
    for (var i in FreeStaff) {
      if (FreeStaff[i].PrimaryKey == e.value) {
        User = FreeStaff[i];
        User.Booking_ID = SCHEVENTDATA.PrimaryKey;
        User.start = SCHEVENTDATA.start;
        User.end = SCHEVENTDATA.end;
        break;
      }
    }
    if ($('#SCHFDT_status_' + e.value).html() == '<i>*</i>') {
      $('#SCHFDT_status_' + e.value).html('<i>' + iTrans('Updating FDT and Qualification Status') + '...</i>');
      SCH_GetPilotFDT('SCHFDT', User, true);
    }
  }
}

function SCH_find_BookedStaff_End(UserPK) {
  var defaultDate = SCHEVENTDATA.end.replace('T', ' ').substr(0, 19);

  var end = new Date(defaultDate);
  try {
    var bookedStaff = SCHEVENTDATA.Associated.BookedStaff;

    for (var i in bookedStaff) {
      var I = bookedStaff[i];
      if (I.PrimaryKey == UserPK) {
        end = new Date(I.end.replace(' ', 'T') + 'Z');
      }
    }
  } catch (e) {
    console.log('Cannot find booked staff end');
  }
  return end;
}

function SCH_find_BookedStaff_Start(UserPK) {
  var defaultDate = SCHEVENTDATA.start.replace('T', ' ').substr(0, 19);

  var start = new Date(defaultDate);
  try {
    var bookedStaff = SCHEVENTDATA.Associated.BookedStaff;

    for (var i in bookedStaff) {
      var I = bookedStaff[i];
      if (I.PrimaryKey == UserPK) {
        start = new Date(I.start.replace(' ', 'T') + 'Z');
      }
    }
  } catch (e) {
    console.log('Cannot find booked staff start');
  }
  return start;
}

function SCH_FindDutyDayIndex(Duty, date) {
  if (Duty.length == 0) {
    return null;
  }
  var i = Duty.length - 1;
  while (i > -1) {
    if (Duty[i].Date == date) {
      return i;
    }
    i--;
  }
  return null;
}

function SCH_GetPilotFDT(idHead, User, newBooking = false) {
  //console.log(User.chatname);
  var FromDate = new Date();
  var ToDate = new Date();
  var FromMonth = FromDate.getUTCMonth() + 1;
  var FromDay = FromDate.getUTCDate();
  var PK = User.PrimaryKey;
  if (FromMonth < 10) {
    FromMonth = '0' + FromMonth;
  }
  if (FromDay < 10) {
    FromDay = '0' + FromDay;
  }
  FromDate = FromDate.getUTCFullYear() - 1 + '-' + FromMonth + '-' + FromDay;
  ToDate.setDate(ToDate.getDate() + 1);
  var ToMonth = ToDate.getUTCMonth() + 1;
  var ToDay = ToDate.getUTCDate();
  if (ToMonth < 10) {
    ToMonth = '0' + ToMonth;
  }
  if (ToDay < 10) {
    ToDay = '0' + ToDay;
  }
  ToDate = ToDate.getUTCFullYear() + '-' + ToMonth + '-' + ToDay;
  var SearchStart = SCH_find_BookedStaff_Start(PK);
  var SearchEnd = SCH_find_BookedStaff_End(PK);
  $.getJSON(
    BASEURL +
      'menu-Administration-Reports-AJAX.php?report=Report_DutyV2_Pilot&UserPK=' +
      PK +
      '&FromDate=' +
      encodeURIComponent(FromDate) +
      '&ToDate=' +
      encodeURIComponent(ToDate) +
      '&BookingStart=' +
      encodeURIComponent(SearchStart.toISOString().substr(0, 19).replace('T', ' ')) +
      '&BookingEnd=' +
      encodeURIComponent(SearchEnd.toISOString().substr(0, 19).replace('T', ' ')) +
      '&newBooking=' +
      newBooking,
    function (I) {
      $('#' + idHead + '_status_' + PK).html('');
      var TRwarning = '';
      var Header = '<h3>' + iTrans('FDT and Qualification Status') + '</h3>';
      var PulsardivID = idHead + 'PULSAR_' + PK;
      if (DUTYTIMES == 1) {
        var FDTwarning = '';
        //UserPK = I.UserPK;  //This seems to be unused
        REPORT_DayCur = I.FDT_DayCur;
        REPORT_NightCur = I.FDT_NightCur;
        REPORT_IFRCur = I.FDT_IfrCur;
        REPORT_FloatCur = I.FDT_FloatRating;
        FDT_ADMIN_UPDATE = true;
        ADMIN_FDT_DATA.CurUser = I.experience;
        FromDate = new Date(FromDate);
        ToDate = new Date(ToDate);
        var PredictedEntries = '';
        if (I.Duty.length > 0 && I.ScheduleFill.length > 0) {
          PredictedEntries +=
            '<h3>' +
            iTrans('Projected Duty Times Created') +
            '<table class="limit-table"><thead><tr><th>' +
            iTrans('Assumption Type') +
            '</th><th>' +
            iTrans('Date') +
            '</th><th>' +
            iTrans('Start') +
            '</th><th>' +
            iTrans('End') +
            '</th><th>FT</th><th>' +
            iTrans('Timezone') +
            '</th></tr></thead><tbody>';
          var LastDrec = I.Duty[I.Duty.length - 1];
          var LastDay = new Date(LastDrec.Date + 'T12:00:00Z');
          LastDay.setDate(LastDay.getDate() + 1);
          //Fill DayOff Recs to first ScheduleFill date
          for (var i in I.ScheduleFill) {
            var SF = I.ScheduleFill[i];
            var SFday = new Date(SF.start.substr(0, 10) + 'T12:00:00Z');
            while (LastDay.getTime() < SFday.getTime()) {
              //Create Blank Records using LastDrec data
              console.log('default day added', dateToStringObject(LastDay).substr(0, 10));
              var NDR = Blank_FDT_DaysV4(LastDrec.User_PK, dateToStringObject(LastDay).substr(0, 10), I.FDT_Defaults);
              NDR.Recs = [];
              LastDay.setDate(LastDay.getDate() + 1);
              I.Duty.push(NDR);
              PredictedEntries +=
                '<tr><td>' +
                iTrans('Default Duty') +
                '</td><td>' +
                NDR.Date +
                '</td><td>' +
                NDR.Start +
                '</td><td>' +
                NDR.End +
                '</td><td>' +
                NDR.FT +
                'hrs</td><td>' +
                NDR.Timezone +
                '</td></tr>';
            }
            console.log('LastDay', dateToStringObject(LastDay).substr(0, 10));
            console.log('SFday', dateToStringObject(SFday).substr(0, 10));
            if (dateToStringObject(SFday).substr(0, 10) >= dateToStringObject(LastDay).substr(0, 10)) {
              var NDR = Blank_FDT_DaysV4(LastDrec.User_PK, dateToStringObject(SFday).substr(0, 10), I.FDT_Defaults);
              NDR.Recs = [];
              if (newBooking) {
                NDR.FT = parseFloat($('#SCH_FlightTime').val());
              } else {
                NDR.FT = SF.Booking.Flight_Time;
              }

              NDR.Start = SF.start.substr(11, 5).replace(':', '');
              NDR.On = NDR.Start;
              NDR.End = SF.end.substr(11, 5).replace(':', '');
              NDR.Off = NDR.End;
              LastDay.setDate(LastDay.getDate() + 1);
              if (SF.end.substr(0, 10) != dateToStringObject(SFday).substr(0, 10)) {
                //Booking Ends on the following day
                NDR.End = '2359';
                NDR.Off = '2359';
                I.Duty.push(NDR);
                PredictedEntries +=
                  '<tr><td>' +
                  iTrans('Booking') +
                  '</td><td>' +
                  NDR.Date +
                  '</td><td>' +
                  NDR.Start +
                  '</td><td>' +
                  NDR.End +
                  '</td><td>' +
                  NDR.FT +
                  'hrs</td><td>' +
                  NDR.Timezone +
                  '</td></tr>';
                var NDR2 = Blank_FDT_DaysV4(LastDrec.User_PK, dateToStringObject(SFday).substr(0, 10), I.FDT_Defaults);
                NDR2.Recs = [];
                NDR2.FT = 0;
                NDR2.Start = '0';
                NDR2.Overnight = 1;
                NDR2.On = NDR.Start;
                NDR2.End = SF.end.substr(11, 5).replace(':', '');
                NDR2.Off = NDR.End;
                I.Duty.push(NDR2);
                PredictedEntries +=
                  '<tr><td>' +
                  iTrans('Booking') +
                  '</td><td>' +
                  NDR2.Date +
                  '</td><td>' +
                  NDR2.Start +
                  '</td><td>' +
                  NDR2.End +
                  '</td><td>' +
                  NDR2.FT +
                  'hrs</td><td>' +
                  NDR2.Timezone +
                  '</td></tr>';
              } else {
                I.Duty.push(NDR);
                PredictedEntries +=
                  '<tr><td>' +
                  iTrans('Booking') +
                  '</td><td>' +
                  NDR.Date +
                  '</td><td>' +
                  NDR.Start +
                  '</td><td>' +
                  NDR.End +
                  '</td><td>' +
                  NDR.FT +
                  'hrs</td><td>' +
                  NDR.Timezone +
                  '</td></tr>';
              }
            } else {
              console.log('lastDay is booking day');
              let dutyIndex = SCH_FindDutyDayIndex(I.Duty, dateToStringObject(SFday).substr(0, 10));
              console.log('dutyIndex', dutyIndex);
              var NDR = I.Duty[dutyIndex];
              NDR.Timezone = I.FDT_Defaults.Timezone;
              if (newBooking) {
                NDR.FT = parseFloat($('#SCH_FlightTime').val());
              } else {
                NDR.FT = SF.Booking.Flight_Time;
              }
              let newStart = SF.start.substr(11, 5).replace(':', '');
              if (NDR.Start > newStart || NDR.Start == NDR.End) {
                NDR.Start = newStart;
              }
              NDR.On = NDR.Start;
              let newEnd = SF.end.substr(11, 5).replace(':', '');
              if (NDR.End < newEnd || NDR.Start == NDR.End) {
                NDR.End = newEnd;
              }
              NDR.Off = NDR.End;
              //console.log('NDR', NDR);
              //console.log('SF', SF);
              if (SF.end.substr(0, 10) != NDR.Date) {
                NDR.End = '2359';
                NDR.Off = '2359';
                PredictedEntries +=
                  '<tr><td>' +
                  iTrans('Booking') +
                  '</td><td>' +
                  NDR.Date +
                  '</td><td>' +
                  NDR.Start +
                  '</td><td>' +
                  NDR.End +
                  '</td><td>' +
                  NDR.FT +
                  'hrs</td><td>' +
                  NDR.Timezone +
                  '</td></tr>';
                dutyIndex = SCH_FindDutyDayIndex(I.Duty, SF.end.substr(0, 10));
                if (dutyIndex != null) {
                  NDR = I.Duty[dutyIndex];
                  NDR.Timezone = I.FDT_Defaults.Timezone;
                } else {
                  NDR = Blank_FDT_DaysV4(LastDrec.User_PK, SF.end.substr(0, 10), I.FDT_Defaults);
                }
                NDR.Start = '0';
                NDR.On = '0';
                NDR.Overnight = 1;
                NDR.End = newEnd;
                NDR.Off = newEnd;
                if (dutyIndex == null) {
                  I.Duty.push(NDR);
                }
                PredictedEntries +=
                  '<tr><td>' +
                  iTrans('Booking') +
                  '</td><td>' +
                  NDR.Date +
                  '</td><td>' +
                  NDR.Start +
                  '</td><td>' +
                  NDR.End +
                  '</td><td>' +
                  NDR.FT +
                  'hrs</td><td>' +
                  NDR.Timezone +
                  '</td></tr>';
              } else {
                PredictedEntries +=
                  '<tr><td>' +
                  iTrans('Booking') +
                  '</td><td>' +
                  NDR.Date +
                  '</td><td>' +
                  NDR.Start +
                  '</td><td>' +
                  NDR.End +
                  '</td><td>' +
                  NDR.FT +
                  'hrs</td><td>' +
                  NDR.Timezone +
                  '</td></tr>';
              }

              LastDay.setDate(LastDay.getDate() + 1);
            }
          }
          PredictedEntries += '</tbody></table>';
        }
        //SearchEnd.setDate(SearchEnd.getDate() + 1);
        var D = UserDuty(
          I.Duty,
          I.LastSync,
          I.experience,
          I.TotalExperience,
          I.List_TypeExperience,
          I.AcTypeExperience,
          I.ClassExperience,
          FromDate,
          SearchEnd,
          I.TotalExperience12,
          I.List_TypeExperience12,
          I.ClassExperience12,
          I.AcTypeExperience12,
          I
        );

        if (D != false) {
          var LastLogin = getLocalDateTime(I.LastLogin);

          //var BookingEnd = stringToDateObject(SCHEVENTDATA.end).getTime();
          var BookingEnd = SearchEnd.getTime();
          var LastSync = getLocalDateTime(I.LastSync, BookingEnd);

          var WVCT = Report_WarningViolationCurrencyTables(D, I, false);
          //console.log(WVCT);

          if (User.AC_ID != undefined && User.AC_ID != 0) {
            var AC = null;
            for (var i in ALLAC_data) {
              if (ALLAC_data[i].PrimaryKey == User.AC_ID) {
                AC = ALLAC_data[i];
                break;
              }
            }
            if (AC != null) {
              var AC_Cur = WVCT.AC_Currency;
              if ((GROUP_DATA.group == 'transportcanada' || GPS_DEBUG) && AC.AcType == 'Cessna Model 550') {
                AC.List_Type = 'Cessna CJ 550 Series';
              } else if ((GROUP_DATA.group == 'transportcanada' || GPS_DEBUG) && AC.AcType == 'King Air C90') {
                AC.List_Type = 'King Air C90 Series';
              }
              if (AC.TypeRatingReq == 1) {
                var CurKey = AC.List_Type;
                if (CurKey == 'Bell 206 Series' || CurKey == 'Bell 206L Series') {
                  if (AC.AcTypes == 'Bell 206L4T') {
                    CurKey = 'Bell 206L4T Series';
                  } else {
                    CurKey = 'Bell 206/206L Series';
                  }
                }
              } else {
                var CurKey = AC.Class + ' ' + AC.Class2;
              }
              if (AC_Cur[CurKey] != undefined) {
                if (AC_Cur[CurKey].ValidTo == null) {
                  $('#' + idHead + '_TD_AC_' + PK).addClass('redbg');
                } else {
                  $('#' + idHead + '_TD_AC_' + PK).addClass('greenbg');
                }
              } else {
                $('#' + idHead + '_TD_AC_' + PK).addClass('redbg');
              }
            }
          }

          var VWtable = WVCT.VWtable;
          var VWtext = WVCT.WVtext;
          var TL = WVCT.TL_Currency;

          if (I.FDT_NvgCur == 1) {
            if (WVCT.NVG_Report.Current == false) {
              TRwarning = 'yellowbg';
              $('#' + idHead + '_status_' + PK).html('<i>' + iTrans('Not NVG Current') + '</i>');
            }
          }
          if (I.FDT_IfrCur == 1) {
            if (WVCT.IFR_Report == null) {
              TRwarning = 'yellowbg';
              $('#' + idHead + '_status_' + PK).html('<i>' + iTrans('Not IFR Current') + '</i>');
            } else if (WVCT.IFR_Report.Current == false) {
              TRwarning = 'yellowbg';
              $('#' + idHead + '_status_' + PK).html('<i>' + iTrans('Not IFR Current') + '</i>');
            }
          }
          var CheckEnd = D.Noff.date.getTime();
          if (BookingEnd > CheckEnd) {
            FDTwarning = 'redbg';
            $('#' + idHead + '_status_' + PK).html('<i>' + iTrans('Day Off Required') + '</i>');
          }

          if (COUNTRY == 'CANADA') {
            var ExtendInfo = '<b>' + I.chatname + '</b>';
            var ExtendText = I.chatname;
            if (D.ExtendON == 1 && D.Extendable == 0) {
              ExtendInfo += '<br />Duty Extended ' + D.ExtendNum + '/6 Used';
            }
            if (D.ExtendON == 0 && D.Extendable == 1) {
              ExtendInfo += '<br />Extension Available ' + D.ExtendNum + '/6 Used';
            }
            if (D.ExtendON == 0 && D.Extendable == 0) {
              ExtendInfo += '<br />NOT Extendable ' + D.ExtendNum + '/6 Used';
            }
            var MaxTimeTxt;
            if (D.MaxNextFltTimes.MaxTime == 'Unknown') {
              MaxTimeTxt = '<b>Unknown Next Day FT</b>';
            } else {
              if (D.MaxNextFltTimes.MaxTime < 0) {
                MaxTimeTxt =
                  '<b>Cannot fly tomorrow</b> ' +
                  Math.abs(D.MaxNextFltTimes.MaxTime) +
                  ' hrs beyond - ' +
                  D.MaxNextFltTimes.Limiter +
                  ' Limit';
              } else {
                MaxTimeTxt =
                  '<b>Max Next Day FT:</b> ' +
                  D.MaxNextFltTimes.MaxTime +
                  ' hrs - ' +
                  D.MaxNextFltTimes.Limiter +
                  ' Limit';
                var BookingFT = parseFloat($('#SCH_FlightTime').val());
                if (!isNaN(BookingFT)) {
                  if (BookingFT > D.MaxNextFltTimes.MaxTime || BookingFT > 15) {
                    TRwarning = 'redbg';
                    $('#' + idHead + '_status_' + PK).html('<i>' + iTrans('Not Enough Flight Time Available') + '</i>');
                  }
                }
              }
            }
            var SCHtext =
              '<table class="limit-table"><thead><tr><th><b>FDT as of</b></th><th><b>Next Req Off</b></th><th><b>Next Limit</b></th></tr></thead><tbody>';
            console.log(D.Noff);
            SCHtext +=
              '<tr><td>' +
              SearchEnd.toISOString().substr(0, 10) +
              '<br />[' +
              LastSync.daysAgo +
              ' ' +
              iTrans('Days from Booking End') +
              ']</td><td class="' +
              FDTwarning +
              '">' +
              dateToStringObject(D.Noff.date).substr(0, 10) +
              '</td><td>' +
              MaxTimeTxt +
              '</td></tr></tbody></table>';
          } else {
            var SCHtext =
              '<table class="limit-table"><thead><tr><th><b>FDT as of</b></th><th><b>Next Req Off</b></th><th><b>LastQ | CurQ | Last2Q</b></th></tr></thead><tbody>';
            SCHtext +=
              '<tr><td>' +
              LastSync.date.toLocaleString() +
              ' [' +
              LastSync.FromNow +
              ']</td><td>' +
              dateToStringObject(D.Noff.date).substr(0, 10) +
              '</td><td>' +
              D.lastQ +
              ' | ' +
              D.curQR +
              ' | ' +
              D.last2Q +
              '</td></tr></tbody></table>';
          }
          SCHtext += PredictedEntries;
          if (GROUP_DATA.PULSAR_FRMS == 1) {
            SCHtext += '<div id="' + PulsardivID + '"><i>Loading FRMS Data...</i></div>';
          }

          SCHtext += VWtable;
        } else {
          var SCHtext = '';
          Header = '';
        }
      } else {
        //doesn't have DUTYTIMES
        var SCHtext = '';
        Header = '';
      }
      var html = '';
      if (CERTS > 0) {
        var certCheck = FitToFlyReportBuilder.build(I.Memos, I.Certs, SearchEnd);
        html += certCheck.html;
        if (certCheck.expiredCert) {
          TRwarning = 'redbg';
          $(`#${idHead}_status_${PK}`).html('<i>' + iTrans('Incomplete & Expired Essential Items') + '</i>');
        }
      }

      $('#' + idHead + '_' + PK)
        .html(Header + '<div>' + SCHtext + html + '</div><br />')
        .enhanceWithin();

      $('#' + idHead + '_TR_' + PK).addClass(TRwarning);

      if (GROUP_DATA.PULSAR_FRMS == 1) {
        //InitCollapsables(idHead + '_BTN_' + PK);
        PULSAR_GetScheduleStaff_FRMS(User, PulsardivID, idHead + '_BTN_' + PK);
      } else {
        InitCollapsables(idHead + '_BTN_' + PK);
      }
    }
  ).fail(function (jqXHR, status, error) {
    //console.log("Failed to get Report Data");
    $('SCHFDT_status_' + PK).html('<i>*** ' + iTrans('Error Retrieving Staff Data') + ' ***</i>');
    InitCollapsables(idHead + '_BTN_' + PK);
  });
}

function InitCollapsables(id) {
  //var coll = document.getElementsByClassName("collapsibleMK");
  var coll = document.getElementById(id);

  if (!coll) {
    return;
  }

  coll.removeEventListener('click', function () {
    this.classList.toggle('activeMK');
    var content = this.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  });
  coll.addEventListener('click', function () {
    this.classList.toggle('activeMK');
    var content = this.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  });
}

function SCHremoveStaff(i, iPK, silent = false) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to remove this staff member?',
    buttons: {
      Ok: {
        click: function () {
          //Continue Action
          $.mobile.loading('show', {
            theme: 'a',
          });
          $.getJSON(
            BASEURL +
              'menu-Scheduler-AJAX.php?action=removeStaff&Staff_ID=' +
              i +
              '&BookingPK=' +
              SCHEVENTDATA.PrimaryKey +
              '&DN=' +
              DN +
              '&timezone=' +
              SCHSTATE.timezone +
              '&ItemPrimaryKey=' +
              iPK,
            function (json_data) {
              if (json_data.length > 0) {
                if (json_data[0].ServerResponse == 'Offline') {
                  //console.log('Offline Scheduler Not Available');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  ServerResponse(json_data[0], 'SCHbookAircraft');
                }
                if (json_data[0].ServerResponse == 'Not Logged In') {
                  NOTLOGGEDIN();
                }
                if (json_data[0].ServerResponse == 'Success') {
                  var conflicts = json_data[0].Conflicts;
                  for (var i in conflicts) {
                    var I = conflicts[i];
                    cirroDB.Delete(
                      'Scheduler_Individual',
                      'PrimaryKey = ? OR PrimaryKey LIKE ?',
                      [I.PrimaryKey, I.PrimaryKey + '_%'],
                      function (e) {
                        if (e === false) console.log('Failed to delete SCH: ' + I.PrimaryKey);
                        else console.log('Deleted SCH: ' + I.PrimaryKey);
                      }
                    );
                  }
                  if (!silent) {
                    SCH_BackupCfields();
                    SCHbookAssociated();
                  } else {
                    dp.events.list = [];
                    dp.update();
                    Manual_ResyncSCHEDULE();
                  }
                } //reload associated data for booking
              }
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            //AJAXFAIL(error);
            $.mobile.loading('hide');
            //console.log('Communication Error getting data');
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt:
                'There was a problem Retrieving aircraft list.  Please try again. If this continues to happen please submit a bug report.',
              buttons: {
                Close: {
                  click: function () {},
                },
                Report: {
                  click: function () {
                    //File bug report
                  },
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function SCH_AddStaff() {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }
  $('#SCH_Book_AircraftBTN').show();
  $('#SCH_Book_StaffBTN').show();
  $('#SCH_Book_AircraftBTN2').show();
  $('#SCH_Book_StaffBTN2').show();
  $('#SCH_Book_VehiclesBTN').show();
  $('#SCH_Book_VehiclesBTN2').show();
  $('#SCHSaveBookingBTN').attr('disabled', false);
  //console.log('Adding Aircraft To Booking');
  var FreeStaff = SCHEVENTDATA.Associated.FreeStaff;
  var PrimaryKey = SCHEVENTDATA.PrimaryKey;
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }
  var Add = [];
  for (var i in FreeStaff) {
    if ($('#SCH2-' + i).is(':checked')) {
      Add.push({
        name: FreeStaff[i].chatname,
        PK: FreeStaff[i].PrimaryKey,
      });
    }
  }
  if (Add.length > 0) {
    //Send new ac to server and reload Min
    //console.log(Add);

    $.mobile.loading('show', {
      theme: 'a',
    });
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Scheduler-AJAX.php?action=updateBookingStaff&DN=' + DN + '&timezone=' + SCHSTATE.timezone,
      data: {
        BookingPK: PrimaryKey,
        Staff: JSON.stringify(Add),
      },
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        //console.log('ServerResponse: check');
        //console.log(data);
        //ensure data[0].ServerResponse == Success
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'SCHSAveBooking()');
          $.mobile.loading('hide');
        }
        if (data[0].ServerResponse == 'Success') {
          var conflicts = data[0].Conflicts;
          for (var i in conflicts) {
            var I = conflicts[i];
            cirroDB.Delete(
              'Scheduler_Individual',
              'PrimaryKey = ? OR PrimaryKey LIKE ?',
              [I.PrimaryKey, I.PrimaryKey + '_%'],
              function (e) {
                if (e === false) console.log('Failed to delete SCH: ' + I.PrimaryKey);
                else console.log('Deleted SCH: ' + I.PrimaryKey);
              }
            );
          }
          SCH_BackupCfields();
          SCHbookAssociated(); //reload new data and display
        }
        if (data[0].ServerResponse == 'Failed') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Failed!',
            headerClose: false,
            buttonPrompt:
              'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
            buttons: {
              Close: {
                click: function () {
                  //Update File Now Text - W&B Problem
                },
              },
              Report: {
                click: function () {
                  //File bug report
                },
              },
            },
          });
          $.mobile.loading('hide');
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        //data.status, data.readyState, data.responseText
        //console.log(data);
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed!',
          headerClose: false,
          buttonPrompt:
            'There was a problem updating the booking.  Please try again. If this continues to happen please submit a bug report.',
          buttons: {
            Close: {
              click: function () {},
            },
            Report: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      });
  } else {
    SCHdisplayAssociated(); //reload original data without selecting aircraft
  }
}

function SCHloadfile() {
  var file = document.getElementById('SCHfile').files[0];
  $('#SCHfileprogress').html('<h2>Loading File</h2>');
  //console.log(file);
  if (file != undefined) {
    $.mobile.loading('show', {
      theme: 'a',
    });
    var filename = file.name;
    var filesize = file.size;
    var file64;
    //console.log(filesize);
    if (filesize > 13000000) {
      $('#SCHfileprogress').html('<h2>File too large!!</h2>');
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/gif') {
          var img = document.createElement('img');
          img.onload = function () {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
            var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
            var width = img.width;
            var height = img.height;
            //console.log(width);
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            var dataurl = canvas.toDataURL(file.type);
            file64 = dataurl.split(',')[1];
            SCHuploadfile(file64, filename);
          };
          img.src = e.target.result;
        } else {
          file64 = btoa(e.target.result);
          ////console.log(cert_base64File);
          SCHuploadfile(file64, filename);
        }
      };
      reader.onerror = function (e) {
        //reset file input
        $.mobile.loading('hide');
        //console.log('Scheduler FileReader Error');
        $('#SCHfileprogress').html('<h2>Error Reading File!!!</h2>');
      };
      if (file.type == 'application/pdf') {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsDataURL(file);
      }
    }
  } else {
    //reset file input
    //console.log('No Shceduler File Selected');
    $('#SCHfileprogress').html('No File Selected');
  }
}

function SCHuploadfile(file64, filename) {
  //#SCHfileprogress div for display upload progress
  var PrimaryKey = SCHEVENTDATA.PrimaryKey;
  var DN = $('#SHC_DisableNotifications').is(':checked');
  if (DN) {
    DN = 1;
  } else {
    DN = 0;
  }

  $.ajax({
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
            if (percentComplete == 100) {
              $('#SCHfileprogress').html('<h2>Processing..</h2>');
            } else {
              $('#SCHfileprogress').html('<h2>Uploading: ' + percentComplete + '%</h2>');
            }
          }
        },
        false
      );
      xhr.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //console.log('Received: ' + percentComplete);
          }
        },
        false
      );
      return xhr;
    },
    type: 'POST',
    url:
      BASEURL +
      'menu-Scheduler-AJAX.php?action=bookingAttachFile&PrimaryKey=' +
      PrimaryKey +
      '&DN=' +
      DN +
      '&timezone=' +
      SCHSTATE.timezone,
    data: {
      SCHfile: file64,
      SCHfilename: filename,
    },
    success: function (data) {
      if (typeof data === 'string') {
        data = jQuery.parseJSON(data);
      }
      if (data[0].ServerResponse == 'Success' || data[0].ServerResponse == 'Nothing Changed') {
        //console.log('Scheduler Attachment Successfully Updated');
        //$.mobile.changePage( $("#Schedule_Page"), {  reverse: true, changeHash: false });
        $('#SCHfileprogress').html('<h2>Successfully Uploaded</h2>');
        SCH_BackupCfields();
        SCHbookAssociated(); //reload new data and display
        $('#SCHfile').val(null);
      } else {
        //insert failed server error
        //console.log('Cert Update Failed: ' + data[0].ServerResponse);
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'SCHuploadfile');
        }
        if (data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        $('#SCHfileprogress').html('<h2>Upload Failed!</h2>');
      }
      $.mobile.loading('hide');
      SCHFILE = null;
      SCHFILENAME = null;
    },
    fail: function (jqXHR, status, error) {
      $.mobile.loading('hide');
      $('#SCHfileprogress').html('<h2>Upload Failed!</h2>');
      SCHFILE = null;
      SCHFILENAME = null;
      AJAXFAIL(error);
    },
  });
}

var dbScheduler;
var dbSchedulerVersion = 1;

function refreshSchedulerData(data) {
  createSchedulerDB(data);
}

function createSchedulerDB(data) {
  updateSchedulerData(data); //first check
}

function updateSchedulerData(data) {
  var ScheduleData = {
    PrimaryKey: 1,
    data: JSON.stringify(data),
  };

  cirroDB.insert('Schedule', ScheduleData);
}

function dbSchedulerData() {
  //console.log("Getting Resource Types");
  cirroDB.query('Schedule', 'PrimaryKey = ?', 1, function (e) {
    var items = e;
    if (e === false) {
      items = [];
    }
    if (items.length == 0) {
      //console.log('Failed to Load Scheduler Data');
    } else {
      //console.log(items);
      //todo group ac again
      //if SCHSTATE.groupAC == false
      //if SCHSTATE.groupStaff == false
      //todo comp
      if (SCHSTATE.ResourceState == undefined) {
        SCHSTATE.ResourceState = items[0].Resources;
        if (SCHSTATE.groupAC == 'false') {
          SCHSTATE.ResourceState[1].children = items[0].AircraftOnly;
        }
        if (SCHSTATE.groupStaff == 'false') {
          SCHSTATE.ResourceState[2].children = items[0].StaffOnly;
        }

        SCH_Filter_ResourceState_StatusTypes(SCHSTATE.ResourceState[0].children, true);

        SCH_Filter_ResourceState_Bases(SCHSTATE.ResourceState[1].children, SCHSTATE.groupAC);
        SCH_Filter_ResourceState_AcTypes(SCHSTATE.ResourceState[1].children, SCHSTATE.groupAC);

        SCH_Filter_ResourceState_Bases(SCHSTATE.ResourceState[2].children, SCHSTATE.groupStaff);
        SCH_Filter_ResourceState_StaffTypes(SCHSTATE.ResourceState[2].children, SCHSTATE.groupStaff);

        SCH_Filter_ResourceState_Bases(SCHSTATE.ResourceState[3].children, false);

        if (LOCALSTORAGE.SCHfilters != undefined) {
          if (LOCALSTORAGE.SCHfilters.Status == 0) {
            RemoveResourceState(SCHSTATE.ResourceState, 'Status');
          }
          if (LOCALSTORAGE.SCHfilters.Aircraft == 0) {
            RemoveResourceState(SCHSTATE.ResourceState, 'Aircraft');
          }
          if (LOCALSTORAGE.SCHfilters.Staff == 0) {
            RemoveResourceState(SCHSTATE.ResourceState, 'Staff');
          }
          if (LOCALSTORAGE.SCHfilters.Vehicles == 0) {
            RemoveResourceState(SCHSTATE.ResourceState, 'Vehicles');
          }
        }
      }

      dp.treeEnabled = true;
      dp.treeIndent = 3;
      dp.treePreventParentUsage = true;
      dp.resources = SCHSTATE.ResourceState;
      dp.init();
    }
    dp.events.filter($('#filter').val());
    SCHSTATE.visibleStart = dp.visibleStart().getTime();
    SCHSTATE.visibleEnd = dp.visibleEnd().getTime();
    SCH_LoadEvents();
  });
}

function RemoveResourceState(items, name) {
  for (var i in items) {
    if (items[i].name == name) {
      items.splice(i, 1);
      break;
    }
  }
}

function SCH_Filter_ResourceState_Bases(items, grouped) {
  if (LOCALSTORAGE.SCHfilters != undefined) {
    //apply the new pretty base filters to resource sections
    if (LOCALSTORAGE.SCHfilters.AllBases == 1) {
      return;
    }
    var BaseList = LOCALSTORAGE.SCHfilters.Bases;
    if (grouped == 'true' || grouped == true) {
      var i = items.length - 1;
      while (i >= 0) {
        var Items = items[i].children;
        var I = Items.length - 1;
        while (I >= 0) {
          var found = false;
          for (var b in BaseList) {
            if (Items[I].BASED_IN == BaseList[b]) {
              found = true;
              break;
            }
          }
          if (found === false) {
            Items.splice(I, 1);
          }
          I--;
        }
        if (Items.length == 0) {
          items.splice(i, 1);
        }
        i--;
      }
    } else {
      var i = items.length - 1;
      while (i >= 0) {
        var found = false;
        for (var b in BaseList) {
          if (items[i].BASED_IN == BaseList[b]) {
            found = true;
            break;
          }
        }
        if (found === false) {
          items.splice(i, 1);
        }
        i--;
      }
    }
  }
}

function SCH_Filter_ResourceState_AcTypes(items, grouped) {
  if (LOCALSTORAGE.SCHfilters != undefined) {
    //apply the new pretty base filters to resource sections
    var TypeList = LOCALSTORAGE.SCHfilters.AcTypes;
    if (grouped == 'true' || grouped == true) {
      var i = items.length - 1;
      while (i >= 0) {
        var Items = items[i].children;
        var found = false;
        for (var b in TypeList) {
          if (items[i].name == TypeList[b]) {
            found = true;
            break;
          }
        }
        if (Items.length == 0 || found === false) {
          items.splice(i, 1);
        }
        i--;
      }
    }
  }
}

function SCH_Filter_ResourceState_StaffTypes(items, grouped) {
  if (LOCALSTORAGE.SCHfilters != undefined) {
    //apply the new pretty base filters to resource sections
    var TypeList = [];
    var T = LOCALSTORAGE.SCHfilters;
    if (T.Ground == undefined) {
      T.Ground = 1;
    } //to set previously undefined flag for versions pervious to 1.36.0
    //to set previously undefined flag for versions pervious to 2022-09-21
    if (T['Flight Follower'] == undefined) {
      T['Flight Follower'] = 1;
    }
    if (T.Maintenance == undefined) {
      T.Maintenance = 1;
    }
    if (T.Medic == undefined) {
      T.Medic = 1;
    }

    if (T.Engineer == 1) {
      TypeList.push('Engineer');
    }
    if (T.Pilot == 1) {
      TypeList.push('Pilot');
    }
    if (T.Ground == 1) {
      TypeList.push('Ground');
    }
    if (T.Admin == 1) {
      TypeList.push('Admin');
    }
    if (T.Attendant == 1) {
      TypeList.push('Attendant');
    }
    if (T['Flight Follower'] == 1) {
      TypeList.push('Flight Follower');
    }
    if (T.Maintenance == 1) {
      TypeList.push('Maintenance');
    }
    if (T.Medic == 1) {
      TypeList.push('Medic');
    }
    if (grouped == 'true' || grouped == true) {
      var i = items.length - 1;
      while (i >= 0) {
        var Items = items[i].children;
        var found = false;
        for (var b in TypeList) {
          if (items[i].name == TypeList[b]) {
            found = true;
            break;
          }
        }
        if (Items.length == 0 || found === false) {
          items.splice(i, 1);
        }
        i--;
      }
    }
  }
}

function SCH_Filter_ResourceState_StatusTypes(items, grouped) {
  if (LOCALSTORAGE.SCHfilters != undefined) {
    //apply the new pretty base filters to resource sections
    var TypeList = [];
    var T = LOCALSTORAGE.SCHfilters;
    if (T.Booked == 1) {
      TypeList.push('Booked');
    }
    if (T.Tentative == 1) {
      TypeList.push('Tentative');
    }
    if (T.Cancelled == 1) {
      TypeList.push('Cancelled');
    }
    if (grouped == 'true' || grouped == true) {
      var i = items.length - 1;
      while (i >= 0) {
        var found = false;
        for (var b in TypeList) {
          if (items[i].name == TypeList[b]) {
            found = true;
            break;
          }
        }
        if (found === false) {
          items.splice(i, 1);
        }
        i--;
      }
    } else {
    }
  }
}

function SCH_DisableNote_BG() {
  if ($('#SHC_DisableNotifications').is(':checked')) {
    $("label[for='SHC_DisableNotifications']").addClass('redbg');
  } else {
    $("label[for='SHC_DisableNotifications']").removeClass('redbg');
  }
  $('#SHC_DisableNotifications').checkboxradio('refresh');
}

function GetDetailMarkup(_booking, _localTime) {
  var template = '<tr><td><b>%</b></td><td>%</td></tr>';

  var html = '<h1>%</h1><table class="limit-table wrap">'.tr(_booking.Client_Text);

  var formatted = {
    Status: '',
    Client_Text: '',
    Job_Text: '',
    UNIT: '',
    Flight_Reports: '',
    Flight_Time: '',
    description: '',
    start: '',
    end: '',
  };
  if (_booking.hasOwnProperty('Associated') && _booking.Associated.hasOwnProperty('LastEdit')) {
    var lEdit = _booking.Associated.LastEdit;

    // The given booking has previous edit detail
    for (var prop in lEdit) {
      if (lEdit.hasOwnProperty(prop) && _booking.hasOwnProperty(prop)) {
        // Both history and current deets have the property
        formatted[prop] = '';
        if (lEdit[prop] !== _booking[prop] && prop !== 'Booked_By') {
          formatted[prop] = '<i class="fal fa-history"></i> ';
        }
      }
    }
  }
  var FRLinks = '';
  if (GROUP_DATA.FlightReports == 1 && _booking.Associated != undefined) {
    if (_booking.Associated.FlightReports != undefined) {
      for (var f in _booking.Associated.FlightReports) {
        var SCHFRPK = _booking.Associated.FlightReports[f].PrimaryKey;
        var ref =
          '<a href="#" onclick="ViewFlightReportPK_SCH(' + SCHFRPK + ",'Schedule_ViewItem');\">#" + SCHFRPK + '</a>, ';
        FRLinks += ref;
      }
    }
  }
  html += template.tr('Status', formatted.Status + _booking.Status);
  html += template.tr('Client', formatted.Client_Text + _booking.Client_Text);
  html += template.tr('Job', formatted.Job_Text + _booking.Job_Text);
  html += template.tr('Division', formatted.UNIT + _booking.UNIT);
  html += template.tr('Flight Reports', formatted.Flight_Reports + FRLinks);
  html += template.tr('Approx. Flight Hours', formatted.Flight_Time + _booking.Flight_Time);
  html += template.tr('Details', formatted.description + nl2br(_booking.description));
  html += template.tr('Timezone:', SCHSTATE.timezoneLabel);

  // The time zone conversion occurred earlier in the process so we aren't doing it again
  html += template.tr('Start:', formatted.start + VueBridge.dateTimeUtils.humanTime(_booking.start));
  html += template.tr('End:', formatted.end + VueBridge.dateTimeUtils.humanTime(_booking.end));

  if (_booking.Associated != undefined) {
    if (_booking.Associated.categoryID != 0 && _booking.Associated.categoryID != undefined) {
      if (SCH_CatLookup[_booking.Associated.categoryID] != undefined) {
        html +=
          '<tr><td><b>' +
          iTrans('Category') +
          '</td><td style="background-color: ' +
          SCH_CatLookup[_booking.Associated.categoryID] +
          '">' +
          SCH_CatNameLookup[_booking.Associated.categoryID] +
          '</td>';
      }
    }
    if (_booking.Associated.Cfields != undefined) {
      html +=
        '<tr><td><b>' +
        iTrans('Additional Details') +
        '</td><td>' +
        SCH_getCfieldHTML(_booking.Associated.Cfields) +
        '</td>';
    }
  }
  html += template.tr('&nbsp;', '&nbsp;');
  html += template.tr('Booked On', SCH_getHumanTime(_booking.Booked_Date));
  html += template.tr('Booked By', _booking.Booked_By);

  html += '</table>';

  return html;
}

function SCH_getCfieldHTML(cFields) {
  var LegDesc = '';
  for (var c in cFields) {
    var C = cFields[c];
    //Hide all aircraft specific Cfields on main FR since they are for custom processing.
    if (C.Aircraft !== '' && C.Aircraft != undefined) {
      continue;
    }
    if (
      (!checkCfieldType('SCH', C) && !checkCfieldType('SCA', C, hasSchedulerAccess('admin'))) ||
      (C.hideDefault == 1 &&
        (C.Value == 0 || C.Value == C.default || C.Value == '' || (C.Value == 'false' && C.type == 'signature')))
    )
      continue;

    if (C.Bill_Item == 0) {
      if (C.type == 'checkbox') {
        var checked = 'no';
        if (C.Value == 1) checked = 'yes';
        LegDesc += '<br/>' + C.Label + ': ';
        LegDesc += checked;
      } else if (C.type == 'signature') {
        LegDesc += '<p><b>' + C.Label + '</b>: ';
        try {
          var SigValue = JSON.parse(C.Value);
          if (SigValue.SignatureName != undefined) {
            LegDesc +=
              '<div style="display: inline-block;"><p class="wrap">' +
              JSON.parse(C.options).SignatureDescription +
              '</p>';
            LegDesc += '<img src="' + SigValue.data + '" width="321px" height="150px"/><br />';
            LegDesc += '<center><b>' + SigValue.SignatureName + '</b></center></div></p>';
          } else {
            LegDesc += iTrans('Unsigned') + '</p>';
          }
        } catch (e) {
          LegDesc += iTrans('Unsigned') + '</p>';
        }
      } else {
        LegDesc += '<br/>' + C.Label + ': ';
        LegDesc += C.Value;
      }
    } else {
      continue; //Billable items not displayed in scheduler views
      //billable item
      var BIOL = JSON.parse(C.Bill_Item_options);
      if (C.type == 'checkbox') {
        if (C.Value == 0) {
          if (MakingInvoice) {
            if (BILLING_INVOICE_DATA.HideEmptyCfields == 1) continue;
          }
          LegDesc += '<br/>' + C.Label + ': ';
          LegDesc += iTrans('Not Charged');
        } else {
          LegDesc += '<br/>' + C.Label + ': ';
          LegDesc += BIOL.Multiple.toLocaleString('en-US', CUR);
        }
      } else {
        if (L.Billable == 1 && !(BIOL.Unit == 'BillByVal' && J.BillBy == 'HourPR')) {
          var Total = 0;
          var BIOLUnit = '';
          var Multiple = parseFloat(BIOL.Multiple);
          if (BIOL.Unit == 'BillByVal') {
            if (J.BillBy == 'Distance' || J.BillBy == 'Weight' || J.BillBy == 'Volume') {
              Multiple = parseFloat(L.BillByVAL);
              BIOLUnit = Unit;
            }
            if (J.BillBy != 'HourPL') {
              Multiple = parseFloat(L.BillByVAL);
              BIOLUnit = 'Leg Hr';
            }
            if (J.BillBy == 'HourPR') {
              Multiple = 0;
              BIOLUnit = 'ERROR';
            }
            if (J.BillBy == 'Hour' || J.BillBy == 'Hour2' || J.BillBy == 'Minute' || J.BillBy == 'HourMinute') {
              Multiple = FT;
              BIOLUnit = 'Leg Hr';
            }
          }
          var iValue = parseFloat(C.Value);

          if (BIOL.Unit != undefined && BIOL.Unit != 'BillByVal') BIOLUnit = BIOL.Unit;
          if (isNaN(iValue)) iValue = 0;
          if (isNaN(Multiple)) Multiple = 0;
          Total = iValue * Multiple;
          Total = Math.round(Total * 100) / 100;
          if (BIOL.Unit == 'BillByVal') {
            LegDesc += '<br/>' + C.Label + ': ';
            LegDesc +=
              round10(parseFloat(iValue)).toLocaleString('en-US', CUR) +
              ' X ' +
              Multiple +
              ' ' +
              iTrans(BIOLUnit) +
              ' = <b>' +
              Total.toLocaleString('en-US', CUR) +
              '</b>';
          } else {
            LegDesc += '<br/>' + C.Label + ': ';
            LegDesc +=
              iValue +
              iTrans(BIOLUnit) +
              ' @ ' +
              Multiple.toLocaleString('en-US', CUR) +
              ' = <b>' +
              Total.toLocaleString('en-US', CUR) +
              '</b>';
          }
        }
      }
    }
  }
  return LegDesc;
}

function GetHistoryMarkup(_history) {
  _history.sort(function (a, b) {
    return new Date(b.schh_changeTime) - new Date(a.schh_changeTime);
  });

  var html = '<h3>Booking History</h3>';
  html += '<ul data-role="listview" class="ui-frfix">';
  for (var h = 0; h < _history.length; h++) {
    (function (_histEl) {
      var niceDate = SCH_getHumanTime(_histEl.schh_changeTime, true);
      if (_histEl.hasOwnProperty('schh_changeString')) {
        html += '<li class="listItem-noDetails"><a><span>%</span> <span>%</span> %</a></li>'.tr(
          niceDate,
          _histEl.schh_changeUser,
          _histEl.schh_changeString
        );
        return;
      } else if (h === _history.length - 1) {
        html += '<li class="listItem-noDetails"><a><span>%</span>  <span>%</span> created the booking.</a></li>'.tr(
          niceDate,
          _histEl.schh_changeUser
        );
        return;
      }

      html +=
        '<li><a onclick="OpenHistoryDetails(%)"><span>%</span>  <span>%</span> changed the booking\'s details.</a></li>'.tr(
          _histEl.ModKey,
          niceDate,
          _histEl.schh_changeUser
        );
    })(_history[h]);
  }

  html += '</ul>';

  return html;
}

function OpenHistoryDetails(_modKey) {
  if (!ONLINE) {
    SCH_IS_OFFLINE();
    return;
  }

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Scheduler-AJAX.php?action=getDetailHistory',
    data: {
      historyItemPk: _modKey,
    },
  }).success(function (_data) {
    DialogWithHistoryDetails(_data.data);
  });
}

function DialogWithHistoryDetails(_results) {
  var contents = '<div class="sama-dialog-container" id="prevEventDet">' + GetDetailMarkup(_results[0], true);
  contents += '<button rel="close" data-role="button">Close</button></div>';

  $('<div>').simpledialog2({
    mode: 'blank',
    animate: false,
    headerText: 'Previous Event Details',
    headerClose: false,
    width: 600,
    zindex: 10000,
    blankContent: contents,
  });
}

function GetNiceDate(_dateTime, _isUTC) {
  if (_isUTC === true) {
    return moment.utc(_dateTime).local().format('MMM DD, YY - HH:mm');
  }

  return moment(_dateTime).format('MMM DD, YY - HH:mm');
}

function SCHsearchClientJobs(term, callback) {
  var txt = term.term;

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=searchJobsV2',
    data: { txt: txt },
  })
    .success(function (data) {
      //console.log(data);
      SEARCH_data = data;
      var options = [];
      for (var i in SEARCH_data) {
        var I = SEARCH_data[i];
        var label = '#' + I.JobNum + ': ' + I.Name + ' [' + I.Business + ']';
        options.push({ label: label, value: i });
      }
      callback(options);
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log("error");
    });
}

var SCH_RAW_SEARCH = null;

function SCHsearchClientJobsSELECT(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');

  var I = SEARCH_data[value];
  SCH_RAW_SEARCH = I;
  var raw = {
    Job_ID: I.JobID,
    Job_Text: I.Name,
    description: I.Description,
  };
  if (I.ClientPK == -1) {
    ExchangeQuoteData('getClients', null, SCH_SelectClientPrompt);
  } else {
    $('#SCH_Client').val(I.ClientPK).selectmenu('refresh');
    SCH_ChangeClient(raw);
  }
}

var SCH_CLIENTS_FILTER = null;

function SCH_SelectClientPrompt(Clients) {
  SCH_CLIENTS_FILTER = Clients;
  var html =
    '<input type="text" data-mini="true" id="SCH_QuoteClientSelect_Filter" placeholder="' +
    iTrans('Search Clients') +
    '" />';
  html += '<label for="SCH_QuoteClientSelect">' + iTrans('Select Client For Global Job') + '</label>';
  html += '<select id="SCH_QuoteClientSelect" data-mini="true">';
  var list = SCH_RAW_SEARCH.clientList;
  for (var i in Clients) {
    SCH_CLIENTS_FILTER[i].index = i;
    if (list.length == 0) {
      html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
    } else {
      for (var c in list) {
        if (Clients[i].ClientPK == list[c]) {
          html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
        }
      }
    }
  }
  html += '</select>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Client'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          var C = Clients[$('#SCH_QuoteClientSelect').val()];

          J = SCH_RAW_SEARCH;
          J.Business = C.Business;
          J.Address1 = C.Address1;
          J.Address2 = C.Address2;
          J.City = C.City;
          J.Postal = C.Postal;
          J.Country = C.Country;
          J.Firstname = C.Firstname;
          J.Lastname = C.Lastname;
          J.Email = C.Email;
          J.Phone = C.Phone;
          J.P_Hobbs = C.P_Hobbs;
          J.S_Hobbs = C.S_Hobbs;
          J.SignatureReq = C.SignatureReq;
          J.ClientPK = C.PrimaryKey;
          var raw = {
            Job_ID: J.JobID,
            Job_Text: J.Name,
            description: J.Description,
          };
          $('#SCH_Client').val(J.ClientPK).selectmenu('refresh');
          SCH_ChangeClient(raw);
        },
      },
    },
    callbackOpen: function () {
      $('#SCH_QuoteClientSelect_Filter').autocomplete({
        select: SCHsearchClientSELECT,
        source: SCH_FtilerClients,
      });
    },
  });
}

function SCHsearchClientSELECT(event, ui) {
  event.preventDefault();
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  $('#SCH_QuoteClientSelect').val(value).selectmenu('refresh');
}

function SCH_FtilerClients(term, callback) {
  var text = term.term;
  SCH_FilterClients(SCH_CLIENTS_FILTER, text, callback);
}

function SCH_FilterClients(OrigArray, keywords, callback) {
  var userFiltered = OrigArray;
  userFiltered = OrigArray.filter(function (item) {
    if (item.Business.toLowerCase().indexOf(keywords.toLowerCase()) > -1) {
      return true;
    } else {
      return false;
    }
  });
  var options = [];
  for (var i in userFiltered) {
    var I = userFiltered[i];
    options.push({
      label: escapeHtml(I.Business),
      value: I.index,
    });
  }
  callback(options);
}

function SCH_IS_OFFLINE() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'OFFLINE',
    headerClose: false,
    buttonPrompt:
      'You are currently Offline.  <br />No changes can be made, and items older than 2 months are unavailable at this time.',
    buttons: {
      Close: {
        click: function () {},
      },
      'Back to Scheduler': {
        click: function () {
          SCHschedulerBack();
        },
      },
    },
  });
}

function SCH_RefreshToast() {
  SCHSaveBooking2(true, false, true);
}

function SCH_CheckRefreshToast(e) {
  if (GROUP_DATA.PULSAR_FRMS == 0 || SCHEVENTDATA.Associated.BookedStaff.length == 0) {
    return;
  }
  if (SCHSTATE.activeToast != null) {
    toaster.remove(SCHSTATE.activeToast);
  }
  SCHSTATE.activeToast = window.toaster.show(
    iTrans('This booking has changed. Would you like to: ') +
      " <a href='#' onClick='SCH_RefreshToast();'> " +
      iTrans('Save and refresh Fatigue Meter?') +
      '</a>',
    15000
  );
}

function SCH_ControlBlock(locked) {
  var checkboxradios = ['SCH_VIEW_Month', 'SCH_VIEW_Week', 'SCH_VIEW_Day', 'SCH_VIEW_Resource'];
  var buttons = ['SCHManSyncBTN', 'SCHNAVNEXT', 'SCHNAVPREV'];
  for (var i in checkboxradios) {
    $('#' + checkboxradios[i])
      .prop('disabled', locked)
      .checkboxradio('refresh');
  }
  for (var i in buttons) {
    $('#' + buttons[i]).prop('disabled', locked);
  }
}

var SCHEDULED_AIRCRAFT = {};

function SCH_PopulateSCH_Aircraft() {
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-Scheduler-AJAX.php?action=checkSchedAC', function (json_data) {
      if (json_data.ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'None') {
          SCHEDULED_AIRCRAFT = {};
        }
        if (json_data.ServerResponse == 'Offline') {
        }
        if (json_data.ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'getDisabled');
        }
        if (json_data.ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      } else {
        SCHEDULED_AIRCRAFT = json_data;
      }
      //popItinAssetTemplates();
    }).fail(function (jqXHR, status, error) {
      SCHEDULED_AIRCRAFT = {};
    });
  } else {
    SCHEDULED_AIRCRAFT = {};
  }
}

var SCH_FOLLOWING_EDIT = {
  ItineraryPK: 0,
  FOLLOW_INDEX: 0,
  ident: '',
  isEditing: false,
  editLegs: false,
};

function SCHmakeFunction(raw, acIndex, type, itinIndex = false) {
  var func = function () {
    console.log('Make Function Type: ' + type);
    console.log(raw);
  };
  switch (type) {
    case 'openItinerary':
      func = function () {
        SCH_OpenItin(raw, acIndex);
      };
      break;
    case 'editItinerary':
      func = function () {
        SCH_EditItin(raw, acIndex, false);
      };
      break;
    case 'editWBlegs':
      func = function () {
        SCH_EditItin(raw, acIndex, true);
      };
      break;
    case 'reportItinerary':
      func = function () {
        SCH_ReportItin(raw, acIndex, itinIndex);
      };
      break;
    case 'reportFlight':
      func = function () {
        SCH_EditFlightReport(raw, acIndex, itinIndex);
      };
      break;
    case 'editQuote':
      func = function () {
        SCH_EditQuote(raw, acIndex);
      };
      break;
    case 'reportFDT':
      func = function () {
        SCH_reportFDT(raw, acIndex);
      };
      break;
  }
  return func;
}

function SCH_Get_RC_Options(raw) {
  console.log(raw);
  var items = [];
  if (SCHEDULED_AIRCRAFT[raw.PrimaryKey] != undefined) {
    var BAC = SCHEDULED_AIRCRAFT[raw.PrimaryKey].Associated.BookedAC;
    for (var i in BAC) {
      var I = BAC[i];
      var createItin = {
        text: 'Open Itinerary',
        disabled: true,
      };

      if (I.hasOpenItin == false) {
        var funcItin = SCHmakeFunction(raw, i, 'openItinerary');
        createItin = {
          text: 'Open Itinerary',
          onclick: funcItin,
          disabled: false,
        };
        editItin = {
          text: 'Edit Itinerary',
          disabled: true,
        };
      }

      var ItinOpts = [];
      if (I.Itinerary != false) {
        var Itins = I.Itinerary;
        for (var a in Itins) {
          var A = Itins[a];
          if (A.status != 'CLOSED') {
            var funcItinEdit = SCHmakeFunction(raw, i, 'editItinerary');
            var funcEditWBlegs = SCHmakeFunction(raw, i, 'editWBlegs');
            ItinOpts.push({
              text: 'Edit Itin #' + A.PrimaryKey + ' ' + A.filed,
              onClick: funcItinEdit,
              disabled: false,
              items: [
                {
                  text: 'Update Leg Details',
                  onclick: funcEditWBlegs,
                },
              ],
            });
          } else {
            var funcItinReport = SCHmakeFunction(raw, i, 'reportItinerary', a);
            ItinOpts.push({
              text: 'Report Itin #' + A.PrimaryKey + ' ' + A.filed,
              onClick: funcItinReport,
              disabled: false,
            });
          }
        }
      } else {
        ItinOpts.push({ text: 'No Unreported Itinerary' });
      }
      var reportItin = { text: 'Itinerary', items: ItinOpts };

      var FlightReportOpts = [];
      if (I.FlightReport != false) {
        var Reports = I.FlightReport;
        for (var b in Reports) {
          var B = Reports[b];
          var funcFlightReport = SCHmakeFunction(raw, i, 'reportFlight', b);
          FlightReportOpts.push({ text: '#' + B.PrimaryKey + ' ' + B.Date, onClick: funcFlightReport });
        }
      } else {
        FlightReportOpts.push({ text: 'No Reports Available' });
      }

      var funcEditQuote = SCHmakeFunction(raw, i, 'editQuote');

      var editQuote = { text: 'Edit Planned Legs', onClick: funcEditQuote };

      var editReports = { text: 'Flight Report', items: FlightReportOpts };

      var item = { text: I.ident, items: [createItin, editQuote, reportItin, editReports] };
      items.push(item);
    }
  }
  if (raw.Associated != undefined) {
    var bookedStaff = raw.Associated.BookedStaff;
    var bookedStaffOpts = [];
    var uniqueStaff = {};
    for (var b in bookedStaff) {
      var B = bookedStaff[b];
      if (uniqueStaff[B.Staff_ID] == undefined) {
        if (userShouldHaveFDT(B.StaffType)) {
          var funcFDTReport = SCHmakeFunction(raw, b, 'reportFDT');
          bookedStaffOpts.push({
            text: B.chatname + ' [' + B.StaffType + ']',
            onClick: funcFDTReport,
            disabled: false,
          });
        }
        uniqueStaff[B.Staff_ID] = true;
      }
    }
    var editFDT = { text: 'Edit FDT', items: bookedStaffOpts };
    if (bookedStaffOpts.length > 0) {
      items.push(editFDT);
    }
  }

  items.push({ text: 'Delete Booking', onClick: SCH_DeleteItem });
  return items;
}

function SCH_EditQuote(raw, I) {
  SCHEVENTDATA = raw;
  SCHEditEvent(null, (refresh = false), (_scrollTo = 0), raw);
  SCH_LegEntry(I);
}

function SCH_OpenItin(raw, I) {
  SCHEDULED_TEMPLATES[0] = raw;
  LoadScheduleTemplate(0, I);
}

function SCH_EditItin(raw, I, editLegs) {
  var AC = SCHEDULED_AIRCRAFT[raw.PrimaryKey].Associated.BookedAC[I];
  console.log(AC);
  for (var i in AC.Itinerary) {
    var I = AC.Itinerary[i];
    if (I.status != 'CLOSED') {
      for (f in Follow_Data) {
        var F = Follow_Data[f];
        if (F.AC.ident == AC.ident) {
          if (F.Itin == null) {
            alert(iTrans('Itinerary is no longer available to edit'));
            return;
          }
          if (F.Itin.PrimaryKey != I.PrimaryKey) {
            alert(iTrans('Itinerary is no longer available to edit'));
            return;
          }
          SCH_FOLLOWING_EDIT = {
            ItineraryPK: F.Itin.PrimaryKey,
            FOLLOW_INDEX: f,
            ident: AC.ident,
            isEditing: true,
            editLegs: editLegs,
          };
          EditItin(f, F.Itin.PrimaryKey, false);
        }
      }
    }
  }
}

function SCH_ReportItin(raw, I, a) {
  var Itin = SCHEDULED_AIRCRAFT[raw.PrimaryKey].Associated.BookedAC[I].Itinerary[a];
  console.log(Itin);
  $('#RPT_FR_Client').val(-1);
  $('#RPT_FR_Job').val(-1);
  BILLING_NewFlightReport(false, true, { Type: 'Itinerary', PrimaryKey: Itin.PrimaryKey });
}

function SCH_EditFlightReport(raw, I, a) {
  var FR = SCHEDULED_AIRCRAFT[raw.PrimaryKey].Associated.BookedAC[I].FlightReport[a];
  console.log(FR);
  BILLING_NewFlightReport(false, true, { Type: 'FlightReport', PrimaryKey: FR.PrimaryKey });
}

function SCH_EditReport(e) {
  var raw = e.source.data.raw;
}

function SCH_RC_Options(e) {
  var raw = e.source.data.raw;
}

function SCH_reportFDT(raw, index) {
  var User = raw.Associated.BookedStaff[index];
  console.log(User);
  if (SYNCING || FDT_SYNCING) {
    alert('Sync currently in progress. Please wait to try again');
    return;
  }
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=GetStaffFDT&USER_PK=' + User.Staff_ID, function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') {
      ServerResponse(json_data[0], 'getpweight');
    } else if (json_data[0].ServerResponse == 'Not Logged In') {
      NOTLOGGEDIN();
    } else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      ADMIN_FDT_DATA.list = json_data[0].Pilots;
      const xit = $('#exitDutyTimes');
      xit.unbind('click').click(hrefHandler);
      toggleBackButton(xit, false);
      FDT_ADMIN_UPDATE = true;
      LOCALSTORAGE.FDT_Defaults = undefined;
      localStorageDB.setItem('FDT_Defaults', JSON.stringify(null));
      ADMIN_FDT_DATA.returnpage = 'Schedule_Page';
      ADMIN_FDT_DATA.futureMonths = 0;
      ADMIN_FDT_DATA.index = 0;
      ADMIN_FDT_DATA.LastData = ADMIN_FDT_DATA.list[0].LastData;
      ADMIN_FDT_DATA.CurUser = ADMIN_FDT_DATA.list[0];
      FDT_OPK = ADMIN_FDT_DATA.CurUser.PrimaryKey;
      USERTYPE = ADMIN_FDT_DATA.CurUser.staffType;
      $('#Duty_Status').html(iTrans('Admin Update') + ': ' + ADMIN_FDT_DATA.CurUser.chatname);
      localStorageDB.setItem('AdminFDT_Edit', FDT_OPK, function (e) {
        LOCALSTORAGE.AdminFDT_Edit = FDT_OPK;
      });
      localStorageDB.setItem('FDT_Sync_Error', '0');
      LOCALSTORAGE.FDT_Sync_Error = 0;
      localStorageDB.setItem('INIT_Login', '1');
      cirroDB.clearAll('FDT_DaysV4', function (e) {
        cirroDB.clearAll('FDT_RecsV3', function (e) {
          cirroDB.clearAll('FDT_StaffV3', function (e) {
            verbose.log('fdt', 'All local fdt records deleted for edit start.');
            loadFdtModule(() => {
              $.mobile.loading('show', { theme: 'a' });
              FDT_MODULE_VERSION = 4;
              SYNCING = true;
              VueBridge.LegacyAppSyncing(SYNCING);
              FDT_SYNCING = true;
              syncFDT();
            });
            //
          });
        });
      });
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
