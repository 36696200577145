class eSAMA_Button {
  /**
   * @param {string} [_p.label]
   * @param {string} [_p.icon]
   * @param {string} [_p.classes]
   * @param {int[]}  [_p.showStates]
   */
  constructor(_p) {
    _p.label = _p.label == null ? 'Unlabeled' : _p.label;
    _p.icon = _p.icon == null ? '' : _p.icon;
    _p.classes = _p.classes == null ? '' : _p.classes;
    _p.showStates = _p.showStates == null ? [] : _p.showStates;

    this.label = _p.label;
    this.showStates = _p.showStates;

    this.dom = {
      button: $(SAMA.Templates.tpl.general.headerButton.formatTemplate(_p)),
    };
  }

  Loading(show) {
    if (show) {
      this.dom.button.prop('disabled', true);
      this.dom.button.addClass('sama-loading');
      return;
    }
    this.dom.button.prop('disabled', false);
    this.dom.button.removeClass('sama-loading');
  }

  GetDom() {
    return this.dom.button;
  }

  Hide() {
    this.dom.button.hide();
  }

  Show() {
    this.dom.button.show();
  }

  Destroy() {
    this.dom.button.remove();
  }
}
