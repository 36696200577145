/**
 *
 */
class PassengersTable extends AbstractDatabaseTable {
  constructor() {
    super('PassengersV2', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"    numeric,
            "OFFLINE_PK"    numeric,
            "group"         text,
            "BASE"          text,
            "homebase_icao" text,
            "paxcode"       text,
            "deptID"        numeric,
            "authcodeID"    numeric,
            "note"          text,
            "clientID"      numeric,
            "active"        numeric,
            "fltpurposeID"  numeric,
            "company"       text,
            "addr_1"        text,
            "addr_2"        text,
            "city"          text,
            "prov_state"    text,
            "postal"        text,
            "nation"        text,
            "ctryresid"     text,
            "ClientPK"      numeric,
            "country"       text,
            "firstname"     text,
            "middlename"    text,
            "lastname"      text,
            "passport_num"  text,
            "DOB"           text,
            "sex"           text,
            "visa"          text,
            "weight"        numeric,
            "units"         text,
            "VIP"           numeric,
            "Cfields"       text,
            "modified"      text,
            "modUserPK"     numeric,
            "DELETED"       numeric,
            "Briefings"     text,
            "filledForms"   text,
            "Contacts"      text,
            primary key ("PrimaryKey")
        
        )
    `;
    //#endregion
  }

  async insert(data) {
    const parsed = [];
    for (let row of data) {
      row.Briefings = this.encodeJsonIfNotString(row.Briefings);
      row.filledForms = this.encodeJsonIfNotString(row.filledForms);
      row.Contacts = this.encodeJsonIfNotString(row.Contacts);
      row.Cfields = this.encodeJsonIfNotString(row.Cfields);

      // Mike: seatPos used only for flight itinerary not for general passenger data here
      delete row['seatPos'];
      parsed.push(row);
    }

    return super.insert(parsed);
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    const parsed = [];
    for (let row of rows) {
      row.Briefings = this.parseJson(row.Briefings);
      row.filledForms = this.parseJson(row.filledForms);
      row.Contacts = this.parseJson(row.Contacts);
      row.Cfields = this.parseJson(row.Cfields);
      parsed.push(row);
    }

    return parsed;
  }
}
