/* ################################ Copyright © 2017 AirSuite Inc. All Rights Reserved   ###################### */
var RadarImages = [];
var USRadarImages = [];
var Animate = false;
var radarType = 'pr';
var radarCalls = [];
var radarValue = '0';

function radarChange(e) {
  console.log(e);
  for (var i in RadarImages) {
    //remove all images if present in array
    if (RadarImages[i] !== undefined) {
      var id = RadarImages[i];
      if (mapb.getLayer(id) != undefined) {
        mapb.removeLayer(id);
        mapb.removeSource(id);
      }
    }
  }
  for (var ii in USRadarImages) {
    //remove all images if present in array
    if (USRadarImages[ii] !== undefined) {
      if (mapb.getLayer(USRadarImages[ii]) != undefined) {
        mapb.removeLayer(USRadarImages[ii]);
        mapb.removeSource(USRadarImages[ii]);
      }
    }
  }
  RadarImages = []; //clear array for new list
  USRadarImages = [];
  if (e.id != 'RADAR_animateON') {
    radarValue = e.value;
    //if ($("#Radar-mapboxCHK").is(":checked")) radarValue = 1;
    //console.log(this);
    console.log(radarValue);
    switch (radarValue) {
      case '0':
        mapb.off('dragend', checkRadar);
        break;
      case '1':
        console.log('Precipition');
        mapb.on('dragend', checkRadar);
        radarType = 'pr';
        checkRadar();
        break;
      case '2':
        mapb.on('dragend', checkRadar);
        radarType = 'et';
        checkRadar();
        break;
      case '3':
        mapb.on('dragend', checkRadar);
        radarType = 'sn';
        checkRadar();
        break;
    }
  } else {
    if ($('#RADAR_animateON').is(':checked')) {
      Animate = true;
    } else {
      Animate = false;
    }
    if (radarValue != '0') {
      checkRadar();
    } //check for new images if radar is not off
  }
}

function checkRadar() {
  console.log('Checking Radar');
  var bounds = mapb.getBounds();
  //console.log(bounds.getWest()+" "+bounds.getSouth()+" "+bounds.getEast()+" "+bounds.getNorth());

  bounds = turf.bboxPolygon([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]);
  var mapcenter = turf.center(bounds);
  var distanceCA = 21000000; //more than half the circumference of earth
  var closestIndexCA;
  var closestBoundsCA;
  var distanceUS = 21000000; //more than half the circumference of earth
  var closestIndexUS;
  var closestBoundsUS;
  for (var i in radarsiteList) {
    var site = radarsiteList[i];
    var radarBoxCA = turf.bboxPolygon([
      parseFloat(site.SElong),
      parseFloat(site.SElat),
      parseFloat(site.NWlong),
      parseFloat(site.NWlat),
    ]);
    //console.log(site.SElong+" "+ site.SElat+" "+site.NWlong+" "+site.NWlat);
    //console.log(radarBox);
    //console.log(bounds);

    if (turf.intersect(radarBoxCA, bounds) != null) {
      var centerCA = turf.center(radarBoxCA);
      //console.log(turf.center(radarBox));
      //console.log(mapcenter);
      //console.log("Detected");
      var checkCA = turf.distance(centerCA, mapcenter, { units: 'meters' });
      //console.log(check);
      if (checkCA < distanceCA) {
        distanceCA = checkCA;
        closestIndexCA = i;
        closestBoundsCA = radarBoxCA;
      }
    }
    //console.log(turf.intersect(center,bounds));
  }
  for (var i in USradarsiteList) {
    var site = USradarsiteList[i];
    var radarBoxUS = turf.bboxPolygon([
      parseFloat(site.SElong),
      parseFloat(site.SElat),
      parseFloat(site.NWlong),
      parseFloat(site.NWlat),
    ]);
    //console.log(site.SElong+" "+ site.SElat+" "+site.NWlong+" "+site.NWlat);
    //console.log(radarBox);
    //console.log(bounds);

    if (turf.intersect(radarBoxUS, bounds) != null) {
      var centerUS = turf.center(radarBoxUS);
      //console.log(turf.center(radarBox));
      //console.log(mapcenter);
      //console.log("Detected");
      var checkUS = turf.distance(centerUS, mapcenter, { units: 'meters' });
      //console.log(check);
      if (checkUS < distanceUS) {
        distanceUS = checkUS;
        closestIndexUS = i;
        closestBoundsUS = radarBoxUS;
      }
    }
    //console.log(turf.intersect(center,bounds));
  }
  if (distanceCA < distanceUS) {
    //radar types == pr / et
    getRadar(radarsiteList[closestIndexCA].Site, radarType, closestBoundsCA, Animate, closestIndexCA, 'CA');
  } else {
    //radar types == NCR / NET
    var radarTypeUS = 'NET';
    if (radarType == 'pr') {
      radarTypeUS = 'NCR';
    }
    getRadar(USradarsiteList[closestIndexUS].Site, radarTypeUS, closestBoundsUS, Animate, closestIndexUS, 'US');
  }
}

function getRadar(Site, Type, radarbounds, Animate, index, C) {
  var site;
  if (C == 'CA') {
    if (RadarImages[index] !== undefined) {
      mapb.removeLayer(RadarImages[index]);
      mapb.removeSource(RadarImages[index]); //remove existing image to restack;
      console.log('Removed Layer');
    }
    site = radarsiteList[index];
    RadarImages[index] = 'Radar' + Site;
  } else {
    if (USRadarImages[index] !== undefined) {
      mapb.removeLayer(USRadarImages[index]);
      mapb.removeSource(USRadarImages[index]); //remove existing image to restack;
    }
    site = USradarsiteList[index];
    USRadarImages[index] = 'Radar' + Site;
  }

  var radarBox = [
    [parseFloat(site.SElong), parseFloat(site.NWlat)],
    [parseFloat(site.NWlong), parseFloat(site.NWlat)],
    [parseFloat(site.NWlong), parseFloat(site.SElat)],
    [parseFloat(site.SElong), parseFloat(site.SElat)],
  ];
  var action = 'Null';
  if (Animate) {
    action = 'Animate';
  }
  var imageUrl =
    TILE_URL + '/Radar' + C + '/' + 'getRadarV2.php?radarSite=' + Site + '&radarType=' + Type + '&action=' + action; //radar image
  //console.log(imageUrl);
  var loaderImage = 'images/loading4.webm'; //preloader image
  //var RadarImage = L.imageOverlay(loaderImage, radarbounds, {opacity:0.4}).addTo(map);
  mapb.addSource('Radar' + Site, {
    type: 'video',
    urls: [loaderImage],
    coordinates: radarBox,
  });
  mapb.addLayer({
    id: 'Radar' + Site,
    source: 'Radar' + Site,
    type: 'raster',
    paint: {
      'raster-opacity': 0.4,
    },
  });

  //use ajaxQ so multiple request do not overload and drop are insted run in sequence not parallel
  console.log(imageUrl);
  $.ajaxq('radar', {
    timeout: 60000,
    url: imageUrl,
    success: function (data) {
      if (C == 'CA') {
        if (mapb.getLayer(RadarImages[index]) != undefined) {
          mapb.removeLayer(RadarImages[index]);
          mapb.removeSource(RadarImages[index]); //remove loader icon;
        }
      } else {
        if (mapb.getLayer(USRadarImages[index]) != undefined) {
          mapb.removeLayer(USRadarImages[index]);
          mapb.removeSource(USRadarImages[index]); //remove loader icon;
        }
      }
      if (data == 'Failed') {
        alert(iTrans('Radar Site') + ' ' + Site.toUpperCase() + ' ' + iTrans('Unavailable'));
        console.log('Server Side Failed to find radar image for station');
        return;
      }
      if (Animate) {
        mapb.addSource('Radar' + Site, {
          type: 'video',
          urls: [data],
          coordinates: radarBox,
        });
        mapb.addLayer({
          id: 'Radar' + Site,
          source: 'Radar' + Site,
          type: 'raster',
          paint: {
            'raster-opacity': 0.7,
          },
        });
        console.log('Loading Image' + data);
      } else {
        mapb.addSource('Radar' + Site, {
          type: 'image',
          url: data,
          coordinates: radarBox,
        });
        mapb.addLayer({
          id: 'Radar' + Site,
          source: 'Radar' + Site,
          type: 'raster',
          paint: {
            'raster-opacity': 0.7,
          },
        });
        console.log('Loading Image' + data);
      }
    },
  }).error(function () {
    alert('Error Loading Radar Overlay!');

    if (C == 'CA') {
      if (mapb.getLayer(RadarImages[index]) != undefined) {
        mapb.removeLayer(RadarImages[index]);
        mapb.removeSource(RadarImages[index]); //remove loader icon;
      }
    } else {
      if (mapb.getLayer(USRadarImages[index]) != undefined) {
        mapb.removeLayer(USRadarImages[index]);
        mapb.removeSource(USRadarImages[index]); //remove loader icon;
      }
    }
  });
}

var radarsiteList = [
  {
    Location: 'Kelowna',
    Site: 'xss',
    SElat: '48.180000',
    SElong: '-122.470000',
    NWlat: '52.530000',
    NWlong: '-114.400000',
  },
  {
    Location: 'Prince George',
    Site: 'xpg',
    SElat: '51.480000',
    SElong: '-126.590000',
    NWlat: '55.790000',
    NWlong: '-117.940000',
  },
  {
    Location: 'Vancouver',
    Site: 'wuj',
    SElat: '46.820000',
    SElong: '-125.790000',
    NWlat: '51.190000',
    NWlong: '-117.950000',
  },
  {
    Location: 'Victoria',
    Site: 'xsi',
    SElat: '46.720000',
    SElong: '-127.060000',
    NWlat: '51.020000',
    NWlong: '-119.230000',
  },
  {
    Location: 'Brandon',
    Site: 'xfw',
    SElat: '48.370000',
    SElong: '-104.490000',
    NWlat: '52.720000',
    NWlong: '-96.410000',
  },
  {
    Location: 'Calgary',
    Site: 'xsm',
    SElat: '49.050000',
    SElong: '-116.830000',
    NWlat: '53.360000',
    NWlong: '-108.670000',
  },
  {
    Location: 'Cold Lake',
    Site: 'whn',
    SElat: '52.780000',
    SElong: '-113.710000',
    NWlat: '57.080000',
    NWlong: '-104.80000',
  },
  {
    Location: 'Edmonton',
    Site: 'whk',
    SElat: '51.420000',
    SElong: '-117.800000',
    NWlat: '55.740000',
    NWlong: '-109.120000',
  },
  {
    Location: 'Grande Prairie',
    Site: 'www',
    SElat: '53.540000',
    SElong: '-123.000000',
    NWlat: '57.870000',
    NWlong: '-113.910000',
  },
  {
    Location: 'Medicine Hat',
    Site: 'xbu',
    SElat: '48.110000',
    SElong: '-113.590000',
    NWlat: '52.500000',
    NWlong: '-105.530000',
  },
  {
    Location: 'Regina',
    Site: 'xbe',
    SElat: '48.390000',
    SElong: '-108.560000',
    NWlat: '52.760000',
    NWlong: '-100.510000',
  },
  {
    Location: 'Saskatoon',
    Site: 'xra',
    SElat: '50.360000',
    SElong: '-111.000000',
    NWlat: '54.700000',
    NWlong: '-102.550000',
  },
  {
    Location: 'Winnipeg',
    Site: 'xwl',
    SElat: '47.960000',
    SElong: '-101.130000',
    NWlat: '52.340000',
    NWlong: '-93.110000',
  },
  {
    Location: 'Dryden',
    Site: 'xdr',
    SElat: '47.705372',
    SElong: '-96.126872',
    NWlat: '51.991732',
    NWlong: '-88.215720',
  },
  {
    Location: 'London',
    Site: 'wso',
    SElat: '41.220000',
    SElong: '-84.310000',
    NWlat: '45.570000',
    NWlong: '-77.330000',
  },
  {
    Location: 'Ottawa',
    Site: 'xft',
    SElat: '42.910000',
    SElong: '-79.170000',
    NWlat: '47.190000',
    NWlong: '-71.910000',
  },
  {
    Location: 'Sault Ste Marie',
    Site: 'wgj',
    SElat: '45.121000',
    SElong: '-87.750000',
    NWlat: '49.314000',
    NWlong: '-80.371000',
  },
  {
    Location: 'Sudbury',
    Site: 'wbi',
    SElat: '43.640000',
    SElong: '-83.640000',
    NWlat: '47.960000',
    NWlong: '-76.260000',
  },
  {
    Location: 'Thunder Bay',
    Site: 'xni',
    SElat: '46.695525',
    SElong: '-92.418932',
    NWlat: '51.018958',
    NWlong: '-84.533504',
  },
  {
    Location: 'Timmins',
    Site: 'xti',
    SElat: '47.096384',
    SElong: '-85.101930',
    NWlat: '51.455016',
    NWlong: '-77.241308',
  },
  {
    Location: 'Toronto',
    Site: 'wkr',
    SElat: '41.820000',
    SElong: '-82.590000',
    NWlat: '46.130000',
    NWlong: '-75.440000',
  },
  {
    Location: 'Chicoutimi',
    Site: 'wmb',
    SElat: '46.430000',
    SElong: '-73.930000',
    NWlat: '50.740000',
    NWlong: '-66.160000',
  },
  {
    Location: 'Montreal',
    Site: 'wmn',
    SElat: '43.280000',
    SElong: '-77.020000',
    NWlat: '47.590000',
    NWlong: '-69.70000',
  },
  {
    Location: 'Quebec City',
    Site: 'wvy',
    SElat: '44.320000',
    SElong: '-75.060000',
    NWlat: '48.600000',
    NWlong: '-67.590000',
  },
  {
    Location: 'Rimouski',
    Site: 'xam',
    SElat: '46.330000',
    SElong: '-70.860000',
    NWlat: '50.660000',
    NWlong: '-63.120000',
  },
  {
    Location: "Val d'Or",
    Site: 'xla',
    SElat: '46.400000',
    SElong: '-81.080000',
    NWlat: '50.690000',
    NWlong: '-73.320000',
  },
  {
    Location: 'Corner Brook',
    Site: 'xme',
    SElat: '46.790000',
    SElong: '-61.120000',
    NWlat: '51.100000',
    NWlong: '-53.290000',
  },
  {
    Location: 'Halifax',
    Site: 'xgo',
    SElat: '42.950000',
    SElong: '-66.770000',
    NWlat: '47.250000',
    NWlong: '-59.460000',
  },
  {
    Location: 'Moncton',
    Site: 'xnc',
    SElat: '44.070000',
    SElong: '-68.840000',
    NWlat: '48.390000',
    NWlong: '-61.390000',
  },
  {
    Location: "St. John's",
    Site: 'wtp',
    SElat: '45.170000',
    SElong: '-56.370000',
    NWlat: '49.5000000',
    NWlong: '-48.760000',
  },
  {
    Location: 'Sidney',
    Site: 'xmb',
    SElat: '43.800000',
    SElong: '-63.310000',
    NWlat: '48.100000',
    NWlong: '-55.910000',
  },
];
var USradarsiteList = [
  {
    Site: 'ABC',
    SElat: '56.7357548661666',
    SElong: '-166.284681479714',
    NWlat: '64.8355168290572',
    NWlong: '-157.448577520197',
  },
  {
    Site: 'ABR',
    SElat: '42.6312411915172',
    SElong: '-101.48383920843',
    NWlat: '48.2705081592907',
    NWlong: '-95.3319116072226',
  },
  {
    Site: 'ABX',
    SElat: '32.7261689619585',
    SElong: '-109.457981178977',
    NWlat: '37.5650361494585',
    NWlong: '-104.179216974432',
  },
  {
    Site: 'ACG',
    SElat: '53.2310402471369',
    SElong: '-139.46548495206',
    NWlat: '60.4618141729181',
    NWlong: '-131.577367942117',
  },
  {
    Site: 'AEC',
    SElat: '59.9133993807706',
    SElong: '-170.292205061479',
    NWlat: '69.09391085538',
    NWlong: '-160.277101634632',
  },
  {
    Site: 'AHG',
    SElat: '56.6771955039284',
    SElong: '-155.750514332164',
    NWlat: '64.7601117636941',
    NWlong: '-146.932787503329',
  },
  {
    Site: 'AIH',
    SElat: '55.5651131231134',
    SElong: '-150.537358426181',
    NWlat: '63.3447456914728',
    NWlong: '-142.050486533425',
  },
  {
    Site: 'AKC',
    SElat: '54.8706270252575',
    SElong: '-160.768223211115',
    NWlat: '62.4755464588512',
    NWlong: '-152.471947465376',
  },
  {
    Site: 'AKQ',
    SElat: '34.5030581110174',
    SElong: '-79.7050719937411',
    NWlat: '39.4559389703924',
    NWlong: '-74.3019292380593',
  },
  {
    Site: 'AMA',
    SElat: '32.8066927268289',
    SElong: '-104.346674915661',
    NWlat: '37.6505037619851',
    NWlong: '-99.0625174227633',
  },
  {
    Site: 'AMX',
    SElat: '23.4130183688077',
    SElong: '-82.8024594254927',
    NWlat: '27.8010035982999',
    NWlong: '-78.0155664478649',
  },
  {
    Site: 'APD',
    SElat: '60.3476389382103',
    SElong: '-152.595780833851',
    NWlat: '69.7073496315696',
    NWlong: '-142.385187350186',
  },
  {
    Site: 'APX',
    SElat: '42.1093138538707',
    SElong: '-87.7614075678045',
    NWlat: '47.6945341907848',
    NWlong: '-81.6684399275346',
  },
  {
    Site: 'ARX',
    SElat: '41.0765317466043',
    SElong: '-94.1767289317738',
    NWlat: '46.5595029380105',
    NWlong: '-88.1953058138761',
  },
  {
    Site: 'ATX',
    SElat: '45.2227704273571',
    SElong: '-125.725156347101',
    NWlat: '51.1564404713024',
    NWlong: '-119.252061753706',
  },
  {
    Site: 'BBX',
    SElat: '36.9250307533958',
    SElong: '-124.399677928578',
    NWlat: '42.0516481850364',
    NWlong: '-118.807004366788',
  },
  {
    Site: 'BGM',
    SElat: '39.5260252102939',
    SElong: '-78.8930042891069',
    NWlat: '44.8662656888095',
    NWlong: '-73.0672874034535',
  },
  {
    Site: 'BHX',
    SElat: '37.8929678067294',
    SElong: '-127.124055924849',
    NWlat: '43.0955740079013',
    NWlong: '-121.448485523571',
  },
  {
    Site: 'BIS',
    SElat: '43.8781306734952',
    SElong: '-103.904881758256',
    NWlat: '49.6533687106046',
    NWlong: '-97.6046220814094',
  },
  {
    Site: 'BLX',
    SElat: '43.0091305680708',
    SElong: '-111.698701255105',
    NWlat: '48.6885434098677',
    NWlong: '-105.502978154963',
  },
  {
    Site: 'BMX',
    SElat: '30.804239321622',
    SElong: '-89.3440241865678',
    NWlat: '35.5311680325595',
    NWlong: '-84.1873746837269',
  },
  {
    Site: 'BOX',
    SElat: '39.2913217579235',
    SElong: '-74.0348118036443',
    NWlat: '44.6110086475719',
    NWlong: '-68.2315170149369',
  },
  {
    Site: 'BRO',
    SElat: '23.7123703002929',
    SElong: '-99.8145965576172',
    NWlat: '28.1116317749023',
    NWlong: '-95.0154022216797',
  },
  {
    Site: 'BUF',
    SElat: '40.2417946555398',
    SElong: '-81.6800426829945',
    NWlat: '45.646381447532',
    NWlong: '-75.784129819003',
  },
  {
    Site: 'BYX',
    SElat: '22.417155900435',
    SElong: '-84.0727428366921',
    NWlat: '26.7689320234819',
    NWlong: '-79.3253507024591',
  },
  {
    Site: 'CAE',
    SElat: '31.5598691766913',
    SElong: '-83.7162630948153',
    NWlat: '36.3294614618475',
    NWlong: '-78.5130715110085',
  },
  {
    Site: 'CBW',
    SElat: '43.1846313615279',
    SElong: '-70.9100258705833',
    NWlat: '48.8830108781294',
    NWlong: '-64.6936118524725',
  },
  {
    Site: 'CBX',
    SElat: '40.7596570379083',
    SElong: '-119.203284482089',
    NWlat: '46.2124310857599',
    NWlong: '-113.254803702615',
  },
  {
    Site: 'CCX',
    SElat: '38.3003434892134',
    SElong: '-80.8551256630637',
    NWlat: '43.5361375565962',
    NWlong: '-75.1433503168279',
  },
  {
    Site: 'CLE',
    SElat: '38.7706828724254',
    SElong: '-84.7324994867498',
    NWlat: '44.045722545277',
    NWlong: '-78.9779107527299',
  },
  {
    Site: 'CLX',
    SElat: '30.3010103815252',
    SElong: '-83.601054354581',
    NWlat: '35.0004427545721',
    NWlong: '-78.4744008567117',
  },
  {
    Site: 'CRP',
    SElat: '25.5437285128507',
    SElong: '-99.9464332719283',
    NWlat: '30.0161406222257',
    NWlong: '-95.0674382435192',
  },
  {
    Site: 'CXX',
    SElat: '41.7323609646884',
    SElong: '-76.1867004255815',
    NWlat: '47.2795563992587',
    NWlong: '-70.1352144969594',
  },
  {
    Site: 'CYS',
    SElat: '38.5202175209739',
    SElong: '-107.667048686634',
    NWlat: '43.7742305824973',
    NWlong: '-101.935398074063',
  },
  {
    Site: 'DAX',
    SElat: '35.9687395130504',
    SElong: '-124.429858037775',
    NWlat: '41.024067882191',
    NWlong: '-118.914954362349',
  },
  {
    Site: 'DDC',
    SElat: '35.2542548717152',
    SElong: '-102.69312261408',
    NWlat: '40.2586494029652',
    NWlong: '-97.2337831254436',
  },
  {
    Site: 'DFX',
    SElat: '27.0008793432063',
    SElong: '-102.75005450162',
    NWlat: '31.5368748266047',
    NWlong: '-97.8016957924581',
  },
  {
    Site: 'DGX',
    SElat: '29.9357861189409',
    SElong: '-92.5324283530495',
    NWlat: '34.6157025007768',
    NWlong: '-87.4270650274103',
  },
  {
    Site: 'DIX',
    SElat: '37.3620918412642',
    SElong: '-77.2210923073508',
    NWlat: '42.5225227494673',
    NWlong: '-71.5915313165838',
  },
  {
    Site: 'DLH',
    SElat: '43.9405937333541',
    SElong: '-95.3587258217551',
    NWlat: '49.7228965897994',
    NWlong: '-89.0507590692693',
  },
  {
    Site: 'DMX',
    SElat: '39.0756595403498',
    SElong: '-96.609657162753',
    NWlat: '44.3767001897639',
    NWlong: '-90.8267037270286',
  },
  {
    Site: 'DOX',
    SElat: '36.2822371604226',
    SElong: '-78.2053637279164',
    NWlat: '41.3605300071023',
    NWlong: '-72.6654078951749',
  },
  {
    Site: 'DTX',
    SElat: '40.0036628306996',
    SElong: '-86.4032277471369',
    NWlat: '45.3865516246449',
    NWlong: '-80.5309854264693',
  },
  {
    Site: 'DVN',
    SElat: '38.9615593927557',
    SElong: '-93.4623327359286',
    NWlat: '44.2528191583807',
    NWlong: '-87.6900493552468',
  },
  {
    Site: 'DYX',
    SElat: '30.1870823946866',
    SElong: '-101.809712080522',
    NWlat: '34.8803807345304',
    NWlong: '-96.6897502552379',
  },
  {
    Site: 'EAX',
    SElat: '36.2668039217862',
    SElong: '-97.0287463517623',
    NWlat: '41.3439676180753',
    NWlong: '-91.490022319447',
  },
  {
    Site: 'EMX',
    SElat: '29.559653895985',
    SElong: '-113.167697393244',
    NWlat: '34.2198711811412',
    NWlong: '-108.083823991255',
  },
  {
    Site: 'ENX',
    SElat: '39.8945905095881',
    SElong: '-76.9898712435636',
    NWlat: '45.2676373845881',
    NWlong: '-71.1283655617454',
  },
  {
    Site: 'EOX',
    SElat: '29.1365302484686',
    SElong: '-87.9848759183017',
    NWlat: '33.7750342767889',
    NWlong: '-82.9246897055886',
  },
  {
    Site: 'EPZ',
    SElat: '29.5391890161687',
    SElong: '-109.235116549405',
    NWlat: '34.1983381860906',
    NWlong: '-104.152408364036',
  },
  {
    Site: 'ESX',
    SElat: '33.2605511890758',
    SElong: '-117.544045965021',
    NWlat: '38.1325909839977',
    NWlong: '-112.22909346147',
  },
  {
    Site: 'EVX',
    SElat: '28.2622525024414',
    SElong: '-88.4232594299316',
    NWlat: '32.8573910522461',
    NWlong: '-83.4103810119629',
  },
  {
    Site: 'EWX',
    SElat: '27.42220272411',
    SElong: '-100.508574926203',
    NWlat: '31.9775158344616',
    NWlong: '-95.5391424421831',
  },
  {
    Site: 'EYX',
    SElat: '32.6757109485973',
    SElong: '-120.193302251642',
    NWlat: '37.5114958607067',
    NWlong: '-114.917900529341',
  },
  {
    Site: 'FCX',
    SElat: '34.5417482133345',
    SElong: '-82.9724916908958',
    NWlat: '39.4972383256392',
    NWlong: '-77.5665024774725',
  },
  {
    Site: 'FDR',
    SElat: '31.9611655356668',
    SElong: '-101.585978962291',
    NWlat: '36.7541190268777',
    NWlong: '-96.3573024264245',
  },
  {
    Site: 'FDX',
    SElat: '32.2262994246049',
    SElong: '-106.247528908469',
    NWlat: '37.0349542097612',
    NWlong: '-101.001723688299',
  },
  {
    Site: 'FFC',
    SElat: '30.9900629078258',
    SElong: '-87.1456546991522',
    NWlat: '35.7273218189586',
    NWlong: '-81.9777358870073',
  },
  {
    Site: 'FSD',
    SElat: '40.852262281938',
    SElong: '-99.7030573342063',
    NWlat: '46.3138101335005',
    NWlong: '-93.7450051325018',
  },
  {
    Site: 'FSX',
    SElat: '32.16706800981',
    SElong: '-113.81361029885',
    NWlat: '36.9721980146928',
    NWlong: '-108.571650293523',
  },
  {
    Site: 'FTG',
    SElat: '37.2071460099654',
    SElong: '-107.348506552956',
    NWlat: '42.355491957231',
    NWlong: '-101.732129155939',
  },
  {
    Site: 'FWS',
    SElat: '30.2211688648571',
    SElong: '-99.8597127741033',
    NWlat: '34.9162982593883',
    NWlong: '-94.7377534346147',
  },
  {
    Site: 'GGW',
    SElat: '45.23313762318',
    SElong: '-109.85584325617',
    NWlat: '51.1680741466175',
    NWlong: '-103.381367048784',
  },
  {
    Site: 'GJX',
    SElat: '36.5097608392889',
    SElong: '-110.98757351962',
    NWlat: '41.6049756830389',
    NWlong: '-105.429157326438',
  },
  {
    Site: 'GLD',
    SElat: '36.802701887651',
    SElong: '-104.486596305154',
    NWlat: '41.9199961991744',
    NWlong: '-98.9040934198557',
  },
  {
    Site: 'GRB',
    SElat: '41.719979580966',
    SElong: '-91.1310270829634',
    NWlat: '47.2659390536222',
    NWlong: '-85.0808894764293',
  },
  {
    Site: 'GRK',
    SElat: '28.4164929615368',
    SElong: '-99.8893531244451',
    NWlat: '33.0191388355602',
    NWlong: '-94.8682848982377',
  },
  {
    Site: 'GRR',
    SElat: '40.1892096224699',
    SElong: '-88.4854156632857',
    NWlat: '45.5889746371183',
    NWlong: '-82.5947629200329',
  },
  {
    Site: 'GSP',
    SElat: '32.4670623501865',
    SElong: '-84.8464010481401',
    NWlat: '37.2901672085849',
    NWlong: '-79.5848321117054',
  },
  {
    Site: 'GUA',
    SElat: '11.414938434254',
    SElong: '142.595398615057',
    NWlat: '15.4836640201915',
    NWlong: '147.034008345171',
  },
  {
    Site: 'GWX',
    SElat: '31.5093194441362',
    SElong: '-90.9246834494851',
    NWlat: '36.2760125593706',
    NWlong: '-85.7246545965021',
  },
  {
    Site: 'GYX',
    SElat: '41.1414026295055',
    SElong: '-73.246129247492',
    NWlat: '46.6306146656383',
    NWlong: '-67.2578979353471',
  },
  {
    Site: 'HDX',
    SElat: '30.7108220741965',
    SElong: '-108.693220714222',
    NWlat: '35.4325933144309',
    NWlong: '-103.542197543057',
  },
  {
    Site: 'HGX',
    SElat: '27.1954380104758',
    SElong: '-97.5538856922497',
    NWlat: '31.7402988503196',
    NWlong: '-92.5958556851474',
  },
  {
    Site: 'HKI',
    SElat: '19.7608134737882',
    SElong: '-161.877270979448',
    NWlat: '24.0254313937101',
    NWlong: '-157.224960521351',
  },
  {
    Site: 'HKM',
    SElat: '18.0179601703991',
    SElong: '-158.075947210138',
    NWlat: '22.2323766743053',
    NWlong: '-153.478401933149',
  },
  {
    Site: 'HMO',
    SElat: '19.0109576762806',
    SElong: '-159.493162286932',
    NWlat: '23.2533282817494',
    NWlong: '-154.865121626421',
  },
  {
    Site: 'HNX',
    SElat: '33.8545184187456',
    SElong: '-122.304733498313',
    NWlat: '38.7645525984331',
    NWlong: '-116.948332575018',
  },
  {
    Site: 'HOU',
    SElat: '28.7501673934936',
    SElong: '-96.1970997972814',
    NWlat: '30.2817443415731',
    NWlong: '-94.2869002027185',
  },
  {
    Site: 'HPX',
    SElat: '34.2640513194691',
    SElong: '-89.973381097967',
    NWlat: '39.2009714854847',
    NWlong: '-84.5876500077681',
  },
  {
    Site: 'HTX',
    SElat: '32.5136486816406',
    SElong: '-88.7119366455078',
    NWlat: '37.3395764160156',
    NWlong: '-83.4472882080078',
  },
  {
    Site: 'HWA',
    SElat: '17.0004427545721',
    SElong: '-157.852290316495',
    NWlat: '21.1879427545721',
    NWlong: '-153.284108498313',
  },
  {
    Site: 'ICT',
    SElat: '35.1508602350408',
    SElong: '-100.164199191007',
    NWlat: '40.1480526178533',
    NWlong: '-94.7127165915752',
  },
  {
    Site: 'ICX',
    SElat: '35.0899853376909',
    SElong: '-115.57988852761',
    NWlat: '40.082935777144',
    NWlong: '-110.133033502752',
  },
  {
    Site: 'ILN',
    SElat: '36.854719321511',
    SElong: '-86.6107500110973',
    NWlat: '41.9759656594016',
    NWlong: '-81.0239358243075',
  },
  {
    Site: 'ILX',
    SElat: '37.5583515930176',
    SElong: '-92.1555043029785',
    NWlat: '42.734239654541',
    NWlong: '-86.5090809631347',
  },
  {
    Site: 'IND',
    SElat: '37.1320589793812',
    SElong: '-89.0803413945978',
    NWlat: '42.274591328014',
    NWlong: '-83.4703061051802',
  },
  {
    Site: 'INX',
    SElat: '33.7198827847568',
    SElong: '-98.2329952864213',
    NWlat: '38.6212041958896',
    NWlong: '-92.8860992015491',
  },
  {
    Site: 'IWA',
    SElat: '30.9180755892667',
    SElong: '-114.246466230913',
    NWlat: '35.6513214388761',
    NWlong: '-109.082925304066',
  },
  {
    Site: 'IWX',
    SElat: '38.7188794500178',
    SElong: '-88.5701111117276',
    NWlat: '43.9895398504084',
    NWlong: '-82.8202997658469',
  },
  {
    Site: 'JAX',
    SElat: '28.1841481087424',
    SElong: '-84.2022046314586',
    NWlat: '32.7755024788596',
    NWlong: '-79.1934544095126',
  },
  {
    Site: 'JGX',
    SElat: '30.3204910971902',
    SElong: '-85.9106174815785',
    NWlat: '35.0209610678933',
    NWlong: '-80.7828320589933',
  },
  {
    Site: 'JKL',
    SElat: '35.0899853376909',
    SElong: '-86.031892006614',
    NWlat: '40.082935777144',
    NWlong: '-80.585036981756',
  },
  {
    Site: 'JUA',
    SElat: '16.0324851781672',
    SElong: '-68.3461954775724',
    NWlat: '20.1959449559992',
    NWlong: '-63.8042393563011',
  },
  {
    Site: 'LBB',
    SElat: '31.273076060902',
    SElong: '-104.402338811701',
    NWlat: '36.0262804066051',
    NWlong: '-99.2170249800249',
  },
  {
    Site: 'LCH',
    SElat: '27.8335411626642',
    SElong: '-95.7070811739835',
    NWlat: '32.4081413823908',
    NWlong: '-90.7166082070091',
  },
  {
    Site: 'LIX',
    SElat: '28.0406035059149',
    SElong: '-92.3214423855868',
    NWlat: '32.6250609033758',
    NWlong: '-87.3202161338112',
  },
  {
    Site: 'LNX',
    SElat: '39.293236583363',
    SElong: '-103.472899114435',
    NWlat: '44.6130913196911',
    NWlong: '-97.6694212202589',
  },
  {
    Site: 'LOT',
    SElat: '38.9538886330345',
    SElong: '-90.9659732471813',
    NWlat: '44.2444922707298',
    NWlong: '-85.1944056424228',
  },
  {
    Site: 'LRX',
    SElat: '38.1245677185059',
    SElong: '-119.645277709961',
    NWlat: '43.3459422302246',
    NWlong: '-113.949232788086',
  },
  {
    Site: 'LSX',
    SElat: '36.1597554293545',
    SElong: '-93.4434492631392',
    NWlat: '41.2290303316983',
    NWlong: '-87.9133311878551',
  },
  {
    Site: 'LTX',
    SElat: '31.5987427173962',
    SElong: '-81.0274820917303',
    NWlat: '36.3705780445446',
    NWlong: '-75.8218435530229',
  },
  {
    Site: 'LVX',
    SElat: '35.4609677262739',
    SElong: '-88.6770391152122',
    NWlat: '40.4799038835005',
    NWlong: '-83.2018360346014',
  },
  {
    Site: 'LWX',
    SElat: '36.4258927778764',
    SElong: '-80.2491661765359',
    NWlat: '41.5148515181108',
    NWlong: '-74.6975748235529',
  },
  {
    Site: 'LZK',
    SElat: '32.421437253085',
    SElong: '-94.886905226274',
    NWlat: '37.2417955294522',
    NWlong: '-89.6283325611462',
  },
  {
    Site: 'MAF',
    SElat: '29.607417380593',
    SElong: '-104.728049205433',
    NWlat: '34.2701065895774',
    NWlong: '-99.6414791592682',
  },
  {
    Site: 'MAX',
    SElat: '39.4110861206054',
    SElong: '-125.618506469727',
    NWlat: '44.7412252807617',
    NWlong: '-119.803809204102',
  },
  {
    Site: 'MBX',
    SElat: '45.4092462435635',
    SElong: '-104.107681593461',
    NWlat: '51.3659265414151',
    NWlong: '-97.6094849048956',
  },
  {
    Site: 'MHX',
    SElat: '32.3631976457076',
    SElong: '-79.4989925453879',
    NWlat: '37.1800464005904',
    NWlong: '-74.2442484491521',
  },
  {
    Site: 'MKX',
    SElat: '40.2599587319114',
    SElong: '-91.4949513660778',
    NWlat: '45.6662087319114',
    NWlong: '-85.5972240933505',
  },
  {
    Site: 'MLB',
    SElat: '25.8658959059282',
    SElong: '-83.0968593528054',
    NWlat: '30.3519493727251',
    NWlong: '-78.2029828435724',
  },
  {
    Site: 'MOB',
    SElat: '28.3745178916237',
    SElong: '-90.7452343333851',
    NWlat: '32.9751190879128',
    NWlong: '-85.7263966647061',
  },
  {
    Site: 'MPX',
    SElat: '42.054124103893',
    SElong: '-96.6033510381525',
    NWlat: '47.633729206432',
    NWlong: '-90.5165091081099',
  },
  {
    Site: 'MQT',
    SElat: '43.6509238225764',
    SElong: '-90.6789667025479',
    NWlat: '49.4006186467951',
    NWlong: '-84.4065723488547',
  },
  {
    Site: 'MRX',
    SElat: '33.7131044699929',
    SElong: '-86.0707517589222',
    NWlat: '38.6139833762429',
    NWlong: '-80.7243384066495',
  },
  {
    Site: 'MSX',
    SElat: '44.1335477794301',
    SElong: '-117.14573400324',
    NWlat: '49.9378995860707',
    NWlong: '-110.813713850541',
  },
  {
    Site: 'MTX',
    SElat: '38.626753914573',
    SElong: '-115.312900043834',
    NWlat: '43.8896781089089',
    NWlong: '-109.571528195468',
  },
  {
    Site: 'MUX',
    SElat: '34.6684608598189',
    SElong: '-124.600154196999',
    NWlat: '39.6325111527876',
    NWlong: '-119.184826604669',
  },
  {
    Site: 'MVX',
    SElat: '44.5937041404031',
    SElong: '-100.514911915172',
    NWlat: '50.4516448142312',
    NWlong: '-94.1244311800868',
  },
  {
    Site: 'MXX',
    SElat: '30.1861058738015',
    SElong: '-88.3456902660023',
    NWlat: '34.8793584372781',
    NWlong: '-83.2257783785733',
  },
  {
    Site: 'NKX',
    SElat: '30.5580243752219',
    SElong: '-119.60764942516',
    NWlat: '35.2714032814719',
    NWlong: '-114.465781527433',
  },
  {
    Site: 'NQA',
    SElat: '32.9153368447044',
    SElong: '-92.5143240772594',
    NWlat: '37.7658464882591',
    NWlong: '-87.2228590115634',
  },
  {
    Site: 'OAX',
    SElat: '38.6814524980025',
    SElong: '-99.234399802468',
    NWlat: '43.9489695878462',
    NWlong: '-93.4880175226385',
  },
  {
    Site: 'OHX',
    SElat: '33.789630432129',
    SElong: '-89.2344470214844',
    NWlat: '38.6954531860352',
    NWlong: '-83.8826403808594',
  },
  {
    Site: 'OKX',
    SElat: '38.2455990461869',
    SElong: '-75.7126750876687',
    NWlat: '43.4768917707963',
    NWlong: '-70.0058102971857',
  },
  {
    Site: 'OTX',
    SElat: '44.7381206304377',
    SElong: '-120.825245541659',
    NWlat: '50.613196924383',
    NWlong: '-114.41607140281',
  },
  {
    Site: 'PAH',
    SElat: '34.5843138122559',
    SElong: '-91.472054901123',
    NWlat: '39.5426725769043',
    NWlong: '-86.0629362487793',
  },
  {
    Site: 'PBZ',
    SElat: '37.9237272782759',
    SElong: '-83.0524039528587',
    NWlat: '43.1288054032759',
    NWlong: '-77.3741369074042',
  },
  {
    Site: 'PDT',
    SElat: '42.8544176691229',
    SElong: '-121.935688753995',
    NWlat: '48.517289983576',
    NWlong: '-115.758009865501',
  },
  {
    Site: 'POE',
    SElat: '28.8390375380083',
    SElong: '-95.4937146689675',
    NWlat: '33.4625574354692',
    NWlong: '-90.4498747808283',
  },
  {
    Site: 'PUX',
    SElat: '35.9282148465243',
    SElong: '-106.932252183394',
    NWlat: '40.9805982693759',
    NWlong: '-101.420561176647',
  },
  {
    Site: 'RAX',
    SElat: '33.2256448780407',
    SElong: '-81.1418567865545',
    NWlat: '38.0955026661266',
    NWlong: '-75.8292846540971',
  },
  {
    Site: 'RGX',
    SElat: '37.1763406788219',
    SElong: '-122.263210698908',
    NWlat: '42.3223062549938',
    NWlong: '-116.649430070357',
  },
  {
    Site: 'RIW',
    SElat: '40.3536436739835',
    SElong: '-111.425640883012',
    NWlat: '45.7685148898038',
    NWlong: '-105.518508647572',
  },
  {
    Site: 'RLX',
    SElat: '35.7853828846325',
    SElong: '-84.4686350458318',
    NWlat: '40.8274513660778',
    NWlong: '-78.9681967024369',
  },
  {
    Site: 'RTX',
    SElat: '42.8772006503018',
    SElong: '-126.049009413286',
    NWlat: '48.5424991122159',
    NWlong: '-119.868683818471',
  },
  {
    Site: 'SFX',
    SElat: '40.391867537065',
    SElong: '-115.635568778298',
    NWlat: '45.8102787919478',
    NWlong: '-109.724574682062',
  },
  {
    Site: 'SGF',
    SElat: '34.7458257640492',
    SElong: '-96.106019190008',
    NWlat: '39.7151403392445',
    NWlong: '-90.6849487443404',
  },
  {
    Site: 'SHV',
    SElat: '30.1023544588956',
    SElong: '-96.3942498918013',
    NWlat: '34.7911209383878',
    NWlong: '-91.2792319141735',
  },
  {
    Site: 'SJT',
    SElat: '29.0497327145663',
    SElong: '-103.015480027632',
    NWlat: '33.6838422116366',
    NWlong: '-97.9600878490098',
  },
  {
    Site: 'SOX',
    SElat: '31.4325297823819',
    SElong: '-120.229281324907',
    NWlat: '36.1948131075772',
    NWlong: '-115.034063151967',
  },
  {
    Site: 'SRX',
    SElat: '32.8629563626376',
    SElong: '-97.0015609602495',
    NWlat: '37.710231142911',
    NWlong: '-91.7136248363148',
  },
  {
    Site: 'TBW',
    SElat: '25.4663484122537',
    SElong: '-84.8356705821644',
    NWlat: '29.9355256583474',
    NWlong: '-79.9602044955167',
  },
  {
    Site: 'TFX',
    SElat: '44.5294944069603',
    SElong: '-114.569796336781',
    NWlat: '50.3798667214134',
    NWlong: '-108.187571993741',
  },
  {
    Site: 'TLH',
    SElat: '28.100175039118',
    SElong: '-86.827001620206',
    NWlat: '32.6874858301336',
    NWlong: '-81.8226625754617',
  },
  {
    Site: 'TLX',
    SElat: '32.9036950406161',
    SElong: '-99.9189323286577',
    NWlat: '37.7534875210849',
    NWlong: '-94.6282496226918',
  },
  {
    Site: 'TWX',
    SElat: '36.4471086952903',
    SElong: '-99.0040274464',
    NWlat: '41.5376390907981',
    NWlong: '-93.4507215603915',
  },
  {
    Site: 'TYX',
    SElat: '41.0126029968262',
    SElong: '-78.6623870849609',
    NWlat: '46.4894401550293',
    NWlong: '-72.6876556396484',
  },
  {
    Site: 'UDX',
    SElat: '41.3645503512295',
    SElong: '-105.829926771684',
    NWlat: '46.8754298678311',
    NWlong: '-99.8180582081186',
  },
  {
    Site: 'UEX',
    SElat: '37.7218453424627',
    SElong: '-101.267578707608',
    NWlat: '42.9107186334783',
    NWlong: '-95.6069896628637',
  },
  {
    Site: 'VAX',
    SElat: '28.5804642278498',
    SElong: '-85.5127276888761',
    NWlat: '33.1911514837092',
    NWlong: '-80.4828870461204',
  },
  {
    Site: 'VBX',
    SElat: '32.4233788230202',
    SElong: '-123.020974384655',
    NWlat: '37.2438591696999',
    NWlong: '-117.762268551914',
  },
  {
    Site: 'VNX',
    SElat: '34.2679230013761',
    SElong: '-100.81651704268',
    NWlat: '39.2051025668058',
    NWlong: '-95.4305029713022',
  },
  {
    Site: 'VTX',
    SElat: '32.0097278386896',
    SElong: '-121.790544003573',
    NWlat: '36.8055499822443',
    NWlong: '-116.558738028786',
  },
  {
    Site: 'VWX',
    SElat: '35.7361459766734',
    SElong: '-90.4677150934393',
    NWlat: '40.7746896778453',
    NWlong: '-84.9711219648882',
  },
  {
    Site: 'YUX',
    SElat: '30.1452066872336',
    SElong: '-117.210491069447',
    NWlat: '34.8362619850852',
    NWlong: '-112.092976199063',
  },
];
