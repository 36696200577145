/**
 *
 */
class PointOfInterestTable extends AbstractDatabaseTable {
  constructor() {
    super('POI', 'PrimaryKey');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('POI_Index.mbtiles');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey" int primary key,
            "Name"       varchar(64)  not null,
            "Type"       varchar(64)  not null,
            "StateProv"  varchar(64)  not null,
            "Country"    varchar(128) not null,
            "District"   varchar(128) not null,
            "Lat"        double not null,
            "Long"       double not null,
            "Elev"       int(6) not null
        )
    `;
    //#endregion
  }
}
