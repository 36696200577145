/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var CheckLists = [];
var Questions = [];
var Items = {};
var TDG_Item = {};
Questions.push({Q:'The Shipper is Trained',L:2, A:null});
Questions.push({Q:'The package is marked "Air Transport, 12.8, Consumer Commodity" or the same in French',L:2,A:null});
Questions.push({Q:'For liquid dangerous goods the package bears two orientation labels one on each opposite side',L:2,A:null});
Questions.push({Q:'The dangerous goods are limited to: a) Class 2.1, Flammable Gas in a quantity ≤ 500mL or Class 2.2, Compressed Gas in a quantity ≤ 1 L each',L:3,A:null});
Questions.push({Q:'The dangerous goods are limited to: b) UN 3175, Solids Containing Flammable Liquid, N.O.S.  (i.e.: medical cleaning wipes in packages)',L:3,A:null});
Questions.push({Q:'The dangerous goods are limited to: c) *1 L of Class 3 Flammable Liquid, Packing Groups (PG) II packed in a metal inner or 500 mL if inner is glass earthenware or plastic',L:3,A:null});
Questions.push({Q:'The dangerous goods are limited to: d) 5 L of a Class 3, PG III or Class 6.1 Toxic in PG III only packed in a metal inner or 500 mL if inner is glass earthenware or plastic',L:3,A:null});
Questions.push({Q:'Solid dangerous goods and the quantity is ≤5 kg in an inner package',L:3,A:null});
Questions.push({Q:'The gross Mass of the completed package is ≤ 30 kg',L:2,A:null});
Questions.push({Q:'The outer package must be of sufficient quality to safely transport Dangerous goods.',L:2,A:null});
Questions.push({Q:'Inner packages must be closed so as not to break or leak during transport. ',L:2,A:null});
TDG_Item = {};
TDG_Item.UN = 8000;
TDG_Item.Description = "Air Transport, 12.8, Consumer Commodity";
TDG_Item.Forbidden = 0;
TDG_Item.Class_Div = "";
TDG_Item.T = "";
TDG_Item.P_Group = "";
TDG_Item.Labels = "Air Transport, 12.8, Consumer Commodity";
TDG_Item.EQ_IP = "";
TDG_Item.EQ_Max = "";
TDG_Item.LQ_PI = "";
TDG_Item.LQ_Max = "";
TDG_Item.PA_PI = "";
TDG_Item.PA_Max = "";
TDG_Item.CA_PI = "";
TDG_Item.CA_Max = "";
TDG_Item.Exemption = "";
TDG_Item.ERG_Num = "";
TDG_Item.Drill_Code = "";
TDG_Item.TCtype = tdgType;
TDG_Item.Checklist = null;
TDG_Item.CheckOK = "true";
TDG_Item.PostOp = "false";
TDG_Item.Exemption = "(12.8)";
Items = {Name:"Consumer Commodities",Questions:Questions, Max:30, Units:"kg",TDG_Item:TDG_Item, CL:"CC"};

CheckLists.push(Items);
Questions = [];

Questions.push({Q:'<p>Peace officer or In-Flight Security Officer TDGR’s Sec. 12.4 (2)<b>(Questions 3-12 N/A)</b></p>Class 1.4S carried by Peace officer or In-Flight Security Officer as per TDGR Sec.12.4(2)',L:3,A:null});
Questions.push({Q:'Class 1.4S, 5 kg gross mass or less per person per TDGR Sec. 12.4(2) & ICAO Part 8: 1.1.2 (n)',L:2,A:null});
Questions.push({Q:'The Shipper is Trained',L:3,A:null});
Questions.push({Q:'If required, has the Shipper declared the Class 1.4S dangerous goods? TDGR Sec. 12.4(1) (b)',L:3,A:null});
Questions.push({Q:'The explosives are one or more of those listed in TDGR Sec. 12.4(1) (c)',L:3,A:null});
Questions.push({Q:'The calibre of cartridges UN 0012 and UN 0014 meet size listed in TDGR Sec. 12.4 (1)d()',L:3,A:null});
Questions.push({Q:'The gross mass (the entire finished package) is 25 kg or less TDGR Sec. 12.4 (1) (e)',L:3,A:null});
Questions.push({Q:'The explosives are packed in good packaging TDGR Sec. 12.4 (1) (f)',L:3,A:null});
Questions.push({Q:'The explosives are packed properly TDGR Sec. 12.4 (1) (g)',L:3,A:null});
Questions.push({Q:'The outer packaging is marked “Explosives – Excepted (or French) as per TDGR Sec. 12.4 (1)(h)',L:3,A:null});
Questions.push({Q:'The Notification to Captain is provided ICAO TI’s Part 7:4.1.4',L:3,A:null});
Questions.push({Q:'Shipper & Consignee names and addresses are marked on package ICAO TI’s Part 5:2.4.2',L:3,A:null});

Items = {Name:"Explosives, Class 1.4S",Questions:Questions, Max:25, Units:"kg", TDG_Item:null, CL:"1.4S"};
CheckLists.push(Items);

Questions = [];

Questions.push({H:'<p><b>Documentation</b></p>', Q:'The UN Number “1845”, preceded by the prefix “UN”',L:2,A:null});
Questions.push({H:'<p><b>Documentation</b></p>', Q:'The words “Carbon dioxide, solid” or “Dry ice”',L:2,A:null});
Questions.push({H:'<p><b>Documentation</b></p>', Q:'The number of packages of dry ice (unless these are the only packages within the consignment)',L:2,A:null});
Questions.push({H:'<p><b>Documentation</b></p>', Q:'The net quantity of dry ice in kilograms',L:2,A:null});
Questions.push({H:'<p><b>State and Operator Variations</b></p>', Q:'State and operator variations complied with [2.8]',L:3,A:null});
Questions.push({H: '<p><b>Quantity</b></p>', Q:'The quantity of dry ice per package is 200 kg or less [4.2]',L:2,A:null});
Questions.push({H:'<p><b>Packages and Overpacks</b></p>', Q:'Same number of packages as shown on the Air Waybill',L:2,A:null});
Questions.push({H:'<p><b>Packages and Overpacks</b></p>', Q:'Packages are free from damage and leakage',L:2,A:null});
Questions.push({H:'<p><b>Packages and Overpacks</b></p>', Q:'The packaging conforms with Packing Instruction 954 and the package is vented to permit the release of gas',L:2,A:null});
Questions.push({H:'<p><b>Marks and Labels</b></p>', Q:'The UN number “1845” preceded by prefix “UN” [7.1.4.1(a)]',L:2,A:null});
Questions.push({H:'<p><b>Marks and Labels</b></p>', Q:'The words “Carbon dioxide, solid” or “Dry ice” [7.1.4.1(a)]',L:2,A:null});
Questions.push({H:'<p><b>Marks and Labels</b></p>', Q:'Full name and address of the shipper and consignee [7.1.4.1(b)]',L:2,A:null});
Questions.push({H:'<p><b>Marks and Labels</b></p>', Q:'The net weight of dry ice within each package [7.1.4.1(d)]',L:2,A:null});
Questions.push({H:'<p><b>Marks and Labels</b></p>', Q:'Class 9 label properly affixed [7.2.3.9].',L:2,A:null});
Questions.push({H:'<p><b>Marks and Labels</b></p>', Q:'Irrelevant marks and labels removed or obliterated [7.1.1(b); 7.2.1(a)].',L:3,A:null});
Questions.push({H:'<p><b>For Overpacks</b></p>', Q:'Packaging Use marks and hazard and handling labels, as required must be clearly visible or reproduced on the outside of the overpack [7.1.7.1, 7.2.7]',L:3,A:null});
Questions.push({H:'<p><b>For Overpacks</b></p>', Q:'The word “Overpack” marked if markings and labels are not visible on packages within the overpack[7.1.7.1]',L:3,A:null});
Questions.push({H:'<p><b>For Overpacks</b></p>', Q:'The total net weight of carbon dioxide, solid (dry ice) in the overpack [7.1.7.1]',L:3,A:null});



Items = {Name:"Dry Ice",Questions:Questions, Max:200, Units:"kg", TDG_Item:null, CL:"DI"};
CheckLists.push(Items);
Questions = [];

Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'Two copies in English and in the IATA format including the air certification statement [8.0.2.1, 8.1.1, 8.1.2, 8.1.6.12]',L:3,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'Full name and address of Shipper and Consignee [8.1.6.1, 8.1.6.2]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'If the Air Waybill number is not shown, enter it. [8.1.6.3]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'The number of pages shown. This question may be indicated as not applicable “N/A” only when the Shippers Declaration data is submitted electronically [8.1.6.4]',L:3,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'The non-applicable Aircraft Type deleted or not shown [8.1.6.5]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'If full name of Airport or City of Departure or Destination is not shown, enter it. [8.1.6.6 and 8.1.6.7] Information is optional',L:3,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'The word “Radioactive” deleted or not shown [8.1.6.8]',L:2,A:null});

Questions.push({H:'<p><b>B - Identification</b></p>', Q:'UN or ID number(s), preceded by prefix [8.1.6.9.1, Step 1]',L:2,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Proper Shipping Name and the technical name in brackets for asterisked entries [8.1.6.9.1, Step 2]',L:2,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Class or Division and for Class 1, the Compatibility Group, [8.1.6.9.1, Step 3]',L:2,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Subsidiary Risk, in parentheses, immediately following Class or Division [8.1.6.9.1, Step 4]',L:3,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Packing Group [8.1.6.9.1, Step 5]',L:2,A:null});


Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Number and Type of Packages [8.1.6.9.2, Step 6]',L:2,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Quantity and unit of measure (net, or gross followed by “G”, as applicable) within per package limit [8.1.6.9.2, Step 6]',L:2,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'15. For Class 1, the net quantity supplemented with the net explosive mass followed by unit of measurement [8.1.6.9.2, Step 6]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'When different dangerous goods are packed in one outer packaging, the following rules are complied with: Compatible according to Table 9.3.A.',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'When different dangerous goods are packed in one outer packaging, the following rules are complied with: UN packages containing Division 6.2 [5.0.2.11(c)]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'When different dangerous goods are packed in one outer packaging, the following rules are complied with: “All packed in one (type of packaging)” [8.1.6.9.2, Step 6(f)]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'When different dangerous goods are packed in one outer packaging, the following rules are complied with: Calculation of “Q” value must not exceed 1 [5.0.2.11 (g) & (h); 2.7.5.6; 8.1.6.9.2, Step 6(g)]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Overpack<br />– Compatible according to Table 9.3.A. [5.0.1.5.1]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Overpack<br />– Wording “Overpack Used” [8.1.6.9.2, Step 7]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Overpack<br />– If more than one overpack is used, identification marks shown and total quantity of dangerous goods [8.1.6.9.2, Step 7]',L:3,A:null});

Questions.push({H:'<p><b>D - Packing Instructions</b></p>', Q:'Packing Instruction Number [8.1.6.9.3, Step 8]',L:2,A:null});
Questions.push({H:'<p><b>D - Packing Instructions</b></p>', Q:'For lithium batteries in compliance with Section IB, “IB” follows the packing instruction [8.1.6.9.3, Step 8]',L:3,A:null});

Questions.push({H:'<p><b>E - Authorizations</b></p>', Q:'Check all verifiable special provisions. The Special Provision Number A1, A2, A4, A5, A51, A81, A88, A99, A130, A190, A191, A201, A202, A211, A212, A331 if used [8.1.6.9 4, Step 9]',L:3,A:null});
Questions.push({H:'<p><b>E - Authorizations</b></p>', Q:'Indication that governmental authorization is attached, including a copy in English and additional approvals for other items under [8.1.6.9.4, Step 9]',L:3,A:null});

Questions.push({H:'<p><b>F - Additional Handling Information</b></p>', Q:'Additional handling information shown for self-reactive and related substances of Division 4.1 and organic peroxides of Division 5.2, or samples thereof, PBE, infectious and controlled substances, fireworks (UN0336 & UN0337) and viscous flammable liquids [8.1.6.11]',L:3,A:null});
Questions.push({H:'<p><b>F - Additional Handling Information</b></p>', Q:'Name of Signatory and Date indicated and Signature of Shipper [8.1.6.13, 8.1.6.14 and 8.1.6.15]',L:2,A:null});
Questions.push({H:'<p><b>F - Additional Handling Information</b></p>', Q:'Amendment or alteration signed by Shipper [8.1.2.6]',L:3,A:null});
Questions.push({H:'<p><b>F - Additional Handling Information</b></p>', Q:'Amendment or alteration signed by Shipper [8.1.2.6]',L:3,A:null});

Questions.push({H:'<p><b>G - Air Waybill - Handling Information</b></p>', Q:'The statement: “Dangerous goods as per attached Shippers Declaration” or “Dangerous Goods as per attached DGD” [8.2.1(a)]',L:2,A:null});
Questions.push({H:'<p><b>G - Air Waybill - Handling Information</b></p>', Q:'“Cargo Aircraft Only” or “CAO”, if applicable [8.2.1(b)]',L:3,A:null});
Questions.push({H:'<p><b>G - Air Waybill - Handling Information</b></p>', Q:'Where non-dangerous goods are included, the number of pieces of dangerous goods shown [8.2.2]',L:3,A:null});

Questions.push({H:'<p><b>H - Packages and Overpacks</b></p>', Q:'Packaging free from damage and leakage [9.1.3 (i)]',L:2,A:null});
Questions.push({H:'<p><b>H - Packages and Overpacks</b></p>', Q:'Packaging conforms with packing instruction',L:3,A:null});
Questions.push({H:'<p><b>H - Packages and Overpacks</b></p>', Q:'Same number and type of packagings and overpacks delivered as shown on DGD [9.1.3]',L:2,A:null});

Questions.push({H:'<p><b>I - Markings</b></p>', Q:'UN Specification Packaging, marked according to 6.0.4 and 6.0.5: Symbol and Specification Code',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'UN Specification Packaging, marked according to 6.0.4 and 6.0.5: X, Y or Z meets or exceeds Packing Group/Packing Instruction requirements',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'UN Specification Packaging, marked according to 6.0.4 and 6.0.5: Gross Weight within limits (Solids, Inner Packagings or IBCs [SP A179])',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'UN Specification Packaging, marked according to 6.0.4 and 6.0.5: Infectious substance package marking [6.5.3.1]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'UN or ID number(s), preceded by prefix [7.1.4.1(a)]',L:2,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'The Proper Shipping Name(s) including technical name where required [7.1.4.1(a)]',L:2,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'The full name(s) and Address(es) of Shipper and Consignee [7.1.4.1(b)]',L:2,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'For consignments of more than one package of all classes (except ID 8000 and Class 7) the net quantity, or gross weight followed by “G”, as applicable, unless contents are identical, marked on the packages [7.1.4.1(c)]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'Carbon Dioxide, Solid (Dry Ice), the net quantity marked on the packages [7.1.4.1(d)]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'The Name and Telephone Number of a responsible person for Division 6.2 Infectious Substances shipment [7.1.4.1(e)]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'The Special Marking requirements shown for Packing Instruction 202 [7.1.4.1(f)]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'Limited Quantities mark [7.1.4.2]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'The Environmentally Hazardous Substance Mark [7.1.5.3]',L:3,A:null});
Questions.push({H:'<p><b>I - Markings</b></p>', Q:'Lithium Battery mark [7.1.5.5]',L:3,A:null});

Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'The label(s) identifying the Primary hazard as per 4.2, Column D properly affixed [7.2.3.1; 7.2.6]',L:2,A:null});
Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'The label(s) identifying the Subsidiary risk, as per 4.2, Column D [7.2.3.2; 7.2.6.2.3]',L:3,A:null});
Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'Cargo Aircraft Only label [7.2.4.2; 7.2.6.3',L:3,A:null});
Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'“Orientation” labels on two opposite sides, if applicable [7.2.4.4]',L:3,A:null});
Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'“Cryogenic Liquid” labels, if applicable [7.2.4.3]',L:3,A:null});
Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'“Keep Away From Heat” label, if applicable [7.2.4.5]',L:3,A:null});
Questions.push({H:'<p><b>J - Labelling</b></p>', Q:'Any irrelevant marks and labels removed or obliterated [7.1.1; 7.2.1]',L:3,A:null});

Questions.push({H:'<p><b>K - Overpacks</b></p>', Q:'Packaging use marks and hazard and handling labels, as required must be clearly visible or reproduced on the outside of the overpack [7.1.7.1, 7.1.7.2, 7.2.7]',L:3,A:null});
Questions.push({H:'<p><b>K - Overpacks</b></p>', Q:'The word “Overpack" marked if marks and labels are not visible on packages within the overpack [7.1.7.1]',L:3,A:null});
Questions.push({H:'<p><b>K - Overpacks</b></p>', Q:'If more than one overpack is used, identification marks shown and total quantity of dangerous goods [7.1.7.3]',L:3,A:null});

Questions.push({H:'<p><b>L - General</b></p>', Q:'State and Operator variations complied with [2.8]',L:3,A:null});
Questions.push({H:'<p><b>L - General</b></p>', Q:'Cargo Aircraft Only shipments, a cargo aircraft operates on all sectors',L:3,A:null});


Items = {Name:"Acceptance Checklist - Non-Radioactive",Questions:Questions, Max:null, Units:null, TDG_Item:null, CL:"AC"};
CheckLists.push(Items);

Questions = [];


Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'Two copies in English and in the IATA format including the air certification statement. This question may be indicated as not applicable “N/A" only when the Shipper’s Declaration data is submitted electronically [10.8.1.2, 10.8.1.4; 8.1.1; 10.8.3.12.2]',L:3,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'Full name and address of Shipper and Consignee [10.8.3.1, 10.8.3.2]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'If the Air Waybill number is not shown, enter it. [10.8.3.3]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'The number of pages shown. This question may be indicated as not applicable “N/A" only when the Shippers Declaration data is submitted electronically [10.8.3.4]',L:3,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'The non-applicable Aircraft Type deleted or not shown [10.8.1.6.2, 10.8.3.5]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'If full name of Airport or City of Departure or Destination is not shown, enter it. [10.8.3.6 and 10.8.3.7]',L:2,A:null});
Questions.push({H:'<p><b>A - Prerequisites</b></p>', Q:'The word “Non-Radioactive" deleted or not shown [10.8.1.6.2, 10.8.3.8]',L:2,A:null});

Questions.push({H:'<p><b>B - Identification</b></p>', Q:'UN number, preceded by prefix “UN” [10.8.3.9.1, Step 1]',L:2,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Proper Shipping Name and where Special Provision A78 applies, the supplementary information in brackets [10.8.3.9.1, Step 2]',L:2,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Class 7 [10.8.3.9.1, Step 3]',L:2,A:null});
Questions.push({H:'<p><b>B - Identification</b></p>', Q:'Subsidiary hazard, in brackets, immediately following Class [10.8.3.9.1, Step 4] and Packing Group if required for Subsidiary hazard [10.8.3.9.1, Step 5]',L:3,A:null});

Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Name or Symbol of Radionuclide(s) [10.8.3.9.2, Step 6 (a)]',L:2,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'A description of the physical and chemical form if in other form [10.8.3.9.2, Step 6 (b)]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'“Special Form” (not required for UN 3332 or UN 3333) or low dispersible material [10.8.3.9.2,Step 6 (b)]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'The number and type of packages and the activity in becquerel or multiples thereof in each package.For Fissile Material the total weight in grams or kilograms of fissile material may be shown in place of activity [10.8.3.9.2, Step 7]',L:2,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'For different individual radionuclides, the activity of each radionuclide and the words “All packed in one” [10.8.3.9.2, Step 7]',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Activity within limits for Type A packages [Table 10.3.A], Type B, or Type C (see attached competent authority certificate)',L:3,A:null});
Questions.push({H:'<p><b>C - Quantity and Type of Packing</b></p>', Q:'Words “Overpack Used” shown on the DGD [10.8.3.9.2, Step 8]',L:3,A:null});

Questions.push({H:'<p><b>D - Packing Instructions</b></p>', Q:'Category of package(s) or overpack [10.8.3.9.3, Step 9 and Table 10.5.C]',L:2,A:null});
Questions.push({H:'<p><b>D - Packing Instructions</b></p>', Q:'Transport Index and dimensions (preferably in sequence Length x Width x Height) for Category II and Category III only [10.8.3.9.3, Step 9 (b) and (c)]',L:3,A:null});
Questions.push({H:'<p><b>D - Packing Instructions</b></p>', Q:'For Fissile Material the Criticality Safety Index (with, in addition and if applicable*, reference to paragraphs 10.6.2.8.1.3 (a) to (c) or 10.6.2.8.1.4), or the words ‘Fissile Excepted’ [10.8.3.9.3, Step 9(d)]',L:3,A:null});

Questions.push({H:'<p><b>E - Authorizations</b></p>', Q:'Identification marks shown and a copy of the document in English attached to DGD for the following [10.5.7.2.3; 10.8.3.9.4. Step 10; 10.8.7]: Special Form approval certificate',L:3,A:null});
Questions.push({H:'<p><b>E - Authorizations</b></p>', Q:'Identification marks shown and a copy of the document in English attached to DGD for the following [10.5.7.2.3; 10.8.3.9.4. Step 10; 10.8.7]: Type B package design approval certificate',L:3,A:null});
Questions.push({H:'<p><b>E - Authorizations</b></p>', Q:'Identification marks shown and a copy of the document in English attached to DGD for the following [10.5.7.2.3; 10.8.3.9.4. Step 10; 10.8.7]: Other approval certificates as required',L:3,A:null});

Questions.push({H:'<p><b>E - Additional Handling Information</b></p>', Q:'Additional Handling Information [10.8.3.11]',L:3,A:null});
Questions.push({H:'<p><b>E - Additional Handling Information</b></p>', Q:'Name and Title (or Department) of Signatory, Place and Date indicated [10.8.3.13 and 10.8.3.14] and Signature of Shipper [10.8.3.15]',L:2,A:null});
Questions.push({H:'<p><b>E - Additional Handling Information</b></p>', Q:'Amendment or alteration signed by Shipper [10.8.1.7]',L:3,A:null});

Questions.push({H:'<p><b>F - Air Waybill-Handling Information</b></p>', Q:'The statement: “Dangerous goods as per attached Shipper\'s Declaration” or “Dangerous Goods as per attached DGD” [10.8.8.1(a)]',L:2,A:null});
Questions.push({H:'<p><b>F - Air Waybill-Handling Information</b></p>', Q:'Cargo Aircraft Only or CAO, if applicable [10.8.8.1(b)]',L:3,A:null});
Questions.push({H:'<p><b>F - Air Waybill-Handling Information</b></p>', Q:'Where non-dangerous goods are included, the number of pieces of dangerous goods shown [10.8.8.2]',L:3,A:null});

Questions.push({H:'<p><b>G - Package(s) and Overpacks</b></p>', Q:'Same number and type of packagings and overpacks delivered as shown on DGD',L:2,A:null});
Questions.push({H:'<p><b>G - Package(s) and Overpacks</b></p>', Q:'Unbroken transportation seal [10.6.2.4.1.2] and package in proper condition forcarriage [9.1.3; 9.1.4]',L:3,A:null});

Questions.push({H:'<p><b>H - Marks</b></p>', Q:'UN number, preceded by prefix [10.7.1.3.1]',L:2,A:null});
Questions.push({H:'<p><b>H - Marks</b></p>', Q:'The Proper Shipping Name and where Special Provision A78 applies, the supplementary Information in brackets [10.7.1.3.1]',L:2,A:null});
Questions.push({H:'<p><b>H - Marks</b></p>', Q:'The full Name and Address of the Shipper and Consignee [10.7.1.3.1]',L:2,A:null});
Questions.push({H:'<p><b>H - Marks</b></p>', Q:'The permissible gross weight if the gross weight of the package exceeds 50 kg [10.7.1.3.1]',L:3,A:null});
Questions.push({H:'<p><b>H - Marks</b></p>', Q:'Type A packages, marked as per 10.7.1.3.4',L:3,A:null});
Questions.push({H:'<p><b>H - Marks</b></p>', Q:'Type B packages, marked as per 10.7.1.3.5',L:3,A:null});
Questions.push({H:'<p><b>H - Marks</b></p>', Q:'Type C packages, Industrial Packages and packages containing Fissile material marked as per 10.7.1.3.6, 10.7.1.3.3 or 10.7.1.3.7',L:3,A:null});

Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'Same category labels as per DGD affixed to two opposite sides of package. [10.7.4.3.1]',L:2,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'Symbol of radionuclide or LSA/SCO indicated as required. [10.7.3.3.1]',L:2,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'Activity in Bq (or multiples thereof). For Fissile material, the total mass in grams (may be used instead) [10.7.3.3.2]',L:2,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'For Category II & III, same TI as per DGD, rounded-up to one decimal place. [ 10.7.3.3.3]',L:2,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'Applicable label(s) identifying the Subsidiary [10.7.3.2; 10.7.4.3]',L:3,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'Two Cargo Aircraft Only labels, if required, on the same surface near the Hazard labels [10.7.4.2.4; 10.7.4.3.1; 10.7.4.4.1]',L:3,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'For fissile materials, two correctly completed Criticality Safety Index (CSI) labels on the same surface as the hazard labels [10.7.3.3.4; 10.7.4.3.1]',L:3,A:null});
Questions.push({H:'<p><b>I - Labelling</b></p>', Q:'Any irrelevant marks and labels removed or obliterated [10.7.1.1; 10.7.2.1]',L:3,A:null});

Questions.push({H:'<p><b>J - For Overpacks</b></p>', Q:'Package use marks and labels clearly visible or reproduced on the outside of the overpack [10.7.1.4.1; 10.7.4.4]',L:3,A:null});
Questions.push({H:'<p><b>J - For Overpacks</b></p>', Q:' The word “Overpack” marked if marks and labels are not visible on packages within the overpack [10.7.1.4.1]',L:3,A:null});
Questions.push({H:'<p><b>J - For Overpacks</b></p>', Q:'If more than one overpack is used, identification marks shown [10.7.1.4.3]',L:3,A:null});
Questions.push({H:'<p><b>J - For Overpacks</b></p>', Q:'Hazard labels reflect the content(s) and activity of each individual radionuclide and the TI of the overpack [10.7.3.4]',L:3,A:null});

Questions.push({H:'<p><b>K - General</b></p>', Q:'State and Operator variations complied with [2.8]',L:3,A:null});
Questions.push({H:'<p><b>K - General</b></p>', Q:'Cargo Aircraft Only shipments, a cargo aircraft operates on all sectors',L:3,A:null});
Questions.push({H:'<p><b>K - General</b></p>', Q:'Packages containing Carbon dioxide solid (dry ice), the marking, labelling and documentary requirements complied with [Packing Instruction 954; 7.1.4.1 (d); 7.2.3.9.1]',L:3,A:null});


Items = {Name:"Acceptance Checklist - Radioactive",Questions:Questions, Max:null, Units:null, TDG_Item:null, CL:"RA"};
CheckLists.push(Items);