/**
 *
 */
class BillingClientTable extends AbstractDatabaseTable {
  constructor() {
    super('BillingClient', 'clientPK');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            clientPK numeric primary key ,
            data     text
        )
    `;
    //#endregion
  }

  async insert(data) {
    for (let row of data) {
      row.data = this.encodeJson(row.data, '{}');
    }

    return super.insert(data);
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    if (rows.length <= 0) {
      return false;
    }

    for (let i = 0; i < rows.length; i++) {
      rows[i].data = this.parseJson(rows[i].data, {});
    }

    return rows;
  }
}
