/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
function AJAXFAIL(error) {
  if (ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Connection Error!',
      headerClose: false,
      buttonPrompt: 'Server Connection Failed! <br />Details:' + error + '<br />Try again Later.',
      buttons: {
        Close: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
  }
}

let NOT_LOGGED_IN_DIALOG = false;
function NOTLOGGEDIN() {
  // if already on the login page, don't throw up the dialog
  if (isPageActive('Login_Page') || isPageActive('Initialization_Page')) {
    return;
  }

  if (NOT_LOGGED_IN_DIALOG) {
    return;
  }

  NOT_LOGGED_IN_DIALOG = true;

  window.vueApp.authTools.authSetToken(null);

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Not Logged In',
    headerClose: false,
    buttonPrompt: 'Your session has expired and you are no longer logged into the system.',
    buttons: {
      Login: {
        click: function () {
          verbose.log('DEBUG', 'Switching to Login Page');
          // NOTLOGGEDIN can be spammed sometimes and have a billion stacked if the user was away from comp
          // kill all open dialogs
          $('.ui-simpledialog-container').remove();
          $('.ui-simpledialog-screen').remove();

          VueBridge.enterVue('/login');

          // reset the flag, since we're now on the login page
          NOT_LOGGED_IN_DIALOG = false;
        },
      },
    },
  });
}

function ServerResponse(data, caller) {
  var Exception = '';
  var ServerResponse = '';

  if (typeof data === 'string') {
    ServerResponse = data;
  } else if (data.hasOwnProperty('ServerResponse')) {
    ServerResponse = data.ServerResponse;
  }

  if (ServerResponse == 'Connection Error' && ONLINE) {
    $('<div>').simpledialog2({
      themeDialog: 'a',
      mode: 'button',
      animate: false,
      headerText: 'ERROR',
      headerClose: false,
      buttonPrompt: 'Server Connection Failed! <br />' + caller,
      buttons: {
        OK: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
  }
  if (ServerResponse == 'DB Error') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'ERROR',
      headerClose: false,
      buttonPrompt: 'Database Error<br />' + caller + '<br />',
      buttons: {
        Acknowledge: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
  }
  if (ServerResponse == 'Nothing Changed') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'No Changes',
      headerClose: false,
      buttonPrompt: 'Nothing Changed to Update',
      buttons: {
        Close: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
  }
  if (ServerResponse == 'Uncaught Exception') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Error',
      headerClose: false,
      buttonPrompt: data.Exception,
      buttons: {
        Close: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
  }
  if (ServerResponse == 'Invalid Password') {
    if (data.Enabled != 0) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Error',
        headerClose: false,
        buttonPrompt: 'Invalid Password.  Warning too many attempts may lockout your account!',
        buttons: {
          Close: {
            click: function () {
              //acknolwedge
            },
          },
        },
      });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Error',
        headerClose: false,
        buttonPrompt:
          'Invalid Password.  Your account has been locked out for security reasons.  Please contact your company admin or use the forgot password reset form.',
        buttons: {
          Close: {
            click: function () {
              Logout();
            },
          },
        },
      });
    }
  }
}
