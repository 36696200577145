function update_Itin_Data() {
  $('#Report_Content').html('<h2>' + iTrans('Getting Records') + '...</h2>');
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  $.ajaxSetup({
    timeout: 6000000,
  });
  var pilot = $('#RPT_Pilots').val();
  var ident = $('#RPT_Idents').val();
  $.getJSON(
    BASEURL +
      'menu-Administration-Reports-AJAX.php?report=Report_Itin&FromDate=' +
      FromDate +
      '&ToDate=' +
      ToDate +
      '&pilot=' +
      encodeURIComponent(pilot) +
      '&ident=' +
      encodeURIComponent(ident),
    function (json_data) {
      $.ajaxSetup({
        timeout: 120000,
      });
      if (json_data[0].ServerResponse == 'No Records') {
        $('#RPT_PageControl').html('');
        $('#Title_Report_Name').html(iTrans('Itinerary Reports'));
        $('#Report_Content').html('<h2>' + iTrans('No Records') + '</h2>');
      } else {
        Report_Data = json_data;
        Report_Itin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    $.ajaxSetup({
      timeout: 120000,
    });
    AJAXFAIL(error);
  });
}

function mkbtn(id) {
  return `<button id="${id}" class="action-btn"><i class="fas fa-eye"></i></button>`;
}

function mkbtnOnClick(on) {
  return `<button onClick="${on}" class="action-btn"><i class="fas fa-eye"></i></button>`;
}

function goToForm(id) {
  const query = RemoteData.buildQueryString({
    'from-legacy': 'Report_Page',
    start: true,
    'form-picker': 1,
  });

  return VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/formData/${id}${query}`);
}

function showAttachedForms(id) {
  const attachedForms = Report_Data[id].attachedForms;
  let contents = '<table><tbody>';
  attachedForms.forEach((formMeta) => {
    const btn = mkbtnOnClick(`goToForm(${formMeta.form_data_id})`);
    contents += `<tr>
    <td>${formMeta.title}</td>
    <td>${btn}</td>
    </tr>`;
  });

  contents += `</tbody>
  </table>
  <a rel="close" data-role="button" href="#">Done</a>`;

  $('<div>').simpledialog2({
    mode: 'blank',
    animate: false,
    headerText: 'Attached Forms',
    headerClose: false,
    width: cSAMA_Dialog.dialogWidth,
    zindex: cSAMA_Dialog.dialogZIndex,
    blankContent: contents,
  });
}

function Report_Itin() {
  console.log('Itin Reports');
  $('#RPT_PageControl').html('');
  $('#Title_Report_Name').html(iTrans('Itinerary Reports'));
  $('#RPT_TDG_Filter1').show();
  $('#RPT_TDG_Filter2').show();
  var HTML = '';
  if (Report_Data.length == 101) {
    HTML = `<h3 class='limit-table'>More than 100 results! Showing newest 100 records from ${
      Report_Data[Report_Data.length - 1].filed
    } UTC</h3>`;
  }
  HTML += `<div style="width: 100%; overflow: auto">
  <table data-testid="report-itin-table" id="Report_Table" data-filter="true" class="wrap">
  <thead>
  <tr>
    <th data-priority="0">${iTrans('Date')}</th>
    <th>${iTrans('Itin')}#</th>
    <th>${iTrans('Ident')}</th>
    <th>${iTrans('Pilot')}</th>
    <th>${iTrans('Pax')}#</th>
    <th>${iTrans('Searchtime')}</th>
    <th>${iTrans('TimeBack')}</th>
    <th>${iTrans('Details')}</th>
    <th>FR</th>
    <th>TDG</th>
    <th>GPS</th>
    <th>${iTrans('Route')}</th>
    <th>WB</th>
    <th>FRM</th>
    <th>RA</th>
    <th>OFP</th>
    <th>PAX</th>
    <th>ATT</th>
    <th>${iTrans('Closed By')}</th>
    <th>${iTrans('Alarm')}</th>
  </tr>
  </thead>
  <tbody>`;

  var Table = '';
  for (var i in Report_Data) {
    if (!Report_Data.hasOwnProperty(i) || i === 'Manifests') {
      continue;
    }

    var I = Report_Data[i];
    var Equip = '';

    if (I.survivalkit > 0) Equip += '<br />' + iTrans('Survival Kit');
    if (I.liferaft > 0) Equip += '<br />' + iTrans('Life Raft');
    if (I.lifejackets > 0) Equip += '<br />' + iTrans('Life Jackets');

    var FR = I.FlightReport != null ? mkbtn('RPT_FR-' + i) : 'N/A';

    var TDG = 'N/A';
    if (I.TDGRevamp == 1) {
      TDG = mkbtnOnClick(`showRevampTdgManifest(${I.tdg_manifest_id})`);
    } else if (I.TDG == 1) {
      TDG = mkbtn('RPT_TDG-' + i);
    }

    var Route = I.RoutePlan == 1 ? mkbtn('RPT_Route-' + i) : 'N/A';
    var GPSData = I.GPSData != '' ? mkbtn('RPT_GPS-' + i) : 'N/A';
    var WB = I.WB == 1 ? mkbtn('RPT_WB-' + i) : 'N/A';
    var RA = I.RA == 1 ? mkbtn('RPT_RA-' + i) : 'N/A';
    var OFP = I.OFP != 0 ? mkbtnOnClick(`showOFPreport(${i})`) : 'N/A';
    var FRM = I.attachedForms.length > 0 ? mkbtnOnClick(`showAttachedForms(${i})`) : 'N/A';
    var PAX =
      I.Pax_Manifest.DefaultList.length > 0 || I.Pax_Manifest.Pax_Legs.length > 0
        ? mkbtnOnClick(`showPAXreport(${i},'${I.ident}')`)
        : 'N/A';
    var Attachments = I.Attachments.length > 0 ? mkbtn('RPT_Attachments-' + i) : 'N/A';

    var Filed = getLocalDateTime(I.filed);
    var Back = getLocalDateTime(I.timeback);
    var Search = getLocalDateTime(I.searchtime);
    var Duration = Report_Duration(Filed.date, Back.date);
    var lineClass = '';

    if (I.emailStatus == 'WARNING Sent') lineClass = 'yellowRecord';
    if (I.pilot != I.closedby) lineClass = 'orangeRecord';
    if (I.emailStatus != 'WARNING Sent' && I.emailStatus != 'Not Triggered') lineClass = 'redRecord';

    var LogBTN = '';

    if (I.emailStatus != 'Not Triggered')
      LogBTN = `<div class="vertical-center-container">
        <button class="cirro-btn" id="RPT_Logs-${i}">
          <i class="icon-margins fas fa-eye "></i> ${iTrans('Alert Logs')}
        </button> 
        </div>`;

    var PilotText = I.pilot;
    if (I.sic != '') PilotText += '<br />SIC: ' + I.sic;
    var wasVIP = '';
    if (I.VIP == 1) wasVIP = ' [VIP]';
    Table += `<tr class="${lineClass}">
  <td>${Filed.text}</td>
  <td>${I.PrimaryKey}${wasVIP}</td>
  <td>${I.ident}</td>
  <td>${PilotText}</td>
  <td>${I.paxnum}</td>
  <td>${Search.text}</td>
  <td>${Back.text}</td>
  <td>${I.Details}${Equip}<br/>Open For: ${Duration}</td>
  <td>${FR}</td>
  <td>${TDG}</td>
  <td>${GPSData}</td>
  <td>${Route}</td>
  <td>${WB}</td>
  <td>${FRM}</td>
  <td>${RA}</td>
  <td>${OFP}</td>
  <td>${PAX}</td>
  <td>${Attachments}</td>
  <td>${I.closedby}</td>
  <td>${I.emailStatus}${LogBTN}</td>
</tr>`;
  }
  HTML += Table + '</tbody></table></div>';
  $('#Report_Content').html(HTML);
  $('#Report_Content').enhanceWithin();
  AddTableSorter('Report_Table');
  for (i in Report_Data) {
    if ($('#RPT_WB-' + i).length) $('#RPT_WB-' + i).click(showItin_WB);
    if ($('#RPT_Route-' + i).length) $('#RPT_Route-' + i).click(showItin_Map);
    if ($('#RPT_GPS-' + i).length) $('#RPT_GPS-' + i).click(showItin_Map);
    if ($('#RPT_TDG-' + i).length) $('#RPT_TDG-' + i).click(showItin_TDG);
    if ($('#RPT_Logs-' + i).length) $('#RPT_Logs-' + i).click(showItin_Logs);
    if ($('#RPT_RA-' + i).length) $('#RPT_RA-' + i).click(showItin_RA);
    if ($('#RPT_Attachments-' + i).length) $('#RPT_Attachments-' + i).click(showItin_Attachments);
    if ($('#RPT_FR-' + i).length) $('#RPT_FR-' + i).click(showItin_FR);
  }
}

function showRevampTdgManifest(manifestId) {
  VueBridge.enterVue(
    `groups/${GROUP_DATA.group}/reports/dangerous-goods/manifest/${manifestId}?from-legacy=Report_Page`
  );
}

function showPAXreport(index, acIdent) {
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html(iTrans('Passenger Manifest'));
  $('#exitSubReportPage').click(exitshowItinLogs);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
    var data = e[0];
    AC_data = data.AC_data;
    ITIN_Pax_Manifest = Report_Data[index].Pax_Manifest;
    ITIN_Pax_Manifest.CurLeg = -1;
    ITIN_Pax_Manifest.AdminView = 1;
    var html =
      "<div class='limit-table'>" + PAX_GetPaxListHTML(Report_Data[index].Pax_Manifest, 'Sub_Report_Page') + '</div>';
    $('#Sub_Report_Content').html(html);
    $('#Sub_Report_Content').enhanceWithin();
  });
}

function showOFPreport(index) {
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');

  $('#exitSubReportPage').click(exitshowItinLogs);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=getItinOFP&ItinPK=' + Report_Data[index].PrimaryKey,
    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
      else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      else if (json_data[0].ServerResponse == 'No Records') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt: iTrans('ERROR Contact site admin'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      } else {
        $('#Title_Sub_Report_Name').html(iTrans('Operational Flight Plan'));
        MEASURE.LEGS = JSON.parse(Report_Data[index].FP);
        var FuelPlanHTML = '';
        for (var i in MEASURE.LEGS) {
          MEASURE.CurLeg = parseInt(i);
          var I = FuelPlans[i];
          MEASURE.geojson = MEASURE.LEGS[i];
          FuelPlanHTML += getFlightPlanDetails(MEASURE, false);
          FuelPlanHTML += '<br />';
        }
        var html = "<div class='limit-table'>" + GetOFP_Html(json_data[0].ServerResponse, FuelPlanHTML) + '</div>';
        $('#Sub_Report_Content').html(html);
        $('#Sub_Report_Content').enhanceWithin();
        //populateBillingHobbs(CURRENT_FLIGHT_REPORT);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function showItin_Attachments() {
  var index = this.id;
  index = index.split('-');
  index = index[1];

  var Attachments = Report_Data[index].Attachments;

  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html(iTrans('Attachments'));
  $('#exitSubReportPage').click(exitshowItinLogs);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  var html =
    "<h3 class='limit-table'>" +
    iTrans('Itinerary Attachments') +
    "</h3><table class='limit-table wrap'><thead><tr><th>" +
    iTrans('Filename') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in Attachments) {
    var A = Attachments[i];
    html +=
      '<tr><td>' +
      A.Filename +
      '</td><td><a href="' +
      BASEURL +
      'menu-Administration-Reports-AJAX.php?report=getItinAtt&PK=' +
      A.PrimaryKey +
      '" data-ajax="false" target="_blank"><img src="./images/inspect.svg" /></a></td></tr>';
  }
  html += '</tbody></table>';
  $('#Sub_Report_Content').html(html);
  $('#Sub_Report_Content').enhanceWithin();
}

function showItin_FR() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  signatureDATA = {
    data: '',
    text: '',
  };
  CURRENT_FLIGHT_REPORT = Report_Data[index].FlightReport;
  CURRENT_FLIGHT_REPORT.Job = Report_Data[index].JOB;
  if (CURRENT_FLIGHT_REPORT.Crew != '') {
    if (Array.isArray(CURRENT_FLIGHT_REPORT.Crew)) {
      BILLING_FLIGHTREPORT_CREW = CURRENT_FLIGHT_REPORT.Crew;
    } else {
      BILLING_FLIGHTREPORT_CREW = JSON.parse(CURRENT_FLIGHT_REPORT.Crew);
      CURRENT_FLIGHT_REPORT.Crew = BILLING_FLIGHTREPORT_CREW;
    }
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    CURRENT_FLIGHT_REPORT.Crew = [];
  }
  console.log('show FR');
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html(iTrans('Flight Report'));
  $('#exitSubReportPage').click(exitshowItinLogs);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  var clientPK = 0;
  if (CURRENT_FLIGHT_REPORT.Job.customCompany == 1) {
    clientPK = CURRENT_FLIGHT_REPORT.Job.ClientPK;
  } else {
    clientPK = CURRENT_FLIGHT_REPORT.operatorPK;
  }
  cirroDB.query('BillingClient', 'clientPK = ?', clientPK, function (json_data) {
    var headerData = makeHeaderData(CURRENT_FLIGHT_REPORT, json_data[0].data);
    console.log(json_data);
    //MAKE FLIGHT REPORT
    CURRENT_FLIGHT_REPORT.BLURB = json_data[0].data.flight_report_blurb;
    CURRENT_FLIGHT_REPORT.headerData = headerData;
    //CURRENT_FLIGHT_REPORT contains all flight report, job and flight legs for review.
    console.log(CURRENT_FLIGHT_REPORT);
    var FlightReport = BILLING_FLIGHT_REPORT_HTML(
      CURRENT_FLIGHT_REPORT,
      true,
      true,
      CURRENT_FLIGHT_REPORT.BLURB,
      CURRENT_FLIGHT_REPORT.headerData
    );
    $('#Sub_Report_Content').html(FlightReport);
    $('#Sub_Report_Content').enhanceWithin();
  });
}

function showItin_RA() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  console.log('show RA');
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html(iTrans('Alert Log'));
  $('#exitSubReportPage').click(exitshowItinLogs);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=getItin_RA&Itin_PK=' + Report_Data[index].PrimaryKey,
    function (log_data) {
      console.log(log_data);
      $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
      $('#Title_Sub_Report_Name').html(iTrans('Risk Assessment') + '# ' + Report_Data[index].PrimaryKey);
      $('#exitSubReportPage').click(exitshowRA_Form);
      $.mobile.changePage($('#Sub_Report_Page'), {
        reverse: false,
        changeHash: false,
      });

      $('#Sub_Report_Content').html('');
      RAform = log_data[0];
      RAform.CurLeg = 0;
      RAform.HidePerLeg = 0;
      RAform.HidePerFlight = 0;
      RAform.isReport = true;
      var html = '';
      for (var i in RAform.LEGS) {
        RAform.CurLeg = i;
        html += RAoperation(true);
      }

      $('#Sub_Report_Content').html('<div class="limit-table">' + html + '</div>');
      $('#Sub_Report_Content').enhanceWithin();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Itin TDG Data');
    AJAXFAIL(error);
  });
}

function exitshowItinLogs() {
  $('#exitSubReportPage').unbind('click', exitshowItinLogs);
  $.mobile.changePage($('#Report_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function showItin_Logs() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  console.log('show TDG');
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html('Alert Log');
  $('#exitSubReportPage').click(exitshowItinLogs);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=getItin_Logs&Itin_PK=' + Report_Data[index].PrimaryKey,
    function (log_data) {
      console.log(log_data);
      $('#Sub_Report_Content').html('');
      var HTML =
        "<h3 class='limit-table'>" +
        iTrans('Showing Alert Logs for Itinerary') +
        '#' +
        Report_Data[index].PrimaryKey +
        '</h3>';
      var I;
      var Sent;
      var Completed;
      var Type;
      var Status;
      var lineClass;
      HTML += '<h3 class="limit-table">' + iTrans('Mail Log') + '</h3>';
      HTML +=
        '<div style="width: 100%; overflow: auto"><table data-filter="true" class="limit-table">' +
        '<thead>' +
        '<tr>' +
        '<th>' +
        iTrans('Sent') +
        '</th>' +
        '<th>' +
        iTrans('User') +
        '</th>' +
        '<th>' +
        iTrans('Email') +
        '</th>' +
        '<th>' +
        iTrans('Type') +
        '</th>' +
        '<th>' +
        iTrans('Status') +
        '</th>' +
        '<th>' +
        iTrans('Additional Info') +
        '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';
      var Mail = log_data[0];
      for (i in Mail) {
        I = Mail[i];
        Sent = getLocalDateTime(I.Initiated);
        Type = I.Type;
        Type = Type.split(':');
        Type = Type[0];
        Status = iTrans('Delivered');
        lineClass = '';
        if (I.Success == 0) {
          Status = iTrans('Failed');
          lineClass = 'redRecord';
        }
        HTML +=
          '<tr class="' +
          lineClass +
          '"><td>' +
          Sent.text +
          '</td><td>' +
          I.chatname +
          '</td><td>' +
          I.Recipient +
          '</td><td>' +
          Type +
          '</td><td>' +
          Status +
          '</td><td>' +
          I.Reason +
          '</td></tr>';
      }
      //HTML += "<tr><td>test</td><td>test</td><td>test</td></tr>";
      HTML += '</tbody></table></div>';

      HTML += '<h3 class="limit-table">' + iTrans('Call Log') + '</h3>';
      HTML +=
        '<div style="width: 100%; overflow: auto"><table data-filter="true" class="limit-table">' +
        '<thead>' +
        '<tr>' +
        '<th>' +
        iTrans('Sent') +
        '</th>' +
        '<th>' +
        iTrans('Completed') +
        '</th>' +
        '<th>' +
        iTrans('User') +
        '</th>' +
        '<th>' +
        iTrans('Phone Number') +
        '</th>' +
        '<th>' +
        iTrans('Type') +
        '</th>' +
        '<th>' +
        iTrans('Status') +
        '</th>' +
        '<th>' +
        iTrans('Additional Info') +
        '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';
      var PBX = log_data[1];
      for (i in PBX) {
        I = PBX[i];
        Sent = getLocalDateTime(I.Initiated);
        Completed = getLocalDateTime(I.Completed);
        Type = I.Type;
        Type = Type.split(':');
        Type = Type[0];
        Status = iTrans('Answered');
        lineClass = '';
        if (I.Answered == 0) {
          Status = iTrans('No Answer');
          lineClass = 'yellowRecord';
        }
        HTML +=
          '<tr class="' +
          lineClass +
          '"><td>' +
          Sent.text +
          '</td><td>' +
          Completed.text +
          '</td><td>' +
          I.chatname +
          '</td><td>' +
          I.Recipient +
          '</td><td>' +
          Type +
          '</td><td>' +
          Status +
          '</td><td>' +
          I.Response +
          I.Reason +
          '</td></tr>';
      }
      //HTML += "<tr><td>test</td><td>test</td><td>test</td></tr>";
      HTML += '</tbody></table></div>';

      HTML += '<h3 class="limit-table">' + iTrans('Outgoing SMS Log') + '</h3>';
      HTML +=
        '<div style="width: 100%; overflow: auto"><table data-filter="true" class="limit-table">' +
        '<thead>' +
        '<tr>' +
        '<th>' +
        iTrans('Sent') +
        '</th>' +
        '<th>' +
        iTrans('User') +
        '</th>' +
        '<th>' +
        iTrans('Phone Number') +
        '</th>' +
        '<th>' +
        iTrans('Type') +
        '</th>' +
        '<th>' +
        iTrans('Status') +
        '</th>' +
        '<th>' +
        iTrans('Additional Info') +
        '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';
      var SMS = log_data[2];
      for (i in SMS) {
        I = SMS[i];
        Sent = getLocalDateTime(I.Initiated);
        Type = I.Type;
        Type = Type.split(':');
        Type = Type[0];
        Status = iTrans('Delivered');
        lineClass = '';
        if (I.Sent == 1) Status = iTrans('Sent, No Delivery Confirmation');
        if (I.Delivered == 1) Status = iTrans('Delivered');
        if (I.Delivered == 0) lineClass = 'yellowRecord';
        if (I.Failed == 1) {
          Status = 'Failed';
          lineClass = 'redRecord';
        }
        HTML +=
          '<tr class="' +
          lineClass +
          '"><td>' +
          Sent.text +
          '</td><td>' +
          I.chatname +
          '</td><td>' +
          I.Recipient +
          '</td><td>' +
          Type +
          '</td><td>' +
          Status +
          '</td><td>' +
          I.Reason +
          '</td></tr>';
      }
      //HTML += "<tr><td>test</td><td>test</td><td>test</td></tr>";
      HTML += '</tbody></table></div>';

      HTML += '<h3 class="limit-table">' + iTrans('Incoming SMS Log') + '</h3>';
      HTML +=
        '<div style="width: 100%; overflow: auto"><table data-filter="true" class="limit-table">' +
        '<thead>' +
        '<tr>' +
        '<th>' +
        iTrans('Received') +
        '</th>' +
        '<th>' +
        iTrans('User') +
        '</th>' +
        '<th>' +
        iTrans('Phone Number') +
        '</th>' +
        '<th>' +
        iTrans('Request') +
        '</th>' +
        '<th>' +
        iTrans('Response') +
        '</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';
      var SMSin = log_data[3];
      for (i in SMSin) {
        I = SMSin[i];
        Sent = getLocalDateTime(I.Sent);
        HTML +=
          '<tr><td>' +
          Sent.text +
          '</td><td>' +
          I.chatname +
          '</td><td>' +
          I.Number +
          '</td><td>' +
          I.Request +
          '</td><td>' +
          I.Response +
          '</td></tr>';
      }
      //HTML += "<tr><td>test</td><td>test</td><td>test</td></tr>";
      HTML += '</tbody></table></div>';

      $('#Sub_Report_Content').html(HTML);
      $('#Sub_Report_Content').enhanceWithin();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Itin TDG Data');
    AJAXFAIL(error);
  });
}

function showItin_WB() {
  console.log('show WB');
  var index = this.id;
  index = index.split('-');
  index = index[1];
  console.log('show TDG');
  $('#RPT_TDG_Filter1').hide();
  $('#RPT_TDG_Filter2').hide();
  $('#Report_Content').html("<h3 class='limit-table'>" + iTrans('Retrieving Data') + '...</h3>');
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=getItin_WB&PK=' + Report_Data[index].PrimaryKey,
    function (wb_data) {
      console.log(wb_data);
      $('#RPT_TDG_Filter1').hide();
      $('#RPT_TDG_Filter2').hide();
      $('#Report_Content').html('');
      var HTML =
        "<button id='RPT_ExitWB' data-icon='back' data-mini='true'>" + iTrans('Return to Itinerary List') + '</button>';
      HTML += '<table class="limit-table"><tr><td><h3>' + iTrans('Itinerary WB') + '</h3>';

      var Pilot = Report_Data[index].pilot;
      var SIC = Report_Data[index].sic;
      var date = getLocalDateTime(Report_Data[index].filed);
      var itinNum = Report_Data[index].PrimaryKey;
      var ident = Report_Data[index].ident;
      HTML += '<b>' + iTrans('Pilot') + ': </b>' + Pilot + '<br />';
      HTML += '<b>SIC: </b>' + SIC + '<br />';
      HTML += '<b>' + iTrans('Ident') + ': </b>' + ident + '<br />';
      HTML += '<b>' + iTrans('Date') + ': </b>' + date.text + '<br />';
      HTML += '<b>' + iTrans('Itin') + '#: </b>' + itinNum + '</tr></td></table>';

      HTML +=
        '<div style="width: 100%; overflow: auto"><table id="Report_Table" data-filter="true">' +
        '<thead>' +
        '<tr>' +
        '<th data-priority="0">' +
        iTrans('Leg Updates') +
        '</th>' +
        '<th></th>' +
        '<th></th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';

      for (i in wb_data) {
        var I = wb_data[i];
        var Changed = getLocalDateTime(I.Changed);
        var table = '<h3>' + iTrans('Leg') + '# ' + (I.LegNum + 1) + '</h3><table>';
        table += '<tr><td>' + iTrans('Updated') + '</td><td>' + I.Changed + '</td></tr>';
        table += '<tr><td>' + iTrans('Pilot') + '</td><td>' + I.Pilot + '</td></tr>';
        table += '<tr><td>SIC</td><td>' + I.sic + '</td></tr>';
        table += '<tr><td>' + iTrans('Cargo Weight') + '</td><td>' + I.CargoWeight + '</td></tr>';
        table += '<tr><td>' + iTrans('Origin') + '</td><td>' + I.LegOrigin + '</td></tr>';
        if (I.LegStartE != 0 && I.LegEndE != 0)
          table += '<tr><td>' + GLOBAL_LABELS.LegTimes.LegStartE + '</td><td>' + I.LegStartE + '</td></tr>';
        table += '<tr><td>' + GLOBAL_LABELS.LegTimes.LegStart + '</td><td>' + I.LegStart + '</td></tr>';
        table += '<tr><td>' + GLOBAL_LABELS.LegTimes.LegEnd + '</td><td>' + I.LegEnd + '</td></tr>';
        if (I.LegStartE != 0 && I.LegEndE != 0)
          table += '<tr><td>' + GLOBAL_LABELS.LegTimes.LegEndE + '/td><td>' + I.LegEndE + '</td></tr>';
        table += '<tr><td>' + iTrans('Dest') + '</td><td>' + I.LegDest + '</td></tr>';
        table += '<tr><td>' + iTrans('ETA Dest') + '</td><td>' + I.LegETA + '</td></tr>';
        table += '<tr><td>' + iTrans('Pax') + ' #</td><td>' + I.PaxNum + '</td></tr>';
        table += '<tr><td>' + iTrans('Details') + '</td><td>' + I.Description + '</td></tr>';

        table += '</table>';
        table += '<h3>' + iTrans('Take Off CofG') + '</h3>';
        table += '<table>';
        table += '<tr><td>' + iTrans('CofG Lat') + '</td><td>' + I.cofglat + '</td></tr>';
        table += '<tr><td>' + iTrans('CofG Long') + '</td><td>' + I.cofglong + '</td></tr>';
        table += '<tr><th>' + iTrans('Weight') + '</th><th></th></tr>';
        table += '<tr><td>' + iTrans('Empty AC') + '</td><td>' + I.Eweight + '</td></tr>';
        table += '<tr><td>' + iTrans('Fuel') + '</td><td>' + I.fuelwt + '</td></tr>';
        table += '<tr><td>' + iTrans('Other Items') + '</td><td>' + I.otherwt + '</td></tr>';

        table += '<tr><td>' + iTrans('Total') + '</td><td>' + I.acwt + '</td></tr>';
        table += '<tr><td>' + iTrans('Max Weight DA') + '</td><td>' + I.maxperfweight + '</td></tr></table>';
        var table2 = '<h3>' + iTrans('Other Items') + '</h3><table>';
        var Items = I.Summary;
        Items = Items.split(',');
        for (b in Items) {
          var B = Items[b].split(':');
          if (B[1] != undefined) table2 += '<tr><td>' + B[0] + '</td><td>' + B[1] + '</td></tr>';
        }
        table2 += '</table>';
        HTML +=
          '<tr style="background-color:#FFFFFF;"><td>' +
          table +
          '</td><td>' +
          table2 +
          '</td><td><h3>' +
          iTrans('Weight and Balance') +
          '</h3><img src="' +
          I.GRAPH +
          '" /><br />';
        HTML += '<b>' + iTrans('Temp Config') + '# ' + I.tempconfig + '</b><br />';
        HTML += '<b>' + iTrans('WB Dated') + ': </b>' + I.wbDate + '<br />';
        HTML += '<b>' + iTrans('Effective') + ': </b>' + Changed.text;

        HTML += '</td></tr>';
      }
      //HTML += "<tr><td>test</td><td>test</td><td>test</td></tr>";
      HTML += '</tbody></table></div>';

      $('#Report_Content').html(HTML);
      $('#Report_Content').enhanceWithin();
      $('#RPT_ExitWB').click(Report_Itin);
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Itin TDG Data');
    AJAXFAIL(error);
  });
}
var REPORTMAP = null;

function showItin_Map() {
  console.log('show GPS');
  var index = this.id;
  index = index.split('-');
  var type = index[0];
  index = index[1];
  var GPS_Data = Report_Data[index].GPSData;
  $('#Report_Content').html('');
  $('#RPT_TDG_Filter1').hide();
  $('#RPT_TDG_Filter2').hide();
  var RoutePlans = Report_Data[index].RouteData;
  if (REPORTMAP != null) {
    REPORTMAP.remove();
    REPORTMAP = null;
  }
  var FuelPlans = [];
  if (Report_Data[index].FP != 'None') FuelPlans = JSON.parse(Report_Data[index].FP);
  //var btnHTML ="<button id='RPT_ExitMap' data-icon='back' data-mini='true'>Return to Itinerary List</button>";
  var legacyFP = true;
  if (FuelPlans.length > 0) {
    if (FuelPlans[0].type != undefined) legacyFP = false;
  }
  if (legacyFP) {
    //legacy reporting prior to 1.35.0

    $('#Report_Content').html('<div id="REPORTMAP"></div>');
    $('#Report_Content').enhanceWithin();
    mapboxgl.accessToken = MAPBOX_TOKEN;
    REPORTMAP = new mapboxgl.Map({
      container: 'REPORTMAP', // container id
      style: 'mapbox://styles/airsuite/cizg733jt00fi2sqiskxfjukc',
      center: [-89.2461, 48.3822], // starting position
      zoom: 3, // starting zoom
      minzoom: 1,
      pitch: 0,
      bearing: 0,
    });

    REPORTMAP.on('load', function () {
      var pathnum = 1;
      var path = [];
      var ReportControl = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
      if (RoutePlans == '')
        ReportControl +=
          '<label for="RPT_ROUTE">' +
          iTrans('Route Plan') +
          '</label><input type="checkbox" id="RPT_ROUTE" disabled />';
      if (RoutePlans != '') {
        if (type == 'RPT_Route')
          ReportControl +=
            '<label for="RPT_ROUTE">' +
            iTrans('Route Plan') +
            '</label><input type="checkbox" id="RPT_ROUTE" checked/>';
        else
          ReportControl +=
            '<label for="RPT_ROUTE">' + iTrans('Route Plan') + '</label><input type="checkbox" id="RPT_ROUTE" />';
        var lines = {
          type: 'FeatureCollection',
          features: [],
        };
        for (i in RoutePlans) {
          if (RoutePlans[i].PathNum == pathnum) {
            path.push([RoutePlans[i].Long, RoutePlans[i].Lat]);
            var destination = [RoutePlans[i].Lat, RoutePlans[i].Long];
          } else {
            var feature = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: path,
              },
            };
            lines.features.push(feature);
            path = [];
            pathnum = RoutePlans[i].PathNum;
            path.push([RoutePlans[i].Long, RoutePlans[i].Lat]);
          }
        }
        var feature = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: path,
          },
        };
        lines.features.push(feature);
        if (REPORTMAP.getSource('ROUTEPLAN-LINES') != undefined) {
          REPORTMAP.getSource('ROUTEPLAN-LINES').setData(lines);
        } else {
          REPORTMAP.addSource('ROUTEPLAN-LINES', {
            type: 'geojson',
            data: lines,
          });
          REPORTMAP.addLayer({
            id: 'ROUTEPLAN-LINES',
            type: 'line',
            source: 'ROUTEPLAN-LINES',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#ff7800',
              'line-width': 4,
              'line-opacity': 0.7,
            },
          });
        }

        var Plan = 1;
        var Point = 1;
        var points = {
          type: 'FeatureCollection',
          features: [],
        };
        for (var fps in FuelPlans) {
          var fp = FuelPlans[fps];
          var Fpath = [];
          for (var p in fp) {
            var f = fp[p];
            Fpath.push([f.latlng.lng, f.latlng.lat]);
            var feature = {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [f.latlng.lng, f.latlng.lat],
              },
              properties: f,
            };
            points.features.push(feature);
          }
        }
        if (REPORTMAP.getSource('ROUTEPLAN-ACTIVE') == undefined) {
          REPORTMAP.on('click', showFuelPlan);
          REPORTMAP.addSource('ROUTEPLAN-ACTIVE', {
            type: 'geojson',
            data: points,
          });
          REPORTMAP.addLayer({
            id: 'ROUTEPLAN-ACTIVE',
            type: 'circle',
            source: 'ROUTEPLAN-ACTIVE',
            paint: {
              'circle-radius': 10,
              'circle-color': '#ff0000',
              'circle-stroke-color': '#000000',
              'circle-stroke-width': 2,
              'circle-stroke-opacity': 0.8,
              'circle-opacity': 0.7,
            },
          });
        } else {
          REPORTMAP.getSource('ROUTEPLAN-ACTIVE').setData(points);
        }
      } else {
        ReportControl +=
          '<label for="RPT_ROUTE">' + iTrans('Route Plan') + '</label><input type="checkbox" id="RPT_ROUTE" disabled/>';
      }
      //End route plans
      if (GPS_Data == '')
        ReportControl +=
          '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" disabled />';
      if (GPS_Data != '') {
        if (type == 'RPT_GPS')
          ReportControl +=
            '<label for="RPT_GPS">' + iTrans('GPS History<') + '/label><input type="checkbox" id="RPT_GPS" checked />';
        else
          ReportControl +=
            '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" />';
        var GPSpoints = {
          type: 'FeatureCollection',
          features: [],
        };
        console.log(GPS_Data);
        var GPS = [];
        var count = GPS_Data.length - 1;
        for (i in GPS_Data) {
          var I = GPS_Data[i];
          GPS.push([I.Long, I.Lat]);
          var GPSfeature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [I.Long, I.Lat],
            },
            properties: I,
          };
          GPSpoints.features.push(GPSfeature);
        }
        var GPSlines = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: GPS,
              },
            },
          ],
        };
        if (REPORTMAP.getSource('GPS-LINES') != undefined) {
          REPORTMAP.getSource('GPS-LINES').setData(GPSlines);
        } else {
          REPORTMAP.addSource('GPS-LINES', {
            type: 'geojson',
            data: GPSlines,
          });
          REPORTMAP.addLayer({
            id: 'GPS-LINES',
            type: 'line',
            source: 'GPS-LINES',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#0000ff',
              'line-width': 4,
              'line-opacity': 0.7,
            },
          });
        }
        if (REPORTMAP.getSource('GPS-POINTS') != undefined) {
          REPORTMAP.getSource('GPS-POINTS').setData(GPSpoints);
        } else {
          REPORTMAP.on('click', showGPSreport);
          REPORTMAP.addSource('GPS-POINTS', {
            type: 'geojson',
            data: GPSpoints,
          });
          REPORTMAP.addLayer({
            id: 'GPS-POINTS',
            type: 'symbol',
            source: 'GPS-POINTS',
            layout: {
              'icon-image': 'GPS',
              'icon-size': 0.3,
              visibility: 'visible',
              'icon-padding': 0,
              'icon-offset': [0, 0],
              'icon-rotation-alignment': 'map',
              'icon-ignore-placement': true,
              'icon-allow-overlap': true,
              'symbol-placement': 'point',
              'icon-rotate': {
                property: 'heading',
                type: 'identity',
              },
            },
            paint: {
              'icon-opacity': 0.8,
            },
          });
        }
      } else {
        ReportControl +=
          '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" disabled/>';
      }
      if (type == 'RPT_GPS') {
        var bbox = turf.bbox(GPSlines);
        if (REPORTMAP.getSource('ROUTEPLAN-ACTIVE') != undefined) {
          REPORTMAP.setLayoutProperty('ROUTEPLAN-ACTIVE', 'visibility', 'none');
          REPORTMAP.setLayoutProperty('ROUTEPLAN-LINES', 'visibility', 'none');
        }
      } else {
        var bbox = turf.bbox(points);
        if (REPORTMAP.getSource('GPS-POINTS') != undefined) {
          REPORTMAP.setLayoutProperty('GPS-POINTS', 'visibility', 'none');
          REPORTMAP.setLayoutProperty('GPS-LINES', 'visibility', 'none');
        }
      }
      REPORTMAP.fitBounds(bbox, {
        padding: 100,
      });
      ReportControl += '<label for="RPT_EXIT">' + iTrans('EXIT') + '</label><input type="checkbox" id="RPT_EXIT" />';
      ReportControl += '</fieldset>';
      $('#RPT_PageControl').html(ReportControl);
      $('#Report_Page').enhanceWithin();
      if (GPS_Data != '') $('#RPT_GPS').click(toggleGPSrpt);
      if (RoutePlans != '') $('#RPT_ROUTE').click(toggleROUTErpt);
      $('#RPT_EXIT').click(Report_Itin);
    });
  } else {
    //New Fuel planning from 1.35.0
    $('#Report_Content').html('<div id="REPORTMAP"></div>');
    $('#Report_Content').enhanceWithin();
    mapboxgl.accessToken = MAPBOX_TOKEN;
    REPORTMAP = new mapboxgl.Map({
      container: 'REPORTMAP', // container id
      style: 'mapbox://styles/airsuite/cizg733jt00fi2sqiskxfjukc',
      center: [-89.2461, 48.3822], // starting position
      zoom: 3, // starting zoom
      minzoom: 1,
      pitch: 0,
      bearing: 0,
    });

    REPORTMAP.on('load', function () {
      var pathnum = 1;
      var path = [];
      var ReportControl = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
      if (FuelPlans == 'None')
        ReportControl +=
          '<label for="RPT_ROUTE">' + iTrans('Fuel Plan') + '</label><input type="checkbox" id="RPT_ROUTE" disabled/>';
      if (FuelPlans != 'None') {
        if (type == 'RPT_Route')
          ReportControl +=
            '<label for="RPT_ROUTE">' + iTrans('Fuel Plan') + '</label><input type="checkbox" id="RPT_ROUTE" checked/>';
        else
          ReportControl +=
            '<label for="RPT_ROUTE">' + iTrans('Fuel Plan') + '</label><input type="checkbox" id="RPT_ROUTE" />';
        var lines = {
          type: 'FeatureCollection',
          features: [],
        };
        getRoutePlanLines(FuelPlans, lines, Report_Data[index].ident);

        if (REPORTMAP.getSource('ROUTEPLAN-ACTIVE') == undefined) {
          REPORTMAP.on('click', showFuelPlan_NEW);
          REPORTMAP.addSource('ROUTEPLAN-ACTIVE', {
            type: 'geojson',
            data: lines,
          });
          REPORTMAP.addLayer({
            id: 'ROUTEPLAN-ACTIVE',
            type: 'circle',
            source: 'ROUTEPLAN-ACTIVE',
            paint: {
              'circle-radius': 10,
              'circle-color': '#ff0000',
              'circle-stroke-color': '#000000',
              'circle-stroke-width': 2,
              'circle-stroke-opacity': 0.8,
              'circle-opacity': 0.7,
            },
          });
          REPORTMAP.addLayer({
            id: 'ROUTEPLAN-LINES',
            type: 'line',
            source: 'ROUTEPLAN-ACTIVE',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#ff7800',
              'line-width': 4,
              'line-opacity': 0.7,
            },
          });
          REPORTMAP.addLayer({
            id: 'measure-labels',
            type: 'symbol',
            source: 'ROUTEPLAN-ACTIVE',
            layout: {
              'symbol-placement': 'line-center',
              'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
              'text-field': '{LegNum}-{SegmentNum} {ORIGIN}-->{DESTINATION} {Distance} {Bearing}°T',
              'text-size': 16,
              'text-ignore-placement': true,
              'text-allow-overlap': true,
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': '#000000',
              'text-halo-width': 1.5,
            },
          });
        } else {
          REPORTMAP.getSource('ROUTEPLAN-ACTIVE').setData(lines);
        }
      } else {
        ReportControl +=
          '<label for="RPT_ROUTE">' + iTrans('Route Plan') + '</label><input type="checkbox" id="RPT_ROUTE" disabled/>';
      }
      //End route plans
      if (GPS_Data == '')
        ReportControl +=
          '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" disabled />';
      if (GPS_Data != '') {
        if (type == 'RPT_GPS')
          ReportControl +=
            '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" checked />';
        else
          ReportControl +=
            '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" />';
        var GPSpoints = {
          type: 'FeatureCollection',
          features: [],
        };
        console.log(GPS_Data);
        var GPS = [];
        var count = GPS_Data.length - 1;
        for (i in GPS_Data) {
          var I = GPS_Data[i];
          GPS.push([I.Long, I.Lat]);
          var GPSfeature = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [I.Long, I.Lat],
            },
            properties: I,
          };
          GPSpoints.features.push(GPSfeature);
        }
        var GPSlines = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: GPS,
              },
            },
          ],
        };
        if (REPORTMAP.getSource('GPS-LINES') != undefined) {
          REPORTMAP.getSource('GPS-LINES').setData(GPSlines);
        } else {
          REPORTMAP.addSource('GPS-LINES', {
            type: 'geojson',
            data: GPSlines,
          });
          REPORTMAP.addLayer({
            id: 'GPS-LINES',
            type: 'line',
            source: 'GPS-LINES',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#0000ff',
              'line-width': 4,
              'line-opacity': 0.7,
            },
          });
        }
        if (REPORTMAP.getSource('GPS-POINTS') != undefined) {
          REPORTMAP.getSource('GPS-POINTS').setData(GPSpoints);
        } else {
          REPORTMAP.on('click', showGPSreport);
          REPORTMAP.addSource('GPS-POINTS', {
            type: 'geojson',
            data: GPSpoints,
          });
          REPORTMAP.addLayer({
            id: 'GPS-POINTS',
            type: 'symbol',
            source: 'GPS-POINTS',
            layout: {
              'icon-image': 'GPS',
              'icon-size': 0.3,
              visibility: 'visible',
              'icon-padding': 0,
              'icon-offset': [0, 0],
              'icon-rotation-alignment': 'map',
              'icon-ignore-placement': true,
              'icon-allow-overlap': true,
              'symbol-placement': 'point',
              'icon-rotate': {
                property: 'heading',
                type: 'identity',
              },
            },
            paint: {
              'icon-opacity': 0.8,
            },
          });
        }
      } else {
        ReportControl +=
          '<label for="RPT_GPS">' + iTrans('GPS History') + '</label><input type="checkbox" id="RPT_GPS" disabled/>';
      }
      if (type == 'RPT_GPS') {
        var bbox = turf.bbox(GPSlines);
        if (REPORTMAP.getSource('ROUTEPLAN-ACTIVE') != undefined) {
          REPORTMAP.setLayoutProperty('ROUTEPLAN-ACTIVE', 'visibility', 'none');
          REPORTMAP.setLayoutProperty('ROUTEPLAN-LINES', 'visibility', 'none');
        }
      } else {
        var bbox = turf.bbox(lines);
        if (REPORTMAP.getSource('GPS-POINTS') != undefined) {
          REPORTMAP.setLayoutProperty('GPS-POINTS', 'visibility', 'none');
          REPORTMAP.setLayoutProperty('GPS-LINES', 'visibility', 'none');
        }
      }
      console.log(bbox);
      REPORTMAP.fitBounds(bbox, {
        padding: 100,
      });
      ReportControl += '<label for="RPT_EXIT">' + iTrans('EXIT') + '</label><input type="checkbox" id="RPT_EXIT" />';
      ReportControl += '</fieldset>';
      $('#RPT_PageControl').html(ReportControl);
      $('#Report_Page').enhanceWithin();
      if (GPS_Data != '') $('#RPT_GPS').click(toggleGPSrpt);
      if (RoutePlans != '') $('#RPT_ROUTE').click(toggleROUTErpt);
      $('#RPT_EXIT').click(Report_Itin);
    });
  }
}

function showFuelPlan(e) {
  //for pre 1.35.0
  var features = REPORTMAP.queryRenderedFeatures(e.point, {
    layers: ['ROUTEPLAN-ACTIVE'],
  });

  if (!features.length) {
    return;
  }
  console.log(features);
  var f = features[0].properties;
  var html = '<h2>' + iTrans('Fuel Plan Details') + "</h2><table class='limit-table'>";
  html += '<tr><td>' + iTrans('Fuel Remaining') + '</td><td>' + f.FuelRemain + ' Lbs</td></tr>';
  html += '<tr><td>' + iTrans('Distance Remaining') + '</td><td>' + f.DistRemain + ' NM</td></tr>';
  html += '<tr><td>' + iTrans('Fuel Consumed') + '</td><td>' + f.FuelConsumed + ' Lbs</td></tr>';
  html += '<tr><td>' + iTrans('Fuel Burn Rate') + '</td><td>' + f.ACFuelBurn + ' Lbs/Hr</td></tr>';
  html += '<tr><td>' + iTrans('Cruise Speed') + '</td><td>' + f.ACcruise + ' Knt</td></tr></table>';
  $('#reportDetails').html(html);
  $('#reportInfo').popup('open');
}

function showFuelPlan_NEW(e) {
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var features = REPORTMAP.queryRenderedFeatures(bbox, {
    layers: ['ROUTEPLAN-LINES'],
  });
  if (!features.length) {
    return;
  }
  var f = features[0].properties;
  console.log(f);
  MEASURE.CurLeg = f.LegIndex;
  MEASURE.geojson = JSON.parse(f.LegFuelPlan);

  $.mobile.changePage($('#ReportsRoutePlan_Details'), {
    reverse: false,
    changeHash: false,
  });
  populateLegDetails('ReportsFuelPlanDetails');
}

function returnReportsFPDetails() {
  $.mobile.changePage($('#Report_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function showGPSreport(e) {
  var features = REPORTMAP.queryRenderedFeatures(e.point, {
    layers: ['GPS-POINTS'],
  });

  if (!features.length) {
    return;
  }
  console.log(features);
  var I = features[0].properties;
  var AGL = parseInt(I.altitude) - parseInt(I.elevation);
  AGL = Math.round(AGL * 3.28084);

  var html = '<h1>' + iTrans('GPS Data') + '</h1>';
  html += '<table>';
  html += '<tr><td>' + iTrans('UTC Time') + ':</td><td>' + I.dateTime + '</td></tr>';
  html += '<tr><td>' + iTrans('Speed') + ':</td><td>' + I.speed + ' Knt</td></tr>';
  html += '<tr><td>' + iTrans('Heading') + ':</td><td>' + I.heading + ' deg true</td></tr>';
  html += '<tr><td>' + iTrans('Altitude') + ':</td><td>' + Math.round(I.altitude * 3.28084) + ' ft ASL</td></tr>';
  //html += "<tr><td>AGL:</td><td>"+AGL+" ft</td></tr>";
  html += '<tr><td>' + iTrans('Lat') + ':</td><td>' + I.Lat + '</td></tr>';
  html += '<tr><td>' + iTrans('Long') + ':</td><td>' + I.Long + '</td></tr>';
  html += '</table>';
  $('#reportDetails').html(html);
  $('#reportInfo').popup('open');
}
var gpsCluster = [];
var gpsRPTmarker;
var gpsRPTpath;
var routeRPTpath;
var fuelRPTpath;
var fuelPaths = [];
var fuelRPTmarker;
var routeRPTmarker;

function toggleGPSrpt() {
  console.log(this.checked);
  REPORTMAP.setLayoutProperty('GPS-POINTS', 'visibility', this.checked ? 'visible' : 'none');
  REPORTMAP.setLayoutProperty('GPS-LINES', 'visibility', this.checked ? 'visible' : 'none');
}

function toggleROUTErpt() {
  console.log(this.checked);
  REPORTMAP.setLayoutProperty('ROUTEPLAN-ACTIVE', 'visibility', this.checked ? 'visible' : 'none');
  REPORTMAP.setLayoutProperty('ROUTEPLAN-LINES', 'visibility', this.checked ? 'visible' : 'none');
}

function showItin_TDG() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  console.log('show TDG');
  $('#RPT_TDG_Filter1').hide();
  $('#RPT_TDG_Filter2').hide();
  $('#Report_Content').html("<h3 class='limit-table'>" + iTrans('Retrieving Data') + '...</h3>');
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=getItin_TDG&PK=' + Report_Data[index].PrimaryKey,
    function (tdg_data) {
      console.log(tdg_data);
      $('#RPT_TDG_Filter1').hide();
      $('#RPT_TDG_Filter2').hide();
      $('#Report_Content').html('');
      Report_Data['Manifests'] = [];
      Report_Data['Manifests'][index] = tdg_data[0];
      Manifest_HTML(tdg_data[0], index, 'itin');
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Itin TDG Data');
    AJAXFAIL(error);
  });
}
function update_Log_Data() {
  $('#Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=Report_Logs&FromDate=' + FromDate + '&ToDate=' + ToDate,
    function (json_data) {
      Report_Data = json_data;
      Report_Logs();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    AJAXFAIL(error);
  });
}

function Report_Logs() {
  var Category = Report_Data[2];
  var NotifyType = $('input:radio[name=RPT_NotifyType]:checked').val();
  var CurCat = 'All';
  if ($('#RPT_CatFilter').length) {
    CurCat = $('#RPT_CatFilter').val();
  }
  //$("#Report_Content").html("");
  var CategoryFilter =
    '<select id="RPT_CatFilter" data-mini="true"><option value="All" selected>' + iTrans('All') + '</option>';
  for (var c in Category) {
    CategoryFilter += '<option value="' + Category[c].Type + '">' + Category[c].Type + '</option>';
  }
  CategoryFilter += '</select>';
  console.log('Log Reports');
  $('#Title_Report_Name').html(iTrans('Notification Report'));
  var HTML = '';
  var i;
  if (NotifyType == 'NoItin') {
    HTML +=
      '<h3>' +
      iTrans('Aircraft Detected Airborne Without Itinerary') +
      '</h3><div style="width: 100%; overflow: auto"><table data-filter="true">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('On') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Ident') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Type') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var NoItin = Report_Data[0];
    for (i in NoItin) {
      var I = NoItin[i];
      var date = I.Datetime;
      date = getLocalDateTime(date);
      HTML += '<tr><td>' + date.text + '</td><td>' + I.ident + '</td><td>' + I.AcType + '</td></tr>';
    }
    HTML += '</tbody></table></div>';
    $('#Report_Content').html(HTML);
    $('#Report_Content').enhanceWithin();
  }
  if (NotifyType == 'Notify') {
    HTML +=
      '<h3 class="limit-table">' +
      iTrans('Notifications Sent') +
      '</h3><div style="width: 100%; overflow: auto"><table data-filter="true">' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('Sent') +
      '</th>' +
      '<th>' +
      iTrans('User') +
      '</th>' +
      '<th>' +
      iTrans('Email') +
      '</th>' +
      '<th>' +
      iTrans(CategoryFilter) +
      '</th>' +
      '<th>' +
      iTrans('Message') +
      '</th>' +
      '<th>' +
      iTrans('Status') +
      '</th>' +
      '<th>' +
      iTrans('Additional Info') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var Notify = Report_Data[1];
    for (i in Notify) {
      I = Notify[i];
      date = I.Initiated;
      date = getLocalDateTime(date);
      var Message = '<b>' + I.subject + '</b><br />' + I.html;
      var Status = iTrans('Delivered');
      var lineClass = '';
      if (I.Success == 0) {
        Status = iTrans('Failed');
        lineClass = 'redRecord';
      }
      console.log(CurCat);
      if (CurCat == 'All' || I.Type == CurCat)
        HTML +=
          '<tr class="' +
          lineClass +
          '"><td>' +
          date.text +
          '</td><td>' +
          I.chatname +
          '</td><td>' +
          I.Recipient +
          '</td><td>' +
          I.Type +
          '</td><td>' +
          Message +
          '</td><td>' +
          Status +
          '</td><td>' +
          I.Reason +
          '</td></tr>';
    }
    HTML += '</tbody></table></div>';
    $('#Report_Content').html(HTML);
    $('#Report_Content').enhanceWithin();
    $('#RPT_CatFilter').val(CurCat).selectmenu('refresh');
    $('#RPT_CatFilter').change(Report_Logs);
  }
}
