(function($){

class cGalAddButton
{
  /**
   * Initializes the add-button
   */
  constructor()
  {
    this.allowAdd    = true;
    this.oldAllowAdd = true;
    this.dom         = {
      wrapper: $('<div />').addClass('gallup-thumbWrapper')
    };

    $('<div />').addClass('gallup-thumb gallup-add')
                .appendTo(this.dom.wrapper);
  }

  /**
   * Get the wrapper DOM element
   * @returns {jQuery}
   */
  GetWrapper()
  {
    return this.dom.wrapper;
  }

  /**
   * Toggles the button on/off
   * @param {boolean} _enable
   */
  Enable(_enable)
  {
    this.allowAdd = _enable;
    if (_enable)
    {
      this.dom.wrapper.removeClass('gallup-disabled');
    }
    else
    {
      this.dom.wrapper.addClass('gallup-disabled');
    }
  }

  ExternalEnable(_enable)
  {
    if (_enable && this.oldAllowAdd)
    {
      // If allow add was true before and _enable is true
      this.Enable(true);
    }
    else if (!_enable)
    {
      this.oldAllowAdd = this.allowAdd;
      this.Enable(false);
    }
  }
}

class cGalImageManip
{

  /**
   * Crops and scales an image
   * @param {string}   _image         Base64 encoded image
   * @param {function} _callback      Function called when the operation is finished
   * @param {int}      _squareSize    The square to fit the image into
   * @param {object}   [_cropAspect]  Has two members, w and h for width and height
   */
  static ScaleCropImage(_image, _callback, _squareSize, _cropAspect)
  {
    let img         = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () =>
    {
      let cropCoords = {
        ratios    : {
          final : 0,
          source: 0
        },
        topLeft   : {
          x: 0,
          y: 0
        },
        sourceArea: {
          width : 0,
          height: 0
        },
        finalSize : {
          width : 0,
          height: 0
        }
      };

      cropCoords.ratios.final = img.width / img.height;

      if (_cropAspect != null)
      {
        cropCoords.ratios.final = _cropAspect.w / _cropAspect.h;
      }
      else if (img.width <= _squareSize && img.height <= _squareSize)
      {
        // picture already fits into defined square
        _callback(_image, cGalImageManip.B64ToFile(_image));
        return;
      }

      if (cropCoords.ratios.final < 1)
      {
        // Height > Width
        cropCoords.finalSize.height = _squareSize;
        cropCoords.finalSize.width  = _squareSize * cropCoords.ratios.final;
      }
      else if (cropCoords.ratios.final > 1)
      {
        // Width > Height
        cropCoords.finalSize.height = _squareSize / cropCoords.ratios.final;
        cropCoords.finalSize.width  = _squareSize;
      }
      else
      {
        // Square
        cropCoords.finalSize.width = cropCoords.finalSize.height = _squareSize;
      }

      cropCoords.ratios.source     = Math.max(cropCoords.finalSize.width / img.width, cropCoords.finalSize.height / img.height);
      cropCoords.sourceArea.width  = cropCoords.finalSize.width / cropCoords.ratios.source;
      cropCoords.sourceArea.height = cropCoords.finalSize.height / cropCoords.ratios.source;

      cropCoords.topLeft.x = (img.width / 2) - (cropCoords.sourceArea.width / 2);
      cropCoords.topLeft.y = (img.height / 2) - (cropCoords.sourceArea.height / 2);

      let sourceCanvas    = document.createElement('canvas');
      let sourceContext   = sourceCanvas.getContext('2d');
      sourceCanvas.width  = cropCoords.finalSize.width * 4;
      sourceCanvas.height = cropCoords.finalSize.height * 4;

      let targetCanvas    = document.createElement('canvas');
      let targetContext   = targetCanvas.getContext('2d');
      targetCanvas.width  = cropCoords.finalSize.width;
      targetCanvas.height = cropCoords.finalSize.height;

      sourceContext.drawImage(
          img,
          cropCoords.topLeft.x, cropCoords.topLeft.y,
          cropCoords.sourceArea.width, cropCoords.sourceArea.height,
          0, 0,
          cropCoords.finalSize.width * 4, cropCoords.finalSize.height * 4
      );

      sourceContext.drawImage(
          sourceCanvas,
          0, 0,
          cropCoords.finalSize.width * 2, cropCoords.finalSize.height * 2
      );

      targetContext.drawImage(
          sourceCanvas,
          0, 0,
          cropCoords.finalSize.width * 2, cropCoords.finalSize.height * 2,
          0, 0,
          cropCoords.finalSize.width, cropCoords.finalSize.height
      );

      let image = targetCanvas.toDataURL('image/jpeg', 0.7);

      let file = cGalImageManip.B64ToFile(image);

      _callback(image, file);
    };

    img.src = _image;
  }

  /**
   * @param {string} _b64 Base64 image
   * @returns {Blob}
   */
  static B64ToFile(_b64)
  {
    let blobBin = atob(_b64.split(',')[1]);
    let buffer  = [];
    for (let i = 0; i < blobBin.length; i++)
    {
      buffer.push(blobBin.charCodeAt(i));
    }
    return new Blob([new Uint8Array(buffer)], {type: 'image/jpeg'});
  }
}

/**
 *
 * @param {jQuery} _jqTarget
 * @param {object} this.settings
 * @class
 */
class cGalLoader
{
  constructor(_jqTarget, _settings)
  {
    this.dom = {
      container: $('<div />').addClass('gallup-thumbs'),
      addButton: new cGalAddButton(),
      input    : _jqTarget
    };

    this.enabled         = true;
    this.pictures        = [];
    this.deletedPictures = [];
    this.settings        = _settings;

    this.dom.container
        .appendTo(this.dom.input.parent());

    this.dom.addButton
        .GetWrapper()
        .appendTo(this.dom.container)
        .on('click', () => this.OnAddButtonClick());

    this.dom.input.hide().on('change', () => this.OnNewPicture());

    this.modal = new cGalModal(this.dom.container, _settings, this);
  }

  /**
   * Destroys this GalLoader instance
   */
  Destroy()
  {
    this.dom.input.show().off();
    this.dom.container.remove();

    this.dom             = null;
    this.pictures        = null;
    this.deletedPictures = null;
  }

  /**
   * Called when the user clicks the add-button
   */
  OnAddButtonClick()
  {
    if (this.dom.addButton.allowAdd)
    {
      this.dom.input.trigger('click');
    }
  }

  /**
   * Called when the user adds a new image to the file input
   */
  OnNewPicture()
  {
    let inputFiles       = this.dom.input.get(0).files;
    let evtBeginLoad     = $.Event('beginLoad');
    evtBeginLoad.numPics = inputFiles.length;
    this.dom.input.trigger(evtBeginLoad);

    let actPics = this.pictures.length;

    for (let i = 0; i < inputFiles.length; i++)
    {
      this.AddPicture(inputFiles[i]);
      actPics++;

      if (actPics >= this.settings.validation.maxImages)
      {
        this.dom.addButton.Enable(false);
        break;
      }
    }

    this.dom.input.val('');
  }

  ShowModal(_centralPicture)
  {
    let center = this.pictures.findIndex((_e) => _e.uid === _centralPicture.uid);
    this.modal.Show(center);
  }

  /**
   * Gets the original input this plugin is attached to
   * @return {jQuery}
   */
  GetOriginalInput()
  {
    return this.dom.input;
  }

  /**
   * Add a picture to the gallery
   * @param {File} _file
   */
  AddPicture(_file)
  {
    let reader = new FileReader();

    let newPic    = this.AddPicFrame();
    reader.onload = () => newPic.FinishLoad(reader.result, false);

    reader.readAsDataURL(_file);
  }

  AddPicFrame(_descr)
  {
    let newPic = new cGalPicture(this, this.settings, _descr);
    this.pictures.push(newPic);
    newPic.GetWrapper()
          .hide()
          .insertBefore(this.dom.addButton.GetWrapper())
          .fadeIn(this.settings.animDur.showThumb);

    return newPic;
  }

  /**
   * Removes the picture from the plugin
   * @param {cGalPicture} _picture
   */
  RemovePicture(_picture)
  {
    this.dom.input.trigger('imageDeleted');

    let remIdx = this.pictures.findIndex((_e) => _e.Equal(_picture));

    if (remIdx === -1)
    {
      throw 'Cant delete picture that doesnt exist';
    }

    if (typeof this.settings.descriptionValidator === 'function' && this.enabled)
    {
      this.settings.descriptionValidator(false, _picture.dom.wrapper, null, _picture.uid);
    }

    this.deletedPictures.push(this.pictures[remIdx]);
    this.pictures.splice(remIdx, 1);

    this.Refresh();
  }

  /**
   * Retrieve all currently loaded b64 images
   * @param {boolean} _getPreloaded
   * @returns {Array}
   */
  GetB64Pictures(_getPreloaded)
  {
    if (_getPreloaded == null)
    {
      _getPreloaded = true;
    }

    let images      = [];
    let allPictures = this.pictures.concat(this.deletedPictures);

    for (let i = 0; i < allPictures.length; i++)
    {
      if (!_getPreloaded && allPictures[i].WasPreloaded())
      {
        continue;
      }
      images[i] = allPictures[i].GetImage();
    }

    return images;
  }

  /**
   * Retrieve all currently loaded image files
   * @param {boolean} _getPreloaded
   * @returns {Array}
   */
  GetFormFiles(_getPreloaded)
  {
    _getPreloaded = (_getPreloaded == null) ? true : _getPreloaded;

    let files       = [];
    let allPictures = this.pictures.concat(this.deletedPictures);

    for (let i = 0; i < allPictures.length; i++)
    {
      if (!_getPreloaded && allPictures[i].WasPreloaded())
      {
        continue;
      }
      files[i] = allPictures[i].GetFile();
    }

    return files;
  }

  /**
   * Load an image into the gallery so users may view/delete it
   * @param {{id:{string}, description:{string}, data:{string}}} _b64ImageObject
   */
  PreloadImage(_b64ImageObject)
  {
    let newPic = this.AddPicFrame(_b64ImageObject.description);

    newPic.FinishLoad(_b64ImageObject, true);
    this.Refresh();
  }

  /**
   * Takes an object of oldUID : newUID pairs and assigns this.pictures the newUID as their UID.
   * @param {object} _updateObject
   */
  UpdateUID(_updateObject)
  {
    for (let p = 0; p < this.pictures.length; p++)
    {
      let pic                = this.pictures[p];
      pic.descriptionChanged = false;
      if (_updateObject.hasOwnProperty(pic.uid))
      {
        pic.uid = _updateObject[pic.uid];
      }
    }
  }

  Refresh()
  {
    let actPic = this.pictures.length;

    let enable = actPic < this.settings.validation.maxImages;
    this.dom.addButton.Enable(enable);
  }

  ExternalEnable(_enable)
  {
    this.enabled = _enable;
    this.dom.addButton.ExternalEnable(_enable);
    this.modal.SetEnable(_enable);

    for (let p = 0; p < this.pictures.length; p++)
    {
      this.pictures[p].SetEnable(_enable);
    }
  }
}

class cGalModal
{
  constructor(_container, _settings, _owner)
  {
    this.settings  = _settings;
    this.owner     = _owner;
    this.scrolling = true;
    this.enabled   = true;

    this.dom = {
      modal    : $('<div />').addClass('gallup-overlay'),
      content  : $('<div/>').addClass('gallup-overlay-content'),
      buttonBar: $('<div/>').addClass('gallup-overlay-buttons'),
      prevBtn  : $('<button />').addClass('gallup-overlay-prev').text('< previous'),
      clseBtn  : $('<button />').addClass('gallup-overlay-close').text('x close'),
      nextBtn  : $('<button />').addClass('gallup-overlay-next').text('next >'),
      images   : []
    };

    this.dom.content.appendTo(this.dom.modal);
    this.dom.buttonBar.appendTo(this.dom.modal);

    this.dom.prevBtn.appendTo(this.dom.buttonBar);
    this.dom.clseBtn.appendTo(this.dom.buttonBar);
    this.dom.nextBtn.appendTo(this.dom.buttonBar);

    this.dom.modal
        .css('z-index', this.settings.modalZindex)
        .hide()
        .appendTo(_container);

    this.dom.prevBtn.on('click', () => this.Prev());
    this.dom.clseBtn.on('click', () => this.Hide());
    this.dom.nextBtn.on('click', () => this.Next());
  }

  Prev()
  {
    this.activeImage--;

    if (this.activeImage < 0)
    {
      this.activeImage = this.dom.images.length - 1;
    }

    this.ScrollTo(this.activeImage);
  }

  Next()
  {

    this.activeImage++;

    if (this.activeImage >= this.dom.images.length)
    {
      this.activeImage = 0;
    }

    this.ScrollTo(this.activeImage);
  }

  SetEnable(_enable)
  {
    this.enabled = _enable;
  }

  LoadImages(_picCenter)
  {
    this.dom.modal.css('opacity', '0').show();
    for (let p = 0; p < this.owner.pictures.length; p++)
    {
      let picture = this.owner.pictures[p];

      let wrap = $('<div/>')
          .addClass('gallup-overlay-image-wrap colad');

      $('<img />')
          .attr('src', picture.GetImage().image)
          .appendTo(wrap);

      this.dom.images[p] = wrap;
      this.dom.images[p].appendTo(this.dom.content);

      if (this.settings.allowDescriptions === true)
      {
        $('<span />')
            .text('#' + (p + 1) + ' Description:')
            .addClass('gallup-overlay-image-title')
            .appendTo(wrap);

        let ta = $('<textarea />')
            .attr('maxlength', 256)
            .addClass('gallup-overlay-description')
            .val(picture.description)
            .prop('disabled', !this.enabled);

        ta.on('input', () =>
        {
          if (!this.enabled)
          {
            return;
          }

          picture.description        = ta.val();
          picture.descriptionChanged = true;

          if (typeof this.settings.descriptionValidator === 'function')
          {
            this.settings.descriptionValidator(picture.description, picture.dom.wrapper, ta, picture.uid);
          }
        });

        ta.appendTo(wrap);

        if (typeof this.settings.descriptionValidator === 'function' && this.enabled)
        {
          this.settings.descriptionValidator(picture.description, picture.dom.wrapper, ta, picture.uid);
        }
      }
    }

    this.OnResize();

    this.dom.modal
        .css('opacity', '1')
        .hide()
        .fadeIn(this.settings.animDur.modal, () =>
        {
          if (_picCenter != null)
          {
            this.ScrollTo(_picCenter);
          }
        })
        .on('scroll', (_e) => this.OnScroll(_e));
  }

  OnResize()
  {
    let last = this.dom.images.length - 1;
    this.dom.content.css({
      'padding-top'   : this.dom.images[0].outerHeight() / 2 + 'px',
      'padding-bottom': this.dom.images[last].outerHeight() / 2 + 'px'
    });

    this.OnScroll();
  }

  OnScroll()
  {
    let fullway = window.innerHeight;
    let halfway = fullway / 2;

    for (let p = 0; p < this.dom.images.length; p++)
    {
      let img    = this.dom.images[p];
      let imgpos = img.position().top + img.outerHeight() / 2;
      let dist   = 0;

      if (imgpos < halfway)
      {
        dist = halfway - imgpos;
      }
      else
      {
        dist = imgpos - halfway;
      }

      img.css('outline', 'none');
      if (dist < 200)
      {
        if (!this.scrolling)
        {
          this.activeImage = p;
        }
        dist = 0;
      }

      img.css('opacity', 1.5 - dist / halfway);
    }
  }

  ScrollTo(_pic)
  {
    this.scrolling   = true;
    this.activeImage = _pic;

    let img         = this.dom.images[_pic];
    let conOffs     = this.dom.content.position().top;
    let scrollValue = img.position().top - conOffs - img.outerHeight() / 5;

    this.dom.modal
        .stop(true, true)
        .animate({
          'scrollTop': scrollValue
        }, 250, null)
        .delay(200)
        .queue((next) =>
        {
          this.scrolling = false;
          next();
        });
  }

  Show(_picCenter)
  {
    this.activeImage = _picCenter;
    $('body, html').css('overflow', 'hidden')
                   .on('resize', () => this.OnResize());
    this.LoadImages(_picCenter);

    this.dom.prevBtn.removeClass('ui-btn ui-shadow ui-corner-all');
    this.dom.clseBtn.removeClass('ui-btn ui-shadow ui-corner-all');
    this.dom.nextBtn.removeClass('ui-btn ui-shadow ui-corner-all');
  }

  Hide()
  {
    this.dom.content.html('');
    this.dom.images = [];
    this.dom.modal.fadeOut(this.settings.animDur.modal, () => $('body, html').css('overflow', '').off());
    this.owner.dom.input.trigger('modalClosed');
  }
}

// -------------------------------------------------------------------------------------------------------------------
/**
 *
 * @param {cGalLoader} _this.owner
 * @param {object}     _image
 * @param {boolean}    [_isPreload]
 * @param {object}     _settings
 * @class
 */

class cGalPicture
{
  constructor(_owner, _settings, _description)
  {
    this.uid                = cGalPicture.GetUID();
    this.file               = null;
    this.owner              = _owner;
    this.isDeleted          = false;
    this.isEnabled          = true;
    this.settings           = _settings;
    this.description        = (_description == null) ? '' : _description;
    this.descriptionChanged = false;

    this.dom = {
      wrapper  : $('<div />').addClass('gallup-thumbWrapper'),
      thumb    : $('<div />').addClass('gallup-thumb'),
      image    : $('<div />').addClass('gallup-image'),
      loader   : $('<div />').addClass('gallup-loader'),
      buttonBar: $('<div />').addClass('gallup-thumbButtonBar'),
      btnDelete: $('<div />').addClass('gallup-thumbBtn gallup-deleteBtn').hide(),
      btnZoom  : $('<div />').addClass('gallup-thumbBtn gallup-zoomBtn').hide()
    };

    this.dom.thumb.appendTo(this.dom.wrapper);
    this.dom.loader.appendTo(this.dom.buttonBar);
    this.dom.buttonBar.appendTo(this.dom.thumb);
  }

  FinishLoad(_image, _isPreload)
  {
    if (_image.data != null)
    {
      this.uid = _image.id || this.uid;
      _image   = _image.data;
    }

    this.image        = _image;
    this.wasPreloaded = (_isPreload == null) ? false : _isPreload;

    this.dom.buttonBar
        .append(this.dom.btnDelete)
        .append(this.dom.btnZoom);

    this.dom.image.hide().appendTo(this.dom.thumb);

    if (this.settings.scaling.enabled && !this.settings.cropping.enabled)
    {
      cGalImageManip.ScaleCropImage(
          this.image,
          (_i, _f) => this.ImageProcessedCallback(_i, _f),
          this.settings.scaling.squareSize
      );
    }
    else if (this.settings.scaling.enabled && this.settings.cropping.enabled)
    {
      cGalImageManip.ScaleCropImage(
          this.image,
          (_i, _f) => this.ImageProcessedCallback(_i, _f),
          this.settings.scaling.squareSize,
          this.settings.cropping.aspect
      );
    }
    else
    {
      this.ImageProcessedCallback(this.image, this.file);
    }

    this.AttachEventHandlers();
  }

  /**
   * Produces a time based, negative Identifier
   * @returns {int}
   */
  static GetUID()
  {
    return -Math.round(window.performance.now() * 10000000000);
  }

  ImageProcessedCallback(_image, _file)
  {
    this.image = _image;
    this.file  = _file;

    this.owner.GetOriginalInput().trigger('doneLoad');

    this.dom.image.fadeOut(250, () =>
    {
      this.dom.loader.fadeOut(250, () =>
      {
        this.dom.btnDelete.fadeIn(100);
        this.dom.btnZoom.fadeIn(100);
      });

      this.dom.image
          .css('background-image', 'url("' + this.image + '")')
          .fadeIn(250);
    });
  }

  /**
   * Attaches event handlers
   */
  AttachEventHandlers()
  {
    this.dom.btnDelete.on('click', () => this.Destroy());
    this.dom.btnZoom.on('click', () =>
    {
      this.owner.ShowModal(this);
    });
    this.dom.image.on('click', () =>
    {
      this.owner.ShowModal(this);
    });
  }

  Destroy()
  {
    if (this.isDeleted || !this.isEnabled)
    {
      return;
    }
    this.isDeleted = true;

    let anProp = {
      'width'  : '0px',
      'opacity': '0'
    };

    this.dom.wrapper.animate(anProp, this.settings.animDur.buttons, () => this.dom.wrapper.remove());
    this.owner.RemovePicture(this);
  }

  SetEnable(_enable)
  {
    this.isEnabled = _enable;
    if (!_enable)
    {
      this.dom.btnDelete.css('opacity', '0.6');
    }
    else
    {
      this.dom.btnDelete.css('opacity', '1');
    }
  }

  /**
   * Get the wrapper DOM element
   * @returns {jQuery}
   */
  GetWrapper()
  {
    return this.dom.wrapper;
  }

  /**
   * Get the image as b64 encoded dataURL
   * @returns {Object}
   */
  GetImage()
  {
    return {
      preloaded         : this.wasPreloaded,
      deleted           : this.isDeleted,
      image             : this.image,
      uid               : this.uid,
      description       : this.description,
      descriptionChanged: this.descriptionChanged
    };
  }

  /**
   * Get the image as a form encoded file
   * @returns {Object}
   */
  GetFile()
  {
    return {
      preloaded         : this.wasPreloaded,
      deleted           : this.isDeleted,
      file              : this.file,
      uid               : this.uid,
      description       : this.description,
      descriptionChanged: this.descriptionChanged
    };
  }

  /**
   * Indicates if the picture was inserted programmatically
   * @returns {boolean}
   */
  WasPreloaded()
  {
    return this.wasPreloaded;
  }

  WasDeleted()
  {
    return this.isDeleted;
  }

  /**
   * Tests for equality with another picture
   * @param {cGalPicture} _other
   * @returns {boolean}
   */
  Equal(_other)
  {
    return this.uid === _other.uid;
  }
}

$.fn.galLoader = function ( _optionsOrOperation, _operationOptions )
{
  let settings = $.extend({}, $.fn.galLoader.defaults, _optionsOrOperation);

  if ( typeof FileReader === 'undefined' || typeof FormData === 'undefined' )
  {
    throw 'Can\'t use galLoader without FileReader or FormData support. Sorry.';
  }

  if ( typeof _optionsOrOperation === 'string' )
  {
    let gl = $(this).data('jq-galLoader');

    if ( gl == null )
    {
      throw 'Cannot call an operations on uninitialized GalLoader.';
    }

    switch (_optionsOrOperation)
    {
      case 'preload' :
        return this.each(function ()
        {
          let gl = $(this).data('jq-galLoader');
          gl.PreloadImage(_operationOptions);
        });
      case 'updateUID' :
        return this.each(function ()
        {
          let gl = $(this).data('jq-galLoader');
          gl.UpdateUID(_operationOptions);
        });
      case 'destroy' :
        return this.each(function ()
        {
          let gl = $(this).data('jq-galLoader');
          gl.Destroy();
          gl = null;
          $(this).data('jq-galLoader', null);
        });
      case 'enable' :
        return this.each(function ()
        {
          let gl = $(this).data('jq-galLoader');
          gl.ExternalEnable(_operationOptions);
        });
      case 'getBase64' :
        return gl.GetB64Pictures(_operationOptions);
      case 'getFormEncoded' :
        return gl.GetFormFiles(_operationOptions);
      case 'version':
        return '2.4.0.1521568942-debug';
      default:
        throw _optionsOrOperation + ' is not a valid GalLoader operation. Sorry.';
    }
  }

  return this.each(function ()
  {
    let gl = new cGalLoader($(this), settings);
    $(this).data('jq-galLoader', gl);
  });
};

$.fn.galLoader.defaults = {
  'modalZindex'         : 10000,
  'validation'          :
    {
      'maxImages': 30
    },
  'scaling'             : {
    'enabled'   : true,
    'squareSize': 1000
  },
  'cropping'            : {
    'enabled': false,
    'aspect' : {
      w: '1',
      h: '1'
    }
  },
  'descriptionValidator': null,
  'allowDescriptions'   : false,
  'animDur'             : {
    'modal'    : 250,
    'showThumb': 700,
    'hideThumb': 300,
    'buttons'  : 250
  }
};


})(jQuery);
