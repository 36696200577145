// function test__iTrans(phrase) {
//   console.log('Running Test...');
//   console.time('iTrans');
//   for (let i = 0; i < 10000; i++) {
//     iTrans(phrase);
//   }
//   console.timeEnd('iTrans');
// }

/**
 *
 * @param {string} phrase
 * @returns {*}
 */
function iTrans(phrase) {
  if (i18n == null) {
    return phrase;
  }

  try {
    // First we need to make the phrase into a json-friendly key
    let key = phrase.replace(/\W/g, '_').replace(/__/g, '');
    let cleanedString = 'status_LANG.' + key;
    const foundTranslation = i18n.t(cleanedString);
    if (foundTranslation === cleanedString) {
      // We didn't have a plain english string in the json
      // fall back to returning the phrase
      if (missingTranslations[key] == undefined) {
        missingTranslations[key] = phrase;
      }
      return phrase;
    }

    return foundTranslation;
  } catch (e) {
    if (window.hasOwnProperty(verbose)) {
      verbose.error('i18n', phrase, e);
    }
    return phrase;
  }
}
var missingTranslations = {};
