/**
 * Class handling the display and real-time modification of SAMA consequences
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_ModalConsequenceEditor extends vSAMA_AbstractModal {
  /**
   * @param {mSAMA_Consequence} _consequence
   * @param {mSAMA_RiskMatrix}  _riskMatrix
   * @param {boolean}           _readOnly
   */
  constructor(_consequence, _riskMatrix, _readOnly) {
    super();

    this.readOnly = _readOnly == null ? false : _readOnly;
    this.riskMatrix = _riskMatrix;
    this.consequence = _consequence;
    this.riskFactorValueEditors = [];

    this.dom.bar.html($(SAMA.Templates.tpl.consequences.modalBar));

    this.dom.riskScore = this.dom.bar.find('.sama-risk-number');
    this.dom.btnCancel = this.dom.bar.find('.btn-cons-cancel');
    this.dom.btnAccept = this.dom.bar.find('.btn-cons-accept');
    this.dom.btnCorrAct = this.dom.bar.find('.btn-cons-corract').hide();

    this.SetUpGui();
  }

  /**
   * @override
   */
  SetUpGui() {
    this.dom.btnCancel.on('click', () => this.OnCancel());
    this.dom.btnCorrAct.on('click', () => this.Close());

    if (this.readOnly) {
      this.dom.btnAccept.prop('disabled', true);
    } else {
      this.dom.btnAccept.on('click', () => this.OnAccept());
    }

    let heading = `
      <div class="grid-row">
        <div class="grid-col-sm-12">
          <h1>Risk Assessment</h1>
        </div>
      </div>`;

    this.dom.content.append(heading);
    super.SetUpGui();
  }

  /**
   * Sets the risk score in the modal bar and colours it
   * @param {number}                    _score
   * @param {{high:int,medium:int}} _bounds
   */
  SetRiskScore(_score, _bounds) {
    this.dom.riskScore.text(_score.toFixed(2));

    if (_score >= _bounds.high) {
      this.dom.riskScore.removeClass('sama-low sama-medium').addClass('sama-high');
    } else if (_score >= _bounds.medium) {
      this.dom.riskScore.removeClass('sama-low sama-high').addClass('sama-medium');
    } else {
      this.dom.riskScore.removeClass('sama-medium sama-high').addClass('sama-low');
    }
  }

  /**
   * @param {vSAMA_RiskFactorValueEditor} _factorValueEditor
   */
  AddRiskFactorValueEditor(_factorValueEditor) {
    let rfveDom = _factorValueEditor.GetDom();
    this.riskFactorValueEditors.push(_factorValueEditor);
    this.dom.content.append(rfveDom);
    rfveDom.enhanceWithin();
  }

  OnCancel() {
    for (let r = 0; r < this.riskFactorValueEditors.length; r++) {
      this.riskFactorValueEditors[r].DiscardChanges();
    }

    this.Close();
  }

  OnAccept() {
    for (let r = 0; r < this.riskFactorValueEditors.length; r++) {
      this.riskFactorValueEditors[r].ConfirmChanges();
    }

    this.Close();
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  /**
   * Called when the estimation button is pressed
   * @param {function} [_after] Function to call when the controls are fondled.
   *                          Used for when the corrective action modal is open
   *                          and we're just peeking at the RA
   */
  Open(_after) {
    this.lockId = SAMA.GeneralGui.LockPageState('CONModal.Open');

    if (_after == null) {
      // If we're not peeking from the COA
      this.dom.btnCancel.show();
      this.dom.btnAccept.show();
      this.dom.btnCorrAct.hide();

      $('html, body').animate(
        {
          scrollTop: 0,
        },
        0
      );
    } else {
      // if we are peeking in from the COA
      this.dom.btnCancel.hide();
      this.dom.btnAccept.hide();
      this.dom.btnCorrAct.show();
    }

    this.fnAfterClose = _after;

    this.UpdateRisk();

    super.Open();
  }

  /**
   * @override
   */
  Close() {
    this.UpdateRisk();

    if (this.fnAfterClose == null) {
      $('html, body').animate(
        {
          scrollTop: $('#sama_rep_estimation_end').offset().top,
        },
        0
      );
    } else {
      this.fnAfterClose();
    }

    this.fnAfterClose = null;

    SAMA.GeneralGui.UnlockPageState(this.lockId);
    super.Close();
  }

  UpdateRisk() {
    let newRisk = this.consequence.UpdateRiskCalculation();
    this.SetRiskScore(newRisk, this.riskMatrix.GetBounds());
    this.UpdateObservers('RiskChange');
  }
}
