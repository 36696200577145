/**
 *
 */
class AirportsTable extends AbstractDatabaseTable {
  constructor() {
    super('Airports', 'ICAO');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('AirportIndex.mbtiles');
  }

  tableCreationQuery() {
    //#region return ...
    return 'select true;';
    //#endregion
  }
}
