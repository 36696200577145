/**
 * TODO check if this is still in use. The way it was implemented
 *      originally was potentially broken and might have caused bugs. (was attempting to use 'id' === 'ICAO' in update query)
 */
class GpsTracksTable extends AbstractDatabaseTable {
  constructor() {
    super('GPS_Tracks', 'id');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            id        integer primary key,
            ICAO      text,
            timestamp integer,
            data      text
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    const parsed = [];
    let timestamp = 0;
    for (const row of rows) {
      row.data = this.parseJson(row.data);
      timestamp = row.timestamp;
      parsed.push(row);
    }

    if (timestamp != 0) {
      await this.delete('timestamp < ?', [timestamp]);
    }

    return parsed.reverse();
  }
}
