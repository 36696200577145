/**
 *
 */
class AcTable extends AbstractDatabaseTable {
  constructor() {
    super('AC', 'ident');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            ident         text primary key,
            AC_data       text,
            WB_Data       text,
            LOADABLE_data text,
            TC_data       text
        )
    `;
    //#endregion
  }

  /**
   *
   * @param whereClause
   * @param values
   * @returns {Promise<false|any[]>}
   */
  async select(whereClause, values) {
    let columns = null;
    if (values == '') {
      columns = ['ident', 'AC_data', `WB_Data`];
    }

    const result = await super.select(whereClause, values, columns);

    if (result === false) {
      return false;
    }

    const parsed = [];
    for (const row of result) {
      row.AC_data = this.parseJson(row.AC_data);
      row.WB_Data = this.parseJson(row.WB_Data);
      row.LOADABLE_data = this.parseJson(row.LOADABLE_data);
      row.TC_data = this.parseJson(row.TC_data);

      parsed.push(row);
    }

    return parsed;
  }
}
