class vSAMA_PolicyEditor extends vSAMA_AbstractEditor {
  constructor(_pageBody, _policy, _readOnly) {
    super();
    this.policy = _policy;

    this.dom = {
      page: _pageBody,
      execSelect: _pageBody.find('input[data-sref="pol_execPk"]'),
      execSignaturePad: _pageBody.find('#sama_pol_execSignature'),
      execPhotoUploader: _pageBody.find('#sama_pol_execImage'),
      policyDocLoader: _pageBody.find('#pol_fileloader'),
    };

    if (_readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.policy, this.dom.page);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.policy, this.dom.page, this);
    }

    this.dom.execPhotoUploader
      .galLoader({
        scaling: {
          enabled: true,
          squareSize: 500,
        },
        validation: {
          maxImages: 1,
        },
        cropping: {
          enabled: true,
          aspect: {
            w: '1',
            h: '1',
          },
        },
      })
      .on('change imageDeleted.galLoader', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        this.policy.SetExecImageChanged(SAMA.enums.IMAGESTATES.CHANGED);
        this.policy.SetModified(true);
      });

    this.dom.policyDocLoader
      .fileloader({
        readonly: _readOnly,
        endpoint: `${cSAMA_Utils.GetAjaxPage()}?do=download&action=policy&id=`,
        validation: {
          maxFiles: 3,
          maxFileSize: 20,
        },
      })
      .on('change fileDeleted', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        this.policy.SetModified(true);
      });

    for (let f = 0; f < this.policy.files.length; f++) {
      this.dom.policyDocLoader.fileloader('preload', this.policy.files[f]);
    }

    cSAMA_GeneralInputs.Staff(this.dom.execSelect, false, 1);

    // Display the exec's photo if we have it.
    let execImageState = this.policy.GetExecImageState();
    let execImage = this.policy.GetExecImage();
    if (execImageState !== SAMA.enums.IMAGESTATES.NONE && execImage.length > 0) {
      this.dom.execPhotoUploader.galLoader('preload', this.policy.GetExecImage());
      // null the image so it won't be re-submitted
      this.policy.SetExecImageChanged(SAMA.enums.IMAGESTATES.UNCHANGED);
    }

    // Set up the signature pad
    let opts = {
      clearCallback: () => this.OnClearExecSignature(),
      inputCallback: () => this.OnInputSignaturePad(),
    };

    this.execSignature = cSAMA_GeneralInputs.SignaturePad(this.dom.execSignaturePad, opts);
    $(window).on('resize.samaevt', () => cSAMA_GeneralInputs.ResizeSignaturePad(this.execSignature));
  }

  OnClearExecSignature() {
    if (this.policy.GetExecSignatureState() === SAMA.enums.IMAGESTATES.NONE) {
      return;
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    this.policy.SetExecSignatureChanged(SAMA.enums.IMAGESTATES.DELETED);
    this.policy.SetModified(true);
  }

  OnInputSignaturePad() {
    if (this.policy.GetExecSignatureState() === SAMA.enums.IMAGESTATES.CHANGED) {
      return;
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    this.policy.SetExecSignatureChanged(SAMA.enums.IMAGESTATES.CHANGED);
    this.policy.SetModified(true);
  }

  FixSigPad() {
    cSAMA_GeneralInputs.ResizeSignaturePad(this.execSignature);
  }

  GetExecSignature() {
    return cSAMA_Utils.Base64ToFile(this.execSignature.toDataURL('image/svg+xml'), 'image/svg+xml');
  }

  SetExecSignature(_data) {
    this.execSignature.fromDataURL(_data);
  }

  GetExecImage() {
    return this.dom.execPhotoUploader.galLoader('getFormEncoded', false);
  }

  GetPolicyFiles() {
    return this.dom.policyDocLoader.fileloader('getFiles');
  }

  UpdateRemotePks(_pks) {
    this.dom.policyDocLoader.fileloader('updateUID', _pks);
  }

  GetDom() {
    return this.dom.page;
  }

  Destroy() {
    this.dom.execSelect.catTagger('destroy');
    this.dom.policyDocLoader.off().fileloader('destroy');
    this.dom.execPhotoUploader.off().galLoader('destroy');
    this.execSignature = null;
    this.dom.execSignaturePad.html('');
    $(window).off('resize.samaevt');
  }
}
