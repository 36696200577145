function StartReportDuty() {
  if (FDT_CUSTOM_RULES_LOADED == false) {
    LoadFDTRules();
  }
  $.mobile.changePage($('#Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $('#Report_Content').html('');
  $('#Report_Controls').html('');
  SUMMARY_ON = false;
  var Controls = '';
  var now = new Date();
  now.setMonth(now.getMonth() - 12);
  Controls += '<div class="ui-grid-a noprint" id="RPT_Filter1">';
  Controls +=
    '<div class="ui-block-a"><label for="RPT_FromDate">' +
    iTrans('From Date') +
    '</label><input type="Date" id="RPT_FromDate" data-mini="true" value="2014-01-01"/></div>';
  Controls +=
    '<div class="ui-block-b"><label for="RPT_ToDate">' +
    iTrans('To Date') +
    '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" /></div></div>';
  Controls +=
    '<button class="greenbg" data-mini="true" onClick="update_Duty_Data();">' +
    iTrans('Update Results') +
    '</button></div>';
  Controls +=
    '<label for="hideWarnings">' +
    iTrans('Hide Warnings') +
    '</label><input type="checkbox" id="hideWarnings" onClick="refresh_Duty_Data()" data-mini="true" />';
  Controls +=
    '<label for="hideExperience">' +
    iTrans('Hide Experience') +
    '</label><input type="checkbox" id="hideExperience" onClick="refresh_Duty_Data()" data-mini="true" checked/>';
  //Controls += '<label for="showLifeExperience">Display Lifetime Experience</label><input type="checkbox" id="showLifeExperience" onClick="refresh_Duty_Data()" data-mini="true" />';
  Controls += '<div id="DutyExperienceOpts"></div>';
  $('#Report_Controls').html(Controls);
  //document.getElementById('RPT_FromDate').valueAsDate = now;
  $('#RPT_FromDate').val(now.toISOString().substring(0, 10));
  //document.getElementById('RPT_ToDate').valueAsDate = new Date();
  $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
  $('#Report_Controls').enhanceWithin();
  HideFDTwarnings = false;
  REPORT_USER_DATA = [];
}

function update_Duty_Data() {
  var count = 0;
  for (var i in Report_Data) {
    var I = Report_Data[i];
    if (I.checked) count++;
  }
  $('#Report_Content').html(
    '<div class="limit-table"><h3 >' +
      iTrans('Processing Report') +
      ' <insettxt id="Report_Content_COUNT">0</insettxt> ' +
      iTrans('of') +
      ' ' +
      count +
      '</h3><button OnClick="CANCEL_FDT_ReportGen()" data-mini="true" class="redbg">' +
      iTrans('Cancel Report Generation') +
      '</button></div>'
  );
  $('#Report_Content').enhanceWithin();
  Report_Duty(0);
}

var DUTYTIME = 'Calendar';
var CANCEL_FDT_ReportGen_STATUS = false;
function CANCEL_FDT_ReportGen() {
  CANCEL_FDT_ReportGen_STATUS = true;
  $('#Report_Content').html('<div class="limit-table"><h3>' + iTrans('Cancelling Report Generation') + '</h3></div>');
}
function PopulateReportAircraftTypes() {
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-Administration-Reports-AJAX.php?report=getCompanyAircraftTypes', function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data.ServerResponse == 'Success') {
        COMPANY_AIRCRAFT_TYPES = json_data.AcTypes;
      } else {
        if (json_data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data[0], 'getSpots');
        }
        if (json_data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      }
    }).fail(function (jqXHR, status, error) {
      AJAXFAIL(error);
    });
  }
  Populate_GROUP_AC_TYPE_DATA();
}

function refresh_Duty_Data() {
  if (REPORT_USER_DATA.length == 0) return;
  Report_DutyV2(REPORT_USER_DATA);
}

var COMPANY_AIRCRAFT_TYPES = [];
var REPORT_USER_DATA = [];

function Report_Duty_ListPilots() {
  var html =
    '<table data-filter="true"><thead><tr><th><input type="checkbox" data-role="none" onClick="SELECT_ALL_FDT_PILOTS(this)" checked /></th><th>' +
    iTrans('Pilot Name') +
    '</th><th>' +
    iTrans('Last Active') +
    '</th><th>' +
    iTrans('Last FDT Sync') +
    '</th></tr></thead><tbody>';

  for (var i in Report_Data) {
    var I = Report_Data[i];
    var CHK =
      '<input type="checkbox" data-role="none" id="FDT_RPT_CHK-' +
      i +
      '" value="' +
      i +
      '" onClick="SELECT_FDT_PILOT(this)" checked />';
    if (I.checked === false) {
      CHK =
        '<input type="checkbox" data-role="none" id="FDT_RPT_CHK-' +
        i +
        '" value="' +
        i +
        '" onClick="SELECT_FDT_PILOT(this)" />';
    }

    html +=
      '<tr><td>' +
      CHK +
      '</td><td>' +
      I.chatname +
      '</td><td> ' +
      (I.LastActive != null ? getLocalDateTime(I.LastActive, null, false).text : 'Never') +
      '</td><td>' +
      (I.LastFDTsync != null ? getLocalDateTime(I.LastFDTsync, null, false).text : 'Never') +
      '</td><tr>';
    I.checked = true;
  }
  html += '</tbody></table>';
  $('#Report_Content').html(
    '<div class="limit-table"><h3>' + iTrans('Select Pilots for Report') + '</h3>' + html + '</div>'
  );
  $('#Report_Content').enhanceWithin();
}

function SELECT_ALL_FDT_PILOTS(e) {
  if (e.checked) {
    for (var i in Report_Data) {
      var I = Report_Data[i];
      $('#FDT_RPT_CHK-' + i).prop('checked', true);
      I.checked = true;
    }
  } else {
    for (var i in Report_Data) {
      var I = Report_Data[i];
      $('#FDT_RPT_CHK-' + i).prop('checked', false);
      I.checked = false;
    }
  }
}

function SELECT_FDT_PILOT(e) {
  if (e.checked) {
    Report_Data[e.value].checked = true;
  } else {
    Report_Data[e.value].checked = false;
  }
}

function Report_Duty(index) {
  if (CANCEL_FDT_ReportGen_STATUS) {
    Report_Duty_ListPilots();
    CANCEL_FDT_ReportGen_STATUS = false;
    return;
  }
  console.log('Processing Index: ' + index);
  var num = parseInt(index) + 1;

  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() - 1 + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate.setUTCDate(ToDate.getUTCDate() + 1);
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  if ($('#hideWarnings').is(':checked')) {
    HideFDTwarnings = true;
  } else {
    HideFDTwarnings = false;
  }

  if (index == Report_Data.length) {
    $('#Report_Content').html('<h3>' + iTrans('Processing Records') + '</h3>');
    Report_DutyV2(REPORT_USER_DATA);
  } else {
    if (Report_Data[index].checked) {
      var curCount = parseInt($('#Report_Content_COUNT').html());
      curCount++;
      $('#Report_Content_COUNT').html(curCount);
      $.getJSON(
        BASEURL +
          'menu-Administration-Reports-AJAX.php?report=Report_DutyV2_Pilot&UserPK=' +
          Report_Data[index].PrimaryKey +
          '&FromDate=' +
          encodeURIComponent($('#RPT_FromDate').val()) +
          '&ToDate=' +
          encodeURIComponent($('#RPT_ToDate').val()),
        function (json_data) {
          REPORT_USER_DATA[index] = json_data;
          var next = parseInt(index) + 1;
          Report_Duty(next);
        }
      ).fail(function (jqXHR, status, error) {
        console.log('Failed to get Report Data');
        $('#Report_Content').html('<h3>' + iTrans('Data Error try again later') + '</h3>');
        AJAXFAIL(error);
        $.mobile.loading('hide');
      });
    } else {
      var next = parseInt(index) + 1;
      Report_Duty(next);
    }
  }
}

function Report_WarningViolationCurrencyTables(D, I, HideFDTwarnings) {
  console.log(D);
  var VWtable =
    '<h2>FDT ' +
    iTrans('Warnings and Violations') +
    '</h2><table class="limit-table"><thead><tr><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Date') +
    '</th></tr></thead><tbody>';
  var VWtext = '';
  var NVG_Report = null;
  var IFR_Report = null;
  var WarnOrViol = false;
  var ViolationDates = [];

  for (var a in D.AdminViolation) {
    var V = D.AdminViolation[a];
    if (V.Status != '' && V.Status != undefined) {
      VWtable +=
        '<tr class="redRecord"><td>' +
        V.Status +
        '</td><td><b>' +
        iTrans('Violation') +
        '</b></td><td>' +
        V.date +
        '</td></tr>';
      VWtext += iTrans('Violation') + ': ' + V.date + ' - ' + V.Status;
      WarnOrViol = true;
      ViolationDates.push({ Status: V.Status, Date: V.date });
    }
  }
  if (!HideFDTwarnings) {
    for (var a in D.AdminWarning) {
      var W = D.AdminWarning[a];
      if (W.Status != '' && W.Status != undefined) {
        if (W.Status.substr(0, 3) == 'NVG') continue;
        VWtable +=
          '<tr class="yellowRecord"><td>' +
          W.Status +
          '</td><td><b>' +
          iTrans('Warning') +
          '</b></td><td>' +
          W.date +
          '</td></tr>';
        VWtext += iTrans('Warning') + ': ' + W.date + ' - ' + W.Status;
        WarnOrViol = true;
      }
    }
  }
  VWtable += '</tbody></table>';
  if (WarnOrViol == false) {
    VWtable = '<h2>' + iTrans('No FDT Warnings and Violations') + '</h2>';
  }
  if (!HideFDTwarnings) {
    if (I.Duty.length > 0) {
      VWtable += '<h2>' + iTrans('Currency Reports') + '</h2>';

      var GenCurrency =
        '<table class="limit-table"><thead><tr><th>' +
        iTrans('Type') +
        '</th><th>' +
        iTrans('Details') +
        '</th><th>' +
        iTrans('Valid Until') +
        '</th></tr></thead><tbody>';
      var C = D.CurrencySummary;

      console.log(I);
      if (I.FDT_NvgCur == 1) {
        if (D.NVG_Report == undefined) {
          //get NVG Currency
          var CurDate = new Date(I.Duty[I.Duty.length - 1].Date + 'T00:00:00Z');

          var FromDate = new Date(CurDate.getTime());
          if (COUNTRY == 'CANADA' || COUNTRY == 'KENYA') {
            //Currency for Canada is 90 days from current date
            FromDate.setDate(FromDate.getDate() - 90);
          } else {
            //Currency for USA / Default all others is 2 calendar months so from beginnin of 2 montsh prior;
            FromDate.setDate(1);
            FromDate.setMonth(FromDate.getMonth() - 2);
            var Difference_In_Time = CurDate.getTime() - FromDate.getTime();
            Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
          }

          var NVG_Recs = [];
          for (var i in I.Recs) {
            var R = I.Recs[i];
            var rDate = new Date(R.Date + 'T00:00:00Z');
            if (rDate.getTime() >= FromDate) NVG_Recs.push(R);
            else break;
          }
          NVG_Report = ProcessNVG_Currency(NVG_Recs, false, { CurUser: { Experience: I.experience } });
        } else {
          NVG_Report = D.NVG_Report;
        }
        if (NVG_Report.Current)
          GenCurrency +=
            '<tr class="greenbg"><td>' +
            iTrans('NVG Currency') +
            '</td><td>' +
            iTrans('Current') +
            '</td><td>' +
            NVG_Report.ValidTo +
            '</td></tr>';
        else
          GenCurrency +=
            '<tr class="yellowbg"><td>' +
            iTrans('NVG Currency') +
            '</td><td>' +
            NVG_Report.ReasonTXT +
            '</td><td>N/A</td></tr>';
      }
      if (I.FDT_IfrCur == 1) {
        //get IFR Currency
        if (D.IFR_Report == undefined) {
          var CurDate = new Date(I.Duty[I.Duty.length - 1].Date + 'T00:00:00Z');

          var FromDate = new Date(CurDate.getTime());

          //Currency for everyone is 180 days from current date
          FromDate.setDate(FromDate.getDate() - 180);

          var IFR_Recs = [];
          for (var i in I.Recs) {
            var R = I.Recs[i];
            var rDate = new Date(R.Date + 'T00:00:00Z');
            if (rDate.getTime() >= FromDate) IFR_Recs.push(R);
            else break;
          }
          IFR_Report = ProcessIFR_Currency(IFR_Recs, false, true);
        } else {
          IFR_Report = D.IFR_Report;
        }

        if (IFR_Report.Current)
          GenCurrency +=
            '<tr class="greenbg"><td>' +
            iTrans('IFR Currency') +
            '</td><td>' +
            iTrans('Current') +
            '</td><td>' +
            IFR_Report.ValidTo +
            '</td></tr>';
        else
          GenCurrency +=
            '<tr class="yellowbg"><td>' +
            iTrans('IFR Currency') +
            '</td><td>' +
            IFR_Report.ReasonTXT +
            '</td><td>N/A</td></tr>';
      }

      GenCurrency += '</tbody></table>';
      if (I.FDT_IfrCur == 1 || I.FDT_NvgCur == 1) VWtable += GenCurrency + '<br />';
      if (D.AC_CURRENCY == undefined) {
        var curR = CheckAC_Currency(I.Recs, I.Duty[I.Duty.length - 1].Rule);
        VWtable += getAC_CurrencyHTML(curR);
      } else {
        VWtable += D.AC_CURRENCY;
        var curR = D.AC_CurrencyData;
      }
    }
  }

  return {
    VWtable: VWtable,
    VWtext: VWtext,
    NVG_Report: NVG_Report,
    IFR_Report: IFR_Report,
    AC_Currency: curR,
    TL_Currency: D.CurrencySummary,
    ViolationDates: ViolationDates,
  };
}

function Report_DutyV2(Report_Data) {
  console.log(Report_Data);
  if ($('#hideWarnings').is(':checked')) {
    HideFDTwarnings = true;
  } else {
    HideFDTwarnings = false;
  }
  $('#Report_Content').html('<h3>' + iTrans('Creating Report') + '.</h3>');
  $.mobile.loading('hide');
  if (LITE == '1') {
    $('#Title_Report_Name').html(iTrans('Logbook Report'));
  } else {
    $('#Title_Report_Name').html(iTrans('Pilot Duty Times'));
  }

  DUTYTIME = 'REPORT'; //global to prevent client database record marking when it doesn't exist (menu-Duty-SHARED)
  $('#Report_Content').html('');
  var AcTypeList = [];
  for (var AC in COMPANY_AIRCRAFT_TYPES) {
    AcTypeList.push(COMPANY_AIRCRAFT_TYPES[AC].List_Type);
  }
  $('#DutyExperienceOpts').html('');

  var warnText = ' / ' + iTrans('Warnings');
  if (HideFDTwarnings) {
    warnText = '';
  }
  var html;
  var CSV = '';
  //AcTypeList = ["Beechcraft 1900 Series", "Bell 206L Series", "Bell 407 Series", "AS350 Series"]; //static list for
  // dev
  var AcHeaders = '';
  var blankShim = '';
  for (var H in AcTypeList) {
    if (AcTypeList[H] != null) {
      AcHeaders += '<th>' + AcTypeList[H].slice(0, -7) + '</th>';
      blankShim += '<td>&ndash;</td>';
    }
  }
  var ExperienceTypes = {
    DR: iTrans('Dual'),
    PIC: iTrans('PIC'),
    SIC: iTrans('SIC'),
    FI: iTrans('Instruction'),
    DAY: iTrans('Day'),
    NIGHT: iTrans('Night'),
    XC: iTrans('Cross Country'),
    AI: iTrans('IFR'),
    SI: iTrans('SIM'),
    GA: iTrans('Precision Approach'),
    DAT: iTrans('Day Takeoff'),
    NT: iTrans('Night Takeoff'),
    IA: iTrans('NonPrecision Approach'),
    DL: iTrans('Day Landing'),
    NL: iTrans('Night Landing'),
  };
  var ExpKeys = Object.keys(ExperienceTypes);
  for (var H in ExperienceTypes) {
    AcHeaders += '<th>' + ExperienceTypes[H] + '</th>';
    blankShim += '<td></td>';
  }

  let Specialty = {};
  let FloatRating = Report_Data.FDT_FloatRating;
  for (const c in FDT_SPECIALTYV3.CS_Recs) {
    const C = FDT_SPECIALTYV3.CS_Recs[c];
    for (const f in C.Fields) {
      if ((C.Fields[f].Field == 'FTO' && FloatRating == 1) || (C.Fields[f].Field == 'FLD' && FloatRating == 1)) {
        C.Fields[f].Used = 1; //to force showing Float landing and Takeoffs in admin reports
      }
      if (C.Fields[f].Used == 1) {
        AcHeaders += '<th>' + C.Fields[f].Label + '</th>';
        blankShim += '<td></td>';
        Specialty[C.Fields[f].Field] = C.Fields[f].Label;
      }
    }
  }

  var SpecKeys = Object.keys(Specialty);

  if ($('#hideExperience').is(':checked')) {
    AcTypeList = [];
    AcHeaders = '';
    blankShim = '';
    Specialty = {};
    ExperienceTypes = {};
    ExpKeys = [];
    SpecKeys = [];
  }
  var showLifetime = false;
  //if ($("#showLifeExperience").is(":checked")){
  //showLifetime = true;
  //}
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  if (COUNTRY == 'CANADA' || LITE == 1) {
    var arrValues = [
      iTrans('Pilot'),
      iTrans('Last Login'),
      iTrans('Last Sync'),
      iTrans('Next Mandatory Day Off'),
      iTrans('FDT Date'),
      iTrans('On'),
      iTrans('Start'),
      iTrans('End'),
      iTrans('Off'),
      iTrans('Flight Time'),
      iTrans('Duty Time'),
      iTrans('Other Duty Time'),
      iTrans('Rest Time'),
      iTrans('Ident'),
      '7',
      '28',
      '30',
      '42',
      '90',
      '180',
      '365',
      'DT7',
      'DT28',
      'DT365',
      'HW7',
      'HW28',
      'HW365',
    ];

    for (var H in AcTypeList) {
      if (AcTypeList[H] != null) {
        arrValues.push(AcTypeList[H].slice(0, -7));
      }
    }
    for (var H in ExperienceTypes) {
      arrValues.push(ExperienceTypes[H]);
    }
    for (var H in Specialty) {
      arrValues.push(Specialty[H]);
    }
    arrValues.push(iTrans('Violations') + ' ' + warnText);
    arrValues.push(iTrans('Flight Days'));
    arrValues.push(iTrans('Duty Days'));
    arrValues.push(iTrans('Total Days'));

    CSV += IterateCSV(arrValues, CSV);

    html = `<page>
    <div data-testid="fdt-summary" id="FDTSUMMARYPDF" style="max-width: 100%; overflow: auto; max-height: 80vh;" class="theadwrapper">
        <table data-filter="true" class="table fdtsummary" border="1">
            <thead>
            <tr>
                <th>${iTrans('View')}</th>
                <th data-priority="1">${iTrans('Pilot')}</th>
                <th>${iTrans('Next Mandatory Day Off')}</th>
                <th>7</th>
                <th>28</th>
                <th>30</th>
                <th>42</th>
                <th>90</th>
                <th>180</th>
                <th>365</th>
                <th>DT7</th>
                <th>DT28</th>
                <th>DT365</th>
                <th>HW7</th>
                <th>HW28</th>
                <th>HW365</th>
                <th>${iTrans('Flt Days')}</th>
                <th>${iTrans('Dty Days')}</th>
                <th>${iTrans('Total Days')}</th>
                <th>${iTrans('Violations')}${warnText} Last 365 Days</th>
                <th>${iTrans('Last Login')}</th>
                <th>${iTrans('Last Sync')}</th>
                <th>${iTrans('FDT Date')}</th>
                <th>${iTrans('Start Wk')}</th>
                <th>${iTrans('Start')}</th>
                <th>${iTrans('End')}</th>
                <th>${iTrans('End Wk')}</th>
                <th>${iTrans('Flt')}</th>
                <th>${iTrans('Wk')}</th>
                <th>FDP</th>
                <th>RT</th>
                <th>${iTrans('Ident')}</th>
                ${AcHeaders}
                <th>${iTrans('View')}</th>
            </tr>
            </thead>
            <tbody>`;
    var UserPK = '';
    if (Report_Data.length > 0) {
      for (var i in Report_Data) {
        var I = Report_Data[i];
        UserPK = I.UserPK;
        if (I.checked == false) continue;
        REPORT_DayCur = I.FDT_DayCur;
        REPORT_NightCur = I.FDT_NightCur;
        REPORT_IFRCur = I.FDT_IfrCur;
        REPORT_FloatCur = I.FDT_FloatRating;

        var D = UserDuty(
          I.Duty,
          I.LastSync,
          I.experience,
          I.TotalExperience,
          I.List_TypeExperience,
          I.AcTypeExperience,
          I.ClassExperience,
          FromDate,
          ToDate,
          I.TotalExperience12,
          I.List_TypeExperience12,
          I.ClassExperience12,
          I.AcTypeExperience12,
          I
        );

        var WVCT = Report_WarningViolationCurrencyTables(D, I, HideFDTwarnings);
        var VWtable = WVCT.VWtable;
        var VWtext = WVCT.VWtext;

        var LastLogin = getLocalDateTime(I.LastLogin);
        var LastSync = getLocalDateTime(I.LastSync);

        if (D != false) {
          var ExtendInfo = '<b>' + I.chatname + '</b>';
          var ExtendText = I.chatname;
          if (D.ExtendON == 1 && D.Extendable == 0) {
            ExtendInfo += '<br />' + iTrans('Duty Extended ') + '' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
            ExtendText += ' - ' + iTrans('Duty Extended') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
          }
          if (D.ExtendON == 0 && D.Extendable == 1) {
            ExtendInfo += '<br />' + iTrans('Extension Available') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
            ExtendText += ' - ' + iTrans('Extension Available') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
          }
          if (D.ExtendON == 0 && D.Extendable == 0) {
            ExtendInfo += '<br />' + iTrans('NOT Extendable') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
            ExtendText += ' - ' + iTrans('NOT Extendable') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
          }
          var On = D.On;
          var Start = D.Start;
          var End = D.End;
          var Off = D.Off;
          var FT = D.FT;
          if (D.Split == 1) {
            On = D.On + '<br />' + D.On2;
            Start = D.Start + '<br />' + D.Start2;
            End = D.End + '<br />' + D.End2;
            Off = D.Off + '<br />' + D.Off2;
            FT = D.FT + '<br />' + D.FT2;
          }
          var MaxTimeTxt;

          if (D.MaxNextFltTimes.MaxTime == 'Unknown') {
            MaxTimeTxt = '<b>' + iTrans('Unknown Next Day FT') + '</b>';
          } else {
            if (D.MaxNextFltTimes.MaxTime < 0) {
              MaxTimeTxt =
                '<b>' +
                iTrans('Cannot fly tomorrow') +
                '</b> ' +
                Math.abs(D.MaxNextFltTimes.MaxTime) +
                ' hrs ' +
                iTrans('beyond') +
                ' - ' +
                D.MaxNextFltTimes.Limiter +
                ' ' +
                iTrans('Limit') +
                '';
            } else {
              MaxTimeTxt =
                '<b>' +
                iTrans('Max Next Day FT') +
                ':</b> ' +
                D.MaxNextFltTimes.MaxTime +
                ' hrs - ' +
                D.MaxNextFltTimes.Limiter +
                ' ' +
                iTrans('Limit') +
                '';
            }
          }
          var arrValues = [
            ExtendText,
            I.LastLogin,
            I.LastSync,
            D.Noff.date.toDateString(),
            D.Date + '\n' + MaxTimeTxt,
            On,
            Start,
            End,
            Off,
            FT,
            D.DT,
            D.HW,
            D.RT,
            D.IDENT,
            D.L7,
            D.L28,
            D.L30,
            D.L42,
            D.L90,
            D.L180,
            D.L365,
            D.DT7,
            D.DT28,
            D.DT365,
            D.HW7,
            D.HW28,
            D.HW365,
          ];

          var PeriodAircaft = '';
          if (showLifetime) {
            var TotalTime = D.Life_TotalExperience.FTT;
            TotalTime = Math.round(TotalTime * 10) / 10;
            //arrValues.push(TotalTime);
            for (var H in AcTypeList) {
              if (AcTypeList[H] != null) {
                if (AcTypeList[H] in D.Life_TypeExperience) {
                  var TFT = D.Life_TypeExperience[AcTypeList[H]].FTT;
                  TFT = Math.round(TFT * 10) / 10;
                  PeriodAircaft += '<td>' + TFT + '</td>';
                  arrValues.push(TFT);
                } else {
                  PeriodAircaft += '<td>0</td>';
                  arrValues.push(0);
                }
              }
            }
            for (var H in ExpKeys) {
              var Exp_VAL = D.Life_TotalExperience[ExpKeys[H]];
              Exp_VAL = Math.round(Exp_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Exp_VAL + '</td>';
              arrValues.push(Exp_VAL);
            }
            for (var H in SpecKeys) {
              var Spec_VAL = D.Life_TotalExperience[SpecKeys[H]];
              Spec_VAL = Math.round(Spec_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Spec_VAL + '</td>';
              arrValues.push(Spec_VAL);
            }
          } else {
            var TotalTime = D.TotalExperience.FTT;
            TotalTime = Math.round(TotalTime * 10) / 10;
            //arrValues.push(TotalTime);
            for (var H in AcTypeList) {
              if (AcTypeList[H] != null) {
                if (AcTypeList[H] in D.TypeExperience) {
                  var TFT = D.TypeExperience[AcTypeList[H]].FTT;
                  TFT = Math.round(TFT * 10) / 10;
                  PeriodAircaft += '<td>' + TFT + '</td>';
                  arrValues.push(TFT);
                } else {
                  PeriodAircaft += '<td>0</td>';
                  arrValues.push(0);
                }
              }
            }
            for (var H in ExpKeys) {
              var Exp_VAL = D.TotalExperience[ExpKeys[H]];
              Exp_VAL = Math.round(Exp_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Exp_VAL + '</td>';
              arrValues.push(Exp_VAL);
            }
            for (var H in SpecKeys) {
              var Spec_VAL = D.TotalExperience[SpecKeys[H]];
              Spec_VAL = Math.round(Spec_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Spec_VAL + '</td>';
              arrValues.push(Spec_VAL);
            }
          }

          html += `
<tr>
    <td>
        <button class="RPTduty-${i} action-btn"><img src="./images/inspect.svg" /></button>
    </td>
    <td>${ExtendInfo}</td>
    <td>${D.Noff.Text}</td>
    <td>${D.L7}</td>
    <td>${D.L28}</td>
    <td>${D.L30}</td>
    <td>${D.L42}</td>
    <td>${D.L90}</td>
    <td>${D.L180}</td>
    <td>${D.L365}</td>
    <td>${D.DT7}</td>
    <td>${D.DT28}</td>
    <td>${D.DT365}</td>
    <td>${D.HW7}</td>
    <td>${D.HW28}</td>
    <td>${D.HW365}</td>
    <td>${D.FltDays}</td>
    <td>${D.DtyDays}</td>
    <td>${D.TotalDays}</td>
    <td>${VWtable}</td>
    <td>${LastLogin.text !== 'NULL' ? LastLogin.text : '' + iTrans('Never') + ''}<br />${LastLogin.FromNow || ''}</td>
    <td>${LastSync.text !== 'NULL' ? LastSync.text : '' + iTrans('Never') + ''}<br />${LastSync.FromNow || ''}</td>
    <td>${D.Date}<br />${MaxTimeTxt}</td>
    <td>${On}</td>
    <td>${Start}</td>
    <td>${End}</td>
    <td>${Off}</td>
    <td>${FT}</td>
    <td>${D.DT}</td>
    <td>${D.HW}</td>
    <td>${D.RT}</td>
    <td>${D.IDENT}</td>
    ${PeriodAircaft}
    <td>
        <button class="RPTduty-${i} action-btn"><img src="./images/inspect.svg" /></button>
    </td>
</tr>`;

          arrValues.push(VWtext);
          arrValues.push(D.FltDays);
          arrValues.push(D.DtyDays);
          arrValues.push(D.TotalDays);

          CSV += IterateCSV(arrValues, CSV);
        } else {
          html += `
<tr>
    <td></td>
    <td>
        <strong>${I.chatname}</strong> <br>
        ${iTrans('No Duty Data Synced')}
    </td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>-</td>
    <td>${LastLogin.text !== 'NULL' ? LastLogin.text : '' + iTrans('Never') + ''}<br />${LastLogin.FromNow || ''}</td>
    <td>${LastSync.text !== 'NULL' ? LastSync.text : '' + iTrans('Never') + ''}<br />${LastSync.FromNow || ''}</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    ${blankShim}
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
</tr>`;

          var arrValues = [
            I.chatname,
            I.LastLogin,
            I.LastSync,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
          ];

          for (var H in AcTypeList) {
            if (AcTypeList[H] != null) {
              arrValues.push('');
            }
          }
          for (var H in ExpKeys) {
            arrValues.push('');
          }
          for (var H in SpecKeys) {
            arrValues.push('');
          }
          arrValues.push(iTrans('No Duty Data Synced'));
          arrValues.push('');
          arrValues.push('');

          CSV += IterateCSV(arrValues, CSV);
        }
      }
      html += '</tbody></table></div></page>';
      //REPORT_HTML_PDF = html;

      html +=
        '<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportDuty_CSV_BTN">' +
        iTrans('Download CSV Data') +
        '</a></div>';

      //html += '<button onClick="GeneratePDF()">GeneratePDF</button>';

      GENERATE_PDF.filename = 'FDT';
      GENERATE_PDF.showPDF = 1;
      GENERATE_PDF.silent = 0;
      GENERATE_PDF.Type = 'FlightDuty';
      GENERATE_PDF.subject = 'FlightDuty';
      GENERATE_PDF.email = '';
      GENERATE_PDF.div = 'FDTSUMMARYPDF';
      GENERATE_PDF.ClientEmail = '';

      $('#Report_Content').html(html).enhanceWithin();

      var $FloatHeaders = $('table.fdtsummary');
      $FloatHeaders.floatThead({
        scrollContainer: function ($FloatHeaders) {
          return $FloatHeaders.closest('.theadwrapper');
        },
      });
      $(window).trigger('resize');
      for (i in Report_Data) {
        if (!Report_Data.hasOwnProperty(i)) {
          continue;
        }

        (function (_i) {
          const dutyButtons = $('.RPTduty-' + _i);
          if (dutyButtons.length > 0) {
            dutyButtons.on('click', () => showDutyTimes(_i));
          }
        })(i);
      }

      DownloadCSV_DATA = CSV;

      $('#exportDuty_CSV_BTN').on('click', function (event) {
        var data;
        var now = new Date();
        var filename =
          'Cirro_FDT_Summary_Report_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '.csv';
        if (device.platform.toUpperCase() === 'ANDROID') {
          console.log('Opening base64 Android');
          event.preventDefault();
          LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
        } else if (IOS) {
          console.log('Opening base64 IOS');
          event.preventDefault();
          LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
        } else {
          console.log('Opening base64 Universal URL');
          var a = document.createElement('a');

          if (typeof a.download != 'undefined') {
            data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
            // set attributes href and target in the <a> element (with id  exportGPX)
            $(this).attr({
              href: data,
              target: '_blank',
              download: filename,
            });
            // let the click go through
          } else {
            // download attribute is not supported
            data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

            // set attributes href and target in the <a> element (with id  exportGPX)
            $(this).attr({
              href: data,
              target: '_system',
            });
          }
        }
      });
    } else {
      $('#Report_Content').html('<h3>' + iTrans('') + 'No Pilot Flight Duty Times Available!</h3>');
    }
  } else if (COUNTRY == 'KENYA') {
    var arrValues = [
      iTrans('Pilot'),
      iTrans('Last Login'),
      iTrans('Last Sync'),
      iTrans('FDT Date'),
      iTrans('Start'),
      iTrans('End'),
      iTrans('Flight Time'),
      iTrans('Duty Time'),
      iTrans('Other Duty Time'),
      iTrans('Rest Time'),
      iTrans('Ident'),
      '72h',
      'DT28d',
      '28d',
      '90d',
      '365d',
    ];

    for (var H in AcTypeList) {
      if (AcTypeList[H] != null) {
        arrValues.push(AcTypeList[H].slice(0, -7));
      }
    }
    for (var H in ExperienceTypes) {
      arrValues.push(ExperienceTypes[H]);
    }
    for (var H in Specialty) {
      arrValues.push(Specialty[H]);
    }
    arrValues.push(iTrans('Violations') + ' ' + warnText);
    arrValues.push(iTrans('Flight Days'));
    arrValues.push(iTrans('Duty Days'));
    arrValues.push(iTrans('Total Days'));

    CSV += IterateCSV(arrValues, CSV);

    html = `<page>
    <div id="FDTSUMMARYPDF" style="max-width: 100%; overflow: auto; max-height: 80vh;" class="theadwrapper">
        <table data-filter="true" class="table fdtsummary" border="1">
            <thead>
            <tr>
                <th>${iTrans('View')}</th>
                <th data-priority="1">${iTrans('Pilot')}</th>
                <th>72h</th>
                <th>DT28d</th>
                <th>28d</th>
                <th>90d</th>
                <th>365d</th>
                <th>${iTrans('Flt Days')}</th>
                <th>${iTrans('Dty Days')}</th>
                <th>${iTrans('Total Days')}</th>
                <th>${iTrans('Violations')}${warnText} Last 365 Days</th>
                <th>${iTrans('Last Login')}</th>
                <th>${iTrans('Last Sync')}</th>
                <th>${iTrans('FDT Date')}</th>
                <th>${iTrans('Start')}</th>
                <th>${iTrans('End')}</th>
                <th>${iTrans('Flt')}</th>
                <th>${iTrans('Dty')}</th>
                <th>ODty</th>
                <th>RT</th>
                <th>${iTrans('Ident')}</th>
                ${AcHeaders}
                <th>${iTrans('View')}</th>
            </tr>
            </thead>
            <tbody>`;
    var UserPK = '';
    if (Report_Data.length > 0) {
      for (var i in Report_Data) {
        var I = Report_Data[i];
        UserPK = I.UserPK;
        if (I.checked == false) continue;
        REPORT_DayCur = I.FDT_DayCur;
        REPORT_NightCur = I.FDT_NightCur;
        REPORT_IFRCur = I.FDT_IfrCur;
        REPORT_FloatCur = I.FDT_FloatRating;

        var D = UserDuty(
          I.Duty,
          I.LastSync,
          I.experience,
          I.TotalExperience,
          I.List_TypeExperience,
          I.AcTypeExperience,
          I.ClassExperience,
          FromDate,
          ToDate,
          I.TotalExperience12,
          I.List_TypeExperience12,
          I.ClassExperience12,
          I.AcTypeExperience12,
          I
        );

        var WVCT = Report_WarningViolationCurrencyTables(D, I, HideFDTwarnings);
        var VWtable = WVCT.VWtable;
        var VWtext = WVCT.WVtext;

        var LastLogin = getLocalDateTime(I.LastLogin);
        var LastSync = getLocalDateTime(I.LastSync);

        if (D != false) {
          var ExtendInfo = '<b>' + I.chatname + '</b>';
          var ExtendText = I.chatname;
          if (D.ExtendON == 1 && D.Extendable == 0) {
            ExtendInfo += '<br />' + iTrans('Duty Extended ') + '' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
            ExtendText += ' - ' + iTrans('Duty Extended') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
          }
          if (D.ExtendON == 0 && D.Extendable == 1) {
            ExtendInfo += '<br />' + iTrans('Extension Available') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
            ExtendText += ' - ' + iTrans('Extension Available') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
          }
          if (D.ExtendON == 0 && D.Extendable == 0) {
            ExtendInfo += '<br />' + iTrans('NOT Extendable') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
            ExtendText += ' - ' + iTrans('NOT Extendable') + ' ' + D.ExtendNum + '/6 ' + iTrans('Used') + '';
          }
          var Start = D.Start;
          var End = D.End;
          var FT = D.FT;
          if (D.Split == 1) {
            Start = D.Start + '<br />' + D.Start2;
            End = D.End + '<br />' + D.End2;
            FT = D.FT + '<br />' + D.FT2;
          }
          var MaxTimeTxt;

          if (D.MaxNextFltTimes.MaxTime == 'Unknown') {
            MaxTimeTxt = '<b>' + iTrans('Unknown Next Day FT') + '</b>';
          } else {
            if (D.MaxNextFltTimes.MaxTime < 0) {
              MaxTimeTxt =
                '<b>' +
                iTrans('Cannot fly tomorrow') +
                '</b> ' +
                Math.abs(D.MaxNextFltTimes.MaxTime) +
                ' hrs ' +
                iTrans('beyond') +
                ' - ' +
                D.MaxNextFltTimes.Limiter +
                ' ' +
                iTrans('Limit') +
                '';
            } else {
              MaxTimeTxt =
                '<b>' +
                iTrans('Max Next Day FT') +
                ':</b> ' +
                D.MaxNextFltTimes.MaxTime +
                ' hrs - ' +
                D.MaxNextFltTimes.Limiter +
                ' ' +
                iTrans('Limit') +
                '';
            }
          }
          var arrValues = [
            ExtendText,
            I.LastLogin,
            I.LastSync,
            D.Noff.date.toDateString(),
            D.Date + '\n' + MaxTimeTxt,
            Start,
            End,
            FT,
            D.DT,
            D.ODT,
            D.RT,
            D.IDENT,
            D.L3,
            D.DT28,
            D.L28,
            D.L90,
            D.L365,
          ];

          var PeriodAircaft = '';
          if (showLifetime) {
            var TotalTime = D.Life_TotalExperience.FTT;
            TotalTime = Math.round(TotalTime * 10) / 10;
            //arrValues.push(TotalTime);
            for (var H in AcTypeList) {
              if (AcTypeList[H] != null) {
                if (AcTypeList[H] in D.Life_TypeExperience) {
                  var TFT = D.Life_TypeExperience[AcTypeList[H]].FTT;
                  TFT = Math.round(TFT * 10) / 10;
                  PeriodAircaft += '<td>' + TFT + '</td>';
                  arrValues.push(TFT);
                } else {
                  PeriodAircaft += '<td>0</td>';
                  arrValues.push(0);
                }
              }
            }
            for (var H in ExpKeys) {
              var Exp_VAL = D.Life_TotalExperience[ExpKeys[H]];
              Exp_VAL = Math.round(Exp_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Exp_VAL + '</td>';
              arrValues.push(Exp_VAL);
            }
            for (var H in SpecKeys) {
              var Spec_VAL = D.Life_TotalExperience[SpecKeys[H]];
              Spec_VAL = Math.round(Spec_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Spec_VAL + '</td>';
              arrValues.push(Spec_VAL);
            }
          } else {
            var TotalTime = D.TotalExperience.FTT;
            TotalTime = Math.round(TotalTime * 10) / 10;
            //arrValues.push(TotalTime);
            for (var H in AcTypeList) {
              if (AcTypeList[H] != null) {
                if (AcTypeList[H] in D.TypeExperience) {
                  var TFT = D.TypeExperience[AcTypeList[H]].FTT;
                  TFT = Math.round(TFT * 10) / 10;
                  PeriodAircaft += '<td>' + TFT + '</td>';
                  arrValues.push(TFT);
                } else {
                  PeriodAircaft += '<td>0</td>';
                  arrValues.push(0);
                }
              }
            }
            for (var H in ExpKeys) {
              var Exp_VAL = D.TotalExperience[ExpKeys[H]];
              Exp_VAL = Math.round(Exp_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Exp_VAL + '</td>';
              arrValues.push(Exp_VAL);
            }
            for (var H in SpecKeys) {
              var Spec_VAL = D.TotalExperience[SpecKeys[H]];
              Spec_VAL = Math.round(Spec_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Spec_VAL + '</td>';
              arrValues.push(Spec_VAL);
            }
          }

          html += `
<tr>
    <td>
        <button class="RPTduty-${i} action-btn"><img src="./images/inspect.svg" /></button>
    </td>
    <td>${ExtendInfo}</td>
    <td>${D.L3}</td>
    <td>${D.DT28}</td>
    <td>${D.L28}</td>
    <td>${D.L90}</td>
    <td>${D.L365}</td>
    <td>${D.FltDays}</td>
    <td>${D.DtyDays}</td>
    <td>${D.TotalDays}</td>
    <td>${VWtable}</td>
    <td>${LastLogin.text !== 'NULL' ? LastLogin.text : '' + iTrans('Never') + ''}<br />${LastLogin.FromNow || ''}</td>
    <td>${LastSync.text !== 'NULL' ? LastSync.text : '' + iTrans('Never') + ''}<br />${LastSync.FromNow || ''}</td>
    <td>${D.Date}<br />${MaxTimeTxt}</td>
    <td>${Start}</td>
    <td>${End}</td>
    <td>${FT}</td>
    <td>${D.DT}</td>
    <td>${D.ODT}</td>
    <td>${D.RT}</td>
    <td>${D.IDENT}</td>
    ${PeriodAircaft}
    <td>
        <button class="RPTduty-${i} action-btn"><img src="./images/inspect.svg" /></button>
    </td>
</tr>`;

          arrValues.push(VWtext);
          arrValues.push(D.FltDays);
          arrValues.push(D.DtyDays);
          arrValues.push(D.TotalDays);

          CSV += IterateCSV(arrValues, CSV);
        } else {
          html += `
<tr>
    <td></td>
    <td>
        <strong>${I.chatname}</strong> <br>
        ${iTrans('No Duty Data Synced')}
    </td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>-</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>${LastLogin.text !== 'NULL' ? LastLogin.text : '' + iTrans('Never') + ''}<br />${LastLogin.FromNow || ''}</td>
    <td>${LastSync.text !== 'NULL' ? LastSync.text : '' + iTrans('Never') + ''}<br />${LastSync.FromNow || ''}</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    ${blankShim}
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
    <td>&ndash;</td>
</tr>`;

          var arrValues = [I.chatname, I.LastLogin, I.LastSync, '', '', '', '', '', '', '', '', '', '', '', '', '', ''];

          for (var H in AcTypeList) {
            if (AcTypeList[H] != null) {
              arrValues.push('');
            }
          }
          for (var H in ExpKeys) {
            arrValues.push('');
          }
          for (var H in SpecKeys) {
            arrValues.push('');
          }
          arrValues.push(iTrans('No Duty Data Synced'));
          arrValues.push('');
          arrValues.push('');

          CSV += IterateCSV(arrValues, CSV);
        }
      }
      html += '</tbody></table></div></page>';
      //REPORT_HTML_PDF = html;

      html +=
        '<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportDuty_CSV_BTN">' +
        iTrans('Download CSV Data') +
        '</a></div>';

      //html += '<button onClick="GeneratePDF()">GeneratePDF</button>';

      GENERATE_PDF.filename = 'FDT';
      GENERATE_PDF.showPDF = 1;
      GENERATE_PDF.silent = 0;
      GENERATE_PDF.Type = 'FlightDuty';
      GENERATE_PDF.subject = 'FlightDuty';
      GENERATE_PDF.email = '';
      GENERATE_PDF.div = 'FDTSUMMARYPDF';
      GENERATE_PDF.ClientEmail = '';

      $('#Report_Content').html(html).enhanceWithin();

      var $FloatHeaders = $('table.fdtsummary');
      $FloatHeaders.floatThead({
        scrollContainer: function ($FloatHeaders) {
          return $FloatHeaders.closest('.theadwrapper');
        },
      });
      $(window).trigger('resize');
      for (i in Report_Data) {
        if (!Report_Data.hasOwnProperty(i)) {
          continue;
        }

        (function (_i) {
          const dutyButtons = $('.RPTduty-' + _i);
          if (dutyButtons.length > 0) {
            dutyButtons.on('click', () => showDutyTimes(_i));
          }
        })(i);
      }

      DownloadCSV_DATA = CSV;

      $('#exportDuty_CSV_BTN').on('click', function (event) {
        var data;
        var now = new Date();
        var filename =
          'Cirro_FDT_Summary_Report_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '.csv';
        if (device.platform.toUpperCase() === 'ANDROID') {
          console.log('Opening base64 Android');
          event.preventDefault();
          LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
        } else if (IOS) {
          console.log('Opening base64 IOS');
          event.preventDefault();
          LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
        } else {
          console.log('Opening base64 Universal URL');
          var a = document.createElement('a');

          if (typeof a.download != 'undefined') {
            data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
            // set attributes href and target in the <a> element (with id  exportGPX)
            $(this).attr({
              href: data,
              target: '_blank',
              download: filename,
            });
            // let the click go through
          } else {
            // download attribute is not supported
            data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

            // set attributes href and target in the <a> element (with id  exportGPX)
            $(this).attr({
              href: data,
              target: '_system',
            });
          }
        }
      });
    } else {
      $('#Report_Content').html('<h3>' + iTrans('') + 'No Pilot Flight Duty Times Available!</h3>');
    }
  } else {
    //all other countries us USA reporting template
    var arrValues = [
      iTrans('Pilot'),
      iTrans('Last Login'),
      iTrans('Last Sync'),
      iTrans('Next Mandatory Day Off'),
      iTrans('FDT Date'),
      iTrans('Start'),
      iTrans('End'),
      iTrans('Flight Time'),
      iTrans('Duty Time'),
      iTrans('Other Duty Time'),
      iTrans('Rest Time'),
      iTrans('Ident'),
      'CurQ',
      'CurQR',
      'Last2Q',
      'Cal Year',
      'TT',
    ];

    for (var H in AcTypeList) {
      if (AcTypeList[H] != null) {
        arrValues.push(AcTypeList[H].slice(0, -7));
      }
    }
    for (var H in ExperienceTypes) {
      arrValues.push(ExperienceTypes[H]);
    }
    for (var H in Specialty) {
      arrValues.push(Specialty[H]);
    }
    arrValues.push('Violations' + warnText);
    arrValues.push('Flight Days');
    arrValues.push('Duty Days');
    arrValues.push('Total Days');
    CSV += IterateCSV(arrValues, CSV);

    html = `<div id="FDTSUMMARYPDF">
    <div style="max-width: 100%; overflow: auto; max-height: 80vh;" class="theadwrapper">
        <table data-filter="false" class="table fdtsummary" border="1">
            <thead>
            <tr>
                <th>${iTrans('View')}</th>
                <th data-priority="1">${iTrans('Pilot')}</th>
                <th>${iTrans('Last Login')}</th>
                <th>${iTrans('Last Sync')}</th>
                <th>${iTrans('Next Mandatory Day Off')}</th>
                <th>${iTrans('FDT Date')}</th>
                <th>${iTrans('Start')}</th>
                <th>${iTrans('End')}</th>
                <th>Flt</th>
                <th>Dty</th>
                <th>ODty</th>
                <th>RT</th>
                <th>${iTrans('Ident')}</th>
                <th>CurQ</th>
                <th>CurQR</th>
                <th>Last2Q</th>
                <th>Cal Year</th>
                <th>TT</th>
                ${AcHeaders}
                <th>${iTrans('Violations')}${warnText} Last 365 Days</th>
                <th>Flt</th>
                <th>Dty</th>
                <th>${iTrans('Total')}</th>
                <th>${iTrans('View')}</th>
            </tr>
            </thead>
            <tbody>`;

    if (Report_Data.length > 0) {
      for (var i in Report_Data) {
        var I = Report_Data[i];
        if (I.checked == false) continue;
        REPORT_DayCur = I.FDT_DayCur;
        REPORT_NightCur = I.FDT_NightCur;
        REPORT_IFRCur = I.FDT_IfrCur;
        REPORT_FloatCur = I.FDT_FloatRating;
        var D = UserDuty(
          I.Duty,
          I.LastSync,
          I.experience,
          I.TotalExperience,
          I.List_TypeExperience,
          I.AcTypeExperience,
          I.ClassExperience,
          FromDate,
          ToDate,
          I.TotalExperience12,
          I.List_TypeExperience12,
          I.ClassExperience12,
          I.AcTypeExperience12,
          I
        );

        var WVCT = Report_WarningViolationCurrencyTables(D, I, HideFDTwarnings);
        var VWtable = WVCT.VWtable;
        var VWtext = WVCT.WVtext;
        var LastLogin = getLocalDateTime(I.LastLogin);
        var LastSync = getLocalDateTime(I.LastSync);

        if (D != false) {
          var Start = D.Start;
          var End = D.End;
          var FT = D.FT;
          if (D.Split == 1) {
            Start = D.Start + '<br />' + D.Start2;
            End = D.End + '<br />' + D.End2;
            FT = D.FT + '<br />' + D.FT2;
          }
          var ExtendInfo = '<b>' + I.chatname + '</b>';
          var ExtendText = I.chatname;
          console.log(D);
          var arrValues = [
            ExtendText,
            I.LastLogin,
            I.LastSync,
            D.Noff.date.toDateString(),
            D.Date,
            Start,
            End,
            FT,
            D.DT,
            D.ODT,
            D.RT,
            D.IDENT,
            D.lastQ,
            D.curQR,
            D.last2Q,
            D.CalYear,
          ];
          var PeriodAircaft = '';
          if (showLifetime) {
            var TotalTime = D.Life_TotalExperience.FTT;
            TotalTime = Math.round(TotalTime * 10) / 10;
            arrValues.push(TotalTime);
            for (var H in AcTypeList) {
              if (AcTypeList[H] != null) {
                if (AcTypeList[H] in D.Life_TypeExperience) {
                  var TFT = D.Life_TypeExperience[AcTypeList[H]].FTT;
                  TFT = Math.round(TFT * 10) / 10;
                  PeriodAircaft += '<td>' + TFT + '</td>';
                  arrValues.push(TFT);
                } else {
                  PeriodAircaft += '<td>0</td>';
                  arrValues.push(0);
                }
              }
            }
            for (var H in ExpKeys) {
              var Exp_VAL = D.Life_TotalExperience[ExpKeys[H]];
              Exp_VAL = Math.round(Exp_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Exp_VAL + '</td>';
              arrValues.push(Exp_VAL);
            }
            for (var H in SpecKeys) {
              var Spec_VAL = D.Life_TotalExperience[SpecKeys[H]];
              Spec_VAL = Math.round(Spec_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Spec_VAL + '</td>';
              arrValues.push(Spec_VAL);
            }
          } else {
            var TotalTime = D.TotalExperience.FTT;
            TotalTime = Math.round(TotalTime * 10) / 10;
            arrValues.push(TotalTime);
            for (var H in AcTypeList) {
              if (AcTypeList[H] != null) {
                if (AcTypeList[H] in D.TypeExperience) {
                  var TFT = D.TypeExperience[AcTypeList[H]].FTT;
                  TFT = Math.round(TFT * 10) / 10;
                  PeriodAircaft += '<td>' + TFT + '</td>';
                  arrValues.push(TFT);
                } else {
                  PeriodAircaft += '<td>0</td>';
                  arrValues.push(0);
                }
              }
            }
            for (var H in ExpKeys) {
              var Exp_VAL = D.TotalExperience[ExpKeys[H]];
              Exp_VAL = Math.round(Exp_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Exp_VAL + '</td>';
              arrValues.push(Exp_VAL);
            }
            for (var H in SpecKeys) {
              var Spec_VAL = D.TotalExperience[SpecKeys[H]];
              Spec_VAL = Math.round(Spec_VAL * 10) / 10;
              PeriodAircaft += '<td>' + Spec_VAL + '</td>';
              arrValues.push(Spec_VAL);
            }
          }
          html += `<tr>
    <td>
        <button class="RPTduty-${i} action-btn"><img src="./images/inspect.svg" /></button>
    </td>
    <td>${ExtendInfo}</td>
    <td>${LastLogin.text}<br />${LastLogin.FromNow}</td>
    <td>${LastSync.text}<br />${LastSync.FromNow}</td>
    <td>${D.Noff.Text}</td>
    <td>${D.Date}</td>
    <td>${Start}</td>
    <td>${End}</td>
    <td>${FT}</td>
    <td>${D.DT}</td>
    <td>${D.ODT}</td>
    <td>${D.RT}</td>
    <td>${D.IDENT}</td>
    <td>${D.lastQ}</td>
    <td>${D.curQR}</td>
    <td>${D.last2Q}</td>
    <td>${D.CalYear}</td>
    <td>${TotalTime}</td>
    ${PeriodAircaft}
    <td>${VWtable}</td>
    <td>${D.FltDays}</td>
    <td>${D.DtyDays}</td>
    <td>${D.TotalDays}</td>
    <td>
        <button class="RPTduty-${i} action-btn"><img src="./images/inspect.svg" /></button>
    </td>
</tr>`;

          arrValues.push(VWtext);
          arrValues.push(D.FltDays);
          arrValues.push(D.DtyDays);
          arrValues.push(D.TotalDays);
          arrValues.push('');

          CSV += IterateCSV(arrValues, CSV);
        } else {
          html += `<tr>
    <td></td>
    <td>
        <strong>${I.chatname}</strong> <br>
        ${iTrans('No Duty Data Synced')}
    </td>
    <td>${LastLogin.text}<br />${LastLogin.FromNow}</td>
    <td>${LastSync.text}<br />${LastSync.FromNow}</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    ${blankShim}
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
</tr>`;

          var arrValues = [I.chatname, I.LastLogin, I.LastSync, '', '', '', '', '', '', '', '', '', '', '', '', '', ''];
          for (var H in AcTypeList) {
            if (AcTypeList[H] != null) {
              arrValues.push('');
            }
          }
          for (var H in ExpKeys) {
            arrValues.push('');
          }
          for (var H in SpecKeys) {
            arrValues.push('');
          }
          arrValues.push(iTrans('No Duty Data Synced'));
          arrValues.push('');
          arrValues.push('');

          CSV += IterateCSV(arrValues, CSV);
        }
      }
      html += '</tbody></table></div></div>';
      //REPORT_HTML_PDF = html;

      html +=
        '<div class="limit-table "><a href="#" data-role="button" data-icon="arrow-d" id="exportDuty_CSV_BTN">' +
        iTrans('Download CSV Data') +
        '</a></div>';

      //html += '<button onClick="GeneratePDF()">GeneratePDF</button>';

      GENERATE_PDF.filename = 'FDT';
      GENERATE_PDF.showPDF = 1;
      GENERATE_PDF.silent = 0;
      GENERATE_PDF.Type = 'FlightDuty';
      GENERATE_PDF.subject = 'FlightDuty';
      GENERATE_PDF.email = '0';
      GENERATE_PDF.div = 'FDTSUMMARYPDF';
      GENERATE_PDF.ClientEmail = '';

      $('#Report_Content').html(html).enhanceWithin();

      var $FloatHeaders = $('table.fdtsummary');
      $FloatHeaders.floatThead({
        //position: 'fixed',
        scrollContainer: function ($FloatHeaders) {
          return $FloatHeaders.closest('.theadwrapper');
        },
      });
      $(window).trigger('resize');
      for (i in Report_Data) {
        if (!Report_Data.hasOwnProperty(i)) {
          continue;
        }

        (function (_i) {
          const dutyButtons = $('.RPTduty-' + _i);
          if (dutyButtons.length > 0) {
            dutyButtons.on('click', () => showDutyTimes(_i));
          }
        })(i);
      }
      // console.log(CSV);
      DownloadCSV_DATA = CSV;

      $('#exportDuty_CSV_BTN').on('click', function (event) {
        var data;
        var now = new Date();
        var filename =
          'Cirro_FDT_Summary_Report_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '.csv';
        if (device.platform.toUpperCase() === 'ANDROID') {
          console.log('Opening base64 Android');
          event.preventDefault();
          LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
        } else if (IOS) {
          console.log('Opening base64 IOS');
          event.preventDefault();
          LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
        } else {
          console.log('Opening base64 Universal URL');
          var a = document.createElement('a');

          if (typeof a.download != 'undefined') {
            data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
            // set attributes href and target in the <a> element (with id  exportGPX)
            $(this).attr({
              href: data,
              target: '_blank',
              download: filename,
            });
            // let the click go through
          } else {
            // download attribute is not supported
            data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

            // set attributes href and target in the <a> element (with id  exportGPX)
            $(this).attr({
              href: data,
              target: '_system',
            });
          }
        }
      });
    } else {
      $('#Report_Content').html('<h3>' + iTrans('No Pilot Flight Duty Times Available') + '!</h3>');
    }
  }
}

function UserDuty(
  duty_Data,
  date,
  experience,
  Texperience,
  List_TypeExperience,
  AcTypeExperience,
  ClassExperience,
  FromDate,
  ToDate,
  TotalExperience12,
  TypeExperience12a,
  ClassExperience12,
  ModelExperience12a,
  rawData
) {
  var FDT_LIB = new fdtShared();
  init_FDT_LIB(FDT_LIB, rawData);
  DUTYTIME = 'REPORT'; //global to prevent client database record marking when it doesn't exist (menu-Duty-SHARED)

  var TypeExperience12 = {};
  var ModelExperience12 = {};
  if (duty_Data.length > 0) {
    var AdminWarning = [];
    var AdminViolation = [];
    var duty = [];
    var count = 0;

    var Details = null;
    var ModelExperience = [];
    ModelExperience['[Unknown]'] = {};
    var Life_ModelExperience = [];
    Life_ModelExperience['[Unknown]'] = {};
    var TypeExperience = [];
    TypeExperience['[Unknown]'] = {};
    var Life_TypeExperience = [];
    Life_TypeExperience['[Unknown]'] = {};
    var ClassExperience = [];
    ClassExperience['[Unknown]'] = {};
    var Life_ClassExperience = [];
    Life_ClassExperience['[Unknown]'] = {};
    var TotalExperience = {
      FTT: 0,
      DR: 0,
      PIC: 0,
      SIC: 0,
      FI: 0,
      DAY: 0,
      NIGHT: 0,
      XC: 0,
      AI: 0,
      SI: 0,
      IA: 0,
      DL: 0,
      NL: 0,
      GA: 0,
      DAT: 0,
      NT: 0,
      LL: 0,
      WB: 0,
      MF: 0,
      OT: 0,
      FTO: 0,
      FLD: 0,
      RGR: 0,
      NVG_HRS: 0,
      NVG_OPS: 0,
      NVG_TO: 0,
      NVG_LD: 0,
      NVG_HOV: 0,
      NVG_DEP: 0,
      NVG_ARR: 0,
      NVG_TRAN: 0,
      CS1: 0,
      CS2: 0,
      CS3: 0,
      CS4: 0,
      CS5: 0,
      CS6: 0,
      CS7: 0,
      CS8: 0,
      CS9: 0,
      CS10: 0,
      CS11: 0,
      CS12: 0,
      CS13: 0,
      CS14: 0,
      CS15: 0,
      CS16: 0,
      CS17: 0,
      CS18: 0,
      CS19: 0,
      CS20: 0,
      CS21: 0,
      CS22: 0,
      CS23: 0,
      CS24: 0,
      CS25: 0,
      CS26: 0,
      CS27: 0,
      CS28: 0,
      CS29: 0,
      CS30: 0,
      CS31: 0,
      CS32: 0,
      CS33: 0,
      CS34: 0,
      CS35: 0,
      CS36: 0,
      CS37: 0,
      CS38: 0,
      CS39: 0,
      CS40: 0,
      CS41: 0,
      CS42: 0,
      CS43: 0,
      CS44: 0,
      CS45: 0,
      CS46: 0,
      CS47: 0,
      CS48: 0,
      CS49: 0,
      CS50: 0,
      CS51: 0,
      CS52: 0,
      CS53: 0,
      CS54: 0,
      CS55: 0,
      CS56: 0,
      CS57: 0,
      CS58: 0,
      CS59: 0,
      CS60: 0,
      CS61: 0,
      CS62: 0,
      CS63: 0,
      CS64: 0,
      CS65: 0,
      CS66: 0,
      CS67: 0,
      CS68: 0,
      CS69: 0,
      CS70: 0,
      CS71: 0,
      CS72: 0,
      CS73: 0,
      CS74: 0,
      CS75: 0,
      CS76: 0,
      CS77: 0,
      CS78: 0,
      CS79: 0,
      CS80: 0,
      CS81: 0,
      CS82: 0,
      CS83: 0,
      CS84: 0,
      CS85: 0,
      CS86: 0,
      CS87: 0,
      CS88: 0,
      CS89: 0,
      CS90: 0,
      CS91: 0,
      CS92: 0,
      CS93: 0,
      CS94: 0,
      CS95: 0,
      CS96: 0,
      CS97: 0,
      CS98: 0,
      CS99: 0,
      CS100: 0,
      CNT1: 0,
      CNT2: 0,
      CNT3: 0,
      CNT4: 0,
      CNT5: 0,
      CNT6: 0,
      CNT7: 0,
      CNT8: 0,
      CNT9: 0,
      CNT10: 0,
    };
    var Life_TotalExperience = {
      FTT: 0,
      DR: 0,
      PIC: 0,
      SIC: 0,
      FI: 0,
      DAY: 0,
      NIGHT: 0,
      XC: 0,
      AI: 0,
      SI: 0,
      IA: 0,
      DL: 0,
      NL: 0,
      GA: 0,
      DAT: 0,
      NT: 0,
      LL: 0,
      WB: 0,
      MF: 0,
      OT: 0,
      FTO: 0,
      FLD: 0,
      RGR: 0,
      NVG_HRS: 0,
      NVG_OPS: 0,
      NVG_TO: 0,
      NVG_LD: 0,
      NVG_HOV: 0,
      NVG_DEP: 0,
      NVG_ARR: 0,
      NVG_TRAN: 0,
      CS1: 0,
      CS2: 0,
      CS3: 0,
      CS4: 0,
      CS5: 0,
      CS6: 0,
      CS7: 0,
      CS8: 0,
      CS9: 0,
      CS10: 0,
      CS11: 0,
      CS12: 0,
      CS13: 0,
      CS14: 0,
      CS15: 0,
      CS16: 0,
      CS17: 0,
      CS18: 0,
      CS19: 0,
      CS20: 0,
      CS21: 0,
      CS22: 0,
      CS23: 0,
      CS24: 0,
      CS25: 0,
      CS26: 0,
      CS27: 0,
      CS28: 0,
      CS29: 0,
      CS30: 0,
      CS31: 0,
      CS32: 0,
      CS33: 0,
      CS34: 0,
      CS35: 0,
      CS36: 0,
      CS37: 0,
      CS38: 0,
      CS39: 0,
      CS40: 0,
      CS41: 0,
      CS42: 0,
      CS43: 0,
      CS44: 0,
      CS45: 0,
      CS46: 0,
      CS47: 0,
      CS48: 0,
      CS49: 0,
      CS50: 0,
      CS51: 0,
      CS52: 0,
      CS53: 0,
      CS54: 0,
      CS55: 0,
      CS56: 0,
      CS57: 0,
      CS58: 0,
      CS59: 0,
      CS60: 0,
      CS61: 0,
      CS62: 0,
      CS63: 0,
      CS64: 0,
      CS65: 0,
      CS66: 0,
      CS67: 0,
      CS68: 0,
      CS69: 0,
      CS70: 0,
      CS71: 0,
      CS72: 0,
      CS73: 0,
      CS74: 0,
      CS75: 0,
      CS76: 0,
      CS77: 0,
      CS78: 0,
      CS79: 0,
      CS80: 0,
      CS81: 0,
      CS82: 0,
      CS83: 0,
      CS84: 0,
      CS85: 0,
      CS86: 0,
      CS87: 0,
      CS88: 0,
      CS89: 0,
      CS90: 0,
      CS91: 0,
      CS92: 0,
      CS93: 0,
      CS94: 0,
      CS95: 0,
      CS96: 0,
      CS97: 0,
      CS98: 0,
      CS99: 0,
      CS100: 0,
      CNT1: 0,
      CNT2: 0,
      CNT3: 0,
      CNT4: 0,
      CNT5: 0,
      CNT6: 0,
      CNT7: 0,
      CNT8: 0,
      CNT9: 0,
      CNT10: 0,
    };
    var keys = Object.keys(TotalExperience);
    if (experience != null) {
      for (var k in keys) {
        if (experience[keys[k]] == undefined) experience[keys[k]] = 0; //For legacy experience data that does not have all the expected new fields
        Life_TypeExperience['[Unknown]'][keys[k]] = experience[keys[k]];
      }
      Life_TypeExperience['[Unknown]']['FTT'] = round10(
        parseFloat(Life_TypeExperience['[Unknown]']['PIC']) + parseFloat(Life_TypeExperience['[Unknown]']['SIC'])
      );
    }

    for (var e in TypeExperience12a) {
      var E = TypeExperience12a[e];
      TypeExperience12[E.List_Type] = {};
      for (var k in keys) {
        var Field = keys[k];
        TypeExperience12[E.List_Type][Field] = E[Field];
      }
    }

    for (var e in ModelExperience12a) {
      var E = ModelExperience12a[e];
      ModelExperience12[E.AcType] = {};
      for (var k in keys) {
        var Field = keys[k];
        ModelExperience12[E.AcType][Field] = E[Field];
      }
    }

    var ExpFromDate = new Date('1970-01-01'); //set default experience fromdate
    if (experience != null) {
      ExpFromDate = new Date(experience.FromDate);
    }
    var Currency = '';
    //console.log("Process Duty");
    for (var i in duty_Data) {
      //todo refactor Model/Type/Total experience for V2 reporting
      duty.push(duty_Data[i]);
      var recdate = new Date(duty_Data[i].Date);
      var offset = new Date(recdate.getUTCFullYear(), recdate.getUTCMonth(), recdate.getUTCDate());

      if (FromDate.getTime() <= offset.getTime()) {
        Details = FDT_LIB.day_Calc(duty, duty_Data[parseInt(i) + 1]);

        Details.CustomRule = null;
        Details.CustomWarning = '';
        Details.CustomViolation = '';
        Details.Date = duty_Data[i].Date;
        if (duty_Data[i].Rule_ID == null) {
          duty_Data[i].Rule_ID = 0;
          Details.Rule_ID = 0;
        }
        if (duty_Data[i].Rule_ID != 0) {
          var method = 'FDTRULE';
          if (window[method + duty_Data[i].Rule_ID] != undefined) {
            Details = window[method + duty_Data[i].Rule_ID](Details, duty_Data);
          }
          //console.log(Details.CustomRule);
          if (Details.CustomWarning.Status != '') {
            AdminWarning.push(Details.CustomWarning);
          }
          if (Details.CustomViolation.Status != '') {
            AdminViolation.push(Details.CustomViolation);
          }
        }
        if (Details.AdminWarning != null) {
          AdminWarning.push(Details.AdminWarning);
        }
        if (Details.AdminViolation != null) {
          AdminViolation.push(Details.AdminViolation);
        }
        if (Details.Currency != '') {
          Currency = {
            Status: Details.Currency,
            date: Details.Date,
          };
        } else {
          Currency = '';
        }
      }
      count++;
    }
    if (Currency != '') {
      AdminWarning.push(Currency);
    }
    //Add lifetime experiences
    //console.log("Process Life Experience");

    if (experience != null) {
      experience.FTT = parseFloat(experience.PIC) + parseFloat(experience.SIC) + parseFloat(experience.DR);
      verbose.log('experience', experience);
      for (var I in keys) {
        //Specialty Fields and other times prepopulated in experience
        var Field = keys[I];
        if (Texperience != null) {
          Life_TotalExperience[Field] =
            Math.round((parseFloat(experience[Field]) + parseFloat(Texperience[Field])) * 10) / 10;
        } else {
          Life_TotalExperience[Field] = Math.round(parseFloat(experience[Field]) * 10) / 10;
        }
      }
      //Removed following since we don't want to double count PIC / SIC times as part of total experience
      //Life_TotalExperience.FT = Math.round((parseFloat(experience.PIC) + parseFloat(experience.SIC))*10)/10;
      //if (Texperience != null) Life_TotalExperience.FTT += Math.round(parseFloat(Texperience.FTT) * 10) / 10;
    } else if (Texperience != null) {
      for (var I in keys) {
        var Field = keys[I];
        Life_TotalExperience[Field] = Math.round(parseFloat(Texperience[Field]) * 10) / 10;
      }
      Life_TotalExperience.FTT = Math.round(parseFloat(Texperience.FTT) * 10) / 10;
    }
    if (AcTypeExperience != null) {
      for (var A in AcTypeExperience) {
        var Model = AcTypeExperience[A];
        if (!(Model.AcType in Life_ModelExperience)) {
          Life_ModelExperience[Model.AcType] = {};
          for (var I in keys) {
            var Field = keys[I];
            Life_ModelExperience[Model.AcType][Field] = parseFloat(Model[Field]);
            if (experience != null && Model.AcType == '[Unknown]' && Field in experience) {
              Life_ModelExperience[Model.AcType][Field] += Math.round(parseFloat(experience[Field]) * 10) / 10;
            }
          }
        }
      }
    }

    if (List_TypeExperience != null) {
      for (var A in List_TypeExperience) {
        var Series = List_TypeExperience[A];
        if (!(Series.List_Type in Life_TypeExperience)) {
          Life_TypeExperience[Series.List_Type] = {};
          for (var I in keys) {
            var Field = keys[I];
            Life_TypeExperience[Series.List_Type][Field] = parseFloat(Series[Field]);
            if (experience != null && Series.List_Type == '[Unknown]') {
              if (Field in experience) {
                Life_TypeExperience[Series.List_Type][Field] += Math.round(parseFloat(experience[Field]) * 10) / 10;
              }
            }
          }
        }
        if (Series.List_Type == '[Unknown]') {
          for (var I in keys) {
            var Field = keys[I];
            if (Field in Series) {
              Life_TypeExperience[Series.List_Type][Field] = parseFloat(Life_TypeExperience[Series.List_Type][Field]);
              Life_TypeExperience[Series.List_Type][Field] += Math.round(parseFloat(Series[Field]) * 10) / 10;
            }
          }
        }
      }
    }

    if (experience != null) {
      if (experience.TypeExperience != '') {
        try {
          var ET = JSON.parse(experience.TypeExperience);
        } catch (e) {
          var ET = [];
        }
        for (var e in ET) {
          var E = ET[e];
          //$("#EXP_"+E.id).val(E.Hours);
          if (E.PIC == undefined) E.PIC = E.Hours;
          if (E.SIC == undefined || E.SIC == '') E.SIC = 0;
          if (E.DUAL == undefined || E.DUAL == '') E.DUAL = 0;

          //Life_TotalExperience.FTT += parseFloat(E.Hours); //this accounts for each individual type experience

          if (!(E.AcTypes in Life_ModelExperience)) {
            Life_ModelExperience[E.AcTypes] = {};
            for (var I in keys) {
              var Field = keys[I];
              Life_ModelExperience[E.AcTypes][Field] = 0;
            }
            Life_ModelExperience[E.AcTypes].FTT += parseFloat(E.PIC) + parseFloat(E.SIC) + parseFloat(E.DUAL);
            Life_ModelExperience[E.AcTypes].PIC += parseFloat(E.PIC);
            Life_ModelExperience[E.AcTypes].DR += parseFloat(E.DUAL);
          } else {
            if (!('FTT' in Life_ModelExperience[E.AcTypes])) {
              Life_ModelExperience[E.AcTypes].FTT = 0;
            }
            Life_ModelExperience[E.AcTypes].FTT += parseFloat(E.PIC) + parseFloat(E.SIC) + parseFloat(E.DUAL);
            Life_ModelExperience[E.AcTypes].PIC += parseFloat(E.PIC);
            Life_ModelExperience[E.AcTypes].SIC += parseFloat(E.SIC);
            Life_ModelExperience[E.AcTypes].DR += parseFloat(E.DUAL);
          }

          if (!(E.List_Type in Life_TypeExperience)) {
            Life_TypeExperience[E.List_Type] = {};
            //Set default 0's for life experience
            for (var I in keys) {
              var Field = keys[I];
              Life_TypeExperience[E.List_Type][Field] = 0;
            }
            Life_TypeExperience[E.List_Type].FTT += parseFloat(E.PIC) + parseFloat(E.SIC) + parseFloat(E.DUAL);
            Life_TypeExperience[E.List_Type].PIC += parseFloat(E.PIC);
            Life_TypeExperience[E.List_Type].SIC += parseFloat(E.SIC);
            Life_TypeExperience[E.List_Type].DR += parseFloat(E.DUAL);

            Life_TotalExperience.FTT += parseFloat(E.PIC) + parseFloat(E.SIC) + parseFloat(E.DUAL);
            Life_TotalExperience.PIC += parseFloat(E.PIC);
            Life_TotalExperience.SIC += parseFloat(E.SIC);
            Life_TotalExperience.DR += parseFloat(E.DUAL);
          } else {
            if (!('FTT' in Life_TypeExperience[E.List_Type])) {
              Life_TypeExperience[E.List_Type].FTT = 0;
            }
            Life_TypeExperience[E.List_Type].FTT += parseFloat(E.PIC) + parseFloat(E.SIC) + parseFloat(E.DUAL);
            Life_TypeExperience[E.List_Type].PIC += parseFloat(E.PIC);
            Life_TypeExperience[E.List_Type].SIC += parseFloat(E.SIC);
            Life_TypeExperience[E.List_Type].DR += parseFloat(E.DUAL);

            Life_TotalExperience.FTT += parseFloat(E.PIC) + parseFloat(E.SIC) + parseFloat(E.DUAL);
            Life_TotalExperience.PIC += parseFloat(E.PIC);
            Life_TotalExperience.SIC += parseFloat(E.SIC);
            Life_TotalExperience.DR += parseFloat(E.DUAL);
          }

          if (!(E.AcTypes in ModelExperience12)) {
            //Add fields to regular model experience for completeness in stats
            ModelExperience12[E.AcType] = {};
            for (var I in keys) {
              var Field = keys[I];
              ModelExperience12[E.AcType][Field] = 0;
            }
          }

          if (!(E.List_Type in TypeExperience12)) {
            //Add fields to regular model experience for completeness in stats
            TypeExperience12[E.List_Type] = {};
            for (var I in keys) {
              var Field = keys[I];
              TypeExperience12[E.List_Type][Field] = 0;
            }
          }
        }
      }
    }
    //console.log(duty_Data);
    //duty_Data.reverse();

    if (Details == null) {
      Details = FDT_LIB.day_Calc(duty_Data);
    } //to ensure some details exist
    duty_Data = duty_Data[duty_Data.length - 1]; //set duty data to last index for report

    //duty.reverse();
    var Noff = {};
    var now = new Date();
    var sync = getLocalDateTime(date);
    if (now > Details.Noff) {
      Noff.Text = Details.Noff.toDateString();
      Noff.Text += '<br />' + Math.round((now - Details.Noff) / (1000 * 60 * 60 * 24)) + ' ' + iTrans('Days ago') + '';
      Noff.Diff = Math.round((now - Details.Noff) / (1000 * 60 * 60 * 24));
      Noff.date = Details.Noff;
    }
    if (Details.Noff >= now) {
      Noff.Text = Details.Noff.toDateString();
      Noff.Text += '<br />In ' + Math.round((Details.Noff - now) / (1000 * 60 * 60 * 24)) + ' ' + iTrans('Days') + '';
      Noff.Diff = Math.round((Details.Noff - now) / (1000 * 60 * 60 * 24));
      Noff.date = Details.Noff;
    }
    if (sync.date > Details.Noff) {
      Noff.Text +=
        '<br />' +
        Math.round((sync.date - Details.Noff) / (1000 * 60 * 60 * 24)) +
        ' ' +
        iTrans('Days Before Last Sync') +
        '';
    }
    if (Details.Noff >= sync.date) {
      Noff.Text +=
        '<br />' +
        Math.round((Details.Noff - sync.date) / (1000 * 60 * 60 * 24)) +
        ' ' +
        iTrans('Days From Last Sync') +
        '';

      var daysfrom = Math.round((Details.Noff - sync.date) / (1000 * 60 * 60 * 24));

      if (duty_Data.Rule == 702 || duty_Data.Rule == 703) {
        if (Details.ExtendON && daysfrom < 6) {
          AdminWarning.push({
            Status: '' + iTrans('Approaching 42 Day Duty Extension') + '',
            date: duty_Data.Date,
          });
        }
        if (!Details.ExtendON && daysfrom < 3) {
          AdminWarning.push({
            Status: '' + iTrans('Approaching 27 Day Duty Limit') + '',
            date: duty_Data.Date,
          });
        }
      }
      if (duty_Data.Rule == 135) {
        if (daysfrom < 7) {
          AdminWarning.push({
            Status: '' + iTrans('Approaching 1Q/2Q Day Duty Limit') + '',
            date: duty_Data.Date,
          });
        }
      }
    }
    /*
    //experience currency implemented in 1.40.2 this is no longer necessary
    if (Details.Current == 0)
    {
      AdminWarning.push({
        Status: 'Not Current',
        date  : duty_Data.Date
      });
    }
    */
    //Reverse warnings and violations to show newest first
    AdminViolation.reverse();
    AdminWarning.reverse();
    if (duty_Data.IDENT == null) {
      duty_Data.IDENT = 'No Ident';
    }
    if (TotalExperience12 == null) {
      TotalExperience12 = [];
    }
    if (TypeExperience12 == null) {
      TypeExperience12 = [];
    }
    if (ModelExperience12 == null) {
      ModelExperience12 = [];
    }
    if (Life_TotalExperience == null) {
      Life_TotalExperience = [];
    }
    if (Life_TypeExperience == null) {
      Life_TypeExperience = [];
    }
    if (Life_ModelExperience == null) {
      Life_TypeExperience = [];
    }

    return {
      Date: duty_Data.Date,
      Modified: duty_Data.Modified,
      IDENT: duty_Data.IDENT,
      Current: Details.Current,
      EL: duty_Data.EL,
      Extend: duty_Data.Extend,
      Split: duty_Data.Split,
      Standby: duty_Data.Standby,
      IFR: duty_Data.IFR,
      Heli: duty_Data.Heli,
      DHC: duty_Data.DHC,
      Dual: duty_Data.Dual,
      Logging: duty_Data.Logging,
      On: duty_Data.On,
      Start: duty_Data.Start,
      End: duty_Data.End,
      Off: duty_Data.Off,
      On2: duty_Data.On2,
      Start2: duty_Data.Start2,
      End2: duty_Data.End2,
      Off2: duty_Data.Off2,
      FT: parseFloat(duty_Data.FT),
      FT2: parseFloat(duty_Data.FT2),
      DFR: duty_Data.DFR,
      Comment: duty_Data.Comment,
      RT: Details.RT,
      RT7: Details.RT7,
      RT17: Details.RT17,
      RT19: Details.RT19,
      medevacRest: Details.medevacRest,
      DT: Details.DT,
      DT7: Details.DT7,
      DT28: Details.DT28,
      DT365: Details.DT365,
      HW: Details.HW,
      HW7: Details.HW7,
      HW28: Details.HW28,
      HW365: Details.HW365,
      ODT: Details.ODT,
      L3: Details.L3,
      L7: Details.L7,
      L28: Details.L28,
      L30: Details.L30,
      L42: Details.L42,
      L90: Details.L90,
      L180: Details.L180,
      L365: Details.L365,
      Off30: Details.Off30,
      Off42: Details.Off42,
      Off90: Details.Off90,
      Noff: Noff,
      lastQ: Details.lastQ,
      curQR: Details.curQR,
      curQ: Details.curQ,
      last2Q: Details.last2Q,
      CalYear: Details.CalYear,
      Status: Details.Status,
      Warning: Details.Warning,
      Warning_Ack: duty_Data.Warning_Ack,
      Violation: Details.Violation,
      Violation_Ack: duty_Data.Violation_Ack,
      ExtendAvail: Details.ExtendAvail,
      Rule: duty_Data.Rule,
      Rule_ID: duty_Data.Rule_ID,
      Need5Off: false,
      ExtendNum: Details.ExtendNum,
      ExtendReason: Details.ExtendReason,
      ExtendON: Details.ExtendON,
      Extendable: Details.Extendable,
      FltDays: Details.FltDays,
      DtyDays: Details.DtyDays,
      TotalDays: Details.TotalDays,
      AdminWarning: AdminWarning,
      AdminViolation: AdminViolation,
      TotalExperience: TotalExperience12,
      TypeExperience: TypeExperience12,
      ModelExperience: ModelExperience12,
      Life_TotalExperience: Life_TotalExperience,
      Life_TypeExperience: Life_TypeExperience,
      Life_ModelExperience: Life_ModelExperience,
      MaxNextFltTimes: Details.MaxNextFltTimes,
      DayCurrent: Details.DayCurrent,
      NightCurrent: Details.NightCurrent,
      IFRCurrent: Details.IFRCurrent,
      CurrencySummary: Details.CurrencySummary,
      IFR_Report: Details.IFR_Report,
      NVG_Report: Details.NVG_Report,
      AC_CURRENCY: Details.AC_CURRENCY,
      AC_CurrencyData: Details.AC_CurrencyData,
    };
  } else {
    return false;
  }
}

function init_FDT_LIB(libVar, user) {
  var UserData = {
    FDT_NvgCur: user.FDT_NvgCur,
    FDT_IfrCur: user.FDT_IfrCur,
    FDT_DayCur: user.FDT_DayCur,
    FDT_NightCur: user.FDT_NightCur,
    FDT_FloatRating: user.FDT_FloatRating,
    NVIS_Date: user.experience != undefined ? user.experience.NVIS_Date : undefined,
    NVIS_Initial_Date: user.experience != undefined ? user.experience.NVIS_Initial_Date : undefined,
    NVIS_Gnd_Date: user.experience != undefined ? user.experience.NVIS_Gnd_Date : undefined,
    IPC_Date: user.experience != undefined ? user.experience.IPC_Date : undefined,
  };
  let defaults = {
    FDT_NvgCur: 0,
    FDT_IfrCur: 0,
    FDT_DayCur: 0,
    FDT_NightCur: 0,
    FDT_FloatRating: 0,
    NVIS_Date: '2000-01-01',
    NVIS_Initial_Date: '2000-01-01',
    NVIS_Gnd_Date: '2000-01-01',
    IPC_Date: '2000-01-01',
  };
  let keys = Object.keys(defaults);
  for (let k in keys) {
    let K = keys[k];
    if (UserData[K] == undefined) {
      UserData[K] = defaults[K];
    }
  }
  libVar.setUserData(UserData);
  libVar.setGroupAC_Data(ADMIN_GROUP_AC_TYPE_DATA);
}

function showDutyTimes(index) {
  DUTYTIME = 'REPORT'; //global to prevent client database record marking when it doesn't exist (menu-Duty-SHARED)
  //console.log('show duty');
  //console.log(REPORT_USER_DATA[index].chatname);
  var FDT_LIB = new fdtShared();
  init_FDT_LIB(FDT_LIB, REPORT_USER_DATA[index]);
  var duty = REPORT_USER_DATA[index].Duty;
  //console.log(duty);
  $('#Title_Sub_Report_Name').html(REPORT_USER_DATA[index].chatname);

  const subReportContent = $('#Sub_Report_Content');
  subReportContent.html('');
  var warnText = ' / Warnings';
  if (HideFDTwarnings) {
    warnText = '';
  }
  var html = '';
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() - 1 + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate.setUTCDate(ToDate.getUTCDate() + 1);
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  html += `<div class="limit-table">
      <a href="#" data-role="button" data-icon="arrow-d" id="exportPilotDuty_RAW_BTN" >
        ${iTrans('Download Raw FDT & Experience CSV Data')}
      </a>
    </div>
    `;
  html +=
    '<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportPilotDuty_CSV_BTN">Download CSV Data</a>';
  html +=
    '<a data-role="button" href="#" data-icon="user" id="exportPilotDuty_CFDT_BTN">Export Cirro FDT File</a></div>';
  html += `<h3> ${REPORT_USER_DATA[index].chatname} </h3>`;
  var CSV = '';

  if (COUNTRY === 'CANADA') {
    var arrValues = [
      iTrans('Date'),
      iTrans('Start Wk'),
      iTrans('Start'),
      iTrans('End'),
      iTrans('End Wk'),
      'Flt',
      'HW',
      'FDP',
      'RT',
      iTrans('Rule'),
      iTrans('Ident'),
      iTrans('DFR'),
      iTrans('Comment'),
      iTrans('Next Req Day Off'),
      '7',
      '28',
      '30',
      '42',
      '90',
      '180',
      '365',
      'HW7',
      'HW28',
      'HW365',
      'Off 30',
      'Off 90',
      'Legs',
      iTrans('Violation') + warnText,
    ];

    CSV += IterateCSV(arrValues, CSV);

    html += `<div style="max-width: 100%; overflow: auto; max-height: 80vh;" class="theadwrapper">
    <table data-filter="true" class="table fdtpilot wrap" border="1">
        <thead>
        <tr>
            <th>${iTrans('Date')}</th>
            <th>${iTrans('Start Wk')}</th>
            <th>${iTrans('Start')}</th>
            <th>${iTrans('End')}</th>
            <th>${iTrans('End Wk')}</th>
            <th>Flt</th>
            <th>HW</th>
            <th>FDP</th>
            <th>RT</th>
            <th>${iTrans('Rule')}</th>
            <th>${iTrans('Ident')}</th>
            <th>DFR</th>
            <th>${iTrans('Comment')}</th>
            <th>${iTrans('Next Req Day Off')}</th>
            <th>7</th>
            <th>28</th>
            <th>30</th>
            <th>42</th>
            <th>90</th>
            <th>180</th>
            <th>365</th>
            <th>HW7</th>
            <th>HW28</th>
            <th>HW365</th>
            <th>Off 30</th>
            <th>Off 90</th>
            <th>Legs</th>
            <th>${iTrans('Violation')}${warnText}</th>
        </tr>
        </thead>
        <tbody>`;
    var duty_Data = [];
    var FromDate = new Date($('#RPT_FromDate').val());
    var ToDate = new Date($('#RPT_ToDate').val());
    var Period = $('#RPT_FromDate').val() + ' to ' + $('#RPT_ToDate').val();
    var TotalFlightTime = 0;
    var TotalFlightDuty = 0;
    var TotalOnCall = 0;
    var TotalReserve = 0;
    var TotalDaysOff = 0;
    for (var i in duty) {
      var data = duty[i];
      duty_Data.push(data);
      var recdate = new Date(data.Date);
      var offset = new Date(recdate.getUTCFullYear(), recdate.getUTCMonth(), recdate.getUTCDate());
      if (FromDate.getTime() <= offset.getTime()) {
        let Legs = '';
        let LegsTBL = '';
        try {
          let Extra = JSON.parse(data.EXTRA);
          LegsTBL =
            '<table><thead><tr><th>Start</th><th>End</th><th>FT</th><th>Reg</th><th>Type</th></tr></thead><tbody>';
          for (var e in Extra.FDT135) {
            var E = Extra.FDT135[e];
            if (E.End == undefined) {
              E.End = FDT_LIB.subDecToTime(E.Start, -E.FT, true);
            }
            Legs +=
              'Start: ' +
              E.Start +
              ' End: ' +
              E.End +
              ' FT: ' +
              E.FT +
              ' Reg: ' +
              E.Reg +
              ' ' +
              (E.Dual == 1 ? 'Dual' : 'Single') +
              ' ' +
              (E.IFR == 1 ? 'IFR' : 'VFR') +
              '<br />';
            LegsTBL +=
              '<tr><td>' +
              E.Start +
              '</td><td>' +
              E.End +
              '</td><td>' +
              E.FT +
              '</td><td>' +
              FDT_LIB.RuleLabelMap[E.Reg] +
              '</td><td>' +
              (E.Dual == 1 ? 'Dual' : 'Single') +
              ' ' +
              (E.IFR == 1 ? 'IFR' : 'VFR') +
              '</td></tr>';
          }
          LegsTBL += '</tbody></table>';
          if (e.length == 0 || data.EXTRA == 0) {
            Legs = '';
            LegsTBL = 'None';
          }
        } catch (e) {
          //console.log(e);
          Legs = '';
          LegsTBL = 'None';
        }
        var C = FDT_LIB.day_Calc(duty_Data, duty[parseInt[i] + 1]);

        C.CustomRule = null;
        C.CustomWarning = '';
        C.CustomViolation = '';
        C.Date = duty_Data[i].Date;
        if (duty_Data[i].Rule_ID == null) {
          duty_Data[i].Rule_ID = 0;
          C.Rule_ID = 0;
        }
        if (duty_Data[i].Rule_ID != 0) {
          var method = 'FDTRULE';
          if (window[method + duty_Data[i].Rule_ID] != undefined) {
            C = window[method + duty_Data[i].Rule_ID](C, duty_Data);
          }
          //console.log(Details.CustomRule);
          if (C.CustomWarning.Status != '') {
            C.Status += C.CustomWarning.Status + '<br />';
            C.Warning = true;
          }
          if (C.CustomViolation.Status != '') {
            C.Status += C.CustomViolation.Status + '<br />';
            C.Violation = true;
          }
        }
        var On = data.On;
        var Start = data.Start;
        var End = data.End;
        var Off = data.Off;
        var FT = data.FT;
        TotalFlightTime += ChecKNaN_Item(data.FT);
        if (data.Split == 1) {
          On = data.On + '<br />' + data.On2;
          Start = data.Start + '<br />' + data.Start2;
          End = data.End + '<br />' + data.End2;
          Off = data.Off + '<br />' + data.Off2;
          FT = data.FT + '<br />' + data.FT2;
          TotalFlightTime += ChecKNaN_Item(data.FT2);
        }
        var Extension = '';
        if (C.ExtendON == 1) {
          Extension = 'orangeRecord';
        }
        var Status = '';
        if (C.Warning) {
          Status = 'yellowRecord';
        }
        if (C.Violation) {
          Status = 'redRecord';
        } else {
          if (HideFDTwarnings) {
            Status = '';
            C.Status = '';
          }
        }
        html += `<tr>
    <td class="${Extension}">${data.Date}</td>
    <td>${On}</td>
    <td>${Start}</td>
    <td>${End}</td>
    <td>${Off}</td>
    <td>${FT}</td>
    <td>${C.HW}</td>
    <td>${C.DT}</td>
    <td>${C.RT}</td>
    <td>${FDT_LIB.RuleLabelMap[data.Rule]}</td>
    <td>${data.IDENT}</td>
    <td>${data.DFR}</td>
    <td>${data.Comment}</td>
    <td>${C.Noff.toDateString()}</td>
    <td>${C.L7}</td>
    <td>${C.L28}</td>
    <td>${C.L30}</td>
    <td>${C.L42}</td>
    <td>${C.L90}</td>
    <td>${C.L180}</td>
    <td>${C.L365}</td>
    <td>${C.HW7}</td>
    <td>${C.HW28}</td>
    <td>${C.HW365}</td>
    <td>${C.Off30}</td>
    <td>${C.Off90}</td>
    <td>${LegsTBL}</td>
    <td class="${Status}">${C.Status}</td>
</tr>`;

        var arrValues = [
          data.Date,
          On,
          Start,
          End,
          Off,
          FT,
          C.HW,
          C.DT,
          C.RT,
          FDT_LIB.RuleLabelMap[data.Rule],
          data.IDENT,
          data.DFR,
          data.Comment,
          C.Noff.toDateString(),
          C.L7,
          C.L28,
          C.L30,
          C.L42,
          C.L90,
          C.L180,
          C.L365,
          C.HW7,
          C.HW28,
          C.HW365,
          C.Off30,
          C.Off90,
          Legs,
          C.Status,
        ];

        CSV += IterateCSV(arrValues, CSV);
        //TotalFlightTime += parseFloat(FT);
        TotalFlightDuty += C.DT;
        if (data.Standby == 2 || data.Standby == 5 || data.Standby == 8) {
          //Reserve
          TotalReserve += dutytime_Calc(data.Start, data.End);
          TotalReserve += dutytime_Calc(data.Start2, data.End2);
        }
        if (data.Standby == 1 || data.Standby == 3 || data.Standby == 4) {
          //Standby
          TotalOnCall += dutytime_Calc(data.Start, data.End);
          TotalOnCall += dutytime_Calc(data.Start2, data.End2);
        }
        if (C.DT == 0) TotalDaysOff++;
      }
    }

    html += '</tbody></table></div>';
    CSV += '\n\n';
    CSV += '' + iTrans('FOR THE PERIOD') + ':,' + Period + '\n';
    CSV += ',' + iTrans('TOTAL FLIGHT TIME') + ': ' + round10(TotalFlightTime) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans('TOTAL FLIGHT DUTY TIME') + ': ' + round10(TotalFlightDuty) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans('TOTAL TIME ON CALL') + ': ' + round10(TotalOnCall) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans('TOTAL TIME ON RESERVE') + ': ' + round10(TotalReserve) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans("TOTAL CAR'S DAYS OFF") + ': ' + TotalDaysOff + ' ' + iTrans('DaysDays') + '\n';
  } else if (COUNTRY == 'KENYA') {
    var arrValues = [
      iTrans('Date'),
      iTrans('Day Off'),
      iTrans('Start'),
      iTrans('End'),
      'Flt',
      'Dty',
      'ODty',
      'RT',
      iTrans('Rule'),
      iTrans('Ident'),
      iTrans('DFR'),
      iTrans('Comment'),
      '72h',
      '28d',
      '90d',
      '365d',
      iTrans('Violation') + warnText,
    ];

    CSV += IterateCSV(arrValues, CSV);

    html += `<div style="max-width: 100%; overflow: auto; max-height: 80vh;" class="theadwrapper">
    <table data-filter="true" class="table fdtpilot wrap" border="1">
        <thead>
        <tr>
            <th>${iTrans('Date')}</th>
            <th>${iTrans('Start')}</th>
            <th>${iTrans('End')}</th>
            <th>Flt</th>
            <th>Dty</th>
            <th>ODty</th>
            <th>RT</th>
            <th>${iTrans('Rule')}</th>
            <th>${iTrans('Ident')}</th>
            <th>DFR</th>
            <th>${iTrans('Comment')}</th>
            <th>72h</th>
            <th>28d</th>
            <th>90d</th>
            <th>365d</th>
            <th>${iTrans('Violation')}${warnText}</th>
        </tr>
        </thead>
        <tbody>`;
    var duty_Data = [];
    var FromDate = new Date($('#RPT_FromDate').val());
    var ToDate = new Date($('#RPT_ToDate').val());
    var Period = $('#RPT_FromDate').val() + ' to ' + $('#RPT_ToDate').val();
    var TotalFlightTime = 0;
    var TotalFlightDuty = 0;
    var TotalOnCall = 0;
    var TotalReserve = 0;
    var TotalDaysOff = 0;
    for (var i in duty) {
      var data = duty[i];
      duty_Data.push(data);
      var recdate = new Date(data.Date);
      var offset = new Date(recdate.getUTCFullYear(), recdate.getUTCMonth(), recdate.getUTCDate());
      if (FromDate.getTime() <= offset.getTime()) {
        var C = FDT_LIB.day_Calc(duty_Data, duty[parseInt(i) + 1]);

        var DayOffEmoji = '';
        if (data.EL == 1) DayOffEmoji = '😴';
        C.CustomRule = null;
        C.CustomWarning = '';
        C.CustomViolation = '';
        C.Date = duty_Data[i].Date;
        if (duty_Data[i].Rule_ID == null) {
          duty_Data[i].Rule_ID = 0;
          C.Rule_ID = 0;
        }
        if (duty_Data[i].Rule_ID != 0) {
          var method = 'FDTRULE';
          if (window[method + duty_Data[i].Rule_ID] != undefined) {
            C = window[method + duty_Data[i].Rule_ID](C, duty_Data);
          }
          //console.log(Details.CustomRule);
          if (C.CustomWarning.Status != '') {
            C.Status += C.CustomWarning.Status + '<br />';
            C.Warning = true;
          }
          if (C.CustomViolation.Status != '') {
            C.Status += C.CustomViolation.Status + '<br />';
            C.Violation = true;
          }
        }
        var Start = data.Start;
        var End = data.End;
        var FT = data.FT;
        TotalFlightTime += ChecKNaN_Item(data.FT);
        if (data.Split == 1) {
          Start = data.Start + '<br />' + data.Start2;
          End = data.End + '<br />' + data.End2;
          FT = data.FT + '<br />' + data.FT2;
          TotalFlightTime += ChecKNaN_Item(data.FT2);
        }
        var Extension = '';
        if (C.ExtendON == 1) {
          Extension = 'orangeRecord';
        }
        var Status = '';
        if (C.Warning) {
          Status = 'yellowRecord';
        }
        if (C.Violation) {
          Status = 'redRecord';
        } else {
          if (HideFDTwarnings) {
            Status = '';
            C.Status = '';
          }
        }
        html += `<tr>
    <td class="${Extension}">${data.Date} ${DayOffEmoji}</td>
    <td>${Start}</td>
    <td>${End}</td>
    <td>${FT}</td>
    <td>${C.DT}</td>
    <td>${C.ODT}</td>
    <td>${C.RT}</td>
    <td>${FDT_LIB.RuleLabelMap[data.Rule]}</td>
    <td>${data.IDENT}</td>
    <td>${data.DFR}</td>
    <td>${data.Comment}</td>
    <td>${C.L3}</td>
    <td>${C.L28}</td>
    <td>${C.L90}</td>
    <td>${C.L365}</td>
    <td class="${Status}">${C.Status}</td>
</tr>`;

        var arrValues = [
          data.Date,
          data.EL,
          Start,
          End,
          FT,
          C.DT,
          C.DT,
          C.RT,
          FDT_LIB.RuleLabelMap[data.Rule],
          data.IDENT,
          data.DFR,
          data.Comment,
          C.L3,
          C.L28,
          C.L90,
          C.L365,
          C.Status,
        ];

        CSV += IterateCSV(arrValues, CSV);
        //TotalFlightTime += parseFloat(FT);
        TotalFlightDuty += C.DT;
        if (data.Standby == 2 || data.Standby == 5 || data.Standby == 8) {
          //Reserve
          TotalReserve += dutytime_Calc(data.Start, data.End);
          TotalReserve += dutytime_Calc(data.Start2, data.End2);
        }
        if (data.Standby == 1 || data.Standby == 3 || data.Standby == 4) {
          //Standby
          TotalOnCall += dutytime_Calc(data.Start, data.End);
          TotalOnCall += dutytime_Calc(data.Start2, data.End2);
        }
        if (C.DT == 0) TotalDaysOff++;
      }
    }

    html += '</tbody></table></div>';
    CSV += '\n\n';
    CSV += '' + iTrans('FOR THE PERIOD') + ':,' + Period + '\n';
    CSV += ',' + iTrans('TOTAL FLIGHT TIME') + ': ' + round10(TotalFlightTime) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans('TOTAL FLIGHT DUTY TIME') + ': ' + round10(TotalFlightDuty) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans('TOTAL TIME ON CALL') + ': ' + round10(TotalOnCall) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans('TOTAL TIME ON RESERVE') + ': ' + round10(TotalReserve) + ' ' + iTrans('Hours') + '\n';
    CSV += ',' + iTrans("TOTAL CAR'S DAYS OFF") + ': ' + TotalDaysOff + ' ' + iTrans('DaysDays') + '\n';
  } else {
    var arrValues = [
      iTrans('Date'),
      iTrans('Start'),
      iTrans('End'),
      'Flt',
      'Dty',
      'ODty',
      'RT',
      iTrans('Ident'),
      'DFR',
      iTrans('Comment'),
      iTrans('Next Req Day Off'),
      'CurQ',
      'CurQR',
      'Last2Q',
      'Last2QR',
      'Cal Year',
      'Cal YearR',
      iTrans('Rule'),
      'Legs',
      iTrans('Violation') + warnText,
    ];

    CSV += IterateCSV(arrValues, CSV);

    html +=
      '<div style="max-width: 100%; overflow: auto; max-height: 80vh;" class="theadwrapper"><table data-filter="true" class="table fdtpilot wrap" border="1">' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('') +
      '' +
      iTrans('Date') +
      '</th>' +
      '<th>Start</th>' +
      '<th>' +
      iTrans('End') +
      '</th>' +
      '<th>Flt</th>' +
      '<th>Dty</th>' +
      '<th>ODty</th>' +
      '<th>RT</th>' +
      '<th>' +
      iTrans('Ident') +
      '</th>' +
      '<th>DFR</th>' +
      '<th>' +
      iTrans('Comment') +
      '</th>' +
      '<th>' +
      iTrans('Next Req Day Off') +
      '</th>' +
      '<th>CurQ</th>' +
      '<th>CurQR</th>' +
      '<th>Last2Q</th>' +
      '<th>Last2QR</th>' +
      '<th>Cal Year</th>' +
      '<th>Cal YearR</th>' +
      '<th>' +
      iTrans('Rule') +
      '</th>' +
      '<th>' +
      iTrans('Legs') +
      '</th>' +
      '<th>' +
      iTrans('Violation') +
      '' +
      warnText +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var duty_Data = [];
    var FromDate = new Date($('#RPT_FromDate').val());
    var ToDate = new Date($('#RPT_ToDate').val());
    for (i in duty) {
      var data = duty[i];
      duty_Data.push(data);
      var recdate = new Date(data.Date);
      var offset = new Date(recdate.getUTCFullYear(), recdate.getUTCMonth(), recdate.getUTCDate());
      if (FromDate.getTime() <= offset.getTime()) {
        let Legs = '';
        let LegsTBL = '';
        try {
          let Extra = JSON.parse(data.EXTRA);
          LegsTBL =
            '<table><thead><tr><th>Start</th><th>End</th><th>FT</th><th>Reg</th><th>Type</th></tr></thead><tbody>';
          for (var e in Extra.FDT135) {
            var E = Extra.FDT135[e];
            if (E.End == undefined) {
              E.End = FDT_LIB.subDecToTime(E.Start, -E.FT, true);
            }
            Legs +=
              'Start: ' +
              E.Start +
              ' End: ' +
              E.End +
              ' FT: ' +
              E.FT +
              ' Reg: ' +
              E.Reg +
              ' ' +
              (E.Dual == 1 ? 'Dual' : 'Single') +
              ' ' +
              (E.IFR == 1 ? 'IFR' : 'VFR') +
              '<br />';
            LegsTBL +=
              '<tr><td>' +
              E.Start +
              '</td><td>' +
              E.End +
              '</td><td>' +
              E.FT +
              '</td><td>' +
              FDT_LIB.RuleLabelMap[E.Reg] +
              '</td><td>' +
              (E.Dual == 1 ? 'Dual' : 'Single') +
              ' ' +
              (E.IFR == 1 ? 'IFR' : 'VFR') +
              '</td></tr>';
          }
          LegsTBL += '</tbody></table>';
          if (e.length == 0 || data.EXTRA == 0) {
            Legs = '';
            LegsTBL = 'None';
          }
        } catch (e) {
          //console.log(e);
          Legs = '';
          LegsTBL = 'None';
        }

        var C = FDT_LIB.day_Calc(duty_Data, duty[parseInt(i) + 1]);
        C.CustomRule = null;
        C.CustomWarning = '';
        C.CustomViolation = '';
        C.Date = duty_Data[i].Date;
        if (duty_Data[i].Rule_ID == null) {
          duty_Data[i].Rule_ID = 0;
          C.Rule_ID = 0;
        }
        if (duty_Data[i].Rule_ID != 0) {
          var method = 'FDTRULE';
          if (window[method + duty_Data[i].Rule_ID] != undefined) {
            C = window[method + duty_Data[i].Rule_ID](C, duty_Data);
            //console.log(Details.CustomRule);
            if (C.CustomWarning.Status != '') {
              C.Status += C.CustomWarning.Status + '<br />';
              C.Warning = true;
            }
            if (C.CustomViolation.Status != '') {
              C.Status += C.CustomViolation.Status + '<br />';
              C.Violation = true;
            }
          }
        }
        var Start = data.Start;
        var End = data.End;
        var FT = data.FT;
        if (data.Split == 1) {
          Start = data.Start + '<br />' + data.Start2;
          End = data.End + '<br />' + data.End2;
          FT = data.FT + '<br />' + data.FT2;
        }
        var Extension = '';
        if (C.ExtendON == 1) {
          Extension = 'orangeRecord';
        }
        var Status = '';
        if (C.Warning) {
          Status = 'yellowRecord';
        }
        if (C.Violation) {
          Status = 'redRecord';
        } else {
          if (HideFDTwarnings) {
            Status = '';
            C.Status = '';
          }
        }
        var rule = 'Single';
        if (data.Dual == 1) {
          rule = 'Dual';
        }
        var Assigned = '';
        if (data.Extend == 1) {
          Assigned = 'AD';
        }

        html +=
          '<tr><td class="' +
          Extension +
          '">' +
          data.Date +
          '</td><td>' +
          Start +
          '</td><td>' +
          End +
          '</td><td>' +
          FT +
          '</td><td>' +
          C.DT +
          '</td><td>' +
          C.ODT +
          '</td><td>' +
          C.RT +
          '</td><td>' +
          data.IDENT +
          '</td><td>' +
          data.DFR +
          '</td><td>' +
          data.Comment +
          '</td><td>' +
          C.Noff.toDateString() +
          '</td><td>' +
          C.lastQ +
          '</td><td>' +
          C.LQR +
          '</td><td>' +
          C.last2Q +
          '</td><td>' +
          C.L2QR +
          '</td><td>' +
          C.CalYear +
          '</td><td>' +
          C.LCalYearR +
          '</td><td>' +
          FDT_LIB.RuleLabelMap[data.Rule] +
          ' ' +
          rule +
          ' ' +
          Assigned +
          '</td><td>' +
          LegsTBL +
          '</td><td class="' +
          Status +
          '">' +
          C.Status +
          '</td></tr>';

        var arrValues = [
          data.Date,
          Start,
          End,
          FT,
          C.DT,
          C.ODT,
          C.RT,
          data.IDENT,
          data.DFR,
          data.Comment,
          C.Noff.toDateString(),
          C.lastQ,
          C.LQR,
          C.last2Q,
          C.L2QR,
          C.CalYear,
          C.LCalYearR,
          FDT_LIB.RuleLabelMap[data.Rule] + ' ' + rule + ' ' + Assigned,
          Legs,
          C.Status,
        ];

        CSV += IterateCSV(arrValues, CSV);
      }
    }

    html += '</tbody></table></div>';
  }

  subReportContent.html(html);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $('#exitSubReportPage').click(exitshowDutyTimes);
  subReportContent.enhanceWithin();

  var $FloatHeaders = $('table.fdtpilot');
  $FloatHeaders.floatThead({
    //position: 'fixed',
    scrollContainer: function ($FloatHeaders) {
      return $FloatHeaders.closest('.theadwrapper');
    },
  });
  $(window).trigger('resize');
  // console.log(CSV);

  $('#exportPilotDuty_RAW_BTN').on('click', async function (e) {
    e.preventDefault();
    $.mobile.loading('show');
    const url = `${BASEURL}menu-Account-AJAX.php`;

    const params = {
      action: 'ExportFDT_CSV',
      AdminExport: true,
      UserPK: REPORT_USER_DATA[index].UserPK,
      FromDate: $('#RPT_FromDate').val(),
      ToDate: $('#RPT_ToDate').val(),
    };

    try {
      const fileName = window.vueApp.utilities.file.getTimestampedFileName(REPORT_USER_DATA[index].chatname, 'csv');
      await window.vueApp.utilities.download.fromUrl(url).setUrlParams(params).toSaveAs(null, fileName);
    } catch (e) {
      window.vueApp.sentry.captureException(e);
    }
    $.mobile.loading('hide');
  });

  $('#exportPilotDuty_CFDT_BTN').on('click', async function (e) {
    e.preventDefault();
    $.mobile.loading('show');
    const url = `${BASEURL}menu-Account-AJAX.php`;

    const params = {
      action: 'ExportFDT',
      UserPK: Report_Data[index].PrimaryKey,
    };
    try {
      const fileName = window.vueApp.utilities.file.getTimestampedFileName(
        REPORT_USER_DATA[index].chatname,
        'cirrofdt'
      );
      await window.vueApp.utilities.download
        .fromUrl(url)
        .setUrlParams(params)
        .setMimeType('application/octet-stream')
        .toSaveAs(null, fileName);
    } catch (e) {
      window.vueApp.sentry.captureException(e);
    }
    $.mobile.loading('hide');
  });

  $('#exportPilotDuty_CSV_BTN').on('click', async function (event) {
    event.preventDefault();
    $.mobile.loading('show');
    try {
      const fileName = window.vueApp.utilities.file.getTimestampedFileName(
        'Cirro_FDT_Report_' + REPORT_USER_DATA[index].chatname,
        'csv'
      );
      const csvBlob = new Blob([CSV], { type: 'text/csv;charset=utf-8;' });

      const filePath = window.vueApp.utilities.file.dirTmp + fileName;
      await window.vueApp.utilities.file.writeFile(filePath, csvBlob);
      await window.vueApp.utilities.file.offerSaveAsDialog(filePath, 'text/csv', fileName);
    } catch (e) {
      window.vueApp.sentry.captureException(e);
    }
    $.mobile.loading('hide');
  });
}

function exitshowDutyTimes() {
  $('#exitSubReportPage').unbind('click', exitshowDutyTimes);
  $.mobile.changePage($('#Report_Page'), {
    reverse: true,
    changeHash: false,
  });
}
