/**
 * Class dealing with the syncing and displaying of keyword categories
 * @param {object}           [_databaseData]
 * @class
 */

class mSAMA_KwdCategory extends mSAMA_AbstractModel {
  /**
   *
   * @param {object}  _databaseData
   * @param {int}     [_num] Optional, to create a new name with a number attached.
   */
  constructor(_databaseData, _num) {
    super('kwc_primaryKey');

    this.sync = {
      kwc_primaryKey: null,
      kwc_name: null,
      kwc_color: '#000000',
      kwc_isBlacklisted: false,
      kwc_locked: false,

      modified: false,
      deleted: false,
      takeTags: [],
    };

    $.extend(true, this.sync, _databaseData);

    this.validateSettings = {
      kwc_name: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 3,
        maxLength: SAMA.enums.TEXTLENGTHS.Tiny,
        mustNotContain: 'WORDONLY',
      }),
    };

    if (this.IsNew()) {
      // Is new Category
      this.SetPk(cSAMA_Utils.GetUID());
      this.sync.kwc_name = 'Category ' + _num;
      this.SetModified(true);
    }
  }

  /**
   * Prompts the server to assign all tags of _catId to this category
   * @param {int} _catId Old owner ID
   */
  TakeKwdsFrom(_catId) {
    this.SetModified(true);
    this.sync.takeTags.push(_catId);
  }

  /**
   * Unlocks the delete button after server sync
   */
  ClearTakeStatus() {
    this.sync.takeTags = [];
  }
}
