/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var dbRule;
var Ruleversion = 1;
var FDT_RuleSyncfailed = false;
function syncRULE() {
  FDT_RuleSyncfailed = false;
  //get flight duty time rule methods from database for group
  console.log('Getting Rule Data');
  //$("#info").html("Getting Duty Times From Server...");
  IOS_postMessage({
    Message: 'Rtext',
    text: 'Downloading',
  });
  $.getJSON(BASEURL + 'sync-AJAX.php?action=ruleData', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      //offline sync complete
      FDT_RuleSyncfailed = true;
    } else {
      if (json_data[0].ServerResponse != 'Uncaught Exception') {
        Rule_data = json_data;
        console.log('Received Rule Data');
        syncRules(Rule_data);
      } else {
        FDT_RuleSyncfailed = true;
        RULE_SYNCING = false;
        IOS_postMessage({
          Message: 'RuleRemoteConFail',
        });
        RULE_SYNCING = false;
        SYNC_STATUS('RULE');
      }
    }
  }).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'RuleRemoteConFail',
    });
    FDT_RuleSyncfailed = true;
    RULE_SYNCING = false;
    SYNC_STATUS('RULE');
  });
}

function syncRules(data) {
  IOS_postMessage({
    Message: 'Rtext',
    text: 'Clearing...',
  });
  cirroDB.clearAll('FDT_Rule', function (e) {
    console.log('Deleted FDT_Rule database successfully');
    if (data[0].ServerResponse != 'No FDT Rules in Database') {
      IOS_postMessage({
        Message: 'Rtext',
        text: 'Updating...',
      });
      insertRules(data);
      return;
    }

    finalizeInsertingRules();
  });
}

function finalizeInsertingRules() {
  IOS_postMessage({ Message: 'Rtext', text: 'Complete' });
  RULE_SYNCING = false;
  SYNC_STATUS('RULE');
  update_Sync('LastRULEsync', LastRULEsync);
}

async function insertRules(data, last) {
  let current = 0;
  let max = data.length;

  for (const rl of data) {
    current++;
    IOS_postMessage({
      Message: 'Rtext',
      text: `Updating ${current} of ${max} Rules`,
    });
    await FdtRuleTable.query().insert(rl);
  }

  finalizeInsertingRules();
  LoadFDTRules();

  // cirroDB.insert('FDT_Rule', data, null, function (e) {
  //   finalizeInsertingRules();
  //   LoadFDTRules();
  // });
}
