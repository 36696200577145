/**
 *  @readonly
 *  @enum
 */
SAMA.enums.CUSTFIELDTYPES = {
  TextField: 0,
  GearSelect: 1,
  CraftSelect: 2,
  StaffSelect: 3,
  BaseSelect: 4,
};

class mSAMA_CustomField extends mSAMA_AbstractModel {
  /**
   * @param {mSAMA_RiskFactor}  _riskFactor         The owning risk factor
   * @param {object}            [_databaseData] From the templates table
   */
  constructor(_riskFactor, _databaseData) {
    super('mcf_primaryKey');
    this.riskFactor = _riskFactor;

    this.sync = {
      mcf_primaryKey: null,
      mcf_groupPk: null,
      mcf_question: '',
      mcf_type: 0,
      mcf_mrfPk: null,
      mcf_archivedDate: null,
      mcf_isMandatory: 1,
      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _databaseData);

    if (this.IsNew()) {
      this.SetPk(cSAMA_Utils.GetUID());
    }
  }

  /**
   * Changes the modified state
   * @param {boolean} _state
   * @override
   */
  SetModified(_state) {
    this.sync.modified = _state;
    this.riskFactor.SetModified(_state);
  }
}
