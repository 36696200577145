let SAMA = {
  setup: false,
  enums: {},
  pages: {},
  defaults: {
    DateInputFormat: 'Y-MM-DD',
    TimeInputFormat: 'HH:mm',
    TimePrettyFormat: 'HH:mm',
    DatePrettyFormat: 'MMM DD, YY',
  },
  settings: {
    /** @type mSAMA_Preferences */
    preferences: null,
    /** @type mSAMA_Policy */
    policyObject: null,
    /** @type mSAMA_RiskMatrix */
    riskMatrix: null,
  },
};

SAMA.enums.REPORT_STAGE = {
  DRAFT: 0,
  SUBMITTED: 1,
  APPROVED: 2,
  REJECTED: 3,
  COMPLETED: 4,
};

// Enum that helps determine users' access levels
// Careful, this also exists on the server side (SAMA_EnAccessType). Changes here must be reflected there.
SAMA.enums.ACCESS = {
  NONE: 0,
  VIEW: 1,
  REVIEW: 2,
  REVIEW_WITH_GRANT: 6,
  EDIT: 3,
  DELETE: 4,
  COMPLETE: 5,
};
