/**
 * Base class for all objects that can be filtered
 * @author falko@air-suite.com
 * @copyright (c) 2018 AirSuite, All Rights Reserved.
 */
class vSAMA_AbstractFilterableItem extends iSAMA_AbstractObservable {
  constructor(_model) {
    super();
    this.visible = true;
    this.searchObject = {};
    this.dom = {};
    this.model = _model;

    $.extend(true, this.searchObject, _model.sync);
  }

  Hide() {
    this.dom.body.slideUp();
    this.visible = false;
  }

  Show() {
    this.dom.body.slideDown();
    this.visible = true;
  }

  GetProperty(_prop) {
    if (this.searchObject.hasOwnProperty(_prop)) {
      return this.searchObject[_prop];
    }
    return null;
  }

  GetDom() {
    return this.dom.body;
  }

  GetModelPk() {
    return this.model.GetPk();
  }

  Destroy(_delay) {
    if (_delay !== null) {
      this.dom.body.delay(_delay).slideUp(() => this.dom.body.remove());
    }
    this.dom.body.slideUp(() => this.dom.body.remove());
    this.UpdateObservers('ItemDeleted', this);
  }
}
