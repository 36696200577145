/**
 * Page class for viewing/editing corrective actions
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */

class pSAMA_CorrectiveActionsEdit extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_COA_Detail', SAMA.pages.CorrectiveActionsList);

    this.header = new cSAMA_PageHeader('View Corrective Action', this.dom.page);

    this.dom.coaEditor = this.dom.page.find('#corrActEdit');
    this.dom.conList = this.dom.page.find('#hConCardList');
    this.dom.originalProblem = this.dom.page.find('#origProb');
    this.dom.timeLine = this.dom.page.find('#coaTimeLine');
  }

  /**
   * Function called when the user enters the page.
   * @param {object} _preseed
   * @override
   */
  EnterPage(_preseed) {
    if (_preseed == null && !_preseed.hasOwnProperty('corrActPk') && _preseed.corrActPk != null) {
      // Page needs a preseed.
      cSAMA_Dialog.ShowDialog({ title: 'Error', message: 'The Corrective Action could not be accessed.' });
      this.PageLoadFailed();
      return;
    }

    if (_preseed.hasOwnProperty('backPage') && _preseed.backPage != null) {
      this.SetTempPrevPage(_preseed.backPage);
    }

    cSAMA_Utils.WaitForCatTaggerLoad();
    let requests = [new cSAMA_Request('CorrectiveActions', 'getData'), new cSAMA_Request('Preferences', 'getData')];
    requests[0].Set('corrActPk', _preseed.corrActPk);

    cSAMA_Request.Dispatch({
      name: 'LoadCOAViewPage',
      reqs: requests,
      clientErrorCode: 'SAMA 64 A6 0A E7',
      fnSuccess: (_r) => this.CheckLockState(_r, _preseed.corrActPk),
    });
  }

  CheckLockState(_response, _coaPk) {
    SAMA.settings.preferences = new mSAMA_Preferences(_response[1]);
    this.riskMatrix = new mSAMA_RiskMatrix(_response[0].riskMatrix);
    this.report = mSAMA_Report.ReportFromRemoteData(_response[0], this.riskMatrix);

    if (this.report === false) {
      cSAMA_Dialog.ShowDialog({
        title: 'Error',
        message: 'The Corrective action could not be accessed because the data from the server was incomplete.',
      });
      this.PageLoadFailed(lock);
      return;
    }

    // Get the consequence and corrective action from the report
    this.FindCoa(_coaPk);

    this.resLock = new cSAMA_ResourceLock({
      what: 'CORRA',
      name: this.correctiveAction.sync.coa_name,
      pk: _coaPk,
      fnOpen: () => this.SetUpGui(_response),
      fnLocked: () => this.PageLoadFailed(),
    });

    if (this.correctiveAction.sync.coa_permissions === SAMA.enums.ACCESS.EDIT) {
      // Lock the record if we're in edit mode
      this.resLock.BeginLocking();
      return;
    }
    this.SetUpGui(_response);
  }

  SetUpGui(_response) {
    let lock = SAMA.GeneralGui.LockPageState('COAView.SetUpGui');

    let permissions = this.correctiveAction.sync.coa_permissions;

    if(this.correctiveAction.sync.coa_lastAction === 'CLOSD')
    {
      // If the CAP is closed, only viewing is allowed
      permissions = SAMA.enums.ACCESS.VIEW;
    }

    this.dom.originalProblem.text(this.report.sync.rep_problemDesc);

    let isReadOnly = false;
    switch (permissions) {
      case SAMA.enums.ACCESS.COMPLETE:
        this.header.AddButton(
          coSAMA_CorrectiveActions.ButtonComplete(this.correctiveAction.GetPk(), () => SAMA.Navigator.GoBack())
        );
        isReadOnly = true;
        break;

      case SAMA.enums.ACCESS.EDIT:
        if (this.correctiveAction.sync.coa_lastAction === 'COMPL') {
          this.header.AddButton(
            coSAMA_CorrectiveActions.ButtonReOpen(this.correctiveAction.GetPk(), () => SAMA.Navigator.GoBack())
          );
          this.header.AddButton(
            coSAMA_CorrectiveActions.ButtonClose(this.correctiveAction.GetPk(), () => SAMA.Navigator.GoBack())
          );
        } else {
          this.header.AddButton(
            coSAMA_CorrectiveActions.ButtonComplete(this.correctiveAction.GetPk(), () => SAMA.Navigator.GoBack())
          );
        }
        this.header.ButtonSaveDiscard();
        break;

      case SAMA.enums.ACCESS.VIEW:
        isReadOnly = true;
        break;

      default:
        cSAMA_Dialog.ShowDialog({
          title: 'Error',
          message: 'The Corrective action could not be accessed with the permissions you requested.',
        });
        this.PageLoadFailed(lock);
        return;
    }

    // Show the original consequence as a card
    let ccard = new vSAMA_TileConsequence(this.consequence, this.riskMatrix);
    ccard.GetDom().appendTo(this.dom.conList);

    // Create / Append the COA editor
    this.editorCoa = new vSAMA_EditorCorrectiveActions(this.correctiveAction, isReadOnly);
    this.editorCoa.GetDom().appendTo(this.dom.coaEditor);

    this.viewTimeLine = new vSAMA_ListView('No history exists for this corrective action.');

    if (!_response[0].hasOwnProperty('timeLine')) {
      _response[0].timeLine = [];
    }

    for (let i = 0; i < _response[0].timeLine.length; i++) {
      let tiLiIt = new mSAMA_TimeLineEntry(_response[0].timeLine[i]);
      let liViIt = new vSAMA_ListItemTimeLine(tiLiIt);

      this.viewTimeLine.AddItem(liViIt);
    }
    this.viewTimeLine.Refresh();
    this.viewTimeLine.GetDom().appendTo(this.dom.timeLine);

    SAMA.GeneralGui.UnlockPageState(lock);
    SAMA.Navigator.FinishedLoading(this.header);
  }

  FindCoa(_coaPk) {
    let consequences = this.report.GetConsequences();

    for (let p = 0; p < consequences.length; p++) {
      let coa = consequences[p].GetCorrectiveAction();

      if (+coa.sync.coa_primaryKey !== +_coaPk) {
        continue;
      }

      this.consequence = consequences[p];
      this.correctiveAction = coa;
      break;
    }
  }

  SaveHandler() {
    if (this.dom.page.find('.sama-form-error').length > 0) {
      cSAMA_Dialog.ShowDialog({
        title: 'Error',
        message: 'Some fields in this form have bad input. Please check the form and correct the issues.',
      });
      return;
    }

    cSAMA_Dialog.ShowConfirm({
      title: 'Confirm Save',
      message: 'Responsible Staff will be informed of your changes. Do you want to save them now?',
      yesFunc: () => {
        let r = new cSAMA_Request('CorrectiveActions', 'setData');
        r.Set('corrAct', this.correctiveAction.GetTransmissible());
        r.Set('notify', true);

        cSAMA_Request.Dispatch({
          name: 'SaveCOA',
          reqs: r,
          clientErrorCode: 'SAMA 78 8A 82 AC',
          fnSuccess: (_r) => this.AfterSave(_r[0]),
          goBackOnError: false,
        });
      },
    });
  }

  /**
   * Function called when the user leaves the page.
   * @override
   */
  LeavePage() {
    SAMA.Navigator.FinishedLoading();
    this.resLock.StopLocking();

    $('body').off('.samaevt');
    this.correctiveAction = null;
    this.dom.conList.html('');

    if (this.loaded === false) {
      return;
    }

    this.editorCoa.Destroy();
    this.editorCoa = null;

    this.viewTimeLine.Destroy();
    this.viewTimeLine = null;
  }

  AfterSave(_r) {
    if (_r.hasOwnProperty('duplicateId') && _r.duplicateId > 0) {
      cSAMA_Dialog.ShowConfirm({
        title: 'Duplicate ID',
        message: "The ID you're trying to assign is already in use. Do you want to change it?",
        noFunc: () => {
          SAMA.Navigator.GoBack();
        },
      });
    } else {
      SAMA.Navigator.GoBack();
    }
  }
}
