/**
 * General functions for enhancing inputs within the SAMA module
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */
class cSAMA_GeneralInputs {
  /**
   * Applies a date input unless we're on mobile
   * @param {jQuery}  _selector
   * @param {boolean} [_future]   If false, doesn't allow picking dates past today
   * @param {boolean} [_past]   If false, doesn't allow picking dates before today
   * @returns {jQuery}
   */
  static Date(_selector, _future, _past) {
    let icon = cSAMA_GeneralInputs.AddIcon(_selector, 'calendar');

    if (cSAMA_Utils.IsMobile()) {
      return _selector;
    }

    let sD, eD;

    sD = _past ? null : new Date();
    eD = _future ? null : new Date();

    _selector.attr('type', 'text');
    _selector.attr('emulate-type', 'date');
    _selector.datepicker({
      format: 'yyyy-mm-dd',
      startDate: sD,
      endDate: eD,
      weekStart: '1',
      autoHide: true,
    });

    return _selector;
  }

  /**
   * Applies a time input unless we're on mobile
   * @param {jQuery} _selector
   * @returns {jQuery}
   */
  static Time(_selector) {
    // NOTE a more user friendly desktop time select would be nice.
    let icon = cSAMA_GeneralInputs.AddIcon(_selector, 'clock-o');

    if (cSAMA_Utils.IsMobile()) {
      return _selector;
    }
    return _selector;
  }

  static Color(_selector) {
    let icon = cSAMA_GeneralInputs.AddIcon(_selector, 'paint-brush');

    let jsc = new jscolor(_selector[0]);

    _selector.on('change', () => {
      let bg = _selector.css('background-color');
      let tx = _selector.css('color');

      icon.css({
        'background-color': bg,
        color: tx,
      });
    });

    let bg = _selector.css('background-color');
    let tx = _selector.css('color');

    icon.css({
      'background-color': bg,
      color: tx,
    });

    _selector.data('jsc', jsc);

    return jsc;
  }

  /**
   * Adds an icon to the given input
   * @param _selector
   * @param _icon
   * @returns {*|jQuery}
   * @constructor
   */
  static AddIcon(_selector, _icon) {
    let parent = _selector.parent().hasClass('sama-icon-input');
    let grandparent = _selector.parent().parent().hasClass('sama-icon-input');

    let icon = $('<div/>')
      .addClass('sama-icon')
      .append($('<i/>').addClass('fal fa-' + _icon));

    if (parent) {
      icon = _selector.parent().find('.sama-icon');
    } else if (grandparent) {
      icon = _selector.parent().parent().find('.sama-icon');
    } else if (!parent && !grandparent) {
      let outsideDiv = $('<div/>').addClass('sama-icon-input');

      _selector.wrap(outsideDiv);
      _selector.before(icon);
    }

    return icon;
  }

  /**
   * Places a signature pad in the given selector
   * @param {object} [_options]
   * @param {function} [_options.clearCallback]
   * @param {function} [_options.inputCallback]
   * @param _selector
   */
  static SignaturePad(_selector, _options) {
    let cv = $('<canvas/>').addClass('sama-signature-pad');
    let bbar = $('<div/>').addClass('sama-signature-buttons');
    let btn_clear = $('<button/>')
      .addClass('ui-btn ui-mini ui-corner-all')
      .append($('<i/>').addClass('fal fa-times'))
      .append($('<span/>').text('Clear'));

    bbar.append(btn_clear);

    _selector.append(cv).append(bbar);

    if (_options == null) {
      _options = {
        inputCallback: null,
        clearCallback: null,
      };
    }

    if (!_options.hasOwnProperty('inputCallback')) {
      _options.inputCallback = null;
    }

    let options = {
      backgroundColor: 'rgb(238, 238, 238)',
      penColor: 'rgb(58, 62, 91)',
      onEnd: () => _options.inputCallback(),
    };

    let canvas = cv.get(0);
    let signaturePad = new SignaturePad(canvas, options);

    signaturePad.canvasElement = canvas;

    btn_clear.on('click', () => {
      signaturePad.clear();
      if (_options.hasOwnProperty('clearCallback')) {
        _options.clearCallback();
      }
    });

    return signaturePad;
  }

  /**
   *
   * @param _sigPad Signature pad object with canvasElement property
   */
  static ResizeSignaturePad(_sigPad) {
    if (!_sigPad.hasOwnProperty('canvasElement')) {
      console.error('Canvas element not found in sigPad');
      return;
    }

    let ratio = Math.max(window.devicePixelRatio || 1, 1);

    let w = _sigPad.canvasElement.offsetWidth;
    let h = w * 0.333;

    _sigPad.canvasElement.width = w * ratio;
    _sigPad.canvasElement.height = h * ratio;

    _sigPad.canvasElement.getContext('2d').scale(ratio, ratio);

    _sigPad.clear();
  }

  /**
   * Apply a default Keyword input to _selector
   * @param {jQuery} _selector
   * @param {boolean} [_readonly]       Is the input read only
   * @param {boolean} [_newTagsAllowed] Setting this false superseeds prf_allowCustKwds
   * @param {array}   [_staticTagCache] Cache of tags that are not dynamically loaded from the server.
   */
  static Keyword(_selector, _readonly, _newTagsAllowed, _staticTagCache) {
    _readonly = _readonly == null ? false : _readonly;
    _newTagsAllowed = _newTagsAllowed == null ? true : _newTagsAllowed;

    if (_staticTagCache != null) {
      _selector.catTagger(_staticTagCache, {
        showAllButton: true,
        permissions: {
          allowNewTag: false,
        },
        remote: {
          url: '',
        },
        inputField: {
          maxTags: 500,
          enabled: !_readonly,
        },
      });
      return;
    }

    _selector.catTagger(null, {
      showAllButton: true,
      bannedWords: SAMA.settings.preferences.GetBadWords(),
      permissions: {
        allowNewTag: _newTagsAllowed && SAMA.settings.preferences.sync.prf_allowCustKwds,
      },
      remote: {
        data: {
          topic: 'Keywords',
          action: 'getData',
        },
      },
      inputField: {
        maxTags: 500,
        enabled: !_readonly,
      },
    });
  }

  /**
   * Apply a default reports input to _selector
   * @param {jQuery} _selector
   * @param {boolean} [_readonly]
   */
  static Reports(_selector, _readonly) {
    _readonly = _readonly == null ? false : _readonly;

    _selector.catTagger(null, {
      showAllButton: true,
      remote: {
        data: {
          topic: 'MultiSelect',
          action: 'getReportData',
        },
      },
      inputField: {
        maxTags: 1,
        enabled: !_readonly,
      },
      messages: {
        tag: 'report',
        category: 'department',
        placeholderCatTag: 'Type summary to find reports',
      },
    });
  }

  /**
   * Attach a CatTagger input to _selector, suggesting people
   * @param {jQuery} _selector
   * @param {boolean} [_readonly]
   * @param {int}     [_maxStaff]
   * @param {array}   [_staticTagCache] Cache of employees that are not dynamically loaded from the server.
   */
  static Staff(_selector, _readonly, _maxStaff, _staticTagCache) {
    _readonly = _readonly == null ? false : _readonly;
    _maxStaff = _maxStaff == null ? 1000 : _maxStaff;

    if (_staticTagCache != null) {
      _selector.catTagger(_staticTagCache, {
        showAllButton: true,
        permissions: {
          allowNewTag: false,
        },
        remote: {
          url: '',
        },
        inputField: {
          maxTags: _maxStaff,
          enabled: !_readonly,
        },
        messages: {
          category: 'department',
          tag: 'employee',
        },
      });
      return;
    }

    _selector.catTagger(null, {
      showAllButton: true,
      permissions: {
        allowNewTag: false,
      },
      inputField: {
        maxTags: _maxStaff,
        enabled: !_readonly,
      },
      remote: {
        fetchOnce: true,
        data: {
          topic: 'MultiSelect',
          action: 'getStaffData',
        },
      },
      messages: {
        category: 'department',
        tag: 'employee',
      },
    });
  }

  /**
   * Attach a CatTagger input to _selector, suggesting Gear
   * @param {jQuery} _selector
   * @param {boolean} [_readonly]
   */
  static Gear(_selector, _readonly) {
    _readonly = _readonly == null ? false : _readonly;

    _selector.catTagger(null, {
      showAllButton: true,
      remote: {
        fetchOnce: true,
        data: {
          topic: 'MultiSelect',
          action: 'getGearData',
        },
      },
      inputField: {
        maxTags: 5,
        enabled: !_readonly,
      },
      messages: {
        tag: 'asset',
      },
    });
  }

  /**
   * Attach a CatTagger input to _selector, suggesting Aircraft
   * @param {jQuery} _selector
   * @param {boolean} [_readonly]
   */
  static Aircraft(_selector, _readonly) {
    _readonly = _readonly == null ? false : _readonly;

    _selector.catTagger(null, {
      showAllButton: true,
      remote: {
        fetchOnce: true,
        data: {
          topic: 'MultiSelect',
          action: 'getCraftData',
        },
      },
      inputField: {
        maxTags: 5,
        enabled: !_readonly,
      },
      messages: {
        tag: 'ident',
        category: 'Model',
      },
    });
  }

  /**
   * Attach a CatTagger input to _selector, suggesting Bases
   * @param {jQuery} _selector
   * @param {boolean} [_readonly]
   */
  static Bases(_selector, _readonly) {
    _readonly = _readonly == null ? false : _readonly;

    _selector.catTagger(null, {
      showAllButton: true,
      use: 'tags',
      remote: {
        fetchOnce: true,
        data: {
          topic: 'MultiSelect',
          action: 'getBaseData',
        },
      },
      inputField: {
        maxTags: 5,
        enabled: !_readonly,
      },
      messages: {
        tag: 'Base',
      },
    });
  }

  /**
   * Unlinks inputs from sync memebers
   * @param {jQuery} _scope
   */
  static UnLinkInputs(_scope) {
    _scope.find('.sama-linked').off('.samaevt').removeClass('sama-linked').val('');
  }

  /**
   * Fills html elements in _scope with the content of _syncmember using OREF tags.
   * @param {mSAMA_AbstractModel} _syncMember
   * @param {jQuery} _scope
   */
  static FillMarkup(_syncMember, _scope) {
    let allOutputs = _scope.find('[data-oref]').not('.sama-linked');
    allOutputs.each((_i, _e) => {
      let out = $(_e);
      let propName = out.data('oref');

      if (_syncMember.sync[propName] === undefined) {
        return;
      }

      if (out.prop('tagName') === 'IMG') {
        out.attr('src', _syncMember.sync[propName]);
        return;
      }

      let text = _syncMember.sync[propName];
      if(out.hasClass('sama-block-text'))
      {
        text = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
      }

      out.html(text);
    });
  }

  /**
   * Fills the inputs in _scope with values from _syncMember without attaching any listeners
   * @param {mSAMA_AbstractModel} _syncMember        The object containing values
   * @param {jQuery}                  _scope             The parent element containing inputs
   */
  static FillInputs(_syncMember, _scope) {
    let allInputs = _scope.find('[data-sref]').not('.sama-linked');
    let allOutputs = _scope.find('[data-oref]').not('.sama-linked');

    allInputs.each((_i, _e) => {
      let inp = $(_e);
      let propName = $(inp).data('sref');

      if (typeof _syncMember.sync[propName] === 'undefined') {
        return;
        //throw('Template requested % but the property does not exist.'.tr(propName));
      }

      allOutputs.filter('[data-oref="%"]'.tr(propName)).text(_syncMember.sync[propName]).addClass('.sama-linked');

      if (inp.data('type') === 'range' || inp.attr('type') === 'range') {
        // Is slider!
        inp.val(_syncMember.sync[propName]).attr('value', _syncMember.sync[propName]);
      } else if (inp.attr('type') === 'checkbox') {
        inp.prop('checked', _syncMember.sync[propName]);
        try {
          inp.checkboxradio('refresh');
        } catch (_e) {
          // Checkboxes are snowflakes that won't update if you don't refresh them.
          // If this runs before a specific checkbox is created by jMobile, everything dies.
          // So, exception, my ass.
        }
      } else {
        cSAMA_GeneralInputs.AssignPropValues(inp, _syncMember, propName);
      }

      inp.addClass('sama-linked');
    });

    allInputs.prop('disabled', true);
  }

  /**
   * Applies a decoded version of the sync value to the given member and input
   * @param _input
   * @param _syncMember
   * @param _propName
   */
  static AssignPropValues(_input, _syncMember, _propName) {
    if (_input.attr('type') === 'text' || _input.prop('tagName').toLowerCase() === 'textarea') {
      _syncMember.sync[_propName] = cSAMA_Utils.DecodeEntities(_syncMember.sync[_propName]);
    }

    _input.val(_syncMember.sync[_propName]);

    if (_input.attr('type') !== 'date' && _input.attr('type') !== 'time') {
      _input.attr('value', _syncMember.sync[_propName]);
    }
  }

  /**
   * Links the inputs in _syncMember to inputs/outputs in _scope
   * @param {mSAMA_AbstractModel} _syncMember        The object containing values
   * @param {jQuery}                  _scope             The parent element containing inputs
   * @param {*}                       [_view]            The object containing functions to be run
   */
  static LinkInputs(_syncMember, _scope, _view) {
    let allInputs = _scope.find('[data-sref]').not('.sama-linked');
    let allOutputs = _scope.find('[data-oref]').not('.sama-linked');
    let allButtonFunctions = _scope.find('button[data-fref]').not('.sama-linked');

    _syncMember.EnableValidation(_scope);

    allInputs.prop('disabled', false);
    allInputs.each((_i, _e) => {
      let inp = $(_e);
      let propName = $(inp).data('sref');

      if (typeof _syncMember.sync[propName] === 'undefined') {
        throw 'Template requested % but the property does not exist.'.tr(propName);
      }

      let outs = allOutputs
        .filter('[data-oref="%"]'.tr(propName))
        .text(_syncMember.sync[propName])
        .addClass('.sama-linked');

      let updateEvent = 'input';
      let element = 0;
      if (inp.data('type') === 'range' || inp.attr('type') === 'range') {
        // Is slider!
        updateEvent = 'change';
        element = 1;

        inp.val(_syncMember.sync[propName]).attr('value', _syncMember.sync[propName]);
      } else if (inp.attr('type') === 'checkbox') {
        // Is checkbox
        updateEvent = 'change';
        element = 2;
        inp.prop('checked', _syncMember.sync[propName]);

        try {
          inp.checkboxradio('refresh');
        } catch (_e) {
          // Checkboxes are snowflakes that won't update if you don't refresh them.
          // If this runs before a specific checkbox is created by jMobile, everything dies.
          // So, exception, my ass.
        }
      } else if (inp.prop('tagName') === 'SELECT') {
        // Is Select
        updateEvent = 'change';

        inp.val(_syncMember.sync[propName]);
      } else {
        cSAMA_GeneralInputs.AssignPropValues(inp, _syncMember, propName);
      }

      inp.trigger(updateEvent).addClass('sama-linked');

      updateEvent += '.samaevt';

      if (element === 1) {
        // Is a slider and needs special treatment (only update when value is changed!)
        if (outs.length > 0) {
          outs.text(_syncMember.sync[propName]);
        }
        inp.on(updateEvent, () => {
          if (+_syncMember.sync[propName] !== +inp.val()) {
            cSAMA_GeneralInputs.LinkedUpdate(propName, _syncMember, inp, _view);
            if (outs.length > 0) {
              outs.text(_syncMember.sync[propName]);
            }
          }
        });
      } else if (element === 2) {
        // Is a checkbox and needs special treatment
        inp.on(updateEvent, () => {
          _syncMember.sync[propName] = inp.prop('checked');
          _syncMember.SetModified(true);

          if (inp.data('fref') != null) {
            if (_view == null) {
              _syncMember[inp.data('fref')](inp);
            } else {
              _view[inp.data('fref')](inp);
            }
          }

          SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        });
      } else if (outs.length > 0) {
        // Has outputs, thus needs to update their text with the new value
        inp.on(updateEvent, () => {
          cSAMA_GeneralInputs.LinkedUpdate(propName, _syncMember, inp, _view);
          if (!outs.hasClass('ui-tabs-anchor') || _syncMember.sync[propName].length >= 3) {
            // If the output is not a tab rider or the prop has at least 3 characters
            outs.text(_syncMember.sync[propName]);
          }
        });
      } else {
        // Normal input with no bells or whistles
        inp.on(updateEvent, () => {
          cSAMA_GeneralInputs.LinkedUpdate(propName, _syncMember, inp, _view);
        });
      }

      // Finally, run the value change and validation function once
      if (inp.data('fref') != null && inp.data('firstrun') !== false) {
        if (_view == null) {
          _syncMember[inp.data('fref')](inp);
        } else {
          _view[inp.data('fref')](inp);
        }
      }

      inp.trigger('validate');
    });

    allButtonFunctions.each((_i, _e) => {
      let btn = $(_e);
      btn.addClass('.sama-linked');
      btn.on('click', _syncMember[btn.data('fref')]);
    });
  }

  /**
   * Common operations for all inputs
   * @param {string}                  _propName
   * @param {mSAMA_AbstractModel} _syncMember
   * @param {jQuery}                  _input
   * @param {*}                       [_view]            The object containing functions to be run
   */
  static LinkedUpdate(_propName, _syncMember, _input, _view) {
    _syncMember.sync[_propName] = _input.val();
    _syncMember.SetModified(true);

    // Validate input
    _input.trigger('validate');

    if (_input.data('fref') != null) {
      if (_view == null) {
        _syncMember[_input.data('fref')](_input);
      } else {
        _view[_input.data('fref')](_input);
      }
    }

    if (_input.hasClass('cttr-keyword-input-loading')) {
      // Taggers don't dirty the page state on initialization.
      return;
    }
    // console.log('%c%s %s', 'color:#0066ff', 'SAMA-IP', _propName);
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }
}
