class MobileDownload {
  static async get(url) {
    $.mobile.loading('show', { theme: 'a' });

    verbose.log('LFS', 'Getting', url);

    try {
      const result = await this.xhr(url);
      await MobileDownload.downloadHandler(result);
    } catch (e) {
      verbose.error('LFS', e);
    }

    $.mobile.loading('hide');
  }

  static async xhr(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onerror = reject;
      xhr.onabort = reject;
      xhr.onload = function () {
        resolve(this);
      };
      xhr.open('GET', url, true);
      xhr.setRequestHeader('X-Auth-Token', window.vueApp.authTools.authGetToken());
      xhr.send();
    });
  }

  /**
   *
   * @param {XMLHttpRequest} xhr
   * @param overrideFileName
   * @private
   */
  static async downloadHandler(xhr, overrideFileName = null) {
    if (xhr.status !== 200) {
      throw new Error('Could not download file at ' + xhr.responseURL);
    }

    const fileContent = xhr.response;
    let mimeType = xhr.getResponseHeader('Content-Type');
    let fileName = xhr.responseURL.split('/').pop();

    //Use the filename from the header if we can find one
    let disposition = xhr.getResponseHeader('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      let filenameRegex = /filename\s?=\s?['"](.*?)['"]/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches.length > 1) {
        fileName = vueApp.utilities.file.sanitizeFileName(matches[1]);
      }
    }

    fileName = overrideFileName ?? fileName;
    if (fileName.toLowerCase().indexOf('.pdf') > -1) {
      mimeType = 'application/pdf';
    }

    verbose.log('FS', 'Downloaded file', fileName, 'with mime', mimeType);

    const filePath = vueApp.utilities.file.dirTmp + fileName;
    await vueApp.utilities.file.writeFile(filePath, fileContent);
    await vueApp.utilities.file.openFileExternal(filePath, mimeType, fileName);
  }
}

function PGdownloadFile(URL) {
  MobileDownload.get(URL);
}

var gettingBigFile = false;

function gettingBigFile_Cancel() {
  gettingBigFile.abort();
  gettingBigFile = false;
  $('#downloadProgress').hide();
  $('#downloadProgressTXT').hide();
  window.toaster.show(iTrans('Download Canceled.'), 5);
}

async function Downloader_getBigFile(filename, fromURL, mimeType) {
  gettingBigfile = true;

  const dl = window.vueApp.utilities.download.fromUrl(fromURL);

  const downloadProgressBar = $('#downloadProgress');
  const downloadProgressText = $('#downloadProgressTXT');

  downloadProgressBar.css('width', '0%').show();

  $('#UpdateTilesBTN').prop('disabled', true);
  $('#CancelTilesBTN').prop('disabled', false);

  const cancelLink = `<a href="#" style="color: red;font-size: larger;" onClick="gettingBigFile_Cancel()">[Cancel]</a>`;

  downloadProgressText.html(`Initializing Download... ${cancelLink}`);
  downloadProgressText.show();

  const progressCb = (progressEvent) => {
    if (!progressEvent.lengthComputable) {
      downloadProgressText.html('Downloading: ' + filename);
      return;
    }

    let freeSpace = FREE_SPACE - progressEvent.loaded;
    let progressRemaining = progressEvent.total - progressEvent.loaded;

    if (progressRemaining > freeSpace) {
      dl.cancel();
      gettingBigFile = false;

      downloadProgressBar.hide();
      downloadProgressText.hide();

      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Error',
        headerClose: false,
        buttonPrompt: `${filename} failed to download because your device is running low on storage.`,
        buttons: { OK: { click: () => {} } },
      });
      return;
    }

    let mbLoaded = Math.round(progressEvent.loaded / 1e6);
    let mbTotal = Math.round(progressEvent.total / 1e6);

    let percent = Math.round((progressEvent.loaded / progressEvent.total) * 10000) / 100;
    let stats = `<span>Downloading: ${filename} ${mbLoaded}MB / ${mbTotal}MB - ${percent}%</span>${cancelLink}`;

    downloadProgressText.html(stats);
    downloadProgressBar.css('width', percent + '%');
  };

  try {
    dl.onProgress(progressCb);
    dl.setMimeType(mimeType);
    await dl.toExternal(null, filename);
  } catch (e) {
    verbose.error('fs', e);
  }

  downloadProgressBar.hide();
  downloadProgressText.hide();
  gettingBigFile = false;
}
