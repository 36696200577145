var WX_Cams = [
  {
    locationId: 1024,
    latitude: 34.63694,
    longitude: -86.775,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 694,
      siteName: 'Intermodal',
      siteIdentifier: 'HSV',
      latitude: 34.62016,
      longitude: -86.75567,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10432,
    latitude: 40.569,
    longitude: -74.97525,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 619,
      siteName: 'Sky Manor Airport',
      siteIdentifier: 'N40',
      latitude: 40.569,
      longitude: -74.97525,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10557,
    latitude: 30.679642,
    longitude: -88.18986,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 724,
      siteName: 'Mobile West',
      latitude: 30.679642,
      longitude: -88.18986,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 206,
    latitude: 44.80722,
    longitude: -68.82806,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 808,
      siteName: 'Bangor International AIrport',
      siteIdentifier: 'BGR',
      latitude: 44.793392,
      longitude: -68.81423,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 207,
    latitude: 44.44944,
    longitude: -68.36139,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 620,
      siteName: 'Hancock County/Bar Harbor Airport',
      siteIdentifier: 'BHB',
      latitude: 44.446346,
      longitude: -68.359634,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 242,
    latitude: 44.57528,
    longitude: -71.17583,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 734,
      siteName: 'Berlin Regional Airport',
      siteIdentifier: 'BML',
      latitude: 44.575848,
      longitude: -71.17815,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 266,
    latitude: 44.40917,
    longitude: -69.01167,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 600,
      siteName: 'Belfast Municipal Airport',
      siteIdentifier: 'BST',
      latitude: 44.410336,
      longitude: -69.01114,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 298,
    latitude: 43.89222,
    longitude: -69.93861,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 601,
      siteName: 'Brunswick Executive Airport',
      siteIdentifier: 'BXM',
      latitude: 43.898438,
      longitude: -69.934204,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 311,
    latitude: 46.87139,
    longitude: -68.01806,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 602,
      siteName: 'Caribou Municipal Airport',
      siteIdentifier: 'CAR',
      latitude: 46.87102,
      longitude: -68.012695,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3264,
    latitude: 47.1371,
    longitude: -55.3292,
    timeZoneId: 'America/St_Johns',
    data: {
      siteId: 398,
      siteName: 'Winterland',
      siteIdentifier: '',
      latitude: 47.13694,
      longitude: -55.32917,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3550,
    latitude: 45.4192,
    longitude: -63.4613,
    timeZoneId: 'America/Halifax',
    data: {
      siteId: 400,
      siteName: 'Debert',
      siteIdentifier: '',
      latitude: 45.41861,
      longitude: -63.45,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3466,
    latitude: 54.1795,
    longitude: -58.4571,
    timeZoneId: 'America/Goose_Bay',
    data: {
      siteId: 396,
      siteName: 'Rigolet',
      siteIdentifier: '',
      latitude: 54.17972,
      longitude: -58.4575,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3124,
    latitude: 52.7087,
    longitude: -88.5421,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 526,
      siteName: 'Summer Beaver',
      siteIdentifier: '',
      latitude: 52.70861,
      longitude: -88.54195,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3080,
    latitude: 52.8939,
    longitude: -89.2891,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 504,
      siteName: 'Wunnumin Lake',
      siteIdentifier: '',
      latitude: 52.89389,
      longitude: -89.28917,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3012,
    latitude: 43.7598,
    longitude: -79.8739,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 458,
      siteName: 'Brampton-Caledon',
      siteIdentifier: '',
      latitude: 43.759056,
      longitude: -79.87732,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3332,
    latitude: 55.9139,
    longitude: -61.1844,
    timeZoneId: 'America/Goose_Bay',
    data: {
      siteId: 395,
      siteName: 'Natuashish',
      siteIdentifier: '',
      latitude: 55.91389,
      longitude: -61.1844,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3412,
    latitude: 45.2576,
    longitude: -79.8297,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 364,
      siteName: 'Parry Sound',
      siteIdentifier: '',
      latitude: 45.2575,
      longitude: -79.82972,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2995,
    latitude: 44.6394,
    longitude: -75.7502,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 346,
      siteName: 'Brockville',
      siteIdentifier: '',
      latitude: 44.63611,
      longitude: -75.75129,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3224,
    latitude: 53.0125,
    longitude: -89.8552,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 515,
      siteName: 'Kingfisher Lake',
      siteIdentifier: '',
      latitude: 53.0125,
      longitude: -89.85528,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3021,
    latitude: 45.4138,
    longitude: -76.3657,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 342,
      siteName: 'Arnprior',
      siteIdentifier: '',
      latitude: 45.41361,
      longitude: -76.36583,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2974,
    latitude: 45.0731,
    longitude: -77.8806,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 344,
      siteName: 'Bancroft',
      siteIdentifier: '',
      latitude: 45.07306,
      longitude: -77.88056,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3615,
    latitude: 44.4494,
    longitude: -80.1592,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 350,
      siteName: 'Collingwood',
      siteIdentifier: '',
      latitude: 44.44917,
      longitude: -80.15833,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2905,
    latitude: 43.9294,
    longitude: -59.9597,
    timeZoneId: 'America/Halifax',
    data: {
      siteId: 482,
      siteName: 'Sable Island',
      siteIdentifier: '',
      latitude: 43.933926,
      longitude: -59.91712,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2907,
    latitude: 46.5648,
    longitude: -75.5762,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 381,
      siteName: 'Mont Laurier',
      siteIdentifier: '',
      latitude: 46.56444,
      longitude: -75.57778,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3416,
    latitude: 53.7547,
    longitude: -73.6753,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 377,
      siteName: 'La Grande-4',
      siteIdentifier: '',
      latitude: 53.75472,
      longitude: -73.67528,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3146,
    latitude: 53.5248,
    longitude: -88.6429,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 514,
      siteName: 'Kasabonika',
      siteIdentifier: '',
      latitude: 53.524723,
      longitude: -88.64306,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3609,
    latitude: 52.9276,
    longitude: -82.432,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 343,
      siteName: 'Attawapiskat',
      siteIdentifier: '',
      latitude: 52.9275,
      longitude: -82.43194,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3333,
    latitude: 51.3919,
    longitude: -56.0832,
    timeZoneId: 'America/St_Johns',
    data: {
      siteId: 397,
      siteName: 'St. Anthony',
      siteIdentifier: '',
      latitude: 51.39194,
      longitude: -56.08306,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3144,
    latitude: 49.1325,
    longitude: -68.2044,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 371,
      siteName: 'Baie Comeau',
      siteIdentifier: '',
      latitude: 49.12361,
      longitude: -68.202446,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2818,
    latitude: 53.6826,
    longitude: -57.042,
    timeZoneId: 'America/Goose_Bay',
    data: {
      siteId: 509,
      siteName: 'Cartwright',
      siteIdentifier: '',
      latitude: 53.6825,
      longitude: -57.041943,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2956,
    latitude: 45.0927,
    longitude: -74.5678,
    timeZoneId: null,
    data: {
      siteId: 351,
      siteName: 'Cornwall',
      siteIdentifier: '',
      latitude: 45.0925,
      longitude: -74.56833,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3452,
    latitude: 43.2857,
    longitude: -81.5064,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 348,
      siteName: 'Centrailia',
      siteIdentifier: '',
      latitude: 43.28556,
      longitude: -81.50833,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2812,
    latitude: 47.0058,
    longitude: -65.4568,
    timeZoneId: 'America/Moncton',
    data: {
      siteId: 392,
      siteName: 'Miramichi',
      siteIdentifier: '',
      latitude: 47.0075,
      longitude: -65.44917,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2810,
    latitude: 42.3062,
    longitude: -82.0817,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 349,
      siteName: 'Chatham Kent',
      siteIdentifier: '',
      latitude: 42.30639,
      longitude: -82.08194,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3158,
    latitude: 47.991,
    longitude: -66.3304,
    timeZoneId: 'America/Moncton',
    data: {
      siteId: 489,
      siteName: 'Charlo',
      siteIdentifier: '',
      latitude: 47.99083,
      longitude: -66.33028,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3705,
    latitude: 46.3516,
    longitude: -82.561,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 354,
      siteName: 'Elliot Lake',
      siteIdentifier: '',
      latitude: 46.35167,
      longitude: -82.56111,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3529,
    latitude: 56.019,
    longitude: -87.6762,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 464,
      siteName: 'Fort Severn',
      siteIdentifier: 'YER',
      latitude: 56.018414,
      longitude: -87.678925,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3422,
    latitude: 52.2035,
    longitude: -81.6958,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 495,
      siteName: 'Fort Albany',
      siteIdentifier: '',
      latitude: 52.20361,
      longitude: -81.69583,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2783,
    latitude: 45.8689,
    longitude: -66.5372,
    timeZoneId: 'America/Moncton',
    data: {
      siteId: 486,
      siteName: 'Fredericton',
      siteIdentifier: 'YFC',
      latitude: 45.871807,
      longitude: -66.52913,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2939,
    latitude: 51.562,
    longitude: -87.9077,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 511,
      siteName: 'Fort Hope',
      siteIdentifier: '',
      latitude: 51.561943,
      longitude: -87.907776,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2782,
    latitude: 46.4091,
    longitude: -74.7801,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 470,
      siteName: 'La Macaza / Mont Tremblant',
      siteIdentifier: '',
      latitude: 46.405163,
      longitude: -74.77845,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2770,
    latitude: 50.2819,
    longitude: -63.6111,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 375,
      siteName: 'Havre St. Pierre',
      siteIdentifier: '',
      latitude: 50.28194,
      longitude: -63.61139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2769,
    latitude: 55.282,
    longitude: -77.7653,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 376,
      siteName: 'Kuujjuarapik',
      siteIdentifier: '',
      latitude: 55.28194,
      longitude: -77.76528,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2763,
    latitude: 49.714,
    longitude: -83.6871,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 357,
      siteName: 'Hearst',
      siteIdentifier: '',
      latitude: 49.71389,
      longitude: -83.68694,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2758,
    latitude: 50.469,
    longitude: -59.6367,
    timeZoneId: 'America/Blanc-Sablon',
    data: {
      siteId: 374,
      siteName: 'Chevery',
      siteIdentifier: '',
      latitude: 50.46889,
      longitude: -59.63667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2757,
    latitude: 44.158,
    longitude: -81.0632,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 356,
      siteName: 'Hanover',
      siteIdentifier: '',
      latitude: 44.15833,
      longitude: -81.06278,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3275,
    latitude: 45.5181,
    longitude: -73.417,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 388,
      siteName: 'St. Hubert',
      siteIdentifier: '',
      latitude: 45.5175,
      longitude: -73.41667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2755,
    latitude: 44.8797,
    longitude: -63.5102,
    timeZoneId: 'America/Halifax',
    data: {
      siteId: 467,
      siteName: 'Halifax / Stanfield',
      siteIdentifier: 'YHZ',
      latitude: 44.88107,
      longitude: -63.501244,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3299,
    latitude: 44.5459,
    longitude: -65.7857,
    timeZoneId: 'America/Halifax',
    data: {
      siteId: 390,
      siteName: 'Digby',
      siteIdentifier: '',
      latitude: 44.54556,
      longitude: -65.7889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3562,
    latitude: 43.4608,
    longitude: -80.3787,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 358,
      siteName: 'Kitchener - Waterloo',
      siteIdentifier: '',
      latitude: 43.46083,
      longitude: -80.37861,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2744,
    latitude: 54.8052,
    longitude: -66.8053,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 385,
      siteName: 'Schefferville',
      siteIdentifier: '',
      latitude: 54.80528,
      longitude: -66.80528,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2743,
    latitude: 44.2014,
    longitude: -81.6057,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 359,
      siteName: 'Kincardine',
      siteIdentifier: '',
      latitude: 44.20139,
      longitude: -81.60667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2741,
    latitude: 51.6587,
    longitude: -85.901,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 474,
      siteName: 'Ogoki Post',
      siteIdentifier: '',
      latitude: 51.65649,
      longitude: -85.90553,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2761,
    latitude: 52.1954,
    longitude: -87.9341,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 471,
      siteName: 'Lansdowne House',
      siteIdentifier: 'YLH',
      latitude: 52.197174,
      longitude: -87.93438,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3220,
    latitude: 47.4097,
    longitude: -72.7889,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 378,
      siteName: 'La Tuque',
      siteIdentifier: '',
      latitude: 47.40972,
      longitude: -72.78889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2730,
    latitude: 52.3028,
    longitude: -55.8478,
    timeZoneId: 'America/St_Johns',
    data: {
      siteId: 472,
      siteName: 'Marys Harbour',
      siteIdentifier: 'YMH',
      latitude: 52.304333,
      longitude: -55.84172,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2729,
    latitude: 47.5976,
    longitude: -70.2239,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 373,
      siteName: 'Charlevoix',
      siteIdentifier: '',
      latitude: 47.5975,
      longitude: -70.22389,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2727,
    latitude: 51.292,
    longitude: -80.6083,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 360,
      siteName: 'Moosonee',
      siteIdentifier: '',
      latitude: 51.29111,
      longitude: -80.60778,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2726,
    latitude: 49.7719,
    longitude: -74.528,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 490,
      siteName: 'Chibougamau/Chapais',
      siteIdentifier: '',
      latitude: 49.77194,
      longitude: -74.52806,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3497,
    latitude: 46.2728,
    longitude: -75.9905,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 379,
      siteName: 'Maniwaki',
      siteIdentifier: '',
      latitude: 46.28289,
      longitude: -75.99056,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3003,
    latitude: 50.1901,
    longitude: -61.7889,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 383,
      siteName: 'Natashquan',
      siteIdentifier: '',
      latitude: 50.19,
      longitude: -61.7889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3252,
    latitude: 49.7618,
    longitude: -77.8027,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 380,
      siteName: 'Matagami',
      siteIdentifier: '',
      latitude: 49.76167,
      longitude: -77.80289,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3517,
    latitude: 43.9228,
    longitude: -78.8967,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 363,
      siteName: 'Oshawa',
      siteIdentifier: '',
      latitude: 43.92278,
      longitude: -78.895,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3324,
    latitude: 45.6565,
    longitude: -61.3684,
    timeZoneId: 'America/Halifax',
    data: {
      siteId: 401,
      siteName: 'Port Hawkesbury',
      siteIdentifier: '',
      latitude: 45.65667,
      longitude: -61.36806,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3233,
    latitude: 58.4719,
    longitude: -78.077,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 469,
      siteName: 'Inukjuak',
      siteIdentifier: 'YPH',
      latitude: 58.468803,
      longitude: -78.07944,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2704,
    latitude: 49.8365,
    longitude: -64.2886,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 384,
      siteName: 'Port Menier',
      siteIdentifier: '',
      latitude: 49.83639,
      longitude: -64.28861,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2703,
    latitude: 54.988,
    longitude: -85.4434,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 477,
      siteName: 'Peawanuck',
      siteIdentifier: 'YPO',
      latitude: 54.98906,
      longitude: -85.439,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3165,
    latitude: 44.2305,
    longitude: -78.3632,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 365,
      siteName: 'Peterborough',
      siteIdentifier: '',
      latitude: 44.22417,
      longitude: -78.36333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2811,
    latitude: 60.0521,
    longitude: -77.2876,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 480,
      siteName: 'Puvirnituq',
      siteIdentifier: '',
      latitude: 60.049156,
      longitude: -77.28639,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2699,
    latitude: 44.9751,
    longitude: -79.3038,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 361,
      siteName: 'Muskoka',
      siteIdentifier: '',
      latitude: 44.97478,
      longitude: -79.30333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2698,
    latitude: 46.7912,
    longitude: -71.3933,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 521,
      siteName: 'Quebec / Jean Lesage Intl',
      siteIdentifier: '',
      latitude: 46.79111,
      longitude: -71.39333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2696,
    latitude: 42.276,
    longitude: -82.9554,
    timeZoneId: 'America/Detroit',
    data: {
      siteId: 534,
      siteName: 'Windsor',
      siteIdentifier: '',
      latitude: 42.27611,
      longitude: -82.95528,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2689,
    latitude: 42.77,
    longitude: -81.1096,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 369,
      siteName: 'St. Thomas',
      siteIdentifier: '',
      latitude: 42.77,
      longitude: -81.11083,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2706,
    latitude: 48.372,
    longitude: -89.3217,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 483,
      siteName: 'Thunder Bay',
      siteIdentifier: 'YQT',
      latitude: 48.37056,
      longitude: -89.31961,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2682,
    latitude: 48.5207,
    longitude: -71.0506,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 491,
      siteName: 'Chicoutimi/St-Honore',
      siteIdentifier: '',
      latitude: 48.52083,
      longitude: -71.05056,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2836,
    latitude: 47.7643,
    longitude: -69.5844,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 500,
      siteName: 'Riviere-du-Loup',
      siteIdentifier: '',
      latitude: 47.76444,
      longitude: -69.58444,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2675,
    latitude: 46.3516,
    longitude: -72.6805,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 389,
      siteName: 'Trois Rivieres',
      siteIdentifier: '',
      latitude: 46.35167,
      longitude: -72.67056,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3228,
    latitude: 45.4385,
    longitude: -71.6914,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 386,
      siteName: 'Sherbrooke',
      siteIdentifier: '',
      latitude: 45.43861,
      longitude: -71.69139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2668,
    latitude: 46.0964,
    longitude: -70.7146,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 387,
      siteName: 'St. Georges',
      siteIdentifier: '',
      latitude: 46.0963,
      longitude: -70.71472,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2667,
    latitude: 44.9457,
    longitude: -75.9403,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 368,
      siteName: 'Smiths Falls',
      siteIdentifier: '',
      latitude: 44.94583,
      longitude: -75.94055,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3629,
    latitude: 64.2302,
    longitude: -76.5249,
    timeZoneId: 'America/Iqaluit',
    data: {
      siteId: 460,
      siteName: 'Kinngait',
      siteIdentifier: 'YTE',
      latitude: 64.230255,
      longitude: -76.52991,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2833,
    latitude: 53.8179,
    longitude: -89.8969,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 345,
      siteName: 'Big Trout Lake',
      siteIdentifier: '',
      latitude: 53.81778,
      longitude: -89.89694,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3331,
    latitude: 45.6118,
    longitude: -62.6211,
    timeZoneId: 'America/Halifax',
    data: {
      siteId: 402,
      siteName: 'Trenton',
      siteIdentifier: '',
      latitude: 45.61194,
      longitude: -62.62111,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2980,
    latitude: 43.6274,
    longitude: -79.3962,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 507,
      siteName: 'Billy Bishop Toronto City',
      siteIdentifier: '',
      latitude: 43.63028,
      longitude: -79.39917,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2652,
    latitude: 48.0711,
    longitude: -65.4602,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 372,
      siteName: 'Bonaventure',
      siteIdentifier: '',
      latitude: 48.07111,
      longitude: -65.46028,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2647,
    latitude: 48.0533,
    longitude: -77.7827,
    timeZoneId: 'America/Montreal',
    data: {
      siteId: 484,
      siteName: 'Val d Or',
      siteIdentifier: 'YVO',
      latitude: 48.05263,
      longitude: -77.78725,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2641,
    latitude: 52.9227,
    longitude: -66.8647,
    timeZoneId: 'America/Goose_Bay',
    data: {
      siteId: 485,
      siteName: 'Wabush',
      siteIdentifier: 'YWK',
      latitude: 52.926968,
      longitude: -66.87314,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2638,
    latitude: 52.9594,
    longitude: -87.3752,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 532,
      siteName: 'Webequie',
      siteIdentifier: '',
      latitude: 52.959442,
      longitude: -87.375275,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2631,
    latitude: 66.1449,
    longitude: -65.7135,
    timeZoneId: 'America/Pangnirtung',
    data: {
      siteId: 476,
      siteName: 'Pangnirtung',
      siteIdentifier: 'YXP',
      latitude: 66.14479,
      longitude: -65.71061,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3013,
    latitude: 47.6949,
    longitude: -79.849,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 353,
      siteName: 'Earlton',
      siteIdentifier: '',
      latitude: 47.695,
      longitude: -79.84889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2779,
    latitude: 46.3638,
    longitude: -79.4242,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 473,
      siteName: 'North Bay',
      siteIdentifier: 'YYB',
      latitude: 46.357693,
      longitude: -79.428,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3037,
    latitude: 50.2903,
    longitude: -88.9097,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 454,
      siteName: 'Armstrong',
      siteIdentifier: 'YYW',
      latitude: 50.295547,
      longitude: -88.90984,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2614,
    latitude: 45.8842,
    longitude: -82.5683,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 355,
      siteName: 'Gore Bay',
      siteIdentifier: '',
      latitude: 45.88167,
      longitude: -82.56722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2611,
    latitude: 42.9997,
    longitude: -82.3094,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 367,
      siteName: 'Sarnia',
      siteIdentifier: '',
      latitude: 42.99944,
      longitude: -82.30889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2606,
    latitude: 43.4415,
    longitude: -79.8502,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 347,
      siteName: 'Burlington Airpark',
      siteIdentifier: '',
      latitude: 43.44167,
      longitude: -79.85028,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2604,
    latitude: 47.6296,
    longitude: -65.739,
    timeZoneId: 'America/Moncton',
    data: {
      siteId: 506,
      siteName: 'Bathurst',
      siteIdentifier: '',
      latitude: 47.63104,
      longitude: -65.74333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2846,
    latitude: 53.5626,
    longitude: -64.1059,
    timeZoneId: 'America/Goose_Bay',
    data: {
      siteId: 394,
      siteName: 'Churchill Falls',
      siteIdentifier: '',
      latitude: 53.56194,
      longitude: -64.10639,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 658,
    latitude: 44.91,
    longitude: -67.0125,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 621,
      siteName: 'Eastport Municipal Airport',
      siteIdentifier: 'EPM',
      latitude: 44.912525,
      longitude: -67.012,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 787,
    latitude: 47.28528,
    longitude: -68.3125,
    timeZoneId: null,
    data: {
      siteId: 606,
      siteName: 'Northern Aroostook Reg Airport',
      siteIdentifier: 'FVE',
      latitude: 47.28697,
      longitude: -68.313774,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1032,
    latitude: 46.12444,
    longitude: -67.79222,
    timeZoneId: 'America/Moncton',
    data: {
      siteId: 595,
      siteName: 'Houlton International Airport',
      siteIdentifier: 'HUL',
      latitude: 46.126167,
      longitude: -67.78524,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1121,
    latitude: 43.96139,
    longitude: -69.7125,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 622,
      siteName: 'Wiscasset Airport',
      siteIdentifier: 'IWI',
      latitude: 43.96373,
      longitude: -69.71179,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1128,
    latitude: 43.99111,
    longitude: -70.94778,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 617,
      siteName: 'Eastern Slopes Regional Airport',
      siteIdentifier: 'IZG',
      latitude: 43.988842,
      longitude: -70.94977,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1217,
    latitude: 44.04833,
    longitude: -70.28333,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 616,
      siteName: 'Auburn/Lewiston Municipal Airport',
      siteIdentifier: 'LEW',
      latitude: 44.044994,
      longitude: -70.28332,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1273,
    latitude: 45.36167,
    longitude: -68.53444,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 689,
      siteName: 'Lincoln Regional Airport',
      siteIdentifier: 'LRG',
      latitude: 45.358368,
      longitude: -68.53331,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1306,
    latitude: 42.71694,
    longitude: -71.12333,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 733,
      siteName: 'Lawrence Muni Airport',
      siteIdentifier: 'LWM',
      latitude: 42.717583,
      longitude: -71.12206,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1416,
    latitude: 45.64778,
    longitude: -68.68556,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 607,
      siteName: 'Millinocket Municipal Airport',
      siteIdentifier: 'MLT',
      latitude: 45.64865,
      longitude: -68.69392,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1484,
    latitude: 44.70306,
    longitude: -67.47833,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 608,
      siteName: 'Machias Valley Airport',
      siteIdentifier: 'MVM',
      latitude: 44.70054,
      longitude: -67.47568,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1602,
    latitude: 44.95222,
    longitude: -68.67444,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 609,
      siteName: 'Dewitt Field Old Town Muni Airport',
      siteIdentifier: 'OLD',
      latitude: 44.95071,
      longitude: -68.67058,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1658,
    latitude: 44.71528,
    longitude: -69.86639,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 630,
      siteName: 'Norridgewock Airport',
      siteIdentifier: 'OWK',
      latitude: 44.717957,
      longitude: -69.86356,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1742,
    latitude: 45.20056,
    longitude: -67.56417,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 611,
      siteName: 'Princeton Municipal Airport',
      siteIdentifier: 'PNN',
      latitude: 45.200836,
      longitude: -67.563515,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1803,
    latitude: 43.64556,
    longitude: -70.30861,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 735,
      siteName: 'Portland International Jetport',
      siteIdentifier: 'PWM',
      latitude: 43.641857,
      longitude: -70.30788,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1860,
    latitude: 44.06,
    longitude: -69.09944,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 626,
      siteName: 'Knox County Regional Airport',
      siteIdentifier: 'RKD',
      latitude: 44.06297,
      longitude: -69.093735,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1972,
    latitude: 43.39361,
    longitude: -70.70778,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 598,
      siteName: 'Sanford Seacoast Reg Airport',
      siteIdentifier: 'SFM',
      latitude: 43.39486,
      longitude: -70.71412,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2286,
    latitude: 44.53306,
    longitude: -69.67528,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 610,
      siteName: 'Waterville Robert LaFleur Airport',
      siteIdentifier: 'WVL',
      latitude: 44.537544,
      longitude: -69.67516,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10602,
    latitude: 44.72435,
    longitude: -67.97884,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 737,
      siteName: 'Deblois Flight Strip',
      siteIdentifier: '43B',
      latitude: 44.72435,
      longitude: -67.97884,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10381,
    latitude: 45.632435,
    longitude: -70.25037,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 599,
      siteName: 'Newton Field Airport',
      siteIdentifier: '59B',
      latitude: 45.632435,
      longitude: -70.25037,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10380,
    latitude: 45.085503,
    longitude: -70.21715,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 651,
      siteName: 'Sugarloaf Regional Airport',
      siteIdentifier: 'B21',
      latitude: 45.085503,
      longitude: -70.21715,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10359,
    latitude: 44.422012,
    longitude: -70.808556,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 603,
      siteName: 'Bethel Regional Airport',
      siteIdentifier: '0B1',
      latitude: 44.422012,
      longitude: -70.808556,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10363,
    latitude: 45.093822,
    longitude: -69.082695,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 688,
      siteName: 'Bull Hill - Charleston',
      latitude: 45.093822,
      longitude: -69.082695,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10362,
    latitude: 44.156273,
    longitude: -70.482414,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 614,
      siteName: 'Oxford Country Regional Airport',
      siteIdentifier: '81B',
      latitude: 44.156273,
      longitude: -70.482414,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10360,
    latitude: 45.001205,
    longitude: -69.236145,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 691,
      siteName: 'Dexter Regional Airport',
      siteIdentifier: '1B0',
      latitude: 45.001205,
      longitude: -69.236145,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10364,
    latitude: 43.464237,
    longitude: -70.47435,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 650,
      siteName: 'Biddeford Muni Airport',
      siteIdentifier: 'B19',
      latitude: 43.464237,
      longitude: -70.47435,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10361,
    latitude: 44.774307,
    longitude: -69.377335,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 604,
      siteName: 'Pittsfield Municipal Airport',
      siteIdentifier: '2B7',
      latitude: 44.774307,
      longitude: -69.377335,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10601,
    latitude: 45.2075,
    longitude: -67.10639,
    timeZoneId: 'America/Moncton',
    data: {
      siteId: 393,
      siteName: 'St. Stephen',
      siteIdentifier: '',
      latitude: 45.2075,
      longitude: -67.10639,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10519,
    latitude: 42.28179,
    longitude: -83.72723,
    timeZoneId: 'America/Detroit',
    data: {
      siteId: 552,
      siteName: 'U of M Ann Arbor',
      siteIdentifier: 'MI50',
      latitude: 42.28179,
      longitude: -83.72723,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 5880,
    latitude: 44.99,
    longitude: -70.6624,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 629,
      siteName: 'Stephen A Bean Muni Airport',
      siteIdentifier: '8B0',
      latitude: 44.992096,
      longitude: -70.66098,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9146,
    latitude: 45.4649,
    longitude: -69.5543,
    timeZoneId: 'America/New_York',
    data: {
      siteId: 605,
      siteName: 'Greenville Municipal Airport',
      siteIdentifier: '3B1',
      latitude: 45.462708,
      longitude: -69.55589,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3471,
    latitude: 73.0064,
    longitude: -85.0473,
    timeZoneId: null,
    data: {
      siteId: 453,
      siteName: 'Arctic Bay',
      siteIdentifier: 'YAB',
      latitude: 73.00629,
      longitude: -85.04722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2804,
    latitude: 70.4859,
    longitude: -68.5169,
    timeZoneId: 'America/Iqaluit',
    data: {
      siteId: 492,
      siteName: 'Clyde River',
      siteIdentifier: '',
      latitude: 70.48583,
      longitude: -68.51694,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2752,
    latitude: 72.6895,
    longitude: -77.9689,
    timeZoneId: 'America/Iqaluit',
    data: {
      siteId: 478,
      siteName: 'Pond Inlet',
      siteIdentifier: 'YIO',
      latitude: 72.69047,
      longitude: -77.96777,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2653,
    latitude: 68.7758,
    longitude: -81.2424,
    timeZoneId: 'America/Iqaluit',
    data: {
      siteId: 468,
      siteName: 'Sanirajak',
      siteIdentifier: 'YUX',
      latitude: 68.77352,
      longitude: -81.22808,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2648,
    latitude: 67.5466,
    longitude: -64.0316,
    timeZoneId: 'America/Pangnirtung',
    data: {
      siteId: 481,
      siteName: 'Qikiqtarjuaq',
      siteIdentifier: 'YVM',
      latitude: 67.54672,
      longitude: -64.03175,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4947,
    latitude: 38.099,
    longitude: -106.17,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 82,
      siteName: 'Saguache Municipal Airport',
      siteIdentifier: '04V',
      latitude: 38.09902,
      longitude: -106.17035,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4949,
    latitude: 39.794,
    longitude: -105.764,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 54,
      siteName: 'Berthoud Pass',
      siteIdentifier: '0CO',
      latitude: 39.794502,
      longitude: -105.76389,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4957,
    latitude: 40.0563,
    longitude: -106.374,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 69,
      siteName: 'Kremmling/McElroy Field',
      siteIdentifier: '20V',
      latitude: 40.056286,
      longitude: -106.37437,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4958,
    latitude: 40.7454,
    longitude: -106.275,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 546,
      siteName: 'Walden/Jackson County',
      siteIdentifier: '33V',
      latitude: 40.744797,
      longitude: -106.2731,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4966,
    latitude: 39.05,
    longitude: -105.514,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 95,
      siteName: 'Wilkerson Pass',
      siteIdentifier: '4BM',
      latitude: 39.050293,
      longitude: -105.51399,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4973,
    latitude: 40.0956,
    longitude: -108.762,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 573,
      siteName: 'Rangely Airport',
      siteIdentifier: '4V0',
      latitude: 40.09309,
      longitude: -108.75724,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4976,
    latitude: 39.426,
    longitude: -107.38,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 87,
      siteName: 'Sunlight Mountain',
      siteIdentifier: '5SM',
      latitude: 39.426018,
      longitude: -107.38002,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4979,
    latitude: 38.799,
    longitude: -106.219,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 50,
      siteName: 'Bald Mountain',
      siteIdentifier: '7BM',
      latitude: 38.79936,
      longitude: -106.21906,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 71,
    latitude: 37.435,
    longitude: -105.86778,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 580,
      siteName: 'San Luis Valley Muni Airport',
      siteIdentifier: 'ALS',
      latitude: 37.44393,
      longitude: -105.86431,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 83,
    latitude: 38.53806,
    longitude: -106.04861,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 582,
      siteName: 'Harriet Alexander Field',
      siteIdentifier: 'ANK',
      latitude: 38.53681,
      longitude: -106.04725,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 92,
    latitude: 39.57,
    longitude: -104.84917,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 578,
      siteName: 'Centennial Airport',
      siteIdentifier: 'APA',
      latitude: 39.56218,
      longitude: -104.84784,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 116,
    latitude: 39.22167,
    longitude: -106.86806,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 542,
      siteName: 'Aspen/Pitkin County',
      siteIdentifier: 'ASE',
      latitude: 39.22148,
      longitude: -106.870125,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 220,
    latitude: 39.90861,
    longitude: -105.11694,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 569,
      siteName: 'Rocky Mountain Metro Airport',
      siteIdentifier: 'BJC',
      latitude: 39.91145,
      longitude: -105.11482,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4995,
    latitude: 40.3544,
    longitude: -106.699,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 91,
      siteName: 'Walton Peak',
      siteIdentifier: 'C07',
      latitude: 40.354427,
      longitude: -106.69958,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4996,
    latitude: 38.0138,
    longitude: -105.373,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 585,
      siteName: 'Silver West Airport',
      siteIdentifier: 'C08',
      latitude: 38.02381,
      longitude: -105.37973,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4998,
    latitude: 39.869,
    longitude: -105.553,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 66,
      siteName: 'Dakota Hill',
      siteIdentifier: 'C99',
      latitude: 39.868958,
      longitude: -105.5535,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 4999,
    latitude: 39.475,
    longitude: -106.152,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 59,
      siteName: 'Copper Mountain',
      siteIdentifier: 'CCU',
      latitude: 39.475166,
      longitude: -106.15226,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 341,
    latitude: 37.30278,
    longitude: -108.62778,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 579,
      siteName: 'Cortez Municipal Airport',
      siteIdentifier: 'CEZ',
      latitude: 37.30472,
      longitude: -108.63084,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 345,
    latitude: 39.78417,
    longitude: -104.5375,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 568,
      siteName: 'Colorado Air and Space Port',
      siteIdentifier: 'CFO',
      latitude: 39.790764,
      longitude: -104.54959,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 416,
    latitude: 38.80556,
    longitude: -104.70056,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 587,
      siteName: 'Colorado Springs Muni Airport',
      siteIdentifier: 'COS',
      latitude: 38.8049,
      longitude: -104.69787,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 5002,
    latitude: 37.464,
    longitude: -106.804,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 97,
      siteName: 'Wolf Creek Pass',
      siteIdentifier: 'CPW',
      latitude: 37.49294,
      longitude: -106.801,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 547,
    latitude: 37.15139,
    longitude: -107.75361,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 539,
      siteName: 'Durango/La Plata',
      siteIdentifier: 'DRO',
      latitude: 37.15608,
      longitude: -107.7524,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 610,
    latitude: 40.04861,
    longitude: -107.88583,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 574,
      siteName: 'Meeker Airport',
      siteIdentifier: 'EEO',
      latitude: 40.044106,
      longitude: -107.88861,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 617,
    latitude: 39.6425,
    longitude: -106.91583,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 543,
      siteName: 'Eagle County',
      siteIdentifier: 'EGE',
      latitude: 39.645462,
      longitude: -106.91843,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 750,
    latitude: 40.33528,
    longitude: -103.80417,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 570,
      siteName: 'Fort Morgan Municipal Airport',
      siteIdentifier: 'FMM',
      latitude: 40.327892,
      longitude: -103.803185,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 755,
    latitude: 40.45167,
    longitude: -105.01111,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 581,
      siteName: "Northern Colorado Reg'l Airport",
      siteIdentifier: 'FNL',
      latitude: 40.45161,
      longitude: -105.014,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 853,
    latitude: 39.12139,
    longitude: -108.52528,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 575,
      siteName: 'Grand Junction Regional Airport',
      siteIdentifier: 'GJT',
      latitude: 39.118057,
      longitude: -108.51806,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 866,
    latitude: 40.09,
    longitude: -105.91639,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 577,
      siteName: 'Granby County Airport',
      siteIdentifier: 'GNB',
      latitude: 40.088207,
      longitude: -105.91317,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 902,
    latitude: 38.53417,
    longitude: -106.93167,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 540,
      siteName: 'Gunnison/Crested Butte',
      siteIdentifier: 'GUC',
      latitude: 38.537502,
      longitude: -106.933014,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 943,
    latitude: 40.48167,
    longitude: -111.42861,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 556,
      siteName: 'Heber Valley',
      siteIdentifier: 'HCR',
      latitude: 40.48353,
      longitude: -111.42346,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 947,
    latitude: 40.48111,
    longitude: -107.2175,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 545,
      siteName: 'Yampa Valley Regional',
      siteIdentifier: 'HDN',
      latitude: 40.480885,
      longitude: -107.22346,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 953,
    latitude: 40.56917,
    longitude: -102.2725,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 571,
      siteName: 'Holyoke Municipal Airport',
      siteIdentifier: 'HEQ',
      latitude: 40.578213,
      longitude: -102.281456,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1116,
    latitude: 39.2425,
    longitude: -102.28528,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 572,
      siteName: 'Kit Carson County Airport',
      siteIdentifier: 'ITR',
      latitude: 39.243977,
      longitude: -102.28337,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1189,
    latitude: 38.06944,
    longitude: -102.68833,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 586,
      siteName: "Southeast Colorado Reg'l Airport",
      siteIdentifier: 'LAA',
      latitude: 38.07698,
      longitude: -102.69557,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1235,
    latitude: 39.27472,
    longitude: -103.66583,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 547,
      siteName: 'Limon',
      siteIdentifier: 'LIC',
      latitude: 39.267445,
      longitude: -103.66629,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1313,
    latitude: 39.21944,
    longitude: -106.31639,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 541,
      siteName: 'Leadville/Lake County',
      siteIdentifier: 'LXV',
      latitude: 39.223305,
      longitude: -106.31457,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 5163,
    latitude: 39.2232,
    longitude: -104.642,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 80,
      siteName: 'Monument Hill/Kelly Air Park',
      siteIdentifier: 'MNH',
      latitude: 39.22325,
      longitude: -104.6423,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 5032,
    latitude: 38.4972,
    longitude: -106.32,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 77,
      siteName: 'Monarch Pass',
      siteIdentifier: 'MYP',
      latitude: 38.49737,
      longitude: -106.320175,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1785,
    latitude: 38.28972,
    longitude: -104.49778,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 584,
      siteName: 'Pueblo Memorial',
      siteIdentifier: 'PUB',
      latitude: 38.28388,
      longitude: -104.49521,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1854,
    latitude: 39.52639,
    longitude: -107.72778,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 544,
      siteName: 'Rifle/Garfield County',
      siteIdentifier: 'RIL',
      latitude: 39.52796,
      longitude: -107.7194,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1937,
    latitude: 34.42611,
    longitude: -119.84139,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 747,
      siteName: 'UCSB Campus',
      latitude: 34.41567,
      longitude: -119.84525,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1943,
    latitude: 40.51611,
    longitude: -106.86611,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 576,
      siteName: 'Steamboat Springs Airport',
      siteIdentifier: 'SBS',
      latitude: 40.517094,
      longitude: -106.86486,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1961,
    latitude: 32.82611,
    longitude: -116.97222,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 778,
      siteName: 'Gillespie Field',
      latitude: 32.821033,
      longitude: -116.988335,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2095,
    latitude: 37.25917,
    longitude: -104.34083,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 583,
      siteName: 'Perry Stokes Airport',
      siteIdentifier: 'TAD',
      latitude: 37.26241,
      longitude: -104.33825,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2112,
    latitude: 37.95361,
    longitude: -107.90861,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 538,
      siteName: 'Telluride',
      siteIdentifier: 'TEX',
      latitude: 37.95444,
      longitude: -107.9019,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2155,
    latitude: 39.32,
    longitude: -120.13944,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 658,
      siteName: 'Truckee Airport',
      siteIdentifier: 'KTRK',
      latitude: 39.318962,
      longitude: -120.14513,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 5077,
    latitude: 37.512,
    longitude: -105.171,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 74,
      siteName: 'La Veta Pass',
      siteIdentifier: 'VTP',
      latitude: 37.51187,
      longitude: -105.171005,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1153,
    latitude: 37.87833,
    longitude: -122.51278,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 711,
      siteName: 'Wolfback Ridge',
      latitude: 37.851,
      longitude: -122.4983,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10589,
    latitude: 38.34289,
    longitude: -117.97422,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 780,
      siteName: 'Pilot Peak NV',
      latitude: 38.34289,
      longitude: -117.97422,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10424,
    latitude: 37.827,
    longitude: -120.2296,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 713,
      siteName: 'Merrell Rd',
      latitude: 37.827,
      longitude: -120.2296,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10410,
    latitude: 38.2607,
    longitude: -120.89333,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 697,
      siteName: 'Buena Vista Amador',
      latitude: 38.2607,
      longitude: -120.89333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10431,
    latitude: 40.726902,
    longitude: -123.973946,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 677,
      siteName: 'Barry Ridge Kneeland',
      latitude: 40.726902,
      longitude: -123.973946,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10443,
    latitude: 37.2875,
    longitude: -121.8558,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 696,
      siteName: 'Carol Drive Santa Clara',
      latitude: 37.2875,
      longitude: -121.8558,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10422,
    latitude: 37.314125,
    longitude: -119.686455,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 744,
      siteName: 'Deadwood Mtn',
      latitude: 37.314125,
      longitude: -119.686455,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10592,
    latitude: 34.254166,
    longitude: -119.03732,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 726,
      siteName: 'Ridgeline',
      latitude: 34.254166,
      longitude: -119.03732,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10585,
    latitude: 33.612446,
    longitude: -117.548645,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 652,
      siteName: 'Bell Canyon',
      latitude: 33.612446,
      longitude: -117.548645,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10584,
    latitude: 33.605186,
    longitude: -117.81102,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 649,
      siteName: 'Signal Peak',
      latitude: 33.605186,
      longitude: -117.81102,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10580,
    latitude: 33.71115,
    longitude: -117.5341,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 653,
      siteName: 'Santiago Peak CalOES',
      latitude: 33.71115,
      longitude: -117.5341,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10578,
    latitude: 34.1159,
    longitude: -119.0513,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 727,
      siteName: 'Laguna Peak',
      latitude: 34.1159,
      longitude: -119.0513,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10576,
    latitude: 33.33906,
    longitude: -118.31841,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 741,
      siteName: 'Avalon',
      latitude: 33.33906,
      longitude: -118.31841,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10418,
    latitude: 34.28907,
    longitude: -119.11215,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 728,
      siteName: 'Butler Hill',
      latitude: 34.28907,
      longitude: -119.11215,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10383,
    latitude: 34.4994,
    longitude: -120.4544,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 743,
      siteName: 'Dangermond',
      latitude: 34.4994,
      longitude: -120.4544,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10446,
    latitude: 40.91447,
    longitude: -122.4445,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 699,
      siteName: 'Sugarloaf Shasta',
      latitude: 40.91447,
      longitude: -122.4445,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10579,
    latitude: 37.630726,
    longitude: -119.03315,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 712,
      siteName: 'Mammoth Mtn',
      latitude: 37.630726,
      longitude: -119.03315,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10572,
    latitude: 40.40335,
    longitude: -116.861664,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 772,
      siteName: 'Mt Lewis',
      latitude: 40.40335,
      longitude: -116.861664,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10501,
    latitude: 40.34611,
    longitude: -121.945,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 792,
      siteName: 'Inskip Hill',
      latitude: 40.34611,
      longitude: -121.945,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10435,
    latitude: 38.7337,
    longitude: -123.2898,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 698,
      siteName: 'Oak Ridge',
      latitude: 38.7337,
      longitude: -123.2898,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10368,
    latitude: 38.808,
    longitude: -119.523,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 789,
      siteName: 'Bald Mtn NV',
      latitude: 38.808,
      longitude: -119.523,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10560,
    latitude: 39.16083,
    longitude: -114.61495,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 796,
      siteName: 'Cave Mtn',
      latitude: 39.16083,
      longitude: -114.61495,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10500,
    latitude: 38.5088,
    longitude: -122.6633,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 701,
      siteName: 'Barham North',
      latitude: 38.5088,
      longitude: -122.6633,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10402,
    latitude: 38.68394,
    longitude: -120.98663,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 729,
      siteName: 'Cameron Park Airport',
      siteIdentifier: 'O61',
      latitude: 38.68394,
      longitude: -120.98663,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10567,
    latitude: 39.44973,
    longitude: -115.99871,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 777,
      siteName: 'Prospect Peak',
      latitude: 39.44973,
      longitude: -115.99871,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10420,
    latitude: 36.692924,
    longitude: -119.09095,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 703,
      siteName: 'Bear Clover',
      latitude: 36.692924,
      longitude: -119.09095,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10375,
    latitude: 36.543568,
    longitude: -121.11885,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 702,
      siteName: 'Mt Helen',
      latitude: 36.543568,
      longitude: -121.11885,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10406,
    latitude: 39.42302,
    longitude: -121.19791,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 707,
      siteName: 'Siller Bros Tower',
      latitude: 39.42302,
      longitude: -121.19791,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10372,
    latitude: 39.557,
    longitude: -119.3638,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 773,
      siteName: 'Eagle Peak',
      latitude: 39.557,
      longitude: -119.3638,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10504,
    latitude: 39.693996,
    longitude: -123.57965,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 740,
      siteName: 'Laytonville',
      latitude: 39.693996,
      longitude: -123.57965,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10401,
    latitude: 39.7713,
    longitude: -121.5387,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 706,
      siteName: 'Concow Mtn',
      latitude: 39.7713,
      longitude: -121.5387,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10598,
    latitude: 32.594803,
    longitude: -116.8447,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 628,
      siteName: 'Otay Mtn',
      latitude: 32.594803,
      longitude: -116.8447,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10596,
    latitude: 33.42837,
    longitude: -117.38253,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 642,
      siteName: 'Buffalo Bump',
      latitude: 33.42837,
      longitude: -117.38253,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10593,
    latitude: 33.913837,
    longitude: -117.7383,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 659,
      siteName: 'Chino Hills',
      latitude: 33.913837,
      longitude: -117.7383,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10586,
    latitude: 33.110634,
    longitude: -117.15306,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 634,
      siteName: 'Coronado Hills',
      latitude: 33.110634,
      longitude: -117.15306,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10583,
    latitude: 32.696804,
    longitude: -116.93636,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 633,
      siteName: 'Mt San Miguel',
      latitude: 32.696804,
      longitude: -116.93636,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10577,
    latitude: 33.18885,
    longitude: -116.76065,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 637,
      siteName: 'Mesa Grande',
      latitude: 33.18885,
      longitude: -116.76065,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10591,
    latitude: 35.516098,
    longitude: -118.717606,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 717,
      siteName: 'Mebane',
      latitude: 35.516098,
      longitude: -118.717606,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10573,
    latitude: 35.477287,
    longitude: -117.70045,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 709,
      siteName: 'El Paso',
      latitude: 35.477287,
      longitude: -117.70045,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10566,
    latitude: 35.946995,
    longitude: -115.0497,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 779,
      siteName: 'Black Mtn NV',
      latitude: 35.946995,
      longitude: -115.0497,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10565,
    latitude: 36.319,
    longitude: -115.574,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 775,
      siteName: 'Angel Peak',
      latitude: 36.319,
      longitude: -115.574,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10417,
    latitude: 35.5669,
    longitude: -120.8193,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 704,
      siteName: 'Templeton',
      latitude: 35.5669,
      longitude: -120.8193,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10385,
    latitude: 35.050068,
    longitude: -120.041016,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 742,
      siteName: 'Plowshare Peak',
      latitude: 35.050068,
      longitude: -120.041016,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10366,
    latitude: 35.927635,
    longitude: -120.38081,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 705,
      siteName: 'Parkfield',
      latitude: 35.927635,
      longitude: -120.38081,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10569,
    latitude: 32.32905,
    longitude: -90.17377,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 553,
      siteName: 'UMMC Jackson',
      siteIdentifier: '',
      latitude: 32.32905,
      longitude: -90.17377,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10568,
    latitude: 33.11165,
    longitude: -90.03062,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 554,
      siteName: 'UMMC Lexington',
      siteIdentifier: '',
      latitude: 33.11165,
      longitude: -90.03062,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3672,
    latitude: 52.1851,
    longitude: -128.157,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 276,
      siteName: 'Bella Bella',
      siteIdentifier: 'BBC',
      latitude: 52.185,
      longitude: -128.15666,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8377,
    latitude: 48.922,
    longitude: -125.541,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 274,
      siteName: 'Amphitrite Point Lighthouse',
      siteIdentifier: 'WAN',
      latitude: 48.92139,
      longitude: -125.54,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8383,
    latitude: 51.604,
    longitude: -127.864,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 273,
      siteName: 'Addenbroke Island Lightstation',
      siteIdentifier: 'WCZ',
      latitude: 51.603333,
      longitude: -127.86389,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8391,
    latitude: 48.783,
    longitude: -123.046,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 284,
      siteName: 'Saturna Island - East Point',
      siteIdentifier: 'WEZ',
      latitude: 48.783054,
      longitude: -123.04528,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8393,
    latitude: 50.333,
    longitude: -125.446,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 278,
      siteName: 'Chatham Point Lightstation',
      siteIdentifier: 'WFM',
      latitude: 50.33361,
      longitude: -125.44611,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8422,
    latitude: 50.224,
    longitude: -121.582,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 303,
      siteName: 'Lytton',
      siteIdentifier: '',
      latitude: 50.22444,
      longitude: -121.58139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8426,
    latitude: 49.468,
    longitude: -123.912,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 518,
      siteName: 'Merry Island',
      siteIdentifier: '',
      latitude: 49.4675,
      longitude: -123.91222,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8849,
    latitude: 49.628,
    longitude: -114.482,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 320,
      siteName: 'Crowsnest Pass',
      siteIdentifier: '',
      latitude: 49.63389,
      longitude: -114.485,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8465,
    latitude: 49.347,
    longitude: -123.193,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 286,
      siteName: 'West Vancouver',
      siteIdentifier: 'WWA',
      latitude: 49.344723,
      longitude: -123.18166,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8468,
    latitude: 58.931,
    longitude: -125.767,
    timeZoneId: 'America/Fort_Nelson',
    data: {
      siteId: 307,
      siteName: 'Muncho Lake',
      siteIdentifier: '',
      latitude: 58.93028,
      longitude: -125.7675,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8956,
    latitude: 53.573,
    longitude: -113.517,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 321,
      siteName: 'Edmonton City Center',
      siteIdentifier: '',
      latitude: 53.5725,
      longitude: -113.52056,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2826,
    latitude: 52.3875,
    longitude: -126.596,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 405,
      siteName: 'Bella Coola',
      siteIdentifier: '',
      latitude: 52.38795,
      longitude: -126.58772,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2823,
    latitude: 58.7061,
    longitude: -98.5122,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 341,
      siteName: 'Tadoule Lake',
      siteIdentifier: '',
      latitude: 58.70611,
      longitude: -98.51222,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3532,
    latitude: 53.3325,
    longitude: -104.009,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 332,
      siteName: 'Nipawin',
      siteIdentifier: '',
      latitude: 53.3325,
      longitude: -104.00833,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2820,
    latitude: 52.3588,
    longitude: -97.018,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 457,
      siteName: 'Berens River',
      siteIdentifier: 'YBV',
      latitude: 52.359707,
      longitude: -97.0249,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2819,
    latitude: 51.1053,
    longitude: -114.371,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 428,
      siteName: 'Calgary - Springbank',
      siteIdentifier: '',
      latitude: 51.1021,
      longitude: -114.37095,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2807,
    latitude: 52.1248,
    longitude: -119.293,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 406,
      siteName: 'Blue River',
      siteIdentifier: '',
      latitude: 52.12472,
      longitude: -119.29278,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3567,
    latitude: 58.4223,
    longitude: -130.032,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 424,
      siteName: 'Dease Lake',
      siteIdentifier: 'YDL',
      latitude: 58.4259,
      longitude: -130.02422,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2797,
    latitude: 61.9705,
    longitude: -132.422,
    timeZoneId: 'America/Whitehorse',
    data: {
      siteId: 524,
      siteName: 'Ross River',
      siteIdentifier: '',
      latitude: 61.97055,
      longitude: -132.4222,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2796,
    latitude: 51.1015,
    longitude: -100.053,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 338,
      siteName: 'Dauphin',
      siteIdentifier: '',
      latitude: 51.10083,
      longitude: -100.0525,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3681,
    latitude: 55.7423,
    longitude: -120.183,
    timeZoneId: 'America/Dawson_Creek',
    data: {
      siteId: 295,
      siteName: 'Dawson Creek',
      siteIdentifier: '',
      latitude: 55.74222,
      longitude: -120.18306,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2790,
    latitude: 61.094,
    longitude: -94.0716,
    timeZoneId: 'America/Rankin_Inlet',
    data: {
      siteId: 455,
      siteName: 'Arviat',
      siteIdentifier: 'YEK',
      latitude: 61.099415,
      longitude: -94.07853,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2964,
    latitude: 49.2102,
    longitude: -102.966,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 510,
      siteName: 'Estevan Regional',
      siteIdentifier: '',
      latitude: 49.210278,
      longitude: -102.965836,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3248,
    latitude: 53.5789,
    longitude: -116.465,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 322,
      siteName: 'Edson',
      siteIdentifier: '',
      latitude: 53.57889,
      longitude: -116.465,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3096,
    latitude: 54.6781,
    longitude: -101.682,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 463,
      siteName: 'Flin Flon',
      siteIdentifier: 'YFO',
      latitude: 54.67642,
      longitude: -101.67727,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3157,
    latitude: 51.2992,
    longitude: -116.982,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 298,
      siteName: 'Golden',
      siteIdentifier: '',
      latitude: 51.29917,
      longitude: -116.9825,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2764,
    latitude: 49.8315,
    longitude: -92.7437,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 352,
      siteName: 'Dryden Regional',
      siteIdentifier: '',
      latitude: 49.83167,
      longitude: -92.74416,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2798,
    latitude: 49.3683,
    longitude: -121.498,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 299,
      siteName: 'Hope',
      siteIdentifier: '',
      latitude: 49.36833,
      longitude: -121.49806,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3725,
    latitude: 51.2663,
    longitude: -121.685,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 407,
      siteName: 'Clinton-Bleibler Ranch',
      siteIdentifier: '',
      latitude: 51.26476,
      longitude: -121.68297,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2886,
    latitude: 53.8571,
    longitude: -94.6537,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 612,
      siteName: 'Island Lake',
      siteIdentifier: 'YIV',
      latitude: 53.85838,
      longitude: -94.65526,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2751,
    latitude: 60.2355,
    longitude: -123.47,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 496,
      siteName: 'Fort Liard',
      siteIdentifier: '',
      latitude: 60.23833,
      longitude: -123.47,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2768,
    latitude: 57.2563,
    longitude: -105.617,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 331,
      siteName: 'Key Lake',
      siteIdentifier: '',
      latitude: 57.25972,
      longitude: -105.6175,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2734,
    latitude: 62.4182,
    longitude: -110.682,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 517,
      siteName: 'Lutselk e',
      siteIdentifier: '',
      latitude: 62.41833,
      longitude: -110.68222,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2733,
    latitude: 53.3106,
    longitude: -110.074,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 327,
      siteName: 'Lloydminster',
      siteIdentifier: '',
      latitude: 53.30917,
      longitude: -110.0725,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3693,
    latitude: 49.9572,
    longitude: -119.378,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 301,
      siteName: 'Kelowna',
      siteIdentifier: '',
      latitude: 49.95611,
      longitude: -119.37777,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2728,
    latitude: 56.6532,
    longitude: -111.223,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 324,
      siteName: 'Fort McMurray',
      siteIdentifier: '',
      latitude: 56.65333,
      longitude: -111.22333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3180,
    latitude: 53.9583,
    longitude: -97.8442,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 340,
      siteName: 'Norway House',
      siteIdentifier: '',
      latitude: 53.95833,
      longitude: -97.84416,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2714,
    latitude: 58.6216,
    longitude: -117.165,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 325,
      siteName: 'High Level Airport',
      siteIdentifier: '',
      latitude: 58.62139,
      longitude: -117.16472,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3527,
    latitude: 51.4465,
    longitude: -90.2141,
    timeZoneId: 'America/Toronto',
    data: {
      siteId: 520,
      siteName: 'Pickle Lake',
      siteIdentifier: '',
      latitude: 51.44639,
      longitude: -90.21333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2859,
    latitude: 54.2859,
    longitude: -130.445,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 281,
      siteName: 'Prince Rupert',
      siteIdentifier: 'YPR',
      latitude: 54.28611,
      longitude: -130.44472,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3579,
    latitude: 49.8341,
    longitude: -124.5,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 280,
      siteName: 'Powell River',
      siteIdentifier: 'YPW',
      latitude: 49.834167,
      longitude: -124.500275,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3728,
    latitude: 58.7674,
    longitude: -111.118,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 323,
      siteName: 'Fort Chipewyan',
      siteIdentifier: '',
      latitude: 58.76667,
      longitude: -111.11722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3167,
    latitude: 54.3765,
    longitude: -125.951,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 291,
      siteName: 'Burns Lake',
      siteIdentifier: 'YPZ',
      latitude: 54.37639,
      longitude: -125.95139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2697,
    latitude: 53.9713,
    longitude: -101.091,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 528,
      siteName: 'The Pas',
      siteIdentifier: '',
      latitude: 53.97139,
      longitude: -101.09111,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3358,
    latitude: 52.1823,
    longitude: -113.894,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 522,
      siteName: 'Red Deer Regional',
      siteIdentifier: '',
      latitude: 52.185,
      longitude: -113.89445,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2693,
    latitude: 49.6302,
    longitude: -112.8,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 430,
      siteName: 'Lethbridge',
      siteIdentifier: 'YQL',
      latitude: 49.6338,
      longitude: -112.78837,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3483,
    latitude: 52.7691,
    longitude: -108.244,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 333,
      siteName: 'North Battleford',
      siteIdentifier: '',
      latitude: 52.76917,
      longitude: -108.24361,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2686,
    latitude: 53.0261,
    longitude: -122.51,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 310,
      siteName: 'Quesnel',
      siteIdentifier: '',
      latitude: 53.00944,
      longitude: -122.51028,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2684,
    latitude: 64.1161,
    longitude: -117.31,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 512,
      siteName: 'Gameti / Rae Lakes',
      siteIdentifier: '',
      latitude: 64.11528,
      longitude: -117.31333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3554,
    latitude: 52.4297,
    longitude: -114.904,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 329,
      siteName: 'Rocky Mountain House',
      siteIdentifier: '',
      latitude: 52.42972,
      longitude: -114.90417,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2673,
    latitude: 50.9623,
    longitude: -118.184,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 314,
      siteName: 'Revelstoke',
      siteIdentifier: '',
      latitude: 50.96667,
      longitude: -118.18333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2669,
    latitude: 59.2504,
    longitude: -105.841,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 334,
      siteName: 'Stony Rapids',
      siteIdentifier: '',
      latitude: 59.25028,
      longitude: -105.84139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2645,
    latitude: 49.1947,
    longitude: -123.184,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 426,
      siteName: 'Vancouver Intl',
      siteIdentifier: 'YVR',
      latitude: 49.195,
      longitude: -123.17824,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3476,
    latitude: 55.8419,
    longitude: -108.418,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 508,
      siteName: 'Buffalo Narrows',
      siteIdentifier: '',
      latitude: 55.841946,
      longitude: -108.4175,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2643,
    latitude: 64.1908,
    longitude: -114.077,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 399,
      siteName: 'Wekweeti',
      siteIdentifier: '',
      latitude: 64.19083,
      longitude: -114.07667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3419,
    latitude: 63.2096,
    longitude: -123.437,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 503,
      siteName: 'Wrigley',
      siteIdentifier: '',
      latitude: 63.20972,
      longitude: -123.43667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2636,
    latitude: 50.0189,
    longitude: -110.721,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 431,
      siteName: 'Medicine Hat',
      siteIdentifier: 'YXH',
      latitude: 50.022,
      longitude: -110.72434,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2630,
    latitude: 53.884,
    longitude: -122.678,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 309,
      siteName: 'Prince George',
      siteIdentifier: '',
      latitude: 53.88944,
      longitude: -122.67889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2623,
    latitude: 54.8254,
    longitude: -127.183,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 312,
      siteName: 'Smithers',
      siteIdentifier: '',
      latitude: 54.82528,
      longitude: -127.18278,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2619,
    latitude: 56.8644,
    longitude: -101.076,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 339,
      siteName: 'Lynn Lake',
      siteIdentifier: '',
      latitude: 56.86389,
      longitude: -101.07611,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2832,
    latitude: 50.2925,
    longitude: -107.691,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 336,
      siteName: 'Swift Current',
      siteIdentifier: '',
      latitude: 50.29194,
      longitude: -107.69056,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2618,
    latitude: 58.7391,
    longitude: -94.0664,
    timeZoneId: 'America/Rankin_Inlet',
    data: {
      siteId: 461,
      siteName: 'Churchill',
      siteIdentifier: 'YYQ',
      latitude: 58.749752,
      longitude: -94.074356,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2612,
    latitude: 55.2929,
    longitude: -114.777,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 330,
      siteName: 'Slave Lake',
      siteIdentifier: '',
      latitude: 55.29306,
      longitude: -114.77722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2828,
    latitude: 53.2543,
    longitude: -131.814,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 283,
      siteName: 'Sandspit',
      siteIdentifier: 'YZP',
      latitude: 53.254166,
      longitude: -131.81389,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2722,
    latitude: 54.1438,
    longitude: -115.787,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 533,
      siteName: 'Whitecourt',
      siteIdentifier: '',
      latitude: 54.143887,
      longitude: -115.78666,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2608,
    latitude: 55.2995,
    longitude: -123.133,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 304,
      siteName: 'Mackenzie',
      siteIdentifier: '',
      latitude: 55.29944,
      longitude: -123.1333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3494,
    latitude: 62.207,
    longitude: -133.373,
    timeZoneId: 'America/Whitehorse',
    data: {
      siteId: 494,
      siteName: 'Faro',
      siteIdentifier: '',
      latitude: 62.20694,
      longitude: -133.37334,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2601,
    latitude: 64.9097,
    longitude: -125.569,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 530,
      siteName: 'Tulita',
      siteIdentifier: '',
      latitude: 64.90972,
      longitude: -125.56944,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2629,
    latitude: 53.4415,
    longitude: -91.7629,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 362,
      siteName: 'Muskrat Dam',
      siteIdentifier: '',
      latitude: 53.44139,
      longitude: -91.76278,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2592,
    latitude: 49.5204,
    longitude: -113.997,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 328,
      siteName: 'Pincher Creek',
      siteIdentifier: '',
      latitude: 49.5206,
      longitude: -113.99722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2590,
    latitude: 53.0642,
    longitude: -93.3444,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 366,
      siteName: 'Sandy Lake',
      siteIdentifier: '',
      latitude: 53.06417,
      longitude: -93.34444,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2589,
    latitude: 55.9333,
    longitude: -129.983,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 161,
      siteName: 'Hyder',
      siteIdentifier: '4Z7',
      latitude: 55.9118,
      longitude: -130.01875,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9093,
    latitude: 46.389,
    longitude: -112.769,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 631,
      siteName: 'Deer Lodge',
      siteIdentifier: '38S',
      latitude: 46.389717,
      longitude: -112.76451,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9100,
    latitude: 46.1516,
    longitude: -112.868,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 725,
      siteName: 'Bowman Field Anaconda',
      siteIdentifier: '3U3',
      latitude: 46.14799,
      longitude: -112.86343,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 5118,
    latitude: 44.4284,
    longitude: -103.38,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 786,
      siteName: 'Sturgis',
      latitude: 44.422222,
      longitude: -103.37944,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9116,
    latitude: 47.845,
    longitude: -110.636,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 807,
      siteName: 'Fort Benton',
      siteIdentifier: '79S',
      latitude: 47.8463,
      longitude: -110.6376,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9118,
    latitude: 47.5676,
    longitude: -114.1,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 803,
      siteName: 'Ronan Airport',
      siteIdentifier: '7S0',
      latitude: 47.5666,
      longitude: -114.1026,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9122,
    latitude: 48.601,
    longitude: -113.115,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 685,
      siteName: 'Browning Starr School Airport',
      siteIdentifier: '8S0',
      latitude: 48.60148,
      longitude: -113.10731,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9127,
    latitude: 45.8827,
    longitude: -111.562,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 676,
      siteName: 'Three Forks',
      siteIdentifier: '9S5',
      latitude: 45.88487,
      longitude: -111.56322,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 125,
    latitude: 46.02306,
    longitude: -99.3525,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 723,
      siteName: 'Ashley Municipal Airport',
      siteIdentifier: 'ASK',
      latitude: 46.02751,
      longitude: -99.357765,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 216,
    latitude: 45.80778,
    longitude: -108.54333,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 648,
      siteName: 'Billings Airport',
      latitude: 45.80339,
      longitude: -108.54461,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 270,
    latitude: 45.95472,
    longitude: -112.49722,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 564,
      siteName: 'Butte Airport',
      siteIdentifier: 'BTM',
      latitude: 45.95361,
      longitude: -112.501945,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 305,
    latitude: 45.77722,
    longitude: -111.15,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 563,
      siteName: 'Bozeman Airport',
      siteIdentifier: 'KBZN',
      latitude: 45.779984,
      longitude: -111.14463,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 363,
    latitude: 43.59611,
    longitude: -91.50389,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 718,
      siteName: 'Caledonia',
      siteIdentifier: 'CHU',
      latitude: 43.5984,
      longitude: -91.50827,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 366,
    latitude: 47.82833,
    longitude: -112.16833,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 716,
      siteName: 'Choteau',
      siteIdentifier: 'CII',
      latitude: 47.82122,
      longitude: -112.16874,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 518,
    latitude: 46.80028,
    longitude: -102.80417,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 802,
      siteName: 'Dickinson Airport',
      siteIdentifier: 'DIK',
      latitude: 46.80353,
      longitude: -102.80003,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 632,
    latitude: 45.27556,
    longitude: -111.64889,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 812,
      siteName: 'Ennis Big Sky',
      siteIdentifier: 'EKS',
      latitude: 45.2717,
      longitude: -111.6467,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 678,
    latitude: 47.42417,
    longitude: -92.49639,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 721,
      siteName: 'Eveleth',
      siteIdentifier: 'EVM',
      latitude: 47.42702,
      longitude: -92.5042,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9144,
    latitude: 49.318,
    longitude: -94.902,
    timeZoneId: null,
    data: {
      siteId: 722,
      siteName: 'Sunset Lodge Resort',
      latitude: 49.31565,
      longitude: -94.85483,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 847,
    latitude: 48.21222,
    longitude: -106.61472,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 639,
      siteName: 'Glasgow Valley Airport',
      siteIdentifier: 'KGGW',
      latitude: 48.20952,
      longitude: -106.6266,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 898,
    latitude: 47.48222,
    longitude: -111.37028,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 684,
      siteName: 'Great Falls',
      siteIdentifier: 'GTF',
      latitude: 47.48245,
      longitude: -111.35722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 981,
    latitude: 46.60667,
    longitude: -111.98306,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 593,
      siteName: 'Helena Airport',
      siteIdentifier: 'HLN',
      latitude: 46.61014,
      longitude: -111.98913,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1007,
    latitude: 46.25694,
    longitude: -114.12361,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 692,
      siteName: 'Hamilton Airport',
      siteIdentifier: '6S5',
      latitude: 46.254444,
      longitude: -114.128334,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1038,
    latitude: 48.54278,
    longitude: -109.76222,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 624,
      siteName: 'Havre Airport',
      siteIdentifier: 'HVR',
      latitude: 48.54592,
      longitude: -109.763,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1139,
    latitude: 47.32861,
    longitude: -106.9525,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 820,
      siteName: 'Jordon Airport',
      siteIdentifier: 'JDN',
      latitude: 47.3262,
      longitude: -106.9482,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1308,
    latitude: 47.04917,
    longitude: -109.46667,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 561,
      siteName: 'Lewistown Airport',
      siteIdentifier: 'LWT',
      latitude: 47.048923,
      longitude: -109.458206,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 9155,
    latitude: 48.3686,
    longitude: -107.919,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 683,
      siteName: 'Malta Airport',
      siteIdentifier: 'M75',
      latitude: 48.36845,
      longitude: -107.91262,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1415,
    latitude: 46.42667,
    longitude: -105.88806,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 597,
      siteName: 'Frank Wiley Field Airport',
      siteIdentifier: 'MLS',
      latitude: 46.427704,
      longitude: -105.87706,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1421,
    latitude: 44.45167,
    longitude: -95.82417,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 719,
      siteName: 'Marshall',
      siteIdentifier: 'MML',
      latitude: 44.44344,
      longitude: -95.81993,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1463,
    latitude: 46.91611,
    longitude: -114.09056,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 557,
      siteName: 'Missoula',
      siteIdentifier: 'MSO',
      latitude: 46.91925,
      longitude: -114.08525,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1906,
    latitude: 45.53528,
    longitude: -112.30222,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 813,
      siteName: 'Twin Bridges',
      siteIdentifier: 'RVF',
      latitude: 45.532,
      longitude: -112.3022,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 1945,
    latitude: 48.54056,
    longitude: -111.87111,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 801,
      siteName: 'Shelby Airport',
      siteIdentifier: 'SBX',
      latitude: 48.540592,
      longitude: -111.86664,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2289,
    latitude: 44.68833,
    longitude: -111.1175,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 566,
      siteName: 'West Yellowstone',
      siteIdentifier: 'WYS',
      latitude: 44.68775,
      longitude: -111.11419,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2316,
    latitude: 56.24667,
    longitude: -134.64806,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 182,
      siteName: 'Port Alexander',
      siteIdentifier: 'AHP',
      latitude: 56.24669,
      longitude: -134.64777,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2363,
    latitude: 56.96111,
    longitude: -133.91,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 4,
      siteName: 'Kake',
      siteIdentifier: 'AFE',
      latitude: 56.97313,
      longitude: -133.9449,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2376,
    latitude: 57.50333,
    longitude: -134.585,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 251,
      siteName: 'Angoon',
      siteIdentifier: 'AGN',
      latitude: 57.49666,
      longitude: -134.56924,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2391,
    latitude: 55.20611,
    longitude: -132.82806,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 104,
      siteName: 'Hydaburg',
      siteIdentifier: 'HYG',
      latitude: 55.20241,
      longitude: -132.82492,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2400,
    latitude: 58.35444,
    longitude: -134.57832,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 8,
      siteName: 'Pedersen Hill',
      siteIdentifier: 'JNU',
      latitude: 58.36555,
      longitude: -134.63495,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2413,
    latitude: 55.35389,
    longitude: -131.7111,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 117,
      siteName: 'Ketchikan',
      siteIdentifier: 'KTN',
      latitude: 55.356853,
      longitude: -131.70937,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2416,
    latitude: 55.57917,
    longitude: -133.07584,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 25,
      siteName: 'Klawock',
      siteIdentifier: 'AKW',
      latitude: 55.57916,
      longitude: -133.07108,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2432,
    latitude: 55.13083,
    longitude: -131.57806,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 123,
      siteName: 'Metlakatla',
      siteIdentifier: 'MTM',
      latitude: 55.128235,
      longitude: -131.57681,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2458,
    latitude: 56.80139,
    longitude: -132.9461,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 186,
      siteName: 'Petersburg',
      siteIdentifier: 'PSG',
      latitude: 56.80802,
      longitude: -132.93831,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2507,
    latitude: 56.48417,
    longitude: -132.36972,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 73,
      siteName: 'Wrangell',
      siteIdentifier: 'WRG',
      latitude: 56.486652,
      longitude: -132.38715,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10429,
    latitude: 45.74376,
    longitude: -123.13497,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 759,
      siteName: 'Buxton',
      latitude: 45.74376,
      longitude: -123.13497,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10508,
    latitude: 44.13468,
    longitude: -122.27601,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 819,
      siteName: 'Deathball Mtn',
      latitude: 44.13468,
      longitude: -122.27601,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10505,
    latitude: 43.52216,
    longitude: -123.10734,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 751,
      siteName: 'Harness Mtn',
      latitude: 43.52216,
      longitude: -123.10734,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10455,
    latitude: 44.50524,
    longitude: -122.96177,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 762,
      siteName: 'Peterson Butte',
      latitude: 44.50524,
      longitude: -122.96177,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10444,
    latitude: 44.5074,
    longitude: -123.572426,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 754,
      siteName: 'Marys Peak',
      latitude: 44.5074,
      longitude: -123.572426,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10400,
    latitude: 43.811104,
    longitude: -120.87446,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 753,
      siteName: 'Pine Mtn',
      latitude: 43.811104,
      longitude: -120.87446,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10399,
    latitude: 43.348686,
    longitude: -119.89153,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 761,
      siteName: 'Wagontire',
      latitude: 43.348686,
      longitude: -119.89153,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10447,
    latitude: 41.43528,
    longitude: -122.39747,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 672,
      siteName: 'Weed',
      latitude: 41.43528,
      longitude: -122.39747,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10442,
    latitude: 41.1268,
    longitude: -122.29175,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 665,
      siteName: 'Girard Ridge',
      latitude: 41.1268,
      longitude: -122.29175,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10373,
    latitude: 41.025536,
    longitude: -119.55821,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 776,
      siteName: 'Fox Mtn',
      latitude: 41.025536,
      longitude: -119.55821,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10539,
    latitude: 58.388237,
    longitude: -134.76186,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 257,
      siteName: 'Lena Point',
      siteIdentifier: 'QLT',
      latitude: 58.388237,
      longitude: -134.76186,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10522,
    latitude: 57.18544,
    longitude: -133.57362,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 197,
      siteName: 'Cape Fanshaw',
      siteIdentifier: '7BJ',
      latitude: 57.18544,
      longitude: -133.57362,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10543,
    latitude: 58.317547,
    longitude: -134.10088,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 108,
      siteName: 'Taku Inlet',
      siteIdentifier: 'TKL',
      latitude: 58.317547,
      longitude: -134.10088,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10542,
    latitude: 58.67996,
    longitude: -134.94044,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 27,
      siteName: 'Berners Bay',
      siteIdentifier: 'Z23',
      latitude: 58.67996,
      longitude: -134.94044,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10538,
    latitude: 58.1248,
    longitude: -134.75568,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 143,
      siteName: 'Hawk Inlet',
      siteIdentifier: 'HWI',
      latitude: 58.1248,
      longitude: -134.75568,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10537,
    latitude: 58.26108,
    longitude: -134.5115,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 413,
      siteName: 'Eaglecrest',
      siteIdentifier: 'JNUH',
      latitude: 58.26108,
      longitude: -134.5115,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10525,
    latitude: 58.06225,
    longitude: -134.05096,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 184,
      siteName: 'Grave Point',
      siteIdentifier: 'A43',
      latitude: 58.06225,
      longitude: -134.05096,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10595,
    latitude: 46.304966,
    longitude: -95.44062,
    timeZoneId: 'America/Chicago',
    data: {
      siteId: 720,
      siteName: 'Henning Municipal Airport',
      siteIdentifier: '05Y',
      latitude: 46.304966,
      longitude: -95.44062,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10599,
    latitude: 43.97695,
    longitude: -117.22362,
    timeZoneId: 'America/Boise',
    data: {
      siteId: 755,
      siteName: 'Rhinehart Butte',
      latitude: 43.97695,
      longitude: -117.22362,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10574,
    latitude: 43.25041,
    longitude: -117.23834,
    timeZoneId: 'America/Boise',
    data: {
      siteId: 770,
      siteName: 'Mahogany',
      latitude: 43.25041,
      longitude: -117.23834,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10558,
    latitude: 43.248775,
    longitude: -115.434456,
    timeZoneId: 'America/Boise',
    data: {
      siteId: 782,
      siteName: 'Bennett Mtn',
      latitude: 43.248775,
      longitude: -115.434456,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10555,
    latitude: 47.6936,
    longitude: -114.1832,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 806,
      siteName: 'Polson Airport',
      siteIdentifier: '8S1',
      latitude: 47.6936,
      longitude: -114.1832,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10524,
    latitude: 48.06008,
    longitude: -114.00193,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 682,
      siteName: 'Ferndale Airport',
      siteIdentifier: '53U',
      latitude: 48.06008,
      longitude: -114.00193,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10483,
    latitude: 47.5974,
    longitude: -110.2715,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 809,
      siteName: 'Geraldine',
      siteIdentifier: '5U8',
      latitude: 47.5974,
      longitude: -110.2715,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10464,
    latitude: 48.1662,
    longitude: -110.1095,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 810,
      siteName: 'Big Sandy Airport',
      siteIdentifier: '3U8',
      latitude: 48.1662,
      longitude: -110.1095,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10434,
    latitude: 48.56654,
    longitude: -108.76907,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 686,
      siteName: 'Harlem Airport',
      siteIdentifier: '48S',
      latitude: 48.56654,
      longitude: -108.76907,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10482,
    latitude: 46.32814,
    longitude: -111.48068,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 594,
      siteName: 'Townsend Airport',
      siteIdentifier: '8U8',
      latitude: 46.32814,
      longitude: -111.48068,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10459,
    latitude: 45.66706,
    longitude: -110.80416,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 549,
      siteName: 'Bozeman Pass',
      siteIdentifier: '',
      latitude: 45.66706,
      longitude: -110.80416,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10407,
    latitude: 45.87929,
    longitude: -104.54302,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 695,
      siteName: 'Ekalaka Airport',
      siteIdentifier: '97M',
      latitude: 45.87929,
      longitude: -104.54302,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10550,
    latitude: 47.16853,
    longitude: -114.85253,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 565,
      siteName: 'Superior Airport',
      siteIdentifier: '9S4',
      latitude: 47.16853,
      longitude: -114.85253,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10513,
    latitude: 46.95774,
    longitude: -112.645935,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 536,
      siteName: 'Lincoln Airport',
      siteIdentifier: 'S69',
      latitude: 46.95774,
      longitude: -112.645935,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10511,
    latitude: 47.178368,
    longitude: -113.44684,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 537,
      siteName: 'Seeley Lake Airport',
      siteIdentifier: '23S',
      latitude: 47.178368,
      longitude: -113.44684,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10465,
    latitude: 45.63057,
    longitude: -109.23627,
    timeZoneId: 'America/Denver',
    data: {
      siteId: 596,
      siteName: 'Columbus Airport',
      siteIdentifier: '6S3',
      latitude: 45.63057,
      longitude: -109.23627,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10436,
    latitude: 57.38861,
    longitude: -122.8525,
    timeZoneId: 'America/Dawson_Creek',
    data: {
      siteId: 290,
      siteName: 'Buckinghorse River (Sikanni Chief)',
      siteIdentifier: '',
      latitude: 57.38861,
      longitude: -122.8525,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3194,
    latitude: 58.6143,
    longitude: -101.469,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 497,
      siteName: 'Lac Brochet',
      siteIdentifier: '',
      latitude: 58.61417,
      longitude: -101.46889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3468,
    latitude: 55.863,
    longitude: -92.0814,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 525,
      siteName: 'Shamattawa',
      siteIdentifier: '',
      latitude: 55.863056,
      longitude: -92.08139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2591,
    latitude: 52.9436,
    longitude: -91.3126,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 501,
      siteName: 'Round Lake (Weagamow Lake)',
      siteIdentifier: '',
      latitude: 52.94361,
      longitude: -91.3125,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10365,
    latitude: 47.77465,
    longitude: -120.697655,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 787,
      siteName: 'Natapoc Ridge',
      latitude: 47.77465,
      longitude: -120.697655,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10479,
    latitude: 55.14275,
    longitude: -131.2171,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 440,
      siteName: 'Twin Island',
      siteIdentifier: 'KTNC',
      latitude: 55.14275,
      longitude: -131.2171,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10480,
    latitude: 55.512558,
    longitude: -130.9089,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 133,
      siteName: 'Misty Fjords',
      siteIdentifier: 'QA6B',
      latitude: 55.512558,
      longitude: -130.9089,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10484,
    latitude: 55.38303,
    longitude: -131.26648,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 436,
      siteName: 'Minx Island',
      siteIdentifier: 'KTNB',
      latitude: 55.38303,
      longitude: -131.26648,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10514,
    latitude: 55.46059,
    longitude: -131.81013,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 235,
      siteName: 'Point Higgins',
      siteIdentifier: 'KTNA',
      latitude: 55.46059,
      longitude: -131.81013,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2599,
    latitude: 52.1215,
    longitude: -101.234,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 527,
      siteName: 'Swan River',
      siteIdentifier: '',
      latitude: 52.121387,
      longitude: -101.23444,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3350,
    latitude: 49.0156,
    longitude: -118.43,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 408,
      siteName: 'Grand Forks',
      siteIdentifier: '',
      latitude: 49.01658,
      longitude: -118.42409,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3469,
    latitude: 50.6825,
    longitude: -119.229,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 311,
      siteName: 'Salmon Arm',
      siteIdentifier: '',
      latitude: 50.6825,
      longitude: -119.22861,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2650,
    latitude: 50.2462,
    longitude: -119.331,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 316,
      siteName: 'Vernon',
      siteIdentifier: 'WJV',
      latitude: 50.24611,
      longitude: -119.33083,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2663,
    latitude: 59.5764,
    longitude: -133.671,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 275,
      siteName: 'Atlin',
      siteIdentifier: 'YSQ',
      latitude: 59.576668,
      longitude: -133.66888,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2671,
    latitude: 49.7817,
    longitude: -123.162,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 313,
      siteName: 'Squamish',
      siteIdentifier: '',
      latitude: 49.78167,
      longitude: -123.16194,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2701,
    latitude: 50.3025,
    longitude: -122.738,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 308,
      siteName: 'Pemberton',
      siteIdentifier: '',
      latitude: 50.3025,
      longitude: -122.73778,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3339,
    latitude: 51.8198,
    longitude: -93.9733,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 499,
      siteName: 'Pikangikum',
      siteIdentifier: '',
      latitude: 51.81972,
      longitude: -93.97333,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2713,
    latitude: 58.4914,
    longitude: -119.408,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 429,
      siteName: 'Rainbow Lake',
      siteIdentifier: '',
      latitude: 58.4914,
      longitude: -119.40765,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2715,
    latitude: 54.9332,
    longitude: -95.2789,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 475,
      siteName: 'Oxford House',
      siteIdentifier: 'YOH',
      latitude: 54.92962,
      longitude: -95.284225,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3700,
    latitude: 50.6747,
    longitude: -121.894,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 302,
      siteName: 'Lillooet',
      siteIdentifier: '',
      latitude: 50.67472,
      longitude: -121.89361,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2774,
    latitude: 54.5584,
    longitude: -94.4909,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 466,
      siteName: 'Gods Lake Narrows',
      siteIdentifier: 'YGO',
      latitude: 54.553093,
      longitude: -94.481766,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3055,
    latitude: 51.2,
    longitude: -115.533,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 427,
      siteName: 'Banff',
      siteIdentifier: '',
      latitude: 51.2057,
      longitude: -115.53332,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3602,
    latitude: 50.5822,
    longitude: -126.916,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 452,
      siteName: 'Alert Bay',
      siteIdentifier: 'YAL',
      latitude: 50.58053,
      longitude: -126.91083,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2827,
    latitude: 51.7273,
    longitude: -91.8245,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 488,
      siteName: 'Cat Lake',
      siteIdentifier: '',
      latitude: 51.72422,
      longitude: -91.82444,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3063,
    latitude: 53.9657,
    longitude: -91.0272,
    timeZoneId: 'America/Winnipeg',
    data: {
      siteId: 487,
      siteName: 'Bearskin Lake',
      siteIdentifier: '',
      latitude: 53.96556,
      longitude: -91.02722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3204,
    latitude: 49.8965,
    longitude: -109.476,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 498,
      siteName: 'Maple Creek',
      siteIdentifier: '',
      latitude: 49.89639,
      longitude: -109.47583,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3183,
    latitude: 49.6975,
    longitude: -103.801,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 502,
      siteName: 'Weyburn',
      siteIdentifier: '',
      latitude: 49.6975,
      longitude: -103.800835,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10515,
    latitude: 55.68596,
    longitude: -132.5287,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 196,
      siteName: 'Thorne Bay',
      siteIdentifier: 'KTB',
      latitude: 55.68596,
      longitude: -132.5287,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10516,
    latitude: 55.472794,
    longitude: -131.82059,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 444,
      siteName: 'Clover Pass',
      siteIdentifier: 'na',
      latitude: 55.472794,
      longitude: -131.82059,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10517,
    latitude: 55.47405,
    longitude: -133.13737,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 159,
      siteName: 'Craig',
      siteIdentifier: 'CGA',
      latitude: 55.47405,
      longitude: -133.13737,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10518,
    latitude: 55.73731,
    longitude: -132.25932,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 158,
      siteName: 'Meyers Chuck',
      siteIdentifier: '84K',
      latitude: 55.73731,
      longitude: -132.25932,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10520,
    latitude: 55.94689,
    longitude: -133.67236,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 226,
      siteName: 'Edna Bay',
      siteIdentifier: 'EDA',
      latitude: 55.94689,
      longitude: -133.67236,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10521,
    latitude: 55.53953,
    longitude: -132.40361,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 187,
      siteName: 'Kasaan',
      siteIdentifier: 'KXA',
      latitude: 55.53953,
      longitude: -132.40361,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10526,
    latitude: 55.45857,
    longitude: -132.84369,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 250,
      siteName: 'Harris River Pass',
      siteIdentifier: 'HYLA',
      latitude: 55.45857,
      longitude: -132.84369,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10512,
    latitude: 56.46743,
    longitude: -133.08304,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 3,
      siteName: 'Level Island',
      siteIdentifier: 'LVD',
      latitude: 56.46743,
      longitude: -133.08304,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10600,
    latitude: 50.26472,
    longitude: -102.46139,
    timeZoneId: 'America/Regina',
    data: {
      siteId: 337,
      siteName: 'Yorkton',
      siteIdentifier: '',
      latitude: 50.26472,
      longitude: -102.46139,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3396,
    latitude: 60.4235,
    longitude: -121.27,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 529,
      siteName: 'Trout Lake / Sambaa K e',
      siteIdentifier: '',
      latitude: 60.42361,
      longitude: -121.26972,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3440,
    latitude: 51.4963,
    longitude: -112.749,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 425,
      siteName: 'Drumheller',
      siteIdentifier: '',
      latitude: 51.5012,
      longitude: -112.75054,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3391,
    latitude: 53.319,
    longitude: -117.753,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 326,
      siteName: 'Jasper Hinton',
      siteIdentifier: '',
      latitude: 53.31917,
      longitude: -117.75306,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2895,
    latitude: 56.9668,
    longitude: -130.249,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 277,
      siteName: 'Bob Quinn Lake',
      siteIdentifier: 'BW4',
      latitude: 56.966667,
      longitude: -130.24945,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3430,
    latitude: 49.3219,
    longitude: -124.931,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 479,
      siteName: 'Port Alberni',
      siteIdentifier: '',
      latitude: 49.319084,
      longitude: -124.93003,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3640,
    latitude: 56.9061,
    longitude: -124.965,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 315,
      siteName: 'Tsay Keh',
      siteIdentifier: '',
      latitude: 56.90611,
      longitude: -124.965,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10449,
    latitude: 50.16833,
    longitude: -122.90417,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 317,
      siteName: 'Whistler Heliport',
      siteIdentifier: '',
      latitude: 50.16833,
      longitude: -122.90417,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10408,
    latitude: 49.06333,
    longitude: -120.78694,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 305,
      siteName: 'Manning Provincial Park',
      siteIdentifier: '',
      latitude: 49.06333,
      longitude: -120.78694,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10450,
    latitude: 49.13888,
    longitude: -123.195274,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 513,
      siteName: 'Garry Point (Richmond)',
      siteIdentifier: '',
      latitude: 49.13888,
      longitude: -123.195274,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10452,
    latitude: 49.16861,
    longitude: -123.935555,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 279,
      siteName: 'Nanaimo Harbour',
      siteIdentifier: 'WNA',
      latitude: 49.16861,
      longitude: -123.935555,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10451,
    latitude: 48.85389,
    longitude: -123.4975,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 282,
      siteName: 'Salt Spring Island - Ganges Harbour',
      siteIdentifier: '',
      latitude: 48.85389,
      longitude: -123.4975,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10478,
    latitude: 54.33167,
    longitude: -130.27667,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 285,
      siteName: 'Seal Cove - Prince Rupert',
      siteIdentifier: 'ZSW',
      latitude: 54.33167,
      longitude: -130.27667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10419,
    latitude: 51.64167,
    longitude: -120.03667,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 293,
      siteName: 'Clearwater',
      siteIdentifier: '',
      latitude: 51.64167,
      longitude: -120.03667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10470,
    latitude: 51.681667,
    longitude: -127.256386,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 523,
      siteName: 'Rivers Inlet (Oweekeno Village)',
      siteIdentifier: '',
      latitude: 51.681667,
      longitude: -127.256386,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10448,
    latitude: 49.84778,
    longitude: -123.18888,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 292,
      siteName: 'Cheakamus Canyon Tower',
      siteIdentifier: '',
      latitude: 49.84778,
      longitude: -123.18888,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10454,
    latitude: 50.06639,
    longitude: -123.11028,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 318,
      siteName: 'Whistler Radar',
      siteIdentifier: '',
      latitude: 50.06639,
      longitude: -123.11028,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10469,
    latitude: 50.02083,
    longitude: -127.36444,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 516,
      siteName: 'Kyuquot',
      siteIdentifier: '',
      latitude: 50.02083,
      longitude: -127.36444,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3676,
    latitude: 49.1733,
    longitude: -119.551,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 409,
      siteName: 'Oliver',
      siteIdentifier: '',
      latitude: 49.17399,
      longitude: -119.55191,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3613,
    latitude: 57.4272,
    longitude: -125.65,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 296,
      siteName: 'Fort Ware',
      siteIdentifier: '',
      latitude: 57.42722,
      longitude: -125.65028,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2965,
    latitude: 52.4521,
    longitude: -125.304,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 289,
      siteName: 'Anahim Lake',
      siteIdentifier: '',
      latitude: 52.45222,
      longitude: -125.30444,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3107,
    latitude: 52.8527,
    longitude: -119.336,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 531,
      siteName: 'Valemount',
      siteIdentifier: '',
      latitude: 52.85277,
      longitude: -119.33638,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3209,
    latitude: 50.1236,
    longitude: -120.744,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 306,
      siteName: 'Merritt',
      siteIdentifier: '',
      latitude: 50.12278,
      longitude: -120.74722,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3713,
    latitude: 50.5167,
    longitude: -116.001,
    timeZoneId: 'America/Edmonton',
    data: {
      siteId: 300,
      siteName: 'Invermere',
      siteIdentifier: '',
      latitude: 50.51666,
      longitude: -116,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2403,
    latitude: 56.01472,
    longitude: -132.8339,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 247,
      siteName: 'Coffman Cove',
      siteIdentifier: 'KCC',
      latitude: 56.00618,
      longitude: -132.815,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10570,
    latitude: 42.32136,
    longitude: -117.87523,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 785,
      siteName: 'Blue Mtn OR',
      latitude: 42.32136,
      longitude: -117.87523,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10503,
    latitude: 42.240307,
    longitude: -123.7673,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 769,
      siteName: 'Fiddler',
      latitude: 42.240307,
      longitude: -123.7673,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10497,
    latitude: 42.30763,
    longitude: -122.83898,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 790,
      siteName: 'Rogue Valley Manor',
      latitude: 42.30763,
      longitude: -122.83898,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10460,
    latitude: 42.12327,
    longitude: -124.30006,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 750,
      siteName: 'Red Mound',
      latitude: 42.12327,
      longitude: -124.30006,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10438,
    latitude: 42.15653,
    longitude: -123.64339,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 763,
      siteName: 'Cave Junction',
      latitude: 42.15653,
      longitude: -123.64339,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10376,
    latitude: 42.18685,
    longitude: -119.92506,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 766,
      siteName: 'Fish Creek Rim',
      latitude: 42.18685,
      longitude: -119.92506,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10374,
    latitude: 42.385914,
    longitude: -119.33212,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 756,
      siteName: 'Beaty Butte',
      latitude: 42.385914,
      longitude: -119.33212,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10382,
    latitude: 41.363,
    longitude: -120.839,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 714,
      siteName: 'Ballard Ridge',
      latitude: 41.363,
      longitude: -120.839,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10571,
    latitude: 41.58597,
    longitude: -118.7624,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 788,
      siteName: 'New York Peak',
      latitude: 41.58597,
      longitude: -118.7624,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10423,
    latitude: 41.567753,
    longitude: -119.90828,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 791,
      siteName: 'Fortynine Mtn',
      latitude: 41.567753,
      longitude: -119.90828,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10367,
    latitude: 41.83601,
    longitude: -119.6244,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 774,
      siteName: 'Yellow Peak',
      latitude: 41.83601,
      longitude: -119.6244,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10430,
    latitude: 41.610207,
    longitude: -122.62485,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 666,
      siteName: 'Antelope Mtn Yreka',
      latitude: 41.610207,
      longitude: -122.62485,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10563,
    latitude: 41.6176,
    longitude: -114.26392,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 815,
      siteName: 'Delano',
      latitude: 41.6176,
      longitude: -114.26392,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10496,
    latitude: 41.8303,
    longitude: -123.8768,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 767,
      siteName: 'Wild Frontier',
      latitude: 41.8303,
      longitude: -123.8768,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10445,
    latitude: 42.077637,
    longitude: -121.97329,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 758,
      siteName: 'Hamaker',
      latitude: 42.077637,
      longitude: -121.97329,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10440,
    latitude: 41.595783,
    longitude: -122.8774,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 768,
      siteName: 'Fort Jones',
      latitude: 41.595783,
      longitude: -122.8774,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10439,
    latitude: 41.92988,
    longitude: -122.5574,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 765,
      siteName: 'Hornbrook',
      latitude: 41.92988,
      longitude: -122.5574,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10398,
    latitude: 42.033886,
    longitude: -121.27586,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 764,
      siteName: 'Bryant Mtn',
      latitude: 42.033886,
      longitude: -121.27586,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10559,
    latitude: 46.069202,
    longitude: -116.465096,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 730,
      siteName: 'Cottonwood ID',
      latitude: 46.069202,
      longitude: -116.465096,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10597,
    latitude: 46.461353,
    longitude: -117.016556,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 731,
      siteName: 'Lewiston Hill',
      latitude: 46.461353,
      longitude: -117.016556,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10507,
    latitude: 46.01611,
    longitude: -123.90972,
    timeZoneId: 'America/Los_Angeles',
    data: {
      siteId: 811,
      siteName: 'Seaside Airport',
      siteIdentifier: '56S',
      latitude: 46.01611,
      longitude: -123.90972,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2762,
    latitude: 68.6355,
    longitude: -95.8504,
    timeZoneId: 'America/Cambridge_Bay',
    data: {
      siteId: 465,
      siteName: 'Gjoa Haven',
      siteIdentifier: 'YHK',
      latitude: 68.633354,
      longitude: -95.84914,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2709,
    latitude: 69.3607,
    longitude: -124.076,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 519,
      siteName: 'Paulatuk (Nora Aliqatchialuk Ruben)',
      siteIdentifier: '',
      latitude: 69.36055,
      longitude: -124.075836,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2649,
    latitude: 67.0212,
    longitude: -126.129,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 462,
      siteName: 'Colville Lake/Tommy Kochon',
      siteIdentifier: 'YCK',
      latitude: 67.0211,
      longitude: -126.12861,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2552,
    latitude: 21.97583,
    longitude: -159.33888,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 618,
      siteName: 'Lihue',
      siteIdentifier: 'LIH',
      latitude: 22.00194,
      longitude: -159.35777,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2563,
    latitude: 20.265,
    longitude: -155.85973,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 567,
      siteName: 'Upolu',
      siteIdentifier: 'UPP',
      latitude: 20.25698,
      longitude: -155.84709,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10357,
    latitude: 22.221214,
    longitude: -159.49767,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 757,
      siteName: 'Princeville',
      siteIdentifier: 'QQK',
      latitude: 22.221214,
      longitude: -159.49767,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10369,
    latitude: 22.13518,
    longitude: -159.72862,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 615,
      siteName: 'Makaha Ridge',
      siteIdentifier: 'BKH',
      latitude: 22.13518,
      longitude: -159.72862,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10370,
    latitude: 21.931011,
    longitude: -159.61241,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 548,
      siteName: 'Loleau',
      siteIdentifier: 'SOK',
      latitude: 21.931011,
      longitude: -159.61241,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10527,
    latitude: 19.93305,
    longitude: -155.645,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 560,
      siteName: 'Waimea',
      siteIdentifier: 'MUE',
      latitude: 19.93305,
      longitude: -155.645,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10528,
    latitude: 19.23722,
    longitude: -155.48193,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 558,
      siteName: 'Pahala',
      siteIdentifier: 'QKL',
      latitude: 19.23722,
      longitude: -155.48193,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10529,
    latitude: 20.63166,
    longitude: -156.21242,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 710,
      siteName: 'Manawainui Gulch',
      latitude: 20.63166,
      longitude: -156.21242,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10532,
    latitude: 20.9918,
    longitude: -156.54628,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 687,
      siteName: 'Kahakuloa',
      siteIdentifier: 'JHMA',
      latitude: 20.9918,
      longitude: -156.54628,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10533,
    latitude: 19.83531,
    longitude: -155.10938,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 590,
      siteName: 'Laupahoehoe',
      siteIdentifier: 'QHC',
      latitude: 19.83531,
      longitude: -155.10938,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10534,
    latitude: 19.31718,
    longitude: -155.10257,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 625,
      siteName: 'Holei Pali',
      siteIdentifier: 'POAA',
      latitude: 19.31718,
      longitude: -155.10257,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10536,
    latitude: 21.45648,
    longitude: -158.06255,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 591,
      siteName: 'Wahiawa',
      siteIdentifier: 'HHI',
      latitude: 21.45648,
      longitude: -158.06255,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10548,
    latitude: 20.8416,
    longitude: -156.89822,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 693,
      siteName: 'Lanai',
      siteIdentifier: 'LNY',
      latitude: 20.8416,
      longitude: -156.89822,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10549,
    latitude: 21.64409,
    longitude: -157.93102,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 592,
      siteName: 'Laie',
      siteIdentifier: 'HI58',
      latitude: 21.64409,
      longitude: -157.93102,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10554,
    latitude: 21.56691,
    longitude: -158.12773,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 559,
      siteName: 'North Shore',
      siteIdentifier: 'HDH',
      latitude: 21.56691,
      longitude: -158.12773,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10356,
    latitude: 22.18397,
    longitude: -159.45682,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 555,
      siteName: 'Powerline Trail',
      siteIdentifier: 'QKK',
      latitude: 22.18397,
      longitude: -159.45682,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10553,
    latitude: 21.30996,
    longitude: -157.64977,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 588,
      siteName: 'Waimanalo',
      siteIdentifier: 'CKH',
      latitude: 21.30996,
      longitude: -157.64977,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2553,
    latitude: 21.21083,
    longitude: -156.97333,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 645,
      siteName: 'Kalaupapa',
      siteIdentifier: 'LUP',
      latitude: 21.20952,
      longitude: -156.96959,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2549,
    latitude: 20.96278,
    longitude: -156.67278,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 589,
      siteName: 'Kapalua',
      siteIdentifier: 'JHM',
      latitude: 20.963,
      longitude: -156.6747,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2556,
    latitude: 21.45028,
    longitude: -157.76778,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 760,
      siteName: 'Kaneohe Bay',
      siteIdentifier: 'NGF',
      latitude: 21.42245,
      longitude: -157.75706,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2548,
    latitude: 20.79556,
    longitude: -156.01418,
    timeZoneId: 'Pacific/Honolulu',
    data: {
      siteId: 690,
      siteName: 'Hana',
      siteIdentifier: 'HNM',
      latitude: 20.79461,
      longitude: -156.01585,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2511,
    latitude: 60.77694,
    longitude: -148.71944,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 2,
      siteName: 'Whittier',
      siteIdentifier: 'IEM',
      latitude: 60.77528,
      longitude: -148.72647,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2476,
    latitude: 61.70028,
    longitude: -157.16583,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 231,
      siteName: 'Sleetmute',
      siteIdentifier: 'SLQ',
      latitude: 61.70211,
      longitude: -157.16882,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2347,
    latitude: 59.04444,
    longitude: -158.50528,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 244,
      siteName: 'Dillingham',
      siteIdentifier: 'DLG',
      latitude: 59.04549,
      longitude: -158.51054,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2500,
    latitude: 61.54083,
    longitude: -165.60083,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 43,
      siteName: 'Chevak',
      siteIdentifier: 'VAK',
      latitude: 61.536827,
      longitude: -165.60109,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2469,
    latitude: 64.72722,
    longitude: -155.46973,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 65,
      siteName: 'Ruby',
      siteIdentifier: 'RBY',
      latitude: 64.730865,
      longitude: -155.46252,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2395,
    latitude: 59.75556,
    longitude: -154.9175,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 86,
      siteName: 'Iliamna',
      siteIdentifier: 'ILI',
      latitude: 59.754898,
      longitude: -154.90747,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2514,
    latitude: 63.02444,
    longitude: -145.50027,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 39,
      siteName: 'Isabel Pass South',
      siteIdentifier: 'Z32A',
      latitude: 63.033524,
      longitude: -145.49763,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2320,
    latitude: 60.77833,
    longitude: -161.83694,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 48,
      siteName: 'Bethel',
      siteIdentifier: 'BET',
      latitude: 60.77734,
      longitude: -161.8449,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2384,
    latitude: 65.69778,
    longitude: -156.3514,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 12,
      siteName: 'Huslia',
      siteIdentifier: 'HLA',
      latitude: 65.69584,
      longitude: -156.36351,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2501,
    latitude: 55.11611,
    longitude: -162.26639,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 17,
      siteName: 'King Cove',
      siteIdentifier: 'KVC',
      latitude: 55.1145,
      longitude: -162.2708,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2462,
    latitude: 57.58028,
    longitude: -157.57195,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 18,
      siteName: 'Pilot Point',
      siteIdentifier: 'PNP',
      latitude: 57.57865,
      longitude: -157.56859,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2450,
    latitude: 64.5125,
    longitude: -165.44417,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 115,
      siteName: 'Nome',
      siteIdentifier: 'OME',
      latitude: 64.50671,
      longitude: -165.44626,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2515,
    latitude: 59.50306,
    longitude: -139.66,
    timeZoneId: 'America/Yakutat',
    data: {
      siteId: 7,
      siteName: 'Yakutat',
      siteIdentifier: 'YAK',
      latitude: 59.51,
      longitude: -139.65964,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2474,
    latitude: 57.04667,
    longitude: -135.36082,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 253,
      siteName: 'Sitka',
      siteIdentifier: 'SIT',
      latitude: 57.05162,
      longitude: -135.36339,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2331,
    latitude: 55.20583,
    longitude: -162.7261,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 128,
      siteName: 'Cold Bay',
      siteIdentifier: 'CDB',
      latitude: 55.20335,
      longitude: -162.71179,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2427,
    latitude: 62.95278,
    longitude: -155.60693,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 78,
      siteName: 'Mc Grath',
      siteIdentifier: 'MCG',
      latitude: 62.9563,
      longitude: -155.6005,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2405,
    latitude: 54.84778,
    longitude: -163.40694,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 98,
      siteName: 'False Pass',
      siteIdentifier: 'KFP',
      latitude: 54.85012,
      longitude: -163.40987,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2321,
    latitude: 61.17278,
    longitude: -151.04527,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 72,
      siteName: 'Beluga',
      siteIdentifier: 'BLG',
      latitude: 61.185493,
      longitude: -151.03456,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2399,
    latitude: 56.31139,
    longitude: -158.37306,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 84,
      siteName: 'Chignik Bay',
      siteIdentifier: 'AJC',
      latitude: 56.309334,
      longitude: -158.37819,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2378,
    latitude: 58.42528,
    longitude: -135.70721,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 434,
      siteName: 'Gustavus',
      siteIdentifier: 'GST',
      latitude: 58.425255,
      longitude: -135.70644,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8372,
    latitude: 60.577,
    longitude: -145.755,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 799,
      siteName: 'Tripod Hill',
      latitude: 60.553223,
      longitude: -145.73338,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8581,
    latitude: 61.06,
    longitude: -146.7,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 28,
      siteName: 'Potato Point',
      siteIdentifier: 'QAO',
      latitude: 61.0565,
      longitude: -146.69757,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2440,
    latitude: 64.54722,
    longitude: -149.07388,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 111,
      siteName: 'Nenana',
      siteIdentifier: 'ENN',
      latitude: 64.54972,
      longitude: -149.08345,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2477,
    latitude: 62.06056,
    longitude: -163.30167,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 45,
      siteName: 'St. Marys',
      siteIdentifier: 'KSM',
      latitude: 62.05711,
      longitude: -163.29613,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8554,
    latitude: 62.509,
    longitude: -153.892,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 46,
      siteName: 'Farewell',
      siteIdentifier: '0AA4',
      latitude: 62.50955,
      longitude: -153.88802,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8560,
    latitude: 60.482,
    longitude: -146.584,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 16,
      siteName: 'Johnstone Point',
      siteIdentifier: 'JOH',
      latitude: 60.48221,
      longitude: -146.57655,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2483,
    latitude: 61.96611,
    longitude: -151.19528,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 180,
      siteName: 'Skwentna',
      siteIdentifier: 'SKW',
      latitude: 61.96618,
      longitude: -151.20053,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2527,
    latitude: 64.87556,
    longitude: -157.73029,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 823,
      siteName: 'Koyukuk',
      siteIdentifier: 'KYU',
      latitude: 64.87968,
      longitude: -157.7184,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2354,
    latitude: 64.77806,
    longitude: -141.14944,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 272,
      siteName: 'Eagle',
      siteIdentifier: 'EAA',
      latitude: 64.77615,
      longitude: -141.1636,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2497,
    latitude: 63.88833,
    longitude: -160.79889,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 29,
      siteName: 'Unalakleet',
      siteIdentifier: 'UNK',
      latitude: 63.88472,
      longitude: -160.79135,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2369,
    latitude: 64.73611,
    longitude: -156.93443,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 20,
      siteName: 'Galena',
      siteIdentifier: 'GAL',
      latitude: 64.74151,
      longitude: -156.94945,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2408,
    latitude: 64.93944,
    longitude: -161.15417,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 9,
      siteName: 'Koyuk',
      siteIdentifier: 'KKA',
      latitude: 64.93553,
      longitude: -161.16278,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2472,
    latitude: 55.31361,
    longitude: -160.5214,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 49,
      siteName: 'Sand Point',
      siteIdentifier: 'SDP',
      latitude: 55.31775,
      longitude: -160.52155,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2478,
    latitude: 57.16611,
    longitude: -170.2225,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 41,
      siteName: 'St. Paul',
      siteIdentifier: 'PAU',
      latitude: 57.160347,
      longitude: -170.22653,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2313,
    latitude: 56.00583,
    longitude: -160.56056,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 675,
      siteName: 'Port Moller',
      latitude: 55.9875,
      longitude: -160.57695,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2329,
    latitude: 61.41583,
    longitude: -149.50806,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 259,
      siteName: 'Birchwood',
      siteIdentifier: 'BCV',
      latitude: 61.416103,
      longitude: -149.5113,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2390,
    latitude: 62.69222,
    longitude: -159.56917,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 219,
      siteName: 'Shageluk',
      siteIdentifier: 'SHX',
      latitude: 62.68813,
      longitude: -159.56648,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2392,
    latitude: 59.32389,
    longitude: -155.90167,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 439,
      siteName: 'Igiugig',
      siteIdentifier: 'IGG',
      latitude: 59.32587,
      longitude: -155.89705,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2393,
    latitude: 58.18528,
    longitude: -157.37527,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 127,
      siteName: 'Egegik',
      siteIdentifier: 'EII',
      latitude: 58.2089,
      longitude: -157.3759,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2401,
    latitude: 59.72667,
    longitude: -157.26,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 22,
      siteName: 'Koliganek',
      siteIdentifier: 'JZZ',
      latitude: 59.726307,
      longitude: -157.26688,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2420,
    latitude: 61.53583,
    longitude: -160.34557,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 26,
      siteName: 'Kalskag',
      siteIdentifier: 'KLG',
      latitude: 61.53775,
      longitude: -160.3327,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2437,
    latitude: 60.69028,
    longitude: -161.97833,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 241,
      siteName: 'Napakiak',
      siteIdentifier: 'WNA',
      latitude: 60.69278,
      longitude: -161.97694,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2446,
    latitude: 59.45139,
    longitude: -157.37306,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 21,
      siteName: 'New Stuyahok',
      siteIdentifier: 'KNW',
      latitude: 59.4562,
      longitude: -157.369,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2457,
    latitude: 55.90667,
    longitude: -159.16055,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 203,
      siteName: 'Perryville',
      siteIdentifier: 'PEV',
      latitude: 55.91042,
      longitude: -159.14459,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2468,
    latitude: 61.77472,
    longitude: -161.31917,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 420,
      siteName: 'Russian Mission',
      siteIdentifier: 'RSH',
      latitude: 61.780003,
      longitude: -161.32257,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2367,
    latitude: 61.26583,
    longitude: -149.65306,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 623,
      siteName: 'Fort Richardson - Bryant AAF',
      siteIdentifier: 'PAFR',
      latitude: 61.263317,
      longitude: -149.66006,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2368,
    latitude: 63.01833,
    longitude: -154.35834,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 11,
      siteName: 'Nikolai',
      siteIdentifier: 'FSP',
      latitude: 63.015476,
      longitude: -154.3669,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2383,
    latitude: 62.18806,
    longitude: -159.77472,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 24,
      siteName: 'Holy Cross',
      siteIdentifier: 'HCA',
      latitude: 62.19052,
      longitude: -159.77473,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2415,
    latitude: 64.31889,
    longitude: -158.7411,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 30,
      siteName: 'Kaltag',
      siteIdentifier: 'KAL',
      latitude: 64.320786,
      longitude: -158.7324,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2444,
    latitude: 64.72917,
    longitude: -158.07417,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 230,
      siteName: 'Nulato',
      siteIdentifier: 'NUL',
      latitude: 64.73169,
      longitude: -158.07272,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2449,
    latitude: 58.09611,
    longitude: -135.40862,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 418,
      siteName: 'Hoonah',
      siteIdentifier: 'HNH',
      latitude: 58.09708,
      longitude: -135.41449,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2398,
    latitude: 65.6225,
    longitude: -168.09473,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 94,
      siteName: 'Wales',
      siteIdentifier: 'IWK',
      latitude: 65.61608,
      longitude: -168.09428,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2433,
    latitude: 62.09472,
    longitude: -163.68279,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 437,
      siteName: 'Mountain Village',
      siteIdentifier: 'MOU',
      latitude: 62.0948,
      longitude: -163.6862,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2430,
    latitude: 63.49,
    longitude: -162.11028,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 5,
      siteName: 'St. Michael',
      siteIdentifier: 'SMK',
      latitude: 63.48562,
      longitude: -162.11269,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2519,
    latitude: 58.83361,
    longitude: -158.52917,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 229,
      siteName: 'Clarks Point',
      siteIdentifier: 'CLP',
      latitude: 58.83676,
      longitude: -158.52428,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2528,
    latitude: 60.79028,
    longitude: -161.4436,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 240,
      siteName: 'Kwethluk',
      siteIdentifier: 'KWT',
      latitude: 60.79278,
      longitude: -161.43889,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2536,
    latitude: 58.70194,
    longitude: -157.0025,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 245,
      siteName: 'South Naknek',
      siteIdentifier: 'WSN',
      latitude: 58.70499,
      longitude: -157.0057,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2498,
    latitude: 61.75417,
    longitude: -150.05167,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 162,
      siteName: 'Willow',
      siteIdentifier: 'UUO',
      latitude: 61.76432,
      longitude: -150.02205,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2512,
    latitude: 61.57194,
    longitude: -149.53944,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 177,
      siteName: 'Wasilla',
      siteIdentifier: 'IYS',
      latitude: 61.571426,
      longitude: -149.54895,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2532,
    latitude: 64.37083,
    longitude: -161.22389,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 171,
      siteName: 'Shaktoolik',
      siteIdentifier: '2C7',
      latitude: 64.34892,
      longitude: -161.18443,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2454,
    latitude: 56.0075,
    longitude: -161.16028,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 10,
      siteName: 'Nelson Lagoon',
      siteIdentifier: 'OUL',
      latitude: 56.0071,
      longitude: -161.17216,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2506,
    latitude: 60.12972,
    longitude: -149.4164,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 258,
      siteName: 'Seward',
      siteIdentifier: 'SWD',
      latitude: 60.12259,
      longitude: -149.42822,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2358,
    latitude: 62.78611,
    longitude: -164.49055,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 42,
      siteName: 'Emmonak',
      siteIdentifier: 'ENM',
      latitude: 62.77823,
      longitude: -164.4954,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2424,
    latitude: 61.0875,
    longitude: -160.92332,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 124,
      siteName: 'Tuluksak',
      siteIdentifier: 'TLT',
      latitude: 61.0987,
      longitude: -160.95767,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2441,
    latitude: 59.98,
    longitude: -154.83917,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 140,
      siteName: 'Nondalton',
      siteIdentifier: '5NN',
      latitude: 59.973457,
      longitude: -154.85248,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2330,
    latitude: 58.19917,
    longitude: -136.63972,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 267,
      siteName: 'Cape Spencer',
      siteIdentifier: 'CSP',
      latitude: 58.198593,
      longitude: -136.6395,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2465,
    latitude: 59.755,
    longitude: -161.84528,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 15,
      siteName: 'Quinhagak',
      siteIdentifier: 'AQH',
      latitude: 59.75056,
      longitude: -161.84361,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2402,
    latitude: 60.87222,
    longitude: -146.69112,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 264,
      siteName: 'Tatitlek',
      siteIdentifier: 'na',
      latitude: 60.8611,
      longitude: -146.6752,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2418,
    latitude: 57.56583,
    longitude: -154.45361,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 238,
      siteName: 'Karluk',
      siteIdentifier: 'KYK',
      latitude: 57.56248,
      longitude: -154.4365,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2419,
    latitude: 57.535,
    longitude: -153.97667,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 31,
      siteName: 'Larsen Bay',
      siteIdentifier: '2A3',
      latitude: 57.53574,
      longitude: -153.979,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2484,
    latitude: 60.475,
    longitude: -151.03944,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 105,
      siteName: 'Soldotna',
      siteIdentifier: 'SXQ',
      latitude: 60.46393,
      longitude: -151.08147,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2570,
    latitude: 62.99278,
    longitude: -156.02972,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 227,
      siteName: 'Takotna',
      siteIdentifier: 'TCT',
      latitude: 62.99449,
      longitude: -156.03049,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2406,
    latitude: 56.93861,
    longitude: -154.1825,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 53,
      siteName: 'Akhiok',
      siteIdentifier: 'AKK',
      latitude: 56.9455,
      longitude: -154.1692,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2332,
    latitude: 65.57389,
    longitude: -144.78082,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 170,
      siteName: 'Central',
      siteIdentifier: 'CEM',
      latitude: 65.5704,
      longitude: -144.81644,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2505,
    latitude: 66.36222,
    longitude: -147.40639,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 153,
      siteName: 'Beaver',
      siteIdentifier: 'WBQ',
      latitude: 66.35972,
      longitude: -147.41252,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2397,
    latitude: 63.7325,
    longitude: -148.91055,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 35,
      siteName: 'McKinley Park',
      siteIdentifier: 'INR',
      latitude: 63.73203,
      longitude: -148.91258,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2429,
    latitude: 63.88583,
    longitude: -152.30167,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 76,
      siteName: 'Minchumina',
      siteIdentifier: 'MHM',
      latitude: 63.8834,
      longitude: -152.3107,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2486,
    latitude: 65.17417,
    longitude: -152.10806,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 261,
      siteName: 'Tanana',
      siteIdentifier: 'TAL',
      latitude: 65.17319,
      longitude: -152.1096,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2335,
    latitude: 61.87111,
    longitude: -158.13779,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 222,
      siteName: 'Crooked Creek',
      siteIdentifier: 'CJX',
      latitude: 61.86697,
      longitude: -158.13147,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2380,
    latitude: 62.89194,
    longitude: -160.06639,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 213,
      siteName: 'Grayling',
      siteIdentifier: 'KGX',
      latitude: 62.90665,
      longitude: -160.06305,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2445,
    latitude: 62.64722,
    longitude: -160.19,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 212,
      siteName: 'Anvik',
      siteIdentifier: 'ANV',
      latitude: 62.64251,
      longitude: -160.18391,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2323,
    latitude: 65.98139,
    longitude: -161.1489,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 132,
      siteName: 'Buckland',
      siteIdentifier: 'BVK',
      latitude: 65.97743,
      longitude: -161.12921,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2352,
    latitude: 61.25111,
    longitude: -149.8064,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 443,
      siteName: 'Joint Base Elmendorf-Richardson',
      siteIdentifier: 'na',
      latitude: 61.260727,
      longitude: -149.83119,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2523,
    latitude: 59.43306,
    longitude: -154.8025,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 163,
      siteName: 'Kokhanok',
      siteIdentifier: '9K2',
      latitude: 59.43951,
      longitude: -154.75648,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2410,
    latitude: 58.67639,
    longitude: -156.6486,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 116,
      siteName: 'King Salmon',
      siteIdentifier: 'AKN',
      latitude: 58.6766,
      longitude: -156.6557,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2417,
    latitude: 60.19833,
    longitude: -154.32278,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 818,
      siteName: 'Wilder',
      siteIdentifier: '05K',
      latitude: 60.19689,
      longitude: -154.32437,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2317,
    latitude: 61.59472,
    longitude: -149.08861,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 96,
      siteName: 'Palmer',
      siteIdentifier: 'PAQ',
      latitude: 61.603394,
      longitude: -149.0947,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2489,
    latitude: 59.05333,
    longitude: -160.39667,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 119,
      siteName: 'Togiak',
      siteIdentifier: 'TOG',
      latitude: 59.06179,
      longitude: -160.37634,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2439,
    latitude: 61.58139,
    longitude: -159.54527,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 106,
      siteName: 'Aniak',
      siteIdentifier: 'ANI',
      latitude: 61.56871,
      longitude: -159.5435,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8502,
    latitude: 58.971,
    longitude: -135.22,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 248,
      siteName: 'Eldred Rock',
      siteIdentifier: 'ERO',
      latitude: 58.97123,
      longitude: -135.22078,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8547,
    latitude: 60.08,
    longitude: -142.5,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 271,
      siteName: 'Cape Yakataga',
      siteIdentifier: 'CYT',
      latitude: 60.081367,
      longitude: -142.48688,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8565,
    latitude: 62.087,
    longitude: -152.722,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 81,
      siteName: 'Puntilla Lake',
      siteIdentifier: '6AK',
      latitude: 62.09785,
      longitude: -152.73392,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8568,
    latitude: 59.45,
    longitude: -136.32,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 211,
      siteName: 'Chilkat',
      siteIdentifier: 'QCH',
      latitude: 59.43874,
      longitude: -136.27269,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8570,
    latitude: 60.783,
    longitude: -148.843,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 40,
      siteName: 'Portage Glacier',
      siteIdentifier: 'A21',
      latitude: 60.784668,
      longitude: -148.84148,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8589,
    latitude: 58.171,
    longitude: -135.256,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 44,
      siteName: 'Sisters Island',
      siteIdentifier: 'SSR',
      latitude: 58.177574,
      longitude: -135.25775,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 8596,
    latitude: 57.779,
    longitude: -135.219,
    timeZoneId: 'America/Sitka',
    data: {
      siteId: 166,
      siteName: 'Tenakee Springs',
      siteIdentifier: 'TKE',
      latitude: 57.77925,
      longitude: -135.21925,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2381,
    latitude: 59.46,
    longitude: -135.31667,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 125,
      siteName: 'Skagway',
      siteIdentifier: 'SGY',
      latitude: 59.4538,
      longitude: -135.32755,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2448,
    latitude: 58.90639,
    longitude: -157.7111,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 201,
      siteName: 'Portage Creek',
      siteIdentifier: 'A14',
      latitude: 58.90605,
      longitude: -157.71655,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2426,
    latitude: 58.93194,
    longitude: -158.90167,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 19,
      siteName: 'Manokotak',
      siteIdentifier: 'MBA',
      latitude: 58.93361,
      longitude: -158.9029,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2350,
    latitude: 53.89889,
    longitude: -166.545,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 256,
      siteName: 'Dutch NDB',
      siteIdentifier: 'DUT',
      latitude: 53.905502,
      longitude: -166.548,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10494,
    latitude: 57.1999,
    longitude: -153.30681,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 142,
      siteName: 'Old Harbor',
      siteIdentifier: 'OLB',
      latitude: 57.1999,
      longitude: -153.30681,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10472,
    latitude: 60.77879,
    longitude: -151.12923,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 148,
      siteName: 'Nikiski',
      siteIdentifier: 'ENA',
      latitude: 60.77879,
      longitude: -151.12923,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10474,
    latitude: 61.21539,
    longitude: -149.89641,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 412,
      siteName: 'Anchorage',
      siteIdentifier: 'ANC',
      latitude: 61.21539,
      longitude: -149.89641,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10475,
    latitude: 59.77717,
    longitude: -150.9724,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 137,
      siteName: 'Bradley Lake',
      siteIdentifier: '0AK7',
      latitude: 59.77717,
      longitude: -150.9724,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10485,
    latitude: 59.97538,
    longitude: -152.67136,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 23,
      siteName: 'Silver Salmon Creek',
      siteIdentifier: 'na',
      latitude: 59.97538,
      longitude: -152.67136,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10486,
    latitude: 59.35447,
    longitude: -151.92079,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 221,
      siteName: 'Nanwalek',
      siteIdentifier: 'KEB',
      latitude: 59.35447,
      longitude: -151.92079,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10487,
    latitude: 60.3737,
    longitude: -153.89,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 88,
      siteName: 'Lake Clark Pass West',
      siteIdentifier: 'QLW',
      latitude: 60.3737,
      longitude: -153.89,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10489,
    latitude: 61.1863,
    longitude: -153.3261,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 89,
      siteName: 'Merrill Pass High',
      siteIdentifier: 'ERWA',
      latitude: 61.1863,
      longitude: -153.3261,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10491,
    latitude: 60.8158,
    longitude: -151.78833,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 246,
      siteName: 'Trading Bay',
      siteIdentifier: '5AK0',
      latitude: 60.8158,
      longitude: -151.78833,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10492,
    latitude: 62.2922,
    longitude: -153.3733,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 79,
      siteName: 'Rohn',
      siteIdentifier: 'QPS',
      latitude: 62.2922,
      longitude: -153.3733,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10493,
    latitude: 60.7636,
    longitude: -152.4119,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 68,
      siteName: 'Lake Clark Pass East',
      siteIdentifier: 'QLE',
      latitude: 60.7636,
      longitude: -152.4119,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10495,
    latitude: 61.2,
    longitude: -153.2978,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 90,
      siteName: 'Merrill Pass Low',
      siteIdentifier: 'ERW',
      latitude: 61.2,
      longitude: -153.2978,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10498,
    latitude: 59.75539,
    longitude: -151.77345,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 145,
      siteName: 'Anchor Point',
      siteIdentifier: 'AK00',
      latitude: 59.75539,
      longitude: -151.77345,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10530,
    latitude: 59.78858,
    longitude: -154.10086,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 149,
      siteName: 'Pedro Bay',
      siteIdentifier: '4K0',
      latitude: 59.78858,
      longitude: -154.10086,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10488,
    latitude: 60.855534,
    longitude: -152.6392,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 32,
      siteName: 'Lake Clark Pass RCO',
      siteIdentifier: 'QLEA',
      latitude: 60.855534,
      longitude: -152.6392,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10411,
    latitude: 61.785366,
    longitude: -147.66599,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 441,
      siteName: 'Sheep Mountain',
      siteIdentifier: 'SMU',
      latitude: 61.785366,
      longitude: -147.66599,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10427,
    latitude: 60.48182,
    longitude: -149.7266,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 220,
      siteName: 'Cooper Landing',
      siteIdentifier: 'JLA',
      latitude: 60.48182,
      longitude: -149.7266,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10466,
    latitude: 60.48643,
    longitude: -149.36583,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 34,
      siteName: 'Moose Pass',
      siteIdentifier: '52Z',
      latitude: 60.48643,
      longitude: -149.36583,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10413,
    latitude: 60.64608,
    longitude: -147.34705,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 797,
      siteName: 'Naked Island',
      latitude: 60.64608,
      longitude: -147.34705,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10371,
    latitude: 62.9029,
    longitude: -143.66928,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 155,
      siteName: 'Mentasta',
      siteIdentifier: 'QME',
      latitude: 62.9029,
      longitude: -143.66928,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10377,
    latitude: 63.32045,
    longitude: -142.7965,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 146,
      siteName: 'Tok',
      siteIdentifier: '6K8',
      latitude: 63.32045,
      longitude: -142.7965,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10379,
    latitude: 61.58326,
    longitude: -144.4334,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 243,
      siteName: 'Chitina',
      siteIdentifier: 'CXC',
      latitude: 61.58326,
      longitude: -144.4334,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10386,
    latitude: 62.10396,
    longitude: -146.17451,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 270,
      siteName: 'Tazlina-Tolsona',
      siteIdentifier: '5AK',
      latitude: 62.10396,
      longitude: -146.17451,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10387,
    latitude: 62.59464,
    longitude: -144.6491,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 218,
      siteName: 'Chistochina',
      siteIdentifier: 'CZO',
      latitude: 62.59464,
      longitude: -144.6491,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10388,
    latitude: 61.12895,
    longitude: -145.77502,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 131,
      siteName: 'Thompson Pass',
      siteIdentifier: 'K55',
      latitude: 61.12895,
      longitude: -145.77502,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10389,
    latitude: 63.41511,
    longitude: -145.75809,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 37,
      siteName: 'Isabel Pass',
      siteIdentifier: 'Z32',
      latitude: 63.41511,
      longitude: -145.75809,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10390,
    latitude: 60.683308,
    longitude: -144.73912,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 793,
      siteName: 'Million Dollar Bridge',
      latitude: 60.683308,
      longitude: -144.73912,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10392,
    latitude: 64.05655,
    longitude: -145.73236,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 154,
      siteName: 'Delta Junction',
      siteIdentifier: 'BIG',
      latitude: 64.05655,
      longitude: -145.73236,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10395,
    latitude: 65.47268,
    longitude: -148.66362,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 139,
      siteName: 'Livengood',
      siteIdentifier: '4AK',
      latitude: 65.47268,
      longitude: -148.66362,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10396,
    latitude: 64.87586,
    longitude: -148.06789,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 152,
      siteName: 'Ester Dome',
      siteIdentifier: 'FAI',
      latitude: 64.87586,
      longitude: -148.06789,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10397,
    latitude: 61.80725,
    longitude: -148.33257,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 433,
      siteName: 'Chickaloon',
      siteIdentifier: '48AK',
      latitude: 61.80725,
      longitude: -148.33257,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10412,
    latitude: 61.832867,
    longitude: -147.32748,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 410,
      siteName: 'Tahneta Pass',
      siteIdentifier: 'HNE',
      latitude: 61.832867,
      longitude: -147.32748,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10457,
    latitude: 63.43137,
    longitude: -150.31076,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 185,
      siteName: 'McKinley North',
      siteIdentifier: '5Z5',
      latitude: 63.43137,
      longitude: -150.31076,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10458,
    latitude: 63.3284,
    longitude: -149.13295,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 47,
      siteName: 'Summit',
      siteIdentifier: 'UMM',
      latitude: 63.3284,
      longitude: -149.13295,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10461,
    latitude: 65.93998,
    longitude: -149.85248,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 118,
      siteName: 'Yukon River Bridge',
      siteIdentifier: 'QAE',
      latitude: 65.93998,
      longitude: -149.85248,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10462,
    latitude: 65.148605,
    longitude: -149.35468,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 144,
      siteName: 'Minto',
      siteIdentifier: '51Z',
      latitude: 65.148605,
      longitude: -149.35468,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10473,
    latitude: 62.40583,
    longitude: -150.26204,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 150,
      siteName: 'McKinley South',
      siteIdentifier: '3AK3',
      latitude: 62.40583,
      longitude: -150.26204,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10476,
    latitude: 63.09738,
    longitude: -149.50252,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 147,
      siteName: 'Honolulu',
      siteIdentifier: 'QA9',
      latitude: 63.09738,
      longitude: -149.50252,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10378,
    latitude: 63.49874,
    longitude: -145.85045,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 57,
      siteName: 'Black Rapids',
      siteIdentifier: '5BK',
      latitude: 63.49874,
      longitude: -145.85045,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10391,
    latitude: 63.64864,
    longitude: -144.06245,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 260,
      siteName: 'Knob Ridge',
      siteIdentifier: 'CSJ',
      latitude: 63.64864,
      longitude: -144.06245,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2451,
    latitude: 60.54111,
    longitude: -165.08694,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 435,
      siteName: 'Toksook Bay',
      siteIdentifier: 'OOK',
      latitude: 60.536713,
      longitude: -165.0891,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2452,
    latitude: 62.96111,
    longitude: -141.92805,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 446,
      siteName: 'Northway',
      siteIdentifier: 'ORT',
      latitude: 62.96176,
      longitude: -141.93591,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2359,
    latitude: 60.57306,
    longitude: -151.24472,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 447,
      siteName: 'Kenai',
      siteIdentifier: 'ENA',
      latitude: 60.57007,
      longitude: -151.24005,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10358,
    latitude: 60.97839,
    longitude: -160.0021,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 206,
      siteName: 'Nyac',
      siteIdentifier: 'ZNC',
      latitude: 60.97839,
      longitude: -160.0021,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10403,
    latitude: 59.984016,
    longitude: -147.90913,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 794,
      siteName: 'Latouche Peak',
      latitude: 59.984016,
      longitude: -147.90913,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10404,
    latitude: 60.817623,
    longitude: -148.37958,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 798,
      siteName: 'Pigot',
      latitude: 60.817623,
      longitude: -148.37958,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10416,
    latitude: 59.1189,
    longitude: -161.5887,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 249,
      siteName: 'Goodnews Bay',
      siteIdentifier: 'GNU',
      latitude: 59.1189,
      longitude: -161.5887,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10481,
    latitude: 57.75756,
    longitude: -153.35097,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 176,
      siteName: 'Uganik Bay',
      siteIdentifier: 'WSJ',
      latitude: 57.75756,
      longitude: -153.35097,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10490,
    latitude: 57.88388,
    longitude: -152.85143,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 233,
      siteName: 'Port Lions',
      siteIdentifier: 'ORI',
      latitude: 57.88388,
      longitude: -152.85143,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10467,
    latitude: 61.42658,
    longitude: -150.07887,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 107,
      siteName: 'Knik',
      siteIdentifier: 'Z40',
      latitude: 61.42658,
      longitude: -150.07887,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10531,
    latitude: 61.35489,
    longitude: -155.43573,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 236,
      siteName: 'Lime Village',
      siteIdentifier: '2AK',
      latitude: 61.35489,
      longitude: -155.43573,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10547,
    latitude: 56.25505,
    longitude: -158.76698,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 174,
      siteName: 'Chignik Lake',
      siteIdentifier: 'A79',
      latitude: 56.25505,
      longitude: -158.76698,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10551,
    latitude: 56.9231,
    longitude: -158.66237,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 175,
      siteName: 'Port Heiden',
      siteIdentifier: 'PTH',
      latitude: 56.9231,
      longitude: -158.66237,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10552,
    latitude: 56.31009,
    longitude: -158.53906,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 204,
      siteName: 'Chignik Lagoon',
      siteIdentifier: 'KCL',
      latitude: 56.31009,
      longitude: -158.53906,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10509,
    latitude: 57.94138,
    longitude: -152.47227,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 210,
      siteName: 'Ouzinkie',
      siteIdentifier: '4K5',
      latitude: 57.94138,
      longitude: -152.47227,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10540,
    latitude: 57.95757,
    longitude: -136.22675,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 172,
      siteName: 'Pelican',
      siteIdentifier: 'PEC',
      latitude: 57.95757,
      longitude: -136.22675,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10546,
    latitude: 58.39007,
    longitude: -135.72972,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 232,
      siteName: 'Gustavus Dock',
      siteIdentifier: 'GSTA',
      latitude: 58.39007,
      longitude: -135.72972,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10541,
    latitude: 59.22222,
    longitude: -135.42027,
    timeZoneId: 'America/Juneau',
    data: {
      siteId: 419,
      siteName: 'Haines',
      siteIdentifier: 'HNS',
      latitude: 59.22222,
      longitude: -135.42027,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2502,
    latitude: 61.13417,
    longitude: -146.24472,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 411,
      siteName: 'Valdez',
      siteIdentifier: 'VDZ',
      latitude: 61.13238,
      longitude: -146.2506,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2373,
    latitude: 62.15417,
    longitude: -145.45528,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 164,
      siteName: 'Gulkana',
      siteIdentifier: 'GKN',
      latitude: 62.15538,
      longitude: -145.45966,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10428,
    latitude: 60.33987,
    longitude: -162.66667,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 103,
      siteName: 'Tuntutuliak',
      siteIdentifier: 'A61',
      latitude: 60.33987,
      longitude: -162.66667,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10471,
    latitude: 60.938374,
    longitude: -164.6314,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 135,
      siteName: 'Newtok',
      siteIdentifier: 'WWT',
      latitude: 60.938374,
      longitude: -164.6314,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10468,
    latitude: 60.38655,
    longitude: -166.1913,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 200,
      siteName: 'Mekoryuk',
      siteIdentifier: 'MYU',
      latitude: 60.38655,
      longitude: -166.1913,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10433,
    latitude: 53.91892,
    longitude: -166.50912,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 254,
      siteName: 'Dutch Ballyhoo',
      siteIdentifier: 'DUTB',
      latitude: 53.91892,
      longitude: -166.50912,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10463,
    latitude: 53.8757,
    longitude: -166.5421,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 255,
      siteName: 'Dutch Haystack',
      siteIdentifier: 'DUTA',
      latitude: 53.8757,
      longitude: -166.5421,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10544,
    latitude: 59.7167,
    longitude: -135.0457,
    timeZoneId: 'America/Vancouver',
    data: {
      siteId: 423,
      siteName: 'Fraser Camp',
      siteIdentifier: '',
      latitude: 59.7167,
      longitude: -135.0457,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2488,
    latitude: 65.24028,
    longitude: -166.33917,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 209,
      siteName: 'Teller',
      siteIdentifier: 'TER',
      latitude: 65.24219,
      longitude: -166.33224,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2344,
    latitude: 66.06889,
    longitude: -162.76694,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 134,
      siteName: 'Deering',
      siteIdentifier: 'DEE',
      latitude: 66.07674,
      longitude: -162.72931,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2473,
    latitude: 66.24944,
    longitude: -166.08917,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 194,
      siteName: 'Shishmaref',
      siteIdentifier: 'SHH',
      latitude: 66.25428,
      longitude: -166.07458,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2338,
    latitude: 61.84444,
    longitude: -165.57361,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 438,
      siteName: 'Scammon Bay',
      siteIdentifier: 'SCM',
      latitude: 61.84459,
      longitude: -165.58072,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2509,
    latitude: 64.68917,
    longitude: -163.4125,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 215,
      siteName: 'White Mountain',
      siteIdentifier: 'WMO',
      latitude: 64.68564,
      longitude: -163.40727,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2374,
    latitude: 64.55028,
    longitude: -163.00694,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 178,
      siteName: 'Golovin',
      siteIdentifier: 'GLV',
      latitude: 64.5458,
      longitude: -163.0271,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2387,
    latitude: 61.52361,
    longitude: -166.1464,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 151,
      siteName: 'Hooper Bay',
      siteIdentifier: 'HPB',
      latitude: 61.52768,
      longitude: -166.11317,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2521,
    latitude: 64.61472,
    longitude: -162.27028,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 6,
      siteName: 'Elim',
      siteIdentifier: 'ELI',
      latitude: 64.61651,
      longitude: -162.27171,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2524,
    latitude: 63.03056,
    longitude: -163.5325,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 207,
      siteName: 'Kotlik',
      siteIdentifier: '2A9',
      latitude: 63.0355,
      longitude: -163.53223,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2375,
    latitude: 63.76639,
    longitude: -171.73277,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 216,
      siteName: 'Gambell',
      siteIdentifier: 'GAM',
      latitude: 63.77548,
      longitude: -171.73,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2470,
    latitude: 63.68611,
    longitude: -170.49306,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 36,
      siteName: 'Savoonga',
      siteIdentifier: 'SVA',
      latitude: 63.68893,
      longitude: -170.49165,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2499,
    latitude: 54.14444,
    longitude: -165.6039,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 234,
      siteName: 'Akun Island',
      siteIdentifier: '7AK',
      latitude: 54.14695,
      longitude: -165.60516,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2336,
    latitude: 60.13667,
    longitude: -164.27888,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 38,
      siteName: 'Chefornak',
      siteIdentifier: 'CFK',
      latitude: 60.13824,
      longitude: -164.28297,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2353,
    latitude: 60.21361,
    longitude: -162.04388,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 102,
      siteName: 'Eek',
      siteIdentifier: 'EEK',
      latitude: 60.21586,
      longitude: -162.01218,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2371,
    latitude: 59.87556,
    longitude: -163.16806,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 109,
      siteName: 'Kwigillingok',
      siteIdentifier: 'GGV',
      latitude: 59.8701,
      longitude: -163.14832,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2522,
    latitude: 60.87333,
    longitude: -162.52417,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 113,
      siteName: 'Kasigluk',
      siteIdentifier: 'Z09',
      latitude: 60.87275,
      longitude: -162.5109,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2348,
    latitude: 61.86417,
    longitude: -162.02611,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 421,
      siteName: 'Marshall',
      siteIdentifier: 'MDM',
      latitude: 61.867058,
      longitude: -162.03331,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2572,
    latitude: 60.90583,
    longitude: -162.44057,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 239,
      siteName: 'Nunapitchuk',
      siteIdentifier: '16A',
      latitude: 60.90468,
      longitude: -162.44272,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2407,
    latitude: 59.93139,
    longitude: -164.02806,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 422,
      siteName: 'Kipnuk',
      siteIdentifier: 'IIK',
      latitude: 59.9341,
      longitude: -164.03113,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2341,
    latitude: 60.49139,
    longitude: -145.4775,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 156,
      siteName: 'Cordova',
      siteIdentifier: 'CDV',
      latitude: 60.49372,
      longitude: -145.47044,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3295,
    latitude: 60.7895,
    longitude: -137.545,
    timeZoneId: 'America/Whitehorse',
    data: {
      siteId: 288,
      siteName: 'Haines Junction',
      siteIdentifier: 'WHT',
      latitude: 60.789165,
      longitude: -137.54556,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 3362,
    latitude: 62.1108,
    longitude: -136.178,
    timeZoneId: 'America/Whitehorse',
    data: {
      siteId: 287,
      siteName: 'Carmacks',
      siteIdentifier: 'XCK',
      latitude: 62.110832,
      longitude: -136.17833,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2799,
    latitude: 61.3707,
    longitude: -139.04,
    timeZoneId: 'America/Whitehorse',
    data: {
      siteId: 404,
      siteName: 'Burwash',
      siteIdentifier: '',
      latitude: 61.36965,
      longitude: -139.03152,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2490,
    latitude: 62.32111,
    longitude: -150.0925,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 160,
      siteName: 'Talkeetna',
      siteIdentifier: 'TKA',
      latitude: 62.31906,
      longitude: -150.0977,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2349,
    latitude: 57.74972,
    longitude: -152.4939,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 417,
      siteName: 'Kodiak',
      siteIdentifier: 'ADQ',
      latitude: 57.74712,
      longitude: -152.4926,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2386,
    latitude: 59.64472,
    longitude: -151.48557,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 100,
      siteName: 'Homer',
      siteIdentifier: 'HOM',
      latitude: 59.647587,
      longitude: -151.52881,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2428,
    latitude: 59.44972,
    longitude: -146.30722,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 442,
      siteName: 'Middleton Island',
      siteIdentifier: 'MDO',
      latitude: 59.45,
      longitude: -146.3088,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2745,
    latitude: 68.2233,
    longitude: -135.006,
    timeZoneId: 'America/Yellowknife',
    data: {
      siteId: 505,
      siteName: 'Aklavik / Freddie Carmichael',
      siteIdentifier: 'CYKD',
      latitude: 68.223335,
      longitude: -135.00555,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: true,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2326,
    latitude: 71.28472,
    longitude: -156.76833,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 179,
      siteName: 'Barrow (Utqiagvik)',
      siteIdentifier: 'BRW',
      latitude: 71.2876,
      longitude: -156.78563,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2327,
    latitude: 66.91389,
    longitude: -151.52888,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 262,
      siteName: 'Bettles',
      siteIdentifier: 'BTT',
      latitude: 66.91706,
      longitude: -151.5159,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2342,
    latitude: 67.25194,
    longitude: -150.20389,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 208,
      siteName: 'Coldfoot',
      siteIdentifier: 'CXF',
      latitude: 67.25585,
      longitude: -150.19415,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2345,
    latitude: 68.03194,
    longitude: -162.89917,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 169,
      siteName: 'Red Dog',
      siteIdentifier: 'DGG',
      latitude: 68.0286,
      longitude: -162.9118,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2366,
    latitude: 67.10611,
    longitude: -157.85693,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 183,
      siteName: 'Ambler',
      siteIdentifier: 'AFM',
      latitude: 67.08655,
      longitude: -157.85725,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2372,
    latitude: 66.88806,
    longitude: -157.16222,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 193,
      siteName: 'Shungnak',
      siteIdentifier: 'SHG',
      latitude: 66.88935,
      longitude: -157.13838,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2394,
    latitude: 66.97611,
    longitude: -160.43832,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 129,
      siteName: 'Kiana',
      siteIdentifier: 'IAN',
      latitude: 66.973495,
      longitude: -160.42932,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2412,
    latitude: 68.13361,
    longitude: -151.74333,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 14,
      siteName: 'Anaktuvuk Pass',
      siteIdentifier: 'AKP',
      latitude: 68.14132,
      longitude: -151.73158,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2453,
    latitude: 66.88472,
    longitude: -162.59805,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 217,
      siteName: 'Kotzebue',
      siteIdentifier: 'OTZ',
      latitude: 66.89146,
      longitude: -162.60616,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2463,
    latitude: 68.34861,
    longitude: -166.79916,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 198,
      siteName: 'Point Hope',
      siteIdentifier: 'PHO',
      latitude: 68.34643,
      longitude: -166.72859,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2466,
    latitude: 70.20972,
    longitude: -151.0064,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 205,
      siteName: 'Nuiqsut',
      siteIdentifier: 'AQT',
      latitude: 70.21358,
      longitude: -151.0012,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2467,
    latitude: 68.11444,
    longitude: -145.57916,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 268,
      siteName: 'Arctic Village',
      siteIdentifier: 'ARC',
      latitude: 68.1183,
      longitude: -145.566,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2471,
    latitude: 70.19472,
    longitude: -148.465,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 13,
      siteName: 'Deadhorse',
      siteIdentifier: 'SCC',
      latitude: 70.197655,
      longitude: -148.47525,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2475,
    latitude: 66.6,
    longitude: -159.98557,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 165,
      siteName: 'Selawik',
      siteIdentifier: 'WLK',
      latitude: 66.60298,
      longitude: -160.00194,
      siteInMaintenance: true,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2493,
    latitude: 70.46694,
    longitude: -157.43556,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 58,
      siteName: 'Atqasuk',
      siteIdentifier: 'ATK',
      latitude: 70.469826,
      longitude: -157.4305,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2504,
    latitude: 67.73611,
    longitude: -164.56332,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 167,
      siteName: 'Kivalina',
      siteIdentifier: 'KVL',
      latitude: 67.7275,
      longitude: -164.54037,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2508,
    latitude: 70.63778,
    longitude: -159.99472,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 195,
      siteName: 'Wainwright',
      siteIdentifier: 'AWI',
      latitude: 70.63618,
      longitude: -160.0307,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2510,
    latitude: 67.56111,
    longitude: -162.98029,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 168,
      siteName: 'Noatak',
      siteIdentifier: 'WTK',
      latitude: 67.57174,
      longitude: -162.97148,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2537,
    latitude: 66.57222,
    longitude: -145.24611,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 75,
      siteName: 'Fort Yukon',
      siteIdentifier: 'FYU',
      latitude: 66.5738,
      longitude: -145.2148,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2573,
    latitude: 69.73278,
    longitude: -163.00528,
    timeZoneId: 'America/Nome',
    data: {
      siteId: 33,
      siteName: 'Point Lay',
      siteIdentifier: 'PIZ',
      latitude: 69.73539,
      longitude: -163.00258,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2574,
    latitude: 70.41389,
    longitude: -150.01833,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 199,
      siteName: 'North Slope',
      siteIdentifier: 'AA27',
      latitude: 70.41344,
      longitude: -150.01414,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2334,
    latitude: 66.645,
    longitude: -143.73972,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 181,
      siteName: 'Chalkyitsik',
      siteIdentifier: 'CIK',
      latitude: 66.64946,
      longitude: -143.72743,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 2517,
    latitude: 66.55167,
    longitude: -152.62193,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 228,
      siteName: 'Allakaket',
      siteIdentifier: '6A8',
      latitude: 66.54942,
      longitude: -152.62965,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
  {
    locationId: 10603,
    latitude: 68.0765,
    longitude: -149.5858,
    timeZoneId: 'America/Anchorage',
    data: {
      siteId: 85,
      siteName: 'Chandalar Shelf',
      siteIdentifier: '5CD',
      latitude: 68.0765,
      longitude: -149.5858,
      siteInMaintenance: false,
      siteActive: true,
      thirdParty: false,
      type: 'cameraSite',
      camerasInMaintenance: false,
      hasPanorama: false,
    },
  },
];
