var geo_options = {
  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
};

var GPS_LOG_DATA = [];
var GPS_LOG_LEGS = [];
var GPS_FLIGHT;
var GPS_FLIGHT_ODOMETER = 0;
var GPS_LEG_ODOMETER = 0;
var GPS_LOGGER_POINTER = null;

//not moving threashold = Meters / second
var NotMoving = 0.1;

function EngineStart() {
  $('#EngineStart').hide();
  $('#EngineStop').show();
  $('#CompleteLeg').prop('disabled', true);
  GPS_FLIGHT = new NEW_FLIGHT();
  GPS_LOGGER_POINTER = navigator.geolocation.watchPosition(
    GpsLogUpdate,
    function () {
      console.log('geolocation error');
    },
    geo_options
  );
  var StartTime = new Date();
  if ($('#LegStart2').val() == 0 || $('#LegStart2').val() == '') {
    SetTime_id(StartTime, 'LegStart2');
  }
  $('#NewLegControlSVG').css('background-color', 'ff0000');
  UpdateWB(true); //save existing changes;
  $('#GPS_HOBBS_STATS').show();
}
function NEW_FLIGHT() {
  this.start_timestamp = 0;
  this.end_timestamp = 0;
  this.stops = 0;
  this.legStops = 0;
  this.Duration = 0;
  this.Itin_PK = Follow_Data[FOLLOW_INDEX].Itin.PrimaryKey;
  this.LEGS = [];
  this.GPS_LOG = [];
}

function END_FLIGHT() {
  GPS_FLIGHT.end_timestamp = GPS_LOG_DATA[0].timestamp;
  CompleteLeg(true);
  GPS_FLIGHT.LEGS = COPY_OBJECT(GPS_LOG_LEGS);
  navigator.geolocation.clearWatch(GPS_LOGGER_POINTER);
  GPS_LOGGER_POINTER = null;
  $('#EngineStart').show();
  $('#EngineStop').hide();

  GPS_LOG_DATA = [];
  GPS_LOG_LEGS = [];
  GPS_FLIGHT_ODOMETER = 0;
  GPS_LEG_ODOMETER = 0;
  //stringify and store GPS_FLIGHT Log
  localStorageDB.getItem('GPS_FLIGHTS', AddLastFlight);
  $('#NewLegControlSVG').css('background-color', '00ff00');
  $('#GPS_HOBBS_STATS').hide();
}
var GPS_FLIGHTS = [];
function AddLastFlight(e) {
  GPS_FLIGHTS = e;
  if (GPS_FLIGHTS == null && GPS_FLIGHTS !== false) {
    GPS_FLIGHTS = [];
    GPS_FLIGHTS.push(GPS_FLIGHT);
  } else {
    //GPS_FLIGHTS = JSON.parse(GPS_FLIGHTS); //update existing stored data
    GPS_FLIGHTS = []; //THIS IS UNTIL LEG TOOL IS FULLY IMPLEMENTED SO WE DON'T KEEP RACKING UP STORED DATA
    GPS_FLIGHTS.push(GPS_FLIGHT);
  }
  console.log(GPS_FLIGHTS);
  GPS_FLIGHTS = JSON.stringify(GPS_FLIGHTS);
  localStorageDB.setItem('GPS_FLIGHTS', GPS_FLIGHTS);
  CurrentAirtimeStats = {
    lastIndex: 0,
    lastTotal: 0,
    lastTimestamp: 0,
  };
}
function COPY_OBJECT(data) {
  return JSON.parse(JSON.stringify(data));
}
function GPS_ITEM(coords, timestamp) {
  this.accuracy = coords.accuracy;
  this.altitudeAccuracy = coords.altitudeAccuracy;
  this.latitude = coords.latitude;
  this.longitude = coords.longitude;
  if (isNaN(coords.heading)) this.heading = 0;
  else this.heading = coords.heading;
  this.heading = coords.heading;
  this.altitude = coords.altitude;
  if (isNaN(coords.speed)) this.speed = 0;
  else this.speed = coords.speed;
  this.timestamp = timestamp;
}

function GPS_Keep(Item) {
  if (GPS_LOG_DATA.length > 0) {
    var Last = GPS_LOG_DATA[GPS_LOG_DATA.length - 1];
    var Distance = GPS_CheckDistance(Last, Item);
    document.getElementById('DistanceLast').innerHTML = Distance;
    //If you were moving and now you're not log it
    if (Last.speed != null && Last.speed > NotMoving && Item.speed != null && Item.speed < NotMoving) {
      GPS_FLIGHT_ODOMETER += Distance;
      GPS_LEG_ODOMETER += Distance;
      document.getElementById('LegOdometer').innerHTML = GPS_LEG_ODOMETER;
      document.getElementById('FlightOdometer').innerHTML = GPS_FLIGHT_ODOMETER;
      document.getElementById('StopDistanceLast').innerHTML = Distance;
      GPS_FLIGHT.stops++;
      GPS_FLIGHT.legStops++;
      document.getElementById('Stops').innerHTML = GPS_FLIGHT.stops;

      //Here is a good spot to automatically popup the leg tool with down time and ask for confirmation to end current leg.
      //perhaps check if we're already in the leg control page before triggering click action
      var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
      if ($('#AutoShowLegInputOPTION').is(':checked')) {
        if (page != 'WB_Page2') $('#NewLegControl').click();
        var DownTime = new Date();
        setTimeout(function () {
          SetTime_id(DownTime, 'LegEnd2');
        }, 3000);
      }
      if (GPS_LOG_DATA.length > 3) document.getElementById('CompleteLeg').disabled = false;
      document.getElementById('EngineStop').disabled = false;
      //Do not automatically update leg downtime and close if we start moving again before this

      return true;
    }
    //If you're moving take a closer look
    if (Item.speed != null && Item.speed >= NotMoving) {
      //if you've moved over the accuracy of your reading since the last position log it
      if (Distance > Item.accuracy && Distance > Last.accuracy) {
        GPS_FLIGHT_ODOMETER += Distance;
        GPS_LEG_ODOMETER += Distance;
        document.getElementById('LegOdometer').innerHTML = GPS_LEG_ODOMETER;
        document.getElementById('FlightOdometer').innerHTML = GPS_FLIGHT_ODOMETER;
        document.getElementById('CompleteLeg').disabled = true;
        document.getElementById('EngineStop').disabled = true;
        //If previous leg in tool was updated mark this as the uptime for the next leg
        var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
        if ($('#AutoShowLegInputOPTION').is(':checked') && page == 'WB_Page2' && Last.speed <= NotMoving) {
          //if you were stopped before and are now moving automatically close the leg page
          UpdateWB(true); //save existing changes;
          $('#exitWBPage2').unbind('click', exitOfflineWBmapb);
          $.mobile.changePage($('#Map_Page'), {
            reverse: true,
            changeHash: false,
          });
          INIT_MAPB();
        }
        return true;
      } else {
        if (GPS_LOG_DATA.length > 3) document.getElementById('CompleteLeg').disabled = false;
        document.getElementById('EngineStop').disabled = false;
        return false;
      }
    } else return false;
  } else {
    //If there is no data in the logger log it
    GPS_LEG_ODOMETER = 0;
    document.getElementById('LegOdometer').innerHTML = GPS_LEG_ODOMETER;
    //var Distance = GPS_CheckDistance(Item,{latitude:55,longitude:37});
    document.getElementById('DistanceLast').innerHTML = 0;
    return true;
  }
}
function SetTime_id(now, id) {
  var minutes = now.getMinutes();
  if (minutes < 10) minutes = '0' + minutes;
  $('#' + id).val(now.getHours() + '' + minutes);
}
function CompleteLeg(finish) {
  if (GPS_LOG_DATA.length < 3) return; //Not an actual leg if less than 3 gps points
  var start_item = GPS_LOG_DATA[0];
  for (var i in GPS_LOG_DATA) {
    var I = GPS_LOG_DATA[i];
    if (I.speed > NotMoving) {
      start_item = I;
      break;
    }
  }
  var end_item = GPS_LOG_DATA[GPS_LOG_DATA.length - 1];
  var Airtime = GetAirtime(GPS_LOG_DATA);
  var Leg = new GPS_LEG(start_item, end_item, Airtime);
  GPS_LOG_LEGS.push(Leg);
  GPS_LOG_DATA = [];
  GPS_FLIGHT.legStops = 0;
  document.getElementById('StopDistanceLast').innerHTML = GPS_LOG_LEGS.length;
  document.getElementById('CompleteLeg').disabled = true;
  var EndTime = new Date();
  if ($('#LegEnd2').val() == 0 || $('#LegEnd2').val() == '') {
    SetTime_id(EndTime, 'LegEnd2');
  }
  if (!finish) {
    WB_Leg_Next();
    setTimeout(function () {
      if ($('#LegStart2').val() == 0 || $('#LegStart2').val() == '') {
        SetTime_id(EndTime, 'LegStart2');
      }
    }, 2000);
  } else {
    UpdateWB(true); //save existing changes;
  }
}
function GPS_LEG(start_Item, end_Item, Airtime) {
  this.start_Item_latitude = start_Item.latitude;
  this.start_Item_longitude = start_Item.longitude;
  this.start_Item_heading = start_Item.heading;
  this.start_Item_altitude = start_Item.altitude;
  this.start_Item_speed = start_Item.speed;
  this.start_Item_timestamp = start_Item.timestamp;

  this.end_Item_latitude = end_Item.latitude;
  this.end_Item_ = end_Item.longitude;
  this.end_Item_ = end_Item.heading;
  this.end_Item_ = start_Item.altitude;
  this.end_Item_ = end_Item.speed;
  this.end_Item_timestamp = end_Item.timestamp;

  this.Distance = GPS_CheckDistance(start_Item, end_Item); //Distance direct from first and last points
  this.Odometer = GPS_LEG_ODOMETER; //distance of entire flight path
  this.Duration = ToTime(end_Item - start_Item); //This will be the Flight time tracker
  this.GPS_LOG = COPY_OBJECT(GPS_LOG_DATA);
  this.stops = GPS_FLIGHT.legStops;
  this.Airtime = Airtime;
}
function GPS_CheckDistance(coord1, coord2) {
  var point1 = turf.point([coord1.longitude, coord1.latitude]);
  var point2 = turf.point([coord2.longitude, coord2.latitude]);
  var distance = turf.distance(point1, point2, { units: 'kilometers' });
  //console.log(distance);
  distance = Math.round(distance * 10000) / 10;
  return distance;
}

function GpsLogUpdate(e) {
  console.log(e);
  var Item = new GPS_ITEM(e.coords, e.timestamp);
  if (GPS_FLIGHT.start_timestamp == 0) GPS_FLIGHT.start_timestamp = e.timestamp;
  if (GPS_Keep(Item)) GPS_LOG_DATA.push(Item);
  GpsCurrentTimes(e.timestamp); //update current times for display later
  debugLog(e);
}
function GpsCurrentTimes(now) {
  var TotalAirTime = 0;
  var LegTime = 0;

  document.getElementById('Legs').innerHTML = parseInt(GPS_LOG_LEGS.length) + 1;
  for (var l in GPS_LOG_LEGS) {
    var L = GPS_LOG_LEGS[l];
    TotalAirTime += L.Airtime;
  }
  var start_item = GPS_LOG_DATA[0];
  var PrevTimestamp = 0;

  TotalAirTime += GetAirtime(GPS_LOG_DATA);

  LegTime = now - start_item.timestamp;
  document.getElementById('Leg_Time').innerHTML = ToTime(LegTime);

  var start = GPS_FLIGHT.start_timestamp;
  var TotalEngineTime = ToTime(now - start); //This will be the Flight time tracker

  document.getElementById('Engine_Time').innerHTML = TotalEngineTime;
  document.getElementById('Air_Time').innerHTML = ToTime(TotalAirTime);

  document.getElementById('LegStops').innerHTML = GPS_FLIGHT.legStops;
}
var CurrentAirtimeStats = {
  lastIndex: 0,
  lastTotal: 0,
  lastTimestamp: 0,
};
function GetAirtime(data) {
  CurrentAirtimeStats.lastTotal = 0;
  CurrentAirtimeStats.lastIndex = 0;
  CurrentAirtimeStats.lastTimestamp = 0;
  if (data.length > 3) {
    for (var i = CurrentAirtimeStats.lastIndex; i < data.length; i++) {
      var I = data[i];
      if (I.speed > NotMoving) {
        if (CurrentAirtimeStats.lastTimestamp != 0)
          CurrentAirtimeStats.lastTotal += I.timestamp - CurrentAirtimeStats.lastTimestamp;
        CurrentAirtimeStats.lastTimestamp = I.timestamp;
      } else {
        CurrentAirtimeStats.lastTimestamp = 0;
      }
    }
    return CurrentAirtimeStats.lastTotal;
  } else {
    return 0;
  }
}
function debugLog(e) {
  console.log(GPS_LOG_DATA);
  var Count = GPS_LOG_DATA.length;
  var c = e.coords;
  document.getElementById('GPS_Items').innerHTML = Count;
  document.getElementById('Accuracy').innerHTML = Math.round(c.accuracy * 10) / 10;
  document.getElementById('Lat').innerHTML = c.latitude;
  document.getElementById('Long').innerHTML = c.longitude;
  document.getElementById('Heading').innerHTML = c.heading;
  document.getElementById('Speed').innerHTML = Math.round(c.speed * 100) / 100;
  document.getElementById('Altitude').innerHTML = Math.round(c.altitude * 10) / 10;
}
function ToTime(e) {
  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var distance = e;
  var days = Math.floor(distance / _day);
  var hours = Math.floor((distance % _day) / _hour);
  var minutes = Math.floor((distance % _hour) / _minute);
  var seconds = Math.floor((distance % _minute) / _second);
  var time = '';
  if (days != 0) time += days + 'd ';

  time += hours + ':';

  if (minutes < 10) minutes = '0' + minutes;
  time += minutes + ':';

  if (seconds < 10) seconds = '0' + seconds;
  time += seconds;
  return time;
}
