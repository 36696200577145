// This is the first JS file that is included
window.openDatabases = {};

/**
 * Checks if the given parameter is 'empty' the Laravellian sense of the word.
 * I.e. null, string or array with length 0, Object without any keys
 * @param _anything
 * @return {boolean}
 */
function blank(_anything) {
  if (_anything == null) {
    return true;
  }

  if (typeof _anything === 'string' || Array.isArray(_anything)) {
    return _anything.length <= 0;
  }

  if (typeof _anything === 'object') {
    return Object.keys(_anything).length <= 0;
  }

  return false;
}

/**
 * Breaks an array into chunks of the given size
 * @template T
 * @param {T[]} array
 * @param {number} chunkSize
 * @returns {T[][]}
 */
function chunkArray(array, chunkSize) {
  if (chunkSize <= 0) {
    chunkSize = 1;
  }

  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

/**
 * Selects the input content.
 * Add to onFocus.
 * @param {HTMLInputElement} input
 */
function selectInputContent(input) {
  input.select();
  if (input.hasOwnProperty('setSelectionRange')) {
    input.setSelectionRange(0, 9999);
  }
}

/**
 * Interactively clamps the value of a numeric text input to the values of its min and max attributes
 * Add to onChange
 * @param {HTMLInputElement} input
 */
function clampInput(input) {
  const value = +input.value;
  input.value = Math.max(Math.min(+input.max, value), +input.min);
  input.dispatchEvent(new Event('input', { bubbles: true, cancelable: true }));
}

function isPageActive(pageId) {
  return $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id === pageId;
}

/**
 * Change to a page in legacy
 * @param {string} id The element ID of the page container
 */
function goToPage(id) {
  $.mobile.changePage($(id), { changeHash: false });
}

/**
 * Shows or hides the splash screen (if present)
 * @param {boolean} show
 */
function splashScreen(show) {
  if (!navigator.hasOwnProperty('splashscreen') || navigator.splashscreen == null) {
    return;
  }

  if (show) {
    navigator.splashscreen.show();
    return;
  }

  navigator.splashscreen.hide();
}

/**
 * Restarts Cirro
 */
function restartCirro() {
  splashScreen(true);
  window.location.replace(APPFILE);
}

/**
 * Toggle whether the mobile keyboard should be shrinking the view
 * @param {boolean} shrink
 */
function kbdShrinkView(shrink) {
  verbose.log('Vue', 'Keyboard should shrink view ->', shrink);

  if (Keyboard == null || typeof Keyboard.shrinkView !== 'function') {
    return;
  }

  Keyboard.shrinkView(shrink);
}

/**
 * Switches between home/back icons in the 'back' button.
 * @param {jQuery} btn The button wrapper
 * @param {boolean} home Going home?
 */
function toggleBackButton(btn, home) {
  const backDiv = btn.find('.btn-back');
  const homeDiv = btn.find('.btn-home');

  if (home) {
    backDiv.hide();
    homeDiv.show();
    return;
  }

  backDiv.show();
  homeDiv.hide();
}

/**
 *
 * @param {string} url
 */
function openExternal(url) {
  if (!CORDOVA) {
    window.open(url, '_blank');
    return;
  }

  if (ANDROID) {
    navigator.app.loadUrl(url, { openExternal: true });
    return;
  }

  SafariViewController.show({ url, tintColor: '#00bcd4', barColor: '#3a3e5b', controlTintColor: '#ececec' });
}

/**
 * @param {string} param
 * @param {*} fallback
 * @returns {string|*}
 */
function getQueryParam(param, fallback = null) {
  const params = new URLSearchParams(document.location.search);
  return params.has(param) ? params.get(param) : fallback;
}

/**
 * Checks userAgentData.brands for google chrome
 * navigator.userAgentData has no support on FireFox and Safari
 * @returns {boolean}
 */

function isThisChrome() {
  if (navigator.userAgentData == null) {
    return false;
  }
  let brands = navigator.userAgentData.brands;
  return brands.some((element) => element.brand === 'Google Chrome');
}

/**
 *
 */

function displayAlert() {
  if (typeof CORDOVA !== 'undefined') {
    return;
  }

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const isChrome = isThisChrome();
  if (isMobile) {
    window.alert('Please download the Cirro app from the app store for optimal performance.');
  } else if (!isChrome) {
    window.alert('Unfortunately, only Google Chrome is officially supported by Cirro at this time.');
  }
}

displayAlert();

/**
 * Turn number of bytes into human readable, formatted string
 * @param {number} bytes
 * @param {number} [decimals]
 * @returns {string}
 */
function humanBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 B';
  }
  const k = 1024;
  const dm = decimals || 1;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function safeTrim(anything) {
  if (typeof anything === 'string') {
    return anything.trim();
  }

  return anything;
}
