/**
 * Base class for all objects that can be observed / may observe others
 * @author falko@air-suite.com
 * @copyright (c) 2018 AirSuite, All Rights Reserved.
 */
class iSAMA_AbstractObservable {
  constructor() {
    this.observers = [];
  }

  /**
   * Begin Observation of another view
   * @param {iSAMA_AbstractObservable} _targetView
   * @param {function}             _eventHandler
   * @param {string}             _eventType
   */
  Observe(_targetView, _eventType, _eventHandler) {
    _targetView.AddObserver(this, _eventType, _eventHandler);
  }

  /**
   * Adds an observer to this view
   * @param {iSAMA_AbstractObservable} _observer
   * @param {string}                   _eventType
   * @param {function}                 _eventHandler
   */
  AddObserver(_observer, _eventType, _eventHandler) {
    this.observers.push({
      obs: _observer,
      event: _eventType,
      func: _eventHandler,
    });
  }

  /**
   * Tells the observers of this view that something has changed.
   * @param {string} _eventType
   * @param {*}      [_value]
   */
  UpdateObservers(_eventType, _value) {
    for (let o = 0; o < this.observers.length; o++) {
      let reg = this.observers[o];

      if (reg.event === _eventType) {
        reg.func(_value);
      }
    }
  }
}
