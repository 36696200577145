/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
function exitTDGnewConsignor() {
  $.mobile.changePage($('#TDGAdminConsignor_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function init_TDGAdmin() {
  $('#exitTDGAdminConsignorPage').unbind('click', exitTDGConsignor);
  $('#exitTDGAdminConsignorPage').click(exitTDGConsignor);
  $('#TDGAdminConsignorPage').unbind('click', openConsignorPage);
  $('#TDGAdminConsignorPage').click(openConsignorPage);
  $('#newTDGAdminConsignorPage').unbind('click', newTDGConsignor);
  $('#newTDGAdminConsignorPage').click(newTDGConsignor);
  $('#exitTDGnewConsignorPage').unbind('click', exitTDGnewConsignor);
  $('#exitTDGnewConsignorPage').click(exitTDGnewConsignor);
  $.getJSON(BASEURL + 'menu-Administration-TDG-AJAX.php?action=getGroup', function (json_data) {
    var StaffList = json_data;
    console.log(StaffList);
    TDGStaffList = StaffList;
    getTDGUsers(StaffList);
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
  });
}
var TDG_Consignors;

function openConsignorPage() {
  TDG_Consignors = null;
  $.mobile.changePage($('#TDGAdminConsignor_Page'), {
    reverse: false,
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Administration-TDG-AJAX.php?action=getConsignor', function (json_data) {
    TDG_Consignors = json_data;
    listConsignors();

    //$("#TDGAdminConsignor_Content").html("Display Consignor List");
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Consignor List');
    AJAXFAIL(error);
  });
}

function listConsignors() {
  console.log('listConsignors');
  if (TDG_Consignors.length > 0) {
    var HTML =
      '<div style="width: 100%; overflow: auto"><table id="AdminConsignorList_Table" data-filter="true" class="limit-table first-td-fill wrap">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Company') +
      '</th>' +
      '<th data-priority="1">' +
      iTrans('Name') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Address') +
      '</th>' +
      '<th data-priority="3">' +
      iTrans('Phone') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Action') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var today = new Date();
    for (i in TDG_Consignors) {
      var Item = TDG_Consignors[i];
      HTML +=
        '<tr><td>' +
        Item.Company +
        '</td><td>' +
        Item.Name +
        '</td><td>' +
        Item.Address +
        '</td><td>' +
        Item.Phone +
        "</td><td><div class='vertical-center-container'><button id='TDGCedit-" +
        i +
        "' class='action-btn'><img src='./images/edit.svg' /></button><button id='TDGCdel-" +
        i +
        "' class='action-btn'><img src='./images/delete.svg' /></button></div></td></tr>";
    }
    HTML += '</tbody></table></div>';
    $('#TDGAdminConsignor_Content').html(HTML);
    $('#TDGAdminConsignor_Content').enhanceWithin();
    for (i in TDG_Consignors) {
      $('#TDGCedit-' + i).click(editTDGConsignor);
      $('#TDGCdel-' + i).click(delTDGConsignor);
    }
  } else {
    $('#TDGAdminConsignor_Content').html('<p>' + iTrans('No Consignors') + '</p>');
  }
}

function editTDGConsignor() {
  console.log('Edit Consignor');
  var id = this.id.split('-');
  var index = id[1];
  var PK = TDG_Consignors[index].PrimaryKey;
  $.mobile.changePage($('#TDGnewConsignor_Page'), {
    reverse: false,
    changeHash: false,
  });
  $('#DG_Action').val(PK);
  $('#DG_Company').val(TDG_Consignors[index].Company);
  $('#DG_Name').val(TDG_Consignors[index].Name);
  $('#DG_Address').val(TDG_Consignors[index].Address);
  $('#DG_Phone').val(TDG_Consignors[index].Phone);
}

function newTDGConsignor() {
  $.mobile.changePage($('#TDGnewConsignor_Page'), {
    reverse: false,
    changeHash: false,
  });
  $('#DG_Action').val('New');
  $('#DG_Company').val('');
  $('#DG_Name').val('');
  $('#DG_Address').val('');
  $('#DG_Phone').val('');
}

function saveTDGConsignor() {
  var PK = $('#DG_Action').val();
  var action = 'editConsignor';
  if (PK == 'New') action = 'newConsignor';
  $.getJSON(
    BASEURL +
      'menu-Administration-TDG-AJAX.php?action=' +
      action +
      '&PK=' +
      PK +
      '&Company=' +
      encodeURIComponent($('#DG_Company').val()) +
      '&Name=' +
      encodeURIComponent($('#DG_Name').val()) +
      '&Address=' +
      encodeURIComponent($('#DG_Address').val()) +
      '&Phone=' +
      encodeURIComponent($('#DG_Phone').val()),
    function (json_data) {
      if (json_data[0].ServerResponse == 'Offline') {
        var msg;
        msg.ServerResponse = 'Connection Error';
        ServerResponse(msg, iTrans('Saving consignor') + '');
      } else {
        openConsignorPage();
      }
    }
  ).fail(function (jqXHR, status, error) {
    var msg;
    msg.ServerResponse = 'Connection Error';
    ServerResponse(msg, iTrans('Saving consignor') + '');
  });
}

function delTDGConsignor() {
  console.log('Del Consignor');
  var id = this.id.split('-');
  var PK = TDG_Consignors[id[1]].PrimaryKey;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Delete Consignor'),
    headerClose: false,
    buttonPrompt: iTrans('Consignor will be permanently Deleted! Are you Sure?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Administration-TDG-AJAX.php?action=deleteConsignor&PK=' + PK, function (json_data) {
            if (json_data[0].ServerResponse == 'Offline') {
              var msg;
              msg.ServerResponse = 'Connection Error';
              ServerResponse(msg, iTrans('Deleting Fuel Cache'));
            } else {
              openConsignorPage();
            }
          }).fail(function (jqXHR, status, error) {
            var msg;
            msg.ServerResponse = 'Connection Error';
            ServerResponse(msg, iTrans('Deleting Fuel Cache'));
          });
        },
      },
      [iTrans('CANCEL')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function exitTDGConsignor() {
  $.mobile.changePage($('#TDGAdmin_Page'), {
    reverse: true,
    changeHash: false,
  });
}
var TDGStaffList = [];

function getTDGUsers(StaffList) {
  if (StaffList.length > 0) {
    var StaffListHTML =
      '<table id="StaffList_Table" class="limit-table first-td-fill">' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('User') +
      '</th>' +
      '<th>' +
      iTrans('Trained') +
      '</th>' +
      '<th>' +
      iTrans('Expires') +
      '</th>' +
      '<th>' +
      iTrans('Updated') +
      '</th>' +
      '<th>' +
      iTrans('By') +
      '</th>' +
      '<th>' +
      iTrans('Action') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var today = new Date();
    for (i in StaffList) {
      if (StaffList[i].user_hidden <= 0) {
        var User = StaffList[i];
        var lineClass = '';
        var UserDate = new Date(User.Expires);
        if (User.User_PK == null || UserDate < today) lineClass = 'redRecord';
        var whoupdate = 'Nobody';
        for (a in TDGStaffList) if (TDGStaffList[a].PrimaryKey == User.By) whoupdate = TDGStaffList[a].chatname;
        var time = null;
        if (User.Updated !== null) {
          time = User.Updated.split(' ');
          time = new Date(time[0] + 'T' + time[1] + 'Z');
        }
        StaffListHTML +=
          "<tr class='" +
          lineClass +
          "'><td>" +
          User.chatname +
          '</td><td>' +
          User.Trained +
          '</td><td>' +
          User.Expires +
          '</td><td>' +
          time +
          '</td><td>' +
          whoupdate +
          "</td><td><button id='TDGedit-" +
          i +
          "' class='action-btn'><img src='./images/edit.svg' /></button></td></tr>";
      }
    }
    StaffListHTML += '</tbody></table>';
    $('#TDGAdmin_Content').html(StaffListHTML);
    $('#TDGAdmin_Content').enhanceWithin();
    for (i in StaffList) {
      $('#TDGedit-' + i).click(editTDGUser);
    }
  } else {
    $('#TDGAdmin_Content').html('<p>' + iTrans('No Data Received') + '</p>');
  }
}

function editTDGUser() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  var User = TDGStaffList[index];
  console.log(User);
  $('#exitTDGAdminEditPage').unbind('click', exitTDGUserEdit);
  $('#exitTDGAdminEditPage').click(exitTDGUserEdit);
  $.mobile.changePage($('#TDGAdminEdit_Page'), {
    changeHash: false,
  });
  var whoupdate = iTrans('Unknown');
  for (i in TDGStaffList) if (TDGStaffList[i].PrimaryKey == User.By) whoupdate = TDGStaffList[i].chatname;
  var time = iTrans('Unknown');
  if (User.Updated != null) {
    var time = User.Updated.split(' ');
    time = new Date(time[0] + 'T' + time[1] + 'Z');
  }
  if (User.Trained == undefined) User.Trained = '2000-01-01 00:00:00';
  var EditHTML = "<table class='limit-table'><tr><td><h3><b>" + iTrans('Username') + ': </b>' + User.chatname + '</h3>';
  EditHTML +=
    "<div id='DGE-Updated'>" +
    iTrans('Last Updated') +
    ': ' +
    time +
    ' ' +
    iTrans('By') +
    ': ' +
    whoupdate +
    '</div><br />';
  EditHTML +=
    "<label for='DGE-Trained-" +
    index +
    "'>" +
    iTrans('Training Date') +
    "</label><input type='date' id='DGE-Trained-" +
    index +
    "' value='" +
    User.Trained.substr(0,10) +
    "' />";
  EditHTML +=
    "<label for='DGE-Expires-" +
    index +
    "'>" +
    iTrans('Expiry Date') +
    "</label><input type='date' id='DGE-Expires-" +
    index +
    "' value='" +
    User.Expires +
    "' /></tr></td></table>";
  EditHTML +=
    '<div style="width: 100%; overflow: auto"><table id="TDGCred_Table" class="limit-table">' +
    '<tr>' +
    '<td data-priority="0"><div id="TDG_Admin_Exempt">' +
    iTrans('Exemptions') +
    '</div></td>' +
    '<td data-priority="0"><label for="DGE-Shipper-' +
    index +
    '">' +
    iTrans('Shipper') +
    '</label><input type="checkbox" id="DGE-Shipper-' +
    index +
    '" data-mini="true"/></td>' +
    '<td data-priority="1"><label for="DGE-Acceptance-' +
    index +
    '">' +
    iTrans('Acceptance') +
    '</label><input type="checkbox" id="DGE-Acceptance-' +
    index +
    '" data-mini="true" /></td>' +
    '</tr>';
  if (COUNTRY == 'CANADA') {
    EditHTML +=
      '<tr><td>12.4 ' +
      iTrans('Explosives') +
      ", Class 1.4S</td><td><input type='checkbox' id='DGE-S4-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A4-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.5 ' +
      iTrans('Forbidden Explosives') +
      "</td><td><input type='checkbox' id='DGE-S5-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A5-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.6 ' +
      iTrans('Toxic and Infectious Substances') +
      "</td><td><input type='checkbox' id='DGE-S6-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A6-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.8 ' +
      iTrans('Consumer Commodities') +
      "</td><td><input type='checkbox' id='DGE-S8-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A8-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.9 ' +
      iTrans('Limited Access') +
      "</td><td><input type='checkbox' id='DGE-S9-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A9-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.10 ' +
      iTrans('Private Aircraft') +
      "</td><td><input type='checkbox' id='DGE-S10-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A10-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.11 ' +
      iTrans('Geological Core Samples') +
      "</td><td><input type='checkbox' id='DGE-S11-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A11-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.12 ' +
      iTrans('Aerial Work') +
      "</td><td><input type='checkbox' id='DGE-S12-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A12-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.13 ' +
      iTrans('Measuring Instruments') +
      "</td><td><input type='checkbox' id='DGE-S13-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A13-" +
      index +
      "' data-ajax='false' /></td></tr>";
    EditHTML +=
      '<tr><td>12.14 ' +
      iTrans('Medical Aid') +
      "</td><td><input type='checkbox' id='DGE-S14-" +
      index +
      "' data-ajax='false' /></td><td><input type='checkbox' id='DGE-A14-" +
      index +
      "' data-ajax='false' /></td></tr>";
  }
  EditHTML += '</table></div>';

  if (COUNTRY == 'CANADA') {
    EditHTML +=
      "<label for='DGE-EC-" +
      index +
      "'>" +
      iTrans('Equivalency Certificates') +
      "</label><input type='checkbox' id='DGE-EC-" +
      index +
      "' data-mini='true'/>";
    EditHTML +=
      "<label for='DGE-Loading-" +
      index +
      "'>" +
      iTrans('Loading') +
      "</label><input type='checkbox' id='DGE-Loading-" +
      index +
      "' data-mini='true' />";
    EditHTML +=
      "<label for='DGE-PassengerHandling-" +
      index +
      "'>" +
      iTrans('Passenger Handling') +
      "</label><input type='checkbox' id='DGE-PassengerHandling-" +
      index +
      "' data-mini='true'/>";
    EditHTML +=
      "<label for='DGE-RemoteAcceptance-" +
      index +
      "'>" +
      iTrans('Remote Site Acceptance Process') +
      "</label><input type='checkbox' id='DGE-RemoteAcceptance-" +
      index +
      "' data-mini='true'/>";
    EditHTML +=
      "<label for='DGE-FlightCrewBasic-" +
      index +
      "'>" +
      iTrans('Flight Crew Basic') +
      "</label><input type='checkbox' id='DGE-FlightCrewBasic-" +
      index +
      "' data-mini='true'/>";
    EditHTML +=
      "<label for='DGE-Awareness-" +
      index +
      "'>" +
      iTrans('Awareness') +
      "</label><input type='checkbox' id='DGE-Awareness-" +
      index +
      "' data-mini='true'/>";
  }
  $('#TDGAdminEdit_Content').html(EditHTML);
  if (COUNTRY != 'CANADA') $('#TDG_Admin_Exempt').hide();
  $('#TDGAdminEdit_Content').enhanceWithin();
  //set current options
  //add Listeners
  $('#DGE-Trained-' + index).change(updateTDGUserItem);
  $('#DGE-Expires-' + index).change(updateTDGUserItem);

  if (User.Shipper == 1)
    $('#DGE-Shipper-' + index)
      .attr('checked', true)
      .checkboxradio('refresh');
  $('#DGE-Shipper-' + index).click(updateTDGUserItem);
  if (User.Acceptance == 1)
    $('#DGE-Acceptance-' + index)
      .attr('checked', true)
      .checkboxradio('refresh');
  $('#DGE-Acceptance-' + index).click(updateTDGUserItem);

  if (COUNTRY == 'CANADA') {
    if (User.A4 == 1)
      $('#DGE-A4-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A4-' + index).click(updateTDGUserItem);
    if (User.S4 == 1)
      $('#DGE-S4-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S4-' + index).click(updateTDGUserItem);
    if (User.A5 == 1)
      $('#DGE-A5-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A5-' + index).click(updateTDGUserItem);
    if (User.S5 == 1)
      $('#DGE-S5-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S5-' + index).click(updateTDGUserItem);
    if (User.A6 == 1)
      $('#DGE-A6-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A6-' + index).click(updateTDGUserItem);
    if (User.S6 == 1)
      $('#DGE-S6-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S6-' + index).click(updateTDGUserItem);
    if (User.A8 == 1)
      $('#DGE-A8-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A8-' + index).click(updateTDGUserItem);
    if (User.S8 == 1)
      $('#DGE-S8-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S8-' + index).click(updateTDGUserItem);
    if (User.A9 == 1)
      $('#DGE-A9-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A9-' + index).click(updateTDGUserItem);
    if (User.S9 == 1)
      $('#DGE-S9-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S9-' + index).click(updateTDGUserItem);
    if (User.A10 == 1)
      $('#DGE-A10-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A10-' + index).click(updateTDGUserItem);
    if (User.S10 == 1)
      $('#DGE-S10-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S10-' + index).click(updateTDGUserItem);
    if (User.A11 == 1)
      $('#DGE-A11-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A11-' + index).click(updateTDGUserItem);
    if (User.S11 == 1)
      $('#DGE-S11-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S11-' + index).click(updateTDGUserItem);
    if (User.A12 == 1)
      $('#DGE-A12-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A12-' + index).click(updateTDGUserItem);
    if (User.S12 == 1)
      $('#DGE-S12-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S12-' + index).click(updateTDGUserItem);
    if (User.A13 == 1)
      $('#DGE-A13-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A13-' + index).click(updateTDGUserItem);
    if (User.S13 == 1)
      $('#DGE-S13-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S13-' + index).click(updateTDGUserItem);
    if (User.A14 == 1)
      $('#DGE-A14-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-A14-' + index).click(updateTDGUserItem);
    if (User.S14 == 1)
      $('#DGE-S14-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-S14-' + index).click(updateTDGUserItem);
    if (User.EC == 1)
      $('#DGE-EC-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-EC-' + index).click(updateTDGUserItem);
    if (User.Loading == 1)
      $('#DGE-Loading-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-Loading-' + index).click(updateTDGUserItem);
    if (User.PassengerHandling == 1)
      $('#DGE-PassengerHandling-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-PassengerHandling-' + index).click(updateTDGUserItem);
    if (User.RemoteAcceptance == 1)
      $('#DGE-RemoteAcceptance-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-RemoteAcceptance-' + index).click(updateTDGUserItem);
    if (User.FlightCrewBasic == 1)
      $('#DGE-FlightCrewBasic-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-FlightCrewBasic-' + index).click(updateTDGUserItem);
    if (User.Awareness == 1)
      $('#DGE-Awareness-' + index)
        .attr('checked', true)
        .checkboxradio('refresh');
    $('#DGE-Awareness-' + index).click(updateTDGUserItem);
  }
}

function updateTDGUserItem() {
  var Item = this.id;
  var index = Item.split('-');
  Item = index[1];
  index = index[2];
  var Value;
  var type;
  var User_PK = TDGStaffList[index].PrimaryKey;
  if (Item == 'Trained' || Item == 'Expires') {
    Value = $('#DGE-' + Item + '-' + index).val();
    type = 's';
  } else {
    type = 'i';
    Value = 0;
    if ($(this).is(':checked')) Value = 1;
  }
  $.getJSON(
    BASEURL +
      'menu-Administration-TDG-AJAX.php?action=setItem&User_PK=' +
      User_PK +
      '&Item=' +
      encodeURIComponent(Item) +
      '&Value=' +
      encodeURIComponent(Value) +
      '&type=' +
      encodeURIComponent(type),
    function (json_data) {
      if (json_data[0].ServerResponse == 'Success') {
        console.log('Success: TDG_User Value Set');
        var time = json_data[0].Updated.split(' ');
        time = new Date(time[0] + 'T' + time[1] + 'Z');
        $('#DGE-Updated').html(iTrans('Last Updated') + ': ' + time + ' ' + iTrans('By') + ': ' + chatname);
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'setItem');
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to Set TDG_User value');
    AJAXFAIL(error);
  });
}

function exitTDGUserEdit() {
  $.mobile.changePage($('#TDGAdmin_Page'), {
    reverse: true,
    changeHash: false,
  });
  init_TDGAdmin();
}
