/**
 * Class for policy cards for the front end
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 * @param {mSAMA_PolicyObjective} _model
 * @param {boolean} _readOnly
 */
class vSAMA_TilePolicyObjective extends vSAMA_AbstractTile {
  constructor(_model, _readOnly) {
    super(_model);

    let tileData = {
      title: this.model.sync.pog_name,
      id: '',
      rows: [],
      buttons: [],
    };

    let status = '<i class="fal fa-cog"></i> In Progress';
    this.searchObject.pog_stage = SAMA.enums.REPORT_STAGE.SUBMITTED;

    let completed = this.model.sync.pog_completedDate != null;

    if (completed) {
      status = '<i class="fal fa-check"></i> Completed';
      this.searchObject.pog_stage = SAMA.enums.REPORT_STAGE.COMPLETED;

      tileData.rows[30] = {
        label: 'Completed',
        value: cSAMA_Utils.NiceDate(this.model.sync.pog_completedDate, true, false, true),
      };
    }

    let dueDate = '%';

    if (this.model.sync.pog_dueDate == null) {
      dueDate = 'None';
      this.model.sync.pog_dueDate = '0000-01-01 00:00:00';
    } else {
      let m = moment.utc(this.model.sync.pog_dueDate).local();
      let overdue = m.isBefore(moment(), 'week');
      let dueThisWeek = m.isSame(moment(), 'week');

      this.searchObject.pog_overdue = false;
      if (overdue && !completed) {
        dueDate = '<span class="sama-form-error">%</span>';
        this.searchObject.pog_overdue = true;
      } else if (dueThisWeek && !completed) {
        dueDate = '<span class="sama-form-warning">%</span>';
      }

      dueDate = dueDate.tr(cSAMA_Utils.NiceDate(this.model.sync.pog_dueDate, true, false, true));
    }

    tileData.rows[10] = {
      label: 'Status',
      value: status,
    };

    tileData.rows[20] = {
      label: 'Due date',
      value: dueDate,
    };

    // Row 30 is the completed date, see above

    tileData.rows[40] = {
      classes: 'sama-card-row-large sama-card-row-header',
      label: 'Description',
      value: '',
    };

    tileData.rows[50] = {
      classes: 'sama-card-row-inset-first sama-card-row-inset sama-card-row-inset-last',
      label: '',
      value: this.model.sync.pog_description,
    };

    if (this.model.tasks.length > 0) {
      tileData.rows[60] = {
        classes: 'sama-card-row-large sama-card-row-header',
        label: 'Tasks',
        value: '',
      };

      for (let t = 0; t < this.model.tasks.length; t++) {
        let classes = 'sama-card-row-inset';

        classes += t === 0 ? ' sama-card-row-inset-first' : '';
        classes += t === this.model.tasks.length - 1 ? ' sama-card-row-inset-last' : '';

        const task = this.model.tasks[t];

        let taskDescr =
          task.sync.pot_state === SAMA_TASKSTATES.Complete
            ? '<div><i class="fal fa-check"></i> Completed</div>'
            : '<div><i class="fal fa-cog"></i> In Progress</div>';

        taskDescr += task.sync.pot_desc;

        tileData.rows[70 + t * 10] = {
          classes,
          label: '',
          value: taskDescr,
        };
      }
    }

    if (_readOnly === false) {
      tileData.buttons[0] = this.ButtonDelete();
    }

    this.SetUpGui(tileData);
  }

  /**
   * Creates a 'View' button
   * @return {{label: string, icon: string, fnClick: fnClick}}
   */
  ButtonDelete() {
    return {
      label: 'Delete',
      icon: 'fa-times',
      classes: 'sama-btn-delete',
      fnClick: () => {
        this.ConfirmDelete();
      },
    };
  }

  ConfirmDelete() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Really delete?',
      message:
        'If you delete this objective, it will no longer be shown here or on the "Policies & Objectives" page.<br/>' +
        'Do you really want to delete the objective?',
      yesFunc: () => {
        this.model.SetDeleted(true);
        this.Destroy();
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      },
    });
  }

  Hide() {
    this.dom.body.hide();
    this.visible = false;
  }

  Show() {
    this.dom.body.show();
    this.visible = true;
  }

  Destroy() {
    this.dom.body.fadeOut(() => this.dom.body.remove());
    this.UpdateObservers('ItemDeleted', this);
  }
}
