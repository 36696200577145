/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var CAWxCams = [
  ['188', '51.191667', '-115.558333', 'CBP2', 'false', 'true', 'Banff', false, 'AB'],

  ['170', '51.103000', '-114.374194', 'CYBW', 'false', 'true', 'Calgary / Springbank', false, 'AB'],

  ['119', '49.633889', '-114.485000', 'TM19', 'false', 'true', 'Crowsnest Pass', false, 'AB'],

  ['201', '51.496389', '-112.748611', 'CEG4', 'false', 'true', 'Drumheller', false, 'AB'],

  ['74', '53.572500', '-113.520556', 'CYXD', 'false', 'false', 'Edmonton City Centre', false, 'AB'],

  ['165', '53.668333', '-113.855556', 'CZVL', 'false', 'true', 'Edmonton / Villeneuve', false, 'AB'],

  ['82', '53.578889', '-116.465000', 'CYET', 'false', 'true', 'Edson', false, 'AB'],

  ['117', '58.767222', '-111.117222', 'CYPY', 'false', 'true', 'Fort Chipewyan', false, 'AB'],

  ['29', '56.65333333', '-111.2244444', 'CYMM', 'false', 'true', 'Fort McMurray', false, 'AB'],

  ['105', '58.621389', '-117.164722', 'CYOJ', 'false', 'true', 'High Level', false, 'AB'],

  ['84', '53.319167', '-117.753056', 'CEC4', 'false', 'true', 'Jasper-Hinton', false, 'AB'],

  ['167', '49.630278', '-112.799722', 'CYQL', 'false', 'true', 'Lethbridge', false, 'AB'],

  ['28', '53.3091667', '-110.0725', 'CYLL', 'false', 'true', 'Lloydminster', false, 'AB'],

  ['168', '50.018889', '-110.720556', 'CYXH', 'false', 'true', 'Medicine Hat', false, 'AB'],

  ['103', '49.520556', '-113.997222', 'CZPC', 'false', 'true', 'Pincher Creek', false, 'AB'],

  ['196', '58.491389', '-119.407778', 'CYOP', 'false', 'true', 'Rainbow Lake', false, 'AB'],

  ['81', '52.429722', '-114.904167', 'CYRM', 'false', 'true', 'Rocky Mountain House', false, 'AB'],

  ['107', '55.293056', '-114.777222', 'CYZH', 'false', 'true', 'Slave Lake', false, 'AB'],

  ['104', '51.603333', '-127.863889', 'TM17', 'false', 'true', 'Addenbroke Island Lightstation', false, 'BC'],

  ['183', '50.580472', '-126.910556', 'CYAL', 'false', 'true', 'Alert Bay', false, 'BC'],

  ['45', '48.921389', '-125.540000', 'TM13', 'false', 'true', 'Amphitrite Point Lighthouse', false, 'BC'],

  ['122', '52.452222', '-125.304444', 'CAJ4', 'false', 'true', 'Anahim Lake', false, 'BC'],

  ['92', '59.576667', '-133.668889', 'CYSQ', 'false', 'true', 'Atlin', false, 'BC'],

  ['48', '52.185000', '-128.156667', 'CBBC', 'false', 'true', 'Bella Bella', false, 'BC'],

  ['184', '52.3880000', '-126.587722', 'CYBD', 'false', 'true', 'Bella Coola', false, 'BC'],

  ['191', '52.124722', '-119.292778', 'CYCP', 'false', 'true', 'Blue River ', false, 'BC'],

  ['139', '56.966667', '-130.249444', 'CBW4', 'false', 'true', 'Bob Quinn Lake', false, 'BC'],

  ['136', '57.388611', '-122.852500', 'TM23', 'false', 'true', 'Buckinghorse River (Sikanni Chief)', false, 'BC'],

  ['135', '54.376389', '-125.951389', 'CYPZ', 'false', 'true', 'Burns Lake', false, 'BC'],

  ['8', '50.33361111', '-125.4461111', 'TM02', 'false', 'true', 'Chatham Point Lightstation', false, 'BC'],

  ['78', '49.847778', '-123.188889', 'TM05', 'false', 'true', 'Cheakamus Canyon Tower', false, 'BC'],

  ['116', '51.649444', '-120.036667', 'TM15', 'false', 'true', 'Clearwater', false, 'BC'],

  ['164', '51.266389', '-121.684722', 'CYIN', 'false', 'true', 'Clinton-Bleibler Ranch', false, 'BC'],

  ['9', '55.74222', '-120.17556', 'CYDQ', 'false', 'true', 'Dawson Creek', false, 'BC'],

  ['156', '58.422222', '-130.032222', 'CYDL', 'false', 'true', 'Dease Lake', false, 'BC'],

  ['141', '57.427222', '-125.650278', 'CAJ9', 'false', 'true', 'Fort Ware', false, 'BC'],

  ['51', '59.715278', '-135.044167', 'TM14', 'false', 'true', 'Fraser Camp', false, 'BC'],

  ['79', '49.957222', '-123.175833', 'TM06', 'false', 'true', 'Garibaldi Tower', false, 'BC'],

  ['98', '51.299167', '-116.982500', 'CYGE', 'false', 'true', 'Golden', false, 'BC'],

  ['197', '49.015556', '-118.430556', 'CZGF', 'false', 'true', 'Grand Forks', false, 'BC'],

  ['96', '49.368333', '-121.498056', 'CYHE', 'false', 'true', 'Hope', false, 'BC'],

  ['83', '50.516667', '-116.000000', 'CAA8', 'false', 'true', 'Invermere', false, 'BC'],

  ['12', '49.9561', '-119.37778', 'CYLW', 'false', 'true', 'Kelowna', false, 'BC'],

  ['111', '50.674722', '-121.893611', 'CYLI', 'false', 'true', 'Lillooet', false, 'BC'],

  ['46', '50.224444', '-121.581389', 'CWLY', 'false', 'true', 'Lytton', false, 'BC'],

  ['62', '55.299444', '-123.133333 ', 'CYZY', 'false', 'true', 'Mackenzie', false, 'BC'],

  ['16', '49.06333333', '-120.7869444', 'TM03', 'false', 'true', 'Manning Provincial Park', false, 'BC'],

  ['109', '50.122778', '-120.747222', 'CAD5', 'false', 'true', 'Merritt', false, 'BC'],

  ['124', '58.930278', '-125.767500', 'CWWQ', 'false', 'true', 'Muncho Lake  ', false, 'BC'],

  ['43', '49.168611', '-123.935556', 'TM12', 'false', 'true', 'Nanaimo Harbour', false, 'BC'],

  ['198', '49.173333', '-119.551111', 'CAU3', 'false', 'true', 'Oliver', false, 'BC'],

  ['64', '50.302500', '-122.737778', 'CYPS', 'false', 'true', 'Pemberton', false, 'BC'],

  ['44', '49.321944', '-124.931111', 'CBS8', 'false', 'true', 'Port Alberni', false, 'BC'],

  ['143', '49.834167', '-124.500278', 'CYPW', 'false', 'true', 'Powell River', false, 'BC'],

  ['33', '53.88944444', '-122.6788889', 'CYXS', 'false', 'true', 'Prince George', false, 'BC'],

  ['63', '54.286111', '-130.444722 ', 'CYPR', 'false', 'true', 'Prince Rupert', false, 'BC'],

  ['52', '53.026111', '-122.510278', 'CYQZ', 'false', 'true', 'Quesnel', false, 'BC'],

  ['118', '50.966667', '-118.183333', 'CYRV', 'false', 'true', 'Revelstoke', false, 'BC'],

  ['85', '50.682500', '-119.228611', 'CZAM', 'false', 'true', 'Salmon Arm', false, 'BC'],

  ['130', '48.853889', '-123.497500', 'TM21', 'false', 'true', 'Salt Spring Island - Ganges Harbour', false, 'BC'],

  ['100', '53.254167', '-131.813889', 'CYZP', 'false', 'true', 'Sandspit', false, 'BC'],

  ['128', '48.783056', '-123.045278', 'TM20', 'false', 'true', 'Saturna Island - East Point', false, 'BC'],

  ['152', '54.331667', '-130.276667', 'CBY5', 'false', 'true', 'Seal Cove - Prince Rupert', false, 'BC'],

  ['106', '54.825278', '-127.182778', 'CYYD', 'false', 'true', 'Smithers', false, 'BC'],

  ['80', '49.781667', '-123.161944', 'CYSE', 'false', 'true', 'Squamish', false, 'BC'],

  ['142', '56.906111', '-124.965000', 'CBN9', 'false', 'true', 'Tsay Keh', false, 'BC'],

  ['202', '49.194722', '-123.183889', 'CYVR', 'false', 'true', 'Vancouver Intl', false, 'BC'],

  ['72', '50.246111', '-119.330833', 'CYVK', 'false', 'true', 'Vernon', false, 'BC'],

  ['108', '49.344722', '-123.181667', 'TM18', 'false', 'true', 'West Vancouver', false, 'BC'],

  ['76', '50.168333', '-122.904167', 'CBE9', 'false', 'true', 'Whistler Heliport', false, 'BC'],

  ['77', '50.066389', '-123.110278', 'TM07', 'false', 'true', 'Whistler Radar', false, 'BC'],

  ['182', '52.358889', '-97.018056', 'CYBV', 'false', 'true', 'Berens River', false, 'MB'],

  ['174', '58.739167', '-94.066389', 'CYYQ', 'false', 'true', 'Churchill', false, 'MB'],

  ['73', '51.100833', '-100.052500', 'CYDN', 'false', 'true', 'Dauphin', false, 'MB'],

  ['179', '54.678056', '-101.681944', 'CYFO', 'false', 'true', 'Flin Flon', false, 'MB'],

  ['190', '54.558889', '-94.491389', 'CYGO', 'false', 'true', 'Gods Lake Narrows', false, 'MB'],

  ['212', '58.614167', '-101.468889', 'CZWH', 'false', 'true', 'Lac Brochet', false, 'MB'],

  ['57', '56.863889', '-101.076111', 'CYYL', 'false', 'true', 'Lynn Lake', false, 'MB'],

  ['99', '53.958333', '-97.844167', 'CYNE', 'false', 'true', 'Norway House', false, 'MB'],

  ['195', '54.933056', '-95.278889', 'CYOH', 'false', 'true', 'Oxford House', false, 'MB'],

  ['181', '52.121389', '-101.234444', 'CZJN', 'false', 'true', 'Swan River', false, 'MB'],

  ['132', '58.706111', '-98.512222', 'CYBQ', 'false', 'true', 'Tadoule Lake', false, 'MB'],

  ['58', '45.868889', '-66.537222', 'CYFC', 'false', 'true', 'Fredericton', false, 'NB'],

  ['113', '47.007778', '-65.449167', 'CYCH', 'false', 'true', 'Miramichi', false, 'NB'],

  ['114', '45.207500', '-67.250556', 'CCS3', 'false', 'true', 'St. Stephen', false, 'NB'],

  ['148', '53.561944', '-64.106389', 'CZUM', 'false', 'true', 'Churchill Falls', false, 'NL'],

  ['166', '52.302778', '-55.847778', 'CYMH', 'false', 'true', 'Mary&#039s Harbour', false, 'NL'],

  ['146', '55.913889', '-61.184444', 'CNH2', 'false', 'true', 'Natuashish', false, 'NL'],

  ['145', '54.179722', '-58.457500', 'CCZ2', 'false', 'true', 'Rigolet', false, 'NL'],

  ['23', '51.387778', '-56.087778', 'CYAY', 'false', 'true', 'St. Anthony', false, 'NL'],

  ['176', '52.922778', '-66.864722', 'CYWK', 'false', 'true', 'Wabush', false, 'NL'],

  ['91', '47.136944', '-55.329167', 'CCC2', 'false', 'true', 'Winterland', false, 'NL'],

  ['175', '67.021111', '-126.128611', 'CYVL', 'false', 'true', 'Colville Lake/Tommy Kochon', false, 'NT'],

  ['210', '60.235556', '-123.470000', 'CYJF', 'false', 'true', 'Fort Liard', false, 'NT'],

  ['155', '64.190833', '-114.076667', 'CYWE', 'false', 'true', 'Wekweeti', false, 'NT'],

  ['206', '63.209722', '-123.436667', 'CYWY', 'false', 'true', 'Wrigley', false, 'NT'],

  ['126', '45.418611', '-63.460556', 'CCQ3', 'false', 'true', 'Debert', false, 'NS'],

  ['147', '44.545556', '-65.788889', 'CYID', 'false', 'true', 'Digby', false, 'NS'],

  ['194', '44.881000', '-63.501361', 'CYHZ', 'false', 'true', 'Halifax / Stanfield ', false, 'NS'],

  ['54', '45.656667', '-61.368056', 'CYPD', 'false', 'true', 'Port Hawkesbury', false, 'NS'],

  ['171', '43.932500', '-60.014861', 'CWSA', 'false', 'true', 'Sable Island', false, 'NS'],

  ['125', '45.611944', '-62.621111', 'CYTN', 'false', 'true', 'Trenton', false, 'NS'],

  ['133', '45.051944', '-64.651667', 'CCW3', 'false', 'false', 'Waterville', false, 'NS'],

  ['192', '73.006389', '-85.047222', 'CYAB', 'false', 'true', 'Arctic Bay', false, 'NU'],

  ['200', '61.093889', '-94.071667', 'CYEK', 'false', 'true', 'Arviat', false, 'NU'],

  ['187', '64.230278', '-76.525000', 'CYTE', 'false', 'true', 'Cape Dorset', false, 'NU'],

  ['158', '70.485833', '-68.516944', 'CYCY', 'false', 'true', 'Clyde River', false, 'NU'],

  ['157', '68.635556', '-95.850278', 'CYHK', 'false', 'true', 'Gjoa Haven', false, 'NU'],

  ['193', '68.775833', '-81.242500', 'CYUX', 'false', 'true', 'Hall Beach', false, 'NU'],

  ['161', '66.145000', '-65.713611', 'CYXP', 'false', 'true', 'Pangnirtung', false, 'NU'],

  ['159', '72.689444', '-77.968889', 'CYIO', 'false', 'true', 'Pond Inlet', false, 'NU'],

  ['160', '67.546667', '-64.031667', 'CYVM', 'false', 'true', 'Qikiqtarjuaq', false, 'NU'],

  ['189', '50.290278', '-88.909722', 'CYYW', 'false', 'true', 'Armstrong', false, 'ON'],

  ['31', '45.41361111', '-76.365833', 'CNP3', 'false', 'true', 'Arnprior', false, 'ON'],

  ['112', '52.927500', '-82.431944', 'CYAT', 'false', 'true', 'Attawapiskat', false, 'ON'],

  ['26', '45.07306', '-77.880556', 'CNW3', 'false', 'true', 'Bancroft', false, 'ON'],

  ['215', '53.965556', '-91.027222', 'CNE3', 'false', 'true', 'Bearskin Lake', false, 'ON'],

  ['144', '53.817778', '-89.896944', 'CYTL', 'false', 'true', 'Big Trout Lake', false, 'ON'],

  ['30', '43.630278', '-79.399083', 'CYTZ', 'false', 'true', 'Billy Bishop Toronto City', false, 'ON'],

  ['186', '43.759722', '-79.873889', 'CNC3', 'false', 'true', 'Brampton-Caledon', false, 'ON'],

  ['32', '44.63944444', '-75.750278', 'CNL3', 'false', 'true', 'Brockville', false, 'ON'],

  ['67', '43.441667', '-79.850278', 'CZBA', 'false', 'true', 'Burlington Airpark', false, 'ON'],

  ['205', '51.727222', '-91.824444', 'CYAC', 'false', 'true', 'Cat Lake', false, 'ON'],

  ['66', '43.285556', '-81.508333', 'CYCE', 'false', 'true', 'Centralia', false, 'ON'],

  ['38', '42.306389', '-82.08194', 'CYCK', 'false', 'true', 'Chatham - Kent', false, 'ON'],

  ['36', '44.44916667', '-80.1583333', 'CNY3', 'false', 'true', 'Collingwood', false, 'ON'],

  ['27', '45.0925', '-74.5683333', 'CYCC', 'false', 'true', 'Cornwall', false, 'ON'],

  ['71', '49.831667', '-92.744167', 'CYHD', 'false', 'true', 'Dryden Regional', false, 'ON'],

  ['138', '47.695000', '-79.848889', 'CYXR', 'false', 'true', 'Earlton', false, 'ON'],

  ['134', '46.351667', '-82.561111', 'CYEL', 'false', 'true', 'Elliot Lake', false, 'ON'],

  ['211', '52.203611', '-81.695833', 'CYFA', 'false', 'true', 'Fort Albany', false, 'ON'],

  ['163', '56.018889', '-87.676111', 'CYER', 'false', 'true', 'Fort Severn', false, 'ON'],

  ['39', '45.88527778', '-82.56777778', 'CYZE', 'false', 'true', 'Gore Bay', false, 'ON'],

  ['34', '44.15833333', '-81.0627778', 'CPN4', 'false', 'true', 'Hanover', false, 'ON'],

  ['41', '49.713889', '-83.686944', 'CYHF', 'false', 'true', 'Hearst', false, 'ON'],

  ['35', '44.2013889', '-81.6066667', 'CNS7', 'false', 'true', 'Kincardine', false, 'ON'],

  ['56', '43.460833', '-80.378611', 'CYKF', 'false', 'true', 'Kitchener - Waterloo', false, 'ON'],

  ['162', '52.195556', '-87.934167', 'CYLH', 'false', 'true', 'Lansdowne House', false, 'ON'],

  ['90', '51.291111', '-80.607778', 'CYMO', 'false', 'true', 'Moosonee', false, 'ON'],

  ['25', '44.97472', '-79.303333', 'CYQA', 'false', 'true', 'Muskoka', false, 'ON'],

  ['150', '53.441389', '-91.762778', 'CZMD', 'false', 'true', 'Muskrat Dam', false, 'ON'],

  ['172', '46.363889', '-79.424167', 'CYYB', 'false', 'true', 'North Bay', false, 'ON'],

  ['177', '51.658611', '-85.443333', 'CYKP', 'false', 'true', 'Ogoki Post', false, 'ON'],

  ['65', '43.922778', '-78.895000', 'CYOO', 'false', 'true', 'Oshawa', false, 'ON'],

  ['50', '45.257500', '-79.829722', 'CNK4', 'false', 'true', 'Parry Sound', false, 'ON'],

  ['178', '54.988056', '-85.443333', 'CYPO', 'false', 'true', 'Peawanuck', false, 'ON'],

  ['87', '44.230000', '-78.363333', 'CYPQ', 'false', 'true', 'Peterborough', false, 'ON'],

  ['209', '51.819722', '-93.973333', 'CYPM', 'false', 'true', 'Pikangikum', false, 'ON'],

  ['213', '52.943611', '-91.312500', 'CZRJ', 'false', 'true', 'Round Lake (Weagamow Lake)', false, 'ON'],

  ['151', '53.064167', '-93.344444', 'CZSJ', 'false', 'true', 'Sandy Lake', false, 'ON'],

  ['47', '42.999444', '-82.308889', 'CYZR', 'false', 'true', 'Sarnia', false, 'ON'],

  ['40', '44.945833', '-75.940556', 'CYSH', 'false', 'true', 'Smiths Falls', false, 'ON'],

  ['42', '42.770000', '-81.110833 ', 'CYQS', 'false', 'true', 'St. Thomas', false, 'ON'],

  ['185', '48.371944', '-89.321667', 'CYQT', 'false', 'true', 'Thunder Bay', false, 'ON'],

  ['204', '52.893889', '-89.289167', 'CKL3', 'false', 'true', 'Wunnumin Lake', false, 'ON'],

  ['60', '49.123500', '-68.204444', 'CYBC', 'false', 'true', 'Baie-Comeau', false, 'QC'],

  ['123', '48.071111', '-65.460278', 'CYVB', 'false', 'true', 'Bonaventure', false, 'QC'],

  ['97', '47.597500', '-70.223889', 'CYML', 'false', 'true', 'Charlevoix', false, 'QC'],

  ['153', '50.468889', '-59.636667', 'CYHR', 'false', 'true', 'Chevery', false, 'QC'],

  ['216', '49.771944', '-74.528056', 'CYMT', 'false', 'true', 'Chibougamau/Chapais', false, 'QC'],

  ['208', '48.520833', '-71.050556', 'CYRC', 'false', 'true', 'Chicoutimi/St-Honore', false, 'QC'],

  ['94', '50.281944', '-63.611389', 'CYGV', 'false', 'true', 'Havre St-Pierre', false, 'QC'],

  ['180', '58.471944', '-78.076944', 'CYPH', 'false', 'true', 'Inukjuak', false, 'QC'],

  ['24', '55.283333', '-77.760278', 'CYGW', 'false', 'true', 'Kuujjuarapik', false, 'QC'],

  ['140', '53.754722', '-73.675278', 'CYAH', 'false', 'true', 'La Grande-4', false, 'QC'],

  ['3', '53.754722', '-73.675278', 'yah', 'false', 'false', 'La Grande IV (Legacy)', true, 'QC'],

  ['93', '46.409444', '-74.780000', 'CYFJ', 'false', 'true', 'La Macaza / Mont Tremblant', false, 'QC'],

  ['89', '47.409722', '-72.788889', 'CYLQ', 'false', 'true', 'La Tuque', false, 'QC'],

  ['127', '46.272778', '-75.990556', 'CYMW', 'false', 'true', 'Maniwaki', false, 'QC'],

  ['137', '49.761667', '-77.802778', 'CYNM', 'false', 'true', 'Matagami', false, 'QC'],

  ['68', '46.564444', '-75.577778', 'CSD4', 'false', 'true', 'Mont-Laurier', false, 'QC'],

  ['95', '50.190000', '-61.788889', 'CYNA', 'false', 'true', 'Natashquan', false, 'QC'],

  ['154', '49.836389', '-64.288611', 'CYPN', 'false', 'true', 'Port Menier', false, 'QC'],

  ['169', '60.052222', '-77.287500', 'CYPX', 'false', 'true', 'Puvirnituq', false, 'QC'],

  ['149', '54.805278', '-66.805278', 'CYKL', 'false', 'true', 'Schefferville', false, 'QC'],

  ['49', '45.440250', '-71.692167', 'CYSC', 'false', 'true', 'Sherbrooke', false, 'QC'],

  ['69', '46.096389', '-70.714722', 'CYSG', 'false', 'true', 'St-Georges', false, 'QC'],

  ['37', '45.5175', '-73.416944', 'CYHU', 'false', 'true', 'St. Hubert', false, 'QC'],

  ['70', '46.351667', '-72.680556', 'CYRQ', 'false', 'true', 'Trois Rivieres', false, 'QC'],

  ['173', '48.053333', '-77.787528', 'CYVO', 'false', 'true', 'Val d&#039Or', false, 'QC'],

  ['59', '57.256389 ', '-105.617500 ', 'CYKJ', 'false', 'true', 'Key Lake', false, 'SK'],

  ['214', '49.896389', '-109.475833', 'CJQ4', 'false', 'true', 'Maple Creek', false, 'SK'],

  ['55', '53.333167', '-104.003889', 'CYBU', 'false', 'true', 'Nipawin', false, 'SK'],

  ['53', '52.769167', '-108.243611', 'CYQW', 'false', 'true', 'North Battleford', false, 'SK'],

  ['61', '59.250278', '-105.841389', 'CYSF', 'false', 'true', 'Stony Rapids', false, 'SK'],

  ['102', '50.291944', '-107.690556', 'CYYN', 'false', 'true', 'Swift Current', false, 'SK'],

  ['203', '49.697500', '-103.800833', 'CJE3', 'false', 'true', 'Weyburn', false, 'SK'],

  ['101', '51.264722', '-102.461389', 'CYQV', 'false', 'true', 'Yorkton', false, 'SK'],

  ['199', '61.370556', '-139.040000', 'CYDB', 'false', 'true', 'Burwash', false, 'YK'],

  ['129', '62.110833', '-136.178333', 'CEX4', 'false', 'true', 'Carmacks', false, 'YK'],

  ['207', '62.206944', '-133.373333', 'CZFA', 'false', 'true', 'Faro', false, 'YK'],

  ['121', '60.789167', '-137.545556', 'CYHT', 'false', 'true', 'Haines Junction', false, 'YK'],

  ['131', '60.087778', '-130.603611', 'TM22', 'false', 'true', 'Swift River - Rancheria', false, 'YK'],
];
