function BI_SelClient(index, JobID = 0) {
  console.log(index);
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  var ClientPK = -2;
  $('#BI_Jobs_Filter').empty();
  if (index >= 0) {
    var Clientindex = -1;
    for (var i in Clients) {
      if (Clients[i].PrimaryKey == index) {
        Clientindex = i;
        break;
      }
    }
    Jobs = Clients[Clientindex].JOBS;

    ClientPK = Clients[Clientindex].PrimaryKey;
  }
  if (index == -1) {
    ClientPK = -1;
    Jobs = [];
    $('#BI_Jobs_Filter').append($('<option />').val('0').text(iTrans('Non Revenue')));
  } else {
    $('#BI_Jobs_Filter').append($('<option />').val('0').text(iTrans('All')));
  }
  for (var c in Jobs) {
    $('#BI_Jobs_Filter').append($('<option />').val(Jobs[c].PrimaryKey).text(Jobs[c].Name));
  }
  $('#BI_Jobs_Filter').val(JobID);
  $('#BI_Jobs_Filter').selectmenu('refresh', true);
  BI_UpdateColumns(ClientPK);
  BI_CreateReport(1);
}

function BI_SelJob(pk) {
  var ClientPK = $('#BI_Client_Filter').val();
  BI_UpdateColumns(ClientPK);
  BI_CreateReport(1);
}

function Report_BI() {
  $('#Title_Report_Name').html(iTrans('Flight Reports'));
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  const ClientJobsModuleStatus = Report_Data[0].ClientsJobsModuleStatus;

  var html = '';
  html += "<h1 id='BI_Rec_Count'>" + iTrans('Filters') + '</h1>';
  html += BI_PopReportOptions();
  html += '<label for="showArchivedClients">' + iTrans('Show Archived Clients & Finished Jobs') + '</label>';
  html += '<input type="checkbox" id="showArchivedClients" onClick="BI_CreateReport(1);" />';
  html += '<div class="ui-grid-a  noprint" id="RPT_TDG_Filter1">';
  html +=
    '<div class="ui-block-a"><label for="RPT_FromDate">' +
    iTrans('From Date') +
    '</label><input type="Date" id="RPT_FromDate" data-mini="true" onChange="BI_CreateReport(1);" /></div>';
  html +=
    '<div class="ui-block-b"><label for="RPT_ToDate">' +
    iTrans('To Date') +
    '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" onChange="BI_CreateReport(1);" /></div></div>';
  html += '<div class="ui-grid-a" id="RPT_TDG_Filter2">';
  html +=
    '<div class="ui-block-a"><label for="RPT_Pilots">' +
    iTrans('Filter Pilot / SIC') +
    `</label><select id="RPT_Pilots" data-mini="true" onChange="BI_CreateReport(1);" ><option value="All" selected>${iTrans(
      'All'
    )}</option></select></div>`;
  html +=
    '<div class="ui-block-b"><label for="RPT_Idents">' +
    iTrans('Filter Ident') +
    `</label><select id="RPT_Idents" data-mini="true" onChange="BI_CreateReport(1);" ><option value="All" selected>${iTrans(
      'All'
    )}</option></select></div></div>`;

  if (ClientJobsModuleStatus) {
    html +=
      '<input type="text" placeholder="' +
      iTrans('Search Client / Job - Type Here') +
      '..." id="BI_SearchClientJob" />';
  }
  html += '<label for"BI_Client_Filter">' + iTrans('Client') + '</label>';
  html += '<select id="BI_Client_Filter" onChange="BI_SelClient(this.value)">';
  html += '<option value="-2" selected>' + iTrans('All') + '</option>';
  if (ClientJobsModuleStatus) {
    html += '<option value="-1">' + iTrans('Company Only') + '</option>';
    for (var c in Clients) {
      var C = Clients[c];
      html += '<option value="' + C.PrimaryKey + '">' + C.Business + '</option>';
    }
  }
  html += '</select>';

  html += '<label for="BI_Jobs_Filter">' + iTrans('Job') + '</label>';
  html += '<select id="BI_Jobs_Filter" onChange="BI_SelJob(this.value)">';
  html += '<option value="0" selected>' + iTrans('All') + '</option>';
  if (ClientJobsModuleStatus) {
    for (var j in Jobs) {
      var J = Jobs[j];
      html += '<option value="' + J.PrimaryKey + '">#' + J.JobNum + ': ' + J.Name + '</option>';
    }
  }
  html += '</select><br />';

  html += '<div id="BI_Report_Columns"></div>';

  $('#Report_Controls').html(html);
  $('#Report_Controls').enhanceWithin();

  var fromdate = new Date();
  fromdate.setDate(fromdate.getDate() - 30);
  //document.getElementById('RPT_FromDate').valueAsDate = fromdate;
  $('#RPT_FromDate').val(fromdate.toISOString().substring(0, 10));
  //document.getElementById('RPT_ToDate').valueAsDate = new Date();
  $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
  for (var i in PILOT_data) {
    $('#RPT_Pilots').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
  }
  for (i in ALLAC_data) {
    $('#RPT_Idents').append($('<option />').val(ALLAC_data[i].ident).text(ALLAC_data[i].ident));
  }
  BI_UpdateColumns(-2);
  BI_CreateReport(1);

  $('#BI_SearchClientJob').autocomplete({
    select: searchClientJobsSELECT,
    source: searchClientJobs,
  });
}
function searchClientJobs(term, callback) {
  var txt = term.term;

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=searchJobs',
    data: { txt: txt },
  })
    .success(function (data) {
      console.log(data);
      SEARCH_data = data;
      var options = [];
      for (var i in SEARCH_data) {
        var I = SEARCH_data[i];
        var label = '#' + I.JobNum + ': ' + I.Name + ' [' + I.Business + ']';
        options.push({ label: label, value: i });
      }
      callback(options);
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      console.log('error');
    });
}

function searchClientJobsSELECT(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  var Item = SEARCH_data[value];
  $('#BI_Client_Filter').val(Item.ClientPK);
  $('#BI_Client_Filter').selectmenu('refresh', true);
  BI_SelClient(Item.ClientPK, Item.JobID);
}
function BI_UpdateColumns(ClientPK) {
  var html = '';
  var Cfields = Report_Data[0].Cfields;
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  var JobColumns = Report_Data[0].JobColumns;
  var LegColumns = Report_Data[0].LegColumns;
  var ReportColumns = Report_Data[0].ReportColumns;
  Report_Data[0].ClientPK = ClientPK;

  html +=
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
    '<input type="radio" name="AllgroupToggle" id="AllselHideToggle" value="0">' +
    '<label for="AllselHideToggle">' +
    iTrans('Hide All') +
    '</label>' +
    '<input type="radio" name="AllgroupToggle" id="AllselShowToggle" value="1" checked="checked">' +
    '<label for="AllselShowToggle">' +
    iTrans('Show All') +
    '</label></fieldset>';

  html += '<div class="limit-table" data-role="collapsible" data-inset="true">';
  html += '<h2>' + iTrans('Report Columns') + '</h2>';
  html += '<div>';

  html +=
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
    '<input type="radio" name="RgroupToggle" id="RselHideToggle" value="0">' +
    '<label for="RselHideToggle">' +
    iTrans('Hide All') +
    '</label>' +
    '<input type="radio" name="RgroupToggle" id="RselShowToggle" value="1" checked="checked">' +
    '<label for="RselShowToggle">' +
    iTrans('Show All') +
    '</label></fieldset>';

  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Column') +
    '</th><th>' +
    iTrans('Options') +
    '</th></tr></thead><tbody>';
  for (var r in ReportColumns) {
    var R = ReportColumns[r];
    var Raction = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
    Raction += '<input type="radio" name="Rgroup' + r + '" id="Rsel_Hide' + r + '" value="0" >';
    Raction += '<label for="Rsel_Hide' + r + '">' + iTrans('Hide') + '</label>';

    Raction += '<input type="radio" name="Rgroup' + r + '" id="Rsel_Show' + r + '" value="1" checked="checked" >';
    Raction += '<label for="Rsel_Show' + r + '">' + iTrans('Show') + '</label>';

    switch (R) {
      case 'P_Hobbs_Total':
      case 'S_Hobbs_Total':
      case 'CargoTotal':
        Raction += '<input type="radio" name="Rgroup' + r + '" id="Rsel_Sum' + r + '" value="2" disabled>';
        Raction += '<label for="Rsel_Sum' + r + '">' + iTrans('Total') + '</label>';

        Raction += '<input type="radio" name="Rgroup' + r + '" id="Rsel_Avg' + r + '" value="3" disabled>';
        Raction += '<label for="Rsel_Avg' + r + '">' + iTrans('Avg') + '</label>';
        break;
    }

    Raction += '</fieldset>';

    html += '<tr><td>' + R + '</td><td>' + Raction + '</td></tr>';
  }
  html += '</tbody></table>';
  html += '</div></div>';
  html += '<div class="limit-table" data-role="collapsible" data-inset="true">';
  html += `<h2>${iTrans('Per Report Custom Fields')}</h2>`;
  html += '<div>';

  html +=
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
    '<input type="radio" name="CgroupToggle" id="CselHideToggle" value="0">' +
    '<label for="CselHideToggle">' +
    iTrans('Hide All') +
    '</label>' +
    '<input type="radio" name="CgroupToggle" id="CselShowToggle" value="1" checked="checked">' +
    '<label for="CselShowToggle">' +
    iTrans('Show All') +
    '</label></fieldset>';

  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Column') +
    `</th><th>${iTrans('Type')}</th><th>` +
    iTrans('Options') +
    '</th></tr></thead><tbody>';
  for (var c in Cfields) {
    var C = Cfields[c];
    if (C.PerLeg == 0) {
      var Type = 'Client';
      if (C.ClientPK == 0) Type = 'Company';
      if (Type == 'Client' && C.ClientPK != ClientPK && ClientPK != -2) continue;
      var Caction = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
      Caction += '<input type="radio" name="Cgroup' + c + '" id="Csel_Hide' + c + '" value="0" >';
      Caction += '<label for="Csel_Hide' + c + '">' + iTrans('Hide') + '</label>';

      Caction += '<input type="radio" name="Cgroup' + c + '" id="Csel_Show' + c + '" value="1" checked="checked" >';
      Caction += '<label for="Csel_Show' + c + '">' + iTrans('Show') + '</label>';

      switch (C.type) {
        case 'number':
          Caction += '<input type="radio" name="Cgroup' + c + '" id="Csel_Sum' + c + '" value="2" disabled>';
          Caction += '<label for="Csel_Sum' + c + '">' + iTrans('Total') + '</label>';

          Caction += '<input type="radio" name="Cgroup' + c + '" id="Csel_Avg' + c + '" value="3" disabled>';
          Caction += '<label for="Csel_Avg' + c + '">' + iTrans('Avg') + '</label>';
          break;
      }

      Caction += '</fieldset>';

      html += '<tr><td>' + C.Label + '</td><td>' + Type + '</td><td>' + Caction + '</td></tr>';
    }
  }
  html += '</tbody></table>';
  html += '</div></div>';
  html += '<div class="limit-table" data-role="collapsible" data-inset="true">';
  html += '<h2>' + iTrans('Job Columns') + '</h2>';
  html += '<div>';

  html +=
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
    '<input type="radio" name="JgroupToggle" id="JselHideToggle" value="0">' +
    '<label for="JselHideToggle">' +
    iTrans('Hide All') +
    '</label>' +
    '<input type="radio" name="JgroupToggle" id="JselShowToggle" value="1" checked="checked">' +
    '<label for="JselShowToggle">' +
    iTrans('Show All') +
    '</label></fieldset>';

  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Column') +
    '</th><th>' +
    iTrans('Options') +
    '</th></tr></thead><tbody>';
  for (var j in JobColumns) {
    var J = JobColumns[j];
    var Jaction = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
    Jaction += '<input type="radio" name="Jgroup' + j + '" id="Jsel_Hide' + j + '" value="0" >';
    Jaction += '<label for="Jsel_Hide' + j + '">' + iTrans('Hide') + '</label>';

    Jaction += '<input type="radio" name="Jgroup' + j + '" id="Jsel_Show' + j + '" value="1" checked="checked" >';
    Jaction += '<label for="Jsel_Show' + j + '">' + iTrans('Show') + '</label>';

    Jaction += '</fieldset>';

    html += '<tr><td>' + J + '</td><td>' + Jaction + '</td></tr>';
  }
  html += '</tbody></table>';
  html += '</div></div>';
  html += '<div class="limit-table" data-role="collapsible" data-inset="true">';
  html += '<h2>' + iTrans('Leg Columns') + '</h2>';
  html += '<div>';

  html +=
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
    '<input type="radio" name="LgroupToggle" id="LselHideToggle" value="0">' +
    '<label for="LselHideToggle">' +
    iTrans('Hide All') +
    '</label>' +
    '<input type="radio" name="LgroupToggle" id="LselShowToggle" value="1" checked="checked">' +
    '<label for="LselShowToggle">' +
    iTrans('Show All') +
    '</label></fieldset>';

  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Column') +
    '</th><th>' +
    iTrans('Options') +
    '</th></tr></thead><tbody>';
  for (var l in LegColumns) {
    var L = LegColumns[l];
    var Laction = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
    Laction += '<input type="radio" name="Lgroup' + l + '" id="Lsel_Hide' + l + '" value="0" >';
    Laction += '<label for="Lsel_Hide' + l + '">' + iTrans('Hide') + '</label>';

    Laction += '<input type="radio" name="Lgroup' + l + '" id="Lsel_Show' + l + '" value="1" checked="checked" >';
    Laction += '<label for="Lsel_Show' + l + '">' + iTrans('Show') + '</label>';

    switch (L) {
      case 'PaxNum':
      case 'Flight_Time':
        Laction += '<input type="radio" name="Lgroup' + l + '" id="Lsel_Sum' + l + '" value="2" disabled>';
        Laction += '<label for="Lsel_Sum' + l + '">' + iTrans('Total') + '</label>';

        Laction += '<input type="radio" name="Lgroup' + l + '" id="Lsel_Avg' + l + '" value="3" disabled>';
        Laction += '<label for="Lsel_Avg' + l + '">' + iTrans('Avg') + '</label>';
        break;
    }
    Laction += '</fieldset>';

    html += '<tr><td>' + L + '</td><td>' + Laction + '</td></tr>';
  }
  html += '</tbody></table>';
  html += '</div></div>';
  html += '<div class="limit-table" data-role="collapsible" data-inset="true">';
  html += '<h2>' + iTrans('Per Leg Custom Fields') + '</h2>';
  html += '<div>';

  html +=
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
    '<input type="radio" name="LCgroupToggle" id="LCselHideToggle" value="0">' +
    '<label for="LCselHideToggle">' +
    iTrans('Hide All') +
    '</label>' +
    '<input type="radio" name="LCgroupToggle" id="LCselShowToggle" value="1" checked="checked">' +
    '<label for="LCselShowToggle">' +
    iTrans('Show All') +
    '</label></fieldset>';

  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Column') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Options') +
    '</th></tr></thead><tbody>';
  for (var c in Cfields) {
    var C = Cfields[c];
    if (C.PerLeg == 1) {
      var Type = 'Client';
      if (C.ClientPK == 0) Type = 'Company';
      if (Type == 'Client' && C.ClientPK != ClientPK && ClientPK != -2) continue;
      var Caction = '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
      Caction += '<input type="radio" name="Cgroup' + c + '" id="LCsel_Hide' + c + '" value="0" >';
      Caction += '<label for="LCsel_Hide' + c + '">' + iTrans('Hide') + '</label>';

      Caction += '<input type="radio" name="Cgroup' + c + '" id="LCsel_Show' + c + '" value="1" checked="checked" >';
      Caction += '<label for="LCsel_Show' + c + '">' + iTrans('Show') + '</label>';

      switch (C.type) {
        case 'number':
          Caction += '<input type="radio" name="Cgroup' + c + '" id="LCsel_Sum' + c + '" value="2" disabled>';
          Caction += '<label for="LCsel_Sum' + c + '">' + iTrans('Total') + '</label>';

          Caction += '<input type="radio" name="Cgroup' + c + '" id="LCsel_Avg' + c + '" value="3" disabled>';
          Caction += '<label for="LCsel_Avg' + c + '">' + iTrans('Avg') + '</label>';
          break;
      }

      Caction += '</fieldset>';

      html += '<tr><td>' + C.Label + '</td><td>' + Type + '</td><td>' + Caction + '</td></tr>';
    }
  }
  html += '</tbody></table>';
  html += '</div></div>';
  html +=
    '<button class="greenbg" onClick="BI_SaveReportSettings()" data-icon="info">' +
    iTrans('Save Report Settings') +
    '</button>';

  html +=
    '<button class="greenbg" onClick="BI_CreateReport(0)" data-icon="check">' + iTrans('Generate Report') + '</button>';

  $('#BI_Report_Columns').html(html);
  $('#BI_Report_Columns').enhanceWithin();
  $('#Report_Content').html('');

  addColumnSelectAllEvents();
}

function addColumnSelectAllEvents() {
  addColumnsToggleEvent($('input[name=AllgroupToggle]'), $('input[id*=sel_Hide]'), $('input[id*=sel_Show]'));
  addColumnsToggleEvent($('input[name=RgroupToggle]'), $('input[id^=Rsel_Hide]'), $('input[id^=Rsel_Show]'));
  addColumnsToggleEvent($('input[name=CgroupToggle]'), $('input[id^=Csel_Hide]'), $('input[id^=Csel_Show]'));
  addColumnsToggleEvent($('input[name=JgroupToggle]'), $('input[id^=Jsel_Hide]'), $('input[id^=Jsel_Show]'));
  addColumnsToggleEvent($('input[name=LgroupToggle]'), $('input[id^=Lsel_Hide]'), $('input[id^=Lsel_Show]'));
  addColumnsToggleEvent($('input[name=LCgroupToggle]'), $('input[id^=LCsel_Hide]'), $('input[id^=LCsel_Show]'));
}

function addColumnsToggleEvent(toggleGroup, hideButtons, showButtons) {
  toggleGroup.on('click', function (event) {
    if (event.target.value == 1) {
      hideButtons.prop('checked', false).checkboxradio('refresh');
      showButtons.prop('checked', true).checkboxradio('refresh');
      return;
    }

    showButtons.prop('checked', false).checkboxradio('refresh');
    hideButtons.prop('checked', true).checkboxradio('refresh');
  });
}

function BI_LoadReportSettings(index) {
  var Cfields = Report_Data[0].Cfields;
  var SelCfields = [];
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  var JobColumns = Report_Data[0].JobColumns;
  var SelJobColumns = [];
  var LegColumns = Report_Data[0].LegColumns;
  var SelLegColumns = [];
  var ReportColumns = Report_Data[0].ReportColumns;
  var SelReportColumns = [];
  var ClientPK = Report_Data[0].ClientPK;
  var JobPK = -2;
  var checkVal = ''; // values: 0-Hide, 1-Show, 2-Sum, 3-Avg
  var showArchivedClients = 0;
  var pilot = 'All';
  var ident = 'All';
  var O = {};
  if (index != -1) {
    $('#DeleteReportSettings').show();
    O = Report_Data[0].SavedReports[index].Report;
    SelCfields = O.CF;
    SelJobColumns = O.JobRows;
    SelLegColumns = O.LH;
    SelReportColumns = O.RH;
    JobPK = O.JobPK;
    ClientPK = O.ClientPK;
    showArchivedClients = O.showArchivedClients;
    pilot = O.pilot;
    ident = O.ident;
    var FromDate = new Date(O.FromDate);
    FromDate.setMinutes(FromDate.getMinutes() + FromDate.getTimezoneOffset());
    var ToDate = new Date(O.ToDate);
    ToDate.setMinutes(ToDate.getMinutes() + ToDate.getTimezoneOffset());
    $('#RPT_FromDate').val(FromDate.toISOString().substring(0, 10));
    $('#RPT_ToDate').val(ToDate.toISOString().substring(0, 10));
    $('#RPT_Pilots').val(pilot).selectmenu('refresh');
    $('#RPT_Idents').val(ident).selectmenu('refresh');
  } else {
    $('#DeleteReportSettings').hide();
  }

  if (showArchivedClients == 1) $('#showArchivedClients').prop('checked', true).checkboxradio('refresh');

  //Reset Radio Values
  for (var r in ReportColumns) {
    var R = ReportColumns[r];

    //$('input:radio[name=Rgroup' + r + ']').filter('[value="1"]').attr("checked",false).checkboxradio("refresh");
    if (SelReportColumns.indexOf(R) != -1) {
      $('input:radio[name=Rgroup' + r + ']')
        .filter('[value="1"]')
        .attr('checked', true)
        .checkboxradio('refresh');
      $('input:radio[name=Rgroup' + r + ']')
        .filter('[value="0"]')
        .attr('checked', false)
        .checkboxradio('refresh');
    } else {
      $('input:radio[name=Rgroup' + r + ']')
        .filter('[value="1"]')
        .attr('checked', false)
        .checkboxradio('refresh');
      $('input:radio[name=Rgroup' + r + ']')
        .filter('[value="0"]')
        .attr('checked', true)
        .checkboxradio('refresh');
    }
  }

  for (var c in Cfields) {
    var C = Cfields[c];
    var found = false;
    for (var c2 in SelCfields) {
      var PrimaryKey = SelCfields[c2].PrimaryKey;
      if (PrimaryKey == C.PrimaryKey) {
        $('input:radio[name=Cgroup' + c + ']')
          .filter('[value="1"]')
          .attr('checked', true)
          .checkboxradio('refresh');
        $('input:radio[name=Cgroup' + c + ']')
          .filter('[value="0"]')
          .attr('checked', false)
          .checkboxradio('refresh');
        found = true;
      }
    }
    if (!found) {
      $('input:radio[name=Cgroup' + c + ']')
        .filter('[value="1"]')
        .attr('checked', false)
        .checkboxradio('refresh');
      $('input:radio[name=Cgroup' + c + ']')
        .filter('[value="0"]')
        .attr('checked', true)
        .checkboxradio('refresh');
    }
  }
  console.log(SelCfields);

  for (var j in JobColumns) {
    var J = JobColumns[j];
    if (SelJobColumns.indexOf(J) != -1) {
      $('input:radio[name=Jgroup' + j + ']')
        .filter('[value="1"]')
        .attr('checked', true)
        .checkboxradio('refresh');
      $('input:radio[name=Jgroup' + j + ']')
        .filter('[value="0"]')
        .attr('checked', false)
        .checkboxradio('refresh');
    } else {
      $('input:radio[name=Jgroup' + j + ']')
        .filter('[value="1"]')
        .attr('checked', false)
        .checkboxradio('refresh');
      $('input:radio[name=Jgroup' + j + ']')
        .filter('[value="0"]')
        .attr('checked', true)
        .checkboxradio('refresh');
    }
  }
  console.log(SelJobColumns);

  for (var l in LegColumns) {
    var L = LegColumns[l];
    if (SelLegColumns.indexOf(L) != -1) {
      $('input:radio[name=Lgroup' + l + ']')
        .filter('[value="1"]')
        .attr('checked', true)
        .checkboxradio('refresh');
      $('input:radio[name=Lgroup' + l + ']')
        .filter('[value="0"]')
        .attr('checked', false)
        .checkboxradio('refresh');
    } else {
      $('input:radio[name=Lgroup' + l + ']')
        .filter('[value="1"]')
        .attr('checked', false)
        .checkboxradio('refresh');
      $('input:radio[name=Lgroup' + l + ']')
        .filter('[value="0"]')
        .attr('checked', true)
        .checkboxradio('refresh');
    }
  }
  console.log(SelLegColumns);

  //send other report filters

  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;

  if (O.ClientPK != undefined) {
    $('#BI_Client_Filter').val(O.ClientPK).selectmenu('refresh');
  } else {
    $('#BI_Client_Filter').val(-2);
    JobPK = 0;
    $('#RPT_Pilots').val('All').selectmenu('refresh');
    $('#RPT_Idents').val('All').selectmenu('refresh');
  }
  if (O.ClientPK == -1 || O.ClientPK == undefined) {
    Jobs = [];
    $('#BI_Jobs_Filter').append($('<option />').val('0').text('Non Revenue'));
  } else {
    $('#BI_Jobs_Filter').append($('<option />').val('0').text('All'));
  }
  for (var c in Jobs) {
    $('#BI_Jobs_Filter').append($('<option />').val(Jobs[c].PrimaryKey).text(Jobs[c].Name));
  }
  $('#BI_Jobs_Filter').val(JobPK);
  $('#BI_Jobs_Filter').selectmenu('refresh', true);
  BI_CreateReport(0);
}

function BI_PopReportOptions() {
  var Options = Report_Data[0].SavedReports;
  var html =
    '<label for="SavedBIReportsList">' +
    iTrans('Select Saved Report Settings') +
    '</label><select id="SavedBIReportsList" onChange="BI_LoadReportSettings(this.value)">';
  html += '<option value="-1">N/A</option>';
  for (var i in Options) {
    var I = Options[i];
    html += '<option value="' + i + '">' + I.Name + '</option>';
  }
  html += '</select>';
  html +=
    '<button onClick="DeleteReportSettings()" data-icon="delete" id="DeleteReportSettings" style="display:none;">' +
    iTrans('Delete Save Report Setting') +
    '</button>';
  return html;
}
function BI_SaveReportSettings() {
  Report_Data[0].ClientPK = $('#BI_Client_Filter').val();
  var Cfields = Report_Data[0].Cfields;
  var SelCfields = [];
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  var JobColumns = Report_Data[0].JobColumns;
  var SelJobColumns = [];
  var LegColumns = Report_Data[0].LegColumns;
  var SelLegColumns = [];
  var ReportColumns = Report_Data[0].ReportColumns;
  var SelReportColumns = [];
  var ClientPK = Report_Data[0].ClientPK;
  var JobPK = $('#BI_Jobs_Filter').val();
  var html = '';
  var checkVal = ''; // values: 0-Hide, 1-Show, 2-Sum, 3-Avg
  var showArchivedClients = 0;
  if ($('#showArchivedClients').is(':checked')) showArchivedClients = 1;
  for (var r in ReportColumns) {
    var R = ReportColumns[r];
    checkVal = $('input[name=Rgroup' + r + ']:checked').val();
    if (checkVal != 0) SelReportColumns.push(R);
  }
  console.log(SelReportColumns);

  for (var c in Cfields) {
    var C = Cfields[c];
    var Type = 'Client';
    if (C.ClientPK == 0) Type = 'Company';
    if (Type == 'Client' && C.ClientPK != ClientPK && ClientPK != -2) continue;
    checkVal = $('input[name=Cgroup' + c + ']:checked').val();
    if (checkVal != 0) SelCfields.push(C);
  }
  console.log(SelCfields);

  for (var j in JobColumns) {
    var J = JobColumns[j];
    checkVal = $('input[name=Jgroup' + j + ']:checked').val();
    if (checkVal != 0) SelJobColumns.push(J);
  }
  console.log(SelJobColumns);

  for (var l in LegColumns) {
    var L = LegColumns[l];
    checkVal = $('input[name=Lgroup' + l + ']:checked').val();
    if (checkVal != 0) SelLegColumns.push(L);
  }
  console.log(SelLegColumns);

  //send other report filters

  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();

  var pilot = $('#RPT_Pilots').val();
  var ident = $('#RPT_Idents').val();
  var Report = {
    CountOnly: 1,
    ClientPK: ClientPK,
    JobPK: JobPK,
    FromDate: FromDate,
    ToDate: ToDate,
    pilot: pilot,
    ident: ident,
    CF: SelCfields,
    JobRows: SelJobColumns,
    RH: SelReportColumns,
    LH: SelLegColumns,
    showArchivedClients: showArchivedClients,
  };
  var Selected = $('#SavedBIReportsList').val();
  var name = '';
  if (Selected != -1) {
    name = Report_Data[0].SavedReports[Selected].Name;
  }
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Name Report'),
    headerClose: false,
    buttonPrompt:
      '<label for="BIReportNameSave">' +
      iTrans('Enter Report Name') +
      '</label><input type="text" id="BIReportNameSave" value="' +
      escapeHtml(name) +
      '"/>',
    buttons: {
      [iTrans('Save')]: {
        click: function () {
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: BASEURL + 'menu-Administration-Reports-AJAX.php?report=SaveBIReport',
            data: { Name: $('#BIReportNameSave').val(), Report: JSON.stringify(Report) },
          })
            .success(function (data) {})
            .fail(function (data) {
              //data.status, data.readyState, data.responseText
              console.log(data);
              $.mobile.loading('hide');
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Failed!'),
                headerClose: false,
                buttonPrompt: iTrans(
                  'There was a problem saving the report settings.  Please try again. If this continues to happen please submit a bug report.'
                ),
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                  [iTrans('Report')]: {
                    click: function () {
                      //File bug report
                    },
                  },
                },
              });
            });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function DeleteReportSettings() {
  var Selected = $('#SavedBIReportsList').val();
  if (Selected != -1) {
    var report = Report_Data[0].SavedReports[Selected];
  } else {
    return;
  }
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete') + ' [' + report.Name + '] ' + iTrans('Reporting Template?'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: BASEURL + 'menu-Administration-Reports-AJAX.php?report=DeleteBIReport&PK=' + report.PrimaryKey,
            data: { Do: 'It' },
          })
            .success(function (data) {
              init_Report('Report_BI', '&ClientPK=-1&showArchivedClients=0');
            })
            .fail(function (data) {
              //data.status, data.readyState, data.responseText
              console.log(data);
              $.mobile.loading('hide');
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Failed!'),
                headerClose: false,
                buttonPrompt: iTrans(
                  'There was a problem deleting the report settings.  Please try again. If this continues to happen please submit a bug report.'
                ),
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                  [iTrans('Report')]: {
                    click: function () {
                      //File bug report
                    },
                  },
                },
              });
            });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}
function BI_CreateReport(CountOnly) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  //todo send selected report fields to server
  var Cfields = Report_Data[0].Cfields;
  var SelCfields = [];
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  var JobColumns = Report_Data[0].JobColumns;
  var SelJobColumns = [];
  var LegColumns = Report_Data[0].LegColumns;
  var SelLegColumns = [];
  var ReportColumns = Report_Data[0].ReportColumns;
  var SelReportColumns = [];
  var ClientPK = $('#BI_Client_Filter').val();
  var JobPK = $('#BI_Jobs_Filter').val();
  var html = '';
  var checkVal = ''; // values: 0-Hide, 1-Show, 2-Sum, 3-Avg
  var showArchivedClients = 0;
  if ($('#showArchivedClients').is(':checked')) showArchivedClients = 1;
  for (var r in ReportColumns) {
    var R = ReportColumns[r];
    checkVal = $('input[name=Rgroup' + r + ']:checked').val();
    if (checkVal != 0) SelReportColumns.push(R);
  }
  console.log(SelReportColumns);

  for (var c in Cfields) {
    var C = Cfields[c];
    var Type = 'Client';
    if (C.ClientPK == 0) Type = 'Company';
    if (Type == 'Client' && C.ClientPK != ClientPK && ClientPK != -2) continue;
    checkVal = $('input[name=Cgroup' + c + ']:checked').val();
    if (checkVal != 0) SelCfields.push(C);
  }
  console.log(SelCfields);

  for (var j in JobColumns) {
    var J = JobColumns[j];
    checkVal = $('input[name=Jgroup' + j + ']:checked').val();
    if (checkVal != 0) SelJobColumns.push(J);
  }
  console.log(SelJobColumns);

  for (var l in LegColumns) {
    var L = LegColumns[l];
    checkVal = $('input[name=Lgroup' + l + ']:checked').val();
    if (checkVal != 0) SelLegColumns.push(L);
  }
  console.log(SelLegColumns);

  //send other report filters

  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();

  var pilot = $('#RPT_Pilots').val();
  var ident = $('#RPT_Idents').val();
  var request = {
    CountOnly: CountOnly,
    ClientPK: ClientPK,
    JobPK: JobPK,
    FromDate: FromDate,
    ToDate: ToDate,
    pilot: pilot,
    ident: ident,
    CF: JSON.stringify(SelCfields),
    JobRows: JSON.stringify(SelJobColumns),
    RH: JSON.stringify(SelReportColumns),
    LH: JSON.stringify(SelLegColumns),
    showArchivedClients: showArchivedClients,
  };

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Administration-Reports-AJAX.php?report=getFlightReports',
    data: request,
  })
    .success(function (data) {
      $.mobile.loading('hide');
      //display report data in table
      $('#BI_Rec_Count').html(`${iTrans('Filters')}: ` + data.NumRows + ` ${iTrans('Records')}`);
      if (CountOnly == 0) BI_Display_Report(data);
      else $('#Report_Content').html('');
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      console.log(data);
      $.mobile.loading('hide');
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem generating the report.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
  //save csv data or download from server?
}

function BI_Display_Report(data) {
  console.log(data);
  DownloadCSV_DATA = data.CSV;
  $('#Report_Content').html('');
  var button =
    '<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportCSV_BTN">' +
    iTrans('Download CSV Data') +
    '</a></div>';
  $('#Report_Content').html(
    button + '<h1>' + iTrans('Report Data Preview') + "</h1><div style='overflow:scroll;'>" + data.HTML + '</div>'
  );
  $('#Report_Content').enhanceWithin();
  $('#exportCSV_BTN').on('click', function (event) {
    var data;
    var now = new Date();
    var filename = 'Cirro_Report_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '.csv';
    if (device.platform.toUpperCase() === 'ANDROID') {
      console.log('Opening base64 Android');
      event.preventDefault();
      LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
    } else if (IOS) {
      console.log('Opening base64 IOS');
      event.preventDefault();
      LoadMobolizeFile2(filename, btoa(unescape(encodeURIComponent(DownloadCSV_DATA))), 'text/csv');
    } else {
      console.log('Opening base64 Universal URL');
      var a = document.createElement('a');

      if (typeof a.download != 'undefined') {
        data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_blank',
          download: filename,
        });
        // let the click go through
      } else {
        // download attribute is not supported
        data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_system',
        });
      }
    }
  });
}
