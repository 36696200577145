/**
 * Class for consequence tiles
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_TileConsequence extends vSAMA_AbstractTile {
  constructor(_consequence, _matrix, _readOnly) {
    super(_consequence);

    let tileData = {
      title: this.model.sync.con_description,
      id: '',
      wrapperClasses: 'sama-100',
      rows: [],
      buttons: [],
    };

    this.model.UpdateRiskCalculation();

    tileData.rows[10] = {
      label: 'Risk',
      value: this.model.sync.con_riskScore,
    };

    let freqName = _matrix.GetFrequencyName(this.model.sync.con_frequency);
    let freqDesc = _matrix.GetFrequencyDesc(this.model.sync.con_frequency);
    let occurStr = this.model.sync.con_hasOccurred === 1 ? 'Has already occurred' : '';

    let occurrenceString =
      '<span class="sama-100">%</span><span class="sama-100 sama-light">%</span><strong class="warn sama-100 sama-light">%</strong>';
    occurrenceString = occurrenceString.tr(freqName, freqDesc, occurStr);

    tileData.rows[20] = {
      classes: 'sama-card-row-large',
      label: 'Occurs',
      value: occurrenceString,
    };

    let riskFactorValues = this.model.GetRiskFactorValues();
    for (let f = 0; f < riskFactorValues.length; f++) {
      let currentRiskFactorValue = riskFactorValues[f];
      let customFieldValues = currentRiskFactorValue.GetCustomFieldValues();
      tileData.rows.push(vSAMA_TileConsequence.GetRiskFactorRow(currentRiskFactorValue, customFieldValues));

      for (let c = 0; c < customFieldValues.length; c++) {
        tileData.rows.push(
          vSAMA_TileConsequence.GetCustomFieldValueRow(c, customFieldValues[c], customFieldValues.length)
        );
      }
    }

    this.SetUpGui(tileData);
  }

  static GetRiskFactorRow(_currentRiskFactorValue, _customFieldValues) {
    let riskFactor = _currentRiskFactorValue.GetRiskFactor();
    let riskString = '<span class="sama-100">%</span><span class="sama-100 sama-light">%</span>';
    riskString = riskString.tr(
      riskFactor.GetRiskName(_currentRiskFactorValue.GetRisk()),
      riskFactor.GetRiskDesc(_currentRiskFactorValue.GetRisk())
    );

    let cls = 'sama-card-row-large sama-card-row-header';

    if (_customFieldValues.length === 0) {
      cls += ' sama-card-row-inset-last';
    }

    return {
      classes: cls,
      label: riskFactor.sync.mrf_name,
      value: riskString,
    };
  }

  static GetCustomFieldValueRow(_iteration, _currentCustomFieldValue, _numCustomFieldValues) {
    let classes = 'sama-card-row-inset';

    classes += _iteration === 0 ? ' sama-card-row-inset-first' : '';
    classes += _iteration === _numCustomFieldValues - 1 ? ' sama-card-row-inset-last' : '';

    let fieldValues = _currentCustomFieldValue.sync.mcfv_value || ' - ';
    if (_currentCustomFieldValue.valueNames.length > 0) {
      // Looks like we've got some resolved names instead of some plain text / PKs
      fieldValues = '';

      for (let i = 0; i < _currentCustomFieldValue.valueNames.length; i++) {
        fieldValues += '<div class="sama-keyword-readOnly"><span>%</span></div>'.tr(
          _currentCustomFieldValue.valueNames[i]
        );
      }
    }

    return {
      classes: classes,
      label: _currentCustomFieldValue.GetCustomField().sync.mcf_question,
      value: fieldValues,
    };
  }
}
