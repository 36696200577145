class vSAMA_PolicyObjectiveTaskEditor extends vSAMA_AbstractEditor {
  constructor(_task) {
    super();

    this.task = _task;
    this.dom = {
      body: $(SAMA.Templates.tpl.objectives.taskEdit),
    };

    this.dom.delButton = this.dom.body.find('.sama-btn-delete');
    this.dom.completeButton = this.dom.body.find('.sama-btn-complete');

    this.dom.delButton.on('click.samaevt', () => this.OnEditorDeleteButton());

    cSAMA_GeneralInputs.LinkInputs(this.task, this.dom.body, this);
  }

  GetDom() {
    return this.dom.body;
  }

  /**
   * Called from the delete button. Confirms the intent and triggers destroy.
   * @constructor
   */
  OnEditorDeleteButton() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Archive Task?',
      message: 'Are you sure?',
      yesFunc: () => {
        this.task.SetDeleted(true);
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        this.Destroy();
        this.UpdateObservers('Deleted');
      },
    });
  }

  Destroy() {
    this.dom.body.remove();
  }
}
