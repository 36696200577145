/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var dbMbtiles;
var Mbtilesversion = 1;
var Mbtiles_Data = null;
var Mbtiles_Data_CACHE = null;
var MBTILE_MASTER_VFR = {
  type: 'FeatureCollection',
  features: [],
};
var MBTILE_STATS = {
  files: 0,
  curFile: 0,
  success: 0,
  successList: [],
  fail: 0,
  failList: [],
};
var audio_download;

function updateMbtiles() {
  if (ONLINE) {
    //$.mobile.loading('show', {
    //	theme: "a"
    //});
    if (MAPSTATE.Download == true) {
      alert('Downloading already in progress!');
      return;
    }

    if (IOS) {
      audio_download = new Media(BASEURL + 'download.mp3');
      audio_download.setVolume(0.01);
      audio_download.play({ playAudioWhenScreenIsLocked: true });
    }
    hideMbtileGrid();
    MAPSTATE.Download = true;
    CancelDownload = false; //reset cancel flag
    GetDownloadStats();
  } else {
    alert('Cannot Update When Offline!');
  }
  $('#OfflineDataStatus').html('<h2>Downloading Offline Data.  Standby</h2>');
  $('#OfflineDataStatus').enhanceWithin();
}

function GetDownloadStats() {
  MBTILE_STATS.files = 0;
  MBTILE_STATS.curFile = 0;
  MBTILE_STATS.success = 0;
  MBTILE_STATS.successList = [];
  MBTILE_STATS.fail = 0;
  MBTILE_STATS.failList = [];
  GotLocalMbtileData = {};
  cirroDB.query('Mbtiles', 'PrimaryKey != ?', '', function (e) {
    if (e === false) {
      e = [];
    }
    for (var i in e) {
      var I = e[i];
      if (I.Mbtiles == 'Download') {
        MBTILE_STATS.files++;
      }
      GotLocalMbtileData[I.PrimaryKey] = I;
    }
    compare_Mbtiles(0);
  });
}

function FinishedDownloadsAlert() {
  MAPSTATE.Download = false;
  if (CORDOVA) {
    if (IOS) {
      audio_download.stop();
      audio_download.release();
    }
    cordova.plugins.backgroundMode.disable();
    $('#OfflineDataStatus').html('<h2>Download Comlete. Refreshing List...</h2>');
  }
  MAPSTATE.LoadingMbTiles = false;
  syncMbtiles(true);
}

var ExpireMbtiles_CACHE = null;

function GetMbtileUpdateData(silent) {
  if (ONLINE) {
    $.getJSON(BASEURL + 'sync-AJAX.php?action=mbtiles', function (json_data) {
      //local database is empty and offline fail with message
      if (json_data[0].ServerResponse == 'Offline') {
        if (!silent) {
          MbtileNetworkError();
        } else {
          MbtileNetworkError();
          MAPSTATE.LoadingMbTiles = false;
        }
        return;
      } else {
        //IOS_postMessage({Message:"SyncStatus", status:"Received Mbtiles Document List"});

        Mbtiles_Data_CACHE = JSON.stringify(json_data);
        //Expire the MbtileData Cache in 1 hour
        ExpireMbtiles_CACHE = setTimeout(function () {
          ExpireMbtiles_CACHE = null;
        }, 43200000); //good for 12 hours
        if (!silent) {
          syncMbtiles2();
        } else {
          MAPSTATE.LoadingMbTiles = false;
        }
      }
    }).fail(function (jqXHR, status, error) {
      Con_Flag = false;
      //IOS_postMessage({Message:"SyncStatusFail", json_data:{ServerResponse:"Connection Error"}});
      //alert("Offline Data Server connection failed");
      //load from local storage list of tile statuses
      if (!silent) {
        popOfflineDataStatus();
      }
      // get last sync dates from local database and return
      if (!silent) {
        MbtileNetworkError();
      }
      if (silent) {
        MbtileNetworkError();
        MAPSTATE.LoadingMbTiles = false;
      }
    });
  } else {
    if (Mbtiles_Data_CACHE != null && !silent) {
      syncMbtiles2();
    } else {
      MbtileNetworkError();
    }
  }
}

function syncMbtiles(silent = true) {
  if (MAPSTATE.LoadingMbTiles) {
    return;
  } //Prevent duplicate calls until the map layer has been completely loaded
  MAPSTATE.LoadingMbTiles = true;
  //get system information like aircraf WB master setup data
  //IOS_postMessage({Message:"Mbtilestext", text:"Downloading"});
  MAPSTATE.Charts = []; //reset Charts mapstate var
  MAPSTATE.AllCharts = []; //reset Charts mapstate var
  MBTILE_MASTER_VFR = {
    type: 'FeatureCollection',
    features: [],
  };
  if (ExpireMbtiles_CACHE != null) {
    syncMbtiles2();
  } else {
    if (CORDOVA) {
      getFreeSpace();
    } //in App.html sets FREE_SPACE
    GetMbtileUpdateData(silent);
  }
}

function MbtileNetworkError() {
  MAPSTATE.LoadingMbTiles = false;
  if (ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Network Error'),
      headerClose: false,
      buttonPrompt: iTrans('Could not retrieve Offline Map Data List'),
      buttons: {
        Retry: {
          click: function () {
            syncMbtiles();
          },
        },
        Cancel: {
          click: function () {
            //Do Nothing
          },
        },
      },
    });
  }
}

function syncMbtiles2() {
  Mbtiles_Data = JSON.parse(Mbtiles_Data_CACHE);
  var htmlOverview = '';
  var htmlDiagrams = '';
  for (var i in Mbtiles_Data) {
    //var Date_Mbtiles = new Date(Mbtiles_Data[i].Date + "T12:00:00Z");
    //Mbtiles_Data[i].Date = Date_Mbtiles;
    var d = Mbtiles_Data[i];
    if (d.Country != MAPSTATE.OfflineCountry && d.Country != 'ALL' && MAPSTATE.ChartGrid == true) {
      continue;
    }
    var BaseType = MAPSTATE.OfflineType.slice(0, 3);
    if ((d.coordinates != '' && d.type == MAPSTATE.OfflineType) || d.type == BaseType + ' Database') {
      //Will need to add other types here "IFRLOW" "IFRHIGH" "HELI" etc...
      var geometryType = 'Polygon';
      if (d.coordinates.slice(0, 7) == '[ [ [ [') {
        geometryType = 'MultiPolygon';
      }
      MBTILE_MASTER_VFR.features.push({
        type: 'Feature',
        properties: {
          PrimaryKey: d.PrimaryKey,
          id: d.id,
          Pub: d.Pub,
          Name: d.Name,
          Edition: d.Edition,
          Date: d.Date,
          index: i,
          File: d.File,
          type: d.type,
        },
        geometry: {
          type: geometryType,
          coordinates: JSON.parse(d.coordinates),
        },
      });
    }
    if (d.coordinates == '' && d.type == MAPSTATE.OfflineType + ' Overview') {
      htmlOverview += '<label for="MB_' + d.id + '">' + d.Name + '</label>';
      htmlOverview +=
        '<input type="checkbox"  name="MB_' +
        d.id +
        '" id="MB_' +
        d.id +
        '" data-mini="true" value="' +
        d.PrimaryKey +
        '" onClick="OfflineDataOption(this,' +
        i +
        ')">';
    }
    if (d.coordinates == '' && d.type == 'Diagrams') {
      htmlDiagrams += '<label for="MB_' + d.id + '">' + d.Name + '</label>';
      htmlDiagrams +=
        '<input type="checkbox"  name="MB_' +
        d.id +
        '" id="MB_' +
        d.id +
        '" data-mini="true" value="' +
        d.PrimaryKey +
        '" onClick="OfflineDataOption(this,' +
        i +
        ')">';
    }
    if (d.coordinates == '' && d.type == 'Database') {
      htmlDiagrams += '<label for="MB_' + d.id + '">' + d.Name + '</label>';
      htmlDiagrams +=
        '<input type="checkbox"  name="MB_' +
        d.id +
        '" id="MB_' +
        d.id +
        '" data-mini="true" value="' +
        d.PrimaryKey +
        '" onClick="OfflineDataOption(this,' +
        i +
        ')">';
    }
  }

  MAPSTATE.LoadingMbTiles = false;

  $('#OfflineOptions_Overview').html(htmlOverview);
  $('#OfflineOptions_Diagrams').html(htmlDiagrams);
  $('#offlinePanel').enhanceWithin();

  GetDownloadStats();
}

function compare_Mbtiles(i) {
  if (Mbtiles_Data.length == 0) {
    MbloopCheck(0, true);
    return;
  }
  if (i % 500 === 0) {
    setTimeout(function () {
      var I = Mbtiles_Data[i];
      if (i < Mbtiles_Data.length - 1) {
        MbtileLoop2(I.PrimaryKey, I.Date, i, false);
      } else {
        MbtileLoop2(I.PrimaryKey, I.Date, i, true);
      }
    }, 1);
  } else {
    var I = Mbtiles_Data[i];
    if (i < Mbtiles_Data.length - 1) {
      MbtileLoop2(I.PrimaryKey, I.Date, i, false);
    } else {
      MbtileLoop2(I.PrimaryKey, I.Date, i, true);
    }
  }
}

var GotLocalMbtileData = false;

function MbtileLoop2(PK, modDate, i, last) {
  var Modified = new Date(modDate + 'T12:00:00Z');
  if (GotLocalMbtileData[PK] == undefined) {
    //no local record continue loop
    MbloopCheck(i, last);
  } else {
    var d = GotLocalMbtileData[PK];
    var RecDate = new Date(d.Date + 'T12:00:00Z');
    var state = d.Mbtiles;
    if (RecDate.getTime() != Modified.getTime() || d.Pub == 'NOTAMS') {
      state = 'Expired';
      updateMAPSTATE_CHARTS(d, i, state);
      updateMbCheckboxesStatus(d, i, state, RecDate, Modified);
      if (MAPSTATE.Download) {
        getResourceMbtiles(i, last);
      } else {
        MbloopCheck(i, last);
      }
    } else {
      updateMbCheckboxesStatus(d, i, state, RecDate, Modified);
      updateMAPSTATE_CHARTS(d, i, state);
      if (state == 'Download' && MAPSTATE.Download) {
        getResourceMbtiles(i, last);
      } else {
        MbloopCheck(i, last);
      }
    }
  }
}

function updateMbCheckboxesStatus(d, i, state, RecDate, Modified) {
  if (
    (d.coordinates == '' &&
      d.type == MAPSTATE.OfflineType + ' Overview' &&
      (state == 'Downloaded' || state == 'Expired' || state == 'Download')) ||
    (d.coordinates == '' &&
      d.type == 'Diagrams' &&
      (state == 'Downloaded' || state == 'Expired' || state == 'Download')) ||
    (d.coordinates == '' &&
      d.type == 'Database' &&
      (state == 'Downloaded' || state == 'Expired' || state == 'Download'))
  ) {
    var checkClass = '';
    if (state == 'Download') {
      checkClass = 'yellowbg';
    } else {
      $("label[for='MB_" + Mbtiles_Data[i].id + "']").removeClass('yellowbg');
    }
    if (RecDate.getTime() != Modified.getTime()) {
      checkClass = 'redbg';
    } else {
      $("label[for='MB_" + Mbtiles_Data[i].id + "']").removeClass('redbg');
    }
    $('#MB_' + d.id)
      .attr('checked', true)
      .checkboxradio('refresh');
    $("label[for='MB_" + d.id + "']").addClass(checkClass);
  }
}

function MbloopCheck(i, last) {
  if (last) {
    GotLocalMbtileData = false;
    if (MAPSTATE.Download) {
      FinishedDownloadsAlert();
    } else {
      showMbtileGrid();
    }
  } else {
    i++;
    compare_Mbtiles(i);
  }
}

function updateMAPSTATE_CHARTS(d, i, state) {
  if (d.coordinates == '') {
    MAPSTATE.Overviews[d.id] = {
      data: d,
      state: state,
      index: i,
    };
  } else {
    var geometryType = 'Polygon';
    if (d.coordinates.slice(0, 7) == '[ [ [ [') {
      geometryType = 'MultiPolygon';
    }
    MAPSTATE.AllCharts[d.id] = {
      type: 'Feature',
      properties: {
        PrimaryKey: d.PrimaryKey,
        id: d.id,
        Pub: d.Pub,
        Name: d.Name,
        Edition: d.Edition,
        Date: d.Date,
        index: i,
        State: state,
        File: d.File,
        type: d.type,
      },
      geometry: {
        type: geometryType,
        coordinates: JSON.parse(d.coordinates),
      },
    };
    if (d.type != undefined && d.type != 'Terrain') {
      if (!MAPSTATE.ChartGrid) {
        var first = MAPSTATE.Type.slice(0, 3);
        if (first == 'Hel') first = 'VFR';
        if (MAPSTATE.Type != d.type && first + ' Database' != d.type && !(MAPSTATE.Type == 'Heli' && d.type == 'VFR')) {
          return;
        }
      } else {
        var first = MAPSTATE.OfflineType.slice(0, 3);
        if (MAPSTATE.OfflineType != d.type && first + ' Database' != d.type) {
          return;
        }
      }
    }
    if (d.type == 'VFR') {
      MAPSTATE.Charts['VFR_' + d.id] = {
        type: 'Feature',
        properties: {
          PrimaryKey: d.PrimaryKey,
          id: 'VFR_' + d.id,
          Pub: d.Pub,
          Name: d.Name,
          Edition: d.Edition,
          Date: d.Date,
          index: i,
          State: state,
          File: 'VFR_' + d.File,
          type: d.type,
        },
        geometry: {
          type: geometryType,
          coordinates: JSON.parse(d.coordinates),
        },
      };
    } else {
      MAPSTATE.Charts[d.id] = {
        type: 'Feature',
        properties: {
          PrimaryKey: d.PrimaryKey,
          id: d.id,
          Pub: d.Pub,
          Name: d.Name,
          Edition: d.Edition,
          Date: d.Date,
          index: i,
          State: state,
          File: d.File,
          type: d.type,
        },
        geometry: {
          type: geometryType,
          coordinates: JSON.parse(d.coordinates),
        },
      };
    }
  }
}

function deleteMbtiles(id, File) {
  cirroDB.Delete('Mbtiles', 'PrimaryKey = ?', id, function (e) {
    removeMbtileFile(File);
  });
}

function removeMbtileFile(File) {
  var filename = File + '.mbtiles';
  if (IOS || ANDROID) {
    var fileURL;
    if (ANDROID) {
      fileURL = cordova.file.applicationStorageDirectory + 'databases';
    }
    if (IOS) {
      fileURL = cordova.file.applicationStorageDirectory + 'Library/LocalDatabase';
    }

    window.resolveLocalFileSystemURL(fileURL, function (dir) {
      dir.getFile(filename, { create: false }, function (fileEntry) {
        fileEntry.remove(
          function () {
            // The file has been removed succesfully
          },
          function (error) {},
          function () {
            // The file doesn't exist do nothing
          }
        );
      });
    });
  }
  if (ELECTRON) {
    fs.unlink(app.getPath('userData') + '/' + filename, (err) => {
      if (err) {
        alert('Failed to delete: ' + File);
      }
    });
  }
}

function queResourceMbtiles(i) {
  Mbtiles_Data[i].Mbtiles = 'Download';
  var SaveItem = Mbtiles_Data[i];
  cirroDB.insert('Mbtiles', SaveItem, null, function (e) {});
}

function getResourceMbtiles(i, last) {
  //Create timer confirmation to allow for download interruption of large number of downloads.
  var PK = Mbtiles_Data[i].PrimaryKey;
  var filename = Mbtiles_Data[i].File + '.mbtiles';
  var fromURL = MBTILE_URL + '/' + filename;
  var fileURL = null;
  MBTILE_STATS.curFile++;
  if (IOS || ANDROID) {
    if (ANDROID) {
      fileURL = cordova.file.applicationStorageDirectory + 'databases/' + filename;
    }
    if (IOS) {
      fileURL = cordova.file.applicationStorageDirectory + 'Library/LocalDatabase/' + filename;
    }
    if (CORDOVA) {
      getFreeSpace();
    } //in App.html sets FREE_SPACE
    downloadMBTILES_SYNC(filename, fileURL, fromURL, i, last, 0);
  } else {
    downloadMBTILES_SYNC_WEB(filename, fileURL, fromURL, i, last, 0);
  }
}

function storeResourceMbtiles(Mbtiles_data, i, last) {
  Mbtiles_Data[i].Mbtiles = Mbtiles_data;
  var SaveItem = Mbtiles_Data[i];

  cirroDB.insert('Mbtiles', SaveItem, null, function (e) {
    if (e === false) {
      if (MAPSTATE.Download) {
        FinishedDownloadsAlert();
      }
    } else {
      i++;
      if (!last) {
        compare_Mbtiles(i);
      } else {
        if (MAPSTATE.Download) {
          FinishedDownloadsAlert();
        } else {
          showMbtileGrid();
        }
      }
    }
  });
}

var DOWNLOADING_MBTILES = false;
var mbtileFileTransfer = null;
var CancelDownload = false;

function cancelMbtiles() {
  CancelDownload = true;
  mbtileFileTransfer.abort();
}

function downloadMBTILES_SYNC(filename, fileURL, fromURL, i, last, failcount) {
  if (DOWNLOADING_MBTILES) {
    return;
  }
  DOWNLOADING_MBTILES = true;
  if (CancelDownload) {
    storeResourceMbtiles('Download', i, last);
    return;
  }

  $('#downloadProgress').show();
  $('#downloadProgressTXT').show();
  $('#UpdateTilesBTN').prop('disabled', true);
  $('#CancelTilesBTN').prop('disabled', false);
  $('#downloadProgress').css('width', '0%');
  $('#downloadProgressTXT').html('Initializing Download...');
  var fileTransfer = new FileTransfer();
  mbtileFileTransfer = fileTransfer;
  fileTransfer.onprogress = function (progressEvent) {
    if (progressEvent.lengthComputable) {
      var FreeSpace = FREE_SPACE - progressEvent.loaded;
      if (progressEvent.total - progressEvent.loaded > FreeSpace) {
        fileTransfer.abort();
        $('#downloadProgress').hide();
        $('#downloadProgressTXT').hide();
        $('#UpdateTilesBTN').prop('disabled', false);
        $('#CancelTilesBTN').prop('disabled', true);

        MBTILE_STATS.fail++;
        FinishedDownloadsAlert();

        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Error',
          headerClose: false,
          buttonPrompt:
            'Your device is running low on storage!<br />Please free up space<br /><b>' +
            filename +
            ' failed to download!</b>',
          buttons: {
            OK: {
              click: function () {
                DOWNLOADING_MBTILES = false;
              },
            },
          },
        });
        return;
      }
      var percent = Math.round((progressEvent.loaded / progressEvent.total) * 10000) / 100;
      var stats =
        'Downloading File ' +
        MBTILE_STATS.curFile +
        ' of ' +
        MBTILE_STATS.files +
        ': ' +
        filename +
        ' ' +
        Math.round(progressEvent.loaded / 1000000) +
        'MB / ' +
        Math.round(progressEvent.total / 1000000) +
        'MB - ' +
        percent +
        '%';

      $('#downloadProgressTXT').html(stats);
      $('#downloadProgress').css('width', percent + '%');
    } else {
      $('#downloadProgressTXT').html('Downloading: ' + filename + ' - Uknown Amount');
    }
  };
  fileTransfer.download(
    fromURL,
    fileURL,
    function (entry) {
      $('#downloadProgress').hide();
      $('#downloadProgressTXT').hide();
      $('#UpdateTilesBTN').prop('disabled', false);
      $('#CancelTilesBTN').prop('disabled', true);
      DOWNLOADING_MBTILES = false;
      MBTILE_STATS.success++;
      MBTILE_STATS.successList.push(filename);
      storeResourceMbtiles('Downloaded', i, last);
    },
    function (error) {
      $('#downloadProgress').hide();
      $('#downloadProgressTXT').hide();
      $('#UpdateTilesBTN').prop('disabled', false);
      $('#CancelTilesBTN').prop('disabled', true);
      failcount++;
      DOWNLOADING_MBTILES = false;
      if (failcount < 10 && !CancelDownload) {
        downloadMBTILES_SYNC(filename, fileURL, fromURL, i, last, failcount); //put failed loop to break infinite
      } else {
        MBTILE_STATS.fail++;
        MBTILE_STATS.failList.push(filename);
        storeResourceMbtiles('Download', i, last);
      }
    },
    true,
    null
  );
}

async function downloadMBTILES_SYNC_WEB(filename, fileURL, fromURL, i, last, failcount) {
  if (DOWNLOADING_MBTILES) {
    return;
  }
  DOWNLOADING_MBTILES = true;
  if (CancelDownload) {
    storeResourceMbtiles('Download', i, last);
    return;
  }

  $('#downloadProgress').show();
  $('#downloadProgressTXT').show();
  $('#UpdateTilesBTN').prop('disabled', true);
  $('#CancelTilesBTN').prop('disabled', false);
  $('#downloadProgress').css('width', '0%');
  $('#downloadProgressTXT').html('Initializing Download...');
  try {
    await window.vueApp.utilities.download
      .fromUrl(fromURL)
      .onProgress((chunk) => {
        const progressPercent = Math.round((chunk.loaded / chunk.total) * 100);
        var stats =
          'Downloading File ' +
          MBTILE_STATS.curFile +
          ' of ' +
          MBTILE_STATS.files +
          ': ' +
          filename +
          ' ' +
          Math.round(chunk.loaded / 1000000) +
          'MB / ' +
          Math.round(chunk.total / 1000000) +
          'MB - ' +
          progressPercent +
          '%';
        $('#downloadProgressTXT').html(stats);
        $('#downloadProgress').css('width', progressPercent + '%');
      })
      .toFileSystem(window.vueApp.utilities.file.dirDb, filename);

    $('#downloadProgress').hide();
    $('#downloadProgressTXT').hide();
    $('#UpdateTilesBTN').prop('disabled', false);
    $('#CancelTilesBTN').prop('disabled', true);
    DOWNLOADING_MBTILES = false;
    MBTILE_STATS.success++;
    MBTILE_STATS.successList.push(filename);
    storeResourceMbtiles('Downloaded', i, last);
  } catch (e) {
    console.log(e);
    $('#downloadProgress').hide();
    $('#downloadProgressTXT').hide();
    $('#UpdateTilesBTN').prop('disabled', false);
    $('#CancelTilesBTN').prop('disabled', true);
    failcount++;
    DOWNLOADING_MBTILES = false;
    if (failcount < 10 && !CancelDownload) {
      downloadMBTILES_SYNC(filename, fileURL, fromURL, i, last, failcount); //put failed loop to break infinite
    } else {
      MBTILE_STATS.fail++;
      MBTILE_STATS.failList.push(filename);
      storeResourceMbtiles('Download', i, last);
    }
  }
}

function hideMbtileGrid() {
  if (mapb == null) {
    return;
  }
  $('#offlinePanel').popup('close');
  $('#startRoutePlanBTN').prop('disabled', false);
  $('#mapbfileinput').prop('disabled', false);
  $('#openacmenu').prop('disabled', false);
  $('#gpsmenu').prop('disabled', false);
  $('#nearestmenu').prop('disabled', false);
  if (GPS.Active) {
    toggleGPSactive();
  }
  $('#startRoutePlanBTN').prop('disabled', false);
  $('#MeasureControl').prop('disabled', false);
  if (mapb.getLayer('ChartSelect') != undefined) {
    mapb.removeLayer('ChartSelect');
  }
  if (mapb.getLayer('ChartSelected') != undefined) {
    mapb.removeLayer('ChartSelected');
  }
  if (mapb.getLayer('ChartSelect-outline') != undefined) {
    mapb.removeLayer('ChartSelect-outline');
  }
  if (mapb.getLayer('ChartSelect-hover') != undefined) {
    mapb.removeLayer('ChartSelect-hover');
  }
  if (mapb.getLayer('ChartSelect-labels') != undefined) {
    mapb.removeLayer('ChartSelect-labels');
  }
  if (mapb.getSource('ChartSelect') != undefined) {
    mapb.removeSource('ChartSelect');
  }
  if (mapb.getSource('ChartSelected') != undefined) {
    mapb.removeSource('ChartSelected');
  }

  mapb.off('mousemove', mousemoveChartSelect);
  mapb.off('click', clickChartSelect);
  mapb.off('mouseout', mouseoutChartSelect);
  MAPSTATE.ChartSelect = false;
  MAPSTATE.ChartGrid = false;
  if ($('#terrainShading').is(':checked')) {
    mapb.setTerrain({ source: 'DEM', exaggeration: 1 });
  }
}

function popOfflineDataStatus(e = false) {
  if (MAPSTATE.Download == true) {
    $('#OfflineDataStatus').html('<h2>Downloading Offline Data.  Standby</h2>');
    $('#OfflineDataStatus').enhanceWithin();
    return;
  }
  var datapacks = LOCALSTORAGE.datapacks;
  if (datapacks == undefined) {
    datapacks = null;
  }
  var chartpacks = LOCALSTORAGE.chartpacks;
  if (chartpacks == undefined) {
    chartpacks = null;
  }
  var html = '';
  $('#OfflineDataStatus').html('');
  if (e) {
    showMbtileTypeOptions();
    return;
  }
  html = '<h3>Offline Data Status</h3>';
  html += '<button data-icon="shop" onClick="popOfflineDataStatus(true);">Manage Offline Data</button>';
  if (LOW_BANDWIDTH) {
    html += '<button data-icon="refresh" onClick="syncMbtiles();">Refresh Status Now</button>';
  }
  html +=
    '<table class="first-td-fill"><thead><tr><th>Name</th><th>Pub</th><th>Edition</th><th>Issue Date</th></tr></thead>';
  var Items = datapacks.concat(chartpacks);
  var update = false;
  for (var d in Items) {
    var D = Items[d];
    var dClass = 'greenbg';
    if (D.state == 'Expired') {
      dClass = 'redbg';
      if (D.Pub == 'NOTAMS') {
        dClass = 'yellowbg';
        D.Date = dateToStringObject(new Date()).substring(0, 10);
      }
    }
    if (D.state == 'Download') {
      dClass = 'yellowbg';
    }
    if (D.state == 'Expired' || D.state == 'Download' || D.Pub == 'NOTAMS') {
      update = true;
    }
    if (D.index != 0) {
      $('#MbCHK-' + D.index)
        .prop('checked', true)
        .checkboxradio('refresh');
    }

    html +=
      "<tr class='" +
      dClass +
      "'><td>" +
      D.Name +
      '</td><td>' +
      D.Pub +
      '</td><td>' +
      D.Edition +
      '</td><td>' +
      D.Date +
      '</td></tr>';
  }
  html += '</table>';
  if (update) {
    html += '<button onClick="updateMbtiles()"  id="UpdateTilesBTN2" class="greenbg">Update Now</button>';
  }
  if (Items.length == 0) {
    if (CORDOVA || GPS_DEBUG || ELECTRON) {
      if (CHARTS == 1 && CFS == 1) {
        html = '<h4>No Offline Data Downloaded</h4>';
        html += '<button data-icon="shop" onClick="popOfflineDataStatus(true);">Manage Offline Data</button>';
      } else {
        html = '<h4>Subscription required for offline data</h4>';
      }
    } else {
      html = '';
    }
  }

  if (e) {
    $('#OfflineDataStatus').append(html);
  } else {
    $('#OfflineDataStatus').html(html);
  }
  $('#OfflineDataStatus').enhanceWithin();
}

function showMbtileTypeOptions(e = false) {
  var html = '<h2>Select Data Type</h2>';
  if (e) {
    html += '<h2>No Items Found</h2>';
  }
  html += '<button onClick="showMbtileCountryOptions(\'VFR\');">VFR Charts</button>';
  html += '<button onClick="showMbtileCountryOptions(\'IFR\');">IFR Charts and Data</button>';
  html += "<button onClick=\"showMbtileList('Any', 'Terrain');\">Terrain Topo Charts</button>";
  html += "<button onClick=\"showMbtileList('Any', 'Satellite');\">Satellite Imagery</button>";
  html += '<button onClick="showMbtileCountryOptions(\'Diagrams\');">Grids / Airports / Diagrams etc.</button>';
  html += '<button onClick="showMbtileCountryOptions(\'Database\');">Aviation Databases and Extras</button>';
  $('#OfflineDataStatus').html(html);
  $('#OfflineDataStatus').enhanceWithin();
}

function showMbtileCountryOptions(type) {
  var html = '';
  html += '<h2>' + type + ' - Select Country</h2>';
  html += "<button onClick=\"showMbtileList('USA', '" + type + '\');">United States</button>';
  html += "<button onClick=\"showMbtileList('Canada', '" + type + '\');">Canada</button>';
  html += "<button onClick=\"showMbtileList('Indonesia', '" + type + '\');">Indonesia</button>';
  $('#OfflineDataStatus').html(html);
  $('#OfflineDataStatus').enhanceWithin();
}

function triggerMapMbtileSelect(Country, type) {
  clickHandler('Map');
  setTimeout(function () {
    offlineData();
    if (Country == 'Canada') {
      $('#OFFLINECOUNTRY').val('CANADA').selectmenu('refresh');
      showCAchartsOFFLINE();
    }
    if (Country == 'USA') {
      $('#OFFLINECOUNTRY').val('UNITED STATES').selectmenu('refresh');
      showUSchartsOFFLINE();
    }
    if (Country == 'Indonesia') {
      $('#OFFLINECOUNTRY').val('INDONESIA').selectmenu('refresh');
      showIndochartsOFFLINE();
    }
    setTimeout(function () {
      $('#T_VFR').prop('checked', false).checkboxradio('refresh');
      $('#T_IFR_LOW').prop('checked', false).checkboxradio('refresh');
      $('#T_IFR_HIGH').prop('checked', false).checkboxradio('refresh');
      $('#T_TOPO').prop('checked', false).checkboxradio('refresh');
      $('#T_SAT').prop('checked', false).checkboxradio('refresh');
      $('#T_CUSTOM').prop('checked', false).checkboxradio('refresh');
      if (type == 'VFR') {
        showVFRchartsOFFLINE();
        $('#T_VFR').prop('checked', true).checkboxradio('refresh');
      }
      if (type == 'IFR') {
        showIFRLOWchartsOFFLINE();
        $('#T_IFR_LOW').prop('checked', true).checkboxradio('refresh');
      }
      if (type == 'Terrain') {
        showTOPOchartsOFFLINE();
        $('#T_TOPO').prop('checked', true).checkboxradio('refresh');
      }
      if (type == 'Satellite') {
        showSATchartsOFFLINE();
        $('#T_SAT').prop('checked', true).checkboxradio('refresh');
      }
      if (type == 'DEM_RGB') {
        showDEM_RGBchartsOFFLINE();
        $('#T_DEM_RGB').prop('checked', true).checkboxradio('refresh');
      }
      if (type != 'DEM_RGB' && type != 'Satellite' && type != 'Terrain' && type != 'IFR' && type != 'VFR') {
        showVFRchartsOFFLINE();
        $('#T_VFR').prop('checked', true).checkboxradio('refresh');
      }
    }, 1000);
  }, 2000);
}

function showMbtileList(Country, type) {
  var html = '<h2>Select Data for Offline Use</h2>';
  html += '<button onClick="updateMbtiles()"  id="UpdateTilesBTN2" class="greenbg">Start Download</button>';
  html += '<button data-icon="shop" onClick="popOfflineDataStatus(true);">Manage Offline Data</button>';
  html +=
    '<button data-icon="grid" class="yellowbg" onClick="triggerMapMbtileSelect(\'' +
    Country +
    "', '" +
    type +
    '\');">Select Data From Map</button>';
  html +=
    '<table class="limit-table" data-filter="true"><thead><tr><th>Name</th><th>Pub</th><th>Edition</th><th>Issue Date</th><th>Action</th></tr></thead><tbody>';
  var count = 0;
  for (var i in Mbtiles_Data) {
    var I = Mbtiles_Data[i];
    if (I.type != 'Custom Overview') {
      if (type == 'IFR' && I.type.substring(0, 3) != 'IFR') {
        continue;
      }
      if (type == 'Database' && I.type.substring(-8, 8) != 'Database') {
        continue;
      }
      if (type == 'VFR' && I.type.substr(0, 3) != 'VFR') {
        continue;
      }
      if (type == 'Terrain' && I.type.substr(-7, 7) != 'Terrain') {
        continue;
      }
      if (type == 'Satellite' && I.type.substr(-9, 9) != 'Satellite') {
        continue;
      }
      if (type == 'DEM_RGB' && I.type.substr(-7, 7) != 'DEM_RGB') {
        continue;
      }
      if (type == 'Diagrams' && I.type != 'Diagrams') {
        continue;
      }
      if (I.Country != Country && type != 'Terrain' && type != 'Satellite' && type != 'DEM_RGB' && I.Country != 'ALL') {
        continue;
      }
    } else {
      if (type != 'Database') {
        continue;
      }
    }
    html +=
      "<tr id='MbSELECT-" +
      i +
      "'><td>" +
      I.Name +
      '</td><td>' +
      I.Pub +
      '</td><td>' +
      I.Edition +
      '</td><td>' +
      I.Date +
      "</td><td><input type='checkbox' id='MbCHK-" +
      i +
      "' onClick='OfflineDataOption(this, " +
      i +
      ")' /></td></tr>";
    count++;
  }
  html += '</tbody></table>';
  if (count == 0) {
    showMbtileTypeOptions(true);
    return;
  }
  $('#OfflineDataStatus').html(html);
  $('#OfflineDataStatus').enhanceWithin();
  var datapacks = LOCALSTORAGE.datapacks;
  if (datapacks == undefined) {
    datapacks = null;
  }
  var chartpacks = LOCALSTORAGE.chartpacks;
  if (chartpacks == undefined) {
    chartpacks = null;
  }
  var Items = datapacks.concat(chartpacks);
  var html = '';
  for (var d in Items) {
    var D = Items[d];
    var dClass = 'greenbg';
    if (D.state == 'Expired') {
      dClass = 'redbg';
    }
    if (D.state == 'Download') {
      dClass = 'yellowbg';
    }
    if (D.state == 'Expired' || D.state == 'Download') {
      update = true;
    }
    if ($('#MbSELECT-' + D.index).length != 0) {
      $('#MbCHK-' + D.index)
        .prop('checked', true)
        .checkboxradio('refresh');
      $('#MbSELECT-' + D.index).addClass(dClass);
    }
    if (D.index == 0 && $('#MbSELECT-0').length != 0) {
      $('#MbCHK-' + (Mbtiles_Data.length - 1))
        .prop('checked', true)
        .checkboxradio('refresh');
    }
  }
}

function showMbtileGrid() {
  //create status list of offline data;
  var datapacks = [];
  for (var o in MAPSTATE.Overviews) {
    var dataItem = MAPSTATE.Overviews[o];
    datapacks.push({
      state: dataItem.state,
      Pub: dataItem.data.Pub,
      Edition: dataItem.data.Edition,
      Name: dataItem.data.Name,
      Date: dataItem.data.Date,
      type: dataItem.data.type,
      index: dataItem.index,
      File: dataItem.File,
    });
  }
  var chartpacks = [];
  for (var c in MAPSTATE.AllCharts) {
    var chartItem = MAPSTATE.AllCharts[c].properties;
    chartpacks.push({
      state: chartItem.State,
      Pub: chartItem.Pub,
      Edition: chartItem.Edition,
      Name: chartItem.Name,
      Date: chartItem.Date,
      type: chartItem.type,
      index: chartItem.index,
      File: chartItem.File,
    });
  }
  localStorageDB.setItem('datapacks', JSON.stringify(datapacks));
  LOCALSTORAGE.datapacks = datapacks;
  localStorageDB.setItem('chartpacks', JSON.stringify(chartpacks));
  LOCALSTORAGE.chartpacks = chartpacks;
  popOfflineDataStatus();

  if (!MAPSTATE.ChartGrid) {
    return;
  }
  var blankCollection = {
    type: 'FeatureCollection',
    features: [],
  };
  for (var i in MAPSTATE.Charts) {
    if (!$('#T_TOPO').is(':checked') && MAPSTATE.Charts[i].properties.type == 'Terrain') {
      continue;
    }
    if (!$('#T_SAT').is(':checked') && MAPSTATE.Charts[i].properties.type == 'Satellite') {
      continue;
    }
    if (!$('#T_DEM_RGB').is(':checked') && MAPSTATE.Charts[i].properties.type == 'DEM_RGB') {
      continue;
    }
    blankCollection.features.push(MAPSTATE.Charts[i]);
  }

  if (!MAPSTATE.ChartSelect) {
    mapb.addSource('ChartSelect', {
      type: 'geojson',
      data: MBTILE_MASTER_VFR,
    });
    mapb.addLayer({
      id: 'ChartSelect',
      type: 'fill',
      source: 'ChartSelect',
      paint: {
        'fill-outline-color': '#000000',
        'fill-color': '#088',
        'fill-opacity': 0.2,
      },
    });

    mapb.addLayer({
      id: 'ChartSelect-outline',
      type: 'line',
      source: 'ChartSelect',
      paint: {
        'line-color': '#00FFFF',
        'line-opacity': 0.9,
        'line-width': 3,
        'line-blur': 2,
      },
    });

    mapb.addLayer({
      id: 'ChartSelect-hover',
      type: 'fill',
      source: 'ChartSelect',
      paint: {
        'fill-color': '#088',
        'fill-opacity': 0.4,
      },
    });

    mapb.addSource('ChartSelected', {
      type: 'geojson',
      data: blankCollection,
    });
    mapb.addLayer({
      id: 'ChartSelected',
      type: 'fill',
      source: 'ChartSelected',
      paint: {
        'fill-color': {
          property: 'State',
          type: 'categorical',
          stops: [
            ['Download', '#ffff00'],
            ['Downloaded', '#00ff00'],
            ['Expired', '#ff0000'],
          ],
        },
        'fill-opacity': 0.4,
      },
    });

    mapb.addLayer({
      id: 'ChartSelect-labels',
      type: 'symbol',
      source: 'ChartSelect',
      layout: {
        'symbol-placement': 'point',
        'text-field': '{Name}',
        'text-size': 10,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
    });

    mapb.on('mousemove', mousemoveChartSelect);
    mapb.on('click', clickChartSelect);
    mapb.on('mouseout', mouseoutChartSelect);
    MAPSTATE.ChartSelect = true;
  } else {
    mapb.getSource('ChartSelected').setData(blankCollection);
    mapb.getSource('ChartSelect').setData(MBTILE_MASTER_VFR);
  }
  MAPSTATE.LoadingMbTiles = false;
  if (mapb != null) {
    if (MBTILE_MASTER_VFR.features.length > 0) {
      var bbox = turf.bbox(MBTILE_MASTER_VFR);
      if (MAPSTATE.ChartGrid == true) {
        flyToMapBounds(bbox, 50);
      }
    } else {
      switch (MAPSTATE.OfflineCountry) {
        case 'Canada':
          flyToMapBounds(
            [
              [-141, 41],
              [-51, 84],
            ],
            50
          );
          break;
        case 'Indonesia':
          flyToMapBounds(
            [
              [94.7717124, -10.3599874813],
              [141.0194444, 5.47982086834],
            ],
            50
          );
          break;
        case 'USA':
          flyToMapBounds(
            [
              [-180, 25],
              [-65, 72],
            ],
            50
          );
          break;
        default:
          //Fit bounds to North america by default for now.
          flyToMapBounds(
            [
              [-180, 25],
              [-52, 84],
            ],
            50
          );
          break;
      }
    }
  }
}

function mousemoveChartSelect(e) {
  var features = mapb.queryRenderedFeatures(e.point, {
    layers: ['ChartSelect'],
  });
  if (features.length) {
    mapb.setFilter('ChartSelect-hover', ['==', 'id', features[0].properties.id]);
  } else {
    mapb.setFilter('ChartSelect-hover', ['==', 'id', '']);
  }
}

function clickChartSelect(e) {
  var features = mapb.queryRenderedFeatures(e.point, {
    layers: ['ChartSelect'],
  });
  if (features.length) {
    var fid = features[0].properties.id;
    var f = MAPSTATE.Charts[fid];
    if (f !== undefined) {
      //remove from MAPSTATE.Charts
      //check State if == Downloaded or Expired then prompt for confirmation
      var r = confirm('Removing offline Data: Are you sure');
      if (!r) {
        return;
      }
      delete MAPSTATE.Charts[fid];
      var PrimaryKey = features[0].properties.PrimaryKey;
      deleteMbtiles(PrimaryKey, features[0].properties.File);
    } else {
      //Add to MAPSTATE.Charts
      var newSelect = features[0].toJSON();
      newSelect.properties.State = 'Download';
      MAPSTATE.Charts[fid] = newSelect;
      var index = features[0].properties.index;
      if (features[0].properties.type == 'IFR Database') {
        if (!$('#MB_USA_IFR_Index').is(':checked')) {
          $('#MB_USA_IFR_Index').trigger('click');
          alert('Adding Required Other Data: [USA Terminal Procedures Index]');
        }
      }
      if (
        features[0].properties.Pub == 'CA_PDF' ||
        features[0].properties.Pub == 'US_PDF' ||
        features[0].properties.Pub == 'CA_CAP' ||
        features[0].properties.Pub == 'US_PDF' ||
        features[0].properties.Pub == 'US_IFR_Index'
      ) {
        if (!$('#MB_Airports').is(':checked')) {
          $('#MB_Airports').trigger('click');
          alert('Adding Required Other Data: [Airports]');
        }
      }
      if (features[0].properties.type == 'VFR' && !$('#MB_naoverview').is(':checked')) {
        $('#MB_naoverview').trigger('click');
        alert('Adding Required Other Data: [World VFR Overview]');
      }
      if (features[0].properties.type == 'Satellite' && !$('#MB_Sattellite_Overview').is(':checked')) {
        $('#MB_Sattellite_Overview').trigger('click');
        alert('Adding Required Other Data: [Satellite Overview]');
      }
      if (features[0].properties.type == 'Terrain' && !$('#MB_Topo_Overview_Tiles').is(':checked')) {
        $('#MB_Topo_Overview_Tiles').trigger('click');
        alert('Adding Required Other Data: [Topo Overview]');
      }
      queResourceMbtiles(index);
    }
    var newData = {
      type: 'FeatureCollection',
      features: [],
    };
    for (var i in MAPSTATE.Charts) {
      if (!$('#T_TOPO').is(':checked') && MAPSTATE.Charts[i].properties.type == 'Terrain') {
        continue;
      }
      if (!$('#T_SAT').is(':checked') && MAPSTATE.Charts[i].properties.type == 'Satellite') {
        continue;
      }
      if (!$('#T_DEM_RGB').is(':checked') && MAPSTATE.Charts[i].properties.type == 'DEM_RGB') {
        continue;
      }
      newData.features.push(MAPSTATE.Charts[i]);
    }
    mapb.getSource('ChartSelected').setData(newData);
  }
}

function mouseoutChartSelect(e) {
  mapb.setFilter('ChartSelect-hover', ['==', 'id', '']);
}

function updateOfflineStyle() {
  if (GROUP_DATA.MAPBOX_CODE != '' || GROUP_DATA.MAPBOX_STYLE != '') {
    removeCustomMapboxLayers('Custom');
  }
  removeMAPSTATESources(); //remove MAPSTATE.sources

  populateMAPSTATEcharts();
}

function populateMAPSTATEcharts() {
  MAPSTATE.Charts = []; //reset Charts mapstate var
  MAPSTATE.AllCharts = []; //reset Charts mapstate var
  var items = [];
  var CustomBGType = '';
  switch (MAPSTATE.CustomBG) {
    case 'satellite':
      CustomBGType = 'Satellite';
      break;
    case 'ifrhi':
      CustomBGType = 'IFR_HI';
      break;
    case 'ifrlow':
      CustomBGType = 'IFR_LOW';
      break;
    case 'vfr':
      CustomBGType = 'VFR';
      break;
  }
  if (window.openDatabases['naturalearth'] == undefined) {
    window.openDatabases['naturalearth'] = true;
  }
  cirroDB.query('Mbtiles', 'PrimaryKey != ?', '', function (e) {
    var Items = e;
    if (e === false) {
      Items = [];
    }
    for (var i in Items) {
      var d = Items[i];
      if (d.type == 'Satellite' || d.type == 'Terrain') {
        if (window.openDatabases[d.File] == undefined) {
          window.openDatabases[d.File] = true;
        }
      } else if (d.type == 'VFR') {
        if (window.openDatabases[d.File] == undefined) {
          window.openDatabases[d.File] = true;
        }
      } else {
        if (window.openDatabases[d.id] == undefined) {
          window.openDatabases[d.id] = true;
        }
      }

      if (
        d.type == MAPSTATE.Type ||
        d.coordinates == '' ||
        (d.type == 'Terrain' && MAPSTATE.Type == 'Night') ||
        (MAPSTATE.Type == 'Custom' && CustomBGType == d.type) ||
        (d.type == 'VFR' && MAPSTATE.Type == 'Heli') ||
        d.type == 'DEM_RGB'
      ) {
        updateMAPSTATE_CHARTS(d, null, d.Mbtiles);
      }
    }
    processOfflineStyle();
  });
}

function processOfflineStyle() {
  if (mapb == null) {
    return;
  }
  removeSVGoverlay(); //because overlay causes problems when swapping styles for some reason

  if (mapb.getSource('ONLINE') != undefined) {
    //mapb.removeLayer("ONLINE");
    RemoveOnlineLayers();
    mapb.removeSource('ONLINE');
  }
  //remove all other layers
  var CustomBGType = '';
  switch (MAPSTATE.CustomBG) {
    case 'satellite':
      CustomBGType = 'Satellite';
      break;
    case 'ifrhi':
      CustomBGType = 'IFR_HI';
      break;
    case 'ifrlow':
      CustomBGType = 'IFR_LOW';
      break;
    case 'vfr':
      CustomBGType = 'VFR';
      break;
  }
  if (mapb.getSource('naturalearth') == undefined) {
    mapb.addSource('naturalearth', {
      type: 'vector',
      tiles: ['{z}/{x}/{y}'],
      mbtiles: true,
      maxzoom: 8,
      minzoom: 0,
    });
    if (mapb.getLayer('naturalearth') != undefined) {
      mapb.removeLayer('naturalearth');
    }
    mapb.addLayer(
      {
        id: 'naturalearth',
        type: 'line',
        source: 'naturalearth',
        'source-layer': 'ne_110m_admin_0_countries_lakes',
        minzoom: 0,
        paint: {
          'line-color': '#ff0000',
        },
      },
      'RasterBefore'
    );
  }
  if (MAPSTATE.Type == 'Satellite' || (MAPSTATE.Type == 'Custom' && CustomBGType == 'Satellite')) {
    var HighResSat = false;
    if (MAPSTATE.Overviews.Sattellite_Overview != undefined) {
      if (MAPSTATE.Overviews.Sattellite_Overview.state == 'Downloaded') {
        HighResSat = true;
      }
    }

    if (mapb.getSource('Satellite') == undefined && !HighResSat) {
      if (mapb.getLayer('Satellite') != undefined) {
        mapb.removeLayer('Satellite');
      }
      MAPSTATE.Sources['Satellite'] = true;
      mapb.addSource('Satellite', {
        type: 'raster',
        tiles: ['{z}/{x}/{y}'],
        tileSize: 256,
        maxzoom: 5,
        minzoom: 0,
        mbtiles: true,
      });

      mapb.addLayer(
        {
          id: 'Satellite',
          type: 'raster',
          source: 'Satellite',
          minzoom: 0,
          maxzoom: 11,
          layout: {
            visibility: 'visible',
          },
        },
        'RasterBefore'
      );
    } else {
      removeLayersAndSource('Satellite');
    }
  } else {
    removeLayersAndSource('Satellite');
  }
  for (var i in MAPSTATE.Overviews) {
    var d = MAPSTATE.Overviews[i];
    if (d.data.type.indexOf('Overview') !== -1) {
      //remove other layers before adding different overview layers
      removeLayersAndSource(d.data.File);
    }
    if (
      d.data.type == MAPSTATE.Type + ' Overview' ||
      (MAPSTATE.Type == 'Night' && d.data.type == 'Terrain Overview') ||
      (MAPSTATE.Type == 'Custom' && d.data.id == 'CUSTOM') ||
      (d.data.type == 'VFR Overview' && MAPSTATE.Type == 'Heli')
    ) {
      MAPSTATE.Sources[d.data.File] = true;
      if (MAPSTATE.Type == 'Custom' && d.data.id == 'CUSTOM') {
        if (GROUP_DATA.MAPBOX_TYPE == 'raster') {
          mapb.addSource(d.data.File, {
            type: 'raster',
            tiles: ['{z}/{x}/{y}'],
            tileSize: 256,
            maxzoom: GROUP_DATA.MAPBOX_MAXZOOM,
            minzoom: GROUP_DATA.MAPBOX_MINZOOM,
            mbtiles: true,
          });
          mapb.addLayer(
            {
              id: 'ONLINE',
              type: 'raster',
              source: d.data.File,
              minzoom: 0,
              maxzoom: 20,
              layout: {
                visibility: 'visible',
              },
            },
            'CustomLayerBefore'
          );
        } else {
          if (mapb.getSource(d.data.File) == undefined) {
            mapb.addSource(d.data.File, {
              type: 'vector',
              tiles: ['{z}/{x}/{y}'],
              mbtiles: true,
            });
          }
          var Layers = getMapboxCustomLayers(d.data.File);

          for (var i in Layers) {
            var L = Layers[i];
            mapb.addLayer(L, 'CustomLayerBefore');
          }
          LoadCustomerLayerScripts();
          //Add Satellite / VFR / IFR background layers if included in custom style
        }
      }
      if (
        d.data.File.substring(0, 4) == 'Topo' &&
        d.data.File.substr(d.data.File.length - 5) == 'Tiles' &&
        (MAPSTATE.Type == 'Terrain' || MAPSTATE.Type == 'Night')
      ) {
        //Can not be combined with custom raster or vecor layers
        var TopoProperties = { properties: d.data };
        addOfflineVectorLayerAndSource(TopoProperties);
      }

      var MaxZoom = 7;
      if (
        MAPSTATE.Type == 'IFR_LOW' ||
        MAPSTATE.Type == 'IFR_HIGH' ||
        (MAPSTATE.Type == 'Custom' && (CustomBGType == 'IFR_LOW' || CustomBGType == 'IFR_HIGH')) ||
        (d.data.type == 'VFR Overview' && MAPSTATE.Type == 'Heli')
      ) {
        switch (d.data.File) {
          case 'USA_IFR_Low':
            MaxZoom = 10;
            break;
          case 'USA_IFR_Low_Alaska':
            MaxZoom = 9;
            break;
          case 'CA_IFR_LO':
            MaxZoom = 9;
            break;
          case 'USA_IFR_High':
            MaxZoom = 9;
            break;
          case 'USA_IFR_High_Alaska':
            MaxZoom = 9;
            break;
          case 'CA_IFR_HI':
            MaxZoom = 9;
            break;
          case 'USA_HeliRoutes':
            MaxZoom = 12;
            break;
        }
      }
      if (
        (d.data.File == 'Satellite_Overview' && MAPSTATE.Type == 'Satellite') ||
        (d.data.File == 'Satellite_Overview' && MAPSTATE.Type == 'Custom' && CustomBGType == 'Satellite')
      ) {
        MaxZoom = 8;
        if (mapb.getSource(d.data.File) == undefined) {
          mapb.addSource(d.data.File, {
            type: 'raster',
            tiles: ['{z}/{x}/{y}'],
            tileSize: 256,
            maxzoom: MaxZoom,
            minzoom: 0,
            mbtiles: true,
          });
        }
        if (mapb.getLayer(d.data.File) != undefined) {
          mapb.removeLayer(d.data.File);
        }
        mapb.addLayer(
          {
            id: d.data.File,
            type: 'raster',
            source: d.data.File,
            minzoom: 0,
            maxzoom: 20,
            layout: {
              visibility: 'visible',
            },
          },
          'RasterBefore'
        );
      }
      if (
        (d.data.File == 'INDO_VFR' && MAPSTATE.Type == 'VFR') ||
        (d.data.File == 'INDO_VFR' && MAPSTATE.Type == 'Custom' && CustomBGType == 'VFR')
      ) {
        MaxZoom = 11;
        if (mapb.getSource(d.data.File) == undefined) {
          mapb.addSource(d.data.File, {
            type: 'raster',
            tiles: ['{z}/{x}/{y}'],
            tileSize: 256,
            maxzoom: MaxZoom,
            minzoom: 0,
            mbtiles: true,
          });
        }
        if (mapb.getLayer(d.data.File) != undefined) {
          mapb.removeLayer(d.data.File);
        }
        mapb.addLayer(
          {
            id: d.data.File,
            type: 'raster',
            source: d.data.File,
            minzoom: 0,
            maxzoom: 20,
            layout: {
              visibility: 'visible',
            },
          },
          'RasterBefore'
        );
      }
      if (
        (d.data.type == MAPSTATE.Type + ' Overview' && d.data.id != 'CUSTOM' && MAPSTATE.Type != 'Terrain') ||
        (MAPSTATE.Type == 'Custom' && d.data.type == CustomBGType + ' Overview') ||
        (d.data.type == 'VFR Overview' && MAPSTATE.Type == 'Heli')
      ) {
        if (mapb.getSource(d.data.File) == undefined) {
          mapb.addSource(d.data.File, {
            type: 'raster',
            tiles: ['{z}/{x}/{y}'],
            tileSize: 256,
            maxzoom: MaxZoom,
            minzoom: 0,
            mbtiles: true,
          });
        }
        if (mapb.getLayer(d.data.File) != undefined) {
          mapb.removeLayer(d.data.File);
        }
        if (d.data.id == 'USA_HeliRoutes') {
          mapb.addLayer(
            {
              id: d.data.File,
              type: 'raster',
              source: d.data.File,
              minzoom: 0,
              maxzoom: 12,
              layout: {
                visibility: 'visible',
              },
            },
            'TopLevelOverview'
          );
        } else {
          mapb.addLayer(
            {
              id: d.data.File,
              type: 'raster',
              source: d.data.File,
              minzoom: 0,
              maxzoom: 11,
              layout: {
                visibility: 'visible',
              },
            },
            'RasterBefore'
          );
        }
      }
    }
  }
  var curStyle = mapb.getStyle();
  for (var I in MAPSTATE.Overviews) {
    var b = MAPSTATE.Overviews[I];
    if (b.data.type == 'Diagrams') {
      //mapb.removeSource(b.data.File);
      var newsource = null;
      if (b.data.File == 'Airports') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 7,
        };
      }
      if (b.data.File == 'NOTAMS') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 7,
        };
      }
      if (b.data.File == 'Obstacles') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 10,
        };
      }
      if (b.data.File == 'US_Airspace') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 7,
        };
      }
      if (b.data.File == 'CA_Airspace') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 7,
        };
      }
      if (b.data.File == 'CA_Diagrams') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
        };
      }
      if (b.data.File == 'US_Diagrams') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
        };
      }
      if (b.data.File == 'MNR_Grid') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 8,
        };
      }
      if (b.data.File == 'ATS_Grid') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
          maxzoom: 9,
        };
      }
      if (b.data.File == 'Lightning') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          maxzoom: 10,
          mbtiles: true,
        };
      }
      if (b.data.File == 'Fires') {
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          maxzoom: 10,
          mbtiles: true,
        };
      }
      if (newsource == null) {
        continue; //couldn't match known source so don't add it
        newsource = {
          type: 'vector',
          tiles: ['{z}/{x}/{y}'],
          mbtiles: true,
        };
      }
      curStyle.sources[b.data.File] = newsource;
    }
  }

  //Navaids is packaged with Cirro so it should always be available
  curStyle.sources.Navaids = {
    type: 'vector',
    tiles: ['{z}/{x}/{y}'],
    maxzoom: 7,
    mbtiles: true,
  };
  mapb.setStyle(curStyle);
  setTerrain('Offline');
  MAPSTATE.OfflineStyle = curStyle;
  //mapb.setStyle(MAPSTATE.OfflineStyle);
  mapb.on('dragend', setOfflineStyle);
  mapb.on('zoomend', zoomEnd_setOfflineStyle);
  setTimeout(function () {
    setOfflineStyle();
  }, 500); //mapb.getBounds() is not returning correct values if called immediately
}

var zoomEndTimer = null;

function zoomEnd_setOfflineStyle() {
  //This is to stop iOS devices from continuously calling zoomend makes performance really suck
  if (zoomEndTimer != null) {
    clearTimeout(zoomEndTimer);
  }
  zoomEndTimer = setTimeout(function () {
    zoomEndTimer = null;
    setOfflineStyle();
  }, 500);
}

function setOfflineStyle() {
  var bounds = mapb.getBounds();
  bounds = turf.bboxPolygon([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]);
  var zoom = mapb.getZoom();
  var LoadedTopo = false;
  for (var i in MAPSTATE.Charts) {
    var c = MAPSTATE.Charts[i];
    try {
      var IsTopo = false;
      var IsDEM_RGB = false;
      if (c.properties.type == 'DEM_RGB') {
        IsDEM_RGB = true;
        continue;
      }
      if (
        c.properties.File.substring(0, 4) == 'Topo' &&
        c.properties.File.substr(c.properties.File.length - 5) == 'Tiles'
      ) {
        IsTopo = true;
      }
      if (turf.intersect(c, bounds) !== null || zoom < 7) {
        if (IsTopo) {
          LoadedTopo = true;
        }

        if (zoom >= 7 && zoom <= 20) {
          MAPSTATE.Sources[c.properties.File] = true;
          if (IsTopo) {
            if (mapb.getSource(c.properties.File) != undefined) {
              setmaxTopoOverviewZoom(true);
            } //if loading offline topo then set maxzoom of overview layer if it checkIfFileExists
            addOfflineVectorLayerAndSource(c);
          } else {
            if (mapb.getSource(c.properties.File) == undefined) {
              if (c.properties.File.substr(c.properties.File.length - 5) == 'Tiles') {
                //Must be offline satellite tiles
                mapb.addSource(c.properties.File, {
                  type: 'raster',
                  tiles: ['{z}/{x}/{y}'],
                  tileSize: 256,
                  maxzoom: 13,
                  minzoom: 9,
                  mbtiles: true,
                });
                mapb.addLayer(
                  {
                    id: c.properties.File,
                    type: 'raster',
                    source: c.properties.File,
                    minzoom: 9,
                    maxzoom: 20,
                    layout: {
                      visibility: 'visible',
                    },
                  },
                  'RasterBefore'
                );
              } else {
                mapb.addSource(c.properties.File, {
                  type: 'raster',
                  tiles: ['{z}/{x}/{y}'],
                  tileSize: 256,
                  maxzoom: 11,
                  minzoom: 7,
                  mbtiles: true,
                });
                mapb.addLayer(
                  {
                    id: c.properties.File,
                    type: 'raster',
                    source: c.properties.File,
                    minzoom: 7,
                    maxzoom: 20,
                    layout: {
                      visibility: 'visible',
                    },
                  },
                  'RasterBefore'
                );
              }
            }
          }
        } else {
          MAPSTATE.Sources[c.properties.File] = false;
          removeLayersAndSource(c.properties.File);
        }
      } else {
        MAPSTATE.Sources[c.properties.File] = false;
        removeLayersAndSource(c.properties.File); //this method in menu-mapb.js  Removes multiple layers if required
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (zoom < 7 || !LoadedTopo) {
    setmaxTopoOverviewZoom(false);
  } //clear topo overview max zoom levels
}

function addOfflineVectorLayerAndSource(c) {
  if (mapb.getSource(c.properties.File) != undefined) {
    return;
  }
  mapb.addSource(c.properties.File, {
    type: 'vector',
    tiles: ['{z}/{x}/{y}'],
    mbtiles: true,
    maxzoom: c.properties.File == 'Topo_Overview_Tiles' ? 7 : 14,
    minzoom: 0,
  });
  var Layers = [];
  if (MAPSTATE.Type == 'Terrain') {
    if (c.properties.File == 'Topo_Overview_Tiles') {
      mapb.addLayer(
        {
          id: c.properties.File + 'background',
          type: 'background',
          paint: {
            'background-color': '#f8f4f0',
          },
        },
        'RasterBefore'
      );
    }
    Layers = getMapboxTopoLayers(c.properties.File);
  }
  if (MAPSTATE.Type == 'Night') {
    if (c.properties.File == 'Topo_Overview_Tiles') {
      mapb.addLayer(
        {
          id: c.properties.File + 'background',
          type: 'background',
          paint: {
            'background-color': 'rgb(12,12,12)',
          },
        },
        'RasterBefore'
      );
    }
    Layers = getMapboxDarkLayers(c.properties.File);
  }
  for (var L in Layers) {
    mapb.addLayer(Layers[L], 'RasterBefore');
  }
}

var MaxZoomSet = false;

function setmaxTopoOverviewZoom(doit) {
  if (mapb.getSource('Topo_Overview_Tiles') == undefined) {
    return;
  } //we dont'have a topo overlay layer
  var Layers = [];
  if (MAPSTATE.Type == 'Terrain') {
    Layers = getMapboxTopoLayers('Topo_Overview_Tiles');
  }
  if (MAPSTATE.Type == 'Night') {
    Layers = getMapboxDarkLayers('Topo_Overview_Tiles');
  }
  if (doit && !MaxZoomSet) {
    MaxZoomSet = true;
    //set the max topo setZoom
    for (var L in Layers) {
      if (mapb.getLayer(Layers[L].id) != undefined) {
        mapb.setLayerZoomRange(Layers[L].id, Layers[L].minzoom, 7);
      }
    }
  } else {
    if (MaxZoomSet != false) {
      MaxZoomSet = false;
      //restore original layer TopoProperties
      for (var L in Layers) {
        if (mapb.getLayer(Layers[L].id) != undefined) {
          mapb.setLayerZoomRange(Layers[L].id, Layers[L].minzoom, Layers[L].maxzoom);
        }
      }
    }
  }
}

function OfflineDataOption(e, i) {
  if (e.checked) {
    var I = Mbtiles_Data[i];
    $("label[for='MB_" + I.id + "']").addClass('yellowbg');
    queResourceMbtiles(i);
    //setTimeout and check Airports if it is needed
    if (I.Pub == 'CA_PDF' || I.Pub == 'US_PDF' || I.Pub == 'CA_CAP' || I.Pub == 'US_PDF' || I.Pub == 'US_IFR_Index') {
      if (!$('#MB_Airports').is(':checked')) {
        setTimeout(function () {
          alert('Adding Required Other Data: [Airports]');
          $('#MB_Airports').trigger('click');
        }, 1000);
      }
    }
  } else {
    //pop warning dialog
    var r = confirm('Removing offline Data: Are you sure');
    if (!r) {
      $('#MB_' + Mbtiles_Data[i].id)
        .attr('checked', true)
        .checkboxradio('refresh');
      return;
    }
    var item = Mbtiles_Data[i];
    $("label[for='MB_" + Mbtiles_Data[i].id + "']").removeClass('yellowbg');
    $("label[for='MB_" + Mbtiles_Data[i].id + "']").removeClass('redbg');
    $("label[for='MB_" + Mbtiles_Data[i].id + "']").removeClass('greenbg');
    deleteMbtiles(item.PrimaryKey, item.File);
  }
  if ($('#MbSELECT-' + i).length != 0) {
    if (e.checked) {
      $('#MbSELECT-' + i).addClass('yellowbg');
    } else {
      $('#MbSELECT-' + i).removeClass('yellowbg');
      $('#MbSELECT-' + i).removeClass('redbg');
      $('#MbSELECT-' + i).removeClass('greenbg');
    }
  }
}

function getTerrainMbtileFile(zoom, xtile, ytile) {
  //https://api.mapbox.com/v4/mapbox.satellite/12/1034/1418.webp
  const pi = 3.14159265358979;
  let n = Math.pow(2, zoom);
  let lon_deg = (xtile / n) * 360.0 - 180.0;
  let lat_rad = Math.atan(Math.sinh(pi * (1 - (2 * ytile) / n)));
  let lat_deg = (lat_rad * 180.0) / pi;
  return 'DEM_RGB_' + getUTM_Zone(lat_deg, lon_deg);
}

function getUTM_Zone(lat, lon) {
  let zone = Math.floor((lon + 180.0) / 6) + 1;
  let Lzone = '';
  if (-90 < lat && lat <= -80 && lon >= 0) Lzone = 'A';
  if (-90 < lat && lat <= -80 && lon < 0) Lzone = 'B';
  if (-80 < lat && lat <= -72) Lzone = 'C';
  if (-72 < lat && lat <= -64) Lzone = 'D';
  if (-64 < lat && lat <= -56) Lzone = 'E';
  if (-56 < lat && lat <= -48) Lzone = 'F';
  if (-48 < lat && lat <= -40) Lzone = 'G';
  if (-40 < lat && lat <= -32) Lzone = 'H';
  if (-32 < lat && lat <= -24) Lzone = 'J';
  if (-24 < lat && lat <= -16) Lzone = 'K';
  if (-16 < lat && lat <= -8) Lzone = 'L';
  if (-8 < lat && lat <= 0) Lzone = 'M';
  if (0 < lat && lat <= 8) Lzone = 'N';
  if (8 < lat && lat <= 16) Lzone = 'P';
  if (16 < lat && lat <= 24) Lzone = 'Q';
  if (24 < lat && lat <= 32) Lzone = 'R';
  if (32 < lat && lat <= 40) Lzone = 'S';
  if (40 < lat && lat <= 48) Lzone = 'T';
  if (48 < lat && lat <= 56) Lzone = 'U';
  if (56 < lat && lat <= 64) Lzone = 'V';
  if (64 < lat && lat <= 72) Lzone = 'W';
  if (72 < lat && lat <= 84) Lzone = 'X';
  if (84 < lat && lat <= 90 && lon >= 0) Lzone = 'Y';
  if (84 < lat && lat <= 90 && lon < 0) Lzone = 'Z';

  return zone + Lzone;
}
