SAMA.enums.TEXTLENGTHS = {
  Tiny: 30,
  Small: 60,
  Medium: 120,
  Large: 256,
  Massive: 30000,
};

SAMA.enums.VALIDATORS = {
  STRING: 0,
  DATE: 1,
  TIME: 2,
  NUMBER: 3,
  KEYWORD: 4,
  CATEGORY: 5,
};

class cSAMA_Validator {
  /**
   * @param {int}             _options.type             The validation type
   * @param {string|null}    [_options.tagName]         When type = keyword, uses this to describe the items we're
   *   looking for.
   * @param {int|null}       [_options.minTags]         When type = keyword, check number of tags isn't below this. Can
   *   be null to set no minimum.
   * @param {int|null}       [_options.maxTags]         When type = keyword, check number of tags doesn't exceed this.
   *   Can be null to set no maximum.
   * @param {boolean|null}   [_options.mustBeInteger]   When type = number, check that the value has no decimals.
   * @param {int|null}       [_options.minimum]         When type = number, can be null to set no minimum.
   * @param {int|null}       [_options.maximum]         When type = number, can be null to set no maximum.
   * @param {int|null}       [_options.maxLength]       When type = string, _options must contain maxLength and may
   *   contain minLength
   * @param {int|null}       [_options.minLength]       When type = string, _options must contain maxLength and may
   *   contain minLength
   * @param {boolean|null}   [_options.future]          When type = date. If true, will force dates to be in the
   *   future. If false, will force dates to be in the past
   * @param {boolean|null}   [_options.required]        If true, requires the value of the field not be empty. True by
   *   default.
   * @param {string[]}       [_options.mustNotContain]  If set, will explore the given input for any of the strings in
   *   this array and error out if they are present
   */
  constructor(_options) {
    this.options = _options;
    this.errorIDs = {};
    this.errorDom = {};

    // Required defaults to true.
    this.options.required = this.options.required == null ? true : this.options.required;

    if (_options.type === SAMA.enums.VALIDATORS.STRING) {
      if (this.options.maxLength == null) {
        throw "SAMA: Can't validate a string with an infinite maximum length";
      }

      this.options.minLength = this.options.minLength || -1;
    }

    if (_options.type === SAMA.enums.VALIDATORS.DATE) {
      if (this.options.future == null) {
        throw "SAMA: Can't validate a date without an indicated restriction.";
      }
    }
  }

  /**
   * Runs the validation on the validate object and shows an error to the user.
   * @param {string} _target
   * @param {jQuery} _input
   * @param {string} _prop
   * @return {boolean}
   */

  Validate(_target, _input, _prop) {
    let r = this.ValidateInput(_target, _input);

    if (r.valid) {
      // Valid, but previous error, so we're removing the lock & message
      this.Unvalidate(_input, _prop);
    } else if (!r.valid) {
      if (this.errorIDs[_prop] == null) {
        // Not valid and not displaying, so we're adding the lock & message
        this.errorIDs[_prop] = SAMA.GeneralGui.LockPageState(_prop + '.ERROR');
      }
      this.SetMessage(_input, r.message, _prop);
    }

    return r.valid;
  }

  ValidateInput(_target, _input) {
    let r = {
      valid: false,
      message: 'Validation Error',
    };

    switch (this.options.type) {
      case SAMA.enums.VALIDATORS.STRING:
        r = this.StringValidator(_target);
        break;

      case SAMA.enums.VALIDATORS.DATE:
        r = this.DateValidator(_target);
        break;

      case SAMA.enums.VALIDATORS.TIME:
        r = this.TimeValidator(_target);
        break;

      case SAMA.enums.VALIDATORS.NUMBER:
        r = this.NumberValidator(_target, _input);
        break;

      case SAMA.enums.VALIDATORS.KEYWORD:
        r = this.KeywordValidator(_target, _input);
        break;

      case SAMA.enums.VALIDATORS.CATEGORY:
        r = this.CategoryValidator(_target, _input);
        break;

      default:
        throw 'SAMA: Unknown validation type';
    }

    return r;
  }

  /**
   * Forcefully ends validation of a property.
   * @param _input
   * @param _prop
   */

  Unvalidate(_input, _prop) {
    if (this.errorIDs[_prop] == null) {
      // Don't have a previous error
      return;
    }

    if (_input != null) {
      this.ClearMessage(_input, _prop);
    }
    SAMA.GeneralGui.UnlockPageState(this.errorIDs[_prop]);
    this.errorIDs[_prop] = null;
  }

  /**
   * Sets the error message for _input
   * @param {jQuery} _input
   * @param {string} _message
   * @param _prop
   */
  SetMessage(_input, _message, _prop) {
    let columnDiv = _input.closest('[class*=col]');

    let error = this.errorDom[_prop];
    if (error == null) {
      this.errorDom[_prop] = error = $('<div />').addClass('sama-form-error sama-form-error-message').hide();
    }

    if(_input.is('select'))
    {
      const closestParentElement = _input.closest('.form-select-container');

      if (closestParentElement.length > 0) {
        closestParentElement.addClass('sama-form-error');
      }
    }else{
      _input.addClass('sama-form-error');
    }

    columnDiv.append(error.stop(true, true).slideDown(100));
    error.text(_message);
  }

  /**
   * Remove the message from _input
   * @param _input
   * @param _prop
   */
  ClearMessage(_input, _prop) {
    let error = this.errorDom[_prop];
    if (error != null) {
      error.stop(true, true).slideUp(100, () => {
        error.remove();
        this.errorDom[_prop] = error = null;
      });
    }

    if(_input.is('select')){
      const closestParentElement = _input.closest('.form-select-container');

      if (closestParentElement.length > 0) {
        closestParentElement.removeClass('sama-form-error');
      }
    }

    _input.removeClass('sama-form-error');
  }

  /**
   * Gets the name of a digit between 0-9
   * @param _num
   * @return {string}
   */
  static DigitName(_num) {
    if (_num > 9) {
      return _num;
    }

    const digitNames = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

    return digitNames[_num];
  }

  /**
   * Runs Date validation
   * @param {string} _target
   * @return {{valid:boolean,[message]:string}}
   */
  DateValidator(_target) {
    if (_target == null || _target.length <= 0) {
      if (this.options.required) {
        return {
          valid: false,
          message: 'This date is required.',
        };
      } else {
        // If the date isn't required and isn't given, we DGAF
        return {
          valid: true,
        };
      }
    }

    let targetDate = moment(_target, 'YYYY-MM-DD');
    let today = moment();

    if (!targetDate.isValid()) {
      return {
        valid: false,
        message: 'Must be a valid date',
      };
    }

    if (this.options.future && targetDate.isBefore(today, 'day')) {
      return {
        valid: false,
        message: 'Date must be today or in the future.',
      };
    } else if (!this.options.future && targetDate.isAfter(today, 'day')) {
      return {
        valid: false,
        message: 'Date must be today or in the past.',
      };
    }

    return {
      valid: true,
    };
  }

  /**
   * Runs Time validation
   * @param {string} _target
   * @return {{valid:boolean,[message]:string}}
   */
  TimeValidator(_target) {
    // Ensure that _target is a string.
    _target = '' + _target;

    if (_target.length <= 0 && this.options.required) {
      return {
        valid: false,
        message: 'This time is required.',
      };
    }

    if (_target.length <= 0 && this.options.required) {
      return {
        valid: false,
        message: 'This time is required.',
      };
    }

    let bits = _target.split(':');
    let t = {
      h: +bits[0],
      m: +bits[1],
      s: +bits[2],
    };

    if (bits.length < 2 || t.h < 0 || t.h > 23 || t.m < 0 || t.m > 59 || (t.s != null && (t.s < 0 || t.s > 59))) {
      // Check seconds if they're there
      return {
        valid: false,
        message: 'Must be a valid HH:mm or HH:mm:ss time',
      };
    }

    let targetTime = moment();
    targetTime.hours(t.h);
    targetTime.minutes(t.m);

    if (t.s != null) {
      targetTime.seconds(t.s);
    }

    if (!targetTime.isValid()) {
      return {
        valid: false,
        message: 'Must be a valid HH:mm or HH:mm:ss time',
      };
    }

    return {
      valid: true,
    };
  }

  /**
   * Runs string validation
   * @param {string} _target
   * @return {{valid:boolean,[message]:string}}
   */
  StringValidator(_target) {
    if (_target == null || _target.length <= 0) {
      if (this.options.required) {
        return {
          valid: false,
          message: 'Must be at least % characters long.'.tr(cSAMA_Validator.DigitName(this.options.minLength)),
        };
      } else {
        return {
          valid: true,
        };
      }
    }

    let maximalTaget = String(_target)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;'); // HTMLEntities count as multiple characters...
    let minimalTarget = maximalTaget.replace(/\s/g, ''); // No spaces for minimum checking.

    if (minimalTarget.length < this.options.minLength) {
      return {
        valid: false,
        message: 'Must be at least % characters long.'.tr(cSAMA_Validator.DigitName(this.options.minLength)),
      };
    } else if (maximalTaget.length > this.options.maxLength) {
      return {
        valid: false,
        message: 'Must be at most % characters long when encoded.'.tr(
          cSAMA_Validator.DigitName(this.options.maxLength)
        ),
      };
    }

    if (Array.isArray(this.options.mustNotContain)) {
      for (let i = 0; i < this.options.mustNotContain.length; i++) {
        if (this.options.mustNotContain[i] == null || this.options.mustNotContain[i].trim().length <= 0) {
          this.options.mustNotContain.splice(i, 1);
        }
      }
    }

    if (this.options.mustNotContain != null && this.options.mustNotContain.length > 0) {
      let pattern;
      if (this.options.mustNotContain === 'WORDONLY') {
        pattern = '[.,!@#$%^&();\\/|<>"\':*+=?]';
      } else {
        pattern = '\\b(' + this.options.mustNotContain.join('|') + ')\\b';
      }

      let r = new RegExp(pattern, 'ig');
      let results = _target.match(r);
      if (results != null && results.length > 0) {
        return {
          valid: false,
          message: 'Must not contain: %.'.tr(results.join(', ')),
        };
      }
    }

    return {
      valid: true,
    };
  }

  /**
   * Runs Number validation
   * @param {string} _target
   * @param {jQuery} _input
   * @return {{valid:boolean,[message]:string}}
   */
  NumberValidator(_target, _input) {
    let inVal = +_target;

    if (this.options.minimum != null && inVal < this.options.minimum) {
      if (this.options.minimum === 0) {
        return {
          valid: false,
          message: 'Must not contain negative numbers.',
        };
      }

      return {
        valid: false,
        message: 'Must be larger than %.'.tr(cSAMA_Validator.DigitName(this.options.minimum)),
      };
    }

    if (this.options.maximum != null && inVal > this.options.maximum) {
      if (this.options.maximum === 0) {
        return {
          valid: false,
          message: 'Must be negative.',
        };
      }

      return {
        valid: false,
        message: 'Must be less than %.'.tr(cSAMA_Validator.DigitName(this.options.maximum)),
      };
    }

    // Modulus will be larger than 0 if inval is decimal
    if (this.options.mustBeInteger === true && inVal % 1 > 0) {
      return {
        valid: false,
        message: 'Must be a whole number.',
      };
    }

    return {
      valid: true,
    };
  }

  /**
   * Runs Keyword validation
   * @param {string} _target
   * @param {jQuery} _input
   * @return {{valid:boolean,[message]:string}}
   */
  KeywordValidator(_target, _input) {
    let inCount = 0;
    try {
      inCount = _input.catTagger('getSelectedTags')[0].length;
    } catch (_e) {
      if (this.options.required) {
        // Got an uninitialized tagger, whoops!
        return {
          valid: false,
          message: 'Must not be empty.',
        };
      }
    }

    this.options.tagName = this.options.tagName == null ? 'tag' : this.options.tagName;

    let sNos = this.options.minTags > 1 ? 's' : '';
    if (this.options.minTags != null && inCount < this.options.minTags) {
      return {
        valid: false,
        message: 'Must contain at least % %%.'.tr(
          cSAMA_Validator.DigitName(this.options.minTags),
          this.options.tagName,
          sNos
        ),
      };
    }

    sNos = this.options.maxTags > 1 ? 's' : '';
    if (this.options.maxTags != null && inCount > this.options.maxTags) {
      return {
        valid: false,
        message: "Can't contain more than % %%.".tr(
          cSAMA_Validator.DigitName(this.options.maxTags),
          this.options.tagName,
          sNos
        ),
      };
    }

    return {
      valid: true,
    };
  }

  /**
   * Runs Category validation
   * @param {int} _target
   * @param {jQuery} _input
   * @return {{valid:boolean,[message]:string}}
   */
  CategoryValidator(_target, _input)
  {
    const inVal = Number(_target);

    if (this.options.required && inVal <= 0)
    {
      return {
        valid  : false,
        message: 'Must select a category',
      }
    }

    return {
      valid: true,
    };
  }
}
