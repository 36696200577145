/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

let CLIENT_DATA;

function ManageClients() {
  $.mobile.changePage($('#adminClients'), {
    reverse: false,
    changeHash: false,
  });
  popAdminClients();
}

function exitManageClients() {
  VueBridge.enterCorrectAdminToolMenu();
}

function popAdminClients() {
  if (AutoCheck === false) {
    fetchClientData();
    return;
  }
  let autoCheckTimer = setInterval(function () {
    if (AutoCheck === true) {
      return false;
    }
    clearInterval(autoCheckTimer);
    fetchClientData();
  }, 50);
}

function fetchClientData() {
  $.getJSON(BASEURL + 'menu-Administration-Users-AJAX.php?action=getClients', function (json_data) {
    if (json_data[0].ServerResponse != null) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse === 'Uncaught Exception') {
        ServerResponse(json_data[0], 'retrieveTemplate()');
      }
      if (json_data[0].ServerResponse === 'Not Logged In') {
        NOTLOGGEDIN();
      }
      if (json_data[0].ServerResponse === 'Offline') {
        $.mobile.changePage($('#MainMenu'), {
          reverse: true,
          changeHash: false,
        });
      }
      if (json_data[0].ServerResponse === 'None') {
        //there are no templates in the system
        $('#FTcontent').html('<h2 class="limit-table">There are no clients or enterprises for your group</h2>');
      }
    } else {
      let clients = json_data;
      CLIENT_DATA = clients;
      console.log('Received Client List');
      console.log(json_data);
      let html =
        '<table class="limit-table last-td-fill"><thead><tr><th>Name</th><th>Sharing</th><th>Action</th></tr></thead>';
      for (let i in clients) {
        if (!clients.hasOwnProperty(i)) {
          continue;
        }

        status = clients[i].Enabled > 0 ? 'Enabled' : 'Disabled';

        html += `<tr>
    <td>${clients[i].Client}</td>
    <td>${status}</td>
    <td>
        <div class="vertical-center-container">
            <button class="action-btn" value="${i}" onclick="updateAdminClients(${i})">
            <img src="./images/delete.svg" />
            </button>
        </div>
    </td>
</tr>`;
      }

      html += '</table>';

      $('#ClientAdmin').html(html);
    }
  }).fail(function () {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Connection Error',
      headerClose: false,
      buttonPrompt: 'There was an error Retrieving the data please try again.',
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}

function updateAdminClients(i) {
  let c = CLIENT_DATA[i];
  var PK = c.PrimaryKey;
  let EN = +c.Enabled === 0 ? 1 : 0;

  $.getJSON(
    BASEURL + 'menu-Administration-Users-AJAX.php?action=setClients&PK=' + PK + '&Enabled=' + EN,
    function (json_data) {
      if (json_data[0].ServerResponse != null) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse === 'Uncaught Exception') {
          ServerResponse(json_data[0], 'retrieveTemplate()');
        }
        if (json_data[0].ServerResponse === 'Not Logged In') {
          NOTLOGGEDIN();
        }
        if (json_data[0].ServerResponse === 'Offline') {
          $.mobile.changePage($('#MainMenu'), {
            reverse: true,
            changeHash: false,
          });
        }
        if (json_data[0].ServerResponse === 'Success') {
          //there are no templates in the system
          popAdminClients();
          syncOfflineTrackingClients();
        }
      }
    }
  ).fail(function () {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Connection Error',
      headerClose: false,
      buttonPrompt: 'There was an error updating the data please try again.',
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}
