class InAppConsole {
  domContainer;

  open = true;

  constructor() {
    this.#createDom();
    this.#shadowFunctions();
  }

  #shadowFunctions() {
    console.olog = () => {};
    console.oerror = () => {};
    console.owarn = () => {};

    // noinspection PointlessBooleanExpressionJS << In this case, the double check is actually sensible
    if (console != null && console.log != null) {
      console.olog = console.log;
      console.oerror = console.error;
      console.owarn = console.warn;
    }

    console.log = (...message) => {
      console.olog(...message);
      try {
        this.#appendMessage(message);
      } catch (e) {
        console.olog('Failed to write message to in-app console', e);
      }
    };

    console.error = (...message) => {
      console.oerror(...message);
      try {
        this.#appendMessage(message, 'rgba(255,0,0,0.4)');
      } catch (e) {
        console.oerror('Failed to write message to in-app console', e);
      }
    };

    console.warn = (...message) => {
      console.owarn(...message);
      try {
        this.#appendMessage(message, 'rgba(255,255,0,0.4)');
      } catch (e) {
        console.oerror('Failed to write message to in-app console', e);
      }
    };
  }

  #createDom() {
    this.toggle = document.createElement('div');
    this.toggle.style.width = '100%';
    this.toggle.style.height = '20px';
    this.toggle.style.backgroundColor = 'rgb(63,63,0)';

    this.scrollContainer = document.createElement('div');
    this.scrollContainer.style.width = '100%';
    this.scrollContainer.style.height = '230px';
    this.scrollContainer.style.overflowY = 'scroll';

    this.domContainer = document.createElement('div');
    this.domContainer.style.position = 'fixed';
    this.domContainer.style.bottom = 0;
    this.domContainer.style.left = 0;
    this.domContainer.style.width = '100vw';
    this.domContainer.style.backgroundColor = 'rgba(0,0,0, 0.75)';
    this.domContainer.style.color = 'rgb(255,255,255)';
    this.domContainer.style.zIndex = 10000000000;

    this.domToolbar = document.createElement('div');
    this.domToolbar.style.width = '100%';
    this.domToolbar.style.height = '48px';
    this.domToolbar.style.backgroundColor = 'rgb(63,63,0)';
    this.domToolbar.style.display = 'flex';
    this.domToolbar.style.alignItems = 'center';
    this.domToolbar.style.justifyContent = 'space-between';
    this.domToolbar.style.padding = '0 10px';

    this.#buildToolbar();

    this.toggle.addEventListener('click', () => this.#toggleCollapse());
    this.#toggleCollapse();

    this.domContainer.prepend(this.scrollContainer);
    this.domContainer.prepend(this.domToolbar);
    this.domContainer.prepend(this.toggle);
    document.body.prepend(this.domContainer);
  }

  #buildToolbar() {
    const dbButton = document.createElement('button');
    dbButton.innerText = 'DB';
    dbButton.addEventListener('click', () => VueBridge.enterVue('/debug/database'));

    this.domToolbar.append(dbButton);
  }

  #toggleCollapse() {
    if (this.open) {
      this.open = false;
      this.domContainer.style.height = '20px';
      return;
    }

    this.open = true;
    this.domContainer.style.height = '250px';
  }

  #appendMessage(message, color = 'transparent') {
    let msg;
    if (Array.isArray(message)) {
      // Handle top-level array
      message = InAppConsole.#getStyled(message);

      msg = message.map(InAppConsole.#prepareMessage).join(' ');
    } else {
      msg = InAppConsole.#prepareMessage(message);
    }

    const messageContainer = document.createElement('div');
    messageContainer.style.color = 'white';
    messageContainer.style.backgroundColor = color;
    messageContainer.style.overflowY = 'auto';
    messageContainer.style.maxHeight = '250px';
    messageContainer.style.borderBottom = 'thin white solid';
    messageContainer.style.padding = '5px';
    messageContainer.style.maxWidth = '90%';
    messageContainer.contentEditable = true;

    messageContainer.innerHTML = msg;

    this.scrollContainer.prepend(messageContainer);
  }

  static #prepareMessage(message, depth = 0) {
    if (depth > 100) {
      return 'Maximum message depth reached';
    }

    if (message instanceof Error) {
      return message.message + '<br/>' + nl2br(message.stack);
    }

    if (typeof message === 'string' || typeof message === 'number' || typeof message === 'boolean') {
      return message;
    }

    if (message === null) {
      return 'null';
    }

    if (message === undefined) {
      return 'undefined';
    }

    if (Array.isArray(message)) {
      return message.map((msg) => InAppConsole.#prepareMessage(msg, depth + 1)).join(', ');
    }

    const iterator = Object.entries(message);
    let ret = '';
    for (const [key, value] of iterator) {
      const prepared = InAppConsole.#prepareMessage(value, depth + 1);
      ret += '<br/>';
      ret += InAppConsole.#shiftMessage(depth, key + ': ');
      ret += InAppConsole.#shiftMessage(depth, prepared);
    }

    return ret;
  }

  static #shiftMessage(depth, message) {
    return new Array(depth * 2).fill('&nbsp;').join('') + message;
  }

  /**
   *
   * @param {*[]} message
   * @return {*[]}
   */
  static #getStyled(message) {
    if (message.length < 2) {
      return message;
    }

    let first = message[0];
    if (typeof first !== 'string') {
      return message;
    }

    if (!first.startsWith('%c')) {
      return message;
    }

    const styles = message[1];

    first = first.substring(2);

    message.shift();
    message[0] = `<span style="display:inline-block;${styles}">${first}</span>`;

    return message;
  }
}
