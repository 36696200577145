/**
 * The page class for Policy and Goals Administration
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */
class pSAMA_PolicyObjectivesEditor extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_AD_PaoEdit', SAMA.pages.MenuAdmin);

    this.header = new cSAMA_PageHeader('Policies & Objectives', this.dom.page);
    this.dom.doneObjectiveList = $('#sama-pao-completed-list');
    this.dom.objectiveEditor = $('#pao-gol-tabEdit');
    this.resLock = new cSAMA_ResourceLock({
      what: 'POLCY',
      name: 'SMS Policies & Objectives',
      pk: 'group',
      fnOpen: () => this.GetData(),
      fnLocked: () => this.PageLoadFailed(),
    });
  }

  /**
   * Function called when the user enters the page.
   * @override
   */
  EnterPage() {
    this.resLock.BeginLocking();
  }

  GetData() {
    cSAMA_Utils.WaitForCatTaggerLoad();

    cSAMA_Request.Dispatch({
      reqs: new cSAMA_Request('PoliciesObjectives', 'getData'),
      name: 'GetPolObPage',
      clientErrorCode: 'SAMA 59 AE FA E0',
      fnSuccess: (_payload) => this.SetUpGui(_payload),
    });
  }

  /**
   * Sets up GUI-Data links
   */
  SetUpGui(_payload) {
    let lock = SAMA.GeneralGui.LockPageState('POG.SetUpGui');
    this.header.ButtonSaveDiscard();

    this.policy = SAMA.settings.policyObject = new mSAMA_Policy(_payload[0]);

    this.viewPolicyEditor = new vSAMA_PolicyEditor(this.dom.page, this.policy, false);

    this.LoadObjectiveEditor();

    SAMA.GeneralGui.UnlockPageState(lock);
    SAMA.Navigator.FinishedLoading(this.header);

    this.viewPolicyEditor.FixSigPad();
  }

  LoadObjectiveEditor() {
    this.viewCompletedObjectives = new vSAMA_TileGrid('No objectives found.');

    this.viewObjectiveEditor = new vSAMA_TabbedEditor({
      noDataRider: 'No Objectives',
      noDataBody: 'There are no objectives. Add some!',
      addButtonLabel: 'New Objective...',
      readOnly: false,
    });

    this.viewObjectiveEditor.GetAddButton().on('click', () => {
      this.AddObjective();
    });

    this.viewObjectiveEditor.GetDom().appendTo(this.dom.objectiveEditor);

    this.viewCompletedObjectives.GetDom().appendTo(this.dom.doneObjectiveList);

    let objectives = this.policy.GetObjectives();

    for (let o = 0; o < objectives.length; o++) {
      let pog = objectives[o];

      if (pog.IsCompleted()) {
        this.AddToCompleteView(pog);
        continue;
      }

      let v = new vSAMA_TabPolicyObjective(pog, false);
      this.viewCompletedObjectives.Observe(v, 'PogCompleted', (_m) => this.AddToCompleteView(_m));
      this.viewObjectiveEditor.AddTab(v);
    }

    this.viewCompletedObjectives.Refresh();
    this.viewObjectiveEditor.Refresh();
  }

  AddToCompleteView(_m) {
    let v = new vSAMA_TilePolicyObjective(_m, false);
    this.viewCompletedObjectives.AddItem(v);
    this.viewCompletedObjectives.Refresh();
  }

  AddObjective() {
    let o = new mSAMA_PolicyObjective(this.policy);
    let v = new vSAMA_TabPolicyObjective(o, false);
    this.viewObjectiveEditor.AddTab(v);
    this.viewObjectiveEditor.Refresh();

    this.viewCompletedObjectives.Observe(v, 'PogCompleted', (_m) => this.AddToCompleteView(_m));

    this.policy.AddObjective(o);
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  /**
   * Function called when the user leaves the page.
   * @override
   */
  LeavePage() {
    this.resLock.StopLocking();
    SAMA.Navigator.FinishedLoading();

    $('body').off('.samaevt');

    if (!this.loaded) {
      return;
    }

    this.viewPolicyEditor.Destroy();
    this.viewObjectiveEditor.Destroy();
    this.viewCompletedObjectives.Destroy();

    this.viewObjectiveEditor = null;
    this.viewObjectiveEditor = null;
    this.viewCompletedObjectives = null;

    cSAMA_GeneralInputs.UnLinkInputs(this.dom.page);
    this.policy = SAMA.settings.policyObject = null;
  }

  /**
   * Assigns server side primary keys to new clientside goals
   * @param _payload
   */
  UpdatePks(_payload) {
    // null the images so they won't be re-submitted
    this.policy.SetExecImageChanged(SAMA.enums.IMAGESTATES.UNCHANGED);
    this.policy.SetExecSignatureChanged(SAMA.enums.IMAGESTATES.UNCHANGED);
    this.policy.SetModified(false);

    let pks = _payload[0];
    this.viewPolicyEditor.UpdateRemotePks(pks.files);

    let objectives = this.policy.GetObjectives();
    for (let o = 0; o < objectives.length; o++) {
      let obj = objectives[o];
      obj.SetModified(false);
      if (!obj.IsNew()) {
        continue;
      }

      let oldPk = obj.GetPk();
      if (pks.objectives.hasOwnProperty(oldPk)) {
        obj.SetPk(pks.objectives[oldPk]);
      }

      for (let t = 0; t < obj.tasks.length; t++) {
        let task = obj.tasks[t];
        task.SetModified(false);
        if (!task.IsNew()) {
          continue;
        }

        let oldPk = task.GetPk();
        if (pks.tasks.hasOwnProperty(oldPk)) {
          task.SetPk(pks.tasks[oldPk]);
        }
      }
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  /**
   * The function which is run when the user clicks the 'Save Changes' button
   */
  SaveHandler() {
    let request = new cSAMA_Request('PoliciesObjectives', 'setData');
    let tm = this.policy.GetTransmissible();

    if (tm == null) {
      // Nothing to transmit.
      return;
    }

    request.Set('policy', tm.policy);
    request.Set('objectives', tm.objectives);

    if (this.policy.GetExecImageState() === SAMA.enums.IMAGESTATES.CHANGED) {
      // Changed Exec Image
      let loaded = this.viewPolicyEditor.GetExecImage();

      if (loaded.length <= 0) {
        this.policy.SetExecImageChanged(SAMA.enums.IMAGESTATES.DELETED);
      } else {
        // The file can be anywhere in the array depending how many images were added, deleted in this session
        for (let i in loaded) {
          if (!loaded.hasOwnProperty(i)) {
            continue;
          }
          request.AddFile('execImage', loaded[i].file);
          break;
        }
      }
    }

    let policyFiles = this.viewPolicyEditor.GetPolicyFiles();
    let deletedPolicyFiles = [];
    let fileNames = {};

    for (let f = 0; f < policyFiles.length; f++) {
      const file = policyFiles[f];

      if (file.deleted && file.uid > 0) {
        deletedPolicyFiles.push(file.uid);
      } else if (!file.deleted && file.uid < 0 && !file.preloaded) {
        fileNames[file.uid] = file.name;
        request.AddFile(file.uid, file.data);
      }
    }

    if (this.policy.GetExecSignatureState() === SAMA.enums.IMAGESTATES.CHANGED) {
      let sig = this.viewPolicyEditor.GetExecSignature();
      request.AddFile('signature', sig);
    }

    request.Set('deletedPolicyFiles', deletedPolicyFiles);
    request.Set('policyFileNames', fileNames);
    cSAMA_Request.Dispatch({
      reqs: request,
      name: 'SavePolObj',
      clientErrorCode: 'SAMA 59 B0 52 6N',
      fnSuccess: (_r) => this.UpdatePks(_r),
      goBackOnError: false,
      keepStateOnError: true,
    });
  }
}
