function toggleSOPFEU_GRID(e) {
  if (e.checked) {
    localStorageDB.setItem('SOPFEU_GRID', 1);
    mapb.on('moveend', updateGrid_SOPFEU);
    //Remove and Uncheck SAR if checked and displayed
    if ($('#GRID_LL').is(':checked')) {
      $('#GRID_LL').prop('checked', false).checkboxradio('refresh');
      toggleGRID_LL({ checked: false });
    }
    if ($('#SAR_CAP').is(':checked')) {
      $('#SAR_CAP').prop('checked', false).checkboxradio('refresh');
      toggleSAR_CAP({ checked: false });
    }
    updateGrid_SOPFEU();
  } else {
    localStorageDB.setItem('SOPFEU_GRID', 0);
    mapb.off('moveend', updateGrid_SOPFEU);
    if (mapb.getSource('SOPFEU_GRID') != undefined) {
      mapb.removeLayer('Grid025');
      mapb.removeLayer('Grid025-LABELS');
      mapb.removeLayer('Grid0083');
      mapb.removeLayer('Grid0083-LABELS2');
      mapb.removeSource('SOPFEU_GRID');
    }
  }
}
function updateGrid_SOPFEU() {
  var JSON_GRID = {
    type: 'FeatureCollection',
    features: getGridFeatures('SOPFEU_GRID'),
  };
  //Cell_Label
  var TEXT_SIZE = 18;
  var TEXT_COLOR = '#FF0000';
  var TEXT_HALO_COLOR = 'rgba(255,255,255,0.7)';
  var TEXT_HALO_WIDTH = 2.4;
  var TEXT_HALO_BLUR = 1;
  //Cell_Coord
  var TEXT_SIZE2 = 15;
  var TEXT_COLOR2 = '#FFFFFF';
  var TEXT_HALO_COLOR2 = 'rgba(0,0,0,0.7)';
  var TEXT_HALO_WIDTH2 = 2.4;
  var TEXT_HALO_BLUR2 = 1;

  if (mapb.getSource('SOPFEU_GRID') == undefined) {
    console.log('Adding SAR Source');
    console.log(JSON_GRID);
    mapb.addSource('SOPFEU_GRID', {
      type: 'geojson',
      data: JSON_GRID,
    });

    mapb.addLayer({
      id: 'Grid0083-LABELS2',
      type: 'symbol',
      source: 'SOPFEU_GRID',
      minzoom: 8,
      maxzoom: 18,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Coord}',
        'text-size': TEXT_SIZE2,
        'text-justify': 'center',
        'text-anchor': 'top',
        'text-allow-overlap': true,
      },
      filter: ['all', ['==', 'Step', '0.08333']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR2,
        'text-halo-width': TEXT_HALO_WIDTH2,
        'text-halo-blur': TEXT_HALO_BLUR2,
        'text-color': TEXT_COLOR2,
      },
    });

    mapb.addLayer({
      id: 'Grid025-LABELS',
      type: 'symbol',
      source: 'SOPFEU_GRID',
      minzoom: 7,
      maxzoom: 18,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'center',
        'text-anchor': 'bottom',
        'text-allow-overlap': true,
      },
      filter: ['all', ['==', 'Step', '0.25']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });

    mapb.addLayer(
      {
        id: 'Grid0083',
        source: 'SOPFEU_GRID',
        type: 'line',
        minzoom: 8,
        maxzoom: 18,
        filter: ['all', ['==', 'Step', '0.08333']],
        paint: {
          'line-color': '#840000',
          'line-opacity': 1,
          'line-width': 0.5,
        },
      },
      'AIRCRAFT'
    );

    mapb.addLayer(
      {
        id: 'Grid025',
        source: 'SOPFEU_GRID',
        type: 'line',
        minzoom: 5,
        maxzoom: 18,
        filter: ['all', ['==', 'Step', '0.25']],
        paint: {
          'line-color': '#FF0000',
          'line-opacity': 1,
          'line-width': 1,
        },
      },
      'AIRCRAFT'
    );
  } else {
    mapb.getSource('SOPFEU_GRID').setData(JSON_GRID);
    console.log('Updating SOPFEU_GRID');
  }
}

function toggleSAR_CAP(e) {
  if (e.checked) {
    localStorageDB.setItem('SAR_CAP', 1);
    mapb.on('moveend', updateGrid_SAR);
    //Remove and Uncheck SAR if checked and displayed
    if ($('#GRID_LL').is(':checked')) {
      $('#GRID_LL').prop('checked', false).checkboxradio('refresh');
      toggleGRID_LL({ checked: false });
    }
    if ($('#GRID_SOPFEU').is(':checked')) {
      $('#GRID_SOPFEU').prop('checked', false).checkboxradio('refresh');
      toggleSOPFEU_GRID({ checked: false });
    }
    updateGrid_SAR();
  } else {
    localStorageDB.setItem('SAR_CAP', 0);
    mapb.off('moveend', updateGrid_SAR);
    if (mapb.getSource('SAR_GRID') != undefined) {
      mapb.removeLayer('Grid1');
      mapb.removeLayer('Grid1-LABELS');
      mapb.removeLayer('Grid05');
      mapb.removeLayer('Grid05-LABELS');
      mapb.removeLayer('Grid05-LABELS2');
      mapb.removeLayer('Grid025');
      mapb.removeLayer('Grid025-LABELS');
      mapb.removeLayer('Grid025-LABELS2');
      mapb.removeLayer('Grid0125');
      mapb.removeLayer('Grid0125-LABELS');
      mapb.removeLayer('Grid0125-LABELS2');
      mapb.removeSource('SAR_GRID');
    }
  }
}

function updateGrid_SAR() {
  var JSON_GRID = {
    type: 'FeatureCollection',
    features: getGridFeatures('SAR_GRID'),
  };
  //Cell_Label
  var TEXT_SIZE = 18;
  var TEXT_COLOR = '#FF0000';
  var TEXT_HALO_COLOR = 'rgba(255,255,255,0.7)';
  var TEXT_HALO_WIDTH = 2.4;
  var TEXT_HALO_BLUR = 1;
  //Cell_Coord
  var TEXT_SIZE2 = 15;
  var TEXT_COLOR2 = '#FFFFFF';
  var TEXT_HALO_COLOR2 = 'rgba(0,0,0,0.7)';
  var TEXT_HALO_WIDTH2 = 2.4;
  var TEXT_HALO_BLUR2 = 1;

  if (mapb.getSource('SAR_GRID') == undefined) {
    console.log('Adding SAR Source');
    console.log(JSON_GRID);
    mapb.addSource('SAR_GRID', {
      type: 'geojson',
      data: JSON_GRID,
    });

    mapb.addLayer({
      id: 'Grid1-LABELS',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 5,
      maxzoom: 6.5,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Label}',
        'text-size': TEXT_SIZE2,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '1']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR2,
        'text-halo-width': TEXT_HALO_WIDTH2,
        'text-halo-blur': TEXT_HALO_BLUR2,
        'text-color': TEXT_COLOR2,
      },
    });

    mapb.addLayer({
      id: 'Grid05-LABELS',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 5.5,
      maxzoom: 6.5,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '0.5']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });
    mapb.addLayer({
      id: 'Grid05-LABELS2',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 6.5,
      maxzoom: 8,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Coord}',
        'text-size': TEXT_SIZE2,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '0.5']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR2,
        'text-halo-width': TEXT_HALO_WIDTH2,
        'text-halo-blur': TEXT_HALO_BLUR2,
        'text-color': TEXT_COLOR2,
      },
    });

    mapb.addLayer({
      id: 'Grid025-LABELS',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 6.5,
      maxzoom: 8,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '0.25']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });
    mapb.addLayer({
      id: 'Grid025-LABELS2',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 8,
      maxzoom: 9.5,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Coord}',
        'text-size': TEXT_SIZE2,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '0.25']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR2,
        'text-halo-width': TEXT_HALO_WIDTH2,
        'text-halo-blur': TEXT_HALO_BLUR2,
        'text-color': TEXT_COLOR2,
      },
    });

    mapb.addLayer({
      id: 'Grid0125-LABELS',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 8,
      maxzoom: 9.5,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '0.125']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });
    mapb.addLayer({
      id: 'Grid0125-LABELS2',
      type: 'symbol',
      source: 'SAR_GRID',
      minzoom: 9.5,
      maxzoom: 18,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{Cell_Coord}',
        'text-size': TEXT_SIZE2,
        'text-justify': 'center',
        'text-anchor': 'center',
      },
      filter: ['all', ['==', 'Step', '0.125']],
      paint: {
        'text-halo-color': TEXT_HALO_COLOR2,
        'text-halo-width': TEXT_HALO_WIDTH2,
        'text-halo-blur': TEXT_HALO_BLUR2,
        'text-color': TEXT_COLOR2,
      },
    });

    mapb.addLayer(
      {
        id: 'Grid0125',
        source: 'SAR_GRID',
        type: 'line',
        minzoom: 8,
        maxzoom: 18,
        filter: ['all', ['==', 'Step', '0.125']],
        paint: {
          'line-color': '#840000',
          'line-opacity': 1,
          'line-width': 0.5,
        },
      },
      'AIRCRAFT'
    );

    mapb.addLayer(
      {
        id: 'Grid025',
        source: 'SAR_GRID',
        type: 'line',
        minzoom: 6.5,
        maxzoom: 18,
        filter: ['all', ['==', 'Step', '0.25']],
        paint: {
          'line-color': '#FF0000',
          'line-opacity': 1,
          'line-width': 1,
        },
      },
      'AIRCRAFT'
    );

    mapb.addLayer(
      {
        id: 'Grid05',
        source: 'SAR_GRID',
        type: 'line',
        minzoom: 5.5,
        maxzoom: 8,
        filter: ['all', ['==', 'Step', '0.5']],
        paint: {
          'line-color': '#840000',
          'line-opacity': 1,
          'line-width': 1.5,
        },
      },
      'AIRCRAFT'
    );

    mapb.addLayer(
      {
        id: 'Grid1',
        source: 'SAR_GRID',
        type: 'line',
        minzoom: 0,
        maxzoom: 6.5,
        filter: ['all', ['==', 'Step', '1']],
        paint: {
          'line-color': '#FF0000',
          'line-opacity': 1,
          'line-width': 2.5,
        },
      },
      'AIRCRAFT'
    );
  } else {
    mapb.getSource('SAR_GRID').setData(JSON_GRID);
    console.log('Updating SAR_GRID');
  }
}

function toggleGRID_LL(e) {
  if (e.checked) {
    localStorageDB.setItem('GRID_LL', 1);
    mapb.on('moveend', updateGrid_LL);
    //Remove and Uncheck SAR if checked and displayed
    if ($('#SAR_CAP').is(':checked')) {
      $('#SAR_CAP').prop('checked', false).checkboxradio('refresh');
      toggleSAR_CAP({ checked: false });
    }
    if ($('#GRID_SOPFEU').is(':checked')) {
      $('#GRID_SOPFEU').prop('checked', false).checkboxradio('refresh');
      toggleSOPFEU_GRID({ checked: false });
    }
    updateGrid_LL();
  } else {
    localStorageDB.setItem('GRID_LL', 0);
    mapb.off('moveend', updateGrid_LL);
    if (mapb.getSource('LL_GRID') != undefined) {
      mapb.removeLayer('Grid_LL');
      mapb.removeLayer('Grid_LL-W-LABELS');
      mapb.removeLayer('Grid_LL-N-LABELS');
      mapb.removeSource('LL_GRID');
      mapb.removeSource('LL_GRID-LABELS');
    }
  }
}

function updateGrid_LL() {
  var Data = getGridFeatures('LL_GRID');
  var JSON_GRID = {
    type: 'FeatureCollection',
    features: Data.Grid,
  };
  var JSON_LABELS = {
    type: 'FeatureCollection',
    features: Data.Labels,
  };
  //LL labels
  var TEXT_SIZE = 16;
  var TEXT_COLOR = '#000000';
  var TEXT_HALO_COLOR = 'rgba(255,255,255,0.7)';
  var TEXT_HALO_WIDTH = 2.4;
  var TEXT_HALO_BLUR = 1;

  if (mapb.getSource('LL_GRID') == undefined) {
    console.log('Adding LL Source');
    console.log(JSON_GRID);
    mapb.addSource('LL_GRID', {
      type: 'geojson',
      data: JSON_GRID,
    });
    mapb.addSource('LL_GRID-LABELS', {
      type: 'geojson',
      data: JSON_LABELS,
    });
    mapb.addLayer({
      id: 'Grid_LL-N-LABELS',
      type: 'symbol',
      source: 'LL_GRID-LABELS',
      minzoom: 0,
      maxzoom: 18,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{North_Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'right',
        'text-anchor': 'top-left',
        'text-offset': [2, 0.2],
      },
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });

    mapb.addLayer({
      id: 'Grid_LL-W-LABELS',
      type: 'symbol',
      source: 'LL_GRID-LABELS',
      minzoom: 0,
      maxzoom: 18,
      layout: {
        visibility: 'visible',
        'symbol-placement': 'point',
        'text-field': '{West_Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'left',
        'text-anchor': 'top-left',
        'text-rotate': 270,
        'text-offset': [-4.5, 0.2],
      },
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });

    mapb.addLayer(
      {
        id: 'Grid_LL',
        source: 'LL_GRID',
        type: 'line',
        minzoom: 0,
        maxzoom: 18,
        paint: {
          'line-color': '#000000',
          'line-opacity': 1,
          'line-width': 1.5,
        },
      },
      'AIRCRAFT'
    );
  } else {
    mapb.getSource('LL_GRID').setData(JSON_GRID);
    mapb.getSource('LL_GRID-LABELS').setData(JSON_LABELS);
    console.log('Updating LL_GRID');
  }
}

function getGridFeatures(GridType) {
  var zoom = mapb.getZoom();
  var bounds = mapb.getBounds();
  //console.log(bounds.getWest()+" "+bounds.getSouth()+" "+bounds.getEast()+" "+bounds.getNorth());
  var West = Math.floor(bounds.getWest());
  var East = Math.ceil(bounds.getEast());
  var North = Math.ceil(bounds.getNorth());
  var South = Math.floor(bounds.getSouth());
  var features = [];
  var Steps = [];
  var LL = false;
  if (GridType == 'SAR_GRID') {
    if (zoom > 5) Steps.push(1);
    if (zoom >= 5.5) Steps.push(0.5);
    if (zoom >= 6.5) Steps.push(0.25);
    if (zoom >= 8) Steps.push(0.125);
    Steps.forEach(function (Step) {
      features = features.concat(GridItem(West, East, North, South, Step, LL, GridType));
    });
  }
  if (GridType == 'LL_GRID') {
    LL = true;
    var Step = 25;
    if (zoom > 3) Step = 10;
    if (zoom > 5) Step = 5;
    if (zoom > 6) Step = 2;
    if (zoom > 7) Step = 1;
    if (zoom > 8.3) Step = 0.5;
    if (zoom > 9.5) Step = 0.25;
    if (zoom > 10.5) Step = 0.125;
    if (zoom > 11.5) Step = 0.0625;
    if (zoom > 12.5) Step = 0.03125;
    if (zoom > 13.5) Step = 0.015625;
    var nWest = West - (West % Step) - Step;
    var nNorth = North - (North % Step) + Step;
    features = GridItem(nWest, East, nNorth, South, Step, LL, GridType);
  }

  if (GridType == 'SOPFEU_GRID') {
    LL = false;
    if (zoom >= 5) Steps.push(0.25);
    if (zoom >= 8) Steps.push(0.08333);
    Steps.forEach(function (Step) {
      features = features.concat(GridItem(West, East, North, South, Step, LL, GridType, -79.501, -57, 45, 55.001));
    });
  }

  return features;
}
function GridItem(
  West,
  East,
  North,
  South,
  Step,
  LL,
  GridType,
  minWest = -180,
  maxWest = 180,
  minNorth = -90,
  maxNorth = 90
) {
  var sopfeu0083 = {
    11: 7,
    12: 8,
    13: 9,
    21: 6,
    22: 4,
    23: 5,
    31: 2,
    32: 3,
    33: 1,
  };
  if (GridType == 'SOPFEU_GRID') {
    West = Math.floor(West / 0.25) * 0.25;
    East = Math.ceil(East / 0.25) * 0.25;
    North = Math.ceil(North / 0.25) * 0.25;
    South = Math.floor(South / 0.25) * 0.25;
  }

  var CurWest = West;
  var CurEast = West + Step;
  var CurNorth = North;
  var CurSouth = North - Step;
  var features = [];
  var LLfeatures = [];
  var Lbl05W = true;
  var Lbl05S = false;
  var Lbl025W = true;
  var Lbl025S = false;
  var Lbl0125W = true;
  var Lbl0125S = false;
  var northCount = 1;
  var westCount = 1;

  while (CurNorth > South) {
    if (CurNorth > maxNorth || CurNorth <= minNorth) {
      CurNorth -= Step;
      CurSouth -= Step;
      if (CurNorth % 0.5 == 0) Lbl05S ^= true;
      if (CurNorth % 0.25 == 0) Lbl025S ^= true;
      if (CurNorth % 0.125 == 0) Lbl0125S ^= true;
      CurWest = West;
      CurEast = West + Step;
      continue;
    }
    if (northCount > 3) northCount = 1;
    while (CurWest < East) {
      if (CurWest >= maxWest || CurWest < minWest) {
        CurWest += Step;
        CurEast += Step;
        continue;
      }
      if (westCount > 3) westCount = 1;
      var Coords = [
        [
          [CurWest, CurNorth],
          [CurEast, CurNorth],
          [CurEast, CurSouth],
          [CurWest, CurSouth],
          [CurWest, CurNorth],
        ],
      ];
      var CellLabel = '';
      var CellCoord = '';
      var Lbl05 = '';
      var Lbl025 = '';
      var Lbl0125 = '';
      var LatHemisphere = 'N';
      if (CurNorth < 0) LatHemisphere = 'S';
      var LngHemisphere = 'W';
      if (CurWest > 0) LngHemisphere = 'E';
      //Make 1 degree Grid labels
      var Grid1_lbl_E = Math.floor(Math.abs(CurEast));
      if (Grid1_lbl_E.toString().length == 2) Grid1_lbl_E = '0' + Grid1_lbl_E;
      if (Grid1_lbl_E.toString().length == 1) Grid1_lbl_E = '00' + Grid1_lbl_E;
      var Grid1_lbl_S = Math.floor(Math.abs(CurSouth));
      if (Grid1_lbl_S.toString().length == 1) Grid1_lbl_S = '0' + Grid1_lbl_S;
      CellCoord = Grid1_lbl_S.toString() + Grid1_lbl_E.toString();

      //AB  Make 30 minute Grid Labels
      //CD
      if (!Lbl05S) {
        if (Lbl05W) Lbl05 = 'A';
        else Lbl05 = 'B';
      } else {
        if (Lbl05W) Lbl05 = 'C';
        else Lbl05 = 'D';
      }

      //AB  Make 15 minute Grid Labels
      //CD
      if (GridType == 'SOPFEU_GRID') {
        if (Step == 0.25 || Step == 0.08333) {
          var sopfeuWest = Math.round((79.5 + CurWest) / 0.25 + 3);
          var sopfeuNorth = 32 - Math.round((52 - CurNorth) / 0.25);
          Lbl025 =
            (sopfeuWest > 9 ? '' : '0') + sopfeuWest.toString() + (sopfeuNorth > 9 ? '' : '0') + sopfeuNorth.toString();
        }
        if (Step == 0.08333) {
          //todo make label for SOPFEU
          CellCoord = sopfeu0083[parseInt(northCount.toString() + westCount.toString())];
        }
      } else {
        if (!Lbl025S) {
          if (Lbl025W) Lbl025 = 'A';
          else Lbl025 = 'B';
        } else {
          if (Lbl025W) Lbl025 = 'C';
          else Lbl025 = 'D';
        }
      }

      //AB  Make 7.5 minute Grid Labels
      //CD
      if (!Lbl0125S) {
        if (Lbl0125W) Lbl0125 = 'A';
        else Lbl0125 = 'B';
      } else {
        if (Lbl0125W) Lbl0125 = 'C';
        else Lbl0125 = 'D';
      }
      switch (Step) {
        case 1:
          CellLabel = CellCoord;
          break;
        case 0.5:
          CellCoord = CellCoord + Lbl05;
          CellLabel = Lbl05;
          break;
        case 0.25:
          if (GridType == 'SOPFEU_GRID') {
            CellCoord = Lbl025;
            CellLabel = Lbl025;
          } else {
            CellCoord = CellCoord + Lbl05 + Lbl025;
            CellLabel = Lbl025;
          }

          break;
        case 0.125:
          CellCoord = CellCoord + Lbl05 + Lbl025 + Lbl0125;
          CellLabel = Lbl0125;
          break;
        case 0.08333:
          CellLabel = Lbl025;
          break;
      }
      var json = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: Coords,
        },
        properties: {
          Cell_Label: CellLabel,
          Cell_Coord: CellCoord,
          Step: Step.toString(),
          LatHemisphere: LatHemisphere,
          LngHemisphere: LngHemisphere,
        },
      };
      if (LL) {
        var LLjson = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [CurWest, CurNorth],
          },
          properties: {
            West_Label: ConvertDD_User(CurWest, 'Long'),
            North_Label: ConvertDD_User(CurNorth, 'Lat'),
            LatHemisphere: LatHemisphere,
            LngHemisphere: LngHemisphere,
          },
        };
        LLfeatures.push(LLjson);
      }
      features.push(json);
      CurWest += Step;
      CurEast += Step;
      if (CurWest % 0.5 == 0) Lbl05W ^= true;
      if (CurWest % 0.25 == 0) Lbl025W ^= true;
      if (CurWest % 0.125 == 0) Lbl0125W ^= true;
      westCount++;
    }
    CurNorth -= Step;
    CurSouth -= Step;
    if (CurNorth % 0.5 == 0) Lbl05S ^= true;
    if (CurNorth % 0.25 == 0) Lbl025S ^= true;
    if (CurNorth % 0.125 == 0) Lbl0125S ^= true;
    CurWest = West;
    CurEast = West + Step;
    northCount++;
  }
  if (LL) return { Grid: features, Labels: LLfeatures };
  else return features;
}

function MNR_GridLayers() {
  var MNR_Layers = [
    {
      id: 'utm-1',
      type: 'line',
      source: 'MNR_Grid',
      'source-layer': 'utm_1',
      minzoom: 10,
      filter: ['==', '$type', 'Polygon'],

      paint: {
        'line-color': 'hsl(0, 100%, 27%)',
        'line-width': 0.5,
      },
    },
    {
      id: 'mnrd',
      type: 'line',
      metadata: {},
      source: 'MNR_Grid',
      'source-layer': 'mnrd',
      minzoom: 3,
      filter: ['==', '$type', 'Polygon'],

      paint: {
        'line-width': 4,
        'line-color': 'hsl(300, 100%, 68%)',
        'line-blur': 3,
        'line-opacity': 0.7,
      },
    },
    {
      id: 'utm-10',
      type: 'line',
      metadata: {},
      source: 'MNR_Grid',
      'source-layer': 'utm_10',
      minzoom: 7,
      filter: ['==', '$type', 'Polygon'],

      paint: {
        'line-color': 'hsl(0, 100%, 50%)',
      },
    },
    {
      id: 'utm-1-Label',
      type: 'symbol',
      metadata: {},
      source: 'MNR_Grid',
      'source-layer': 'utm_1',
      minzoom: 11.3,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        'text-field': '{FIRE_BLOCK}',
        'text-size': 15,
        'text-letter-spacing': 0.1,
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'text-padding': 10,
        'text-allow-overlap': true,
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'text-color': 'hsl(0, 100%, 50%)',
      },
    },
    {
      id: 'mnrd-Label',
      type: 'symbol',
      metadata: {},
      source: 'MNR_Grid',
      'source-layer': 'mnrd',
      minzoom: 3,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        'text-field': '{DIST_NAME}',
        'text-allow-overlap': true,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 2,
        'text-halo-blur': 1.5,
        'text-halo-color': 'hsl(0, 0%, 0%)',
      },
    },
    {
      id: 'utm-10-Label',
      type: 'symbol',
      metadata: {},
      source: 'MNR_Grid',
      'source-layer': 'utm_10',
      minzoom: 7.5,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        'text-field': '{FIRE_IDENT} Z{UTM_ZONE}',
        'text-size': 16,
        'text-letter-spacing': 0.1,
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'text-padding': 10,
        'text-max-width': 1,
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 2,
        'text-halo-blur': 1.5,
        'text-color': 'hsl(0, 3%, 0%)',
      },
    },
  ];
  return MNR_Layers;
}

function SOPFEU_GridLayers() {
  var SOPFEU_Layers = [
    {
      id: 'sopfeu-lines',
      type: 'line',
      metadata: {},
      source: 'SOPFEU_Grid',
      'source-layer': 'Lines',
      minzoom: 7,
      paint: {
        'line-color': 'hsl(0, 100%, 50%)',
      },
    },
    {
      id: 'sopfeu-points',
      type: 'circle',
      metadata: {},
      source: 'SOPFEU_Grid',
      'source-layer': 'Points',
      minzoom: 7,
      paint: {
        'circle-color': 'hsl(0, 0%, 100%)',
        'circle-stroke-width': 1,
      },
    },
    {
      id: 'sopfeu-labels',
      type: 'symbol',
      source: 'SOPFEU_Grid',
      'source-layer': 'Points',
      minzoom: 7.5,
      layout: {
        'text-field': '{Name}',
        'text-size': 16,
        'text-letter-spacing': 0.1,
        'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Regular'],
        'text-padding': 10,
        'text-max-width': 1,
        'text-anchor': 'bottom',
        'text-offset': [0, -0.5],
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 2,
        'text-halo-blur': 1.5,
        'text-color': 'hsl(0, 3%, 0%)',
      },
    },
  ];
  return SOPFEU_Layers;
}

function ATS_GridLayers() {
  var ATS_Layers = [
    {
      id: 'ats-twp',
      type: 'line',
      source: 'ATS_Grid',
      'source-layer': 'ats_twp',
      minzoom: 3,
      maxzoom: 12,

      paint: {
        'line-width': 0.5,
        'line-color': 'hsl(0, 98%, 47%)',
      },
    },
    {
      id: 'ats-sec',
      type: 'line',
      source: 'ATS_Grid',
      'source-layer': 'ats_sec',
      minzoom: 11,
      maxzoom: 13,

      paint: {
        'line-color': 'hsl(0, 98%, 47%)',
      },
    },
    {
      id: 'ats-qtr',
      type: 'line',
      source: 'ATS_Grid',
      'source-layer': 'ats_qtr',
      minzoom: 13,

      paint: {
        'line-color': 'hsl(0, 98%, 47%)',
      },
    },
    {
      id: 'ats-twp-Label',
      type: 'symbol',
      source: 'ATS_Grid',
      'source-layer': 'ats_twp',
      minzoom: 5,
      maxzoom: 11,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        'text-field': 'M~{M} R~{RGE} TWP~{TWP} ',
        'text-rotate': 0,
        'text-offset': [0, 0],
        'text-padding': 10,
        'text-size': 13,
        'text-max-width': 1,
        'symbol-avoid-edges': true,
        'text-justify': 'right',
      },
      paint: {
        'text-color': 'hsl(0, 11%, 6%)',
        'text-halo-color': 'hsl(0, 0%, 79%)',
        'text-halo-width': 2,
        'text-halo-blur': 1,
      },
    },
    {
      id: 'ats-qtr-Label',
      type: 'symbol',
      source: 'ATS_Grid',
      'source-layer': 'ats_qtr',
      minzoom: 13.5,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        'text-field': 'M~{M} R~{RGE} TWP~{TWP} SEC~{SEC} LS~{LS}',
        'text-size': 13,
        'text-padding': 20,
        'symbol-avoid-edges': true,
        'text-max-width': 1,
        'text-justify': 'right',
      },
      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-halo-color': 'hsl(0, 100%, 46%)',
        'text-halo-blur': 1,
      },
    },
    {
      id: 'ats-sec-Label',
      type: 'symbol',
      source: 'ATS_Grid',
      'source-layer': 'ats_sec',
      minzoom: 11,
      maxzoom: 13.5,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        'text-field': 'M~{M} R~{RGE} TWP~{TWP} SEC~{SEC}',
        'text-rotate': 0,
        'text-offset': [0, 0],
        'text-padding': 10,
        'text-size': 13,
        'text-max-width': 1,
        'symbol-avoid-edges': true,
        'text-justify': 'right',
      },
      paint: {
        'text-color': 'hsl(0, 11%, 6%)',
        'text-halo-color': 'hsl(0, 0%, 79%)',
        'text-halo-width': 2,
        'text-halo-blur': 1,
      },
    },
  ];
  return ATS_Layers;
}
