MAPSTATE.CustomCode = false;

function showCustomOverlay() {
  removeSVGoverlay(); //because overlay causes problems when swapping styles for some reason

  $('#CustomMapLayerToggles').show();
  localStorageDB.setItem('MapType', 'Custom');
  if (ONLINE && !MAPSTATE.OfflineDataOn) {
    RemoveOnlineLayers();
    MAPSTATE.Type = 'Custom';
    if (mapb.getSource('ONLINE') != undefined) {
      mapb.removeSource('ONLINE');
      mapb.addSource('ONLINE', CustomSOURCE);
      AddOnlineLayers();
    } else {
      mapb.addSource('ONLINE', CustomSOURCE);
      AddOnlineLayers();
    }
  } else {
    MAPSTATE.Type = 'Custom';
    updateOfflineStyle();
  }
  LoadCustomerLayerScripts();
}

function LoadCustomerLayerScripts() {
  if (GROUP_DATA.MAPBOX_CODE != '' && MAPSTATE.CustomCode == true) {
    mapb.on('click', CustomOverlayDataGrabber);
  }
  if (MAPSTATE.CustomCode == true || GROUP_DATA.MAPBOX_CODE == '') {
    return;
  }
  mapb.on('click', CustomOverlayDataGrabber);
  PopulateCustomerLayerOptions(); //Must be included within mapbox code
}

function LoadCustomMapScripts() {
  //*********THIS IS FOR PROUDCTION
  if (MAPSTATE.LoadedCode == true || GROUP_DATA.MAPBOX_CODE == '') {
    return;
  }
  var script = document.createElement('script');
  script.innerHTML = GROUP_DATA.MAPBOX_CODE;
  document.body.appendChild(script);
  MAPSTATE.LoadedCode = true;
}

//************************************************************************* START CUSTOM MAPBOX_CODE

//************************************************************************* END CUSTOM MAPBOX_CODE

function getMapboxCustomLayers(SOURCE) {
  if (GROUP_DATA.MAPBOX_STYLE != '') {
    return JSON.parse(GROUP_DATA.MAPBOX_STYLE); //This is for production mode
  } else {
    return _getMapboxCustomLayers(SOURCE);
  }
}

function removeCustomMapboxLayers(SOURCE) {
  var Layers = [];
  if (GROUP_DATA.MAPBOX_STYLE != '') {
    Layers = JSON.parse(GROUP_DATA.MAPBOX_STYLE); //This is for production mode
  } else {
    Layers = _getMapboxCustomLayers(SOURCE);
  }
  for (var i in Layers) {
    var L = Layers[i];
    if (mapb.getLayer(L.id) != undefined) {
      mapb.removeLayer(L.id);
    }
  }
  _removeCustomMapboxSources();
}
