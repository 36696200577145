function update_TimeSheet_Data() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  REPORT_USER_DATA = [];
  getTimeSheet(0);
}

function getTimeSheet(index) {
  console.log('Processing Index: ' + index);
  var num = parseInt(index) + 1;

  $('#Report_Content').html(
    "<h3 class='limit-table'>" + iTrans('Retrieving Pilot') + ': ' + num + ' of ' + Report_Data.length + '</h3>'
  );
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  statusTS.ReportDays = Math.round((ToDate - FromDate) / (1000 * 60 * 60 * 24));
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  statusTS.FromDate = FromDate;
  statusTS.ToDate = ToDate;
  if (index == Report_Data.length) Report_TimeSheets_Display(REPORT_USER_DATA);
  else {
    $.getJSON(
      BASEURL +
        'menu-Administration-Reports-AJAX.php?report=Report_TimeSheet&UserPK=' +
        Report_Data[index].PrimaryKey +
        '&FromDate=' +
        FromDate +
        '&ToDate=' +
        ToDate,
      function (json_data) {
        REPORT_USER_DATA.push(json_data);
        var next = parseInt(index) + 1;
        getTimeSheet(next);
      }
    ).fail(function (jqXHR, status, error) {
      console.log('Failed to get Report Data');
      $('#Report_Content').html('<h3>' + iTrans('Data Error try again later') + '</h3>');
      AJAXFAIL(error);
      $.mobile.loading('hide');
    });
  }
}

function Report_TimeSheets_Display(data) {
  $('#Report_Content').html('<h3>' + iTrans('Generating Report') + '</h3>');
  $('#Title_Report_Name').html(iTrans('Staff Time Sheets'));
  $.mobile.loading('hide');
  console.log(Report_Data);
  console.log(data);
  var html = "<h2 class='limit-table'>" + iTrans('Ground Staff') + '</h2>';
  var phtml = "<h2 class='limit-table'>" + iTrans('Pilot Staff') + '</h2>';
  var FR_Thead = '';
  if (GROUP_DATA.FlightReports == 1) {
    FR_Thead = '<th>Rev</th><th>NonRev</th><th>FR ' + iTrans('Total') + '</th>';
  }
  html +=
    '<table  data-filter="true" class="limit-table second-td-fill" border=1><thead><tr><th>SEL</th><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Last Sync') +
    '</th><th>' +
    iTrans('Hours') +
    '</th><th>' +
    iTrans('Unpaid') +
    '</th><th>OT</th><th>Reg</th><th>FP</th><th>FT</th><th>WD</th><th>FD</th>' +
    FR_Thead +
    '<th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  phtml +=
    '<table  data-filter="true" class="limit-table second-td-fill" border=1><thead><tr><th>SEL</th><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Last Sync') +
    '</th><th>' +
    iTrans('Duty') +
    '</th><th>FT</th><th>WD</th><th>FD</th>' +
    FR_Thead +
    '<th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in Report_Data) {
    var User = Report_Data[i];
    var D = data[i];
    var DutyTime = 0;
    var FlightTime = 0;
    var WkDays = 0;
    var FltDays = 0;
    var FR_Rev = 0;
    var FR_NonRev = 0;
    var FR_Total = 0;
    for (var I in D.Records) {
      var R = D.Records[I];
      var DT = 0;
      DT = dutytime_Calc(R.Start, R.End);
      if (R.Split == 1) DT += dutytime_Calc(R.Start2, R.End2);
      DT = round10(DT);
      if (DT > 0) WkDays++;
      if (round10(parseFloat(R.FT)) > 0) FltDays++;
      else if (round10(parseFloat(R.FT2)) > 0 && R.Split == 1) FltDays++;
      DutyTime += DT;
      FR_Rev += R.FR_Rev;
      FR_NonRev += R.FR_NonRev;
    }
    FR_Total = round10(FR_Rev + FR_NonRev);
    DutyTime = round10(DutyTime);
    if (isNaN(D.Totals.FT)) D.Totals.FT = 0;
    if (isNaN(D.Totals.FT2)) D.Totals.FT2 = 0;
    FlightTime = round10(parseFloat(D.Totals.FT) + parseFloat(D.Totals.FT2));
    if (isNaN(FlightTime)) FlightTime = 0;
    var bg = '';
    var action =
      '<button id="timesheet' +
      i +
      '" class="action-btn" onClick="viewTimeSheet(' +
      i +
      ',true)"><img src="./images/inspect.svg"></button>';
    var SEL = '<input type="checkbox" data-role="none" id="TS_' + i + '" />';
    if (D.Records.length < statusTS.ReportDays) bg = 'yellowRecord';
    if (D.Records.length == 0) {
      bg = 'redRecord';
      action = '';
    }
    User.FltDays = FltDays;
    User.WkDays = WkDays;
    User.FR_Rev = FR_Rev;
    User.FR_NonRev = FR_NonRev;
    User.FR_Total = FR_Total;
    var FR_Items = '';
    if (GROUP_DATA.FlightReports == 1) {
      FR_Items = '<td>' + FR_Rev + '</td><td>' + FR_NonRev + '</td><td>' + FR_Total + '</td>';
    }
    var SEL = '<input type="checkbox" data-role="none" id="TS_SEL_' + i + '" />';
    if (bg == 'redRecord') SEL = '';
    if (userShouldHaveFDT(User.staffType)) {
      phtml +=
        '<tr class="' +
        bg +
        '"><td>' +
        SEL +
        '<td>' +
        User.chatname +
        '</td><td>' +
        User.staffType +
        '</td><td>' +
        User.LastFDTsync +
        '</td><td>' +
        DutyTime +
        '</td><td>' +
        FlightTime +
        '</td><td>' +
        WkDays +
        '</td><td>' +
        FltDays +
        '</td>' +
        FR_Items +
        '<td>' +
        action +
        '</td></tr>';
    } else {
      var RegHours = round10(DutyTime - D.Totals.UP - D.Totals.OT);
      html +=
        '<tr class="' +
        bg +
        '"><td>' +
        SEL +
        '<td>' +
        User.chatname +
        '</td><td>' +
        User.staffType +
        '</td><td>' +
        User.LastFDTsync +
        '</td><td>' +
        DutyTime +
        '</td><td>' +
        D.Totals.UP +
        '</td><td>' +
        D.Totals.OT +
        '</td><td>' +
        RegHours +
        '</td><td>' +
        D.Totals.FP +
        '</td><td>' +
        FlightTime +
        '</td><td>' +
        WkDays +
        '</td><td>' +
        FltDays +
        '</td>' +
        FR_Items +
        '<td>' +
        action +
        '</td></tr>';
    }
  }
  html += '</tbody></table>';
  html +=
    '<button class="greenbg" onClick="ProcessGroundTimeSheets()" data-mini="true">' +
    iTrans('Process Selected Ground Staff') +
    '</button><br />';
  phtml += '</tbody></table>';
  phtml +=
    '<button class="greenbg" onClick="ProcessPilotTimeSheets()" data-mini="true">' +
    iTrans('Process Selected Pilot Staff') +
    '</button>';
  $('#Report_Content').html(html + phtml);
  $('#Report_Content').enhanceWithin();
}
function viewTimeSheet(index, view) {
  var D = REPORT_USER_DATA[index];
  var Pilot = false;
  if (userShouldHaveFDT(Report_Data[index].staffType)) Pilot = true;
  if (view === true) {
    $('#Title_Sub_Report_Name').html(Report_Data[index].chatname);
    $('#Sub_Report_Content').html('');
  }
  var arrValues = [
    iTrans('Date'),
    iTrans('Start'),
    iTrans('End'),
    iTrans('Flt'),
    iTrans('Dty'),
    iTrans('Ident'),
    iTrans('DFR'),
    iTrans('Comment'),
  ];
  if (!Pilot) {
    arrValues = [
      iTrans('Date'),
      iTrans('Start'),
      iTrans('End'),
      iTrans('Flt'),
      iTrans('Hours'),
      iTrans('Unpaid'),
      iTrans('Overtime'),
      iTrans('Reg'),
      iTrans('FP'),
      iTrans('SD'),
      iTrans('Location'),
      iTrans('Ident'),
      iTrans('DFR'),
      iTrans('Comment'),
    ];
  }
  var ExperienceHeaders = '';
  var SpecialtyHeaders = '';
  var OtherHeaders = '';

  if (GROUP_DATA.FlightReports == 1) {
    OtherHeaders +=
      '<th>FR</th><th>' +
      iTrans('Job Num') +
      '</th><th>' +
      iTrans('Job Name') +
      '</th><th>' +
      iTrans('Rev') +
      '</th><th>' +
      iTrans('NonRev') +
      '</th><th>FR_' +
      iTrans('Total') +
      '</th>';
    arrValues.push('' + iTrans('FR') + '');
    arrValues.push('' + iTrans('Job_Num') + '');
    arrValues.push('' + iTrans('Job_Name') + '');
    arrValues.push('' + iTrans('Rev') + '');
    arrValues.push('' + iTrans('NonRev') + '');
    arrValues.push('' + iTrans('FR_Total') + '');
  }
  if (Pilot) {
    var ExperienceTypes = {
      DR: iTrans('Dual'),
      PIC: 'PIC',
      SIC: 'SIC',
      FI: 'Inst',
      XC: 'XC',
      AI: 'IFR',
      SI: 'SIM',
      GA: 'Prec Apr',
      IA: 'NonP Apr',
      DAY: iTrans('Day'),
      DAT: 'D TO',
      DL: 'D LND',
      NIGHT: iTrans('Night'),
      NT: 'N TO',
      NL: 'N LND',
    };
    var ExpKeys = Object.keys(ExperienceTypes);
    for (var H in ExperienceTypes) {
      ExperienceHeaders += '<th>' + ExperienceTypes[H] + '</th>';
      arrValues.push(ExperienceTypes[H]);
    }
    //optional renaming possible in future for some custom fields in specialty tracking

    var SpecialtyHeaders = '';
    var Specialty = {};

    for (var c in FDT_SPECIALTYV3.CS_Recs) {
      var C = FDT_SPECIALTYV3.CS_Recs[c];
      for (var f in C.Fields) {
        if (C.Fields[f].Field == 'FTO' || C.Fields[f].Field == 'FLD') {
          C.Fields[f].Used = 1; //to force showing Float landing and Takeoffs in admin reports
        }
        if (C.Fields[f].Used == 1) {
          SpecialtyHeaders += `<th>${C.Fields[f].Label}</th>`;
          Specialty[C.Fields[f].Field] = C.Fields[f].Label;
          arrValues.push(C.Fields[f].Label);
        }
      }
    }
    var SpecKeys = Object.keys(Specialty);
    OtherHeaders += ExperienceHeaders + SpecialtyHeaders;
  } else {
    arrValues.push(iTrans('Admin Comment'));
    arrValues.push(iTrans('Approved'));
  }

  if (view === true) {
    var html =
      '<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportTimeSheet_CSV_BTN">' +
      iTrans('Download CSV Data') +
      '</a></div>';
    html += "<h3 class='limit-table'>" + Report_Data[index].chatname + '</h3>';
  } else {
    var html = '';
  }
  var CSV = '';

  CSV += IterateCSV(arrValues, CSV);
  if (!Pilot) {
    html +=
      '<div style="max-width: 100%; overflow: auto;"><table data-filter="true" class="limit-table last-td-fill" border=1>' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('Date') +
      '</th>' +
      '<th>' +
      iTrans('Start') +
      '</th>' +
      '<th>' +
      iTrans('End') +
      '</th>' +
      '<th>' +
      iTrans('Flt') +
      '</th>' +
      '<th>' +
      iTrans('Hours') +
      '</th>' +
      '<th>' +
      iTrans('Unpaid') +
      '</th>' +
      '<th>' +
      iTrans('Overtime') +
      '</th>' +
      '<th>' +
      iTrans('Reg') +
      '</th>' +
      '<th>' +
      iTrans('FP') +
      '</th>' +
      '<th>' +
      iTrans('SD') +
      '</th>' +
      '<th>' +
      iTrans('Location') +
      '</th>' +
      '<th>' +
      iTrans('Ident') +
      '</th>' +
      '<th>' +
      iTrans('DFR') +
      '</th>' +
      '<th>' +
      iTrans('Comment') +
      '</th>' +
      OtherHeaders +
      '<th>' +
      iTrans('Admin Comment') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
  } else {
    html +=
      '<div style="max-width: 100%; overflow: auto;"><table data-filter="true" class="limit-table last-td-fill" border=1>' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('Date') +
      '</th>' +
      '<th>' +
      iTrans('Start') +
      '</th>' +
      '<th>' +
      iTrans('End') +
      '</th>' +
      '<th>' +
      iTrans('Flt') +
      '</th>' +
      '<th>' +
      iTrans('Dty') +
      '</th>' +
      '<th>' +
      iTrans('Ident') +
      '</th>' +
      '<th>DFR</th>' +
      '<th>' +
      iTrans('Comment') +
      '</th>' +
      OtherHeaders +
      '</tr>' +
      '</thead>' +
      '<tbody>';
  }
  var TotalFT = 0;
  var TotalDT = 0;
  for (var i in D.Records) {
    var C = D.Records[i];
    var Start = C.Start;
    var End = C.End;
    var FT = C.FT;
    var DT = dutytime_Calc(C.Start, C.End);
    var CSVstart = C.Start;
    var CSVend = C.End;
    var CSVft = C.FT;
    var CSVdt = DT;
    TotalFT += parseFloat(C.FT);
    if (C.IDENT == null) C.IDENT = '';
    if (C.Split == 1) {
      Start = C.Start + '<br />' + C.Start2;
      CSVstart = C.Start + '\n' + C.Start2;
      End = C.End + '<br />' + C.End2;
      CSVend = C.End + '\n' + C.End2;
      FT = C.FT + '<br />' + C.FT2;
      TotalFT += parseFloat(C.FT2);
      CSVft = parseFloat(C.FT) + parseFloat(C.FT2);
      CSVft = round10(CSVft);
      DT = DT + '<br />' + dutytime_Calc(C.Start2, C.End2);
      CSVdt = parseFloat(DT) + parseFloat(dutytime_Calc(C.Start2, C.End2));
      CSVdt = round10(CSVdt);
    }
    TotalDT += dutytime_Calc(C.Start, C.End);
    if (C.Split == 1) TotalDT += dutytime_Calc(C.Start2, C.End2);
    var recValues = [C.Date, CSVstart, CSVend, CSVft, CSVdt, C.IDENT, C.DFR, C.Comment];
    var RegH = 0;
    var Idents = '';
    if (!Pilot) {
      RegH = round10(CSVdt - C.Unpaid - C.Overtime);
      var ID = [];
      try {
        ID = JSON.parse(C.idents);
        for (var id in ID) {
          Idents += '[' + ID[id] + '] ';
        }
      } catch (e) {
        Idents = '';
      }
      recValues = [
        C.Date,
        CSVstart,
        CSVend,
        CSVft,
        CSVdt,
        C.Unpaid,
        C.Overtime,
        RegH,
        C.FieldPay,
        C.SickDay,
        C.LOC,
        Idents,
        C.DFR,
        C.Comment,
      ];
    }
    var ExperienceVals = '';
    var SpecialtyVals = '';
    if (GROUP_DATA.FlightReports == 1) {
      recValues.push(C.FR);
      ExperienceVals += '<td>' + C.FR + '</td>';

      recValues.push(C.FRJobNum);
      ExperienceVals += '<td>' + C.FRJobNum + '</td>';
      recValues.push(C.FRJobName);
      ExperienceVals += '<td>' + C.FRJobName + '</td>';

      recValues.push(C.FR_Rev);
      ExperienceVals += '<td>' + round10(C.FR_Rev) + '</td>';
      recValues.push(C.FR_NonRev);
      ExperienceVals += '<td>' + round10(C.FR_NonRev) + '</td>';
      recValues.push(C.FR_Total);
      ExperienceVals += '<td>' + round10(C.FR_Total) + '</td>';
    }
    if (Pilot) {
      for (var H in ExpKeys) {
        var E = ExpKeys[H];
        if (C.Recs[E] == null) C.Recs[E] = 0;
        ExperienceVals += '<td>' + C.Recs[E] + '</td>';
        recValues.push(C.Recs[E]);
      }
      for (var H in SpecKeys) {
        var S = SpecKeys[H];
        if (C.Recs[S] == null) C.Recs[S] = 0;
        SpecialtyVals += '<td>' + C.Recs[S] + '</td>';
        recValues.push(C.Recs[S]);
      }
    } else {
      recValues.push(C.AdminComment);
      recValues.push(C.Approved);
    }

    CSV += IterateCSV(recValues, CSV);

    if (!Pilot) {
      var bgclass = '';
      if (C.Approved == 1) bgclass = 'greenRecord';
      if (view == i && view !== true) bgclass = 'orangeRecord';
      html +=
        '<tr class="' +
        bgclass +
        '"><td>' +
        C.Date +
        '</td><td>' +
        Start +
        '</td><td>' +
        End +
        '</td><td>' +
        FT +
        '</td><td>' +
        DT +
        '</td><td>' +
        C.Unpaid +
        '</td><td>' +
        C.Overtime +
        '</td><td>' +
        RegH +
        '</td><td>' +
        C.FieldPay +
        '</td><td>' +
        C.SickDay +
        '</td><td>' +
        C.LOC +
        '<td>' +
        Idents +
        '</td><td>' +
        C.DFR +
        '</td><td>' +
        C.Comment +
        '</td>' +
        ExperienceVals +
        SpecialtyVals +
        '<td>' +
        C.AdminComment +
        '</td></tr>';
    } else {
      var bgclass = '';
      if (view == i && view !== true) bgclass = 'orangeRecord';
      html +=
        '<tr class="' +
        bgclass +
        '"><td>' +
        C.Date +
        '</td><td>' +
        Start +
        '</td><td>' +
        End +
        '</td><td>' +
        FT +
        '</td><td>' +
        DT +
        '</td><td>' +
        C.IDENT +
        '</td><td>' +
        C.DFR +
        '</td><td>' +
        C.Comment +
        '</td>' +
        ExperienceVals +
        SpecialtyVals +
        '</tr>';
    }
  }
  TotalDT = round10(TotalDT);
  //TotalFT = round10(parseFloat(D.Totals.FT) + parseFloat(D.Totals.FT2));
  TotalFT = round10(TotalFT);
  if (isNaN(TotalFT)) TotalFT = 0;
  var ExperienceValsTotals = '';
  var SpecialtyValsTotals = '';
  var recValues = [
    iTrans('Totals'),
    '',
    '',
    TotalFT + ' Hrs\n' + Report_Data[index].FltDays + ' ' + iTrans('Days') + '',
    TotalDT + ' Hrs\n' + Report_Data[index].WkDays + ' ' + iTrans('Days') + '',
    '',
    '',
    '',
  ];
  var Summary = {
    FlightTime: TotalFT,
    FlightDays: Report_Data[index].FltDays,
    WorkTime: TotalDT,
    WorkDays: Report_Data[index].WkDays,
    FR_Rev: 'N/A',
    FR_NonRev: 'N/A',
    FR_Total: 'N/A',
  };
  var RegTotal = 0;
  if (!Pilot) {
    RegTotal = round10(TotalDT - D.Totals.UP - D.Totals.OT);
    recValues = [
      iTrans('Totals'),
      '',
      '',
      TotalFT + ' Hrs\n' + Report_Data[index].FltDays + ' ' + iTrans('Days') + '',
      TotalDT + ' Hrs\n' + Report_Data[index].WkDays + ' ' + iTrans('Days') + '',
      D.Totals.UP,
      D.Totals.OT,
      RegTotal,
      D.Totals.FP,
      D.Totals.SD,
      '',
      '',
      '',
      '',
    ];
    Summary = {
      FlightTime: TotalFT,
      FlightDays: Report_Data[index].FltDays,
      WorkTime: TotalDT,
      WorkDays: Report_Data[index].WkDays,
      UnPaid: D.Totals.UP,
      Overtime: D.Totals.OT,
      RegularTime: RegTotal,
      FieldPay: D.Totals.FP,
      SickDay: D.Totals.SD,
      FR_Rev: 'N/A',
      FR_NonRev: 'N/A',
      FR_Total: 'N/A',
    };
  }

  var FR_Totals = '';
  if (GROUP_DATA.FlightReports == 1) {
    recValues.push('');
    recValues.push('');
    recValues.push('');
    FR_Totals += '<td></td>';
    FR_Totals += '<td></td>';
    FR_Totals += '<td></td>';
    recValues.push(Report_Data[index].FR_Rev);
    FR_Totals += '<td><b>' + Report_Data[index].FR_Rev + '</b></td>';
    recValues.push(Report_Data[index].FR_NonRev);
    FR_Totals += '<td><b>' + Report_Data[index].FR_NonRev + '</b></td>';
    recValues.push(Report_Data[index].FR_Total);
    FR_Totals += '<td><b>' + Report_Data[index].FR_Total + '</b></td>';
    Summary.FR_Rev = Report_Data[index].FR_Rev;
    Summary.FR_NonRev = Report_Data[index].FR_NonRev;
    Summary.FR_Total = Report_Data[index].FR_Total;
  }
  if (Pilot) {
    for (var H in ExpKeys) {
      var E = ExpKeys[H];
      ExperienceValsTotals += '<td><b>' + D.Totals[E] + '</b></td>';
      recValues.push(D.Totals[E]);
    }
    for (var H in SpecKeys) {
      var S = SpecKeys[H];
      SpecialtyValsTotals += '<td><b>' + D.Totals[S] + '</b></td>';
      recValues.push(D.Totals[S]);
    }
  } else {
    recValues.push('');
  }
  CSV += IterateCSV(recValues, CSV);
  if (!Pilot) {
    html +=
      '<tr><td><b>' +
      iTrans('Totals') +
      '</b></td><td></td><td></td><td><b>' +
      TotalFT +
      ' Hrs<br />' +
      Report_Data[index].FltDays +
      ' ' +
      iTrans('Days') +
      '</b></td><td><b>' +
      TotalDT +
      ' Hrs<br />' +
      Report_Data[index].WkDays +
      ' ' +
      iTrans('Days') +
      '</b></td><td><b>' +
      D.Totals.UP +
      '</b></td><td><b>' +
      D.Totals.OT +
      '</b></td><td><b>' +
      RegTotal +
      '</b></td><td><b>' +
      D.Totals.FP +
      '</b></td><td><b>' +
      D.Totals.SD +
      '</b></td><td></td><td></td><td></td><td></td>' +
      FR_Totals +
      ExperienceValsTotals +
      SpecialtyValsTotals +
      '<td></td></tr>';
  } else {
    html +=
      '<tr><td><b>' +
      iTrans('Totals') +
      '</b></td><td></td><td></td><td><b>' +
      TotalFT +
      ' Hrs<br />' +
      Report_Data[index].FltDays +
      ' ' +
      iTrans('Days') +
      '</b></td><td><b>' +
      TotalDT +
      ' Hrs<br />' +
      Report_Data[index].WkDays +
      ' ' +
      iTrans('Days') +
      '</b></td><td></td><td></td><td></td>' +
      FR_Totals +
      ExperienceValsTotals +
      SpecialtyValsTotals +
      '</tr>';
  }

  html += '</tbody></table></div>';

  if (view === true) {
    $('#Sub_Report_Content').html(html);
    $.mobile.changePage($('#Sub_Report_Page'), {
      reverse: false,
      changeHash: false,
    });
    $('#exitSubReportPage').click(exitshowTimeSheet);
    $('#Sub_Report_Content').enhanceWithin();
    DownloadCSV_DATA = CSV;
    $('#exportTimeSheet_CSV_BTN').on('click', function (event) {
      var data;
      var now = new Date();
      var filename =
        'Cirro_Timesheet_' +
        Report_Data[index].chatname +
        '_' +
        now.getFullYear() +
        '_' +
        (now.getMonth() + 1) +
        '_' +
        now.getDate() +
        '.csv';
      if (device.platform.toUpperCase() === 'ANDROID') {
        console.log('Opening base64 Android');
        event.preventDefault();
        LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
      } else if (IOS) {
        console.log('Opening base64 IOS');
        event.preventDefault();
        LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
      } else {
        console.log('Opening base64 Universal URL');
        var a = document.createElement('a');

        if (typeof a.download != 'undefined') {
          data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
          // set attributes href and target in the <a> element (with id  exportGPX)
          $(this).attr({
            href: data,
            target: '_blank',
            download: filename,
          });
          // let the click go through
        } else {
          // download attribute is not supported
          data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

          // set attributes href and target in the <a> element (with id  exportGPX)
          $(this).attr({
            href: data,
            target: '_system',
          });
        }
      }
    });
  } else {
    return { html: html, csv: CSV, summary: Summary };
  }
}

function exitshowTimeSheet() {
  $('#exitSubReportPage').unbind('click', exitshowTimeSheet);
  $.mobile.changePage($('#Report_Page'), {
    reverse: true,
    changeHash: false,
  });
}

var statusTS = {
  type: 'Ground',
  CurUser: 0,
  UserCount: 0,
  CurDay: 0,
  TotalDay: 0,
  UserIndex: [],
  UserData: {},
  UserRecs: [],
  UserTotals: {},
  TSreturn: false,
  FromDate: '',
  ToDate: '',
  ReportDays: 0,
};
function ProcessGroundTimeSheets() {
  statusTS.type = 'Ground';
  ProcessTimeSheetType();
}
function ProcessPilotTimeSheets() {
  statusTS.type = 'Pilot';
  ProcessTimeSheetType();
}
function ProcessTimeSheetType() {
  statusTS.CurUser = 0;
  statusTS.UserCount = 0;
  statusTS.CurDay = 0;
  statusTS.TotalDay = 0;
  statusTS.UserIndex = [];
  statusTS.UserData = {};
  statusTS.UserRecs = [];
  statusTS.UserTotals = {};
  statusTS.TSreturn = true;

  for (var i in Report_Data) {
    var I = Report_Data[i];
    if (statusTS.type == 'Ground') {
      if (!userShouldHaveFDT(I.staffType) && $('#TS_SEL_' + i).is(':checked')) {
        statusTS.UserIndex.push(i);
      }
    } else {
      if (userShouldHaveFDT(I.staffType) && $('#TS_SEL_' + i).is(':checked')) {
        statusTS.UserIndex.push(i);
      }
    }
  }
  statusTS.UserCount = statusTS.UserIndex.length;
  if (statusTS.UserCount == 0) {
    alert(iTrans('Please select at least one') + ' ' + statusTS.type + ' ' + iTrans('Staff to continue') + '');
    return;
  }
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });
  $('#exitSubReportPage').click(exitshowTimeSheet);

  ProcessTSindex(0);
}
function ProcessTSindex(index) {
  statusTS.UserData = Report_Data[statusTS.UserIndex[index]];
  statusTS.UserRecs = REPORT_USER_DATA[statusTS.UserIndex[index]].Records;
  statusTS.UserTotals = REPORT_USER_DATA[statusTS.UserIndex[index]].Totals;
  var CurUser = parseInt(index) + 1;
  statusTS.CurUser = index;
  $('#Title_Sub_Report_Name').html(CurUser + '/' + statusTS.UserCount + ' - ' + statusTS.UserData.chatname);
  statusTS.CurDay = 0;
  statusTS.TotalDay = statusTS.UserRecs.length;

  processTSday(0);
}
function prevTSuser() {
  if (statusTS.CurUser != 0) statusTS.CurUser--;
  ProcessTSindex(statusTS.CurUser);
}
function nextTSuser() {
  if (statusTS.CurUser != statusTS.UserIndex.length - 1) {
    statusTS.CurUser++;
    ProcessTSindex(statusTS.CurUser);
  } else {
    TimeSheetExportReport = [];
    TimesheetIndividualReports = [];
    createTSsummary(0);
  }
}
function prevTSday() {
  if (statusTS.CurDay != 0) statusTS.CurDay--;
  processTSday(statusTS.CurDay);
}
function nextTSday() {
  if (statusTS.CurDay != statusTS.UserRecs.length - 1) {
    statusTS.CurDay++;
    processTSday(statusTS.CurDay);
  } else {
    nextTSuser();
  }
}
function processTSday(index) {
  var CurDay = parseInt(statusTS.CurDay) + 1;
  var CurUser = parseInt(statusTS.CurUser) + 1;
  var D = statusTS.UserRecs[index];
  var txt = '';
  var disable = '';
  if (D.Approved == 1) {
    txt = ' *Approved';
  }
  var nav =
    '<div class="itin-nav"><table class="limit-table last-td-fill"><tbody><tr>' +
    '<td><button data-mini="true" class="cirro-btn ui-btn ui-shadow ui-corner-all ui-mini" onclick="prevTSuser()"><div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i>' +
    iTrans('Prev User') +
    '</div></button></td>' +
    '<td><button data-mini="true" class="cirro-btn ui-btn ui-shadow ui-corner-all ui-mini" onclick="nextTSuser()"><div class="vertical-center-container">' +
    iTrans('Next User') +
    '<i class="icon-margins fal fa-chevron-right"></i></div></button></td><td><h2' +
    iTrans('User') +
    ' ' +
    CurUser +
    '/' +
    statusTS.UserCount +
    ' - ' +
    statusTS.UserData.chatname +
    '</h2></td>' +
    '</tr></tbody></table></div>';
  nav +=
    '<div class="itin-nav"><table class="limit-table first-td-fill"><tbody><tr><td><h2>' +
    iTrans('Day') +
    ' ' +
    CurDay +
    '/' +
    statusTS.TotalDay +
    ' - ' +
    statusTS.UserRecs[statusTS.CurDay].Date +
    txt +
    '</h2></td>' +
    '<td><button data-mini="true" class="cirro-btn ui-btn ui-shadow ui-corner-all ui-mini" onclick="prevTSday()"><div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i>' +
    iTrans('Prev Day') +
    '</div></button></td>' +
    '<td><button data-mini="true" class="cirro-btn ui-btn ui-shadow ui-corner-all ui-mini" onclick="nextTSday()"><div class="vertical-center-container">' +
    iTrans('Next Day') +
    '<i class="icon-margins fal fa-chevron-right"></i></div></button></td>' +
    '</tr></tbody></table></div>';
  var view = viewTimeSheet(statusTS.UserIndex[statusTS.CurUser], statusTS.CurDay);
  if (statusTS.type == 'Ground') {
    var html =
      '<br /><div class="limit-table"><label for="AdminTSComment">' +
      iTrans('Admin Comment') +
      '</label><input type="text" id="AdminTSComment" value="' +
      escapeHtml(D.AdminComment) +
      '"/></div>';
    html += '<div id="TS_Expenses" class="limit-table"></div><br />';

    html +=
      '<button onClick="approveTSday();" data-mini="true" class="greenbg">' +
      iTrans('Approve Day and Comment') +
      ' -> [' +
      iTrans('Lock record') +
      ']</button><br />';
    html +=
      '<button onClick="AdminCommentTSday();" data-mini="true" class="yellowbg" >' +
      iTrans('Save Admin Comment Only') +
      ' -> [' +
      iTrans('Unlock record') +
      ']</button>';
  } else {
    var html = '<div id="TS_Expenses" class="limit-table"></div><br />';
  }
  $('#Sub_Report_Content').html(nav + view.html + html);
  $('#Sub_Report_Content').enhanceWithin();
  if (GROUP_DATA.FlightReports == 1) {
    TS_LoadExpenses();
  }
}
function approveTSday() {
  var D = statusTS.UserRecs[statusTS.CurDay];
  D.Approved = 1;
  D.AdminComment = $('#AdminTSComment').val();
  D.Modified = dateToStringObject(new Date());
  UpdateTSrecord(D);
}
function AdminCommentTSday() {
  var D = statusTS.UserRecs[statusTS.CurDay];
  D.Approved = 0;
  D.AdminComment = $('#AdminTSComment').val();
  D.Modified = dateToStringObject(new Date());
  UpdateTSrecord(D);
}
function UpdateTSrecord(D) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Administration-Reports-AJAX.php?report=UpdateTimesheet&User=' +
      statusTS.UserData.PrimaryKey +
      '&Approved=' +
      D.Approved +
      '&AdminComment=' +
      encodeURIComponent(D.AdminComment) +
      '&Modified=' +
      encodeURIComponent(D.Modified) +
      '&Date=' +
      statusTS.UserRecs[statusTS.CurDay].Date,
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Update Timesheet Record') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'UpdateTSrecord');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          if (json_data[0].ServerResponse == 'Success') nextTSday();
        }
      } else {
        alert('<h1>' + iTrans('Error Cannot Update Timesheet Record') + '</h1>'); //retrieve old data from database
      }
    }
  ).fail(function (jqXHR, status, error) {
    $.mobile.loading('hide');
    AJAXFAIL(error);
  });
}
function TS_LoadExpenses() {
  // If the user does not have expense permisisons do not render the expense data
  if (!VueBridge.hasPermission('billing.expenses.useAndReport')) {
    return;
  }

  //switch to admin page and get data from server

  $('#TS_Expenses').html('<h2>' + iTrans('Retrieving Expenses') + '</h2>');
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getUserExpenses&User=' +
      statusTS.UserData.PrimaryKey +
      '&Date=' +
      statusTS.UserRecs[statusTS.CurDay].Date,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Expense data') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'TS_LoadExpenses');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          statusTS.Expenses = json_data[0];
          TS_ShowUserExpenses();
        }
      } else {
        $('#TS_Expenses').html('<h2>' + iTrans('No Expenses Recorded') + '</h2>');
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function TS_ShowUserExpenses() {
  var E = statusTS.Expenses.UserExpenses;
  //display list of expenses for admin users in BILLING_ADMINEXPENSES

  var total = 0;

  if (E.length > 0) {
    var html =
      '<h3>' +
      iTrans('Expenses') +
      '</h3><center><b>' +
      iTrans('Flags') +
      ': A </b>= ' +
      iTrans('Approved') +
      ', <b>P</b> = ' +
      iTrans('Paid') +
      ', <b>B</b> = ' +
      iTrans('Billable') +
      ', <b>I</b> = ' +
      iTrans('Invoiced') +
      ', <b>R</b> = ' +
      iTrans('Reimburseable') +
      '</center>';
    html +=
      '<table id="TS_ExpensesTable" class="first-td-fill wrap"><thead><tr><th>' +
      iTrans('User') +
      '</th><th>' +
      iTrans('Client') +
      '</th><th>' +
      iTrans('Date') +
      '</th><th>' +
      iTrans('Category') +
      '</th><th>' +
      iTrans('Merchant') +
      '</th><th>' +
      iTrans('Amount') +
      '</th><th>' +
      iTrans('Flags') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var i in E) {
      var e = E[i];

      let actions = '';

      //approved
      if (VueBridge.hasPermission('billing.expenses.administrate')) {
        actions = `<div class='vertical-center-container'>
            <button onClick='BILLING_AdminViewExpenseTS(${i})' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button>
            <button onClick='BILLING_DeleteExpense(${e.ExpensePK})' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button>
          </div>`;
      } else {
        actions = "<div class='vertical-center-container'></div>";
      }

      var bgclass = '';

      if (e.Approved == 0 && e.Reimburse == 1) bgclass = 'redRecord';
      if (e.Approved == 1 && e.Reimburse == 1 && e.Paid == 0) bgclass = 'orangeRecord';
      if ((e.Paid == 1 && e.Reimburse == 1 && e.Approved == 1) || (e.Approved == 1 && e.Reimburse == 0))
        bgclass = 'greenRecord';

      //needs to be marked embursed or paid
      if (e.Paid == 0 && e.Approved == 1 && e.Reimburse == 1) {
        actions = `<div class='vertical-center-container'>
          <button onClick='BILLING_AdminViewExpenseTS(${i})' data-mini='true' class='action-btn'><img src='./images/inspect.svg'/></button>`;

        if (VueBridge.hasPermission('billing.expenses.administrate')) {
          actions += `<button onClick='BILLING_MarkReimbursed(${e.ExpensePK})' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>`;
        }
      }

      //needs approval
      const needsApproval = (e.Approved == 0 && e.Billable == 1) || (e.Approved == 0 && e.Reimburse == 1);
      if (needsApproval && VueBridge.hasPermission('billing.expenses.administrate')) {
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_ProcessExpense(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteExpense(" +
          e.ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
      }

      if (e.Approved == 1 && e.Paid == 1)
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_AdminViewExpenseTS(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";

      if (actions == '') {
        actions = "<div class='vertical-center-container'></div>";
      }

      var A = '';
      if (e.Approved == 1) A = 'A';
      var P = '';
      if (e.Paid == 1) P = 'P';
      var B = '';
      if (e.Billable == 1) B = 'B';
      var I = '';
      if (e.Invoiced == 1) I = 'I';
      var R = '';
      if (e.Reimburse == 1) R = 'R';
      html +=
        '<tr class="' +
        bgclass +
        '"><td>' +
        e.chatname +
        '</td><td>' +
        e.Business +
        '</td><td>' +
        e.Date +
        '</td><td>' +
        e.Category +
        '</td><td>' +
        e.Merchant +
        '</td><td>' +
        e.Amount.toLocaleString('en-US', CUR) +
        ' ' +
        e.Currency +
        '</td><td>' +
        A +
        ' ' +
        P +
        ' ' +
        B +
        ' ' +
        I +
        ' ' +
        R +
        '</td><td>' +
        actions +
        '</td></tr>';
      total += e.Amount;
    }
    html += '</table>';
    $('#TS_Expenses').html(html);
    $('#TS_Expenses').enhanceWithin();
    AddTableSorter('TS_ExpensesTable');
  } else {
    $('#TS_Expenses').html('<h2>' + iTrans('No Expenses Recorded') + '</h2>');
  }
}
function BILLING_AdminViewExpenseTS(index) {
  //TODO VIEW PICTURES OF RECEIPT
  // BILLING_ADMINEXPENSEVIEW div for admin expense view

  var B = statusTS.Expenses.UserExpenses[index];
  //console.log(B);
  $.mobile.changePage($('#BILLING_AdminViewExpensePage'), {
    changeHash: false,
  });
  if (B.Filename != 'N/A') {
    var fileEXT = B.Filename.split('.').pop().toLowerCase();
    var filetype = 'data:image/png';
    if (fileEXT == 'pdf') filetype = 'data:application/' + fileEXT;
    else filetype = 'data:image/' + fileEXT;
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpensePhoto&PK=' + B.ExpensePK, function (json_data) {
      //$("#BILLING_ADMIN_EXPENSE_VIEW_PHOTO").html("<h3>"+B.Filename+"</h3><img src='data:image/"+filetype+";base64,"+json_data.photo+"' />");
      B.fileType = filetype;
      B.fileData = json_data.photo;
      displayExpenseInfo(B, fileEXT);
    });
  } else {
    B.fileType = '';
    B.fileData = '';
    displayExpenseInfo(B, null);
  }
}
var TimeSheetExportReport;
function createTSsummary(index) {
  if (index < statusTS.UserIndex.length) {
    var UserPK = Report_Data[statusTS.UserIndex[index]].PrimaryKey;
    var data = viewTimeSheet(statusTS.UserIndex[index], -1);
    data.username = Report_Data[statusTS.UserIndex[index]].chatname;
    data.summary.ExpenseTotal = 'N/A';
    TimeSheetExportReport.push(data);
    if (GROUP_DATA.FlightReports == 1) TS_LoadImburseableExpenses(index, UserPK);
    else {
      index++;
      createTSsummary(index);
    }
  } else {
    //done
    console.log(TimeSheetExportReport);
    var CSV = '' + iTrans('Reporting From') + ' ' + statusTS.FromDate + ' To ' + statusTS.ToDate + '\n';
    CSV += '\n' + '' + iTrans('SUMMARY REPORT') + '' + '\n' + '\n';
    var DetailedCSV = '';
    $('#Title_Sub_Report_Name').html('' + iTrans('Timesheet Report Summary and Export') + '');
    var html = '<div class="limit-table"><h3>' + iTrans('Timesheet Report Summary and Export') + '</h3>';
    html += '<h4>' + iTrans('Reporting From') + ' ' + statusTS.FromDate + ' To ' + statusTS.ToDate + '</h4>';
    if (statusTS.type == 'Ground') {
      html +=
        '<table class="limit-table"><thead><tr><th>' +
        iTrans('Username') +
        '</th><th>' +
        iTrans('Hours') +
        '</th><th>' +
        iTrans('Unpaid') +
        '</th><th>' +
        iTrans('Overtime') +
        '</th><th>' +
        iTrans('Regular') +
        '</th><th>' +
        iTrans('FieldPay') +
        '</th><th>' +
        iTrans('FltDays') +
        '</th><th>' +
        iTrans('NormDays') +
        '</th><th>' +
        iTrans('Rev') +
        '</th><th>' +
        iTrans('NonRev') +
        '</th><th>' +
        iTrans('FR_Total') +
        '</th><th>' +
        iTrans('Expenses') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead><tbody>';
      CSV += IterateCSV(
        [
          iTrans('Username'),
          iTrans('Hours'),
          iTrans('Unpaid'),
          iTrans('Overtime'),
          iTrans('Regular'),
          iTrans('FieldPay'),
          iTrans('FltDays'),
          iTrans('NormDays'),
          iTrans('Rev'),
          iTrans('NonRev'),
          iTrans('FR_Total'),
          iTrans('Expenses'),
        ],
        CSV
      );
    } else {
      html +=
        '<table class="limit-table"><thead><tr><th>Username</th><th>Hours</th><th>FltDays</th><th>NormDays</th><th>Rev</th><th>NonRev</th><th>FR_Total</th><th>Expenses</th><th>Action</th></tr></thead><tbody>';
      CSV += IterateCSV(
        [
          iTrans('Username'),
          iTrans('Hours'),
          iTrans('FltDays'),
          iTrans('NormDays'),
          iTrans('Rev'),
          iTrans('NonRev'),
          iTrans('FR_Total'),
          iTrans('Expenses'),
        ],
        CSV
      );
    }
    for (var i in TimeSheetExportReport) {
      var I = TimeSheetExportReport[i];
      var S = I.summary;
      if (statusTS.type == 'Ground') {
        html +=
          '<tr><td>' +
          I.username +
          '</td><td>' +
          S.WorkTime +
          '</td><td>' +
          S.UnPaid +
          '</td><td>' +
          S.Overtime +
          '</td><td>' +
          S.RegularTime +
          '</td><td>' +
          S.FieldPay +
          '</td><td>' +
          S.FlightDays +
          '</td><td>' +
          S.WorkDays +
          '</td><td>' +
          S.FR_Rev +
          '</td><td>' +
          S.FR_NonRev +
          '</td><td>' +
          S.FR_Total +
          '</td><td>' +
          S.ExpenseTotal +
          '</td><td><button class="action-btn" onClick="DownloadTimesheetCSV(' +
          i +
          ');"><img src="./images/import.svg" /></button></td></tr>';
        var recValues = [
          I.username,
          S.WorkTime,
          S.UnPaid,
          S.Overtime,
          S.RegularTime,
          S.FieldPay,
          S.FlightDays,
          S.WorkDays,
          S.FR_Rev,
          S.FR_NonRev,
          S.FR_Total,
          S.ExpenseTotal,
        ];
      } else {
        html +=
          '<tr><td>' +
          I.username +
          '</td><td>' +
          S.WorkTime +
          '</td><td>' +
          S.FlightDays +
          '</td><td>' +
          S.WorkDays +
          '</td><td>' +
          S.FR_Rev +
          '</td><td>' +
          S.FR_NonRev +
          '</td><td>' +
          S.FR_Total +
          '</td><td>' +
          S.ExpenseTotal +
          '</td><td><button class="action-btn" onClick="DownloadTimesheetCSV(' +
          i +
          ');"><img src="./images/import.svg" /></button></td></tr>';
        var recValues = [
          I.username,
          S.WorkTime,
          S.FlightDays,
          S.WorkDays,
          S.FR_Rev,
          S.FR_NonRev,
          S.FR_Total,
          S.ExpenseTotal,
        ];
      }
      CSV += IterateCSV(recValues, CSV);
      TimesheetIndividualReports[i] = I.username + '\n\n' + CSV + '\n' + iTrans('DETAILED REPORT') + '\n\n' + I.csv;
      DetailedCSV += '\n' + '' + iTrans('Username') + ': ' + I.username + '\n';
      DetailedCSV += I.csv;
    }
    html +=
      '</tbody></table><div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportTimeSheet_CSV_BTN">' +
      iTrans('Download Full Report CSV Data') +
      '</a></div></div>';
    $('#Sub_Report_Content').html(html);
    $('#Sub_Report_Content').enhanceWithin();
    DownloadCSV_DATA = CSV + '\n' + '' + iTrans('DETAILED REPORT') + '' + '\n' + DetailedCSV;
    $('#exportTimeSheet_CSV_BTN').on('click', function (event) {
      var data;
      var now = new Date();
      var filename =
        'Cirro_' + statusTS.type + '_Timesheet_Report_' + statusTS.FromDate + '_' + statusTS.ToDate + '.csv';
      if (device.platform.toUpperCase() === 'ANDROID') {
        console.log('Opening base64 Android');
        event.preventDefault();
        LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
      } else if (IOS) {
        console.log('Opening base64 IOS');
        event.preventDefault();
        LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
      } else {
        console.log('Opening base64 Universal URL');
        var a = document.createElement('a');

        if (typeof a.download != 'undefined') {
          data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
          // set attributes href and target in the <a> element (with id  exportGPX)
          $(this).attr({
            href: data,
            target: '_blank',
            download: filename,
          });
          // let the click go through
        } else {
          // download attribute is not supported
          data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

          // set attributes href and target in the <a> element (with id  exportGPX)
          $(this).attr({
            href: data,
            target: '_system',
          });
        }
      }
    });
  }
}
var TimesheetIndividualReports;
function DownloadTimesheetCSV(index) {
  console.log(TimesheetIndividualReports[index]);
  var data;
  var now = new Date();
  var filename =
    'Cirro_Timesheet_Report_' +
    TimeSheetExportReport[index].username +
    '_' +
    statusTS.FromDate +
    '_' +
    statusTS.ToDate +
    '.csv';
  if (device.platform.toUpperCase() === 'ANDROID') {
    console.log('Opening base64 Android');
    event.preventDefault();
    LoadMobolizeFile(filename, btoa(TimesheetIndividualReports[index]), 'text/csv');
  } else if (IOS) {
    console.log('Opening base64 IOS');
    event.preventDefault();
    LoadMobolizeFile(filename, btoa(TimesheetIndividualReports[index]), 'text/csv');
  } else {
    console.log('Opening base64 Universal URL');
    var a = document.createElement('a');

    if (typeof a.download != 'undefined') {
      data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(TimesheetIndividualReports[index]);
      // set attributes href and target in the <a> element (with id  exportGPX)
      a.href = data;
      a.target = '_blank';
      a.download = filename;

      // let the click go through
      a.click();
      a.remove();
    } else {
      // download attribute is not supported
      data = 'data:text/csv;base64,' + btoa(TimesheetIndividualReports[index]);

      // set attributes href and target in the <a> element (with id  exportGPX)
      a.href = data;
      a.target = '_blank';
      a.download = filename;
      a.click();
      a.remove();
    }
  }
}

function TS_LoadImburseableExpenses(index, UserPK) {
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getUserExpenseTotals&User=' +
      UserPK +
      '&FromDate=' +
      statusTS.FromDate +
      '&ToDate=' +
      statusTS.ToDate,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>Offline Cannot Get Expense data</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'TS_LoadExpenses');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          if (json_data[0].ExpenseTotal == null) TimeSheetExportReport[index].summary.ExpenseTotal = 0;
          else TimeSheetExportReport[index].summary.ExpenseTotal = json_data[0].ExpenseTotal;
          index++;
          createTSsummary(index);
        }
      } else {
        TimeSheetExportReport[index].summary.ExpenseTotal = 0;
        index++;
        createTSsummary(index);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}
