let defaultOrder = [];
let defaultsToShow = [];
let modulesToShow = ['#TDGmenuBTN', '#Manage', '#WB'];

/**
 * Finds what modules are allowed to be accessed by the user
 * Creates the list from that
 * @param preferenceList The list of preferences
 * @param defaults
 */
function buildUserModuleArray(preferenceList, defaults) {
  defaultsToShow = defaults;
  let IDs = [];

  window.vueApp.authTools.authRefreshUser();
  let hasPermission = window.vueApp.authTools.authHasPermission(
    window.vueApp.groupKey,
    'safetyManagement.reports.submitReports,safetyManagement.flightRiskAssessments.use'.split(',')
  );
  if (hasPermission) {
    modulesToShow.push('#SMSmenu');
  }

  $('#hiddenModules')
    .find('.flex-icon')
    .each(function () {
      IDs.push(`#${this.id}`);
    });

  defaultOrder = IDs;

  //Go through the group and user rights to select which modules they are allowed to see
  //Essentially the same as set_user_rights,
  if (ITINERARY === 1) {
    modulesToShow.push('#Following');
  }
  if (USER_RIGHTS.StudentPilot == 1) {
    modulesToShow.push('#BILLING_AccountBalance');
  }
  hasPermission = window.vueApp.authTools.authHasPermission(
    window.vueApp.groupKey,
    'billing.flightReports.use'.split(',')
  );

  if (GROUP_DATA.FlightReports == 1) {
    if (hasPermission) {
      modulesToShow.push('#BILLING_NewFlightReport');
    }
  }
  if (GROUP_DATA.Expenses == 1) {
    modulesToShow.push('#BILLING_Expenses');
  }
  if (GROUP_DATA.Expenses == 1) {
    if (USER_RIGHTS.ExpenseAccess === 1) {
      modulesToShow.push('#BILLING_AdminExpenses');
    }
  }
  if (GROUP_DATA.ClientJobs == 1) {
    if (USER_RIGHTS.BillingAccess === 1) {
      modulesToShow.push('#BILLING_ClientListBTN');
    }
  }
  if (GROUP_DATA.billing == 1) {
    if (USER_RIGHTS.InvoiceAccess === 1) {
      modulesToShow.push('#BILLING_AdminInvoicing');
    }
  }

  if (USER_RIGHTS.StudentPilot > 0) {
    modulesToShow.push('#BILLING_AccountBalance');
  }
  if (GROUP_DATA.Personal_Itin > 0) {
    modulesToShow.push('#PERSONAL_ITIN_Report');
  }

  if (DUTYTIMES === 1 || (DUTYTIMES === 0 && USERTYPE === 'Pilot' && TIMESHEETS === 1)) {
    modulesToShow.push('#duty');
  }

  if (ASSETTRACKING === 1) {
    modulesToShow.push('#assets');
  }

  if (SCHEDULER === 1) {
    modulesToShow.push('#schedulerBTN');
  }

  if (MAPPING === 1) {
    modulesToShow.push('#Planning');
  }
  hasPermission = window.vueApp.authTools.authHasPermission(
    window.vueApp.groupKey,
    'library.certifiables.view,library.contacts.use,library.documents.use,library.memos.use,library.forms.use'.split(
      ','
    )
  );
  if (
    (GROUP_DATA.Documents === 1 || GROUP_DATA.Certs === 1 || GROUP_DATA.Memos === 1 || GROUP_DATA.Forms === 1) &&
    hasPermission
  ) {
    modulesToShow.push('#docs');
  }

  if (USER_RIGHTS.SuperUser === 1 || USER_RIGHTS.AdminAccess === 1) {
    modulesToShow.push('#Administration');
  }

  if (GROUP_DATA.Quoting === 1 && (USER_RIGHTS.QuotingAccess === 1 || USER_RIGHTS.SuperUser === 1)) {
    modulesToShow.push('#BILLING_AdminQuoting');
  }

  if (GROUP_DATA.Pax_Man === 1) {
    modulesToShow.push('#PaxManifesting');
  }

  modulesToShow = sortAccordingToDefault(modulesToShow);
  modulesToShow.forEach((item) => {
    buildListItem(item, preferenceList);
  });
  //If the user has some preferences selected, have the "Show All Modules" button de-selected
  if (preferenceList.length === 0) {
    $('#showAllCHK').prop('checked', true);
  } else {
    $('#showAllCHK').prop('checked', false);
  }
  $('#showAllCHK').click(function () {
    //if they have toggled "show all modules" to true, remove the checked status
    //from all of the checkboxes, and the 'checked' styles from the labels
    if (this.checked) {
      $('#favModuleListContainer').find('input[type=checkbox]:checked').prop('checked', false);
      $('#favModuleListContainer').find('label').removeClass('ui-checkbox-on');
      $('#favModuleListContainer').find('label').addClass('ui-checkbox-off');
    }
  });
  //Method to be applied to all module inputs
  $('#favModuleListContainer')
    .find('input')
    .click(function () {
      //If they have deselected all options, "re-check" the 'Show All modules" button
      let count = $('#favModuleListContainer').find('input[type=checkbox]:checked').length;
      if (count === 0) {
        $('#showAllCHK_TXT').removeClass('ui-checkbox-off');
        $('#showAllCHK_TXT').addClass('ui-checkbox-on');
        $('#showAllCHK').prop('checked', true);
      } else {
        $('#showAllCHK_TXT').removeClass('ui-checkbox-on');
        $('#showAllCHK_TXT').addClass('ui-checkbox-off');
        $('#showAllCHK').prop('checked', false);
      }
    });
  defaultOrder = modulesToShow;

  const toSync = SYNC_MENU_PREFS === true || SYNC_MENU_PREFS === 'true';

  $('#syncPrefsCHK').prop('checked', toSync);
}

/**
 * Build the list item component to display
 * @param moduleID the module to display
 * @param prefList the list of preferences
 */
function buildListItem(moduleID, prefList) {
  // Don't add the module if it already exists
  if (document.getElementById(moduleID) != null) {
    return;
  }

  //Check the preferences to see if they already favourited them
  let checked = '';
  if (prefList.find((preference) => preference === moduleID)) {
    checked = 'checked="checked"';
  }
  let moduleContainer = $('<div class="grid-col-lg-6 grid-col-md-6 grid-col-sm-12 fav-div" ></div>').appendTo(
    $('#favModuleListContainer')
  );
  let moduleText = $(moduleID).children('.icon-label').text();
  //Change the logbook/FDT label to account for 3 labels it *can* have
  if (moduleID === '#duty') {
    moduleText = !userShouldHaveFDT(USERTYPE) ? 'Time Sheet' : 'Flight Duty Times';
    moduleText = userShouldHaveFDT(USERTYPE) && LITE === 1 ? 'Log Book' : 'Flight Duty Times';
  }
  //create the list item
  let moduleLabel = $(
    `<label for="${moduleID}" v-t="homePageSettings.favouriteModules.modules.${moduleText}"></label>`
  ).appendTo(moduleContainer);
  moduleContainer.append(
    `<input ${checked} id="${moduleID}" type="checkbox" class="favourites-input" value="${moduleID}">`
  );
  let icon = $(moduleID).children('.svg-icon').clone();

  icon[0].setAttribute('width', '35px');
  icon[0].setAttribute('height', '35px');

  moduleContainer.prepend(icon.clone());
  moduleLabel.append(moduleText);
}

/**
 *  Handles a new preference set
 *  Moves things to/from favourites box
 *  Saves to local DB
 * @returns {null} If no new changes detected
 */
async function saveMenuFavourites() {
  //Get the old preferences
  let oldPrefs = MENU_PREFERENCES;
  oldPrefs = oldPrefs === '' || oldPrefs == null ? [] : oldPrefs.split(',');
  //Get the new preferences
  let unSortedPrefs = [];
  $('.favourites-input:checkbox:checked').each(function () {
    let sThisVal = this.checked ? $(this).val() : '';
    unSortedPrefs.push(sThisVal);
  });
  let sortedPrefs = sortAccordingToDefault(unSortedPrefs);
  let userWantsToSync = $('#syncPrefsCHK').is(':checked');
  //Verify that there is new changes
  if (oldPrefs.length === 0 && unSortedPrefs.length === 0) {
    $('#errorBox').show();
    $('#errorMessage').text(iTrans('No changes detected'));
    return null;
  }
  let oldSyncPrefs = SYNC_MENU_PREFS === true || SYNC_MENU_PREFS === 'true';
  if (
    JSON.stringify(oldPrefs.sort()) === JSON.stringify(unSortedPrefs.sort()) &&
    oldSyncPrefs == $('#syncPrefsCHK').is(':checked')
  ) {
    $('#errorBox').show();
    $('#errorMessage').text(iTrans('No changes detected'));
    return null;
  }

  //Hide it, because everything has checked out so far
  $('#errorBox').hide();
  //Save the new preferences in the DB, and update the localstorage variable
  localStorageDB.setItem('MENU_PREFERENCES', sortedPrefs);
  MENU_PREFERENCES = sortedPrefs.toString();

  let menuSynced = false;
  if (userWantsToSync) {
    menuSynced = await syncPreferenceSettings(sortedPrefs);
    SYNC_MENU_PREFS = true;
    localStorageDB.setItem('SYNC_MENU_PREFS', true);
  } else {
    SYNC_MENU_PREFS = false;
    localStorageDB.setItem('SYNC_MENU_PREFS', false);
  }

  if (menuSynced === false && userWantsToSync) {
    $('#errorBox').show();
    $('#errorMessage').text(iTrans('An error occurred syncing'));
  }
  //If they updated to have no preferences, hide the fav box,show the default box
  //and move any old settings back
  if (sortedPrefs.length === 0) {
    $('#favouriteBox').hide();
    $('#hiddenModules').show();
    $('#hiddenModules').children().appendTo('#favouriteBox');
    $('#toggleHiddenDiv').hide();
    defaultOrder.forEach(function (item) {
      let element = $(item).parent().detach();
      $('#hiddenModules').append(element);
    });
    defaultsToShow.forEach(function (item) {
      let element = $(item).parent().detach();
      $('#hiddenModules').append(element);
    });
  } else {
    //They have favourited some modules, hide the default box, remove all old preferences and add the new ones
    if (!$('#toggleHiddenButton').is(':checked')) {
      $('#hiddenModules').hide();
    }
    $('#favouriteBox').show();
    $('#toggleHiddenDiv').show();
    $('#hiddenModules').children().appendTo('#favouriteBox');
    defaultOrder.forEach(function (item) {
      let element = $(item).parent().detach();
      $('#hiddenModules').append(element);
    });
    sortedPrefs.forEach(function (item) {
      let element = $(item).parent().detach();
      $('#favourites').append(element);
    });
    defaultsToShow.forEach(function (item) {
      let element = $(item).parent().detach();
      $('#favourites').append(element);
    });
  }
  //display a nice message
  if (menuSynced || !userWantsToSync) {
    cSAMA_Dialog.ShowDialog({ title: iTrans('Success'), message: iTrans('Your preferences have been saved') });
  }
}

/**
 * Uploads the new preferences to capi
 * @param preferences The preferences to be synced with the server
 * @returns {Promise<boolean>} True if upload process was successful, false if failed
 */
async function syncPreferenceSettings(preferences) {
  try {
    await RemoteData.patch(
      `api/v2/groups/${GROUP_DATA.PrimaryKey}/users/${USER_PK}/preferences/general.menuPreferences`,
      { setting_value: preferences.toString() }
    );
  } catch (e) {
    return false;
  }
  return true;
}

function sortAccordingToDefault(toBeSorted) {
  let toBeReturned = [];
  defaultOrder.forEach(function (item) {
    if (toBeSorted.includes(item)) {
      toBeReturned.push(item);
    }
  });
  return toBeReturned;
}
