/**
 *
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class cSAMA_Scheduler {
  static AddEvents(_start, _end, _timezone, _coas, _pogs, _dayPilot, _fontSize) {
    if (!_coas && !_pogs) {
      return;
    }

    let requests = [];

    if (_coas) {
      requests[0] = new cSAMA_Request('Scheduler', 'getCorrectiveActions');
    }

    for (let i = 0; i < requests.length; i++) {
      requests[i].Set('start', _start);
      requests[i].Set('end', _end);
      requests[i].Set('timezone', _timezone);
    }

    cSAMA_Request.Dispatch({
      name: 'UpdateSchedule',
      reqs: requests,
      clientErrorCode: 'SAMA 63 DA 5B 72',
      goBackOnError: false,
      fnSuccess: (_results) => {
        cSAMA_Scheduler.OnDownloaded(_results, _dayPilot, _fontSize);
      },
    });
  }

  static OnDownloaded(_results, _dayPilot, _fontSize) {
    for (let i = 0; i < _results.length; i++) {
      for (let r = 0; r < _results[i].length; r++) {
        let currElement = _results[i][r];
        currElement.fontSize = _fontSize;

        currElement.usr_assignees = currElement.usr_assignees ? currElement.usr_assignees : '-';
        currElement.usr_completer = currElement.usr_completer ? currElement.usr_completer : '-';

        currElement.type = currElement.hasOwnProperty('coa_name') ? 'COA' : 'POG';
        currElement.id = currElement.type === 'COA' ? currElement.coa_primaryKey : currElement.pog_primaryKey;

        let markup;
        if (currElement.type === 'COA') {
          currElement.coa_termName = cSAMA_Utils.GetCapTermName(currElement.coa_term);

          currElement.color = '#a8b0ff';
          markup = cSAMA_Scheduler.GetMarkup(
            currElement,
            currElement.coa_completedDate,
            currElement.coa_dueDate,
            SAMA.Templates.tpl.correctiveAction.dayPilot
          );
        } else if (currElement.type === 'POG') {
          currElement.color = '#99e7ff';
          markup = cSAMA_Scheduler.GetMarkup(
            currElement,
            currElement.pog_completedDate,
            currElement.pog_dueDate,
            SAMA.Templates.tpl.objectives.dayPilot
          );
        }

        if (markup == null) {
          return;
        }

        let event = cSAMA_Scheduler.CreateEvent(currElement, markup[0], markup[1], markup[2]);
        _dayPilot.events.add(event);
      }
    }
  }

  static GetMarkup(_currElement, _completedDate, dueDate, _template) {
    let date = _completedDate;
    let color = '#ffffff';

    _currElement.hidden = '';
    _currElement.icon = 'check-circle-o';
    if (_completedDate == null) {
      color = _currElement.color;
      date = dueDate;
      _currElement.hidden = 'hidden';
      _currElement.icon = 'clock-o';
    }

    let html = _template.formatTemplate(_currElement).trim();

    return [date, html, color];
  }

  static CreateEvent(elementData, date, html, color) {
    let dayStart = cSAMA_Utils.GetInputDateFromSQLDate(date, true);
    dayStart.hours(0).minutes(0).seconds(0).milliseconds(0);

    let dayEnd = moment(dayStart);
    dayEnd.hours(23).minutes(59).seconds(59);

    return new DayPilot.Event({
      id: DayPilot.guid(),
      start: dayStart.format('YYYY-MM-DD[T]HH:mm:ss'),
      end: dayEnd.format('YYYY-MM-DD[T]HH:mm:ss'),
      html: html,
      overrideBubbleHtml: html,
      backColor: color,
      barHidden: true,
      moveHDisabled: true,
      moveVDisabled: true,
      clickDisabled: false,
      moveDisabled: true,
      resizeDisabled: true,
      raw: {
        TYPE: elementData.type,
        id: elementData.id,
      },
    });
  }
}
