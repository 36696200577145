/**
 *
 * @param {mSAMA_CorrectiveAction} this.correctiveAction
 * @param {vSAMA_ModalConsequenceEditor} _this.conModal
 * @param {boolean} [_readOnly]
 */
class vSAMA_ModalCorrectiveActionsEditor extends vSAMA_AbstractModal {
  constructor(_correctiveAction, _conModal, _readOnly) {
    super();

    this.showingRA = false;
    this.conModal = _conModal;
    this.corrActScrollTop = 0;
    this.riskAssScrollTop = 0;
    this.modalScrollTop = 0;
    this.modalLockId = -1;
    this.correctiveAction = _correctiveAction;

    this.dom.bar.html($(SAMA.Templates.tpl.correctiveAction.modalBar));

    this.dom.btnDone = this.dom.bar.find('#sama-coa-accept');
    this.dom.btnCancel = this.dom.bar.find('#sama-coa-cancel');
    this.dom.btnShowRa = this.dom.bar.find('#sama-coa-showra');

    // Put this modal under the RA modal
    this.dom.wrapper.css('z-index', 499);

    if (_readOnly) {
      this.dom.btnDone.prop('disabled', true);
    }

    this.editCorrectiveAction = new vSAMA_EditorCorrectiveActions(this.correctiveAction, _readOnly);

    this.SetUpGui();
  }

  SetUpGui() {
    this.dom.btnCancel.on('click', () => this.RollbackChanges());
    this.dom.btnDone.on('click', () => this.AcceptChanges());
    this.dom.btnShowRa.on('click', () => this.StartPeekingRA());

    this.editCorrectiveAction.GetDom().appendTo(this.dom.content);
    super.SetUpGui();
  }

  RollbackChanges() {
    this.correctiveAction.DiscardValueChanges();
    this.editCorrectiveAction.ResetInputs();
    SAMA.GeneralGui.UnlockPageState(this.modalLockId);
    this.Close();
  }

  AcceptChanges() {
    this.correctiveAction.ConfirmValueChanges();
    this.conModal.OnAccept();
    SAMA.GeneralGui.UnlockPageState(this.modalLockId);
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    this.Close();
  }

  /**
   * Have a look at the Risk Assessment
   */
  StartPeekingRA() {
    if (this.showingRA) {
      return;
    }

    this.corrActScrollTop = cSAMA_Utils.GetScrollTop();
    this.conModal.Open(() => this.StopPeekingRA());
    cSAMA_Utils.ScrollTo(this.riskAssScrollTop);
    this.showingRA = true;
  }

  /**
   * Stop looking at the Risk Assessment
   */
  StopPeekingRA() {
    this.riskAssScrollTop = cSAMA_Utils.GetScrollTop();
    cSAMA_Utils.ScrollTo(this.corrActScrollTop);
    this.showingRA = false;
  }

  /**
   * Closes the modal
   */
  Close() {
    this.corrActScrollTop = 0;
    this.riskAssScrollTop = 0;

    if (this.showingRA) {
      this.conModal.Close();
    }

    super.Close();

    $('html, body').animate(
      {
        scrollTop: this.modalScrollTop,
      },
      0
    );
  }

  Destroy() {
    this.editCorrectiveAction = null;
    super.Destroy();
  }

  Open() {
    this.modalLockId = SAMA.GeneralGui.LockPageState('COA.Modal');
    this.modalScrollTop = cSAMA_Utils.GetScrollTop();

    this.editCorrectiveAction.OnEvaluateTabsState();
    this.editCorrectiveAction.ResetInputs();
    cSAMA_Utils.ScrollTo(0);

    super.Open();
  }
}
