/**
 *
 */
class PaxManTable extends AbstractDatabaseTable {
  constructor() {
    super('PaxManV2', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey   integer primary key ,
            OFFLINE_PK   numeric,
            Name         text,
            Description  text,
            modified     text,
            PaxCount     numeric,
            hasVIP       numeric,
            ClientFilter numeric,
            DELETED      numeric,
            Cfields      text,
            Passengers   text
        )
    `;
    //#endregion
  }

  async insert(data) {
    for (let i = 0; i < data.length; i++) {
      let d = data[i];
      if (typeof d.Passengers !== 'string') {
        data[i].Passengers = this.encodeJson(d.Passengers, '[]');
        data[i].Cfields = this.encodeJson(d.Cfields, '[]');
      }
    }

    return super.insert(data);
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    if (rows.length <= 0) {
      return false;
    }

    for (let i = 0; i < rows.length; i++) {
      rows[i].Passengers = this.parseJson(rows[i].Passengers, []);
      rows[i].Cfields = this.parseJson(rows[i].Cfields, []);
    }

    return rows;
  }
}
