/**
 * Get category and departments list then
 * show the report using the default filters
 */
function getReportData()
{
  // Get the filters
  const getReportCategoryReq   = new cSAMA_Request('ReportCategory', 'getData');
  const getReportDepartmentReq = new cSAMA_Request('Departments', 'getData');

  sendSMSRequest('GetReportCategories', getReportCategoryReq, vReportCategoryFilter);
  sendSMSRequest('GetReportDepartments', getReportDepartmentReq, vReportDepartmentFilter);

  // Get the reports
  filterReportData();
}

/**
 * Gets all the reports using the
 * selected filters
 */
function filterReportData()
{
  // Get the reports
  const reportFilters = getSelectedFilters();
  const getReportReq  = new cSAMA_Request('SafetyManagementReport', 'getData');
  getReportReq.Set('reportFilters', reportFilters);
  sendSMSRequest('GetSMSReport', getReportReq, showReportPreview);
}

/**
 * Sets the table headers and
 * populate the table rows
 *
 * @param _smsReportData
 */
function showReportPreview(_smsReportData)
{
  const smsReportData = _smsReportData.reportData;
  const headers       = [
    'Report ID',                        // rep_primaryKey
    'Title',                            // rep_summary
    'Report Status',
    'Reporter ID',                      // rep_reporterPk
    'Reporter',                         // rep_reporterName
    'Submitted Date',                   // rep_submittedDate
    'Approved Date',                    // rep_approvedDate
    'Flight Information',
    'Flight Date',
    'Itin / FR Number',                 // rep_itinPk
    'Flight Leg',
    'Report Type',                      // rep_isProactive 1 - Hazard - 0 Occurence
    'Report Category',                  // rep_catPk
    'Observed Date',                    // rep_dateTime
    'Observed Time',                    // rep_dateTime
    'Location',                         // rep_tarmac
    'Department',                       // rep_depPk
    'Anonymous Submission',             // rep_isAnonymous
    'Hazard Description',               // rep_problemDesc
    'Suggestions and Actions Taken',    // rep_suggestions
    'Initial Risk',                     // rep_riskScoreInitial
    'Mitigated Risk',                   // rep_riskScoreCurrent
    'Photos',
    'Documents',
    'Keywords',                         // rep_keywordPks
    'Approving Admin User'              //
  ];

  // Populate the table
  const tableBody     = $('#previewSMSReport');
  const smsReportHtml = formatReport(headers, smsReportData);
  tableBody.html(smsReportHtml);
  AddTableSorter('SMS_Report_Table');

  // Handle CSV download
  $('#downloadCsvBtn').off('click').on('click', function ()
  {
    exportCSV(smsReportData);
  });

  // Show the modal
  $('#reportModal').show();
  $('#sms-report-overlay').show();
}

/**
 * Hide the report modal
 */
function hideReportPreview()
{
  $('#reportModal').hide();
  $('#sms-report-overlay').hide();
}

/**
 * Create the html table for the report
 * @param headers
 * @param data
 * @returns {string}
 */
function formatReport(headers = null, data = null)
{
  const propAutoWidth = [
    'flightDate',
    'flightLeg'
  ];
  let table         = '<div class="table-container"><table id="SMS_Report_Table" data-filter-"true" class="wrap"><thead><tr>';

  if (headers)
  {
    headers.forEach(header =>
    {
      table += '<th>' + iTrans(header) + '</th>';
    });
  }

  table += '</thead><tbody>';

  if (data.length > 0)
  {
    // Create table td from the data
    table += cSAMA_Utils.GenerateTableContent(data, propAutoWidth);
  }
  else
  {
    table += '<tr>';
    table += '<td colspan=24>No results found.</td>';
    table += '</tr>';
  }

  table += '</tbody></table></div>';

  return table;
}

/**
 * Handles the exporting of Report CSV
 * @param reportData
 */
function exportCSV(reportData = null)
{
  const formData = reportData.map((data) =>
  {
    let repPhotoNames = '';
    if (data.photoPks.length > 0)
    {
      data.photoPks.forEach((photo) =>
      {
        repPhotoNames += photo.repPhotoName + '\n';
      });
    }

    let repDocuments = '';
    if (data.documents.length > 0)
    {
      data.documents.forEach((document) =>
      {
        repDocuments += document.repDocumentName + '\n';
      });
    }

    return {
      [iTrans('Report ID')]                                 : data.reportId,
      [iTrans('Title')]                                     : data.reportTitle,
      [iTrans('Report Status')]                             : data.reportStatus,
      [iTrans('Reporter ID')]                               : data.reporterId,
      [iTrans('Reporter')]                                  : data.reporter,
      [iTrans('Submitted Date')]                            : data.submittedDate,
      [iTrans('Approved Date')]                             : data.approvedDate,
      [iTrans('Flight Information (Itin or Flight Report)')]: data.flightInfo,
      [iTrans('Flight Date')]                               : data.flightDate,
      [iTrans('Itin / FR Number')]                          : data.itinFRNum,
      [iTrans('Flight Leg')]                                : cSAMA_Utils.ReplaceNewline(data.flightLeg),
      [iTrans('Report Type')]                               : data.reportType,
      [iTrans('Report Category')]                           : data.reportCategory,
      [iTrans('Observed Date')]                             : data.observedDate,
      [iTrans('Observed Time')]                             : data.observedTime,
      [iTrans('Location')]                                  : data.location,
      [iTrans('Department')]                                : data.department,
      [iTrans('Anonymous Submission')]                      : data.anonymousSubmission,
      [iTrans('Hazard Description')]                        : data.hazardDescription,
      [iTrans('Suggestions and Actions Taken')]             : data.suggestionsAndActions,
      [iTrans('Initial Risk')]                              : data.initialRisk,
      [iTrans('Mitigated Risk')]                            : data.mitigatedRisk,
      [iTrans('Photos')]                                    : repPhotoNames,
      [iTrans('Documents')]                                 : repDocuments,
      [iTrans('Keywords')]                                  : data.reportKeywords,
      [iTrans('Approving Admin User')]                      : data.adminApprover
    };
  });
  window.vueApp.utilities.blobUtils.downloadAsCsv(formData, 'Cirro_Safety_Management_Report');
}

/**
 * Populate the category options filter
 * @param repCategory
 */
function vReportCategoryFilter(repCategory)
{
  const reportCatSel = $('#report-category');
  const reportCatVal = reportCatSel.val();

  reportCatSel.empty().append($('<option>', {
    value: 0,
    text : 'Any'
  }));

  repCategory.forEach(category =>
  {
    reportCatSel.append($('<option>', {
      value: category.rc_primaryKey,
      text : category.rc_name
    }));
  });

  if (reportCatVal === null)
  {
    reportCatSel.val(0);
  }
  else
  {
    reportCatSel.val(reportCatVal);
  }
}

/**
 * Populate the department options filter
 * @param repDepartment
 */
function vReportDepartmentFilter(repDepartment)
{
  const reportDepSel = $('#report-department');
  const reportDepVal = reportDepSel.val();

  reportDepSel.empty().append($('<option>', {
    value: 0,
    text : 'Any'
  }));

  repDepartment.forEach(repDepartment =>
  {
    reportDepSel.append($('<option>', {
      value: repDepartment.dep_primaryKey,
      text : repDepartment.dep_name
    }));
  });

  if (reportDepVal === null)
  {
    reportDepSel.val(0);
  }
  else
  {
    reportDepSel.val(reportDepVal);
  }
}

/**
 * Sends request to the server
 * @param _reqName
 * @param _request
 * @param _callback
 */
function sendSMSRequest(_reqName, _request, _callback)
{
  cSAMA_Request.Dispatch({
    name           : _reqName,
    reqs           : _request,
    clientErrorCode: 'SAMA 34 22 CC EE',
    fnSuccess      : (_result) =>
    {
      _callback(_result[0]);
    },
    fnFail         : (_error) =>
    {
      cSAMA_Dialog.ShowDialog({
        title  : 'Error',
        message: 'Failed to generate Safety Management Report.'
      });
    }
  });
}

/**
 * Get the selected filters
 * @returns {{
 * fromDate: (*|string|jQuery),
 * sortByRisk: (*|string|jQuery),
 * toDate: (*|string|jQuery),
 * id: (*|string|jQuery),
 * type: (*|string|jQuery),
 * category: (*|string|jQuery),
 * department: (*|string|jQuery),
 * status: (*|string|jQuery),
 * sortByDate: (*|string|jQuery)
 * }}
 */
function getSelectedFilters()
{
  let reportFilters;

  reportFilters = {
    'type'      : $('#report-type').val(),
    'category'  : $('#report-category').val(),
    'department': $('#report-department').val(),
    'status'    : $('#report-status').val(),
    'fromDate'  : $('#from-date').val(),
    'toDate'    : $('#to-date').val(),
    'id'        : $('#id-name').val(),
    'sortByDate': $('#sort-date').val(),
    'sortByRisk': $('#sort-risk').val()
  };

  return reportFilters;
}

/**
 * Toggle Filter for mobile view
 */
function toggleFilter()
{
  const filterContainer = $('.smsReportModal .filter-container');
  filterContainer.toggleClass('collapsed');
}

/**
 * Hide report modal onload
 */
$(document).ready(function ()
{
  $('#reportModal').hide();
  $('#sms-report-overlay').hide();
});
