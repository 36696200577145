/**
 *
 */
class PassengerCustomFieldsTable extends AbstractDatabaseTable {
  constructor() {
    super('PassengerCfields', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"        numeric primary key,
            "group"             text,
            "SortOrder"         numeric,
            "type"              text,
            "reqPax"            numeric,
            "reqMan"            numeric,
            "usedFor"           text,
            "hideDefault"       numeric,
            "Label"             text,
            "Placeholder"       text,
            "default"           text,
            "PerLeg"            numeric,
            "ClientPK"          numeric,
            "Bill_Item"         numeric,
            "Bill_Item_Options" text,
            "options"           text,
            "Archived"          numeric
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    const parsed = [];
    for (const row of rows) {
      row.paxcode = row.paxcode == '' ? null : row.paxcode;
      parsed.push(row);
    }

    return parsed;
  }
}
