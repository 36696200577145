/******************************* Admin Clients & Jobs *******************************************************************/

function exitCustomCompany() {
  //todo maybe autosave edits?
  if (BILLING_CLIENTS[BILLING_CLIENT_INDEX].isOperator) {
    BI_options();
  } else {
    $.mobile.changePage($('#BILLING_NewClientPage'), {
      reverse: true,
      changeHash: false,
    });
  }
}

function BILLING_customCompanyToggle(e) {
  if (e.checked) {
    $('#BM_customCompanyOptionsHLDR').show();
  } else {
    $('#BM_customCompanyOptionsHLDR').hide();
  }
  var C = BILLING_CLIENTS[BILLING_CLIENT_INDEX];
  C.customCompany = e.checked ? 1 : 0;
}
function addNewOperator() {
  BILLING_CLIENT_INDEX = BILLING_CLIENTS.length;
  var newPK = -1;
  for (var i in BILLING_CLIENTS) {
    var C = BILLING_CLIENTS[i];
    if (C.clientPK < newPK) newPK = C.clientPK;
  }
  newPK--;
  BILLING_CLIENTS.push({
    clientPK: newPK,
    isOperator: true,
  });
  BILLING_editCustomCompany();
}
function editOperatorIndex(index) {
  BILLING_CLIENT_INDEX = index;
  BILLING_editCustomCompany();
}

function BILLING_deleteCustomCompany(index) {
  var C = BILLING_CLIENTS[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this Operator?'),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          cirroDB.Delete('BillingClient', 'clientPK = ?', C.clientPK, function (e) {
            console.log('operator deleted');
            BILLING_confirmDeleteOperator(C.clientPK);
          });
        },
      },
      [iTrans('No')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}
function BILLING_confirmDeleteOperator(clientPK) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=deleteOperator&clientPK=' + clientPK, function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception')
      ServerResponse(json_data[0], 'BILLING_confirmDeleteOperator');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      BI_options();
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function BILLING_editCustomCompany() {
  $.mobile.changePage($('#BILLING_CustomCompanyPage'), {
    reverse: true,
    changeHash: false,
  });
  var C = BILLING_CLIENTS[BILLING_CLIENT_INDEX];
  if (C.clientPK != undefined) {
    C.ClientPK = C.clientPK;
  }
  console.log('ClientPK', C.ClientPK);
  cirroDB.query('BillingClient', 'clientPK = ?', C.ClientPK, function (e) {
    var data = {};
    if (e === false || e.length == 0) {
      console.log('Creating New Custom Company Record');
      data = {
        ClientPK: C.ClientPK,
        Company_Name: '',
        QuoteTerms: '',
        type: 'image/png',
        flight_report_blurb: '',
        invoice_blurb: '',
        isOperator: C.isOperator != undefined,
        address: {
          address_one: '',
          address_two: '',
          city: '',
          province: '',
          postal_code: '',
          country: '',
          contact_first_name: '',
          contact_last_name: '',
          contact_phone: '',
          contact_email: '',
          image: '',
          type: '',
        },
      };
    } else {
      data = e[0].data;
    }
    BIDATA = data;
    $('#CCBIname').val(data.Company_Name);
    if (data.address != null) {
      $('#CCBIaddress1').val(data.address.address_one);
      $('#CCBIaddress2').val(data.address.address_two);
      $('#CCBIcity').val(data.address.city);
      $('#CCBIprovince').val(data.address.province);
      $('#CCBIpostal').val(data.address.postal_code);
      $('#CCBIcountry').val(data.address.country);

      $('#CCBIfirst').val(data.address.contact_first_name);
      $('#CCBIlast').val(data.address.contact_last_name);
      $('#CCBIphone').val(data.address.contact_phone);
      $('#CCBIemail').val(data.address.contact_email);
    } else {
      $('#CCBIaddress1').val('');
      $('#CCBIaddress2').val('');
      $('#CCBIcity').val('');
      $('#CCBIprovince').val('');
      $('#CCBIpostal').val('');
      $('#CCBIcountry').val('');

      $('#CCBIfirst').val('');
      $('#CCBIlast').val('');
      $('#CCBIphone').val('');
      $('#CCBIemail').val('');
    }

    $('#CCBI_invoiceblurb').val(data.invoice_blurb).keyup();
    $('#CCBI_flightreportblurb').val(data.flight_report_blurb).keyup();

    $('#CCBI_QuoteTerms').val(data.QuoteTerms).keyup();

    if (data.address.image != '')
      $('#CCBI_logo').html(
        "<img style='display:block; width:50%; height:50%;' id='BIbase64image' src='data:" +
          data.address.type +
          ';base64,' +
          data.address.image +
          "' />"
      );
    else $('#CCBI_logo').html(iTrans('No uploaded logo images'));

    $('#CCBIoptionsHLDR').enhanceWithin();
  });
}

function BILLING_save_customLogo() {
  var file = document.getElementById('CCBIlogofile').files[0];
  console.log(file);
  if (file != undefined) {
    $.mobile.loading('show', {
      theme: 'a',
    });
    var filename = file.name;
    var filesize = file.size;
    var file64;
    console.log(filesize);
    if (filesize > 13000000) {
      $('#CCBIlogofile').val('');
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/gif') {
          var img = document.createElement('img');
          img.onload = function () {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
            var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
            var width = img.width;
            var height = img.height;
            console.log(width);
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            var dataurl = canvas.toDataURL(file.type);

            file64 = dataurl.split(',')[1];
            if (BIDATA.logo == '') {
              BIDATA.logo = {
                id: 0,
              };
              BIDATA.image = {};
            } else {
            }
            BIDATA.address.type = file.type;
            BIDATA.address.filename = filename;
            BIDATA.address.size = filesize;
            BIDATA.address.image = file64;
            $.mobile.loading('hide');
            $('#CCBI_logo').html(
              "<img style='display:block; width:50%; height:50%;' id='BIbase64image' src='" + dataurl + "' />"
            );
          };
          img.src = e.target.result;
        } else {
          console.log('Wrong filetype');
          $('#CCBIlogofile').val('');
        }
      };
      reader.onerror = function (e) {
        //reset file input
        $.mobile.loading('hide');
        console.log('Scheduler FileReader Error');
        $('#SCHfileprogress').html('<h2>' + iTrans('Error Reading File') + '!!!</h2>');
      };
      reader.readAsDataURL(file);
    }
  } else {
    //reset file input
    console.log('No Shceduler File Selected');
    $('#CCBIlogofile').val('');
  }
}

function BILLING_saveCustomCompany() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  var C = BILLING_CLIENTS[BILLING_CLIENT_INDEX];
  console.log(C);
  BIDATA.clientPK = C.ClientPK;
  BIDATA.Company_Name = $('#CCBIname').val();

  if (BIDATA.address == '') BIDATA.address = {};
  BIDATA.address.address_one = $('#CCBIaddress1').val();
  BIDATA.address.address_two = $('#CCBIaddress2').val();
  BIDATA.address.city = $('#CCBIcity').val();
  BIDATA.address.province = $('#CCBIprovince').val();
  BIDATA.address.postal_code = $('#CCBIpostal').val();
  BIDATA.address.country = $('#CCBIcountry').val();
  BIDATA.address.contact_first_name = $('#CCBIfirst').val();
  BIDATA.address.contact_last_name = $('#CCBIlast').val();
  BIDATA.address.contact_phone = $('#CCBIphone').val();
  BIDATA.address.contact_email = $('#CCBIemail').val();

  BIDATA.invoice_blurb = $('#CCBI_invoiceblurb').val();
  BIDATA.flight_report_blurb = $('#CCBI_flightreportblurb').val();
  BIDATA.QuoteTerms = $('#CCBI_QuoteTerms').val();

  $.mobile.loading('show', {
    theme: 'a',
  });
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Administration-AJAX.php?action=saveSettingsV2',
    data: {
      BIDATA: JSON.stringify(BIDATA),
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'BIsave()');
      }
      if (data[0].ServerResponse == 'Success') {
        var C = {
          clientPK: BIDATA.clientPK,
          data: BIDATA,
        };
        cirroDB.insert('BillingClient', C, '', function (e) {
          exitCustomCompany();
        });
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //Update File Now Text - W&B Problem
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}
function BILLING_GetClientAlertList() {
  var clientPK = BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey;
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Administration-AJAX.php?action=GetClientAlertList&clientPK=' + clientPK,
    data: {
      AlertList: '',
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'SaveFR_AlertList()');
      }
      if (data[0].ServerResponse == 'Success') {
        FR_AlertList = data[0].AlertList;
        ShowFR_AlertList('BM_ClientAlertList', clientPK, 0);
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //Update File Now Text - W&B Problem
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_GetJobAlertList() {
  var clientPK = BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey;
  BILLING_JOB_PK = BILLING_JOBS[0].Active[BILLING_JOB_INDEX].PrimaryKey;
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url:
      BASEURL +
      'menu-Administration-AJAX.php?action=GetClientAlertList&clientPK=' +
      clientPK +
      '&jobPK=' +
      BILLING_JOB_PK,
    data: {
      AlertList: '',
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'SaveFR_AlertList()');
      }
      if (data[0].ServerResponse == 'Success') {
        FR_AlertList = data[0].AlertList;
        ShowFR_AlertList('BM_JobAlertList', clientPK, BILLING_JOB_PK);
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //Update File Now Text - W&B Problem
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_PopulateClientUserInfo(e) {
  ExchangeQuoteData('getUserInfo', { UserPK: e.value }, function (data) {
    var UserInfo = data[0].UserData;
    if (UserInfo.USER_DATA.PersonalInfo != null) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Populate Info',
        headerClose: false,
        buttonPrompt: iTrans(
          'Would you like to populate the Client info automatically from the selected assocaited Cirro User?'
        ),
        buttons: {
          [iTrans('Yes')]: {
            click: function () {
              var P = UserInfo.USER_DATA.PersonalInfo;
              $('#BM_Business').val(UserInfo.chatname);
              $('#BM_Address1').val(P.USER_ADDRESS);
              $('#BM_Address2').val(P.USER_ADDRESS2);
              $('#BM_City').val(P.USER_CITY);
              $('#BM_Postal').val(P.USER_ZIP);
              $('#BM_Prov_State').val(P.USER_STATE);
              $('#BM_Country').val(P.USER_COUNTRY);
              var NameParts = UserInfo.chatname.split(' ');
              if (NameParts.length > 1) {
                $('#BM_Firstname').val(NameParts[0]);
                $('#BM_Lastname').val(NameParts[NameParts.length - 1]);
              } else {
                $('#BM_Firstname').val(UserInfo.chatname);
                $('#BM_Lastname').val('');
              }
              $('#BM_Email').val(P.USER_EMAIL);
              $('#BM_Phone').val(P.USER_PHONE);
            },
          },
          [iTrans('No Keep Existing')]: {
            click: function () {},
          },
        },
      });
    }
  });
}

function BILLING_ArchivedClients() {
  $.mobile.changePage($('#BILLING_ArchivedClientListPage'), {
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getArchivedClients', function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_ARCHIVEDCLIENTLIST').html('<h1>' + iTrans('No Clients Available') + '</h1>');
      $('#BILLING_ARCHIVEDCLIENTLIST').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_CLIENTLIST').html('<h1>' + iTrans('Offline Cannot Retrieve Client list') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception')
        ServerResponse(json_data[0], 'Billing_ArchivedClientList');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      console.log('Received Archived Client List');
      BILLING_ShowArchivedClients(json_data);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_ARCHIVEDCLIENTLIST').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_ShowArchivedClients(data) {
  var html =
    '<table id="BIT_Admin1" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Client') +
    '</th><th>ID</th><th>' +
    iTrans('Action') +
    '</th></tr></thead>';
  for (var i in data) {
    var actions =
      "<div class='vertical-center-container'><button onClick='BILLING_ActivateClient(" +
      data[i].PrimaryKey +
      ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
    html += '<tr><td>' + data[i].Business + '</td><td>' + data[i].PrimaryKey + '</td><td>' + actions + '</td></tr>';
  }
  html += '</table>';
  $('#BILLING_ARCHIVEDCLIENTLIST').html(html);
  $('#BILLING_ARCHIVEDCLIENTLIST').enhanceWithin();
  AddTableSorter('BIT_Admin1');
}

function BILLING_ActivateClient(PK) {
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=activateArchivedClient&PrimaryKey=' + PK, function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_CLIENTLIST').html('<h1>' + iTrans('Offline Cannot Retrieve Client list') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception')
        ServerResponse(json_data[0], 'Billing_ArchivedClientList');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data[0].ServerResponse == 'Success') {
        BILLING_ClientList_return();
      }
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_ARCHIVEDCLIENTLIST').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_ClientList_returnPROMPT() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt: '<center>Your changes will not be saved. <h3>Are you Sure?</h3></center>',
    buttons: {
      Confirm: {
        click: function () {
          //do nothing
          BILLING_ClientList_return();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_ClientList_return() {
  $.mobile.changePage($('#BILLING_ClientListPage'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_LOAD_ClientList();
}

function BILLING_ClientList() {
  $.mobile.changePage($('#BILLING_ClientListPage'), {
    changeHash: false,
  });
  BILLING_LOAD_ClientList();

  if (GROUP_DATA.Bases != undefined) {
    //console.log('Received Bases Data');
    $('#BMJ_Base').empty();
    $('#BMJ_Base').append('<option value="" selected>None</option>');
    for (var i in GROUP_DATA.Bases) {
      var bases = GROUP_DATA.Bases[i];
      $('#BMJ_Base').append('<option value="' + bases.name + '">' + bases.name + '</option>');
    }
  }
}

function BILLING_LOAD_ClientList(e) {
  //get client list from server
  var reload = e || 'same';
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getClients', function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_CLIENTLIST').html('<h1>No Clients Available</h1>');
      $('#BILLING_CLIENTLIST').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_CLIENTLIST').html('<h1>' + iTrans('Offline Cannot Retrieve Client list') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_ClientList');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      console.log('Received Client List');
      BILLING_CLIENTS = json_data; //set global
      if (reload == 'same') BILLING_ShowClients(json_data);
      if (reload == 'job') BILLING_ShowJobs(BILLING_CLIENT_INDEX);
      if (reload == 'reload') BILLING_ClientCF();
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_CLIENTLIST').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
  BILLING_GetClientDataList(); //populate Client Data Feed for Jobs
}

function BILLING_CopyClient(index) {
  var ClientPK = BILLING_CLIENTS[index].PrimaryKey;

  var options =
    '<label for="COPYCLIENT_NAME">' +
    iTrans('Enter Temporary Client Name') +
    '</label><input type="text" id="COPYCLIENT_NAME" value="New Client" onClick="this.select();"/>';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Copy Client'),
    headerClose: false,
    buttonPrompt:
      iTrans('Copying Client ID') +
      ': ' +
      ClientPK +
      options +
      '<br /><b>' +
      iTrans('Note') +
      ': </b>' +
      iTrans('Archived Custom Fields and Finished Jobs are not copied'),
    buttons: {
      [iTrans('Copy Client')]: {
        click: function () {
          //do nothing

          ExchangeQuoteData('copyClient', { Name: $('#COPYCLIENT_NAME').val(), ClientPK: ClientPK }, function (data) {
            BILLING_LOAD_ClientList('same');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_ShowClients(data) {
  var html =
    '<table id="BIT_Admin2" data-filter="true" class="limit-table second-td-fill"><thead><tr><th>ID</th><th>' +
    iTrans('Client') +
    '</th><th>' +
    iTrans('Balance') +
    '<th>' +
    iTrans('Action') +
    '</th></tr></thead>';
  for (var i in data) {
    var actions =
      "<div class='vertical-center-container'><button onClick='BILLING_CopyClient(" +
      i +
      ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_ShowJobs(" +
      i +
      ")' data-mini='true' class='action-btn' data-testid='billing-add-job-client-" +
      data[i].Business +
      "'><img src='./images/ffmenu.svg' width='20' height='20'/></button><button onClick='BILLING_EditClient(" +
      i +
      ")' data-mini='true' class='action-btn' data-testid='billing-edit-client-" +
      data[i].Business +
      "'><img src='./images/edit.svg' /></button><button onClick='BILLING_RemoveClient(" +
      i +
      ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
    var balance = '';
    if (data[i].TrackBalance == 1) {
      balance =
        "<div class='vertical-center-container' style='float:right;'> " +
        data[i].Balance.CurBalance.toLocaleString('en-US', CUR) +
        "<button onClick='BILLING_UpdateClientBalance(" +
        i +
        ")' data-mini='true' class='action-btn'><img src='./images/Money.png' width='20px' height='20px' /></button></div>";
    }

    html +=
      '<tr><td>' +
      data[i].PrimaryKey +
      '</td><td data-testid="billing-show-client-' +
      data[i].Business +
      '">' +
      data[i].Business +
      '</td><td>' +
      balance +
      '</td><td>' +
      actions +
      '</td></tr>';
  }
  html += '</table>';
  $('#BILLING_CLIENTLIST').html(html);
  $('#BILLING_CLIENTLIST').enhanceWithin();
  AddTableSorter('BIT_Admin2');
}

function BILLING_UpdateClientBalance(index) {
  $.mobile.changePage($('#BILLING_ManageClientBalance'), {
    changeHash: false,
  });
  $('#BILLING_AdminClientBalanceTXT').html(' ' + BILLING_CLIENTS[index].Business + ' ' + iTrans('Account Balances'));
  $('#BM_JA_CirroUserPK').val(BILLING_CLIENTS[index].AUserPK);
  ExchangeQuoteData('ManageClientBalance', BILLING_CLIENTS[index], BILLING_DisplayClientBalance);
}

function BILLING_SaveAccountBalance() {
  var ClientPK = $('#BM_JA_ClientPK').val();
  var CirroUserPK = $('#BM_JA_CirroUserPK').val();
  var Amount = $('#BM_JA_SetAmount').val();
  var Notes = $('#BM_JS_SetNotes').val();
  var CurBalance = $('#BM_JA_NewCurBalance').val();
  var Action = $('input[name=BILLING_BALANCEACTION]:checked').val();

  var UpdateData = {
    ClientPK: ClientPK,
    CirroUserPK: CirroUserPK,
    Amount: Math.round(Amount * 100) / 100,
    Notes: Notes,
    CurBalance: Math.round(CurBalance * 100) / 100,
    Action: Action,
  };
  ExchangeQuoteData('UpdateClientBalance', UpdateData, BILLING_DisplayClientBalance);
}

function BILLING_ConfirmClientBalanceLeave() {
  var value = parseFloat($('#BM_JA_SetAmount').val());
  if (value != 0 || $('#BM_JS_SetNotes').val() != '') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Warning'),
      headerClose: false,
      buttonPrompt: iTrans(
        'There is unsaved data in either the Amount or Notes sections.  Are you sure you wish to leave?'
      ),
      buttons: {
        [iTrans('Stay Here')]: {
          click: function () {
            //staying here
          },
        },
        [iTrans('Leave Anyways')]: {
          click: function () {
            BILLING_ClientList_return();
          },
        },
      },
    });
  } else BILLING_ClientList_return();
}
function BILLING_SetBalanceAction(e) {
  $('#BM_JA_SetAmount_HLDR').show();
  switch (e.value) {
    case 'Set Balance':
      $('#BM_JA_SetAmount').prop('disabled', false);
      break;
    case 'Debit':
      $('#BM_JA_SetAmount').prop('disabled', false);
      break;
    case 'Credit':
      $('#BM_JA_SetAmount').prop('disabled', false);
      break;
    case 'Note':
      $('#BM_JA_SetAmount').val('0');
      $('#BM_JA_SetAmount').prop('disabled', true);
      $('#BM_JA_NewCurBallance').html('');
      $('#BM_JA_SetAmount_HLDR').hide();
      break;
  }
  BILLING_CalcNewBalance();
}

function BILLING_CalcNewBalance() {
  var value = parseFloat($('#BM_JA_SetAmount').val());
  if (isNaN(value)) value = 0;
  var CurBalance = parseFloat($('#BM_JA_LastCurBalance').val());
  if (isNaN(CurBalance)) CurBalance = 0;
  var Type = $('input[name=BILLING_BALANCEACTION]:checked').val();
  var NewCurBalance = 0;
  var html = '';
  switch (Type) {
    case 'Set Balance':
      NewCurBalance = value;
      html = '<h3>' + iTrans('New Current Balance') + ': ' + NewCurBalance.toLocaleString('en-US', CUR) + '</h3>';
      break;
    case 'Debit':
      NewCurBalance = CurBalance - value;
      html = '<h3>' + iTrans('New Current Balance') + ': ' + NewCurBalance.toLocaleString('en-US', CUR) + '</h3>';
      break;
    case 'Credit':
      NewCurBalance = value + CurBalance;
      html = '<h3>' + iTrans('New Current Balance') + ': ' + NewCurBalance.toLocaleString('en-US', CUR) + '</h3>';
      break;
    case 'Note':
      NewCurBalance = CurBalance;
      html = '';
      break;
  }
  $('#BM_JA_NewCurBalance').val(NewCurBalance);
  $('#BM_JA_NewCurBallance').html(html);
}

function BILLING_DisplayClientBalance(data) {
  $('#BM_JA_SetAmount').val(0);
  $('#BM_JS_SetNotes').val('').keyup();
  var ClientBalance = data[0].ClientBalance;
  $('#BM_JA_ClientPK').val(ClientBalance[0].ClientPK);
  $('#BM_JA_LastCurBalance').val(ClientBalance[0].CurBalance);
  var html =
    '<h3>' +
    iTrans('Last 100 Transactions') +
    '</h3><table  id="BIT_Admin3" class="limit-table wrap"><thead><tr><th>' +
    iTrans('UTC Timestamp') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Amount') +
    '</th><th>' +
    iTrans('Cur Balance') +
    '</th><th>' +
    iTrans('Notes') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in ClientBalance) {
    var iClass = '';
    var I = ClientBalance[i];
    if (I.CurBalance < 0) iClass = 'redbg';
    var Action = '';
    if (I.InvoicePK != 0) {
      Action =
        "<div class='vertical-center-container'><button onClick='BILLING_DisplayInvoicePK(" +
        I.InvoicePK +
        ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
    }
    html +=
      '<tr><td>' +
      I.Entered +
      '</td><td>' +
      iTrans(I.Action) +
      '</td><td>' +
      I.Amount.toLocaleString('en-US', CUR) +
      '</td><td class="' +
      iClass +
      '">' +
      I.CurBalance.toLocaleString('en-US', CUR) +
      '</td><td>' +
      I.Notes +
      '</td><td>' +
      Action +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#BILLING_MANAGECLIENTBALANCE').html(html);
  $('#BILLING_MANAGECLIENTBALANCE').enhanceWithin();
  AddTableSorter('BIT_Admin3');
  BILLING_CalcNewBalance();
}

function BILLING_DisplayInvoicePK(PK) {
  EDIT_INVOICE = false;
  BILLING_REVIEW = true;
  BILLING_GETINVOICE(PK);
  $('#submitInvoiceBTN').prop('disabled', true);
  BILLING_RETURN_CLIENT_BALANCE = true;
}

function BILLING_TrackBalanceToggle(e) {
  if (e.checked) {
    $('#BM_TrackBalanceOptions').show();
  } else {
    $('#BM_TrackBalanceOptions').hide();
  }
}
function BILLING_AssocUserToggle(e) {
  if (e.checked) {
    $('#BM_AUserPKOptions').show();
  } else {
    $('#BM_AUserPKOptions').hide();
  }
}
function BILLING_EditClient(index) {
  ADMIN_BILLING_FIELDS = false;
  BILLING_CLIENT_INDEX = index;
  $.mobile.changePage($('#BILLING_NewClientPage'), {
    changeHash: false,
  });
  $('#ClientCFDISABLE').html(iTrans('Custom Field Collection Options'));
  $('#BM_AUserPK').empty();
  $('#BM_AUserPK').append($('<option />').val(0).text(iTrans('Nobody Selected')));
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    $('#BM_AUserPK').append($('<option />').val(I.PrimaryKey).text(I.chatname));
  }

  $('#BM_departmentID').empty();
  $('#BM_departmentID').append(
    $('<option />')
      .val(0)
      .text('[' + iTrans('Not Associated') + ']')
  );
  for (var i in GROUP_DATA.Departments) {
    var dep = GROUP_DATA.Departments[i];
    if (dep.billing_job == 0) continue;
    $('#BM_departmentID').append(
      $('<option />')
        .val(dep.id)
        .text((dep.dept_code == null ? '' : '[' + dep.dept_code + ']') + ' ' + dep.name)
    );
  }

  var C = BILLING_CLIENTS[index];
  if (C.customCompany == 1) {
    $('#BM_customCompanyOptionsHLDR').show();
    $('#BM_customCompany').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BM_customCompanyOptionsHLDR').hide();
    $('#BM_customCompany').prop('checked', false).checkboxradio('refresh');
  }
  if (C.TrackBalance == 1) {
    BILLING_TrackBalanceToggle({ checked: true });
    $('#BM_TrackBalance').prop('checked', true).checkboxradio('refresh');
  } else {
    BILLING_TrackBalanceToggle({ checked: false });
    $('#BM_TrackBalance').prop('checked', false).checkboxradio('refresh');
  }

  if (C.SignatureReq == 1) {
    $('#BM_SignatureReq').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BM_SignatureReq').prop('checked', false).checkboxradio('refresh');
  }

  if (C.AssocUser == 1) {
    BILLING_AssocUserToggle({ checked: true });
    $('#BM_AssocUser').prop('checked', true).checkboxradio('refresh');
  } else {
    BILLING_AssocUserToggle({ checked: false });
    $('#BM_AssocUser').prop('checked', false).checkboxradio('refresh');
  }
  $('#BM_AUserPK').val(C.AUserPK).selectmenu('refresh');

  $('#BM_departmentID').val(C.departmentID).selectmenu('refresh');

  $('#BM_PrimaryKey').val(C.PrimaryKey);
  $('#BM_Business').val(C.Business);
  $('#BM_Address1').val(C.Address1);
  $('#BM_Address2').val(C.Address2);
  $('#BM_City').val(C.City);
  $('#BM_Postal').val(C.Postal);
  $('#BM_Prov_State').val(C.Prov_State);
  $('#BM_Country').val(C.Country);
  $('#BM_Firstname').val(C.Firstname);
  $('#BM_Lastname').val(C.Lastname);
  $('#BM_Email').val(C.Email);
  $('#BM_Phone').val(C.Phone);
  if (HOBBS == 1) {
    if (C.P_Hobbs == 1) $('#BI_HOBBS_P').prop('checked', true).checkboxradio('refresh');
    else $('#BI_HOBBS_P').prop('checked', false).checkboxradio('refresh');
    if (C.S_Hobbs == 1) $('#BI_HOBBS_S').prop('checked', true).checkboxradio('refresh');
    else $('#BI_HOBBS_S').prop('checked', false).checkboxradio('refresh');
  }
  $('#ClientCFDISABLE').prop('disabled', false);
  $('#BILLING_CLIENT_PDF').prop('disabled', false);
  $('#BILLING_CLIENT_PDF_CURRENT').html('');
  if (C.Filename != '') {
    $('#BILLING_CLIENT_PDF').hide();
    $('#BILLING_RemovePDF_BTN').show();
    $('#BILLING_CLIENT_PDF_FILENAME').html(
      '<b>Filename: </b><a href="' +
        BASEURL +
        'menu-Billing-AJAX.php?action=getClientPDF&OPEN=1&PrimaryKey=' +
        C.PrimaryKey +
        '" target="_blank">' +
        C.Filename +
        '</a>'
    );
    getClientPDF(C.PrimaryKey);
  } else {
    $('#BILLING_CLIENT_PDF_FILENAME').html('');
    $('#BILLING_CLIENT_PDF').html('');
    $('#BILLING_CLIENT_PDF').show();
    $('#BILLING_RemovePDF_BTN').hide();
  }
  BILLING_GetClientAlertList();
}

function BILLING_RemoveClient(index) {
  console.log(index);
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to archive this client?'),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          var PrimaryKey = BILLING_CLIENTS[index].PrimaryKey;

          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: BASEURL + 'menu-Billing-AJAX.php?action=deleteClient',
            data: {
              PrimaryKey: PrimaryKey,
            },
          })
            .success(function (data) {
              //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
              console.log('ServerResponse: check');
              console.log(data);
              //ensure data[0].ServerResponse == Success
              if (data[0].ServerResponse == 'Uncaught Exception') {
                ServerResponse(data[0], 'BILLING_RemoveClient()');
              }
              if (data[0].ServerResponse == 'Success') {
                BILLING_LOAD_ClientList();
              }
              if (data[0].ServerResponse == 'Failed') {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('Failed!'),
                  headerClose: false,
                  buttonPrompt: iTrans(
                    'There was a problem deleting the client.  Please try again. If this continues to happen please submit a bug report'
                  ),
                  buttons: {
                    [iTrans('Close')]: {
                      click: function () {},
                    },
                    [iTrans('Report')]: {
                      click: function () {
                        //File bug report
                      },
                    },
                  },
                });
              }
            })
            .fail(function (data) {
              //data.status, data.readyState, data.responseText
              console.log(data);
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Failed!'),
                headerClose: false,
                buttonPrompt:
                  'There was a problem deleting a Client.  Please try again. If this continues to happen please submit a bug report',
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                  [iTrans('Report')]: {
                    click: function () {
                      //File bug report
                    },
                  },
                },
              });
            });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //Do nothing
        },
      },
    },
  });
}

function BILLING_NewClient() {
  $.mobile.changePage($('#BILLING_NewClientPage'), {
    changeHash: false,
  });

  BILLING_TrackBalanceToggle({ checked: false });
  $('#BM_TrackBalance').prop('checked', false).checkboxradio('refresh');

  $('#BM_SignatureReq').prop('checked', false).checkboxradio('refresh');

  BILLING_AssocUserToggle({ checked: false });
  $('#BM_AssocUser').prop('checked', false).checkboxradio('refresh');
  $('#BM_AUserPK').empty();
  $('#BM_AUserPK').append($('<option />').val(0).text('Nobody Selected'));
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    $('#BM_AUserPK').append($('<option />').val(I.PrimaryKey).text(I.chatname));
  }
  $('#BM_departmentID').empty();
  $('#BM_departmentID').append(
    $('<option />')
      .val(0)
      .text('[' + iTrans('Not Associated') + ']')
  );
  for (var i in GROUP_DATA.Departments) {
    var dep = GROUP_DATA.Departments[i];
    $('#BM_departmentID').append(
      $('<option />')
        .val(dep.id)
        .text((dep.dept_code == null ? '' : '[' + dep.dept_code + ']') + ' ' + dep.name)
    );
  }
  $('#BM_AUserPK').val(0).selectmenu('refresh');
  $('#BM_departmentID').val(0).selectmenu('refresh');
  $('#BM_PrimaryKey').val('None');
  $('#BM_Business').val('');
  $('#BM_Address1').val('');
  $('#BM_Address2').val('');
  $('#BM_City').val('');
  $('#BM_Postal').val('');
  $('#BM_Prov_State').val('');
  $('#BM_Country').val('');
  $('#BM_Firstname').val('');
  $('#BM_Lastname').val('');
  $('#BM_Email').val('');
  $('#BM_Phone').val('');
  if (HOBBS == 1) {
    $('#BI_HOBBS_P').prop('checked', false).checkboxradio('refresh');
    $('#BI_HOBBS_S').prop('checked', false).checkboxradio('refresh');
  }
  $('#BILLING_CLIENT_PDF_FILENAME').html('');
  $('#BILLING_CLIENT_PDF_CURRENT').html('');
  $('#BILLING_RemovePDF_BTN').hide();
  $('#BILLING_CLIENT_PDF').hide();
  $('#ClientCFDISABLE').prop('disabled', true);
  $('#ClientCFDISABLE').html(
    "Custom Field Collection Options <b class='redbg'> [ SAVE NEW CLIENT TO ADD CUSTOM FIELDS ] </b>"
  );
}

function BILLING_AddClient() {
  document.getElementById('BILLING_ADDCLIENT_BTN').disabled = true;
  //capture data from form and send to server

  var Business = $('#BM_Business').val();
  var Address1 = $('#BM_Address1').val();
  var Address2 = $('#BM_Address2').val();
  var City = $('#BM_City').val();
  var Postal = $('#BM_Postal').val();
  var Prov_State = $('#BM_Prov_State').val();
  var Country = $('#BM_Country').val();
  var Firstname = $('#BM_Firstname').val();
  var Lastname = $('#BM_Lastname').val();
  var Email = $('#BM_Email').val();
  var Phone = $('#BM_Phone').val();
  var PrimaryKey = $('#BM_PrimaryKey').val();
  var P_Hobbs = 0;
  var S_Hobbs = 0;
  var customCompany = $('#BM_customCompany').is(':checked') ? 1 : 0;
  if (HOBBS == 1) {
    if ($('#BI_HOBBS_P').is(':checked')) P_Hobbs = 1;
    if ($('#BI_HOBBS_S').is(':checked')) S_Hobbs = 1;
  }
  var TrackBalance = 0;
  var AssocUser = 0;
  if ($('#BM_TrackBalance').is(':checked')) TrackBalance = 1;
  if ($('#BM_AssocUser').is(':checked')) AssocUser = 1;

  var SignatureReq = 0;
  if ($('#BM_SignatureReq').is(':checked')) SignatureReq = 1;
  var AUserPK = $('#BM_AUserPK').val();
  var departmentID = $('#BM_departmentID').val();

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=newClient',
    data: {
      PrimaryKey: PrimaryKey,
      departmentID: departmentID,
      Business: Business,
      Address1: Address1,
      Address2: Address2,
      City: City,
      Postal: Postal,
      Prov_State: Prov_State,
      Country: Country,
      Firstname: Firstname,
      Lastname: Lastname,
      Email: Email,
      Phone: Phone,
      P_Hobbs: P_Hobbs,
      S_Hobbs: S_Hobbs,
      TrackBalance: TrackBalance,
      AssocUser: AssocUser,
      AUserPK: AUserPK,
      SignatureReq: SignatureReq,
      customCompany: customCompany,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'BILLING_AddClient()');
        document.getElementById('BILLING_ADDCLIENT_BTN').disabled = false;
      }
      if (data[0].ServerResponse == 'Success') {
        document.getElementById('BILLING_ADDCLIENT_BTN').disabled = false;
        BILLING_ClientList_return();
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem adding / updating the client.  Please try again. If this continues to happen please submit a bug report'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                document.getElementById('BILLING_ADDCLIENT_BTN').disabled = false;
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem adding / updating the client.  Please try again. If this continues to happen please submit a bug report'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              document.getElementById('BILLING_ADDCLIENT_BTN').disabled = false;
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_ShowJobs(index) {
  BILLING_CLIENT_INDEX = index;
  var PrimaryKey = BILLING_CLIENTS[index].PrimaryKey;
  $('#ClientNameHldr').html(' ' + BILLING_CLIENTS[index].Business + ' - Job List');
  $('#ClientNameHldr2').html(' ' + BILLING_CLIENTS[index].Business + ' - New / Edit Job');
  //Retrieve list of jobs for client from server
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobs&PrimaryKey=' + PrimaryKey, function (json_data) {
    if (json_data.length == 0) {
      $.mobile.changePage($('#BILLING_ListJobs'), {
        changeHash: false,
      });
      $('#BILLING_JOBLIST').html('<h1>' + iTrans('No Jobs Exist for Client') + '</h1>');
      $('#BILLING_JOBLIST').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_JOBSLIST').html('<h1>' + iTrans('Offline Cannot Retrieve List of Jobs') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_ShowJobs');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      console.log('Received jobs List');
      $.mobile.changePage($('#BILLING_ListJobs'), {
        changeHash: false,
      });
      BILLING_JOBS = json_data; //set global
      BILLING_DisplayJobs();
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_JOBLIST').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_DisplayJobs() {
  var Active = BILLING_JOBS[0].Active;
  var Finished = BILLING_JOBS[0].Finished;
  console.log('Displaying Job List');
  $('#BILLING_JOBLIST').html('');
  var html = '';
  if ($('#BILLING_JOBONGOING').is(':checked')) {
    if (Active.length > 0) {
      html =
        '<table  id="BIT_Admin4" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Job Name') +
        '</th><th>' +
        iTrans('Job') +
        '#</th><th>' +
        iTrans('Start Date') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var A in Active) {
        var actions =
          "<div class='vertical-center-container'><button onclick='BILLING_CopyJob(" +
          Active[A].PrimaryKey +
          ',' +
          Active[A].ClientPK +
          ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px'></button></button><button onClick='BILLING_EditJobA(" +
          A +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button></div>";

        html +=
          '<tr><td data-testid="billing-display-active-job-name-' +
          Active[A].Name +
          '">' +
          Active[A].Name +
          '</td><td>' +
          Active[A].JobNum +
          '</td><td>' +
          Active[A].StartDate +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<h2>' + iTrans('No Active Jobs') + '</h2>';
  } else {
    if (Finished.length > 0) {
      html =
        '<table  id="BIT_Admin4" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Job Name') +
        '</th><th>' +
        iTrans('Job') +
        '#</th><th>' +
        iTrans('End Date') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var F in Finished) {
        var actions =
          "<div class='vertical-center-container'><button onclick='BILLING_CopyJob(" +
          Finished[F].PrimaryKey +
          ',' +
          Finished[F].ClientPK +
          ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px'></button></button><button onClick='BILLING_EditJobF(" +
          F +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button></div>";
        html +=
          '<tr><td data-testid="billing-display-finished-job-name-' +
          Finished[F].Name +
          '">' +
          Finished[F].Name +
          '</td><td>' +
          Finished[F].JobNum +
          '</td><td>' +
          Finished[F].EndDate +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<h2>' + iTrans('No Completed Jobs') + '</h2>';
  }
  $('#BILLING_JOBLIST').html(html);
  $('#BILLING_JOBLIST').enhanceWithin();
  AddTableSorter('BIT_Admin4');
}

function BILLING_CopyJob(PK, CPK) {
  var options =
    '<label for="COPYJOB_NAME">' +
    iTrans('Enter Job Name') +
    '</label><input type="text" id="COPYJOB_NAME" value="New Job" onClick="this.select();"/>';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Copy Job'),
    headerClose: false,
    buttonPrompt: iTrans('Copying Job ID') + ': ' + PK + options,
    buttons: {
      [iTrans('Copy Job')]: {
        click: function () {
          //do nothing

          ExchangeQuoteData('copyJob', { Name: $('#COPYJOB_NAME').val(), JobPK: PK, ClientPK: CPK }, function (data) {
            BILLING_LOAD_ClientList('job');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_NewJob() {
  $.mobile.changePage($('#BILLING_NewJobPage'), {
    changeHash: false,
  });
  //clear fields for entry
  $('#BMJ_PrimaryKey').val('');

  $('#BMJ_Base').val('').selectmenu('refresh');
  $('#BMJ_LockCrew').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_LockAC').prop('checked', false).checkboxradio('refresh');
  $('#BIreqFRsignatureON').prop('checked', false).checkboxradio('refresh');
  $('#BIshowDetails').prop('checked', true).checkboxradio('refresh');
  $('#BIshowJobDetails').prop('checked', true).checkboxradio('refresh');
  $('#BIshowPaxManLegs').prop('checked', false).checkboxradio('refresh');
  $('#BIshowPaxManReport').prop('checked', false).checkboxradio('refresh');
  $('#BI_ClientDataFeed').val(0).selectmenu('refresh');
  BILLING_popMultiClientList([]);
  BILLING_multiClientSelected();
  $('#BMJ_Name').val('');
  $('#BMJ_flightCatID').empty();
  $('#BMJ_flightCatID').append($('<option />').val(0).text('[Not Assigned]'));
  if (GROUP_DATA.FlightCategories.length > 0) {
    $('#BMJ_flightCatIDHLDR').show();
    for (var i in GROUP_DATA.FlightCategories) {
      var fc = GROUP_DATA.FlightCategories[i];
      $('#BMJ_flightCatID').append(
        $('<option />')
          .val(fc.PrimaryKey)
          .text((fc.fltcatcode == null ? '' : '[' + fc.fltcatcode + '] ') + fc.fltcatdesc)
      );
    }
    $('#BMJ_flightCatID').val(0).selectmenu('refresh');
  } else {
    $('#BMJ_flightCatIDHLDR').hide();
    $('#BMJ_flightCatID').val(0);
  }


  $('#BMJ_Description').val('');
  $('#BMJ_Min_Hours').val('');
  $('#BMJ_CrewMins').val('');
  $('#BMJ_Averaged').val('None').selectmenu('refresh');
  $('#BMJ_Daily_Rate').val('');
  $('#BMJ_Daily_AC').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_StartDate').val('');
  $('#BMJ_EndDate').val('');
  $('#BMJ_BlockTimeAT').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_BlockTimeFT').prop('checked', true).checkboxradio('refresh');
  $('#BMJ_DisplayAirBlockTime').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_Finished').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_PrimaryKey').val('None');

  $('#BMJ_showClientFuel')
    .prop('checked', GROUP_DATA.FR_showClientFuel == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showTZ')
    .prop('checked', GROUP_DATA.FR_showTZ == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showTDG')
    .prop('checked', GROUP_DATA.FR_showTDG == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showAddress')
    .prop('checked', GROUP_DATA.FR_showAddress == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showClientDetails')
    .prop('checked', GROUP_DATA.FR_showClientDetails == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showJobNum')
    .prop('checked', GROUP_DATA.FR_showJobNum == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showStartEndTZ')
    .prop('checked', GROUP_DATA.FR_ITIN_showStartEndTZ == 2 || GROUP_DATA.FR_ITIN_showStartEndTZ == 3 ? true : false)
    .checkboxradio('refresh');

  $('#BMJ_Firstname').val('');
  $('#BMJ_Lastname').val('');
  $('#BMJ_Email').val('');
  $('#BMJ_Phone').val('');

  $('#BILLBY_Hour').prop('checked', true).checkboxradio('refresh');
  $('#BILLBY_Hourw').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Hobbs').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Distance').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Weight').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Volume').prop('checked', false).checkboxradio('refresh');
  //Add hooks for verification
  //enable / disable #BM_JobDetailsNextBTN
  BILLING_update_BILLBY_Opt($('input[name=BILLBY]:checked').val());
  BILLING_Check_ShowAirBlock();
}

function BILLING_popMultiClientList(list = []){
  $('#BMJ_MultiClientJob').empty();
  $('#BMJ_MultiClientJob').append($('<option />').val('').text(iTrans('Cloose Clients')));
  $('#BMJ_MultiClientJob').append($('<option />').val(0).text(iTrans('Global - All Clients')));
  for(var i in BILLING_CLIENTS){
    var I = BILLING_CLIENTS[i];
    $('#BMJ_MultiClientJob').append($('<option />').val(I.ClientPK).text(I.Business));
  }
  for(var i in list){
    $("#BMJ_MultiClientJob").find("option[value="+list[i]+"]").prop("selected", "selected");
  }
  $("#BMJ_MultiClientJob").find("option[value="+BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey+"]").prop("selected", "selected");
  $("#BMJ_MultiClientJob").find("option[value="+BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey+"]").prop("disabled", true);
  $('#BMJ_MultiClientJob').selectmenu('refresh');
}

function BILLING_multiClientSelected(e = false){
  var list = $('#BMJ_MultiClientJob').val();
  console.log(list);
  if (list.length > 0) {
   if (list[0] == 0){
     for(var i in BILLING_CLIENTS){
       var I = BILLING_CLIENTS[i];
       $("#BMJ_MultiClientJob").find("option[value="+I.ClientPK+"]").prop("selected", '');
       $("#BMJ_MultiClientJob").find("option[value="+I.ClientPK+"]").prop("disabled", true);
     }
   }else{
     $("#BMJ_MultiClientJob").find("option[value="+0+"]").prop("selected", '');
     for(var i in BILLING_CLIENTS){
       var I = BILLING_CLIENTS[i];
       $("#BMJ_MultiClientJob").find("option[value="+I.ClientPK+"]").prop("disabled", false);
     }
   }
  }else{
    $("#BMJ_MultiClientJob").find("option[value="+0+"]").prop("selected", '');
    for(var i in BILLING_CLIENTS){
      var I = BILLING_CLIENTS[i];
      $("#BMJ_MultiClientJob").find("option[value="+I.ClientPK+"]").prop("disabled", false);
    }
  }
  $("#BMJ_MultiClientJob").find("option[value="+BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey+"]").prop("selected", "selected");
  $("#BMJ_MultiClientJob").find("option[value="+BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey+"]").prop("disabled", true);
  $('#BMJ_MultiClientJob').selectmenu('refresh', true);
}

function BILLING_EditJobA(index) {
  BILLING_JOB_INDEX = index;
  var J = BILLING_JOBS[0].Active[index];
  console.log(J);
  $.mobile.changePage($('#BILLING_NewJobPage'), {
    changeHash: false,
  });
  BILLING_popMultiClientList(J.clientList);
  BILLING_multiClientSelected();
  $('#BMJ_PrimaryKey').val(J.PrimaryKey);
  $('#BMJ_Base').val(J.Base).selectmenu('refresh');
  if (J.LockCrew == 1) $('#BMJ_LockCrew').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_LockCrew').prop('checked', false).checkboxradio('refresh');

  if (J.LockAC == 1) $('#BMJ_LockAC').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_LockAC').prop('checked', false).checkboxradio('refresh');

  if (J.reqSignature == 1) $('#BIreqFRsignatureON').prop('checked', true).checkboxradio('refresh');
  else $('#BIreqFRsignatureON').prop('checked', false).checkboxradio('refresh');

  if (J.showDetails == 1) $('#BIshowDetails').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowDetails').prop('checked', false).checkboxradio('refresh');

  if (J.showJobDetails == 1) $('#BIshowJobDetails').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowJobDetails').prop('checked', false).checkboxradio('refresh');

  if (J.showPaxManLegs == 1) $('#BIshowPaxManLegs').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowPaxManLegs').prop('checked', false).checkboxradio('refresh');

  if (J.showPaxManReport == 1) $('#BIshowPaxManReport').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowPaxManReport').prop('checked', false).checkboxradio('refresh');

  $('#BI_ClientDataFeed').val(J.clientDataFeedPK).selectmenu('refresh');

  if (J.showNonBillable == 1) $('#BIshowNonBillable').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowNonBillable').prop('checked', false).checkboxradio('refresh');

  if (J.blankLegtimes == 1) $('#BIblankLegtimes').prop('checked', true).checkboxradio('refresh');
  else $('#BIblankLegtimes').prop('checked', false).checkboxradio('refresh');

  $('#BMJ_Name').val(J.Name);

  $('#BMJ_flightCatID').empty();
  $('#BMJ_flightCatID').append($('<option />').val(0).text('[Not Assigned]]'));
  if (GROUP_DATA.FlightCategories.length > 0) {
    $('#BMJ_flightCatIDHLDR').show();
    for (var i in GROUP_DATA.FlightCategories) {
      var fc = GROUP_DATA.FlightCategories[i];
      $('#BMJ_flightCatID').append(
        $('<option />')
          .val(fc.PrimaryKey)
          .text((fc.fltcatcode == null ? '' : '[' + fc.fltcatcode + '] ') + fc.fltcatdesc)
      );
    }
    $('#BMJ_flightCatID').val(J.flightCatID).selectmenu('refresh');
  } else {
    $('#BMJ_flightCatIDHLDR').hide();
    $('#BMJ_flightCatID').val(0);
  }

  $('#BMJ_Description').val(J.Description);
  $('#BMJ_Min_Hours').val(J.Min_Hours);
  $('#BMJ_CrewMins').val(J.CrewMins);
  $('#BMJ_Averaged').val(J.Averaged).selectmenu('refresh');
  $('#BMJ_Daily_Rate').val(J.Daily_Rate);
  if (J.Daily_Per_AC == 1) $('#BMJ_Daily_AC').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_Daily_AC').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_StartDate').val(J.StartDate);
  $('#BMJ_EndDate').val(J.EndDate);
  $('#BMJ_BlockTimeAT').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_BlockTimeFT').prop('checked', false).checkboxradio('refresh');
  if (J.BlockTime == 1) $('#BMJ_BlockTimeAT').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_BlockTimeFT').prop('checked', true).checkboxradio('refresh');
  if (J.showAirBlock == 1) $('#BMJ_DisplayAirBlockTime').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayAirBlockTime').prop('checked', false).checkboxradio('refresh');
  if (J.showPaxWeights == 1) $('#BMJ_DisplayPaxWeights').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayPaxWeights').prop('checked', false).checkboxradio('refresh');
  if (J.showCargoWeights == 1) $('#BMJ_DisplayCargoWeights').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayCargoWeights').prop('checked', false).checkboxradio('refresh');
  if (J.showRIM == 1) $('#BMJ_DisplayRIM').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayRIM').prop('checked', false).checkboxradio('refresh');
  if (J.showFuel == 1) $('#BMJ_DisplayFuel').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayFuel').prop('checked', false).checkboxradio('refresh');

  $('#BMJ_showClientFuel')
    .prop('checked', J.showClientFuel == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showTZ')
    .prop('checked', J.showTZ == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showTDG')
    .prop('checked', J.showTDG == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showAddress')
    .prop('checked', J.showAddress == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showClientDetails')
    .prop('checked', J.showClientDetails == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showJobNum')
    .prop('checked', J.showJobNum == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showStartEndTZ')
    .prop('checked', J.showStartEndTZ == 1 ? true : false)
    .checkboxradio('refresh');

  if (J.Finished == 1) $('#BMJ_Finished').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_Finished').prop('checked', false).checkboxradio('refresh');

  $('#BMJ_Firstname').val(J.contact_firstname);
  $('#BMJ_Lastname').val(J.contact_lastname);
  $('#BMJ_Email').val(J.contact_email);
  $('#BMJ_Phone').val(J.contact_phone);

  BILLING_GetJobAlertList();
  $('#BILLBY_Hour').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Hour2').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Minute').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_HourMinute').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Hobbs').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Distance').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Weight').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_HourPR').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_HourPL').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Volume').prop('checked', false).checkboxradio('refresh');
  switch (J.BillBy) {
    case 'Hour':
      $('#BILLBY_Hour').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Hour2':
      $('#BILLBY_Hour2').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Minute':
      $('#BILLBY_Minute').prop('checked', true).checkboxradio('refresh');
      break;
    case 'HourMinute':
      $('#BILLBY_HourMinute').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Hobbs':
      $('#BILLBY_Hobbs').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Distance':
      $('#BILLBY_Distance').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Weight':
      $('#BILLBY_Weight').prop('checked', true).checkboxradio('refresh');
      break;
    case 'HourPR':
      $('#BILLBY_HourPR').prop('checked', true).checkboxradio('refresh');
      break;
    case 'HourPL':
      $('#BILLBY_HourPL').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Volume':
      $('#BILLBY_Volume').prop('checked', true).checkboxradio('refresh');
      break;
  }
  BILLING_update_BILLBY_Opt(J.BillBy, J.BillByOPT);
  BILLING_Check_ShowAirBlock();
}

function BILLING_Check_ShowAirBlock() {
  if ($('#BMJ_BlockTimeAT').is(':checked')) {
    $('#BMJ_DisplayAirBlockTime').prop('disabled', false);
  } else {
    $('#BMJ_DisplayAirBlockTime').prop('disabled', true);
    $('#BMJ_DisplayAirBlockTime').prop('checked', false);
  }
  $('#BMJ_DisplayAirBlockTime').checkboxradio('refresh');
}
function BILLING_EditJobF(index) {
  BILLING_JOB_INDEX = index;
  var J = BILLING_JOBS[0].Finished[index];
  console.log(J);
  $.mobile.changePage($('#BILLING_NewJobPage'), {
    changeHash: false,
  });
  BILLING_popMultiClientList(J.clientList);
  BILLING_multiClientSelected();
  $('#BMJ_PrimaryKey').val(J.PrimaryKey);
  $('#BMJ_Base').val(J.Base).selectmenu('refresh');
  if (J.LockCrew == 1) $('#BMJ_LockCrew').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_LockCrew').attr('checked', false).checkboxradio('refresh');

  if (J.LockAC == 1) $('#BMJ_LockAC').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_LockAC').prop('checked', false).checkboxradio('refresh');

  if (J.reqSignature == 1) $('#BIreqFRsignatureON').prop('checked', true).checkboxradio('refresh');
  else $('#BIreqFRsignatureON').prop('checked', false).checkboxradio('refresh');

  if (J.showDetails == 1) $('#BIshowDetails').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowDetails').prop('checked', false).checkboxradio('refresh');

  if (J.showJobDetails == 1) $('#BIshowJobDetails').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowJobDetails').prop('checked', false).checkboxradio('refresh');

  if (J.showPaxManLegs == 1) $('#BIshowPaxManLegs').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowPaxManLegs').prop('checked', false).checkboxradio('refresh');

  if (J.showPaxManReport == 1) $('#BIshowPaxManReport').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowPaxManReport').prop('checked', false).checkboxradio('refresh');

  $('#BI_ClientDataFeed').val(J.clientDataFeedPK).selectmenu('refresh');

  if (J.showNonBillable == 1) $('#BIshowNonBillable').prop('checked', true).checkboxradio('refresh');
  else $('#BIshowNonBillable').prop('checked', false).checkboxradio('refresh');

  if (J.blankLegtimes == 1) $('#BIblankLegtimes').prop('checked', true).checkboxradio('refresh');
  else $('#BIblankLegtimes').prop('checked', false).checkboxradio('refresh');

  $('#BMJ_Name').val(J.Name);
  $('#BMJ_Description').val(J.Description);
  $('#BMJ_Min_Hours').val(J.Min_Hours);
  $('#BMJ_CrewMins').val(J.CrewMins);
  $('#BMJ_Averaged').val(J.Averaged).selectmenu('refresh');
  $('#BMJ_Daily_Rate').val(J.Daily_Rate);
  if (J.Daily_Per_AC == 1) $('#BMJ_Daily_AC').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_Daily_AC').prop('checked', false).checkboxradio('refresh');
  $('#BMJ_StartDate').val(J.StartDate);
  $('#BMJ_EndDate').val(J.EndDate);
  $('#BMJ_BlockTimeAT').prop('checked', true).checkboxradio('refresh');
  $('#BMJ_BlockTimeFT').prop('checked', true).checkboxradio('refresh');
  if (J.BlockTime == 1) $('#BMJ_BlockTimeAT').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_BlockTimeFT').prop('checked', true).checkboxradio('refresh');
  if (J.showAirBlock == 1) $('#BMJ_DisplayAirBlockTime').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayAirBlockTime').prop('checked', false).checkboxradio('refresh');
  if (J.showPaxWeights == 1) $('#BMJ_DisplayPaxWeights').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayPaxWeights').prop('checked', false).checkboxradio('refresh');
  if (J.showCargoWeights == 1) $('#BMJ_DisplayCargoWeights').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayCargoWeights').prop('checked', false).checkboxradio('refresh');
  if (J.showRIM == 1) $('#BMJ_DisplayRIM').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayRIM').prop('checked', false).checkboxradio('refresh');
  if (J.showFuel == 1) $('#BMJ_DisplayFuel').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_DisplayFuel').prop('checked', false).checkboxradio('refresh');

  $('#BMJ_showClientFuel')
    .prop('checked', J.showClientFuel == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showTZ')
    .prop('checked', J.showTZ == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showTDG')
    .prop('checked', J.showTDG == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showAddress')
    .prop('checked', J.showAddress == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showClientDetails')
    .prop('checked', J.showClientDetails == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showJobNum')
    .prop('checked', J.showJobNum == 1 ? true : false)
    .checkboxradio('refresh');
  $('#BMJ_showStartEndTZ')
    .prop('checked', J.showStartEndTZ == 1 ? true : false)
    .checkboxradio('refresh');

  if (J.Finished == 1) $('#BMJ_Finished').prop('checked', true).checkboxradio('refresh');
  else $('#BMJ_Finished').prop('checked', false).checkboxradio('refresh');

  $('#BILLBY_Hour').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Hobbs').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Distance').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Weight').prop('checked', false).checkboxradio('refresh');
  $('#BILLBY_Volume').prop('checked', false).checkboxradio('refresh');
  switch (J.BillBy) {
    case 'Hour':
      $('#BILLBY_Hour').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Hobbs':
      $('#BILLBY_Hobbs').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Distance':
      $('#BILLBY_Distance').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Weight':
      $('#BILLBY_Weight').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Volume':
      $('#BILLBY_Volume').prop('checked', true).checkboxradio('refresh');
      break;
  }
  BILLING_update_BILLBY_Opt(J.BillBy, J.BillByOPT);
  BILLING_Check_ShowAirBlock();
}

function BILLING_JobList_return() {
  //returns to existing list of jobs and refreshes list
  $.mobile.changePage($('#BILLING_ListJobs'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_ShowJobs(BILLING_CLIENT_INDEX);
}

function BILLING_JobPage() {
  $.mobile.changePage($('#BILLING_NewJobPage'), {
    transition: 'none',
    changeHash: false,
  });
}

function BILLING_createJob() {
  var Name = $('#BMJ_Name').val();
  var flightCatID = $('#BMJ_flightCatID').val();
  var Description = $('#BMJ_Description').val();
  var Min_Hours = $('#BMJ_Min_Hours').val();
  var CrewMins = $('#BMJ_CrewMins').val();
  var Averaged = $('#BMJ_Averaged').val();
  var Daily = $('#BMJ_Daily_Rate').val();
  var DailyAC = 0;
  if ($('#BMJ_Daily_AC').is(':checked')) DailyAC = 1;
  var StartDate = $('#BMJ_StartDate').val();
  var EndDate = $('#BMJ_EndDate').val();
  var BlockTime = $('input[name=BMJ_BlockTime]:checked').val();
  var showAirBlock = $('#BMJ_DisplayAirBlockTime').is(':checked') ? 1 : 0;
  var showPaxWeights = $('#BMJ_DisplayPaxWeights').is(':checked') ? 1 : 0;
  var showCargoWeights = $('#BMJ_DisplayCargoWeights').is(':checked') ? 1 : 0;
  var showRIM = $('#BMJ_DisplayRIM').is(':checked') ? 1 : 0;
  var showFuel = $('#BMJ_DisplayFuel').is(':checked') ? 1 : 0;

  var showClientFuel = $('#BMJ_showClientFuel').is(':checked') ? 1 : 0;
  var showTZ = $('#BMJ_showTZ').is(':checked') ? 1 : 0;
  var showTDG = $('#BMJ_showTDG').is(':checked') ? 1 : 0;
  var showAddress = $('#BMJ_showAddress').is(':checked') ? 1 : 0;
  var showClientDetails = $('#BMJ_showClientDetails').is(':checked') ? 1 : 0;
  var showJobNum = $('#BMJ_showJobNum').is(':checked') ? 1 : 0;
  var showStartEndTZ = $('#BMJ_showStartEndTZ').is(':checked') ? 1 : 0;

  var Finished = $('#BMJ_Finished').is(':checked');

  var firstname = $('#BMJ_Firstname').val();
  var lastname = $('#BMJ_Lastname').val();
  var email = $('#BMJ_Email').val();
  var phone = $('#BMJ_Phone').val();

  var Base = $('#BMJ_Base').val();
  var LockCrew = $('#BMJ_LockCrew').is(':checked');
  var LockAC = $('#BMJ_LockAC').is(':checked');
  var reqSignature = $('#BIreqFRsignatureON').is(':checked');
  var showDetails = $('#BIshowDetails').is(':checked') ? 1 : 0;
  var showJobDetails = $('#BIshowJobDetails').is(':checked') ? 1 : 0;
  var showPaxManLegs = $('#BIshowPaxManLegs').is(':checked') ? 1 : 0;
  var showPaxManReport = $('#BIshowPaxManReport').is(':checked') ? 1 : 0;
  var clientDataFeedPK = $('#BI_ClientDataFeed').val();
  var showNonBillable = $('#BIshowNonBillable').is(':checked') ? 1 : 0;
  var blankLegTimes = $('#BIblankLegTimes').is(':checked') ? 1 : 0;

  var PrimaryKey = $('#BMJ_PrimaryKey').val();
  var BillBy = $('input[name=BILLBY]:checked').val();
  var BillByOPT = '';
  switch (BillBy) {
    case 'Hour':
      BillByOPT = '';
      break;
    case 'Hobbs':
      BillByOPT = $('input[name=BILLBY_HobbsOpt]:checked').val();
      break;
    case 'Distance':
      BillByOPT = $('input[name=BILLBY_DistanceOpt]:checked').val();
      break;
    case 'Weight':
      BillByOPT = $('input[name=BILLBY_WeightOpt]:checked').val();
      break;
    case 'Volume':
      BillByOPT = $('input[name=BILLBY_VolumeOpt]:checked').val();
      break;
  }
  console.log(BILLING_CLIENTS);
  console.log(BILLING_CLIENT_INDEX);
  var ClientPK = BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey;
  var list = $('#BMJ_MultiClientJob').val();
  if (list.length > 0) {
    if (list[0] == 0) {
      ClientPK = 0;
    } else {
      list.push(ClientPK);
      ClientPK =  -2;
    }
  }
  var newJob = {
    PrimaryKey: PrimaryKey,
    ClientPK: ClientPK,
    clientList: list,
    Name: Name,
    flightCatID: flightCatID,
    Description: Description,
    Min_Hours: Min_Hours,
    CrewMins: CrewMins,
    Averaged: Averaged,
    Daily: Daily,
    DailyAC: DailyAC,
    StartDate: StartDate,
    EndDate: EndDate,
    BlockTime: BlockTime,
    showAirBlock: showAirBlock,
    showPaxWeights: showPaxWeights,
    showCargoWeights: showCargoWeights,
    showRIM: showRIM,
    showFuel: showFuel,
    reqSignature: reqSignature,
    Finished: Finished,
    BillBy: BillBy,
    BillByOPT: BillByOPT,
    Base: Base,
    LockCrew: LockCrew,
    LockAC: LockAC,
    showDetails: showDetails,
    showJobDetails: showJobDetails,
    showNonBillable: showNonBillable,
    showPaxManLegs: showPaxManLegs,
    showPaxManReport: showPaxManReport,
    blankLegTimes: blankLegTimes,
    clientDataFeedPK: clientDataFeedPK,
    firstname: firstname,
    lastname: lastname,
    email: email,
    phone: phone,
    showClientFuel: showClientFuel,
    showTZ: showTZ,
    showTDG: showTDG,
    showAddress: showAddress,
    showClientDetails: showClientDetails,
    showJobNum: showJobNum,
    showStartEndTZ: showStartEndTZ,
  };
  document.getElementById('BM_JobDetailsNextBTN').disabled = true;
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=createJob',
    data: newJob,
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'BILLING_createJob()');
        document.getElementById('BM_JobDetailsNextBTN').disabled = false;
      }
      if (data[0].ServerResponse == 'Success') {
        document.getElementById('BM_JobDetailsNextBTN').disabled = false;
        if (PrimaryKey == 'None') {
          $('#BMJ_PrimaryKey').val(data[0].InsertID);
          BILLING_JOB_INDEX = BILLING_JOBS[0].Active.length;
          BILLING_JOBS[0].Active.push(newJob);
        }

        BILLING_JOB_PK = data[0].InsertID;
        BILLING_AddAircraftPage();
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem adding / updating the job.  Please try again. If this continues to happen please submit a bug report'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                document.getElementById('BM_JobDetailsNextBTN').disabled = false;
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem adding / updating the job.  Please try again. If this continues to happen please submit a bug report'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              document.getElementById('BM_JobDetailsNextBTN').disabled = false;
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_AddAircraftPage() {
  //insert or update job
  //Display list of aircraft types and rates
  $.mobile.changePage($('#BILLING_NewJobPage_Aircraft'), {
    transition: 'none',
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getACTypes&JobID=' + BILLING_JOB_PK, function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_AircraftList').html('<h1>' + iTrans('No Aircraft In System') + '</h1>');
      $('#BILLING_AircraftList').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_JOBSLIST').html('<h1>' + iTrans('Offline Cannot Retrieve List of Aircraft') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception')
        ServerResponse(json_data[0], 'Billing Add Aircraft Page');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      console.log('Received Aircraft List');
      BILLING_DisplayAircraft(json_data);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_AircraftList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_DisplayAircraft(data) {
  var List = data[0].List;
  var Types = data[0].Types;
  var html = '<h3>' + iTrans('Aircraft Types Used') + '</h3>';
  var J = BILLING_JOBS[0].Active[BILLING_JOB_INDEX];
  var billBy = 'Wet';
  switch (J.BillByOPT) {
    case 'Volume':
    case 'Distance':
    case 'Weight':
      billBy = J.BillBy + '/' + J.BillByOPT;
      switch (J.BillByOPT) {
        case 'litres':
          billBy = 'Litre';
          break;
        case 'gallonsus':
          billBy = 'US Gallon';
          break;
        case 'galllonsimp':
          billBy = 'Imp Gallon';
          break;
        default:
          billBy = J.BillByOPT;
      }
      break;
    case 'Hour':
      billBy = 'Wet';
  }
  if (List.length > 0) {
    html +=
      '<button onClick="BILLING_RemoveAircraftType()" data-mini="true" data-icon="minus" class="redbg" >' +
      iTrans('Remove All Aircraft Types') +
      '</button>';
    html +=
      '<table  id="BIT_Admin5" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Type') +
      '</th><th>' +
      billBy +
      ' ' +
      iTrans('Rate') +
      '</th><th>' +
      iTrans('Dry Rate') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var L in List) {
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_RemoveAircraftType(\"" +
        List[L].AcType +
        "\")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
      html +=
        '<tr><td>' +
        List[L].AcType +
        '</td><td><input type="number" step="1" value="' +
        List[L].WetRate +
        '" style="width:80px;" onChange="BILLING_changeFuelRate(' +
        L +
        ",'" +
        List[L].AcType +
        '\')" id="BM_WR-' +
        L +
        '"/></td><td><input type="number" step="1" value="' +
        List[L].DryRate +
        '" style="width:80px;" onChange="BILLING_changeFuelRate(' +
        L +
        ",'" +
        List[L].AcType +
        '\')" id="BM_DR-' +
        L +
        '"/></td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</table>';
  } else html += '<p>' + iTrans('No Aircraft Selected for Job') + '</p>';
  html += '<h3>Unused Aircraft Types</h3>';

  //Add aircraft selectable list
  if (Types.length > 0) {
    html +=
      '<button onClick="BILLING_AddAircraftType()" data-testid="billing-add-aircraft-all-button" data-mini="true" data-icon="plus" class="greenbg" >' +
      iTrans('Add All Aircraft Types') +
      '</button>';
    html +=
      '<table  id="BIT_Admin5" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var T in Types) {
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_AddAircraftType(\"" +
        Types[T].AcType +
        "\")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
      html += '<tr><td>' + Types[T].AcType + '</td><td>' + actions + '</td></tr>';
    }
    html += '</table>';
  } else html += '<p>' + iTrans('No Aircraft Available for Job') + '</p>';
  $('#BILLING_AircraftList').html(html);
  $('#BILLING_AircraftList').enhanceWithin();
  AddTableSorter('BIT_Admin5');
}

function BILLING_changeFuelRate(L, Type) {
  console.log(L);
  console.log(Type);
  var WR = $('#BM_WR-' + L).val();
  var DR = $('#BM_DR-' + L).val();
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=changeACRate&JobID=' +
      BILLING_JOB_PK +
      '&AcType=' +
      encodeURIComponent(Type) +
      '&WetRate=' +
      WR +
      '&DryRate=' +
      DR,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_AircraftList').html('<h1>' + iTrans('Offline Cannot Update Fuel Rate') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'Billing Add Aircraft Page');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        console.log('Updated Fuel Rates');
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_AircraftList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_AddAircraftType(Type = '') {
  $.getJSON(
    BASEURL + 'menu-Billing-AJAX.php?action=addACTypes&JobID=' + BILLING_JOB_PK + '&AcType=' + encodeURIComponent(Type),
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_AircraftList').html('<h1>' + iTrans('Offline Cannot Add Aircraft') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'Billing Add Aircraft Page');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        console.log('Received Updated Aircraft List');
        BILLING_DisplayAircraft(json_data);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_AircraftList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_RemoveAircraftType(Type = '') {
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=removeACTypes&JobID=' +
      BILLING_JOB_PK +
      '&AcType=' +
      encodeURIComponent(Type),
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_AircraftList').html('<h1>' + iTrans('Offline Cannot Remove Aircraft') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'Billing Add Aircraft Page');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        console.log('Received Updated Aircraft List');
        BILLING_DisplayAircraft(json_data);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_AircraftList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_AddCrewPage() {
  //Display list of pilots engineers and ground crew to add to job
  $.mobile.changePage($('#BILLING_NewJobPage_Crew'), {
    transition: 'none',
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getCrew&JobID=' + BILLING_JOB_PK, function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_CrewList').html('<h1>' + iTrans('No Staff In System') + '</h1>');
      $('#BILLING_CrewList').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_CrewList').html('<h1>' + iTrans('Offline Cannot Retrieve List of Staff') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing Add Crew Page');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      console.log('Received Staff List');
      BILLING_DisplayCrew(json_data);
      BILLING_sync_data(); //to update jobs and client lists
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_CrewList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_RefreshCrewList() {
  BILLING_DisplayCrew(BILLING_DisplayCrew_LIST);
}
var BILLING_DisplayCrew_LIST = [];
function BILLING_DisplayCrew(data) {
  console.log(data);
  BILLING_DisplayCrew_LIST = data;
  var CurBase = $('#BMJ_Base').val();
  if (CurBase == '') {
    $('#BIT_ShowJobBaseOnly').prop('checked', false).prop('disabled', true).checkboxradio('refresh');
  } else {
    $('#BIT_ShowJobBaseOnly').prop('disabled', false).checkboxradio('refresh');
    if (!$('#BIT_ShowJobBaseOnly').is(':checked')) CurBase = '';
  }
  var Crew = data[0].Crew;
  var AddList = data[0].AddList;
  var html = '<h3>Assigned Crew</h3>';

  if (Crew.length > 0) {
    html +=
      '<button onClick="BILLING_RemoveCrew()" data-mini="true" data-icon="minus" class="redbg" >' +
      iTrans('Remove All Crew') +
      '</button>';
    html +=
      '<table  id="BIT_Admin6" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Current Base') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var C in Crew) {
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_RemoveCrew(" +
        Crew[C].PrimaryKey +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
      html +=
        '<tr><td>' +
        Crew[C].chatname +
        '</td><td>' +
        Crew[C].BASED_IN +
        '</td><td>' +
        Crew[C].staffType +
        '<td>' +
        actions +
        '</td></tr>';
    }
    html += '</table>';
  } else html += '<p>' + iTrans('No Crew Selected for Job') + '</p>';
  html += '<h3>' + iTrans('Available Crew List') + '</h3>';
  //Add aircraft selectable list
  if (AddList.length > 0) {
    html +=
      '<button onClick="BILLING_AddCrew()" data-testid="billing-add-crew-all-button" data-mini="true" data-icon="plus" class="greenbg" >' +
      iTrans('Add All Crew') +
      '</button>';
    html +=
      '<table  id="BIT_Admin6b" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Current Base') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    var Other = '';
    var Pilot = '';
    for (var A in AddList) {
      if (AddList[A].BASED_IN != CurBase && CurBase != '') continue;
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_AddCrew(" +
        AddList[A].PrimaryKey +
        ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
      var U =
        '<tr><td>' +
        AddList[A].chatname +
        '</td><td>' +
        AddList[A].BASED_IN +
        '</td><td>' +
        AddList[A].staffType +
        '</td><td>' +
        actions +
        '</td></tr>';
      if (
        AddList[A].staffType == 'Pilot' ||
        AddList[A].staffType == 'NASP SO' ||
        AddList[A].staffType == 'Rescue Specialist'
      )
        Pilot += U;
      else Other += U;
    }
    html += Pilot;
    html += Other;
    html += '</table>';
  } else html += '<p>' + iTrans('No Crew Available for Job') + '</p>';
  $('#BILLING_CrewList').html(html);
  $('#BILLING_CrewList').enhanceWithin();
  AddTableSorter('BIT_Admin6');
  AddTableSorter('BIT_Admin6b');
}

function BILLING_AddCrew(UserPK = '') {
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=addCrew&JobID=' +
      BILLING_JOB_PK +
      '&UserPK=' +
      UserPK +
      '&base=' +
      encodeURIComponent($('#BIT_ShowJobBaseOnly').is(':checked') ? $('#BMJ_Base').val() : ''),
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_CrewList').html('<h1>' + iTrans('Offline Cannot Add Aircraft') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing Add Crew Page');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        console.log('Received Updated Crew List');
        BILLING_DisplayCrew(json_data);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_CrewList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_RemoveCrew(UserPK = '') {
  $.getJSON(
    BASEURL + 'menu-Billing-AJAX.php?action=removeCrew&JobID=' + BILLING_JOB_PK + '&UserPK=' + UserPK,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_CrewList').html('<h1>' + iTrans('Offline Cannot Add Aircraft') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'Billing Remove Crew Page');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        console.log('Received Updated Crew List');
        BILLING_DisplayCrew(json_data);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_CrewList').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

//******************************************************Admin Custom Fields

function BILLING_ClientCF(changePage = true) {
  BILLING_RETURN_TO = 'Client';
  if (changePage) {
    $.mobile.changePage($('#BILLING_NewClientCFPage'), {
      changeHash: false,
    });
  }
  //get custom fields for client
  var CF = BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields;
  if (CF.length > 0) {
    var html =
      "<table  id='BIT_Admin7' class='first-td-fill'><thead><tr><th>" +
      iTrans('Preview') +
      '</th><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Collected') +
      '</th><th>' +
      iTrans('Req') +
      '</th><th>' +
      iTrans('Action') +
      '</th><th>' +
      iTrans('Sort Order') +
      '</th></tr></thead><tbody>';
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft !== '') {
        continue;
      }
      var Collected = checkCfieldCollectedFor(I);
      if (I.PerLeg == 1) Collected += ' (' + iTrans('Per Leg') + ')';
      var Req = iTrans('No');
      if (I.req == 1 && I.usedFor == 'FR') Req = iTrans('FR');
      if (I.req == 1 && I.usedFor.substring(0,3) == 'SCH') Req = iTrans('Sched');
      if (I.reqItin == 1) Req = iTrans('Itin');
      if (I.req == 1 && I.reqItin == 1) Req = iTrans('All');
      var Preview = createCustomField('', I, I.default);
      var Button =
        '<div class="vertical-center-container"><button onclick="BILLING_EditClientCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/edit.svg"></button><button onclick="BILLING_RemoveClientCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/delete.svg"></button></div>';

      var SortOrder =
        '<div class="vertical-center-container"><button class="action-btn ui-btn" data-mini="true" onclick="CF_SortOrder_UP(' +
        i +
        ')"><img src="./images/Plus-icon.png" widht="20px" height="20px"></button><button class="action-btn" data-mini="true" onclick="CF_SortOrder_DOWN(' +
        i +
        ')"><img src="./images/Minus-icon.png" widht="20px" height="20px"></button></div>';

      html +=
        '<tr data-testid=billing-custom-field-preview-item-' +
        I.Label +
        ' ><td>' +
        Preview +
        '</td><td>' +
        I.Label +
        '</td><td>' +
        I.type +
        '</td><td>' +
        Collected +
        '</td><td>' +
        Req +
        '</td><td>' +
        Button +
        '</td><td>' +
        SortOrder +
        '</td></tr>';
    }
    html += '</tbody></table>';
    $('#ClientCustomFieldsContent').html(html);
  } else {
    $('#ClientCustomFieldsContent').html('<h3>' + iTrans('No Custom Fields Found') + '</h3>');
  }
  $('#ClientCustomFieldsContent').enhanceWithin();
  //AddTableSorter("BIT_Admin7"); this is stupid since custom field order are sortable now
}

function CF_SortOrder_DOWN(q) {
  if (q == BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields - 1) return;
  q = parseInt(q);
  nq = q + 1;
  CF_UpdateSortOrder(q, nq);
}
function CF_SortOrder_UP(q) {
  if (q == 0) return;
  q = parseInt(q);
  nq = q - 1;
  CF_UpdateSortOrder(q, nq);
}
function CF_UpdateSortOrder(q, nq) {
  var PK1 = BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields[q].PrimaryKey;
  var PK2 = BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields[nq].PrimaryKey;
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=reorderCflieds&PK1=' + PK1 + '&PK2=' + PK2, function (json_data) {
    BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields = CF_array_move(BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields, q, nq);
    BILLING_ClientCF(false);
  }).fail(function (jqXHR, status, error) {
    alert(iTrans('Connection Error. Sort order not updated'));
  });
}

function BILLING_Client_return() {
  $.mobile.changePage($('#BILLING_NewClientPage'), {
    changeHash: false,
    reverse: true,
  });
}

function BILLING_EditClientCF(index) {
  //index of CF
  $.mobile.changePage($('#BILLING_NewClientCFaddPage'), {
    changeHash: false,
  });
  var C;
  if (BILLING_RETURN_TO == 'Briefing') {
    $('#BILLING_CANCEL_CF').removeAttr('onclick');
    $('#BILLING_CANCEL_CF').unbind('click');
    $('#BILLING_CANCEL_CF').click(PaxBriefingCancelCF);
    $('#BILLING_CfieldItems_HLDR').hide();
    $('#PAX_CfieldItems_HLDR').show();
    C = ADMIN_CFIELDS[index];
    C.reqItin = C.req;
    BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a passenger;
    /*
     BILLING_CLIENT_CFIELD.PrimaryKey = C.PrimaryKey;
     BILLING_CLIENT_CFIELD.type = "";
     BILLING_CLIENT_CFIELD.options = [];
     */
    BILLING_CLIENT_CFIELD = C;
    $('#BILLING_CF_USEON_SCH').prop('disabled', true).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCA').prop('disabled', true).checkboxradio('refresh');
  } else if (BILLING_RETURN_TO == 'Passenger') {
    $('#BILLING_CF_USEON_SCH').prop('disabled', true).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCA').prop('disabled', true).checkboxradio('refresh');
    $('#BILLING_CANCEL_CF').removeAttr('onclick');
    $('#BILLING_CANCEL_CF').unbind('click');
    $('#BILLING_CANCEL_CF').click(PaxBriefingCancelPaxCF);
    $('#BILLING_CfieldItems_HLDR').hide();
    $('#PAX_CfieldItems_HLDR').show();
    C = ADMIN_CFIELDS[index];
    BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a passenger;
    /*
     BILLING_CLIENT_CFIELD.PrimaryKey = C.PrimaryKey;
     BILLING_CLIENT_CFIELD.type = "";
     BILLING_CLIENT_CFIELD.options = [];
     */
    BILLING_CLIENT_CFIELD = C;
    C.req = C.reqPax;
    C.reqItin = C.reqMan;
  } else if (ADMIN_BILLING_FIELDS) {
    $('#BILLING_CF_USEON_SCH').prop('disabled', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCA').prop('disabled', false).checkboxradio('refresh');
    C = BIDATA.Cfields[index];
    BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a client;
    /*
     BILLING_CLIENT_CFIELD.PrimaryKey = C.PrimaryKey;
     BILLING_CLIENT_CFIELD.type = "";
     BILLING_CLIENT_CFIELD.options = [];
     */
    BILLING_CLIENT_CFIELD = C;
  } else {
    BILLING_CLIENT_CFIELD_INDEX = index;
    BILLING_CLIENT_CFIELD = BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields[index];
    C = BILLING_CLIENT_CFIELD;
  }
  var options = JSON.parse(C.options);
  var html = '';
  $('#BILLING_CF_Label').val(C.Label);
  $('#BILLING_CF_TYPE').val(C.type).selectmenu('refresh');

  if (C.req == 1) $('#BILLING_CF_REQ').prop('checked', true);
  else $('#BILLING_CF_REQ').prop('checked', false);

  if (C.reqItin == 1) $('#BILLING_CF_REQ_ITIN').prop('checked', true).checkboxradio('refresh');
  else $('#BILLING_CF_REQ_ITIN').prop('checked', false).checkboxradio('refresh');

  CheckUseableCFinputs({ value: C.usedFor });

  $('#BILLING_CF_USEON_ALL').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
  if (C.usedFor == 'ALL') {
    $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
  }else{
    if (C.usedFor.indexOf('FR') != -1 || C.usedFor.indexOf('Pax') != -1){
      $('#BILLING_CF_USEON_FR').prop('checked', true).checkboxradio('refresh');
    }
    if (C.usedFor.indexOf('ITIN') != -1 || C.usedFor.indexOf('Man') != -1){
      $('#BILLING_CF_USEON_ITIN').prop('checked', true).checkboxradio('refresh');
    }
  }

  if (C.hideDefault == 1) $('#BILLING_CF_HIDEDEFAULT').prop('checked', true).checkboxradio('refresh');
  else $('#BILLING_CF_HIDEDEFAULT').prop('checked', false).checkboxradio('refresh');

  if (C.PerLeg == 1) {
    $('#BILLING_CF_PERLEG').prop('checked', true);
    $('#BILLING_CF_PERLEG').checkboxradio('refresh');
  } else {
    $('#BILLING_CF_PERLEG').prop('checked', false);
    $('#BILLING_CF_PERLEG').checkboxradio('refresh');
  }
  $('#BILLING_CF_CHK_OPTIONS_HLDR').hide();
  switch (C.type) {
    case 'text':
      $('#BILLING_CF_BILLITEM').checkboxradio('disable').checkboxradio('refresh');
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', false);
      html +=
        '<label for="CF_TEXT_MAX">' +
        iTrans('Max Length') +
        '</label><input type="number" id="CF_NUM_MIN" value="' +
        options.maxlength +
        '" min="1" max="512" data-mini="true"/>';
      html +=
        '<label for="CF_TEXT_DEFAULT">' +
        iTrans('Default Text') +
        '</label><input type="text" id="CF_TEXT_DEFAULT" data-mini="true" value="' +
        C.default +
        '"/>';
      if (C.Placeholder != '' && C.Placeholder != undefined) $('#BILLING_CF_PH').val(C.Placeholder);
      break;
    case 'number':
      $('#BILLING_CF_BILLITEM').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', true);
      html +=
        '<label for="CF_NUM_MIN">' +
        iTrans('Min Value') +
        '</label><input type="number" id="CF_NUM_MIN" value="' +
        options.min +
        '" data-mini="true"/>';
      html +=
        '<label for="CF_NUM_MAX">' +
        iTrans('Max Value') +
        '</label><input type="number" id="CF_NUM_MAX" value="' +
        options.max +
        '" data-mini="true"/>';
      html +=
        '<label for="CF_NUM_DEFAULT">' +
        iTrans('Default Number') +
        '</label><input type="number" id="CF_NUM_DEFAULT" data-mini="true" value="' +
        C.default +
        '"/>';
      break;
    case 'checkbox':
      $('#BILLING_CF_BILLITEM').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', true);
      //$("#BILLING_CF_REQ").prop('checked', false);
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_CHK_OPTIONS_HLDR').show();
      if (C.default == 1) {
        $('#BILLING_CF_CHK_OPTIONS0').prop('checked', false).checkboxradio('refresh');
        $('#BILLING_CF_CHK_OPTIONS1').prop('checked', true).checkboxradio('refresh');
      } else {
        $('#BILLING_CF_CHK_OPTIONS0').prop('checked', true).checkboxradio('refresh');
        $('#BILLING_CF_CHK_OPTIONS1').prop('checked', false).checkboxradio('refresh');
      }
      break;
    case 'select':
      $('#BILLING_CF_BILLITEM').checkboxradio('enable').checkboxradio('refresh');
      BILLING_CLIENT_CFIELD.options = options;
      console.log(BILLING_CLIENT_CFIELD);
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', false);
      if (C.Placeholder != '' && C.Placeholder != undefined) $('#BILLING_CF_PH').val(C.Placeholder);
      html += CF_RefreshList(true);
      break;
    case 'signature':
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', true);
      $('#BILLING_CF_BILLITEM').prop('checked', false);
      $('#BILLING_CF_BILLITEM').checkboxradio('disable').checkboxradio('refresh');
      html += '<label for="BILLING_CF_SIGNATURE_SignTXT">' + iTrans('Signature Reason Description') + '</label>';
      html += '<textarea id="BILLING_CF_SIGNATURE_SignTXT">' + options.SignatureDescription + '</textarea>';
      break;
  }

  if (C.Bill_Item == 1 && C.type != 'text') {
    $('#BILLING_CF_BILLITEM').prop('checked', true);
    $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
    BILLING_BILLITEM({ checked: true });
  } else {
    $('#BILLING_CF_BILLITEM').prop('checked', false);
    $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
    BILLING_BILLITEM({ checked: false });
  }

  $('#ClientCustomField').html(html);
  $('#ClientCustomField').enhanceWithin();
}

function BILLING_RemoveClientCF(index) {
  //index of CF
  BILLING_CLIENT_CFIELD_INDEX = index;
  var PK = BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields[index].PrimaryKey;
  console.log(PK);
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('You are about to delete this custom field') +
      '</p>  <h3>' +
      iTrans('Are you Sure?') +
      '</h3></center>',
    buttons: {
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
      [iTrans('Delete')]: {
        click: function () {
          //warn user existing records for each user will be deleted
          $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=deleteCustomField&PK=' + PK, function (json_data) {
            //status could be offline/Not Logged In/Uncaught Exception
            if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
            else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
            else if (json_data[0].ServerResponse == 'No Records') {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('ERROR'),
                headerClose: false,
                buttonPrompt: iTrans('ERROR Contact site admin'),
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                },
              });
            } else {
              if (BILLING_RETURN_TO == 'Client') BILLING_LOAD_ClientList('reload');
              else if (BILLING_RETURN_TO == 'Company') returnAdminBICF();
              else if (BILLING_RETURN_TO == 'Pax') returnPaxBICF();
            }
          }).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
    },
  });
}

function BILLING_AddClientCF() {
  $.mobile.changePage($('#BILLING_NewClientCFaddPage'), {
    changeHash: false,
  });
  BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a client;
  BILLING_CLIENT_CFIELD.PrimaryKey = 0;
  BILLING_CLIENT_CFIELD.type = '';
  BILLING_CLIENT_CFIELD.options = [];
  BILLING_CLIENT_CFIELD.Bill_Item = 0;
  BILLING_CLIENT_CFIELD.Bill_Item_options = '';
  $('#BILLING_CF_BILLITEM').prop('checked', false);
  $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
  BILLING_BILLITEM({ checked: false });
  //we're here at the page now what?  resetl inputs
  $('#BILLING_CF_Label').val('');
  $('#BILLING_CF_TYPE').val(null).selectmenu('refresh');

  $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_PH').val('');

  $('#BILLING_CF_REQ').prop('checked', false);
  $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_REQ_ITIN').prop('checked', false);
  $('#BILLING_CF_REQ_ITIN').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_HIDEDEFAULT').prop('checked', false);
  $('#BILLING_CF_HIDEDEFAULT').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_PERLEG').prop('checked', false);
  $('#BILLING_CF_PERLEG').checkboxradio('refresh');

  $('#ClientCustomField').html('');
}

function CheckUseableCFinputs(e) {
  if (e.value == 'ALL'){
    $('#BILLING_CF_REQ').prop('disabled', false).checkboxradio('refresh');
    $('#BILLING_CF_REQ_ITIN').prop('disabled', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCH').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCA').prop('checked', false).checkboxradio('refresh');

  }else{
    if ($('#BILLING_CF_USEON_FR').is(':checked')  || $('#BILLING_CF_USEON_SCH').is(':checked') || $('#BILLING_CF_USEON_SCA').is(':checked')){
      $('#BILLING_CF_REQ').prop('disabled', false).checkboxradio('refresh');
    }else{
      $('#BILLING_CF_REQ').prop('checked', false);
      $('#BILLING_CF_REQ').prop('disabled', true).checkboxradio('refresh');
    }
    if ($('#BILLING_CF_USEON_ITIN').is(':checked')){
      $('#BILLING_CF_REQ_ITIN').prop('disabled', false).checkboxradio('refresh');
    }else{
      $('#BILLING_CF_REQ_ITIN').prop('checked', false);
      $('#BILLING_CF_REQ_ITIN').prop('disabled', true).checkboxradio('refresh');
    }
  }
  //set default input if necessary
  if ($('#BILLING_CF_USEON_FR').is(':checked') && $('#BILLING_CF_USEON_ITIN').is(':checked') && $('#BILLING_CF_USEON_SCH').is(':checked') && $('#BILLING_CF_USEON_SCA').is(':checked'))
  {
    $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_CF_REQ').prop('disabled', false).checkboxradio('refresh');
    $('#BILLING_CF_REQ_ITIN').prop('disabled', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCH').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_CF_USEON_SCA').prop('checked', false).checkboxradio('refresh');
  }else{
      if ($('#BILLING_CF_USEON_FR').is(':checked') || $('#BILLING_CF_USEON_ITIN').is(':checked') || $('#BILLING_CF_USEON_SCH').is(':checked') || $('#BILLING_CF_USEON_SCA').is(':checked')){
        $('#BILLING_CF_USEON_ALL').prop('checked', false).checkboxradio('refresh');
      }else{
        $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
      }
    }


}

function BILLING_ClientCFreturn(e) {
  var reload = e || 'same';
  if (BILLING_RETURN_TO == 'Passenger') {
    PaxCfields();
    return;
  }
  $.mobile.changePage($('#BILLING_NewClientCFPage'), {
    changeHash: false,
    reverse: true,
  });

  if ((reload = 'reload')) {
    BILLING_LOAD_ClientList('reload');
  }
}

function BILLING_CFTYPE(e) {
  //User has selected or changed the custom field input type
  console.log(e);
  var html = '';
  $('#BILLING_CF_BILLITEM').checkboxradio('enable').checkboxradio('refresh');
  $('#BILLING_CF_CHK_OPTIONS_HLDR').hide();
  switch (e) {
    case 'text':
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', false);
      html +=
        '<label for="CF_TEXT_MAX">' +
        iTrans('Max Length') +
        '</label><input type="number" id="CF_NUM_MIN" value="64" min="1" max="512" data-mini="true"/>';
      html +=
        '<label for="CF_TEXT_DEFAULT">' +
        iTrans('Default Text') +
        '</label><input type="text" id="CF_TEXT_DEFAULT" data-mini="true"/>';
      $('#BILLING_CF_BILLITEM').prop('checked', false);
      $('#BILLING_CF_BILLITEM').checkboxradio('disable').checkboxradio('refresh');
      break;
    case 'number':
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', true);
      $('#BILLING_CF_BILLITEM').prop('checked', false);
      $('#BILLING_CF_BILLITEM').checkboxradio('enable').checkboxradio('refresh');
      html +=
        '<label for="CF_NUM_MIN">' +
        iTrans('Min Value') +
        '</label><input type="number" id="CF_NUM_MIN" value="0" data-mini="true"/>';
      html +=
        '<label for="CF_NUM_MAX">' +
        iTrans('Max Value') +
        '</label><input type="number" id="CF_NUM_MAX" value="9999999999" data-mini="true"/>';
      html +=
        '<label for="CF_NUM_DEFAULT">' +
        iTrans('Default Number') +
        '</label><input type="number" id="CF_NUM_DEFAULT" data-mini="true"/>';
      break;
    case 'checkbox':
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', true);
      $('#BILLING_CF_REQ').prop('checked', false);
      $('#BILLING_CF_BILLITEM').prop('checked', false);
      $('#BILLING_CF_BILLITEM').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_CHK_OPTIONS_HLDR').show();

      $('#BILLING_CF_CHK_OPTIONS0').prop('checked', true).checkboxradio('refresh');
      $('#BILLING_CF_CHK_OPTIONS1').prop('checked', false).checkboxradio('refresh');

      break;
    case 'select':
      BILLING_CLIENT_CFIELD.options = [];
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', false);
      html += CF_RefreshList(true);
      break;
    case 'signature':
      BILLING_CLIENT_CFIELD.options = [];
      $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');
      $('#BILLING_CF_PH').val('');
      $('#BILLING_CF_PH').prop('disabled', true);
      $('#BILLING_CF_BILLITEM').prop('checked', false);
      $('#BILLING_CF_BILLITEM').checkboxradio('disable').checkboxradio('refresh');
      html += '<label for="BILLING_CF_SIGNATURE_SignTXT">' + iTrans('Signature Reason Description') + '</label>';
      html += '<textarea id="BILLING_CF_SIGNATURE_SignTXT"></textarea>';
      break;
  }
  BILLING_CLIENT_CFIELD.type = e;
  if (BILLING_CLIENT_CFIELD.Bill_Item == 1 && e != 'text') {
    $('#BILLING_CF_BILLITEM').prop('checked', true);
    $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
    BILLING_BILLITEM({ checked: true });
  } else {
    $('#BILLING_CF_BILLITEM').prop('checked', false);
    $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
    BILLING_BILLITEM({ checked: false });
  }
  $('#ClientCustomField').html(html);
  $('#ClientCustomField').enhanceWithin();
}

function BILLING_CFTYPE_OPT(e) {
  //User has selected or changed the custom field input type
  console.log(e);
  if (e == 'BillByVal') {
    $('#BILLITEM_OPTION_MULTIPLE').prop('disabled', true);
    $('#BILLITEM_OPTION_MULTIPLE').val('');
  } else {
    $('#BILLITEM_OPTION_MULTIPLE').prop('disabled', false);
    $('#BILLITEM_OPTION_MULTIPLE').val(0);
  }
}

function BILLING_BILLITEM(e) {
  if (e.checked) {
    $('#BILLITEM_OPTIONS').show();
    var CF = BILLING_CLIENT_CFIELD;
    if (CF.type == 'select') {
      for (var i in CF.options) {
        //Check each select value to ensure it can be cast as a number
        if (isNaN(CF.options[i].value)) {
          alert(iTrans('Select option value must contain numbers only to be used as a Billable Item!'));
          $('#BILLING_CF_BILLITEM').prop('checked', false);
          $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
          BILLING_BILLITEM({ checked: false });
          break;
        }
      }
    }
    //populate BillItem Options for type or display existing
    if (CF.Bill_Item_options != '') {
      var Opt = JSON.parse(CF.Bill_Item_options);
      if (Opt.Taxable == 1) {
        $('#BILLITEM_OPTION_TAX').prop('checked', true);
        $('#BILLITEM_OPTION_TAX').checkboxradio('refresh');
      } else {
        $('#BILLITEM_OPTION_TAX').prop('checked', false);
        $('#BILLITEM_OPTION_TAX').checkboxradio('refresh');
      }
      if (Opt.Unit != undefined) {
        //for legacy invoices

        $('#BILLITEM_OPTION_Qty_Unit').val(Opt.Unit);
        $('#BILLITEM_OPTION_Qty_Unit').selectmenu('refresh');
        if (Opt.Unit == 'BillByVal') {
          $('#BILLITEM_OPTION_MULTIPLE').prop('disabled', true);
          $('#BILLITEM_OPTION_MULTIPLE').val('');
        }
      } else {
        $('#BILLITEM_OPTION_Qty_Unit').val('');
        $('#BILLITEM_OPTION_Qty_Unit').selectmenu('refresh');
        $('#BILLITEM_OPTION_MULTIPLE').prop('disabled', false);
        $('#BILLITEM_OPTION_MULTIPLE').val('');
      }
      $('#BILLITEM_OPTION_MULTIPLE').val(Opt.Multiple);
    } else {
      $('#BILLITEM_OPTION_Qty_Unit').val('');
      $('#BILLITEM_OPTION_Qty_Unit').selectmenu('refresh');
      $('#BILLITEM_OPTION_MULTIPLE').prop('disabled', false);
      $('#BILLITEM_OPTION_MULTIPLE').val('');
      $('#BILLITEM_OPTION_TAX').prop('checked', true);
      $('#BILLITEM_OPTION_TAX').checkboxradio('refresh');
    }
  } else {
    $('#BILLITEM_OPTIONS').hide();
  }
}

function CF_DELETE_OPTION() {
  BILLING_CLIENT_CFIELD.options.pop();
  CF_RefreshList();
}

function CF_ADD_OPTION() {
  if ($('#CF_SELECT_VALUE').val() == '' || $('#CF_SELECT_TEXT').val() == '') return;
  BILLING_CLIENT_CFIELD.options.push({
    value: $('#CF_SELECT_VALUE').val(),
    text: $('#CF_SELECT_TEXT').val(),
  });
  CF_RefreshList();
}

function CF_RefreshList(returnHTML = false) {
  var html =
    '<h3>' +
    iTrans('Input Dropdown Options') +
    '</h3><table><thead><tr><th>' +
    iTrans('Value') +
    '</th><th>' +
    iTrans('Text') +
    '</th><th>' +
    iTrans('Change Order') +
    '</tr></thead><tbody>';
  for (var i in BILLING_CLIENT_CFIELD.options) {
    var I = BILLING_CLIENT_CFIELD.options[i];
    var Buttons =
      '<div class="vertical-center-container"><button onclick="CF_RemoveOption(' +
      i +
      ');" class="action-btn"><img src="./images/delete.svg"></button><button class="action-btn ui-btn" data-mini="true" onclick="CF_Order_UP(' +
      i +
      ')"><img src="./images/Plus-icon.png" widht="20px" height="20px"></button><button class="action-btn" data-mini="true" onclick="CF_Order_DOWN(' +
      i +
      ')"><img src="./images/Minus-icon.png" widht="20px" height="20px"></button></div>';

    html +=
      '<tr><td>' +
      '<input type="text" id="CF_Dropdown_Item_Value_' +
      i +
      '" value="' +
      escapeHtml(I.value) +
      '" onChange="CF_EditOption(' +
      i +
      ')"/>' +
      '</td><td>' +
      '<input type="text" id="CF_Dropdown_Item_Text_' +
      i +
      '" value="' +
      escapeHtml(I.text) +
      '"  onChange="CF_EditOption(' +
      i +
      ')"/>' +
      '</td><td>' +
      Buttons +
      '</td></tr>';
  }
  html += '<tr><td><input type="text" id="CF_SELECT_VALUE" /></td>';
  html += '<td><input type="text" id="CF_SELECT_TEXT" /></td></tr>';
  html +=
    '<tr><td><button class="redbg" data-icon="delete" onClick="CF_DELETE_OPTION()" data-mini="true" >' +
    iTrans('Delete') +
    '</button></td><td><button class="greenbg" data-icon="plus" onClick="CF_ADD_OPTION()" data-mini="true" >' +
    iTrans('Add') +
    '</button></td></tr></tbody></table>';
  html += '<button data-mini="true" onClick="CF_NaturalSortEntries();">Sort Entries by Text Field</button><br />';
  if (returnHTML) return html;
  $('#ClientCustomField').html(html);
  $('#ClientCustomField').enhanceWithin();
}
function CF_Order_DOWN(q) {
  if (q == BILLING_CLIENT_CFIELD.options.length - 1) return;
  q = parseInt(q);
  nq = q + 1;
  BILLING_CLIENT_CFIELD.options = CF_array_move(BILLING_CLIENT_CFIELD.options, q, nq);
  CF_RefreshList();
}
function CF_Order_UP(q) {
  if (q == 0) return;
  q = parseInt(q);
  nq = q - 1;
  BILLING_CLIENT_CFIELD.options = CF_array_move(BILLING_CLIENT_CFIELD.options, q, nq);
  CF_RefreshList();
}
function CF_array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}
function CF_EditOption(index) {
  var I = BILLING_CLIENT_CFIELD.options[index];
  I.value = $('#CF_Dropdown_Item_Value_' + index).val();
  I.text = $('#CF_Dropdown_Item_Text_' + index).val();
}
function CF_RemoveOption(index) {
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      iTrans('Are you sure you want to remove the') +
      '"' +
      BILLING_CLIENT_CFIELD.options[index].text +
      '" ' +
      iTrans('Dropdown Option') +
      '?',
    buttons: {
      [iTrans('Remove')]: {
        click: function () {
          BILLING_CLIENT_CFIELD.options.splice(index, 1);
          CF_RefreshList();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}
function CF_NaturalSortEntries() {
  BILLING_CLIENT_CFIELD.options.sort(BILLING_SortSelectOptions);
  CF_RefreshList();
}
function BILLING_SaveClientCF() {
  var label = $('#BILLING_CF_Label').val();
  if (label.length < 1) {
    BillingValidation(iTrans('Label field cannot be empty'));
    return;
  }
  if (label.length > 64) {
    BillingValidation(iTrans('Label field cannot be longer than 64 characters'));
    return;
  }
  var type = $('#BILLING_CF_TYPE').val();
  if (type == null) {
    BillingValidation(iTrans('Please Select Input Type'));
    return;
  }
  var prompt = $('#BILLING_CF_PH').val();
  var req = 0;
  if ($('#BILLING_CF_REQ').is(':checked')) req = 1;

  var reqItin = 0;
  if ($('#BILLING_CF_REQ_ITIN').is(':checked')) reqItin = 1;

  var usedFor = '';
  if ($('#BILLING_CF_USEON_ALL').is(':checked')){
    usedFor = 'ALL';
  }else{
    if ($('#BILLING_CF_USEON_FR').is(':checked')){
      if (BILLING_RETURN_TO == 'Passenger')
      {
        usedFor += 'Pax';
      }else{
        usedFor += 'FR';
      }
    }
    if ($('#BILLING_CF_USEON_ITIN').is(':checked')){
      if (BILLING_RETURN_TO == 'Passenger')
      {
        usedFor += 'Man';
      }else{
        usedFor += 'ITIN';
      }

    }
    if ($('#BILLING_CF_USEON_SCH').is(':checked')){
      usedFor += 'SCH';
    }
    if ($('#BILLING_CF_USEON_SCA').is(':checked')){
      usedFor += 'SCA';
    }
  }

  var hideDefault = 0;
  if ($('#BILLING_CF_HIDEDEFAULT').is(':checked')) hideDefault = 1;

  var perleg = 0;
  if ($('#BILLING_CF_PERLEG').is(':checked')) perleg = 1;

  var Bill_Item = 0;
  var Bill_Item_options = '';
  if ($('#BILLING_CF_BILLITEM').is(':checked')) {
    Bill_Item = 1;
    var Taxable = 0;
    if ($('#BILLITEM_OPTION_TAX').is(':checked')) Taxable = 1;
    var Multiple = parseFloat($('#BILLITEM_OPTION_MULTIPLE').val());
    if (isNaN(Multiple)) Multiple = 0;
    var Unit = $('#BILLITEM_OPTION_Qty_Unit').val();
    Bill_Item_options = '{"Taxable":' + Taxable + ', "Multiple":' + Multiple + ', "Unit": "' + Unit + '"}';
  }
  Bill_Item_options = Bill_Item_options;

  var defaultValue = '';
  var options = '[]';
  var placeholder = $('#BILLING_CF_PH').val();
  if (type == 'checkbox') {
    if ($('#BILLING_CF_CHK_OPTIONS1').is(':checked')) {
      defaultValue = 1;
    } else {
      defaultValue = 0;
    }
  }
  if (type == 'text') {
    defaultValue = $('#CF_TEXT_DEFAULT').val();
    var maxlength = $('#CF_NUM_MIN').val();
    if (maxlength < 1) {
      BillingValidation(iTrans('Max Length must be greater than 0'));
    }
    if (defaultValue.length > maxlength) {
      BillingValidation(iTrans('Default text cannot be greater than Max Length'));
      return;
    }
    options = JSON.stringify({
      maxlength: maxlength,
    });
  }
  if (type == 'number') {
    defaultValue = parseFloat($('#CF_NUM_DEFAULT').val());
    var maxValue = parseFloat($('#CF_NUM_MAX').val());
    var minValue = parseFloat($('#CF_NUM_MIN').val());
    if ($('#CF_NUM_DEFAULT').val() != '') {
      if (defaultValue > maxValue) {
        BillingValidation(iTrans('Default value cannot be greater than Max Value'));
        return;
      }
      if (defaultValue < minValue) {
        BillingValidation(iTrans('Default value cannot be less than Max Value'));
        return;
      }
    } else {
      defaultValue = '';
    }
    options = JSON.stringify({
      max: maxValue,
      min: minValue,
    });
  }
  if (type == 'select') {
    CF_ADD_OPTION(); // this should save a new option if the usere didn't hit had but entered values
    options = BILLING_CLIENT_CFIELD.options;
    if (options.length == 0) {
      BillingValidation(iTrans('There must be at least 1 option to choose from'));
      return;
    }
    options = JSON.stringify(options);
  }
  if (type == 'signature') {
    options = { SignatureDescription: $('#BILLING_CF_SIGNATURE_SignTXT').val() };
    if ($('#BILLING_CF_SIGNATURE_SignTXT').val().length == 0) {
      BillingValidation(iTrans('Signature Reason Description Cannot Be Blank!'));
      return;
    }
    options = JSON.stringify(options);
  }
  var ClientPK;
  var PrimaryKey;
  if (ADMIN_BILLING_FIELDS || BILLING_RETURN_TO == 'Passenger' || BILLING_RETURN_TO == 'Briefing') {
    ClientPK = 0;
    PrimaryKey = BILLING_CLIENT_CFIELD.PrimaryKey;
    if (BILLING_RETURN_TO == 'Briefing') {
      ClientPK = $('#BILLING_CF_CLIENTPK').val();
    }
  } else {
    ClientPK = BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey;
    PrimaryKey = BILLING_CLIENT_CFIELD.PrimaryKey;
  }
  //todo ClientPK = 0 if using same code for company wide custom fields
  $('#BILLING_SaveClientCF_BTN').prop('disabled', true);

  //Post Custom Field Data
  if (BILLING_RETURN_TO == 'Briefing') {
    var data = {
      type: type,
      FormPK: PAX_BRIEFING_FORM.PrimaryKey,
      req: reqItin,
      hideDefault: hideDefault,
      label: label,
      placeholder: placeholder,
      default: defaultValue,
      PerLeg: perleg,
      Bill_Item: Bill_Item,
      Bill_Item_options: Bill_Item_options,
      options: options,
      PrimaryKey: PrimaryKey,
      ClientPK: PAX_BRIEFING_FORM.ClientPK == 0 ? ClientPK : PAX_BRIEFING_FORM.ClientPK,
    };
    if (PAX_BRIEFING_FORM.Briefings > 0) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt:
          iTrans('This form has been used for') +
          ' ' +
          PAX_BRIEFING_FORM.Briefings +
          ' ' +
          iTrans('passenger briefings. What would you like to do with the existing briefing records?'),
        buttons: {
          [iTrans('Leave As Is')]: {
            click: function () {
              PAX_SYNC_REQUIRED = true;
              data.saveAction = 'nothing';

              PAX_SaveBriefingCfields(data);
            },
          },
          [iTrans('Mark as Invalid')]: {
            click: function () {
              PAX_SYNC_REQUIRED = true;
              data.saveAction = 'invalidate';
              PAX_BRIEFING_FORM.Briefings = 0; //so users don't see the prompt when adding addition cfields after this one
              PAX_SaveBriefingCfields(data);
            },
          },
        },
      });
    } else {
      data.saveAction = 'nothing';
      PAX_SaveBriefingCfields(data);
    }
  } else if (BILLING_RETURN_TO == 'Passenger') {
    if (usedFor == 'PaxMan'){
      usedFor = 'ALL';
    }
    $.mobile.loading('show', {
      theme: 'a',
    });
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-PaxManifesting-AJAX.php?action=editCustomField',

      data: {
        type: type,
        reqPax: req,
        reqMan: reqItin,
        usedFor: usedFor,
        hideDefault: hideDefault,
        label: label,
        placeholder: placeholder,
        default: defaultValue,
        PerLeg: perleg,
        Bill_Item: Bill_Item,
        Bill_Item_options: Bill_Item_options,
        options: options,
        PrimaryKey: PrimaryKey,
        ClientPK: ClientPK,
      },
    })
      .success(function (json_data) {
        $.mobile.loading('hide');
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Add Custom Field') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'BILLING_AdminApproveExpense');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          $('#BILLING_SaveClientCF_BTN').prop('disabled', false);

          PaxCfields();
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        AJAXFAIL(error);
        $('#BILLING_SaveClientCF_BTN').prop('disabled', false);
      });
  } else {
    $.mobile.loading('show', {
      theme: 'a',
    });
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Billing-AJAX.php?action=editCustomField&action2=post',
      data: {
        type: type,
        req: req,
        reqItin: reqItin,
        usedFor: usedFor,
        hideDefault: hideDefault,
        label: label,
        placeholder: placeholder,
        default: defaultValue,
        PerLeg: perleg,
        Bill_Item: Bill_Item,
        Bill_Item_options: Bill_Item_options,
        options: options,
        PrimaryKey: PrimaryKey,
        ClientPK: ClientPK,
      },
    })
      .success(function (json_data) {
        $.mobile.loading('hide');
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Add Custom Field') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'BILLING_AdminApproveExpense');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          BILLING_sync_data(); //to ensure Cfieds are ready for itin hopefully
          $('#BILLING_SaveClientCF_BTN').prop('disabled', false);
          if (ADMIN_BILLING_FIELDS) returnAdminBICF();
          else BILLING_ClientCFreturn('reload'); //trigger reload of clients new custom field
          ADMIN_BILLING_FIELDS = false;
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        AJAXFAIL(error);
        $('#BILLING_SaveClientCF_BTN').prop('disabled', false);
      });
  }
}
//global custom field function
function BILLING_SortSelectOptions(a, b) {
  a = a.value;
  b = b.value;
  var re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi,
    sre = /(^[ ]*|[ ]*$)/g,
    dre =
      /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
    hre = /^0x[0-9a-f]+$/i,
    ore = /^0/,
    // convert all to strings and trim()
    x = a.toString().replace(sre, '') || '',
    y = b.toString().replace(sre, '') || '',
    // chunk/tokenize
    xN = x.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0'),
    yN = y.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0'),
    // numeric, hex or date detection
    xD = parseInt(x.match(hre)) || (xN.length != 1 && x.match(dre) && Date.parse(x)),
    yD = parseInt(y.match(hre)) || (xD && y.match(dre) && Date.parse(y)) || null;
  // first try and sort Hex codes or Dates
  if (yD)
    if (xD < yD) return -1;
    else if (xD > yD) return 1;
  // natural sorting through split numeric strings and default strings
  for (var cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
    // find floats not starting with '0', string or 0 if not defined (Clint Priest)
    oFxNcL = (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
    oFyNcL = (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) return isNaN(oFxNcL) ? 1 : -1;
    // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
    else if (typeof oFxNcL !== typeof oFyNcL) {
      oFxNcL += '';
      oFyNcL += '';
    }
    if (oFxNcL < oFyNcL) return -1;
    if (oFxNcL > oFyNcL) return 1;
  }
  return 0;
}
function createCustomField(Leg, f, value = false, Itin = false) {
  //console.log(f);
  var fdefault = f.default;
  if (value !== false) fdefault = value;
  var html = '';
  var options = JSON.parse(f.options);
  var REQ = '';
  var REQ_IND = '';
  if ((f.req == 1 && Itin == false) || (f.reqItin == 1 && Itin == true)) {
    REQ = iTrans('Required');
    REQ_IND = '<b style="color:red;"> *</b>';
  }
  if (Leg === '') {
    //Per report items
    switch (f.type) {
      case 'text':
        html +=
          '<label data-testid="billing-flight-report-custom-field-' +
          f.Label +
          '" for="BI_CF_' +
          f.PrimaryKey +
          '" >' +
          f.Label +
          REQ_IND +
          '</label>';
        html +=
          '<input type="text" data-testid="billing-admin-flight-report-custom-field-' +
          f.Label +
          '" maxlength="' +
          options.maxlength +
          '" id="BI_CF_' +
          f.PrimaryKey +
          '" onChange="BillingValidateCF(this)" value="' +
          escapeHtml(fdefault) +
          '" placeholder="' +
          escapeHtml(f.Placeholder) +
          '" data-mini="true" name="' +
          REQ +
          '" />';
        break;
      case 'number':
        html += '<label for="BI_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        html +=
          '<input type="number" max="' +
          options.max +
          '" min="' +
          options.min +
          '" id="BI_CF_' +
          f.PrimaryKey +
          '" onChange="BillingValidateCF(this)" value="' +
          fdefault +
          '" data-mini="true" name="' +
          REQ +
          '" />';
        break;
      case 'checkbox':
        html += '<label for="BI_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        if (fdefault == 1)
          html += '<input type="checkbox" id="BI_CF_' + f.PrimaryKey + '" data-mini="true" name="" checked/>';
        else html += '<input type="checkbox" id="BI_CF_' + f.PrimaryKey + '" data-mini="true" name="" />';
        break;
      case 'select':
        html += '<label for="BI_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        html += '<select id="BI_CF_' + f.PrimaryKey + '" onChange="BillingValidateCF(this)" data-mini="true" name="" >';
        if (f.Placeholder != '')
          html += '<option value="" disabled selected hidden>' + escapeHtml(f.Placeholder) + '</option>';

        for (var i in options) {
          if (fdefault == options[i].value)
            html +=
              '<option value="' +
              escapeHtml(options[i].value) +
              '" selected>' +
              escapeHtml(options[i].text) +
              '</option>';
          else
            html += '<option value="' + escapeHtml(options[i].value) + '">' + escapeHtml(options[i].text) + '</option>';
        }
        html += '</select>';
        break;
      case 'signature':
        html += '<div style="display: inline-block;">';
        if (value == undefined) value = '';
        html += '<input type="hidden" id="BI_CF_' + f.PrimaryKey + '" value="' + escapeHtml(value) + '" />';
        html += '<label>' + f.Label + REQ_IND + '</label>';
        html += '<p class="wrap">' + options.SignatureDescription + '</p>';
        var SignatureHTML = '';
        if (value != '' && value != 'false') {
          value = JSON.parse(value);
          SignatureHTML +=
            '<div style="display: inline-block;"><img src="' + value.data + '" width="321px" height="150px"/><br />';
          SignatureHTML +=
            '<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>' +
            value.SignatureName +
            '</b></center></td><td><button onClick="BILLING_REMOVE_SIGNATURE(' +
            f.PrimaryKey +
            ', false);" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></td></tr></table></div>';
        } else {
          SignatureHTML =
            '<button class="yellowbg" style="width: 120px;" data-mini="true" id="BI_CF_SIGN_' +
            f.PrimaryKey +
            '" onClick="BILLING_CAPTURE_SIGNATURE(' +
            f.PrimaryKey +
            ', false);">' +
            iTrans('Sign Now') +
            '</button>';
        }
        html += '<div id="BI_CF_SIGN_' + f.PrimaryKey + '" >' + SignatureHTML + '</div>';
        html += '</div>';

        break;
    }
  } else {
    //per leg fields
    switch (f.type) {
      case 'text':
        html += '<label for="BI_CF' + Leg + '_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        html +=
          '<input type="text" maxlength="' +
          options.maxlength +
          '" id="BI_CF' +
          Leg +
          '_' +
          f.PrimaryKey +
          '" onChange="updateFlightLegData(' +
          Leg +
          ')" value="' +
          escapeHtml(fdefault) +
          '" placeholder="' +
          escapeHtml(f.Placeholder) +
          '" data-mini="true" name="' +
          REQ +
          '" />';
        break;
      case 'number':
        html += '<label for="BI_CF' + Leg + '_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        html +=
          '<input type="number" max="' +
          options.max +
          '" min="' +
          options.min +
          '" id="BI_CF' +
          Leg +
          '_' +
          f.PrimaryKey +
          '" onChange="updateFlightLegData(' +
          Leg +
          ')" value="' +
          fdefault +
          '" data-mini="true" name="' +
          REQ +
          '" />';
        break;
      case 'checkbox':
        html += '<label for="BI_CF' + Leg + '_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        if (fdefault == 1)
          html +=
            '<input type="checkbox" id="BI_CF' +
            Leg +
            '_' +
            f.PrimaryKey +
            '" data-mini="true" checked onClick="updateFlightLegData(' +
            Leg +
            ')" name="" />';
        else
          html +=
            '<input type="checkbox" id="BI_CF' +
            Leg +
            '_' +
            f.PrimaryKey +
            '" data-mini="true" onClick="updateFlightLegData(' +
            Leg +
            ')" name="" />';
        break;
      case 'select':
        html += '<label for="BI_CF' + Leg + '_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
        html +=
          '<select id="BI_CF' +
          Leg +
          '_' +
          f.PrimaryKey +
          '" onChange="updateFlightLegData(' +
          Leg +
          ')" data-mini="true" name="" >';
        if (f.Placeholder != '')
          html += '<option value="" disabled selected hidden>' + escapeHtml(f.Placeholder) + '</option>';

        for (var i in options) {
          if (fdefault == options[i].value)
            html +=
              '<option value="' +
              escapeHtml(options[i].value) +
              '" selected>' +
              escapeHtml(options[i].text) +
              '</option>';
          else
            html += '<option value="' + escapeHtml(options[i].value) + '">' + escapeHtml(options[i].text) + '</option>';
        }
        html += '</select>';
        break;
      case 'signature':
        html += '<div style="display: inline-block;">';
        if (value == undefined) value = '';
        html += '<input type="hidden" id="BI_CF' + Leg + '_' + f.PrimaryKey + '" value="' + escapeHtml(value) + '" />';
        html += '<label>' + f.Label + REQ_IND + '</label>';
        html += '<p class="wrap">' + options.SignatureDescription + '</p>';
        var SignatureHTML = '';
        if (value != '' && value != 'false') {
          value = JSON.parse(value);
          SignatureHTML +=
            '<div style="display: inline-block;"><img src="' + value.data + '" width="321px" height="150px"/><br />';
          SignatureHTML +=
            '<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>' +
            value.SignatureName +
            '</b></center></td><td><button onClick="BILLING_REMOVE_SIGNATURE(' +
            f.PrimaryKey +
            ', ' +
            Leg +
            ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></td></tr></table></div>';
        } else {
          SignatureHTML =
            '<button class="yellowbg" style="width: 120px;" data-mini="true" id="BI_CF_SIGN' +
            Leg +
            '_' +
            f.PrimaryKey +
            '" onClick="BILLING_CAPTURE_SIGNATURE(' +
            f.PrimaryKey +
            ', ' +
            Leg +
            ');">Sign Now</button>';
        }
        html += '<div id="BI_CF_SIGN' + Leg + '_' + f.PrimaryKey + '" >' + SignatureHTML + '</div>';
        html += '</div>';

        break;
    }
  }
  return html;
}

function BillingValidateCF(e) {
  var IDs = $('#BILLING_CUSTOM_FILEDS_FR [id]')
    .map(function () {
      return this.id;
    })
    .get();

  //check if in details or leg collection

  //loop through all custom fields and enable next button if values present in required fields otherwise disable
  if (CurPageID() == 'BILLING_FlightDescriptionPage') {
    if ('signature' in CURRENT_FLIGHT_REPORT) {
      if (CURRENT_FLIGHT_REPORT.signature != '') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt:
            iTrans('This flight report has already been signed!') +
            '<br />' +
            iTrans('Modifying it will remove the signature.') +
            '<br />' +
            iTrans('Are you Sure?'),
          buttons: {
            [iTrans('Continue')]: {
              click: function () {
                signatureDATA = {
                  data: '',
                  text: '',
                };
                CURRENT_FLIGHT_REPORT.signature = '';
                CURRENT_FLIGHT_REPORT.signed_by = '';
                CURRENT_FLIGHT_REPORT.Completed = 0;
              },
            },
            [iTrans('Cancel')]: {
              click: function () {
                BILLING_returnNewFlightReport();
              },
            },
          },
        });
      }
    }
  }
}

function BillingValidation(msg) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('ERROR'),
    headerClose: false,
    buttonPrompt: '<p>' + msg + '</p>',
    buttons: {
      [iTrans('Close')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function BILLINGloadfile() {
  var file = document.getElementById('BILLING_CLIENT_PDF').files[0];
  $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>Loading File</h2>');
  console.log(file);
  if (file != undefined) {
    $.mobile.loading('show', {
      theme: 'a',
    });
    var filename = file.name;
    var filesize = file.size;
    var file64;
    console.log(filesize);
    if (filesize > 13000000) {
      $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>' + iTrans('File too large!') + '</h2>');
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        file64 = btoa(e.target.result);
        //console.log(cert_base64File);
        BILLINGuploadfile(file64, filename, 0);
      };
      reader.onerror = function (e) {
        //reset file input
        $.mobile.loading('hide');
        console.log('Scheduler FileReader Error');
        $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>' + iTrans('Error Reading File!') + '</h2>');
      };
      if (file.type == 'application/pdf') reader.readAsBinaryString(file);
      else reader.readAsDataURL(file);
    }
  } else {
    //reset file input
    console.log('No File Selected');
    $('#BILLING_CLIENT_PDF_CURRENT').html(iTrans('No File Selected'));
  }
}

function BILLINGuploadfile(file64, filename, DeletePDF) {
  //#SCHfileprogress div for display upload progress
  var PrimaryKey = BILLING_CLIENTS[BILLING_CLIENT_INDEX].PrimaryKey;

  $.ajax({
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
            if (percentComplete == 100) $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>Processing..</h2>');
            else
              $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>' + iTrans('Uploading') + ': ' + percentComplete + '%</h2>');
          }
        },
        false
      );
      xhr.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            console.log('Received: ' + percentComplete);
          }
        },
        false
      );
      return xhr;
    },
    type: 'POST',
    url: BASEURL + 'menu-Billing-AJAX.php?action=AddClientPDF&PrimaryKey=' + PrimaryKey + '&DeletePDF=' + DeletePDF,
    data: {
      BILLINGfile: file64,
      BILLINGfilename: filename,
    },
    success: function (data) {
      data = jQuery.parseJSON(data);
      if (data[0].ServerResponse == 'Success' || data[0].ServerResponse == 'Nothing Changed') {
        console.log('Scheduler Attachment Successfully Updated');
        //$.mobile.changePage( $("#Schedule_Page"), {  reverse: true, changeHash: false });
        if (DeletePDF == 0) {
          $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>' + iTrans('Successfully Uploaded') + '</h2>');
          $('#BILLING_CLIENT_PDF').hide();
          $('#BILLING_RemovePDF_BTN').show();
          ShowClientPDF(file64);
        } else {
          $('#BILLING_CLIENT_PDF_CURRENT').html('');
          $('#BILLING_CLIENT_PDF').show();
          $('#BILLING_RemovePDF_BTN').hide();
        }
        $('#BILLING_CLIENT_PDF').val('');
        $('#BILLING_CLIENT_PDF_FILENAME').html(
          '<b>' +
            iTrans('Filename') +
            ': </b><a href="' +
            BASEURL +
            'menu-Billing-AJAX.php?action=getClientPDF&OPEN=1&PrimaryKey=' +
            PrimaryKey +
            '" target="_blank">' +
            filename +
            '</a>'
        );
      } else {
        //insert failed server error
        console.log('Update Failed: ' + data[0].ServerResponse);
        if (data[0].ServerResponse == 'Uncaught Exception') ServerResponse(data[0], 'SCHuploadfile');
        if (data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        $('#BILLING_CLIENT_PDF_CURRENT').html(
          '<h2>' + iTrans('Upload Failed') + ': ' + data[0].ServerResponse + '</h2>'
        );
      }
      $.mobile.loading('hide');
    },
    fail: function (jqXHR, status, error) {
      $.mobile.loading('hide');
      $('#BILLING_CLIENT_PDF_CURRENT').html('<h2>' + iTrans('Upload Failed') + '</h2>');
      AJAXFAIL(error);
    },
  });
}
function getClientPDF(PrimaryKey) {
  $.getJSON(
    BASEURL + 'menu-Billing-AJAX.php?action=getClientPDF&OPEN=0&PrimaryKey=' + PrimaryKey,
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        if (json_data[0].ServerResponse == 'Offline') console.log('Not Available');
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'SCH_PopClients');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Failed'); //Do Nothing
      } else {
        console.log('Received Resources');

        ShowClientPDF(json_data[0].PDF);
      }
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    console.log('Communication Error Retrieving events');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Failed!'),
      headerClose: false,
      buttonPrompt: iTrans(
        'There was a problem loading client PDF.  Please try again. If this continues to happen please submit a bug report'
      ),
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
        [iTrans('Report')]: {
          click: function () {
            //File bug report
          },
        },
      },
    });
  });
}
function ShowClientPDF(file) {
  var divID = 'BILLING_CLIENT_PDF_CURRENT';
  pdfjsLib.disableWorker = true; // due to CORS

  var loadingTask = pdfjsLib.getDocument('data:application/pdf;base64,' + file);
  LoadPDFPages(loadingTask, divID);
}
function BILLING_update_BILLBY_Opt(opt, setval) {
  var val = setval || '';
  switch (opt) {
    case 'Hour':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').show();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      break;
    case 'Hour2':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').show();
      $('#BILLBYOptionVolume').hide();
      break;
    case 'Minute':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      break;
    case 'Hobbs':
      $('#BILLBYOptionHobbs').show();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      if (val != '') {
        switch (val) {
          case 'Primary':
            $('#BILLBY_Hobbs_Primary').prop('checked', true).checkboxradio('refresh');
            $('#BILLBY_Hobbs_Secondary').prop('checked', false).checkboxradio('refresh');
            break;
          case 'Secondary':
            $('#BILLBY_Hobbs_Primary').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_Hobbs_Secondary').prop('checked', true).checkboxradio('refresh');
            break;
        }
      }
      break;
    case 'Distance':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').show();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      if (val != '') {
        switch (val) {
          case 'NM':
            $('#BILLBY_Distance_NM').prop('checked', true).checkboxradio('refresh');
            $('#BILLBY_Distance_SM').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_Distance_KM').prop('checked', false).checkboxradio('refresh');
            break;
          case 'SM':
            $('#BILLBY_Distance_NM').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_Distance_SM').prop('checked', true).checkboxradio('refresh');
            $('#BILLBY_Distance_KM').prop('checked', false).checkboxradio('refresh');
            break;
          case 'KM':
            $('#BILLBY_Distance_NM').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_Distance_SM').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_Distance_KM').prop('checked', true).checkboxradio('refresh');
            break;
        }
      }
      break;
    case 'Weight':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').show();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      if (val != '') {
        switch (val) {
          case 'LB':
            $('#BILLBY_Weight_LB').prop('checked', true).checkboxradio('refresh');
            $('#BILLBY_Weight_KG').prop('checked', false).checkboxradio('refresh');
            break;
          case 'KG':
            $('#BILLBY_Weight_LB').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_Weight_KG').prop('checked', true).checkboxradio('refresh');
            break;
        }
      }
      break;
    case 'HourPR':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      break;
    case 'HourPL':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').hide();
      break;
    case 'Volume':
      $('#BILLBYOptionHobbs').hide();
      $('#BILLBYOptionDistance').hide();
      $('#BillbyOptionHobbsWeight').hide();
      $('#BillbyOptionHour').hide();
      $('#BillbyOptionHour2').hide();
      $('#BILLBYOptionVolume').show();
      if (val != '') {
        switch (val) {
          case 'litres':
            $('#BILLBY_VolumeLitres').prop('checked', true).checkboxradio('refresh');
            $('#BILLBY_VolumeGallonsUS').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_VolumeGallonsImp').prop('checked', false).checkboxradio('refresh');
            break;
          case 'gallonsus':
            $('#BILLBY_VolumeLitres').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_VolumeGallonsUS').prop('checked', true).checkboxradio('refresh');
            $('#BILLBY_VolumeGallonsImp').prop('checked', false).checkboxradio('refresh');
            break;
          case 'gallonsimp':
            $('#BILLBY_VolumeLitres').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_VolumeGallonsUS').prop('checked', false).checkboxradio('refresh');
            $('#BILLBY_VolumeGallonsImp').prop('checked', true).checkboxradio('refresh');
            break;
        }
      }
      break;
  }
}

function BILLING_GetClientDataList(refreshPK = 0) {
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getClients', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'None') {
        $('#BI_ClientDataFeedHLDR').hide();
        localStorageDB.setItem('itinClients', '[]');
      }
      if (json_data[0].ServerResponse == 'Offline') {
        if (LOCALSTORAGE.itinClients == undefined) return;
        var json_data = LOCALSTORAGE.itinClients;
        $('#BI_ClientDataFeed').empty();
        $('#BI_ClientDataFeed').append($('<option />').val(0).text('Not Enabled'));
        for (var i in json_data) {
          if (json_data[i].Enabled == 1)
            $('#BI_ClientDataFeed').append($('<option />').val(json_data[i].ClientPK).text(json_data[i].Client));
        }
        if (refreshPK != 0) $('#BI_ClientDataFeed').val(refreshPK).selectmenu('refresh');
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getDisabled');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      //console.log("Received Client List");
      $('#BI_ClientDataFeedHLDR').show();
      localStorageDB.setItem('itinClients', JSON.stringify(json_data));
      $('#BI_ClientDataFeed').empty();
      $('#BI_ClientDataFeed').append($('<option />').val(0).text('Not Enabled'));
      for (var i in json_data) {
        if (json_data[i].Enabled == 1)
          $('#BI_ClientDataFeed').append($('<option />').val(json_data[i].ClientPK).text(json_data[i].Client));
      }
      if (refreshPK != 0) $('#BI_ClientDataFeed').val(refreshPK).selectmenu('refresh');
    }
    //popItinAssetTemplates();
  }).fail(function (jqXHR, status, error) {
    if (LOCALSTORAGE.itinClients == undefined) return;
    var json_data = LOCALSTORAGE.itinClients;
    $('#BI_ClientDataFeed').empty();
    $('#BI_ClientDataFeed').append($('<option />').val(0).text('Not Enabled'));
    for (var i in json_data) {
      if (json_data[i].Enabled == 1)
        $('#BI_ClientDataFeed').append($('<option />').val(json_data[i].ClientPK).text(json_data[i].Client));
    }
    if (refreshPK != 0) $('#BI_ClientDataFeed').val(refreshPK).selectmenu('refresh');
  });
}
