class vSAMA_CustomFieldEditor extends vSAMA_AbstractEditor {
  constructor(_customField) {
    super();

    this.customField = _customField;
    this.dom = {
      body: $(SAMA.Templates.tpl.customRepField.editorTab),
    };

    this.dom.delButton = this.dom.body.find('.sama-btn-delete');

    this.dom.delButton.on('click.samaevt', () => this.OnEditorDeleteButton());

    cSAMA_GeneralInputs.LinkInputs(this.customField, this.dom.body, this);
  }

  GetDom() {
    return this.dom.body;
  }

  /**
   * Called from the delete button. Confirms the intent and triggers destroy.
   * @constructor
   */
  OnEditorDeleteButton() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Archive Custom Field?',
      message:
        'Reporters will no longer be able to use the field ' +
        'but it will still show up in existing reports. <br />' +
        'Do you really want to archive it?',
      yesFunc: () => {
        this.customField.SetDeleted(true);
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        this.Destroy();
        this.UpdateObservers('DeletedCF');
      },
    });
  }

  Destroy() {
    this.dom.body.remove();
  }
}
