/**
 *
 */
class PilotsTable extends AbstractDatabaseTable {
  constructor() {
    super('Pilots', 'username');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            username         text primary key not null,
            chatname         text
        )
    `;
    //#endregion
  }

  /**
   *
   * @param whereClause
   * @param values
   * @param columns
   * @returns {Promise<false|any[]>}
   */
  async select(whereClause, values, columns) {
    return super.select(whereClause, values, columns, 'order by chatname collate nocase asc');
  }
}
