/**
 * Helper class for forms integration in bookings
 */
class FormsInBookings extends FormBridge {
  /**
   * @param sourcePage
   * @param htmlContainerId
   * @param forms
   * @param bookingId
   * @param editLevel
   * @private
   */
  constructor(sourcePage, editLevel, htmlContainerId, forms, bookingId) {
    super(sourcePage, editLevel, htmlContainerId, forms, bookingId, FormsInBookings.LINK_TYPE);

    this.picker.AddObserver(
      null,
      'linkFilled',
      this.wrapSaveCallback((formId) => this.linkFilledForm(formId))
    );
    this.picker.AddObserver(
      null,
      'linkNew',
      this.wrapSaveCallback((formId) => this.linkNewForm(formId))
    );
    this.picker.AddObserver(
      null,
      'viewItem',
      this.wrapSaveCallback((formId) => this.viewForm(formId))
    );
    this.picker.AddObserver(
      null,
      'editItem',
      this.wrapSaveCallback((formId) => this.editForm(formId))
    );

    this.picker.AddObserver(null, 'unlinkItem', (formId) => {
      if (!ONLINE) {
        SCH_IS_OFFLINE();
        return;
      }

      this.confirmUnlink(async () => {
        // Update the booking if a form was unlinked
        await this.unlinkForm(formId);
        SCHbookAssociated();
      });
    });
  }

  static handleReturnToAdmin() {
    verbose.log('Scheduler', 'Back to edit booking');
    SCHEditEvent(null, false, this.INSTANCE.preSwitchScrollPosition, SCHLASTSAVED);
  }

  static handleReturnToView() {
    verbose.log('Scheduler', 'Back to view booking');
    SCHViewEventDelayed(SCHEDITTHIS, this.INSTANCE.preSwitchScrollPosition);
  }

  /**
   * Adding some sugar to SCHSaveBooking, that's all
   * @return {Promise<unknown>}
   */
  async saveBooking() {
    return new Promise((resolve) => SCHSaveBooking(true, false, resolve));
  }

  /**
   * This function builds a callback for the observers to use depending on whether we're in admin mode or not
   * @param {function} wrappedCallback
   * @return {(function(*=): Promise<void>)|*|(function(*=): *)}
   */
  wrapSaveCallback(wrappedCallback) {
    if (this.editLevel === FormBridge.EL_ADMIN) {
      return async (formId) => {
        verbose.log('Scheduler', 'Saving event before switching to Vue.');
        await this.saveBooking();
        wrappedCallback(formId);
      };
    }

    return async (formId) => {
      verbose.log('Scheduler', 'Switching to Vue without saving event.');
      wrappedCallback(formId);
    };
  }
}

FormsInBookings.LINK_TYPE = 'bookings';
FormsInBookings.PAGE_EDITOR = 'bookingEditor';
FormsInBookings.PAGE_VIEWER = 'bookingViewer';
