class vSAMA_TabDepartment extends vSAMA_AbstractTab {
  constructor(_department, _staffSource, _readOnly) {
    super(_department);

    this.SetUpGui('<li><a data-oref="dep_name"></a></li>', SAMA.Templates.tpl.departments.editorTab, _readOnly);

    this.dom.body.enhanceWithin();

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    this.staffSource = _staffSource;
    this.dom.deleteMessage = this.dom.body.find('.sama-cannot-delete');
    this.dom.headTagger = this.dom.body.find('[data-sref="dep_managerPk"]');
    this.dom.subordTagger = this.dom.body.find('[data-sref="dep_personnel"]');
    this.dom.colorInput = this.dom.body.find('.jscolor');
    this.dom.colorRef = this.dom.colorRef = cSAMA_GeneralInputs.Color(this.dom.colorInput);
    this.initialStaffList = [];
    this.initialManager = {
      tagName: 'Nobody',
      tagID: -1,
    };

    this.dom.headTagger.catTagger(this.staffSource, {
      use: 'tags',
      forceColor: '#3a3e5b',
      ignoreCatCheck: true,
      cacheIDcheck: false,
      inputField: {
        maxTags: 1,
      },
      remote: {
        url: '',
      },
      messages: {
        category: 'department',
        tag: 'manager',
      },
    });

    this.dom.subordTagger.catTagger(this.staffSource, {
      use: 'tags',
      forceColor: '#3a3e5b',
      ignoreCatCheck: true,
      cacheIDcheck: false,
      showAllButton: true,
      inputField: {
        maxTags: 100,
      },
      remote: {
        url: '',
      },
      messages: {
        category: 'department',
        tag: 'employee',
      },
    });

    this.dom.headTagger.catTagger('setBuddies', [this.dom.subordTagger]);
    this.dom.subordTagger.catTagger('setBuddies', [this.dom.headTagger]);

    if (this.model.sync.dep_locked) {
      this.dom.deleteButton.prop('disabled', true).off();
    }

    this.dom.colorInput.on('change', () => {
      this.model.sync.dep_color = this.dom.colorRef.toHEXString();
      this.model.SetModified(true);
      this.OnColorChange();
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    });

    this.OnColorChange();
    this.ResetDeletedStaff();
  }

  GetPk() {
    return this.model.GetPk();
  }

  /**
   * Get the staff currently assigned to this department
   * @returns {Array}
   */
  GetCurrentStaff() {
    let currentStaff = [];
    let mgr = this.dom.headTagger.catTagger('getSelectedTags')[0];
    if (mgr.length <= 0) {
      currentStaff[0] = null;
    } else {
      currentStaff[0] = mgr[0];
    }

    currentStaff = currentStaff.concat(this.dom.subordTagger.catTagger('getSelectedTags')[0]);

    return currentStaff;
  }

  /**
   * Gets the staff that was deleted since the last save or page load.
   * @returns {Array}
   */
  GetDeletedStaff() {
    if (this.model.sync.deleted) {
      // If the Deparment was nuked, we need to reassign all its users.
      return this.initialStaffList;
    }

    let deletedUsers = [];

    let currentManager = this.dom.headTagger.catTagger('getSelectedTags')[0];
    let currentStaff = this.dom.subordTagger.catTagger('getSelectedTags')[0];

    for (let i = 0; i < this.initialStaffList.length; i++) {
      let haveUser = false;
      for (let c = 0; c < currentStaff.length; c++) {
        if (currentStaff[c].tagID === +this.initialStaffList[i].tagID) {
          haveUser = true;
          break;
        }
      }

      if (!haveUser) {
        this.initialStaffList[i].catID = +this.model.sync.dep_primaryKey;
        deletedUsers.push(this.initialStaffList[i]);
      }
    }

    if (currentManager.length > 0) {
      // There is a manager. Check if its the same as before and if the previous one is valid
      if (currentManager[0].tagID !== +this.initialManager.tagID && +this.initialManager.tagID > -1) {
        this.initialManager.catID = this.model.sync.dep_primaryKey;
        this.initialManager.newGuy = currentManager[0];
        deletedUsers.push(this.initialManager);
      }
    } else if (this.initialManager.tagID > -1) {
      // There's no manager, but there used to be one
      this.initialManager.catID = this.model.sync.dep_primaryKey;
      deletedUsers.push(this.initialManager);
    }

    return deletedUsers;
  }

  SetAllowDelete(_toggle) {
    if (!this.model.sync.dep_locked) {
      this.dom.deleteButton.prop('disabled', !_toggle);

      if (_toggle) {
        this.dom.deleteMessage.slideUp();
      } else {
        this.dom.deleteMessage.slideDown();
      }
    }
  }

  /**
   * Sets the currently selected staff as the initial staff
   */
  ResetDeletedStaff() {
    if (this.model.sync.deleted) {
      return;
    }

    let currentManager = this.dom.headTagger.catTagger('getSelectedTags')[0];
    this.initialStaffList = this.dom.subordTagger.catTagger('getSelectedTags')[0];

    if (currentManager.length > 0) {
      // Set init manager to current manager.
      this.initialManager = currentManager[0];
    } else if (this.initialManager.tagID > -1) {
      // Set init manager to no manager.
      this.initialManager = {
        tagName: 'Nobody',
        tagID: -1,
        catID: this.model.sync.dep_primaryKey,
      };
    }
  }

  OnColorChange() {
    let colString = 'color: % !important'.tr(this.model.sync.dep_color);
    this.dom.rider.find('a').attr('style', colString);
  }

  OnDelete() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Delete Department',
      message:
        'If you delete this department, the users assigned to it will be unassigned. ' +
        'It will be impossible to assign the department to new reports and existing reports ' +
        'must be reassigned in the next step. Do you want to continue?',
      yesFunc: () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        super.OnDelete();

        this.UpdateObservers('DepartmentDeleted', this.model);
      },
    });
  }
}
