class coSAMA_CommonReports {
  /**
   * Used to tell the server about a minimal change made to the active report
   * @param {string}      _p.operand     The operation, i.e. submitted, approved, rejected, deleted.
   * @param {object}      _p.data
   * @param {boolean}     [_p.backAfter] Whether to go back after the transmission was successful (default is true)
   * @param {function}    [_p.fn]        Function to run on success
   */
  static NotifyReportMinimal(_p) {
    _p.backAfter = _p.backAfter == null ? true : _p.backAfter;

    let r = new cSAMA_Request('Reports', 'minimalOp');
    r.Set('operand', _p.operand);
    r.Set('data', _p.data);

    cSAMA_Request.Dispatch({
      name: 'RepMinOp&o=' + _p.operand,
      reqs: r,
      clientErrorCode: 'SAMA 76 3C 3C 40',
      goBackOnError: false,
      fnSuccess: () => {
        cSAMA_Dialog.ShowDialog({
          title: 'Success!',
          message: 'The report was % successfully.'.tr(_p.operand),
          closeFn: () => {
            if (_p.fn != null) {
              _p.fn();
            }

            if (_p.backAfter) {
              SAMA.Navigator.GoBack();
            }
          },
        });
      },
    });
  }

  /**
   * Gets a delete-button for use in the header
   */
  static ButtonDelete(_pk) {
    let btnDel = new eSAMA_Button({
      label: 'Delete',
      icon: 'fa-times-circle',
      classes: 'adminbg',
      showStates: [cSAMA_GeneralGui.StateReady, cSAMA_GeneralGui.StateDirty],
    });

    btnDel.GetDom().on('click', () => coSAMA_CommonReports.ConfirmDelete(_pk));

    return btnDel;
  }

  /**
   * Gets a print-button for use in the header
   */
  static ButtonGetPdf(report) {
    let btnExp = new eSAMA_Button({
      label: 'Get PDF',
      icon: 'fa-file-pdf',
      showStates: [cSAMA_GeneralGui.StateReady],
    });

    btnExp.GetDom().on('click', async () => {
      btnExp.Loading(true);
      await coSAMA_CommonReports.ConfirmPdfSettings(report);
      btnExp.Loading(false);
    });

    return btnExp;
  }

  static async ConfirmPdfSettings(report) {
    return new Promise((resolve) => {
      cSAMA_Dialog.ShowConfirm({
        title: 'Export Report',
        message: 'How would you like this report exported?',
        buttons: {
          'With Images': {
            click: () => this.GetPDF(true, report, resolve),
          },
          'Without Images': {
            click: () => this.GetPDF(false, report, resolve),
          },
          Cancel: {
            icon: 'delete',
            click: () => resolve(),
          },
        },
      });
    });
  }

  static async GetPDF(_withPhotos, report, finishCb) {
    let sourceUrl = cSAMA_Utils.GetAjaxPage();

    let params = {
      do: 'download',
      action: 'collate_report',
      id: report.GetPk(),
      photos: _withPhotos ? 1 : 0,
    };

    const fileName = await vueApp.utilities.file.getTimestampedFileName(report.sync.rep_summary, 'report.pdf');

    await vueApp.utilities.download
      .fromUrl(sourceUrl)
      .setUrlParams(params)
      .setMimeType('application/pdf')
      .toSaveAs(null, fileName);

    finishCb();
  }

  static ConfirmDelete(_pk) {
    cSAMA_Dialog.ShowTextBox({
      title: 'Delete Report',
      message: 'Please enter a reason. The reporter and all assigned staff will be notified.',
      yesFunc: (_comment) => {
        coSAMA_CommonReports.NotifyReportMinimal({
          operand: 'deleted',
          data: {
            reportPk: _pk,
            comment: _comment,
          },
        });
      },
    });
  }
}
