/**
 *
 */
class FdtRuleTable extends AbstractDatabaseTable {
  constructor() {
    super('FDT_Rule', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey integer primary key , 
            Method text, 
            Name text, 
            RuleItem numeric, 
            "group" text
        )
    `;
    //#endregion
  }
}
