/**
 *
 */
class CanadianFacilityInfoTable extends AbstractDatabaseTable {
  constructor() {
    super('pdf', 'name');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('CA_PDF.mbtiles');
  }

  tableCreationQuery() {
    //#region return ...
    return 'select true;';
    //#endregion
  }
}
