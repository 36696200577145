/**
 * Page class for listing corrective actions
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_CorrectiveActionsList extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_CorrectiveActionsList', SAMA.pages.MenuHome);

    this.dom.coaList = this.dom.page.find('#SamaCoaList');
    this.dom.coaFilter = this.dom.page.find('#SamaCoaFilter');
    this.dom.dataSel = this.dom.page.find('input[name="coadata"]');

    this.dom.reportPicker = this.dom.page.find('#SamaCoaReportPicker');
    this.dom.reportInput = this.dom.reportPicker.find('input');

    this.dom.datePicker = this.dom.page.find('#SamaCoaDatePicker');
    this.dom.dateFrom = this.dom.datePicker.find('input[name="dateFrom"]');
    this.dom.dateTo = this.dom.datePicker.find('input[name="dateTo"]');
    this.dom.dateType = this.dom.datePicker.find('select');
    this.dom.dateButton = this.dom.datePicker.find('button');

    this.dom.btnGetByReport = this.dom.reportPicker.find('button');

    this.header = new cSAMA_PageHeader('Corrective Actions', this.dom.page);

    this.viewList = new vSAMA_ListView('No corrective actions found.');
    this.viewList.GetDom().appendTo(this.dom.coaList);

    this.filter = new cSAMA_FilterCorrectiveActions(this.viewList);
    this.filter.GetDom().appendTo(this.dom.coaFilter);

    this.dom.dataSel.on('change', () => this.ChangeDataSelection());
    this.lastDataSel = -1;

    cSAMA_GeneralInputs.Reports(this.dom.reportInput);
    this.dom.btnGetByReport.on('click', () => this.LoadByReport());
    this.dom.reportInput.on('input', () => this.ValidateReport());

    let start = moment().subtract(15, 'd'); // half a month earlier
    let month = moment().add(15, 'd'); // half a month later

    this.dom.dateFrom.val(start.format(SAMA.defaults.DateInputFormat));
    this.dom.dateTo.val(month.format(SAMA.defaults.DateInputFormat));

    cSAMA_GeneralInputs.Date(this.dom.dateTo, true, true);
    cSAMA_GeneralInputs.Date(this.dom.dateFrom, true, true);

    this.dom.datePicker.find('input').on('input', () => this.ValidateDate());
    this.dom.dateButton.on('click', () => this.LoadByDate());

    this.ValidateDate();
    this.ValidateReport();
  }

  ChangeDataSelection() {
    let dataSel = +this.dom.dataSel.filter(':checked').val();

    switch (dataSel) {
      case 1:
      case 2:
        if (this.lastDataSel === dataSel) {
          break;
        }
        this.LoadCorrectiveActions(dataSel);
        break;
      case 3:
        // Specific Report
        this.dom.datePicker.stop(true, true).slideUp(150);
        this.dom.reportPicker.stop(true, true).slideDown(150);
        return;
      case 4:
        // Date
        this.dom.reportPicker.stop(true, true).slideUp(150);
        this.dom.datePicker.stop(true, true).slideDown(150);
        return;
    }

    this.dom.datePicker.stop(true, true).slideUp(150);
    this.dom.reportPicker.stop(true, true).slideUp(150);
  }

  LoadCorrectiveActions(_dataSel, _data) {
    let r = new cSAMA_Request('CorrectiveActions', 'getList');
    r.Set('type', _dataSel);

    if (_data != null) {
      r.Set('data', _data);
    }

    cSAMA_Request.Dispatch({
      name: 'FetchCorrectiveActions',
      reqs: r,
      clientErrorCode: 'SAMA 34 11 XD LO',
      fnSuccess: (_r) => {
        this.lastData = _data;
        this.lastDataSel = _dataSel;
        this.ReplaceItems(_r);
      },
      goBackOnError: false,
    });
  }

  ValidateDate() {
    // Get date inputs, validate, check if this date range === old daterange, call loadCorrectiveActions
    let dateFrom = this.dom.dateFrom.val();
    let dateTo = this.dom.dateTo.val();
    let issue = '';

    // Validate dates
    let mFrom = moment(dateFrom, 'YYYY-MM-DD');
    let mTo = moment(dateTo, 'YYYY-MM-DD');
    mTo.hour(23).minute(59).second(59);

    if (dateFrom.length <= 0 || !mFrom.isValid()) {
      issue = 'The start date is invalid';
    }

    if (dateTo.length <= 0 || !mTo.isValid()) {
      issue = issue.length > 0 ? 'Both dates are invalid.' : 'The end date is invalid.';
    }

    if (mTo.isValid() && mFrom.isValid() && mTo.isBefore(mFrom)) {
      issue = 'End date is before start date.';
    }

    if (issue.length > 0) {
      this.dom.dateButton.html(' <i class="fal fa-ban"></i> ' + issue).addClass('sama-form-error');
      return false;
    }

    this.dom.dateButton.html('<i class="fal fa-search"></i> Get Corrective Actions').removeClass('sama-form-error');
    return true;
  }

  LoadByDate() {
    if (!this.ValidateDate()) {
      return;
    }

    let nowData = {
      dateFrom: moment(this.dom.dateFrom.val()).format('YYYY-MM-DD'),
      dateTo: moment(this.dom.dateTo.val()).format('YYYY-MM-DD'),
      dateType: this.dom.dateType.val(),
    };

    if (
      this.lastData != null &&
      this.lastData.hasOwnProperty('dateFrom') &&
      this.lastData.hasOwnProperty('dateTo') &&
      this.lastData.hasOwnProperty('dateType')
    ) {
      if (
        nowData.dateFrom === this.lastData.dateFrom &&
        nowData.dateTo === this.lastData.dateTo &&
        nowData.dateType === this.lastData.dateType
      ) {
        // no need to repeat the same call
        return;
      }
    }

    this.LoadCorrectiveActions(4, nowData);
  }

  ValidateReport() {
    let report = this.dom.reportInput.catTagger('getSelectedTags')[0];
    let issue = '';

    if (report.length <= 0) {
      issue = 'Please pick a report.';
    }

    if (issue.length > 0) {
      this.dom.btnGetByReport.html(' <i class="fal fa-ban"></i> ' + issue).addClass('sama-form-error');
      return false;
    }

    this.dom.btnGetByReport.html('<i class="fal fa-search"></i> Get Corrective Actions').removeClass('sama-form-error');
    return true;
  }

  LoadByReport() {
    if (!this.ValidateReport()) {
      return;
    }

    let data = {
      reportPk: this.dom.reportInput.catTagger('getSelectedTags')[0][0].tagID,
    };

    if (this.lastData != null && this.lastData.hasOwnProperty('reportPk')) {
      if (data.reportPk === this.lastData.reportPk) {
        // no need to repeat the same call
        return;
      }
    }

    this.LoadCorrectiveActions(3, data);
  }

  ReplaceItems(_response) {
    this.viewList.Clear();

    if (_response.length <= 0) {
      return;
    }

    _response = _response[0];
    for (let d = 0; d < _response.length; d++) {
      let coa = new mSAMA_CorrectiveAction(_response[d]);
      let liViIt = new vSAMA_ListItemCorrectiveAction(coa, _response[d].mtx_boundsHigh, _response[d].mtx_boundsMedium);
      this.Observe(liViIt, 'ItemChanged', () => this.ReloadItems());

      this.viewList.AddItem(liViIt);
    }

    this.filter.RunFilters();
  }

  ReloadItems() {
    if (this.lastDataSel > 2) {
      this.LoadCorrectiveActions(this.lastDataSel, this.lastData);
      return;
    }

    this.LoadCorrectiveActions(this.lastDataSel);
  }

  /**
   * Function called when the user enters the page.
   * @override
   */
  EnterPage() {
    this.ChangeDataSelection();
    SAMA.Navigator.FinishedLoading(this.header);
  }

  /**
   * Function to call when the user leaves the page.
   * @override
   */
  LeavePage() {
    this.lastData = null;
    this.lastDataSel = -1;
    this.viewList.Clear();
    super.LeavePage();
  }
}
