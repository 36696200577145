const SAMA_TASKSTATES = {
  Stub: 'stub',
  Progress: 'progress',
  Complete: 'complete',
  Archived: 'archived',
};

class mSAMA_PolicyObjectiveTask extends mSAMA_AbstractModel {
  /**
   * @param {mSAMA_PolicyObjective}  _objective  The owning objective
   * @param {object}            [_databaseData]
   */
  constructor(_objective, _databaseData) {
    super('pot_primaryKey');
    this.objective = _objective;

    this.sync = {
      pot_primaryKey: null,
      pot_groupPk: null,
      pot_desc: '',
      pot_state: SAMA_TASKSTATES.Progress,
      pot_completed: false,
      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _databaseData);

    if (this.IsNew()) {
      this.SetPk(cSAMA_Utils.GetUID());
    }

    this.sync.pot_completed = this.sync.pot_state === SAMA_TASKSTATES.Complete;
  }

  /**
   * Changes the modified state
   * @param {boolean} _state
   * @override
   */
  SetModified(_state) {
    this.sync.modified = _state;
    this.objective.SetModified(_state);
  }

  SetDeleted(_state) {
    this.SetModified(true);
    this.sync.deleted = _state;
    this.sync.pot_state = _state ? SAMA_TASKSTATES.Archived : SAMA_TASKSTATES.Progress;
  }

  GetTransmissible() {
    if (this.sync.deleted) {
      return this.GetFullTransmissible();
    }

    if (this.sync.pot_completed) {
      this.sync.pot_state = SAMA_TASKSTATES.Complete;
    } else {
      this.sync.pot_state = SAMA_TASKSTATES.Progress;
    }

    return this.GetFullTransmissible();
  }
}
