class mSAMA_RiskFactor extends mSAMA_AbstractModel {
  constructor(_riskMatrix, _databaseData) {
    super('mrf_primaryKey');

    this.customFields = [];
    this.riskMatrix = _riskMatrix;

    this.sync = {
      mrf_primaryKey: null,
      mrf_name: null,
      mrf_question: null,
      mrf_weight: 100,

      mrf_nameHighest: null,
      mrf_nameHigh: null,
      mrf_nameMedium: null,
      mrf_nameLow: null,
      mrf_nameNone: null,

      mrf_descHighest: null,
      mrf_descHigh: null,
      mrf_descMedium: null,
      mrf_descLow: null,
      mrf_descNone: null,

      mrf_archivedDate: null,

      mrf_riskThreshold: 1,

      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _databaseData);

    let titleValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 3,
      maxLength: SAMA.enums.TEXTLENGTHS.Tiny,
    });

    let descriptionValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 5,
      maxLength: SAMA.enums.TEXTLENGTHS.Small,
    });

    this.validateSettings = {
      mrf_name: titleValidator,
      mrf_question: descriptionValidator,

      mrf_nameHighest: titleValidator,
      mrf_nameHigh: titleValidator,
      mrf_nameMedium: titleValidator,
      mrf_nameLow: titleValidator,
      mrf_nameNone: titleValidator,

      mrf_descHighest: descriptionValidator,
      mrf_descHigh: descriptionValidator,
      mrf_descMedium: descriptionValidator,
      mrf_descLow: descriptionValidator,
      mrf_descNone: descriptionValidator,
    };

    if (this.IsNew()) {
      let num = this.riskMatrix.GetRiskFactors().length + 1;
      this.sync.mrf_name = 'Nameless Risk Factor #' + num;

      // Is new factor
      this.SetPk(cSAMA_Utils.GetUID());
      this.SetModified(true);
    }
  }

  /**
   *
   * @param {mSAMA_CustomField} _field
   */
  AddCustomField(_field) {
    this.customFields.push(_field);
  }

  /**
   * Returns the object as a transmittable object
   * @return {object|null}
   * @override
   */
  GetTransmissible() {
    let mod = this.IsModified();
    let loc = this.IsNew() && this.IsDeleted();

    if (!mod || loc) {
      return null;
    }

    let r = {
      riskFactor: this.sync,
      customFields: [],
    };

    for (let cf = 0; cf < this.customFields.length; cf++) {
      let t = this.customFields[cf].GetTransmissible();

      if (t !== null) {
        r.customFields.push(t);
      }
    }

    return r;
  }

  /**
   * Returns the object as a transmittable object ignoring the modified flag
   * But respecting the local flag
   * @returns {object|null}
   * @override
   */
  GetFullTransmissible() {
    let loc = this.IsNew() && this.IsDeleted();

    if (loc) {
      // No purely local objects
      return null;
    }

    let r = {
      riskFactor: this.sync,
      customFields: [],
    };

    for (let cf = 0; cf < this.customFields.length; cf++) {
      let t = this.customFields[cf].GetFullTransmissible();

      if (t != null) {
        r.customFields.push(t);
      }
    }

    return r;
  }

  /**
   * Changes the modified state
   * @param {boolean} _state
   * @override
   */
  SetModified(_state) {
    this.sync.modified = _state;
    this.riskMatrix.SetModified(_state);
  }

  /**
   * Get the custom fields associated with this risk factor
   * @returns {mSAMA_CustomField[]}
   */
  GetCustomFields() {
    return this.customFields;
  }

  /**
   * Gets the risk name (high med low..etc) from their corresponding integer value
   * @param {int} _riskValue
   * @returns {string|null}
   */
  GetRiskName(_riskValue) {
    switch (+_riskValue) {
      case 1:
        return this.sync.mrf_nameNone;
      case 2:
        return this.sync.mrf_nameLow;
      case 3:
        return this.sync.mrf_nameMedium;
      case 4:
        return this.sync.mrf_nameHigh;
      case 5:
        return this.sync.mrf_nameHighest;
      default:
        throw 'Not a valid risk value: ' + _riskValue;
    }
  }

  /**
   * Gets the risk description from their corresponding integer value
   * @param {int} _riskValue
   * @returns {string|null}
   */
  GetRiskDesc(_riskValue) {
    switch (+_riskValue) {
      case 1:
        return this.sync.mrf_descNone;
      case 2:
        return this.sync.mrf_descLow;
      case 3:
        return this.sync.mrf_descMedium;
      case 4:
        return this.sync.mrf_descHigh;
      case 5:
        return this.sync.mrf_descHighest;
      default:
        throw 'Not a valid risk value: ' + _riskValue;
    }
  }
}
