/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

var FC_data;
var remote_FC_Update = [];
var remote_FC_Send = false;

var FC_FORCE_RESYNC = false; //falg to be used in menu-Mapb-DB to trigger back to back FC syncs if another fuel cache is updated while syncronizing was still in progress
function syncFC() {
  FC_FORCE_RESYNC = false;
  FC_SYNCING = true;
  IOS_postMessage({
    Message: 'FCtext',
    text: 'Downloading',
  });
  //Open sqlite db
  Send_FC_History_Data();
}

function Send_FC_History_Data() {
  cirroDB.query('FuelCachesHistoryV2', 'PrimaryKey != ?', 0, function (e) {
    if (e == false) e = [];
    if (e.length == 0) getFCdataIOS();
    else {
      IOS_postMessage({
        Message: 'FCtext',
        text: 'Sending History',
      });
      $.post(
        BASEURL + 'sync-AJAX.php?action=fcUpdateHistoryV2',
        {
          history: e,
        },
        function (data) {
          //console.log(data);
          if (data[0].ServerResponse == 'Success') {
            IOS_postMessage({
              Message: 'FCtext',
              text: 'Downloading',
            });
            getFCdataIOS();
            cirroDB.clearAll('FuelCachesHistoryV2', null);
          } else {
            FC_SyncError = true;
            getFCdataIOS();
          }
        },
        'json'
      ).fail(function (jqXHR, status, error) {
        //alert("Failed Remote update Request!");
        FC_SyncError = true;
        getFCdataIOS();
      });
    }
  });
}

var FC_SyncError = false;
function getFCdataIOS() {
  console.log('Getting FC Data');
  FC_SyncError = false;

  $.getJSON(BASEURL + 'sync-AJAX.php?action=fcDataV2', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      //offline sync complete
      FC_SyncError = true;
      FC_SYNCING = false;
      SYNC_STATUS('FC');
    } else {
      if (json_data[0].ServerResponse != 'Uncaught Exception') {
        FC_data = json_data;
        console.log('Received FC Data');
        if (json_data[0].ServerResponse == 'No Fuel Caches in Database') {
          IOS_postMessage({
            Message: 'FCtext',
            text: 'Complete',
          });
          FC_SYNCING = false;
          SYNC_STATUS('FC');
          if (!FC_FORCE_RESYNC) update_Sync('LastFCsync', LastFCsync);
        } else compare_FCIOS(FC_data);
      } else {
        IOS_postMessage({
          Message: 'FCRemoteConFail',
        });
        FC_SyncError = true;
        FC_SYNCING = false;
        SYNC_STATUS('FC');
      }
    }
  }).fail(function (jqXHR, status, error) {
    FC_SyncError = true;
    FC_SYNCING = false;
    SYNC_STATUS('FC');
    IOS_postMessage({
      Message: 'FCRemoteConFail',
    });
  });
}

function compare_FCIOS(FC_data) {
  IOS_postMessage({
    Message: 'FCtext',
    text: 'Comparing',
  });
  remote_FC_Update = [];
  remote_FC_Send = false;
  var localFCdata = {};
  cirroDB.query('FuelCachesV2', 'PrimaryKey != ? ORDER BY location', 0, function (e) {
    if (e === false) {
      localFCdata = [];
    } else {
      for (var i in e) {
        var I = e[i];
        I.PrimaryKey = parseInt(I.PrimaryKey); // for iOS fix
        localFCdata[I.PrimaryKey] = I;
      }
    }
    for (var i in FC_data) {
      var I = FC_data[i];
      if (I.DELETED == 1 && localFCdata[I.PrimaryKey] != undefined) {
        deleteFC_Record(I);
      } else {
        if (localFCdata[I.PrimaryKey] != undefined) {
          var datetime = stringToDateObject(localFCdata[I.PrimaryKey].datetime);
          var FC_rec_datetime = stringToDateObject(I.datetime);
          if (datetime.getTime() > FC_rec_datetime.getTime()) {
            //local fuel data is newer upate remote record
            remote_FC_Send = true; //set update flag
            remote_FC_Update.push(localFCdata[I.PrimaryKey]);
          }
          if (datetime.getTime() < FC_rec_datetime.getTime()) {
            //remote fuel data is newer
            updateFC_Record(I, localFCdata[I.PrimaryKey]);
          }
        } else {
          if (I.DELETED == 0) {
            updateFC_Record(I, { ImageDateTime: '1900-01-01 00:00:00' });
          }
        }
      }
    }
    setTimeout(function () {
      if (FC_SyncError == false && !FC_FORCE_RESYNC) update_Sync('LastFCsync', LastFCsync);
      if (remote_FC_Send) remote_updateFC();
      else {
        FC_SYNCING = false;
        if (FC_SyncError == false && !FC_FORCE_RESYNC) update_Sync('LastFCsync', LastFCsync);
        if (FC_SyncError == false && FC_FORCE_RESYNC) syncFC();
        else SYNC_STATUS('FC');
        IOS_postMessage({
          Message: 'FC_Complete',
        });
        IOS_postMessage({
          Message: 'FCtext',
          text: 'Complete',
        });
      }
    }, 1000);
  });
}

function deleteFC_Record(FC_rec) {
  cirroDB.Delete(
    'FuelCachesV2',
    'PrimaryKey = ? or PrimaryKey = ?',
    [FC_rec.PrimaryKey, FC_rec.PrimaryKey + '.0'],
    function (e) {
      IOS_postMessage({
        Message: 'FCtext',
        text: 'Deleting: ' + FC_rec.location,
      });
    }
  );
}
function updateFC_Record(FC_rec, item) {
  if (FC_rec.ImageDateTime != '1980-01-01 00:00:00' && FC_rec.ImageDateTime != item.ImageDateTime) {
    //Get Fuel Cache Image Data
    $.getJSON(BASEURL + 'sync-AJAX.php?action=fcDataV2_ImageData&FCPK=' + FC_rec.PrimaryKey, function (json_data) {
      if (json_data[0].ServerResponse == 'Success') {
        FC_rec = json_data[0].data;
      } else {
        FC_SyncError = true;
      }
      cirroDB.insert('FuelCachesV2', FC_rec, null, function (e) {
        if (FC_SYNCING) {
          IOS_postMessage({
            Message: 'FCtext',
            text: 'Updating: ' + FC_rec.location,
          });
        }
      });
    }).fail(function (jqXHR, status, error) {
      FC_SyncError = true;
      cirroDB.insert('FuelCachesV2', FC_rec, null, function (e) {
        if (FC_SYNCING) {
          IOS_postMessage({
            Message: 'FCtext',
            text: 'Updating: ' + FC_rec.location,
          });
        }
      });
    });
  } else {
    cirroDB.insert('FuelCachesV2', FC_rec, null, function (e) {
      if (FC_SYNCING) {
        IOS_postMessage({
          Message: 'FCtext',
          text: 'Updating: ' + FC_rec.location,
        });
      }
    });
  }
}

function remote_updateFC() {
  IOS_postMessage({
    Message: 'FCtext',
    text: 'Sending Data',
  });
  var updateString = JSON.stringify(remote_FC_Update);
  console.log('Updating: ' + remote_FC_Update.length + ' FC Remote Records');
  var updateString64 = btoa(updateString);
  //send data from remote_FC_Update array to sync-AJAX.php?action=fcUpdate
  //confirmation of data received with new LocalFCsync datetime else update with original received from server

  $.post(
    BASEURL + 'sync-AJAX.php?action=fcUpdateV2',
    {
      update: updateString64,
    },
    function (data) {
      //console.log(data);
      if (data[0].ServerResponse != 'Success' || data[0].ServerResponse == 'Offline') {
        console.log('Remote update Failed on Server Side: ' + data[0].ServerResponse);
        FC_SyncError = true;
        //SYNC_STATUS("FC");
        IOS_postMessage({
          Message: 'FCtext',
          text: 'Complete: Send Failed',
        });
        FC_SYNCING = false;
        FC_SyncError = true;
        SYNC_STATUS('FC');
        //Update local sync database with incremented sync attempt
        IOS_postMessage({
          Message: 'FCSyncFail',
        });
      }
      if (data[0].ServerResponse == 'Success') {
        FCsync = new Date(data[0].LastFCsync);
        console.log('Remote FC update completed Successfully');
        //Update loacal sync database with new FDT sync datetime

        FC_SYNCING = false;

        if (FC_SyncError == false && !FC_FORCE_RESYNC) update_Sync('LastFCsync', FCsync);
        if (FC_SyncError == false && FC_FORCE_RESYNC) syncFC();
        else SYNC_STATUS('FC');
        IOS_postMessage({
          Message: 'FC_Complete',
        });
        IOS_postMessage({
          Message: 'FCtext',
          text: 'Complete',
        });
      }

      //Ensure Complete synce success by verifying Con_Flag = true; if false local data was not updated!
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    console.log(error);

    console.log('Closing FC Database after Failed Sync');
    IOS_postMessage({
      Message: 'FCtext',
      text: 'Complete: Send Failed',
    });
    FC_SYNCING = false;
    FC_SyncError = true;
    SYNC_STATUS('FC');
    //Update local sync database with incremented sync attempt
    IOS_postMessage({
      Message: 'FCSyncFail',
    });
  });
}
