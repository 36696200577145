function update_TDG_Data() {
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + (ToDate.getUTCDate() + 1);
  $('#Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=Report_TDG&FromDate=' + FromDate + '&ToDate=' + ToDate,
    function (json_data) {
      Report_Data = json_data[0];
      Report_TDG();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    AJAXFAIL(error);
  });
}

function Report_TDG() {
  $('#Report_Content').html('');
  $('#Title_Report_Name').html(iTrans('TDG Reports'));
  var TDGtype = $('input:radio[name=RPT_TDGtype]:checked').val();
  var PilotFilter = $('#RPT_Pilots').val();
  var IdentFilter = $('#RPT_Idents').val();
  if (TDGtype == 'Manifests') {
    $('#RPT_TDG_Filter1').show();
    $('#RPT_TDG_Filter2').show();
    var Manifests = Report_Data.Manifests;
    var HTML =
      '<h3 class="limit-table">' +
      iTrans('TDG Manifests') +
      '</h3><div style="width: 100%; overflow: auto"><table id="Report_Table">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Date') +
      '</th>' +
      '<th>' +
      iTrans('Ident') +
      '</th>' +
      '<th>' +
      iTrans('Pilot') +
      '</th>' +
      '<th>' +
      iTrans('Itin Details') +
      '</th>' +
      '<th>' +
      iTrans('Manifest Summary') +
      '</th>' +
      '<th>' +
      iTrans('View') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var Table = '';
    for (i in Manifests) {
      var I = Manifests[i];
      var Filed = getLocalDateTime(I.filed);
      var Items = I.Data;
      var Summary = '<table>';
      for (b in Items) {
        var B = Items[b];
        Summary +=
          '<tr><td>' +
          B.UN +
          '</td><td class="wrap">' +
          B.Description +
          '</td><td>' +
          B.pkgQTY +
          'x' +
          B.QTY +
          '</td></tr>';
      }
      Summary += '</table>';
      if (IdentFilter == 'All' || IdentFilter == I.ident)
        if (PilotFilter == 'All' || PilotFilter == I.pilot)
          Table +=
            '<tr><td>' +
            Filed.text +
            '</td><td>' +
            I.ident +
            '</td><td>' +
            I.pilot +
            "</td><td class='wrap'>" +
            I.iDetails +
            '</td><td>' +
            Summary +
            "</td><td><button id='RPT_Man-" +
            i +
            "' class='action-btn'><img src='./images/inspect.svg' /></button></td></tr>";
    }
    HTML += Table + '</tbody></table>';
    $('#Report_Content').html(HTML);
    $('#Report_Content').enhanceWithin();
    for (i in Manifests) $('#RPT_Man-' + i).click(showManifest);
  }
  if (TDGtype == 'Rejected') {
    var Rejected = Report_Data.Rejected;
    $('#RPT_TDG_Filter1').show();
    $('#RPT_TDG_Filter2').hide();
    var RejectedHTML =
      '<h3 class="limit-table">' +
      iTrans('Rejected Packages') +
      '</h3><div style="width: 100%; overflow: auto"><table id="Report_Table">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Date') +
      '</th>' +
      '<th>' +
      iTrans('User') +
      '</th>' +
      '<th>' +
      iTrans('Item Summary') +
      '</th>' +
      '<th>' +
      iTrans('Reason') +
      '</th>' +
      '<th>' +
      iTrans('View') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    for (i in Rejected) {
      var rI = Rejected[i];
      var rdate = getLocalDateTime(rI.DateTime);
      var rItem = rI.Manifest;

      var Checklist = rItem.Checklist;
      var count = 0;
      for (b in Checklist) {
        var C = Checklist[b];
        var ItemNum = parseInt(b) + 1;
        if (C.A == 'No') {
          count++;
        }
      }
      var Reason =
        iTrans('Rejected for answering') +
        ' <b>"' +
        iTrans('No') +
        '"</b> ' +
        iTrans('to') +
        ' <b>' +
        count +
        '</b> ' +
        iTrans('checklist items') +
        '';
      if (count == 0) Reason = iTrans('Unknown');
      var rSummary =
        '<table><tr><td>' +
        rItem.UN +
        '</td><td>' +
        rItem.Description +
        '</td><td>' +
        rItem.pkgQTY +
        'x' +
        rItem.QTY +
        '</td></tr></table>';
      RejectedHTML +=
        '<tr><td>' +
        rdate.text +
        '</td><td>' +
        rI.chatname +
        '</td><td>' +
        rSummary +
        '</td><td>' +
        Reason +
        "</td><td><button id='RPT_Rej-" +
        i +
        "' class='action-btn'><img src='./images/inspect.svg' /></button></td></tr>";
    }
    RejectedHTML += '</tbody></table>';
    $('#Report_Content').html(RejectedHTML);
    $('#Report_Content').enhanceWithin();
    for (i in Rejected) $('#RPT_Rej-' + i).click(showReject);
  }
}

function showReject() {
  console.log('displaying rejects');
  $('#Report_Content').html('');
  $('#RPT_TDG_Filter1').hide();
  var index = this.id;
  index = index.split('-');
  index = index[1];
  var Rejected = Report_Data['Rejected'][index]['Manifest'];
  var Checklist = Rejected.Checklist;
  var RejectedHTML =
    "<button id='RPT_ExitReject' data-icon='back' data-mini='true'>" + iTrans('Return to Rejection List') + '</button>';
  var rdate = getLocalDateTime(Report_Data['Rejected'][index].DateTime);
  var rby = Report_Data['Rejected'][index].chatname;
  RejectedHTML += '<table class="limit-table"><tr><td><h3>' + iTrans('Rejected Package Info') + '/h3>';
  RejectedHTML += '<p><b>' + iTrans('On') + ': </b>' + rdate.date + '<br />';
  RejectedHTML += '<b>' + iTrans('By') + ': </b>' + rby + '</p></td></tr></table>';

  RejectedHTML +=
    '<div style="width: 100%; overflow: auto"><table data-filter="true">' +
    '<thead>' +
    '<tr>' +
    '<th data-priority="1">' +
    iTrans('Consignor') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Shipping Name') +
    '</th>' +
    '<th data-priority="3">UN</th>' +
    '<th data-priority="2">' +
    iTrans('Class') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Quantity') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Location') +
    '</th>' +
    '<th data-priority="2">CAO</th>' +
    '<th data-priority="2">' +
    iTrans('Exemption') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('ERG Code') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Drill Code') +
    '</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';
  RejectedHTML +=
    '<tr><td>' +
    Rejected.Consignor +
    '</td><td>' +
    Rejected.Description +
    '</td><td>' +
    Rejected.UN +
    '</td><td>' +
    Rejected.Class_Div +
    '</td><td>' +
    Rejected.pkgQTY +
    'x' +
    Rejected.QTY +
    '</td><td>' +
    Rejected.Location +
    '</td><td>' +
    Rejected.CAO +
    '</td><td>' +
    Rejected.Exemption +
    '</td><td>' +
    Rejected.ERG_Num +
    '</td><td>' +
    Rejected.Drill_Code +
    '</td></tr></tbody></table><br />';
  RejectedHTML +=
    '<h3 class="limit-table">Checklist</h3><div style="width: 100%; overflow: auto"><table id="Report_Table" class="wrap limit-table">' +
    '<thead>' +
    '<tr>' +
    '<th data-priority="0">' +
    iTrans('Question') +
    '</th>' +
    '<th>' +
    iTrans('Answer') +
    '</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';
  var oldCat = '';
  for (i in Checklist) {
    var I = Checklist[i];
    var Cat = I.Q;
    Cat = Cat.split('</p>');
    var Q = Cat[1];
    Cat = Cat[0];
    var ItemNum = parseInt(i) + 1;
    if (Cat != oldCat) {
      RejectedHTML += '<tr><th>' + Cat + '</th><th></th></tr>';
      oldCat = Cat;
    }
    var lineClass = '';
    if (I.A == 'No') lineClass = 'redRecord';
    RejectedHTML += "<tr class='" + lineClass + "'><td>" + ItemNum + ': ' + Q + '</td><td>' + I.A + '</td></tr>';
  }
  RejectedHTML += '</tbody></table></div>';
  $('#Report_Content').html(RejectedHTML);
  $('#Report_Content').enhanceWithin();
  $('#RPT_ExitReject').click(Report_TDG);
}

function showManifest()
{
  console.log('Displaying Manifest');
  $('#RPT_TDG_Filter1').hide();
  $('#RPT_TDG_Filter2').hide();
  $('#Report_Content').html('');
  var index = this.id;
  index     = index.split('-');
  index     = index[1];
  Manifest_HTML(Report_Data['Manifests'][index], index, 'tdg');
}

function Manifest_HTML(DATA, index, type){
  var TDG_Manifest = DATA.Data;
  if (type == 'tdg')
  {
    var Pilot       = DATA.pilot;
    var LoadedBy    = DATA.Details;
    var PreparedBy  = DATA.AcceptedBy;
    if (PreparedBy == undefined) PreparedBy = LoadedBy;
    var transported = getLocalDateTime(DATA.filed);
    var itinNum     = DATA.TDG_PK;
    var ident       = DATA.ident;
    var Origin      = DATA.Origin;
    var Dest        = DATA.Dest;
    var Details     = DATA.iDetails;
    var ManifestHTML =
            "<button id='RPT_ExitManifest' data-icon='back' data-mini='true'>" + iTrans('Return to Manifests') + '</button>';
  }else{
    var Pilot = Report_Data[index].pilot;
    var LoadedBy = DATA.Details;
    var PreparedBy = DATA.Data[0].AcceptedBy;
    if (PreparedBy == undefined) PreparedBy = LoadedBy;
    var transported = getLocalDateTime(Report_Data[index].filed);
    var itinNum = DATA.TDG_PK;
    var ident = Report_Data[index].ident;
    var Origin      = DATA.Origin;
    var Dest        = DATA.Dest;
    var Details     = Report_Data[index].Details;
    var ManifestHTML =
            "<button id='RPT_ExitManifest' data-icon='back' data-mini='true'>" + iTrans('Return to Itinerary List') + '</button>';
  }

  ManifestHTML += '<table class="limit-table"><tr><td><h3>' + iTrans('Dangerous Goods Transported') + '</h3><h3>' + iTrans('TDG Manifest') + '</h3>';
  ManifestHTML +=
    '<p><b>' +
    iTrans('Transported') +
    ': </b>' +
    transported.date +
    '<br /><b>' +
    iTrans('Prepared By') +
    ': </b>' +
    PreparedBy +
    '<br /><b>' +
    iTrans('Loaded By') +
    ': </b>' +
    LoadedBy +
    '<br /><b>' +
    iTrans('Captain') +
    ': </b>' +
    Pilot +
      '<br />' + (DATA.signature == null ? 'Unsigned' : '<img width="300px" src="' + DATA.signature + '" />') +
    '<br /><b>' +
    iTrans('Aircraft') +
    ': </b>' +
    ident +
    '<br /><b>' +
    iTrans('Itinerary') +
    ' #</b>' +
    itinNum +
      '<br /><b>' +
      iTrans('Origin') +
      ': </b>' +
      Origin +
      '<br /><b>' +
      iTrans('Dest') +
      ': </b>' +
      Dest +
      '<br /><b>' +
    iTrans('Details') +
    ': </b>' +
    Details +
    '</p></td></tr></table>';
  ManifestHTML +=
    '<div style="width: 100%; overflow: auto"><table data-filter="true">' +
    '<thead>' +
    '<tr>' +
    '<th data-priority="1">' +
    iTrans('Consignor') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Shipping Name') +
    '</th>' +
    '<th data-priority="3">UN</th>' +
    '<th data-priority="2">' +
    iTrans('Class') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Quantity') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Location') +
    '</th>' +
    '<th data-priority="2">CAO</th>' +
    '<th data-priority="2">' +
    iTrans('Exemption') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('ERG Code') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Drill Code') +
    '</th>' +
    '<th data-priority="2">' +
    iTrans('Acceptance') +
    '</th>' +
      '<th>' + iTrans('Action') + '</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';
  for (var i in TDG_Manifest) {
    var lineClass = '';
    //check for segregation between packages
    for (var s in TDG_Manifest) {
      if (s !== i) {
        //don't check against self
        var mClass = TDG_Manifest[i].Class_Div;
        mClass = mClass.split(' ');
        mClass = mClass[0];
        var cClass = TDG_Manifest[s].Class_Div;
        cClass = cClass.split(' ');
        cClass = cClass[0];
        if ((mClass == '3' && cClass == '5.1') || (mClass == '5.1' && cClass == '3')) lineClass = 'yellowRecord';
        if ((mClass == '4.2' && cClass == '5.1') || (mClass == '5.1' && cClass == '4.2')) lineClass = 'yellowRecord';
        if ((mClass == '4.3' && cClass == '8') || (mClass == '8' && cClass == '4.3')) lineClass = 'yellowRecord';
        var emClass = mClass.split('.');
        var ecClass = cClass.split('.');

        if (emClass[0] == '1' && ecClass[0] == '1') {
          //comparing two explosive class substances
          if (
            (emClass[1] == '4B' && ecClass[1] !== '4B' && ecClass[1] !== '4S') ||
            (emClass[1] !== '4S' && emClass[1] !== '4B' && ecClass[1] == '4B')
          )
            lineClass = 'yellowRecord';
        }
        //All explosives not 1.4S are not compatable with any other class
        if (emClass[0] == '1' && ecClass[0] !== '1') {
          if (emClass[1] !== '4S') lineClass = 'yellowRecord';
        }
        if (ecClass[0] == '1' && emClass[0] !== '1') {
          if (ecClass[1] !== '4S') lineClass = 'yellowRecord';
        }
      }
    }

    var Item = TDG_Manifest[i];
    var Acceptance = '';
    if (Item.Acceptance == 'false') Acceptance += '' + iTrans('Not Fully Regulated') + '<br />';
    if (Item.Acceptance == 'true') Acceptance += '' + iTrans('Fully Regulated') + '<br />';
    if (Item.AcceptedBy != undefined) Acceptance += '' + iTrans('Accepted By') + ': ' + Item.AcceptedBy;
    else Acceptance += '' + iTrans('Accepted By') + ': ' + iTrans('Unknown User') + '';

    var Action = '';
    if (Item.Checklist != ''){
      //Populate checklist
      Action = '<div class="vertical-center-container"><button class="action-btn" onClick="displayTDG_Checklist(' + index + ',' + i + ')"><img src="./images/inspect.svg"></button></div>';

    }
    ManifestHTML +=
      "<tr class='" +
      lineClass +
      "'><td>" +
      Item.Consignor +
      '</td><td>' +
      Item.Description +
      '</td><td>' +
      Item.UN +
      '</td><td>' +
      Item.Class_Div +
      '</td><td>' +
      Item.pkgQTY +
      'x' +
      Item.QTY +
      '</td><td>' +
      Item.Location +
      '</td><td>' +
      Item.CAO +
      '</td><td>' +
      Item.Exemption +
      '</td><td>' +
      Item.ERG_Num +
      '</td><td>' +
      Item.Drill_Code +
      '</td><td>' +
      Acceptance +
        '</td><td>' +
        Action +
      '</td></tr>';
  }
  ManifestHTML += '</tbody></table></div>';
  ManifestHTML += '<div id="TDG_ChecklistHLDR"></div>';
  $('#Report_Content').html(ManifestHTML);
  $('#Report_Content').enhanceWithin();
  if (type == 'tdg')  $('#RPT_ExitManifest').click(Report_TDG);
  else $('#RPT_ExitManifest').click(Report_Itin);
}

function displayTDG_Checklist(index, i){
  var TDG_Manifest = Report_Data['Manifests'][index].Data[i];
  var html = '<h3 className="limit-table">Checklist</h3>';
  html += '<b>Description: </b>' + TDG_Manifest.Description;
  html += ADMIN_TDG_Checlist_HTML(TDG_Manifest.Checklist);
  $("#TDG_ChecklistHLDR").html('<div class="limit-table">' + html + '</div>');
  $("#TDG_ChecklistHLDR").enhanceWithin();
  $('html, body').animate({
    scrollTop: $("#TDG_ChecklistHLDR").offset().top
  }, 500);
}

function ADMIN_TDG_Checlist_HTML(Checklist){
  console.log(Checklist);
  var HTML =
      '<div style="width: 100%; overflow: auto"><table id="Report_Table" class="wrap limit-table">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Question') +
      '</th>' +
      '<th>' +
      iTrans('Answer') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
  var oldCat = '';
  for (var i in Checklist) {
    var I = Checklist[i];
    var ItemNum = parseInt(i) + 1;
    var lineClass = '';
    if (I.A == 'No') lineClass = 'redRecord';
    HTML += "<tr class='" + lineClass + "'><td>" + ItemNum + ': ' + I.Q + '</td><td>' + I.A + '</td></tr>';
  }
  HTML += '</tbody></table></div>';
  return HTML;
}