class mSAMA_CorrectiveAction extends mSAMA_AbstractModel {
  constructor(_databaseData, _consequence) {
    super('coa_primaryKey');

    this.consequence = _consequence;
    this.syncReset = {};

    let cpk = this.consequence == null ? -1 : this.consequence.GetPk();
    this.sync = {
      coa_primaryKey: null,
      coa_groupId: '',
      coa_conPk: cpk,
      coa_name: null,
      coa_whys: null,
      coa_resolution: null,
      coa_dueDate: null,
      coa_responsibleStaff: null,
      coa_term: 0,
      coa_termSelect: -1,
      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _databaseData);

    if (this.IsNew()) {
      this.SetPk(cSAMA_Utils.GetUID());
      this.SetModified(true);
    }

    if (cpk !== -1) {
      this.validateSettings = {
        coa_name: new cSAMA_Validator({
          type: SAMA.enums.VALIDATORS.STRING,
          minLength: 5,
          maxLength: SAMA.enums.TEXTLENGTHS.Small,
          mustNotContain: SAMA.settings.preferences.GetBadWords(),
        }),

        coa_resolution: new cSAMA_Validator({
          type: SAMA.enums.VALIDATORS.STRING,
          minLength: 25,
          maxLength: SAMA.enums.TEXTLENGTHS.Massive,
          mustNotContain: SAMA.settings.preferences.GetBadWords(),
        }),
      };

      if (this.sync.coa_dueDate != null) {
        this.sync.coa_dueDate = cSAMA_Utils.GetInputDateFromSQLDate(this.sync.coa_dueDate);
      } else {
        this.sync.coa_dueDate = moment().format(SAMA.defaults.DateInputFormat);
      }

      if (this.sync.coah_action === 'COMPL') {
        this.sync.coa_completedDate = cSAMA_Utils.NiceDate(this.sync.coah_dateTime, true, false, true);
      }

      if (this.sync.coa_whys == null) {
        this.sync.coa_whys = [];
      }

      let fyValidator = new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        maxLength: SAMA.enums.TEXTLENGTHS.Massive,
        required: false,
        mustNotContain: SAMA.settings.preferences.GetBadWords(),
      });

      // Set the presets for the 5y tabs and link the validation
      for (let i = 0; i < 5; i++) {
        this.validateSettings['coa_fy' + i] = fyValidator;

        if (this.sync.coa_whys[i] != null) {
          this.sync['coa_fy' + i] = this.sync.coa_whys[i];
        } else {
          this.sync['coa_fy' + i] = '';
        }
      }

      this.validateSettings.coa_groupId = new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        maxLength: 64,
        required: false,
      });

      this.ConfirmValueChanges();
    }
  }

  /**
   * Resets the changes made to the object
   */
  DiscardValueChanges() {
    $.extend(true, this.sync, this.syncReset);
  }

  /**
   * Confirms the changes made to the object
   */
  ConfirmValueChanges() {
    $.extend(true, this.syncReset, this.sync);
  }

  /**
   * @return {mSAMA_Consequence}
   */
  GetConsequence() {
    return this.consequence;
  }

  /**
   * Returns the object as a transmittable object
   * @return {null|object}
   */
  GetTransmissible() {
    if (!this.IsModified() || (this.IsNew() && this.IsDeleted())) {
      return null;
    }

    // Add the Timezone to the due date
    this.sync.realDueDate = cSAMA_Utils.CombineDateTime(this.sync.coa_dueDate);

    return this.sync;
  }
}
