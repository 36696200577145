/**
 * Class describing SMS objectives
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 * @param {mSAMA_Policy} _owner
 * @param {object} _databaseData
 */

class mSAMA_PolicyObjective extends mSAMA_AbstractModel {
  constructor(_owner, _databaseData) {
    super('pog_primaryKey');

    /** @type {mSAMA_Policy} */
    this.owner = _owner;
    this.tasks = [];

    this.sync = {
      pog_primaryKey: null,
      pog_groupPk: null,
      pog_name: null,
      pog_description: null,
      pog_dueDate: null,
      pog_completedDate: null,
      modified: false,
      deleted: false,
      completed: false,
    };

    $.extend(true, this.sync, _databaseData);

    this.validateSettings = {
      pog_name: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 15,
        maxLength: SAMA.enums.TEXTLENGTHS.Small,
      }),
      pog_description: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 25,
        maxLength: SAMA.enums.TEXTLENGTHS.Massive,
      }),
      pog_dueDate: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.DATE,
        future: true,
        required: false,
      }),
    };

    if (this.sync.pog_dueDate != null) {
      // Got a date from server -> transform to local time
      this.sync.pog_dueDate = cSAMA_Utils.GetInputDateFromSQLDate(this.sync.pog_dueDate);
    }

    if (this.IsNew()) {
      this.SetPk(cSAMA_Utils.GetUID());
      this.sync.pog_name = 'Untitled Objective';
    }
  }

  /**
   * Marks the objective as completed
   */
  SetCompleted() {
    this.SetModified(true);
    this.sync.completed = true;
    this.sync.pog_completedDate = moment().utc().format(SAMA.defaults.DateInputFormat);
  }

  /**
   * Gets the completed state of the objective
   * @returns {boolean}
   */
  IsCompleted() {
    return this.sync.pog_completedDate != null;
  }

  SetModified(_state) {
    super.SetModified(_state);
    this.owner.SetModified(_state);
  }

  AddTask(_task) {
    this.tasks.push(_task);
  }

  /**
   * Returns the object as a transmittable object
   * @return {null|object}
   */
  GetTransmissible() {
    if (!this.IsModified() || (this.IsNew() && this.IsDeleted())) {
      return null;
    }

    // Check if the prime minister is a valid date. If not, we DGAF
    let trueDue = moment(this.sync.pog_dueDate);
    if (!trueDue.isValid() || trueDue.isBefore('2000-01-01')) {
      trueDue = null;
    } else {
      trueDue = trueDue.format();
    }

    let submission = {
      objective: {
        pog_trueDueDate: trueDue,
      },
      tasks: [],
    };

    $.extend(true, submission.objective, this.sync);

    for (let t = 0; t < this.tasks.length; t++) {
      let task = this.tasks[t].GetTransmissible();
      if (task != null) {
        submission.tasks.push(task);
      }
    }

    return submission;
  }
}
