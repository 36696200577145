class cSAMA_FilterObjectives extends cSAMA_AbstractFilter {
  constructor(_view, _frameless) {
    super({
      view: _view,
      filterTemplate: SAMA.Templates.tpl.objectives.filterForm,
      frameless: _frameless,
      orderTerms: {
        pog_dueDate: null,
      },
      showTerms: {
        pog_stage: null,
      },
    });
  }

  GenerateTerms(_inputValues) {
    // ------------------
    // Show byCompletion
    // ------------------
    switch (_inputValues.pogState) {
      case 0:
        // Show if ongoing
        this.showTerms.pog_stage = new cSAMA_FilterTerm.Show.Boolean();
        break;
      case 1:
        // Show if Completed
        this.showTerms.pog_stage = new cSAMA_FilterTerm.Show.Boolean(true);
        break;
    }
  }
}
