/**
 * Data class for consequences
 */
class mSAMA_Consequence extends mSAMA_AbstractModel {
  /**
   * @param {mSAMA_Report}      _parentReport
   * @param {mSAMA_RiskMatrix}  _riskMatrix
   * @param {object}           [_dataBaseData]
   */
  constructor(_parentReport, _riskMatrix, _dataBaseData) {
    super('con_primaryKey');
    this.parentReport = _parentReport;

    this.riskFactorValues = [];

    this.sync = {
      con_primaryKey: null,
      con_description: '',
      con_frequency: 1,
      con_riskScore: 0,
      con_repPk: -1,
      con_hasOccurred: false,
      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _dataBaseData);

    this.validateSettings = {
      con_description: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 10,
        mustNotContain: SAMA.settings.preferences.GetBadWords(),
        maxLength: SAMA.enums.TEXTLENGTHS.Small,
      }),
    };

    if (this.IsNew()) {
      let num = this.parentReport.GetConsequences().length + 1;
      this.sync.con_description = 'Nondescript Consequence #' + num;

      // Since this is a new consequence, we must populate it with empty risk factor values
      this.SetPk(cSAMA_Utils.GetUID());
      this.GenerateEmptyRiskFactorValues(_riskMatrix);
      this.SetModified(true);
    }
  }

  GenerateEmptyRiskFactorValues(_riskMatrix) {
    let factors = _riskMatrix.GetRiskFactors();

    for (let f = 0; f < factors.length; f++) {
      this.AddRiskFactor(factors[f]);
    }
  }

  /**
   * Indicates whether this consequence has a corrective action.
   * @return {boolean}
   */
  HasCorrectiveAction() {
    return this.correctiveAction != null;
  }

  /**
   * Returns the corrective action of this consequence
   * @return {mSAMA_CorrectiveAction}
   */
  GetCorrectiveAction() {
    return this.correctiveAction;
  }

  /**
   * Sets the corrective action of this consequence
   * @param {mSAMA_CorrectiveAction} _corrAct
   */
  SetCorrectiveAction(_corrAct) {
    this.correctiveAction = _corrAct;
  }

  /**
   * Adds a risk factor value to this consequence
   * @param {mSAMA_RiskFactor} _factor
   * @param {mSAMA_RiskFactorValue} [_factorValue]
   */
  AddRiskFactor(_factor, _factorValue) {
    if (_factorValue == null) {
      _factorValue = new mSAMA_RiskFactorValue(_factor, this);
    }

    this.riskFactorValues.push(_factorValue);
  }

  /**
   * Gets the values of risk factors in this consequence
   * @returns {mSAMA_RiskFactorValue[]}
   */
  GetRiskFactorValues() {
    return this.riskFactorValues;
  }

  /**
   * Updates the calculated risk
   * @return {number}
   */
  UpdateRiskCalculation() {
    let newScore = 0;
    let newDivisor = 0;
    let lockToMax = false;

    for (let r = 0; r < this.riskFactorValues.length; r++) {
      let factor = this.riskFactorValues[r];
      let threshold = factor.GetThreshold();
      let risk = factor.GetRisk();

      // If this works out to be true, the risk passes the threshold and
      // we must set the consequence to the highest risk. We can also stop the loop there
      // because the risk score will not be calculated.
      if (risk >= threshold && threshold > 1) {
        lockToMax = true;
        break;
      }

      newScore += factor.GetWeight() * factor.GetRisk();
      newDivisor += factor.GetWeight();
    }

    if (!lockToMax) {
      newDivisor = newDivisor <= 0 ? 1 : newDivisor;
      newScore = newScore / newDivisor;
    } else {
      newScore = 5;
    }

    this.sync.con_riskOnly = newScore;

    newScore += +this.sync.con_frequency;
    this.sync.con_riskScore = newScore.toFixed(2);

    return newScore;
  }

  /**
   * Get the current risk score
   * @returns {number}
   */
  GetRiskScore() {
    return +this.sync.con_riskScore;
  }

  /**
   * Get the risk axis value only
   * @return {number}
   */
  GetRiskOnly() {
    return +this.sync.con_riskOnly;
  }

  /**
   * Get the frequency axis value only
   * @return {number}
   */
  GetFrequencyOnly() {
    return +this.sync.con_frequency;
  }

  /**
   * Prepare this for sending
   * @returns {{consequence: ({con_primaryKey: number, con_description: string, con_frequency: number, con_riskScore:
   *   number, modified: boolean, deleted: boolean}|*), riskFactors: Array}}
   * @override
   */
  GetTransmissible() {
    let submission = {
      consequence: this.sync,
      riskFactors: [],
    };

    for (let rfv = 0; rfv < this.riskFactorValues.length; rfv++) {
      let factor = this.riskFactorValues[rfv];
      if (factor.IsModified()) {
        submission.riskFactors.push(factor.GetTransmissible());
      }
    }

    if (this.HasCorrectiveAction()) {
      submission.correctiveAction = this.correctiveAction.GetTransmissible();
    }

    return submission;
  }
}
