class cSAMA_FilterCorrectiveActions extends cSAMA_AbstractFilter {
  constructor(_view) {
    super({
      view: _view,
      filterTemplate: SAMA.Templates.tpl.correctiveAction.filterForm,
      orderTerms: {
        coa_dueDate: null,
        coa_riskScore: null,
      },
      showTerms: {
        coa_name: null,
        rep_summary: null,
        coa_riskScore: null,
        coa_overdue: null,
        coah_action: null,
      },
    });
  }

  /**
   *
   * @param {object} _inputValues
   */
  GenerateTerms(_inputValues) {
    // ------------------
    // Show by completion
    // ------------------
    this.showTerms.coa_overdue = null;
    this.showTerms.coah_action = null;
    switch (_inputValues.coa_stage) {
      case 1:
        // Show if in progress
        this.showTerms.coah_action = new cSAMA_FilterTerm.Show.IsString('PROGR');
        break;
      case 2:
        // Show if overdue
        this.showTerms.coa_overdue = new cSAMA_FilterTerm.Show.Boolean();
        break;
      case 3:
        // Show if completed
        this.showTerms.coah_action = new cSAMA_FilterTerm.Show.IsString('COMPL');
        break;
      case 4:
        // Show if closed
        this.showTerms.coah_action = new cSAMA_FilterTerm.Show.IsString('CLOSD');
        break;
    }

    // ------------------
    // Show by Title
    // ------------------
    if (_inputValues.coa_title.length > 0) {
      this.showTerms.coa_name = new cSAMA_FilterTerm.Show.StringContains(_inputValues.coa_title);
    } else {
      this.showTerms.coa_name = null;
    }

    // ------------------
    // Show by Report
    // ------------------
    if (_inputValues.coa_reportName.length > 0) {
      this.showTerms.rep_summary = new cSAMA_FilterTerm.Show.StringContains(_inputValues.coa_reportName);
    } else {
      this.showTerms.rep_summary = null;
    }

    // ------------------
    // Show by Risk Range
    // ------------------
    this.showTerms.coa_riskScore = new cSAMA_FilterTerm.Order.Between(
      _inputValues.coa_riskMin,
      _inputValues.coa_riskMax
    );

    // ------------------
    // Order by Date
    // ------------------
    switch (_inputValues.coa_sortDate) {
      case 0:
        // Off
        this.orderTerms.coa_dueDate = null;
        break;

      case 1:
        // New First
        this.orderTerms.coa_dueDate = new cSAMA_FilterTerm.Order.Date(true);
        break;

      case 2:
        // Old First
        this.orderTerms.coa_dueDate = new cSAMA_FilterTerm.Order.Date();
        break;
    }

    // ------------------
    // Order by Risk
    // ------------------
    switch (_inputValues.coa_sortRisk) {
      case 0:
        // Off
        this.orderTerms.coa_riskScore = null;
        break;

      case 1:
        // High First
        this.orderTerms.coa_riskScore = new cSAMA_FilterTerm.Order.GreaterThan();
        break;

      case 2:
        // Low First
        this.orderTerms.coa_riskScore = new cSAMA_FilterTerm.Order.GreaterThan(true);
        break;
    }
  }
}
