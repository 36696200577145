let cSAMA_FilterTerm = {
  Show: {},
  Order: {},
};

/**
 * A boolean filter that checks _value for truthyness or, if _invert, checks for falsyness
 * @param {boolean} [_invert]
 * @constructor
 */
cSAMA_FilterTerm.Show.Boolean = function (_invert) {
  /**
   * @param {*} _value
   * @return {boolean}
   */
  this.Run = (_value) => {
    if (_invert === true) {
      return !cSAMA_Utils.IsTruthy(_value);
    }
    return cSAMA_Utils.IsTruthy(_value);
  };
};

/**
 * A filter that checks for exact equality between integers
 * @param {string} _comparator
 * @constructor
 */
cSAMA_FilterTerm.Show.Exactly = function (_comparator) {
  /**
   *
   * @param {int} _comparatorB
   * @return {boolean}
   */
  this.Run = (_comparatorB) => {
    return _comparator === +_comparatorB;
  };
};

/**
 * A filter that checks for exact equality between strings
 * @param {int} _comparator
 * @constructor
 */
cSAMA_FilterTerm.Show.IsString = function (_comparator) {
  /**
   *
   * @param {int} _comparatorB
   * @return {boolean}
   */
  this.Run = (_comparatorB) => {
    return _comparator === _comparatorB;
  };
};

/**
 * A filter that checks if _haystack contains _needle
 * @param {string} _needle
 * @constructor
 */
cSAMA_FilterTerm.Show.StringContains = function (_needle) {
  if (_needle == null) {
    _needle = '';
  }
  _needle = cSAMA_Utils.EscapeRegExp(_needle);
  let rx = new RegExp(_needle, 'i');

  /**
   *
   * @param {string} _haystack
   * @return {boolean}
   */
  this.Run = (_haystack) => {
    if (_haystack == null) {
      return false;
    }

    return _haystack.search(rx) > -1;
  };
};

//Set _infToDate to true if you want to find all dates in the future. Unset or false only searches the next day
cSAMA_FilterTerm.Show.BetweenDates = function (_dateFrom, _dateTo, _infToDate) {
  let odf = _dateFrom;
  let odt = _dateTo;

  if (_dateFrom === false) {
    _dateFrom = '0000-01-01';
  }

  _dateFrom = moment(_dateFrom, 'YYYY-MM-DD');

  if (_dateTo === false) {
    if (odf === false || _infToDate === true) {
      // FOR THE EMPEROR!!!
      _dateTo = '40000-01-01';
      _dateTo = moment(_dateTo, 'YYYY-MM-DD');
    } else {
      _dateTo = _dateFrom.clone();
      _dateTo.add(1, 'days');
    }
  } else {
    _dateTo = moment(_dateTo, 'YYYY-MM-DD');
  }

  /**
   * Inclusively checks if _dateString is between the dates from the inputs
   * @param _dateString
   * @return {boolean}
   * @constructor
   */
  this.Run = (_dateString) => {
    if (_dateString == null) {
      // If both dates are unset, we'll accept any date, even null.
      return odt === false && odf === false;
    }
    return moment(_dateString).isBetween(_dateFrom, _dateTo, 'day', '[]');
  };
};

/**
 * A sorter that compares Dates
 * @constructor
 */
cSAMA_FilterTerm.Order.Date = function (_invert) {
  /**
   *
   * @param _wertA
   * @param _wertB
   * @return {number}
   */
  this.Run = (_wertA, _wertB) => {
    let a, b;

    if (_invert === true) {
      a = moment(_wertB);
      b = moment(_wertA);
    } else {
      a = moment(_wertA);
      b = moment(_wertB);
    }

    let r = 0;

    if (a.isBefore(b, 'day')) {
      r = -1;
    } else if (a.isAfter(b, 'day')) {
      r = 1;
    }

    return r;
  };
};

/**
 * Sorter that shows whether one value is greater than the other
 * @param {boolean} [_invert]
 * @constructor
 */
cSAMA_FilterTerm.Order.GreaterThan = function (_invert) {
  /**
   *
   * @param {int} _wertA
   * @param {int} _wertB
   * @return {number}
   */
  this.Run = (_wertA, _wertB) => {
    if (_invert === true) {
      return _wertB - _wertA;
    }
    return _wertA - _wertB;
  };
};

/**
 * Sorter that shows whether one value is between a max and min value
 * @constructor
 */
cSAMA_FilterTerm.Order.Between = function (_min, _max, _inclusive) {
  _inclusive = _inclusive == null ? true : _inclusive;

  /**
   *
   * @param {int} _wert
   * @return {number}
   */
  this.Run = (_wert) => {
    _wert = +_wert;
    if (_inclusive) {
      return _wert >= _min && _wert <= _max;
    }

    return _wert > _min && _wert < _max;
  };
};
