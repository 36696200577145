/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var SystemSyncError = false;
function syncSYSTEM() {
  //get system information like aircraf WB master setup data
  $.getJSON(BASEURL + 'sync-AJAX.php?action=AcTypeList', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      IOS_postMessage({
        Message: 'SyncStatusOffline',
      });
      SystemSyncError = true;
      syncPILOT_Data();
    } else {
      if (json_data[0].ServerResponse == 'No Aircraft') {
        SystemSyncError = false;
        localStorageDB.setItem('AcTypeList', '[]');
        FDT_AC_TYPE_DATA = [];
      } else {
        FDT_AC_TYPE_DATA = json_data;
        localStorageDB.setItem('AcTypeList', JSON.stringify(json_data));
      }
      syncPILOT_Data();
    }
  }).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'Stext',
      text: 'Aircraft Type List Failed',
    });

    SystemSyncError = true;

    syncPILOT_Data();
  });
}

function syncPILOT_Data() {
  var PILOT_Data;
  //get group data: aircraft weight and balance, Temp Configs, Aircraft List and store in group table
  IOS_postMessage({
    Message: 'Ptext',
    text: 'Downloading',
  });
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getPilots', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      IOS_postMessage({
        Message: 'SyncStatusOffline',
      });
      SystemSyncError = true;
      if (RISK_MGMT == 1) SyncRiskForms();
      else SystemSyncComplete();
    } else {
      if (json_data[0].ServerResponse == 'No Pilots') {
        SystemSyncError = false;
        PILOT_Data = [];
        STORE_PILOT_Complete = true;
        IOS_postMessage({
          Message: 'Ptext',
          text: 'No Pilots',
        });

        cirroDB.clearAll('Pilots', function (e) {
          if (RISK_MGMT == 1) SyncRiskForms();
          else SystemSyncComplete();
        });
      } else {
        cirroDB.clearAll('Pilots', function (e) {
          PILOT_Data = json_data;
          for (var p in PILOT_Data) {
            AddPilot(PILOT_Data[p], p == PILOT_Data.length - 1);
          }
        });
      }
    }
  }).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'Ptext',
      text: 'Pilot Sync Failed!',
    });

    SystemSyncError = true;

    if (RISK_MGMT == 1) SyncRiskForms();
    else SystemSyncComplete();
  });
}

var PilotInsertError = false;
function AddPilot(Pilot, last) {
  cirroDB.insert('Pilots', Pilot, null, function (e) {
    IOS_postMessage({
      Message: 'Ptext',
      text: Pilot.chatname,
    });
    if (e === false) PilotInsertError = true;
    if (last) {
      if (PilotInsertError) {
        IOS_postMessage({
          Message: 'Ptext',
          text: 'Error',
        });
      } else {
        IOS_postMessage({
          Message: 'Ptext',
          text: 'Complete',
        });
      }
      if (RISK_MGMT == 1) SyncRiskForms();
      else SystemSyncComplete();
    }
  });
}

function SyncRiskForms() {
  IOS_postMessage({
    Message: 'Stext',
    text: 'Downloading',
  });
  $.getJSON(BASEURL + 'menu-RA-AJAX.php?action=getUserForms', function (json_data) {
    IOS_postMessage({
      Message: 'Stext',
      text: 'Complete',
    });
    if (json_data.Forms == undefined) {
      SystemSyncComplete();

      return;
    }
    storeRiskForms(json_data);
    SystemSyncComplete();
  }).fail(function (jqXHR, status, error) {
    SystemSyncError = true;
    IOS_postMessage({
      Message: 'Stext',
      text: 'Error',
    });
    SystemSyncComplete();
  });
}

function SystemSyncComplete() {
  if (PilotInsertError == false) SystemSyncError = false;
  if (RISK_MGMT == 0) {
    IOS_postMessage({
      Message: 'Stext',
      text: 'Complete',
    });
  }
  SYSTEM_SYNCING = false;
  SYNC_STATUS('SYSTEM');
}
