/**
 *
 */
class FuelCachesTable extends AbstractDatabaseTable {
  constructor() {
    super('FuelCachesV2', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"        integer primary key,
            "group"             text,
            "location"          text,
            "FuelType"          text,
            "hasFuelPump"       integer,
            "hasAC_Power"       integer,
            "hasBad_Fuel"       integer,
            "inspected"         text,
            "full"              numeric,
            "full_Alarm"        numeric,
            "empty"             numeric,
            "datetime"          text,
            "whoupdate"         text,
            "Lat"               numeric,
            "Long"              numeric,
            "moveable"          integer,
            "bulk"              integer,
            "capacity"          numeric,
            "capacity_Alarm"    numeric,
            "remaining"         numeric,
            "MeasureType"       text,
            "Units"             text,
            "DipChart"          text,
            "DipChartUnits"     text,
            "Image"             text,
            "ImageDateTime"     text,
            "Comment"           text,
            "Update_Comment"    text,
            "inspection_Doc_ID" integer,
            "DELETED"           integer
        )    `;
    //#endregion
  }

  /**
   *
   * @param whereClause
   * @param values
   * @returns {Promise<false|any[]>}
   */
  async select(whereClause, values) {
    await this.createTable();

    let columns = [
      'PrimaryKey',
      'group',
      'location',
      'FuelType',
      'hasFuelPump',
      'hasAC_Power',
      'hasBad_Fuel',
      'inspected',
      'full',
      'full_Alarm',
      'empty',
      'datetime',
      'whoupdate',
      'Lat',
      'Long',
      'moveable',
      'bulk',
      'capacity',
      'capacity_Alarm',
      'remaining',
      'MeasureType',
      'Units',
      'DipChart',
      'DipChartUnits',
      'ImageDateTime',
      'Comment',
      'Update_Comment',
      'inspection_Doc_ID',
      'DELETED',
    ];

    if (whereClause !== 0) {
      columns.push('Image');
    }

    return super.select(whereClause, values, columns);
  }
}
