class vSAMA_ListItemTimeLine extends vSAMA_AbstractListViewItem {
  constructor(_timeLineEntry) {
    super(_timeLineEntry);

    let formatter = {};

    $.extend(true, formatter, this.model.sync);

    formatter.tie_userName = formatter.tie_userName == null ? 'Anonymous' : formatter.tie_userName;
    formatter.tie_action = vSAMA_ListItemTimeLine.EnHumanReadableActionNames[formatter.tie_action];
    formatter.tie_dateTime = cSAMA_Utils.NiceDate(formatter.tie_dateTime, true, true, true);

    if (formatter.tie_comment == null || formatter.tie_comment.length <= 0) {
      formatter.tie_comment = 'No comment was left.';
    }

    this.SetUpGui(SAMA.Templates.tpl.general.timeLineListItem, formatter, true);
  }
}

vSAMA_ListItemTimeLine.EnHumanReadableActionNames = {
  CREAT: 'Created',
  COMPL: 'Completed',
  REOPN: 'Re-Opened',
  CLOSD: 'Closed',
  submit: 'Submitted',
  approve: 'Approved',
  reject: 'Rejected',
  archive: 'Archived',
};
