/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

function popFlightTemplates() {
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getFlightTemplates', function (json_data) {
    if (json_data[0].ServerResponse != null) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse === 'Uncaught Exception') {
        ServerResponse(json_data[0], 'retrieveTemplate()');
      }
      if (json_data[0].ServerResponse === 'Not Logged In') {
        NOTLOGGEDIN();
      }
      if (json_data[0].ServerResponse === 'Offline') {
        $.mobile.changePage($('#MainMenu'), {
          reverse: true,
          changeHash: false,
        });
      }
      if (json_data[0].ServerResponse === 'None') {
        //there are no templates in the system
        $('#FTcontent').html('<h2 class="limit-table">There are no templates for your group</h2>');
      }
    } else {
      let templates = json_data;
      console.log('Received Flight Templates');
      console.log(json_data);
      let html =
        '<table class="limit-table last-td-fill"><thead><tr><th>Name</th><th>AcType</th><th>Ident</th><th>Action</th></tr></thead>';
      for (let i in templates) {
        if (!templates.hasOwnProperty(i)) {
          continue;
        }

        html += `<tr>
    <td>${templates[i].name}</td>
    <td>${templates[i].AcType}</td>
    <td>${templates[i].AcIdent}</td>
    <td>
        <div class="vertical-center-container">
            <button class="action-btn" value="${i}" onclick="deleteFtemplate(${templates[i].PrimaryKey})">
                <img src="./images/delete.svg" /></button>
        </div>
    </td>
</tr>`;
      }
      html += '</table>';
      $('#FTcontent').html(html);
    }
  }).fail(function () {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Connection Error',
      headerClose: false,
      buttonPrompt: 'There was an error Retrieving the data please try again.',
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}

function deleteFtemplate(i) {
  $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=deleteFlightTemplates&PK=' + i, function (json_data) {
    if (json_data[0].ServerResponse != null) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse === 'Uncaught Exception') {
        ServerResponse(json_data[0], 'retrieveTemplate()');
      }
      if (json_data[0].ServerResponse === 'Not Logged In') {
        NOTLOGGEDIN();
      }
      if (json_data[0].ServerResponse === 'Offline') {
        $.mobile.changePage($('#MainMenu'), {
          reverse: true,
          changeHash: false,
        });
      }
      if (json_data[0].ServerResponse === 'Success') {
        popFlightTemplates();
      }
    } else {
      console.log('Problem Here...');
    }
  }).fail(function () {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Connection Error',
      headerClose: false,
      buttonPrompt: 'There was an error Retrieving the data please try again.',
      buttons: {
        Close: {
          click: function () {},
        },
      },
    });
  });
}
