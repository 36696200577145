class mSAMA_TimeLineEntry extends mSAMA_AbstractModel {
  constructor(_databaseData) {
    super('pk');

    this.sync = {
      tie_primaryKey: null,
      tie_action: null,
      tie_dateTime: null,
      tie_userName: null,
      tie_comment: '',
    };

    $.extend(true, this.sync, _databaseData);
  }
}
