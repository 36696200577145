/**
 *
 */
class FdtRecsV3Table extends AbstractDatabaseTable {
  constructor() {
    super('FDT_RecsV3', ['Date', 'IDENT']);
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            RecPrimaryKey integer,
            "group"       text,
            User_PK       integer,
            Modified      text,
            Date          text,
            FTT           numeric,
            DR            numeric,
            PIC           numeric,
            SIC           numeric,
            FI            numeric,
            DAY           numeric,
            NIGHT         numeric,
            XC            numeric,
            AI            numeric,
            SI            numeric,
            IA            integer,
            DL            integer,
            NL            integer,
            GA            integer,
            DAT           integer,
            NT            integer,
            LL            numeric,
            WB            numeric,
            MF            numeric,
            OT            numeric,
            FTO           integer,
            FLD           integer,
            RGR           integer,
            NVG_HRS       numeric,
            NVG_OPS       integer,
            NVG_TO        integer,
            NVG_LD        integer,
            NVG_HOV       integer,
            NVG_DEP       integer,
            NVG_ARR       integer,
            NVG_TRAN      integer,
            CS1           numeric,
            CS2           numeric,
            CS3           numeric,
            CS4           numeric,
            CS5           numeric,
            CS6           numeric,
            CS7           numeric,
            CS8           numeric,
            CS9           numeric,
            CS10          numeric,
            CS11          numeric,
            CS12          numeric,
            CS13          numeric,
            CS14          numeric,
            CS15          numeric,
            CS16          numeric,
            CS17          numeric,
            CS18          numeric,
            CS19          numeric,
            CS20          numeric,
            CS21          numeric,
            CS22          numeric,
            CS23          numeric,
            CS24          numeric,
            CS25          numeric,
            CS26          numeric,
            CS27          numeric,
            CS28          numeric,
            CS29          numeric,
            CS30          numeric,
            CS31          numeric,
            CS32          numeric,
            CS33          numeric,
            CS34          numeric,
            CS35          numeric,
            CS36          numeric,
            CS37          numeric,
            CS38          numeric,
            CS39          numeric,
            CS40          numeric,
            CS41          numeric,
            CS42          numeric,
            CS43          numeric,
            CS44          numeric,
            CS45          numeric,
            CS46          numeric,
            CS47          numeric,
            CS48          numeric,
            CS49          numeric,
            CS50          numeric,
            CS51          numeric,
            CS52          numeric,
            CS53          numeric,
            CS54          numeric,
            CS55          numeric,
            CS56          numeric,
            CS57          numeric,
            CS58          numeric,
            CS59          numeric,
            CS60          numeric,
            CS61          numeric,
            CS62          numeric,
            CS63          numeric,
            CS64          numeric,
            CS65          numeric,
            CS66          numeric,
            CS67          numeric,
            CS68          numeric,
            CS69          numeric,
            CS70          numeric,
            CS71          numeric,
            CS72          numeric,
            CS73          numeric,
            CS74          numeric,
            CS75          numeric,
            CS76          numeric,
            CS77          numeric,
            CS78          numeric,
            CS79          numeric,
            CS80          numeric,
            CS81          numeric,
            CS82          numeric,
            CS83          numeric,
            CS84          numeric,
            CS85          numeric,
            CS86          numeric,
            CS87          numeric,
            CS88          numeric,
            CS89          numeric,
            CS90          numeric,
            CS91          numeric,
            CS92          numeric,
            CS93          numeric,
            CS94          numeric,
            CS95          numeric,
            CS96          numeric,
            CS97          numeric,
            CS98          numeric,
            CS99          numeric,
            CS100         numeric,
            CNT1          integer,
            CNT2          integer,
            CNT3          integer,
            CNT4          integer,
            CNT5          integer,
            CNT6          integer,
            CNT7          integer,
            CNT8          integer,
            CNT9          integer,
            CNT10         integer,
            DFR           text,
            IDENT         text,
            List_Type     text,
            AcType        text,
            Class         text,
            Class2        text,
            RecComment    text,
            "DATA"        text,
            "DELETED"     integer,
            primary key (Date, IDENT)
        )
    `;
    //#endregion
  }

  async delete(whereClause, values) {
    await this.createTable();

    const now = dateToStringObject(new Date());
    const q = {
      q: `update ${this.tableName} set "Modified"=${now}, "DELETED" = 1 where ${whereClause}`,
      v: values,
    };

    try {
      await this.connection.query(q);
      return true;
    } catch (e) {
      verbose.error('SQL', e);
    }

    return false;
  }
}
