class vSAMA_AbstractListViewItem extends vSAMA_AbstractFilterableItem {
  /**
   *
   * @param {string} _template
   * @param {object} _format
   * @param {boolean} [_showButtonBar]
   */
  SetUpGui(_template, _format, _showButtonBar) {
    this.dom.body = $(_template.formatTemplate(_format));
    this.dom.contents = this.dom.body.find('.sama-listItem-subHeader,.sama-listItem-badges');
    this.dom.buttonWrapper = this.dom.body.find('.sama-listItem-buttons');
    this.dom.buttonBar = this.dom.body.find('.sama-listItem-buttons > div');

    this.dom.buttonWrapper.hide();

    if (_showButtonBar !== false) {
      this.dom.body.on('click', () => {
        this.dom.buttonWrapper.stop(true, true).slideToggle(50);
        this.dom.contents.stop(true, true).slideToggle(50);
      });
    }
  }

  SetBorderColor(_color) {
    this.dom.body.css('border-left', '3px solid ' + _color);
  }

  /**
   *
   * @param {eSAMA_Button} _button
   */
  AddButton(_button) {
    _button.GetDom().appendTo(this.dom.buttonBar);
  }
}
