class vSAMA_TabRiskFactor extends vSAMA_AbstractTab {
  /**
   * @param {mSAMA_RiskFactor} _riskFactor
   * @param {boolean}          _readOnly
   */
  constructor(_riskFactor, _readOnly) {
    super(_riskFactor);

    this.SetUpGui(SAMA.Templates.tpl.riskFactor.editorRider, SAMA.Templates.tpl.riskFactor.editorTab, _readOnly);

    this.dom.body.enhanceWithin();

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    this.dom.customRepContainer = this.dom.body.find('.sama-custRepContainer');
    this.dom.newCustFieldButton = this.dom.body.find('.sama-btn-newCustField');
    this.dom.newCustFieldButton.on('click.samaevt', () => this.AddCustomRepFieldEditor());

    let customFields = _riskFactor.customFields;
    for (let cf = 0; cf < customFields.length; cf++) {
      let cfEdit = new vSAMA_CustomFieldEditor(customFields[cf]);
      this.Observe(cfEdit, 'DeletedCF', () => this.RemovedCustomRepFieldEditor());

      this.dom.customRepContainer.append(cfEdit.GetDom()).enhanceWithin();
    }
  }

  /**
   * Button Handler for the Add Custom Reporting Field button in the tabbed editor
   */
  AddCustomRepFieldEditor() {
    let newCf = new mSAMA_CustomField(this.model);
    let cfEdit = new vSAMA_CustomFieldEditor(newCf);

    this.Observe(cfEdit, 'DeletedCF', () => this.RemovedCustomRepFieldEditor());
    this.dom.customRepContainer.append(cfEdit.GetDom()).enhanceWithin();

    this.model.SetModified(true);
    this.model.AddCustomField(newCf);
    this.owner.Refresh();
  }

  RemovedCustomRepFieldEditor() {
    this.owner.Refresh();
  }

  OnDelete() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Delete Risk Factor',
      message:
        'Reporters will no longer be able to use this factor in new reports ' +
        'but it will still appear in existing ones. <br/>' +
        'Are you sure you want to archive it now?',
      yesFunc: () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        super.OnDelete();
      },
    });
  }
}
