/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var FOLLOW_INDEX = null;
var WB_LEG_INDEX = 0;
var RETURN_MAP = false;
var AC_UNITS = 'Imperial';

function toggleWB_TZ(e) {
  if (e.value == ITIN_TIMEZONE_UTCmode) {
    return;
  }
  ITIN_TIMEZONE_UTCmode = e.value;
  if (ITIN_TIMEZONE_UTCmode == 'Local') {
    updateWB_TZ(ITIN_TIMEZONE);
  } else {
    updateWB_TZ(-ITIN_TIMEZONE);
  }
}
function updateWB_TZ(Offset) {
  var inputs = [
    'LegStart2E',
    'LegStart2',
    'LegEnd2',
    'LegEnd2E',
    'LegStart2E_pop',
    'LegStart2_pop',
    'LegEnd2E_pop',
    'LegEnd2_pop',
  ];
  var itinDate = '2000-01-01';

  for (var i in inputs) {
    var divID = inputs[i];
    var itinTime = $('#' + divID).val();
    if (itinTime.length == 0) itinTime = '0000';
    if (itinTime.length == 1) itinTime = '000' + itinTime;
    if (itinTime.length == 2) itinTime = '00' + itinTime;
    if (itinTime.length == 3) itinTime = '000' + itinTime;
    if (itinTime.length > 4) itinTime = '0000';
    if (itinTime != '') {
      var curDateTime = itinDate + ' ' + itinTime.substr(0, 2) + ':' + itinTime.substr(2, 2) + ':00';
      var newDateTime = toUTCmode_offset(curDateTime, Offset);
      $('#' + divID).val(newDateTime.substr(11, 2) + newDateTime.substr(14, 2));
    }
  }
}

function convertWB_Time_Value(itinTime) {
  if (ITIN_TIMEZONE_UTCmode == 'Local') {
    return itinTime;
  }
  var itinDate = '2000-01-01';
  if (itinTime != '') {
    var curDateTime = itinDate + ' ' + itinTime.substr(0, 2) + ':' + itinTime.substr(2, 2) + ':00';
    var newDateTime = toUTCmode_offset(curDateTime, ITIN_TIMEZONE);
    return newDateTime.substr(11, 2) + newDateTime.substr(14, 2);
  } else {
    return 0;
  }
}

function ITIN_ValidateLegCfields() {
  var CF = ITIN_Cfields;
  if (CF != null) {
    if (CF.length > 0) {
      for (var i in CF) {
        var I = CF[i];
        if (!checkCfieldType('ITIN', I)) continue;
        if (I.PerLeg == 1) {
          var checked = 0;
          if (I.type == 'checkbox') {
            if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
            I.Value = checked;
            if (I.reqItin == 1 && checked == 0) {
              BillingValidation('[' + I.Label + '] is required to be checked.');
              return false;
            }
          } else {
            I.Value = $('#BI_CF_' + I.PrimaryKey).val();
            if (I.Value == undefined) I.Value = '';
            if (
              (I.Value == '' && I.reqItin == 1) ||
              (I.Value == null && I.reqItin == 1) ||
              (I.Value == ' ' && I.reqItin == 1)
            ) {
              BillingValidation('[' + I.Label + '] is a required field and is empty.');
              return false;
            }
          }
        }
      }
    }
  }
  return true;
}

async function WB_ChangeTab(tab) {
  // If the tdg tab is clicked we need to maintain the current selection so it is handled seperately
  if (tab == 'tdg') {
    if (!VueBridge.hasFeature('TransportationOfDangerousGoods')) {
      return;
    }

    const name = $('#Pilots2').val();
    const pilotEmail = PILOT_data.find((pilot) => pilot.chatname === name).username;

    const pilotCapabilitiesActionable = await hasCapabilitiesToCreateTdgManifest(pilotEmail);
    const userCapabilitiesActionable = await hasCapabilitiesToCreateTdgManifest(window.vueApp.user.id);

    if (!pilotCapabilitiesActionable || !userCapabilitiesActionable) {
      let userMissingCapabilities = '';
      if (!pilotCapabilitiesActionable && userCapabilitiesActionable) {
        userMissingCapabilities = 'pilot';
      } else if (pilotCapabilitiesActionable && !userCapabilitiesActionable) {
        userMissingCapabilities = 'user';
      } else {
        userMissingCapabilities = 'both';
      }
      showNoCapabilitiesDialogForTdgManifest(userMissingCapabilities);

      $('#WB_TDG_TAB').prop('checked', false).checkboxradio('refresh');
      return;
    }

    const itin = Follow_Data[FOLLOW_INDEX].Itin;
    const itinParam =
      window.ONLINE || itin.OfflinePK == 0 ? `itineraryId=${itin.PrimaryKey}` : `offlinePk=${itin.OfflinePK}`;
    const editParam = window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS > 0 ? `&edit=1` : '';

    VueBridge.enterVue(
      `/groups/${window.vueApp.groupKey}/dangerous-goods/manifest?${itinParam}&origin=${$(
        '#LegOrigin2'
      ).val()}&destination=${$('#LegDest2').val()}&fromLeg=1&legNumber=${
        WB_LEG_INDEX + 1
      }&pilotEmail=${pilotEmail}${editParam}`
    );

    $('#WB_TDG_TAB').prop('checked', false).checkboxradio('refresh');
    return;
  }

  $('#WB_ITIN_TAB').prop('checked', false).checkboxradio('refresh');
  $('#WB_WB_TAB').prop('checked', false).checkboxradio('refresh');
  $('#WB_RA_TAB').prop('checked', false).checkboxradio('refresh');
  $('#WB_OFP_TAB').prop('checked', false).checkboxradio('refresh');
  switch (tab) {
    case 'itin':
      $('#WB_ITIN_TAB').prop('checked', true).checkboxradio('refresh');
      GROUP_DATA.ITIN_showEngSS == 0 ? $('#WB_ITIN_showEngSS_HLDR').hide() : $('#WB_ITIN_showEngSS_HLDR').show();
      GROUP_DATA.ITIN_useRouting == 0 ? $('#WB_ITIN_Routing_HLDR').hide() : $('#WB_ITIN_Routing_HLDR').show();

      GROUP_DATA.ITIN_showETA_ETE == 0 ? $('#WB_ITIN_ETA_HLDR').hide() : $('#WB_ITIN_ETA_HLDR').show();
      GROUP_DATA.ITIN_showETA_ETE == 0 ? $('#WB_ITIN_ETE_HLDR').hide() : $('#WB_ITIN_ETE_HLDR').show();

      VueBridge.getGroupPreference('itinerary.autoLegUpdate', '1') === '1'
        ? $('#GPS_HOBBS_BUTTONS3').show()
        : $('#GPS_HOBBS_BUTTONS3').hide();

      $('#WB_WB_TAB_CONTENT').hide();
      $('#WB_RA_TAB_CONTENT').hide();
      $('#WB_OFP_TAB_CONTENT').hide();
      $('#WB_ITIN_TAB_CONTENT').show();
      break;
    case 'wb':
      $('#WB_WB_TAB').prop('checked', true).checkboxradio('refresh');
      GROUP_DATA.ITIN_showTotalCargo == 0 ? $('#WB_CARGO2').hide() : $('#WB_CARGO2').show();
      $('#WB_RA_TAB_CONTENT').hide();
      $('#WB_ITIN_TAB_CONTENT').hide();
      $('#WB_OFP_TAB_CONTENT').hide();
      $('#WB_WB_TAB_CONTENT').show();
      break;
    case 'ra':
      $('#WB_RA_TAB').prop('checked', true).checkboxradio('refresh');
      $('#WB_WB_TAB_CONTENT').hide();
      $('#WB_ITIN_TAB_CONTENT').hide();
      $('#WB_OFP_TAB_CONTENT').hide();
      $('#WB_RA_TAB_CONTENT').show();
      break;
    case 'ofp':
      $('#WB_OFP_TAB').prop('checked', true).checkboxradio('refresh');
      $('#WB_WB_TAB_CONTENT').hide();
      $('#WB_ITIN_TAB_CONTENT').hide();
      $('#WB_RA_TAB_CONTENT').hide();
      $('#WB_OFP_TAB_CONTENT').show();
      break;
  }
}
function showNoCapabilitiesDialogForTdgManifest(userWithoutCapabilities) {
  let message;
  switch (userWithoutCapabilities) {
    case 'pilot':
      message = 'The pilot does not have the capabilities required to transport dangerous goods';
      break;
    case 'user':
      message = 'You do not have the capabilities required to transport dangerous goods';
      break;
    case 'both':
      message = 'You and the pilot do not have the capabilities required to transport dangerous goods';
      break;
    default:
      message = '';
  }

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Warning'),
    headerClose: false,
    buttonPrompt:
      iTrans(message) +
      '<br>' +
      '<br>' +
      iTrans(
        'Either Ship and Transport Dangerous Goods as Cargo or Transport Dangerous Goods as Cargo and Passenger Crew is required'
      ),
    buttons: {
      [iTrans('Ok')]: {
        click: function () {},
      },
    },
  });
}
function showNoCapabilitiesDialogForPilotSelection() {
  return new Promise((resolve) => {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Warning'),
      headerClose: false,
      buttonPrompt:
        iTrans('The pilot does not have the capabilities required to transport dangerous goods') +
        '<br>' +
        '<br>' +
        iTrans(
          'Either Ship and Transport Dangerous Goods as Cargo or Transport Dangerous Goods as Cargo and Passenger Crew is required'
        ) +
        '<br>' +
        '<br>' +
        iTrans('Changing the pilot will remove existing dangerous goods manifest data'),
      buttons: {
        [iTrans('Change Pilot')]: {
          click: function () {
            resolve(false);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            resolve(true);
          },
        },
      },
    });
  });
}
function WB_UpdateETE() {
  //get ETA Time calculate hours and minutes travel time update ETE
  var now = new Date();
  var Hours = now.getUTCHours();
  var Minutes = now.getUTCMinutes();
  if (Minutes < 10) Minutes = '0' + Minutes;
  var nowTime = parseInt(Hours + '' + Minutes);
  var ETA = parseInt($('#LegETA2').val());

  var ETE = 0;
  if (nowTime > ETA) {
    ETE = 24 - dutytime_Calc(ETA, nowTime);
  } else {
    ETE = dutytime_Calc(nowTime, ETA);
  }

  $('#LegETE2').val(subDecToTime('0000', -ETE));
  var Updated = now.toISOString().substring(0, 16) + 'Z';
  Updated = Updated.replace('T', ' ');
  $('#LegETE2Time').html(Updated);
}

function WB_OldETE(DateTime) {
  var changed = DateTime.split(' ');
  var now = new Date(changed[0] + 'T' + changed[1] + 'Z');
  var Hours = now.getUTCHours();
  var Minutes = now.getUTCMinutes();
  if (Minutes < 10) Minutes = '0' + Minutes;
  var nowTime = parseInt(Hours + '' + Minutes);
  var ETA = parseInt($('#LegETA2').val());

  var ETE = 0;
  if (nowTime > ETA) {
    ETE = 24 - dutytime_Calc(ETA, nowTime);
  } else {
    ETE = dutytime_Calc(nowTime, ETA);
  }

  $('#LegETE2').val(subDecToTime('0000', -ETE));
  var Updated = now.toISOString().substring(0, 16) + 'Z';
  Updated = Updated.replace('T', ' ');
  $('#LegETE2Time').html(Updated);
}

function WB_UpdateETA() {
  //get ETE hours and minutes add to current local time and update ETA
  var now = new Date();
  var Hours = now.getUTCHours();
  var Minutes = now.getUTCMinutes();
  if (Minutes < 10) Minutes = '0' + Minutes;
  var nowTime = Hours + '' + Minutes;

  var ETE = $('#LegETE2').val();
  var Duration = dutytime_Calc('0000', ETE);
  var ETA2Time = subDecToTime(nowTime, -Duration);
  if (parseInt(ETA2Time) >= 2400) ETA2Time -= 2400;
  $('#LegETA2').val(ETA2Time);
  var Updated = now.toISOString().substring(0, 16) + 'Z';
  Updated = Updated.replace('T', ' ');
  $('#LegETE2Time').html(Updated);
}

function timeToTimeString(e) {
  e = String(e);
  if (e.length == 4) {
    return e.substring(0, 2) + ':' + e.substring(2, 4) + ':00';
  }
  if (e.length == 3) {
    return '0' + e.substring(0, 1) + ':' + e.substring(1, 3) + ':00';
  }
  if (e.length == 2) {
    return '00:' + e + ':00';
  }
  if (e.length == 1) {
    return '00:0' + e + ':00';
  }
  if (e.length == 0) {
    return '00:00:00';
  }
  return e;
}

function WB_Init() {
  SYNC_FOLLOW = true;
  WB_INIT = true;

  const unit = LOCALSTORAGE.UserUNITS === 'Imperial' ? 'Lbs' : 'Kg';
  const labelText = `${iTrans('Total Cargo')} (${unit})`;
  const inputHTML =
    '<input type="number" min="0" max="999999" id="WB_CARGO_TOTAL2" value="0" onClick="selectInputContent(this)" />';
  $('#WB_CARGO2').html(`<label for="WB_CARGO_TOTAL2">${labelText}</label> ${inputHTML}`);

  UpdateWBUnitLBL(LOCALSTORAGE.UserUNITS);
  getFollowData();
}

function NowTime(e, id = null) {
  var now = new Date();

  var minutes = now.getMinutes();
  if (minutes < 10) minutes = '0' + minutes;
  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    now.setMinutes(now.getMinutes() + ITIN_TIMEZONE);
  }
  if (id == null) $('#' + e.id).val(now.getHours() + '' + minutes);
  else $('#' + id).val(now.getHours() + '' + minutes);

  //$("#"+e.id).clockpicker('show');
}

function MobileNowTime() {
  var id = this.id;
  var now = new Date();

  var minutes = now.getMinutes();
  if (minutes < 10) minutes = '0' + minutes;
  $('#' + id).val(now.getHours() + '' + minutes);

  $('#' + id).one('touchend', function (e) {
    setTimeout(function () {
      $('#' + id).numpad('close');
    }, 500);
  });
}

function WB_fileaclist(popOnly) {
  popOnly = popOnly || false;
  WB_compareOpen(popOnly);
}

function WB_updateNavBTN(enabled) {
  $('#WB_PREV_BTN').prop('disabled', !enabled);
  $('#WB_NEXT_BTN').prop('disabled', !enabled);
}

function WB_Leg_Prev() {
  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    VueBridge.TdgManifestUpdate();
  }

  if (!ITIN_ValidateLegCfields()) return;
  if (WB_LEG_INDEX > 0) {
    WB_updateNavBTN(false);
    UpdateWB(true, 'prev');
  } else return;
}

function WB_Leg_Prev2() {
  WB_LEG_INDEX--;
  FC_UpliftLegSelect();
  SCH_DATA.LegIndex = WB_LEG_INDEX;
  MEASURE.CurLeg = WB_LEG_INDEX;
  if (RAform != null) {
    RAform.CurLeg--;
    RAoperation();
  }
  try {
    MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[MEASURE.CurLeg]));
  } catch (e) {
    MEASURE.geojson = {
      type: 'FeatureCollection',
      features: [],
    };
  }
  populateLegDetails('WB_OFP_LegHLDR', true);
  //$("#WB_OFP_LegHLDR").html(getFlightPlanDetails2(MEASURE, true));
  LoadWBLeg('prev');
  UpdateNextLegText();
}
function UpdateNextLegText() {
  if (WB_LEG_INDEX == Follow_Data[FOLLOW_INDEX].WB.length - 1) {
    $('#WB_NEXT_BTN_HTML').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
    $('#WB_NEXT_BTN_HTML_pop').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
    //$("#LegETA2").prop("disabled", false); //don't disable might need to update pre-populated legs
    //$("#LegETAE").prop("disabled", false);
  } else {
    $('#WB_NEXT_BTN_HTML').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
    $('#WB_NEXT_BTN_HTML_pop').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
    //$("#LegETA2").prop("disabled", true);
    //$("#LegETE2").prop("disabled", true);
  }
  FC_UpliftLegSelect();
}

function WB_Leg_Next() {
  if (!ITIN_ValidateLegCfields()) return;
  WB_updateNavBTN(false);
  UpdateWB(true, 'next');

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    VueBridge.TdgManifestUpdate();
  }
}

function WB_setEnabledStateOfTDG(enabled) {
  const elem = $('#WB_TDG_TAB');
  elem.prop('disabled', !enabled);

  const parent = elem.parent();
  if (enabled) {
    parent.removeClass('ui-state-disabled');
    return;
  }

  parent.addClass('ui-state-disabled');
}

function WB_Leg_Save() {
  UpdateWB();

  if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
    VueBridge.TdgManifestUpdate();
  }
}

function WB_Leg_Next2() {
  WB_LEG_INDEX++;
  FC_UpliftLegSelect();
  SCH_DATA.LegIndex = WB_LEG_INDEX;
  MEASURE.CurLeg = WB_LEG_INDEX;
  if (MEASURE.LEGS[SCH_DATA.LegIndex] == undefined) {
    if (MEASURE.LEGS.length == 0) {
      resetMeasureData();
    }
    var LastLeg = MEASURE.LEGS[MEASURE.LEGS.length - 1];
    MEASURE.LEGS[SCH_DATA.LegIndex] = {
      type: 'FeatureCollection',
      features: [JSON.parse(JSON.stringify(LastLeg.features[LastLeg.features.length - 1]))],
    };
  }
  try {
    MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[MEASURE.CurLeg]));
  } catch (e) {
    MEASURE.geojson = {
      type: 'FeatureCollection',
      features: [],
    };
  }
  populateLegDetails('WB_OFP_LegHLDR', true);
  //$("#WB_OFP_LegHLDR").html(getFlightPlanDetails2(MEASURE, true));

  if (RAform != null) {
    RAform.CurLeg++;
    RAoperation();
  }
  LoadWBLeg('next');
  if (WB_LEG_INDEX >= Follow_Data[FOLLOW_INDEX].WB.length - 1) {
    $('#WB_NEXT_BTN_HTML').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
    $('#WB_NEXT_BTN_HTML_pop').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
    $('#LegETA2').prop('disabled', false);
    $('#LegETE2').prop('disabled', false);
    //UpdateWB(true);
  } else {
    $('#WB_NEXT_BTN_HTML').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
    $('#WB_NEXT_BTN_HTML_pop').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
    $('#LegETA2').prop('disabled', true);
    $('#LegETE2').prop('disabled', true);
  }
  if (Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX] == undefined) {
    //New leg has not been saved yet so auto save it now
    $('#LegComments2').val('');
    UpdateWB(true, 'none');
  }
}

function LoadWBLeg(action) {
  var e = WB_LEG_INDEX;
  if (Follow_Data[FOLLOW_INDEX].AC.StartStop != undefined && Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
    $('#LegStartEHLDR2').show();
    $('#LegEndEHLDR2').show();
  } else {
    $('#LegStartEHLDR2').hide();
    $('#LegEndEHLDR2').hide();
  }
  var time = new Date();
  ITIN_Cfields = JSON.parse(Follow_Data[FOLLOW_INDEX].Itin.Cfields);

  var I;
  if (
    (WB_LEG_INDEX < Follow_Data[FOLLOW_INDEX].WB.length && action == 'prev') ||
    (WB_LEG_INDEX <= Follow_Data[FOLLOW_INDEX].WB.length - 1 && action == 'next')
  ) {
    FUEL_data = '';
    eacweight = '';
    eLat = '';
    eLong = '';
    ecofgLat = '';
    ecofgLong = '';
    error = '';
    searchtime = '';
    FuelType = 'Standard';
    FuelStandard = 'Standard';
    SeatType = 'Standard';
    SeatStandard = 'Standard';
    GraphName = 'Standard';
    GraphStandard = 'Standard';
    Loading = [];
    optLoad = [];
    tcNum = 0;
    tcIndex = '';
    PaXnum = 0;
    SN = '';

    $('#WB_OPT2').html('');
    $('#TCNUM2').html('');
    //$("#LongCanvas2").html("");
    //$("#LatCanvas2").html("");
    $('#Seating2').html('');
    $('#fuelLoad2').html('');
    $('#bagLoad2').html('');
    $('#optLoad2').html('');
    $('#PaX2').html('');
    $('#LegETA2Time').html(time.toISOString().substring(11, 16) + 'Z');
    //$("#WB_NEXT_BTN").prop("disabled",false);
    TCNUM = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].tempconfig;
    maxperfweight = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].maxperfweight;
    $('#AC_MAX_PERF2').val(maxperfweight);
    var Cfields = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].Cfields;

    if (Cfields == null) {
      ITIN_Cfields = JSON.parse(Follow_Data[FOLLOW_INDEX].Itin.Cfields);
      WB_NewLegCfields();
    } else {
      ITIN_Cfields = Cfields;
      WB_PopLegCfields(Cfields);
    }

    WB_initialize(TCNUM);
    //WB_getActiveTC(acIdent);
  } else {
    error = '';
    //$("#WB_NEXT_BTN").prop("disabled",true);

    var ITEMS = Follow_Data[FOLLOW_INDEX].WB[Follow_Data[FOLLOW_INDEX].WB.length - 1];
    $('#LegStart2E').val('0');
    $('#LegStart2').val('0');
    $('#LegEnd2').val('0');
    $('#LegEnd2E').val('0');
    $('#LegStart2E_pop').val('0');
    $('#LegStart2_pop').val('0');
    $('#LegEnd2_pop').val('0');
    $('#LegEnd2E_pop').val('0');
    $('#LegETA2').val('0000');
    $('#LegETA2Time').html(time.toISOString().substring(11, 16) + 'Z');
    $('#LegETE2').val('0000');
    $('#LegOrigin2').val(ITEMS.LegDest);
    $('#LegRouting2').val('');
    $('#LegDest2').val('');
    $('#noShutdown2').prop('checked', false).checkboxradio('refresh');
    checkItinNoShutdown();
    $('#LegPassengerCount2').val(ITEMS.PaxNum);
    $('#LegComments2').val(br2nl(ITEMS.Description));
    $('#WB_LegNum').html('Leg ' + (WB_LEG_INDEX + 1) + ' (New Active) ' + '<span id="itinTotalFT"></span>');
    getFlightTotals();
    $('#WB_LegNum_pop').html('Leg ' + (WB_LEG_INDEX + 1) + ' (New Active)');
    $('#AC_MAX_PERF2').val(ITEMS.maxperfweight);
    if (GROUP_DATA.Pax_Man == 1) {
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
      var oldScroll = 0;
      ITIN_Pax_Manifest.Scheduler = false;
      if (ITIN_Pax_Manifest.scrollTo != undefined) oldScroll = ITIN_Pax_Manifest.scrollTo;
      ITIN_Pax_Manifest = Follow_Data[FOLLOW_INDEX].Itin.Pax_Manifest;
      ITIN_Pax_Manifest.ClientPK = Follow_Data[FOLLOW_INDEX].ClientPK;
      ITIN_Pax_Manifest.briefingLock = Follow_Data[FOLLOW_INDEX].AC.briefingLock;
      ITIN_Pax_Manifest.briefingReq = Follow_Data[FOLLOW_INDEX].AC.briefingReq;
      ITIN_Pax_Manifest.Actype = Follow_Data[FOLLOW_INDEX].AC.AcType;
      ITIN_Pax_Manifest.ident = Follow_Data[FOLLOW_INDEX].AC.ident;
      ITIN_Pax_Manifest.CurLeg = WB_LEG_INDEX;
      ITIN_Pax_Manifest.scrollTo = oldScroll;
      PAX_Init_ITIN_Manifesting(WB_LEG_INDEX); //in menu-PaxManifesting.js
    }
    WB_NewLegCfields();
  }
}

function WB_LoadSummaryValues(Summary) {
  var WBS = Summary.split(',');
  for (var w in WBS) {
    var W = WBS[w];
    if (W != null) {
      var LID = W.split(':');
      var LWT = parseFloat(LID[1]);
      if (LID[2] != undefined) {
        //RESTORE VARIABLE ARM ITEM*************************************************
        //****************************************************
        var varItem = $('#' + LID[0])[0];
        varItem.dataset.longdefault = LID[2];
        varItem.dataset.latdefault = LID[3];

        if ($('#WBstations').is(':checked')) {
          $("label[for='" + LID[0] + "']").html(
            '<font size="-2">' +
              LID[0] +
              '<br />' +
              convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
              '<div class="WBSTATION" style="display: block">' +
              LID[2] +
              ' | ' +
              LID[3] +
              '</div></font>'
          );
        } else {
          $("label[for='" + LID[0] + "']").html(
            '<font size="-2">' +
              LID[0] +
              '<br />' +
              convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
              '<div class="WBSTATION">' +
              LID[2] +
              ' | ' +
              LID[3] +
              '</div></font>'
          );
        }
        $("input[id='" + LID[0] + "']").val(LWT);
      } else {
        $("label[for='" + LID[0] + "']").html(
          '<font size="-2">' + LID[0] + '<br />' + convertACToUserUnits(LWT, 'Weight', AC_UNITS)
        );
      }
      $("input[id='" + LID[0] + "']").val(LWT);
    }
  }
}

function WB_NewLegCfields() {
  $('#ITIN_LEG_CFIELDS').html('');
  $('#ITIN_LEG_CFIELDS2').html('');
  $('#editItinCfields').html('');
  var html = '';
  var CF = ITIN_Cfields;
  var NewCF = [];
  if (CF.length > 0) {
    html = '<h3>Additional Fields</h3>';
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        html += createCustomField('', I, false, true);
        NewCF.push(CF);
      }
    }
  }
  $('#ITIN_LEG_CFIELDS2').html(html);
  $('#ITIN_LEG_CFIELDS2').enhanceWithin();
}

function WB_PopLegCfields(CF) {
  $('#ITIN_LEG_CFIELDS').html('');
  $('#editItinCfields').html('');
  var html = '';
  if (CF.length > 0) {
    html = '<h3>Additional Fields</h3>';
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        html += createCustomField('', I, I.Value, true);
      }
    }
  }
  $('#ITIN_LEG_CFIELDS2').html(html);
  $('#ITIN_LEG_CFIELDS2').enhanceWithin();
}
function WB_compareOpen(newLeg) {
  //alert("triggered");
  EditWBFromItin = false;
  newLeg = newLeg || false;
  //reset variables
  //$.mobile.changePage( $("#AC_Page"), {  changeHash: false });
  $('#WB_Content').html('');
  cirroDB.query('AC', 'ident != ?', '', function (e) {
    ALLAC_data = [];
    if (e === false) e = [];
    else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data.push(item);
      }
    }

    $('#WB_Content').html('');
    var RecentAC = [];
    if (LOCALSTORAGE.RecentAC != undefined) RecentAC = LOCALSTORAGE.RecentAC;
    else localStorageDB.setItem('RecentAC', '[]');

    $('#WB_Content').html('<div id="WB_RecentList"></div>');
    $('#WB_RecentList').append(
      '<h3 class="limit-table">Recent Aircraft</h3><div style="width: 100%; overflow: auto"><table id="AC_Table_Itinerary_Recent2" class="last-td-fill limit-table nowrap wrap-description">' +
        '<thead>' +
        '<tr>' +
        '<th data-priority="0">Select</th>' +
        '<th data-priority="1">Ident</th>' +
        '<th data-priority="2">Model</th>' +
        '<th data-priority="2">Status</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody><tr>'
    );

    $('#WB_Content').append(
      '<h3 class="limit-table">Aircraft List</h3><div style="width: 100%; overflow: auto"><table id="AC_Table_Itinerary2" data-filter="true" class="last-td-fill limit-table nowrap wrap-description">' +
        '<thead>' +
        '<tr>' +
        '<th data-priority="0">Select</th>' +
        '<th data-priority="1">Ident</th>' +
        '<th data-priority="2">Model</th>' +
        '<th data-priority="2">Status</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody><tr>'
    );

    MAP_NEW_LEG = false;
    var HasEditableWB = false;
    var ShowRecent = false;
    for (i in ALLAC_data) {
      var Iopen = false;
      var pilot = false;
      var status = null;
      var X = null;
      for (x in Follow_Data) {
        if (Follow_Data[x].Itin != null) {
          if (ALLAC_data[i].ident == Follow_Data[x].Itin.ident) {
            if (Follow_Data[x].Itin.pilot == chatname || Follow_Data[x].Itin.sic == chatname) {
              pilot = true;
              ShowRecent = true;
            }
            status = Follow_Data[x].Itin.status;
            Iopen = true;
            X = x;
          }
        }
      }
      var Recent = false;
      var RecentList = [];
      for (var a in RecentAC) {
        if (RecentAC[a] == ALLAC_data[i].ident) {
          Recent = true;
          ShowRecent = true;
        }
      }
      var ACD = ALLAC_data[i];
      if (ACD.Hibrenated == 1) {
        if (!Recent)
          $('#WB_Content tr:last').after(
            "<tr><td><button id='WBAC" +
              i +
              "' name='ITIN-" +
              X +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' disabled>Hibernated</button></td><td>" +
              ALLAC_data[i].ident +
              '</td><td>' +
              ALLAC_data[i].AcType +
              '</td><td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        else
          $('#WB_RecentList tr:last').after(
            "<tr><td><button id='WBAC" +
              i +
              "' name='ITIN-" +
              X +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='cirro-btn' disabled>Hibernated</button></td><td>" +
              ALLAC_data[i].ident +
              '</td><td>' +
              ALLAC_data[i].AcType +
              '</td><td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        continue;
      }
      if (ACD.Serviceable == 0) {
        if (!Recent)
          $('#WB_Content tr:last').after(
            "<tr><td><button id='WBAC" +
              i +
              "' name='ITIN-" +
              X +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='redbg cirro-btn' disabled>Non Serviceable</button></td><td>" +
              ALLAC_data[i].ident +
              '</td><td>' +
              ALLAC_data[i].AcType +
              '</td><td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        else
          $('#WB_RecentList tr:last').after(
            "<tr><td><button id='WBAC" +
              i +
              "' name='ITIN-" +
              X +
              "' value='" +
              ALLAC_data[i].ident +
              "' data-mini='true' class='redbg cirro-btn' disabled>Non Serviceable</button></td><td>" +
              ALLAC_data[i].ident +
              '</td><td>' +
              ALLAC_data[i].AcType +
              '</td><td>' +
              ACD.ServiceReason +
              '</td></tr>'
          );
        continue;
      }
      if (Iopen && LITE == 0 && WANDB == 1) {
        if (pilot || USER_RIGHTS.EditItinAccess == 1 || USER_RIGHTS.SuperUser == 1) {
          if (ALLAC_data[i].AcType == 'CUSTOM') {
            if (!Recent)
              $('#WB_Content tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='UPDATE-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Updatable</div></button></td><td>" +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].Custom_Type +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
            else
              $('#WB_RecentList tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='UPDATE-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Updatable</div></button></td><td>" +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].Custom_Type +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
          } else {
            if (!Recent)
              $('#WB_Content tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='UPDATE-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Updatable</div></button></td><td>" +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].AcType +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
            else
              $('#WB_RecentList tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='UPDATE-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Updatable</div></button></td><td>" +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].AcType +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
          }

          if (pilot) {
            if (MAP_NEW_LEG == false) {
              $('#NewLegControl').unbind('click', WB_fileaclistSelect);
              $('#NewLegControl').attr('name', 'UPDATE-' + X);
              $('#NewLegControl').attr('value', ALLAC_data[i].ident);
              $('#NewLegControl').click(WB_fileaclistSelect);
              //alert("added attribute");
              $('#StartStopControl').unbind('click', StartStopTime);
              $('#StartStopControl').attr('name', 'UPDATE-' + X);
              $('#StartStopControl').attr('value', ALLAC_data[i].ident);
              $('#StartStopControl').click(StartStopTime);
            }
            MAP_NEW_LEG = true;
          }
          HasEditableWB = true;
        } else {
          if (ALLAC_data[i].AcType == 'CUSTOM') {
            if (!Recent)
              $('#WB_Content tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='ITIN-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
                  status +
                  ' Itinerary</div></button></td><td>' +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].Custom_Type +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
            else
              $('#WB_RecentList tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='ITIN-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
                  status +
                  ' Itinerary</div></button></td><td>' +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].Custom_Type +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
          } else {
            if (!Recent)
              $('#WB_Content tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='ITIN-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
                  status +
                  ' Itinerary</div></button></td><td>' +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].AcType +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
            else
              $('#WB_RecentList tr:last').after(
                "<tr><td><button id='WBAC" +
                  i +
                  "' name='ITIN-" +
                  X +
                  "' value='" +
                  ALLAC_data[i].ident +
                  "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> " +
                  status +
                  ' Itinerary</div></button></td><td>' +
                  ALLAC_data[i].ident +
                  '</td><td>' +
                  ALLAC_data[i].AcType +
                  '</td><td>' +
                  ACD.ServiceReason +
                  '</td></tr>'
              );
          }
        }
      } else {
        if (ALLAC_data[i].AcType == 'CUSTOM') {
          if (!Recent)
            $('#WB_Content tr:last').after(
              "<tr><td><button id='WBAC" +
                i +
                "' name='BLANK-" +
                i +
                "' value='" +
                ALLAC_data[i].ident +
                "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Select</div></button></td><td>" +
                ALLAC_data[i].ident +
                '</td><td>' +
                ALLAC_data[i].Custom_Type +
                '</td><td>' +
                ACD.ServiceReason +
                '</td></tr>'
            );
          else
            $('#WB_RecentList tr:last').after(
              "<tr><td><button id='WBAC" +
                i +
                "' name='BLANK-" +
                i +
                "' value='" +
                ALLAC_data[i].ident +
                "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Select</div></button></td><td>" +
                ALLAC_data[i].ident +
                '</td><td>' +
                ALLAC_data[i].Custom_Type +
                '</td><td>' +
                ACD.ServiceReason +
                '</td></tr>'
            );
        } else {
          if (!Recent)
            $('#WB_Content tr:last').after(
              "<tr><td><button id='WBAC" +
                i +
                "' name='BLANK-" +
                i +
                "' value='" +
                ALLAC_data[i].ident +
                "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Select</div></button></td><td>" +
                ALLAC_data[i].ident +
                '</td><td>' +
                ALLAC_data[i].AcType +
                '</td><td>' +
                ACD.ServiceReason +
                '</td></tr>'
            );
          else
            $('#WB_RecentList tr:last').after(
              "<tr><td><button id='WBAC" +
                i +
                "' name='BLANK-" +
                i +
                "' value='" +
                ALLAC_data[i].ident +
                "' data-mini='true' class='cirro-btn' ><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> Select</div></button></td><td>" +
                ALLAC_data[i].ident +
                '</td><td>' +
                ALLAC_data[i].AcType +
                '</td><td>' +
                ACD.ServiceReason +
                '</td></tr>'
            );
        }
      }
      $('#WBAC' + i).click(WB_fileaclistSelect);
    }
    if (!MAPSTATE.Measuring) {
      if (HasEditableWB && !MEASURE.EditExisting) {
        LoadFollowDataFuelPlan();
      }
      if (!HasEditableWB && MEASURE.EditExisting) {
        resetMeasureData();
      }
    }
    if (!newLeg) {
      $('#WB_Content tr:last').after('</tbody></table></div>');
    } else {
      if (MAP_NEW_LEG) {
        if (!MAPSTATE.Measuring == true) {
          $('#NewLegControl').prop('disabled', false);
          $('#StartStopControl').prop('disabled', false);
        }
      } else {
        $('#NewLegControl').prop('disabled', true);
        $('#StartStopControl').prop('disabled', true);
      }
    }
    if (!ShowRecent) $('#WB_RecentList').html('');
    $('#WB_Content').enhanceWithin();
    AddTableSorter('AC_Table_Itinerary2');
    return;
  });
}

function exitOfflineWB() {
  if (WB_UPDATE == false) {
    if (EditWBFromItin == false) {
      if (SCH_FOLLOWING_EDIT.isEditing) {
        $.mobile.changePage($('#Schedule_Page'), {
          reverse: true,
          changeHash: false,
        });
        SCH_PopulateSCH_Aircraft();
      } else {
        $.mobile.changePage($('#WB_Page'), {
          reverse: true,
          changeHash: false,
        });
      }
    } else {
      if (SCH_FOLLOWING_EDIT.isEditing) {
        $.mobile.changePage($('#Schedule_Page'), {
          reverse: true,
          changeHash: false,
        });
        SCH_PopulateSCH_Aircraft();
      } else {
        $.mobile.changePage($('#Following_Page'), {
          reverse: true,
          changeHash: false,
        });
      }
    }
    return;
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Any unsaved changes on this leg will be lost!<br />Are you sure?',
    buttons: {
      Yes: {
        click: function () {
          $('#exitWBPage2').unbind('click', exitOfflineWB);
          if (EditWBFromItin == false && !SCH_FOLLOWING_EDIT.isEditing) {
            $.mobile.changePage($('#WB_Page'), {
              reverse: true,
              changeHash: false,
            });
          } else {
            if (SCH_FOLLOWING_EDIT.isEditing) {
              $.mobile.changePage($('#Schedule_Page'), {
                reverse: true,
                changeHash: false,
              });
              SCH_PopulateSCH_Aircraft();
            } else {
              $.mobile.changePage($('#Following_Page'), {
                reverse: true,
                changeHash: false,
              });
            }
          }

          if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
            TdgManifestClearForLegs();
          }
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function exitOfflineWBmapb() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Any unsaved changes on this leg will be lost!<br />Are you sure?',
    buttons: {
      Yes: {
        click: function () {
          $('#exitWBPage2').unbind('click', exitOfflineWBmapb);
          returnToMapB();

          if (VueBridge.hasFeature('TransportationOfDangerousGoods')) {
            TdgManifestClearForLegs();
          }
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function TdgManifestClearForLegs() {
  if (!window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS) {
    return;
  }

  VueBridge.TdgManifestClear(window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS);
  window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS = [];
}

var WB_LOAD, WB_UPDATE;

function showWBSummary2() {
  $.mobile.changePage($('#WB_Page3'), {
    reverse: false,
    changeHash: false,
  });
}

function exitWBSummary2() {
  $.mobile.changePage($('#WB_Page2'), {
    reverse: true,
    changeHash: false,
  });
}

function StartStopTime() {
  var name = this.name;
  $('#AC_MAX_PERF2').val(''); //so it will be automatically set at max gross when initialized
  name = name.split('-');
  if (name[0] == 'UPDATE') {
    WB_UPDATE = true;
    WB_LOAD = parseInt(name[1]);
    //show leg fields

    acIdent = this.value;
    for (var Z in Follow_Data) {
      if (acIdent == Follow_Data[Z].AC.ident) {
        FOLLOW_INDEX = Z;
        if (Follow_Data[Z].WB != null) {
          WB_LEG_INDEX = Follow_Data[Z].WB.length - 1;
          for (var I in Follow_Data[Z].WB) {
            if (Follow_Data[Z].WB[I].Active == 1) {
              WB_LEG_INDEX = parseInt(I);
              //alert(I);
            }
          }
        } else WB_LEG_INDEX = -1;
        break;
      }
    }

    if (Follow_Data[FOLLOW_INDEX].WB !== null) {
      var ITEMS = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX];
      var WBS = ITEMS.Summary;

      if (Follow_Data[FOLLOW_INDEX].AC.StartStop != undefined && Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
        $('#LegStartEPOP').show();
        $('#LegEndEPOP').show();
      } else {
        $('#LegStartEPOP').hide();
        $('#LegEndEPOP').hide();
      }
      $('#WB_LegNum_pop').html('Leg ' + (WB_LEG_INDEX + 1) + ' (Active)');
      var time = new Date();
      $('#LegETA2Time_pop').html(time.toISOString().substring(11, 16) + 'Z');
      $('#LegStart2E_pop').val(ITEMS.LegStartE);
      $('#LegStart2_pop').val(ITEMS.LegStart);
      $('#LegEnd2_pop').val(ITEMS.LegEnd);
      $('#LegEnd2E_pop').val(ITEMS.LegEndE);
      $('#LegETA2_pop').val(ITEMS.LegETA);
      $('#noShutdown2').prop('checked', ITEMS.noShutdown == 1 ? true : false);
      try {
        $('#noShutdown2').checkboxradio('refresh');
      } catch (e) {
        //do nothing
      }
      checkItinNoShutdown();
      $('#AC_MAX_PERF2').val(ITEMS.maxperfweight);

      if (ITEMS.Cfields == null) {
        ITIN_Cfields = JSON.parse(Follow_Data[FOLLOW_INDEX].Itin.Cfields);
        WB_NewLegCfields();
      } else {
        if (ITEMS.Cfields.length == 0) {
          ITIN_Cfields = JSON.parse(Follow_Data[FOLLOW_INDEX].Itin.Cfields);
          WB_NewLegCfields();
        } else {
          ITIN_Cfields = ITEMS.Cfields;
          WB_PopLegCfields(ITEMS.Cfields);
        }
      }
      $('#StartStopPanel').popup('open', {
        transition: 'turn',
      });
    }

    AC_data = '';
    TC_data = '';
    WB_data = '';
    PILOT_data = [];
    LOADABLE_data = '';
    FUEL_data = '';
    eacweight = '';
    eLat = '';
    eLong = '';
    ecofgLat = '';
    ecofgLong = '';
    error = '';
    searchtime = '';
    FuelType = 'Standard';
    FuelStandard = 'Standard';
    SeatType = 'Standard';
    SeatStandard = 'Standard';
    GraphName = 'Standard';
    GraphStandard = 'Standard';
    Loading = [];
    optLoad = [];
    tcNum = 0;
    tcIndex = '';
    PaXnum = 0;
    SN = '';

    $('#WB_OPT2').html('');
    $('#TCNUM2').html('');
    $('#LongCanvas2').html('');
    $('#LatCanvas2').html('');
    $('#Seating2').html('');
    $('#fuelLoad2').html('');
    $('#bagLoad2').html('');
    $('#optLoad2').html('');
    $('#Pilots2').empty();
    $('#SICs2').empty();
    $('#PaX2').html('');

    $('#WB_Ident2').html(acIdent);

    $('#WB_flightCatID').empty();
    $('#WB_flightCatID').append($('<option />').val(0).text('[Not Assigned]'));
    if (GROUP_DATA.FlightCategories.length > 0) {
      $('#WB_flightCatIDHLDR').show();
      for (var i in GROUP_DATA.FlightCategories) {
        var fc = GROUP_DATA.FlightCategories[i];
        $('#WB_flightCatID').append(
          $('<option />')
            .val(fc.PrimaryKey)
            .text((fc.fltcatcode == null ? '' : '[' + fc.fltcatcode + '] ') + fc.fltcatdesc)
        );
      }
    } else {
      $('#WB_flightCatIDHLDR').hide();
    }

    cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
      var data = e[0];

      TC_data = data.TC_data;
      WB_data = data.WB_Data;
      LOADABLE_data = data.LOADABLE_data;
      AC_data = data.AC_data;
      $('#WB_EQHTML2').html('<b>Aircraft Temp Config Rebuild Required!</b>');
      if (AC_data.EQ_HTML != undefined) $('#WB_EQHTML2').html(AC_data.EQ_HTML);
      PilotsTable.query()
        .select()
        .then(function (e) {
          PILOT_data = e;
          $('#SICs2').empty();
          $('#Pilots2').empty();
          $('#SICs2').append($('<option />').val('').text('None'));
          for (i in PILOT_data) {
            $('#Pilots2').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
            $('#SICs2').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
          }
          if (WB_LEG_INDEX == -1) WB_getActiveTC(acIdent);
          else {
            TCNUM = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].tempconfig;
            WB_initialize(TCNUM);
          }
        });
    });
  }
}
function NOWupdateStartStop(i) {
  var time = new Date();
  var todisplay = '';

  if (time.getHours() < 10) todisplay += time.getHours();
  else todisplay += time.getHours();

  if (time.getMinutes() < 10) todisplay += '0' + time.getMinutes();
  else todisplay += '' + time.getMinutes();

  switch (i) {
    case 1:
      $('#LegStart2E_pop').val(todisplay);
      break;
    case 2:
      $('#LegStart2_pop').val(todisplay);
      break;
    case 3:
      $('#LegEnd2_pop').val(todisplay);
      break;
    case 4:
      $('#LegEnd2E_pop').val(todisplay);
      break;
  }
  updateStartStop(true);
}
function updateStartStop(e) {
  //Transfer values to original form taht isn't visible right now
  $('#LegStart2E').val($('#LegStart2E_pop').val());
  $('#LegStart2').val($('#LegStart2_pop').val());
  $('#LegEnd2').val($('#LegEnd2_pop').val());
  $('#LegEnd2E').val($('#LegEnd2E_pop').val());
  $('#LegETA2').val($('#LegETA2_pop').val());
  //call the silent save stuff
  $('#LegStart2E_pop').blur();
  $('#LegStart2_pop').blur();
  $('#LegEnd2_pop').blur();
  $('#LegEnd2E_pop').blur();

  UpdateWB(true);
}
function WB_fileaclistSelect(e = false) {
  if (e.index == undefined) {
    var OverrideLegIndex = null;

    acIdent = this.value;
    var name = this.name;
    name = name.split('-');
    var GoAction = name[0];
    var LoadIndex = name[1];
  } else {
    acIdent = Follow_Data[e.index].AC.ident;
    var GoAction = 'UPDATE';
    var LoadIndex = e.index;
    var OverrideLegIndex = MEASURE.CurLeg;
  }

  $('#coordinates').hide();
  $('#WB_NEXT_BTN').prop('disabled', false);
  $('#showWBSummaryPage2').unbind('click', showWBSummary2);
  $('#showWBSummaryPage2').click(showWBSummary2);
  $('#exitWB_Page3').unbind('click', exitWBSummary2);
  $('#exitWB_Page3').click(exitWBSummary2);

  WB_LOAD = false;
  WB_UPDATE = false;

  maxperfweight = '';
  if (GoAction == 'UPDATE') {
    WB_UPDATE = true;
    WB_LOAD = parseInt(LoadIndex);
    //show leg fields
    $('#WB_CREW2').show();
    $('#WB_LEGS2').show();
    GROUP_DATA.ITIN_showTotalCargo == 0 ? $('#WB_CARGO2').hide() : $('#WB_CARGO2').show();
    $('#WB_LEG_NAV').show();
    $('#GPS_HOBBS_BUTTONS1').show();
    $('#GPS_HOBBS_BUTTONS2').show();
    $('#GPS_HOBBS_BUTTONS3').show();
  }
  if (GoAction == 'ITIN') {
    WB_LOAD = parseInt(LoadIndex);
    //hide leg fields
    $('#WB_CREW2').hide();
    $('#WB_LEGS2').hide();
    $('#WB_CARGO2').hide();
    $('#WB_LEG_NAV').hide();
    $('#GPS_HOBBS_BUTTONS1').hide();
    $('#GPS_HOBBS_BUTTONS2').hide();
    $('#GPS_HOBBS_BUTTONS3').hide();
    $('#AC_MAX_PERF2').val(''); //so it will be automatically set at max gross when initialized
  }
  if (GoAction == 'BLANK') {
    //hide leg fields
    $('#WB_CREW2').hide();
    $('#WB_LEGS2').hide();
    $('#WB_CARGO2').hide();
    $('#WB_LEG_NAV').hide();
    $('#GPS_HOBBS_BUTTONS1').hide();
    $('#GPS_HOBBS_BUTTONS2').hide();
    $('#GPS_HOBBS_BUTTONS3').hide();
    $('#AC_MAX_PERF2').val(''); //so it will be automatically set at max gross when initialized
  }

  var RecentAC = [];
  if (LOCALSTORAGE.RecentAC != undefined) RecentAC = LOCALSTORAGE.RecentAC;

  RecentAC.unshift(acIdent);
  RecentAC.length = 3;
  localStorageDB.setItem('RecentAC', JSON.stringify(RecentAC));

  RAform = null;
  var HasItin = false;
  var HasFuelPlan = false;
  resetFC_Uplifts();
  for (var Z in Follow_Data) {
    if (acIdent == Follow_Data[Z].AC.ident) {
      FOLLOW_INDEX = Z;
      if (Follow_Data[Z].WB != null) {
        if (OverrideLegIndex == null) {
          WB_LEG_INDEX = 0;
          for (var I in Follow_Data[Z].WB) {
            if (Follow_Data[Z].WB[I].Active == 1) {
              WB_LEG_INDEX = parseInt(I);
            }
          }
        } else {
          WB_LEG_INDEX = OverrideLegIndex;
        }
        try {
          maxperfweight = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].maxperfweight;
        } catch (e) {
          maxperfweight = '';
        }
      } else WB_LEG_INDEX = -1;
      if (Follow_Data[Z].Itin != null) {
        HasItin = true;
        ITIN_TIMEZONE = getTZ_Offset(Follow_Data[Z].Itin.EntryTimezone);
        ITIN_ENTRY_TIMEZONE = Follow_Data[Z].Itin.EntryTimezone;
        $('#WB_UTCmode_LocalLBL').html(getLocalTZLabel(Follow_Data[Z].Itin.EntryTimezone));
        if (GROUP_DATA.Risk_Mgmt == 1 && Follow_Data[Z].Itin.RA_Data != null) {
          RAform = Follow_Data[Z].Itin.RA_Data;
          RAform.HidePerFlight = 1;
          RAform.HidePerLeg = 0;
          if (WB_LEG_INDEX != -1) RAform.CurLeg = WB_LEG_INDEX;
          else RAform.CurLeg = 0;
        }
        if (GROUP_DATA.FuelCache == 1 && GROUP_DATA.FC_Itin_Capture == 1) {
          if (Follow_Data[Z].Itin.FC_Uplifts != undefined) {
            FC_Uplift.upLiftList = JSON.parse(JSON.stringify(Follow_Data[Z].Itin.FC_Uplifts));
          } else {
            FC_UpliftSelected(0);
          }
          FC_Uplift.div = 'EditItinFuelUplift';
          FC_Uplift.legIndex = WB_LEG_INDEX;
          FC_Uplift.curTarget = 'EditItinerary';
        }
      }
      if (Follow_Data[Z].FuelPlan != null) {
        HasFuelPlan = true;
        SCH_DATA.LegIndex = WB_LEG_INDEX;
        MEASURE.CurLeg = WB_LEG_INDEX;
        FuelPlans = Follow_Data[Z].FuelPlan;
      }
      break;
    }
  }
  if (HasItin) UpdateNextLegText();
  if (Follow_Data[FOLLOW_INDEX] != undefined) {
    if (Follow_Data[FOLLOW_INDEX].AC.StartStop != undefined && Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
      $('#LegStartEHLDR2').show();
      $('#LegEndEHLDR2').show();
    } else {
      $('#LegStartEHLDR2').hide();
      $('#LegEndEHLDR2').hide();
    }
  } else {
    $('#LegStartEHLDR2').hide();
    $('#LegEndEHLDR2').hide();
  }
  $('#AC_MAX_PERF2').val(maxperfweight);
  AC_data = '';
  TC_data = '';
  WB_data = '';
  PILOT_data = [];
  LOADABLE_data = '';
  FUEL_data = '';
  eacweight = '';
  eLat = '';
  eLong = '';
  ecofgLat = '';
  ecofgLong = '';
  error = '';
  searchtime = '';
  FuelType = 'Standard';
  FuelStandard = 'Standard';
  SeatType = 'Standard';
  SeatStandard = 'Standard';
  GraphName = 'Standard';
  GraphStandard = 'Standard';
  Loading = [];
  optLoad = [];
  tcNum = 0;
  tcIndex = '';
  PaXnum = 0;
  SN = '';

  $('#WB_OPT2').html('');
  $('#TCNUM2').html('');
  $('#LongCanvas2').html('');
  $('#LatCanvas2').html('');
  $('#Seating2').html('');
  $('#fuelLoad2').html('');
  $('#bagLoad2').html('');
  $('#optLoad2').html('');
  $('#Pilots2').empty();
  $('#SICs2').empty();
  $('#PaX2').html('');

  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  $('#exitWBPage2').unbind('click', exitOfflineWB);
  $('#exitWBPage2').unbind('click', exitOfflineWBmapb);
  var ChangeTab = 'itin';

  if (page == 'Map_Page') {
    $('#exitWBPage2').click(exitOfflineWBmapb);
    RETURN_MAP = true;
    $('#NWInfo').hide();
    $('#SWInfo').hide();
    $('#NEInfo').hide();
    $('#SEInfo').hide();
    $('#NNInfo').hide();
    $('#Compass').hide();
  } else {
    $('#exitWBPage2').click(exitOfflineWB);
    RETURN_MAP = false;
    ChangeTab = 'wb';
  }
  if (SCH_FOLLOWING_EDIT.isEditing) {
    ChangeTab = 'itin';
  }
  $.mobile.changePage($('#WB_Page2'), {
    changeHash: false,
  });
  //initialize new tab
  WB_ChangeTab(ChangeTab);
  if (RAform != null) {
    $('#WB_RA_TAB').prop('disabled', false).checkboxradio('refresh');
    RISK_CURRENT_DIV = 'WB_RA_TAB_CONTENT';
    RAform.ItinActive = 1;
    RAoperation();
  } else {
    $('#WB_RA_TAB_CONTENT').html('');
    $('#WB_RA_TAB').prop('disabled', true).checkboxradio('refresh');
  }
  if (HasItin) {
    $('#WB_ITIN_TAB').prop('disabled', false).checkboxradio('refresh');
  } else {
    $('#WB_ITIN_TAB').prop('disabled', true).checkboxradio('refresh');
  }
  if (HasFuelPlan) {
    $('#WB_OFP_TAB').prop('disabled', false).checkboxradio('refresh');
    populateLegDetails('WB_OFP_LegHLDR', true);
    //$("#WB_OFP_LegHLDR").html(getFlightPlanDetails2(MEASURE, true));
  } else {
    $('#WB_OFP_TAB').prop('disabled', true).checkboxradio('refresh');
  }
  $('#WB_Ident2').html(acIdent);
  $('#WB_flightCatID').empty();
  $('#WB_flightCatID').append($('<option />').val(0).text('[Not Assigned]'));
  if (GROUP_DATA.FlightCategories.length > 0) {
    $('#WB_flightCatIDHLDR').show();
    for (var i in GROUP_DATA.FlightCategories) {
      var fc = GROUP_DATA.FlightCategories[i];
      $('#WB_flightCatID').append(
        $('<option />')
          .val(fc.PrimaryKey)
          .text((fc.fltcatcode == null ? '' : '[' + fc.fltcatcode + '] ') + fc.fltcatdesc)
      );
    }
  } else {
    $('#WB_flightCatIDHLDR').hide();
  }
  cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
    var data = e[0];

    TC_data = data.TC_data;
    WB_data = data.WB_Data;
    LOADABLE_data = data.LOADABLE_data;
    AC_data = data.AC_data;
    $('#WB_EQHTML2').html('<b>Aircraft Temp Config Rebuild Required!</b>');
    if (AC_data.EQ_HTML != undefined) $('#WB_EQHTML2').html(AC_data.EQ_HTML);
    PilotsTable.query()
      .select()
      .then(function (e) {
        PILOT_data = e;
        $('#SICs2').empty();
        $('#Pilots2').empty();
        $('#SICs2').append($('<option />').val('').text('None'));
        for (i in PILOT_data) {
          $('#Pilots2').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
          $('#SICs2').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
        }

        $('#SICs2').selectmenu('refresh');
        $('#Pilots2').selectmenu('refresh');
        $('#WB_flightCatID').selectmenu('refresh');
        if (WB_LEG_INDEX == -1) WB_getActiveTC(acIdent);
        else {
          if (Follow_Data[FOLLOW_INDEX] != undefined) {
            try {
              TCNUM = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].tempconfig;
            } catch (e) {
              TCNUM = 0;
            }
          } else {
            TCNUM = 0;
          }
          WB_initialize(TCNUM);
        }
      });
  });
}

function WB_getActiveTC(Ident) {
  //alert("Getting TC");
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=get_opt_Status&acident=' + Ident, function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getActiveTC');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          var TCNUM = 0;
          if (json_data[0].noTC == 1) {
            AC_data.opt_Status = json_data[0].Result;
          } else {
            TCNUM = json_data[0].Result;
          }
          WB_initialize(TCNUM);
        }
      } else {
        WB_initialize(0);
      }
    }).fail(function (jqXHR, status, error) {
      WB_initialize(0);
    });
  } else {
    TCNUM = 0;

    for (x in Follow_Data) {
      if (Ident == Follow_Data[x].ident && Follow_Data[x].Itin != null && Follow_Data[x].WB != null) {
        TCNUM = Follow_Data[x].WB[Follow_Data[x].WB.length - 1].tempconfig;
      }
    }
    WB_initialize(TCNUM);
  }
}
var WB_INIT = false;

function WB_initialize(TCNUM) {
  var canrefresh = true;
  if ('WB_Page2' != $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id) canrefresh = false;
  FuelStandard = AC_data.FuelType;
  FuelType = FuelStandard;
  $('#male2').unbind('click', maleWeight2);
  $('#male2').click(maleWeight2);
  $('#female2').unbind('click', femaleWeight2);
  $('#female2').click(femaleWeight2);
  $('#emptySeat2').unbind('click', emptySeatWeight2);
  $('#emptySeat2').click(emptySeatWeight2);
  WB_INIT = true;

  if (AC_data.Units != undefined) {
    AC_UNITS = AC_data.Units;
  }
  //$("#exitWBSummaryPage").click(hrefHandler);
  //$("#showWBSummaryPage").click(hrefHandler);
  curPosition = 'Pilot2';
  $('#WBH2').text(acIdent);
  $("input[name='slider2']").unbind('slidestop', weightChange2);
  $("input[name='slider2']").unbind('change', weightChangeP2);
  $("input[name='slider2']").unbind('keyup', weightChange2);

  $("input[name='VariableLongslider2']").unbind('slidestop', LongChange2);
  $("input[name='VariableLongslider2']").unbind('keyup', LongChange2);

  $("input[name='VariableLatslider2']").unbind('slidestop', LatChange2);
  $("input[name='VariableLatslider2']").unbind('keyup', LatChange2);

  $("input[name='slider2']").bind('slidestop', weightChange2);
  $("input[name='slider2']").bind('change', weightChangeP2);
  $("input[name='slider2']").bind('keyup', weightChange2);

  $("input[name='VariableLongslider2']").bind('slidestop', LongChange2);
  $("input[name='VariableLongslider2']").bind('keyup', LongChange2);

  $("input[name='VariableLatslider2']").bind('slidestop', LatChange2);
  $("input[name='VariableLatslider2']").bind('keyup', LatChange2);

  var PilotWeight = 206;
  if (LOCALSTORAGE.PilotWeight != undefined && !isNaN(LOCALSTORAGE.PilotWeight)) {
    PilotWeight = LOCALSTORAGE.PilotWeight;
  }

  $("input[name='slider2']").val(convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', WBUnitLbl.Type));

  if (canrefresh)
    $('#slider2').attr('max', convertToUserUNITS(350, 'Weight', 'Imperial', WBUnitLbl.Type)).slider('refresh');
  //$.getJSON('itinerary-AJAX.php?action=getPilots', function (json_data) {
  //PILOT_data = json_data;

  //});

  if (canrefresh) $("input[name='slider2']").slider('refresh');

  //$.getJSON('itinerary-AJAX.php?action=getAC&acident='+acIdent, function (json_data) {
  //AC_data = json_data;

  helitype = AC_data.AcType;
  SN = parseInt(AC_data.SN);

  var bagString = '<center><fieldset id="POS2" data-role="controlgroup" data-type="horizontal" >';
  for (a in WB_data.Baggage) {
    //Each Baggage Compartment
    var bagRow = WB_data.Baggage[a].Row;
    bagString += '<table class="itintable"><tr class="itintable">';
    for (b in WB_data.Baggage[a].Bags) {
      var Bags = WB_data.Baggage[a].Bags[b];
      var Bag = Bags.Baggage;
      var bWT = Bags.WT;
      var bLat = Bags.Lat;
      var bLong = Bags.Long;
      var VariableBaggage = null;
      if (Bags.Variable != undefined) {
        VariableBaggage = Bags.Variable;
        bLat = VariableBaggage[0].CGL;
        bLong = VariableBaggage[0].CG;
      }
      var bStation =
        "<div class='WBSTATION2'>" +
        convertToUserUNITS(bLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        ' | ' +
        convertToUserUNITS(bLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        '</div>';
      Loading.push({
        Abr: Bag,
        MaxWT: bWT,
        LoadLat: bLat,
        LoadLong: bLong,
        Variable: VariableBaggage,
      });
      bagString +=
        '<td class="itintable"><input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
        Bag +
        '2" value="0" alt="Bag" data-longdefault="' +
        bLong +
        '" data-latdefault="' +
        bLat +
        '"/><label for="' +
        Bag +
        '2"><font size="-2">' +
        Bag +
        '<br />0 ' +
        WBUnitLbl.Weight +
        bStation +
        '</font></label></td>';
    }
    bagString += '</tr></table>';
  }
  $('#bagLoad2').append(bagString + '</fieldset>');
  FUEL_data = WB_data.Fuel;
  minFuel = null;
  for (f in FUEL_data) {
    if (
      FUEL_data[f].Type == FuelStandard &&
      SN >= parseInt(FUEL_data[f].SN_Low) &&
      SN <= parseInt(FUEL_data[f].SN_High)
    ) {
      if (minFuel === null) {
        minFuel = parseFloat(FUEL_data[f].Tank[0].WT);
      }
      maxFuel = parseInt(FUEL_data[f].Capacity);
    }
  }
  var DefaultFuel = Math.round(maxFuel / 2);
  //for per 1.25.5 releases
  if (AC_data.DefaultFuel != undefined) {
    if (AC_data.DefaultFuel <= maxFuel && AC_data.DefaultFuel >= 0) {
      DefaultFuel = AC_data.DefaultFuel > minFuel ? AC_data.DefaultFuel : minFuel;
    }
    if (AC_data.DefaultFuel > maxFuel) DefaultFuel = maxFuel;
  }
  var fStation =
    "<div class='WBSTATION2'>" +
    convertToUserUNITS(0, 'Arm', AC_UNITS, WBUnitLbl.Type) +
    ' | ' +
    convertToUserUNITS(0, 'Arm', AC_UNITS, WBUnitLbl.Type) +
    '</div>';
  $('#fuelLoad2').append(
    '<fieldset id="POS2" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable"><td class="itintable"><input type="radio" onClick="selectSliderInput2()" name="Position2" id="Fuel2" value="' +
      DefaultFuel +
      '" alt="Fuel"/>  <label for="Fuel2"><font size="-2">Fuel<br />' +
      convertToUserUNITS(DefaultFuel, 'Weight', AC_UNITS, WBUnitLbl.Type) +
      ' ' +
      WBUnitLbl.Weight +
      fStation +
      '</font></label></td></tr>        </table></fieldset>'
  );
  var optLoadString =
    '<fieldset id="POS2" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable">';
  if (AC_data.noTC == 1) {
    noTC_ChangeOpt2(true);
  } else {
    if (TC_data.length > 1)
      $('#WB_OPT2').append('<fieldset data-role="controlgroup"><legend><b>Installed Optional Equipment</b></legend>');
    else if (WANDB == '1') $('#WB_OPT2').append('<h2>No Optional Equipment</h2>');
    tcDesc = '';
    if (WANDB == '0') {
      TCUM = 0;
    }
    for (var i in TC_data) {
      if (TC_data[i].TCNum == TCNUM) {
        //find active temp configuration for initial setup
        tcNum = TC_data[i].TCNum;
        tcIndex = i;
        if (WANDB == '1') {
          if (AC_UNITS == 'Imperial')
            $('#TCNUM2').html('Temp Config #' + tcNum + '<br /><b>Aircraft Graph Units: </b>Imperial lbs / in');
          else $('#TCNUM2').html('Temp Config #' + tcNum + '<br /><b>Aircraft Graph Units: </b>kg / mm');
        } else $('#SEQ_Label1').html('');
        //TODO update weight and balance variables and reset basket weight to 0 if removed or added
        eacweight = parseFloat(TC_data[i].TCWeight);
        eLat = parseFloat(TC_data[i].TCLatMom);
        eLong = parseFloat(TC_data[i].TCHorzMom);
        ecofgLong = parseFloat(eLong / eacweight).toFixed(2);
        ecofgLat = parseFloat(eLat / eacweight).toFixed(2);
        for (var t in TC_data[i].Optional) {
          var opt = TC_data[i].Optional[t];
          //add optional equipment with initial setting
          tcDesc += opt.Abr + ',';
          $('#WB_OPT2').append(
            '<input type="checkbox" name="opt2_' +
              opt.Abr +
              '" id="opt2_' +
              opt.Abr +
              '" data-mini="true" /><label for="opt2_' +
              opt.Abr +
              '">' +
              opt.Description +
              '</label>'
          );

          $('#opt2_' + opt.Abr).click(TCchange2);
        }

        for (var t in TC_data[i].Optional) {
          opt = TC_data[i].Optional[t];
          if (opt.status == 1) {
            if (opt.Seating == 1) {
              SeatType = opt.SeatType;
            }
            $('#opt2_' + opt.Abr).attr('checked', 'checked');
            if (opt.Loadable == 1) {
              if (opt.Fuel != 1) {
                var oStation =
                  '<div id="' +
                  opt.Abr +
                  '2_NAME"></div>' +
                  "<div class='WBSTATION2'>" +
                  convertToUserUNITS(opt.LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                  ' | ' +
                  convertToUserUNITS(opt.LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                  '<br />' +
                  opt.Description +
                  '</div>';
                if (opt.VariableArms == undefined) {
                  opt.VariableArms = 0;
                  opt.LoadLatMax = 0;
                  opt.LoadLongMax = 0;
                  opt.LoadLatMin = 0;
                  opt.LoadLongMin = 0;
                }
                optLoadString +=
                  '<td class="itintable"><input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
                  opt.Abr +
                  '2" value="0" alt="Opt" data-variable="' +
                  opt.VariableArms +
                  '" data-latmax="' +
                  opt.LoadLatMax +
                  '" data-latmin="' +
                  opt.LoadLatMin +
                  '" data-longmax="' +
                  opt.LoadLongMax +
                  '" data-longmin="' +
                  opt.LoadLongMin +
                  '"  data-latdefault="' +
                  opt.LoadLat +
                  '" data-longdefault="' +
                  opt.LoadLong +
                  '"/><label for="' +
                  opt.Abr +
                  '2"><font size="-2">' +
                  opt.Abr +
                  '<br />0 ' +
                  WBUnitLbl.Weight +
                  oStation +
                  '</font></label></td>';
                optLoad.push({
                  Abr: opt.Abr,
                  MaxWT: opt.MaxWT,
                  LoadLat: opt.LoadLat,
                  LoadLong: opt.LoadLong,
                  ExternalLoad: opt.ExternalLoad,
                  VariableArms: opt.VariableArms,
                  LoadLatMax: opt.LoadLatMax,
                  LoadLongMax: opt.LoadLongMax,
                  LoadLatMin: opt.LoadLatMin,
                  LoadLongMin: opt.LoadLongMin,
                });
              }
            }
            //alert("conflict: "+opt.Abr);
            for (c in TC_data[i].Optional) {
              //search for conflict item and disable checkbox
              if (TC_data[i].Optional[c].conflictwith != null) {
                var con = TC_data[i].Optional[c].conflictwith.split('|');
                for (d in con) {
                  if (con[d] == opt.PrimaryKey) {
                    $('#opt2_' + TC_data[i].Optional[c].Abr).prop('disabled', true); //disable match
                  }
                }
              }
            }
            if (opt.Fuel == 1) {
              for (f in FUEL_data) {
                if (
                  FUEL_data[f].Type == opt.Type &&
                  SN >= parseInt(FUEL_data[f].SN_Low) &&
                  SN <= parseInt(FUEL_data[f].SN_High)
                ) {
                  maxFuel = parseInt(FUEL_data[f].Capacity);
                  FuelType = opt.Type;
                }
              }
            }
          }
        }
      }
    }
  }
  for (i in LOADABLE_data) {
    if (LOADABLE_data[i].Fuel == 1) {
      //plusFuel += parseInt(LOADABLE_data[i].MaxWT);
      for (f in FUEL_data) {
        if (
          FUEL_data[f].Type == LOADABLE_data[i].Type &&
          SN >= parseInt(FUEL_data[f].SN_Low) &&
          SN <= parseInt(FUEL_data[f].SN_High)
        ) {
          maxFuel = parseInt(FUEL_data[f].Capacity);
          FuelStandard = LOADABLE_data[i].Type;
        }
      }
    }
    if (LOADABLE_data[i].Fuel != 1 && LOADABLE_data[i].Loadable == 1) {
      var lStation =
        "<div class='WBSTATION2'>" +
        convertToUserUNITS(LOADABLE_data[i].LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        ' | ' +
        convertToUserUNITS(LOADABLE_data[i].LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        '<br />' +
        LOADABLE_data[i].Description +
        '</div>';
      if (LOADABLE_data[i].VariableArms == undefined) {
        LOADABLE_data[i].VariableArms = 0;
        LOADABLE_data[i].LoadLatMax = 0;
        LOADABLE_data[i].LoadLongMax = 0;
        LOADABLE_data[i].LoadLatMin = 0;
        LOADABLE_data[i].LoadLongMin = 0;
      }
      optLoadString +=
        '<td class="itintable"><input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
        LOADABLE_data[i].Abr +
        '2" value="0" alt="Opt" data-variable="' +
        LOADABLE_data[i].VariableArms +
        '" data-latmax="' +
        LOADABLE_data[i].LoadLatMax +
        '" data-latmin="' +
        LOADABLE_data[i].LoadLatMin +
        '" data-longmax="' +
        LOADABLE_data[i].LoadLongMax +
        '" data-longmin="' +
        LOADABLE_data[i].LoadLongMin +
        '"  data-latdefault="' +
        LOADABLE_data[i].LoadLat +
        '" data-longdefault="' +
        LOADABLE_data[i].LoadLong +
        '"/><label for="' +
        LOADABLE_data[i].Abr +
        '2"><font size="-2">' +
        LOADABLE_data[i].Abr +
        '<br />0 ' +
        WBUnitLbl.Weight +
        lStation +
        '</font></label></td>';
      optLoad.push({
        Abr: LOADABLE_data[i].Abr,
        MaxWT: LOADABLE_data[i].MaxWT,
        LoadLat: LOADABLE_data[i].LoadLat,
        LoadLong: LOADABLE_data[i].LoadLong,
        ExternalLoad: LOADABLE_data[i].ExternalLoad,
        VariableArms: LOADABLE_data[i].VariableArms,
        LoadLatMax: LOADABLE_data[i].LoadLatMax,
        LoadLongMax: LOADABLE_data[i].LoadLongMax,
        LoadLatMin: LOADABLE_data[i].LoadLatMin,
        LoadLongMin: LOADABLE_data[i].LoadLongMin,
      });
    }
    if (LOADABLE_data[i].cgRestrict == 1) {
      //set cofg graph standard for what is fixed to aircraft
      GraphName = LOADABLE_data[i].cgGraph;
      GraphStandard = GraphName;
    }
    if (LOADABLE_data[i].Seating == 1) {
      SeatStandard = LOADABLE_data[i].SeatType;
      if (SeatType == 'Standard') SeatType = SeatStandard; //prevent overriding possible optional selected
    }
  }
  var PaXstring =
    '<legend><b>Number of Passengers</b></legend><center><table class="itintable"><tr class="itintable"><td class="itintable">';
  //$("#Seating").append();
  var seatString = '<center><fieldset id="POS2" data-role="controlgroup" data-type="horizontal" data-mini="true">';
  for (var a in WB_data.Seating) {
    if (WB_data.Seating[a].Type == SeatType) {
      var row = WB_data.Seating[a].Row;
      seatString += "<table class='itintable'><tr class='itintable'>";
      for (var b in WB_data.Seating[a].Seats) {
        var Seats = WB_data.Seating[a].Seats[b];
        var Seat = Seats.Seat;
        var WT = Seats.WT;
        var Lat = Seats.Lat;
        var Long = Seats.Long;

        Loading.push({
          Abr: Seat,
          MaxWT: WT,
          LoadLat: Lat,
          LoadLong: Long,
        });
        var pStation =
          '<div id="' +
          Seat +
          '2_NAME"></div>' +
          "<div class='WBSTATION2'>" +
          convertToUserUNITS(Seats.Long, 'Arm', AC_UNITS, WBUnitLbl.Type) +
          ' | ' +
          convertToUserUNITS(Seats.Lat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
          '</div>';
        if (Seat == 'Pilot') {
          var PilotWeight = 206;
          if (LOCALSTORAGE.PilotWeight != undefined && !isNaN(LOCALSTORAGE.PilotWeight)) {
            PilotWeight = LOCALSTORAGE.PilotWeight;
          }
          seatString +=
            '<td class="itintable"> <input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
            Seat +
            '2" value="' +
            convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
            '" alt="Pax"/><label for="' +
            Seat +
            '2"><font size="-2">' +
            Seat +
            '<br />' +
            convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
            WBUnitLbl.Weight +
            pStation +
            '</font></label></td>';
        } else {
          seatString +=
            '<td class="itintable"> <input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
            Seat +
            '2" value="0" alt="Pax"/><label for="' +
            Seat +
            '2"><font size="-2">' +
            Seat +
            '<br />0 ' +
            WBUnitLbl.Weight +
            pStation +
            '</font></label></td>';
        }
      }
      seatString += '</tr></table>';
    }
  }
  $('#PaX2').html(PaXstring + '</td></tr></table>');
  $('#Seating2').html(seatString + '</fieldset>');
  $('#optLoad2').html(optLoadString + '</tr></table></fieldset>');
  $("input[name='Position2']").bind('click', positionChange2);
  $('#Pilot2').attr('checked', true);

  if (Follow_Data[FOLLOW_INDEX] != undefined) {
    if (Follow_Data[FOLLOW_INDEX].WB !== null) {
      var ITEMS = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX];
      var WBS = ITEMS.Summary;
      $('#WB_CARGO_TOTAL2').val(ITEMS.CargoWeight);
      $('#Pilots2').val(ITEMS.Pilot);
      $('#SICs2').val(ITEMS.sic);
      $('#WB_flightCatID').val(ITEMS.flightCatID);
      $('#LegStart2E').val(ITEMS.LegStartE);
      $('#LegStart2').val(ITEMS.LegStart);
      $('#LegEnd2').val(ITEMS.LegEnd);
      $('#LegEnd2E').val(ITEMS.LegEndE);
      $('#LegStart2E_pop').val(ITEMS.LegStartE);
      $('#LegStart2_pop').val(ITEMS.LegStart);
      $('#LegEnd2_pop').val(ITEMS.LegEnd);
      $('#LegEnd2E_pop').val(ITEMS.LegEndE);
      $('#LegETA2').val(ITEMS.LegETA);
      $('#noShutdown2').prop('checked', ITEMS.noShutdown == 1 ? true : false);
      try {
        $('#noShutdown2').checkboxradio('refresh');
      } catch (e) {
        //do nothing
      }
      checkItinNoShutdown();
      WB_OldETE(ITEMS.Changed);
      var time = new Date();
      $('#LegETA2Time').html(time.toISOString().substring(11, 16) + 'Z');
      $('#LegOrigin2').val(ITEMS.LegOrigin);
      $('#LegRouting2').val(ITEMS.LegRouting);
      $('#LegDest2').val(ITEMS.LegDest);
      $('#LegPassengerCount2').val(ITEMS.PaxNum);
      $('#LegComments2').val(br2nl(ITEMS.Description));
      if (ITIN_TIMEZONE_UTCmode == 'UTC') {
        ITIN_TIMEZONE_UTCmode = 'Local';
        toggleWB_TZ({ value: 'UTC' });
      }
      if (GROUP_DATA.Pax_Man == 1) {
        PAX_MANIFESTING.AddingManifestTo = 'Leg';
        var oldScroll = 0;
        if (ITIN_Pax_Manifest.scrollTo != undefined) oldScroll = ITIN_Pax_Manifest.scrollTo;
        ITIN_Pax_Manifest = Follow_Data[FOLLOW_INDEX].Itin.Pax_Manifest;
        ITIN_Pax_Manifest.ClientPK = Follow_Data[FOLLOW_INDEX].ClientPK;
        ITIN_Pax_Manifest.briefingLock = Follow_Data[FOLLOW_INDEX].AC.briefingLock;
        ITIN_Pax_Manifest.briefingReq = Follow_Data[FOLLOW_INDEX].AC.briefingReq;
        ITIN_Pax_Manifest.Actype = Follow_Data[FOLLOW_INDEX].AC.AcType;
        ITIN_Pax_Manifest.ident = Follow_Data[FOLLOW_INDEX].AC.ident;
        ITIN_Pax_Manifest.CurLeg = WB_LEG_INDEX;
        ITIN_Pax_Manifest.scrollTo = oldScroll;
        PAX_Init_ITIN_Manifesting(WB_LEG_INDEX); //in menu-PaxManifesting.js
      }
      if (ITEMS.Cfields == null) {
        ITIN_Cfields = JSON.parse(Follow_Data[FOLLOW_INDEX].Itin.Cfields);
        WB_NewLegCfields();
      } else {
        if (ITEMS.Cfields.length == 0) {
          ITIN_Cfields = JSON.parse(Follow_Data[FOLLOW_INDEX].Itin.Cfields);
          WB_NewLegCfields();
        } else {
          ITIN_Cfields = ITEMS.Cfields;
          WB_PopLegCfields(ITEMS.Cfields);
        }
      }
      try {
        $('#Pilots2').selectmenu('refresh');
        $('#SICs2').selectmenu('refresh');
        $('#WB_flightCatID').selectmenu('refresh');
      } catch (e) {
        //do nothing using quick entyr tool
      }
      var ActiveLeg = '';
      if (ITEMS.Active == 1) ActiveLeg = ' (Active)';
      $('#WB_LegNum').html('Leg ' + (WB_LEG_INDEX + 1) + ActiveLeg + ' <span id="itinTotalFT"></span>');
      getFlightTotals();
      $('#WB_LegNum_pop').html('Leg ' + (WB_LEG_INDEX + 1) + ActiveLeg);
      WBS = WBS.split(',');
      for (var w in WBS) {
        var W = WBS[w];
        if (W != null && W != '') {
          var LID = W.split(':');
          var LWT = parseFloat(LID[1]);
          if (LID[2] != undefined) {
            //RESTORE VARIABLE ARM ITEM*************************************************
            //****************************************************
            var varItem = $('#' + LID[0] + '2')[0];
            varItem.dataset.longdefault = LID[2];
            varItem.dataset.latdefault = LID[3];
            if ($('#WBstations2').is(':checked')) {
              $("label[for='" + LID[0] + "2']").html(
                '<font size="-2">' +
                  LID[0] +
                  '<br />' +
                  convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
                  '<div id="' +
                  LID[0] +
                  '2_NAME"></div>' +
                  '<div class="WBSTATION2" style="display: block">' +
                  LID[2] +
                  ' | ' +
                  LID[3] +
                  '</div></font>'
              );
            } else {
              $("label[for='" + LID[0] + "2']").html(
                '<font size="-2">' +
                  LID[0] +
                  '<br />' +
                  convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
                  '<div id="' +
                  LID[0] +
                  '2_NAME"></div>' +
                  '<div class="WBSTATION2">' +
                  LID[2] +
                  ' | ' +
                  LID[3] +
                  '</div></font>'
              );
            }
            $("input[id='" + LID[0] + "2']").val(LWT);
          } else {
            //Check if previous loaded position exists and do nothing if it doesn't
            if ($("label[for='" + LID[0] + "2']")[0] != undefined) {
              var oldlabel = $("label[for='" + LID[0] + "2']")[0].innerHTML;
              oldlabel = oldlabel.split('<div class="WBSTATION2"');

              $("label[for='" + LID[0] + "2']").html(
                '<font size="-2">' +
                  LID[0] +
                  '<br />' +
                  convertACToUserUnits(LWT, 'Weight', AC_UNITS) +
                  '<div id="' +
                  LID[0] +
                  '2_NAME"></div>' +
                  '<div class="WBSTATION2"' +
                  oldlabel[1] +
                  '</font>'
              );
              $("input[id='" + LID[0] + "2']").val(LWT);
            } else {
              alert('Loading Error: ' + LID[0] + ' Location not found');
            }
          }
        }
      }
      $("label[for='Fuel2']").html(
        '<font size="-2">Fuel<br />' + convertACToUserUnits(ITEMS.fuelwt, 'Weight', AC_UNITS) + ''
      );
      $('#Fuel2').val(ITEMS.fuelwt);
    }
  }
  if (WB_UPDATE == true) {
    $('#wbUPDATE').html(
      '<button id="WBupdate" data-mini="true" data-icon="check" class="greenbg" onclick="WB_Leg_Save()">Save / Activate Leg</button>'
    );
    $('#wbUPDATE2').html(
      '<button id="WBupdate2" data-mini="true" data-icon="check" class="greenbg" onclick="WB_Leg_Save()">Save / Activate Leg</button>'
    );
  } else {
    $('#wbUPDATE2').html('');
    $('#wbUPDATE').html('');
  }
  $('#WB_Page2').enhanceWithin();
  if ($('#WBstations2').is(':checked')) {
    $('.WBSTATION2').show();
  }
  $('#VariableArmHLDR2').hide(); //hide variable arm by default
  calculateWB2();
  updatePaxSeatingNames2();
}

function TCchange2() {
  if (AC_data.noTC == 1) {
    noTC_ChangeOpt2();
    return;
  }
  var oldTC = tcNum;
  FuelType = FuelStandard;
  GraphName = GraphStandard;
  var SeatChange = false;
  var optSeatChange = false;
  //SeatType = SeatStandard;
  if (SeatType != SeatStandard) SeatChange = true;
  for (f in FUEL_data) {
    if (FUEL_data[f].Type == FuelType && SN >= parseInt(FUEL_data[f].SN_Low) && SN <= parseInt(FUEL_data[f].SN_High)) {
      maxFuel = parseInt(FUEL_data[f].Capacity);
    }
  }
  $('#optLoad2').html('');
  for (var i in TC_data[0].Optional) {
    var ena = TC_data[0].Optional[i].Abr;
    $('#opt2_' + ena).checkboxradio('enable'); //enabled all checkboxes
  }
  for (var i in TC_data) {
    var match = true;
    for (var b in TC_data[i].Optional) {
      var opt = TC_data[i].Optional[b];
      if (
        (opt.status == 0 && $('#opt2_' + opt.Abr).is(':checked') == true) ||
        (opt.status == 1 && $('#opt2_' + opt.Abr).is(':checked') == false)
      )
        match = false;
    }
    if (match == true) {
      optLoad = [];
      var optLoadString =
        '<fieldset id="POS2" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable">';
      for (var t in TC_data[i].Optional) {
        opt = TC_data[i].Optional[t];
        if ($('#opt2_' + opt.Abr).is(':checked')) {
          for (c in TC_data[i].Optional) {
            //search for conflict item and disable checkbox
            //var con = TC_data[i].Optional[c].conflictwith;
            //if (con == opt.Description) $("#opt_"+TC_data[i].Optional[c].Abr).checkboxradio("disable"); //disable match
            if (opt.conflictwith !== null) {
              var con = opt.conflictwith.split('|');
              for (var e in con) {
                if (con[e] == TC_data[i].Optional[c].PrimaryKey) {
                  $('#opt2_' + TC_data[i].Optional[c].Abr).checkboxradio('disable'); //disable match
                }
              }
            }
          }
        }
        if (opt.status == 1) {
          if (opt.Loadable == 1) {
            if (opt.Fuel != 1) {
              var oStation =
                "<div class='WBSTATION2'>" +
                convertToUserUNITS(opt.LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                ' | ' +
                convertToUserUNITS(opt.LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                '<br />' +
                opt.Description +
                '</div>';
              if (opt.VariableArms == undefined) {
                opt.VariableArms = 0;
                opt.LoadLatMax = 0;
                opt.LoadLongMax = 0;
                opt.LoadLatMin = 0;
                opt.LoadLongMin = 0;
              }
              optLoadString +=
                '<td class="itintable"><input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
                opt.Abr +
                '2" value="0" alt="Opt" data-variable="' +
                opt.VariableArms +
                '" data-latmax="' +
                opt.LoadLatMax +
                '" data-latmin="' +
                opt.LoadLatMin +
                '" data-longmax="' +
                opt.LoadLongMax +
                '" data-longmin="' +
                opt.LoadLongMin +
                '"  data-latdefault="' +
                opt.LoadLat +
                '" data-longdefault="' +
                opt.LoadLong +
                '"/><label for="' +
                opt.Abr +
                '2"><font size="-2">' +
                opt.Abr +
                '<br />0 ' +
                WBUnitLbl.Weight +
                oStation +
                '</font></label></td>';
              optLoad.push({
                Abr: opt.Abr,
                MaxWT: opt.MaxWT,
                LoadLat: opt.LoadLat,
                LoadLong: opt.LoadLong,
                ExternalLoad: opt.ExternalLoad,
                VariableArms: opt.VariableArms,
                LoadLatMax: opt.LoadLatMax,
                LoadLongMax: opt.LoadLongMax,
                LoadLatMin: opt.LoadLatMin,
                LoadLongMin: opt.LoadLongMin,
              });
            }
          }
          if (opt.Seating == 1) {
            if (SeatType != opt.SeatType) {
              //seating configuration has changed
              SeatChange = true;
              SeatType = opt.SeatType;
              optSeatChange = true;
            } else {
              //seating configuration is the same
              SeatChange = false;
              optSeatChange = true;
            }
          }

          if (opt.Fuel == 1) {
            //plusFuel += parseInt(opt.MaxWT)
            for (var f in FUEL_data) {
              if (
                FUEL_data[f].Type == opt.Type &&
                SN >= parseInt(FUEL_data[f].SN_Low) &&
                SN <= parseInt(FUEL_data[f].SN_High)
              ) {
                maxFuel = parseInt(FUEL_data[f].Capacity);
                FuelType = opt.Type;
              }
            }
          }
        }
      }

      for (var L in LOADABLE_data) {
        if (LOADABLE_data[L].Fuel == 1) {
          //plusFuel += parseInt(LOADABLE_data[L].MaxWT);
          for (var f in FUEL_data) {
            if (
              FUEL_data[f].Type == LOADABLE_data[L].Type &&
              SN >= parseInt(FUEL_data[f].SN_Low) &&
              SN <= parseInt(FUEL_data[f].SN_High)
            ) {
              maxFuel = parseInt(FUEL_data[f].Capacity);
              FuelStandard = LOADABLE_data[L].Type;
            }
          }
        }
        if (LOADABLE_data[L].Fuel != 1 && LOADABLE_data[L].Loadable == 1) {
          var lStation =
            "<div class='WBSTATION2'>" +
            convertToUserUNITS(LOADABLE_data[L].LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            ' | ' +
            convertToUserUNITS(LOADABLE_data[L].LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            '<br />' +
            LOADABLE_data[L].Description +
            '</div>';
          if (LOADABLE_data[L].VariableArms == undefined) {
            LOADABLE_data[L].VariableArms = 0;
            LOADABLE_data[L].LoadLatMax = 0;
            LOADABLE_data[L].LoadLongMax = 0;
            LOADABLE_data[L].LoadLatMin = 0;
            LOADABLE_data[L].LoadLongMin = 0;
          }
          optLoadString +=
            '<td class="itintable"><input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
            LOADABLE_data[L].Abr +
            '2" value="0" alt="Opt" data-variable="' +
            LOADABLE_data[L].VariableArms +
            '" data-latmax="' +
            LOADABLE_data[L].LoadLatMax +
            '" data-latmin="' +
            LOADABLE_data[L].LoadLatMin +
            '" data-longmax="' +
            LOADABLE_data[L].LoadLongMax +
            '" data-longmin="' +
            LOADABLE_data[L].LoadLongMin +
            '"  data-latdefault="' +
            LOADABLE_data[L].LoadLat +
            '" data-longdefault="' +
            LOADABLE_data[L].LoadLong +
            '"/><label for="' +
            LOADABLE_data[L].Abr +
            '2"><font size="-2">' +
            LOADABLE_data[L].Abr +
            '<br />0 ' +
            WBUnitLbl.Weight +
            lStation +
            '</font></label></td>';
          optLoad.push({
            Abr: LOADABLE_data[L].Abr,
            MaxWT: LOADABLE_data[L].MaxWT,
            LoadLat: LOADABLE_data[L].LoadLat,
            LoadLong: LOADABLE_data[L].LoadLong,
            ExternalLoad: LOADABLE_data[L].ExternalLoad,
            VariableArms: LOADABLE_data[L].VariableArms,
            LoadLatMax: LOADABLE_data[L].LoadLatMax,
            LoadLongMax: LOADABLE_data[L].LoadLongMax,
            LoadLatMin: LOADABLE_data[L].LoadLatMin,
            LoadLongMin: LOADABLE_data[L].LoadLongMin,
          });
        }
        if (LOADABLE_data[L].cgRestrict == 1) {
          //set cofg graph standard for what is fixed to aircraft
          GraphName = LOADABLE_data[L].cgGraph;
          GraphStandard = GraphName;
        }
        if (LOADABLE_data[L].Seating == 1) {
          SeatStandard = LOADABLE_data[L].SeatType;
          if (SeatType == 'Standard') SeatType = SeatStandard; //prevent overriding possible optional selected
        }
      }

      if (SeatChange && !optSeatChange) SeatType = SeatStandard;
      if (SeatChange) {
        $('#PaX2').html('');
        $('#Seating2').html('');
        Loading = [];
        var PaXstring =
          '<legend><b>Number of Passengers</b></legend><center><table class="itintable"><tr class="itintable"><td class="itintable">';
        //$("#Seating").append();
        var seatString =
          '<center><fieldset id="POS2" data-role="controlgroup" data-type="horizontal" data-mini="true">';
        for (var a in WB_data.Seating) {
          //Each Row
          if (WB_data.Seating[a].Type == SeatType) {
            var row = WB_data.Seating[a].Row;
            seatString += "<table class='itintable'><tr class='itintable'>";
            for (var b in WB_data.Seating[a].Seats) {
              var Seats = WB_data.Seating[a].Seats[b];
              var Seat = Seats.Seat;
              var WT = Seats.WT;
              var Lat = Seats.Lat;
              var Long = Seats.Long;
              Loading.push({
                Abr: Seat,
                MaxWT: WT,
                LoadLat: Lat,
                LoadLong: Long,
              });
              var pStation =
                '<div id="' +
                curPosition +
                '2_NAME"></div>' +
                "<div class='WBSTATION2'>" +
                convertToUserUNITS(Seats.Long, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                ' | ' +
                convertToUserUNITS(Seats.Lat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
                '</div>';
              if (Seat == 'Pilot') {
                var PilotWeight = 206;
                if (LOCALSTORAGE.PilotWeight != undefined && !isNaN(LOCALSTORAGE.PilotWeight)) {
                  PilotWeight = LOCALSTORAGE.PilotWeight;
                }
                seatString +=
                  '<td class="itintable"> <input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
                  Seat +
                  '2" value="' +
                  convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
                  '" alt="Pax"/><label for="' +
                  Seat +
                  '2"><font size="-2">' +
                  Seat +
                  '<br />' +
                  convertACToUserUnits(PilotWeight, 'Weight', 'Imperial') +
                  pStation +
                  '</font></label></td>';
                $("input[name='slider2']")
                  .val(convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', WBUnitLbl.Type))
                  .slider('refresh');
              } else {
                seatString +=
                  '<td class="itintable"> <input type="radio" onClick="selectSliderInput2()" name="Position2" id="' +
                  Seat +
                  '2" value="0" alt="Pax"/><label for="' +
                  Seat +
                  '2"><font size="-2">' +
                  Seat +
                  '<br />0 ' +
                  WBUnitLbl.Weight +
                  pStation +
                  '</font></label></td>';
              }
            }
            seatString += '</tr></table>';
          }
        }
        $('#Seating2').append(seatString + '</fieldset>');
        //Re Add baggage to Loading Var
        for (var ab in WB_data.Baggage) {
          for (var bb in WB_data.Baggage[ab].Bags) {
            var Bags = WB_data.Baggage[ab].Bags[bb];
            var Bag = Bags.Baggage;
            var bWT = Bags.WT;
            var bLat = Bags.Lat;
            var bLong = Bags.Long;
            var VariableBaggage = null;
            if (Bags.Variable != undefined) {
              VariableBaggage = Bags.Variable;
              bLat = VariableBaggage[0].CGL;
              bLong = VariableBaggage[0].CG;
            }
            Loading.push({
              Abr: Bag,
              MaxWT: bWT,
              LoadLat: bLat,
              LoadLong: bLong,
              Variable: VariableBaggage,
            });
          }
        }
      }
      if ($('#Fuel2').val() > maxFuel) {
        $('#Fuel2').val(maxFuel);
        $("label[for='Fuel2']").html('<font size="-2">Fuel<br />' + convertACToUserUnits(maxFuel, 'Weight', AC_UNITS));
      }
      if ($('#Fuel2').is(':checked'))
        $('#slider2').attr('max', convertToUserUNITS(maxFuel, 'Weight', AC_UNITS, WBUnitLbl.Type)).slider('refresh');
      $('#optLoad2').append(optLoadString + '</tr></table></fieldset>');
      $("input[name='Position2']").bind('click', positionChange2);
      $('#Pilot2').attr('checked', true);
      //$("#optLoad").enhanceWithin();
      $('#WB_Page2').enhanceWithin();
      //$("#WB_OPT").enhanceWithin();
      tcNum = TC_data[i].TCNum;
      tcIndex = i;
      if (AC_UNITS == 'Imperial')
        $('#TCNUM2').html('Temp Config #' + tcNum + '<br /><b>Aircraft Graph Units: </b>Imperial lbs / in');
      else $('#TCNUM2').html('Temp Config #' + tcNum + '<br /><b>Aircraft Graph Units: </b>kg / mm');

      $.getJSON(
        BASEURL +
          'menu-Itinerary-AJAX.php?action=updateTC&acident=' +
          encodeURIComponent(acIdent) +
          '&oldTC=' +
          oldTC +
          '&newTC=' +
          tcNum,
        function (json_data) {
          if (json_data[0].ServerResponse != 'Success') {
            if (json_data[0].ServerResponse == 'Offline') {
            } else alert('Unknown Problem Saving Temp Config ' + json_data[0].Exception);
          }
        }
      ).fail(function (jqXHR, status, error) {
        console.log(error);
      });

      eacweight = parseFloat(TC_data[i].TCWeight);
      eLat = parseFloat(TC_data[i].TCLatMom);
      eLong = parseFloat(TC_data[i].TCHorzMom);
      ecofgLong = parseFloat(eLong / eacweight).toFixed(2);
      ecofgLat = parseFloat(eLat / eacweight).toFixed(2);
      calculateWB2();
      break;
    }
  }
}

function noTC_ChangeOpt2(setDefault = false) {
  tcNum = 'N/A';
  tcDesc = '';
  FuelType = FuelStandard;
  GraphName = GraphStandard;
  var SeatChange = false;
  var optSeatChange = false;
  //SeatType = SeatStandard;
  if (SeatType != SeatStandard) SeatChange = true;
  for (var f in FUEL_data) {
    if (FUEL_data[f].Type == FuelType && SN >= parseInt(FUEL_data[f].SN_Low) && SN <= parseInt(FUEL_data[f].SN_High)) {
      maxFuel = parseInt(FUEL_data[f].Capacity);
      ACmaxFuel = maxFuel;
    }
  }
  $('#optLoad2').html('');
  var Optional = JSON.parse(AC_data.opt_Items);
  var Defaults = {};
  if (AC_data.opt_Status != undefined && AC_data.opt_Status != 'null') {
    Defaults = JSON.parse(AC_data.opt_Status);
  } else {
    for (var i in Optional) {
      Defaults[Optional[i].Abr] = 0;
    }
  }
  var Amendment = JSON.parse(AC_data.Amendment);
  var TC_data = {
    Date: Amendment.Date,
    ANum: Amendment.ANum,
    TCNum: 'N/A',
    TCWeight: Number(Amendment.Weight),
    TCHorzMom: Number(Amendment.HorzMom),
    TCLatMom: Number(Amendment.LatMom),
    Active: 1,
    Optional: Optional,
  };
  if (setDefault === true) {
    if (Optional.length > 0)
      $('#WB_OPT2').append(
        '<fieldset data-role="controlgroup"><legend><b>' + iTrans('Installed Optional Equipment') + '</b></legend>'
      );
    else if (WANDB == '1') $('#WB_OPT2').append('<h2 class="limit-table">' + iTrans('No Optional Equipment') + '</h2>');
    if (Follow_Data[FOLLOW_INDEX] != undefined) {
      if (Follow_Data[FOLLOW_INDEX].WB !== null) {
        for (var i in Defaults) {
          Defaults[i] = 0;
        }
        var Items = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].TCdesc.split(',');
        for (var i in Items) {
          if (Defaults[Items[i]] != undefined) Defaults[Items[i]] = 1;
        }
      }
    }
  }
  for (var i in Optional) {
    var O = Optional[i];
    if (setDefault === true) {
      $('#WB_OPT2').append(
        '<input type="checkbox" name="opt2_' +
          O.Abr +
          '" id="opt2_' +
          O.Abr +
          '" data-mini="true" /><label for="opt2_' +
          O.Abr +
          '">' +
          O.Description +
          '</label>'
      );
      $('#opt2_' + O.Abr).click(noTC_ChangeOpt2);
    }
    if (O.Kit == 1) {
      var K = GetKitTotalChange(O);
      O.Weight = K.Weight;
      O.HorzArm = K.HorzArm;
      O.LatArm = K.LatArm;
      O.HorzMom = K.HorzMom;
      O.LatMom = K.LatMom;
    } else {
      O.HorzMom = roundNum(O.Weight * O.HorzArm, 2);
      O.LatMom = roundNum(O.Weight * O.LatArm);
    }
    if (setDefault !== true) {
      $('#opt2_' + O.Abr).checkboxradio('enable'); //enabled all checkboxes
    }
    if ($('#opt2_' + O.Abr).is(':checked') == true) {
      if (O.Optional == 'Removed') {
        TC_data.TCWeight += Number(O.Weight);
        TC_data.TCHorzMom += Number(O.HorzMom);
        TC_data.TCLatMom += Number(O.LatMom);
      }
    } else {
      if (O.Optional == 'Installed') {
        TC_data.TCWeight -= Number(O.Weight);
        TC_data.TCHorzMom -= Number(O.HorzMom);
        TC_data.TCLatMom -= Number(O.LatMom);
      }
    }
  }
  eacweight = parseFloat(TC_data.TCWeight);
  if (setDefault === true) {
    tcDesc = '';
    for (var i in Defaults) {
      var D = Defaults[i];
      if (D == 1) tcDesc += i + ',';
      $('#opt2_' + i).prop('checked', D == 1);
      try {
        $('#opt2_' + i).checkboxradio('refresh');
      } catch (e) {
        //do nothing
      }
    }
  } else {
    for (var i in Defaults) {
      if ($('#opt2_' + i).is(':checked')) {
        tcDesc += i + ',';
        Defaults[i] = 1;
      } else {
        Defaults[i] = 0;
      }
    }
    AC_data.opt_Status = JSON.stringify(Defaults);
  }

  optLoad = [];
  var optLoadString =
    '<fieldset id="POS2" data-role="controlgroup" data-type="horizontal" ><table class="itintable"><tr class="itintable">';
  $('#WB_OPT2').enhanceWithin();
  for (var t in TC_data.Optional) {
    var opt = TC_data.Optional[t];
    if ($('#opt2_' + opt.Abr).is(':checked')) {
      opt.status = 1;
      for (var c in TC_data.Optional) {
        if (opt.Conflict !== null) {
          var con = opt.Conflict.split('|');
          for (var e in con) {
            if (con[e] == TC_data.Optional[c].PrimaryKey) {
              $('#opt2_' + TC_data.Optional[c].Abr).checkboxradio('disable'); //disable match
            }
          }
        }
      }
    } else {
      opt.status = 0;
    }
    if (opt.status == 1) {
      if (opt.Loadable == 1) {
        if (opt.Fuel != 1) {
          var oStation =
            "<div class='WBSTATION2'>" +
            convertToUserUNITS(opt.LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            ' | ' +
            convertToUserUNITS(opt.LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            '<br />' +
            opt.Description +
            '</div>';
          if (opt.VariableArms == undefined) {
            opt.VariableArms = 0;
            opt.LoadLatMax = 0;
            opt.LoadLongMax = 0;
            opt.LoadLatMin = 0;
            opt.LoadLongMin = 0;
          }
          optLoadString +=
            '<td class="itintable"><input type="radio" onClick="selectSliderInput()"  name="Position2" id="' +
            opt.Abr +
            '2" value="0" alt="Opt" data-variable="' +
            opt.VariableArms +
            '" data-latmax="' +
            opt.LoadLatMax +
            '" data-latmin="' +
            opt.LoadLatMin +
            '" data-longmax="' +
            opt.LoadLongMax +
            '" data-longmin="' +
            opt.LoadLongMin +
            '"  data-latdefault="' +
            opt.LoadLat +
            '" data-longdefault="' +
            opt.LoadLong +
            '"/><label for="' +
            opt.Abr +
            '2"><font size="-2">' +
            opt.Abr +
            '<br />0 ' +
            WBUnitLbl.Weight +
            oStation +
            '</font></label></td>';
          optLoad.push({
            Abr: opt.Abr,
            MaxWT: opt.MaxWT,
            LoadLat: opt.LoadLat,
            LoadLong: opt.LoadLong,
            ExternalLoad: opt.ExternalLoad,
            VariableArms: opt.VariableArms,
            LoadLatMax: opt.LoadLatMax,
            LoadLongMax: opt.LoadLongMax,
            LoadLatMin: opt.LoadLatMin,
            LoadLongMin: opt.LoadLongMin,
          });
        }
      }
      if (opt.Seating == 1) {
        if (SeatType != opt.SeatType) {
          //seating configuration has changed
          SeatChange = true;
          SeatType = opt.SeatType;
          optSeatChange = true;
        } else {
          //seating configuration is the same
          SeatChange = false;
          optSeatChange = true;
        }
      }

      if (opt.Fuel == 1) {
        //plusFuel += parseInt(opt.MaxWT)
        for (f in FUEL_data) {
          if (
            FUEL_data[f].Type == opt.Type &&
            SN >= parseInt(FUEL_data[f].SN_Low) &&
            SN <= parseInt(FUEL_data[f].SN_High)
          ) {
            maxFuel = parseInt(FUEL_data[f].Capacity);
            ACmaxFuel = maxFuel;
            FuelType = opt.Type;
          }
        }
      }
    }
  }

  for (var L in LOADABLE_data) {
    if (LOADABLE_data[L].Fuel == 1) {
      //plusFuel += parseInt(LOADABLE_data[L].MaxWT);
      for (var f in FUEL_data) {
        if (
          FUEL_data[f].Type == LOADABLE_data[L].Type &&
          SN >= parseInt(FUEL_data[f].SN_Low) &&
          SN <= parseInt(FUEL_data[f].SN_High)
        ) {
          maxFuel = parseInt(FUEL_data[f].Capacity);
          FuelStandard = LOADABLE_data[L].Type;
        }
      }
    }
    if (LOADABLE_data[L].Fuel != 1 && LOADABLE_data[L].Loadable == 1) {
      var lStation =
        "<div class='WBSTATION2'>" +
        convertToUserUNITS(LOADABLE_data[L].LoadLong, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        ' | ' +
        convertToUserUNITS(LOADABLE_data[L].LoadLat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
        '<br />' +
        LOADABLE_data[L].Description +
        '</div>';
      if (LOADABLE_data[L].VariableArms == undefined) {
        LOADABLE_data[L].VariableArms = 0;
        LOADABLE_data[L].LoadLatMax = 0;
        LOADABLE_data[L].LoadLongMax = 0;
        LOADABLE_data[L].LoadLatMin = 0;
        LOADABLE_data[L].LoadLongMin = 0;
      }
      optLoadString +=
        '<td class="itintable"><input type="radio" onClick="selectSliderInput()"  name="Position2" id="' +
        LOADABLE_data[L].Abr +
        '2" value="0" alt="Opt" data-variable="' +
        LOADABLE_data[L].VariableArms +
        '" data-latmax="' +
        LOADABLE_data[L].LoadLatMax +
        '" data-latmin="' +
        LOADABLE_data[L].LoadLatMin +
        '" data-longmax="' +
        LOADABLE_data[L].LoadLongMax +
        '" data-longmin="' +
        LOADABLE_data[L].LoadLongMin +
        '"  data-latdefault="' +
        LOADABLE_data[L].LoadLat +
        '" data-longdefault="' +
        LOADABLE_data[L].LoadLong +
        '"/><label for="' +
        LOADABLE_data[L].Abr +
        '2"><font size="-2">' +
        LOADABLE_data[L].Abr +
        '<br />0 ' +
        WBUnitLbl.Weight +
        lStation +
        '</font></label></td>';
      optLoad.push({
        Abr: LOADABLE_data[L].Abr,
        MaxWT: LOADABLE_data[L].MaxWT,
        LoadLat: LOADABLE_data[L].LoadLat,
        LoadLong: LOADABLE_data[L].LoadLong,
        ExternalLoad: LOADABLE_data[L].ExternalLoad,
        VariableArms: LOADABLE_data[L].VariableArms,
        LoadLatMax: LOADABLE_data[L].LoadLatMax,
        LoadLongMax: LOADABLE_data[L].LoadLongMax,
        LoadLatMin: LOADABLE_data[L].LoadLatMin,
        LoadLongMin: LOADABLE_data[L].LoadLongMin,
      });
    }
    if (LOADABLE_data[L].cgRestrict == 1) {
      //set cofg graph standard for what is fixed to aircraft
      GraphName = LOADABLE_data[L].cgGraph;
      GraphStandard = GraphName;
    }
    if (LOADABLE_data[L].Seating == 1) {
      SeatStandard = LOADABLE_data[L].SeatType;
      if (SeatType == 'Standard') SeatType = SeatStandard; //prevent overriding possible optional selected
    }
  }

  if (SeatChange && !optSeatChange) SeatType = SeatStandard;
  if (SeatChange) {
    $('#PaX').html('');
    $('#Seating').html('');
    Loading = [];

    var seatString = '<center><fieldset id="POS2" data-role="controlgroup" data-type="horizontal" data-mini="true">';
    PaXnum = 0;
    for (var a in WB_data.Seating) {
      //Each Row
      if (WB_data.Seating[a].Type == SeatType) {
        var row = WB_data.Seating[a].Row;
        seatString += "<table class='itintable'><tr class='itintable'>";
        for (var b in WB_data.Seating[a].Seats) {
          var Seats = WB_data.Seating[a].Seats[b];
          var Seat = Seats.Seat;
          var WT = Seats.WT;
          var Lat = Seats.Lat;
          var Long = Seats.Long;
          Loading.push({
            Abr: Seat,
            MaxWT: WT,
            LoadLat: Lat,
            LoadLong: Long,
          });
          var pStation =
            '<div id="' +
            Seat +
            '2_NAME"></div>' +
            "<div class='WBSTATION2'>" +
            convertToUserUNITS(Seats.Long, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            ' | ' +
            convertToUserUNITS(Seats.Lat, 'Arm', AC_UNITS, WBUnitLbl.Type) +
            '</div>';
          if (Seat == 'Pilot') {
            var PilotWeight = 206;
            if (LOCALSTORAGE.PilotWeight != undefined && !isNaN(LOCALSTORAGE.PilotWeight)) {
              PilotWeight = LOCALSTORAGE.PilotWeight;
            }
            seatString +=
              '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
              Seat +
              '2" value="' +
              convertToUserUNITS(PilotWeight, 'Weight', 'Imperial', AC_UNITS) +
              '" alt="Pax"/><label for="' +
              Seat +
              '2"><font size="-2">' +
              Seat +
              '<br /> ' +
              convertACToUserUnits(PilotWeight, 'Weight', 'Imperial') +
              pStation +
              '</font></label></td>';
          } else {
            seatString +=
              '<td class="itintable"> <input type="radio" onClick="selectSliderInput()"  name="Position" id="' +
              Seat +
              '2" value="0" alt="Pax"/><label for="' +
              Seat +
              '2"><font size="-2">' +
              Seat +
              '<br />0 ' +
              WBUnitLbl.Weight +
              pStation +
              '</font></label></td>';
          }
          PaXnum++;
        }
        seatString += '</tr></table>';
      }
    }
    $('#PaX2').html(itin_generatePaxInput(PaXnum, paxnum)).enhanceWithin();
    $('#Seating2').append(seatString + '</fieldset>');
    //Re Add baggage to Loading Var
    for (var ab in WB_data.Baggage) {
      for (var bb in WB_data.Baggage[ab].Bags) {
        var Bags = WB_data.Baggage[ab].Bags[bb];
        var Bag = Bags.Baggage;
        var bWT = Bags.WT;
        var bLat = Bags.Lat;
        var bLong = Bags.Long;
        var VariableBaggage = null;
        if (Bags.Variable != undefined) {
          VariableBaggage = Bags.Variable;
          bLat = VariableBaggage[0].CGL;
          bLong = VariableBaggage[0].CG;
        }
        Loading.push({
          Abr: Bag,
          MaxWT: bWT,
          LoadLat: bLat,
          LoadLong: bLong,
          Variable: VariableBaggage,
        });
      }
    }
  }
  if ($('#Fuel2').val() > maxFuel) {
    $('#Fuel2').val(maxFuel);
    $("label[for='Fuel2']").html('<font size="-2">Fuel<br />' + convertACToUserUnits(maxFuel, 'Weight', AC_UNITS));
  }
  if ($('#Fuel2').is(':checked'))
    $('#slider2').attr('max', convertToUserUNITS(maxFuel, 'Weight', AC_UNITS, WBUnitLbl.Type)).slider('refresh');
  $('#optLoad2').append(optLoadString + '</tr></table></fieldset>');
  $("input[name='Position2']").bind('click', positionChange2);
  $('#Pilot2').attr('checked', true);
  //$("#optLoad").enhanceWithin();
  $('#WB_Page2').enhanceWithin();
  //$("#WB_OPT").enhanceWithin();
  tcIndex = 0;
  $('#TCNUM2').html('<h2>' + iTrans('Temp Config') + ' N/A</h2>');
  tcNum = 'N/A';
  if (AC_UNITS == 'Imperial') {
    $('#TCNUM').html(iTrans('Temp Config') + ' #N/A');
    $('#ItinWBUnitsLbl').html(
      iTrans('Temp Config') +
        ' #N/A<br /><b>' +
        iTrans('Aircraft Graph Units') +
        ': </b>' +
        iTrans('Imperial') +
        ' lbs / in'
    );
  } else {
    $('#TCNUM').html(iTrans('Temp Config') + ' #N/A');
    $('#ItinWBUnitsLbl').html(
      iTrans('Temp Config') + ' #N/A<br /><b>' + iTrans('Aircraft Graph Units') + ': </b>kg / mm'
    );
  }

  eacweight = parseFloat(TC_data.TCWeight);
  eLat = parseFloat(TC_data.TCLatMom);
  eLong = parseFloat(TC_data.TCHorzMom);
  ecofgLong = parseFloat(eLong / eacweight).toFixed(2);
  ecofgLat = parseFloat(eLat / eacweight).toFixed(2);
  if (setDefault !== true) {
    save_opt_Status(Defaults, AC_data.ident);
    calculateWB2();
    //updatePaxSeatingNames();
  }
}

function save_opt_Status(opt_Status, ident) {
  $.post(
    BASEURL + 'menu-Maintenance-AJAX.php?action=update_opt_Status&ident=' + encodeURIComponent(ident),
    {
      opt_Status: opt_Status,
    },
    function (json_data) {
      if (json_data[0].ServerResponse != 'Success')
        alert('Problem Saving Optional Equipment Status: ' + json_data[0].Exception);
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
  });
}

function ClearWBLoading() {
  for (var i in Loading) {
    var L = Loading[i];
    if (!L.hasOwnProperty('Variable')) continue;
    $('#' + L.Abr + '2').val(0);
    $("label[for='" + L.Abr + "2']").html('<font size="-2">' + L.Abr + '<br />' + 0 + ' ' + WBUnitLbl.Weight + '');
  }
  for (var I in optLoad) {
    var O = optLoad[I];
    $('#' + O.Abr + '2').val(0);
    $("label[for='" + O.Abr + "2']").html('<font size="-2">' + O.Abr + '<br />' + 0 + ' ' + WBUnitLbl.Weight + '');
  }
  $('#WB_CARGO_TOTAL2').val(0);
  calculateWB2();
}

function ClearWBPaxLoading() {
  var hasSIC = false;
  if (Follow_Data[FOLLOW_INDEX].Itin != null) {
    hasSIC = Follow_Data[FOLLOW_INDEX].Itin.SIC_PK != 0;
    if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
      var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
      if (passengers.length == 0) {
        return;
      }
      for (var p in passengers) {
        let P = passengers[p];
        P.seatPos = '';
      }
    }
  }
  for (var i in Loading) {
    var L = Loading[i];
    if (L.hasOwnProperty('Variable') || ((L.Abr == 'CoPilot' || L.Abr == 'FwdPax') && hasSIC)) continue;
    if (L.Abr != 'Pilot') {
      $('#' + L.Abr + '2').val(0);
      $("label[for='" + L.Abr + "2']").html('<font size="-2">' + L.Abr + '<br />' + 0 + ' ' + WBUnitLbl.Weight + '');
      $('#' + L.Abr + '2_NAME').html('');
    }
  }

  calculateWB2();
}

function positionLoadPax() {}
function positionChange2() {
  $('#slider2').blur();

  if (this.id == 'Fuel2')
    $('#slider2').attr('max', convertToUserUNITS(maxFuel, 'Weight', AC_UNITS, WBUnitLbl.Type)).slider('refresh');
  for (var i in Loading) {
    if (this.id == Loading[i].Abr + '2')
      $('#slider2')
        .attr('max', convertToUserUNITS(Loading[i].MaxWT, 'Weight', AC_UNITS, WBUnitLbl.Type))
        .slider('refresh');
  }
  for (i in optLoad) {
    if (this.id == optLoad[i].Abr + '2')
      $('#slider2')
        .attr('max', convertToUserUNITS(optLoad[i].MaxWT, 'Weight', AC_UNITS, WBUnitLbl.Type))
        .slider('refresh');
  }
  curPosition = this.id;
  $("input[name='slider2']").val(convertToUserUNITS($(this).val(), 'Weight', AC_UNITS, WBUnitLbl.Type));
  $("input[name='slider2']").slider('refresh');

  var id = this.alt;
  if (id == 'Pax') {
    $('#male2').removeClass('ui-disabled');
    $('#female2').removeClass('ui-disabled');
    checkPaxSeatingPos2();
  } else {
    $('#male2').addClass('ui-disabled');
    $('#female2').addClass('ui-disabled');
  }
  weightChangeP2();

  if (this.dataset.variable != undefined) {
    if (this.dataset.variable == 1) {
      $('#VariableArmHLDR2').show();
      $('#VariableLongslider2').attr('max', this.dataset.longmax).slider('refresh');
      $('#VariableLongslider2').attr('min', this.dataset.longmin).slider('refresh');

      $('#VariableLatslider2').attr('max', this.dataset.latmax).slider('refresh');
      $('#VariableLatslider2').attr('min', this.dataset.latmin).slider('refresh');

      $("input[name='VariableLongslider2']").val(this.dataset.longdefault);
      $("input[name='VariableLongslider2']").slider('refresh');
      $("input[name='VariableLatslider2']").val(this.dataset.latdefault);
      $("input[name='VariableLatslider2']").slider('refresh');
    } else {
      $('#VariableArmHLDR2').hide();
    }
  } else {
    $('#VariableArmHLDR2').hide();
  }
}

function checkPaxSeatingPos2() {
  var seatPos = curPosition.slice(0, -1);
  var hasEmpty = true;
  if (Follow_Data[FOLLOW_INDEX].Itin != null) {
    if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
      var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
      if (passengers.length == 0) {
        return;
      }
      for (var p in passengers) {
        let P = passengers[p];
        if (P.seatPos == seatPos) {
          return;
        }
        if (P.seatPos == '' || !P.hasOwnProperty('seatPos')) {
          hasEmpty = true;
        }
      }
      if (hasEmpty) {
        selectPaxSeatPos2();
      }
    }
  }
}

function selectPaxSeatPos2() {
  var seatPos = curPosition.slice(0, -1);
  if (seatPos == 'Pilot') {
    return;
  }
  var html =
    '<table class="first-td-fill"><thead><tr><th>' +
    iTrans('Last, First') +
    '</th><th>' +
    iTrans('Weight') +
    ' ' +
    (WBUnitLbl.Type == 'Imperial' ? 'lbs' : 'kg') +
    '</th><th>' +
    iTrans('Add') +
    '</th></tr></thead><tbody>';
  var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
  var hasAvailable = false;
  for (var p in passengers) {
    let P = passengers[p];
    if (P.seatPos == '' || !P.hasOwnProperty('seatPos')) {
      var Units = 'Metric';
      if (P.units == 'imperial') {
        Units = 'Imperial';
      }
      hasAvailable = true;
      html +=
        '<tr><td>' +
        P.lastname +
        ', ' +
        P.firstname +
        '</td><td>' +
        convertToUserUNITS(P.weight, 'Weight', Units, WBUnitLbl.Type) +
        '</td><td><button onclick="assignPaxSeatPos2(' +
        p +
        ');" data-mini="true" class="action-btn"><img src="./images/Check_mark_green.svg"></button></td></tr>';
    }
  }
  html += '</tbody></table>';
  if (hasAvailable) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: seatPos,
      headerClose: false,
      buttonPrompt: html,
      width: 400,
      buttons: {
        [iTrans('Cancel')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
  }
}
function assignPaxSeatPos2(index) {
  $.mobile.sdCurrentDialog.close();
  var seatPos = curPosition.slice(0, -1);
  var passenger = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][index];
  passenger.seatPos = seatPos;
  ITIN_Pax_Manifest.Pax_Legs[WB_LEG_INDEX][index].seatPos = seatPos;
  var Units = 'Metric';
  if (passenger.units == 'imperial') {
    Units = 'Imperial';
  }
  $("input[name='slider2']").val(convertToUserUNITS(passenger.weight, 'Weight', Units, WBUnitLbl.Type));
  $("input[name='slider2']").slider('refresh');
  weightChange2();
  updatePaxSeatingNames2();
}
function updatePaxSeatingNames2() {
  if (Follow_Data[FOLLOW_INDEX].Itin != null) {
    if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
      var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
      for (var p in passengers) {
        let P = passengers[p];
        if (P.seatPos != '') {
          $('#' + P.seatPos + '2_NAME').html(P.lastname + ', ' + P.firstname);
        }
      }
    }
  }
}

function emptySeatWeight2() {
  $("input[name='slider2']").val(convertToUserUNITS(0, 'Weight', 'Imperial', WBUnitLbl.Type));
  $("input[name='slider2']").slider('refresh');
  var seatPos = curPosition.slice(0, -1);
  if (Follow_Data[FOLLOW_INDEX].Itin != null) {
    if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] != undefined) {
      var passengers = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg];
      for (var p in passengers) {
        let P = passengers[p];
        if (P.seatPos == seatPos) {
          P.seatPos = '';
          ITIN_Pax_Manifest.Pax_Legs[WB_LEG_INDEX][p].seatPos = '';
          break;
        }
      }
    }
  }
  weightChange2();
  updatePaxSeatingNames2();
}
function SlidernumpadChange2(value) {
  $("input[name='slider2']").val(value);
  $("input[name='slider2']").slider('refresh');
  weightChange2();
}

function femaleWeight2() {
  $("input[name='slider2']").val(convertToUserUNITS(GROUP_DATA.FemaleWeight, 'Weight', 'Imperial', WBUnitLbl.Type));
  $("input[name='slider2']").slider('refresh');
  weightChange2();
}

function maleWeight2() {
  $("input[name='slider2']").val(convertToUserUNITS(GROUP_DATA.MaleWeight, 'Weight', 'Imperial', WBUnitLbl.Type));
  $("input[name='slider2']").slider('refresh');
  weightChange2();
}

function weightChange2() {
  var oldlabel = $("label[for='" + curPosition + "']")[0].innerHTML;
  oldlabel = oldlabel.split('<div class="WBSTATION2"');

  var weight = document.getElementById('slider2').value;
  weight = convertToUserUNITS(weight, 'Weight', WBUnitLbl.Type, AC_UNITS);
  if (weight == '') {
    weight = 0;
    document.getElementById('slider2').value = convertToUserUNITS(weight, 'Weight', 'Imperial', WBUnitLbl.Type);
  } else weight = parseInt(weight);

  var label = curPosition.slice(0, -1);
  $("label[for='" + curPosition + "']").html(
    '<font size="-2">' +
      label +
      '<br />' +
      convertACToUserUnits(weight, 'Weight', AC_UNITS) +
      '<div id="' +
      curPosition +
      '_NAME"></div>' +
      '<div class="WBSTATION2"' +
      oldlabel[1]
  );
  $("input[id='" + curPosition + "']").val(weight);
  $('#WB_Page2').enhanceWithin();

  var alt = $('#' + curPosition).attr('alt');

  if (alt == 'Bag') {
    for (var i in Loading) {
      if (Loading[i].Abr == label) {
        if (Loading[i].Variable != undefined) {
          var CGL = null;
          var CG = null;
          for (var l in Loading[i].Variable) {
            var L = Loading[i].Variable[l];
            if (weight >= L.WT) {
              CGL = L.CGL;
              CG = L.CG;
              Loading[i].LoadLat = CGL;
              Loading[i].LoadLong = CG;
            }
          }
          if (CGL != null) {
            var varItem = $('#' + curPosition);
            varItem[0].dataset.longdefault = CG;
            varItem[0].dataset.latdefault = CGL;
            if ($('#WBstations2').is(':checked')) {
              $("label[for='" + curPosition + "']").html(
                '<font size="-2">' +
                  label +
                  '<br />' +
                  convertACToUserUnits(weight, 'Weight', AC_UNITS) +
                  '<div id="' +
                  curPosition +
                  '_NAME"></div>' +
                  '<div class="WBSTATION2" style="display: block;">' +
                  CG +
                  ' | ' +
                  CGL +
                  '</div>'
              );
            } else {
              $("label[for='" + curPosition + "']").html(
                '<font size="-2">' +
                  label +
                  '<br />' +
                  convertACToUserUnits(weight, 'Weight', AC_UNITS) +
                  '<div id="' +
                  curPosition +
                  '_NAME"></div>' +
                  '<div class="WBSTATION2">' +
                  CG +
                  ' | ' +
                  CGL +
                  '</div>'
              );
            }
          }
        } else {
          break;
        }
      }
    }
  }
  calculateWB2();
}

function LongChange2() {
  var arm = document.getElementById('VariableLongslider2').value;
  if (arm == '') {
    arm = 0;
    document.getElementById('VariableLongslider2').value = arm;
  } else arm = parseFloat(arm);
  var label = curPosition.slice(0, -1);
  var LoadLong = 0;
  var LoadLat = 0;
  for (var i in optLoad) {
    if (optLoad[i].Abr == label) {
      if (arm > optLoad[i].LoadLongMax) arm = optLoad[i].LoadLongMax;
      if (arm < optLoad[i].LoadLongMin) arm = optLoad[i].LoadLongMin;
      optLoad[i].LoadLong = arm;
      LoadLong = arm;
      LoadLat = optLoad[i].LoadLat;
    }
  }
  var weight = parseInt($('#slider2').val());
  if ($('#WBstations2').is(':checked')) {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        label +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION2" style="display: block;">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  } else {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        label +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION2">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  }

  $('#WB_Page2').enhanceWithin();
  var varItem = $('#' + curPosition);
  varItem[0].dataset.longdefault = arm;
  calculateWB2();
}

function LatChange2() {
  var arm = document.getElementById('VariableLatslider2').value;
  if (arm == '') {
    arm = 0;
    document.getElementById('VariableLatslider2').value = arm;
  } else arm = parseFloat(arm);
  var label = curPosition.slice(0, -1);
  var LoadLong = 0;
  var LoadLat = 0;
  for (var i in optLoad) {
    if (optLoad[i].Abr == label) {
      if (arm > optLoad[i].LoadLatMax) arm = optLoad[i].LoadLatMax;
      if (arm < optLoad[i].LoadLatMin) arm = optLoad[i].LoadLatMin;
      optLoad[i].LoadLat = arm;
      LoadLat = arm;
      LoadLong = optLoad[i].LoadLong;
    }
  }
  var weight = parseInt($('#slider2').val());
  if ($('#WBstations2').is(':checked')) {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        label +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION2" style="display: block;">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  } else {
    $("label[for='" + curPosition + "']").html(
      '<font size="-2">' +
        label +
        '<br />' +
        convertACToUserUnits(weight, 'Weight', AC_UNITS) +
        '<div id="' +
        curPosition +
        '_NAME"></div>' +
        '<div class="WBSTATION2">' +
        LoadLong +
        ' | ' +
        LoadLat +
        '</div>'
    );
  }
  $('#WB_Page2').enhanceWithin();
  var varItem = $('#' + curPosition);
  varItem[0].dataset.latdefault = arm;
  calculateWB2();
}

function ShowLoadingStations2(e) {
  if (e.checked) $('.WBSTATION2').show();
  else $('.WBSTATION2').hide();
}
function weightChangeP2() {
  var value = document.getElementById('slider2').value;
  if (curPosition == 'Fuel2') {
    if (value < minFuel) {
      value = minFuel;
      document.getElementById('slider2').value = value;
    }
  }
  if (value < 0) {
    value = 0;
    document.getElementById('slider2').value = 0;
  }
  var m = $('#slider2').attr('max');
  value = Math.floor((value / m) * 100);
  $("label[for='slider2']").html('<b>Weight:<br />' + value + '%</b>');
}

function addCargoWeight2() {
  var Total = 0;
  for (var a in WB_data.Baggage) {
    //Each Baggage Compartment
    var bagRow = WB_data.Baggage[a].Row;
    for (b in WB_data.Baggage[a].Bags) {
      var Bags = WB_data.Baggage[a].Bags[b];
      var Bag = Bags.Baggage;
      Total += parseFloat($('#' + Bag + '2').val());
    }
    Total = Math.round(Total * 10) / 10;
    $('#WB_CARGO_TOTAL2').val(Total);
  }
}

function calculateWB2() {
  tident = acIdent;
  var ZFW = WB_data.ZFW;
  var MLW = 0;
  if (WB_data.MLW != undefined) {
    MLW = WB_data.MLW;
  }
  fuelwtArray = [];
  fuelcgArray = [];
  fuelcglArray = [];
  for (var i in FUEL_data) {
    if (FUEL_data[i].Type == FuelType && SN >= parseInt(FUEL_data[i].SN_Low) && SN <= parseInt(FUEL_data[i].SN_High)) {
      var Tank = FUEL_data[i].Tank;
      var Critical = FUEL_data[i].Critical;
      for (a in Tank) {
        fuelwtArray.push(parseFloat(Tank[a].WT));
        fuelcgArray.push(parseFloat(Tank[a].CG));
        fuelcglArray.push(parseFloat(Tank[a].CGL));
      }
    }
  }
  for (var i in TC_data) {
    if (tcNum == TC_data[i].TCNum) {
      for (b in TC_data[i].Optional) {
        var tcItem = TC_data[i].Optional[b];
        if (tcItem.cgRestrict == 1 && tcItem.status == 1) GraphName = tcItem.cgGraph;
      }
    }
  }

  var wbgraph = WB_data.Graph[0];
  var wbset = WB_data;
  var LatGraphType = 'weight';
  for (var i in WB_data.Graph) {
    if (WB_data.Graph[i].Type == GraphName) {
      wbgraph = WB_data.Graph[i];
      LatGraphType = WB_data.Graph[i].LatGraphType;
    }
  }

  x = [];
  y = [];
  var minx = parseFloat(wbgraph.LongGraph[0].x);
  var miny = parseFloat(wbgraph.LongGraph[0].y);
  var maxx = minx;
  var maxy = miny;
  for (var i in wbgraph.LongGraph) {
    var Lx = parseFloat(wbgraph.LongGraph[i].x);
    var Ly = parseFloat(wbgraph.LongGraph[i].y);
    x.push(Lx);
    y.push(Ly);
    if (Lx > maxx) maxx = Lx;
    if (Lx < minx) minx = Lx;
    if (Ly > maxy) maxy = Ly;
    if (Ly < miny) miny = Ly;
  }
  xlabel = [];
  for (var i in wbgraph.xLabel) {
    xlabel.push(wbgraph.xLabel[i].xlabel);
  }
  ylabel = [];
  for (var i in wbgraph.yLabel) {
    ylabel.push(wbgraph.yLabel[i].ylabel);
  }
  x2 = [];
  y2 = [];
  maxLong = 0;
  minLing = 0;
  maxLeft = 0;
  maxRight = 0;
  if (wbgraph.LatGraph != null) {
    var minx2 = parseFloat(wbgraph.LatGraph[0].x);
    var miny2 = parseFloat(wbgraph.LatGraph[0].y);
    var maxx2 = minx2;
    var maxy2 = miny2;
    for (i in wbgraph.LatGraph) {
      var Lx2 = parseFloat(wbgraph.LatGraph[i].x);
      var Ly2 = parseFloat(wbgraph.LatGraph[i].y);
      x2.push(Lx2);
      y2.push(Ly2);
      if (Lx2 > maxx2) maxx2 = Lx2;
      if (Lx2 < minx2) minx2 = Lx2;
      if (Ly2 > maxy2) maxy2 = Ly2;
      if (Ly2 < miny2) miny2 = Ly2;
    }

    maxLong = maxx;
    minLong = minx;
    maxLeft = miny2;
    maxRight = maxy2;
  }
  maxweight = parseFloat(wbgraph.maxweight);
  maxperfweight = maxweight;
  maxExternal = parseFloat(wbgraph.maxExternal);
  if (maxExternal == undefined || maxExternal == 0) maxExternal = maxperfweight;
  if ($('#AC_MAX_PERF2').val() == '') {
    if (maxExternal > maxweight) {
      $('#AC_MAX_PERF2').val(maxExternal);
      maxperfweight = maxExternal;
    } else $('#AC_MAX_PERF2').val(maxweight);
  } else {
    maxperfweight = parseInt($('#AC_MAX_PERF2').val());
    if (maxperfweight >= maxExternal) {
      maxperfweight = maxExternal;
      $('#AC_MAX_PERF2').val(maxExternal);
    }
  }
  var MaxPerfLine = [];
  MaxPerfLine.push({ x: maxx, y: maxperfweight });
  MaxPerfLine.push({ x: minx, y: maxperfweight });

  var MLWLine = [];
  if (MLW > 0) {
    MLWLine.push({ x: maxx, y: MLW });
    MLWLine.push({ x: minx, y: MLW });
  }

  error = '';

  var fLong = 0;
  var fLat = 0;
  var addwt = 0;

  var EXTLong = 0;
  var EXTLat = 0;
  var EXTwt = 0;
  var ExternalCalc = false;
  for (var i in Loading) {
    fLong += parseFloat(Loading[i].LoadLong) * parseInt($('#' + Loading[i].Abr + '2').val());
    fLat += parseFloat(Loading[i].LoadLat) * parseInt($('#' + Loading[i].Abr + '2').val());
    addwt += parseInt($('#' + Loading[i].Abr + '2').val());
  }
  for (var i in optLoad) {
    if (optLoad[i].ExternalLoad != 1) {
      fLat += parseFloat(optLoad[i].LoadLat) * parseInt($('#' + optLoad[i].Abr + '2').val());
      fLong += parseFloat(optLoad[i].LoadLong) * parseInt($('#' + optLoad[i].Abr + '2').val());
      addwt += parseInt($('#' + optLoad[i].Abr + '2').val());
    } else {
      EXTwt += parseInt($('#' + optLoad[i].Abr + '2').val());
      if (EXTwt > 0) {
        EXTLat += parseFloat(optLoad[i].LoadLat) * parseInt($('#' + optLoad[i].Abr + '2').val());
        EXTLong += parseFloat(optLoad[i].LoadLong) * parseInt($('#' + optLoad[i].Abr + '2').val());
        ExternalCalc = true;
      }
    }
  }
  fuelwt = parseInt(document.getElementById('Fuel2').value);
  if (fuelwt < minFuel) {
    fuelwt = minFuel;
  }
  FuelRemain = fuelwt;

  addwt += fuelwt;
  for (var i in fuelwtArray) {
    if (fuelwtArray[i] <= fuelwt) {
      fuelLong = fuelcgArray[i];
      fuelLat = fuelcglArray[i];
    }
  }

  //Static Weight and Balance Load Poisitions
  fLong = fLong + fuelLong * fuelwt;
  fLat = fLat + fuelLat * fuelwt;

  //initial takeoff cofg calculation
  facweight = eacweight + addwt;
  zfacweight = facweight - fuelwt;
  fLat = eLat + fLat;
  fLong = eLong + fLong;
  fcofgLat = fLat / facweight;
  fcofgLong = fLong / facweight;
  nofLat = fuelwt * fuelLat;
  nofLong = fuelwt * fuelLong;
  nofLat = fLat - nofLat;
  nofLong = fLong - nofLong;
  nofwt = facweight - fuelwt;
  nofcofg = nofLong / nofwt;
  nofcofgLat = nofLat / nofwt;
  //start graphing calculations
  Mac = nofwt * nofcofg;
  MacL = nofwt * nofcofgLat;
  criticalCG = [];
  criticalLabel = [];
  criticalWT = [];
  criticalLat = [];
  criticalLat.push(nofcofgLat);
  criticalCG.push(nofcofg);
  criticalWT.push(nofwt);
  criticalLabel.push('No Fuel');
  var Latline = null;
  Longline = [
    {
      x: nofcofg,
      y: nofwt,
    },
  ];

  if (LatGraphType == 'weight')
    Latline = [
      {
        x: nofcofgLat,
        y: nofwt,
      },
    ];
  if (LatGraphType == 'cg')
    Latline = [
      {
        x: nofcofgLat,
        y: nofcofg,
        w: nofwt,
      },
    ];

  for (var i in fuelwtArray) {
    if (fuelwtArray[i] <= fuelwt) {
      cg = fuelcgArray[i];
      cgl = fuelcglArray[i];
      Mfuel = cg * fuelwtArray[i];
      MfuelL = cgl * fuelwtArray[i];
      Mtotal = Mfuel + Mac;
      MtotalL = MfuelL + MacL;
      Wtotal = fuelwtArray[i] + nofwt;
      lasti = i;
      Tcofg = Mtotal / Wtotal;
      TcofgL = MtotalL / Wtotal;
      for (a in Critical) {
        if (parseFloat(Critical[a].WT) == fuelwtArray[i]) {
          criticalLat.push(fLat / Wtotal);
          criticalCG.push(Tcofg);
          criticalLabel.push(Critical[a].Label);
          criticalWT.push(Wtotal);
          if (LatGraphType == 'cg')
            Latline.push({
              x: TcofgL,
              y: Tcofg,
              w: Wtotal,
            });
        }
      }
      Longline.push({
        x: Tcofg,
        y: Wtotal,
      });
      if (LatGraphType == 'weight')
        Latline.push({
          x: TcofgL,
          y: Wtotal,
        });
    }
  }
  cg = fuelcgArray[lasti];
  Mfuel = cg * fuelwt;
  Mtotal = Mfuel + Mac;
  Wtotal = fuelwt + nofwt;
  Tcofg = Mtotal / Wtotal;
  TcofgL = MtotalL / Wtotal;
  Longline.push({
    x: Tcofg,
    y: Wtotal,
  });
  if (LatGraphType == 'weight')
    Latline.push({
      x: TcofgL,
      y: Wtotal,
    });
  if (LatGraphType == 'cg')
    Latline.push({
      x: TcofgL,
      y: Tcofg,
      w: Wtotal,
    });
  criticalLat.push(fLat / Wtotal);
  criticalCG.push(Tcofg);
  criticalLabel.push('Takeoff');
  criticalWT.push(Wtotal);

  error = "<table class='limit-table wrap'><thead><tr><th>Weight & Balance Overview</th></tr></thead>";

  for (var i in criticalCG) {
    fcofgLong = criticalCG[i];
    errorLabel = criticalLabel[i];
    facweight = criticalWT[i];
    fcofgLat = criticalLat[i];

    if (pnpoly(x.length, x, y, fcofgLong, facweight) == false) {
      error += '<tr class="yellowbg"><td>WARNING! - ' + errorLabel + ' CofG BEYOND LIMITS</td></tr>';
    }
    if (LatGraphType != 'cg' && wbgraph.LatGraph != null) {
      if (pnpoly(x2.length, x2, y2, facweight, fcofgLat) == false) {
        error += '<tr class="yellowbg"><td>WARNING! - ' + errorLabel + ' CofG BEYOND LATERAL LIMITS</td></tr>';
      }
    } else {
      if (pnpoly(x2.length, x2, y2, fcofgLong, fcofgLat) == false && wbgraph.LatGraph != null) {
        error += '<tr class="yellowbg"><td>WARNING! - ' + errorLabel + ' CofG BEYOND LATERAL LIMITS</td></tr>';
      }
    }
  } // end for loop for criticalCG
  if (facweight > maxweight)
    error += '<tr class="yellowbg"><td>WARNING! - Over maximum internal weight limits</td></tr>';
  if (ZFW != null)
    if (zfacweight > ZFW) error += '<tr class="yellowbg"><td>WARNING! - Over maximum Zero Fuel Weight</td></tr>';
  facweight = Math.round(facweight);
  fcofgLong = Math.round(fcofgLong * 100) / 100;
  fcofgLat = Math.round(fcofgLat * 100) / 100;
  externalMax = maxExternal - facweight;
  UsefulLoad = maxweight - facweight;
  SafetyPCT = Math.round((UsefulLoad / maxweight) * 100);
  goodWB = 0;

  var ELongline = null;
  var ELatline = null;
  var ELongArm = '';
  var ELatArm = '';
  if (EXTwt > 0) {
    ELongline = JSON.parse(JSON.stringify(Longline));
    if (Latline != null) ELatline = JSON.parse(JSON.stringify(Latline));
    for (var e in ELongline) {
      var E = ELongline[e];
      var ELongMoment = E.x * E.y + EXTLong;
      var ELongWeight = EXTwt + E.y;
      E.x = ELongMoment / ELongWeight;
      E.y = ELongWeight;
      ELongArm = E.x;
    }
    if (Latline != null) {
      for (var e in ELatline) {
        var E = ELatline[e];
        if (LatGraphType == 'cg') {
          var ELatMoment = E.x * E.w + EXTLat;
          var ELatWeight = EXTwt + E.w;
          E.x = ELatMoment / ELatWeight;
          if (e == 0) E.y = ELongline[0].x;
          else E.y = ELongline[ELongline.length - 1].x;
          ELatArm = E.x;
        } else {
          var ELatMoment = E.x * E.y + EXTLat;
          var ELatWeight = EXTwt + E.y;
          E.x = ELatMoment / ELatWeight;
          E.y = ELatWeight;
          ELatArm = E.x;
        }
      }
    }
    if (facweight + EXTwt > maxExternal && AC_data.Class == 'Helicopter')
      error += '<tr class="yellowbg"><td>WARNING! - Over maximum external weight limits</td></tr>';
    var xLongE = [];
    var yLongE = [];
    var xLatE = [];
    var yLatE = [];
    if (wbgraph.ExternalLongGraph != undefined) {
      for (var i in wbgraph.ExternalLongGraph) {
        xLongE.push(parseFloat(wbgraph.ExternalLongGraph[i].x));
        yLongE.push(parseFloat(wbgraph.ExternalLongGraph[i].y));
      }
    }
    if (wbgraph.ExternalLatGraph != undefined) {
      for (var i in wbgraph.ExternalLatGraph) {
        xLatE.push(parseFloat(wbgraph.ExternalLatGraph[i].x));
        yLatE.push(parseFloat(wbgraph.ExternalLatGraph[i].y));
      }
    }
    var ExternalLongFailed = false;
    var ExternalLatFailed = false;
    for (var i in ELongline) {
      var eLongCofG = ELongline[i].x;
      var eWeightCofG = ELongline[i].y;
      var eLatCofG;
      if (Latline != null) {
        if (ELatline[i] == undefined) eLatCofG = false;
        else eLatCofG = ELatline[i].x;
      } else {
        eLatCofG = false;
      }

      if (
        pnpoly(xLongE.length, xLongE, yLongE, eLongCofG, eWeightCofG) == false &&
        !ExternalLongFailed &&
        wbgraph.ExternalLongGraph != undefined
      ) {
        ExternalLongFailed = true;
        error += '<tr class="yellowbg"><td>WARNING! - External Load CofG BEYOND LIMITS</td></tr>';
      }
      if (LatGraphType != 'cg' && wbgraph.LatGraph != null && wbgraph.ExternalLatGraph != undefined) {
        if (
          pnpoly(xLatE.length, xLatE, yLatE, eWeightCofG, eLatCofG) == false &&
          !ExternalLatFailed &&
          eLatCofG !== false
        ) {
          ExternalLatFailed = true;
          error += '<tr class="yellowbg"><td>WARNING! - External Load CofG BEYOND LATERAL LIMITS</td></tr>';
        }
      } else {
        if (
          pnpoly(xLatE.length, xLatE, yLatE, eLongCofG, eLatCofG) == false &&
          wbgraph.LatGraph != null &&
          !ExternalLatFailed &&
          wbgraph.ExternalLatGraph != undefined &&
          eLatCofG !== false
        ) {
          ExternalLatFailed = true;
          error += '<tr class="yellowbg"><td>WARNING! - External Load CofG BEYOND LATERAL LIMITS</td></tr>';
        }
      }
    } // end for loop for criticalCG
  }
  //update fuel label CofG used
  if ($('#WBstations2').is(':checked')) {
    $("label[for='Fuel2']").html(
      '<font size="-2">Fuel<br />' +
        convertACToUserUnits(fuelwt, 'Weight', AC_UNITS) +
        '<div class="WBSTATION2" style="display: block;">' +
        fuelLong +
        ' | ' +
        fuelLat +
        '</div>'
    );
  } else {
    $("label[for='Fuel2']").html(
      '<font size="-2">Fuel<br />' +
        convertACToUserUnits(fuelwt, 'Weight', AC_UNITS) +
        '<div class="WBSTATION2">' +
        fuelLong +
        ' | ' +
        fuelLat +
        '</div>'
    );
  }
  if (error == "<table class='limit-table wrap'><thead><tr><th>Weight & Balance Overview</th></tr></thead>") goodWB = 1;

  var totalExternalSummary = '';
  if (EXTwt > 0) {
    totalExternalSummary =
      '<tr><td>Total aircraft weight: ' + convertACToUserUnits(EXTwt + facweight, 'Weight', AC_UNITS, 2) + '</td></tr>';
    var AllUpEXTwt = EXTwt + facweight;
    var EXTSafetyPCT = Math.round(100 - (AllUpEXTwt / maxperfweight) * 100);
    var EXTUsefulLoad = maxperfweight - AllUpEXTwt;
    if (EXTUsefulLoad < 0) {
      var EXTOverBy = parseInt(AllUpEXTwt - maxperfweight);
      if (AC_data.restrict_MaxPerfExt == 1) {
        goodWB = 0;
        error +=
          "<tr class='yellowbg'><td>" +
          iTrans('WARNING') +
          ' -  ' +
          iTrans('External Load') +
          ' ' +
          convertACToUserUnits(EXTOverBy, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('beyond maximum weight for density altitude') +
          '.</td></tr>';
      } else {
        totalExternalSummary +=
          "<tr class='yellowbg'><td>" +
          iTrans('WARNING') +
          ' -  ' +
          iTrans('External Load') +
          ' ' +
          convertACToUserUnits(EXTOverBy, 'Weight', AC_UNITS, 0) +
          ' ' +
          iTrans('beyond maximum weight for density altitude') +
          '.</td></tr>';
      }
    } else {
      totalExternalSummary +=
        '<tr><td>' +
        iTrans('External Load Safety Margin of') +
        ' ' +
        EXTSafetyPCT +
        '% ' +
        iTrans('or') +
        ' ' +
        convertACToUserUnits(EXTUsefulLoad, 'Weight', AC_UNITS, 0) +
        ' ' +
        iTrans('from maximum weight for density altitude') +
        '.</td></tr>';
    }
  }
  if (WANDB == '1') {
    error +=
      '<tr><td>Total aircraft internal weight: ' +
      convertACToUserUnits(facweight, 'Weight', AC_UNITS, 2) +
      '</td></tr>' +
      totalExternalSummary +
      '<tr><td>Longitudinal C of G: ' +
      convertACToUserUnits(fcofgLong, 'Arm', AC_UNITS, 2) +
      '</td></tr><tr><td>Lateral C of G: ' +
      convertACToUserUnits(fcofgLat, 'Arm', AC_UNITS, 2) +
      '</td></tr><tr><td>C of G Within Limits for all critical fuel burn positions.</td></tr>';

    if (AC_data.Class == 'Helicopter')
      error +=
        '<tr><td>Maximum External Load: ' + convertACToUserUnits(externalMax, 'Weight', AC_UNITS, 0) + '</td></tr>';

    error +=
      '<tr><td>Internal Load Safety Margin of ' +
      SafetyPCT +
      '% or ' +
      convertACToUserUnits(UsefulLoad, 'Weight', AC_UNITS, 0) +
      ' from maximum certified allowable.</td></tr>';
    if (maxweight > maxperfweight) {
      var SafetyPCT2 = Math.round(100 - (facweight / maxperfweight) * 100);
      var UsefulLoad2 = maxperfweight - facweight;
      if (UsefulLoad2 < 0) {
        var OverBy = parseInt(facweight - maxperfweight);
        if (AC_data.restrict_MaxPerf == 1) {
          goodWB = 0;
          error +=
            "<tr class='yellowbg'><td>WARNING -  Internal Load " +
            convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
            ' beyond maximum weight for density altitude.</td></tr>';
        } else {
          error +=
            "<tr class='yellowbg'><td>WARNING -  Internal Load " +
            convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
            ' beyond maximum weight for density altitude.</td></tr>';
        }
      } else {
        error +=
          '<tr><td>Internal Load Safety Margin of ' +
          SafetyPCT2 +
          '% or ' +
          convertACToUserUnits(UsefulLoad2, 'Weight', AC_UNITS, 0) +
          ' from maximum weight for density altitude.</td></tr>';
      }
    }
    if (tcNum != 'N/A') {
      error +=
        '<tr><td>' +
        iTrans('ENSURE TEMP CONFIG') +
        ' # ' +
        tcNum +
        ' ' +
        iTrans('IS ACTIVE ON AIRCRAFT TECHNICAL DISPATCH CARD') +
        '</td></tr>';
    }
  } else {
    error +=
      '<tr><td>Aircraft takeoff weight: ' +
      convertACToUserUnits(facweight, 'Weight', AC_UNITS, 2) +
      '</td></tr><tr><td>Longitudinal C of G: ' +
      convertACToUserUnits(fcofgLong, 'Arm', AC_UNITS, 2) +
      '</td></tr><tr><td>Lateral C of G: ' +
      convertACToUserUnits(fcofgLat, 'Arm', AC_UNITS, 2) +
      '</td></tr><tr><td>C of G Within Limits for all critical fuel burn positions.</td></tr>';

    if (AC_data.Class == 'Helicopter')
      error +=
        '<tr><td>Maximum External Load: ' + convertACToUserUnits(externalMax, 'Weight', AC_UNITS, 0) + '</td></tr>';

    error +=
      '<tr><td>Internal Load Safety Margin of ' +
      SafetyPCT +
      '% or ' +
      convertACToUserUnits(UsefulLoad, 'Weight', AC_UNITS, 0) +
      ' from maximum certified allowable.</td></tr>';
    if (maxweight > maxperfweight) {
      var SafetyPCT2 = Math.round(100 - (facweight / maxperfweight) * 100);
      var UsefulLoad2 = maxperfweight - facweight;
      if (UsefulLoad2 < 0) {
        var OverBy = parseInt(facweight - maxperfweight);
        if (AC_data.restrict_MaxPerf == 1) {
          goodWB = 0;
          error +=
            "<tr class='yellowbg'><td>WARNING -  Internal Load " +
            convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
            ' beyond maximum weight for density altitude.</td></tr>';
        } else {
          error +=
            "<tr class='yellowbg'><td>WARNING -  Internal Load " +
            convertACToUserUnits(OverBy, 'Weight', AC_UNITS, 0) +
            ' beyond maximum weight for density altitude.</td></tr>';
        }
      } else {
        error +=
          '<tr><td>Internal Load Safety Margin of ' +
          SafetyPCT2 +
          '% or ' +
          convertACToUserUnits(UsefulLoad2, 'Weight', AC_UNITS, 0) +
          ' from maximum weight for density altitude.</td></tr>';
      }
    }
  }
  if (goodWB == 1) {
    //Update File Now Text - W&B Problem
    $('#WBupdate').prop('disabled', false);
    $('#WBupdate').html('Save / Activate Leg');
    //$("#WBupdate").button("refresh");
    $('#WBupdate2').prop('disabled', false);
    $('#WBupdate2').html('Save / Activate Leg');
    $('#WB_NEXT_BTN').prop('disabled', false);
    $('#WB_PREV_BTN').prop('disabled', false);
    $('#exitWBPage2').prop('disabled', false);
    //$("#WBupdate2").button("refresh");
    $('#WB_LegErrorTXT').html('');
  } else {
    error = '';
    if (GROUP_DATA.WB_Warn_Off != 1) {
      error = '<div style="color:red;">' + error + '</div>';
      $('#WBupdate').prop('disabled', true);
      //Update File Now Text - W&B Problem
      $('#WBupdate').val('W&B Problem');
      //$("#WBupdate").button("refresh");
      $('#WBupdate2').prop('disabled', true);
      //Update File Now Text - W&B Problem
      $('#WBupdate2').val('W&B Problem');

      $('#WB_NEXT_BTN').prop('disabled', true);
      $('#WB_PREV_BTN').prop('disabled', true);
      if (WB_UPDATE) $('#exitWBPage2').prop('disabled', true);
      $('#WB_LegErrorTXT').html('<h1 class="yellowbg">Weight and Balance Beyond Limits!</h1>');
    } else {
      $('#WBupdate').prop('disabled', false);
      $('#WBupdate').html('Save / Activate Leg');
      //$("#WBupdate").button("refresh");
      $('#WBupdate2').prop('disabled', false);
      $('#WBupdate2').html('Save / Activate Leg');
      $('#WB_NEXT_BTN').prop('disabled', false);
      $('#WB_PREV_BTN').prop('disabled', false);
      $('#exitWBPage2').prop('disabled', false);
      $('#WB_LegErrorTXT').html('');
    }
  }
  $('#WB_summary2').html('');

  $('#WB_summary2').append(
    '<h4>' +
      acIdent +
      ' Amendment #' +
      TC_data[tcIndex].ANum +
      '<br />Dated: ' +
      TC_data[tcIndex].Date +
      '<br />Temp Configuration # ' +
      tcNum +
      '<br />Detailed Loading Info:<br /></h4>'
  );
  $('#WB_summary2').append(
    '<table data-role="table" class="ui-responsive">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">Position</th>' +
      '<th data-priority="1">Weight (' +
      WBUnitLbl.Weight +
      ')</th>' +
      '<th data-priority="2">Long (' +
      WBUnitLbl.Arm +
      ')</th>' +
      '<th data-priority="persist">Lat (' +
      WBUnitLbl.Arm +
      ')</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody><tr>'
  );
  $('#WB_summary2 tr:last').after(
    '<tr><td>Aircraft</td><td>' +
      convertACToUserUnits(eacweight, 'Weight', AC_UNITS, 2) +
      '</td><td>' +
      convertACToUserUnits(ecofgLong, 'Arm', AC_UNITS, 2) +
      '</td><td>' +
      convertACToUserUnits(ecofgLat, 'Arm', AC_UNITS, 2) +
      '</td></tr>'
  );

  $('#WB_summary2 tr:last').after(
    '<tr><td>Fuel</td><td>' +
      convertACToUserUnits(fuelwt, 'Weight', AC_UNITS, 2) +
      '</td><td>' +
      convertACToUserUnits(fuelLong, 'Arm', AC_UNITS, 2) +
      '</td><td>' +
      convertACToUserUnits(fuelLat, 'Arm', AC_UNITS, 2) +
      '</td></tr>'
  );

  for (i in Loading) {
    $('#WB_summary2 tr:last').after(
      '<tr><td>' +
        Loading[i].Abr +
        '</td><td>' +
        convertACToUserUnits($('#' + Loading[i].Abr + '2').val(), 'Weight', AC_UNITS, 2) +
        '</td><td>' +
        convertACToUserUnits(Loading[i].LoadLong, 'Arm', AC_UNITS, 2) +
        '</td><td>' +
        convertACToUserUnits(Loading[i].LoadLat, 'Arm', AC_UNITS, 2) +
        '</td></tr>'
    );
  }
  var ExternalSummary = '';
  for (i in optLoad) {
    if (optLoad[i].ExternalLoad != 1)
      $('#WB_summary2 tr:last').after(
        '<tr><td>' +
          optLoad[i].Abr +
          '</td><td>' +
          convertACToUserUnits($('#' + optLoad[i].Abr + '2').val(), 'Weight', AC_UNITS, 2) +
          '</td><td>' +
          optLoad[i].LoadLong +
          '</td><td>' +
          optLoad[i].LoadLat +
          '</td></tr>'
      );
    else
      ExternalSummary =
        '<tr><td>' +
        optLoad[i].Abr +
        '</td><td>' +
        $('#' + optLoad[i].Abr + '2').val() +
        '</td><td>' +
        convertACToUserUnits(optLoad[i].LoadLong, 'Arm', AC_UNITS, 2) +
        '</td><td>' +
        convertACToUserUnits(optLoad[i].LoadLat, 'Arm', AC_UNITS, 2) +
        '</td></tr>';
  }
  $('#WB_summary2 tr:last').after(
    '<tr><td><h4>Total Internal</h4></td><td><h4>' +
      convertACToUserUnits(facweight, 'Weight', AC_UNITS, 2) +
      '</h4></td><td><h4>' +
      convertACToUserUnits(fcofgLong, 'Arm', AC_UNITS, 2) +
      '</h4></td><td><h4>' +
      convertACToUserUnits(fcofgLat, 'Arm', AC_UNITS, 2) +
      '</h4></td></tr></tbody></table>'
  );
  if (EXTwt > 0) {
    $('#WB_summary2 tr:last').after('<tr><td><h3>External Load Items</h3></td><td></td><td></td><td></td>');
    $('#WB_summary2 tr:last').after(ExternalSummary);
    ELongArm = Math.round(ELongArm * 10) / 10;
    ELatArm = Math.round(ELatArm * 10) / 10;
    $('#WB_summary2 tr:last').after(
      '<tr><td><h4>Total All Up Weight</h4></td><td><h4>' +
        convertACToUserUnits(EXTwt + facweight, 'Weight', AC_UNITS, 2) +
        '</h4></td><td><h4>' +
        convertACToUserUnits(ELongArm, 'Arm', AC_UNITS, 2) +
        '</h4></td><td><h4>' +
        convertACToUserUnits(ELatArm, 'Arm', AC_UNITS, 2) +
        '</h4></td></tr></tbody></table>'
    );
  }
  $('#wbReport2').html(error);

  var longgraphArray = [];
  for (i in wbgraph.eLongGraph) {
    longgraphArray.push(wbgraph.eLongGraph[i].Graph);
    for (b in wbgraph.eLongGraph[i].Graph) {
      var elonX = parseFloat(wbgraph.eLongGraph[i].Graph[b].x);
      var elonY = parseFloat(wbgraph.eLongGraph[i].Graph[b].y);
      if (elonX > maxx) maxx = elonX;
      if (elonX < minx) minx = elonX;
      if (elonY > maxy) maxy = elonY;
      if (elonY < miny) miny = elonY;
    }
  }
  var latgraphArray = [];
  for (i in wbgraph.eLatGraph) {
    latgraphArray.push(wbgraph.eLatGraph[i].Graph);
    for (b in wbgraph.eLatGraph[i].Graph) {
      var elatX = parseFloat(wbgraph.eLatGraph[i].Graph[b].x);
      var elatY = parseFloat(wbgraph.eLatGraph[i].Graph[b].y);
      if (elatX > maxx2) maxx2 = elatX;
      if (elatX < minx2) minx2 = elatX;
      if (elatY > maxy2) maxy2 = elatY;
      if (elatY < miny2) miny2 = elatY;
    }
  }
  var xLabel = [];
  for (i in wbgraph.xLabel) {
    xLabel.push(wbgraph.xLabel[i].xlabel);
  }
  var yLabel = [];
  for (i in wbgraph.yLabel) {
    yLabel.push(wbgraph.yLabel[i].ylabel);
  }
  var xLabel2 = [];
  for (i in wbgraph.xLabel2) {
    xLabel2.push(wbgraph.xLabel2[i].xlabel2);
  }
  var yLabel2 = [];
  for (i in wbgraph.yLabel2) {
    yLabel2.push(wbgraph.yLabel2[i].ylabel2);
  }
  $('#LongCanvas2').html('');

  drawGraph(
    '#LongCanvas2',
    wbgraph.LongGraph,
    longgraphArray,
    Longline,
    ELongline,
    [minx, maxx],
    [miny, maxy],
    150,
    200,
    'Long',
    xlabel,
    yLabel,
    MaxPerfLine,
    MLWLine
  );

  var MLWLine2 = [];
  $('#LatCanvas2').html('');
  if (wbgraph.LatGraph != null)
    drawGraph(
      '#LatCanvas2',
      wbgraph.LatGraph,
      latgraphArray,
      Latline,
      ELatline,
      [miny2, maxy2],
      [minx2, maxx2],
      150,
      200,
      'Lat',
      yLabel2,
      xLabel2,
      null,
      MLWLine2
    );
}

function UpdateWB(silent = false, action = 'none') {
  $('#WBupdate').prop('disabled', true);
  $('#WBupdate').val('Saving Weight and Balance...');
  $('#WBupdate2').prop('disabled', true);
  $('#WBupdate2').val('Saving Weight and Balance...');

  var canvas = document.getElementById('Canvas2');
  canvas.getElementsByTagName('svg')[0].id = 'svgLongCanvas2';
  if (canvas.getElementsByTagName('svg')[1] != undefined) {
    canvas.getElementsByTagName('svg')[1].id = 'svgLatCanvas2';
  }
  svgAsDataUri(document.getElementById('svgLongCanvas2'), 1, function (uriLong) {
    var imageLong = new Image();
    imageLong.src = uriLong;
    imageLong.onload = function () {
      if (document.getElementById('svgLatCanvas2') != undefined) {
        svgAsDataUri(document.getElementById('svgLatCanvas2'), 1, function (uriLat) {
          var imageLat = new Image();
          imageLat.src = uriLat;
          imageLat.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = 320;
            canvas.height = 200;
            var context = canvas.getContext('2d');
            context.drawImage(imageLong, 0, 0);
            context.drawImage(imageLat, 170, 0);
            var png = canvas.toDataURL('image/png');

            SendWBData(png, silent, action);
          };
        });
      } else {
        var canvas = document.createElement('canvas');
        canvas.width = 320;
        canvas.height = 200;
        var context = canvas.getContext('2d');
        context.drawImage(imageLong, 0, 0);
        //context.drawImage(imageLat,170,0);
        var png = canvas.toDataURL('image/png');

        SendWBData(png, silent, action);
        return;
      }
    };
  });
}

function checkItinNoShutdown() {
  if (Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX - 1] != undefined) {
    var LL = Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX - 1];
    if (LL.noShutdown == 1) {
      if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
        $('#LegStart2E').val(LL.LegEndE);
      }
      $('#LegStartEHLDR2').hide();
    } else {
      if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) $('#LegStartEHLDR2').show();
      else $('#LegStartEHLDR2').hide();
    }
  } else {
    if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) $('#LegStartEHLDR2').show();
    else $('#LegStartEHLDR2').hide();
  }

  if ($('#noShutdown2').is(':checked')) {
    try {
      Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].noShutdown = 1;
    } catch (e) {
      console.log(e);
    }
    if (Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX - 1] != undefined) {
      $('#LegStartEHLDR2').hide();
      $('#LegEnd2E').val($('#LegEnd2').val());
    } else {
      if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
        $('#LegStartEHLDR2').show();
      } else {
        $('#LegStartEHLDR2').hide();
      }
    }
    $('#LegEndEHLDR2').hide();
  } else {
    try {
      Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].noShutdown = 0;
    } catch (e) {
      console.log(e);
    }

    if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
      $('#LegStartEHLDR2').show();
      $('#LegEndEHLDR2').show();
    } else {
      $('#LegStartEHLDR2').hide();
      $('#LegEndEHLDR2').hide();
    }
  }
  if (Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX - 1] != undefined) {
    if (Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX - 1].noShutdown == 1) {
      $('#LegStartEHLDR2').hide();
      $('#LegStart2E').val(Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX - 1].LegEnd);
      if ($('#LegStart2').val() == '0') {
        $('#LegStart2').val(null);
      }
    } else {
      if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
        $('#LegStartEHLDR2').show();
      }
    }
  } else {
    if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 1) {
      $('#LegStartEHLDR2').show();
    }
  }
  getFlightTotals();
}

function getFlightTotals() {
  var FT = 0;
  var USFT = 0;
  if (Follow_Data[FOLLOW_INDEX].WB.length > 0) {
    var Legs = Follow_Data[FOLLOW_INDEX].WB;
    for (var l in Legs) {
      if (l > WB_LEG_INDEX) {
        break;
      }
      var L = Legs[l];
      if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 0) {
        if ($('#LegEnd2').val() === '0' && l == WB_LEG_INDEX) {
          continue;
        }
        if (l == WB_LEG_INDEX) {
          FT += getDecimalTime($('#LegStart2').val(), $('#LegEnd2').val());
          USFT += getDecimalTime($('#LegStart2').val(), $('#LegEnd2').val());
        } else {
          FT += getDecimalTime(L.LegStart, L.LegEnd);
          USFT += getDecimalTime(L.LegStart, L.LegEnd);
        }
      } else {
        if (
          $('#LegEnd2E').val() === '0' &&
          l == WB_LEG_INDEX &&
          Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].noShutdown == 0
        ) {
          continue;
        }
        if (
          $('#LegEnd2').val() === '0' &&
          l == WB_LEG_INDEX &&
          Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].noShutdown == 1
        ) {
          continue;
        }
        if (l == WB_LEG_INDEX) {
          if (Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX].noShutdown == 0) {
            FT += getDecimalTime($('#LegStart2E').val(), $('#LegEnd2E').val());
            USFT += getDecimalTime($('#LegStart2').val(), $('#LegEnd2').val());
          } else {
            FT += getDecimalTime($('#LegStart2E').val(), $('#LegEnd2').val());
            USFT += getDecimalTime($('#LegStart2').val(), $('#LegEnd2').val());
          }
        } else {
          if (L.noShutdown == 0) {
            FT += getDecimalTime(L.LegStartE, L.LegEndE);
            USFT += getDecimalTime(L.LegStart, L.LegEnd);
          } else {
            FT += getDecimalTime(L.LegStartE, L.LegEnd);
            USFT += getDecimalTime(L.LegStart, L.LegEnd);
          }
        }
      }
    }
  }
  if (COUNTRY != 'UNITED STATES') {
    $('#itinTotalFT').html(' - ' + iTrans('Total') + ': ' + round10(FT) + 'hrs FT');
  } else {
    if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 0) {
      $('#itinTotalFT').html(' - ' + iTrans('Total') + ': ' + round10(USFT) + 'hrs Flight');
    } else {
      $('#itinTotalFT').html(
        ' - ' + iTrans('Total') + ': ' + round10(FT) + ' hrs Block / ' + round10(USFT) + 'hrs Flight'
      );
    }
  }
}
function SendWBData(Graph, silent, action) {
  checkItinNoShutdown();

  var WBPK = Follow_Data[FOLLOW_INDEX].Itin.PrimaryKey;
  var OfflinePK = Follow_Data[FOLLOW_INDEX].Itin.OfflinePK;
  var otherwt = 0;
  //var otherwt = attachedwt; //add array of passenger weights and baggage weights and attached weights
  var wbDate = TC_data[tcIndex].Date; //get wbDate from weight and balance stuff
  var Ehmom = TC_data[tcIndex].TCHorzMom;
  var Elmom = TC_data[tcIndex].TCLatMom;
  var TCdesc = tcDesc;
  var WBSummary = '';
  for (var i in Loading) {
    if (Loading[i].Variable != undefined) {
      WBSummary +=
        Loading[i].Abr +
        ':' +
        $('#' + Loading[i].Abr + '2').val() +
        ':' +
        Loading[i].LoadLong +
        ':' +
        Loading[i].LoadLat +
        ',';
    } else {
      if (Loading[i].VariableArms == 1) {
        WBSummary +=
          Loading[i].Abr +
          ':' +
          $('#' + Loading[i].Abr + '2').val() +
          ':' +
          Loading[i].LoadLong +
          ':' +
          Loading[i].LoadLat +
          ',';
      } else {
        WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr + '2').val() + ',';
      }
    }

    otherwt += parseFloat($('#' + Loading[i].Abr + '2').val());
  }
  for (var i in optLoad) {
    if (optLoad[i].VariableArms == 1) {
      WBSummary +=
        optLoad[i].Abr +
        ':' +
        $('#' + optLoad[i].Abr + '2').val() +
        ':' +
        optLoad[i].LoadLong +
        ':' +
        optLoad[i].LoadLat +
        ',';
    } else {
      WBSummary += optLoad[i].Abr + ':' + $('#' + optLoad[i].Abr + '2').val() + ',';
    }
    otherwt += parseFloat($('#' + optLoad[i].Abr + '2').val());
  }
  var CargoWeight = $('#WB_CARGO_TOTAL2').val();
  var Pilot = $('#Pilots2').val();
  var flightCatID = $('#WB_flightCatID').val();
  var PilotWeight = convertToUserUNITS(parseInt($('#Pilot2').val()), 'Weight', AC_UNITS, 'Imperial');
  LOCALSTORAGE.PilotWeight = PilotWeight;
  localStorageDB.setItem('PilotWeight', PilotWeight);
  var SIC = $('#SICs2').val();
  var LegStartE = convertWB_Time_Value($('#LegStart2E').val());
  var LegStart = convertWB_Time_Value($('#LegStart2').val());
  var LegEnd = convertWB_Time_Value($('#LegEnd2').val());
  var LegEndE = convertWB_Time_Value($('#LegEnd2E').val());
  if (Follow_Data[FOLLOW_INDEX].AC.StartStop == 0) {
    LegStartE = LegStart;
    LegEndE = LegEnd;
  }

  var LegOrigin = $('#LegOrigin2').val();
  var LegRouting = $('#LegRouting2').val();
  var LegDest = $('#LegDest2').val();
  var LegETA = $('#LegETA2').val();
  var PaxNum = $('#LegPassengerCount2').val();
  var Description = $('#LegComments2').val();
  var now = new Date();
  var timezone = now.getTimezoneOffset(); //returns time in minutes ie: GMT+2,  -120
  var Pax_Manifest = null;
  if (GROUP_DATA.Pax_Man == 1) {
    if (ITIN_Pax_Manifest.DefaultList != undefined) {
      var hasVIP = 0;
      for (var p in ITIN_Pax_Manifest.DefaultList) {
        var P = ITIN_Pax_Manifest.DefaultList[p];
        if (P.VIP == 1) {
          hasVIP = 1;
          break;
        }
      }
      for (var l in ITIN_Pax_Manifest.Pax_Legs) {
        var L = ITIN_Pax_Manifest.Pax_Legs[l];
        for (var p in L) {
          var P = L[p];
          if (P.VIP == 1) {
            hasVIP = 1;
            break;
          }
        }
      }
      Pax_Manifest = {
        DefaultList: JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList)),
        Pax_Legs: JSON.parse(JSON.stringify(ITIN_Pax_Manifest.Pax_Legs)),
        HasVIP: hasVIP,
      };
    }
  }

  var LegNum = WB_LEG_INDEX;
  var Changed = new Date().toISOString();
  Changed = Changed.replace('T', ' ');
  Changed = Changed.replace('Z', '');

  var Active = 1;
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (page == 'Map_Page') {
    //$("#WB_LegNum_pop").html("Leg " + (WB_LEG_INDEX + 1) + " (Active)");
  } else {
    if (action == 'next') Active = 0;
    if (WB_LEG_INDEX != Follow_Data[FOLLOW_INDEX].WB.length - 1 && action == 'prev') Active = 0;
    var ActiveLeg = '';
    if (Active == 1) ActiveLeg = ' (Active)';
    if (!silent) {
      $('#WB_LegNum').html('Leg ' + (WB_LEG_INDEX + 1) + ActiveLeg + ' <span id="itinTotalFT"></span>');
      getFlightTotals();
    }
  }
  var PerLegFields = [];
  var CF = JSON.parse(JSON.stringify(ITIN_Cfields));
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
        PerLegFields.push(I);
      }
    }
  }

  var ForceOFFLINE = false;
  if (OfflinePK != 0 && WBPK == 0) ForceOFFLINE = true; //To ensure even if we're online to store thie leg data with the offline itinerary so we don't end up with sync conflicts
  var RAformLEG = null;
  if (RAform != null) {
    if (RAform.LEGS[LegNum] != undefined) RAformLEG = JSON.stringify(RAform.LEGS[LegNum]);
  }

  var FuelPlans = 'None';
  if (PLANAC != null) {
    FuelPlans = JSON.parse(JSON.stringify(MEASURE.LEGS));
    Follow_Data[FOLLOW_INDEX].FuelPlan = FuelPlans;
  }
  var FuelUplifts = null;
  if (GROUP_DATA.FuelCache == 1 && GROUP_DATA.FC_Itin_Capture == 1) {
    if (FC_Uplift.upLiftList[WB_LEG_INDEX] != undefined) {
      FuelUplifts = FC_Uplift.upLiftList;
    } else {
      FC_UpliftSelected(0);
      FuelUplifts = FC_Uplift.upLiftList;
    }
  }
  var SendData = {
    Cfields: PerLegFields,
    WB_PK: WBPK,
    WB_OfflinePK: OfflinePK,
    LegNum: LegNum,
    timezone: timezone,
    LegStartE: LegStartE,
    LegStart: LegStart,
    LegEnd: LegEnd,
    LegEndE: LegEndE,
    LegETA: LegETA,
    LegOrigin: LegOrigin,
    LegRouting: LegRouting,
    LegDest: LegDest,
    Pilot: Pilot,
    sic: SIC,
    SIC: SIC,
    flightCatID: flightCatID,
    acwt: facweight,
    fuelwt: fuelwt,
    otherwt: otherwt,
    CargoWeight: CargoWeight,
    cofglat: fcofgLat,
    cofglong: fcofgLong,
    tempconfig: tcNum,
    wbDate: wbDate,
    Eweight: eacweight,
    maxperfweight: maxperfweight,
    Ehmom: Ehmom,
    Elmom: Elmom,
    TCdesc: TCdesc,
    Summary: WBSummary,
    GRAPH: Graph,
    PaxNum: PaxNum,
    Description: Description,
    Changed: Changed,
    Active: Active,
    RAformLEG: RAformLEG,
    FuelPlans: FuelPlans,
    Pax_Manifest: Pax_Manifest,
    FuelUplifts: FuelUplifts,
    noShutdown: $('#noShutdown2').is(':checked') ? 1 : 0,
    TDGRevamp: window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS?.length > 0 ? 1 : 0,
  };
  Follow_Data[FOLLOW_INDEX].WB[WB_LEG_INDEX] = SendData;

  if (Follow_Data[FOLLOW_INDEX].Itin != null) {
    Follow_Data[FOLLOW_INDEX].Itin.Pax_Manifest = Pax_Manifest;
  }

  if (RAformLEG != null) {
    Follow_Data[FOLLOW_INDEX].Itin.RA_Data.LEGS[WB_LEG_INDEX] = JSON.parse(RAformLEG);
  }
  createFollowDB(Follow_Data); //update offline follow data
  if (silent) {
    SYNC_FOLLOW = true;
    $('#WBupdate').prop('disabled', false);
    $('#WBupdate').html('Save / Activate Leg');
    //$("#WBupdate").button("refresh");
    $('#WBupdate2').prop('disabled', false);
    $('#WBupdate2').html('Save / Activate Leg');
    //$("#WBupdate2").button("refresh");
    //refreshFollowData(Follow_Data);
  }
  ITIN_CALLBACK.action = action;
  ITIN_CALLBACK.silent = silent;
  updateWB_Itin(SendData, ForceOFFLINE, silent, updateWB_Itin_Callback);
}
var ITIN_CALLBACK = {};
function updateWB_Itin_Callback() {
  if (ITIN_CALLBACK.action == 'next') {
    WB_Leg_Next2();
  }
  if (ITIN_CALLBACK.action == 'prev') {
    WB_Leg_Prev2();
  }
  if (ITIN_CALLBACK.action == 'none') {
    WB_updateNavBTN(true);
  }
  console.log(ITIN_CALLBACK.action, ITIN_CALLBACK.silent);
}
function updateWB_Itin(SendData, ForceOFFLINE, silent, callback) {
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (ONLINE && !ForceOFFLINE) {
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Itinerary-AJAX.php?action=updateItinWB',
      data: SendData,
      timeout: 5000, //5 seconds
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText

        $('#WBupdate').prop('disabled', false);
        $('#WBupdate').html('Save / Activate Leg');
        //$("#WBupdate").button("refresh");
        $('#WBupdate2').prop('disabled', false);
        $('#WBupdate2').html('Save / Activate Leg');
        //$("#WBupdate2").button("refresh");
        //ensure data[0].ServerResponse == Success
        if (data[0].ServerResponse == 'Uncaught Exception') {
          storeOfflineWB(SendData, callback);
        }
        if (data[0].ServerResponse == 'Success') {
          FC_Uplift.upLiftList = data[0].FC_Uplifts;
          FC_UpliftLegSelect();
          callback();
          if (silent) {
            //$("#LongCanvas2").html("");
            //$("#LatCanvas2").html("");

            //SYNC_FOLLOW = true;
            if (page == 'Map_Page') {
              SYNC_FOLLOW = true;
              getFollowData(); //create copy of local follow data
            }
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Success',
              headerClose: false,
              buttonPrompt: 'Weight and Balance Updated Successfully.',
              buttons: {
                Return: {
                  click: function () {
                    //$("#exitWBSummaryPage").unbind('click',hrefHandler);
                    //$("#showWBSummaryPage").unbind('click',hrefHandler);

                    if (RETURN_MAP) returnToMapB();
                    else
                      $.mobile.changePage($('#MainMenu'), {
                        reverse: true,
                        changeHash: false,
                      });
                    $('#LongCanvas2').html('');
                    $('#LatCanvas2').html('');
                    SYNC_FOLLOW = true;
                    getFollowData(); //create copy of local follow data
                  },
                },
                'Stay on Page': {
                  click: function () {
                    SYNC_FOLLOW = true;
                    getFollowData(true); //create copy of local follow data
                  },
                },
              },
            });
          }
        }
        if (data[0].ServerResponse == 'Offline') {
          //ServerResponse(data[0], "SendWBData()");
          storeOfflineWB(SendData, callback);
          $('#WBupdate').prop('disabled', false);
          $('#WBupdate').html('Save / Activate Leg');
          //$("#WBupdate").button("refresh");
          $('#WBupdate2').prop('disabled', false);
          $('#WBupdate2').html('Save / Activate Leg');
          //$("#WBupdate2").button("refresh");
          if (silent) {
            //$("#LongCanvas2").html("");
            //$("#LatCanvas2").html("");
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Success',
              headerClose: false,
              buttonPrompt:
                'Currently Offline<br /><b>Weight and Balance will be uploaded to server when a connection is established.</b>',
              buttons: {
                Return: {
                  click: function () {
                    //$("#exitWBSummaryPage").unbind('click',hrefHandler);
                    //$("#showWBSummaryPage").unbind('click',hrefHandler);
                    if (RETURN_MAP) returnToMapB();
                    else
                      $.mobile.changePage($('#MainMenu'), {
                        reverse: true,
                        changeHash: false,
                      });
                    $('#LongCanvas2').html('');
                    $('#LatCanvas2').html('');

                    SYNC_FOLLOW = true;
                    //refreshFollowData(Follow_Data);
                  },
                },
                'Stay on Page': {
                  click: function () {
                    SYNC_FOLLOW = true;
                    //refreshFollowData(Follow_Data);
                  },
                },
              },
            });
          }
        }
      })
      .fail(function (data) {
        //Failed store data in FollowData and database then return to main menu
        storeOfflineWB(SendData, callback);

        $('#WBupdate').prop('disabled', false);
        $('#WBupdate').html('Save / Activate Leg');
        //$("#WBupdate").button("refresh");
        $('#WBupdate2').prop('disabled', false);
        $('#WBupdate2').html('Save / Activate Leg');
        if (silent) {
          //$("#LongCanvas2").html("");
          //$("#LatCanvas2").html("");
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Warning',
            headerClose: false,
            buttonPrompt:
              'Offline / Connection Error<br /><b>Weight and Balance will be uploaded to server when a stable connection is established.</b>',
            buttons: {
              Return: {
                click: function () {
                  //$("#exitWBSummaryPage").unbind('click',hrefHandler);
                  //$("#showWBSummaryPage").unbind('click',hrefHandler);
                  if (RETURN_MAP) returnToMapB();
                  else
                    $.mobile.changePage($('#MainMenu'), {
                      reverse: true,
                      changeHash: false,
                    });
                  $('#LongCanvas2').html('');
                  $('#LatCanvas2').html('');
                  SYNC_FOLLOW = true;
                  refreshFollowData(Follow_Data);
                },
              },
              'Stay on Page': {
                click: function () {
                  SYNC_FOLLOW = true;
                  refreshFollowData(Follow_Data);
                },
              },
            },
          });
        }
      });
  } else {
    WAIT_FOR_OFFLINE_UPLOAD = true;
    storeOfflineWB(SendData, callback);
    $('#WBupdate').prop('disabled', false);
    $('#WBupdate').html('Save / Activate Leg');
    //$("#WBupdate").button("refresh");
    $('#WBupdate2').prop('disabled', false);
    $('#WBupdate2').html('Save / Activate Leg');
    if (silent) {
      //$("#LongCanvas2").html("");
      //$("#LatCanvas2").html("");
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Warning',
        headerClose: false,
        buttonPrompt:
          'Offline<br /><b>Weight and Balance will be uploaded to server when a stable connection is established.</b>',
        buttons: {
          Return: {
            click: function () {
              //$("#exitWBSummaryPage").unbind('click',hrefHandler);
              //$("#showWBSummaryPage").unbind('click',hrefHandler);
              if (RETURN_MAP) returnToMapB();
              else
                $.mobile.changePage($('#MainMenu'), {
                  reverse: true,
                  changeHash: false,
                });
              $('#LongCanvas2').html('');
              $('#LatCanvas2').html('');
              //SYNC_FOLLOW = true;
              //refreshFollowData(Follow_Data);
            },
          },
          'Stay on Page': {
            click: function () {
              //SYNC_FOLLOW = true;
              //refreshFollowData(Follow_Data);
            },
          },
        },
      });
    }
  }
}

function returnToMapB() {
  $.mobile.changePage($('#Map_Page'), {
    reverse: true,
    changeHash: false,
  });
  INIT_MAPB(false);
}

function selectSliderInput2() {
  $('#slider2').focus().select();
}

function adjustTimezone(tzDate, tzTime, toTimezone, fromTimezone, addMinutes = 0, stripColen = true) {
  try {
    tzTime = tzTime.toString();
    tzTime = tzTime.replace(':', '');
    switch (tzTime.length) {
      case 0:
        tzTime = '00:00:00';
        break;
      case 1:
        tzTime = '00:0' + tzTime + ':00';
        break;
      case 2:
        tzTime = '00:' + tzTime + ':00';
        break;
      case 3:
        tzTime = '0' + tzTime.substr(0, 1) + ':' + tzTime.substr(1, 2) + ':00';
        break;
      case 4:
        tzTime = tzTime.substr(0, 2) + ':' + tzTime.substr(2, 2) + ':00';
        break;
      case 6:
        tzTime = tzTime.substr(0, 2) + ':' + tzTime.substr(2, 2) + ':' + tzTime.substr(4, 2);
        break;
    }
    let date = new Date(tzDate + 'T' + tzTime + 'Z');
    date.setMinutes(date.getMinutes() - Get_TZ_Difference(toTimezone, fromTimezone, true) + addMinutes);
    tzTime = date.toISOString().substr(11, 5);
    if (stripColen) tzTime = tzTime.replace(':', '');
    return {
      date: date.toISOString().substr(0, 10),
      time: tzTime,
    };
  } catch (e) {
    console.log(e);
    return {
      date: '',
      time: '',
    };
  }
}
