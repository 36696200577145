class TDG_DATABASE {
  static implementation = new DatabaseTdg();

  static query(table, query, optional, callback) {
    if (typeof callback !== 'function') {
      callback = () => {};
    }

    TDG_DATABASE.implementation.select(table, query, optional).then(
      (res) => callback(res),
      () => callback(false)
    );
  }
}

class cirroDB {
  static query(table, query, optional, callback) {
    queryCirro_DB('SELECT', table, query, optional, callback);
  }

  static insert(table, data, optional, callback) {
    if (!Array.isArray(data) && data != null) {
      data = [data];
    }

    queryCirro_DB('INSERT', table, data, optional, callback);
  }

  static update(table, data, optional, callback) {
    queryCirro_DB('UPDATE', table, data, optional, callback);
  }

  static Delete(table, PrimaryKey, key, callback) {
    queryCirro_DB('DELETE', table, PrimaryKey, key, callback);
  }

  static clearAll(table, callback) {
    queryCirro_DB('DROP', table, null, null, callback);
  }
}

function queryCirro_DB(action, table, data, optional, callback) {
  if (typeof callback != 'function') {
    callback = () => {};
  }

  if (!Array.isArray(optional) && optional != null) {
    optional = [optional];
  }

  switch (table) {
    case 'FuelCachesV2':
      // DONE REFACTORING
      queryCirro_DB_FuelCachesV2(action, table, data, optional, callback);
      break;
    case 'FuelCachesHistoryV2':
      // DONE REFACTORING
      queryCirro_DB_FuelCachesHistoryV2(action, table, data, optional, callback);
      break;
    case 'FuelCacheUplifts':
      // DONE REFACTORING
      queryCirro_DB_FuelCacheUplifts(action, table, data, optional, callback);
      break;
    case 'AC':
      // DONE REFACTORING
      queryCirro_DB_AC(action, table, data, optional, callback);
      break;
    case 'Pilots':
      // DONE REFACTORING
      queryCirro_DB_Pilots(action, table, data, optional, callback);
      break;
    case 'FDT_DaysV4':
      // DONE REFACTORING
      queryCirro_DB_FDT_DaysV4(action, table, data, optional, callback);
      break;
    case 'FDT_RecsV3':
      // DONE REFACTORING
      queryCirro_DB_FDT_RecsV3(action, table, data, optional, callback);
      break;
    case 'FDT_StaffV3':
      // DONE REFACTORING
      queryCirro_DB_FDT_StaffV3(action, table, data, optional, callback);
      break;
    case 'Sync':
      // DONE REFACTORING
      queryCirro_DB_Sync(action, table, data, optional, callback);
      break;
    case 'WB':
      // DONE REFACTORING
      queryCirro_DB_WB(action, table, data, optional, callback);
      break;
    case 'FDT_Rule':
      // DONE REFACTORING
      queryCirro_DB_FDT_Rule(action, table, data, optional, callback);
      break;
    case 'Waypoints':
      // DONE REFACTORING
      queryCirro_DB_Waypoints(action, table, data, optional, callback);
      break;
    case 'WaypointGroups':
      // DONE REFACTORING
      queryCirro_DB_WaypointGroups(action, table, data, optional, callback);
      break;
    case 'WaypointFeatures':
      // DONE REFACTORING
      queryCirro_DB_WaypointFeatures(action, table, data, optional, callback);
      break;
    case 'Schedule':
      // DONE REFACTORING
      queryCirro_DB_Schedule(action, table, data, optional, callback);
      break;
    case 'Scheduler_Bookings':
      // DONE REFACTORING
      queryCirro_DB_Scheduler_Bookings(action, table, data, optional, callback);
      break;
    case 'Scheduler_Individual':
      // DONE REFACTORING
      queryCirro_DB_Scheduler_Individual(action, table, data, optional, callback);
      break;
    case 'Following':
      // DONE REFACTORING
      queryCirro_DB_Following(action, table, data, optional, callback);
      break;
    case 'mapData':
      // DONE REFACTORING
      queryCirro_DB_mapData(action, table, data, optional, callback);
      break;
    case 'Mbtiles':
      // DONE REFACTORING
      queryCirro_DB_Mbtiles(action, table, data, optional, callback);
      break;
    case 'Airports':
      // DONE REFACTORING
      queryCirro_DB_Airports(action, table, data, optional, callback);
      break;
    case 'GPS_Tracks':
      // DONE REFACTORING
      queryCirro_DB_GPS_Tracks(action, table, data, optional, callback);
      break;
    case 'TDG_Group_Data':
      // DONE REFACTORING
      queryCirro_DB_TDG(action, table, data, optional, callback);
      break;
    case 'RiskForms':
      // DONE REFACTORING
      queryCirro_DB_RiskForms(action, table, data, optional, callback);
      break;
    case 'Itinerary':
      // DONE REFACTORING
      queryCirro_DB_Itinerary(action, table, data, optional, callback);
      break;
    case 'PaxMan':
      // DONE REFACTORING
      queryCirro_DB_PaxMan(action, table, data, optional, callback);
      break;
    case 'Passengers':
      // DONE REFACTORING
      queryCirro_DB_Passengers(action, table, data, optional, callback);
      break;
    case 'PassengerCfields':
      // DONE REFACTORING
      queryCirro_DB_PassengerCfields(action, table, data, optional, callback);
      break;
    case 'Tasks':
      // DONE REFACTORING
      queryCirro_DB_Tasks(action, table, data, optional, callback);
      break;
    case 'Objectives':
      // DONE REFACTORING
      queryCirro_DB_Objectives(action, table, data, optional, callback);
      break;
    case 'BillingClient':
      queryCirro_DB_BillingClient(action, table, data, optional, callback);
      break;
    default:
      const err = new Error('Unknown table: ' + table);
      window.vueApp.sentry.captureException(err);
      verbose.error('SQL', 'Low Level Database Error', {
        action,
        table,
        data,
        optional,
        err,
      });
      break;
  }
}

function queryCirro_DB_TDG(action, table, data, optional, callback) {
  const tRef = TdgGroupDataTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete('PrimaryKey = 1').then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Airports(action, table, data, optional, callback) {
  const tRef = AirportsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_GPS_Tracks(action, table, data, optional, callback) {
  const tRef = GpsTracksTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Mbtiles(action, table, data, optional, callback) {
  const tRef = MbTilesTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Following(action, table, data, optional, callback) {
  const tRef = FollowingTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (r) => {
          // This was odd in the original code, so I'm keeping it here.
          // While parsing the json in the rows, the 'item' variable is being set, and the callback receives only 'item'.
          //
          //  while (i < result.length) {
          //           var item = result.item(i);     <-- here
          // -- 8< --
          // callback(item);                          <-- it follows that we're returning the last state of 'item' here

          if (r.length <= 0) {
            callback(null);
            return;
          }

          callback(r[r.length - 1]);
        },
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_mapData(action, table, data, optional, callback) {
  const tRef = MapDataTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (r) => {
          // This was odd in the original code, so I'm keeping it here.
          // While parsing the json in the rows, the 'item' variable is being set, and the callback receives only 'item'.
          //
          //  while (i < result.length) {
          //           var item = result.item(i);     <-- here
          // -- 8< --
          // callback(item);                          <-- it follows that we're returning the last state of 'item' here

          if (r.length <= 0) {
            callback(null);
            return;
          }

          callback(r[r.length - 1]);
        },
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Schedule(action, table, data, optional, callback) {
  const tRef = ScheduleTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Scheduler_Bookings(action, table, data, optional, callback) {
  const tRef = SchedulerBookingsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Tasks(action, table, data, optional, callback) {
  const tRef = TasksTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Objectives(action, table, data, optional, callback) {
  const tRef = ObjectivesTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Scheduler_Individual(action, table, data, optional, callback) {
  const tRef = SchedulerIndividualTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Waypoints(action, table, data, optional, callback) {
  const tRef = WaypointsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_WaypointGroups(action, table, data, optional, callback) {
  const tRef = WaypointGroupsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_WaypointFeatures(action, table, data, optional, callback) {
  const tRef = WaypointFeaturesTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FDT_Rule(action, table, data, optional, callback) {
  const tRef = FdtRuleTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_WB(action, table, data, optional, callback) {
  const tRef = WbTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Sync(action, table, data, optional, callback) {
  const tRef = SyncTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FDT_DaysV4(action, table, data, optional, callback) {
  const tRef = FdtDaysV4Table.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FDT_RecsV3(action, table, data, optional, callback) {
  const tRef = FdtRecsV3Table.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FDT_StaffV3(action, table, data, optional, callback) {
  const tRef = FdtStaffV3Table.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        (_) => callback(true),
        (e) => {
          if (e.message.indexOf('JobNums') !== -1) {
            promptRebuildFDT();
            return; // This was implicit in the original code (no callback(...))
          }

          callback(false);
        }
      );
      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Pilots(action, table, data, optional, callback) {
  const tRef = PilotsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_AC(action, table, data, optional, callback) {
  const tRef = AcTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FuelCachesV2(action, table, data, optional, callback) {
  const tRef = FuelCachesTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FuelCachesHistoryV2(action, table, data, optional, callback) {
  const tRef = FuelCachesHistoryTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_FuelCacheUplifts(action, table, data, optional, callback) {
  const tRef = FuelCacheUpliftsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_RiskForms(action, table, data, optional, callback) {
  const tRef = RiskFormsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Itinerary(action, table, data, optional, callback) {
  const tRef = ItineraryTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
    case 'UPDATE':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_PaxMan(action, table, data, optional, callback) {
  const tRef = PaxManTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'UPDATE':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_Passengers(action, table, data, optional, callback) {
  if (!Array.isArray(optional)) {
    optional = [optional];
  }

  const tRef = PassengersTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );
      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

function queryCirro_DB_PassengerCfields(action, table, data, optional, callback) {
  const tRef = PassengerCustomFieldsTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}

let REBUILD_PENDING = false;

function promptRebuildFDT() {
  if (REBUILD_PENDING) {
    return;
  }

  REBUILD_PENDING = true;
  update_Sync('LastFDTsync', new Date());
  if (!ANDROID) {
    $('<div>').simpledialog2({
      themeDialog: 'a',
      mode: 'button',
      animate: false,
      headerText: 'Database Upgrading',
      headerClose: false,
      buttonPrompt: 'Your Flight Duty Time / Timesheet database will be upgraded now.',
      buttons: {
        Upgrade: {
          click: function () {
            //BackgroundSync();
            cirroDB.clearAll('FDT_DaysV4', function (e) {
              restartCirro();
            });
          },
        },
      },
    });
  } else {
    $('<div>').simpledialog2({
      themeDialog: 'a',
      mode: 'button',
      animate: false,
      headerText: 'Database Upgrading',
      headerClose: false,
      buttonPrompt:
        'Your Flight Duty Time / Timesheet database requires upgrading. Please re-launch Cirro to complete the upgrade.',
      buttons: {
        Upgrade: {
          click: function () {
            //BackgroundSync();
            cirroDB.clearAll('FDT_DaysV4', function (e) {
              restartCirro();
            });
          },
        },
      },
    });
  }
}

function queryCirro_DB_BillingClient(action, table, data, optional, callback) {
  const tRef = BillingClientTable.query();

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'INSERT':
      tRef.insert(data).then(
        () => callback(true),
        () => callback(false)
      );

      return;
    case 'SELECT':
      tRef.select(data, optional).then(
        (res) => callback(res),
        () => callback(false)
      );

      return;
    case 'DELETE':
      tRef.delete(data, optional).then(
        () => callback(true),
        () => callback(false)
      );

      return;
  }
}
