var FuelPlan = [];
var FuelPlans = [];
var RoutePlans = [];
var FuelRemain = 0;
var FuelRadius = null;
var PLANAC = null;
var PLANAC_UNITS = 'Imperial';
var groupversion = 1;
var RoutePlanningON = false;

function CAS_to_CAS(IAS, Alt, Temp, inHg) {
  var lapserate = 0.0019812; // degrees / foot std. lapse rate C� in to K� result
  var tempcorr = 273.15; // deg Kelvin
  var stdtemp0 = 288.15; // deg Kelvin
  var wasFeet = true; // default: feet
  var wasInches = true; // default: inches
  var wasCelsius = true; // default: Celsius

  isFeet = true;
  isInches = true;
  isCelsius = true;

  function roundit(thenum) {
    return Math.floor(thenum + 0.5);
  }
  function twoplace(number) {
    if (isNaN(number)) return number;

    number = Math.round(100 * number);
    var whole = Math.floor(number / 100);
    var mods = number % 100;
    var decimal = mods.toString();
    if (mods < 10) decimal = '0' + decimal;
    return whole.toString() + '.' + decimal;
  }
  function compute() {
    altFactor = isFeet ? 1 : 3.28084;
    setFactor = isInches ? 1 : 0.02953;

    IA = Alt;
    altstg = inHg;
    temp = Temp;

    if (!isCelsius) temp = (temp + 40) * (5 / 9) - 40;

    xx = (setFactor * altstg) / 29.92126;
    PA = IA + 145442.2 * altFactor * (1 - Math.pow(xx, 0.190261));
    PA = roundit(PA);

    stdtemp = stdtemp0 - PA * lapserate;

    Tratio = stdtemp / altFactor / lapserate;
    xx = stdtemp / (temp + tempcorr); // for temp in deg C
    DA = PA + Tratio * altFactor * (1 - Math.pow(xx, 0.234969));
    DA = roundit(DA);

    aa = DA * lapserate; // Calculate DA temperature
    bb = stdtemp0 - aa; // Correct DA temp to Kelvin
    cc = bb / stdtemp0; // Temperature ratio
    cc1 = 1 / 0.234969; // Used to find .235 root next
    dd = Math.pow(cc, cc1); // Establishes Density Ratio
    dd = Math.pow(dd, 0.5); // For TAS, square root of DR
    ee = 1 / dd; // For TAS; 1 divided by above
    var cas = IAS;
    ff = ee * cas;
    return roundit(ff);
  }
  return compute();
}

function clearRoutePlanLayers() {
  if (mapb == null) return; //map isn't initialized
  if (mapb.getSource('ROUTEPLAN-ACTIVE') != undefined) {
    mapb.removeLayer('ROUTEPLAN-ACTIVE');
    mapb.removeSource('ROUTEPLAN-ACTIVE');

    mapb.removeControl(finishRoutecontrol);
    mapb.removeControl(drawRoutecontrol);
    mapb.addControl(drawRoutecontrol, 'top-left');
    mapb.removeControl(deleteRoutecontrol);
  }
  if (mapb.getSource('ROUTEPLAN-LINES') != undefined) {
    mapb.removeLayer('ROUTEPLAN-LINES');
    mapb.removeLayer('ROUTEPLAN-LINES-LABELS');
    mapb.removeSource('ROUTEPLAN-LINES');
  }
  if (mapb.getSource('ROUTEPLAN-RADIUS') != undefined) {
    mapb.removeLayer('ROUTEPLAN-RADIUS');
    mapb.removeLayer('ROUTEPLAN-RADIUS-LABELS');
    mapb.removeSource('ROUTEPLAN-RADIUS');
  }
  if (mapb.getSource('ROUTEPLAN-OBSTACLES') != undefined) {
    mapb.removeLayer('ROUTEPLAN-OBSTACLES');
    mapb.removeLayer('ROUTEPLAN-OBSTACLES-AGL');
    mapb.removeLayer('ROUTEPLAN-OBSTACLES-ASL');
    mapb.removeSource('ROUTEPLAN-OBSTACLES');
    mapb.removeSource('ROUTEPLAN-OBSTACLES-AGL');
    mapb.removeSource('ROUTEPLAN-OBSTACLES-ASL');
  }
  if (MAPB_OBSTACLES) PLANAC = null; //we're not making a route for an itinerary
}

var ACFuel = 1000;
var ACmaxFuel = 1000;
var ACFuelBurn = 300;
var ACcruise = 120;
var ACground = 120;
var ACalt = 1000;
var ACdepart = '0';

function PercentStop() {
  //console.log(this.value);
  FuelRemain = Math.floor((ACmaxFuel * this.value) / 100);
  var FW;
  FW = FuelRemain;
  if (AC_UNITS == 'Metric') FW = Math.round(FW * 2.20462);
  var ppG = 1;
  switch (rpFueltype) {
    case 'Jeta':
      ppG = JETA;
      break;
    case 'Jetb':
      ppG = JETB;
      break;
    case 'Avgas':
      ppG = AVGAS;
      break;
  }
  switch (rpUnits) {
    case 'USg':
      FW = Math.round(FW / ppG);
      break;
    case 'Metrickg':
      FW = Math.round(FW * PtoKG);
      break;
    case 'Metricl':
      FW = Math.round((FW / ppG) * GtoLT);
      break;
  }
  $('#rpFuel').val(FW);
}

function FuelqtyChange() {
  console.log('QTY Change');
  var FW;
  FW = ACmaxFuel;
  var ppG = 1;
  switch (rpFueltype) {
    case 'Jeta':
      ppG = JETA;
      break;
    case 'Jetb':
      ppG = JETB;
      break;
    case 'Avgas':
      ppG = AVGAS;
      break;
  }
  switch (rpUnits) {
    case 'USg':
      FW = Math.round(FW / ppG);
      break;
    case 'Metrickg':
      FW = Math.round(FW * PtoKG);
      break;
    case 'Metricl':
      FW = Math.round((FW / ppG) * GtoLT);
      break;
  }
  var percent = Math.floor((this.value / FW) * 100);
  console.log(percent);
  if (percent > 100 || percent < 1) {
    $("input[name='Percentslider']").val(percent);
    $("input[name='Percentslider']").slider('refresh');
  } else {
    $("input[name='Percentslider']").unbind('change', PercentStop);
    $("input[name='Percentslider']").val(percent);
    $("input[name='Percentslider']").slider('refresh');
    $("input[name='Percentslider']").bind('change', PercentStop);
  }
}
var planLine;

var JETA = 7.09;
var JETB = 6.78;
var AVGAS = 6.12;
var LTtoG = 0.264172;
var GtoLT = 3.78541;
var PtoKG = 0.453592;
var KGtoP = 2.20462;
var rpUnits = 'USp';
var rpFueltype = 'Jeta';
var rpReserveAmount = 20;
var CHKreserve = false;
var CHKAutoCalcFuel = false;

var CurMarkerProperties = {
  NavType: 'Waypoint',
};
function routeToMarker(name, lat, lng) {
  $('#gpsMenu').hide();
  var point = new mapboxgl.LngLat(lng, lat);
  var last = {
    lngLat: point,
  };
  flyToMapPos(point);
  try {
    $('#airportInfo').popup('close');
  } catch (e) {
    console.log('acInfo');
  }
  try {
    $('#fireInfo').popup('close');
  } catch (e) {
    console.log('fireInfo');
  }
  try {
    $('#acInfo').popup('close');
  } catch (e) {
    console.log('acInfo');
  }
  try {
    $(document).trigger('simpledialog', {
      method: 'close',
    });
  } catch (e) {
    console.log('simpledialog');
  }
  if (CurMarkerProperties.NavType == undefined) CurMarkerProperties.NavType = 'Waypoint';
  RouteToMarkerNew(CurMarkerProperties, [point.lng, point.lat]);
}

var lastFuelPoint;

var ItinRoute = [];

var MANUAL_LEG = false;

function initSCHLegPlanner() {
  //called when page has cahnged to ItinLegPlanner
  //populate pilot / copilot dropdowns
  $('#LegStartE').val('');
  $('#LegStart').val('');
  $('#LegOrigin').val('');
  $('#LegRouting').val('');
  $('#LegNavaidSearch').val('');
  $('#LegEnd').val('');
  $('#LegEndE').val('');
  $('#LegDest').val('');
  $('#LegPassengerCount').val('');
  $('#LegComments').val('');
  $('#ITIN_CARGO_TOTAL').val('');

  PilotsTable.query()
    .select()
    .then(function (e) {
      if (e === false) PILOT_data = [];
      else PILOT_data = e;
      $('#SICs3').empty();
      $('#Pilots3').empty();
      $('#SICs3').append($('<option />').val('').text('None'));
      for (i in PILOT_data) {
        $('#Pilots3').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
        $('#SICs3').append($('<option />').val(PILOT_data[i].chatname).text(PILOT_data[i].chatname));
      }
      $('#Pilots3').val($('#Pilots').val());
      $('#SICs3').val($('#SICs').val());
    });
}

function ITIN_Leg_Prev() {
  ITIN_Save_Leg();
  if (SCH_DATA.LegIndex > 0) {
    SCH_DATA.LegIndex--;
    MEASURE.CurLeg = SCH_DATA.LegIndex;
    if (RAform != null) RAform.CurLeg == SCH_DATA.LegIndex;
    if (SCH_DATA.LEGS.length > 1)
      $('#ITIN_NEXT_BTN_HTML').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
    else $('#ITIN_NEXT_BTN_HTML').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
    ITIN_Load_Leg();
    LoadRouteRA();
  }
  $('#ITIN_LegNum').html('Leg Number ' + (SCH_DATA.LegIndex + 1));
  PrevNavLeg(true);
}

function ITIN_Leg_Next() {
  ITIN_Save_Leg();
  SCH_DATA.LegIndex++;
  MEASURE.CurLeg = SCH_DATA.LegIndex;
  if (RAform != null) RAform.CurLeg == SCH_DATA.LegIndex;
  var I = SCH_DATA.LegIndex;
  if (I >= SCH_DATA.LEGS.length - 1) {
    $('#ITIN_NEXT_BTN_HTML').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
  } else {
    $('#ITIN_NEXT_BTN_HTML').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
  }
  if (I > SCH_DATA.LEGS.length - 1) {
    if (!MANUAL_LEG) {
      $.mobile.changePage($('#Map_Page'), {
        reverse: false,
        changeHash: false,
      });
    } else {
      $('#LegStartE').val('');
      $('#LegStart').val('');
      $('#LegRouting').val('');
      $('#LegNavaidSearch').val('');
      $('#LegEnd').val('');
      $('#LegEndE').val('');
      $('#LegOrigin').val($('#LegDest').val());
      $('#LegDest').val('');
      $('#LegComments').val('');
      ITIN_Save_Leg(false);
    }
  } else {
    ITIN_Load_Leg();
    LoadRouteRA();
  }
  $('#ITIN_LegNum').html('Leg Number ' + (SCH_DATA.LegIndex + 1));
  NextNavLeg(true);
}

function SetupAutoComplete() {
  $('#LegOrigin').autocomplete({
    select: AirportAutoCompleteSelect,
    source: AirportAutoComplete,
  });

  /*
	$( "#LegNavaidSearch" ).autocomplete({
		select: NavaidAutoCompleteSelect,
		source: NavaidAutoComplete
	});
	*/
  $('#LegDest').autocomplete({
    select: AirportAutoCompleteSelect,
    source: AirportAutoComplete,
  });
}
var AUTOCOMPLETE = {
  index: null,
  data: null,
};

function NavaidAutoComplete(term, callback) {
  console.log(text);

  var text = term.term;
  SearchMobileNavaidDB(text, callback);
}
function NavaidAutoCompleteSelect(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val(label);
}

function LegRoutingDB(text, callback) {
  var Lat = center.lat;
  var Long = center.lng;

  var minlat = Lat - 2.5;
  var maxlng = Long + 2;
  var maxlat = Lat + 2.5;
  var minlng = Long - 2;

  if (!MOBILE) {
    $.getJSON(
      `${BASEURL}menu-Map-AJAX.php?action=searchNavaids&txt=${encodeURIComponent(txt)}&Lat=${Lat}&Long=${Long}`,
      (data) => {
        //local database is empty and offline fail with message
        let result = data.features;
        NAlist = computeBearingAndDistance(result, Long, Lat);
        showClosestNavaids();
      }
    ).fail(() => {
      alert('Sorry, we could not load any Navaids.');
    });
    return;
  }

  if (CORDOVA) {
    let qO = {
      q: 'SELECT * FROM Navaids WHERE "Lat" > ? AND "Long" > ? AND "Lat" < ? and "Long" < ? LIMIT 100',
      v: [minlat, minlng, maxlat, maxlng],
    };

    if (txt.length > 2) {
      qO = {
        q: 'SELECT * FROM Navaids WHERE Name LIKE ? OR ID LIKE ? OR Airport LIKE ? LIMIT 100',
        v: ['%' + txt + '%', txt + '%', txt + '%'],
      };
    }

    NavaidTable.query()
      .raw(qO)
      .then((result) => {
        if (result === false) {
          return;
        }

        NAlist = computeBearingAndDistance(result, Long, Lat);
        showClosestNavaids();
      });
  }
}

function AirportAutoComplete(term, callback) {
  var text = term.term;
  if (CORDOVA) SearchMobileDB(text, callback);
  else SearchOnlineDB(text, callback);
}

function SearchMobileDB(text, callback, id = '', otherList = []) {
  AirportsTable.query()
    .raw({
      q: 'SELECT * FROM Airports WHERE ICAO LIKE ? OR Name LIKE ? ORDER BY ICAO ASC LIMIT 15',
      v: [text + '%', '%' + text + '%'],
    })
    .then((result) => {
      var data = [];
      for (var i in result) {
        var item = result[i];
        data.push(item);
        if (id === 'TZSearch' && item.keywords != '') {
          otherList.unshift({
            label: '[' + item.ICAO + '] ' + item.Name + ' ' + item.keywords,
            value: item.keywords,
          });
        }
      }
      if (id === 'TZSearch') {
        callback(otherList);
        return;
      }
      AUTOCOMPLETE.data = data;
      SearchParseResults(callback);
    })
    .catch((e) => {
      if (id === 'TZSearch') {
        callback(otherList);
        return;
      }
      AUTOCOMPLETE.data = [];
      callback([]);
    });
}

function SearchOnlineDB(text, callback) {
  $.getJSON(BASEURL + 'menu-Map-AJAX.php?action=searchAirports&Type=All&Name=' + text, function (data) {
    //local database is empty and offline fail with message
    var items = [];
    for (var i in data.features) {
      items.push(data.features[i].properties);
    }
    AUTOCOMPLETE.data = items;
    SearchParseResults(callback);
  }).fail(function (jqXHR, status, error) {
    callback([]);
  });
}
function SearchParseResults(callback) {
  var options = [];
  for (var i in AUTOCOMPLETE.data) {
    var I = AUTOCOMPLETE.data[i];
    var label = I.ICAO + ': ' + I.Name;
    options.push({ label: label, value: i });
  }
  callback(options);
}

var LEG_ROUTING_ARRAY = [];
var LEG_FINAL_DEST = null;
function ParseLegRouting(e) {
  if (MANUAL_LEG == false) return;
  if (e.id == 'LegRouting') {
    var Ref = MEASURE.geojson.features;
    var Dest = $('#LegDest').val();
    if (Dest.length > 0) {
      //backup destination feature
      LEG_FINAL_DEST = JSON.parse(JSON.stringify(Ref[Ref.length - 1]));
    } else {
      LEG_FINAL_DEST = null;
    }

    var text = $('#LegRouting').val();
    LEG_ROUTING_ARRAY = text.split(' ');

    if (Ref.length > LEG_ROUTING_ARRAY.length + 1) {
      //old route had more routing points need to delete and start fresh
      if (Ref[0] != undefined) {
        var Origin = JSON.parse(JSON.stringify(Ref[0]));
        Ref = [];
        Ref.push(Origin);
      }
      if (LEG_FINAL_DEST != null) Ref[1] = LEG_FINAL_DEST;
    }
    if (LEG_ROUTING_ARRAY.length > 0) {
      IterateLegRouting(0);
    } else {
      ITIN_Save_Leg();
    }
  }
  if (e.id == 'LegRouting2') {
  }
}

function IterateLegRouting(index) {
  if (LEG_ROUTING_ARRAY[index] == undefined) {
    $('#LegRouting').val(LEG_ROUTING_ARRAY.join(' '));
    if (LEG_FINAL_DEST != null) MEASURE.geojson.features.push(LEG_FINAL_DEST);
    ITIN_Save_Leg();
    return;
  }
  var point = LEG_ROUTING_ARRAY[index];
  point.replace('[', '');
  point.replace(']', '');
  if (point.indexOf('|') != -1) {
    point = point.substr(0, 3);
  }
  if (point.length == 3) {
    searchRoutingIdent(point, index, 'Navaid');
    return;
  }
  if (point.length == 4) {
    searchRoutingIdent(point, index, 'Airport');
    return;
  }
  index++;
  IterateLegRouting(index);
}

function searchRoutingIdent(ident, index, type) {
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) center = mapb.getBounds().getCenter();
  if (GPS.Active) center = GPS.lnglat;
  var Lat = center.lat;
  var Long = center.lng;

  var minlat = Lat - 2.5;
  var maxlng = Long + 2;
  var maxlat = Lat + 2.5;
  var minlng = Long - 2;
  if (CORDOVA) {
    if (type == 'Navaid') {
      NavAidTable.query()
        .raw({
          q: 'SELECT * FROM Navaids WHERE ID LIKE ? LIMIT 100',
          v: [ident + '%'],
        })
        .then((result) => {
          searchRouting_Results(result, index, 'Navaid', ident);
        })
        .catch((e) => {
          verbose.warn('SQL', 'Error Searching NavAids:', e);
        });
      return;
    }

    AirportsTable.query()
      .raw({
        q: 'SELECT * FROM Airports WHERE ICAO LIKE ?',
        v: [ident + '%'],
      })
      .then((result) => {
        searchRouting_Results(result, index, 'Airport', ident);
      })
      .catch((e) => {
        verbose.warn('SQL', 'Error Searching Airports:', e);
      });

    return;
  }

  if (type == 'Navaid') {
    $.getJSON(
      BASEURL + 'menu-Map-AJAX.php?action=searchNavaids&txt=&Lat=' + Lat + '&Long=' + Long + '&ident=' + ident,
      function (data) {
        //local database is empty and offline fail with message
        var result = data.features;
        searchRouting_Results(result, index, 'Navaid', ident);
      }
    ).fail(function (jqXHR, status, error) {
      verbose.warn('SQL', 'Error Searching NavAids:', error);
    });
    return;
  }

  $.getJSON(
    BASEURL +
      'menu-Map-AJAX.php?action=searchAirports&Type=ICAO&ICAO=' +
      encodeURIComponent(ident) +
      '&Lat=' +
      Lat +
      '&Long=' +
      Long,
    function (data) {
      //local database is empty and offline fail with message
      var result = data.features;
      searchRouting_Results(result, index, 'Airport', ident);
    }
  ).fail(function (jqXHR, status, error) {
    verbose.warn('SQL', 'Error Searching Airports:', error);
  });
}

function searchRouting_Results(result, index, NavType, ident) {
  NAlist = computeBearingAndDistance(result);
  NAlist.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  if (NAlist.length > 0) {
    LEG_ROUTING_ARRAY[index] = '[' + ident.toUpperCase() + ']';
    index++; //intentionally incremented here since index 0 is always reserved for the origin
    InsertRoutingPoint(NAlist[0], index); //use closest item in case multiple ids show up
  } else {
    console.log('Route item not found removing');
    LEG_ROUTING_ARRAY.splice(index, 1);
    IterateLegRouting(index);
  }
}
function InsertRoutingPoint(item, index) {
  var Ref = MEASURE.geojson.features;
  if (item.FuelPlan == undefined) item.FuelPlan = GetDefaultFuelPlan();
  if (Ref[index] == undefined) {
    Ref[index] = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.Long, item.Lat],
      },
      properties: item,
    };
  } else {
    Ref[index].geometry.coordinates = [item.Long, item.Lat];
    Ref[index].properties = item;
  }
  Ref[index].properties.id = index;
  IterateLegRouting(index);
}

function AirportAutoCompleteSelect(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val(label);
  //console.log(label);
  if (id == 'LegOrigin2' || id == 'LegDest2') {
    if (MEASURE.LEGS[SCH_DATA.LegIndex] == undefined) {
      var Ref = [JSON.parse(JSON.stringify(MEASURE.geojson.features[MEASURE.geojson.features.length - 1]))];
    } else {
      var Ref = JSON.parse(JSON.stringify(MEASURE.LEGS[SCH_DATA.LegIndex].features));
    }
    var item = AUTOCOMPLETE.data[value];
    var index = 0;
    if (id == 'LegOrigin2') index = 0;
    if (id == 'LegDest2') {
      index = Ref.length - 1;
      if (index < 1) index = 1;
    }
    if (Ref[index] == undefined) {
      Ref[index] = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.Long, item.Lat],
        },
        properties: item,
      };
    } else {
      Ref[index].geometry.coordinates = [item.Long, item.Lat];
      Ref[index].properties = item;
    }
    Ref[index].properties.NavType = 'Airport';
    Ref[index].properties.id = index;
    MEASURE.geojson.features = Ref;
    if (MEASURE.LEGS[SCH_DATA.LegIndex] != undefined) {
      MEASURE.LEGS[SCH_DATA.LegIndex].features = JSON.parse(JSON.stringify(Ref));
    } else {
      MEASURE.LEGS[SCH_DATA.LegIndex] = {
        features: JSON.parse(JSON.stringify(Ref)),
        type: 'FeatureCollection',
      };
    }
    console.log(MEASURE.geojson.features);
  } else {
    var Ref = MEASURE.geojson.features;
    var item = AUTOCOMPLETE.data[value];
    var index = 0;
    if (id == 'LegOrigin') index = 0;
    if (id == 'LegDest') {
      index = Ref.length - 1;
      if (index < 1) index = 1;
    }
    if (Ref[index] == undefined) {
      Ref[index] = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [item.Long, item.Lat],
        },
        properties: item,
      };
    } else {
      Ref[index].geometry.coordinates = [item.Long, item.Lat];
      Ref[index].properties = item;
    }
    Ref[index].properties.NavType = 'Airport';
    Ref[index].properties.id = index;
    if ($('#LegDest').val() != '' && Ref[0] != undefined && $('#LegOrigin').val() != '' && Ref[1] != undefined) {
      ITIN_Save_Leg();
    }
  }
}

function NewManualLeg() {
  ITIN_PopLeg();
}

function ITIN_Save_Leg(updateMeasure = true) {
  var Index = SCH_DATA.LegIndex;
  var now = new Date();
  now = now.toISOString().substring(0, 16) + 'Z';
  var WBSummary = '';
  var otherwt = 0;
  for (var i in Loading) {
    if (Loading[i].Variable != undefined) {
      WBSummary +=
        Loading[i].Abr +
        ':' +
        $('#' + Loading[i].Abr).val() +
        ':' +
        Loading[i].LoadLong +
        ':' +
        Loading[i].LoadLat +
        ',';
    } else {
      if (Loading[i].VariableArms == 1) {
        WBSummary +=
          Loading[i].Abr +
          ':' +
          $('#' + Loading[i].Abr).val() +
          ':' +
          Loading[i].LoadLong +
          ':' +
          Loading[i].LoadLat +
          ',';
      } else {
        WBSummary += Loading[i].Abr + ':' + $('#' + Loading[i].Abr).val() + ',';
      }
    }
    otherwt += parseFloat($('#' + Loading[i].Abr).val());
  }
  for (var i in optLoad) {
    if (optLoad[i].VariableArms == 1) {
      WBSummary +=
        optLoad[i].Abr +
        ':' +
        $('#' + optLoad[i].Abr).val() +
        ':' +
        optLoad[i].LoadLong +
        ':' +
        optLoad[i].LoadLat +
        ',';
    } else {
      WBSummary += optLoad[i].Abr + ':' + $('#' + optLoad[i].Abr).val() + ',';
    }
    otherwt += parseFloat($('#' + optLoad[i].Abr).val());
  }
  var PerLegFields = [];
  var CF = JSON.parse(JSON.stringify(ITIN_Cfields));
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
        PerLegFields.push(I);
      }
    }
  }
  if (SCH_DATA.LEGS == undefined) {
    SCH_DATA.LEGS = [];
  }
  SCH_DATA.LEGS[Index] = {
    LegNum: Index,
    Changed: now,
    LegStartE: $('#LegStartE').val(),
    LegStart: $('#LegStart').val(),
    LegEnd: $('#LegEnd').val(),
    LegEndE: $('#LegEndE').val(),
    LegETA: 0,
    LegOrigin: $('#LegOrigin').val(),
    LegRouting: $('#LegRouting').val(),
    LegDest: $('#LegDest').val(),
    Pilot: $('#Pilots3').val(),
    sic: $('#SICs3').val(),
    PaxNum: $('#LegPassengerCount').val(),
    Description: $('#LegComments').val(),
    acwt: facweight,
    fuelwt: fuelwt,
    otherwt: otherwt,
    CargoWeight: $('#ITIN_CARGO_TOTAL').val(),
    cofglat: fcofgLat,
    cofglong: fcofgLong,
    tempconfig: tcNum,
    wbDate: TC_data[tcIndex].Date,
    Eweight: eacweight,
    Ehmom: TC_data[tcIndex].TCHorzMom,
    Elmom: TC_data[tcIndex].TCLatMom,
    TCdesc: tcDesc,
    Summary: WBSummary,
    GRAPH: '',
    Cfields: PerLegFields,
  };
  if (updateMeasure) MeasureUpdateDraw();
}

function ITIN_Load_Leg() {
  console.log(SCH_DATA);
  if (SCH_DATA.LEGS == undefined) {
    ITIN_Save_Leg();
  } else if (SCH_DATA.LEGS[SCH_DATA.LegIndex] == undefined) {
    ITIN_Save_Leg();
  }
  LoadFollowDataACweights(SCH_DATA.LegIndex);
  var L = SCH_DATA.LEGS[SCH_DATA.LegIndex];
  $('#LegStartE').val(L.LegStartE);
  $('#LegStart').val(L.LegStart);
  $('#LegOrigin').val(L.LegOrigin);
  $('#LegRouting').val(L.LegRouting);
  $('#LegNavaidSearch').val('');
  $('#LegEnd').val(L.LegEnd);
  $('#LegEndE').val(L.LegEndE);
  $('#LegDest').val(L.LegDest);
  $('#LegPassengerCount').val(L.PaxNum);
  $('#LegComments').val(L.Description);
  $('#ITIN_CARGO_TOTAL').val(L.CargoWeight);
  $('#Pilots3').val(L.Pilot);
  $('#SICs3').val(L.sic);
  ITIN_PopLegCfields(L.Cfields);
  $('#SICs3').selectmenu('refresh');
  $('#Pilots3').selectmenu('refresh');
}

function ITIN_PopLeg() {
  $('#ITIN_NEXT_BTN_HTML').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
  SCH_DATA.LegIndex = MEASURE.CurLeg;
  $('#ITIN_LegNum').html('Leg Number ' + (SCH_DATA.LegIndex + 1));

  var L = MEASURE.LEGS[MEASURE.CurLeg].features;
  var Labels = getMeasureFeature(L);

  $('#LegStartE').val('');
  $('#LegStart').val('');
  $('#LegOrigin').val(Labels.Origin);
  $('#LegRouting').val(Labels.Routing);
  $('#LegEnd').val('');
  $('#LegEndE').val('');
  $('#LegDest').val(Labels.Dest);
  $('#LegPassengerCount').val('');
  $('#LegComments').val('');
  $('#ITIN_CARGO_TOTAL').val('');
  if (SCH_DATA.LegIndex == 0) $('#Pilots3').val($('#Pilots').val());
  if (CurPageID() != 'Map_Page') {
    $('#SICs3').selectmenu('refresh');
    $('#Pilots3').selectmenu('refresh');
  }
  if (SCH_DATA.LEGS == undefined) {
    ITIN_NewLegCfields();
    ITIN_Save_Leg();
  } else if (SCH_DATA.LEGS[SCH_DATA.LegIndex] == undefined) {
    ITIN_NewLegCfields();
    ITIN_Save_Leg();
  } else {
    ITIN_Load_Leg();
  }
}

function ITIN_NewLegCfields() {
  clearAllCfieldHolders();
  var html = '';
  var CF = ITIN_Cfields;
  if (CF.length > 0) {
    html = '<h3>Additional Fields</h3>';
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        html += createCustomField('', I, false, 'Itin');
      }
    }
  }
  $('#ITIN_LEG_CFIELDS').html(html);
  $('#ITIN_LEG_CFIELDS').enhanceWithin();
}

function ITIN_PopLegCfields(CF) {
  $('#ITIN_LEG_CFIELDS2').html('');
  $('#editItinCfields').html('');
  var html = '';
  if (CF.length > 0) {
    html = '<h3>Additional Fields</h3>';
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        html += createCustomField('', I, I.Value, 'Itin');
      }
    }
  }
  $('#ITIN_LEG_CFIELDS').html(html);
  $('#ITIN_LEG_CFIELDS').enhanceWithin();
}
function ReviewItinLegs() {
  $.mobile.changePage($('#Map_Page'), {
    reverse: false,
    changeHash: false,
  });
  if (mapb == null) {
    INIT_MAPB();
    setTimeout(function () {
      PLANAC = acIdent;
      ReviewItinLegs2();
    }, 2000);
  } else {
    ReviewItinLegs2();
  }
}
function ReviewItinLegs2() {
  MEASURE.CurLeg = 0;
  MEASURE.geojson = JSON.parse(JSON.stringify(FuelPlans[0]));
  MEASURE.LEGS = JSON.parse(JSON.stringify(FuelPlans));
  SCH_DATA.LegIndex = 0;

  var I = 1;
  if (I >= MEASURE.LEGS.length) {
    $('#ITIN_NEXT_BTN_HTML').html('New Leg<i class="icon-margins fal fa-chevron-right"></i>');
  } else {
    $('#ITIN_NEXT_BTN_HTML').html('Next<i class="icon-margins fal fa-chevron-right"></i>');
  }
  $('#ITIN_LegNum').html('Leg Number ' + (SCH_DATA.LegIndex + 1));

  RoutePlanningON = true;
  RETURN_ITIN = true;

  MAPB_OBSTACLES = false;
  LoadFPsettings(MEASURE.geojson.features[0].properties.FuelPlan);
  startRoutePlan();
  ITIN_Load_Leg();
  //ITIN_PopLeg();
  LoadRouteRA();
}
function LoadRouteRA() {
  if (RAform == null) {
    $('#RA_ItinLeg').hide();
    $('#RA_ItinLegDIV').html('');
  } else {
    RAform.ItinActive = 1;
    RAform.HidePerFlight = 1;
    RAform.CurLeg == SCH_DATA.LegIndex;
    RISK_CURRENT_DIV = 'RA_ItinLegDIV';
    $('#RA_ItinLeg').show();
    RAoperation();
  }
}
var ITINobstacles;
function getRouteDistance(json) {
  var Distance = turf.lineDistance(json, { units: 'meters' }).toFixed(1);
  return getDisplayDistance(Distance);
}
function CheckRouteObstacles(map, Measure = true) {
  //$("#MobilizeOfflineTiles").show();
  if (CFS == 1) {
    if (!ONLINE) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Offline',
        headerClose: false,
        buttonPrompt: 'Unable to retrieve Obstacle data along your intended route in <b>OFFLINE</b> mode..',
        buttons: {
          Close: {
            click: function () {},
          },
          Report: {
            click: function () {
              //File bug report
            },
          },
        },
      });

      return;
    }
    if (Measure) {
      RoutePlans = [];
      var MLobslider = LOCALSTORAGE.MLobslider;
      if (MLobslider == undefined) MLobslider = 10;

      var lines = {
        type: 'FeatureCollection',
        features: [],
      };
      var FuelPlans = JSON.parse(JSON.stringify(MEASURE.LEGS));
      for (var L in FuelPlans) {
        var coords = [];
        var RouteCoords = [];
        for (var f in FuelPlans[L].features) {
          var C = FuelPlans[L].features[f].geometry.coordinates;
          coords.push(C);
          RouteCoords.push([C[1], C[0]]);
        }
        RoutePlans.push(RouteCoords);
        var feature = {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coords,
          },
          properties: {
            Label: '',
          },
        };
        if (coords.length < 2) continue;
        console.log(feature);
        feature.properties.Label = getRouteDistance(feature);
        lines.features.push(feature);
      }
      if (removefilecontrol == null) {
        removefilecontrol = new removefileControl();
        mapb.addControl(removefilecontrol, 'bottom-left');
      }
    }

    var PostRoute = JSON.stringify(RoutePlans);

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Map-AJAX.php?action=getRouteObstacles',
      data: {
        Route: PostRoute,
        NM: MLobslider,
      },
    })
      .success(function (data) {
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        console.log('Received Route Obstacles');
        ITINobstacles = data;
        if (data.Obstacles.features.length) {
          if (map.getSource('ROUTEPLAN-OBSTACLES') == undefined) {
            map.addSource('ROUTEPLAN-OBSTACLES', {
              type: 'geojson',
              data: data.Obstacles,
            });
            map.addLayer({
              id: 'ROUTEPLAN-OBSTACLES',
              type: 'circle',
              source: 'ROUTEPLAN-OBSTACLES',
              paint: {
                'circle-radius': 5,
                'circle-color': '#ff0000',
                'circle-stroke-color': '#FFFF00',
                'circle-stroke-width': 5,
                'circle-stroke-opacity': 0.3,
                'circle-opacity': 0.7,
              },
            });
            map.addSource('ROUTEPLAN-OBSTACLES-AGL', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [data.HAGL.Long, data.HAGL.Lat],
                    },
                    properties: data.HAGL,
                  },
                ],
              },
            });
            map.addLayer({
              id: 'ROUTEPLAN-OBSTACLES-AGL',
              type: 'circle',
              source: 'ROUTEPLAN-OBSTACLES-AGL',
              paint: {
                'circle-radius': 10,
                'circle-color': '#ff26ff',
                'circle-stroke-color': '#FFFF00',
                'circle-stroke-width': 10,
                'circle-stroke-opacity': 0.5,
                'circle-opacity': 1,
              },
            });
            map.addSource('ROUTEPLAN-OBSTACLES-ASL', {
              type: 'geojson',
              data: {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [data.HASL.Long, data.HASL.Lat],
                    },
                    properties: data.HASL,
                  },
                ],
              },
            });
            map.addLayer({
              id: 'ROUTEPLAN-OBSTACLES-ASL',
              type: 'circle',
              source: 'ROUTEPLAN-OBSTACLES-ASL',
              paint: {
                'circle-radius': 10,
                'circle-color': '#26c8ff',
                'circle-stroke-color': '#FFFF00',
                'circle-stroke-width': 10,
                'circle-stroke-opacity': 0.5,
                'circle-opacity': 1,
              },
            });
          } else {
            map.getSource('ROUTEPLAN-OBSTACLES').setData(data.Obstacles);
            var ASL = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [data.HASL.Long, data.HASL.Lat],
                  },
                  properties: data.HASL,
                },
              ],
            };
            map.getSource('ROUTEPLAN-OBSTACLES-ASL').setData(ASL);
            var AGL = {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [data.HAGL.Long, data.HAGL.Lat],
                  },
                  properties: data.HAGL,
                },
              ],
            };
            map.getSource('ROUTEPLAN-OBSTACLES-AGL').setData(AGL);
          }

          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Summary',
            headerClose: false,
            buttonPrompt: data.Details,
            buttons: {
              Close: {
                click: function () {},
              },
            },
          });
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Summary',
            headerClose: false,
            buttonPrompt: '<h3>No Obstacles found along route</h3>',
            buttons: {
              Close: {
                click: function () {},
              },
            },
          });
        }
      })
      .fail(function (data) {
        //data.status, data.readyState, data.responseText
        console.log(data);
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed!',
          headerClose: false,
          buttonPrompt: 'Unable to retrieve Obstacle data along your intended route.',
          buttons: {
            Close: {
              click: function () {},
            },
            Report: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      });
  }
}

/*---------------------------------------------------NEW ROUTE PLAN TOOL -----------------------------  */

function SetupRouteAircraftSEL() {
  if (PLANAC == null) {
    FuelPlans = [];
    //reset variables
    ALLAC_data = [];
    //$.mobile.changePage( $("#AC_Page"), {  changeHash: false });
    $('#WB_Content').html('');
    cirroDB.query('AC', 'ident != ?', '', function (e) {
      ALLAC_data = [];
      if (e === false) e = [];
      else {
        for (var i in e) {
          var item = e[i].AC_data;
          item.rawData = e[i];
          ALLAC_data.push(item);
        }
      }
      var html =
        '<button data-mini="true" onClick="CloseSetupRoutePlanNew();" data-icon="delete" class="redbg">Cancel</button>';
      html += "<label for='PlanAC'>Select Aircraft</label><select id='PlanAC'>";
      for (i in ALLAC_data) {
        html += "<option value='" + i + "'>" + ALLAC_data[i].ident + '</option>';
      }
      html += '</select>';
      CHKAutoCalcFuel = false;
      if (ALLAC_data.length > 0) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Setup Route Plan',
          headerClose: false,
          buttonPrompt: html,
          buttons: {
            OK: {
              click: function () {
                var index = $('#PlanAC').val();
                SetupRouteAircraftSELECTED(index);
              },
            },
          },
        });
      } else {
        ACFuel = 1000;
        FuelRemain = 1000;
        ACmaxFuel = 10000;
        ACFuelBurn = 200;
        ACcruise = 100;
        ACground = 100;
        SetupRouteAircraft(); //Display Blank Template
      }
    });
  } else {
    $('#rpUSp').prop('checked', true);
    SetupRouteAircraft();
    LoadRouteAircraftSettings(PLANAC, false);
  }
}
function SetupRouteAircraftSELECTED(i) {
  //console.log(ALLAC_data[i]);
  var ac = ALLAC_data[i];
  var acIdent = ac.ident;
  PLANAC = acIdent;
  ACFuelBurn = ac.Consumption;
  ACcruise = ac.Cruise;
  LoadRouteAircraftSettings(acIdent, true);
}
function LoadRouteAircraftSettings(acIdent, resetFuelQty) {
  $('#FuelPlanAircraft').html('Setup ' + acIdent);
  if (ACFuelBurn == null || ACFuelBurn == '' || parseInt(ACFuelBurn) == 0) ACFuelBurn = 100;
  if (ACcruise == null || ACcruise == '' || parseInt(ACcruise) == 0) ACcruise = 100;
  ACground = ACcruise;
  cirroDB.query('AC', 'ident = ?', acIdent, function (e) {
    var data = e[0];
    console.log(data);
    var TC_data = data.TC_data;
    var WB_data = data.WB_Data;
    var LOADABLE_data = data.Loadable_data;
    var AC_data = data.AC_data;
    AC_UNITS = AC_data.Units;
    ACmaxFuel = parseInt(WB_data.Fuel[0].Capacity);
    if (AC_data.Class == 'Aeroplane' && resetFuelQty) {
      rpReserveAmount = 30;
    }

    //WB_initialize(TCNUM);
    var FuelStandard = AC_data.FuelType;
    var SN = AC_data.SN;
    //console.log(WB_data);
    var FUEL_data = WB_data.Fuel;
    for (f in FUEL_data) {
      if (
        FUEL_data[f].Type == FuelStandard &&
        SN >= parseInt(FUEL_data[f].SN_Low) &&
        SN <= parseInt(FUEL_data[f].SN_High)
      )
        ACmaxFuel = parseInt(FUEL_data[f].Capacity);
    }
    for (var i in LOADABLE_data) {
      if (LOADABLE_data[i].Fuel == 1) {
        //plusFuel += parseInt(LOADABLE_data[i].MaxWT);
        for (f in FUEL_data) {
          if (
            FUEL_data[f].Type == LOADABLE_data[i].Type &&
            SN >= parseInt(FUEL_data[f].SN_Low) &&
            SN <= parseInt(FUEL_data[f].SN_High)
          ) {
            maxFuel = parseInt(FUEL_data[f].Capacity);
            ACmaxFuel = maxFuel;
            FuelStandard = LOADABLE_data[i].Type;
          }
        }
      }
    }
    TCNUM = 1;
    var FoundTCNUM = false;
    for (var i in Follow_Data) {
      var F = Follow_Data[i];
      if (F.AC.ident == acIdent) {
        if (F.WB != null) {
          TCNUM = F.WB[0].tempconfig;
          FoundTCNUM = true;
        }
      }
    }
    for (var i in TC_data) {
      if (TC_data[i].TCNum == TCNUM) {
        //find active temp configuration for initial setup
        for (var t in TC_data[i].Optional) {
          var opt = TC_data[i].Optional[t];
          if (opt.Fuel == 1 && opt.status == 1) {
            for (var f in FUEL_data) {
              if (
                FUEL_data[f].Type == opt.Type &&
                SN >= parseInt(FUEL_data[f].SN_Low) &&
                SN <= parseInt(FUEL_data[f].SN_High)
              ) {
                ACmaxFuel = parseInt(FUEL_data[f].Capacity);
              }
            }
          }
        }
      }
    }
    if (ONLINE && !FoundTCNUM) {
      $.getJSON(BASEURL + 'menu-Itinerary-AJAX.php?action=getActiveTC&acident=' + acIdent, function (json_data) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getActiveTC');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          if (json_data[0].ServerResponse == 'Offline') {
            //send offline message
            console.log('Offline Cannot Continue...');
          }
        } else {
          TCNUM = json_data[0].TCNum;
          //console.log("Received Active Temp Configuration Num");
        }

        for (var i in TC_data) {
          if (TC_data[i].TCNum == TCNUM) {
            //find active temp configuration for initial setup
            for (var t in TC_data[i].Optional) {
              var opt = TC_data[i].Optional[t];
              if (opt.Fuel == 1 && opt.status == 1) {
                for (var f in FUEL_data) {
                  if (
                    FUEL_data[f].Type == opt.Type &&
                    SN >= parseInt(FUEL_data[f].SN_Low) &&
                    SN <= parseInt(FUEL_data[f].SN_High)
                  ) {
                    ACmaxFuel = parseInt(FUEL_data[f].Capacity);
                  }
                }
              }
            }
          }
        }
        rpUnits = 'USp';
        $('#rpUSp').prop('checked', true);
        $('#rpUSg').prop('checked', false);
        $('#rpMetrickg').prop('checked', false);
        $('#rpMetricl').prop('checked', false);

        ACFuel = ACmaxFuel;
        if (AC_UNITS == 'Metric') {
          rpUnits = 'Metrickg';
          $('#rpUSp').prop('checked', false);
          $('#rpUSg').prop('checked', false);
          $('#rpMetrickg').prop('checked', true);
          $('#rpMetricl').prop('checked', false);
        }
        if (resetFuelQty || FuelRemain > ACFuel) FuelRemain = ACFuel;
        SetupRouteAircraft();
      }).fail(function (jqXHR, status, error) {
        rpUnits = 'USp';
        $('#rpUSp').prop('checked', true);
        $('#rpUSg').prop('checked', false);
        $('#rpMetrickg').prop('checked', false);
        $('#rpMetricl').prop('checked', false);

        ACFuel = ACmaxFuel;
        if (AC_UNITS == 'Metric') {
          rpUnits = 'Metrickg';
          $('#rpUSp').prop('checked', false);
          $('#rpUSg').prop('checked', false);
          $('#rpMetrickg').prop('checked', true);
          $('#rpMetricl').prop('checked', false);
        }
        if (resetFuelQty || FuelRemain > ACFuel) FuelRemain = ACFuel;
        SetupRouteAircraft();
      });
    } else {
      rpUnits = 'USp';
      $('#rpUSp').prop('checked', true);
      $('#rpUSg').prop('checked', false);
      $('#rpMetrickg').prop('checked', false);
      $('#rpMetricl').prop('checked', false);

      ACFuel = ACmaxFuel;
      if (AC_UNITS == 'Metric') {
        rpUnits = 'Metrickg';
        $('#rpUSp').prop('checked', false);
        $('#rpUSg').prop('checked', false);
        $('#rpMetrickg').prop('checked', true);
        $('#rpMetricl').prop('checked', false);
      }
      if (resetFuelQty || FuelRemain > ACFuel) FuelRemain = ACFuel;
      SetupRouteAircraft();
    }
  });
}
function CloseSetupRoutePlanNew() {
  $(document).trigger('simpledialog', {
    method: 'close',
  });
}
function CloseSetupACRoutePlanNew() {
  $.mobile.changePage($('#Map_Page'), {
    changeHash: false,
    reverse: true,
  });
  PLANAC = null;
  MeasureUpdateDraw();
}
function SetupRouteAircraft() {
  if (RoutePlanningON == true) {
    $('#FuelPlanCancelBTN').prop('disabled', true);
  } else {
    $('#FuelPlanCancelBTN').prop('disabled', false);
  }
  if (MEASURE.EditExisting) $('#FuelPlanCancelBTN').prop('disabled', true);
  else $('#FuelPlanCancelBTN').prop('disabled', false);
  UpdateNavVisibility(false);
  $.mobile.changePage($('#SetupRoutePlan'), {
    changeHash: false,
  });
  $('#rpUSp').checkboxradio('refresh');
  $('#rpUSg').checkboxradio('refresh');
  $('#rpMetrickg').checkboxradio('refresh');
  $('#rpMetricl').checkboxradio('refresh');
  if (isNaN(parseInt(ACFuelBurn)) || ACFuelBurn < 1) ACFuelBurn = 1;
  if (isNaN(parseInt(ACcruise)) || ACcruise < 10) ACcruise = 10;
  if (isNaN(parseInt(ACmaxFuel)) || ACmaxFuel < 1) ACmaxFuel = 10;
  if (isNaN(parseInt(FuelRemain)) || FuelRemain < 1) FuelRemain = 1;
  var percent = Math.floor((FuelRemain / ACmaxFuel) * 100);
  $('#rpTime').clockpicker({
    autoclose: true,
    align: 'right',
    placement: 'top',
  });
  var now = new Date();
  now.setMinutes(now.getMinutes() + 30);
  var hours = now.getHours();
  if (hours < 10) hours = '0' + hours;
  var minutes = now.getMinutes();
  if (minutes < 10) $('#rpTime').val(hours + '0' + minutes);
  else $('#rpTime').val(String(hours) + String(minutes));

  $('#rpReserve').checkboxradio('refresh');
  $("input[name='Percentslider']").unbind('change', PercentStop);
  $("input[name='Percentslider']").bind('change', PercentStop);
  if (CHKAutoCalcFuel) {
    $('#AutoCalcFuel').prop('checked', true).checkboxradio('refresh');
    $('#Precentslider').slider('disable');
    FuelRemain = ACmaxFuel;
  } else {
    $('#AutoCalcFuel').prop('checked', false).checkboxradio('refresh');
    $('#Precentslider').slider('enable');
  }

  FuelqtyChangeNew({ value: FuelRemain });

  $('#rpFuel').val(round10(FuelRemain));
  $('#rpFuelBurn').val(ACFuelBurn);
  $('#rpCruise').val(ACcruise);
  $('#rpAlt').val(ACalt);
  if (rpUnits != $('input[name=rpUnits]:checked').val()) {
    rptUnits({ value: $('input[name=rpUnits]:checked').val() });
  }
  $('#rpReserve0').prop('checked', false).checkboxradio('refresh');
  $('#rpReserve1').prop('checked', false).checkboxradio('refresh');
  $('#rpReserve2').prop('checked', false).checkboxradio('refresh');
  $('#rpReserve3').prop('checked', false).checkboxradio('refresh');
  switch (rpReserveAmount) {
    case 0:
      $('#rpReserve0').prop('checked', true).checkboxradio('refresh', true);
      break;
    case 20:
      $('#rpReserve1').prop('checked', true).checkboxradio('refresh', true);
      break;
    case 30:
      $('#rpReserve2').prop('checked', true).checkboxradio('refresh');
      break;
    case 45:
      $('#rpReserve3').prop('checked', true).checkboxradio('refresh');
      break;
  }
  switch (rpUnits) {
    case 'USp':
      $('#rpUSp').prop('checked', true).checkboxradio('refresh', true);
      break;
    case 'USg':
      $('#rpUSg').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Metrickg':
      $('#rpMetrickg').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Metricl':
      $('#rpMetricl').prop('checked', true).checkboxradio('refresh');
      break;
  }
  var Units = 'Lbs';
  switch (rpUnits) {
    case 'USp':
      Units = 'Lbs';
      break;
    case 'USg':
      Units = 'Gal';
      break;
    case 'Metrickg':
      Units = 'Kg';
      break;
    case 'Metricl':
      Units = 'Litres';
      break;
  }
  $("label[for='rpFuel']").html('Fuel Remaining (' + Units + ')');
  $("label[for='rpFuelBurn']").html('Rate (' + Units + '/Hr)');
  switch (rpFueltype) {
    case 'Jeta':
      $('#rpJeta').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Jetb':
      $('#rpJetb').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Avgas':
      $('#rpAvgas').prop('checked', true).checkboxradio('refresh');
      break;
  }
}
function FuelqtyChangeNew(e) {
  console.log('QTY Change');
  var FW;
  FW = ACmaxFuel;
  if (e.value > FW) {
    e.value = FW;
    $('#rpFuel').val(FW);
  }
  var ppG = 1;
  switch (rpFueltype) {
    case 'Jeta':
      ppG = JETA;
      break;
    case 'Jetb':
      ppG = JETB;
      break;
    case 'Avgas':
      ppG = AVGAS;
      break;
  }
  switch (rpUnits) {
    case 'USg':
      FW = Math.round(FW / ppG);
      break;
    case 'Metrickg':
      FW = Math.round(FW * PtoKG);
      break;
    case 'Metricl':
      FW = Math.round((FW / ppG) * GtoLT);
      break;
  }
  var percent = Math.floor((e.value / FW) * 100);
  console.log(percent);
  if (percent > 100 || percent < 1) {
    $("input[name='Percentslider']").val(percent);
    $("input[name='Percentslider']").slider('refresh');
  } else {
    $("input[name='Percentslider']").unbind('change', PercentStop);
    $("input[name='Percentslider']").val(percent);
    $("input[name='Percentslider']").slider('refresh');
    $("input[name='Percentslider']").bind('change', PercentStop);
  }
}
function rptAutoCalcFuel(e) {
  if ($('#AutoCalcFuel').is(':checked')) {
    CHKAutoCalcFuel = true;
    $('#Precentslider').val(100);
    $('#rpFuel').val(ACmaxFuel);
    $('#Precentslider').slider('disable');
    $('#rpFuel').prop('disabled', true);
  } else {
    CHKAutoCalcFuel = false;
    $('#Precentslider').slider('enable');
    $('#rpFuel').prop('disabled', false);
  }
}
function rptReserveAmount(e) {
  rpReserveAmount = parseInt(e.value);
}

function rptUnits(e) {
  console.log(e);
  var oldUnits = rpUnits;
  rpUnits = e.value;
  var Units = 'Lbs';
  switch (rpUnits) {
    case 'USp':
      Units = 'Lbs';
      break;
    case 'USg':
      Units = 'Gal';
      break;
    case 'Metrickg':
      Units = 'Kg';
      break;
    case 'Metricl':
      Units = 'Litres';
      break;
  }
  var ppG = 1;
  switch (rpFueltype) {
    case 'Jeta':
      ppG = JETA;
      break;
    case 'Jetb':
      ppG = JETB;
      break;
    case 'Avgas':
      ppG = AVGAS;
      break;
  }
  $("label[for='rpFuel']").html('Fuel Remaining (' + Units + ')');
  $("label[for='rpFuelBurn']").html('Rate (' + Units + '/Hr)');
  var FW = $('#rpFuel').val();
  var FR = $('#rpFuelBurn').val();
  if (oldUnits == 'USp') {
    switch (rpUnits) {
      case 'USg':
        FW = Math.round(FW / ppG);
        FR = Math.round(FR / ppG);
        break;
      case 'Metrickg':
        FW = Math.round(FW * PtoKG);
        FR = Math.round(FR * PtoKG);
        break;
      case 'Metricl':
        FW = Math.round((FW / ppG) * GtoLT);
        FR = Math.round((FR / ppG) * GtoLT);
        break;
    }
  }
  if (oldUnits == 'USg') {
    switch (rpUnits) {
      case 'USp':
        FW = Math.round(FW * ppG);
        FR = Math.round(FR * ppG);
        break;
      case 'Metrickg':
        FW = Math.round(FW * ppG * PtoKG);
        FR = Math.round(FR * ppG * PtoKG);
        break;
      case 'Metricl':
        FW = Math.round(FW * GtoLT);
        FR = Math.round(FR * GtoLT);
        break;
    }
  }
  if (oldUnits == 'Metrickg') {
    switch (rpUnits) {
      case 'USg':
        FW = Math.round((FW * KGtoP) / ppG);
        FR = Math.round((FR * KGtoP) / ppG);
        break;
      case 'USp':
        FW = Math.round(FW * KGtoP);
        FR = Math.round(FR * KGtoP);
        break;
      case 'Metricl':
        FW = Math.round(((FW * KGtoP) / ppG) * GtoLT);
        FR = Math.round(((FR * KGtoP) / ppG) * GtoLT);
        break;
    }
  }
  if (oldUnits == 'Metricl') {
    switch (rpUnits) {
      case 'USg':
        FW = Math.round(FW * LTtoG);
        FR = Math.round(FR * LTtoG);
        break;
      case 'Metrickg':
        FW = Math.round(FW * LTtoG * ppG * PtoKG);
        FR = Math.round(FR * LTtoG * ppG * PtoKG);
        break;
      case 'USp':
        FW = Math.round(FW * LTtoG * ppG);
        FR = Math.round(FR * LTtoG * ppG);
        break;
    }
  }
  $('#rpFuel').val(FW);
  $('#rpFuelBurn').val(FR);
}
var FUwt = 1;

function rptFueltype(e) {
  console.log(e);
  rpFueltype = e.value;
}
function UpdateAircraftSettings() {
  rptUnits({ value: 'USp' });
  ACFuel = parseInt($('#rpFuel').val());
  FuelRemain = ACFuel;
  if (parseInt(ACFuel) == 0) ACFuel = 1;
  if (ACFuel > ACmaxFuel) ACFuel = ACmaxFuel;
  ACFuelBurn = parseInt($('#rpFuelBurn').val());
  if (ACFuelBurn == '' || parseInt(ACFuelBurn) == 0) ACFuelBurn = 1;
  ACcruise = parseInt($('#rpCruise').val());
  if (ACcruise == '' || parseInt(ACcruise) == 0) ACcruise = 1;
  CHKreserve = $('#rpReserve').is(':checked');
  CHKAutoCalcFuel = $('#AutoCalcFuel').is(':checked');
  ACalt = $('#rpAlt').val();
  ACdepart = $('#rpTime').val();
  $.mobile.changePage($('#Map_Page'), {
    changeHash: false,
    reverse: true,
  });
  ACground = ACcruise;
  UpdateNavVisibility(true);
  MeasureUpdateDraw();
  $('#coordinates').show();
  setTimeout(function () {
    UpdateMeasureWindow();
  }, 1000);
}
function returnfpMap() {
  $.mobile.changePage($('#Map_Page'), {
    changeHash: false,
    reverse: true,
  });
  UpdateNavVisibility(true);
  $('#coordinates').show();
}
function CalcFuelNeeded() {
  FuelRemain = MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties.FuelPlan.FuelRequired + 5;
  MeasureUpdateDraw();
}

function GetDefaultFuelPlan() {
  var timestamp = new Date();
  var Hours = ACdepart.substr(0, 2);
  var Minutes = ACdepart.substr(2, 2);
  if (timestamp.getHours() > Hours) {
    timestamp.setDate(timestamp.getDate() + 1);
  } else if (timestamp.getHours() == parseInt(Hours)) {
    if (timestamp.getMinutes() > parseInt(Minutes)) {
      timestamp.setDate(timestamp.getDate() + 1);
    }
  }
  timestamp.setHours(parseInt(Hours));
  timestamp.setMinutes(parseInt(Minutes));
  timestamp = timestamp.getTime();
  var UseableFuel = round10(FuelRemain - (rpReserveAmount / 60) * ACFuelBurn);
  if (MANUAL_LEG) {
    UseableFuel = round10(ACmaxFuel - (rpReserveAmount / 60) * ACFuelBurn);
  }
  var FuelPlan = {
    StartFuel: FuelRemain,
    BurnRate: ACFuelBurn,
    ReserveTime: rpReserveAmount,
    ReserveAmount: round10((rpReserveAmount / 60) * ACFuelBurn),
    MaxDist: round10((FuelRemain / ACFuelBurn) * ACground),
    ReserveDist: round10(((FuelRemain - (rpReserveAmount / 60) * ACFuelBurn) / ACFuelBurn) * ACground),
    UseableFuel: UseableFuel,
    FuelRequired: round10((rpReserveAmount / 60) * ACFuelBurn),
    FuelUsed: 0,
    TravelTime: 0,
    AirSpeed: ACcruise,
    GroundSpeed: ACground,
    SegmentTime: 0,
    TotalLegTime: 0,
    ACalt: parseInt(ACalt),
    Distance: 0,
    TotalDistance: 0,
    Temp: 0,
    Wind: 0,
    WindDir: 0,
    timestamp: timestamp,
    Forecast: '00',
    Updated: false,
  };
  return FuelPlan;
}

function UpdateFuelPlan(FP = null, timestamp = null) {
  var FuelPlan;
  if (FP == null) {
    FuelPlan = GetDefaultFuelPlan();
    console.log('NewFuelPlan');
    console.log(FuelPlan);
    MAPSTATE.DistanceLeft = round10(FuelPlan.ReserveDist * 1852);
  } else {
    FuelPlan = FP;
  }
  if (timestamp != null) {
    FuelPlan.timestamp = timestamp;
  }
  if (MEASURE.geojson.features.length == 0 || PLANAC == null) {
    if (mapb.getSource('ROUTEPLAN-RADIUS') != undefined) {
      mapb.removeLayer('ROUTEPLAN-RADIUS');
      mapb.removeLayer('ROUTEPLAN-RADIUS-LABELS');
      mapb.removeSource('ROUTEPLAN-RADIUS');
    }

    return;
  }
  FinishFuelPlan(FuelPlan, true);
}

function AverageWind_Speed_Dir(WS1, WD1, WS2, WD2) {
  var V_east = WS1 * Math.sin((WD1 * Math.PI) / 180);
  V_east += WS2 * Math.sin((WD2 * Math.PI) / 180);
  V_east = V_east / 2;
  var V_north = WS1 * Math.cos((WD1 * Math.PI) / 180);
  V_north += WS2 * Math.cos((WD2 * Math.PI) / 180);
  V_north = V_north / 2;

  var unit_WD = (Math.atan2(V_east, V_north) * 180) / Math.PI;
  unit_WD = (360 + unit_WD) % 360;
  var unit_WS = Math.sqrt(V_east * V_east + V_north * V_north);
  return {
    WindDir_Avg: Math.round(unit_WD),
    Wind_Avg: Math.round(unit_WS * 100) / 100,
  };
}
var UpdatingWindsToast = null;
function UpdateFuelPlanWinds() {
  console.log('Updating Fuel Plan Winds');
  UpdatingWindsToast = toaster.show('Updating Fuel Plan Wind Forecasts [Leg ' + 1 + ']...', 5);
  FinishFuelPlan(MEASURE.geojson.features[0].properties.FuelPlan, true, 0);
}
function UpdatedFuelPlanLeg(Leg) {
  MEASURE.LEGS[Leg] = JSON.parse(JSON.stringify(MEASURE.geojson));
  Leg++;
  if (MEASURE.LEGS[Leg] != undefined) {
    toaster.remove(UpdatingWindsToast);
    UpdatingWindsToast = toaster.show('Updating Fuel Plan Wind Forecasts [Leg ' + (Leg + 1) + ']...', 5);
    MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[Leg]));
    FinishFuelPlan(MEASURE.geojson.features[0].properties.FuelPlan, true, Leg);
  } else {
    FuelPlans = JSON.parse(JSON.stringify(MEASURE.LEGS));
    toaster.remove(UpdatingWindsToast);
    UpdatingWindsToast = toaster.show('Fuel Plan Wind Forecasts Updated.', 5);
  }
}
function FinishFuelPlan(FuelPlan, getwind, refreshing = false) {
  var FP = JSON.parse(JSON.stringify(FuelPlan));
  var coords;
  var FlyZone = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };
  for (var i in MEASURE.geojson.features) {
    var F = MEASURE.geojson.features[i];
    coords = F.geometry.coordinates;
    if (F.properties.FuelPlan != undefined) {
      if (F.properties.FuelPlan.Updated == true) {
        var W = F.properties.FuelPlan;
        FP.Temp = W.Temp;
        FP.WindDir = W.WindDir;
        FP.Wind = W.Wind;
        FP.Forecast = W.Forecast;
        FP.Updated = W.Updated;
      } else {
        FP.Updated = false;
      }
    } else {
      FP.Updated = false;
    }
    var figures = {
      UseableFuel: FP.UseableFuel,
      BurnRate: FP.BurnRate,
      AirSpeed: FP.AirSpeed,
      WindSpeed: FP.Wind * 1.94384,
      WindDir: FP.WindDir,
    };
    if (i == 0) {
      if (FP.Updated == false && getwind) {
        GetWXLatLong(i, FP, FuelPlan, refreshing);
        return;
      }
      F.properties.FuelPlan = JSON.parse(JSON.stringify(FP));
      MAPSTATE.DistanceLeft = round10(FP.ReserveDist * 1852);
      if (refreshing === false) FlyZone = createGeoJSONCircle(coords, MAPSTATE.DistanceLeft, 'blue', true, figures);
      continue;
    }

    var PF = MEASURE.geojson.features[parseInt(i) - 1];
    var P1 = PF.geometry.coordinates;
    var P2 = F.geometry.coordinates;
    var point1 = turf.point(P1);
    var point2 = turf.point(P2);
    var midpoint = turf.midpoint(point1, point2);
    var distance = turf.distance(point1, point2, { units: 'meters' });
    var bearing = turf.bearing(point1, point2).toFixed(0);
    if (bearing < 0) bearing = 360 + parseInt(bearing);
    var AvgWind = AverageWind_Speed_Dir(
      PF.properties.FuelPlan.Wind,
      PF.properties.FuelPlan.WindDir,
      FP.Wind,
      FP.WindDir
    );
    var AverageWind = AvgWind.Wind_Avg * 1.94384; // in knots
    var AverageDir = AvgWind.WindDir_Avg;
    FP.GroundSpeed = getGroundSpeed(FP.AirSpeed, bearing, AverageWind, AverageDir);

    var FuelUsed = round10(((distance * 0.000539957) / FP.GroundSpeed) * FP.BurnRate);
    FP.StartFuel -= FuelUsed;
    FP.StartFuel = round10(FP.StartFuel);
    FP.UseableFuel -= FuelUsed;
    FP.UseableFuel = round10(FP.UseableFuel);
    figures.UseableFuel = FP.UseableFuel;
    FP.FuelUsed = round10(FuelUsed);
    FP.FuelRequired += FuelUsed;
    FP.FuelRequired = round10(FP.FuelRequired);
    FP.MaxDist = round10((FP.StartFuel / FP.BurnRate) * FP.GroundSpeed);
    FP.ReserveDist = round10((FP.UseableFuel / FP.BurnRate) * FP.GroundSpeed);
    FP.SegmentTime = Math.round(distance / (FP.GroundSpeed * 0.514444));
    FP.TotalLegTime += FP.SegmentTime;
    FP.TravelTime = Math.round(distance / (FP.GroundSpeed * 0.514444)) * 1000;
    FP.timestamp += FP.TravelTime;
    FP.Distance = distance;
    FP.TotalDistance += distance;
    MAPSTATE.DistanceLeft = round10(FP.ReserveDist * 1852);

    if (i > MEASURE.geojson.features.length - 3 && refreshing === false) {
      if (F.properties.isAlternate === true) {
        var ReserveZone = createGeoJSONCircle(coords, MAPSTATE.DistanceLeft, 'yellow', true, figures);
        FlyZone.data.features.push(ReserveZone.data.features[0]);
      } else {
        FlyZone = createGeoJSONCircle(coords, MAPSTATE.DistanceLeft, 'blue', true, figures);
      }
    }
    F.properties.FuelPlan = JSON.parse(JSON.stringify(FP));

    if (FP.Updated == false && getwind) {
      GetWXLatLong(i, FP, FuelPlan, refreshing);
      return;
    }
  }
  if (SCH_QUOTE_FuelPlan.noMap) {
    MEASURE.LEGS[refreshing] = JSON.parse(JSON.stringify(MEASURE.geojson));
    makeQuoteRouteLegs();
    return;
  }
  if (refreshing !== false) {
    MEASURE.LEGS[refreshing] = JSON.parse(JSON.stringify(MEASURE.geojson));
    UpdatedFuelPlanLeg(refreshing);
    return;
  }

  if (MAPSTATE.DistanceLeft < 0) {
    if (!MEASURE.dragoff && MEASURE.dialog == false) {
      MEASURE.dialog = true;
      if (MANUAL_LEG) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'WARNING',
          headerClose: false,
          buttonPrompt:
            'This manual route entry has exceeded the range of this aircrafts maximum Fuel capacity using the default cruise and fuel burn rates.',
          buttons: {
            'Remove Last Point': {
              click: function () {
                $('#LegDest').val('');
                MEASURE.dialog = false;
                MEASURE.geojson.features.pop();
                MeasureUpdateDraw();
              },
            },
            'Continue Anyways': {
              click: function () {},
            },
          },
        });
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'WARNING',
          headerClose: false,
          buttonPrompt: 'You have exceeded the range of the aircraft with the given fuel.',
          buttons: {
            'Remove Point': {
              click: function () {
                MEASURE.dialog = false;
                MEASURE.geojson.features.pop();
                MeasureUpdateDraw();
              },
            },
          },
        });
      }
      return;
    }
  }
  //if (!MANUAL_LEG){
  if (mapb.getSource('ROUTEPLAN-RADIUS') == undefined) {
    mapb.addSource('ROUTEPLAN-RADIUS', FlyZone);
    mapb.addLayer(
      {
        id: 'ROUTEPLAN-RADIUS',
        type: 'fill',
        source: 'ROUTEPLAN-RADIUS',
        layout: {},
        paint: {
          'fill-color': { type: 'identity', property: 'color' },
          'fill-opacity': 0.3,
        },
      },
      'Airports-Balloonport'
    );
    mapb.addLayer(
      {
        id: 'ROUTEPLAN-RADIUS-LABELS',
        type: 'symbol',
        source: 'ROUTEPLAN-RADIUS',
        layout: {
          'symbol-placement': 'point',
          'text-field': '{NM} NM',
          'text-size': 17,
          'text-justify': 'center',
          'text-anchor': 'center',
          'text-offset': [0, 1],
        },
        paint: {
          'text-halo-color': 'rgba(144,0,0,0.7)',
          'text-halo-width': TEXT_HALO_WIDTH,
          'text-halo-blur': TEXT_HALO_BLUR,
          'text-color': TEXT_COLOR,
        },
      },
      'RasterBefore'
    );
  } else mapb.getSource('ROUTEPLAN-RADIUS').setData(FlyZone.data);
  //}
}

function UpdateFuelPlan_Actual(index) {
  var TotalFuelUsed = 0;
  var TotalTravelTime = 0;
  for (var i in MEASURE.geojson.features) {
    var F = MEASURE.geojson.features[i].properties.FuelPlan;
    if (i == 0) {
      console.log('Skipping: ' + i);
      continue; //only update fuel plan for subsequent legs
    }
    console.log('GroundSpeed ' + i + ': ' + F.GroundSpeed_Actual);
    console.log('StartFuel ' + i + ': ' + F.StartFuel);
    var PF = MEASURE.geojson.features[parseInt(i) - 1].properties.FuelPlan;

    var FuelUsed = Math.round(((F.Distance * 0.000539957) / F.GroundSpeed_Actual) * PF.BurnRate_Actual);
    TotalFuelUsed += FuelUsed;
    //console.log(FuelUsed);
    F.StartFuel_Actual = Math.round(PF.StartFuel_Actual - FuelUsed);
    F.UseableFuel_Actual = Math.round(PF.UseableFuel_Actual - FuelUsed);
    console.log(TotalFuelUsed);
    F.UseableFuel_Actual = Math.round(F.UseableFuel_Actual);
    F.FuelUsed_Actual = Math.round(FuelUsed);
    F.FuelRequired_Actual = Math.round(PF.ReserveAmount_Actual + TotalFuelUsed);
    F.MaxDist_Actual = Math.round((F.StartFuel_Actual / F.BurnRate_Actual) * F.GroundSpeed_Actual);
    F.ReserveDist_Actual = Math.round((F.UseableFuel_Actual / F.BurnRate_Actual) * F.GroundSpeed_Actual);
    F.SegmentTime_Actual = Math.round(F.Distance / (F.GroundSpeed_Actual * 0.514444));
    F.TotalLegTime_Actual = Math.round(F.SegmentTime_Actual + PF.TotalLegTime_Actual);
    F.TravelTime_Actual = Math.round(F.Distance / (F.GroundSpeed_Actual * 0.514444)) * 1000;
  }
  MEASURE.LEGS[MEASURE.CurLeg] = JSON.parse(JSON.stringify(MEASURE.geojson));
}

function GetWXLatLong(i, FP, FPO, refreshing) {
  var F = MEASURE.geojson.features[i];
  if (FP.timestamp == null) {
    FP.timestamp = new Date().getTime();
  }
  if (ONLINE) {
    var url =
      TILE_URL +
      '/GetGribWind.php?timestamp=' +
      FP.timestamp +
      '&alt=' +
      FP.ACalt +
      '&lat=' +
      F.geometry.coordinates[1] +
      '&long=' +
      F.geometry.coordinates[0];
    $.getJSON(url, function (W) {
      FP.Updated = true;
      FP.Temp = W.Temp;
      FP.WindDir = W.Dir;
      FP.Wind = W.Spd;
      FP.Forecast = W.Forecast;
      //FP.WindDir = 90; //testing
      //FP.Wind = 10; //testing
      F.properties.FuelPlan = JSON.parse(JSON.stringify(FP));
      FinishFuelPlan(FPO, true, refreshing);
    }).fail(function (jqXHR, status, error) {
      console.log('Error Retrieving wind');
      FP.Updated = true;
      F.properties.FuelPlan = JSON.parse(JSON.stringify(FP));
      FinishFuelPlan(FPO, true, refreshing);
    });
  } else {
    FP.Updated = true;
    F.properties.FuelPlan = JSON.parse(JSON.stringify(FP));
    FinishFuelPlan(FPO, true, refreshing);
  }
}

function finishFuelPlanLeg(e) {
  if (MEASURE.LEGS.length == 0 && MEASURE.geojson.features.length == 0) {
    //no route was created
    if (MAPB_OBSTACLES) {
      //mapb.getSource('ROUTEPLAN-LINES').setData(lines);
      //CheckRouteObstacles(mapb);
    } else {
      //Route planning  / scheduler mode prompt for leg details

      $.mobile.changePage($('#ItinEnterLegInfo'), {
        reverse: false,
        changeHash: false,
      });

      UpdateNavVisibility(false);
      RoutePlanningON = false;
      $('#RoutePlanHldr').html('');
      PostRoute = null;
      MAPB_OBSTACLES = true;
    }
    $('#RoutePlanCHK').prop('checked', false).checkboxradio('refresh');
    $('#ReviewItinLegsBTN').prop('disabled', false);
    LoadRouteRA();

    return;
  }

  var lines = {
    type: 'FeatureCollection',
    features: [],
  };
  for (var L in MEASURE.LEGS) {
    var coords = [];
    for (var f in MEASURE.LEGS[L].features) {
      for (var c in MEASURE.LEGS[L].features[f].geometry.coordinates) {
        var C = MEASURE.LEGS[L].features[f].geometry.coordinates[c];
        coords.push([C[1], C[0]]);
      }
    }
    var feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
      properties: {
        Label: '',
      },
    };
    if (coords.length == 0) continue;
    feature.properties.Label = getRouteDistance(feature);
    lines.features.push(feature);
  }
  if (mapb.getSource('ROUTEPLAN-LINES') == undefined) {
    mapb.addSource('ROUTEPLAN-LINES', {
      type: 'geojson',
      data: lines,
    });
    mapb.addLayer({
      id: 'ROUTEPLAN-LINES',
      type: 'line',
      source: 'ROUTEPLAN-LINES',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#ff7800',
        'line-width': 4,
        'line-opacity': 0.7,
      },
    });
    mapb.addLayer({
      id: 'ROUTEPLAN-LINES-LABELS',
      type: 'symbol',
      source: 'ROUTEPLAN-LINES',
      layout: {
        visibility: 'visible',
        'symbol-placement': 'line',
        'text-field': '{Label}',
        'text-size': TEXT_SIZE,
        'text-justify': 'center',
        'text-anchor': 'top',
        'text-offset': [0, 1],
      },
      paint: {
        'text-halo-color': TEXT_HALO_COLOR,
        'text-halo-width': TEXT_HALO_WIDTH,
        'text-halo-blur': TEXT_HALO_BLUR,
        'text-color': TEXT_COLOR,
      },
    });
  } else mapb.getSource('ROUTEPLAN-LINES').setData(lines);

  //Route planning  / scheduler mode prompt for leg details
  $.mobile.changePage($('#ItinEnterLegInfo'), {
    reverse: false,
    changeHash: false,
  });
  ITIN_PopLeg();
  LoadRouteRA();
  UpdateNavVisibility(false);
  $('#coordinates').hide();
  $('#ReviewItinLegsBTN').prop('disabled', false);
}

function getMeasureFeature(features, OFP = false) {
  var Origin = '';
  var Routing = '';
  var Dest = '';
  var Alt = '';
  var FP = {};
  var AltFP = false;
  var Winds = 0;
  var WindDir = 0;
  var GroundSpeed = 0;
  var Bearing = 0;
  var Temp = 0;
  var Calcs = {
    Wind: 0,
    WindDir: 0,
    GroundSpeed: 0,
    Bearing: 0,
    Temp: 0,
    ETE: 0,
    ETE_Dst_Alt: 0,
    Dist: 0,
    Dist_Dst_Alt: 0,
    FuelEnroute: 0,
    FuelEnroute_Dst_Alt: 0,
    FuelRequired: {
      taxi: 0,
      destination: 0,
      alternate: 0,
      holding: 0,
      additional: 0,
    },
  };
  for (var i in features) {
    if (i == 0) {
      Origin = getMeasureFeatureLabel(features[i].properties, false);
      if (OFP) FP = JSON.parse(JSON.stringify(features[i].properties.FuelPlan));
      continue;
    }
    if (i != 0 && OFP) {
      var AvgWind = AverageWind_Speed_Dir(
        FP.Wind,
        FP.WindDir,
        features[i].properties.FuelPlan.Wind,
        features[i].properties.FuelPlan.WindDir
      );
      var P1 = features[parseInt(i) - 1].geometry.coordinates;
      var P2 = features[i].geometry.coordinates;
      var point1 = turf.point(P1);
      var point2 = turf.point(P2);
      var distance = turf.distance(point1, point2, { units: 'meters' });
      var Bearing = turf.bearing(point1, point2).toFixed(0);
      if (Bearing < 0) Bearing = 360 + parseInt(Bearing);

      var SegmentTime = Math.round(
        distance / (getGroundSpeed(FP.AirSpeed, Calcs.Bearing, Calcs.Wind, Calcs.WindDir) * 0.514444)
      );
      if (features[i].properties.isAlternate == true) {
        Calcs.ETE_Dst_Alt += SegmentTime;
        Calcs.Dist_Dst_Alt += distance;
        Calcs.FuelEnroute_Dst_Alt += features[i].properties.FuelPlan.FuelUsed;
      } else {
        Calcs.ETE += SegmentTime;
        Calcs.Dist += distance;
        Calcs.FuelEnroute += features[i].properties.FuelPlan.FuelUsed;
      }
    }
    if (i == 1 && OFP) {
      Calcs.Wind = Math.round(AvgWind.Wind_Avg * 1.94384);
      Calcs.WindDir = AvgWind.WindDir_Avg;
      Calcs.Bearing = Bearing;
      Calcs.Temp = Math.round((FP.Temp + features[i].properties.FuelPlan.Temp) / 2);
      Calcs.GroundSpeed = getGroundSpeed(FP.AirSpeed, Calcs.Bearing, Calcs.Wind, Calcs.WindDir);
    }
    if (i < features.length - 1) {
      if (features[parseInt(i) + 1].properties.isAlternate != true)
        Routing += getMeasureFeatureLabel(features[i].properties, true);
      Dest = getMeasureFeatureLabel(features[i].properties, false);
    }
    if (i == features.length - 1) {
      if (features[i].properties.isAlternate == true) {
        Alt = getMeasureFeatureLabel(features[i].properties, false);
        if (OFP) AltFP = JSON.parse(JSON.stringify(features[i].properties.FuelPlan));
      } else Dest = getMeasureFeatureLabel(features[i].properties, false);
    }
  }
  return {
    Origin: Origin,
    Routing: Routing,
    Dest: Dest,
    Alt: Alt,
    FP: FP,
    AltFP: AltFP,
    Calcs: Calcs,
  };
}
function getMeasureFeatureLabel(f, route) {
  if (f.NavType == 'Airport') {
    if (route) return '[' + f.ICAO + '] ';
    else return f.ICAO + ': ' + f.Name.toUpperCase();
  }
  if (f.NavType == 'Navaid') {
    if (route) return '[' + f.ID + '|' + f.type + '] ';
    else return f.ID + ': ' + f.Type;
  }
  var lat = ConvertDD_User(Math.round(f.Lat * 1000) / 1000, 'Lat');
  var long = ConvertDD_User(Math.round(f.Long * 1000) / 1000, 'Long');

  if (route) return '[' + lat + ',' + long + '] ';
  else return 'Waypoint: (' + lat + ',' + long + ')';
}
