class vSAMA_EditorCorrectiveActions extends vSAMA_AbstractEditor {
  constructor(_correctiveAction, _isReadOnly) {
    super();

    this.readOnly = _isReadOnly;
    this.correctiveAction = _correctiveAction;
    this.allowTermSelect = false;

    let body = $(SAMA.Templates.tpl.correctiveAction.editorView);
    this.dom = {
      wrapper: body,
      fiveWhyTabs: body.find('.five-y-tabedit > div'),
      staffSelect: body.find('[data-sref="coa_responsibleStaff"]'),
      dueDate: body.find('input[data-sref="coa_dueDate"]'),
      termSelect: body.find('select[data-sref="coa_term"]'),
    };

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.correctiveAction, this.dom.wrapper);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.correctiveAction, this.dom.wrapper, this);
      this.dom.termSelect.on('change', () => this.ToggleDateSelect());
    }

    cSAMA_GeneralInputs.Staff(this.dom.staffSelect, this.readOnly);
    cSAMA_GeneralInputs.Date(this.dom.dueDate, true, true);

    this.dom.wrapper.enhanceWithin();

    this.OnEvaluateTabsState();
  }

  ToggleDateSelect() {
    let termLength = -1;
    let newSelection = this.dom.termSelect.val();

    if(!this.allowTermSelect){
      this.allowTermSelect = true;
      return;
    }

    switch (newSelection) {
      case '1':
        termLength = SAMA.settings.preferences.sync.prf_immediateTermDays;
        break;
      case '2':
        termLength = SAMA.settings.preferences.sync.prf_shortTermDays;
        break;
      case '4':
        termLength = SAMA.settings.preferences.sync.prf_mediumTermDays;
        break;
      case '3':
        termLength = SAMA.settings.preferences.sync.prf_longTermDays;
        break;
    }

    if (termLength > 0) {
      const m = moment().add(termLength, 'd');
      this.dom.dueDate.val(m.format('YYYY-MM-DD')).trigger('input');
    }
  }

  GetDom() {
    return this.dom.wrapper;
  }

  OnEvaluateTabsState() {
    let disableTabs = [];
    for (let i = 1; i < 5; i++) {
      // If the tab before this one is empty, we disable it and all tabs after it
      let tabContents = this.correctiveAction.sync['coa_fy' + (i - 1)];
      if (tabContents == null || tabContents.length < 4) {
        for (let t = i; t < 5; t++) {
          disableTabs.push(t);
        }
        break;
      }
    }

    // For some reason, there sometimes is a race condition where the tabs-call
    // gets done before the enhancement is completed. Thus:
    try {
      this.dom.fiveWhyTabs.parent().enhanceWithin();
      this.dom.fiveWhyTabs.tabs('option', 'disabled', disableTabs);
    } catch (_e) {
      this.dom.fiveWhyTabs.parent().enhanceWithin();
      this.dom.fiveWhyTabs.tabs('option', 'disabled', disableTabs);
    }
  }

  ResetInputs() {
    let linkedInputs = this.dom.wrapper.find('.sama-linked').toArray();
    this.allowTermSelect = false;

    for (let l = 0; l < linkedInputs.length; l++) {
      let input = $(linkedInputs[l]);
      let propName = input.attr('data-sref');

      input
        .val(this.correctiveAction.sync[propName])
        .attr('value', this.correctiveAction.sync[propName])
        .trigger('change')
        .trigger('input');
    }

    this.dom.staffSelect.catTagger('refresh');
  }

  Destroy() {
    this.dom.wrapper.remove();
  }
}
