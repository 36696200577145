class vSAMA_TabTrendRule extends vSAMA_AbstractTab {
  constructor(_rule, _departmentSelect, _staffCache, _keywordCache, _readOnly) {
    super(_rule);

    this.SetUpGui('<li><a data-oref="tru_name"></a></li>', SAMA.Templates.tpl.trends.ruleTabEdit, _readOnly);

    this.dom.infUsers = this.dom.body.find('input[data-sref="tru_assignedUsers"]');
    this.dom.reporter = this.dom.body.find('input[data-sref="tru_reporterPk"]');
    this.dom.andKwds = this.dom.body.find('input[data-sref="tru_andKwds"]');
    this.dom.orKwds = this.dom.body.find('input[data-sref="tru_orKwds"]');
    this.dom.depts = this.dom.body.find('select[data-sref="tru_depPk"]');
    this.dom.depts = this.dom.body.find('select[data-sref="tru_depPk"]');
    this.dom.reps = this.dom.body.find('input[data-sref="tru_numReps"]');
    this.dom.timunits = this.dom.body.find('input[data-sref="tru_numTimeUnits"]');

    this.dom.tuerror = $('<div/>').addClass('sama-form-error-message').hide().text('Can not be 0.');

    let clone = _departmentSelect.clone(false);
    this.dom.depts.replaceWith(clone);

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    this.dom.body.enhanceWithin();

    cSAMA_GeneralInputs.Staff(this.dom.infUsers, false, 10, _staffCache);
    cSAMA_GeneralInputs.Staff(this.dom.reporter, false, 1, _staffCache);
    cSAMA_GeneralInputs.Keyword(this.dom.andKwds, false, false, _keywordCache);
    cSAMA_GeneralInputs.Keyword(this.dom.orKwds, false, false, _keywordCache);
  }

  OnDelete() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Really Delete Monitoring Rule?',
      message:
        'The assigned users will no longer receive notifications for this rule. ' +
        'Are you sure you want to delete it now?',
      yesFunc: () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        super.OnDelete();
      },
    });
  }

  OnTimeFrameChanged() {
    this.dom.tuerror.hide().detach();
    // If one of numReps or numTimeUnits is >0, the other one must also be >0
    if (this.model.sync.tru_numReps > 0 && this.model.sync.tru_numTimeUnits <= 0) {
      this.dom.timunits.parent().after(this.dom.tuerror.addClass('sama-form-error').show());
    } else if (this.model.sync.tru_numReps <= 0 && this.model.sync.tru_numTimeUnits > 0) {
      this.dom.reps.parent().after(this.dom.tuerror.addClass('sama-form-error').show());
    }
  }

  DestroySilently() {
    this.dom.infUsers.catTagger('destroy');
    this.dom.reporter.catTagger('destroy');
    this.dom.orKwds.catTagger('destroy');
    this.dom.andKwds.catTagger('destroy');
    super.DestroySilently();
  }
}
