class ProgressDialog {
  constructor() {
    this._title = '';
    this._dom = null;
  }

  show(title) {
    this._title = title;
    if (this._dom) {
      console.error('Progress dialog is already showing');
      return;
    }

    this._dom = document.createElement('div');
    this._dom.className = 'global-progress-dialog_modal';

    const elBox = document.createElement('div');
    elBox.className = 'global-progress-dialog_box';
    this._dom.appendChild(elBox);

    this.elTitle = document.createElement('h1');
    this.elTitle.className = 'global-progress-dialog_title';
    this.elTitle.innerText = title;
    elBox.appendChild(this.elTitle);

    this.elText = document.createElement('div');
    this.elText.className = 'global-progress-dialog_text';
    this.elText.innerText = '';
    elBox.appendChild(this.elText);

    const elBar = document.createElement('div');
    elBar.className = 'global-progress-dialog_bar';
    elBox.appendChild(elBar);

    this.elFill = document.createElement('div');
    this.elFill.className = 'global-progress-dialog_fill';
    this.elFill.style.width = '50%';
    elBar.appendChild(this.elFill);

    this.elSubText = document.createElement('div');
    this.elSubText.className = 'global-progress-dialog_text';
    this.elSubText.innerText = '';
    elBox.appendChild(this.elSubText);

    this.update('Preparing...', 0);

    document.body.appendChild(this._dom);
  }

  update(_msg, _progressPercentage = 0) {
    this.elFill.style.width = `${_progressPercentage}%`;
    this.elText.innerText = `${_msg}`;
    this.elSubText.innerText = `${_progressPercentage}%`;
  }

  hide() {
    if (!this._dom) {
      return;
    }

    document.body.removeChild(this._dom);
    this._dom = null;
  }
}

window.progressDialog = new ProgressDialog();
