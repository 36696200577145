if (window.vueApp == null) {
  window.vueApp = {
    built: true,
    inVue: false,
    ready: false,
    enteredRevampHomeMenu: false,
    groupKey: -1,
    groupId: -1,
    user: null,
    authTools: {},
    constants: {},
    models: {},
    groupPreferences: {},
    preferences: {},
    repositories: {},
    utilities: {},
  };
}

class VueBridge {
  static setHtmlStyle(_inVue) {
    const html = $('html');
    if (_inVue) {
      return html.addClass('ui-vue').removeClass('ui-mobile');
    }

    html.removeClass('ui-vue').addClass('ui-mobile');
  }

  static async Initialize() {
    // ----------------
    // System Listeners
    // ----------------
    // listen for vue event, to navigate back to legacy
    window.addEventListener(
      'cirro-back-to-legacy',
      (e) => {
        verbose.log('Vue', 'event: cirro-back-to-legacy', e);
        this.leaveVue(e.detail);
      },
      false
    );

    window.addEventListener(
      'cirro-user-became-unauthenticated',
      () => {
        this.leaveVue({ page: 'MainMenu' });
        NOTLOGGEDIN();
      },
      false
    );

    window.addEventListener(
      'cirro-logout-and-restart',
      (e) => {
        verbose.log('Vue', 'logout and restart');
        this.leaveVue({ id: '#Initialization_Page' });

        let mode = 'RemoveProfile';
        if (e.detail.keepUserData) {
          mode = '';
        }
        if (e.detail.keepGroupData) {
          mode = 'KeepGroupData';
        }

        Logout(mode);
      },
      false
    );

    window.addEventListener(
      'cirro-restart',
      (e) => {
        this.leaveVue({ id: '#Initialization_Page' });
        restartCirro();
      },
      false
    );

    window.addEventListener(
      'cirro-enter-vue',
      (e) => {
        this.enterVue(e.detail.route);
      },
      false
    );

    // User Settings Listener
    window.addEventListener('cirro-modified-user-settings', (e) => this.updateUserSettings(e.detail), false);
    window.addEventListener('cirro-changed-user-name', (e) => {
      if (SCHEDULER > 0 && ONLINE && !LOW_BANDWIDTH) {
        // resync offline scheduler data
        getOfflineScheduleData();
      }
    });

    // -------------
    // EFB Listeners
    // -------------
    window.addEventListener('capi-efb-offline-file-finish', (e) => {
      clearTimeout(syncTimer);
      syncTimer = setTimeout('SyncToolLong()', 120000);
    });

    window.addEventListener('capi-efb-offline-file-finish-error', (e) => {
      PDF_SYNC_ERROR = true;
      this.showDialog(
        'File Sync Error',
        'There was an <b>error</b> attempting to synchronize one or more files.<br/>The system will attempt to download the files again on the next synchronization.<br/>You can also manually <b>Sync Now</b> to try again at any time.',
        'error'
      );
    });

    // on offline file finish
    window.addEventListener('capi-efb-offline-finish', (e) => {
      PDF_SYNCING = false;
      SYNC_STATUS('PDF');
      this.updateMenu('Offline Sync Finish');
    });

    window.addEventListener(
      'cirro-tdg-manifest-created-itinerary',
      (e) => {
        window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS = this.#addManifestIdToArray(
          window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS,
          e.detail.manifestId
        );
      },
      false
    );

    window.addEventListener(
      'cirro-tdg-manifest-created-updated-leg',
      (e) => {
        window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS = this.#addManifestIdToArray(
          window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS,
          e.detail.manifestId
        );
      },
      false
    );

    window.addEventListener('cirro-store-session-data', (e) => storeSessionData(e.detail));
    window.addEventListener(
      'cirro-tdg-manifest-leg-update-complete',
      (e) => {
        WB_setEnabledStateOfTDG(true);
      },
      false
    );

    window.addEventListener('cirro-reset-offline-databases', () => {
      confirm_ResetOfflineDatabases();
    });

    // disable all vue navigation buttons until capi is loaded
    $('.capi-nav-button').prop('disabled', true);
  }

  static #addManifestIdToArray(idsParam, manifestId) {
    let ids = idsParam ?? [];

    if (ids.includes(manifestId)) {
      return ids;
    }

    ids.push(manifestId);
    return ids;
  }

  static onSyncFinished() {
    if (window.sw != null) {
      /* Issue with SW shouldn't prevent people from working online*/
      try {
        window.sw.init();
      } catch (_e) {}
    }
  }

  /**
   * Navigate to Vue Page
   */
  static async enterVue(route) {
    let waitCycle = 0;
    while (!window.vueApp.ready) {
      await this.wait();
      waitCycle++;
      if (waitCycle > 60) {
        verbose.error('Gave up waiting on Vue for enter vue');
        return;
      }
    }

    verbose.log('Vue', 'Go to vue page', route);

    // set inVue status, and reset Vue history stack
    VueBridge.clearVueHistory();

    // clear any existing errors then go to route
    await window.vueApp.app.$store.commit('app/SET_ERROR', null);
    window.vueApp.app.$router.push(route);

    VueBridge.setHtmlStyle(true);
    goToPage('#capi-vue-page');

    window.vueApp.inVue = true;
  }

  static clearVueHistory() {
    window.vueApp.app.$router.history.stack = [];
    window.vueApp.app.$router.history.index = -1;
  }

  /**
   * @typedef VueCallback
   * @property {string|null} page An identifier for the page we're changing to. In the simplest case, this should be the id of the page without a pound sign
   */

  /**
   * @param {VueCallback} callbackObject object describing some legacy callback to execute
   */
  static leaveVue(callbackObject) {
    if (!window.vueApp.inVue) {
      verbose.log('Vue', 'Not in Vue, leaving Vue not necessary');
      return;
    }

    VueBridge.setHtmlStyle(false);

    VueBridge.runLegacyCallback(callbackObject);

    // update menu icons
    VueBridge.updateMenu('Leave Vue');
    VueBridge.leaveVueCap();
  }

  static leaveVueCap() {
    // update inVue status and default route
    window.vueApp.inVue = false;
    VueBridge.clearVueHistory();
    window.vueApp.app.$router.push('/loading');
  }

  static runLegacyCallback(callbackObject) {
    switch (callbackObject.page) {
      case FormsInBookings.PAGE_EDITOR:
        FormsInBookings.handleReturnToAdmin();
        break;
      case FormsInBookings.PAGE_VIEWER:
        FormsInBookings.handleReturnToView();
        break;
      case FormsInItineraryCreator.PAGE:
        FormsInItineraryCreator.handleReturnToCreatePage();
        break;
      case FormsInItineraryEditor.PAGE:
        FormsInItineraryEditor.handleReturnToEditPage();
        break;
      case 'Schedule_Page':
      case 'UpdateFDTList':
        if (VueBridge.canAccessAdminToolMenu()) {
          exitAdminRevampFDT();
          Admin_Update_FDT();
        } else {
          goToPage('#' + callbackObject.page);
          exitAdminRevampFDT();
        }
        break;
      case 'ITIN-TDG-REVAMP-CANCEL':
        $('#TDG-REVAMP').prop('checked', false).checkboxradio('refresh');
        $('#TDG-REVAMP-ManifestEdit').prop('disabled', true);
        goToPage('#ITIN-TDG-REVAMP');
        break;
      case 'SAMA_AD_Preferences':
        SAMA.Navigator.GoPage(SAMA.pages.PreferencesEditor);
        break;
      case 'SAMA_AD_TrendsEdit':
        SAMA.Navigator.GoPage(SAMA.pages.TrendsEdit);
        break;
      case 'RA_Home':
        retrieveRiskForms('Admin');
        break;
      case 'SAMA_AD_RiskMtxEdit':
        SAMA.Navigator.GoPage(SAMA.pages.RiskMatrixEditor);
        break;
      case 'LabellingOptionsPage':
        LabellingOptions();
        break;
      case 'adminClients':
        ManageClients();
        break;
      case 'SchedulerOptionsPage':
        Scheduler_options();
        break;
      case 'PassengerWeightsPage':
        PassengerWeight_options();
        break;
      case 'PAX_Briefing_Admin':
        PaxManifestOptions();
        break;
      case 'BIoptions':
        BI_options();
        break;
      case 'clickHandler':
        clickHandler(callbackObject.module);
        break;
      case 'initReport':
        clickHandler('Reports'); // This is necessary to load the back button handler (To go to revamp admin tool menu) from the report page
        init_Report(callbackObject.module, callbackObject.query);
        break;
      default:
        const page = callbackObject.page;

        if (page === 'MainMenu') {
          window.vueApp.enteredRevampHomeMenu = false;
        }

        goToPage('#' + page);
        break;
    }
  }

  /**
   * Update menu icons from Vue data
   */
  static async updateMenu(_whoCalledMe) {
    verbose.log('Vue', 'Update Menu called by: ', _whoCalledMe);

    // Proxy the app object for readability
    const a = window.vueApp;

    let waitCycle = 0;
    while (!a.ready) {
      await this.wait();
      waitCycle++;
      if (waitCycle > 60) {
        verbose.error('Vue', 'Gave up waiting on Vue for menu update');
        return;
      }
    }

    if (DOCUMENTS > 0 || MEMOS > 0) {
      verbose.log('vue', 'EFB index from update menu');
      await a.app.$store.dispatch('efb/index', { groupKey: a.groupKey });
    }

    if (this.hasPermission('library.certifiables.view')) {
      await a.repositories.certifiableRecords.getHomeCount(a.groupKey);
    }

    if (this.hasPermission('library.forms.use')) {
      await a.app.$store.dispatch('forms/homeCount', { groupKey: a.groupKey });
    }

    // update library badge
    // update library badge
    const unread = [];

    const promises = [];

    promises.push(a.repositories.CapabilityRepository.getOverridesRequiringApprovalCount(a.groupKey, a.user.id));

    if (FORMS > 0) {
      promises.push(a.app.$store.dispatch('forms/homeCount', { groupKey: a.groupKey }));
    }

    if (DOCUMENTS > 0) {
      unread.push(a.app.$store.getters['efb/getUnreadCount']('efb'));
    }

    if (MEMOS > 0) {
      unread.push(a.app.$store.getters['efb/getUnreadCount']('mem'));
    }

    if (CERTS > 0) {
      unread.push(a.models.keyvaluestore.getKeyValue('certifiable_pending_count', 0));
      unread.push(a.models.keyvaluestore.getKeyValue('certifiable_essential_expired_count', 0));
    }

    unread.push(...(await Promise.all(promises)));

    const unreadCount = unread.reduce((acc, val) => acc + parseInt(val), 0);

    VueBridge.setLibraryBadge(unreadCount);

    $('.capi-nav-button').prop('disabled', false);

    setFavourites();
    let hasPermission = VueBridge.hasPermission(['tasksAndManagement.tasks.use']);
    if (!hasPermission) {
      $('#todolistContainer').hide();
    }
    const reportsPerms = [
      'library.certifiables.useAndReport',
      'safetyManagement.flightRiskAssessments.useAndReport',
      'flights.itineraries.useAndReport',
      'billing.flightReports.useAndReport',
      'billing.expenses.useAndReport',
      'admin.dangerousGoods.useAndReport',
      'tasksAndManagement.timeManagement.useAndReport',
      'admin.assets.useAndReport',
      'passengerManifesting.general.useAndReport',
      'group.userAccounts.useAndReport',
    ];

    hasPermission = VueBridge.hasPermission(reportsPerms);
    if (!hasPermission) {
      $('#reports').prop('disabled', true);
    }

    const haz = $('.sama-not-feat-fish');
    const fish = $('.sama-feat-fish');

    haz.hide();
    if (VueBridge.hasFeature('fish')) {
      fish.show();
    } else {
      fish.hide();
      haz.show();
    }

    this.updatei18n();
  }

  static async dropSession() {
    document.cookie = 'PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'KEY_PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    window.vueApp.authTools.authSetToken(null);

    if (typeof cordova !== 'undefined' && cordova.plugin != null && cordova.plugin.iosClearCookies != null) {
      cordova.plugin.iosClearCookies();
    }
  }

  static enterRevampHomeMenu() {
    window.vueApp.enteredRevampHomeMenu = true;
    VueBridge.enterVue('/');
  }

  /**
   * Shortcut to enter Admin Tool Menu
   */
  static enterAdminToolMenu() {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/administration/tool-menu`);
  }

  /**
   * Shortcut to enter Personal Itinerary module
   */
  static enterPersonalItinerary() {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/personal-itinerary/itineraries`);
  }

  /**
   * Shortcut to enter Vehicle Reports module
   */
  static enterVehicleReports() {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/vehicle-reports`);
  }

  /**
   * Shortcut to enter Vehicle Maintenance module
   */
  static enterVehicleMaintenance() {
    let fromQuery = !VueBridge.canAccessAdminToolMenu() ? '?from-legacy=Administration_Page' : '';

    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/vehicle-maintenance${fromQuery}`);
  }

  /**
   * Set menu company library badge count
   *
   * @param count
   */
  static setLibraryBadge(count) {
    const documentsBadge = $('#documents-badge-number');
    if (count <= 0) {
      documentsBadge.hide();
      return;
    }
    documentsBadge.text(count).show();
  }

  /**
   * Shortcut to enter EFB Library
   */
  static enterLibrary(_fromPage = null) {
    const fromQuery = _fromPage != null ? `?from-legacy=${_fromPage}` : '';
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/library${fromQuery}`);
  }
  /**
   * Shortcut to enter Dashboard Notifications
   */
  static enterDashboard(_fromPage = null) {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/dashboard`);
  }

  /**
   * Shortcut to enter Field Gear
   */
  static enterFieldGear(_fromPage = null) {
    const fromQuery = _fromPage != null ? `?from-legacy=${_fromPage}` : '';
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/fieldGear${fromQuery}`);
  }

  /**
   * Shortcut to enter Flight Duty
   */
  static enterFlightDuty(_fromPage = null) {
    const fromQuery = _fromPage != null ? `?from-legacy=${_fromPage}` : '';
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/flightDuty${fromQuery}`);
  }

  /**
   * Shortcut to enter Flight Duty
   */
  static enterEnterpriseReports(_fromPage = null) {
    const fromQuery = _fromPage != null ? `?from-legacy=${_fromPage}` : '';
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/reports/enterpriseReporting${fromQuery}`);
  }

  /**
   * Shortcut to enter Field Gear
   */
  static enterEquipmentList(_fromPage = null) {
    const fromQuery = _fromPage != null ? `?from-legacy=${_fromPage}` : '';
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/maintenance/equipmentList${fromQuery}`);
  }

  /**
   * Shortcut to user settings
   */
  static enterUserSettings() {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/users/me/preferences`);
  }

  /**
   * Shortcut to enter tag settings for a given user
   */
  static enterUserTags(_userId, _returnTo) {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/tags/users/${_userId}?from-legacy=${_returnTo}`);
  }

  /**
   * Shortcut to enter Notification Log
   */
  static enterNotificationLog() {
    VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/reports/notification-log`);
  }

  /**
   * Retrieve offline documents
   *
   * @returns {Promise<void>}
   */
  static async getOfflineDocuments() {
    let waitCycle = 0;
    while (!window.vueApp.ready) {
      await this.wait();
      waitCycle++;
      if (waitCycle > 60) {
        verbose.error('vue', 'Gave up waiting on Vue for offline docs');
        return;
      }
    }

    if (DOCUMENTS > 0 || MEMOS > 0) {
      await window.vueApp.app.$store.dispatch('efb/getOfflineDocuments', { groupKey: window.vueApp.groupKey });
    }
  }

  static TdgManifestLinkToItinerary(itineraryId) {
    // There isn't a linked TDG manifest
    if (!window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS) {
      return;
    }

    const args = {
      groupKey: window.GROUP_DATA.group,
      userId: window.USER_PK,
      itineraryId,
      manifestIds: window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS,
      createdOffline: !window.ONLINE,
    };

    window.dispatchEvent(new CustomEvent('cirro-tdg-manifest-link-to-itinerary', { detail: args }));
  }

  static TdgManifestUpdate() {
    if (!window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS) {
      return;
    }

    const args = {
      groupKey: window.GROUP_DATA.group,
      userId: window.USER_PK,
      manifestIds: window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS,
    };

    window.dispatchEvent(new CustomEvent('cirro-tdg-manifest-leg-update', { detail: args }));

    window.CURRENT_LEGS_TDG_REVAMP_MANIFEST_IDS = [];
  }

  static TdgSyncItineraryCreatedOfflineToItinModel(itinData) {
    if (!window.CURRENT_ITIN_TDG_REVAMP_MANIFEST_IDS) {
      return;
    }
    window.dispatchEvent(
      new CustomEvent('cirro-tdg-sync-itinerary-created-offline-to-itin-model', { detail: itinData })
    );
  }

  static TdgManifestClear(manifestIds) {
    const args = {
      manifestIds,
    };
    window.dispatchEvent(new CustomEvent('cirro-tdg-manifest-clear', { detail: args }));
  }

  /**
   * This is ran when the app has completed syncing any offline data
   */
  static DataCreatedOfflineSync() {
    window.dispatchEvent(new CustomEvent('cirro-data-created-offline-sync'));
  }

  /*
   * This is ran when the app is currently syncing
   */
  static LegacyAppSyncing(syncing) {
    window.dispatchEvent(new CustomEvent('cirro-legacy-syncing', { detail: syncing }));
  }

  /**
   * Closes an open manifest asociated to a itin primary key
   */
  static CloseOpenManifest(ItinPrimaryKey) {
    window.dispatchEvent(new CustomEvent('cirro-tdg-close-open-manifest', { detail: { ItinPrimaryKey } }));
  }

  /**
   * Show the invalid capability dialog from a legacy page
   */
  static showInvalidCapabilitiesDialog(
    actionSlug,
    onValidCallback,
    onAdminOverrideRequestCallback = () => {},
    onCancelCallback = () => {}
  ) {
    const args = {
      actionSlug,
      onValidCallback,
      onAdminOverrideRequestCallback,
      onCancelCallback,
      legacyPageId: $.mobile.activePage.attr('id'),
    };

    window.dispatchEvent(new CustomEvent('cirro-show-invalid-capabilities-dialog', { detail: args }));
  }

  static showDialog(_title, _message, _color) {
    if (window.vueApp.inVue) {
      window.vueApp.app.$store.commit('app/SET_DIALOG', {
        title: _title,
        message: _message,
        color: _color,
      });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: _title,
        headerClose: false,
        buttonPrompt: _message,
        buttons: {
          Close: {
            click: function () {
              // close please
            },
          },
        },
      });
    }
  }

  static wait(_ms) {
    _ms = _ms == null ? 1000 : _ms;
    return new Promise((resolve) => {
      window.setTimeout(resolve, _ms);
    });
  }

  /**
   * Triggered when the user leaves the account settings page
   *
   * @param {string}  settingsObject.startupScreen
   * @param {string}  settingsObject.timeInput
   * @param {string}  settingsObject.unitSystem
   */
  static updateUserSettings(settingsObject) {
    const startUpScreenMap = {
      dash: 'dashboard',
      home: 'HomeScreen',
      map: 'Map',
      following: 'Following',
    };

    const timeInputMap = {
      keyboard: 'Keyboard',
      keypad: 'Keypad',
      clock_picker: 'Clockpicker',
    };

    const unitSystemMap = {
      imperial: 'Imperial',
      metric: 'Metric',
    };

    LOCALSTORAGE.UserUNITS = unitSystemMap[settingsObject.unitSystem];
    localStorageDB.setItem('UserUNITS', LOCALSTORAGE.UserUNITS);

    LOCALSTORAGE.STARTUP = startUpScreenMap[settingsObject.startupScreen];
    localStorageDB.setItem('STARTUP', LOCALSTORAGE.STARTUP);

    LOCALSTORAGE.POLL_TIMEZONE = settingsObject.pollTimezone ? null : false;
    localStorageDB.setItem('POLL_TIMEZONE', LOCALSTORAGE.POLL_TIMEZONE);

    LOCALSTORAGE.TimeInput = timeInputMap[settingsObject.timeInput];
    localStorageDB.getItem('TimeInput', function (old) {
      localStorageDB.setItem('TimeInput', LOCALSTORAGE.TimeInput, function () {
        initChangeTimeInput();
        if (old != null && old !== LOCALSTORAGE.TimeInput) {
          verbose.log('vue', 'Restarting because of time input difference', LOCALSTORAGE.TimeInput, old);
          return restartCirro();
        }
      });
    });

    USER_RIGHTS.FDT_DayCur = settingsObject.FDT_DayCur;
    USER_RIGHTS.FDT_NightCur = settingsObject.FDT_NightCur;
    USER_RIGHTS.FDT_NvgCur = settingsObject.FDT_NvgCur;
    USER_RIGHTS.FDT_IfrCur = settingsObject.FDT_IfrCur;
    USER_RIGHTS.FDT_FloatRating = settingsObject.FDT_FloatRating;

    verbose.log('Vue', 'Updated user settings', settingsObject, LOCALSTORAGE);
  }

  static onPageShow(pageOrEvent) {
    // Emulate the CAPI behaviour of checking whether the user is still valid. This is throttled internally.
    window.vueApp.authTools.authRefreshUser();

    // If we're passing in a page, use it. If we're passing in an event, use "this"
    const page = 'currentTarget' in pageOrEvent ? $(this) : $(pageOrEvent);

    // This handles the v-enable-feature attribute by passing the contents of that attribute to vue's auth facades
    // This will remove the element if the feature is not enabled for the logged in user
    page.find('[v-enable-feature]').each(function () {
      const element = this;
      let feature = element.getAttribute('v-enable-feature');
      let hasFeature = false;

      try {
        hasFeature = VueBridge.hasFeature(...feature.split(','));
      } catch (e) {
        verbose.error('VUE', e);
      }

      if (!hasFeature) {
        element.remove();
      }
    });

    // This handles the v-enable-permission attribute by passing the contents of that attribute to vue's auth facades
    // use "level.super" to check for super users. For other permission paths, check revamp's PermissionTypes class.
    page.find('[v-enable-permission]').each(function () {
      const element = $(this);
      let path = element.attr('v-enable-permission');

      let hideInsteadOfDisabling = false;
      let hasPermission = false;
      if (path.indexOf('remove:') > -1) {
        hideInsteadOfDisabling = true;
        path = path.replace('remove:', '');
      }

      try {
        if (path.indexOf('level.super') > -1) {
          hasPermission = window.vueApp.authTools.authIsSuperUser(window.vueApp.groupKey);
        } else {
          hasPermission = VueBridge.hasPermission(path.split(','));
        }
      } catch (e) {
        verbose.error('VUE', e);
      }

      if (hideInsteadOfDisabling) {
        if (hasPermission) {
          element.show();
        } else {
          element.hide();
        }
      } else {
        element.attr('disabled', !hasPermission);
      }
    });
    // This handles the v-t translation attribute by passing the contents of that attribute to vue-i18n.
    page.find('[v-t]').each(function () {
      const element = $(this);
      element.text(i18n.t(element.attr('v-t')));
    });
    // Handles v-html translation by passing the contents to vue-i18n and receiving a string with html then
    // setting the html according to the string
    page.find('[v-html]').each(function () {
      const element = $(this);
      // Set use the html function to grab the string and parse for html to show on the page.
      element.html(i18n.t(element.attr('v-html')));
    });
  }

  static updatei18n() {
    verbose.log('I18N', 'Updated Internationalization');
    $('div[data-role="page"]').each(function () {
      VueBridge.onPageShow(this);
      $(this).off('pageshow', VueBridge.onPageShow).on('pageshow', VueBridge.onPageShow);
    });
  }

  static showConfirmDialog(_title, _message, _runOnOk) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: _title,
      headerClose: false,
      buttonPrompt: _message,
      buttons: {
        Ok: {
          click: () => {
            if (typeof _runOnOk == 'function') {
              _runOnOk();
            }
          },
        },
        Cancel: {
          click: () => {},
        },
      },
    });
  }

  static setPreference(path, value) {
    window.vueApp.preferences.set(GROUP_DATA.PrimaryKey, path, value);
  }

  static getPreference(path, defaultValue = null) {
    return window.vueApp.preferences.get(path, defaultValue);
  }

  /**
   * Sets a value for a specific group preference by calling the set method from the actual GroupPreference model.
   *
   * @param {string} path
   * @param {*} value
   */
  static setGroupPreference(path, value) {
    window.vueApp.groupPreferences.set(GROUP_DATA.PrimaryKey, path, value);
  }

  /**
   * Gets a value for a specific group preference by calling the get method from the actual GroupPreference model.
   *
   * @param {string} path
   * @param {*} defaultValue
   */
  static getGroupPreference(path, defaultValue = null) {
    return window.vueApp.groupPreferences.get(path, defaultValue);
  }

  /**
   * Check if the user has a feature flag enabled
   * @param features
   * @returns {*}
   */
  static hasFeature(...features) {
    try {
      return window.vueApp.authTools.authHasFeature(window.vueApp.groupKey, ...features);
    } catch (e) {
      return false;
    }
  }

  static hasPermission(permissions) {
    return window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, permissions);
  }

  /**
   * An easy accessor for the dangerous goods constants
   * @returns {object}
   */
  static get dangerousGoodsConstants() {
    return window.vueApp.constants.capabilities.dangerousGoods;
  }

  /**
   * An easy accessor for the cpability utilities
   * @returns {object}
   */
  static get capabilityUtils() {
    return window.vueApp.utilities.capabilities;
  }

  /**
   * An easy accessor for the date time utilities
   *
   * @typedef {object} DateTimeUtils
   * @property {string} dateFormat
   * @property {string} timeFormat
   * @property {string[]} formattableDateFormats
   *
   * @property {(timestamp: string|moment.Moment, timezone: string) => moment.Moment|null} convertTimezoneToUtc
   * @property {(timestamp: string|moment.Moment, timezone: string) => string|moment.Moment|null} convertUtcToTimezone
   * @property {(timestamp: string|moment.Moment) => string|moment.Moment|null} convertUtcToUsersTimezone
   * @property {() => string} currentUsersTimezonePreference
   * @property {() => string} currentUsersHumanTimezonePreference
   * @property {(_seconds: number) => null|{hours: number, minutes: number, seconds: number }} secondsToHumanDuration
   * @property {(_seconds: number, _default: string|null) => string|null} secondsToHumanDurationString
   * @property {(_timeStamp: string|Moment, _date: boolean, _time: boolean, _default: *) => string} humanTime
   * @property {(_val: string, utc: boolean) => Object} momentWithFormats
   *
   * @returns {DateTimeUtils}
   */
  static get dateTimeUtils() {
    return window.vueApp.utilities.dateTimeUtils;
  }

  /**
   * @typedef {object} timezones
   * @property {string} defaultTimeZone
   * @property {id: string, text: string, offset: number} default
   *
   * @returns {timezones}
   */
  static get timezones() {
    return window.vueApp.utilities.timezones;
  }

  /**
   * An easy accessor for the deprecation utilities
   *
   * @typedef {object} DeprecationUtils
   * @property {(deprecationName: string, deprecationWarnDate: string, deprecationUser: string) => void} markDeprecated
   *
   * @returns {DeprecationUtils}
   */
  static get deprecationUtils() {
    return window.vueApp.utilities.deprecationUtils;
  }

  /**
   * An easy accessor for lodash debounce
   *
   * @returns {(func: Function, wait: number, options: {leading: boolean, maxWait: number, trailing: boolean}) => Function} debounce
   */
  static get debounce() {
    return window.vueApp.utilities.debounce;
  }

  /**
   * Switch to DB debugging tool
   */
  static debugDatabase() {
    VueBridge.enterVue('/debug/database');
  }

  /**
   * Switch to FS Debugging tool
   */
  static debugFileSystem() {
    VueBridge.enterVue('/debug/file-system');
  }

  static canAccessAdminToolMenu() {
    return VueBridge.hasFeature('adminMenuRevamp') || window.vueApp.enteredRevampHomeMenu;
  }

  static showPdfViewerDialog(url, fileName) {
    window.vueApp.utilities.appBus.$emit('show-pdf-viewer-dialog', { url, fileName });
  }

  static enterCorrectAdminToolMenu() {
    if (VueBridge.canAccessAdminToolMenu()) {
      VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/administration/tool-menu`);
    } else {
      $.mobile.changePage($('#Administration_Page'), {
        reverse: true,
        changeHash: false,
      });
    }
  }
}
