var localStorageDB = {
  getItem: function (keyname, callback) {
    queryLocalStorageDB('SELECT', keyname, null, callback);
  },
  setItem: function (keyname, StringData, callback) {
    queryLocalStorageDB('INSERT', keyname, StringData, callback);
  },
  clearItem: function (keyname, callback) {
    queryLocalStorageDB('DELETE', keyname, null, callback);
  },
  clearAll: function (callback) {
    queryLocalStorageDB('DROP', null, null, callback);
  },
  getAll: function (callback) {
    queryLocalStorageDB('SELECT_ALL', null, null, callback);
  },
};

function queryLocalStorageDB(action, key, data, callback) {
  const tRef = LocalStorageTable.query();

  if (typeof callback != 'function') {
    callback = () => {};
  }

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );
      return;
    case 'INSERT':
      tRef.insert(key, data).then(
        () => callback(true),
        () => callback(false)
      );
      return;
    case 'SELECT':
      tRef.selectByKey(key).then(
        (res) => callback(res),
        () => callback(false)
      );
      return;
    case 'SELECT_ALL':
      tRef.selectAll().then(
        (res) => callback(res),
        () => callback(false)
      );
      return;
    case 'DELETE':
      tRef.delete(key).then(
        () => callback(true),
        () => callback(false)
      );
      return;
  }
}
