/**
 * Class handling the display and real-time modification of SAMA reports
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_ReportsEditor extends vSAMA_AbstractEditor {
  constructor(_pageBody, _report, _departments, _categories, riskMatrix, _editPermissions) {
    super();
    this.oldReportType = null;
    this.departments = _departments;
    this.categories = _categories;
    this.riskMatrix = riskMatrix;
    this.permissions = _editPermissions;
    this.report = _report;
    this.readOnly = _editPermissions === SAMA.enums.ACCESS.VIEW || _editPermissions === SAMA.enums.ACCESS.DELETE;

    this.dom = {
      page: _pageBody,
      instructionLine: _pageBody.find('.sama-instructions'),
      itinSelect: _pageBody.find('#itinSelect'),
      legSelect: _pageBody.find('#itinLegSelect'),
      itinSourceFieldset: _pageBody.find('#sama-itinSource'),
      itinDateFrom: _pageBody.find('#sama-itinDateFrom'),
      itinDateTo: _pageBody.find('#sama-itinDateTo'),
      itinRepText: _pageBody.find('#sama-itinRepText'),
      departmentDropdown: _pageBody.find('select[data-sref="rep_depPk"]'),
      categoryDropdown: _pageBody.find('select[data-sref="rep_catPk"]'),
      toggleCategory: _pageBody.find('#isShowRepCategory'),
      typeDropdown: _pageBody.find('#sama-reportType'),
      otherField: _pageBody.find('#repTarmac'),
      photoUploader: _pageBody.find('input.use_gal'),
      anonBox: _pageBody.find('.anonBox'),
      anonCheck: _pageBody.find('.anonBox').find('input'),
      proactiveElements: _pageBody.find('.sama-rep-proactive'),
      reactiveElements: _pageBody.find('.sama-rep-reactive'),
      simpleHiders: _pageBody.find('.sama-rep-simple-hidden'),
      keyWords: _pageBody.find('input[data-sref="rep_keywordPks"]'),
      staffAccess: _pageBody.find('input[data-sref="rep_assignedStaff"]'),
      adminOnly: _pageBody.find('#rep-admin-only'),
      reportDocLoader: _pageBody.find('#rep_fileloader'),
      fileList: _pageBody.find('#rep_filelist'),
      reviewerOnly: _pageBody.find('.reviewer-only'),
    };

    this.DescriptionValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 5,
      maxLength: SAMA.enums.TEXTLENGTHS.Large,
      required: false,
      mustNotContain: SAMA.settings.preferences.GetBadWords(),
    });

    this.dom.typeDropdown.on('change', (newValue) => this.updateReportType(newValue));
    this.dom.typeDropdown.val(this.report.sync.rep_isProactive ? '1' : '0');

    try {
      this.dom.typeDropdown.selectmenu('refresh');
    } catch (e) {}

    if (this.report.sync.rep_itinPk > 0) {
      // Got an itin preset.
      this.FillItinFields();
    } else {
      this.ApplyItinLegEventHooks();
    }
  }

  ResetItinInputs() {
    this.dom.itinDateFrom.val('').prop('disabled', this.readOnly);
    this.dom.itinDateTo.val('').prop('disabled', this.readOnly);

    try {
      if (this.readOnly === true) {
        this.dom.itinSourceFieldset.selectmenu('disable');
        //console.log('SAMA', 'DIS');
      } else {
        this.dom.itinSourceFieldset.selectmenu('enable');
        //console.log('SAMA', 'ENABLED');
      }
    } catch (_e) {
      // Select Menu wasn't initialized yet. Oh well.
    }

    this.ClearLegSelect();
    this.ClearItinSelect();
  }

  ResetReviewerOnlyInputs() {
    if (
      this.report.GetPk() < 0 ||
      (this.permissions !== SAMA.enums.ACCESS.REVIEW_WITH_GRANT && this.permissions !== SAMA.enums.ACCESS.REVIEW)
    ) {
      this.dom.reviewerOnly.prop('disabled', true).parent().addClass('ui-state-disabled');
    } else {
      this.dom.reviewerOnly.prop('disabled', false).parent().removeClass('ui-state-disabled');
    }
  }

  ApplyItinLegEventHooks() {
    this.dom.itinSourceFieldset.on('change', () => this.TryGetItins());

    this.dom.itinDateFrom.on('change', () => this.TryGetItins());
    this.dom.itinDateTo.on('change', () => this.TryGetItins());
  }

  FillItinFields() {
    this.dom.itinSourceFieldset.val(this.report.sync.rep_itinsFromWB);

    this.TryGetItins(this.report.sync.rep_itinPk);
  }

  TryGetItins(_preset) {
    let dateFrom = this.dom.itinDateFrom.val();
    let dateTo = this.dom.itinDateTo.val();
    let source = +this.dom.itinSourceFieldset.val();

    if (source === 1) {
      this.dom.itinRepText.text('Itinerary:');
    } else {
      this.dom.itinRepText.text('Flight Report:');
    }

    let r = new cSAMA_Request('Itins', 'getItinList');
    if (_preset == null) {
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      this.ClearItinSelect();
      this.ClearLegSelect();
      if (dateFrom == null || dateFrom.length <= 0) {
        return;
      }

      dateFrom = moment(dateFrom, SAMA.defaults.DateInputFormat);
      dateFrom.hour(0).minute(0);

      if (!dateFrom.isValid()) {
        return;
      }

      if (dateTo == null || dateTo.length <= 0) {
        dateTo = dateFrom.clone();
        dateTo.hour(23).minute(59);
      } else {
        dateTo = moment(dateTo, SAMA.defaults.DateInputFormat);
        if (!dateTo.isValid()) {
          return;
        }
      }

      this.report.sync.rep_itinsFromWB = source;
      r.Set('dateFrom', dateFrom.format());
      r.Set('dateTo', dateTo.format());
      r.Set('itinsFromWB', source);
    } else {
      r.Set('itinPk', _preset);
      r.Set('itinsFromWB', this.report.sync.rep_itinsFromWB);
    }

    cSAMA_Request.Dispatch({
      name: 'UpdateItinList',
      reqs: r,
      clientErrorCode: 'SAMA EE 22 OE 29',
      goBackOnError: false,
      keepStateOnError: true,
      fnSuccess: (_r) => this.ReceiveItins(_r[0], _preset),
    });
  }

  static GetItinOptionString(_itin) {
    _itin.itn_details = cSAMA_Utils.EllipseString(_itin.itn_details, 60);

    if (_itin.hasOwnProperty('brp_client')) {
      // Got a Flight report with additional detail
      _itin.brp_client = _itin.brp_client == null ? 'Company' : _itin.brp_client;
      _itin.brp_jobName = _itin.brp_jobName == null ? 'Non-Revenue' : _itin.brp_jobName;

      _itin.brp_client = cSAMA_Utils.EllipseString(_itin.brp_client, 15);
      _itin.brp_jobName = cSAMA_Utils.EllipseString(_itin.brp_jobName, 15);

      _itin.itn_details += `&nbsp;&nbsp;&nbsp;(${_itin.brp_client})&nbsp;&nbsp;&nbsp;[${_itin.brp_jobName}]`;
    }

    return `<option value="${_itin.itn_primaryKey}">&nbsp;&nbsp;&nbsp;${_itin.itn_ident}&nbsp;&nbsp;&nbsp;${_itin.itn_details}&nbsp;&nbsp;</option>`;
  }

  ReceiveItins(_itins, _preset) {
    let optns = `<option value="0" checked>&nbsp;&nbsp;Not Applicable&nbsp;&nbsp;</option>`;

    for (let i = 0; i < _itins.length; i++) {
      optns += vSAMA_ReportsEditor.GetItinOptionString(_itins[i]);
    }

    this.dom.itinSelect.off('.samaevt').html(optns);

    if (_preset != null) {
      this.dom.itinSelect.val(_preset);

      this.TryGetLegs(_preset);
    }

    this.dom.itinSelect.trigger('change');

    if (!this.readOnly) {
      this.dom.itinSelect.selectmenu('enable').on('change.samaevt', () => this.TryGetLegs());
    }
  }

  TryGetLegs(_preset) {
    let source = +this.dom.itinSourceFieldset.val();

    this.report.sync.rep_itinPk = +this.dom.itinSelect.val();

    if (_preset == null) {
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    }

    if (this.report.sync.rep_itinPk === 0) {
      this.ClearLegSelect();
      return;
    }

    let r = new cSAMA_Request('Itins', 'getItinLegList');
    r.Set('itinPk', this.report.sync.rep_itinPk);
    r.Set('itinsFromWB', source);

    cSAMA_Request.Dispatch({
      name: 'UpdateLegList',
      reqs: r,
      clientErrorCode: 'SAMA DA 22 OE 29',
      goBackOnError: false,
      keepStateOnError: true,
      fnSuccess: (_r) => this.ReceiveItinLegs(_r[0], _preset),
    });
  }

  ClearLegSelect() {
    this.dom.legSelect.off('.samaevt').html('<option value="0">...</option>').selectmenu('disable').trigger('change');
  }

  ClearItinSelect() {
    this.dom.itinSelect.off('.samaevt').html('<option value="0">...</option>').selectmenu('disable').trigger('change');
  }

  static GetLegOptionString(_leg) {
    _leg.leg_desc = cSAMA_Utils.EllipseString(_leg.leg_desc, 30);

    _leg.leg_timeUp = cSAMA_Utils.NiceTimeFromMikeTime(_leg.leg_timeUp);
    _leg.leg_timeDown = cSAMA_Utils.NiceTimeFromMikeTime(_leg.leg_timeDown);

    return `<option value="${_leg.leg_primaryKey}">&nbsp;&nbsp;${_leg.leg_origin} [${_leg.leg_timeUp}] -> ${_leg.leg_destination} [${_leg.leg_timeDown}]&nbsp;&nbsp;&nbsp;${_leg.leg_desc}&nbsp;&nbsp;</option>`;
  }

  ReceiveItinLegs(_legs, _preset) {
    let optns = `<option value="0" checked>&nbsp;&nbsp;Not Applicable&nbsp;&nbsp;</option>`;

    for (let i = 0; i < _legs.length; i++) {
      optns += vSAMA_ReportsEditor.GetLegOptionString(_legs[i]);
    }

    this.dom.legSelect.off('.samaevt').html(optns);

    if (this.report.sync.rep_itinLegPk > 0) {
      this.dom.legSelect.val(this.report.sync.rep_itinLegPk);
      this.ApplyItinLegEventHooks();
    }

    this.dom.legSelect.trigger('change');

    if (!this.readOnly) {
      this.dom.legSelect
        .selectmenu('enable')
        .trigger('change')
        .on('change.samaevt', () => this.SelectedLeg());
    }
  }

  SelectedLeg(_preset) {
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    this.report.sync.rep_itinLegPk = this.dom.legSelect.val();
  }

  GuiSetUpGeneral() {
    cSAMA_GeneralInputs.Date(this.dom.page.find('input[type="date"]'), false, true);

    if (this.readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.report, this.dom.page);
      cSAMA_GeneralGui.BuildFileList(this.report.sync.rep_files, this.dom.fileList, 'reports');
      this.dom.fileList.show();
      this.dom.reportDocLoader.hide();
      this.dom.typeDropdown.hide();
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.report, this.dom.page, this);
      this.GuiDocumentLoader();
      this.dom.fileList.hide();
    }

    if(SAMA.settings.preferences.sync.prf_addCategoryTracking === false){
      this.dom.toggleCategory.hide();
    }else{
      this.dom.toggleCategory.show();
    }

    this.GuiPhotoUploader();
    cSAMA_GeneralInputs.Keyword(this.dom.keyWords);
    this.dom.adminOnly.hide();

    if (this.report.WasDownloaded()) {
      this.GuiBuildDepartmentsDropdown(this.report.sync.rep_depPk);
      this.GuiBuildCategoriesDropdown(this.report.sync.rep_catPk);
      this.GuiPrefillAdvancedData();
    } else {
      this.GuiBuildDepartmentsDropdown();
      this.GuiBuildCategoriesDropdown();
    }
  }

  AllowGrant() {
    this.dom.adminOnly.show();
    cSAMA_GeneralInputs.Staff(this.dom.staffAccess, this.readOnly);
  }

  /**
   * Set up the page based on the 'simple initial reports' setting
   */
  GuiSetUpSimple() {
    if (SAMA.settings.preferences.sync.prf_useSimpleDraft && this.report.isDraft) {
      this.dom.simpleHiders.hide();
    } else {
      this.dom.simpleHiders.show();
    }
  }

  /**
   * Set up the page for a proactive report
   */
  GuiSetUpForProactive() {
    if (SAMA.settings.preferences.sync.prf_allowAnonProactive) {
      this.dom.anonBox.show();
    } else {
      this.dom.anonBox.hide();
    }

    this.dom.reactiveElements.hide();
    this.dom.proactiveElements.show();
  }

  /**
   * Set up the page for a reactive report
   */
  GuiSetUpForReactive() {
    if (SAMA.settings.preferences.sync.prf_allowAnonReactive) {
      this.dom.anonBox.show();
    } else {
      this.dom.anonBox.hide();
    }

    this.dom.proactiveElements.hide();
    this.dom.reactiveElements.show();
    cSAMA_GeneralInputs.Time(this.dom.page.find('input[type="time"]'));
  }

  /**
   * Generates the Markup for the department selection
   * @private
   */
  GuiBuildDepartmentsDropdown(_preset) {
    let hasPreset = true;
    if (_preset == null) {
      hasPreset = false;
      _preset = 0;
    }

    // Remove all but the default option
    this.dom.departmentDropdown.find('option').prop('selected', true).not('.sama-keep').remove();

    // Select the preset option
    this.dom.departmentDropdown.val(_preset).attr('value', _preset).trigger('input').trigger('change');

    for (let di = 0; di < this.departments.length; di++) {
      let deptData = this.departments[di];
      // If the department has no head, everyone will get prompted to add their input. If it has no users, its
      // pointless.
      if (deptData.dep_personnel == null) {
        continue;
      }

      let d = new mSAMA_Department(deptData);
      let newOpt = $('<option />')
        .attr('value', d.sync.dep_primaryKey)
        .appendTo(this.dom.departmentDropdown)
        .text('"%", headed by %'.tr(d.sync.dep_name, d.sync.dep_managerName));

      let currentUserPk = +USER_PK;

      let userInDep = d.sync.dep_personnel.split(',').find((_e) => +_e === currentUserPk);

      if (userInDep != null || currentUserPk === +d.sync.dep_managerPk) {
        newOpt.text('Your department, "%", headed by %'.tr(d.sync.dep_name, d.sync.dep_managerName));

        if (currentUserPk === +d.sync.dep_managerPk || d.sync.dep_managerPk == null) {
          newOpt.text('Your own department, "%"'.tr(d.sync.dep_name));
        }

        if (!hasPreset) {
          // Select the department the user's in if there's no preset
          newOpt.prop('selected', true);

          this.dom.departmentDropdown
            .val(d.sync.dep_primaryKey)
            .attr('value', d.sync.dep_primaryKey)
            .trigger('input')
            .trigger('change');
        }
      }

      if (hasPreset && _preset === d.sync.dep_primaryKey) {
        // If there's a preset, select that
        newOpt.prop('selected', true);

        this.dom.departmentDropdown
          .val(d.sync.dep_primaryKey)
          .attr('value', d.sync.dep_primaryKey)
          .trigger('input')
          .trigger('change');
      }
    }
  }

  /**
   * Generates the Markup for the report category selection
   * @private
   */
  GuiBuildCategoriesDropdown(_preset) {
    let hasPreset = true;
    if (_preset == null) {
      hasPreset = false;
      _preset = 0;
    }

    // Remove all but the default option
    this.dom.categoryDropdown.find('option').prop('selected', true).not('.sama-keep').remove();

    // Select the preset option
    this.dom.categoryDropdown.val(_preset).attr('value', _preset).trigger('input').trigger('change');

    for (let di = 0; di < this.categories.length; di++) {
      let categoryData = this.categories[di];

      const category = new mSAMA_ReportCategory(categoryData);
      let newOpt = $('<option />')
          .attr('value', category.sync.rc_primaryKey)
          .appendTo(this.dom.categoryDropdown)
          .text('%'.tr(category.sync.rc_name));

      if (hasPreset && _preset === category.sync.rc_primaryKey) {
        // If there's a preset, select that
        newOpt.prop('selected', true);

        this.dom.categoryDropdown
            .val(category.sync.rc_primaryKey)
            .attr('value', category.sync.rc_primaryKey)
            .trigger('input')
            .trigger('change');
      }
    }
  }

  GuiDocumentLoader() {
    this.dom.reportDocLoader
      .fileloader({
        readonly: this.readOnly,
        endpoint: `${cSAMA_Utils.GetAjaxPage()}?do=download&action=reports&id=`,
        validation: {
          maxFiles: 10,
          maxFileSize: 30,
        },
      })
      .on('change fileDeleted', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        this.report.SetModified(true);
      });

    for (let f = 0; f < this.report.sync.rep_files.length; f++) {
      this.dom.reportDocLoader.fileloader('preload', this.report.sync.rep_files[f]);
    }
  }

  /**
   * Builds the Uploader for photos of the incident / hazard
   * @private
   */
  GuiPhotoUploader() {
    let numPix = 0;
    let numPixLoaded = 0;

    this.dom.photoUploader
      .galLoader({
        descriptionValidator: (_text, _wrapper, _input, _id) =>
          this.ValidatePictureDescription(_text, _wrapper, _input, _id),
        allowDescriptions: true,
      })
      .on('change imageDeleted', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      })
      .on('beginLoad.galLoader', (_e) => {
        numPix = _e.numPics;
        this.picLoadLock = SAMA.GeneralGui.LockPageState('GalLoader.Load');
      })
      .on('doneLoad.galLoader', () => {
        if (this.picLoadLock == null) {
          return;
        }

        numPixLoaded++;
        if (numPixLoaded >= numPix) {
          SAMA.GeneralGui.UnlockPageState(this.picLoadLock);
        }
      });
  }

  ValidatePictureDescription(_text, _wrapper, _input, _id) {
    if (_text === false) {
      this.DescriptionValidator.Unvalidate(null, 'photoDescription' + _id);
      _wrapper.removeClass('sama-form-error');
      return;
    }

    let valid = this.DescriptionValidator.Validate(_text, _input, 'photoDescription' + _id);

    if (valid === false) {
      _wrapper.addClass('sama-form-error');
    } else {
      _wrapper.removeClass('sama-form-error');
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  /**
   * Prefills photos and consequence tabs when the report's been downloaded
   * @private
   */
  GuiPrefillAdvancedData() {
    let photos = this.report.GetPhotos();

    for (let p = 0; p < photos.length; p++) {
      this.dom.photoUploader.galLoader('preload', photos[p]);
    }
  }

  /**
   * Set up the page for viewing
   * @public
   */
  GuiSetUpForView() {
    this.ToggleAnonInput(false);

    // Nothing here yet?
    this.dom.page
      .find('.sama-content')
      .find('input, select, textarea, button, .ui-select')
      .not('.sama-allowRO, .cttr-input, .gallup-overlay-buttons > button')
      .addClass('sama-state-disabled')
      .prop('disabled', true);

    this.dom.keyWords.catTagger('enable', false);

    this.dom.photoUploader.galLoader('enable', false);
    this.dom.typeDropdown.hide();
  }

  /**
   * Set up the page for reviewing
   * @public
   */
  GuiSetUpForReview() {
    this.ToggleAnonInput(false);
  }

  /**
   * Set up the page for editing
   * @public
   */
  GuiSetUpForEdit() {
    this.ToggleAnonInput(true);
    $('.sama-admin-correctiveAction').hide();
  }

  /**
   * Sets the instruction string from policy
   * @param {number} _riskScore
   * @public
   */
  UpdateInstructions(_riskScore) {
    let bounds = this.riskMatrix.GetBounds();
    let highestScoreDisplay = Math.ceil(_riskScore);

    this.report.sync.rep_riskScoreInitial = _riskScore;

    if (highestScoreDisplay >= bounds.high) {
      this.dom.instructionLine
        .text(SAMA.settings.policyObject.sync.pol_instrHighRisk)
        .removeClass('sama-low sama-medium')
        .addClass('sama-high');
    } else if (highestScoreDisplay >= bounds.medium) {
      this.dom.instructionLine
        .text(SAMA.settings.policyObject.sync.pol_instrMedRisk)
        .removeClass('sama-high sama-low')
        .addClass('sama-medium');
    } else {
      this.dom.instructionLine
        .text(SAMA.settings.policyObject.sync.pol_instrLowRisk)
        .removeClass('sama-high sama-medium')
        .addClass('sama-low');
    }
  }

  UpdateReportRisk() {
    let relevantCons = this.report.RecalculateRiskScore();

    let rf = {
      risk: 0,
      frequency: 0,
    };

    if (relevantCons != null) {
      rf = {
        risk: relevantCons.GetRiskOnly(),
        frequency: relevantCons.GetFrequencyOnly(),
      };
    }

    this.UpdateInstructions(this.report.GetRiskScore());
    this.UpdateObservers('RiskChange', rf);
  }

  /**
   * Sets the department dropdown to the given value
   * @param _depPk
   * @public
   */
  SetDepartment(_depPk) {
    this.dom.departmentDropdown.val(_depPk).attr('value', _depPk).trigger('input').trigger('change');
  }

  /**
   * Synchronizes remote keys
   * @param {object} _keywords
   * @param {object} _photos
   * @param {object} _files
   * @public
   */
  SetRemoteKeys(_keywords, _photos, _files) {
    this.dom.keyWords.catTagger('updateRemoteKeys', _keywords);

    if (_photos !== false) {
      this.dom.photoUploader.galLoader('updateUID', _photos);
    }

    if (_files !== false) {
      this.dom.reportDocLoader.fileloader('updateUID', _files);
    }
  }

  /**
   * Gets prepared photos
   * @returns {array}
   * @public
   */
  GetSelectedPhotos() {
    return this.dom.photoUploader.galLoader('getFormEncoded', true);
  }

  GetSelectedFiles() {
    return this.dom.reportDocLoader.fileloader('getFiles');
  }

  /**
   * Gets selected keywords
   * @returns {array}
   * @public
   */
  GetSelectedKeywords() {
    return this.dom.keyWords.catTagger('getSelectedTags')[0];
  }

  /**
   * Gets the selected department PK
   * @returns {number}
   * @public
   */
  GetSelectedDepartment() {
    return +this.dom.departmentDropdown.val();
  }

  ToggleAnonInput(_enabled) {
    this.dom.anonCheck.prop('disabled', !_enabled);
    try {
      if (_enabled === true) {
        this.dom.anonCheck.checkboxradio('enable');
      } else {
        this.dom.anonCheck.checkboxradio('disable');
      }
    } catch (_e) {
      // Papa don't preach
    }
  }

  /**
   * Removes enhancements from DOM
   * @public
   */
  Destroy() {
    super.Destroy();
    this.ToggleAnonInput(true);

    if (this.readOnly) {
      // Re-enable inputs
      this.dom.page
        .find('.sama-content')
        .find('.ui-state-disabled')
        .add('.sama-state-disabled')
        .not('.sama-allowRO')
        .removeClass('ui-state-disabled sama-state-disabled')
        .prop('disabled', false);
    }

    this.dom.reactiveElements.hide();
    this.dom.proactiveElements.hide();

    if (this.dom.photoUploader.data('jq-galLoader') != null) {
      this.dom.photoUploader.off().galLoader('destroy').val('');
    }

    if (this.dom.reportDocLoader.data('jq-fileloader') != null) {
      this.dom.reportDocLoader.off().fileloader('destroy').val('');
    }

    if (this.dom.keyWords.data('catTagger-plugin') != null) {
      this.dom.keyWords.off().catTagger('destroy');
    }

    if (this.dom.staffAccess.data('catTagger-plugin') != null) {
      this.dom.staffAccess.off().catTagger('destroy');
    }
  }

  GetDom() {
    return this.dom.page;
  }

  updateReportType(newType) {
    if (this.oldReportType == null) {
      this.oldReportType = this.report.sync.rep_isProactive;
    }

    this.report.sync.rep_isProactive = newType.currentTarget.value > 0;

    if (this.oldReportType !== this.report.sync.rep_isProactive) {
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    }

    this.UpdateObservers('TypeChange');
  }
}
