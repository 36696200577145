/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

var AutoCheck = false;
var CurrentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
var CurrentTimeZoneLBL = function login_localStorageCheck() {
  if (LOCALSTORAGE.username != null && LOCALSTORAGE.username.length > 0) {
    if (GOTLOCALSTORAGE === false) {
      getLocalStorage();
      AutoCheck = false;
      return;
    }

    gotLocalStorage();
  }
};

async function Login(fromAuto) {
  vueApp.sentry.captureException(new Error('Login function is deprecated as of 24.26.9'));
  verbose.log('DEBUG', 'Logging In');

  const auto = fromAuto || false;
  const onLoginPage = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id === 'Login_Page';
  const dom_loginButton = $('#loginBTN');
  const dom_loginUserName = $('#login_username');
  const dom_loginPassword = $('#login_password');
  const dom_loginText = $('#LoginText');
  const dom_loginPage = $('#Login_Page');

  // disabled the login button and give the user some feedback
  dom_loginButton.prop('disabled', true);
  dom_loginText.html(iTrans('Logging In...'));

  // pseudo encrypt the user and pass over the wire
  let loginUsername = window.btoa(dom_loginUserName.val());
  let password = window.btoa(dom_loginPassword.val());
  if (!auto) {
    dom_loginUserName.textinput('disable');
    dom_loginPassword.textinput('disable');
  } else {
    if (CORDOVA) {
      loginUsername = LOCALSTORAGE.username;
      password = LOCALSTORAGE.password;
    }
  }

  if (!ONLINE) {
    dom_loginText.html(iTrans('Internet Connection Not Detected. Please try again when you are online'));
    dom_loginButton.prop('disabled', false);
    dom_loginUserName.textinput('enable');
    dom_loginPassword.textinput('enable');

    if (auto) {
      login_localStorageCheck();
    }

    return;
  }

  let responseData = null;
  //Enable the loading spinner
  $.mobile.loading('show', {
    theme: 'a',
  });
  try {
    responseData = await RemoteData.post(
      'api/v2/users/me/sign-in?legacy&no-array',
      { username: loginUsername, password: password },
      30000
    );
  } catch (message) {
    verbose.error('net', { message });

    let netObject = null;
    if ('netObject' in message) {
      netObject = message.netObject;

      if (netObject.statusText === 'timeout') {
        message =
          'Looks like the server is taking too long to respond. This can be caused by either poor connectivity or an error with our servers. Please check your internet connection and try again.';
      } else if (netObject.hasOwnProperty('responseJSON') && netObject.responseJSON != null) {
        message = netObject.responseJSON.message || netObject.responseJSON.errors.message;
      } else {
        message = 'There was a connection error, sorry.';
      }
    }

    if (message instanceof Error) {
      message = message.message;
    }

    dom_loginText.html(`${message || ''}`);
    dom_loginButton.prop('disabled', false);

    if (onLoginPage) {
      if (LOCALSTORAGE.username == null || LOCALSTORAGE.username === '') {
        dom_loginUserName.textinput('enable');
      }
      dom_loginPassword.textinput('enable');
    }

    if (netObject.status === 403) {
      return;
    }

    if (auto) {
      login_localStorageCheck();
      return;
    }

    AutoCheck = false;

    $.mobile.loading('hide');
    $('<div>').simpledialog2({
      themeDialog: 'a',
      mode: 'button',
      animate: false,
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt: message,
      buttons: {
        [iTrans('OK')]: {
          click: function () {},
          id: 'sd2-ok-btn',
        },
      },
      callbackOpen: function () {
        // auto focus ok on open
        $('#sd2-ok-btn').focus();
      },
      callbackClose: function () {
        // focus back on password on close
        $('#login_password').focus();
      },
    });
    return;
  }
  //Remove the spinner
  $.mobile.loading('hide');
  const serverResponse = responseData.ServerResponse;
  dom_loginButton.prop('disabled', false);

  let buttonCallback = () => {};
  let buttonPrompt = iTrans('The credentials used were invalid. Please try again.');
  let humanReadableResponse = serverResponse || '';

  AutoCheck = false;
  ONLINE = true;
  switch (serverResponse) {
    case 'Offline':
      OfflineTriggered('menu-auth offline response');
      if (auto) {
        login_localStorageCheck();
        return;
      }
      ServerResponse('Connection Error', iTrans('Unable to Login'));
      return;
    case 'Authenticated':
      if (IOS || ANDROID) {
        CELLDATA = navigator.connection.type !== 'wifi';
        if (responseData.GROUP_DATA.Lite > 0) {
          CheckSubscription();
        }
      } else {
        CELLDATA = false;
      }

      dom_loginText.html(responseData.ServerResponse);
      localStorageDB.setItem('username', loginUsername);
      localStorageDB.setItem('password', password);
      if (LOCALSTORAGE.GROUP_DATA != null && LOCALSTORAGE.GROUP_DATA.group !== responseData.GROUP_DATA.group) {
        //different group than local data that exists on device.  throw dialog and force complete logout.
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt:
            '<center>' +
            iTrans('You are logging into a different group account than the previous user of this device') +
            '.  ' +
            iTrans('The existing data will be removed now') +
            '.  ' +
            iTrans('Please try logging in again') +
            '.</center>',
          buttons: {
            [iTrans('Logout')]: {
              click: function () {
                Logout('RemoveProfile'); //menu-Authentication.js
              },
            },
          },
        });
        return;
      }

      if (onLoginPage) {
        dom_loginPassword.textinput('enable');
      }

      SetAirportIndexCurVersion(responseData.AirportIndexVersion);
      setLocalStorage(responseData);
      return;
    case 'Pending-Reset':
      if (responseData.RESETURL) {
        buttonCallback = () => {
          openExternal(responseData.RESETURL);
          dom_loginText.html(iTrans('Login'));
          dom_loginPassword.val('');
        };
      }

      buttonPrompt = iTrans(
        'An administrator requires that you change your password. You will not be able to sign in until you do.'
      );

      humanReadableResponse = iTrans('Please change your password.');
      break;
  }

  dom_loginText.html(humanReadableResponse);

  verbose.log('DEBUG', 'Switching to Login Page');
  VueBridge.enterVue('/login');

  if (onLoginPage) {
    if (LOCALSTORAGE.username == null || LOCALSTORAGE.username === '') {
      dom_loginUserName.textinput('enable');
    }
    dom_loginPassword.textinput('enable');

    // don't toss an invalid dialog if it's actually valid and just locked.
    if (serverResponse === 'Account Locked. Please contact an administrator.') {
      return;
    }
  }

  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('ERROR'),
    headerClose: false,
    buttonPrompt,
    buttons: {
      [iTrans('OK')]: {
        click: function () {
          buttonCallback();
          dom_loginPassword.textinput('enable');
          dom_loginUserName.textinput('enable');
        },
        id: 'sd2-ok-btn',
      },
    },
    callbackOpen: function () {
      // auto focus ok on open
      $('#sd2-ok-btn').focus();
    },
    callbackClose: function () {
      // focus back on password on close
      $('#login_password').focus();
    },
  });
}

async function AutoLogin() {
  verbose.log('DEBUG', 'Logging In Automatically');

  let username = LOCALSTORAGE.username;

  const dom_initDialog = $('#initDialog');

  dom_initDialog.html('Attempting Automatic Login...');

  if (!ONLINE) {
    AutoCheck = false;
    dom_initDialog.html(iTrans('Please Wait...'));

    if (!blank(username)) {
      VueBridge.updateMenu('NOT ONLINE AUTOLOGIN');
      gotLocalStorage();
    } else {
      dom_initDialog.html(iTrans("Internet Connection Not Detected.<br>Please try again when you're online"));
    }
  }

  if (blank(username)) {
    verbose.log('DEBUG', 'Switching to Login Page because the username could not be restored', LOCALSTORAGE);
    VueBridge.enterVue('/login');
    return;
  }

  let responseData = null;
  try {
    responseData = await RemoteData.post('api/v2/users/me/legacy', {}, 30000);
  } catch (e) {
    if (e instanceof Error) {
      e = e.message;
    }
    verbose.error('Vue', 'me/legacy error - hasLocalStorage?', GOTLOCALSTORAGE);
    AutoCheck = false;
    if (!blank(username)) {
      e = e.message;
      //If the username and password is saved then call gotLocalStorage() to trigger offline login mode
      //It will load all the local GROUP_DATA and USER_RIGHTS, settings etc that is usually supplied from the login returned data
      gotLocalStorage();
      // Trigger offline here
      //Moved here because doesn't need to be triggered if there was no user logged into the device.
      OfflineTriggered(`me/legacy error ${e}`);
      return;
    }

    VueBridge.enterVue('/login');

    return;
  }

  const serverResponse = responseData.ServerResponse;
  ONLINE = true;
  AutoCheck = false;

  switch (serverResponse) {
    case 'Offline':
      OfflineTriggered('me/legacy offline response');
      gotLocalStorage();
      return;

    case 'Authenticated':
      verbose.log('DEBUG', 'AutoLogin Succeeded');
      CELLDATA = false;
      if (IOS || ANDROID) {
        CELLDATA = navigator.connection.type !== 'wifi';
        if (responseData.hasOwnProperty('GROUP_DATA') && responseData.GROUP_DATA.Lite > 0) {
          CheckSubscription();
        }
      }

      storeSessionData(responseData);
      window.dispatchEvent(new Event('cirro-vue-register-globals'));
      return;
  }

  verbose.log('DEBUG', 'Switching to Login Page');
  VueBridge.enterVue('/login');
}

function storeSessionData(responseData) {
  SetAirportIndexCurVersion(responseData.AirportIndexVersion);
  setLocalStorage(responseData);
}
