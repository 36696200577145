var CURRENT_QUOTE;
var CURRENT_QUOTING_FIELD;
var CURRNET_QUOTE_CLIENT_DATA;
var QUOTING_FIELDS = [];
var QUOTE_AIRCRAFTLIST = [];
var EXISTING_QUOTE_DATA = [];
var QUOTE_CURRENT_LEG;

function BILLING_InitQuoting() {
  ITIN_Pax_Manifest.Scheduler = false;
  ITIN_Pax_Manifest.Quote = true;
  ITIN_Pax_Manifest.FlightReport = false;
  if (GROUP_DATA.Scheduler == 0) {
    $('#BTNQuotetoScheduler').hide();
  }
  $.mobile.changePage($('#BILLING_QuotePage'), {
    changeHash: false,
    reverse: false,
  });
  BILLING_refreshQuotingFields();
  var val = $('input[name=BILLING_QUOTESTATUS]:checked').val();
  BILLING_ShowQuotes(val);
}

function ExchangeQuoteData(action, data, callback, silent = false) {
  if (!silent) {
    $.mobile.loading('show', {
      theme: 'a',
    });
  }

  var json = JSON.stringify(data);
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=' + action,
    data: { data: json },
  })
    .success(function (json_data) {
      $.mobile.loading('hide');
      if (json_data.length == 1) {
        if (json_data[0].ServerResponse != undefined) {
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Failed!'),
              headerClose: false,
              buttonPrompt:
                iTrans('There was a problem sending quote data to the server ERROR') + ': ' + json_data[0].Exception,
              buttons: {
                [iTrans('Close')]: {
                  click: function () {},
                },
              },
            });
            return;
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
          if (json_data[0].ServerResponse == 'Offline') {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Offline'),
              headerClose: false,
              buttonPrompt: iTrans('You have lost your internet connection.  Please try again when you are connected'),
              buttons: {
                [iTrans('Close')]: {
                  click: function () {},
                },
              },
            });
            return;
          }
        }
      }

      if (callback != null) {
        callback(json_data);
      }
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem sending quote data to the server.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_updateshowUnreportedQuotes() {
  var FromDate = new Date($('#RPT_FR_FromDate').val());
  var ToDate = new Date($('#RPT_FR_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate.setUTCDate(ToDate.getUTCDate() + 1);
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  var Qnum = $('#BILLING_SearchFRNum').val();
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'menu-Billing-AJAX.php?action=getFRQuotes&Start=' +
        FromDate +
        '&End=' +
        ToDate +
        '&Qnum=' +
        Qnum +
        '&type=approved',
      function (json_data) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
          }
          if (json_data[0].ServerResponse == 'NoItems') {
            $('#BILLING_FLIGHTREPORTS').html('<h3>' + iTrans('No Quotes to show') + '</h3>');
            $('#BILLING_FLIGHTREPORTS').enhanceWithin();
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            ServerResponse(json_data[0], 'Billing_ShowQuotes');
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
        } else {
          BILLING_displayFR_Quote('approved', json_data);
        }
      }
    ).fail(function (jqXHR, status, error) {
      //AJAXFAIL(error);
      $('#BILLING_FLIGHTREPORTS').html(
        '<h3>' + iTrans('There was an error Retrieving quote data.') + ' ' + iTrans('Please try again later') + '</h3>'
      );
      $('#BILLING_FLIGHTREPORTS').enhanceWithin();
    });
  } else {
    $('#BILLING_FLIGHTREPORTS').html('<h3>' + iTrans('OFFLINE - Quoting module is only available online.') + '</h3>');
    $('#BILLING_FLIGHTREPORTS').enhanceWithin();
  }
}
function BILLING_displayFR_Quote(type, data) {
  var html = '';

  html +=
    '<table id="BIT_Quoting1" class="limit-table wrap" data-filter="true"><thead><tr><th>ID</th><th>' +
    iTrans('Date') +
    '</th><th>' +
    iTrans('Client') +
    '</th><th>' +
    iTrans('Job Name') +
    '</th><th>' +
    iTrans('Modified') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  var showall = false;
  if ($('#BILLING_SearchFRNum').val() != '') {
    showall = true;
  }
  for (var i in data) {
    var C = data[i];
    var actions = '';
    if (showall) {
      $('#QuotingFilterRadio').hide();
      if (C.DELETED == 1) {
        type = 'deleted';
      } else if (C.Invoiced == 1) {
        type = 'invoiced';
      } else if (C.Completed == 1) {
        type = 'completed';
      } else if (C.Approved == 1) {
        type = 'approved';
      } else {
        type = 'pending';
      }
    } else {
      $('#QuotingFilterRadio').show();
    }
    var trclass = '';
    if (C.Status == 'ClientApproved') {
      trclass = 'greenbg';
    }
    if (C.Status == 'ClientNote') {
      trclass = 'yellowbg';
    }
    if (C.Status == 'Declined') {
      trclass = 'redbg';
    }
    var actions =
      "<div class='vertical-center-container'><button onClick='BILLING_ReportQuote(" +
      i +
      ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button></div>";
    switch (type) {
      case 'pending':
        actions = '<h3>' + iTrans('Pending') + '</h3>';
        break;
      case 'approved':
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_ReportQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button></div>";
        break;
      case 'completed':
        actions = '<h3>' + iTrans('Completed') + '</h3>';
        break;
      case 'invoiced':
        actions = '<h3>' + iTrans('Invoiced') + '</h3>';
        break;
      case 'deleted':
        actions = '<h3>' + iTrans('DELETED') + '</h3>';
        break;
    }

    html +=
      '<tr class="' +
      trclass +
      '"><td>' +
      C.PrimaryKey +
      '</td><td>' +
      C.Date +
      '</td><td>' +
      C.Client_Text +
      '</td><td>' +
      C.Job_Text +
      '</td><td>' +
      C.Modified +
      '</td><td>' +
      actions +
      '</td></tr>';
  }
  html += '</tbody></table>';

  $('#BILLING_FLIGHTREPORTS').html(html);
  $('#BILLING_FLIGHTREPORTS').enhanceWithin();
  EXISTING_QUOTE_DATA = data;
  AddTableSorter('BIT_Quoting1');
}
function BILLING_ReportQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  BILLING_QuoteToFlightReport();
}
function BILLING_SearchQuotes() {
  var val = $('input[name=BILLING_QUOTESTATUS]:checked').val();
  BILLING_ShowQuotes(val);
}

function BILLING_ShowQuotes(e) {
  var FromDate = new Date($('#QUOTE_FR_FromDate').val());
  var ToDate = new Date($('#QUOTE_FR_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate.setUTCDate(ToDate.getUTCDate() + 1);
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  var Qnum = $('#BILLING_SearchQuoteNum').val();
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'menu-Billing-AJAX.php?action=getQuotes&Start=' +
        FromDate +
        '&End=' +
        ToDate +
        '&Qnum=' +
        Qnum +
        '&type=' +
        e,
      function (json_data) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
          }
          if (json_data[0].ServerResponse == 'NoItems') {
            $('#BILLING_QUOTERESULTS').html('<h2>' + iTrans('No Quotes to show') + '</h2>');
            $('#BILLING_QUOTERESULTS').enhanceWithin();
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            ServerResponse(json_data[0], 'Billing_ShowQuotes');
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
        } else {
          BILLING_displayQuoteStatus(e, json_data);
        }
      }
    ).fail(function (jqXHR, status, error) {
      //AJAXFAIL(error);
      $('#BILLING_QUOTERESULTS').html(
        '<h1>' + iTrans('There was an error Retrieving quote data.') + ' ' + iTrans('Please try again later') + '</h1>'
      );
      $('#BILLING_QUOTERESULTS').enhanceWithin();
    });
  } else {
    $('#BILLING_QUOTERESULTS').html(
      '<h1>' + iTrans('Error: OFFLINE - Quoting module is only available online.') + '</h1>'
    );
    $('#BILLING_QUOTERESULTS').enhanceWithin();
  }
}

function BILLING_clear_quote_filter_text() {
  $('#BILLING_SearchQuoteNum').val('');
  $('#QuotingFilterRadio').show();
  BILLING_SearchQuotes();
}

function BILLING_displayQuoteStatus(type, data) {
  var html = '';

  html +=
    '<table id="BIT_Quoting1" class="limit-table wrap" data-filter="true"><thead><tr><th>ID</th><th>' +
    iTrans('Date') +
    '</th><th>' +
    iTrans('Client') +
    '</th><th>' +
    iTrans('Job Name') +
    '</th><th>' +
    iTrans('Modified') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  var showall = false;
  if ($('#BILLING_SearchQuoteNum').val() != '') {
    showall = true;
  }
  for (var i in data) {
    var C = data[i];
    var actions = '';
    if (showall) {
      $('#QuotingFilterRadio').hide();
      if (C.DELETED == 1) {
        type = 'deleted';
      } else if (C.Invoiced == 1) {
        type = 'invoiced';
      } else if (C.Completed == 1) {
        type = 'completed';
      } else if (C.Approved == 1) {
        type = 'approved';
      } else {
        type = 'pending';
      }
    } else {
      $('#QuotingFilterRadio').show();
    }
    var trclass = '';
    if (C.Status == 'ClientApproved') {
      trclass = 'greenbg';
    }
    if (C.Status == 'ClientNote') {
      trclass = 'yellowbg';
    }
    if (C.Status == 'Declined') {
      trclass = 'redbg';
    }
    switch (type) {
      case 'pending':
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CopyQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_ViewFinalizeQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_editQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_deleteQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";

        break;
      case 'approved':
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CopyQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_viewQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_completeQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button><button onClick='BILLING_deleteQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        break;
      case 'completed':
        if (C.JobPK == 0) {
          actions =
            "<div class='vertical-center-container'><button onClick='BILLING_CopyQuote(" +
            i +
            ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_viewQuote(" +
            i +
            ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_invoiceQuote(" +
            i +
            ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
        } else {
          actions =
            "<div class='vertical-center-container'><button onClick='BILLING_CopyQuote(" +
            i +
            ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_viewQuote(" +
            i +
            ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg'/></button><button onClick='BILLING_invoiceQuote(" +
            i +
            ")' data-mini='true' class='action-btn' disabled><img src='./images/Check_mark_green.svg' /></button></div>";
        }
        break;
      case 'invoiced':
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CopyQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_viewQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
        break;
      case 'deleted':
        actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CopyQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/copyicon.png' width='20px' height='20px' /></button><button onClick='BILLING_viewQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_restoreQuote(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/restore.png' width='20' height='20' /></button></div>";
        break;
    }

    const dateTimeFormat = `${VueBridge.dateTimeUtils.formattableDateFormats[0]} ${VueBridge.dateTimeUtils.timeFormat}`;

    html +=
      '<tr class="' +
      trclass +
      '"><td>' +
      C.PrimaryKey +
      '</td><td>' +
      C.Date +
      '</td><td>' +
      C.Client_Text +
      '</td><td>' +
      C.Job_Text +
      '</td><td>' +
      VueBridge.dateTimeUtils.convertUtcToUsersTimezone(C.Modified)?.format(dateTimeFormat) +
      '</td><td>' +
      actions +
      '</td></tr>';
  }
  html += '</tbody></table>';

  $('#BILLING_QUOTERESULTS').html(html);
  $('#BILLING_QUOTERESULTS').enhanceWithin();
  EXISTING_QUOTE_DATA = data;
  AddTableSorter('BIT_Quoting1');
}

function BILLING_CopyQuote(index) {
  NewQuote = JSON.parse(JSON.stringify(EXISTING_QUOTE_DATA[index]));

  var options =
    '<label for="COPYQUOTE_AIRCRAFT">' +
    iTrans('Aircraft') +
    '</label><input type="checkbox" id="COPYQUOTE_AIRCRAFT" checked />';
  options +=
    '<label for="COPYQUOTE_LEGS">' +
    iTrans('Trip Legs') +
    '</label><input type="checkbox" id="COPYQUOTE_LEGS" checked />';
  options +=
    '<label for="COPYQUOTE_QUOTEITEMS">' +
    iTrans('Quote Fields') +
    '</label><input type="checkbox" id="COPYQUOTE_QUOTEITEMS" checked />';
  options +=
    '<label for="COPYQUOTE_LEGITEMS">' +
    iTrans('Leg Fields') +
    '</label><input type="checkbox" id="COPYQUOTE_LEGITEMS" checked />';
  options +=
    '<label for="COPYQUOTE_CLIENTINFO">' +
    iTrans('Client Info') +
    '</label><input type="checkbox" id="COPYQUOTE_CLIENTINFO" checked />';
  options +=
    '<label for="COPYQUOTE_PAXMAN">' +
    iTrans('Passenger Manifests') +
    '</label><input type="checkbox" id="COPYQUOTE_PAXMAN" checked />';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Copy Quote'),
    headerClose: false,
    buttonPrompt: iTrans('Please select items to copy from quote ID') + ': ' + NewQuote.PrimaryKey + options,
    buttons: {
      [iTrans('Copy Quote')]: {
        click: function () {
          //do nothing
          var date = new Date();
          date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
          var datestring = date.toISOString().substr(0, 10);
          date.setDate(date.getDate() + 30);
          var expirestring = date.toISOString().substr(0, 10);
          NewQuote.PrimaryKey = 0;
          NewQuote.Date = datestring;
          NewQuote.Expires = expirestring;
          NewQuote.AcceptedDateTime = '0001-01-01 00:00:00';
          NewQuote.signed_by = '';
          NewQuote.signature = null;
          NewQuote.ClientNotes = null;
          NewQuote.Terms = null;
          NewQuote.Locked = 0;
          NewQuote.Approved = 0;
          NewQuote.Completed = 0;
          NewQuote.Invoiced = 0;
          NewQuote.Modified = dateToStringObject(new Date());
          NewQuote.ModBy = 0;
          NewQuote.HASH = '';
          NewQuote.Status = 'New Quote';
          if (!$('#COPYQUOTE_AIRCRAFT').is(':checked')) {
            NewQuote.Aircraft = [];
          }
          var KeepLegCfields = true;
          if (!$('#COPYQUOTE_LEGITEMS').is(':checked')) {
            KeepLegCfields = false;
          }
          var KeepLegs = true;
          if (!$('#COPYQUOTE_LEGS').is(':checked')) {
            NewQuote.CommonLegs = [];
            KeepLegs = false;
          }
          for (var i in NewQuote.Aircraft) {
            var A = NewQuote.Aircraft[i];
            if (KeepLegs == false) {
              A.Legs = [];
            }
            if (KeepLegCfields == false) {
              for (var l in A.Legs) {
                A.Legs[l].Cfields = [];
              }
            }
          }
          if (!$('#COPYQUOTE_QUOTEITEMS').is(':checked')) {
            NewQuote.Cfields = [];
          }
          if (!$('#COPYQUOTE_CLIENTINFO').is(':checked')) {
            NewQuote.ClientPK = 0;
            NewQuote.JobPK = 0;
            NewQuote.Client_Text = '';
            NewQuote.Job_Text = '';
            NewQuote.ContactName = '';
            NewQuote.AddressedTo = '';
            NewQuote.QuoteEmail = '';
            NewQuote.QuotePhone = '';
            NewQuote.Notes = '';
          }
          NewQuote.CopyPaxMan = 1;
          if (!$('#COPYQUOTE_PAXMAN').is(':checked')) {
            NewQuote.CopyPaxMan = 0;
          }
          $('#BILLING_QuotePending').prop('checked', true).checkboxradio('refresh');
          $('#BILLING_QuoteApproved').prop('checked', false).checkboxradio('refresh');
          $('#BILLING_QuoteBilled').prop('checked', false).checkboxradio('refresh');
          $('#BILLING_QuoteInvoiced').prop('checked', false).checkboxradio('refresh');
          $('#BILLING_QuoteDeleted').prop('checked', false).checkboxradio('refresh');
          ExchangeQuoteData('copyQuote', NewQuote, BILLING_SearchQuotes);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_editQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  $.mobile.changePage($('#BILLING_NewQuotePage'), {
    changeHash: false,
    reverse: false,
  });
  BILLING_Init_NewQuote();
}

function BILLING_deleteQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('Are you sure you wish to delete quote') +
      ' ID: ' +
      CURRENT_QUOTE.PrimaryKey +
      '?</h3></center>',
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          //do nothing
          ExchangeQuoteData('deleteQuote', CURRENT_QUOTE, BILLING_QuoteList_return);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_restoreQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('Are you sure you would like to restore Quote') +
      ' ID: ' +
      CURRENT_QUOTE.PrimaryKey +
      '</center>',
    buttons: {
      [iTrans('Restore')]: {
        click: function () {
          //do nothing
          ExchangeQuoteData('restoreQuote', CURRENT_QUOTE, BILLING_QuoteList_return);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_QuoteList_returnPROMPT() {
  if (CURRENT_QUOTE.Scheduler) {
    SCH_QuoteLegsComplete();
    return;
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      '<center>' + iTrans('Your changes will not be saved.') + ' <h3>' + iTrans('Are you Sure?') + '</h3></center>',
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          BILLING_QuoteList_return();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_QuoteList_return() {
  $.mobile.changePage($('#BILLING_QuotePage'), {
    changeHash: false,
    reverse: true,
  });
  var val = $('input[name=BILLING_QUOTESTATUS]:checked').val();
  BILLING_ShowQuotes(val);
}

function BILLING_Reset_CURRENT_QUOTE() {
  var date = new Date();
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  var datestring = date.toISOString().substr(0, 10);
  date.setDate(date.getDate() + 30);
  var expirestring = date.toISOString().substr(0, 10);
  CURRENT_QUOTE = {
    PrimaryKey: 0,
    Scheduler: false,
    Date: datestring,
    Expires: expirestring,
    ClientPK: 0,
    JobPK: 0,
    Client_Text: '',
    StartTimezone: CurrentTimeZone,
    EndTimezone: CurrentTimeZone,
    Job_Text: '',
    ContactName: '',
    AddressedTo: '',
    QuoteEmail: '',
    QuotePhone: '',
    AcceptedDateTime: '0001-01-01 00:00:00',
    signed_by: '',
    signature: null,
    Cfields: [],
    Cfields2: [],
    CommonLegs: [],
    Notes: '',
    ClientNotes: null,
    Terms: null,
    Status: 'New Quote',
    Locked: 0,
    Approved: 0,
    Completed: 0,
    Invoiced: 0,
    Modified: dateToStringObject(new Date()),
    ModBy: 0,
    HASH: '',
    DELETED: 0,
    Aircraft: [],
    Pax_Manifest: {
      DefaultList: [],
      Pax_Legs: [],
      CurLeg: 0,
      HasVIP: 0,
      Editing: true,
      scrollTo: 0,
      ClientPK: 0,
    },
  };
}

function BILLING_AdminNewQuote() {
  $.mobile.changePage($('#BILLING_NewQuotePage'), {
    changeHash: false,
    reverse: false,
  });

  BILLING_Reset_CURRENT_QUOTE();
  BILLING_initNewQuoteCfields(0); //per leg is == 0
  CURRNET_QUOTE_CLIENT_DATA = undefined;
  BILLING_Init_NewQuote();
}

function BILLING_Init_NewQuote() {
  var Q = CURRENT_QUOTE;
  makeTimezoneInput('BMQ_StartTimezoneHLDR', 'BMQ_StartTimezone', iTrans('Start Timezone'), true, Q.StartTimezone);
  makeTimezoneInput('BMQ_EndTimezoneHLDR', 'BMQ_EndTimezone', iTrans('End Timezone'), true, Q.EndTimezone);

  $('#BMQ_StartTimezone').val(Q.StartTimezone).selectmenu('refresh');
  $('#BMQ_EndTimezone').val(Q.EndTimezone).selectmenu('refresh');
  $('#BMQ_PrimaryKey').val(Q.PrimaryKey);
  $('#BMQ_Date').val(Q.Date);
  $('#BMQ_Expires').val(Q.Expires);
  $('#BMQ_ClientPK').val(Q.ClientPK);
  $('#BMQ_JobPK').val(Q.JobPK);
  $('#BMQ_Client_Text').val(Q.Client_Text);
  $('#BMQ_Job_Text').val(Q.Job_Text).keyup();
  $('#BMQ_ContactName').val(Q.ContactName);
  $('#BMQ_AddressedTo').val(Q.AddressedTo).keyup();
  $('#BMQ_QuoteEmail').val(Q.QuoteEmail);
  $('#BMQ_QuotePhone').val(Q.QuotePhone);
  $('#BMQ_Notes').val(Q.Notes).keyup();

  if (Q.ClientNotes != '' && Q.ClientNotes != null) {
    $('#BMQ_ClientNotes').html('<h3>' + iTrans('Client Notes') + '</h3>' + Q.ClientNotes.replace(/\n/g, '<br />'));
  } else {
    $('#BMQ_ClientNotes').html('');
  }
  $('#BMQ_CustomFieldsHLDR').html(BILLING_popQuoteCfields(CURRENT_QUOTE));
  BILLING_TotalCfieldItems('BMQ_CF');
  if (Q.Approved == 1) {
    //todo add signature and any client notes
  } else {
    $('#BMQ_AcceptedDetails').html('<h3>' + iTrans('Pending Approval') + '</h3>');
  }
  $('#NewQuoteContent').enhanceWithin();
}

function BMQsearchClientJobs(term, callback) {
  var txt = term.term.toLowerCase();
  var options = [];
  if (txt.length < 2) {
    callback(options);
    return;
  }
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=searchJobsQuote&txt=' + txt, function (json_data) {
    CURRNET_QUOTE_CLIENT_DATA = json_data;
    for (var J in json_data) {
      var I = json_data[J];
      var label = '#' + I.JobNum + ': ' + I.Name + ' [' + I.Business + ']';
      options.push({ label: label, value: J });
    }

    callback(options);
  }).fail(function (jqXHR, status, error) {
    callback(options);
  });
}

function BMQsearchClientJobsSELECT(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  var C = CURRNET_QUOTE_CLIENT_DATA[value];
  CURRENT_QUOTE.Job = C;
  $('#BMQ_JobPK').val(C.JobID);
  $('#BMQ_ClientPK').val(C.ClientPK);
  $('#BMQ_Client_Text').val(C.Business);
  $('#BMQ_Job_Text')
    .val(C.Name + '\r\n' + C.Description)
    .keyup();
  $('#BMQ_ContactName').val(C.Firstname + ' ' + C.Lastname);
  var Address = C.Address1 + '\r\n';
  Address += C.Address2 + '\r\n';
  Address += C.Postal + '\r\n';
  Address += C.City + ', ' + C.Prov_State + '\r\n';
  Address += C.Country;
  $('#BMQ_AddressedTo').val(Address).keyup();
  $('#BMQ_QuoteEmail').val(C.Email);
  $('#BMQ_QuotePhone').val(C.Phone);
  $('#BMQ_Notes').val('').keyup();
  $('#NewQuoteContent').enhanceWithin();
  //if CURRNET_QUOTE.ClientPK == 0 Prompt to select the client
  if (C.ClientPK == 0) {
    ExchangeQuoteData('getClients', null, BILLING_SelectClientPrompt);
  } else {
    CURRENT_QUOTE.ClientPK = C.PrimaryKey;
  }
}

function BILLING_SelectClientPrompt(Clients) {
  console.log(Clients);
  var html = '<label for="BILLING_QuoteClientSelect">' + iTrans('Select Client For Global Job') + '</label>';
  html += '<select id="BILLING_QuoteClientSelect" data-mini="true">';
  for (var i in Clients) {
    if (CURRENT_QUOTE.Job.clientList.length == 0){
      html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
    }else{
      var found = false;
      for (var j in CURRENT_QUOTE.Job.clientList){
        if (CURRENT_QUOTE.Job.clientList[j] == Clients[i].PrimaryKey){
          html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
        }
      }
    }

  }
  html += '</select>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Client'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          var C = Clients[$('#BILLING_QuoteClientSelect').val()];
          $('#BMQ_JobPK').val(C.JobID);
          $('#BMQ_ClientPK').val(C.ClientPK);
          $('#BMQ_Client_Text').val(C.Business);
          $('#BMQ_ContactName').val(C.Firstname + ' ' + C.Lastname);
          var Address = C.Address1 + '\r\n';
          Address += C.Address2 + '\r\n';
          Address += C.Postal + '\r\n';
          Address += C.City + ', ' + C.Prov_State + '\r\n';
          Address += C.Country;
          $('#BMQ_AddressedTo').val(Address).keyup();
          $('#BMQ_QuoteEmail').val(C.Email);
          $('#BMQ_QuotePhone').val(C.Phone);
          $('#BMQ_Notes').val('').keyup();
          $('#NewQuoteContent').enhanceWithin();
          J = CURRENT_QUOTE.Job;
          J.Business = C.Business;
          J.Address1 = C.Address1;
          J.Address2 = C.Address2;
          J.City = C.City;
          J.Postal = C.Postal;
          J.Country = C.Country;
          J.Firstname = C.Firstname;
          J.Lastname = C.Lastname;
          J.Email = C.Email;
          J.Phone = C.Phone;
          J.P_Hobbs = C.P_Hobbs;
          J.S_Hobbs = C.S_Hobbs;
          J.SignatureReq = C.SignatureReq;
          J.ClientPK = C.PrimaryKey;
          CURRENT_QUOTE.ClientPK = C.PrimaryKey;
        },
      },
    },
  });
}

function BILLING_createQuote() {
  //Save data

  CURRENT_QUOTE.PrimaryKey = $('#BMQ_PrimaryKey').val();
  CURRENT_QUOTE.Date = $('#BMQ_Date').val();
  CURRENT_QUOTE.StartTimezone = $('#BMQ_StartTimezone').val();
  CURRENT_QUOTE.EndTimezone = $('#BMQ_EndTimezone').val();
  CURRENT_QUOTE.Expires = $('#BMQ_Expires').val();
  CURRENT_QUOTE.JobPK = $('#BMQ_JobPK').val();
  CURRENT_QUOTE.Client_Text = $('#BMQ_Client_Text').val();
  CURRENT_QUOTE.ClientPK = $('#BMQ_ClientPK').val();
  CURRENT_QUOTE.Job_Text = $('#BMQ_Job_Text').val();
  CURRENT_QUOTE.ContactName = $('#BMQ_ContactName').val();
  CURRENT_QUOTE.AddressedTo = $('#BMQ_AddressedTo').val();
  CURRENT_QUOTE.QuoteEmail = $('#BMQ_QuoteEmail').val();
  CURRENT_QUOTE.QuotePhone = $('#BMQ_QuotePhone').val();
  CURRENT_QUOTE.Notes = $('#BMQ_Notes').val();
  CURRENT_QUOTE.Job = {
    customCompany: 0,
    P_Hobbs: 1,
    S_Hobbs: 1,
    Averaged: 'None',
    Business: '',
    BillBy: 'Hour',
    BillByOPT: '',
    BlockTime: 0,
    Cfields: [],
    ClientPK: 0,
    Description: '',
    flightCatID: 0,
    EndDate: null,
    Finished: 0,
    JobID: 0,
    ClientID: 0,
    JobNum: 0,
    Min_Hours: 0,
    Name: '',
    StartDate: null,
    group: GROUP_DATA.group,
    SignatureReq: 0,
    showDetails: 1,
    showJobDetails: 1,
    showPaxManLegs: 1,
    showPaxManReport: 1,
    showPaxWeights: 1,
    showCargoWeights: 1,
    showFuel: 1,
    showNonBillable: 1,
    blankLegTimes: 0,
    Firstname: '',
    Lastname: '',
    Email: '',
    Assigned_Crew: [],
    LockCrew: 0,
    LockAC: 0,
    Base: '',
    showClientFuel: GROUP_DATA['FR_showClientFuel'],
    showTZ: GROUP_DATA['FR_showTZ'],
    showTDG: GROUP_DATA['FR_showTDG'],
    showAddress: GROUP_DATA['FR_showAddress'],
    showClientDetails: GROUP_DATA['FR_showClientDetails'],
    showJobNum: GROUP_DATA['FR_showJobNum'],
    showStartEndTZ: GROUP_DATA['FR_ITIN_showStartEndTZ'] == 3 || GROUP_DATA['FR_ITIN_showStartEndTZ'] == 2 ? 1 : 0,
  };

  var action = 'newQuote';
  if (CURRENT_QUOTE.PrimaryKey != 0) {
    action = 'updateQuote';
  }
  ExchangeQuoteData(action, CURRENT_QUOTE, BILLING_SelectQuoteAircraft);
}

function BILLING_AdminQuoteCfields(e = false) {
  $.mobile.changePage($('#BILLING_ManageQuotingFields'), {
    changeHash: false,
    reverse: e,
  });
  ExchangeQuoteData('getQuoteFields', null, BILLING_ListQuoteCfields);
}

function BILLING_ListQuoteCfields(data) {
  if (data === false) {
    $('#QuotingFieldContent').html('<h3>' + iTrans('Error Retrieving Quoting Fields') + '</h3>');
  } else {
    QUOTING_FIELDS = data;
    if (data.length > 0) {
      var html = '';
      html +=
        '<table class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Name') +
        '</th><th>' +
        iTrans('Qty') +
        '</th><th>' +
        iTrans('Price') +
        '</th><th>' +
        iTrans('Tax') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead><tbody>';
      for (var i in data) {
        var C = data[i];
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_editQuoteFieldi(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_deleteQuoteField(" +
          i +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        var iClass = '';
        if (C.PerLeg == 1) {
          iClass = 'yellowbg';
        }
        html +=
          '<tr class="' +
          iClass +
          '"><td>' +
          C.Name +
          '</td><td>' +
          C.DefaultQty +
          '</td><td>' +
          C.DefaultPrice +
          '</td><td>' +
          C.Tax +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</tbody></table>';
      $('#QuotingFieldContent').html(html);
      $('#QuotingFieldContent').enhanceWithin();
    } else {
      $('#QuotingFieldContent').html('<h3>' + iTrans('No quoting fields in system') + '</h3>');
    }
  }
}

function BILLING_refreshQuotingFields() {
  ExchangeQuoteData('getQuoteFields', null, function (data) {
    if (data === false) {
      QUOTING_FIELDS = [];
    } else {
      QUOTING_FIELDS = data;
    }
  });
}

function BILLING_AdminNewQuoteField() {
  CURRENT_QUOTING_FIELD = {
    PrimaryKey: 0,
    type: 'Quote',
    req: 0,
    Name: '',
    DefaultQty: 1,
    DefaultPrice: '1.00',
    Tax: 0,
    Taxable: 1,
    PerLeg: 0,
    Archived: 0,
    QuoteSub: 0,
    QuoteTax: 0,
    QuoteTotal: 0,
  };
  BILLING_EditQuoteField();
}

function BILLING_editQuoteFieldi(index) {
  CURRENT_QUOTING_FIELD = QUOTING_FIELDS[index];
  BILLING_EditQuoteField();
}

function BILLING_EditQuoteField() {
  $.mobile.changePage($('#BILLING_EditQuotingField'), {
    changeHash: false,
    reverse: false,
  });

  var C = CURRENT_QUOTING_FIELD;
  $('#BMQF_PrimaryKey').val(C.PrimaryKey);
  if (C.req == 1) {
    $('#BMQF_req').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BMQF_req').prop('checked', false).checkboxradio('refresh');
  }
  $('#BMQF_Name').val(C.Name);
  $('#BMQF_DefaultQty').val(C.DefaultQty);
  $('#BMQF_DefaultPrice').val(C.DefaultPrice);
  if (C.Taxable == 1) {
    $('#BMQF_TaxHLDR').show();
    $('#BMQF_Taxable').prop('checked', true).checkboxradio('refresh');
    $('#BMQF_Tax').val(C.Tax);
  } else {
    $('#BMQF_Taxable').prop('checked', false).checkboxradio('refresh');
    $('#BMQF_TaxHLDR').hide();
  }

  if (C.PerLeg == 1) {
    $('#BMQF_PerLeg').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BMQF_PerLeg').prop('checked', false).checkboxradio('refresh');
  }
  if (C.PerPax == 1) {
    $('#BMQF_PerPax').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BMQF_PerPax').prop('checked', false).checkboxradio('refresh');
  }
  if (C.PerDay == 1) {
    $('#BMQF_PerDay').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BMQF_PerDay').prop('checked', false).checkboxradio('refresh');
  }
}

function BILLING_UpdateBMQF_TaxHLDR(e) {
  if (e.checked) {
    $('#BMQF_TaxHLDR').show();
  } else {
    $('#BMQF_TaxHLDR').hide();
  }
}

function BILLING_ManageQuoteFields_returnPROMPT() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      '<center>' + iTrans('Your changes will not be saved.') + ' <h3>' + iTrans('Are you Sure?') + '</h3></center>',
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          BILLING_ManageQuoteField_return();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_AdminSaveQuoteField() {
  var C = CURRENT_QUOTING_FIELD;
  C.PrimaryKey = $('#BMQF_PrimaryKey').val();
  C.Name = $('#BMQF_Name').val();
  C.DefaultQty = $('#BMQF_DefaultQty').val();
  C.DefaultPrice = $('#BMQF_DefaultPrice').val();
  if ($('#BMQF_Taxable').is(':checked')) {
    C.Taxable = 1;
  } else {
    C.Taxable = 0;
  }
  C.Tax = $('#BMQF_Tax').val();
  if ($('#BMQF_PerLeg').is(':checked')) {
    C.PerLeg = 1;
  } else {
    C.PerLeg = 0;
  }
  if ($('#BMQF_PerPax').is(':checked')) {
    C.PerPax = 1;
    C.DefaultQty = 0;
  } else {
    C.PerPax = 0;
  }
  if ($('#BMQF_PerDay').is(':checked')) {
    C.PerDay = 1;
    C.DefaultQty = 0;
  } else {
    C.PerDay = 0;
  }
  if ($('#BMQF_req').is(':checked')) {
    C.req = 1;
  } else {
    C.req = 0;
  }

  ExchangeQuoteData('saveQuoteField', C, function (data) {
    if (data !== false) {
      BILLING_AdminQuoteCfields(true);
    }
  });
}

function BILLING_deleteQuoteField(index) {
  var C = QUOTING_FIELDS[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt: '<center>' + iTrans('Are you sure you wish to delete field') + ': ' + C.Name + '?</h3></center>',
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          //do nothing
          ExchangeQuoteData('deleteQuoteField', C, function (data) {
            BILLING_AdminQuoteCfields(false);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_ManageQuoteField_return() {
  BILLING_AdminQuoteCfields(true);
}

function BILLING_GetCFselector(PerLeg) {
  var html = '<select onChange="BILLING_AddNewQuoteFieldIndex(this);" data-mini="true">';
  html += '<option value="-1">' + iTrans('Add New Quoting Field') + '</option>';
  for (var i in QUOTING_FIELDS) {
    var F = QUOTING_FIELDS[i];
    if (PerLeg != F.PerLeg) {
      continue;
    }
    html += '<option value="' + i + '">' + F.Name + '</option>';
  }
  html += '</select>';
  return html;
}

function BILLING_AddNewQuoteFieldIndex(e) {
  var F = QUOTING_FIELDS[e.value];
  F.Amount = parseFloat(F.DefaultPrice) * parseFloat(F.DefaultQty);
  F.Amount = Math.round(F.Amount * 100) / 100;
  CURRENT_QUOTE.Cfields.push(JSON.parse(JSON.stringify(F)));
  $('#BMQ_CustomFieldsHLDR').html(BILLING_popQuoteCfields(CURRENT_QUOTE));
  $('#BMQ_CustomFieldsHLDR').enhanceWithin();
  BILLING_TotalCfieldItems('BMQ_CF');
}

function BILLING_deleteBMQ_CF(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('Are you sure you wish to delete field') +
      ': ' +
      CURRENT_QUOTE.Cfields[index].Name +
      '?</h3></center>',
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          //do nothing
          CURRENT_QUOTE.Cfields.splice(index, 1);
          $('#BMQ_CustomFieldsHLDR').html(BILLING_popQuoteCfields(CURRENT_QUOTE));
          $('#BMQ_CustomFieldsHLDR').enhanceWithin();
          BILLING_TotalCfieldItems('BMQ_CF');
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_deleteBMQ_Leg_CF(index, legIndex, acIndex, Leg) {
  if (acIndex === false) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        '<center>' +
        iTrans('Are you sure you wish to delete field') +
        ': ' +
        CURRENT_QUOTE.CommonLegs[legIndex].Cfields[index].Name +
        '?</h3></center>',
      buttons: {
        [iTrans('Delete')]: {
          click: function () {
            //do nothing
            CURRENT_QUOTE.CommonLegs[legIndex].Cfields.splice(index, 1);
            CURRENT_QUOTE.CurLeg = legIndex;
            BILLING_popQuoteEditLegFields(CURRENT_QUOTE.CommonLegs[legIndex], acIndex, legIndex);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            //warn user existing records for each user will be delete
          },
        },
      },
    });
  } else {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        '<center>' +
        iTrans('Are you sure you wish to delete field') +
        ': ' +
        CURRENT_QUOTE.Aircraft[acIndex].Legs[legIndex].Cfields[index].Name +
        '?</h3></center>',
      buttons: {
        [iTrans('Delete')]: {
          click: function () {
            //do nothing
            CURRENT_QUOTE.Aircraft[acIndex].Legs[legIndex].Cfields.splice(index, 1);
            CURRENT_QUOTE.CurLeg = legIndex;
            BILLING_popQuoteEditLegFields(CURRENT_QUOTE.Aircraft[acIndex].Legs[legIndex], acIndex, legIndex);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            //warn user existing records for each user will be delete
          },
        },
      },
    });
  }
}

function BILLING_QuoteCfieldUpdate(e) {
  var F = CURRENT_QUOTE.Cfields[e.name];
  F.DefaultQty = parseFloat($('#DefaultQty-BMQ_CF-' + e.name).val());
  F.DefaultPrice = parseFloat($('#DefaultPrice-BMQ_CF-' + e.name).val());
  F.Tax = parseFloat($('#DefaultTax-BMQ_CF-' + e.name).val());
  F.Amount = Math.round(F.DefaultQty * F.DefaultPrice * 100) / 100;
  $('#Amount-BMQ_CF-' + e.name).html(F.Amount.toLocaleString('en-US', CUR));
  BILLING_TotalCfieldItems('BMQ_CF');
}

function BILLING_TotalCfieldItems(id) {
  var Sub = 0;
  var Tax = 0;
  var Total = 0;
  //Todo Add other Per Leg Quote Fields and aircraft total quote fields along the way

  if (id == 'BMQ_CF') {
    for (var i in CURRENT_QUOTE.Cfields) {
      var F = CURRENT_QUOTE.Cfields[i];
      F.Amount = parseFloat(F.DefaultPrice) * parseFloat(F.DefaultQty);
      F.Amount = Math.round(F.Amount * 100) / 100;
      Sub += F.Amount;
      if (F.Taxable == 1) {
        var pTax = F.Tax / 100;
        Tax += F.Amount * pTax;
      }
    }
  }
  if (id == 'BMQ_CFL') {
    Sub += QUOTE_CURRENT_LEG.Amount;
    Tax += (QUOTE_CURRENT_LEG.Amount * QUOTE_CURRENT_LEG.Tax) / 100;
    for (var i in QUOTE_CURRENT_LEG.Cfields) {
      var F = QUOTE_CURRENT_LEG.Cfields[i];
      F.Amount = parseFloat(F.DefaultPrice) * parseFloat(F.DefaultQty);
      F.Amount = Math.round(F.Amount * 100) / 100;
      Sub += F.Amount;
      if (F.Taxable == 1) {
        var pTax = F.Tax / 100;
        Tax += F.Amount * pTax;
      }
    }
  }
  Sub = Math.round(Sub * 100) / 100;
  Tax = Math.round(Tax * 100) / 100;
  Total = Math.round((Sub + Tax) * 100) / 100;
  $('#Sub-' + id).html(Sub.toLocaleString('en-US', CUR));
  $('#Tax-' + id).html(Tax.toLocaleString('en-US', CUR));
  $('#Total-' + id).html(Total.toLocaleString('en-US', CUR));
}

function BILLING_initNewQuoteCfields(PerLeg) {
  for (var i in QUOTING_FIELDS) {
    var F = QUOTING_FIELDS[i];
    if (PerLeg != F.PerLeg) {
      continue;
    }
    if (F.req == 1 && PerLeg == 0) {
      F.Amount = parseFloat(F.DefaultPrice) * parseFloat(F.DefaultQty);
      F.Amount = Math.round(F.Amount * 100) / 100;
      var copyF = JSON.parse(JSON.stringify(F));
      CURRENT_QUOTE.Cfields.push(copyF);
    }
  }
}

function BILLING_returnQuoteDetails() {
  $.mobile.changePage($('#BILLING_NewQuotePage'), {
    changeHash: false,
    reverse: true,
  });
  //reload any future changes
  BILLING_Init_NewQuote();
}

function BILLING_SelectQuoteAircraft(data) {
  $.mobile.changePage($('#BILLING_selectQuotingAircraft'), {
    changeHash: false,
    reverse: false,
  });
  if (CURRENT_QUOTE.Status == 'New Quote') {
    CURRENT_QUOTE.Status = 'Add Aircraft';
  }
  if (CURRENT_QUOTE.PrimaryKey == 0) {
    CURRENT_QUOTE.PrimaryKey = data[0].InsertPK;
    CURRENT_QUOTE.Hash = data[0].Hash;
  }
  QUOTE_AIRCRAFTLIST = data[0].AcList;
  BILLING_UpdateSelectedAircraft();
}

function BILLING_UpdateSelectedAircraft() {
  var html =
    '<button data-icon="plus" class="greenbg" onClick="BILLING_AdminAddQuoteAircraft()">' +
    iTrans('Add Aircraft') +
    '</button><table class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Ident') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Rate - Currency / Unit') +
    '</th><th>' +
    iTrans('Tax Rate') +
    '</th><th>' +
    iTrans('Cruise') +
    ' (knt)</th><th>' +
    iTrans('Unique Legs') +
    '</th></tr></thead><tbody>';
  if (CURRENT_QUOTE.Aircraft.length != 0) {
    for (var i in CURRENT_QUOTE.Aircraft) {
      var A = CURRENT_QUOTE.Aircraft[i];
      var uniquelegs = '';
      if (A.UniqueLegs == 1) {
        uniquelegs = 'checked';
      }
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_QuoteRemoveAircraftIndex(" +
        i +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button>";
      var id = '-BMQL_CF-' + i;
      var Currency = BILLING_AcCurrencySelector(id, i, A.Currency);
      var RateUnit = BILLING_AcRateUnitSelector(id, i, A.Rate_Unit);
      html +=
        '<tr><td>' +
        actions +
        ' ' +
        A.AC_Ident +
        '</div></td><td>' +
        A.AcType +
        '</td><td><input style="width:75px;" type="number" id="Rate' +
        id +
        '" value="' +
        A.Rate +
        '" name="' +
        i +
        '" data-role="none" onClick="this.select();" onChange="BILLING_UpdateAircraftQuoteData(this);" /> ' +
        Currency +
        '/' +
        RateUnit +
        '</td><td><input style="width:75px;" type="number" id="Tax' +
        id +
        '" value="' +
        A.Tax +
        '" name="' +
        i +
        '" data-role="none" onClick="this.select();" onChange="BILLING_UpdateAircraftQuoteData(this);" /></td><td><input style="width:75px;" type="number" id="Cruise' +
        id +
        '" value="' +
        A.Cruise +
        '" name="' +
        i +
        '" data-role="none" onClick="this.select();" onChange="BILLING_UpdateAircraftQuoteData(this);" /></td><td><input type="checkbox" id="UniqueLegs' +
        id +
        '" name="' +
        i +
        '" onClick="BILLING_UpdateAircraftQuoteData(this);" ' +
        uniquelegs +
        '/></td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html =
      '<button data-icon="plus" class="greenbg" onClick="BILLING_AdminAddQuoteAircraft()">' +
      iTrans('Add Aircraft') +
      '</button><h3>' +
      iTrans('No Aircraft added to quote') +
      '</h3>';
  }
  $('#QuotingAircraftContent').html(html);
  $('#QuotingAircraftContent').enhanceWithin();
}

function BILLING_AcCurrencySelector(id, index, value) {
  var html =
    '<select id="Currency' +
    id +
    '" onChange="BILLING_UpdateAircraftQuoteData(this);" name="' +
    index +
    '" data-role="none">';
  if (value == 'USD') {
    html += '<option value="USD" selected>USD</option>';
  } else {
    html += '<option value="USD">USD</option>';
  }
  if (value == 'CAD') {
    html += '<option value="CAD" selected>CAD</option>';
  } else {
    html += '<option value="CAD">CAD</option>';
  }
  if (value == 'AUD') {
    html += '<option value="AUD" selected>AUD</option>';
  } else {
    html += '<option value="AUD">AUD</option>';
  }
  if (value == 'CLP') {
    html += '<option value="CLP" selected>CLP</option>';
  } else {
    html += '<option value="CLP">CLP</option>';
  }
  html += '</select>';
  return html;
}

function BILLING_AcRateUnitSelector(id, index, value) {
  var html =
    '<select id="Rate_Unit' +
    id +
    '" onChange="BILLING_UpdateAircraftQuoteData(this);" name="' +
    index +
    '" data-role="none">';
  if (value == 'Hour') {
    html += '<option value="Hour" selected>' + iTrans('Hour') + '</option>';
  } else {
    html += '<option value="Hour">' + iTrans('Hour') + '</option>';
  }
  if (value == 'nm') {
    html += '<option value="nm" selected>nm</option>';
  } else {
    html += '<option value="nm">nm</option>';
  }
  if (value == 'sm') {
    html += '<option value="sm" selected>sm</option>';
  } else {
    html += '<option value="sm">sm</option>';
  }
  if (value == 'km') {
    html += '<option value="km" selected>km</option>';
  } else {
    html += '<option value="km">km</option>';
  }
  if (value == 'lb') {
    html += '<option value="lb" selected>lb</option>';
  } else {
    html += '<option value="lb">lb</option>';
  }
  if (value == 'kg') {
    html += '<option value="kg" selected>kg</option>';
  } else {
    html += '<option value="kg">kg</option>';
  }
  if (value == 'litres') {
    html += '<option value="litres" selected>Litre</option>';
  } else {
    html += '<option value="litres">Litre</option>';
  }
  if (value == 'gallonsus') {
    html += '<option value="gallonsus" selected>US Gallon</option>';
  } else {
    html += '<option value="gallonsus">US Gallon</option>';
  }
  if (value == 'gallonsimp') {
    html += '<option value="gallonsimp" selected>Imp Gallon</option>';
  } else {
    html += '<option value="gallonsimp">Imp Gallon</option>';
  }
  html += '</select>';
  return html;
}

function BILLING_UpdateAircraftQuoteData(e) {
  var A = CURRENT_QUOTE.Aircraft[e.name];
  var decimalsPoints = getDecimals($('#Rate-BMQL_CF-' + e.name).val());
  A.Rate = roundNum(parseFloat($('#Rate-BMQL_CF-' + e.name).val()), decimalsPoints > 2 ? decimalsPoints : 2);
  A.Cruise = parseInt($('#Cruise-BMQL_CF-' + e.name).val());
  if ($('#UniqueLegs-BMQL_CF-' + e.name).is(':checked')) {
    A.UniqueLegs = 1;
    A.Pax_Manifest = {
      DefaultList: [],
      Pax_Legs: [],
      CurLeg: 0,
      HasVIP: 0,
      Editing: true,
      scrollTo: 0,
      ClientPK: CURRENT_QUOTE.ClientPK,
    };
  } else {
    A.UniqueLegs = 0;
  }
  A.Currency = $('#Currency-BMQL_CF-' + e.name).val();
  A.Rate_Unit = $('#Rate_Unit-BMQL_CF-' + e.name).val();
  A.Tax = parseFloat($('#Tax-BMQL_CF-' + e.name).val());
  if (A.Tax > 100) {
    A.Tax = 99;
  }
  if (A.Tax < 0) {
    A.Tax = 0;
  }
  A.Tax = Math.round(A.Tax * 100) / 100;

  ExchangeQuoteData('UpdateQuoteAircraft', A, function (data) {
    if (A.PrimaryKey == 0) {
      A.PrimaryKey = data[0].InsertPK;
    }
    QUOTE_AIRCRAFTLIST = data[0].AcList;
    BILLING_UpdateSelectedAircraft();
  });
}

function BILLING_AdminAddQuoteAircraft() {
  var html =
    '<table id="BIT_Quoting2" class="limit-table first-td-fill" data-filter="true"><thead><tr><th>' +
    iTrans('Ident') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Rate') +
    '</th><th>' +
    iTrans('Tax') +
    '</th><th>' +
    iTrans('Cruise') +
    ' (knt)</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  var unit = 'hr';
  if (CURRENT_QUOTE.Job != undefined) {
    switch (CURRENT_QUOTE.Job.BillBy) {
      case 'Volume':
      case 'Distance':
      case 'Weight':
        switch (CURRENT_QUOTE.Job.BillByOPT) {
          case 'litres':
            unit = 'Litre';
            break;
          case 'gallonsus':
            unit = 'US Gallon';
            break;
          case 'gallonsimp':
            unit = 'Imp Gallon';
            break;
          default:
            unit = CURRENT_QUOTE.Job.BillByOPT;
        }
        break;
      default:
        unit = 'hr';
    }
  }
  for (var i in QUOTE_AIRCRAFTLIST) {
    var A = QUOTE_AIRCRAFTLIST[i];
    var QuoteAC = BILLING_QuoteFindACRate(A.AcType);
    var rate = '$' + roundNum(A.hourly_rate_cents / 100, 2) + ' ' + A.hourly_rate_currency + '/' + unit;
    if (QuoteAC !== false) {
      rate = '$' + QuoteAC.WetRate + ' ' + A.hourly_rate_currency + '/' + unit;
    }
    var disabled = '';
    var extratext = '';
    if (A.Hibrenated == 1) {
      disabled = 'disabled';
      extratext = ' [' + iTrans('Hibernated') + ']';
    }
    var actions =
      "<div class='vertical-center-container'><button onClick='BILLING_QuoteAddAircraftIndex(" +
      i +
      ")' data-mini='true' class='action-btn' " +
      disabled +
      "><img src='./images/Check_mark_green.svg' /></button></div>";
    html +=
      '<tr><td>' +
      A.ident +
      extratext +
      '</td><td>' +
      A.AcType +
      '</td><td>' +
      rate +
      '</td><td>' +
      A.Tax +
      '%<td>' +
      A.Cruise +
      '</td><td>' +
      actions +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#QuotingAircraftContent').html(html);
  $('#QuotingAircraftContent').enhanceWithin();
  AddTableSorter('BIT_Quoting2');
}

function BILLING_QuoteFindACRate(AcType) {
  if (CURRENT_QUOTE.Job != undefined) {
    if (CURRENT_QUOTE.Job.QuoteAC != undefined) {
      for (var i in CURRENT_QUOTE.Job.QuoteAC) {
        var I = CURRENT_QUOTE.Job.QuoteAC[i];
        if (I.AcType == AcType) {
          return I;
        }
      }
    }
  }
  return false;
}

function BILLING_QuoteRemoveAircraftIndex(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('Are you sure you wish to delete aircraft') +
      ': ' +
      CURRENT_QUOTE.Aircraft[index].AC_Ident +
      '?</h3></center>',
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          //do nothing
          var Aircraft = CURRENT_QUOTE.Aircraft[index];
          CURRENT_QUOTE.Aircraft.splice(index, 1);
          ExchangeQuoteData('RemoveQuoteAircraft', Aircraft, function (data) {
            QUOTE_AIRCRAFTLIST = data[0].AcList;
            BILLING_UpdateSelectedAircraft();
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //warn user existing records for each user will be delete
        },
      },
    },
  });
}

function BILLING_QuoteAddAircraftIndex(index) {
  var A = QUOTE_AIRCRAFTLIST[index];
  var QuoteAC = BILLING_QuoteFindACRate(A.AcType);
  var Aircraft = {
    PrimaryKey: 0,
    ACPK: A.PrimaryKey,
    QuotePK: CURRENT_QUOTE.PrimaryKey,
    AcType: A.AcType,
    AC_Ident: A.ident,
    StartTimezone: CurrentTimeZone,
    EndTimezone: CurrentTimeZone,
    Cruise: A.Cruise,
    SCH_AircraftPK: 0,
    TaxiTime: 0,
    Rate: roundNum(A.hourly_rate_cents / 100, 2),
    Rate_Unit: 'Hour',
    Tax: A.Tax,
    Currency: A.hourly_rate_currency,
    UniqueLegs: 0,
    Legs: [],
  };
  if (QuoteAC !== false) {
    Aircraft.Rate = QuoteAC.WetRate;
  }
  if (CURRENT_QUOTE.Job != undefined) {
    if (CURRENT_QUOTE.Job.BillBy == 'Distance') {
      switch (CURRENT_QUOTE.Job.BillByOPT) {
        case 'SM':
          Aircraft.Rate_Unit = 'sm';
          break;
        case 'NM':
          Aircraft.Rate_Unit = 'nm';
          break;
        case 'KM':
          Aircraft.Rate_Unit = 'km';
          break;
        default:
          Aircraft.Rate_Unit = 'Hour';
          break;
      }
    }
    if (CURRENT_QUOTE.Job.BillBy == 'Weight') {
      switch (CURRENT_QUOTE.Job.BillByOPT) {
        case 'KG':
          Aircraft.Rate_Unit = 'kg';
          break;
        case 'LB':
          Aircraft.Rate_Unit = 'lb';
          break;
        default:
          Aircraft.Rate_Unit = 'lb';
          break;
      }
    }
    if (CURRENT_QUOTE.Job.BillBy == 'Volume') {
      Aircraft.Rate_Unit = CURRENT_QUOTE.Job.BillByOPT;
    }
  }
  CURRENT_QUOTE.Aircraft.push(Aircraft);

  ExchangeQuoteData('UpdateQuoteAircraft', Aircraft, function (data) {
    if (Aircraft.PrimaryKey == 0) {
      Aircraft.PrimaryKey = data[0].InsertPK;
    }
    QUOTE_AIRCRAFTLIST = data[0].AcList;
    BILLING_UpdateSelectedAircraft();
  });
}

function BILLING_returnQuoteAircraft() {
  $.mobile.changePage($('#BILLING_selectQuotingAircraft'), {
    changeHash: false,
    reverse: true,
  });
  //update aircraft list in case we're working in reverse
  ExchangeQuoteData(
    'RemoveQuoteAircraft',
    { QuotePK: CURRENT_QUOTE.PrimaryKey, AC_Ident: '', UniqueLegs: 0 },
    function (data) {
      QUOTE_AIRCRAFTLIST = data[0].AcList;
      BILLING_UpdateSelectedAircraft();
    }
  );
}

function BILLING_AddQuoteLegs() {
  $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
    changeHash: false,
    reverse: false,
  });
  if (CURRENT_QUOTE.Status == 'Add Aircraft') {
    CURRENT_QUOTE.Status = 'Add Legs';
  }
  BILLING_ReloadQuoteLegs();
  if (ITIN_Pax_Manifest.Scheduler) {
    SCHpaxManifest(CURRENT_QUOTE.SCH_INDEX, -1);
  }
}

function BILLING_ReloadQuoteLegs() {
  var UniqueLegs = '';
  var Aircraft = CURRENT_QUOTE.Aircraft;
  var HasUnique = false;
  var HasUniqueLegs = false;
  var HasAircraft = false;
  for (var i in Aircraft) {
    var AC = Aircraft[i];
    if (AC.UniqueLegs == 0) {
      HasAircraft = true;
      continue;
    }
    HasUnique = true;
    if (AC.Legs.length > 0) {
      HasUniqueLegs = true;
      UniqueLegs +=
        '<h3>' +
        iTrans('Legs for') +
        ': ' +
        AC.AC_Ident +
        '</h3>' +
        BILLING_GenerateLegList(
          CURRENT_QUOTE,
          BILLING_getTimezoneHTML(AC, i),
          AC.Legs,
          i,
          true,
          AC.Pax_Manifest.Pax_Legs
        );
      setTimeout(function () {
        BILLING_AddQuoteTimezoneSearch(i);
      }, 500);
    } else {
      UniqueLegs += '<h3>' + iTrans('No Legs for') + ': ' + AC.AC_Ident + '</h3>';
    }
  }
  if (!CURRENT_QUOTE.Scheduler) {
    $('#BMQ_TravelLegsNavHLDR').show();
    $('#BMQ_CommonLegsHLDR').show();
    $('#BMQL_ExtraDetailsHLDR').show();
    var CommonLegs;
    $('#CommonLegBTN').prop('disabled', false);
    if (HasAircraft) {
      CommonLegs = '<h3>' + iTrans('No Common Legs Added') + '</h3>';
      if (CURRENT_QUOTE.CommonLegs.length > 0) {
        CommonLegs = BILLING_GenerateLegList(
          CURRENT_QUOTE,
          BILLING_getTimezoneHTML(CURRENT_QUOTE),
          CURRENT_QUOTE.CommonLegs,
          false,
          CURRENT_QUOTE.Pax_Manifest.Pax_Legs
        );
      }
    } else {
      CommonLegs = '<h3>' + iTrans('No Aircraft with Common Legs') + '</h3>';
      $('#CommonLegBTN').prop('disabled', true);
    }
    $('#UniqueLegBTN').prop('disabled', false);
    if (!HasUnique) {
      UniqueLegs = '<h3>' + iTrans('No Aircraft with Unique Legs') + '</h3>';
      $('#UniqueLegBTN').prop('disabled', true);
    } else if (!HasUniqueLegs) {
      UniqueLegs = '<h3>' + iTrans('No Unique Legs Added') + '</h3>';
    }
    $('#QuotingCommonLegsContent').html(CommonLegs);
    $('#QuotingCommonLegsContent').enhanceWithin();
  } else {
    $('#BMQ_CommonLegsHLDR').hide();
    $('#BMQ_TravelLegsNavHLDR').hide();
    $('#BMQL_ExtraDetailsHLDR').hide();
    $('#QuotingCommonLegsContent').html('');
    if (ITIN_Pax_Manifest.Scheduler) {
      ITIN_Pax_Manifest.CurLeg = -1;
      SCHpaxManifest(CURRENT_QUOTE.SCH_INDEX, -1);
    }
  }
  $('#QuotingUniqueLegsContent').html(UniqueLegs);
  $('#QuotingUniqueLegsContent').enhanceWithin();
}

function BILLING_editQuoteLeg(leg, index) {
  $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
    changeHash: false,
    reverse: false,
  });
  var Leg;
  if (index === false) {
    Leg = CURRENT_QUOTE.CommonLegs[leg];
    if (ITIN_Pax_Manifest.Quote) {
      for (var i in CURRENT_QUOTE.Aircraft) {
        var I = CURRENT_QUOTE.Aircraft[i];
        if (I.UniqueLegs == 0) {
          QUOTING_paxManifest(I.AC_Ident, leg, false, -1);
          break;
        }
      }
    }
  } else {
    if (ITIN_Pax_Manifest.Quote) {
      QUOTING_paxManifest(CURRENT_QUOTE.Aircraft[index].AC_Ident, leg, false, index);
    }
    Leg = CURRENT_QUOTE.Aircraft[index].Legs[leg];
  }
  CURRENT_QUOTE.CurLeg = index;
  if (ITIN_Pax_Manifest.Scheduler) {
    SCHpaxManifest(CURRENT_QUOTE.SCH_INDEX, leg);
  }
  BILLING_popQuoteEditLegFields(Leg, index, leg);
}

function BILLING_deleteQuoteLeg(index, ACindex) {
  //confirm delete
  var leg = parseInt(index) + 1;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete leg') + '# ' + leg,
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          var L;
          if (ACindex === false) {
            L = JSON.parse(JSON.stringify(CURRENT_QUOTE.CommonLegs[index]));
          } else {
            L = JSON.parse(JSON.stringify(CURRENT_QUOTE.Aircraft[ACindex].Legs[index]));
          }
          L.legIndex = index;
          if (CURRENT_QUOTE.Scheduler) {
            L.SCH_BookingPK = SCHEVENTDATA.PrimaryKey;
            L.SCH_AircraftPK = CURRENT_QUOTE.SCH_AC_DATA.ItemPrimaryKey;
          }
          ExchangeQuoteData('DeleteQuoteLeg', L, function (data) {
            if (ACindex === false) {
              CURRENT_QUOTE.CommonLegs.splice(index, 1);
            } else {
              CURRENT_QUOTE.Aircraft[ACindex].Legs.splice(index, 1);
            }
            try {
              ITIN_Pax_Manifest.Pax_Legs.splice(index, 1);
            } catch (e) {
              //doesn't exist yet probably
            }
            BILLING_ReloadQuoteLegs();
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_QuoteLegEdit_returnPROMPT() {
  //prompt save changes?
  $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_ReloadQuoteLegs();
}

function BILLING_CreateNewLeg(QACPK) {
  var Cruise = 100;
  var Currency = 'USD';
  var Rate_Unit = 'Hour';
  var Rate = 0;
  var TaxiTime = 0;
  var Tax = 0;
  var Weight = 0;
  if (QACPK == 0) {
    var A = CURRENT_QUOTE.Aircraft;
    for (var i in A) {
      if (A[i].UniqueLegs == 0 && A[i].Cruise != 0) {
        Cruise = A[i].Cruise;
        Currency = A[i].Currency;
        Rate_Unit = A[i].Rate_Unit;
        Rate = A[i].Rate;
        TaxiTime = A[i].TaxiTime;
        Tax = A[i].Tax;
        break;
      }
    }
  } else {
    var A = CURRENT_QUOTE.Aircraft;
    for (var i in A) {
      if (A[i].PrimaryKey == QACPK) {
        Cruise = A[i].Cruise;
        Currency = A[i].Currency;
        Rate_Unit = A[i].Rate_Unit;
        Rate = A[i].Rate;
        TaxiTime = A[i].TaxiTime;
        Tax = A[i].Tax;
        break;
      }
    }
  }
  var DepartDate = BILLING_GetTodayDate();

  if (CURRENT_QUOTE.Scheduler) {
    DepartDate = SCHEVENTDATA.start.substr(0, 10);
  }
  var Leg = {
    PrimaryKey: 0,
    newLegSet: 0,
    QuotePK: CURRENT_QUOTE.PrimaryKey,
    QACPK: QACPK,
    SCH_AircraftPK: CURRENT_QUOTE.Scheduler ? CURRENT_QUOTE.SCH_AircraftPK : 0,
    flightCatID: CURRENT_QUOTE.Scheduler ? CURRENT_QUOTE.flightCatID : 0,
    DepartDate: DepartDate,
    ArrivalDate: DepartDate,
    DepartTime: '00:00',
    ArrivalTime: '00:00',
    FromICAO: '',
    ToICAO: '',
    FromICAO_Name: '',
    ToICAO_Name: '',
    FromDESC: '',
    ToDESC: '',
    ETE: '00:00',
    Distance: 0,
    DistanceUnits: 'nm',
    Cruise: Cruise,
    CruiseUnits: 'knt',
    Weight: Weight,
    PaxNum: 0,
    Amount: 0,
    Currency: Currency,
    Rate_Unit: Rate_Unit,
    Rate: Rate,
    Tax: Tax,
    TaxiTimeOrigin: TaxiTime,
    TaxiTimeDest: TaxiTime,
    Billable: 1,
    Timezone: CurrentTimeZone,
    Cfields: BILLING_getLegCfields(),
    Cfields2: [],
    modified: dateToStringObject(new Date()),
  };
  return Leg;
}

function BILLING_getLegCfields() {
  var Cfields = [];
  for (var i in QUOTING_FIELDS) {
    var F = QUOTING_FIELDS[i];
    if (F.PerLeg == 0) {
      continue;
    }
    if (F.req == 1) {
      F.Amount = parseFloat(F.DefaultPrice) * parseFloat(F.DefaultQty);
      F.Amount = Math.round(F.Amount * 100) / 100;
      var copyF = JSON.parse(JSON.stringify(F));
      Cfields.push(copyF);
    }
  }
  return Cfields;
}

function BILLING_AddCommonLeg() {
  //prompt to add Cfields from previous leg if one exists?
  var CommonLegs = CURRENT_QUOTE.CommonLegs;
  if (CommonLegs.length > 0) {
    var PrevLeg = JSON.parse(JSON.stringify(CommonLegs[CommonLegs.length - 1]));
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt: iTrans('Would you like to copy the Quoting fields from the previous leg?'),
      buttons: {
        Yes: {
          click: function () {
            var NewCfields = JSON.parse(JSON.stringify(PrevLeg.Cfields));
            var NewLeg = BILLING_CreateNewLeg(0);
            NewLeg.Cfields = NewCfields;
            NewLeg.FromICAO = PrevLeg.ToICAO;
            NewLeg.FromICAO_Name = PrevLeg.ToICAO_Name;
            NewLeg.Timezone = PrevLeg.Timezone;
            NewLeg.flightCatID = PrevLeg.flightCatID;
            NewLeg.FromDESC = PrevLeg.ToDESC;
            NewLeg.DepartDate = PrevLeg.ArrivalDate;
            NewLeg.DepartTime = PrevLeg.ArrivalTime;
            NewLeg.PaxNum = PrevLeg.PaxNum;
            NewLeg.Weight = PrevLeg.Weight;
            CommonLegs.push(NewLeg);
            var html = BILLING_GenerateLegList(
              CURRENT_QUOTE,
              BILLING_getTimezoneHTML(CURRENT_QUOTE),
              CommonLegs,
              false,
              true,
              CURRENT_QUOTE.Pax_Manifest.Pax_Legs
            );
            $('#QuotingCommonLegsContent').html(html);
            $('#QuotingCommonLegsContent').enhanceWithin();
            $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
              changeHash: false,
              reverse: false,
            });
            CURRENT_QUOTE.CurLeg = 0;
            try {
              CURRENT_QUOTE.Pax_Manifest.Pax_Legs.push(
                JSON.parse(JSON.stringify(CURRENT_QUOTE.Pax_Manifest.Pax_Legs[CommonLegs.length - 2]))
              );
            } catch (e) {
              console.log('could not copy pax legs', e);
            }
            for (var i in CURRENT_QUOTE.Aircraft) {
              var I = CURRENT_QUOTE.Aircraft[i];
              if (I.UniqueLegs == 0) {
                QUOTING_paxManifest(I.AC_Ident, CommonLegs.length - 1, false, -1);
                break;
              }
            }
            BILLING_popQuoteEditLegFields(NewLeg, false, CommonLegs.length - 1);
          },
        },
        No: {
          click: function () {
            var NewLeg = BILLING_CreateNewLeg(0);
            NewLeg.FromICAO = PrevLeg.ToICAO;
            NewLeg.FromICAO_Name = PrevLeg.ToICAO_Name;
            NewLeg.FromDESC = PrevLeg.ToDESC;
            NewLeg.DepartDate = PrevLeg.ArrivalDate;
            NewLeg.DepartTime = PrevLeg.ArrivalTime;
            NewLeg.Timezone = PrevLeg.Timezone;
            NewLeg.flightCatID = PrevLeg.flightCatID;
            NewLeg.PaxNum = PrevLeg.PaxNum;
            NewLeg.Weight = PrevLeg.Weight;
            CommonLegs.push(NewLeg);
            var html = BILLING_GenerateLegList(
              CURRENT_QUOTE,
              BILLING_getTimezoneHTML(CURRENT_QUOTE),
              CommonLegs,
              false,
              true,
              CURRENT_QUOTE.Pax_Manifest.Pax_Legs
            );
            $('#QuotingCommonLegsContent').html(html);
            $('#QuotingCommonLegsContent').enhanceWithin();
            $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
              changeHash: false,
              reverse: false,
            });
            CURRENT_QUOTE.CurLeg = 0;
            for (var i in CURRENT_QUOTE.Aircraft) {
              var I = CURRENT_QUOTE.Aircraft[i];
              if (I.UniqueLegs == 0) {
                QUOTING_paxManifest(I.AC_Ident, CommonLegs.length - 1, false, -1);
                break;
              }
            }
            BILLING_popQuoteEditLegFields(NewLeg, false, CommonLegs.length - 1);
          },
        },
      },
    });
  } else {
    var NewLeg = BILLING_CreateNewLeg(0);
    if (CURRENT_QUOTE.Date > NewLeg.DepartDate) {
      NewLeg.DepartDate = CURRENT_QUOTE.Date;
      NewLeg.ArrivalDate = CURRENT_QUOTE.Date;
    }
    CommonLegs.push(NewLeg);
    var html = BILLING_GenerateLegList(
      CURRENT_QUOTE,
      BILLING_getTimezoneHTML(CURRENT_QUOTE),
      CommonLegs,
      false,
      true,
      CURRENT_QUOTE.Pax_Manifest.Pax_Legs
    );
    $('#QuotingCommonLegsContent').html(html);
    $('#QuotingCommonLegsContent').enhanceWithin();
    $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
      changeHash: false,
      reverse: false,
    });
    CURRENT_QUOTE.CurLeg = 0;
    for (var i in CURRENT_QUOTE.Aircraft) {
      var I = CURRENT_QUOTE.Aircraft[i];
      if (I.UniqueLegs == 0) {
        QUOTING_paxManifest(I.AC_Ident, CommonLegs.length - 1, false, -1);
        break;
      }
    }
    BILLING_popQuoteEditLegFields(NewLeg, false, CommonLegs.length - 1);
  }
}

function BILLING_AddUniqueLeg() {
  //search list of aircraft for UniqueLegs flag and ask user to select aircraft if more than one
  var Aircraft = CURRENT_QUOTE.Aircraft;
  var SelectList = [];
  var html = '<h3>' + iTrans('Select Aircraft') + '</h3><select id="SelectUniqueAircraft">';
  var curIndex = 0;
  for (var i in Aircraft) {
    var A = Aircraft[i];
    if (A.UniqueLegs == 1) {
      SelectList.push(A);
      html += '<option value="' + i + '">' + A.AC_Ident + '</option>';
      curIndex = i;
    }
  }
  html += '</select>';
  if (SelectList.length == 1) {
    BILLING_AddUniqueLeg_Aircraft(curIndex);
  } else {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Select Aircraft'),
      headerClose: false,
      buttonPrompt: html,
      buttons: {
        [iTrans('Select')]: {
          click: function () {
            BILLING_AddUniqueLeg_Aircraft($('#SelectUniqueAircraft').val());
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  }
}

function BILLING_AddUniqueLeg_Aircraft(index) {
  //prompt to add Cfields from previous leg if one exists?
  var UniqueLegs = CURRENT_QUOTE.Aircraft[index].Legs;
  if (UniqueLegs.length > 0) {
    var PrevLeg = JSON.parse(JSON.stringify(UniqueLegs[UniqueLegs.length - 1]));
    if (CURRENT_QUOTE.Scheduler) {
      var NewCfields = JSON.parse(JSON.stringify(PrevLeg.Cfields));
      var NewLeg = BILLING_CreateNewLeg(CURRENT_QUOTE.Aircraft[index].PrimaryKey);
      NewLeg.Cfields = NewCfields;
      NewLeg.FromICAO = PrevLeg.ToICAO;
      NewLeg.FromICAO_Name = PrevLeg.ToICAO_Name;
      NewLeg.FromDESC = PrevLeg.ToDESC;
      NewLeg.Timezone = CurrentTimeZone;
      NewLeg.DepartDate = PrevLeg.ArrivalDate;
      NewLeg.DepartTime = PrevLeg.ArrivalTime;
      NewLeg.PaxNum = PrevLeg.PaxNum;
      UniqueLegs.push(NewLeg);
      var html = BILLING_GenerateLegList(
        CURRENT_QUOTE,
        BILLING_getTimezoneHTML(CURRENT_QUOTE.Aircraft[index]),
        UniqueLegs,
        index,
        true,
        CURRENT_QUOTE.Aircraft[index].Pax_Manifest.Pax_Legs
      );
      $('#QuotingUniqueLegsContent').html(html);
      $('#QuotingUniqueLegsContent').enhanceWithin();
      $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
        changeHash: false,
        reverse: false,
      });
      CURRENT_QUOTE.CurLeg = UniqueLegs.length - 1;
      SCHpaxManifest(index, UniqueLegs.length - 1);
      BILLING_popQuoteEditLegFields(NewLeg, index, UniqueLegs.length - 1);
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Confirm'),
        headerClose: false,
        buttonPrompt: iTrans('Would you like to copy the Quoting fields from the previous leg?'),
        buttons: {
          [iTrans('Yes')]: {
            click: function () {
              var NewCfields = JSON.parse(JSON.stringify(PrevLeg.Cfields));
              var NewLeg = BILLING_CreateNewLeg(CURRENT_QUOTE.Aircraft[index].PrimaryKey);
              NewLeg.Cfields = NewCfields;
              NewLeg.FromICAO = PrevLeg.ToICAO;
              NewLeg.FromICAO_Name = PrevLeg.ToICAO_Name;
              NewLeg.FromDESC = PrevLeg.ToDESC;
              NewLeg.Timezone = CurrentTimeZone;
              NewLeg.DepartDate = PrevLeg.ArrivalDate;
              NewLeg.DepartTime = PrevLeg.ArrivalTime;
              NewLeg.PaxNum = PrevLeg.PaxNum;
              UniqueLegs.push(NewLeg);
              var html = BILLING_GenerateLegList(
                CURRENT_QUOTE,
                BILLING_getTimezoneHTML(CURRENT_QUOTE.Aircraft[index]),
                UniqueLegs,
                index,
                true,
                CURRENT_QUOTE.Aircraft[index].Pax_Manifest.Pax_Legs
              );
              $('#QuotingUniqueLegsContent').html(html);
              $('#QuotingUniqueLegsContent').enhanceWithin();
              $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
                changeHash: false,
                reverse: false,
              });
              CURRENT_QUOTE.CurLeg = index;
              try {
                CURRENT_QUOTE.Aircraft[index].Pax_Manifest.Pax_Legs.push(
                  JSON.parse(JSON.stringify(CURRENT_QUOTE.Aircraft[index].Pax_Manifest.Pax_Legs[UniqueLegs.length - 2]))
                );
              } catch (e) {
                //do nothing
                console.log('could not copy paxlegs', e);
              }

              QUOTING_paxManifest(CURRENT_QUOTE.Aircraft[index].AC_Ident, UniqueLegs.length - 1, false, index);
              BILLING_popQuoteEditLegFields(NewLeg, index, UniqueLegs.length - 1);
            },
          },
          [iTrans('No')]: {
            click: function () {
              var NewLeg = BILLING_CreateNewLeg(CURRENT_QUOTE.Aircraft[index].PrimaryKey);
              NewLeg.FromICAO = PrevLeg.ToICAO;
              NewLeg.FromICAO_Name = PrevLeg.ToICAO_Name;
              NewLeg.FromDESC = PrevLeg.ToDESC;
              NewLeg.Timezone = CurrentTimeZone;
              NewLeg.DepartDate = PrevLeg.ArrivalDate;
              NewLeg.DepartTime = PrevLeg.ArrivalTime;
              NewLeg.PaxNum = PrevLeg.PaxNum;
              UniqueLegs.push(NewLeg);
              var html = BILLING_GenerateLegList(
                CURRENT_QUOTE,
                BILLING_getTimezoneHTML(CURRENT_QUOTE.Aircraft[index]),
                UniqueLegs,
                index,
                true,
                CURRENT_QUOTE.Aircraft[index].Pax_Manifest.Pax_Legs
              );
              $('#QuotingUniqueLegsContent').html(html);
              $('#QuotingUniqueLegsContent').enhanceWithin();
              $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
                changeHash: false,
                reverse: false,
              });
              CURRENT_QUOTE.CurLeg = index;
              QUOTING_paxManifest(CURRENT_QUOTE.Aircraft[index].AC_Ident, UniqueLegs.length - 1, false, index);

              BILLING_popQuoteEditLegFields(NewLeg, index, UniqueLegs.length - 1);
            },
          },
        },
      });
    }
  } else {
    var NewLeg = BILLING_CreateNewLeg(CURRENT_QUOTE.Aircraft[index].PrimaryKey);
    if (CURRENT_QUOTE.Date > NewLeg.DepartDate) {
      NewLeg.DepartDate = CURRENT_QUOTE.Date;
      NewLeg.ArrivalDate = CURRENT_QUOTE.Date;
    }
    UniqueLegs.push(NewLeg);

    var html = BILLING_GenerateLegList(
      CURRENT_QUOTE,
      BILLING_getTimezoneHTML(CURRENT_QUOTE.Aircraft[index]),
      UniqueLegs,
      index,
      true,
      CURRENT_QUOTE.Aircraft[index].Pax_Manifest.Pax_Legs
    );
    $('#QuotingUniqueLegsContent').html(html);

    $('#QuotingUniqueLegsContent').enhanceWithin();
    $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
      changeHash: false,
      reverse: false,
    });
    CURRENT_QUOTE.CurLeg = UniqueLegs.length - 1;
    if (CURRENT_QUOTE.Scheduler) {
      SCHpaxManifest(index, UniqueLegs.length - 1);
    } else {
      QUOTING_paxManifest(CURRENT_QUOTE.Aircraft[index].AC_Ident, UniqueLegs.length - 1, false, index);
    }
    BILLING_popQuoteEditLegFields(NewLeg, index, UniqueLegs.length - 1);
  }
}

function BILLING_AddQuoteTimezoneSearch(index) {
  console.log('Adding Search autocompletes');
  $('#BMQ_StartTimezone-' + index + '_TZSearch').autocomplete({
    select: TimezoneInputSearchSelect,
    source: TimezoneInputSearch,
  });
  $('#BMQ_EndTimezone-' + index + '_TZSearch').autocomplete({
    select: TimezoneInputSearchSelect,
    source: TimezoneInputSearch,
  });
}

function BILLING_UpdateACTimezone(index) {
  CURRENT_QUOTE.Aircraft[index].StartTimezone = $('#BMQ_StartTimezone-' + index).val();
  CURRENT_QUOTE.Aircraft[index].EndTimezone = $('#BMQ_EndTimezone-' + index).val();
  ExchangeQuoteData('UpdateQuoteAircraft', CURRENT_QUOTE.Aircraft[index], null);
}

function BILLING_popQuoteEditLegFields(Leg, index, legindex) {
  QUOTE_CURRENT_LEG = Leg;
  QUOTE_CURRENT_LEG.legIndex = legindex;
  $('#QuotingLegCfields').html(BILLING_popQuoteCfields_Legs(CURRENT_QUOTE, Leg, index, legindex));
  $('#QuotingLegCfields').enhanceWithin();
  BILLING_TotalCfieldItems('BMQ_CFL');
  //Todo update existing leg details for display editing
  BILLING_ShowLegData();
  var LegNum = parseInt(legindex) + 1;
  $('#BMQL_LegDetails').html(iTrans('Leg') + ' ' + LegNum + ' ' + iTrans('Details'));
}

function BILLING_UpdateBMQTimezoneTXT(e) {
  $('#BMQL_BillableNOTE12').html(iTrans('Time') + ' (' + e.value + ')');
  $('#BMQL_BillableNOTE11').html(iTrans('Date') + ' (' + e.value + ')');
}

function BILLING_ShowLegData() {
  var L = QUOTE_CURRENT_LEG;
  popTimezoneList('#BMQ_EntryTimezone');
  $('#BMQ_flightCatID').empty();
  $('#BMQ_flightCatID').append($('<option />').val(0).text('[Not Assigned]'));
  if (GROUP_DATA.FlightCategories.length > 0) {
    $('#BMQ_flightCatIDHLDR').show();
    for (var i in GROUP_DATA.FlightCategories) {
      var fc = GROUP_DATA.FlightCategories[i];
      $('#BMQ_flightCatID').append(
        $('<option />')
          .val(fc.PrimaryKey)
          .text((fc.fltcatcode == null ? '' : '[' + fc.fltcatcode + '] ') + fc.fltcatdesc)
      );
    }
  } else {
    $('#BMQ_flightCatIDHLDR').hide();
  }
  $('#BMQ_flightCatID').val(L.flightCatID).selectmenu('refresh');
  $('#BMQ_EntryTimezone').val(L.Timezone).selectmenu('refresh');

  $('#BMQL_BillableNOTE12').html(iTrans('Time') + ' (' + L.Timezone + ')');
  $('#BMQL_BillableNOTE11').html(iTrans('Date') + ' (' + L.Timezone + ')');
  $('#BMQL_FromICAO').val(L.FromICAO);
  $('#BMQL_FromICAO_Name').val(L.FromICAO_Name);
  $('#BMQL_FromDESC').val(L.FromDESC);
  $('#BMQL_DepartDate').val(L.DepartDate);
  $('#BMQL_DepartTime').val(L.DepartTime);
  $('#BMQL_TaxiTimeOrigin').val(L.TaxiTimeOrigin);

  $('#BMQL_ToICAO').val(L.ToICAO);
  $('#BMQL_ToICAO_Name').val(L.ToICAO_Name);
  $('#BMQL_ToDESC').val(L.ToDESC);
  $('#BMQL_ArrivalDate').val(L.ArrivalDate);
  $('#BMQL_ArrivalTime').val(L.ArrivalTime);
  $('#BMQL_TaxiTimeDest').val(L.TaxiTimeDest);

  $('#BMQL_ETE').val(L.ETE);
  $('#BMQL_Distance').val(L.Distance);
  $('#BMQL_Cruise').val(L.Cruise);
  $('#BMQL_Weight').val(L.Weight);
  if (
    L.Rate_Unit == 'lb' ||
    L.Rate_Unit == 'kg' ||
    L.Rate_Unit == 'litres' ||
    L.Rate_Unit == 'gallonsus' ||
    L.Rate_Unit == 'gallonsimp'
  ) {
    $('#BMQL_WeightHLDR').show();
    switch (L.Rate_Unit) {
      case 'lb':
      case 'kg':
        $('#BMQL_WeightTXT').html('Weight (' + L.Rate_Unit + ')');
        break;
      case 'litres':
        $('#BMQL_WeightTXT').html('Volume (Litres)');
        break;
      case 'gallonsus':
        $('#BMQL_WeightTXT').html('Volume (US Gallons)');
        break;
      case 'gallonsimp':
        $('#BMQL_WeightTXT').html('Volume (Imp Gallons)');
        break;
    }
  } else {
    $('#BMQL_WeightHLDR').hide();
  }
  $('#BMQL_PaxNum').val(L.PaxNum);
  $('#BMQL_Rate').val(L.Rate);
  $('#BMQL_Tax').val(L.Tax);
  $('#BMQL_Amount').html(L.Amount.toLocaleString('en-US', CUR));
  if (L.Billable == 1) {
    $('#BMQL_Billable').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BMQL_Billable').prop('checked', false).checkboxradio('refresh');
  }
  $('#BMQL_newLegSet')
    .prop('checked', L.newLegSet == 1 ? true : false)
    .checkboxradio('refresh');
  BILLING_CalculateACLegAmount();
  BILLING_Update_QuoteAirportTimezones();
}

function BILLING_SaveLegData(send = true, exit = false) {
  var L = QUOTE_CURRENT_LEG;
  L.Timezone = $('#BMQ_EntryTimezone').val();
  L.FromICAO = $('#BMQL_FromICAO').val();
  L.FromICAO_Name = $('#BMQL_FromICAO_Name').val();
  L.FromDESC = $('#BMQL_FromDESC').val();
  L.DepartDate = $('#BMQL_DepartDate').val();
  L.DepartTime = $('#BMQL_DepartTime').val();
  L.TaxiTimeOrigin = $('#BMQL_TaxiTimeOrigin').val();

  L.ToICAO = $('#BMQL_ToICAO').val();
  L.ToICAO_Name = $('#BMQL_ToICAO_Name').val();
  L.ToDESC = $('#BMQL_ToDESC').val();
  L.ArrivalDate = $('#BMQL_ArrivalDate').val();
  L.ArrivalTime = $('#BMQL_ArrivalTime').val();
  L.TaxiTimeDest = $('#BMQL_TaxiTimeDest').val();

  L.ETE = $('#BMQL_ETE').val();
  L.Distance = $('#BMQL_Distance').val();
  L.Weight = $('#BMQL_Weight').val();
  L.Cruise = $('#BMQL_Cruise').val();
  L.PaxNum = $('#BMQL_PaxNum').val();
  L.flightCatID = $('#BMQ_flightCatID').val();
  L.Tax = $('#BMQL_Tax').val();
  L.Rate = $('#BMQL_Rate').val();
  if ($('#BMQL_Billable').is(':checked')) {
    L.Billable = 1;
  } else {
    L.Billable = 0;
  }
  L.newLegSet = $('#BMQL_newLegSet').is(':checked') ? 1 : 0;

  try {
    L.Pax_Manifest = ITIN_Pax_Manifest.Pax_Legs[L.legIndex];
  } catch (e) {
    L.Pax_Manifest = [];
  }

  if (send) {
    if (CURRENT_QUOTE.Scheduler) {
      L.SCH_BookingPK = SCHEVENTDATA.PrimaryKey;
      L.SCH_AircraftPK = CURRENT_QUOTE.SCH_AC_DATA.ItemPrimaryKey;
    }
    ExchangeQuoteData(
      'UpdateQuoteLeg',
      L,
      function (data) {
        if (L.PrimaryKey == 0) {
          L.PrimaryKey = data[0].InsertPK;
        }
        if (L.legIndex != data[0].legIndex) {
          updateManifestArrayOrder(ITIN_Pax_Manifest.Pax_Legs, L.legIndex, data[0].legIndex);
        }
        if (!exit) {
          BILLING_QuoteLegEdit_returnPROMPT();
        }
      },
      exit
    );
  }
}

function updateManifestArrayOrder(paxArray, localIndex, newIndex) {
  try {
    var temp = JSON.parse(JSON.stringify(paxArray[localIndex]));
    var tempnew = JSON.parse(JSON.stringify(paxArray[newIndex]));
    paxArray[localIndex] = tempnew;
    paxArray[newIndex] = temp;
  } catch (e) {
    //do nothing
  }
}

function BILLING_GetQuoteHeader(CURRENT_QUOTE) {
  var clientPK = 0;
  if (CURRENT_QUOTE.Job != undefined) {
    if (CURRENT_QUOTE.Job.customCompany == 1) {
      clientPK = CURRENT_QUOTE.Job.ClientPK;
    }
  }

  cirroDB.query('BillingClient', 'clientPK = ?', clientPK, function (json_data) {
    if (json_data[0].data.QuoteTerms == null) {
      json_data[0].data.QuoteTerms = '';
    }
    if (CURRENT_QUOTE.Terms == null) {
      CURRENT_QUOTE.Terms = json_data[0].data.QuoteTerms;
    }
    BILLING_MakeQuoteHeader(json_data[0].data, CURRENT_QUOTE);
  });
}

function BILLING_GetCFselector_Legs(PerLeg, index, legindex) {
  var html =
    '<select onChange="BILLING_AddNewQuoteFieldIndex_Legs(this,' + index + ',' + legindex + ');" data-mini="true">';
  html += '<option value="-1">' + iTrans('Add New Quoting Field') + '</option>';
  for (var i in QUOTING_FIELDS) {
    var F = QUOTING_FIELDS[i];
    if (PerLeg != F.PerLeg) {
      continue;
    }
    html += '<option value="' + i + '">' + F.Name + '</option>';
  }
  html += '</select>';
  return html;
}

function BILLING_AddNewQuoteFieldIndex_Legs(e, index, legindex) {
  var F = QUOTING_FIELDS[e.value];
  F.Amount = parseFloat(F.DefaultPrice) * parseFloat(F.DefaultQty);
  F.Amount = Math.round(F.Amount * 100) / 100;
  BILLING_SaveLegData(false); //Silently save leg data so it doesn't get reset when adding a new quoting field
  if (index === false) {
    CURRENT_QUOTE.CommonLegs[legindex].Cfields.push(JSON.parse(JSON.stringify(F)));
    CURRENT_QUOTE.CurLeg = legIndex;
    $('#QuotingLegCfields').html(BILLING_popQuoteEditLegFields(CURRENT_QUOTE.CommonLegs[legindex], index, legindex));
  } else {
    CURRENT_QUOTE.Aircraft[index].Legs[legindex].Cfields.push(JSON.parse(JSON.stringify(F)));
    CURRENT_QUOTE.CurLeg = legIndex;
    $('#QuotingLegCfields').html(
      BILLING_popQuoteEditLegFields(CURRENT_QUOTE.Aircraft[index].Legs[legindex], index, legindex)
    );
  }

  $('#QuotingLegCfields').enhanceWithin();

  BILLING_TotalCfieldItems('BMQ_CFL');
}

function BILLING_QuoteCfieldUpdate_Leg(e, index, legindex) {
  if (index === false) {
    var F = CURRENT_QUOTE.CommonLegs[legindex].Cfields[e.name];
  } else {
    var F = CURRENT_QUOTE.Aircraft[index].Legs[legindex].Cfields[e.name];
  }

  F.DefaultQty = parseFloat($('#DefaultQty-BMQ_CFL-' + e.name).val());
  F.DefaultPrice = parseFloat($('#DefaultPrice-BMQ_CFL-' + e.name).val());
  F.Tax = parseFloat($('#DefaultTax-BMQ_CFL-' + e.name).val());
  F.Amount = Math.round(F.DefaultQty * F.DefaultPrice * 100) / 100;
  $('#Amount-BMQ_CFL-' + e.name).html(F.Amount.toLocaleString('en-US', CUR));
  BILLING_TotalCfieldItems('BMQ_CFL');
}

function BILLING_LegList_return() {
  $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
    changeHash: false,
    reverse: true,
  });
}

function BILLING_Finalize_return() {
  $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_ReloadQuoteLegs();
}

function BILLING_FinalizeQuote() {
  $.mobile.changePage($('#BILLING_FinalizeQuoteDetails'), {
    changeHash: false,
    reverse: false,
  });
  $('#BTNQuotetoBooking').prop('disabled', GROUP_DATA.FlightReports == 1 ? false : true);
  $('#BTNQuotetoScheduler').prop('disabled', GROUP_DATA.Scheduler == 1 ? false : true);
  $('#BILLING_ShowLegPricing').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_ShowCompanyNotes').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_ShowClientNotes').prop('checked', true).checkboxradio('refresh');
  if (CURRENT_QUOTE.Status == 'Add Aircraft') {
    CURRENT_QUOTE.Status = 'Finalize';
  }
  var html = BILLING_CreateQuoteHTML(true, true, true);
  $('#QuotingReviewContent').html(html);
  BILLING_GetQuoteHeader(CURRENT_QUOTE);
  BILLING_PopulateSignatureData(CURRENT_QUOTE);
}

function BILLING_returnSignatureQuoteReview() {
  var Q = CURRENT_QUOTE;
  var html = '';
  if (!QuotesignaturePad.isEmpty()) {
    if ($('#BILLING_QuoteSignatureName').val() == '') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: iTrans('Please type your full name in the space below your signature'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
      return;
    }

    var data = QuotesignaturePad.toDataURL('image/png');
    Q.signature = data;
    Q.signed_by = $('#BILLING_QuoteSignatureName').val();
    Q.AcceptedDateTime = new Date().toISOString().substr(0, 19).replace('T', ' ');
    Q.Locked = 1;
    Q.Approved = 1;
    Q.Status = 'Signed';
  } else {
    Q.signature = null;
    Q.signed_by = '';
  }

  if (Q.ReturnTo == 'ReviewQuote' || Q.ReturnTo == undefined) {
    $.mobile.changePage($('#BILLING_FinalizeQuoteDetails'), {
      changeHash: false,
      reverse: true,
    });
  }
  if (Q.ReturnTo == 'ViewQuote') {
    $.mobile.changePage($('#BILLING_ViewQuote'), {
      changeHash: false,
      reverse: true,
    });
  }
  BILLING_PopulateSignatureData(Q);
}

function BILLING_CreateQuoteInvoiceHTML(CURRENT_QUOTE, index, showCommonLegItems, showUniqueLegItems, showAllPricing) {
  var index = CURRENT_QUOTE.PrimaryKey;
  var html = '<div id="BILLING_QUOTE_HEADER-' + index + '"></div>';
  var Quote_Sub = 0;
  var Quote_Tax = 0;
  var Quote_Total = 0;
  html += '<h2>' + iTrans('Travel Details') + '</h2>';
  //Create commong leg details
  if (CURRENT_QUOTE.CommonLegs.length > 0) {
    var CommonAClist = '';
    var HasCommonAC = false;
    for (var a in CURRENT_QUOTE.Aircraft) {
      var A = CURRENT_QUOTE.Aircraft[a];
      if (A.UniqueLegs == 0) {
        HasCommonAC = true;
        CommonAClist += A.AC_Ident + ' (' + A.AcType + ') ';
      }
    }
    if (HasCommonAC) {
      CommonAClist = '<h4>' + CommonAClist + '</h4>';
      var CommonLegList = BILLING_GenerateLegList(
        CURRENT_QUOTE,
        BILLING_getTimezoneHTML(CURRENT_QUOTE),
        CURRENT_QUOTE.CommonLegs,
        false,
        false,
        CURRENT_QUOTE.Pax_Manifest.Pax_Legs
      );
      html += CommonAClist + CommonLegList;
    }
  }

  //Create Unique Leg Details
  if (CURRENT_QUOTE.Aircraft.length > 0) {
    var UniqueACIdent = '';
    var UniqueLegList = '';
    for (var a in CURRENT_QUOTE.Aircraft) {
      var A = CURRENT_QUOTE.Aircraft[a];
      if (A.UniqueLegs == 1) {
        if (A.Legs.length > 0) {
          UniqueACIdent += '<h4>' + A.AC_Ident + ' (' + A.AcType + ')</h4>';
          var UniqueLegList = BILLING_GenerateLegList(
            CURRENT_QUOTE,
            BILLING_getTimezoneHTML(A),
            A.Legs,
            false,
            false,
            A.Pax_Manifest.Pax_Legs
          );
          html += UniqueACIdent + UniqueLegList;
        }
      }
    }
  }

  html += BILLING_popQuoteCfields(CURRENT_QUOTE, false);
  html +=
    '<div id="BILLING_COMPANY_NOTES-' +
    index +
    '""><h3>' +
    iTrans('Notes') +
    '</h3>' +
    CURRENT_QUOTE.Notes.replace(/\n/g, '<br />') +
    '</div>';
  if (CURRENT_QUOTE.ClientNotes == null) {
    html += '<div id="BILLING_CLIENT_NOTES"></div>';
  } else {
    html +=
      '<div id="BILLING_CLIENT_NOTES-' +
      index +
      '""><h3>' +
      iTrans('Client Notes') +
      '</h3>' +
      CURRENT_QUOTE.ClientNotes.replace(/\n/g, '<br />') +
      '</div>';
  }
  //html += '<div id="BILLING_QUOTE_TERMS-' + index +'""></div>';
  //html += 'Terms and Conditions available upon request'
  html += '<div id="BILLING_CLIENT_SIGNATURE-' + index + '""></div>';
  return html;
}

function BILLING_SaveAndViewQuote() {
  ExchangeQuoteData('updateQuote', CURRENT_QUOTE, function (data) {
    $.mobile.changePage($('#BILLING_ViewQuote'), {
      changeHash: false,
      reverse: false,
    });
    BILLING_viewQuoteLoad();
  });
}

function BILLING_ViewFinalizeQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  CURRENT_QUOTE.ReturnTo = 'ReviewQuote';
  BILLING_FinalizeQuote();
}

//COMPLETE OTHER STATES OF QUOTING ALONG THE WAY
function BILLING_viewQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  CURRENT_QUOTE.ReturnTo = 'ViewQuote';
  $.mobile.changePage($('#BILLING_ViewQuote'), {
    changeHash: false,
    reverse: false,
  });
  BILLING_viewQuoteLoad();
}

function BILLING_viewQuoteLoad() {
  $('#QuotingReviewContent').html(''); //to prevent duplciate id's
  var html = BILLING_CreateQuoteHTML(true, true, true);
  $('#QuotingViewContent').html(html);
  BILLING_GetQuoteHeader(CURRENT_QUOTE);
  BILLING_PopulateSignatureData(CURRENT_QUOTE);
  $('#BILLING_ShowLegPricing2').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_ShowCompanyNotes2').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_ShowClientNotes2').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_ManualQuoteEmailList').val(CURRENT_QUOTE.QuoteEmail.replace(/;/g, ';\n')).keyup;
  BILLING_ShowLegCharges({ checked: $('#BILLING_ShowLegPricing2').is(':checked') });
}

function BILLING_completeQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you would like to mark this quote as completed?'),
    buttons: {
      [iTrans('Mark Completed')]: {
        click: function () {
          CURRENT_QUOTE.Completed = 1;
          ExchangeQuoteData('updateQuote', CURRENT_QUOTE, function (data) {
            BILLING_QuoteList_return();
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_invoiceQuote(index) {
  CURRENT_QUOTE = EXISTING_QUOTE_DATA[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you would like to mark this quote as Paid?'),
    buttons: {
      [iTrans('Mark Paid')]: {
        click: function () {
          CURRENT_QUOTE.Invoiced = 1;
          ExchangeQuoteData('updateQuote', CURRENT_QUOTE, function (data) {
            BILLING_QuoteList_return();
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_EmailToCient() {
  CURRENT_QUOTE.CustomEmailList = $('#BILLING_ManualQuoteEmailList').val();

  ExchangeQuoteData('SendQuoteEmails', CURRENT_QUOTE, function (data) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Success'),
      headerClose: false,
      buttonPrompt: iTrans('Emails have been sent successfully.'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            BILLING_QuoteList_return();
          },
        },
      },
    });
  });
}

function BILLING_QuoteToPDF() {
  //console.log(BILLING_INVOICE_DATA);
  GENERATE_PDF.filename = 'Quote_' + CURRENT_QUOTE.PrimaryKey;
  GENERATE_PDF.showPDF = 1;
  GENERATE_PDF.subject = 'Quote#' + CURRENT_QUOTE.PrimaryKey + ' - ' + CURRENT_QUOTE.Date;
  GENERATE_PDF.email = '0';
  GENERATE_PDF.silent = 0;
  GENERATE_PDF.Type = 'Quote';
  GENERATE_PDF.div = 'QuotingViewContent';
  $('#BTNQuotetoPDF').prop('disabled', true);
  GENERATE_PDF.ClientEmail = '0';
  GeneratePDF();
}

function BILLING_QuoteToFlightReport() {
  console.log('CURRENT_QUOTE', CURRENT_QUOTE);
  var Q = JSON.parse(JSON.stringify(CURRENT_QUOTE));
  var startDate = dateToStringObject(new Date()).substr(0, 10);
  if (Q.CommonLegs.length > 0) {
    startDate = Q.CommonLegs[0].DepartDate;
  } else {
    if (Q.Aircraft.length > 0) {
      if (Q.Aircraft[0].Legs.length > 0) {
        startDate = Q.Aircraft[0].Legs[0].DepartDate;
      }
    }
  }
  var html = '<label for="Quote2Booking_StartDate">' + iTrans('Start Date') + '</label>';
  html += '<input type="date" id="Quote2Booking_StartDate" value="' + startDate + '"/>';
  if (Q.Aircraft.length == 0) {
    alert(iTrans('No Aircraft in Quote'));
    return;
  } else {
    html += '<label for="Quote2Booking_Aircraft">' + iTrans('Aircraft') + '</label>';
    html += '<select id="Quote2Booking_Aircraft">';
    for (var i in Q.Aircraft) {
      var A = Q.Aircraft[i];
      html += '<option value="' + i + '">' + A.AC_Ident + '</option>';
    }
    html += '</select>';
    html += '<div id="Quote2Booking_AircraftTimezoneHLDR"></div>';
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Report Options'),
    headerClose: false,
    buttonPrompt: html,
    width: '400px',
    callbackOpen: function () {
      makeTimezoneInput(
        'Quote2Booking_AircraftTimezoneHLDR',
        'Quote2Booking_AircraftTimezone',
        iTrans('Report Timezone'),
        true,
        'UTC'
      );
    },
    buttons: {
      [iTrans('Create Flight Report')]: {
        click: function () {
          var newStartDate = $('#Quote2Booking_StartDate').val();
          Q.flightDate = newStartDate;
          Q.reportTimezone = $('#Quote2Booking_AircraftTimezone').val();
          var acIndex = $('#Quote2Booking_Aircraft').val();
          Q.reportAC = Q.Aircraft[acIndex];
          var SCH_Opts = {
            Type: 'Quote',
            Quote: Q,
          };
          TIMEZONE_Input_List['Quote2Booking_AircraftTimezoneHLDR'] = undefined;
          BILLING_NewFlightReport(false, false, SCH_Opts);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          TIMEZONE_Input_List['Quote2Booking_AircraftTimezoneHLDR'] = undefined;
          //Do nothing
        },
      },
    },
  });
}

function BILLING_QuoteToScheduler() {
  console.log('CURRENT_QUOTE', CURRENT_QUOTE);
  var Q = JSON.parse(JSON.stringify(CURRENT_QUOTE));
  var startDate = dateToStringObject(new Date()).substr(0, 10);
  if (Q.CommonLegs.length > 0) {
    startDate = Q.CommonLegs[0].DepartDate;
  }
  for (var i in Q.Aircraft) {
    var A = Q.Aircraft[i];
    if (A.Legs.length > 0) {
      if (A.Legs[0].DepartDate < startDate) {
        startDate = A.Legs[0].DepartDate;
      }
      continue;
    }
  }

  var html = '<label for="Quote2Booking_StartDate">' + iTrans('Flight Date') + '</label>';
  html += '<input type="date" id="Quote2Booking_StartDate" value="' + startDate + '"/>';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Booking Options'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Create Booking')]: {
        click: function () {
          var newStartDate = $('#Quote2Booking_StartDate').val();

          BILLING_MakeQuoteBooking(Q, startDate, newStartDate);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //Do nothing
        },
      },
    },
  });
}

function BILLING_MakeQuoteBooking(Quote, startDate, newStartDate) {
  var daysAdd = Math.round((new Date(newStartDate).getTime() - new Date(startDate).getTime()) / (1000 * 60 * 60 * 24));
  console.log('daysAdd', daysAdd);
  Quote.startDate = startDate;
  Quote.newStartDate = newStartDate;
  Quote.daysAdd = daysAdd;

  $.post(
    BASEURL + 'menu-Scheduler-AJAX.php?action=quoteBooking',
    {
      Quote: Quote,
    },
    function (data) {
      //console.log(data);
      if (data[0].ServerResponse == 'Success') {
        LIVE_SCH_SYNC = true;
        Manual_ResyncSCHEDULE();
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Booking Created Successfully.'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //do nothing
              },
            },
          },
        });
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed'),
          headerClose: false,
          buttonPrompt: iTrans('Error creating booking from quote.'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //do nothing
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Failed'),
      headerClose: false,
      buttonPrompt: iTrans('Error creating booking from quote.'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
  });
}
