var agreed = {};
function onAgreeToTerms(e, id) {
  let caller = $(e);
  let icon = caller.find('i');

  if (agreed.hasOwnProperty(id)) {
    icon.removeClass('fa-check-circle');
    icon.addClass('fa-circle');
    caller.removeClass('greenbg');
    delete agreed[id];
    return;
  }

  icon.removeClass('fa-circle');
  icon.addClass('fa-check-circle');
  agreed[id] = true;
  caller.addClass('greenbg');

  if (Object.values(agreed).length < 2) {
    return;
  }

  LOCALSTORAGE.TERMS = 1;
  localStorageDB.setItem('TERMS', 1, function (e) {
    verbose.log('DEBUG', 'Switching to Login Page');
    VueBridge.enterVue('/login');
    init();
  });
}
