/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
function WBMENU() {
  $('#ammendWBMENU').prop('disabled', true).checkboxradio('refresh');
  $('#installWBMENU').prop('disabled', true).checkboxradio('refresh');
  $('#tcWBMENU').prop('disabled', true).checkboxradio('refresh');
  $('#CHARTS').html('');
  $('#CHARTS2').html('');
  var value = this.value;
  switch (value) {
    case '0':
      $('#Content_WB_Rebuild').hide();
      editIEL();
      break;
    case '1':
      $('#Content_WB_Rebuild').hide();
      editAEL();
      break;
    case '2':
      $('#Content_WB_Rebuild').show();
      RuneditTC = true;
      editAEL();

      // editTC();
      break;
  }
}

var AC_data;
var AEL_data;
var TC_data;
var OPT_data;
var IEL_data;
var ACRebuild = false;
var ACRefresh = false;
var RuneditTC = false;
var Custom_ACTypes_data;
var InputStyle = 'style="border-color:#dddddd; background-color: #ffffff; border-width: 1px;"';

function aclist() {
  VueBridge.deprecationUtils.markDeprecated('menu-Maintenance-WB.acList', '2024-11-13', 'Phaminator100');
  $('#Content').html('');
  $.ajaxSetup({
    async: false,
  }); //Disable asyncronous ajax until automation complete follow script depends on it!
  $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_ACTypes', function (json_data) {
    ACTypes_data = json_data;
  });
  $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_Custom_ACTypes', function (json_data) {
    Custom_ACTypes_data = json_data;
  });
  $.ajaxSetup({
    async: true,
  });
  $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AC', function (json_data) {
    if (json_data[0].ServerResponse == 'No Aircraft') {
      $('#Content').html('<h1 class="limit-table">' + iTrans('There are no aircraft in the system') + '</h1>');
    } else {
      AC_data = json_data;
      $('#Content').html('');
      EL_data = json_data;
      $('#Content').append(
        '<div style="width: 100%; overflow: auto"><table id="AC_Table" data-filter="true" class="limit-table last-td-fill nowrap">' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="1">' +
          iTrans('Ident') +
          '</th>' +
          '<th data-priority="2">' +
          iTrans('Model') +
          '</th>' +
          '<th data-priority="3">' +
          iTrans('Serial') +
          '</th>' +
          '<th>Base</th>' +
          '<th data-priority="3">' +
          iTrans('Service Note') +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>'
      );
      for (i in AC_data) {
        var Serviceable = '';
        if (AC_data[i].Serviceable == 0) Serviceable = 'redbg';
        if (AC_data[i].Hibrenated == 0) {
          if (AC_data[i].AcType == 'CUSTOM')
            $('#Content tr:last').after(
              "<tr><td><div class='vertical-center-container'></div><button id='AC" +
                i +
                "' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg'/> " +
                AC_data[i].ident +
                '</div></button><button onClick="copyAircraft(' +
                i +
                ');" class="action-btn" ><img src="./images/copyicon.png" width="20px" height="20px" /></button></div></td><td>' +
                AC_data[i].Custom_Type +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].BASED_IN +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
          else
            $('#Content tr:last').after(
              "<tr><td><div class='vertical-center-container'><button id='AC" +
                i +
                "' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg'/> " +
                AC_data[i].ident +
                '</div></button><button onClick="copyAircraft(' +
                i +
                ');" class="action-btn" ><img src="./images/copyicon.png" width="20px" height="20px" /></button></div></td><td>' +
                AC_data[i].AcType +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].BASED_IN +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
          $('#AC' + i).click(editAC1);
        } else {
          if (AC_data[i].AcType == 'CUSTOM')
            $('#Content tr:last').after(
              "<tr><td><button id='AC" +
                i +
                "' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true' disabled>" +
                AC_data[i].ident +
                ' (Hibernated)</button></td><td>' +
                AC_data[i].Custom_Type +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].BASED_IN +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
          else
            $('#Content tr:last').after(
              "<tr><td><button id='AC" +
                i +
                "' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true' disabled> " +
                AC_data[i].ident +
                ' (Hibernated)</button></td><td>' +
                AC_data[i].AcType +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].BASED_IN +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
        }
      }
      $('#Content tr:last').after('</tbody></table></div>');
      $('#Content').enhanceWithin();
    }
  });
}

function copyAircraft(index) {
  var fromIdent = AC_data[index].ident;
  var html =
    '<h3>' +
    iTrans(
      'This operation is destructive and cannot be undone.  If an existing aircraft ident is used it will be entirely overwritten!'
    ) +
    '</h3>';
  html +=
    '<label for="copyAircraftToIdent">' +
    iTrans('Enter New/Existing Aircraft Ident') +
    '</label><input type="text" id="copyAircraftToIdent"/>';
  html +=
    '<label for="copyAircraftToIdent_confirm">' +
    iTrans('Confirm New/Existing Aircraft Ident') +
    '</label><input type="text" id="copyAircraftToIdent_confirm" />';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Copy') + ': ' + fromIdent,
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('OK')]: {
        click: function () {
          if ($('#copyAircraftToIdent_confirm').val() != $('#copyAircraftToIdent').val()) {
            alert('Cannot Copy New/Existing Aircraft Idents Not Identical');
            return;
          }
          if (fromIdent == $('#copyAircraftToIdent').val()) {
            alert('Cannot Copy The Same Aircraft');
            return;
          }
          $.mobile.loading('show', {
            theme: 'a',
          });
          var toIdent = $('#copyAircraftToIdent').val();
          $.getJSON(
            BASEURL +
              'menu-Maintenance-AJAX.php?action=copyAircraft&fromIdent=' +
              encodeURIComponent(fromIdent) +
              '&toIdent=' +
              encodeURIComponent(toIdent),
            function (json_data) {
              $.mobile.loading('hide');
              if (json_data.length > 0) {
                if (json_data[0].ServerResponse != undefined) {
                  //status could be offline/Not Logged In/Uncaught Exception
                  if (json_data[0].ServerResponse == 'Offline') {
                    alert('<h1>' + iTrans('Offline Cannot Get Hobbs') + '</h1>'); //retrieve old data from database
                  }
                  if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'gethobbscur');
                  if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                  if (json_data[0].ServerResponse == 'Success') {
                    $('<div>').simpledialog2({
                      mode: 'button',
                      animate: false,
                      headerText: iTrans('Success'),
                      headerClose: false,
                      buttonPrompt: iTrans(
                        'Aircraft copied successfully. Please rebuild the aircraft and verify the W&B.'
                      ),
                      buttons: {
                        [iTrans('OK')]: {
                          click: function () {
                            aclist();
                          },
                        },
                      },
                    });
                  }
                }
              } else {
                alert('Failed to copy aircraft!');
              }
            }
          ).fail(function (jqXHR, status, error) {
            //Fail silently and populate with offline holders
            $.mobile.loading('hide');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function checkCustom(k) {
  console.log($('#KFM_WB_AC-AcType-' + k).val());
  var val = $('#KFM_WB_AC-AcType-' + k).val();
  if (WANDB == '1') $('#CAcType').hide();
  else {
    if (val == 'CUSTOM') $('#CAcType').show();
    else $('#CAcType').hide();
  }
}

function createCustomType() {
  $.mobile.changePage($('#Custom_WB_Page1'), {
    reverse: false,
    changeHash: false,
  });
  loadCWB();
}

function CustomLabel() {
  console.log('Customlabel');
  if (this.value == '') $('#NCT_Select').html(iTrans('New Custom Type'));
  else $('#NCT_Select').html(iTrans('Edit Custom Type'));
  CurCustomLabel = this.value;
}
var CurCustomLabel = '';
var WBUnitLbl = {
  Weight: 'lbs',
  Length: 'in',
  Arm: 'in',
  Moment: 'lb-in',
  Rate: 'lbs/h',
  Metric: false,
  ToWeight: 2.20462,
  ToLength: 0.0393701,
  ToRate: 0.453592,
  Type: 'Imperial',
};

function UpdateWBUnitLBL(type) {
  if (type == 'Metric') {
    WBUnitLbl.Rate = 'kg/h';
    WBUnitLbl.Weight = 'kg';
    WBUnitLbl.Moment = 'kg-mm';
    WBUnitLbl.Arm = 'mm';
    WBUnitLbl.Length = 'mm';
    WBUnitLbl.Metric = true;
    WBUnitLbl.ToWeight = 0.453592;
    WBUnitLbl.ToLength = 25.4;
    WBUnitLbl.ToRate = 0.453592;
    WBUnitLbl.Type = 'Metric';
  } else {
    WBUnitLbl = {
      Weight: 'lbs',
      Length: 'in',
      Arm: 'in',
      Moment: 'lb-in',
      Rate: 'lbs/h',
      Metric: false,
      ToWeight: 2.20462,
      ToLength: 0.0393701,
      ToRate: 2.20462,
      Type: 'Imperial',
    };
  }
}

function getCAMP_stateColor(state) {
  try {
    let str = state.toLowerCase();
    if (str.includes('yellow_tolerance')) return 'orange';
    if (str.includes('yellow')) return 'yellow';
    if (str.includes('red')) return 'red';
    if (str.includes('green')) return 'green';
    if (str.includes('orange')) return 'orange';
    return 'white';
  } catch (e) {
    return 'white';
  }
}
function getCAMP_UnitTXT(unit) {
  let CampUnits = {
    '(AFL)': { Description: 'LANDING (as tracked for engines)', Remark: 'Unit of Measure' },
    ACCCYC: { Description: 'ACCUMULATED CYCLES', Remark: 'Unit of Measure' },
    ACH: { Description: 'AIR CONDITIONING HOURS', Remark: 'Unit of Measure' },
    AFL: { Description: 'AIRFRAME/AIRCRAFT LANDINGS', Remark: 'Unit of Measure' },
    APH: { Description: 'APU HOURS (per airframe)', Remark: 'Unit of Measure' },
    APRMPR: { Description: 'APRMPR', Remark: 'Unit of Measure' },
    APS: { Description: 'APU STARTS (per airframe)', Remark: 'Unit of Measure' },
    'APU HRS': { Description: 'APU HOURS (per APU)', Remark: 'Unit of Measure' },
    APUS: { Description: 'APU STARTS (per APU)', Remark: 'Unit of Measure' },
    ARIN: { Description: 'AIRFRAME RETIREMENT INDEX NUMBER', Remark: 'Unit of Measure' },
    AROT: { Description: 'AUTO ROTATIONS', Remark: 'Unit of Measure' },
    ASTC: { Description: 'AS TORQUE CYCLES', Remark: 'Unit of Measure' },
    ATEC: { Description: 'ACCUMULATED TOTAL ENGINE CYCLES', Remark: 'Unit of Measure' },
    ATOW: { Description: 'AUTO ROTATIONS', Remark: 'Unit of Measure' },
    ATTCS: { Description: 'AUTOMATIC TAKEOFF THRUST CONTROL SYSTEM', Remark: 'Unit of Measure' },
    BMH: { Description: 'BLOWER MOTOR HOURS', Remark: 'Unit of Measure' },
    C1C: { Description: 'GAS GENERATOR CYCLES', Remark: 'Unit of Measure' },
    C2C: { Description: 'TURBINE CYCLES', Remark: 'Unit of Measure' },
    CATA: { Description: 'CAT. A TRAINING OPERATIONS HOURS', Remark: 'Unit of Measure' },
    CDMHRS: { Description: 'COMPRESSOR DRIVE MODULE HOURS', Remark: 'Unit of Measure' },
    CGL: { Description: 'CESSNA GEAR LANDING', Remark: 'Unit of Measure' },
    CHARGEHOURS: { Description: 'CHOCK TO CHOCK', Remark: 'Unit of Measure' },
    CHCY: { Description: 'CARGO HOOK CYCLES', Remark: 'Unit of Measure' },
    CHCYC: { Description: 'CARGO HOOK CYCLES (VIRTUAL)', Remark: 'Unit of Measure' },
    CHHRS: { Description: 'CARGO HOOK HOURS', Remark: 'Unit of Measure' },
    CHOP: { Description: 'CARGO HOOK OPERATION PENALTY', Remark: 'Unit of Measure' },
    CHSS: { Description: 'CARGO HOOK SUSPENSION SYSTEM HOURS', Remark: 'Unit of Measure' },
    CHSY: { Description: 'CARGO HOOK SUSPENSION SYSTEM CYCLES', Remark: 'Unit of Measure' },
    CLFT: { Description: 'CARGO LIFTS', Remark: 'Unit of Measure' },
    COMPCYC: { Description: 'ENGINE COMPRESSION CYCLES', Remark: 'Unit of Measure' },
    CRD: { Description: 'CREEP DAMAGE', Remark: 'Unit of Measure' },
    CTBCL: { Description: 'CT BLADE CREEP LIFE HOURS', Remark: 'Unit of Measure' },
    CTCCYC: { Description: 'COMPRESSOR TURBINE COVER CYCLE', Remark: 'Unit of Measure' },
    CTCRD: { Description: 'CTCRD', Remark: 'Unit of Measure' },
    CTCYC: { Description: 'COMPRESSOR TURBINE CYCLES', Remark: 'Unit of Measure' },
    CTDCYC: { Description: 'COMPRESSOR TURBINE DISC CYCLE', Remark: 'Unit of Measure' },
    CYC1: { Description: 'CYCLES1', Remark: 'Unit of Measure' },
    CYC2: { Description: 'CYCLES2', Remark: 'Unit of Measure' },
    DAYS: { Description: 'CALENDAR', Remark: 'Unit of Measure' },
    DCYC: { Description: 'DRY CYCLES', Remark: 'Unit of Measure' },
    DFLAPP: { Description: 'DRY FILM LUBRICANT APPLICATIONS', Remark: 'Unit of Measure' },
    ECU: { Description: 'ELECTRONIC CONTROL UNIT HOURS', Remark: 'Unit of Measure' },
    ECYC: { Description: 'ENGINE CYCLES SINCE NEW', Remark: 'Unit of Measure' },
    EFL: { Description: 'ENGINE FAILURE LANDING', Remark: 'Unit of Measure' },
    EGE: { Description: 'EMERGENCY GEAR EXTENSIONS', Remark: 'Unit of Measure' },
    EHP: { Description: 'ENGINE HOVER PERFORMANCE TIME', Remark: 'Unit of Measure' },
    ELO: { Description: 'EXTERNAL LOAD OPERATIONS', Remark: 'Unit of Measure' },
    EMH: { Description: 'EVAPORATOR MOTOR HOURS', Remark: 'Unit of Measure' },
    EMREL: { Description: 'EMERGENCY GEAR RELEASE', Remark: 'Unit of Measure' },
    ENC: { Description: 'ENGINE CYCLES', Remark: 'Unit of Measure' },
    ENS: { Description: 'ENGINE STARTS', Remark: 'Unit of Measure' },
    EROPL: { Description: 'NON-ETOPS EXTENDED RANGE OPERATION LEG', Remark: 'Unit of Measure' },
    ETOPL: { Description: 'ETOPS LEG', Remark: 'Unit of Measure' },
    EXC: { Description: 'ENGINE EXCURSIONS', Remark: 'Unit of Measure' },
    FDAY: { Description: 'FLOAT DAYS', Remark: 'Unit of Measure' },
    FFC: { Description: 'FIRE FIGHTING CYCLES', Remark: 'Unit of Measure' },
    FFH: { Description: 'FIRE FIGHTING HOURS', Remark: 'Unit of Measure' },
    FGL: { Description: 'FLOAT GEAR LANDINGS', Remark: 'Unit of Measure' },
    FH: { Description: 'FLYING HOURS', Remark: 'Unit of Measure' },
    FLAPCYC: { Description: 'FLAP CYCLES', Remark: 'Unit of Measure' },
    FLCY: { Description: 'FLIGHT CYCLES', Remark: 'Unit of Measure' },
    FLIR: { Description: 'FORWARD LOOKING INFRARED HOURS', Remark: 'Unit of Measure' },
    FLTS: { Description: 'FLIGHTS', Remark: 'Unit of Measure' },
    FLTS1: { Description: 'FLIGHTS1', Remark: 'Unit of Measure' },
    FLTS2: { Description: 'FLIGHTS2', Remark: 'Unit of Measure' },
    FOLD: { Description: 'FODING/UNFOLDING PROCEDURE', Remark: 'Unit of Measure' },
    FRD: { Description: 'FIRE RETARDANT', Remark: 'Unit of Measure' },
    FWL: { Description: 'FLOAT WATER LANDINGS', Remark: 'Unit of Measure' },
    GBH: { Description: 'GEARBOX HOURS', Remark: 'Unit of Measure' },
    HBS: { Description: 'HOBBS METER HOURS', Remark: 'Unit of Measure' },
    HEC: { Description: 'HECTOMETERS', Remark: 'Unit of Measure' },
    HECHRS: { Description: 'HUMAN EXTERNAL CARGO HOURS', Remark: 'Unit of Measure' },
    HECLFT: { Description: 'HUMAN EXTERNAL CARGO LIFTS', Remark: 'Unit of Measure' },
    HLFTS: { Description: 'HOIST LIFTS', Remark: 'Unit of Measure' },
    HRS: { Description: 'HOURS', Remark: 'Unit of Measure' },
    HSCY: { Description: 'HOISTING CYCLES', Remark: 'Unit of Measure' },
    HSCYP: { Description: 'HOIST CYCLES IN PENALTY CG REGION', Remark: 'Unit of Measure' },
    HSH: { Description: 'HOISTING HOURS', Remark: 'Unit of Measure' },
    HUCY: { Description: 'HUMIDIFIER CYCLES', Remark: 'Unit of Measure' },
    HVH: { Description: 'HEAT AND VENTILATION HOURS', Remark: 'Unit of Measure' },
    HW334X: { Description: 'HIGH WIND STARTS OR STOPS 33-45', Remark: 'Unit of Measure' },
    HW4X60: { Description: 'HIGH WIND STARTS OR STOPS 45-60', Remark: 'Unit of Measure' },
    HWL: { Description: 'HIGH WIND LANDING', Remark: 'Unit of Measure' },
    HWSR: { Description: 'HIGH WIND START', Remark: 'Unit of Measure' },
    HWST: { Description: 'HIGH WIND STOP', Remark: 'Unit of Measure' },
    HWTO: { Description: 'HIGH WIND TAKE OFF', Remark: 'Unit of Measure' },
    ICE: { Description: 'ICING CONDITIONS DURING FLIGHT', Remark: 'Unit of Measure' },
    IMPCYC: { Description: 'IMPELLOR CYCLES', Remark: 'Unit of Measure' },
    KG1200: { Description: 'LOAD ABOVE 1200 KG', Remark: 'Unit of Measure' },
    KG6400: { Description: 'HELICOPTER LIFTS', Remark: 'Unit of Measure' },
    LCF1: { Description: 'CT7-2A FULL CYCLES', Remark: 'Unit of Measure' },
    LCF2: { Description: 'CT7-2A PARTIAL CYCLES', Remark: 'Unit of Measure' },
    LDLFT: { Description: 'LOAD LIFTS', Remark: 'Unit of Measure' },
    LF1: { Description: '300 - 700KG PAYLOAD', Remark: 'Unit of Measure' },
    LF2: { Description: '700 - 1000KG PAYLOAD', Remark: 'Unit of Measure' },
    LF3: { Description: '1000 - 1200KG PAYLOAD', Remark: 'Unit of Measure' },
    LF4: { Description: '1000 - 1200KG PAYLOAD', Remark: 'Unit of Measure' },
    LF5: { Description: '1400 - 1600KG PAYLOAD', Remark: 'Unit of Measure' },
    LGEXT: { Description: 'LANDING GEAR EXTENSION', Remark: 'Unit of Measure' },
    LGRET: { Description: 'LANDING GEAR RETRACTION', Remark: 'Unit of Measure' },
    LHCY: { Description: 'LOAD HOOK CYCLES', Remark: 'Unit of Measure' },
    LIFT: { Description: 'LIFT', Remark: 'Unit of Measure' },
    LLFT: { Description: 'LOGGING TAKEOFF LIFT', Remark: 'Unit of Measure' },
    LOG: { Description: 'LOGGING OPERATION', Remark: 'Unit of Measure' },
    LRF: { Description: 'LOAD RELEASE IN FLIGHT', Remark: 'Unit of Measure' },
    LRG: { Description: 'LOAD RELEASE ON GROUND', Remark: 'Unit of Measure' },
    MET: { Description: 'CABLE LENGTH METERS', Remark: 'Unit of Measure' },
    MGBTC: { Description: 'MGEARBOX TORQUE CYCLES', Remark: 'Unit of Measure' },
    MMAC: { Description: 'MULTI MISSION AIRCRAFT SYSTEM CYCLES', Remark: 'Unit of Measure' },
    MOS: { Description: 'CALENDAR MONTHS', Remark: 'Unit of Measure' },
    MPH: { Description: 'MED PACK HOURS', Remark: 'Unit of Measure' },
    MSC: { Description: 'MISCELLANNEOUS', Remark: 'Unit of Measure' },
    MTOW: { Description: 'MAX TAKE OFF WEIGHT HOURS', Remark: 'Unit of Measure' },
    MTOWL: { Description: 'MAX TAKE OFF WEIGHT LANDINGS', Remark: 'Unit of Measure' },
    MTWHRS: { Description: 'MAX TAKE OFF WEIGHT HRS', Remark: 'Unit of Measure' },
    N1: { Description: 'N1 CYCLES', Remark: 'Unit of Measure' },
    N2: { Description: 'N2 CYCLES', Remark: 'Unit of Measure' },
    NG: { Description: 'NG CYCLES', Remark: 'Unit of Measure' },
    NGC1: { Description: 'NG CYCLES/GAS', Remark: 'Unit of Measure' },
    NLFT: { Description: 'NORMAL TAKEOFF LIFT', Remark: 'Unit of Measure' },
    NP: { Description: 'NP CYCLES', Remark: 'Unit of Measure' },
    NPC2: { Description: 'NP CYCLES/TURBINE', Remark: 'Unit of Measure' },
    OEI: { Description: 'ONE ENGINE INOPERATIVE EVENT', Remark: 'Unit of Measure' },
    OPH: { Description: 'OPERATING HOURS', Remark: 'Unit of Measure' },
    PRC: { Description: 'PROPELLER CYCLES', Remark: 'Unit of Measure' },
    PRESSCYC: { Description: 'PRESSURIZATION CYCLES', Remark: 'Unit of Measure' },
    PT1CYC: { Description: 'POWER TURBINE 1 CYCLE (FIRST STAGE)', Remark: 'Unit of Measure' },
    PT2CYC: { Description: 'POWER TURBINE 2 CYCLE (SECOND STAGE)', Remark: 'Unit of Measure' },
    PTBCL: { Description: 'PT BLADE CREEP LIFE HOURS', Remark: 'Unit of Measure' },
    PTCRD: { Description: 'PTCRD', Remark: 'Unit of Measure' },
    PTCYC: { Description: 'POWER TURBINE CYCLES', Remark: 'Unit of Measure' },
    RBC: { Description: 'ROTOR BRAKE CYCLES', Remark: 'Unit of Measure' },
    RC: { Description: 'ROPING CYCLES', Remark: 'Unit of Measure' },
    RDSF: { Description: 'ROUNDS FIRED', Remark: 'Unit of Measure' },
    RETEX: { Description: 'LANDING GEAR RETRACTION/EXTENSION', Remark: 'Unit of Measure' },
    REX: { Description: 'RATING EXCURSIONS', Remark: 'Unit of Measure' },
    RHCY: { Description: 'REMOTE HOOK CYCLES', Remark: 'Unit of Measure' },
    RHOP: { Description: 'RESCUE HOIST OPERATION PENALTY', Remark: 'Unit of Measure' },
    RIN: { Description: 'RETIREMENT INDEX NUMBER', Remark: 'Unit of Measure' },
    RIN2: { Description: 'RETIREMENT INDEX NUMBER', Remark: 'Unit of Measure' },
    RINV: { Description: 'RIN VIRTUAL', Remark: 'Unit of Measure' },
    ROL: { Description: 'RUN ON LANDINGS', Remark: 'Unit of Measure' },
    RON: { Description: 'RUN ON', Remark: 'Unit of Measure' },
    RSTTYPE2: { Description: 'ROTOR SPEED TRANSIENT TYPE 2 EXCEEDANCE', Remark: 'Unit of Measure' },
    RTCY: { Description: 'ROTOR CYCLES', Remark: 'Unit of Measure' },
    RTHRS: { Description: 'ROTOR HOURS', Remark: 'Unit of Measure' },
    RTO: { Description: 'RAPID TAKE OFFS', Remark: 'Unit of Measure' },
    SAL: { Description: 'STEEP APPROACH LANDING', Remark: 'Unit of Measure' },
    SC: { Description: 'SLING CYCLE', Remark: 'Unit of Measure' },
    SDLFT: { Description: 'SANDY OR DUSTY ENVIRONMENT LIFT OPERATIONS', Remark: 'Unit of Measure' },
    SEC1: { Description: 'INCREMENTAL COUNTS', Remark: 'Unit of Measure' },
    SEC2: { Description: 'INCREMENTAL COUNTS', Remark: 'Unit of Measure' },
    SLDL: { Description: 'SAND LADEN AND/OR DUST LADEN', Remark: 'Unit of Measure' },
    SLHRS: { Description: 'SEARCHLIGHT HOURS', Remark: 'Unit of Measure' },
    SORTIE: { Description: 'SORTIE', Remark: 'Unit of Measure' },
    STGP: { Description: 'START GRIPS', Remark: 'Unit of Measure' },
    TC: { Description: 'TORQUE CYCLE', Remark: 'Unit of Measure' },
    TCH: { Description: 'TACHOMETER HOURS', Remark: 'Unit of Measure' },
    TE: { Description: 'TORQUE EVENT', Remark: 'Unit of Measure' },
    TRCHN: { Description: 'TIRE CHANGES', Remark: 'Unit of Measure' },
    TRCHx: { Description: 'No. X, TIRE CHANGES', Remark: 'Unit of Measure' },
    UBF: { Description: 'UNPRESSURIZED BAGGAGE COMPARTMENT FLIGHTS', Remark: 'Unit of Measure' },
    UPL: { Description: 'UNPAVED RUNWAY LANDINGS', Remark: 'Unit of Measure' },
    VCHRS: { Description: 'VERY COLD WEATHER OPERATING HOURS', Remark: 'Unit of Measure' },
    WB: { Description: 'WATER BOMBING', Remark: 'Unit of Measure' },
  }; //end of CAMP-UNIT-OF-MEASURE
  try {
    return CampUnits[unit].Description;
  } catch (e) {
    return unit;
  }
}
function populateCampTimes(acident) {
  signatureDATA.data = '';
  signatureDATA.text = '';
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#KFM_WB_CAMP_Times').html('<h2>' + iTrans('Populating') + '</h2>');

  var Note = 'Unknown';
  //CURRENT_FLIGHT_REPORT.Aircraft = "ES-DEMO1"; //todo remove this in production
  $.getJSON(
    BASEURL + 'CAMP-AJAX.php?action=getIdent&ident=' + encodeURIComponent(acident) + '&FR_PK=0',
    function (json_data) {
      $('#Billing_CompleteAircraftTimes').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="populateCampTimes(AC_data[ACIndex].ident);" data-icon="refresh">Try Again</button>';
          $('#KFM_WB_CAMP_Times').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!</h2><b>' +
              iTrans(
                'There was either an error contacting the CAMP Server OR the requested aircraft does not exist in the maintenance system'
              ) +
              '.</b>' +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#KFM_WB_CAMP_Times').enhanceWithin();
        }
        if (json_data.data.Status == 'Not Found') {
          $('#KFM_WB_CAMP_Times').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!<br />' +
              iTrans('The requested aircraft does not exist in the maintenance system') +
              '.</h2>'
          );
          $('#KFM_WB_CAMP_Times').enhanceWithin();
        }
        if (json_data.data.Status == 'SoapFault') {
          $('#KFM_WB_CAMP_Times').html(
            '<h2>' + iTrans('SoapFault') + '</h2><b>' + json_data.data.faultstring + '.</b>'
          );
          $('#KFM_WB_CAMP_Times').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        var data = json_data.data;

        AC_TECH_DISPATCH.RAW = json_data;
        AC_TECH_DISPATCH.UPDATE = JSON.parse(JSON.stringify(json_data)); //make copy of object for update

        var html = CAMP_EarliestDueTimes_HTML(data);

        //transfer traxxall times here
        html += '<h3>' + iTrans('Override Aircraft Times') + '</h3>';

        html +=
          '<table class="limit-table first-td-fill"><thead><tr><th>Description</th><th>Last Reported</th><th>Position</th><th>Unit</th><th>Value</th><th>New Value</th></tr></thead><tbody>';
        var Actuals = data.latestAircraftTimes.timesInfo;
        for (var i in Actuals) {
          var I = Actuals[i];
          var InputHtml = '';

          switch (I.unit) {
            case 'LDG':
              InputHtml +=
                "<input type='number' id='WBFR_" +
                i +
                "' value='" +
                I.lastReportedValue +
                "' name='" +
                i +
                "' onChange='WB_SetCAMP_Data(this);'  style='width:90px;' step='1' onclick='this.select();' />";
              break;
            case 'HRS':
              InputHtml +=
                "<input type='number' id='WBFR_" +
                i +
                "' value='" +
                (I.lastReportedValue / 60).toFixed(1) +
                "' name='" +
                i +
                "' onChange='WB_SetCAMP_Data(this);'  style='width:90px;' onclick='this.select()' step='.01' />";
              break;
            default:
              InputHtml +=
                "<input type='number' step='1' id='WBFR_" +
                i +
                "' value='" +
                I.lastReportedValue +
                "' name='" +
                i +
                "' onChange='WB_SetCAMP_Data(this);'  style='width:90px;' step='1' onclick='this.select();' />";

              break;
          }
          if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value == undefined) {
            AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value = I.lastReportedValue;
          }
          html +=
            '<tr><td>' +
            I.profileType +
            ' - ' +
            getCAMP_UnitTXT(I.unit) +
            '</td><td>' +
            I.lastReportedDate +
            '</td><td>' +
            I.position +
            '</td><td style="text-align: center;">' +
            I.unit +
            '</td><td style="text-align: right;">' +
            (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS'
              ? (I.lastReportedValue / 60).toFixed(1)
              : I.lastReportedValue) +
            '</td><td><div class="vertical-center-container">' +
            InputHtml +
            '</div></td></tr>';
        }
        html += '</tbody></table>';
        html += '<label for="WBFR_Note">' + iTrans('Reason for Aircraft Times Override') + '</label>';
        html += '<textarea id="WBFR_Note"></textarea>';

        html +=
          '<div id="MaintSignatureHLDR"><button onClick="ACTIMES_OPEN_SIGNATURE(true)" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button></div>';
        html +=
          '<div id="MaintUpdateTimesHLDR" style="display:none;"><button onClick="OverrideAircraftTimes_CAMP(\'' +
          acident +
          '\')" data-icon="check" class="greenbg" id="MaintUpdateAircraftTimes_CAMPBTN" >' +
          iTrans('Update Aircraft Times') +
          '</button></div>';

        $('#KFM_WB_CAMP_Times').html(html);
        $('#KFM_WB_CAMP_Times').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="populateCampTimes(AC_data[ACIndex].ident);" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#KFM_WB_CAMP_Times').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved!') +
        '</h2><b>' +
        iTrans(
          'There was either an error contacting the Camp Server OR the requested aircraft does not exist in the maintenance system.'
        ) +
        '</b><br />' +
        iTrans(
          'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
        ) +
        refreshBTN +
        '<br /><br />' +
        iTrans('Response') +
        ': ' +
        Note
    );
    $('#KFM_WB_CAMP_Times').enhanceWithin();
  });

  //populate div KFM_WB_CAMP_Times
}

function CAMP_EarliestDueTimes_HTML(data) {
  var earliestDueTimes = data.aicraftState.earliestDueTimes;
  var html = '<h2>' + acIdent + ' - ' + iTrans('Technical Records') + '</h2>';
  html +=
    '<h3 style="background-color: ' +
    getCAMP_stateColor(data.aicraftState.state) +
    '">' +
    iTrans('Earliest Due Times') +
    '</h3>';
  html += '<table class="limit-table"><thead><tr><th>Item</th><th>Due Value</th></tr></thead><tbody>';
  var dueTasks = [];
  var curHours = 0;
  var curCycles = 0;
  var todayDate = new Date();
  for (var t in earliestDueTimes) {
    var T = earliestDueTimes[t];
    dueTasks = dueTasks.concat(T.dueTasks);
    if (T == null) continue; //i've seen these null for some reason follow up with Steve
    if (T.unit == 'Date') {
      html += '<tr><td>' + T.unit + '</td><td>' + T.value + ' (' + getDaysTillDue(T.value) + ')</td></tr>';
    } else {
      if (T.unit == 'HRS' || T.unit == 'Hours') {
        curHours = roundNum(parseFloat(T.value) / 60, 1);
      }
      if (T.unit == 'ENC' || T.unit == 'Cycles') {
        curCycles = parseFloat(T.Value);
      }
      html +=
        '<tr><td>' +
        getCAMP_UnitTXT(T.unit) +
        '</td><td>' +
        (T.unit == 'HRS' || T.unit == 'Hours' ? (parseFloat(T.value) / 60).toFixed(1) : T.value) +
        '</td></tr>';
    }
  }
  html += '</tbody></table>';

  if (dueTasks.length > 0) {
    html +=
      '<h3 style="background-color: ' +
      getCAMP_stateColor(data.aicraftState.state) +
      '">' +
      iTrans('Next Due Tasks') +
      '</h3>';
    html +=
      '<table class="limit-table"><thead><tr><th>Description</th><th>Due Unit</th><th>Due Value</th><th>Max Ext</th></tr></thead><tbody>';
    for (var i in dueTasks) {
      var I = dueTasks[i];
      var nextDue = I.nextDue;
      var nextDueUnit = '';
      var nextDueValue = '';
      var lineClass = '';
      var maxDueValue = '';
      for (var n in nextDue) {
        var ND = nextDue[n];
        nextDueUnit += (n > 0 ? '<br />' : '') + getCAMP_UnitTXT(ND.unit);
        nextDueValue +=
          (n > 0 ? '<br />' : '') +
          (ND.unit == 'Hours' || ND.unit == 'HRS' ? (parseFloat(ND.value) / 60).toFixed(1) : ND.value);
        if (I.maxNextDue.length > 0) {
          for (var m in I.maxNextDue) {
            var MD = I.maxNextDue[m];
            if (MD.unit == ND.unit) {
              switch (ND.unit) {
                case 'Hours':
                case 'HRS':
                  if (roundNum(parseFloat(ND.value) / 60, 1) < curHours) {
                    lineClass = 'orangebg';
                  }
                  maxDueValue = roundNum(parseFloat(MD.value) / 60, 1);
                  if (maxDueValue < curHours) {
                    lineClass = 'redbg';
                  }
                  break;
                case 'Date':
                case 'MOS':
                  var ND_Date = new Date(ND.value);
                  if (todayDate.getTime() > ND_Date.getTime()) {
                    lineClass = 'orangebg';
                  }
                  var MD_Date = new Date(MD.value);
                  if (todayDate.getTime() > MD_Date.getTime()) {
                    lineClass = 'redbg';
                  }
                  break;
                case 'Cycles':
                case 'ENC':
                  if (ND.value < curCycles) {
                    lineClass = 'orangebg';
                  }
                  if (MD.value < curCycles) {
                    lineClass = 'redbg';
                  }
                  break;
              }
              break;
            }
          }
        } else {
          //No maxNextDue List;
          switch (ND.unit) {
            case 'Hours':
            case 'HRS':
              if (roundNum(parseFloat(ND.value) / 60, 1) < curHours) {
                lineClass = 'redbg';
              }
              if (roundNum(parseFloat(ND.value) / 60, 1) == curHours) {
                lineClass = 'orangebg';
              }
              break;
            case 'Date':
            case 'MOS':
              var ND_Date = new Date(ND.value);
              if (todayDate.getTime() > ND_Date.getTime()) {
                lineClass = 'redbg';
              }
              if (todayDate.getTime() == ND_Date.getTime()) {
                lineClass = 'orangebg';
              }
              break;
            case 'Cycles':
            case 'ENC':
              if (ND.value < curCycles) {
                lineClass = 'redbg';
              }
              if (ND.value == curCycles) {
                lineClass = 'orangebg';
              }
              break;
          }
        }
      }
      html +=
        '<tr class="' +
        lineClass +
        '"><td class="wrap">' +
        I.description +
        '</td><td>' +
        nextDueUnit +
        '</td><td>' +
        nextDueValue +
        '</td><td>' +
        maxDueValue +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html +=
      '<h3 style="background-color: ' +
      getCAMP_stateColor(data.aicraftState.state) +
      '">' +
      iTrans('Next Due Tasks') +
      ': ' +
      iTrans('No Items Listed') +
      '</h3>';
  }
  return html;
}

function OverrideAircraftTimes_CAMP(ident) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  //ident = "ES-DEMO1"; //todo remove this in production
  AC_TECH_DISPATCH.UPDATE.MaintenanceOverride = true;
  AC_TECH_DISPATCH.UPDATE.MaintenanceReason = $('#WBFR_Note').val();
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'CAMP-AJAX.php?action=updateTimes&ident=' + encodeURIComponent(ident) + '&FR_PK=0',
    data: {
      data: AC_TECH_DISPATCH,
      signature_text: signatureDATA.text,
      signature_data: signatureDATA.data,
      FR_PK: 0,
      Date: dateToStringObject(new Date()),
    },
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data.ServerResponse != 'Success') {
        if (json_data.ServerResponse == 'Failed') {
          if (json_data.data.Status != undefined) {
            if (json_data.data.Status == 'SoapFault') {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Update Failed'),
                headerClose: false,
                buttonPrompt: json_data.data.faultstring,
                buttons: {
                  [iTrans('OK')]: {
                    click: function () {
                      //do nothing
                    },
                  },
                },
              });
            }
          } else {
            var Reasons = json_data.data.reason + '<br />';

            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Update Failed'),
              headerClose: false,
              buttonPrompt: Reasons,
              buttons: {
                [iTrans('OK')]: {
                  click: function () {
                    //do nothing
                  },
                },
              },
            });
            //status could be offline/Not Logged In/Uncaught Exception
            if (json_data[0] != undefined) {
              if (json_data[0].ServerResponse == 'Uncaught Exception') {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('Update Failed'),
                  headerClose: false,
                  buttonPrompt: json_data[0].Exception,
                  buttons: {
                    [iTrans('OK')]: {
                      click: function () {
                        //do nothing
                      },
                    },
                  },
                });
              }
            }
          }
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Aircraft times have been successfully updated'),
          buttons: {
            [iTrans('Refresh Records Now')]: {
              click: function () {
                populateCampTimes(AC_data[ACIndex].ident);
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Update Failed'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem submitting the Updated Aircraft Times.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    });
}

function WB_SetCAMP_Data(e) {
  //could do some validation here too;
  var value = $('#' + e.id).val();
  console.log('value', value);
  var parts = e.id.split('_');
  value = CAMP_FormatUnitOfMeasure(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].unit, value);
  if (value < 0) {
    value = 0;
  }
  AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].value = parseFloat(value);
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].unit == 'HRS') {
    AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].value = Math.round(parseFloat(value));
    $('#' + e.id).val((value / 60).toFixed(1));
  } else {
    $('#' + e.id).val(value);
  }
}

function editAC1(switchpage = true) {
  if (switchpage) {
    WB_SIGNATURE_DATA = '';
    $.mobile.changePage($('#AC_Edit_Page'), {
      changeHash: false,
    });
  }
  if (this.value) ACIndex = this.value;
  var AC = AC_data[ACIndex];
  PAX_CUR_AIRCRAFT = AC; //global in menu-MaxManifesting.js
  $('#Content_Edit').html('');
  var ImperialChecked = 'checked="checked"';
  var MetricChecked = '';
  var Metric = false;
  if (AC.Units == 'Metric') {
    ImperialChecked = '';
    MetricChecked = 'checked="checked"';
    Metric = true;
  }
  /*
	var html = '<div class="limit-table"><legend><br />Units of Measurement for Input</legend><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
	html += '<input type="radio" name="WBUNITS" id="KFM_WB_AC-Units1-' + AC.PrimaryKey + '" value="Imperial" ' + ImperialChecked + ' >';
	html += '<label for="KFM_WB_AC-Units1-' + AC.PrimaryKey + '">Imerial - Lbs / Inches </label>';
	html += '<input type="radio" name="WBUNITS" id="KFM_WB_AC-Units2-' + AC.PrimaryKey + '" value="Metric" ' + MetricChecked + ' )>';
	html += '<label for="KFM_WB_AC-Units2-' + AC.PrimaryKey + '">Metric - Kg / cm</label>';
	html += '</fieldset></div><br />';
	$("#Content_Edit").html(html);

	*/

  UpdateWBUnitLBL(AC.Units);

  $('#Content_Edit').append(
    `<div style="width: 100%; overflow: auto"><table id="EDIT-AC_Table" class="limit-table"><thead><tr><th data-priority="1">${iTrans(
      'Aircraft'
    )}</th><th data-priority="2">${iTrans(
      'Other'
    )}</th><th data-priority="3">W and B</th></tr></thead><tbody><tr><td><b>${iTrans('Ident')}</b><h3>${
      AC.ident
    }</h3><input type='hidden' id='KFM_WB_AC-ident-${AC.PrimaryKey}' name='ident' disabled/>${iTrans(
      'Rate'
    )}/Hr<input ${InputStyle} type='number' step='0.01' id='KFM_WB_AC-hourly_rate_cents-${
      AC.PrimaryKey
    }' name='hourly_rate_cents' />S/N<input ${InputStyle} type='number' id='KFM_WB_AC-SN-${
      AC.PrimaryKey
    }' name='SN' />${iTrans('Model')}<select id='KFM_WB_AC-AcType-${
      AC.PrimaryKey
    }' name='AcType' data-mini='true' inline='true' onChange='checkCustom(${
      AC.PrimaryKey
    })'/><div id='CAcType'>${iTrans('Custom Model')}<select id='KFM_WB_AC-Custom_Type-${
      AC.PrimaryKey
    }' name='Custom_Type' data-mini='true' inline='true' /><button onClick='createCustomType()' data-mini='true' data-icon='plus' class='greenbg' id='NCT_Select'>${iTrans(
      'New Custom Type'
    )}</button></div></td><td>${iTrans('Cruise Speed Knots')}<input ${InputStyle} type='number' id='KFM_WB_AC-Cruise-${
      AC.PrimaryKey
    }' name='Cruise' />${iTrans('Fuel Burn')} ${
      WBUnitLbl.Rate
    }<input ${InputStyle} type='number' id='KFM_WB_AC-Consumption-${AC.PrimaryKey}' name='Consumption' />${iTrans(
      'Default Fuel Loading'
    )} ${WBUnitLbl.Weight}<input ${InputStyle} type='number' id='KFM_WB_AC-DefaultFuel-${
      AC.PrimaryKey
    }' name='DefaultFuel' />ESN<input ${InputStyle} type='text' id='KFM_WB_AC-esn-${
      AC.PrimaryKey
    }' name='esn' />${iTrans('Satphone')} #<input ${InputStyle} type='text' id='KFM_WB_AC-satphone-${
      AC.PrimaryKey
    }' name='satphone'/></td><td>${iTrans('Scale Date')}<input ${InputStyle} type='date' id='KFM_WB_AC-Date-${
      AC.PrimaryKey
    }' name='Date' />${iTrans('Empty Weight')} ${
      WBUnitLbl.Weight
    }<input ${InputStyle} type='number' step='0.01' id='KFM_WB_AC-Weight-${AC.PrimaryKey}' name='Weight'/>Long Moment ${
      WBUnitLbl.Moment
    }<input ${InputStyle} type='number' step='0.01' id='KFM_WB_AC-LongMom-${
      AC.PrimaryKey
    }' name='LongMom'/>Lat Moment ${
      WBUnitLbl.Moment
    }<input ${InputStyle} type='number' step='0.01' id='KFM_WB_AC-LatMom-${
      AC.PrimaryKey
    }' name='LatMom'/></td></tr></tbody></table></div>`
  );

  $('#KFM_WB_AC-ident-' + AC.PrimaryKey).val(AC.ident);
  $('#KFM_WB_AC-hourly_rate_cents-' + AC.PrimaryKey).val(AC.hourly_rate_cents / 100);
  $('#KFM_WB_AC-SN-' + AC.PrimaryKey).val(AC.SN);
  $('#KFM_WB_AC-Cruise-' + AC.PrimaryKey).val(AC.Cruise);
  $('#KFM_WB_AC-Consumption-' + AC.PrimaryKey).val(AC.Consumption);
  $('#KFM_WB_AC-DefaultFuel-' + AC.PrimaryKey).val(AC.DefaultFuel);

  $('#KFM_WB_AC-satphone-' + AC.PrimaryKey).val(AC.satphone);
  $('#KFM_WB_AC-Date-' + AC.PrimaryKey).val(AC.Date);
  $('#KFM_WB_AC-Weight-' + AC.PrimaryKey).val(AC.Weight);
  $('#KFM_WB_AC-LongMom-' + AC.PrimaryKey).val(AC.LongMom);
  $('#KFM_WB_AC-LatMom-' + AC.PrimaryKey).val(AC.LatMom);
  if (WANDB == '0')
    $('#KFM_WB_AC-AcType-' + AC.PrimaryKey).append($('<option />').val('CUSTOM').text(iTrans('CUSTOM Type')));
  else $('#KFM_WB_AC-AcType-' + AC.PrimaryKey).append($('<option />').val('CUSTOM').text(iTrans('AC Type')));
  $('#KFM_WB_AC-Custom_Type-' + AC.PrimaryKey).append($('<option />').val('').text(iTrans('New Custom Type')));
  for (b in ACTypes_data) {
    $('#KFM_WB_AC-AcType-' + AC.PrimaryKey).append(
      $('<option />').val(ACTypes_data[b].AcTypes).text(ACTypes_data[b].AcTypes)
    );
  }
  for (b in Custom_ACTypes_data) {
    $('#KFM_WB_AC-Custom_Type-' + AC.PrimaryKey).append(
      $('<option />').val(Custom_ACTypes_data[b].AcTypes).text(Custom_ACTypes_data[b].AcTypes)
    );
  }

  $('#Content_Edit').append('<div id="KFM_WB_AC_Trackers" class="limit-table"></div>');
  showAcTrackerOptions();
  if (SCHEDULER == 1) {
    if (GROUP_DATA.Bases != undefined) {
      var baseSelect = '<h3 class="limit-table">' + iTrans('Base Location') + '</h3>';
      baseSelect += '<select id="KFM_WB_AC-BASED_IN-' + AC.PrimaryKey + '" >';
      var options = '<option value="" selected>N/A</option>';
      if (AC.BASED_IN != '') options = '<option value="">N/A</option>';
      for (var i in GROUP_DATA.Bases) {
        var bases = GROUP_DATA.Bases[i];
        if (AC.BASED_IN == bases.name)
          options += '<option value="' + bases.name + '" selected>' + bases.name + '</option>';
        else options += '<option value="' + bases.name + '">' + bases.name + '</option>';
      }
      //todo check raw data for selected base and update
      baseSelect += options + '</select>';
      $('#Content_Edit').append(baseSelect);
      $('#KFM_WB_AC-BASED_IN-' + AC.PrimaryKey).change(WBchange);
    }
  }
  if (GROUP_DATA.WinAir == 1 || GROUP_DATA.Flightdocs == 1 || GROUP_DATA.CAMP == 1) {
    var IntegrationName = iTrans('Uses Flightdocs Integration');
    if (GROUP_DATA.WinAir == 1) IntegrationName = iTrans('Uses WinAir Integration');
    if (GROUP_DATA.CAMP == 1) IntegrationName = iTrans('Uses CAMP Systems Integration');
    var Tchecked = '';
    if (AC.TechDispatch == 1) Tchecked = 'checked';
    var Thtml = '<label for="KFM_WB_AC-TechDispatch-' + AC.PrimaryKey + '">' + IntegrationName + '</label>';
    Thtml += '<input type="checkbox" id="KFM_WB_AC-TechDispatch-' + AC.PrimaryKey + '" ' + Tchecked + '/>';
    if (GROUP_DATA.CAMP == 1) {
      Thtml += '<div id="KFM_WB_CAMP_Times" class="limit-table"></div>';
    }
    $('#Content_Edit').append('<div><br />' + Thtml + '</div>');
    $('#KFM_WB_AC-TechDispatch-' + AC.PrimaryKey).click(WBchange);
    if (GROUP_DATA.CAMP == 1) {
      populateCampTimes(AC.ident);
    }
  }

  var TChtml =
    '<label for="KFM_WB_AC-noTC-' +
    AC.PrimaryKey +
    '">' +
    iTrans('Do Not Create Temp Configuration Matrix') +
    '</label>';
  TChtml +=
    '<input type="checkbox" id="KFM_WB_AC-noTC-' + AC.PrimaryKey + '" ' + (AC.noTC == 1 ? 'checked' : '') + '/>';
  $('#Content_Edit').append(TChtml);
  $('#KFM_WB_AC-noTC-' + AC.PrimaryKey).click(WBchange);

  if (GROUP_DATA.OFP == 1) {
    var OFPchecked = '';
    if (AC.OFP_REQ == 1) OFPchecked = 'checked';
    var OFPhtml =
      '<label for="KFM_WB_AC-OFP_REQ-' +
      AC.PrimaryKey +
      '">' +
      iTrans('Operational Flight Plan Required for A/C') +
      '</label>';
    OFPhtml += '<input type="checkbox" id="KFM_WB_AC-OFP_REQ-' + AC.PrimaryKey + '" ' + OFPchecked + '/>';
    $('#Content_Edit').append(OFPhtml);
    $('#KFM_WB_AC-OFP_REQ-' + AC.PrimaryKey).click(WBchange);
  }

  var DYNchecked = '';
  if (AC.Dynamic_Tracker == 1) DYNchecked = 'checked';
  var DYNtracking =
    '<label for="KFM_WB_AC-Dynamic_Tracker-' +
    AC.PrimaryKey +
    '">' +
    iTrans('Select GPS Tracker For Each Flight') +
    '</label>';
  DYNtracking += '<input type="checkbox" id="KFM_WB_AC-Dynamic_Tracker-' + AC.PrimaryKey + '" ' + DYNchecked + '/>';
  $('#Content_Edit').append(DYNtracking);
  $('#KFM_WB_AC-Dynamic_Tracker-' + AC.PrimaryKey).click(WBchange);

  if (ITINERARY == 1) {
    //company subscribes to aircraft tracking integration
    var Schecked = '';
    if (AC.SilenceAlerts == 1) Schecked = 'checked';
    var Shtml =
      '<label for="KFM_WB_AC-SilenceAlerts-' +
      AC.PrimaryKey +
      '">' +
      iTrans('Silence No Itin Alerts for A/C') +
      '</label>';
    Shtml += '<input type="checkbox" id="KFM_WB_AC-SilenceAlerts-' + AC.PrimaryKey + '" ' + Schecked + '/>';

    Shtml +=
      '<label for="KFM_WB_AC-restrict_MaxPerf-' +
      AC.PrimaryKey +
      '">' +
      iTrans('Restrict Internal Max Weight For DA') +
      '</label><input type="checkbox" id="KFM_WB_AC-restrict_MaxPerf-' +
      AC.PrimaryKey +
      '" ' +
      (AC.restrict_MaxPerf == 1 ? 'checked' : '') +
      '/>';

    Shtml +=
      '<label for="KFM_WB_AC-restrict_MaxPerfExt-' +
      AC.PrimaryKey +
      '">' +
      iTrans('Restrict External Max Weight For DA') +
      '</label><input type="checkbox" id="KFM_WB_AC-restrict_MaxPerfExt-' +
      AC.PrimaryKey +
      '" ' +
      (AC.restrict_MaxPerfExt == 1 ? 'checked' : '') +
      '/>';

    var SSchecked = '';
    if (AC.StartStop == 1) SSchecked = 'checked';
    Shtml +=
      '<label for="KFM_WB_AC-StartStop-' +
      AC.PrimaryKey +
      '">' +
      iTrans('Track Flight Time and Air Time for A/C') +
      ' - [' +
      iTrans('Default') +
      ': ' +
      iTrans('Air Time Only') +
      ']</label>';
    Shtml += '<input type="checkbox" id="KFM_WB_AC-StartStop-' + AC.PrimaryKey + '" ' + SSchecked + '/>';
    Shtml +=
      '<div class="limit-table">' +
      iTrans('Note') +
      ': ' +
      iTrans('If billing and invoicing requires Flight Time and you only collect Air Time on the aircraft') +
      ' - ' +
      iTrans('Cirro will automatically populate the Flight Time component with the same times collected for Air Time') +
      '.</div>';
    $('#Content_Edit').append('<div>' + Shtml + '</div>');

    $('#KFM_WB_AC-SilenceAlerts-' + AC.PrimaryKey).click(WBchange);
    $('#KFM_WB_AC-StartStop-' + AC.PrimaryKey).click(WBchange);
    $('#KFM_WB_AC-restrict_MaxPerf-' + AC.PrimaryKey).click(WBchange);
    $('#KFM_WB_AC-restrict_MaxPerfExt-' + AC.PrimaryKey).click(WBchange);
  }

  var Serviceable = '';
  if (AC.Serviceable == 1) Serviceable = 'checked';
  var ServiceHTML =
    '<label for="KFM_WB_AC-Serviceable-' + AC.PrimaryKey + '">' + iTrans('Aircraft is Serviceable') + '</label>';
  ServiceHTML += '<input type="checkbox" id="KFM_WB_AC-Serviceable-' + AC.PrimaryKey + '" ' + Serviceable + '/>';
  ServiceHTML +=
    '<label for="KFM_WB_AC-ServiceReason-' +
    AC.PrimaryKey +
    '">Service Reason</label><textarea id="KFM_WB_AC-ServiceReason-' +
    AC.PrimaryKey +
    '"></textarea>';

  $('#Content_Edit').append('<div class="limit-table"><br />' + ServiceHTML + '</div>');
  $('#KFM_WB_AC-ServiceReason-' + AC.PrimaryKey).val(AC.ServiceReason);
  $('#KFM_WB_AC-ServiceReason-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Serviceable-' + AC.PrimaryKey).click(WBchange);

  if (WANDB == 1)
    $('#Content_Edit').append(
      '<div><br /><button class=class="ui-btn ui-shadow ui-corner-all ui-btn-icon-left" data-icon="check">' +
        iTrans('Save Changes') +
        '</button><button class="ui-btn ui-shadow ui-corner-all ui-btn-icon-left ui-icon-delete redbg" name="' +
        AC.ident +
        '" id="KFM_WB_AC-DeleteID-' +
        AC.PrimaryKey +
        '">' +
        iTrans('Delete This Aircraft') +
        '</button></div>'
    );
  else
    $('#Content_Edit').append(
      '<div><br /><button class=class="ui-btn ui-shadow ui-corner-all ui-btn-icon-left" data-icon="check" onClick="editAEL()" id="LITE_WB">' +
        iTrans('Save Changes') +
        '</button><button class="ui-btn ui-shadow ui-corner-all ui-btn-icon-left ui-icon-delete redbg" name="' +
        AC.ident +
        '" id="KFM_WB_AC-DeleteID-' +
        AC.PrimaryKey +
        '">' +
        iTrans('Delete This Aircraft') +
        '</button></div>'
    );
  if (WANDB == 1)
    $('#Content_Edit').append(
      '<div class="limit-table"><h1 class="limit-table">' +
        iTrans('Weight and Balance Summary') +
        '</h1>' +
        AC.EQ_HTML +
        '</div>'
    );
  $('#Content_Edit').enhanceWithin();
  if (AC.AcType == 'CUSTOM') {
    if (AC.Custom_Type != '') {
      $('#NCT_Select').html(iTrans('Edit Custom Type'));
      CurCustomLabel = AC.Custom_Type;
    } else {
      $('#NCT_Select').html(iTrans('New Custom Type'));
      CurCustomLabel = '';
    }
    $('#CAcType').show();
  } else {
    $('#CAcType').hide();
    $('#NCT_Select').html(iTrans('Edit Custom Type'));
  }
  if (WANDB == '1') $('#CAcType').hide();
  $('#KFM_WB_AC-AcType-' + AC.PrimaryKey).val(AC.AcType);
  $('#KFM_WB_AC-AcType-' + AC.PrimaryKey).selectmenu('refresh', true);
  $('#KFM_WB_AC-Custom_Type-' + AC.PrimaryKey).val(AC.Custom_Type);
  $('#KFM_WB_AC-Custom_Type-' + AC.PrimaryKey).selectmenu('refresh', true);
  CurCustomLabel = AC.Custom_Type;

  $('#KFM_WB_AC-Units1-' + AC.PrimaryKey).click(WBchange);
  $('#KFM_WB_AC-Units2-' + AC.PrimaryKey).click(WBchange);

  $('#KFM_WB_AC-AcType-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Custom_Type-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Custom_Type-' + AC.PrimaryKey).change(CustomLabel);
  $('#KFM_WB_AC-ident-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-SN-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-hourly_rate_cents-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Cruise-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Consumption-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-DefaultFuel-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-esn-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-satphone-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Date-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-Weight-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-LongMom-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-LatMom-' + AC.PrimaryKey).change(WBchange);
  $('#KFM_WB_AC-DeleteID-' + AC.PrimaryKey).click(deleteRec);
  $('#Content_Edit').enhanceWithin();
  if (HOBBS == 1) {
    var Hobbs = '<h3>' + iTrans('Hobbs Meter Readings') + '</h3>';
    var dateObj = new Date();

    var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    var date = ('0' + dateObj.getDate()).slice(-2);
    var year = dateObj.getFullYear();
    var shortDate = year + '-' + month + '-' + date;

    var now = new Date().toISOString().substr(11, 5);

    Hobbs += '<h3>' + iTrans('Hobbs Reading Immediately Prior to Selected UTC Date and Time') + '</h3>';
    Hobbs += '<div id="MAINT_HOBBS_TABLE"></div><br />';

    Hobbs +=
      '<table class="limit-table"><tr><td><label for="MAINT_HOBBS_DATE">' +
      iTrans('Reported UTC Date') +
      '</label><input type="date" id="MAINT_HOBBS_DATE" onChange="MAINT_Get_Hobbs(\'' +
      AC.ident +
      '\')" /></td>';
    Hobbs +=
      '<td><label for="MAINT_HOBBS_TIME">' +
      iTrans('Reported UTC Time') +
      '</label><input type="time" id="MAINT_HOBBS_TIME" value="' +
      now +
      '" onChange="MAINT_Get_Hobbs(\'' +
      AC.ident +
      '\')"/></td></tr></table>';

    Hobbs += '<br /><label for="MAINT_HOBBS_TYPE">' + iTrans('Meter to update') + '</label>';
    Hobbs +=
      '<select id="MAINT_HOBBS_TYPE" data-mini="true"><option value="Primary">' +
      iTrans('Flight Time') +
      '</option><option value="Secondary">' +
      iTrans('Maintenance') +
      '</option></select>';
    Hobbs +=
      '<label for="MAINT_HOBBS_VALUE">' +
      iTrans('Hobbs Value') +
      '</label><input " +InputStyle+ " type="number" step="0.1" min="0" max="99999" id="MAINT_HOBBS_VALUE" data-mini="true"/>';
    Hobbs +=
      '<label for="MAINT_HOBBS_COMMENT">' +
      iTrans('Comment') +
      '</label><input " +InputStyle+ " type="text" id="MAINT_HOBBS_COMMENT" data-mini="true"/>';
    Hobbs +=
      '<button onClick="MAINT_Update_Hobbs()" class="greenbg" data-mini="true" data-icon="plus" >' +
      iTrans('Update Hobbs') +
      '</button>';
    $('#MAINT_HOBBS').html(Hobbs);
    $('#MAINT_HOBBS').enhanceWithin();
    $('#MAINT_HOBBS_DATE').val(shortDate);
    HOBBS_AC_IDENT = AC.ident;
    HOBBS_AC_PK = AC.PrimaryKey;
    MAINT_Get_Hobbs(AC.ident);
  }
  getDepartmentHTML('Aircraft', AC.PrimaryKey, 'AC_Department');
}
var HOBBS_AC_IDENT;
var HOBBS_AC_PK;

function MAINT_Update_Hobbs() {
  console.log('Updating Hobbs Readings');
  var Type = $('#MAINT_HOBBS_TYPE').val();
  var Total = $('#MAINT_HOBBS_VALUE').val();
  if (Total == '') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Error'),
      headerClose: false,
      buttonPrompt: iTrans('Please enter a valid Hobbs Value'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
    return;
  }
  var Comment = $('#MAINT_HOBBS_COMMENT').val();
  var ReportDate = $('#MAINT_HOBBS_DATE').val();
  var ReportTime = $('#MAINT_HOBBS_TIME').val();
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=overrideHobbs&PK=' +
      HOBBS_AC_PK +
      '&Type=' +
      encodeURIComponent(Type) +
      '&Total=' +
      Total +
      '&Comment=' +
      encodeURIComponent(Comment) +
      '&DateTime=' +
      encodeURIComponent(ReportDate + ' ' + ReportTime),
    function (data) {
      $.mobile.loading('hide');

      var Hobbs = '<h3>' + iTrans('Hobbs Meter Readings') + '</h3>';
      var dateObj = new Date();

      var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
      var date = ('0' + dateObj.getDate()).slice(-2);
      var year = dateObj.getFullYear();
      var shortDate = year + '-' + month + '-' + date;

      var now = new Date().toISOString().substr(11, 5);

      Hobbs += '<h3>' + iTrans('Hobbs Reading Immediately Prior to Selected UTC Date and Time') + '</h3>';
      Hobbs += '<div id="MAINT_HOBBS_TABLE"></div><br />';

      Hobbs +=
        '<table class="limit-table"><tr><td><label for="MAINT_HOBBS_DATE">' +
        iTrans('Reported UTC Date') +
        '</label><input type="date" id="MAINT_HOBBS_DATE" onChange="MAINT_Get_Hobbs(\'' +
        AC_data[ACIndex].ident +
        '\')" /></td>';
      Hobbs +=
        '<td><label for="MAINT_HOBBS_TIME">' +
        iTrans('Reported UTC Time') +
        '</label><input type="time" id="MAINT_HOBBS_TIME" value="' +
        now +
        '" onChange="MAINT_Get_Hobbs(\'' +
        AC_data[ACIndex].ident +
        '\')"/></td></tr></table>';

      Hobbs += '<br /><label for="MAINT_HOBBS_TYPE">' + iTrans('Meter to update') + '</label>';
      Hobbs +=
        '<select id="MAINT_HOBBS_TYPE" data-mini="true"><option value="Primary">Flight Time</option><option value="Secondary">' +
        iTrans('Maintenance') +
        '</option></select>';
      Hobbs +=
        '<label for="MAINT_HOBBS_VALUE">' +
        iTrans('Hobbs Value') +
        '</label><input " +InputStyle+ " type="number" step="0.1" min="0" max="99999" id="MAINT_HOBBS_VALUE" data-mini="true"/>';
      Hobbs +=
        '<label for="MAINT_HOBBS_COMMENT">' +
        iTrans('Comment') +
        '</label><input " +InputStyle+ " type="text" id="MAINT_HOBBS_COMMENT" data-mini="true"/>';
      Hobbs +=
        '<button onClick="MAINT_Update_Hobbs()" class="greenbg" data-mini="true" data-icon="plus" >' +
        iTrans('Update Hobbs') +
        '</button>';
      $('#MAINT_HOBBS').html(Hobbs);
      $('#MAINT_HOBBS').enhanceWithin();
      $('#MAINT_HOBBS_DATE').val(shortDate);

      MAINT_Get_Hobbs(HOBBS_AC_IDENT);
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $('#MAINT_HOBBS').html('<h3>' + iTrans('Error Updatng Hobbs reading') + '</h3>');
    $.mobile.loading('hide');
  });
}

function MAINT_Get_Hobbs(ident) {
  var DateTime = stringToDateObject($('#MAINT_HOBBS_DATE').val() + ' ' + $('#MAINT_HOBBS_TIME').val());
  //DateTime.setMinutes(DateTime.getTimezoneOffset() + DateTime.getMinutes());
  DateTime = DateTime.toISOString().substr(0, 19);
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getCurrentAChobbs&ident=' +
      encodeURIComponent(ident) +
      '&DateTime=' +
      encodeURIComponent(DateTime),
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Hobbs') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'gethobbscur');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          if (json_data[0].ServerResponse == 'Success') {
            InitHobbsEdit(json_data[0].HOBBS);
          }
        }
      }
    }
  ).fail(function (jqXHR, status, error) {
    //Fail silently and populate with offline holders
    $.mobile.loading('hide');
    $('#MAINT_HOBBS_TABLE').html('<h2>' + iTrans('Failed to retrieve Hobbs Times') + '</h2>');
    $('#MAINT_HOBBS_TABLE').enhanceWithin();
  });
}
function InitHobbsEdit(H) {
  var Hobbs =
    '<table class="limit-table last-td-fill"><thead><tr><th>' +
    iTrans('Meter') +
    '</th><th>' +
    iTrans('Reported') +
    '</th><th>' +
    iTrans('Reading') +
    '</th><th>' +
    iTrans('Comment') +
    '</th><th>' +
    iTrans('Modified') +
    '</th></tr></thead><tbody>';
  Hobbs +=
    '<tr><td>' +
    iTrans('Flight Time') +
    '</td><td>' +
    H.Primary.PrevReading.DateTime +
    '</td><td>' +
    H.Primary.PrevReading.Total +
    '</td><td>' +
    H.Primary.PrevReading.Comment +
    '</td><td>' +
    H.Primary.PrevReading.modified +
    '</td></tr>';
  Hobbs +=
    '<tr><td>' +
    iTrans('Maintenance') +
    '</td><td>' +
    H.Secondary.PrevReading.DateTime +
    '</td><td>' +
    H.Secondary.PrevReading.Total +
    '</td><td>' +
    H.Secondary.PrevReading.Comment +
    '</td><td>' +
    H.Secondary.PrevReading.modified +
    '</td></tr>';

  Hobbs += '</tbody></table>';

  $('#MAINT_HOBBS_TABLE').html(Hobbs);
  $('#MAINT_HOBBS_TABLE').enhanceWithin();
}
var ACIndex;

/**
 * Populates the edit Weight and Balance page and navigates to the specified tab.
 *
 * @param {string} selectedTab
 */
function populateEditWBPageAndSelectTab(selectedTab) {
  populateEditWBPage();
  UpdateWBUnitLBL(AC_data[ACIndex].Units);

  switch (selectedTab) {
    case '0':
      editIEL();
      $('#installWBMENU').prop('checked', true).checkboxradio('refresh');
      break;
    case '1':
      editAEL();
      break;
    case '2':
      $('#Content_WB_Rebuild').show();
      RuneditTC = true;
      editAEL();
      $('');
      break;
  }
}
/*
 * Populates the edit Weight and Balance page with the selected aircraft's details.
 */
function populateEditWBPage() {
  WB_SIGNATURE_DATA = '';
  $('#Content_WB_Rebuild').hide();
  $.mobile.changePage($('#WB_Edit_Page'), {
    changeHash: false,
  });

  var i = ACIndex;
  $('#Content_WB').html('');
  $('#WB_Aircraft').html(
    "<h2 class='limit-table'>" +
      iTrans('Selected Aircraft') +
      ': ' +
      AC_data[i].ident +
      ' ' +
      iTrans('Type') +
      ': ' +
      AC_data[i].AcType +
      '</h2>'
  );

  $('#Header_Title_WB_Edit').html(AC_data[i].ident + ' ' + iTrans('Weight and Balance'));
  if (WB_SIGNATURE_DATA == '')
    $('#WB_SignatureStatus').html(
      '<button class="redbg" onClick="WB_CAPTURE_SIGNATURE(null);" data-mini="true">' +
        iTrans('Add Signature Now') +
        '</button>'
    );
  else
    $('#WB_SignatureStatus').html(
      '<center><h3 class="greenbg">' +
        iTrans('Signature Ready') +
        '<br />' +
        iTrans('This will appear at end of report') +
        '</h3>' +
        WB_SIGNATURE_DATA +
        '<button data-role="none" data-mini="true" class="yellowbg" onClick="WB_RemoveSignature()">' +
        iTrans('Remove Signature') +
        '</button></center>'
    );
  $('#WB_SignatureStatus').enhanceWithin();
}
function editAC2() {
  populateEditWBPage();
  editAEL(); //trigger Amendments btn
}

function editTC(scaledDate = 'Unknown', amdDate = 'Unknown') {
  console.log('editTC called');
  var LimitGraphTypes = {};
  LimitGraphTypes[CofG_Graph] = CofG_Graph;

  if (!viewingReportOnly) {
    ACRebuild = true;
  }
  $('#LoadingIndicator').show();
  $('#Content_WB').hide();

  var MAXweight = 0;
  var MINweight = 999999;
  var MAXhorz = 0;
  var MINhorz = 999999;
  var i = ACIndex;

  /*
  var TH_WB = '<th>Graph | Fwd <= Long Arm  ' +
      WBUnitLbl.Arm +
      ' <= Aft<br />Use Weight Empty Center of Gravity Limits</th>';

  if (!$("#useWeightEmpty").is(":checked")){
    TH_WB = '<th>Graph | Fwd | Empty Long Arm  ' +
        WBUnitLbl.Arm +
        ' | Aft<br />Aircraft CofG Limits</th>';
  }
*/
  var TH_WB = '<th>Graph vs [Empty]</th><th>Most Fwd Limit</th><th>Most Aft Limit</th>';

  if (!$('#useWeightEmpty').is(':checked')) {
    TH_WB = '<th>Graph vs [CofG]</th><th>Most Fwd Pos</th><th>Most Aft Pos</th>';
  }

  if ($('#HideEWCGL').is(':checked')) {
    TH_WB = '';
  }
  console.log('THWB');
  console.log(TH_WB);
  $('#Content_WB').html(WBHTML);

  if (!viewingReportOnly) {
    $('#tcWBMENU').prop('checked', true);
  }
  $('#Content_WB').append("<table class='limit-table'><tr><td><div id='TC'></div></td></tr></table>");
  var curActive = 0;
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_TC&ident=' + encodeURIComponent(AC_data[i].ident),
    function (json_data) {
      TC_data = json_data;
      if (TC_data.length == 0) {
        $('#TC').append(
          '<h4 class="limit-table">' +
            iTrans('No Temp Configurations Found Please Check') +
            ' "' +
            iTrans('WB Amendments') +
            '"</h4>'
        );
        if (WANDB == 0) {
          $('#LITE_WB').prop('disabled', false);
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt: iTrans('Aircraft Weight and Balance Updated Successfully'),
            buttons: {
              [iTrans('OK')]: {
                click: function () {},
              },
            },
          });
          $.mobile.loading('hide');
        }
      } else {
        if (WANDB == 0) {
          $('#LITE_WB').prop('disabled', false);
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt: iTrans('Aircraft Weight and Balance Updated Successfully'),
            buttons: {
              [iTrans('OK')]: {
                click: function () {},
              },
            },
          });
          $.mobile.loading('hide');
        }
        if (AC_data[ACIndex].noTC == 0) {
          $('#TC').append("<h3 class='limit-table'>" + iTrans('Weight and Balance Temp Configurations') + '</h3>');
          $('#TC').append('<b>' + iTrans('Scaled On') + ': ' + scaledDate + '<br />' + amdDate + '</b><br />');
        }
      }

      $.getJSON(
        BASEURL + 'menu-Maintenance-AJAX.php?action=getoptional&ident=' + encodeURIComponent(AC_data[i].ident),
        function (json_data) {
          var tempconfigs = false;
          var curACTypes_data = ACTypes_data[0];
          for (var t in ACTypes_data) {
            if (ACTypes_data[t].AcTypes == AC_data[i].AcType) {
              curACTypes_data = ACTypes_data[t];
            }
          }

          OPT_data = json_data;
          if (AC_data[ACIndex].noTC == 0) {
            var TableKeyString = '';
            var PrintKeyString = '';
            var TableKey = '';
            for (var tcKey in OPT_data) {
              TableKeyString += '<th>' + OPT_data[tcKey].Abr + '</th>';
              PrintKeyString += "<th style='width:20px;'>" + OPT_data[tcKey].Abr + '</th>';
              var conflictString = '';
              TableKey += OPT_data[tcKey].Abr + ' = ' + OPT_data[tcKey].Description + conflictString + '<br />';
              if (OPT_data[tcKey].cgRestrict == 1) {
                LimitGraphTypes[OPT_data[tcKey].cgGraph] = OPT_data[tcKey].cgGraph;
              }
            }
            //Calculate Kit Moments and add to OPT data
            for (var o in OPT_data) {
              var O = OPT_data[o];
              if (O.Kit == 1) {
                var K = GetKitTotalChange(O);
                O.Weight = K.Weight;
                O.HorzArm = K.HorzArm;
                O.LatArm = K.LatArm;
                O.HorzMom = K.HorzMom;
                O.LatMom = K.LatMom;
              }
            }
            $('#TC').append(
              '<div style="width: 100%; overflow: auto"><table class="limit-table" id="TC-List_Table">' +
                '<thead>' +
                '<tr>' +
                TableKeyString +
                '<th data-priority="1">TC Num</th>' +
                '<th data-priority="persist">' +
                iTrans('Weight') +
                ' ' +
                WBUnitLbl.Weight +
                '</th>' +
                '<th>Long Arm ' +
                WBUnitLbl.Arm +
                '</th><th data-priority="2">Moment(H) ' +
                WBUnitLbl.Moment +
                '</th>' +
                '<th data-priority="3">Lat Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th data-priority="3">Moment(L) ' +
                WBUnitLbl.Moment +
                '</th>' +
                TH_WB +
                '</tr>' +
                '</thead>' +
                '<tbody><tr>'
            );
          }

          if (Printview && AC_data[ACIndex].noTC == 0)
            $('#PrintTC').append(
              '<h4 class="limit-table">' +
                iTrans('Weight and Balance Temp Configurations') +
                '</h4><b>' +
                iTrans('Scaled On') +
                ': ' +
                scaledDate +
                '<br />' +
                amdDate +
                '</b><br /><table data-role="table" style="width:800px;" id="printTC-List_Table" class="ui-responsive">' +
                '<thead>' +
                '<tr>' +
                PrintKeyString +
                '<th data-priority="1" style="width:50px;">TC Num</th>' +
                '<th data-priority="persist" style="width:80px;">' +
                iTrans('Weight') +
                ' ' +
                WBUnitLbl.Weight +
                '</th>' +
                '<th>Long Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th data-priority="2" style="width:100px;">Moment(H) ' +
                WBUnitLbl.Moment +
                '</th>' +
                '<th data-priority="3" style="width:60px;">Lat Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th data-priority="3" style="width:60px;">Moment(L) ' +
                WBUnitLbl.Moment +
                '</th>' +
                TH_WB +
                '</tr>' +
                '</thead>' +
                '<tbody><tr>'
            );

          var curLimitGraphTypes = {};
          curLimitGraphTypes[CofG_Graph] = CofG_Graph;

          var balastCG = EmptyCG_TXT(
            curACTypes_data.EmptyCG,
            curACTypes_data.Graph,
            TC_data[0].Weight,
            TC_data[0].HorzMom,
            0,
            0,
            false,
            AC_data[i].SN,
            curLimitGraphTypes,
            curLimitGraphTypes
          )[0];
          if (OPT_data.length == 0 || AC_data[ACIndex].noTC == 1 || OPT_data.length > 12) {
            console.log(curACTypes_data);
            console.log(balastCG);

            if (TC_data.length > 0) {
              var nLarm = parseFloat(Number(TC_data[0].LatMom) / Number(TC_data[0].Weight)).toFixed(2);

              var nHarm = parseFloat(Number(TC_data[0].HorzMom) / Number(TC_data[0].Weight)).toFixed(2);

              if ($('#HideEWCGL').is(':checked')) {
                var tcHorzArmTXT = '';
              } else {
                var tcHorzArmTXT =
                  '<span>Not Setup | </span><span class="">N/A</span><span> <= <b>' +
                  nHarm +
                  '</b> <= </span><span class="">N/A</span>';
                if (balastCG.GraphName != 'Skip')
                  tcHorzArmTXT =
                    '<span>' +
                    balastCG.GraphName +
                    ' | </span><span class="' +
                    (balastCG.FwdMax < nHarm ? '' : 'redbg') +
                    '">' +
                    balastCG.FwdMax +
                    '</span><span> <= <b>' +
                    nHarm +
                    '</b> <= </span><span class="' +
                    (balastCG.AftMax > nHarm ? '' : 'redbg') +
                    '">' +
                    balastCG.AftMax +
                    '</span>';

                if (!$('#useWeightEmpty').is(':checked')) {
                  tcHorzArmTXT =
                    '<span>Not Setup | </span><span class="">N/A</span><span> | <b>' +
                    nHarm +
                    '</b> | </span><span class="">N/A</span>';
                  if (balastCG.GraphName != 'Skip')
                    tcHorzArmTXT =
                      '<span>' +
                      balastCG.GraphName +
                      ' | </span><span class="' +
                      (balastCG.FwdGood ? '' : 'redbg') +
                      '">' +
                      balastCG.FwdPoint +
                      '[' +
                      balastCG.FwdWeight +
                      WBUnitLbl.Weight +
                      ']</span><span> | <b>' +
                      nHarm +
                      '</b> | </span><span class="' +
                      (balastCG.AftGood ? '' : 'redbg') +
                      '">' +
                      balastCG.AftPoint +
                      '[' +
                      balastCG.AftWeight +
                      WBUnitLbl.Weight +
                      ']</span>';
                }
                tcHorzArmTXT = '<td><center>' + tcHorzArmTXT + '</center></td>';
              }

              $('#TC tr:last').after(
                '<tr>' +
                  PrintKeyString +
                  '<td>0</td><td>' +
                  TC_data[0].Weight +
                  '</td>' +
                  tcHorzArmTXT +
                  '<td>' +
                  nHarm +
                  '</td><td>' +
                  TC_data[0].HorzMom +
                  '</td><td>' +
                  nLarm +
                  '</td><td>' +
                  TC_data[0].LatMom +
                  '</td></tr>'
              );

              $('#TC tr:last').after('</tbody></table></div>');
              if (AC_data[ACIndex].noTC == 0)
                $('#TC').append('<h3>' + iTrans('No Optional Equipment Installed on Aircraft') + '</h3>');
              if (Printview) {
                $('#PrintTC tr:last').after(
                  '<tr>' +
                    PrintKeyString +
                    '<td>0</td><td>' +
                    TC_data[0].Weight +
                    '</td><td>' +
                    nHarm +
                    '</td><td>' +
                    TC_data[0].HorzMom +
                    '</td><td>' +
                    nLarm +
                    '</td><td>' +
                    TC_data[0].LatMom +
                    '</td></tr>'
                );
                $('#PrintTC tr:last').after('</tbody></table>');
                if (AC_data[ACIndex].noTC == 0)
                  $('#PrintTC').append('<h5>' + iTrans('No Optional Equipment Installed on Aircraft') + '</h5>');
              }
            }
            var Dactive = 0;
            if (curActive == 0) Dactive = 1;

            $('#LoadingIndicator').html(
              '<h1 class="limit-table">' + iTrans('Deleting Old Configuration Data') + '...</h1>'
            );
            $.getJSON(
              BASEURL + 'menu-Maintenance-AJAX.php?action=deleteTC&ident=' + encodeURIComponent(AC_data[i].ident),
              function (json_data) {
                if (json_data[0].ServerResponse == 'Success') {
                  // Successfully updated Original WB
                  $('#LoadingIndicator').html(
                    '<h1 class="limit-table">' + iTrans('Rebuilding Temp configs') + '.</h1>'
                  );
                  $.getJSON(
                    BASEURL +
                      'menu-Maintenance-AJAX.php?action=NewRecord&table=KFM_WB_TC&ident=' +
                      encodeURIComponent(AC_data[i].ident) +
                      '&anum=' +
                      TC_data[0].ANum +
                      '&tcnum=0&date=' +
                      TC_data[0].Date +
                      '&description=Original&weight=' +
                      TC_data[0].Weight +
                      '&horzmom=' +
                      TC_data[0].HorzMom +
                      '&latmom=' +
                      TC_data[0].LatMom +
                      '&active=' +
                      Dactive +
                      '&JSON=',
                    function (json_data) {
                      if (json_data[0].ServerResponse == 'Success') {
                        // Successfully updated Original WB
                        $.getJSON(
                          BASEURL +
                            'menu-Maintenance-AJAX.php?action=SetFuelStandard&ident=' +
                            encodeURIComponent(AC_data[i].ident),
                          function (json_data) {
                            if (json_data[0].ServerResponse == 'Success') {
                              //console.log(json_data[0].PrimaryKey);
                              //PK = json_data[0].PrimaryKey;
                              // Successfully updated Original WB
                              $('#LoadingIndicator').html('<h1 class="limit-table">' + iTrans('Complete') + '</h1>');
                              $('#LoadingIndicator').hide();
                              $('#Content_WB').show();
                              $.mobile.loading('hide');
                            } else {
                              alert(iTrans('SetFuelType Failed') + ': ' + json_data[0].ServerResponse);
                            }
                          }
                        );
                      } else {
                        alert(iTrans('Insert Basic TC Failed') + ': ' + json_data[0].ServerResponse);
                      }
                      if (WANDB == 1 && !viewingReportOnly) {
                        $('#ammendWBMENU').prop('disabled', false).checkboxradio('refresh');
                        $('#installWBMENU').prop('disabled', false).checkboxradio('refresh');
                        $('#tcWBMENU').prop('disabled', false).checkboxradio('refresh');
                      }
                    }
                  );
                } else {
                  alert(iTrans('DeleteTC Failed') + ': ' + json_data[0].ServerResponse);
                }
              }
            );

            if (!Printview) UpdateAircraft_EQHTML(WBHTML, AC_data[i].ident);
          } else {
            var TC_SEND = [];
            var configNUM = 0;
            tempconfigs = true;

            var itemNum = OPT_data.length;
            var optNum = Math.pow(2, OPT_data.length);

            var optBin = Number(optNum).toString(2);
            var q = 0;
            var BIN = '0';
            //$("#LoadingIndicator").html('<h1 class="limit-table">Rebuilding 1 of ' + optNum + ' Temp configs.</h1>');
            var HideInvalidTC = $('#HideInvalidTC').is(':checked');
            while (q < optNum) {
              var tcWeight = Number(TC_data[0].Weight);
              var tcHorzMom = Number(TC_data[0].HorzMom);
              var tcLatMom = Number(TC_data[0].LatMom);
              var tcHorzArm = 0;
              var tcLatArm = 0;
              curBin = Number(q).toString(2);
              while (curBin.length < itemNum) {
                curBin = BIN.concat(curBin);
              }
              //alert(curBin);
              var opt = itemNum - 1;
              var keyString = '';
              var verify = '';

              var description = '';
              var tcNUM = q;

              var OPTarray = [];
              var curLimitGraphTypes = {};
              curLimitGraphTypes[CofG_Graph] = CofG_Graph;
              var curLimitGraphTypes2 = 'Standard';
              while (opt >= 0) {
                var conflict = 0;
                var status = curBin.substr(opt, 1);
                var tKey = '';
                var iHmom;
                var iLmom;
                if (status == '1') {
                  var Abr = OPT_data[opt].Abr;
                  for (c in OPT_data) {
                    if (OPT_data[opt].Conflict != null) {
                      var Citems = OPT_data[opt].Conflict.split('|');
                      for (f in Citems) {
                        if (Citems[f] != '' && Citems[f] == OPT_data[c].PK_Item && curBin.substr(c, 1) == '1') {
                          conflict = 1;
                          tcNUM = 'N/A';
                          tKey = '<td><center>O</center></td>';
                        }
                      }
                    }
                  }
                  if (conflict != 1) tKey = '<td><center>X</center></td>';
                  if (OPT_data[opt].cgGraph != null && OPT_data[opt].cgRestrict == 0) {
                    if (OPT_data[opt].cgRestrict == 1) {
                      curLimitGraphTypes = {};
                      curLimitGraphTypes[OPT_data[opt].cgGraph] = OPT_data[opt].cgGraph;
                    } else {
                      curLimitGraphTypes2 = OPT_data[opt].cgGraph;
                    }
                  }
                  keyString = tKey.concat(keyString);
                }
                OPTarray.push({
                  PKitem: OPT_data[opt].PK_Item,
                  AcIdent: OPT_data[opt].AcIdent,
                  status: status,
                  conflict: conflict,
                  conflictwith: OPT_data[opt].Conflict,
                });
                if (status == '0') {
                  tKey = '<td><center></center></td>';
                  keyString = tKey.concat(keyString);
                }

                if (OPT_data[opt].Optional == 'Removed' && status == '1') {
                  verify += ' | Install: ' + OPT_data[opt].Description;
                  description += OPT_data[opt].Abr + ',';
                  if (OPT_data[opt].Kit == 1) {
                    iHmom = OPT_data[opt].HorzMom;
                    iLmom = OPT_data[opt].LatMom;
                  } else {
                    iHmom = OPT_data[opt].HorzArm * OPT_data[opt].Weight;
                    iLmom = OPT_data[opt].LatArm * OPT_data[opt].Weight;
                  }
                  tcWeight += Number(OPT_data[opt].Weight);
                  tcHorzMom += iHmom;
                  tcLatMom += iLmom;
                }
                if (OPT_data[opt].Optional == 'Installed' && status == '0') {
                  verify += ' | Remove: ' + OPT_data[opt].Description;
                  if (OPT_data[opt].Kit == 1) {
                    iHmom = OPT_data[opt].HorzMom;
                    iLmom = OPT_data[opt].LatMom;
                  } else {
                    iHmom = OPT_data[opt].HorzArm * OPT_data[opt].Weight;
                    iLmom = OPT_data[opt].LatArm * OPT_data[opt].Weight;
                  }
                  tcWeight -= Number(OPT_data[opt].Weight);
                  tcHorzMom -= iHmom;
                  tcLatMom -= iLmom;
                }
                if (OPT_data[opt].Optional == 'Installed' && status == '1') {
                  verify += ' | Do Nothing: ' + OPT_data[opt].Description;
                  description += OPT_data[opt].Abr + ',';
                }
                if (OPT_data[opt].Optional == 'Removed' && status == '0') {
                  verify += ' | Do Nothing: ' + OPT_data[opt].Description;
                }
                opt--;
              }
              hArm = tcHorzMom / tcWeight;
              tcHorzArm = parseFloat(hArm).toFixed(2);
              lArm = tcLatMom / tcWeight;
              tcLatArm = parseFloat(lArm).toFixed(2);
              tcHorzMom = parseFloat(tcHorzMom).toFixed(2);
              tcLatMom = parseFloat(tcLatMom).toFixed(2);
              tcWeight = parseFloat(tcWeight).toFixed(2);

              if (tcWeight > MAXweight) MAXweight = tcWeight;
              if (tcWeight < MINweight) MINweight = tcWeight;
              if (tcHorzArm > MAXhorz) MAXhorz = tcHorzArm;
              if (tcHorzArm < MINhorz) MINhorz = tcHorzArm;

              var balastCG = EmptyCG_TXT(
                curACTypes_data.EmptyCG,
                curACTypes_data.Graph,
                tcWeight,
                tcHorzMom,
                0,
                0,
                false,
                AC_data[i].SN,
                curLimitGraphTypes,
                curLimitGraphTypes2
              )[0];
              console.log(balastCG);

              if (!(HideInvalidTC == true && tcNUM == 'N/A')) {
                if ($('#HideEWCGL').is(':checked')) {
                  var tcHorzArmTXT = '';
                } else {
                  var tcHorzArmTXT = '';

                  if (balastCG.GraphName != 'Skip')
                    tcHorzArmTXT =
                      '<td>' +
                      balastCG.GraphName +
                      '</td><td class="' +
                      (balastCG.FwdMax < tcHorzArm ? '' : 'redbg') +
                      '">' +
                      balastCG.FwdMax +
                      '</td><td class="' +
                      (balastCG.AftMax > tcHorzArm ? '' : 'redbg') +
                      '">' +
                      balastCG.AftMax +
                      '</td>';
                  else tcHorzArmTXT = '<tr><td>Not Setup</td><td>N/A</td><td>N/A</td>';
                  if (!$('#useWeightEmpty').is(':checked')) {
                    if (balastCG.GraphName != 'Skip')
                      tcHorzArmTXT =
                        '<td>' +
                        balastCG.GraphName +
                        '</td><td class="' +
                        (balastCG.FwdGood ? '' : 'redbg') +
                        '">' +
                        balastCG.FwdWeight +
                        WBUnitLbl.Weight +
                        '@' +
                        balastCG.FwdPoint +
                        '</td><td class="' +
                        (balastCG.AftGood ? '' : 'redbg') +
                        '">' +
                        balastCG.AftWeight +
                        WBUnitLbl.Weight +
                        '@' +
                        balastCG.AftPoint +
                        '</td>';
                    else tcHorzArmTXT = '<td>Not Setup</td><td class="">N/A</td><td class="">N/A</td></tr>';
                  }
                }
                //console.log(tcHorzArmTXT);

                $('#TC tr:last').after(
                  '<tr>' +
                    keyString +
                    '<td><center>' +
                    tcNUM +
                    '</center></td><td><center>' +
                    tcWeight +
                    '</center></td>' +
                    '<td><center>' +
                    tcHorzArm +
                    '</center></td><td><center>' +
                    tcHorzMom +
                    '</center></td><td><center>' +
                    tcLatArm +
                    '</center></td><td><center>' +
                    tcLatMom +
                    '</center></td>' +
                    tcHorzArmTXT +
                    '</tr>'
                );

                if (Printview)
                  $('#PrintTC tr:last').after(
                    '<tr>' +
                      keyString +
                      '<td><center>' +
                      tcNUM +
                      '</center></td><td><center>' +
                      tcWeight +
                      '</center></td>' +
                      '<td><center>' +
                      tcHorzArm +
                      '</center></td><td><center>' +
                      tcHorzMom +
                      '</center></td><td><center>' +
                      tcLatArm +
                      '</center></td><td><center>' +
                      tcLatMom +
                      '</center></td>' +
                      tcHorzArmTXT +
                      '</tr>'
                  );
              }
              var active = 0;
              if (curActive - 1 == q || (curActive == 0 && q == 0)) active = 1;
              //$.ajaxSetup({ async: false });
              var PK;
              var postString = JSON.stringify(OPTarray);
              var postString64 = btoa(postString);
              TC_SEND.push({
                ident: AC_data[i].ident,
                anum: TC_data[0].ANum,
                tcnum: q,
                date: TC_data[0].Date,
                description: description,
                weight: tcWeight,
                horzmom: tcHorzMom,
                latmom: tcLatMom,
                active: active,
                JSON: postString,
              });

              configNUM++;
              q++;
            }
            //Send TC_SEND;

            $('#LoadingIndicator').html(
              '<h1 class="limit-table">' + iTrans('Sending Data To Server, Please wait') + '...</h1>'
            );

            if (Printview) {
              //Don't send data to server on rebuild
              $.mobile.loading('hide');
              $('#LoadingIndicator').hide();
              $('#Content_WB').show();
              TC_SEND = [];
            } else {
              $.ajax({
                type: 'POST',
                url: BASEURL + 'menu-Maintenance-AJAX.php?action=NewRecordsPOST&table=KFM_WB_TC',
                data: { TC_SEND: TC_SEND },
                success: function (data) {
                  $.mobile.loading('hide');
                  $('#LoadingIndicator').hide();
                  $('#Content_WB').show();
                  TC_SEND = [];
                  if (data[0].ServerResponse == 'Success') {
                    $.getJSON(
                      BASEURL +
                        'menu-Maintenance-AJAX.php?action=SetFuelStandard&ident=' +
                        encodeURIComponent(AC_data[i].ident),
                      function (json_data) {
                        if (json_data[0].ServerResponse == 'Success') {
                          //console.log(json_data[0].PrimaryKey);
                          //PK = json_data[0].PrimaryKey;
                          // Successfully updated Original WB
                        } else {
                          alert(iTrans('SetFuelType Failed') + ': ' + json_data[0].ServerResponse);
                        }
                      }
                    );
                  } else {
                    if (data[0].ServerResponse == 'Uncaught Exception') AJAXFAIL(data[0].Exception);
                  }
                },
                fail: function (jqXHR, status, error) {
                  TC_SEND = [];
                  $.mobile.loading('hide');
                  $('#LoadingIndicator').html(
                    '<h1 class="limit-table">' + iTrans('Failed Please try rebuilding again') + '!</h1>'
                  );
                  AJAXFAIL(error);
                },
              });
            }

            if (!viewingReportOnly) {
              $('#ammendWBMENU').prop('disabled', false).checkboxradio('refresh');
              $('#installWBMENU').prop('disabled', false).checkboxradio('refresh');
              $('#tcWBMENU').prop('disabled', false).checkboxradio('refresh');
            }

            if (Printview) {
              $('#PrintTC tr:last').after(
                '<tr>' +
                  TableKeyString +
                  '<th>TC Num</th><th>' +
                  iTrans('Weight') +
                  ' ' +
                  WBUnitLbl.Arm +
                  '</th>' +
                  TH_WB +
                  '<th>Long Arm ' +
                  WBUnitLbl.Arm +
                  '</th>' +
                  '<th>Long Mom ' +
                  WBUnitLbl.Moment +
                  '</th><th>Lat Arm ' +
                  WBUnitLbl.Arm +
                  '</th><th>Lat Mom ' +
                  WBUnitLbl.Moment +
                  '</th></tr>'
              );
              $('#PrintTC tr:last').after('</table>');
              $('#PrintTC').append('<h5>' + TableKey + '</h5>');
              $('#PrintTC').append(
                '<h5><strong>X </strong>= ' +
                  iTrans('Installed') +
                  '<br /><strong>O </strong>= ' +
                  iTrans('Installation Conflict') +
                  ' - ' +
                  iTrans('Temp Config is Invalid for this Configuration') +
                  ' <br />' +
                  iTrans('For Lateral Arm') +
                  ': - ' +
                  iTrans('is Left and') +
                  ' + ' +
                  iTrans('is Right') +
                  '</h5>'
              );
            }

            if (!Printview) UpdateAircraft_EQHTML(WBHTML, AC_data[i].ident);
            $('#TC tr:last').after('</table>');
            $('#TC').append('<h4>' + TableKey);
            $('#TC').append(
              '<strong>X </strong>= ' +
                iTrans('Installed') +
                '<br /><strong>O </strong>= ' +
                iTrans('Installation Conflict') +
                ' - ' +
                iTrans('Temp Config is Invalid for this Configuration') +
                ' <br />' +
                iTrans('For Lateral Arm') +
                ': - ' +
                iTrans('is Left and') +
                ' + ' +
                iTrans('is Right') +
                '</h4>'
            );
            //alert("Max: wt-"+MAXweight+" ar-"+MAXhorz+" Min: wt-"+MINweight+" ar-"+MINhorz);
          } //end else

          console.log('Start Charts');
          if (WANDB == 0) {
            //Do something to fix dougs shit here

            return;
          }

          /*
          //console.log(ACTypes_data[i]);

              var graphCG;
              if (tempconfigs)
                graphCG = EmptyCG(
                    curACTypes_data.EmptyCG,
                    curACTypes_data.Graph,
                  MAXweight,
                  MAXhorz * MAXweight,
                  MINweight,
                  MINhorz * MINweight,
                  tempconfigs,
                  AC_data[i].SN,
                  LimitGraphTypes
                );
              if (!tempconfigs)
                graphCG = EmptyCG(
                    curACTypes_data.EmptyCG,
                    curACTypes_data.Graph,
                  AC_data[i].Weight,
                  AC_data[i].LongMom,
                  0,
                  0,
                  tempconfigs,
                  AC_data[i].SN,
                  LimitGraphTypes
                );

          console.log(graphCG);
          var max_y_Axis_Weight = null;
          for (var g in graphCG) {
            if (g == 0)
              $('#CHARTS').append(
                '<h2><center>' + iTrans('Weight empty vs center of gravity Chart(s)') + '</center></h2>'
              );
            var lines = [];

            if (tempconfigs) {
              lines.push([
                [Number(graphCG[g].FwdMax), Number(MAXweight), 'FWD'],
                [Number(MAXhorz), Number(MAXweight), MAXhorz],
                [graphCG[g].AftMax, Number(MAXweight), 'Aft'],
              ]);
              lines.push([
                [Number(graphCG[g].FwdMin), Number(MINweight), 'FWD'],
                [Number(MINhorz), Number(MINweight), MINhorz],
                [graphCG[g].AftMin, Number(MINweight), 'Aft'],
              ]);
            } else {
              var HorzArm = Number(TC_data[0].HorzMom) / Number(TC_data[0].Weight);
              max_y_Axis_Weight = Number(TC_data[0].Weight) + 150;
              lines.push([
                [Number(graphCG[g].FwdMax), Number(AC_data[i].Weight), 'FWD'],
                [Number(HorzArm), Number(AC_data[i].Weight), HorzArm],
                [graphCG[g].AftMax, Number(AC_data[i].Weight), 'Aft'],
              ]);
            }
            //if (!Printview) $("#TC").append("<div id='cgChart" + g + "' style='height:350px; width:750px;'></div><div id='cgWarning" + g + "'></div>");
            if (Printview)
              $('#CHARTS').append(
                "<div id='cgChart" +
                  g +
                  "' style='height:350px; width:750px;'></div><div id='cgWarning" +
                  g +
                  "'></div>"
              );
            else
              $('#CHARTS2').append(
                "<div id='cgChart" +
                  g +
                  "' style='height:350px; width:750px;'></div><div id='cgWarning" +
                  g +
                  "'></div>"
              );
            $('#cgWarning' + g).append(
              '<h4>' +
                iTrans('Using') +
                ' ' +
                graphCG[g].GraphName +
                ' ' +
                iTrans('Aircraft CofG Graph Limits') +
                '</h4>'
            );
            if (graphCG[g].FwdMax > MAXhorz > graphCG[g].AftMax || graphCG[g].FwdMin > MINhorz > graphCG[g].AftMin)
              $('#cgWarning' + g).append(
                '<h3>' + graphCG[g].Type + ' ' + iTrans('Out of limits and requires balast') + '!</h3>'
              );
            /*
            $.jqplot.postDrawHooks.push(function () {
              $(".jqplot-grid-canvas").css('z-index', '0');
              $(".jqplot-series-canvas").css('z-index', '1');
              $(".jqplot-overlayCanvas-canvas").css('z-index', '2');
              $('.jqplot-yaxis-tick').css('margin-right', '-50px');
              $('.jqplot-yaxis-tick').css('z-index', '3');
            });


            $.jqplot.config.enablePlugins = true;
            var plot1 = $.jqplot('cgChart' + g, lines, {
              title: graphCG[g].Type + ' ' + iTrans('Most Forward and Aft Limits for All Temp Configurations'),
              seriesDefaults: {
                showMarker: true,
                pointLabels: {
                  show: true,
                },
              },
              axesDefaults: {
                pad: 1.8,
              },
              axes: {
                xaxis: {
                  label: 'Longitudinal C.G.',
                  labelRenderer: $.jqplot.CanvasAxisLabelRenderer,
                },
                yaxis: {
                  label: iTrans('Empty Weight'),
                  labelRenderer: $.jqplot.CanvasAxisLabelRenderer,
                  max: max_y_Axis_Weight,
                },
              },
            });

            var imgData = $('#cgChart' + g).jqplotToImageStr({});
            var imgElem = $('<img/>').attr('src', imgData);
            $('#cgChart' + g).html(imgElem);
          }
          */

          if (WB_SIGNATURE_DATA == '') {
            $('#CHARTS').append("<h4 class='limit-table'>" + GROUP_DATA.MaintRelease + '</h4><br />');
            $('#CHARTS').append(
              "<h4 class='limit-table'>" +
                iTrans('Signature') +
                ':_________________________________                ' +
                iTrans('Licence') +
                ' no.___________________________'
            );
          } else {
            $('#CHARTS').append(WB_SIGNATURE_DATA);
            $('#Content_WB').append(WB_SIGNATURE_DATA);
          }

          if (Printview)
            setTimeout(function () {
              /*
              var x = document.getElementsByClassName('jqplot-grid-canvas');
              for (var c = x.length - 1; c >= 0; --c) {
                var img = convertCanvasToImage(x[c]);
                img.style.cssText = document.defaultView.getComputedStyle(x[c], '').cssText;
                x[c].parentNode.insertBefore(img, x[c]);
                x[c].parentNode.removeChild(x[c]);
              }
              var x = document.getElementsByClassName('jqplot-series-shadowCanvas');
              for (var c = x.length - 1; c >= 0; --c) {
                var img = convertCanvasToImage(x[c]);
                img.style.cssText = document.defaultView.getComputedStyle(x[c], '').cssText;
                x[c].parentNode.insertBefore(img, x[c]);
                x[c].parentNode.removeChild(x[c]);
              }
              var x = document.getElementsByClassName('jqplot-series-canvas');
              for (var c = x.length - 1; c >= 0; --c) {
                var img = convertCanvasToImage(x[c]);
                img.style.cssText = document.defaultView.getComputedStyle(x[c], '').cssText;
                x[c].parentNode.insertBefore(img, x[c]);
                x[c].parentNode.removeChild(x[c]);
              }
              var x = document.getElementsByClassName('jqplot-lineRenderer-highlight-canvas');
              for (var c = x.length - 1; c >= 0; --c) {
                var img = convertCanvasToImage(x[c]);
                img.style.cssText = document.defaultView.getComputedStyle(x[c], '').cssText;
                x[c].parentNode.insertBefore(img, x[c]);
                x[c].parentNode.removeChild(x[c]);
              }
              var x = document.getElementsByClassName('jqplot-event-canvas');
              for (var c = x.length - 1; c >= 0; --c) {
                var img = convertCanvasToImage(x[c]);
                img.style.cssText = document.defaultView.getComputedStyle(x[c], '').cssText;
                x[c].parentNode.insertBefore(img, x[c]);
                x[c].parentNode.removeChild(x[c]);
              }
              var x = document.getElementsByClassName('jqplot-base-canvas');
              for (var c = x.length - 1; c >= 0; --c) {
                var img = convertCanvasToImage(x[c]);
                img.style.cssText = document.defaultView.getComputedStyle(x[c], '').cssText;
                x[c].parentNode.insertBefore(img, x[c]);
                x[c].parentNode.removeChild(x[c]);
              }
               */

              GeneratePDF_WeightAndBalance(AC_data[i].ident);
            }, 1000);
        }
      );
    }
  );
}
function convertCanvasToImage(canvas) {
  var image = new Image();
  image.src = canvas.toDataURL('image/png');
  return image;
}
function UpdateTC_RebuildProgress(configNUM, optNum) {
  $('#LoadingIndicator').html(
    '<h1 class="limit-table">' +
      iTrans('Rebuilding') +
      ' ' +
      configNUM +
      ' ' +
      iTrans('of') +
      ' ' +
      optNum +
      ' ' +
      iTrans('Temp configs') +
      '.</h1>'
  );
  return;
}
function UpdateAircraft_EQHTML(html, ident) {
  if (WB_SIGNATURE_DATA == '' && !Printview && GROUP_DATA.WandB == 1) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Signature'),
      headerClose: false,
      buttonPrompt: iTrans('Would you like to add a Signature to this report?'),
      buttons: {
        [iTrans('Sign Now')]: {
          click: function () {
            WB_CAPTURE_SIGNATURE(ident);
          },
        },
        [iTrans('No')]: {
          click: function () {
            UpdateAircraft_EQHTML2(html, ident);
          },
        },
      },
    });
  } else {
    UpdateAircraft_EQHTML2(html, ident);
  }
}
function UpdateAircraft_EQHTML2(html, ident) {
  $.ajax({
    type: 'POST',
    url: BASEURL + 'menu-Maintenance-AJAX.php?action=EQ_HTML',
    data: {
      html: html + WB_SIGNATURE_DATA,
      ident: ident,
    },
    success: function (data) {
      //do nothing...
    },
    fail: function (jqXHR, status, error) {
      AJAXFAIL(error);
    },
  });
}
function EmptyCG(empty, graph, MAXweight, MAXmoment, MINweight, MINmoment, tempconfigs, serial, LimitGraphTypes) {
  MAXweight = parseFloat(MAXweight);
  MAXmoment = parseFloat(MAXmoment);
  MINweight = parseFloat(MINweight);
  MINmoment = parseFloat(MINmoment);
  var emptyObject = JSON.parse(empty);
  var graphObject = JSON.parse(graph);
  serial = parseFloat(serial);
  console.log('SN: ' + serial);
  console.log(emptyObject);
  console.log(graphObject);
  console.log(LimitGraphTypes);
  MAXfwdArray = [];
  MINfwdArray = [];
  MAXaftArray = [];
  MINaftArray = [];
  graphName = [];
  var InfoArray = [];
  for (var a in graphObject) {
    if (LimitGraphTypes[graphObject[a].Type] == undefined) continue;
    //x and y coords array of longitudinal graph
    var gLength = graphObject[a].LongGraph.length;
    var i = 1;
    for (var b in graphObject[a].LongGraph) {
      var x1, y1, x2, y2;
      if (i != gLength) {
        x1 = parseFloat(graphObject[a].LongGraph[b].x);
        y1 = parseFloat(graphObject[a].LongGraph[b].y);
        x2 = parseFloat(graphObject[a].LongGraph[i].x);
        y2 = parseFloat(graphObject[a].LongGraph[i].y);
      } else {
        x1 = parseFloat(graphObject[a].LongGraph[b].x);
        y1 = parseFloat(graphObject[a].LongGraph[b].y);
        x2 = parseFloat(graphObject[a].LongGraph[0].x);
        y2 = parseFloat(graphObject[a].LongGraph[0].y);
      }
      //get line equations and get intercepts

      for (var c in emptyObject) {
        //console.log("x1: "+x1+" y1:"+y1+" x2: "+x2+" y2:"+y2);
        //console.log(doLineSegmentsIntersect({x:x1,y:y1}, {x:x2,y:y2},{x:0,y:2800},{x:1000,y:2800}));
        //console.log(emptyObject[c].SN_Low+" "+emptyObject[c].SN_High);
        if (
          doLineSegmentsIntersect(
            {
              x: x1,
              y: y1,
            },
            {
              x: x2,
              y: y2,
            },
            {
              x: -1000,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            },
            {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            }
          ) &&
          y1 < y2 &&
          parseFloat(emptyObject[c].SN_Low) <= serial &&
          serial <= parseFloat(emptyObject[c].SN_High)
        ) {
          //var intersection = require('intersection');
          seg2 = {
            start: {
              x: 0,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            },
            end: {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            },
          };
          seg3 = {
            start: {
              x: x1,
              y: y1,
            },
            end: {
              x: x2,
              y: y2,
            },
          };
          intercept = intersection.intersect(seg2, seg3).x;

          var fwdlimit =
            ((MAXweight + parseFloat(emptyObject[c].FwdWT)) * intercept - parseFloat(emptyObject[c].FwdMOM)) /
            MAXweight;
          MAXfwdArray[c] = {
            Type: emptyObject[c].Type,
            Fwd: fwdlimit,
          };
          graphName[c] = {
            GraphName: graphObject[a].Type,
          };
          //console.log(intercept);
          //console.log("FWD x1: " + x1 + " y1:" + y1 + " x2: " + x2 + " y2:" + y2);
        }
        if (
          doLineSegmentsIntersect(
            {
              x: x1,
              y: y1,
            },
            {
              x: x2,
              y: y2,
            },
            {
              x: -1000,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            },
            {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            }
          ) &&
          y1 > y2 &&
          parseFloat(emptyObject[c].SN_Low) <= serial &&
          serial <= parseFloat(emptyObject[c].SN_High)
        ) {
          seg2 = {
            start: {
              x: 0,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            },
            end: {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            },
          };
          seg3 = {
            start: {
              x: x1,
              y: y1,
            },
            end: {
              x: x2,
              y: y2,
            },
          };
          intercept = intersection.intersect(seg2, seg3).x;
          //console.log(intercept);
          var aftlimit =
            ((MAXweight + parseFloat(emptyObject[c].AftWT)) * intercept - parseFloat(emptyObject[c].AftMOM)) /
            MAXweight;
          MAXaftArray[c] = {
            Type: emptyObject[c].Type,
            Aft: aftlimit,
          };
          //console.log("AFT x1: " + x1 + " y1:" + y1 + " x2: " + x2 + " y2:" + y2);
        }
      }

      if (tempconfigs) {
        for (var c in emptyObject) {
          //console.log("x1: "+x1+" y1:"+y1+" x2: "+x2+" y2:"+y2);
          //console.log(doLineSegmentsIntersect({x:x1,y:y1}, {x:x2,y:y2},{x:0,y:2800},{x:1000,y:2800}));
          if (
            doLineSegmentsIntersect(
              {
                x: x1,
                y: y1,
              },
              {
                x: x2,
                y: y2,
              },
              {
                x: -1000,
                y: MINweight + parseFloat(emptyObject[c].FwdWT),
              },
              {
                x: 1000,
                y: MINweight + parseFloat(emptyObject[c].FwdWT),
              }
            ) &&
            y1 < y2 &&
            parseFloat(emptyObject[c].SN_Low) <= serial &&
            serial <= parseFloat(emptyObject[c].SN_High)
          ) {
            //var intersection = require('intersection');
            seg2 = {
              start: {
                x: 0,
                y: MINweight + parseFloat(emptyObject[c].FwdWT),
              },
              end: {
                x: 1000,
                y: MINweight + parseFloat(emptyObject[c].FwdWT),
              },
            };
            seg3 = {
              start: {
                x: x1,
                y: y1,
              },
              end: {
                x: x2,
                y: y2,
              },
            };
            intercept = intersection.intersect(seg2, seg3).x;

            var fwdlimit =
              ((MINweight + parseFloat(emptyObject[c].FwdWT)) * intercept - parseFloat(emptyObject[c].FwdMOM)) /
              MINweight;
            MINfwdArray[c] = {
              Type: emptyObject[c].Type,
              Fwd: fwdlimit,
            };
            //console.log(intercept);
            //console.log("x1: " + x1 + " y1:" + y1 + " x2: " + x2 + " y2:" + y2);
          }
          if (
            doLineSegmentsIntersect(
              {
                x: x1,
                y: y1,
              },
              {
                x: x2,
                y: y2,
              },
              {
                x: -1000,
                y: MINweight + parseFloat(emptyObject[c].AftWT),
              },
              {
                x: 1000,
                y: MINweight + parseFloat(emptyObject[c].AftWT),
              }
            ) &&
            y1 > y2 &&
            parseFloat(emptyObject[c].SN_Low) <= serial &&
            serial <= parseFloat(emptyObject[c].SN_High)
          ) {
            seg2 = {
              start: {
                x: 0,
                y: MINweight + parseFloat(emptyObject[c].AftWT),
              },
              end: {
                x: 1000,
                y: MINweight + parseFloat(emptyObject[c].AftWT),
              },
            };
            seg3 = {
              start: {
                x: x1,
                y: y1,
              },
              end: {
                x: x2,
                y: y2,
              },
            };
            intercept = intersection.intersect(seg2, seg3).x;
            //console.log(intercept);
            var aftlimit =
              ((MINweight + parseFloat(emptyObject[c].AftWT)) * intercept - parseFloat(emptyObject[c].AftMOM)) /
              MINweight;
            MINaftArray[c] = {
              Type: emptyObject[c].Type,
              Aft: aftlimit,
            };
            //console.log("x1: " + x1 + " y1:" + y1 + " x2: " + x2 + " y2:" + y2);
          }
        }
      }
      i++;
    }

    if (
      MAXfwdArray.length != MAXaftArray.length ||
      MINfwdArray.length != MINaftArray.length ||
      (MAXfwdArray.length != MINfwdArray.length && tempconfigs) ||
      (MAXaftArray.length != MINaftArray.length && tempconfigs)
    ) {
      //alert("Outside Graph Area, check empty CofG Values");
      return [];
    }

    for (var d in MAXfwdArray) {
      if (tempconfigs)
        InfoArray.push({
          GraphName: graphName[d].GraphName,
          Type: MAXfwdArray[d].Type,
          FwdMax: MAXfwdArray[d].Fwd,
          AftMax: MAXaftArray[d].Aft,
          FwdMin: MINfwdArray[d].Fwd,
          AftMin: MINaftArray[d].Aft,
        });
      else
        InfoArray.push({
          GraphName: graphName[d].GraphName,
          Type: MAXfwdArray[d].Type,
          FwdMax: MAXfwdArray[d].Fwd,
          AftMax: MAXaftArray[d].Aft,
        });
    }
  } //end loop
  console.log('CheckArray');
  console.log(MAXaftArray);

  return InfoArray;
}

function EmptyCG_TXT(
  empty,
  graph,
  MAXweight,
  MAXmoment,
  MINweight,
  MINmoment,
  tempconfigs,
  serial,
  LimitGraphTypes,
  LimitGraphTypes2
) {
  var FwdPoint = 0;
  var AftPoint = 0;
  console.log(empty);
  console.log(LimitGraphTypes);
  console.log(LimitGraphTypes2);
  if (empty == null || empty == '') {
    return [
      {
        GraphName: 'Skip',
        Type: 'Standard',
        FwdMax: 0,
        AftMax: 999,
        FwdPoint: 0,
        FwdWeight: 0,
        FwdGood: false,
        AftPoint: 0,
        AftWeight: 0,
        AftGood: false,
        Reason: 'empty == null',
      },
    ];
  }
  MAXweight = parseFloat(MAXweight);
  MAXmoment = parseFloat(MAXmoment);
  MINweight = parseFloat(MINweight);
  MINmoment = parseFloat(MINmoment);
  var emptyObject = JSON.parse(empty);
  var graphObject = JSON.parse(graph);
  console.log(emptyObject);
  serial = parseFloat(serial);

  MAXfwdArray = [];
  MINfwdArray = [];
  MAXaftArray = [];
  MINaftArray = [];
  graphName = [];
  var InfoArray = [];
  for (var a in graphObject) {
    if (LimitGraphTypes[graphObject[a].Type] == undefined) continue;
    //x and y coords array of longitudinal graph
    var gLength = graphObject[a].LongGraph.length;
    var i = 1;
    for (var b in graphObject[a].LongGraph) {
      var x1, y1, x2, y2;
      if (i != gLength) {
        x1 = parseFloat(graphObject[a].LongGraph[b].x);
        y1 = parseFloat(graphObject[a].LongGraph[b].y);
        x2 = parseFloat(graphObject[a].LongGraph[i].x);
        y2 = parseFloat(graphObject[a].LongGraph[i].y);
      } else {
        x1 = parseFloat(graphObject[a].LongGraph[b].x);
        y1 = parseFloat(graphObject[a].LongGraph[b].y);
        x2 = parseFloat(graphObject[a].LongGraph[0].x);
        y2 = parseFloat(graphObject[a].LongGraph[0].y);
      }
      //get line equations and get intercepts
      var G = PrepBalastGraph(graphObject[a]);
      for (var c in emptyObject) {
        if (emptyObject[c].Type != LimitGraphTypes2) continue; //for loading specific graphs

        var FwdWeight = parseFloat(emptyObject[c].FwdWT) + MAXweight;
        var FwdMOM = parseFloat(emptyObject[c].FwdMOM) + MAXmoment;
        FwdPoint = FwdMOM / FwdWeight;

        var AftWeight = parseFloat(emptyObject[c].AftWT) + MAXweight;
        var AftMOM = parseFloat(emptyObject[c].AftMOM) + MAXmoment;
        AftPoint = AftMOM / AftWeight;
        //console.log("x1: "+x1+" y1:"+y1+" x2: "+x2+" y2:"+y2);
        //console.log(doLineSegmentsIntersect({x:x1,y:y1}, {x:x2,y:y2},{x:0,y:2800},{x:1000,y:2800}));
        //console.log(emptyObject[c].SN_Low+" "+emptyObject[c].SN_High);
        if (
          doLineSegmentsIntersect(
            {
              x: x1,
              y: y1,
            },
            {
              x: x2,
              y: y2,
            },
            {
              x: -1000,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            },
            {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            }
          ) &&
          y1 < y2 &&
          parseFloat(emptyObject[c].SN_Low) <= serial &&
          serial <= parseFloat(emptyObject[c].SN_High)
        ) {
          //var intersection = require('intersection');
          seg2 = {
            start: {
              x: 0,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            },
            end: {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].FwdWT),
            },
          };
          seg3 = {
            start: {
              x: x1,
              y: y1,
            },
            end: {
              x: x2,
              y: y2,
            },
          };
          intercept = intersection.intersect(seg2, seg3).x;

          var fwdlimit =
            ((MAXweight + parseFloat(emptyObject[c].FwdWT)) * intercept - parseFloat(emptyObject[c].FwdMOM)) /
            MAXweight;
          MAXfwdArray[c] = {
            Type: emptyObject[c].Type,
            Fwd: fwdlimit,
          };
          graphName[c] = {
            GraphName: graphObject[a].Type,
          };
          //console.log(intercept);
          //console.log("FWD x1: " + x1 + " y1:" + y1 + " x2: " + x2 + " y2:" + y2);
        }
        if (
          doLineSegmentsIntersect(
            {
              x: x1,
              y: y1,
            },
            {
              x: x2,
              y: y2,
            },
            {
              x: -1000,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            },
            {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            }
          ) &&
          y1 > y2 &&
          parseFloat(emptyObject[c].SN_Low) <= serial &&
          serial <= parseFloat(emptyObject[c].SN_High)
        ) {
          seg2 = {
            start: {
              x: 0,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            },
            end: {
              x: 1000,
              y: MAXweight + parseFloat(emptyObject[c].AftWT),
            },
          };
          seg3 = {
            start: {
              x: x1,
              y: y1,
            },
            end: {
              x: x2,
              y: y2,
            },
          };
          intercept = intersection.intersect(seg2, seg3).x;
          //console.log(intercept);
          var aftlimit =
            ((MAXweight + parseFloat(emptyObject[c].AftWT)) * intercept - parseFloat(emptyObject[c].AftMOM)) /
            MAXweight;
          MAXaftArray[c] = {
            Type: emptyObject[c].Type,
            Aft: aftlimit,
          };
          //console.log("AFT x1: " + x1 + " y1:" + y1 + " x2: " + x2 + " y2:" + y2);
        }
      }
      i++;
    }
    if (MAXfwdArray.length == 0) {
      return [
        {
          GraphName: 'Skip',
          Type: 'Standard',
          FwdMax: 0,
          AftMax: 999,
          FwdPoint: 0,
          FwdWeight: 0,
          FwdGood: false,
          AftPoint: 0,
          AftWeight: 0,
          AftGood: false,
          Reason: 'MAXfwdArray.length == 0',
        },
      ];
    }

    for (var d in MAXfwdArray) {
      var GraphName = graphName[d].GraphName;
      //console.log(MAXfwdArray);
      //console.log(MAXaftArray);
      if (LimitGraphTypes2 != 'Standard') GraphName += ' ' + LimitGraphTypes2;
      if (MAXfwdArray[d].Fwd == undefined) MAXfwdArray[d].Fwd = 0;
      if (MAXaftArray[d] == undefined) MAXaftArray[d] = {};
      if (MAXaftArray[d].Aft == undefined) MAXaftArray[d].Aft = 0;
      InfoArray.push({
        GraphName: GraphName,
        Type: MAXfwdArray[d].Type,
        FwdMax: MAXfwdArray[d].Fwd.toFixed(2),
        AftMax: MAXaftArray[d].Aft.toFixed(2),
        FwdPoint: FwdPoint.toFixed(2),
        FwdWeight: FwdWeight.toFixed(2),
        FwdGood: pnpoly(G.x.length, G.x, G.y, FwdPoint, FwdWeight) == false ? false : true,
        AftPoint: AftPoint.toFixed(2),
        AftWeight: AftWeight.toFixed(2),
        AftGood: pnpoly(G.x.length, G.x, G.y, AftPoint, AftWeight) == false ? false : true,
      });
    }
  } //end loop
  if (InfoArray.length == 0) {
    return [
      {
        GraphName: 'Skip',
        Type: 'Standard',
        FwdMax: 0,
        AftMax: 999,
        FwdPoint: 0,
        FwdWeight: 0,
        FwdGood: false,
        AftPoint: 0,
        AftWeight: 0,
        AftGood: false,
        Reason: 'empty == null',
      },
    ];
  }
  return InfoArray;
}

function PrepBalastGraph(wbgraph) {
  var x = [];
  var y = [];
  var minx = parseFloat(wbgraph.LongGraph[0].x);
  var miny = parseFloat(wbgraph.LongGraph[0].y);
  var maxx = minx;
  var maxy = miny;
  for (var i in wbgraph.LongGraph) {
    var Lx = parseFloat(wbgraph.LongGraph[i].x);
    var Ly = parseFloat(wbgraph.LongGraph[i].y);
    x.push(Lx);
    y.push(Ly);
    if (Lx > maxx) maxx = Lx;
    if (Lx < minx) minx = Lx;
    if (Ly > maxy) maxy = Ly;
    if (Ly < miny) miny = Ly;
  }
  return { x: x, y: y };
}

function doLineSegmentsIntersect(p, p2, q, q2) {
  var r = subtractPoints(p2, p);
  var s = subtractPoints(q2, q);

  var uNumerator = crossProduct(subtractPoints(q, p), r);
  var denominator = crossProduct(r, s);

  if (uNumerator == 0 && denominator == 0) {
    // colinear, so do they overlap?
    return (
      ((q.x - p.x < 0 != q.x - p2.x < 0) != q2.x - p.x < 0) != q2.x - p2.x < 0 ||
      ((q.y - p.y < 0 != q.y - p2.y < 0) != q2.y - p.y < 0) != q2.y - p2.y < 0
    );
  }

  if (denominator == 0) {
    // lines are paralell
    return false;
  }

  var u = uNumerator / denominator;
  var t = crossProduct(subtractPoints(q, p), s) / denominator;

  return t >= 0 && t <= 1 && u >= 0 && u <= 1;
}

function crossProduct(point1, point2) {
  return point1.x * point2.y - point1.y * point2.x;
}

function subtractPoints(point1, point2) {
  var result = {};
  result.x = point1.x - point2.x;
  result.y = point1.y - point2.y;

  return result;
}
var intersection = function () {
  var vector = {};
  vector.oA = function (segment) {
    return segment.start;
  };
  vector.AB = function (segment) {
    var start = segment.start;
    var end = segment.end;
    return {
      x: end.x - start.x,
      y: end.y - start.y,
    };
  };
  vector.add = function (v1, v2) {
    return {
      x: v1.x + v2.x,
      y: v1.y + v2.y,
    };
  };
  vector.sub = function (v1, v2) {
    return {
      x: v1.x - v2.x,
      y: v1.y - v2.y,
    };
  };
  vector.scalarMult = function (s, v) {
    return {
      x: s * v.x,
      y: s * v.y,
    };
  };
  vector.crossProduct = function (v1, v2) {
    return v1.x * v2.y - v2.x * v1.y;
  };
  var self = {};
  self.vector = function (segment) {
    return vector.AB(segment);
  };
  self.intersectSegments = function (a, b) {
    // turn a = p + t*r where 0<=t<=1 (parameter)
    // b = q + u*s where 0<=u<=1 (parameter)
    var p = vector.oA(a);
    var r = vector.AB(a);

    var q = vector.oA(b);
    var s = vector.AB(b);

    var cross = vector.crossProduct(r, s);
    var qmp = vector.sub(q, p);
    var numerator = vector.crossProduct(qmp, s);
    var t = numerator / cross;
    var intersection = vector.add(p, vector.scalarMult(t, r));
    return intersection;
  };
  self.isParallel = function (a, b) {
    // a and b are line segments.
    // returns true if a and b are parallel (or co-linear)
    var r = vector.AB(a);
    var s = vector.AB(b);
    return vector.crossProduct(r, s) === 0;
  };
  self.isCollinear = function (a, b) {
    // a and b are line segments.
    // returns true if a and b are co-linear
    var p = vector.oA(a);
    var r = vector.AB(a);

    var q = vector.oA(b);
    var s = vector.AB(b);
    return vector.crossProduct(vector.sub(p, q), r) === 0;
  };
  self.safeIntersect = function (a, b) {
    if (self.isParallel(a, b) === false) {
      return self.intersectSegments(a, b);
    } else {
      return false;
    }
  };
  return self;
};
intersection.intersectSegments = intersection().intersectSegments;
intersection.intersect = intersection().safeIntersect;
intersection.isParallel = intersection().isParallel;
intersection.isCollinear = intersection().isCollinear;
intersection.describe = function (a, b) {
  var isCollinear = intersection().isCollinear(a, b);
  var isParallel = intersection().isParallel(a, b);
  var pointOfIntersection = undefined;
  if (isParallel === false) {
    pointOfIntersection = intersection().intersectSegments(a, b);
  }
  return {
    collinear: isCollinear,
    parallel: isParallel,
    intersection: pointOfIntersection,
  };
};

function Check_IEL_in_AEL(IEL, AEL) {
  //Loop throught all amendment equipment list data
  //Check if AEL item is added or removed then ensure it is added or removed from the Installed Equipment List
  var New_IEL = JSON.parse(JSON.stringify(IEL));
  var New_AEL = [];

  for (var a in AEL) {
    var A = AEL[a];
    New_IEL = Remove_or_Install_IEL(New_IEL, A);
  }

  function mysortfunction(a, b) {
    try {
      var o1 = a.ListType.toLowerCase();
      var o2 = b.ListType.toLowerCase();

      var p1 = a.Description.toLowerCase();
      var p2 = b.Description.toLowerCase();

      if (o1 < o2) return -1;
      if (o1 > o2) return 1;
      if (p1 < p2) return -1;
      if (p1 > p2) return 1;
      return 0;
    } catch (e) {
      console.log('Sort Failed');
      console.log(e);
      return 0;
    }
  }
  return New_IEL.sort(mysortfunction);
}

function Remove_or_Install_IEL(IEL, Item) {
  var New_IEL = JSON.parse(JSON.stringify(IEL));
  var found = false;
  var action = null;
  for (var i in New_IEL) {
    var I = New_IEL[i];
    if (
      Item.PartNum == I.PartNum &&
      Item.STCNum == I.STCNum &&
      Item.HorzArm == I.HorzArm &&
      Item.LatArm == I.LatArm &&
      Item.Description == I.Description &&
      Item.Weight == I.Weight
    ) {
      if (Item.Action == 'Removed') {
        New_IEL.splice(i, 1);
        return New_IEL;
        break;
      }
      found = true;
    }
  }
  if (!found && Item.Action == 'Installed')
    New_IEL.push({
      Abr: null,
      AcIdent: 'copy',
      AcType: 'copy',
      Conflict: '',
      Description: Item.Description,
      ExternalLoad: 0,
      Fuel: 0,
      HorzArm: Item.HorzArm,
      Kit: 0,
      KitItems: null,
      LatArm: Item.LatArm,
      ListType: 'Installed',
      LoadLat: null,
      LoadLatMax: 0,
      LoadLatMin: 0,
      LoadLong: null,
      LoadLongMax: 0,
      LoadLongMin: 0,
      Loadable: 0,
      MaxWT: null,
      Optional: 'Standard',
      PK_Item: null,
      PartNum: Item.PartNum,
      PrimaryKey: null,
      STCNum: Item.STCNum,
      SeatType: '',
      Seating: 0,
      SupName: '',
      SupReq: 0,
      Type: null,
      Units: Item.Units,
      VariableArms: 0,
      Weight: Item.Weight,
      cgGraph: null,
      cgRestrict: 0,
      group: 'copy',
      ielPrimaryKey: null,
    });
  return New_IEL;
}

function editAEL() {
  var i = ACIndex;
  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AEL&ident=' +
      encodeURIComponent(AC_data[i].ident),
    function (json_data) {
      AEL_data = json_data;
      if (AEL_data == '') {
        AEL_data = [];
      }
      var html = "<div style='page-break-before: always'><h4><center>" + iTrans('Equipment Lists') + '</center></h4>';
      $.getJSON(
        BASEURL +
          'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_IEL&ident=' +
          encodeURIComponent(AC_data[i].ident),
        function (IEL) {
          var Listtype = '';
          var KitLists = '';
          CofG_Graph = 'Standard';

          if (!$('#useOrigionalEQlist').is(':checked')) var IEL = Check_IEL_in_AEL(IEL, AEL_data);
          for (var e in IEL) {
            var IO = IEL[e];
            if (IO.cgRestrict == 1) CofG_Graph = IO.cgGraph;
            if (WBUnitLbl.Metric && IO.Units != 'Metric') {
              //convert IO to Metric
              var tokg = 0.453592;
              var tomm = 25.4;

              IO.HorzArm = Math.round(IO.HorzArm * tomm * 100) / 100;
              IO.LatArm = Math.round(IO.LatArm * tomm * 100) / 100;
              IO.MaxWT = Math.round(IO.MaxWT * tokg * 100) / 100;
              IO.Weight = Math.round(IO.Weight * tokg * 100) / 100;
            }
            if (!WBUnitLbl.Metric && IO.Units == 'Metric') {
              //convert IO Imperial
              var tolb = 2.20462;
              var toin = 0.0393701;
              IO.HorzArm = Math.round(IO.HorzArm * toin * 100) / 100;
              IO.LatArm = Math.round(IO.LatArm * toin * 100) / 100;
              IO.MaxWT = Math.round(IO.MaxWT * tolb * 100) / 100;
              IO.Weight = Math.round(IO.Weight * tolb * 100) / 100;
            }

            if (Listtype != IEL[e].ListType) {
              if (Listtype != '') html += '</tbody></table></div>';
              Listtype = IEL[e].ListType;
              if (e != 0) html += "<div style='page-break-before: always'>";
              html += "<h4 class='limit-table'>" + IEL[e].ListType + ' ' + iTrans('Equipment List') + '</h4>';
              html +=
                '<table style="width:800px;">' +
                '<thead>' +
                '<tr>' +
                '<th>' +
                iTrans('Part') +
                ' #</th>' +
                '<th>STC #</th>' +
                (IEL[e].ListType == 'Optional' ? '<th>' + iTrans('Abr') + '</th>' : '') +
                '<th>' +
                iTrans('Description') +
                '</th>' +
                '<th>' +
                iTrans('Weight') +
                ' ' +
                WBUnitLbl.Weight +
                '</th>' +
                '<th>Long Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th>Lat Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '</tr>' +
                '</thead>' +
                '<tbody>';
            }
            if (IEL[e].Kit == 1 && IEL[e].KitItems != null) {
              html +=
                "<tr class='yellowbg'><td style='width: 100px;'>" +
                IEL[e].PartNum +
                "</td><td style='width: 100px;'>" +
                IEL[e].STCNum +
                '</td>' +
                (IEL[e].ListType == 'Optional' ? '<td style="width: 50px;">' + IEL[e].Abr + '</td>' : '') +
                "<td style='width: 250px;'>" +
                IEL[e].Description +
                '</td><td>' +
                IEL[e].Weight +
                '</td><td>' +
                IEL[e].HorzArm +
                '</td><td>' +
                IEL[e].LatArm +
                '</td></tr>';
              KitLists +=
                '<h4 class="limit-table yellowbg">' + iTrans('Kit Items For') + ': ' + IEL[e].Description + '</h4>';
              KitLists += GetKithtml(IEL[e].KitItems);
            } else
              html +=
                "<tr><td style='width: 100px;'>" +
                IEL[e].PartNum +
                "</td><td style='width: 100px;'>" +
                IEL[e].STCNum +
                '</td>' +
                (IEL[e].ListType == 'Optional' ? '<td style="width: 50px;">' + IEL[e].Abr + '</td>' : '') +
                "<td style='width: 250px;'>" +
                IEL[e].Description +
                '</td><td>' +
                Number(IEL[e].Weight).toFixed(2) +
                '</td><td>' +
                IEL[e].HorzArm +
                '</td><td>' +
                IEL[e].LatArm +
                '</td></tr>';
          }
          html += '</tbody></table></div>';
          //WBHTML = html + KitLists;
          WBHTML = '';

          editAEL2(html + KitLists + '');
          //setTimeout("LoadPrintHTML()",1000);
        }
      );
    }
  );
}
var WBHTML = '';
var WB_REVISION_NO = new Date().toISOString();
var CofG_Graph = 'Standard';
/*
 * Boolean flag to determine if the user is viewing the report only using the 'View Aircraft W&B Report' action
 * on the revamp Aircraft List page. If true, it means that they have triggered that action and only want to see
 * the PDF dialog of the aircraft's report.
 */
var viewingReportOnly = false;

function editAEL2(EquipmentListHTML) {
  console.log('editAEL Called');
  if (WANDB == 0) $('#LITE_WB').prop('disabled', true);
  var i = ACIndex;
  var AC = AC_data[i];
  var acHTML = '';
  if (!Printview) {
    $('#PrintWB_BTN').show();
    WB_REVISION_NO = 'REV: ' + AC.ident + ' S/N:' + AC.SN + ' [' + new Date().toISOString() + ']';
  }
  acHTML +=
    '<h3><center>' +
    COMPANY +
    '</center></h3><h4><center>Ident: ' +
    AC.ident +
    '<br />Model: ' +
    AC.AcType +
    ' <br />Serial: ' +
    AC.SN +
    '<br />' +
    WB_REVISION_NO +
    '</center></h4>';
  WBHTML = acHTML;

  $('#ammendWBMENU').prop('checked', true);
  $('#Content_WB').html('');
  if (Printview) $('#PrintA').append(acHTML);
  var uhtml = '';
  $('#Content_WB').append("<div id='USCHARTC'></div>");
  var acHorzArm = Number(AC_data[i].LongMom) / Number(AC_data[i].Weight);
  var acLatArm = Number(AC_data[i].LatMom) / Number(AC_data[i].Weight);
  acHorzArm = parseFloat(acHorzArm).toFixed(2);
  acLatArm = parseFloat(acLatArm).toFixed(2);
  var dateFormatted = AC_data[i].Date;
  if (GROUP_DATA.Country != 'UNITED STATES') {
    $('#Content_WB').append("<h3 class='limit-table'>" + iTrans('Empty Weight and Balance Report') + '</h3>');
    if (Printview)
      $('#PrintA').append("<div><h4 class='limit-table'>" + iTrans('Empty Weight and Balance Report') + '</h4>');
    WBHTML += "<h4 class='limit-table'>" + iTrans('Empty Weight and Balance Report') + '</h4>';
  } else {
    if (Printview) $('#PrintA').append("<div><div id='USCHARTC2'></div>");
  }

  //USA Chart C html
  uhtml +=
    "<center><h4 class='limit-table'>" +
    iTrans('BASIC WEIGHT AND BALANCE RECORD') +
    "</h4><div class='limit-table'>" +
    iTrans('Continuous History of Changes in Structure or Equipment') +
    '<br />' +
    iTrans('Affecting Weight and Balance') +
    '</div>';
  var fontsize = '';
  if (Printview) fontsize = "style='font-size: 10px !important;'";
  uhtml +=
    "<h4 class='limit-table'><b>" +
    iTrans('Aircraft') +
    ' Model: </b>' +
    AC_data[i].AcType +
    ' <b>Serial Number: </b>' +
    AC_data[i].SN +
    ' <b>' +
    iTrans('Registration Number') +
    ': </b>' +
    AC_data[i].ident +
    '</h4></center>';
  uhtml += "<table border=1 class='wrap' " + fontsize + '>';
  uhtml +=
    '<thead><tr><th>' +
    iTrans('Date') +
    '</th><th>' +
    iTrans('Status') +
    '</th><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Weight') +
    '</th><th>Long Arm</th><th>Long Mom</th><th>Lat Arm</th><th>Lat Mom</th><th>AC ' +
    iTrans('Weight') +
    '</th><th>Long C.G.</th><th>Long MOM</th><th>Lat C.G.</th><th>Lat MOM</th></tr></thead><tbody>';
  uhtml +=
    "<tr class='whitebg'><td>" +
    dateFormatted +
    '</td><td><b>' +
    iTrans('Scaled') +
    '</b></td><td><b>' +
    iTrans('New Weight and Balance') +
    '</b></td><td></td><td></td><td></td><td></td><td></td><td>' +
    AC_data[i].Weight +
    WBUnitLbl.Weight +
    '</td><td>' +
    acHorzArm +
    WBUnitLbl.Arm +
    '</td><td>' +
    AC_data[i].LongMom +
    WBUnitLbl.Moment +
    '</td><td>' +
    acLatArm +
    WBUnitLbl.Arm +
    '</td><td>' +
    AC_data[i].LatMom +
    WBUnitLbl.Moment +
    '</td></tr>';

  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AEL&ident=' +
      encodeURIComponent(AC_data[i].ident),
    function (json_data) {
      AEL_data = json_data;
      if (AEL_data == '') {
        AEL_data = [];
      }

      //Load EmptyWeight Data

      var HorzMom;
      var LatMom;
      var TotalWt = Number(AC_data[i].Weight);
      var TotalHorzArm = acHorzArm;
      var TotalHorzMom = Number(AC_data[i].LongMom);
      var TotalLatArm = acLatArm;
      var TotalLatMom = Number(AC_data[i].LatMom);
      var latestDate;
      var aclass = 'limit-table';
      if (GROUP_DATA.Country != 'UNITED STATES') {
        if (Printview) {
          $('#PrintA').append(
            '<table style="width:800px;" class="limit-table">' +
              '<thead>' +
              '<tr>' +
              '<th>' +
              iTrans('Description') +
              '</th>' +
              '<th>' +
              iTrans('Action') +
              '</th>' +
              '<th>' +
              iTrans('Weight') +
              ' ' +
              WBUnitLbl.Weight +
              '</th>' +
              '<th>Long Arm ' +
              WBUnitLbl.Arm +
              '</th>' +
              '<th>Moment(H) ' +
              WBUnitLbl.Moment +
              '</th>' +
              '<th>Lat Arm ' +
              WBUnitLbl.Arm +
              '</th>' +
              '<th>Moment(L) ' +
              WBUnitLbl.Moment +
              '</th>' +
              '</tr>' +
              '</thead>' +
              '<tbody>'
          );
        }
        WBHTML +=
          '<div style="width: 100%; overflow: auto"><table class="' +
          aclass +
          '">' +
          '<thead>' +
          '<tr>' +
          '<th>' +
          iTrans('Description') +
          '</th>' +
          '<th>' +
          iTrans('Action') +
          '</th>' +
          '<th>' +
          iTrans('Weight') +
          ' ' +
          WBUnitLbl.Weight +
          '</th>' +
          '<th>Long Arm ' +
          WBUnitLbl.Arm +
          '</th>' +
          '<th>Moment(H) ' +
          WBUnitLbl.Moment +
          '</th>' +
          '<th>Lat Arm ' +
          WBUnitLbl.Arm +
          '</th>' +
          '<th>Moment(L) ' +
          WBUnitLbl.Moment +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>';
      }

      $('#Content_WB').append(
        '<div style="width: 100%; overflow: auto"><table class="' +
          aclass +
          '">' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="0">Del</th>' +
          '<th data-priority="persist">' +
          iTrans('Description') +
          '</th>' +
          '<th data-priority="3">' +
          iTrans('Action') +
          '</th>' +
          '<th data-priority="4" style="width:55px;">' +
          iTrans('Weight') +
          ' ' +
          WBUnitLbl.Weight +
          '</th>' +
          '<th data-priority="4" style="width:50px;">Long Arm ' +
          WBUnitLbl.Arm +
          '</th>' +
          '<th data-priority="4" style="width:55px;">Moment(H) ' +
          WBUnitLbl.Moment +
          '</th>' +
          '<th data-priority="4" style="width:50px;">Lat Arm ' +
          WBUnitLbl.Arm +
          '</th>' +
          '<th data-priority="4" style="width:55px;">Moment(L) ' +
          WBUnitLbl.Moment +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>'
      );

      $('#Content_WB tr:last').after(
        '<tr><td></td><td>' +
          iTrans('Scaled On') +
          ': ' +
          dateFormatted +
          '</td><td>' +
          iTrans('As weighed') +
          '</td><td>' +
          AC_data[i].Weight +
          WBUnitLbl.Weight +
          '</td><td>' +
          acHorzArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          AC_data[i].LongMom +
          WBUnitLbl.Moment +
          '</td><td>' +
          acLatArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          AC_data[i].LatMom +
          WBUnitLbl.Moment +
          '</td></tr>'
      );
      if (GROUP_DATA.Country != 'UNITED STATES') {
        if (Printview) {
          $('#PrintA tr:last').after(
            "<tr><td style='width: 300px;'>" +
              iTrans('Scaled On') +
              ': ' +
              dateFormatted +
              "</td><td style='width: 100px;'>As weighed</td><td>" +
              AC_data[i].Weight +
              WBUnitLbl.Weight +
              '</td><td>' +
              acHorzArm +
              WBUnitLbl.Arm +
              '</td><td>' +
              AC_data[i].LongMom +
              WBUnitLbl.Moment +
              '</td><td>' +
              acLatArm +
              WBUnitLbl.Arm +
              '</td><td>' +
              AC_data[i].LatMom +
              WBUnitLbl.Moment +
              '</td></tr>'
          );
        }
        WBHTML +=
          '<tr><td>' +
          iTrans('Scaled On') +
          ': ' +
          dateFormatted +
          '</td><td>' +
          iTrans('As weighed') +
          '</td><td>' +
          AC_data[i].Weight +
          WBUnitLbl.Weight +
          '</td><td>' +
          acHorzArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          AC_data[i].LongMom +
          WBUnitLbl.Moment +
          '</td><td>' +
          acLatArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          AC_data[i].LatMom +
          WBUnitLbl.Moment +
          '</td></tr>';
      }

      for (var a in AEL_data) {
        var I = AEL_data[a];
        var K = null;
        if (I.Kit == 1) {
          K = GetKitTotalChange(I);
          I.Weight = K.Weight;
          I.HorzArm = K.HorzArm;
          I.LatArm = K.LatArm;
          I.HorzMom = K.HorzMom;
          I.LatMom = K.LatMom;
        }
        if (I.ANum != 0) continue;
        var IO = I;
        if (WBUnitLbl.Metric && IO.Units != 'Metric') {
          //convert IO to Metric
          var tokg = 0.453592;
          var tomm = 25.4;

          IO.HorzArm = Math.round(IO.HorzArm * tomm * 100) / 100;
          IO.LatArm = Math.round(IO.LatArm * tomm * 100) / 100;
          IO.Weight = Math.round(IO.Weight * tokg * 100) / 100;
        }
        if (!WBUnitLbl.Metric && IO.Units == 'Metric') {
          //convert IO Imperial
          var tolb = 2.20462;
          var toin = 0.0393701;
          IO.HorzArm = Math.round(IO.HorzArm * toin * 100) / 100;
          IO.LatArm = Math.round(IO.LatArm * toin * 100) / 100;
          IO.Weight = Math.round(IO.Weight * tolb * 100) / 100;
        }
        if (I.Kit == 1) {
          HorzMom = Number(parseFloat(I.HorzMom)).toFixed(2);
          LatMom = Number(parseFloat(I.LatMom)).toFixed(2);
        } else {
          HorzMom = Number(parseFloat(I.HorzArm * I.Weight)).toFixed(2);
          LatMom = Number(parseFloat(I.LatArm * I.Weight)).toFixed(2);
        }
        if (I.Action == 'Installed') {
          TotalHorzMom = Number(TotalHorzMom) + Number(parseFloat(HorzMom).toFixed(2));
          TotalLatMom = Number(TotalLatMom) + Number(parseFloat(LatMom).toFixed(2));
          TotalWt = Number(TotalWt) + Number(I.Weight);
          TotalLatArm = Number(parseFloat(TotalLatMom) / parseFloat(TotalWt)).toFixed(2);
          TotalHorzArm = Number(parseFloat(TotalHorzMom) / parseFloat(TotalWt)).toFixed(2);
        } else {
          TotalHorzMom = Number(TotalHorzMom) - Number(parseFloat(HorzMom).toFixed(2));
          TotalLatMom = Number(TotalLatMom) - Number(parseFloat(LatMom).toFixed(2));
          TotalWt = Number(TotalWt) - Number(I.Weight);
          TotalLatArm = Number(parseFloat(TotalLatMom) / parseFloat(TotalWt)).toFixed(2);
          TotalHorzArm = Number(parseFloat(TotalHorzMom) / parseFloat(TotalWt)).toFixed(2);
        }

        TotalHorzMom = Number(parseFloat(TotalHorzMom)).toFixed(2);
        TotalLatMom = Number(parseFloat(TotalLatMom)).toFixed(2);
        TotalWt = Number(parseFloat(TotalWt)).toFixed(2);

        $('#Content_WB tr:last').after(
          "<tr><td><input type='hidden' id='KFM_WB_AEL-Index-" +
            I.PrimaryKey +
            "' value='" +
            a +
            "' /><button class='action-btn' id='KFM_WB_AEL-DeleteID-" +
            I.PrimaryKey +
            "-0' name='PrimaryKey'><img src='./images/delete.svg' /></button></td><td><textarea id='KFM_WB_AEL-Description-" +
            I.PrimaryKey +
            "' name='Description'></textarea></td><td><select id='KFM_WB_AEL-Action-" +
            I.PrimaryKey +
            "' name='Action'/></td><td><input " +
            InputStyle +
            " type='number' step='0.01'data-role='none' id='KFM_WB_AEL-Weight-" +
            I.PrimaryKey +
            "' name='Weight' value='" +
            I.Weight +
            "'/></td><td><input " +
            InputStyle +
            " type='number' step='0.01'data-role='none' id='KFM_WB_AEL-HorzArm-" +
            I.PrimaryKey +
            "' name='HorzArm' value='" +
            I.HorzArm +
            "'/></td><td>" +
            HorzMom +
            '</td><td><input ' +
            InputStyle +
            " type='number' step='0.01'data-role='none' id='KFM_WB_AEL-LatArm-" +
            I.PrimaryKey +
            "' name='LatArm' value='" +
            I.LatArm +
            "'/></td><td>" +
            LatMom +
            '</td></tr>'
        );
        //<td><img id='KFM_WB_AEL-"+I.ANum+"-" + I.PrimaryKey +"' src='Icons/plus.jpg' name='AddAmd'/></td><td><img id='KFM_WB_AEL-"+I.ANum+"-" + I.PrimaryKey + "-EL' src='Icons/list.bmp' name='AddAmdEL'/></td>
        $('#KFM_WB_AEL-Description-' + I.PrimaryKey).val(I.Description);
        $('#KFM_WB_AEL-Action-' + I.PrimaryKey).append($('<option />').val('Removed').text(iTrans('Removed')));
        $('#KFM_WB_AEL-Action-' + I.PrimaryKey).append($('<option />').val('Installed').text(iTrans('Installed')));
        $('#KFM_WB_AEL-Action-' + I.PrimaryKey).val(I.Action);
        $('#KFM_WB_AEL-Action-' + I.PrimaryKey).change(WBchange);
        $('#KFM_WB_AEL-Description-' + I.PrimaryKey).change(WBchange);
        $('#KFM_WB_AEL-Weight-' + I.PrimaryKey).change(WBchange);
        $('#KFM_WB_AEL-HorzArm-' + I.PrimaryKey).change(WBchange);
        $('#KFM_WB_AEL-LatArm-' + I.PrimaryKey).change(WBchange);
        $('#KFM_WB_AEL-DeleteID-' + I.PrimaryKey + '-0').click(deleteRec);

        if (GROUP_DATA.Country != 'UNITED STATES') {
          if (Printview)
            $('#PrintA tr:last').after(
              '<tr><td>' +
                IO.Description +
                '</td><td>' +
                IO.Action +
                '</td><td>' +
                IO.Weight +
                '</td><td>' +
                IO.HorzArm +
                '</td><td>' +
                HorzMom +
                '</td><td>' +
                IO.LatArm +
                '</td><td>' +
                LatMom +
                '</td></tr>'
            );
          WBHTML +=
            '<tr><td>' +
            IO.Description +
            '</td><td>' +
            IO.Action +
            '</td><td>' +
            IO.Weight +
            '</td><td>' +
            IO.HorzArm +
            '</td><td>' +
            HorzMom +
            '</td><td>' +
            IO.LatArm +
            '</td><td>' +
            LatMom +
            '</td></tr>';
        }
        uhtml +=
          "<tr class='" +
          actionClass +
          "'><td>" +
          AC_data[i].Date +
          '</td><td>' +
          IO.Action +
          '</td><td>' +
          IO.Description +
          '</td><td>' +
          IO.Weight +
          WBUnitLbl.Weight +
          '</td><td>' +
          IO.HorzArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          HorzMom +
          WBUnitLbl.Moment +
          '</td><td>' +
          IO.LatArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          LatMom +
          WBUnitLbl.Moment +
          '</td><td>' +
          TotalWt +
          WBUnitLbl.Weight +
          '</td><td>' +
          TotalHorzArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          TotalHorzMom +
          WBUnitLbl.Moment +
          '</td><td>' +
          TotalLatArm +
          WBUnitLbl.Arm +
          '</td><td>' +
          TotalLatMom +
          WBUnitLbl.Moment +
          '</td></tr>';
      }

      $('#Content_WB tr:last').after(
        "<tr><td style='border-right: 0px;'>" +
          '<Button  onClick="recalcAmmendment();" data-mini="true" ><div class="vertical-center-container"><img src="./images/refresh.svg"></div></Button>' +
          "</td><td><Button  style='width:200px;' id='KFM_WB_AEL-0' data-inline='true' data-mini='true' data-icon='plus' class='greenbg'>" +
          iTrans('New Blank Item') +
          "</Button></td><th style='border-right: 0px;'>Total:</th><td>" +
          TotalWt +
          '</td><td>' +
          TotalHorzArm +
          '</td><td>' +
          TotalHorzMom +
          '</td><td>' +
          TotalLatArm +
          '</td><td>' +
          TotalLatMom +
          '</td></tr>'
      );
      $('#KFM_WB_AEL-0').click(addAEL);
      $('#Content_WB tr:last').after('</table>');

      if (GROUP_DATA.Country != 'UNITED STATES') {
        if (Printview) {
          $('#PrintA tr:last').after(
            "<tr><td style='white-space: nowrap; text-align: right;'><b>" +
              iTrans('EMPTY WEIGHT') +
              ' C of G - </b></td><td><b>' +
              iTrans('Total') +
              ':</b></th><td><b>' +
              TotalWt +
              '</b></td><td><b>' +
              TotalHorzArm +
              '</b></td><td><b>' +
              TotalHorzMom +
              '</b></td><td><b>' +
              TotalLatArm +
              '</b></td><td><b>' +
              TotalLatMom +
              '</b></td></tr>'
          );
        }
        WBHTML +=
          "<tr><td style='text-align: right;'><b>" +
          iTrans('EMPTY WEIGHT') +
          ' C of G - </b></td><td><b>' +
          iTrans('Total') +
          ':</b></th><td><b>' +
          Number(TotalWt).toFixed(2) +
          '</b></td><td><b>' +
          TotalHorzArm +
          '</b></td><td><b>' +
          TotalHorzMom +
          '</b></td><td><b>' +
          TotalLatArm +
          '</b></td><td><b>' +
          TotalLatMom +
          '</b></td></tr>';

        if (Printview) $('#PrintA tr:last').after('</table>');
        WBHTML += '</table></div>';
      }

      var curA = 1;
      if (AEL_data.length == 0) {
        $('#Content_WB').append("<h3 class='limit-table'>" + iTrans('No Amendments Found') + '</h3>');
        if (GROUP_DATA.Country != 'UNITED STATES')
          WBHTML += "<h3 class='limit-table'>" + iTrans('No Amendments Found') + '</h3>';
        if (Printview && GROUP_DATA.Country != 'UNITED STATES')
          $('#PrintA').append("<h5 class='limit-table'>" + iTrans('No Amendments Found') + '</h5>');
      } else {
        //Load ammendment data to display
        var lastAmd = AEL_data.length;
        var lastA = AEL_data[lastAmd - 1].ANum;

        for (var a in AEL_data) {
          var I = AEL_data[a];
          var K = null;
          if (I.Kit == 1) {
            K = GetKitTotalChange(I);
            I.Weight = K.Weight;
            I.HorzArm = K.HorzArm;
            I.LatArm = K.LatArm;
            I.HorzMom = K.HorzMom;
            I.LatMom = K.LatMom;
          }
          if (I.ANum == 0) continue;
          var IO = I;
          if (WBUnitLbl.Metric && IO.Units != 'Metric') {
            //convert IO to Metric
            var tokg = 0.453592;
            var tomm = 25.4;

            IO.HorzArm = Math.round(IO.HorzArm * tomm * 100) / 100;
            IO.LatArm = Math.round(IO.LatArm * tomm * 100) / 100;
            IO.Weight = Math.round(IO.Weight * tokg * 100) / 100;
          }
          if (!WBUnitLbl.Metric && IO.Units == 'Metric') {
            //convert IO Imperial
            var tolb = 2.20462;
            var toin = 0.0393701;
            IO.HorzArm = Math.round(IO.HorzArm * toin * 100) / 100;
            IO.LatArm = Math.round(IO.LatArm * toin * 100) / 100;
            IO.Weight = Math.round(IO.Weight * tolb * 100) / 100;
          }

          if (I.ANum == curA) {
            latestDate = I.Date;
            if (GROUP_DATA.Country != 'UNITED STATES') {
              $('#Content_WB').append(
                "<br /><table style='width:400px;background: none; border: 0px;'><tr><th><h2>" +
                  iTrans('Amendment') +
                  '# ' +
                  curA +
                  ' ' +
                  iTrans('Dated') +
                  ":</h2></th><th><input type='date' id='KFM_WB_AEL-Date-" +
                  I.PrimaryKey +
                  "' name='Date' data-inline='true'/></th></tr></table>"
              );
            } else {
              $('#Content_WB').append(
                "<br /><table style='width:400px;background: none; border: 0px;'><tr><th><h2>" +
                  iTrans('Change Date') +
                  '# ' +
                  curA +
                  ' ' +
                  iTrans('Dated') +
                  ":</h2></th><th><input type='date' id='KFM_WB_AEL-Date-" +
                  I.PrimaryKey +
                  "' name='Date' data-inline='true'/></th></tr></table>"
              );
            }
            var dateFormatted2 = I.Date;
            if (GROUP_DATA.Country != 'UNITED STATES') {
              if (Printview)
                $('#PrintA').append(
                  '<h5>' + iTrans('Amendment') + ' # ' + curA + ' ' + iTrans('Dated') + ': ' + dateFormatted2 + '</h5>'
                );
              WBHTML +=
                "<h5 class='limit-table'>" +
                iTrans('Amendment') +
                ' # ' +
                curA +
                ' ' +
                iTrans('Dated') +
                ': ' +
                dateFormatted2 +
                '</h5>';
              if (Printview)
                $('#Lamd').html(
                  '<h5>' + iTrans('Amendment') + ' # ' + curA + ' ' + iTrans('Dated') + ': ' + dateFormatted2 + '</h5>'
                );
            }
            $('#KFM_WB_AEL-Date-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-Date-' + I.PrimaryKey).val(I.Date);
            if (I.Date == null) {
              var PK = I.PrimaryKey;
              setTimeout(function (e) {
                $('#KFM_WB_AEL-Date-' + PK).val(new Date().toISOString().substr(0, 10));
                $('#KFM_WB_AEL-Date-' + PK).change();
                console.log(PK);
              }, 1000);
            }

            if (GROUP_DATA.Country != 'UNITED STATES') {
              if (Printview) {
                $('#PrintA').append(
                  '<table style="width:800px;" class="wbthird-td-fill">' +
                    '<thead>' +
                    '<tr>' +
                    '<th>' +
                    iTrans('Part') +
                    ' #</th>' +
                    '<th>STC #</th>' +
                    '<th>' +
                    iTrans('Description') +
                    '</th>' +
                    '<th>' +
                    iTrans('Option') +
                    '</th>' +
                    '<th>' +
                    iTrans('Weight') +
                    ' ' +
                    WBUnitLbl.Weight +
                    '</th>' +
                    '<th>Long Arm ' +
                    WBUnitLbl.Arm +
                    '</th>' +
                    '<th>Moment(H) ' +
                    WBUnitLbl.Moment +
                    '</th>' +
                    '<th>Lat Arm ' +
                    WBUnitLbl.Arm +
                    '</th>' +
                    '<th>Moment(L) ' +
                    WBUnitLbl.Moment +
                    '</th>' +
                    '</tr>' +
                    '</thead>' +
                    '<tbody><tr>'
                );
              }
              WBHTML +=
                '<div style="width: 100%; overflow: auto"><table class="' +
                aclass +
                '">' +
                '<thead>' +
                '<tr>' +
                '<th>' +
                iTrans('Part') +
                ' #</th>' +
                '<th>STC #</th>' +
                '<th>' +
                iTrans('Description') +
                '</th>' +
                '<th>' +
                iTrans('Option') +
                '</th>' +
                '<th>' +
                iTrans('Weight') +
                ' ' +
                WBUnitLbl.Weight +
                '</th>' +
                '<th>Long Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th>Moment(H) ' +
                WBUnitLbl.Moment +
                '</th>' +
                '<th>Lat Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th>Moment(L) ' +
                WBUnitLbl.Moment +
                '</th>' +
                '</tr>' +
                '</thead>' +
                '<tbody><tr>';
            }
            var aclass = 'limit-table';
            if (I.ANum == lastA) aclass = '';
            $('#Content_WB').append(
              '<div style="width: 100%; overflow: auto"><table class="' +
                aclass +
                '">' +
                '<thead>' +
                '<tr>' +
                '<th data-priority="0">Del</th>' +
                '<th data-priority="1">' +
                iTrans('Part') +
                ' #</th>' +
                '<th data-priority="2">STC #</th>' +
                '<th data-priority="persist">' +
                iTrans('Description') +
                '</th>' +
                '<th data-priority="3">' +
                iTrans('Option') +
                '</th>' +
                '<th data-priority="4" style="width:55px;">' +
                iTrans('Weight') +
                ' ' +
                WBUnitLbl.Weight +
                '</th>' +
                '<th data-priority="4" style="width:50px;">Long Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th data-priority="4" style="width:55px;">Moment(H) ' +
                WBUnitLbl.Moment +
                '</th>' +
                '<th data-priority="4" style="width:50px;">Lat Arm ' +
                WBUnitLbl.Arm +
                '</th>' +
                '<th data-priority="4" style="width:55px;">Moment(L) ' +
                WBUnitLbl.Moment +
                '</th>' +
                '</tr>' +
                '</thead>' +
                '<tbody><tr>'
            );

            curA++;
          }
          if (I.Kit == 1) {
            HorzMom = Number(parseFloat(I.HorzMom)).toFixed(2);
            LatMom = Number(parseFloat(I.LatMom)).toFixed(2);
          } else {
            HorzMom = Number(parseFloat(I.HorzArm * I.Weight)).toFixed(2);
            LatMom = Number(parseFloat(I.LatArm * I.Weight)).toFixed(2);
          }
          if (I.Action == 'Installed') {
            TotalHorzMom = Number(TotalHorzMom) + Number(parseFloat(HorzMom).toFixed(2));
            TotalLatMom = Number(TotalLatMom) + Number(parseFloat(LatMom).toFixed(2));
            TotalWt = Number(TotalWt) + Number(I.Weight);
            TotalLatArm = Number(parseFloat(TotalLatMom) / parseFloat(TotalWt)).toFixed(2);
            TotalHorzArm = Number(parseFloat(TotalHorzMom) / parseFloat(TotalWt)).toFixed(2);
          } else {
            TotalHorzMom = Number(TotalHorzMom) - Number(parseFloat(HorzMom).toFixed(2));
            TotalLatMom = Number(TotalLatMom) - Number(parseFloat(LatMom).toFixed(2));
            TotalWt = Number(TotalWt) - Number(I.Weight);
            TotalLatArm = Number(parseFloat(TotalLatMom) / parseFloat(TotalWt)).toFixed(2);
            TotalHorzArm = Number(parseFloat(TotalHorzMom) / parseFloat(TotalWt)).toFixed(2);
          }

          TotalHorzMom = Number(parseFloat(TotalHorzMom)).toFixed(2);
          TotalLatMom = Number(parseFloat(TotalLatMom)).toFixed(2);

          if (GROUP_DATA.Country != 'UNITED STATES') {
            if (Printview)
              $('#PrintA tr:last').after(
                "<tr><td style='width: 100px;'>" +
                  I.PartNum +
                  "</td><td style='width: 100px;'>" +
                  I.STCNum +
                  "</td><td style='width: 250px;'>" +
                  I.Description +
                  '</td><td>' +
                  I.Action +
                  '</td><td>' +
                  I.Weight +
                  '</td><td>' +
                  I.HorzArm +
                  '</td><td>' +
                  HorzMom +
                  '</td><td>' +
                  I.LatArm +
                  '</td><td>' +
                  LatMom +
                  '</td></tr>'
              );
            WBHTML +=
              '<tr><td>' +
              I.PartNum +
              '</td><td>' +
              I.STCNum +
              '</td><td>' +
              I.Description +
              '</td><td>' +
              I.Action +
              '</td><td>' +
              I.Weight +
              '</td><td>' +
              I.HorzArm +
              '</td><td>' +
              HorzMom +
              '</td><td>' +
              I.LatArm +
              '</td><td>' +
              LatMom +
              '</td></tr>';
          }
          if (I.ANum == lastA) {
            //last Amendment boxes and editable
            $('#Content_WB tr:last').after(
              '<tr><td><input ' +
                InputStyle +
                " type='hidden' id='KFM_WB_AEL-Index-" +
                I.PrimaryKey +
                "' value='" +
                a +
                "' /><button class='action-btn' id='KFM_WB_AEL-DeleteID-" +
                I.PrimaryKey +
                '-' +
                curA +
                "' name='PrimaryKey'><img src='./images/delete.svg' /></button></td><td><input " +
                InputStyle +
                " type='text' id='KFM_WB_AEL-PartNum-" +
                I.PrimaryKey +
                "' name='PartNum' value='" +
                I.PartNum +
                "' /></td><td><input " +
                InputStyle +
                " type='text' id='KFM_WB_AEL-STCNum-" +
                I.PrimaryKey +
                "' name='STCNum' value='" +
                I.STCNum +
                "'/></td><td><textarea id='KFM_WB_AEL-Description-" +
                I.PrimaryKey +
                "' name='Description'></textarea></td><td><select id='KFM_WB_AEL-Action-" +
                I.PrimaryKey +
                "' name='Action'/></td><td><input " +
                InputStyle +
                " type='number' step='0.01'data-role='none' id='KFM_WB_AEL-Weight-" +
                I.PrimaryKey +
                "' name='Weight' value='" +
                I.Weight +
                "'/></td><td><input " +
                InputStyle +
                " type='number' step='0.01'data-role='none' id='KFM_WB_AEL-HorzArm-" +
                I.PrimaryKey +
                "' name='HorzArm' value='" +
                I.HorzArm +
                "'/></td><td>" +
                HorzMom +
                '</td><td><input ' +
                InputStyle +
                " type='number' step='0.01'data-role='none' id='KFM_WB_AEL-LatArm-" +
                I.PrimaryKey +
                "' name='LatArm' value='" +
                I.LatArm +
                "'/></td><td>" +
                LatMom +
                '</td></tr>'
            );
            //<td><img id='KFM_WB_AEL-"+I.ANum+"-" + I.PrimaryKey +"' src='Icons/plus.jpg' name='AddAmd'/></td><td><img id='KFM_WB_AEL-"+I.ANum+"-" + I.PrimaryKey + "-EL' src='Icons/list.bmp' name='AddAmdEL'/></td>
            $('#KFM_WB_AEL-Description-' + I.PrimaryKey).val(I.Description);
            $('#KFM_WB_AEL-Action-' + I.PrimaryKey).append($('<option />').val('Removed').text(iTrans('Removed')));
            $('#KFM_WB_AEL-Action-' + I.PrimaryKey).append($('<option />').val('Installed').text(iTrans('Installed')));
            $('#KFM_WB_AEL-Action-' + I.PrimaryKey).val(I.Action);
            $('#KFM_WB_AEL-Action-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-PartNum-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-STCNum-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-Description-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-Weight-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-HorzArm-' + I.PrimaryKey).change(WBchange);
            $('#KFM_WB_AEL-LatArm-' + I.PrimaryKey).change(WBchange);

            $('#KFM_WB_AEL-DeleteID-' + I.PrimaryKey + '-' + curA).click(deleteRec);
          } else {
            //Past Amendment not editable
            $('#Content_WB tr:last').after(
              "<tr><td></td><td><input type='hidden' id='KFM_WB_AEL-Index-" +
                I.PrimaryKey +
                "' value='" +
                a +
                "' />" +
                I.PartNum +
                '</td><td>' +
                I.STCNum +
                '</td><td>' +
                I.Description +
                '</td><td>' +
                I.Action +
                '</td><td>' +
                I.Weight +
                '</td><td>' +
                I.HorzArm +
                '</td><td>' +
                HorzMom +
                '</td><td>' +
                I.LatArm +
                '</td><td>' +
                LatMom +
                '</td></tr>'
            );
            //if (!GPS_DEBUG) $("#KFM_WB_AEL-Date-" + I.PrimaryKey).prop("disabled", true);
          }
          var Na = parseInt(a) + 1;
          var nextA = a;
          if (Na != AEL_data.length) {
            nextA = AEL_data[Na].ANum;
          }
          TotalWt = Number(parseFloat(TotalWt)).toFixed(2);
          if (a == lastAmd - 1 || nextA == curA) {
            if (a == lastAmd - 1) {
              $('#Content_WB tr:last').after(
                "<tr><td style='border-right: 0px;'></td><td style='border-right: 0px;'></td><td style='border-right: 0px;'><Button  style='width:200px;' id='KFM_WB_AEL-" +
                  I.ANum +
                  '-' +
                  I.PrimaryKey +
                  "' data-inline='true' data-mini='true' data-icon='plus' class='greenbg'>" +
                  iTrans('New Blank Item') +
                  "</Button></td><td style='border-right: 0px;'><Button  style='width:200px;' id='KFM_WB_AEL-" +
                  I.ANum +
                  '-' +
                  I.PrimaryKey +
                  "-EL' data-inline='true' data-mini='true' data-icon='plus' class='greenbg'>" +
                  iTrans('Add From List') +
                  "</Button></td><th style='border-right: 0px;'>Total:</th><td>" +
                  TotalWt +
                  '</td><td>' +
                  TotalHorzArm +
                  '</td><td>' +
                  TotalHorzMom +
                  '</td><td>' +
                  TotalLatArm +
                  '</td><td>' +
                  TotalLatMom +
                  '</td></tr>'
              );
              $('#KFM_WB_AEL-' + I.ANum + '-' + I.PrimaryKey).click(addAEL);
              $('#KFM_WB_AEL-' + I.ANum + '-' + I.PrimaryKey + '-EL').click(addAEL_EL);
            } else {
              $('#Content_WB tr:last').after(
                "<tr><td></td><td></td><td></td><td style='text-align: right;'><b>" +
                  iTrans('EMPTY WEIGHT') +
                  ' C of G - </b></td><td><b>' +
                  iTrans('Total') +
                  ':</b></th><td><b>' +
                  TotalWt +
                  '</b></td><td><b>' +
                  TotalHorzArm +
                  '</b></td><td><b>' +
                  TotalHorzMom +
                  '</b></td><td><b>' +
                  TotalLatArm +
                  '</b></td><td><b>' +
                  TotalLatMom +
                  '</b></td></tr>'
              );
            }
            $('#Content_WB tr:last').after('</table></div>');
            if (GROUP_DATA.Country != 'UNITED STATES') {
              if (Printview) {
                $('#PrintA tr:last').after(
                  "<tr><td></td><td></td><td style='white-space: nowrap; text-align: right;'><b>" +
                    iTrans('EMPTY WEIGHT') +
                    ' C of G - </b></td><td><b>' +
                    iTrans('Total') +
                    ':</b></th><td><b>' +
                    TotalWt +
                    '</b></td><td><b>' +
                    TotalHorzArm +
                    '</b></td><td><b>' +
                    TotalHorzMom +
                    '</b></td><td><b>' +
                    TotalLatArm +
                    '</b></td><td><b>' +
                    TotalLatMom +
                    '</b></td></tr>'
                );
                $('#PrintA tr:last').after('</table>');
              }
              WBHTML +=
                "<tr><td></td><td></td><td style='text-align: right;'><b>" +
                iTrans('EMPTY WEIGHT') +
                ' C of G - </b></td><td><b>' +
                iTrans('Total') +
                ':</b></th><td><b>' +
                TotalWt +
                '</b></td><td><b>' +
                TotalHorzArm +
                '</b></td><td><b>' +
                TotalHorzMom +
                '</b></td><td><b>' +
                TotalLatArm +
                '</b></td><td><b>' +
                TotalLatMom +
                '</b></td></tr></tbody></table></div>';
            }
          }

          if (I.LatArm == null) I.LatArm = '0.00';
          var actionClass = 'whitebg';
          if (I.Action == 'Removed') actionClass = 'greybg';
          uhtml +=
            "<tr class='" +
            actionClass +
            "'><td>" +
            latestDate +
            '</td><td>' +
            I.Action +
            '</td><td>' +
            I.Description +
            '</td><td>' +
            I.Weight +
            WBUnitLbl.Weight +
            '</td><td>' +
            I.HorzArm +
            WBUnitLbl.Arm +
            '</td><td>' +
            HorzMom +
            WBUnitLbl.Moment +
            '</td><td>' +
            I.LatArm +
            WBUnitLbl.Arm +
            '</td><td>' +
            LatMom +
            WBUnitLbl.Moment +
            '</td><td>' +
            TotalWt +
            WBUnitLbl.Weight +
            '</td><td>' +
            TotalHorzArm +
            WBUnitLbl.Arm +
            '</td><td>' +
            TotalHorzMom +
            WBUnitLbl.Moment +
            '</td><td>' +
            TotalLatArm +
            WBUnitLbl.Arm +
            '</td><td>' +
            TotalLatMom +
            WBUnitLbl.Moment +
            '</td></tr>';
        } //end for a AEL loop

        uhtml += '</tbody></table>'; //End US Chart C table
        if (GROUP_DATA.Country == 'UNITED STATES') {
          if (Printview) $('#USCHARTC2').html(uhtml);

          $('#USCHARTC').html(uhtml);
          WBHTML = WBHTML + uhtml;
        }
      } //end else if
      if (Printview) $('#PrintA').append(EquipmentListHTML);
      WBHTML += EquipmentListHTML;
      $('#Content_WB').append("<div id='EQList'></div>");
      $('#Content_WB').enhanceWithin();
      $('#EQList').append(
        '<Button  class="greenbg" id="AddAEL-' +
          curA +
          '" data-mini="true" data-icon="plus">' +
          iTrans('Create New Amendment') +
          '</Button>'
      );
      $('#EQList').append(
        '<Button  class="redbg" id="refreshAEL" data-mini="true" data-icon="check">' +
          iTrans('Save Changes / Update Calculations') +
          '</Button>'
      );
      $('#AddAEL-' + curA).click(addAEL);
      $('#refreshAEL').click(refreshAmmendment);
      $('#Content_WB').enhanceWithin();
      if (newAEL) {
        var last_li = $('#EQList').offset().top;
        $.mobile.silentScroll(last_li);
        newAEL = false;
      }
      var AmendmentTXT = '' + iTrans('Amendment') + ' # ' + (curA - 1) + ' ' + iTrans('Dated') + ': ' + latestDate;
      if (curA == 1) {
        AmendmentTXT = '' + iTrans('No Amendments') + '';
      }

      let updateTcUrl =
        BASEURL +
        'menu-Maintenance-AJAX.php?action=updateTC&ident=' +
        encodeURIComponent(AC_data[i].ident) +
        '&anum=0&date=' +
        AC_data[i].Date +
        '&description=Original&weight=' +
        AC_data[i].Weight +
        '&horzmom=' +
        AC_data[i].LongMom +
        '&latmom=' +
        AC_data[i].LatMom;

      if (AEL_data.length != 0) {
        var amd = curA - 1;

        if (amd == 0) {
          latestDate = AC_data[i].Date;
        }

        updateTcUrl =
          BASEURL +
          'menu-Maintenance-AJAX.php?action=updateTC&ident=' +
          encodeURIComponent(AC_data[i].ident) +
          '&anum=' +
          amd +
          '&date=' +
          latestDate +
          '&description=Amendment ' +
          amd +
          '&weight=' +
          TotalWt +
          '&horzmom=' +
          TotalHorzMom +
          '&latmom=' +
          TotalLatMom;
      }

      $.getJSON(updateTcUrl, function (json_data) {
        if (json_data[0].ServerResponse == 'Success') {
          if (Printview || RuneditTC) {
            RuneditTC = false;
            editTC(dateFormatted, AmendmentTXT);
          } else {
            AC_REBUILD = false;
            if (WANDB == 0) {
              console.log('Amendments Found');
              editTC(dateFormatted, AmendmentTXT); //update temp configs for simple wandb aircraft
            } else {
              if (!viewingReportOnly) {
                $('#ammendWBMENU').prop('disabled', false).checkboxradio('refresh');
                $('#installWBMENU').prop('disabled', false).checkboxradio('refresh');
                $('#tcWBMENU').prop('disabled', false).checkboxradio('refresh');
              }
            }
          }
        } else {
          alert(json_data[0].ServerResponse);
        }
      });
    }
  );
}

function refreshAmmendment() {
  newAEL = true;
  $('#refreshAEL').prop('disabled', true);
  editAEL();
}

function recalcAmmendment() {
  newAEL = false;
  $('#refreshAEL').prop('disabled', true);
  editAEL();
}

function addAEL_EL() {
  var a = ACIndex;
  var Keys = this.id.split('-');
  var ANum = Keys[1];
  $.mobile.loading('show', {
    theme: 'a',
  });
  $('#EQList').html('<h1 class="limit-table">' + iTrans('Loading List') + '...</h1>');
  var subType = '';
  for (b in ACTypes_data) {
    if (ACTypes_data[b].AcTypes == AC_data[a].AcType) subType = ACTypes_data[b].List_Type; // Server up same part lists as 206L for other similar to series
  }
  $.getJSON(
    BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_EL_EQ&ListType=' + encodeURIComponent(subType),
    function (json_data) {
      $('#EQList').html("<h4 class='limit-table'>" + iTrans('Select Item From Equipment List') + '</h4>');
      EL_data = json_data;
      $('#EQList').append(
        '<div style="width: 100%; overflow: auto"><table class="limit-table nowrap last-td-fill" id="EQList-List_Table" data-filter="true">' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="0">' +
          iTrans('Select') +
          '</th>' +
          '<th data-priority="1">' +
          iTrans('List') +
          '</th>' +
          '<th data-priority="2">' +
          iTrans('Part') +
          ' #</th>' +
          '<th data-priority="4">STC #</th>' +
          '<th data-priority="persist">' +
          iTrans('Description') +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>'
      );
      for (var i in EL_data) {
        if (EL_data[i].Kit == 1) continue;
        $('#EQList tr:last').after(
          "<tr><td><button id='KFM_WB_AEL-" +
            ANum +
            '-' +
            i +
            "' name='" +
            AC_data[a].ident +
            "' data-icon='plus' style='width:30px;'></button></td><td>" +
            EL_data[i].ListType +
            '</td><td>' +
            EL_data[i].PartNum +
            '</td><td>' +
            EL_data[i].STCNum +
            '</td><td>' +
            EL_data[i].Description +
            '</td>'
        );
        $('#KFM_WB_AEL-' + ANum + '-' + i).click(addEL_AEL);
      }
      $('#EQList tr:last').after('</tbody></table></div>');
      $('#EQList').append(
        '<button data-mini="true" data-icon="delete" class="redbg" onClick="editAEL();">' +
          iTrans('Close Selection List') +
          '</button>'
      );
      $('#EQList').enhanceWithin();
      $.mobile.loading('hide');
    }
  );
}

function addEL_AEL() {
  var Keys = this.id.split('-');
  var i = Keys[2];
  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=NewRecord&table=KFM_WB_AEL_EL&ident=' +
      encodeURIComponent(this.name) +
      '&anum=' +
      Keys[1] +
      '&partnum=' +
      encodeURIComponent(EL_data[i].PartNum) +
      '&stc=' +
      encodeURIComponent(EL_data[i].STCNum) +
      '&description=' +
      encodeURIComponent(EL_data[i].Description) +
      '&weight=' +
      EL_data[i].Weight +
      '&horzarm=' +
      EL_data[i].HorzArm +
      '&latarm=' +
      EL_data[i].LatArm,
    function (json_data) {
      if (json_data[0].ServerResponse == 'Success') {
        newAEL = true;
        editAEL();
      } else {
        alert(json_data[0].ServerResponse);
      }
    }
  );
}
var newAEL = false;

function addAEL() {
  var Keys = this.id.split('-');
  var Units = 'Imperial';
  if (WBUnitLbl.Metric) Units = 'Metric';
  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=NewRecord&table=KFM_WB_AEL&anum=' +
      Keys[1] +
      '&ident=' +
      encodeURIComponent(AC_data[ACIndex].ident) +
      '&Units=' +
      Units,
    function (json_data) {
      if (json_data[0].ServerResponse == 'Success') {
        if (Keys[1] != 0) newAEL = true;
        editAEL();
      } else {
        alert(json_data[0].ServerResponse);
      }
    }
  );
}
var NumOPTIONAL = 0;
function editIEL() {
  $('#Content_WB').html("<h1 class='limit-table'>" + iTrans('Loading List') + '...</h1>');
  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_IEL&ident=' +
      encodeURIComponent(AC_data[ACIndex].ident),
    function (json_data) {
      $('#Content_WB').html(
        "<button id='AddNewE' data-icon='plus' name='" +
          AC_data[ACIndex].AcType +
          '_' +
          AC_data[ACIndex].ident +
          "' data-mini='true' class='greenbg'>" +
          iTrans('Add Equipment to Aircraft') +
          '</button>'
      );
      $('#Content_WB').append("<h3 class='limit-table'>" + iTrans('Installed Equipment List') + '</h3>');
      $('#Content_WB').append(
        '<div style="width: 100%; overflow: auto"><table class="limit-table description-td-fill" id="IEL-List_Table" data-filter="true">' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="0">Del</th>' +
          '<th data-priority="1">' +
          iTrans('Part') +
          ' #</th>' +
          '<th data-priority="2">STC #</th>' +
          '<th data-priority="persist">' +
          iTrans('Description') +
          '</th>' +
          '<th data-priority="3">' +
          iTrans('Sup Name') +
          '</th>' +
          '<th data-priority="4">' +
          iTrans('Option') +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>'
      );
      IEL_data = json_data;
      NumOPTIONAL = 0;
      for (var e in IEL_data) {
        if (IEL_data[e].ListType == 'Optional') NumOPTIONAL++;
        $('#Content_WB tr:last').after(
          "<tr><td><div class='vertical-center-container'><button class='action-btn' id='KFM_WB_IEL-DeleteID-" +
            IEL_data[e].ielPrimaryKey +
            "' name='PrimaryKey'><img src='./images/delete.svg' /></button></div></td><td>" +
            IEL_data[e].PartNum +
            '</td><td>' +
            IEL_data[e].STCNum +
            '</td><td>' +
            IEL_data[e].Description +
            '</td><td>' +
            IEL_data[e].SupName +
            "</td><td><select id='KFM_WB_IEL-Optional-" +
            IEL_data[e].ielPrimaryKey +
            "' name='Optional'/></td>"
        );
        $('#KFM_WB_IEL-DeleteID-' + IEL_data[e].ielPrimaryKey).click(deleteRec);
        $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).append(
          $('<option />').val('Removed').text(iTrans('Removed'))
        );
        $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).append(
          $('<option />').val('Installed').text(iTrans('Installed'))
        );
        $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).val(IEL_data[e].Optional);
        if (IEL_data[e].Optional == 'Standard') {
          $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).append(
            $('<option />').val('Standard').text(iTrans('Standard'))
          );
          $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).val(IEL_data[e].Optional);
          $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).prop('disabled', true);
        }
        $('#KFM_WB_IEL-Optional-' + IEL_data[e].ielPrimaryKey).change(WBchange);
      }
      $('#Content_WB tr:last').after('</table></div>');
      $('#AddNewE').click(addEQ);
      $('#Content_WB').enhanceWithin();
      $('#ammendWBMENU').prop('disabled', false).checkboxradio('refresh');
      $('#installWBMENU').prop('disabled', false).checkboxradio('refresh');
      $('#tcWBMENU').prop('disabled', false).checkboxradio('refresh');
    }
  );
}

function addEQ() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $('#Content_WB').html('<h1 class="limit-table">' + iTrans('Loading List') + '...</h1>');
  var subType = '';
  for (b in ACTypes_data) {
    if (ACTypes_data[b].AcTypes == AC_data[ACIndex].AcType) subType = ACTypes_data[b].List_Type; // Server up same part lists as 206L for other similar to series
  }
  console.log(
    BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_EL_AC&actype=' + encodeURIComponent(subType)
  );
  $.getJSON(
    BASEURL +
      'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_EL_AC&ident=' +
      encodeURIComponent(AC_data[ACIndex].ident) +
      '&actype=' +
      encodeURIComponent(subType),
    function (json_data) {
      $('#Content_WB').html(
        "<button id='AddEQ' data-icon='plus' name='" +
          AC_data[ACIndex].AcType +
          '_' +
          AC_data[ACIndex].ident +
          "' data-mini='true' class='greenbg'>" +
          iTrans('Add Selected Equipment') +
          '</button>'
      );
      EL_data = json_data;
      $('#Content_WB').append(
        '<div style="width: 100%; overflow: auto"><table class="limit-table nowrap description-td-fill" id="EQ-List_Table" data-filter="true" >' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="0">' +
          iTrans('Select') +
          '</th>' +
          '<th data-priority="1">' +
          iTrans('List') +
          '</th>' +
          '<th data-priority="2">' +
          iTrans('Model') +
          '</th>' +
          '<th data-priority="persist">' +
          iTrans('Description') +
          '</th>' +
          '<th data-priority="3">' +
          iTrans('Part') +
          ' #</th>' +
          '<th data-priority="4">STC #</th>' +
          '<th>' +
          iTrans('Weight') +
          '</th><th>Lat</th><th>Long</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>'
      );
      for (i in EL_data) {
        var disable = '';

        $('#Content_WB tr:last').after(
          '<tr><td><input ' +
            InputStyle +
            " type='checkbox'  class='eqclass' name='" +
            i +
            "' id='SELECT-ID-" +
            EL_data[i].PrimaryKey +
            "' data-mini='false'></td><td>" +
            EL_data[i].ListType +
            '</td><td>' +
            EL_data[i].AcType +
            '</td><td>' +
            EL_data[i].Description +
            '</td><td>' +
            EL_data[i].PartNum +
            '</td><td>' +
            EL_data[i].STCNum +
            '</td><td>' +
            EL_data[i].Weight +
            '</td><td>' +
            EL_data[i].LatArm +
            '</td><td>' +
            EL_data[i].HorzArm +
            '</td></tr>'
        );
      }
      $('#Content_WB tr:last').after('</tbody></table></div>');
      $('#AddEQ').click(addIEL);
      $('#Content_WB').enhanceWithin();
      $.mobile.loading('hide');
    }
  );
}

function addIEL() {
  var status = $('input[type="checkbox"]')
    .filter('.eqclass')
    .map(function () {
      var name = $(this).attr('name');
      if ($(this).is(':checked'))
        return {
          name: name,
          status: 'Checked',
        };
    })
    .get();

  $.ajaxSetup({
    async: false,
  });
  for (var i in status) {
    var index = status[i].name;
    var Optional = 'Standard';
    if (EL_data[index].ListType == 'Optional') {
      NumOPTIONAL++;
      Optional = 'Removed';
    }
    if (NumOPTIONAL >= 12 && AC_data[ACIndex].noTC == 0) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Warning',
        headerClose: false,
        buttonPrompt: iTrans(
          'The Temp Configuration Matrix cannot be produced when more than 12 optional equipment items are installed'
        ),
        buttons: {
          OK: {
            click: function () {
              //do nothing
            },
          },
        },
      });
    }
    $.getJSON(
      BASEURL +
        'menu-Maintenance-AJAX.php?action=IELadd&table=KFM_WB_IEL&PrimaryKey=' +
        EL_data[index].PrimaryKey +
        '&ident=' +
        encodeURIComponent(AC_data[ACIndex].ident) +
        '&option=' +
        encodeURIComponent(Optional),
      function (json_data) {
        if (json_data[0].ServerResponse == 'Success') {
          editIEL();
        } else {
          alert(json_data[0].ServerResponse);
        }
      }
    );
  }
  $.ajaxSetup({
    async: true,
  });
}

function addAC() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Enter Ident'),
    headerClose: false,
    buttonPrompt:
      '<label for="newacident">' +
      iTrans('Aircraft Ident') +
      '</label><input type="text" data-mini="true" id="newacident" /><br /><b>' +
      iTrans('Note') +
      ': ' +
      iTrans('This ident cannot be changed in the future') +
      '</b>',
    buttons: {
      [iTrans('Add New Aircraft')]: {
        click: function () {
          var ident = $('#newacident').val();
          ident = ident.replace(/ /g, '');
          if (ident.length < 3) {
            alert(iTrans('Invalid Aircraft Ident: needs to be at least 3 characters long'));
            return;
          }
          ident = encodeURIComponent(ident);
          $.getJSON(
            BASEURL + 'menu-Maintenance-AJAX.php?action=NewRecord&table=KFM_WB_AC&ident=' + ident,
            function (json_data) {
              if (json_data[0].ServerResponse == 'Success') {
                ACIndex = 0; //default for new ac index
                $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AC', function (json_data) {
                  AC_data = json_data;

                  editAcIdent(ident);
                });
              } else {
                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                  //if json_data[0].Exception has "Duplicate New Entry" then as if they want to restore the ident
                  if (json_data[0].Exception.indexOf('Duplicate entry') != -1) {
                    $('<div>').simpledialog2({
                      mode: 'button',
                      animate: false,
                      headerText: iTrans('Ident Exists'),
                      headerClose: false,
                      buttonPrompt:
                        '<b>' +
                        ident +
                        '</b> ' +
                        iTrans('already exists in the system') +
                        '.  ' +
                        iTrans('Would you like to restore this aircraft instead?') +
                        '',
                      buttons: {
                        [iTrans('Restore Aircraft')]: {
                          click: function () {
                            $.getJSON(
                              BASEURL + 'menu-Maintenance-AJAX.php?action=restoreAC&ident=' + ident,
                              function (json_data) {
                                if (json_data[0].ServerResponse == 'Uncaught Exception') {
                                  $('<div>').simpledialog2({
                                    mode: 'button',
                                    animate: false,
                                    headerText: 'Error',
                                    headerClose: false,
                                    buttonPrompt: json_data[0].Exception,
                                    buttons: {
                                      OK: {
                                        click: function () {
                                          //do nothing
                                        },
                                      },
                                    },
                                  });
                                } else {
                                  $.getJSON(
                                    BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AC',
                                    function (json_data) {
                                      AC_data = json_data;
                                      editAcIdent(ident);
                                    }
                                  );
                                }
                              }
                            );
                          },
                        },
                        [iTrans('Cancel')]: {
                          click: function () {
                            //do nothing
                          },
                        },
                      },
                    });
                  } else {
                    $('<div>').simpledialog2({
                      mode: 'button',
                      animate: false,
                      headerText: iTrans('Error'),
                      headerClose: false,
                      buttonPrompt: json_data[0].Exception,
                      buttons: {
                        [iTrans('OK')]: {
                          click: function () {
                            //do nothing
                          },
                        },
                      },
                    });
                  }
                }
                //alert(json_data[0].ServerResponse);
              }
            }
          );
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}
/**
 * Sets the global `ACIndex` variable to the index of the aircraft with the specified identifier.
 *
 * @param {string} ident
 */
function setACIndex(ident) {
  ident = decodeURIComponent(ident);
  ACIndex = -1;
  for (var i in AC_data) {
    if (AC_data[i].ident == ident) ACIndex = i;
  }
}

function editAcIdent(ident) {
  setupEditACAndWBEventHandlers();
  setACIndex(ident);
  editAC1();
}

/**
 * Navigates to the Weight and Balance edit page and selects the tab within it for the specified aircraft.

 * @param {string} ident
 * @param {string} selectedTab
 */
function goToEditWB(ident, selectedTab) {
  setupEditACAndWBEventHandlers();
  setACIndex(ident);
  populateEditWBPageAndSelectTab(selectedTab);
}
function deleteRec() {
  WBPrimaryKey = this.id;
  var Keys = WBPrimaryKey.split('-');
  if (Keys[0] == 'KFM_WB_AC') acDelete = this.name;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm Delete'),
    headerClose: false,
    buttonPrompt: iTrans('This item will be permanently deleted and cannot be recovered. Are you sure?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          if (Keys[0] == 'KFM_WB_AEL') {
            if (Keys[3] != 0) newAEL = true;
          }
          deleteConfirmed();
        },
      },
      [iTrans('CANCEL')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}
var acDelete = '';

function deleteConfirmed() {
  var Keys = WBPrimaryKey.split('-');
  if (Keys[0] == 'KFM_WB_AC') {
    console.log(
      BASEURL +
        'menu-Maintenance-AJAX.php?action=DeleteRecord&table=' +
        Keys[0] +
        '&PrimaryKey=' +
        Keys[2] +
        '&ident=' +
        acDelete
    );
    $.getJSON(
      BASEURL +
        'menu-Maintenance-AJAX.php?action=DeleteRecord&table=' +
        Keys[0] +
        '&PrimaryKey=' +
        Keys[2] +
        '&ident=' +
        acDelete,
      function (json_data) {
        if (json_data[0].ServerResponse == 'Success') {
          if (Keys[0] == 'KFM_WB_AC') {
            VueBridge.enterAircraftList();
            ACRebuild = true;
          }
        } else {
          alert(json_data[0].ServerResponse);
        }
      }
    );
  } else {
    $.getJSON(
      BASEURL +
        'menu-Maintenance-AJAX.php?action=DeleteRecord&table=' +
        Keys[0] +
        '&PrimaryKey=' +
        Keys[2] +
        '&ident=' +
        encodeURIComponent(AC_data[ACIndex].ident),
      function (json_data) {
        if (json_data[0].ServerResponse == 'Success') {
          if (Keys[0] == 'KFM_WB_EL') equiplist();
          if (Keys[0] == 'KFM_WB_IEL') editIEL();
          if (Keys[0] == 'KFM_WB_AEL') editAEL();
        } else {
          alert(json_data[0].ServerResponse);
        }
      }
    );
  }
}
var WBPrimaryKey;

function createPDF() {
  var form = document.createElement('form');
  form.setAttribute('method', 'post');
  form.setAttribute('action', 'PDF.aspx');
  form.setAttribute('target', '_blank');
  var HTMLDIV = document.createElement('input');
  HTMLDIV.setAttribute('type', 'hidden');
  HTMLDIV.setAttribute('name', 'HTML');
  HTMLDIV.setAttribute('value', $('#PRINT').html());
  form.appendChild(HTMLDIV);
  document.body.appendChild(form);
  form.submit();
}
var Printview = false;

function printWB() {
  //$("#Print_Page").hide();
  //var myWindow = window.open("", "MsgWindow", "width=200, height=100");
  //mywindow = window.open('', 'PrintWindow', 'height=400,width=600');
  if (!viewingReportOnly) {
    $('#Print_Page').show();
  }
  var i = ACIndex;
  if (!viewingReportOnly) {
    $.mobile.changePage($('#Print_Page'), {
      changeHash: false,
    });
  }
  $('body').css('overflow', 'visible');
  $('#PRINT').html('');
  $('#PrintA').html('');
  $('#PrintTC').html('');
  $('#CHARTS').html('');
  $('#CHARTS2').html('');
  Printview = true;

  editAEL();
}
var mywindow;

function LoadPrintHTML() {
  $('#Print_Page').removeClass();
  window.print();
}

var WB_Signature;
var WB_SIGNATURE_DATA = '';
function WB_CAPTURE_SIGNATURE(ident) {
  //goto signature page
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  $.mobile.changePage($('#WB_Signature'), {
    transition: 'slide',
    changeHash: false,
    reverse: false,
  });
  $('#wb-signature-name').val(chatname);
  var Licence = '';
  try {
    var DATA = JSON.parse(USER_RIGHTS.USER_DATA);
    Licence = DATA.PersonalInfo.USER_LICENCE;
  } catch (e) {
    Licence = '';
  }
  var TodayDate = new Date();
  TodayDate.setMinutes(TodayDate.getMinutes() - TodayDate.getTimezoneOffset());
  $('#wb-signature-licence').val(Licence);
  document.getElementById('wb-signature-date').valueAsDate = TodayDate;

  $('#wb-signature-pad-wrapper').html('');
  $('#wb-signature-pad-wrapper').html('<canvas id="wb-signature-pad" class="signature-pad"></canvas>');
  var canvas = document.getElementById('wb-signature-pad');
  WB_Signature = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    WB_Signature.clear(); // otherwise isEmpty() might return incorrect value
  }
  window.addEventListener('resize', resizeCanvas);
  resizeCanvas();
  WB_Signature.ident = ident;
  WB_Signature.page = page;
}
function WB_ClearSignature() {
  WB_Signature.clear();
  WB_SIGNATURE_DATA = '';
}
function WB_RemoveSignature() {
  WB_SIGNATURE_DATA = '';
  $('#WB_SignatureStatus').html(
    '<button class="redbg" onClick="WB_CAPTURE_SIGNATURE(null);">' + iTrans('Add Signature Now') + '</button>'
  );
  $('#WB_SignatureStatus').enhanceWithin();
}
function WB_returnSignature() {
  var SignatureName = $('#wb-signature-name').val();
  if (SignatureName.length == 0 && !WB_Signature.isEmpty()) {
    alert(iTrans('Signature Name cannot be blank!'));
    return;
  }
  var Licence = $('#wb-signature-licence').val();
  if (Licence.length == 0 && !WB_Signature.isEmpty()) {
    alert(iTrans('Licence cannot be blank!'));
    return;
  }
  $.mobile.changePage($('#' + WB_Signature.page), {
    changeHash: false,
    reverse: true,
  });
  if (SignatureName.length > 0 && WB_Signature.isEmpty()) {
    UpdateAircraft_EQHTML2(html, WB_Signature.ident);
    return;
  }

  if (!WB_Signature.isEmpty()) {
    var data = WB_Signature.toDataURL('image/svg+xml');
    var html =
      '<center><div style="display: inline-block;"><img src="' + data + '" width="321px" height="150px"/><br />';
    html +=
      '<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>' +
      WB_REVISION_NO +
      '</b><br /><b>' +
      iTrans('Dated') +
      ': ' +
      $('#wb-signature-date').val() +
      '</b><br /><b>' +
      SignatureName +
      '</b><br /><b>' +
      iTrans('Licence') +
      ' #</b>' +
      Licence +
      '</center></td></tr></table></div></center>';
    WB_SIGNATURE_DATA = "<h4 class='limit-table'>" + GROUP_DATA.MaintRelease + '</h4>' + html;

    if (WB_Signature.ident != null) {
      $('#Content_WB').append(WB_SIGNATURE_DATA);
      UpdateAircraft_EQHTML2(WBHTML, WB_Signature.ident);
    }
    $('#WB_SignatureStatus').html(
      '<center><h3 class="greenbg">' +
        iTrans('Signature Ready') +
        '<br />' +
        iTrans('This will appear at end of report') +
        '</h3>' +
        WB_SIGNATURE_DATA +
        '<button data-role="none" data-mini="true" class="yellowbg" onClick="WB_RemoveSignature()">' +
        iTrans('Remove Signature') +
        '</button></center>'
    );
    $('#WB_SignatureStatus').enhanceWithin();
  }
}

function GeneratePDF_WeightAndBalance(ident) {
  //console.log(BILLING_INVOICE_DATA);
  GENERATE_PDF.filename = ident + '_WeightAndBalance';
  GENERATE_PDF.showPDF = 1;
  GENERATE_PDF.silent = 0;
  GENERATE_PDF.subject = iTrans('Weight And Balance Report') + ': ' + ident;
  GENERATE_PDF.email = '';
  GENERATE_PDF.div = 'WB_PrintItems';
  GENERATE_PDF.ClientEmail = '0';
  GENERATE_PDF.Header = '';
  GENERATE_PDF.Footer = WB_REVISION_NO;
  GENERATE_PDF.PageNums = 1;
  GENERATE_PDF.Type = 'WB';
  GeneratePDF();
}
