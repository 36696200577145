class vSAMA_CustomFieldValueEditor extends vSAMA_AbstractEditor {
  /**
   * @param {mSAMA_CustomFieldValue} _customFieldValue
   * @param {boolean}                _readOnly
   */
  constructor(_customFieldValue, _readOnly) {
    super();

    this.customField = _customFieldValue.GetCustomField();
    this.customFieldValue = _customFieldValue;

    this.dom = {};

    this.hasTagger = false;

    let template;
    let inputType = 'input';
    let applicantFunction = null; // The function used to enhance the template

    switch (this.customField.sync.mcf_type) {
      case SAMA.enums.CUSTFIELDTYPES.TextField:
        template = SAMA.Templates.tpl.customRepField.text;
        break;

      case SAMA.enums.CUSTFIELDTYPES.GearSelect:
        template = SAMA.Templates.tpl.customRepField.keyword;
        applicantFunction = cSAMA_GeneralInputs.Gear;
        this.hasTagger = true;
        break;

      case SAMA.enums.CUSTFIELDTYPES.CraftSelect:
        template = SAMA.Templates.tpl.customRepField.keyword;
        applicantFunction = cSAMA_GeneralInputs.Aircraft;
        this.hasTagger = true;
        break;

      case SAMA.enums.CUSTFIELDTYPES.StaffSelect:
        template = SAMA.Templates.tpl.customRepField.keyword;
        applicantFunction = cSAMA_GeneralInputs.Staff;
        this.hasTagger = true;
        break;

      case SAMA.enums.CUSTFIELDTYPES.BaseSelect:
        template = SAMA.Templates.tpl.customRepField.keyword;
        applicantFunction = cSAMA_GeneralInputs.Bases;
        this.hasTagger = true;
        break;

      default:
        throw 'Unknown field type ' + this.customField.sync.mcf_type;
    }

    this.dom.body = $(template.formatTemplate(this.customField.sync));
    this.dom.input = this.dom.body.find(inputType);

    if (_readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.customFieldValue, this.dom.body);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.customFieldValue, this.dom.body, this);
    }

    if (applicantFunction != null) {
      applicantFunction(this.dom.input, _readOnly);
    }
  }

  /**
   * Makes the field required at a certain risk level and revalidates it
   */
  SetRequired(_atLevel) {
    this.customFieldValue.SetRequiredLevel(_atLevel);
    this.customFieldValue.Validate(this.dom.body);
  }

  /**
   * Reloads the values in the editor's inputs
   */
  ReloadInputs() {
    this.dom.input
      .attr('value', this.customFieldValue.sync.mcfv_value)
      .val(this.customFieldValue.sync.mcfv_value)
      .trigger('change');

    if (this.hasTagger) {
      this.dom.input.catTagger('refresh');
    }
  }

  /**
   * Discards changes made and resets to previous state
   */
  DiscardChanges() {
    this.customFieldValue.DiscardValueChanges();

    this.ReloadInputs();
  }

  /**
   * Confirms changes made
   */
  ConfirmChanges() {
    this.customFieldValue.ConfirmValueChanges();
  }

  GetDom() {
    return this.dom.body;
  }

  /**
   * Nukes the Markup of the object
   */
  Destroy() {
    if (this.hasTagger) {
      this.dom.input.catTagger('destroy');
    }

    this.dom.body.remove();
  }
}
