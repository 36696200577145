/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
function ExitEditWP() {
  $.mobile.changePage($('#WPadmin_Page'), {
    reverse: true,
    changeHash: false,
  });
}
var WPList;

function admingetWPlist() {
  $('#updateWPBTN').unbind('click', updateWPitem);
  $('#updateWPBTN').click(updateWPitem);
  $('#ExitEditWPPage').unbind('click', ExitEditWP);
  $('#ExitEditWPPage').click(ExitEditWP);
  $('#WPList').html("<h3 class='limit-table'>" + iTrans('Getting Waypoint Files') + '</h3>');
  $.getJSON(BASEURL + 'menu-Administration-WP-AJAX.php?action=getWPlist', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'No Waypoints in Database') {
        $('#WPList').html('');
        $('#WPList').html("<h3 class='limit-table'>" + iTrans('There are no Waypoints in the system') + '</h3>');
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getWPlist');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      console.log('Received Waypoints Data');
      WPList = json_data;
      displayWP(json_data);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function displayWP(WP) {
  $('#WPList').html('');
  $('#WPList').append(
    '<div style="width: 100%; overflow: auto"><table id="WPList_Table" data-filter="true" class="limit-table first-td-fill" >' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('Name') +
      '</th>' +
      '<th>' +
      iTrans('Updated') +
      '</th>' +
      '<th>' +
      iTrans('By') +
      '</th>' +
      '<th>' +
      iTrans('Action') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody><tr>'
  );
  for (i in WP) {
    $('#WPList tr:last').after(
      '<tr><td>' +
        WP[i].Name +
        '</td><td>' +
        WP[i].datetime +
        '</td><td>' +
        WP[i].whoupdate +
        "</td><td><div class='vertical-center-container'><button id='WPList-EditID-" +
        WP[i].PrimaryKey +
        "' name='" +
        i +
        "' class='action-btn' data-mini='true'><img src='./images/edit.svg' /></button><button id='WPList-DeleteID-" +
        WP[i].PrimaryKey +
        "' name='" +
        i +
        "' PrimaryKey = 'test'  class='action-btn' data-mini='true'><img src='./images/delete.svg' /></button></div></td></tr>"
    );
    $('#WPList-DeleteID-' + WP[i].PrimaryKey).click(deleteWPList);
    $('#WPList-EditID-' + WP[i].PrimaryKey).click(editWPList);
  }
  $('#WPList tr:last').after('</table></div>');

  $('#WPList').enhanceWithin();
  AddTableSorter('WPList_Table');
}

function deleteWPList() {
  console.log('DeleteWP: ' + this.name);
  var id = this.id.split('-');
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Delete Waypoints'),
    headerClose: false,
    buttonPrompt: iTrans('Waypoints in this list will be permanently Deleted! Are you Sure?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Administration-WP-AJAX.php?action=deleteWP&WPPK=' + id[2], function (json_data) {
            if (json_data[0].ServerResponse == 'Offline') {
              var msg;
              msg.ServerResponse = 'Connection Error';
              ServerResponse(msg, 'Deleting Waypoints');
            } else {
              admingetWPlist();
            }
          }).fail(function (jqXHR, status, error) {
            var msg;
            msg.ServerResponse = 'Connection Error';
            ServerResponse(msg, 'Deleting Waypoints');
          });
        },
      },
      [iTrans('CANCEL')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function editWPList() {
  console.log('EditWP: ' + this.name);
  $('#WPEditHeader').html('Editing: ' + WPList[this.name].Name);
  $.mobile.changePage($('#Edit_WP_Page'), {
    changeHash: false,
  });
  console.log(WPList[this.name].Data);
  var wp = WPList[this.name];
  $('#editWPname').val(wp.Name);
  $('#wp_overrideStyle')
    .prop('checked', wp.overrideStyle == 1 ? true : false)
    .checkboxradio('refresh');
  $('#editWPpoint').val(wp.Point).css('background-color', wp.Point);
  $('#editWPpointOutline').val(wp.circleOutline).css('background-color', wp.circleOutline);
  $('#editWPpolyline').val(wp.Polyline).css('background-color', wp.Polyline);
  $('#editWPpolygon').val(wp.Polygon).css('background-color', wp.Polygon);
  $('#editWPpolylineOutline').val(wp.polygonOutline).css('background-color', wp.polygonOutline);
  $('#wp_pointStroke').val(wp.circleStroke);
  $('#wp_pointOpacity').val(wp.pointOpacity);
  $('#wp_lineStroke').val(wp.lineStroke);
  $('#wp_lineOpacity').val(wp.lineOpacity);
  $('#wp_polygonStroke').val(wp.polygonStroke);
  $('#wp_polygonOpacity').val(wp.polygonOpacity);
  $('#wp_radius').val(wp.pointRadius);
  $('#WPPK').val(wp.PrimaryKey);
  updateWP_Opts({ checked: wp.overrideStyle == 1 ? true : false });
}

function updateWP_Opts(e) {
  if (e.checked) {
    $('#WaypointOptionsHLDR').show();
  } else {
    $('#WaypointOptionsHLDR').hide();
  }
}

function validate_Radius(val) {
  if (isNaN(val)) {
    return 1;
  }
  val = parseInt(val);
  if (val < 1) {
    return 1;
  }
  if (val > 9999) {
    return 9999;
  }
  return val;
}
function validate_Stroke(val) {
  if (isNaN(val)) {
    return 1;
  }
  val = parseInt(val);
  if (val < 1) {
    return 1;
  }
  if (val > 9999) {
    return 9999;
  }
  return val;
}
function validate_Opacity(val) {
  if (isNaN(val)) {
    return 1;
  }
  val = parseFloat(val);
  if (val <= 0) {
    return 0;
  }
  if (val > 1) {
    return 1;
  }
  return Math.round(val * 100) / 100;
}
function updateWPitem() {
  console.log('Update waypoint item');
  console.log('Update Fuel Cache item');
  var Name = $('#editWPname').val();
  var Point = $('#editWPpoint').val();
  var Polyline = $('#editWPpolyline').val();
  var Polygon = $('#editWPpolygon').val();
  var WPPK = $('#WPPK').val();
  var action = 'updateWP';

  var overrideStyle = $('#wp_overrideStyle').is(':checked') ? 1 : 0;
  var circleOutline = $('#editWPpointOutline').val();
  var polygonOutline = $('#editWPpolylineOutline').val();
  var circleStroke = validate_Stroke($('#wp_pointStroke').val());
  var pointOpacity = validate_Opacity($('#wp_pointOpacity').val());
  var lineStroke = validate_Stroke($('#wp_lineStroke').val());
  var lineOpacity = validate_Opacity($('#wp_lineOpacity').val());
  var polygonStroke = 1; //future proofing since we don't have a way to set this yet
  var polygonOpacity = validate_Opacity($('#wp_polygonOpacity').val());
  var pointRadius = validate_Radius($('#wp_radius').val());

  $('#UpdateWPBTN').prop('disabled', true);
  $.getJSON(
    BASEURL +
      'menu-Administration-WP-AJAX.php?action=' +
      action +
      '&Name=' +
      encodeURIComponent(Name) +
      '&Point=' +
      encodeURIComponent(Point) +
      '&Polyline=' +
      encodeURIComponent(Polyline) +
      '&Polygon=' +
      encodeURIComponent(Polygon) +
      '&WPPK=' +
      WPPK +
      '&overrideStyle=' +
      overrideStyle +
      '&circleOutline=' +
      encodeURIComponent(circleOutline) +
      '&polygonOutline=' +
      encodeURIComponent(polygonOutline) +
      '&circleStroke=' +
      circleStroke +
      '&pointOpacity=' +
      pointOpacity +
      '&lineStroke=' +
      lineStroke +
      '&lineOpacity=' +
      lineOpacity +
      '&polygonStroke=' +
      polygonStroke +
      '&polygonOpacity=' +
      polygonOpacity +
      '&pointRadius=' +
      pointRadius,

    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'UpdateSpot');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data[0].ServerResponse == 'Success') {
        syncWP(true);
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Successfully Updated'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //acknolwedge
                $('#UpdateWPBTN').prop('disabled', false);
                $.mobile.changePage($('#WPadmin_Page'), {
                  reverse: true,
                  changeHash: false,
                });
                admingetWPlist();
              },
            },
          },
        });
      }
      if (json_data[0].ServerResponse == 'Nothing Changed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt: iTrans('Nothing Changed'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //acknolwedge
                $('#UpdateWPBTN').prop('disabled', false);
              },
            },
          },
        });
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}
