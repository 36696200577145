/**
 * Page functions for the risk matrix editor
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_RiskMatrixEditor extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_AD_RiskMtxEdit', SAMA.pages.MenuAdmin);

    this.header = new cSAMA_PageHeader('Edit Risk Matrix', this.dom.page);

    this.dom.matrixPlaceholder = this.dom.page.find('div[data-sama-template="riskMatrix-placeholder"]');
    this.dom.riskFactorEditor = $('#riskFactor-tabEdit');

    this.resLock = new cSAMA_ResourceLock({
      what: 'MTXED',
      name: 'Risk Matrix Editor',
      pk: 'group',
      fnOpen: () => this.GetData(),
      fnLocked: () => this.PageLoadFailed(),
    });
  }

  SetUpGui(_json) {
    this.riskMatrix = new mSAMA_RiskMatrix(_json[0]);
    this.riskMatrixView = new vSAMA_RiskMatrixView(this.dom.matrixPlaceholder, this.riskMatrix);
    this.riskMatrixEditor = new vSAMA_RiskMatrixEditor(this.riskMatrix);

    this.riskMatrixView.Observe(this.riskMatrixEditor, 'MatrixChanged', () => this.riskMatrixView.UpdateGui());

    this.factorTabEdit = new vSAMA_TabbedEditor({
      noDataRider: 'No Risk Factors',
      noDataBody: 'No Risk Factors exist. Please create some.',
      addButtonLabel: 'New Risk Factor...',
      readOnly: false,
    });

    this.factorTabEdit.GetAddButton().on('click.samaevt', () => this.OnTabEditNewObject());
    this.factorTabEdit.GetDom().appendTo(this.dom.riskFactorEditor);

    SAMA.Navigator.FinishedLoading(this.header);
    cSAMA_GeneralInputs.LinkInputs(this.riskMatrix, this.dom.page, this.riskMatrixEditor);

    let riskFactors = this.riskMatrix.GetRiskFactors();

    for (let rf = 0; rf < riskFactors.length; rf++) {
      // Once we create that audit user, this readonly 'false' here will be oozeful :D
      let factor = riskFactors[rf];
      let newTab = new vSAMA_TabRiskFactor(factor, false);
      this.factorTabEdit.AddTab(newTab);
    }

    this.factorTabEdit.Refresh();
  }

  OnTabEditNewObject() {
    let newRiskFactor = new mSAMA_RiskFactor(this.riskMatrix);
    let newTab = new vSAMA_TabRiskFactor(newRiskFactor, false);

    this.riskMatrix.AddRiskFactor(newRiskFactor);
    this.factorTabEdit.AddTab(newTab);
    this.factorTabEdit.Refresh();

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  EnterPage() {
    this.resLock.BeginLocking();
  }

  GetData() {
    this.header.ButtonSaveDiscard();

    let r = new cSAMA_Request('RiskMatrix', 'getData');

    cSAMA_Request.Dispatch({
      name: 'FetchRiskMatrixEditor',
      reqs: r,
      clientErrorCode: 'SAMA 59 DC CC E1',
      fnSuccess: (_r) => this.SetUpGui(_r),
    });
  }

  /**
   * Function called when the user leaves the page.
   * Cleans up before leaving.
   * @override
   */
  LeavePage() {
    SAMA.Navigator.FinishedLoading();
    this.resLock.StopLocking();

    if (this.loaded === false) {
      // page load failed
      return;
    }

    cSAMA_GeneralInputs.UnLinkInputs(this.dom.page);

    this.factorTabEdit.Destroy();
    this.riskMatrixView.Destroy();

    this.factorTabEdit = null;
    this.riskMatrixEditor = null;
    this.riskMatrixView = null;
    this.riskMatrix = null;
  }

  /**
   * Assigns server PKs to local objects
   * @param {{customFields:{int:int}[], riskFactors:{int:int}[], matrixVersion:int }} _payload
   */
  UpdatePks(_payload) {
    this.riskMatrix.SetModified(false);
    this.riskMatrix.SetPk(_payload.matrixVersion);
    this.riskMatrix.sync.mtx_version = _payload.matrixVersion;

    let riskFactors = this.riskMatrix.GetRiskFactors();

    for (let rf = 0; rf < riskFactors.length; rf++) {
      let factor = riskFactors[rf];

      factor.SetModified(false);

      let rfpk = factor.GetPk();
      if (typeof _payload.riskFactors === 'object' && _payload.riskFactors.hasOwnProperty(rfpk)) {
        factor.SetPk(_payload.riskFactors[rfpk]);
      }

      if (typeof _payload.customFields !== 'object') {
        continue;
      }

      let customFields = factor.GetCustomFields();
      for (let cf = 0; cf < customFields.length; cf++) {
        let field = customFields[cf];
        field.SetModified(false);

        let cfpk = field.GetPk();
        if (_payload.customFields.hasOwnProperty(cfpk)) {
          field.SetPk(_payload.customFields[cfpk]);
        }
      }
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  /**
   * Saves changes
   */
  SaveHandler() {
    let rm = this.riskMatrix.GetTransmissible();

    if (rm == null) {
      // Nothing to save...
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
      return;
    }
    rm.storeType = 'override';

    const confirmArgsWCustomField = {
      title: 'Versioning',
      message:
        'Do you want to create a new version of Risk Matrix?<br/><ul>' +
        '<li>Changing the field type of custom field will require you to <b>create a new version of the Risk Matrix</b>.</li>' +
        '<li>Only the most recent version of the matrix can be used in new reports and edited here.</li></ul>',
      yesFunc: () => {
        rm = this.riskMatrix.GetFullTransmissible();
        rm.storeType = 'version';
        this.DoSave(rm);
      },
    };

    const confirmDefaultArguments = {
      title: 'Versioning',
      message:
        'Do you want to override the current Risk Matrix or save a new version?<br/><ul>' +
        "<li>Override the matrix if you've made minor changes, such as correcting a typo. <b>This will retroactively change existing reports</b>.</li>" +
        "<li>Changes that affect how the matrix may be interpreted as well as changes to the risk factor's weighting should probably be saved as a new version. This will not change existing reports.</li>" +
        '<li>Only the most recent version of the matrix can be used in new reports and edited here.</li></ul>',
      buttons: {
        Override: {
          click: () => {
            this.DoSave(rm);
          },
        },
        'New Version': {
          click: () => {
            rm = this.riskMatrix.GetFullTransmissible();
            rm.storeType = 'version';
            this.DoSave(rm);
          },
        },
      },
    };

    if (rm.riskFactors[0]?.customFields?.length > 0) {
      cSAMA_Dialog.ShowConfirm(confirmArgsWCustomField);
    } else {
      cSAMA_Dialog.ShowConfirm(confirmDefaultArguments);
    }
  }

  DoSave(_data) {
    let r = new cSAMA_Request('RiskMatrix', 'setData');
    r.Set('matrixData', _data);

    cSAMA_Request.Dispatch({
      name: 'SaveRiskMatrix',
      reqs: r,
      clientErrorCode: 'SAMA 59 DF 94 AA',
      fnSuccess: (_r) => this.UpdatePks(_r[0]),
      goBackOnError: false,
      keepStateOnError: true,
    });
  }
}
