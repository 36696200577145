/******************************* Staff Create New Flight Report *******************************************************************/
var FLIGHT_REPORT_WAS_SIGNED = 0;
var FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;
var VIEWING_ARCHIVED = false;
var VIEWING_FLIGHT_REPORT = false;
var REFRESH_FR_LIST = false;
var FLIGHT_REPORT_LISTS = {
  Clients: [],
  Jobs: [],
};

function BI_ClearInput(e) {
  $('#' + e.id).val('');
}

function BIFRsearchClientJobs(term, callback) {
  var txt = term.term.toLowerCase();

  var options = [];
  for (var J in REPORT_JOBS) {
    var I = REPORT_JOBS[J];

    if (
      String(I.JobNum).search(txt) != -1 ||
      I.Name.toLowerCase().indexOf(txt) != -1 ||
      I.Business.toLowerCase().indexOf(txt) != -1
    ) {
      var label = '#' + I.JobNum + ': ' + I.Name + ' [' + I.Business + ']';
      if (I.clientList == '' || I.clientList == undefined) {
        I.clientList = [];
      }
      options.push({ label: label, value: I.ClientPK + '|' + I.JobID + '|' + I.clientList.join() });
    }
  }
  callback(options);
}

function BIFRsearchClientJobsSELECT(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  $('#BILLING_FPjob').val(value);
  $('#BILLING_FPjob').selectmenu('refresh');
  var parts = value.split('|');
  var JobPK = parts[1];
  var ClientPK = parts[0];
  var clientList = parts[2];
  if (ClientPK == -1) {
    BILLING_SelectFRClientPrompt(JobPK, clientList, BIFR_ClientSelected);
    return;
  }
  BILLING_PrevJob(value);
  BILLING_POP_CF(value);
  CURRENT_FLIGHT_REPORT.Job = BILLING_GetJobData(value);
  CURRENT_FLIGHT_REPORT.AlertList = CURRENT_FLIGHT_REPORT.Job.AlertList;
}

function BIFR_ClientSelected(JobID) {
  BILLING_PrevJob(JobID);
  BILLING_POP_CF(JobID);
  CURRENT_FLIGHT_REPORT.Job = BILLING_GetJobData(JobID);
  CURRENT_FLIGHT_REPORT.AlertList = CURRENT_FLIGHT_REPORT.Job.AlertList;
}

function BILLING_LOAD_OFFLINE_REPORTS(data) {
  if (!data) BILLING_OFFLINE_REPORTS = [];
  else BILLING_OFFLINE_REPORTS = data;
  //console.log("LOADING OFFLINE REPORTS");
  //console.log(data);
  $('#BILLING_SIGNATURE').show();
}

function filterBillingReports() {
  if ($('#BILLING_ARCHIVED').is(':checked')) {
    populateArchivedReports();
  } else if ($('#BILLING_UNREPORTEDQUOTES').is(':checked')) {
    BILLING_updateshowUnreportedQuotes();
  } else {
    BILLING_FILTER_REPORT_DATES();
  }
}

function getJobIdList() {
  var ClientPK = $('#RPT_FR_Client').val();
  var JobID = $('#RPT_FR_Job').val();
  var list = '';
  if (ClientPK != -1 && JobID == -1) {
    for (var i in REPORT_JOBS) {
      var I = REPORT_JOBS[i];
      if (I.ClientPK == ClientPK) {
        if (list == '') list = '(' + I.JobID;
        else list += ',' + I.JobID;
      }
    }
    if (list != '') list += ')';
  }
  if (JobID != -1) list = '(' + JobID + ')';

  return list;
}

function BILLING_FILTER_REPORT_DATES() {
  var FromDate = new Date($('#RPT_FR_FromDate').val());
  var ToDate = new Date($('#RPT_FR_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  var FRnum = $('#BILLING_SearchFRNum').val();
  var ClientPK = $('#RPT_FR_Client').val();
  var Pilot = $('#RPT_FR_Pilot').val();
  var Ident = $('#RPT_FR_Ident').val();
  let list = getJobIdList();

  $.mobile.loading('show', {
    theme: 'a',
  });
  var getAdmin = 1;
  if (LOW_BANDWIDTH) {
    getAdmin = 0;
    var curTab = $('input[name*=BILLING_UNREPORTEDFLIGHTS]:checked').val();
    switch (curTab) {
      case '4':
        getAdmin = 1;
        break;
    }
  }
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getItinerary&Start=' +
      FromDate +
      '&End=' +
      ToDate +
      '&FRnum=' +
      FRnum +
      '&list=' +
      encodeURIComponent(list) +
      '&ClientPK=' +
      ClientPK +
      '&getAdmin=' +
      getAdmin +
      (Pilot == -1 ? '' : '&Pilot=' + Pilot) +
      (Ident == -1 ? '' : '&Ident=' + Ident),
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.length == 0) {
        $('#BILLING_FLIGHTREPORTS').html('<h1>' + iTrans('You have no flights to report on') + '</h1>');
        $('#BILLING_FLIGHTREPORTS').enhanceWithin();
        return;
      }
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          //$("#BILLING_FLIGHTREPORTS").html("<h1>Offline Cannot Retrieve data</h1>"); //retrieve old data from database
          BillingDB('SELECT', 'Itinerary', null, BILLING_showFlightReports); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'Billing_NewFlightReport');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        //console.log("Received jobs List");

        BillingDB('INSERT', 'Itinerary', json_data, StoredConfirm); //Store Itinerary Data
        BILLING_FLIGHTREPORTS = json_data;
        BILLING_showFlightReports(json_data);
      }
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //$("#BILLING_FLIGHTREPORTS").html("<h1>Connection Error!</h1>");
    $.mobile.loading('hide');
    BillingDB('SELECT', 'Itinerary', null, BILLING_showFlightReports); //retrieve old data from database
  });
}

function BILLING_ContinueFlightReportOffline() {
  signatureDATA = {
    data: '',
    text: '',
  };
  //Auto fill and lock Most Itinerary information if Already contained
  //Create options for custom fields to be captured by clients
  var U = CURRENT_FLIGHT_REPORT;

  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
  });
  //console.log(U);
  signatureDATA = {
    data: CURRENT_FLIGHT_REPORT.signature,
    text: CURRENT_FLIGHT_REPORT.signed_by,
  };
  CURRENT_FLIGHT_REPORT_LAST_JOB = U.ClientPK + '|' + U.JobPK;
  BILLING_POP_EDIT_CF(U.Cfields);
  $('#BILLING_IPimaryKey').val(U.Itin_ID);
  $('#BILLING_FPimaryKey').val(U.PrimaryKey);
  //console.log($("#BILLING_FPimaryKey").val());
  $('#BILLING_FPdate').val(U.Date);
  $('#BILLING_FPjob')
    .val(U.ClientPK + '|' + U.JobPK)
    .selectmenu('refresh', true); //select job dropdown select
  CURRENT_FLIGHT_REPORT.Job = BILLING_GetJobData(U.ClientPK + '|' + U.JobPK);
  BILLING_checkAcIdent_and_select(U.Aircraft); //select aircraft identification from dropdown
  if (HOBBS == 1) {
    $('#BILLING_FPac').selectmenu('disable');
  } else {
    if (U.Itin_ID == 0) $('#BILLING_FPac').selectmenu('enable');
    else $('#BILLING_FPac').selectmenu('disable');
  }
  BILLING_checkPIC_and_select(U.Pilot);
  //$("#BILLING_FPpilot").val(U.Pilot).selectmenu('refresh', true); //select pilot from dropdown
  BILLING_checkSIC_and_select(U.SIC);
  //$("#BILLING_FPSIC").val(U.SIC).selectmenu('refresh', true); //select pilot from dropdown
  $('#BILLING_FPpax').val(U.Passengers);
  if (U.Crew != '') {
    if (Array.isArray(U.Crew)) {
      BILLING_FLIGHTREPORT_CREW = U.Crew;
    } else {
      try {
        BILLING_FLIGHTREPORT_CREW = JSON.parse(U.Crew);
        U.Crew = BILLING_FLIGHTREPORT_CREW;
      } catch (e) {
        console.log(e);
        alert('Error parsing crew data: please readd crew members');
        BILLING_FLIGHTREPORT_CREW = [];
        U.Crew = BILLING_FLIGHTREPORT_CREW;
      }
    }
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  }
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();
  if (U.TDG == 1) {
    $('#BILLING_TDGOFF').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BILLING_TDGOFF').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', false).checkboxradio('refresh');
  }
  if (U.FuelProvided == 1) $('#BILLING_FUEL').prop('checked', true).checkboxradio('refresh');
  else $('#BILLING_FUEL').prop('checked', false).checkboxradio('refresh');
  if (U.BillByVAL == undefined) $('#BILLING_HOURPR').val(0);
  else $('#BILLING_HOURPR').val(U.BillByVAL);
  $('#BILLING_FPdetails').val(br2nl(U.Details));
  if (HOBBS == 1) {
    //Client has hobbs meter tracking.  get last times and update
    populateBillingHobbs(U);
  }
  BILLING_checkTechDispatch();
}

function BILLING_DELETE_FlightReportOffline() {
  BillingDB('DELETE', 'CURRENT_FLIGHT_REPORT', null, StoredConfirm); //delete Flight Report Data it has been Sent!!!
  localStorageDB.setItem('MigrationStatus', 1);
  LOCALSTORAGE.MigrationStatus = 1;
}

function BILLING_LOAD_FLIGHT_REPORT_LISTS() {
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    FLIGHT_REPORT_LISTS.Jobs = e;
  });
  BillingDB('SELECT', 'Clients', null, function (e) {
    FLIGHT_REPORT_LISTS.Clients = e;
  });
}

var OPERATOR_LOOKUP = {};

function BILLING_pop_OperatorList(clientPK = 0) {
  OPERATOR_LOOKUP = {};
  if (APP_ENVIRONMENT == 'local' || APP_ENVIRONMENT == 'staging' || GROUP_DATA.group == 'greatslave') {
    $('#BILLING_OperatorSelectorHLDR').show();
    cirroDB.query('BillingClient', 'clientPK <= ?', 0, function (list) {
      console.log('list', list);
      if (list === false || list.length == 0) {
        //no client shouldn't ever happen
      } else {
        list.sort(compare);
        $('#BILLING_FPoperator').empty();
        for (var i in list) {
          var I = list[i].data;
          OPERATOR_LOOKUP[I.clientPK] = I.Company_Name;
          $('#BILLING_FPoperator').append($('<option />').val(I.clientPK).text(I.Company_Name));
        }
        $('#BILLING_FPoperator').val(clientPK);
        CURRENT_FLIGHT_REPORT.operatorPK = clientPK;
        try {
          $('#BILLING_FPoperator').selectmenu('refresh', true);
        } catch (e) {
          //do nothing
        }
      }
    });
  } else {
    $('#BILLING_OperatorSelectorHLDR').hide();
  }

  function compare(a, b) {
    if (a.data.Company_Name < b.data.Company_Name) {
      return -1;
    }
    if (a.data.Company_Name > b.data.Company_Name) {
      return 1;
    }
    return 0;
  }
}

function BILLING_SelectOperator(clientPK) {
  CURRENT_FLIGHT_REPORT.operatorPK = clientPK;
}

function BILLING_NewFlightReport(returning, forceRefresh = false, SCH_Opts = false) {
  if (!returning) {
    BILLING_InitializePilotLists();
  }
  getFCdata(true);
  resetFC_Uplifts();
  BILLING_pop_OperatorList();
  if (!returning) {
    if (LOW_BANDWIDTH) {
      var fromdate = new Date();
      fromdate.setDate(fromdate.getDate() - 7);
      $('#RPT_FR_FromDate').val(fromdate.toISOString().substring(0, 10));
    }
    BILLING_LOAD_FLIGHT_REPORT_LISTS();
    makeTimezoneInput(
      'BILLING_FP_EntryTimezoneHLDR',
      'BILLING_FP_EntryTimezone',
      iTrans('Timezone of Report'),
      false,
      CurrentTimeZone,
      BILLING_ChangeEntryTimezone
    );
    makeTimezoneInput(
      'BILLING_FP_StartTimezoneHLDR',
      'BILLING_FP_StartTimezone',
      iTrans('Start Timezone'),
      false,
      CurrentTimeZone
    );
    makeTimezoneInput(
      'BILLING_FP_EndTimezoneHLDR',
      'BILLING_FP_EndTimezone',
      iTrans('End Timezone'),
      false,
      CurrentTimeZone
    );
  }
  BILLING_EDITING_OFFLINE = false;
  FLIGHT_REPORT_WAS_SIGNED = 0;
  FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;
  if (GROUP_DATA.DisableFRemail == 1 && USER_RIGHTS.BillingAccess == 0 && USER_RIGHTS.SuperUser == 0) {
    $('#BILLING_SEND_FR_TO_CLIENT').prop('checked', false);
    $('#BILLING_SEND_FR_TO_CLIENT').prop('disabled', true);
    $('#BILLING_SEND_FR_TO_JOB_CONTACT').prop('checked', false);
    $('#BILLING_SEND_FR_TO_JOB_CONTACT').prop('disabled', true);
    $('#BILLING_SEND_FR_TO').prop('disabled', true);
  }
  returning = returning || false;
  signatureDATA.data = '';
  signatureDATA.text = '';
  $('#BILLING_HOURPR_HLDR').hide();
  clearAllCfieldHolders();
  //get all Unreported Itinerary flights for user and other pilots:  Billable = 1 and JobID = 0
  if (!returning)
    $.mobile.changePage($('#BILLING_FlightReportsPage'), {
      changeHash: false,
    });

  //If client job selected be sure to grab custom fields attached to client; [Later on when we enhance this]
  UpdateMenuButtons(!ONLINE);
  CheckOfflineDataToSend();
  if (LOW_BANDWIDTH) $('#FlightReportRefreshBTN').html('<img src="./images/refreshNOW.svg">');
  else $('#FlightReportRefreshBTN').html('<img src="./images/refresh.svg">');
  if ((ONLINE && !LOW_BANDWIDTH) || (ONLINE && forceRefresh)) {
    var FromDate = new Date($('#RPT_FR_FromDate').val());
    var ToDate = new Date($('#RPT_FR_ToDate').val());
    FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
    ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
    var ClientPK = $('#RPT_FR_Client').val();
    var JobPK = $('#RPT_FR_Job').val();
    if (ClientPK != -1 || JobPK != -1) {
      filterBillingReports();
      return;
    }
    var getAdmin = 1;
    if (LOW_BANDWIDTH) {
      getAdmin = 0;
      var curTab = $('input[name*=BILLING_UNREPORTEDFLIGHTS]:checked').val();
      switch (curTab) {
        case '4':
          getAdmin = 1;
          break;
      }
    }
    $.mobile.loading('show', {
      theme: 'a',
    });
    $.getJSON(
      BASEURL +
        'menu-Billing-AJAX.php?action=getItinerary&Start=' +
        FromDate +
        '&End=' +
        ToDate +
        '&getAdmin=' +
        getAdmin,
      function (json_data) {
        if (json_data.length == 0) {
          $('#BILLING_FLIGHTREPORTS').html('<h1>' + iTrans('You have no flights to report on') + '</h1>');
          $('#BILLING_FLIGHTREPORTS').enhanceWithin();
          Initialize_Billing_Flight_Reports(SCH_Opts);
          return;
        }
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            //$("#BILLING_FLIGHTREPORTS").html("<h1>Offline Cannot Retrieve data</h1>"); //retrieve old data from database
            Initialize_Billing_Flight_Reports(SCH_Opts);
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception')
            ServerResponse(json_data[0], 'Billing_NewFlightReport');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          //console.log("Received Itinerary List");
          //Store Itinerary Data
          json_data[0].timestamp = new Date().getTime();
          BillingDB('INSERT', 'Itinerary', json_data, function (e) {
            Initialize_Billing_Jobs(SCH_Opts);
          });
        }
      }
    ).fail(function (jqXHR, status, error) {
      //AJAXFAIL(error);
      //$("#BILLING_FLIGHTREPORTS").html("<h1>Connection Error!</h1>");
      Initialize_Billing_Flight_Reports(SCH_Opts);
    });
  } else {
    Initialize_Billing_Flight_Reports(SCH_Opts);
  }
}

function Initialize_Billing_Jobs(SCH_Opts = false) {
  console.log(SCH_Opts);
  //populate list of available jobs
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobsCrew', function (json_data) {
    $.mobile.loading('hide');
    if (json_data.length == 0) {
      //empty this should never happen though!!!
      $('#BILLING_FPjob').empty();
      REPORT_JOBS = [];
      alert(iTrans('No Job List Should Not Happen!'));
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        //alert("<h1>Offline Cannot Retrieve List of Jobs</h1>"); //retrieve old data from database
        Initialize_Billing_Flight_Reports(SCH_Opts);
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_NewFlightReport');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      //console.log("Received jobs List");
      // Store Jobs Crew Data
      //Verify return object has billing data
      if (json_data[0].Business == 'Company') {
        BillingDB('INSERT', 'JobsCrew', json_data, function (e) {
          Initialize_Billing_Flight_Reports(SCH_Opts);
        });
      } else {
        console.log('VERIFIED DATA FAILED');
        Initialize_Billing_Flight_Reports(SCH_Opts);
      }
    }
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    $.mobile.loading('hide');
    Initialize_Billing_Flight_Reports(SCH_Opts);
  });
}

function Initialize_Billing_Flight_Reports(SCH_Opts = false) {
  //populate Aircraft list in BILLING_FPac
  FLIGHT_REPORT_WAS_SIGNED = 0;
  FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    ALLAC_data = [];
    if (e === false) e = [];
    else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data.push(item);
      }
    }
    if (ALLAC_data.length == 0) {
      alert(iTrans('Aircraft List has not synced yet.  Please resync Cirro and try again!'));
      $.mobile.loading('hide');
      cirroHOME();
      return;
    }
    var PrevSelectedAC = $('#BILLING_FPac').val();
    $('#BILLING_FPac').empty();
    for (var i in ALLAC_data) {
      if (ALLAC_data[i].Hibrenated == 1)
        $('#BILLING_FPac').append($('<option disabled/>').val(ALLAC_data[i].ident).text(ALLAC_data[i].ident));
      else $('#BILLING_FPac').append($('<option />').val(ALLAC_data[i].ident).text(ALLAC_data[i].ident));
    }
    $('#BILLING_FPac').val(PrevSelectedAC);

    BillingDB('SELECT', 'OFFLINE_SYNC', null, BILLING_LOAD_OFFLINE_REPORTS); // Get all offline flight reports library
    BillingDB('SELECT', 'JobsCrew', null, BILLING_populate_jobscrew); //retrieve old data from database
    BillingDB('SELECT', 'Itinerary', null, BILLING_showFlightReports); //retrieve old data from database
    $.mobile.loading('hide');
    checkExistingFlightReport(false, SCH_Opts);
  });
}

function BILLING_InitializePilotLists() {
  $('#BILLING_FlightReportsPageNewFlightReportTXT').prop('disabled', true);
  PilotsTable.query()
    .select()
    .then(function (e) {
      if (e === false) PILOT_data = [];
      else PILOT_data = e;

      BILLING_PopulatePilotOptions();
      BILLING_PopulateIdentOptions();

      if (PILOT_data.length == 0) {
        alert(iTrans('Pilot Staff List has not synced yet.  Please resync Cirro and try again!'));
        $.mobile.loading('hide');
        cirroHOME();
        if (!SYNCING) update_Sync('LastSYSTEMsync', '2020-01-01 00:00:00'); //For some reason Sync No wasn't retrieving pilot staff list so make sure to que up system sync in case this table mysteriously disapeared
        return;
      }
      var PrevSelectedPIC = $('#BILLING_FPpilot').val();
      $('#BILLING_FPpilot').empty();
      var PrevSelectedSIC = $('#BILLING_FPSIC').val();
      $('#BILLING_FPSIC').empty();
      $('#BILLING_FPSIC').append($('<option />').val('').text('None'));
      for (var i in PILOT_data) {
        $('#BILLING_FPpilot').append(
          $('<option />').val(PILOT_data[i].chatname).text(escapeHtml(PILOT_data[i].chatname))
        );
        $('#BILLING_FPSIC').append(
          $('<option />').val(PILOT_data[i].chatname).text(escapeHtml(PILOT_data[i].chatname))
        );
      }
      BILLING_SelectFR_Crew(PrevSelectedPIC, PrevSelectedSIC);

      $('#BILLING_FlightReportsPageNewFlightReportTXT').prop('disabled', false);
    });
}

function BILLING_CreateFlightReportFromScheduler(SCH_Opts) {
  if (SCH_Opts.Type == 'Quote') {
    BILLING_CreateFlightReportFromQuote(SCH_Opts.Quote);
    return;
  }
  if (SCH_Opts.Type == 'Itinerary') {
    BILLING_updateshowFlightReports();
    for (var i in BILLING_FLIGHTREPORTS[0].OtherItins) {
      var I = BILLING_FLIGHTREPORTS[0].OtherItins[i];
      if (I.PrimaryKey == SCH_Opts.PrimaryKey) {
        BILLING_CreateFlightReportOther(i);
        return;
      }
    }
    alert(iTrans('Sorry this Itinerary is no longer available'));
  }
  if (SCH_Opts.Type == 'FlightReport') {
    BILLING_updateshowFlightReports();
    for (var i in BILLING_FLIGHTREPORTS[0].AdminList) {
      var I = BILLING_FLIGHTREPORTS[0].AdminList[i];
      if (I.PrimaryKey == SCH_Opts.PrimaryKey) {
        if (I.Completed == -1) {
          BILLING_viewLockedFlightReport(i, true);
        } else if (I.Completed == 1) {
          BILLING_viewFlightReportUncompleted(i, true);
        } else {
          BILLING_CreateFlightReportUncompleted(i, true);
        }
        return;
      }
    }
    alert(iTrans('Sorry this Flight Report is no longer available'));
  }
}

function checkExistingFlightReport(forceSave = false, SCH_Opts = false) {
  BillingDB('SELECT', 'CURRENT_FLIGHT_REPORT', null, function (e) {
    if (e == false) {
      CURRENT_FLIGHT_REPORT = {};
      BILLING_FLIGHTLEGS = [];
      if (SCH_Opts != false) {
        BILLING_CreateFlightReportFromScheduler(SCH_Opts);
        return;
      }
      if (forceSave) {
        BILLING_returnNewFlightReport();
        return;
      }
    } else {
      if (forceSave) {
        Billing_SignedFlightReport(false);
        return;
      }
      CURRENT_FLIGHT_REPORT = e;
      if (CURRENT_FLIGHT_REPORT.Legs == undefined) {
        CURRENT_FLIGHT_REPORT.Legs = [];
      }
      BILLING_FLIGHTLEGS = CURRENT_FLIGHT_REPORT.Legs;

      //automaticlly load unfinished flight report
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt:
          '<b>' +
          iTrans(
            'An unsaved new/modified flight report was found.  Please complete and save the flight report OR discard your changes.'
          ) +
          '</b>',
        buttons: {
          [iTrans('Complete')]: {
            click: function () {
              BILLING_ContinueFlightReportOffline();
            },
          },
          [iTrans('Discard Changes')]: {
            click: function () {
              BILLING_DELETE_FlightReportOffline();
              if (SCH_Opts != false) {
                BILLING_CreateFlightReportFromScheduler(SCH_Opts);
              }
            },
          },
        },
      });
    }
  }); //Store Itinerary Data
}

function BILLING_populate_jobscrew(json_data) {
  let Jobs = json_data;
  const JobsFiltered = Jobs.filter((job) => job.ClientPK > -1);
  REPORT_JOBS = Jobs;

  $('#BILLING_FPjob').empty();
  $('#RPT_FR_Client').empty();
  $('#RPT_FR_Job').empty();
  $('#RPT_FR_Job').append($('<option />').val(-1).text('All Jobs'));
  $('#RPT_FR_Client').append($('<option />').val(-1).text('All Clients'));

  var ClientPK = {};

  if (JobsFiltered.length == 1) {
    const I = JobsFiltered[0];
    $('#BILLING_FPjob').append(
      $('<option />')
        .val(I.ClientPK + '|' + I.JobID)
        .text('[' + I.Business + '] - ' + I.Name + ' #' + I.JobNum)
    );
    $('#BILLING_FPjob')
      .val(I.ClientPK + '|' + I.JobID)
      .change();

    return;
  }

  for (var J in Jobs) {
    var I = Jobs[J];
    if (I.ClientPK == -1) {
      $('#BILLING_FPjob').append(
        $('<option />')
          .val(I.ClientPK + '|' + I.JobID + '|' + I.clientList)
          .text('[-' + iTrans('GLOBAL JOB') + '-] - ' + I.Name + ' #' + I.JobNum)
      );
    } else {
      $('#BILLING_FPjob').append(
        $('<option />')
          .val(I.ClientPK + '|' + I.JobID + '|')
          .text('[' + I.Business + '] - ' + I.Name + ' #' + I.JobNum)
      );
      if (ClientPK[I.ClientPK] == undefined) {
        $('#RPT_FR_Client').append($('<option />').val(I.ClientPK).text(I.Business));
      }
      $('#RPT_FR_Job').append($('<option />').val(I.JobID).text(I.Name));
      ClientPK[I.ClientPK] = I.ClientPK;
    }
  }
}

function BILLING_FilterRptJobList(JobID = null) {
  var ClientPK = $('#RPT_FR_Client').val();
  console.log(ClientPK);
  $('#RPT_FR_Job').empty();
  $('#RPT_FR_Job').append($('<option />').val(-1).text('All Jobs'));
  var Jobs = REPORT_JOBS;
  for (var J in Jobs) {
    var I = Jobs[J];
    if ((ClientPK != I.ClientPK && I.ClientPK != -1 && ClientPK != -1) || (I.ClientPK == -1 && ClientPK == -1))
      continue;
    $('#RPT_FR_Job').append($('<option />').val(I.JobID).text(I.Name));
  }
  if (JobID != null) $('#RPT_FR_Job').val(JobID).selectmenu('refresh');
  else $('#RPT_FR_Job').selectmenu('refresh');
}

let BILLING_ACData = null;

function BILLING_PopulateIdentOptions() {
  if (!ONLINE) {
    return;
  }

  const setOptions = (ACData) => {
    const identSelect = $('#RPT_FR_Ident');
    identSelect.empty();
    identSelect.append($('<option />').val(-1).text(iTrans('all idents')));
    identSelect.val(-1).selectmenu('refresh');

    const includeDeleted = $('#RPT_FR_ShowDeletedAircraft').is(':checked');

    let filteredACs = ACData;
    if (!includeDeleted) {
      filteredACs = ACData.filter((ac) => ac.DELETED === 0);
    }

    const sortedACs = filteredACs.sort((a, b) => a.ident.localeCompare(b.ident));

    for (const ac of sortedACs) {
      identSelect.append($('<option />').val(ac.ident).text(ac.ident));
    }
  };

  if (BILLING_ACData) {
    setOptions(BILLING_ACData);
    return;
  }

  $.getJSON(BASEURL + `api/v2/groups/${GROUP_DATA.group}/aircraft?deleted=1`, function (ACData) {
    setOptions(ACData);
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function BILLING_PopulatePilotOptions() {
  let pilots = PILOT_data.sort((a, b) => a.chatname.localeCompare(b.chatname));

  const pilotSelect = $('#RPT_FR_Pilot');
  pilotSelect.empty();
  pilotSelect.append($('<option />').val(-1).text(iTrans('all pilots')));
  pilotSelect.val(-1).selectmenu('refresh');

  for (const pilot of pilots) {
    pilotSelect.append($('<option />').val(pilot.chatname).text(escapeHtml(pilot.chatname)));
  }
}

function BILLING_togglePilotIdentFilters() {
  // Hide pilot and ident filters unless you are on the archived or admin tab
  if ($('#BILLING_ADMIN_FLIGHTS').is(':checked')) {
    $('#RPT_FR_Filter_PilotIdent').show();
    return;
  }

  if ($('#BILLING_ARCHIVED').is(':checked')) {
    $('#RPT_FR_Filter_PilotIdent').show();
    return;
  }

  $('#RPT_FR_Filter_PilotIdent').hide();
  $('#RPT_FR_Pilot').val('-1').selectmenu('refresh');
  $('#RPT_FR_Ident').val('-1').selectmenu('refresh');
}

function BILLING_clearFilters() {
  $('#RPT_FR_Pilot').val('-1').selectmenu('refresh');
  $('#RPT_FR_Ident').val('-1').selectmenu('refresh');
  $('#RPT_FR_Client').val('-1').selectmenu('refresh');
  $('#RPT_FR_Job').val('-1').selectmenu('refresh');
  $('#BILLING_SearchFRNum').val('');

  let fromdate = new Date();
  fromdate.setMonth(fromdate.getMonth() - 1);
  $('#RPT_FR_FromDate').val(fromdate.toISOString().substring(0, 10));
  $('#RPT_FR_ToDate').val(new Date().toISOString().substring(0, 10));
}

function BILLING_FilterRptClientList() {
  var JobID = $('#RPT_FR_Job').val();
  var ClientPK = $('#RPT_FR_Client').val();
  if (JobID == -1) {
    BILLING_FilterRptJobList();
    return;
  }
  if (JobID == 0) {
    BILLING_FilterRptJobList();
    return;
  }
  var Jobs = REPORT_JOBS;
  for (var J in Jobs) {
    var I = Jobs[J];
    if (I.JobID == JobID) {
      if (I.ClientPK == -1 && ClientPK <= 0) {
        $('#RPT_FR_Client').val(-1).selectmenu('refresh');
      } else {
        if (I.ClientPK != -1) {
          $('#RPT_FR_Client').val(I.ClientPK).selectmenu('refresh');
        }
      }
      BILLING_FilterRptJobList(JobID);
      return;
    }
  }
  $('#RPT_FR_Client').val(-2).selectmenu('refresh');
}

function BILLING_returnNewFlightReport() {
  VIEWING_ARCHIVED = false;
  VIEWING_FLIGHT_REPORT = false;
  BILLING_EDITING_OFFLINE = false;
  $.mobile.changePage($('#BILLING_FlightReportsPage'), {
    changeHash: false,
    reverse: true,
  });
  if (REFRESH_FR_LIST) {
    REFRESH_FR_LIST = false;
    BILLING_NewFlightReport(true, true);
  } else {
    Initialize_Billing_Flight_Reports();
  }
}

function BILLING_returnNewFlightReport2() {
  $.mobile.changePage($('#BILLING_FlightReportsPage'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_NewFlightReport(true, true);
}

function BILLING_updateshowFlightReports() {
  BILLING_showFlightReports(BILLING_FLIGHTREPORTS);
}

function BILLING_showArchivedFlightReports() {
  $('#BILLING_FLIGHTREPORTS').html('<h1>' + iTrans('You have no archived flight reports') + '</h1>');
  var Controls = '';

  BILLING_togglePilotIdentFilters();

  Controls += '<div id="ArchivedFRhldr"></div>';
  $('#BILLING_FLIGHTREPORTS').html(Controls);
  $('#BILLING_FLIGHTREPORTS').enhanceWithin();

  populateArchivedReports();
}

function populateArchivedReports() {
  var FromDate = new Date($('#RPT_FR_FromDate').val());
  var ToDate = new Date($('#RPT_FR_ToDate').val());
  FromDate = dateToStringObject(FromDate).substring(0, 10);
  ToDate = dateToStringObject(ToDate).substring(0, 10);
  var Pilot = $('#RPT_FR_Pilot').val();
  var Ident = $('#RPT_FR_Ident').val();
  var FRnum = $('#BILLING_SearchFRNum').val();
  const jobIdList = getJobIdList();

  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getArchivedFR&Start=' +
      FromDate +
      '&End=' +
      ToDate +
      '&FRnum=' +
      FRnum +
      '&JobIDs=' +
      jobIdList +
      (Pilot == -1 ? '' : '&Pilot=' + Pilot) +
      (Ident == -1 ? '' : '&Ident=' + Ident),
    function (json_data) {
      if (json_data.length == 0) {
        $('#ArchivedFRhldr').html('<h1>' + iTrans('You have no archived flight reports') + '</h1>');
        $('#ArchivedFRhldr').enhanceWithin();
        return;
      }
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#ArchivedFRhldr').html('<h1>' + iTrans('Offline Cannot Retrieve Data') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception')
          ServerResponse(json_data[0], 'populateArchivedReports');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        //console.log("Received jobs List");
        ARCHIVED_FLIGHTREPORTS = json_data[0].FlightReports;
        showArchivedReports(json_data[0].Itinerary);
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#ArchivedFRhldr').html('<h1>' + iTrans('Connection Error!') + '</h1>');
  });
}

function showArchivedReports(Itinerary) {
  if (ARCHIVED_FLIGHTREPORTS.length > 0) {
    var html = '<h3>' + iTrans('Archived | Billed | Processed Reports') + '</h3>';
    var html2 = '<h3>' + iTrans('Deleted Reports') + '</h3>';
    var html3 = '<h3>' + iTrans('Non-Billable Itinerary') + '</h3>';
    html +=
      '<table id="BIT_FR1" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Client') +
      ' [' +
      iTrans('Job') +
      ']</th><th>' +
      iTrans('Itin') +
      '#</th><th>' +
      iTrans('FR') +
      '#</th><th>' +
      iTrans('Invoice') +
      '#</th><th>' +
      iTrans('Reported') +
      '</th><th>' +
      iTrans('Modified') +
      '</th><th>' +
      iTrans('Pilot') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    html2 +=
      '<table id="BIT_FR2" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Client') +
      ' [' +
      iTrans('Job') +
      ']</th><th>' +
      iTrans('Itin') +
      '#</th><th>' +
      iTrans('FR') +
      '#</th><th>' +
      iTrans('Deleted By') +
      '</th><th>' +
      iTrans('Date & Time') +
      '</th><th>' +
      iTrans('Pilot') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    var showDeleted = false;
    for (var U in ARCHIVED_FLIGHTREPORTS) {
      var ItinPK = ARCHIVED_FLIGHTREPORTS[U].Itin_ID;
      if (ItinPK == 0) ItinPK = 'N/A';
      if (ARCHIVED_FLIGHTREPORTS[U].DELETED == 0) {
        var Trailing = '';
        if (ARCHIVED_FLIGHTREPORTS[U].Details.length > 50) Trailing = '...';
        var Desc =
          '<div style="font-size:-1;">' + ARCHIVED_FLIGHTREPORTS[U].Details.substring(0, 50) + Trailing + '</div>';
        var RestoreBTN = '';

        RestoreBTN =
          "<button onClick='BILLING_MarkFRBillable(" +
          ARCHIVED_FLIGHTREPORTS[U].PrimaryKey +
          ")' data-mini='true' class='action-btn'><img src='./images/restore.png' width='25' height='25'></button>";

        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_viewArchivedFlightReport(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button>" +
          RestoreBTN +
          '</div>';
        var InvoiceNum = ARCHIVED_FLIGHTREPORTS[U].InvoiceNum;
        if (InvoiceNum == null) InvoiceNum = 'N/A';
        var processedClass = '';
        if (ARCHIVED_FLIGHTREPORTS[U].processed == 1) processedClass = 'yellowbg';
        if (ARCHIVED_FLIGHTREPORTS[U].Completed == 1)
          html +=
            '<tr class="' +
            processedClass +
            '"><td>' +
            ARCHIVED_FLIGHTREPORTS[U].Job.Business +
            ' [' +
            ARCHIVED_FLIGHTREPORTS[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            ARCHIVED_FLIGHTREPORTS[U].PrimaryKey +
            '</td><td>' +
            InvoiceNum +
            '</td><td>' +
            ARCHIVED_FLIGHTREPORTS[U].Date +
            '</td><td>' +
            ARCHIVED_FLIGHTREPORTS[U].modified +
            '</td><td>' +
            ARCHIVED_FLIGHTREPORTS[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
      } else {
        showDeleted = true;
        var Trailing2 = '';
        if (ARCHIVED_FLIGHTREPORTS[U].Details.length > 50) Trailing2 = '...';
        var Desc2 =
          '<div style="font-size:-1;">' + ARCHIVED_FLIGHTREPORTS[U].Details.substring(0, 50) + Trailing2 + '</div>';
        var actions2 =
          "<div class='vertical-center-container'><button onClick='BILLING_viewArchivedFlightReport(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_RestoreDeletedFlightReport(" +
          U +
          ")' data-mini='true' class='action-btn'><img src='./images/restore.png' width='25' height='25' /></button></div>";
        html2 +=
          '<tr><td>' +
          ARCHIVED_FLIGHTREPORTS[U].Job.Business +
          ' [' +
          ARCHIVED_FLIGHTREPORTS[U].Job.Name +
          ']' +
          Desc2 +
          '</td><td>' +
          ItinPK +
          '</td><td>' +
          ARCHIVED_FLIGHTREPORTS[U].PrimaryKey +
          '</td><td>' +
          ARCHIVED_FLIGHTREPORTS[U].By_Name +
          '</td><td>' +
          ARCHIVED_FLIGHTREPORTS[U].modified +
          '</td><td>' +
          ARCHIVED_FLIGHTREPORTS[U].Pilot +
          '</td><td>' +
          actions2 +
          '</td></tr>';
      }
    }
    html += '</table>';
    html2 += '</table>';
    if (showDeleted) html += html2;
  } else {
    html = '<h3>' + iTrans('No Archived or Billed Reports found') + '</h3>';
  }
  if (Itinerary.length > 0) {
    html3 = '<h3>' + iTrans('Non-Billable Itinerary') + '</h3>';

    html3 +=
      '<table id="BIT_ITIN1" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Ident') +
      '</th><th>' +
      iTrans('Itin') +
      '#</th><th>' +
      iTrans('Filed UTC') +
      '</th><th>' +
      iTrans('Pilot') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var i in Itinerary) {
      var I = Itinerary[i];
      var actions3 =
        "<div class='vertical-center-container'><button onClick='BILLING_MarkItinBillable(" +
        I.PrimaryKey +
        ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button></div>";
      html3 +=
        '<tr><td>' +
        I.ident +
        '</td><td>' +
        I.PrimaryKey +
        '</td><td>' +
        I.filed +
        '</td><td>' +
        I.pilot +
        '<td>' +
        actions3 +
        '</td></tr>';
    }
    html3 += '</tbody></table>';
    html += html3;
  } else {
    html += '<h3>' + iTrans('No Un-Billed Itinerary found') + '</h3>';
  }
  $('#ArchivedFRhldr').html(html);
  $('#ArchivedFRhldr').enhanceWithin();
  AddTableSorter('BIT_FR1');
  AddTableSorter('BIT_FR2');
  AddTableSorter('BIT_ITIN1');
}

function BILLING_MarkItinBillable(PK) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      iTrans('Are you sure you want to mark this Itinerary as billable?') +
      '<br />' +
      iTrans(
        'Any associated pilot staff will see this as a reportable Itinerary under [Itins] or [Other Itins] for flight reporting.'
      ),
    buttons: {
      [iTrans('Restore')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=restoreItin&PrimaryKey=' + PK, function (json_data) {
            if (json_data[0].ServerResponse != undefined) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Offline') {
                $('#ArchivedFRhldr').html('<h1>' + iTrans("Offline Couldn't Mark Itinerary as Billable") + '</h1>');
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'BILLING_MarkItinBillable');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Success') {
                BILLING_NewFlightReport(false, true);
                populateArchivedReports();
              }
            } else {
              populateArchivedReports();
            }
          }).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
            $('#ArchivedFRhldr').html('<h1>' + iTrans('Connection Error!') + '</h1>');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_MarkFRBillable(PK) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to mark this Flight Report as billable?'),
    buttons: {
      [iTrans('Restore')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=makeFlightReportBillable&PK=' + PK, function (json_data) {
            if (json_data[0].ServerResponse != undefined) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Offline') {
                $('#ArchivedFRhldr').html('<h1>' + iTrans("Offline Couldn't Mark Flight Report as Billable") + '</h1>');
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'BILLING_MarkItinBillable');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Success') {
                BILLING_NewFlightReport(false, true);
                populateArchivedReports();
              }
            } else {
              populateArchivedReports();
            }
          }).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
            $('#ArchivedFRhldr').html('<h1>' + iTrans('Connection Error!') + '</h1>');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_viewArchivedFlightReport(index, admin) {
  VIEWING_ARCHIVED = true;
  VIEWING_FLIGHT_REPORT = true;
  var U;
  $('#Billing_SaveFlightReportBTN').prop('disabled', true);
  BILLING_EDITING_OFFLINE = false;
  U = ARCHIVED_FLIGHTREPORTS[index];
  signatureDATA = {
    data: U.signature,
    text: U.signed_by,
  };
  //get Legs for flight report
  CURRENT_FLIGHT_REPORT = U;
  BILLING_FLIGHTLEGS = U.Legs;
  if (U.Crew != '') {
    BILLING_FLIGHTREPORT_CREW = JSON.parse(U.Crew);
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    U.Crew = [];
  }
  Billing_CompleteFlightReport(true);
  $('#BILLING_SIGNATURE').hide();
  $('#Billing_SaveFlightReportBTN').prop('disabled', true);
}

function BILLING_viewLockedFlightReport(index, admin) {
  VIEWING_ARCHIVED = true;
  VIEWING_FLIGHT_REPORT = true;
  var U;

  $('#Billing_SaveFlightReportBTN').prop('disabled', false);

  $('#Billing_SaveFlightReportBTN').prop('disabled', true);
  BILLING_EDITING_OFFLINE = false;
  U = BILLING_FLIGHTREPORTS[0].Uncompleted[index];
  if (admin) U = BILLING_FLIGHTREPORTS[0].AdminList[index];
  signatureDATA = {
    data: U.signature,
    text: U.signed_by,
  };
  //get Legs for flight report
  CURRENT_FLIGHT_REPORT = U;
  BILLING_FLIGHTLEGS = U.Legs;
  if (U.Crew != '') {
    BILLING_FLIGHTREPORT_CREW = JSON.parse(U.Crew);
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    U.Crew = [];
  }
  Billing_CompleteFlightReport(true);
  $('#BILLING_SIGNATURE').hide();
  $('#Billing_SaveFlightReportBTN').prop('disabled', true);
}

function BILLING_RestoreDeletedFlightReport(index) {
  var U;
  U = ARCHIVED_FLIGHTREPORTS[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      iTrans('Are you sure you want to restore this flight report?') +
      '<br />' +
      iTrans(
        'Any Itinerary that may have been associated with this report will not be updated or re-marked as reported!'
      ),
    buttons: {
      [iTrans('Restore')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Billing-AJAX.php?action=restoreFR&PrimaryKey=' + U.PrimaryKey,
            function (json_data) {
              if (json_data[0].ServerResponse != undefined) {
                //status could be offline/Not Logged In/Uncaught Exception
                if (json_data[0].ServerResponse == 'Offline') {
                  $('#ArchivedFRhldr').html('<h1>' + iTrans("Offline Couldn't Restore Flight Report") + '</h1>');
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception')
                  ServerResponse(json_data[0], 'RestoreDeletedFlightReport');
                if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                if (json_data[0].ServerResponse == 'Success') {
                  BILLING_NewFlightReport(false, true);
                  populateArchivedReports();
                }
              } else {
                populateArchivedReports();
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
            $('#ArchivedFRhldr').html('<h1>' + iTrans('Connection Error!') + '</h1>');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_showFlightReports(data) {
  if (!data) {
    return; //don't throw this error
    const e = new Error('Cannot show flight reports, data is null');
    vueApp.sentry.captureException(e);
    vueApp.utilities.dialogs.error
      .message(iTrans('Sorry, flight report data appears to be unavailable.'))
      .setStackTrace(e.stack)
      .show();
  }

  BILLING_FLIGHTREPORTS = data;

  BILLING_togglePilotIdentFilters();

  $('#Billing_SaveFlightReportBTN').prop('disabled', false);
  var PilotItins = data[0].PilotItins;
  var OtherItins = data[0].OtherItins;
  var Uncompleted = data[0].Uncompleted;
  if (data[0].timestamp != undefined) {
    var dataAge = (new Date().getTime() - data[0].timestamp) / 1000;
    if (dataAge < 1) {
      if (LOW_BANDWIDTH)
        $('#FlightReportsHeader').html(
          iTrans('Flight Reports') + ': ' + iTrans('Low Bandwidth Enabled Use Refresh to update')
        );
      else $('#FlightReportsHeader').html(iTrans('Flight Reports'));
    } else {
      dataAge = secondsToAge(dataAge);
      if (ONLINE) {
        if (LOW_BANDWIDTH)
          $('#FlightReportsHeader').html(
            iTrans('Flight Reports Updated') +
              ' ' +
              dataAge +
              ' ' +
              iTrans('ago') +
              ': ' +
              iTrans('Low Bandwidth Enabled.  Use Refresh to update')
          );
        else $('#FlightReportsHeader').html(iTrans('Flight Reports Updated') + ' ' + dataAge + ' ' + iTrans('ago'));
      } else
        $('#FlightReportsHeader').html(iTrans('OFFLINE: Flight Reports as of') + ' ' + dataAge + ' ' + iTrans('ago'));
    }
  } else {
    if (ONLINE) {
      if (LOW_BANDWIDTH)
        $('#FlightReportsHeader').html(
          iTrans('Flight Reports') + ': ' + iTrans('Low Bandwidth Enabled Use Refresh to update')
        );
      else $('#FlightReportsHeader').html(iTrans('Flight Reports'));
    } else $('#FlightReportsHeader').html(iTrans('OFFLINE: Flight Reports'));
  }
  var AdminList = [];
  if (USER_RIGHTS.BillingAccess == 1 || USER_RIGHTS.SuperUser == 1) AdminList = data[0].AdminList;
  if ($('#BILLING_MYUNREPORTED').is(':checked')) {
    var html = '<h3>' + iTrans('Unreported Itineraries') + '</h3>';
    if (PilotItins.length > 0) {
      if (PilotItins.length >= 200)
        html +=
          '<h2>' +
          iTrans('There are more than 200 records in this date range.  Displaying the most recent 200 items only!') +
          '</h2>';
      html +=
        '<table id="BIT_FR3" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Ident') +
        '</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('Itin Filed UTC') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var P in PilotItins) {
        var date1 = PilotItins[P].filed;
        date1 = date1.split(' ');
        date1 = date1[0];
        var Trailing = '';
        if (PilotItins[P].Details.length > 50) Trailing = '...';
        var Desc = '<div style="font-size:-1;">' + PilotItins[P].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CreateFlightReportPilot(" +
          P +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_MarkFlightNonReportable(" +
          P +
          ")' data-mini='true' class='action-btn' " +
          (PilotItins[P].status == 'CLOSED' ? '' : 'disabled') +
          "><img src='./images/delete.svg'/></button></div>";
        html +=
          '<tr class="' +
          (PilotItins[P].status == 'CLOSED' ? '' : 'yellowbg') +
          '"><td>' +
          PilotItins[P].ident +
          Desc +
          '</td><td>' +
          (PilotItins[P].flight_report_id == null ? 'N/A' : PilotItins[P].flight_report_id) +
          '</td><td>' +
          PilotItins[P].PrimaryKey +
          '</td><td>' +
          PilotItins[P].filed +
          '</td><td>' +
          PilotItins[P].pilot +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No unreported flights') + '</p>';
  }
  if ($('#BILLING_OTHERUNREPORTED').is(':checked')) {
    var html = '<h3>' + iTrans('Other Itineraries') + '</h3>';
    if (OtherItins.length > 0) {
      if (OtherItins.length >= 200)
        html +=
          '<h2>' +
          iTrans('There are more than 200 records in this date range.  Displaying the most recent 200 items only!') +
          '</h2>';
      html +=
        '<table id="BIT_FR3" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Ident') +
        '</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('Itin Filed UTC') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var O in OtherItins) {
        var Trailing = '';
        if (OtherItins[O].Details.length > 50) Trailing = '...';
        var Desc = '<div style="font-size:-1;">' + OtherItins[O].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CreateFlightReportOther(" +
          O +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_MarkFlightNonReportableAdmin(" +
          O +
          ")' data-mini='true' class='action-btn' " +
          (OtherItins[O].status == 'CLOSED' ? '' : 'disabled') +
          "><img src='./images/delete.svg' /></button></div>";

        html +=
          '<tr class="' +
          (OtherItins[O].status == 'CLOSED' ? '' : 'yellowbg') +
          '"><td>' +
          OtherItins[O].ident +
          Desc +
          '</td><td>' +
          (OtherItins[O].flight_report_id == null ? 'N/A' : OtherItins[O].flight_report_id) +
          '</td><td>' +
          OtherItins[O].PrimaryKey +
          '<td>' +
          OtherItins[O].filed +
          '</td><td>' +
          OtherItins[O].pilot +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No unreported flights') + '</p>';
  }
  if ($('#BILLING_UNFINISHED').is(':checked')) {
    var html = '';
    if (BILLING_OFFLINE_REPORTS.length > 0) {
      html += '<h3>' + iTrans('Offline Reports') + '</h3>';
      html +=
        '<table id="BIT_FR3" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        ' [' +
        iTrans('Job') +
        ']</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Reported') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var O in BILLING_OFFLINE_REPORTS) {
        var Trailing = '';
        var ItinPK = BILLING_OFFLINE_REPORTS[O].Itin_ID;
        if (ItinPK == 0) ItinPK = 'N/A';
        if (BILLING_OFFLINE_REPORTS[O].Details.length > 50) Trailing = '...';
        var Desc =
          '<div style="font-size:-1;">' + BILLING_OFFLINE_REPORTS[O].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CreateFlightReportOffline(" +
          O +
          ",false)' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteOfflineFlightReport(" +
          O +
          ",false)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        html +=
          '<tr><td>' +
          BILLING_OFFLINE_REPORTS[O].Job.Business +
          ' [' +
          BILLING_OFFLINE_REPORTS[O].Job.Name +
          ']' +
          Desc +
          '</td><td>' +
          ItinPK +
          '</td><td>' +
          BILLING_OFFLINE_REPORTS[O].PrimaryKey +
          '<td>' +
          BILLING_OFFLINE_REPORTS[O].Date +
          '</td><td>' +
          BILLING_OFFLINE_REPORTS[O].Pilot +
          '</td><td>' +
          actions +
          '</td></tr>';
      }

      html += '</table>';
    }
    if (Uncompleted.length > 0) {
      if (Uncompleted.length >= 200)
        html +=
          '<h2>' +
          iTrans('There are more than 200 records in this date range.  Displaying the most recent 200 items only!') +
          '</h2>';
      html += '<h3>' + iTrans('In-Progress') + '</h3>';
      html +=
        '<table id="BIT_FR3" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        ' [' +
        iTrans('Job') +
        ']</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Reported') +
        '</th><th>' +
        iTrans('Modified') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';

      for (var U in Uncompleted) {
        var ItinPK = Uncompleted[U].Itin_ID;
        if (ItinPK == 0) ItinPK = 'N/A';
        var Trailing = '';
        if (Uncompleted[U].Details.length > 50) Trailing = '...';
        var Desc = '<div style="font-size:-1;">' + Uncompleted[U].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_CreateFlightReportUncompleted(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteFlightReport(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        if (Uncompleted[U].Completed == 0 || Uncompleted[U].Completed == -2)
          html +=
            '<tr><td>' +
            Uncompleted[U].Job.Business +
            ' [' +
            Uncompleted[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            Uncompleted[U].PrimaryKey +
            '<td>' +
            Uncompleted[U].Date +
            '</td><td>' +
            Uncompleted[U].modified +
            '</td><td>' +
            Uncompleted[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
        if (Uncompleted[U].Completed == -1) {
          //Pending Signature
          actions =
            "<div class='vertical-center-container'><button onClick='BILLING_viewLockedFlightReport(" +
            U +
            ",false)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_UnlockFlightReport(" +
            U +
            ",false)' data-mini='true' class='action-btn'><img src='./images/unlock.svg'  width='20' height='20'/></button></div>";
          html +=
            "<tr><td><img src='./images/signature.svg' width='20' height='20'/>" +
            Uncompleted[U].Job.Business +
            ' [' +
            Uncompleted[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            Uncompleted[U].PrimaryKey +
            '<td>' +
            Uncompleted[U].Date +
            '</td><td>' +
            Uncompleted[U].modified +
            '</td><td>' +
            Uncompleted[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
        }
      }

      html += '</table>';
      html += '<h3>' + iTrans('Unbilled') + '</h3>';
      html +=
        '<table id="BIT_FR3BB" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        ' [' +
        iTrans('Job') +
        ']</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Reported') +
        '</th><th>' +
        iTrans('Modified') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in Uncompleted) {
        var ItinPK = Uncompleted[U].Itin_ID;
        if (ItinPK == 0) ItinPK = 'N/A';
        var Trailing = '';
        if (Uncompleted[U].Details.length > 50) Trailing = '...';
        var Desc = '<div style="font-size:-1;">' + Uncompleted[U].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_viewFlightReportUncompleted(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_CreateFlightReportUncompleted(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteFlightReport(" +
          U +
          ",false)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        if (Uncompleted[U].Completed == 1)
          html +=
            '<tr><td>' +
            Uncompleted[U].Job.Business +
            ' [' +
            Uncompleted[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            Uncompleted[U].PrimaryKey +
            '<td>' +
            Uncompleted[U].Date +
            '</td><td>' +
            Uncompleted[U].modified +
            '</td><td>' +
            Uncompleted[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
      }

      html += '</table>';
    } else html += '<h3>' + iTrans('Nothing Editable') + '</h3>';
  }
  if ($('#BILLING_ADMIN_FLIGHTS').is(':checked')) {
    var html = '';
    if (AdminList.length > 0) {
      if (AdminList.length >= 200)
        html +=
          '<h2>' +
          iTrans('There are more than 200 records in this date range.  Displaying the most recent 200 items only!') +
          '</h2>';
      html += '<h3>In-Progress</h3>';
      html +=
        '<table id="BIT_FR3" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        ' [' +
        iTrans('Job') +
        ']</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Reported') +
        '</th><th>' +
        iTrans('Modified') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in AdminList) {
        var ItinPK = AdminList[U].Itin_ID;
        if (ItinPK == 0) ItinPK = 'N/A';
        var Trailing = '';
        if (AdminList[U].Details.length > 50) Trailing = '...';
        var Desc = '<div style="font-size:-1;">' + AdminList[U].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_viewEmailHistory(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/ffmenu.svg' width='20' height='20'/></button><button onClick='BILLING_viewFlightReportUncompleted(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_DeleteFlightReport(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        if (AdminList[U].Completed == 0) {
          html +=
            '<tr><td>' +
            AdminList[U].Job.Business +
            ' [' +
            AdminList[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            AdminList[U].PrimaryKey +
            '</td><td>' +
            AdminList[U].Date +
            '</td><td>' +
            AdminList[U].modified +
            '</td><td>' +
            AdminList[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
        }
        if (AdminList[U].Completed == -1) {
          //Pending Signature
          actions =
            "<div class='vertical-center-container'><button onClick='BILLING_viewEmailHistory(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/ffmenu.svg' width='20' height='20'/></button><button onClick='BILLING_viewLockedFlightReport(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_UnlockFlightReport(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/unlock.svg'  width='20' height='20'/></button></div>";
          html +=
            "<tr><td><img src='./images/signature.svg' width='20' height='20'/>" +
            AdminList[U].Job.Business +
            ' [' +
            AdminList[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            AdminList[U].PrimaryKey +
            '</td><td>' +
            AdminList[U].Date +
            '</td><td>' +
            AdminList[U].modified +
            '</td><td>' +
            AdminList[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
        }
        if (AdminList[U].Completed == -2) {
          //A user other than the Pilot or SIC has created and marked this FR as in Progress
          actions =
            "<div class='vertical-center-container'><button onClick='BILLING_viewEmailHistory(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/ffmenu.svg' width='20' height='20'/></button><button onClick='BILLING_viewFlightReportUncompleted(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_CreateFlightReportUncompleted(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteFlightReport(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
          html +=
            '<tr><td>' +
            AdminList[U].Job.Business +
            ' [' +
            AdminList[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            AdminList[U].PrimaryKey +
            '</td><td>' +
            AdminList[U].Date +
            '</td><td>' +
            AdminList[U].modified +
            '</td><td>' +
            AdminList[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
        }
      }

      html += '</table>';
      html += '<h3>' + iTrans('Unbilled') + '</h3>';
      html +=
        '<table id="BIT_FR3B" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        ' [' +
        iTrans('Job') +
        ']</th><th>' +
        iTrans('Itin') +
        '#</th><th>' +
        iTrans('FR') +
        '#</th><th>' +
        iTrans('Reported') +
        '</th><th>' +
        iTrans('Modified') +
        '</th><th>' +
        iTrans('Pilot') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in AdminList) {
        var ItinPK = AdminList[U].Itin_ID;
        if (ItinPK == 0) ItinPK = 'N/A';
        var Trailing = '';
        if (AdminList[U].Details.length > 50) Trailing = '...';
        var Desc = '<div style="font-size:-1;">' + AdminList[U].Details.substring(0, 50) + Trailing + '</div>';
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_viewEmailHistory(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/ffmenu.svg' width='20' height='20'/></button><button onClick='BILLING_viewFlightReportUncompleted(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_CreateFlightReportUncompleted(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_MarkBilledFlightReport(" +
          U +
          ",true, true)' data-mini='true' class='action-btn'><img src='./images/Check_mark_yellow.svg' /></button><button onClick='BILLING_DeleteFlightReport(" +
          U +
          ",true)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";

        if (AdminList[U].JobPK == 0) {
          //Non revenue job give option to mark as billed.
          actions =
            "<div class='vertical-center-container'><button onClick='BILLING_viewEmailHistory(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/ffmenu.svg' width='20' height='20'/></button><button onClick='BILLING_viewFlightReportUncompleted(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button><button onClick='BILLING_CreateFlightReportUncompleted(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_MarkBilledFlightReport(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button><button onClick='BILLING_DeleteFlightReport(" +
            U +
            ",true)' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        }
        if (AdminList[U].Completed == 1)
          html +=
            '<tr><td>' +
            AdminList[U].Job.Business +
            ' [' +
            AdminList[U].Job.Name +
            ']' +
            Desc +
            '</td><td>' +
            ItinPK +
            '</td><td>' +
            AdminList[U].PrimaryKey +
            '</td><td>' +
            AdminList[U].Date +
            '</td><td>' +
            AdminList[U].modified +
            '</td><td>' +
            AdminList[U].Pilot +
            '</td><td>' +
            actions +
            '</td></tr>';
      }

      html += '</table>';
    } else html += '<h3>' + iTrans('Nothing Editable') + '</h3>';
  }
  $('#BILLING_FLIGHTREPORTS').html(html);
  $('#BILLING_FLIGHTREPORTS').enhanceWithin();
  AddTableSorter('BIT_FR3');
  AddTableSorter('BIT_FR3B');
  AddTableSorter('BIT_FR3BB');
}

function BILLING_DeleteOfflineFlightReport(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      iTrans('Are you sure you want to delete this offline flight report?') +
      '<br />' +
      iTrans('This cannot be undone!'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          BILLING_OFFLINE_REPORTS.splice(index, 1);
          BillingDB('INSERT', 'OFFLINE_SYNC', BILLING_OFFLINE_REPORTS, BILLING_updateshowFlightReports);
          //File bug report
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_DeleteFlightReport(index, Admin) {
  var U;
  if (Admin) U = BILLING_FLIGHTREPORTS[0].AdminList[index];
  else U = BILLING_FLIGHTREPORTS[0].Uncompleted[index];
  //console.log(U);
  var attachedTXT = '';
  if (U.Itin_ID != 0) {
    attachedTXT = 1;
  }

  if (attachedTXT == '') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt: iTrans('Are you sure you want to delete this flight report.'),
      buttons: {
        [iTrans('Delete')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'menu-Billing-AJAX.php?action=DeleteFlightReport&PrimaryKey=' +
                U.PrimaryKey +
                '&Itin_ID=' +
                U.Itin_ID +
                '&MarkItin=0',
              function (json_data) {
                if (json_data.length > 0) {
                  if (json_data[0].ServerResponse != undefined) {
                    //status could be offline/Not Logged In/Uncaught Exception
                    if (json_data[0].ServerResponse == 'Offline') {
                      alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                    }
                    if (json_data[0].ServerResponse == 'Uncaught Exception')
                      ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                    if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                    if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
                  }
                } else {
                  alert('' + iTrans('Failed to mark flight report') + '');
                }
              }
            ).fail(function (jqXHR, status, error) {
              AJAXFAIL(error);
            });
            //File bug report
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  } else {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        iTrans('The report is currently attached to itinerary') +
        '# ' +
        U.Itin_ID +
        ' ' +
        iTrans('Would you like to mark the itinerary for pilot action') +
        '?',
      buttons: {
        [iTrans('Delete and Mark')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'menu-Billing-AJAX.php?action=DeleteFlightReport&PrimaryKey=' +
                U.PrimaryKey +
                '&Itin_ID=' +
                U.Itin_ID +
                '&MarkItin=1',
              function (json_data) {
                if (json_data.length > 0) {
                  if (json_data[0].ServerResponse != undefined) {
                    //status could be offline/Not Logged In/Uncaught Exception
                    if (json_data[0].ServerResponse == 'Offline') {
                      alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                    }
                    if (json_data[0].ServerResponse == 'Uncaught Exception')
                      ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                    if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                    if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
                  }
                } else {
                  alert('' + iTrans('Failed to mark flight report') + '');
                }
              }
            ).fail(function (jqXHR, status, error) {
              AJAXFAIL(error);
            });
            //File bug report
          },
        },
        [iTrans('Delete Only')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'menu-Billing-AJAX.php?action=DeleteFlightReport&PrimaryKey=' +
                U.PrimaryKey +
                '&Itin_ID=' +
                U.Itin_ID +
                '&MarkItin=0',
              function (json_data) {
                if (json_data.length > 0) {
                  if (json_data[0].ServerResponse != undefined) {
                    //status could be offline/Not Logged In/Uncaught Exception
                    if (json_data[0].ServerResponse == 'Offline') {
                      alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                    }
                    if (json_data[0].ServerResponse == 'Uncaught Exception')
                      ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                    if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                    if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
                  }
                } else {
                  alert('' + iTrans('Failed to mark flight report') + '');
                }
              }
            ).fail(function (jqXHR, status, error) {
              AJAXFAIL(error);
            });
            //File bug report
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  }
}

function BILLING_UnlockFlightReport(index, Admin) {
  var U;
  if (Admin) U = BILLING_FLIGHTREPORTS[0].AdminList[index];
  else {
    U = BILLING_FLIGHTREPORTS[0].Uncompleted[index];
    if (U.By_PK != USER_PK && U.By_PK != 0) {
      var by = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === U.By_PK);
      var editor = 'Unknown';
      if (by != undefined) editor = by.chatname;
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Admin Lock'),
        headerClose: false,
        buttonPrompt: editor + ' ' + iTrans('has locked this flight report.  Contact them to have it unlocked'),
        buttons: {
          [iTrans('OK')]: {
            click: function () {},
          },
        },
      });
      return;
    }
  }

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to cancel the signature request for this flight report.'),
    buttons: {
      [iTrans('Unlock')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Billing-AJAX.php?action=UnlockFlightReport&PrimaryKey=' + U.PrimaryKey,
            function (json_data) {
              if (json_data.length > 0) {
                if (json_data[0].ServerResponse != undefined) {
                  //status could be offline/Not Logged In/Uncaught Exception
                  if (json_data[0].ServerResponse == 'Offline') {
                    alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                  }
                  if (json_data[0].ServerResponse == 'Uncaught Exception')
                    ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                  if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                  if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
                }
              } else {
                alert('' + iTrans('Failed to mark flight report') + '');
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
          //File bug report
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_MarkBilledFlightReport(index, Admin, processed = false) {
  var U;
  if (Admin) U = BILLING_FLIGHTREPORTS[0].AdminList[index];
  else U = BILLING_FLIGHTREPORTS[0].Uncompleted[index];
  //console.log(U);
  if (!processed) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        iTrans(
          'Are you sure you want to archive this Non-Revenue flight report.  It will be moved to the archive and will no longer be visible for'
        ) +
        ' <b>' +
        iTrans('editing or billing') +
        '</b>.',
      buttons: {
        [iTrans('Archive')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'menu-Billing-AJAX.php?action=MarkBilledFlightReport&PrimaryKey=' +
                U.PrimaryKey +
                '&Itin_ID=' +
                U.Itin_ID +
                '&processed=' +
                processed,
              function (json_data) {
                if (json_data.length > 0) {
                  if (json_data[0].ServerResponse != undefined) {
                    //status could be offline/Not Logged In/Uncaught Exception
                    if (json_data[0].ServerResponse == 'Offline') {
                      alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                    }
                    if (json_data[0].ServerResponse == 'Uncaught Exception')
                      ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                    if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                    if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
                  }
                } else {
                  alert(iTrans('Failed to mark flight report'));
                }
              }
            ).fail(function (jqXHR, status, error) {
              AJAXFAIL(error);
            });
            //File bug report
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  } else {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        iTrans(
          'Are you sure you want to Mark this flight report as "Processed / Archived".  It will be moved to the archive and will no longer be visible for'
        ) +
        ' <b>' +
        iTrans('editing or billing') +
        '</b>.',
      buttons: {
        [iTrans('Archive')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'menu-Billing-AJAX.php?action=MarkBilledFlightReport&PrimaryKey=' +
                U.PrimaryKey +
                '&Itin_ID=' +
                U.Itin_ID +
                '&processed=' +
                processed,
              function (json_data) {
                if (json_data.length > 0) {
                  if (json_data[0].ServerResponse != undefined) {
                    //status could be offline/Not Logged In/Uncaught Exception
                    if (json_data[0].ServerResponse == 'Offline') {
                      alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                    }
                    if (json_data[0].ServerResponse == 'Uncaught Exception')
                      ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                    if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                    if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
                  }
                } else {
                  alert(iTrans('Failed to mark flight report'));
                }
              }
            ).fail(function (jqXHR, status, error) {
              AJAXFAIL(error);
            });
            //File bug report
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  }
}

function BILLING_CreateFlightReport(JobID) {
  //this is a blank flight report!!
  //Blank form inputs
  var parts = JobID.split('|');
  var JobPK = parts[1];
  var ClientPK = parts[0];
  var clientList = parts[2];
  if (ClientPK == -1) {
    BILLING_SelectFRClientPrompt(JobPK, clientList, BILLING_CreateFlightReport);
    return;
  }
  FLIGHT_REPORT_WAS_SIGNED = 0;
  FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;
  CURRENT_FLIGHT_REPORT = {
    Completed: 0,
    FC_Uplifts: {},
  };
  var Job = BILLING_GetJobData(JobID);

  CURRENT_FLIGHT_REPORT.AlertList = Job.AlertList;
  signatureDATA = {
    data: '',
    text: '',
  };
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
  });

  var now = new Date();
  var timezone = now.getTimezoneOffset();
  now.setMinutes(now.getMinutes() - timezone);
  $('#BILLING_FPdate').val(now.toISOString().slice(0, 10));
  BILLING_FLIGHTLEGS = [];

  $('#BILLING_FPjob').val(JobID).selectmenu('refresh', true); //select job dropdown select
  CURRENT_FLIGHT_REPORT.Job = Job;
  $('#BILLING_FPac').selectmenu('enable');
  $('#BILLING_FPac').val('').selectmenu('refresh', true); //select aircraft identification from dropdown
  BILLING_SelectFR_Crew(chatname, '');
  $('#BILLING_FPpax').val('');

  $('#BILLING_TDGOFF').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_TDGON').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_FUEL').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_FPdetails').val('');
  $('#BILLING_HOURPR').val(0);

  $('#BILLING_IPimaryKey').val(0);
  $('#BILLING_FPimaryKey').val('None');
  BILLING_FLIGHTREPORT_CREW = [];
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();
  //Create options for custom fields to be captured by clients
  BILLING_POP_CF(JobID);
  if (HOBBS == 1) {
    //Client has hobbs meter tracking.  get last times and update
    $('#BILLING_HOBBS').html('<h3>' + iTrans('Please Select an Aircraft To Populate Hobbs Data') + '</h3>');
  }
  BILLING_paxManifest($('#BILLING_FPac').val(), -1);
}

function BILLING_SelectFR_Crew(pilot, sic) {
  if (pilot != null) {
    if (userShouldHaveFDT(USER_RIGHTS.staffType) && USER_RIGHTS.chatname == pilot) {
      $('#BILLING_FPpilot').val(pilot); //select pilot from dropdown
    } else {
      if (USER_RIGHTS.chatname == pilot) {
        $('#BILLING_FPpilot').val(''); //select pilot from dropdown
      } else {
        $('#BILLING_FPpilot').val(pilot); //select pilot from dropdown
      }
    }
  }
  if (sic != null) {
    $('#BILLING_FPSIC').val(sic); //select SIC from dropdown
  }
  try {
    $('#BILLING_FPpilot').selectmenu('refresh', true);
    $('#BILLING_FPSIC').selectmenu('refresh', true);
  } catch (e) {
    console.log('no pilot select refresh');
  }
}

function BILLING_Set_PIC_SIC_PK() {
  CURRENT_FLIGHT_REPORT.Pilot_PK = BILLING_LookupChatnamePK($('#BILLING_FPpilot').val());
  CURRENT_FLIGHT_REPORT.SIC_PK = BILLING_LookupChatnamePK($('#BILLING_FPSIC').val());
}

function BILLING_LookupChatnamePK(chatname) {
  try {
    return LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === chatname && userShouldHaveFDT(x.staffType)).PrimaryKey;
  } catch (e) {
    return 0;
  }
}

function BILLING_POP_CREW() {
  var data = BILLING_FLIGHTREPORT_CREW;
  if (data.length > 0) {
    var html =
      '<h4>' +
      iTrans('Crew Members') +
      '</h4><table id="BIT_FR4" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>Remove</th></tr></thead>';
    for (var i in data) {
      var I = data[i];
      html +=
        '<tr><td>' +
        I.chatname +
        '</td><td>' +
        I.staffType +
        '</td><td><button onclick="BILLING_RemoveCrewMember(' +
        i +
        ')" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></td></tr>';
    }
    html += '</table>';
    $('#BILLING_FPcrew').html(html);
    AddTableSorter('BIT_FR4');
  } else {
    $('#BILLING_FPcrew').html('<h4>' + iTrans('No Crew Added') + '</h4>');
  }
}

function BILLING_POP_FuelUplift(legIndex = -1) {
  if ((CURRENT_FLIGHT_REPORT.Job.showFuel == 0 && GROUP_DATA.FC_FR_Capture == 0) || GROUP_DATA.FuelCache == 0) {
    $('#FRFuelUplift').html('');
    return;
  }
  if (legIndex == -1) {
    FC_Uplift.upLiftList = CURRENT_FLIGHT_REPORT.FC_Uplifts;
  }
  FC_Uplift.div = 'FRFuelUplift' + legIndex;
  FC_Uplift.curSearchID = legIndex;
  FC_Uplift.legIndex = legIndex;
  FC_Uplift.curTarget = 'FRFuelUplift' + legIndex;
  FR_UpliftLegSelect(legIndex);
}

function BillingAddCrew() {
  $.mobile.changePage($('#BILLING_CrewMemberPage'), {
    changeHash: false,
  });
  $('#FR_FilterCrewBase').empty();
  if (GROUP_DATA.Bases != undefined) {
    //console.log('Received Bases Data');
    $('#FR_FilterCrewBase').empty();
    $('#FR_FilterCrewBase').append('<option value="" selected>' + iTrans('None') + '</option>');
    for (var i in GROUP_DATA.Bases) {
      var bases = GROUP_DATA.Bases[i];
      $('#FR_FilterCrewBase').append('<option value="' + escapeHtml(bases.name) + '">' + bases.name + '</option>');
    }
  } else {
    $('#FR_FilterCrewBase').append('<option value="" selected>' + iTrans('None') + '</option>');
  }
  $('#FR_FilterCrewBase').selectmenu('refresh');
  BillingRefreshCrewList();
}

var SELECTED_CREW = {};

function BillingRefreshCrewList() {
  SELECTED_CREW = {};
  var html =
    '<button onClick="BILLING_AddCrewMembers();" data-mini="true" data-icon="plus" class="greenbg">' +
    iTrans('Add Selected Crew Members') +
    '</button>';
  var CurBase = $('#FR_FilterCrewBase').val();
  html +=
    '<table id="BIT_FR5" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Base') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Add') +
    '</th></tr></thead>';
  $('#BILLING_AddCrewMemberList').html('');
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    if (I.BASED_IN == undefined) I.BASED_IN = '';
    if (CurBase != '' && I.BASED_IN != CurBase) continue;
    var found = false;
    for (var f in BILLING_FLIGHTREPORT_CREW) {
      if (BILLING_FLIGHTREPORT_CREW[f].PrimaryKey == I.PrimaryKey) found = true;
    }

    if (!found)
      html +=
        '<tr id="FR_CrewSEL-' +
        i +
        '"><td onClick="BILLING_SelectCrewMemberRow(' +
        i +
        ');"><h4>' +
        I.chatname +
        '</h4></td><td>' +
        I.BASED_IN +
        '</td><td>' +
        I.staffType +
        '</td><td><input id="FR_CrewCHK-' +
        i +
        '" type="checkbox" onclick="BILLING_SelectCrewMember(this);" value="' +
        i +
        '" data-mini="true" class="action-btn"></td></tr>';
  }

  html += '</table>';
  html +=
    '<button onClick="BILLING_AddCrewMembers();" data-mini="true" data-icon="plus" class="greenbg">' +
    iTrans('Add Selected Crew Members') +
    '</button>';
  $('#BILLING_AddCrewMemberList').html(html);
  $('#BILLING_AddCrewMemberList').enhanceWithin();
  AddTableSorter('BIT_FR5');
}

function BILLING_SelectCrewMemberRow(index) {
  if ($('#FR_CrewCHK-' + index).is(':checked')) {
    $('#FR_CrewCHK-' + index)
      .prop('checked', false)
      .checkboxradio('refresh');
    BILLING_SelectCrewMember({ checked: false, value: index });
  } else {
    $('#FR_CrewCHK-' + index)
      .prop('checked', true)
      .checkboxradio('refresh');
    BILLING_SelectCrewMember({ checked: true, value: index });
  }
}

function BILLING_SelectCrewMember(e) {
  if (e.checked) {
    $('#FR_CrewSEL-' + e.value).addClass('greenbg');
    SELECTED_CREW[e.value] = e.value;
  } else {
    $('#FR_CrewSEL-' + e.value).removeClass('greenbg');
    delete SELECTED_CREW[e.value];
  }
}

function BILLING_AddCrewMembers(index) {
  for (var i in SELECTED_CREW) {
    BILLING_FLIGHTREPORT_CREW.push(STAFF_LIST[SELECTED_CREW[i]]);
  }
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_POP_CREW();
}

function BILLING_returnAddCrewMembers() {
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
    reverse: true,
  });
}

function BILLING_RemoveCrewMember(index) {
  BILLING_FLIGHTREPORT_CREW.splice(index, 1);
  BILLING_POP_CREW();
}

function ValidateHOURPR() {
  var val = $('#BILLING_HOURPR').val();
  if (val < 0) $('#BILLING_HOURPR').val(0);
  if (val > 100) $('#BILLING_HOURPR').val(100);
}

var CURRENT_FLIGHT_REPORT_LAST_JOB = '0|0';

function BILLING_PrevJob(JobID) {
  CURRENT_FLIGHT_REPORT_LAST_JOB = JobID;
}

var BILLING_BACKUP_LEGS = [];
var BILLING_BACKUP_Cfields = null;

function BILLING_POP_CF(JobID) {
  if (BILLING_FLIGHTLEGS.length > 0) {
    //prompt warning that changing jobs will destroy existing leg info
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('WARNING'),
      headerClose: false,
      buttonPrompt:
        iTrans('There is existing leg data for this flight report.') +
        '<br /><br /><b>' +
        iTrans('Custom Field data for all legs will be lost!') +
        '</b><br /><br /><b>' +
        iTrans('Are you sure you want to proceed?') +
        '</b>',
      buttons: {
        [iTrans('Yes')]: {
          click: function () {
            BILLING_BACKUP_LEGS = JSON.parse(JSON.stringify(BILLING_FLIGHTLEGS));
            if (CURRENT_FLIGHT_REPORT.Cfields != undefined) {
              BILLING_BACKUP_Cfields = JSON.parse(JSON.stringify(CURRENT_FLIGHT_REPORT.Cfields));
            } else {
              //do nothing but maybe something in the future if the breaks something
            }
            //BILLING_FLIGHTLEGS = [];
            CURRENT_FLIGHT_REPORT.Legs = [];
            BILLING_POP_CF2(JobID);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            //File bug report
            BILLING_BACKUP_LEGS = [];
            BILLING_BACKUP_Cfields = null;
            $('#BILLING_FPjob').val(CURRENT_FLIGHT_REPORT_LAST_JOB).selectmenu('refresh');
            BILLING_POP_CF2(CURRENT_FLIGHT_REPORT_LAST_JOB);
            CURRENT_FLIGHT_REPORT.Job = BILLING_GetJobData(CURRENT_FLIGHT_REPORT_LAST_JOB);
          },
        },
      },
    });
  } else {
    BILLING_BACKUP_LEGS = JSON.parse(JSON.stringify(BILLING_FLIGHTLEGS));
    if (CURRENT_FLIGHT_REPORT.Cfields != undefined) {
      BILLING_BACKUP_Cfields = JSON.parse(JSON.stringify(CURRENT_FLIGHT_REPORT.Cfields));
    }
    BILLING_POP_CF2(JobID);
  }
}

function BILLING_POP_CF2(JobID) {
  if (JobID == '0|0|') {
    $('#BILLING_FPjob-button').addClass('redbg');
  } else {
    $('#BILLING_FPjob-button').removeClass('redbg');
  }
  //populate custom fields for flight report
  $('#BILLING_CUSTOM_FILEDS_FR').html('');
  //console.log("Generating flight report custom fields");
  var Job = BILLING_GetJobData(JobID);
  if (Job == null) return;
  var CF = Job.Cfields;
  if (Job.BillBy == 'HourPR') $('#BILLING_HOURPR_HLDR').show();
  else $('#BILLING_HOURPR_HLDR').hide();

  var html = '';
  var Aircraft = $('#BILLING_FPac').val();
  if (CF.length > 0) {
    html = '<h3>' + iTrans('Additional Fields') + '</h3>';
    for (var i in CF) {
      var I = CF[i];
      if (typeof BILLING_Script_SkipCfieldInput === 'function') {
        if (window['BILLING_Script_SkipCfieldInput'](I)) {
          continue;
        }
      }
      if (I.Aircraft != Aircraft && I.Aircraft !== '') {
        continue;
      }
      if (!checkCfieldType('FR', I)) continue;
      if (I.PerLeg == 0) {
        if (BILLING_BACKUP_Cfields == null) {
          html += createCustomField('', I, false, 'FR');
        } else {
          var foundVal = false;
          var oldVal = '';
          for (var c in BILLING_BACKUP_Cfields) {
            var C = BILLING_BACKUP_Cfields[c];
            if (C.PrimaryKey == I.PrimaryKey) {
              foundVal = true;
              oldVal = C.Value;
            }
          }
          if (foundVal) {
            html += createCustomField('', I, oldVal, 'FR');
          } else {
            html += createCustomField('', I, false, 'FR');
          }
        }
      }
    }
  }
  BILLING_BACKUP_Cfields = null;
  $('#BILLING_CUSTOM_FILEDS_FR').html(html);
  $('#BILLING_CUSTOM_FILEDS_FR').enhanceWithin();
}

function BILLING_POP_EDIT_CF(Cfields) {
  //populate custom fields for flight report
  $('#BILLING_CUSTOM_FILEDS_FR').html('');
  if (Cfields == null) return;
  //console.log(Cfields);

  var Aircraft = CURRENT_FLIGHT_REPORT.Aircraft;
  var CF = Cfields;
  var html = '';
  if (CF.length > 0) {
    html = '<h3>' + iTrans('Additional Fields') + '</h3>';
    for (var i in CF) {
      var I = CF[i];
      if (typeof BILLING_Script_SkipCfieldInput === 'function') {
        if (window['BILLING_Script_SkipCfieldInput'](I)) {
          continue;
        }
      }
      if (I.Aircraft != Aircraft && I.Aircraft !== '') {
        continue;
      }
      if (!checkCfieldType('FR', I)) continue;
      if (I.PerLeg == 0) {
        html += createCustomField('', I, I.Value, 'FR');
      }
    }
  }
  $('#BILLING_CUSTOM_FILEDS_FR').html(html);
  $('#BILLING_CUSTOM_FILEDS_FR').enhanceWithin();
}

VIEWONLY = false;

function BILLING_viewFlightReportUncompleted(index, Admin) {
  //console.log("Loading Uncomplete Legs");
  REFRESH_FR_LIST = false;
  BILLING_EDITING_OFFLINE = false;
  VIEWING_FLIGHT_REPORT = true;
  var U;
  if (Admin) U = BILLING_FLIGHTREPORTS[0].AdminList[index];
  else U = BILLING_FLIGHTREPORTS[0].Uncompleted[index];
  signatureDATA = {
    data: U.signature,
    text: U.signed_by,
  };
  //get Legs for flight report
  CURRENT_FLIGHT_REPORT = U;

  $('#BILLING_SEND_FR_TO').val('');
  if (CURRENT_FLIGHT_REPORT.custom_emails != null) {
    $('#BILLING_SEND_FR_TO_LIST').attr('checked', true);
    $('#BILLING_SEND_FR_TO_hldr').show();
    $('#BILLING_SEND_FR_TO').val(CURRENT_FLIGHT_REPORT.custom_emails);
  }

  $('#BILLING_FPimaryKey').val(U.PrimaryKey);
  BILLING_FLIGHTLEGS = U.Legs;
  if (U.Crew != '') {
    BILLING_FLIGHTREPORT_CREW = JSON.parse(U.Crew);
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    U.Crew = [];
  }
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();
  VIEWONLY = true;
  Billing_CompleteFlightReport(true);
}

var BILLING_EDITING_OFFLINE = false;

function BILLING_CreateFlightReportOffline(index) {
  FLIGHT_REPORT_WAS_SIGNED = 0;
  FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;
  BILLING_EDITING_OFFLINE = true;
  signatureDATA = {
    data: '',
    text: '',
  };
  //Auto fill and lock Most Itinerary information if Already contained
  //Create options for custom fields to be captured by clients
  var U;
  U = BILLING_OFFLINE_REPORTS[index];

  CURRENT_FLIGHT_REPORT = U;
  CURRENT_FLIGHT_REPORT.OfflineIndex = index; //to update this offline index when re-saving
  BILLING_FLIGHTLEGS = U.Legs;
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
  });
  //console.log(U);
  signatureDATA = {
    data: U.signature,
    text: U.signed_by,
  };
  BILLING_POP_EDIT_CF(U.Cfields);
  $('#BILLING_IPimaryKey').val(U.Itin_ID);
  $('#BILLING_FPimaryKey').val(U.PrimaryKey);
  //console.log(U);
  //console.log($("#BILLING_FPimaryKey").val());
  $('#BILLING_FPdate').val(U.Date);
  $('#BILLING_FPjob')
    .val(U.ClientPK + '|' + U.JobPK)
    .selectmenu('refresh', true); //select job dropdown select
  CURRENT_FLIGHT_REPORT.Job = BILLING_GetJobData(U.ClientPK + '|' + U.JobPK);
  BILLING_checkAcIdent_and_select(U.Aircraft);
  if (HOBBS == 1) {
    $('#BILLING_FPac').selectmenu('disable');
  } else {
    if (U.Itin_ID == 0) $('#BILLING_FPac').selectmenu('enable');
    else $('#BILLING_FPac').selectmenu('disable');
  }
  BILLING_checkPIC_and_select(U.Pilot);
  //$("#BILLING_FPpilot").val(U.Pilot).selectmenu('refresh', true); //select pilot from dropdown
  BILLING_checkSIC_and_select(U.SIC);
  //$("#BILLING_FPSIC").val(U.SIC).selectmenu('refresh', true); //select pilot from dropdown

  $('#BILLING_FPpax').val(U.Passengers);
  if (U.Crew != '') {
    BILLING_FLIGHTREPORT_CREW = JSON.parse(U.Crew);
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  }
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();
  if (U.TDG == 1) {
    $('#BILLING_TDGOFF').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BILLING_TDGOFF').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', false).checkboxradio('refresh');
  }
  if (U.FuelProvided == 1) $('#BILLING_FUEL').prop('checked', true).checkboxradio('refresh');
  else $('#BILLING_FUEL').prop('checked', false).checkboxradio('refresh');
  if (U.BillByVAL == undefined) $('#BILLING_HOURPR').val(0);
  else $('#BILLING_HOURPR').val(U.BillByVAL);
  $('#BILLING_FPdetails').val(br2nl(U.Details));
  if (HOBBS == 1) {
    //Client has hobbs meter tracking.  get last times and update
    populateBillingHobbs(U);
  }
  BILLING_checkTechDispatch();
}

function BILLING_viewEmailHistory(index, Admin) {
  //Show Modified and Email history;
  $.mobile.changePage($('#BILLING_FR_ModHistoryPage'), {
    changeHash: false,
  });
  var H;
  if (Admin) H = BILLING_FLIGHTREPORTS[0].AdminList[index].modHistory;
  else H = BILLING_FLIGHTREPORTS[0].Uncompleted[index].modHistory;

  var html = '<h3>' + iTrans('Flight Report Modification History') + '</h3>';
  if (H.length == 0) html += '<h4>' + iTrans('No Modifications Recorded') + '</h4>';
  else {
    var html =
      '<table class="limit-table wrap"><thead><tr><th>' +
      iTrans('Modified') +
      '</th><th>' +
      iTrans('By') +
      '</th><th>' +
      iTrans('Email Log') +
      '</th><th>' +
      iTrans('Changes') +
      '</th></tr></thead><tbody>';
    for (var i in H) {
      var I = H[i];
      var eH = I.emailHistory;
      var MailLog = '';
      if (eH.length == 0) {
        MailLog = '<h4>' + iTrans('No Email History') + '</h4>';
      } else {
        MailLog = '<table style="width:100%;"><tbody>';
        for (var e in eH) {
          var E = eH[e];
          MailLog += '<tr><td>' + E.email + '</td><td>' + (E.successful == 0 ? 'Failed' : 'Success') + '</td></tr>';
        }
        MailLog += '</tobdy></table>';
      }
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_FR_ModHistory_Show(" +
        index +
        ',' +
        i +
        ',' +
        Admin +
        ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button>" +
        '</div>';
      html +=
        '<tr><td>' +
        I.modified +
        '</td><td>' +
        I.mod_name +
        '</td><td>' +
        MailLog +
        '</td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</tbody></table>';
  }

  $('#BILLING_FR_ModHistoryHLDR').html(html);
  $('#BILLING_FR_ModHistoryHLDR').enhanceWithin();
  $('#BILLING_FR_ModHistory_HTML_HLDR').html('');
}

function BILLING_FR_ModHistory_Show(index, modIndex, Admin) {
  var H;
  if (Admin) H = BILLING_FLIGHTREPORTS[0].AdminList[index].modHistory[modIndex];
  else H = BILLING_FLIGHTREPORTS[0].Uncompleted[index].modHistory[modIndex];
  console.log(H);
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getFR_ModifiedHTML&FR_PK=' +
      H.FR_PK +
      '&modified=' +
      encodeURIComponent(H.modified),
    function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_FR_ModHistory_HTML_HLDR').html(
            '<h1>' + iTrans('Offline Cannot View Moification History') + '</h1>'
          ); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing Add Crew Page');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          $('#BILLING_FR_ModHistory_HTML_HLDR').html(
            '<h2>' +
              iTrans('Flight Report Modified on') +
              ': ' +
              json_data[0].diff.modified +
              ' By: ' +
              json_data[0].diff.mod_name +
              '</h2><flightreport>' +
              json_data[0].diff.html +
              '</flightreport>'
          );
          $('#BILLING_FR_ModHistory_HTML_HLDR').enhanceWithin();
        }
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_FR_ModHistory_HTML_HLDR').html('<h1>' + iTrans('Connection Error') + '!</h1>');
  });
}

function BILLING_FR_ModHistory_EXIT() {
  $.mobile.changePage($('#BILLING_FlightReportsPage'), {
    changeHash: false,
  });
}

function BILLING_CreateFlightReportUncompleted(index, Admin) {
  BILLING_EDITING_OFFLINE = false;
  signatureDATA = {
    data: '',
    text: '',
  };
  //Auto fill and lock Most Itinerary information if Already contained
  //Create options for custom fields to be captured by clients
  var U;
  if (Admin) U = BILLING_FLIGHTREPORTS[0].AdminList[index];
  else U = BILLING_FLIGHTREPORTS[0].Uncompleted[index];
  if (U.ITIN != null) {
    if (U.FieldGear.length == 0 && U.ITIN.FieldGear.length != 0) U.FieldGear = U.ITIN.FieldGear;
  }

  CURRENT_FLIGHT_REPORT = U;
  console.log(CURRENT_FLIGHT_REPORT);

  if (CURRENT_FLIGHT_REPORT.custom_emails != null) {
    $('#BILLING_SEND_FR_TO_LIST').attr('checked', true);
    $('#BILLING_SEND_FR_TO_hldr').show();
    $('#BILLING_SEND_FR_TO').val(CURRENT_FLIGHT_REPORT.custom_emails);
  }

  BILLING_FLIGHTLEGS = U.Legs;
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
  });
  $('#BILLING_FP_EntryTimezone').val(U.EntryTimezone).selectmenu('refresh');
  $('#BILLING_FP_StartTimezone').val(U.StartTimezone).selectmenu('refresh');
  $('#BILLING_FP_EndTimezone').val(U.EndTimezone).selectmenu('refresh');
  //console.log(U);
  signatureDATA = {
    data: U.signature,
    text: U.signed_by,
  };
  BILLING_POP_EDIT_CF(U.Cfields);
  $('#BILLING_IPimaryKey').val(U.Itin_ID);
  $('#BILLING_FPimaryKey').val(U.PrimaryKey);

  //console.log(U);
  //console.log($("#BILLING_FPimaryKey").val());
  $('#BILLING_FPdate').val(U.Date);
  if ($('#BILLING_FPjob option[value="' + U.ClientPK + '|' + U.JobPK + '"]').length == 0) {
    $('#BILLING_FPjob').append(
      '<option value=' + U.ClientPK + '|' + U.JobPK + '>' + CURRENT_FLIGHT_REPORT.Job.Name + '</option>'
    );
  }
  $('#BILLING_FPjob')
    .val(U.ClientPK + '|' + U.JobPK)
    .selectmenu('refresh', true); //select job dropdown select
  if (U.JobPK == 0) {
    $('#BILLING_FPjob-button').addClass('redbg');
  } else {
    $('#BILLING_FPjob-button').removeClass('redbg');
  }

  BILLING_checkAcIdent_and_select(U.Aircraft);
  if (HOBBS == 1) {
    $('#BILLING_FPac').selectmenu('disable');
  } else {
    if (U.Itin_ID == 0) $('#BILLING_FPac').selectmenu('enable');
    else $('#BILLING_FPac').selectmenu('disable');
  }
  BILLING_checkPIC_and_select(U.Pilot);
  //$("#BILLING_FPpilot").val(U.Pilot).selectmenu('refresh', true); //select pilot from dropdown
  BILLING_checkSIC_and_select(U.SIC);
  //$("#BILLING_FPSIC").val(U.SIC).selectmenu('refresh', true); //select pilot from dropdown

  if (U.Crew != '') {
    BILLING_FLIGHTREPORT_CREW = JSON.parse(U.Crew);
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  } else {
    BILLING_FLIGHTREPORT_CREW = [];
    U.Crew = BILLING_FLIGHTREPORT_CREW;
  }
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();
  $('#BILLING_FPpax').val(U.Passengers);
  if (U.TDG == 1) {
    $('#BILLING_TDGOFF').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BILLING_TDGOFF').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', false).checkboxradio('refresh');
  }
  if (U.FuelProvided == 1) $('#BILLING_FUEL').prop('checked', true).checkboxradio('refresh');
  else $('#BILLING_FUEL').prop('checked', false).checkboxradio('refresh');
  if (U.Job.BillBy == 'HourPR') $('#BILLING_HOURPR_HLDR').show();
  else $('#BILLING_HOURPR_HLDR').hide();
  if (U.BillByVAL == undefined) $('#BILLING_HOURPR').val(0);
  else $('#BILLING_HOURPR').val(U.BillByVAL);

  $('#BILLING_FPdetails').val(br2nl(U.Details));
  if (HOBBS == 1) {
    //Client has hobbs meter tracking.  get last times and update
    populateBillingHobbs(U);
  }
  BILLING_checkTechDispatch();
}

function BILLING_MarkFlightNonReportable(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to mark this flight as non-reportable?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          var PK = BILLING_FLIGHTREPORTS[0].PilotItins[index].PrimaryKey;
          //console.log(PK);
          $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=MarkFlightReport&PrimaryKey=' + PK, function (json_data) {
            if (json_data.length > 0) {
              if (json_data[0].ServerResponse != undefined) {
                //status could be offline/Not Logged In/Uncaught Exception
                if (json_data[0].ServerResponse == 'Offline') {
                  alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception')
                  ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
              }
            } else {
              alert(iTrans('Failed to mark flight report'));
            }
          }).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
          //File bug report
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function BILLING_MarkFlightNonReportableAdmin(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to mark this flight as non-reportable?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          var PK = BILLING_FLIGHTREPORTS[0].OtherItins[index].PrimaryKey;
          //console.log(PK);
          $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=MarkFlightReport&PrimaryKey=' + PK, function (json_data) {
            if (json_data.length > 0) {
              if (json_data[0].ServerResponse != undefined) {
                //status could be offline/Not Logged In/Uncaught Exception
                if (json_data[0].ServerResponse == 'Offline') {
                  alert('<h1>' + iTrans('Offline Cannot Update Flight Leg') + '</h1>'); //retrieve old data from database
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception')
                  ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
                if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                if (json_data[0].ServerResponse == 'Success') BILLING_NewFlightReport(false, true);
              }
            } else {
              alert(iTrans('Failed to mark flight report'));
            }
          }).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
          //File bug report
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function BILLING_Lookup_FR_PK(PK, admin = false) {
  if (admin) {
    var A = BILLING_FLIGHTREPORTS[0].AdminList;
    for (var i in A) {
      var I = A[i];
      if (I.PrimaryKey == PK) return { index: i, autoNew: I.By_PK == 0 ? true : false, completed: I.Completed };
    }
  } else {
    var U = BILLING_FLIGHTREPORTS[0].Uncompleted;
    for (var i in U) {
      var I = U[i];
      if (I.PrimaryKey == PK) return { index: i, autoNew: I.By_PK == 0 ? true : false, completed: I.Completed };
    }
  }
  return false;
}

function BILLING_CreateFlightReportFromQuote(Quote) {
  FLIGHT_REPORT_WAS_SIGNED = 0;
  FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;
  signatureDATA = {
    data: '',
    text: '',
  };
  //Auto fill and lock Most Itinerary information if Already contained
  //Create options for custom fields to be captured by clients
  CURRENT_FLIGHT_REPORT = {
    PrimaryKey: 'None',
    Completed: 0,
    FC_Uplifts: {},
  };
  $('#BILLING_FPimaryKey').val('None');

  if (GROUP_DATA.FuelCache == 1) {
    CURRENT_FLIGHT_REPORT.FC_Uplifts = null;
  }
  BILLING_FLIGHTLEGS = []; //Epmpty flighlegs new flight report
  BILLING_FLIGHTREPORT_CREW = [];

  CURRENT_FLIGHT_REPORT.FieldGear = [];
  var Job = BILLING_GetJobData(Quote.ClientPK + '|' + Quote.JobPK, true);
  CURRENT_FLIGHT_REPORT.AlertList = Job.AlertList;
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
  });

  $('#BILLING_FPjob')
    .val(Quote.ClientPK + '|' + Quote.JobPK)
    .selectmenu('refresh', true); //select job dropdown select
  if (Quote.JobPK == 0) {
    $('#BILLING_FPjob-button').addClass('redbg');
  } else {
    $('#BILLING_FPjob-button').removeClass('redbg');
  }

  BILLING_checkAcIdent_and_select(Quote.reportAC.AC_Ident); //Also will trigger pax manifesting

  BILLING_FLIGHTLEGS = [];

  CURRENT_FLIGHT_REPORT.Job = Job;

  BILLING_SelectFR_Crew(chatname, '');
  $('#BILLING_FPpax').val('');

  $('#BILLING_TDGOFF').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_TDGON').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_FUEL').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_FPdetails').val('');
  $('#BILLING_HOURPR').val(0);

  $('#BILLING_IPimaryKey').val(0);
  $('#BILLING_FPimaryKey').val('None');
  BILLING_FLIGHTREPORT_CREW = [];
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();

  $('#BILLING_FPdate').val(Quote.flightDate);

  $('#BILLING_FP_EntryTimezone').val(Quote.reportTimezone).selectmenu('refresh');
  CURRENT_FLIGHT_REPORT.EntryTimezone = Quote.reportTimezone;
  $('#BILLING_FP_StartTimezone').val(Quote.StartTimezone).selectmenu('refresh');
  $('#BILLING_FP_EndTimezone').val(Quote.EndTimezone).selectmenu('refresh');
  var Legs = Quote.CommonLegs;
  if (Quote.reportAC.UniqueLegs == 1) {
    CURRENT_FLIGHT_REPORT.Pax_Manifest = Quote.reportAC.Pax_Manifest;
    Legs = Quote.reportAC.Legs;
  } else {
    CURRENT_FLIGHT_REPORT.Pax_Manifest = Quote.Pax_Manifest;
  }
  var daysToAdd = Math.round(
    (new Date(Quote.flightDate).getTime() - new Date(Legs[0].DepartDate).getTime()) / (1000 * 60 * 60 * 24)
  );
  Quote.daysToAdd = daysToAdd;

  $('#BILLING_FPpax').val(0);
  $('#BILLING_FPdetails').val('');
  for (var i in Legs) {
    var L = Legs[i];
    BILLING_QuoteLegTZ_to_FR_ReportTZ(L, daysToAdd);
    BILLING_FLIGHTLEGS.push({
      PrimaryKey: 'None',
      Date: L.DepartDT.datePart,
      Pilot_PK: CURRENT_FLIGHT_REPORT.Pilot_PK,
      SIC_PK: CURRENT_FLIGHT_REPORT.SIC_PK,
      flightCatID: L.flightCatID,
      Time_Start: L.DepartDT.timePart,
      Time_Up: L.DepartDT.taxiTimePart,
      Time_Down: L.ArrivalDT.taxiTimePart,
      Time_Stop: L.ArrivalDT.timePart,
      Details_Up: L.FromICAO_Name,
      Details_Down: L.ToICAO_Name,
      noShutdown: 0,
      Description: '',
      Cfields: undefined,
      PaxNum: L.PaxNum,
      cargoWeight: L.Weight,
      Billable: 1,
      BillByVAL: 0,
    });
  }
  CURRENT_FLIGHT_REPORT.Legs = BILLING_FLIGHTLEGS;
  BILLING_paxManifest($('#BILLING_FPac').val(), -1);
  console.log('CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT);
  console.log('Qutoe', Quote);
}

function BILLING_QuoteLegTZ_to_FR_ReportTZ(L, daysToAdd) {
  var dt = BILLING_ConvertQuoteLegTime(L.DepartDate, L.DepartTime, L.Timezone, L.TaxiTimeOrigin, daysToAdd);
  L.DepartDT = dt;
  var at = BILLING_ConvertQuoteLegTime(L.ArrivalDate, L.ArrivalTime, L.Timezone, L.TaxiTimeDest * -1, daysToAdd);
  L.ArrivalDT = at;
}

function BILLING_ConvertQuoteLegTime(datePart, timePart, Timezone, TaxiTime, daysToAdd) {
  let dt = new Date(datePart + 'T' + timePart + ':00Z');
  let min = getTimezoneOffset(new Date(), Timezone);
  dt.setUTCMinutes(dt.getUTCMinutes() - min);
  dt = new Date(dt.toISOString().substr(0, 19) + 'Z');

  var entryTZ = $('#BILLING_FP_EntryTimezone').val();
  let entryMin = getTimezoneOffset(new Date(), entryTZ);
  dt.setUTCMinutes(dt.getUTCMinutes() + entryMin);
  var timePart = dt.toISOString().substr(11, 5).replace(':', '');
  dt.setUTCMinutes(dt.getUTCMinutes() + TaxiTime);
  dt.setUTCDate(dt.getUTCDate() + daysToAdd);
  return {
    datePart: dt.toISOString().substr(0, 10),
    timePart: timePart,
    taxiTimePart: dt.toISOString().substr(11, 5).replace(':', ''),
  };
}

function BILLING_CreateFlightReportPilot(index) {
  signatureDATA = {
    data: '',
    text: '',
  };
  //Auto fill and lock Most Itinerary information if Already contained
  //Create options for custom fields to be captured by clients
  CURRENT_FLIGHT_REPORT = {
    Completed: 0,
    FC_Uplifts: {},
  };

  var P = BILLING_FLIGHTREPORTS[0].PilotItins[index];
  if (HOBBS == 1) {
    CURRENT_FLIGHT_REPORT.HOBBS = P.HOBBS;
  }
  if (GROUP_DATA.FuelCache == 1) {
    CURRENT_FLIGHT_REPORT.FC_Uplifts = P.FC_Uplifts;
  }
  CURRENT_FLIGHT_REPORT.WB = P.WB;
  BILLING_FLIGHTLEGS = []; //Epmpty flighlegs new flight report
  BILLING_FLIGHTREPORT_CREW = [];

  $('#BILLING_IPimaryKey').val(P.PrimaryKey);
  if (P.flight_report_id == null) {
    $('#BILLING_FPimaryKey').val('None');
    var FRIndex = false;
  } else {
    var FRIndex = BILLING_Lookup_FR_PK(P.flight_report_id, false);
    $('#BILLING_FPimaryKey').val(P.flight_report_id);
  }
  CURRENT_FLIGHT_REPORT.FieldGear = P.FieldGear;
  var Job = BILLING_GetJobData(P.JobClientID + '|' + P.JobID);
  CURRENT_FLIGHT_REPORT.AlertList = Job.AlertList;
  if (P.status == 'CLOSED') {
    if (FRIndex === false) {
      BILLING_popFlightReport(P);
    } else {
      if (FRIndex.autoNew) {
        BILLING_CreateFlightReportUncompleted(FRIndex.index, false);
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt:
            iTrans('You are creating a Flight Report from a CLOSED itinerary that already has a Flight Report!') +
            '<br /><br /><b>' +
            iTrans('Would you like to edit the existing Flight Report or Create a new one?') +
            '</b>',
          buttons: {
            [iTrans('New Flight Report')]: {
              click: function () {
                $('#BILLING_FPimaryKey').val('None');
                BILLING_popFlightReport(P, true);
              },
            },
            [iTrans('Edit Existing')]: {
              click: function () {
                BILLING_CreateFlightReportUncompleted(FRIndex.index, false);
              },
            },
            [iTrans('Cancel')]: {
              click: function () {},
            },
          },
        });
      }
    }
  } else {
    if (P.flight_report_id == null) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt:
          iTrans('You are creating a Flight Report from an OPEN itinerary.') +
          '<br /><br /><b>' +
          iTrans('This allows you to create multiple flight reports!') +
          '</b><br /><br />' +
          iTrans(
            'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
          ),
        buttons: {
          [iTrans('Continue')]: {
            click: function () {
              BILLING_popFlightReport(P, true);
            },
          },
          [iTrans('Cancel')]: {
            click: function () {},
          },
        },
      });
    } else {
      //Itinerary has been reported previously - Create a new FR or update existing
      if (FRIndex !== false) {
        if (FRIndex.autoNew) {
          BILLING_CreateFlightReportUncompleted(FRIndex.index, false);
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('WARNING'),
            headerClose: false,
            buttonPrompt:
              iTrans('You are creating a Flight Report from an OPEN itinerary that already has a Flight Report!') +
              '<br /><br /><b>' +
              iTrans('Would you like to edit the existing Flight Report or Create a new one?') +
              '</b><br /><br />' +
              iTrans(
                'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
              ),
            buttons: {
              [iTrans('New Flight Report')]: {
                click: function () {
                  $('#BILLING_FPimaryKey').val('None');
                  BILLING_popFlightReport(P, true);
                },
              },
              [iTrans('Edit Existing')]: {
                click: function () {
                  BILLING_CreateFlightReportUncompleted(FRIndex.index, false);
                },
              },
              [iTrans('Cancel')]: {
                click: function () {},
              },
            },
          });
        }
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt:
            iTrans('You are creating a Flight Report from an OPEN itinerary that already has a Flight Report!') +
            '<br /><br /><b>' +
            iTrans(
              'The existing flight report is not currently in the available lists to edit. You will need to expand your search criteria to manually find and edit the report'
            ) +
            '</b><br /><br />' +
            iTrans(
              'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
            ),
          buttons: {
            [iTrans('New Flight Report')]: {
              click: function () {
                $('#BILLING_FPimaryKey').val('None');
                BILLING_popFlightReport(P, true);
              },
            },
            [iTrans('Cancel')]: {
              click: function () {},
            },
          },
        });
      }
    }
  }
}

function BILLING_CreateFlightReportOther(index) {
  //Auto fill and lock Most Itinerary information if Already contained
  CURRENT_FLIGHT_REPORT = {
    Completed: 0,
    FC_Uplifts: {},
  };
  //Create options for custom fields to be captured by clients
  var O = BILLING_FLIGHTREPORTS[0].OtherItins[index];
  //Show warning that user is reporting on somebody elses itinerary

  BILLING_FLIGHTLEGS = []; //Epmpty flighlegs new flight report
  BILLING_FLIGHTREPORT_CREW = [];
  if (GROUP_DATA.FuelCache == 1) {
    CURRENT_FLIGHT_REPORT.FC_Uplifts = O.FC_Uplifts;
  }
  if (HOBBS == 1) {
    CURRENT_FLIGHT_REPORT.HOBBS = O.HOBBS;
  }
  CURRENT_FLIGHT_REPORT.WB = O.WB;
  $('#BILLING_IPimaryKey').val(O.PrimaryKey);
  if (O.flight_report_id == null) {
    $('#BILLING_FPimaryKey').val('None');
    var FRIndex = false;
  } else {
    var FRIndex = BILLING_Lookup_FR_PK(O.flight_report_id, true);
    $('#BILLING_FPimaryKey').val(O.flight_report_id);
  }
  var Job = BILLING_GetJobData(O.JobClientID + '|' + O.JobID);
  CURRENT_FLIGHT_REPORT.AlertList = Job.AlertList;
  if (O.status == 'CLOSED') {
    if (FRIndex === false) {
      BILLING_popFlightReport(O);
    } else {
      if (FRIndex.autoNew) {
        BILLING_CreateFlightReportUncompleted(FRIndex.index, true);
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt:
            iTrans('You are creating a Flight Report from a CLOSED itinerary that already has a Flight Report!') +
            '<br /><br /><b>' +
            iTrans('Would you like to edit the existing Flight Report or Create a new one?') +
            '</b>',
          buttons: {
            [iTrans('New Flight Report')]: {
              click: function () {
                $('#BILLING_FPimaryKey').val('None');
                BILLING_popFlightReport(O, true);
              },
            },
            [iTrans('Edit Existing')]: {
              click: function () {
                BILLING_CreateFlightReportUncompleted(FRIndex.index, true);
              },
            },
            [iTrans('Cancel')]: {
              click: function () {},
            },
          },
        });
      }
    }
  } else {
    if (O.flight_report_id == null) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt:
          iTrans('You are creating a Flight Report from an OPEN itinerary.') +
          '<br /><br /><b>' +
          iTrans('This allows you to create multiple flight reports!') +
          '</b><br /><br />' +
          iTrans(
            'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
          ),
        buttons: {
          [iTrans('Continue')]: {
            click: function () {
              BILLING_popFlightReport(O, false);
            },
          },
          [iTrans('Cancel')]: {
            click: function () {},
          },
        },
      });
    } else {
      //Itinerary has been reported previously - Create a new FR or update existing
      if (FRIndex !== false) {
        if (FRIndex.autoNew) {
          BILLING_CreateFlightReportUncompleted(FRIndex.index, true);
        } else {
          if (FRIndex.completed == 0) {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('WARNING'),
              headerClose: false,
              buttonPrompt:
                iTrans('You are creating a Flight Report from an OPEN itinerary that already has a Flight Report!') +
                '<br /><br /><b>' +
                iTrans('Would you like to edit the existing Flight Report or Create a new one?') +
                '</b><br /><br />' +
                iTrans(
                  'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
                ),
              buttons: {
                [iTrans('New Flight Report')]: {
                  click: function () {
                    $('#BILLING_FPimaryKey').val('None');
                    BILLING_popFlightReport(O, true);
                  },
                },
                [iTrans('Edit Existing')]: {
                  click: function () {
                    BILLING_CreateFlightReportUncompleted(FRIndex.index, true);
                  },
                },
                [iTrans('Cancel')]: {
                  click: function () {},
                },
              },
            });
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('WARNING'),
              headerClose: false,
              buttonPrompt:
                iTrans(
                  'You are attempting to create a Flight Report from an OPEN itinerary that has an existing Flight Report marked as completed!'
                ) +
                '<br /><br /><b>' +
                iTrans('Would you like to view the existing Flight Report or Create a new one?') +
                '</b><br /><br />' +
                iTrans(
                  'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
                ),
              buttons: {
                [iTrans('New Flight Report')]: {
                  click: function () {
                    $('#BILLING_FPimaryKey').val('None');
                    BILLING_popFlightReport(O, true);
                  },
                },
                [iTrans('View Existing')]: {
                  click: function () {
                    BILLING_viewFlightReportUncompleted(FRIndex.index, true);
                  },
                },
                [iTrans('Cancel')]: {
                  click: function () {},
                },
              },
            });
          }
        }
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt:
            iTrans('You are creating a Flight Report from an OPEN itinerary that already has a Flight Report!') +
            '<br /><br /><b>' +
            iTrans(
              'The existing flight report is not currently in the available lists to edit. You will need to expand your search criteria to manually find and edit the report'
            ) +
            '</b><br /><br />' +
            iTrans(
              'Once Completed this Itinerary will remain reportable until it has been CLOSED and marked as reported'
            ),
          buttons: {
            [iTrans('New Flight Report')]: {
              click: function () {
                $('#BILLING_FPimaryKey').val('None');
                BILLING_popFlightReport(O, true);
              },
            },
            [iTrans('Cancel')]: {
              click: function () {},
            },
          },
        });
      }
    }
  }
}

function nomadCalc_HTML(FR) {
  var Legs = FR.Legs;
  var html =
    '<table><thead><tr><th>' +
    iTrans('Leg#') +
    '</th><th>' +
    iTrans('Max NG') +
    '</th><th>' +
    iTrans('Landings') +
    '</th><th>' +
    iTrans('Lifts') +
    '</th><th>' +
    iTrans('Starts') +
    '</th><th>' +
    iTrans('NP Cycles') +
    '</th><th>' +
    iTrans('NG Cycles') +
    '</th><th>' +
    iTrans('TQ Cycles') +
    '</th></tr></thead><tbody>';
  var totalLandings = 0;
  var totalLifts = 0;
  var totalStarts = 0;
  var totalNP = 0;
  var totalNG = 0;
  var totalTQ = 0;
  for (var l in Legs) {
    var L = Legs[l];
    var values = BILLING_Script_getNomadValues(L.Cfields); //custom billing script that maps cfields to proper fields
    html +=
      '<tr><td>' +
      (parseInt(l) + 1) +
      '</td><td>' +
      values.maxng +
      '</td><td>' +
      values.landings +
      '</td><td>' +
      values.lifts +
      '</td><td>' +
      values.starts +
      '</td><td style="border-left:thin solid #afafaf;">' +
      values.NP_Cycles +
      '</td><td>' +
      values.NG_Cycles +
      '</td><td>' +
      values.TQ_Cycles +
      '</td></tr>';
    totalLandings += values.landings;
    totalLifts += values.lifts;
    totalStarts += values.starts;
    totalNP += values.NP_Cycles;
    totalNG += values.NG_Cycles;
    totalTQ += values.TQ_Cycles;
  }
  totalLandings = Math.round(totalLandings);
  totalLifts = Math.round(totalLifts);
  totalStarts = Math.round(totalStarts);
  totalNP = Math.round(totalNP * 100) / 100;
  totalNG = Math.round(totalNG * 100) / 100;
  totalTQ = Math.round(totalTQ * 100) / 100;
  html +=
    '<tr style="border-top:thin solid #afafaf;"><td><b>' +
    iTrans('Totals') +
    '</b></td><td></td><td><b>' +
    totalLandings +
    '</td><td><b>' +
    totalLifts +
    '</b></td><td><b>' +
    totalStarts +
    '</b></td><td style="border-left:thin solid #afafaf;"><b>' +
    totalNP +
    '</b></td><td><b>' +
    totalNG +
    '</b></td><td><b>' +
    totalTQ +
    '</b></td></tr>';
  html += '</tbody></table>';

  return html;
}

function updateNomad(maxNG, landings, lifts, starts) {
  //todo update calculations
  let maxNG_Table = [
    { maxPercent: 100, maxRPM: 33200, K1: 1 },
    { maxPercent: 99, maxRPM: 32868, K1: 0.9 },
    { maxPercent: 98, maxRPM: 32536, K1: 0.8 },
    { maxPercent: 97, maxRPM: 32204, K1: 0.7 },
    { maxPercent: 96, maxRPM: 31872, K1: 0.65 },
    { maxPercent: 95, maxRPM: 31540, K1: 0.6 },
    { maxPercent: 94, maxRPM: 31208, K1: 0.55 },
    { maxPercent: 93, maxRPM: 30876, K1: 0.5 },
  ];
  landingsK2 = 0.15;
  liftsK2 = 0.05;

  let NG_Cycles = 0;
  let NP_Cycles = starts;
  let TQ_Cycles = 0;

  let NG_Mult = 0.5;
  for (let i in maxNG_Table) {
    let I = maxNG_Table[i];
    if (maxNG > I.maxRPM) {
      if (i > 0) {
        NG_Mult = maxNG_Table[parseInt(i) - 1].K1;
      } else {
        NG_Mult = 1;
      }
      break;
    }
  }
  let liftNG_Cycles = lifts * liftsK2;
  let landingNG_Cycles = (landings - 1) * landingsK2;

  NG_Cycles = Math.round(((landings > 1 ? landingNG_Cycles : 0) + NG_Mult + liftNG_Cycles) * 100) / 100;
  TQ_Cycles = Math.round((landings + lifts * 2) * 100) / 100;

  return { NP_Cycles: NP_Cycles, NG_Cycles: NG_Cycles, TQ_Cycles: TQ_Cycles };
}

function validateNomad(number) {
  if (isNaN(number)) return 0;
  return parseInt(number);
}

function BILLING_popAircraftTimes(back) {
  var goback = back || false;
  $.mobile.changePage($('#BILLING_ACTIMES'), {
    changeHash: false,
    reverse: goback,
  });
  $('#Billing_CompleteAircraftTimes').prop('disabled', true);
  $('#Billing_CompleteAircraftTimes').off('click');
  $('#Billing_CompleteAircraftTimes').click(function () {
    signatureDATA.data = '';
    signatureDATA.text = '';
    Billing_CompleteFlightReport_display();
  });
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#BILLING_ACTIMES_Content').html('<h2>' + iTrans('Populating') + '</h2>');
  //console.log(CURRENT_FLIGHT_REPORT);
  var Landings = CURRENT_FLIGHT_REPORT.Legs.length;
  var TotalTime = CURRENT_FLIGHT_REPORT.HoursFlown;
  var Starts = CURRENT_FLIGHT_REPORT.Legs.length > 0 ? 1 : 0;
  for (var i in CURRENT_FLIGHT_REPORT.Legs) {
    var I = CURRENT_FLIGHT_REPORT.Legs[i];
    if (I.noShutdown == 0) {
      Starts++;
    }
  }
  let nomadHTML = '';
  if (
    (GROUP_DATA.group == 'nomad' &&
      (CURRENT_FLIGHT_REPORT.AcType == 'AS332C1' ||
        CURRENT_FLIGHT_REPORT.AcType == 'AS332L' ||
        CURRENT_FLIGHT_REPORT.AcType == 'AS332L1' ||
        CURRENT_FLIGHT_REPORT.AcType == 'AS332L2')) ||
    GPS_DEBUG
  ) {
    //todo remove true
    nomadHTML = nomadCalc_HTML(CURRENT_FLIGHT_REPORT);
  }

  $.getJSON(
    BASEURL +
      'FD-AJAX.php?action=getIdent&Ident=' +
      encodeURIComponent(CURRENT_FLIGHT_REPORT.Aircraft) +
      '&FR_PK=' +
      CURRENT_FLIGHT_REPORT.PrimaryKey,
    function (json_data) {
      $('#Billing_CompleteAircraftTimes').prop('disabled', false);
      if (json_data.ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="BILLING_popAircraftTimes();" data-icon="refresh">' +
            iTrans('Try Again') +
            '</button>';

          $('#BILLING_ACTIMES_Content').html(
            nomadHTML +
              '<h2>' +
              iTrans('No Aircraft Data Retrieved!') +
              '</h2><b>' +
              iTrans(
                'There was either an error contacting the Flightdocs Server OR the requested aircraft does not exist in the maintenance system.'
              ) +
              '</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
              ) +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        if (json_data.Existing) {
          var data = json_data.UpdateData.data;
          var signature_text = json_data.UpdateData.signature_text;
          var signature_data = json_data.UpdateData.signature_data;
          displayUpdatedAircraftTimes(CURRENT_FLIGHT_REPORT.Aircraft, data, signature_text, signature_data);
          return;
        }
        AC_TECH_DISPATCH.RAW = json_data;
        AC_TECH_DISPATCH.UPDATE = JSON.parse(JSON.stringify(json_data.Aircraft)); //make copy of object for update
        //console.log(json_data);
        var AC = json_data.Aircraft;
        var html = nomadHTML + '<h3>' + CURRENT_FLIGHT_REPORT.Aircraft + ' - ' + iTrans('Technical Record') + '</h3>';

        html +=
          "<table class='limit-table last-td-fill'><thead><tr><th>" +
          iTrans('Flight Report Summary') +
          '</th><th></th><th></th></tr></thead><tbody>';
        html += '<tr><td><b>' + iTrans('Legs') + ': </b></td><td>' + Landings + '</td><td></td></tr>';
        html += '<tr><td><b>' + iTrans('Total Flight Time') + ': </b></td><td>' + TotalTime + '</td><td></td></tr>';
        if (HOBBS == 1) {
          var P_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Primary;
          var S_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Secondary;
          CURRENT_FLIGHT_REPORT.P_Total = round10(P_Hobbs.Reported.Total - P_Hobbs.PrevReading.Total);
          CURRENT_FLIGHT_REPORT.S_Total = round10(S_Hobbs.Reported.Total - S_Hobbs.PrevReading.Total);
          if (CURRENT_FLIGHT_REPORT.P_Total > 0) TotalTime = CURRENT_FLIGHT_REPORT.P_Total;
          if (CURRENT_FLIGHT_REPORT.S_Total > TotalTime) TotalTime = CURRENT_FLIGHT_REPORT.S_Total;
          html +=
            '<tr><td><b>' +
            iTrans('Flight HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.P_Total +
            '</td><td></td></tr>';
          html +=
            '<tr><td><b>' +
            iTrans('Maint HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.S_Total +
            '</td><td></td></tr>';
        }
        html += '</tbody></table>';
        html +=
          '<h4>' + iTrans('Technical Record Last Updated') + ': ' + new Date(AC.LastUpdated).toDateString() + '</h4>';
        html +=
          '<table class="limit-table wrap"><thead><tr><th>' +
          iTrans('Item') +
          '</th><th>' +
          iTrans('Status') +
          '</th><th>' +
          iTrans('Add Value') +
          '</th><th>' +
          iTrans('Total') +
          '</th></tr></thead><tbody>';
        html +=
          '<tr><td>' +
          iTrans('Landings') +
          '</td><td>' +
          AC.Landings +
          '</td><td><input data-role="none" style="width:50px;" type="number" id="UTD_Landings_Input" onKeyup="UTD_AddValue(this)" onChange="UTD_AddValue(this)" onfocus="this.select();" value=' +
          Landings +
          ' /></td><td><div id="UTD_Landings">' +
          (AC.Landings + Landings) +
          '</div></td></tr>';
        html +=
          '<tr><td>RIN</td><td>' +
          AC.RIN +
          '</td><td><input data-role="none" style="width:50px;" type="number" id="UTD_RIN_Input" onKeyup="UTD_AddValue(this)" onChange="UTD_AddValue(this)" onfocus="this.select();" value=0 /></td><td><div id="UTD_RIN">' +
          AC.RIN +
          '</div></td></tr>';
        html +=
          '<tr><td>' +
          iTrans('Total Time') +
          '</td><td>' +
          AC.TotalTime +
          '</td><td><input data-role="none" style="width:50px;" type="number" id="UTD_Time_Input" onKeyup="UTD_AddValue(this)" onChange="UTD_AddValue(this)" onfocus="this.select();" value=' +
          TotalTime +
          ' /></td><td><div id="UTD_Time">' +
          (AC.TotalTime + TotalTime) +
          '</div></td></tr>';
        html += '</tbody></table>';

        html += '<h3>' + iTrans('Equipment times') + '</h3>';
        html +=
          '<table class="limit-table wrap"><thead><tr><th>' +
          iTrans('Type') +
          '</th><th>' +
          iTrans('Cycles') +
          '</th><th>' +
          iTrans('Hours') +
          '</th></tr></thead><tbody>';
        for (var t in AC.AircraftEquipmentTimes) {
          var T = AC.AircraftEquipmentTimes[t];

          var Ucycles =
            '<br /><input data-role="none" style="width:50px;" type="number" id="UTDE_Cycles' +
            t +
            '" onKeyup="UTDE_AddValue(this,' +
            t +
            ',\'Cycles\')" onChange="UTDE_AddValue(this,' +
            t +
            ',\'Cycles\')" onfocus="this.select();" value=0 /><div id="UTDE_Cycles' +
            t +
            '_Total">' +
            T.Cycles +
            '</div>';

          var UHours =
            '<br /><input data-role="none" style="width:50px;" type="number" id="UTDE_Hours' +
            t +
            '" onKeyup="UTDE_AddValue(this,' +
            t +
            ',\'Hours\')" onChange="UTDE_AddValue(this,' +
            t +
            ',\'Hours\')" onfocus="this.select();" value=0 /><div id="UTDE_Hours' +
            t +
            '_Total">' +
            T.Hours +
            '</div>';

          /*
                var UC1C = '<br /><input data-role="none" style="width:50px;" type="number" id="UTDE_C1C' + t + '" onKeyup="UTDE_AddValue(this,' + t + ',\'C1C\')" onChange="UTDE_AddValue(this,' + t + ',\'C1C\')" onfocus="this.select();" value=0 /><div id="UTDE_C1C' + t + '_Total">' + T.C1C + '</div>';

                var UC2C = '<br /><input data-role="none" style="width:50px;" type="number" id="UTDE_C2C' + t + '" onKeyup="UTDE_AddValue(this,' + t + ',\'C2C\')" onChange="UTDE_AddValue(this,' + t + ',\'C2C\')" onfocus="this.select();" value=0 /><div id="UTDE_C2C' + t + '_Total">' + T.C2C + '</div>';
				*/

          html +=
            '<tr><td>' + T.Type + '</td><td>' + T.Cycles + Ucycles + '</td><td>' + T.Hours + UHours + '</td></tr>';
        }
        html += '</tbody></table>';
        html +=
          '<div id="PilotSignatureHLDR"><button onClick="ACTIMES_OPEN_SIGNATURE()" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button></div>';
        html +=
          '<div id="UpdateTimesHLDR" style="display:none;"><button onClick="UpdateAircraftTimes(\'' +
          CURRENT_FLIGHT_REPORT.Aircraft +
          '\')" data-icon="check" class="greenbg" >' +
          iTrans('Update Aircraft Times') +
          '</button></div>';
        $('#BILLING_ACTIMES_Content').html(html);
        $('#BILLING_ACTIMES_Content').enhanceWithin();
        UTD_AddValue({ id: 'UTD_Landings_Input', value: Landings });
        UTD_AddValue({ id: 'UTD_RIN_Input', value: 0 });
        UTD_AddValue({ id: 'UTD_Time_Input', value: TotalTime });
        if (
          (GROUP_DATA.group == 'nomad' &&
            (CURRENT_FLIGHT_REPORT.AcType == 'AS332C1' ||
              CURRENT_FLIGHT_REPORT.AcType == 'AS332L' ||
              CURRENT_FLIGHT_REPORT.AcType == 'AS332L1' ||
              CURRENT_FLIGHT_REPORT.AcType == 'AS332L2')) ||
          GPS_DEBUG
        ) {
          updateNomad();
        }
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="BILLING_popAircraftTimes();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#BILLING_ACTIMES_Content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved!') +
        '</h2><b>' +
        iTrans(
          'There was either an error contacting the Flightdocs Server OR the requested aircraft does not exist in the maintenance system.'
        ) +
        '</b><br />' +
        iTrans(
          'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
        ) +
        refreshBTN +
        '<br /><br />' +
        iTrans('Response') +
        ': ' +
        Note
    );
    $('#BILLING_ACTIMES_Content').enhanceWithin();
    $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  });
}

function BILLING_popAircraftTimes_WinAir(back) {
  var goback = back || false;
  $.mobile.changePage($('#BILLING_ACTIMES'), {
    changeHash: false,
    reverse: goback,
  });
  $('#Billing_CompleteAircraftTimes').prop('disabled', true);
  $('#Billing_CompleteAircraftTimes').off('click');
  $('#Billing_CompleteAircraftTimes').click(function () {
    if (signatureDATA.data != '') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans(
          'You have not updated the aircraft technical records.  Please click [Update Aircraft Times] to update now.  If you continue without updating the technical records you have input will be lost.'
        ),
        buttons: {
          [iTrans('Continue without updating')]: {
            click: function () {
              signatureDATA.data = '';
              signatureDATA.text = '';
              Billing_CompleteFlightReport_display();
            },
          },
          [iTrans('Stay on page')]: {
            click: function () {},
          },
          [iTrans('Update Aircraft Times')]: {
            click: function () {
              //do nothing
              UpdateAircraftTimes_WinAir(CURRENT_FLIGHT_REPORT.Aircraft);
            },
          },
        },
      });
    } else {
      signatureDATA.data = '';
      signatureDATA.text = '';
      Billing_CompleteFlightReport_display();
    }
  });
  signatureDATA.data = '';
  signatureDATA.text = '';
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#BILLING_ACTIMES_Content').html('<h2>' + iTrans('Populating') + '</h2>');
  //console.log(CURRENT_FLIGHT_REPORT);
  $.getJSON(
    BASEURL +
      'WinAir-AJAX.php?action=getIdent&Ident=' +
      encodeURIComponent(CURRENT_FLIGHT_REPORT.Aircraft) +
      '&FR_PK=' +
      CURRENT_FLIGHT_REPORT.PrimaryKey,
    function (json_data) {
      $('#Billing_CompleteAircraftTimes').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="BILLING_popAircraftTimes();" data-icon="refresh">' +
            iTrans('Try Again') +
            '</button>';
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retrieved!') +
              '</h2><b>' +
              iTrans(
                'There was either an error contacting the WinAir Server OR the requested aircraft does not exist in the maintenance system.'
              ) +
              '</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
              ) +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAircraft') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retrieved!') +
              '</h2><b>The requested aircraft does not exist in the maintenance system.</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'AircraftDisabled') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('Aircraft Disabled') +
              '</h2><b>' +
              iTrans('The requested aircraft has been disabled in the maintenance system.') +
              '</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'APIaccessdenied') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('API Access Denied') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retrieved from the maintenance system because the API key provided does not have the required permissions.'
              ) +
              '</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAPIkey') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('Invalid API key') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retrieved from the maintenance system because the API key provided is not valid.'
              ) +
              '</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        if (json_data.Existing) {
          var data = json_data.ExistingData;
          data.data = JSON.parse(data.data);
          var signature_text = data.signature_text;
          var signature_data = data.signature_data;
          displayUpdatedAircraftTimes_WinAir(CURRENT_FLIGHT_REPORT.Aircraft, data.data, signature_text, signature_data);
          return;
        }
        AC_TECH_DISPATCH.RAW = json_data;
        AC_TECH_DISPATCH.UPDATE = JSON.parse(JSON.stringify(json_data)); //make copy of object for update
        var html = '';
        var Landings = CURRENT_FLIGHT_REPORT.Legs.length;
        var TotalTime = CURRENT_FLIGHT_REPORT.HoursFlown;

        html += '<h2>' + CURRENT_FLIGHT_REPORT.Aircraft + ' - ' + iTrans('Technical Records') + ' - ';
        if (json_data.LastEntry == 'Unknown') html += iTrans('Updated') + ': <b>' + iTrans('Never') + '</b></h2>';
        else html += iTrans('Updated') + ': ' + new Date(json_data.LastEntry).toDateString() + '</h2>';

        var Recent = json_data.MostRecentInspections;
        if (Recent != null) {
          if (Recent.length > 0) {
            html += '<h3>' + iTrans('Recent Inspections') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>' +
              iTrans('Completed') +
              '</th></tr></thead><tbody>';
            for (var i in Recent) {
              var R = Recent[i];
              html += '<tr><td>' + R.task + '</td><td>' + R.description + '</td><td>' + R.dateCompleted + '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Recent Inspections') + '</h3>';
          }
        }
        var Next = json_data.NextDueInspections;
        if (Next != null) {
          if (Next.length > 0) {
            html += '<h3>' + iTrans('Next Due Inspections') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>' +
              iTrans('Projected') +
              '</th></tr></thead><tbody>';

            var now = new Date().getTime();
            for (var i in Next) {
              var N = Next[i];
              var pDueDate = new Date(N.projectedDueDate + 'T23:59:59').getTime();
              var Nclass = '';
              if (pDueDate < now) Nclass = 'redbg';
              html +=
                '<tr class="' +
                Nclass +
                '"><td>' +
                N.task +
                '</td><td>' +
                N.description +
                '</td><td>' +
                N.projectedDueDate +
                '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Next Due Inspections') + '</h3>';
          }
        }
        var NextC = json_data.NextDueItems;
        if (NextC != null) {
          if (NextC.length > 0) {
            html += '<h3>' + iTrans('Next Due Maintenance Per Counter') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>' +
              iTrans('Counter') +
              '</th><th>' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>' +
              iTrans('Due At') +
              '</th><th>' +
              iTrans('Remaining') +
              '</th></tr></thead><tbody>';

            for (var i in NextC) {
              var NC = NextC[i];
              var NCclass = '';
              if (parseFloat(NC.remaining) < 0) NCclass = 'redbg';
              html +=
                '<tr class="' +
                NCclass +
                '"><td>' +
                NC.counterName +
                '</td><td>' +
                NC.taskCheck +
                '</td><td>' +
                NC.counterDescription +
                '</td><td>' +
                NC.dueAt +
                '</td><td>' +
                NC.remaining +
                '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Next Due Maintenance Per Counter') + '</h3>';
          }
        }
        var Deferred = json_data.DefferedTasks;
        if (Deferred != null) {
          if (Deferred.length > 0) {
            html += '<h3>' + iTrans('Deferred Tasks') + '</h3>';
            html +=
              '<table class="limit-table wrap"><thead><tr><th>WO-' +
              iTrans('Task') +
              '</th><th>' +
              iTrans('Description') +
              '</th><th>Mel ' +
              iTrans('Reference') +
              '</th><th>Mel ' +
              iTrans('Category') +
              '</th><th>' +
              iTrans('Effective Date') +
              '</th><th>' +
              iTrans('Due Date') +
              '</th><th>' +
              iTrans('Deferral Days') +
              '</th><th>' +
              iTrans('Remaining Days') +
              '</th></tr></thead><tbody>';

            for (var i in Deferred) {
              var D = Deferred[i];
              var Dclass = '';
              if (parseInt(D.remainingDays) < 0) Dclass = 'redbg';
              html +=
                '<tr class="' +
                Dclass +
                '"><td>' +
                D.woTask +
                '</td><td>' +
                D.description +
                '</td><td>' +
                D.melReference +
                '</td><td>' +
                D.melCategory +
                '</td><td>' +
                D.effectiveDate +
                '</td><td>' +
                D.dueDate +
                '</td><td>' +
                D.deferralDays +
                '</td><td>' +
                D.remainingDays +
                '</td></tr>';
            }
            html += '</tbody></table>';
          } else {
            html += '<h3>' + iTrans('No Deferred Tasks') + '</h3>';
          }
        }
        html += '<h3>' + iTrans('Data from Flight Report') + '</h3>';
        html +=
          "<table class='limit-table'><thead><tr><th>" +
          iTrans('Item') +
          '</th><th></th><th>' +
          iTrans('Value') +
          '</th></tr></thead><tbody>';
        html += '<tr><td><b>' + iTrans('Legs') + ': </b></td><td>' + Landings + '</td><td></td></tr>';
        html +=
          '<tr><td><b>' +
          iTrans('Total Flight Time') +
          ': </b></td><td>' +
          CURRENT_FLIGHT_REPORT.HoursFlown +
          '</td><td></td></tr>';
        if (HOBBS == 1) {
          var P_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Primary;
          var S_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Secondary;
          CURRENT_FLIGHT_REPORT.P_Total = round10(P_Hobbs.Reported.Total - P_Hobbs.PrevReading.Total);
          CURRENT_FLIGHT_REPORT.S_Total = round10(S_Hobbs.Reported.Total - S_Hobbs.PrevReading.Total);
          if (CURRENT_FLIGHT_REPORT.P_Total > 0) TotalTime = CURRENT_FLIGHT_REPORT.P_Total;
          if (CURRENT_FLIGHT_REPORT.S_Total > TotalTime) TotalTime = CURRENT_FLIGHT_REPORT.S_Total;
          html +=
            '<tr><td><b>' +
            iTrans('Flight HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.P_Total +
            '</td><td></td></tr>';
          html +=
            '<tr><td><b>' +
            iTrans('Maint HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.S_Total +
            '</td><td></td></tr>';
        }
        html += '</tbody></table>';

        html += '<h3>' + iTrans('Update Equipment times') + '</h3>';
        var IP = json_data.Fields;
        var CT = json_data.CurrentTimes;
        html +=
          '<table class="limit-table first-td-fill"><thead><tr><th>' +
          iTrans('Description') +
          '</th><th>' +
          iTrans('Cur Value') +
          '</th><th>' +
          iTrans('Add Amount') +
          '</th><th>' +
          iTrans('Action') +
          '</th></tr></thead><tbody>';
        var Hours = decimalToHHMM(CURRENT_FLIGHT_REPORT.HoursFlown);
        for (var i in IP) {
          var InputHtml = '';
          var plusBTN =
            '<button class="action-btn" value="' +
            i +
            '" onclick="BILLING_WinAirPlus(this.value)"><img src="./images/Plus-icon.png" height="22px" ></button>';
          var minusBTN =
            '<button class="action-btn" value="' +
            i +
            '" onclick="BILLING_WinAirMinus(this.value)"><img src="./images/Minus-icon.png" height="22px" ></button>';
          var clearBTN =
            '<button class="action-btn" value="' +
            i +
            '" onclick="BILLING_WinAirClear(this.value)"><img src="./images/cleartxt.png" width="22px" ></button>';
          var BTN = '';
          switch (IP[i].inputFormat) {
            case 'HH:MM':
              InputHtml =
                "<input type='text' maxlength='5' id='FR_" +
                IP[i].inputName +
                "' value='" +
                Hours +
                "' name='" +
                i +
                "' onChange='BILLING_SetWinAirData(this);'  style='width:100px;' onclick='this.select();'/>";
              AC_TECH_DISPATCH.UPDATE.Fields[i].value = Hours;
              break;
            case '0':
              InputHtml =
                "<input type='number' step='1' id='FR_" +
                IP[i].inputName +
                "' value='0' name='" +
                i +
                "' onChange='BILLING_SetWinAirData(this);'  style='width:100px;' onclick='this.select();'/>";
              AC_TECH_DISPATCH.UPDATE.Fields[i].value = '0';
              BTN = plusBTN + minusBTN + clearBTN;
              break;
            case '0.00':
              InputHtml =
                "<input type='number' step='0.01' id='FR_" +
                IP[i].inputName +
                "' value='0.00' name='" +
                i +
                "' onChange='BILLING_SetWinAirData(this);'  style='width:100px;' onclick='this.select();'/>";
              AC_TECH_DISPATCH.UPDATE.Fields[i].value = '0.00';
              BTN = clearBTN;
              break;
            default:
              InputHtml =
                "<input type='number' step='0.01' id='FR_" +
                IP[i].inputName +
                "' value='0' name='" +
                i +
                "' onChange='BILLING_SetWinAirData(this);'  style='width:100px;' onclick='this.select();'/>";
              AC_TECH_DISPATCH.UPDATE.Fields[i].value = '0.00';
              BTN = clearBTN;
              break;
          }
          html +=
            '<tr><td>' +
            IP[i].inputDescription +
            ' (' +
            IP[i].inputFormat +
            ')</td><td><b>' +
            CT[i].inputCurrentValue +
            '</b></td><td>' +
            InputHtml +
            '</td><td><div class="vertical-center-container">' +
            BTN +
            '</div></tr>';
        }
        html += '</tbody></table>';

        if (json_data.Existing == false) {
          //Display Journey Log Information and entry options for Current
          var data = json_data.ExistingData;
          html += '<h3>' + iTrans('Journey Log Information') + '</h3>';
          html +=
            '<table class="limit-table"><thead><tr><th>' +
            iTrans('Description') +
            '</th><th>' +
            iTrans('Last Known') +
            '</th><th>' +
            iTrans('Current') +
            '</th></tr></thead><tbody>';
          html +=
            '<tr><td>' +
            iTrans('Volume') +
            '</td><td>' +
            data.Volume +
            '</td><td><input type="number" id="FR_WinAirCurVolume" value="' +
            data.Volume +
            '" /></td></tr>';
          html +=
            '<tr><td>' +
            iTrans('Page') +
            '</td><td>' +
            data.Page +
            '</td><td><input type="number" id="FR_WinAirCurPage" value="' +
            (parseInt(data.Page) + 1) +
            '"/></td></tr>';
          html += '</tbody></table>';
        }

        html +=
          '<div id="PilotSignatureHLDR"><button onClick="ACTIMES_OPEN_SIGNATURE()" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button></div>';
        html +=
          '<div id="UpdateTimesHLDR" style="display:none;"><button onClick="UpdateAircraftTimes_WinAir(\'' +
          CURRENT_FLIGHT_REPORT.Aircraft +
          '\')" data-icon="check" class="greenbg" >' +
          iTrans('Update Aircraft Times') +
          '</button></div>';
        $('#BILLING_ACTIMES_Content').html(html);
        $('#BILLING_ACTIMES_Content').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="BILLING_popAircraftTimes();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#BILLING_ACTIMES_Content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved!') +
        '</h2><b>' +
        iTrans(
          'There was either an error contacting the WinAir Server OR the requested aircraft does not exist in the maintenance system.'
        ) +
        '</b><br />' +
        iTrans(
          'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
        ) +
        refreshBTN +
        '<br /><br />' +
        iTrans('Response') +
        ': ' +
        Note
    );
    $('#BILLING_ACTIMES_Content').enhanceWithin();
    $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  });
}

function BILLING_WinAirPlus(index) {
  var id = 'FR_' + AC_TECH_DISPATCH.UPDATE.Fields[index].inputName;
  var val = $('#' + id).val();
  val = parseInt(val);
  val++;
  $('#' + id).val(val);
  AC_TECH_DISPATCH.UPDATE.Fields[index].value = val;
}

function BILLING_WinAirMinus(index) {
  var id = 'FR_' + AC_TECH_DISPATCH.UPDATE.Fields[index].inputName;
  var val = $('#' + id).val();
  val = parseInt(val);
  val--;
  if (val < 0) val = 0;
  $('#' + id).val(val);
  AC_TECH_DISPATCH.UPDATE.Fields[index].value = val;
}

function BILLING_WinAirClear(index) {
  var id = 'FR_' + AC_TECH_DISPATCH.UPDATE.Fields[index].inputName;
  $('#' + id).val(AC_TECH_DISPATCH.UPDATE.Fields[index].inputFormat);
  AC_TECH_DISPATCH.UPDATE.Fields[index].value = $('#' + id).val();
}

function BILLING_SetWinAirData(e) {
  //could do some validation here too;
  var value = $('#' + e.id).val();
  switch (AC_TECH_DISPATCH.UPDATE.Fields[e.name].inputFormat) {
    case 'HH:MM':
      value = validateHHMM(value);
      break;
    case '0':
      value = parseInt(value);
      break;
    case '0.0':
      value = parseFloat(value);
      value = FormatNumber(value, 0, 1);
      break;
    case '0.00':
      value = parseFloat(value);
      value = FormatNumber(value, 0, 2);
      break;
    default:
      value = parseFloat(value);
      value = FormatNumber(value, 0, 2);
      break;
  }
  $('#' + e.id).val(value);
  AC_TECH_DISPATCH.UPDATE.Fields[e.name].value = value;
}

function BILLING_popAircraftTimes_CAMP(back) {
  var goback = back || false;
  $.mobile.changePage($('#BILLING_ACTIMES'), {
    changeHash: false,
    reverse: goback,
  });
  $('#Billing_CompleteAircraftTimes').prop('disabled', true);
  $('#Billing_CompleteAircraftTimes').off('click');
  $('#Billing_CompleteAircraftTimes').click(function () {
    if (CURRENT_FLIGHT_REPORT.CAMPUpdated == true) {
      signatureDATA.data = '';
      signatureDATA.text = '';
      Billing_CompleteFlightReport_display();
    } else if (signatureDATA.data == '') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans(
          'You have not signed the aircraft technical records. Please click [Sign Now]. If you continue without updating, the technical records you have input will be lost.'
        ),
        buttons: {
          [iTrans('Continue Without Updating')]: {
            click: function () {
              signatureDATA.data = '';
              signatureDATA.text = '';
              Billing_CompleteFlightReport_display();
            },
          },
          [iTrans('Sign Now')]: {
            click: function () {
              ACTIMES_OPEN_SIGNATURE();
            },
          },
        },
      });
    } else if (!$('#UpdateTimesHLDR').is(':hidden')) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans(
          'You have not updated the aircraft technical records. If you continue without updating, the technical records you have input will be lost.'
        ),
        buttons: {
          [iTrans('Continue Without Updating')]: {
            click: function () {
              signatureDATA.data = '';
              signatureDATA.text = '';
              Billing_CompleteFlightReport_display();
            },
          },
          [iTrans('Update Times Now')]: {
            click: function () {
              $('#UpdateAircraftTimes_CAMPBTN').trigger('click');
            },
          },
        },
      });
    } else {
      signatureDATA.data = '';
      signatureDATA.text = '';
      Billing_CompleteFlightReport_display();
    }
  });
  signatureDATA.data = '';
  signatureDATA.text = '';
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#BILLING_ACTIMES_Content').html('<h2>' + iTrans('Populating') + '</h2>');
  //console.log(CURRENT_FLIGHT_REPORT);
  //var acIdent = encodeURIComponent(CURRENT_FLIGHT_REPORT.Aircraft)
  var Note = 'Unknown';
  //CURRENT_FLIGHT_REPORT.Aircraft = "ES-DEMO1"; //todo remove this in production
  $.getJSON(
    BASEURL +
      'CAMP-AJAX.php?action=getIdent&ident=' +
      encodeURIComponent(CURRENT_FLIGHT_REPORT.Aircraft) +
      '&FR_PK=' +
      CURRENT_FLIGHT_REPORT.PrimaryKey,
    function (json_data) {
      $('#Billing_CompleteAircraftTimes').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          var Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="BILLING_popAircraftTimes_CAMP();" data-icon="refresh">Try Again</button>';
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!</h2><b>' +
              iTrans(
                'There was either an error contacting the CAMP Server OR the requested aircraft does not exist in the maintenance system'
              ) +
              '.</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available'
              ) +
              '.' +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.data.Status == 'Not Found') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retreived') +
              '!<br />' +
              iTrans('The requested aircraft does not exist in the maintenance system') +
              '.</h2>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.data.Status == 'SoapFault') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' + iTrans('SoapFault') + '</h2><b>' + json_data.data.faultstring + '.</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        var data = json_data.data;
        if (data.Existing) {
          data = data.ExistingData;
          var signature_text = data.signature_text;
          var signature_data = data.signature_data;
          console.log(data);
          displayUpdatedAircraftTimes_CAMP(
            CURRENT_FLIGHT_REPORT.Aircraft,
            JSON.parse(data.data),
            signature_text,
            signature_data
          );
          return;
        }
        AC_TECH_DISPATCH.RAW = json_data;
        AC_TECH_DISPATCH.UPDATE = JSON.parse(JSON.stringify(json_data)); //make copy of object for update
        var html = '';
        var Landings = CURRENT_FLIGHT_REPORT.Legs.length;
        var TotalTime = CURRENT_FLIGHT_REPORT.HoursFlown;

        acIdent = CURRENT_FLIGHT_REPORT.Aircraft;

        html += CAMP_EarliestDueTimes_HTML(data);

        html += '<h3>' + iTrans('Data from Flight Report') + '</h3>';
        html +=
          "<table class='limit-table'><thead><tr><th>" +
          iTrans('Item') +
          '</th><th></th><th>' +
          iTrans('Value') +
          '</th></tr></thead><tbody>';
        html +=
          '<tr><td><b>' +
          iTrans('Legs') +
          ': </b></td><td style="text-align: center;">' +
          Landings +
          '</td><td></td></tr>';
        html +=
          '<tr><td><b>' +
          iTrans('Total Flight Time') +
          ': </b></td><td style="text-align: center;">' +
          CURRENT_FLIGHT_REPORT.HoursFlown +
          '</td><td></td></tr>';
        if (HOBBS == 1) {
          var P_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Primary;
          var S_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Secondary;
          CURRENT_FLIGHT_REPORT.P_Total = round10(P_Hobbs.Reported.Total - P_Hobbs.PrevReading.Total);
          CURRENT_FLIGHT_REPORT.S_Total = round10(S_Hobbs.Reported.Total - S_Hobbs.PrevReading.Total);
          if (CURRENT_FLIGHT_REPORT.P_Total > 0) TotalTime = CURRENT_FLIGHT_REPORT.P_Total;
          if (CURRENT_FLIGHT_REPORT.S_Total > TotalTime) TotalTime = CURRENT_FLIGHT_REPORT.S_Total;
          html +=
            '<tr><td><b>' +
            iTrans('Flight HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.P_Total +
            '</td><td></td></tr>';
          html +=
            '<tr><td><b>' +
            iTrans('Maint HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.S_Total +
            '</td><td></td></tr>';
        }
        html += '</tbody></table>';

        //transfer traxxall times here
        html += '<h3>' + iTrans('Update Aircraft Times') + '</h3>';

        html +=
          '<table class="limit-table first-td-fill"><thead><tr><th>Description</th><th>Last Reported</th><th>Position</th><th>Unit</th><th>Value</th><th>New Value</th><th>Add Value</th></tr></thead><tbody>';
        var Actuals = data.latestAircraftTimes.timesInfo;
        var Hours = CURRENT_FLIGHT_REPORT.HoursFlown;
        for (var i in Actuals) {
          var I = Actuals[i];
          var DisabledInput = '';
          var InputHtml = '';

          var plusBTN =
            '<button class="action-btn" value="' +
            i +
            '" onclick="BILLING_CAMP_Plus(this)"><img src="./images/Plus-icon.png" height="22px" ></button>';
          var minusBTN =
            '<button class="action-btn" value="' +
            i +
            '" onclick="BILLING_CAMP_Minus(this)"><img src="./images/Minus-icon.png" height="22px" ></button>';
          var clearBTN =
            '<button class="action-btn" value="' +
            i +
            '" onclick="BILLING_CAMP_Clear(this)"><img src="./images/cleartxt.png" width="22px" ></button>';
          var BTN = '';
          switch (I.unit) {
            case 'AFL':
            case 'CGL':
            case 'FGL':
            case 'FWL':
            case 'LGEXT':
            case 'LGRET':
            case 'RETEX':
            case '(AFL)':
              InputHtml +=
                "<input type='number' id='FR_" +
                i +
                "' value='" +
                Landings +
                "' name='" +
                i +
                "' onChange='BILLING_SetCAMP_Data(this);'  style='width:60px;' step='1' onclick='this.select();' + DisabledInput/>";
              AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value =
                parseInt(I.lastReportedValue) + Landings;
              BTN = plusBTN + minusBTN + clearBTN;
              break;
            case 'HRS':
              InputHtml +=
                "<input type='number' id='FR_" +
                i +
                "' value='" +
                Hours +
                "' name='" +
                i +
                "' onChange='BILLING_SetCAMP_Data(this);'  style='width:60px;' onclick='this.select()' step='0.1' + DisabledInput/>";
              AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value =
                I.lastReportedValue + Math.abs(Hours * 60);
              BTN = plusBTN + minusBTN + clearBTN;
              break;
            default:
              InputHtml +=
                "<input type='number' step='1' id='FR_" +
                i +
                "' value='0' name='" +
                i +
                "' onChange='BILLING_SetCAMP_Data(this);'  style='width:60px;' step='1' onclick='this.select();' + DisabledInput/>";
              BTN = plusBTN + minusBTN + clearBTN;
              break;
          }
          if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value == undefined) {
            AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value = I.lastReportedValue;
          }
          html +=
            '<tr><td>' +
            I.profileType +
            ' - ' +
            getCAMP_UnitTXT(I.unit) +
            '</td><td>' +
            I.lastReportedDate +
            '</td><td>' +
            I.position +
            '</td><td style="text-align: center;">' +
            I.unit +
            '</td><td style="text-align: right;">' +
            (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS'
              ? (I.lastReportedValue / 60).toFixed(1)
              : I.lastReportedValue) +
            '</td><td style="text-align: right;"><b><span id="FFR_' +
            i +
            '" >' +
            (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS'
              ? (parseInt(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value) / 60).toFixed(1)
              : AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value) +
            '</span></b>' +
            '</td><td><div class="vertical-center-container">' +
            InputHtml +
            BTN +
            '</div></td></tr>';
        }
        html += '</tbody></table>';

        html +=
          '<div id="PilotSignatureHLDR"><button onClick="ACTIMES_OPEN_SIGNATURE()" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button></div>';
        html +=
          '<div id="UpdateTimesHLDR" style="display:none;"><button onClick="UpdateAircraftTimes_CAMP(\'' +
          CURRENT_FLIGHT_REPORT.Aircraft +
          '\')" data-icon="check" class="greenbg" id="UpdateAircraftTimes_CAMPBTN" >' +
          iTrans('Update Aircraft Times') +
          '</button></div>';

        $('#BILLING_ACTIMES_Content').html(html);
        $('#BILLING_ACTIMES_Content').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="BILLING_popAircraftTimes_CAMP();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#BILLING_ACTIMES_Content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved!') +
        '</h2><b>' +
        iTrans(
          'There was either an error contacting the CAMP Server OR the requested aircraft does not exist in the maintenance system.'
        ) +
        '</b><br />' +
        iTrans(
          'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
        ) +
        refreshBTN +
        '<br /><br />' +
        iTrans('Response') +
        ': ' +
        Note
    );
    $('#BILLING_ACTIMES_Content').enhanceWithin();
    $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  });
}

function BILLING_CAMP_Plus(e) {
  var id = 'FR_' + e.value;
  var val = $('#' + id).val();
  val = parseFloat(val);
  val++;
  var i = e.value;
  $('#' + id).val(val);
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS') {
    val = val * 60;
  }

  var newVal =
    parseFloat(AC_TECH_DISPATCH.RAW.data.latestAircraftTimes.timesInfo[i].lastReportedValue) + parseFloat(val);
  AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value = newVal;
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS') {
    $('#FFR_' + i).html((AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value / 60).toFixed(1));
  } else {
    $('#FFR_' + i).html(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value);
  }
}

function BILLING_CAMP_Minus(e) {
  var id = 'FR_' + e.value;
  var val = $('#' + id).val();
  val = parseFloat(val);
  val--;
  if (val < 0) val = 0;
  $('#' + id).val(val);
  var i = e.value;
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS') {
    val = val * 60;
  }

  var newVal =
    parseFloat(AC_TECH_DISPATCH.RAW.data.latestAircraftTimes.timesInfo[i].lastReportedValue) + parseFloat(val);
  AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value = newVal;
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS') {
    $('#FFR_' + i).html((AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value / 60).toFixed(1));
  } else {
    $('#FFR_' + i).html(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value);
  }
}

function BILLING_CAMP_Clear(e) {
  var i = e.value;
  var id = 'FR_' + e.value;
  $('#' + id).val(0);

  AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].value =
    AC_TECH_DISPATCH.RAW.data.latestAircraftTimes.timesInfo[i].lastReportedValue;
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].unit == 'HRS') {
    $('#FFR_' + i).html(
      (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].lastReportedValue / 60).toFixed(1)
    );
  } else {
    $('#FFR_' + i).html(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[i].lastReportedValue);
  }
}

function BILLING_SetCAMP_Data(e) {
  //could do some validation here too;
  var value = $('#' + e.id).val();
  console.log('value', value);
  var parts = e.id.split('_');
  value = CAMP_FormatUnitOfMeasure(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].unit, value);

  AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].value =
    parseFloat(AC_TECH_DISPATCH.RAW.data.latestAircraftTimes.timesInfo[parts[1]].lastReportedValue) + parseFloat(value);
  if (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].unit == 'HRS') {
    $('#' + e.id).val((value / 60).toFixed(1));
    $('#FFR_' + parts[1]).html(
      (AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].value / 60).toFixed(1)
    );
  } else {
    $('#' + e.id).val(value);
    $('#FFR_' + parts[1]).html(AC_TECH_DISPATCH.UPDATE.data.latestAircraftTimes.timesInfo[parts[1]].value);
  }
}

function CAMP_FormatUnitOfMeasure(UOM, value) {
  switch (UOM) {
    case 'LDG':
      value = parseInt(value);
      value = FormatNumber(value, 0, 0);
      break;

    case 'HRS':
      value = parseFloat(value) * 60;
      value = FormatNumber(value, 0, 1);
      break;
    case 'RIN':
      value = parseFloat(value);
      value = FormatNumber(value, 0, 0);
      break;

    default:
      value = parseFloat(value);
      value = FormatNumber(value, 0, 2);
      break;
  }
  return value;
}

function UpdateAircraftTimes_CAMP(ident) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  //ident = "ES-DEMO1"; //todo remove this in production
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url:
      BASEURL +
      'CAMP-AJAX.php?action=updateTimes&ident=' +
      encodeURIComponent(ident) +
      '&FR_PK=' +
      CURRENT_FLIGHT_REPORT.PrimaryKey,
    data: {
      data: AC_TECH_DISPATCH,
      signature_text: signatureDATA.text,
      signature_data: signatureDATA.data,
      FR_PK: CURRENT_FLIGHT_REPORT.PrimaryKey,
      Date: CURRENT_FLIGHT_REPORT.Date,
    },
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data.ServerResponse != 'Success') {
        if (json_data.ServerResponse == 'Failed') {
          if (json_data.data.Status != undefined) {
            if (json_data.data.Status == 'SoapFault') {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Update Failed'),
                headerClose: false,
                buttonPrompt: json_data.data.faultstring,
                buttons: {
                  [iTrans('OK')]: {
                    click: function () {
                      //do nothing
                    },
                  },
                },
              });
            }
          } else {
            var Reasons = json_data.data.reason + '<br />';

            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Update Failed'),
              headerClose: false,
              buttonPrompt: Reasons,
              buttons: {
                [iTrans('OK')]: {
                  click: function () {
                    //do nothing
                  },
                },
              },
            });
            //status could be offline/Not Logged In/Uncaught Exception
            if (json_data[0] != undefined) {
              if (json_data[0].ServerResponse == 'Uncaught Exception') {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('Update Failed'),
                  headerClose: false,
                  buttonPrompt: json_data[0].Exception,
                  buttons: {
                    [iTrans('OK')]: {
                      click: function () {
                        //do nothing
                      },
                    },
                  },
                });
              }
            }
          }
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        displayUpdatedAircraftTimes_CAMP(ident, AC_TECH_DISPATCH, signatureDATA.text, signatureDATA.data);
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Update Failed'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem submitting the Updated Aircraft Times.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    });
}

function displayUpdatedAircraftTimes_CAMP(ident, json_data, signature_text, signature_data) {
  var html = '<h3>' + ident + ' ' + iTrans('Updated Successfully') + '</h3>';

  var Actuals = json_data.UPDATE.data.latestAircraftTimes.timesInfo;
  html +=
    '<table class="limit-table first-td-fill"><thead><tr><th>Description</th><th>Last Reported</th><th>Position</th><th>Unit</th><th>Value</th><th>New Value</th></tr></thead><tbody>';
  var Hours = CURRENT_FLIGHT_REPORT.HoursFlown;
  for (var i in Actuals) {
    var I = Actuals[i];

    html +=
      '<tr><td>' +
      I.profileType +
      '</td><td>' +
      I.lastReportedDate +
      '</td><td>' +
      I.position +
      '</td><td style="text-align: center;">' +
      I.unit +
      '</td><td style="text-align: right;">' +
      (I.unit == 'HRS' ? (I.lastReportedValue / 60).toFixed(1) : I.lastReportedValue) +
      '</td><td style="text-align: right;"><b>' +
      (I.unit == 'HRS' ? (parseInt(I.value) / 60).toFixed(1) : I.value) +
      '</b>' +
      '</td></tr>';
  }
  html += '</tbody></table>';

  html += '<center><img src="' + signature_data + '" width="321px" height="150px"/><br />';
  html += '<b>' + signature_text + '</b></center>';
  $('#BILLING_ACTIMES_Content').html(html);
  $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  signatureDATA.data = '';
  signatureDATA.text = '';
  CURRENT_FLIGHT_REPORT.CAMPUpdated = true;
}

function BILLING_popAircraftTimes_Traxxall(back) {
  var goback = back || false;
  $.mobile.changePage($('#BILLING_ACTIMES'), {
    changeHash: false,
    reverse: goback,
  });
  $('#Billing_CompleteAircraftTimes').prop('disabled', true);
  $('#Billing_CompleteAircraftTimes').off('click');
  $('#Billing_CompleteAircraftTimes').click(function () {
    if (CURRENT_FLIGHT_REPORT.TraxxallUpdated == true) {
      signatureDATA.data = '';
      signatureDATA.text = '';
      Billing_CompleteFlightReport_display();
    } else if (signatureDATA.data == '') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans(
          'You have not signed the aircraft technical records. Please click [Sign Now]. If you continue without updating, the technical records you have input will be lost.'
        ),
        buttons: {
          [iTrans('Continue Without Updating')]: {
            click: function () {
              signatureDATA.data = '';
              signatureDATA.text = '';
              Billing_CompleteFlightReport_display();
            },
          },
          [iTrans('Sign Now')]: {
            click: function () {
              ACTIMES_OPEN_SIGNATURE();
            },
          },
        },
      });
    } else if (!$('#UpdateTimesHLDR').is(':hidden')) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans(
          'You have not updated the aircraft technical records. If you continue without updating, the technical records you have input will be lost.'
        ),
        buttons: {
          [iTrans('Continue Without Updating')]: {
            click: function () {
              signatureDATA.data = '';
              signatureDATA.text = '';
              Billing_CompleteFlightReport_display();
            },
          },
          [iTrans('Update Times Now')]: {
            click: function () {
              $('#UpdateAircraftTimes_TraxxallBTN').trigger('click');
            },
          },
        },
      });
    } else {
      signatureDATA.data = '';
      signatureDATA.text = '';
      Billing_CompleteFlightReport_display();
    }
  });
  signatureDATA.data = '';
  signatureDATA.text = '';
  AC_TECH_DISPATCH = {}; //empty old tech dispatch object
  $('#ITIN-FD_content').html('<h2>' + iTrans('Populating') + '</h2>');
  $('#BILLING_ACTIMES_Content').html('<h2>' + iTrans('Populating') + '</h2>');
  //console.log(CURRENT_FLIGHT_REPORT);
  //var acIdent = encodeURIComponent(CURRENT_FLIGHT_REPORT.Aircraft)
  var Note = 'Unknown';
  //CURRENT_FLIGHT_REPORT.Aircraft = "ES-DEMO1"; //todo remove this in production
  $.getJSON(
    BASEURL +
      'Traxxall-AJAX.php?action=getIdent&Ident=' +
      encodeURIComponent(CURRENT_FLIGHT_REPORT.Aircraft) +
      '&FR_PK=' +
      CURRENT_FLIGHT_REPORT.PrimaryKey,
    function (json_data) {
      $('#Billing_CompleteAircraftTimes').prop('disabled', false);
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          //ServerResponse(json_data, "popTechDispatch");
          Note = json_data.Exception;
          var refreshBTN =
            '<button data-mini="ture" onclick="BILLING_popAircraftTimes_Traxxall();" data-icon="refresh">' +
            iTrans('Try Again') +
            '</button>';
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retrieved!') +
              '</h2><b>' +
              iTrans(
                'There was either an error contacting the Traxxall Server OR the requested aircraft does not exist in the maintenance system.'
              ) +
              '</b><br />' +
              iTrans(
                'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
              ) +
              refreshBTN +
              '<br /><br />' +
              iTrans('Response') +
              ': ' +
              Note
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAircraft') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('No Aircraft Data Retrieved!') +
              '</h2><b>The requested aircraft does not exist in the maintenance system.</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'AircraftDisabled') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('Aircraft Disabled') +
              '</h2><b>' +
              iTrans('The requested aircraft has been disabled in the maintenance system.') +
              '</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'APIaccessdenied') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('API Access Denied') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retrieved from the maintenance system because the API key provided does not have the required permissions.'
              ) +
              '</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'InvalidAPIkey') {
          $('#BILLING_ACTIMES_Content').html(
            '<h2>' +
              iTrans('Invalid API key') +
              '</h2><b>' +
              iTrans(
                'The requested aircraft cannot be retrieved from the maintenance system because the API key provided is not valid.'
              ) +
              '</b>'
          );
          $('#BILLING_ACTIMES_Content').enhanceWithin();
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        if (json_data.Existing) {
          var data = json_data.ExistingData;
          data.data = JSON.parse(data.data);
          var signature_text = data.signature_text;
          var signature_data = data.signature_data;
          console.log(data.data);
          displayUpdatedAircraftTimes_Traxxall(
            CURRENT_FLIGHT_REPORT.Aircraft,
            data.data,
            signature_text,
            signature_data
          );
          return;
        }
        AC_TECH_DISPATCH.RAW = json_data;
        AC_TECH_DISPATCH.UPDATE = JSON.parse(JSON.stringify(json_data)); //make copy of object for update
        var html = '';
        var Landings = CURRENT_FLIGHT_REPORT.Legs.length;
        var TotalTime = CURRENT_FLIGHT_REPORT.HoursFlown;

        html += '<h2>' + CURRENT_FLIGHT_REPORT.Aircraft + ' - ' + iTrans('Technical Records') + ' - ';
        if (json_data.LastEntry == 'Unknown') html += iTrans('Updated') + ': <b>' + iTrans('Never') + '</b></h2>';
        else html += iTrans('Updated') + ': ' + new Date(json_data.LastEntry).toDateString() + '</h2>';

        html += '<h3>' + iTrans('Next Due Items') + '</h3>';
        html +=
          '<table class="limit-table wrap"><thead><tr><th>Description</th><th>Earliest Due</th><th>Remaining</th></tr></thead><tbody>';
        var DueList = json_data.DueList;
        if (DueList.message != undefined) {
          if (DueList.message.indexOf('Not Found') != -1) {
            $('#BILLING_ACTIMES_Content').html(iTrans('Aircraft Not In Traxxall System'));
            $('#BILLING_ACTIMES_Content').enhanceWithin();
            return;
          }
        }
        for (var i in DueList) {
          var I = DueList[i];
          var lineClass = '';
          if (I['aircraft Color'] == 'Red') lineClass = 'redbg';
          html +=
            '<tr class="' +
            lineClass +
            '"><td>' +
            I.description +
            '</td><td>' +
            I['earliest Due'] +
            '</td><td>' +
            I['remaining Time'] +
            '</td></tr>';
        }
        html += '</tbody></table>';

        html += '<h3>' + iTrans('Data from Flight Report') + '</h3>';
        html +=
          "<table class='limit-table'><thead><tr><th>" +
          iTrans('Item') +
          '</th><th></th><th>' +
          iTrans('Value') +
          '</th></tr></thead><tbody>';
        html += '<tr><td><b>' + iTrans('Legs') + ': </b></td><td>' + Landings + '</td><td></td></tr>';
        html +=
          '<tr><td><b>' +
          iTrans('Total Flight Time') +
          ': </b></td><td>' +
          CURRENT_FLIGHT_REPORT.HoursFlown +
          '</td><td></td></tr>';
        if (HOBBS == 1) {
          var P_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Primary;
          var S_Hobbs = CURRENT_FLIGHT_REPORT.HOBBS.Secondary;
          CURRENT_FLIGHT_REPORT.P_Total = round10(P_Hobbs.Reported.Total - P_Hobbs.PrevReading.Total);
          CURRENT_FLIGHT_REPORT.S_Total = round10(S_Hobbs.Reported.Total - S_Hobbs.PrevReading.Total);
          if (CURRENT_FLIGHT_REPORT.P_Total > 0) TotalTime = CURRENT_FLIGHT_REPORT.P_Total;
          if (CURRENT_FLIGHT_REPORT.S_Total > TotalTime) TotalTime = CURRENT_FLIGHT_REPORT.S_Total;
          html +=
            '<tr><td><b>' +
            iTrans('Flight HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.P_Total +
            '</td><td></td></tr>';
          html +=
            '<tr><td><b>' +
            iTrans('Maint HOBBS') +
            ': </b></td><td>' +
            CURRENT_FLIGHT_REPORT.S_Total +
            '</td><td></td></tr>';
        }
        html += '</tbody></table>';

        //transfer traxxall times here
        html += '<h3>' + iTrans('Update Aircraft Times') + '</h3>';
        html += '<table><thead><tr><th>Assembly</th><th>Component Updates</th><th>As Of</th></tr></thead><tbody>';
        var Actuals = json_data.Actuals;
        var Hours = CURRENT_FLIGHT_REPORT.HoursFlown;
        for (var i in Actuals) {
          var I = Actuals[i];
          if (!I.isCurrent || I.isSpare) continue;
          var vhtml =
            '<table><thead><tr><th>Current</th><th>Units</th><th>New Value</th><th>Add Value</th></tr></thead><tbody>';
          for (var ii in I.values) {
            var V = I.values[ii];
            if (V.isReadOnlyUom) continue;
            //if (ii > 0) vhtml += '<br />';
            //vhtml += V.value + ' ' + V.unitOfMeasure;
            var InputHtml = '';

            var plusBTN =
              '<button class="action-btn" value="' +
              i +
              '_' +
              ii +
              '" onclick="BILLING_TraxxallPlus(this)"><img src="./images/Plus-icon.png" height="22px" ></button>';
            var minusBTN =
              '<button class="action-btn" value="' +
              i +
              '_' +
              ii +
              '" onclick="BILLING_TraxxallMinus(this)"><img src="./images/Minus-icon.png" height="22px" ></button>';
            var clearBTN =
              '<button class="action-btn" value="' +
              i +
              '_' +
              ii +
              '" onclick="BILLING_TraxxallClear(this)"><img src="./images/cleartxt.png" width="22px" ></button>';
            var BTN = '';

            var DisabledInput = '';

            switch (V.unitOfMeasure) {
              case 'Ldg':
                InputHtml +=
                  "<input type='number' id='FR_" +
                  i +
                  '_' +
                  ii +
                  "' value='" +
                  Landings +
                  "' name='" +
                  i +
                  "' onChange='BILLING_SetTraxxallData(this);'  style='width:60px;' step='1' onclick='this.select();' + DisabledInput/>";
                AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value =
                  AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value + Landings;
                BTN = plusBTN + minusBTN + clearBTN;
                break;

              case 'Hrs':
              case 'ACH':
              case 'FH':
              case 'Hrs(APU)':
              case 'Start(API)':
              case 'Hrs Meter':
                InputHtml +=
                  "<input type='number' id='FR_" +
                  i +
                  '_' +
                  ii +
                  "' value='" +
                  Hours +
                  "' name='" +
                  i +
                  "' onChange='BILLING_SetTraxxallData(this);'  style='width:60px;' onclick='this.select()' step='.01' + DisabledInput/>";
                AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value =
                  AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value + Hours;
                BTN = plusBTN + minusBTN + clearBTN;
                break;
              case 'CRD':
                InputHtml +=
                  "<input type='number' id='FR_" +
                  i +
                  '_' +
                  ii +
                  "' value='0' name='" +
                  i +
                  "' onChange='BILLING_SetTraxxallData(this);'  style='width:60px;' step='.01' onclick='this.select();' + DisabledInput/>";
                BTN = plusBTN + minusBTN + clearBTN;
                break;
              case 'CSN1':
              case 'CSN1(NG)':
              case 'CSN2':
              case 'CSN2(NP)':
                InputHtml +=
                  "<input type='number' id='FR_" +
                  i +
                  '_' +
                  ii +
                  "' value='" +
                  Landings +
                  "' name='" +
                  i +
                  "' onChange='BILLING_SetTraxxallData(this);'  style='width:60px;' step='.01' onclick='this.select();' + DisabledInput/>";
                BTN = plusBTN + minusBTN + clearBTN;
                break;
              case 'Enc':
              case 'APUS':
              case 'PRC':
              case 'FLCY':
              case 'HC':
              case 'SC':
              case 'RC':
              case 'TC':

              case 'LC':
              case 'Events':
              case 'RIN':
                InputHtml +=
                  "<input type='number' id='FR_" +
                  i +
                  '_' +
                  ii +
                  "' value='" +
                  Landings +
                  "' name='" +
                  i +
                  "' onChange='BILLING_SetTraxxallData(this);'  style='width:60px;' step='1' onclick='this.select();' + DisabledInput/>";
                AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value =
                  AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value + Landings;
                BTN = plusBTN + minusBTN + clearBTN;
                break;

              default:
                InputHtml +=
                  "<input type='number' step='1' id='FR_" +
                  i +
                  '_' +
                  ii +
                  "' value='0' name='" +
                  i +
                  "' onChange='BILLING_SetTraxxallData(this);'  style='width:60px;' step='1' onclick='this.select();' + DisabledInput/>";
                AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value = AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value;
                BTN = clearBTN;
                break;
            }

            vhtml +=
              '<tr><td><b>' +
              V.value +
              '</b></td><td>' +
              V.unitOfMeasure +
              '</b></td><td><b><span id="FFR_' +
              i +
              '_' +
              ii +
              '" >' +
              AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value +
              '</span></b>' +
              '</td><td><div class="vertical-center-container">' +
              InputHtml +
              BTN +
              '</div></tr>';
          }
          vhtml += '</tbody></table>';
          html +=
            '<tr><td>' +
            I.majorAssemblyDescription +
            '</td><td>' +
            vhtml +
            '</td><td>' +
            I.majorAssemblyActualDate +
            '</td></tr>';
        }
        html += '</tbody></table>';

        html +=
          '<div id="PilotSignatureHLDR"><button onClick="ACTIMES_OPEN_SIGNATURE()" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button></div>';
        html +=
          '<div id="UpdateTimesHLDR" style="display:none;"><button onClick="UpdateAircraftTimes_Traxxall(\'' +
          CURRENT_FLIGHT_REPORT.Aircraft +
          '\')" data-icon="check" class="greenbg" id="UpdateAircraftTimes_TraxxallBTN" >' +
          iTrans('Update Aircraft Times') +
          '</button></div>';

        $('#BILLING_ACTIMES_Content').html(html);
        $('#BILLING_ACTIMES_Content').enhanceWithin();
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    var refreshBTN =
      '<button data-mini="ture" onclick="BILLING_popAircraftTimes_Traxxall();" data-icon="refresh">' +
      iTrans('Try Again') +
      '</button>';
    $('#BILLING_ACTIMES_Content').html(
      '<h2>' +
        iTrans('No Aircraft Data Retrieved!') +
        '</h2><b>' +
        iTrans(
          'There was either an error contacting the Traxxall Server OR the requested aircraft does not exist in the maintenance system.'
        ) +
        '</b><br />' +
        iTrans(
          'You can still continue and save this report and review the Flight Report again later to update aircraft technical dispatch data when the service is available.'
        ) +
        refreshBTN +
        '<br /><br />' +
        iTrans('Response') +
        ': ' +
        Note
    );
    $('#BILLING_ACTIMES_Content').enhanceWithin();
    $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  });
}

function BILLING_TraxxallPlus(e) {
  var id = 'FR_' + e.value;
  var val = $('#' + id).val();
  val = parseFloat(val);
  val++;
  var i = e.value.split('_')[0];
  var ii = e.value.split('_')[1];

  $('#' + id).val(Traxxall_FormatUnitOfMeasure(AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].unitOfMeasure, val));
  var newVal = parseFloat(AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value) + val;
  AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value = Traxxall_FormatUnitOfMeasure(
    AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].unitOfMeasure,
    newVal
  );
  $('#FFR_' + i + '_' + ii).html(AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value);
}

function BILLING_TraxxallMinus(e) {
  var id = 'FR_' + e.value;
  var val = $('#' + id).val();
  val = parseFloat(val);
  val--;
  if (val < 0) val = 0;
  var i = e.value.split('_')[0];
  var ii = e.value.split('_')[1];
  $('#' + id).val(Traxxall_FormatUnitOfMeasure(AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].unitOfMeasure, val));
  var newVal = parseFloat(AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value) + val;
  AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value = Traxxall_FormatUnitOfMeasure(
    AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].unitOfMeasure,
    newVal
  );
  $('#FFR_' + i + '_' + ii).html(AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value);
}

function BILLING_TraxxallClear(e) {
  var i = e.value.split('_')[0];
  var ii = e.value.split('_')[1];
  var id = 'FR_' + e.value;
  $('#' + id).val(0);

  AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value = AC_TECH_DISPATCH.RAW.Actuals[i].values[ii].value;
  $('#FFR_' + i + '_' + ii).html(AC_TECH_DISPATCH.UPDATE.Actuals[i].values[ii].value);
}

function BILLING_SetTraxxallData(e) {
  //could do some validation here too;
  var value = $('#' + e.id).val();
  var parts = e.id.split('_');
  value = Traxxall_FormatUnitOfMeasure(AC_TECH_DISPATCH.UPDATE.Actuals[parts[1]].values[parts[2]].unitOfMeasure, value);

  $('#' + e.id).val(value);
  AC_TECH_DISPATCH.UPDATE.Actuals[parts[1]].values[parts[2]].value =
    AC_TECH_DISPATCH.RAW.Actuals[parts[1]].values[parts[2]].value + parseFloat(value);
  AC_TECH_DISPATCH.UPDATE.Actuals[parts[1]].values[parts[2]].value = Traxxall_FormatUnitOfMeasure(
    AC_TECH_DISPATCH.UPDATE.Actuals[parts[1]].values[parts[2]].unitOfMeasure,
    parseFloat(AC_TECH_DISPATCH.UPDATE.Actuals[parts[1]].values[parts[2]].value)
  );
  $('#FFR_' + parts[1] + '_' + parts[2]).html(AC_TECH_DISPATCH.UPDATE.Actuals[parts[1]].values[parts[2]].value);
}

function Traxxall_FormatUnitOfMeasure(UOM, value) {
  switch (UOM) {
    case 'Ldg':
      value = parseInt(value);
      value = FormatNumber(value, 0, 0);
      break;

    case 'Hrs':
    case 'ACH':
    case 'FH':
    case 'Hrs(APU)':
    case 'Start(API)':
    case 'Hrs Meter':
      value = parseFloat(value);
      value = FormatNumber(value, 0, 1);
      break;
    case 'CRD':
    case 'CSN1':
    case 'CSN1(NG)':
    case 'CSN2':
    case 'CSN2(NP)':
      value = parseFloat(value);
      value = FormatNumber(value, 0, 2);
      break;
    case 'Enc':
    case 'APUS':
    case 'PRC':
    case 'FLCY':
    case 'HC':
    case 'SC':
    case 'RC':
    case 'TC':

    case 'LC':
    case 'Events':
    case 'RIN':
      value = parseFloat(value);
      value = FormatNumber(value, 0, 0);
      break;

    default:
      value = parseFloat(value);
      value = FormatNumber(value, 0, 2);
      break;
  }
  return value;
}

var PilotSignature;

var ACTIMES_SIGNATURE_RETURN_MAINT = false;

function ACTIMES_OPEN_SIGNATURE(returnMaint = false) {
  ACTIMES_SIGNATURE_RETURN_MAINT = returnMaint;
  if (returnMaint) {
    PAX_SaveScrollPos();
  }

  //goto signature page
  $.mobile.changePage($('#BILLING_ACTIMES_Signature'), {
    changeHash: false,
    reverse: false,
  });
  $('#signature-pad-wrapper2').html('');
  $('#signature-pad-wrapper2').html('<canvas id="signature-pad2" class="signature-pad"></canvas>');
  var canvas = document.getElementById('signature-pad2');
  PilotSignature = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    if (PilotSignature.isEmpty()) {
      setTimeout(function () {
        ACTIMES_ResizeSignaturePad();
      }, 1000);
    }
  }

  window.addEventListener('orientationchange', resizeCanvas);
  ACTIMES_ResizeSignaturePad();
}

function ACTIMES_ResizeSignaturePad() {
  var canvas = document.getElementById('signature-pad2');
  var ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
  PilotSignature.clear(); // otherwise isEmpty() might return incorrect value
}

function ACTIMES_returnReview() {
  if (ACTIMES_SIGNATURE_RETURN_MAINT) {
    $.mobile.changePage($('#AC_Edit_Page'), {
      changeHash: false,
      reverse: true,
    });
    if (!PilotSignature.isEmpty()) {
      var data = PilotSignature.toDataURL('image/png');
      signatureDATA.data = data;
      signatureDATA.text = chatname;
      var html = '<center><img src="' + data + '" width="321px" height="150px"/><br />';
      html += '<b>' + chatname + '</b></center>';
      $('#MaintSignatureHLDR').html(html);
      $('#MaintUpdateTimesHLDR').show();
    } else {
      signatureDATA.data = '';
      signatureDATA.text = '';
      $('#MaintSignatureHLDR').html(
        '<button onClick="ACTIMES_OPEN_SIGNATURE()" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button>'
      );
      $('#MaintUpdateTimesHLDR').hide();
      $('#MaintSignatureHLDR').enhanceWithin();
    }
    PAX_updateScrollPos();
  } else {
    $.mobile.changePage($('#BILLING_ACTIMES'), {
      changeHash: false,
      reverse: true,
    });
    if (!PilotSignature.isEmpty()) {
      var data = PilotSignature.toDataURL('image/png');
      signatureDATA.data = data;
      signatureDATA.text = chatname;
      var html = '<center><img src="' + data + '" width="321px" height="150px"/><br />';
      html += '<b>' + chatname + '</b></center>';
      $('#PilotSignatureHLDR').html(html);
      $('#UpdateTimesHLDR').show();

      setTimeout(function () {
        window.scrollTo(0, 5000);
      }, 500);
    } else {
      signatureDATA.data = '';
      signatureDATA.text = '';
      $('#PilotSignatureHLDR').html(
        '<button onClick="ACTIMES_OPEN_SIGNATURE()" data-icon="plus" class="greenbg" >' +
          iTrans('Sign Technical Record') +
          '</button>'
      );
      $('#UpdateTimesHLDR').hide();
      $('#PilotSignatureHLDR').enhanceWithin();
    }
  }
}

function ACTIMES_ClearSignature() {
  PilotSignature.clear();
}

function UpdateAircraftTimes(ident) {
  $.mobile.loading('show', {
    theme: 'a',
  });

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'FD-AJAX.php?action=updateTimes&Ident=' + encodeURIComponent(ident),
    data: {
      data: AC_TECH_DISPATCH.UPDATE,
      signature_text: signatureDATA.text,
      signature_data: signatureDATA.data,
      FR_PK: CURRENT_FLIGHT_REPORT.PrimaryKey,
    },
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data.ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data, 'UpdateAircraftTimes');
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        displayUpdatedAircraftTimes(ident, json_data, signatureDATA.text, signatureDATA.data);
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem submitting the flight report.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function UpdateAircraftTimes_WinAir(ident) {
  $.mobile.loading('show', {
    theme: 'a',
  });

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'WinAir-AJAX.php?action=updateTimes&Ident=' + encodeURIComponent(ident),
    data: {
      data: AC_TECH_DISPATCH.UPDATE,
      signature_text: signatureDATA.text,
      signature_data: signatureDATA.data,
      FR_PK: CURRENT_FLIGHT_REPORT.PrimaryKey,
      Date: CURRENT_FLIGHT_REPORT.Date,
      Volume: $('#FR_WinAirCurVolume').val(),
      Page: $('#FR_WinAirCurPage').val(),
    },
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data, 'UpdateAircraftTimes');
        }
        if (json_data.ServerResponse == 'Failed') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Update Failed'),
            headerClose: false,
            buttonPrompt: json_data.Reason,
            buttons: {
              [iTrans('OK')]: {
                click: function () {
                  //do nothing
                },
              },
            },
          });
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        displayUpdatedAircraftTimes_WinAir(ident, AC_TECH_DISPATCH.UPDATE, signatureDATA.text, signatureDATA.data);
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Update Failed'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem submitting the flight report.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function UpdateAircraftTimes_Traxxall(ident) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  //ident = "ES-DEMO1"; //todo remove this in production
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'Traxxall-AJAX.php?action=updateTimes&Ident=' + encodeURIComponent(ident),
    data: {
      data: AC_TECH_DISPATCH,
      signature_text: signatureDATA.text,
      signature_data: signatureDATA.data,
      FR_PK: CURRENT_FLIGHT_REPORT.PrimaryKey,
      Date: CURRENT_FLIGHT_REPORT.Date,
    },
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data.ServerResponse != 'Success') {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data.ServerResponse == 'Uncaught Exception') {
          ServerResponse(json_data, 'UpdateAircraftTimes');
        }
        if (json_data.ServerResponse == 'Failed') {
          Reasons = '';
          for (var i in json_data.Reason[0].errors) {
            Reasons += json_data.Reason[0].errors[i] + '<br />';
          }
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Update Failed'),
            headerClose: false,
            buttonPrompt: Reasons,
            buttons: {
              [iTrans('OK')]: {
                click: function () {
                  //do nothing
                },
              },
            },
          });
        }
        if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        displayUpdatedAircraftTimes_Traxxall(ident, AC_TECH_DISPATCH, signatureDATA.text, signatureDATA.data);
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Update Failed'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem submitting the flight report.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function displayUpdatedAircraftTimes(ident, json_data, signature_text, signature_data) {
  //console.log(json_data);
  var AC = json_data.Data;
  var html = '<h3>' + ident + ' ' + iTrans('Updated Successfully') + '</h3>';
  html +=
    '<table class="limit-table wrap"><thead><tr><th>' +
    iTrans('Item') +
    '</th><th>' +
    iTrans('Status') +
    '</th></tr></thead><tbody>';
  html += '<tr><td>' + iTrans('Last Updated') + '</td><td>' + new Date(AC.LastUpdated).toDateString() + '</td></tr>';
  html += '<tr><td>' + iTrans('Landings') + '</td><td>' + AC.Landings + '</td></tr>';
  html += '<tr><td>RIN</td><td>' + AC.RIN + '</td></tr>';
  html += '<tr><td>' + iTrans('Total Time') + '</td><td>' + AC.TotalTime + '</td></tr>';
  html += '</tbody></table>';

  html += '<h3>' + iTrans('Equipment Times') + '</h3>';
  html +=
    '<table class="limit-table wrap"><thead><tr><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Cycles') +
    '</th><th>' +
    iTrans('Hours') +
    '</th></tr></thead><tbody>';
  for (var t in AC.AircraftEquipmentTimes) {
    var T = AC.AircraftEquipmentTimes[t];
    html += '<tr><td>' + T.Type + '</td><td>' + T.Cycles + '</td><td>' + T.Hours + '</td></tr>';
  }
  html += '</tbody></table>';
  html += '<center><img src="' + signature_data + '" width="321px" height="150px"/><br />';
  html += '<b>' + signature_text + '</b></center>';
  $('#BILLING_ACTIMES_Content').html(html);
  $('#Billing_CompleteAircraftTimes').prop('disabled', false);
}

function displayUpdatedAircraftTimes_WinAir(ident, json_data, signature_text, signature_data) {
  //console.log(json_data);

  var html = '<h3>' + ident + ' ' + iTrans('Updated Successfully') + '</h3>';

  var IP = json_data.Fields;
  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Description') +
    ' / ' +
    iTrans('Remarks') +
    '</th><th>' +
    iTrans('Updated Value') +
    '</th></tr></thead><tbody>';
  for (var i in IP) {
    html += '<tr><td>' + IP[i].inputDescription + ' (' + IP[i].inputFormat + ')</td><td>' + IP[i].value + '</td></tr>';
  }
  html += '</tbody></table>';

  html += '<center><img src="' + signature_data + '" width="321px" height="150px"/><br />';
  html += '<b>' + signature_text + '</b></center>';
  $('#BILLING_ACTIMES_Content').html(html);
  $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  signatureDATA.data = '';
  signatureDATA.text = '';
}

function displayUpdatedAircraftTimes_Traxxall(ident, json_data, signature_text, signature_data) {
  var html = '<h3>' + ident + ' ' + iTrans('Updated Successfully') + '</h3>';

  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Updated Value') +
    '</th></tr></thead><tbody>';
  var Actuals = json_data.UPDATE.Actuals;
  for (var i in Actuals) {
    var I = Actuals[i];
    if (I.isCurrent === false || I.isCurrent == 'false' || I.isSpare === true || I.isSpare == 'true') continue;
    var vhtml = '';
    for (var ii in I.values) {
      var V = I.values[ii];
      if (V.isReadOnlyUom === true) continue;
      vhtml += V.value + ' ' + V.unitOfMeasure + '<br />';
    }
    html += '<tr><td>' + I.majorAssemblyDescription + '</td><td>' + vhtml + '</td></tr>';
  }
  html += '</tbody></table>';

  html += '<center><img src="' + signature_data + '" width="321px" height="150px"/><br />';
  html += '<b>' + signature_text + '</b></center>';
  $('#BILLING_ACTIMES_Content').html(html);
  $('#Billing_CompleteAircraftTimes').prop('disabled', false);
  signatureDATA.data = '';
  signatureDATA.text = '';
  CURRENT_FLIGHT_REPORT.TraxxallUpdated = true;
}

function UTDE_AddValue(e, index, type) {
  var value = parseFloat(e.value);
  var id = e.id;
  var E = AC_TECH_DISPATCH.RAW.Aircraft.AircraftEquipmentTimes[index];
  var Update = AC_TECH_DISPATCH.UPDATE.AircraftEquipmentTimes[index];
  var Total;
  //console.log(e.id + " " + index + " " + type)
  switch (type) {
    case 'Cycles':
      Total = parseFloat(E.Cycles);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#' + id + '_Total').html(Total);
      Update.Cycles = Total;
      break;
    case 'Hours':
      Total = parseFloat(E.Hours);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#' + id + '_Total').html(Total);
      Update.Hours = Total;
      break;
    case 'C1C':
      Total = parseFloat(E.C1C);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#' + id + '_Total').html(Total);
      Update.C1C = Total;
      break;
    case 'C2C':
      Total = parseFloat(E.C2C);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#' + id + '_Total').html(Total);
      Update.C2C = Total;
      break;
  }
}

function UTD_AddValue(e) {
  var value = parseFloat(e.value);
  var AC = AC_TECH_DISPATCH.RAW.Aircraft;
  var Update = AC_TECH_DISPATCH.UPDATE;
  var Total;
  switch (e.id) {
    case 'UTD_Landings_Input':
      Total = parseFloat(AC.Landings);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#UTD_Landings').html(Total);
      Update.Landings = Total;
      break;
    case 'UTD_RIN_Input':
      Total = parseFloat(AC.RIN);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#UTD_RIN').html(Total);
      Update.RIN = Total;
      break;
    case 'UTD_Time_Input':
      Total = parseFloat(AC.TotalTime);
      if (isNaN(Total)) Total = 0;
      Total += value;
      $('#UTD_Time').html(Total);
      Update.TotalTime = Total;
      break;
  }
}

function BILLING_popFlightReport(I, newDate = false) {
  //Lock Items?
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
  });
  BILLING_FLIGHTLEGS = []; //Epmpty flighlegs new flight report
  if (I.Cfields != undefined) {
    CURRENT_FLIGHT_REPORT.Cfields = JSON.parse(I.Cfields);
  }
  $('#BILLING_FP_EntryTimezone').val(I.EntryTimezone).selectmenu('refresh');
  $('#BILLING_FP_StartTimezone').val(I.StartTimezone).selectmenu('refresh');
  $('#BILLING_FP_EndTimezone').val(I.EndTimezone).selectmenu('refresh');
  CURRENT_FLIGHT_REPORT.HOBBS = I.HOBBS;
  CURRENT_FLIGHT_REPORT.Pax_Manifest = I.Pax_Manifest;
  CURRENT_FLIGHT_REPORT.Pilot_PK = I.Pilot_PK;
  CURRENT_FLIGHT_REPORT.SIC_PK = I.SIC_PK;
  var date = I.filed;
  date = date.split(' ');
  var dateObj = new Date(date[0] + 'T' + date[1] + 'Z');
  if (newDate) {
    var now = new Date();
    var timezone = now.getTimezoneOffset();
    now.setMinutes(now.getMinutes() - timezone);
    $('#BILLING_FPdate').val(now.toISOString().slice(0, 10));
  } else {
    var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    var date = ('0' + dateObj.getDate()).slice(-2);
    var year = dateObj.getFullYear();
    var shortDate = year + '-' + month + '-' + date;
    $('#BILLING_FPdate').val(shortDate);
  }
  CURRENT_FLIGHT_REPORT.Date = shortDate;
  CURRENT_FLIGHT_REPORT.Job = BILLING_GetJobData(I.JobClientID + '|' + I.JobID);

  $('#BILLING_FPjob')
    .val(I.JobClientID + '|' + I.JobID)
    .selectmenu('refresh', true); //select job dropdown select
  if (I.JobID == 0) {
    $('#BILLING_FPjob-button').addClass('redbg');
  } else {
    $('#BILLING_FPjob-button').removeClass('redbg');
  }

  BILLING_checkAcIdent_and_select(I.ident); //Also will trigger pax manifesting

  $('#BILLING_FPac').selectmenu('disable');

  BILLING_checkPIC_and_select(I.pilot);
  //$("#BILLING_FPpilot").val(I.pilot).selectmenu('refresh', true); //select pilot from dropdown
  BILLING_checkSIC_and_select(I.sic);

  //$("#BILLING_FPSIC").val(I.sic).selectmenu('refresh', true); //select SIC from dropdown
  BILLING_POP_CREW();
  BILLING_POP_FuelUplift();
  $('#BILLING_FPpax').val(I.paxnum);
  if (I.TDG == 1) {
    $('#BILLING_TDGOFF').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BILLING_TDGOFF').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_TDGON').prop('checked', false).checkboxradio('refresh');
  }
  if (I.FuelProvided == 1) $('#BILLING_FUEL').prop('checked', true).checkboxradio('refresh');
  else $('#BILLING_FUEL').prop('checked', false).checkboxradio('refresh');

  $('#BILLING_FPdetails').val(br2nl(I.Details));
  if (I.Cfields == null || I.Cfields == '' || I.Cfields == '[]') {
    BILLING_POP_CF(I.JobClientID + '|' + I.JobID); //populate custom fields default company only
  } else {
    BILLING_POP_EDIT_CF(JSON.parse(I.Cfields));
    if (BILLING_GetJobData(I.JobClientID + '|' + I.JobID).BillBy == 'HourPR') $('#BILLING_HOURPR_HLDR').show();
    else $('#BILLING_HOURPR_HLDR').hide();
  }
  var totalPax = 0;
  for (var i in I.WB) {
    var L = I.WB[i];
    if (L.LegStartE == 0 && L.LegEndE == 0) {
      L.LegStartE = L.LegStart;
      L.LegEndE = L.LegEnd;
    }
    if (L.Cfields == null || L.Cfields == '' || L.Cfields == '[]') {
      var LegCfields = undefined;
    } else {
      var LegCfields = JSON.parse(L.Cfields);
    }
    var Pilot_PK = CURRENT_FLIGHT_REPORT.Pilot_PK;
    try {
      Pilot_PK = LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === L.Pilot && x.staffType === 'Pilot').PrimaryKey;
    } catch (e) {
      console.log(e);
    }

    var SIC_PK = CURRENT_FLIGHT_REPORT.SIC_PK;
    try {
      if (L.sic != '') {
        SIC_PK = LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === L.sic && x.staffType === 'Pilot').PrimaryKey;
      }
    } catch (e) {
      console.log(e);
    }
    if (CURRENT_FLIGHT_REPORT.Pax_Manifest.Pax_Legs[L.LegNum] == undefined) {
      CURRENT_FLIGHT_REPORT.Pax_Manifest.Pax_Legs[L.LegNum] = [];
    }
    BILLING_FLIGHTLEGS.push({
      PrimaryKey: 'None',
      Date: CURRENT_FLIGHT_REPORT.Date,
      Pilot_PK: Pilot_PK,
      SIC_PK: SIC_PK,
      flightCatID: L.flightCatID,
      Time_Start: L.LegStartE,
      Time_Up: L.LegStart,
      Time_Down: L.LegEnd,
      Time_Stop: L.LegEndE,
      Details_Up: L.LegOrigin,
      Details_Down: L.LegDest,
      noShutdown: L.noShutdown,
      Description: br2nl(L.Description),
      Cfields: LegCfields,
      PaxNum: L.PaxNum,
      cargoWeight: L.CargoWeight,
      Billable: 1,
      BillByVAL: 0,
    });
    totalPax += parseInt(L.PaxNum);
  }
  CURRENT_FLIGHT_REPORT.Passengers = totalPax;
  CURRENT_FLIGHT_REPORT.EntryTimezone = I.EntryTimezone;
  CURRENT_FLIGHT_REPORT.StartTimezone = I.StartTimezone;
  CURRENT_FLIGHT_REPORT.EndTimezone = I.EndTimezone;
  CURRENT_FLIGHT_REPORT.Legs = BILLING_FLIGHTLEGS;
  populateBillingHobbs(I);
  BILLING_checkTechDispatch();
}

function BILLING_Load_Itin_Legs(itinPK) {
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=getItinLegs&itinPK=' + itinPK,
    data: null,
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot get dut times') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'BI_Get_DutyTimes');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          if (USERTYPE != 'Guest') {
            var promptText =
              iTrans('This will overwrite all current Legs entered for this Flight Report.') +
              '<br /><center><h3>' +
              iTrans('Are you sure?') +
              '</h3></center>';

            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Confirm'),
              headerClose: false,
              buttonPrompt: promptText,
              buttons: {
                Yes: {
                  click: function () {
                    BILLING_Replace_Legs(json_data[0].Itin);
                  },
                },
                No: {
                  click: function () {},
                },
              },
            });
          }
        }
      }
    })
    .fail(function (data) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a Retrieving your Itinerary data to populate your legs.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_Replace_Legs(I) {
  console.log(I);
  BILLING_FLIGHTLEGS = [];
  var totalPax = 0;
  for (var i in I.WB) {
    var L = I.WB[i];
    if (L.LegStartE == 0 && L.LegEndE == 0) {
      L.LegStartE = L.LegStart;
      L.LegEndE = L.LegEnd;
    }
    if (L.Cfields == null || L.Cfields == '') {
      var LegCfields = undefined;
    } else {
      var LegCfields = JSON.parse(L.Cfields);
    }
    BILLING_FLIGHTLEGS.push({
      PrimaryKey: 'None',
      Date: CURRENT_FLIGHT_REPORT.Date,
      Pilot_PK: CURRENT_FLIGHT_REPORT.Pilot_PK,
      SIC_PK: CURRENT_FLIGHT_REPORT.SIC_PK,
      flightCatID: L.flightCatID,
      Time_Start: L.LegStartE,
      Time_Up: L.LegStart,
      Time_Down: L.LegEnd,
      Time_Stop: L.LegEndE,
      Details_Up: L.LegOrigin,
      Details_Down: L.LegDest,
      noShutdown: L.noShutdown,
      Description: br2nl(L.Description),
      Cfields: LegCfields,
      PaxNum: L.PaxNum,
      cargoWeight: L.CargoWeight,
      Billable: 1,
      BillByVAL: 0,
    });
    totalPax += parseInt(L.PaxNum);
  }
  CURRENT_FLIGHT_REPORT.Passengers = totalPax;
  CURRENT_FLIGHT_REPORT.EntryTimezone = I.EntryTimezone;
  CURRENT_FLIGHT_REPORT.StartTimezone = I.StartTimezone;
  CURRENT_FLIGHT_REPORT.EndTimezone = I.EndTimezone;
  CURRENT_FLIGHT_REPORT.Legs = BILLING_FLIGHTLEGS;
  BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm);

  BILLING_NewFlightLeg('Remove');
}

function BILLING_update_noShutdown_Inputs() {
  for (var index in BILLING_FLIGHTLEGS) {
    var L = BILLING_FLIGHTLEGS[index];
    if (CURRENT_FLIGHT_REPORT.BlockTime == 0) {
      $('#FLU2_StartHLDR-' + (parseInt(index) + 1)).hide();
      $('#FLU2_StopHLDR-' + index).hide();
      continue;
    }
    if (L.noShutdown == 1) {
      $('#FLU2_StopHLDR-' + index).hide();
      if (BILLING_FLIGHTLEGS[parseInt(index) + 1] != undefined) {
        $('#FLU2_StartHLDR-' + (parseInt(index) + 1)).hide();
      } else {
        $('#FLU2_StartHLDR-' + (parseInt(index) + 1)).show();
      }
    } else {
      $('#FLU2_StopHLDR-' + index).show();
      if (BILLING_FLIGHTLEGS[parseInt(index) + 1] != undefined) {
        $('#FLU2_StartHLDR-' + (parseInt(index) + 1)).show();
      }
    }
  }
}

function BILLING_ChangeEntryTimezone() {
  BILLING_ChangeEntryTimezoneTo(this.value);
}

function BILLING_ChangeEntryTimezoneTo(newTimezone) {
  console.log(newTimezone);

  if (CURRENT_FLIGHT_REPORT.EntryTimezone != undefined) {
    if (CURRENT_FLIGHT_REPORT.Legs != undefined) {
      if (CURRENT_FLIGHT_REPORT.Legs.length > 0) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Timezone Change',
          headerClose: false,
          buttonPrompt:
            iTrans('Changing Timezone From') +
            ': ' +
            CURRENT_FLIGHT_REPORT.EntryTimezone +
            ' ' +
            iTrans('To') +
            ': ' +
            newTimezone,
          buttons: {
            [iTrans('Convert Leg Times')]: {
              click: function () {
                BILLING_ConvertLegs_to_new_Timezone(newTimezone, CURRENT_FLIGHT_REPORT.EntryTimezone);
                CURRENT_FLIGHT_REPORT.EntryTimezone = newTimezone;
              },
            },
            [iTrans('Do Not Change Legs')]: {
              click: function () {
                CURRENT_FLIGHT_REPORT.EntryTimezone = newTimezone;
                return;
              },
            },
            [iTrans('Cancel')]: {
              click: function () {
                $('#BILLING_FP_EntryTimezone').val(CURRENT_FLIGHT_REPORT.EntryTimezone).selectmenu('refresh');
              },
            },
          },
        });
        return;
      }
    }
  }
  CURRENT_FLIGHT_REPORT.EntryTimezone = newTimezone;
}

function BILLING_ConvertLegs_to_new_Timezone(newTimezone, oldTimezone) {
  for (var i in CURRENT_FLIGHT_REPORT.Legs) {
    var I = CURRENT_FLIGHT_REPORT.Legs[i];
    var TStart = adjustTimezone(I.Date, I.Time_Start, newTimezone, oldTimezone, 0);
    var TUp = adjustTimezone(I.Date, I.Time_Up, newTimezone, oldTimezone, 0);
    var TDown = adjustTimezone(I.Date, I.Time_Down, newTimezone, oldTimezone, 0);
    var TStop = adjustTimezone(I.Date, I.Time_Stop, newTimezone, oldTimezone, 0);
    I.Date = TStart.date;
    I.Time_Start = TStart.time;
    I.Time_Up = TUp.time;
    I.Time_Down = TDown.time;
    I.Time_Stop = TStop.time;
  }
}

function BILLING_checkAcIdent_and_select(ident) {
  var acidentexists = false;
  $('#BILLING_FPac').each(function () {
    if (this.value == ident) {
      acidentexists = true;
      return false;
    }
  });
  if (acidentexists === false) {
    $('#BILLING_FPac').append($('<option />').val(ident).text(ident));
  }
  $('#BILLING_FPac').val(ident);
  $('#BILLING_FPac').selectmenu('refresh');
  BILLING_setPaxManIdent();
}

function BILLING_checkPIC_and_select(PIC) {
  var PICexists = false;
  $('#BILLING_FPpilot').each(function () {
    if (this.value == PIC) {
      PICexists = true;
      return false;
    }
  });
  if (PICexists === false) {
    $('#BILLING_FPpilot').append($('<option />').val(PIC).text(PIC));
  }
  $('#BILLING_FPpilot').val(PIC);
  $('#BILLING_FPpilot').selectmenu('refresh');
  BILLING_SelectFR_Crew(PIC, null);
}

function BILLING_checkSIC_and_select(SIC) {
  if (SIC == '') {
    BILLING_SelectFR_Crew(null, SIC);
    return;
  }
  var SICexists = false;
  $('#BILLING_FPSIC').each(function () {
    if (this.value == SIC) {
      SICexists = true;
      return false;
    }
  });
  if (SICexists === false) {
    $('#BILLING_FPSIC').append($('<option />').val(SIC).text(SIC));
  }
  BILLING_SelectFR_Crew(null, SIC);
}

function BILLING_checkTechDispatch() {
  //set default
  AC_data = {
    TechDispatch: 1,
  };
  if (GROUP_DATA.Flightdocs == 0 && GROUP_DATA.WinAir == 0) return;
  var ident = $('#BILLING_FPac').val();
  if (ident == '') return;
  cirroDB.query('AC', 'ident = ?', ident, function (e) {
    AC_data = e[0].AC_data;
    if (AC_data == null || AC_data.TechDispatch == null) {
      AC_data.TechDispatch = 1; //for users that don't have this new flag set;
    }
  });
}

function getCurrentAircraftHobbs() {
  if (HOBBS == 0) return;
  var ReportDate = $('#BILLING_FPdate').val();
  var ReportTime = $('#HobbsUtime').val();
  if (ReportTime == undefined) {
    ReportTime = new Date().toTimeString().split(' ')[0];
  }
  var ident = $('#BILLING_FPac').val();
  if (ident == '') return;
  var DateTime = stringToDateObject(ReportDate + ' ' + ReportTime);
  DateTime.setMinutes(DateTime.getTimezoneOffset() + DateTime.getMinutes());
  DateTime = DateTime.toISOString().substring(0, 19);

  var FRPK = CURRENT_FLIGHT_REPORT.PrimaryKey;
  if (FRPK == undefined) FRPK = 0;
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getCurrentAChobbs&ident=' +
      encodeURIComponent(ident) +
      '&DateTime=' +
      encodeURIComponent(DateTime) +
      '&FRPK=' +
      FRPK,
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Invoices') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminInvoicing');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          if (json_data[0].ServerResponse == 'Success') {
            CURRENT_FLIGHT_REPORT.HOBBS = json_data[0].HOBBS;
            populateBillingHobbs(json_data[0]);
          }
        }
      }
    }
  ).fail(function (jqXHR, status, error) {
    //Fail silently and populate with offline holders
    $.mobile.loading('hide');
    populateBillingHobbs(null);
  });
}

var BILLING_HOBBS_CHANGE_TIME = false;

function populateBillingHobbs(FR) {
  if (HOBBS == 0) return;
  var OfflineHobbs = false;

  if ($('#HobbsUtime').val() == undefined) {
    var html =
      '<h3>' +
      iTrans('Hobbs Readings') +
      "</h3><table class='limit-table'><thead><tr><th>" +
      iTrans('Meter') +
      '</th><th>' +
      iTrans('Start') +
      '</th><th>' +
      iTrans('End') +
      '</th><th>' +
      iTrans('Total') +
      '</th></tr></thead><tbody>';

    var now = new Date().toTimeString().split(' ')[0];
    now = ''; //Use a blank local time for the Hobbs Reading
    html +=
      '<label for="HobbsUtime">' +
      iTrans('Local Time When Hobbs Readings Was Taken') +
      '</label><input type="time" id="HobbsUtime" value="' +
      now +
      '" onChange="BILLING_HOBBS_CHANGE_TIME = true; getCurrentAircraftHobbs();"/>';

    html +=
      '<div id="OfflineHobbsDialog"><h3>' +
      iTrans(
        'Please Note: If there is an unreported flight prior to this report the billable Hobbs time will not be accurate until the previous flight Hobbs reading has been reported.  If you make a mistake you can edit this reported reading in the future.'
      ) +
      '</h3></div>';
    html += '<h3 id="HobbsTimestamp"></h3>';
    html +=
      '<tr><td>' +
      iTrans('Flight Hobbs') +
      '</td><td><input type="number" id="BILLING_HOBBS_FLIGHT_START" disabled /></td><td><input type="number" id="BILLING_HOBBS_FLIGHT_END" onChange="updateBillingHobbs()" /></td><td><div id="BILLING_FLIGHT_TOTAL"></div></td></tr>';
    html +=
      '<tr><td>' +
      iTrans('Maint Hobbs') +
      '</td><td><input type="number" id="BILLING_HOBBS_MAINT_START" disabled /></td><td><input type="number" id="BILLING_HOBBS_MAINT_END"  onChange="updateBillingHobbs()" /></td><td><div id="BILLING_MAINT_TOTAL"></div></td></tr>';
    html += '</tbody></table></div>';
    if (
      USER_RIGHTS.SuperUser ||
      window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, 'billing.flightReports.administrate')
    ) {
      html +=
        '<button data-mini="true" data-icon="edit" class="redbg" onClick="BILLING_EnterHobbsCorrection()">' +
        iTrans('Correct HOBBS Start Times') +
        '</button>';
    }
    $('#BILLING_HOBBS').html(html);
    $('#BILLING_HOBBS').enhanceWithin();
  }

  if (FR == null) {
    OfflineHobbs = true;
    $('#OfflineHobbsDialog').html(
      '<h3>' +
        iTrans(
          'WARNING: Please be aware the current Hobbs meter times could not be retrieved from the server.  Total billable Hobbs times calculation will not be accurate upon initial Flight Report creation.'
        )
    );
    var ReportDate = $('#BILLING_FPdate').val();
    var ReportTime = $('#HobbsUtime').val();
    var DateTime = stringToDateObject(ReportDate + ' ' + ReportTime);
    DateTime.setMinutes(DateTime.getTimezoneOffset() + DateTime.getMinutes());
    DateTime = DateTime.toISOString().substring(0, 19);

    var FRHOBBS = {
      Primary: {
        PrevReading: {
          PrimaryKey: 0,
          group: LOCALSTORAGE.GROUP_DATA.group,
          Type: 'Primary',
          AC_PK: 0,
          User_PK: 0,
          Job_ID: 0,
          DateTime: DateTime,
          Total: 0,
          Comment: '',
        },
        Reported: {
          PrimaryKey: 0,
          group: LOCALSTORAGE.GROUP_DATA.group,
          Type: 'Primary',
          AC_PK: 0,
          User_PK: 0,
          Job_ID: 0,
          DateTime: DateTime,
          Total: 0,
          Comment: '',
        },
      },
      Secondary: {
        PrevReading: {
          PrimaryKey: 0,
          group: LOCALSTORAGE.GROUP_DATA.group,
          Type: 'Secondary',
          AC_PK: 0,
          User_PK: 0,
          Job_ID: 0,
          DateTime: DateTime,
          Total: 0,
          Comment: '',
        },
        Reported: {
          PrimaryKey: 0,
          group: LOCALSTORAGE.GROUP_DATA.group,
          Type: 'Secondary',
          AC_PK: 0,
          User_PK: 0,
          Job_ID: 0,
          DateTime: DateTime,
          Total: 0,
          Comment: '',
        },
      },
    };
    CURRENT_FLIGHT_REPORT.HOBBS = FRHOBBS;
    FR = {
      HOBBS: FRHOBBS,
    };
    $('#HobbsTimestamp').html(iTrans('Last Hobbs Timestamp') + ': Unknown');
  } else {
    if (FR.HOBBS.Primary.Reported.PrimaryKey == 0) {
      var HD = moment(stringToDateObject(FR.HOBBS.Primary.PrevReading.DateTime));
      $('#HobbsTimestamp').html(
        iTrans('Last Hobbs Timestamp') +
          ': ' +
          HD.format('YYYY-MM-DD hh:mm a') +
          ' ' +
          (FR.HOBBS.Primary.PrevReading.Job_ID != 0
            ? iTrans('From') + ' FR#' + FR.HOBBS.Primary.PrevReading.Job_ID
            : iTrans(FR.HOBBS.Primary.PrevReading.Comment))
      );
    } else {
      var HD = moment(stringToDateObject(FR.HOBBS.Primary.PrevReading.DateTime));
      $('#HobbsTimestamp').html(
        iTrans('Current Hobbs Timestamp') +
          ': ' +
          HD.format('YYYY-MM-DD hh:mm a') +
          ' ' +
          (FR.HOBBS.Primary.PrevReading.Job_ID != 0
            ? iTrans('From') + ' FR#' + FR.HOBBS.Primary.PrevReading.Job_ID
            : iTrans(FR.HOBBS.Primary.PrevReading.Comment))
      );
    }
  }
  if (BILLING_HOBBS_CHANGE_TIME == false) {
    $('#BILLING_HOBBS_FLIGHT_START').val(FR.HOBBS.Primary.PrevReading.Total);
    $('#BILLING_HOBBS_FLIGHT_END').val(FR.HOBBS.Primary.Reported.Total);

    $('#BILLING_HOBBS_MAINT_START').val(FR.HOBBS.Secondary.PrevReading.Total);
    $('#BILLING_HOBBS_MAINT_END').val(FR.HOBBS.Secondary.Reported.Total);
  }

  if (FR.HOBBS.Primary.Reported.PrimaryKey != 0) {
    var Updated = stringToDateObject(FR.HOBBS.Primary.Reported.DateTime).toTimeString().split(' ')[0];
    //console.log(Updated);
    if (BILLING_HOBBS_CHANGE_TIME == false) $('#HobbsUtime').val(Updated);
    $('#HobbsUtime').parent().removeClass('redbg');
  } else {
    if ($('#HobbsUtime').val() == '') {
      $('#HobbsUtime').parent().addClass('redbg');
    } else {
      $('#HobbsUtime').parent().removeClass('redbg');
    }
  }
  BILLING_HOBBS_CHANGE_TIME = false;
  updateBillingHobbs();
}

function BILLING_EnterHobbsCorrection() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Select Hobbs',
    headerClose: false,
    buttonPrompt: iTrans('Which HOBBS reading would you like to correct?'),
    buttons: {
      'Flight HOBBS Start': {
        click: function () {
          BILLING_EnterHobbsCorrection_Sel(true);
        },
      },
      'Maint HOBBS Start': {
        click: function () {
          BILLING_EnterHobbsCorrection_Sel(false);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

var HobbsEditRecord = null;

function BILLING_EnterHobbsCorrection_Sel(Primary) {
  if (Primary) {
    HobbsEditRecord = CURRENT_FLIGHT_REPORT.HOBBS.Primary.PrevReading;
  }
  if (!Primary) {
    HobbsEditRecord = CURRENT_FLIGHT_REPORT.HOBBS.Secondary.PrevReading;
  }
  var oldValue = HobbsEditRecord.Total;
  var html = '';
  html += '<label for="HOBBS_NEW_VALUE">' + iTrans('New Start Value') + '</label>';
  html += '<input type="number" id="HOBBS_NEW_VALUE" data-mini="true" value="' + HobbsEditRecord.Total + '"/>';
  html +=
    `<label for="HOBBS_NEW_VALUE_UPDATE">` +
    iTrans('Update') +
    ' ' +
    (HobbsEditRecord.Job_ID == 0
      ? HobbsEditRecord.Comment + ' ' + iTrans('Time')
      : 'FR#' +
        HobbsEditRecord.Job_ID +
        ' ' +
        (Primary ? iTrans('Flight End') : iTrans('Maint End')) +
        ' ' +
        iTrans('Time')) +
    `</label>
<input type="radio" value="overwrite" id="HOBBS_NEW_VALUE_UPDATE" name="HOBBS_NEW_VALUE_RADIO" checked />
<label for="HOBBS_NEW_VALUE_CORRECT">` +
    iTrans('Add HOBBS Correction') +
    `</label>
<input type="radio" value="addNew" id="HOBBS_NEW_VALUE_CORRECT" name="HOBBS_NEW_VALUE_RADIO" ` +
    (HobbsEditRecord.Job_ID == 0 ? 'disabled' : '') +
    `/>`;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Update HOBBS',
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      Update: {
        click: function () {
          var newValue = round10(parseFloat($('#HOBBS_NEW_VALUE').val()));
          var updateType = $("input:radio[name ='HOBBS_NEW_VALUE_RADIO']:checked").val();
          HobbsEditRecord.Total = newValue;
          $.getJSON(
            BASEURL +
              'menu-Billing-AJAX.php?action=correctHobbsFR&newTotal=' +
              newValue +
              '&HobbsPK=' +
              HobbsEditRecord.PrimaryKey +
              '&type=' +
              updateType,
            function (json_data) {
              if (json_data[0].ServerResponse == 'Success') {
                populateBillingHobbs(CURRENT_FLIGHT_REPORT);
              } else {
                HobbsEditRecord.Total = oldValue;
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('ERROR'),
                  headerClose: false,
                  buttonPrompt: iTrans('Failed to Send Hobbs Correction.  Please Try Again.'),
                  buttons: {
                    [iTrans('Close')]: {
                      click: function () {},
                    },
                  },
                });
              }
            }
          ).fail(function (jqXHR, status, error) {
            HobbsEditRecord.Total = oldValue;
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('ERROR'),
              headerClose: false,
              buttonPrompt: iTrans('Failed to Send Hobbs Correction.  Please Try Again.'),
              buttons: {
                [iTrans('Close')]: {
                  click: function () {},
                },
              },
            });
          });
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

var currentHOBBS = {
  P_Total: 0,
  S_Total: 0,
};

function updateBillingHobbs() {
  if (HOBBS == 0) return;
  var FlightTotal =
    Math.round(
      (parseFloat($('#BILLING_HOBBS_FLIGHT_END').val()) - parseFloat($('#BILLING_HOBBS_FLIGHT_START').val())) * 10
    ) / 10;
  var MaintTotal =
    Math.round(
      (parseFloat($('#BILLING_HOBBS_MAINT_END').val()) - parseFloat($('#BILLING_HOBBS_MAINT_START').val())) * 10
    ) / 10;
  if (FlightTotal < 0) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Flight Hobbs Error'),
      headerClose: false,
      buttonPrompt:
        iTrans('Flight Hobbs [End] Time must be larger than [Start] Time.') +
        '<br />' +
        iTrans('The time you entered results in') +
        ': <b>' +
        FlightTotal +
        '</b> ' +
        iTrans('Hobbs hours') +
        '.',
      buttons: {
        [iTrans('OK')]: {
          click: function () {
            $('#BILLING_HOBBS_FLIGHT_END').val($('#BILLING_HOBBS_FLIGHT_START').val());
            $('#BILLING_HOBBS_FLIGHT_END').focus();
            $('#BILLING_HOBBS_FLIGHT_END').select();
          },
        },
      },
    });
    return;
  }
  if (FlightTotal > 20) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Flight Hobbs Warning'),
      headerClose: false,
      buttonPrompt:
        iTrans('Total Flight Time is') +
        ': <b>' +
        FlightTotal +
        '</b><br />' +
        iTrans(
          'This seems really high and indicative of one or more previously unreported itinerary that need to be completed. Please ensure all previous flight reports have been completed before having a client sign this report.'
        ),
      buttons: {
        [iTrans('OK')]: {
          click: function () {},
        },
      },
    });
  }
  if (MaintTotal < 0) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Maint Hobbs Error',
      headerClose: false,
      buttonPrompt:
        iTrans('Maint Hobbs [End] Time must be larger than [Start] Time.') +
        '<br />' +
        iTrans('The time you entered results in') +
        ': <b>' +
        MaintTotal +
        '</b> ' +
        iTrans('Hobbs hours') +
        '.',
      buttons: {
        [iTrans('OK')]: {
          click: function () {
            $('#BILLING_HOBBS_MAINT_END').val($('#BILLING_HOBBS_MAINT_START').val());
            $('#BILLING_HOBBS_MAINT_END').focus();
            $('#BILLING_HOBBS_MAINT_END').select();
          },
        },
      },
    });
    return;
  }
  if (MaintTotal > 20) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Maint Hobbs Warning',
      headerClose: false,
      buttonPrompt:
        iTrans('Total Maint Time is') +
        ': <b>' +
        MaintTotal +
        '</b><br />' +
        iTrans(
          'This seems really high and indicative of one or more previously unreported itinerary that need to be completed. Please ensure all previous flight reports have been completed before having a client sign this report.'
        ),
      buttons: {
        OK: {
          click: function () {},
        },
      },
    });
  }
  $('#BILLING_FLIGHT_TOTAL').html(FlightTotal);
  $('#BILLING_MAINT_TOTAL').html(MaintTotal);

  var H = CURRENT_FLIGHT_REPORT.HOBBS;
  var ReportDate = $('#BILLING_FPdate').val();
  var ReportTime = $('#HobbsUtime').val();
  var DateTime = stringToDateObject(ReportDate + ' ' + ReportTime);
  DateTime.setMinutes(DateTime.getTimezoneOffset() + DateTime.getMinutes());
  try {
    DateTime = DateTime.toISOString().substring(0, 19);
  } catch (e) {
    // Ignore conversion errors
    DateTime = null;
  }
  if (H.Primary == undefined) H.Primary = BILLING_getHobbsDefault('Primary');
  if (H.Secondary == undefined) H.Secondary = BILLING_getHobbsDefault('Secondary');
  H.Primary.Reported.Total = round10($('#BILLING_HOBBS_FLIGHT_END').val());
  H.Primary.Reported.DateTime = DateTime;
  H.Secondary.Reported.Total = round10($('#BILLING_HOBBS_MAINT_END').val());
  H.Secondary.Reported.DateTime = DateTime;
}

function BILLING_getHobbsDefault(type) {
  var DateTime = stringToDateObject(ReportDate + ' ' + ReportTime);
  DateTime.setMinutes(DateTime.getTimezoneOffset() + DateTime.getMinutes());
  DateTime = DateTime.toISOString().substring(0, 19);
  var Primary = {
    PrevReading: {
      PrimaryKey: 0,
      group: LOCALSTORAGE.GROUP_DATA.group,
      Type: 'Primary',
      AC_PK: 0,
      User_PK: 0,
      Job_ID: 0,
      DateTime: DateTime,
      Total: 0,
      Comment: '',
    },
    Reported: {
      PrimaryKey: 0,
      group: LOCALSTORAGE.GROUP_DATA.group,
      Type: 'Primary',
      AC_PK: 0,
      User_PK: 0,
      Job_ID: 0,
      DateTime: DateTime,
      Total: 0,
      Comment: '',
    },
  };
  var Secondary = {
    PrevReading: {
      PrimaryKey: 0,
      group: LOCALSTORAGE.GROUP_DATA.group,
      Type: 'Secondary',
      AC_PK: 0,
      User_PK: 0,
      Job_ID: 0,
      DateTime: DateTime,
      Total: 0,
      Comment: '',
    },
    Reported: {
      PrimaryKey: 0,
      group: LOCALSTORAGE.GROUP_DATA.group,
      Type: 'Secondary',
      AC_PK: 0,
      User_PK: 0,
      Job_ID: 0,
      DateTime: DateTime,
      Total: 0,
      Comment: '',
    },
  };
  if (type == 'Primary') return Primary;
  if (type == 'Secondary') return Secondary;
}

function BILLING_SelectFRClientPrompt(JobPK, clientList, callback, setCFR = false) {
  var Clients = FLIGHT_REPORT_LISTS.Clients;
  var html = '<label for="BILLING_FRClientSelect">' + iTrans('Select Client For Global Job') + '</label>';
  html += '<select id="BILLING_FRClientSelect" data-mini="true">';

  var list = clientList.split(',');

  console.log('list', list);
  for (var i in Clients) {
    if (clientList == '') {
      html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
    } else {
      for (var c in list) {
        if (Clients[i].ClientPK == list[c]) {
          html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
        }
      }
    }
  }
  html += '</select>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Client'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          var C = Clients[$('#BILLING_FRClientSelect').val()];
          var JobID = C.PrimaryKey + '|' + JobPK;
          var I = BILLING_GetJobData(JobID);
          $('#BILLING_FPjob').append(
            $('<option />')
              .val(JobID)
              .text('[' + I.Business + '] - ' + I.Name + ' #' + I.JobNum)
          );
          $('#BILLING_FPjob').val(JobID).selectmenu('refresh');
          if (setCFR != false) {
            callback(JobID, setCFR);
          } else {
            callback(JobID);
          }
        },
      },
    },
  });
}

function BILLING_GetJobData(data, setCFR = false) {
  var Job = FLIGHT_REPORT_LISTS.Jobs[0];
  CURRENT_FLIGHT_REPORT.JobID = data;
  var parts = data.split('|');
  var JobPK = parts[1];
  var ClientPK = parts[0];
  var clientList = parts[2];
  if (clientList == undefined) {
    clientList = [];
  }

  if (ClientPK == -1) {
    BILLING_SelectFRClientPrompt(JobPK, clientList, BILLING_GetJobData, setCFR);
    return null;
  }
  if (JobPK != 0) {
    var foundJob = false;
    for (var f in FLIGHT_REPORT_LISTS.Jobs) {
      if (FLIGHT_REPORT_LISTS.Jobs[f].JobID == JobPK && FLIGHT_REPORT_LISTS.Jobs[f].ClientPK == ClientPK) {
        Job = JSON.parse(JSON.stringify(FLIGHT_REPORT_LISTS.Jobs[f]));
        foundJob = true;
        if ($('#BILLING_FPjob option[value="' + ClientPK + '|' + JobPK + '"]').length == 0) {
          $('#BILLING_FPjob').append(
            '<option value=' +
              ClientPK +
              '|' +
              JobPK +
              '>[' +
              FLIGHT_REPORT_LISTS.Jobs[f].Business +
              '] - ' +
              FLIGHT_REPORT_LISTS.Jobs[f].Name +
              '</option>'
          );
        }
        break;
      }
    }
    if (!foundJob) {
      if (CURRENT_FLIGHT_REPORT.Job.ClientPK == ClientPK && CURRENT_FLIGHT_REPORT.Job.JobID == JobPK) {
        if ($('#BILLING_FPjob option[value="' + ClientPK + '|' + JobPK + '"]').length == 0) {
          $('#BILLING_FPjob').append(
            '<option value=' +
              ClientPK +
              '|' +
              JobPK +
              '>[' +
              CURRENT_FLIGHT_REPORT.Job.Business +
              '] - ' +
              CURRENT_FLIGHT_REPORT.Job.Name +
              '</option>'
          );
        }
      } else {
        alert('Cannot Find Selected Job Data');
      }
    }
  }
  if (setCFR) {
    CURRENT_FLIGHT_REPORT.Job = Job;
    CURRENT_FLIGHT_REPORT.AlertList = Job.AlertList;
    BILLING_POP_CF(data);
    BILLING_paxManifest($('#BILLING_FPac').val(), -1);
    BILLING_POP_FuelUplift();
  }
  setJob_UI_Options(Job);
  return Job;
}

function setJob_UI_Options(J) {
  J.showTZ == 0 ? $('#BILLING_FP_EntryTimezoneHLDR').hide() : $('#BILLING_FP_EntryTimezoneHLDR').show();
  J.showStartEndTZ == 0 ? $('#BILLING_StartEndTZ_HLDR').hide() : $('#BILLING_StartEndTZ_HLDR').show();
  J.showTDG == 0 ? $('#BILLING_showTDG_HLDR').hide() : $('#BILLING_showTDG_HLDR').show();
  J.showClientFuel == 0 ? $('#BILLING_showClientFuel_HLDR').hide() : $('#BILLING_showClientFuel_HLDR').show();
}

function BILLING_CreateFlightLegs() {
  //console.log($("#BILLING_FPimaryKey").val());
  //Save or Update Flight Report Data
  var date = $('#BILLING_FPdate').val();
  var JobID = $('#BILLING_FPjob').val();
  var parts = JobID?.split('|') || [];
  var JobPK = parts[1];
  var ClientPK = parts[0];
  var Aircraft = $('#BILLING_FPac').val();

  //console.log(Aircraft);
  if (Aircraft == null) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt: iTrans('No Aircraft Selected!'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
      },
    });
    return;
  }
  var Pilot = $('#BILLING_FPpilot').val();
  if (Pilot == '') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt: iTrans('No Pilot Selected!'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
      },
    });
    return;
  }
  var SIC = $('#BILLING_FPSIC').val();
  var Pilot_PK = CURRENT_FLIGHT_REPORT.Pilot_PK;
  var SIC_PK = CURRENT_FLIGHT_REPORT.SIC_PK;

  var Passengers = $('#BILLING_FPpax').val();
  var BillByVAL = $('#BILLING_HOURPR').val();
  var Details = $('#BILLING_FPdetails').val();
  if (Details.length > 1024) {
    BillingValidation(iTrans('Flight details cannot be longer than 1024 characters'));
    return;
  }
  var TDG = 0;
  if ($('#BILLING_TDGON').is(':checked')) TDG = 1;
  var FuelProvided = 0;
  if ($('#BILLING_FUEL').is(':checked')) FuelProvided = 1;
  var Itin_ID = $('#BILLING_IPimaryKey').val();
  var PrimaryKey = $('#BILLING_FPimaryKey').val();
  if (CURRENT_FLIGHT_REPORT != undefined) {
    if ('Job' in CURRENT_FLIGHT_REPORT) {
      var Job = CURRENT_FLIGHT_REPORT.Job;
      var CF = CURRENT_FLIGHT_REPORT.Job.Cfields;
      JobPK = Job.JobID;
      ClientPK = Job.ClientPK;
    } else {
      var Job = BILLING_GetJobData(JobID);
      var CF = Job.Cfields;
      JobPK = Job.JobID;
      ClientPK = Job.ClientPK;
    }
  } else {
    var Job = BILLING_GetJobData(JobID);
    var CF = Job.Cfields;
    JobPK = Job.JobID;
    ClientPK = Job.ClientPK;
  }

  var PerReportFields = [];
  CURRENT_LEG_CFIELDS = [];
  var DisableNext = false;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != Aircraft && I.Aircraft !== '') {
        continue;
      }
      if (!checkCfieldType('FR', I)) continue;
      if (I.PerLeg == 0) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
          if (I.req == 1 && checked == 0) {
            BillingValidation('[' + I.Label + '] is required to be checked.');
            DisableNext = true;
          }
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
          //console.log("#BI_CF_" + I.PrimaryKey);
          //console.log(I.Value);
          //if (I.Value == undefined) continue; //could have been added after existing flight report was made
          if (I.Value == undefined) I.Value = '';
          if ((I.Value == '' && I.req == 1) || (I.Value == null && I.req == 1) || (I.Value == ' ' && I.req == 1)) {
            BillingValidation('[' + I.Label + '] is a required field and is empty.');
            DisableNext = true;
          }
        }
        PerReportFields.push(I);
      } else {
        CURRENT_LEG_CFIELDS.push(I);
      }
    }
  }
  if (DisableNext) return;
  //$("#Billing_CompleteFlightReportBTN").prop("disabled", DisableNext);
  var PerLegFields = [];
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 1) {
        var checked = 0;
        if (I.type == 'checkbox') {
          I.Value = checked;
        } else {
          I.Value = I.default;
        }
        PerLegFields.push({
          Archived: I.Archived,
          Bill_Item: I.Bill_Item,
          Bill_Item_options: I.Bill_Item_options,
          ClientPK: I.ClientPK,
          Label: I.Label,
          PerLeg: I.PerLeg,
          Placeholder: I.Placeholder,
          PrimaryKey: I.PrimaryKey,
          hideDefault: I.hideDefault,
          Value: I.Value,
          default: I.default,
          group: I.group,
          options: I.options,
          req: I.req,
          reqItin: I.reqItin,
          usedFor: I.usedFor,
          SortOrder: I.SortOrder,
          type: I.type,
        });
      }
    }
  }

  var HF = 0;
  var HM = 0;
  for (var L in BILLING_FLIGHTLEGS) {
    if (BILLING_FLIGHTLEGS[L].Cfields == undefined) {
      BILLING_FLIGHTLEGS[L].Cfields = PerLegFields;
    }
    if (BILLING_FLIGHTLEGS[L].BillByVAL == undefined) {
      BILLING_FLIGHTLEGS[L].BillByVAL = 0;
    }
    if (BILLING_FLIGHTLEGS[L].PrimaryKey == undefined) {
      BILLING_FLIGHTLEGS[L].PrimaryKey = 'None';
    }
  }
  if (BILLING_BACKUP_LEGS.length > 0) {
    BILLING_FLIGHTLEGS = JSON.parse(JSON.stringify(BILLING_BACKUP_LEGS));
    for (var l in BILLING_FLIGHTLEGS) {
      var L = BILLING_FLIGHTLEGS[l];
      if (L.Cfields == undefined) L.Cfields = [];
      for (var nc in PerLegFields) {
        var Exists = false;
        for (var c in L.Cfields) {
          if (PerLegFields[nc].PrimaryKey == L.Cfields[c].PrimaryKey) Exists = true;
        }
        if (!Exists) {
          L.Cfields.push(PerLegFields[nc]);
        }
      }
      var c = L.Cfields.length;
      while (c--) {
        var Exists = false;
        for (var nc in PerLegFields) {
          //console.log(PerLegFields[nc]);
          if (PerLegFields[nc].PrimaryKey == L.Cfields[c].PrimaryKey) Exists = true;
        }
        if (!Exists) {
          if (L.Cfields == undefined) L.Cfields = [];
          L.Cfields.splice(c, 1);
        }
      }
    }
    BILLING_BACKUP_LEGS = [];
  }
  var OfflineIndex = undefined;
  var HOBBS2 = undefined;
  var UID = new Date().getTime();
  var Attachments = [];
  var AlertList = [];
  if (CURRENT_FLIGHT_REPORT != undefined) {
    if ('OfflineIndex' in CURRENT_FLIGHT_REPORT) {
      OfflineIndex = CURRENT_FLIGHT_REPORT.OfflineIndex;
    }
    if ('HOBBS' in CURRENT_FLIGHT_REPORT) {
      HOBBS2 = CURRENT_FLIGHT_REPORT.HOBBS;
    }
    if ('UID' in CURRENT_FLIGHT_REPORT) {
      UID = CURRENT_FLIGHT_REPORT.UID;
    }
    if ('Attachments' in CURRENT_FLIGHT_REPORT) {
      Attachments = CURRENT_FLIGHT_REPORT.Attachments;
    }
    if ('AlertList' in CURRENT_FLIGHT_REPORT) {
      AlertList = CURRENT_FLIGHT_REPORT.AlertList;
    }
  }
  var wasCompleted = CURRENT_FLIGHT_REPORT.Completed;
  var FieldGear = [];
  if ('FieldGear' in CURRENT_FLIGHT_REPORT) {
    FieldGear = CURRENT_FLIGHT_REPORT.FieldGear;
  }
  var Pax_Manifest = null;
  if ('Pax_Manifest' in CURRENT_FLIGHT_REPORT) {
    Pax_Manifest = CURRENT_FLIGHT_REPORT.Pax_Manifest;
  }
  var operatorPK = 0;
  if ('operatorPK' in CURRENT_FLIGHT_REPORT) {
    operatorPK = CURRENT_FLIGHT_REPORT.operatorPK;
  }
  var WB = [];
  if ('WB' in CURRENT_FLIGHT_REPORT) {
    WB = CURRENT_FLIGHT_REPORT.WB;
  }
  CURRENT_FLIGHT_REPORT = {
    Date: date,
    Attachments: Attachments,
    AlertList: AlertList,
    JobPK: JobPK,
    ClientPK: ClientPK,
    JobNum: Job.JobNum,
    EntryTimezone: $('#BILLING_FP_EntryTimezone').val(),
    StartTimezone: $('#BILLING_FP_StartTimezone').val(),
    EndTimezone: $('#BILLING_FP_EndTimezone').val(),
    Aircraft: Aircraft,
    Pilot: Pilot,
    SIC: SIC,
    Pilot_PK: Pilot_PK,
    SIC_PK: SIC_PK,
    Passengers: Passengers,
    TDG: TDG,
    Itin_ID: Itin_ID,
    PrimaryKey: PrimaryKey,
    BillByVAL: BillByVAL,
    Details: Details,
    FuelProvided: FuelProvided,
    Cfields: PerReportFields,
    Job: Job,
    signature: signatureDATA.data,
    signed_by: signatureDATA.text,
    HobbsFlight: HF,
    HobbsMaint: HM,
    HobbsRecorded: 0,
    OfflineIndex: OfflineIndex,
    Crew: BILLING_FLIGHTREPORT_CREW,
    HOBBS: HOBBS2,
    UID: UID,
    Completed: wasCompleted,
    FieldGear: FieldGear,
    Pax_Manifest: Pax_Manifest,
    FC_Uplifts: FC_Uplift.upLiftList,
    custom_emails: CURRENT_FLIGHT_REPORT.custom_emails,
    WB: WB,
    operatorPK: operatorPK,
  };
  //TODO Disable next button

  if (HOBBS == 1 && ONLINE) {
    HF = $('#BILLING_HOBBS_FLIGHT_END').val();
    HM = $('#BILLING_HOBBS_MAINT_END').val();
    CURRENT_FLIGHT_REPORT = {
      Date: date,
      Attachments: Attachments,
      AlertList: AlertList,
      JobPK: JobPK,
      ClientPK: ClientPK,
      JobNum: Job.JobNum,
      EntryTimezone: $('#BILLING_FP_EntryTimezone').val(),
      StartTimezone: $('#BILLING_FP_StartTimezone').val(),
      EndTimezone: $('#BILLING_FP_EndTimezone').val(),
      Aircraft: Aircraft,
      Pilot: Pilot,
      SIC: SIC,
      Pilot_PK: Pilot_PK,
      SIC_PK: SIC_PK,
      Passengers: Passengers,
      TDG: TDG,
      Itin_ID: Itin_ID,
      PrimaryKey: PrimaryKey,
      BillByVAL: BillByVAL,
      Details: Details,
      FuelProvided: FuelProvided,
      Cfields: PerReportFields,
      Job: Job,
      signature: signatureDATA.data,
      signed_by: signatureDATA.text,
      HobbsFlight: HF,
      HobbsMaint: HM,
      HobbsRecorded: 1,
      OfflineIndex: OfflineIndex,
      Crew: BILLING_FLIGHTREPORT_CREW,
      HOBBS: HOBBS2,
      UID: UID,
      Completed: wasCompleted,
      FieldGear: FieldGear,
      Pax_Manifest: Pax_Manifest,
      FC_Uplifts: FC_Uplift.upLiftList,
      custom_emails: CURRENT_FLIGHT_REPORT.custom_emails,
      WB: WB,
      operatorPK: operatorPK,
    };
    if ($('#HobbsUtime').val() == '') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans('MISSING - Local Time When Hobbs Readings Was Taken.'),
        buttons: {
          [iTrans('OK')]: {
            click: function () {
              //do nothing
            },
          },
        },
      });
      return;
    }
    if (HF == $('#BILLING_HOBBS_FLIGHT_START').val() && HM == $('#BILLING_HOBBS_MAINT_START').val()) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans(
          'HOBBS Times have not been updated.  Are you sure you want to continue with 0hr flight times.'
        ),
        buttons: {
          [iTrans('Continue')]: {
            click: function () {
              BILLING_SendReportData();
            },
          },
          [iTrans('Cancel')]: {
            click: function () {
              //do nothing
            },
          },
        },
      });
    } else {
      BILLING_SendReportData();
    }
  } else {
    CURRENT_FLIGHT_REPORT = {
      Date: date,
      Attachments: Attachments,
      AlertList: AlertList,
      JobPK: JobPK,
      ClientPK: ClientPK,
      JobNum: Job.JobNum,
      EntryTimezone: $('#BILLING_FP_EntryTimezone').val(),
      StartTimezone: $('#BILLING_FP_StartTimezone').val(),
      EndTimezone: $('#BILLING_FP_EndTimezone').val(),
      Aircraft: Aircraft,
      Pilot: Pilot,
      SIC: SIC,
      Pilot_PK: Pilot_PK,
      SIC_PK: SIC_PK,
      Passengers: Passengers,
      TDG: TDG,
      Itin_ID: Itin_ID,
      PrimaryKey: PrimaryKey,
      BillByVAL: BillByVAL,
      Details: Details,
      FuelProvided: FuelProvided,
      Cfields: PerReportFields,
      Job: Job,
      signature: signatureDATA.data,
      signed_by: signatureDATA.text,
      HobbsFlight: HF,
      HobbsMaint: HM,
      HobbsRecorded: 0,
      OfflineIndex: OfflineIndex,
      Crew: BILLING_FLIGHTREPORT_CREW,
      HOBBS: HOBBS2,
      UID: UID,
      Completed: wasCompleted,
      FieldGear: FieldGear,
      Pax_Manifest: Pax_Manifest,
      FC_Uplifts: FC_Uplift.upLiftList,
      custom_emails: CURRENT_FLIGHT_REPORT.custom_emails,
      WB: WB,
      operatorPK: operatorPK,
    };
    BILLING_SendReportData();
  }
}

function BILLING_SendReportData() {
  if (VIEWING_ARCHIVED) return;
  //Save Report Data
  //Itin id 0 == No Itinerary;
  REFRESH_FR_LIST = true;
  if (ONLINE && !LOW_BANDWIDTH && !BILLING_EDITING_OFFLINE) {
    //send flight report data to server and attempt to retrieve PrimaryKey for Flight Report
    //This will also insert the flight hobbs data that is needed for the flight report
    $('#Billing_CompleteFlightReportBTN').prop('disabled', true);

    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Billing-AJAX.php?action=SubmitFlightReportONLY',
      data: CURRENT_FLIGHT_REPORT,
      timeout: 30000, //10 seconds
    })
      .success(function (json_data) {
        $.mobile.loading('hide');
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, BILLING_GenerateLegs); //Store Itinerary Data
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'CreateFlightLegs');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          if (json_data[0].ServerResponse == 'Success') {
            $('#Billing_CompleteFlightReportBTN').prop('disabled', false);
            //console.log("Received Inserted Flight Report PrimaryKey");
            //if primary key was 0 then this was a new flight report.  disable Hobbs reading input if available

            var PK = json_data[0].InsertID;
            if (json_data[0].HOBBS != undefined) {
              CURRENT_FLIGHT_REPORT.HOBBS = json_data[0].HOBBS;
            }
            CURRENT_FLIGHT_REPORT.PrimaryKey = PK;
            $('#BILLING_FPimaryKey').val(PK); //Set flight report primary key
            BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, BILLING_GenerateLegs); //Store Itinerary Data
          }
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        //data.status, data.readyState, data.responseText
        //console.log(data);
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem updating your flight report data.  Would you like to try resending?'
          ),
          buttons: {
            [iTrans('Yes')]: {
              click: function () {
                BILLING_SendReportData();
              },
            },
            [iTrans('No')]: {
              click: function () {
                //Uncheck the non-billable checkbox
                $('#Billing_CompleteFlightReportBTN').prop('disabled', false);
                BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, BILLING_GenerateLegs); //Store Itinerary Data
              },
            },
          },
        });
      });
  } else {
    BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, BILLING_GenerateLegs); //Store Itinerary Data
  }
}

function BILLING_GenerateLegs(storeSuccess) {
  $.mobile.changePage($('#BILLING_FlightLegsPage'), {
    changeHash: false,
  });
  BILLING_NewFlightLeg('Remove');
  updateFlightLegData(0, false, true);
}

function updateFlightLegData(index, newLeg, init) {
  var NewLeg = newLeg || false;
  var Init = init || false;
  if ('signature' in CURRENT_FLIGHT_REPORT) {
    if (CURRENT_FLIGHT_REPORT.signature != '' && !Init) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt:
          iTrans('This flight report has already been signed!') +
          '<br />' +
          iTrans('Modifying it will remove the signature.') +
          '<br />' +
          iTrans('Are you Sure?'),
        buttons: {
          [iTrans('Continue')]: {
            click: function () {
              signatureDATA = {
                data: '',
                text: '',
              };
              CURRENT_FLIGHT_REPORT.signature = '';
              CURRENT_FLIGHT_REPORT.signed_by = '';
              CURRENT_FLIGHT_REPORT.Completed = 0;
              if (CURRENT_FLIGHT_REPORT.Pilot_PK != USER_PK && CURRENT_FLIGHT_REPORT.SIC_PK != USER_PK) {
                CURRENT_FLIGHT_REPORT.Completed = -2; //Admin created FR so Admin can edit it in propgress
              }
              UpdateFlightLegData2(index, NewLeg);
            },
          },
          [iTrans('Cancel')]: {
            click: function () {
              BILLING_returnCreateFlightReport();
            },
          },
        },
      });
    } else {
      UpdateFlightLegData2(index, NewLeg);
    }
  } else {
    UpdateFlightLegData2(index, NewLeg);
  }
}

function FR_MakePIC_SIC_Selector(index, Pilot_PK, SIC_PK) {
  var html = '<div style="display:' + ($('#BILLING_FPSIC').val() == '' ? 'none' : 'block') + ';">';
  html += '<label for="FLU-' + index + '">' + iTrans('Pilot In Command') + '</label>';
  html += `<fieldset data-role="controlgroup" data-type="vertical" data-mini="true">
<label for="FLPilot_PK-${index}">${$('#BILLING_FPpilot').val()}</label>
    <input
        type="radio"
        name="FR_LegPIC_Radio-${index}"
        id="FLPilot_PK-${index}"
        value="${Pilot_PK}"
        ${CURRENT_FLIGHT_REPORT.Pilot_PK == Pilot_PK ? 'checked' : ''}
        onClick="updateFlightLegData(${index})"
    />
    <label for="FLSIC_PK-${index}">${$('#BILLING_FPSIC').val()}</label>
    <input
        type="radio"
        name="FR_LegPIC_Radio-${index}"
        id="FLSIC_PK-${index}"
        value="${SIC_PK}"
        ${CURRENT_FLIGHT_REPORT.SIC_PK == Pilot_PK ? 'checked' : ''}
        onClick="updateFlightLegData(${index})"
    /></fieldset>`;
  html += '</div>';
  return html;
}

function FR_MakeflightCatID_Selector(index, value) {
  var html = '<div style="display:' + (GROUP_DATA.FlightCategories.length == 0 ? 'none' : 'block') + ';">';
  html += '<label for="FLflightCatID-' + index + '">' + iTrans('Flight Category') + '</label>';
  html += '<select id="FLflightCatID-' + index + '" data-mini="true" onchange="updateFlightLegData(' + index + ')">';
  html += '<option value="0" ' + (value == 0 ? 'selected' : '') + '>[' + iTrans('Not Assigned') + ']</option>';
  for (var i in GROUP_DATA.FlightCategories) {
    var fc = GROUP_DATA.FlightCategories[i];
    html +=
      '<option value="' +
      fc.PrimaryKey +
      '" ' +
      (value == fc.PrimaryKey ? 'selected' : '') +
      '>' +
      (fc.fltcatcode == null ? '' : '[' + fc.fltcatcode + '] ') +
      fc.fltcatdesc +
      '</option>';
  }
  html += '</select></div>';
  return html;
}

function UpdateFlightLegData2(index, newLeg) {
  $('#Billing_CompleteFlightReportBTN').prop('disabled', true);
  //console.log("updating flight leg data index:" + index);
  let newNoShutdown = false;
  if (BILLING_FLIGHTLEGS[index] != undefined) {
    var L = BILLING_FLIGHTLEGS[index];
    ////console.log(L);
    L.Pilot_PK = $('#FLPilot_PK-' + index).is(':checked')
      ? $('#FLPilot_PK-' + index).val()
      : $('#FLSIC_PK-' + index).val();
    L.SIC_PK = $('#FLSIC_PK-' + index).is(':checked') ? $('#FLPilot_PK-' + index).val() : $('#FLSIC_PK-' + index).val();
    L.flightCatID = $('#FLflightCatID-' + index).val();
    L.Time_Start = $('#FLU2-' + index).val();
    L.Time_Up = $('#FLU-' + index).val();
    L.Time_Down = $('#FLD-' + index).val();
    L.Time_Stop = $('#FLD2-' + index).val();
    if (CURRENT_FLIGHT_REPORT.BlockTime == 0) {
      L.Time_Start = L.Time_Up;
      L.Time_Stop = L.Time_Down;
      if (BILLING_FLIGHTLEGS[parseInt(index) - 1] != undefined) {
        if (BILLING_FLIGHTLEGS[parseInt(index) - 1].noShutdown == 1) {
          L.Time_Start = BILLING_FLIGHTLEGS[parseInt(index) - 1].Time_Down;
          //L.Time_Up = L.Time_Start;
          //console.log('L.Time_Start', L.Time_Start);
          //$('#FLU-' + index).val(L.Time_Start);
          $('#FLU2-' + index).val(L.Time_Start);
        }
      }
    }
    L.Details_Up = $('#FLULU-' + index).val();
    L.Details_Down = $('#FLDLD-' + index).val();
    L.noShutdown = $('#FLnoShutdown-' + index).is(':checked') ? 1 : 0;
    if (L.noShutdown == 1) {
      L.Time_Stop = L.Time_Down;
      $('#FLD2-' + index).val(L.Time_Stop);
    }
    L.cargoWeight = round10($('#FLCargoWeight-' + index).val());
    if (BILLING_FLIGHTLEGS[parseInt(index) + 1] != undefined) {
      if (L.noShutdown == 1) {
        BILLING_FLIGHTLEGS[parseInt(index) + 1].Time_Start = L.Time_Stop;
        $('#FLU2-' + (parseInt(index) + 1)).val(L.Time_Stop);
      }
    } else {
      //new leg if it doesn't exist
      if (L.noShutdown == 1) {
        newNoShutdown = true;
      }
    }
    if (GROUP_DATA.Pax_Man == 1) {
      L.passengerWeight = BILLING_getPaxLegWeight(CURRENT_FLIGHT_REPORT.Pax_Manifest.Pax_Legs, index);
    } else {
      L.passengerWeight = 0;
    }
    L.Description = $('#FLDesc-' + index).val();
    L.PaxNum = $('#FLPaxNum-' + index).val();
    L.BillByVAL = $('#FLBV-' + index).val();
    L.Date = $('#FLDate-' + index).val();
    var Start2 = $('#FLU2-' + index).val();
    var Start = $('#FLU-' + index).val();
    var End = $('#FLD-' + index).val();
    var End2 = $('#FLD2-' + index).val();
    var Diff = 0;
    if (CURRENT_FLIGHT_REPORT.Job.BlockTime == 1) Diff = getDecimalTime(Start2, End2, CURRENT_FLIGHT_REPORT.Job.BillBy);
    else Diff = getDecimalTime(Start, End, CURRENT_FLIGHT_REPORT.Job.BillBy);
    $('#Start').blur();
    if (Diff < 0) {
      Diff += 24;
      //$("#FLD-" + index).val(Start);
      //$("#FLD-" + index).focus();
      //return; //do not save changes
    }
    var billable = 1;
    if ($('#FLBIL-' + index).length > 0) {
      if ($('#FLBIL-' + index).is(':checked')) billable = 0;
    }
    L.Billable = billable;

    var CF = L.Cfields;
    var PerLegFields = [];
    var DisableNext = false;
    var Aircraft = CURRENT_FLIGHT_REPORT.Aircraft;
    if (CF.length > 0) {
      for (var i in CF) {
        var I = CF[i];
        if (I.Aircraft != Aircraft && I.Aircraft !== '') {
          continue;
        }
        if (!checkCfieldType('FR', I)) continue;
        if (I.PerLeg == 1) {
          var checked = 0;
          if (I.type == 'checkbox') {
            if ($('#BI_CF' + index + '_' + I.PrimaryKey).is(':checked')) checked = 1;
            I.Value = checked;
            if (I.req == 1 && checked == 0) {
              //BillingValidation("[" + I.Label + "] is required to be checked.");
              DisableNext = true;
            }
          } else {
            I.Value = $('#BI_CF' + index + '_' + I.PrimaryKey).val();
            //if (I.Value == undefined) continue; //could have been added after existing flight report was made
            if (I.Value == undefined) I.Value = ''; //to support placeholder values on required dropdowns
            if (I.auto_pop_field != null) {
              switch (I.auto_pop_field) {
                case 'starts':
                  if (BILLING_FLIGHTLEGS[parseInt(index) - 1] != undefined) {
                    if (BILLING_FLIGHTLEGS[parseInt(index) - 1].noShutdown == 0) {
                      I.Value = 1;
                    } else {
                      I.Value = 0;
                    }
                  } else {
                    I.Value = 1;
                  }
                  break;
              }
              $('#BI_CF' + index + '_' + I.PrimaryKey).val(I.Value);
            }
            if ((I.Value == '' && I.req == 1) || (I.Value == null && I.req == 1) || (I.Value == ' ' && I.req == 1)) {
              //BillingValidation(I.Label+" is a required field and is empty.");
              DisableNext = true;
            }
          }
          PerLegFields.push({
            Archived: I.Archived,
            Bill_Item: I.Bill_Item,
            Bill_Item_options: I.Bill_Item_options,
            ClientPK: I.ClientPK,
            Label: I.Label,
            PerLeg: I.PerLeg,
            Placeholder: I.Placeholder,
            PrimaryKey: I.PrimaryKey,
            hideDefault: I.hideDefault,
            Value: I.Value,
            default: I.default,
            group: I.group,
            options: I.options,
            req: I.req,
            reqItin: I.reqItin,
            usedFor: I.usedFor,
            SortOrder: I.SortOrder,
            type: I.type,
            maint_provider: I.maint_provider == undefined ? null : I.maint_provider,
            auto_pop_field: I.auto_pop_field == undefined ? null : I.auto_pop_field,
            Aircraft: I.Aircraft == undefined ? '' : I.Aircraft,
          });
        }
      }
    }
    //$("#Billing_CompleteFlightReportLegsBTN").prop("disabled", DisableNext);
    L.Cfields = PerLegFields;
  }
  BILLING_checkOvernightLegs();

  //BILLING_FLIGHTLEGS[index].Cfields = PerLegFields;
  CURRENT_FLIGHT_REPORT.Legs = BILLING_FLIGHTLEGS;
  //console.log(CURRENT_FLIGHT_REPORT.Legs);
  BILLING_ADD_LEGS();
  if (newNoShutdown) {
    //timeout needed because jquery is complaingin about something
    setTimeout(function () {
      BILLING_NewFlightLeg();
    }, 100);
  }
  BILLING_update_noShutdown_Inputs();
  BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm); // Store Jobs Crew Data
  //BILLING_SendFlightLeg(index, NewLeg);
  //BILLING_FLIGHTLEGS[index] = L;
  ////console.log(L);
}

function BILLING_checkOvernightLegs() {
  var NewLeg_Date = CURRENT_FLIGHT_REPORT.Date;
  var TlegDate = new Date(NewLeg_Date + 'T12:00:00Z');
  var End = null;
  var End2 = null;
  var nextLegIncDate = false;
  for (var i in BILLING_FLIGHTLEGS) {
    var I = BILLING_FLIGHTLEGS[i];
    if (I.Time_Start == 0 && I.Time_Stop == 0) {
      I.Time_Start = I.Time_Up;
      I.Time_Stop = I.Time_Down;
    }
    var Start = parseInt(I.Time_Start);
    var Start2 = parseInt(I.Time_Up);
    if (End != null) {
      if (Start < End || Start2 < End2 || nextLegIncDate) {
        //Start is less than previous Leg End, Increment New Leg Date
        TlegDate.setDate(TlegDate.getDate() + 1);
        nextLegIncDate = false;
      }
    }
    End = parseInt(I.Time_Stop);
    End2 = parseInt(I.Time_Down);
    if (Start > End || Start2 > End2) {
      nextLegIncDate = true;
    }
    I.Date = TlegDate.toISOString().substr(0, 10);
  }
}

function deleteFlightLeg(index) {
  //console.log(index);

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this flight leg?  This cannot be undone.'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          BILLING_FLIGHTLEGS.splice(index, 1);
          CURRENT_FLIGHT_REPORT.Legs = BILLING_FLIGHTLEGS;
          try {
            ITIN_Pax_Manifest.Pax_Legs[index] = [];
          } catch (e) {
            console.log('Could not delete Leg Passengers');
          }

          BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm); // Store Jobs Crew Data
          if (BILLING_FLIGHTLEGS.length == 0) {
            //Popup warning confirming user wishes to submit an empty flight report;
            var html = '';
            if (CURRENT_FLIGHT_REPORT.Itin_ID != 0 && ONLINE) {
              html +=
                "<button onClick='BILLING_Load_Itin_Legs(" +
                CURRENT_FLIGHT_REPORT.Itin_ID +
                ")' data-mini='true' data-icon='bars'>" +
                iTrans('Import Legs From Itinerary') +
                '</button>';
            }
            $('#BILLING_FLIGHTLEGS').html(
              '<h2>' +
                iTrans('Empty Flight Report') +
                '</h2>' +
                html +
                '<button class="greenbg" data-icon="plus" onClick="BILLING_NewFlightLeg()">' +
                iTrans('Add new leg') +
                '</button>'
            );
            $('#BILLING_FLIGHTLEGS').enhanceWithin();
            updateFlightLegData(0);
          } else {
            BILLING_NewFlightLeg('Remove');
            if (BILLING_FLIGHTLEGS.length > 1) {
              updateFlightLegData(BILLING_FLIGHTLEGS.length - 2);
            }
          }
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function deleteAllFlightLegs() {
  var FlightReportPK = $('#BILLING_FPimaryKey').val();
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete all flight legs?  This cannot be undone.'),
    buttons: {
      'Delete All': {
        click: function () {
          BILLING_FLIGHTLEGS = [];
          CURRENT_FLIGHT_REPORT.Legs = [];
          BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm); // Store Jobs Crew Data
          //Popup warning confirming user wishes to submit an empty flight report;
          var html = '';
          if (CURRENT_FLIGHT_REPORT.Itin_ID != 0) {
            html +=
              "<button onClick='BILLING_Load_Itin_Legs(" +
              CURRENT_FLIGHT_REPORT.Itin_ID +
              ")' data-mini='true' data-icon='bars'>" +
              iTrans('Import Legs From Itinerary') +
              '</button>';
          }
          $('#BILLING_FLIGHTLEGS').html(
            '<h2>' +
              iTrans('Empty Flight Report') +
              '</h2>' +
              html +
              '<button class="greenbg" data-icon="plus" onClick="BILLING_NewFlightLeg()">' +
              iTrans('Add new leg') +
              '</button>'
          );
          $('#BILLING_FLIGHTLEGS').enhanceWithin();
          updateFlightLegData(0);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function confirmNonBillableLeg(i) {
  //console.log($("#FLBIL-" + i).is(":checked"));
  var checked = $('#FLBIL-' + i).is(':checked');
  if (checked) {
    //prompt confirmation of non billable leg
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        iTrans('The Client will NOT be billed for this leg and it will not appear on the invoice.') +
        '<br /><b>' +
        iTrans('Are you sure you wish to mark this leg as [NON-BILLABLE]?') +
        '</b>',
      buttons: {
        Yes: {
          click: function () {
            //Mark Leg as non billable
            updateFlightLegData(i);
          },
        },
        No: {
          click: function () {
            //Uncheck the non-billable checkbox
            $('#FLBIL-' + i)
              .prop('checked', false)
              .checkboxradio('refresh');
          },
        },
      },
    });
  } else {
    //Update leg to billable without prompt confirmation
    updateFlightLegData(i);
  }
}

function BILLING_NewFlightLeg(Action) {
  //Push Items
  //console.log(Action + " Flight Leg");
  $('#BILLING_FLIGHTLEGS').html('');
  var html = '';
  var Leg = 1;
  var JobID = CURRENT_FLIGHT_REPORT.Job.JobID;
  var Job = BILLING_GetJobData(CURRENT_FLIGHT_REPORT.Job.ClientPK + '|' + CURRENT_FLIGHT_REPORT.Job.JobID);
  var CF = Job.Cfields;

  var JobNum = Job.JobNum;
  var BillBy = Job.BillBy;
  var BillByOPT = Job.BillByOPT;
  var BlockTime = Job.BlockTime;
  for (var i in ALLAC_data) {
    var I = ALLAC_data[i];
    if (I.ident == CURRENT_FLIGHT_REPORT.Aircraft) {
      if (I.StartStop == 1) BlockTime = 1;
      break;
    }
  }
  var showCargoWeight = 'none';
  if (Job.showCargoWeights == 1) showCargoWeight = 'block';
  var BlockTime_Start = 'none';
  var BlockTime_Stop = 'none';
  var BlockTime_StartNew = 'none';
  var BlockTime_StopNew = 'none';
  var showNoShutdown = 'block';
  if (BlockTime == 1) {
    BlockTime_Start = 'block';
    BlockTime_Stop = 'block';
    BlockTime_StartNew = 'block';
    BlockTime_StopNew = 'block';
    showNoShutdown = 'block';
  }

  var showNonBillable = Job.showNonBillable;
  var blankLegTimes = Job.blankLegTimes;
  CURRENT_FLIGHT_REPORT.BlockTime = BlockTime;

  var DefaultLocation = '';
  if (CURRENT_FLIGHT_REPORT.Itin_ID != 0) {
    html +=
      "<button onClick='BILLING_Load_Itin_Legs(" +
      CURRENT_FLIGHT_REPORT.Itin_ID +
      ")' data-mini='true' data-icon='bars'>" +
      iTrans('Import Legs From Itinerary') +
      '</button>';
  }
  if (BILLING_FLIGHTLEGS.length > 0) {
    html +=
      "<button onClick='deleteAllFlightLegs()' data-mini='true' class='redbg' data-icon='delete'>" +
      iTrans('Delete All Legs') +
      '</button>';
    for (var i in BILLING_FLIGHTLEGS) {
      //Add existing legs
      var I = BILLING_FLIGHTLEGS[i];
      if (I.noShutdown == 1) {
        BlockTime_Stop = 'none';
        if (BILLING_FLIGHTLEGS[parseInt(i) - 1] != undefined) {
          if (BILLING_FLIGHTLEGS[parseInt(i) - 1].noShutdown == 1) {
            BlockTime_Start = 'none';
          }
        }
      }
      html +=
        '<table class="limit-table last-td-fill"><thead><tr><th><div class="vertical-center-container"><button onClick="deleteFlightLeg(' +
        i +
        ')" data-mini="true" class="cirro-btn" style="width:25px;"><img src="./images/delete.svg" /></button> ' +
        iTrans('Leg') +
        ' ' +
        Leg +
        ' - <div id="LSTAT' +
        i +
        '"></div></div></th></tr><tr><td>';
      html += '<table class="last-td-fill"><tr>';

      html +=
        '<td><div id="FLU2_StartHLDR-' +
        i +
        '" style="display:' +
        BlockTime_Start +
        ';"><label for="FLU2-' +
        i +
        '">' +
        GLOBAL_LABELS.LegTimes.LegStartE +
        '</label><input id="FLU2-' +
        i +
        '" value="' +
        I.Time_Start +
        '" onFocus="blur()" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
        blankLegTimes +
        '); updateFlightLegData(' +
        i +
        ')" /></div><label for="FLU-' +
        i +
        '" style="width: 75px;">' +
        GLOBAL_LABELS.LegTimes.LegStart +
        '</label><input id="FLU-' +
        i +
        '" value="' +
        I.Time_Up +
        '" onFocus="blur()" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
        blankLegTimes +
        '); updateFlightLegData(' +
        i +
        ')" /></td>';
      html +=
        '<td><label for="FLULU-' +
        i +
        '">' +
        iTrans('Location') +
        '</label><input type="text" id="FLULU-' +
        i +
        '" value="' +
        escapeHtml(I.Details_Up) +
        '" onChange="updateFlightLegData(' +
        i +
        ')" /></td>';
      html += '</tr><tr>';
      html +=
        '<td><label for="FLD-' +
        i +
        '">' +
        GLOBAL_LABELS.LegTimes.LegEnd +
        '</label><input id="FLD-' +
        i +
        '" onFocus="blur()" value="' +
        I.Time_Down +
        '" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
        blankLegTimes +
        '); updateFlightLegData(' +
        i +
        ')"/><div  id="FLU2_StopHLDR-' +
        i +
        '"style="display:' +
        BlockTime_Stop +
        ';"><label for="FLD2-' +
        i +
        '">' +
        GLOBAL_LABELS.LegTimes.LegEndE +
        '</label><input id="FLD2-' +
        i +
        '" onFocus="blur()" value="' +
        I.Time_Stop +
        '" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
        blankLegTimes +
        '); updateFlightLegData(' +
        i +
        ')"/></div></td>';
      html +=
        '<td><label for="FLDLD-' +
        i +
        '">' +
        iTrans('Location') +
        '</label><input type="text" id="FLDLD-' +
        i +
        '" value="' +
        escapeHtml(I.Details_Down) +
        '" onChange="updateFlightLegData(' +
        i +
        ')"/></td>';
      DefaultLocation = I.Details_Down;
      if (BillBy == 'Distance' || BillBy == 'Weight' || BillBy == 'Volume') {
        switch (BillByOPT) {
          case 'gallonsimp':
            html += '<tr><td><b>' + iTrans('Volume') + ' (Imperial Gallon)</b></td>';
            break;
          case 'gallonsus':
            html += '<tr><td><b>' + iTrans('Volume') + ' (US Gallon)</b></td>';
            break;
          case 'litres':
            html += '<tr><td><b>' + iTrans('Volume') + ' (Litres)</b></td>';
            break;
          case 'NM':
            html += '<tr><td><b>' + iTrans('Dist') + ' (NM)</b></td>';
            break;
          case 'SM':
            html += '<tr><td><b>' + iTrans('Dist') + ' (sm)</b></td>';
            break;
          case 'KM':
            html += '<tr><td><b>' + iTrans('Dist') + ' (km)</b></td>';
            break;
          case 'KG':
            html += '<tr><td><b>' + iTrans('Weight') + ' (kg)</b></td>';
            break;
          case 'LB':
            html += '<tr><td><b>' + iTrans('Weight') + ' (lb)</b></td>';
            break;
          default:
            html += '<tr><td><b>' + iTrans(BillBy) + '</b></td>';
            break;
        }
        html +=
          '<td><input type="text" id="FLBV-' +
          i +
          '" value="' +
          I.BillByVAL +
          '" onChange="updateFlightLegData(' +
          i +
          ')"/></td>';
      } else {
        if (BillBy == 'HourPL')
          html +=
            '<tr><td><h3>' +
            iTrans('Bill Hours') +
            '</h3></td><td><input type="number" step="0.1" max="24" min="0" id="FLBV-' +
            i +
            '" value="' +
            I.BillByVAL +
            '" onChange="updateFlightLegData(' +
            i +
            ')"/></td>';
        else
          html +=
            '<input type="hidden" id="FLBV-' +
            i +
            '" value="' +
            I.BillByVAL +
            '" onChange="updateFlightLegData(' +
            i +
            ')"/>';
      }
      html += '</tr></table>';
      html += '</tr></td><tr><td>';
      html +=
        '<div style="display:' +
        showNoShutdown +
        '"><label for="FLnoShutdown-' +
        i +
        '">' +
        iTrans('No Shutdown') +
        '</label><input data-mini="true" type="checkbox" id="FLnoShutdown-' +
        i +
        '" onChange="updateFlightLegData(' +
        i +
        ')" ' +
        (I.noShutdown ? 'checked' : '') +
        ' /></div>';
      html += '<div id="FRFuelUplift' + i + '"></div>';
      html += FR_MakePIC_SIC_Selector(i, I.Pilot_PK, I.SIC_PK);
      html += FR_MakeflightCatID_Selector(i, I.flightCatID);
      html +=
        '<input type="hidden" id="FLDate-' +
        i +
        '"  value="' +
        I.Date +
        '" onChange="updateFlightLegData(' +
        i +
        ')"/>';
      html +=
        '<label for="FLPaxNum-' +
        i +
        '">' +
        iTrans('Number Of Passengers') +
        '</label><input type="number" id="FLPaxNum-' +
        i +
        '" onChange="updateFlightLegData(' +
        i +
        ')" value="' +
        I.PaxNum +
        '" />';
      html +=
        '<div style="display:' +
        showCargoWeight +
        '"><label for="FLCargoWeight-' +
        i +
        '">' +
        iTrans('Cargo Weight (Lbs)') +
        '</label><input type="number" id="FLCargoWeight-' +
        i +
        '" onChange="updateFlightLegData(' +
        i +
        ')" value="' +
        I.cargoWeight +
        '" /></div>';
      html += '<div id="BILLING_ManifestingHLDR-' + i + '"></div>';
      html +=
        '<label for="FLDesc-' +
        i +
        '">' +
        iTrans('Description') +
        '</label><textarea id="FLDesc-' +
        i +
        '" onChange="updateFlightLegData(' +
        i +
        ')">' +
        I.Description +
        '</textarea>';
      if (showNonBillable == 1) {
        if (I.Billable == 1)
          html +=
            '<label for="FLBIL-' +
            i +
            '">' +
            iTrans('Non-billable') +
            '</label><input data-mini="true" type="checkbox" id="FLBIL-' +
            i +
            '" onClick="confirmNonBillableLeg(' +
            i +
            ')"/>';
        else
          html +=
            '<label for="FLBIL-' +
            i +
            '">' +
            iTrans('Non-billable') +
            '</label><input data-mini="true" type="checkbox" id="FLBIL-' +
            i +
            '" checked onClick="confirmNonBillableLeg(' +
            i +
            ')"/>';
      }
      html += BILLING_POP_EDIT_CF_LEG(I.Cfields, i);
      html += '</td></tr>';
      html += '</table>';
      Leg++;
    }
  }
  if (Action != 'Remove') {
    //Add blank entry to be filled
    var L = BILLING_FLIGHTLEGS.length;
    var NewLeg_Date = CURRENT_FLIGHT_REPORT.Date;
    var TlegDate = new Date(NewLeg_Date + 'T12:00:00Z');
    var End = null;
    var nextLegIncDate = false;
    for (var i in BILLING_FLIGHTLEGS) {
      var I = BILLING_FLIGHTLEGS[i];
      var Start = parseInt(I.Time_Up);
      if (End != null) {
        if (Start < End || nextLegIncDate) {
          //Start is less than previous Leg End, Increment New Leg Date
          TlegDate.setDate(TlegDate.getDate() + 1);
          nextLegIncDate = false;
        }
      }
      End = parseInt(I.Time_Down);
      if (Start > End) {
        nextLegIncDate = true;
      }
    }
    if (BILLING_FLIGHTLEGS.length > 0) {
      if (BILLING_FLIGHTLEGS[BILLING_FLIGHTLEGS.length - 1].noShutdown == 1) {
        BlockTime_StartNew = 'none';
      }
    }

    if (nextLegIncDate) {
      TlegDate.setDate(TlegDate.getDate() + 1);
    }
    NewLeg_Date = TlegDate.toISOString().substr(0, 10);
    html +=
      '<table class="limit-table last-td-fill"><thead><tr><th><div class="vertical-center-container"><button onClick="deleteFlightLeg(' +
      L +
      ')" data-mini="true" class="cirro-btn" style="width:25px;"><img src="./images/delete.svg" /></button> ' +
      iTrans('Leg') +
      ' ' +
      Leg +
      ' - <div id="LSTAT' +
      L +
      '"></div></div></th></tr><tr><td>';
    html += '<table class="last-td-fill"><tr>';
    var prevLegStop = 0;
    if (BILLING_FLIGHTLEGS.length > 0) {
      if (BILLING_FLIGHTLEGS[BILLING_FLIGHTLEGS.length - 1].noShutdown == 1) {
        prevLegStop = BILLING_FLIGHTLEGS[BILLING_FLIGHTLEGS.length - 1].Time_Down;
      }
    }
    html +=
      '<td><div id="FLU2_StartHLDR-' +
      L +
      '" style="display:' +
      BlockTime_StartNew +
      ';"><label for="FLU2-' +
      L +
      '">' +
      GLOBAL_LABELS.LegTimes.LegStartE +
      '</label><input id="FLU2-' +
      L +
      '" onFocus="blur()" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
      blankLegTimes +
      '); updateFlightLegData(' +
      L +
      ')" value="' +
      prevLegStop +
      '"/></div><label for="FLU-' +
      L +
      '">' +
      GLOBAL_LABELS.LegTimes.LegStart +
      '</label><input id="FLU-' +
      L +
      '" onFocus="blur()" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
      blankLegTimes +
      '); updateFlightLegData(' +
      L +
      ')" value="0"/></td>';

    html +=
      '<td><label for="FLULU-' +
      L +
      '">' +
      iTrans('Location') +
      '</label><input type="text" id="FLULU-' +
      L +
      '" onChange="updateFlightLegData(' +
      L +
      ')" value="' +
      escapeHtml(DefaultLocation) +
      '"/></td>';
    html += '</tr><tr>';
    html +=
      '<td><label for="FLD-' +
      L +
      '">' +
      GLOBAL_LABELS.LegTimes.LegEnd +
      '</label><input id="FLD-' +
      L +
      '" onFocus="blur()" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
      blankLegTimes +
      '); updateFlightLegData(' +
      L +
      ')" value="0"/><div id="FLU2_StopHLDR-' +
      i +
      '" style="display:' +
      BlockTime_StopNew +
      ';"><label for="FLD2-' +
      L +
      '">' +
      GLOBAL_LABELS.LegTimes.LegEndE +
      '</label><input id="FLD2-' +
      L +
      '" onFocus="blur()" ondblclick="BI_ClearInput(this)" onChange="validateTime(this.value,this.id,true,' +
      blankLegTimes +
      '); updateFlightLegData(' +
      L +
      ')" value="0"/></div></td>';
    html +=
      '<td><label for="FLDLD-' +
      L +
      '">' +
      iTrans('Location') +
      '</label><input type="text" id="FLDLD-' +
      L +
      '" onChange="updateFlightLegData(' +
      L +
      ')"/></td>';
    if (BillBy == 'Distance' || BillBy == 'Weight' || BillBy == 'Volume') {
      switch (BillByOPT) {
        case 'gallonsimp':
          html += '<tr><td><b>' + iTrans('Volume') + ' (Imperial Gallon)</b></td>';
          break;
        case 'gallonsus':
          html += '<tr><td><b>' + iTrans('Volume') + ' (US Gallon)</b></td>';
          break;
        case 'litres':
          html += '<tr><td><b>' + iTrans('Volume') + ' (Litres)</b></td>';
          break;
        case 'NM':
          html += '<tr><td><b>' + iTrans('Dist') + ' (NM)</b></td>';
          break;
        case 'SM':
          html += '<tr><td><b>' + iTrans('Dist') + ' (sm)</b></td>';
          break;
        case 'KM':
          html += '<tr><td><b>' + iTrans('Dist') + ' (km)</b></td>';
          break;
        case 'KG':
          html += '<tr><td><b>' + iTrans('Weight') + ' (kg)</b></td>';
          break;
        case 'LB':
          html += '<tr><td><b>' + iTrans('Weight') + ' (lb)</b></td>';
          break;
        default:
          html += '<tr><td><b>' + iTrans(BillBy) + '</b></td>';
          break;
      }
      html += '<td><input type="text" id="FLBV-' + L + '" value="0" onChange="updateFlightLegData(' + L + ')"/></td>';
    } else {
      if (BillBy == 'HourPL')
        html +=
          '<tr><td><h3>' +
          iTrans('Bill Hours') +
          '</h3></td><td><input type="number" step="0.1" max="24" min="0" id="FLBV-' +
          L +
          '" value="0" onChange="updateFlightLegData(' +
          L +
          ')"/></td>';
      else html += '<input type="hidden" id="FLBV-' + L + '" value="0" onChange="updateFlightLegData(' + L + ')"/>';
    }
    html += '</tr></table>';
    html += '</tr></td><tr><td>';
    html +=
      '<div style="display:' +
      showNoShutdown +
      '"><label for="FLnoShutdown-' +
      L +
      '">' +
      iTrans('No Shutdown') +
      '</label><input data-mini="true" type="checkbox" id="FLnoShutdown-' +
      L +
      '" onChange="updateFlightLegData(' +
      L +
      ')" /></div>';
    html += '<div id="FRFuelUplift' + L + '"></div>';
    html += FR_MakePIC_SIC_Selector(L, CURRENT_FLIGHT_REPORT.Pilot_PK, CURRENT_FLIGHT_REPORT.SIC_PK);
    html += FR_MakeflightCatID_Selector(L, CURRENT_FLIGHT_REPORT.Job.flightCatID);
    html +=
      '<input type="hidden" id="FLDate-' +
      L +
      '"  value="' +
      NewLeg_Date +
      '" onChange="updateFlightLegData(' +
      L +
      ')"/>';
    let newPaxNum = 0;
    if (GROUP_DATA.Pax_Man == 1) {
      try {
        if (ITIN_Pax_Manifest.Pax_Legs[parseInt(L) - 1] != undefined) {
          ITIN_Pax_Manifest.Pax_Legs[L] = JSON.parse(JSON.stringify(ITIN_Pax_Manifest.Pax_Legs[parseInt(L) - 1]));
          newPaxNum = ITIN_Pax_Manifest.Pax_Legs[L].length;
        } else {
          ITIN_Pax_Manifest.Pax_Legs[L] = JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList));
          newPaxNum = ITIN_Pax_Manifest.Pax_Legs[L].length;
        }
      } catch (e) {
        console.log('Cannot load previous or default leg pax manifest');
      }
    }

    html +=
      '<label for="FLPaxNum-' +
      L +
      '">' +
      iTrans('Number of Passengers') +
      '</label><input type="number" id="FLPaxNum-' +
      L +
      '" onChange="updateFlightLegData(' +
      L +
      ')" value="' +
      newPaxNum +
      '" />';
    html +=
      '<div style="display:' +
      showCargoWeight +
      '"><label for="FLCargoWeight-' +
      L +
      '">' +
      iTrans('Cargo Weight (Lbs)') +
      '</label><input type="number" id="FLCargoWeight-' +
      L +
      '" onChange="updateFlightLegData(' +
      L +
      ')" value="0" /></div>';
    html += '<div id="BILLING_ManifestingHLDR-' + L + '"></div>';
    html +=
      '<label for="FLDesc-' +
      L +
      '">' +
      iTrans('Description') +
      '</label><textarea id="FLDesc-' +
      L +
      '" onChange="updateFlightLegData(' +
      L +
      ')"></textarea>';
    if (showNonBillable == 1) {
      html +=
        '<label for="FLBIL-' +
        L +
        '">' +
        iTrans('Non-billable') +
        '</label><input data-mini="true" type="checkbox" id="FLBIL-' +
        L +
        '" onClick="confirmNonBillableLeg(' +
        L +
        ')" />';
    }
    html += BILLING_POP_CF_LEG(CF, L);
    html += '</td></tr>';
    html += '</table>';
    var N = {};
    N.JobID = JobID;
    N.JobNum = JobNum;
    N.Time_Start = '0';
    N.Time_Up = '0';
    N.Time_Down = '0';
    N.Time_Stop = '0';
    N.Details_Up = '';
    N.Details_Down = '';
    N.noShutdown = 0;
    N.Description = '';
    N.Billable = 1;
    N.PrimaryKey = 'None';
    N.Cfields = CF;
    N.PaxNum = newPaxNum;
    BILLING_FLIGHTLEGS.push(N);

    html +=
      '<br /><button class="greenbg" data-icon="plus" onClick="BILLING_NewFlightLeg()">' +
      iTrans('Add new leg') +
      '</button>';

    $('#BILLING_FLIGHTLEGS').html(html);
    $('#BILLING_FLIGHTLEGS').enhanceWithin();
    updateFlightLegData(L, true); //to save proper custom fields

    var type = LOCALSTORAGE.TimeInput;
    if (type == undefined) type = 'Clockpicker';

    for (var i in BILLING_FLIGHTLEGS) {
      BILLING_POP_FuelUplift(i);
      BillingInputType('#FLD-' + i, type, true);
      BillingInputType('#FLU-' + i, type, true);
      if (CURRENT_FLIGHT_REPORT.BlockTime == 1) {
        BillingInputType('#FLD2-' + i, type, true);
        BillingInputType('#FLU2-' + i, type, true);
      }
    }

    BillingInputType('#FLD-' + L, type, true);
    BillingInputType('#FLU-' + L, type, true);
    if (CURRENT_FLIGHT_REPORT.BlockTime == 1) {
      BillingInputType('#FLD2-' + L, type, true);
      BillingInputType('#FLU2-' + L, type, true);
    }

    BILLING_ADD_LEGS();
    setTimeout(function () {
      $('html, body').animate(
        {
          scrollTop: $('#LSTAT' + i).offset().top - 50,
        },
        500
      );
    }, 500);
  } else {
    html +=
      '<br /><button class="greenbg" data-icon="plus" onClick="BILLING_NewFlightLeg()">' +
      iTrans('Add new leg') +
      '</button>';
    $('#BILLING_FLIGHTLEGS').html(html);
    $('#BILLING_FLIGHTLEGS').enhanceWithin();

    var type = LOCALSTORAGE.TimeInput;
    if (type == undefined) type = 'Clockpicker';

    for (var i in BILLING_FLIGHTLEGS) {
      BILLING_POP_FuelUplift(i);
      BillingInputType('#FLD-' + i, type, true);
      BillingInputType('#FLU-' + i, type, true);
      if (CURRENT_FLIGHT_REPORT.BlockTime == 1) {
        BillingInputType('#FLU2-' + i, type, true);
        BillingInputType('#FLD2-' + i, type, true);
      }
    }
    BILLING_ADD_LEGS();
    if (BILLING_FLIGHTLEGS.length > 0) {
      updateFlightLegData(BILLING_FLIGHTLEGS.length - 1);
    }
  }
  BILLING_paxManifest(CURRENT_FLIGHT_REPORT.Aircraft, 0, false);
}

function BillingInputType(id, type, hidedecimal) {
  if (type == 'Keypad') {
    $(id).off();
    //console.log("Input type: "+  id);
    $.fn.numpad.defaults.gridTpl = '<table class="numpad"></table>';
    $.fn.numpad.defaults.backgroundTpl = '<div class="ui-popup-screen ui-overlay-a"></div>';
    $.fn.numpad.defaults.displayTpl = '<input data-theme="b" type="text"/>';
    $.fn.numpad.defaults.buttonNumberTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.buttonFunctionTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.onKeypadCreate = function () {
      $(this).enhanceWithin();
    };
    $(id).numpad({
      hidePlusMinusButton: true,
      hideDecimalButton: hidedecimal,
    });
    $(id).removeAttr('onFocus');
  }
  if (type == 'Clockpicker') {
    $(id).removeAttr('onFocus');
    $(id).focus(ClockPickerBlur);

    $(id).clockpicker({
      autoclose: true,
      align: 'left',
    });
  }
  if (type == 'Keyboard') {
    $(id).removeAttr('onFocus');
    $(id).focus(ClockPickerFocus);
  }
}

function BILLING_ADD_LEGS() {
  var Total = 0;
  ////console.log(BILLING_FLIGHTLEGS);
  var HobbsTotals = '';
  if (HOBBS == 1) {
    HobbsTotals =
      '<br />- ' +
      iTrans('Flight Hobbs Hr') +
      ': ' +
      CURRENT_FLIGHT_REPORT.HOBBS.Primary.Reported.Total +
      ' ' +
      iTrans('Maint Hobbs Hr') +
      ': ' +
      CURRENT_FLIGHT_REPORT.HOBBS.Secondary.Reported.Total;
  }
  var BlockTime = 0;
  if (CURRENT_FLIGHT_REPORT.Job.BlockTime == 1) BlockTime = 1;
  var HoursPL = 0;
  if (CURRENT_FLIGHT_REPORT.Job.BillBy == 'HourPL') HoursPL = 1;
  var HoursPR = 0;
  if (CURRENT_FLIGHT_REPORT.Job.BillBy == 'HourPR') HoursPR = 1;
  var fltTotal = 0;
  for (var i in BILLING_FLIGHTLEGS) {
    L = BILLING_FLIGHTLEGS[i];
    if (HoursPL == 1 || HoursPR == 1) {
      if (HoursPL == 1) {
        var FT = parseFloat($('#FLBV-' + i).val());
        if (FT < 0) FT += 24;
        if (!isNaN(FT)) {
          Total += FT;
          Total = Math.round(Total * 10) / 10;
          fltTotal = Total;
          $('#LSTAT' + i).html('- ' + iTrans('Flight Totals') + ': ' + round10(FT) + ' / ' + Total);
        } else {
          $('#LSTAT' + i).html('- ' + iTrans('Flight Totals') + ': N/A / ' + Total);
        }
      } else {
        var FT = parseFloat(CURRENT_FLIGHT_REPORT.BillByVAL);
        if (!isNaN(FT)) {
          Total = FT;
          Total = Math.round(Total * 10) / 10;
          fltTotal = Total;
          $('#LSTAT' + i).html(
            '- ' + iTrans('Flight Totals') + ': ' + round10(FT) + ' ' + iTrans('Entire Flight Report')
          );
        } else {
          $('#LSTAT' + i).html(
            '- ' + iTrans('Flight Totals') + ': ' + round10(FT) + '' + iTrans('Entire Flight Report')
          );
        }
      }
    } else {
      if (parseInt(L.Time_Down) != 0) {
        var FT = getDecimalTime(L.Time_Up, L.Time_Down, CURRENT_FLIGHT_REPORT.Job.BillBy);

        if (!isNaN(FT)) {
          fltTotal += FT;
          fltTotal = round10(fltTotal);
        }
      }
      if (BlockTime == 1) {
        if (parseInt(L.Time_Stop) != 0) {
          var FT = getDecimalTime(L.Time_Start, L.Time_Stop, CURRENT_FLIGHT_REPORT.Job.BillBy);

          if (!isNaN(FT)) {
            Total += FT;
            Total = Math.round(Total * 10) / 10;
            $('#LSTAT' + i).html(
              '- ' + iTrans('Flight Totals') + ': ' + round10(FT) + ' / ' + Total + ' ' + iTrans('Total') + HobbsTotals
            );
          } else {
            $('#LSTAT' + i).html(
              '- ' + iTrans('Flight Totals') + ': N/A / ' + Total + ' ' + iTrans('Total') + HobbsTotals
            );
          }
        }
      } else {
        if (parseInt(L.Time_Down) != 0) {
          var FT = getDecimalTime(L.Time_Up, L.Time_Down, CURRENT_FLIGHT_REPORT.Job.BillBy);

          if (!isNaN(FT)) {
            Total += FT;
            Total = Math.round(Total * 10) / 10;
            $('#LSTAT' + i).html(
              '- ' + iTrans('Flight Totals') + ': ' + round10(FT) + ' / ' + Total + ' ' + iTrans('Total') + HobbsTotals
            );
          } else {
            $('#LSTAT' + i).html(
              '- ' + iTrans('Flight Totals') + ': N/A / ' + Total + ' ' + iTrans('Total') + HobbsTotals
            );
          }
        }
      }
    }
  }
  if (COUNTRY == 'UNITED STATES' || GROUP_DATA.group == 'transportcanada' || GPS_DEBUG) {
    CURRENT_FLIGHT_REPORT.HoursFlown = LegTime_ToHours(fltTotal, CURRENT_FLIGHT_REPORT.Job.BillBy);
  } else {
    CURRENT_FLIGHT_REPORT.HoursFlown = LegTime_ToHours(Total, CURRENT_FLIGHT_REPORT.Job.BillBy);
  }
}

function BILLING_POP_CF_LEG(Cfields, Leg) {
  //populate custom fields for flight report legs
  //console.log("Generating flight leg custom fields");
  var CF = Cfields;
  //console.log(Cfields);
  var html = '';
  if (CF == null || CF == undefined) return '';
  var Aircraft = CURRENT_FLIGHT_REPORT.Aircraft;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != Aircraft && I.Aircraft !== '') {
        continue;
      }
      if (!checkCfieldType('FR', I)) continue;
      if (I.PerLeg == 1) {
        html += createCustomField(Leg, I, false, 'FR');
      }
    }
  }
  return html;
}

function BILLING_POP_EDIT_CF_LEG(Cfields, Leg) {
  //populate custom fields for flight report legs
  //console.log("Generating flight leg custom fields");
  //console.log(Cfields);
  var CF = Cfields;
  var html = '';
  if (CF == undefined || CF == null) return '';
  var Aircraft = CURRENT_FLIGHT_REPORT.Aircraft;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft != Aircraft && I.Aircraft !== '') {
        continue;
      }
      if (!checkCfieldType('FR', I)) continue;
      if (I.PerLeg == 1) {
        html += createCustomField(Leg, I, I.Value, 'FR');
      }
    }
  }
  return html;
}

function BILLING_returnCreateFlightLegs() {
  $.mobile.changePage($('#BILLING_FlightLegsPage'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_NewFlightLeg('Remove'); //to refresh fuel uplifts if already stored
}

function BILLING_returnCreateFlightReport() {
  $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
    changeHash: false,
    reverse: true,
  });
  BILLING_setPaxManIdent();
}

function Billing_CompleteFlightReport(viewonly = false) {
  //console.log(viewonly);
  //Verify up and down time for each leg
  var BlockTime = CURRENT_FLIGHT_REPORT.Job.BlockTime;
  var blankLegTimes = CURRENT_FLIGHT_REPORT.Job.blankLegTimes;
  if (!viewonly) {
    $('#RETURN_TO_LEGS').prop('disabled', false);
    //console.log(BILLING_FLIGHTLEGS);
    for (var index in BILLING_FLIGHTLEGS) {
      var leg = parseInt(index) + 1;
      var Start2 = $('#FLU2-' + index).val();
      var Start = $('#FLU-' + index).val();
      var End = $('#FLD-' + index).val();
      var End2 = $('#FLD2-' + index).val();
      var Diff = 0;
      if (BlockTime == 1) getDecimalTime(Start2, End2, CURRENT_FLIGHT_REPORT.Job.BillBy);
      else Diff = getDecimalTime(Start, End, CURRENT_FLIGHT_REPORT.Job.BillBy);
      $('#Start').blur();
      if (Diff < 0) {
        Diff += 24;
        //$("#FLD-" + index).val(Start);
        //$("#FLD-" + index).focus();
        //BillingValidation("Leg " + leg + " start time is before end time.");
        //return; //do not save changes
      }
      if (blankLegTimes == 0) {
        if ($('#FLU-' + index).val() == '0') {
          $('#FLU-' + index).focus();
          if (LOCALSTORAGE.TimeInput == 'Clockpicker') $('#FLU-' + index).clockpicker('show');
          BillingValidation(iTrans('Leg') + ': ' + leg + ' ' + iTrans('Enter Up Time'));
          return;
        }
        if ($('#FLD-' + index).val() == '0') {
          $('#FLD-' + index).focus();
          if (LOCALSTORAGE.TimeInput == 'Clockpicker') $('#FLD-' + index).clockpicker('show');
          BillingValidation(iTrans('Leg') + ': ' + leg + ' ' + iTrans('Enter Down Time'));
          return;
        }
      }
      if ($('#FLULU-' + index).val().length > 1024) {
        $('#FLULU-' + index).focus();
        BillingValidation(
          iTrans('Leg') + ': ' + leg + ' ' + iTrans('Location details cannot be longer than 1024 characters')
        );
        return;
      }
      if ($('#FLDLD-' + index).val().length > 1024) {
        $('#FLDLD-' + index).focus();
        BillingValidation(
          iTrans('Leg') + ': ' + leg + ' ' + iTrans('Location details cannot be longer than 1024 characters')
        );
        return;
      }
      if ($('#FLDesc-' + index).val().length > 1024) {
        $('#FLDesc-' + index).focus();
        BillingValidation(
          iTrans('Leg') + ': ' + leg + ' ' + iTrans('Description cannot be longer than 1024 characters')
        );
        return;
      }
      if (BlockTime == 1 && blankLegTimes == 0) {
        if ($('#FLU2-' + index).val() == '0') {
          $('#FLU2-' + index).focus();
          if (LOCALSTORAGE.TimeInput == 'Clockpicker') $('#FLU2-' + index).clockpicker('show');
          BillingValidation(iTrans('Leg') + ': ' + leg + ' ' + iTrans('Enter Start Time'));
          return;
        }
        if ($('#FLD2-' + index).val() == '0') {
          $('#FLD2-' + index).focus();
          if (LOCALSTORAGE.TimeInput == 'Clockpicker') $('#FLD2-' + index).clockpicker('show');
          BillingValidation(iTrans('Leg') + ': ' + leg + ' ' + iTrans('Enter Stop Time'));
          return;
        }
      }
      //check each field for incomplete required data
      var CF = BILLING_FLIGHTLEGS[index].Cfields;

      if (CF != null) {
        var Aircraft = CURRENT_FLIGHT_REPORT.Aircraft;
        if (CF.length > 0) {
          for (var i in CF) {
            var I = CF[i];
            if (I.Aircraft != Aircraft && I.Aircraft !== '') {
              continue;
            }
            if (!checkCfieldType('FR', I)) continue;
            if (I.PerLeg == 1) {
              var checked = 0;
              if (I.type != 'checkbox') {
                var Value = $('#BI_CF' + index + '_' + I.PrimaryKey).val();
                //if (Value == undefined) continue; //could have been added after existing flight report was made
                if (Value == undefined) Value = ''; //to support placeholder validation for required dropdowns
                if ((Value == '' && I.req == 1) || (Value == null && I.req == 1) || (Value == ' ' && I.req == 1)) {
                  BillingValidation(
                    iTrans('Leg') + ': ' + leg + ' [' + I.Label + '] ' + iTrans('is a required field and is empty.')
                  );
                  return;
                }
              } else {
                if (I.req == 1 && !$('#BI_CF' + index + '_' + I.PrimaryKey).is(':checked')) {
                  BillingValidation(
                    iTrans('Leg') + ': ' + leg + ' [' + I.Label + '] ' + iTrans('is required to be checked.')
                  );
                  return;
                }
              }
            }
          }
        }
      }
    }
    updateBillingHobbs();

    BILLING_UpdateFieldGear(viewonly);
  } else {
    BILLING_UpdateFieldGear(viewonly);
  }
}

function BILLING_UpdateFieldGear(viewonly) {
  if (CURRENT_FLIGHT_REPORT.FieldGear.length == 0 || VIEWING_FLIGHT_REPORT == true) {
    BILLING_Updated_FieldGear(viewonly);
  } else {
    $.mobile.changePage($('#BILLING_FieldGearPage'), {
      changeHash: false,
      reverse: false,
    });
    //populate field gear update fields
    getDeviceLocation();
    var FG = CURRENT_FLIGHT_REPORT.FieldGear;
    var html = '<h2>' + iTrans('Update Field Gear Times') + '</h2>';
    html +=
      '<table class="limit-table"><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Comment') +
      '</th><th>' +
      iTrans('Status') +
      '</th><th>' +
      iTrans('Hours') +
      '</th><th>' +
      iTrans('Cycles') +
      '</th></tr></thead><tbody>';

    for (var i in FG) {
      var I = FG[i];
      if (I.HistoryPK == undefined) I.HistoryPK = 0;
      var fgSelect =
        '<select id="FR_Asset_Status_' +
        i +
        '" data-mini="true">' +
        '<option value="Defective">' +
        iTrans('Defective') +
        '</option>' +
        '<option value="Inspected">' +
        iTrans('Inspected - FULL') +
        '</option>' +
        '<option value="InspectedPreFlight">' +
        iTrans('Inspected Pre-Flight') +
        '</option>' +
        '<option value="Pending" selected>' +
        iTrans('Pending Inspection') +
        '</option>' +
        '<option value="Missing">' +
        iTrans('Missing') +
        '</option>' +
        '</select>';
      var fgHoursVal = 0;
      if (I.Usage != undefined) {
        fgHoursVal = I.Usage;
      }
      var fgHours =
        '<input onfocus="selectInputContent(this)" type="number" id="FR_Asset_Hours_' +
        i +
        '" step=0.1 value="' +
        fgHoursVal +
        '" ' +
        (I.Usage_Alarm == 0 && I.Usage_Limit == 0 ? 'disabled' : '') +
        '/>';
      var fgCyclesVal = 0;
      if (I.cycles != undefined) {
        fgCyclesVal = I.cycles;
      }
      var fgCycles =
        '<input onfocus="selectInputContent(this)" type="number" id="FR_Asset_Cycles_' +
        i +
        '" step=1 value="' +
        fgCyclesVal +
        '" ' +
        (I.cycle_Alarm == 0 && I.cycle_Limit == 0 ? 'disabled' : '') +
        '/>';
      var fgCommentVal = '';
      if (I.NewComment != undefined) {
        fgCommentVal = escapeHtml(I.NewComment);
      }
      var fgComment =
        '<input onfocus="selectInputContent(this)" type="text" id="FR_Asset_Comment_' +
        i +
        '" value="' +
        fgCommentVal +
        '" />';
      html +=
        '<tr><td>' +
        I.Name +
        '</td><td>' +
        fgComment +
        '</td><td>' +
        fgSelect +
        '</td><td>' +
        fgHours +
        '</td><td>' +
        fgCycles +
        '</td></tr>';
    }
    html += '</tbody></table>';

    html +=
      '<button class="greenbg" data-icon="check" onClick="BILLING_UpdateFieldGear_Send(' +
      viewonly +
      ');" data-mini="true">' +
      iTrans('Update Field Gear') +
      '</button>';

    $('#BILLING_FIELDGEAR').html(html);
    $('#BILLING_FIELDGEAR').enhanceWithin();
    for (var i in FG) {
      var I = FG[i];
      if (I.NewStatus != undefined) {
        $('#FR_Asset_Status_' + i)
          .val(I.NewStatus)
          .selectmenu('refresh');
      }
    }
  }
  Temp_ViewOnly = viewonly;
}

var Temp_ViewOnly = false;

function BILLING_UpdateFieldGear_Send_Next() {
  BILLING_UpdateFieldGear_Send(Temp_ViewOnly);
}

function BILLING_UpdateFieldGear_Send(viewonly) {
  var FG = CURRENT_FLIGHT_REPORT.FieldGear;
  var updated_FG = [];
  var error = false;
  for (var i in FG) {
    var I = FG[i];
    I.Hours = round10(parseFloat($('#FR_Asset_Hours_' + i).val()));
    I.Cycles = parseInt($('#FR_Asset_Cycles_' + i).val());
    I.NewStatus = $('#FR_Asset_Status_' + i).val();
    I.NewComment = $('#FR_Asset_Comment_' + i).val();
    I.Lat = UserDevice.Lat;
    I.Long = UserDevice.Long;
    if (USER_RIGHTS['AssetAccess'] == 0 && USER_RIGHTS['SuperUser'] == 0) {
      if (I.Hours < 0) {
        I.Hours = 0;
        error = true;
      }
      if (I.Cycles < 0) {
        I.Cycles = 0;
        error = true;
      }
    }
  }
  if (!error) BILLING_Updated_FieldGear(viewonly);
  else alert(iTrans('You do not have permission to enter negative values'));
}

function BILLING_Updated_FieldGear(viewonly) {
  if (!viewonly) {
    if (FLIGHTDOCS == 1 && ONLINE && AC_data.TechDispatch == 1) {
      BILLING_popAircraftTimes();
    } else if (GROUP_DATA.WinAir == 1 && ONLINE && AC_data.TechDispatch == 1) {
      BILLING_popAircraftTimes_WinAir();
    } else if (GROUP_DATA.Traxxall == 1 && ONLINE && AC_data.TechDispatch == 1) {
      //todo add  && ONLINE && AC_data.TechDispatch == 1
      BILLING_popAircraftTimes_Traxxall();
    } else if (GROUP_DATA.CAMP == 1 && ONLINE && AC_data.TechDispatch == 1) {
      //todo add  && ONLINE && AC_data.TechDispatch == 1
      BILLING_popAircraftTimes_CAMP();
    } else {
      $('#Billing_CompleteAircraftTimes').prop('disabled', false);
      Billing_CompleteFlightReport_display();
    }
  } else {
    $('#RETURN_TO_LEGS').prop('disabled', true);
    Billing_CompleteFlightReport_display();
  }
}

function Billing_CompleteFlightReport_display() {
  var clientPK = 0;
  if (CURRENT_FLIGHT_REPORT.Job.customCompany == 1) {
    clientPK = CURRENT_FLIGHT_REPORT.Job.ClientPK;
  } else {
    clientPK = CURRENT_FLIGHT_REPORT.operatorPK;
  }
  cirroDB.query('BillingClient', 'clientPK = ?', clientPK, function (e) {
    if (e === false || e.length == 0) {
      BillingDB('SELECT', 'Settings', null, BILLING_SettingsRetrieved); // get Jobs Crew Data
    } else {
      BILLING_SettingsRetrieved([e[0].data]);
    }
  });
}

function BILLING_SettingsRetrieved(json_data) {
  $.mobile.changePage($('#BILLING_FlightReview'), {
    changeHash: false,
    reverse: false,
  });
  $('#BILLING_SEND_ATTR_TO_CLIENT').checkboxradio('refresh');
  $('#BILLING_SEND_FR_TO_CLIENT').checkboxradio('refresh');
  $('#BILLING_SEND_FR_TO_JOB_CONTACT').checkboxradio('refresh');
  $('#BILLING_SEND_FR_TO_PICSIC').checkboxradio('refresh');
  $('#BILLING_SEND_FR_TO_CREW').checkboxradio('refresh');
  $('#BILLING_SEND_FR_TO_LIST').checkboxradio('refresh');

  $('#BILLING_FR_AttachBTN').show();

  //populate review and signature pages
  $('#BILLING_FLIGHT_REPORT_REVIEW').html('');
  var headerData = makeHeaderData(CURRENT_FLIGHT_REPORT, json_data[0]);
  //console.log(json_data);
  //MAKE FLIGHT REPORT
  CURRENT_FLIGHT_REPORT.BLURB = json_data[0].flight_report_blurb;
  CURRENT_FLIGHT_REPORT.headerData = headerData;
  //CURRENT_FLIGHT_REPORT contains all flight report, job and flight legs for review.
  //console.log(CURRENT_FLIGHT_REPORT);
  var FlightReport = BILLING_FLIGHT_REPORT_HTML(
    CURRENT_FLIGHT_REPORT,
    true,
    true,
    CURRENT_FLIGHT_REPORT.BLURB,
    CURRENT_FLIGHT_REPORT.headerData
  );
  $('#BILLING_FLIGHT_REPORT_REVIEW').html(FlightReport);
  //display old signature if reviewing old
  var html = '';
  if ('signature' in CURRENT_FLIGHT_REPORT) {
    if (CURRENT_FLIGHT_REPORT.signature != '') {
      signatureDATA.data = CURRENT_FLIGHT_REPORT.signature;
      signatureDATA.text = CURRENT_FLIGHT_REPORT.signed_by;
    }
  }
  if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 1)
    html += '<h3>' + iTrans('A Signature is Required for this Client') + '</h3>';
  var emailFRsignature =
    '<label for="BILLING_EMAIL_SIGNATURE">' +
    iTrans('Request Flight Report Signature by Email') +
    '</label><input type="checkbox" id="BILLING_EMAIL_SIGNATURE" onClick="BILLING_toggleEmailSignature(this);" data-mini="true" />';
  if (signatureDATA.data.length > 0) {
    var data = signatureDATA.data;
    //html = '<img src="'+data+'">';
    html +=
      '<button class="redbg" data-icon="delete" data-mini="true" onClick="BILLING_DELETE_SIGNATURE()">' +
      iTrans('Remove this signature') +
      '</button>';
  } else {
    html +=
      emailFRsignature +
      '<button class="greenbg" data-icon="plus" data-mini="true" onClick="BILLING_OPEN_SIGNATURE()" id="BILLING_OPEN_SIGNATURE_BTN">' +
      iTrans('Sign this flight report') +
      '</button>';
    if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 0) {
      if (CURRENT_FLIGHT_REPORT.Completed == 0 || CURRENT_FLIGHT_REPORT.Completed == -2)
        html +=
          '<label for="BILLING_COMPLETE_CHECK">' +
          iTrans('Mark as complete without signature') +
          '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)"/>';
      else
        html +=
          '<label for="BILLING_COMPLETE_CHECK">' +
          iTrans('Mark as complete without signature') +
          '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)" checked/>';
    } else {
      html +=
        '<label for="BILLING_COMPLETE_CHECK">' +
        iTrans('Mark as complete without signature') +
        '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)" disabled/>';
    }
  }
  $('#BILLING_SIGNATURE').html(html);
  if (GROUP_DATA.FR_Template == 3) {
    $('#BILLING_SIGNATURE').hide();
  } else {
    $('#BILLING_SIGNATURE').show();
  }
  $('#BILLING_SIGNATURE').enhanceWithin();
  if (!VIEWONLY) Billing_SignedFlightReport(true); //To silently save the flight report to the server in case user doesn't hit the save button
  VIEWONLY = false;
  generateFlightReportEmailList();
  generateFlightReportAttachmentList();
  if (CURRENT_FLIGHT_REPORT.SaveAttempt) generateFlightReportPDF();
  if (CURRENT_FLIGHT_REPORT.Job.Email != '') {
    $('#BILLING_SEND_FR_TO_CLIENT_EMAIL').html(
      '&bull; ' +
        CURRENT_FLIGHT_REPORT.Job.Firstname +
        ' ' +
        CURRENT_FLIGHT_REPORT.Job.Lastname +
        ' [' +
        CURRENT_FLIGHT_REPORT.Job.Email +
        ']'
    );
    $('#BILLING_SEND_FR_TO_CLIENT').prop('disabled', false).checkboxradio('refresh');
  } else {
    $('#BILLING_SEND_FR_TO_CLIENT_EMAIL').html(iTrans('No Email Address On File'));
    $('#BILLING_SEND_FR_TO_CLIENT').prop('checked', false).prop('disabled', true).checkboxradio('refresh');
  }

  if (CURRENT_FLIGHT_REPORT.Job.contact_email != '') {
    $('#BILLING_SEND_FR_TO_JOB_CONTACT_EMAIL').html(
      '&bull; ' +
        CURRENT_FLIGHT_REPORT.Job.contact_firstname +
        ' ' +
        CURRENT_FLIGHT_REPORT.Job.contact_lastname +
        ' [' +
        CURRENT_FLIGHT_REPORT.Job.contact_email +
        ']'
    );
    $('#BILLING_SEND_FR_TO_JOB_CONTACT').prop('disabled', false).checkboxradio('refresh');
  } else {
    $('#BILLING_SEND_FR_TO_JOB_CONTACT_EMAIL').html(iTrans('No Email Address On File'));
    $('#BILLING_SEND_FR_TO_JOB_CONTACT').prop('checked', false).prop('disabled', true).checkboxradio('refresh');
  }

  if (LOCALSTORAGE.STAFF_LIST.length > 0) {
    var PilotInfo = '';
    if (LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === CURRENT_FLIGHT_REPORT.Pilot) !== undefined) {
      PilotInfo =
        '&bull; ' +
        CURRENT_FLIGHT_REPORT.Pilot +
        ' [' +
        LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === CURRENT_FLIGHT_REPORT.Pilot).username +
        ']';
      CURRENT_FLIGHT_REPORT.PIC_Email = LOCALSTORAGE.STAFF_LIST.find(
        (x) => x.chatname === CURRENT_FLIGHT_REPORT.Pilot
      ).username;
    }
    var SICInfo = '';
    CURRENT_FLIGHT_REPORT.SIC_Email = '';
    if (LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === CURRENT_FLIGHT_REPORT.SIC) !== undefined) {
      SICInfo =
        '<br />&bull; ' +
        CURRENT_FLIGHT_REPORT.SIC +
        ' [' +
        LOCALSTORAGE.STAFF_LIST.find((x) => x.chatname === CURRENT_FLIGHT_REPORT.SIC).username +
        ']';
      CURRENT_FLIGHT_REPORT.SIC_Email = LOCALSTORAGE.STAFF_LIST.find(
        (x) => x.chatname === CURRENT_FLIGHT_REPORT.SIC
      ).username;
    }
    $('#BILLING_SEND_FR_TO_PICSIC_LIST').html(PilotInfo + SICInfo);
  }

  if (CURRENT_FLIGHT_REPORT.Crew.length > 0) {
    var CrewList = '';
    for (var i in CURRENT_FLIGHT_REPORT.Crew) {
      CrewList +=
        '&bull; ' + CURRENT_FLIGHT_REPORT.Crew[i].chatname + ' [' + CURRENT_FLIGHT_REPORT.Crew[i].username + ']<br />';
    }
    $('#BILLING_SEND_FR_TO_CREW_LIST').html(CrewList);
    $('#BILLING_SEND_FR_TO_CREW').prop('disabled', false).checkboxradio('refresh');
  } else {
    $('#BILLING_SEND_FR_TO_CREW_LIST').html(iTrans('No Crew Added To Report'));
    $('#BILLING_SEND_FR_TO_CREW').prop('checked', false).prop('disabled', true).checkboxradio('refresh');
  }
  if (
    (GROUP_DATA.FR_requestEmailON == 1 &&
      $('#BILLING_EMAIL_SIGNATURE').is(':checked') == false &&
      signatureDATA.data.length == 0) ||
    (CURRENT_FLIGHT_REPORT.Job.reqSignature == 1 &&
      $('#BILLING_EMAIL_SIGNATURE').is(':checked') == false &&
      signatureDATA.data.length == 0)
  ) {
    $('#BILLING_EMAIL_SIGNATURE').trigger('click');
  }
}

function BILLING_toggleCustomList(e) {
  if (e.checked) {
    $('#BILLING_SEND_FR_TO_hldr').show();
  } else {
    $('#BILLING_SEND_FR_TO_hldr').hide();
    $('#BILLING_SEND_FR_TO').val('');
  }
}

function BILLING_toggleEmailSignature(e) {
  if (e.checked) {
    $('#BILLING_OPEN_SIGNATURE_BTN').prop('disabled', true);
    $('#BILLING_COMPLETE_CHECK').prop('checked', false).prop('disabled', true).checkboxradio('refresh');
  } else {
    $('#BILLING_OPEN_SIGNATURE_BTN').prop('disabled', false);
    $('#BILLING_COMPLETE_CHECK').prop('disabled', false).checkboxradio('refresh');
  }
}

function BILLING_FR_COMPLETED_TOGGLE(e) {
  if (e.checked) {
    CURRENT_FLIGHT_REPORT.Completed = 1;
  } else {
    CURRENT_FLIGHT_REPORT.Completed = 0;
    if (CURRENT_FLIGHT_REPORT.Pilot_PK != USER_PK && CURRENT_FLIGHT_REPORT.SIC_PK != USER_PK) {
      CURRENT_FLIGHT_REPORT.Completed = -2; //Admin created FR so Admin can edit it in propgress
    }
  }
}

function generateFlightReportEmailList() {
  $('#BILLING_FR_FORCED_EMAIL_LIST').html(iTrans('Populating'));
  var FR_AlertList = CURRENT_FLIGHT_REPORT.AlertList;
  if (FR_AlertList == undefined) FR_AlertList = [];
  var html = '<p><h4>' + iTrans('This Flight Report Will Automatically Be Emailed To:') + '</h4>';
  if (FR_AlertList.length > 0) {
    html +=
      '<table class="limit-table wrap"><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Email') +
      '</th><th>' +
      iTrans('Phone') +
      '</th><th>' +
      iTrans('Saved') +
      '</th><th>' +
      iTrans('Completed') +
      '</th><th>' +
      iTrans('Signed') +
      '</th><th>' +
      iTrans('Base') +
      '</th><th>' +
      iTrans('Type') +
      '</th></tr></thead><tbody>';
    for (var i in FR_AlertList) {
      var I = FR_AlertList[i];
      if (I.DELETED == 1) continue;
      var Type = 'Global';
      if (I.GPK != 0 && I.JPK == 0) Type = 'Client';
      if (I.GPK != 0 && I.JPK != 0) Type = 'Job';
      html +=
        '<tr><td>' +
        I.ExternalContact +
        '</td><td>' +
        I.ExternalEmail +
        '</td><td>' +
        I.ExternalPhone +
        '</td><td>' +
        (I.on_Save == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.on_Complete == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.on_Signed == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.CurBaseOnly == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        Type +
        '</td></tr>';
    }
    html += '</tbody></table></p>';
  } else {
    html = '<h4>' + iTrans('No Entries For Flight Report CC Email List') + '</h4>';
  }
  $('#BILLING_FR_FORCED_EMAIL_LIST').html(html);
  $('#BILLING_FR_FORCED_EMAIL_LIST').enhanceWithin();
}

function generateFlightReportAttachmentList() {
  var A = CURRENT_FLIGHT_REPORT.Attachments;
  if (A == undefined) A = [];
  var html = '';
  if (!ONLINE) {
    html += '<h3>' + iTrans('Offline Cannot add new attachments') + '</h3>';
    $('#BILLING_FR_AttachBTN').hide();
  } else {
    $('#BILLING_FR_AttachBTN').show();
  }
  if (A.length > 0) {
    html += '<h3>' + iTrans('Flight Report Attachments') + '</h3>';
    html +=
      '<table class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Filename') +
      '</th><th>' +
      iTrans('Private') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in A) {
      var PrivateCHK =
        '<input type="checkbox" data-role="none" id="FR_PrvCHK_' +
        i +
        '" onClick="BILLING_MarkAttachmentIndex(' +
        i +
        ', this)" ' +
        (A[i].Private == 0 ? '' : 'checked') +
        '/>';
      html +=
        '<tr id="FR_PrvCLASS_' +
        i +
        '" class="' +
        (A[i].Private == 0 ? '' : 'yellowbg') +
        '"><td><a href="' +
        BASEURL +
        'menu-Billing-AJAX.php?action=getFRAtt&PK=' +
        A[i].PrimaryKey +
        '&FRPK=' +
        A[i].FR_PK +
        '" data-ajax="false" target="_blank" class="ui-link">' +
        A[i].Filename +
        '</a></td><td>' +
        PrivateCHK +
        '</td><td><div class="vertical-center-container"><button onClick="BILLING_RemoveFRfile(' +
        i +
        ')" data-mini="true" class="action-btn"><img src="./images/delete.svg" /></button></div></td></tr>';
    }
    html += '</tbody></table>';
    $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html(html);
  } else {
    $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html(html + '<h3>' + iTrans('No Attachments') + '</h3>');
  }
}

function BILLING_MarkAttachmentIndex(index, e) {
  var A = CURRENT_FLIGHT_REPORT.Attachments[index];
  A.Private = e.checked ? 1 : 0;
  if (e.checked) {
    $('#FR_PrvCLASS_' + index).addClass('yellowbg');
  } else {
    $('#FR_PrvCLASS_' + index).removeClass('yellowbg');
  }
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url:
      BASEURL +
      'menu-Billing-AJAX.php?action=markFRfile&PK=' +
      A.PrimaryKey +
      '&FRPK=' +
      A.FR_PK +
      '&Private=' +
      A.Private,
    data: {
      PrimaryKey: A.PrimaryKey,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'BILLING_MarkAttachmentIndex()');
      }
      if (data[0].ServerResponse == 'Success') {
        CURRENT_FLIGHT_REPORT.Attachments = data[0].Attachments;
        generateFlightReportAttachmentList();
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem marking the file.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem marking the file.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              generateFlightReportAttachmentList();
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
              generateFlightReportAttachmentList();
            },
          },
        },
      });
    });
}

function BILLING_RemoveFRfile(index) {
  var A = CURRENT_FLIGHT_REPORT.Attachments[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to remove the file') + ' [' + A.Filename + ']?',
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          $.ajax({
            type: 'POST',
            dataType: 'json',
            url: BASEURL + 'menu-Billing-AJAX.php?action=removeFRfile&PK=' + A.PrimaryKey + '&FRPK=' + A.FR_PK,
            data: {
              PrimaryKey: A.PrimaryKey,
            },
          })
            .success(function (data) {
              //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
              console.log('ServerResponse: check');
              console.log(data);
              //ensure data[0].ServerResponse == Success
              if (data[0].ServerResponse == 'Uncaught Exception') {
                ServerResponse(data[0], 'BILLING_removeFRfile()');
              }
              if (data[0].ServerResponse == 'Success') {
                CURRENT_FLIGHT_REPORT.Attachments = data[0].Attachments;
                generateFlightReportAttachmentList();
              }
              if (data[0].ServerResponse == 'Failed') {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('Failed!'),
                  headerClose: false,
                  buttonPrompt: iTrans(
                    'There was a problem removing the file.  Please try again. If this continues to happen please submit a bug report.'
                  ),
                  buttons: {
                    [iTrans('Close')]: {
                      click: function () {},
                    },
                    [iTrans('Report')]: {
                      click: function () {
                        //File bug report
                      },
                    },
                  },
                });
              }
            })
            .fail(function (data) {
              //data.status, data.readyState, data.responseText
              console.log(data);
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Failed!'),
                headerClose: false,
                buttonPrompt: iTrans(
                  'There was a problem removing the file.  Please try again. If this continues to happen please submit a bug report.'
                ),
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                  [iTrans('Report')]: {
                    click: function () {
                      //File bug report
                    },
                  },
                },
              });
            });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //Do nothing
        },
      },
    },
  });
}

function BILLING_FRAttachFile() {
  if (ONLINE) {
    $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html('<h3>' + iTrans('Processing File') + '</h3>');
    $('#BILLING_FR_AttachBTN').hide();
    var file = IMAGE_GET_FileInput('FlightReport');
    if (file != undefined) {
      if (file.type.indexOf('image') != -1) {
        MAX_PHOTO_RESOLUTION = 1024;

        var html = '<fieldset data-role="controlgroup" data-type="vertical" data-mini="true">';
        html +=
          '<label for="IMAGE_TINY">' +
          iTrans('Tiny') +
          ' (512 x 512 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_TINY" value="512" onclick="IMAGE_PREVIEW_RESIZE(this,\'FlightReport\')" />';
        html +=
          '<label for="IMAGE_SMALL">' +
          iTrans('Small') +
          ' (1024 x 1024 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_SMALL" value="1024" checked="checked" onclick="IMAGE_PREVIEW_RESIZE(this,\'FlightReport\')" />';
        html +=
          '<label for="IMAGE_MEDIUM">' +
          iTrans('Medium') +
          ' (2048 x 2048 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_MEDIUM" value="2048" onclick="IMAGE_PREVIEW_RESIZE(this,\'FlightReport\')" />';
        html +=
          '<label for="IMAGE_LARGE">' +
          iTrans('Large') +
          ' (4096 x 4096 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_LARGE" value="4096" onclick="IMAGE_PREVIEW_RESIZE(this,\'FlightReport\')" />';
        html +=
          '<label for="IMAGE_ORIG">' +
          iTrans('Actual Size') +
          ': ' +
          FormatFileSize(file.size) +
          ' <span id="IMAGE_ORIG_DIMENSIONS"></span></label><input type="radio" name="IMAGE_TYPES" id="IMAGE_ORIG" value="99999999" onclick="IMAGE_PREVIEW_RESIZE(this,\'FlightReport\')" />';
        html += '</fieldset>';
        html +=
          '<b>' +
          iTrans('Image Preview - ') +
          ' <span id="IMAGE_SIZE_HLDR"></span></b><div id="IMAGE_PREVIEW_HLDR" style="overflow:scroll;width:100%;height:300px"></div>';
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Select Max Photo Size'),
          headerClose: false,
          buttonPrompt: html,
          buttons: {
            [iTrans('Done')]: {
              click: function () {
                BILLING_ProcessFRAttr(file);
              },
            },
          },
          callbackOpen: function () {
            IMAGE_PREVIEW_RESIZE({ value: 1024 }, 'FlightReport');
            IMAGE_GET_Dimensions(file);
          },
        });
      } else {
        BILLING_ProcessFRAttr(file);
      }
    } else {
      generateFlightReportAttachmentList();
      document.getElementById('BILLING_Edit_Attachment').value = '';
    }
  } else {
    $('#BILLING_FR_AttachBTN').show();
    alert(iTrans('Cannot Add Attachments when Offline'));
    generateFlightReportAttachmentList();
    document.getElementById('BILLING_Edit_Attachment').value = '';
  }
}

function BILLING_ProcessFRAttr(file) {
  var filename = file.name;
  var filesize = file.size;
  if (file.type.indexOf('image') != -1 && MAX_PHOTO_RESOLUTION != 99999999) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var img = document.createElement('img');
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
        var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
        var width = img.width;
        var height = img.height;
        //console.log(width);
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        var dataurl = canvas.toDataURL(file.type);
        document.getElementById('BILLING_Edit_Attachment').value = '';
        BILLING_SendFRAttachment(dataurl.split(',')[1], filename);
      };
      img.src = e.target.result;
    };
    reader.onerror = function (e) {
      alert(iTrans('There was an error resizing the image'));
    };

    reader.readAsDataURL(file);
  } else {
    if (filesize > 5242880) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Max File Size'),
        headerClose: false,
        buttonPrompt:
          '<p>' +
          iTrans('The selected file is over the 5MB size limit.  Please reduce the file size and try again') +
          '</p>',
        buttons: {
          Close: {
            click: function () {
              document.getElementById('BILLING_Edit_Attachment').value = '';
              generateFlightReportAttachmentList();
            },
          },
        },
      });
    } else {
      var reader = new FileReader();
      reader.onerror = function (e) {
        alert('Error with attachment');
        document.getElementById('BILLING_Edit_Attachment').value = '';
        generateFlightReportAttachmentList();
        $('#BILLING_FR_AttachBTN').show();
      };
      reader.onload = function (e) {
        document.getElementById('BILLING_Edit_Attachment').value = '';
        BILLING_SendFRAttachment(btoa(e.target.result), filename);
      };
      reader.readAsBinaryString(file);
    }
  }
}

function BILLING_SendFRAttachment(data, filename) {
  //#SCHfileprogress div for display upload progress
  var PrimaryKey = CURRENT_FLIGHT_REPORT.PrimaryKey;

  $.ajax({
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
            if (percentComplete == 100) {
              $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html('<h2>' + iTrans('Processing') + '...</h2>');
            } else {
              $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html(
                '<h2>' + iTrans('Uploading') + ': ' + percentComplete + '%</h2>'
              );
            }
          }
        },
        false
      );
      xhr.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //console.log('Received: ' + percentComplete);
          }
        },
        false
      );
      return xhr;
    },
    type: 'POST',
    url: BASEURL + 'menu-Billing-AJAX.php?action=AttachFRFile&PrimaryKey=' + PrimaryKey,
    data: {
      file: data,
      filename: filename,
    },
    success: function (data) {
      data = jQuery.parseJSON(data);
      if (data[0].ServerResponse == 'Success' || data[0].ServerResponse == 'Nothing Changed') {
        //console.log('Scheduler Attachment Successfully Updated');
        //$.mobile.changePage( $("#Schedule_Page"), {  reverse: true, changeHash: false });
        $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html('<h2>' + iTrans('Successfully Uploaded') + '</h2>');

        CURRENT_FLIGHT_REPORT.Attachments = data[0].Attachments;
        generateFlightReportAttachmentList();
      } else {
        //insert failed server error
        //console.log('Cert Update Failed: ' + data[0].ServerResponse);
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'AttachFRFile');
        }
        if (data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
        $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html('<h2>' + iTrans('Upload Failed!') + '</h2>');
      }
      $.mobile.loading('hide');
      $('#BILLING_FR_AttachBTN').show();
    },
    fail: function (jqXHR, status, error) {
      $.mobile.loading('hide');
      $('#BILLING_FLIGHT_REPORT_ATTACHMENTS').html('<h2>' + iTrans('Upload Failed!') + '</h2>');
      $('#BILLING_FR_AttachBTN').show();
      AJAXFAIL(error);
    },
  });
}

function generateFlightReportPDF(silent = 0, auto = 0) {
  if (CURRENT_FLIGHT_REPORT.PrimaryKey == 'None') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Cannot Send'),
      headerClose: false,
      buttonPrompt: iTrans('The flight report has not been saved to the server to generate a report number'),
      buttons: {
        [iTrans('Save Now')]: {
          click: function () {
            CURRENT_FLIGHT_REPORT.SaveAttempt = true;
            Billing_SignedFlightReport(true);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            //do nothing
            CURRENT_FLIGHT_REPORT.SaveAttempt = false;
          },
        },
      },
    });

    return;
  }
  CURRENT_FLIGHT_REPORT.SaveAttempt = false;
  //console.log(CURRENT_FLIGHT_REPORT);
  GENERATE_PDF.filename = CURRENT_FLIGHT_REPORT.Date + '_' + CURRENT_FLIGHT_REPORT.Aircraft + '_Flight_Report';
  GENERATE_PDF.showPDF = 0;
  GENERATE_PDF.silent = silent;
  GENERATE_PDF.auto = auto;
  var signedStatus = '';
  if (FLIGHT_REPORT_WAS_SIGNED == 1) signedStatus = ' [SIGNED]';
  GENERATE_PDF.subject =
    iTrans('Flight Report') +
    ' #' +
    CURRENT_FLIGHT_REPORT.PrimaryKey +
    ' ' +
    signedStatus +
    ': ' +
    CURRENT_FLIGHT_REPORT.Date +
    '_' +
    CURRENT_FLIGHT_REPORT.Aircraft;
  GENERATE_PDF.email = $('#BILLING_SEND_FR_TO').val();

  if ($('#BILLING_SEND_FR_TO_CREW').is(':checked')) {
    for (var i in CURRENT_FLIGHT_REPORT.Crew) {
      if (GENERATE_PDF.email != '') GENERATE_PDF.email += ';' + CURRENT_FLIGHT_REPORT.Crew[i].username;
      else GENERATE_PDF.email = CURRENT_FLIGHT_REPORT.Crew[i].username;
    }
  }
  if ($('#BILLING_SEND_FR_TO_PICSIC').is(':checked')) {
    if (GENERATE_PDF.email != '') GENERATE_PDF.email += ';' + CURRENT_FLIGHT_REPORT.PIC_Email;
    else GENERATE_PDF.email = CURRENT_FLIGHT_REPORT.PIC_Email;
    if (CURRENT_FLIGHT_REPORT.SIC_Email != '') GENERATE_PDF.email += ';' + CURRENT_FLIGHT_REPORT.SIC_Email;
  }
  GENERATE_PDF.div = 'BILLING_FLIGHT_REPORT_REVIEW';

  GENERATE_PDF.SendAttr = 0;
  if ($('#BILLING_SEND_ATTR_TO_CLIENT').is(':checked')) {
    GENERATE_PDF.SendAttr = 1;
  }
  GENERATE_PDF.Type = 'FlightReport';
  GENERATE_PDF.PrimaryKey = CURRENT_FLIGHT_REPORT.PrimaryKey;
  //GENERATE_PDF.Type = "FlightReport";
  //GENERATE_PDF.Attachments = CURRENT_FLIGHT_REPORT.Attachments;

  //To prevent double save problem
  if (!ONLINE) Billing_SignedFlightReport(true); //silently save this flight report

  $('#FlightReportPDF_BTN').prop('disabled', true);

  GENERATE_PDF.ViewOnly = 1;
  if ($('#BILLING_EMAIL_SIGNATURE').is(':checked')) {
    GENERATE_PDF.ViewOnly = 0;
    FLIGHT_REPORT_SIGNATURE_REQUESTED = 1;
  }
  GENERATE_PDF.ClientEmail = '';
  if ($('#BILLING_SEND_FR_TO_CLIENT').is(':checked')) {
    GENERATE_PDF.ClientEmail = CURRENT_FLIGHT_REPORT.Job.Email;
  }
  if ($('#BILLING_SEND_FR_TO_JOB_CONTACT').is(':checked')) {
    GENERATE_PDF.ClientEmail += (GENERATE_PDF.ClientEmail == '' ? '' : ';') + CURRENT_FLIGHT_REPORT.Job.contact_email;
  }
  if (!$('#BILLING_SEND_FR_TO_CLIENT').is(':checked') && !$('#BILLING_SEND_FR_TO_JOB_CONTACT').is(':checked')) {
    GENERATE_PDF.ClientEmail = '0';
  }
  GeneratePDF();
  BILLING_OnEmail_FlightReport(CURRENT_FLIGHT_REPORT);
}

function generateFlightReportPDF_ONLY() {
  if (CURRENT_FLIGHT_REPORT.PrimaryKey == 'None') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Cannot Send'),
      headerClose: false,
      buttonPrompt: iTrans('The flight report has not been saved to the server to generate a report number'),
      buttons: {
        [iTrans('Save Now')]: {
          click: function () {
            CURRENT_FLIGHT_REPORT.SaveAttempt = true;
            Billing_SignedFlightReport(true);
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            //do nothing
            CURRENT_FLIGHT_REPORT.SaveAttempt = false;
          },
        },
      },
    });

    return;
  }
  CURRENT_FLIGHT_REPORT.SaveAttempt = false;
  //console.log(CURRENT_FLIGHT_REPORT);
  GENERATE_PDF.filename = CURRENT_FLIGHT_REPORT.Date + '_' + CURRENT_FLIGHT_REPORT.Aircraft + '_Flight_Report';
  GENERATE_PDF.showPDF = 1;
  var signedStatus = '';
  if (FLIGHT_REPORT_WAS_SIGNED == 1) signedStatus = ' [SIGNED]';
  GENERATE_PDF.subject =
    iTrans('Flight Report') +
    ' #' +
    CURRENT_FLIGHT_REPORT.PrimaryKey +
    ' ' +
    signedStatus +
    ': ' +
    CURRENT_FLIGHT_REPORT.Date +
    '_' +
    CURRENT_FLIGHT_REPORT.Aircraft;
  GENERATE_PDF.email = '';
  GENERATE_PDF.div = 'BILLING_FLIGHT_REPORT_REVIEW';

  GENERATE_PDF.SendAttr = 0;
  GENERATE_PDF.Type = 'FlightReport';
  GENERATE_PDF.PrimaryKey = CURRENT_FLIGHT_REPORT.PrimaryKey;
  GENERATE_PDF.landscape = 0;
  if (GROUP_DATA.FR_Template == 3) {
    GENERATE_PDF.landscape = 1;
  }

  //GENERATE_PDF.Type = "FlightReport";
  //GENERATE_PDF.Attachments = CURRENT_FLIGHT_REPORT.Attachments;
  Billing_SignedFlightReport(true); //silently save this flight report
  $('#FlightReportPDF_BTN').prop('disabled', true);
  GENERATE_PDF.silent = 0;
  GENERATE_PDF.ClientEmail = 0;
  GeneratePDF();
}

function BILLING_returnFlightReview() {
  //done display signature if it exists;
  var html = '';
  if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 1)
    html += '<h3>' + iTrans('A Signature is Required for this Client') + '</h3>';
  var emailFRsignature =
    '<label for="BILLING_EMAIL_SIGNATURE">' +
    iTrans('Request Flight Report Signature by Email') +
    '</label><input type="checkbox" id="BILLING_EMAIL_SIGNATURE" onClick="BILLING_toggleEmailSignature(this);" data-mini="true" ' +
    (GROUP_DATA.FR_requestEmailON == 1 ? 'checked' : '') +
    '/>';
  if (!signaturePad.isEmpty()) {
    if ($('#BILLING_SIGNATURE_NAME').val() == '') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Validation'),
        headerClose: false,
        buttonPrompt: iTrans('Please enter a name for this Signature'),
        buttons: {
          [iTrans('Ok')]: {
            click: function () {},
          },
        },
      });

      return;
    }
    FLIGHT_REPORT_WAS_SIGNED = 1;

    var data = signaturePad.toDataURL('image/png');
    signatureDATA.data = data;
    //html = '<img src="'+data+'">';
    BILLING_SIGNATURE_NAME_UPDATE();
    html +=
      '<button class="redbg" data-icon="delete" data-mini="true" onClick="BILLING_DELETE_SIGNATURE()">' +
      iTrans('Remove this signature') +
      '</button>';
  } else {
    signatureDATA.data = '';
    signatureDATA.text = '';

    html +=
      emailFRsignature +
      '<button class="greenbg" data-icon="plus" data-mini="true" onClick="BILLING_OPEN_SIGNATURE()" id="BILLING_OPEN_SIGNATURE_BTN">' +
      iTrans('Sign this flight report') +
      '</button>';
    if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 0) {
      if (CURRENT_FLIGHT_REPORT.Completed == 0 || CURRENT_FLIGHT_REPORT.Completed == -2)
        html +=
          '<label for="BILLING_COMPLETE_CHECK">' +
          iTrans('Mark as complete without signature') +
          '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)"/>';
      else
        html +=
          '<label for="BILLING_COMPLETE_CHECK">' +
          iTrans('Mark as complete without signature') +
          '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)" checked/>';
    } else {
      html +=
        '<label for="BILLING_COMPLETE_CHECK">' +
        iTrans('Mark as complete without signature') +
        '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)" disabled/>';
    }
  }
  $.mobile.changePage($('#BILLING_FlightReview'), {
    changeHash: false,
    reverse: true,
  });

  $('#BILLING_FR_AttachBTN').show();

  $('#BILLING_FLIGHT_REPORT_REVIEW').html('');
  var FlightReport = BILLING_FLIGHT_REPORT_HTML(
    CURRENT_FLIGHT_REPORT,
    true,
    true,
    CURRENT_FLIGHT_REPORT.BLURB,
    CURRENT_FLIGHT_REPORT.headerData
  );
  $('#BILLING_FLIGHT_REPORT_REVIEW').html(FlightReport);
  $('#BILLING_SIGNATURE').html(html);
  if (GROUP_DATA.FR_Template == 3) {
    $('#BILLING_SIGNATURE').hide();
  } else {
    $('#BILLING_SIGNATURE').show();
  }
  $('#BILLING_SIGNATURE').enhanceWithin();
  CURRENT_FLIGHT_REPORT.signature = signatureDATA.data;
  CURRENT_FLIGHT_REPORT.signed_by = signatureDATA.text;
  generateFlightReportAttachmentList();
  BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm);
}

function BILLING_ClearSignature() {
  FR_ResizeSignaturePad();
  CURRENT_FLIGHT_REPORT.signature = '';
  CURRENT_FLIGHT_REPORT.signed_by = '';
}

function BILLING_SIGNATURE_NAME_UPDATE() {
  signatureDATA.text = $('#BILLING_SIGNATURE_NAME').val();
  $('#BILLING_FLIGHT_REPORT_REVIEW').html('');
  var FlightReport = BILLING_FLIGHT_REPORT_HTML(
    CURRENT_FLIGHT_REPORT,
    true,
    true,
    CURRENT_FLIGHT_REPORT.BLURB,
    CURRENT_FLIGHT_REPORT.headerData
  );
  $('#BILLING_FLIGHT_REPORT_REVIEW').html(FlightReport);
  BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm);
}

function BILLING_DELETE_SIGNATURE() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirmation'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to remove the signature from this flight report?'),
    buttons: {
      [iTrans('Remove')]: {
        click: function () {
          signatureDATA = {
            data: '',
            text: '',
          };
          CURRENT_FLIGHT_REPORT.signature = '';
          CURRENT_FLIGHT_REPORT.signed_by = '';
          FLIGHT_REPORT_WAS_SIGNED = 0;
          FLIGHT_REPORT_SIGNATURE_REQUESTED = 0;
          CURRENT_FLIGHT_REPORT.Completed = 0;
          if (CURRENT_FLIGHT_REPORT.Pilot_PK != USER_PK && CURRENT_FLIGHT_REPORT.SIC_PK != USER_PK) {
            CURRENT_FLIGHT_REPORT.Completed = -2; //Admin created FR so Admin can edit it in propgress
          }
          $('#BILLING_SIGNATURE_NAME').val('');
          var html = '';
          var emailFRsignature =
            '<label for="BILLING_EMAIL_SIGNATURE">' +
            iTrans('Request Flight Report Signature by Email') +
            '</label><input type="checkbox" id="BILLING_EMAIL_SIGNATURE" onClick="BILLING_toggleEmailSignature(this);" data-mini="true" />';
          if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 1)
            html += '<h3>' + iTrans('A Signature is Required for this Client') + '</h3>';
          html +=
            emailFRsignature +
            '<button class="greenbg" data-icon="plus" data-mini="true" onClick="BILLING_OPEN_SIGNATURE()" id="BILLING_OPEN_SIGNATURE_BTN">' +
            iTrans('Sign this flight report') +
            '</button>';
          if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 0) {
            if (CURRENT_FLIGHT_REPORT.Completed == 0 || CURRENT_FLIGHT_REPORT.Completed == -2)
              html +=
                '<label for="BILLING_COMPLETE_CHECK">' +
                iTrans('Mark as complete without signature') +
                '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)"/>';
            else
              html +=
                '<label for="BILLING_COMPLETE_CHECK">' +
                iTrans('Mark as complete without signature') +
                '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)" checked/>';
          } else {
            html += '<h3>A Signature is Required for this Client</h3>';
            html +=
              '<label for="BILLING_COMPLETE_CHECK">' +
              iTrans('Mark as complete without signature') +
              '</label><input type="checkbox" id="BILLING_COMPLETE_CHECK" data-mini="true" onClick="BILLING_FR_COMPLETED_TOGGLE(this)" disabled/>';
          }

          $('#BILLING_SIGNATURE').html(html);
          if (GROUP_DATA.FR_Template == 3) {
            $('#BILLING_SIGNATURE').hide();
          } else {
            $('#BILLING_SIGNATURE').show();
          }
          $('#BILLING_SIGNATURE').enhanceWithin();

          $('#BILLING_FLIGHT_REPORT_REVIEW').html('');
          var FlightReport = BILLING_FLIGHT_REPORT_HTML(
            CURRENT_FLIGHT_REPORT,
            true,
            true,
            CURRENT_FLIGHT_REPORT.BLURB,
            CURRENT_FLIGHT_REPORT.headerData
          );
          $('#BILLING_FLIGHT_REPORT_REVIEW').html(FlightReport);
          BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm);
          Billing_SignedFlightReport_Finish(true);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

var signaturePad;
var signatureDATA = {
  data: '',
  text: '',
};

function BILLING_OPEN_SIGNATURE() {
  //goto signature page
  $.mobile.changePage($('#BILLING_Signature'), {
    changeHash: false,
    reverse: false,
  });
  $('#signature-pad-wrapper').html('');
  $('#signature-pad-wrapper').html('<canvas id="signature-pad" class="signature-pad"></canvas>');
  var canvas = document.getElementById('signature-pad');
  signaturePad = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    if (signaturePad.isEmpty()) {
      setTimeout(function () {
        FR_ResizeSignaturePad();
      }, 1000);
    }
  }

  window.addEventListener('orientationchange', resizeCanvas);
  FR_ResizeSignaturePad();
}

function FR_ResizeSignaturePad() {
  var canvas = document.getElementById('signature-pad');
  var ratio = Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext('2d').scale(ratio, ratio);
  signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  window.scrollTo(0, 0);
}

function returnFlightLegPage() {
  $.mobile.changePage($('#BILLING_FlightLegsPage'), {
    changeHash: false,
    reverse: true,
  });
}

function Billing_SignedFlightReport(silent, isFinalSave = false) {
  if (silent || CURRENT_FLIGHT_REPORT.Completed == 1) {
    Billing_SignedFlightReport_Finish(silent, isFinalSave);
    return;
  }
  //Check if FR has been completed
  var signature = signatureDATA.data;
  var complete = 0;
  if (signature.length > 0) complete = 1;
  else if ($('#BILLING_COMPLETE_CHECK').is(':checked')) complete = 1;
  if (CURRENT_FLIGHT_REPORT.signature.length > 0) CURRENT_FLIGHT_REPORT.Completed = 1;
  else CURRENT_FLIGHT_REPORT.Completed = complete;
  if (FLIGHT_REPORT_SIGNATURE_REQUESTED == 0) {
    if (CURRENT_FLIGHT_REPORT.Completed == 0 || CURRENT_FLIGHT_REPORT.Completed == -2) {
      var html = '<h3>' + iTrans('Flight Report NOT Completed') + '!</h3>';
      if (CURRENT_FLIGHT_REPORT.Job.SignatureReq == 1) {
        html += iTrans('The client requires a signature to complete this Flight Report');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt: html,
          buttons: {
            [iTrans('Cancel and Return')]: {
              click: function () {
                //Do nothing;
              },
            },
            [iTrans('Sign Now')]: {
              click: function () {
                BILLING_OPEN_SIGNATURE();
              },
            },
            [iTrans('Save as In-Progress')]: {
              click: function () {
                if (CURRENT_FLIGHT_REPORT.Pilot_PK != USER_PK && CURRENT_FLIGHT_REPORT.SIC_PK != USER_PK) {
                  CURRENT_FLIGHT_REPORT.Completed = -2; //Admin created FR so Admin can edit it in propgress
                }
                Billing_SignedFlightReport_Finish(silent, isFinalSave);
              },
            },
          },
        });
      } else {
        html += iTrans('To complete this Flight Report please sign OR mark it as completed without a signature.');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('WARNING'),
          headerClose: false,
          buttonPrompt: html,
          buttons: {
            [iTrans('Cancel and Return')]: {
              click: function () {
                //Do nothing;
              },
            },
            [iTrans('Mark as Completed')]: {
              click: function () {
                $('#BILLING_COMPLETE_CHECK').prop('checked', true).checkboxradio('refresh');
                CURRENT_FLIGHT_REPORT.Completed = 1;
                Billing_SignedFlightReport_Finish(silent, isFinalSave);
              },
            },
            [iTrans('Sign Now')]: {
              click: function () {
                BILLING_OPEN_SIGNATURE();
              },
            },
            [iTrans('Save as In-Progress')]: {
              click: function () {
                if (CURRENT_FLIGHT_REPORT.Pilot_PK != USER_PK && CURRENT_FLIGHT_REPORT.SIC_PK != USER_PK) {
                  CURRENT_FLIGHT_REPORT.Completed = -2; //Admin created FR so Admin can edit it in propgress
                }
                Billing_SignedFlightReport_Finish(silent, isFinalSave);
              },
            },
          },
        });
      }
    } else {
      Billing_SignedFlightReport_Finish(silent, isFinalSave);
    }
  } else {
    Billing_SignedFlightReport_Finish(silent, isFinalSave);
  }
}

function Billing_SignedFlightReport_Finish(silent, isFinalSave = false) {
  //Prompte to update flight duty times if pilot ytpe
  if (VIEWING_ARCHIVED) return;
  if (!silent) {
    $.mobile.loading('show', {
      theme: 'a',
    });
  }
  $('#Billing_SaveFlightReportBTN').prop('disabled', true);
  //return to flight reports page
  //Set completed flag on flight report server side
  var FlightReportPK = $('#BILLING_FPimaryKey').val();

  CURRENT_FLIGHT_REPORT.custom_emails = $('#BILLING_SEND_FR_TO').val();

  CURRENT_FLIGHT_REPORT.html = $('#BILLING_FLIGHT_REPORT_REVIEW').html();

  CURRENT_FLIGHT_REPORT.FLIGHT_REPORT_WAS_SIGNED = FLIGHT_REPORT_WAS_SIGNED;
  if (FLIGHT_REPORT_SIGNATURE_REQUESTED == 1) CURRENT_FLIGHT_REPORT.Completed = -1;
  BillingDB('INSERT', 'CURRENT_FLIGHT_REPORT', CURRENT_FLIGHT_REPORT, StoredConfirm); //save flight repot state before sending
  if (!BILLING_EDITING_OFFLINE) {
    //console.log(CURRENT_FLIGHT_REPORT);
    REFRESH_FR_LIST = true;
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + `menu-Billing-AJAX.php?action=SubmitFlightReport${isFinalSave ? '&isFinalSave=1' : ''}`,
      data: CURRENT_FLIGHT_REPORT,
      timeout: 120000, //2 minutes
    })
      .success(function (json_data) {
        if (!silent) {
          $.mobile.loading('hide');
          //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
          if (json_data[0].ServerResponse != undefined) {
            //status could be offline/Not Logged In/Uncaught Exception
            if (json_data[0].ServerResponse == 'Offline') {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Offline'),
                headerClose: false,
                buttonPrompt:
                  iTrans('You are currently offline.') +
                  '<br />' +
                  iTrans(
                    'If you wish to connect now you can resend your flight report or just store it offline until you connect at a later time.'
                  ) +
                  '</b>',
                buttons: {
                  [iTrans('Resend')]: {
                    click: function () {
                      Billing_SignedFlightReport(false);
                    },
                  },
                  [iTrans('Store Offline')]: {
                    click: function () {
                      //Uncheck the non-billable checkbox

                      $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                      BILLING_STORE_OFFLINE_REPORT(silent);
                    },
                  },
                },
              });
            }
            if (json_data[0].ServerResponse == 'Uncaught Exception')
              ServerResponse(json_data[0], 'BILLING_SendFlightLeg');
            if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
            if (json_data[0].ServerResponse == 'Success') {
              //remove signature data after success to ensure duplicates don't get saved
              BillingDB('DELETE', 'CURRENT_FLIGHT_REPORT', null, StoredConfirm); //delete Flight Report Data it has been Sent!!!
              signatureDATA.data = '';
              signatureDATA.text = '';
              if (CURRENT_FLIGHT_REPORT.PrimaryKey == 'None') {
                CURRENT_FLIGHT_REPORT.PrimaryKey = json_data[0].ReportPK;
              }
              if (GROUP_DATA.FuelCache == 1 && isFinalSave) {
                CURRENT_FLIGHT_REPORT.FC_Uplifts = json_data[0].FC_Uplifts;
                FC_Uplift.upLiftList = CURRENT_FLIGHT_REPORT.FC_Uplifts;
                BackgroundSync(false, true);
              }

              if (CURRENT_FLIGHT_REPORT.AlertList == undefined) CURRENT_FLIGHT_REPORT.AlertList = [];
              if (CURRENT_FLIGHT_REPORT.AlertList.length > 0) {
                //this gets called on save too
                $('#BILLING_SEND_FR_TO_CREW').prop('checked', false).checkboxradio('refresh');
                GENERATE_PDF.email = '';
                GENERATE_PDF.silent = 1;
                try {
                  $('#BILLING_SEND_FR_TO_PICSIC').prop('checked', false).checkboxradio('refresh');
                  $('#BILLING_SEND_FR_TO_LISTTXT').prop('checked', false).checkboxradio('refresh');
                  $('#BILLING_SEND_FR_TO_CREW').prop('checked', false).checkboxradio('refresh');
                } catch (e) {
                  $('#BILLING_SEND_FR_TO_PICSIC').prop('checked', false);
                  $('#BILLING_SEND_FR_TO_LISTTXT').prop('checked', false);
                  $('#BILLING_SEND_FR_TO_CREW').prop('checked', false);
                }

                generateFlightReportPDF(1, 1); //Trigger silent send with auto email checks
              }
              if (
                userShouldHaveFDT(USERTYPE) &&
                (chatname == CURRENT_FLIGHT_REPORT.Pilot || chatname == CURRENT_FLIGHT_REPORT.SIC) &&
                VueBridge.hasPermission(['tasksAndManagement.timeManagement.use'])
              ) {
                BILLING_NewFlightReport(false, true);
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('Confirm'),
                  headerClose: false,
                  buttonPrompt: iTrans('Would you like to update your flight duty times now?'),
                  buttons: {
                    [iTrans('Yes')]: {
                      click: function () {
                        //Mark Invoice as Paid
                        $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                        //console.log(json_data[0].Report);
                        $.mobile.changePage($('#DutyTimes'), {
                          changeHash: false,
                        });

                        BILLING_CheckFDTImportPrompts(json_data[0].Report);
                      },
                    },
                    [iTrans('No')]: {
                      click: function () {
                        $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                        //cirroHOME();
                      },
                    },
                  },
                });
              } else {
                $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                //cirroHOME();
                BILLING_NewFlightReport(false, true);
              }
            }
          }
        } else {
          //##################### SILENT SAVEING
          $('#Billing_SaveFlightReportBTN').prop('disabled', false);
          if (json_data[0].ServerResponse == 'Success') {
            //remove signature data after success to ensure duplicates don't get saved
            BillingDB('DELETE', 'CURRENT_FLIGHT_REPORT', null, StoredConfirm); //delete Flight Report Data it has been Sent!!!
            signatureDATA.data = '';
            signatureDATA.text = '';

            if (CURRENT_FLIGHT_REPORT.PrimaryKey == 'None' && CURRENT_FLIGHT_REPORT.SaveAttempt === true) {
              CURRENT_FLIGHT_REPORT.PrimaryKey = json_data[0].ReportPK;
              VIEWONLY = true;
              Billing_CompleteFlightReport_display();
            } else {
              if (CURRENT_FLIGHT_REPORT.PrimaryKey == 'None') {
                CURRENT_FLIGHT_REPORT.PrimaryKey = json_data[0].ReportPK;
                Billing_CompleteFlightReport_display();
              }
            }
          }
        }
      })
      .fail(function (data) {
        $.mobile.loading('hide');
        if (!silent) {
          //data.status, data.readyState, data.responseText
          //console.log(data);
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('ERROR'),
            headerClose: false,
            buttonPrompt:
              iTrans(
                'There was a problem sending this flight report to the server.  Would you like to try resending?'
              ) +
              '<br /><b>' +
              iTrans("If you're offline the report will be submitted when you're online next time") +
              '</b>',
            buttons: {
              [iTrans('Resend')]: {
                click: function () {
                  Billing_SignedFlightReport(false);
                },
              },
              [iTrans('Store Offline')]: {
                click: function () {
                  //Uncheck the non-billable checkbox

                  $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                  BILLING_STORE_OFFLINE_REPORT(silent);
                },
              },
            },
          });
        } else {
          $('#Billing_SaveFlightReportBTN').prop('disabled', false);
          if (CURRENT_FLIGHT_REPORT.SaveAttempt === true) {
            CURRENT_FLIGHT_REPORT.SaveAttempt = false;
            alert('Failed to send to server');
          }
        }
      });
  } else {
    //offline or editing an offline itinerary
    if (!silent) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      if (BILLING_EDITING_OFFLINE) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt:
            iTrans('You are currently editing an offline Flight Report') +
            '<br /><b>' +
            iTrans("Your offline Flight Report report will be submitted when you're online next time") +
            '</b>',
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //Uncheck the non-billable checkbox
                REFRESH_FR_LIST = true;

                $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                BILLING_STORE_OFFLINE_REPORT(silent);
              },
            },
          },
        });
        $.mobile.loading('hide');
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt:
            iTrans('You are currently offline') +
            '<br /><b>' +
            iTrans("Your offline Flight Report report will be submitted when you're online next time") +
            '</b>',
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //Uncheck the non-billable checkbox
                REFRESH_FR_LIST = true;

                $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                BILLING_STORE_OFFLINE_REPORT(silent);
              },
            },
          },
        });
        $.mobile.loading('hide');
      }
    } else {
      if (BILLING_EDITING_OFFLINE) {
        REFRESH_FR_LIST = true;
        BILLING_STORE_OFFLINE_REPORT(silent);
      } else {
        $('#Billing_SaveFlightReportBTN').prop('disabled', false);
        if (CURRENT_FLIGHT_REPORT.SaveAttempt === true) {
          CURRENT_FLIGHT_REPORT.SaveAttempt = false;
          alert('Failed to send to server');
        }
      }
    }
  }
}

function BILLING_STORE_OFFLINE_REPORT(silent) {
  BillingDB('SELECT', 'OFFLINE_SYNC', null, function (data) {
    BILLING_Retrieved_SyncItems(data, silent);
  });
}

function BILLING_Retrieved_SyncItems(data, silent) {
  CURRENT_FLIGHT_REPORT.OFFLINE_EMAIL = generateFlightReportOFFLINE_EMAIL();
  if (data == false) {
    //No current offline Data to append to
    data = [];
  }
  if (CURRENT_FLIGHT_REPORT.OfflineIndex != undefined) {
    //if editing existing offline flight report replace it with the udpated data
    data[CURRENT_FLIGHT_REPORT.OfflineIndex] = CURRENT_FLIGHT_REPORT;
  } else {
    data.push(CURRENT_FLIGHT_REPORT);
  }
  BillingDB('INSERT', 'OFFLINE_SYNC', data, function (stored) {
    BILLING_OfflineStored(stored, silent);
  }); // Store CURRENT_FLIGHT_REPORT to offline sync library
}

function BILLING_OfflineStored(stored, silent) {
  if (!silent) {
    if (!stored) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt:
          '<b>' +
          iTrans(
            'There was a problem storing this flight report for offline synchronization.  This is really bad Sorry :(.  Please let our tech support know.  You can try again now if you would like.'
          ) +
          '</b>',
        buttons: {
          [iTrans('Try Again')]: {
            click: function () {
              BILLING_STORE_OFFLINE_REPORT(silent);
            },
          },
          [iTrans('No')]: {
            click: function () {
              CURRENT_FLIGHT_REPORT = []; //empty current flight report variable
              BILLING_FLIGHTLEGS = [];
              $('#Billing_SaveFlightReportBTN').prop('disabled', false);
              //cirroHOME();
              BILLING_EDITING_OFFLINE = false;
              BILLING_NewFlightReport(false, false);
            },
          },
        },
      });
    } else {
      var ItinPK = CURRENT_FLIGHT_REPORT.Itin_ID;
      var ReportPK = CURRENT_FLIGHT_REPORT.PrimaryKey;
      if (ReportPK == 'None') {
        if (ItinPK != 0) {
          //todo Sift through Itins and Other itins offline data to remove itin index if matched so users can't report on it again offline
          //BILLING_search_remove_Itin(ItinPK); Stop doing this since we allow multiple FR's to be made from 1 itinerary now
        }
      } else {
        //todo Sift through editable flight reports and remove items that matched edited offline reports
        BILLING_search_remove_Editable(ReportPK);
      }

      CURRENT_FLIGHT_REPORT = []; //empty current flight report variable
      BILLING_FLIGHTLEGS = [];
      $('#Billing_SaveFlightReportBTN').prop('disabled', false);
      //cirroHOME();
      BillingDB('DELETE', 'CURRENT_FLIGHT_REPORT', null, StoredConfirm); //delete Flight Report Data it has been Sent!!!
      BILLING_EDITING_OFFLINE = false;
      BILLING_NewFlightReport(false, true);
      BILLING_EDITING_OFFLINE = false;
    }
  } else {
    //Silent background save
    $('#Billing_SaveFlightReportBTN').prop('disabled', false);
    if (CURRENT_FLIGHT_REPORT.SaveAttempt === true) {
      CURRENT_FLIGHT_REPORT.SaveAttempt = false;
    }
  }
}

function BILLING_search_remove_Itin(Itin_PK) {
  //Remove Itinerary from offline reportable lists
  var OI = BILLING_FLIGHTREPORTS[0].OtherItins;
  for (var O in OI) {
    if (OI[O].PrimaryKey == Itin_PK) OI.splice(O, 1);
  }
  var PI = BILLING_FLIGHTREPORTS[0].PilotItins;
  for (var P in PI) {
    if (PI[P].PrimaryKey == Itin_PK) PI.splice(P, 1);
  }
  BillingDB('INSERT', 'Itinerary', BILLING_FLIGHTREPORTS, StoredConfirm); //Store Itinerary Data
}

function BILLING_search_remove_Editable(Report_PK) {
  //Remove Itinerary from offline reportable lists
  var PI = BILLING_FLIGHTREPORTS[0].Uncompleted;
  for (var P in PI) {
    if (PI[P].PrimaryKey == Report_PK) PI.splice(P, 1);
  }
  BillingDB('INSERT', 'Itinerary', BILLING_FLIGHTREPORTS, StoredConfirm); //Store Itinerary Data
}

function BI_Get_DutyTimes(Date) {
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=getDutyTimes&Date=' + Date,
    data: null,
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot get dut times') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'BI_Get_DutyTimes');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        if (json_data[0].ServerResponse == 'Success') {
          if (USERTYPE != 'Guest') {
            var promptText = '';
            if (json_data[0].Report.OvernightReport.Overnight == true) {
              promptText += iTrans('The flight reports contain overnight data.') + '<br />';
              promptText +=
                iTrans(
                  'This will overwrite your current duty time and aircraft experience records for this day and the next.'
                ) + '<br />';
            } else {
              promptText +=
                iTrans('This will overwrite your current duty time and aircraft experience records for this day.') +
                '<br />';
            }

            promptText += '<center><h3>' + iTrans('Are you sure?') + '</h3></center>';

            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Confirm'),
              headerClose: false,
              buttonPrompt: promptText,
              buttons: {
                Yes: {
                  click: function () {
                    //Mark Invoice as Paid
                    //console.log(json_data[0].Report);
                    //$.mobile.changePage( $("#DutyTimes"), {  changeHash: false });
                    BILLING_CheckFDTImportPrompts(json_data[0].Report);
                  },
                },
                No: {
                  click: function () {},
                },
              },
            });
          }
        }
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a Retrieving your flight report data to populate your duty times.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BILLING_CheckFDTImportPrompts(data) {
  FDT_REPORT = data;

  if (FDT_REPORT.FR.PerLegHours != 0 && COUNTRY != 'CANADA') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Multiple Times'),
      headerClose: false,
      buttonPrompt:
        iTrans(
          'Per Leg Hours have also been found.  Would you like to override calculated leg flight times and use the Per Leg Hours?'
        ) +
        '<br /><br /><b>' +
        iTrans('Replace with Per Leg Hours') +
        ': </b>' +
        FDT_REPORT.FR.PerLegHours +
        '<br /><b>' +
        iTrans('Current PIC / SIC Times') +
        '</b><br /><b>PIC: </b>' +
        FDT_REPORT.PIC +
        '<br /><b>SIC: </b>' +
        FDT_REPORT.SIC,
      buttons: {
        [iTrans('Replace with Per Leg')]: {
          click: function () {
            FDT_REPORT.PIC = FDT_REPORT.FR.PerLegHours;
            FDT_REPORT.SIC = 0;
            FDT_REPORT.TOTAL = FDT_REPORT.FR.PerLegHours;
            for (var i in FDT_REPORT.Experience) {
              var I = FDT_REPORT.Experience[i];
              I.SIC = 0;
              I.PIC = FDT_REPORT.FR.PerLegHours;
              I.Total = FDT_REPORT.FR.PerLegHours;
            }
            //$("#calendar").hide(); //empty calendar div
            $('#calendar').hide(); //empty calendar div
            $('#calendar').html('');
            $('#calendar').removeClass();

            initDutyTimeImport(); //menu-Duty.js
          },
        },
        [iTrans('Use Original')]: {
          click: function () {
            //$("#calendar").hide(); //empty calendar div
            //FDT_REPORT.FR.HobbsHours = 0;
            $('#calendar').hide(); //empty calendar div
            $('#calendar').html('');
            $('#calendar').removeClass();

            initDutyTimeImport(); //menu-Duty.js
          },
        },
      },
    });
    return;
  }

  if (FDT_REPORT.FR.PerReportHours != 0 && COUNTRY != 'CANADA') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Multiple Times'),
      headerClose: false,
      buttonPrompt:
        iTrans(
          'Per Report Hours have also been found.  Would you like to override leg flight times and use the Per Report Hours instead?'
        ) +
        '<br /><br /><b>' +
        iTrans('Replace with Per Report Hours') +
        ': </b>' +
        FDT_REPORT.FR.PerReportHours +
        '<br /><b>' +
        iTrans('Current PIC / SIC Times') +
        '</b><br /><b>PIC: </b>' +
        FDT_REPORT.PIC +
        '<br /><b>SIC: </b>' +
        FDT_REPORT.SIC,
      buttons: {
        [iTrans('Replace with Per Report')]: {
          click: function () {
            FDT_REPORT.PIC = FDT_REPORT.FR.PerReportHours;
            FDT_REPORT.SIC = 0;
            for (var i in FDT_REPORT.Experience) {
              var I = FDT_REPORT.Experience[i];
              I.SIC = 0;
              I.PIC = FDT_REPORT.FR.PerReportHours;
              I.Total = FDT_REPORT.FR.PerReportHours;
            }
            //$("#calendar").hide(); //empty calendar div
            $('#calendar').hide(); //empty calendar div
            $('#calendar').html('');
            $('#calendar').removeClass();

            initDutyTimeImport(); //menu-Duty.js
          },
        },
        [iTrans('Use Original')]: {
          click: function () {
            //$("#calendar").hide(); //empty calendar div
            //FDT_REPORT.FR.HobbsHours = 0;
            $('#calendar').hide(); //empty calendar div
            $('#calendar').html('');
            $('#calendar').removeClass();

            initDutyTimeImport(); //menu-Duty.js
          },
        },
      },
    });
    return;
  }

  if (FDT_REPORT.FR.HobbsHours != 0 && COUNTRY != 'CANADA') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Multiple Times'),
      headerClose: false,
      buttonPrompt:
        iTrans(
          'Hobbs Times have also been found.  Would you like to override leg flight times and use the Hobbs instead?'
        ) +
        '<br /><br /><b>' +
        iTrans('Replace with Hobbs') +
        ': </b>' +
        FDT_REPORT.FR.HobbsHours +
        '<br /><b>' +
        iTrans('Current PIC / SIC Times') +
        '</b><br /><b>PIC: </b>' +
        FDT_REPORT.PIC +
        '<br /><b>SIC: </b>' +
        FDT_REPORT.SIC,
      buttons: {
        [iTrans('Replace with Hobbs')]: {
          click: function () {
            FDT_REPORT.PIC = FDT_REPORT.FR.HobbsHours;
            FDT_REPORT.SIC = 0;
            for (var i in FDT_REPORT.Experience) {
              var I = FDT_REPORT.Experience[i];
              I.SIC = 0;
              I.PIC = FDT_REPORT.FR.HobbsHours;
              I.Total = FDT_REPORT.FR.HobbsHours;
            }
            //$("#calendar").hide(); //empty calendar div
            $('#calendar').hide(); //empty calendar div
            $('#calendar').html('');
            $('#calendar').removeClass();

            initDutyTimeImport(); //menu-Duty.js
          },
        },
        [iTrans('Use Original')]: {
          click: function () {
            //$("#calendar").hide(); //empty calendar div
            FDT_REPORT.FR.HobbsHours = 0;
            $('#calendar').hide(); //empty calendar div
            $('#calendar').html('');
            $('#calendar').removeClass();

            initDutyTimeImport(); //menu-Duty.js
          },
        },
      },
    });
    return;
  }
  //$("#calendar").hide(); //empty calendar div
  $('#calendar').hide(); //empty calendar div
  $('#calendar').html('');
  $('#calendar').removeClass();
  initDutyTimeImport(); //menu-Duty.js
}

function initDutyTimeImport() {
  loadFdtModule();
}

/**
 * @Deprecated 24.25.2 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
function import_FR_FDTV2() {
  //Add revamp FDT import engine here
  cirroDB.query('AC', 'ident != ?', '', function (aircraft) {
    try {
      let importDate = new Date(FDT_REPORT.Date + 'T00:00:00Z');
      importDate.setDate(importDate.getDate() - 1);
      let SearchFrom = importDate.toISOString().substr(0, 10);

      cirroDB.query('FDT_DaysV4', 'Date >= ? ORDER BY Date ASC LIMIT 3', [SearchFrom], function (e) {
        try {
          var result = e;

          if (result === false || result.length < 1) {
            // do nothing
            alert(iTrans('Cannot import to FDT records found!'));
          } else {
            console.log('Loading Default FDT Rec Settings');
            //plugin.settings.events.push(result.value);
            var curRec = result[1];

            Standby = 0;
            IFR = curRec.IFR;
            Heli = curRec.Heli;
            DHC = curRec.DHC;
            Dual = curRec.Dual;
            Logging = curRec.Logging;
            AerialApp = curRec.AerialApp;
            TimezoneHB = curRec.TimezoneHB;
            FDTdefault_homeBase = curRec.homeBase;
            FDTdefault_homeBase2 = curRec.homeBase2;
            FDTdefault_Timezone = curRec.Timezone;
            FDTdefault_travelTime = curRec.travelTime;
            FDTdefault_Sectors = curRec.Sectors;
            FDTdefault_Medevac = curRec.medevac;

            Rule = SetDefaultFDT_Rule(curRec.Rule); //in sync-IOS-FDT.js
            Rule_ID = curRec.Rule_ID;
            AverageFlightTime = curRec.ET2;
            let prevRec = result[0];
            let nextRec = null;
            if (result[2] != undefined) {
              nextRec = result[2];
            }
            let ImportFR = new fdtImport(TimezoneHB, FDT_REPORT, prevRec, curRec, nextRec, aircraft);
            ImportFR.confirmImportFlightReports();
          }
        } catch (e) {
          console.log(e);
        }
      });
    } catch (e) {
      console.log(e);
    }
  });
}

function decimalToHHMM(val) {
  var HH = Math.floor(val);
  var MM = Math.round((val % 1) * 60);
  if (MM < 10) MM = '0' + MM.toString();
  var value = HH.toString() + ':' + MM.toString();
  return validateHHMM(value);
}

function validateHHMM(value) {
  value = value.replace(/[^0-9]/, '');
  value = parseInt(value);
  value = value.toString();
  var HH = '00';
  var MM = '00';
  if (value.length == 4) {
    HH = value.substr(0, 2);
    MM = value.substr(2, 2);
  }
  if (value.length == 3) {
    HH = value.substr(0, 1);
    MM = value.substr(1, 2);
  }
  if (value.length == 2) {
    HH = '00';
    MM = value;
  }
  if (value.length == 1) {
    HH = '00';
    MM = '0' + value;
  }
  MM = parseInt(MM);
  HH = parseInt(HH);
  if (MM > 59) {
    HH++;
    MM = MM - 60;
  }
  if (MM < 10) {
    MM = MM.toString();
    MM = '0' + MM;
  } else MM = MM.toString();

  if (HH > 24) HH = 24; //set maximum 24 hour flight time
  if (HH < 10) HH = '0' + HH.toString();
  value = HH + ':' + MM;
  return value;
}
