/**
 * Page class for the Preferences editor
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_PreferencesEditor extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_AD_Preferences', SAMA.pages.MenuAdmin);
    this.header = new cSAMA_PageHeader('Preferences & Keywords', this.dom.page);
    this.resLock = new cSAMA_ResourceLock({
      what: 'PREFS',
      name: 'SMS Preferences',
      pk: 'group',
      fnOpen: () => this.GetData(),
      fnLocked: () => this.PageLoadFailed(),
    });
  }

  EnterPage() {
    this.resLock.BeginLocking();
  }

  GetData() {
    let requests = [
      new cSAMA_Request('Preferences', 'getData'),
      new cSAMA_Request('Keywords', 'getAllCategories'),
      new cSAMA_Request('ReportCategory', 'getData'),
    ];

    cSAMA_Request.Dispatch({
      name: 'GetPreferencesPage',
      reqs: requests,
      clientErrorCode: 'SAMA CA EN DE FU',
      fnSuccess: (_r) => this.SetUpGui(_r),
    });
  }

  SetUpGui(_payload) {
    this.kwdCategories = [];
    this.reportCategories = [];
    let categoryTrackingCheckBox = this.dom.page.find('input[data-sref="prf_addCategoryTracking"]');

    SAMA.settings.preferences = new mSAMA_Preferences(_payload[0]);

    this.viewKwdCatEdit = new vSAMA_TabbedEditor({
      addButtonLabel: 'Create Keyword Category...',
      noDataRider: 'No Categories loaded',
      noDataBody: "No Categories were loaded from the server. That's an error. Please try again.",
      readOnly: false,
    });

    this.viewReportCat = new vSAMA_TabReportCategory({
      addButtonLabel: 'Create New Category',
      noDataRider: 'No Categories loaded',
      noDataBody: "No Categories were loaded from the server. That's an error. Please try again.",
      readOnly: false,
    });

    this.viewPrefsEdit = new vSAMA_PreferencesEditor(this.dom.page, this.kwdCategories, this.reportCategories);

    for (let cat = 0; cat < _payload[1].length; cat++) {
      this.AddCategory(_payload[1][cat]);
    }
    this.viewKwdCatEdit.Refresh();
    this.viewKwdCatEdit.GetAddButton().on('click.samaevt', () => this.CreateNewCategory());
    this.viewKwdCatEdit.GetDom().appendTo(this.dom.page.find('#category-tabEdit'));

    for (let reportCatIdx = 0; reportCatIdx < _payload[2].length; reportCatIdx++) {
      this.AddReportCategory(_payload[2][reportCatIdx]);
    }
    this.viewReportCat.Refresh();
    this.viewReportCat.GetAddCategoryButton().on('click.samaevt', () => this.CreateNewReportCategory());
    this.viewReportCat.GetDom().appendTo(this.dom.page.find('#reportCategory-tabEdit'));

    if (SAMA.settings.preferences.sync.prf_addCategoryTracking === false) {
      this.dom.page.find('#require-reportCategory').hide();
      this.viewReportCat.GetDom().hide();
    }

    categoryTrackingCheckBox.change(() => {
      if (categoryTrackingCheckBox.is(':checked')) {
        this.viewReportCat.GetDom().show();
        this.dom.page.find('#require-reportCategory').show();
      } else {
        this.dom.page.find('#require-reportCategory').hide();
        this.viewReportCat.GetDom().hide();
      }
    });

    this.header.ButtonSaveDiscard();
    SAMA.Navigator.FinishedLoading(this.header);
  }

  AddCategory(_catData) {
    let cat = new mSAMA_KwdCategory(_catData, this.kwdCategories.length);
    let catEd = new vSAMA_TabKwdCategory(cat, false);

    this.viewPrefsEdit.Observe(catEd, 'AttemptCategoryDelete', (_dt) => this.viewPrefsEdit.ShowDeleteDropdown(_dt));
    this.viewPrefsEdit.Observe(catEd, 'CategoryEdited', () => this.viewPrefsEdit.OnCategoryEdited());
    catEd.Observe(this.viewPrefsEdit, 'DisableDelete', (_cpk) => catEd.CheckDisableDelete(_cpk));

    this.kwdCategories.push(cat);
    this.viewKwdCatEdit.AddTab(catEd);
  }

  CreateNewCategory() {
    this.AddCategory();
    this.viewPrefsEdit.ClearTaggers(false);
    this.viewKwdCatEdit.Refresh();
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  AddReportCategory(_repCatData) {
    let reportCategory = new mSAMA_ReportCategory(_repCatData, this.reportCategories.length);
    let reportCategoryEdit = new vSAMA_TabEditReportCategory(reportCategory, false);

    this.viewPrefsEdit.Observe(reportCategoryEdit, 'AttemptCategoryDelete', (_dt) =>
      this.viewPrefsEdit.ShowDelRepCategoryConf(_dt)
    );
    this.viewPrefsEdit.Observe(reportCategoryEdit, 'CategoryEdited', () => this.viewPrefsEdit.OnCategoryEdited());
    // reportCategoryEdit.Observe(this.viewPrefsEdit, 'DisableDelete', (_cpk) => catEd.CheckDisableDelete(_cpk));

    this.reportCategories.push(reportCategory);
    this.viewReportCat.AddTab(reportCategoryEdit);
  }

  CreateNewReportCategory() {
    this.AddReportCategory();
    this.viewPrefsEdit.ClearTaggers(false);
    this.viewReportCat.Refresh();
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  /**
   * Saves changes made on this page.
   */
  SaveHandler() {
    let settings = SAMA.settings.preferences.GetTransmissible();
    let bannedKeywords = this.viewPrefsEdit.GetBlacklistedTags();
    let addedKeywords = this.viewPrefsEdit.GetAddedTags();
    let replaceTags = this.viewPrefsEdit.GetReplaceTags();
    let modCategories = [];
    let reportCategories = [];
    let requests = [];

    for (let kc = 0; kc < this.kwdCategories.length; kc++) {
      let c = this.kwdCategories[kc].GetTransmissible();

      if (c != null) {
        modCategories.push(c);
      }
    }

    for (let rptCategoryIdx = 0; rptCategoryIdx < this.reportCategories.length; rptCategoryIdx++) {
      let reportCat = this.reportCategories[rptCategoryIdx].GetTransmissible();

      if (reportCat != null) {
        reportCategories.push(reportCat);
      }
    }

    let reqPrefs;
    if (settings != null) {
      reqPrefs = new cSAMA_Request('Preferences', 'setData');
      reqPrefs.Set('settings', settings);
      requests.push(reqPrefs);
    }

    let reqRepCategory;
    if (reportCategories.length > 0) {
      reqRepCategory = new cSAMA_Request('ReportCategory', 'setFromPreferences');
      reqRepCategory.Set('reportCategories', reportCategories);
      requests.push(reqRepCategory);
    }

    let reqKeywords;
    let hasReplTags = replaceTags.replace.length > 0 && replaceTags.with.length > 0;
    if (bannedKeywords.length > 0 || addedKeywords.length > 0 || modCategories.length > 0 || hasReplTags) {
      reqKeywords = new cSAMA_Request('Keywords', 'setFromPreferences');

      if (addedKeywords.length > 0) {
        reqKeywords.Set('addedKeywords', addedKeywords);
      }

      if (modCategories.length > 0) {
        reqKeywords.Set('modCategories', modCategories);
      }

      if (hasReplTags) {
        reqKeywords.Set('replaceTags', replaceTags);
        bannedKeywords = bannedKeywords.concat(replaceTags.replaceTags);
      }

      if (bannedKeywords.length > 0) {
        reqKeywords.Set('bannedKeywords', bannedKeywords);
      }
      requests.push(reqKeywords);
    }

    cSAMA_Request.Dispatch({
      name: 'UpdatePreferences',
      reqs: requests,
      clientErrorCode: 'SAMA 5A 0D 37 57',
      goBackOnError: false,
      keepStateOnError: true,
      fnSuccess: (_r) => this.UpdatePks(_r),
    });
  } // End SaveHandler

  /**
   * Update the local PKs to match server side ones
   * @param {{cpk:int[]}} _payload
   */
  UpdatePks(_payload) {
    let categoryPks;
    let reportCategoryPks;
    for (let p = 0; p < _payload.length; p++) {
      if (_payload[p].hasOwnProperty('cpk')) {
        categoryPks = _payload[p].cpk;
      }

      if (_payload[p].hasOwnProperty('rptCategoryPks')) {
        reportCategoryPks = _payload[p].rptCategoryPks;
      }
    }

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
    SAMA.settings.preferences.UpdateProfanityLock();
    this.viewPrefsEdit.ClearTaggers(true);

    this.UpdatePkCategories(this.kwdCategories, categoryPks, true);
    this.UpdatePkCategories(this.reportCategories, reportCategoryPks);

    let tabs = this.viewKwdCatEdit.GetTabs();
    for (let ked = 0; ked < tabs.length; ked++) {
      tabs[ked].EnableDeleteButton();
    }
  }

  /**
   * Set primary keys
   * @param categories
   * @param categoryPks
   * @param doClearStatus
   * @constructor
   */
  UpdatePkCategories(categories, categoryPks, doClearStatus = false) {
    if (categoryPks == null) {
      return;
    }

    for (let i = 0; i < categories.length; i++) {
      let category = categories[i];
      category.SetModified(false);

      if (doClearStatus) {
        category.ClearTakeStatus();
      }

      if (!category.IsNew()) {
        continue;
      }

      let oldPk = category.GetPk();
      if (categoryPks.hasOwnProperty(oldPk)) {
        category.SetPk(categoryPks[oldPk]);
      }
    }
  }

  LeavePage() {
    this.resLock.StopLocking();
    SAMA.Navigator.FinishedLoading();

    if (!this.loaded) {
      return;
    }
    this.viewKwdCatEdit.Destroy();
    this.viewReportCat.Destroy();
    this.viewPrefsEdit.Destroy();

    this.viewPrefsEdit = null;
    this.viewKwdCatEdit = null;
    this.viewReportCat = null;
  }
}
