/**
 * Class for tiles that can be introspected
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_AbstractTile extends vSAMA_AbstractFilterableItem {
  SetUpGui(_data) {
    this.data = _data;
    this.dom = {
      body: null,
      rowContainer: null,
      buttonContainer: null,
      buttons: [],
    };

    this.dom.body = $(
      SAMA.Templates.tpl.general.cards.body.formatTemplate({
        title: _data.title,
        id: _data.id,
        wrapperClasses: _data.wrapperClasses || '',
      })
    );

    this.dom.rowContainer = this.dom.body.find('.sama-card-rows');
    this.dom.buttonContainer = this.dom.body.find('.sama-card-buttons');

    _data.rows.forEach((_r) => this.AddRow(_r));
    _data.buttons.forEach((_b) => this.AddButton(_b).on('click', _b.fnClick));

    this.dom.body.enhanceWithin();
  }

  AddRow(_data) {
    // Don't show placeholders
    if (_data.label == null) {
      return;
    }

    if (_data.classes == null) {
      _data.classes = '';
    }

    let formatted;

    if (_data.label.length > 0) {
      formatted = $(SAMA.Templates.tpl.general.cards.row.formatTemplate(_data));
    } else {
      formatted = $(SAMA.Templates.tpl.general.cards.wideRow.formatTemplate(_data));
    }

    this.dom.buttons.push(formatted);
    return formatted.appendTo(this.dom.rowContainer);
  }

  AddButton(_data) {
    if (_data.classes == null) {
      _data.classes = '';
    }

    let formatted = SAMA.Templates.tpl.general.cards.button.formatTemplate(_data);
    return $(formatted).appendTo(this.dom.buttonContainer);
  }
}
