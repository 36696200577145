/**
 * Class describing preferences
 * @param {object} _databaseData
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */

class mSAMA_Preferences extends mSAMA_AbstractModel {
  constructor(_databaseData) {
    super('prf_groupPk');

    this.sync = {
      prf_groupPk: null,
      prf_badWords: null,
      prf_freeFormFRA: null,
      prf_allowAnonProactive: null,
      prf_useSimpleDraft: null,
      prf_allowAnonReactive: null,
      prf_filterProfanity: null,
      prf_allowCustKwds: null,
      prf_smsObjCompleteEmail: null,
      prf_addCategoryTracking: null,
      prf_requireCategorySelect: null,
      prf_useFlightReportLegs: null,
      prf_immediateTermDays: 0,
      prf_shortTermDays: 0,
      prf_mediumTermDays: 0,
      prf_longTermDays: 0,
      prf_overdueNotiFrequency: 1,
      filterProfanityLock: null,
      badWordTags: null,
      categoryList: null,
      modified: false,
    };

    $.extend(true, this.sync, _databaseData);

    this.UpdateProfanityLock();

    if (this.sync.prf_badWords != null) {
      this.sync.prf_badWords = this.sync.prf_badWords.split(',');

      for (let b = 0; b < this.sync.prf_badWords.length; b++) {
        this.sync.prf_badWords[b] = this.sync.prf_badWords[b].trim();
      }
    }

    const numberValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.NUMBER,
      minimum: 1,
      maximum: 1024,
    });

    const notiValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.NUMBER,
      minimum: 1,
      maximum: 365,
    });

    this.validateSettings = {
      prf_shortTermDays: numberValidator,
      prf_mediumTermDays: numberValidator,
      prf_longTermDays: numberValidator,
      prf_immediateTermDays: numberValidator,
      prf_overdueNotiFrequency: notiValidator,
    };
  }

  /**
   * Sets the profanities-changed lock to prevent resubmission.
   */
  UpdateProfanityLock() {
    this.sync.filterProfanityLock = this.sync.prf_filterProfanity;
  }

  /**
   * Gets the bad words as an array, or null if profanitiy is not being filtered.
   * @return {string[]|null}
   */
  GetBadWords() {
    if (!this.sync.prf_filterProfanity) {
      return null;
    }

    return this.sync.prf_badWords;
  }
}
