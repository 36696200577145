/**
 *
 */
class FuelCachesHistoryTable extends AbstractDatabaseTable {
  constructor() {
    super('FuelCachesHistoryV2', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"        integer primary key ,
            "group"             text,
            "location"          text,
            "FuelType"          text,
            "hasFuelPump"       integer,
            "hasAC_Power"       integer,
            "hasBad_Fuel"       integer,
            "inspected"         text,
            "full"              numeric,
            "full_Alarm"        numeric,
            "empty"             numeric,
            "datetime"          text,
            "whoupdate"         text,
            "Lat"               numeric,
            "Long"              numeric,
            "moveable"          integer,
            "bulk"              integer,
            "capacity"          numeric,
            "capacity_Alarm"    numeric,
            "remaining"         numeric,
            "MeasureType"       text,
            "Units"             text,
            "DipChart"          text,
            "DipChartUnits"     text,
            "Image"             text,
            "ImageDateTime"     text,
            "Comment"           text,
            "Update_Comment"    text,
            "inspection_Doc_ID" integer,
            "DELETED"           integer
        )
    `;
    //#endregion
  }
}
