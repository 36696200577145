/**
 *
 */
class SchedulerIndividualTable extends AbstractDatabaseTable {
  constructor() {
    super('Scheduler_Individual', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey" text primary key,
            "ACK"        integer,
            "ACK_Date"   text,
            "AC_ID"      integer,
            "Approved"   integer,
            "Avail"      integer,
            "Booking_ID" integer,
            "HASH"       text,
            "Staff_ID"   integer,
            "Status"     text,
            "cname"      text,
            "end"        text,
            "group"      text,
            "modified"   text,
            "start"      text
        )`;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    const isForSync = values[0] === 'SYNC';

    if (isForSync) {
      columns = ['PrimaryKey', 'modified'];
      values = null;
    }

    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    if (isForSync) {
      const result = {};
      for (let row of rows) {
        if (row.PrimaryKey.toString().indexOf('_') !== -1) {
          let split = row.PrimaryKey.split('_');
          row.PrimaryKey = parseInt(split[0]) + '_' + split[1];
          result['PK' + parseInt(split[0])] = row;
        } else {
          result['PK' + row.PrimaryKey] = row;
        }
      }

      return result;
    }

    return rows;
  }
}
