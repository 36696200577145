/**
 * General functions for showing Dialogs
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */
class cSAMA_Dialog {
  /**
   * Shows a simple message dialog to the users
   * @param  {string} _p.title        The dialog's title
   * @param  {string} _p.message      The dialog's message
   * @param  {function} [_p.closeFn] (Optional) Function delegate to execute on close
   * @param  {{}}       [_p.buttons]  (Optional) Object containing alternate buttons and their callbacks
   * @return {jQuery} Dialog container
   */
  static ShowDialog(_p) {
    return $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: _p.title,
      headerClose: false,
      buttonPrompt: _p.message,
      width: cSAMA_Dialog.dialogWidth,
      zindex: cSAMA_Dialog.dialogZIndex,
      buttons: _p.buttons || {
        Close: {
          click: () => {
            if (_p.closeFn != null) {
              _p.closeFn();
            }
          },
        },
      },
      callbackOpen: function () {
        setTimeout(function () {
          $('a.ui-btn:contains("Close")').attr('data-testid', 'sama-report-sms-new-confirmation-dialog-close-button');
        }, 100);
      },
    });
  }

  /**
   * Shows a dialog that asks for confirmation of an action
   * @param  {string}   _p.title      The dialog's title
   * @param  {string}   _p.message    The dialog's message
   * @param  {function} [_p.yesFunc]  Function delegate to execute on yes
   * @param  {function} [_p.noFunc]   Function delegate to execute on no
   * @param  {{}}       [_p.buttons]  Object containing alternate buttons and their callbacks
   */
  static ShowConfirm(_p) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: _p.title,
      headerClose: false,
      buttonPrompt: _p.message,
      width: cSAMA_Dialog.dialogWidth,
      zindex: cSAMA_Dialog.dialogZIndex,
      buttons: _p.buttons || {
        Yes: {
          click: () => {
            if (_p.yesFunc != null) {
              _p.yesFunc();
            }
          },
        },
        No: {
          icon: 'delete',
          click: () => {
            if (_p.noFunc != null) {
              _p.noFunc();
            }
          },
        },
      },
      callbackOpen: function () {
        setTimeout(function () {
          $('a.ui-btn:contains("Yes")').attr('data-testid', 'sama-report-sms-new-confirmation-dialog-yes-button');
          $('a.ui-btn:contains("No")').attr('data-testid', 'sama-report-sms-new-confirmation-dialog-no-button');
        }, 100);
      },
    });
  }

  /**
   * Shows a dialog that wants text
   * @param  {string}   _p.title     The dialog's title
   * @param  {string}   _p.message   The dialog's message
   * @param  {function} [_p.yesFunc] Function delegate to execute on yes
   * @param  {function} [_p.noFunc]  Function delegate to execute on no
   */
  static ShowTextBox(_p) {
    let id = new Date().getTime();
    let contents = '<div class="sama-dialog-container" id="text-choice-%"><div>%</div><textarea></textarea>'.tr(
      id,
      _p.message
    );

    contents +=
      '<a class="acceptBtn" rel="close" data-role="button" href="#">Accept</a>' +
      '<a class="cancelBtn" rel="close" data-role="button" href="#">Cancel</a></div>';

    $('<div>').simpledialog2({
      mode: 'blank',
      animate: false,
      headerText: _p.title,
      headerClose: false,
      width: cSAMA_Dialog.dialogWidth,
      zindex: cSAMA_Dialog.dialogZIndex,
      blankContent: contents,
    });

    let dom = $('#text-choice-' + id);
    let acc = dom.find('.acceptBtn');

    acc.on('click.samaevt', () => {
      let val = dom.find('textarea').val();

      if (_p.yesFunc != null) {
        _p.yesFunc(val);
      }
      acc.off('click.samaevt');
    });

    let cnc = dom.find('.cancelBtn');
    cnc.on('click.samaevt', () => {
      let val = dom.find('textarea').val();

      if (_p.noFunc != null) {
        _p.noFunc(val);
      }
      cnc.off('click.samaevt');
    });
  }

  /**
   * Creates a modal dialog with a dropdown.
   * @param {string}                                            _p.title        The title of the dialog
   * @param {string}                                            _p.message      Message of the prompt
   * @param {{value:string, name:string, [selected]:boolean}[]} _p.options      Options to show in the select
   * @param {function}                                          _p.onAccept     Function called when the user hits
   *   'Accept' with their choice as its argument.
   * @param {function}                                          [_p.onCancel]   Function called when the user hits
   *   'Cancel'
   * @param {boolean}                                           [_p.hasCancel]  Whether the dialog has a cancel button.
   *                                                                          Defaults to true.
   */
  static ShowDropdown(_p) {
    _p.hasCancel = _p.hasCancel == null ? true : _p.hasCancel;

    let id = new Date().getTime();
    let contents = '<div class="sama-dialog-container" id="select-choice-%"><div>%</div><select>'.tr(id, _p.message);

    for (let o = 0; o < _p.options.length; o++) {
      let _opt = _p.options[o];
      let selected = '';
      if (_opt.selected === true) {
        selected = 'selected';
      }
      contents += '<option % value="%">%</option>'.tr(selected, _opt.value, _opt.name);
    }

    contents += '</select><a class="acceptBtn" rel="close" data-role="button" href="#">Accept</a>';

    if (_p.hasCancel) {
      contents += '<a class="cancelBtn" rel="close" data-role="button" href="#">Cancel</a>';
    }

    contents += '</div>';

    $('<div>').simpledialog2({
      mode: 'blank',
      animate: false,
      headerText: _p.title,
      headerClose: false,
      width: cSAMA_Dialog.dialogWidth,
      zindex: cSAMA_Dialog.dialogZIndex,
      blankContent: contents,
    });

    let dom = $('#select-choice-' + id);
    let acp = dom.find('.acceptBtn');
    acp.on('click.samaevt', () => {
      let val = dom.find('select').val();

      if (_p.onAccept != null) {
        _p.onAccept(val);
      }
      acp.off('click.samaevt');
    });

    let cnc = dom.find('.cancelBtn');
    cnc.on('click.samaevt', () => {
      if (_p.onCancel != null) {
        _p.onCancel();
      }
      cnc.off('click.samaevt');
    });
  }

  /**
   * Shows a confirm with a 'dont ask again' checkbox.
   * Function delegates are given the state of the checkbox as an argument
   * @param  {string}   p.title     The dialog's title
   * @param  {string}   p.message   The dialog's message
   * @param  {string}   p.yesLabel  Label for the top button
   * @param  {string}   p.noLabel   Label for the bottom button
   * @param  {bool}     [p.canCheck]   Whether the checkbox is visible
   * @param  {function} [p.yesFunc] Function delegate to execute on yes.
   * @param  {function} [p.noFunc]  Function delegate to execute on no.
   */
  static ShowConfirmWithRememberOption(p) {
    p.canCheck = p.canCheck == null ? true : p.canCheck;

    let id = new Date().getTime();
    let contents = '<div class="sama-dialog-container" id="dialog-remember-%"><div>%</div>'.tr(id, p.message);

    contents += `<a class="acceptBtn" rel="close" data-role="button" href="#">${p.yesLabel}</a>
        <a class="cancelBtn" rel="close" data-role="button" href="#">${p.noLabel}</a></div>`;

    if (p.canCheck) {
      contents += `<input id="dialog-remember-box-${id}" type="checkbox" />
        <label for="dialog-remember-box-${id}">Don't ask Again</label>`;
    }

    $('<div>').simpledialog2({
      mode: 'blank',
      animate: false,
      headerText: p.title,
      headerClose: false,
      width: cSAMA_Dialog.dialogWidth,
      zindex: cSAMA_Dialog.dialogZIndex,
      blankContent: contents,
    });

    let dom = $('#dialog-remember-' + id);
    let rem = $('#dialog-remember-box-' + id);
    let acc = dom.find('.acceptBtn');

    acc.on('click.samaevt', () => {
      if (p.yesFunc != null) {
        p.yesFunc(rem.is(':checked'));
      }
      acc.off('click.samaevt');
    });

    let cnc = dom.find('.cancelBtn');
    cnc.on('click.samaevt', () => {
      if (p.noFunc != null) {
        p.noFunc(rem.is(':checked'));
      }
      cnc.off('click.samaevt');
    });
  }
}

cSAMA_Dialog.dialogWidth = 400;
cSAMA_Dialog.dialogZIndex = 100100;
