/**
 * General functions for enhancing the GUI of the SAMA module
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */

class cSAMA_GeneralGui {
  constructor() {
    this.pagestate = cSAMA_GeneralGui.StateLoading;
    this.activeLocks = [];
    this.loadLocks = [];
  }

  /**
   * Locks the page state
   * @param {string} [_name]      A name for the lock
   * @param {int}    [_lockId]    A preset for the lock ID.
   * @returns {*}
   */
  LockPageState(_name, _lockId) {
    _name = _name == null ? 'Unnamed' : _name;
    _lockId = _lockId == null ? -cSAMA_Utils.GetUID() : +_lockId;

    this.activeLocks.push({ name: _name, id: _lockId });

    this.SetPageState(cSAMA_GeneralGui.StateBlocked);
    return _lockId;
  }

  UnlockPageState(_id) {
    if (this.pagestate !== cSAMA_GeneralGui.StateBlocked) {
      return;
    }

    // Removes the error
    let unlock = this.activeLocks.filter((e) => e.id === _id);
    this.activeLocks = this.activeLocks.filter((e) => e.id !== _id);

    if (this.activeLocks.length <= 0) {
      // If there are no more errors, the page state can be released
      this.SetPageState(cSAMA_GeneralGui.StateReleased);
    }
  }

  /**
   * Updates the page state
   * @param _newState
   */
  SetPageState(_newState) {
    if (_newState === this.pagestate) {
      return;
    }

    if (_newState === cSAMA_GeneralGui.StateLoading) {
      // Started Loading. reset validation outcomes
      this.activeLocks = [];
      this.afterLockState = null;
      $('.sama-form-error').removeClass('sama-form-error');
      $('.sama-form-error-message').remove();
    }

    if (_newState === cSAMA_GeneralGui.StateBlocked) {
      if (!(this.afterLockState === cSAMA_GeneralGui.StateLoading && this.pagestate === cSAMA_GeneralGui.StateDirty)) {
        // Can't change from loading to dirty.
        this.afterLockState = this.pagestate;
      }
    } else if (_newState === cSAMA_GeneralGui.StateReleased) {
      // Return to previous state
      let newState = this.afterLockState;
      this.afterLockState = null;
      this.SetPageState(newState);
      return;
    }

    if (this.pagestate === cSAMA_GeneralGui.StateBlocked && this.afterLockState != null) {
      this.afterLockState = _newState;

      _newState = cSAMA_GeneralGui.StateBlocked;
    }

    this.pagestate = _newState;

    if (SAMA.PageHeader == null) {
      return;
    }

    this.ApplyButtonStates();
  }

  ApplyButtonStates() {
    let btnState = this.pagestate;

    if (cSAMA_GeneralGui.StateBlocked && this.afterLockState === cSAMA_GeneralGui.StateDirty) {
      btnState = cSAMA_GeneralGui.StateDirtyBlocked;
    }

    SAMA.PageHeader.UpdateButtonState(btnState);
  }

  /**
   * Gets the page state
   * @return {int}
   */
  GetPageState() {
    return this.pagestate;
  }

  /**
   * Gets active state locks
   * @return {Array}
   */
  GetLocks() {
    return this.activeLocks;
  }

  /**
   * Explicitly shows the loading indicator.
   * This exists to work around jQuery Mobile hiding the loading indicator
   * on page change.
   */
  ShowLoadIndicator(_lockID) {
    this.loadLocks.push(_lockID);
    this.LoadIndicator();
  }

  /**
   * Explicitly hides the loading indicator.
   * This exists to work around jQuery Mobile hiding the loading indicator
   * on page change.
   */
  HideLoadIndicator(_lockID) {
    this.loadLocks = this.loadLocks.filter((e) => e !== _lockID);
    this.LoadIndicator();
  }

  LoadIndicator() {
    if (this.loadLocks.length <= 0) {
      $.mobile.loading('hide');
    } else {
      $.mobile.loading('show', {
        theme: 'a',
      });
    }
  }

  static BuildFileList(_filesList, _domTarget, _actionName) {
    if (_filesList == null) {
      _domTarget.text('No files loaded');
      return;
    }
    for (let f = 0; f < _filesList.length; f++) {
      const file = _filesList[f];
      _domTarget
        .html('')
        .append(
          $('<a/>')
            .append($('<i/>').addClass('fal fa-file'))
            .append($('<span/>').text(file.name))
            .attr('href', `${cSAMA_Utils.GetAjaxPage()}?do=download&action=${_actionName}&id=${file.id}`)
            .attr('target', '_blank')
            .prop('download', true)
            .addClass('ui-btn ui-corner-all ui-mini')
        );
    }
  }
}

cSAMA_GeneralGui.StateLoading = 0;
cSAMA_GeneralGui.StateReady = 1;
cSAMA_GeneralGui.StateDirty = 2;
cSAMA_GeneralGui.StateBlocked = 3;
cSAMA_GeneralGui.StateReleased = 4;
cSAMA_GeneralGui.StateDirtyBlocked = 5;

cSAMA_GeneralGui.SlPageState = ['Loading', 'Ready', 'Dirty', 'Blocked', 'Released'];
