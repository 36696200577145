//Density Altitude Calculator
var DAPageFrom = '';
var AC_DA = {
  Elevation: 0,
  EleUnit: 'F',
  AirTemp: 0,
  AirUnit: 'F',
  AltSetting: 1,
  AltUnit: 'Hg',
  DewPoint: 0,
  DewUnit: 'F',
  DA_ATF: 0,
  DA_ATC: 0,
  DA_APH: 0,
  DA_APM: 0,
  DA_PCT: 1,
};

function CalcPerformance(Page) {
  $.mobile.changePage($('#WB_DA_Page'), {
    reverse: false,
    changeHash: false,
  });
  DAPageFrom = Page;
  e_calc();
}

function exitDApage() {
  if (DAPageFrom == 'WandB') {
    $.mobile.changePage($('#WB_Page2'), {
      reverse: true,
      changeHash: false,
    });
  }
  if (DAPageFrom == 'Itin') {
    $.mobile.changePage($('#ITIN-WEIGHTBALANCE'), {
      reverse: true,
      changeHash: false,
    });
  }
}
var CurrentSelection = {
  in_elev: 'in_elev_F',
  in_temp: 'in_temp_F',
  in_alt_set: 'in_alt_set_I',
  in_dp: 'in_dp_F',
};
function changeDA_Stuff(e) {
  var clicked = $('#' + e.id).val();
  if (clicked != CurrentSelection[e.name]) {
    CurrentSelection[e.name] = clicked;
  } else {
    return;
  }

  switch (e.id) {
    case 'in_elev_F':
      var ele = parseInt($('#DA_elevation').val());
      $('#DA_elevation').val(Math.round(ele * 3.28084));
      break;
    case 'in_elev_M':
      var ele = parseInt($('#DA_elevation').val());
      $('#DA_elevation').val(Math.round(ele * 0.3048));
      break;
    case 'in_temp_F':
      var temp = parseInt($('#DA_temperature').val());
      $('#DA_temperature').val(Math.round(temp * 1.8 + 32));
      break;
    case 'in_temp_C':
      var temp = parseInt($('#DA_temperature').val());
      $('#DA_temperature').val(Math.round((temp - 32) * 0.556));
      break;
    case 'in_alt_set_I':
      var pres = parseInt($('#DA_altset').val());
      $('#DA_altset').val(Math.round(pres * 0.02953 * 100) / 100);
      break;
    case 'in_alt_set_M':
      var pres = parseFloat($('#DA_altset').val());
      $('#DA_altset').val(Math.round(pres * 33.8639));
      break;
    case 'in_dp_F':
      var temp = parseInt($('#DA_dewpoint').val());
      $('#DA_dewpoint').val(Math.round(temp * 1.8 + 32));
      break;
    case 'in_dp_C':
      var temp = parseInt($('#DA_dewpoint').val());
      $('#DA_dewpoint').val(Math.round((temp - 32) * 0.556));
      break;
  }
  e_calc();
}
// Global Variables for conversions
var in_per_mb = 1 / 33.86389;
var mb_per_in = 33.86389;

var m_per_ft = 0.3048;
var ft_per_m = 1 / 0.3048;

function e_resetOut() {
  var inForm = document.e_inputs;
  var outForm = document.e_outputs;

  $('#densaltz').html('');
  $('#actpress').html('');
  $('#relden').html('');

  $('#densaltzm').html('');
  $('#actpressmb').html('');

  inForm.elevation.focus();
}

function e_resetForm() {
  var inForm = document.e_inputs;
  var outForm = document.e_outputs;

  inForm.elevation.value = '';
  inForm.temperature.value = '';
  inForm.altset.value = '';
  inForm.dewpoint.value = '';

  $('#densaltz').html('');
  $('#actpress').html('');
  $('#relden').html('');

  $('#densaltzm').html('');
  $('#actpressmb').html('');

  inForm.elevation.focus();
}



function calcVaporPressure_wobus(t) {
  // Calculate the saturation vapor pressure given the temperature(celsius)
  // Polynomial
  var eso = 6.1078;

  var c0 = 0.99999683;
  var c1 = -0.90826951e-2;
  var c2 = 0.78736169e-4;
  var c3 = -0.61117958e-6;
  var c4 = 0.43884187e-8;
  var c5 = -0.29883885e-10;
  var c6 = 0.21874425e-12;
  var c7 = -0.17892321e-14;
  var c8 = 0.11112018e-16;
  var c9 = -0.30994571e-19;

  var pol = c0 + t * (c1 + t * (c2 + t * (c3 + t * (c4 + t * (c5 + t * (c6 + t * (c7 + t * (c8 + t * c9))))))));

  es = eso / Math.pow(pol, 8);

  return es;
}

function calcAbsPress(pressure, altitude) {
  // Calculate absolute air pressure given the barometric pressure(mb) and altitude(meters)
  var k1 = 0.190284;
  var k2 = 8.4288 * Math.pow(10, -5);
  var p1 = Math.pow(pressure, k1);
  var p2 = altitude * k2;
  var p3 = 0.3 + Math.pow(p1 - p2, 1 / k1);
  return p3;
}

function calcDensity(abspressmb, e, tc) {
  //  Calculate the air density in kg/m3
  var Rv = 461.4964;
  var Rd = 287.0531;

  var tk = tc + 273.15;
  var pv = e * 100;
  var pd = (abspressmb - e) * 100;
  var d = pv / (Rv * tk) + pd / (Rd * tk);
  return d;
}

function calcAltitude(d) {
  // Calculate the ISA altitude (meters) for a given density (kg/m3)
  var g = 9.80665;
  var Po = 101325;
  var To = 288.15;
  var L = 6.5;
  var R = 8.31432;
  var M = 28.9644;

  var D = d * 1000;

  var p2 = ((L * R) / (g * M - L * R)) * Math.log((R * To * D) / (M * Po));

  var H = -(To / L) * (Math.exp(p2) - 1);

  var h = H * 1000;

  return h;
}

function calcZ(h) {
  // Calculate the Z altitude (meters), given the H altitide (meters)
  var r = 6369e3;

  return (r * h) / (r - h);
}

function calcH(z) {
  // Calculate the H altitude (meters), given the Z altitide (meters)
  var r = 6369e3;

  return (r * z) / (r + z);
}

function calcAs2Press(As, h) {
  // Calculate the actual pressure (mb)from the altimeter setting (mb) and geopotential altitude (m)
  var k1 = 0.190263;
  var k2 = 8.417286e-5;

  var p = Math.pow(Math.pow(As, k1) - k2 * h, 1 / k1);

  return p;
}

function validateInput(InputName, prompt) {
  // Checks a form input to see that the input is not blank and that it is a number
  if (isNaN(InputName) || InputName.length == 0 || InputName.charAt(0) == ' ') {
    alert(prompt);
    //InputName.focus();
    //InputName.select();
    return false;
  }
  return true;
}

/////////////////////////////////////////////////////////////////////////////////////////////
///// Calculations for English units ////////////////////////////////////////////////////////

function e_calc() {
  // Validate all the required inputs

  if (!validateInput($('#DA_elevation').val(), 'Invalid entry for Elevation')) return;
  if (!validateInput($('#DA_temperature').val(), 'Invalid entry for Temperature')) return;
  if (!validateInput($('#DA_altset').val(), 'Invalid entry for Altimeter Setting')) return;
  if (!validateInput($('#DA_dewpoint').val(), 'Invalid entry for Dew Point')) return;

  // Process the input values

  if ($('#in_elev_F').is(':checked')) {
    AC_DA.EleUnit = 'F';
    var z = 1.0 * $('#DA_elevation').val(); // geometric elevation, feet
    var zm = z * m_per_ft;
    AC_DA.Elevation = z;
  }

  if ($('#in_elev_M').is(':checked')) {
    AC_DA.EleUnit = 'M';
    var zm = 1.0 * $('#DA_elevation').val(); // geometric elevation, meters
    var z = zm * ft_per_m;
    AC_DA.Elevation = zm;
  }

  if ($('#in_alt_set_I').is(':checked')) {
    AC_DA.AltUnit = 'Hg';
    var altset = 1.0 * $('#DA_altset').val(); // altimeter setting, in-Hg
    var altsetmb = altset * mb_per_in;
    AC_DA.AltSetting = altset;
  }

  if ($('#in_alt_set_M').is(':checked')) {
    AC_DA.AltUnit = 'mb';
    var altsetmb = 1.0 * $('#DA_altset').val(); // altimeter setting, mb
    var altset = altsetmb * in_per_mb;
    AC_DA.AtlSetting = altsetmb;
  }

  if ($('#in_temp_F').is(':checked')) {
    AC_DA.AirUnit = 'F';
    var tf = 1.0 * $('#DA_temperature').val(); // temperature, deg F
    var tc = (5 / 9) * (tf - 32);
    AC_DA.AirTemp = tf;
  }

  if ($('#in_temp_C').is(':checked')) {
    AC_DA.AirUnit = 'C';
    var tc = 1.0 * $('#DA_temperature').val(); // temperature, deg C
    var tf = (9 / 5) * tc + 32;
    AC_DA.AirTemp = tc;
  }

  if ($('#in_dp_F').is(':checked')) {
    AC_DA.DewUnit = 'F';
    var tdpf = 1.0 * $('#DA_dewpoint').val(); // dewpoint, deg F
    var tdpc = (5 / 9) * (tdpf - 32);
    AC_DA.DewPoint = tdpf;
  }

  if ($('#in_dp_C').is(':checked')) {
    AC_DA.DewUnit = 'C';
    var tdpc = 1.0 * $('#DA_dewpoint').val(); // dewpoint, deg C
    var tdpf = (9 / 5) * tdpc + 32;
    AC_DA.DewPoint = tdpc;
  }

  // Calculate the vapor pressures (mb) given the ambient temperature (c) and dewpoint (c)

  var esmb = calcVaporPressure_wobus(tc);
  var emb = calcVaporPressure_wobus(tdpc);

  // Calculate geopotential altitude H (m) from geometric altitude (m) Z

  var hm = calcH(zm);

  // Calculate the absolute pressure given the altimeter setting(mb) and geopotential elevation(meters)

  var actpressmb = calcAs2Press(altsetmb, hm);

  // Calculate the air density (kg/m3) from absolute pressure (mb) vapor pressure (mb) and temp (c)

  var density = calcDensity(actpressmb, emb, tc);
  var relden = 100 * (density / 1.225);

  // Calculate the geopotential altitude (m) in ISA with that same density (kg/m3)

  var densaltm = calcAltitude(density);

  // Calculate geometric altitude Z (m) from geopotential altitude (m) H

  var densaltzm = calcZ(densaltm);

  // Convert Units for output

  var actpress = actpressmb * in_per_mb;
  var densaltz = densaltzm * ft_per_m;

  if (densaltz > 36090 || densaltz < -15000) {
    alert('Out of range for Troposhere Algorithm: Altitude =' + roundNum(densaltz, 0) + ' feet');
    e_resetOut();
    return;
  }

  // Print the results

  $('#densaltz').html(roundNum(densaltz, 0) + ' feet');
  $('#actpress').html(roundNum(actpress, 3) + ' inches Hg');
  $('#relden').html(roundNum(relden, 2) + '%');

  $('#densaltzm').html(roundNum(densaltzm, 0) + ' meters');
  $('#actpressmb').html(roundNum(actpressmb, 2) + ' mb');
  $('#relden2').html(roundNum(relden, 2) + '%');

  AC_DA.DA_ATF = roundNum(densaltz, 0);
  AC_DA.DA_ATC = roundNum(densaltzm, 0);
  AC_DA.DA_APH = roundNum(actpress, 3);
  AC_DA.DA_APM = roundNum(actpressmb, 2);
  AC_DA.DA_PCT = roundNum(relden, 2);
}
