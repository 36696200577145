class vSAMA_AbstractEditor extends iSAMA_AbstractObservable {
  /**
   * Returns the outermost markup element of the editor
   * @abstract
   */
  GetDom() {}

  /**
   * Unlinks inputs
   */
  Destroy() {
    cSAMA_GeneralInputs.UnLinkInputs(this.GetDom());
  }
}
