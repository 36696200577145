/**
 * Class containing and synching policy
 * @param {object} _databaseData
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class mSAMA_Policy extends mSAMA_AbstractModel {
  constructor(_databaseData) {
    super('pol_groupPk');
    this.objectives = [];
    this.files = _databaseData ? _databaseData.files : [];

    this.sync = {
      pol_groupPk: null,
      pol_safetyPolicy: null,
      pol_reportingPolicy: null,
      pol_instrHighRisk: null,
      pol_instrMedRisk: null,
      pol_instrLowRisk: null,
      pol_execImage: null,
      pol_execSignature: null,
      pol_execImageState: SAMA.enums.IMAGESTATES.NONE,
      pol_execSignatureState: SAMA.enums.IMAGESTATES.NONE,
      pol_execPk: -1,
      pol_useOnlyFile: 0,
      adm_name: null,
      modified: false,
    };

    let validatePolicies = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 25,
      maxLength: SAMA.enums.TEXTLENGTHS.Massive,
    });

    let validateInstructions = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 10,
      maxLength: SAMA.enums.TEXTLENGTHS.Medium,
    });

    this.validateSettings = {
      pol_safetyPolicy: validatePolicies,
      pol_reportingPolicy: validatePolicies,
      pol_instrHighRisk: validateInstructions,
      pol_instrMedRisk: validateInstructions,
      pol_instrLowRisk: validateInstructions,
    };

    $.extend(true, this.sync, _databaseData.policy);

    for (let o = 0; o < _databaseData.objectives.length; o++) {
      let objective = new mSAMA_PolicyObjective(this, _databaseData.objectives[o]);

      for (let t = 0; t < _databaseData.tasks.length; t++) {
        // Check if the task is owned by the objective or nah
        const taskData = _databaseData.tasks[t];
        if (+taskData['pot_pogPk'] !== objective.GetPk()) {
          //console.log('SAMA', taskData);
          continue;
        }

        objective.AddTask(new mSAMA_PolicyObjectiveTask(objective, taskData));
      }

      this.AddObjective(objective);
    }
  }

  /**
   * @param {mSAMA_PolicyObjective} _newObjective
   */
  AddObjective(_newObjective) {
    this.objectives.push(_newObjective);
  }

  /**
   * @returns {mSAMA_PolicyObjective[]}
   */
  GetObjectives() {
    return this.objectives;
  }

  /**
   * @param {SAMA.enums.IMAGESTATES.} _state
   */
  SetExecSignatureChanged(_state) {
    this.sync.pol_execSignatureState = _state;
  }

  /**
   * Gets the state of the Signature
   * @return {SAMA.enums.IMAGESTATES.}
   */
  GetExecSignatureState() {
    return this.sync.pol_execSignatureState;
  }

  /**
   * Gets the b64 encoded image
   * @returns {string|null}
   */
  GetExecSignatureImage() {
    return this.sync.pol_execSignature;
  }

  /**
   * @param {SAMA.enums.IMAGESTATES.} _state
   */
  SetExecImageChanged(_state) {
    this.sync.pol_execImageState = _state;
  }

  /**
   * Gets the state of the image
   * @return {SAMA.enums.IMAGESTATES.}
   */
  GetExecImageState() {
    return this.sync.pol_execImageState;
  }

  /**
   * Gets the b64 encoded image
   * @returns {string|null}
   */
  GetExecImage() {
    return this.sync.pol_execImage;
  }

  /**
   * Returns the object as a transmittable object
   * @returns {null|object}
   * @override
   */
  GetTransmissible() {
    if (!this.IsModified()) {
      return null;
    }

    // Don't resubmit the yuge exec images.
    this.sync.pol_execImage = null;
    this.sync.pol_execSignature = null;
    let transmit = {
      policy: this.sync,
      objectives: [],
    };

    for (let o = 0; o < this.objectives.length; o++) {
      let objective = this.objectives[o];
      if (!objective.IsModified()) {
        continue;
      }

      let tm = objective.GetTransmissible();
      if (tm != null) {
        transmit.objectives.push(tm);
      }
    }

    return transmit;
  }
}

/**
 * @enum
 * @readonly
 */
SAMA.enums.IMAGESTATES = {
  UNCHANGED: 0,
  DELETED: 1,
  CHANGED: 2,
  NONE: 3,
};
