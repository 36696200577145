/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var DATABASE_QUEUE = {};
var DATABASE_QUEUE_LENGTH = 50; //number of milliseconds to delay each subsiquent request
/**
 *
 * @param {any[]} data
 * @param centerLong
 * @param centerLat
 * @returns {any[]}
 */
function computeBearingAndDistance(data, centerLong = null, centerLat = null) {
  const processed = [];
  for (let item of data) {
    const point1 = turf.point([centerLong, centerLat]);
    const point2 = turf.point([item.Long, item.Lat]);
    const distance = turf.distance(point1, point2, { units: 'meters' });
    let bearing = turf.bearing(point1, point2).toFixed(0);
    if (bearing < 0) {
      bearing = 360 + parseInt(bearing);
    }
    item.bearing = bearing;
    item.distance = distance;
    processed.push(item);
  }

  return processed;
}

//***************************************** North American POI database
/*
 Standard Types

 "Terrain Feature"
 "Water Feature"
 "Administrative Area"
 "Populated Place"
 "Feature Associated with Vegetation"
 "Constructed Feature"
 "Ice and Snow Feature"
 "Underground Feature"
 "Undersea and Maritime Feature"
 "Volcanic Feature"
 "Unclassified"
 */
var POIlist = [];

function searchPOIdelay(nearest, keyword) {
  if (keyword.length) {
    if (MAPSTATE.SearchTimeout != null) {
      clearTimeout(MAPSTATE.SearchTimeout);
    }
    MAPSTATE.SearchTimeout = setTimeout(function () {
      MAPSTATE.SearchTimeout = null;
      searchPOI(nearest, keyword);
    }, 1000);
  }
}

function searchPOI(nearest, keyword = '') {
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;

  var minlat = Lat - 1;
  var maxlng = Long + 1;
  var maxlat = Lat + 1;
  var minlng = Long - 1;

  $('#search-UN').val('');

  if (!nearest) {
    if (keyword.length < 3) {
      $('#POISearchResults').html('');
      return;
    } else {
      MAPSTATE.SearchingPOI = true;
      $('#POISearchResults').html('<h2>Searching...</h2>');
      $('#search-poi-text').prop('disabled', true);
    }
  }

  if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_POI_Index === 'Available') {
    let qO = {
      q: 'select * from POI where "Lat" >= ? and "Long" >= ? and "Lat" <= ? and "Long" <= ?',
      v: [minlat, minlng, maxlat, maxlng],
    };

    if (!nearest) {
      qO = {
        q: 'select * from POI where Name like ? limit 1000',
        v: [keyword + '%'],
      };
    }

    PointOfInterestTable.query()
      .raw(qO)
      .then((res) => completePoiSearch(res, nearest, Long, Lat));

    return;
  }

  $.getJSON(
    BASEURL +
      'menu-Map-AJAX.php?action=searchPOI&txt=' +
      encodeURIComponent(keyword) +
      '&Lat=' +
      Lat +
      '&Long=' +
      Long +
      '&nearest=' +
      nearest
  )
    .success((data) => completePoiSearch(data, nearest, Long, Lat))
    .fail(function (jqXHR, status, error) {
      window.toaster.show('Sorry - Could not retrieve POI');
      verbose.error('POI', error);
      $('#search-poi-text').prop('disabled', false);
    });
}

function completePoiSearch(data, nearest, Long, Lat) {
  POIlist = computeBearingAndDistance(data, Long, Lat);
  if (nearest) {
    showclosestPOI();
    return;
  }

  showPOIresults();
  MAPSTATE.SearchingPOI = false;
  $('#search-poi-text').prop('disabled', false).focus();
}

function showclosestPOI() {
  //sort distance
  if (POIlist.length) {
    POIlist.sort(function (a, b) {
      return parseFloat(a.distance) - parseFloat(b.distance);
    });

    $('#searchtabs-POI').html('');
    var html =
      '<table class="second-td-fill wrap gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Name</th><th>District</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
    var count = 0;
    for (var a in POIlist) {
      var A = POIlist[a];
      html +=
        '<tr class="selectable" onClick="POInavTo(' +
        a +
        ')"><td><div class="vertical-center-container"><img src="./SpritesPNG/' +
        A.Type +
        '.png" width="20px" /></div><td class="directToTxt">' +
        A.Name +
        '</td><td><div style="font-size:10pt;">' +
        A.District +
        '</div></td><td>' +
        A.bearing +
        '&degT</td><td>' +
        getDisplayDistance(A.distance) +
        '</td></tr>';
      count++;
      if (count > 100) {
        break;
      }
    }
    html += '</tbody></table>';

    $('#searchtabs-POI').html(html);
    $('#searchtabs-POI').enhanceWithin();
  } else {
    $('#searchtabs-POI').html('<h2>No Results</h2>');
  }
}

function showPOIresults() {
  //sort alphabetically
  if (POIlist.length) {
    POIlist.sort(function (a, b) {
      return parseFloat(a.distance) - parseFloat(b.distance);
    });
    $('#searchtabs-POI').html('');
    var html =
      '<table class="second-td-fill wrap gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Name</th><th>District</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
    var count = 0;
    for (var a in POIlist) {
      var A = POIlist[a];
      html +=
        '<tr class="selectable" onClick="POInavTo(' +
        a +
        ')"><td><div class="vertical-center-container"><img src="./SpritesPNG/' +
        A.Type +
        '.png" width="20px" /></div><td class="directToTxt">' +
        A.Name +
        '</td><td><div style="font-size:10pt;">' +
        A.District +
        '</div></td><td>' +
        A.bearing +
        '&degT</td><td>' +
        getDisplayDistance(A.distance) +
        '</td></tr>';
      count++;
      if (count > 100) {
        break;
      }
    }
    html += '</tbody></table>';

    $('#POISearchResults').html(html);
    $('#POISearchResults').enhanceWithin();
  } else {
    $('#POISearchResults').html('<h2>No Results</h2>');
  }
}

//**************************************** done POI

var NAlist = [];

function searchNavaids(ident) {
  //$("#searchNavaidIdent").val(ident.toUpperCase());
  $('#searchNavaidName').val('');
  if (ident.length > 0) {
    searchNavaidsDB('', ident);
  }
}

function searchNavaidsDB(txt = '', ident = '') {
  var center = {
    lat: 0,
    lng: 0,
  };
  var nearest = false;
  if (txt != '' && $('#searchNavaidIdent').val().length > 0) {
    $('#searchNavaidIdent').val('');
  }
  if (txt == '' && ident == '') {
    nearest = true;
  }
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;

  var minlat = Lat - 2.5;
  var maxlng = Long + 2;
  var maxlat = Lat + 2.5;
  var minlng = Long - 2;
  let qO = {
    q: 'select * from Navaids where Lat > ? and "Long" > ? and Lat < ? and "Long" < ? limit 100',
    v: [minlat, minlng, maxlat, maxlng],
  };

  if (txt.length > 2) {
    qO = {
      q: 'select * from Navaids where Name like ? or Airport like ? limit 100',
      v: [`%${txt}%`, `${txt}%`],
    };
  } else if (ident.length > 0) {
    qO = {
      q: 'select * from Navaids where ID like ? limit 100',
      v: [`${ident}%`],
    };
  }

  NavAidTable.query()
    .raw(qO)
    .then((res) => {
      NAlist = computeBearingAndDistance(res, Long, Lat);
      showClosestNavaids(nearest);
    })
    .catch((e) => {
      verbose.error('SQL', e);
    });
}

function showClosestNavaids(nearest) {
  NAlist.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  if (nearest) {
    //todo split to VOR nd NDB types
    $('#searchtabs-VOR').html('');
    $('#searchtabs-NDB').html('');
    var vor = false;
    var ndb = false;
    var vorhtml =
      '<table class="third-td-fill wrap gpsmenuResult" data-filter="true"><thead><tr><th></th><th>NDB</th><th>Name</th><th>Chan</th><th>Bearing</th><th>Dist</th><th>Radial</th><th>Frequency</th></tr></thead><tbody>';
    var ndbhtml =
      '<table class="third-td-fill wrap gpsmenuResult" data-filter="true"><thead><tr><th></th><th>VOR</th><th>Name</th><th>Bearing</th><th>Dist</th><th>Frequency</th></tr></thead><tbody>';
    for (var a in NAlist) {
      var A = NAlist[a];

      if (A.Type == 'NDB' || A.Type == 'NDB-DME') {
        ndbhtml +=
          '<tr class="selectable" onClick="DisplayClosestNavaidDetails(' +
          a +
          ')"><td><div class="vertical-center-container"><img src="./images/' +
          A.Type +
          '.png" width="20px" /></div><td class="directToTxt">' +
          A.ID +
          '</td><td>' +
          A.Airport +
          ' - ' +
          A.Name +
          '</td><td>' +
          A.bearing +
          '&degT</td><td>' +
          getDisplayDistance(A.distance) +
          '</td><td>' +
          A.Freq +
          'Khz </td></tr>';
        ndb = true;
      } else {
        var Radial = A.bearing - 180;
        if (Radial < 0) {
          Radial += 360;
        }
        vorhtml +=
          '<tr class="selectable" onClick="DisplayClosestNavaidDetails(' +
          a +
          ')"><td><div class="vertical-center-container"><img src="./images/' +
          A.Type +
          '.png" width="20px" /></div></td><td class="directToTxt">' +
          A.ID +
          '</td><td>' +
          A.Airport +
          ' - ' +
          A.Name +
          '</td><td>' +
          A.Channel +
          '</td><td>' +
          A.bearing +
          '&degT</td><td>' +
          getDisplayDistance(A.distance) +
          '</td><td>' +
          Math.round(Radial) +
          '&deg</td><td>' +
          A.Freq +
          'Mhz</td></tr>';
        vor = true;
      }
    }
    ndbhtml += '</tbody></table>';
    vorhtml += '</tbody></table>';
    if (vor) {
      $('#searchtabs-VOR').html(vorhtml);
    } else {
      $('#searchtabs-VOR').html('<h2>No Results</h2>');
    }
    $('#searchtabs-VOR').enhanceWithin();
    if (ndb) {
      $('#searchtabs-NDB').html(ndbhtml);
    } else {
      $('#searchtabs-NDB').html('<h2>No Results</h2>');
    }
    $('#searchtabs-NDB').enhanceWithin();
  } else {
    $('#searchtabs-NDB').html('');
    var html =
      '<table class="third-td-fill wrap gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Ident</th><th>Name</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
    if (NAlist.length == 1) {
      DisplayNavaidDetails(NAlist[0]);
    } else {
      for (var a in NAlist) {
        var A = NAlist[a];
        html +=
          '<tr class="selectable" onClick="DisplayClosestNavaidDetails(' +
          a +
          ')"><td><div class="vertical-center-container"><img src="./images/' +
          A.Type +
          '.png" width="20px" /></div><td class="directToTxt">' +
          A.ID +
          '</td><td>' +
          A.Airport +
          ' - ' +
          A.Name +
          '</td><td>' +
          A.bearing +
          '&degT</td><td>' +
          getDisplayDistance(A.distance) +
          '</td></tr>';
      }
      html += '</tbody></table>';
      $('#NavaidSearchResults').html(html);
      $('#NavaidSearchResults').enhanceWithin();
    }
  }
}

function clear_search_airport_text() {
  $('#search-airport-text').val('');
  $('#searchAirportICAO').val('');
}

function clear_search_navaid_text() {
  $('#searchNavaidName').val('');
  $('#searchNavaidIdent').val('');
}

function clear_search_poi_text() {
  $('#search-poi-text').val('');
}

function clear_search_address_text() {
  $('#search-address-text').val('');
}

function DisplayClosestNavaidDetails(a) {
  var A = NAlist[a];
  $('#gpsMenu').tabs();
  $('#gpsMenu').show();
  DisplayNavaidDetails(A);
}

function DisplayNavaidDetails(A) {
  closeSearchMenu();
  if (mapb != null) {
    mapb.on('click', closeDirectoTo);
  }
  $('#NavaidTabDirectTo').click();
  $('#searchNavaidIdent').val(A.ID);
  $('#searchNavaidName').val(A.Name);
  $('#NavaidSearchResults').html('');
  A.CirroLayerType = 'Navaid';
  MAPSTATE.CurDirectTo = A;
  var html = '';
  html +=
    '<table class="first-td-fill wrap gpsmenuResult"><tr><td style="vertical-align:top;"><b>Ident:</b> ' +
    A.ID +
    '<br /><b>Name:</b> ' +
    A.Name +
    '<br /><b>Airport:</b> ' +
    A.Airport +
    '<br />' +
    A.State +
    ' ' +
    A.Country +
    '<br /><b>Type</b> ' +
    A.Type +
    '<br />';

  if (GPS.Active == 1) {
    html += '<div class="directToTxt"><b>From Current Location</b></div>';
  } else {
    html += '<div class="directToTxt"><b>From Map Center:</b></div>';
  }
  var LatCoordTXT;
  var LongCoordTXT;
  if (LOCALSTORAGE.CoordFormat == 'UTM') {
    var utmOK = getUTM(A.Lat, A.Long);
    LatCoordTXT = utmzone + ' 0' + utmlat;
    LongCoordTXT = 'UTM ' + utmlong;
  } else {
    LongCoordTXT = ConvertDD_User(A.Long, 'Long');
    LatCoordTXT = ConvertDD_User(A.Lat, 'Lat');
  }

  html +=
    '<table class="last-td-fill wrap gpsmenuResult"><tr><td class="directToTxt"><b>' +
    getDisplayDistance(A.distance) +
    '' +
    '</b></td><td class="directToTxt"><b>' +
    A.bearing +
    '&degT' +
    '</b></td><td><b>' +
    LatCoordTXT +
    '<br />' +
    LongCoordTXT +
    '</b></td><td>';

  html +=
    '<button class="directto-btn" onClick="navigateToMarker(\'' +
    A.ID +
    "'," +
    A.Lat +
    ',' +
    A.Long +
    ", '" +
    A.Type +
    '\')" ><img src="./images/DirectTo.png" width="35" height="35"/></button>';
  if (MAPSTATE.Measuring != false) {
    html +=
      '<button class="directto-btn" onClick="routeToMarker(\'' +
      A.ID +
      "'," +
      A.Lat +
      ',' +
      A.Long +
      ')"><img src="./images/routeplan.png" width="35" height="35"/></button>';
  }
  CurMarkerProperties = A;
  CurMarkerProperties.NavType = 'Navaid';
  html += '</td></tr></table>';
  html += '</td><td style="vertical-align:top;">';
  html += '<div id="NAVAIDPREVIEW" class="previewmap"></div>';
  html += '</td></tr></table><br />';
  $('#NavaidSearchResults').html(html);
  $('#NavaidSearchResults').enhanceWithin();
  MakePreviewMap(A, 'NAVAIDPREVIEW');
}

function NAnavTo(e) {
  //console.log(e);
  //APlist = [];
  mapb.off('click', closeDirectoTo);
  if (MAPSTATE.Measuring == false) {
    $('#gpsMenu').hide();
  }
  var lnglat = new mapboxgl.LngLat(NAlist[e].Long, NAlist[e].Lat);
  navigateTo(lnglat, NAlist[e].Name, 'Navaid');
}

function searchArea_AirportMOBILE() {
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;

  var minlat = Lat - 2.5;
  var maxlng = Long + 2;
  var maxlat = Lat + 2.5;
  var minlng = Long - 2;

  const qO = {
    q: 'select * from Airports where Lat > ? and "Long" > ? and Lat < ? and "Long" < ?',
    v: [minlat, minlng, maxlat, maxlng],
  };

  AirportsTable.query()
    .raw(qO)
    .then((result) => {
      APlist = computeBearingAndDistance(result, Long, Lat);
      showClosestAirports();
    })
    .catch((e) => {
      verbose.error('SQL', e);
      $('#searchtabs-Airport').html('<h3>Database Error</h3>');
    });
}

function searchArea_AirportPC() {
  $('#searchtabs-Airport').html('<h3>Searching...</h3>');
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;
  var minlat = Lat - 2.5;
  var maxlng = Long + 2;
  var maxlat = Lat + 2.5;
  var minlng = Long - 2;

  cirroDB.query(
    'Airports',
    'Lat >= ? AND Long >= ? AND Lat <=? AND Long <=?',
    [minlat, minlng, maxlat, maxlng],
    function (e) {
      if (e === false) {
        e = [];
      }
      APlist = computeBearingAndDistance(e, Long, Lat);
      //console.log(APlist);
      showClosestAirports();
    }
  );
}

function showClosestAirports() {
  APlist.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  $('#searchtabs-Airport').html('');
  var html =
    '<table class="third-td-fill wrap gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Ident</th><th>Airport</th><th>Bearing</th><th>Dist</th><th>Runway</th><th>Frequency</th></tr></thead><tbody>';
  for (var a in APlist) {
    var A = APlist[a];
    var Icon = 'AptHardCT';
    switch (A.Type) {
      case 'closed':
        continue;
        break;
      case 'large_airport':
        Icon = 'AptBig';
        break;
      case 'medium_airport':
        Icon = 'AptHard';
        break;
      case 'small_airport':
        Icon = 'AptOther';
        break;
      case 'seaplane_base':
        Icon = 'AirportSea0';
        break;
      case 'heliport':
        Icon = 'AirportHeli0';
        break;
    }
    var Rwy = '';
    var Freq = '';
    if (A.Rwy != 0) {
      Rwy = A.Rwy + 'ft';
    } else {
      A.Rwy = '';
    }
    if (A.Freq != '') {
      Freq = A.Freq + 'hz';
    }
    //html += '<tr onClick="navTo('+a+')"><td><div class="vertical-center-container"><img src="./images/' + Icon + '.png" width="20px" /></div></td><td>'+A.ICAO+'<div style="font-size:10pt;">'+A.Name+'</div></td><td>'+A.bearing+'&degT</td><td>'+A.distance+' nm</td><td>' + A.Rwy + '</td><td>' + A.Freq + '</td></tr>';
    html +=
      '<tr class="selectable" onClick="DisplayClosestAptDetails(' +
      a +
      ')"><td><div class="vertical-center-container"><img src="./images/' +
      Icon +
      '.png" width="20px" /></div></td><td class="directToTxt">' +
      A.ICAO +
      '</td><td><div style="font-size:10pt;">' +
      A.Name +
      '</div></td><td>' +
      A.bearing +
      '&degT</td><td>' +
      getDisplayDistance(A.distance) +
      '</td><td>' +
      Rwy +
      '</td><td>' +
      Freq +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#searchtabs-Airport').html(html);
  $('#searchtabs-Airport').enhanceWithin();
}

function DisplayClosestAptDetails(a) {
  var A = APlist[a];
  $('#gpsMenu').tabs();
  $('#gpsMenu').show();
  DisplayAptDetails(A);
}

function DisplayAptDetails(A) {
  closeSearchMenu();
  if (mapb != null) {
    mapb.on('click', closeDirectoTo);
  }

  $('#AirportTabDirectTo').click();
  $('#searchAirportICAO').val(A.ICAO);
  $('#search-airport-text').val(A.Name);
  $('#searchAirportResults').html('');
  A.CirroLayerType = 'Airport';
  MAPSTATE.CurDirectTo = A;
  var Icon = 'AptHardCT';
  switch (A.Type) {
    case 'large_airport':
      Icon = 'AptBig';
      break;
    case 'medium_airport':
      Icon = 'AptHard';
      break;
    case 'small_airport':
      Icon = 'AptOther';
      break;
    case 'seaplane_base':
      Icon = 'AirportSea0';
      break;
    case 'heliport':
      Icon = 'AirportHeli0';
      break;
  }
  var LocFrom = '<div class="directToTxt"><b>From Map Center:</b></div>';
  if (GPS.Active == 1) {
    LocFrom = '<div class="directToTxt"><b>From Current Location</b></div>';
  }
  var Sun = calculateSunriseSunset(A.Lat, A.Long);
  var html = '';
  if (A.Freq != '') {
    html +=
      '<table class="first-td-fill"><tr><td style="vertical-align:top;"><b>' +
      A.FreqType +
      ':</b> ' +
      A.Freq +
      'hz<br/>';
  } else {
    html += '<table class="first-td-fill"><tr><td style="vertical-align:top;"<b>Freq:</b> Unavailable<br />';
  }
  html += '<b>City:</b> ' + A.City + '<br /><b>Region:</b> ' + A.Region + '<br /><b>Rwy:</b> ' + A.Rwy + ' ft<br />';
  html += '<b>Sun Rise:</b> ' + Sun.Sunrise + ' <b>Set:</b> ' + Sun.Sunset;
  html += LocFrom;
  var LatCoordTXT;
  var LongCoordTXT;
  if (LOCALSTORAGE.CoordFormat == 'UTM') {
    var utmOK = getUTM(A.Lat, A.Long);
    LatCoordTXT = utmzone + ' 0' + utmlat;
    LongCoordTXT = 'UTM ' + utmlong;
  } else {
    LongCoordTXT = ConvertDD_User(A.Long, 'Long');
    LatCoordTXT = ConvertDD_User(A.Lat, 'Lat');
  }

  html +=
    '<table class="last-td-fill"><tr><td class="directToTxt"><b>' +
    getDisplayDistance(A.distance) +
    '</b></td><td class="directToTxt"><b>' +
    A.bearing +
    '&degT' +
    '</b></td><td><b>' +
    LatCoordTXT +
    '<br />' +
    LongCoordTXT +
    '</b></td><td>';

  html +=
    '<button class="directto-btn" onClick="navigateToMarker(\'' +
    A.ICAO +
    "'," +
    A.Lat +
    ',' +
    A.Long +
    ', \'Airport\')"> <img src="./images/DirectTo.png" width="35" height="35"/></button>';
  if (MAPSTATE.Measuring != false) {
    html +=
      '<button class="directto-btn" onClick="routeToMarker(\'' +
      A.ICAO +
      "'," +
      A.Lat +
      ',' +
      A.Long +
      ')"><img src="./images/routeplan.png" width="35" height="35"/></button>';
  }
  CurMarkerProperties = A;
  CurMarkerProperties.NavType = 'Airport';
  html += '</td></tr></table>';
  html += '</td><td style="vertical-align:top;">';
  html += '<div id="AIRPORTPREVIEW" class="previewmap"></div>';
  html += '</td></tr></table><br />';
  html += `<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">
					<input type="radio" name="directToINFO" id="directToINFO_Facility"  checked="checked" onClick="ShowdirectToINFO('FacilityInfo')">
					<label for="directToINFO_Facility">Facility</label>
                    <input type="radio" name="directToINFO" id="directToINFO_IFR" onClick="ShowdirectToINFO('IFRInfo')">
					<label for="directToINFO_IFR">IFR</label>
					<input type="radio" name="directToINFO" id="directToINFO_METAR" onClick="ShowdirectToINFO('METARInfo')">
					<label for="directToINFO_METAR">METAR / TAF</label>
					<input type="radio" name="directToINFO" id="directToINFO_NOTAM" onClick="ShowdirectToINFO('NOTAMInfo')">
					<label for="directToINFO_NOTAM">NOTAM</label>
				</fieldset>`;

  html +=
    '<label for="panzoomToggleBTN">' +
    iTrans('Pan Zoom Facility / IFR Pages') +
    '</label><input type="checkbox" id="panzoomToggleBTN" data-mini="true" onClick="togglePinchZoom(this);" />';
  html +=
    '<div style="overflow-y:hidden; overflow-x: scroll;" id="PDF_Zoom_Hldr"><div id="FacilityInfo" class="pdfjsdiv"></div></div>';
  html += '<div id="IFRInfo" style="display: none;">';
  html +=
    "<button data-mini='true' data-icon='delete' class='redbg' onClick='removeSVGoverlay(); $(\"#IFRInfoPlate\").html(\"\");'>Remove Overlay From Map / Hide IFR Info</button>";
  html +=
    '<div style="overflow-y:hidden; overflow-x: scroll;" id="PDF_Zoom_IFR_Hldr"><div id="IFRInfoPlate" class="pdfjsdiv"></div></div><div id="IFRInfoList"></div></div>';
  html += '<div id="METARInfo" style="display: none;"><h2>Loading METAR/TAF Info...</h2></div>';
  html += '<div id="NOTAMInfo" style="display: none;"><h2>Loading NOTAM Info...</h2></div>';
  html += '<br /><br />';
  $('#searchAirportResults').html(html);
  $('#searchAirportResults').enhanceWithin();
  MakePreviewMap(A, 'AIRPORTPREVIEW');
  if (CFS == 1) {
    $('#FacilityInfo').html('<h2>Loading Facility Info...</h2>');
    GetFacilityInfo(A);
  } else {
    $('#FacilityInfo').html('<h2>Not Subscribed to Facility Info</h2>');
  }
  if (IFR_CHARTS == 1) {
    $('#IFRInfoList').html('<h2>Loading IFR Info...</h2>');
    GetIFRInfo(A);
  } else {
    $('#IFRInfoList').html('<h2>Not Subscribed to IFR Info</h2>');
  }
  GetMetarTafNotam(A);
}

var AirportPreviewMap = null;
var NavaidPreviewMap = null;

function flyToMapPos(center, bearing = null, zoom = null, bounds = false) {
  let options = { center: center };
  if (bearing != null) {
    options.bearing = bearing;
  }
  if (zoom != null) {
    options.zoom = zoom;
  }
  if (bounds) {
  } else {
    if (MAPSTATE.OfflineDataOn) {
      if (zoom != null) {
        mapb.setZoom(zoom);
      }
      if (bearing != null) {
        mapb.setBearing(bearing);
      }
      mapb.setCenter(center);
    } else {
      mapb.flyTo(options);
    }
  }

  console.log('Moving Map To', options);
}

function flyToMapBounds(bounds, padding, maxZoom = null) {
  var options = { animate: !MAPSTATE.OfflineDataOn, padding: padding };
  if (maxZoom != null) {
    options.maxZoom = maxZoom;
  }
  if (MAPSTATE.OfflineDataOn && !CORDOVA) {
    return;
  }
  mapb.fitBounds(bounds, options);
}

MAPSTATE.AirportPreviewCenter = [0, 0];
MAPSTATE.NavaidPreviewCenter = [0, 0];

function MakePreviewMap(A, container) {
  if (container == 'AIRPORTPREVIEW') {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    if (AirportPreviewMap != null) {
      AirportPreviewMap.remove();
      AirportPreviewMap = null;
      //$("#AIRPORTPREVIEW").off('click', flyToMapPos);
    }
    //todo use current map style but need to update mapbox build to accept mbtiles in properties for validation
    AirportPreviewMap = new mapboxgl.Map({
      container: 'AIRPORTPREVIEW', // container id
      style: mapb.getStyle(),
      center: [A.Long, A.Lat], // starting position
      zoom: 11, // starting zoom
      minzoom: 11,
      pitch: 0,
      bearing: 0,
      maxTileCacheSize: 0,
    });
    MAPSTATE.AirportPreviewCenter = [A.Long, A.Lat];
    AirportPreviewMap.on('load', function () {
      //AirportPreviewMap.dragPan.disable();
      //AirportPreviewMap.scrollZoom.disable();
      var Icon = 'airfield-11';
      switch (A.Type) {
        case 'large_airport':
          Icon = 'airport-11';
          break;
        case 'medium_airport':
          Icon = 'airport-15';
          break;
        case 'small_airport':
          Icon = 'airfield-11';
          break;
        case 'seaplane_base':
          Icon = 'Airport_Water';
          break;
        case 'heliport':
          Icon = 'Asset 3';
          break;
      }
      var URL = BASEURL + 'SpritesPNG/' + Icon + '.png';
      if (CORDOVA) {
        URL = '/SpritesPNG/' + Icon + '.png';
      }
      AirportPreviewMap.loadImage(URL, function (error, image) {
        AirportPreviewMap.addSource('airportmarker', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [A.Long, A.Lat],
                  properties: {
                    Test: 'test',
                  },
                },
              },
            ],
          },
        });
        AirportPreviewMap.addLayer({
          id: 'airportmarker',
          type: 'symbol',
          source: 'airportmarker',
          layout: {
            'icon-image': Icon,
            'icon-size': MAPSTATE.IconSize,
          },
        });
      });
    });
  }
  if (container == 'NAVAIDPREVIEW') {
    mapboxgl.accessToken = MAPBOX_TOKEN;
    if (NavaidPreviewMap != null) {
      NavaidPreviewMap.remove();
      NavaidPreviewMap = null;
    }
    //todo use current map style but need to update mapbox build to accept mbtiles in properties for validation
    NavaidPreviewMap = new mapboxgl.Map({
      container: 'NAVAIDPREVIEW', // container id
      style: mapb.getStyle(),
      center: [A.Long, A.Lat], // starting position
      zoom: 11, // starting zoom
      minzoom: 11,
      pitch: 0,
      bearing: 0,
      maxTileCacheSize: 0,
    });
    MAPSTATE.NavaidPreviewCenter = [A.Long, A.Lat];
    NavaidPreviewMap.on('load', function () {
      //NavaidPreviewMap.dragPan.disable();
      //NavaidPreviewMap.scrollZoom.disable();
      var URL = BASEURL + 'SpritesPNG/' + A.Type + '.png';
      if (CORDOVA) {
        URL = '/SpritesPNG/' + A.Type + '.png';
      }

      NavaidPreviewMap.loadImage(URL, function (error, image) {
        if (error) {
          throw error;
        }
        NavaidPreviewMap.addImage(A.Type, image);
        NavaidPreviewMap.addSource('navaid', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [A.Long, A.Lat],
                  properties: {
                    Test: 'test',
                  },
                },
              },
            ],
          },
        });
        NavaidPreviewMap.addLayer({
          id: 'navaid',
          type: 'symbol',
          source: 'navaid',
          layout: {
            'icon-image': A.Type,
            'icon-size': MAPSTATE.IconSize,
          },
        });
      });
    });
  }
}

function GetIFRInfo(A) {
  if (ONLINE && !MAPSTATE.OfflineDataOn) {
    if (A.Country == 'CA') {
      $.getJSON(BASEURL + 'menu-Map-AJAX.php?action=getCA_IFR&ICAO=' + A.ICAO, function (json_data) {
        //console.log(json_data);
        if (json_data.length == 0) {
          $('#IFRInfoList').html('<h2>No IFR Data For This Airport</h2>');
        } else {
          var html = '';
          for (var i in json_data) {
            var I = json_data[i];

            if (I.map == 0) {
              html +=
                '<button onClick="LoadFacilityInfo(\'' +
                AVDATA_URL +
                '/CAP/' +
                encodeURIComponent(I.file) +
                '\',\'IFRInfoPlate\');" class="yellowbg wrap" data-mini="true">' +
                I.description +
                '</button>';
            } else {
              html +=
                "<button data-mini='true' class='greenbg wrap' onClick='getOverlaySVG(\"" +
                I.file +
                '", "' +
                I.bounds +
                '","CA")\'>' +
                I.description +
                '</button>';
            }
          }
          $('#IFRInfoList').html(html);
          $('#IFRInfoList').enhanceWithin();
        }
      });
    } else {
      $.getJSON(
        BASEURL +
          'menu-Map-AJAX.php?action=getUS_IFR&ICAO=' +
          encodeURIComponent(A.ICAO) +
          '&local=' +
          encodeURIComponent(A.local_code),
        function (json_data) {
          //console.log(json_data);
          if (json_data.length == 0) {
            $('#IFRInfoList').html('<h2>No IFR Data For This Airport</h2>');
          } else {
            var html = '';
            for (var i in json_data) {
              var I = json_data[i];

              if (I.map == 0) {
                html +=
                  '<button onClick="LoadFacilityInfo(\'' +
                  AVDATA_URL +
                  '/US_IFR_PDF/' +
                  encodeURIComponent(I.file) +
                  '\',\'IFRInfoPlate\');" class="yellowbg wrap" data-mini="true">' +
                  I.description +
                  '</button>';
              } else {
                html +=
                  "<button data-mini='true' class='greenbg wrap' onClick='getOverlaySVG(\"" +
                  I.file +
                  '", "' +
                  I.bounds +
                  '","US")\'>' +
                  I.description +
                  '</button>';
              }
            }
            $('#IFRInfoList').html(html);
            $('#IFRInfoList').enhanceWithin();
          }
        }
      );
    }
  } else {
    if (CORDOVA) {
      if (A.Country == 'CA') {
        search_CA_IFR_INDEX(A.ICAO, true, 'IFRInfoList');
      } else {
        search_US_IFR_INDEX(A.ICAO, A.local_code, true, 'IFRInfoList');
      }
    } else {
      $('#IFRInfoList').html('<h2>Not Available Offline</h2>');
      $('#IFRInfoList').enhanceWithin();
    }
  }
}

function ShowdirectToINFO(id) {
  $('#FacilityInfo').hide();
  $('#IFRInfo').hide();
  $('#METARInfo').hide();
  $('#NOTAMInfo').hide();
  $('#' + id).show();
}

function GetMetarTafNotam(A) {
  if (ONLINE) {
    var now = new Date();
    var now = new Date();
    now = iTrans('As of') + ': ' + now.toUTCString() + '<br />';
    $.ajax({
      url: BASEURL + 'menu-Map-AJAX.php?action=metarTAF&site=' + encodeURIComponent(A.ICAO),
      dataType: 'text',
      success: function (data) {
        $('#METARInfo').html(now + data);
      },
    });

    if (A.Country == 'CA') {
      $.getJSON(
        BASEURL + 'menu-Map-AJAX.php?action=getNOTAM_CA&ident=' + encodeURIComponent(A.ICAO),
        function (json_data) {
          $('#NOTAMInfo').html(now + json_data.Notams);
        }
      );
    } else {
      $.getJSON(
        BASEURL + 'menu-Map-AJAX.php?action=getNOTAM_ICAO&ident=' + encodeURIComponent(A.ICAO),
        function (json_data) {
          $('#NOTAMInfo').html(now + json_data.Notams);
        }
      );
    }
  } else {
    $('#METARInfo').html('<h2>Not Available Offline</h2>');
    $('#NOTAMInfo').html('<h2>Not Available Offline</h2>');
  }
}

function GetFacilityInfo(A) {
  MAPSTATE.curAirport = A;
  if (A.Facility == '') {
    $('#FacilityInfo').html('<h3>No Data For This Airport</h3>');
    return;
  }
  if (ONLINE) {
    var URL = '';
    if (A.Country == 'CA') {
      URL = AVDATA_URL + '/CFS/' + encodeURIComponent(A.Facility);
    } else if (A.Country == 'US') {
      URL = AVDATA_URL + '/US_PDF/' + encodeURIComponent(A.local_code) + '.pdf';
    } else {
      $('#FacilityInfo').html('<h2>Not Data For This Country</h2>');
      return;
    }
    LoadFacilityInfo(URL, 'FacilityInfo');
  } else if (CORDOVA) {
    GetFacilityInfoBase64(A.ICAO, A.local_code, A.Country, 'application/pdf');
    //"data:application/pdf;base64," + Base64pdf
  } else {
    $('#FacilityInfo').html('<h2>Not Available Offline!</h2>');
  }
}

function GetFacilityInfoBase64(ICAO, local_code, Country, type) {
  let table;

  if (Country === 'US') {
    table = UsAmericanFacilityInfoTable.query();
    name = local_code + '.pdf';
  } else if (Country === 'CA') {
    table = CanadianFacilityInfoTable.query();
    name = ICAO + '.pdf';
  } else {
    verbose.error('MK', `Invalid Country "${Country}" for Facility Info Request`);
    return;
  }

  table
    .raw({
      q: 'select base64(pdf) as PDF from pdf where name = ?',
      v: [name],
    })
    .then((res) => {
      if (!Array.isArray(res) || res.length <= 0) {
        return;
      }

      let PDF = res[0].PDF;
      LoadFacilityInfo('data:application/pdf;base64,' + PDF, 'FacilityInfo');
    })
    .catch((e) => {
      $('#FacilityInfo').html('<h2>Missing Offline Database</h2>');
    });
}

function LoadFacilityInfo(URL, divID) {
  var scale = 2;
  if (ELECTRON && !ONLINE) {
    //remote.shell.openItem(app.getPath("userData") + "/" + name);
    $('#' + divID).html('<h2>Loading Content...</h2>');
    var currentPage = 1;
    pdfjsLib.disableWorker = true; // due to CORS
    var loadingTask = pdfjsLib.getDocument(URL);
    LoadPDFPages(loadingTask, divID);
    return;
  }

  if (CORDOVA && !ONLINE) {
    $('#' + divID).html('<h2>Loading Offline Content...</h2>');
    var currentPage = 1;

    pdfjsLib.disableWorker = true; // due to CORS
    var loadingTask = pdfjsLib.getDocument({ data: atob(URL.split(',')[1]) });
    LoadPDFPages(loadingTask, divID);

    return;
  }
  $('#' + divID).html('<h2>Loading Content...</h2>');

  var request = new XMLHttpRequest();

  request.open('GET', URL, true);
  request.responseType = 'arraybuffer';
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status === 404) {
        $('#' + divID).html('<h2>No Facility Data.</h2>');
      } else if (request.status === 200) {
        $('#' + divID).html('<h2>Loading Content...</h2>');
      } else {
        $('#' + divID).html(
          '<h2>Error Loading: <a href="#" onClick="LoadFacilityInfo(\'' +
            escapeHtml(URL) +
            "','" +
            divID +
            '\')">Retry Now</h2>Note: Pop-up and Ad blockers have been identified as a possible cause. Please disable them if this problem persists.'
        );
      }
    }
  };
  request.onload = function (oEvent) {
    pdfjsLib.disableWorker = true; // due to CORS
    if (!IOS) {
      LoadSVGPages({ data: request.response }, divID);
      return;
    }
    var loadingTask = pdfjsLib.getDocument({ data: request.response });
    LoadPDFPages(loadingTask, divID);
  };
  request.onerror = function () {
    $('#' + divID).html(
      '<h2>Error Loading: <a href="#" onClick="LoadFacilityInfo(\'' +
        escapeHtml(URL) +
        "','" +
        divID +
        '\')">Retry Now</h2>Note: Pop-up and Ad blockers have been identified as a possible cause. Please disable them if this problem persists.'
    );
  };
  request.ontimeout = function (e) {
    $('#' + divID).html(
      '<h2>Loading Timed Out: <a href="#" onClick="LoadFacilityInfo(\'' +
        escapeHtml(URL) +
        "','" +
        divID +
        '\')">Retry Now</h2>Note: Pop-up and Ad blockers have been identified as a possible cause. Please disable them if this problem persists.'
    );
  };
  request.timeout = 15000;
  request.send();
}

function testGeoPDF(divID = 'FacilityInfo') {
  var loadingTask = pdfjsLib.getDocument('https://dev.air-suite.com/LFHeli_pdf2014.pdf');
  LoadPDFPages(loadingTask, divID);
}

function togglePinchZoom(e) {
  if (e.checked) {
    MAPSTATE.panzoom = panzoom(MAPSTATE.panzoomContainer, {
      maxZoom: 5,
      minZoom: 0.5,
      smoothScroll: true,
      onDoubleClick: function (e) {
        instance.zoomAbs(0, 0, 1);
        instance.moveTo(0, 0);
        instance.dispose();
        instance = null;
        return true;
      },
      zoomDoubleClickSpeed: 1,
    });
    var Scrollcontainer = document.getElementById('directTotabs-Airport');
    $('#directTotabs-Airport').animate({ scrollTop: $('#panzoomToggleBTN').parent().position().top - 50 }, 'slow');
  } else {
    var instance = MAPSTATE.panzoom;
    instance.zoomAbs(0, 0, 1);
    instance.moveTo(0, 0);
    instance.dispose();
    MAPSTATE.panzoom = null;
    $('#directTotabs-Airport').animate({ scrollTop: 0 }, 'slow');
  }
}

function LoadSVGPages(URL, divID) {
  $('#' + divID).addClass('pdfjssvg');
  $('#' + divID).removeClass('pdfjssvgloaded');

  if (!pdfjsLib.getDocument || !pdfjsViewer.PDFViewer) {
    alert('Please build the pdfjs-dist library using\n  `gulp dist-install`');
  }

  $('#' + divID).html('<div id="viewer" class="pdfViewer"></div>');
  // Some PDFs need external cmaps.
  //
  var CMAP_URL = '../../node_modules/pdfjs-dist/cmaps/';
  var CMAP_PACKED = true;

  var container = document.getElementById(divID);
  console.log(container);
  MAPSTATE.panzoomContainer = container;
  var eventBus = new pdfjsViewer.EventBus();

  // (Optionally) enable hyperlinks within PDF files.
  var pdfLinkService = new pdfjsViewer.PDFLinkService({
    eventBus: eventBus,
  });

  var pdfViewer = new pdfjsViewer.PDFViewer(
    {
      container: container,
      eventBus: eventBus,
      linkService: pdfLinkService,
      renderer: 'svg',
      textLayerMode: 0,
    },
    []
  );
  pdfLinkService.setViewer(pdfViewer);

  eventBus.on('pagesinit', function () {
    // We can use pdfViewer now, e.g. let's change default scale.
    pdfViewer.currentScaleValue = 'page-width';
  });

  // Loading document.
  var loadingTask = pdfjsLib.getDocument(URL);
  loadingTask.promise.then(function (pdfDocument) {
    // Document loaded, specifying document for the viewer and
    // the (optional) linkService.

    pdfViewer.setDocument(pdfDocument);

    pdfLinkService.setDocument(pdfDocument, null);

    setTimeout(function () {
      $('#' + divID).addClass('pdfjssvgloaded');
      $('#' + divID).removeClass('pdfjssvg');
    }, 500);
  });
}

function LoadPDFPages(loadingTask, divID) {
  var currentPage = 1;
  loadingTask.promise.then(function (pdf) {
    function getPage() {
      pdf.getPage(currentPage).then(function (page) {
        //var LGIDictPromise = page.ensure(page, 'LGIDict');  //added
        console.log(page._pageInfo); //contains measure data that is coordinates to georeference goePDF's on a map
        //console.log("Printing " + currentPage);
        var scale = 2;
        var viewport = page.getViewport({ scale: scale });
        var canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');
        var renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        page.render(renderContext).promise.then(function () {
          pages.push(ctx.getImageData(0, 0, canvas.width, canvas.height));
          heights.push(height);
          height += canvas.height;
          if (width < canvas.width) {
            width = canvas.width;
          }
          if (currentPage < pdf.numPages) {
            currentPage++;
            getPage();
          } else {
            $('#' + divID).html('');
            draw(divID);
            pages = [];
            height = 0;
            heights = [];
          }
        });
      });
    }

    getPage();
  });
}

function searchICAO_Airport(ICAO) {
  searchICAO_AirportMOBILE(ICAO);
}

function searchICAO_AirportMOBILE(ICAO) {
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;

  AirportsTable.query()
    .select('ICAO like ?', [ICAO + '%'])
    .then(function (res) {
      Slist = computeBearingAndDistance(res, Long, Lat);
      showICAOAirports();
    })
    .catch((e) => {
      verbose.error('SQL', 'Error searching for airport: ' + e);
    });
}

function showICAOAirports(type) {
  $('#searchAirportICAO').prop('disabled', false);

  $('#search-airport-text').prop('disabled', false);
  if (Slist.length == 0) {
    $('#searchAirportResults').html('<h3>No results</h3>');
    return;
  }
  var html =
    '<table class="second-td-fill gpsmenuResult"><thead><tr><th></th><th>Airport</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
  var count = 0;
  Slist.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  var count = 0;
  if (Slist.length == 1) {
    DisplayAptDetails(Slist[0]);
  } else {
    $('#searchAirportICAO').focus();
    for (var a in Slist) {
      var A = Slist[a];
      var Icon = 'AptHardCT';
      switch (A.Type) {
        case 'closed':
          continue;
          break;
        case 'large_airport':
          Icon = 'AptBig';
          break;
        case 'medium_airport':
          Icon = 'AptHard';
          break;
        case 'small_airport':
          Icon = 'AptOther';
          break;
        case 'seaplane_base':
          Icon = 'AirportSea0';
          break;
        case 'heliport':
          Icon = 'AirportHeli0';
          break;
      }
      //html += '<tr onClick="SnavTo('+a+')"><td>'+A.ICAO+'<div style="font-size:10pt;">'+A.Name+'</div></td><td>'+A.bearing+'&degT</td><td>'+A.distance+' nm</td></tr>';
      html +=
        '<tr onClick="PreviewNavIndex(' +
        a +
        ')"><td><div class="vertical-center-container"><img src="./images/' +
        Icon +
        '.png" width="20px" /></div></td><td>' +
        A.ICAO +
        '<div style="font-size:10pt;">' +
        A.Name +
        '</div></td><td>' +
        A.bearing +
        '&degT</td><td>' +
        getDisplayDistance(A.distance) +
        '</td></tr>';
      count++;
      if (count > 25) {
        break;
      }
    }
    html += '</tbody></table>';
    $('#searchAirportResults').html(html);
  }
}

var OLDAirportIdent = '';

function PreviewNavIndex(SlistINDEX) {
  var A = Slist[SlistINDEX];
  OLDAirportIdent = $('#searchAirportICAO').val();
  DisplayAptDetails(A);
}

function searchText_Airport(text) {
  searchText_AirportMOBILE(text);
}

function searchText_AirportONLINE(text) {
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;
  $.getJSON(
    BASEURL +
      'menu-Map-AJAX.php?action=searchAirports&Type=Name&Name=' +
      encodeURIComponent(text) +
      '&Lat=' +
      Lat +
      '&Long=' +
      Long,
    function (data) {
      //local database is empty and offline fail with message
      var result = data.features.map((e) => e.properties);
      Slist = computeBearingAndDistance(result, Long, Lat);
      showTextAirports();
    }
  ).fail(function (jqXHR, status, error) {
    alert('Could not retrieve airports');
  });
}

function searchText_AirportMOBILE(text) {
  let center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  let Lat = center.lat;
  let Long = center.lng;

  AirportsTable.query()
    .select('Name like ?', ['%' + text + '%'])
    .then((result) => {
      Slist = computeBearingAndDistance(result, Long, Lat);
      showTextAirports();
    })
    .catch((e) => {
      verbose.error('SQL', 'Error searching for airport: ' + e);
    });
}

function searchText_AirportPC(text) {
  text = text.toLowerCase();
  var center = { lat: 0, lng: 0 };
  if (mapb != null) {
    center = mapb.getBounds().getCenter();
  }
  if (GPS.Active) {
    center = GPS.lnglat;
  }
  var Lat = center.lat;
  var Long = center.lng;
  $('#searchAirportResults').html('<h3>Updating Results</h3>');
  var Temp_Slist = [];

  cirroDB.query('Airports', 'Name LIKE ? ', ['%' + text + '%'], function (e) {
    if (e === false) {
      e = [];
    }
    Slist = computeBearingAndDistance(e, Long, Lat);
    showTextAirports();
  });
}

function showTextAirports() {
  $('#searchAirportICAO').prop('disabled', false);
  $('#search-airport-text').prop('disabled', false);

  if (Slist.length == 0) {
    $('#searchAirportResults').html('<h3>No results</h3>');
    return;
  } else {
    $('#search-airport-text').focus();
  }
  var html =
    '<table class="first-td-fill gpsmenuResult"><thead><tr><th>Airport</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
  var count = 0;
  Slist.sort(function (a, b) {
    return parseFloat(a.distance) - parseFloat(b.distance);
  });
  var count = 0;
  for (var a in Slist) {
    var A = Slist[a];
    html +=
      '<tr onClick="PreviewNavIndex(' +
      a +
      ')"><td>' +
      A.ICAO +
      '<div style="font-size:10pt;">' +
      A.Name +
      '</div></td><td>' +
      A.bearing +
      '&degT</td><td>' +
      getDisplayDistance(A.distance) +
      '</td></tr>';
    count++;
    if (count > 25) {
      break;
    }
  }
  html += '</tbody></table>';
  $('#searchAirportResults').html(html);
}

/*	****************************************	Waypoint  DB stuff      *********************************** */

var WPdata = {};

function openWPdb() {
  getWPlist();
}

function getWPlist() {
  $('#wpLIST').empty();
  WPdata = {};
  var i = 0;

  cirroDB.query('Waypoints', 'PrimaryKey != ?', [''], function (e) {
    if (e === false) {
      e = [];
    }
    for (var I in e) {
      var wpData = e[I];
      WPdata['wpts-' + wpData.PrimaryKey] = wpData;
      $('#wpLIST').append(
        '<label for="wpts-' +
          wpData.PrimaryKey +
          '">' +
          wpData.Name +
          '</label><input type="checkbox"  value="wpts-' +
          wpData.PrimaryKey +
          '" id="wpts-' +
          wpData.PrimaryKey +
          '" data-mini="true" onClick="getWP(this.id,this.value)">'
      );
      i++;
    }
    $('#wpLIST').enhanceWithin();
    //display waypoint with id that match.
    localStorageDB.getItem('DataList', function (e) {
      var curWaypoints = e;
      if (curWaypoints != null) {
        curWaypoints = JSON.parse(curWaypoints);
      } else {
        curWaypoints = {};
      }
      for (var i in curWaypoints) {
        //if (curWaypoints[i] == null || typeof WPdata[i] == 'undefined') continue;
        $('#' + curWaypoints[i])
          .attr('checked', true)
          .checkboxradio('refresh');
        getWP(curWaypoints[i], i);
      }
    });
  });
}

function getWaypointData(PrimaryKey) {
  cirroDB.query('Waypoints', 'PrimaryKey != ?', [''], function (e) {
    if (e === false) {
      e = [];
    }
    return e;
  });
}

var WP_ARRAY = {};
var deviceON = false;
var labelsON = false;
var wptsON = true;
var linesON = true;
var areasON = true;
var WP_Index = {};

function getWP(id, index) {
  //console.log("Load Weightpoint set: "+id);
  //var id = this.id;
  //var index = this.value;
  localStorageDB.getItem('DataList', function (e) {
    var curWaypoints = e;
    if (curWaypoints != null) {
      var curWaypoints2 = JSON.parse(curWaypoints);
      curWaypoints = {};
      for (var c in curWaypoints2) {
        if (curWaypoints2[c] != null) {
          curWaypoints[curWaypoints2[c]] = curWaypoints2[c];
        }
      }
    } else {
      curWaypoints = {};
    }
    if ($('#' + id).is(':checked')) {
      curWaypoints[index] = id;
      //console.log("Load: "+index);
      //console.log(curWaypoints);
      //create new array index in layer group
      //addObstacles(WPdata[index].Data, flightRoutes, false);
      cirroDB.query('Waypoints', 'PrimaryKey = ?', WPdata[index].PrimaryKey, function (e) {
        if (e === false) {
          e = [];
        }
        console.log(e);
        for (var I in e) {
          var wpData = e[I];
          addGeoJson(wpData.data, id, WPdata[index].Name);
          WP_ARRAY['wpts-' + e[I].PrimaryKey] = wpData;
        }
      });
      WP_Index[index] = index;
    } else {
      //console.log("Remove: "+index);
      //remove array index in layer group
      //console.log(WP_ARRAY[index]);
      if (WP_ARRAY[index] != undefined) {
        //for doug's problem
        mapb.removeLayer(index + '-point-LABELS');
        mapb.removeLayer(index + '-line-LABELS');
        mapb.removeLayer(index + '-polygon-LABELS');
        mapb.removeLayer(index + '-point');
        mapb.removeLayer(index + '-line');
        mapb.removeLayer(index + '-polygon');
        mapb.removeSource(index);
        delete WP_Index[index];
        delete WP_ARRAY[index];
        delete WPTshared[index];
      }
      delete curWaypoints[index];
    }
    //console.log(JSON.stringify(curWaypoints));
    localStorageDB.setItem('DataList', JSON.stringify(curWaypoints));
    $('#wpLIST').enhanceWithin();
  });
}

function WP_Filter(e) {
  //console.log("filtering:"+e.id);
  var id = e.id;
  var checked = false;
  if ($('#' + id).is(':checked')) {
    checked = true;
  }
  switch (id) {
    case 'labelsON':
      labelsON = checked;
      if (mapb.getSource('WPTS') != undefined) {
        if (labelsON) {
          mapb.setLayoutProperty('WPTS-LABELS', 'visibility', 'visible');
        } else {
          mapb.setLayoutProperty('WPTS-LABELS', 'visibility', 'none');
        }
      }
      if (labelsON) {
        localStorageDB.setItem('labelsON', 1);
      } else {
        localStorageDB.setItem('labelsON', 0);
      }
      break;
    case 'wptsON':
      wptsON = checked;
      break;
    case 'deviceON':
      deviceON = checked;
      if (deviceON) {
        addUserWPTS();
        localStorageDB.setItem('DeviceON', 1);
      } else {
        localStorageDB.setItem('DeviceON', 0);
        mapb.off('click', editWaypoint);
        removeWPT_Layers();
        $('#deviceWPTsave').hide();
        $('#deviceWPTdelete').hide();
      }
      break;
    case 'linesON':
      linesON = checked;
      break;
    case 'areasON':
      areasON = checked;
      break;
  }
  //console.log(linesON);
  //console.log(wptsON);
  //console.log(areasON);
  for (var i in WP_ARRAY) {
    //console.log("processing");
    //mapb.removeLayer(WP_ARRAY[i]);
    //var item = WP_ARRAY[i];
    var item = i;
    if (wptsON) {
      mapb.setLayoutProperty(item + '-point', 'visibility', 'visible');
    } else {
      mapb.setLayoutProperty(item + '-point', 'visibility', 'none');
    }
    if (linesON) {
      mapb.setLayoutProperty(item + '-line', 'visibility', 'visible');
    } else {
      mapb.setLayoutProperty(item + '-line', 'visibility', 'none');
    }
    if (areasON) {
      mapb.setLayoutProperty(item + '-polygon', 'visibility', 'visible');
    } else {
      mapb.setLayoutProperty(item + '-polygon', 'visibility', 'none');
    }
    if (labelsON) {
      mapb.setLayoutProperty(item + '-point-LABELS', 'visibility', 'visible');
      mapb.setLayoutProperty(item + '-line-LABELS', 'visibility', 'visible');
      mapb.setLayoutProperty(item + '-polygon-LABELS', 'visibility', 'visible');
    } else {
      mapb.setLayoutProperty(item + '-point-LABELS', 'visibility', 'none');
      mapb.setLayoutProperty(item + '-line-LABELS', 'visibility', 'none');
      mapb.setLayoutProperty(item + '-polygon-LABELS', 'visibility', 'none');
    }
  }
}

function WP_DeviceON() {
  var id = this.id;
  var checked = false;
  if ($('#' + id).is(':checked')) {
    checked = true;
  }
  if (checked) {
    addUserWPTS();
  } else {
    removeWPT_Layers();
  }
}

function addUserWPTS() {
  removeWPT_Layers();

  localStorageDB.getItem('DeviceWPTS', function (e) {
    var DeviceWPTS = JSON.parse(e);
    if (DeviceWPTS == null) {
      return;
    }
    if (DeviceWPTS.length) {
      $('#deviceWPTsave').show();
      $('#deviceWPTdelete').show();
      var geojson = WPTAdd(DeviceWPTS);
      if (mapb.getSource('WPTS') == undefined) {
        //console.log("ListenerAdded");
        mapb.off('click', editWaypoint);
        mapb.on('click', editWaypoint);
        WPT_LAYERS.push('WPTS');
        mapb.addSource('WPTS', {
          type: 'geojson',
          data: geojson,
        });
        var labelsVisible = 'none';
        if (labelsON) {
          labelsVisible = 'visible';
        }
        mapb.addLayer(
          {
            id: 'WPTS',
            type: 'symbol',
            source: 'WPTS',
            filter: ['all', ['==', '$type', 'Point']],
            layout: {
              'icon-image': 'WPT',
              'icon-size': 0.4,
              'icon-rotate': {
                property: 'Heading',
                type: 'identity',
              },
              visibility: 'visible',
              'icon-padding': 0,
              'icon-offset': [0, 0],
              'icon-rotation-alignment': 'map',
              'icon-ignore-placement': true,
              'icon-allow-overlap': true,
              'symbol-placement': 'point',
            },
            paint: {
              'icon-opacity': 0.8,
            },
          },
          'AIRCRAFT'
        );
        mapb.addLayer({
          id: 'WPTS-LABELS',
          type: 'symbol',
          source: 'WPTS',
          layout: {
            visibility: labelsVisible,
            'symbol-placement': 'point',
            'text-field': '{Name}',
            'text-size': TEXT_SIZE,
            'text-justify': 'center',
            'text-anchor': 'center',
            'text-offset': [2, 0],
          },
          filter: ['all', ['==', '$type', 'Point']],
          paint: {
            'text-halo-color': TEXT_HALO_COLOR,
            'text-halo-width': TEXT_HALO_WIDTH,
            'text-halo-blur': TEXT_HALO_BLUR,
            'text-color': TEXT_COLOR,
          },
        });
      } else {
        mapb.getSource('WPTS').setData(geojson);
      }
    } else {
      $('#deviceWPTsave').hide();
      $('#deviceWPTdelete').hide();
    }
    //console.log(WPT_LAYERS);
  });
}

function removeWPT_Layers() {
  for (var i in WPT_LAYERS) {
    var item = WPT_LAYERS[i];
    mapb.removeLayer(item);
    mapb.removeLayer(item + '-LABELS');
    mapb.removeSource(item);
  }
  WPT_LAYERS = [];
}

function WPTAdd(wptdata) {
  var data = {
    type: 'FeatureCollection',
    features: [],
  };
  for (var i in wptdata) {
    var item = wptdata[i];
    //console.log(item);
    data.features.push({
      type: 'Feature',
      properties: { Name: item.Name, Lat: item.Lat, Long: item.Long, Index: item.Index },
      geometry: { type: 'Point', coordinates: [item.Long, item.Lat] },
    });
  }
  return data;
}

function saveUserWPTS() {
  //removeWPT_Layers();

  localStorageDB.getItem('DeviceWPTS', function (e) {
    var DeviceWPTS = JSON.parse(e);
    var jsonDeviceWPTS = WPTAdd(DeviceWPTS);
    //console.log(jsonDeviceWPTS);
    saveWPTS(jsonDeviceWPTS);
  });
}

function deleteUserWPTS() {
  $('#acPanel').hide();
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Warning',
    headerClose: false,
    buttonPrompt: '<p>This will remove all Personal Device waypoints from this device!<br /><b>Are you sure?</b></p>',
    buttons: {
      Delete: {
        click: function () {
          localStorageDB.setItem('DeviceWPTS', '[]');
          removeWPT_Layers();
          $('#deviceWPTsave').hide();
          $('#deviceWPTdelete').hide();
          mapb.off('click', editWaypoint);
        },
      },
      Cancel: {
        click: function () {},
      },
    },
  });
}

function addGeoJson(wpdata, id, layer) {
  var Pointarray = [];
  //console.log("adding geojson");
  var Point = wpdata.Point;
  var Polyline = wpdata.Polyline;
  var Polygon = wpdata.Polygon;
  var Icon = wpdata.Icon;
  var circleOutline = '#00FF00';
  var polygonOutline = '#00FF00';
  var pointOpacity = 1;
  var lineOpacity = 1;
  var polygonOpacity = 1;
  var pointRadius = 12;
  var lineStroke = 3;
  var circleStroke = 3;
  var polygonStroke = 3;
  var overrideStyle = 0;
  if (wpdata.overrideStyle != undefined) {
    overrideStyle = wpdata.overrideStyle;
    circleOutline = wpdata.circleOutline;
    polygonOutline = wpdata.polygonOutline;
    pointOpacity = wpdata.pointOpacity;
    lineOpacity = wpdata.lineOpacity;
    polygonOpacity = wpdata.polygonOpacity;
    pointRadius = wpdata.pointRadius;
    lineStroke = wpdata.lineStroke;
    circleStroke = wpdata.circleStroke;
    polygonStroke = wpdata.polygonStroke;
  }
  var data = {
    type: 'FeatureCollection',
    features: [],
  };
  //console.log(wpdata);
  for (var i in wpdata.Data) {
    var p = wpdata.Data[i];

    switch (p.geometry.type) {
      case 'Point':
        if (p.properties['circle-radius'] == undefined) {
          p.properties['circle-radius'] = pointRadius;
        }
        if (p.properties['circle-color'] == undefined) {
          p.properties['circle-color'] = Point;
        } else if (p.properties['circle-color'].charAt(0) != '#') {
          p.properties['circle-color'] = '#' + p.properties['circle-color'];
        }
        if (p.properties['circle-opacity'] == undefined) {
          p.properties['circle-opacity'] = pointOpacity;
        }
        if (p.properties['circle-stroke-color'] == undefined) {
          p.properties['circle-stroke-color'] = circleOutline;
        } else if (p.properties['circle-stroke-color'].charAt(0) != '#') {
          p.properties['circle-stroke-color'] = '#' + p.properties['circle-stroke-color'];
        }
        if (p.properties['circle-stroke-width'] == undefined) {
          p.properties['circle-stroke-width'] = circleStroke;
        }
        if (p.properties['circle-stroke-opacity'] == undefined) {
          p.properties['circle-stroke-opacity'] = pointOpacity;
        }
        break;
      case 'LineString':
      case 'MultiLineString':
        if (p.properties['line-color'] == undefined) {
          p.properties['line-color'] = Polyline;
        } else if (p.properties['line-color'].charAt(0) != '#') {
          p.properties['line-color'] = '#' + p.properties['line-color'];
        }
        if (p.properties['line-opacity'] == undefined) {
          p.properties['line-opacity'] = lineOpacity;
        }
        if (p.properties['line-width'] == undefined) {
          p.properties['line-width'] = lineStroke;
        }
      case 'Polygon':
        if (p.properties['fill-color'] == undefined) {
          p.properties['fill-color'] = Polygon;
        } else if (p.properties['fill-color'].charAt(0) != '#') {
          p.properties['fill-color'] = '#' + p.properties['fill-color'];
        }
        if (p.properties['fill-opacity'] == undefined) {
          p.properties['fill-opacity'] = polygonOpacity;
        }
        if (p.properties['fill-outline-color'] == undefined) {
          p.properties['fill-outline-color'] = polygonOutline;
        } else if (p.properties['fill-outline-color'].charAt(0) != '#') {
          p.properties['fill-outline-color'] = '#' + p.properties['fill-outline-color'];
        }
        break;
      case 'GeometryCollection':
        if (p.properties['line-color'] == undefined) {
          p.properties['line-color'] = Polyline;
        } else if (p.properties['line-color'].charAt(0) != '#') {
          p.properties['line-color'] = '#' + p.properties['line-color'];
        }
        if (p.properties['line-width'] == undefined) {
          p.properties['line-width'] = lineStroke;
        }
        if (p.properties['fill-color'] == undefined) {
          p.properties['fill-color'] = Polygon;
        } else if (p.properties['fill-color'].charAt(0) != '#') {
          p.properties['fill-color'] = '#' + p.properties['fill-color'];
        }
        if (p.properties['line-opacity'] == undefined) {
          p.properties['line-opacity'] = lineOpacity;
        }
        if (p.properties['fill-opacity'] == undefined) {
          p.properties['fill-opacity'] = lineOpacity;
        }
        if (p.properties['fill-outline-color'] == undefined) {
          p.properties['fill-outline-color'] = polygonOutline;
        } else if (p.properties['fill-outline-color'].charAt(0) != '#') {
          p.properties['fill-outline-color'] = '#' + p.properties['fill-outline-color'];
        }
        if (p.properties['circle-radius'] == undefined) {
          p.properties['circle-radius'] = pointRadius;
        }
        if (p.properties['circle-color'] == undefined) {
          p.properties['circle-color'] = Point;
        } else if (p.properties['circle-color'].charAt(0) != '#') {
          p.properties['circle-color'] = '#' + p.properties['circle-color'];
        }
        if (p.properties['circle-opacity'] == undefined) {
          p.properties['circle-opacity'] = pointOpacity;
        }
        if (p.properties['circle-stroke-color'] == undefined) {
          p.properties['circle-stroke-color'] = circleOutline;
        } else if (p.properties['circle-stroke-color'].charAt(0) != '#') {
          p.properties['circle-stroke-color'] = '#' + p.properties['circle-stroke-color'];
        }
        if (p.properties['circle-stroke-width'] == undefined) {
          p.properties['circle-stroke-width'] = circleStroke;
        }
        if (p.properties['circle-stroke-opacity'] == undefined) {
          p.properties['circle-stroke-opacity'] = pointOpacity;
        }
        break;
    }
    if (p.properties.Name == undefined && p.properties.name != undefined) {
      p.properties.Name = p.properties.name;
    }
    if (overrideStyle == 1) {
      switch (p.geometry.type) {
        case 'Point':
          p.properties['circle-radius'] = pointRadius;
          p.properties['circle-color'] = Point;
          p.properties['circle-opacity'] = pointOpacity;
          p.properties['circle-stroke-color'] = circleOutline;
          p.properties['circle-stroke-width'] = circleStroke;
          p.properties['circle-stroke-opacity'] = pointOpacity;
          break;
        case 'LineString':
        case 'MultiLineString':
          p.properties['line-color'] = Polyline;
          p.properties['line-opacity'] = lineOpacity;
          p.properties['line-width'] = lineStroke;
        case 'Polygon':
          p.properties['fill-color'] = Polygon;
          p.properties['fill-opacity'] = polygonOpacity;
          p.properties['fill-outline-color'] = polygonOutline;
          break;
        case 'GeometryCollection':
          p.properties['line-color'] = Polyline;
          p.properties['line-width'] = lineStroke;
          p.properties['fill-color'] = Polygon;
          p.properties['stroke-opacity'] = lineOpacity;
          p.properties['fill-opacity'] = lineOpacity;
          break;
      }
    }
    console.log(p);
    data.features.push(p);

    ////console.log(p.properties.stroke);
    if (p.geometry.type == 'Point') {
      p.properties.CirroLayer = layer;
      Pointarray.push(p);
    }
  }
  WPTshared[id] = Pointarray;
  //console.log(data);
  mapb.addSource(id, {
    type: 'geojson',
    data: data,
  });
  var labelsVisible = 'none';
  if (labelsON) {
    labelsVisible = 'visible';
  }

  var wptsVisible = 'none';
  if (wptsON) {
    wptsVisible = 'visible';
  }

  mapb.addLayer(
    {
      id: id + '-point',
      type: 'circle',
      source: id,
      layout: {
        visibility: wptsVisible,
      },
      filter: ['all', ['==', '$type', 'Point']],
      paint: {
        'circle-radius': {
          type: 'identity',
          property: 'circle-radius',
        },
        'circle-color': {
          type: 'identity',
          property: 'circle-color',
        },
        'circle-stroke-color': {
          type: 'identity',
          property: 'circle-stroke-color',
        },
        'circle-stroke-width': {
          type: 'identity',
          property: 'circle-stroke-width',
        },
        'circle-stroke-opacity': {
          type: 'identity',
          property: 'circle-stroke-opacity',
        },
        'circle-opacity': {
          type: 'identity',
          property: 'circle-opacity',
        },
      },
    },
    'AIRCRAFT'
  );

  mapb.addLayer({
    id: id + '-point-LABELS',
    type: 'symbol',
    source: id,
    layout: {
      visibility: labelsVisible,
      'symbol-placement': 'point',
      'text-field': '{Name}',
      'text-size': TEXT_SIZE,
      'text-justify': 'center',
      'text-anchor': 'top',
      'text-offset': [0, 1],
    },
    filter: ['all', ['==', '$type', 'Point']],
    paint: {
      'text-halo-color': TEXT_HALO_COLOR,
      'text-halo-width': TEXT_HALO_WIDTH,
      'text-halo-blur': TEXT_HALO_BLUR,
      'text-color': TEXT_COLOR,
    },
  });

  var linesVisible = 'none';
  if (linesON) {
    linesVisible = 'visible';
  }
  mapb.addLayer(
    {
      id: id + '-line',
      type: 'line',
      source: id,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
        visibility: linesVisible,
      },
      filter: ['all', ['==', '$type', 'LineString']],
      paint: {
        'line-color': {
          type: 'identity',
          property: 'line-color',
        },
        'line-width': {
          type: 'identity',
          property: 'line-width',
        },
        'line-opacity': {
          type: 'identity',
          property: 'line-opacity',
        },
      },
    },
    'AIRCRAFT'
  );

  mapb.addLayer({
    id: id + '-line-LABELS',
    type: 'symbol',
    source: id,
    layout: {
      visibility: labelsVisible,
      'symbol-placement': 'line',
      'text-field': '{Name}',
      'text-size': TEXT_SIZE,
      'text-justify': 'center',
      'text-anchor': 'top',
    },
    filter: ['all', ['==', '$type', 'LineString']],
    paint: {
      'text-halo-color': TEXT_HALO_COLOR,
      'text-halo-width': TEXT_HALO_WIDTH,
      'text-halo-blur': TEXT_HALO_BLUR,
      'text-color': TEXT_COLOR,
    },
  });

  var areasVisible = 'none';
  if (areasON) {
    areasVisible = 'visible';
  }
  mapb.addLayer(
    {
      id: id + '-polygon',
      type: 'fill',
      source: id,
      layout: {
        visibility: areasVisible,
      },
      filter: ['all', ['==', '$type', 'Polygon']],
      paint: {
        'fill-outline-color': {
          type: 'identity',
          property: 'fill-outline-color',
        },
        'fill-opacity': {
          type: 'identity',
          property: 'fill-opacity',
        },
        'fill-color': {
          type: 'identity',
          property: 'fill-color',
        },
      },
    },
    'AIRCRAFT'
  );

  mapb.addLayer({
    id: id + '-polygon-LABELS',
    type: 'symbol',
    source: id,
    layout: {
      visibility: labelsVisible,
      'symbol-placement': 'point',
      'text-field': '{Name}',
      'text-size': TEXT_SIZE,
      'text-justify': 'center',
      'text-anchor': 'top',
    },
    filter: ['all', ['==', '$type', 'Polygon']],
    paint: {
      'text-halo-color': TEXT_HALO_COLOR,
      'text-halo-width': TEXT_HALO_WIDTH,
      'text-halo-blur': TEXT_HALO_BLUR,
      'text-color': TEXT_COLOR,
    },
  });
  mapb.on('click', sharedWaypointInfo);
}

function sharedWaypointInfo(e) {
  if (MAPSTATE.Measuring || MAPSTATE.ChartSelect) {
    return;
  }
  var layers = [];
  var keys = Object.keys(WP_ARRAY);
  for (var i in keys) {
    layers.push(keys[i] + '-point');
    layers.push(keys[i] + '-line');
    layers.push(keys[i] + '-polygon');
  }
  if (layers.length == 0) {
    return;
  }
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var features = mapb.queryRenderedFeatures(bbox, { layers: layers });

  if (!features.length) {
    return;
  }
  //console.log(features[0]);
  var data = features[0];
  if (data.properties.name == undefined && data.properties.Name != undefined) {
    data.properties.name = data.properties.Name;
  }
  $('#acDetails').html('');
  $('#acDetails').append('<h4>Waypoint Info</h4>');
  $('#acDetails').append('<tr><td>Name</td><td>' + data.properties.name + '</td></tr>');
  //check if it is a multi geometry item if so don't use geometry coordinates.  use click coordinates
  var Lat = data.geometry.coordinates[1];
  var Long = data.geometry.coordinates[0];
  if (Array.isArray(data.geometry.coordinates[0])) {
    Lat = e.lngLat.lat;
    Long = e.lngLat.lng;
  }
  if (LOCALSTORAGE.CoordFormat == 'UTM') {
    var utmOK = getUTM(Lat, Long);

    var html =
      '<tr><td>UTM ZONE</td><td>' +
      utmzone +
      '</td></tr><tr><td>Easting</td><td>' +
      utmlat +
      '</td></tr><tr><td>Northing</td><td>' +
      utmlong +
      '</td></tr>';
    $('#acDetails tr:last').after(html);
  } else {
    $('#acDetails tr:last').after('<tr><td>Lat:</td><td>' + ConvertDD_User(Lat, 'Lat') + '</td></tr>');
    $('#acDetails tr:last').after('<tr><td>Long:</td><td>' + ConvertDD_User(Long, 'Long') + '</td></tr>');
  }
  $('#acDetails tr:last').after('</table><br />');
  if (GPS.Active != false) {
    $('#acDetails').append(
      '<img src="./images/DirectTo.png" width="35" height="35" onClick="navigateToMarker(\'' +
        data.properties.name +
        "'," +
        Lat +
        ',' +
        Long +
        ", 'Device')\"/>"
    );
  }
  if (MAPSTATE.Measuring != false) {
    $('#acDetails').append(
      '<img src="./images/routeplan.png" width="35" height="35" onClick="routeToMarker(\'' +
        data.properties.name +
        "'," +
        Lat +
        ',' +
        Long +
        ')"/>'
    );
  }
  CurMarkerProperties = { NavType: 'waypoint' };
  $('#acInfo').popup('open');
}

function ShowDescriptionHTML(e) {
  //console.log(e);
}

function exportWPTS() {
  //console.log("export waypoints");
  //console.log(WP_ARRAY);	//holds loaded features for export
}

/*	****************************************	Fuel Cache DB stuff      *********************************** */

var FCdata = [];

var isCanceled = false;

function openFCdb() {
  getFCdata();
}

function getFCdata(silent = false) {
  FCdata = [];
  cirroDB.query('FuelCachesV2', 'PrimaryKey != ? ORDER BY location', 0, function (e) {
    if (e === false) {
      FCdata = [];
    } else {
      for (var i in e) {
        var I = e[i];
        I.PrimaryKey = parseInt(I.PrimaryKey); // for iOS fix
        FCdata.push(I);
      }
    }
    if (!silent) {
      if ($('#fcON').is(':checked')) {
        mapFC();
      }
      $.mobile.loading('hide');
    }
    //console.log("fuelData Loaded");
  });
}

function setFCdata(Item, refresh = false) {
  console.log('updating FC', Item);

  cirroDB.insert('FuelCachesHistoryV2', Item, null, function (e) {
    if (e === false) {
      console.log('FuelCachesHistoryV2 Error: ' + e.value);
    }
    cirroDB.insert('FuelCachesV2', Item, null, function (e) {
      if (e === false) {
        console.log('FuelCaches Error: ' + e.value);
      }
      FC_FORCE_RESYNC = true;
      update_Sync('LastFCsync', new Date()); //when update success
      //console.log("Background FC Sync Started");
      BackgroundSync(false, true);

      getFCdata();
    });
  });
}

/*	****************************************	AC Position DB stuff      *********************************** */

function DownloadTime() {
  var now = new Date().getTime();
  var elapsed = now - offlineTime;
  elapsed = elapsed * 0.001;
  var remaining = nbTiles - nbCount;
  var secondsToComplete = (elapsed / nbCount) * remaining;

  //var secondsToComplete = nbTiles / numCopied;
  //secondsToComplete = secondsToComplete * 6;
  var hours = parseInt(secondsToComplete / 3600) % 24;
  var minutes = parseInt(secondsToComplete / 60) % 60;
  var seconds = secondsToComplete % 60;
  seconds = Math.round(seconds);
  var result =
    (hours < 10 ? '0' + hours : hours) +
    'H-' +
    (minutes < 10 ? '0' + minutes : minutes) +
    'M-' +
    (seconds < 10 ? '0' + seconds : seconds) +
    'S';
  if (mobilize) {
    $('#OfflineMobilizeDELETE').html(
      '[' + nbCount + '/' + nbTiles + '] Time Remaining: ' + result + ' [Cancel Download]'
    );
  } else {
    $('#OfflineDELETE').html('[' + nbCount + '/' + nbTiles + '] Time Remaining: ' + result + ' [Cancel Download]');
  }
  downloadTimer = setTimeout('DownloadTime()', 1000);
}

function LoadMobolizeFile(ICAO, local_code, Country, type) {
  let table;

  if (Country === 'US') {
    table = UsAmericanFacilityInfoTable.query();
    name = local_code + '.pdf';
  } else if (Country === 'CA') {
    table = CanadianFacilityInfoTable.query();
    name = ICAO + '.pdf';
  } else {
    verbose.error('MK', `Invalid Country "${Country}" for Facility Info Request`);
    return;
  }

  table
    .raw({
      q: 'select base64(pdf) as PDF from pdf where name = ?',
      v: [name],
    })
    .then((res) => {
      if (!Array.isArray(res) || res.length <= 0) {
        return;
      }

      let PDF = res[0].PDF;
      LoadMobolizeFile2(name, PDF, type);
    })
    .catch((e) => {
      verbose.error('MK', `Database Error: ${e.message}`);
    });
}

async function LoadMobolizeFile2(name, data, type) {
  try {
    const blobUtils = window.vueApp.utilities.blobUtils;
    const fileUtils = window.vueApp.utilities.file;

    $.mobile.loading('show', { theme: 'a' });

    const blob = blobUtils.b64toBlob(data, type);
    await fileUtils.writeFile(fileUtils.dirTmp + name, blob);
    await fileUtils.openFileExternal(fileUtils.dirTmp + name, type, name);
  } catch (e) {
    verbose.error(e);
    toaster.show(`Sorry, Cirro couldn't work with the file "${name}".`);
  }

  $.mobile.loading('hide', { theme: 'a' });
}

function search_CA_IFR_INDEX(ICAO, display, div = 'IFR_Data') {
  const targetDiv = $('#' + div);
  CanadianIfrInfoTable.query()
    .raw({
      q: 'select "key", "icao", "description", "map" from pdf where "icao" = ?',
      v: [ICAO],
    })
    .then((result) => {
      if (result.length <= 0) {
        targetDiv.html('');
        return;
      }

      let html = '';
      let I; // This reproduces the behaviour of the previous code; the I.key param is used after the loop concludes.
      for (const r of result) {
        I = r;
        if (I.map == 0) {
          html += `
          <button data-mini='true' class='yellowbg wrap' onClick='load_CA_IFR_File("${I.icao}","${I.key}", "${div}")'>
            ${I.description}
          </button>`;
        } else {
          html += `
          <button data-mini='true' class='greenbg wrap' onClick='load_CA_IFR_File("${I.icao}","${I.key}", "${div}")'>
            ${I.description}
          </button>`;
        }
      }

      html += `
      <button data-mini='true' class='yellowbg wrap' onClick='load_CA_IFR_File("General--General.pdf","${I.key}", "${div}")'>
        General Pages
      </button>`;

      html += `
      <button data-mini='true' class='yellowbg wrap' onClick='load_CA_IFR_File("Changes--Changes.pdf","${I.key}", "${div}")'>
        Changes
      </button>`;

      html += '<br /><br />';

      targetDiv.html(html).enhanceWithin();
    })
    .catch((e) => {
      targetDiv.html(`<h2>Sorry, it looks like you're missing "Canadian Offline IFR Info".</h2>`);
    });
}

function load_CA_IFR_File(icao, key, div = 'IFR_Data') {
  CanadianIfrInfoTable.query()
    .raw({
      q: 'select BASE64(pdf) as PDF, bounds, map from pdf where "key" = ?',
      v: [key],
    })
    .then((result) => {
      if (result.length <= 0) {
        return;
      }

      const pdf = result[0].PDF;
      const bounds = result[0].bounds;
      const map = result[0].map;
      const file = icao + key + '.pdf';
      if (div === 'IFR_Data') {
        if (map == 0) {
          LoadMobolizeFile2(file, pdf, 'application/pdf');
        } else {
          getOverlaySVG_DB(pdf, bounds, 'CA');
        }
      }
      if (div === 'IFRInfoList') {
        if (map == 0) {
          LoadFacilityInfo('data:application/pdf;base64,' + pdf, 'IFRInfoPlate');
        } else {
          getOverlaySVG_DB(pdf, bounds, 'CA');
        }
      }
    })
    .catch((e) => {
      targetDiv.html(`<h2>Sorry, it looks like you're missing "Canadian Offline IFR Info".</h2>`);
    });
}

function search_US_IFR_INDEX(ICAO, local_code, display, div = 'IFR_Data') {
  const targetDiv = $('#' + div);
  UsAmericanIfrInfoTable.query()
    .raw({
      q: 'select map, description, volume, file from pdf where ident = ? or icao = ?',
      v: [local_code, ICAO],
    })
    .then((result) => {
      if (result.length == 0) {
        targetDiv.html('');
        return;
      }

      let html = '';
      for (const I in result) {
        if (I.map == 0) {
          html += `<button data-mini='true' class='yellowbg wrap' onClick='load_US_IFR_File("${I.volume}","${I.file}", "${I.map}")'>
              ${I.description}
              </button>`;
        } else {
          html += `<button data-mini='true' class='greenbg wrap' onClick='load_US_IFR_File("${I.volume}","${I.file}", "${I.map}")'>
              ${I.description}
              </button>`;
        }
      }

      html += '<br /><br />';

      targetDiv.html(html);
      targetDiv.enhanceWithin();
    })
    .catch((e) => {
      targetDiv.html('<h2>Offline Download Missing: USA Terminal Procedures Index</h2>');
    });
}

function load_US_IFR_File(volume, file, map) {
  UsAmericanTerminalProceduresDatabase.open(volume)
    .raw({
      q: 'select pdf, bounds from pdf where file = ?',
      v: [file],
    })
    .then((res) => {
      if (res.length <= 0) {
        $('#IFRInfoPlate').html('<h2>Offline Download Missing: USA Terminal Procedures Area: ' + volume + '</h2>');
        return;
      }

      const bounds = res[0].bounds;
      if (map == 0) {
        LoadFacilityInfo(res[0].pdf, 'IFRInfoPlate');
      } else {
        getOverlaySVG_DB(res[0].pdf, bounds, 'CA');
      }
    })
    .catch((e) => {
      $('#IFRInfoPlate').html(`<h2>Offline Download Missing: USA Terminal Procedures Area: ${volume}</h2>`);
      verbose.error('MK', `Database Error: ${e.message}`);
    });
}

$(document).ready(function () {
  $('#exportGPX').on('click', function (event) {
    //console.log(WP_ARRAY);	//holds loaded features for export
    var data;
    // prepare the string that is going to go into the href attribute
    // data:mimetype;encoding,string
    if (WP_Index.length == 0) {
      $('#popupPanel').popup('close');
      event.preventDefault();
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Error',
        headerClose: false,
        buttonPrompt: 'Nothing Selected!<br />Select Items from "Data Lists" to Export.',
        buttons: {
          OK: {
            click: function () {},
          },
        },
      });
      return;
    }
    var array = [];
    var Data = '';
    for (var i in WP_ARRAY) {
      //console.log(WP_ARRAY[i]);
      for (var b in WP_ARRAY[i].data.Data) {
        array.push(WP_ARRAY[i].data.Data[b]);
      }
    }
    //console.log(array);
    var json = { type: 'FeatureCollection', features: array };
    Data += togpx(json, {
      creator: 'AirSuite: Cirro',
      featureTitle: function (properties) {
        //console.log(properties);
        if (properties.name !== undefined) {
          return properties.name;
        } else if (properties.Name !== undefined) {
          return properties.Name;
        } else {
          return 'Unknown';
        }
      },
      featureDescription: function (properties) {
        if (properties.desc !== undefined) {
          return properties.desc;
        } else {
          return 'No Description';
        }
      },
    });
    if (device.platform.toUpperCase() === 'ANDROID') {
      //console.log("Opening base64 Android");
      event.preventDefault();
      //LoadMobolizeFile2('Cirro.gpx', btoa(Data), 'application/gpx');
      OpenWithMobile('Cirro.gpx', Data, 'application/gpx');
    } else if (IOS) {
      //console.log("Opening base64 IOS");
      event.preventDefault();
      //LoadMobolizeFile2('Cirro.gpx', btoa(Data), 'application/gpx');
      OpenWithMobile('Cirro.gpx', Data, 'application/gpx');
    } else {
      //console.log("Opening base64 Universal URL");
      var a = document.createElement('a');

      if (typeof a.download != 'undefined') {
        data = 'data:application/gpx;charset=utf-8,' + encodeURIComponent(Data);
        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_blank',
          download: 'Cirro.gpx',
        });
        // let the click go through
      } else {
        // download attribute is not supported
        data = 'data:application/gpx;base64,' + btoa(Data);

        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_system',
        });
      }
    }
  });
  $('#exportKML').on('click', function (event) {
    var data;
    // prepare the string that is going to go into the href attribute
    // data:mimetype;encoding,string
    if (WP_Index.length == 0) {
      $('#popupPanel').popup('close');
      event.preventDefault();
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Error',
        headerClose: false,
        buttonPrompt: 'Nothing Selected!<br />Select Items from "Data Lists" to Export.',
        buttons: {
          OK: {
            click: function () {},
          },
        },
      });
      return;
    }
    var array = [];
    var Data = '';
    //console.log(WP_ARRAY);
    for (var i in WP_ARRAY) {
      //console.log(WP_ARRAY[i]);
      for (var b in WP_ARRAY[i].data.Data) {
        array.push(WP_ARRAY[i].data.Data[b]);
      }
    }
    var json = { type: 'FeatureCollection', features: array };
    Data = tokml(json, {
      name: 'name',
      description: 'description',
      simplestyle: true,
    });
    if (device.platform.toUpperCase() === 'ANDROID') {
      //console.log("Opening base64 Android");
      event.preventDefault();
      //LoadMobolizeFile2('Cirro.kml', btoa(Data), 'application/vnd.google-earth.kml+xml');
      OpenWithMobile('Cirro.kml', Data, 'application/vnd.google-earth.kml+xml');
    } else if (IOS) {
      //console.log("Opening base64 IOS");
      event.preventDefault();
      //LoadMobolizeFile2('Cirro.kml', btoa(Data), 'application/vnd.google-earth.kml+xml');
      OpenWithMobile('Cirro.kml', Data, 'application/vnd.google-earth.kml+xml');
    } else {
      var a = document.createElement('a');

      if (typeof a.download != 'undefined') {
        //console.log("Opening raw data url");
        //data = 'data:application/kml;charset=utf-8,' + encodeURIComponent(Data);
        data = new Blob([Data], { type: 'application/vnd.google-earth.kml+xml' });
        //console.log(data);
        // set attributes href and target in the <a> element (with id  exportGPX)
        window.URL = window.URL || window.webkitURL;
        var clickEvent = document.createEvent('MouseEvent');
        clickEvent.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.textContent = 'Cirro.kml';
        a.href = '#';
        a.href = window.URL.createObjectURL(data);
        a.download = 'Cirro.kml';
        a.dispatchEvent(clickEvent);
        event.preventDefault();
        return false;
        // let the click go through
      } else {
        //console.log("Opening base64 Universal URL");
        // download attribute is not supported
        data = 'data:application/vnd.google-earth.kml+xml;base64,' + btoa(Data);

        // set attributes href and target in the <a> element (with id  exportKML)
        $(this).attr({
          href: data,
          target: '_system',
        });
      }
    }
  });
});

function getOverlaySVG(file, bounds, Country) {
  /*
   var url = BASEURL + "AirportCharts/US_IFR_SVG/" + file.replace(".PDF",".svg");
   bounds = bounds.replace(/\s\s+/g, ',');
   //console.log(bounds);
   bounds = JSON.parse(bounds);
   mapOverlaySVG(url,bounds);
   */

  //var test = "https://dev.air-suite.com/AirportCharts/US_IFR_SVG/00001IL35R.svg";
  //var url = "http://dev.air-suite.com/" + file.replace(".PDF",".svg");
  //Use proxy for insecure avdata url for now
  $('#gpsMenu').hide();
  var url = AVDATA_URL + '/US_IFR_SVG/' + file.replace('.PDF', '.svg');
  if (Country == 'CA') {
    url = AVDATA_URL + '/CAP_SVG/' + file.replace('.pdf', '.svg');
  }
  $.ajax({
    type: 'GET',
    url: url,
    data: null,
    dataType: 'text',
    success: function (data) {
      //process text from server
      getOverlaySVG_DB(btoa(data), bounds, Country);
    },
  });
}

function getOverlaySVG_DB(file, bounds, country) {
  if (ELECTRON && !ONLINE) {
    var blob = new Blob([file], {
      type: 'image/svg+xml',
    });
    var reader = new FileReader();
    reader.onload = function () {
      bounds = bounds.replace(/\s\s+/g, ',');
      bounds = bounds.replace(/\s+/g, ',');
      //console.log(bounds);
      bounds = JSON.parse(bounds);
      mapOverlaySVG(reader.result, bounds, country);
    };
    reader.readAsDataURL(blob);
    closeDirectoTo();
  } else {
    closeDirectoTo();
    var url = 'data:image/svg+xml;base64,' + file;
    bounds = bounds.replace(/\s\s+/g, ',');
    bounds = bounds.replace(/\s+/g, ',');
    //console.log(bounds);
    bounds = JSON.parse(bounds);
    mapOverlaySVG(url, bounds, country);
  }
}

function mapOverlaySVG(url, bounds, country) {
  var canvas = document.getElementById('mapOverlayCanvas');
  var svg = canvas.getContext('2d');
  var img = new Image();
  img.onload = function () {
    //console.log("drawing to canvas");
    if (country == 'CA') {
      svg.clearRect(0, 0, 1600, 2450);
      svg.drawImage(img, 75, 75, 1450, 2300);
      svg.globalCompositeOperation = 'destination-over';
      svg.fillStyle = 'rgba(255, 255, 255, 0.85)';
      svg.fillRect(0, 0, 1600, 2450);
    } else {
      svg.clearRect(0, 0, 1600, 2450);
      svg.drawImage(img, 0, 0, 1600, 2450);
      svg.globalCompositeOperation = 'destination-over';
      svg.fillStyle = 'rgba(255, 255, 255, 0.85)';
      svg.fillRect(0, 0, 1600, 2450);
    }
    if (mapb.getSource('canvas') == undefined) {
      mapb.addSource('canvas', {
        type: 'canvas',
        canvas: 'mapOverlayCanvas',
        animate: true,
        coordinates: bounds,
      });
      mapb.addLayer(
        {
          id: 'canvas',
          source: 'canvas',
          type: 'raster',
          paint: { 'raster-opacity': 1 },
        },
        'Airports-Small'
      );
    } else {
      mapb.getSource('canvas').setCoordinates(bounds);
    }
    mapb.setLayoutProperty('canvas', 'visibility', 'visible');
    var newbounds = new mapboxgl.LngLatBounds();
    for (var i in bounds) {
      newbounds.extend(bounds[i]);
    }

    flyToMapBounds(newbounds, 50);
  };
  img.src = url;
}

function mapOverlayPdfMap(svgDataURL, bounds, svgWidth, svgHeight) {
  var canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  for (var i = 0; i < pages.length; i++) {
    ctx.putImageData(pages[i], 0, heights[i]);
  }
  //var svgDataURL = canvas.toDataURL();

  var canvas = document.getElementById('mapOverlayCanvas');
  var svg = canvas.getContext('2d');
  var img = new Image();
  img.onload = function () {
    svg.drawImage(img, 0, 0, svgWidth, svgHeight);
    svg.globalCompositeOperation = 'destination-over';
    svg.fillStyle = 'rgba(255, 255, 0, 1)';
    if (mapb.getSource('canvas') == undefined) {
      mapb.addSource('canvas', {
        type: 'canvas',
        canvas: 'mapOverlayCanvas',
        animate: true,
        coordinates: bounds,
      });
      mapb.addLayer(
        {
          id: 'canvas',
          source: 'canvas',
          type: 'raster',
          paint: { 'raster-opacity': 0.75 },
        },
        'Airports-Small'
      );
    } else {
      mapb.getSource('canvas').setCoordinates(bounds);
    }
    mapb.setLayoutProperty('canvas', 'visibility', 'visible');
    var newbounds = new mapboxgl.LngLatBounds();
    for (var i in bounds) {
      newbounds.extend(bounds[i]);
    }
    flyToMapBounds(newbounds, 50);
  };
  img.src = svgDataURL;
}

function removeSVGoverlay() {
  if (mapb.getSource('canvas') != undefined) {
    mapb.removeLayer('canvas');
    mapb.removeSource('canvas');
  }
}

/**
 *
 * @param fileName
 * @param fileContent
 * @param mimeType
 * @return {Promise<void>}
 */
async function OpenWithMobile(fileName, fileContent, mimeType) {
  try {
    const filePath = vueApp.utilities.file.dirTmp + fileName;
    await vueApp.utilities.file.writeFile(filePath, fileContent);
    await vueApp.utilities.file.openFileExternal(filePath, mimeType, fileName);
  } catch (e) {
    verbose.error('fs', 'failed opening file', e);
  }
}
