//****************************** Manifest Listings
var PAX_MANIFESTING = {
  returnTo: '#PAX_Manifesting_Main',
  Manifests: [],
  Manifests_Filtered: [],
  Passengers: [],
  PassengerCfields: [],
  CurIndex: 0,
  CUR_MANIFEST: {
    PrimaryKey: 0,
    group: '',
    Name: '',
    Description: '',
    ClientFilter: -1,
    modified: null,
    DELETED: 0,
    PaxCount: 0,
    hasVIP: 0,
    Passengers: [],
  },
  AddingPax: false,
  AddingManifest: false,
  FromManifest: false,
  AddingManifestTo: '',
  briefingOnly: false,
  Cfields: [],
};
var BRIEFING_MANIFEST = {
  paxList: [],
};

var ITIN_Pax_Briefing = {};
function clearManifestFilter() {
  $('#FilterManifestTXT').val('');
  filterManifestArray();
}

var MANIFEST_MANUAL_SYNC = false;

function PAX_ManualSync() {
  $('#ManifestingSyncBTN').prop('disabled', true);
  PAX_syncPassengers(true);
  setTimeout(function () {
    PAX_syncManifests(true);
  }, 1000);
  setTimeout(function () {
    $('#ManifestingSyncBTN').prop('disabled', false);
  }, 3000);
}
function PAX_syncManifests(manualSync = false) {
  if (MANIFEST_MANUAL_SYNC) {
    return;
  }
  if (CurMANIFESTsync == '') {
    console.log('Manifest Synced Cancelloed. CurMANIFESTsync is blank');
    return;
  }
  MANIFEST_MANUAL_SYNC = manualSync;
  SYNCING == true;

  cirroDB.query('PaxMan', 'modified >= ?', [CurMANIFESTsync], PAX_SendUpdatedManData);
}

function PAX_SendUpdatedManData(data) {
  if (data === false) {
    data = [];
  }
  IOS_postMessage({
    Message: 'MANIFESTtext',
    text: iTrans('Sending') + ' ' + data.length + ' ' + iTrans('items to Server'),
  });

  ExchangePaxData(
    'sendOfflineManData',
    { CurMANIFESTsync: CurMANIFESTsync, LastMANIFESTsync: dateToStringObject(LastMANIFESTsync), Manifests: data },
    function (e) {
      console.log(e);
      if (e === false) {
        //Sync has failed for some reason
        MANIFEST_SYNCING = false;
        if (MANIFEST_MANUAL_SYNC) {
          MANIFEST_MANUAL_SYNC = false;
          SYNCING = false;
          VueBridge.LegacyAppSyncing(SYNCING);
          return;
        }
        IOS_postMessage({
          Message: 'MANIFESTtext',
          text: 'Failed: Server Error',
        });
        SYNC_STATUS('Manifests');
        return;
      }
      PAX_storeManifests(e);
    },
    false,
    false,
    false
  );
}
function PAX_storeManifests(result) {
  var UpdatedMan = result.UpdatedMan;
  if (UpdatedMan.length == 1) {
    if (
      PAX_MANIFESTING.CUR_MANIFEST.PrimaryKey < 1 &&
      UpdatedMan[0].OFFLINE_PK == PAX_MANIFESTING.CUR_MANIFEST.OFFLINE_PK
    ) {
      PAX_MANIFESTING.CUR_MANIFEST.PrimaryKey = UpdatedMan[0].PrimaryKey;
    }
  }
  var Manifests = [];
  for (var m in UpdatedMan) {
    var M = UpdatedMan[m];
    Manifests.push({
      PrimaryKey: M.PrimaryKey,
      OFFLINE_PK: M.OFFLINE_PK == null ? '' : M.OFFLINE_PK,
      Name: M.Name,
      Description: M.Description,
      modified: M.modified,
      PaxCount: M.PaxCount,
      hasVIP: M.hasVIP,
      ClientFilter: M.ClientFilter,
      DELETED: M.DELETED,
      Cfields: M.Cfields,
      Passengers: M.Passengers,
    });
  }
  result.Manifests = Manifests;
  if (Manifests.length > 0) {
    PAX_BulkUpdate_Manifests(result);
  } else {
    PAX_ManSyncComplete(result);
    PAX_LoadManifestListData();
  }
  cirroDB.clearAll('PassengerCfields', function (e) {
    for (var i in result.Cfields) {
      PAX_UpdatePassengerCfeild(result.Cfields[i]);
    }
  });
  PAX_MANIFESTING.Cfields = result.Cfields; //this is the same as Passenger Cfields
  $('#ManifestingSyncBTN').prop('disabled', false);
}

function PAX_BulkUpdate_Manifests(result) {
  PaxManTable.query()
    .insert(result.Manifests)
    .then(() => {
      console.log('Manifest Bulk Updated');
      PAX_ManSyncComplete(result);
    })
    .catch((err) => {
      console.error('Manifest Bulk Update Error', err);
      PAX_ManSyncComplete(result);
    });
}

function PAX_ManSyncComplete(result) {
  cirroDB.Delete('PaxMan', 'PrimaryKey <= ?', [0], null);
  update_Sync('LastMANIFESTsync', stringToDateObject(result.LastMANIFESTsync));
  //reset CurPASSENGERsync to sync records from here
  cirroDB.insert(
    'Sync',
    {
      SyncItem: 'CurMANIFESTsync',
      Date: '',
    },
    null,
    null
  );
  MANIFEST_SYNCING = false;
  PAX_LoadManifestListData();
  if (MANIFEST_MANUAL_SYNC) {
    MANIFEST_MANUAL_SYNC = false;
    SYNCING = false;
    VueBridge.LegacyAppSyncing(SYNCING);
    return;
  }
  IOS_postMessage({
    Message: 'MANIFESTtext',
    text: 'Complete',
  });

  SYNC_STATUS('Manifests');
}

function PAX_LoadManifestListData() {
  cirroDB.query('PaxMan', 'PrimaryKey != ?', [-9999], function (e) {
    PAX_MANIFESTING.Manifests = e;
    console.log('PAX_MANIFESTING.Manifests SET');
  });
}

function PAX_update_CurMANIFESTsync(date) {
  cirroDB.query('Sync', 'SyncItem = ?', 'CurMANIFESTsync', function (e) {
    if (e === false) {
      e = [{ SyncItem: 'CurMANIFESTsync', Date: '' }];
    }
    if (e[0].Date == '') {
      update_Sync('CurMANIFESTsync', stringToDateObject(date));
      CurMANIFESTsync = date;
    } else {
      CurMANIFESTsync = e[0].Date;
    }
  });
}

var PAX_MANUAL_SYNC = false;
function PAX_syncPassengers(manualSync = false) {
  if (PAX_MANUAL_SYNC) {
    return;
  }
  if (CurPASSENGERsync == '') {
    console.log('Pax Synced Cancelloed. CurPASSENGERsync is blank');
    return;
  }
  PAX_MANUAL_SYNC = manualSync;
  SYNCING == true;
  PASSENGER_SYNCING = true;
  console.log('getting Modified Passenger from: ' + CurPASSENGERsync);
  cirroDB.query('Passengers', 'modified >= ?', [CurPASSENGERsync], PAX_SendUpdatedPaxData);
}
function PAX_SendUpdatedPaxData(data) {
  if (data === false) {
    data = [];
  }
  IOS_postMessage({
    Message: 'PAXtext',
    text: iTrans('Sending') + ' ' + data.length + ' ' + iTrans('items to Server'),
  });

  ExchangePaxData(
    'sendOfflinePaxData',
    { CurPASSENGERsync: CurPASSENGERsync, LastPASSENGERsync: dateToStringObject(LastPASSENGERsync), Passengers: data },
    function (e) {
      console.log(e);
      if (e === false) {
        //Sync has failed for some reason
        PASSENGER_SYNCING = false;
        if (PAX_MANUAL_SYNC) {
          PAX_MANUAL_SYNC = false;
          SYNCING = false;
          VueBridge.LegacyAppSyncing(SYNCING);
          return;
        }
        IOS_postMessage({
          Message: 'PAXtext',
          text: 'Failed: Server Error',
        });
        SYNC_STATUS('Passengers');
        return;
      }
      PAX_storePassengers(e);
    },
    false,
    false,
    false
  );
}
function PAX_storePassengers(result) {
  var Passengers = result.UpdatedPax;
  for (var i in Passengers) {
    if (
      CUR_PASSENGER.OFFLINE_PK !== null &&
      CUR_PASSENGER.OFFLINE_PK != '' &&
      CUR_PASSENGER.OFFLINE_PK == Passengers[i].OFFLINE_PK
    ) {
      CUR_PASSENGER.PrimaryKey = Passengers[i].PrimaryKey;
      CUR_PASSENGER.Briefings = JSON.parse(JSON.stringify(Passengers[i].Briefings));
    }
    try {
      Passengers[i].Briefings = JSON.stringify(Passengers[i].Briefings);
    } catch (e) {
      Passengers[i].Briefings = '[]';
    }

    try {
      Passengers[i].Contacts = JSON.stringify(Passengers[i].Contacts);
    } catch (e) {
      Passengers[i].Contacts = '[]';
    }

    try {
      Passengers[i].Cfields = JSON.stringify(Passengers[i].Cfields);
    } catch (e) {
      Passengers[i].Cfields = '[]';
    }
    Passengers.filledForms = [];
  }
  if (Passengers.length > 0) {
    PAX_BulkUpdate(result);
  } else {
    PAX_SyncComplete(result);
  }

  cirroDB.clearAll('PassengerCfields', function (e) {
    for (var i in result.Cfields) {
      PAX_UpdatePassengerCfeild(result.Cfields[i]);
    }
  });
  PAX_MANIFESTING.PassengerCfields = JSON.parse(JSON.stringify(result.Cfields));
  $('#ManifestingSyncBTN').prop('disabled', false);
}

function PAX_BulkUpdate(result) {
  PassengersTable.query()
    .insert(result.UpdatedPax)
    .then(() => {
      console.log('Passengers Bulk Updated');
      PAX_SyncComplete(result);
    })
    .catch((err) => {
      console.error('Passengers Bulk Update Error', err);
      PAX_SyncComplete(result);
    });
}

function PAX_SyncComplete(result) {
  //remove negative primaryKey Passengers from DB
  cirroDB.Delete('Passengers', 'PrimaryKey <= ?', [0], null);
  update_Sync('LastPASSENGERsync', stringToDateObject(result.LastPASSENGERsync));
  //reset CurPASSENGERsync to sync records from here
  cirroDB.insert(
    'Sync',
    {
      SyncItem: 'CurPASSENGERsync',
      Date: '',
    },
    null,
    null
  );
  PASSENGER_SYNCING = false;
  PAX_LoadPassengerList();
  if (PAX_MANUAL_SYNC) {
    PAX_MANUAL_SYNC = false;
    SYNCING = false;
    VueBridge.LegacyAppSyncing(SYNCING);
    return;
  }
  IOS_postMessage({
    Message: 'PAXtext',
    text: 'Complete',
  });

  SYNC_STATUS('Passengers');
}

function PAX_update_CurPASSENGERsync(date) {
  cirroDB.query('Sync', 'SyncItem = ?', 'CurPASSENGERsync', function (e) {
    if (e === false) {
      e = [{ SyncItem: 'CurPASSENGERsync', Date: '' }];
    }
    if (e[0].Date == '') {
      update_Sync('CurPASSENGERsync', stringToDateObject(date));
      CurPASSENGERsync = date;
    } else {
      CurPASSENGERsync = e[0].Date;
    }
    console.log('CurPASSENGERsync', CurPASSENGERsync);
  });
}
function PAX_LoadPassengerList() {
  cirroDB.query('Passengers', 'PrimaryKey != ?', [-9999], function (e) {
    PAX_MANIFESTING.Passengers = e;
    console.log('PAX_MANIFESTING.Passengers SET');
  });
}
function PAX_UpdatePassenger(pax, callback = null) {
  var p = JSON.parse(JSON.stringify(pax));
  delete p['clientName'];
  delete p['selected'];
  cirroDB.insert('Passengers', p, null, callback);
}
function PAX_UpdatePassengerCfeild(Cfield, callback = null) {
  cirroDB.insert('PassengerCfields', Cfield, null, callback);
}

function PAX_SaveScrollPos() {
  ITIN_Pax_Manifest.scrollTo = document.documentElement.scrollTop || document.body.scrollTop;
}

function PAX_updateScrollPos() {
  document.documentElement.scrollTop = document.body.scrollTop = ITIN_Pax_Manifest.scrollTo;
}

function PaxManifestOptions() {
  $.mobile.changePage($('#PAX_Briefing_Admin'), {
    changeHash: false,
  });
  PAX_SaveScrollPos();
}
function PaxBriefingOptions() {
  $.mobile.changePage($('#PAX_Briefing_Forms_Admin'), {
    changeHash: false,
  });
  PAX_DisplayAircraft();
}

function PAX_ExitAdminFormEdits() {
  $.mobile.changePage($('#PAX_Briefing_Admin'), {
    changeHash: false,
  });
}

function PaxManifestInit() {
  PAX_MANIFESTING.AddingManifestTo = '';
  PAX_MANIFESTING.FromManifest = false;
  PAX_MANIFESTING.returnTo = '#PAX_Manifesting_Main';
  $.mobile.changePage($('#PAX_Manifesting_Main'), {
    changeHash: false,
  });
  PAX_LoadPassengerList();
}

function SCHpaxManifest(index, legIndex = 0) {
  $('#QUOTING_ManifestingHLDR').html('');
  $('#QuotingDefaultPaxManifestHLDR').html('');
  PAX_MANIFESTING.returnTo = '#BILLING_EditQuotingLegs'; //BILLING_EditQuotingLegs
  if (legIndex == -1) {
    PAX_MANIFESTING.returnTo = '#BILLING_AddQuotingLegs';
  }
  var AC = SCHEVENTDATA.Associated.BookedAC[index];
  SCHEVENTDATA.paxManEditIndex = index;
  cirroDB.query('AC', 'ident = ?', AC.ident, function (e) {
    var data = e[0];
    AC_data = data.AC_data;
    if (AC.Pax_Manifest == undefined) {
      PAX_MANIFESTING.AddingManifest = true;
      ITIN_Pax_Manifest = {
        DefaultList: [],
        Pax_Legs: [],
        CurLeg: legIndex,
        HasVIP: 0,
        Editing: true,
        scrollTo: 0,
        ClientPK: SCHEVENTDATA.Client_ID,
        AC_EVENT: AC,
      };
      AC.Pax_Manifest = ITIN_Pax_Manifest;
    } else {
      PAX_MANIFESTING.AddingManifest = false;
      ITIN_Pax_Manifest = AC.Pax_Manifest;
      ITIN_Pax_Manifest.CurLeg = legIndex;
      ITIN_Pax_Manifest.Editing = true;
      ITIN_Pax_Manifest.scrollTo = 0;
      ITIN_Pax_Manifest.ClientPK = SCHEVENTDATA.Client_ID;
      ITIN_Pax_Manifest.AC_EVENT = AC;
    }
    ITIN_Pax_Manifest.Quote = false;
    ITIN_Pax_Manifest.Scheduler = true;
    ITIN_Pax_Manifest.FlightReport = false;
    PAX_MANIFESTING.AddingManifestTo = 'Itinerary';

    PAX_Init_ITIN_Manifesting(legIndex);
  });
}

function BILLING_paxManifest(ident, leg = -1, updateScroll = true) {
  $('#BILLING_ManifestingHLDR').html('');

  if (ident == '' || ident == null || GROUP_DATA.Pax_Man == 0) {
    ITIN_Pax_Manifest = {
      DefaultList: [],
      Pax_Legs: [],
      CurLeg: leg,
      HasVIP: 0,
      Editing: true,
      scrollTo: 0,
      ClientPK: CURRENT_FLIGHT_REPORT.Job.ClientPK,
    };
    CURRENT_FLIGHT_REPORT.Pax_Manifest = ITIN_Pax_Manifest;
    return;
  }
  ResetITIN_PaxManifest();
  PAX_MANIFESTING.returnTo = '#BILLING_FlightDescriptionPage';
  if (leg != -1) {
    PAX_MANIFESTING.returnTo = '#BILLING_FlightLegsPage';
  }
  cirroDB.query('AC', 'ident = ?', ident, function (e) {
    var data = e[0];
    AC_data = data.AC_data;
    if (CURRENT_FLIGHT_REPORT.Pax_Manifest == undefined) {
      PAX_MANIFESTING.AddingManifest = true;
      ITIN_Pax_Manifest = {
        DefaultList: [],
        Pax_Legs: [],
        CurLeg: leg,
        HasVIP: 0,
        Editing: true,
        scrollTo: 0,
        ClientPK: CURRENT_FLIGHT_REPORT.Job.ClientPK,
      };
    } else {
      PAX_MANIFESTING.AddingManifest = false;
      ITIN_Pax_Manifest = JSON.parse(JSON.stringify(CURRENT_FLIGHT_REPORT.Pax_Manifest));
      ITIN_Pax_Manifest.CurLeg = leg;
      ITIN_Pax_Manifest.Editing = true;
      ITIN_Pax_Manifest.scrollTo = 0;
      ITIN_Pax_Manifest.ClientPK = CURRENT_FLIGHT_REPORT.Job.ClientPK;
    }
    CURRENT_FLIGHT_REPORT.Pax_Manifest = ITIN_Pax_Manifest;
    ITIN_Pax_Manifest.FlightReport = true;
    ITIN_Pax_Manifest.Scheduler = false;
    PAX_MANIFESTING.AddingManifestTo = 'Itinerary';

    PAX_Init_ITIN_Manifesting(leg, updateScroll);
  });
}

function QUOTING_paxManifest(ident, leg = -1, updateScroll = true, AircraftIndex = -1) {
  $('#QUOTING_ManifestingHLDR').html('');
  $('#QuotingDefaultPaxManifestHLDR').html('');
  if (ident == '' || ident == null || GROUP_DATA.Pax_Man == 0) {
    ITIN_Pax_Manifest = {
      DefaultList: [],
      Pax_Legs: [],
      CurLeg: leg,
      HasVIP: 0,
      Editing: true,
      scrollTo: 0,
      ClientPK: 0,
    };
    //Pax_Manifest.Pax_Manifest = ITIN_Pax_Manifest;
    return;
  }
  ResetITIN_PaxManifest();
  PAX_MANIFESTING.returnTo = '#BILLING_EditQuotingLegs';
  if (leg == -1) {
    PAX_MANIFESTING.returnTo = '#BILLING_AddQuotingLegs';
  }
  cirroDB.query('AC', 'ident = ?', ident, function (e) {
    var data = e[0];
    AC_data = data.AC_data;
    if (AircraftIndex == -1) {
      if (CURRENT_QUOTE.Pax_Manifest == undefined) {
        PAX_MANIFESTING.AddingManifest = true;
        ITIN_Pax_Manifest = {
          DefaultList: [],
          Pax_Legs: [],
          CurLeg: leg,
          HasVIP: 0,
          Editing: true,
          scrollTo: 0,
          ClientPK: CURRENT_QUOTE.ClientPK,
        };
      } else {
        PAX_MANIFESTING.AddingManifest = false;
        ITIN_Pax_Manifest = JSON.parse(JSON.stringify(CURRENT_QUOTE.Pax_Manifest));
        ITIN_Pax_Manifest.CurLeg = leg;
        ITIN_Pax_Manifest.Editing = true;
        ITIN_Pax_Manifest.scrollTo = 0;
        ITIN_Pax_Manifest.ClientPK = CURRENT_QUOTE.ClientPK;
      }
      CURRENT_QUOTE.Pax_Manifest = ITIN_Pax_Manifest;
    } else {
      if (CURRENT_QUOTE.Aircraft[AircraftIndex].Pax_Manifest == undefined) {
        PAX_MANIFESTING.AddingManifest = true;
        ITIN_Pax_Manifest = {
          DefaultList: [],
          Pax_Legs: [],
          CurLeg: leg,
          HasVIP: 0,
          Editing: true,
          scrollTo: 0,
          ClientPK: CURRENT_QUOTE.ClientPK,
        };
      } else {
        PAX_MANIFESTING.AddingManifest = false;
        ITIN_Pax_Manifest = JSON.parse(JSON.stringify(CURRENT_QUOTE.Aircraft[AircraftIndex].Pax_Manifest));
        ITIN_Pax_Manifest.CurLeg = leg;
        ITIN_Pax_Manifest.Editing = true;
        ITIN_Pax_Manifest.scrollTo = 0;
        ITIN_Pax_Manifest.ClientPK = CURRENT_QUOTE.ClientPK;
      }
      CURRENT_QUOTE.Aircraft[AircraftIndex].Pax_Manifest = ITIN_Pax_Manifest;
    }

    ITIN_Pax_Manifest.Quote = true;
    ITIN_Pax_Manifest.FlightReport = false;
    ITIN_Pax_Manifest.Scheduler = false;
    PAX_MANIFESTING.AddingManifestTo = 'Itinerary';

    PAX_Init_ITIN_Manifesting(leg, updateScroll);
  });
}

function BILLING_setPaxManIdent() {
  BILLING_paxManifest($('#BILLING_FPac').val(), -1);
}

function PaxManifests() {
  PAX_MANIFESTING.UnlockSignature = true;
  $.mobile.changePage($('#PAX_Manifests'), {
    changeHash: false,
  });

  PAX_MANIFESTING.AddingManifest = false;
  PAX_MANIFESTING.FromManifest = true;
  if (
    PAX_MANIFESTING.returnTo == '#ITIN-INPUT' ||
    PAX_MANIFESTING.returnTo == '#BILLING_FlightDescriptionPage' ||
    PAX_MANIFESTING.returnTo == '#BILLING_FlightLegsPage'
  ) {
    PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    PAX_MANIFESTING.AddingManifest = true;
  }
  $('#PAX_Manifests_List').html('<h4>Loading List...</h4>');
  if (
    PAX_MANIFESTING.returnTo == '#ITIN-INPUT' ||
    PAX_MANIFESTING.returnTo == '#BILLING_EditQuotingLegs' ||
    PAX_MANIFESTING.returnTo == '#BILLING_AddQuotingLegs'
  ) {
    PAX_ITIN_SelectManifest(ITIN_Pax_Manifest.CurLeg);
  } else {
    PAX_LoadManifestList();
  }
}

function PAX_Manifestreturn() {
  PAX_MANIFESTING.UnlockSignature = false;
  if (
    PAX_MANIFESTING.AddingManifestTo == 'Itinerary' ||
    PAX_MANIFESTING.AddingManifestTo == 'Leg' ||
    ITIN_Pax_Manifest.Quote ||
    ITIN_Pax_Manifest.Scheduler
  ) {
    if (ITIN_Pax_Manifest.Editing) {
      if (ITIN_Pax_Manifest.FlightReport) {
        if (ITIN_Pax_Manifest.CurLeg == -1) {
          $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
            changeHash: false,
          });
        } else {
          $.mobile.changePage($('#BILLING_FlightLegsPage'), {
            changeHash: false,
          });
        }
        PAX_updateScrollPos();
      } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
        PAX_MANIFESTING.AddingManifestTo == 'Leg';
        if (ITIN_Pax_Manifest.CurLeg == -1) {
          $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
            changeHash: false,
          });
        } else {
          $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
            changeHash: false,
          });
        }
      } else {
        $.mobile.changePage($('#Edit_Itin_Page'), {
          changeHash: false,
        });
      }
    } else {
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        $.mobile.changePage($('#ITIN-INPUT'), {
          changeHash: false,
        });
      } else {
        $.mobile.changePage($('#WB_Page2'), {
          changeHash: false,
        });
      }
      PAX_updateScrollPos();
    }
  } else {
    $.mobile.changePage($('#PAX_Manifesting_Main'), {
      changeHash: false,
    });
  }
}

function PAX_Manreturn() {
  PAX_MANIFESTING.briefingOnly = false;
  BRIEFING_MANIFEST.briefing = false;

  if (PAX_MANIFESTING.FromManifest == true) {
    $.mobile.changePage($('#PAX_EditManifest'), {
      changeHash: false,
    });
    showPaxManWeightOrCodeColumn();
    return;
  }
  PAX_MANIFESTING.UnlockSignature = false;
  if (PAX_MANIFESTING.AddingManifestTo == 'Itinerary' || PAX_MANIFESTING.AddingManifestTo == 'Leg') {
    if (ITIN_Pax_Manifest.Editing) {
      if (ITIN_Pax_Manifest.FlightReport) {
        if (ITIN_Pax_Manifest.CurLeg == -1) {
          $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
            changeHash: false,
          });
        } else {
          $.mobile.changePage($('#BILLING_FlightLegsPage'), {
            changeHash: false,
          });
        }
        showPaxManWeightOrCodeColumn();
        return;
      } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
        if (ITIN_Pax_Manifest.CurLeg == -1) {
          $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
            changeHash: false,
          });
        } else {
          $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
            changeHash: false,
          });
        }
      } else {
        $.mobile.changePage($('#Edit_Itin_Page'), {
          changeHash: false,
        });
      }
    } else {
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        $.mobile.changePage($('#ITIN-INPUT'), {
          changeHash: false,
        });
      } else {
        $.mobile.changePage($('#WB_Page2'), {
          changeHash: false,
        });
      }
      PAX_updateScrollPos();
    }
    showPaxManWeightOrCodeColumn();
  } else {
    $.mobile.changePage($('#PAX_Manifesting_Main'), {
      changeHash: false,
    });
  }
}

function PaxManifesting(AddingPax = true) {
  PAX_MANIFESTING.AddingPax = AddingPax;
  PAX_MANIFESTING.FromManifest = false;
  if (BRIEFING_MANIFEST.briefing) {
    $.mobile.changePage($('#PAX_Bulk_BriefingPaxSelect'), {
      changeHash: false,
    });
    cirroDB.query('Passengers', 'PrimaryKey != ? ORDER BY lastname ASC', ['-99999'], function (e) {
      PAX_MANIFESTING.Passengers = e;
      PAX_LoadList(e, 1);
    });
  } else {
    $.mobile.changePage($('#PAX_Manifesting'), {
      changeHash: false,
    });
    $('#PAX_ManifestList').html('<h4>Loading List...</h4>');
    var clientPK = null;
    if (AddingPax) {
      clientPK = PAX_MANIFESTING.CUR_MANIFEST.ClientFilter;
    }
    paxManTableViewOptionsInit();
    PAX_Populate_ClientList(clientPK);
    cirroDB.query('Passengers', 'PrimaryKey != ? ORDER BY lastname ASC', ['-99999'], PAX_LoadList);
    PAX_PopulateLookups();
  }
}
var PAX_STAFF_LOOKUP = {};
function PAX_PopulateLookups() {
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e == false) {
      e = [];
    }
    PAX_CLIENT_LOOKUP = {};
    for (var a in e) {
      if (PAX_CLIENT_LOOKUP[e[a].ClientPK] != undefined) {
        continue;
      }
      PAX_CLIENT_LOOKUP[e[a].ClientPK] = e[a];
    }
  });
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    PAX_STAFF_LOOKUP[I.PrimaryKey] = I;
  }
}

function PAX_Manifest_AddPassenger() {
  PAX_MANIFESTING.AddingPax = true;
  PAX_MANIFESTING.AddingManifestTo = 'manifest';
  PAX_SaveManifest();
  $.mobile.changePage($('#PAX_Manifesting'), {
    changeHash: false,
  });
  paxManTableViewOptionsInit();
  PAX_Populate_ClientList(PAX_MANIFESTING.CUR_MANIFEST.ClientFilter);
  cirroDB.query('Passengers', 'PrimaryKey != ? ORDER BY lastname ASC', ['-99999'], PAX_LoadList);
}

var filterManifestArray_Timeout = null;

function filterManifestArray() {
  if (filterManifestArray_Timeout != null) {
    clearTimeout(filterManifestArray_Timeout);
  }
  filterManifestArray_Timeout = setTimeout(function () {
    filterManifestArray_Timeout = null;
    var keywords = $('#FilterManifestTXT').val().toLowerCase();
    if (keywords != '') {
      PAX_MANIFESTING.Manifests_Filtered = filterManifestTypeArray(PAX_MANIFESTING.Manifests, keywords);
    } else {
      PAX_MANIFESTING.Manifests_Filtered = PAX_MANIFESTING.Manifests;
    }

    const dateFormat = `${VueBridge.dateTimeUtils.formattableDateFormats[0]} ${VueBridge.dateTimeUtils.timeFormat}`;

    PAX_MANIFESTING.Manifests_Filtered = PAX_MANIFESTING.Manifests_Filtered.map(function (manifest) {
      manifest.modified = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(manifest.modified)?.format(dateFormat);

      return manifest;
    });
    displayManifestFiltered();
  }, 500);
}

function filterManifestTypeArray(OrigArray, keywords) {
  var Filtered = OrigArray.filter(function (item) {
    if (
      item.Name.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
      item.Name.toLowerCase().indexOf(keywords.toLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  });
  return Filtered;
}

function PAX_LoadManifestList(data) {
  PAX_LoadManifestListData();
  cirroDB.query('PassengerCfields', 'PrimaryKey != ?', [-999], function (Cfields) {
    PAX_MANIFESTING.Cfields = Cfields;
    filterManifestArray();
  });
}

function displayManifestFiltered() {
  var html = '';
  if (PAX_MANIFESTING.Manifests_Filtered.length == 0) {
    html = '<h3>No Manifests</h3>';
  } else {
    html =
      '<table class="limit-table first-td-fill" id="PAX_MANIFEST_TABLE"><thead><tr><th>Name & Description</th><th>Paxs</th><th>Modified</th><th>Action</th></tr></thead><tbody>';
    for (var i in PAX_MANIFESTING.Manifests_Filtered) {
      var I = PAX_MANIFESTING.Manifests_Filtered[i];
      if (I.DELETED == 1) continue;
      var actions =
        '<div class="vertical-center-container"><button onclick="PAX_EditManifest(' +
        i +
        ');" data-mini="true" class="action-btn"><img src="./images/edit.svg"></button><button onclick="PAX_DeleteManifest(' +
        i +
        ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></div>';
      if (PAX_MANIFESTING.AddingManifest) {
        actions =
          '<div class="vertical-center-container"><button data-testid="pax-manifesting-select-manifest-new-itin-' +
          I.Name +
          '" onclick="PAX_AddingManifest(' +
          i +
          ');" data-mini="true" class="action-btn"><img src="./images/Check_mark_green.svg"></button><button onclick="PAX_EditManifest(' +
          i +
          ');" data-mini="true" class="action-btn"><img src="./images/edit.svg"></button><button onclick="PAX_DeleteManifest(' +
          i +
          ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></div>';
      }
      if (PAX_MANIFESTING.AddingManifestTo == 'Itinerary' || PAX_MANIFESTING.AddingManifestTo == 'Leg') {
        var ItinClientPK = ITIN_Pax_Manifest.ClientPK;
        if (ItinClientPK != '0' && I.ClientFilter != ItinClientPK && I.ClientFilter != -1) {
          continue;
        }
      }

      if (I.hasVIP == 1) {
        html +=
          '<tr data-testid="pax-manifesting-manifests-list-name-' +
          I.Name +
          '" class="vip-following"><td><div style="font-size:larger"><b>' +
          I.Name +
          '</b></div>' +
          I.Description +
          '</td><td>' +
          I.PaxCount +
          '</td><td>' +
          I.modified +
          '</td><td>' +
          actions +
          '</td></tr>';
      } else {
        html +=
          '<tr data-testid="pax-manifesting-manifests-list-name-' +
          I.Name +
          '" ><td><div style="font-size:larger"><b>' +
          I.Name +
          '</b></div>' +
          I.Description +
          '</td><td>' +
          I.PaxCount +
          '</td><td>' +
          I.modified +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
    }
    html += '</tbody></table>';
  }

  $('#PAX_Manifests_List').html(html);
  $('#PAX_Manifests_List').enhanceWithin();
  AddTableSorter('PAX_MANIFEST_TABLE', [[0, 0]]);
}

function PAX_AddingManifest(index) {
  PAX_MANIFESTING.AddingManifest = false;
  if (PAX_MANIFESTING.AddingManifestTo == 'Itinerary') {
    ITIN_Pax_Manifest.DefaultList = JSON.parse(JSON.stringify(PAX_MANIFESTING.Manifests_Filtered[index].Passengers));
    ITIN_Pax_Manifest.DefaultList = PAX_LookupPassengerList(ITIN_Pax_Manifest.DefaultList);
    $.mobile.changePage($(PAX_MANIFESTING.returnTo), {
      changeHash: false,
    });
    PAX_Init_ITIN_Manifesting(ITIN_Pax_Manifest.CurLeg);
  }
  if ((PAX_MANIFESTING.AddingManifestTo = 'Leg')) {
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] = JSON.parse(
      JSON.stringify(PAX_MANIFESTING.Manifests_Filtered[index].Passengers)
    );
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] = PAX_LookupPassengerList(
      ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg]
    );
    $.mobile.changePage($(PAX_MANIFESTING.returnTo), {
      changeHash: false,
    });
    PAX_Init_ITIN_Manifesting(ITIN_Pax_Manifest.CurLeg);
  }
  if (ITIN_Pax_Manifest.Editing) {
    if (GROUP_DATA.Pax_Man == 1 && ITIN_Pax_Manifest.Scheduler) {
      ExchangeQuoteData(
        'UpdateSCH_Manifest',
        { DefaultList: ITIN_Pax_Manifest.DefaultList, AC_EventPK: ITIN_Pax_Manifest.AC_EVENT.ItemPrimaryKey },
        function (data) {
          console.log(data);
        },
        true
      );
    }
  }
}

function PAX_DeleteManifest(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete this Manifest?',
    buttons: {
      Delete: {
        click: function () {
          var M = PAX_MANIFESTING.Manifests_Filtered[index];
          M.modified = dateToStringObject(new Date());
          M.DELETED = 1;
          var Manifest = {
            PrimaryKey: M.PrimaryKey,
            OFFLINE_PK: M.OFFLINE_PK == null ? '' : M.OFFLINE_PK,
            Name: M.Name,
            Description: M.Description,
            modified: M.modified,
            PaxCount: M.PaxCount,
            hasVIP: M.hasVIP,
            ClientFilter: M.ClientFilter,
            DELETED: M.DELETED,
            Cfields: M.Cfields,
            Passengers: M.Passengers,
          };
          PAX_update_CurMANIFESTsync(Manifest.modified);
          PAX_updateManifestOffline(M, true);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function PAX_SaveManifest(silent = true) {
  var M = PAX_MANIFESTING.CUR_MANIFEST;
  M.Name = $('#PAX_Manifest_Name').val();
  M.Description = $('#PAX_Manifest_Description').val();
  M.ClientFilter = $('#PAX_Manifest_ClientFilter').val();
  M.modified = dateToStringObject(new Date());
  var DisableNext = savePaxCfields(M, 'MAN');
  if (DisableNext) {
    return;
  }

  var Manifest = {
    PrimaryKey: M.PrimaryKey,
    OFFLINE_PK: M.OFFLINE_PK == null ? '' : M.OFFLINE_PK,
    Name: M.Name,
    Description: M.Description,
    modified: M.modified,
    PaxCount: M.PaxCount,
    hasVIP: M.hasVIP,
    ClientFilter: M.ClientFilter,
    DELETED: M.DELETED,
    Cfields: M.Cfields,
    Passengers:
      PAX_MANIFESTING.CurIndex == -1
        ? []
        : makeNewPaxLookup(PAX_MANIFESTING.Manifests_Filtered[PAX_MANIFESTING.CurIndex].Passengers),
  };
  PAX_update_CurMANIFESTsync(Manifest.modified);

  if (Manifest.PrimaryKey != null) {
    PAX_updateManifestOffline(Manifest, false, silent);
  } else {
    cirroDB.query('PaxMan', 'PrimaryKey <= ? ORDER BY PrimaryKey ASC LIMIT 1', [0], function (PaxMan) {
      var insert_id = 0;
      if (PaxMan !== false) {
        if (PaxMan.length != 0) {
          insert_id = parseInt(PaxMan[0].PrimaryKey) - 1; //Offline passengers get negative PK's
        }
      }
      Manifest.PrimaryKey = insert_id;
      M.PrimaryKey = insert_id;
      PAX_updateManifestOffline(Manifest, false, silent);
    });
  }
}

function PAX_updateManifestOffline(M, refresh = false, silent = true) {
  console.log(M);
  cirroDB.insert('PaxMan', M, null, function (e) {
    console.log('Manifests STORED');
    if (refresh) {
      PAX_LoadManifestList();
    } else {
      PAX_EnableAddPaxBTN();
    }
    if (!silent) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Success'),
        headerClose: false,
        buttonPrompt: iTrans('Manifest Saved Successfully'),
        buttons: {
          [iTrans('Stay On Page')]: {
            click: function () {
              //do nothing
            },
          },
          [iTrans('Go Back')]: {
            click: function () {
              PaxManifests();
            },
          },
        },
        callbackOpen: function () {
          // Use callbackOpen to set the data-testid attributes after the dialog is created
          $('a.ui-btn:contains("Go Back")').attr(
            'data-testid',
            'menu-pax-manifesting-saved-manifest-success-return-button'
          );
          $('a.ui-btn:contains("Stay on Page")').attr(
            'data-testid',
            'menu-pax-manifesting-saved-manifest-success-stay-button'
          );
        },
      });
    }
    if (ONLINE && !LOW_BANDWIDTH) {
      PAX_syncManifests(true);
    }
  });
}

function PAX_EnableAddPaxBTN(data) {
  if (PAX_MANIFESTING.CurIndex == -1) {
    if (PAX_MANIFESTING.Manifests == false) {
      PAX_MANIFESTING.Manifests = [];
    }
    PAX_MANIFESTING.CurIndex = PAX_MANIFESTING.Manifests.length;
    PAX_MANIFESTING.Manifests.push(PAX_MANIFESTING.CUR_MANIFEST);
    PAX_MANIFESTING.Manifests_Filtered = PAX_MANIFESTING.Manifests;
  }
}

function PAX_LookupPassengerList(paxLookup) {
  console.log('paxLookup', paxLookup);
  var Passengers = [];
  for (var i in paxLookup) {
    var I = paxLookup[i];
    var p = getPaxByPK(PAX_MANIFESTING.Passengers, I.PassengerPK, I.OFFLINE_PK);
    if (p !== false) {
      p.PassengerPK = I.PassengerPK;
      Passengers.push(p);
    }
  }
  return Passengers;
}

function getPaxByPK(OrigArray, PrimaryKey, OFFLINE_PK) {
  console.log('PK', PrimaryKey, 'OFFLINE_PK', OFFLINE_PK);
  var Filtered = OrigArray.filter(function (item) {
    if (
      (item.PrimaryKey == PrimaryKey && PrimaryKey > 0) ||
      (item.OFFLINE_PK == OFFLINE_PK && OFFLINE_PK != null && OFFLINE_PK != 0)
    ) {
      return true;
    } else {
      return false;
    }
  });
  if (Filtered.length > 0) {
    return Filtered[0];
  } else {
    return false;
  }
}

function makeNewPaxLookup(Passengers) {
  var paxLookup = [];
  for (var i in Passengers) {
    var I = Passengers[i];
    paxLookup.push({ PassengerPK: I.PrimaryKey, OFFLINE_PK: I.OFFLINE_PK });
  }
  return paxLookup;
}
function PAX_EditManifest(index, refresh = false, save = false) {
  PAX_MANIFESTING.CurIndex = index;
  $.mobile.changePage($('#PAX_EditManifest'), {
    changeHash: false,
  });
  if (index != -1) {
    PAX_MANIFESTING.CUR_MANIFEST = PAX_MANIFESTING.Manifests_Filtered[index];
  } else {
    PAX_MANIFESTING.CUR_MANIFEST = {
      PrimaryKey: null,
      OFFLINE_PK: new Date().getTime(),
      group: '',
      Name: '',
      Description: '',
      ClientFilter: -1,
      modified: dateToStringObject(new Date()),
      DELETED: 0,
      PaxCount: 0,
      hasVIP: 0,
      Passengers: [],
      Cfields: [],
    };
  }

  var C = PAX_MANIFESTING.CUR_MANIFEST;
  if (!refresh) {
    C.Passengers = PAX_LookupPassengerList(C.Passengers);
  }
  $('#PAX_Manifest_Name').val(C.Name);
  $('#PAX_Manifest_Description').val(C.Description);
  var chtml = '';

  var newCfields = populateCfields(PAX_MANIFESTING.Cfields, 'Man');

  if (C.Cfields == null || C.Cfields.length == 0) {
    //Populate with new Cfields
    C.Cfields = newCfields;
  } else {
    //check NewCfields list against existing Cfields and do what you need to
    for (var i in newCfields) {
      var I = newCfields[i];
      var found = false;
      for (var o in C.Cfields) {
        var O = C.Cfields[o];
        if (I.PrimaryKey == O.PrimaryKey) {
          found = true;
          break;
        }
      }
      if (!found) C.Cfields.push(I);
    }
  }

  if (C.Cfields == null || C.Cfields.length == 0) {
    //Populate with new Cfields
    C.Cfields = newCfields;
  }
  $('#PAX_Manifest_Cfields').html(''); //to prevent duplciate divid's
  $('#PAX_Passenger_Cfields').html('');

  var chtml =
    '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>' +
    iTrans('Additional Manifest Information') +
    '</h5><div>';

  var requiredCF = '';
  for (var i in C.Cfields) {
    var I = C.Cfields[i];
    if (I.reqMan == 1) {
      requiredCF += createPaxManCustomField(I, I.Value, 'Man', 'MAN');
    } else {
      chtml += createPaxManCustomField(I, I.Value, 'Man', 'MAN');
    }
  }

  if (C.Cfields.length == 0) {
    $('#PAX_Manifest_Cfields').html('');
  } else {
    $('#PAX_Manifest_Cfields').html(requiredCF + chtml + '</div></div>');
    $('#PAX_Manifest_Cfields').enhanceWithin();
  }

  $('#PAX_Manifest_ClientFilter').empty();
  $('#PAX_Manifest_ClientFilter').append('<option value="-1" selected>N/A</option>');
  $('#PAX_Manifest_modified').html('<h3>Last Modified: ' + C.modified + '</h3>');
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    var Jobs = {};
    if (e == false) {
      e = [];
    }
    for (var i in e) {
      if (Jobs[e[i].Business] == undefined) {
        Jobs[e[i].Business] = 1;
        $('#PAX_Manifest_ClientFilter').append(
          '<option data-testid="pax-manifesting-client-' +
            e[i].Business +
            '" value="' +
            e[i].ClientPK +
            '">' +
            e[i].Business +
            '</option>'
        );
      }
    }
    $('#PAX_Manifest_ClientFilter').val(C.ClientFilter).selectmenu('refresh');
    var html = '';
    if (C.Passengers.length == 0) {
      html += '<h3>No Passengers Added to Manifest</h3>';
    } else {
      html += '<h3>Passenger List</h3>';
      html +=
        '<table class="limit-table second-td-fill" id="PAX_MAN_LIST"><thead><tr><th>Lastname</th><th>Firstname</th><th>Modified</th><th>Action</th></tr></thead><tbody>';
      for (var i in C.Passengers) {
        var I = C.Passengers[i];
        var actions =
          '<div class="vertical-center-container"><button onclick="PAX_ManifestViewPaxContacts(' +
          i +
          ');" data-mini="true" class="action-btn"><img src="./images/inspect.svg"></button><button onclick="PAX_ManifestRemovePax(' +
          i +
          ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></div>';

        html +=
          '<tr data-testid="pax-manifesting-manifest-list-client-pax-' +
          I.lastname +
          ' ' +
          I.firstname +
          '" class="' +
          (I.VIP == 1 ? 'vip-following' : '') +
          '"><td>' +
          I.lastname +
          '</td><td>' +
          I.firstname +
          ' ' +
          I.middlename +
          '</td><td>' +
          I.modified +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</tbody></table>';
    }
    $('#ManifestPaxList').html(html);
    $('#ManifestPaxList').enhanceWithin();
    AddTableSorter('PAX_MAN_LIST', [[0, 0]]);
  });
}

function PAX_ManifestViewPaxContacts(index, returnDIV = '#PAX_EditManifest') {
  PAX_SaveScrollPos();
  var C = PAX_MANIFESTING.CUR_MANIFEST.Passengers[index];
  PAX_PaxDetails_return_ID = returnDIV;
  CUR_PASSENGER = C;
  PAX_DetailHTML(C);
}
function PAX_ManifestViewPaxContacts_Admin(index, leg, returnDIV = '#PAX_EditManifest') {
  if (leg == -1) {
    var C = ITIN_Pax_Manifest.DefaultList[index];
  } else {
    var C = ITIN_Pax_Manifest.Pax_Legs[leg][index];
  }
  PAX_PaxDetails_return_ID = returnDIV;
  CUR_PASSENGER = C;
  PAX_DetailHTML(C);
}

function PAX_ManifestRemovePax(index) {
  var M = PAX_MANIFESTING.CUR_MANIFEST;

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete this Passenger?',
    buttons: {
      Delete: {
        click: function () {
          PAX_MANIFESTING.Manifests_Filtered[PAX_MANIFESTING.CurIndex].Passengers.splice(index, 1);
          M.modified = dateToStringObject(new Date());
          cirroDB.insert(
            'PaxMan',
            {
              PrimaryKey: M.PrimaryKey,
              OFFLINE_PK: M.OFFLINE_PK == null ? '' : M.OFFLINE_PK,
              Name: M.Name,
              Description: M.Description,
              modified: M.modified,
              PaxCount: M.PaxCount,
              hasVIP: M.hasVIP,
              ClientFilter: M.ClientFilter,
              DELETED: M.DELETED,
              Cfields: M.Cfields,
              Passengers: makeNewPaxLookup(PAX_MANIFESTING.Manifests_Filtered[PAX_MANIFESTING.CurIndex].Passengers),
            },
            null,
            null
          );
          PAX_update_CurMANIFESTsync(M.modified);
          PAX_EditManifest(PAX_MANIFESTING.CurIndex, true);
          if (ONLINE && !LOW_BANDWIDTH) {
            PAX_syncManifests(true);
          }
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function PAX_ManifestingAddPax(save = false) {
  var M = PAX_MANIFESTING.CUR_MANIFEST;
  for (var i in PAX_Filtered) {
    var I = PAX_Filtered[i];
    if (I.selected != true) continue;
    I.PassengerPK = I.PrimaryKey;
    PAX_MANIFESTING.Manifests_Filtered[PAX_MANIFESTING.CurIndex].Passengers.push(I);
  }
  M.PaxCount = PAX_MANIFESTING.Manifests_Filtered[PAX_MANIFESTING.CurIndex].Passengers.length;
  M.modified = dateToStringObject(new Date());
  cirroDB.insert(
    'PaxMan',
    {
      PrimaryKey: M.PrimaryKey,
      OFFLINE_PK: M.OFFLINE_PK == null ? '' : M.OFFLINE_PK,
      Name: M.Name,
      Description: M.Description,
      modified: M.modified,
      PaxCount: M.PaxCount,
      hasVIP: M.hasVIP,
      ClientFilter: M.ClientFilter,
      DELETED: M.DELETED,
      Cfields: M.Cfields,
      Passengers: makeNewPaxLookup(PAX_MANIFESTING.Manifests_Filtered[PAX_MANIFESTING.CurIndex].Passengers),
    },
    null,
    null
  );
  PAX_update_CurMANIFESTsync(M.modified);
  PAX_EditManifest(PAX_MANIFESTING.CurIndex, true, save);
}
//******************* Passenger Lists ****************************************

var PAX_Filtered = [];
var PAX_LIST = [];

var CUR_PASSENGER = {
  PrimaryKey: 0,
  group: null,
  deptID: 0,
  fltpurposeID: 0,
  paxcode: null,
  BASE: '',
  ClientPK: 0,
  active: 1,
  country: '',
  firstname: '',
  middlename: '',
  lastname: '',
  passport_num: '',
  DOB: '',
  sex: '',
  visa: '',
  weight: 0,
  units: 'imperial',
  VIP: 0,
  modified: '',
  Contacts: [],
  DELETED: 0,
};

function PAX_return() {
  //todo prompt save changes
  $('#PAX_ManifestList').html('<h3>Refreshing...</h3');
  PaxManifesting(PAX_MANIFESTING.AddingPax); //Not adding to manifest
  if (ITIN_Pax_Manifest.Scheduler && GROUP_DATA.Pax_Man == 1) {
    //todo send default manifest to be saved
    ExchangeQuoteData(
      'UpdateSCH_Manifest',
      { DefaultList: ITIN_Pax_Manifest.DefaultList, AC_EventPK: ITIN_Pax_Manifest.AC_EVENT.ItemPrimaryKey },
      function (data) {
        console.log(data);
      },
      true
    );
  }
}

var filterPaxArray_Timeout = null;

function filterPaxArray(filter = 0) {
  if (filterPaxArray_Timeout != null) {
    clearTimeout(filterPaxArray_Timeout);
  }

  filterPaxArray_Timeout = setTimeout(function () {
    filterPaxArray_Timeout = null;
    var keywords = '';
    var clientPK = -2;
    switch (filter) {
      case 0:
        keywords = $('#FilterPaxTXT').val().toLowerCase();
        clientPK = $('#PaxListClient').val();
        break;
      case 1:
        keywords = $('#FilterBulkBriefPassengerList').val().toLowerCase();
        clientPK = $('#PaxListClientB').val();
        break;
    }
    if (keywords != '' || clientPK != -2) {
      PAX_Filtered = FilterPaxTypeArray(PAX_LIST, keywords, clientPK);
    } else {
      PAX_Filtered = PAX_LIST;
    }
    PAX_Filtered.sort((a, b) =>
      a.lastname.toLowerCase() > b.lastname.toLowerCase()
        ? 1
        : b.lastname.toLowerCase() > a.lastname.toLowerCase()
        ? -1
        : 0
    );
    switch (filter) {
      case 0:
        displayPaxFiltered();
        break;
      case 1:
        PAX_BulkBriefing_PaxSelect();
        break;
    }
  }, 500);
}

function FilterPaxTypeArray(OrigArray, keywords, clientPK) {
  var Filtered = OrigArray.filter(function (item) {
    var paxcode = item.paxcode;
    if (paxcode == null) paxcode = '';
    if (item.ClientPK != clientPK && clientPK != -2) {
      return false;
    }
    if (keywords == '') {
      return true;
    }
    if (
      item.firstname.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
      item.lastname.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
      item.clientName.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
      paxcode.toLowerCase().indexOf(keywords.toLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  });
  return Filtered;
}

function clearPaxFilter(filter = 0) {
  $('#FilterPaxTXT').val('');
  $('#FilterBulkBriefPassengerList').val('');
  filterPaxArray(filter);
}

function PAX_LoadList(Passengers, filter = 0) {
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e == false) {
      e = [];
    }
    var PAX_CLIENT_LOOKUP = {};
    for (var a in e) {
      if (PAX_CLIENT_LOOKUP[e[a].ClientPK] != undefined) {
        continue;
      }
      PAX_CLIENT_LOOKUP[e[a].ClientPK] = e[a].Business;
    }
    PAX_LIST = [];
    for (var i in Passengers) {
      var I = Passengers[i];

      if (!blank(I.modified)) {
        const dateFormat = `${VueBridge.dateTimeUtils.formattableDateFormats[0]} ${VueBridge.dateTimeUtils.timeFormat}`;

        I.modified = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(I.modified)?.format(dateFormat);
      }

      if (PAX_MANIFESTING.AddingPax) {
        if (I.active == 0) continue;
      }
      if (I.DELETED == 0) PAX_LIST.push(I);
    }
    for (var i in PAX_LIST) {
      if (PAX_CLIENT_LOOKUP[PAX_LIST[i].ClientPK] == undefined) {
        PAX_LIST[i].ClientPK = -1;
      }
      PAX_LIST[i].clientName =
        PAX_CLIENT_LOOKUP[PAX_LIST[i].ClientPK] != undefined ? PAX_CLIENT_LOOKUP[PAX_LIST[i].ClientPK] : 'N/A';
    }
    cirroDB.query('PassengerCfields', 'PrimaryKey != ?', [-999], function (Cfields) {
      PAX_MANIFESTING.Cfields = Cfields;
      filterPaxArray(filter);
    });
  });
}

function displayPaxFiltered(page = 0) {
  var html = '';
  if (PAX_Filtered.length == 0) {
    html = '<h3>No Items</h3>';
  } else {
    if (PAX_MANIFESTING.AddingPax && PAX_MANIFESTING.AddingManifestTo != 'manifest') {
      html =
        '<button data-mini="true" class="greenbg" onClick="PAX_ITIN_ManifestingAddPaxs()" data-icon="plus">' +
        iTrans('Add Selected Passengers') +
        '</button>';
    } else if (PAX_MANIFESTING.AddingManifestTo == 'manifest') {
      html =
        '<button data-mini="true" class="greenbg" onClick="PAX_ManifestingAddPax(true)" data-icon="plus">' +
        iTrans('Add Selected Passengers') +
        '</button>';
    }

    const clientColumnHiddenClass = renderHiddenClassForPaxManColumn('client');
    const companyColumnHiddenClass = renderHiddenClassForPaxManColumn('company');
    const codeColumnHiddenClass = renderHiddenClassForPaxManColumn('code');
    const weightColumnHiddenClass = renderHiddenClassForPaxManColumn('weight');

    html += `<table class="limit-table second-td-fill" id="PAX_MAN_Filtered">
          <thead>
            <tr>
              <th>Lastname</th>
              <th>Firstname</th>
              <th id="PAX_ClientColumnHeader" class="${clientColumnHiddenClass}">Client</th>
              <th id="PAX_CompanyColumnHeader" class="${companyColumnHiddenClass}">Company</th>
              <th id="PAX_CodeColumnHeader" class="${codeColumnHiddenClass}">Code</th>
              <th id="PAX_WeightColumnHeader" class="${weightColumnHiddenClass}">Weight</th>
              <th>Modified</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>`;
    var count = 0;
    var total = PAX_Filtered.length;
    var numPerPage = 50;
    var recStart = page * numPerPage;
    var i = -1;
    for (i in PAX_Filtered) {
      if (i < recStart) continue;
      count++;
      if (count == numPerPage + 1) break;
      var I = PAX_Filtered[i];
      var actions =
        '<div class="vertical-center-container"><button onclick="PAX_ListViewPaxContacts(' +
        i +
        ');" data-mini="true" class="action-btn"><img src="./images/inspect.svg"></button><button onclick="PAX_EditPax(' +
        i +
        ');" data-mini="true" class="action-btn"><img src="./images/edit.svg"></button><button onclick="PAX_DeletePax(' +
        i +
        ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></div>';
      if (PAX_MANIFESTING.AddingPax) {
        if (PAX_MANIFESTING.AddingManifestTo == 'Itinerary' || PAX_MANIFESTING.AddingManifestTo == 'Leg') {
          if (PAX_MANIFESTING.AddingManifestTo == 'Leg') {
            var ItinClientPK = ITIN_Pax_Manifest.ClientPK;
          } else {
            var ItinClientPK = $('#ITIN_JOB option:selected').attr('data-clientpk');
          }
          /*

           if (ItinClientPK != '0' && I.ClientPK != ItinClientPK && I.ClientPK != -1) {
           continue;
           }
           */

          if (ITIN_Pax_Manifest.CurLeg == -1) {
            if (ITIN_Pax_Manifest.DefaultList == null) ITIN_Pax_Manifest.DefaultList = [];
            if (
              ITIN_Pax_Manifest.DefaultList.filter((Passengers) => Passengers.PassengerPK == I.PrimaryKey).length > 0
            ) {
              continue;
            }
          } else {
            if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] == null)
              ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] = [];
            if (
              ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg].filter(
                (Passengers) => Passengers.PassengerPK == I.PrimaryKey
              ).length > 0
            ) {
              continue;
            }
          }
          //if (PAX_MANIFESTING.CUR_MANIFEST.ClientFilter != I.ClientPK &&  PAX_MANIFESTING.CUR_MANIFEST.ClientFilter != -1 || PAX_MANIFESTING.CUR_MANIFEST.Passengers.includes()) continue;
          actions =
            '<div class="vertical-center-container"><input style="width:25px;height:25px;" type="checkbox" data-role="none" id="PAX_SelectCHK-' +
            i +
            '" ' +
            (I.selected ? 'checked' : '') +
            '/><button onclick="PAX_EditPax(' +
            i +
            ');" data-mini="true" class="action-btn"><img src="./images/edit.svg"></button><button onclick="PAX_ITIN_ManifestingAddPax(' +
            i +
            ');" data-mini="true" class="action-btn"><img src="./images/Check_mark_green.svg"></button></div>';
        } else {
          if (
            PAX_MANIFESTING.CUR_MANIFEST.Passengers.filter((Passengers) => Passengers.PassengerPK === I.PrimaryKey)
              .length > 0
          ) {
            continue;
          }
          actions =
            '<div class="vertical-center-container"><input type="checkbox" data-role="none" data-testid="pax-manifesting-new-manifest-select-pax-' +
            i +
            '" id="PAX_SelectCHK-' +
            i +
            '" ' +
            (I.selected ? 'checked' : '') +
            '/></div>';
        }
      }
      var TRclass = I.VIP == 1 ? 'class="vip-following"' : '';
      if (I.selected == 1) TRclass = 'class="greenRecord"';
      if (I.active == 0) TRclass = 'class="redbg"';
      var TRselect = '';
      if (PAX_MANIFESTING.AddingPax) TRselect = 'onClick="PAX_togglePaxSelect(' + i + ')"';

      html += `<tr style="height:40px;" data-testid="pax-manifesting-pax-${I.lastname} ${
        I.firstname
      }" id="PAX_SelectTR-${i}" ${TRclass} ${TRselect}>
                 <td>${I.lastname}</td>
                 <td>${I.firstname} ${I.middlename}</td>
                 <td id="PAX_ClientColumn-${i}" class="${clientColumnHiddenClass}">${I.clientName}</td>
                 <td id="PAX_CompanyColumn-${i}" class="${companyColumnHiddenClass}">${I.company}</td>
                 <td id="PAX_CodeColumn-${i}" class="${codeColumnHiddenClass}">${
        I.paxcode == null ? '[N/A]' : I.paxcode
      }</td>
                 <td id="PAX_WeightColumn-${i}" class="${weightColumnHiddenClass}">${I.weight}</td>
                 <td>${I.modified}</td>
                 <td>${actions}</td>
               </tr>`;
    }
    html += '</tbody></table>';
    var nav = '';
    if (total != 0) {
      if (recStart == 0) recStart = 1;
      if (i == total - 1) {
        nav =
          (page == 0 ? '' : '<a onClick="displayPaxFiltered(' + (page - 1) + ')"><--Prev Page </a>') +
          recStart +
          ' - ' +
          total +
          ' of ' +
          total +
          ' items';
      } else {
        nav =
          (page == 0 ? '' : '<a onClick="displayPaxFiltered(' + (page - 1) + ')"><--Prev Page </a>') +
          (i - (numPerPage - 1)) +
          ' - ' +
          i +
          ' of ' +
          total +
          ' items' +
          '<a onClick="displayPaxFiltered(' +
          (i < total ? page + 1 : page) +
          ')"> Next Page --></a>';
      }
      html = nav + html + nav;
    }

    if (PAX_MANIFESTING.AddingPax && PAX_MANIFESTING.AddingManifestTo != 'manifest') {
      html +=
        '<button data-mini="true" onClick="PAX_ITIN_ManifestingAddPaxs()" class="greenbg" data-icon="plus">' +
        iTrans('Add Selected Passengers') +
        '</button>';
    } else if (PAX_MANIFESTING.AddingManifestTo == 'manifest') {
      html +=
        '<button data-mini="true" data-testid="pax-manifesting-add-passengers-button" onClick="PAX_ManifestingAddPax(true)" class="greenbg" data-icon="plus">' +
        iTrans('Add Selected Passengers') +
        '</button>';
    }
  }

  $('#PAX_ManifestList').html(html);
  $('#PAX_ManifestList').enhanceWithin();
  //AddTableSorter('PAX_MAN_Filtered', [[0, 0]]);
}

function PAX_ListViewPaxContacts(index, returnDIV = '#PAX_Manifesting') {
  PAX_SaveScrollPos();
  var C = PAX_Filtered[index];
  PAX_PaxDetails_return_ID = returnDIV;
  CUR_PASSENGER = C;
  PAX_DetailHTML(C);
}

function PAX_togglePaxSelect(index) {
  if (PAX_Filtered[index].selected == undefined || PAX_Filtered[index].selected == false) {
    PAX_Filtered[index].selected = true;
    $('#PAX_SelectTR-' + index).removeClass('yellowRecord');
    $('#PAX_SelectTR-' + index).addClass('greenRecord');
    $('#PAX_SelectCHK-' + index).prop('checked', true);
  } else {
    PAX_Filtered[index].selected = false;
    if (PAX_Filtered[index].VIP == 1) $('#PAX_SelectTR-' + index).removeClass('yellowRecord');
    $('#PAX_SelectTR-' + index).removeClass('greenRecord');
    $('#PAX_SelectCHK-' + index).prop('checked', false);
  }
  PAX_BulkBriefing_PaxSelected(false);
}

function PAX_ITIN_ManifestingAddPax(index, autoPax = false) {
  if (!autoPax) {
    var NewPax = JSON.parse(JSON.stringify(PAX_Filtered[index]));
  } else {
    var NewPax = JSON.parse(JSON.stringify(autoPax));
  }
  NewPax.PassengerPK = NewPax.PrimaryKey;
  if (ITIN_Pax_Manifest.CurLeg == -1) {
    ITIN_Pax_Manifest.DefaultList.push(NewPax);
    if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] == undefined) {
      ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] = [];
    }
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg].push(NewPax);
    if (ITIN_Pax_Manifest.Editing) {
      if (ITIN_Pax_Manifest.FlightReport) {
        $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
          changeHash: false,
        });
      } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
        if (ITIN_Pax_Manifest.CurLeg == -1) {
          $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
            changeHash: false,
          });
          if (GROUP_DATA.Pax_Man == 1 && ITIN_Pax_Manifest.Scheduler) {
            ExchangeQuoteData(
              'UpdateSCH_Manifest',
              { DefaultList: ITIN_Pax_Manifest.DefaultList, AC_EventPK: ITIN_Pax_Manifest.AC_EVENT.ItemPrimaryKey },
              function (data) {
                console.log(data);
              },
              true
            );
          }
        } else {
          $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
            changeHash: false,
          });
        }
      } else {
        $.mobile.changePage($('#Edit_Itin_Page'), {
          changeHash: false,
        });
      }
    } else {
      $.mobile.changePage($('#ITIN-INPUT'), {
        changeHash: false,
      });
    }
  } else {
    if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] == undefined) {
      ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] = [];
    }
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg].push(PAX_ITIN_CheckPreviouslyBriefed(NewPax));
    if (ITIN_Pax_Manifest.FlightReport) {
      $.mobile.changePage($('#BILLING_FlightLegsPage'), {
        changeHash: false,
      });
    } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
          changeHash: false,
        });
      } else {
        $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
          changeHash: false,
        });
      }
    } else {
      $.mobile.changePage($('#WB_Page2'), {
        changeHash: false,
      });
    }
  }
  PAX_updateScrollPos();
  PAX_Init_ITIN_Manifesting(ITIN_Pax_Manifest.CurLeg);
}

function PAX_ITIN_CheckPreviouslyBriefed(NewPax) {
  for (var i in ITIN_Pax_Manifest.Pax_Legs) {
    var L = ITIN_Pax_Manifest.Pax_Legs[i];
    for (var p in L) {
      var P = L[p];
      if (P.PassengerPK == NewPax.PassengerPK && P.OFFLINE_PK == NewPax.OFFLINE_PK) {
        var P = JSON.parse(JSON.stringify(P));
        P.seatPos = '';
        return P;
      }
    }
  }
  return NewPax;
}

function PAX_ITIN_ManifestingAddPaxs() {
  for (var i in PAX_Filtered) {
    var I = PAX_Filtered[i];
    if (I.selected) {
      var NewPax = JSON.parse(JSON.stringify(I));
      NewPax.PassengerPK = NewPax.PrimaryKey;
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        ITIN_Pax_Manifest.DefaultList.push(NewPax);
      } else {
        if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] == undefined) {
          ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg] = [];
        }
        ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg].push(PAX_ITIN_CheckPreviouslyBriefed(NewPax));
      }
    }
  }

  if (ITIN_Pax_Manifest.CurLeg == -1) {
    if (ITIN_Pax_Manifest.Editing) {
      if (ITIN_Pax_Manifest.FlightReport) {
        $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
          changeHash: false,
        });
      } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
        $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
          changeHash: false,
        });
        if (GROUP_DATA.Pax_Man == 1 && ITIN_Pax_Manifest.Scheduler) {
          ExchangeQuoteData(
            'UpdateSCH_Manifest',
            { DefaultList: ITIN_Pax_Manifest.DefaultList, AC_EventPK: ITIN_Pax_Manifest.AC_EVENT.ItemPrimaryKey },
            function (data) {
              console.log(data);
            },
            true
          );
        }
      } else {
        $.mobile.changePage($('#Edit_Itin_Page'), {
          changeHash: false,
        });
      }
    } else {
      $.mobile.changePage($('#ITIN-INPUT'), {
        changeHash: false,
      });
    }
  } else {
    if (ITIN_Pax_Manifest.FlightReport) {
      $.mobile.changePage($('#BILLING_FlightLegsPage'), {
        changeHash: false,
      });
    } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
          changeHash: false,
        });
      } else {
        $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
          changeHash: false,
        });
      }
    } else {
      $.mobile.changePage($('#WB_Page2'), {
        changeHash: false,
      });
    }
  }
  PAX_updateScrollPos();
  PAX_Init_ITIN_Manifesting(ITIN_Pax_Manifest.CurLeg);
}

function PAX_EditPax(index) {
  CUR_PASSENGER = PAX_Filtered[index];
  $.mobile.changePage($('#PAX_NewPassengerPage'), {
    changeHash: false,
  });
  $('#PAX_SavePaxAndAddBTN').hide();
  PAX_PopulatePaxFields();
}

function PAX_DeletePax(index) {
  CUR_PASSENGER = PAX_Filtered[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete this Passenger?',
    buttons: {
      Delete: {
        click: function () {
          CUR_PASSENGER.DELETED = 1;
          CUR_PASSENGER.modified = dateToStringObject(new Date());
          PAX_update_CurPASSENGERsync(CUR_PASSENGER.modified);
          PAX_addOfflinePassenger(false);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function PAX_NewPassenger() {
  $.mobile.changePage($('#PAX_NewPassengerPage'), {
    changeHash: false,
  });
  $('#PAX_ContactList').html('');
  CUR_PASSENGER = {
    PrimaryKey: null,
    OFFLINE_PK: new Date().getTime(),
    group: null,
    deptID: 0,
    fltpurposeID: 0,
    seatPos: '',
    paxcode: null,
    BASE: '',
    ClientPK: -1,
    active: 1,
    country: '',
    firstname: '',
    middlename: '',
    lastname: '',
    passport_num: '',
    DOB: '1900-01-01',
    sex: 'x',
    visa: '',
    weight: 0,
    units: 'imperial',
    VIP: 0,
    modified: '',
    Contacts: [],
    DELETED: 0,
  };
  if (PAX_MANIFESTING.AddingManifestTo == 'Itinerary') {
    CUR_PASSENGER.ClientPK = Itin_ClientPK;
    if (CUR_PASSENGER.ClientPK == 0) {
      CUR_PASSENGER.ClientPK = -1;
    }
  }
  if (PAX_MANIFESTING.AddingManifestTo == 'Itinerary' || PAX_MANIFESTING.AddingManifestTo == 'Leg') {
    $('#PAX_SavePaxAndAddBTN').show();
  } else {
    $('#PAX_SavePaxAndAddBTN').hide();
  }
  PAX_PopulatePaxFields();
}

function PAX_Populate_ClientList(clientPK = null, briefing = false) {
  var B = '';
  if (briefing) B = 'B';
  $('#PaxListClient' + B).empty();
  $('#PaxListClient' + B).append('<option value="-2">' + iTrans('Any') + '</option>');
  $('#PaxListClient' + B).append('<option value="0">' + iTrans('Company') + '</option>');

  $('#PaxListClient' + B).append('<option value="-1">N/A</option>');
  Cfield_SignatureStatus.returnPageID = 'PAX_NewPassengerPage';
  BillingDB('SELECT', 'Clients', null, function (e) {
    var Jobs = {};
    if (e == false) {
      e = [];
    }
    for (var i in e) {
      $('#PaxListClient' + B).append(
        '<option data-testid="pax-manifesting-pax-list-client-' +
          e[i].Business +
          '" value="' +
          e[i].PrimaryKey +
          '">' +
          e[i].Business +
          '</option>'
      );
    }
    if (clientPK != null) {
      $('#PaxListClient' + B)
        .val(clientPK)
        .selectmenu('refresh');
    } else {
      $('#PaxListClient' + B)
        .val(-2)
        .selectmenu('refresh');
    }
  });
}

function PAX_PopulatePaxFields() {
  var C = CUR_PASSENGER;
  $('#PAX_homebase_icao').val('');
  $('#PAX_DB_BASE').empty();
  $('#PAX_DB_BASE').append('<option value="" selected>N/A</option>');
  $('#PAX_fltpurposeID').empty();
  $('#PAX_fltpurposeID').append('<option value="0" selected>[None]</option>');
  $('#PAX_deptID').empty();
  $('#PAX_deptID').append('<option value="0" selected>[Not Assigned]</option>');
  if (GROUP_DATA.Bases != undefined) {
    for (var i in GROUP_DATA.Bases) {
      var bases = GROUP_DATA.Bases[i];
      $('#PAX_DB_BASE').append('<option value="' + bases.name + '">' + bases.name + '</option>');
      if (bases.name == C.BASE) $('#PAX_homebase_icao').val(bases.icao);
    }

    for (var i in GROUP_DATA.FlightPurpose) {
      var pur = GROUP_DATA.FlightPurpose[i];
      $('#PAX_fltpurposeID').append('<option value="' + pur.PrimaryKey + '">' + pur.desc + '</option>');
    }

    for (var i in GROUP_DATA.Departments) {
      var dep = GROUP_DATA.Departments[i];
      $('#PAX_deptID').append(
        '<option value="' +
          dep.id +
          '">' +
          (dep.dept_code != null ? '[' + dep.dept_code + '] ' : '') +
          dep.name +
          '</option>'
      );
    }
  }
  $('#PAX_DB_ClientPK').empty();
  $('#PAX_DB_ClientPK').append('<option value="-1">N/A</option>');
  Cfield_SignatureStatus.returnPageID = 'PAX_NewPassengerPage';
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    var Jobs = {};
    if (e == false) {
      e = [];
    }
    for (var i in e) {
      if (Jobs[e[i].Business] == undefined) {
        Jobs[e[i].Business] = 1;
        $('#PAX_DB_ClientPK').append(
          '<option data-testid="pax-manifesting-new-pax-client-' +
            e[i].Business +
            '"  value="' +
            e[i].ClientPK +
            '">' +
            e[i].Business +
            '</option>'
        );
      }
    }

    $('#PAX_DB_BASE').val(C.BASE).selectmenu('refresh');
    $('#PAX_DB_ClientPK').val(C.ClientPK).selectmenu('refresh');
    $('#PAX_DB_country').val(C.country);
    $('#PAX_DB_firstname').val(C.firstname);
    $('#PAX_DB_middlename').val(C.middlename);
    $('#PAX_DB_lastname').val(C.lastname);
    $('#PAX_DB_passport_num').val(C.passport_num);
    $('#PAX_DB_DOB').val(C.DOB);
    $('#PAX_DB_sex').val(C.sex).selectmenu('refresh');
    $('#PAX_DB_visa').val(C.visa);
    $('#PAX_DB_weight').val(C.weight);
    if (C.units == 'imperial') {
      $('#PAX_DB_units_imperial').prop('checked', true).checkboxradio('refresh');
      $('#PAX_DB_units_metric').prop('checked', false).checkboxradio('refresh');
    } else {
      $('#PAX_DB_units_imperial').prop('checked', false).checkboxradio('refresh');
      $('#PAX_DB_units_metric').prop('checked', true).checkboxradio('refresh');
    }
    if (C.VIP == 0) {
      $('#PAX_DB_VIP').prop('checked', false).checkboxradio('refresh');
    } else {
      $('#PAX_DB_VIP').prop('checked', true).checkboxradio('refresh');
    }

    $('#PAX_active')
      .prop('checked', C.active == 1 ? true : false)
      .checkboxradio('refresh');
    $('#PAX_homebase_icao').val(C.homebase_icao);
    $('#PAX_paxcode').val(C.paxcode);
    $('#PAX_deptID').val(C.deptID).selectmenu('refresh');
    $('#PAX_note').val(C.note);
    $('#PAX_fltpurposeID').val(C.fltpurposeID).selectmenu('refresh');
    $('#PAX_company').val(C.company);
    $('#PAX_addr_1').val(C.addr_1);
    $('#PAX_addr_2').val(C.addr_2);
    $('#PAX_city').val(C.city);
    $('#PAX_prov_state').val(C.prov_state);
    $('#PAX_postal').val(C.postal);
    $('#PAX_nation').val(C.nation);
    $('#PAX_ctryresid').val(C.ctryresid);

    var newCfields = populateCfields(PAX_MANIFESTING.Cfields, 'Pax');

    if (C.Cfields == null || C.Cfields.length == 0) {
      //Populate with new Cfields
      C.Cfields = newCfields;
    } else {
      //check NewCfields list against existing Cfields and do what you need to
      for (var i in newCfields) {
        var I = newCfields[i];
        var found = false;
        for (var o in C.Cfields) {
          var O = C.Cfields[o];
          if (I.PrimaryKey == O.PrimaryKey) {
            found = true;
            break;
          }
        }
        if (!found) C.Cfields.push(I);
      }
    }
    $('#PAX_Manifest_Cfields').html(''); //to prevent duplciate divid's
    $('#PAX_Passenger_Cfields').html('');
    var chtml =
      '<div  data-role="collapsible" data-inset="true" data-mini="false"><h5>' +
      iTrans('Additional Passenger Information') +
      '</h5><div>';
    var req_fields = '';
    for (var i in C.Cfields) {
      var I = C.Cfields[i];
      if (I.reqPax == 1) {
        req_fields += createPaxManCustomField(I, I.Value, 'Pax');
      } else {
        chtml += createPaxManCustomField(I, I.Value, 'Pax');
      }
    }

    if (C.Cfields.length == 0) {
      $('#PAX_Passenger_Cfields').html('');
    } else {
      $('#PAX_Passenger_Cfields').html(req_fields + chtml + '</div></div>');
      $('#PAX_Passenger_Cfields').enhanceWithin();
    }

    PAX_LoadPaxContacts();
  });
}

function PAX_autoPop_paxcode(e) {
  if (e.value == '') {
    let lastname = $('#PAX_DB_lastname').val().replace(/\s/g, '');
    let firstname = $('#PAX_DB_firstname').val().replace(/\s/g, '');

    var paxcode = lastname.substr(0, 4) + firstname.substr(0, 1);
    $('#PAX_paxcode').val(paxcode);
  }
}

function PAX_changeBase(e) {
  for (var i in GROUP_DATA.Bases) {
    var bases = GROUP_DATA.Bases[i];
    if (e.value == bases.name) {
      $('#PAX_homebase_icao').val(bases.icao);
      return;
    }
  }
}

function PAX_SavePassenger(autoAdd = false, silent = false) {
  var C = CUR_PASSENGER;
  C.BASE = $('#PAX_DB_BASE').val();
  C.Briefings = [];
  C.group = GROUP_DATA.group;
  C.ClientPK = $('#PAX_DB_ClientPK').val();
  C.country = $('#PAX_DB_country').val();
  C.firstname = $('#PAX_DB_firstname').val();
  C.middlename = $('#PAX_DB_middlename').val();
  C.lastname = $('#PAX_DB_lastname').val();
  C.passport_num = $('#PAX_DB_passport_num').val();
  C.DOB = $('#PAX_DB_DOB').val();
  C.sex = $('#PAX_DB_sex').val();
  C.visa = $('#PAX_DB_visa').val();
  C.weight = $('#PAX_DB_weight').val();
  C.units = $('#PAX_DB_units :radio:checked').val();
  if ($('#PAX_DB_VIP').is(':checked')) {
    C.VIP = 1;
  } else {
    C.VIP = 0;
  }

  C.homebase_icao = $('#PAX_homebase_icao').val();
  C.paxcode = $('#PAX_paxcode').val();
  if (C.paxcode != null) {
    var paxcode = C.paxcode.replace(/ /g, '+');
    if (paxcode == '') C.paxcode = null;
  }
  C.deptID = $('#PAX_deptID').val();
  C.note = $('#PAX_note').val();
  C.fltpurposeID = $('#PAX_fltpurposeID').val();
  if (C.fltpurposeID == null) C.fltpurposeID = 0;
  C.company = $('#PAX_company').val();
  C.addr_1 = $('#PAX_addr_1').val();
  C.addr_2 = $('#PAX_addr_2').val();
  C.city = $('#PAX_city').val();
  C.prov_state = $('#PAX_prov_state').val();
  C.postal = $('#PAX_postal').val();
  C.nation = $('#PAX_nation').val();
  C.ctryresid = $('#PAX_ctryresid').val();
  C.active = $('#PAX_active').is(':checked') ? 1 : 0;
  C.modified = dateToStringObject(new Date());
  PAX_update_CurPASSENGERsync(C.modified);
  var DisableNext = savePaxCfields(C);
  if (DisableNext) {
    return;
  }

  PAX_checkPaxCode(C, silent, autoAdd, PAX_SavePassengerVerified);
}
function PAX_SavePassengerVerified(C, silent, autoAdd) {
  if (!silent && C !== false) {
    //Get most resent OFFLINE Passenger PK becuase they need to be unique
    if (C.PrimaryKey != null) {
      PAX_addOfflinePassenger(autoAdd);
    } else {
      cirroDB.query('Passengers', 'PrimaryKey <= ? ORDER BY PrimaryKey ASC LIMIT 1', ['0'], function (Passenger) {
        var insert_id = 0;
        if (Passenger !== false) {
          if (Passenger.length != 0) {
            insert_id = parseInt(Passenger[0].PrimaryKey) - 1; //Offline passengers get negative PK's
          }
        }
        C.PrimaryKey = insert_id;
        PAX_addOfflinePassenger(autoAdd);
      });
    }
  }
}

function PAX_checkPaxCode(pax, silent, autoAdd, callback) {
  cirroDB.query('Passengers', 'paxcode = ? ', [pax.paxcode], function (Passenger) {
    if (Passenger === false) {
      Passenger = [];
    }

    if (Passenger.length != 0) {
      if (
        Passenger[0].PrimaryKey == pax.PrimaryKey ||
        (Passenger[0].OFFLINE_PK == pax.OFFLINE_PK && pax.OFFLINE_PK != '' && pax.OFFLINE_PK != null)
      ) {
        callback(pax, silent, autoAdd);
      } else {
        if (!silent) {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Error',
            headerClose: false,
            buttonPrompt: iTrans('The Passenger Code is already in use. Please choose a different code'),
            buttons: {
              Close: {
                click: function () {
                  //Update File Now Text - W&B Problem
                },
              },
            },
          });
        }
        callback(false, silent, autoAdd);
      }
    } else {
      callback(pax, silent, autoAdd);
    }
  });
}
function PAX_addOfflinePassenger(autoAdd, silent) {
  console.log('UpdatedPassenger');
  console.log(autoAdd);
  PAX_UpdatePassenger(CUR_PASSENGER, function (e) {
    PAX_PaxSaved(autoAdd);
    if (!silent) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Success',
        headerClose: false,
        buttonPrompt: iTrans('Passenger Saved Successfully'),
        buttons: {
          [iTrans('Return')]: {
            click: function () {
              PAX_return();
            },
          },
          [iTrans('Stay on Page')]: {
            click: function () {
              //do nothing
            },
          },
        },
        callbackOpen: function () {
          // Use callbackOpen to set the data-testid attributes after the dialog is created
          $('a.ui-btn:contains("Return")').attr('data-testid', 'menu-pax-manifesting-saved-pax-success-return-button');
          $('a.ui-btn:contains("Stay on Page")').attr(
            'data-testid',
            'menu-pax-manifesting-saved-pax-success-stay-button'
          );
        },
      });
    }
  });
}

function PAX_PaxSaved(autoAdd) {
  if (ONLINE && !LOW_BANDWIDTH) {
    PAX_syncPassengers(true);
  }
  if (CUR_PASSENGER.DELETED == 1) {
    $('#PAX_ManifestList').html('<h3>Refreshing...</h3');
    PaxManifesting(PAX_MANIFESTING.AddingPax);
    return;
  }

  if (autoAdd) {
    PAX_ITIN_ManifestingAddPax(null, CUR_PASSENGER);
  } else {
    PAX_LoadPaxContacts();
  }
}

function PAX_LoadPaxContacts() {
  var html = '';
  if (CUR_PASSENGER.Contacts.length > 0) {
    html += '<h3>Passenger Contact List</h3>';
    html +=
      '<table class="limit-table first-td-fill"><thead><tr><th>Contact</th><th>Relation</th><th>Action</th></tr></thead><tbody>';
    for (var i in CUR_PASSENGER.Contacts) {
      var C = CUR_PASSENGER.Contacts[i];
      if (C.DELETED == 1) {
        continue;
      }
      var action =
        '<div class="vertical-center-container"><button class="action-btn" onclick="PAX_EditContact(' +
        i +
        ')"><img src="./images/edit.svg"></button><button class="action-btn" onclick="PAX_DeleteContact(' +
        i +
        ')"><img src="./images/delete.svg"></button></div>';
      if (C.isPaxContact == 0) {
        html +=
          '<tr><td>' +
          C.firstname +
          ' ' +
          C.middlename +
          ' ' +
          C.lastname +
          '</td><td>' +
          C.Relation +
          '</td><td>' +
          action +
          '</td></tr>';
      } else {
        html +=
          '<tr class="yellowrec"><td>' +
          CUR_PASSENGER.firstname +
          ' ' +
          CUR_PASSENGER.middlename +
          ' ' +
          CUR_PASSENGER.lastname +
          '</td><td>Passenger</td><td>' +
          action +
          '</td></tr>';
      }
    }
    html += '</tbody></table>';
  } else {
    html += '<h3>No Passenger Contacts</h3>';
  }

  html +=
    '<button data-mini="ture" class="greenbg" data-icon="plus" onClick="PAX_EditContact(-1)">Add New Contact</button>';

  $('#PAX_ContactList').html(html);
  $('#PAX_ContactList').enhanceWithin();
}

function PAX_EditContact(index) {
  PAX_SavePassenger(false, true);
  $.mobile.changePage($('#PAX_EditPaxContact'), {
    changeHash: false,
  });
  var C = {
    PrimaryKey: 0,
    OFFLINE_PK: new Date().getTime(),
    PassengerPK: CUR_PASSENGER.PrimaryKey,
    group: '',
    Relation: '',
    firstname: '',
    middlename: '',
    lastname: '',
    phone_type: '',
    phone_num: '',
    email: '',
    isPaxContact: 0,
    modified: dateToStringObject(new Date()),
    DELETED: 0,
  };
  if (index != -1) {
    //edit existing
    var C = CUR_PASSENGER.Contacts[index];
  }
  if (C.isPaxContact == 1) {
    $('#PAX_DB_Contacts_firstname').val(CUR_PASSENGER.firstname).prop('disabled', true);
    $('#PAX_DB_Contacts_middlename').val(CUR_PASSENGER.middlename).prop('disabled', true);
    $('#PAX_DB_Contacts_lastname').val(CUR_PASSENGER.lastname).prop('disabled', true);
    $('#PAX_DB_Contacts_Relation').val('').prop('disabled', true);
    $('#PAX_DB_Contacts_isPaxContact').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#PAX_DB_Contacts_firstname').val(C.firstname).prop('disabled', false);
    $('#PAX_DB_Contacts_middlename').val(C.middlename).prop('disabled', false);
    $('#PAX_DB_Contacts_lastname').val(C.lastname).prop('disabled', false);
    $('#PAX_DB_Contacts_Relation').val(C.Relation).prop('disabled', false);
    $('#PAX_DB_Contacts_isPaxContact').prop('checked', false).checkboxradio('refresh');
  }
  $('#PAX_DB_Contacts_phone_type').val(C.phone_type).selectmenu('refresh');
  $('#PAX_DB_Contacts_phone_num').val(C.phone_num);
  $('#PAX_DB_Contacts_phone_num').val(C.email);
  $('#PAX_DB_Contacts_index').val(index);
}

function PAX_toggle_isContact(e) {
  if (e.checked) {
    $('#PAX_DB_Contacts_firstname').val(CUR_PASSENGER.firstname).prop('disabled', true);
    $('#PAX_DB_Contacts_middlename').val(CUR_PASSENGER.middlename).prop('disabled', true);
    $('#PAX_DB_Contacts_lastname').val(CUR_PASSENGER.lastname).prop('disabled', true);
  } else {
    $('#PAX_DB_Contacts_firstname').val('').prop('disabled', false);
    $('#PAX_DB_Contacts_middlename').val('').prop('disabled', false);
    $('#PAX_DB_Contacts_lastname').val('').prop('disabled', false);
  }
}

function PAX_SaveContact() {
  var index = $('#PAX_DB_Contacts_index').val();
  var checked = 0;
  if ($('#PAX_DB_Contacts_isPaxContact').is(':checked')) {
    checked = 1;
  }
  if (index == -1) {
    var C = {
      PrimaryKey: 0,
      OFFLINE_PK: new Date().getTime(),
      PassengerPK: CUR_PASSENGER.PrimaryKey,
      group: '',
      Relation: $('#PAX_DB_Contacts_Relation').val(),
      firstname: $('#PAX_DB_Contacts_firstname').val(),
      middlename: $('#PAX_DB_Contacts_middlename').val(),
      lastname: $('#PAX_DB_Contacts_lastname').val(),
      phone_type: $('#PAX_DB_Contacts_phone_type').val(),
      phone_num: $('#PAX_DB_Contacts_phone_num').val(),
      email: $('#PAX_DB_Contacts_email').val(),
      isPaxContact: checked,
      modified: dateToStringObject(new Date()),
      DELETED: 0,
    };
    CUR_PASSENGER.Contacts.push(C);
  } else {
    var C = CUR_PASSENGER.Contacts[index];
    C.Relation = $('#PAX_DB_Contacts_Relation').val();
    C.firstname = $('#PAX_DB_Contacts_firstname').val();
    C.middlename = $('#PAX_DB_Contacts_middlename').val();
    C.lastname = $('#PAX_DB_Contacts_lastname').val();
    C.phone_type = $('#PAX_DB_Contacts_phone_type').val();
    C.phone_num = $('#PAX_DB_Contacts_phone_num').val();
    C.email = $('#PAX_DB_Contacts_email').val();
    C.isPaxContact = checked;
    C.modified = dateToStringObject(new Date());
  }
  PAX_update_CurPASSENGERsync(C.modified);
  PAX_editOfflinePassengerContacts();
}

function PAX_DeleteContact(index) {
  var C = CUR_PASSENGER.Contacts[index];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete this Contact?',
    buttons: {
      Delete: {
        click: function () {
          C.DELETED = 1;
          C.modified = dateToStringObject(new Date());
          PAX_update_CurPASSENGERsync(C.modified);
          PAX_editOfflinePassengerContacts();
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function PAX_editOfflinePassengerContacts() {
  PAX_UpdatePassenger(CUR_PASSENGER, function (e) {
    console.log('Passengers UPDATED');
    PAX_PaxSaved(false);
    PAX_PaxContactSaved();
  });
}

function PAX_PaxContactSaved() {
  $.mobile.changePage($('#PAX_NewPassengerPage'), {
    reverse: true,
    changeHash: false,
  });
  PAX_PopulatePaxFields();
}
var PAX_FlightPurpose = null;
function PAX_FltPurposeLookup(id) {
  if (PAX_FlightPurpose == null) {
    PAX_FlightPurpose = {};
    var P = GROUP_DATA.FlightPurpose;
    for (var i in P) {
      var I = P[i];
      PAX_FlightPurpose[I.PrimaryKey] = I;
    }
  }
  if (PAX_FlightPurpose[id] == undefined) {
    return { code: '[None]' };
  }
  return PAX_FlightPurpose[id];
}
function PAX_DetailHTML(P) {
  var html = '';
  $.mobile.changePage($('#PAX_PaxDetailPage'), {
    changeHash: false,
  });
  var sex = P.sex;
  if (sex == 'male') sex = 'Male';
  if (sex == 'female') sex = 'Female';
  if (sex == 'x') sex = 'Undisclosed';
  html += '<table class="last-td-fill">';

  html +=
    '<tr class="' +
    (P.VIP == 1 ? 'vip-following' : '') +
    '"><td><b>VIP: </b></td><td>' +
    (P.VIP == 1 ? 'Yes' : 'No') +
    '</td></tr>';
  html +=
    '<tr class="' +
    (P.active == 0 ? 'redbg' : '') +
    '"><td><b>Status: </b></td><td>' +
    (P.active == 0 ? iTrans('Inactive') : iTrans('Active')) +
    '</td></tr>';
  html += '<tr><td><b>Firstname: </b></td><td>' + P.firstname + '</td></tr>';
  html += '<tr><td><b>Middlename: </b></td><td>' + P.middlename + '</td></tr>';
  html += '<tr><td><b>Lastname: </b></td><td>' + P.lastname + '</td></tr>';
  html += '<tr><td><b>Country / Nationality: </b></td><td>' + P.country + '</td></tr>';
  html += '<tr><td><b>Passport #</b></td><td>' + P.passport_num + '</td></tr>';
  html += '<tr><td><b>DOB </b></td><td>' + P.DOB + '</td></tr>';
  html += '<tr><td><b>Sex:</b></td><td>' + sex + '</td></tr>';
  html += '<tr><td><b>Visitor Visa Number: </b></td><td>' + P.visa + '</td></tr>';
  html += '<tr><td><b>Weight: </b></td><td>' + P.weight + '</td></tr>';
  html += '<tr><td><b>Weight Units: </b></td><td>' + (P.units == 'imperial' ? 'Lbs' : 'Kg') + '</td></tr>';
  html += '<tr><td><b>Base: </b></td><td>' + P.BASE + '</td></tr>';
  html += '<tr><td><b>Home Base ICAO: </b></td><td>' + P.homebase_icao + '</td></tr>';
  html +=
    '<tr><td><b>Passenger Code: </b></td><td>' + (P.paxcode == null ? '[Not Assigned]' : P.paxcode) + '</td></tr>';
  html +=
    '<tr><td><b>Flight Purpose Code: </b></td><td>' +
    (P.fltpurposeID == 0 ? '[None]' : PAX_FltPurposeLookup(P.fltpurposeID).code) +
    '</td></tr>';
  html += '<tr><td><b>Company: </b></td><td>' + P.company + '</td></tr>';
  html += '<tr><td><b>Address 1: </b></td><td>' + P.addr_1 + '</td></tr>';
  html += '<tr><td><b>Address 2: </b></td><td>' + P.addr_2 + '</td></tr>';
  html += '<tr><td><b>Prov/State: </b></td><td>' + P.prov_state + '</td></tr>';
  html += '<tr><td><b>Postal/ZIP: </b></td><td>' + P.postal + '</td></tr>';
  html += '<tr><td><b>Nationality: </b></td><td>' + P.nation + '</td></tr>';
  html += '<tr><td><b>Country of Residence: </b></td><td>' + P.ctryresid + '</td></tr>';
  html += '<tr><td><b>Notes: </b></td><td>' + P.note + '</td></tr>';
  html += '</table>';
  try {
    var cfields = JSON.parse(P.Cfields);
    P.Cfields = cfields;
  } catch (e) {
    //Already parsed or is invalid json
  }
  try {
    var briefings = JSON.parse(P.Briefings);
    P.Briefings = briefings;
  } catch (e) {
    //Already parsed or is invalid json
  }
  try {
    var contacts = JSON.parse(P.Contacts);
    P.Contacts = contacts;
  } catch (e) {
    //Already parsed or is invalid json
  }
  if (P.Cfields != null) {
    html += '<h3>' + iTrans('Additional Information') + '</h3>';
    html += PAX_Cfield_HTML(P.Cfields);
  }

  html += PAX_ShowBriefings(P);
  var C = P.Contacts;
  html += '<h3>Contacts</h3>';
  html +=
    '<table class="limit-table"><thead><tr><th>Contact</th><th>Relation</th><th>Phone</th><th>Email</th></tr></thead><tbody>';
  for (var i in C) {
    var I = C[i];
    if (I.DELETED == 1) continue;
    if (I.isPaxContact == 1) {
      I.Relation = 'Passenger';
      html +=
        '<tr class="yellowbg"><td>' +
        I.firstname +
        ' ' +
        I.middlename +
        ' ' +
        I.lastname +
        '</td><td>' +
        I.Relation +
        '</td><td>' +
        I.phone_num +
        '(' +
        I.phone_type +
        ')' +
        '</td><td>' +
        I.email +
        '</td></tr>';
    } else {
      html +=
        '<tr><td>' +
        I.firstname +
        ' ' +
        I.middlename +
        ' ' +
        I.lastname +
        '</td><td>' +
        I.Relation +
        '</td><td>' +
        I.phone_num +
        '(' +
        I.phone_type +
        ')' +
        '</td><td>' +
        I.email +
        '</td></tr>';
    }
  }
  html += '</tbody></tbale>';

  $('#PAX_Detail_HLDR').html(html);
  $('#PAX_Detail_HLDR').enhanceWithin();
}

function PAX_ShowBriefings(I) {
  var html = '<h3>' + iTrans('Briefings') + '</h3>';
  html +=
    '<table class="limit-table"><thead><tr><th>Form</th><th>Ident</th><th>AcType</th><th>Briefed</th><th>By</th><th>Expires</th><th>Client</th></tr></thead><tbody>';
  for (var i in I.Briefings) {
    var B = I.Briefings[i];
    html +=
      '<tr><td>' +
      B.title +
      '</td><td>' +
      B.AcIdent +
      '</td><td>' +
      B.AcType +
      '</td><td>' +
      B.created +
      'UTC</td><td>' +
      B.briefer +
      '</td><td>' +
      (B.expires == null ? 'Never' : B.expires.substr(0, 10)) +
      '</td><td>' +
      (PAX_CLIENT_LOOKUP[B.ClientPK] != undefined ? PAX_CLIENT_LOOKUP[B.ClientPK].Business : 'Unknown') +
      ' </td></tr>';
  }
  html += '</tbody></table>';
  return html;
}

var PAX_PaxDetails_return_ID = '';

function PAX_PaxDetails_return() {
  $.mobile.changePage($(PAX_PaxDetails_return_ID), {
    changeHash: false,
  });
  if (
    PAX_PaxDetails_return_ID == '#ITIN-INPUT' ||
    PAX_PaxDetails_return_ID == '#WB_Page2' ||
    PAX_PaxDetails_return_ID == '#BILLING_FlightDescriptionPage' ||
    PAX_PaxDetails_return_ID == '#BILLING_FlightLegsPage'
  ) {
    PAX_updateScrollPos();
  }
}

function ExchangePaxData(action, data, callback, showloading = true, extraVar = null, showErrors = true) {
  if (showloading) {
    $.mobile.loading('show', {
      theme: 'a',
    });
  }
  var json = JSON.stringify(data);
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-PaxManifesting-AJAX.php?action=' + action,
    data: { data: json },
  })
    .success(function (json_data) {
      $.mobile.loading('hide');
      if (json_data.length == 1) {
        if (json_data[0].ServerResponse != undefined) {
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed!',
              headerClose: false,
              buttonPrompt: 'There was a problem passenger data to the server ERROR: ' + json_data[0].Exception,
              buttons: {
                Close: {
                  click: function () {},
                },
              },
            });
            return;
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
          if (json_data[0].ServerResponse == 'Offline') {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Offline',
              headerClose: false,
              buttonPrompt: 'You have lost your internet connection.  Please try again when you are connected',
              buttons: {
                Close: {
                  click: function () {},
                },
              },
            });
            return;
          }
        }
      }
      if (callback != null) {
        if (extraVar != null) callback(json_data, extraVar);
        else callback(json_data);
      }
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      //console.log(data);
      if (!showErrors) {
        callback(false);
        return;
      }
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Failed!',
        headerClose: false,
        buttonPrompt:
          'There was a problem exchanging passenger data with the server.  Please try again. If this continues to happen please submit a bug report.',
        buttons: {
          Close: {
            click: function () {},
          },
          Report: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

//************************************* Itinerary Passenger Manifesting ***********************************

function PAX_Init_ITIN_Manifesting(leg = -1, updateScroll = true) {
  console.log('Showing Leg', leg);
  ITIN_Pax_Manifest.AdminView = 0;
  let html = `
    <div class="grid-row">
      <div class="grid-col-sm-12">
        <h2>${iTrans('Passenger Manifest')}</h2>
      </div>
    </div>`;

  html += `<div class="grid-row">
      <div class="grid-col-sm-6">
        <button 
        data-testid="pax-manifesting-load-manifest-new-itin"
        data-mini="true" 
        class="greenbg" 
        data-icon="plus" 
        onClick="PAX_ITIN_SelectManifest(${leg})">
          ${iTrans('Load Manifest')}
        </button>
      </div>
      <div class="grid-col-sm-6">
        <button data-mini="true" 
        class="greenbg" 
        data-icon="plus" 
        onClick="PAX_ITIN_AddPassenger(${leg})">
          ${iTrans('Add Passenger')}
        </button>
      </div>
    </div>`;

  html += PAX_ITIN_ShowDefaultManifest(ITIN_Pax_Manifest.CurLeg);
  if (ITIN_Pax_Manifest.FlightReport) {
    $('#QuotingDefaultPaxManifestHLDR').html('');
    $('#BMQL_MaxManifestHLDR').html('');
    $('#ItineraryPaxManifestHLDR').html('');
    $('#LegPasengerManifesting').html('');
    $('#EditItinPaxManifestHLDR').html('');
    $('#BILLING_ManifestingHLDR').html(html).enhanceWithin();
    if (ITIN_Pax_Manifest.CurLeg == -1) {
      $('#BILLING_FPpax').val(ITIN_Pax_Manifest.DefaultList.length);
    } else {
      for (var i in CURRENT_FLIGHT_REPORT.Legs) {
        let html = `
    <div class="grid-row">
      <div class="grid-col-sm-12">
        <h2>${iTrans('Passenger Manifest')}</h2>
      </div>
    </div>`;

        html += `<div class="grid-row">
      <div class="grid-col-sm-6">
        <button 
        data-testid="pax-manifesting-load-manifest-new-itin-report"
        data-mini="true" 
        class="greenbg" 
        data-icon="plus" 
        onClick="PAX_FR_SetCurLeg(${i});PAX_ITIN_SelectManifest(${i})">
          ${iTrans('Load Manifest')}
        </button>
      </div>
      <div class="grid-col-sm-6">
        <button data-mini="true" 
        class="greenbg" 
        data-icon="plus" 
        onClick="PAX_FR_SetCurLeg(${i});PAX_ITIN_AddPassenger(${i})">
          ${iTrans('Add Passenger')}
        </button>
      </div>
    </div>`;
        if (ITIN_Pax_Manifest.Pax_Legs[i] != undefined) {
          if (ITIN_Pax_Manifest.Pax_Legs[i].length > 0) {
            $('#FLPaxNum-' + i).val(ITIN_Pax_Manifest.Pax_Legs[i].length);
            BILLING_FLIGHTLEGS[i].PaxNum = ITIN_Pax_Manifest.Pax_Legs[i].length;
          }
        }
        html += PAX_ITIN_ShowDefaultManifest(i, true);
        $('#BILLING_ManifestingHLDR-' + i)
          .html(html)
          .enhanceWithin();
      }
    }
    if (updateScroll) PAX_updateScrollPos();
    return;
  } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
    if (leg == -1) {
      $('#ItineraryPaxManifestHLDR').html('');
      $('#LegPasengerManifesting').html('');
      $('#EditItinPaxManifestHLDR').html('');
      $('#BMQL_MaxManifestHLDR').html('');
      $('#QuotingDefaultPaxManifestHLDR').html(html).enhanceWithin();
    } else {
      $('#ItineraryPaxManifestHLDR').html('');
      $('#LegPasengerManifesting').html('');
      $('#EditItinPaxManifestHLDR').html('');
      $('#QuotingDefaultPaxManifestHLDR').html('');
      $('#BMQL_MaxManifestHLDR').html(html).enhanceWithin();
      try {
        $('#BMQL_PaxNum').val(ITIN_Pax_Manifest.Pax_Legs[leg].length);
      } catch (e) {
        $('#BMQL_PaxNum').val(0);
      }
    }

    if (updateScroll) PAX_updateScrollPos();
    return;
  } else {
    if (ITIN_Pax_Manifest.Editing) {
      $('#QuotingDefaultPaxManifestHLDR').html('');
      $('#BMQL_MaxManifestHLDR').html('');
      $('#ItineraryPaxManifestHLDR').html('');
      $('#LegPasengerManifesting').html('');
      $('#EditItinPaxManifestHLDR').html(html).enhanceWithin();
      if (updateScroll) PAX_updateScrollPos();
      return;
    }
    if (leg == -1) {
      $('#QuotingDefaultPaxManifestHLDR').html('');
      $('#BMQL_MaxManifestHLDR').html('');
      $('#EditItinPaxManifestHLDR').html('');
      $('#LegPasengerManifesting').html('');
      $('#ItineraryPaxManifestHLDR').html(html).enhanceWithin();
      if (ITIN_Pax_Manifest.DefaultList.length > 0) {
        $('#passenger-count').val(ITIN_Pax_Manifest.DefaultList.length);
      }
    } else {
      $('#QuotingDefaultPaxManifestHLDR').html('');
      $('#BMQL_MaxManifestHLDR').html('');
      $('#EditItinPaxManifestHLDR').html('');
      $('#EditItinPaxManifestHLDR').html('');
      $('#LegPasengerManifesting').html(html).enhanceWithin();
      if (ITIN_Pax_Manifest.Pax_Legs[leg] != undefined) {
        if (ITIN_Pax_Manifest.Pax_Legs[leg].length > 0) {
          $('#LegPassengerCount2').val(ITIN_Pax_Manifest.Pax_Legs[leg].length);
        } else {
          $('#LegPassengerCount2').val(0);
        }
      } else {
        $('#LegPassengerCount2').val(0);
      }
    }
    if (updateScroll) PAX_updateScrollPos();
  }
}

function PAX_FR_SetCurLeg(leg) {
  ITIN_Pax_Manifest.CurLeg = leg;
}

function PAX_ITIN_ShowDefaultManifest(leg = -1, PAX_FR_SetCurLeg = false) {
  let setLeg = '';
  if (PAX_FR_SetCurLeg) {
    setLeg = `PAX_FR_SetCurLeg(${leg});`;
  }

  let html = `
  <div class="grid-row">
  <div class="grid-col-sm-12">
  `;

  if (leg == -1) {
    var L = ITIN_Pax_Manifest.DefaultList;
  } else {
    var checkLeg = 0;
    while (checkLeg <= leg) {
      if (
        ITIN_Pax_Manifest.Pax_Legs[checkLeg] == undefined &&
        !ITIN_Pax_Manifest.Quote &&
        !ITIN_Pax_Manifest.Scheduler
      ) {
        if (ITIN_Pax_Manifest.Pax_Legs.length == 0 || ITIN_Pax_Manifest.Pax_Legs.length == undefined) {
          ITIN_Pax_Manifest.Pax_Legs[checkLeg] = JSON.parse(JSON.stringify(ITIN_Pax_Manifest.DefaultList));
        } else {
          ITIN_Pax_Manifest.Pax_Legs[checkLeg] = JSON.parse(JSON.stringify(ITIN_Pax_Manifest.Pax_Legs[checkLeg - 1]));
          console.log('addedMan', ITIN_Pax_Manifest.Pax_Legs[checkLeg]);
        }
      }
      checkLeg++;
    }

    var L = ITIN_Pax_Manifest.Pax_Legs[leg];
  }
  console.log('L', L);
  if (L == null) L = [];
  if (L.length <= 0) {
    return `${html}<h3>${iTrans('No Passengers in Manifest')}</h3></div></div>`;
  }

  const codeColumnHiddenClass = renderHiddenClassForPaxManColumn('code');
  const weightColumnHiddenClass = renderHiddenClassForPaxManColumn('weight');

  html += `<table class="limit-table first-td-fill">
               <thead>
                 <tr>
                   <th>${iTrans('Last First')} - [${iTrans('Company')}]</th>
                   <th class="pax-manifesting-code-column-header ${codeColumnHiddenClass}">${iTrans('Code')}</th>
                   <th class="pax-manifesting-weight-column-header ${weightColumnHiddenClass}">${iTrans('Weight')}</th>
                   <th>${iTrans('Safety Briefing')}</th>
                   <th>${iTrans('PU')}</th>
                   <th>${iTrans('Action')}</th>
                 </tr>
               </thead>
             <tbody>`;

  for (let i in L) {
    if (!L.hasOwnProperty(i)) {
      continue;
    }

    let I = L[i];

    if (I.PersonalUse == null) {
      I.PersonalUse = 0;
    }

    const puCheck = I.PersonalUse > 0 ? 'checked' : '';
    const vipClass = I.VIP > 0 ? 'class="vip-following"' : '';

    let PU = `<input type="checkbox" data-role="none" id="PAX_ITIN_PU-${i}" onClick="${setLeg}PAX_ITIN_UpdatePU(this,${i})" ${puCheck}/>`;
    let briefingBTN = `<div class="vertical-center-container">
          <button onclick="${setLeg}PAX_ITIN_Manifest_StartBriefing(${i});" data-mini="true" class="action-btn">
            <i class="fas fa-file"></i> 
          </button>
    
        </div>`;
    let actions = `<div class="vertical-center-container">
          <button onclick="${setLeg}PAX_ITIN_ManifestViewPaxContacts(${i});" data-mini="true" class="action-btn">
            <i class="fas fa-eye"></i> 
          </button>
          <button onclick="${setLeg}PAX_ITIN_ManifestRemovePax(${i});" data-mini="true" class="action-btn">
            <i class="fas fa-times"></i>
          </button>
        </div>`;

    var briefingStatus = PAX_ITIN_GetBriefingStatus(I);
    I.briefingStatus = briefingStatus;
    var briefingAction = '';
    if (briefingStatus.briefed) {
      briefingAction =
        '<button data-mini="true" class="greenbg" onClick="' +
        setLeg +
        'PAX_ITIN_ShowBriefingStatus(' +
        i +
        ');">' +
        briefingStatus.status +
        '</button>';
    } else {
      briefingAction =
        '<button class="redbg" data-mini="true" onClick="' +
        setLeg +
        'PAX_ITIN_ShowBriefingStatus(' +
        i +
        ');">' +
        briefingStatus.status +
        '</button>';
    }
    var companyTXT = '';
    if (I.company != '') {
      companyTXT = ' - [' + I.company + ']';
    }
    html += `<tr ${vipClass}>
              <td>${I.lastname}, ${I.firstname} ${I.middlename}${companyTXT}</td>
              <td class="pax-manifesting-code-column ${codeColumnHiddenClass}">${
      I.paxcode == null ? '[N/A]' : I.paxcode
    }</td>
              <td class="pax-manifesting-weight-column ${weightColumnHiddenClass}">${I.weight}</td>
              <td>${briefingAction}</td>
            <td>${PU}</td>
            <td>${actions}</td>
           </tr>`;
  }
  html += '</tbody></table>';
  return html + '</div></div>';
}

function PAX_ITIN_ShowBriefingStatus(i) {
  PAX_SaveScrollPos();
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e == false) {
      e = [];
    }
    PAX_CLIENT_LOOKUP = {};
    for (var a in e) {
      if (PAX_CLIENT_LOOKUP[e[a].ClientPK] != undefined) {
        continue;
      }
      PAX_CLIENT_LOOKUP[e[a].ClientPK] = e[a];
      if (PAX_CLIENT_LOOKUP[e[a].ClientPK].Business == 'Company') {
        PAX_CLIENT_LOOKUP[e[a].ClientPK].Business = 'All Company Flights';
      }
    }
    if (ITIN_Pax_Manifest.CurLeg == -1) {
      ITIN_Pax_Briefing = ITIN_Pax_Manifest.DefaultList[i].briefingStatus;
      ITIN_Pax_Briefing.DefaultListIndex = i;
      ITIN_Pax_Briefing.name =
        ITIN_Pax_Manifest.DefaultList[i].firstname + ' ' + ITIN_Pax_Manifest.DefaultList[i].lastname;
      if (ITIN_Pax_Manifest.Editing != true) {
        ITIN_Pax_Briefing.ClientPK = $('#ITIN_JOB option:selected').attr('data-clientpk');
      }

      if (ITIN_Pax_Manifest.DefaultList[i].filledForm != undefined) {
        ITIN_Pax_Briefing.briefedForm = ITIN_Pax_Manifest.DefaultList[i].filledForm;
      }
    } else {
      ITIN_Pax_Briefing = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][i].briefingStatus;
      ITIN_Pax_Briefing.DefaultListIndex = i;
      ITIN_Pax_Briefing.name =
        ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][i].firstname +
        ' ' +
        ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][i].lastname;
      if (ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][i].filledForm != undefined) {
        ITIN_Pax_Briefing.briefedForm = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][i].filledForm;
      }
    }
    PAX_doBriefing();
  });
}

function PAX_ITIN_GetBriefingStatus(I) {
  if (AC_data.briefingReq == 1) {
    var blankForms = PAX_ITIN_GetBlankBriefing();
    var acLock = AC_data.briefingLock;
    var briefed = false;
    var status = iTrans('Never Briefed');
    var form = null;
    var canSkip = true;
    if (blankForms.forms.length > 0) canSkip = false; //If forms available you can't skip.
    if (I.filledForm == undefined) I.filledForm = null;
    if (I.filledForm != null) {
      if (!Array.isArray(I.Briefings)) {
        I.Briefings = [];
      }
      I.Briefings.push(I.filledForm);
    }
    var now = new Date().toISOString().substr(0, 19).replace('T', ' ');

    for (var i in I.Briefings) {
      var B = I.Briefings[i];
      if (B.expires != null) {
        if (now >= B.expires) {
          B.isExpired = true;
        }
      }
      if (blankForms.client && B.ClientPK != ITIN_Pax_Manifest.ClientPK) continue;
      if (B.AcType == AC_data.AcType && acLock == 0 && B.isExpired == false) {
        briefed = true;
        status = iTrans('Expires') + ' ' + (B.expires == null ? 'Never' : B.expires.substr(0, 10));
        form = B;
        canSkip = false;
      } else if (B.AcIdent == AC_data.ident && acLock == 1 && B.isExpired == false) {
        briefed = true;
        status = iTrans('Expires') + ' ' + (B.expires == null ? 'Never' : B.expires.substr(0, 10));
        form = B;
        canSkip = false;
      } else if (B.List_Type == AC_data.List_Type && acLock == 2 && B.isExpired == false) {
        briefed = true;
        status = iTrans('Expires') + ' ' + (B.expires == null ? 'Never' : B.expires.substr(0, 10));
        form = B;
        canSkip = false;
      } else if (
        (B.AcType == AC_data.AcType && acLock == 0 && B.isExpired == true) ||
        (B.AcIdent == AC_data.ident && acLock == 1 && B.isExpired == true)
      ) {
        if (!briefed) {
          status = iTrans('Expired') + ' ' + B.expires.substr(0, 10);
          canSkip = false;
        }
      }
    }
    return {
      status: status,
      briefed: briefed,
      required: true,
      briefedForm: form,
      forms: blankForms.forms,
      canSkip: canSkip,
    };
  } else {
    return {
      status: iTrans('Not Required'),
      briefed: false,
      required: false,
      briefedForm: null,
      forms: [],
      canSkip: true,
    };
  }
}

function PAX_ITIN_GetBlankBriefing() {
  var company = [];
  var client = [];
  for (var i in AC_data.BriefingForms) {
    var I = AC_data.BriefingForms[i];
    if (I.ClientPK == 0) {
      company.push(JSON.parse(JSON.stringify(I)));
    } else if (ITIN_Pax_Manifest.ClientPK == I.ClientPK) {
      client.push(JSON.parse(JSON.stringify(I)));
    }
  }
  if (client.length > 0) return { forms: client, client: true };
  else return { forms: company, client: false };
}

function PAX_ITIN_UpdatePU(e, index) {
  if (ITIN_Pax_Manifest.CurLeg == -1) {
    ITIN_Pax_Manifest.DefaultList[index].PersonalUse = e.checked ? 1 : 0;
    if (ITIN_Pax_Manifest.Scheduler && GROUP_DATA.Pax_Man == 1) {
      //todo send default manifest to be saved
      ExchangeQuoteData(
        'UpdateSCH_Manifest',
        { DefaultList: ITIN_Pax_Manifest.DefaultList, AC_EventPK: ITIN_Pax_Manifest.AC_EVENT.ItemPrimaryKey },
        function (data) {
          console.log(data);
        },
        true
      );
    }
  } else {
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][index].PersonalUse = e.checked ? 1 : 0;
  }
}

function PAX_ITIN_ManifestViewPaxContacts(index) {
  PAX_SaveScrollPos();
  if (ITIN_Pax_Manifest.CurLeg == -1) {
    var C = ITIN_Pax_Manifest.DefaultList[index];
  } else {
    var C = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][index];
  }
  if (ITIN_Pax_Manifest.FlightReport) {
    if (ITIN_Pax_Manifest.CurLeg == -1) {
      PAX_PaxDetails_return_ID = '#BILLING_FlightDescriptionPage';
    } else {
      PAX_PaxDetails_return_ID = '#BILLING_FlightLegsPage';
    }
  } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
    if (ITIN_Pax_Manifest.CurLeg == -1) {
      PAX_PaxDetails_return_ID = '#BILLING_AddQuotingLegs';
    } else {
      PAX_PaxDetails_return_ID = '#BILLING_EditQuotingLegs';
    }
  } else {
    if (ITIN_Pax_Manifest.Editing) {
      PAX_PaxDetails_return_ID = '#Edit_Itin_Page';
    } else {
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        PAX_PaxDetails_return_ID = '#ITIN-INPUT';
      } else {
        PAX_PaxDetails_return_ID = '#WB_Page2';
      }
    }
  }

  CUR_PASSENGER = C;
  PAX_DetailHTML(C);
}

function PAX_ITIN_ManifestRemovePax(index) {
  if (ITIN_Pax_Manifest.CurLeg == -1) {
    ITIN_Pax_Manifest.DefaultList.splice(index, 1);
  } else {
    try {
      var seatPos = ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][index].seatPos;
      if (seatPos != '' && seatPos != undefined) {
        $('#' + seatPos + '2_NAME').html('');
        $('#' + seatPos + '2')
          .click()
          .checkboxradio('refresh');
        emptySeatWeight2();
      }
    } catch (e) {
      console.log(e);
    }
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg].splice(index, 1);
  }
  PAX_SaveScrollPos();
  PAX_Init_ITIN_Manifesting(ITIN_Pax_Manifest.CurLeg, true);
  if (ITIN_Pax_Manifest.Editing && GROUP_DATA.Pax_Man == 1 && ITIN_Pax_Manifest.Scheduler) {
    ExchangeQuoteData(
      'UpdateSCH_Manifest',
      { DefaultList: ITIN_Pax_Manifest.DefaultList, AC_EventPK: ITIN_Pax_Manifest.AC_EVENT.ItemPrimaryKey },
      function (data) {
        console.log(data);
      },
      true
    );
  }
}

function PAX_ITIN_SelectManifest(leg) {
  PAX_LoadPassengerList();
  PAX_SaveScrollPos();
  $('#PAX_Manifests_List').html('<h4>Loading List...</h4>');
  ITIN_Pax_Manifest.CurLeg = leg;
  PAX_MANIFESTING.AddingManifest = true;
  PAX_MANIFESTING.FromManifest = true;
  if (ITIN_Pax_Manifest.FlightReport) {
    if (leg == -1) {
      PAX_MANIFESTING.returnTo = '#BILLING_FlightDescriptionPage';
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    } else {
      PAX_MANIFESTING.returnTo = '#BILLING_FlightLegsPage';
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
    }
  } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
    if (ITIN_Pax_Manifest.CurLeg == -1) {
      PAX_MANIFESTING.returnTo = '#BILLING_AddQuotingLegs';
    } else {
      PAX_MANIFESTING.returnTo = '#BILLING_EditQuotingLegs';
    }
    if (leg == -1) {
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    } else {
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
    }
  } else {
    if (leg == -1) {
      if (ITIN_Pax_Manifest.Editing) {
        PAX_MANIFESTING.returnTo = '#Edit_Itin_Page';
      } else {
        PAX_MANIFESTING.returnTo = '#ITIN-INPUT';
      }
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    } else {
      if (ITIN_Pax_Manifest.Editing) {
        PAX_MANIFESTING.returnTo = '#Edit_Itin_Page';
      } else {
        PAX_MANIFESTING.returnTo = '#WB_Page2';
      }
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
    }
  }

  $.mobile.changePage($('#PAX_Manifests'), {
    changeHash: false,
  });

  PAX_LoadManifestList();
}

function PAX_ITIN_AddPassenger(leg) {
  PAX_SaveScrollPos();
  $('#PAX_ManifestList').html('<h4>Loading List...</h4>');

  PAX_MANIFESTING.FromManifest = false;
  ITIN_Pax_Manifest.CurLeg = leg;
  if (ITIN_Pax_Manifest.FlightReport) {
    if (leg == -1) {
      PAX_MANIFESTING.returnTo = '#BILLING_FlightDescriptionPage';
      PAX_MANIFESTING.AddingPax = true;
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    } else {
      PAX_MANIFESTING.returnTo = '#BILLING_FlightLegsPage';
      PAX_MANIFESTING.AddingPax = true;
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
    }
  } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
    PAX_MANIFESTING.returnTo = '#BILLING_EditQuoteingLegs';
    if (leg == -1) {
      PAX_MANIFESTING.AddingPax = true;
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    } else {
      PAX_MANIFESTING.AddingPax = true;
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
    }
  } else {
    if (leg == -1) {
      if (ITIN_Pax_Manifest.Editing) {
        PAX_MANIFESTING.returnTo = '#Edit_Itin_Page';
      } else {
        PAX_MANIFESTING.returnTo = '#ITIN-INPUT';
      }
      PAX_MANIFESTING.AddingPax = true;
      PAX_MANIFESTING.AddingManifestTo = 'Itinerary';
    } else {
      if (ITIN_Pax_Manifest.Editing) {
        PAX_MANIFESTING.returnTo = '#Edit_Itin_Page';
      } else {
        PAX_MANIFESTING.returnTo = '#WB_Page2';
      }
      PAX_MANIFESTING.AddingPax = true;
      PAX_MANIFESTING.AddingManifestTo = 'Leg';
    }
  }

  $.mobile.changePage($('#PAX_Manifesting'), {
    changeHash: false,
  });

  paxManTableViewOptionsInit();
  PAX_Populate_ClientList(ITIN_Pax_Manifest.ClientPK == 0 ? null : ITIN_Pax_Manifest.ClientPK, false);
  cirroDB.query('Passengers', 'PrimaryKey != ? ORDER BY lastname ASC', ['-99999'], PAX_LoadList);
}

function PAX_GetPaxListHTML(Manifest, returnDIV) {
  var defaultList = Manifest.DefaultList;
  var Pax_Legs = Manifest.Pax_Legs;
  PAX_MANIFESTING.CUR_MANIFEST.Passengers = Manifest;
  var html = '';
  if (defaultList.length == 0) {
    html += '<h4>No Passengers Added to Default Manifest</h4>';
  } else {
    html += '<h4>Default Passenger List</h4>';
    html +=
      '<table class="limit-table first-td-fill" id="PAX_MAN_DefaultList"><thead><tr><th>Name</th><th>Briefing Status</th><th>Action</th></tr></thead><tbody>';
    for (var i in defaultList) {
      var I = defaultList[i];
      var actions =
        '<div class="vertical-center-container"><button onclick="PAX_ManifestViewPaxContacts_Admin(' +
        i +
        ",-1,'#" +
        returnDIV +
        '\');" data-mini="true" class="action-btn"><img src="./images/inspect.svg"></button></div>';

      var briefingStatus = PAX_ITIN_GetBriefingStatus(I);
      I.briefingStatus = briefingStatus;
      var briefingAction = '';
      if (briefingStatus.briefed) {
        briefingAction =
          '<button data-mini="true" class="greenbg" onClick="PAX_ITIN_ShowBriefingStatus(' +
          i +
          ');">' +
          briefingStatus.status +
          '</button>';
      } else {
        briefingAction =
          '<button class="redbg" data-mini="true" onClick="PAX_ITIN_ShowBriefingStatus(' +
          i +
          ');">' +
          briefingStatus.status +
          '</button>';
      }

      html +=
        '<tr class="' +
        (I.VIP == 1 ? 'vip-following' : '') +
        '"><td>' +
        I.firstname +
        ' ' +
        I.lastname +
        '</td><td>' +
        briefingAction +
        '</td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</tbody></table>';
  }

  if (Pax_Legs.length == 0) {
    html += '<h4>No Passengers Added to Legs</h4>';
  } else {
    for (var l in Pax_Legs) {
      var legList = Pax_Legs[l];
      html += '<h4>Leg#' + (parseInt(l) + 1) + ' Passenger List</h4>';
      html +=
        '<table class="limit-table first-td-fill" id="PAX_MAN_legList"><thead><tr><th>Name</th><th>Briefing Status</th><th>Action</th></tr></thead><tbody>';
      for (var i in legList) {
        var I = legList[i];
        var actions =
          '<div class="vertical-center-container"><button onclick="PAX_ManifestViewPaxContacts_Admin(' +
          i +
          ',' +
          l +
          ",'#" +
          returnDIV +
          '\');" data-mini="true" class="action-btn"><img src="./images/inspect.svg"></button></div>';

        var briefingStatus = PAX_ITIN_GetBriefingStatus(I);
        I.briefingStatus = briefingStatus;
        var briefingAction = '';
        if (briefingStatus.briefed) {
          briefingAction =
            '<button data-mini="true" class="greenbg" onClick="PAX_ITIN_ShowBriefingStatus(' +
            i +
            ');">' +
            briefingStatus.status +
            '</button>';
        } else {
          briefingAction =
            '<button class="redbg" data-mini="true" onClick="PAX_ITIN_ShowBriefingStatus(' +
            i +
            ');">' +
            briefingStatus.status +
            '</button>';
        }

        html +=
          '<tr class="' +
          (I.VIP == 1 ? 'vip-following' : '') +
          '"><td>' +
          I.firstname +
          ' ' +
          I.lastname +
          '</td><td>' +
          briefingAction +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</tbody></table>';
    }
  }

  return html;
  setTimeout(function () {
    AddTableSorter('PAX_MAN_DefaultList', [[0, 0]]);
    AddTableSorter('PAX_MAN_legList', [[0, 0]]);
  }, 500);
}

function PaxCfieldsReturn() {
  $.mobile.changePage($('#PAX_Briefing_Admin'), {
    changeHash: false,
  });
  BILLING_RETURN_TO = '';
  $('#BILLING_CF_USEON_ALLTXT').html(iTrans('ALL'));
  $('#BILLING_CF_USEON_FRTXT').html(iTrans('Flight Reports'));
  $('#BILLING_CF_USEON_ITINTXT').html(iTrans('Itinerary'));
}

function PaxCfields() {
  $.mobile.changePage($('#PAX_PaxCfieldsPage'), {
    changeHash: false,
  });

  BILLING_RETURN_TO = 'Passenger'; //global var in billing.js
  $('#BILLING_CF_USEON_ALLTXT').html(iTrans('ALL'));
  $('#BILLING_CF_USEON_FRTXT').html(iTrans('Passengers'));
  $('#BILLING_CF_USEON_ITINTXT').html(iTrans('Manifests'));
  $('#BILLING_CF_REQ_ITINTXT').html(iTrans('Required for Manifests'));
  $('#BILLING_CF_REQTXT').html(iTrans('Required for Passengers'));

  PAX_getCfields();
}

function PAX_getCfields() {
  $.getJSON(BASEURL + 'menu-PaxManifesting-AJAX.php?action=getPaxCfields', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        alert('<h1>' + iTrans('Offline Cannot Get Invoice Data') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'BILLING_GETINVOICE');
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
      ADMIN_CFIELDS = json_data[0].Cfields;
      Pax_LoadCfields();
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function Pax_LoadCfields() {
  var CF = ADMIN_CFIELDS;
  if (CF.length > 0) {
    var html =
      "<table  id='BIT_Admin7' class='first-td-fill'><thead><tr><th>" +
      iTrans('Preview') +
      '</th><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Collected') +
      '</th><th>' +
      iTrans('Req') +
      '</th><th>' +
      iTrans('Action') +
      '</th><th>' +
      iTrans('Sort Order') +
      '</th></tr></thead><tbody>';
    for (var i in CF) {
      var I = CF[i];
      var Collected = iTrans('Man & Pax');
      if (I.usedFor == 'Pax') {
        Collected = iTrans('Pax');
      } else if (I.usedFor == 'Man') {
        Collected = iTrans('Man');
      }
      if (I.PerLeg == 1) {
        Collected += ' (' + iTrans('Per Leg') + ')';
      }
      var Req = iTrans('No');
      if (I.reqMan == 1) {
        Req = iTrans('Man');
      }
      if (I.reqPax == 1) {
        Req = iTrans('Pax');
      }
      if (I.req == 1 && I.reqItin == 1) {
        Req = iTrans('All');
      }
      var Preview = createPaxManCustomField(I, I.Value, 'Pax');
      var Button =
        '<div class="vertical-center-container"><button onclick="BILLING_EditClientCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/edit.svg"></button><button onclick="PAX_RemoveBriefingCF(' +
        i +
        ', true)" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/delete.svg"></button></div>';

      var SortOrder =
        '<div class="vertical-center-container"><button class="action-btn ui-btn" data-mini="true" onclick="ADMIN_CF_SortOrder_UP(' +
        i +
        ')"><img src="./images/Plus-icon.png" widht="20px" height="20px"></button><button class="action-btn" data-mini="true" onclick="ADMIN_CF_SortOrder_DOWN(' +
        i +
        ')"><img src="./images/Minus-icon.png" widht="20px" height="20px"></button></div>';

      html +=
        '<tr><td>' +
        Preview +
        '</td><td>' +
        I.Label +
        '</td><td>' +
        I.type +
        '</td><td>' +
        Collected +
        '</td><td>' +
        Req +
        '</td><td>' +
        Button +
        '</td><td>' +
        SortOrder +
        '</td></tr>';
    }
    html += '</tbody></table>';
    $('#PAX_Cfields_HLDR').html(html);
  } else {
    $('#PAX_Cfields_HLDR').html('<h3>' + iTrans('No Custom Fields Found') + '</h3>');
  }
  $('#PAX_Cfields_HLDR').enhanceWithin();
}

function PAX_AddCompanyCF() {
  $('#BILLING_CfieldItems_HLDR').hide();
  $('#PAX_CfieldItems_HLDR').show();

  $.mobile.changePage($('#BILLING_NewClientCFaddPage'), {
    changeHash: false,
  });

  $('#BILLING_CANCEL_CF').removeAttr('onclick');
  $('#BILLING_CANCEL_CF').unbind('click');
  $('#BILLING_CANCEL_CF').click(PaxCancelCF);
  ADMIN_BILLING_FIELDS = true;

  BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a client;
  BILLING_CLIENT_CFIELD.PrimaryKey = 0;
  BILLING_CLIENT_CFIELD.type = '';
  BILLING_CLIENT_CFIELD.options = [];
  BILLING_CLIENT_CFIELD.Bill_Item = 0;
  BILLING_CLIENT_CFIELD.Bill_Item_options = '';
  $('#BILLING_CF_CHK_OPTIONS_HLDR').hide();
  $('#BILLING_CF_BILLITEM').prop('checked', false);
  $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
  BILLING_BILLITEM({ checked: false });
  //we're here at the page now what?  resetl inputs
  $('#BILLING_CF_Label').val('');
  $('#BILLING_CF_TYPE').val(null).selectmenu('refresh');

  $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_PH').val('');

  $('#BILLING_CF_REQ').prop('checked', false);
  $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_REQ_ITIN').prop('checked', false);
  $('#BILLING_CF_REQ_ITIN').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_HIDEDEFAULT').prop('checked', false);
  $('#BILLING_CF_HIDEDEFAULT').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_PERLEG').prop('checked', false);
  $('#BILLING_CF_PERLEG').checkboxradio('refresh');

  $('#ClientCustomField').html('');
}

function returnPaxBICF() {
  $('#BILLING_CANCEL_CF').unbind('click', PaxCancelCF);
  $('#BILLING_CANCEL_CF').click(PaxCfieldsReturn);
  $.mobile.changePage($('#BIoptions'), {
    reverse: true,
    changeHash: false,
  });
  cirroDB.query('BillingClient', 'clientPK = ?', 0, function (json_data) {
    BI_display(json_data[0].data);
  });
}

function PaxCancelCF() {
  $.mobile.changePage($('#PAX_PaxCfieldsPage'), {
    reverse: true,
    changeHash: false,
  });
  $('#BILLING_CANCEL_CF').unbind('click');
  $('#BILLING_CANCEL_CF').click(BILLING_ClientCFreturn);
}

function populateCfields(Cfields, type) {
  var newCfields = [];
  for (var i in Cfields) {
    var I = Cfields[i];
    if (I.usedFor == type || I.usedFor == 'ALL') {
      newCfields.push(I);
    }
  }
  return newCfields;
}

function createPaxManCustomField(f, value = false, type, id = 'PAX') {
  //console.log(f);
  //type 1 == Pax, type 2 == ITIN_Brief
  var otype = type;
  if (type == 'Pax') otype = 1;
  else otype = 2;
  var fdefault = f.default;
  if (value !== false) {
    fdefault = value;
  }
  var html = '';
  var options = JSON.parse(f.options);
  var REQ = '';
  var REQ_IND = '';
  if ((f.reqMan == 1 && type == 'Man') || (f.reqPax == 1 && type == 'Pax') || (f.req == 1 && type == 'Brief')) {
    REQ = iTrans('Required');
    REQ_IND = '<b style="color:red;"> *</b>';
  }

  //Per report items
  switch (f.type) {
    case 'text':
      html += '<label for="' + id + '_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
      html +=
        '<input type="text" maxlength="' +
        options.maxlength +
        '" id="' +
        id +
        '_CF_' +
        f.PrimaryKey +
        '" onChange="BillingValidateCF(this)" value="' +
        escapeHtml(fdefault) +
        '" placeholder="' +
        escapeHtml(f.Placeholder) +
        '" data-mini="true" name="' +
        REQ +
        '" />';
      break;
    case 'number':
      html += '<label for="' + id + '_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
      html +=
        '<input type="number" max="' +
        options.max +
        '" min="' +
        options.min +
        '" id="' +
        id +
        '_CF_' +
        f.PrimaryKey +
        '" onChange="BillingValidateCF(this)" value="' +
        fdefault +
        '" data-mini="true" name="' +
        REQ +
        '" />';
      break;
    case 'checkbox':
      html += '<label for="' + id + '_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
      if (fdefault == 1) {
        html += '<input type="checkbox" id="' + id + '_CF_' + f.PrimaryKey + '" data-mini="true" name="" checked/>';
      } else {
        html += '<input type="checkbox" id="' + id + '_CF_' + f.PrimaryKey + '" data-mini="true" name="" />';
      }
      break;
    case 'select':
      html += '<label for="' + id + '_CF_' + f.PrimaryKey + '" >' + f.Label + REQ_IND + '</label>';
      html +=
        '<select id="' + id + '_CF_' + f.PrimaryKey + '" onChange="BillingValidateCF(this)" data-mini="true" name="" >';
      if (f.Placeholder != '') {
        html += '<option value="" disabled selected hidden>' + escapeHtml(f.Placeholder) + '</option>';
      }

      for (var i in options) {
        if (fdefault == options[i].value) {
          html +=
            '<option value="' +
            escapeHtml(options[i].value) +
            '" selected>' +
            escapeHtml(options[i].text) +
            '</option>';
        } else {
          html += '<option value="' + escapeHtml(options[i].value) + '">' + escapeHtml(options[i].text) + '</option>';
        }
      }
      html += '</select>';
      break;
    case 'signature':
      Cfield_SignatureStatus;
      html += '<div style="display: inline-block;">';
      if (value == undefined) {
        value = '';
      }
      html += '<input type="hidden" id="' + id + '_CF_' + f.PrimaryKey + '" value="' + escapeHtml(value) + '" />';
      html += '<label>' + f.Label + REQ_IND + '</label>';
      html += '<p class="wrap">' + options.SignatureDescription + '</p>';
      var SignatureHTML = '';
      if (value != '' && value != 'false') {
        value = JSON.parse(value);
        SignatureHTML +=
          '<div style="display: inline-block;"><img src="' + value.data + '" width="321px" height="150px"/><br />';
        SignatureHTML +=
          '<table class="first-td-fill" style="width: 321px;""><tr><td><center><b>' +
          value.SignatureName +
          '</b></center></td><td><button onClick="BILLING_REMOVE_SIGNATURE(' +
          f.PrimaryKey +
          ', false,' +
          otype +
          ');" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></td></tr></table></div>';
      } else {
        SignatureHTML =
          '<button class="yellowbg" style="width: 120px;" data-mini="true" id="' +
          id +
          '_CF_SIGN_' +
          f.PrimaryKey +
          '" onClick="BILLING_CAPTURE_SIGNATURE(' +
          f.PrimaryKey +
          ', false,' +
          otype +
          ');">' +
          iTrans('Sign Now') +
          '</button>';
      }
      html += '<div id="' + id + '_CF_SIGN_' + f.PrimaryKey + '" >' + SignatureHTML + '</div>';
      html += '</div>';

      break;
  }
  return html;
}

function savePaxCfields(C, id = 'PAX') {
  var DisableNext = false;
  if (C.Cfields.length > 0) {
    for (var i in C.Cfields) {
      var I = C.Cfields[i];
      var checked = 0;
      if (I.type == 'checkbox') {
        if ($('#' + id + '_CF_' + I.PrimaryKey).is(':checked')) {
          checked = 1;
        }
        I.Value = checked;
        if ((I.reqMan == 1 && checked == 0 && id == 'MAN') || (I.reqPax && checked == 0 && id == 'PAX')) {
          BillingValidation('[' + I.Label + '] is required to be checked.');
          DisableNext = true;
        }
      } else if (I.type == 'signature') {
        if ((I.reqMan == 1 && id == 'MAN') || (I.reqPax == 1 && id == 'PAX')) {
          var hasValue = false;
          try {
            if (I.Value == '' || I.Value == undefined) {
              hasValue = false;
            } else {
              hasValue = true;
            }
          } catch (e) {
            hasValue = false;
          }
          var hasSig = false;
          try {
            var sigData = JSON.parse($('#' + Cfield_SignatureStatus.CfieldID).val());
            if (sigData.data.length == 0 || sigData.data == undefined) {
              BillingValidation('[' + I.Label + '] is required.');
              DisableNext = true;
            } else {
              hasSig = true;
              I.Value = JSON.stringify(sigData);
            }
          } catch (e) {
            hasSig = false;
          }
          if (!hasValue && !hasSig) {
            BillingValidation('[' + I.Label + '] is required.');
            DisableNext = true;
          }
        }
      } else {
        I.Value = $('#' + id + '_CF_' + I.PrimaryKey).val();
        if (I.Value == undefined) {
          I.Value = '';
        }
        if (
          (I.Value == '' && I.reqMan == 1 && id == 'MAN') ||
          (I.Value == null && I.reqMan == 1 && id == 'MAN') ||
          (I.Value == ' ' && I.reqMan == 1 && id == 'MAN') ||
          (I.Value == '' && I.reqPax == 1 && id == 'PAX') ||
          (I.Value == null && I.reqPax == 1 && id == 'PAX') ||
          (I.Value == ' ' && I.reqPax == 1 && id == 'PAX')
        ) {
          BillingValidation('[' + I.Label + '] is a required field and is empty.');
          DisableNext = true;
        }
      }
    }
  }
  return DisableNext;
}

function PaxBriefingReturn(data, promptSave = false) {
  if (PAX_BRIEFING_FORM != null) {
    var I = PAX_BRIEFING_FORM;
    if (I.index == undefined) I.index = 0;
    if (I.Cfields.length == 0) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('You must add at least 1 briefing item to this form.'),
        buttons: {
          [iTrans('Delete This Briefing')]: {
            click: function () {
              PAX_SYNC_REQUIRED = true;
              ExchangePaxData('deleteBriefingForm', BRIEFING_FORMS[I.index], PAX_DeleteBriefingForm_DELETED, true);
              PAX_BRIEFING_FORM = null;
            },
          },
          Close: {
            click: function () {
              //do Nothing
            },
          },
        },
      });
      return;
    }
    if (promptSave) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('WARNING'),
        headerClose: false,
        buttonPrompt: iTrans('Any unsaved changes will be lost.'),
        buttons: {
          [iTrans('Exit Without Saving')]: {
            click: function () {
              ITIN_Pax_Manifest.scrollTo = 0;
              PAX_BRIEFING_FORM = null;
              $('#PAX_Hide_for_Briefing').show();
              $('#BILLING_CF_REQ_ITINTXT').html(iTrans('Required on Itinerary'));
              $('#PAX_BRIEFING_Cfield_ClientSelector').html('');
              PAX_ShowBriefFormList();
            },
          },
          [iTrans('Cancel')]: {
            click: function () {},
          },
        },
      });
    } else {
      ITIN_Pax_Manifest.scrollTo = 0;
      PAX_BRIEFING_FORM = null;
      $('#PAX_Hide_for_Briefing').show();
      $('#BILLING_CF_REQ_ITINTXT').html(iTrans('Required on Itinerary'));
      $('#PAX_BRIEFING_Cfield_ClientSelector').html('');
      PAX_ShowBriefFormList();
    }
  } else {
    ITIN_Pax_Manifest.scrollTo = 0;
    PaxBriefingOptions();
  }
}

var PAX_CUR_AIRCRAFT = null;
var PAX_BRIEFING_FORM = null;
var BRIEFING_FORMS = [];
var PAX_CLIENT_LOOKUP = {};
function TEST_Briefing() {
  AC = {
    AcType: 'Bell 206L',
    ident: 'C-FBHM',
    briefingLock: 0,
  };
  PAX_CUR_AIRCRAFT = AC;
  PAX_EditBriefing();
}

var BRIEFING_AC_DATA = [];
function PAX_DisplayAircraft() {
  var html = '<b>Note: </b> Any changes to the briefing forms will cause all existing briefings to become expired!';
  $('#PAX_Briefing_AircraftList')
    .html('<h3>' + iTrans('Retreiving Aircraft Data') + '</h3>')
    .enhanceWithin();
  $.getJSON(BASEURL + 'menu-Maintenance-AJAX.php?action=GetTable&table=KFM_WB_AC', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'PAX_DisplayAircraft');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      BRIEFING_AC_DATA = json_data;
      html +=
        '<table class="limit-table last-td-fill" data-filter="true"><thead><tr><th>' +
        iTrans('Ident') +
        '</th><th>' +
        iTrans('Type') +
        '</th><th>' +
        iTrans('Series') +
        '</th><th>' +
        iTrans('Forms') +
        '</th><th>' +
        iTrans('Briefing Req') +
        '</th><th>' +
        iTrans('Forms Avail') +
        '</th><th>' +
        iTrans('Actions') +
        '</th></tr></thead><tbody>';
      for (var i in BRIEFING_AC_DATA) {
        var I = BRIEFING_AC_DATA[i];
        var action =
          '<div class="vertical-center-container"><button data-mini="true" class="action-btn" onClick="PAX_EditBriefing_Select(' +
          i +
          ')" ' +
          (I.briefingReq == 1 ? '' : 'disabled') +
          '><img src="./images/edit.svg"></button></div>';
        var select =
          '<select data-role="none" id="PAX_AC-briefingLock-' +
          i +
          '" onChange="PAX_EditBriefing_Lock(' +
          i +
          ', this)" ' +
          (I.briefingReq == 1 ? '' : 'disabled') +
          '>';
        select +=
          '<option value="1" ' + (I.briefingLock == 1 ? 'selected' : '') + '>' + iTrans('This Ident') + '</option>';
        select +=
          '<option value="0" ' + (I.briefingLock == 0 ? 'selected' : '') + '>' + iTrans('A/C Type') + '</option>';
        select +=
          '<option value="2" ' + (I.briefingLock == 2 ? 'selected' : '') + '>' + iTrans('A/C Series') + '</option>';
        select += '</select>';
        html +=
          '<tr><td>' +
          I.ident +
          '</td><td>' +
          I.AcType +
          '</td><td>' +
          I.List_Type +
          '</td><td>' +
          I.Forms.length +
          '</td><td><input onClick="PAX_EditBriefing_Req(' +
          i +
          ', this)" type="checkbox" id="PAX_AC-briefingReq-' +
          i +
          '" ' +
          (I.briefingReq == 1 ? 'checked' : '') +
          ' /></td><td>' +
          select +
          '</td><td>' +
          action +
          '</td></tr>';
      }
      html += '</tbody></table>';
      $('#PAX_Briefing_AircraftList').html(html).enhanceWithin();
      PAX_updateScrollPos();
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function PAX_EditBriefing_Req(index, e) {
  PAX_SaveScrollPos();
  PAX_CUR_AIRCRAFT = BRIEFING_AC_DATA[index];
  PAX_SYNC_REQUIRED = true;
  ExchangePaxData(
    'updateAcReq',
    { PK: PAX_CUR_AIRCRAFT.PrimaryKey, state: e.checked ? 1 : 0 },
    PAX_DisplayAircraft,
    true
  );
}

function PAX_EditBriefing_Lock(index, e) {
  PAX_SaveScrollPos();
  PAX_CUR_AIRCRAFT = BRIEFING_AC_DATA[index];
  PAX_SYNC_REQUIRED = true;
  ExchangePaxData('updateAcLock', { PK: PAX_CUR_AIRCRAFT.PrimaryKey, state: e.value }, PAX_DisplayAircraft, true);
}

function PAX_EditBriefing_Select(index) {
  PAX_CUR_AIRCRAFT = BRIEFING_AC_DATA[index];
  PAX_EditBriefing();
}

var PAX_SYNC_REQUIRED = false;
function PAX_ExitAdminEdits() {
  if (PAX_SYNC_REQUIRED) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Sync Required'),
      headerClose: false,
      buttonPrompt: iTrans('The changes made require a sync.'),
      buttons: {
        [iTrans('Sync Now')]: {
          click: function () {
            restartCirro();
          },
        },
        [iTrans('Exit Without Syncing')]: {
          click: function () {
            VueBridge.enterCorrectAdminToolMenu();
          },
        },
      },
    });
  } else {
    VueBridge.enterCorrectAdminToolMenu();
  }
}

function PAX_EditBriefing(newForm = 0) {
  $.mobile.changePage($('#PAX_PaxBriefingsPage'), {
    reverse: true,
    changeHash: false,
  });
  var AC = PAX_CUR_AIRCRAFT;

  BillingDB('SELECT', 'JobsAll', null, function (e) {
    if (e == false) {
      e = [];
    }
    PAX_CLIENT_LOOKUP = {};
    for (var i in e) {
      if (PAX_CLIENT_LOOKUP[e[i].ClientPK] != undefined) {
        continue;
      }
      PAX_CLIENT_LOOKUP[e[i].ClientPK] = e[i];
      if (PAX_CLIENT_LOOKUP[e[i].ClientPK].Business == 'Company') {
        PAX_CLIENT_LOOKUP[e[i].ClientPK].Business = 'All Company Flights';
      }
    }
    $.getJSON(
      BASEURL +
        'menu-PaxManifesting-AJAX.php?action=getPaxCfields_Briefing&ident=' +
        encodeURIComponent(AC.ident) +
        '&AcType=' +
        encodeURIComponent(AC.AcType) +
        '&List_Type=' +
        encodeURIComponent(AC.List_Type) +
        '&briefingLock=' +
        AC.briefingLock +
        '&makeNew=' +
        newForm,
      function (json_data) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Invoice Data') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') {
            ServerResponse(json_data[0], 'BILLING_GETINVOICE');
          }
          if (json_data[0].ServerResponse == 'Not Logged In') {
            NOTLOGGEDIN();
          }
          BRIEFING_FORMS = json_data[0].Forms;
          if (newForm == 1 || json_data[0].CreatedNew) {
            BRIEFING_FORMS[BRIEFING_FORMS.length - 1].index = BRIEFING_FORMS.length - 1;
            PAX_LoadBriefingForm(BRIEFING_FORMS.length - 1);
          } else {
            PAX_ShowBriefFormList();
          }
        }
      }
    ).fail(function (jqXHR, status, error) {
      AJAXFAIL(error);
    });
  });
}

function PAX_ShowBriefFormList() {
  var html = '<h3>' + iTrans('Select Briefing Form to Edit') + '</h3>';

  html +=
    '<table class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Owned By') +
    '</th><th>' +
    iTrans('Briefed') +
    '</th><th>' +
    iTrans('Used For') +
    '</th><th>' +
    iTrans('Modified') +
    '</th><th>' +
    iTrans('Actions') +
    '</th></tr></thead><tbody>';
  for (var i in BRIEFING_FORMS) {
    var I = BRIEFING_FORMS[i];
    I.index = i;
    var Button =
      '<div class="vertical-center-container"><button onclick="PAX_LoadBriefingForm(' +
      i +
      ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini" ' +
      (PAX_CUR_AIRCRAFT.ident == I.AcIdent ? '' : 'disabled') +
      '><img src="./images/edit.svg"></button><button onclick="PAX_DeleteBriefingForm(' +
      i +
      ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini" ' +
      (PAX_CUR_AIRCRAFT.ident == I.AcIdent ? '' : 'disabled') +
      '><img src="./images/delete.svg"></button></div>';
    html +=
      '<tr><td>' +
      I.title +
      '</td><td>' +
      I.AcIdent +
      '</td><td>' +
      I.Briefings +
      '</td><td>' +
      (PAX_CLIENT_LOOKUP[I.ClientPK] != undefined ? PAX_CLIENT_LOOKUP[I.ClientPK].Business : iTrans('Unknown')) +
      '</td><td>' +
      I.modified +
      '</td><td>' +
      Button +
      '</td></tr>';
  }
  html += '</tbody></table>';

  html +=
    '<button data-mini="true" onclick="PAX_EditBriefing(1)" class="greenbg">' +
    iTrans('Create New Briefing Form') +
    '</button>';
  $('#PaxBriefingFromHLDR').html(html);
  $('#PaxBriefingFromHLDR').enhanceWithin();
}

function PAX_DeleteBriefingForm(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Confirm',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete this Passenger Briefing Form?',
    buttons: {
      Delete: {
        click: function () {
          PAX_SYNC_REQUIRED = true;
          ExchangePaxData('deleteBriefingForm', BRIEFING_FORMS[index], PAX_DeleteBriefingForm_DELETED, true);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}
function PAX_DeleteBriefingForm_DELETED() {
  if (BRIEFING_FORMS.length == 1) {
    PAX_BRIEFING_FORM = null;
    PaxBriefingReturn(true, true);
    return;
  }
  PAX_EditBriefing();
}

function PAX_LoadBriefingForm(index) {
  //some testing data'
  $('#PAX_Hide_for_Briefing').hide();
  $('#BILLING_CF_REQ_ITINTXT').html(iTrans('Required for Briefing'));
  BRIEFING_FORMS[index].Editing = index;
  PAX_BRIEFING_FORM = BRIEFING_FORMS[index];
  ADMIN_CFIELDS = PAX_BRIEFING_FORM.Cfields;
  BILLING_RETURN_TO = 'Briefing';
  var Form = PAX_BRIEFING_FORM;
  var html = '<h3>' + iTrans('Passenger Briefing Form') + '</h3>';
  html += '<b>' + iTrans('Aircraft Type') + ':</b> ' + PAX_BRIEFING_FORM.AcType + '<br />';
  html += '<b>' + iTrans('Aircraft Ident') + ':</b> ' + PAX_BRIEFING_FORM.AcIdent + '<br />';

  html += '<label for="PAX_B_title">' + iTrans('Title') + '</label>';
  html += '<input type="text" data-mini="true" id="PAX_B_title" value="' + Form.title + '" />';

  html += '<label for="PAX_B_description">' + iTrans('Description') + '</label>';
  html += '<textarea id="PAX_B_description">' + Form.description + '</textarea>';

  html +=
    '<label for="PAX_B_validPeriod">' +
    iTrans('Number of Days Briefing is Valid For, 0 for Always Valid.  Use negative symbol for Hours ie: -1 = 1 hour') +
    '</label>';
  html += '<input type="number" data-mini="true" id="PAX_B_validPeriod" step=1 value="' + Form.validPeriod + '" />';

  html += '<label for="PAX_B_wrnExpiry">' + iTrans('Warn Admin Staff if Expiring') + '</label>';
  html +=
    '<input type="checkbox" data-mini="true" id="PAX_B_wrnExpiry"  ' + (Form.wrnExpiry == 1 ? 'checked' : '') + '/>';

  html += '<label for="PAX_B_videoUrl">' + iTrans('URL For Briefing Video') + '</label>';
  html += '<input type="text" data-mini="true" id="PAX_B_videoUrl" value="' + Form.videoUrl + '" />';

  html += '<label for="PAX_B_ClientPK">' + iTrans('Used For') + '</label>';
  html += '<select id="PAX_B_ClilentPK" data-mini="true"></select>';

  html += '<div id="PAX_B_CfieldsHLDR"></div>';

  html +=
    '<button onClick="PAX_Briefing_AddCompanyCF()" data-mini="true" data-icon="plus">' +
    iTrans('New Briefing Item') +
    '</button>';
  html +=
    '<button onClick="PAX_Briefing_SaveForm()" data-mini="true" data-icon="check" class="greenbg">' +
    iTrans('Save Form') +
    '</button>';
  $('#PaxBriefingFromHLDR').html(html);
  $('#PaxBriefingFromHLDR').enhanceWithin();
  Pax_Briefing_LoadCfields();

  var CFselect =
    '<label for="BILLING_CF_CLIENTPK">' +
    iTrans('Used For') +
    '</label><select id="BILLING_CF_CLIENTPK" data-mini="true">';

  for (var i in PAX_CLIENT_LOOKUP) {
    CFselect += '<option value="' + PAX_CLIENT_LOOKUP[i].ClientPK + '">' + PAX_CLIENT_LOOKUP[i].Business + '</option>';
    $('#PAX_B_ClilentPK').append(
      '<option value="' + PAX_CLIENT_LOOKUP[i].ClientPK + '">' + PAX_CLIENT_LOOKUP[i].Business + '</option>'
    );
  }
  CFselect += '</select>';
  $('#PAX_BRIEFING_Cfield_ClientSelector').html(CFselect);
  $('#PAX_B_ClilentPK').val(Form.ClientPK).selectmenu('refresh');
  //get aircraft briefing form or use default settings
}

function Pax_Briefing_LoadCfields() {
  var CF = ADMIN_CFIELDS;
  if (CF.length > 0) {
    var html =
      "<table  id='BIT_Admin7' class='first-td-fill'><thead><tr><th>" +
      iTrans('Preview') +
      '</th><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Collected') +
      '</th><th>' +
      iTrans('Req') +
      '</th><th>' +
      iTrans('Action') +
      '</th><th>' +
      iTrans('Sort Order') +
      '</th></tr></thead><tbody>';
    for (var i in CF) {
      var I = CF[i];
      var Collected = 'Always';
      if (I.ClientPK != 0) {
        Collected = 'Client';
      }
      var Req = iTrans('No');
      if (I.req == 1) {
        Req = iTrans('Yes');
      }
      var Preview = createPaxManCustomField(I, I.Value, 'Pax', 'BRIEF');
      var Button =
        '<div class="vertical-center-container"><button onclick="BILLING_EditClientCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/edit.svg"></button><button onclick="PAX_RemoveBriefingCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/delete.svg"></button></div>';

      var SortOrder =
        '<div class="vertical-center-container"><button class="action-btn ui-btn" data-mini="true" onclick="ADMIN_CF_SortOrder_UP(' +
        i +
        ')"><img src="./images/Plus-icon.png" widht="20px" height="20px"></button><button class="action-btn" data-mini="true" onclick="ADMIN_CF_SortOrder_DOWN(' +
        i +
        ')"><img src="./images/Minus-icon.png" widht="20px" height="20px"></button></div>';

      html +=
        '<tr><td>' +
        Preview +
        '</td><td>' +
        I.Label +
        '</td><td>' +
        I.type +
        '</td><td>' +
        Collected +
        '</td><td>' +
        Req +
        '</td><td>' +
        Button +
        '</td><td>' +
        SortOrder +
        '</td></tr>';
    }
    html += '</tbody></table>';
    $('#PAX_B_CfieldsHLDR').html(html);
  } else {
    $('#PAX_B_CfieldsHLDR').html('<h3>' + iTrans('No Briefing Fields Found') + '</h3>');
  }
  $('#PAX_B_CfieldsHLDR').enhanceWithin();
}

function PAX_RemoveBriefingCF(e, pax = false) {
  //confirm delete before removing Cfield PrimaryKey
  var PK = ADMIN_CFIELDS[e].PrimaryKey;
  console.log(PK);

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('You are about to delete this custom field') +
      '</p>  <h3>' +
      iTrans('Are you Sure?') +
      '</h3></center>',
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          PAX_SYNC_REQUIRED = true;
          //warn user existing records for each user will be deleted
          $.getJSON(
            BASEURL +
              'menu-PaxManifesting-AJAX.php?action=delete' +
              (pax ? 'Pax' : '') +
              'CustomField&PK=' +
              PK +
              '&FormPK=' +
              (pax ? '' : PAX_BRIEFING_FORM.PrimaryKey),
            function (json_data) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
              else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              else if (json_data[0].ServerResponse == 'No Records') {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('ERROR'),
                  headerClose: false,
                  buttonPrompt: iTrans('ERROR Contact site admin'),
                  buttons: {
                    [iTrans('Close')]: {
                      click: function () {},
                    },
                  },
                });
              } else {
                ADMIN_CFIELDS.splice(e, 1);
                if (pax) Pax_LoadCfields();
                else Pax_Briefing_LoadCfields(); //reload admin display
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function PaxBriefingCancelCF() {
  $.mobile.changePage($('#PAX_PaxBriefingsPage'), {
    changeHash: false,
  });
}
function PaxBriefingCancelPaxCF() {
  $.mobile.changePage($('#PAX_PaxCfieldsPage'), {
    changeHash: false,
  });
}

function PAX_Briefing_AddCompanyCF() {
  $('#BILLING_CfieldItems_HLDR').hide();
  $('#PAX_CfieldItems_HLDR').show();

  $.mobile.changePage($('#BILLING_NewClientCFaddPage'), {
    changeHash: false,
  });

  $('#BILLING_CANCEL_CF').removeAttr('onclick');
  $('#BILLING_CANCEL_CF').unbind('click');
  $('#BILLING_CANCEL_CF').click(PaxBriefingCancelCF);
  ADMIN_BILLING_FIELDS = true;

  BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a client;
  BILLING_CLIENT_CFIELD.PrimaryKey = 0;
  BILLING_CLIENT_CFIELD.type = '';
  BILLING_CLIENT_CFIELD.options = [];
  BILLING_CLIENT_CFIELD.Bill_Item = 0;
  BILLING_CLIENT_CFIELD.Bill_Item_options = '';
  $('#BILLING_CF_CHK_OPTIONS_HLDR').hide();
  $('#BILLING_CF_BILLITEM').prop('checked', false);
  $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
  BILLING_BILLITEM({ checked: false });
  //we're here at the page now what?  resetl inputs
  $('#BILLING_CF_Label').val('');
  $('#BILLING_CF_TYPE').val(null).selectmenu('refresh');

  $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_PH').val('');

  $('#BILLING_CF_REQ').prop('checked', false);
  $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_REQ_ITIN').prop('checked', false);
  $('#BILLING_CF_REQ_ITIN').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_HIDEDEFAULT').prop('checked', false);
  $('#BILLING_CF_HIDEDEFAULT').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_PERLEG').prop('checked', false);
  $('#BILLING_CF_PERLEG').checkboxradio('refresh');

  $('#ClientCustomField').html('');
}

function PAX_Briefing_SaveForm() {
  var I = PAX_BRIEFING_FORM;
  I.title = $('#PAX_B_title').val();
  I.description = $('#PAX_B_description').val();
  I.validPeriod = $('#PAX_B_validPeriod').val();
  I.videoUrl = $('#PAX_B_videoUrl').val();
  I.ClientPK = $('#PAX_B_ClilentPK').val();
  //I.Cfields = [];
  I.expires = '2020-01-01 00:00:00';
  I.wrnExpiry = $('#PAX_B_wrnExpiry').val();

  if (I.Cfields.length == 0) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt: iTrans('You must add at least 1 briefing item to this form.'),
      buttons: {
        Close: {
          click: function () {
            //do Nothing
          },
        },
      },
    });
    return;
  }
  //Prompt to invalidate all existing Briefings if there are existing briefings to be altered or Update Expiry date or do nothing;
  if (I.Briefings > 0) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('WARNING'),
      headerClose: false,
      buttonPrompt:
        iTrans('This form has been used for') +
        ' ' +
        I.Briefings +
        ' ' +
        iTrans('passenger briefings. What would you like to do with the existing briefing records?'),
      buttons: {
        [iTrans('Leave As Is')]: {
          click: function () {
            PAX_SYNC_REQUIRED = true;
            I.saveAction = 'nothing';
            ExchangePaxData('saveBriefingForm', I, PaxBriefingReturn, true, false);
          },
        },
        [iTrans('Update Expiry Date')]: {
          click: function () {
            PAX_SYNC_REQUIRED = true;
            I.saveAction = 'update';
            ExchangePaxData('saveBriefingForm', I, PaxBriefingReturn, true, false);
          },
        },
        [iTrans('Mark as Invalid')]: {
          click: function () {
            PAX_SYNC_REQUIRED = true;
            I.saveAction = 'invalidate';
            ExchangePaxData('saveBriefingForm', I, PaxBriefingReturn, true, false);
          },
        },
      },
    });
    return;
  } else {
    PAX_SYNC_REQUIRED = true;
    I.saveAction = 'nothing';
    ExchangePaxData('saveBriefingForm', I, PaxBriefingReturn, true, false);
  }
}

function PAX_SaveBriefingCfields(data) {
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-PaxManifesting-AJAX.php?action=editCustomField_Briefing',

    data: data,
  })
    .success(function (json_data) {
      $.mobile.loading('hide');
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        alert('<h1>' + iTrans('Offline Cannot Add Custom Field') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception')
        ServerResponse(json_data[0], 'BILLING_AdminApproveExpense');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data[0].ServerResponse == 'Success') {
        $('#BILLING_SaveClientCF_BTN').prop('disabled', false);
        ADMIN_CFIELDS = json_data[0].Cfields;
        PAX_BRIEFING_FORM.Cfields = json_data[0].Cfields;
        $.mobile.changePage($('#PAX_PaxBriefingsPage'), {
          changeHash: false,
        });
        Pax_Briefing_LoadCfields();
      }
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      AJAXFAIL(error);
      $('#BILLING_SaveClientCF_BTN').prop('disabled', false);
    });
}

function PaxdoBriefingReturn() {
  if (PAX_MANIFESTING.briefingOnly) {
    $.mobile.changePage($('#PAX_Bulk_BriefingPaxSelect'), {
      reverse: true,
      changeHash: false,
    });
    return;
  }
  if (ITIN_Pax_Manifest.AdminView == 1) {
    $.mobile.changePage($('#Sub_Report_Page'), {
      reverse: true,
      changeHash: false,
    });
    return;
  }
  if (ITIN_Pax_Manifest.Editing) {
    if (ITIN_Pax_Manifest.FlightReport) {
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        $.mobile.changePage($('#BILLING_FlightDescriptionPage'), {
          reverse: true,
          changeHash: false,
        });
      } else {
        $.mobile.changePage($('#BILLING_FlightLegsPage'), {
          reverse: true,
          changeHash: false,
        });
      }
      PAX_Init_ITIN_Manifesting();
    } else if (ITIN_Pax_Manifest.Quote || ITIN_Pax_Manifest.Scheduler) {
      $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
        reverse: true,
        changeHash: false,
      });
      if (ITIN_Pax_Manifest.CurLeg == -1) {
        $.mobile.changePage($('#BILLING_AddQuotingLegs'), {
          reverse: true,
          changeHash: false,
        });
      } else {
        $.mobile.changePage($('#BILLING_EditQuotingLegs'), {
          reverse: true,
          changeHash: false,
        });
      }
      PAX_Init_ITIN_Manifesting();
    } else if (ITIN_Pax_Manifest.Popup == 1) {
      $.mobile.changePage($('#Following_Page'), {
        reverse: true,
        changeHash: false,
      });
    } else {
      $.mobile.changePage($('#Edit_Itin_Page'), {
        reverse: true,
        changeHash: false,
      });
      PAX_Init_ITIN_Manifesting();
    }
    return;
  }
  if (ITIN_Pax_Manifest.CurLeg == -1) {
    $.mobile.changePage($('#ITIN-INPUT'), {
      reverse: true,
      changeHash: false,
    });
  } else {
    $.mobile.changePage($('#WB_Page2'), {
      reverse: true,
      changeHash: false,
    });
  }
  PAX_updateScrollPos();

  PAX_Init_ITIN_Manifesting(ITIN_Pax_Manifest.CurLeg);
}
function PAX_doBriefing(index = false) {
  $.mobile.changePage($('#PAX_PaxdoBriefingsPage'), {
    reverse: true,
    changeHash: false,
  });
  if (index !== false) {
    ITIN_Pax_Briefing = BRIEFING_MANIFEST.paxList[index].briefingStatus;
    ITIN_Pax_Briefing.briefingIndex = index;
    ITIN_Pax_Briefing.name =
      BRIEFING_MANIFEST.paxList[index].firstname + ' ' + BRIEFING_MANIFEST.paxList[index].lastname;
  }
  var P = ITIN_Pax_Briefing;

  var html = '';
  if (P.forms.length == 0 && P.briefedForm == null) {
    $('#PaxdoBriefingFromHLDR').html('<h3>' + iTrans('No Briefings Available') + '</h3>');
    P.canSkip = true;
  } else {
    if (P.briefed == false) {
      //choose an available briefing form if more than 1
      if (P.forms.length == 1) {
        PAX_doBriefing_selected(0);
      } else {
        html += '<h3>' + iTrans('Select Briefing Form') + '</h3>';
        html +=
          '<table class="limit-table first-td-fill"><thead><tr><th>' +
          iTrans('Name') +
          '</th><th>' +
          iTrans('Used For') +
          '</th><th>' +
          (AC_data.briefingLock == 1 ? iTrans('AC Ident') : iTrans('AC Type')) +
          '</th><th>' +
          iTrans('Modified') +
          '</th><th>' +
          iTrans('Select') +
          '</th></tr></thead><tbody>';

        for (var i in P.forms) {
          var I = P.forms[i];
          var Button =
            '<div class="vertical-center-container"><button onclick="PAX_doBriefing_selected(' +
            i +
            ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/Check_mark_green.svg"></button></div>';
          html +=
            '<tr><td>' +
            I.title +
            '</td><td>' +
            PAX_CLIENT_LOOKUP[I.ClientPK].Business +
            '</td><td>' +
            (AC_data.briefingLock == 1 ? I.AcIdent : I.AcType) +
            '</td><td>' +
            I.modified +
            '</td><td>' +
            Button +
            '</td></tr>';
        }
        html += '</tbody></table>';
        $('#PaxdoBriefingFromHLDR').html(html);
        $('#PaxdoBriefingFromHLDR').enhanceWithin();
      }
    } else {
      //display their briefing form history
      PAX_showBriefingRecord();
    }
  }
}

function PAX_showBriefingRecord() {
  $('#PaxdoBriefingFromHLDR').html('');
  var P = ITIN_Pax_Briefing;
  var F = P.briefedForm;
  if (F == null) F = BRIEFING_MANIFEST.paxList[ITIN_Pax_Briefing.briefingIndex].filledForm;
  var CF = F.Cfields;
  var html = '<h3>' + F.title + '</h3>';
  html += '<b>' + iTrans('Created') + ':</b> ' + F.created + '<br />';
  html += '<b>' + iTrans('Modified') + ':</b> ' + F.FormModified + '<br />';
  html +=
    '<b>' +
    iTrans('Valid For') +
    ':</b> ' +
    (F.validPeriod < 0
      ? Math.abs(F.validPeriod) + ' ' + iTrans('Hours')
      : F.validPeriod == 0
      ? iTrans('Forever')
      : F.validPeriod + ' ' + iTrans('Days')) +
    '<br />';
  if (F.videoUrl != '')
    html +=
      '<button onClick="PAX_viewVideoLink("' +
      F.videoUrl +
      '") data-mini="true" data-icon="eye">' +
      iTrans('View Briefing Video') +
      '</button>';
  html += '<p><b>' + iTrans('Description') + '</b><br />' + F.description + '</p>';

  html += '<h3>' + iTrans('Briefing Items') + '</h3>';
  if (CF.length > 0) {
    html += PAX_Cfield_HTML(CF);
  }

  $('#PaxdoBriefingFromHLDR').html(html);
  $('#PaxdoBriefingFromHLDR').enhanceWithin();
}

function PAX_viewVideoLink(Url) {
  openExternal(Url, null);
}

function PAX_doBriefing_selected(i) {
  var P = ITIN_Pax_Briefing;
  P.formIndex = i;
  var F = P.forms[i];
  var html = '<h3>' + F.title + '</h3>';
  html += '<b>' + iTrans('Created') + ':</b> ' + F.created + '<br />';
  html += '<b>' + iTrans('Modified') + ':</b> ' + F.FormModified + '<br />';
  html +=
    '<b>' + iTrans('Valid For') + ':</b> ' + Math.abs(F.validPeriod) + ' ' + F.validPeriod < 0
      ? iTrans('Hours')
      : iTrans('Days') + '<br />';
  if (F.videoUrl != '')
    html +=
      '<button onClick="PAX_viewVideoLink(\'' +
      F.videoUrl +
      '\')" data-mini="true" data-icon="eye">' +
      iTrans('View Briefing Video') +
      '</button>';
  html += '<p><b>' + iTrans('Description') + '</b><br />' + F.description + '</p>';
  html += '<h3>' + iTrans('Briefing Items') + '</h3>';
  for (var i in F.Cfields) {
    var I = F.Cfields[i];
    //Don't display client specific custom fields if the client is not selected
    //if (I.ClientPK != P.ClientPK && I.ClientPK != 0) continue;
    html += createPaxManCustomField(I, I.Value, 'Brief', 'ITIN_BRIEF');
  }
  html +=
    '<button onClick="PAX_verifyBriefingFields();" data-mini="true" class="greenbg">' +
    iTrans('Save Briefing') +
    '</button>';
  $('#PaxdoBriefingFromHLDR').html(html);
  $('#PaxdoBriefingFromHLDR').enhanceWithin();
}

function PAX_verifyBriefingFields() {
  var P = ITIN_Pax_Briefing;
  var F = P.forms[P.formIndex];
  var DisableNext = false;
  var filledForm = JSON.parse(JSON.stringify(F));
  var CF = filledForm.Cfields;
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      //Don't display client specific custom fields if the client is not selected
      if (I.ClientPK != P.ClientPK && I.ClientPK != 0) continue;
      var checked = 0;
      if (I.type == 'signature') {
        if (I.req == 1) {
          var hasValue = false;
          try {
            if (I.Value == '' || I.Value == undefined) {
              hasValue = false;
            } else {
              hasValue = true;
            }
          } catch (e) {
            hasValue = false;
          }
          var hasSig = false;
          try {
            var sigData = JSON.parse($('#' + Cfield_SignatureStatus.CfieldID).val());
            if (sigData.data.length == 0 || sigData.data == undefined) {
              BillingValidation('[' + I.Label + '] is required.');
              DisableNext = true;
            } else {
              hasSig = true;
              I.Value = JSON.stringify(sigData);
            }
          } catch (e) {
            hasSig = false;
          }
          if (!hasValue && !hasSig) {
            BillingValidation('[' + I.Label + '] is required.');
            DisableNext = true;
          }
        } else {
          try {
            var sigData = JSON.parse($('#' + Cfield_SignatureStatus.CfieldID).val());
            if (sigData.data.length == 0 || sigData.data == undefined) {
              I.Value = '';
            } else {
              I.Value = JSON.stringify(sigData);
            }
          } catch (e) {
            I.Value = '';
          }
        }
      } else if (I.type == 'checkbox') {
        if ($('#ITIN_BRIEF_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
        I.Value = checked;
        if (I.req == 1 && checked == 0) {
          BillingValidation('[' + I.Label + '] is required to be checked.');
          DisableNext = true;
        }
      } else {
        I.Value = $('#ITIN_BRIEF_CF_' + I.PrimaryKey).val();
        //console.log("#BI_CF_" + I.PrimaryKey);
        //console.log(I.Value);
        //if (I.Value == undefined) continue; //could have been added after existing flight report was made
        if (I.Value == undefined) I.Value = '';
        if ((I.Value == '' && I.req == 1) || (I.Value == null && I.req == 1) || (I.Value == ' ' && I.req == 1)) {
          BillingValidation('[' + I.Label + '] is a required field and is empty.');
          DisableNext = true;
        }
      }
    }
  }
  if (DisableNext) return;
  var now = new Date();
  filledForm.created = now.toISOString().substr(0, 19).replace('T', ' ');
  filledForm.isExpired = false;
  if (filledForm.validPeriod == 0) {
    filledForm.expires = null;
  } else {
    if (filledForm.validPeriod < 0) {
      now.setUTCHours(now.getUTCHours() + Math.abs(filledForm.validPeriod));
    } else {
      now.setUTCDate(now.getUTCDate() + filledForm.validPeriod);
    }

    filledForm.expires = now.toISOString().substr(0, 19).replace('T', ' ');
  }
  if (PAX_MANIFESTING.briefingOnly) {
    BRIEFING_MANIFEST.paxList[ITIN_Pax_Briefing.briefingIndex].filledForm = filledForm;
    //ITIN_Pax_Briefing.filledForm = filledForm;
    $.mobile.changePage($('#PAX_Bulk_BriefingPaxSelect'), {
      reverse: true,
      changeHash: false,
    });
    PAX_BulkBriefing_PaxSelected();
    PAX_SaveItinBriefingsDATA(BRIEFING_MANIFEST.paxList[ITIN_Pax_Briefing.briefingIndex]);
    return;
  } else if (ITIN_Pax_Manifest.CurLeg == -1) {
    ITIN_Pax_Manifest.DefaultList[ITIN_Pax_Briefing.DefaultListIndex].filledForm = filledForm;
    PAX_SaveItinBriefingsDATA(ITIN_Pax_Manifest.DefaultList[ITIN_Pax_Briefing.DefaultListIndex]);
  } else {
    ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][ITIN_Pax_Briefing.DefaultListIndex].filledForm = filledForm;
    PAX_SaveItinBriefingsDATA(ITIN_Pax_Manifest.Pax_Legs[ITIN_Pax_Manifest.CurLeg][ITIN_Pax_Briefing.DefaultListIndex]);
  }

  PaxdoBriefingReturn();
}

function PAX_SaveItinBriefingsDATA(briefedPax) {
  var query = 'PrimaryKey = ?';
  var queryVal = briefedPax.PrimaryKey;
  if (briefedPax.PrimaryKey <= 0) {
    query = 'OFFLINE_PK = ?';
    queryVal = briefedPax.OFFLINE_PK;
  }
  cirroDB.query('Passengers', query, [queryVal], function (paxData) {
    var p = null;
    if (paxData !== false) {
      p = paxData[0];
    }

    var modDate = dateToStringObject(new Date());
    if (CurPASSENGERsync == '') {
      PAX_update_CurPASSENGERsync(modDate);
      CurPASSENGERsync = modDate;
    }
    p.modified = modDate;
    PAX_UpdatePassengerBriefingData(p, briefedPax, modDate);
    PAX_UpdatePassenger(p);
    if (ONLINE && !LOW_BANDWIDTH) {
      PAX_syncPassengers(true);
    }
  });
}

function PAX_verifyBriefingCompleted() {
  var L = ITIN_Pax_Manifest.DefaultList;
  var canGo = true;
  var userList = '';
  for (var i in L) {
    var I = L[i];
    if (I.briefingStatus.briefed == false && I.briefingStatus.required && I.briefingStatus.canSkip == false) {
      canGo = false;
      userList += I.firstname + ' ' + I.lastname + '<br />';
    }
  }
  if (!canGo) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Briefing Required'),
      headerClose: false,
      buttonPrompt: userList,
      buttons: {
        Close: {
          click: function () {
            //do Nothing
          },
        },
      },
    });
  }
  return canGo;
}

function PaxBulkBrief() {
  PAX_LoadPassengerList();
  BRIEFING_MANIFEST.paxList = [];
  $.mobile.changePage($('#PAX_Bulk_Briefing'), {
    reverse: true,
    changeHash: false,
  });
  var ClientSelect =
    '<label for="PAX_BriefingClientSelect">' +
    iTrans('Client') +
    '</label><select id="PAX_BriefingClientSelect" data-mini="true" onChange="PAX_PopulateBulkBriefAC()">';
  BillingDB('SELECT', 'JobsAll', null, function (e) {
    PAX_CLIENT_LOOKUP = {};
    if (e == false) {
      e = [];
    }
    for (var i in e) {
      if (PAX_CLIENT_LOOKUP[e[i].ClientPK] == undefined) {
        PAX_CLIENT_LOOKUP[e[i].ClientPK] = e[i];
        if (e[i].ClientPK == 0)
          ClientSelect += '<option value="' + e[i].ClientPK + '" selected>' + e[i].Business + '</option>';
        else ClientSelect += '<option value="' + e[i].ClientPK + '">' + e[i].Business + '</option>';
      }
    }
    ClientSelect += '</select>';
    $('#PAX_BulkBriefClients').html(ClientSelect).enhanceWithin();
    PAX_PopulateBulkBriefAC();
  });
}
function PAX_PopulateBulkBriefAC() {
  var ClientPK = $('#PAX_BriefingClientSelect').val();
  cirroDB.query('AC', 'ident != ?', '', function (e) {
    BRIEFING_AC_DATA = [];
    if (e === false) BRIEFING_AC_DATA = [];
    else {
      for (var i in e) BRIEFING_AC_DATA.push(e[i].AC_data);
    }
    html =
      '<table class="limit-table last-td-fill" data-filter="true"><thead><tr><th>' +
      iTrans('Ident') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Series') +
      '</th><th>' +
      iTrans('Forms') +
      '</th><th>' +
      iTrans('Forms Avail') +
      '</th><th>' +
      iTrans('Actions') +
      '</th></tr></thead><tbody>';

    for (var i in BRIEFING_AC_DATA) {
      var I = BRIEFING_AC_DATA[i];
      if (I.briefingReq == 0) continue;
      var ClientForms = 0;

      for (var f in I.BriefingForms) {
        if (I.BriefingForms[f].ClientPK == ClientPK) ClientForms++;
      }

      var action =
        '<div class="vertical-center-container"><button data-mini="true" class="action-btn" onClick="PAX_BulkBriefing_Selected(' +
        i +
        ')" ' +
        ' ' +
        ((ClientForms == 0 && ClientPK != -1) || (I.BriefingForms.length == 0 && ClientPK == -1) ? 'disabled' : '') +
        '><img src="./images/Check_mark_green.svg"></button></div>';
      var select =
        '<select data-role="none" id="PAX_AC-briefingLock-' +
        i +
        '" onChange="PAX_EditBriefing_Lock(' +
        i +
        ', this)" ' +
        (I.briefingReq == 1 ? '' : 'disabled') +
        '>';
      var LockType = '';
      switch (I.briefingLock) {
        case 0:
          LockType = iTrans('A/C Type');
          break;
        case 1:
          LockType = iTrans('This Ident');
          break;
        case 2:
          LockType = iTrans('A/C Series');
          break;
      }

      html +=
        '<tr><td>' +
        I.ident +
        '</td><td>' +
        I.AcType +
        '</td><td>' +
        I.List_Type +
        '</td><td>' +
        (ClientPK == -1 ? I.BriefingForms.length : ClientForms) +
        '</td><td>' +
        LockType +
        '</td><td>' +
        action +
        '</td></tr>';
    }
    html += '</tbody></table>';
    $('#PAX_BulkBriefAircraft').html(html).enhanceWithin();
  });
}

function PAX_BulkBriefing_Selected(index) {
  AC_data = BRIEFING_AC_DATA[index];
  //goto pax selector page
  $.mobile.changePage($('#PAX_Bulk_BriefingPaxSelect'), {
    reverse: true,
    changeHash: false,
  });
  var formClientPK = $('#PAX_BriefingClientSelect').val();
  if (formClientPK == 0) formClientPK = null;
  PAX_Populate_ClientList(formClientPK, true);
  PAX_MANIFESTING.AddingPax = true;
  //PAX_MANIFESTING.AddingManifestTo = 'Itinerary'; //Causing to return to leg tool when we don't want this
  PAX_MANIFESTING.CUR_MANIFEST.ClientFilter = -1;
  PAX_MANIFESTING.briefingOnly = true;
  ITIN_Pax_Manifest.ClientPK = $('#PAX_BriefingClientSelect').val();
  BRIEFING_MANIFEST.briefing = true;
  PAX_MANIFESTING.CUR_MANIFEST.Name = 'Bulk Passenger Briefing';
  PAX_MANIFESTING.CUR_MANIFEST.Description = 'An Bulk List to brief many passengers easily';
  cirroDB.query('Passengers', 'PrimaryKey != ? ORDER BY lastname ASC', ['-99999'], function (e) {
    PAX_LoadList(e, 1);
  });
}

function PAX_BulkBriefing_PaxSelect(page = 0) {
  $('#PAX_ManifestList').html(''); //to prevent duplicate Div id's
  var html = '';
  if (PAX_Filtered.length == 0) {
    html = '<h3>No Items</h3>';
  } else {
    html =
      '<table class="limit-table second-td-fill" id="PAX_MAN_Filtered"><thead><tr><th>Lastname</th><th>Firstname</th><th>Modified</th><th>Action</th></tr></thead><tbody>';

    var count = 0;
    var total = PAX_Filtered.length;
    var numPerPage = 50;
    var recStart = page * numPerPage;
    var i = -1;
    for (i in PAX_Filtered) {
      var I = PAX_Filtered[i];
      for (var c in BRIEFING_MANIFEST.paxList) {
        if (
          (BRIEFING_MANIFEST.paxList[c].PrimaryKey == I.PrimaryKey && I.PrimaryKey > 0) ||
          (BRIEFING_MANIFEST.paxList[c].OFFLINE_PK == I.OFFLINE_PK && I.OFFLINE_PK != null && I.OFFLINE_PK != '')
        )
          continue;
      }
      if (i < recStart) continue;
      count++;
      if (count == numPerPage + 1) break;

      var actions =
        '<div class="vertical-center-container"><input style="width:25px;height:25px;" type="checkbox" data-role="none" id="PAX_SelectCHK-' +
        i +
        '" ' +
        (I.selected ? 'checked' : '') +
        '/></div>';
      var TRclass = I.VIP == 1 ? 'class="vip-following"' : '';
      if (I.selected == 1) TRclass = 'class="greenRecord"';
      html +=
        '<tr style="height:40px;" id="PAX_SelectTR-' +
        i +
        '" ' +
        TRclass +
        ' onClick="PAX_togglePaxSelect(' +
        i +
        ')"><td>' +
        I.lastname +
        '</td><td>' +
        I.firstname +
        ' ' +
        I.middlename +
        '</td><td>' +
        I.modified +
        '</td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</tbody></table>';
    var addPaxBTN =
      '<button data-mini="true" class="greenbg" onClick="PAX_BulkBriefing_PaxSelected()" data-icon="plus">' +
      iTrans('Add Selected Passengers') +
      '</button>';
    var nav = '';
    if (total != 0) {
      if (recStart == 0) recStart = 1;
      if (i == total - 1) {
        nav =
          (page == 0 ? '' : '<a onClick="displayPaxFiltered(false,' + (page - 1) + ')"><--Prev Page </a>') +
          recStart +
          ' - ' +
          total +
          ' of ' +
          total +
          ' items';
      } else {
        nav =
          (page == 0 ? '' : '<a onClick="displayPaxFiltered(false,' + (page - 1) + ')"><--Prev Page </a>') +
          (i - (numPerPage - 1)) +
          ' - ' +
          i +
          ' of ' +
          total +
          ' items' +
          '<a onClick="displayPaxFiltered(' +
          (i < total ? page + 1 : page) +
          ')"> Next Page --></a>';
      }
      html = nav + html + nav;
    }

    html = addPaxBTN + html + addPaxBTN;
  }

  $('#PAX_BulkBriefPassengerList').html(html);
  $('#PAX_BulkBriefPassengerList').enhanceWithin();
  //AddTableSorter('PAX_MAN_Filtered', [[0, 0]]); //this doesn't work with pagination
}

function PAX_BulkBriefing_PaxSelected(done = true) {
  //BRIEFING_MANIFEST.paxList = [];
  for (var i in PAX_Filtered) {
    var I = PAX_Filtered[i];

    var found = false;
    var foundIndex = null;
    for (var c in BRIEFING_MANIFEST.paxList) {
      if (
        (BRIEFING_MANIFEST.paxList[c].PrimaryKey == I.PrimaryKey && I.PrimaryKey != 0) ||
        (BRIEFING_MANIFEST.paxList[c].OFFLINE_PK == I.OFFLINE_PK && I.OFFLINE_PK != null)
      ) {
        found = true;
        foundIndex = c;
        break;
      }
    }
    if (I.selected) {
      if (!found) BRIEFING_MANIFEST.paxList.push(JSON.parse(JSON.stringify(I)));
    } else {
      if (found) BRIEFING_MANIFEST.paxList.splice(foundIndex, 1);
    }
  }
  if (done) PAX_BulkBriefing_PaxDisplay();
}
function PAX_BulkBriefing_PaxDisplay() {
  let html =
    '<button data-mini="true" onClick="PAX_BulkBriefing_PaxSelect()" class="greenbg" data-icon="plus">' +
    iTrans('Add More Passengers') +
    '</button>';

  var L = BRIEFING_MANIFEST.paxList;

  if (L == null) L = [];
  if (L.length <= 0) {
    html += '<h3>No Passengers in Briefing Manifest</h3>';
  }

  html += `<table class="limit-table first-td-fill" data-filter="true">
               <thead>
                 <tr>
                   <th>Last, First - [Company]</th>
                   <th>Safety Briefing</th>
                   <th>Action</th>
                 </tr>
               </thead>
             <tbody>`;

  for (let i in L) {
    if (!L.hasOwnProperty(i)) {
      continue;
    }

    let I = L[i];

    const vipClass = I.VIP > 0 ? 'class="vip-following"' : '';

    let briefingBTN = `<div class="vertical-center-container">
          <button onclick="PAX_ITIN_Manifest_StartBriefing(${i});" data-mini="true" class="action-btn">
            <i class="fas fa-file"></i> 
          </button>
    
        </div>`;
    let actions = `<div class="vertical-center-container">
          <button onclick="PAX_ITIN_BulkRemovePax(${i});" data-mini="true" class="action-btn">
            <i class="fas fa-times"></i>
          </button>
        </div>`;

    var briefingStatus = PAX_ITIN_GetBriefingStatus(I);
    I.briefingStatus = briefingStatus;
    var briefingAction = '';
    if (briefingStatus.briefed) {
      briefingAction =
        '<button data-mini="true" class="greenbg" onClick="PAX_doBriefing(' +
        i +
        ');">' +
        briefingStatus.status +
        '</button>';
    } else {
      briefingAction =
        '<button class="redbg" data-mini="true" onClick="PAX_doBriefing(' +
        i +
        ');">' +
        briefingStatus.status +
        '</button>';
    }
    var companyTXT = '';
    if (I.company != '') {
      companyTXT = ' - [' + I.company + ']';
    }
    html += `<tr ${vipClass}>
            <td>${I.lastname}, ${I.firstname} ${I.middlename}${companyTXT}</td>`;

    html += `<td>${briefingAction}</td>
            <td>${actions}</td>
            </tr>`;
  }
  html += '</tbody></table>';
  html +=
    '<button data-mini="true" onClick="PAX_SendBulkBriefingData()" class="greenbg" data-icon="check">' +
    iTrans('Done Briefing Passengers') +
    '</button>';
  $('#PAX_BulkBriefPassengerList').html(html);
  $('#PAX_BulkBriefPassengerList').enhanceWithin();
}

function PAX_ITIN_BulkRemovePax(index) {
  delete BRIEFING_MANIFEST.paxList[index];
  for (var i in PAX_Filtered) {
    var I = PAX_Filtered[i];
    if (I.PrimaryKey != index) continue;
    I.selected = false;
    break;
  }
  PAX_BulkBriefing_PaxDisplay();
}

function PAX_SendBulkBriefingData() {
  for (var i in BRIEFING_MANIFEST.paxList) {
    var I = BRIEFING_MANIFEST.paxList[i];
    if (I.briefingStatus.briefed == false) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Incompleted Briefing'),
        headerClose: false,
        buttonPrompt: I.firstname + ' ' + I.lastname + ' ' + iTrans('Has not completed their briefing.'),
        buttons: {
          [iTrans('Complete Briefing Now')]: {
            click: function () {
              PAX_doBriefing(i);
            },
          },
          [iTrans('Exit Briefings Anyway')]: {
            click: function () {
              //exit briefings
              PAX_BriefingsCompleted();
            },
          },
        },
      });
      return;
    }
  }
  PAX_BriefingsCompleted();
}
function PAX_UpdatePassengerBriefingData(I, B, modDate) {
  if (B.filledForm == null) return;
  I.Briefings.push(B.filledForm);
  I.modified = modDate;
  if (I.filledForms == undefined) I.filledForms = [];
  I.filledForms.push(B.filledForm);
}

function PAX_BriefingsCompleted(sync = true) {
  if (sync) {
    if (ONLINE && !LOW_BANDWIDTH) {
      PAX_syncPassengers(true);
    }
  }
  PAX_MANIFESTING = {
    returnTo: '#PAX_Manifesting_Main',
    Manifests: [],
    Manifests_Filtered: [],
    CurIndex: 0,
    CUR_MANIFEST: {
      PrimaryKey: 0,
      group: '',
      Name: '',
      Description: '',
      ClientFilter: -1,
      modified: null,
      DELETED: 0,
      PaxCount: 0,
      hasVIP: 0,
      Passengers: [],
    },
    AddingPax: false,
    AddingManifest: false,
    FromManifest: false,
    AddingManifestTo: '',
    briefingOnly: false,
    Cfields: [],
  };
  BRIEFING_MANIFEST = {
    paxList: [],
  };
  ITIN_Pax_Briefing = {};
  $.mobile.changePage($('#PAX_Manifesting_Main'), {
    reverse: true,
    changeHash: false,
  });
}

function PAX_Cfield_HTML(Cfields) {
  var html = '';
  for (var i in Cfields) {
    var C = Cfields[i];

    if (C.type == 'checkbox') {
      var checked = 'no';
      if (C.Value == 1) checked = 'yes';
      html += '<p>' + C.Label + ': ';
      html += checked + '</p>';
    } else if (C.type == 'signature') {
      html += '<p><b>' + C.Label + '</b>: ';
      try {
        var SigValue = JSON.parse(C.Value);
        if (SigValue.SignatureName != undefined) {
          html +=
            '<div style="display: inline-block;"><p class="wrap">' +
            JSON.parse(C.options).SignatureDescription +
            '</p>';
          html += '<img src="' + SigValue.data + '" width="321px" height="150px"/><br />';
          html += '<center><b>' + SigValue.SignatureName + '</b></center></div></p>';
        } else {
          html += iTrans('Unsigned') + '</p>';
        }
      } catch (e) {
        html += iTrans('Unsigned') + '</p>';
      }
    } else {
      html += '<p>' + C.Label + ': ';
      html += C.Value + '</p>';
    }
  }
  return html;
}

function paxManTableViewOptionsInit() {
  // Closes 'View Options' filter
  $('#PAX_Manifest_ViewOptions > .ui-collapsible').collapsible({
    collapsed: true,
  });

  if (VueBridge.getPreference('passengerManifesting.filters.client') === '1') {
    $('#PAX_Manifest_ShowClient').prop('checked', true).checkboxradio('refresh');
    $('#PAX_Manifest_ShowCompany').prop('checked', false).checkboxradio('refresh');
  } else {
    $('#PAX_Manifest_ShowClient').prop('checked', false).checkboxradio('refresh');
    $('#PAX_Manifest_ShowCompany').prop('checked', true).checkboxradio('refresh');
  }

  if (VueBridge.getPreference('passengerManifesting.filters.code') === '1') {
    $('#PAX_Manifest_ShowCode').prop('checked', true).checkboxradio('refresh');
    $('#PAX_Manifest_ShowWeight').prop('checked', false).checkboxradio('refresh');
  } else {
    $('#PAX_Manifest_ShowCode').prop('checked', false).checkboxradio('refresh');
    $('#PAX_Manifest_ShowWeight').prop('checked', true).checkboxradio('refresh');
  }
}

function selectColumnToDisplay(columnName) {
  if (columnName === 'client') {
    VueBridge.setPreference('passengerManifesting.filters.client', '1');

    $('[id^=PAX_ClientColumn]').removeClass('hidden');
    $('[id^=PAX_CompanyColumn]').addClass('hidden');
  } else if (columnName === 'company') {
    VueBridge.setPreference('passengerManifesting.filters.client', '0');

    $('[id^=PAX_CompanyColumn]').removeClass('hidden');
    $('[id^=PAX_ClientColumn]').addClass('hidden');
  } else if (columnName === 'code') {
    VueBridge.setPreference('passengerManifesting.filters.code', '1');

    $('[id^=PAX_CodeColumn]').removeClass('hidden');
    $('[id^=PAX_WeightColumn]').addClass('hidden');
  } else {
    VueBridge.setPreference('passengerManifesting.filters.code', '0');

    $('[id^=PAX_WeightColumn]').removeClass('hidden');
    $('[id^=PAX_CodeColumn]').addClass('hidden');
  }

  if (ONLINE) {
    // Debouncing so that a user doesn't make too many requests to the server due to spamming or making multiple clicks in quick succession
    VueBridge.debounce(async () => {
      await window.vueApp.repositories.userPreferences.syncOfflineChangedPassengerManifestPreferences(
        window.vueApp.groupId,
        window.vueApp.user.id
      );
    }, 500)();
  }
}

function renderHiddenClassForPaxManColumn(columnName) {
  if (columnName === 'client') {
    return VueBridge.getPreference('passengerManifesting.filters.client') === '0' ? 'hidden' : '';
  }

  if (columnName === 'company') {
    return VueBridge.getPreference('passengerManifesting.filters.client') === '1' ? 'hidden' : '';
  }

  if (columnName === 'code') {
    return VueBridge.getPreference('passengerManifesting.filters.code') === '0' ? 'hidden' : '';
  }

  // If columnName is 'weight'
  return VueBridge.getPreference('passengerManifesting.filters.code') === '1' ? 'hidden' : '';
}

/*
 * This function is used to show/hide either the weight or code column whenever we click on the
 * back button from the 'Add Passenger' page. The back button handler just changes to the page
 * you were on beforehand and doesn't rebuild the pax man table, so we need to manually handle that by
 * calling this function.
 */
function showPaxManWeightOrCodeColumn() {
  if (VueBridge.getPreference('passengerManifesting.filters.code') === '1') {
    $('[class^=pax-manifesting-weight-column]').addClass('hidden');
    $('[class^=pax-manifesting-code-column]').removeClass('hidden');
  } else {
    $('[class^=pax-manifesting-code-column]').addClass('hidden');
    $('[class^=pax-manifesting-weight-column]').removeClass('hidden');
  }
}
