/**
 *
 */
class FdtDaysV4Table extends AbstractDatabaseTable {
  constructor() {
    super('FDT_DaysV4', 'Date');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "id"            integer,
            "group"         text,
            "User_PK"       integer,
            "Modified"      text,
            "Date"          text,
            "Start"         integer,
            "End"           integer,
            "Start2"        integer,
            "End2"          integer,
            "FT"            numeric,
            "FT2"           numeric,
            "On"            integer,
            "Off"           integer,
            "On2"           integer,
            "Off2"          integer,
            "homeBase"      integer,
            "travelTime"    numeric,
            "delayedRep"    integer,
            "medevac"       integer,
            "homeBase2"     integer,
            "travelTime2"   numeric,
            "delayedRep2"   integer,
            "medevac2"      integer,
            "DFR"           text,
            "IDENT"         text,
            "Comment"       text,
            "Current"       integer,
            "EL"            integer,
            "Extend"        integer,
            "Split"         integer,
            "Standby"       integer,
            "IFR"           integer,
            "Heli"          integer,
            "AerialApp"     integer,
            "DHC"           integer,
            "Dual"          integer,
            "Logging"       integer,
            "Overnight"     integer,
            "Sectors"       integer,
            "CrewCount"     integer,
            "restType"      integer,
            "Timezone"      text,
            "TimezoneEnd"   text,
            "TimezoneEnd2"  text,
            "TimezoneHB"    text,
            "begLat"        numeric,
            "begLong"       numeric,
            "endLat"        numeric,
            "endLong"       numeric,
            "ET1"           numeric,
            "ET2"           numeric,
            "ET3"           numeric,
            "ET4"           numeric,
            "ET5"           numeric,
            "EC1"           integer,
            "EC2"           integer,
            "EC3"           integer,
            "EC4"           integer,
            "EC5"           integer,
            "Rule"          integer,
            "Rules"         text,
            "Status"        text,
            "Warning"       integer,
            "Warning_Ack"   integer,
            "Violation"     integer,
            "Violation_Ack" integer,
            "Rule_ID"       integer,
            "EXTRA"         text,
            "Scheduled"     integer,
            primary key (Date)
        )
    `;
    //#endregion
  }
}
