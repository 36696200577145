/**
 * Base class for all views that can contain FilterableItems
 * @author falko@air-suite.com
 * @copyright (c) 2018 AirSuite, All Rights Reserved.
 */
class vSAMA_AbstractFilterableView extends iSAMA_AbstractObservable {
  constructor(_emptyText = 'No data available.') {
    super();

    this.dom = {
      wrapper: $('<div/>'),
      listEmpty: $('<div/>'),
    };

    this.items = [];

    this.dom.listEmpty.addClass('sama-list-empty').text(_emptyText).appendTo(this.dom.wrapper);
  }

  AddItem(_item) {
    let iDom = _item.GetDom();
    this.items.push(_item);
    this.dom.wrapper.append(iDom);
    this.Observe(_item, 'ItemDeleted', (_i) => this.RemoveItem(_i));
  }

  RemoveItem(_item) {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].GetModelPk() === _item.GetModelPk()) {
        this.items.splice(i, 1);
      }
    }

    this.Refresh();
  }

  Refresh() {
    if (this.HasVisibleItems()) {
      this.dom.listEmpty.hide();
      return;
    }

    this.dom.listEmpty.slideDown();
  }

  OnSortItems(_visible) {
    // Now that they're sorted, move the items in order
    for (let vc = 0; vc < _visible.length; vc++) {
      _visible[vc].GetDom().prependTo(this.dom.wrapper);
    }

    this.Refresh();
  }

  GetItems() {
    return this.items;
  }

  HasVisibleItems() {
    return this.items.find((_e) => _e.visible === true) != null;
  }

  Clear() {
    this.items = [];
    this.dom.wrapper.children().not('.sama-list-empty').remove();

    this.Refresh();
  }

  GetDom() {
    return this.dom.wrapper;
  }

  Destroy() {
    this.dom.wrapper.remove();
  }
}
