function initChangeTimeInput() {
  localStorageDB.getItem('TimeInput', function (e) {
    let type = e;
    if (type == false || type == '' || type == null) {
      type = 'Clockpicker';
    }
    LOCALSTORAGE.TimeInput = type;

    // all time inputs in cirro
    let timeInputs = [
      {
        jQ: $('#edit_TimeBack'),
      },
      {
        jQ: $('#LegStart2E'),
      },
      {
        jQ: $('#LegStart2'),
      },
      {
        jQ: $('#LegEnd2'),
      },
      {
        jQ: $('#LegEnd2E'),
      },
      {
        jQ: $('#LegStart2E_pop'),
      },
      {
        jQ: $('#LegStart2_pop'),
      },
      {
        jQ: $('#LegEnd2_pop'),
      },
      {
        jQ: $('#LegEnd2E_pop'),
      },
      {
        jQ: $('#LegETA2'),
      },
      {
        jQ: $('#LegETE2'),
      },
      {
        jQ: $('#returnTime'),
        rightAlign: true,
      },
    ];

    // reset focus and taphold binds
    timeInputs.forEach((ti) => {
      ti.jQ.unbind('focus');
      ti.jQ.unbind('taphold');
    });

    if (type === 'Clockpicker') {
      timeInputs.forEach((ti) => {
        ti.jQ.clockpicker({
          autoclose: true,
          align: ti.hasOwnProperty('rightAlign') ? 'right' : 'left',
        });
        ti.jQ.focus(ClockPickerBlur);
      });
    }
    if (type === 'Keypad') {
      $.fn.numpad.defaults.gridTpl = '<table class="numpad"></table>';
      $.fn.numpad.defaults.backgroundTpl = '<div class="ui-popup-screen ui-overlay-a"></div>';
      $.fn.numpad.defaults.displayTpl = '<input data-theme="b" type="text"/>';
      $.fn.numpad.defaults.buttonNumberTpl = '<button data-role="button" data-theme="a"></button>';
      $.fn.numpad.defaults.buttonFunctionTpl = '<button data-role="button" data-theme="a"></button>';
      $.fn.numpad.defaults.onKeypadCreate = function () {
        $(this).enhanceWithin();
      };
      timeInputs.forEach((ti) => {
        ti.jQ.taphold(MobileNowTime);
        ti.jQ.numpad({
          hidePlusMinusButton: true,
          hideDecimalButton: true,
        });
      });
    }
    if (type === 'Keyboard') {
      timeInputs.forEach((ti) => {
        ti.jQ.focus(ClockPickerFocus);
      });
    }
  });
}
/**
 * Still used by capiShim to set the legacy time input
 */
function ClockPickerFocus() {
  this.select();
}
/**
 * Still used by capiShim to set the legacy time input
 */
function ClockPickerBlur() {
  this.blur();
}
