/**
 * Class handling the displaying of tabbed information and editors
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class vSAMA_TabbedEditor extends vSAMA_AbstractEditor {
  /**
   *
   * @param {string}  _p.noDataRider
   * @param {string}  _p.noDataBody
   * @param {string}  _p.addButtonLabel
   * @param {boolean} _p.readOnly
   */
  constructor(_p) {
    super();
    this.tabs = [];
    this.id = cSAMA_Utils.GetUID();

    let template = $(SAMA.Templates.tpl.tabEditor.frame);
    this.dom = {
      wrapper: template,
      bodyContainer: template.find('.tabedit-bodyContainer'),
      addButton: template.find('.tabedit-addButton'),
      riderContainer: template.find('.tabedit-riderbar'),
      noTabs: {
        rider: $('<li/>'),
        body: $('<div/>'),
      },
    };

    this.dom.addButton.find('span').text(_p.addButtonLabel);

    if (_p.readOnly) {
      this.dom.addButton.prop('disabled', true);
    }

    // No data tab
    this.dom.noTabs.rider.appendTo(this.dom.riderContainer);

    $('<a/>')
      .text(_p.noDataRider)
      .attr('href', '#tab' + this.id)
      .appendTo(this.dom.noTabs.rider);

    this.dom.noTabs.body
      .text(_p.noDataBody)
      .attr('id', 'tab' + this.id)
      .addClass('tabedit-tabBody')
      .appendTo(this.dom.bodyContainer);

    this.dom.wrapper.enhanceWithin();
  }

  /**
   * Gets a reference to the add button
   * @returns {jQuery}
   */
  GetAddButton() {
    return this.dom.addButton;
  }

  /**
   * Shows the given tab. Since this might be a batched  add, be sure to call 'Refresh'
   * after every solo-call to AddTab
   * @param {vSAMA_AbstractTab} _newTab The view of the tab
   */
  AddTab(_newTab) {
    _newTab.SetOwner(this);
    _newTab.rider.appendTo(this.dom.riderContainer);
    _newTab.body.appendTo(this.dom.bodyContainer);

    this.tabs.push(_newTab);
  }

  /**
   * Removes the given tab from the active tabs list
   * @param _tabId
   */
  TabDestroyed(_tabId) {
    this.tabs = this.tabs.filter((_e) => _e.id !== _tabId);
    this.Refresh();
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
  }

  /**
   * Sets tabs' heights to the tallest one in the editor
   * @private
   */
  ResetTabSize() {
    let newMaximum = 0;
    let current = this.dom.bodyContainer.tabs('option', 'active');

    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].body.css('min-height', 'auto');

      // Gotta set the tabs active to make them calculate their true height :I
      this.dom.bodyContainer.tabs('option', 'active', i);

      let ht = this.tabs[i].body.height();
      if (ht > newMaximum) {
        newMaximum = ht;
      }
    }

    for (let t = 0; t < this.tabs.length; t++) {
      this.tabs[t].body.css('min-height', newMaximum + 30 + 'px');
    }

    this.dom.bodyContainer.tabs('option', 'active', current);
  }

  Refresh() {
    let scrollTop = cSAMA_Utils.GetScrollTop();
    let numTabs = this.tabs.length;

    if (numTabs <= 0) {
      this.dom.noTabs.body.show();
      this.dom.noTabs.rider.show();
    } else {
      this.dom.noTabs.body.hide();
      this.dom.noTabs.rider.hide();
    }

    this.dom.bodyContainer.tabs('refresh');

    if (this.dom.bodyContainer.tabs('option', 'active') <= 0) {
      this.dom.bodyContainer.tabs('option', 'active', numTabs);
    }

    this.ResetTabSize();

    // Reset the view to where it was.
    $('html, body').animate(
      {
        scrollTop: scrollTop,
      },
      0
    );
  }

  Destroy() {
    this.dom.bodyContainer.tabs('destroy');

    for (let i = 0; i < this.tabs.length; i++) {
      this.tabs[i].DestroySilently();
    }

    this.dom.wrapper.remove();
  }

  GetTabs() {
    return this.tabs;
  }

  GetTabFromModelPk(_mPk) {
    return this.tabs.find((_t) => _t.GetModel().GetPk() === +_mPk);
  }

  GetDom() {
    return this.dom.wrapper;
  }
}
