/******************************* Staff Expenses *******************************************************************/
function BILLING_Return_Expenses() {
  if (FDT_Expense) {
    $.mobile.changePage($('#DutyTimes'), {
      reverse: true,
      changeHash: false,
    });
    FDT_LoadExpenses(refreshDATE);
  } else {
    BILLING_Expenses();
  }
}

function BILLING_LOAD_OFFLINE_EXPENSES(data) {
  //console.log(data);
  if (data.length > 0) {
    var html = '<h3>Offline Expenses</h3>';
    html +=
      '<table id="BIT_Expenses1" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Client') +
      '</th><th>' +
      iTrans('Date') +
      '</th><th>' +
      iTrans('Category') +
      '</th><th>' +
      iTrans('Merchant') +
      '</th><th>' +
      iTrans('Amount') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead>';
    for (var U in data) {
      //var actions = "<div class='vertical-center-container'><button onClick='BILLING_EditExpense(" + U + ")' data-mini='true' class='action-btn' disabled><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteExpense(" + data[U].ExpensePK + ")' data-mini='true' class='action-btn' disabled><img src='./images/delete.svg' /></button></div>";
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_DeleteExpense(" +
        data[U].ExpensePK +
        ")' data-mini='true' class='action-btn' disabled><img src='./images/delete.svg' /></button></div>";
      html +=
        '<tr><td>' +
        data[U].Business +
        '</td><td>' +
        data[U].date +
        '</td><td>' +
        data[U].Category +
        '</td><td>' +
        data[U].Merchant +
        '</td><td>' +
        data[U].Amount.toLocaleString('en-US', CUR) +
        '</td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</table>';
    $('#BILLING_EXPENSES_OFFLINE').html(html);
    $('#BILLING_EXPENSES_OFFLINE').enhanceWithin();
    AddTableSorter('BIT_Expenses1');
  } else {
    $('#BILLING_EXPENSES_OFFLINE').html('');
  }
}
function BILLING_Expenses() {
  FDT_Expense = false;
  cert_base64File = null;
  cert_Filename = 'N/A';
  BillingDB('SELECT', 'OFFLINE_SYNC_EXPENSE', null, BILLING_LOAD_OFFLINE_EXPENSES); // Store CURRENT_FLIGHT_REPORT to offline sync library
  $.mobile.changePage($('#BILLING_ExpensesPage'), {
    transition: 'none',
    changeHash: false,
  });
  //load approved and unapproved expenses
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpenses', function (json_data) {
      if (json_data.length == 0) {
        $('#BILLING_EXPENSES').html('<h1>' + iTrans('You have no expenses') + '</h1>');
        $('#BILLING_EXPENSES').enhanceWithin();
        return;
      }
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          $('#BILLING_EXPENSES').html('<h1>' + iTrans('Offline Cannot Retrieve Full List of Expenses') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_Expenses');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        //console.log("Received jobs List");
        BILLING_EXPENSES = json_data[0]; //set global
        BILLING_DisplayExpenses(json_data);
      }
    }).fail(function (jqXHR, status, error) {
      AJAXFAIL(error);
      $('#BILLING_EXPENSES').html('<h1>' + iTrans('Connection Error!') + '</h1>');
    });
  } else {
    $('#BILLING_EXPENSES').html('<h1>' + iTrans('Offline Cannot Retrieve Full List of Expenses') + '</h1>'); //retrieve old data from database
  }
}

function BILLING_DisplayExpenses(data) {
  //console.log(data);
  var Approved = data[0].Approved;
  var Unapproved = data[0].Unapproved;
  if ($('#BILLING_APPROVED').is(':checked')) {
    var html = '<h3>' + iTrans('Approved Expenses') + '</h3>';
    if (Approved.length > 0) {
      html +=
        '<table id="BIT_Expenses2" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Category') +
        '</th><th>' +
        iTrans('Merchant') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var A in Approved) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_ViewExpense(" +
          A +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
        html +=
          '<tr><td>' +
          Approved[A].Business +
          '</td><td>' +
          Approved[A].Date +
          '</td><td>' +
          Approved[A].Category +
          '</td><td>' +
          Approved[A].Merchant +
          '</td><td>' +
          Approved[A].Amount.toLocaleString('en-US', CUR) +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Approved Expenses') + '</p>';
  } else {
    var html = '<h3>' + iTrans('Unapproved Expenses') + '</h3>';
    if (Unapproved.length > 0) {
      html +=
        '<table id="BIT_Expenses2" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Category') +
        '</th><th>' +
        iTrans('Merchant') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in Unapproved) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_EditExpense(" +
          U +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteExpense(" +
          Unapproved[U].ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        html +=
          '<tr><td>' +
          Unapproved[U].Business +
          '</td><td>' +
          Unapproved[U].Date +
          '</td><td>' +
          Unapproved[U].Category +
          '</td><td>' +
          Unapproved[U].Merchant +
          '</td><td>' +
          Unapproved[U].Amount.toLocaleString('en-US', CUR) +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Unapproved Expenses') + '</p>';
  }
  $('#BILLING_EXPENSES').html(html);
  $('#BILLING_EXPENSES').enhanceWithin();
  AddTableSorter('BIT_Expenses2');
}

function BILLING_DeleteExpense(ExpensePK) {
  //console.log("Deleting ExpensePK: " + ExpensePK);
  //pop confirmation dialog
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this Expense?'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Billing-AJAX.php?action=deleteExpense&ExpensePK=' + ExpensePK,
            function (json_data) {
              if (json_data[0].ServerResponse != undefined) {
                //status could be offline/Not Logged In/Uncaught Exception
                if (json_data[0].ServerResponse == 'Offline') {
                  $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('Offline Cannot Delete Expense') + '</h1>'); //retrieve old data from database
                }
                if (json_data[0].ServerResponse == 'Uncaught Exception')
                  ServerResponse(json_data[0], 'Billing_DelteExpense');
                if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
                if (json_data[0].ServerResponse == 'Success') {
                  //console.log("Reloading Expenses");
                  if (statusTS.TSreturn == true) {
                    TS_LoadExpenses();
                  } else {
                    var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
                    if (page == 'BILLING_AdminExpensesPage') BILLING_LoadAdminExpenses();
                    else BILLING_Return_Expenses();
                  }
                }
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
            $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('Connection Error!') + '</h1>');
          });
          //File bug report
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function BILLING_EditExpense(index) {
  var B = (BILLING_EXPENSES.CurExpense = BILLING_EXPENSES.Unapproved[index]);
  //load jobs user is associated with to attach expense to
  //Allow no job selection for expense
  //console.log(B);
  $.mobile.changePage($('#BILLING_ExpenseJobPage'), {
    transition: 'none',
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobsCrew', function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('You have no active jobs') + '</h1>');
      $('#BILLING_EXPENSEJOBS').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('Offline Cannot Retrieve List of Jobs') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_NewExpense');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      //console.log("Received jobs List");
      BILLING_DisplayCrewJobs(json_data, B.JobID);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('Connection Error!') + '</h1>');
  });
}

function BILLING_EditExpenseFDT(index) {
  var B = (BILLING_EXPENSES.CurExpense = BILLING_EXPENSES.UserExpenses[index]);
  //load jobs user is associated with to attach expense to
  //Allow no job selection for expense
  //console.log(B);
  $.mobile.changePage($('#BILLING_ExpenseJobPage'), {
    transition: 'none',
    changeHash: false,
  });
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobsCrew', function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('You have no active jobs') + '</h1>');
      $('#BILLING_EXPENSEJOBS').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('Offline Cannot Retrieve List of Jobs') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_NewExpense');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      //console.log("Received jobs List");
      BILLING_DisplayCrewJobs(json_data, B.JobID);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('Connection Error!') + '</h1>');
  });
}

function BILLING_ViewExpense(index) {
  var B = BILLING_EXPENSES.Approved[index];
  //console.log(B);
  $.mobile.changePage($('#BILLING_ViewExpensePage'), {
    changeHash: false,
  });
  var html = '<h3>' + iTrans('Date') + ': ' + B.Date + '</h3>';
  html +=
    '<table id="BIT_Expenses3" class="limit-table"><thead><tr><th>' +
    iTrans('Field') +
    '</th><th>' +
    iTrans('Value') +
    '</th></tr></thead><tbody>';
  html += '<tr><td>' + iTrans('Job') + '#</td><td>' + B.JobID + '</td></tr>';
  html += '<tr><td>' + iTrans('Business') + '</td><td>' + B.Business + '</td></tr>';
  html += '<tr><td>' + iTrans('Job Name') + '</td><td>' + B.Name + '</td></tr>';
  html += '<tr><td>' + iTrans('Category') + '</td><td>' + B.Category + '</td></tr>';
  html += '<tr><td>' + iTrans('Merchant') + '</td><td>' + B.Merchant + '</td></tr>';
  html += '<tr><td>' + iTrans('Amount') + '</td><td>' + B.Amount.toLocaleString('en-US', CUR) + '</td></tr>';
  html += '<tr><td>' + iTrans('Comment') + '</td><td>' + B.Comment + '</td></tr>';
  html += '</tbody></table><br />';

  html += "<div id='BILLING_VIEW_EXPENSE_PHOTO'></div>";

  $('#BILLING_EXPENSEVIEW').html(html);
  $('#BILLING_EXPENSEVIEW').enhanceWithin();
  AddTableSorter('BIT_Expenses3');
  if (B.Filename != 'N/A') {
    var fileEXT = B.Filename.split('.').pop().toLowerCase();
    var filetype = 'data:image/png';
    if (fileEXT == 'pdf') filetype = 'data:application/' + fileEXT;
    else filetype = 'data:image/' + fileEXT;
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpensePhoto&PK=' + B.ExpensePK, function (json_data) {
      if (fileEXT == 'pdf')
        $('#BILLING_VIEW_EXPENSE_PHOTO').html(
          '<h3>' +
            B.Filename +
            "</h3><embed type='application/pdf' src='" +
            filetype +
            ';base64,' +
            json_data.photo +
            "' width='100%' height='500px' ></embed>"
        );
      else
        $('#BILLING_VIEW_EXPENSE_PHOTO').html(
          '<h3>' + B.Filename + "</h3><img src='" + filetype + ';base64,' + json_data.photo + "' />"
        );
    });
  } else $('#BILLING_VIEW_EXPENSE_PHOTO').html('');
}

function BILLING_ViewExpenseFDT(index) {
  var B = BILLING_EXPENSES.UserExpenses[index];
  //console.log(B);
  $.mobile.changePage($('#BILLING_ViewExpensePage'), {
    changeHash: false,
  });
  var html = '<h3>' + iTrans('Date') + ': ' + B.Date + '</h3>';
  html +=
    '<table id="BIT_Expenses3" class="limit-table"><thead><tr><th>' +
    iTrans('Field') +
    '</th><th>' +
    iTrans('Value') +
    '</th></tr></thead><tbody>';
  html += '<tr><td>' + iTrans('Job') + '#</td><td>' + B.JobID + '</td></tr>';
  html += '<tr><td>' + iTrans('Business') + '</td><td>' + B.Business + '</td></tr>';
  html += '<tr><td>' + iTrans('Job Name') + '</td><td>' + B.Name + '</td></tr>';
  html += '<tr><td>' + iTrans('Category') + '</td><td>' + B.Category + '</td></tr>';
  html += '<tr><td>' + iTrans('Merchant') + '</td><td>' + B.Merchant + '</td></tr>';
  html += '<tr><td>' + iTrans('Amount') + '</td><td>' + B.Amount.toLocaleString('en-US', CUR) + '</td></tr>';
  html += '<tr><td>' + iTrans('Comment') + '</td><td>' + B.Comment + '</td></tr>';
  html += '</tbody></table><br />';

  html += "<div id='BILLING_VIEW_EXPENSE_PHOTO'></div>";

  $('#BILLING_EXPENSEVIEW').html(html);
  $('#BILLING_EXPENSEVIEW').enhanceWithin();
  AddTableSorter('BIT_Expenses3');
  if (B.Filename != 'N/A') {
    var fileEXT = B.Filename.split('.').pop().toLowerCase();
    var filetype = 'data:image/png';
    if (fileEXT == 'pdf') filetype = 'data:application/' + fileEXT;
    else filetype = 'data:image/' + fileEXT;
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpensePhoto&PK=' + B.ExpensePK, function (json_data) {
      if (fileEXT == 'pdf')
        $('#BILLING_VIEW_EXPENSE_PHOTO').html(
          '<h3>' +
            B.Filename +
            "</h3><embed type='application/pdf' src='" +
            filetype +
            ';base64,' +
            json_data.photo +
            "' width='100%' height='500px' ></embed>"
        );
      else
        $('#BILLING_VIEW_EXPENSE_PHOTO').html(
          '<h3>' + B.Filename + "</h3><img src='" + filetype + ';base64,' + json_data.photo + "' />"
        );
    });
  } else $('#BILLING_VIEW_EXPENSE_PHOTO').html('');
}

function BILLING_EXPENSES_OBJ() {
  var date = '';
  if (FDT_Expense) {
    date = dateToStringObject(refreshDATE).substr(0, 10);
  } else {
    date = dateToStringObject(new Date()).substr(0, 10);
  }
  this.JobID = 0;
  this.Category = 'Meals';
  this.Amount = '';
  this.Merchant = '';
  this.Date = date;
  this.Comment = '';
  this.ExpensePK = 'None';
  if (COUNTRY == 'CANADA') this.Currency = 'CAD';
  if (COUNTRY == 'UNITED STATES') this.Currency = 'USD';
  if (COUNTRY == 'AUSTRALIA') this.Currency = 'AUD';
}
function BILLING_NewExpense() {
  $.mobile.changePage($('#BILLING_ExpenseJobPage'), {
    transition: 'none',
    changeHash: false,
  });
  BILLING_EXPENSES.CurExpense = new BILLING_EXPENSES_OBJ();
  $('#BILLING_EXPENSEPK').val('None');
  $('#BILLING_AMOUNT').val('');
  $('#BILLING_MERCHANT').val('');
  $('#BILLING_EXPENSEDATE').val('');
  $('#BILLING_COMMENT').val('');
  $('#BILLING_EXPENSE_PHOTO').html('');
  $('#BILLING_ATTACHMENT').val('');
  //load jobs user is associated with to attach expense to
  //Allow no job selection for expense

  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobsCrew', function (json_data) {
    if (json_data.length == 0) {
      $('#BILLING_EXPENSEJOBS').html('<h1>' + iTrans('You have no active jobs') + '</h1>');
      $('#BILLING_EXPENSEJOBS').enhanceWithin();
      return;
    }
    if (json_data[0].ServerResponse != undefined) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        //$("#BILLING_EXPENSEJOBS").html("<h1>Offline Cannot Retrieve List of Jobs</h1>"); //retrieve old data from database
        BillingDB('SELECT', 'JobsCrew', null, BILLING_DisplayCrewJobs); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'Billing_NewExpense');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    } else {
      //console.log("Received jobs List");
      BillingDB('INSERT', 'JobsCrew', json_data, StoredConfirm); // Store Jobs Crew Data
      BILLING_DisplayCrewJobs(json_data, 0);
    }
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    BillingDB('SELECT', 'JobsCrew', null, BILLING_DisplayCrewJobs); //retrieve old data from database
  });
}

function BILLING_DisplayCrewJobs(data, JobID = 0) {
  //console.log(data);
  //console.log(JobID);

  var html =
    '<table id="BIT_Expenses4" data-filter="true" class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Job Name') +
    '</th><th>' +
    iTrans('Job') +
    '#</th><th>' +
    iTrans('Client') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead>';
  for (var i in data) {
    var actions =
      "<div class='vertical-center-container'><button onClick='BILLING_SetExpenseJob(" +
      data[i].ClientPK +
      ', ' +
      data[i].JobID +
      ', "' +
      escapeHtml(data[i].Business) +
      "\")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
    if (data[i].JobID == JobID) {
      $('#BILLING_SelJobTXT').html('<b>Client: </b>' + data[i].Business + ' <b>Job: </b>' + data[i].Name);
      html +=
        '<tr class="yellowbg"><td>' +
        data[i].Name +
        '</td><td>' +
        data[i].JobNum +
        '</td><td>' +
        data[i].Business +
        '</td><td>' +
        actions +
        '</td></tr>';
    } else
      html +=
        '<tr><td>' +
        data[i].Name +
        '</td><td>' +
        data[i].JobNum +
        '</td><td>' +
        data[i].Business +
        '</td><td>' +
        actions +
        '</td></tr>';
  }
  html += '</table>';
  $('#BILLING_EXPENSEJOBS').html(html);
  $('#BILLING_EXPENSEJOBS').enhanceWithin();
  AddTableSorter('BIT_Expenses4');
}

function BILLING_SetExpenseJob(ClientPK, JobID, Business) {
  BILLING_EXPENSE_CLIENTPK = ClientPK;
  BILLING_EXPENSE_JOBID = JobID;
  BILLING_EXPENSE_BUSINESS = Business;
  if (ClientPK == -1) {
    ExchangeQuoteData('getClients', null, BILLING_SetExpenseClient);
  } else {
    BILLING_ExpenseCategoryPage();
  }
}
function BILLING_SetExpenseClient(Clients) {
  var html = '<label for="BILLING_QuoteClientSelect">' + iTrans('Select Client For Global Job') + '</label>';
  html += '<select id="BILLING_QuoteClientSelect" data-mini="true">';
  for (var i in Clients) {
    html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
  }
  html += '</select>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Client'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          var C = Clients[$('#BILLING_QuoteClientSelect').val()];
          BILLING_EXPENSE_CLIENTPK = C.PrimaryKey;
          BILLING_EXPENSE_BUSINESS = C.Business;
          BILLING_ExpenseCategoryPage();
        },
      },
    },
  });
}

function BILLING_SameExpenseJob() {
  if (BILLING_EXPENSES.CurExpense != undefined) {
    BILLING_EXPENSE_CLIENTPK = BILLING_EXPENSES.CurExpense.ClientPK;
    BILLING_EXPENSE_JOBID = BILLING_EXPENSES.CurExpense.JobID;
    BILLING_EXPENSE_BUSINESS = BILLING_EXPENSES.CurExpense.Business;
  } else {
    BILLING_EXPENSE_JOBID = 0;
    BILLING_EXPENSE_BUSINESS = 'Company';
  }

  BILLING_ExpenseCategoryPage();
}

function BILLING_NewExpensePage() {
  //load jobs to attach expense to
  //console.log("return to Expenses?");
  $.mobile.changePage($('#BILLING_ExpenseJobPage'), {
    transition: 'none',
    changeHash: false,
  });
}

function BILLING_ExpenseCategoryPage() {
  //Select Categories for Expense
  $.mobile.changePage($('#BILLING_ExpenseCategoryPage'), {
    transition: 'none',
    changeHash: false,
  });
  var B = BILLING_EXPENSES.CurExpense;
  if (B == undefined) B = { Category: 'Meals' };
  if (B.hasOwnProperty('Category')) {
    $('input[name=BILLING_EXPENSE_CATEGORIES][value=' + B.Category + ']').prop('checked', true);
    $('input[name=BILLING_EXPENSE_CATEGORIES').checkboxradio('refresh');
  } else {
    $('input[name=BILLING_EXPENSE_CATEGORIES][value=BILLING_Meals]').prop('checked', true);
    $('input[name=BILLING_EXPENSE_CATEGORIES').checkboxradio('refresh');
  }
}

function BILLING_ExpenseDetailsPage() {
  //Record Expense Details
  //console.log("Loading Expense Details");
  $.mobile.changePage($('#BILLING_ExpenseDetailsPage'), {
    transition: 'none',
    changeHash: false,
  });
  var B = BILLING_EXPENSES.CurExpense;
  if (B == undefined) B = {};
  if (!B.hasOwnProperty('Amount')) {
    if (COUNTRY == 'CANADA') B.Currency = 'CAD';
    if (COUNTRY == 'UNITED STATES') B.Currency = 'USD';
    if (COUNTRY == 'AUSTRALIA') B.Currency = 'AUD';
    B.Paid_By = 'Employee';
    B.Filename = 'N/A';
    B.Amount = 0;
    B.Merchant = '';
    B.Comment = '';
    B.ExpensePK = 'None';
    $('#BILLING_ATTACHMENT').val('');
  }
  $('#BILLING_AMOUNT').val(B.Amount);
  $('#BILLING_MERCHANT').val(B.Merchant);
  $('#BILLING_EXPENSEDATE').val(B.Date);
  $('#BILLING_COMMENT').val(B.Comment);
  $('#BILLING_EXPENSEPK').val(B.ExpensePK);
  $('#BILLING_EXPENSE_CURRENCY').val(B.Currency).selectmenu('refresh');
  switch (B.Paid_By) {
    case 'Employee':
      $('#BILLING_PAIDBYME').prop('checked', true).checkboxradio('refresh');
      $('#BILLING_PAIDBYCOMPANY').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_PAIDBYACCOUNT').prop('checked', false).checkboxradio('refresh');
      break;
    case 'Company':
      $('#BILLING_PAIDBYME').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_PAIDBYCOMPANY').prop('checked', true).checkboxradio('refresh');
      $('#BILLING_PAIDBYACCOUNT').prop('checked', false).checkboxradio('refresh');
      break;
    case 'Account':
      $('#BILLING_PAIDBYME').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_PAIDBYCOMPANY').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_PAIDBYACCOUNT').prop('checked', true).checkboxradio('refresh');
      break;
  }
  if (B.Filename != 'N/A' && B.hasOwnProperty('Filename')) {
    var filetype = B.Filename.split('.').pop().toLowerCase();
    if (filetype == 'pdf') filetype = 'data:application/' + filetype;
    else filetype = '' + filetype;
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpensePhoto&PK=' + B.ExpensePK, function (json_data) {
      //$("#BILLING_EXPENSE_PHOTO").html("<h3>"+B.Filename+"</h3><img src='"+filetype+";base64,"+json_data.photo+"' />");
      if (B.Filename.split('.').pop().toLowerCase() == 'pdf') {
        $('#BILLING_EXPENSE_PHOTO').html('<h3>' + B.Filename + "</h3><div id='UserExpenseDIV'></div>");
        BILLING_ShowExpensePDF(json_data.photo, 'UserExpenseDIV');
      } else $('#BILLING_EXPENSE_PHOTO').html('<h3>' + B.Filename + "</h3><img class='limit-table' src='data:image/" + filetype + ';base64,' + json_data.photo + "' />");
    });
  } else $('#BILLING_EXPENSE_PHOTO').html('');
  //console.log(B);
}
function BILLING_ShowExpensePDF(data, divID) {
  $('#' + divID).html('<img src="./images/loading_processmaker.gif" />');
  pdfjsLib.disableWorker = true; // due to CORS
  var loadingTask = pdfjsLib.getDocument('data:application/pdf;base64,' + data);
  LoadPDFPages(loadingTask, divID);
}
var ExpenseData;
function BILLING_ExpenseDone() {
  //upload to server and return to expenses home page
  //console.log("Sending data to server");
  var Currency = $('#BILLING_EXPENSE_CURRENCY').val();
  var Amount = $('#BILLING_AMOUNT').val();
  var Merchant = $('#BILLING_MERCHANT').val();
  var Category = $('input[name=BILLING_EXPENSE_CATEGORIES]:checked').val();
  var date = $('#BILLING_EXPENSEDATE').val();
  var Paid_By = 'Employee';
  if ($('#BILLING_PAIDBYCOMPANY').is(':checked')) Paid_By = 'Company';
  if ($('#BILLING_PAIDBYACCOUNT').is(':checked')) Paid_By = 'Account';
  var Comment = $('#BILLING_COMMENT').val();
  var ExpensePK = $('#BILLING_EXPENSEPK').val();
  var B = BILLING_EXPENSES.CurExpense;
  if (B == undefined) B = '';
  var curFilename = 'N/A';
  if (B.hasOwnProperty('Filename')) curFilename = B.Filename;
  ////console.log(file64);
  if (date == '') {
    alert('Expense data cannot be blank!');
    return;
  }
  ExpenseData = {
    Filename: cert_Filename,
    Amount: Amount,
    Merchant: Merchant,
    date: date,
    Paid_By: Paid_By,
    Comment: Comment,
    Category: Category,
    Currency: Currency,
    ExpensePK: ExpensePK,
    curFilename: curFilename,
    Attachment: cert_base64File,
    JobID: BILLING_EXPENSE_JOBID,
    ClientID: BILLING_EXPENSE_CLIENTPK,
    Business: BILLING_EXPENSE_BUSINESS,
  };
  if (ONLINE) {
    $.ajax({
      xhr: function () {
        var xhr = new window.XMLHttpRequest();
        xhr.upload.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
              if (percentComplete == 100) $('#SCHfileprogress').html('<h2>' + iTrans('Processing') + '...</h2>');
              else $('#SCHfileprogress').html('<h2>' + iTrans('Uploading') + ': ' + percentComplete + '%</h2>');
            }
          },
          false
        );
        xhr.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              //console.log("Received: " + percentComplete);
            }
          },
          false
        );
        return xhr;
      },
      type: 'POST',
      url:
        BASEURL +
        'menu-Billing-AJAX.php?action=createExpense&ClientID=' +
        BILLING_EXPENSE_CLIENTPK +
        '&JobID=' +
        BILLING_EXPENSE_JOBID,
      data: ExpenseData,
      success: function (data) {
        data = jQuery.parseJSON(data);
        if (data[0].ServerResponse == 'Success' || data[0].ServerResponse == 'Nothing Changed') {
          //console.log("Expense Attachment Successfully Updated");
          //$.mobile.changePage( $("#Schedule_Page"), {  reverse: true, changeHash: false });
          $('#SCHfileprogress').html('<h2>' + iTrans('Successfully Uploaded') + '</h2>');
          BILLING_Return_Expenses(); //go back to billing expense page
          cert_base64File = null;
          cert_Filename = 'N/A';
          $('#BILLING_ATTACHMENT').val('');
        } else {
          //insert failed server error
          //console.log("Cert Update Failed: " + data[0].ServerResponse);
          if (data[0].ServerResponse == 'Uncaught Exception') ServerResponse(data[0], 'SCHuploadfile');
          if (data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('ERROR'),
            headerClose: false,
            buttonPrompt:
              iTrans('There was a problem sending this expense to the server.  Would you like to try resending?') +
              '<br /><b>' +
              iTrans("If you're offline the expense will be submitted when you're online next time") +
              '</b>',
            buttons: {
              [iTrans('Resend')]: {
                click: function () {
                  BILLING_ExpenseDone();
                },
              },
              [iTrans('Store Offline')]: {
                click: function () {
                  //Uncheck the non-billable checkbox
                  $('#SCHfileprogress').html('<h2>' + iTrans('Successfully Uploaded') + '</h2>');
                  BILLING_Return_Expenses(); //go back to billing expense page
                  cert_base64File = null;
                  cert_Filename = 'N/A';
                  $('#BILLING_ATTACHMENT').val('');
                  $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                  BILLING_STORE_OFFLINE_EXPENSE();
                },
              },
            },
          });
        }
        BMFILE = null;
        BMFILENAME = null;
      },
      error: function (jqXHR, status, error) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt:
            iTrans('There was a problem sending this expense to the server.  Would you like to try resending?') +
            '<br /><b>' +
            iTrans("If you're offline the expense will be submitted when you're online next time") +
            '</b>',
          buttons: {
            [iTrans('Resend')]: {
              click: function () {
                BILLING_ExpenseDone();
              },
            },
            [iTrans('Store Offline')]: {
              click: function () {
                //Uncheck the non-billable checkbox
                $('#SCHfileprogress').html('<h2>' + iTrans('Successfully Uploaded') + '</h2>');
                BILLING_Return_Expenses(); //go back to billing expense page
                cert_base64File = null;
                cert_Filename = 'N/A';
                $('#BILLING_ATTACHMENT').val('');
                $('#Billing_SaveFlightReportBTN').prop('disabled', false);
                BILLING_STORE_OFFLINE_EXPENSE();
              },
            },
          },
        });
      },
    });
  } else {
    //Uncheck the non-billable checkbox
    $('#SCHfileprogress').html('<h2>' + iTrans('Successfully Uploaded') + '</h2>');
    BILLING_Return_Expenses(); //go back to billing expense page
    cert_base64File = null;
    cert_Filename = 'N/A';
    $('#BILLING_ATTACHMENT').val('');
    $('#Billing_SaveFlightReportBTN').prop('disabled', false);
    BILLING_STORE_OFFLINE_EXPENSE();
  }
}

function BILLING_STORE_OFFLINE_EXPENSE(data) {
  BillingDB('SELECT', 'OFFLINE_SYNC_EXPENSE', null, BILLING_Retrieved_SyncExpenseItems);
}

function BILLING_Retrieved_SyncExpenseItems(data) {
  if (data == false) {
    //No current offline Data to append to
    data = [];
  }
  data.push(ExpenseData);
  BillingDB('INSERT', 'OFFLINE_SYNC_EXPENSE', data, BILLING_OfflineStoredExpense); // Store CURRENT_FLIGHT_REPORT to offline sync library
}

function BILLING_OfflineStoredExpense(stored) {
  if (!stored) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt:
        '<b>' +
        iTrans(
          'There was a problem storing this expense for offline synchronization.  This is really bad Sorry :(.  Please let our tech support know.  You can try again now if you would like'
        ) +
        '</b>',
      buttons: {
        'Try Again': {
          click: function () {
            BILLING_ExpenseDone();
          },
        },
        No: {
          click: function () {
            ExpenseData = {};
            $('#SCHfileprogress').html('<h2>' + iTrans('Failed') + '</h2>');
            BILLING_Return_Expenses(); //go back to billing expense page
            cert_base64File = null;
            cert_Filename = 'N/A';
            $('#BILLING_ATTACHMENT').val('');
          },
        },
      },
    });
  } else {
    ExpenseData = {};
    $('#SCHfileprogress').html('<h2>' + iTrans('Failed') + '</h2>');
    BILLING_Return_Expenses(); //go back to billing expense page
    cert_base64File = null;
    cert_Filename = 'N/A';
    $('#BILLING_ATTACHMENT').val('');
  }
}

/******************************* Aministration of Expenses *******************************************************************/

var BILLING_ADMIN_EXPENSES = {};

function BILLING_returnAdminExpenses() {
  if (statusTS.TSreturn == true) {
    $.mobile.changePage($('#Sub_Report_Page'), {
      changeHash: false,
      reverse: true,
    });
    TS_LoadExpenses();
  } else {
    $.mobile.changePage($('#BILLING_AdminExpensesPage'), {
      changeHash: false,
      reverse: true,
    });
    BILLING_LoadAdminExpenses();
  }
}

function BILLING_AdminExpenses() {
  cert_base64File = null;
  cert_Filename = 'N/A';
  statusTS.TSreturn = false;
  $.mobile.changePage($('#BILLING_AdminExpensesPage'), {
    changeHash: false,
  });
  BILLING_LoadAdminExpenses();
}

function BILLING_LoadAdminExpenses() {
  //switch to admin page and get data from server

  $('#BILLING_ADMINEXPENSES').html('<h2>' + iTrans('Retrieving Expenses') + '</h2>');
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getAdminExpenses', function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Get Billing Job Data') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminNewInvoice');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        BILLING_ADMIN_EXPENSES.Expenses = json_data[0];
        BILLING_SHOWADMINEXPENSES();
      }
    } else {
      $('#BILLING_ADMINEXPENSES').html('<h2>' + iTrans('No Expense Data Try Again Later') + '</h2>');
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function BILLING_SHOWADMINEXPENSES() {
  var E = BILLING_ADMIN_EXPENSES.Expenses;
  //display list of expenses for admin users in BILLING_ADMINEXPENSES
  var Approved = E.Approved;
  var Unapproved = E.Unapproved;
  var NotImbursed = E.NotImbursed;
  var total = 0;
  if ($('#BILLING_ADMINAPPROVED').is(':checked')) {
    var html = '<h3>' + iTrans('Approved Billable Expenses') + " <div id='AdminTotalCategory'</div></h3>";
    if (Approved.length > 0) {
      html +=
        '<table id="BIT_Expenses5" data-filter="true" class="first-td-fill"><thead><tr><th>' +
        iTrans('User') +
        '</th><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Category') +
        '</th><th>' +
        iTrans('Merchant') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Processed By') +
        '</th><th>' +
        iTrans('UTC Timestamp') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var A in Approved) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_AdminViewExpense(" +
          A +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button>" +
          "<button onClick='BILLING_MarkReimbursed(" +
          Approved[A].ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button>" +
          "</button><button onClick='BILLING_DeleteExpense(" +
          Approved[A].ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        html +=
          '<tr><td>' +
          Approved[A].chatname +
          '</td><td>' +
          Approved[A].Business +
          '</td><td>' +
          Approved[A].Date +
          '</td><td>' +
          Approved[A].Category +
          '</td><td>' +
          Approved[A].Merchant +
          '</td><td>' +
          Approved[A].Amount.toLocaleString('en-US', CUR) +
          ' ' +
          Approved[A].Currency +
          '</td><td>' +
          Approved[A].ProcessedBy +
          '</td><td>' +
          Approved[A].DateTime +
          '</td><td>' +
          actions +
          '</td></tr>';
        total += Approved[A].Amount;
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Unbilled Approved Expenses') + '</p>';
  }
  if ($('#BILLING_ADMINUNAPPROVED').is(':checked')) {
    var html = '<h3>' + iTrans('Unapproved Expenses') + "<div id='AdminTotalCategory'</div></h3>";
    if (Unapproved.length > 0) {
      html +=
        '<table id="BIT_Expenses5" data-filter="true" class="first-td-fill"><thead><tr><th>' +
        iTrans('User') +
        '</th><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Category') +
        '</th><th>' +
        iTrans('Merchant') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Processed By') +
        '</th><th>' +
        iTrans('UTC Timestamp') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in Unapproved) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_ProcessExpense(" +
          U +
          ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_DeleteExpense(" +
          Unapproved[U].ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
        html +=
          '<tr><td>' +
          Unapproved[U].chatname +
          '</td><td>' +
          Unapproved[U].Business +
          '</td><td>' +
          Unapproved[U].Date +
          '</td><td>' +
          Unapproved[U].Category +
          '</td><td>' +
          Unapproved[U].Merchant +
          '</td><td>' +
          Unapproved[U].Amount.toLocaleString('en-US', CUR) +
          ' ' +
          Unapproved[U].Currency +
          '</td><td>' +
          Unapproved[U].ProcessedBy +
          '</td><td>' +
          Unapproved[U].DateTime +
          '</td><td>' +
          actions +
          '</td></tr>';
        total += Unapproved[U].Amount;
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Unapproved Expenses') + '</p>';
  }
  if ($('#BILLING_ADMINNOTREIMBURSED').is(':checked')) {
    var html = '<h3>' + iTrans('Reimbursable Expenses') + " <div id='AdminTotalCategory'</div></h3>";
    if (NotImbursed.length > 0) {
      html +=
        '<table id="BIT_Expenses5" data-filter="true" class="first-td-fill"><thead><tr><th>' +
        iTrans('User') +
        '</th><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Category') +
        '</th><th>' +
        iTrans('Merchant') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Processed By') +
        '</th><th>' +
        iTrans('UTC Timestamp') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in NotImbursed) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_MarkReimbursed(" +
          NotImbursed[U].ExpensePK +
          ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
        html +=
          '<tr><td>' +
          NotImbursed[U].chatname +
          '</td><td>' +
          NotImbursed[U].Business +
          '</td><td>' +
          NotImbursed[U].Date +
          '</td><td>' +
          NotImbursed[U].Category +
          '</td><td>' +
          NotImbursed[U].Merchant +
          '</td><td>' +
          NotImbursed[U].Amount.toLocaleString('en-US', CUR) +
          ' ' +
          NotImbursed[U].Currency +
          '</td><td>' +
          NotImbursed[U].ProcessedBy +
          '</td><td>' +
          NotImbursed[U].DateTime +
          '</td><td>' +
          actions +
          '</td></tr>';
        total += NotImbursed[U].Amount;
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Pending Reimbursable Expenses') + '</p>';
  }
  $('#BILLING_ADMINEXPENSES').html(html);
  $('#BILLING_ADMINEXPENSES').enhanceWithin();
  AddTableSorter('BIT_Expenses5');
  total = Math.round(total * 100) / 100;
  if (total != 0) $('#AdminTotalCategory').html(' Totaling ' + total.toLocaleString('en-US', CUR));
}

function BILLING_MarkReimbursed(ExpensePK) {
  //TODO Prompt for confirmation the mark expense as Paid=1
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to mark this expense as Reimbursed?'),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          //Mark Invoice as Paid
          $.getJSON(
            BASEURL + 'menu-Billing-AJAX.php?action=markExpensePaid&ExpensePK=' + ExpensePK,
            function (json_data) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Offline') {
                alert('<h1>' + iTrans('Offline Cannot Update Expense') + '</h1>'); //retrieve old data from database
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'BILLING_PendingInvoicePaid');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Success') {
                if (statusTS.TSreturn == true) {
                  TS_LoadExpenses();
                } else {
                  BILLING_LoadAdminExpenses(); //reload expense data
                }
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function BILLING_AdminViewExpense(index) {
  //TODO VIEW PICTURES OF RECEIPT
  // BILLING_ADMINEXPENSEVIEW div for admin expense view

  var B = BILLING_ADMIN_EXPENSES.Expenses.Approved[index];
  //console.log(B);
  $.mobile.changePage($('#BILLING_AdminViewExpensePage'), {
    changeHash: false,
  });
  if (B.Filename != 'N/A') {
    var fileEXT = B.Filename.split('.').pop().toLowerCase();
    var filetype = 'data:image/png';
    if (fileEXT == 'pdf') filetype = 'data:application/' + fileEXT;
    else filetype = 'data:image/' + fileEXT;
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpensePhoto&PK=' + B.ExpensePK, function (json_data) {
      //$("#BILLING_ADMIN_EXPENSE_VIEW_PHOTO").html("<h3>"+B.Filename+"</h3><img src='data:image/"+filetype+";base64,"+json_data.photo+"' />");
      B.fileType = filetype;
      B.fileData = json_data.photo;
      displayExpenseInfo(B, fileEXT);
    });
  } else {
    B.fileType = '';
    B.fileData = '';
    displayExpenseInfo(B, null);
  }
}

function displayExpenseInfo(B, fileEXT) {
  var html = '<h3>' + iTrans('Date') + ': ' + B.Date + '</h3>';
  html +=
    '<table id="BIT_Expenses6" class="limit-table"><thead><tr><th>' +
    iTrans('Field') +
    '</th><th>' +
    iTrans('Value') +
    '</th></tr></thead><tbody>';
  html += '<tr><td>' + iTrans('Expense') + ' PK</td><td>' + B.ExpensePK + '</td></tr>';
  html += '<tr><td>' + iTrans('Business') + '</td><td>' + B.Business + '</td></tr>';
  html += '<tr><td>' + iTrans('Job Name') + '</td><td>' + B.Name + '</td></tr>';
  html += '<tr><td>' + iTrans('Category') + '</td><td>' + B.Category + '</td></tr>';
  html += '<tr><td>' + iTrans('Merchant') + '</td><td>' + B.Merchant + '</td></tr>';
  html += '<tr><td>' + iTrans('Amount') + '</td><td>' + B.Amount.toLocaleString('en-US', CUR) + '</td></tr>';
  html += '<tr><td>' + iTrans('Comment') + '</td><td>' + B.Comment + '</td></tr>';
  html += '</tbody></table><br />';
  if (B.fileData != '') {
    if (fileEXT == 'pdf')
      html +=
        "<embed type='application/pdf' src='" +
        B.fileType +
        ';base64,' +
        B.fileData +
        "' width='100%' height='500px'></embed>";
    else html += "<img src='data:" + B.fileType + ';base64,' + B.fileData + "' width='auto' height='auto'/>";
    //html += "<img src='data:"+B.fileType+";base64,"+B.fileData+"' width='auto' height='auto'/>";
  }
  $('#BILLING_ADMINEXPENSEVIEW').html(html);
  $('#BILLING_ADMINEXPENSEVIEW').enhanceWithin();
  AddTableSorter('BIT_Expenses6');
}

function BILLING_AdminExpenseJobChange(e) {
  if (e.value != -1) {
    var C = BILLING_ADMIN_EXPENSES.Expenses.Jobs[e.value];
    if (C.ClientPK == 0) {
      ExchangeQuoteData('getClients', null, BILLING_AdminExpenseJobChange_SelectClient);
    } else {
      $('#BILLING_ADMINEXPENSE_Client').val(C.Business);
      $('#BILLING_ADMINEXPENSE_ClientID').val(C.ClientPK);
    }
  } else {
    //is no Job
    $('#BILLING_ADMINEXPENSE_Client').val('[Internal Company Expense]').selectmenu('refresh');
    $('#BILLING_ADMINEXPENSE_ClientID').val(0);
  }
}
function BILLING_AdminExpenseJobChange_SelectClient(Clients) {
  console.log(Clients);
  var html = '<label for="BILLING_QuoteClientSelect">' + iTrans('Select Client For Global Job') + '</label>';
  html += '<select id="BILLING_QuoteClientSelect" data-mini="true">';
  for (var i in Clients) {
    html += '<option value="' + i + '">' + Clients[i].Business + '</option>';
  }
  html += '</select>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Client'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          //do nothing
          var C = Clients[$('#BILLING_QuoteClientSelect').val()];
          $('#BILLING_ADMINEXPENSE_Client').val(C.Business);
          $('#BILLING_ADMINEXPENSE_ClientID').val(C.ClientPK);
        },
      },
    },
  });
}
function BILLING_ProcessExpense(index) {
  //TODO Process unapproved expense
  if (statusTS.TSreturn == true) {
    var E = statusTS.Expenses.UserExpenses;
    var Jobs = statusTS.Expenses.Jobs;
    var StaffList = statusTS.Expenses.StaffList;
    var B = statusTS.Expenses.UserExpenses[index];
  } else {
    var Jobs = BILLING_ADMIN_EXPENSES.Expenses.Jobs;
    var StaffList = BILLING_ADMIN_EXPENSES.Expenses.StaffList;
    var B = BILLING_ADMIN_EXPENSES.Expenses.Unapproved[index];
  }

  $.mobile.changePage($('#BILLING_AdminExpenseDetailsPage'), {
    changeHash: false,
  });
  $('#BILLING_ADMINEXPENSE_Incurredby').empty();
  $('#BILLING_ADMINEXPENSE_Incurredby').append($('<option selected/>').val(-1).text('Myself'));
  for (var i in StaffList)
    $('#BILLING_ADMINEXPENSE_Incurredby').append(
      $('<option />').val(StaffList[i].PrimaryKey).text(StaffList[i].chatname)
    );
  $('#BILLING_ADMINEXPENSE_Job').empty();
  $('#BILLING_ADMINEXPENSE_Job').append($('<option selected/>').val(-1).text('No Job'));
  var JobIndex = -1;
  for (var j in Jobs) {
    $('#BILLING_ADMINEXPENSE_Job').append(
      $('<option />')
        .val(j)
        .text(Jobs[j].Name + ' - [' + Jobs[j].Business + '] ')
    );
    if (Jobs[j].PrimaryKey == B.JobID) {
      JobIndex = j;
    }
  }
  BILLING_ADMIN_EXPENSES.Approve = B;

  console.log(B);
  $('#BILLING_ADMINEXPENSE_Incurredby').val(B.UserPK).selectmenu('refresh');
  $('#BILLING_ADMINEXPENSE_Job').val(JobIndex).selectmenu('refresh');
  $('#BILLING_ADMINEXPENSE_Client').val(B.Business);
  $('#BILLING_ADMINEXPENSE_ClientID').val(B.ClientPK);
  $('#BILLING_ADMINEXPENSE_CURRENCY').val(B.Currency).selectmenu('refresh');
  $('#BILLING_ADMINAMOUNT').val(B.Amount);
  $('#BILLING_ADMINAMOUNT_R').val(B.Amount_R);
  $('#BILLING_ADMINAMOUNT_B').val(B.Amount_B);
  if (B.JobID != 0) $('#BILLING_ADMINAMOUNT_B').val(B.Amount_B);
  $('#BILLING_ADMINMERCHANT').val(B.Merchant);
  $('#BILLING_ADMINEXPENSEDATE').val(B.Date);
  $('#BILLING_ADMINCOMMENT').val(B.Comment);
  $('#BILLING_ADMINEXPENSE_CATEGORIES').val(B.Category).selectmenu('refresh');
  switch (B.Paid_By) {
    case 'Employee':
      $('#BILLING_ADMINPAIDBYME').prop('checked', true).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYCOMPANY').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYACCOUNT').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYME').prop('checked', true).checkboxradio('refresh');
      //auto populate re-emburse full amount
      $('#BILLING_ADMINAMOUNT_R').val(B.Amount_R);
      break;
    case 'Company':
      $('#BILLING_ADMINPAIDBYME').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYCOMPANY').prop('checked', true).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYACCOUNT').prop('checked', false).checkboxradio('refresh');
      //Clear re-emburse fullamout
      $('#BILLING_ADMINAMOUNT_R').val(0);
      break;
    case 'Account':
      $('#BILLING_ADMINPAIDBYME').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYCOMPANY').prop('checked', false).checkboxradio('refresh');
      $('#BILLING_ADMINPAIDBYACCOUNT').prop('checked', true).checkboxradio('refresh');
      // Clear re-emburse full amount
      $('#BILLING_ADMINAMOUNT_R').val(0);
      break;
  }
  if (B.Reimburse == 1) {
    $('#BILLING_ADMINREIMBURSABLEYES').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_ADMINREIMBURSABLENO').prop('checked', false).checkboxradio('refresh');
  } else {
    $('#BILLING_ADMINREIMBURSABLEYES').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_ADMINREIMBURSABLENO').prop('checked', true).checkboxradio('refresh');
  }
  if (B.Billable == 0) {
    $('#BILLING_ADMINBILLABLEYES').prop('checked', false).checkboxradio('refresh');
    $('#BILLING_ADMINBILLABLENO').prop('checked', true).checkboxradio('refresh');
  } else {
    $('#BILLING_ADMINBILLABLEYES').prop('checked', true).checkboxradio('refresh');
    $('#BILLING_ADMINBILLABLENO').prop('checked', false).checkboxradio('refresh');
  }
  if (B.Filename != 'N/A') {
    var fileEXT = B.Filename.split('.').pop().toLowerCase();
    var filetype = 'data:image/png';
    if (fileEXT == 'pdf') filetype = 'data:application/' + fileEXT;
    else filetype = 'data:image/' + fileEXT;
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getExpensePhoto&PK=' + B.ExpensePK, function (json_data) {
      //$("#BILLING_ADMIN_EXPENSE_PHOTO").html("<h3>"+B.Filename+"</h3><img src='"+filetype+";base64,"+json_data.photo+"' />");
      if (fileEXT == 'pdf') {
        $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + B.Filename + "</h3><div id='AdminExpenseDIV'></div>");
        BILLING_ShowExpensePDF(json_data.photo, 'AdminExpenseDIV');
      } else $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + B.Filename + "</h3><img src='" + filetype + ';base64,' + json_data.photo + "' />");
    });
  } else $('#BILLING_ADMIN_EXPENSE_PHOTO').html('');
  $('#BILLING_ADMINEXPENSEPK').val(B.ExpensePK);
}

function BILLING_AdminCreateNewExpense() {
  //TODO Process unapproved expense
  var Jobs = BILLING_ADMIN_EXPENSES.Expenses.Jobs;
  var StaffList = BILLING_ADMIN_EXPENSES.Expenses.StaffList;
  $.mobile.changePage($('#BILLING_AdminExpenseDetailsPage'), {
    changeHash: false,
  });
  $('#BILLING_ADMINEXPENSE_Incurredby').empty();
  $('#BILLING_ADMINEXPENSE_Incurredby').append($('<option selected/>').val(-1).text('Myself'));
  for (var i in StaffList)
    $('#BILLING_ADMINEXPENSE_Incurredby').append(
      $('<option />').val(StaffList[i].PrimaryKey).text(StaffList[i].chatname)
    );
  $('#BILLING_ADMINEXPENSE_Job').empty();
  $('#BILLING_ADMINEXPENSE_Job').append($('<option selected/>').val(-1).text('No Job'));
  var JobIndex = -1;
  for (var j in Jobs) {
    $('#BILLING_ADMINEXPENSE_Job').append(
      $('<option />')
        .val(j)
        .text(Jobs[j].Name + ' - [' + Jobs[j].Business + '] ')
    );
  }
  $('#BILLING_ADMINEXPENSE_Incurredby').val(-1).selectmenu('refresh');
  $('#BILLING_ADMINEXPENSE_Job').val(-1).selectmenu('refresh');
  $('#BILLING_ADMINEXPENSE_Client').val('');
  $('#BILLING_ADMINAMOUNT').val('');
  $('#BILLING_ADMINMERCHANT').val('');
  $('#BILLING_ADMINEXPENSEDATE').val('');
  $('#BILLING_ADMINCOMMENT').val('');
  $('#BILLING_ADMINEXPENSE_CATEGORIES').val('Meals').selectmenu('refresh');

  $('#BILLING_ADMINPAIDBYME').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_ADMINPAIDBYCOMPANY').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_ADMINPAIDBYACCOUNT').prop('checked', false).checkboxradio('refresh');

  $('#BILLING_ADMINREIMBURSABLEYES').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_ADMINREIMBURSABLENO').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_ADMINAMOUNT_R').val('');

  $('#BILLING_ADMINBILLABLEYES').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_ADMINBILLABLENO').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_ADMINEXPENSEPK').val('None');
  $('#BILLING_ADMIN_EXPENSE_PHOTO').html('');
  cert_base64File = null;
  cert_Filename = 'N/A';
  $('#BILLING_ADMIN_ATTACHMENT').val(null);
}

function BILLING_AdminApproveExpense(Approved) {
  if ($('#BILLING_ADMINEXPENSE_Job').val() == -1) {
    var JobID = 0;
  } else {
    var JobID = BILLING_ADMIN_EXPENSES.Expenses.Jobs[$('#BILLING_ADMINEXPENSE_Job').val()].PrimaryKey;
  }
  var ClientID = $('#BILLING_ADMINEXPENSE_ClientID').val();
  var UserPK = $('#BILLING_ADMINEXPENSE_Incurredby').val();
  var ExpensePK = $('#BILLING_ADMINEXPENSEPK').val();
  var Category = encodeURIComponent($('#BILLING_ADMINEXPENSE_CATEGORIES').val());
  var Paid_By = encodeURIComponent($('input[name=BILLING_ADMINPAIDBY]:checked').val());
  var Currency = $('#BILLING_ADMINEXPENSE_CURRENCY').val();
  var Amount = encodeURIComponent($('#BILLING_ADMINAMOUNT').val());
  var Amount_R = encodeURIComponent($('#BILLING_ADMINAMOUNT_R').val());
  var Amount_B = encodeURIComponent($('#BILLING_ADMINAMOUNT_B').val());
  var Merchant = encodeURIComponent($('#BILLING_ADMINMERCHANT').val());
  var date = encodeURIComponent($('#BILLING_ADMINEXPENSEDATE').val());
  var Comment = encodeURIComponent($('#BILLING_ADMINCOMMENT').val());
  var Imbersable = 0;
  if ($('#BILLING_ADMINREIMBURSABLEYES').is(':checked')) Imbersable = 1;
  var Billable = 0;
  var curFilename = $('#BILLING_ADMINEXPENSEFILENAME').val();
  if ($('#BILLING_ADMINBILLABLEYES').is(':checked')) Billable = 1;

  //TODO Approve this expense
  //Billable is false if the expense is a company expense
  //Reimbursable expense automatically set to false if Paid_By = Employee

  $.ajax({
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      xhr.upload.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = Math.floor((evt.loaded / evt.total) * 100);
            if (percentComplete == 100) $('#SCHfileprogress').html('<h2>' + iTrans('Processing') + '..</h2>');
            else $('#SCHfileprogress').html('<h2>' + iTrans('Uploading') + ': ' + percentComplete + '%</h2>');
          }
        },
        false
      );
      xhr.addEventListener(
        'progress',
        function (evt) {
          if (evt.lengthComputable) {
            var percentComplete = evt.loaded / evt.total;
            //console.log("Received: " + percentComplete);
          }
        },
        false
      );
      return xhr;
    },
    type: 'POST',
    url:
      BASEURL +
      'menu-Billing-AJAX.php?action=markExpenseApproved&ExpensePK=' +
      ExpensePK +
      '&Category=' +
      Category +
      '&Paid_By=' +
      Paid_By +
      '&Currency=' +
      Currency +
      '&Amount=' +
      Amount +
      '&Merchant=' +
      Merchant +
      '&Date=' +
      date +
      '&Comment=' +
      Comment +
      '&Imbersable=' +
      Imbersable +
      '&Billable=' +
      Billable +
      '&Approved=' +
      Approved +
      '&ClientID=' +
      ClientID +
      '&JobID=' +
      JobID +
      '&UserPK=' +
      UserPK +
      '&Amount_R=' +
      Amount_R +
      '&Amount_B=' +
      Amount_B,
    data: {
      Filename: cert_Filename,
      curFilename: curFilename,
      Attachment: cert_base64File,
    },
    success: function (data) {
      var json_data = jQuery.parseJSON(data);
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Offline') {
        alert('<h1>' + iTrans('Offline Cannot Update Expense') + '</h1>'); //retrieve old data from database
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception')
        ServerResponse(json_data[0], 'BILLING_AdminApproveExpense');
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data[0].ServerResponse == 'Success') {
        BILLING_returnAdminExpenses(); //reload expense data
        cert_base64File = null;
        cert_Filename = 'N/A';
        $('#BILLING_ADMIN_ATTACHMENT').val('');
      }
    },
    fail: function (jqXHR, status, error) {
      $('#SCHfileprogress').html('<h2>' + iTrans('Upload Failed') + '!</h2>');

      AJAXFAIL(error);
    },
  });
}
