/**
 * Data class for RiskFactorValues
 */
class mSAMA_RiskFactorValue extends mSAMA_AbstractModel {
  /**
   * @param {mSAMA_RiskFactor}    _targetRiskFactor   The risk factor these values associate with
   * @param {mSAMA_Consequence}   _parentConsequence  The consequence this value is a part of
   * @param {object}              [_databaseData]     Values read from the Database
   */
  constructor(_targetRiskFactor, _parentConsequence, _databaseData) {
    super('mrfv_primaryKey');
    this.riskFactor = _targetRiskFactor;
    this.parentConsequence = _parentConsequence;
    this.customFieldValues = [];

    this.sync = {
      mrfv_primaryKey: null,
      mrfv_rfPk: this.riskFactor.GetPk(),
      mrfv_riskValue: 1,
      modified: false,
    };

    this.syncReset = {};

    $.extend(true, this.sync, _databaseData);

    if (this.IsNew()) {
      this.SetPk(cSAMA_Utils.GetUID());
      this.SetModified(true);

      let custFields = this.riskFactor.GetCustomFields();
      for (let cf = 0; cf < custFields.length; cf++) {
        this.AddCustomFieldValue(new mSAMA_CustomFieldValue(this, custFields[cf]));
      }
    }

    this.ConfirmValueChanges();
  }

  /**
   * Adds a custom field value to this risk factor value
   * @param {mSAMA_CustomFieldValue} _field
   */
  AddCustomFieldValue(_field) {
    this.customFieldValues.push(_field);
  }

  /**
   * Gets custom field values
   * @returns {mSAMA_CustomFieldValue[]}
   */
  GetCustomFieldValues() {
    return this.customFieldValues;
  }

  /**
   * Gets the weighted risk
   * @returns {int}
   */
  GetRisk() {
    return +this.sync.mrfv_riskValue;
  }

  /**
   * Resets the changes made to the value object
   * @override
   */
  DiscardValueChanges() {
    $.extend(true, this.sync, this.syncReset);

    for (let cf = 0; cf < this.customFieldValues.length; cf++) {
      this.customFieldValues[cf].DiscardValueChanges();
    }
  }

  /**
   * Confirms the changes made to the value object
   * @override
   */
  ConfirmValueChanges() {
    $.extend(true, this.syncReset, this.sync);

    for (let cf = 0; cf < this.customFieldValues.length; cf++) {
      this.customFieldValues[cf].ConfirmValueChanges();
    }
  }

  /**
   * Gets the risk's weight
   * @returns {number}
   */
  GetWeight() {
    return +this.riskFactor.sync.mrf_weight;
  }

  /**
   * Gets the risk threshold
   * @returns {number} 1 (disabled) - 5 (highest)
   */
  GetThreshold() {
    return +this.riskFactor.sync.mrf_riskThreshold;
  }

  /**
   * Gets the RF that this RFV uses.
   * @return {mSAMA_RiskFactor}
   */
  GetRiskFactor() {
    return this.riskFactor;
  }

  /**
   * Returns a submittable object containing the risk factor values as well as the custom field values
   * @returns {{riskFactor: ({mrfv_primaryKey: null, mrfv_conPk: null, mrfv_riskValue: number, modified: boolean}|*),
   *   customFieldValues: Array}}
   * @override
   */
  GetTransmissible() {
    let submission = {
      riskFactorValues: this.sync,
      customFieldValues: [],
    };

    for (let cf = 0; cf < this.customFieldValues.length; cf++) {
      let field = this.customFieldValues[cf];
      if (field.IsModified()) {
        submission.customFieldValues.push(field.sync);
      }
    }

    return submission;
  }

  /**
   * Changes the modified state
   * @param {boolean} _state
   * @override
   */
  SetModified(_state) {
    this.sync.modified = _state;
    this.parentConsequence.SetModified(_state);
  }
}
