/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
function DownloadOfflineGroupTDG(refresh) {
  if (ONLINE) {
    $.getJSON(BASEURL + 'menu-TDG-AJAX.php?action=getGroup', function (json_data) {
      cirroDB.insert('TDG_Group_Data', json_data, null, function (e) {
        //console.log("Stored Offline TDG Group Settings");
        if (refresh === true) init_TDG(TDG_OP);
        if (refresh == 'Reload') getTDGgroupData();
      });
    }).fail(function (jqXHR, status, error) {
      //console.log("Failed to getAll");
      //alert("Connection Error: Failed to refresh TDG data");
    });
  } else {
    //alert("Cannot Refresh TDG data when offline!");
  }
}
function exitTDG() {
  ////console.log("ExitTDG: "+TDG_OP);
  if (TDG_OP == 'Planning') {
    $.mobile.changePage($('#MainMenu'), {
      reverse: true,
      changeHash: false,
    });
  }
  if (TDG_OP == 'Itinerary') {
    $.mobile.changePage($('#ITIN-TDG'), {
      reverse: true,
      changeHash: false,
    });
    if (TDG_Manifest.length > 0) {
      TDG_Itin_Manifest();
    } else {
      $('#TDG_Itin_Manifest').html('<p>No TDG Manifest Added</p>');
      $('#TDG').prop('checked', false).checkboxradio('refresh');
      $('#TDG_Itin_Manifest').enhanceWithin();
    }
  }
}
function ERGAutoComplete(term, callback) {
  //console.log(text);

  var text = term.term;
  if (isNaN(text)) {
    searchERGListingKeyword(text, callback);
  } else {
    searchERGListing(text, callback);
  }
}

function searchERGListing(UNnum, callback) {
  var UNtxt = '';
  //console.log(UNnum.length);
  if (UNnum.length == 4) {
    if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 == 'Available') {
      TDG_DATABASE.query('TDG_Identification', UNnum, 'UN', function (e) {
        SEARCH_data = e;
        if (e === false) SEARCH_data = [];
        SearchERGParseResults(callback);
      });
    } else {
      $.getJSON(
        BASEURL + 'menu-TDG-AJAX.php?action=Search&UN=' + UNnum + '&Text=' + encodeURIComponent(UNtxt),
        function (json_data) {
          //console.log("Data Received");
          SEARCH_data = json_data;
          SearchERGParseResults(callback);
        }
      ).fail(function (jqXHR, status, error) {
        //console.log("Failed to getAll");
      });
    }
  }
}

function searchERGListingKeyword(UNtxt, callback) {
  var UNnum = '';
  if (UNtxt.length > 0) {
    if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 == 'Available') {
      TDG_DATABASE.query('TDG_Identification', UNtxt, 'Text', function (e) {
        SEARCH_data = e;
        if (e === false) SEARCH_data = [];
        SearchERGParseResults(callback);
      });
    } else {
      $.getJSON(
        BASEURL + 'menu-TDG-AJAX.php?action=Search&UN=' + UNnum + '&Text=' + encodeURIComponent(UNtxt),
        function (json_data) {
          //console.log("Data Received");
          SEARCH_data = json_data;
          SearchERGParseResults(callback);
        }
      ).fail(function (jqXHR, status, error) {
        //console.log("Failed to getAll");
      });
    }
  }
}

function SearchERGParseResults(callback) {
  var options = [];
  for (var i in SEARCH_data) {
    var I = SEARCH_data[i];
    var label =
      'UN: ' +
      I.UN +
      ': ' +
      I.Description +
      ' | Class: ' +
      I.Class_Div +
      ' | PG: ' +
      I.P_Group +
      ' | Labels: ' +
      I.Labels;
    options.push({ label: label, value: i });
  }
  callback(options);
}

function ERGAutoCompleteSelect(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  var ERG = SEARCH_data[value].ERG_en;
  if (i18n.locale == 'fr') ERG = SEARCH_data[value].ERG_fr;

  if (ERG == '') {
    alert('No ERG guide for this Item');
    return;
  }
  ////console.log(label);
  $.mobile.changePage($('#ERGDisplay2'), {
    reverse: false,
    changeHash: false,
  });
  $('#ERG-Info2').html(ERG);
  ////console.log(Ref[index]);
}

function exitERGDisplay2() {
  $.mobile.changePage($('#TDG_Home'), {
    reverse: true,
    changeHash: false,
  });
}

let TDG_UserList = [];
async function TDG_Itin_Manifest() {
  const capUtils = VueBridge.capabilityUtils;
  TDG_UserList = await capUtils.usersWithCapabilities(
    'CreateFlightWithTdg',
    getLoadingSupervisorRequiredDGCapabilities()
  );

  //console.log("Populating Itinerary Manifest");
  $('#TDG_Itin_Manifest').html('');
  var ManifestHTML = '<div style="width: 100%; overflow: auto"><h3>' + iTrans('Dangerous Goods Transported') + '</h3>';
  ManifestHTML +=
    '<label for="ITIN_TDG_Origin">' +
    iTrans('Origin') +
    '</label><input type="text" data-mini="true" id="ITIN_TDG_Origin" onChange="calculateWB()" />';
  ManifestHTML +=
    '<label for="ITIN_TDG_Dest">' +
    iTrans('Destination') +
    '</label><input type="text" data-mini="true" id="ITIN_TDG_Dest"  onChange="calculateWB()" />';

  ManifestHTML +=
    '<table data-filter="true" class="limit-table">' +
    '<thead>' +
    '<tr>' +
    '<th data-priority="1">Consignor</th>' +
    '<th data-priority="2">Shipping Name</th>' +
    '<th data-priority="3">UN</th>' +
    '<th data-priority="2">Class</th>' +
    '<th data-priority="2">Quantity</th>' +
    '<th data-priority="2">Location</th>' +
    '<th data-priority="2">CAO</th>' +
    '<th data-priority="2">Exemption</th>' +
    '<th data-priority="2">ERG Code</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';
  var hasLA = false;
  for (var i in TDG_Manifest) {
    var lineClass = '';
    //check for segregation between packages
    for (var s in TDG_Manifest) {
      if (s !== i) {
        //don't check against self
        var mClass = TDG_Manifest[i].Class_Div;
        mClass = mClass.split(' ');
        mClass = mClass[0];
        var cClass = TDG_Manifest[s].Class_Div;
        cClass = cClass.split(' ');
        cClass = cClass[0];
        if ((mClass == '3' && cClass == '5.1') || (mClass == '5.1' && cClass == '3')) lineClass = 'yellowRecord';
        if ((mClass == '4.2' && cClass == '5.1') || (mClass == '5.1' && cClass == '4.2')) lineClass = 'yellowRecord';
        if ((mClass == '4.3' && cClass == '8') || (mClass == '8' && cClass == '4.3')) lineClass = 'yellowRecord';
        var emClass = mClass.split('.');
        var ecClass = cClass.split('.');

        if (emClass[0] == '1' && ecClass[0] == '1') {
          //comparing two explosive class substances
          if (
            (emClass[1] == '4B' && ecClass[1] !== '4B' && ecClass[1] !== '4S') ||
            (emClass[1] !== '4S' && emClass[1] !== '4B' && ecClass[1] == '4B')
          )
            lineClass = 'yellowRecord';
        }
        //All explosives not 1.4S are not compatable with any other class
        if (emClass[0] == '1' && ecClass[0] !== '1') {
          if (emClass[1] !== '4S') lineClass = 'yellowRecord';
        }
        if (ecClass[0] == '1' && emClass[0] !== '1') {
          if (ecClass[1] !== '4S') lineClass = 'yellowRecord';
        }
      }
    }

    var Item = TDG_Manifest[i];
    if (Item.UN.toString().length == 2) Item.UN = '00' + Item.UN;
    if (Item.UN.toString().length == 3) Item.UN = '0' + Item.UN;
    ManifestHTML +=
      "<tr class='" +
      lineClass +
      "'><td>" +
      Item.Consignor +
      '</td><td>' +
      Item.Description +
      '</td><td>' +
      Item.UN +
      '</td><td>' +
      Item.Class_Div +
      '</td><td>' +
      Item.pkgQTY +
      'x' +
      Item.QTY +
      '</td><td>' +
      Item.Location +
      '</td><td>' +
      Item.CAO +
      '</td><td>' +
      Item.Exemption +
      '</td><td>' +
      Item.ERG_Num +
      '</td></tr>';
    if (Item.Exemption == '(12.9)') hasLA = true;
  }
  ManifestHTML += '</tbody></table></div>';

  let UserOptions = '';
  for (let user of TDG_UserList) {
    UserOptions += "<option value='" + user.fullName + "'>" + user.fullName + '</option>';
  }
  ManifestHTML +=
    '<br /><label for="TDG_Loader_Search">Search Loader Name</label><input type="text" data-mini="true" id="TDG_Loader_Search" />';

  ManifestHTML +=
    "<label for='TDG_Loader'>Loading Supervisor</label><select id='TDG_Loader'><option value='null' selected data-mini='true'>Select Loader</option>" +
    UserOptions +
    '</select>';
  ManifestHTML +=
    "<label for='CHK_NOTOC'>NOTOC: I am aware of the dangerous goods loaded on this aircraft and their location.</label><input type='checkbox' id='CHK_NOTOC' data-icon='check' data-mini='true' />";

  ManifestHTML += '<div id="TDG_PilotSignature"></div>';
  ManifestHTML +=
    '<button onClick="ITIN_TDG_Pilot_Signature();" data-mini="true" id="TDG_PilotSignatureBTN">Add Pilot Signature</button>';

  $('#TDG_Itin_Manifest').html(ManifestHTML);
  $('#TDG_Itin_Manifest').enhanceWithin();
  $('#CHK_NOTOC').click(calculateWB);
  $('#TDG_Loader').change(calculateWB);
  $('#TDG_Loader_Search').autocomplete({
    select: TDGNameSelect,
    source: searchTDGName,
  });

  if ($('#CHK_PL').is(':checked')) {
    $('#CHK_NOTOC').prop('checked', true).checkboxradio('refresh');
  }

  const pilotCanSupervise = TDG_UserList.some((user) => user.fullName === chatname);
  if ($('#CHK_PL').is(':checked') && pilotCanSupervise) {
    $('#TDG_Loader').val(chatname);
  }

  $('#TDG_Loader').selectmenu('refresh');
  $('#TDG_Itin_Manifest').enhanceWithin();
  calculateWB();
  ////console.log(ManifestHTML);
}

var Itin_TDG_Pilot_Signature;
var TDGsignatureDATA = { data: '', text: '' };
function ITIN_TDG_Pilot_Signature() {
  //goto signature page
  $.mobile.changePage($('#ITIN-TDG-SignaturePage'), {
    transition: 'slide',
    changeHash: false,
    reverse: false,
  });
  $('#ITIN-TDG-signature-pad-wrapper').html('');
  $('#ITIN-TDG-signature-pad-wrapper').html('<canvas id="ITIN-TDG-signature-pad" class="signature-pad"></canvas>');
  var canvas = document.getElementById('ITIN-TDG-signature-pad');
  Itin_TDG_Pilot_Signature = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    Itin_TDG_Pilot_Signature.clear(); // otherwise isEmpty() might return incorrect value
  }
  window.addEventListener('resize', resizeCanvas);
  resizeCanvas();
}

function ITIN_TDG_ClearSignature() {
  Itin_TDG_Pilot_Signature.clear();
}

function ITIN_ReturnTDG() {
  $.mobile.changePage($('#ITIN-TDG'), {
    transition: 'slide',
    changeHash: false,
    reverse: true,
  });
  if (!Itin_TDG_Pilot_Signature.isEmpty()) {
    var data = Itin_TDG_Pilot_Signature.toDataURL('image/png');
    TDGsignatureDATA.data = data;
    TDGsignatureDATA.text = chatname;
    var html = '<center><img src="' + data + '" width="321px" height="150px"/><br />';
    html += '<b>' + iTrans('Pilot') + ': ' + chatname + '</b></center>';
    $('#TDG_PilotSignature').html(html);
    $('#TDG_PilotSignatureBTN').hide();
    if ($('#TDG_Loader').val() == 'null') {
      document.getElementById('TDGNEXT').disabled = true;
      return;
    }
    if ($('#CHK_NOTOC').length) {
      if (!$('#CHK_NOTOC').is(':checked')) {
        document.getElementById('TDGNEXT').disabled = true;
        return;
      }
    }
    for (var t in TDG_Manifest) {
      if (TDG_Manifest[t].Exemption == '(12.9)') {
        if ($('ITIN_TDG_Origin').val() == '' || $('#ITIN_TDG_Dest').val() == '') {
          document.getElementById('TDGNEXT').disabled = true;
          return;
        }
        break;
      }
    }
    $('#TDGNEXT').prop('disabled', false);
  } else {
    TDGsignatureDATA.data = '';
    TDGsignatureDATA.text = '';
    $('#PilotTDGSignatureHLDR').html('');
    $('#TDG_PilotSignatureBTN').show();
    $('#TDGNEXT').prop('disabled', true);
  }
}

var TDG_OP = '';

function searchTDGName(term, callback) {
  var text = term.term;
  var Users = FilterTDG_Users(TDG_UserList, text, callback);
}
function FilterTDG_Users(OrigArray, keywords, callback) {
  var userFiltered = OrigArray;
  userFiltered = OrigArray.filter(function (item) {
    if (item.chatname.toLowerCase().indexOf(keywords.toLowerCase()) > -1) return true;
    else return false;
  });
  var options = [];
  for (var i in userFiltered) {
    var I = userFiltered[i];
    if (I.isValid) options.push({ label: I.chatname, value: I.chatname });
  }
  callback(options);
}
function TDGNameSelect(event, ui) {
  $('#TDG_Loader').val(ui.item.value);
  $('#TDG_Loader').selectmenu('refresh');
  setTimeout(function () {
    $('#TDG_Loader_Search').val('');
  }, 500);
}

function init_TDG(e) {
  TDG_OP = e;

  const xit = $('#exitTDG_Home');
  toggleBackButton(xit, TDG_OP != 'Itinerary');

  xit.unbind('click', exitTDG).click(exitTDG);

  $('#exitTDG_AT').unbind('click', hrefHandler);
  $('#exitTDG_AT').click(hrefHandler);
  $('#exitTDG_ADD').unbind('click', hrefHandler);
  $('#exitTDG_ADD').click(hrefHandler);
  $('#exitUNDisplay').unbind('click', hrefHandler);
  $('#exitUNDisplay').click(hrefHandler);
  $('#exitERGDisplay').unbind('click', hrefHandler);
  $('#exitERGDisplay').click(hrefHandler);
  $('#AT_Template').unbind('change', TemplateChange);
  $('#AT_Template').change(TemplateChange);

  $('#CHK_ECS').unbind('click', opChange);
  $('#CHK_ECS').click(opChange);
  $('#CHK_FE').unbind('click', opChange);
  $('#CHK_FE').click(opChange);
  $('#CHK_TIS').unbind('click', opChange);
  $('#CHK_TIS').click(opChange);
  $('#CHK_CC').unbind('click', opChange);
  $('#CHK_CC').click(opChange);
  $('#CHK_LA').unbind('click', opChange);
  $('#CHK_LA').click(opChange);
  $('#CHK_CS').unbind('click', opChange);
  $('#CHK_CS').click(opChange);
  $('#CHK_MI').unbind('click', opChange);
  $('#CHK_MI').click(opChange);
  $('#CHK_CO').unbind('click', opChange);
  $('#CHK_CO').click(opChange);
  $('#CHK_PL').unbind('click', opChange);
  $('#CHK_PL').click(opChange);
  $('#CHK_Medical').unbind('click', opChange);
  $('#CHK_Medical').click(opChange);
  $('#UNSearch-Result').html('');
  $('#search-UN').val('');
  $('#ATManifest').html('');
  templateVal = -1;
  TDG_Manifest = [];
  if ($('#CHK_CC').is(':checked')) {
    $('#CCcheck2BTN').show();
    $('#CCcheckBTN').show();
  } else {
    $('#CCcheck2BTN').hide();
    $('#CCcheckBTN').hide();
  }
  if (TDGACCESS == 0) {
    $('#HideAllTDGItems').hide();
  } else {
    getTDGgroupData();
  }
  if (LOCALSTORAGE.OFFLINE_DB_TDG_2021 !== 'Available' && CORDOVA) {
    //display download TDG database Button
    $('#PromptOfflineTDG').show();
  } else {
    $('#PromptOfflineTDG').hide();
  }
  setChkCapabilityMap();
}
function DownloadOfflineTDGDatabase() {
  var Item = OFFLINEDBLIST[0];
  download_Database(Item, OfflineTDGDownloaded);
}
function OfflineTDGDownloaded(result, Item) {
  if (!result) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Download Error',
      headerClose: false,
      buttonPrompt: '<p>"' + Item.Label + '" Failed to download would you like to try again?</p>',
      buttons: {
        Retry: {
          click: function () {
            download_Database(Item, OfflineTDGDownloaded);
          },
        },
        'Try Later': {
          click: function () {
            return;
          },
        },
      },
    });
  } else {
    $('#PromptOfflineTDG').hide();
  }
}

function getTDGgroupData() {
  cirroDB.query('TDG_Group_Data', null, null, function (e) {
    if (e === false || e == null || e == '') {
      //pop warning no offline tdg group data
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Error',
        headerClose: false,
        buttonPrompt: "Couldn't retrieve offline dangerous goods company settings.  Please try again later.",
        buttons: { OK: { click: function () {} } },
      });
    } else {
      loadTDG_groupData(e);
    }
  });
}
function loadTDG_groupData(json_data) {
  ConsignorList = json_data[0];
  LocationList = json_data[1];
  TemplateList = json_data[2];
  console.log(TemplateList);
  EqCert = json_data[3];
  restrictTDG();
  templatePrompt();
}

let chkDGCapabilityPilotMap = {};
let chkDGCapabilitySupervisorMap = {};
function setChkCapabilityMap() {
  const tdgCapabilities = VueBridge.dangerousGoodsConstants;
  chkDGCapabilityPilotMap = {
    CHK_ECS: [tdgCapabilities.explosivesCanAccept, tdgCapabilities.explosivesCanShip],
    CHK_FE: [tdgCapabilities.forbiddenExplosivesCanAccept, tdgCapabilities.forbiddenExplosivesCanShip],
    CHK_TIS: [tdgCapabilities.toxicSubstanceCanAccept, tdgCapabilities.toxicSubstanceCanShip],
    CHK_CC: [tdgCapabilities.consumerCommoditiesCanAccept, tdgCapabilities.consumerCommoditiesCanShip],
    CHK_LA: [tdgCapabilities.limitedAccessCanAccept, tdgCapabilities.limitedAccessCanShip],
    CHK_CS: [tdgCapabilities.coreSampleCanAccept, tdgCapabilities.coreSampleCanShip],
    CHK_AW: [tdgCapabilities.aerialWorkCanAccept, tdgCapabilities.aerialWorkCanShip],
    CHK_MI: [tdgCapabilities.measuringInstrumentsCanAccept, tdgCapabilities.measuringInstrumentsCanShip],
    CHK_Medical: [tdgCapabilities.medicalAidCanAccept, tdgCapabilities.medicalAidCanShip],
    CHK_Private: [tdgCapabilities.privateAircraftCanAccept, tdgCapabilities.privateAircraftCanShip],
  };

  chkDGCapabilitySupervisorMap = {
    CHK_ECS: tdgCapabilities.explosivesCanAccept,
    CHK_FE: tdgCapabilities.forbiddenExplosivesCanAccept,
    CHK_TIS: tdgCapabilities.toxicSubstanceCanAccept,
    CHK_CC: tdgCapabilities.consumerCommoditiesCanAccept,
    CHK_LA: tdgCapabilities.limitedAccessCanAccept,
    CHK_CS: tdgCapabilities.coreSampleCanAccept,
    CHK_AW: tdgCapabilities.aerialWorkCanAccept,
    CHK_MI: tdgCapabilities.measuringInstrumentsCanAccept,
    CHK_Medical: tdgCapabilities.medicalAidCanAccept,
    CHK_Private: tdgCapabilities.privateAircraftCanAccept,
  };
}

async function restrictTDG() {
  const capUtils = VueBridge.capabilityUtils;
  const action = 'CreateFlightWithTdg';

  let disabledCount = 0;

  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_ECS))) {
    $('#CHK_ECS').checkboxradio('disable');
    disabledCount++;
  }
  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_FE))) {
    $('#CHK_FE').checkboxradio('disable');
    disabledCount++;
  }
  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_TIS))) {
    $('#CHK_TIS').checkboxradio('disable');
    disabledCount++;
  }
  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_CC))) {
    $('#CHK_CC').checkboxradio('disable');
    $('#CCcheckBTN').hide();
    $('#CCcheck2BTN').hide();
    disabledCount++;
  }
  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_LA))) {
    $('#CHK_LA').checkboxradio('disable');
    disabledCount++;
  }
  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_CS))) {
    $('#CHK_CS').checkboxradio('disable');
    disabledCount++;
  }

  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_AW))) {
    $('#CHK_AW').checkboxradio('disable');
    disabledCount++;
  }

  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_MI))) {
    $('#CHK_MI').checkboxradio('disable');
    disabledCount++;
  }

  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_Medical))) {
    $('#CHK_Medical').checkboxradio('disable');
    disabledCount++;
  }

  if (!(await capUtils.areCapabilitiesActionable(action, chkDGCapabilityPilotMap.CHK_Private))) {
    $('#CHK_Private').checkboxradio('disable');
    disabledCount++;
  }

  const allTDGItemsDisabled = disabledCount === Object.keys(chkDGCapabilityPilotMap).length;
  if (allTDGItemsDisabled) {
    $('#AT_BTN').prop('disabled', true);
  }
}

function opChange() {
  //console.log(this.id);
  var id = this.id;
  var checked = this.checked;
  if (checked) {
    switch (id) {
      case 'CHK_FE':
        $('#CHK_ECS').checkboxradio('disable');
        $('#CHK_ECS').prop('checked', false).checkboxradio('refresh');

        $('#CHK_TIS').checkboxradio('disable');
        $('#CHK_TIS').prop('checked', false).checkboxradio('refresh');

        $('#CHK_CC').checkboxradio('disable');
        $('#CHK_CC').prop('checked', false).checkboxradio('refresh');

        $('#CHK_LA').checkboxradio('disable');
        $('#CHK_LA').prop('checked', false).checkboxradio('refresh');

        $('#CHK_CS').checkboxradio('disable');
        $('#CHK_CS').prop('checked', false).checkboxradio('refresh');

        $('#CHK_MI').checkboxradio('disable');
        $('#CHK_MI').prop('checked', false).checkboxradio('refresh');
        break;
      case 'CHK_ECS':
      case 'CHK_TIS':
      case 'CHK_CC':
      case 'CHK_LA':
      case 'CHK_MI':
        $('#CHK_FE').checkboxradio('disable');
        $('#CHK_FE').prop('checked', false).checkboxradio('refresh');
        break;
      case 'CHK_CC':
        $('#CCcheck2BTN').show();
        $('#CCcheckBTN').show();
        break;
    }
  } else {
    switch (id) {
      case 'CHK_FE':
        $('#CHK_ECS').checkboxradio('enable');
        $('#CHK_TIS').checkboxradio('enable');
        $('#CHK_CC').checkboxradio('enable');
        $('#CHK_LA').checkboxradio('enable');
        $('#CHK_CS').checkboxradio('enable');
        $('#CHK_MI').checkboxradio('enable');
        //$("#CHK_Medical").prop("disabled", false);
        break;
      case 'CHK_ECS':
      case 'CHK_TIS':
      case 'CHK_CC':
      case 'CHK_LA':
      case 'CHK_MI':
        $('#CHK_FE').checkboxradio('enable');
        break;
      case 'CHK_CC':
        $('#CCcheck2BTN').hide();
        $('#CCcheckBTN').hide();
        break;
    }
  }
  restrictTDG();
}

var ConsignorList;
var LocationList;
var TemplateList;
var TemplateID;
var tdgType;
var EqCert;
var templateVal = -1;

const tdgCheckBoxIds = [
  'CHK_ECS',
  'CHK_FE',
  'CHK_TIS',
  'CHK_CC',
  'CHK_LA',
  'CHK_CS',
  'CHK_MI',
  'CHK_CO',
  'CHK_PL',
  'CHK_Medical',
  'CHK_AW',
  'CHK_Private',
];

function TemplateChange() {
  //console.log("change template");
  //console.log("select val: " + this.value);
  //console.log(tdgType);
  templateVal = this.value;
  if (templateVal != '-1') {
    TDG_Manifest = TemplateList[this.value].Manifest;
    TemplateID = TemplateList[this.value];
    tdgType = TemplateList[this.value].Type;
    var checkState = TemplateList[this.value].checkState;
    checkState = checkState.split(',');

    for (const [i, id] of tdgCheckBoxIds.entries()) {
      if (checkState[i] == '1')
        $('#' + id)
          .prop('checked', true)
          .checkboxradio('refresh');
      else
        $('#' + id)
          .prop('checked', false)
          .checkboxradio('refresh');

      if (checkState.length === i + 1) {
        break; // we've reached the end of the checkState array there are some differences in the items included
      }
    }

    populateManifest();
  } else {
    TDG_Manifest = [];

    $('#ATManifest').html('<p>No Items</p>');
  }
}

function searchListing() {
  var UNnum = $('#search-UN').val();
  var UNtxt = $('#search-Description').val();
  //console.log(UNnum.length);
  if (UNnum.length == 4) {
    if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 == 'Available') {
      TDG_DATABASE.query('TDG_Identification', UNnum, 'UN', function (e) {
        SEARCH_data = e;
        if (e === false) SEARCH_data = [];
        ListTDGResults(SEARCH_data);
      });
    } else {
      $.getJSON(
        BASEURL + 'menu-TDG-AJAX.php?action=Search&UN=' + UNnum + '&Text=' + encodeURIComponent(UNtxt),
        function (json_data) {
          //console.log("Data Received");
          SEARCH_data = json_data;
          ListTDGResults(json_data);
        }
      ).fail(function (jqXHR, status, error) {
        //console.log("Failed to getAll");
      });
    }
  }
}

function searchListingKeyword() {
  var UNnum = '';
  var UNtxt = $('#search-Description').val();
  //console.log(UNnum.length);
  if (UNtxt.length > 0) {
    $('#search-UN').val('');
    if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 == 'Available') {
      TDG_DATABASE.query('TDG_Identification', UNtxt, 'Text', function (e) {
        SEARCH_data = e;
        if (e === false) SEARCH_data = [];
        ListTDGResults(SEARCH_data);
      });
    } else {
      $.getJSON(
        BASEURL + 'menu-TDG-AJAX.php?action=Search&UN=' + UNnum + '&Text=' + encodeURIComponent(UNtxt),
        function (json_data) {
          //console.log("Data Received");
          SEARCH_data = json_data;
          ListTDGResults(json_data);
        }
      ).fail(function (jqXHR, status, error) {
        //console.log("Failed to getAll");
      });
    }
  }
}
var SEARCH_data;

async function ListTDGResults(data) {
  $('#UNSearch-Result').html('');
  $('#UNSearch-Result').append(
    '<div style="width: 100%; overflow: auto"><table id="UN_Table" data-filter="true" class="limit-table wrap">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">Select</th>' +
      '<th data-priority="1">UN</th>' +
      '<th data-priority="2">Description</th>' +
      '<th data-priority="3">PG</th>' +
      '<th data-priority="2">Class</th>' +
      '<th data-priority="2">Labels</th>' +
      '<th data-priority="2">EQ</th>' +
      '<th data-priority="2">LQ</th>' +
      '<th data-priority="2">PA</th>' +
      '<th data-priority="2">CA</th>' +
      '<th data-priority="2">Details</th>' +
      '<th data-priority="2">ERG</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody><tr>'
  );

  var LA = $('#CHK_LA').is(':checked');
  if (LA) LA = 1;
  else LA = 0;

  var AW = $('#CHK_AW').is(':checked');
  if (AW) AW = 1;
  else AW = 0;

  var CO = $('#CHK_CO').is(':checked');
  if (CO) CO = 1;
  else CO = 0;
  for (i in data) {
    var AW = $('#CHK_AW').is(':checked');
    data[i].UN = data[i].UN.toString();
    if (data[i].UN.length == 1) data[i].UN = '000' + data[i].UN;
    if (data[i].UN.length == 2) data[i].UN = '00' + data[i].UN;
    if (data[i].UN.length == 3) data[i].UN = '0' + data[i].UN;

    if (data[i].EQ_IP !== 'Forbidden' && data[i].EQ_IP !== '')
      EQ_item = data[i].EQ_IP + '/IP<br />' + data[i].EQ_Max + '/pkg';
    var LQ_item = 'F';
    if (data[i].LQ_Max !== 'Forbidden' && data[i].LQ_Max !== '') LQ_item = data[i].LQ_Max;
    var PA_item = 'F';
    if (data[i].PA_Max !== 'Forbidden' && data[i].PA_Max !== '') PA_item = data[i].PA_Max;

    const tdgCapabilities = VueBridge.dangerousGoodsConstants;
    const hasEquivelancyCerificateTraining = await VueBridge.capabilityUtils.areCapabilitiesActionable(
      'CreateFlightWithTdg',
      [tdgCapabilities.equivalencyCertificateTraining]
    );

    if (AW) {
      data[i].Exemption = '12.12 ';

      data[i].CAO = null;
      var searchCert = false;

      if (hasEquivelancyCerificateTraining) {
        //user has Equivelance Certificate training.  search for EC's
        for (c in EqCert) {
          if (
            parseInt(data[i].UN) == parseInt(EqCert[c].UN) &&
            data[i].Class_Div == EqCert[c].Class_Div &&
            data[i].P_Group == EqCert[c].P_Group
          ) {
            if (
              (EqCert[c].LA == 1 && LA == 1) ||
              (EqCert[c].CAO == 1 && CO == 1) ||
              (EqCert[c].AW == 1 && tdgType == 'Aerial Work')
            ) {
              data[i].Exemption += 'SA ' + EqCert[c].EC;
              data[i].EC = EqCert[c];
              if (EqCert[c].CAO == 1) data[i].CAO = 'X';
              else data[i].CAO = '';
              searchCert = true;
            }
          }
        }
      }
      var EQ_item = 'F';

      if (searchCert) {
        $('#UNSearch-Result tr:last').after(
          "<tr><td><div class='vertical-center-container'><button id='TDG_Item" +
            i +
            "' value='" +
            i +
            "' data-mini='true' class='cirro-btn'><img src='./images/Check_mark_green.svg' /></button></div></td><td>" +
            data[i].UN +
            '</td><td>' +
            data[i].Description +
            '<br /><b>' +
            data[i].Exemption +
            ' Note: </b>' +
            data[i].EC.Note +
            '</td><td>' +
            data[i].P_Group +
            '</td><td>' +
            data[i].Class_Div +
            '</td><td>' +
            data[i].Labels +
            '</td><td>' +
            EQ_item +
            '</td><td>' +
            LQ_item +
            '</td><td>' +
            PA_item +
            '</td><td>' +
            data[i].CA_Max +
            '</td><td><button id="Search-UN-View-' +
            i +
            '" class="action-btn" name="' +
            data[i].PrimaryKey +
            '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
            i +
            '"  name="' +
            data[i].PrimaryKey +
            '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
        );
        $('#TDG_Item' + i).click(AddTDGItem);
      } else {
        $('#UNSearch-Result tr:last').after(
          "<tr><td><div class='vertical-center-container'><button id='TDG_Item" +
            i +
            "' value='" +
            i +
            "' class='cirro-btn' data-mini='true'><img src='./images/Check_mark_green.svg' /></button></td><td>" +
            data[i].UN +
            '</td><td>' +
            data[i].Description +
            '</td><td>' +
            data[i].P_Group +
            '</td><td>' +
            data[i].Class_Div +
            '</td><td>' +
            data[i].Labels +
            '</td><td>' +
            EQ_item +
            '</td><td>' +
            LQ_item +
            '</td><td>' +
            PA_item +
            '</td><td>' +
            data[i].CA_Max +
            '</td><td><button id="Search-UN-View-' +
            i +
            '" class="action-btn" name="' +
            data[i].PrimaryKey +
            '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
            i +
            '"  name="' +
            data[i].PrimaryKey +
            '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
        );
        $('#TDG_Item' + i).click(AddTDGItem);
        $('#Search-UN-View-' + i).click(viewUN);
        $('#Search-ERG-View-' + i).click(viewERG);
      }
    } else {
      data[i].Exemption = '';
      if ($('#CHK_FE').is(':checked') && checkForbiddenExplosive(data[i])) {
        $('#UNSearch-Result tr:last').after(
          "<tr><td><div class='vertical-center-container'><button id='TDG_Item" +
            i +
            "' value='" +
            i +
            "' class='cirro-btn' data-mini='true'><img src='./images/Check_mark_green.svg' /></button></td><td>" +
            data[i].UN +
            '</td><td>' +
            data[i].Description +
            '</td><td>' +
            data[i].P_Group +
            '</td><td>' +
            data[i].Class_Div +
            '</td><td>' +
            data[i].Labels +
            '</td><td>' +
            EQ_item +
            '</td><td>' +
            LQ_item +
            '</td><td>' +
            PA_item +
            '</td><td>' +
            data[i].CA_Max +
            '</td><td><button id="Search-UN-View-' +
            i +
            '" class="action-btn" name="' +
            data[i].PrimaryKey +
            '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
            i +
            '"  name="' +
            data[i].PrimaryKey +
            '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
        );
        $('#TDG_Item' + i).click(AddTDGItem);
        $('#Search-UN-View-' + i).click(viewUN);
        $('#Search-ERG-View-' + i).click(viewERG);
      } else if (
        data[i].Forbidden == 1 ||
        (!$('#CHK_CO').is(':checked') && data[i].PA_Max == 'Forbidden') ||
        (!$('#CHK_CO').is(':checked') && data[i].PA_Max == '')
      ) {
        //check for EC for item
        data[i].CAO = null;
        var searchCert = false;
        if (hasEquivelancyCerificateTraining) {
          //user has Equivelance Certificate training.  search for EC's
          console.log(data[i]);
          for (c in EqCert) {
            console.log(EqCert[c]);
            if (
              data[i].UN == EqCert[c].UN &&
              data[i].Class_Div == EqCert[c].Class_Div &&
              data[i].P_Group == EqCert[c].P_Group
            ) {
              console.log('Match');
              if (
                (EqCert[c].LA == 1 && LA == 1) ||
                (EqCert[c].CAO == 1 && CO == 1) ||
                (EqCert[c].AW == 1 && tdgType == 'Aerial Work')
              ) {
                data[i].Exemption += 'SA ' + EqCert[c].EC;
                data[i].EC = EqCert[c];
                if (EqCert[c].CAO == 1) data[i].CAO = 'X';
                else data[i].CAO = '';
                searchCert = true;
              }
            }
          }
        }
        if (searchCert) {
          //$("#UNSearch-Result tr:last").after("<tr><td><div class='vertical-center-container'><button id='TDG_Item" + i + "' value='" + i + "' data-mini='true' class='cirro-btn'><img src='./images/Check_mark_green.svg' /></button></div></td><td>" + data[i].UN + "</td><td>" + data[i].Description + "<br /><b>" + data[i].Exemption + " Note: </b>" + data[i].EC.Note + "</td><td>" + data[i].P_Group + "</td><td>" + data[i].Class_Div + "</td><td>" + data[i].Labels + "</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>");
          //$("#TDG_Item" + i).click(AddTDGItem);
          $('#UNSearch-Result tr:last').after(
            "<tr><td><div class='vertical-center-container'><button id='TDG_Item" +
              i +
              "' value='" +
              i +
              "' data-mini='true' class='cirro-btn'><img src='./images/Check_mark_green.svg' /></button></div></td><td>" +
              data[i].UN +
              '</td><td>' +
              data[i].Description +
              '<br /><b>' +
              data[i].Exemption +
              ' Note: </b>' +
              data[i].EC.Note +
              '</td><td>' +
              data[i].P_Group +
              '</td><td>' +
              data[i].Class_Div +
              '</td><td>' +
              data[i].Labels +
              '</td><td>' +
              EQ_item +
              '</td><td>' +
              LQ_item +
              '</td><td>' +
              PA_item +
              '</td><td>' +
              data[i].CA_Max +
              '</td><td><button id="Search-UN-View-' +
              i +
              '" class="action-btn" name="' +
              data[i].PrimaryKey +
              '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
              i +
              '"  name="' +
              data[i].PrimaryKey +
              '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
          );
          $('#TDG_Item' + i).click(AddTDGItem);
          $('#Search-UN-View-' + i).click(viewUN);
          $('#Search-ERG-View-' + i).click(viewERG);
        } else if (data[i].LA == 1 && $('#CHK_LA').is(':checked')) {
          var EQ_item = 'F';
          if (data[i].EQ_IP !== 'Forbidden' && data[i].EQ_IP !== '')
            EQ_item = data[i].EQ_IP + '/IP<br />' + data[i].EQ_Max + '/pkg';
          var LQ_item = 'F';
          if (data[i].LQ_Max !== 'Forbidden' && data[i].LQ_Max !== '') LQ_item = data[i].LQ_Max;
          var PA_item = 'F';
          if (data[i].PA_Max !== 'Forbidden' && data[i].PA_Max !== '') PA_item = data[i].PA_Max;
          if (data[i].LA == 1 && $('#CHK_LA').is(':checked'))
            $('#UNSearch-Result tr:last').after(
              "<tr><td><button id='TDG_Item" +
                i +
                "' value='" +
                i +
                "' data-mini='true' class='cirro-btn'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> LA</div></button></td><td>" +
                data[i].UN +
                '</td><td>' +
                data[i].Description +
                '</td><td>' +
                data[i].P_Group +
                '</td><td>' +
                data[i].Class_Div +
                '</td><td>' +
                data[i].Labels +
                '</td><td>' +
                EQ_item +
                '</td><td>' +
                LQ_item +
                '</td><td>' +
                PA_item +
                '</td><td>' +
                data[i].CA_Max +
                '</td><td><button id="Search-UN-View-' +
                i +
                '" class="action-btn" name="' +
                data[i].PrimaryKey +
                '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
                i +
                '"  name="' +
                data[i].PrimaryKey +
                '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
            );
          else
            $('#UNSearch-Result tr:last').after(
              "<tr><td><div class='vertical-center-container'><button id='TDG_Item" +
                i +
                "' value='" +
                i +
                "' class='cirro-btn' data-mini='true'><img src='./images/Check_mark_green.svg' /></button></td><td>" +
                data[i].UN +
                '</td><td>' +
                data[i].Description +
                '</td><td>' +
                data[i].P_Group +
                '</td><td>' +
                data[i].Class_Div +
                '</td><td>' +
                data[i].Labels +
                '</td><td>' +
                EQ_item +
                '</td><td>' +
                LQ_item +
                '</td><td>' +
                PA_item +
                '</td><td>' +
                data[i].CA_Max +
                '</td><td><button id="Search-UN-View-' +
                i +
                '" class="action-btn" name="' +
                data[i].PrimaryKey +
                '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
                i +
                '"  name="' +
                data[i].PrimaryKey +
                '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
            );
          $('#TDG_Item' + i).click(AddTDGItem);
          $('#Search-UN-View-' + i).click(viewUN);
          $('#Search-ERG-View-' + i).click(viewERG);
        } else
          $('#UNSearch-Result tr:last').after(
            '<tr><td>Forbidden</td><td>' +
              data[i].UN +
              '</td><td>' +
              data[i].Description +
              '</td><td>' +
              data[i].P_Group +
              '</td><td>' +
              data[i].Class_Div +
              '</td><td>' +
              data[i].Labels +
              '</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>'
          );
      } else {
        var EQ_item = 'F';
        if (data[i].EQ_IP !== 'Forbidden' && data[i].EQ_IP !== '')
          EQ_item = data[i].EQ_IP + '/IP<br />' + data[i].EQ_Max + '/pkg';
        var LQ_item = 'F';
        if (data[i].LQ_Max !== 'Forbidden' && data[i].LQ_Max !== '') LQ_item = data[i].LQ_Max;
        var PA_item = 'F';
        if (data[i].PA_Max !== 'Forbidden' && data[i].PA_Max !== '') PA_item = data[i].PA_Max;
        if (data[i].LA == 1 && $('#CHK_LA').is(':checked'))
          $('#UNSearch-Result tr:last').after(
            "<tr><td><button id='TDG_Item" +
              i +
              "' value='" +
              i +
              "' data-mini='true' class='cirro-btn'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg' /> LA</div></button></td><td>" +
              data[i].UN +
              '</td><td>' +
              data[i].Description +
              '</td><td>' +
              data[i].P_Group +
              '</td><td>' +
              data[i].Class_Div +
              '</td><td>' +
              data[i].Labels +
              '</td><td>' +
              EQ_item +
              '</td><td>' +
              LQ_item +
              '</td><td>' +
              PA_item +
              '</td><td>' +
              data[i].CA_Max +
              '</td><td><button id="Search-UN-View-' +
              i +
              '" class="action-btn" name="' +
              data[i].PrimaryKey +
              '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
              i +
              '"  name="' +
              data[i].PrimaryKey +
              '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
          );
        else if (data[i].UN != 0)
          $('#UNSearch-Result tr:last').after(
            "<tr><td><div class='vertical-center-container'><button id='TDG_Item" +
              i +
              "' value='" +
              i +
              "' class='cirro-btn' data-mini='true'><img src='./images/Check_mark_green.svg' /></button></td><td>" +
              data[i].UN +
              '</td><td>' +
              data[i].Description +
              '</td><td>' +
              data[i].P_Group +
              '</td><td>' +
              data[i].Class_Div +
              '</td><td>' +
              data[i].Labels +
              '</td><td>' +
              EQ_item +
              '</td><td>' +
              LQ_item +
              '</td><td>' +
              PA_item +
              '</td><td>' +
              data[i].CA_Max +
              '</td><td><button id="Search-UN-View-' +
              i +
              '" class="action-btn" name="' +
              data[i].PrimaryKey +
              '"  data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td><td><button id="Search-ERG-View-' +
              i +
              '"  name="' +
              data[i].PrimaryKey +
              '" class="action-btn" data-mini="true" ><div class="vertical-center-container"><img src="./images/inspect.svg" /></div></button></td></tr>'
          );
        else
          $('#UNSearch-Result tr:last').after(
            '<tr><td>Forbidden</td><td>' +
              data[i].UN +
              '</td><td>' +
              data[i].Description +
              '</td><td>' +
              data[i].P_Group +
              '</td><td>' +
              data[i].Class_Div +
              '</td><td>' +
              data[i].Labels +
              '</td><td></td><td></td><td></td><td></td><td></td><td></td></tr>'
          );
        $('#TDG_Item' + i).click(AddTDGItem);
        $('#Search-UN-View-' + i).click(viewUN);
        $('#Search-ERG-View-' + i).click(viewERG);
      }
      //End Air Taxi
    }
  }
  $('#UNSearch-Result tr:last').after('</tbody></table></div>');
  $('#UNSearch-Result').enhanceWithin();
}
function checkForbiddenExplosive(I) {
  var UN = parseInt(I.UN);
  switch (UN) {
    case 30:
    case 42:
    case 59:
    case 65:
    case 81:
    case 82:
    case 83:
    case 84:
    case 241:
    case 331:
    case 332:
    case 360:
      return true;
      break;
    default:
      return false;
  }
}
function ManageTDGConsigors() {
  $('#exitTDGAdminConsignorPage').unbind('click', exitTDGConsignorITIN);
  $('#exitTDGAdminConsignorPage').click(exitTDGConsignorITIN);
  $('#TDGAdminConsignorPage').unbind('click', openConsignorPage);
  $('#TDGAdminConsignorPage').click(openConsignorPage);
  $('#newTDGAdminConsignorPage').unbind('click', newTDGConsignor);
  $('#newTDGAdminConsignorPage').click(newTDGConsignor);
  $('#exitTDGnewConsignorPage').unbind('click', exitTDGnewConsignor);
  $('#exitTDGnewConsignorPage').click(exitTDGnewConsignor);
  openConsignorPage();
}
function exitTDGConsignorITIN() {
  $.mobile.changePage($('#TDG_Home'), {
    reverse: true,
    changeHash: false,
  });
  DownloadOfflineGroupTDG('Reload');
}

var TDG_Manifest = [];

async function AddTDGItem() {
  //console.log("Add TDG Item");
  //console.log(this.value);
  $('#UNSearch-Result').html('');
  $('#search-UN').val('');
  var TDG_Item = SEARCH_data[this.value];
  TDG_Item.Acceptance = 'false';
  TDG_Item.Accepted = 'false';
  TDG_Item.AcceptedBy = chatname;
  TDG_Item.Rejected = 'false';
  TDG_Item.PostOp = 'false';
  var G = null;
  var LA = null;
  var LAIP = null;
  var LA_PAX = null;
  var LA_Note = null;
  var AW = $('#CHK_AW').is(':checked');

  if ($('#CHK_LA').is(':checked')) {
    if (TDG_Item.LA == 1) {
      //item is covered by 12.9 limited access checked
      LA = TDG_Item.LA;
      LAIP = TDG_Item.LA_IP;
      LAIP = LAIP.split(' ');
      LA_PAX = TDG_Item.LA_Pax;
      LA_PAX = LA_PAX.split(' ');
      LA_Note = TDG_Item.LA_Note;
      TDG_Item.Exemption = '(12.9)';
    }
  }
  var AcceptanceOverride = false;

  const tdgCapabilities = VueBridge.dangerousGoodsConstants;
  const hasEquivelancyPerms = await VueBridge.capabilityUtils.areCapabilitiesActionable('CreateFlightWithTdg', [
    tdgCapabilities.equivalencyCertificateTraining,
  ]);

  if (hasEquivelancyPerms) {
    for (i in EqCert) {
      if (EqCert[i].LA == 1 && $('#CHK_LA').is(':checked')) {
        if (
          TDG_Item.UN == EqCert[i].UN &&
          TDG_Item.Class_Div == EqCert[i].Class_Div &&
          TDG_Item.P_Group == EqCert[i].P_Group &&
          EqCert[i].EC == 7720
        ) {
          TDG_Item.Acceptance = 'false';
          TDG_Item.Accepted = 'true';
          AcceptanceOverride = true;
        }
      }
    }
  }
  console.log(TDG_Item);
  //console.log(TDG_Item.PA_Max);
  var Unit = null;
  var EQ_IP = TDG_Item.EQ_IP;
  var EQIP = null;
  if (EQ_IP !== '' && EQ_IP !== 'Forbidden') {
    EQ_IP = EQ_IP.split(' ');
    Unit = EQ_IP[1];
    EQIP = EQ_IP[0];
  }
  var EQ_Max = TDG_Item.EQ_Max;
  var EQ = null;
  if (EQ_Max !== '' && EQ_Max !== 'Forbidden') {
    EQ_Max = EQ_Max.split(' ');
    Unit = EQ_Max[1];
    EQ = EQ_Max[0];
  }
  var LQ_Max = TDG_Item.LQ_Max;
  var LQ = null;
  if (LQ_Max !== '' && LQ_Max !== 'Forbidden') {
    LQ_Max = LQ_Max.split(' ');
    Unit = LQ_Max[1];
    LQ = LQ_Max[0];
  }
  var PA_Max = TDG_Item.PA_Max;
  var PA = null;
  var Exemption = '';
  if (PA_Max !== '' && PA_Max !== 'Forbidden') {
    if (TDG_Item.LA == 1 && $('#CHK_LA').is(':checked')) {
      PA_Max = TDG_Item.LA_Pax;
      Exemption = '(12.9)';
    }
    PA_Max = PA_Max.split(' ');
    if (TDG_Item.PA_Max !== 'No Limit') Unit = PA_Max[1];
    else if (Unit == null) Unit = 'kg';
    PA = PA_Max[0];
    if (PA_Max.length > 2) G = true;
  }
  var CA_Max = TDG_Item.CA_Max;
  var CA = null;
  if (CA_Max !== '' && CA_Max !== 'Forbidden') {
    CA_Max = CA_Max.split(' ');
    if (TDG_Item.CA_Max !== 'No Limit') Unit = CA_Max[1];
    else if (Unit == null) Unit = 'kg';
    CA = CA_Max[0];
    if (CA_Max.length > 2) G = true;
  }
  var unitChoice;
  //if (Unit == null && AW) Unit = "L";
  if (checkForbiddenExplosive(TDG_Item) && $('#CHK_FE').is(':checked')) {
    AcceptanceOverride = true;
    Unit = 'kg';
  }
  if (Unit == 'kg' || Unit == 'g')
    unitChoice =
      "<fieldset data-role='controlgroup' data-type='horizontal' data-mini='true'><legend>Measuring Units</legend><input type='radio' name='TDGUNIT' id='gType' value='g'><label for='gType'>g</label><input type='radio' name='TDGUNIT' id='kgType' value='kg' checked><label for='kgType'>kg</label></fieldset>";
  if (Unit == 'mL' || Unit == 'L')
    unitChoice =
      "<fieldset data-role='controlgroup' data-type='horizontal' data-mini='true'><legend>Measuring Units</legend><input type='radio' name='TDGUNIT' id='mlType' value='mL'><label for='mlType'>mL</label><input type='radio' name='TDGUNIT' id='lType' value='L' checked><label for='lType'>L</label></fieldset>";
  if (Unit == null)
    unitChoice =
      "<fieldset data-role='controlgroup' data-type='horizontal' data-mini='true'><legend>Measuring Units</legend><input type='radio' name='TDGUNIT' id='gType' value='g'><label for='gType'>g</label><input type='radio' name='TDGUNIT' id='kgType' value='kg' /><label for='kgType'>kg</label><input type='radio' name='TDGUNIT' id='mlType' value='mL' /><label for='mlType'>mL</label><input type='radio' name='TDGUNIT' id='lType' value='L' checked /><label for='lType'>L</label></fieldset>";

  var Consignors = '';
  for (i in ConsignorList) {
    Consignors += '<option value="' + i + '">' + ConsignorList[i].Company + '</option> ';
  }
  var Locations;
  for (i in LocationList) {
    Locations += '<option value="' + LocationList[i].Location + '">' + LocationList[i].Location + '</option> ';
  }
  var Packages;
  var p = 1;
  while (p < 11) {
    Packages += '<option value="' + p + '">' + p + '</option> ';
    p++;
  }
  var maxQTY = 'No Limit';
  if ($('#CHK_CO').is(':checked') && CA !== null) maxQTY = parseFloat(CA);
  else {
    if (EQ !== null) maxQTY = parseFloat(EQ);
    if (LQ !== null) maxQTY = parseFloat(LQ);
    if (PA !== null) maxQTY = parseFloat(PA);
  }
  if (LA !== null) {
    G = false;
    Unit = 'kg';
    if ($('#CHK_CO').is(':checked')) {
      if (TDG_Item.LA_IP == '' && TDG_Item.LA_PAX == 'UL') maxQTY = 'No Limit';
      else {
        maxQTY = parseFloat(LAIP[0]);
        Unit = LAIP[1];
      }
    } else {
      //max is passenger limit
      if (TDG_Item.LA_PAX == 'UL') maxQTY = 'No Limit';
      else {
        maxQTY = parseFloat(LA_PAX[0]);
        Unit = LA_PAX[1];
        if (LA_PAX.length > 2) G = true;
      }
    }
  }
  if (AW) {
    TDG_Item.Exemption = '(12.12)';
    if (Unit == 'L') {
      maxQTY = 450;
    } else if (Unit == 'mL') {
      maxQTY = 450000;
    } else {
      maxQTY = 'No Limit';
    }
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Enter Quantity',
    headerClose: false,
    buttonPrompt:
      "<label for='TDG_Con'>Consignor</label><select id='TDG_Con'>" +
      Consignors +
      "</select><label for='pkgQTY'># of Packages</label><input type='number' id='pkgQTY' value='1'><label for='newQTY'>Quantity: " +
      maxQTY +
      ' ' +
      Unit +
      " Max</label><input type='text' id='newQTY' />" +
      unitChoice +
      "<label for='TDG_Location'>Load Location</label><select id='TDG_Location'>" +
      Locations +
      '</select>',
    buttons: {
      OK: {
        click: function () {
          //acknolwedge
          var unitType = $('input:radio[name=TDGUNIT]:checked').val();
          if ($('#newQTY').val() == '') return;
          var unitQTY = parseFloat($('#newQTY').val());
          var pkgQTY = $('#pkgQTY').val();

          TDG_Item.Consignor = ConsignorList[$('#TDG_Con').val()].Company;
          TDG_Item.ConsignorID = ConsignorList[$('#TDG_Con').val()].PrimaryKey;
          TDG_Item.Location = $('#TDG_Location').val();
          TDG_Item.TCtype = tdgType;
          TDG_Item.pkgQTY = pkgQTY;
          TDG_Item.Checklist = null;
          TDG_Item.CheckOK = 'true';
          var CAO = '';
          if (PA == null) CAO = 'X';

          if (TDG_Item.CAO == null) TDG_Item.CAO = CAO;
          TDG_Item.QTY = unitQTY + ' ' + unitType;
          //TDG_Item.Exemption = Exemption;
          var compareItem = unitQTY;
          if (Unit == 'g' || Unit == 'mL') maxQTY = maxQTY * 1000;
          if (unitType !== CA_Max && $('#CHK_CO').is(':checked')) {
            if (unitType == 'mL' || unitType == 'g') compareItem = unitQTY / 1000;
          }
          if (unitType !== PA_Max && !$('#CHK_CO').is(':checked')) {
            if (unitType == 'mL' || unitType == 'g') compareItem = unitQTY / 1000;
          }
          //console.log(compareItem + " " + maxQTY);
          if (G) compareItem = compareItem * pkgQTY; //if limited by gross weight then multiply number of packages
          if (compareItem > maxQTY && maxQTY !== 'No Limit') {
            var LimitType = 'Passenger Aircraft';
            if ($('#CHK_CO').is(':checked')) LimitType = 'Cargo Aircraft';

            //over maximum dangerous goods for cargo only aircraft
            //console.log("Over tdg Limit");
            tdgFail(LimitType, maxQTY + ' ' + Unit, TDG_Item);
          } else {
            if (AW || AcceptanceOverride) {
              TDG_Item.Accepted = 'true';
              if (checkForbiddenExplosive(TDG_Item) && $('#CHK_FE').is(':checked')) {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: 'Forbidden Explosives',
                  headerClose: false,
                  width: '90%',
                  buttonPrompt:
                    'Ensure you comply with the following in TDG regulation 12.5 (1):<br />(c) the explosives<br />' +
                    '<br />' +
                    '(i) are not included in compatibility group A,<br />' +
                    '<br />' +
                    '(ii) have not deteriorated or been damaged,<br />' +
                    '<br />' +
                    '(iii) do not have an active means of initiation and are not primed for use, and<br />' +
                    '<br />' +
                    '(iv) are in a means of containment that is required for them by the packing instructions set out in Chapter 3, Class 1 — Explosives, of Part S-4, Packing Instructions, of the Supplement to the ICAO Technical Instructions; and<br />' +
                    '<br />' +
                    '(d) there are no other dangerous goods transported on board the aircraft at the same time as the explosives.',
                  buttons: {
                    Close: {
                      click: function () {
                        TDG_Item.Exemption = '(12.1)';
                        TDG_Item.CAO = '';
                        TDG_Manifest.push(TDG_Item);
                        templatePrompt();
                        populateManifest();
                      },
                    },
                  },
                });
              } else {
                TDG_Manifest.push(TDG_Item);
                templatePrompt();
                populateManifest();
              }
            } else {
              //check if remote acceptance is required
              var compareEQIP = parseFloat(EQIP);
              if (EQ_IP[1] == 'mL' && EQ_Max[1] == 'L') compareEQIP = parseFloat(EQIP) / 1000;
              if (EQ_IP[1] == 'g' && EQ_Max[1] == 'kg') compareEQIP = parseFloat(EQIP) / 1000;
              var compareEQ = compareItem * pkgQTY;
              //console.log(compareEQIP + " " + compareItem + " " + EQ + " " + compareEQ + " " + pkgQTY);
              if ($('#CHK_ECS').is(':checked') && TDG_Item.CL == '1.4S') TDG_Item.Exemption = '(12.4)';
              if (
                (EQIP !== null &&
                  parseFloat(compareEQIP) < compareItem &&
                  LA == null &&
                  !$('#CHK_ECS').is(':checked') &&
                  TDG_Item.CL !== '1.4S') ||
                (EQ !== null &&
                  parseFloat(EQ) < compareItem &&
                  LA == null &&
                  !$('#CHK_ECS').is(':checked') &&
                  TDG_Item.CL !== '1.4S') ||
                (LQ == null && EQ == null && LA == null && !$('#CHK_ECS').is(':checked') && TDG_Item.CL !== '1.4S') ||
                $('#CHK_FE').is(':checked')
              ) {
                TDG_Item.Acceptance = 'true';

                //display full acceptance checklist for qualified person
                if (TDG_Item.CL !== '') {
                  //look for checklist match if required
                  var C = 3;
                  for (var i in CheckLists) {
                    if (CheckLists[i].CL == TDG_Item.CL) C = i;
                  }
                  popupQ(CheckLists[C].Name, CheckLists[C].Questions, 0, TDG_Item, C);
                } else {
                  popupQ(CheckLists[3].Name, CheckLists[3].Questions, 0, TDG_Item, 3);
                }
              } else {
                TDG_Item.Acceptance = 'false';
                //Full Acceptance is not required for this item
                if (TDG_Item.CL !== '') {
                  //look for checklist match if required
                  var C = 0;
                  for (var i in CheckLists) {
                    if (CheckLists[i].CL == TDG_Item.CL) C = i;
                  }
                  popupQ(CheckLists[C].Name, CheckLists[C].Questions, 0, TDG_Item, C);
                } else {
                  //no checklist and not fully regulated add item
                  TDG_Item.Accepted = 'true';
                  TDG_Manifest.push(TDG_Item);
                  templatePrompt();
                  populateManifest();
                }
              }
            }
          }
        },
      },
    },
  });
}

async function tdgFail(LimitType, limit, TDG_Item) {
  //conduct equivelancy certificate cross examination for possible exemption
  var CertOpts =
    "<h3><center>Over Limit</center></h3><p>A possible Equivalency Certificate has been found.</p><p>Please select the appropriate Equivalency Certificate or Cancel</p><label for='CertOpts'>Equivalency Certificate</label><select id='CertOpts'><option value='-1'>CANCEL</option>";
  var searchCert = false;

  const tdgCapabilities = VueBridge.dangerousGoodsConstants;
  const hasEquivelancyPerms = await VueBridge.capabilityUtils.areCapabilitiesActionable('CreateFlightWithTdg', [
    tdgCapabilities.equivalencyCertificateTraining,
  ]);

  if (hasEquivelancyPerms) {
    for (var i in EqCert) {
      if (
        (EqCert[i].CAO == 1 && $('#CHK_CO').is(':checked')) ||
        (EqCert[i].LA == 1 && $('#CHK_LA').is(':checked')) ||
        (tdgType == 'Air Taxi' && EqCert[i].AT == 1) ||
        (tdgType == 'Aerial Work' && EqCert[i].AW == 1) ||
        (tdgType == 'Air Commuter' && EqCert[i].AC == 1) ||
        (tdgType == 'Airline' && EqCert[i].AL == 1)
      ) {
        if (
          TDG_Item.UN == EqCert[i].UN &&
          TDG_Item.Class_Div == EqCert[i].Class_Div &&
          TDG_Item.P_Group == EqCert[i].P_Group
        ) {
          CertOpts += "<option value='" + i + "'>SA " + EqCert[i].EC + '</option>';
          searchCert = true;
        }
      }
    }
  }
  CertOpts += "</select><div id='ECnote'></div>";
  if (searchCert) {
    $('<div>').simpledialog2({
      callbackOpen: ECnote,
      mode: 'button',
      animate: false,
      headerText: 'Equivalency Certificate',
      headerClose: false,
      buttonPrompt: CertOpts,
      buttons: {
        OK: {
          click: function () {
            //acknolwedge
            var Cert = $('#CertOpts').val();
            if (Cert == '-1') return;
            TDG_Item.Exemption = 'Equivalency Certificate SA ' + EqCert[Cert].EC;
            TDG_Item.EC = EqCert[Cert];
            TDG_Manifest.push(TDG_Item);
            templatePrompt();
            populateManifest();
          },
        },
      },
    });
  } else {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Error',
      headerClose: false,
      buttonPrompt: 'Over ' + LimitType + ' maximum Limit of ' + limit,
      buttons: {
        OK: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
  }
}

function ECnote() {
  $('#CertOpts').change(showECnote);
}

function showECnote() {
  //console.log("ECItem: " + this.value);
  if (this.value == '-1') {
    $('#ECnote').html('');
  } else {
    $('#ECnote').html('<h3>Conditions</h3>' + EqCert[this.value].Note);
  }
}

function populateManifest() {
  //console.log(TDG_Manifest);
  $('#ATManifest').html('');
  if (TDG_Manifest.length > 0) {
    var ManifestOK = true;
    var ManifestHTML =
      '<div style="width: 100%; overflow: auto"><table id="UN_Table" data-filter="true" class="limit-table wrap">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">Del</th>' +
      '<th data-priority="1">Consignor</th>' +
      '<th data-priority="2">Shipping Name</th>' +
      '<th data-priority="3">UN</th>' +
      '<th data-priority="2">Class</th>' +
      '<th data-priority="2">Quantity</th>' +
      '<th data-priority="2">Location</th>' +
      '<th data-priority="2">CAO</th>' +
      '<th data-priority="2">Exemption</th>' +
      '<th data-priority="2">ERG Code</th>' +
      '<th data-priority="2">Acceptance</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';

    for (i in TDG_Manifest) {
      var lineClass = '';
      //check for segregation between packages
      for (s in TDG_Manifest) {
        if (s !== i) {
          //don't check against self
          var mClass = TDG_Manifest[i].Class_Div;
          mClass = mClass.split(' ');
          mClass = mClass[0];
          var cClass = TDG_Manifest[s].Class_Div;
          cClass = cClass.split(' ');
          cClass = cClass[0];
          if ((mClass == '3' && cClass == '5.1') || (mClass == '5.1' && cClass == '3')) lineClass = 'yellowRecord';
          if ((mClass == '4.2' && cClass == '5.1') || (mClass == '5.1' && cClass == '4.2')) lineClass = 'yellowRecord';
          if ((mClass == '4.3' && cClass == '8') || (mClass == '8' && cClass == '4.3')) lineClass = 'yellowRecord';
          var emClass = mClass.split('.');
          var ecClass = cClass.split('.');

          if (emClass[0] == '1' && ecClass[0] == '1') {
            //comparing two explosive class substances
            if (
              (emClass[1] == '4B' && ecClass[1] !== '4B' && ecClass[1] !== '4S') ||
              (emClass[1] !== '4S' && emClass[1] !== '4B' && ecClass[1] == '4B')
            )
              lineClass = 'yellowRecord';
          }
          //All explosives not 1.4S are not compatable with any other class
          if (emClass[0] == '1' && ecClass[0] !== '1') {
            if (emClass[1] !== '4S') lineClass = 'yellowRecord';
          }
          if (ecClass[0] == '1' && emClass[0] !== '1') {
            if (ecClass[1] !== '4S') lineClass = 'yellowRecord';
          }
        }
      }
      var Item = TDG_Manifest[i];
      Item.UN = Item.UN.toString();
      if (Item.UN.length == 1) Item.UN = '000' + Item.UN;
      if (Item.UN.length == 2) Item.UN = '00' + Item.UN;
      if (Item.UN.length == 3) Item.UN = '0' + Item.UN;
      var Accepted = '';
      if (Item.Accepted == 'false' && Item.Rejected == 'false') Accepted = 'Pending';
      if (Item.Accepted == 'false' && Item.Rejected == 'true')
        Accepted =
          'Rejected By:<br />' +
          Item.AcceptedBy +
          "<button id='Reject-" +
          i +
          "' data-mini='true' data-icon='eye' style='width:20px;'> </button>";
      if (Item.Accepted == 'true' || Item.Exemption != '') {
        Item.Accepted = 'true';
        Accepted = 'Accepted By:<br />' + Item.AcceptedBy;
      }
      if (Item.Accepted == 'false')
        Accepted +=
          "<button id='Accept-" + i + "' data-mini='true' style='width:80px;' data-icon='false'>Checklist</button>";
      ManifestHTML +=
        "<tr class='" +
        lineClass +
        "'><td><button id='DEL_TDG_Item" +
        i +
        "' value='" +
        i +
        "' data-mini='true' class='action-btn'><div class='vertical-center-container'><img src='./images/delete.svg' /></div></button></td><td>" +
        Item.Consignor +
        '</td><td>' +
        Item.Description +
        '</td><td>' +
        Item.UN +
        '</td><td>' +
        Item.Class_Div +
        '</td><td>' +
        Item.pkgQTY +
        'x' +
        Item.QTY +
        '</td><td>' +
        Item.Location +
        '</td><td>' +
        Item.CAO +
        '</td><td>' +
        Item.Exemption +
        '</td><td>' +
        Item.ERG_Num +
        '</td><td>' +
        Accepted +
        '</td></tr>';
      if (Item.Accepted == 'false') {
        ManifestOK = false;
        lineClass = 'redRecord';
      }
    }
    ManifestHTML += '</tbody></table></div>';
    ManifestHTML +=
      "<button id='SaveTemplate' data-icon='plus' data-mini='true' class='greenbg'>Save as New Template</button>";
    if (templateVal != '-1') {
      ManifestHTML += "<button id='UpdateTemplate' data-icon='check' data-mini='true'>Update This Template</button>";
      if (ADMINACCESS == 1)
        ManifestHTML +=
          "<button id='DeleteTemplate' data-icon='delete' data-mini='true' class='redbg'>Delete Template</button>";
    }
    if (TDG_OP == 'Planning')
      ManifestHTML += "<button id='ManifestComplete' data-icon='check' data-mini='true'>Finished</button>";
    if (TDG_OP == 'Itinerary') {
      if (ManifestOK == false)
        ManifestHTML += '<h3>Cannot add this manifest to the itinerary.  1 or more items require acceptance</h3>';
      else
        ManifestHTML +=
          "<button id='ManifestComplete' data-icon='check' data-mini='true'>Add Manifest to Itinerary</button>";
    }
    //add tdg to appropriate manifest holder

    $('#ATManifest').html(ManifestHTML);
    $.mobile.changePage($('#TDG_AT'), {
      reverse: true,
      changeHash: false,
    });

    for (i in TDG_Manifest) {
      $('#DEL_TDG_Item' + i).click(deleteTDGitem);
      if (TDG_Manifest[i].Accepted == 'false') $('#Accept-' + i).click(updateTDGstatus);
      if (TDG_Manifest[i].Rejected == 'true') $('#Reject-' + i).click(Review_TDGItem);
    }
    $('#SaveTemplate').click(SaveTemplate);
    if (templateVal != '-1') $('#UpdateTemplate').click(UpdateTemplate);
    if (ADMINACCESS == 1) $('#DeleteTemplate').click(DeleteTemplate);
    if ((TDG_OP == 'Itinerary' && ManifestOK == true) || TDG_OP == 'Planning') $('#ManifestComplete').click(exitTDG);

    $('#ATManifest').enhanceWithin();
  } else {
    $('#ATManifest').html('<p>No Items</p>');
  }
}

function DeleteTemplate() {
  //console.log("Deleting template Manifest");
  if (ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Confirm',
      headerClose: false,
      buttonPrompt: 'Delete template: ' + TemplateID.Name + '?',
      buttons: {
        Yes: {
          click: function () {
            $.ajax({
              type: 'POST',
              dataType: 'json',
              url: BASEURL + 'menu-TDG-AJAX.php?action=deleteTemplate',
              data: {
                TemplateName: TemplateID.Name,
                tdgType: tdgType,
              },
            })
              .success(function (data) {
                //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
                //console.log("ServerResponse: success");
                //console.log(data);
                templateVal = '-1';
                //ensure data[0].ServerResponse == Success
                //getTDGgroupData();
                DownloadOfflineGroupTDG('Reload');
                TDG_Manifest = [];

                $('#ATManifest').html('<p>No Items</p>');
              })
              .fail(function (data) {
                //data.status, data.readyState, data.responseText
                //console.log(data);
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: 'Failed!',
                  headerClose: false,
                  buttonPrompt:
                    'There was a problem deleting the template.  Please try again. If this continues to happen please submit a bug report.',
                  buttons: {
                    Close: {
                      click: function () {},
                    },
                    Report: {
                      click: function () {
                        //File bug report
                      },
                    },
                  },
                });
              });
          },
        },
        Cancel: {
          click: function () {},
        },
      },
    });
  } else {
    NotAvailableOffline('');
  }
}
function NotAvailableOffline(text) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Offline',
    headerClose: false,
    buttonPrompt: 'Operation Not Available Offline',
    buttons: {
      OK: {
        click: function () {},
      },
    },
  });
}
function SaveTemplate() {
  //console.log("Saving Manifest");
  if (ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Enter Template Name',
      headerClose: false,
      buttonPrompt: "<label for='TemplateName'>Template Name</label><input type='text' id='TemplateName' />",
      buttons: {
        Cancel: {
          click: function () {
            //do nothing
          },
        },
        OK: {
          click: function () {
            //acknolwedge
            var checkState = getCheckState();
            var TemplateName = $('#TemplateName').val();
            if (TemplateName != '') {
              $.ajax({
                type: 'POST',
                dataType: 'json',
                url: BASEURL + 'menu-TDG-AJAX.php?action=saveTemplate',
                data: {
                  Manifest: TDG_Manifest,
                  TemplateName: TemplateName,
                  tdgType: tdgType,
                  checkState: checkState,
                },
              })
                .success(function (data) {
                  //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
                  //console.log("ServerResponse: success");
                  //console.log(data);
                  //ensure data[0].ServerResponse == Success
                  //getTDGgroupData();
                  DownloadOfflineGroupTDG('Reload');
                })
                .fail(function (data) {
                  //data.status, data.readyState, data.responseText
                  //console.log(data);
                  $('<div>').simpledialog2({
                    mode: 'button',
                    animate: false,
                    headerText: 'Failed!',
                    headerClose: false,
                    buttonPrompt:
                      'There was a problem saving the template.  Please try again. If this continues to happen please submit a bug report.',
                    buttons: {
                      Close: {
                        click: function () {},
                      },
                      Report: {
                        click: function () {
                          //File bug report
                        },
                      },
                    },
                  });
                });
            } //end if
          },
        },
      },
    });
  } else {
    NotAvailableOffline('');
  }
}

function getCheckState() {
  let checkState = '';

  for (const id of tdgCheckBoxIds) {
    if ($('#' + id).is(':checked')) checkState += '1,';
    else checkState += '0,';
  }

  // remove trailing comma
  return checkState.slice(0, -1);
}

function getLoadingSupervisorRequiredDGCapabilities() {
  let requiredCapabilities = [];

  for (const id of tdgCheckBoxIds) {
    if (id === 'CHK_CO' || id === 'CHK_PL') {
      // skip items without associated capabilities
      continue;
    }

    if ($('#' + id).is(':checked')) requiredCapabilities.push(chkDGCapabilitySupervisorMap[id]);
  }

  return requiredCapabilities;
}

function getPilotRequiredDGCapabilities() {
  let requiredCapabilities = [];

  for (const id of tdgCheckBoxIds) {
    if (id === 'CHK_CO' || id === 'CHK_PL') {
      // skip items without associated capabilities
      continue;
    }

    if ($('#' + id).is(':checked')) requiredCapabilities = requiredCapabilities.concat(chkDGCapabilityPilotMap[id]);
  }

  return requiredCapabilities;
}

function UpdateTemplate() {
  //console.log("Saving Manifest");
  if (ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Template Name',
      headerClose: false,
      buttonPrompt:
        "<label for='TemplateName'>Template Name</label><input type='text' id='TemplateName' value='" +
        TemplateID.Name +
        "'/>",
      buttons: {
        Cancel: {
          click: function () {
            //do nothing
          },
        },
        OK: {
          click: function () {
            //acknolwedge
            var checkState = getCheckState();
            var TemplateName = $('#TemplateName').val();
            if (TemplateName != '') {
              $.ajax({
                type: 'POST',
                dataType: 'json',
                url: BASEURL + 'menu-TDG-AJAX.php?action=updateTemplate',
                data: {
                  Manifest: TDG_Manifest,
                  TemplateName: TemplateName,
                  TemplatePK: TemplateID.PrimaryKey,
                  checkState: checkState,
                },
              })
                .success(function (data) {
                  //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
                  //console.log("ServerResponse: success");
                  //console.log(data);
                  //ensure data[0].ServerResponse == Success
                  //getTDGgroupData();
                  DownloadOfflineGroupTDG('Reload');
                })
                .fail(function (data) {
                  //data.status, data.readyState, data.responseText
                  //console.log(data);
                  $('<div>').simpledialog2({
                    mode: 'button',
                    animate: false,
                    headerText: 'Failed!',
                    headerClose: false,
                    buttonPrompt:
                      'There was a problem updating the template.  Please try again. If this continues to happen please submit a bug report.',
                    buttons: {
                      Close: {
                        click: function () {},
                      },
                      Report: {
                        click: function () {
                          //File bug report
                        },
                      },
                    },
                  });
                });
            } //end if
          },
        },
      },
    });
  } else {
    NotAvailableOffline('');
  }
}

async function canUseTemplate(index) {
  let checkState = TemplateList[index].checkState;
  checkState = checkState.split(',');
  let requiredCapabilities = [];

  for (const [i, id] of tdgCheckBoxIds.entries()) {
    if (id === 'CHK_CO' || id === 'CHK_PL') {
      // skip items without associated capabilities
      continue;
    }

    if (checkState[i] == '1') {
      requiredCapabilities = requiredCapabilities.concat(chkDGCapabilityPilotMap[id]);
    }

    if (checkState.length === i + 1) {
      break; // we've reached the end of the checkState array legacy chaeckstates don't have the same number of items
    }
  }

  return await VueBridge.capabilityUtils.areCapabilitiesActionable('CreateFlightWithTdg', requiredCapabilities);
}

async function templatePrompt() {
  $('#AT_Template').empty();
  $('#AT_Template').append('<option value="-1" >Blank Manifest</option>');

  for (i in TemplateList) {
    let disabled = '';
    if (!(await canUseTemplate(i))) {
      disabled = 'disabled';
    }

    if (templateVal !== i) {
      $('#AT_Template').append(`<option value='${i}' ${disabled}>${TemplateList[i].Name}</option>`);
    } else if (disabled !== '') {
      $('#AT_Template').append('<option value=' + i + ' disabled>' + TemplateList[i].Name + ' (unsaved)</option>');
    } else {
      $('#AT_Template').append('<option value=' + i + ' selected>' + TemplateList[i].Name + '</option>');
    }
  }
  if (templateVal != -1) {
    $('#AT_Template-button').html('<span>' + TemplateList[templateVal].Name + '</span>');
  } else {
    $('#AT_Template-button').html('<span>Blank Manifest</span>');
  }

  $('#AT_template').selectmenu('refresh', true);
  $('#TDG_AT').enhanceWithin();
}

function deleteTDGitem() {
  if (ONLINE) {
    //console.log("delete: " + this.value);
    var deleteItem = this.value;
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Confirm',
      headerClose: false,
      buttonPrompt: 'Delete this item?',
      buttons: {
        Yes: {
          click: function () {
            TDG_Manifest.splice(deleteItem, 1);
            templatePrompt();
            populateManifest();
          },
        },
        Cancel: {
          click: function () {},
        },
      },
    });
  } else {
    NotAvailableOffline('');
  }
}

function NarrowSearch() {
  var id = this.id.split('-');
  var key = id[3];
  var Item = SEARCH_data[key];
  //console.log(Item);
  //Item.Description.replace(",", '');
  var Search = Item.Description.split('- see');
  var UNtxt = $('#search-Description').val();
  $.getJSON(
    BASEURL + 'menu-TDG-AJAX.php?action=NarrowSearch&Text=' + encodeURIComponent(Search[1]),
    function (json_data) {
      //console.log("Data Received");
      SEARCH_data = json_data;
      ListResults(json_data);
    }
  ).fail(function (jqXHR, status, error) {
    //console.log("Failed to getAll");
  });
}

function viewERG() {
  var id = this.id;
  //console.log("name: " + this.name);
  $.mobile.changePage($('#ERGDisplay'), {
    reverse: false,
    changeHash: false,
  });
  if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 == 'Available') {
    TDG_DATABASE.query('TDG_Identification', this.name, 'PK', function (e) {
      if (e === false) return;
      if (i18n.locale == 'fr') $('#ERG-Info').html(e[0].ERG_fr);
      else $('#ERG-Info').html(e[0].ERG_en);
    });
  } else {
    $.getJSON(BASEURL + 'menu-TDG-AJAX.php?action=getERG&UN=' + this.name, function (json_data) {
      if (i18n.locale == 'fr') $('#ERG-Info').html(json_data[0].ERG_fr);
      else $('#ERG-Info').html(json_data[0].ERG_en);
    }).fail(function (jqXHR, status, error) {
      //console.log("Failed to viewERG");
    });
  }
}

function viewUN() {
  $.mobile.changePage($('#UNDisplay'), {
    reverse: false,
    changeHash: false,
  });
  var id = this.id;
  //console.log("name:" + this.name);
  if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 == 'Available') {
    TDG_DATABASE.query('UN', this.name, null, function (e) {
      if (e === false || e.length == 0 || e == '') return;
      viewUN2(e[0]);
    });
  } else {
    $.getJSON(BASEURL + 'menu-TDG-AJAX.php?action=getUN&PrimaryKey=' + this.name, function (json_data) {
      //console.log("Data Received");
      viewUN2(json_data[0]);
    }).fail(function (jqXHR, status, error) {
      //console.log("Failed to getAll");
    });
  }
}

function viewUN2(data) {
  var HTML = '';
  if (data.Class_Div == 'Forbidden') HTML += '<h1>UN ' + data.UN + '  *Forbidden*</h1>';
  else HTML += '<h1>UN ' + data.UN + '</h1>';
  HTML += '<p><strong>Description: </strong>' + data.Description + '</p>';
  HTML += '<p><strong>Class/Division: </strong>' + data.Class_Div + '</p>';
  if (data.P_Group != '') {
    var PG;
    switch (data.P_Group) {
      case 'I':
        PG = '(Great Danger)';
        break;
      case 'II':
        PG = '(Medium Danger)';
        break;
      case 'III':
        PG = '(Minor Danger)';
        break;
    }
    HTML += '<p><strong>Packing Group: </strong>' + data.P_Group + ' ' + PG + '</p>';
  }
  if (data.Class_Div != 'Forbidden') {
    if (data.Labels != '') {
      HTML += '<p><strong>Labels: </strong>' + data.Labels + '</p>';
    }
    if (data.LQ_PI != '') {
      HTML += '<p><strong>Limited Quantity Max: </strong>' + data.LQ_Max + '</p>';
      HTML += '<div data-role="collapsible" data-collapsed="true" data-mini="true">';
      HTML += '<h3>Packing Instruction: ' + data.LQ_PI + '</h3>';
      HTML += '<div style="width: 100%; overflow: auto"><p>' + data.PI_LQ + '</p></div>';
      HTML += '</div>';
    }
    if (data.PA_PI != '') {
      HTML += '<p><strong>Passenger Aircraft Max: </strong>' + data.PA_Max + '</p>';
      HTML += '<div data-role="collapsible" data-collapsed="true" data-mini="true">';
      HTML += '<h3>Packing Instruction: ' + data.PA_PI + '</h3>';
      HTML += '<div style="width: 100%; overflow: auto"><p>' + data.PI_PA + '</p></div>';
      HTML += '</div>';
    }
    if (data.CA_PI != '') {
      HTML += '<p><strong>Cargo Aircraft Max: </strong>' + data.CA_Max + '</p>';
      HTML += '<div data-role="collapsible" data-collapsed="true" data-mini="true">';
      HTML += '<h3>Packing Instruction: ' + data.CA_PI + '</h3>';
      HTML += '<div style="width: 100%; overflow: auto"><p>' + data.PI_CA + '</p></div>';
      HTML += '</div>';
    }
    if (data.SP_id != '') {
      HTML += '<p><strong>Special Provisions: </strong>' + data.SP_id + '</p>';
      HTML += '<div data-role="collapsible" data-collapsed="true" data-mini="true">';
      HTML += '<h3>Special Provision: ' + data.SP_id + '</h3>';
      HTML += '<div style="width: 100%; overflow: auto"><p>' + data.SP + '</p></div>';
      HTML += '</div>';
    }
  }
  $('#UN-Info').html(HTML);
  $('#UN-Info').enhanceWithin();
}

function checkList(C) {
  var TDG_Item = CheckLists[C].TDG_Item;

  var unitChoice =
    "<fieldset data-role='controlgroup' data-type='horizontal' data-mini='true'><legend>Measuring Units</legend><input type='radio' name='TDGUNIT2' id='gType' value='g'><label for='gType'>g</label><input type='radio' name='TDGUNIT2' id='kgType' value='kg' checked><label for='kgType'>kg</label></fieldset>";

  var Consignors = '';
  for (i in ConsignorList) {
    Consignors += '<option value="' + i + '">' + ConsignorList[i].Company + '</option> ';
  }
  var Locations;
  for (i in LocationList) {
    Locations += '<option value="' + LocationList[i].Location + '">' + LocationList[i].Location + '</option> ';
  }
  var Packages;
  var p = 1;
  while (p < 11) {
    Packages += '<option value="' + p + '">' + p + '</option> ';
    p++;
  }
  var maxQTY = CheckLists[C].Max;
  var Unit = CheckLists[C].Units;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Enter Quantity',
    headerClose: false,
    buttonPrompt:
      "<label for='TDG_Con2'>Consignor</label><select id='TDG_Con2'>" +
      Consignors +
      "</select><label for='pkgQTY2'># of Packages</label><select id='pkgQTY2'>" +
      Packages +
      "</select><label for='newQTY2'>Quantity: " +
      maxQTY +
      ' ' +
      Unit +
      " Max</label><input type='text' id='newQTY2' />" +
      unitChoice +
      "<label for='TDG_Location2'>Load Location</label><select id='TDG_Location2'>" +
      Locations +
      '</select>',
    buttons: {
      Checklist: {
        click: function () {
          //acknolwedge
          var unitType = $('input:radio[name=TDGUNIT2]:checked').val();
          if ($('#newQTY2').val() == '') return;
          var unitQTY = parseFloat($('#newQTY2').val());
          var pkgQTY = $('#pkgQTY2').val();
          TDG_Item.Consignor = ConsignorList[$('#TDG_Con2').val()].Company;
          TDG_Item.ConsignorID = $('#TDG_Con2').val();
          TDG_Item.Location = $('#TDG_Location2').val();
          TDG_Item.pkgQTY = pkgQTY;

          TDG_Item.CAO = '';
          TDG_Item.QTY = unitQTY + ' ' + unitType;
          //TDG_Item.Exemption = Exemption;
          var compareItem = unitQTY;
          if (unitType == 'mL' || unitType == 'g') compareItem = unitQTY / 1000;

          if (compareItem > maxQTY) {
            var LimitType = 'Passenger Aircraft';
            if ($('#CHK_CO').is(':checked')) LimitType = 'Cargo Aircraft';
            //over maximum dangerous goods for cargo only aircraft
            //console.log("Over tdg Limit");
            tdgFail(LimitType, maxQTY + ' ' + Unit, TDG_Item);
          } else {
            //check if remote acceptance is required
            TDG_Item.Acceptance = 'false';
            popupQ(CheckLists[C].Name, CheckLists[C].Questions, 0, TDG_Item, C);
          }
        },
      },
    },
  });
}

function updateTDGstatus() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  TDG_Manifest[index].PostOp = 'true';
  popupQ(CheckLists[3].Name, CheckLists[3].Questions, 0, TDG_Manifest[index], 3);
}

function popupQ(Title, Questions, q, TDG_Item, CL) {
  for (var c in CheckLists[CL].Questions) {
    CheckLists[CL].Questions[c].A = null; //reset all questions to unanswered;
  }
  //console.log(TDG_Item);
  $.mobile.changePage($('#TDG_Checklist'), {
    reverse: false,
    changeHash: false,
  });
  var html = '<h3>' + Title + '</h3>';

  html += '<table class="limit-table first-td-fill"><thead><tr><th>Question</th><th>Answer</th></tr></thead><tbody>';
  var PrevHeading = '';
  for (var i in Questions) {
    var I = Questions[i];
    var options =
      '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true" style="width:150px;">' +
      '<label for="TDGQ_idyes' +
      i +
      '">Yes</label>' +
      '<input type="radio" name="TDGQ' +
      i +
      '" id="TDGQ_idyes' +
      i +
      '" value="Yes" onClick="TDG_CheckedList(this,' +
      i +
      ',' +
      CL +
      ')">' +
      '<label for="TDGQ_idno' +
      i +
      '">No</label>' +
      '<input type="radio" name="TDGQ' +
      i +
      '" id="TDGQ_idno' +
      i +
      '" value="No" onClick="TDG_CheckedList(this,' +
      i +
      ',' +
      CL +
      ')">';
    if (I.L == 3)
      options +=
        '<label for="TDGQ_idna' +
        i +
        '">N/A</label>' +
        '<input type="radio" name="TDGQ' +
        i +
        '" id="TDGQ_idna' +
        i +
        '" value="N/A" onClick="TDG_CheckedList(this,' +
        i +
        ',' +
        CL +
        ')">';
    options += '</fieldset>';
    var Item = I.Q;
    if (I.H !== undefined && I.H != PrevHeading) {
      Item = I.H + I.Q;
      PrevHeading = I.H;
    }
    html += '<tr><td id="TDG_TD' + i + '" class="yellowbg">' + Item + '</td><td>' + options + '</td></tr>';
  }
  html += '</tbody></table>';
  html += '<button data-icon="check" id="TDG_CompletedListBTN">Review and Reject Package</button>';

  $('#TDG_ChecklistContent').html(html);
  $('#TDG_ChecklistContent').enhanceWithin();

  $('#TDG_CompletedListBTN').click(function () {
    ChecklistComplete(TDG_Item, Questions);
  });
}
function TDG_CheckedList(e, index, CL) {
  var id = e.id;
  var A = e.value;
  CheckLists[CL].Questions[index].A = A;
  $('#TDG_TD' + index).removeClass('yellowbg');
  $('#TDG_TD' + index).removeClass('redbg');
  $('#TDG_TD' + index).removeClass('greenbg');
  if (A == 'No') $('#TDG_TD' + index).addClass('redbg');
  if (A == 'Yes') $('#TDG_TD' + index).addClass('greenbg');
  var Questions = CheckLists[CL].Questions;
  var Accepted = true;
  $('#TDG_CompletedListBTN').removeClass('greenbg');
  $('#TDG_CompletedListBTN').removeClass('redbg');
  for (var i in Questions) if (Questions[i].A == 'No' || Questions[i].A == null) Accepted = false;
  if (Accepted) {
    $('#TDG_CompletedListBTN').html('Checklist Complete.  Add to Manifest');
    $('#TDG_CompletedListBTN').addClass('greenbg');
  } else {
    $('#TDG_CompletedListBTN').html('Review and Reject Package');
    $('#TDG_CompletedListBTN').addClass('redbg');
  }
}

function ChecklistComplete(TDG_Item, Questions) {
  //console.log("checklist completed");
  TDG_Item.CheckOK = 'true';
  for (i in Questions) if (Questions[i].A == 'No' || Questions[i].A == null) TDG_Item.CheckOK = 'false';
  TDG_Item.Checklist = Questions;
  TDG_Item.AcceptedBy = chatname;
  if (TDG_Item.CheckOK == 'true') {
    //checklist ok add item to manifest
    TDG_Item.Accepted = 'true';
    if (TDG_Item.PostOp == 'false') TDG_Manifest.push(TDG_Item);
    templatePrompt();
    populateManifest();
  } else {
    //checklist problem email to self or dg coordinator for forwarding

    //find items that failed checklist
    var Wrong = '';
    TDG_Item.Accepted = 'false';
    TDG_Item.Rejected = 'true';
    for (i in Questions) {
      if (Questions[i].A == 'No') Wrong += '<p>' + Questions[i].Q + '</p>';
      if (Questions[i].A == null) Wrong += '<p>' + Questions[i].Q + ' - <b>Not Answered</b></p>';
    }

    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Not Accepted',
      headerClose: false,
      buttonPrompt: '<p><b>Package rejected due to no compliance of:</b></p>' + Wrong,
      buttons: {
        Ok: {
          click: function () {
            //acknolwedge
            templatePrompt();
            populateManifest();
            //TODO Create ajax request to store rejected shippment
            Reject_TDGItem(TDG_Item);
            $.mobile.changePage($('#TDG_ADD'), {
              reverse: true,
              changeHash: false,
            });
          },
        },
      },
    });
  }
}
function ExitChecklist() {
  $.mobile.changePage($('#TDG_ADD'), {
    reverse: true,
    changeHash: false,
  });
}
function Review_TDGItem() {
  var index = this.id;
  index = index.split('-');
  index = index[1];
  var TDG_Item = TDG_Manifest[index];
  var Questions = TDG_Item.Checklist;
  var Wrong = '';
  for (i in Questions) {
    if (Questions[i].A == 'No') Wrong += '<p>' + Questions[i].Q + '</p>';
    if (Questions[i].A == null) Wrong += '<p>' + Questions[i].Q + ' - <b>Not Answered</b></p>';
  }
  $('<div>').simpledialog2({
    width: '100%',
    mode: 'button',
    animate: false,
    headerText: 'Not Accepted',
    headerClose: false,
    buttonPrompt: '<p><b>Package rejected due to no compliance of:</b></p>' + Wrong,
    buttons: {
      Ok: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function Reject_TDGItem(TDG_Item) {
  //console.log("Sending rejection data to server");

  //todo if offline store rejected Item in database ans sync on next connection
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-TDG-AJAX.php?action=Reject',
    data: {
      Manifest: TDG_Item,
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      //console.log("ServerResponse: success");
      //console.log(data);
      //ensure data[0].ServerResponse == Success
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Failed!',
        headerClose: false,
        buttonPrompt:
          'There was a problem saving the rejected acceptance information to the server. If this continues to happen please submit a bug report.',
        buttons: {
          Close: {
            click: function () {},
          },
          Report: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}
