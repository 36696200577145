/**
 *
 */
class FuelCacheUpliftsTable extends AbstractDatabaseTable {
  constructor() {
    super('FuelCacheUplifts', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "PrimaryKey"     integer primary key,
            "group"          text,
            "location_name"  text,
            "comment"        text,
            "datetime"       text,
            "FC_PK"          integer,
            "Itin_PK"        integer,
            "Itin_OfflinePK" integer,
            "FR_PK"          integer,
            "FR_UID"         integer,
            "LegNum"         integer,
            "Amount"         numeric,
            "Units"          text,
            "FuelType"       text,
            "convertTo"      text,
            "cacheUpdated"   integer,
            "historyPK"      integer
        )
    `;
    //#endregion
  }
}
