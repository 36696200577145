/**
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class mSAMA_RiskMatrix extends mSAMA_AbstractModel {
  /**
   *
   * @param {object} _databaseData
   */
  constructor(_databaseData) {
    super('mtx_groupPk');
    this.riskFactors = [];

    this.sync = {
      mtx_groupPk: null,
      mtx_version: -1,

      mtx_titlesHighest: null,
      mtx_titlesHigh: null,
      mtx_titlesMedium: null,
      mtx_titlesLow: null,
      mtx_titlesNone: null,

      mtx_boundsHigh: null,
      mtx_boundsMedium: null,

      mtx_freqQuestion: null,
      mtx_freqNameHighest: null,
      mtx_freqNameHigh: null,
      mtx_freqNameMedium: null,
      mtx_freqNameLow: null,
      mtx_freqNameLowest: null,

      mtx_freqDescHighest: null,
      mtx_freqDescHigh: null,
      mtx_freqDescMedium: null,
      mtx_freqDescLow: null,
      mtx_freqDescLowest: null,
    };

    $.extend(this.sync, _databaseData.matrix[0]);

    let titleValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 3,
      maxLength: SAMA.enums.TEXTLENGTHS.Tiny,
    });

    let descriptionValidator = new cSAMA_Validator({
      type: SAMA.enums.VALIDATORS.STRING,
      minLength: 5,
      maxLength: SAMA.enums.TEXTLENGTHS.Small,
    });

    this.validateSettings = {
      mtx_titlesHighest: titleValidator,
      mtx_titlesHigh: titleValidator,
      mtx_titlesMedium: titleValidator,
      mtx_titlesLow: titleValidator,
      mtx_titlesNone: titleValidator,

      mtx_freqQuestion: descriptionValidator,
      mtx_freqNameHighest: titleValidator,
      mtx_freqNameHigh: titleValidator,
      mtx_freqNameMedium: titleValidator,
      mtx_freqNameLow: titleValidator,
      mtx_freqNameLowest: titleValidator,

      mtx_freqDescHighest: descriptionValidator,
      mtx_freqDescHigh: descriptionValidator,
      mtx_freqDescMedium: descriptionValidator,
      mtx_freqDescLow: descriptionValidator,
      mtx_freqDescLowest: descriptionValidator,
    };

    if (this.GetPk() == null) {
      this.SetPk(cSAMA_Utils.GetUID());
    }

    for (let rf = 0; rf < _databaseData.riskFactors.length; rf++) {
      let factorData = _databaseData.riskFactors[rf];
      let newFactor = new mSAMA_RiskFactor(this, factorData);

      for (let cf = 0; cf < _databaseData.customFields.length; cf++) {
        let fieldData = _databaseData.customFields[cf];

        if (fieldData.mcf_mrfPk !== factorData.mrf_primaryKey) {
          continue;
        }
        newFactor.AddCustomField(new mSAMA_CustomField(newFactor, fieldData));
      }

      this.riskFactors.push(newFactor);
    }
  }

  /**
   * Adds a new risk factor to this matrix
   * @param {mSAMA_RiskFactor} _newFactor
   */
  AddRiskFactor(_newFactor) {
    this.riskFactors.push(_newFactor);
    this.SetModified(true);
  }

  /**
   *
   * @param _pk
   * @return {mSAMA_RiskFactor|null}
   */
  GetRiskFactorByPk(_pk) {
    let results = this.riskFactors.filter((_e) => {
      return _e.GetPk() === _pk;
    });

    if (results.length !== 1) {
      throw 'Found more or less than one Risk Factor.';
    }

    return results[0];
  }

  /**
   * Get the risk bounds
   * @returns {{high: int, medium: int}}
   */
  GetBounds() {
    return {
      high: this.sync.mtx_boundsHigh,
      medium: this.sync.mtx_boundsMedium,
    };
  }

  /**
   * Gets the Risk factors
   * @return {mSAMA_RiskFactor[]}
   */
  GetRiskFactors() {
    return this.riskFactors;
  }

  /**
   * Returns the object as a transmittable object
   * @return {object|null}
   * @override
   */
  GetTransmissible() {
    if (!this.IsModified()) {
      return null;
    }

    let r = {
      riskMatrix: this.sync,
      riskFactors: [],
    };

    for (let rf = 0; rf < this.riskFactors.length; rf++) {
      let t = this.riskFactors[rf].GetTransmissible();

      if (t !== null) {
        r.riskFactors.push(t);
      }
    }

    return r;
  }

  /**
   * Returns the object as a transmittable object, ignoring the modified states
   * @return {object|null}
   * @override
   */
  GetFullTransmissible() {
    let r = {
      riskMatrix: this.sync,
      riskFactors: [],
    };

    for (let rf = 0; rf < this.riskFactors.length; rf++) {
      let t = this.riskFactors[rf].GetFullTransmissible();

      if (t !== null) {
        r.riskFactors.push(t);
      }
    }

    return r;
  }

  /**
   * Gets the risk name (high med low..etc) from their corresponding integer value
   * @param {int} _freqValue
   * @returns {string|null}
   */
  GetFrequencyName(_freqValue) {
    switch (+_freqValue) {
      case 1:
        return this.sync.mtx_freqNameLowest;
      case 2:
        return this.sync.mtx_freqNameLow;
      case 3:
        return this.sync.mtx_freqNameMedium;
      case 4:
        return this.sync.mtx_freqNameHigh;
      case 5:
        return this.sync.mtx_freqNameHighest;
      default:
        throw 'Not a valid frequency value: ' + _freqValue;
    }
  }

  /**
   * Gets the risk description from their corresponding integer value
   * @param {int} _freqValue
   * @returns {string|null}
   */
  GetFrequencyDesc(_freqValue) {
    switch (+_freqValue) {
      case 1:
        return this.sync.mtx_freqDescLowest;
      case 2:
        return this.sync.mtx_freqDescLow;
      case 3:
        return this.sync.mtx_freqDescMedium;
      case 4:
        return this.sync.mtx_freqDescHigh;
      case 5:
        return this.sync.mtx_freqDescHighest;
      default:
        throw 'Not a valid frequency value: ' + _freqValue;
    }
  }
}
