/**
 * Base class for all page classes to inherit from
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @class
 * @abstract
 */
class pSAMA_AbstractPage extends iSAMA_AbstractObservable {
  /**
   * @param {string} _domId
   * @param {pSAMA_AbstractPage} _prevPage
   */
  constructor(_domId, _prevPage) {
    super();
    this.domId = '#' + _domId;
    this.prevPage = _prevPage;
    this.loaded = true;

    this.dom = {
      page: $(this.domId),
    };
  }

  /**
   * Get the html-ID of this page
   * @return {string}
   */
  GetPageId() {
    return this.domId;
  }

  /**
   * Get the page that we access this one from by default
   * @return {pSAMA_AbstractPage|string|null}
   */
  GetPrevPage() {
    if (this.tempPrev != null) {
      let t = this.tempPrev;
      this.tempPrev = null;
      return t;
    }

    return this.prevPage;
  }

  /**
   * Provides a temporary page to return to after this one
   * @param {pSAMA_AbstractPage|function} _prev
   */
  SetTempPrevPage(_prev) {
    this.tempPrev = _prev;
  }

  /**
   * Function called when the user enters the page.
   * @param {*} _preseed
   * @abstract
   */
  EnterPage(_preseed) {}

  /**
   * Function called when the user leaves the page.
   */
  LeavePage() {
    SAMA.Navigator.FinishedLoading();
  }

  /**
   * Store changes made on this page's models
   */
  SaveHandler() {}

  /**
   * Function called to set up the pages' GUI
   */
  SetUpGui() {}

  EqualTo(_otherPage) {
    return _otherPage.domId === this.domId;
  }

  PageLoadFailed() {
    if (this.resLock != null) {
      this.resLock.StopLocking();
    }
    this.loaded = false;
  }
}
