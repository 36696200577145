/**
 *
 */
class WaypointsTable extends AbstractDatabaseTable {
  constructor() {
    super('Waypoints', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey integer primary key,
            Name       text,
            datetime   text,
            data       text
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    if (whereClause === 'PrimaryKey != ?') {
      columns = ['rowid', 'PrimaryKey', 'Name', 'datetime'];
      orderByClause = 'order by "Name" asc';
    }

    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    for (const row of rows) {
      row.data = this.parseJson(row.data);
    }

    return rows;
  }
}
