class vSAMA_PreferencesEditor extends vSAMA_AbstractEditor {
  constructor(_pageBody, _kwdCategories, _reportCategories) {
    super();
    this.kwdCategories = _kwdCategories;
    this.reportCategories = _reportCategories;
    this.dom = {
      body: _pageBody,
      kwdBlacklist: _pageBody.find('#prf_kwdBlacklist'),
      kwdAdd: _pageBody.find('#prf_kwdAdd'),
      badWordTagger: _pageBody.find('#prf_badWordsTagger'),
      replaceThis: _pageBody.find('#prf_replaceThis'),
      replaceWith: _pageBody.find('#prf_replaceWith'),
    };

    cSAMA_GeneralInputs.LinkInputs(SAMA.settings.preferences, this.dom.body, this);

    // region Banned words tagger
    // -----------------------------------------------------------------------------------------------------------------
    // Add all tags in the source as a preset
    let presetVal = '';

    for (let t = 0; t < SAMA.settings.preferences.sync.badWordTags.tags.length; t++) {
      presetVal += SAMA.settings.preferences.sync.badWordTags.tags[t].name + ',';
    }

    this.dom.badWordTagger.val(presetVal);
    this.dom.badWordTagger
      .catTagger(SAMA.settings.preferences.sync.badWordTags, {
        use: 'tags',
        permissions: {
          allowNewTag: true,
        },
        remote: {
          url: '',
        },
        inputField: {
          maxTags: 1000,
        },
        messages: {
          tag: 'Word',
        },
      })
      .on('tagAdded', () => this.UpdatePrefsWithBadWords())
      .on('tagRemoved', () => this.UpdatePrefsWithBadWords());
    // endregion

    // region Keyword Add
    // -----------------------------------------------------------------------------------------------------------------
    this.dom.kwdAdd
      .catTagger(null, {
        showAllButton: true,
        acceptAsterisk: true,
        permissions: {
          allowNewTag: true,
          blacklistedOnly: true,
        },
        inputField: {
          maxTags: 500,
        },
        remote: {
          data: {
            topic: 'Keywords',
            action: 'getData',
            getBlacklisted: true,
          },
          timeBetweenSync: 5000,
        },
        messages: {
          addingNotAllowed: 'You cannot add new categories here. Please use the editor below.',
          bannedWordUsed: 'These words are on the banned word list above: "%{wordList}". Please remove them there.',
        },
      })
      .on('tagAdded', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      });
    // endregion

    // region Keyword Blacklist
    //-----------------------------------------------------------------------------------------------------------------
    this.dom.kwdBlacklist
      .catTagger(null, {
        showAllButton: true,
        acceptAsterisk: true,
        permissions: {
          allowBlacklisted: false,
        },
        inputField: {
          maxTags: 500,
        },
        remote: {
          data: {
            topic: 'Keywords',
            action: 'getData',
          },
          timeBetweenSync: 5000,
        },
      })
      .on('tagAdded', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      });
    // endregion

    this.InstallKeywordReplaceInputs();
  }

  InstallKeywordReplaceInputs() {
    this.dom.replaceThis
      .catTagger(null, {
        showAllButton: true,
        permissions: {
          allowBlacklisted: false,
        },
        inputField: {
          maxTags: 100,
        },
        remote: {
          data: {
            topic: 'Keywords',
            action: 'getData',
          },
          timeBetweenSync: 5000,
        },
      })
      .on('tagAdded', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      });

    this.dom.replaceWith
      .catTagger(null, {
        showAllButton: true,
        permissions: {
          allowBlacklisted: false,
        },
        inputField: {
          maxTags: 1,
        },
        remote: {
          data: {
            topic: 'Keywords',
            action: 'getData',
          },
          timeBetweenSync: 5000,
        },
      })
      .on('tagAdded', () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
      });

    this.dom.replaceThis.catTagger('setBuddies', [this.dom.replaceWith]);
    this.dom.replaceWith.catTagger('setBuddies', [this.dom.replaceThis]);
  }

  GetBlacklistedTags() {
    return this.dom.kwdBlacklist.catTagger('getSelectedTags')[0];
  }

  GetAddedTags() {
    return this.dom.kwdAdd.catTagger('getSelectedTags')[0];
  }

  GetReplaceTags() {
    return {
      replaceTags: this.dom.replaceThis.catTagger('getSelectedTags')[0],
      replace: this.dom.replaceThis.val(),
      with: this.dom.replaceWith.val(),
    };
  }

  /**
   *
   */
  UpdatePrefsWithBadWords() {
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
    SAMA.settings.preferences.SetModified(true);
    let sel = this.dom.badWordTagger.catTagger('getSelectedTags')[0];

    let newVal = [];
    for (let s = 0; s < sel.length; s++) {
      newVal.push(sel[s].tagName);
    }

    SAMA.settings.preferences.sync.prf_badWords = newVal.join(',');
  }

  /**
   *
   * @param {vSAMA_TabKwdCategory} _delTab
   */
  ShowDeleteDropdown(_delTab) {
    let delCat = _delTab.model;

    let message =
      '<p>Reporters will no longer be able to use this category but it will still show up in existing reports. </p>' +
      '<p>Tags in this category can be moved to another one or blacklisted below.</p>' +
      '<p>Are you sure you want to archive the category now? If yes, what should happen to its keywords?</p>';

    let selObj = [
      {
        value: '-1',
        name: 'Blacklist',
      },
    ];

    // Build the select
    let delCatPk = delCat.GetPk();
    for (let kwd = 0; kwd < this.kwdCategories.length; kwd++) {
      let _cat = this.kwdCategories[kwd];

      if (_cat.IsDeleted() || _cat.GetPk() === delCatPk) {
        // Don't suggest deleted categories
        continue;
      }

      selObj.push({
        value: _cat.GetPk(),
        name: 'Move to ' + _cat.sync.kwc_name,
      });
    }

    // Show the select
    cSAMA_Dialog.ShowDropdown({
      title: 'Really Delete?',
      message: message,
      options: selObj,
      onAccept: (_val) => {
        _val = +_val;

        // User clicked 'accept'
        this.ClearTaggers(false);

        // Delete the old category
        _delTab.ConfirmDelete();
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        SAMA.settings.preferences.Validate(this.dom.body);

        if (_val === -1) {
          // User chose 'blacklist'
          return;
        }

        // User chose to move the Keywords
        let moveToCat = this.kwdCategories.filter((_c) => _c.GetPk() === _val);

        if (moveToCat.length !== 1) {
          throw (
            'Fatal: Category that should exist no longer does or random Category has sprung up: ' + moveToCat.length
          );
        }

        moveToCat[0].TakeKwdsFrom(delCat.GetPk());

        this.UpdateObservers('DisableDelete', moveToCat[0].GetPk());
      },
    });
  }


  ShowDelRepCategoryConf(_delTab){
    // Show the select
    cSAMA_Dialog.ShowConfirm({
      title: 'Delete Category',
      message:
          'Reporters will no longer be able to assign this category but it will still show up in existing reports.  <br>' +
          'Do you want to delete it now?',
      yesFunc: () => {
        _delTab.ConfirmDelete();
      },
    })
  }
  OnCategoryEdited() {
    this.ClearTaggers(false);
  }

  ClearTaggers(_enable) {
    _enable = _enable === true;

    this.dom.kwdAdd.catTagger('flushCache');
    this.dom.kwdBlacklist.catTagger('flushCache');
    this.dom.replaceThis.catTagger('flushCache');
    this.dom.replaceWith.catTagger('flushCache');

    this.dom.kwdAdd.catTagger('enable', _enable);
    this.dom.kwdBlacklist.catTagger('enable', _enable);
    this.dom.replaceThis.catTagger('enable', _enable);
    this.dom.replaceWith.catTagger('enable', _enable);
  }

  GetDom() {
    return this.dom.body;
  }

  Destroy() {
    super.Destroy();
    this.dom.badWordTagger.catTagger('destroy');
    this.dom.kwdBlacklist.catTagger('destroy');
    this.dom.kwdAdd.catTagger('destroy');
    this.dom.replaceThis.catTagger('destroy');
    this.dom.replaceWith.catTagger('destroy');
  }
}
