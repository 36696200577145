class mSAMA_Report extends mSAMA_AbstractModel {
  constructor(_databaseData, _riskMatrixVersion, _preferences) {
    super('rep_primaryKey');

    this.initialDepartment = -1;
    this.consequences = [];
    this.downloaded = _databaseData != null && _databaseData.rep_summary != null;

    _riskMatrixVersion = _riskMatrixVersion == null ? -1 : _riskMatrixVersion;

    this.sync = {
      rep_primaryKey: -1,
      rep_groupReportId: '',
      rep_isProactive: false,
      rep_reporter: '',
      rep_isAnonymous: false,
      rep_mtxVersion: _riskMatrixVersion,
      rep_summary: '',
      rep_date: null,
      rep_time: null,
      rep_dateTime: null,
      rep_itinPk: 0,
      rep_itinLegPk: 0,
      rep_tarmac: '',
      rep_problemDesc: '',
      rep_suggestions: '',
      rep_keywordPks: '', // Comma separated values from inputs
      rep_assignedStaff: '', // Comma separated values from inputs
      rep_depPk: 0,
      rep_catPk: 0,
      rep_approvedDate: null,
      rep_submittedDate: null,
      rep_archivedDate: null,
      rep_riskScoreInitial: 0.0,
      rep_riskScoreCurrent: null,
      rep_photos: [],
      rep_files: [],
      rep_itinsFromWB: 0,
      rep_restrictToAdmins: 0,
      depChange: false,
      modified: false,
      deleted: false,
      finished: false,
    };

    let badWords = _preferences == null ? '' : _preferences.GetBadWords();
    let requireCategory = SAMA.settings?.preferences?.sync?.prf_requireCategorySelect === false ? 0 : 1;
    this.validateSettings = {
      rep_tarmac: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 5,
        maxLength: SAMA.enums.TEXTLENGTHS.Medium,
        mustNotContain: badWords,
      }),
      rep_summary: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 5,
        maxLength: SAMA.enums.TEXTLENGTHS.Small,
        mustNotContain: badWords,
      }),
      rep_problemDesc: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 25,
        maxLength: SAMA.enums.TEXTLENGTHS.Massive,
        mustNotContain: badWords,
      }),
      rep_date: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.DATE,
        future: false,
        required: true,
      }),
      rep_catPk: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.CATEGORY,
        required: requireCategory,
      })
    };

    $.extend(true, this.sync, _databaseData);

    if (this.sync.rep_isProactive !== 1 && this.sync.rep_isProactive !== true) {
      this.sync.rep_isProactive = false;

      // The report is reactive, so we require the time.
      this.validateSettings.rep_time = new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.TIME,
        required: true,
      });
    } else {
      this.sync.rep_isProactive = true;
    }

    if (this.sync.rep_dateTime != null) {
      // Got an utc datetime from server -> transform to local date
      this.sync.rep_date = cSAMA_Utils.GetInputDateFromSQLDate(this.sync.rep_dateTime);
    } else {
      this.sync.rep_date = moment().format(SAMA.defaults.DateInputFormat);
    }

    if (this.sync.rep_dateTime != null) {
      // Got an utc datetime from server -> transform to local time
      this.sync.rep_time = cSAMA_Utils.GetInputTimeFromSQLTime(this.sync.rep_dateTime);
    } else {
      this.sync.rep_time = moment().format(SAMA.defaults.TimeInputFormat);
    }

    if (this.sync.rep_reporter == null) {
      this.sync.rep_isAnonymous = true;
    }

    if (this.sync.rep_submittedDate == null) {
      this.sync.finished = false;
    } else {
      this.validateSettings['rep_groupReportId'] = new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 1,
        maxLength: 64,
      });
    }

    this.initialDepartment = this.sync.rep_depPk == null ? 0 : +this.sync.rep_depPk;
  }

  get isDraft() {
    return this.sync.rep_submittedDate == null;
  }

  /**
   * Returns the initial department the report was downloaded with
   * @returns {number}
   */
  GetInitialDepartment() {
    return this.initialDepartment;
  }

  /**
   * Indicates if the report was created locally or downloaded
   * @return {boolean}
   */
  WasDownloaded() {
    return this.downloaded;
  }

  /**
   * Indicates if the report is proactive
   * @return {boolean}
   */
  IsProactive() {
    return this.sync.rep_isProactive;
  }

  IsApproved() {
    return this.sync.rep_approvedDate != null;
  }

  /**
   * Gets consequences associated with the report
   * @returns {mSAMA_Consequence[]}
   */
  GetConsequences() {
    // UNFORSEEN CONSEQUENCES? Time to grab a crowbar. RIP Episode 3 (2002-2017)
    return this.consequences;
  }

  /**
   * @param {mSAMA_Consequence} _consequence
   */
  AddConsequence(_consequence) {
    this.consequences.push(_consequence);
  }

  GetPhotos() {
    return this.sync.rep_photos;
  }

  /**
   * Accumulate and update the overall risk score of the report
   * @return {mSAMA_Consequence}
   */
  RecalculateRiskScore() {
    let maxRiskScore = 0;
    let maxRiskConsequence = null;

    for (let c = 0; c < this.consequences.length; c++) {
      let _cons = this.consequences[c];

      if (_cons.IsDeleted()) {
        continue;
      }

      let rscore = Math.ceil(_cons.GetRiskScore());
      if (rscore > maxRiskScore) {
        maxRiskScore = rscore;
        maxRiskConsequence = _cons;
      }
    }

    if (maxRiskConsequence != null) {
      this.sync.rep_riskScoreCurrent = maxRiskConsequence.GetRiskScore();
    } else {
      this.sync.rep_riskScoreCurrent = 0;
    }

    return maxRiskConsequence;
  }

  /**
   * @return {number}
   */
  GetRiskScore() {
    return this.sync.rep_riskScoreCurrent;
  }

  GetNumActiveConsequences() {
    let numActiveConseqs = 0;
    for (let c = 0; c < this.consequences.length; c++) {
      let con = this.consequences[c];

      if (!con.IsDeleted()) {
        numActiveConseqs++;
      }
    }

    return numActiveConseqs;
  }

  /**
   * Prepares the contents of the report for sending
   * @return {object|boolean}
   * @override
   */
  GetTransmissible() {
    let submission = {
      report: {
        rep_trueDateTime: cSAMA_Utils.CombineDateTime(this.sync.rep_date, this.sync.rep_time),
      },
      consequences: [],
    };

    $.extend(true, submission.report, this.sync);

    for (let c = 0; c < this.consequences.length; c++) {
      let con = this.consequences[c];
      if (con.IsModified() && !(con.IsDeleted() && con.IsNew())) {
        submission.consequences.push(con.GetTransmissible());
      }
    }

    // Don't resubmit these photos.
    submission.report.rep_photos = [];

    return submission;
  }

  /**
   * Generates a report from its database data as well as the correct risk matrix
   * @param {object} _reportData
   * @param {mSAMA_RiskMatrix} _riskMatrix
   * @return mSAMA_Report | boolean
   */
  static ReportFromRemoteData(_reportData, _riskMatrix) {
    if (!_reportData.hasOwnProperty('report') || _reportData.report == null) {
      return false;
    }

    let report = new mSAMA_Report(_reportData.report, _riskMatrix.sync.mtx_version, SAMA.settings.preferences);

    for (let co = 0; co < _reportData.consequences.length; co++) {
      let conData = _reportData.consequences[co];
      let cons = new mSAMA_Consequence(report, _riskMatrix, conData.consequence);
      report.AddConsequence(cons);

      if (conData.correctiveAction != null) {
        let corrAct = new mSAMA_CorrectiveAction(conData.correctiveAction, cons);
        cons.SetCorrectiveAction(corrAct);
      }

      let riskFactors = _riskMatrix.GetRiskFactors();
      for (let rf = 0; rf < riskFactors.length; rf++) {
        // It's not a given that there are values, so we'll filter the find the values that
        // are based on the current factor
        let riskFactor = riskFactors[rf];
        let rfPk = riskFactor.GetPk();
        let riskFactorValue = conData.riskFactors.filter((_rfv) => {
          return +_rfv.riskFactorValues.mrfv_rfPk === rfPk;
        });

        let rFactVal = mSAMA_Report.CreateRiskFactorValue(riskFactorValue, riskFactor, cons);

        let customFields = riskFactor.GetCustomFields();

        for (let cf = 0; cf < customFields.length; cf++) {
          let cfv = mSAMA_Report.CreateCustomFieldValue(customFields[cf], riskFactorValue, rFactVal, _reportData);
          rFactVal.AddCustomFieldValue(cfv);
        }

        cons.AddRiskFactor(riskFactor, rFactVal);
      }
    }
    return report;
  }

  static CreateCustomFieldValue(_custField, _riskFactorValues, _rFactVal, _reportData) {
    // It's not a given that there are values, so we'll filter the find the values that
    // are based on the current custom field
    let cfPk = _custField.GetPk();

    let customFieldValues = [];
    if (_riskFactorValues.length > 0) {
      customFieldValues = _riskFactorValues[0].customFieldValues.filter((_cfv) => {
        return +_cfv.mcfv_mcfPk === cfPk;
      });
    }

    let cfv;
    if (customFieldValues.length === 1) {
      // There are values associated with this customField
      cfv = new mSAMA_CustomFieldValue(_rFactVal, _custField, customFieldValues[0]);
      let cfvPk = customFieldValues[0].mcfv_primaryKey;

      if (_reportData.hasOwnProperty('resolvedNames') && _reportData.resolvedNames != null) {
        if (_reportData.resolvedNames.hasOwnProperty(cfvPk)) {
          cfv.SetValueNames(_reportData.resolvedNames[cfvPk]);
        }
      }
    } else {
      // There are no values associated with this customField
      cfv = new mSAMA_CustomFieldValue(_rFactVal, _custField);
    }

    return cfv;
  }

  static CreateRiskFactorValue(riskFactorValue, _riskFactor, _cons) {
    if (riskFactorValue.length === 1) {
      // There are values associated with this factor
      return new mSAMA_RiskFactorValue(_riskFactor, _cons, riskFactorValue[0].riskFactorValues);
    }

    // There are no values associated with this factor
    return new mSAMA_RiskFactorValue(_riskFactor, _cons);
  }
}
