/**
 * Deals with the ubiqitous page header
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @param {string} _title        The page's title
 * @param {jQuery} _selector     The page's selector
 * @param {string} [_icon]       The page's icon
 * @param {string} [_moduleName] The page's parent module name
 * @class
 */

class cSAMA_PageHeader {
  constructor(_title, _selector, _icon, _moduleName) {
    _moduleName = _moduleName == null ? 'Safety Reports' : _moduleName;
    _icon = _icon == null ? 'risk' : _icon;

    this.buttons = {};

    this.dom = {};

    // Insert the menu bar
    let placeholder = _selector.find('[data-sama-template="header-placeholder"]');
    let iconStr = '<img src="./images/%.svg" />'.tr(_icon);

    this.dom.bar = SAMA.Templates.tpl.general.header.formatTemplate({
      moduleName: _moduleName,
      pageTitle: _title,
      icon: iconStr,
    });

    this.dom.bar = $(this.dom.bar);
    this.dom.buttonContainer = this.dom.bar.find('.sama-headerButtons');

    $(placeholder).prepend(this.dom.bar);
    this.Reset();
  }

  SetTitle(_newTitle) {
    this.dom.bar.find('h2 > span').text(_newTitle);
  }

  /**
   * Adds a button to the header and returns the identifier used to target that button
   * @param {eSAMA_Button}   _button
   * @return {int}
   */
  AddButton(_button) {
    if (this.buttons.hasOwnProperty(_button.label)) {
      throw 'This button already exists in the header ' + _button.label;
    }

    this.buttons[_button.label] = _button;

    this.dom.buttonContainer.prepend(_button.GetDom()).enhanceWithin();

    return _button.id;
  }

  /**
   * Shows / Hides this.buttons depending on the state of the page
   * @param _btnState
   */
  UpdateButtonState(_btnState) {
    for (let b in this.buttons) {
      if (this.buttons.hasOwnProperty(b)) {
        let btn = this.buttons[b];
        if (btn.showStates.includes(_btnState)) {
          btn.Show();
          continue;
        }
        btn.Hide();
      }
    }
  }

  // Clear all buttons, reinstate the back-button
  Reset() {
    for (let b in this.buttons) {
      if (this.buttons.hasOwnProperty(b)) {
        this.buttons[b].Destroy();
        delete this.buttons[b];
      }
    }

    let btnBk = new eSAMA_Button({
      label: 'Back',
      icon: 'fa-chevron-left',
      showStates: [cSAMA_GeneralGui.StateReady, cSAMA_GeneralGui.StateBlocked],
    });

    btnBk.GetDom().on('click', () => SAMA.Navigator.GoBack());

    this.AddButton(btnBk);
  }

  /**
   * Adds the Save/Discard this.buttons to the header
   */
  ButtonSaveDiscard() {
    let btnDisc = new eSAMA_Button({
      label: 'Discard',
      icon: 'fa-times',
      showStates: [cSAMA_GeneralGui.StateDirty, cSAMA_GeneralGui.StateDirtyBlocked],
    });

    let btnSave = new eSAMA_Button({
      label: 'Save',
      icon: 'fa-save',
      showStates: [cSAMA_GeneralGui.StateDirty],
    });

    btnDisc.GetDom().on('click', () => SAMA.Navigator.DiscardChanges());
    btnSave.GetDom().on('click', () => SAMA.Navigator.SaveChanges());

    this.AddButton(btnDisc);
    this.AddButton(btnSave);
  }
}
