class DashBoard {
  constructor() {
    this.dom = {};

    this.dom.bell = $('#dashBell');
    this.dom.bellNum = this.dom.bell.find('.notificationBlob');
    this.dom.homeSettings = $('#homeSettings');

    this.initialized = false;
    this.timer = null;

    // Needs SAMA functions
    this.dom.bell.on('click', () => this.GoToDash(true));
    this.dom.homeSettings.on('click', () => this.GoToHomeSetting(true));

    window.addEventListener('cirro-dismissed-notification', (e) => this.updateBellIcon(e.detail.count), false);

    $('#todolist').on('click', () =>
      VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/tasks/?from-legacy=MainMenu`)
    );
  }

  Reset() {
    if (this.timer != null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.StartPolling();
  }

  StartPolling() {
    const timeout = LOW_BANDWIDTH ? 240 : 120;
    this.timer = setTimeout(() => this.PollServer(), timeout * 1000);
  }

  async sendTimezone() {
    let req = new cSAMA_Request('Settings', 'deviceTimezone');
    req.Set('time-zone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    cSAMA_Request.Dispatch({
      name: 'TimezoneBeacon',
      clientErrorCode: 'DASH OH HI MA RC',
      reqs: req,
      keepStateOnError: true,
      showLoading: false,
      goBackOnError: false,
      showErrors: false,
    });
  }

  async PollServer() {
    if (!window.ONLINE || !window.vueApp.authTools.authUserHasSession()) {
      this.Reset();
      return;
    }

    if (LOCALSTORAGE.POLL_TIMEZONE == null) {
      this.sendTimezone();
    }

    try {
      let newsCount = await RemoteData.get(
        `api/v2/groups/${GROUP_DATA.PrimaryKey}/dashboard/users/${USER_PK}/notifications/count`
      );

      newsCount = newsCount > 99 ? '99+' : newsCount;

      this.updateBellIcon(newsCount);
    } catch (e) {
      this.dom.bell.hide();
    }

    this.Reset();
  }

  updateBellIcon(newsCount) {
    this.dom.bell.show();
    if (newsCount <= 0) {
      this.dom.bellNum.text('-').css('opacity', '0');
      this.dom.bell.css('color', '');
      return;
    }

    this.dom.bellNum.text(newsCount).css('opacity', '1');
    this.dom.bell.css('color', '#f05a40');
  }

  GoToDash() {
    VueBridge.enterDashboard();
  }

  GoToHomeSetting(_reverse) {
    _reverse = _reverse == null ? false : _reverse;

    $.mobile.changePage($('#Home-Settings-Page'), {
      reverse: _reverse,
      changeHash: false,
    });
  }
}
