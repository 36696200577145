/**
 * Helper class for forms integration in itineraries
 */
class FormsInItineraryCreator extends FormBridge {
  constructor(sourcePage, editLevel, htmlContainerId, forms, itineraryId) {
    super(sourcePage, editLevel, htmlContainerId, forms, itineraryId, FormsInItineraryCreator.LINK_TYPE);

    window.removeEventListener('forms-floating-attach', (e) => this.addFloating(e));
    window.addEventListener('forms-floating-attach', (e) => this.addFloating(e));

    this.picker.AddObserver(null, 'linkFilled', () => this.linkFilledForm());
    this.picker.AddObserver(null, 'linkNew', () => this.linkNewForm());
    this.picker.AddObserver(null, 'viewItem', (formId) => this.viewForm(formId));
    this.picker.AddObserver(null, 'editItem', (formId) => this.editForm(formId));
    this.picker.AddObserver(null, 'unlinkItem', (formId) => this.confirmUnlink(() => this.unlinkForm(formId)));
  }

  static instance() {
    return super.instance(
      FormsInItineraryCreator.PAGE,
      '#itineraryFormPickerHolder',
      [],
      -1,
      FormsInItineraryCreator.EL_ADMIN
    );
  }

  /**
   *
   */
  static handleReturnToCreatePage() {
    verbose.log('Itin', 'Back to itin detail page');
    goToPage('#ITIN-INPUT');
    this.returnToPreSwitchPosition();
  }

  /**
   * We don't know the ID of the itin yet, so we need to add the form as 'floating'.
   * @param formLinkEvent
   */
  addFloating(formLinkEvent) {
    const link = formLinkEvent.detail;
    verbose.log('Forms', 'Added floating form', link);
    this.picker.addForm(link.form_data_id, link.title, link.state, link.locked_at);
  }

  /**
   * Hard-links floating forms once we have a DB pk for the itin
   * @param itinId
   * @return {Promise<void>}
   */
  async handleSave(itinId) {
    const loadedForms = this.picker.getLoadedFormIds();

    verbose.log('Forms', 'Saving Floating Forms:', loadedForms);

    loadedForms.forEach(async (formDataId) => {
      try {
        await RemoteData.post(
          `api/v2/groups/${window.vueApp.groupKey}/forms/filled/${formDataId}/links/${this.linkTargetType}/${itinId}`
        );
        verbose.log('Forms', 'Saved Floating Form ', formDataId);
      } catch (e) {
        verbose.error('Forms', e);
        VueBridge.showDialog(
          'Failed',
          'There was a problem linking one or more attached form(s). Please try again. ' +
            'If this continues to happen, please notify support.'
        );
      }
    });
  }
}

FormsInItineraryCreator.LINK_TYPE = 'itineraries';
FormsInItineraryCreator.PAGE = 'itineraryCreationPage';
