class mSAMA_AbstractModel {
  /**
   *
   * @param {string} _pkKey
   */
  constructor(_pkKey) {
    this.sync = {
      modified: false,
      deleted: false,
    };

    this.validateSettings = {};
    this.pkKey = _pkKey;
  }

  /**
   * True if the object has not been synched with the server yet
   * @return {boolean}
   */
  IsNew() {
    let pk = this.GetPk();
    return pk == null || pk < 0;
  }

  /**
   * Gets the primary key from within sync
   * @return {number}
   */
  GetPk() {
    return this.sync[this.pkKey];
  }

  /**
   * Gets the primary key from within sync
   * @param {number} _pk
   */
  SetPk(_pk) {
    this.sync[this.pkKey] = _pk;
  }

  /**
   * Changes the changed state
   * @param {boolean} _state
   */
  SetModified(_state) {
    this.sync.modified = _state;
  }

  /**
   * Gets the changed state
   * @returns {boolean}
   */
  IsModified() {
    return this.sync.modified;
  }

  /**
   * Whether this object was deleted since the last server sync
   * @returns {boolean}
   */
  SetDeleted(_state) {
    this.sync.deleted = _state;
    this.SetModified(_state);
    if (_state) {
      this.EndValidation();
    }
  }

  /**
   * Gets the deletion state
   * @returns {boolean}
   */
  IsDeleted() {
    return this.sync.deleted;
  }

  /**
   * Returns the object as a transmittable object
   * @return {null|object}
   */
  GetTransmissible() {
    if (!this.IsModified() || (this.IsNew() && this.IsDeleted())) {
      return null;
    }

    return this.sync;
  }

  /**
   * Returns the object as a transmittable object, ignoring the modified flag
   * @return {null|object}
   */
  GetFullTransmissible() {
    if (this.IsNew() && this.IsDeleted()) {
      return null;
    }

    return this.sync;
  }

  /**
   * Installs validation event hooks in sref'd inputs in _scope
   * @param {jQuery} _scope Scope to look for inputs in
   */
  EnableValidation(_scope) {
    if (this.validationScope == null || this.validationScope === _scope) {
      this.validationScope = _scope;
    } else {
      this.validationScope.add(_scope);
    }
    for (let _validator in this.validateSettings) {
      if (!this.validateSettings.hasOwnProperty(_validator)) {
        continue;
      }

      let input = _scope.find('[data-sref="%"]'.tr(_validator));
      input.on('validate.samaevt', () => {
        // Validate input
        this.validateSettings[_validator].Validate(this.sync[_validator], input, _validator);
      });
    }
  }

  EndValidation() {
    if (this.validationScope == null) {
      return;
    }

    for (let validator in this.validateSettings) {
      if (!this.validateSettings.hasOwnProperty(validator)) {
        continue;
      }

      this.StopValidation(validator);
    }
  }

  StopValidation(_validator) {
    let input = this.validationScope.find('[data-sref="%"]'.tr(_validator));
    this.validateSettings[_validator].Unvalidate(input, _validator);
  }

  /**
   * Checks if the object has valid form inputs
   * @param {jQuery} _scope Scope to look for inputs in
   * @return {boolean}
   */
  Validate(_scope) {
    for (let _v in this.validateSettings) {
      if (!this.validateSettings.hasOwnProperty(_v)) {
        continue;
      }

      _scope.find('[data-sref="%"]'.tr(_v)).trigger('validate.samaevt');
    }
  }
}
