/********************************* Invoicing *********************************************************************************/
var BILLING_INVOICE_DATA = {};
/*  Variable will store data in this structure
 BILLING_INVOICE_DATA = {
 JobList:null,
 Client:null,
 Job:null,
 Expenses:[];
 FlightReports:[{
 Report:null,
 ReportCfields:[],
 Legs:[],
 LegCfields:[],
 Adjustment:null,
 AdjDesc:null
 }],
 Tax:null,
 TaxDesc:null,
 CustomerPO:null,
 AgreementNum:null,
 Comments,null
 };
 */
var BILLING_HEADER_DATA = {};
function BILLING_returnAdminInvoicing() {
  DeleteNewInvoiceNumber();
  $.mobile.changePage($('#BILLING_INVOICINGPage'), {
    changeHash: false,
    reverse: true,
  });
}
var BILLING_INVOICES = [];

function BILLING_AdminInvoicing(e) {
  signatureDATA = {
    data: '',
    text: '',
  };
  var back = e || false;
  $.mobile.changePage($('#BILLING_INVOICINGPage'), {
    changeHash: false,
    reverse: back,
  });
  //display list of invoices in two states: Pending and Paid
  $('#BILLING_INVOICES').html('<h2>' + iTrans('Retrieving Invoices') + '</h2>');
  //display list of jobs whether they contain billable items or not
  //Get jobs to bill;
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getInvoices', function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Get Invoices') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminInvoicing');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        BILLING_INVOICES = json_data[0];
        BILLING_COMPANY_ADJUSTMENTS = json_data[0]['AdjItems'];
        BILLING_ShowInvoices();
      }
    } else {
      $('#BILLING_INVOICES').html('<h2>' + iTrans('No Invoices Available to View') + '</h2>');
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
  // BILLING_INVOICES //div to place invoices
}
function BILLING_FilterInvoiceSearch() {
  $('#BILLING_INVOICES').html('<h2>' + iTrans('Retrieving Invoices') + '</h2>');
  //display list of jobs whether they contain billable items or not
  //Get jobs to bill;
  var text = $('#BILLING_INVOICE_search').val();
  $.getJSON(
    BASEURL + 'menu-Billing-AJAX.php?action=getInvoices&search=' + encodeURIComponent(text),
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Invoices') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminInvoicing');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          BILLING_INVOICES = json_data[0];
          BILLING_COMPANY_ADJUSTMENTS = json_data[0]['AdjItems'];
          BILLING_ShowInvoices();
        }
      } else {
        $('#BILLING_INVOICES').html('<h2>' + iTrans('No Invoices Available to View') + '</h2>');
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}
function BILLING_ShowInvoices() {
  //TODO Add PDF button to actions to compile and download PDF of invoice
  //console.log(BILLING_INVOICES);
  signatureDATA = {
    data: '',
    text: '',
  };
  var Pending = BILLING_INVOICES.Pending;
  var Paid = BILLING_INVOICES.PaidInvoice;
  if ($('#BILLING_InvoicePending').is(':checked')) {
    var html = '<h3>' + iTrans('Pending Payment') + '</h3>';
    if (Pending.length > 200)
      html = '<h3>' + iTrans('Pending Payment') + ': ' + iTrans('Showing Most Recent 200 Results') + '</h3>';
    if (Pending.length > 0) {
      html +=
        '<table  id="BIT_Invoice1" data-filter="true" class="limit-table first-td-fill"><thead><tr class="hideable"><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Invoice') +
        '#</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var A in Pending) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_PendingInvoiceDelete(" +
          A +
          ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button><button onClick='BILLING_PendingInvoicePaid(" +
          A +
          ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button><button onClick='BILLING_ViewPendingInvoice(" +
          A +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
        html +=
          '<tr class="hideable"><td>' +
          Pending[A].Client +
          '</td><td>' +
          Pending[A].InvoiceNum +
          '</td><td>' +
          Pending[A].Date +
          '</td><td>' +
          Pending[A].Amount.toLocaleString('en-US', CUR) +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Pending Invoices') + '</p>';
  } else {
    var html = '<h3>' + iTrans('Paid Invoices') + '</h3>';
    if (Paid.length > 200)
      html = '<h3>' + iTrans('Paid Invoices') + ': ' + iTrans('Showing Most Recent 200 Results') + '</h3>';
    if (Paid.length > 0) {
      html +=
        '<table  id="BIT_Invoice1" data-filter="true" class="limit-table first-td-fill"><thead><tr class="hideable"><th>' +
        iTrans('Client') +
        '</th><th>' +
        iTrans('Invoice') +
        '#</th><th>' +
        iTrans('Date') +
        '</th><th>' +
        iTrans('Amount') +
        '</th><th>' +
        iTrans('Action') +
        '</th></tr></thead>';
      for (var U in Paid) {
        var actions =
          "<div class='vertical-center-container'><button onClick='BILLING_UnPayInvoice(" +
          U +
          ")' data-mini='true' class='action-btn'><img src='./images/restore.png'  width='25' height='25'/></button><button onClick='BILLING_ViewPaidInvoiceData(" +
          U +
          ")' data-mini='true' class='action-btn'><img src='./images/inspect.svg' /></button></div>";
        if (Paid[U].Client.indexOf('Update Invoice Numbering') !== -1) actions = '';
        html +=
          '<tr class="hideable"><td>' +
          Paid[U].Client +
          '</td><td>' +
          Paid[U].InvoiceNum +
          '</td><td>' +
          Paid[U].Date +
          '</td><td>' +
          Paid[U].Amount.toLocaleString('en-US', CUR) +
          '</td><td>' +
          actions +
          '</td></tr>';
      }
      html += '</table>';
    } else html += '<p>' + iTrans('No Paid Invoices') + '</p>';
  }
  $('#BILLING_INVOICES').html(html);
  $('#BILLING_INVOICES').enhanceWithin();
  AddTableSorter('BIT_Invoice1');
}

function BILLING_PendingInvoiceDelete(index) {
  //TODO show confirmation dialog and Mark pending invoice as paid
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans(
      'All flight reports and expenses associated with this invoice will be marked as unbilled.  Are you sure you want to <b>DELETE</b> this invoice?'
    ),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          //Mark Invoice as Paid
          $.getJSON(
            BASEURL +
              'menu-Billing-AJAX.php?action=deleteInvoice&PrimaryKey=' +
              BILLING_INVOICES.Pending[index].PrimaryKey,
            function (json_data) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Offline') {
                alert('<h1>' + iTrans('Offline Cannot Delete Invoice') + '</h1>'); //retrieve old data from database
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'BILLING_Delete invoice');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Success') {
                BILLING_AdminInvoicing(); //reload invoice data
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function BILLING_UnPayInvoice(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Mark Unpaid'),
    headerClose: false,
    buttonPrompt: iTrans(
      'This invoice and all associated details will be restored from archive. Are you sure you want to mark this invoice as unpaid?'
    ),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          //Mark Invoice as Paid
          $.getJSON(
            BASEURL +
              'menu-Billing-AJAX.php?action=markInvoiceUnPaid&PrimaryKey=' +
              BILLING_INVOICES.PaidInvoice[index].PrimaryKey,
            function (json_data) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Offline') {
                alert('<h1>Offline Cannot Update Invoice</h1>'); //retrieve old data from database
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'BILLING_PendingInvoicePaid');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Success') {
                BILLING_AdminInvoicing(); //reload invoice data
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function BILLING_PendingInvoicePaid(index) {
  //TODO show confirmation dialog and Mark pending invoice as paid
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans(
      'This invoice and all associated details will become archived. Are you sure you want to mark this invoice as paid?'
    ),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          //Mark Invoice as Paid
          $.getJSON(
            BASEURL +
              'menu-Billing-AJAX.php?action=markInvoicePaid&PrimaryKey=' +
              BILLING_INVOICES.Pending[index].PrimaryKey,
            function (json_data) {
              //status could be offline/Not Logged In/Uncaught Exception
              if (json_data[0].ServerResponse == 'Offline') {
                alert('<h1>Offline Cannot Update Invoice</h1>'); //retrieve old data from database
              }
              if (json_data[0].ServerResponse == 'Uncaught Exception')
                ServerResponse(json_data[0], 'BILLING_PendingInvoicePaid');
              if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
              if (json_data[0].ServerResponse == 'Success') {
                BILLING_AdminInvoicing(); //reload invoice data
              }
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function BILLING_ViewPendingInvoice(index) {
  signatureDATA = {
    data: '',
    text: '',
  };
  BILLING_REVIEW = false;
  EDIT_INVOICE = true;
  BILLING_GETINVOICE(BILLING_INVOICES.Pending[index].PrimaryKey);
  $('#submitInvoiceBTN').prop('disabled', false);
}

function BILLING_ViewPaidInvoiceData(index) {
  EDIT_INVOICE = false;
  BILLING_REVIEW = true;
  BILLING_GETINVOICE(BILLING_INVOICES.PaidInvoice[index].PrimaryKey);
  $('#submitInvoiceBTN').prop('disabled', true);
}

function BILLING_GETINVOICE(PrimaryKey) {
  signatureDATA = {
    data: '',
    text: '',
  };
  $('#BILLING_INVOICEPREVIEW').html('<h2>' + iTrans('Retrieving Invoice') + '</h2>');
  //Get big INVOICE data;
  if (BILLING_REVIEW) {
    $.mobile.changePage($('#BILLING_INVOICEPREVIEWPage'), {
      changeHash: false,
    });
  }
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getINVOICE&PrimaryKey=' + PrimaryKey, function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Get Invoice Data') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'BILLING_GETINVOICE');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        BILLING_INVOICE_DATA = json_data[0];
        BILLING_INVOICE_DATA.InvoiceNum = json_data[2];
        CURRENCY_RATES = json_data[3];
        PopulateCurrencyRates();
        if (EDIT_INVOICE) {
          BILLING_AdminInvoiceComplete(false);
          BILLING_INVOICE_DATA.needSave = false;
        } else {
          BILLING_PreviewInvoice(json_data[2]);
          BILLING_INVOICE_DATA.needSave = false;
        }
      }
    } else {
      $('#BILLING_INVOICEPREVIEW').html('<h2>' + iTrans('No Invoices Available to View') + '</h2>');
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function BILLING_returnAdminNewInvoice() {
  $.mobile.changePage($('#BILLING_NEWINVOICINGJobPage'), {
    changeHash: false,
    reverse: true,
  });
}

var INVOICE_NUMBER = null;
var CUSTOM_INVOICE_NUMBER = null;
var CURRENCY_RATES = null;
function CreateNewInvoiceNumber() {
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=MakeInvoiceNum', function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans("Couldn't create invoice Number") + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminNewInvoiceNum');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        INVOICE_NUMBER = json_data[0].InvoiceNum;
        CUSTOM_INVOICE_NUMBER = json_data[0].CustInvoiceNum;
        CURRENCY_RATES = json_data[0].CurrencyRates;
        PopulateCurrencyRates();
      }
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function PopulateCurrencyRates() {
  if (GROUP_DATA.CurConversion == 0 || COUNTRY != 'CANADA') {
    return;
  }
  var html =
    '<label for="BILLING_CurrencyRates">' +
    iTrans('Convert to Currency') +
    '</label><select id="BILLING_CurrencyRates" onChange="updateCurConversion(this)">';
  html += '<option value="">' + iTrans('No Conversion') + '</option>';
  if (CURRENCY_RATES != null) {
    for (var i in CURRENCY_RATES) {
      var I = CURRENCY_RATES[i];
      html += '<option value="' + I.label + '">' + I.label + ' ' + I.Rate + '</option>';
    }
  }
  html += '</select>';
  $('#BILLING_CurrencyConverter').html(html);
  if (BILLING_INVOICE_DATA.CurConvert != undefined && BILLING_INVOICE_DATA.CurConvert != '') {
    $('#BILLING_CurrencyRates').val(BILLING_INVOICE_DATA.CurConvert).selectmenu('refresh');
  }
}

function getCurrencyRate(cur) {
  if (cur == 'CAD' || cur == '') return 1;
  var rate = CURRENCY_RATES.filter(function (item) {
    if (
      item.label.toLowerCase().indexOf(cur.toLowerCase()) > -1 ||
      item.label.toLowerCase().indexOf(cur.toLowerCase()) > -1
    ) {
      return true;
    } else {
      return false;
    }
  });
  return rate[0].Rate;
}
function updateCurConversion(e) {
  BILLING_INVOICE_DATA.CurConvert = e.value;
  BILLING_INVOICE_DATA.CurRate = getCurrencyRate(e.value);
  BILLING_AdminInvoiceComplete();
}

function toInvoiceCurrency(amount, overrideDecimals = false) {
  var decimalPlaces = getDecimals(amount);
  if (BILLING_INVOICE_DATA.CurConvert != '' && BILLING_INVOICE_DATA.CurConvert != undefined) {
    var converted = amount / BILLING_INVOICE_DATA.CurRate;
    if (overrideDecimals) {
      return decimalPlaces > 2
        ? '$' + roundNum(converted, decimalPlaces)
        : converted.toLocaleString('en-US', CUR) + ' ' + BILLING_INVOICE_DATA.CurConvert.substr(0, 3);
    }
    return converted.toLocaleString('en-US', CUR) + ' ' + BILLING_INVOICE_DATA.CurConvert.substr(0, 3);
  } else {
    if (overrideDecimals) {
      return decimalPlaces > 2 ? '$' + roundNum(amount, decimalPlaces) : amount.toLocaleString('en-US', CUR);
    }
    return amount.toLocaleString('en-US', CUR);
  }
}

function normalizeExpenseCost(E) {
  if (GROUP_DATA.CurConversion == 1 && E.Currency != 'CAD') {
    var Amount_Cur = E.Amount_B * getCurrencyRate(E.Currency);
    return Amount_Cur;
  } else {
    return E.Amount_B;
  }
}
function DeleteNewInvoiceNumber() {
  if (INVOICE_NUMBER == null) return;
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=DeleteInvoiceNum&PK=' + INVOICE_NUMBER, function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans("Couldn't create invoice Number") + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminDeleteInvoiceNum');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        INVOICE_NUMBER = null;
      }
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function BILLING_Verify_FinishJob(e) {
  if (e.checked) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Warning'),
      headerClose: false,
      buttonPrompt: iTrans(
        'Marking this Job as "Finished" will also hide any unbilled <b>Flight</b> and <b>Expense</b> Reports related to this job.<br />  Are you sure you want to do this?'
      ),
      buttons: {
        [iTrans('Mark As Finished')]: {
          click: function () {},
        },
        [iTrans('Cancel')]: {
          click: function () {
            //do nothing
            $('#' + e.id)
              .prop('checked', false)
              .checkboxradio('refresh');
          },
        },
      },
    });
  }
}

function BILLING_AdminNewInvoice() {
  CreateNewInvoiceNumber();
  BILLING_REVIEW = false;
  EDIT_INVOICE = false;
  $('#submitInvoiceBTN').prop('disabled', false);
  BILLING_INVOICE_DATA = {
    needSave: true,
    CurConvert: '',
    CurRate: 1,
  };
  $.mobile.changePage($('#BILLING_NEWINVOICINGJobPage'), {
    changeHash: false,
  });
  $('#BILLING_INVOICESJOBS').html('<h2>' + iTrans('Retrieving Jobs') + '</h2>');
  //display list of jobs whether they contain billable items or not
  //Get jobs to bill;
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getBillingJobs', function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'Offline') {
          alert('<h1>' + iTrans('Offline Cannot Get Billing Jobs') + '</h1>'); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminNewInvoice');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        BILLING_INVOICE_DATA.JobList = json_data;
        BILLING_ShowInvoiceJobs();
      }
    } else {
      $('#BILLING_INVOICESJOBS').html('<h2>' + iTrans('No Jobs Available To Bill') + '</h2>');
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });

  // BILLING_INVOICESJOBS //div to place jobs to invoice
}

function BILLING_ShowInvoiceJobs() {
  var Jobs = BILLING_INVOICE_DATA.JobList;
  console.log(Jobs);
  var html =
    '<h2>Active Jobs</h3><table  id="BIT_Invoice2" data-filter="true" class="limit-table second-td-fill"><thead><tr class="hideable"><th>' +
    iTrans('Client') +
    ' ID</th><th>' +
    iTrans('Client') +
    '</th><th>' +
    iTrans('Job') +
    ' ID</th><th>' +
    iTrans('Job') +
    '#</th><th>' +
    iTrans('Job') +
    '</th><th>' +
    iTrans('Reports') +
    '</th><th>' +
    iTrans('Expenses') +
    '</th><th>' +
    iTrans('Quotes') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead>';
  for (var J in Jobs) {
    var actions =
      "<div class='vertical-center-container'><button onClick='BILLING_AdminSelectFlightReport(" +
      J +
      ")' data-mini='true' class='action-btn'><img src='./images/Check_mark_green.svg' /></button></div>";
    html +=
      '<tr class="hideable"><td>' +
      Jobs[J].PrimaryKey +
      '</td><td>' +
      Jobs[J].Business +
      '</td><td>' +
      Jobs[J].JobPK +
      '</td><td>' +
      Jobs[J].JobNum +
      '</td><td>' +
      Jobs[J].Name +
      '</td><td>' +
      Jobs[J].ReportCount +
      '</td><td>' +
      Jobs[J].ExpenseCount +
      '</td><td>' +
      Jobs[J].QuoteCount +
      '</td><td>' +
      actions +
      '</td></tr>';
  }
  html += '</table>';
  $('#BILLING_INVOICESJOBS').html(html);
  $('#BILLING_INVOICESJOBS').enhanceWithin();
  AddTableSorter('BIT_Invoice2');
}

function BILLING_returnAdminSelectFlightReport() {
  $.mobile.changePage($('#BILLING_NEWINVOICINGFlightReportsPage'), {
    changeHash: false,
    reverse: true,
  });
}

function BILLING_AdminSelectFlightReport(J) {
  //console.log(J);
  BILLING_INVOICE_DATA.Job = BILLING_INVOICE_DATA.JobList[J];
  BILLING_INVOICE_DATA.Client = BILLING_INVOICE_DATA.JobList[J].Business;

  $.mobile.changePage($('#BILLING_NEWINVOICINGFlightReportsPage'), {
    changeHash: false,
  });
  $('#BILLING_INVOICESFLIGHTREPORTS').html('<h2>' + iTrans('Retrieving Job Data') + '</h2>');
  // display a list of flight reports that are ready to be invoiced (Marked completed but unbilled for client)
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getBillingFlightReports&JobPK=' +
      BILLING_INVOICE_DATA.JobList[J].JobPK +
      '&ClientPK=' +
      BILLING_INVOICE_DATA.JobList[J].ClientPK,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Billing Job Data') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminNewInvoice');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          BILLING_INVOICE_DATA.FlightReports = json_data;
          BILLING_ShowInvoiceFlightReports();
        }
      } else {
        $('#BILLING_INVOICESFLIGHTREPORTS').html('<h2>' + iTrans('No Job Data Try Again Later') + '</h2>');
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
  // BILLING_INVOICESFLIGHTREPORTS //div to place flight report lsits
}

function BILLING_ShowInvoiceFlightReports() {
  var FlightReports = BILLING_INVOICE_DATA.FlightReports;
  //console.log(FlightReports);
  var BillBy = BILLING_INVOICE_DATA.Job.BillBy;
  var BillByOPT = BILLING_INVOICE_DATA.Job.BillByOPT;
  var html = '';
  var Unit = 'Hours';
  switch (BillBy) {
    case 'Hobbs':
      Unit = 'Hobbs Hours';
      break;
    case 'Distance':
      switch (BillByOPT) {
        case 'NM':
          Unit = iTrans('Distance') + ' (NM)';
          break;
        case 'SM':
          Unit = iTrans('Distance') + ' (sm)';
          break;
        case 'KM':
          Unit = iTrans('Distance') + ' (km)';
          break;
        default:
          Unit = iTrans('Distance');
          break;
      }
      break;
    case 'Weight':
      switch (BillByOPT) {
        case 'LB':
          Unit = iTrans('Weight') + ' (lb)';
          break;
        case 'KG':
          Unit = iTrans('Weight') + ' (kg)';
          break;
        default:
          Unit = iTrans('Weight');
          break;
      }
      break;
    case 'Volume':
      switch (BillByOPT) {
        case 'litres':
          Unit = iTrans('Volume') + ' (L)';
          break;
        case 'gallonsus':
          Unit = iTrans('Volume') + ' (US gal)';
          break;
        case 'gallonsimp':
          Unit = iTrans('Volume') + ' (US imp)';
          break;
        default:
          Unit = iTrans('Volume');
          break;
      }
      break;
  }
  html =
    '<table  id="BIT_Invoice3" data-filter="true" class="limit-table last-td-fill"><thead><tr class="hideable"><th><label for="FRCHK"> </label><input type="checkbox" id="FRCHK" checked onClick="SelectDeselectAllFlightReports()" data-mini="true"/></th><th>' +
    iTrans('FR') +
    '#</th><th>' +
    iTrans('Date') +
    '</th><th>' +
    iTrans('Ident') +
    '</th><th>' +
    iTrans('Pilot') +
    '</th><th>' +
    Unit +
    '</th></tr></thead>';
  for (var F in FlightReports) {
    var FR = FlightReports[F];
    var checkbox = '<input type="checkbox" id="FRCHK-' + F + '" checked/>';
    var Hours = 0;
    switch (BillBy) {
      case 'Hour':
      case 'Hour2':
      case 'Minute':
      case 'HourMinute':
        var lastStart = '';
        for (var L in FR.Legs) {
          var Leg = FR.Legs[L];
          if (BILLING_INVOICE_DATA.Job.BlockTime == 1) {
            if (Leg.Time_Stop == '') continue;
            if (Leg.Time_Start != '') {
              lastStart = Leg.Time_Start;
              Hours += getDecimalTime(Leg.Time_Start, Leg.Time_Stop, BillBy);
            } else {
              if (lastStart != '') Hours += getDecimalTime(lastStart, Leg.Time_Stop, BillBy);
            }
          } else {
            if (Leg.Time_Down == '') continue;
            if (Leg.Time_Up != '') {
              lastStart = Leg.Time_Up;
              Hours += getDecimalTime(Leg.Time_Up, Leg.Time_Down, BillBy);
            } else {
              if (lastStart != '') Hours += getDecimalTime(lastStart, Leg.Time_Down, BillBy);
            }
          }
          if (Hours < 0) Hours += 24;
        }
        break;
      case 'HourPR':
        if (FR.BillByVAL == undefined) FR.BillByVAL = 0;
        Hours += FR.BillByVAL;
        break;
      case 'HourPL':
        for (var L in FR.Legs) {
          var Leg = FR.Legs[L];
          Hours += Leg.BillByVAL;
        }
        break;
      case 'Hobbs':
        if (BillByOPT == 'Primary' || BillByOPT == '') {
          Hours = round10(FR.HOBBS.Primary.Reported.Total - FR.HOBBS.Primary.PrevReading.Total);
        }
        if (BillByOPT == 'Secondary') {
          Hours = round10(FR.HOBBS.Secondary.Reported.Total - FR.HOBBS.Secondary.PrevReading.Total);
        }
        break;
      case 'Distance':
        for (var L in FR.Legs) {
          var Leg = FR.Legs[L];
          Hours += parseFloat(Leg.BillByVAL);
        }
        break;
      case 'Weight':
        for (var L in FR.Legs) {
          var Leg = FR.Legs[L];
          Hours += parseFloat(Leg.BillByVAL);
        }
        break;
      case 'Volume':
        for (var L in FR.Legs) {
          var Leg = FR.Legs[L];
          Hours += parseFloat(Leg.BillByVAL);
        }
        break;
    }
    Hours = LegTime_ToHours(Hours, BillBy);
    html +=
      '<tr class="hideable"><td>' +
      checkbox +
      '</td><td>' +
      FR.PrimaryKey +
      '</td><td>' +
      FR.Date +
      '</td><td>' +
      FR.Aircraft +
      '</td><td>' +
      FR.Pilot +
      '</td><td>' +
      Hours +
      '</td></tr>';
  }
  html += '</table>';
  $('#BILLING_INVOICESFLIGHTREPORTS').html(html);
  $('#BILLING_INVOICESFLIGHTREPORTS').enhanceWithin();
  AddTableSorter('BIT_Invoice3');
}

function SelectDeselectAllFlightReports() {
  var F = BILLING_INVOICE_DATA.FlightReports;
  var Select = false;
  if ($('#FRCHK').is(':checked')) Select = true;
  for (var i in F) {
    if (Select)
      $('#FRCHK-' + i)
        .prop('checked', true)
        .checkboxradio('refresh');
    else
      $('#FRCHK-' + i)
        .prop('checked', false)
        .checkboxradio('refresh');
  }
}

function CheckSelectAllFlightReports() {
  var F = BILLING_INVOICE_DATA.FlightReports;
  var Selected = true;
  for (var i in F) {
    if (!$('#FRCHK-' + i).is(':checked')) Selected = false;
  }
  if (Selected) $('#FRCHK').prop('checked', true).checkboxradio('refresh');
  else $('#FRCHK').prop('checked', false).checkboxradio('refresh');
}

function BILLING_returnAdminSelectExpenses() {
  $.mobile.changePage($('#BILLING_NEWINVOICINGExpensesPage'), {
    changeHash: false,
    reverse: true,
  });
}

function BILLING_AdminSelectExpenses() {
  //Store Selected flight reports
  var F = BILLING_INVOICE_DATA.FlightReports;
  var BilledFlightReports = [];
  for (var i in F) {
    if ($('#FRCHK-' + i).is(':checked')) BilledFlightReports.push(F[i]);
  }
  BILLING_INVOICE_DATA.BilledFlightReports = BilledFlightReports;
  //console.log(BILLING_INVOICE_DATA.BilledFlightReports);
  $.mobile.changePage($('#BILLING_NEWINVOICINGExpensesPage'), {
    changeHash: false,
  });
  // display a list of Approved and Billable Expenses For the selected Client/Job
  $('#BILLING_INVOICESEXPENSES').html('<h2>' + iTrans('Retrieving Expenses and Receipts') + '</h2>');
  // display a list of flight reports that are ready to be invoiced (Marked completed but unbilled for client)
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getBillingExpenses&JobPK=' +
      BILLING_INVOICE_DATA.Job.JobPK +
      '&ClientPK=' +
      BILLING_INVOICE_DATA.Job.ClientPK,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Billing Job Data') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminNewInvoice');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          BILLING_INVOICE_DATA.Expenses = json_data;
          BILLING_ShowInvoiceExpenses();
        }
      } else {
        $('#BILLING_INVOICESEXPENSES').html(
          '<h2>' +
            iTrans('No Expenses Available') +
            ' </h2><p>' +
            iTrans('Some expenses might require approval before they become listed here') +
            '</p>'
        );
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
  // BILLING_INVOICESEXPENSES //div to place expenses list
}

function BILLING_ShowInvoiceExpenses() {
  var Expenses = BILLING_INVOICE_DATA.Expenses;
  //console.log(Expenses);
  var html =
    '<table  id="BIT_Invoice4" data-filter="true" class="limit-table last-td-fill"><thead><tr class="hideable"><th><label for="EXCHK"> </label><input type="checkbox" id="EXCHK" checked onClick="SelectDeselectAllExpenses()" data-mini="true"/></th><th>' +
    iTrans('Date') +
    '</th><th>' +
    iTrans('Category') +
    '</th><th>' +
    iTrans('Amount') +
    '</th><th>' +
    iTrans('Billable Amount') +
    '</th></tr></thead>';
  for (var E in Expenses) {
    var EX = Expenses[E];
    var checkbox = '<input type="checkbox" id="EXCHK-' + E + '" checked/>';

    html +=
      '<tr class="hideable"><td>' +
      checkbox +
      '</td><td>' +
      EX.Date +
      '</td><td>' +
      EX.Category +
      '</td><td>' +
      EX.Amount.toLocaleString('en-US', CUR) +
      '</td><td>' +
      EX.Amount_B.toLocaleString('en-US', CUR) +
      '</tr>';
  }
  html += '</table>';
  $('#BILLING_INVOICESEXPENSES').html(html);
  $('#BILLING_INVOICESEXPENSES').enhanceWithin();
  AddTableSorter('BIT_Invoice4');
}

function SelectDeselectAllExpenses() {
  var F = BILLING_INVOICE_DATA.Expenses;
  var Select = false;
  if ($('#EXCHK').is(':checked')) Select = true;
  for (var i in F) {
    if (Select)
      $('#EXCHK-' + i)
        .prop('checked', true)
        .checkboxradio('refresh');
    else
      $('#EXCHK-' + i)
        .prop('checked', false)
        .checkboxradio('refresh');
  }
}

function CheckSelectAllExpenses() {
  var F = BILLING_INVOICE_DATA.Expenses;
  var Selected = true;
  for (var i in F) {
    if (!$('#EXCHK-' + i).is(':checked')) Selected = false;
  }
  if (Selected) $('#EXCHK').prop('checked', true).checkboxradio('refresh');
  else $('#EXCHK').prop('checked', false).checkboxradio('refresh');
}

function BILLING_returnAdminSelectQuotes() {
  $.mobile.changePage($('#BILLING_NEWINVOICINGQuotesPage'), {
    changeHash: false,
    reverse: true,
  });
}

function BILLING_AdminSelectQuotes() {
  var BE = BILLING_INVOICE_DATA.Expenses;
  var BilledExpenses = [];
  for (var i in BE) {
    if ($('#EXCHK-' + i).is(':checked')) BilledExpenses.push(BE[i]);
  }
  BILLING_INVOICE_DATA.BilledExpenses = BilledExpenses;
  $.mobile.changePage($('#BILLING_NEWINVOICINGQuotesPage'), {
    changeHash: false,
  });
  // display a list of Approved and Billable Quotes For the selected Client/Job
  $('#BILLING_INVOICESQUOTES').html('<h2' + iTrans('Retrieving Quotes and Receipts') + '</h2>');
  // display a list of flight reports that are ready to be invoiced (Marked completed but unbilled for client)
  $.getJSON(
    BASEURL +
      'menu-Billing-AJAX.php?action=getBillingQuotes&JobPK=' +
      BILLING_INVOICE_DATA.Job.JobPK +
      '&ClientPK=' +
      BILLING_INVOICE_DATA.Job.ClientPK,
    function (json_data) {
      if (json_data.length > 0) {
        if (json_data[0].ServerResponse != undefined) {
          //status could be offline/Not Logged In/Uncaught Exception
          if (json_data[0].ServerResponse == 'Offline') {
            alert('<h1>' + iTrans('Offline Cannot Get Billing Job Data') + '</h1>'); //retrieve old data from database
          }
          if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'AdminNewInvoice');
          if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
        } else {
          BILLING_INVOICE_DATA.Quotes = json_data;
          BILLING_ShowInvoiceQuotes();
        }
      } else {
        $('#BILLING_INVOICESQUOTES').html(
          '<h2>' +
            iTrans('No Quotes Available') +
            ' </h2><p>' +
            iTrans('Some Quotes May need to be marked as completed before they become listed here') +
            '</p>'
        );
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
  // BILLING_INVOICESQUOTES //div to place QUOTES list
}

function BILLING_ShowInvoiceQuotes() {
  var Quotes = BILLING_INVOICE_DATA.Quotes;
  //console.log(Quotes);
  var html =
    '<table  id="BIT_Invoice5" data-filter="true" class="limit-table last-td-fill"><thead><tr class="hideable"><th><label for="IQCHK"> </label><input type="checkbox" id="IQCHK" checked onClick="SelectDeselectAllQuotes()" data-mini="true"/></th><th>ID</th><th>' +
    iTrans('Date') +
    '</th><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Amount') +
    '</th><th>' +
    iTrans('Tax') +
    '</th><th>' +
    iTrans('Total') +
    '</th></tr></thead>';
  for (var E in Quotes) {
    var EX = Quotes[E];
    var checkbox = '<input type="checkbox" id="IQCHK-' + E + '" checked/>';

    html +=
      '<tr class="hideable"><td>' +
      checkbox +
      '</td><td>' +
      EX.PrimaryKey +
      '<td>' +
      EX.Date +
      '</td><td>' +
      EX.Job_Text +
      '</td><td>' +
      parseFloat(EX.QuoteSub).toLocaleString('en-US', CUR) +
      '</td><td>' +
      parseFloat(EX.QuoteTax).toLocaleString('en-US', CUR) +
      '</td><td>' +
      parseFloat(EX.QuoteTotal).toLocaleString('en-US', CUR) +
      '</tr>';
  }
  html += '</table>';
  $('#BILLING_INVOICESQUOTES').html(html);
  $('#BILLING_INVOICESQUOTES').enhanceWithin();
  AddTableSorter('BIT_Invoice5');
}

function SelectDeselectAllQuotes() {
  var F = BILLING_INVOICE_DATA.Quotes;
  var Select = false;
  if ($('#IQCHK').is(':checked')) Select = true;
  for (var i in F) {
    if (Select)
      $('#IQCHK-' + i)
        .prop('checked', true)
        .checkboxradio('refresh');
    else
      $('#IQCHK-' + i)
        .prop('checked', false)
        .checkboxradio('refresh');
  }
}

function CheckSelectAllQuotes() {
  var F = BILLING_INVOICE_DATA.Quotes;
  var Selected = true;
  for (var i in F) {
    if (!$('#IQCHK-' + i).is(':checked')) Selected = false;
  }
  if (Selected) $('#IQCHK').prop('checked', true).checkboxradio('refresh');
  else $('#IQCHK').prop('checked', false).checkboxradio('refresh');
}

function BILLING_returnAdminReview() {
  if (EDIT_INVOICE) {
    BILLING_returnAdminInvoicing();
  } else {
    $.mobile.changePage($('#BILLING_NEWINVOICINGReviewPage'), {
      changeHash: false,
      reverse: true,
    });
  }
}

function BILLING_AdminReview() {
  //Store quotes to be invoiced
  var BQ = BILLING_INVOICE_DATA.Quotes;
  var BilledQuotes = [];
  for (var i in BQ) {
    if ($('#IQCHK-' + i).is(':checked')) BilledQuotes.push(BQ[i]);
  }
  BILLING_INVOICE_DATA.BilledQuotes = BilledQuotes;
  //console.log(BILLING_INVOICE_DATA.BilledExpenses);
  BILLING_AdminReviewUpdate();
}

function BILLING_AdminReviewUpdate() {
  $.mobile.changePage($('#BILLING_NEWINVOICINGReviewPage'), {
    changeHash: false,
  });
  //Present Invoice for review and allow for adjustment of fuel rates and adjustments pon per flight report level

  var FR = BILLING_INVOICE_DATA.BilledFlightReports;
  //console.log(BILLING_INVOICE_DATA);
  var AllAircraftRates = BILLING_INVOICE_DATA.Job.Aircraft;
  //class="limit-table last-td-fill"
  var Unit = iTrans('Hours');
  if (BILLING_INVOICE_DATA.Job.BillBy == undefined) BILLING_INVOICE_DATA.Job.BillBy = 'Hour'; //for legacy invoice data data
  var UnitOpt = '';
  if (BILLING_INVOICE_DATA.Job.BillByOPT == undefined) BILLING_INVOICE_DATA.Job.BillByOPT = ''; //for legacy invoice data data
  switch (BILLING_INVOICE_DATA.Job.BillBy) {
    case 'Hour':
    case 'Hour2':
    case 'Minute':
    case 'HourMinute':
      Unit = iTrans('Hours');
      break;
    case 'Hobbs':
      Unit = iTrans('Hobbs');
      break;
    case 'Distance':
      switch (BILLING_INVOICE_DATA.Job.BillByOPT) {
        case 'NM':
          Unit = iTrans('Distance') + ' (NM)';
          break;
        case 'SM':
          Unit = iTrans('Distance') + ' (sm)';
          break;
        case 'KM':
          Unit = iTrans('Distance') + ' (km)';
          break;
        default:
          Unit = iTrans('Distance');
          break;
      }
      break;
    case 'Weight':
      switch (BILLING_INVOICE_DATA.Job.BillByOPT) {
        case 'KG':
          Unit = iTrans('Weight') + ' (kg)';
          break;
        case 'LB':
          Unit = iTrans('Weight') + ' (lb)';
          break;
        default:
          Unit = iTrans('Weight');
          break;
      }
      break;
    case 'Volume':
      switch (BILLING_INVOICE_DATA.Job.BillByOPT) {
        case 'litres':
          Unit = iTrans('Volume') + ' (L)';
          break;
        case 'gallonsus':
          Unit = iTrans('Volume') + ' (US gal)';
          break;
        case 'gallonsimp':
          Unit = iTrans('Volume') + ' (Imp gal)';
          break;
        default:
          Unit = iTrans('Volume');
          break;
      }
      break;
  }
  var html =
    '<h3>Flight Reports</h3><table class="limit-table"><thead><tr class="hideable"><th>' +
    iTrans('FR') +
    '#</th><th>' +
    iTrans('Aircraft') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Report Date') +
    '</th><th>' +
    Unit +
    '</th><th>' +
    iTrans('Rate') +
    '</th></tr></thead>';
  var TotalHours = 0;
  var TotalAmount = 0;
  var Bill_Items = {
    Fields: {},
    Total: 0,
    TotalNonTaxable: 0,
  };
  for (var f in FR) {
    var F = FR[f];
    if (F.Crew == '') F.Crew = [];
    if (!Array.isArray(F.Crew)) F.Crew = JSON.parse(F.Crew);
    F.Bill_Items = BILLING_CalcBill_Items(F);
    Bill_Items.Total += F.Bill_Items.Total;
    Bill_Items.TotalNonTaxable += F.Bill_Items.TotalNonTaxable;
    for (var z in F.Bill_Items.Fields) {
      var Z = F.Bill_Items.Fields[z];
      if (Bill_Items.Fields[z] == undefined) {
        Bill_Items.Fields[z] = JSON.parse(JSON.stringify(Z));
      } else {
        Bill_Items.Fields[z].Qty += Z.Qty;
        Bill_Items.Fields[z].Total += Z.Total;
      }
    }
    var AircraftRates = {
      AcType: F.AcType,
      WetRate: 0,
      DryRate: 0,
      Index: AllAircraftRates.length,
    };
    var FoundRate = false;
    for (var i in AllAircraftRates) {
      if (AllAircraftRates[i].AcType == F.AcType) {
        AircraftRates = AllAircraftRates[i];
        AircraftRates.Index = i;
        FoundRate = true;
      }
    }
    if (!FoundRate) AllAircraftRates[AllAircraftRates.length] = AircraftRates; //set default aircraft type rates to be updated for non selected type
    var FuelRate = 0;
    if (F.FuelProvided == 1) FuelRate = AircraftRates.DryRate;
    else FuelRate = AircraftRates.WetRate;
    var Rate =
      '$<input type="number" step="1" id="Frate-' +
      f +
      '" onChange="BILLING_ChangeRate(' +
      AircraftRates.Index +
      ',' +
      F.FuelProvided +
      ',' +
      f +
      ')" value="' +
      FuelRate +
      '" style="width:80px;" data-role="none"/>';
    var Legs = F.Legs;
    var Hours = 0;
    if (BILLING_INVOICE_DATA.Job.BillBy == undefined) BILLING_INVOICE_DATA.Job.BillBy = 'Hour'; //for legacy invoice data data
    switch (BILLING_INVOICE_DATA.Job.BillBy) {
      case 'Hour':
      case 'Hour2':
      case 'Minute':
      case 'HourMinute':
        var lastStart = '';
        for (var l in Legs) {
          var Leg = Legs[l];
          var THours = 0;
          if (Leg.Billable == 1) {
            if (BILLING_INVOICE_DATA.Job.BlockTime == 1) {
              if (Leg.Time_Start != '') {
                lastStart = Leg.Time_Start;
                if (Leg.Time_Stop != '') {
                  THours = getDecimalTime(Leg.Time_Start, Leg.Time_Stop, BILLING_INVOICE_DATA.Job.BillBy);
                }
              } else {
                if (Leg.Time_Stop != '' && lastStart != '') {
                  THours = getDecimalTime(lastStart, Leg.Time_Stop, BILLING_INVOICE_DATA.Job.BillBy);
                }
              }
            } else {
              if (Leg.Time_Start != '') {
                lastStart = Leg.Time_Up;
                if (Leg.Time_Down != '') {
                  THours = getDecimalTime(Leg.Time_Up, Leg.Time_Down, BILLING_INVOICE_DATA.Job.BillBy);
                }
              } else {
                if (Leg.Time_Down != '' && lastStart != '') {
                  THours = getDecimalTime(lastStart, Leg.Time_Down, BILLING_INVOICE_DATA.Job.BillBy);
                }
              }
            }
            if (THours < 0) THours += 24;
            Hours += THours;
          }
        }
        Hours = LegTime_ToHours(Hours, BILLING_INVOICE_DATA.Job.BillBy);
        F.TotalHours = Hours;
        F.FuelRate = FuelRate;
        var Amount = Math.round(Hours * FuelRate * 100) / 100;
        F.TotalAmount = Amount;
        TotalHours += Hours;
        TotalAmount += Amount;
        html +=
          '<tr class="hideable"><td>' +
          F.PrimaryKey +
          '</td><td>' +
          F.Aircraft +
          '</td><td>' +
          F.AcType +
          '</td><td>' +
          F.Date +
          '</td><td>' +
          Hours +
          '</td><td>' +
          Rate +
          '</td></tr>';
        break;
      case 'HourPL':
        for (var l in Legs) {
          var Leg = Legs[l];
          if (Leg.Billable == 1) {
            var THours = Leg.BillByVAL;
            Hours += THours;
          }
        }
        Hours = Math.round(Hours * 10) / 10;
        F.TotalHours = Hours;
        F.FuelRate = FuelRate;
        var Amount = Math.round(Hours * FuelRate * 100) / 100;
        F.TotalAmount = Amount;
        TotalHours += Hours;
        TotalAmount += Amount;
        html +=
          '<tr class="hideable"><td>' +
          F.PrimaryKey +
          '</td><td>' +
          F.Aircraft +
          '</td><td>' +
          F.AcType +
          '</td><td>' +
          F.Date +
          '</td><td>' +
          Hours +
          '</td><td>' +
          Rate +
          '</td></tr>';
        break;
      case 'HourPR':
        if (F.BillByVAL == undefined) F.BillByVAL = 0;
        Hours = Math.round(F.BillByVAL * 10) / 10;
        F.TotalHours = Hours;
        F.FuelRate = FuelRate;
        var Amount = Math.round(Hours * FuelRate * 100) / 100;
        F.TotalAmount = Amount;
        TotalHours += Hours;
        TotalAmount += Amount;
        html +=
          '<tr class="hideable"><td>' +
          F.PrimaryKey +
          '</td><td>' +
          F.Aircraft +
          '</td><td>' +
          F.AcType +
          '</td><td>' +
          F.Date +
          '</td><td>' +
          Hours +
          '</td><td>' +
          Rate +
          '</td></tr>';
        break;
      case 'Hobbs':
        var P1 = F.HOBBS.Primary.Reported.Total;
        var P2 = F.HOBBS.Primary.PrevReading.Total;
        Hours = Math.round((P1 - P2) * 10) / 10;
        F.P_Total = Hours;
        var S1 = F.HOBBS.Secondary.Reported.Total;
        var S2 = F.HOBBS.Secondary.PrevReading.Total;
        var Hours2 = Math.round((S1 - S2) * 10) / 10;
        F.S_Total = Hours2;
        switch (BILLING_INVOICE_DATA.Job.BillByOPT) {
          case 'Secondary':
            F.TotalHours = Hours2;
            F.FuelRate = FuelRate;
            var Amount = Math.round(Hours2 * FuelRate * 100) / 100;
            F.TotalAmount = Amount;
            TotalHours += Hours2;
            TotalAmount += Amount;
            html +=
              '<tr class="hideable"><td>' +
              F.PrimaryKey +
              '</td><td>' +
              F.Aircraft +
              '</td><td>' +
              F.AcType +
              '</td><td>' +
              F.Date +
              '</td><td>' +
              Hours2 +
              '</td><td>' +
              Rate +
              '</td></tr>';
            break;
          default:
            F.TotalHours = Hours;
            F.FuelRate = FuelRate;
            var Amount = Math.round(Hours * FuelRate * 100) / 100;
            F.TotalAmount = Amount;
            TotalHours += Hours;
            TotalAmount += Amount;
            html +=
              '<tr class="hideable"><td>' +
              F.PrimaryKey +
              '</td><td>' +
              F.Aircraft +
              '</td><td>' +
              F.AcType +
              '</td><td>' +
              F.Date +
              '</td><td>' +
              Hours +
              '</td><td>' +
              Rate +
              '</td></tr>';
            break;
        }

        break;
      case 'Distance':
        for (var l in Legs) {
          var Leg = Legs[l];
          if (Leg.Billable == 1) Hours += parseFloat(Leg.BillByVAL);
        }
        Hours = Math.round(Hours * 10) / 10;
        F.TotalHours = Hours;
        F.FuelRate = FuelRate;
        var Amount = Math.round(Hours * FuelRate * 100) / 100;
        F.TotalAmount = Amount;
        TotalHours += Hours;
        TotalAmount += Amount;
        html +=
          '<tr class="hideable"><td>' +
          F.PrimaryKey +
          '</td><td>' +
          F.Aircraft +
          '</td><td>' +
          F.AcType +
          '</td><td>' +
          F.Date +
          '</td><td>' +
          Hours +
          '</td><td>' +
          Rate +
          '</td></tr>';
        break;
      case 'Weight':
        for (var l in Legs) {
          var Leg = Legs[l];
          if (Leg.Billable == 1) Hours += parseFloat(Leg.BillByVAL);
        }
        Hours = Math.round(Hours * 10) / 10;
        F.TotalHours = Hours;
        F.FuelRate = FuelRate;
        var Amount = Math.round(Hours * FuelRate * 100) / 100;
        F.TotalAmount = Amount;
        TotalHours += Hours;
        TotalAmount += Amount;
        html +=
          '<tr class="hideable"><td>' +
          F.PrimaryKey +
          '</td><td>' +
          F.Aircraft +
          '</td><td>' +
          F.AcType +
          '</td><td>' +
          F.Date +
          '</td><td>' +
          Hours +
          '</td><td>' +
          Rate +
          '</td></tr>';
        break;
      case 'Volume':
        for (var l in Legs) {
          var Leg = Legs[l];
          if (Leg.Billable == 1) Hours += parseFloat(Leg.BillByVAL);
        }
        Hours = Math.round(Hours * 10) / 10;
        F.TotalHours = Hours;
        F.FuelRate = FuelRate;
        var Amount = Math.round(Hours * FuelRate * 100) / 100;
        F.TotalAmount = Amount;
        TotalHours += Hours;
        TotalAmount += Amount;
        html +=
          '<tr class="hideable"><td>' +
          F.PrimaryKey +
          '</td><td>' +
          F.Aircraft +
          '</td><td>' +
          F.AcType +
          '</td><td>' +
          F.Date +
          '</td><td>' +
          Hours +
          '</td><td>' +
          Rate +
          '</td></tr>';
        break;
    }
  }
  TotalHours = Math.round(TotalHours * 100) / 100;
  TotalAmount = Math.round(TotalAmount * 100) / 100;
  html +=
    '<tr class="hideable"><td></td><td></td><td></td><td style="text-align:right;"><b>' +
    iTrans('Total') +
    ':</b></td><td><b>' +
    TotalHours +
    '</b></td><td><b>' +
    TotalAmount.toLocaleString('en-US', CUR) +
    '</b></td></tr>';
  html += '</table>';
  BILLING_INVOICE_DATA.Bill_Items = Bill_Items;
  BILLING_INVOICE_DATA.TotalHours = TotalHours;
  BILLING_INVOICE_DATA.TotalAmount = TotalAmount;

  var EX = BILLING_INVOICE_DATA.BilledExpenses;
  //console.log(EX);
  var ExpenseTotal = 0;
  if (EX.length > 0) {
    html +=
      '<h3>' +
      iTrans('Expenses') +
      '</h3><table class="limit-table"><thead><tr class="hideable"><th>' +
      iTrans('Date') +
      '</th><th>' +
      iTrans('Category') +
      '</th><th>' +
      iTrans('Amount') +
      '</th></tr></thead>';
    for (var e in EX) {
      var E = EX[e];
      E.Amount_Cur = E.Amount_B;
      var ConvertedAmount = '';
      if (GROUP_DATA.CurConversion == 1 && E.Currency != 'CAD') {
        E.Amount_Cur = E.Amount_B * getCurrencyRate(E.Currency);
        ConvertedAmount = ' (' + E.Amount_Cur.toLocaleString('en-US', CUR) + ' CAD)';
      }
      html +=
        '<tr class="hideable"><td>' +
        E.Date +
        '</td><td>' +
        E.Category +
        '</td><td>' +
        E.Amount_B.toLocaleString('en-US', CUR) +
        ' ' +
        E.Currency +
        ConvertedAmount;
      ('</td></tr>');
      ExpenseTotal += E.Amount_Cur;
    }
    ExpenseTotal = Math.round(ExpenseTotal * 100) / 100;
    html +=
      '<tr class="hideable">' +
      "<td></td><td style='text-align:right'><b>" +
      iTrans('Total') +
      ':</b></td><td><b>' +
      ExpenseTotal.toLocaleString('en-US', CUR) +
      '</b></td></tr>';
    html += '</table>';
  } else {
    html += '<h3>' + iTrans('No Expenses Being Billed') + '</h3>';
  }

  var BQ = BILLING_INVOICE_DATA.BilledQuotes;
  //console.log(EX);
  var QuoteTotal = 0;
  if (BQ.length > 0) {
    html +=
      '<h3>' +
      iTrans('Quotes') +
      '</h3><table class="limit-table"><thead><tr class="hideable"><th>ID#</th><th>' +
      iTrans('Date') +
      '</th><th>' +
      iTrans('Description') +
      '</th><th>' +
      iTrans('Total Amount') +
      '</th></tr></thead>';
    for (var e in BQ) {
      var Q = BQ[e];
      html +=
        '<tr class="hideable"><td>' +
        Q.PrimaryKey +
        '<td>' +
        Q.Date +
        '</td><td>' +
        Q.Job_Text +
        '</td><td>' +
        parseFloat(Q.QuoteTotal).toLocaleString('en-US', CUR) +
        '</td></tr>';
      QuoteTotal += parseFloat(Q.QuoteTotal);
    }
    QuoteTotal = Math.round(QuoteTotal * 100) / 100;
    html +=
      '<tr class="hideable">' +
      "<td></td><td></td><td style='text-align:right'><b>" +
      iTrans('Total') +
      ':</b></td><td><b>' +
      QuoteTotal.toLocaleString('en-US', CUR) +
      '</b></td></tr>';
    html += '</table>';
  } else {
    html += '<h3>' + iTrans('No Quotes Being Billed') + '</h3>';
  }

  BILLING_INVOICE_DATA.TotalExpenses = ExpenseTotal;
  BILLING_INVOICE_DATA.TotalQuotes = QuoteTotal;
  BILLING_INVOICE_DATA.TotalPayable = Math.round((ExpenseTotal + TotalAmount + QuoteTotal) * 100) / 100;
  $('#BILLING_INVOICEREVIEW').html(html);
  $('#BILLING_INVOICEREVIEW').enhanceWithin();

  // BILLING_INVOICEREVIEW //div to place review of invoice items
}

function BILLING_CalcBill_Items(FR) {
  //flight report
  var PR = BILLING_CalcBill_Item(FR.Cfields, {}, false, FR, false);

  if (FR.Legs.length > 0) {
    for (var i in FR.Legs) {
      var PLBI = BILLING_CalcBill_Item(FR.Legs[i].Cfields, PR.Fields, true, FR, i);
      PR.Fields = PLBI.Fields;
      PR.Total += PLBI.Total;
      PR.TotalNonTaxable += PLBI.TotalNonTaxable;
    }
  }

  return PR;
}
function BILLING_CalcBill_Item(CF, Fields, PerLeg, FR, legIndex) {
  var calc = {
    Total: 0,
    TotalNonTaxable: 0,
    Fields: Fields,
  };

  for (var i in CF) {
    var I = CF[i];
    var Qty = 0;
    var Cost = 0;
    if (I.usedFor == 'ITIN') continue; //Skip because this is for itineray fields only
    if ((PerLeg && I.PerLeg == 0) || (!PerLeg && I.PerLeg == 1)) continue;
    if (I.Bill_Item_options == undefined) continue;
    var BIO;
    try {
      BIO = JSON.parse(I.Bill_Item_options);
    } catch (e) {
      BIO = {
        Taxable: 0,
        Multiple: 0,
        Unit: '',
      };
    }
    if (I.Bill_Item == 1) {
      var Amount = parseFloat(I.Value);
      if (isNaN(Amount)) Amount = 0;
      var Options = JSON.parse(I.Bill_Item_options);
      var Multiple = parseFloat(Options.Multiple);
      if (isNaN(Multiple)) Multiple = 0;
      var Total = 0;
      var Unit = '';
      if (BIO.Unit != '') Unit = I.Unit;
      if (I.type != 'checkbox') {
        if (BIO.Unit == 'BillByVal') {
          switch (BILLING_INVOICE_DATA.Job.BillBy) {
            case 'Hour':
            case 'Hour2':
            case 'Minute':
            case 'HourMinute':
              var Hours = 0;
              if (PerLeg) {
                var Leg = FR.Legs[legIndex];
                if (BILLING_INVOICE_DATA.Job.BlockTime == 1) {
                  Hours += getDecimalTime(Leg.Time_Start, Leg.Time_Stop, BILLING_INVOICE_DATA.Job.BillBy);
                } else {
                  Hours += getDecimalTime(Leg.Time_Up, Leg.Time_Down, BILLING_INVOICE_DATA.Job.BillBy);
                }
                if (Hours < 0) Hours += 24;
                Multiple = Hours;
              } else {
                for (var L in FR.Legs) {
                  var Leg = FR.Legs[L];
                  if (BILLING_INVOICE_DATA.Job.BlockTime == 1) {
                    Hours += getDecimalTime(Leg.Time_Start, Leg.Time_Stop, BILLING_INVOICE_DATA.Job.BillBy);
                  } else {
                    Hours += getDecimalTime(Leg.Time_Up, Leg.Time_Down, BILLING_INVOICE_DATA.Job.BillBy);
                  }
                  if (Hours < 0) Hours += 24;
                }
                Multiple = Hours;
              }
              break;
            case 'HourPL':
            case 'Distance':
            case 'Weight':
            case 'Volume':
              if (PerLeg) {
                Multiple = FR.Legs[legIndex].BillByVAL;
              } else {
                var multTotal = 0;
                for (var L in FR.Legs) {
                  var Leg = FR.Legs[L];
                  multTotal += Leg.BillByVAL;
                }
                Multiple = multTotal;
              }
              break;
            case 'HourPR':
              if (!PerLeg) Multiple = FR.BillByVAL;
              else Multiple = 0;
              break;
            case 'Hobbs':
              if (!PerLeg) {
                if (BILLING_INVOICE_DATA.Job.BillByOPT == 'Primary')
                  Multiple = round10(FR.HOBBS.Primary.Reported.Total - FR.HOBBS.Primary.PrevReading.Total);
                if (BILLING_INVOICE_DATA.Job.BillByOPT == 'Secondary')
                  Multiple = round10(FR.HOBBS.Secondary.Reported.Total - FR.HOBBS.Secondary.PrevReading.Total);
              } else {
                Multiple = 0;
              }
              break;
          }
          console.log(I);
          console.log(FR);
          Total = Amount * Multiple;
          Qty = Multiple;
          Cost = Amount;
        } else {
          Total = Amount * Multiple;
          Qty = Amount;
          Cost = Multiple;
        }

        if (Options.Taxable == 1) calc.Total += Total;
        else calc.TotalNonTaxable += Total;
      } else {
        //is billable checkbox
        if (I.Value == 1) {
          Cost = Multiple;
          Qty = 1;
          Total = Multiple;
          if (Options.Taxable == 1) calc.Total += Total;
          else calc.TotalNonTaxable += Total;
        }
      }
      if (Fields[I.PrimaryKey] == undefined) {
        Fields[I.PrimaryKey] = {
          Label: I.Label,
          Qty: Qty,
          Unit: Unit,
          Cost: Cost,
          PerLeg: I.PerLeg,
          Total: Total,
        };
      } else {
        Fields[I.PrimaryKey].Total += Total;
        Fields[I.PrimaryKey].Qty += Qty;
      }
    }
  }
  return calc;
}

function BILLING_ChangeRate(index, F, id) {
  if (F == 1)
    BILLING_INVOICE_DATA.Job.Aircraft[index].DryRate = Math.round(parseFloat($('#Frate-' + id).val()) * 100) / 100;
  else BILLING_INVOICE_DATA.Job.Aircraft[index].WetRate = Math.round(parseFloat($('#Frate-' + id).val()) * 100) / 100;
  BILLING_AdminReviewUpdate();
}

function ValidateTax(e) {
  var id = e.id;
  var val = $('#' + id).val();
  if (val < 0) $('#' + id).val(0);
  if (val > 99) $('#' + id).val(50);
}

function BILLING_ADD_ADJUSTMENT(e) {
  var Desc = '';
  var Value = 0;
  var Qty = 1;
  var Unit = '';
  var Total = 0;
  if (e != undefined) {
    Desc = BILLING_INVOICE_DATA.Adjustments[e].Description;
    Value = BILLING_INVOICE_DATA.Adjustments[e].Amount;
    Qty = BILLING_INVOICE_DATA.Adjustments[e].Qty;
    if (BILLING_INVOICE_DATA.Adjustments[e].Unit != undefined) Unit = BILLING_INVOICE_DATA.Adjustments[e].Unit; //for prev v1.22.1
    Total = BILLING_INVOICE_DATA.Adjustments[e].Total;
  }
  var html = '<select id="InvoiceAdjustmentSelect" onChange="UpdateAdjustmentVals()">';
  html += '<option value="-1">' + iTrans('Manual Adjustment') + '</option>';
  for (var i in BILLING_COMPANY_ADJUSTMENTS) {
    var I = BILLING_COMPANY_ADJUSTMENTS[i];
    html += '<option value="' + i + '">' + I.Desc + '</option>';
  }
  html += '</select>';
  html += '<label for="ADJ_DESC">' + iTrans('Description') + '</label>';
  html += '<input type="text" id="ADJ_DESC" value="' + Desc + '"/>';
  html += '<label for="ADJ_Qty">' + iTrans('Quantity') + '</label>';
  html += '<input type="number" id="ADJ_Qty" value="' + Qty + '"/>';
  html += '<label for="ADJ_Qty_Unit">' + iTrans('Unit') + '</label>';
  html += '<select id="ADJ_Qty_Unit">';
  html += '<option value="">' + iTrans('None') + '</option>';
  html += '<option value="NM">NM</option>';
  html += '<option value="sm">sm</option>';
  html += '<option value="km">km</option>';
  html += '<option value="kg">kg</option>';
  html += '<option value="lbs">lbs</option>';
  html += '<option value="L">Litres</option>';
  html += '<option value="Gal">Gallons</option>';
  html += '<option value="Days">' + iTrans('Days') + '</option>';
  html += '<option value="Hours">' + iTrans('Hours') + '</option>';
  html += '</select>';
  html += '<label for="ADJ_AMOUNT">' + iTrans('Amount') + '</label>';
  html += '<input type="number" id="ADJ_AMOUNT" value="' + Value + '"/>';
  setTimeout(function () {
    $('#ADJ_Qty_Unit').val(Unit);
    $('#ADJ_Qty_Unit').selectmenu('refresh');
  }, 500);
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Adjustments'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          //acknolwedge
          var Amount = $('#ADJ_AMOUNT').val();
          var Qty = $('#ADJ_Qty').val();
          //Amount = Math.round(Amount * 100) / 100;
          var decimalsPoints = getDecimals(Amount);
          var Total = roundNum(Amount * Qty, decimalsPoints > 2 ? decimalsPoints : 2);
          if (e == undefined) {
            BILLING_INVOICE_DATA.Adjustments.push({
              Description: $('#ADJ_DESC').val(),
              Amount: Amount,
              Qty: Qty,
              Unit: $('#ADJ_Qty_Unit').val(),
              Total: Total,
            });
          } else {
            BILLING_INVOICE_DATA.Adjustments[e] = {
              Description: $('#ADJ_DESC').val(),
              Amount: Amount,
              Qty: Qty,
              Unit: $('#ADJ_Qty_Unit').val(),
              Total: Total,
            };
          }
          BILLING_INVOICE_DATA.needSave = true;
          BILLING_AdminInvoiceComplete(true);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function UpdateAdjustmentVals() {
  var index = $('#InvoiceAdjustmentSelect').val();
  if (index == '-1') {
    $('#ADJ_AMOUNT').val(0);
    $('#ADJ_Qty').val(1);
    $('#ADJ_DESC').val('');
    $('#ADJ_Qty_Unit').val('');
    $('#ADJ_Qty_Unit').selectmenu('refresh');
  } else {
    var I = BILLING_COMPANY_ADJUSTMENTS[index];
    $('#ADJ_AMOUNT').val(I.Amount);
    $('#ADJ_Qty').val(I.Qty);
    $('#ADJ_DESC').val(I.Desc);
    if (I.Unit != undefined) {
      //for pre v1.22.1
      $('#ADJ_Qty_Unit').val(I.Unit);
      $('#ADJ_Qty_Unit').selectmenu('refresh');
    }
  }
}
function BILLING_DeleteADJ(e) {
  BILLING_INVOICE_DATA.Adjustments.splice(e, 1);
  BILLING_AdminInvoiceComplete(true);
}
function Billing_SwitchHideCfields(e) {
  if (e.checked) BILLING_INVOICE_DATA.HideEmptyCfields = 1;
  else BILLING_INVOICE_DATA.HideEmptyCfields = 0;
  BILLING_AdminInvoiceComplete(true);
}
function Billing_MovePRCfields(e) {
  if (e.checked) BILLING_INVOICE_DATA.MovePRCfields = 1;
  else BILLING_INVOICE_DATA.MovePRCfields = 0;
  BILLING_AdminInvoiceComplete(true);
}
function BILLING_AdminInvoiceComplete(e) {
  if (e != true)
    $.mobile.changePage($('#BILLING_NEWINVOICINGCompletePage'), {
      changeHash: false,
    });
  $('#BILLING_CurrencyConverter').enhanceWithin();
  var B = BILLING_INVOICE_DATA;
  if (INVOICE_NUMBER != null) {
    if (BILLING_INVOICE_DATA.PrimaryKey == undefined) BILLING_INVOICE_DATA.PrimaryKey = INVOICE_NUMBER;
    if (BILLING_INVOICE_DATA.InvoiceNum == undefined) BILLING_INVOICE_DATA.InvoiceNum = CUSTOM_INVOICE_NUMBER;
  }
  //console.log(B);
  if (B.Adjustments == undefined) {
    B.Adjustments = [];
  }
  B.TotalAdjustment = 0;
  var html = '';
  if (B.HideEmptyCfields != undefined) {
    if (B.HideEmptyCfields == 1) $('#BILLING_HIDE_EMPTY').prop('checked', true).checkboxradio('refresh');
    else $('#BILLING_HIDE_EMPTY').prop('checked', false).checkboxradio('refresh');
  } else {
    $('#BILLING_HIDE_EMPTY').prop('checked', false).checkboxradio('refresh');
    B.HideEmptyCfields = 0;
  }
  if (BILLING_INVOICE_DATA.Job.BillBy == 'Hobbs') {
    $('#BILLING_HIDELEGTOTALS_DIV').show();
  } else {
    $('#BILLING_HIDELEGTOTALS_DIV').hide();
    $('#BILLING_HIDELEGTOTALS').prop('checked', false);
  }

  html += '<h3>Job Minimums</h3>';
  html +=
    '<table class="limit-table last-td-fill"><tr><td><b>Minimum Hours: <br />Apply Minimums By: </b></td><td>' +
    BILLING_INVOICE_DATA.Job.Min_Hours +
    '<br />' +
    BILLING_INVOICE_DATA.Job.Averaged +
    '</td><td></td></tr></table>';

  html += '<h3>Adjustments</h3>';
  var Ahtml =
    '<table class="limit-table first-td-fill"><thead><tr class="hideable"><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Amount') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var a in B.Adjustments) {
    var A = B.Adjustments[a];
    var Qty = 1;
    var Unit = '';
    if (A.Unit != undefined) Unit = A.Unit; //for legacy invoices
    if (A.Qty != undefined) Qty = A.Qty; //for legacy invoices
    if (Unit != '') {
      Qty = Qty + ' ' + Unit;
    }

    var Total = A.Amount;
    if (A.Total != undefined) Total = A.Total; //for legacy invoices
    var Button =
      '<div class="vertical-center-container"><button onclick="BILLING_ADD_ADJUSTMENT(' +
      a +
      ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/edit.svg"></button><button onclick="BILLING_DeleteADJ(' +
      a +
      ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/delete.svg"></button></div>';
    Ahtml +=
      '<tr class="hideable"><td><b>' +
      A.Description +
      ':</b> ' +
      Qty +
      ' @ ' +
      toInvoiceCurrency(A.Amount) +
      '</td><td>' +
      toInvoiceCurrency(Total, true) +
      '</td><td>' +
      Button +
      '</tr>';
    B.TotalAdjustment += parseFloat(Total);
  }
  B.TotalAdjustment = Math.round(B.TotalAdjustment * 100) / 100;

  if (B.Adjustments.length == 0) Ahtml = iTrans('None') + '<br />';
  else
    Ahtml +=
      '<tr class="hideable"><td style="text-align:right"><b>' +
      iTrans('Total Adjustments') +
      '</b></td><td>' +
      toInvoiceCurrency(B.TotalAdjustment) +
      '</td><td></td></tr></tbody></table>';
  html += Ahtml;
  html +=
    '<button class="greenbg" data-icon="plus" data-mini="true" onClick="BILLING_ADD_ADJUSTMENT()" >' +
    iTrans('Add Adjustment') +
    '</button>';

  html += '<h3>Invoice Totals</h3>';

  html +=
    '<table class="limit-table first-td-fill"><thead><tr class="hideable"><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Amount') +
    '</th></tr></thead><tbody>';
  var FlightCount = B.BilledFlightReports.length;
  var Unit = iTrans('Hours');
  if (BILLING_INVOICE_DATA.Job.BillBy == undefined) BILLING_INVOICE_DATA.Job.BillBy = 'Hour'; //for legacy invoice data data
  var UnitOPT = '';
  if (BILLING_INVOICE_DATA.Job.BillByOPT == undefined) BILLING_INVOICE_DATA.Job.BillByOPT = ''; //for legacy invoice data data
  switch (BILLING_INVOICE_DATA.Job.BillBy) {
    case 'Hour':
    case 'Hour2':
    case 'Minute':
    case 'HourMinute':
      Unit = iTrans('Flight Hours');
      break;
    case 'Hobbs':
      switch (UnitOPT) {
        case 'Primary':
          Unit = iTrans('Flight Hobbs Hours');
          break;
        case 'Secondary':
          Unit = iTrans('Maintenance Hobbs Hours');
          break;
        default:
          Unit = iTrans('Flight Hobbs Hours');
          break;
      }
      break;
    case 'Distance':
      switch (UnitOPT) {
        case 'NM':
          Unit = 'NM';
          break;
        case 'SM':
          Unit = 'sm';
          break;
        case 'KM':
          Unit = 'km';
          break;
        default:
          Unit = 'NM';
          break;
      }
      break;
    case 'Weight':
      switch (UnitOPT) {
        case 'LB':
          Unit = 'lb';
          break;
        case 'KG':
          Unit = 'kg';
          break;
        default:
          Unit = 'lb';
          break;
      }
      break;
    case 'Volume':
      switch (UnitOPT) {
        case 'litres':
          Unit = 'L';
          break;
        case 'gallonsus':
          Unit = 'US gal';
          break;
        case 'gallonsimp':
          Unit = 'imp gal';
          break;
        default:
          Unit = 'L';
          break;
      }
      break;
  }
  html +=
    '<tr class="hideable"><td><b>' +
    iTrans('Flights') +
    ': </b>' +
    B.TotalHours +
    ' ' +
    Unit +
    ' (' +
    FlightCount +
    ' ' +
    iTrans('Reports') +
    ')</td><td style="text-align:right">' +
    toInvoiceCurrency(B.TotalAmount) +
    '</td></tr>';

  var DailyTotal = 0;
  if (B.Job.Daily_Rate != undefined) {
    var UniqueDateCount = 0;
    var lastDate;
    for (var U in B.BilledFlightReports) {
      var bDate = B.BilledFlightReports[U].Date;
      if (bDate != lastDate) {
        UniqueDateCount++;
        lastDate = bDate;
      }
    }
    if (B.Job.Daily_Per_AC == 1) {
      DailyTotal = Math.round(B.Job.Daily_Rate * B.BilledFlightReports.length * 100) / 100;
      html +=
        '<tr class="hideable"><td><b>' +
        iTrans('Daily Charges') +
        ': </b>' +
        B.BilledFlightReports.length +
        ' @ ' +
        toInvoiceCurrency(B.Job.Daily_Rate) +
        ' (' +
        iTrans('Per Aircraft/Day') +
        ')</td><td style="text-align:right">' +
        toInvoiceCurrency(DailyTotal) +
        '</td></tr>';
    } else {
      DailyTotal = Math.round(B.Job.Daily_Rate * UniqueDateCount * 100) / 100;
      html +=
        '<tr class="hideable"><td><b>' +
        iTrans('Daily Charges') +
        ': </b>' +
        UniqueDateCount +
        ' @ ' +
        toInvoiceCurrency(B.Job.Daily_Rate) +
        ' (Per Day)</td><td style="text-align:right">' +
        toInvoiceCurrency(DailyTotal) +
        '</td></tr>';
    }
  }
  if (B.BilledQuotes != undefined) {
    if (B.BilledQuotes.length > 0) {
      $('#BILLING_HIDEQUOTEITEMS').show();
      var QuoteNum = parseInt(B.BilledQuotes.length) + 1;
      html +=
        '<tr class="hideable"><td><b>' +
        iTrans('Quote Charges') +
        ': </b>' +
        QuoteNum +
        '</td><td style="text-align:right">' +
        toInvoiceCurrency(B.TotalQuotes) +
        '</td></tr>';
    } else {
      $('#BILLING_HIDEQUOTEITEMS').hide();
    }
  }
  html +=
    '<tr class="hideable"><td>' +
    iTrans('Expenses') +
    '</td><td style="text-align:right">' +
    toInvoiceCurrency(B.TotalExpenses) +
    '</td></tr>';
  html +=
    '<tr class="hideable"><td>' +
    iTrans('Adjustments') +
    '</td><td style="text-align:right">' +
    toInvoiceCurrency(B.TotalAdjustment) +
    '</td></tr>';

  var BIF = [];
  if (B.Bill_Items != undefined) BIF = B.Bill_Items.Fields;
  else {
    B.Bill_Items = {
      Fields: [],
      Total: 0,
      TotalNonTaxable: 0,
    };
  }
  //Flight report billable items custom fields
  for (var b in BIF) {
    if (BIF[b] == null) continue;
    if ((B.HideEmptyCfields == 1 && BIF[b].Total == 0) || (B.HideEmptyCfields == 1 && BIF[b].Total == '')) continue;
    var PerLegTXT = '';
    if (BIF[b].PerLeg == 1) PerLegTXT = ' (Per Leg)';
    html +=
      '<tr class="hideable"><td><b>' +
      iTrans('FR Item') +
      ':</b> ' +
      BIF[b].Label +
      PerLegTXT +
      '</td><td style="text-align:right">' +
      toInvoiceCurrency(BIF[b].Total) +
      '</td></tr>';
  }

  var TotalPayable =
    Math.round(
      (B.TotalPayable + B.TotalAdjustment + DailyTotal + B.Bill_Items.Total + B.Bill_Items.TotalNonTaxable) * 100
    ) / 100;
  TotalPayableTXT = TotalPayable.toLocaleString('en-US', CUR);
  if (GROUP_DATA.CurConversion == 1 && B.CurConvert != '' && B.CurConvert != undefined) {
    var CurTotalPayable = TotalPayable / B.CurRate;
    TotalPayableTXT =
      TotalPayable.toLocaleString('en-US', CUR) +
      ' CAD (' +
      CurTotalPayable.toLocaleString('en-US', CUR) +
      ' ' +
      B.CurConvert.substr(0, 3) +
      ')';
  }
  html +=
    '<tr class="hideable"><td  style="text-align:right"><b>' +
    iTrans('Amount payable') +
    '<b></td><td style="text-align:right"><b>' +
    TotalPayableTXT +
    '</b></td></tr>';
  html +=
    '<tr class="hideable"><td><label for="BillingTaxDesc">' +
    iTrans('Tax Description') +
    '</label><input type="text" id="BillingTaxDesc" onChange="BILLING_SetSaveFlag()"></td><td><label for="BillingAddTax">' +
    iTrans('Percent Tax') +
    '</label><input type="number" step="0.1" min="0" max="99" value="0" id="BillingAddTax" onChange="BILLING_SetSaveFlag()" onKeup="ValidateTax(this)"></td></tr>';

  html +=
    '<tr class="hideable"><td><label for="BillingTaxDesc2">(' +
    iTrans('Optional 2nd Tax') +
    ') ' +
    iTrans('Description') +
    '</label><input type="text" id="BillingTaxDesc2" onChange="BILLING_SetSaveFlag()"></td><td><label for="BillingAddTax2">' +
    iTrans('Percent Tax') +
    '</label><input type="number" step="0.1" min="0" max="99" value="0" id="BillingAddTax2" onChange="BILLING_SetSaveFlag()" onKeup="ValidateTax(this)"></td></tr></tbody></table>';

  html += '<h3>' + iTrans('Bill to') + '</h3>';
  html += '<table class="limit-table">';
  html += B.Client + '<br />';
  html += B.Job.Address1 + '<br />';
  if (B.Job.Address2 != '') html += B.Job.Address2 + '<br />';
  html += B.Job.City + ', ' + B.Job.Prov_State + ' ' + B.Job.Postal + '<br />';
  html += B.Job.Country + '<br />';
  html += 'Attn: ' + B.Job.Firstname + ' ' + B.Job.Lastname;
  html += '</table>';
  html +=
    '<label for="BillingCustomerPO">' +
    iTrans('Customer PO') +
    ' (' +
    iTrans('optional') +
    ')</label><input type="text" id="BillingCustomerPO" onChange="BILLING_SetSaveFlag()" />';
  html +=
    '<label for="BillingAgreementNum">' +
    iTrans('Agreement') +
    ' # (' +
    iTrans('optional') +
    ')</label><input type="text" id="BillingAgreementNum" onChange="BILLING_SetSaveFlag()" />';
  html +=
    '<label for="BillingComments">' +
    iTrans('Comments or instructions') +
    ' (' +
    iTrans('optional') +
    ')</label><input type="text" id="BillingComments" onChange="BILLING_SetSaveFlag()" />';
  html +=
    '<button class="yellowbg" data-icon="eye" onClick="BILLING_PreviewInvoice()">' +
    iTrans('Preview your invoice') +
    '</button>';

  var date = new Date();
  date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
  B.Date = date;
  $('#BILLING_INVOICERECOMPLETE').html(html);
  $('#BILLING_INVOICERECOMPLETE').enhanceWithin();
  //Show Bill to information and input for user to add tax amount and description
  //Allow preview of final invoice
  // BILLING_INVOICERECOMPLETE //div to place expenses list
  if (EDIT_INVOICE || e) {
    $('#BillingAddTax').val(B.Tax);
    $('#BillingTaxDesc').val(B.TaxDesc);
    if (B.Tax2 != undefined) {
      $('#BillingAddTax2').val(B.Tax2);
      $('#BillingTaxDesc2').val(B.TaxDesc2);
    } else {
      $('#BillingAddTax2').val('');
      $('#BillingTaxDesc2').val('');
    }
    $('#BillingCustomerPO').val(B.CustomerPO);
    $('#BillingAgreementNum').val(B.AgreementNum);
    $('#BillingComments').val(B.Comments);
  }
}
function BILLING_SetSaveFlag() {
  BILLING_INVOICE_DATA.needSave = true;
}

function BILLING_PreviewInvoice(invoicenum) {
  if (BILLING_INVOICE_DATA.Edited != undefined) {
    if (BILLING_INVOICE_DATA.Edited == true) {
      //prompt user to either discard previously edited invoice OR view the previously edited version
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Warning'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There is a previously edited version of this invoice.  Would you like to generate a new version OR edit the previous version.  <b>If you create a new invoice the previous version will be discarded permanently</b>'
        ),
        buttons: {
          [iTrans('Create New')]: {
            click: function () {
              BILLING_INVOICE_DATA.needSave = true;
              BILLING_PreviewInvoice2(invoicenum);
            },
          },
          [iTrans('Use Old Version')]: {
            click: function () {
              //File bug report
              $.mobile.changePage($('#BILLING_INVOICEPREVIEWPage'), {
                changeHash: false,
              });
              $('#EditInvoiceElements').prop('checked', false).checkboxradio('refresh');
              BILLING_INVOICE_DATA.needSave = false;
              $('#BILLING_INVOICEPREVIEW').html(BILLING_INVOICE_DATA.RAWHTML);
              $('#BILLING_INVOICEPREVIEW').enhanceWithin();
            },
          },
        },
      });
    } else {
      BILLING_PreviewInvoice2(invoicenum);
    }
  } else {
    BILLING_PreviewInvoice2(invoicenum);
  }
}
function BILLING_PreviewInvoice2(invoicenum) {
  var InvoiceNum = invoicenum || ' {----}';
  if (BILLING_INVOICE_DATA.InvoiceNum != undefined) InvoiceNum = BILLING_INVOICE_DATA.InvoiceNum;
  cirroDB.query('BillingClient', 'clientPK != ?', '', function (headerData) {
    BILLING_HEADER_DATA = {};
    for (var i in headerData) {
      BILLING_HEADER_DATA[headerData[i].clientPK] = headerData[i].data;
    }
    ProcessInvoice(InvoiceNum);
  });
}

function makeHeaderData(B, data) {
  //console.log(B);
  //console.log(data);
  var SoldTo;
  if (B.Job == null)
    B.Job = {
      Averaged: 'None',
      BillBy: 'Hour',
      BillByOPT: '',
      Business: 'Company',
      Cfields: null,
      ClientPK: 0,
      Description: iTrans('Internal Non Revenue Flight'),
      EndDate: null,
      Finished: 0,
      JobID: 0,
      JobNum: 0,
      Min_Hours: 0,
      Name: iTrans('Non Revenue'),
      P_Hobbs: 1,
      S_Hobbs: 1,
      StartDate: null,
    };
  if (B.Client == undefined && B.Job.Business == 'Company') {
    SoldTo = iTrans('Internal Company Flight');
  } else {
    if (B.Client == undefined) {
      B.Client = B.Job.Business;
    }
    SoldTo = B.Client + '<br />';
    SoldTo += B.Job.Address1 + '<br />';
    if (B.Job.Address2 != '') SoldTo += B.Job.Address2 + '<br />';
    SoldTo += B.Job.City + ', ' + B.Job.Prov_State + ' ' + B.Job.Postal + '<br />';
    SoldTo += B.Job.Country + '<br />';
    SoldTo += 'Attn: ' + B.Job.Firstname + ' ' + B.Job.Lastname;
  }
  var JobInfo = '<table class="limit-table">';
  if (B.Job.JobNum != undefined) {
    JobInfo += '<tr class="hideable"><td><b>' + iTrans('Job') + '#</b></td><td>' + B.Job.JobNum + '</td></tr>';
  }

  //JobInfo += '<tr class="hideable"><td><b>Job #</b></td><td></td></tr>';
  JobInfo += '<tr class="hideable"><td><b>' + iTrans('Job name') + '</b></td><td>' + B.Job.Name + '</td></tr>';
  if (B.CustomerPO != '')
    JobInfo += '<tr class="hideable"><td><b>' + iTrans('Customer PO') + '</b></td><td>' + B.CustomerPO + '</td></tr>';
  if (B.AgreementNum != '')
    JobInfo += '<tr class="hideable"><td><b>' + iTrans('Agreement') + ' #</b></td><td>' + B.AgreementNum + '</td></tr>';
  if (B.Comments != '')
    JobInfo += '<tr class="hideable"><td><b>' + iTrans('Comments') + '</b></td><td>' + B.Comments + '</td></tr>';
  JobInfo += '</table>';

  var CompanyInfo = '<b>' + data.Company_Name + '</b><br />';
  CompanyInfo += data.address.address_one + '<br />';
  if (data.address.address_two != '') CompanyInfo += data.address.address_two + '<br />';
  CompanyInfo += data.address.city + ', ' + data.address.province + ' ' + data.address.postal_code + '<br />';
  CompanyInfo += data.address.country + '<br />';
  CompanyInfo += 'Phone ' + data.address.contact_phone + '<br />';
  CompanyInfo += data.address.contact_email;
  return {
    CompanyInfo: CompanyInfo,
    SoldTo: SoldTo,
    Logo: data.address.image,
    Type: data.address.type,
    JobInfo: JobInfo,
    Date: B.Date,
  };
}

function ProcessInvoice(invoicenum) {
  //console.log(invoicenum);
  var InvoiceNum = invoicenum || ' {----}';
  $.mobile.changePage($('#BILLING_INVOICEPREVIEWPage'), {
    changeHash: false,
  });
  $('#EditInvoiceElements').prop('checked', false).checkboxradio('refresh');

  var B = BILLING_INVOICE_DATA;
  //console.log(B);
  if (BILLING_REVIEW == false) {
    B.Tax = $('#BillingAddTax').val();
    B.TaxDesc = $('#BillingTaxDesc').val();
    B.Tax2 = $('#BillingAddTax2').val();
    B.TaxDesc2 = $('#BillingTaxDesc2').val();
    B.CustomerPO = $('#BillingCustomerPO').val();
    B.AgreementNum = $('#BillingAgreementNum').val();
    B.Comments = $('#BillingComments').val();
    B.TaxAmount = Math.round(B.Tax * (B.TotalPayable - B.Bill_Items.TotalNonTaxable)) / 100;
    B.TotalInvoice = Math.round(B.TotalPayable + B.TaxAmount * 100) / 100;
  }

  var data = BILLING_HEADER_DATA[0];
  if (BILLING_INVOICE_DATA.Job.customCompany == 1) {
    data = BILLING_HEADER_DATA[BILLING_INVOICE_DATA.Job.ClientPK];
  }
  var headerData = makeHeaderData(B, data);
  var iDate = B.Date;
  if (iDate.length > 0) {
    iDate = B.Date.split('-');
    if (parseInt(iDate[1]) < 10) iDate[1] = '0' + iDate[1];
    if (parseInt(iDate[2]) < 10) iDate[2] = '0' + iDate[2];
    iDate = iDate[0] + '-' + iDate[1] + '-' + iDate[2];
  }
  var html = '<page contenteditable="false"><invoice><table class="limit-table">';
  html +=
    '<tr class="hideable"><td><img style="width:350px; height:auto;" src="data:' +
    data.address.type +
    ';base64,' +
    data.address.image +
    '" /></td><td style="text-align:right; vertical-align:top; "><h3>' +
    iTrans('Invoice') +
    ' ' +
    InvoiceNum +
    '<br />' +
    iDate +
    '</h3></td></tr></table>';
  html += '<table class="limit-table">';
  html +=
    '<tr class="hideable"><td style="vertical-align:top;">' +
    headerData.CompanyInfo +
    '</td><td style="vertical-align:top;"><b>' +
    iTrans('Sold To') +
    '</b><br />' +
    headerData.SoldTo +
    '</td><td style="vertical-align:top;">' +
    headerData.JobInfo +
    '</td></tr></table>';
  html += '<br />';
  html += '<table class="limit-table wrap" style="border: 1px solid #afafaf;"><thead>';
  html +=
    '<tr class="hideable"><th style="width:100px; font-weight:bold;">' +
    iTrans('Quantity') +
    '</th><th style="width:100px; font-weight:bold;">' +
    iTrans('Unit Price') +
    '</th><th style="width:340px; font-weight:bold;">' +
    iTrans('Description') +
    '</th><th style="text-align:right; width:100px; font-weight:bold;">' +
    iTrans('Amount') +
    '</th></tr></thead><tbody>';
  var FlightReports = '';
  var QuoteReports = '';
  var Unit = iTrans('Hours');
  if (B.Job.BillBy == undefined) B.Job.BillBy = 'Hour'; //for legacy invoice data data
  if (B.Job.BillByOPT == undefined) B.Job.BillByOPT = ''; //for legacy invoice data data
  switch (B.Job.BillBy) {
    case 'Hour':
    case 'Hour2':
    case 'Minute':
    case 'HourMinute':
      Unit = iTrans('Hours');
      break;
    case 'Hobbs':
      Unit = iTrans('Hobbs Hours');
      break;
    case 'Distance':
      switch (B.Job.BillByOPT) {
        case 'KM':
          Unit = iTrans('Distance') + ' (km)';
          break;
        case 'NM':
          Unit = iTrans('Distance') + ' (NM)';
          break;
        case 'SM':
          Unit = iTrans('Distance') + ' (sm)';
          break;
        default:
          Unit = iTrans('Distance');
          break;
      }
      break;
    case 'Weight':
      switch (B.Job.BillByOPT) {
        case 'KG':
          Unit = iTrans('Weight') + ' (kg)';
          break;
        case 'LB':
          Unit = iTrans('Weight') + ' (lb)';
          break;
        default:
          Unit = iTrans('Weight');
          break;
      }

      break;
    case 'Volume':
      switch (B.Job.BillByOPT) {
        case 'litres':
          Unit = iTrans('Volume') + ' (L)';
          break;
        case 'gallonsus':
          Unit = iTrans('Volume') + ' (US gal)';
          break;
        case 'gallonsimp':
          Unit = iTrans('Volume') + ' (US imp)';
          break;
        default:
          Unit = iTrans('Volume');
          break;
      }
      break;
  }
  for (var f in B.BilledFlightReports) {
    var F = B.BilledFlightReports[f];
    F.TotalHours = Math.round(F.TotalHours * 10) / 10;
    var OtherItems = '';
    var TotalFRamount = F.TotalAmount;
    if (F.Bill_Items == undefined) {
      F.Bill_Items = {
        Fields: [],
        Total: 0,
        TotalNonTaxable: 0,
      };
    }
    var BIF_List = '';
    for (var BI in F.Bill_Items.Fields) {
      var BIF = F.Bill_Items.Fields[BI];
      if (BIF == null) continue;
      if ((B.HideEmptyCfields == 1 && BIF.Total == 0) || (B.HideEmptyCfields == 1 && BIF.Total == '')) continue;
      //OtherItems += "<br />" + BIF.Label + ": " + BIF.Total.toLocaleString('en-US', CUR);
      var BIF_Qty = parseFloat(BIF.Qty);
      if (isNaN(BIF_Qty)) BIF_Qty = 0;
      var BUnit = '';
      if (BIF.Unit != undefined) BIF_Qty = BIF_Qty + ' ' + BIF.Unit;
      BIF_List +=
        '<tr class="hideable"><td style="width:100px;">' +
        BIF_Qty +
        '</td><td style="width:100px;">' +
        toInvoiceCurrency(BIF.Cost) +
        '</td><td style="width:340px;">' +
        'FR#' +
        F.PrimaryKey +
        ' ' +
        iTrans('Item') +
        ': ' +
        BIF.Label +
        '</td><td style="text-align:right; width:100px;">' +
        toInvoiceCurrency(BIF.Total) +
        '</td></tr>';

      TotalFRamount += BIF.Total;
    }
    html +=
      '<tr class="hideable"><td style="width:100px;">' +
      F.TotalHours.toLocaleString('en-US') +
      ' ' +
      Unit +
      '</td><td style="width:100px;">' +
      toInvoiceCurrency(F.FuelRate) +
      '</td><td style="width:340px;">' +
      F.Aircraft +
      ' (' +
      F.AcType +
      ')<br />' +
      iTrans('Flight Report') +
      ' #' +
      F.PrimaryKey +
      ' on ' +
      F.Date +
      OtherItems +
      '</td><td style="text-align:right; width:100px;">' +
      toInvoiceCurrency(F.TotalAmount) +
      '</td></tr>';
    html += BIF_List;
    F.Job = B.Job;
    var dataFR = data;
    if (F.operatorPK != 0) {
      dataFR = BILLING_HEADER_DATA[F.operatorPK];
    }
    var FRheaderData = makeHeaderData(B, dataFR);
    FlightReports += BILLING_FLIGHT_REPORT_HTML(F, false, true, dataFR.flight_report_blurb, FRheaderData, f, true);
  }

  var DailyTotal = 0;
  B.TotalDaily = DailyTotal;
  if (B.Job.Daily_Rate != undefined) {
    var UniqueDateCount = 0;
    var lastDate;
    if (B.Job.Daily_Rate != 0) {
      for (var U in B.BilledFlightReports) {
        var bDate = B.BilledFlightReports[U].Date;
        if (bDate != lastDate) {
          UniqueDateCount++;
          lastDate = bDate;
        }
      }
      if (B.Job.Daily_Per_AC == 1) {
        DailyTotal = Math.round(B.Job.Daily_Rate * B.BilledFlightReports.length * 100) / 100;
        html +=
          '<tr class="hideable"><td style="width:100px;">' +
          B.BilledFlightReports.length +
          ' ' +
          iTrans('Days') +
          '</td><td style="width:100px;">' +
          toInvoiceCurrency(B.Job.Daily_Rate) +
          '</td><td style="width:340px;">' +
          iTrans('Job Rate Per Aircraft/Day') +
          '</td><td style="text-align:right; width:100px;">' +
          toInvoiceCurrency(DailyTotal) +
          '</td></tr>';
      } else {
        DailyTotal = Math.round(B.Job.Daily_Rate * UniqueDateCount * 100) / 100;
        html +=
          '<tr class="hideable"><td style="width:100px;">' +
          UniqueDateCount +
          ' ' +
          iTrans('Days') +
          '</td><td style="width:100px;">' +
          toInvoiceCurrency(B.Job.Daily_Rate) +
          '</td><td style="width:340px;">' +
          iTrans('Job Rate Per Day') +
          '</td><td style="text-align:right; width:100px;">' +
          toInvoiceCurrency(DailyTotal) +
          '</td></tr>';
      }
    }
    B.TotalDaily = Math.round((B.TotalAmount + DailyTotal) * 100) / 100;
  }
  var TotalAcCharges = B.TotalDaily + B.Bill_Items.Total + B.Bill_Items.TotalNonTaxable;
  html +=
    '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="border-top: thin solid #afafaf; width:340px;"><b>' +
    iTrans('Subtotal') +
    '</b></td><td style="text-align:right; border-top: thin solid #afafaf; width:100px;"><b>' +
    toInvoiceCurrency(TotalAcCharges) +
    '</td></tr>';

  var SubTotalQ = 0;
  var TotalQtax = 0;
  var TotalQ = 0;
  if (B.BilledQuotes != undefined) {
    if (B.BilledQuotes.length > 0) {
      html +=
        '<tr style="border-top: thin solid #afafaf"><td></td><td></td><td><b>' +
        iTrans('Quotes') +
        '</b></td><td></td></tr>';
      for (var e in B.BilledQuotes) {
        var E = B.BilledQuotes[e];
        html +=
          '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;">' +
          E.Date +
          ' ' +
          iTrans('Quote') +
          '#' +
          E.PrimaryKey +
          '</td><td style="text-align:right; width: 100px;">' +
          toInvoiceCurrency(parseFloat(E.QuoteSub)) +
          '</td></tr>';
        SubTotalQ += parseFloat(E.QuoteSub);
        TotalQtax += parseFloat(E.QuoteTax);
        TotalQ += parseFloat(E.QuoteTotal);
        QuoteReports += '<page contenteditable="false">' + BILLING_CreateQuoteInvoiceHTML(E) + '</page>';
      }
      SubTotalQ = Math.round(SubTotalQ * 100) / 100;
      TotalQtax = Math.round(TotalQtax * 100) / 100;
      TotalQ = Math.round((TotalQtax + SubTotalQ) * 100) / 100;
      html +=
        '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="border-top: thin solid #afafaf; width:340px;"><b>' +
        iTrans('Subtotal') +
        '</b></td><td style="text-align:right; border-top: thin solid #afafaf; width: 100px;"><b>' +
        toInvoiceCurrency(SubTotalQ) +
        '</b></td></tr>';
    }
  }

  B.TotalAdjustment = 0;
  if (B.Adjustments == undefined) B.Adjustments = [];
  if (B.Adjustments.length > 0) {
    html +=
      '<tr style="border-top: thin solid #afafaf"><td></td><td></td><td><b>' +
      iTrans('Adjustments') +
      '</b></td><td></td></tr>';
    for (var a in B.Adjustments) {
      var A = B.Adjustments[a];
      var Qty = 1;
      if (A.Qty != undefined) Qty = A.Qty; //for legacy invoices
      var AdjTotal = A.Amount;
      var Unit = '';
      if (A.Unit != undefined) Unit = A.Unit;
      if (Unit != '') Qty = Qty + ' ' + Unit;

      var AmountA = parseFloat(A.Amount);
      if (isNaN(AmountA)) AmountA = 0;
      AmountA = toInvoiceCurrency(AmountA, true);

      if (A.Total != undefined) AdjTotal = A.Total; //for legacy invoices
      html +=
        '<tr class="hideable"><td style="width:100px;">' +
        Qty +
        '</td><td style="width:100px;">' +
        AmountA +
        '</td><td style="width:340px;">' +
        A.Description +
        '</td><td style="text-align:right; width: 100px;"><b>' +
        toInvoiceCurrency(AdjTotal) +
        '</b></td></tr>';
      B.TotalAdjustment += parseFloat(AdjTotal);
    }
    B.TotalAdjustment = Math.round(B.TotalAdjustment * 100) / 100;
    html +=
      '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px; border-top: thin solid #afafaf;"><b>' +
      iTrans('Subtotal') +
      '</b></td><td style="text-align:right; width: 100px;border-top: thin solid #afafaf;"><b>' +
      toInvoiceCurrency(B.TotalAdjustment) +
      '</b></td></tr>';
  }

  B.BilledExpenses.sort(function (a, b) {
    if (a.Category < b.Cateogry) return -1;
    if (a.Category > b.Category) return 1;
    return 0;
  });

  var curCat;
  var catTotal = 0;
  var totalE = 0;
  if (B.BilledExpenses.length > 0) {
    html +=
      '<tr style="border-top: thin solid #afafaf"><td></td><td></td><td><b>' +
      iTrans('Expenses') +
      '</b></td><td></td></tr>';
    for (var e in B.BilledExpenses) {
      var E = B.BilledExpenses[e];
      if (e == 0) curCat = E.Category;
      if (curCat == E.Category && e != B.BilledExpenses.length - 1) catTotal += normalizeExpenseCost(E);
      else {
        if (e == B.BilledExpenses.length - 1 && curCat != E.Category) {
          //last record with different category so only one record, write old category first
          html +=
            '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;">' +
            curCat +
            '</td><td style="text-align:right; width: 100px;">' +
            toInvoiceCurrency(catTotal) +
            '</td></tr>';

          curCat = E.Category;
          catTotal = normalizeExpenseCost(E);
          html +=
            '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;">' +
            curCat +
            '</td><td style="text-align:right; width: 100px;">' +
            toInvoiceCurrency(catTotal) +
            '</td></tr>';
        } else if (e == B.BilledExpenses.length - 1 && curCat == E.Category) {
          //last record same category
          catTotal += normalizeExpenseCost(E);
          html +=
            '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;">' +
            curCat +
            '</td><td style="text-align:right; width: 100px;">' +
            toInvoiceCurrency(catTotal) +
            '</td></tr>';
        } else if (e != B.BilledExpenses.length - 1) {
          //more records different category
          html +=
            '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;">' +
            curCat +
            '</td><td style="text-align:right; width: 100px;">' +
            toInvoiceCurrency(catTotal) +
            '</td></tr>';
          curCat = E.Category;
          catTotal = normalizeExpenseCost(E);
        }
      }
      totalE += normalizeExpenseCost(E);
    }
    totalE = Math.round(totalE * 100) / 100;
    html +=
      '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="border-top: thin solid #afafaf; width:340px;"><b>' +
      iTrans('Subtotal') +
      '</b></td><td style="text-align:right; border-top: thin solid #afafaf; width: 100px;"><b>' +
      toInvoiceCurrency(totalE) +
      '</b></td></tr>';
  }

  var totalPay = totalE + B.TotalAdjustment + B.TotalDaily + B.Bill_Items.Total;
  var totalPayShow = Math.round((totalPay + SubTotalQ) * 100) / 100;
  var totalTax = Math.round((totalPay - totalE) * B.Tax) / 100;
  var totalTax2 = Math.round((totalPay - totalE) * B.Tax2) / 100;
  var invoiceTotal = totalPay + totalTax + totalTax2 + TotalQ + B.Bill_Items.TotalNonTaxable;
  invoiceTotal = Math.round(invoiceTotal * 100) / 100;
  B.INVOICE_TOTAL = invoiceTotal;

  html +=
    '<tr class="hideable" style="border-top: thin solid #afafaf"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;"><b>' +
    iTrans('Pre Tax') +
    '</b></td><td style="text-align:right; width:100px;"><b>' +
    toInvoiceCurrency(totalPayShow) +
    '</b></td></tr>';

  if (TotalQtax > 0)
    html +=
      '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;"><b>' +
      iTrans('Tax on Quotes') +
      '</b></td><td style="text-align:right; width: 100px;"><b>' +
      toInvoiceCurrency(TotalQtax) +
      '</b></td></tr>';

  if (totalTax > 0)
    html +=
      '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;"><b>' +
      iTrans('Tax') +
      ' (' +
      B.Tax +
      '% ' +
      B.TaxDesc +
      ')</b></td><td style="text-align:right; width: 100px;"><b>' +
      toInvoiceCurrency(totalTax) +
      '</b></td></tr>';

  if (totalTax2 > 0)
    html +=
      '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="width:340px;"><b>' +
      iTrans('Tax') +
      ' (' +
      B.Tax2 +
      '% ' +
      B.TaxDesc2 +
      ')</b></td><td style="text-align:right; width: 100px;"><b>' +
      toInvoiceCurrency(totalTax2) +
      '</b></td></tr>';

  html +=
    '<tr class="hideable"><td style="width:100px;"></td><td style="width:100px;"></td><td style="border-top: thin solid #afafaf; width:340px;"><b>' +
    iTrans('PAY THIS AMOUNT') +
    '</b></td><td style="text-align:right; border-top: thin solid #afafaf; width:100px;"><b>' +
    toInvoiceCurrency(invoiceTotal) +
    '</b></td></tr>';
  html += '</tbody></table><br />';
  html += '<div style="text-align:center;">' + data.invoice_blurb + '</div></invoice></page><br /><br />';
  //Todo Flight reports and Expenses with receipt pictures
  html += FlightReports;
  html += QuoteReports;
  html += ProcessExpenses(B.BilledExpenses, headerData, InvoiceNum, data.flight_report_blurb);
  $('#BILLING_INVOICEPREVIEW').html(html);
  $('#BILLING_INVOICEPREVIEW').enhanceWithin();
  //post populate QuoteReportHeaders and Signatures
  if (B.BilledQuotes != undefined) {
    if (B.BilledQuotes.length > 0) {
      for (var i in B.BilledQuotes) {
        BILLING_GetQuoteHeader(B.BilledQuotes[i]);
        BILLING_PopulateSignatureData(B.BilledQuotes[i]);
      }
    }
    if ($('#BILLING_HIDE_QuoteLegPricing').is(':checked')) {
      $('.quotedetailpricing').show();
    } else {
      $('.quotedetailpricing').hide();
    }
  }
  BILLING_InvoiceSaveStatus();
}

function BILLING_InvoiceSaveStatus() {
  if (BILLING_INVOICE_DATA.needSave == true) {
    $('#InvoicePDF_BTN').prop('disabled', true);
    $('#InvoicePDF_EmailBTN').prop('disabled', true);
    $('#InvoiceFinalize_BTN').prop('disabled', false);
    $('#EditInvoiceElements').prop('disabled', true).checkboxradio('refresh');
  } else {
    $('#InvoicePDF_BTN').prop('disabled', false);
    $('#InvoicePDF_EmailBTN').prop('disabled', false);
    $('#EditInvoiceElements').prop('disabled', false).checkboxradio('refresh');
    $('#InvoiceFinalize_BTN').prop('disabled', true);
  }
}

function ProcessExpenses(data, header, InvoiceNum, blurb) {
  //console.log(data);
  // {CompanyInfo:"", SoldTo:"", Logo:"", Type: ""};
  if (data.length == 0) return '';
  var Receipts = '';
  var html = '<page contenteditable="false"><expensereport><table class="limit-table">';
  html +=
    '<tr class="hideable"><td><img style="width:350px; height:auto;" src="data:' +
    header.Type +
    ';base64,' +
    header.Logo +
    '" /></td><td style="text-align:right; vertical-align:top; "><h3>' +
    iTrans('Expense Details') +
    '<br />' +
    iTrans('For invoice') +
    ' ' +
    InvoiceNum +
    ' ' +
    iTrans('on') +
    ' ' +
    header.Date +
    '</h3></td></tr></table>';
  html += '<table class="limit-table">';
  html +=
    '<tr class="hideable"><td style="vertical-align:top;">' +
    header.CompanyInfo +
    '</td><td style="vertical-align:top;"><b>' +
    iTrans('Sold To') +
    '</b><br />' +
    header.SoldTo +
    '</td><td style="vertical-align:top;">' +
    header.JobInfo +
    '</td></tr></table>';
  html += '<br />';
  html += '<table class="limit-table" style="border: 1px solid #afafaf;"><thead>';
  html +=
    '<tr class="hideable"><th style=" font-weight:bold;">Date</th><th style=" font-weight:bold;" >' +
    iTrans('Category') +
    '</th><th style=" font-weight:bold;">' +
    iTrans('Merchant') +
    '</th><th style=" font-weight:bold;">' +
    iTrans('Comment') +
    '</th><th style="text-align:right; font-weight:bold;">' +
    iTrans('Billable Amount') +
    '</th></tr></thead><tbody>';
  var curCat;
  var catTotal = 0;
  var total = 0;
  for (var e in data) {
    var E = data[e];
    if (E.Filename != 'N/A') {
      var filetype = E.Filename.split('.').pop().toLowerCase();
      if (filetype == 'pdf') {
        Receipts +=
          '<page contenteditable="false"><h3>' +
          E.Date +
          ': ' +
          E.Merchant +
          ' - ' +
          E.Comment +
          "</h3><div id='INVOICE_RECEIPT" +
          e +
          "'></div></page>";
        var divID = 'INVOICE_RECEIPT' + e;
        pdfjsLib.disableWorker = true; // due to CORS
        var loadingTask = pdfjsLib.getDocument('data:application/' + filetype + ';base64,' + E.Attachment);
        LoadPDFPages(loadingTask, divID);
      } else {
        filetype = 'data:image/' + filetype;
        Receipts +=
          '<page contenteditable="false"><h3>' +
          E.Date +
          ': ' +
          E.Merchant +
          ' - ' +
          E.Comment +
          "</h3><img src='" +
          filetype +
          ';base64,' +
          E.Attachment +
          "' /></page>";
      }
    } else {
      Receipts +=
        '<page contenteditable="false"><h3>' +
        E.Date +
        ': ' +
        E.Merchant +
        ' - ' +
        E.Comment +
        '<br />' +
        iTrans('NO RECEIPT PHOTO') +
        '</h3></page>';
    }
    if (e == 0) curCat = E.Category;
    if (curCat == E.Category && e != data.length - 1) {
      catTotal += E.Amount_B;
      html +=
        '<tr class="hideable"><td>' +
        E.Date +
        '</td><td>' +
        E.Category +
        '</td><td>' +
        E.Merchant +
        '</td><td>' +
        E.Comment +
        '</td><td style="text-align:right;">' +
        toInvoiceCurrency(E.Amount_B) +
        '</td></tr>';
    } else {
      if (e == data.length - 1) {
        if (curCat != E.Category) {
          html +=
            '<tr class="hideable"><td></td><td></td><td></td><td style="border-top: thin solid #afafaf"><b>' +
            curCat +
            ' ' +
            iTrans('subtotal') +
            '</b></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
            toInvoiceCurrency(catTotal) +
            '</b></td></tr>';
          curCat = E.Category;
          catTotal = E.Amount_B;
        } else catTotal += E.Amount_B;
        html +=
          '<tr class="hideable"><td>' +
          E.Date +
          '</td><td>' +
          E.Category +
          '</td><td>' +
          E.Merchant +
          '</td><td>' +
          E.Comment +
          '</td><td style="text-align:right;">' +
          toInvoiceCurrency(E.Amount_B) +
          '</td></tr>';
        html +=
          '<tr class="hideable"><td></td><td></td><td></td><td style="border-top: thin solid #afafaf"><b>' +
          curCat +
          ' ' +
          iTrans('subtotal') +
          '</b></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
          toInvoiceCurrency(catTotal) +
          '</b></td></tr>';
      } else {
        html +=
          '<tr class="hideable"><td></td><td></td><td></td><td style="border-top: thin solid #afafaf"><b>' +
          curCat +
          ' ' +
          iTrans('subtotal') +
          '</b></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
          toInvoiceCurrency(catTotal) +
          '</b></td></tr>';
        html +=
          '<tr class="hideable"><td>' +
          E.Date +
          '</td><td>' +
          E.Category +
          '</td><td>' +
          E.Merchant +
          '</td><td>' +
          E.Comment +
          '</td><td style="text-align:right;">' +
          toInvoiceCurrency(E.Amount_B) +
          '</td></tr>';
      }
      curCat = E.Category;
      catTotal = E.Amount_B;
    }
    total += E.Amount_B; //TODO ALL ADDITION SHOULD BE Amount_B !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  }
  html +=
    '<tr class="hideable"><td></td><td></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
    iTrans('Total') +
    '</b></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
    toInvoiceCurrency(total) +
    '</b></td></tr>';
  html += '</tbody></table><br />';
  html += '<center>' + blurb + '</center></expensereport></page><br /><br />';

  return html + Receipts;
}
var BILLING_RETURN_CLIENT_BALANCE = false;
var BILLING_RETURN_USER_BALANCE = false;
function BILLING_ExitPreviewInvoice() {
  if (BILLING_RETURN_USER_BALANCE) {
    $.mobile.changePage($('#BILLING_StudentBalancePage'), {
      changeHash: false,
      reverse: true,
    });
    $('#BILLING_INVOICEPREVIEW').html('');
    BILLING_RETURN_USER_BALANCE = false;
    return;
  }
  if (BILLING_RETURN_CLIENT_BALANCE) {
    $.mobile.changePage($('#BILLING_ManageClientBalance'), {
      changeHash: false,
      reverse: true,
    });
    $('#BILLING_INVOICEPREVIEW').html('');
    BILLING_RETURN_CLIENT_BALANCE = false;
    return;
  }
  //Exit back to
  if (BILLING_REVIEW == true) {
    BILLING_returnAdminInvoicing();
  } else {
    //Exit back to NewInvoicing if from Invoicing Creation
    $.mobile.changePage($('#BILLING_NEWINVOICINGCompletePage'), {
      changeHash: false,
      reverse: true,
    });
    BILLING_AdminInvoiceComplete(true);
  }
}

function BILLING_AdminSubmitInvoice() {
  if (BILLING_INVOICE_DATA.needSave == true) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('WARNING'),
      headerClose: false,
      buttonPrompt: iTrans('You have unsaved changes to the Invoice'),
      buttons: {
        [iTrans('Save Now')]: {
          click: function () {
            BILLING_SaveInvoice();
          },
        },
        [iTrans('Discard Changes')]: {
          click: function () {
            $('#BILLING_EMAIL_TO').val('');
            BILLING_AdminInvoicing(true); //reload invoice list
            INVOICE_NUMBER = null;
            BILLING_returnAdminInvoicing();
          },
        },
      },
    });
  } else {
    $('#BILLING_EMAIL_TO').val('');
    BILLING_AdminInvoicing(true); //reload invoice list
    INVOICE_NUMBER = null;
    BILLING_returnAdminInvoicing();
  }
}

function BILLING_SaveInvoice() {
  //pop up confirmation;
  BILLING_INVOICE_DATA.RAWHTML = $('#BILLING_INVOICEPREVIEW').html();
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirmation'),
    headerClose: false,
    buttonPrompt: iTrans(
      'Saving Invoice: Only changes to Adjustments and Tax will be possible in the future.  Are you sure?'
    ),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          $.mobile.loading('show', {
            theme: 'a',
          });
          var B = BILLING_INVOICE_DATA;
          B.Tax = $('#BillingAddTax').val();
          B.TaxDesc = $('#BillingTaxDesc').val();
          B.Tax2 = $('#BillingAddTax2').val();
          B.TaxDesc2 = $('#BillingTaxDesc2').val();
          B.CustomerPO = $('#BillingCustomerPO').val();
          B.AgreementNum = $('#BillingAgreementNum').val();
          B.Comments = $('#BillingComments').val();
          B.TaxAmount = Math.round(B.Tax * B.TotalPayable) / 100;
          B.TotalInvoice = Math.round((B.TotalPayable + B.TaxAmount) * 100) / 100;
          B.Amount = B.TotalInvoice;

          var submitData = JSON.stringify(BILLING_INVOICE_DATA);
          //Invoice fully submitted

          $.ajax({
            type: 'POST',
            dataType: 'json',
            timeout: 600000,
            url: BASEURL + 'menu-Billing-AJAX.php?action=SubmitInvoice',
            data: {
              Invoice: submitData,
            },
          })
            .success(function (data) {
              $.mobile.loading('hide');

              //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
              //console.log("ServerResponse: check");
              //console.log(data);
              //ensure data[0].ServerResponse == Success
              if (data[0].ServerResponse == 'Uncaught Exception') {
                ServerResponse(data[0], 'BILLING_AdminSubmitInvoice()');
              }
              if (data[0].ServerResponse == 'Success') {
                //BILLING_LOAD_ClientList();
                BILLING_REVIEW = false;
                EDIT_INVOICE = true;
                BILLING_INVOICE_DATA.needSave = false;
                BILLING_InvoiceSaveStatus();
              }
              if (data[0].ServerResponse == 'Failed') {
                $('<div>').simpledialog2({
                  mode: 'button',
                  animate: false,
                  headerText: iTrans('Failed!'),
                  headerClose: false,
                  buttonPrompt: iTrans(
                    'There was a problem Submitting the invoice.  Please try again. If this continues to happen please submit a bug report.'
                  ),
                  buttons: {
                    [iTrans('Close')]: {
                      click: function () {},
                    },
                    [iTrans('Retry')]: {
                      click: function () {
                        BILLING_AdminSubmitInvoice();
                      },
                    },
                  },
                });
              }
            })
            .fail(function (data) {
              //data.status, data.readyState, data.responseText
              //console.log(data);
              $.mobile.loading('hide');
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Failed!'),
                headerClose: false,
                buttonPrompt: iTrans(
                  'There was a problem submitting the invoice.  Please try again. If this continues to happen please submit a bug report.'
                ),
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                  [iTrans('Retry')]: {
                    click: function () {
                      BILLING_AdminSubmitInvoice();
                    },
                  },
                },
              });
            });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}

function generateFlightReportOFFLINE_EMAIL() {
  var GeneratePack = {};
  GeneratePack.filename = CURRENT_FLIGHT_REPORT.Date + '_' + CURRENT_FLIGHT_REPORT.Aircraft + '_Flight_Report';
  GeneratePack.showPDF = 0;
  GeneratePack.subject =
    iTrans('Flight Report') + ': ' + CURRENT_FLIGHT_REPORT.Date + '_' + CURRENT_FLIGHT_REPORT.Aircraft;
  GeneratePack.email = $('#BILLING_SEND_FR_TO').val();
  GeneratePack.html = $('#BILLING_FLIGHT_REPORT_REVIEW').html();
  if ($('#BILLING_SEND_FR_TO_CLIENT').is(':checked')) {
    GeneratePack.ClientEmail = CURRENT_FLIGHT_REPORT.Job.Email;
    if (!validateEmail(GeneratePack.ClientEmail)) {
      GeneratePack.ClientEmail = '0';
    }
  } else {
    GeneratePack.ClientEmail = '0';
  }
  GeneratePack.FLIGHT_REPORT_SIGNATURE_REQUESTED = FLIGHT_REPORT_SIGNATURE_REQUESTED;
  return GeneratePack;
}

var GENERATE_PDF = {
  div: 'BILLING_INVOICEPREVIEW',
  showPDF: 1,
  filename: 'test',
  subject: 'Cirro Debug',
  email: '',
};

function GeneratePDF_INVOICE(showPDF = 1) {
  //console.log(BILLING_INVOICE_DATA);
  GENERATE_PDF.filename = BILLING_INVOICE_DATA.Date + '_Invoice';
  GENERATE_PDF.showPDF = showPDF;
  GENERATE_PDF.silent = 0;
  GENERATE_PDF.Type = 'Invoice';
  GENERATE_PDF.subject = 'Invoice #' + BILLING_INVOICE_DATA.InvoiceNum + ': ' + BILLING_INVOICE_DATA.Date;
  GENERATE_PDF.email = $('#BILLING_EMAIL_TO').val();
  if (showPDF > 0) {
    GENERATE_PDF.email = '';
  }
  GENERATE_PDF.comments = $('#BILLING_EMAIL_TO_COMMENTS').val();
  GENERATE_PDF.div = 'BILLING_INVOICEPREVIEW';
  GENERATE_PDF.Header = '';
  GENERATE_PDF.Footer = '';
  $('#InvoicePDF_BTN').prop('disabled', true);
  if ($('#BILLING_EMAIL_TO_CLIENT').is(':checked') && showPDF == 0) {
    GENERATE_PDF.ClientEmail = BILLING_INVOICE_DATA.Job.Email;
    //check for valid client email address
    var clientEmails = GENERATE_PDF.ClientEmail.split(';');
    var hasInvalid = false;
    var invalidList = '';
    var validList = '';
    for (var i in clientEmails) {
      if (!validateEmail(clientEmails[i])) {
        hasInvalid = true;
        invalidList += clientEmails[i] + '; ';
      } else {
        validList += clientEmails[i] + '; ';
      }
    }
    if (hasInvalid) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Warning'),
        headerClose: false,
        buttonPrompt:
          iTrans('The Client email addresses') +
          ': [' +
          invalidList +
          '] ' +
          iTrans('are invalid.') +
          ' ' +
          iTrans('Only the following Client recipients will receive the flight report') +
          ': ' +
          validList,
        buttons: {
          [iTrans('OK')]: {
            click: function () {
              if (validList == '') {
                GENERATE_PDF.ClientEmail = '0';
              } else {
                GENERATE_PDF.ClientEmail = validList;
              }
              GeneratePDF();
            },
          },
        },
      });
    } else {
      GeneratePDF();
    }

    //GENERATE_PDF.email = CURRENT_FLIGHT_REPORT.Job.Email;
  } else {
    GENERATE_PDF.ClientEmail = '0';
    GeneratePDF();
  }
}

function GeneratePDF() {
  //validate email list
  if (GENERATE_PDF.silent == undefined) GENERATE_PDF.silent = 0;
  if (GENERATE_PDF.auto == undefined) GENERATE_PDF.auto = 0;
  if (GENERATE_PDF.ViewOnly == undefined) GENERATE_PDF.ViewOnly = 0;
  if (GENERATE_PDF.comments == undefined) GENERATE_PDF.comments = '';

  if (!ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('OFFLINE'),
      headerClose: false,
      buttonPrompt:
        '<b>' +
        iTrans(
          'PDF Reports cannot be generated offline.  If this is a flight report it will be emailed upon your next sync.'
        ) +
        '</b>',
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            $('#FlightReportPDF_BTN').prop('disabled', false);
            $('#InvoicePDF_BTN').prop('disabled', false);
          },
        },
      },
    });
    return;
  }
  if (GENERATE_PDF.email != 0 || GENERATE_PDF.auto == 1) {
    var EmailList = GENERATE_PDF.email.split(';');
    //console.log(EmailList);
    if (EmailList[0].trim() == '' && GENERATE_PDF.ClientEmail == '0' && GENERATE_PDF.auto == 0) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: iTrans(
          'No Valid email address to send to. Plese check the email list inputted / Client Email and try again.'
        ),
        buttons: {
          [iTrans('OK')]: {
            click: function () {
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
              $('#BTNQuotetoPDF').prop('disabled', false);
            },
          },
        },
      });
      return;
    }
    if (EmailList[0] != '' && GENERATE_PDF.auto == 0) {
      var hasInvalid = false;
      var invalidList = '';
      var validList = '';
      for (var i in EmailList) {
        if (!validateEmail(EmailList[i]) && EmailList[i].trim() != '') {
          hasInvalid = true;
          invalidList += EmailList[i] + '; ';
        } else {
          if (EmailList[i].trim() != '') {
            validList += EmailList[i] + '; ';
          }
        }
      }
      if (hasInvalid) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Warning'),
          headerClose: false,
          buttonPrompt:
            iTrans('The email addresses') +
            ': [' +
            invalidList +
            '] ' +
            iTrans('are invalid.') +
            ' ' +
            iTrans('Only the following recipients will receive the PDF') +
            ': ' +
            validList,
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //do nothing
              },
            },
          },
        });
        if (validList !== '') {
          GENERATE_PDF.ClientEmail = validList;
        } else {
          GENERATE_PDF.ClientEmail = '0';
        }
      }
    }
  }
  var html = $('#' + GENERATE_PDF.div).html();
  if (GENERATE_PDF.Type != 'WB' && GENERATE_PDF.Type != 'PIS') {
    GENERATE_PDF.Header = '';
    GENERATE_PDF.Footer = '';
    GENERATE_PDF.PageNums = 0;
  }
  var data = new FormData();
  data.append('html', html);
  data.append('email', GENERATE_PDF.email);
  data.append('comments', GENERATE_PDF.comments);
  data.append('ClientEmail', GENERATE_PDF.ClientEmail);
  data.append('showPDF', GENERATE_PDF.showPDF);
  data.append('filename', GENERATE_PDF.filename);
  data.append('subject', GENERATE_PDF.subject);
  data.append('SendAttr', GENERATE_PDF.SendAttr);
  data.append('Type', GENERATE_PDF.Type);
  data.append('PrimaryKey', GENERATE_PDF.PrimaryKey);
  data.append('Header', GENERATE_PDF.Header);
  data.append('Footer', GENERATE_PDF.Footer);
  data.append('PageNums', GENERATE_PDF.PageNums);
  data.append('ViewOnly', GENERATE_PDF.ViewOnly);
  data.append('auto', GENERATE_PDF.auto);
  data.append('silent', GENERATE_PDF.silent);
  if (GENERATE_PDF.Type == 'FlightReport') {
    data.append('landscape', GENERATE_PDF.landscape);
  } else {
    data.append('landscape', 0);
  }

  data.append('FLIGHT_REPORT_WAS_SIGNED', FLIGHT_REPORT_WAS_SIGNED);
  $.mobile.loading('show', {
    theme: 'a',
  });
  if (IOS || ANDROID) {
    var xhr = new XMLHttpRequest(),
      blob;
    xhr.timeout = 600000;
    xhr.open('POST', BASEURL + 'ToPDF.php?ts=0', true);
    xhr.setRequestHeader('X-Auth-Token', window.vueApp.authTools.authGetToken());
    // Set the responseType to blob
    xhr.responseType = 'blob';

    xhr.addEventListener(
      'load',
      function () {
        $.mobile.loading('hide');
        if (GENERATE_PDF.silent == 1) {
          $('#FlightReportPDF_BTN').prop('disabled', false);
          $('#InvoicePDF_BTN').prop('disabled', false);
          return;
        }
        if (xhr.status === 200) {
          if (GENERATE_PDF.showPDF == 1) {
            try {
              MobileDownload.downloadHandler(xhr, GENERATE_PDF.filename + '.pdf');
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
            } catch (e) {
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
              verbose.error('LFS', e);
            }
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Success'),
              headerClose: false,
              buttonPrompt: iTrans('Email Successfully Sent'),
              buttons: {
                [iTrans('Close')]: {
                  click: function () {
                    //acknolwedge
                    $('#FlightReportPDF_BTN').prop('disabled', false);
                    $('#InvoicePDF_BTN').prop('disabled', false);
                    $('#BTNQuotetoPDF').prop('disabled', false);
                  },
                },
              },
            });
          }
        } else {
          if (GENERATE_PDF.showPDF == 1) {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Error'),
              headerClose: false,
              buttonPrompt: iTrans("Sorry, the PDF couldn't be created."),
              buttons: {
                [iTrans('Close')]: {
                  click: function () {
                    //acknolwedge
                    $('#FlightReportPDF_BTN').prop('disabled', false);
                    $('#InvoicePDF_BTN').prop('disabled', false);
                    $('#BTNQuotetoPDF').prop('disabled', false);
                  },
                },
              },
            });
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Error'),
              headerClose: false,
              buttonPrompt: '<p>' + iTrans('Email Failed to send!') + '</p>',
              buttons: {
                [iTrans('Close')]: {
                  click: function () {
                    //acknolwedge
                    $('#FlightReportPDF_BTN').prop('disabled', false);
                    $('#InvoicePDF_BTN').prop('disabled', false);
                    $('#BTNQuotetoPDF').prop('disabled', false);
                  },
                },
              },
            });
          }
        }
        $.mobile.loading('hide');
      },
      false
    );

    xhr.addEventListener('error', function () {
      if (GENERATE_PDF.silent == 1) return;
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: '<p>' + iTrans('PDF / Email generated Failed!') + '</p>',
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //acknolwedge
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
              $('#BTNQuotetoPDF').prop('disabled', false);
            },
          },
        },
      });
      $.mobile.loading('hide');
    });
    // Send XHR
    xhr.send(data);
  } else {
    var xhr = new XMLHttpRequest();
    xhr.timeout = 600000;
    xhr.open('POST', BASEURL + 'ToPDF.php', true);
    xhr.setRequestHeader('X-Auth-Token', window.vueApp.authTools.authGetToken());
    xhr.responseType = 'blob';

    xhr.addEventListener(
      'load',
      function () {
        $.mobile.loading('hide');
        if (GENERATE_PDF.silent == 1) {
          $('#FlightReportPDF_BTN').prop('disabled', false);
          $('#InvoicePDF_BTN').prop('disabled', false);
          return;
        }
        if (xhr.status === 200) {
          //console.log("PDF retrieved");
          if (GENERATE_PDF.showPDF == 1) {
            var url = window.URL.createObjectURL(xhr.response);
            VueBridge.showPdfViewerDialog(url);

            $('#FlightReportPDF_BTN').prop('disabled', false);
            $('#InvoicePDF_BTN').prop('disabled', false);
            $('#BTNQuotetoPDF').prop('disabled', false);
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Success'),
              headerClose: false,
              buttonPrompt: '<p>' + iTrans('Emails Successfully Sent') + '</p>',
              buttons: {
                [iTrans('Close')]: {
                  click: function () {
                    //acknolwedge
                    $('#FlightReportPDF_BTN').prop('disabled', false);
                    $('#InvoicePDF_BTN').prop('disabled', false);
                    $('#BTNQuotetoPDF').prop('disabled', false);
                  },
                },
              },
            });
          }
        } else {
          if (GENERATE_PDF.showPDF == 1) {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Error'),
              headerClose: false,
              buttonPrompt:
                '<p>' +
                iTrans(
                  'Sorry, the PDF generation failed. This is usually due to connectivity issues. Please try again.'
                ) +
                '</p>',
              buttons: {
                [iTrans('Close')]: {
                  click: function () {
                    //acknolwedge
                    $('#FlightReportPDF_BTN').prop('disabled', false);
                    $('#InvoicePDF_BTN').prop('disabled', false);
                    $('#BTNQuotetoPDF').prop('disabled', false);
                  },
                },
              },
            });
          } else {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Error'),
              headerClose: false,
              buttonPrompt:
                '<p>' +
                iTrans(
                  'Sorry, emails could not be sent. This is usually due to connectivity issues. Please try again.'
                ) +
                '</p>',
              buttons: {
                [iTrans('Close')]: {
                  click: function () {
                    //acknolwedge
                    $('#FlightReportPDF_BTN').prop('disabled', false);
                    $('#InvoicePDF_BTN').prop('disabled', false);
                    $('#BTNQuotetoPDF').prop('disabled', false);
                  },
                },
              },
            });
          }
        }
        $('#FlightReportPDF_BTN').prop('disabled', false);
        $('#InvoicePDF_BTN').prop('disabled', false);
      },
      false
    );

    xhr.addEventListener('error', function () {
      $.mobile.loading('hide');
      if (GENERATE_PDF.silent == 1) {
        $('#FlightReportPDF_BTN').prop('disabled', false);
        $('#InvoicePDF_BTN').prop('disabled', false);
        return;
      }
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: '<p>' + iTrans("Sorry, the PDF couldn't be created.") + '</p>',
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //acknolwedge
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
              $('#BTNQuotetoPDF').prop('disabled', false);
            },
          },
        },
      });
    });
    // Send XHR
    xhr.send(data);
  }
  GENERATE_PDF.SendAttr = 0;
  GENERATE_PDF.Type = '';
  GENERATE_PDF.PrimaryKey = 'none';
}

function StripQuotes(string) {
  //console.log(string);
  return string.replace(/"/g, '"');
}
