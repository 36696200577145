var MAX_PHOTO_RESOLUTION = 2048;
var Dash = null;
var CELLDATA = true;
var DebugModeCount = 0;
var CANCEL_PDF_DOWNLOADS = false;
var i18n = { locale: 'en' }; //HARD CODED HERE FOR TESTING
var APP_READY_STATE = 0;
let checkedForNotificationPermission = false; // Used to stop us from running the check more than once

function toDeviceDateTime(dateTime, noSeconds = false) {
  var deviceDT = new Date(dateTime.replace(' ', 'T') + 'Z');
  if (LOCALSTORAGE.SCH_UTCmode != 'UTC') {
    deviceDT.setMinutes(deviceDT.getMinutes() - deviceDT.getTimezoneOffset());
  }
  if (noSeconds) return deviceDT.toISOString().substr(0, 16).replace('T', ' ');
  return deviceDT.toISOString().substr(0, 19).replace('T', ' ');
}

function getTZ_Offset(timeZone = 'UTC', date = new Date()) {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (utcDate.getTime() - tzDate.getTime()) / 6e4;
}
async function checkPersistedStorage() {
  const isPersisted = await navigator.storage.persisted();
  console.log(`Persisted storage granted: ${isPersisted}`);
  if (!isPersisted) {
    const askPersist = await navigator.storage.persist();
    console.log(`How about now is storage granted: ${askPersist}`);
  }
}

/**
 * Checks to see if browser supports notifications and permissions are granted
 * @returns {Promise<void>}
 */

async function checkNotificationPerms() {
  if (IOS || ANDROID) {
    return;
  }

  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    window.toaster.show(
      iTrans('This browser does not support desktop notifications. This may cause Cirro to not function correctly.'),
      0
    );
    return;
  }

  const permission = await Notification.requestPermission();

  if (permission !== 'granted') {
    // if the user hasn't granted permission, we inform them that things will break
    deniedNotificationToast();
    return;
  }
  checkedForNotificationPermission = true;
}

/**
 * If browserPerms global variable is true, call checkPersistedStorage
 */
async function checkStorageQuota() {
  if (checkedForNotificationPermission) {
    checkPersistedStorage();
  }
}
function deniedNotificationToast() {
  window.toaster.show(
    `
    <i class="fal fa-2x fa-exclamation-triangle mr-5 error--text"></i>
    <span class="error--text">
    ${iTrans('Cirro requires both the "Notifications" and "Store Files" permissions to store data on your device.')}
    <br/>
    ${iTrans('If you do not grant these permissions, some modules will be unavailable or function incorrectly.')}
    <br/>
    <a href="https://air-suite.com/how-to-reset-cirros-web-permission/" target="_blank">
      ${iTrans('How do I grant these permissions?')}
    </a>
    </span> 
    `,
    0
  );
}

function SetDebugMode() {
  DebugModeCount++;
  if (DebugModeCount == 5 && GPS_DEBUG == false) {
    CANCEL_PDF_DOWNLOADS = true;
    GPS_DEBUG = true;
    DebugModeCount = 0;
    localStorageDB.setItem('GPS_DEBUG', 'true');
    //console.log("debug enabled");
    alert('Debugging Mode Enabled');
    //audio_download = new Audio('download.mp3');
    //audio_download.play();
    var script = document.createElement('script');
    script.src = 'https://rawgit.com/paulirish/memory-stats.js/master/bookmarklet.js';
    document.head.appendChild(script);
    $('#CesiumSelectHLDR').show();
    $('#CesiumTriggerMbTileSelectBTN').show();
  }
  if (DebugModeCount == 5 && GPS_DEBUG == true) {
    DebugModeCount = 0;
    GPS_DEBUG = false;
    localStorageDB.setItem('GPS_DEBUG', 'false');
    alert('Debugging Mode Disabled');
  }
}

var TableSorter_SORT = {};
function AddTableSorter(id, defaultSort = null) {
  /*
  $('#' + id).tablesorter({numberSorter: TableSorter_NaturalOrder}).bind("sortEnd",function(e, t) {
      console.log(e);
    console.log(t);
    });
    */
  var sortList = [];
  if (TableSorter_SORT[id] != undefined) {
    sortList = TableSorter_SORT[id];
  } else if (defaultSort != null) sortList = defaultSort;
  var SortUsing = $.tablesorter.sortNatural;
  switch (id) {
    case 'BIT_FR1':
    case 'BIT_FR2':
    case 'BIT_FR3':
    case 'BIT_FR3':
    case 'BIT_FR3BB':
    case 'BIT_FR3B':
    case 'BIT_FR4':
    case 'BIT_FR5':
      SortUsing = TableSorter_textSorter;
      break;
  }
  $('#' + id).tablesorter({
    //numberSorter: TableSorter_NaturalOrder,
    //numberSorter: TableSorter_numberSorter,
    //textSorter: TableSorter_textSorter,
    sortList: sortList,
    sortReset: true,
    widgets: ['currentSort', 'staticRow'],
    widgetOptions: {
      currentSort_callback: function (config, values) {
        // values also contained in config.currentSort
        // update display
        console.log(values);
        var SortArray = [];
        for (var i in values) {
          var I = values[i];
          if (I == 'asc') SortArray.push([i, 0]);
          if (I == 'desc') SortArray.push([i, 1]);
        }
        TableSorter_SORT[id] = SortArray;
      },
    },
  });
}
function TableSorter_textSorter(a, b) {
  return $.tablesorter.sortNatural(a, b);
}
function TableSorter_numberSorter(a, b) {
  console.log(a);
  a = a.toString();
  b = b.toString();
  console.log(a);
  return $.tablesorter.sortText(a, b);
}
function TableSorter_NaturalOrder(a, b) {
  console.log(a);
  var re = /(^-?[0-9]+(\.?[0-9]*)[df]?e?[0-9]?$|^0x[0-9a-f]+$|[0-9]+)/gi,
    sre = /(^[ ]*|[ ]*$)/g,
    dre =
      /(^([\w ]+,?[\w ]+)?[\w ]+,?[\w ]+\d+:\d+(:\d+)?[\w ]?|^\d{1,4}[\/\-]\d{1,4}[\/\-]\d{1,4}|^\w+, \w+ \d+, \d{4})/,
    hre = /^0x[0-9a-f]+$/i,
    ore = /^0/,
    // convert all to strings and trim()
    x = a.toString().replace(sre, '') || '',
    y = b.toString().replace(sre, '') || '',
    // chunk/tokenize
    xN = x.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0'),
    yN = y.replace(re, '\0$1\0').replace(/\0$/, '').replace(/^\0/, '').split('\0'),
    // numeric, hex or date detection
    xD = parseInt(x.match(hre)) || (xN.length != 1 && x.match(dre) && Date.parse(x)),
    yD = parseInt(y.match(hre)) || (xD && y.match(dre) && Date.parse(y)) || null;
  // first try and sort Hex codes or Dates
  if (yD)
    if (xD < yD) return -1;
    else if (xD > yD) return 1;
  // natural sorting through split numeric strings and default strings
  for (var cLoc = 0, numS = Math.max(xN.length, yN.length); cLoc < numS; cLoc++) {
    // find floats not starting with '0', string or 0 if not defined (Clint Priest)
    oFxNcL = (!(xN[cLoc] || '').match(ore) && parseFloat(xN[cLoc])) || xN[cLoc] || 0;
    oFyNcL = (!(yN[cLoc] || '').match(ore) && parseFloat(yN[cLoc])) || yN[cLoc] || 0;
    // handle numeric vs string comparison - number < string - (Kyle Adams)
    if (isNaN(oFxNcL) !== isNaN(oFyNcL)) return isNaN(oFxNcL) ? 1 : -1;
    // rely on string comparison if different types - i.e. '02' < 2 != '02' < '2'
    else if (typeof oFxNcL !== typeof oFyNcL) {
      oFxNcL += '';
      oFyNcL += '';
    }
    if (oFxNcL < oFyNcL) return -1;
    if (oFxNcL > oFyNcL) return 1;
  }
  return 0;
}
function escapeHtml(text) {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;',
  };
  if (typeof text === 'string' || text instanceof String) {
    return text.replace(/[&<>"']/g, function (m) {
      return map[m];
    });
  } else {
    return text;
  }
}

function stringToDateObject(date) {
  if (date == null) {
    return new Date(0);
  }

  var time = date.split(' ');
  if (time.length > 1) {
    return new Date(time[0] + 'T' + time[1] + 'Z');
  } else {
    return new Date(date + 'Z');
  }
}

function dateToStringObject(date) {
  if (!isValidDate(date)) {
    return '0001-01-01 00:00:00';
  }

  var time = date.toISOString().substr(0, 19);
  time = time.replace('T', ' ');
  return time;
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

function decimalPlaces(num) {
  var match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 0;
  }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0) -
      // Adjust for scientific notation.
      (match[2] ? +match[2] : 0)
  );
}

function updateMenuIconBadges() {
  VueBridge.updateMenu('UPDATE MENU BADGES');

  if (!ONLINE) {
    $('#unreported-badge').hide();
    $('#documents-badge').hide();
    return;
  }
  var url = BASEURL + 'menu-Badges-AJAX.php';
  if (!LOW_BANDWIDTH) popItinAssetTemplates();

  $.getJSON(url).then(
    function (data) {
      var count = data.unreported;
      if (data && count) {
        $('#unreported-badge-number').text(count).show();
      } else {
        $('#unreported-badge-number').hide();
      }

      count = data.unreportedTracker;
      if (data && count) {
        $('#unreportedTracker-badge-number').text(count).show();
      } else {
        $('#unreportedTracker-badge-number').hide();
      }

      console.log('VERSION', ONLINE, data.version, WEB_VERSION);

      if (SCHEDULER > 0 && ONLINE && !LOW_BANDWIDTH) {
        getOfflineScheduleData();
      } //sync offline scheduler data
      checkStorageQuota();
    },
    function () {
      $('#unreported-badge').hide();
    }
  );
}
function OpenCirroAppStore() {
  if (ANDROID) {
    navigator.app.loadUrl('market://details?id=com.airsuite.cirro', { openExternal: true }); //external url open google play
  }
  if (IOS) {
    window.open('itms-apps://itunes.apple.com/app/id1077004326', '_system'); //IOS open appstore
  }
  if (ELECTRON) {
    window.open('http://apps.air-suite.com', '_system');
  }
}
function getOfflineScheduleData() {
  var Start = new Date();
  Start.setDate(Start.getDate() - 31);
  Start = Start.toISOString().substring(0, 10) + ' 00:00:00';
  var End = new Date();
  End.setDate(End.getDate() + 60);
  End = End.toISOString().substring(0, 10) + ' 00:00:00';
  var Now = new Date();
  $.getJSON(BASEURL + 'menu-Scheduler-AJAX.php?action=getOfflineResources', function (json_data) {
    if (json_data[0].ServerResponse != undefined) {
      if (json_data[0].ServerResponse == 'Offline') {
        //console.log("Offline Scheduler Not Available");
      }
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(json_data[0], 'getOfflineSchedulerData');
        SCHschedulerBack();
      }
      if (json_data[0].ServerResponse == 'Not Logged In') {
        NOTLOGGEDIN();
      }
      if (json_data[0].ServerResponse == 'None') {
      } //Do Nothing
    } else {
      //console.log("Received Bookings");
      //var sEvent = '<div style="color:DARKGOLDENROD;">ABC Drilling Inc - Tentative<br />Slave Lake Job</div><div style="color:BLACK;">C-FBHM</div><div style="color:MAROON;">Mike Kleywegt - Pending</div><div style="color:DARKGREEN;">Doug Carlson - Acknowledged</div>';
      //var details

      var Resources = json_data[0].Resources;
      //store
      var Data = {
        PrimaryKey: 1,
        Resources: Resources,
        AircraftOnly: json_data[0].AircraftOnly,
        StaffOnly: json_data[0].StaffOnly,
      };
      refreshSchedulerData(Data); //in menu-Scheduler.js
    }
  }).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);
    //fail silently
    //console.log("Communication Error Retrieving events");
  });
}

var DEPARTMENT_ASSOCIATIONS = {
  type: '',
  divID: '',
  typePK: 0,
  Associations: [],
  departments: [],
};
function getDepartmentAssociations(type, typePK, callback) {
  if (ONLINE) {
    $.getJSON(
      BASEURL + 'sync-AJAX.php?action=getDepartmentAssociations&type=' + type + '&typePK=' + typePK,
      function (json_data) {
        callback(true, json_data);
      }
    ).fail(function (jqXHR, status, error) {
      callback(false, error);
    });
  } else {
    callback(false, iTrans('Cannot Retreive Department Associations while offline'));
  }
}

function setDepartmentAssociation(type, typePK, departmentID, callback) {
  if (ONLINE) {
    $.getJSON(
      BASEURL +
        'sync-AJAX.php?action=setDepartmentAssociation&type=' +
        type +
        '&typePK=' +
        typePK +
        '&departmentID=' +
        departmentID,
      function (json_data) {
        if (json_data.ServerResponse != 'Success') {
          callback(false, json_data[0].Exception);
        } else {
          callback(true, json_data);
        }
      }
    ).fail(function (jqXHR, status, error) {
      callback(false, error);
    });
  } else {
    callback(false, iTrans('Cannot Set Department Associations while offline'));
  }
}

function removeDepartmentAssociation(type, typePK, departmentID, callback) {
  if (ONLINE) {
    var name = '';
    for (var i in DEPARTMENT_ASSOCIATIONS.Associations) {
      var I = DEPARTMENT_ASSOCIATIONS.Associations[i];
      if (I.departmentID == departmentID) {
        name = I.name;
        break;
      }
    }
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Confirm'),
      headerClose: false,
      buttonPrompt:
        '<b>' + iTrans('Are you sure you want to remove the association with department: ') + ' ' + name + '?</b>',
      buttons: {
        [iTrans('Remove')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'sync-AJAX.php?action=removeDepartmentAssociation&type=' +
                type +
                '&typePK=' +
                typePK +
                '&departmentID=' +
                departmentID,
              function (json_data) {
                callback(true, json_data);
              }
            ).fail(function (jqXHR, status, error) {
              callback(false, error);
            });
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  } else {
    callback(false, iTrans('Cannot Remove Department Associations while offline'));
  }
}

function getDepartmentHTML(type, typePK, divID) {
  DEPARTMENT_ASSOCIATIONS.type = type;
  DEPARTMENT_ASSOCIATIONS.typePK = typePK;
  DEPARTMENT_ASSOCIATIONS.divID = divID;
  getDepartmentAssociations(type, typePK, updateDepartmentHTML);
}

function updateDepartmentHTML(success, json_data) {
  if (success) {
    var html = '';
    var Associations = json_data.Associations;
    DEPARTMENT_ASSOCIATIONS.Associations = Associations;
    DEPARTMENT_ASSOCIATIONS.departments = json_data.Departments;
    if (Associations.length == 0) {
      html += '<h3>' + iTrans('No Department Associations') + '</h3>';
    } else {
      html += '<h3>' + iTrans('Department Associations') + '</h3>';
      html +=
        '<table class="limit-table first-td-fill"><thead><tr><th>' +
        iTrans('Name') +
        '</th><th>' +
        iTrans('Del') +
        '</th></tr></thead><tbody';
      for (var a in Associations) {
        var A = Associations[a];
        html +=
          '<tr><td>' +
          A.name +
          '</td><td><div class="vertical-center-container"><button data-mini="true" class="action-btn" onClick="removeDepartmentAssociation(\'' +
          DEPARTMENT_ASSOCIATIONS.type +
          "', " +
          DEPARTMENT_ASSOCIATIONS.typePK +
          ',' +
          A.departmentID +
          ', updateDepartmentHTML)"><img src="./images/delete.svg" /></button> </div></td></tr>';
      }
      html += '</tbody></table>';
    }
    html +=
      '<button onClick="addDepartmentAssociation()" class="greenbg" data-mini="true" data-icon="plus" >' +
      iTrans('Add Department Association') +
      '</button>';
    $('#' + DEPARTMENT_ASSOCIATIONS.divID).html(html);
    $('#' + DEPARTMENT_ASSOCIATIONS.divID).enhanceWithin();
  } else {
    $('#' + DEPARTMENT_ASSOCIATIONS.divID).html(json_data);
  }
}

function addDepartmentAssociation() {
  var html = '<h3>' + iTrans('Add Department Association') + '</h3>';
  html +=
    '<table class="limit-table first-td-fill"><thead><tr><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Add') +
    '</th></tr></thead><tbody';
  var departments = DEPARTMENT_ASSOCIATIONS.departments;
  for (var d in departments) {
    var D = departments[d];
    html +=
      '<tr><td>' +
      D.name +
      '</td><td><div class="vertical-center-container"><button data-mini="true" class="action-btn" onClick="setDepartmentAssociation(\'' +
      DEPARTMENT_ASSOCIATIONS.type +
      "', " +
      DEPARTMENT_ASSOCIATIONS.typePK +
      ',' +
      D.id +
      ', updateDepartmentHTML)"><img src="./images/Check_mark_green.svg" /></button> </div> </td></tr>';
  }
  html += '</tbody></table>';
  html +=
    '<button onClick="getDepartmentAssociations(\'' +
    DEPARTMENT_ASSOCIATIONS.type +
    "', " +
    DEPARTMENT_ASSOCIATIONS.typePK +
    ', updateDepartmentHTML);" class="redbg" data-mini="true" data-icon="minus" >' +
    iTrans('Cancel New Department Association') +
    '</button>';
  $('#' + DEPARTMENT_ASSOCIATIONS.divID).html(html);
  $('#' + DEPARTMENT_ASSOCIATIONS.divID).enhanceWithin();
}
function OfflineTriggered(_whoCalledMe = 'Listener') {
  verbose.log('Vue', 'OFFLINE TRIGGERED by: ', _whoCalledMe, 'Current Online status: ', ONLINE);

  // check every 5s for connectivity, or 1 mins if in lowBandWidth mode
  clearTimeout(ONLINE_CHECK);
  ONLINE_CHECK = setTimeout(CheckOnlineStatus, LOW_BANDWIDTH ? 60000 : 5000);

  // if already offline, or has not gotLocaleStorage,update the menu buttons to be sure, then return
  if (!ONLINE || !GOTLOCALSTORAGE) {
    UpdateMenuButtons(true);
    return;
  }

  ONLINE = false;
  window.dispatchEvent(new Event('internet-offline'));

  $('#unreported-badge').hide();
  $('#Status_Text').html(iTrans('Status: Offline'));
  $('#Duty_Status').html(iTrans('Offline'));
  $('#SCH_BANNER').html(iTrans('Schedule - Read Only'));
  UpdateMenuButtons(true); //disable menu buttons

  // disable module sepcific functionality
  if (MAPPING == 1 && mapb !== null) {
    clearTimeout(updateTimeout); //prevent map for attempted refresh while offline
    offlineDataOn(true);
  }
  if (SCHEDULER == 1 && dp != null) {
    updateSchedulerListeners();
  }

  $('#offlineDataBTN').prop('disabled', true);

  if (toasterID_OfflineBillingData != null) {
    toaster.remove(toasterID_OfflineBillingData);
  }
  if (SYNCING) {
    IOS_postMessage({
      Message: 'SyncStatus',
      status: 'BadConnection',
    });
  }
}

function OnlineTriggered(_whoCalledMe = 'Listener') {
  // check every 30s for continued connectivity, or 1 mins if in lowBandWidth mode
  clearTimeout(ONLINE_CHECK);
  ONLINE_CHECK = setTimeout(CheckOnlineStatus, LOW_BANDWIDTH ? 60000 : 30000);

  // if already online, or has not gotLocaleStorage, return
  if (ONLINE || !GOTLOCALSTORAGE) {
    return;
  }

  verbose.log('Vue', 'ONLINE TRIGGERED by: ', _whoCalledMe, 'Current Online status: ', ONLINE);

  ONLINE = true;
  window.dispatchEvent(new Event('internet-online'));

  // re-enable menu buttons, set status text
  $('#Status_Text').html(iTrans('Status: Online'));
  $('#Duty_Status').html(iTrans('Synced'));
  $('#SCH_BANNER').html(iTrans('Schedule'));
  UpdateMenuButtons(false);

  // re-enable module specific things
  if (SCHEDULER == 1 && dp != null) {
    updateSchedulerListeners();
  }
  if (MAPPING == 1 && mapb !== null) {
    getAC(); //reactivate map refresh timer if map initialized
    offlineDataOn(true); //trigger online tiles upate if necessary
  }
  if (CHARTS == 1) {
    $('#offlineDataBTN').prop('disabled', false);
  }

  // Verify login status
  $.getJSON(BASEURL + 'sync-AJAX.php?action=getLastSync', function (json_data) {
    //local database is empty and offline fail with message
    if (json_data[0].ServerResponse == 'Offline') {
      return;
    }

    if (json_data[0].ServerResponse == 'Success') {
      // we're logged in and dont' need to attempt re-login, do a sync
      console.log('Already Logged In');

      if (LOW_BANDWIDTH) {
        CheckOfflineSendItems();
      } else {
        BackgroundSync();
      }
      return;
    }

    if (json_data[0].ServerResponse == 'Not Logged In') {
      AutoCheck = true;
      AutoLogin(); //attempt autologin to confirm credentials
    }
  }).fail(function (jqXHR, status, error) {
    //crappy connection do nothing fo rnow
    return;
  });
}

function UpdateMenuButtons(_isOffline) {
  //true or false for online or offline _isOfflines
  //console.log("Disable Buttons: " + _isOffline);
  $('.btn-online-only').prop('disabled', _isOffline);
  $('.sama-fp-buttons button').prop('disabled', _isOffline);

  if (!_isOffline) {
    if (SAMA.Navigator != null) {
      SAMA.Navigator.Online();
    }
  } else {
    if (SAMA.Navigator != null) {
      SAMA.Navigator.Offline();
    }
  }

  $('#GFABTN').prop('disabled', _isOffline);
  $('#PROGBTN').prop('disabled', _isOffline);
  $('#SATBTN').prop('disabled', _isOffline);

  // $("#BILLING_NewFlightReport").prop('disabled', _isOffline);
  //$("#BILLING_Expenses").prop('disabled', _isOffline);
  $('#assets').prop('disabled', _isOffline);
  //$("#schedulerBTN").prop('disabled', _isOffline);
  if (CORDOVA && LOCALSTORAGE.OFFLINE_DB_TDG_2021 != 'Available') {
    $('#TDGmenuBTN').prop('disabled', _isOffline);
  }
  $('#SMSmenu').prop('disabled', _isOffline);
  $('#PersonalItinBTN').prop('disabled', _isOffline); //for offline create new flight dev
  $('#ManualSync').prop('disabled', _isOffline);
  $('#Manage').prop('disabled', _isOffline);
  $('#Administration').prop('disabled', _isOffline);

  $('#BILLING_AdminInvoicing').prop('disabled', _isOffline);
  $('#BILLING_ClientListBTN').prop('disabled', _isOffline);
  $('#BILLING_AdminExpenses').prop('disabled', _isOffline);
  $('#BILLING_AdminQuoting').prop('disabled', _isOffline);

  $('#SCHManSyncBTN').prop('disabled', _isOffline);
  //$("#FlightReportPDF_BTN").prop('disabled', _isOffline);
  //$("#Billing_SaveFlightReportBTN").prop('disabled', _isOffline);
  //$("#FlightReportPDF_BTN").prop("disabled", _isOffline);
  try {
    if (_isOffline) {
      $('#BILLING_ADMIN_FLIGHTS').checkboxradio('disable');
      $('#BILLING_ARCHIVED').checkboxradio('disable');
    } else {
      $('#BILLING_ADMIN_FLIGHTS').checkboxradio('enable');
      $('#BILLING_ARCHIVED').checkboxradio('enable');
    }
  } catch (e) {
    //console.log(e);
  }
  try {
    if (_isOffline) {
      $('#PersonalItinBTN').checkboxradio('disable');
    } else {
      $('#PersonalItinBTN').checkboxradio('enable');
    }
  } catch (e) {
    //console.log(e);
  }
}

async function Logout(e) {
  if (LOCALSTORAGE.FDT_Sync_Error == 1) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('FDT Not Synced'),
      headerClose: false,
      buttonPrompt: iTrans(
        'The most recent attempt to sync your FDT data failed. Logging out will cause any unsynced records to be lost.'
      ),
      buttons: {
        [iTrans('Force Logout')]: {
          click: function () {
            localStorageDB.setItem('FDT_Sync_Error', '0');
            LOCALSTORAGE.FDT_Sync_Error = 0;
            Logout(e);
          },
        },
        [iTrans('Cancel Logout')]: {
          click: function () {
            //do nothing
          },
        },
        [iTrans('Attempt FDT Sync Now')]: {
          click: function () {
            BackgroundSync(true);
          },
        },
      },
    });

    return;
  }

  $('#SyncProgressText').hide();

  goToPage('#Initialization_Page');

  const initDialog = $('#initDialog');
  if (e == 'RemoveProfile') {
    //remove flight duty times
    $(document).trigger('simpledialog', { method: 'close' });
  }

  const ssoAuthenticated = VueBridge.getKeyValue(VueBridge.KeyValueEnum.ssoAuthenticated, false);
  if (e != 'KeepGroupData') {
    initDialog.html(iTrans('Removing User and Group Data') + '...');
    LOCALSTORAGE.INIT_Login = 1;

    // This removes EVERYTHING.
    for (let i = 0; i < 10; i++) {
      try {
        await vueApp.utilities.maintenance.clearApplicationData(ssoAuthenticated);
        break;
      } catch (e) {
        console.error(e);
        await VueBridge.wait(250);
      }
    }

    return await handleFinalLogoutSteps(ssoAuthenticated);
  }

  initDialog.html(iTrans('Logging Out, Removing User Personal Data') + '...');

  for (let i = 0; i < 10; i++) {
    try {
      await internalClearUserData();
      break;
    } catch (e) {
      console.error(e);
      await VueBridge.wait(250);
    }
  }

  await handleFinalLogoutSteps(ssoAuthenticated);
}

/**
 * Perform the final steps of logout
 * @param ssoAuthenticated
 * @returns {Promise<void>}
 */
async function handleFinalLogoutSteps(ssoAuthenticated) {
  if (!ssoAuthenticated) {
    await attemptRemoteLogout();
    await VueBridge.dropSession();
    restartCirro();
    return;
  }

  const oauthProviderLogoutUrl = await RemoteData.get('api/v2/users/me/oauth/logout', null, null, 'text');
  await VueBridge.dropSession();

  if (oauthProviderLogoutUrl) {
    if (CORDOVA) {
      cordova.InAppBrowser.open(oauthProviderLogoutUrl, '_blank', 'location=yes');
    } else {
      window.open(oauthProviderLogoutUrl);
    }
  }

  VueBridge.enterVue('/login/logged-out');
}

async function internalClearUserData() {
  console.log('clearing fdt');
  await FdtDaysV4Table.query().dropTable();
  await FdtRecsV3Table.query().dropTable();
  await FdtStaffV3Table.query().dropTable();

  // Remove Billing data (for non-billing clients)
  console.log('remove billing data');
  await BillingDataTable.query().delete('Itinerary');
  await BillingDataTable.query().delete('JobsCrew');
  await BillingDataTable.query().delete('Scripts');
  await BillingDataTable.query().delete('OFFLINE_SYNC');
  await BillingDataTable.query().delete('OFFLINE_SYNC_EXPENSE');

  // Reset LocalStorage
  console.log('reset localstore');
  await LocalStorageTable.query().raw(`
      update LocalStorageData
      set "text" = '1'
      where "id" = 'To_Login'
         or "id" = 'INIT_Login'
         or "id" = 'INITIAL_SYNC';
  `);

  await LocalStorageTable.query().raw(`
      update LocalStorageData
      set "text" = ''
      where "id" in ('FDT_Defaults', 'username', 'password', 'chatname', 'USER_PK', 'USERTYPE', 'USER_RIGHTS');
  `);

  LOCALSTORAGE.INITIAL_SYNC = 1;
  LOCALSTORAGE.INIT_Login = 1;

  const oldTs = new Date('1980-01-01T00:00:00Z');
  const now = new Date();

  console.log('reset sync');

  await update_Sync('LastSCHEDULEsync', now);
  await update_Sync('LastLIBRARYsync', now);
  await update_Sync('LastSYSTEMsync', now);
  await update_Sync('LastFDTsync', now);
  await update_Sync('LastFDTsync', now);
  await update_Sync('LastMANIFESTsync', oldTs);
  await update_Sync('LastPASSENGERsync', oldTs);
  await update_Sync('CurMANIFESTsync', oldTs);
  await update_Sync('CurPASSENGERsync', oldTs);

  // drop billing database and all offline billing data
  if (GROUP_DATA.FlightReports > 0) {
    await BillingDataTable.query().dropTable();
  }

  console.log('clearing user data');

  await vueApp.utilities.maintenance.clearUserData();
}

async function attemptRemoteLogout() {
  const err = iTrans('Local Logout only') + '.<br /> ' + iTrans('Unable to Logout from Remote Server') + '.';

  try {
    const data = await RemoteData.post('api/v2/users/me/sign-out', {});

    if (data[0].ServerResponse === 'Offline') {
      ServerResponse({ ServerResponse: 'Connection Error' }, err);
    } else if (data[0].ServerResponse === 'Success') {
    } else {
      ServerResponse({ ServerResponse: 'Server Error' }, err);
    }
  } catch (e) {
    ServerResponse({ ServerResponse: 'Connection Error' }, err);
  }
}

function clearUserData() {
  $.mobile.changePage($('#Initialization_Page'), {
    reverse: true,
    changeHash: false,
  });

  $('#initDialog').html(iTrans('Please Wait...'));
  $('#clearUserData').attr('disabled', 'disabled');
  $('#loginBTN').attr('disabled', 'disabled');

  vueApp.utilities.maintenance.clearUserData();
}

function getPilotList() {
  cirroDB.query('AC', 'ident != ?', '', function (e) {
    ALLAC_data = [];
    if (e === false) {
      e = [];
    } else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data.push(item);
      }
    }
    PilotsTable.query()
      .select()
      .then(function (e) {
        if (e === false) {
          PILOT_data = [];
        } else {
          PILOT_data = e;
        }
      });
  });
}

var pages = [],
  heights = [],
  width = 0,
  height = 0,
  currentPage = 1;
var scale = 1.5;

function draw(id) {
  var canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  for (var i = 0; i < pages.length; i++) {
    ctx.putImageData(pages[i], 0, heights[i]);
  }
  $('#' + id).append("<img src='" + canvas.toDataURL() + "' />");
  //document.body.appendChild(canvas);
}

function setStartupPage() {
  LoadAircraftTypes();
  updateVersionString();
  if (GROUP_DATA.group == 'CPS') {
    //Disable All FDT Ops Spec For this group until we add group side flags for them
    OS92 = false; //Increase in Flight Time
    OS93 = false; //Increase in Flight Duty Time 15 hour duty day
    OS94 = false; //Time free from duty - 42 consecutive working days
    OS95 = false; //Program of Controlled Rest on the Flight Deck
  }

  goToStartupPage();

  if (!ONLINE) {
    //console.log("Status: OFFLINE");
    if (MAPPING == 1 && mapb !== null) {
      clearTimeout(updateTimeout);
    } //prevent map for attempted refresh while offline
  }
}

function goToStartupPage() {
  let page = LOCALSTORAGE.STARTUP;
  if (page == null) {
    page = 'HomeScreen';
  }

  const curpage = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (curpage === 'Initialization_Page' || curpage === 'Login_Page') {
    if (LOCALSTORAGE.ActiveMap == 'true' && LOCALSTORAGE.GPS != null && LOCALSTORAGE.GPS == 'true') {
      page = 'Map';
      if (LOCALSTORAGE.GPS_DEBUG == 'true') {
        GPS_DEBUG = true;
      }
    } else {
      localStorageDB.setItem('GPS_DEBUG', 'false');
    }

    switch (page) {
      case 'dashboard':
        Dash.GoToDash();
        return;
      case 'HomeScreen':
        cirroHOME();
        return;
      case 'Map':
        clickHandler('Map');
        return;
      case 'Following':
        clickHandler('Following');
        return;
    }
  }
}

function LoadAircraftTypes() {
  localStorageDB.getItem('AcTypeList', function (e) {
    try {
      if (e == null) FDT_AC_TYPE_DATA = [];
      else FDT_AC_TYPE_DATA = JSON.parse(e);
    } catch (e) {
      FDT_AC_TYPE_DATA = [];
    }
    //console.log("AcTypeListLoaded");
    if (FDT_AC_TYPE_DATA.length == 0) {
      if (ONLINE) {
        $.getJSON(BASEURL + 'sync-AJAX.php?action=AcTypeList', function (json_data) {
          if (json_data[0].ServerResponse == 'Offline') {
            alert('Could not retrieve AcTypeList');
          } else {
            if (json_data[0].ServerResponse == 'No Aircraft') {
              SystemSyncError = false;
              localStorageDB.setItem('AcTypeList', '[]');
            } else {
              localStorageDB.setItem('AcTypeList', JSON.stringify(json_data));
              FDT_AC_TYPE_DATA = json_data;
            }
          }
        }).fail(function (jqXHR, status, error) {
          alert('Could not retrieve AcTypeList');
        });
      }
    }
  });
}

function DSToffset(date) {
  ////console.log(date);
  if (SCHSTATE.timezone == 0) return 0;
  var arr = date.split(' ');
  var Rdate = new Date(arr[0] + 'T' + arr[1] + 'Z');
  if (arr.length == 1) {
    Rdate = new Date(date + 'Z');
  }

  var offset = SCHSTATE.timezone - Rdate.getTimezoneOffset();
  var minutes = -SCHSTATE.timezone + offset;
  ////console.log(minutes);
  if (isNaN(minutes)) {
    return -SCHSTATE.timezone;
  } else {
    return minutes;
  }
}

function toDSToffset(date) {
  console.log(date);
  if (SCHSTATE.timezone == 0) {
    var Rdate = new Date(date + 'Z');
    return Rdate.toISOString().substring(0, 19);
  }
  var Rdate = new Date(date + 'Z');
  var Offset = SCHSTATE.timezone - Rdate.getTimezoneOffset();
  ////console.log(Offset);
  if (isNaN(Offset)) {
    return date;
  } else {
    Rdate.setMinutes(Rdate.getMinutes() - Offset);
    return Rdate.toISOString().substring(0, 19);
  }
}

function getLocalTZLabel(tzName = null) {
  var hrs = -(new Date().getTimezoneOffset() / 60);
  var localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone + ' (GMT' + (hrs > 0 ? '+' : '') + hrs + ')';
  if (tzName == null) {
    if (hrs == 0) {
      localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone + ' (GMT)';
    }
    if (hrs == -60) {
      localTZ = Intl.DateTimeFormat().resolvedOptions().timeZone + ' (UTC+01)';
    }
  } else {
    hrs = -(getTZ_Offset(tzName) / 60);
    localTZ = tzName + ' (GMT' + (hrs > 0 ? '+' : '') + hrs + ')';
    if (hrs == 0) {
      localTZ = tzName + ' (GMT)';
    }
    if (hrs == -60) {
      localTZ = tzName + ' (UTC+01)';
    }
  }

  return localTZ;
}
function toUTCmode_offset(date, timezone) {
  var Rdate = new Date(date + 'Z');
  if (timezone == 0) {
    return date;
  } else {
    Rdate.setMinutes(Rdate.getMinutes() - timezone);
    return Rdate.toISOString().substring(0, 19);
  }
}

function CheckOnlineStatus() {
  const cacheBust = new Date().getTime();
  RemoteData.get('api/v1/dev-ops/ping?e=' + cacheBust, {}, 5000, 'text')
    .then((data) => {
      if (data == null || data.indexOf('OK') <= -1) {
        OfflineTriggered('Ping Failure (data)');
        return;
      }

      CELLDATA = CORDOVA ? navigator.connection.type !== 'wifi' : false;
      OnlineTriggered('Ping Success');
    })
    .catch((e) => {
      OfflineTriggered('Ping Failure');
    });
}

var ONLINE_CHECK = setTimeout(CheckOnlineStatus, 60000);

/********************************** Get and Set login variables **************************/
var RISK_MGMT;
var RISK_REQ;
var SMS;
var SAMA_ENABLED = 0;
var STAFF_LIST = [];
var USER_RIGHTS = {
  PrimaryKey: '',
  username: '',
  group: '',
  group_pk: '',
  chatname: '',
  staffType: '',
  phone: '',
  Verified: '',
  AlertCat: '',
  Priority: '',
  SuperUser: '',
  AdminAccess: '',
  AuditAccess: '',
  ReportAccess: '',
  MaintAccess: '',
  UserAccess: '',
  BillingAccess: '',
  SMSAccess: '',
  ExpenseAccess: '',
  SchedulerAccess: '',
  DocsAccess: '',
  MappingAccess: '',
  TDGAccess: '',
  AssetAccess: '',
  ItinEditAccess: '',
  Enabled: '',
  LoginAttempts: '',
  LastLogin: '',
  LastFDTsync: '',
  sms: '',
  pbx: '',
  itin: '',
  USER_DATA: '',
};
var GROUP_DATA = {
  PrimaryKey: '',
  Bases: [],
  Company_Name: '',
  Country: '',
  Provider: '',
  p_Access: '',
  pUSER: '',
  pPASS: '',
  pURL: '',
  group: '',
  Charts: '',
  CFS: '',
  CAP: '',
  Mapping: '',
  Itinerary: '',
  TDG: '',
  Logbook: '',
  FuelCache: '',
  PBX: '',
  DutyTimes: '',
  TimeSheets: '',
  Assets: '',
  Scheduler: '',
  Risk_Mgmt: '',
  SAMA: '',
  Risk_Req: '',
  SMS: '',
  MaxAC: '',
  MaxUsers: '',
  WandB: '',
  Enterprise: '',
  billing: '',
  Client: '',
  Drone: '',
  Memos: '',
  Documents: '',
  Forms: '',
  Certs: '',
  Hobbs: '',
  IFR: '',
  Flightdocs: '',
  Lite: '',
  WB_Warn_Off: '',
  Pax_Man: '',
  LastGROUPsync: '',
  LastFCsync: '',
  LastRULEsync: '',
  MAPBOX_ID: '',
  MAPBOX_TOKEN: '',
  MAPBOX_TYPE: 'raster',
  MAPBOX_STYLE: '',
  p_license: '',
  delete_status: '',
  LastWPsync: '',
  last_flight_report_identifier: '',
  last_invoice_identifier: '',
  invoice_blurb: '',
  flight_report_blurb: '',
  Alert_Warning: '',
  FemalWeight: '',
  MaleWeight: '',
  Alert_T1: '',
  Alert_T2: '',
  Alert_T3: '',
  FDT_CS: '',
  LabelOptions: '',
};
var SCHEDULER_ACCESS = 0;
var TIMEOFF_ACCESS = 0;
var BOOKING_ACCESS = 0;
var GOTLOCALSTORAGE = false;
var LOCALSTORAGE = {};
var PREFERENCES = [];

function getLocalStorage() {
  localStorageDB.getAll(function (e) {
    LOCALSTORAGE = e;

    gotLocalStorage();
  });
}

function LoadLocalStorageDefaultSettings() {
  if (LOCALSTORAGE.USE_CELLDATA == null) {
    LOCALSTORAGE.USE_CELLDATA = 1;
  }
  if (LOCALSTORAGE.LOW_BANDWIDTH > 0) {
    LOW_BANDWIDTH = true;
    $('#LowBandwidthCHK').prop('checked', true);
    $('#LowBandwidthCHK_TXT').html(iTrans('Low Bandwidth Mode'));
  } else {
    $('#LowBandwidthCHK').prop('checked', false);
    $('#LowBandwidthCHK_TXT').html(iTrans('Low Bandwidth Mode'));
  }
  if (LOCALSTORAGE.DisplayUnitsIndex != null) {
    changeDisplayUnits(LOCALSTORAGE.DisplayUnitsIndex);
  }
  LOCALSTORAGE.TimeInput = LOCALSTORAGE.TimeInput == null ? 'Clockpicker' : LOCALSTORAGE.TimeInput;
}

function gotLocalStorage() {
  console.log('Getting Local Storage Settings');
  To_Login = LOCALSTORAGE.To_Login;
  if (To_Login > 0) {
    verbose.log('DEBUG', 'Switching to Login Page');
    VueBridge.enterVue('/login');
    return;
  }
  AVDATA_URL = LOCALSTORAGE.AVDATA_URL;
  MBTILE_URL = LOCALSTORAGE.MBTILE_URL;
  USER_RIGHTS = LOCALSTORAGE.USER_RIGHTS;
  GROUP_DATA = LOCALSTORAGE.GROUP_DATA;
  PREFERENCES = LOCALSTORAGE.MENU_PREFERENCES;
  localStorageDB.getItem('STAFF_LIST', LoadStaffList);
  COMPANY = LOCALSTORAGE.COMPANY;
  USERTYPE = LOCALSTORAGE.USERTYPE;
  USER_PK = LOCALSTORAGE.USER_PK;
  chatname = LOCALSTORAGE.chatname;
  username = window.atob(LOCALSTORAGE.username);
  MAPPING = LOCALSTORAGE.MAPPING;
  VERSION = LOCALSTORAGE.VERSION;
  ADMINACCESS = LOCALSTORAGE.ADMINACCESS;
  TDGACCESS = LOCALSTORAGE.TDGACCESS;
  ASSETTRACKING = LOCALSTORAGE.ASSETTRACKING;
  SCHEDULER = LOCALSTORAGE.SCHEDULER;
  WANDB = LOCALSTORAGE.WANDB;
  ENTERPRISE = LOCALSTORAGE.ENTERPRISE;
  CLIENT = LOCALSTORAGE.CLIENT;
  CHARTS = LOCALSTORAGE.CHARTS;
  CFS = LOCALSTORAGE.CFS;
  ITINERARY = LOCALSTORAGE.ITINERARY;
  MAPBOX_MAP_ID = LOCALSTORAGE.MAPBOX_MAP_ID;
  MAPBOX_TOKEN = LOCALSTORAGE.MAPBOX_TOKEN;
  FUELCACHE = LOCALSTORAGE.FUELCACHE;
  COUNTRY = LOCALSTORAGE.COUNTRY;
  DUTYTIMES = LOCALSTORAGE.DUTYTIMES;
  DRONE = LOCALSTORAGE.DRONE;
  MEMOS = LOCALSTORAGE.MEMOS;
  DOCUMENTS = LOCALSTORAGE.DOCUMENTS;
  FORMS = LOCALSTORAGE.FORMS;
  CERTS = LOCALSTORAGE.CERTS;
  LITE = LOCALSTORAGE.LITE;
  RISK_MGMT = LOCALSTORAGE.RISK_MGMT;
  RISK_REQ = LOCALSTORAGE.RISK_REQ;
  SMS = LOCALSTORAGE.SMS;
  HOBBS = LOCALSTORAGE.HOBBS;
  IFR_CHARTS = LOCALSTORAGE.IFR_CHARTS;
  FLIGHTDOCS = LOCALSTORAGE.FLIGHTDOCS;
  FDT_SPECIALTY = LOCALSTORAGE.FDT_CS;
  FDT_SPECIALTYV3 = LOCALSTORAGE.FDT_CSV3;
  TIMESHEETS = LOCALSTORAGE.TimeSheets;
  SAMA_ENABLED = LOCALSTORAGE.SAMA;
  MENU_PREFERENCES = LOCALSTORAGE.MENU_PREFERENCES || '';
  SYNC_MENU_PREFS = LOCALSTORAGE.SYNC_MENU_PREFS;
  LoadLocalStorageDefaultSettings();
  Set_USER_RIGHTS();
  Init_Objects();
  Dash.Reset();
  GOTLOCALSTORAGE = true;
  getFollowData(); //Create or load last follow data for itinerary stuff
  LoadCustomMapScripts();

  if (LOCALSTORAGE.AdminFDT_Edit != 0) {
    //Was updating a different users FDT records and exited or refreshed app before restoring own records
    localStorageDB.setItem('AdminFDT_Edit', 0, function (e) {
      LOCALSTORAGE.AdminFDT_Edit = 0;
    });
    update_Sync('LastFDTsync', new Date());
    cirroDB.clearAll('FDT_DaysV4', function (e) {
      cirroDB.clearAll('FDT_RecsV3', function (e) {
        cirroDB.clearAll('FDT_StaffV3', function (e) {});
      });
    });
  }

  if (GROUP_DATA.FlightReports > 0 && !BILLING_SCRIPTS_LOADED) {
    BILLING_LoadScripts();
  }
  localStorageDB.getItem('STAFF_LIST', LoadStaffList);
  window.dispatchEvent(new Event('cirro-vue-register-globals'));
  setTimeout('setStartupPage()', 2000);
}

function setLocalStorage(L) {
  verbose.log('LOCSTOR', 'Setting LocalStorage:', L);

  AVDATA_URL = L.AVDATAURL;
  localStorageDB.setItem('ServerAirportIndexVersion', L.AirportIndexVersion);
  localStorageDB.setItem('AVDATA_URL', JSON.stringify(AVDATA_URL));
  MBTILE_URL = L.MBTILEURL;
  localStorageDB.setItem('MBTILE_URL', JSON.stringify(MBTILE_URL));
  USER_RIGHTS = L.USER_RIGHTS;
  localStorageDB.setItem('USER_RIGHTS', JSON.stringify(USER_RIGHTS));
  GROUP_DATA = L.GROUP_DATA;
  localStorageDB.setItem('GROUP_DATA', JSON.stringify(GROUP_DATA));
  USER_PK = L.USER_PK;
  localStorageDB.setItem('USER_PK', USER_PK);
  COMPANY = L.COMPANY;
  localStorageDB.setItem('COMPANY', COMPANY);
  USERTYPE = L.USERTYPE;
  localStorageDB.setItem('USERTYPE', USERTYPE);
  chatname = L.chatname;
  localStorageDB.setItem('chatname', chatname);
  username = L.username;
  localStorageDB.setItem('username', window.btoa(username));
  To_Login = 0;
  localStorageDB.setItem('To_Login', 0);
  MAPPING = L.MAPPING;
  localStorageDB.setItem('MAPPING', MAPPING);
  VERSION = L.VERSION;
  localStorageDB.setItem('VERSION', VERSION);
  //APPFILE = L.APPFILE //UNSET
  ADMINACCESS = L.USER_RIGHTS.AdminAccess;
  localStorageDB.setItem('ADMINACCESS', ADMINACCESS);
  TDGACCESS = L.TDGACCESS;
  localStorageDB.setItem('TDGACCESS', TDGACCESS);
  ASSETTRACKING = L.ASSETTRACKING;
  localStorageDB.setItem('ASSETTRACKING', ASSETTRACKING);
  SCHEDULER = L.SCHEDULER;
  localStorageDB.setItem('SCHEDULER', SCHEDULER);
  WANDB = L.WANDB;
  localStorageDB.setItem('WANDB', WANDB);
  ENTERPRISE = L.ENTERPRISE;
  localStorageDB.setItem('ENTERPRISE', ENTERPRISE);
  CLIENT = L.CLIENT;
  localStorageDB.setItem('CLIENT', CLIENT);
  CHARTS = L.CHARTS;
  localStorageDB.setItem('CHARTS', CHARTS);
  CFS = L.CFS;
  localStorageDB.setItem('CFS', CFS);
  ITINERARY = L.ITINERARY;
  localStorageDB.setItem('ITINERARY', ITINERARY);
  MAPBOX_MAP_ID = L.MAPBOX_MAP_ID;
  localStorageDB.setItem('MAPBOX_MAP_ID', MAPBOX_MAP_ID);
  MAPBOX_TOKEN = L.MAPBOX_TOKEN;
  localStorageDB.setItem('MAPBOX_TOKEN', MAPBOX_TOKEN);
  FUELCACHE = L.FUELCACHE;
  localStorageDB.setItem('FUELCACHE', FUELCACHE);
  COUNTRY = L.COUNTRY;
  localStorageDB.setItem('COUNTRY', COUNTRY);
  DUTYTIMES = L.DUTYTIMES;
  localStorageDB.setItem('DUTYTIMES', DUTYTIMES);
  localStorageDB.setItem('DUTYTIMES', DUTYTIMES);
  DRONE = L.DRONE;
  localStorageDB.setItem('DRONE', DRONE);
  MEMOS = L.MEMOS;
  localStorageDB.setItem('MEMOS', MEMOS);
  DOCUMENTS = L.DOCUMENTS;
  localStorageDB.setItem('DOCUMENTS', DOCUMENTS);
  FORMS = L.FORMS;
  localStorageDB.setItem('FORMS', FORMS);
  CERTS = L.CERTS;
  localStorageDB.setItem('CERTS', CERTS);
  LITE = L.LITE;
  localStorageDB.setItem('LITE', LITE);
  RISK_MGMT = L.RISK_MGMT;
  localStorageDB.setItem('RISK_MGMT', RISK_MGMT);
  RISK_REQ = L.RISK_REQ;
  localStorageDB.setItem('RISK_REQ', RISK_REQ);
  SMS = L.SMS;
  localStorageDB.setItem('SMS', SMS);
  HOBBS = L.HOBBS;
  localStorageDB.setItem('HOBBS', HOBBS);
  IFR_CHARTS = L.IFR;
  localStorageDB.setItem('IFR_CHARTS', IFR_CHARTS);
  FLIGHTDOCS = L.FLIGHTDOCS;
  localStorageDB.setItem('FLIGHTDOCS', FLIGHTDOCS);
  FDT_SPECIALTY = JSON.parse(L.FDT_CS);
  localStorageDB.setItem('FDT_CS', L.FDT_CS);
  FDT_SPECIALTYV3 = JSON.parse(L.GROUP_DATA.FDT_CSV3);
  localStorageDB.setItem('FDT_CSV3', L.GROUP_DATA.FDT_CSV3);
  TIMESHEETS = L.TimeSheets;
  localStorageDB.setItem('TimeSheets', L.TimeSheets);
  SAMA_ENABLED = L.SAMA;
  localStorageDB.setItem('SAMA', L.SAMA);
  //Menu preferences/favourites
  SYNC_MENU_PREFS = LOCALSTORAGE.SYNC_MENU_PREFS;
  if (SYNC_MENU_PREFS == null) {
    SYNC_MENU_PREFS = true;
    localStorageDB.setItem('SYNC_MENU_PREFS', true);
  }
  if (SYNC_MENU_PREFS == true || SYNC_MENU_PREFS == 'true') {
    let tempPrefs = L.MENU_PREFERENCES == null ? '' : L.MENU_PREFERENCES;
    MENU_PREFERENCES = tempPrefs.setting_value;
    localStorageDB.setItem('MENU_PREFERENCES', tempPrefs.setting_value);
  } else {
    MENU_PREFERENCES = LOCALSTORAGE.MENU_PREFERENCES;
  }
  Set_USER_RIGHTS();
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (page == 'Initialization_Page' || page == 'Login_Page') {
    $.mobile.changePage($('#Initialization_Page'), {
      changeHash: false,
    });
    $('#initDialog').text(iTrans('Synchronizing Server Data') + '...');
  }

  GOTLOCALSTORAGE = true;

  LOCALSTORAGE.chatname = L.chatname;
  localStorageDB.getAll(function (e) {
    LOCALSTORAGE = e;

    LoadLocalStorageDefaultSettings();

    CheckOfflineDatabases(0); //do this for all apps now

    MigrateGPS_Log();
    Init_Objects();
    Dash.Reset();
    LoadCustomMapScripts();
    if (LOCALSTORAGE.AdminFDT_Edit != 0) {
      //Was updating a different users FDT records and exited or refreshed app before restoring own records
      localStorageDB.setItem('AdminFDT_Edit', 0, function (e) {
        LOCALSTORAGE.AdminFDT_Edit = 0;
      });
      update_Sync('LastFDTsync', new Date());
      cirroDB.clearAll('FDT_DaysV4', function (e) {
        cirroDB.clearAll('FDT_RecsV3', function (e) {
          cirroDB.clearAll('FDT_StaffV3', function (e) {
            FDT_ADMIN_UPDATE = false;
            FDT_SKIP_TRANSITION = true;
            FDT_OPK = 0;
            localStorageDB.setItem('AdminFDT_Edit', FDT_OPK, function (e) {
              LOCALSTORAGE.AdminFDT_Edit = FDT_OPK;
            });
            if (LOW_BANDWIDTH && LOCALSTORAGE.ManualSync != 1) {
              $('#initDialog').html(
                iTrans('Low Bandwidth Mode Enabled.') + '<br />' + iTrans('Use Sync Now When Ready.')
              );
              setTimeout('setStartupPage()', 2000);
              CheckOfflineSendItems();
              if (GROUP_DATA.FlightReports == 1 && !BILLING_SCRIPTS_LOADED) BILLING_LoadScripts();
              localStorageDB.getItem('STAFF_LIST', LoadStaffList);
              BackgroundSync(true);
            } else {
              if (LOCALSTORAGE.ManualSync == 1) {
                LOCALSTORAGE.ManualSync = 0;
                localStorageDB.setItem('ManualSync', 0);
              }
              BackgroundSync();
            }
          });
        });
      });
    } else {
      if (LOW_BANDWIDTH && LOCALSTORAGE.ManualSync != 1) {
        $('#initDialog').html(iTrans('Low Bandwidth Mode Enabled.') + '<br />' + iTrans('Use Sync Now When Ready.'));
        setTimeout('setStartupPage()', 2000);
        CheckOfflineSendItems();
        if (GROUP_DATA.FlightReports == 1 && !BILLING_SCRIPTS_LOADED) BILLING_LoadScripts();
        localStorageDB.getItem('STAFF_LIST', LoadStaffList);
      } else {
        if (LOCALSTORAGE.ManualSync == 1) {
          LOCALSTORAGE.ManualSync = 0;
          localStorageDB.setItem('ManualSync', 0);
        }
        BackgroundSync();
      }
    }
  });
}

/**
 * Uses the menu preferences to set up the main menu on start up
 */
function setFavourites() {
  let defaultsToShow = ['#ManualSync'];

  let prefList = MENU_PREFERENCES;
  prefList = prefList == '' || prefList == null || prefList == 'undefined' ? [] : prefList.split(',');
  buildUserModuleArray(prefList, defaultsToShow);
  if (prefList.length === 0) {
    $('#favouriteBox').hide();
    $('#toggleHiddenDiv').hide();
    $('#hiddenModules').show();
  } else {
    $('#hiddenModules').hide();
    prefList.forEach(function (item) {
      var element = $(item).parent().detach();
      $('#favourites').append(element);
    });
    defaultsToShow.forEach(function (item) {
      var element = $(item).parent().detach();
      $('#favourites').append(element);
    });
  }
}

/**
 * Quick way to check if user is subscribed to the premium plan
 * No reason to show them the upsell page if they buy everything
 * @returns {boolean}
 */
function isPlanPremium() {
  return (
    GROUP_DATA.Pax_Man &&
    GROUP_DATA.Personal_Itin &&
    GROUP_DATA.FlightReports &&
    GROUP_DATA.billing &&
    GROUP_DATA.Quoting &&
    RISK_MGMT &&
    GROUP_DATA.Documents &&
    GROUP_DATA.Certs &&
    GROUP_DATA.Memos &&
    GROUP_DATA.Forms &&
    MAPPING &&
    ITINERARY &&
    DUTYTIMES &&
    SCHEDULER &&
    ASSETTRACKING &&
    TDGACCESS &&
    WANDB &&
    TIMESHEETS &&
    SAMA_ENABLED
  );
}
function Set_USER_RIGHTS() {
  //show the add modules button if they are a super userand there are modules they are not subscribed to
  if (USER_RIGHTS.SuperUser && !isPlanPremium()) {
    $('#addModuleDiv').show();
  }
  //set default FR and Quote search dates here since it sometimes wasn't happening correctly elsewhere
  var fromdate = new Date();
  fromdate.setMonth(fromdate.getMonth() - 1);
  $('#RPT_FR_FromDate').val(fromdate.toISOString().substring(0, 10));
  $('#RPT_FR_ToDate').val(new Date().toISOString().substring(0, 10));
  $('#QUOTE_FR_FromDate').val(fromdate.toISOString().substring(0, 10));
  $('#QUOTE_FR_ToDate').val(new Date().toISOString().substring(0, 10));

  ReLabelItems(); //in menu-Administration.js to udpate desired group labels
  if (AVDATA_URL == 'http://ad2.air-suite.com') {
    $('#CesiumMap').hide();
  } //hidden killswitch for experimental cesium map
  if (!CORDOVA && !ELECTRON) {
    $('#redownloadDatabases').hide();
    $('#CesiumSelectHLDR').hide();
    $('#CesiumDialogHLDR').show();
    $('#CesiumTriggerMbTileSelectBTN').hide();
  }

  if (APP_ENVIRONMENT === 'local' || APP_ENVIRONMENT === 'staging') {
    $('#RevampHomeMenuBTN').show();
  }

  if (FLIGHTDOCS == 0) {
    $('#FD_options').remove();
  }
  if (GROUP_DATA['WinAir'] == 0) {
    $('#WinAir_options').remove();
  }
  if (HOBBS == 0) {
    $('#BI_HOBBS_OPTION').hide();
    $('#BILLBY_Hobbs').prop('disabled', true);
  }
  if (USER_RIGHTS.StudentPilot == 0) {
    $('#BILLING_AccountBalance').unwrap().remove();
  }
  if (GROUP_DATA.Pax_Man == 0) {
    $('#PaxManifesting').unwrap().remove();
  }
  if (GROUP_DATA.Personal_Itin == 0) {
    $('#PersonalItinBTN').unwrap().remove();
    $('#PERSONAL_ITIN_Report').unwrap().remove();
    $('#Itinerary').parent().removeClass('grid-col-sm-6');
    $('#Itinerary').parent().addClass('grid-col-sm-12');
    $('#vehicleAdminBTN').hide();
  }

  if (GROUP_DATA.Pax_Man == 0) {
    $('#PaxMan_options').hide();
  }
  if (GROUP_DATA.Quoting == 0 || (USER_RIGHTS.QuotingAccess == 0 && USER_RIGHTS.SuperUser == 0)) {
    $('#BILLING_AdminQuoting').unwrap().remove();
  }

  if (RISK_MGMT == 0) {
    $('#RAreportbtn').remove();
  }

  /** CAPI */
  if (GROUP_DATA.Documents == 0 && GROUP_DATA.Certs == 0 && GROUP_DATA.Memos == 0 && GROUP_DATA.Forms == 0) {
    $('.mod_company_library').remove();
  }
  //////////

  if (LITE == '1') {
    $('#engineerType').attr('disabled', true);
    $('#adminType').attr('disabled', true);
    $('#groundType').attr('disabled', true);
    $('#attendantType').attr('disabled', true);
    $('#SELECT_Rule_ID').attr('disabled', true);
    $('#CUSTOM_RULE_SELECT2').hide();
    $('#itinClients2').hide();
    $('#SYNCiFUEL').remove();
    $('#SYNCiRULES').remove();

    // swap FDT labels to Logbook
    $('#FlightDutyTimeTXT').remove();
    $('#LogbookTXT').show();

    $('#DutySetupTXT').remove();
    $('#DutySetupLogbookTXT').show();

    $('#Reports_PageDutyTimesTXT').remove();
    $('#Reports_PageLogbookTXT').show();

    $('#AdminMC').hide();

    if (CHARTS == 0) {
      $('#CesiumMap').hide();
    }
  }
  if (GROUP_DATA.MAPBOX_ID == 'default') {
    $('input[type=radio]#mapboxBASE').remove();
  }
  if (DRONE == 0) {
    //$("#airspaceControls").remove();
  }
  if (CFS == 1) {
    CA_CFS_GEN();
    eCFSgenerateContent();
    vtaBacksgenerateContent();
    US_DCS_GEN();
    $('#eCFSBTNhldr').show();
    $('#vtaBacksBTNhldr').show();
  }
  if (IFR_CHARTS == 1) {
    CAPgenerateContent();
    $('#CAPBTNhldr').show();
  }
  if (MAPPING == 0) {
    $('#MAPPING_MAP').remove();
  }
  if (FUELCACHE == 0) {
    $('#FUELCACHE_MAP').remove();
    $('#fuelcache').remove();
  }
  if (ITINERARY == 0) {
    $('#Itinerary').remove();
    $('#ITINERARYOuterbtn').remove();
    $('#Following').unwrap().remove();
    $('#AdminFT').remove();
    $('#AdminMC').remove();
  }
  if (DUTYTIMES == 0 || (DUTYTIMES == 1 && USERTYPE != 'Pilot' && TIMESHEETS == 0)) {
    $('#duty').unwrap().remove();
  } else if (USERTYPE != 'Pilot') {
    // if not a pilot, show the time sheet label and remove FDT label
    $('#FlightDutyTimeTXT').remove();
    $('#TimesheetTXT').show();
  }
  if (USERTYPE != 'Pilot') {
    $('#DutyDefaultsPage').prop('disabled', true);
  }
  if (DUTYTIMES == 0) {
    $('#ADMIN_DUTY').remove();
  }
  if (WANDB == 0) {
    $('#editWB').remove();
    $('#ELBTN').remove();
  }
  if (ASSETTRACKING == 0) {
    $('#assets').unwrap().remove();
    $('#ASSETS_btn').remove();
  }
  if (SCHEDULER == 0) {
    $('#schedulerBTN').unwrap().remove();
  }
  if (TDGACCESS == 0) {
    $('#PlanTDGbtn').unwrap().remove();
    $('#DANGEROUSbtn').remove();
    //$("#TDGmenuBTN").unwrap().remove();
    $('#HideAllTDGItems').hide();
    $('#TDGAdmin').remove();
  }
  if (MAPPING == 0) {
    $('#Planning').unwrap().remove();
  }

  if (TIMESHEETS == 0) {
    $('#ADMIN_TIMESHEETS').remove();
  }
  if (GROUP_DATA.Pax_Man == 0) {
    $('#ADMIN_PAXMANIFESTING').remove();
  }
  if (CHARTS == 0) {
    $('#CHARTS_SELECTORS').remove();
  }
  switch (COUNTRY) {
    case 'CANADA':
      break;
    case 'UNITED STATES':
      $('#DUTY_LIMITS_CA').hide();
      $('#DUTY_LIMITS_CA_SETUP').hide();
      /*
      $("#SELECT_Rule option[value='702']").remove();
      $("#SELECT_Rule option[value='703']").remove();
      $("#SELECT_Rule2 option[value='702']").remove();
      $("#SELECT_Rule2 option[value='703']").remove();
      $("#SELECT_Rule option[value='704']").remove();
      $("#SELECT_Rule2 option[value='704']").remove();
      $("#SELECT_Rule option[value='604']").remove();
      $("#SELECT_Rule2 option[value='604']").remove();
      $("#SELECT_Rule option[value='001']").remove();
      $("#SELECT_Rule2 option[value='001']").remove();
      $("#SELECT_Rule option[value='002']").remove();
      $("#SELECT_Rule2 option[value='002']").remove();
       */
      $('#Canadian_TDG').hide();
      break;
    case 'AUSTRALIA':
      break;
    default:
      $('#CANADA_FIRES').remove();
      $('#CANADA_FIRES2').remove();
      $('#CANADA_FIRE_INDEX').remove();
      $('#DUTY_LIMITS_CA').hide();
      $('#DUTY_LIMITS_CA_SETUP').hide();
      $('#Canadian_TDG').hide();
      break;
  }
  if (USER_RIGHTS.SuperUser == 0) {
    if (USER_RIGHTS.AdminAccess == 0) {
      $('#Administration').unwrap().remove();
    }
    if (USER_RIGHTS.SchedulerAccess == 0) {
      $('#calEditcheckDIV').remove(); //Don't allow Calendar Editing
      if (GROUP_DATA.SCH_AssignOnly == 1) {
        $('#SCHMyEventsCHK').prop('disabled');
        $('#SCHMyEventsCHK').prop('checked', true);
      }
    } else {
      SCHEDULER_ACCESS = 1;
    }
    if (USER_RIGHTS.SchedulerTimeOff == 0) {
      $('#calEditcheckDIV').remove(); //Don't allow Calendar Editing
      if (GROUP_DATA.SCH_AssignOnly == 1) {
        $('#SCHMyEventsCHK').prop('disabled');
        $('#SCHMyEventsCHK').prop('checked', true);
      }
    } else {
      TIMEOFF_ACCESS = 1;
    }
    if (USER_RIGHTS.SchedulerBookings == 0) {
      $('#calEditcheckDIV').remove(); //Don't allow Calendar Editing
      if (GROUP_DATA.SCH_AssignOnly == 1) {
        $('#SCHMyEventsCHK').prop('disabled');
        $('#SCHMyEventsCHK').prop('checked', true);
      }
    } else {
      BOOKING_ACCESS = 1;
    }
    if (USER_RIGHTS.BillingAccess == 0) {
      $('#BILLING_ADMIN_FLIGHTS').hide(); //Flight Reports Admin Section
      $('#BILLING_ADMIN_FLIGHTS_Label').hide();
    }
    if (USER_RIGHTS.SMSAccess == 0) {
      $('#ReviewRiskBTN').prop('disabled', true);
    }
    if (USER_RIGHTS.MaintAccess == 0) {
      $('#aircraft').prop('disabled', true);
      $('#FD_options').prop('disabled', true);
      $('#WinAir_options').prop('disabled', true);
    }

    if (USER_RIGHTS.MappingAccess == 0) {
      $('#fuelcache').prop('disabled', true);
      $('#manageWPTS').prop('disabled', true);
      $('#spotdevices').prop('disabled', true);
    }
    if (USER_RIGHTS.TDGAccess == 0) {
      $('#TDGAdmin').prop('disabled', true);
    }
    $('#PassengerWeights').prop('disabled', true);
    if (USER_RIGHTS.AssetAccess == 0) {
      $('#addnewAssetPage').prop('disabled', true);
    }
  } else {
    SCHEDULER_ACCESS = 1;
    TIMEOFF_ACCESS = 1;
    BOOKING_ACCESS = 1;
  }
  if (GROUP_DATA.Quoting == 0) {
    $('#BILLING_UNREPORTEDQUOTES').hide();
  }
}

function generatePasswordResetOptions(baseUrl) {
  $('#PasswordResetOptions').html(
    `<a target="_blank" href="${baseUrl}view/auth/reset-password" data-mini="true" data-role="button">
       <i class="fal fa-user-lock"></i>
       ${iTrans('Forgot Password')}
     </a>`
  );
}

function PHONEGAP_READY() {
  if (APP_READY_STATE < 2) {
    console.log('Waiting for Storage backend and Cordova to be ready');
    return;
  }

  verbose.log('VUE', 'Basic Init complete.');

  let iDev = 5;
  // Install the tap handler for the in-app dev console
  $('.devtap').on('click', () => {
    if (iDev > 0) {
      iDev--;
      return;
    }

    if (window.iac == null) {
      window.iac = new InAppConsole();
    }

    $('#RevampHomeMenuBTN').show();
  });

  $.ajaxSetup({
    beforeSend: function (jqXhr, settings) {
      const { url } = settings;
      const token = window.vueApp.authTools.authGetToken();

      // The URL is the path when making internal calls, so check if http exists to detect external ones
      // The URL may contain the baseurl?
      if (token && (url.indexOf(BASEURL) > -1 || url.indexOf('http') < 0)) {
        jqXhr.setRequestHeader('X-Auth-Token', token);
      }
    },
    complete: function (jqXhr) {
      const authToken = jqXhr.getResponseHeader('X-Auth-Token');

      if (authToken != null) {
        window.vueApp.authTools.authSetToken(authToken);
      }
    },
  });

  generatePasswordResetOptions(BASEURL);

  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
    },
  };
  if (!window.device) {
    window.device = {
      platform: 'Browser',
    };
    $('#Admin_certPicture').hide();
  }
  MOBILE = ANDROID || IOS;
  $('#LoadingIndicator').hide();
  $('#exitFollowPage').click(hrefHandler);
  $('#exitPlanningpage').click(hrefHandler);
  $('#exitWBpage').click(hrefHandler);
  $('#exitItineraryPage').click(hrefHandler);
  $('#exitDutyPage').click(hrefHandler);
  $('#exitCPWpage').click(hrefHandler);
  $('#exitACPWpage').click(hrefHandler);
  $('#exitAdministrationPage').click(hrefHandler);
  $('#exitDocAdminPage').click(hrefHandler);
  $('#exitUserAdminPage').click(hrefHandler);
  $('#exitUserOptionsAdminPage').click(hrefHandler);
  $('#exitDocumentsPage').click(hrefHandler);
  $('#exitDutyTimes').click(hrefHandler);
  $('#exitDutyDefaults').click(hrefHandler);
  $('#DutyDefaultsPage').click(hrefHandler);
  $('#LogoutBTN').click(hrefHandler);
  $('#exitFilePage').click(hrefHandler);
  $('#skipSync').click(skipSync);
  $('#restoreDatabase').click(restoreDatabase);
  $('#retrySync').click(restartCirro);
  $('#FFitinInfo').on('swiperight', closePopup);

  verbose.log('DEBUG', 'PHONEGAP_READY, online status: ', ONLINE);

  startTime();
  updateSplash();
  updateVersionString();

  window.addEventListener('offline', OfflineTriggered);
}

var cert_base64File = null;
var cert_Filename = 'N/A';
var cert_Filesize = null;

function enableSaveFile(type) {
  switch (type) {
    case 'Admin':
      break;
    case 'User':
      break;
    case 'Admin_User':
      break;
    case 'AssetHistory':
      break;
    case 'Asset':
      break;
  }
}

function failFile(e) {
  //console.log(e);
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Error'),
    headerClose: false,
    buttonPrompt: iTrans('There was an error attempting to read the local file'),
    buttons: {
      [iTrans('OK')]: {
        click: function () {
          //fail
        },
      },
    },
  });
}

var Android_Image_Data;
function Android_GetPic(imageData, type) {
  Android_Image_Data = imageData;

  MAX_PHOTO_RESOLUTION = 1024;

  var html = '<fieldset data-role="controlgroup" data-type="vertical" data-mini="true">';
  html +=
    '<label for="IMAGE_TINY">' +
    iTrans('Tiny') +
    ' (512 x 512 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_TINY" value="512" onclick="IMAGE_PREVIEW_RESIZE(this,0)" />';
  html +=
    '<label for="IMAGE_SMALL">' +
    iTrans('Small') +
    ' (1024 x 1024 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_SMALL" value="1024" checked="checked" onclick="IMAGE_PREVIEW_RESIZE(this,0)" />';
  html +=
    '<label for="IMAGE_MEDIUM">' +
    iTrans('Medium') +
    ' (2048 x 2048 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_MEDIUM" value="2048" onclick="IMAGE_PREVIEW_RESIZE(this,0)" />';
  html +=
    '<label for="IMAGE_LARGE">' +
    iTrans('Large') +
    ' (4096 x 4096 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_LARGE" value="4096" onclick="IMAGE_PREVIEW_RESIZE(this,0)" />';
  html +=
    '<label for="IMAGE_ORIG">' +
    iTrans('Actual Size') +
    ': ' +
    FormatFileSize(Math.round((Android_Image_Data.length * 3) / 4)) +
    ' <span id="IMAGE_ORIG_DIMENSIONS"></span></label><input type="radio" name="IMAGE_TYPES" id="IMAGE_ORIG" value="99999999" onclick="IMAGE_PREVIEW_RESIZE(this,0)" />';
  html += '</fieldset>';
  html +=
    '<b>' +
    iTrans('Image Preview - ') +
    ' <span id="IMAGE_SIZE_HLDR"></span></b><div id="IMAGE_PREVIEW_HLDR" style="overflow:scroll;width:100%;height:300px"></div>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Select Max Photo Size'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Done')]: {
        click: function () {
          Android_ProcessPic(type);
        },
      },
    },
    callbackOpen: function () {
      IMAGE_PREVIEW_RESIZE({ value: 1024 }, 0);
      IMAGE_GET_Dimensions(0);
    },
  });
}
function Android_ProcessPic(type) {
  if (type == 'User') {
    $('input[name=User_KeepFile][value=0]').prop('checked', false).checkboxradio('refresh');
    $('input[name=User_KeepFile][value=1]').prop('checked', true).checkboxradio('refresh');
  }
  // here we can upload imageData to the server
  var img = document.createElement('img');
  img.onload = function () {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
    var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
    var width = img.width;
    var height = img.height;
    console.log(width);
    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);

    var dataurl = canvas.toDataURL('image/jpeg');
    cert_base64File = dataurl.split(',')[1];
    var date = new Date();
    $.mobile.loading('hide');

    if (type == 'AssetHistory') {
      cert_Filename =
        'Cirro_AssetHistory_' +
        date.getFullYear() +
        date.getMonth() +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        '.jpg';
      $('#AndroidAssetHistoryPIC_TXT').html('<h3>Picture Filename: ' + cert_Filename + '</h3>');
      $('#Asset_File').prop('disabled', true);
    } else if (type == 'Expense') {
      cert_Filename =
        'Cirro_Expense_' +
        date.getFullYear() +
        date.getMonth() +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        '.jpg';

      $('#BILLING_EXPENSE_PHOTO').html("<img class='limit-table' src='" + dataurl + "' />");
    } else {
      cert_Filename =
        'Cirro_Cert_' +
        date.getFullYear() +
        date.getMonth() +
        date.getDate() +
        date.getHours() +
        date.getMinutes() +
        '.jpg';
      $('#PictureFilename').html('<h3>New Picture File: ' + cert_Filename + '</h3>');
    }
    console.log(cert_Filename);
    console.log('Photo upload ready');
  };
  img.src = 'data:image/jpeg;base64,' + Android_Image_Data;
}

var cert_base64File2;
var cert_Filename2;

function base64File(type) {
  console.log('base64File: ' + type);
  var file = IMAGE_GET_FileInput(type);

  if (file != undefined) {
    if (file.type.indexOf('image') != -1) {
      MAX_PHOTO_RESOLUTION = 1024;

      var html = '<fieldset data-role="controlgroup" data-type="vertical" data-mini="true">';
      html +=
        '<label for="IMAGE_TINY">' +
        iTrans('Tiny') +
        ' (512 x 512 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_TINY" value="512" onclick="IMAGE_PREVIEW_RESIZE(this,\'' +
        type +
        '\')" />';
      html +=
        '<label for="IMAGE_SMALL">' +
        iTrans('Small') +
        ' (1024 x 1024 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_SMALL" value="1024" checked="checked" onclick="IMAGE_PREVIEW_RESIZE(this,\'' +
        type +
        '\')" />';
      html +=
        '<label for="IMAGE_MEDIUM">' +
        iTrans('Medium') +
        ' (2048 x 2048 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_MEDIUM" value="2048" onclick="IMAGE_PREVIEW_RESIZE(this,\'' +
        type +
        '\')" />';
      html +=
        '<label for="IMAGE_LARGE">' +
        iTrans('Large') +
        ' (4096 x 4096 MAX)</label><input type="radio" name="IMAGE_TYPES" id="IMAGE_LARGE" value="4096" onclick="IMAGE_PREVIEW_RESIZE(this,\'' +
        type +
        '\')" />';
      html +=
        '<label for="IMAGE_ORIG">' +
        iTrans('Actual Size') +
        ': ' +
        FormatFileSize(file.size) +
        ' <span id="IMAGE_ORIG_DIMENSIONS"></span></label><input type="radio" name="IMAGE_TYPES" id="IMAGE_ORIG" value="99999999" onclick="IMAGE_PREVIEW_RESIZE(this,\'' +
        type +
        '\')" />';
      html += '</fieldset>';
      html +=
        '<b>' +
        iTrans('Image Preview - ') +
        ' <span id="IMAGE_SIZE_HLDR"></span></b><div id="IMAGE_PREVIEW_HLDR" style="overflow:scroll;width:100%;height:300px"></div>';
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Select Max Photo Size'),
        headerClose: false,
        buttonPrompt: html,
        buttons: {
          [iTrans('Done')]: {
            click: function () {
              Processbase64File(file, type);
            },
          },
        },
        callbackOpen: function () {
          IMAGE_PREVIEW_RESIZE({ value: 1024 }, type);
          IMAGE_GET_Dimensions(file);
        },
      });
    } else {
      Processbase64File(file, type);
    }
  }
}

function IMAGE_GET_Dimensions(file) {
  var img = new Image();

  img.onload = function () {
    var width = img.naturalWidth,
      height = img.naturalHeight;
    window.URL.revokeObjectURL(img.src);
    $('#IMAGE_ORIG_DIMENSIONS').html('(' + width + ' x ' + height + ')');
    if (width < 4096 && height < 4096) {
      $('#IMAGE_LARGE').remove();
      $('[for="IMAGE_LARGE"]').remove();
    }
    if (width < 2048 && height < 2048) {
      $('#IMAGE_MEDIUM').remove();
      $('[for="IMAGE_MEDIUM"]').remove();
    }
    if (width < 1024 && height < 1024) {
      $('#IMAGE_SMALL').remove();
      $('[for="IMAGE_SMALL"]').remove();
      $('input:radio[name="IMAGE_TYPES"]').filter('[value="99999999"]').attr('checked', true).checkboxradio('refresh');
    }
    if (width < 512 && height < 512) {
      $('#IMAGE_TINY').remove();
      $('[for="IMAGE_TINY"]').remove();
    }
  };
  if (file !== 0) {
    img.src = window.URL.createObjectURL(file);
  } else {
    img.src = 'data:image/jpeg;base64,' + Android_Image_Data;
  }
}
function IMAGE_GET_FileInput(type) {
  var file;
  switch (type) {
    case 'Itinerary':
      file = document.getElementById('ITINERARY_Attachment').files[0];
      break;
    case 'ItineraryEdit':
      file = document.getElementById('ITINERARY_Edit_Attachment').files[0];
      break;
    case 'Admin':
      file = document.getElementById('cert_File').files[0];
      break;
    case 'User':
      file = document.getElementById('User_File').files[0];
      $('input[name=User_KeepFile][value=0]').prop('checked', false).checkboxradio('refresh');
      $('input[name=User_KeepFile][value=1]').prop('checked', true).checkboxradio('refresh');
      break;
    case 'Admin_User':
      file = document.getElementById('Admin_User_File').files[0];
      $('input[name=Admin_User_KeepFile][value=0]').prop('checked', false).checkboxradio('refresh');
      $('input[name=Admin_User_KeepFile][value=1]').prop('checked', true).checkboxradio('refresh');
      break;
    case 'AssetHistory':
      file = document.getElementById('Asset_File').files[0];
      break;
    case 'AssetHistoryInspect':
      file = document.getElementById('AssetInspect_File').files[0];
      break;
    case 'Asset':
      file = document.getElementById('Asset_File3').files[0];
      break;
    case 'Expense':
      file = document.getElementById('BILLING_ATTACHMENT').files[0];
      break;
    case 'AdminExpense':
      file = document.getElementById('BILLING_ADMIN_ATTACHMENT').files[0];
      break;
    case 'FlightReport':
      file = document.getElementById('BILLING_Edit_Attachment').files[0];
      break;
    case 'FC_Admin':
      file = document.getElementById('FC-Image_File').files[0];
      break;
    case 'vaccination':
      file = document.getElementById('PAX_DB_vacc_Doc').files[0];
      break;
  }
  return file;
}
function IMAGE_PREVIEW_RESIZE(e, type) {
  $('#IMAGE_PREVIEW_HLDR').html('<img src="./images/ajax-loader.gif" />');
  MAX_PHOTO_RESOLUTION = parseInt(e.value);
  if (type == 0) {
    var img = document.createElement('img');
    img.onload = function () {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
      var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
      var width = img.width;
      var height = img.height;
      //console.log(width);
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      var dataurl = canvas.toDataURL('image/jpeg');
      $('#IMAGE_PREVIEW_HLDR').html("<img src='" + dataurl + "' />");
      $('#IMAGE_SIZE_HLDR').html('Size: ' + FormatFileSize(Math.round((dataurl.length * 3) / 4)));
    };
    img.src = 'data:image/jpeg;base64,' + Android_Image_Data;
  } else {
    var file = IMAGE_GET_FileInput(type);

    var reader = new FileReader();
    reader.onload = function (e) {
      var img = document.createElement('img');
      img.onload = function () {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
        var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
        var width = img.width;
        var height = img.height;
        //console.log(width);
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        var dataurl = canvas.toDataURL(file.type);
        $('#IMAGE_PREVIEW_HLDR').html("<img src='" + dataurl + "' />");
        if (MAX_PHOTO_RESOLUTION > 4096) $('#IMAGE_SIZE_HLDR').html('Size: ' + FormatFileSize(file.size));
        else $('#IMAGE_SIZE_HLDR').html('Size: ' + FormatFileSize(Math.round((dataurl.length * 3) / 4)));
      };
      img.src = e.target.result;
    };
    reader.onerror = function (e) {
      alert(iTrans('There was an error generating the image preview'));
    };

    reader.readAsDataURL(file);
  }
}
function FormatFileSize(size) {
  console.log(size);
  var filesize = Math.round(size / 10000) / 100 + ' MB';
  if (size < 1000000) filesize = Math.round(size / 10) / 100 + ' KB';
  return filesize;
}
function Processbase64File(file, type) {
  if (CORDOVA) {
    getLocalFile(file, type);
    return;
  }
  if (file.size > 12582912 && (type == 'Itinerary' || type == 'ItineraryEdit')) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Max File Size'),
      headerClose: false,
      buttonPrompt:
        '<p>' +
        iTrans('The selected file is over the 12MB Itinerary size limit') +
        '.  ' +
        iTrans('Please reduce the file size and try again') +
        '</p>',
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            cert_base64File = null;
            cert_Filename = 'N/A';
          },
        },
      },
    });
    document.getElementById('ITINERARY_Attachment').value = '';
    return;
  }
  //console.log(file);
  if (type == 'AssetHistoryInspect') {
    if (file != undefined) {
      cert_Filename2 = file.name;
      cert_Filesize = file.size;
      //console.log(cert_Filesize);
      if (cert_Filesize > 157286400) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Max File Size'),
          headerClose: false,
          buttonPrompt:
            '<p>' +
            iTrans('The selected file is over the 150MB size limit') +
            '.  ' +
            iTrans('Please reduce the file size and try again') +
            '</p>',
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                cert_base64File2 = null;
                cert_Filename2 = 'N/A';
              },
            },
          },
        });
      } else {
        var reader = new FileReader();
        reader.onload = function (e) {
          if (file.type.indexOf('image') != -1) {
            var img = document.createElement('img');
            img.onload = function () {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
              var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
              var width = img.width;
              var height = img.height;
              //console.log(width);
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);

              var dataurl = canvas.toDataURL(file.type);
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html("<img class='limit-table' src='" + dataurl + "' />");
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html(
                  '<h3>' + file.name + "</h3><img class='limit-table' src='" + dataurl + "' />"
                );
              }
              cert_base64File = dataurl.split(',')[1];
              if (type == 'Itinerary' || type == 'ItineraryEdit') {
                ITIN_Files.push({
                  Filename: file.name,
                  File_Data: cert_base64File,
                  Private: 0,
                  Size: FormatFileSize(Math.round((cert_base64File.length * 3) / 4)),
                });
                itin_RefreshAttachmentList();
              }
            };
            img.src = e.target.result;
          } else {
            cert_base64File2 = btoa(e.target.result);
            var filetype = file.name.split('.').pop().toLowerCase();
            if (filetype == 'pdf') {
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='UserExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File2, 'UserExpenseDIV');
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='AdminExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File2, 'AdminExpenseDIV');
              }
            } else {
              $('#BILLING_ADMIN_EXPENSE_PHOTO').html('');
              $('#BILLING_EXPENSE_PHOTO').html('');
            }
          }
          ////console.log(cert_base64File);
          enableSaveFile(type);
        };
        reader.onerror = function (e) {
          cert_base64File2 = null;
          //console.log("FileReader Error");
          enableSaveFile(type);
        };
        if (file.type.indexOf('image') != -1) {
          reader.readAsDataURL(file);
        } else {
          reader.readAsBinaryString(file);
        }
      }
    } else {
      cert_base64File2 = null;
      cert_Filename2 = 'N/A';
      //console.log("No Cert File Selected");
      enableSaveFile(type);
    }
  } else {
    if (file != undefined) {
      cert_Filename = file.name;
      cert_Filesize = file.size;
      //console.log(cert_Filesize);
      if (cert_Filesize > 157286400) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Max File Size'),
          headerClose: false,
          buttonPrompt:
            '<p>' +
            iTrans('The selected file is over the 150MB size limit') +
            '.  ' +
            iTrans('Please reduce the file size and try again') +
            '</p>',
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                cert_base64File = null;
                cert_Filename = 'N/A';
              },
            },
          },
        });
      } else {
        var reader = new FileReader();
        reader.onload = function (e) {
          if (file.type.indexOf('image') != -1) {
            var img = document.createElement('img');
            img.onload = function () {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
              var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
              var width = img.width;
              var height = img.height;
              //console.log(width);
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);

              var dataurl = canvas.toDataURL(file.type);
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html("<img class='limit-table' src='" + dataurl + "' />");
              }
              if (type == 'vaccination') {
                CUR_PASSENGER.vacc_Doc = dataurl;
                CUR_PASSENGER.vacc_Doc_Name = file.name;
                $('#PAX_DB_vacc_Doc').val(null);
                PAX_SavePassenger(false, true);
                PAX_PopulatePaxFields();
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html(
                  '<h3>' + file.name + "</h3><img class='limit-table' src='" + dataurl + "' />"
                );
              }
              if (type == 'FC_Admin') {
                $('#FC-Image_DIV').html("<img class='limit-table' src='" + dataurl + "' />");
                $('#FC-Image').val(dataurl);
                var now = new Date().toISOString();

                $('#FC-ImageDateTime').val(now.substr(0, 10) + ' ' + now.substr(11, 8));
              }
              cert_base64File = dataurl.split(',')[1];
              if (type == 'Itinerary' || type == 'ItineraryEdit') {
                ITIN_Files.push({
                  Filename: file.name,
                  File_Data: cert_base64File,
                  Private: 0,
                  Size: FormatFileSize(Math.round((cert_base64File.length * 3) / 4)),
                });
                itin_RefreshAttachmentList();
              }
            };
            img.src = e.target.result;
          } else {
            cert_base64File = btoa(e.target.result);
            var filetype = file.name.split('.').pop().toLowerCase();
            if (filetype == 'pdf') {
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='UserExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File, 'UserExpenseDIV');
              }
              if (type == 'vaccination') {
                CUR_PASSENGER.vacc_Doc = e.target.result;
                CUR_PASSENGER.vacc_Doc_Name = file.name;
                $('#PAX_DB_vacc_Doc').val(null);
                PAX_SavePassenger(false, true);
                PAX_PopulatePaxFields();
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='AdminExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File, 'AdminExpenseDIV');
              }
            } else {
              $('#BILLING_ADMIN_EXPENSE_PHOTO').html('');
              $('#BILLING_EXPENSE_PHOTO').html('');
            }
            if (type == 'Itinerary' || type == 'ItineraryEdit') {
              ITIN_Files.push({
                Filename: file.name,
                Private: 0,
                File_Data: cert_base64File,
                Size: FormatFileSize(file.size),
              });
              itin_RefreshAttachmentList();
            }
          }
          ////console.log(cert_base64File);
          enableSaveFile(type);
        };
        reader.onerror = function (e) {
          cert_base64File = null;
          //console.log("FileReader Error");
          enableSaveFile(type);
        };
        if (file.type.indexOf('image') == -1) {
          console.log('readBinary');
          reader.readAsBinaryString(file);
        } else {
          console.log('readDataURL');
          reader.readAsDataURL(file);
        }
      }
    } else {
      cert_base64File = null;
      cert_Filename = 'N/A';
      //console.log("No Cert File Selected");
      enableSaveFile(type);
    }
  }
}

function Load_Android(type, id) {
  //console.log("Loading Android File");
  window.plugins.mfilechooser.open(
    ['.pdf', '.jpg', '.png', '.gif'],
    function (uri) {
      window.requestFileSystem(
        LocalFileSystem.PERSISTENT,
        0,
        function (fileSystem) {
          //console.log("0");
          fileSystem.root.getFile(
            uri,
            null,
            function (fileEntry) {
              //console.log("1");
              fileEntry.file(function (file) {
                $(id).val(file.name);
                getLocalFile(file, type);
              }, failFile);
            },
            failFile
          );
        },
        failFile
      );
    },
    function (error) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: iTrans('There was an error attempting to read the local file'),
        buttons: {
          [iTrans('OK')]: {
            click: function () {
              //fail
            },
          },
        },
      });
      //console.log("Reader Error");
    }
  );
}

function getLocalFile(file, type) {
  //console.log(file);
  if (file.size > 12582912 && (type == 'Itinerary' || type == 'ItineraryEdit')) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Max File Size'),
      headerClose: false,
      buttonPrompt:
        '<p>' +
        iTrans('The selected file is over the 12MB Itinerary size limit') +
        '.  ' +
        iTrans('Please reduce the file size and try again') +
        '</p>',
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            cert_base64File = null;
            cert_Filename = 'N/A';
          },
        },
      },
    });
    document.getElementById('ITINERARY_Attachment').value = '';
    return;
  }
  if (type == 'AssetHistoryInspect') {
    if (file != undefined) {
      cert_Filename2 = file.name;
      cert_Filesize = file.size;
      //console.log(cert_Filesize);
      if (cert_Filesize > 15728640) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Max File Size'),
          headerClose: false,
          buttonPrompt:
            '<p>' +
            iTrans('The selected file is over the 150MB size limit') +
            '.  ' +
            iTrans('Please reduce the file size and try again') +
            '</p>',
          buttons: {
            Close: {
              click: function () {
                cert_base64File2 = null;
                cert_Filename2 = 'N/A';
              },
            },
          },
        });
      } else {
        var reader = new FileReader();
        reader.onload = function (e) {
          if (file.type.indexOf('image') != -1) {
            var img = document.createElement('img');
            img.onload = function () {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
              var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
              var width = img.width;
              var height = img.height;
              //console.log(width);
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);

              var dataurl = canvas.toDataURL(file.type);
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html("<img src='" + dataurl + "' />");
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><img src='" + dataurl + "' />");
              }
              cert_base64File2 = dataurl.split(',')[1];
              //console.log(cert_base64File);
            };
            img.src = e.target.result;
          } else {
            cert_base64File2 = btoa(e.target.result);
            var filetype = file.name.split('.').pop().toLowerCase();
            if (filetype == 'pdf') {
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='UserExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File2, 'UserExpenseDIV');
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='AdminExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File2, 'AdminExpenseDIV');
              }
            } else {
              $('#BILLING_ADMIN_EXPENSE_PHOTO').html('');
              $('#BILLING_EXPENSE_PHOTO').html('');
            }
          }
          ////console.log(cert_base64File);
          enableSaveFile(type);
        };
        reader.onerror = function (e) {
          enableSaveFile(type);
          cert_base64File2 = null;
          //console.log("FileReader Error");
        };
        if (file.type.indexOf('image') != -1) {
          reader.readAsDataURL(file);
        } else {
          reader.readAsBinaryString(file);
        }
      }
    } else {
      enableSaveFile(type);
      cert_base64File2 = null;
      cert_Filename2 = 'N/A';
      //console.log("No Cert File Selected");
    }
  } else {
    if (file != undefined) {
      cert_Filename = file.name;
      cert_Filesize = file.size;
      //console.log(cert_Filesize);
      if (cert_Filesize > 15728640) {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Max File Size'),
          headerClose: false,
          buttonPrompt:
            '<p>' +
            iTrans('The selected file is over the 150MB size limit') +
            '.  ' +
            iTrans('Please reduce the file size and try again') +
            '</p>',
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                cert_base64File = null;
                cert_Filename = 'N/A';
              },
            },
          },
        });
      } else {
        var reader = new FileReader();
        reader.onload = function (e) {
          if (file.type.indexOf('image') != -1) {
            var img = document.createElement('img');
            img.onload = function () {
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
              var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
              var width = img.width;
              var height = img.height;
              //console.log(width);
              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else {
                if (height > MAX_HEIGHT) {
                  width *= MAX_HEIGHT / height;
                  height = MAX_HEIGHT;
                }
              }
              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);

              var dataurl = canvas.toDataURL(file.type);
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html("<img src='" + dataurl + "' />");
              }
              if (type == 'vaccination') {
                CUR_PASSENGER.vacc_Doc = dataurl;
                CUR_PASSENGER.vacc_Doc_Name = file.name;
                $('#PAX_DB_vacc_Doc').val(null);
                PAX_SavePassenger(false, true);
                PAX_PopulatePaxFields();
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><img src='" + dataurl + "' />");
              }
              if (type == 'FC_Admin') {
                $('#FC-Image_DIV').html("<img class='limit-table' src='" + dataurl + "' />");
                $('#FC-Image').val(dataurl);
                var now = new Date().toISOString();

                $('#FC-ImageDateTime').val(now.substr(0, 10) + ' ' + now.substr(11, 8));
              }
              cert_base64File = dataurl.split(',')[1];
              //console.log(cert_base64File);
              if (type == 'Itinerary' || type == 'ItineraryEdit') {
                ITIN_Files.push({
                  Filename: file.name,
                  File_Data: cert_base64File,
                  Private: 0,
                  Size: FormatFileSize(Math.round((cert_base64File.length * 3) / 4)),
                });
                itin_RefreshAttachmentList();
              }
            };
            img.src = e.target.result;
          } else {
            cert_base64File = btoa(e.target.result);
            var filetype = file.name.split('.').pop().toLowerCase();
            if (filetype == 'pdf') {
              if (type == 'Expense') {
                $('#BILLING_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='UserExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File, 'UserExpenseDIV');
              }
              if (type == 'vaccination') {
                CUR_PASSENGER.vacc_Doc = e.target.result;
                CUR_PASSENGER.vacc_Doc_Name = file.name;
                $('#PAX_DB_vacc_Doc').val(null);
                PAX_SavePassenger(false, true);
                PAX_PopulatePaxFields();
              }
              if (type == 'AdminExpense') {
                $('#BILLING_ADMIN_EXPENSE_PHOTO').html('<h3>' + file.name + "</h3><div id='AdminExpenseDIV'></div>");
                BILLING_ShowExpensePDF(cert_base64File, 'AdminExpenseDIV');
              }
            } else {
              $('#BILLING_ADMIN_EXPENSE_PHOTO').html('');
              $('#BILLING_EXPENSE_PHOTO').html('');
            }
            if (type == 'Itinerary' || type == 'ItineraryEdit') {
              ITIN_Files.push({
                Filename: file.name,
                Private: 0,
                File_Data: cert_base64File,
                Size: FormatFileSize(file.size),
              });
              itin_RefreshAttachmentList();
            }
          }
          ////console.log(cert_base64File);
          enableSaveFile(type);
        };
        reader.onerror = function (e) {
          enableSaveFile(type);
          cert_base64File = null;
          //console.log("FileReader Error");
        };
        if (file.type.indexOf('image') == -1) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsDataURL(file);
        }
      }
    } else {
      enableSaveFile(type);
      cert_base64File = null;
      cert_Filename = 'N/A';
      //console.log("No Cert File Selected");
    }
  }
}

var UserDevice = {};
UserDevice.Lat = '0';
UserDevice.Long = '0';
UserDevice.Accuracy = '0';
UserDevice.Altitude = '0';
UserDevice.AltitudeAccuracy = '0';
UserDevice.Heading = '0';
UserDevice.Speed = '0';
UserDevice.Timestamp = '0';

function getDeviceLocation() {
  //console.log("Requesting Device Location");
  navigator.geolocation.getCurrentPosition(DeviceLocation, noDeviceLocation, {
    maximumAge: 600000,
  });
}

function DeviceLocation(position) {
  UserDevice.Lat = position.coords.latitude;
  UserDevice.Long = position.coords.longitude;
  UserDevice.Accuracy = position.coords.accuracy;
  UserDevice.Altitude = position.coords.altitude;
  UserDevice.AltitudeAccuracy = position.coords.altitudeAccuracy;
  UserDevice.Heading = position.coords.heading;
  UserDevice.Speed = position.coords.speed;
  UserDevice.Timestamp = position.timestamp;
  //console.log("Device Location received");
}

function noDeviceLocation(error) {
  UserDevice.Lat = '0';
  UserDevice.Long = '0';
  //console.log("Failed to get Device Location");
}

function getDaysDiff(datetime) {
  if (datetime != null) {
    var time = datetime.split(' ');
    if (time.length > 1) {
      time = new Date(time[0] + 'T' + time[1] + 'Z');
    } else {
      time = new Date(time[0]);
    }
    var compareDate = new Date(time);
    //var ExpireDate = new Date("2014-10-10"); //testing
    var today = new Date();
    // get number of days until expirary
    var Diff;
    if (today < compareDate) {
      Diff = Math.round((compareDate.getTime() - today.getTime()) / 86400000);
    } else {
      Diff = Math.round((today.getTime() - compareDate.getTime()) / 86400000);
    }
    return Diff;
  } else {
    return 'Unknown';
  }
}

function startTime() {
  var today = new Date();
  var h = today.getUTCHours();
  var m = today.getUTCMinutes();
  //var s=today.getUTCSeconds();
  // add a zero in front of numbers<10
  m = checkTime(m);
  //s=checkTime(s);
  if ($('#Map_Header_Title').length) {
    document.getElementById('Map_Header_Title').innerHTML = h + ':' + m + ' UTC';
  }
  if ($('#GFA_Header_Title').length) {
    document.getElementById('GFA_Header_Title').innerHTML = h + ':' + m + ' UTC';
  }
  t = setTimeout(function () {
    startTime();
  }, 60000);
}

function checkTime(i) {
  if (i < 10) {
    i = '0' + i;
  }
  return i;
}

function time_Diff(start, end) {
  var H, H1, H2, M, M1, M2, DT;
  if (start.length < 3) {
    H1 = 0;
    M1 = parseInt(start);
  }
  if (end.length < 3) {
    H2 = 0;
    M2 = parseInt(end);
  }
  if (start.length == 3) {
    H1 = parseInt(start.substr(0, 1));
    M1 = parseInt(start.substr(1, 2));
  }
  if (end.length == 3) {
    H2 = parseInt(end.substr(0, 1));
    M2 = parseInt(end.substr(1, 2));
  }
  if (start.length == 4) {
    H1 = parseInt(start.substr(0, 2));
    M1 = parseInt(start.substr(2, 2));
  }
  if (end.length == 4) {
    H2 = parseInt(end.substr(0, 2));
    M2 = parseInt(end.substr(2, 2));
  }
  ////console.log("H:"+H2 +" M:"+M2);
  //if (H2 == 0 && M2 == 0 && H1 > 0 || H2 == 0 && M2 == 0 && M1 > 0) H2 = 24; //if old duty time ends at 0000 and start > 0
  H = H2 - H1;
  M = M2 - M1;
  if (M !== 0) {
    M = Math.round(M / 6) / 10;
    DT = H + M;
  } else {
    DT = H;
  }
  return DT;
}

let obj_init = false;
function Init_Objects() {
  if (obj_init === true) {
    return;
  }
  SAMA_SetUp();

  Dash = new DashBoard();
  obj_init = true;

  if (window.history && history.pushState) {
    // disable auto scroll restoration and push defualt null history entry
    history.scrollRestoration = 'manual';
    history.pushState(null, null, null);
  }

  updateVersionString();
}

$(window).on('navigate', function (event) {
  // prevent default back button behaviour
  event.preventDefault();
  history.pushState(null, null, null);
});

function updateVersionString() {
  $('.cirro-version').html(getVersionString());
}

function getVersionString() {
  let platform = `Chrome ${getChromeVersion()}`;
  let userName = LOCALSTORAGE?.chatname ?? '';
  let groupName = LOCALSTORAGE?.GROUP_DATA?.Company_Name ?? '';

  if (CORDOVA) {
    platform = `${device.platform} ${device.version}`;
  }

  if (ELECTRON) {
    let os = require('os');

    let platformsNames = {
      win32: 'Windows',
      darwin: 'MacOS',
      linux: 'Linux',
      sunos: 'sun',
      openbsd: 'OpenBSD',
      android: 'Android',
      aix: 'AIX',
    };

    const p = platformsNames[os.platform()];
    platform = `${p} ${os.release()}`;
  }
  return `${userName} - ${groupName}<br/> Cirro ${VERSION_NAME} on ${platform}`;
}

function getChromeVersion() {
  let raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  return raw ? parseInt(raw[2], 10) : false;
}

/**
 * Grabs elements by Id and toggles password visibility & button visibility
 */
function togglePassword() {
  let element = document.getElementById('login_password');
  let viewButton = document.getElementById('viewPassword');
  let hideButton = document.getElementById('hidePassword');
  if (element.type === 'password') {
    element.type = 'text';
    viewButton.style.display = 'none';
    hideButton.style.display = 'grid';
  } else if (element.type === 'text') {
    element.type = 'password';
    viewButton.style.display = 'grid';
    hideButton.style.display = 'none';
  }
}
