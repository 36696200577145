/**
 * Page class for viewing Policies and Objectives
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */
class pSAMA_PolicyObjectivesView extends pSAMA_AbstractPage {
  constructor() {
    super('SAMA_PAO_View', SAMA.pages.MenuHome);

    this.dom.tileGrid = this.dom.page.find('#pogCardList');
    this.dom.filters = this.dom.page.find('.sama-filter-location');
    this.dom.fileList = this.dom.page.find('#policy-file-listing');
    this.dom.policies = this.dom.page.find('.pol-policy');

    this.header = new cSAMA_PageHeader('Policies & Objectives', this.dom.page);

    this.viewObjectives = new vSAMA_TileGrid('No objectives found.');

    this.viewObjectives.GetDom().appendTo(this.dom.tileGrid);

    this.filter = new cSAMA_FilterObjectives(this.viewObjectives, true);
    this.filter.GetDom().appendTo(this.dom.filters);
  }

  /**
   * Function called when the user enters the page.
   * @override
   */

  EnterPage() {
    cSAMA_Request.Dispatch({
      reqs: new cSAMA_Request('PoliciesObjectives', 'getData'),
      name: 'GetPolObPage',
      clientErrorCode: 'SAMA 59 AE FA E0',
      fnSuccess: (_payload) => this.SetUpGui(_payload),
    });
  }

  SetUpGui(_payload) {
    this.policy = new mSAMA_Policy(_payload[0]);
    this.viewObjectives.Clear();

    if (
      this.policy.sync.adm_name == null ||
      this.policy.sync.pol_execSignature == null ||
      (this.policy.sync.pol_useOnlyFile && this.policy.files.length <= 0)
    ) {
      // Policies seem to be empty
      this.dom.page.find('.sama-content > .sama-list-empty').show();
      this.dom.page.find('.grid-container-fluid').hide();
      SAMA.Navigator.FinishedLoading(this.header);
      return;
    }

    if (this.policy.sync.pol_useOnlyFile) {
      this.dom.policies.hide();
    } else {
      this.dom.policies.show();
    }

    this.dom.fileList.html('');
    if (this.policy.files.length > 0) {
      cSAMA_GeneralGui.BuildFileList(this.policy.files, this.dom.fileList, 'policy');
      this.dom.fileList.show();
    } else {
      this.dom.fileList.hide();
    }

    // Policies are set!
    this.dom.page.find('.sama-content > .sama-list-empty').hide();
    this.dom.page.find('.grid-container-fluid').show();

    cSAMA_GeneralInputs.FillMarkup(this.policy, this.dom.page);

    let objectives = this.policy.GetObjectives();

    for (let i = 0; i < objectives.length; i++) {
      let nTile = new vSAMA_TilePolicyObjective(objectives[i]);
      this.viewObjectives.AddItem(nTile);
    }
    this.filter.RunFilters();

    SAMA.Navigator.FinishedLoading(this.header);
  }

  /**
   * Function called when the user leaves the page.
   * @override
   */

  LeavePage() {
    this.viewObjectives.Clear();
    SAMA.Navigator.FinishedLoading();
  }
}
