/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var OS92 = true; //Increase in Flight Time
var OS93 = true; //Increase in Flight Duty Time 15 hour duty day
var OS94 = true; //Time free from duty - 42 consecutive working days
var OS95 = false; //Program of Controlled Rest on the Flight Deck
var AC_CURRENCY = {};
var Last2CalendarMonths = null;
var Last4CalendarMonths = null;
var NVG_Report = {
  Totals: null,
  Reason: '',
  Current: true,
};
var IFR_Report = {
  Total_Hrs: null,
  Total_Appr: null,
  Reason: '',
  Current: true,
};
var DutyCheckStartDate = new Date('2020-12-12T00:00:00Z');

function day_Calc(data) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty-SHARED.day_Calc', '2024-06-26', 'bldarosa');
  data = data.slice();
  data.reverse(); //records from recent to past
  var inputErrors = {
    On: [],
    Start: [],
    FT: [],
    End: [],
    Off: [],
    On2: [],
    Start2: [],
    FT2: [],
    End2: [],
    Off2: [],
  };
  var inputMessages = {
    On: [],
    Start: [],
    FT: [],
    End: [],
    Off: [],
    On2: [],
    Start2: [],
    FT2: [],
    End2: [],
    Off2: [],
  };
  var Q4LastYear = false;
  var DayCurrent = false;
  var NightCurrent = false;
  var FloatCurrent = false;
  var IFRCurrent = false;
  var RT = 0; //Rest Time
  var RT17 = 0; //Max consecutive Rest Time within last 17 days in Days
  var DOin7 = false;
  var RT17T = 0;
  var RT30 = 0;
  var RT_found = false; //switch for rest time calculation
  var DT = 0; //Duty Time
  var DT14 = 0;
  var DT3 = 0;
  var DT7 = 0;
  var DT28 = 0;
  var DT365 = 0;
  var ODT = 0; //Overnight Duty time:  Is always 0 if pervious duty day didn't end at 2359 and next duty day doesn't start at 0000;
  var L3 = 0;
  var L7 = 0;
  var L28 = 0;
  var L30 = 0;
  var L42 = 0;
  var L90 = 0;
  var dTO_L90 = 0;
  var dLD_L90 = 0;
  var nTO_L90 = 0;
  var nLD_L90 = 0;
  var prevSplit = false;
  var ifr_AI_L180 = 0;
  var ifr_GA_L180 = 0;
  var ifr_IA_L180 = 0;
  var dTO_L180 = 0;
  var dLD_L180 = 0;
  var nTO_L180 = 0;
  var nLD_L180 = 0;
  var Over27DayDeployment_Violation = false;
  var fTO_L180 = 0;
  var fLD_L180 = 0;
  var CalMonthFT = 0;
  var FT90 = 0;
  var CalYearFT = 0;

  var CurrentAsOf = {
    Day90: null,
    Night90: null,
    Day180: null,
    Float180: null,
    IFR: null,
  };

  var CASR135_Noff = new Date(data[0].Date + 'T00:00:00Z');
  CASR135_Noff.setDate(CASR135_Noff.getDate() + 7);

  var Currency = '';
  var L180 = 0;
  var L365 = 0;

  var L6 = 0;
  var L29 = 0;
  var L41 = 0;
  var L89 = 0;
  var L179 = 0;
  var L364 = 0;

  var Q1 = 0;
  var Q2 = 0;
  var Q3 = 0;
  var Q4 = 0;
  var Q1R = 0;
  var Q2R = 0;
  var Q3R = 0;
  var Q4R = 0;
  var Q1C = 92;
  var Q2C = 91;
  var Q3C = 91;
  var Q4C = 91;
  var curQ = 0;
  var curQR = 0;
  var last2Q = 0;
  var lastQ = 0;
  var CalYear = 0;
  var TEMP = new Date(data[0].Date);
  var rYear = TEMP.getFullYear();
  var Off30 = 0;
  var Off30_Done = false;
  var Off42 = 0;
  var Off42T = 0;
  var Off90 = 0;
  var Off7 = 0;
  var Off28 = 0;
  var Off21 = 0;
  var FltDays = 0;
  var DtyDays = 0;
  var TotalDays = 0;
  var Noff = new Date(data[0].Date + 'T00:00:00Z');
  var Noff_Limit = 'Unknown';
  var Status = ''; //violation or warning status dialog text
  var Wstatus = '';
  var Vstatus = '';
  var Warning = false;
  var Violation = false;
  var ExtendNum = 0;
  var Extend = false;
  var ExtendON = false;
  var ExtendON42 = false;
  var ExtendON90 = false;
  var ExtendAvail = true;
  var Extendable = true;
  var OnCall = false;
  var TotalLast24 = '';
  var AA_rest = 0;
  if (OS92 == false) {
    ExtendAvail = false;
    Extendable = false;
  }
  var ExtendReason = '';
  var ExtendLast30 = false;
  var Need5Off = false;
  var count5Off = 0;

  var CurrencySummary = null;
  var pEXTRA = '';
  var pREC = '';
  var WP = 0;
  //data.reverse();//data is last 365 records of flight duty times plus number of records displayed in calendar view
  var rec = data[0]; //the record to be processed
  var Reserve = false;
  var Reserve2 = false;
  var FCP = rec.ET1;
  var pFCP = 0;
  var pLRF = 0;
  var pSA = 0;
  var CR = { Reserve: false, Reserve2: false };
  var CR2 = { Reserve: false, Reserve2: false };
  if (data.length > 1) {
    pFCP = data[1].ET1;
    pLRF = data[1].EC4;
    pSA = data[1].restType;
    CR = CheckReserveStatus(rec, data[1]);
    Reserve = CR.Reserve;
    Reserve2 = CR.Reserve2;
  } else {
    CR = CheckReserveStatus(rec, null);
  }
  Reserve = CR.Reserve;
  Reserve2 = CR.Reserve2;
  var pReserve = false;
  var pReserve2 = false;
  if (data.length > 2) {
    CR2 = CheckReserveStatus(data[1], data[2]);
  } else if (data.length > 1) {
    CR2 = CheckReserveStatus(data[1], null);
  }
  pReserve = CR2.Reserve;
  pReserve2 = CR2.Reserve2;
  if (rec.Timezone == undefined) {
    rec.Timezone = CurrentTimeZone;
  }
  var TZ_HourDif = Get_TZ_Difference(rec.Timezone);
  var Noff_3 = new Date(rec.Date);
  var Noff_7 = new Date(rec.Date);
  var Noff_14 = new Date(rec.Date); //for ausie ag
  var Noff_17 = new Date(rec.Date);
  var LTW = 0;
  var LTW14 = 0; //36 hours off in 14 days aussie ag work
  var dayFT = parseFloat(rec.FT) + parseFloat(rec.FT2);
  var nightFT = 0;
  var pFT = 0;
  var Overnight = false;
  var CheckOldOvernight = true;
  var AcclimatizationData = { Hours: 0, Timezone: rec.Timezone, DSTcorrected: false };

  var dutyEarly_in7d = false;
  var dutyLate_in7d = false;
  var dutyNight_in7d = false;
  var has_dutyOver12h_in7d = false;
  var has_work24h_in48h = false;
  var has_120off_in504h = false;
  var has_5lnoff_in504h = false;

  if (rec.Overnight == 1) {
    Overnight = true;
    CheckOldOvernight = false;
  } else {
    Overnight = false;
    var RecDate = new Date(rec.Date);
    var ReleaseDate = new Date('2020-10-17T00:00:00');
    if (RecDate.getTime() > ReleaseDate.getTime()) {
      CheckOldOvernight = false;
    }
  }

  var pOvernight = false;

  if (data.length == 1) {
    RT = RT + 24;
  } else {
    if (data[1].Timezone == undefined) {
      data[1].Timezone = CurrentTimeZone;
    }
    TZ_HourDif = Get_TZ_Difference(data[1].Timezone);
    pFT = parseFloat(data[1].FT) + parseFloat(data[1].FT2);
    pEXTRA = data[1].EXTRA;
    pREC = data[1];
    if (
      dutytime_Calc(data[1].End, data[1].Start2) >= 8 &&
      (data[1].Split == 1 || data[1].Split == 3) &&
      data[1].End2 == 2359
    ) {
      if (CheckOldOvernight) {
        Overnight = true;
      }
    }
    if (pREC.Split == 3 && pREC.Overnight == 1 && pREC.End2 != '2359' && pREC.End2 != 0) {
      prevSplit = pREC.End2;
    }
    if (
      (parseInt(data[1].End) == 2359 && parseInt(rec.Start) == 0) ||
      (parseInt(data[1].End2) == 2359 && parseInt(rec.Start) == 0) ||
      Overnight
    ) {
      if ((parseInt(rec.Start) == 0 && parseInt(rec.End) != 0) || Overnight) {
        if (CheckOldOvernight) {
          Overnight = true;
        }
        dayFT = parseFloat(rec.FT);
        var PrevDT = dutytime_Calc(data[1].Start, data[1].End);
        if (data[1].Overnight == 1) {
          PrevDT = 0;
        }
        if (data[1].Split == 0 || data[1].Split == 2) {
          nightFT = parseFloat(data[1].FT) + parseFloat(rec.FT);
        } else {
          nightFT = parseFloat(data[1].FT2) + parseFloat(rec.FT);
        }
        if (parseInt(data[1].End2) == 2359) {
          PrevDT = dutytime_Calc(data[1].Start2, data[1].End2);
          nightFT = parseFloat(data[1].FT2) + parseFloat(rec.FT);
          dayFT = nightFT;
        }
        if (parseInt(data[1].End) == 2359) {
          PrevDT = dutytime_Calc(data[1].Start, data[1].End);
          nightFT = parseFloat(data[1].FT) + parseFloat(rec.FT);
          dayFT = nightFT;
        }
        var PrevDT2 = dutytime_Calc(rec.Start, rec.End);
        ODT = Math.round((PrevDT + PrevDT2) * 10) / 10;
        nightFT = Math.round(nightFT * 10) / 10;
      }
    }
  }
  if (data.length > 2) {
    if (CheckOldOvernight) {
      if (parseInt(data[2].End) == 2359 || parseInt(data[2].End2) == 2359 || parseInt(data[1].Start) == 0) {
        if (parseInt(data[1].Start) == 0 && parseInt(data[1].End) != 0) {
          pOvernight = true;
        }
      }
    } else {
      if (data[1].Overnight == 1) {
        pOvernight = true;
      } else {
        pOvernight = false;
      }
    }
  }

  DT = duty_Calc(rec, Overnight, pREC); //get duty time for record

  if (Overnight) {
    ODT = DT;
  } else {
    if (rec.Split == 1 || rec.Split == 3) {
      var pREC_RT = 0;
      if (pREC.Split == 0 || pREC.Split == 2) {
        pREC_RT = dutytime_Calc(pREC.End, '2359') + dutytime_Calc('0', rec.Start) + TZ_HourDif;
      } else {
        pREC_RT = dutytime_Calc(pREC.End2, '2359') + dutytime_Calc('0', rec.Start) + TZ_HourDif;
      }

      if (dutytime_Calc(rec.End, rec.Start2) >= 8 && dutytime_Calc(rec.End, rec.Start2) > pREC_RT) {
        ODT = dutytime_Calc(rec.Start, rec.End);
      }
    }
  }
  if (DT == 0) {
    RT = 24; //rest time 24 hours if no duty day
    RT17 = 1;
    RT17T = 1;
    RT30 = 1;
    DOin7 = true;
  } else {
    RT = dutytime_Calc('0', rec.Start); //rest time sine midnight to start time
    if (Overnight) {
      if (pREC != '') {
        if (pREC.Split == 1) {
          RT = dutytime_Calc(pREC.End, pREC.Start2);
        } else {
          if (pREC.End == '2359') {
            RT = dutytime_Calc('0000', pREC.Start);
          } else {
            RT = dutytime_Calc(pREC.End, rec.Start) + 24;
          }
        }
      }
    }
  }
  if ((rec.Standby == 2 && rec.FT == 0) || (rec.Standby == 5 && rec.FT == 0) || (rec.Standby == 8 && rec.FT == 0)) {
    //Reserve duty time
    DT = 0;
    RT = dutytime_Calc('0', rec.Start); //rest time sine midnight to start time
  }
  if (
    rec.Standby == 10 ||
    rec.Standby == 16 ||
    rec.Standby == 9 ||
    rec.Standby == 33 ||
    rec.Standby == 34 ||
    rec.Standby == 35 ||
    rec.Standby == 43
  ) {
    OnCall = true;
  }
  if (rec.Date == '2020-11-04' && false) {
    console.log('Here');
    console.log('Rule: ' + rec.Rule);
    console.log(rec);
    console.log(Overnight);
    console.log(pREC);
    console.log('DT: ' + DT);
    console.log('ODT: ' + ODT);
    console.log('RT: ' + RT);
    console.log('nightFT:  ' + nightFT);
    console.log('dayFT:  ' + dayFT);
    console.log('Calc: ' + dutytime_Calc(rec.End, rec.Start2));
    console.log(rec);
  }

  var RT7 = RT; //Max consecutive Rest Time within last 7 days in Hours
  var RT14 = RT; //Max consecutive rest tiem within last 14 days in hours
  var aRT7; //For australian 7 day periods 2 consecutive days rest between 10pm and 6am local
  var RT7T = RT;
  var RT14T = RT;
  var RT21T = RT;
  var offIn21_LocalTZ = 0;
  var AdminWarning = null;
  var AdminViolation = null;
  var pDT = duty_Calc(data[1], pOvernight); //get previous day duty time

  var splitRest = 0;
  var DTadd = 0;
  var RTadd = 0;
  var FTadd = 0;
  var DTminus = 0; //for aussie long rest periods
  var Rule = rec.Rule;
  Rule = SetDefaultFDT_Rule(Rule); //in sync-IOS-FDT.js

  if (Rule != 702) {
    rec.AerialApp = 0;
  }
  if (rec.AerialApp == 0) {
    if (Rule == 2) {
      //australian ag work
      if (rec.Split == 1 || rec.Split == 3) {
        splitRest = dutytime_Calc(rec.End, rec.Start2);
        if (rec.Standby == 0) {
          //Suitable Sleeping
          if (splitRest >= 2 && splitRest < 3) {
            DTadd = splitRest;
          }
          if (splitRest >= 3) {
            DTadd = 3;
          }
        }
        if (rec.Standby == 1) {
          //Suitable Resting
          if (splitRest >= 3 && splitRest < 6) {
            DTadd = Math.round(splitRest * 0.5 * 10) / 10;
            if (DTadd > 2) {
              DTadd = 2;
            }
          }
          if (splitRest > 6) {
            DTadd = 2;
          }
        }
        if (splitRest >= 6) {
          DTminus = Math.round(splitRest * 0.5 * 10) / 10;
        }
      }
    } else if (Rule == 705) {
      //Implement 705 split duty rest time adding
      if (data.length > 1) {
        if ((rec.Split == 1 || rec.Split == 3) && Overnight == 0) {
          //reduce calculated rest by 45 minutes before calculation
          var newEnd = subDecToTime(rec.End, -0.75);
          if (newEnd > 2359) {
            newEnd = 2359;
          } //make sure it didn't pass midnight TODO this may need more checking
          splitRest = dutytime_Calc(newEnd, rec.Start2);
          // 100% if from 0 to 559
          //50% if 600 to 2359
          //UOC 50% during anytime
          var splitMult = 0.5;
          if (rec.End >= 0 && rec.End <= 59 && rec.EC1 != 1) {
            splitMult = 1;
          }
          DTadd = round10(splitRest * splitMult);
        }
      }
    } else {
      if (data.length > 1) {
        if (
          data[1].Split == 1 &&
          data[1].Overnight == 0 &&
          data[1].End == '2359' &&
          rec.Overnight == 1 &&
          (rec.Split == 1 || rec.Split == 3)
        ) {
          splitRest = dutytime_Calc(rec.End, rec.Start2);
        } else if ((data[1].Split == 2 || data[1].Split == 3) && data[1].End2 != '2359' && rec.Overnight == 1) {
          if (data[1].End2 != 0 && data[1].Start2 != 0) {
            splitRest = dutytime_Calc(data[1].End2, '2359') + dutytime_Calc('0000', rec.Start);
          } else {
            splitRest = dutytime_Calc(data[1].End, '2359') + dutytime_Calc('0000', rec.Start);
          }
        } else if ((rec.Split == 1 || rec.Split == 3) && rec.Overnight == 0 && rec.End != '2359') {
          splitRest = dutytime_Calc(rec.End, rec.Start2);
        }

        if (splitRest >= 4) {
          RTadd = Math.floor((splitRest / 2) * 10) / 10;
          if (Rule == 604) {
            //maximum of 4 hours for private operators
            if (RTadd > 4) {
              RTadd = 4;
            }
          } else if (RTadd > 3) {
            //maximum 3 hours for 703 operators
            RTadd = 3;
          }
          DTadd = RTadd;
        }
      }
    }
    //RTadd = 1;
  } else {
    //Aerial Work
    if (Rule == 702 && rec.AerialApp == 1) {
      if (RT != 24) {
        if (data.length > 1) {
          if ((data[1].Split == 1 || data[1].Split == 3) && data[1].End != '2359') {
            AA_rest = dutytime_Calc(data[1].End2, '2359') + dutytime_Calc('0', rec.Start) + TZ_HourDif;
          } else {
            AA_rest = dutytime_Calc(data[1].End, '2359') + dutytime_Calc('0', rec.Start) + TZ_HourDif;
          }
          if (AA_rest < 5) {
            //violation didn't get 5 hours rest between
            Violation = true;
            Status +=
              '<p>' +
              iTrans('Needed a minimum of 5 hours overnight rest for split duty during Aerial Application') +
              '</p>';
            Vstatus +=
              '' +
              iTrans('Needed a minimum of 5 hours overnight rest for split duty during Aerial Application') +
              '.<br />';
          }
          if (parseInt(data[1].End2) <= 2000 && parseInt(rec.Start) >= 600 && AA_rest >= 5) {
            //No violation minimum 5 hour rest period obtained entirely between required hours
          } else {
            if (parseInt(data[1].End2) > 2000) {
              var compRest = AA_rest - dutytime_Calc(data[1].End2, '2000');
              if (compRest < 5) {
                Violation = true;
                Status +=
                  '<p>' +
                  iTrans(
                    'Needed a minimum of 5 hours overnight rest between 20:00 and 06:00 for split duty during Aerial Application'
                  ) +
                  '</p>';
                Vstatus +=
                  '' +
                  iTrans(
                    'Needed a minimum of 5 hours overnight rest between 20:00 and 06:00 for split duty during Aerial Application'
                  ) +
                  '.<br />';
              }
            }
            if (parseInt(rec.Start) < 600) {
              var compRest = AA_rest - dutytime_Calc('0600', rec.Start);
              if (compRest < 5) {
                Violation = true;
                Status +=
                  '<p>' +
                  iTrans(
                    'Needed a minimum of 5 hours overnight rest between 20:00 and 06:00 for split duty during Aerial Application'
                  ) +
                  '</p>';
                Vstatus +=
                  '' +
                  iTrans(
                    'Needed a minimum of 5 hours overnight rest between 20:00 and 06:00 for split duty during Aerial Application'
                  ) +
                  '.<br />';
              }
            }
          }
          if (rec.Split == 1 || rec.Split == 3) {
            AA_rest += dutytime_Calc(rec.End, rec.Start2);
          } else {
            AA_rest += dutytime_Calc(rec.End, '2359');
          }
          if (AA_rest < 9) {
            Violation = true;
            Status +=
              '<p>' + iTrans('Needed a minimum of 9 hours opportunity to rest during Aerial Application') + '</p>';
            Vstatus +=
              '' + iTrans('Needed a minimum of 9 hours opportunity to rest during Aerial Application') + '.<br />';
          }
        }
      }
    }
  }
  var conDays = 0;
  var Reset42 = true;
  var Reset = false;
  var ResetFT = false;
  var conResetFT = 0;
  var pFT = 0;
  var pDual = 0;
  var Con_3_12 = 0;
  var Check36in7 = true;
  var Check36in14 = true; //ausie ag rule
  var Check72in14 = true; // DGAC DAN 137
  var ExtendConDays = false;
  var Extend42on = false;
  var Count27Con = 0;
  var HEMS_on = false;
  var HEMS_Length = 0;

  var MinRT = 12;
  var maxFT = 14;
  var maxDuty = null;
  if (rec.Extend == 1) {
    MinRT = 16;
  }

  //Unforeseen  operational Circumstance Used in previous days
  var UOC = {
    L1: false,
    L7: false,
    L30: false,
    L90: false,
  };

  if (rec.EC1 == 1) {
    UOC.L1 = true;
  }
  var DutyAdd = true;
  //var DutyAdd = false; //This will affect DT7 DT28 and DT365  Disabled because there is some typ eof Duty violation addition probloem going on.
  var Off_over_i12 = 0;

  for (var i in data) {
    var DutyCheckDate = new Date(data[i].Date + 'T00:00:00Z');
    if (DutyCheckDate.getTime() < DutyCheckStartDate.getTime()) {
      DutyAdd = false;
    }
    if (data[i].Timezone == undefined) {
      data[i].Timezone = CurrentTimeZone;
    }
    if (i > 0) {
      TZ_HourDif = Get_TZ_Difference(data[i].Timezone, data[parseInt(i) - 1].Timezone);
    }
    if (data[i].DAT == undefined) {
      data[i].DAT = 0;
    }
    if (data[i].DL == undefined) {
      data[i].DL = 0;
    }
    if (data[i].NT == undefined) {
      data[i].NT = 0;
    }
    if (data[i].NL == undefined) {
      data[i].NL = 0;
    }
    if (data[i].FTO == undefined) {
      data[i].FTO = 0;
    }
    if (data[i].FLD == undefined) {
      data[i].FLD = 0;
    }
    if (data[i].AI == undefined) {
      data[i].AI = 0;
    }
    if (data[i].GA == undefined) {
      data[i].GA = 0;
    }
    if (data[i].IA == undefined) {
      data[i].IA = 0;
    }
    var rDT = 0;
    //todo find out why rDT is adding up so big
    if (parseInt(i) + 1 != data.length) {
      rDT = duty_Calc(data[i], data[i].Overnight, data[parseInt(i) + 1]);
    } //get duty time for record
    if (i == 1) {
      pFT = parseFloat(data[i].FT) + parseFloat(data[i].FT2);
      if (isNaN(pFT)) {
        pFT = 0;
      }
      pDual = data[i].Dual;
    }

    var recFT1 = parseFloat(data[i].FT);
    if (isNaN(recFT1)) {
      recFT1 = 0;
    }
    var recFT2 = parseFloat(data[i].FT2);
    if (isNaN(recFT2)) {
      recFT2 = 0;
    }
    var rec_FT = recFT1 + recFT2;
    var rest_DT = duty_Calc(data[i]);
    if (isNaN(rest_DT)) {
      rest_DT = 0;
    }
    if (RT30 < 30) {
      if (rest_DT == 0) {
        RT30++;
      } else {
        RT30 = 0;
      }
    }

    if (i < 6) {
      if (rest_DT == 0 && i != 0) {
        conResetFT++;
      }
      if (conResetFT == 5) {
        ResetFT = true;
      }
    }
    if (i < 7) {
      if (data[i].Start == 0 && data[i].End == 0 && !DOin7) {
        CASR135_Noff = new Date(data[i].Date + 'T00:00:00Z');
        CASR135_Noff.setDate(CASR135_Noff.getDate() + 7);
        DOin7 = true;
      }
      if (data[i].EC1 == 1) {
        UOC.L7 = true;
      }
      if (rec.Rule == 705) {
        if ((data[i].Start > 200 && data[i].Start < 700) || (data[i].Start2 > 200 && data[i].Start2 < 700)) {
          dutyEarly_in7d = true;
        }
        if ((data[i].End >= 1 && data[i].End < 200) || (data[i].End2 >= 1 && data[i].End2 < 200)) {
          dutyLate_in7d = true;
        }
        if ((data[i].Start > 1300 && data[i].Start < 2359) || (data[i].Start2 > 1300 && data[i].Start2 < 2359)) {
          dutyNight_in7d = true;
        }

        var cur_i_DT = duty_Calc(
          data[i],
          data[i].Overnight == 1,
          data[parseInt(i) + 1] == undefined ? '' : data[parseInt(i) + 1]
        );
        var cur_i_48hWork =
          duty_Calc(data[i], data[i].Overnight, data[parseInt(i) + 1] == undefined ? '' : data[parseInt(i) + 1]) +
          duty_Calc(
            data[parseInt(i) + 1],
            data[parseInt(i) + 1] == undefined ? 0 : data[parseInt(i) + 1].Overnight,
            data[parseInt(i) + 2] == undefined ? '' : data[parseInt(i) + 2]
          );
        if (cur_i_DT > 12) {
          has_dutyOver12h_in7d = true;
        }
        if (cur_i_48hWork > 24) {
          has_work24h_in48h = true;
        }
      }
    }
    if (i > 12) {
      if (rest_DT == 0) {
        Off_over_i12++;
      }
    }
    if (i < 21 && rec.Rule == 705) {
      if (rest_DT == 0 && i != 0) {
        RT21T += 24;
      } else {
        RT21T = 0;
      }
      if (RT21T >= 120) {
        has_120off_in504h = true;
      }
      if (data[parseInt(i) + 1] != undefined) {
        if (data[i].Timezone == data[parseInt(i) + 1].Timezone) {
          offIn21_LocalTZ++;
        } else {
          offIn21_LocalTZ = 0;
        }
      }
      if (offIn21_LocalTZ >= 5) {
        has_5lnoff_in504h = true;
      }
    }

    if (i < 47) {
      if (rest_DT != 0 && i != 0) {
        conDays++;
      }
      if (rest_DT == 0) {
        conDays = 0;
      }
    }
    if (i < 4) {
      if (rDT > 12) {
        Con_3_12++;
      }
    }

    if (i < 3) {
      DT3 += rDT;
    }

    if (i < 6) {
      L6 += rec_FT;
    }
    if (i < 3) {
      L3 += rec_FT;
    }
    if (i < 7) {
      L7 += rec_FT;
      if (DutyAdd) {
        DT7 += rDT;
      }
      if (rDT == 0) {
        Off7++;
      }
      //Aussie count consecutive rest periods between 10pm and 6 am last 7 days
      if (Rule == 1) {
        if (i != 0 && aRT7 < 2) {
          if (pDT + DT > 11 || pFT + FT > 8) {
            var pEnd = parseInt(data[1].End);
            var pEnd2 = parseInt(data[1].End2);
            var tStart = parseInt(rec.Start);
            if (pEnd2 != 0 && pEnd2 <= 1100 && tStart != 0 && tStart >= 600) {
              aRT7++;
            } else if (pEnd != 0 && pEnd <= 1100 && tStart != 0 && tStart >= 600) {
              aRT7++;
            } else {
              aRT7 = 0;
            }
          }
        }
      }
      //check for most consecutive hours off duty in last 7 days
      if (i != 0 && RT7 < 36 && Check36in7) {
        if (rest_DT == 0) {
          RT7T += 24;
          if (RT7T > RT7) {
            RT7 = RT7T;
            if (RT7 >= 36) {
              //if RT7 is over 36 hours calculate next off

              Noff_7 = new Date(data[i].Date + 'T00:00:00Z');
              var LTWH = Math.round(LTW);
              LTWH = 24 - LTWH;
              Noff_7.setHours(LTWH);
              var LTWM = (LTW % 1) * 60;
              LTWM = Math.round(LTWM);
              Noff_7.setMinutes(LTWM);
              Noff_7.setHours(Noff.getHours() + 132);
              Check36in7 = false;
            }
          }
        } else {
          var p = parseInt(i) + 1;
          var P = parseInt(i) + 2;
          var AddP = false;
          if (data[p] != undefined) {
            if (data[p].Start == '0' && data[p].End == '0') {
              RT7T += 24;
              AddP = true;
            } else {
              if (data[p].Split == 0 || data[p].Split == 2) {
                RT7T += dutytime_Calc(data[p].End, '2400');
              }
              if (data[p].Split == 1 || data[p].Split == 3) {
                RT7T += dutytime_Calc(data[p].End2, '2400');
              }
            }
          }
          if (data[i].Split == 0 || data[i].Split == 2) {
            RT7T += dutytime_Calc('0', data[i].Start);
          }
          if (data[i].Split == 1 || data[i].Split == 3) {
            RT7T += dutytime_Calc('0', data[i].Start);
          }

          if (AddP && P < 7 && data[P] != undefined) {
            if (data[P].Start == '0' && data[P].End == '0') {
              RT7T += 24;
            }
            if (data[P].Split == 0 || data[P].Split == 2) {
              RT7T += dutytime_Calc(data[P].End, '2400');
            }
            if (data[P].Split == 1 || data[P].Split == 3) {
              RT7T += dutytime_Calc(data[P].End2, '2400');
            }
          }
          if (RT7T > RT7) {
            RT7 = RT7T;
            if (RT7 >= 36) {
              if (data[p] != undefined) {
                if (data[p].Start == '0' && data[p].End == '0') {
                  LTW = 24;
                } else {
                  if (data[p].Split == 0 || data[p].Split == 2) {
                    LTW = dutytime_Calc(data[p].End, '2400');
                  }
                  if (data[p].Split == 1 || data[p].Split == 3) {
                    LTW = dutytime_Calc(data[p].End2, '2400');
                  }
                }
              }
              if (data[i].Split == 0 || data[i].Split == 2) {
                LTW += dutytime_Calc('0', data[i].Start);
              }
              if (data[i].Split == 1 || data[i].Split == 3) {
                LTW += dutytime_Calc('0', data[i].Start);
              }
              if (AddP && P < 7 && data[P] != undefined) {
                if (data[P].Start == '0' && data[P].End == '0') {
                  LTW += 24;
                }
                if (data[P].Split == 0 || data[P].Split == 2) {
                  LTW += dutytime_Calc(data[P].End, '2400');
                }
                if (data[P].Split == 1 || data[P].Split == 3) {
                  LTW += dutytime_Calc(data[P].End2, '2400');
                }
              }
              Noff_7 = new Date(data[i].Date + 'T00:00:00Z');
              var LTWH = Math.round(LTW);
              LTWH = 24 - LTWH;
              Noff_7.setHours(LTWH);
              var LTWM = (LTW % 1) * 60;
              LTWM = Math.round(LTWM);
              Noff_7.setMinutes(LTWM);
              Noff_7.setHours(Noff.getHours() + 132);
              Check36in7 = false;
            }
          }
          RT7T = 0;
        }
      }
    }
    if (i < 14) {
      //for australian rules duty last 14 days
      if (Rule == 1 || Rule == 2) {
        DT14 += rDT;
        if (i != 0 && RT14 < 36 && Check36in14) {
          if (rest_DT == 0) {
            RT14T += 24;
            if (RT14T > RT14) {
              RT14 = RT14T;
              if (RT14 >= 36) {
                //if RT14 is over 36 hours calculate next off
                Noff_14 = new Date(data[i].Date + 'T00:00:00Z');
                var LTWH14 = Math.round(LTW14);
                LTWH14 = 24 - LTWH14;
                Noff_14.setHours(LTWH14);
                var LTWM14 = (LTW14 % 1) * 60;
                LTWM14 = Math.round(LTWM14);
                Noff_14.setMinutes(LTWM14);
                Noff_14.setHours(Noff.getHours() + 300); // + 12.5 days
                Check36in14 = false;
              }
            }
          } else {
            var p = parseInt(i) + 1;
            var P = parseInt(i) + 2;
            var AddP = false;
            if (data[p] != undefined) {
              if (data[p].Start == '0' && data[p].End == '0') {
                RT14T += 24;
                AddP = true;
              } else {
                if (data[p].Split == 0 || data[p].Split == 2) {
                  RT14T += dutytime_Calc(data[p].End, '2400');
                }
                if (data[p].Split == 1 || data[p].Split == 3) {
                  RT14T += dutytime_Calc(data[p].End2, '2400');
                }
              }
            }
            if (data[i].Split == 0 || data[i].Split == 2) {
              RT14T += dutytime_Calc('0', data[i].Start);
            }
            if (data[i].Split == 1 || data[i].Split == 3) {
              RT14T += dutytime_Calc('0', data[i].Start);
            }

            if (AddP && P < 7 && data[P] != undefined) {
              if (data[P].Start == '0' && data[P].End == '0') {
                RT14T += 24;
              }
              if (data[P].Split == 0 || data[P].Split == 2) {
                RT14T += dutytime_Calc(data[P].End, '2400');
              }
              if (data[P].Split == 1 || data[P].Split == 3) {
                RT14T += dutytime_Calc(data[P].End2, '2400');
              }
            }
            if (RT14T > RT14) {
              RT14 = RT14T;
              if (RT14 >= 36) {
                if (data[p] != undefined) {
                  if (data[p].Start == '0' && data[p].End == '0') {
                    LTW14 = 24;
                  } else {
                    if (data[p].Split == 0 || data[p].Split == 2) {
                      LTW14 = dutytime_Calc(data[p].End, '2400');
                    }
                    if (data[p].Split == 1 || data[p].Split == 3) {
                      LTW14 = dutytime_Calc(data[p].End2, '2400');
                    }
                  }
                }
                if (data[i].Split == 0 || data[i].Split == 2) {
                  LTW14 += dutytime_Calc('0', data[i].Start);
                }
                if (data[i].Split == 1 || data[i].Split == 3) {
                  LTW14 += dutytime_Calc('0', data[i].Start);
                }
                if (AddP && P < 7 && data[P] != undefined) {
                  if (data[P].Start == '0' && data[P].End == '0') {
                    LTW14 += 24;
                  }
                  if (data[P].Split == 0 || data[P].Split == 2) {
                    LTW14 += dutytime_Calc(data[P].End, '2400');
                  }
                  if (data[P].Split == 1 || data[P].Split == 3) {
                    LTW14 += dutytime_Calc(data[P].End2, '2400');
                  }
                }
                Noff_14 = new Date(data[i].Date + 'T00:00:00Z');
                var LTWH14 = Math.round(LTW14);
                LTWH14 = 24 - LTWH14;
                Noff_14.setHours(LTWH14);
                var LTWM14 = (LTW14 % 1) * 60;
                LTWM14 = Math.round(LTWM14);
                Noff_14.setMinutes(LTWM14);
                Noff_14.setHours(Noff.getHours() + 300); // + 12.5 days
                Check36in14 = false;
              }
            }
            RT14T = 0;
          }
        }
      }
      //for DGAC DAN 137 72 consecutive hourse off in last 14 days
      if (Rule == 5 && Check72in14) {
        DT14 += rDT;
        if (i != 0 && RT14 < 72) {
          if (rest_DT == 0) {
            RT14T += 24;
            if (RT14T > RT14) {
              RT14 = RT14T;
              if (RT14 >= 72) {
                //if RT14 is over 36 hours calculate next off
                Noff_14 = new Date(data[i].Date + 'T00:00:00Z');
                var LTWH14 = Math.round(LTW14);
                LTWH14 = 24 - LTWH14;
                Noff_14.setHours(LTWH14);
                var LTWM14 = (LTW14 % 1) * 60;
                LTWM14 = Math.round(LTWM14);
                Noff_14.setMinutes(LTWM14);
                Noff_14.setHours(Noff.getHours() + 264); // + 11 days
                Check72in14 = false;
              }
            }
          } else {
            var p = parseInt(i) + 1;
            var P = parseInt(i) + 2;
            var AddP = false;
            if (data[p] != undefined) {
              if (data[p].Start == '0' && data[p].End == '0') {
                RT14T += 24;
                AddP = true;
              } else {
                if (data[p].Split == 0 || data[p].Split == 2) {
                  RT14T += dutytime_Calc(data[p].End, '2400');
                }
                if (data[p].Split == 1 || data[p].Split == 3) {
                  RT14T += dutytime_Calc(data[p].End2, '2400');
                }
              }
            }
            if (data[i].Split == 0 || data[i].Split == 2) {
              RT14T += dutytime_Calc('0', data[i].Start);
            }
            if (data[i].Split == 1 || data[i].Split == 3) {
              RT14T += dutytime_Calc('0', data[i].Start);
            }

            if (AddP && P < 7 && data[P] != undefined) {
              if (data[P].Start == '0' && data[P].End == '0') {
                RT14T += 24;
              }
              if (data[P].Split == 0 || data[P].Split == 2) {
                RT14T += dutytime_Calc(data[P].End, '2400');
              }
              if (data[P].Split == 1 || data[P].Split == 3) {
                RT14T += dutytime_Calc(data[P].End2, '2400');
              }
            }
            if (RT14T > RT14) {
              RT14 = RT14T;
              if (RT14 >= 72) {
                if (data[p] != undefined) {
                  if (data[p].Start == '0' && data[p].End == '0') {
                    LTW14 = 24;
                  } else {
                    if (data[p].Split == 0 || data[p].Split == 2) {
                      LTW14 = dutytime_Calc(data[p].End, '2400');
                    }
                    if (data[p].Split == 1 || data[p].Split == 3) {
                      LTW14 = dutytime_Calc(data[p].End2, '2400');
                    }
                  }
                }
                if (data[i].Split == 0 || data[i].Split == 2) {
                  LTW14 += dutytime_Calc('0', data[i].Start);
                }
                if (data[i].Split == 1 || data[i].Split == 3) {
                  LTW14 += dutytime_Calc('0', data[i].Start);
                }
                if (AddP && P < 7 && data[P] != undefined) {
                  if (data[P].Start == '0' && data[P].End == '0') {
                    LTW14 += 24;
                  }
                  if (data[P].Split == 0 || data[P].Split == 2) {
                    LTW14 += dutytime_Calc(data[P].End, '2400');
                  }
                  if (data[P].Split == 1 || data[P].Split == 3) {
                    LTW14 += dutytime_Calc(data[P].End2, '2400');
                  }
                }
                Noff_14 = new Date(data[i].Date + 'T00:00:00Z');
                var LTWH14 = Math.round(LTW14);
                LTWH14 = 24 - LTWH14;
                Noff_14.setHours(LTWH14);
                var LTWM14 = (LTW14 % 1) * 60;
                LTWM14 = Math.round(LTWM14);
                Noff_14.setMinutes(LTWM14);
                Noff_14.setHours(Noff.getHours() + 264); // + 11 days
                Check72in14 = false;
              }
            }
            RT14T = 0;
          }
        }
      }
    }
    if (i < 17) {
      if (i != 0 && RT17 < 3) {
        //check for most consecutive calendar days off in last 17 days
        if (rest_DT == 0) {
          RT17T++;
          if (RT17T > RT17) {
            RT17 = RT17T;
          }
        } else {
          RT17T = 0;
        }
        if (RT17 == 3) {
          Noff_17 = new Date(data[i].Date + 'T00:00:00Z');
          Noff_17.setDate(Noff_17.getDate() + 17);
        }
      }
    }
    if (i < 21) {
      if (rDT == 0) {
        Off21++;
      }
    }
    if (i < 28) {
      if (rDT == 0) {
        Off28++;
      }
      L28 += rec_FT;
      if (DutyAdd) {
        DT28 += rDT;
      }
    }
    if (i < 29 && !Reset) {
      L29 += rec_FT;
    }
    if (i < 30) {
      if (data[i].EC1 == 1) {
        UOC.L30 = true;
      }

      if (!Reset) {
        L30 += rec_FT;
      } //stop adding flight time when extension resets previous flight times
      if (rest_DT == 0) {
        Off30++;
      } else if (i == 0) {
        //check for 24 hours rest since last duty and today start
        var checkRT24 = dutytime_Calc('0', data[i].Start); //rest time sine midnight to start time
        var prevRT = 0;
        if (parseInt(i) + 1 < data.length - 1) {
          var PrevRec = data[parseInt(i) + 1];
          if (PrevRec.Split == 1 || PrevRec.Split == 3) {
            if (parseInt(PrevRec.End2) == 0) {
              prevRT = 0;
            } else {
              prevRT = dutytime_Calc(PrevRec.End2, '2400');
            }
          } else {
            if (parseInt(PrevRec.End) == 0) {
              prevRT = 0;
            } else {
              prevRT = dutytime_Calc(PrevRec.End, '2400');
            }
          }
          if (checkRT24 + prevRT >= 24) {
            Off30++;
          }
        }
      }

      if (Off30 == 3 && Reset42 && rec.AerialApp == 0 && (rec.Rule == 702 || rec.Rule == 703) && Off30_Done == false) {
        //Had 3 off in 30 days
        var Temp_Noff = new Date(data[i].Date + 'T00:00:00Z');
        Temp_Noff.setDate(Temp_Noff.getDate() + 30);
        if (Noff.getTime() < Temp_Noff.getTime() || Noff_Limit == 'Unknown') {
          Noff = new Date(data[i].Date + 'T00:00:00Z');

          Noff.setDate(Noff.getDate() + 30);
          Noff_Limit = '3do in 30d';
        }
        Off30_Done = true;
        //Noff = new Date(); //testing
      }
      if (Off30 == 5 && rec.AerialApp == 1) {
        //had 5 days off in 30 days for canadian aerial application
        var Temp_Noff = new Date(data[i].Date + 'T00:00:00Z');
        Temp_Noff.setDate(Temp_Noff.getDate() + 30);
        if (Noff.getTime() < Temp_Noff.getTime() || Noff_Limit == 'Unknown') {
          Noff = new Date(data[i].Date + 'T00:00:00Z');
          Noff.setDate(Noff.getDate() + 30);
          Noff_Limit = '5do in 30d';
        }
        Off30_Done = true;
        //Noff = new Date(); //testing
      }
      if (data[i].Extend == 1 && i != 0) {
        // reject current record extension if available or on;
        if (rec.Extend == 1) {
          //rec.Extend = 0;
          if (Rule == 702 || Rule == 703) {
            Violation = true;
            Status +=
              '<p>' +
              iTrans('A previous non overlapping period of 30 days started on') +
              ' ' +
              data[i].Date +
              ' ' +
              iTrans('is interferring with this record') +
              '</p>';
            Vstatus +=
              '' +
              iTrans('A previous non overlapping period of 30 days started on') +
              ' ' +
              data[i].Date +
              ' ' +
              iTrans('is interferring with this record') +
              '.<br />';
          }
        }
        ExtendLast30 = true; //Duty extended within last 30 days
      }
      if (data[i].Extend == 1) {
        ExtendON = true;
        ExtendAvail = false; //Extension available if not used in past 30 days;
        Extendable = false;
      }
    }
    if (i < 41 && !Reset) {
      L41 += rec_FT;
    }
    if (i < 42) {
      if (data[i].Extend == 1) {
        ExtendON42 = true;
      }
      if (!Reset) {
        L42 += rec_FT;
      }
    }
    if (i < 47) {
      if (rest_DT == 0) {
        Off42++;
      } else {
        //check for 24 hours rest since last duty and today start
        var checkRT24 = dutytime_Calc('0', rec.Start); //rest time sine midnight to start time
        var prevRT = 0;
        var checkRT24 = dutytime_Calc('0', data[i].Start); //rest time sine midnight to start time
        var prevRT = 0;
        if (parseInt(i) + 1 < data.length - 1) {
          var PrevRec = data[parseInt(i) + 1];
          if (PrevRec.Split == 1 || PrevRec.Split == 3) {
            if (parseInt(PrevRec.End2) == 0) {
              prevRT = 0;
            } else {
              prevRT = dutytime_Calc(PrevRec.End2, '2400');
            }
          } else {
            if (parseInt(PrevRec.End) == 0) {
              prevRT = 0;
            } else {
              prevRT = dutytime_Calc(PrevRec.End, '2400');
            }
          }
          if (checkRT24 + prevRT >= 24) {
            Off42++;
          }
        }
      }
    }

    if (i < 52 && OS94 && rec.Logging == 0 && !Extend42on && (rec.Rule == 702 || rec.Rule == 703)) {
      //set next day off 42 days from most recent 5 consecutive days ops spec 94
      if (rec.Logging == 0) {
        if (
          duty_Calc(data[parseInt(i) + 1]) == 0 &&
          duty_Calc(data[parseInt(i) + 2]) == 0 &&
          duty_Calc(data[parseInt(i) + 3]) == 0 &&
          duty_Calc(data[parseInt(i) + 4]) == 0 &&
          duty_Calc(data[parseInt(i) + 5]) == 0 &&
          OS94
        ) {
          var Temp_Noff = new Date(data[i].Date + 'T00:00:00Z');
          Temp_Noff.setDate(Temp_Noff.getDate() + 42);
          if (Noff.getTime() < Temp_Noff.getTime() || Noff_Limit == 'Unknown') {
            Noff = Temp_Noff;
            Noff_Limit = '5do b&a 42d';
          }
          Reset42 = false;
          Extend42on = true;
        }
      }
    }

    if (i < 52 && OS94 && (rec.Rule == 702 || rec.Rule == 703)) {
      if ((conDays > 27 && Extend42on) || (Off_over_i12 >= 3 && Extend42on)) {
        //check if next off is same as todays date
        var recDate = new Date(rec.Date + 'T00:00:00Z');
        //trigger violation if > 0 duty time recorded
        //if over 27 days check for another 4 consecutive days off
        if (Noff.getTime() <= recDate.getTime() && DT != 0 && !Over27DayDeployment_Violation) {
          Over27DayDeployment_Violation = true;
          Violation = true;
          Status +=
            '<p class="redbg">' + iTrans('Over 27 consecutive day deployment 5 consecutive days off required') + '</p>';
          Vstatus += '' + iTrans('Over 27 consecutive day deployment 5 consecutive days off required') + '<br />';
        }
      }
    }
    if (i < 89 && !Reset) {
      L89 += rec_FT;
    }
    if (i < 90) {
      if (data[i].EC1 == 1) {
        UOC.L90 = true;
      }
      if (data[i].Extend == 1) {
        ExtendON90 = true;
      }

      if (!Reset && !(Rule == 3 && RT30 == 30)) {
        L90 += rec_FT;
      } //stop adding flight time when extension resets previous flight times OR CASR135 30 consecutive days off
      if (rest_DT == 0) {
        Off90++;
        if (Off90 == 13) {
          var Temp_Noff = new Date(data[i].Date + 'T00:00:00Z');
          Temp_Noff.setDate(Temp_Noff.getDate() + 90);
          if (Noff.getTime() > Temp_Noff.getTime() || Noff_Limit == 'Unknown') {
            Noff = Temp_Noff;
            Noff_Limit = '13do in 90d';
          }
        }
      } else {
        var checkRT24 = dutytime_Calc('0', data[i].Start); //rest time sine midnight to start time
        var prevRT = 0;
        if (parseInt(i) + 1 < data.length - 1) {
          var PrevRec = data[parseInt(i) + 1];
          if (PrevRec.Split == 1 || PrevRec.Split == 3) {
            if (parseInt(PrevRec.End2) == 0) {
              prevRT = 0;
            } else {
              prevRT = dutytime_Calc(PrevRec.End2, '2400');
            }
          } else {
            if (parseInt(PrevRec.End) == 0) {
              prevRT = 0;
            } else {
              prevRT = dutytime_Calc(PrevRec.End, '2400');
            }
          }
          if (checkRT24 + prevRT >= 24) {
            Off90++;
          }
        }
      }
      dTO_L90 += data[i].DAT;
      dLD_L90 += data[i].DL;
      nTO_L90 += data[i].NT;
      nLD_L90 += data[i].NL;
      if (CurrentAsOf.Day90 == null && dTO_L90 + nTO_L90 >= 3 && dLD_L90 + nLD_L90 >= 3) {
        CurrentAsOf.Day90 = data[i].Date;
      }
      if (CurrentAsOf.Night90 == null && nTO_L90 >= 3 && nLD_L90 >= 3) {
        CurrentAsOf.Night90 = data[i].Date;
      }
      FT90 += round10(parseFloat(data[i].FT) + parseFloat(data[i].FT2));
    }

    if (i < 179) {
      L179 += rec_FT;
    }
    if (i < 180) {
      L180 += rec_FT;

      ifr_AI_L180 += data[i].AI;
      ifr_GA_L180 += data[i].GA;
      ifr_IA_L180 += data[i].IA;
      dTO_L180 += data[i].DAT;
      dLD_L180 += data[i].DL;
      nTO_L180 += data[i].NT;
      nLD_L180 += data[i].NL;
      fTO_L180 += data[i].FTO;
      fLD_L180 += data[i].FLD;
      if (CurrentAsOf.Day180 == null && dTO_L180 + nTO_L180 >= 5 && dLD_L180 + nLD_L180 >= 5) {
        CurrentAsOf.Day180 = data[i].Date;
      }
      if (CurrentAsOf.Night180 == null && nTO_L180 >= 5 && nLD_L180 >= 5) {
        CurrentAsOf.Night180 = data[i].Date;
      }
      if (CurrentAsOf.Float180 == null && fTO_L180 >= 5 && fLD_L180 >= 5) {
        CurrentAsOf.Float180 = data[i].Date;
      }
      if (CurrentAsOf.IFR == null && ifr_AI_L180 >= 6 && ifr_GA_L180 + ifr_IA_L180 >= 6) {
        CurrentAsOf.IFR = data[i].Date;
      }
    }
    if (i < 364) {
      L364 += rec_FT;
    }
    if (i < 365) {
      if (!(Rule == 3 && RT30 == 30)) {
        L365 += rec_FT;
      }
      if (DutyAdd) {
        DT365 += rDT;
      }
      if (rec_FT > 0) {
        FltDays++;
      }
      if (rec_FT == 0 && rest_DT > 0) {
        DtyDays++;
      }
      //count max 6 X 30 non overlapping days here
      if (data[i].Extend == 1) {
        ExtendNum++;
      } //increment number of extensions
      var Uday = new Date(data[i].Date + 'T00:00:00Z');
      var qYear = Uday.getFullYear();
      var qMonth = Uday.getMonth(); //for part 135
      var qDay = Uday.getDate();
      //var Q4LastYear = false;
      if (qYear == rYear) {
        CalYear += rec_FT;
      }
      if (qMonth >= 0 && qMonth < 3 && qDay > 0 && qDay < 32 && qYear == rYear) {
        if (i == 0) {
          curQ = 1;
        }
        Q1 += rec_FT; //jan 1 through mar 31
        if (rest_DT == 0) {
          Q1R++;
        }
        Q1C--;
        if (i < 92) {
          Q4LastYear = true;
        }
      }
      if (qMonth > 2 && qMonth < 6 && qDay >= 0 && qDay < 31 && qYear == rYear) {
        if (i == 0) {
          curQ = 2;
        }
        Q2 += rec_FT; //apr 1 through june 30
        if (rest_DT == 0) {
          Q2R++;
        }
        Q2C--;
      }
      if (qMonth > 5 && qMonth < 9 && qDay >= 0 && qDay < 31 && qYear == rYear) {
        if (i == 0) {
          curQ = 3;
        }
        Q3 += rec_FT; //july 1 through sept 30
        if (rest_DT == 0) {
          Q3R++;
        }
        Q3C--;
      }
      if (!Q4LastYear && qMonth > 8 && qMonth < 12 && qDay > 0 && qDay < 32 && qYear == rYear) {
        if (i == 0) {
          curQ = 4;
        }
        Q4 += rec_FT; //Oct 1 through dec 31 this year
        if (rest_DT == 0) {
          Q4R++;
        }
        Q4C--;
      }
      if (Q4LastYear && qMonth > 8 && qMonth < 12 && qDay > 0 && qDay < 32 && qYear == rYear - 1) {
        if (i == 0) {
          curQ = 4;
        }
        Q4 += rec_FT; //Oct 1 through dec 31 last year
        if (rest_DT == 0) {
          Q4R++;
        }
        Q4C--;
      }
    }

    if (!RT_found && i != 0) {
      //calculate total rest period since last duty time current record already processed
      if (rest_DT == 0) {
        RT += 24;
      } else {
        if (
          (rec.Start == '0000' && data[i].End == '2359') ||
          (rec.Start == '0000' && data[i].End2 == '2359') ||
          (rec.Start == '0' && data[i].End == '2359') ||
          (rec.Start == '0' && data[i].End2 == '2359')
        ) {
          //Worked during midnight

          if (data[i].Split == 0 || data[i].Split == 2 || data[i].End == '2359') {
            //Not split duty day

            if (data[i].End == '2359' && data[i].Start > rec.Start) {
              if (!Overnight) {
                RT += dutytime_Calc(rec.End, data[i].Start);
              }
            }
          } else {
            //split duty day
            if (data[i].End2 == '2359' && data[i].Start2 > rec.Start) {
              if (data[i].End > rec.Start) {
                RT += dutytime_Calc(data[i].End, data[i].Start2);
              } else {
                RT += dutytime_Calc(rec.End, data[i].Start2);
              }
            }
          }
        } else {
          //Didn't work during midnight
          if (ODT == 0) {
            if (data[i].Split == 0 || data[i].Split == 2) {
              RT += dutytime_Calc(data[i].End, '2400');
            }
            if (data[i].Split == 1 || data[i].Split == 3) {
              RT += dutytime_Calc(data[i].End2, '2400');
            }
          }
        }
        RT_found = true; //flag to end continuous rest period calc
      }
    }
    if (data[i].Extend == 1) {
      //Reset Last 30, 42 and 90 day counts if 5 consecutive days off prior to extension

      if (rest_DT != 0) {
        if (
          duty_Calc(data[parseInt(i) + 1]) == 0 &&
          duty_Calc(data[parseInt(i) + 2]) == 0 &&
          duty_Calc(data[parseInt(i) + 3]) == 0 &&
          duty_Calc(data[parseInt(i) + 4]) == 0 &&
          duty_Calc(data[parseInt(i) + 5]) == 0 &&
          OS92
        ) {
          Reset = true;
        }
      } else {
        if (
          duty_Calc(data[parseInt(i) + 1]) == 0 &&
          duty_Calc(data[parseInt(i) + 2]) == 0 &&
          duty_Calc(data[parseInt(i) + 3]) == 0 &&
          duty_Calc(data[parseInt(i) + 4]) == 0 &&
          OS92
        ) {
          Reset = true;
        }
      }
    }
    if (data[i].Date.substr(0, 4) == data[0].Date.substr(0, 4)) {
      CalYearFT += round10(parseFloat(data[i].FT) + parseFloat(data[i].FT2));
    }
    if (data[i].Date.substr(0, 7) == data[0].Date.substr(0, 7)) {
      CalMonthFT += round10(parseFloat(data[i].FT) + parseFloat(data[i].FT2));
    }
    //end loop
  }

  if (data.length <= 27) {
    Off30 = 3;
  } //prevent warning / violations for previous records that don't exist
  if (data.length <= 77) {
    Off90 = 13;
  }

  TotalDays = FltDays + DtyDays;

  L7 = round10(L7);
  L30 = round10(L30);
  L42 = round10(L42);
  L90 = round10(L90);
  L180 = round10(L180);
  L365 = round10(L365);

  //begin duty time rule checking
  if (Rule == 5) {
    //DGAC DAN 137
    if (RT7 < 36) {
      Violation = true;
      Status += '<p>' + iTrans('Less than 36 consecutive hours rest in previous week, had') + ': ' + RT7 + '</p>';
      Vstatus += iTrans('Less than 36 consecutive hours rest in previous week, had') + ': ' + RT7 + '<br />';
    }
    if (RT14 < 72) {
      Violation = true;
      Status += '<p>' + iTrans('Less than 72 consecutive hours rest in previous 2 weeks, had') + ': ' + RT14 + '</p>';
      Vstatus += iTrans('Less than 72 consecutive hours rest in previous 2 weeks, had') + ': ' + RT14 + '<br />';
    }
    if (CalMonthFT > 100) {
      Violation = true;
      Status += '<p>' + iTrans('Over 100 hours flight time in calendar month, had') + ': ' + CalMonthFT + '</p>';
      Vstatus += iTrans('Over 100 hours flight time in calendar month, had') + ': ' + CalMonthFT + '<br />';
    }
    if (CalYearFT > 1000) {
      Violation = true;
      Status += '<p>' + iTrans('Over 1000 hours flight time in calendar year, had') + ': ' + CalYearFT + '</p>';
      Vstatus += iTrans('Over 1000 hours flight time in calendar year, had') + ': ' + CalYearFT + '<br />';
    }
    if (FT90 > 270) {
      Violation = true;
      Status += '<p>' + iTrans('Over 270 hours flight time in trimester, had') + ': ' + FT90 + '</p>';
      Vstatus += iTrans('Over 270 hours flight time in trimester, had') + ': ' + FT90 + '<br />';
    }
    var reqRest = 10;
    switch (Math.floor(DT)) {
      case 7:
        reqRest = 10;
        break;
      case 8:
        reqRest = 12;
        break;
      case 9:
        reqRest = 13;
        break;
      case 10:
        reqRest = 14;
        break;
      case 11:
      case 12:
        reqRest = 15;
        break;
      case 13:
        reqRest = 16;
        break;
      case 14:
        reqRest = 17;
        break;
    }
    if (DT > 14) {
      reqRest = 17;
    }
    var restPCT = 1;
    if (DT > 14) {
      restPCT = (DT - 14) / 14;
      if (restPCT > 0.2) {
        restPCT = 0.2;
        Violation = true;
        Status += '<p class="redbg">' + iTrans('Over 20% increase in duty time') + '</p>';
        Vstatus += iTrans('Over 20% increase in duty time') + '<br />';
      }
      restPCT += 1;
      reqRest = round10(reqRest * restPCT);
    }
    if (dayFT < 6 && DT > 14) {
      Status += '<p>' + iTrans('Need') + ' ' + reqRest + ' ' + iTrans('hours rest tonight') + '</p>';
      Wstatus += iTrans('Need') + ' ' + reqRest + ' ' + iTrans('hours rest tonight') + '<br />';
    }

    switch (Math.floor(pDT)) {
      case 7:
        reqRest = 10;
        break;
      case 8:
        reqRest = 12;
        break;
      case 9:
        reqRest = 13;
        break;
      case 10:
        reqRest = 14;
        break;
      case 11:
      case 12:
        reqRest = 15;
        break;
      case 13:
        reqRest = 16;
        break;
      case 14:
        reqRest = 17;
        break;
    }
    if (pDT > 14) {
      reqRest = 17;
    }
    restPCT = 1;
    if (pDT > 14) {
      restPCT = (pDT - 14) / 14;
      if (restPCT > 0.2) restPCT = 0.2;
      restPCT += 1;
      reqRest = round10(reqRest * restPCT);
    }
    if (dayFT >= 6 && DT > 14) {
      Violation = true;
      Status += '<p class="redbg">' + iTrans('Over 14 hours duty time and over 6 hours flight time') + '</p>';
      Vstatus += iTrans('Over 14 hours duty time and over 6 hours flight time') + '<br />';
    }

    if (pFT < 6 && pDT > 14) {
      //If Flight time is less than 6 hours, DUTY TIME can be extended 20% by extending also rest times 20%
      if (RT < reqRest) {
        Violation = true;
        Status +=
          '<p class="redbg">' +
          iTrans('Needed') +
          ' ' +
          reqRest +
          ' ' +
          iTrans('hours rest time, had') +
          ' ' +
          RT +
          '</p>';
        Vstatus += iTrans('Needed') + ' ' + reqRest + ' ' + iTrans('hours rest time, had') + ' ' + RT + '<br />';
      }
    } else {
      if (RT < reqRest) {
        Violation = true;
        Status +=
          '<p class="redbg">' +
          iTrans('Needed') +
          ' ' +
          reqRest +
          ' ' +
          iTrans('hours rest time, had') +
          ' ' +
          RT +
          '</p>';
        Vstatus += iTrans('Needed') + ' ' + reqRest + ' ' + iTrans('hours rest time, had') + ' ' + RT + '<br />';
      }
    }
  }

  var MaxNextFltTimes = {
    L6: '',
    L29: '',
    L41: '',
    L89: '',
    L179: '',
    L364: '',
    MaxTime: 'Unknown',
    Limiter: 'N/A',
  };
  if (Rule == 702 || Rule == 703 || Rule == 704 || Rule == 705 || Rule == 604 || Rule == 0) {
    var check;
    MaxNextFltTimes = getMaxNextFltTimes(
      dayFT,
      Rule,
      ExtendON,
      Standby,
      Logging,
      Heli,
      rec.Dual,
      DHC,
      L6,
      L29,
      L41,
      L89,
      L179,
      L364
    );
    check = checkPrevDuty(pDT, dayFT, ExtendON, UOC);
    if (check.maxFT != null) {
      maxFT = check.maxFT;
    }
    if (check.result) {
      //Warning = true;
      RTadd = RTadd + 1;
      Status += '<p>' + check.text + '</p>';
      Wstatus += check.text + '<br />';
    }
    if (!check.result) {
      //prevent duplicate over 8 hours violation
      check = checkPrevDuty(DT, dayFT, ExtendON, UOC);
      if (check.maxFT != null) {
        maxFT = check.maxFT;
      }
      if (check.result) {
        //Warning = true;
        RTadd = RTadd + 1;
        Status += '<p>' + check.text + '</p>';
        Wstatus += check.text + '<br />';
      }
    }
    if (!check.result) {
      //prevent duplicate over 8 hours violation
      check = checkPrevDuty(ODT, nightFT, ExtendON, UOC);
      if (check.maxFT != null) {
        maxFT = check.maxFT;
      }
      if (check.result) {
        //Warning = true;
        RTadd = RTadd + 1;
        Status += '<p>' + check.text + '</p>';
        Wstatus += check.text + '<br />';
      }
    }

    if (ExtendON == 1) {
      //only for 6 non overlapping periods
      if (dayFT != 0) {
        check = check42(dayFT);
        if (check.result) {
          Violation = true;
          Status += '<p>' + check.text + '</p>';
          Vstatus += check.text + '<br />';
        }
        check = check42(nightFT);
        if (check.result) {
          Violation = true;
          Status += '<p>' + check.text + '</p>';
          Vstatus += check.text + '<br />';
        }
        check = check180(dayFT, Rule);
        if (check.result) {
          Violation = true;
          Status += '<p>' + check.text + '</p>';
          Vstatus += check.text + '<br />';
        }
        check = check180(nightFT, Rule);
        if (check.result) {
          Violation = true;
          Status += '<p>' + check.text + '</p>';
          Vstatus += check.text + '<br />';
        }
      }
    }

    check = check1(dayFT, DT, ExtendON, rec.IFR, rec.Dual, RT, UOC, rec);
    if (check.result) {
      Violation = true;
      Status += '<p class="redbg">' + check.text + '</p>';
      Vstatus += check.text + '<br />';
    }
    check = check1(nightFT, ODT, ExtendON, rec.IFR, rec.Dual, RT, UOC, rec);
    if (check.result) {
      Violation = true;
      Status += '<p class="redbg">' + check.text + '</p>';
      Vstatus += check.text + '<br />';
    }

    check = checkRT(
      RT,
      dayFT,
      ExtendON,
      pDT,
      RTadd,
      pFT,
      rec.AerialApp,
      rec.Split,
      Rule,
      Overnight,
      UOC,
      pReserve,
      pReserve2,
      pFCP,
      pLRF,
      pSA
    );
    if (check.result) {
      Violation = true;
      Status += '<p class="yellowbg">' + check.text + '</p>';
      Vstatus += check.text + '<br />';
    }
    //shared
    if (Rule == 705) {
      //Duty rules don't apply until after December 12, 2020

      //TODO can go 70 hours if:
      //120 consecutive hours free from duty including 5 consecutive local nights rest in any 504 consecutive hours and
      //not assigned late or early duty in last 7 days
      //early duty means hours of work that begin between 02:00 and 06:59 at the location where the flight crew member is acclimatized;
      //late duty means hours of work that end between midnight and 01:59 at the location where the flight crew member is acclimatized;
      //night duty means hours of work that begin between 13:00 and 01:59 and that end after 01:59 at a location where the flight crew member is acclimatized
      //no duty period greater than 12 hours
      //maximum work of 24 hours in any consecutive 48 hours
      if (
        has_120off_in504h &&
        has_5lnoff_in504h &&
        !dutyEarly_in7d &&
        !dutyLate_in7d &&
        !dutyNight_in7d &&
        !has_dutyOver12h_in7d &&
        !has_work24h_in48h
      ) {
        if (DT7 > 70) {
          Violation = true;
          Status += '<p class="redbg">' + iTrans('Over 70 hours duty time in last 7 days') + '</p>';
          Vstatus += iTrans('Over 70 hours duty time in last 7 days') + '<br />';
        }
      } else {
        if (DT7 > 60) {
          Violation = true;
          Status +=
            '<p class="redbg">' +
            iTrans('Over 60 hours duty time in last 7 days. Worked ') +
            DT7 +
            ' ' +
            iTrans('Hours') +
            '</p>';
          Vstatus += iTrans('Over 60 hours duty time in last 7 days. Worked ') + DT7 + ' ' + iTrans('Hours') + '<br />';
        }
      }
      if (DT28 > 192) {
        Violation = true;
        Status += '<p class="redbg">' + iTrans('Over 192 hours duty time in last 28 days') + '</p>';
        Vstatus += iTrans('Over 192 hours duty time in last 28 days') + '<br />';
      }
      if (DT365 > 2200) {
        Violation = true;
        Status += '<p class="redbg">' + iTrans('Over 2200 hours duty time in last 365 days') + '</p>';
        Vstatus += iTrans('Over 2200 hours duty time in last 365 days') + '<br />';
      }

      if (Off7 < 1 && Off28 < 4 && Off21 < 5) {
        Violation = true;
        Status +=
          '<p class="redbg">' +
          iTrans('Needed 1 day off in 7 and 4 days off in 28 days OR 5 days off within 21 days') +
          '</p>';
        Vstatus += iTrans('Needed 1 day off in 7 and 4 days off in 28 days OR 5 days off within 21 days') + '<br />';
      }
    }
    if (dayFT != 0) {
      check = check7(L7, Rule, rec.Heli, rec.DHC, UOC);
      if (check.result) {
        Violation = true;
        Status += '<p>' + check.text + '</p>';
        Vstatus += check.text + '<br />';
      }
      if (Rule == 705) {
        L30 = L28;
      }
      check = check30(L30, Rule, ExtendON, rec.Standby, rec.Logging, rec.Heli, rec.Dual, rec.DHC, OnCall, UOC);
      if (check.result) {
        Violation = true;
        Status += '<p>' + check.text + '</p>';
        Vstatus += check.text + '<br />';
      }
      check = check90(L90, Rule, ExtendON, UOC);
      if (check.result) {
        Violation = true;
        Status += '<p>' + check.text + '</p>';
        Vstatus += check.text + '<br />';
      }
      check = check365(L365, Rule);
      if (check.result) {
        Violation = true;
        Status += '<p>' + check.text + '</p>';
        Vstatus += check.text + '<br />';
      }
    }
    if (rec.Rule == 705 && !Overnight && data.length > 2) {
      var tData1 = data.slice();
      tData1.shift();
      var tData2 = tData1.slice();
      tData2.shift();
      var PrevDuty = duty_Calc(data[1], data[1].Overnight, data[2]);
      var CRC = CheckReserveStatus(data[1], data[2]);
      check = checkDuty(
        PrevDuty,
        999,
        0,
        0,
        0,
        0,
        rec.Split,
        L30,
        705,
        0,
        UOC,
        CRC.Reserve,
        CRC.Reserve2,
        tData1,
        tData2,
        data[1].Overnight
      );
      if (check.result && PrevDuty - check.maxDuty >= 1) {
        Violation = true;
        Status +=
          '<p class="redbg">' +
          iTrans('Needed') +
          ' ' +
          PrevDuty +
          ' ' +
          iTrans('Rest Time had only ') +
          ' ' +
          RT +
          ' ' +
          iTrans('Hours') +
          '</p>'; //text could contain warning
        Vstatus +=
          iTrans('Needed') +
          ' ' +
          PrevDuty +
          ' ' +
          iTrans('Rest Time had only ') +
          ' ' +
          RT +
          ' ' +
          iTrans('Hours') +
          '<br />';
      }
    }
    if (Overnight) {
      var tData = data.slice();
      tData.shift();
      check = checkDuty(
        ODT,
        RT,
        ExtendON,
        DTadd,
        nightFT,
        rec.AerialApp,
        rec.Split,
        L30,
        Rule,
        RTadd,
        UOC,
        Reserve,
        Reserve2,
        pREC,
        tData,
        Overnight
      );
      if (pREC.Rule == 705) {
        AcclimatizationData = check.Acc;
        if (ODT - check.maxDuty >= 1 && check.result) {
          check.text += '. ' + ODT + ' ' + iTrans('Hours rest required');
        }
      }
      if (check.maxDuty != null) {
        maxDuty = check.maxDuty;
      }
      DT = 0;
      if (rec.Split == 1 || rec.Split == 3) {
        DT = dutytime_Calc(rec.Start2, rec.End2);
      }
      dayFT = nightFT;
    } else {
      check = checkDuty(
        DT,
        RT,
        ExtendON,
        DTadd,
        dayFT,
        rec.AerialApp,
        rec.Split,
        L30,
        Rule,
        RTadd,
        UOC,
        Reserve,
        Reserve2,
        rec,
        data,
        Overnight
      );
      if (rec.Rule == 705) {
        AcclimatizationData = check.Acc;
        if (DT - check.maxDuty >= 1 && check.result) {
          check.text += '. ' + DT + ' ' + iTrans('Hours rest required');
        }
      }
      if (check.maxDuty != null) {
        maxDuty = check.maxDuty;
      }
    }
    if (check.result) {
      Violation = true;
      Status += '<p class="redbg">' + check.text + '</p>'; //text could contain warning
      Vstatus += check.text + '<br />';
    }
    if (check.text != '' && !check.result) {
      Status += check.text + '<br />';
    }
    check = checkOff30(Off30, Off42, Extend42on, Rule, rec.Logging, rec.DHC, rec.AerialApp, rec.Split);
    if (check.result) {
      Violation = true;
      Status += '<p>' + check.text + '</p>';
      Vstatus += check.text + '<br />';
    }
    if (Off30 > 3 && Off30 <= 4 && !Extend42on) {
      Warning = true;
      Status += '<p>' + iTrans('WARNING: Approaching 3 off in 30 day limit.') + '</p>';
      Wstatus += iTrans('WARNING: Approaching 3 off in 30 day limit.') + '<br />';
    }

    check = checkOff717(DT, RT7, RT17, Rule, rec.Heli, rec.DHC, Noff_7, Noff_17, Noff);

    if (check.result) {
      Violation = true;
      Status += '<p>' + check.text + '</p>';
      Vstatus += check.text + '<br />';
    } else {
      Noff = check.Noff;
      if (check.Noff_Limit != '') {
        Noff_Limit = check.Noff_Limit;
      }
    }

    check = checkOff90(Off90, Extend, Rule, rec.Heli);
    if (check.result) {
      Violation = true;
      Status += '<p>' + check.text + '</p>';
      Vstatus += check.text + '<br />';
    }
    if (Off90 >= 13 && Off90 <= 15 && data.length >= 90) {
      Warning = true;
      Status += '<p>' + iTrans('WARNING: Approaching or at 13 off in 90 day limit.') + '</p>';
      Wstatus += iTrans('WARNING: Approaching or at 13 off in 90 day limit.') + '<br />';
    }

    if (Rule == 704 || Rule == 705) {
      if (Con_3_12 == 3) {
        //Requires 24 hours rest starting tomorrow
        Warning = true;
        Status +=
          '<p>' +
          iTrans('Had 3 consecutive days over 12 hours duty time.  Require 24 consecutive hour rest period') +
          '.</p>';
        Wstatus +=
          '' +
          iTrans('Had 3 consecutive days over 12 hours duty time.  Require 24 consecutive hour rest period') +
          '.<br />';
        Noff = new Date(rec.Date + 'T00:00:00Z');
        Noff.setDate(Noff.getDate() + 1);
        Noff_Limit = '3con DT > 12hr';
      }
      if (Con_3_12 >= 3 && DT > 0) {
        var test = duty_Calc(data[3]);
        if (test > 12) {
          Noff = new Date(rec.Date + 'T00:00:00Z');
          Violation = true;
          Status +=
            '<p>' +
            iTrans(
              'Had 3 consecutive days over 12 hours duty time and required 24 consecutive hour rest period not taken'
            ) +
            '.</p>';
          Vstatus +=
            iTrans(
              'Had 3 consecutive days over 12 hours duty time and required 24 consecutive hour rest period not taken'
            ) + '.<br />';
        }
      } else {
      }
    }

    //check for flight duty time and rest period warnings only on days with duty time logged
    if (dayFT != 0) {
      if (ExtendON) {
        //Extend flight duty limitations
        if (rec.Heli == 1 && rec.Logging == 1 && rec.Dual == 1) {
          if (120 >= L30 && L30 > 100) {
            Warning = true;
            Status += '<p>' + iTrans('Approaching 120 hrs in 30 days') + '</p>';
            Wstatus += '' + iTrans('Approaching 120 hrs in 30 days') + '<br />';
          }
        } else {
          if (150 >= L30 && L30 > 130) {
            Warning = true;
            Status += '<p>' + iTrans('Approaching 150 hrs in 30 days') + '</p>';
            Wstatus += '' + iTrans('Approaching 150 hrs in 30 days') + '<br />';
          }
        }
      }

      if (210 >= L42 && L42 > 180 && ExtendON42) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 210 hrs in 42 days') + '</p>';
        Wstatus += iTrans('Approaching 210 hrs in 42 days') + '<br />';
      }
      if (450 >= L90 && L90 > 430 && ExtendON90) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 450 hrs in 90 days') + '</p>';
        Wstatus += iTrans('Approaching 450 hrs in 90 days') + '<br />';
      }
      if (900 >= L180 && L180 > 850) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 900 hrs in 180 days') + '</p>';
        Wstatus += iTrans('Approaching 900 hrs in 180 days') + '<br />';
      }

      //Normal flight duty limitations
      if (120 >= L30 && L30 > 100 && !ExtendON) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 120 hrs in 30 days') + '</p>';
        Wstatus += iTrans('Approaching 120 hrs in 30 days') + '<br />';
      }
      if (300 >= L90 && L90 > 280 && !ExtendON90) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 300 hrs in 90 days') + '</p>';
        Wstatus += iTrans('Approaching 300 hrs in 90 days') + '<br />';
      }

      //warnings shared for all
      if (1200 >= L365 && L365 > 1100) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 1200 hrs in 365 days') + '</p>';
        Wstatus += iTrans('Approaching 1200 hrs in 365 days') + '<br />';
      }
    }

    if (ExtendNum > 5) {
      ExtendReason += iTrans('Already used 6 extensions within last 365 days') + '<br />';
    }
    if (ExtendLast30 == true) {
      ExtendReason += iTrans('Extension currently in use') + '<br />';
    }
    if (ExtendLast30 == false && ExtendNum < 6) {
      ExtendAvail = true;
    }

    if (ExtendAvail == false) {
      ResetFT = false;
    }

    var current = checkCurrency(
      ifr_AI_L180,
      ifr_GA_L180,
      ifr_IA_L180,
      dTO_L90,
      dLD_L90,
      nTO_L90,
      nLD_L90,
      dTO_L180,
      dLD_L180,
      nTO_L180,
      nLD_L180,
      fTO_L180,
      fLD_L180,
      CurrentAsOf,
      Rule
    );
    if (current.result) {
      Currency += current.text;
    }
    DayCurrent = current.DayCurrent;
    NightCurrent = current.NightCurrent;
    IFRCurrent = current.IFRCurrent;

    CurrencySummary = current;
  } else if (Rule == 135 || Rule == 131 || Rule == 133) {
    //Non scheduled operations
    var checkFT24_results = checkFT24(rec.EXTRA, rec, pEXTRA, pREC, Rule);
    //don't check anything if CountTimes is false
    if (checkFT24_results.CountTimes === true) {
      //check rest time restrictions
      var add = 0;
      var DayRest = dutytime_Calc(rec.End, rec.Start2);
      if (
        (rec.Split == '1' || rec.Split == '3') &&
        !Overnight &&
        rec.Start > 0 &&
        rec.End != 0 &&
        rec.Start2 > 0 &&
        rec.end2 != 2359 &&
        DayRest >= 8
      ) {
        //check each individual FT, FT2 to ensure not over 10 hours
        check = checkRT135(parseFloat(rec.FT), rec.Dual, Rule, MinRT);
        add = -check.add;
        if (check.result) {
          Warning = true;
          Status += '<p>' + check.text + '</p>';
          Wstatus += check.text + '<br />';
        }
        check = checkRT135(parseFloat(rec.FT2), rec.Dual, Rule, MinRT);
        add = -check.add;
        if (check.result) {
          Warning = true;
          Status += '<p>' + check.text + '</p>';
          Wstatus += check.text + '<br />';
        }
      } else {
        check = checkRT135(dayFT, rec.Dual, Rule, MinRT);
        add = -check.add;
        if (check.result) {
          Warning = true;
          Status += '<p>' + check.text + '</p>';
          Wstatus += check.text + '<br />';
        }
      }
      //Status += "Debug2: " + pFT + "<br />";
      if (data[1] != undefined) {
        check = checkPrevRT135(pFT, RT, pDual, data[1]);
        if (check.result) {
          Violation = true;
          Status += '<p>' + check.text + '</p>';
          Vstatus += check.text + '<br />';
        }
      }

      if (rec.Extend == 1 && Rule != 131) {
        ExtendON = 1;
        //135.267 (C)  Do something else don't know what
        var DayRest = dutytime_Calc(rec.End, rec.Start2);
        if (
          (rec.Split == '1' || rec.Split == '3') &&
          !Overnight &&
          rec.Start > 0 &&
          rec.End != 0 &&
          rec.Start2 > 0 &&
          rec.end2 != 2359 &&
          DayRest >= 8
        ) {
          if (rec.Dual == 1) {
            if (parseFloat(rec.FT) > 10 || parseFloat(rec.FT2) > 10) {
              Violation = true;
              Status += '<p>' + iTrans('Over 10 Hour Maximum Flight Time') + '</p>';
              Vstatus += iTrans('Over 10 Hour Maximum Flight Time') + '<br />';
            }
            maxFT = 10;
          } else {
            if (parseFloat(rec.FT) > 8 || parseFloat(rec.FT2) > 8) {
              Violation = true;
              Status += '<p>' + iTrans('Over 8 Hour Maximum Flight Time') + '</p>';
              Vstatus += iTrans('Over 8 Hour Maximum Flight Time') + '<br />';
            }
            maxFT = 8;
          }

          //Regular Day shift
        } else {
          if (rec.Dual == 1) {
            if (dayFT > 10) {
              Violation = true;
              Status += '<p>' + iTrans('Over 10 Hour Maximum Flight Time') + '</p>';
              Vstatus += iTrans('Over 10 Hour Maximum Flight Time') + '<br />';
            }
            maxFT = 10;
          } else {
            if (dayFT > 8) {
              Violation = true;
              Status += '<p>' + iTrans('Over 8 Hour Maximum Flight Time') + '</p>';
              Vstatus += iTrans('Over 8 Hour Maximum Flight Time') + '<br />';
            }
            maxFT = 8;
          }
        }
      } else {
        ExtendON = 0;
        //135.267 (B)  limit to rolling 24 hour look back for flight time
        //125.271 (C) limit to 8 hours rolling 24 hour look back for flight time
        var Roll25_Rec = checkFT135EXTRA(rec.EXTRA, rec, add);
        var Roll25_pRec = checkFT135EXTRA(pEXTRA, pREC, add);

        TotalLast24 = checkFT24_results.Total;

        if (dayFT > 0) {
          if (!Roll25_Rec.empty) {
            //Status += "<p>Debug: "+ Roll25_Rec.Debug+"</p>";
            var whichDay = 'Tomorrow';
            if (Roll25_Rec.FirstNeg) {
              whichDay = 'Today';
            }
            var whichDay2 = 'Tomorrow';
            if (Roll25_Rec.StartNeg) {
              whichDay2 = 'Today';
            }
            var whichDay3 = 'Tomorrow';
            if (Roll25_Rec.FlightNeg) {
              whichDay3 = 'Today';
            }
            if (rec.Dual == 1 && Rule != 131) {
              Status +=
                '<p>' + iTrans('Earliest Duty Start') + ' ' + iTrans(whichDay) + ' @ ' + Roll25_Rec.First + '<br />';
              if (!Roll25_Rec.noEarly) {
                Status +=
                  iTrans('Earliest Flight Start') +
                  ' ' +
                  iTrans(whichDay3) +
                  ' @ ' +
                  Roll25_Rec.Flight +
                  ' ' +
                  iTrans('For') +
                  ' ' +
                  round10(Roll25_Rec.Duration) +
                  ' ' +
                  iTrans('hour(s) Max') +
                  '<br />';
              }
              Status +=
                iTrans('10 Hour Flight Possible') +
                ' ' +
                iTrans(whichDay2) +
                ' ' +
                iTrans('Starting') +
                ' @ ' +
                Roll25_Rec.Start +
                '</p>';
            } else {
              Status +=
                '<p>' + iTrans('Earliest Duty Start') + ' ' + iTrans(whichDay) + ' @ ' + Roll25_Rec.First + '<br />';
              if (!Roll25_Rec.noEarly) {
                Status +=
                  iTrans('Earliest Flight Start') +
                  ' ' +
                  iTrans(whichDay3) +
                  ' @ ' +
                  Roll25_Rec.Flight +
                  ' ' +
                  iTrans('For') +
                  ' ' +
                  Roll25_Rec.Duration +
                  ' ' +
                  iTrans('hour(s) Max') +
                  '<br />';
              }
              Status +=
                iTrans('8 Hour Flight Possible') +
                ' ' +
                iTrans(whichDay2) +
                ' ' +
                iTrans('Starting') +
                ' @ ' +
                Roll25_Rec.Start +
                '</p>';
            }
          }

          if (rec.Dual == 1 && Rule != 131 && TotalLast24 > 10) {
            Violation = true;
            Status +=
              '<p>' +
              iTrans('Over 10 Hour Maximum Flight Time') +
              ' [ ' +
              TotalLast24 +
              ' ] ' +
              iTrans('within 24 hour period') +
              '</p>';
            Vstatus += iTrans('Over 10 Hour Maximum Flight Time within 24 hour period') + '<br />';
          }
          if (rec.Dual == 0 && Rule != 131 && TotalLast24 > 8) {
            Violation = true;
            Status +=
              '<p>' +
              iTrans('Over 8 Hour Maximum Flight Time') +
              ' [ ' +
              TotalLast24 +
              ' ] ' +
              iTrans('within 24 hour period') +
              '</p>';
            Vstatus += iTrans('Over 8 Hour Maximum Flight Time within 24 hour period') + '<br />';
          }
        }
      }

      if (curQ != 0) {
        Noff = new Date(data[0].Date + 'T00:00:00Z');
        var off135 = 0;

        if (curQ == 1) {
          lastQ = Q1;
          last2Q = Q1 + Q4;
          off135 = Q1R + Q1C - 13;
          curQR = Q1R + Q1C;
          //if (Q1C <= 13 && Q1R >= 13) off135 = Q2C-13;
        }
        if (curQ == 2) {
          off135 = Q2R + Q2C - 13;
          lastQ = Q2;
          last2Q = Q2 + Q1;
          curQR = Q2R + Q2C;
        }
        if (curQ == 3) {
          off135 = Q3R + Q3C - 13;
          lastQ = Q3;
          last2Q = Q2 + Q3;
          curQR = Q3R + Q3C;
        }
        if (curQ == 4) {
          off135 = Q4R + Q4C - 13;
          lastQ = Q4;
          last2Q = Q4 + Q3;
          curQR = Q4R + Q4C;
        }
        Noff.setDate(Noff.getDate() + off135);
      }
      if (Q1R < 13 && Q1R + Q1C >= 12 && Q1R + Q1C < 20 && curQ == 1) {
        Warning = true;
        Status +=
          '<p>' +
          iTrans('13 Rest Periods Required for Q1 had') +
          ':' +
          Q1R +
          '. ' +
          Q1C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q1</p>';
        Wstatus +=
          iTrans('13 Rest Periods Required for Q1 had') +
          ':' +
          Q1R +
          '. ' +
          Q1C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q1<br />';
      }
      if (Q2R < 13 && Q2R + Q2C >= 12 && Q2R + Q2C < 20 && curQ == 2) {
        Warning = true;
        Status +=
          '<p>' +
          iTrans('13 Rest Periods Required for Q2 had') +
          ':' +
          Q2R +
          '. ' +
          Q2C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q2</p>';
        Wstatus +=
          iTrans('13 Rest Periods Required for Q2 had') +
          ':' +
          Q2R +
          '. ' +
          Q2C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q2<br />';
      }
      if (Q3R < 13 && Q3R + Q3C >= 12 && Q3R + Q3C < 20 && curQ == 3) {
        Warning = true;
        Status +=
          '<p>' +
          iTrans('13 Rest Periods Required for Q3 had') +
          ':' +
          Q3R +
          '. ' +
          Q3C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q3</p>';
        Wstatus +=
          iTrans('13 Rest Periods Required for Q3 had') +
          ':' +
          Q3R +
          '. ' +
          Q3C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q3<br />';
      }
      if (Q4R < 13 && Q4R + Q4C >= 12 && Q4R + Q4C <= 20 && curQ == 4) {
        Warning = true;
        Status +=
          '<p>' +
          iTrans('13 Rest Periods Required for Q4 had') +
          ':' +
          Q4R +
          '. ' +
          Q4C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q14/p>';
        Wstatus +=
          iTrans('13 Rest Periods Required for Q4 had') +
          ':' +
          Q4R +
          '. ' +
          Q4C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q4<br />';
      }

      if (Q1R + Q1C < 13 && curQ == 1) {
        Violation = true;
        Status +=
          '<p>' +
          iTrans('Needed 13 rest periods for Q1 had') +
          ':' +
          Q1R +
          '. ' +
          Q1C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q1</p>';
        Vstatus +=
          iTrans('Needed 13 rest periods for Q1 had') +
          ':' +
          Q1R +
          '. ' +
          Q1C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q1<br />';
      }
      if (Q2R + Q2C < 13 && curQ == 2) {
        Violation = true;
        Status +=
          '<p>' +
          iTrans('Needed 13 rest periods for Q2 had') +
          ':' +
          Q2R +
          '. ' +
          Q2C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q2</p>';
        Vstatus +=
          iTrans('Needed 13 rest periods for Q2 had') +
          ':' +
          Q2R +
          '. ' +
          Q2C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q2<br />';
      }
      if (Q3R + Q3C < 13 && curQ == 3) {
        Violation = true;
        Status +=
          '<p>' +
          iTrans('Needed 13 rest periods for Q3 had') +
          ':' +
          Q3R +
          '. ' +
          Q3C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q3</p>';
        Vstatus +=
          iTrans('Needed 13 rest periods for Q3 had') +
          ':' +
          Q3R +
          '. ' +
          Q3C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q3<br />';
      }
      if (Q4R + Q4C < 13 && curQ == 4) {
        Violation = true;
        Status +=
          '<p>' +
          iTrans('Needed 13 rest periods for Q4 had') +
          ':' +
          Q4R +
          '. ' +
          Q4C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q4</p>';
        Vstatus +=
          iTrans('Needed 13 rest periods for Q4 had') +
          ':' +
          Q4R +
          '. ' +
          Q4C +
          ' ' +
          iTrans('Days Remain in') +
          ' Q4<br />';
      }

      var DayRest = dutytime_Calc(rec.End, rec.Start2);
      var DT1 = dutytime_Calc(rec.Start, rec.End);
      var DT2 = dutytime_Calc(rec.Start2, rec.End2);
      if (Overnight) {
        var overnightDT = duty_Calc(data[1], Overnight);
        DT1 += overnightDT;
        DT = DT1;
      }
      if (
        (rec.Split == '1' || rec.Split == '3') &&
        !Overnight &&
        rec.Start > 0 &&
        rec.End != 0 &&
        rec.Start2 > 0 &&
        rec.end2 != 2359 &&
        DayRest >= 8
      ) {
        if (DT1 > 14) {
          Violation = true;
          Status += '<p>' + iTrans('Over 14 hour duty day') + ': ' + DT1 + 'hrs</p>';
          Vstatus += iTrans('Over 14 hour duty day') + ': ' + DT1 + 'hrs<br />';
        }
        if (DT2 > 14) {
          Violation = true;
          Status += '<p>' + iTrans('Over 14 hour duty day') + ': ' + DT2 + 'hrs</p>';
          Vstatus += iTrans('Over 14 hour duty day') + ': ' + DT2 + 'hrs<br />';
        }
        maxDuty = 14;
      } else {
        if (DT1 > 14) {
          Violation = true;
          Status += '<p>' + iTrans('Over 14 hour duty day') + ': ' + DT1 + 'hrs</p>';
          Vstatus += iTrans('Over 14 hour duty day') + ': ' + DT1 + 'hrs<br />';
        }
        if (DT2 > 14) {
          Violation = true;
          Status += '<p>' + iTrans('Over 14 hour duty day') + ': ' + DT2 + 'hrs</p>';
          Vstatus += iTrans('Over 14 hour duty day') + ': ' + DT2 + 'hrs<br />';
        }
        maxDuty = 14;
      }
      if (Q1 > 450 && Q1 <= 500 && curQ == 1) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 1st quarter 500hrs flew') + ':' + Q1 + 'hrs</p>';
        Wstatus += iTrans('Approaching 1st quarter 500hrs flew') + ':' + Q1 + 'hrs<br />';
      }
      if (Q2 > 450 && Q2 <= 500 && curQ == 2) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 2nd quarter 500hrs flew') + ':' + Q2 + 'hrs</p>';
        Wstatus += iTrans('Approaching 2nd quarter 500hrs flew') + ':' + Q2 + 'hrs<br />';
      }
      if (Q3 > 450 && Q3 <= 500 && curQ == 3) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 3rd quarter 500hrs flew') + ':' + Q3 + 'hrs</p>';
        Wstatus += iTrans('Approaching 3rd quarter 500hrs flew') + ':' + Q3 + 'hrs<br />';
      }
      if (Q4 > 450 && Q4 <= 500 && curQ == 4) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching 4th quarter 500hrs flew') + ':' + Q4 + 'hrs</p>';
        Wstatus += iTrans('Approaching 4th quarter 500hrs flew') + ':' + Q4 + 'hrs<br />';
      }

      if (Q1 > 450) {
        Violation = true;
        Status += '<p>' + iTrans('Over 1st quarter 500hrs flew') + ':' + Q1 + 'hrs</p>';
        Vstatus += iTrans('Over 1st quarter 500hrs flew') + ':' + Q1 + 'hrs<br />';
      }
      if (Q2 > 500) {
        Violation = true;
        Status += '<p>' + iTrans('Over 2nd quarter 500hrs flew') + ':' + Q2 + 'hrs</p>';
        Vstatus += iTrans('Over 2nd quarter 500hrs flew') + ':' + Q2 + 'hrs<br />';
      }
      if (Q3 > 500) {
        Violation = true;
        Status += '<p>' + iTrans('Over 3rd quarter 500hrs flew') + ':' + Q3 + 'hrs</p>';
        Vstatus += iTrans('Over 3rd quarter 500hrs flew') + ':' + Q3 + 'hrs<br />';
      }
      if (Q4 > 500) {
        Violation = true;
        Status += '<p>' + iTrans('Over 4th quarter 500hrs flew') + ':' + Q4 + 'hrs</p>';
        Vstatus += iTrans('Over 4th quarter 500hrs flew') + ':' + Q4 + 'hrs<br />';
      }
      if (Q1 + Q2 >= 750 && Q1 + Q2 <= 800 && curQ == 2) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching quarter 1+2 800hrs flew') + ':' + (Q1 + Q2) + 'hrs</p>';
        Wstatus += iTrans('Approaching quarter 1+2 800hrs flew') + ':' + (Q1 + Q2) + 'hrs<br />';
      }
      if (Q2 + Q3 > 750 && Q2 + Q3 <= 800 && curQ == 3) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching quarter 2+3 800hrs flew') + ':' + (Q2 + Q3) + 'hrs</p>';
        Wstatus += iTrans('Approaching quarter 2+3 800hrs flew') + ':' + (Q2 + Q3) + 'hrs<br />';
      }
      if (Q3 + Q4 > 750 && Q3 + Q4 <= 800 && curQ == 4) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching quarter 3+4 800hrs flew') + ':' + (Q3 + Q4) + 'hrs</p>';
        Wstatus += iTrans('Approaching quarter 3+4 800hrs flew') + ':' + (Q3 + Q4) + 'hrs<br />';
      }
      if (Q1 + Q4 > 750 && Q4LastYear && Q1 + Q4 <= 800 && curQ == 1) {
        Warning = true;
        Status += '<p>' + iTrans('Approaching quarter 1+4 800hrs flew') + ':' + (Q1 + Q4) + 'hrs</p>';
        Wstatus += iTrans('Approaching quarter 1+4 800hrs flew') + ':' + (Q1 + Q4) + 'hrs<br />';
      }
      if (Q1 + Q2 >= 800) {
        Violation = true;
        Status += '<p>' + iTrans('Over quarter 1+2 800hrs flew') + ':' + (Q1 + Q2) + 'hrs</p>';
        Vstatus += iTrans('Over quarter 1+2 800hrs flew') + ':' + (Q1 + Q2) + 'hrs<br />';
      }
      if (Q2 + Q3 >= 800) {
        Violation = true;
        Status += '<p>' + iTrans('Over quarter 2+3 800hrs flew') + ':' + (Q2 + Q3) + 'hrs</p>';
        Vstatus += iTrans('Over quarter 2+3 800hrs flew') + ':' + (Q2 + Q3) + 'hrs<br />';
      }
      if (Q3 + Q4 >= 800) {
        Violation = true;
        Status += '<p>' + iTrans('Over quarter 3+4 800hrs flew') + ':' + (Q3 + Q4) + 'hrs</p>';
        Vstatus += iTrans('Over quarter 3+4 800hrs flew') + ':' + (Q3 + Q4) + 'hrs<br />';
      }
      if (Q1 + Q4 >= 800 && Q4LastYear) {
        Violation = true;
        Status += '<p>' + iTrans('Over quarter 1+4 800hrs flew') + ':' + (Q1 + Q4) + 'hrs</p>';
        Vstatus += iTrans('Over quarter 1+4 800hrs flew') + ':' + (Q1 + Q4) + 'hrs<br />';
      }
    }
    ExtendAvail = false;
    Extendable = false;

    var current = checkCurrency(
      ifr_AI_L180,
      ifr_GA_L180,
      ifr_IA_L180,
      dTO_L90,
      dLD_L90,
      nTO_L90,
      nLD_L90,
      dTO_L180,
      dLD_L180,
      nTO_L180,
      nLD_L180,
      fTO_L180,
      fLD_L180,
      CurrentAsOf,
      Rule
    );
    if (current.result) {
      Currency += current.text;
    }
    DayCurrent = current.DayCurrent;
    NightCurrent = current.NightCurrent;
    IFRCurrent = current.IFRCurrent;
    CurrencySummary = current;
  } else if (Rule == 1) {
    //AUSTRALIA NON-AGRICULTURAL FDT
    var minRest = 9;
    //FLIGHT TIME RESTRICTIONS
    if (rec.Split == 0 || rec.Split == 2) {
      if (dayFT > 9 || nightFT > 9) {
        Status +=
          '<p>' + iTrans('Over 9 hours flight time, 24 hours of rest is now required before next flight') + '</p>';
        Wstatus += iTrans('Over 9 hours flight time, 24 hours of rest is now required before next flight');
        //RTadd = RTadd + 24;
        minRest = 24;
      } else if (dayFT > 8 || nightFT > 8) {
        //Violation = true;
        var tFT = dayFT;
        if (nightFT > 8) {
          tFT = nightFT;
        }
        if (tFT < 8.3) {
          RTadd = 1;
        }
        if (tFT >= 8.3 && tFT <= 8.4) {
          RTadd = 2;
        }
        if (tFT >= 8.5 && tFT <= 8.7) {
          RTadd = 3;
        }
        if (tFT > 8.8) {
          RTadd = 4;
        }
        Status +=
          '<p>' +
          iTrans('WARNING: Over 8 hours flight time per tour of duty. Extend Rest by') +
          ': ' +
          RTadd +
          ' ' +
          iTrans('Hours') +
          '</p>';
        Wstatus +=
          iTrans('WARNING: Over 8 hours flight time per tour of duty. Extend Rest by') +
          ': ' +
          RTadd +
          ' ' +
          iTrans('Hours') +
          '<br />';
        minRest += RTadd;
      }
    }
    if (L7 > 30) {
      Violation = true;
      Status += '<p>' + iTrans('Over 30 hours flight time last 7 days') + '</p>';
      Vstatus += iTrans('Over 30 hours flight time last 7 days') + '<br />';
    }
    if (L30 > 100) {
      Violation = true;
      Status += '<p>' + iTrans('Over 100 hours flight time last 30 days') + '</p>';
      Vstatus += iTrans('Over 100 hours flight time last 30 days') + '<br />';
    }
    if (L90 > 300) {
      Violation = true;
      Status += '<p>' + iTrans('Over 300 hours flight time last 90 days') + '</p>';
      Vstatus += iTrans('Over 300 hours flight time last 90 days') + '<br />';
    }
    if (L365 > 900) {
      Violation = true;
      Status += '<p>' + iTrans('Over 900 hours flight time last 365 days') + '</p>';
      Vstatus += iTrans('Over 900 hours flight time last 365 days') + '<br />';
    }
    //DUTY TIME RESTRICTIONS
    if (rec.Split == 0 || rec.Split == 2) {
      if (DT > 12) {
        Status +=
          '<p>' + iTrans('Over 12 hours duty time, 24 hours of rest is now required before next flight') + '</p>';
        Wstatus += iTrans('Over 12 hours duty time, 24 hours of rest is now required before next flight');
        //RTadd = RTadd + 24;
        minRest = 24;
      } else if (DT > 11) {
        if (DT < 11.3) {
          RTadd = 1;
        }
        if (DT >= 11.3 && DT <= 11.4) {
          RTadd = 2;
        }
        if (DT >= 11.5 && DT <= 11.7) {
          RTadd = 3;
        }
        if (DT > 11.8) {
          RTadd = 4;
        }
        Status +=
          '<p>' +
          iTrans('WARNING: Over 11 hours duty time. Extend Rest by') +
          ': ' +
          RTadd +
          ' ' +
          iTrans('Hours') +
          '</p>';
        Wstatus +=
          iTrans('WARNING: Over 11 hours duty time. Extend Rest by') + ': ' + RTadd + ' ' + iTrans('Hours') + '<br />';
        minRest += RTadd;
      }
    }
    if (DT14 > 80 && DT14 < 90) {
      Warning = true;
      Status += '<p>' + iTrans('Approaching 90 hour duty time within 14 days') + '</p>';
      Wstatus += iTrans('Approaching 90 hour duty time within 14 days') + '<br />';
    }
    if (DT14 > 90) {
      Violation = true;
      Status += '<p>' + iTrans('Over 90 hours duty time last 14 days') + '</p>';
      Vstatus += iTrans('Over 90 hours duty time last 14 days') + '<br />';
    }
    //REST TIME REQUIREMENTS
    if (minRest != 24) {
      if (data.length > 1) {
        var pEnd = parseInt(data[1].End);
        var pEnd2 = parseInt(data[1].End2);
        var tStart = parseInt(rec.Start);
        if (pEnd2 != 0 && pEnd2 <= 1100 && tStart != 0 && tStart >= 600) {
          minRest = 9;
        } else if (pEnd != 0 && pEnd <= 1100 && tStart != 0 && tStart >= 600) {
          minRest = 9;
        } else {
          minRest = 10;
        }
      } else {
        if (parseInt(rec.Start) >= 600) {
          minRest = 9;
        } else {
          minRest = 10;
        }
      }
    }

    //Look at last 2 conescutive duty periods
    if (rec.split == 0 || rec.Split == 2) {
      if (pDT + DT > 11 || pFT + FT > 8) {
        if (data.length > 1) {
          var pEnd = parseInt(data[1].End);
          var pEnd2 = parseInt(data[1].End2);
          var tStart = parseInt(rec.Start);
          if (pEnd2 != 0 && pEnd2 <= 1100 && tStart != 0 && tStart >= 600) {
            minRest = 12;
          } else if (pEnd != 0 && pEnd <= 1100 && tStart != 0 && tStart >= 600) {
            minRest = 12;
          } else {
            minRest = 24;
          }
        } else {
          if (parseInt(rec.Start) >= 600) {
            minRest = 12;
          } else {
            minRest = 24;
          }
        }
      }
    }

    if (aRT7 < 2) {
      //2 consecutive nights off in 7 days from 10pm to 6 am
      Violation = true;
      Status += '<p>' + iTrans('Did no receive 2 consecutive nights off between 10pm and 6am past 7 days') + '</p>';
      Vstatus += iTrans('Did no receive 2 consecutive nights off between 10pm and 6am past 7 days') + '<br />';
    }
    //END NON AG
  } else if (Rule == 2) {
    //AUSTRALIAN AGRICULTURAL FDT FULES

    //Flight Time restrictions
    if (L28 > 170) {
      Violation = true;
      Status += '<p>' + iTrans('Over 170 hours flight time in last 28 days') + '</p>';
      Vstatus += iTrans('Over 170 hours flight time in last 28 days') + '<br />';
    }

    if (L365 > 1200) {
      Violation = true;
      Status += '<p>' + iTrans('Over 1200 hours flight time in last 365 days') + '</p>';
      Vstatus += iTrans('Over 1200 hours flight time in last 356 days') + '<br />';
    }

    if (DT - DTminus > 15) {
      //duty time minus reclaimed duty from split duty if it happened
      Violation = true;
      Status += '<p>' + iTrans('Over 15 hours duty time') + '</p>';
      Vstatus += iTrans('Over 15 hours duty time') + '<br />';
    }

    if (DT3 > 44) {
      Violation = true;
      Status += '<p>' + iTrans('Over 44 hours duty time in last 3 days') + '</p>';
      Vstatus += iTrans('Over 44 hours duty time in last 3 days') + '<br />';
    }
    if (DT7 > 98) {
      Violation = true;
      Status += '<p>' + iTrans('Over 98 hours duty time in last 7 days') + '</p>';
      Vstatus += iTrans('Over 98 hours duty time in last 7 days') + '<br />';
    }
    if (RT14 < 36) {
      Violation = true;
      Status += '<p>' + iTrans('Need 36 hours consecutive rest in last 14 days') + '</p>';
      Vstatus += iTrans('Need 36 hours consecutive rest in last 14 days') + '<br />';
    }

    //END AG
  } else if (Rule == 3) {
    //CASR 135 Indonesia
    if (DT > 12) {
      Violation = true;
      Status += '<p>' + iTrans('Over 12 hours duty time') + '</p>';
      Vstatus += iTrans('Over 12 hours duty time') + '<br />';
    }
    if (dayFT > 6 && rec.Dual == 0) {
      Violation = true;
      Status += '<p>' + iTrans('Over 6 hours flight time') + '</p>';
      Vstatus += iTrans('Over 6 hours flight time') + '<br />';
    }
    if (dayFT > 7 && rec.Dual == 1 && rec.Heli == 1) {
      Violation = true;
      Status += '<p>' + iTrans('Over 7 hours flight time') + '</p>';
      Vstatus += iTrans('Over 7 hours flight time') + '<br />';
    }
    if (dayFT > 9 && rec.Dual == 1 && rec.Heli == 0) {
      Violation = true;
      Status += '<p>' + iTrans('Over 9 hours flight time') + '</p>';
      Vstatus += iTrans('Over 9 hours flight time') + '<br />';
    }
    if (dayFT > 5 && rec.EL == 1 && Heli == 1) {
      Violation = true;
      Status += '<p>' + iTrans('Over 5 hours flight time with external loads') + '</p>';
      Vstatus += iTrans('Over 5 hours flight time with external loads') + '<br />';
    }
    if ((L7 > 40 && rec.Heli == 1) || (L7 > 40 && rec.Heli == 0 && rec.EL == 0)) {
      Violation = true;
      Status += '<p>' + iTrans('Over 40 hours flight time in 7 days') + '</p>';
      Vstatus += iTrans('Over 40 hours flight time in 7 days') + '<br />';
    }
    if (L7 > 35 && rec.EL == 1 && rec.Heli == 0) {
      //aeroplane rec.EL == 1 means MCTOW > 12500lbs
      Violation = true;
      Status += '<p>' + iTrans('Over 35 hours flight time in 7 days') + '</p>';
      Vstatus += iTrans('Over 35 hours flight time in 7 days') + '<br />';
    }
    if (L30 > 100) {
      Violation = true;
      Status += '<p>' + iTrans('Over 100 hours flight time last 30 days') + '</p>';
      Vstatus += iTrans('Over 100 hours flight time last 30 days') + '<br />';
    }
    if (L90 > 300) {
      Violation = true;
      Status += '<p>' + iTrans('Over 300 hours flight time last 90 days') + '</p>';
      Vstatus += iTrans('Over 300 hours flight time last 90 days') + '<br />';
    }
    if (L365 > 1050) {
      Violation = true;
      Status += '<p>' + iTrans('Over 1050 hours flight time last 365 days') + '</p>';
      Vstatus += iTrans('Over 1050 hours flight time last 365 days') + '<br />';
    }
    if (RT < 8) {
      Violation = true;
      Status += '<p>' + iTrans('Needed 8 hours rest time') + '</p>';
      Vstatus += iTrans('Needed 8 hours rest time') + '<br />';
    }
    if (!DOin7) {
      Violation = true;
      Status += '<p>' + iTrans('Needed 1 day off in 7 days') + '</p>';
      Vstatus += iTrans('Needed 1 day off in 7 days') + '<br />';
    }
    Noff = CASR135_Noff;
  } else if (Rule == 4) {
    //Kenya EAAC

    //Flight Time Restrictions
    if (dayFT > 11) {
      Violation = true;
      Status += '<p>' + iTrans('Over 11 hours flight time') + '</p>';
      Vstatus += iTrans('Over 11 hours flight time') + '<br />';
    }
    if (L3 > 18) {
      Violation = true;
      Status += '<p>' + iTrans('Over 18 hours flight time in 72hrs') + '</p>';
      Vstatus += iTrans('Over 18 hours flight time in 72hrs') + '<br />';
    }

    if (L28 >= 90 && L28 <= 105) {
      Warning = true;
      Status += '<p>' + iTrans('Approaching 105 hours flight time in 28 days') + '</p>';
      Wstatus += iTrans('Approaching 105 hours flight time in 28 days') + '<br />';
    }
    if (L28 > 105) {
      Violation = true;
      Status += '<p>' + iTrans('Over 105 hours flight time in 28 days') + '</p>';
      Vstatus += iTrans('Over 105 hours flight time in 28 days') + '<br />';
    }

    if (L365 >= 1055 && L365 <= 1155) {
      Warning = true;
      Status += '<p>' + iTrans('Approaching 105 hours flight time in 28 days') + '</p>';
      Wstatus += iTrans('Approaching 105 hours flight time in 28 days') + '<br />';
    }
    if (L365 > 1155) {
      Violation = true;
      Status += '<p>' + iTrans('Over 1155 hours flight time in 365 days') + '</p>';
      Vstatus += iTrans('Over 1155 hours flight time in 365 days') + '<br />';
    }

    //Duty Time Restrictions

    if (DT28 >= 140 && DT28 <= 160) {
      Warning = true;
      Status += '<p>' + iTrans('Approaching 160 hours duty time in 28 days') + '</p>';
      Wstatus += iTrans('Approaching 160 hours duty time in 28 days') + '<br />';
    }
    if (DT28 > 160) {
      Violation = true;
      Status += '<p>' + iTrans('Over 160 hours duty time in 28 days') + '</p>';
      Vstatus += iTrans('Over 160 hours duty time in 28 days') + '<br />';
    }

    if (DT365 >= 2050 && DT365 <= 2200) {
      Warning = true;
      Status += '<p>' + iTrans('Approaching 2200 hours duty time in 365 days') + '</p>';
      Wstatus += iTrans('Approaching 2200 hours duty time in 365 days') + '<br />';
    }
    if (DT365 > 2200) {
      Violation = true;
      Status += '<p>' + iTrans('Over 2200 hours duty time in 365 days') + '</p>';
      Vstatus += iTrans('Over 2200 hours duty time in 365 days') + '<br />';
    }
    if (data[1] != undefined && data[2] != undefined) {
      var PrevDuty = duty_Calc(data[1], data[1].Overnight, data[2]);
      var RestReq = checkKenyaRT(PrevDuty);
      if (RT < RestReq) {
        Violation = true;
        Status +=
          '<p>' +
          iTrans('Needed') +
          ' ' +
          RestReq +
          ' ' +
          iTrans('hours rest') +
          ' ' +
          iTrans('had') +
          ' ' +
          RT +
          ' ' +
          iTrans('hours rest') +
          '</p>';
        Vstatus +=
          iTrans('Needed') +
          ' ' +
          RestReq +
          ' ' +
          iTrans('hours rest') +
          ' ' +
          iTrans('had') +
          ' ' +
          RT +
          ' ' +
          iTrans('hours rest') +
          '<br />';
      }
    }
  } else {
    //Is a private pilot disable warnings and violations;
    ExtendNum = 0;
    ExtendReason = iTrans('No Applicable');
    Noff = new Date();
    Noff.setDate(Noff.getDate() + 27);
    Noff_Limit = '3do in 30d';
    ExtendAvail = false;
    Extendable = false;
  }
  var NowDate = formatDate(new Date());
  NowDate = new Date(NowDate + 'T00:00:00Z');
  var RecDate = new Date(rec.Date + 'T00:00:00Z');

  if (RecDate.getTime() == NowDate.getTime()) {
    for (var C in AC_CURRENCY) {
      if (AC_CURRENCY[C].ValidToState == 'Warning') {
        Warning = true;
        Status +=
          "<p class='yellowbg'>" +
          iTrans('Warning') +
          ': ' +
          C +
          ' ' +
          iTrans('Currency will expire on') +
          ' ' +
          AC_CURRENCY[C].ValidTo +
          '</p>';
        Wstatus += C + ' ' + iTrans('Currency will expire soon') + ' ' + AC_CURRENCY[C].ValidTo + '<br />';
      }

      if (AC_CURRENCY[C].Float_ValidToState == 'Warning' && AC_CURRENCY[C].Float) {
        Warning = true;
        Status +=
          "<p class='yellowbg'>" +
          iTrans('Warning') +
          ': ' +
          C +
          ' ' +
          iTrans('Float Currency will expire on') +
          ' ' +
          AC_CURRENCY[C].Float_ValidTo +
          '</p>';
        Wstatus += C + ' ' + iTrans('Currency will expire soon') + ' ' + AC_CURRENCY[C].Float_ValidTo + '<br />';
      }
    }
    if (
      (USER_RIGHTS.FDT_NvgCur == 1 && !FDT_ADMIN_UPDATE) ||
      (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)
    ) {
      if (NVG_Report.Current == false) {
        Warning = true;
        Status += "<p class='yellowbg'>WARNING: " + NVG_Report.ReasonTXT + '</p>';
        Wstatus += NVG_Report.ReasonTXT + '<br />';
      }
    }
    if (
      (USER_RIGHTS.FDT_IfrCur == 1 && !FDT_ADMIN_UPDATE) ||
      (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1)
    ) {
      if (IFR_Report.Current == false) {
        Warning = true;
        Status += "<p class='yellowbg'>WARNING: " + IFR_Report.ReasonTXT + '</p>';
        Wstatus += IFR_Report.ReasonTXT + '<br />';
      }
    }
  }
  if (UOC.L1 == true) {
    Warning = true;
    Status += '<p class="yellowbg"><h3 class="yellowbg">' + iTrans('Unforeseen  Operation Circumstance') + '</h3></p>'; //text could contain warning
    Wstatus += iTrans('Unforeseen  Operation Circumstance') + '<br />';
  }
  if (rec.EC5 > 45) {
    Violation = true;
    Status += '<p class="yellowbg">' + iTrans('More than 45 minutes of controlled rest on flight deck') + '</p>'; //text could contain warning
    Vstatus += iTrans('More than 45 minutes of controlled rest on flight deck') + '<br />';
  }
  if (rec.EC4 == 1) {
    if (rec.Sectors > 3) {
      Violation = true;
      Status += '<p class="yellowbg">' + iTrans('Flew more 3 sectors during Long Range Flight') + '</p>'; //text could contain warning
      Vstatus += iTrans('Flew more 3 sectors during Long Range Flight') + '<br />';
    }
  }
  if (rec.EC4 == 2) {
    if (rec.Sectors > 2 && UOC.L1 == 0) {
      Violation = true;
      Status += '<p class="yellowbg">' + iTrans('Flew more 1 sectors after Transoceanic Long Range Flight') + '</p>'; //text could contain warning
      Vstatus += iTrans('Flew more 1 sectors after Transoceanic Long Range Flight') + '<br />';
    }
    if (rec.Sectors > 3 && UOC.L1 == 1) {
      Violation = true;
      Status +=
        '<p class="yellowbg">' +
        iTrans('Flew more 2 sectors after Transoceanic Long Range Flight including unscheduled technical stop') +
        '</p>'; //text could contain warning
      Vstatus +=
        iTrans('Flew more 2 sectors after Transoceanic Long Range Flight including unscheduled technical stop') +
        '<br />';
    }
  }
  if (Warning) {
    AdminWarning = {
      Status: Wstatus,
      date: rec.Date,
    };
  }
  if (Violation) {
    AdminViolation = {
      Status: Vstatus,
      date: rec.Date,
    };
  }
  RT = Math.round(RT * 10) / 10;
  data.reverse(); //return records to origin order
  DT = Math.round(DT * 10) / 10;

  if (DUTYTIME == 'Calendar') {
    if (
      (Warning && rec.Warning != 1) ||
      (Violation && rec.Violation != 1) ||
      (!Warning && rec.Warning == 1 && rec.Warning_Ack == 0) ||
      (!Violation && rec.Violation == 1 && rec.Violation_Ack == 0)
    ) {
      markRec(rec.Date, Status, Warning, Violation);
    }
  }
  Noff.setMinutes(Noff.getMinutes() + Noff.getTimezoneOffset());
  var returnData = {
    RT: RT,
    Recs: rec.Recs,
    prevSplit: prevSplit,
    DT: DT,
    DT7: round10(DT7),
    DT28: round10(DT28),
    DT365: round10(DT365),
    ODT: ODT,
    L3: round10(L3),
    L7: round10(L7),
    L28: round10(L28),
    L30: round10(L30),
    L42: round10(L42),
    L90: round10(L90),
    L180: round10(L180),
    L365: round10(L365),
    Off30: Off30,
    Off42: Off42,
    Off90: Off90,
    Noff: Noff,
    Noff_Limit: Noff_Limit,
    lastQ: round10(lastQ),
    curQR: curQR,
    curQ: curQ,
    last2Q: round10(last2Q),
    CalYear: round10(CalYear),
    Status: Status,
    Warning: Warning,
    Violation: Violation,
    ExtendAvail: ExtendAvail,
    Extendable: Extendable,
    ExtendNum: ExtendNum,
    ExtendReason: ExtendReason,
    ExtendON: ExtendON,
    FltDays: FltDays,
    DtyDays: DtyDays,
    TotalDays: TotalDays,
    AdminWarning: AdminWarning,
    AdminViolation: AdminViolation,
    ResetFT: ResetFT,
    TotalLast24: TotalLast24,
    dayFT: dayFT,
    MaxNextFltTimes: MaxNextFltTimes,
    Currency: Currency,
    Split: rec.Split == 1 || rec.Split == 3 ? 1 : 0,
    Split2: rec.Split == 2 || rec.Split == 3 ? 1 : 0,
    NightCurrent: NightCurrent,
    DayCurrent: DayCurrent,
    IFRCurrent: IFRCurrent,
    CurrencySummary: CurrencySummary,
    maxFT: maxFT,
    maxDuty: maxDuty,
    AccTZ: AcclimatizationData.Timezone,
    AccHours: AcclimatizationData.Hours,
    AccDSTcorrected: AcclimatizationData.DSTcorrected,
    UOC: rec.EC1,
    FCP: rec.ET1,
    RES_Notice: rec.EC2,
    RES_Notice2: rec.EC3,
    LRF: rec.EC4,
    CROFD: rec.EC5,
    ET2: rec.ET2,
    inputErrors: inputErrors,
    inputMessages: inputMessages,
  };
  return returnData;
}

function checkKenyaRT(prevDuty) {
  if (prevDuty == 0) {
    return 0;
  }
  if (prevDuty <= 10) {
    return 11;
  }
  if (prevDuty > 10 && prevDuty <= 11) {
    return 12;
  }
  if (prevDuty > 11 && prevDuty <= 12) {
    return 13;
  }
  if (prevDuty > 12 && prevDuty <= 13) {
    return 14;
  }
  if (prevDuty > 13 && prevDuty <= 14) {
    return 15;
  }
  if (prevDuty > 14 && prevDuty <= 15) {
    return 16;
  }
  if (prevDuty > 15 && prevDuty <= 16) {
    return 17;
  }
  return 17;
}

var REPORT_DayCur = 0;
var REPORT_NightCur = 0;
var REPORT_FloatCur = 0;
var REPORT_IFRCur = 0;

function checkCurrency(
  ifr_AI_L180,
  ifr_GA_L180,
  ifr_IA_L180,
  dTO_L90,
  dLD_L90,
  nTO_L90,
  nLD_L90,
  dTO_L180,
  dLD_L180,
  nTO_L180,
  nLD_L180,
  fTO_L180,
  fLD_L180,
  CurrentAsOf,
  Rule
) {
  var result = {
    result: false,
    text: '',
  };
  var DayCur = 0;
  var NightCur = 0;
  var DayCurrent = true;
  var Day_Status = 'Good';
  var NightCurrent = true;
  var Night_Status = 'Good';
  var DayCurrentTo = null;
  var NightCurrentTo = null;
  var IFRCur = 0;
  var IFRCurrent = true;
  var IFR_Status = 'Good';
  var IFRCurrentTo = null;
  var summary = '';

  if (DUTYTIME == 'REPORT') {
    DayCur = REPORT_DayCur;
    NightCur = REPORT_NightCur;
    IFRCur = REPORT_IFRCur;
  } else {
    if (FDT_ADMIN_UPDATE) {
      DayCur = ADMIN_FDT_DATA.CurUser.FDT_DayCur;
      NightCur = ADMIN_FDT_DATA.CurUser.FDT_NightCur;
      IFRCur = ADMIN_FDT_DATA.CurUser.FDT_IfrCur;
    } else {
      DayCur = USER_RIGHTS.FDT_DayCur;
      NightCur = USER_RIGHTS.FDT_NightCur;
      IFRCur = USER_RIGHTS.FDT_IfrCur;
    }
  }
  if (DayCur == undefined) DayCur = 1;
  if (NightCur == undefined) NightCur = 1;
  if (IFRCur == undefined) IFRCur = 1;

  if (IFRCur == 1) {
    if (ifr_GA_L180 + ifr_IA_L180 < 6) {
      result.result = true;
      result.text += iTrans('Less than 6 IFR approaches in last 180 days') + '<br />';
      IFRCurrent = false;
    }
    if (ifr_AI_L180 < 6) {
      result.result = true;
      result.text += iTrans('Less than 6 hours of IFR in last 180 days') + '<br />';
      IFRCurrent = false;
    }
    if (CurrentAsOf.IFR != null) {
      var IFR_ValidTo = Get_Date_ValidTo(CurrentAsOf.IFR, 180);
      var IFR_Status = Check_Date_ValidTo(IFR_ValidTo, 14);
      IFRCurrentTo = IFR_ValidTo;
      if (IFR_Status == 'Warning') {
        result.text += iTrans('Warning: Day currency elapses on ') + IFR_ValidTo;
      }
    }
  }

  if (DayCur == 1) {
    if (Rule != 702 && Rule != 604) {
      if (dTO_L90 + nTO_L90 < 3) {
        //since day or night count
        result.result = true;
        result.text += iTrans('Less than 3 day takeoffs in last 90 days') + '<br />';
        DayCurrent = false;
      }
      if (dLD_L90 + nLD_L90 < 3) {
        //since day or night count
        result.result = true;
        result.text += iTrans('Less than 3 day landings in last 90 days') + '<br />';
        DayCurrent = false;
      }
      if (CurrentAsOf.Day90 != null) {
        var Day_ValidTo = Get_Date_ValidTo(CurrentAsOf.Day90, 90);
        var Day_Status = Check_Date_ValidTo(Day_ValidTo, 14);
        DayCurrentTo = Day_ValidTo;
        if (Day_Status == 'Warning') {
          result.text += iTrans('Warning: Day currency elapses on ') + Day_ValidTo;
        }
      }
    }

    if (Rule == '703' || Rule == '704' || Rule == '705') {
      if (dTO_L180 + nTO_L180 < 5) {
        //since day or night count
        result.result = true;
        result.text += iTrans('Less than 5 day takeoffs in last 6 months') + '<br />';
        DayCurrent = false;
      }
      if (dLD_L180 + nLD_L180 < 5) {
        //since day or night count
        result.result = true;
        result.text += iTrans('Less than 5 day landings in last 6 months') + '<br />';
        DayCurrent = false;
      }
      if (CurrentAsOf.Day180 != null) {
        var Day_ValidTo = Get_Date_ValidTo(CurrentAsOf.Day180, 180);
        var Day_Status = Check_Date_ValidTo(Day_ValidTo, 30);
        DayCurrentTo = Day_ValidTo;
        if (Day_Status == 'Warning') {
          result.text += iTrans('Warning: Day currency elapses on ') + Day_ValidTo;
        }
      }
    }
  }
  if (NightCur == 1) {
    if (Rule != 702 && Rule != 604) {
      if (nTO_L90 < 3) {
        result.result = true;
        result.text += iTrans('Less than 3 night takeoffs in last 90 days') + '<br />';
        NightCurrent = false;
      }
      if (nLD_L90 < 3) {
        result.result = true;
        result.text += iTrans('Less than 3 night landings in last 90 days') + '<br />';
        NightCurrent = false;
      }
      if (CurrentAsOf.Night90 != null) {
        var Night_ValidTo = Get_Date_ValidTo(CurrentAsOf.Night90, 90);
        var Night_Status = Check_Date_ValidTo(Night_ValidTo, 14);
        if (Night_Status == 'Warning') {
          result.text += iTrans('Warning: Night currency elapses on ') + Night_ValidTo;
        }
        NightCurrentTo = Night_ValidTo;
      }
    }
    if (Rule == '703' || Rule == '704' || Rule == '705') {
      if (nTO_L180 < 5) {
        result.result = true;
        result.text += iTrans('Less than 5 night takeoffs in last 6 months') + '<br />';
        NightCurrent = false;
      }
      if (nLD_L180 < 5) {
        result.result = true;
        result.text += iTrans('Less than 5 night landings in last 6 months') + '<br />';
        NightCurrent = false;
      }
      if (CurrentAsOf.Night180 != null) {
        var Night_ValidTo = Get_Date_ValidTo(CurrentAsOf.Night180, 180);
        var Night_Status = Check_Date_ValidTo(Night_ValidTo, 30);
        if (Night_Status == 'Warning') {
          result.text += iTrans('Warning: Night currency elapses on ') + Night_ValidTo;
        }
        NightCurrentTo = Night_ValidTo;
      }
    }
  }
  result.DayCurrent = DayCurrent;
  result.DayCurrentTo = DayCurrentTo;
  result.NightCurrent = NightCurrent;
  result.NightCurrentTo = NightCurrentTo;
  result.IFRCurrent = IFRCurrent;
  result.IFRCurrentTo = IFRCurrentTo;
  var html = '';
  /*
   if (DayCurrent && DayCur == 1 && Day_Status == 'Good')
   {
   html += '<p class="greenbg"><b>' + iTrans('Day Current Until') + ': ' + Day_ValidTo + '</b></p>';
   }
   if (DayCurrent && DayCur == 1 && Day_Status == 'Warning')
   {
   html += '<p class="yellowbg"><b>' + iTrans('Day Current Until') + ': ' + Day_ValidTo + '</b></p>';
   }
   if (!DayCurrent && DayCur == 1)
   {
   html += '<p class="redbg"><b>' + iTrans('Not Day Current') + '</b></p>';
   }

   if (NightCurrent && NightCur == 1 && Night_Status == 'Good')
   {
   html += '<p class="greenbg"><b>' + iTrans('Night Current Until') + ': ' + Night_ValidTo + '</b></p>';
   }
   if (NightCurrent && NightCur == 1 && Night_Status == 'Warning')
   {
   html += '<p class="greenbg"><b>' + iTrans('Night Current Until') + ': ' + Night_ValidTo + '</b></p>';
   }
   if (!NightCurrent && NightCur == 1)
   {
   html += '<p class="redbg"><b>' + iTrans('Not Night Current') + '</b></p>';
   }

   result.summary = html;
   */

  return result;
}

function checkRT(
  RT,
  FT,
  Extend,
  pDT,
  RTadd,
  pFT,
  AA,
  split,
  Rule,
  Overnight = false,
  UOC,
  pReserve,
  pReserve2,
  FCP,
  LRF,
  SA
) {
  var maxDuty = 14;

  if (LRF != 0) {
    if (RT < pDT && pDT > 8) {
      return {
        result: true,
        text: iTrans('Need') + ' ' + round10(pDT) + ' ' + iTrans('hours rest time after Long Range Flight'),
      };
    }
  }

  var checkFCP = maxDuty - pDT - FCP;

  var checkFCPtxt = '';
  if (checkFCP < 0 && FCP != 0) {
    checkFCP = round10((pDT + FCP - maxDuty) / 2);
    checkFCPtxt = ' (' + iTrans('Flight Crew Positioning') + ')';
  } else {
    checkFCP = 0;
  }
  if (AA == 1) {
    return {
      result: false,
      text: '',
    }; //aerial application rest time handled in different menthod
  } else {
    var diff;
    diff = pDT - 14;
    if (Rule == 604) {
      diff = pDT - 14;
      // if rule 0s 604 and day before last 30 under 70 hours and off 24 hours day before previus day then
      // diff = pDT - 15;
    }
    if (diff < RTadd) {
      RTadd = diff;
    } else {
      diff = RTadd;
    }
    if (diff < 0) {
      diff = 0;
    }
    //if (Overnight) alert(diff);
    if (pReserve || pReserve2) {
      //Check for 10 hours rest because day before was on reserve
      if (RT < 10) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + 10 + ' ' + iTrans('hours rest time (on reserve yesterday)'),
        };
      }
      //diff -= round10(pDT / 2);
    }

    if (Rule == 705) {
      //end Home Base
      if (RT < diff + 12 + checkFCP && SA == 0) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 12 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
      if (RT < diff + 10 + checkFCP && SA == 0) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 10 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
      //TODO rest period of 11 hours with travel time to and from accommodation

      return {
        result: false,
        text: '',
      };
    }

    if (Extend == 0) {
      if (RT < diff + 8 + checkFCP) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 8 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
    } else {
      if ((pDT > 14 && RT < round10(diff + 9 + checkFCP) && pFT > 8) || (pFT > 8 && RT < diff + 9 + checkFCP)) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 9 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
      if (pDT <= 14 && RT < round10(diff + 8 + checkFCP)) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 8 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
      if (pDT > 14 && RT < round10(diff + 9 + checkFCP)) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 9 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
      if (RT < diff + 8 + checkFCP) {
        return {
          result: true,
          text: iTrans('Need') + ' ' + round10(diff + 8 + checkFCP) + ' ' + iTrans('hours rest time') + checkFCPtxt,
        };
      }
    }
  }

  return {
    result: false,
    text: '',
  };
}

function checkFT24(EXTRA, rec, pEXTRA, prec, Rule) {
  //for US regs
  var FT = 0;
  var FT2 = 0;
  var Total = 0;
  var TodayLegStart = '0000';
  var TodayLegEnd = '0000';
  var TodayLegStartFT = 0;
  var CountTimes = false;
  var ExtraFailed = false;
  var pExtraFailed = false;
  if (EXTRA == '0' || EXTRA == '') ExtraFailed = true;
  if (pEXTRA == '0' || pEXTRA == '') pExtraFailed = true;
  if (EXTRA != undefined && EXTRA.trim() != '') {
    var Extra;
    try {
      var Extra = JSON.parse(EXTRA);
      var Eitems = Extra.FDT135;
      if (Eitems.length > 0) {
        for (var E in Eitems) {
          if (Eitems[E].Reg == 135) {
            CountTimes = true;
            break;
          }
        }
        FT = getTotalFT(EXTRA);
        //TodayLegStart = subDecToTime(Eitems[0].Start, -FT);
        TodayLegStart = Eitems[0].Start;

        TodayLegEnd = subDecToTime(Eitems[Eitems.length - 1].Start, -Eitems[Eitems.length - 1].FT);
      }
    } catch (e) {
      console.log('Error CheckFT24');
      ExtraFailed = true;
    }
  }
  if (pEXTRA != undefined && pEXTRA != '') {
    try {
      var Extra = JSON.parse(pEXTRA);
      var ExtraStart = 0;
      FT2 = getTotalFTfrom(pEXTRA, TodayLegEnd);

      if (FT2 == 0) {
        FT2 = getTotalFTfrom(pEXTRA, TodayLegEnd);
      }
    } catch (e) {
      console.log('Error p CheckFT24');
      pExtraFailed = true;
    }
  }
  Total = FT + FT2;
  Total = Math.round(Total * 10) / 10;
  if (Rule == 91 || Rule == 137) {
    CountTimes = false;
  } else if (pExtraFailed && ExtraFailed) {
    CountTimes = true;
  }

  if (CountTimes === false) {
    Total = 0;
  }
  return { Total: Total, CountTimes: CountTimes };
}

function getTotalFTbefore(EXTRA, start) {
  var FT = 0;
  try {
    var Extra = JSON.parse(EXTRA);
    var Eitems = Extra.FDT135;
    if (Eitems.length > 0) {
      for (var E in Eitems) {
        if (Eitems[E].FT == 0) {
          continue;
        }
        if (parseInt(Eitems[E].Start) <= parseInt(start)) {
          var AdjustTime = subDecToTime(Eitems[E].Start, -parseFloat(Eitems[E].FT));
          if (parseInt(AdjustTime) >= parseInt(start)) {
            var PT = parseFloat(Eitems[E].FT) - dutytime_Calc(start, AdjustTime);
            FT += PT;
          } else {
            FT += parseFloat(Eitems[E].FT);
          }
        }
      }
      return FT;
    }
  } catch (e) {
    console.log('Error getTotalFTbefore');
  }
  return FT;
}

function getTotalFTfrom(EXTRA, start) {
  var FT = 0;
  var tally = 0;
  var AdjustTime = '';
  try {
    var Extra = JSON.parse(EXTRA);
    var Eitems = Extra.FDT135;
    if (Eitems.length > 0) {
      var Adjusted = false;
      for (var E in Eitems) {
        if (Eitems[E].FT == 0) {
          continue;
        }
        if (parseInt(Eitems[E].Start) > parseInt(start)) {
          if (!Adjusted) {
            if (AdjustTime == '') {
              AdjustTime = dutytime_Calc(start, Eitems[E].Start);
            }
            if (parseFloat(Eitems[E].FT) <= AdjustTime) {
              FT += parseFloat(Eitems[E].FT);
              if (FT > AdjustTime) {
                return AdjustTime;
              }
            } else {
              FT += AdjustTime;
              if (FT > AdjustTime) {
                return AdjustTime;
              }
              Adjusted = true;
            }
          }
        }
      }
      return FT;
    }
  } catch (e) {
    console.log('Error getTotalFTfrom');
  }
  return FT;
}

function getTotalFT(EXTRA) {
  var start = '0000';
  var FT = 0;
  try {
    var Extra = JSON.parse(EXTRA);
    var Eitems = Extra.FDT135;

    if (Eitems.length > 0) {
      for (var E in Eitems) {
        if (Eitems[E].FT == 0) {
          continue;
        }
        if (parseInt(Eitems[E].Start) >= parseInt(start)) {
          FT += parseFloat(Eitems[E].FT);
        }
      }
      return FT;
    }
  } catch (e) {
    console.log('Error getTotalFT');
  }
  return FT;
}

function checkFT135EXTRA(EXTRA, rec, add) {
  if (rec.Start == undefined) {
    return {
      Start: '0000',
      First: '0000',
      Duration: 0,
    };
  }
  var Start = rec.Start.toString();
  var End = rec.End.toString();
  var First = Start;
  var FirstFT = Start;
  var FirstNeg = false;
  var StartNeg = false;
  var FlightNeg = false;
  var Flight = '0000';
  var FirstFT = 0;
  var FT = 0;
  var diff = 0;
  var maxDuration = 8;
  var Debug = '';
  var RestTime = add - 10;
  if (rec.Rule == 131) {
    RestTime = add - 8;
  }
  var FTfrom = 0;
  var empty = true;
  var noEarly = false;
  if (rec.Dual == 1 && Rule != 131) {
    maxDuration = 10;
  }
  var Duration = maxDuration;

  //Start = for maximum flight 8 or 10 hours straight
  //First = first possible start with amount of time possible

  if (EXTRA != undefined && EXTRA != '') {
    try {
      var Extra = JSON.parse(EXTRA);
      var Eitems = Extra.FDT135;
      if (Eitems.length > 0) {
        for (var E in Eitems) {
          if (Eitems[E].FT == '0.0' || parseInt(Eitems[E].FT) == 0) {
            continue;
          }
          FT = parseFloat(Eitems[E].FT);

          if (E == 0) {
            var EndFT = subDecToTime(Eitems[E].Start, -parseFloat(Eitems[E].FT));
            if (parseInt(EndFT) < parseInt(maxDuration + '00')) {
              //Can start same day
              Start = subDecToTime(EndFT, maxDuration - 24);
              StartNeg = true;
              FlightNeg = true;
            } else {
              Start = subDecToTime(EndFT, maxDuration);
              StartNeg = false;
              FlightNeg = false;
            }
            //Earliest Flight time is same as Start right now
            Flight = Start;

            //Calculate first possible duty start time
            First = subDecToTime(End, RestTime);
            if (parseInt(First) > 2359) {
              First = subDecToTime(First, 24);
              FirstNeg = false;
              //FTfrom for entire day total;
              FTfrom = getTotalFTbefore(EXTRA, '2400');
            } else {
              FirstNeg = true;
              //FTfrom First + 10 hours
              FTfrom = getTotalFTbefore(EXTRA, subDecToTime(First, maxDuration));
            }

            //Calculate first possible flight start time
            FirstFT = getTotalFTbefore(EXTRA, First);
            if (FirstFT < maxDuration) {
              Flight = First;
              FlightNeg = FirstNeg;
            }

            //if Start is less than first then extra rest was needed but we can definatly fly the max so just set start to first
            if (
              (parseInt(Start) < parseInt(First) && !FirstNeg) ||
              (!FirstNeg && StartNeg) ||
              (parseInt(Start) < parseInt(First) && FirstNeg && StartNeg)
            ) {
              Start = First;
              noEarly = true;
            } else {
              if (FT <= maxDuration) {
                if (
                  (parseInt(Flight) < parseInt(Start) && !StartNeg) ||
                  (!StartNeg && FlightNeg) ||
                  (parseInt(Flight) < parseInt(Start) && StartNeg && FlightNeg)
                ) {
                  FTfrom = dutytime_Calc(Flight, Start);
                }
              } else {
                noEarly = true;
              }
            }
            if (Start == Flight) {
              noEarly = true;
            }

            Duration = maxDuration - FTfrom;

            empty = false;
          }
          break;
        }
      } else {
        noEarly = true;
      }
    } catch (e) {
      console.log('Error checkFT135EXTRA');
      noEarly = true;
    }
  } else {
    noEarly = true;
  }
  var End = rec.End.toString();
  if (End == '0') {
    End = '0000';
  }
  Debug =
    'End: ' +
    End +
    ' diff:' +
    Rule +
    ' First:' +
    First +
    ' Start:' +
    Start +
    ' add:' +
    add +
    ' FirstFT:' +
    FirstFT +
    ' RestTime:' +
    RestTime +
    ' FTfrom:' +
    FTfrom +
    ' Flight: ' +
    Flight;

  return {
    Start: Start,
    First: First,
    FirstNeg: FirstNeg,
    StartNeg: StartNeg,
    FlightNeg: FlightNeg,
    Flight: Flight,
    Duration: Duration,
    Empty: empty,
    noEarly: noEarly,
    Debug: Debug,
  };
}

function subDecToTime(Time, Dec) {
  var dec = Math.round(60 * Dec);
  var hours;
  if (dec > 0) {
    hours = Math.floor(dec / 60);
  } else {
    hours = Math.ceil(dec / 60);
  }
  var minutes = Math.round(dec % 60);
  var hour;
  var minute;
  if (Time.length == 4) {
    hour = parseInt(Time.substring(0, 2));
    minute = parseInt(Time.substring(2, 4));
  }
  if (Time.length == 3) {
    hour = parseInt(Time.substring(0, 1));
    minute = parseInt(Time.substring(1, 3));
  }
  if (Time.length == 2) {
    hour = 0;
    minute = parseInt(Time.substring(0, 2));
  }
  if (Time.length == 1) {
    hour = 0;
    minute = parseInt(Time);
  }
  hour -= hours;

  if (minute > minutes) {
    minute -= minutes;
  } else {
    hour--;
    minute = 60 - minutes + minute;
  }
  if (minute >= 60) {
    minute -= 60;
    hour++;
  }
  if (hour < 0) {
    hour += 24;
  }
  if (hour == 0) {
    hour = '00';
  }
  if (hour > 0 && hour < 10) {
    hour = '0' + hour;
  }
  if (minute == 0) {
    minute = '00';
  }
  if (minute > 0 && minute < 10) {
    minute = '0' + minute;
  }
  var final = hour.toString() + minute.toString();
  return final;
}

function checkRT135(FT, Dual, Rule, RT) {
  if (Rule == 135 || Rule == 133) {
    if (Dual == 0) {
      if (FT > 8 && FT < 8.6) {
        return {
          result: true,
          text: iTrans('Need 11 hours rest time'),
          add: 1,
        };
      }
      if (FT > 8.5 && FT < 9.1) {
        return {
          result: true,
          text: iTrans('Need 12 hours rest time'),
          add: 2,
        };
      }
      if (FT > 9) {
        return {
          result: true,
          text: iTrans('Need 16 hours rest time'),
          add: 6,
        };
      }
      return {
        result: false,
        text: '',
        add: 0,
      };
    } else {
      if (FT > 10 && FT < 10.6) {
        return {
          result: true,
          text: iTrans('Need 11 hours rest time'),
          add: 1,
        };
      }
      if (FT > 10.5 && FT < 11.1) {
        return {
          result: true,
          text: iTrans('Need 12 hours rest time'),
          add: 2,
        };
      }
      if (FT > 11) {
        return {
          result: true,
          text: iTrans('Need 16 hours rest time'),
          add: 6,
        };
      }
    }
  } else {
    //HEMS operation
    if (FT > 8) {
      return {
        result: true,
        text: iTrans('Need') + ' ' + RT + ' ' + iTrans('hours rest time'),
        add: RT - 8,
      };
    }
  }
  return {
    result: false,
    text: '',
    add: 0,
  };
}

function checkPrevRT135(FT, RT, Dual, rec) {
  if (rec.Rule == 135 || rec.Rule == 133) {
    if (Dual == 0) {
      if (FT < 8.1 && RT < 10) {
        return {
          result: true,
          text: iTrans('Needed 10 hours rest time'),
          add: 0,
        };
      }
      if (FT > 8 && FT < 8.6 && RT < 11) {
        return {
          result: true,
          text: iTrans('Needed 11 hours rest time'),
          add: 1,
        };
      }
      if (FT > 8.5 && FT < 9.1 && RT < 12) {
        return {
          result: true,
          text: iTrans('Needed 12 hours rest time'),
          add: 2,
        };
      }
      if (FT > 9 && RT < 16) {
        return {
          result: true,
          text: iTrans('Needed 16 hours rest time'),
          add: 6,
        };
      }
      return {
        result: false,
        text: '',
        add: 0,
      };
    } else {
      if (FT < 10.1 && RT < 10) {
        return {
          result: true,
          text: iTrans('Needed 10 hours rest time'),
          add: 0,
        };
      }
      if (FT > 10 && FT < 10.6 && RT < 11) {
        return {
          result: true,
          text: iTrans('Needed 11 hours rest time'),
          add: 1,
        };
      }
      if (FT > 10.5 && FT < 10.1 && RT < 12) {
        return {
          result: true,
          text: iTrans('Needed 12 hours rest time'),
          add: 2,
        };
      }
      if (FT > 11 && RT < 16) {
        return {
          result: true,
          text: iTrans('Needed 16 hours rest time'),
          add: 6,
        };
      }
      return {
        result: false,
        text: '',
        add: 0,
      };
    }
  } else {
    //HEMS Prev Rest Time
    var MinRT = 12;
    if (rec.Extend == 1) {
      MinRT = 16;
    }
    if (FT > 8 && RT < MinRT) {
      if (rec.Extend == 1) {
        return {
          result: true,
          text: iTrans('Needed 16 hours rest time'),
          add: 8,
        };
      } else {
        return {
          result: true,
          text: iTrans('Needed 12 hours rest time'),
          add: 4,
        };
      }
    }
  }
  return {
    result: false,
    text: '',
    add: 0,
  };
}

function checkOff717(DT, RT7, RT17, Rule, Heli, DHC, Noff_7, Noff_17, Noff) {
  // requires 36 consecutive with 7 days OR 3 consecutive within 17
  //700.19(a)
  //RT7 in hours and RT17 in Calendar Days
  var check = '';
  var Noff_Limit = '';
  //todo add exception in the future to handle medevac flights that will use old logice for 704/705 ops
  if (Rule == 704 || Rule == 705) {
    return {
      result: false,
      text: '',
      Noff: Noff,
      Noff_Limit: Noff_Limit,
    }; //defult return false
  }
  if (Rule == 604) {
    var Off = Noff_7;
    if (Noff_17 > Noff_7) {
      Off = Noff_17;
    }
    if (RT17 < 3 && RT7 < 36 && DT != 0) {
      return {
        result: true,
        text: iTrans('Need 36 consecutive hours off in 7 days OR 3 consecutive calendar days off in 17 days'),
        Noff: Off,
      };
    } else {
      return {
        result: false,
        text: '',
        Noff: Off,
        Noff_Limit: Noff_Limit,
      }; //defult return false
    }
  }
  if (Noff_17.getTime() > Noff.getTime()) {
    Noff = Noff_17;
    Noff_Limit = '3do in 17d';
  }
  if (Noff_7.getTime() > Noff.getTime()) {
    Noff = Noff_7;
    Noff_Limit = '36ho in 7d';
  }
  return {
    result: false,
    text: '',
    Noff: Noff,
    Noff_Limit: Noff_Limit,
  }; //defult return false
}

function checkOff30(Off30, Off42, Extend42on, Rule, Heli, DHC, AA, Split) {
  if (AA == 1) {
    if (AA == 1 && Split == 1 && Off30 < 5) {
      return {
        result: true,
        text: iTrans('Need 5 days off within 30 days'),
      };
    }
  } else {
    //requires 3 days off within 30 if not extended
    //700.19(b)
    if ((Rule == 702 && !Extend42on) || (Rule == 703 && !Extend42on) || (Heli == 1 && !Extend42on)) {
      if (Off30 < 3) {
        return {
          result: true,
          text: iTrans('Need 3 days off within 30 days'),
        };
      } else {
        return {
          result: false,
          text: '',
        };
      }
    }
    if (Off42 < 5 && Extend42on && OS94 && Off30 < 3) {
      return {
        result: true,
        text: iTrans('Need 5 Consecutive days off within 47 days'),
      };
    }
  }
  return {
    result: false,
    text: '',
  }; //default return false
}

function checkOff90(Off90, Extend, Rule, Heli) {
  // requires 13 days off within 90 days
  // 700.19(b)
  if (Rule == 702 || Rule == 703 || Heli == 1) {
    if (Off90 < 13) {
      return {
        result: true,
        text: iTrans('Need 13 days off within 90 days'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }

  return {
    result: false,
    text: '',
  }; //default return false
}

function checkPrevDuty(pDT, FT, Extend, UOC) {
  if (pDT > 14 && pDT <= 15 && Extend == 1 && FT > 8 && !UOC.L1) {
    return {
      result: true,
      text: iTrans('WARNING: Over Maximum 8 hours flight time in 24 hours, increase rest period by 1 hour'),
      maxFT: 8,
    };
  } else if (pDT > 14 && pDT <= 15 && Extend == 1 && FT > 8 && UOC.L1) {
    return {
      result: true,
      text: iTrans(
        'WARNING: Over Maximum 11 hours flight time in 24 hours with Unforeseen  operational circumstance, increase rest period by 1 hour'
      ),
      maxFT: 8,
    };
  } else {
    return {
      result: false,
      text: '',
      maxFT: null,
    };
  } //default return false
}

function checkDuty(
  DT,
  RT,
  Extend,
  DTadd,
  dayFT,
  AA,
  split,
  L30,
  Rule,
  RTadd,
  UOC,
  Reserve,
  Reserve2,
  rec,
  data,
  Overnight
) {
  var CheckDuty = {
    DT: 0,
    Acc: { Hours: 0, Timezone: rec.Timezone, DSTcorrected: false },
  };
  if (rec.Rule == 705) {
    CheckDuty = FindMaxDuty(rec, data, Overnight);
  }

  //Reserve duty depends on beginning fo the reserve duty period

  if ((DT > 10 && Reserve && !UOC.L1) || (DT > 10 && Reserve2 && !UOC.L1)) {
    return {
      result: true,
      text: iTrans('Over 10 hour duty day for reserve'),
      maxDuty: 10,
      Acc: CheckDuty.Acc,
    };
  }
  if ((DT > 13 && Reserve && UOC.L1) || (DT > 10 && Reserve2 && UOC.L1)) {
    return {
      result: true,
      text: iTrans('Over 13 hour duty day for reserve with Unforeseen  operational circumstance'),
      maxDuty: 10,
      Acc: CheckDuty.Acc,
    };
  }
  if (Reserve || Reserve2) {
    RTadd += round10(DT / 2);
  }

  var maxDuty = 14;
  if (AA == 1) {
    if (DT > 14 && split == 1 && dayFT > 0 && !UOC.L1) {
      return {
        result: true,
        text: iTrans('Over 14 hour duty day for split duty aerial application'),
        maxDuty: 14,
        Acc: CheckDuty.Acc,
      };
    }
    if (DT > 17 && split == 1 && dayFT > 0 && UOC.L1) {
      return {
        result: true,
        text: iTrans(
          'Over 17 hour duty day for split duty aerial application with Unforeseen  operational circumstance'
        ),
        maxDuty: 14,
        Acc: CheckDuty.Acc,
      };
    }
  } else {
    if (Rule != 604 && Rule != 705) {
      maxDuty = 14 + DTadd;
      if (DT > DTadd + 14 && Extend == 0 && dayFT > 0 && !UOC.L1) {
        return {
          result: true,
          text: iTrans('Over') + ' ' + (DTadd + 14) + ' ' + iTrans('hour duty day') + ' DT:' + DT,
          maxDuty: DTadd + 14,
          Acc: CheckDuty.Acc,
        };
      }
      if (DT > DTadd + 14 && DT <= DTadd + 15 && dayFT > 8 && !UOC.L1) {
        return {
          result: false,
          text:
            iTrans('WARNING') +
            ': ' +
            iTrans('Minimum') +
            ' ' +
            (RTadd + 8) +
            ' ' +
            iTrans('hour rest period required tonight'),
          maxDuty: DTadd + 14,
          Acc: CheckDuty.Acc,
        };
      }
      if (Extend == 1) {
        maxDuty = 15 + DTadd;
      }
      if (DT > DTadd + 15 && Extend == 1 && dayFT > 0 && !UOC.L1) {
        return {
          result: true,
          text: iTrans('Over') + ' ' + (DTadd + 15) + ' ' + iTrans('hour duty day') + ' DT:' + DT,
          maxDuty: DTadd + 15,
          Acc: CheckDuty.Acc,
        };
      }

      if (DT > DTadd + 17 && Extend == 0 && dayFT > 0 && UOC.L1) {
        return {
          result: true,
          text:
            iTrans('Over') +
            ' ' +
            (DTadd + 17) +
            ' ' +
            iTrans('hour duty day with Unforeseen  operation circumstance') +
            ' DT:' +
            DT,
          maxDuty: DTadd + 17,
          Acc: CheckDuty.Acc,
        };
      }
      if (DT > DTadd + 17 && DT <= DTadd + 18 && dayFT > 8 && UOC.L1) {
        var UOCadd = dayFT - 8;
        if (UOCadd < 0) {
          UOCadd = 0;
        }
        if (DTadd + 14 - DT > UOCadd) {
          UOCadd = DTadd + 14 - DT;
        }
        return {
          result: false,
          text:
            iTrans('WARNING') +
            ': ' +
            iTrans('Minimum') +
            ' ' +
            (RTadd + 8 + UOCadd) +
            ' ' +
            iTrans('hour rest period required tonight due to Unforeseen  operational circumstance'),
          maxDuty: DTadd + 14,
          Acc: CheckDuty.Acc,
        };
      }
      if (DT > DTadd + 18 && Extend == 1 && dayFT > 0 && UOC.L1) {
        return {
          result: true,
          text:
            iTrans('Over') +
            ' ' +
            (DTadd + 18) +
            ' ' +
            iTrans('hour duty day with Unforeseen  operation circumstance') +
            ' DT:' +
            DT,
          maxDuty: DTadd + 15,
          Acc: CheckDuty.Acc,
        };
      }
    } else if (Rule != 705) {
      if (L30 > 70) {
        maxDuty = 14 + DTadd;
        if (DT > DTadd + 14 && dayFT > 0 && !UOC.L1) {
          return {
            result: true,
            text: iTrans('Over') + ' ' + (DTadd + 14) + ' ' + iTrans('hour maximum duty day'),
            maxDuty: DTadd + 14,
            Acc: CheckDuty.Acc,
          };
        }
      } else {
        maxDuty = 15 + DTadd;
        if (
          (RT < 24 && DT > DTadd + 15 && dayFT > 0 && !UOC.L1) ||
          (RT >= 24 && DT > DTadd + 15 && dayFT > 0 && !UOC.L1)
        ) {
          return {
            result: true,
            text: iTrans('Over') + ' ' + (DTadd + 15) + ' ' + iTrans('hour maximum duty day'),
            maxDuty: DTadd + 15,
            Acc: CheckDuty.Acc,
          };
        }
      }
      if (DT > DTadd + 14 && DT <= DTadd + 15 && dayFT > 8 && !UOC.L1) {
        return {
          result: false,
          text:
            iTrans('WARNING') +
            ': ' +
            iTrans('Minimum') +
            ' ' +
            (RTadd + 8 + UOCadd) +
            ' ' +
            iTrans('hour rest period required tonight'),
          maxDuty: DTadd + 14,
          Acc: CheckDuty.Acc,
        };
      }

      if (L30 > 70) {
        maxDuty = 14 + DTadd;
        if (DT > DTadd + 14 && dayFT > 0 && UOC.L1) {
          return {
            result: true,
            text:
              iTrans('Over') +
              ' ' +
              (DTadd + 14) +
              ' ' +
              iTrans('hour maximum duty day with Unforeseen  operation circumstance'),
            maxDuty: DTadd + 14,
            Acc: CheckDuty.Acc,
          };
        }
      } else {
        maxDuty = 15 + DTadd;
        if (
          (RT < 24 && DT > DTadd + 15 && dayFT > 0 && UOC.L1) ||
          (RT >= 24 && DT > DTadd + 15 && dayFT > 0 && UOC.L1)
        ) {
          return {
            result: true,
            text:
              iTrans('Over') +
              ' ' +
              (DTadd + 15) +
              ' ' +
              iTrans('hour maximum duty day with Unforeseen  operation circumstance'),
            maxDuty: DTadd + 15,
            Acc: CheckDuty.Acc,
          };
        }
      }

      if (DT > DTadd + 14 && DT <= DTadd + 15 && dayFT > 8 && UOC.L1) {
        var UOCadd = dayFT - 8;
        if (UOCadd < 0) {
          UOCadd = 0;
        }
        if (DTadd + 14 - DT > UOCadd) {
          UOCadd = DTadd + 14 - DT;
        }
        return {
          result: false,
          text:
            iTrans('WARNING') +
            ': ' +
            iTrans('Minimum') +
            ' ' +
            (RTadd + 8 + UOCadd) +
            ' ' +
            iTrans('hour rest period required tonight due to Unforeseen  operation circumstance'),
          maxDuty: DTadd + 14,
          Acc: CheckDuty.Acc,
        };
      }
    } else {
      //Rule must be 705 maximum 9 to 13 hours in 24 hour period

      if (rec == '') {
        return {
          result: false,
          text: '',
          maxDuty: 9,
          Acc: CheckDuty.Acc,
        }; //default return false
      }

      maxDuty = CheckDuty.DT + DTadd;

      if (rec.FT > 0) {
        if (DT > maxDuty && dayFT > 0 && !UOC.L1) {
          return {
            result: true,
            text: iTrans('Over') + ' ' + maxDuty + ' ' + iTrans('hour maximum duty day'),
            maxDuty: maxDuty,
            Acc: CheckDuty.Acc,
          };
        }
        var UOC_add = 0;
        if (UOC.L1) {
          if (rec.CrewCount == 1) {
            UOC_add = 1;
          }
          if (rec.CrewCount > 1) {
            UOC_add = 2;
          }
          if (rec.CrewCount > 1 && rec.Sectors == 1) {
            UOC_add = 3;
          }
        }
        if (DT > maxDuty + 3 && dayFT > 0 && UOC.L1) {
          return {
            result: true,
            text:
              iTrans('Over') +
              ' ' +
              (maxDuty + UOC_add) +
              ' ' +
              iTrans('hour maximum duty day with Unforeseen  operation circumstance'),
            maxDuty: maxDuty + UOC_add,
            Acc: CheckDuty.Acc,
          };
        }
      } else {
        maxDuty = 18;
      }
      return {
        result: false,
        text: '',
        maxDuty: maxDuty,
        Acc: CheckDuty.Acc,
      }; //default return false
    }
  }
  return {
    result: false,
    text: '',
    maxDuty: maxDuty,
    Acc: CheckDuty.Acc,
  }; //default return false
}

function FindAcclimatizedTZ(rec, data) {
  //If timezone change was 4 or more hours and it has been less than 96 hours sice the change OR
  //if timezone change was less than 4 hours and it has been less than 72 hours
  //convert the rec start time to the previous time zone since they are not acclimatized to the new time
  //NOTE: Edge case if daylight savings time has happened they are not acclimatized either

  //If the pilot didn't fly and go over the max duty here eg grooming aircraft office etc the rest time will be increased to
  //the greater of the standard rest period or the full duty period including the additional work
  var LastTZDiff = -1;
  var Timezone = rec.Timezone;
  var TZ_HourDif = 0;
  var count = 0;
  var dateDST = new Date(rec.Date + 'T00:00:00');
  var curDST = dateDST.isDstObserved();
  var correctForDST = 0;
  var DSTcorrected = false;
  for (var i in data) {
    var recDST = new Date(data[i].Date + 'T00:00:00');
    recDST = recDST.isDstObserved();
    if (data[i].Timezone == rec.Timezone) {
      TZ_HourDif = 0;
    } else {
      TZ_HourDif = Get_TZ_Difference(data[i].Timezone, rec.Timezone);
    }
    if (recDST != curDST && correctForDST == 0) {
      DSTcorrected = true;
      if (curDST == false) {
        correctForDST = -1;
      } else {
        correctForDST = 1;
      }
    }
    if (TZ_HourDif == LastTZDiff) {
      count++;
      if ((count == 3 && LastTZDiff < 4) || (count == 4 && LastTZDiff >= 4)) {
        Timezone = data[i].Timezone;
        break;
      }
    } else {
      count = 0;
      dateDST = new Date(data[i].Date + 'T00:00:00');
      LastTZDiff = TZ_HourDif;
    }
  }
  return { Hours: LastTZDiff + correctForDST, Timezone: Timezone, DSTcorrected: DSTcorrected };
}

function FindMaxDuty(rec, data, Overnight) {
  var Start = rec.Start;
  var AccTimeZone = FindAcclimatizedTZ(rec, data);
  if (AccTimeZone.Hours != 0) {
    Start = subDecToTime(rec.Start, -AccTimeZone.Hours);
  }

  var Avg0 = [
    { from: 0, to: 359, Flights: [{ from: 1, to: 999, limit: 9 }] },
    {
      from: 400,
      to: 459,
      Flights: [
        { from: 1, to: 11, limit: 10 },
        { from: 12, to: 999, limit: 9 },
      ],
    },
    {
      from: 500,
      to: 559,
      Flights: [
        { from: 1, to: 11, limit: 11 },
        { from: 12, to: 17, limit: 10 },
        { from: 18, to: 999, limit: 9 },
      ],
    },
    {
      from: 600,
      to: 659,
      Flights: [
        { from: 1, to: 11, limit: 12 },
        { from: 12, to: 17, limit: 11 },
        { from: 18, to: 999, limit: 10 },
      ],
    },
    {
      from: 700,
      to: 1259,
      Flights: [
        { from: 1, to: 11, limit: 13 },
        { from: 12, to: 17, limit: 12 },
        { from: 18, to: 999, limit: 11 },
      ],
    },
    {
      from: 1300,
      to: 1659,
      Flights: [
        { from: 1, to: 11, limit: 12.5 },
        { from: 12, to: 17, limit: 11.5 },
        { from: 18, to: 999, limit: 10.5 },
      ],
    },
    {
      from: 1700,
      to: 2159,
      Flights: [
        { from: 1, to: 11, limit: 12 },
        { from: 12, to: 17, limit: 11 },
        { from: 18, to: 999, limit: 10 },
      ],
    },
    {
      from: 2200,
      to: 2259,
      Flights: [
        { from: 1, to: 11, limit: 11 },
        { from: 12, to: 17, limit: 10 },
        { from: 18, to: 999, limit: 9 },
      ],
    },
    {
      from: 2300,
      to: 2359,
      Flights: [
        { from: 1, to: 11, limit: 10 },
        { from: 12, to: 17, limit: 9 },
        { from: 18, to: 999, limit: 9 },
      ],
    },
  ];
  var Avg30 = [
    { from: 0, to: 359, Flights: [{ from: 1, to: 999, limit: 9 }] },
    {
      from: 400,
      to: 459,
      Flights: [
        { from: 1, to: 11, limit: 10 },
        { from: 12, to: 999, limit: 9 },
      ],
    },
    {
      from: 500,
      to: 559,
      Flights: [
        { from: 1, to: 7, limit: 11 },
        { from: 8, to: 11, limit: 10 },
        { from: 12, to: 999, limit: 9 },
      ],
    },
    {
      from: 600,
      to: 659,
      Flights: [
        { from: 1, to: 7, limit: 12 },
        { from: 8, to: 11, limit: 11 },
        { from: 12, to: 999, limit: 10 },
      ],
    },
    {
      from: 700,
      to: 1259,
      Flights: [
        { from: 1, to: 7, limit: 13 },
        { from: 8, to: 11, limit: 12 },
        { from: 12, to: 999, limit: 11 },
      ],
    },
    {
      from: 1300,
      to: 1659,
      Flights: [
        { from: 1, to: 7, limit: 12.5 },
        { from: 8, to: 11, limit: 11.5 },
        { from: 12, to: 999, limit: 10.5 },
      ],
    },
    {
      from: 1700,
      to: 2159,
      Flights: [
        { from: 1, to: 7, limit: 12 },
        { from: 8, to: 11, limit: 11 },
        { from: 12, to: 999, limit: 10 },
      ],
    },
    {
      from: 2200,
      to: 2259,
      Flights: [
        { from: 1, to: 7, limit: 11 },
        { from: 8, to: 11, limit: 10 },
        { from: 12, to: 999, limit: 9 },
      ],
    },
    {
      from: 2300,
      to: 2359,
      Flights: [
        { from: 1, to: 7, limit: 10 },
        { from: 8, to: 11, limit: 9 },
        { from: 12, to: 999, limit: 9 },
      ],
    },
  ];
  var Avg50 = [
    { from: 0, to: 359, Flights: [{ from: 1, to: 999, limit: 9 }] },
    {
      from: 400,
      to: 459,
      Flights: [
        { from: 1, to: 4, limit: 10 },
        { from: 5, to: 999, limit: 9 },
      ],
    },
    {
      from: 500,
      to: 559,
      Flights: [
        { from: 1, to: 4, limit: 11 },
        { from: 5, to: 6, limit: 10 },
        { from: 7, to: 999, limit: 9 },
      ],
    },
    {
      from: 600,
      to: 659,
      Flights: [
        { from: 1, to: 4, limit: 12 },
        { from: 5, to: 6, limit: 11 },
        { from: 7, to: 999, limit: 10 },
      ],
    },
    {
      from: 700,
      to: 1259,
      Flights: [
        { from: 1, to: 4, limit: 13 },
        { from: 5, to: 6, limit: 12 },
        { from: 7, to: 999, limit: 11 },
      ],
    },
    {
      from: 1300,
      to: 1659,
      Flights: [
        { from: 1, to: 4, limit: 12.5 },
        { from: 5, to: 6, limit: 11.5 },
        { from: 7, to: 999, limit: 10.5 },
      ],
    },
    {
      from: 1700,
      to: 2159,
      Flights: [
        { from: 1, to: 4, limit: 12 },
        { from: 5, to: 6, limit: 11 },
        { from: 7, to: 999, limit: 10 },
      ],
    },
    {
      from: 2200,
      to: 2259,
      Flights: [
        { from: 1, to: 4, limit: 11 },
        { from: 5, to: 6, limit: 10 },
        { from: 7, to: 999, limit: 9 },
      ],
    },
    {
      from: 2300,
      to: 2359,
      Flights: [
        { from: 1, to: 4, limit: 10 },
        { from: 5, to: 6, limit: 9 },
        { from: 7, to: 999, limit: 9 },
      ],
    },
  ];
  var Table = Avg0;
  if (rec.ET2 == null) {
    rec.ET2 = 0;
  }
  switch (rec.ET2.toString()) {
    case '0':
      Table = Avg0;
      break;
    case '30':
      Table = Avg30;
      break;
    case '50':
      Table = Avg50;
      break;
  }

  for (var i in Table) {
    var I = Table[i];
    if (I.from <= Start && I.to >= Start) {
      for (var f in I.Flights) {
        var F = I.Flights[f];
        if (rec.Sectors >= F.from && rec.Sectors <= F.to) {
          return { DT: F.limit, Acc: AccTimeZone };
        }
      }
    }
  }
  return { DT: 9, Acc: AccTimeZone };
}

function check1(FT, DT, Extend, IFR, Dual, RT, UOC, rec) {
  //705 can go max 16 hour flight with augmented flight crew.  need clarification on the parameters to allow this
  if (rec.Rule == 705) {
    /*
     if (FT > 16 && )
     {
     return {
     result: true,
     text  : iTrans('Over 16 hours flight time last 24 hours'),
     maxFT : 16
     };
     }
     */

    if (FT > 8 && rec.CrewCount < 2) {
      return {
        result: true,
        text: iTrans('Over 8 hours single pilot flight time last 24 hours'),
        maxFT: 8,
      };
    }
  }
  //Single Pilot IFR / Extended 15 hour duty day logging,dhc,heli 8 hours within 24
  if ((DT > 14 && Extend == 1) || (IFR == 1 && Dual == 0)) {
    if (IFR == 1 && Dual == 0 && FT > 8 && !UOC.L1) {
      return {
        result: true,
        text: iTrans('Over 8 hours single pilot IFR last 24 hours'),
        maxFT: 8,
      };
    }
    if (IFR == 1 && Dual == 0 && FT > 11 && UOC.L1) {
      return {
        result: true,
        text: iTrans('Over 11 hours single pilot IFR last 24 hours with Unforeseen  operational circumstance'),
        maxFT: 8,
      };
    }

    if (IFR == 0 && FT > 8 && RT < 9 && !UOC.L1) {
      return {
        result: true,
        text: iTrans('Over 8 hours flight time last 24 hours with less than 9 hours rest while extended'),
        maxFT: 8,
      };
    }
    if (IFR == 0 && FT > 11 && RT < 9 && UOC.L1) {
      return {
        result: true,
        text: iTrans(
          'Over 11 hours flight time last 24 hours with less than 9 hours rest while extended with Unforeseen  operation circumstance'
        ),
        maxFT: 8,
      };
    }

    return {
      result: false,
      text: '',
      maxFT: 8,
    };
  }
  return {
    result: false,
    text: '',
    maxFT: null,
  };
}

function getMaxNextFltTimes(FT, Rule, Extend, Standby, Logging, Heli, DualPilot, DHC, L6, L29, L41, L89, L179, L364) {
  //Check7
  var MaxTime = 1200;
  var Limiter = '';
  if (Rule == 702 || Rule == 703 || Rule == 604) {
    //60 in 7 days
    L6 = 60 - L6;
  }
  if (Rule == 704 && Heli == 0) {
    // 40 in 7 days
    L6 = 40 - L6;
  }
  if (Rule == 705 && Heli == 0) {
    //unlimited now so zero it out
    L6 = 0;
  }
  if (L6 < MaxTime) {
    MaxTime = L6;
    Limiter = '7 ' + iTrans('Day');
  }
  //Check30
  if ((Rule == 702 && Extend == 1 && Standby == 0) || (Rule == 703 && Extend == 1 && Standby == 0)) {
    //default for extensions otherwise
    L29 = 150 - L29;
  } else if (Standby == 1) {
    //100 in 30 days
    L29 = 100 - L29;
  } else {
    // 120 in 30 days for all except standby and extensions
    L29 = 120 - L29;
  }
  if (L29 < MaxTime) {
    MaxTime = L29;
    Limiter = '30 ' + iTrans('Day');
  }
  //Check42
  L41 = 210 - L41;
  if (L41 < MaxTime) {
    MaxTime = L41;
    Limiter = '42 ' + iTrans('Day');
  }
  //Check90
  if ((Rule == 702 && Extend == 1) || (Rule == 703 && Extend == 1)) {
    //450 in 90 days
    L89 = 450 - L89;
  } else {
    //300 in 90 days for all except extensions
    L89 = 300 - L89;
  }
  if (L89 < MaxTime) {
    MaxTime = L89;
    Limiter = '90 ' + iTrans('Day');
  }
  //Check180
  if (Rule == 702 || Rule == 703) {
    L179 = 900 - L179;
    if (L179 < MaxTime) {
      MaxTime = L179;
      Limiter = '180 ' + iTrans('Day');
    }
    L179 = round10(L179);
  } else {
    L179 = 'N/A';
  }

  //Check365
  L364 = 1200 - L364;
  if (L364 < MaxTime) {
    MaxTime = L364;
    Limiter = '365 ' + iTrans('Day');
  }
  return {
    L6: round10(L6),
    L29: round10(L29),
    L41: round10(L41),
    L89: round10(L89),
    L179: L179,
    L364: round10(L364),
    MaxTime: round10(MaxTime),
    Limiter: Limiter,
  };
}

function check7(FT, Rule, Heli, DHC, UOC) {
  if (Rule == 705) {
    return {
      result: false,
      text: '',
    };
  }
  if (Rule == 702 || Rule == 703) {
    //60 in 7 days
    if (FT > 60 && !UOC.L7) {
      return {
        result: true,
        text: iTrans('Over 60 hours in 7 days'),
      };
    } else if (FT > 63 && UOC.L7) {
      return {
        result: true,
        text: iTrans('Over 63 hours in 7 days with Unforeseen  operational circumstance'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }
  if (Rule == 704 && Heli == 0) {
    // 40 in 7 days
    if (FT > 40 && !UOC.L7) {
      return {
        result: true,
        text: iTrans('Over 40 hours in 7 days'),
      };
    } else if (FT > 43 && UOC.L7) {
      return {
        result: true,
        text: iTrans('Over 43 hours in 7 days with Unforeseen  operational circumstance'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }
  return {
    result: false,
    text: '',
  };
}

function check30(FT, Rule, Extend, Standby, Logging, Heli, DualPilot, DHC, OnCall, UOC) {
  if (Rule == 705) {
    if (FT > 112) {
      return {
        result: true,
        text: iTrans('Over 112 hours in 28 days'),
      };
    }
  }
  if ((Rule == 702 && Extend == 1 && Standby == 0) || (Rule == 703 && Extend == 1 && Standby == 0)) {
    //default for extensions otherwise
    if (FT > 150 && !UOC.L30) {
      return {
        result: true,
        text: iTrans('Over 150 hours in 30 days'),
      };
    } else if (FT > 153 && UOC.L30) {
      return {
        result: true,
        text: iTrans('Over 153 hours in 30 days with Unforeseen  operational circumstance'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }
  if (OnCall) {
    //100 in 30 days
    if (FT > 100) {
      return {
        result: true,
        text: iTrans('Over 100 hours in 30 days (On Call)'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }
  // 120 in 30 days for all except standby and extensions
  if (FT > 120) {
    return {
      result: true,
      text: iTrans('Over 120 hours in 30 days'),
    };
  } else {
    return {
      result: false,
      text: '',
    };
  }
}

function check42(FT) {
  // 702/703 Only 210 hours in 42 with extension
  if (FT > 210) {
    return {
      result: true,
      text: iTrans('Over 210 hours in 42 days'),
    };
  } else {
    return {
      result: false,
      text: '',
    };
  }
}

function check90(FT, Rule, Extend, UOC) {
  if ((Rule == 702 && Extend == 1) || (Rule == 703 && Extend == 1)) {
    //450 in 90 days
    if (FT > 450 && !UOC.L90) {
      return {
        result: true,
        text: iTrans('Over 450 hours in 90 days'),
      };
    } else if (FT > 453 && UOC.L90) {
      return {
        result: true,
        text: iTrans('Over 453 hours in 90 days with Unforeseen  operational circumstance'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  } else {
    //300 in 90 days for all except extensions
    if (FT > 300 && !UOC.L90) {
      return {
        result: true,
        text: iTrans('Over 300 hours in 90 days'),
      };
    } else if (FT > 303 && UOC.L90) {
      return {
        result: true,
        text: iTrans('Over 303 hours in 90 days with Unforeseen  operational circumstance'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }
}

function check180(FT, Rule) {
  //900 in 180 days for 702 or 703 only with extension
  if (Rule == 702 || Rule == 703) {
    if (FT > 900) {
      return {
        result: true,
        text: iTrans('Over 900 hours in 180 days'),
      };
    }
  }
  return {
    result: false,
    text: '',
  };
}

function check365(FT, Rule) {
  if (Rule == 705) {
    if (FT > 1000) {
      return {
        result: true,
        text: iTrans('Over 1000 hours in 365 days'),
      };
    } else {
      return {
        result: false,
        text: '',
      };
    }
  }
  //1200 in 365 days for all scenarios
  if (FT > 1200) {
    return {
      result: true,
      text: iTrans('Over 1200 hours in 365 days'),
    };
  } else {
    return {
      result: false,
      text: '',
    };
  }
}

function duty_Calc(rec, ON, PrevRec = '') {
  var Overnight = ON || false;

  var TZ_HourDif = 0;
  if (rec == undefined) {
    return 0;
  }
  var Reserve = false;
  var Reserve2 = false;
  switch (rec.Standby.toString()) {
    case '2':
    case '5':
      Reserve = true;
      break;
    case '6':
    case '7':
      Reserve2 = true;
      break;
    case '8':
      Reserve = true;
      Reserve2 = true;
      break;
    case '19':
      Reserve2 = true;
      break;
    case '35':
      Reserve = true;
      break;
  }

  if (PrevRec == '') {
    TZ_HourDif = 0;
  } else {
    if (PrevRec.Timezone != rec.Timezone) {
      TZ_HourDif = Get_TZ_Difference(PrevRec.Timezone, rec.Timezone);
    } else {
      TZ_HourDif = 0;
    }
  }
  if (rec.hasOwnProperty('Start')) {
    var DT1 = dutytime_Calc(rec.Start, rec.End);
    if ((rec.Split == '1' || rec.Split == '3') && rec.Start2 != 0 && rec.End2 != 0) {
      if (rec.AerialApp == 1) {
        var DT2 = dutytime_Calc(rec.Start2, rec.End2);
        DT1 += DT2;
      } else if (Overnight) {
        DT1 = 0;
        if (rec.End2 == '2359') {
          DT1 = dutytime_Calc(rec.Start2, rec.End2);
          if (rec.Rule == 705 && Reserve2) DT1 = round10(DT1 * 0.33);
        }
        if (PrevRec != '') {
          if ((PrevRec.Split == 1 || PrevRec.Split == 3) && rec.Start2 != 0 && rec.End2 != 0) {
            DT1 = dutytime_Calc(PrevRec.Start2, '2359');
          } else {
            if (PrevRec.Overnight == 1) {
              DT1 = 0;
            } else if (parseInt(PrevRec.End) >= 2000) {
              DT1 = dutytime_Calc(PrevRec.Start, '2359');
            } else {
              DT1 = 0;
            }
          }
          DT1 += dutytime_Calc('0000', rec.End) + TZ_HourDif;
          if (rec.Rule == 705 && Reserve) DT1 = round10(DT1 * 0.33);
        }
      } else {
        //Check if working overnights
        if (rec.Overnight == undefined) {
          var pREC_RT = 0;
          if (PrevRec != '') {
            if ((PrevRec.Split == 1 || PrevRec.Split == 3) && rec.Start2 != 0 && rec.End2 != 0) {
              pREC_RT = dutytime_Calc(PrevRec.End, '2359') + dutytime_Calc('0', rec.Start) + TZ_HourDif;
            } else {
              pREC_RT = dutytime_Calc(PrevRec.End2, '2359') + dutytime_Calc('0', rec.Start) + TZ_HourDif;
            }
          }

          if (rec.End2 != 0 && dutytime_Calc(rec.End, rec.Start2) < 8) {
            DT1 = dutytime_Calc(rec.Start, rec.End2);
          } else if (dutytime_Calc(rec.End, rec.Start2) >= 8 && dutytime_Calc(rec.End, rec.Start2) > pREC_RT) {
            DT1 = dutytime_Calc(rec.Start2, rec.End2);
          } else {
            DT1 = dutytime_Calc(rec.Start, rec.End2);
          }
          if (rec.Rule == 705 && Reserve) DT1 = round10(DT1 * 0.33);
        } else {
          DT1 = dutytime_Calc(rec.Start, rec.End2);
          if (rec.Rule == 705 && Reserve) DT1 = round10(DT1 * 0.33);
        }
      }
    } else if (Overnight) {
      if (rec.End == '2359') {
        DT1 = dutytime_Calc(rec.Start, rec.End);
      }
      if (PrevRec != '') {
        if ((PrevRec.Split == 1 || PrevRec.Split == 3) && PrevRec.Start2 != 0 && PrevRec.End2 != 0) {
          if (PrevRec.Overnight == 1) {
            DT1 = dutytime_Calc(PrevRec.Start2, '2359');
          } else {
            DT1 = 0;
          }
        } else {
          if (PrevRec.Overnight == 1) {
            DT1 = 0;
          } else if (parseInt(PrevRec.End) >= 2000) {
            DT1 = dutytime_Calc(PrevRec.Start, '2359');
          } else {
            DT = 0;
          }
        }
        if (
          PrevRec.Overnight == 1 &&
          (PrevRec.Split == 1 || PrevRec.Split == 3) &&
          PrevRec.Start2 != 0 &&
          PrevRec.End2 != 0
        ) {
          DT1 += dutytime_Calc('0000', rec.End) + TZ_HourDif;
        } else {
          DT1 += dutytime_Calc(rec.Start, rec.End) + TZ_HourDif;
        }
        if (rec.Rule == 705 && Reserve) DT1 = round10(DT1 * 0.33);
      }
    } else {
      if (rec.Rule == 705 && Reserve) DT1 = round10(DT1 * 0.33);
    }
    return DT1;
  } else {
    return 0;
  }
}

function dutytime_Calc(start, end) {
  try {
    start = parseInt(start).toString();
  } catch (e) {
    start = '0000';
  }
  try {
    end = parseInt(end).toString();
  } catch (e) {
    end = '0000';
  }
  var H, H1, H2, M, M1, M2, DT;

  if (start.length < 3) {
    H1 = 0;
    M1 = parseInt(start);
  }
  if (end.length < 3) {
    H2 = 0;
    M2 = parseInt(end);
  }
  if (start.length == 3) {
    H1 = parseInt(start.substr(0, 1));
    M1 = parseInt(start.substr(1, 2));
  }
  if (end.length == 3) {
    H2 = parseInt(end.substr(0, 1));
    M2 = parseInt(end.substr(1, 2));
  }
  if (start.length == 4) {
    H1 = parseInt(start.substr(0, 2));
    M1 = parseInt(start.substr(2, 2));
  }
  if (end.length == 4) {
    H2 = parseInt(end.substr(0, 2));
    M2 = parseInt(end.substr(2, 2));
  }

  if ((H2 == 0 && M2 == 0 && H1 > 0) || (H2 == 0 && M2 == 0 && M1 > 0)) {
    H2 = 24;
  } //if old duty time ends at 0000 and start > 0
  H = H2 - H1;
  M = M2 - M1;

  if (M !== 0) {
    //M = Math.round(M/6)/10;
    if (M >= 60) {
      H++;
      M -= 60;
    }
    if (M < 0) {
      H--;
      M += 60;
    }

    if (M >= 9 && M <= 14) {
      M = 0.2;
    }
    if (M >= 15 && M <= 20) {
      M = 0.3;
    }
    if (M >= 21 && M <= 26) {
      M = 0.4;
    }
    if (M >= 27 && M <= 32) {
      M = 0.5;
    }
    if (M >= 33 && M <= 38) {
      M = 0.6;
    }
    if (M >= 39 && M <= 44) {
      M = 0.7;
    }
    if (M >= 45 && M <= 50) {
      M = 0.8;
    }
    if (M >= 51 && M <= 56) {
      M = 0.9;
    }

    if (H == 0) {
      if (M >= 1 && M <= 8) {
        M = 0.1;
      }
      if (M >= 57) {
        H++;
        M = 0;
      }
    } else {
      if (M >= 3 && M <= 8) {
        M = 0.1;
      } else if (M >= 57) {
        H++;
        M = 0;
      } else if (M >= 1 && M <= 2) {
        M = 0;
      }
    }

    DT = H + M;
  } else {
    DT = H;
  }
  if (isNaN(DT)) {
    console.log('Error DT isNaN');
    return 0;
  }
  DT = Math.round(DT * 10) / 10;
  return DT;
}

function round10(float) {
  var rounded = Math.round(float * 10) / 10;
  return rounded;
}

function registerFunction(PK, functionBody) {
  'use strict';
  var script = document.createElement('script');
  script.innerHTML = 'function FDTRULE' + PK + '(data, duty_Data){' + functionBody + '}';
  document.body.appendChild(script);
}

function markRec(id, Status, Warning, Violation) {
  //alert(id);

  var table = '';

  if (userShouldHaveFDT(USERTYPE)) {
    table = 'FDT_DaysV4';
  } else {
    table = 'FDT_StaffV3';
  }

  //todo look at records from previous 7 days to see if same Warning or Violation  exists
  var searchFrom = stringToDateObject(id);
  searchFrom.setDate(searchFrom.getDate() - 7);
  searchFrom = dateToStringObject(searchFrom).substr(0, 10);

  cirroDB.query(table, 'Date >= ? AND Date <= ? ORDER BY Date DESC', [searchFrom, id], function (e) {
    if (e.length == 0 || e === false) {
      //no rec to update
      console.log('marRec Failed');
    } else {
      var WarningNum, ViolationNum;
      if (Warning) {
        WarningNum = 1;
      } else {
        WarningNum = 0;
      }
      if (Violation) {
        ViolationNum = 1;
      } else {
        ViolationNum = 0;
      }
      var record = e[0];
      var WarningIgnore = false;
      var ViolationIgnore = false;
      for (var i in e) {
        var I = e[i];
        if (Warning) {
          if (I.Status == Status && I.Warning_Ack == 1) {
            WarningIgnore = true;
          } //Already had warning for same thing in last 7 days so ignore
        }
        if (Violation) {
          if (I.Status == Status && I.Violation_Ack == 1) {
            ViolationIgnore = true;
          } //Already had violation for same thing in last 7 days so ignore
        }
      }
      if (WarningIgnore && ViolationIgnore) {
        console.log('Previously Acknolwedged Violation/Warning Found');
        console.log('Warning / Violation Status: ' + Status);
        return;
      }
      record.Modified = dateToStringObject(new Date()); //to prevent problems with syncing
      record.Status = Status;
      record.Warning = WarningNum;
      record.Violation = ViolationNum;
      cirroDB.insert(table, record, null, function (e) {
        update_Sync('LastFDTsync', new Date());
      });
    }
  });
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param CurDate
 * @param init
 */
function CheckNVGCurrency(CurDate, init = false) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty-SHARED.CheckNVGCurrency', '2024-06-26', 'bldarosa');
  var FromDate = new Date(CurDate.getTime());
  if (COUNTRY == 'CANADA' || COUNTRY == 'KENYA') {
    //Currency for Canada is 90 days from current date
    if (LOCALSTORAGE.FDT_Experience != undefined) {
      FromDate = new Date(LOCALSTORAGE.FDT_Experience.NVIS_Date + 'T00:00:00Z');
    } else {
      FromDate.setDate(FromDate.getDate() - 90);
    }
  } else {
    //Currency for USA / Default all others is 2 calendar months so from beginnin of 2 montsh prior;
    FromDate.setDate(1);
    FromDate.setMonth(FromDate.getMonth() - 2);
    var Difference_In_Time = CurDate.getTime() - FromDate.getTime();
    Last2CalendarMonths = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    FromDate.setMonth(FromDate.getMonth() - 2);
    Difference_In_Time = CurDate.getTime() - FromDate.getTime();
    Last4CalendarMonths = Math.round(Difference_In_Time / (1000 * 3600 * 24));
  }

  cirroDB.query(
    'FDT_RecsV3',
    'DELETED = 0 AND Date >= ? AND Date <= ? ORDER BY Date DESC',
    [FromDate.toISOString().substr(0, 10), CurDate.toISOString().substr(0, 10)],
    function (r) {
      ProcessNVG_Currency(r, init);
    }
  );
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param CurDate
 * @param init
 */
function CheckIFRCurrency(CurDate, init = false) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty-SHARED.CheckIFRCurrency', '2024-06-26', 'bldarosa');
  var FromDate = new Date(CurDate.getTime());

  if (COUNTRY == 'UNITED STATES') {
    //from previous 6 calendar months
    FromDate.setMonth(FromDate.getMonth() - 6);
    FromDate.setDate(1);
  } else {
    //Currency for Canada is 180 days from current date
    FromDate.setDate(FromDate.getDate() - 180);
  }

  cirroDB.query(
    'FDT_RecsV3',
    'DELETED = 0 AND Date >= ? AND Date <= ? ORDER BY Date DESC',
    [FromDate.toISOString().substr(0, 10), CurDate.toISOString().substr(0, 10)],
    function (r) {
      try {
        ProcessIFR_Currency(r, init);
      } catch (e) {
        console.log(e);
      }
    }
  );
}

function ChecKNaN_Item(item) {
  if (isNaN(item) || item == '') {
    return 0;
  } else {
    return parseFloat(item);
  }
}
function ProcessIFR_Currency(r, init, returnData = false) {
  IFR_Report = {
    Total_Hrs: null,
    Total_Appr: null,
    Reason: '',
    ReasonTXT: '',
    Current: true,
    ValidFrom: '',
    ValidTo: '',
    ValidState: 'Violation',
  };
  var T = {
    AI: 0,
    GA: 0,
    IA: 0,
  };
  var IFR_Current = false;
  var IPC_isValid = false;
  var IPC_Expires = null;
  if (LOCALSTORAGE.FDT_Experience != undefined) {
    if (COUNTRY == 'CANADA') {
      IPC_Expires = Get_Date_ValidTo(LOCALSTORAGE.FDT_Experience.IPC_Date, 0, 24, false);
    }
    if (COUNTRY == 'UNITED STATES') {
      IPC_Expires = Get_Date_ValidTo(LOCALSTORAGE.FDT_Experience.IPC_Date, 0, 7, -1);
    }
  }

  for (var i in r) {
    var R = r[i];

    T.AI += parseFloat(ChecKNaN_Item(R.AI)); //IFR Time
    T.GA += parseInt(ChecKNaN_Item(R.GA)); //Precision Approaches
    T.IA += parseInt(ChecKNaN_Item(R.IA)); // Non Precision Approaches

    if (IFR_Report.ValidFrom == '') {
      if (COUNTRY == 'UNITED STATES') {
        //6 calendar months looking back
        if (T.GA + T.IA >= 6) {
          IFR_Report.ValidFrom = R.Date;
          IFR_Report.ValidTo = Get_Date_ValidTo(R.Date, 0, 6, false);
          if (IFR_Report.ValidTo != null) {
            IFR_Current = true;
            IFR_Report.ValidState = Check_Date_ValidTo(IFR_Report.ValidTo, 14);
          }
        }
      } else if (COUNTRY == 'CANADA') {
        //first day of 13th month don't need the 666
        //after that need 666 and totally invalid after 24 months from IPC date
        //666 is still valid if achieved during first 13 months
        //LOCALSTORAGE.FDT_Experience.IPC_Date
        if (IPC_Expires == null) {
          IFR_Report = {
            Total_Hrs: null,
            Total_Appr: null,
            Reason: '',
            ReasonTXT: '',
            Current: true,
            ValidFrom: '',
            ValidTo: '',
            ValidState: 'Violation',
          };
          break;
        } else {
          var IPC_ValidTo = Get_Date_ValidTo(LOCALSTORAGE.FDT_Experience.IPC_Date, 0, 13, true);
          if (IPC_ValidTo != null) {
            IPC_isValid = true;
            IFR_Current = true;
          }
          var Non_IPC_isValid = false;
          if (T.AI >= 6 && T.GA + T.IA >= 6) {
            IFR_Report.ValidFrom = R.Date;
            IFR_Report.ValidTo = Get_Date_ValidTo(R.Date, 0, 6, false);
            if (IFR_Report.ValidTo != null) {
              IFR_Report.ValidState = Check_Date_ValidTo(IFR_Report.ValidTo, 14);
              IFR_Current = true;
              Non_IPC_isValid = true;
            }
          }
        }
      } else {
        //All other countries IFR currency day calc
        if (T.AI >= 6 && T.GA + T.IA >= 6) {
          IFR_Report.ValidFrom = R.Date;
          IFR_Report.ValidTo = Get_Date_ValidTo(R.Date, 180);
          if (IFR_Report.ValidTo != null) {
            IFR_Report.ValidState = Check_Date_ValidTo(IFR_Report.ValidTo, 14);
            IFR_Current = true;
          }
        }
      }
    } else {
      break;
    }
  }
  if (COUNTRY == 'UNITED STATES') {
    if (!IFR_Current && IPC_Expires == null) {
      IFR_Report.Current = false;
      if (T.GA + T.IA < 6) {
        if (IFR_Report.ReasonTXT != '') {
          IFR_Report.ReasonTXT += ' & ';
        } else {
          IFR_Report.ReasonTXT +=
            '<b>' + iTrans('Less than 6 IFR approaches in last 6 months, IPC required') + ':</b> ';
        }
        IFR_Report.Reason +=
          "<p class='yellowbg'><b>" +
          iTrans('Not IFR Current') +
          ':</b> ' +
          iTrans('Less than 6 IFR approaches in last 6 months, IPC required') +
          '</p>';
        IFR_Report.Totals = T;
        IFR_Report.ReasonTXT += iTrans('Less than 6 IFR approaches in last 6 months, IPC required');
      }
    } else {
      IFR_Report.Current = true;
      if (!IFR_Current) {
        IFR_Report.ValidFrom = LOCALSTORAGE.FDT_Experience.IPC_Date;
        IFR_Report.ValidTo = IPC_Expires;
        IFR_Report.ValidState = Check_Date_ValidTo(IFR_Report.ValidTo, 14);
      }
      IFR_Report.Reason =
        "<b class='greenbg'>" +
        iTrans('IFR Current. Valid From') +
        ': ' +
        IFR_Report.ValidFrom +
        ' ' +
        iTrans('Current Until') +
        ': ' +
        IFR_Report.ValidTo +
        '</b>';
      if (IFR_Report.ValidState == 'Warning') {
        IFR_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('IFR Valid From') +
          ': ' +
          IFR_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          IFR_Report.ValidTo +
          '</b>';
      }
    }
  } else if (COUNTRY == 'CANADA') {
    //first day of 13th month don't need the 666
    //after that need 666 and totally invalid after 24 months from IPC date
    //666 is still valid if achieved during first 13 months
    //LOCALSTORAGE.FDT_Experience
    if (IPC_isValid) {
      if (Non_IPC_isValid) {
        //check which date is valid longer
        var IPC_Date = new Date(IPC_ValidTo + 'T00:00:00Z');
        var Non_IPC_Date = new Date(IFR_Report.ValidTo + 'T00:00:00Z');
        if (IPC_Date.getTime() > Non_IPC_Date.getTime()) {
          IFR_Report.ValidFrom = LOCALSTORAGE.FDT_Experience.IPC_Date;
          IFR_Report.ValidTo = IPC_ValidTo;
          IFR_Report.ValidState = Check_Date_ValidTo(IFR_Report.ValidTo, 14);
          IFR_Current = true;
        }
      } else {
        IFR_Report.ValidFrom = LOCALSTORAGE.FDT_Experience.IPC_Date;
        IFR_Report.ValidTo = IPC_ValidTo;
        IFR_Report.ValidState = Check_Date_ValidTo(IFR_Report.ValidTo, 14);
        IFR_Current = true;
      }
    }

    if (!IFR_Current) {
      IFR_Report.Current = false;
      if (IPC_Expires == null) {
        IFR_Report.Reason =
          "<p class='yellowbg'><b>" +
          iTrans('Not IFR Current') +
          ':</b> ' +
          iTrans('Instrument Proficiency Check has expired') +
          '</p>';
        IFR_Report.Totals = T;
        IFR_Report.ReasonTXT =
          '<b>' + iTrans('Not IFR Current') + ':</b> ' + iTrans('Instrument Proficiency Check has expired');
      } else {
        if (T.AI < 6) {
          IFR_Report.Reason =
            "<p class='yellowbg'><b>" +
            iTrans('Not IFR Current') +
            ':</b> ' +
            iTrans('Less than 6 hours IFR in last 180 days') +
            '</p>';
          IFR_Report.Totals = T;
          IFR_Report.ReasonTXT =
            '<b>' + iTrans('Not IFR Current') + ':</b> ' + iTrans('Less than 6 hours IFR in last 180 days');
        }
        if (T.GA + T.IA < 6) {
          if (IFR_Report.ReasonTXT != '') {
            IFR_Report.ReasonTXT += ' & ';
          } else {
            IFR_Report.ReasonTXT += '<b>' + iTrans('Not IFR Current') + ':</b> ';
          }
          IFR_Report.Reason +=
            "<p class='yellowbg'><b>" +
            iTrans('Not IFR Current') +
            ':</b> ' +
            iTrans('Less than 6 IFR approaches in last 180 days') +
            '</p>';
          IFR_Report.Totals = T;

          IFR_Report.ReasonTXT += iTrans('Less than 6 IFR approaches in last 180 days');
        }
      }
    } else {
      IFR_Report.Current = true;
      IFR_Report.Reason =
        "<b class='greenbg'>" +
        iTrans('IFR Current. Valid From') +
        ': ' +
        IFR_Report.ValidFrom +
        ' ' +
        iTrans('Current Until') +
        ': ' +
        IFR_Report.ValidTo +
        '</b>';
      if (IFR_Report.ValidState == 'Warning') {
        IFR_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('IFR Valid From') +
          ': ' +
          IFR_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          IFR_Report.ValidTo +
          '</b>';
      }
    }
  } else {
    //All other countries IFR currency logic
    if (!IFR_Current) {
      IFR_Report.Current = false;
      if (T.AI < 6) {
        IFR_Report.Reason =
          "<p class='yellowbg'><b>" +
          iTrans('Not IFR Current') +
          ':</b> ' +
          iTrans('Less than 6 hours IFR in last 180 days') +
          '</p>';
        IFR_Report.Totals = T;
        IFR_Report.ReasonTXT =
          '<b>' + iTrans('Not IFR Current') + ':</b> ' + iTrans('Less than 6 hours IFR in last 180 days');
      }
      if (T.GA + T.IA < 6) {
        if (IFR_Report.ReasonTXT != '') {
          IFR_Report.ReasonTXT += ' & ';
        } else {
          IFR_Report.ReasonTXT += '<b>' + iTrans('Not IFR Current') + ':</b> ';
        }
        IFR_Report.Reason +=
          "<p class='yellowbg'><b>" +
          iTrans('Not IFR Current') +
          ':</b> ' +
          iTrans('Less than 6 IFR approaches in last 180 days') +
          '</p>';
        IFR_Report.Totals = T;

        IFR_Report.ReasonTXT += iTrans('Less than 6 IFR approaches in last 180 days');
      }
    } else {
      IFR_Report.Current = true;
      IFR_Report.Reason =
        "<b class='greenbg'>" +
        iTrans('IFR Current. Valid From') +
        ': ' +
        IFR_Report.ValidFrom +
        ' ' +
        iTrans('Current Until') +
        ': ' +
        IFR_Report.ValidTo +
        '</b>';
      if (IFR_Report.ValidState == 'Warning') {
        IFR_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('IFR Valid From') +
          ': ' +
          IFR_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          IFR_Report.ValidTo +
          '</b>';
      }
    }
  }

  IFR_Report.Totals = T;
  if (!init && DUTYTIME != 'REPORT') {
    //$('#IFR_CURRENCY_TXT').html(IFR_Report.Reason);
    document.getElementById('IFR_CURRENCY_TXT').innerHTML = IFR_Report.Reason;
  }
  if (DUTYTIME == 'REPORT' || returnData) {
    return JSON.parse(JSON.stringify(IFR_Report));
  }
}

function ProcessNVG_Currency(r, init, adminOverride = null) {
  let CUR_ADMIN_FDT_DATA = ADMIN_FDT_DATA;
  if (adminOverride != null) {
    CUR_ADMIN_FDT_DATA = adminOverride;
  }
  NVG_Report = {
    Totals: null,
    Reason: '',
    ReasonTXT: '',
    Current: false,
    ValidFrom: '',
    ValidTo: '',
    ValidState: 'Violation',
  };
  if (r === false) {
    r = [];
  }
  //OPS is not called Events for Canadian NVIS tracking
  let T = {
    HRS: 0,
    OPS: 0,
    TO: 0,
    LD: 0,
    HOV: 0,
    DEP: 0,
    ARR: 0,
    TRAN: 0,
  };
  let H = {
    HRS: 0,
    OPS: 0,
    TO: 0,
    LD: 0,
    HOV: 0,
    DEP: 0,
    ARR: 0,
    TRAN: 0,
  };

  let NVIS_Expires = null;
  let NVIS_LastAnnual = new Date('2000-01-01T00:00:00Z');
  let NVIS_LastGnd = new Date('2000-01-01T00:00:00Z');
  let NVIS_isNew = false;
  let NVG_HeliOps = false;
  if (FDT_ADMIN_UPDATE) {
    if (CUR_ADMIN_FDT_DATA.CurUser.Experience != null) {
      if (COUNTRY == 'CANADA') {
        NVIS_Expires = this.Get_Date_ValidTo(CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date, 0, 12, false);
        NVIS_LastAnnual = new Date(CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date + 'T00:00:00Z');
        NVIS_LastGnd = new Date(CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Gnd_Date + 'T00:00:00Z');
        if (
          CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date == CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Initial_Date
        ) {
          NVIS_isNew = true;
        }
      }
      if (COUNTRY == 'UNITED STATES') {
        NVIS_Expires = this.Get_Date_ValidTo(CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date, 0, 7, -1);
      }
    } else {
      if (COUNTRY == 'CANADA') {
        NVIS_Expires = this.Get_Date_ValidTo('2000-01-01T00:00:00Z', 0, 12, false);
        NVIS_LastAnnual = new Date('2000-01-01T00:00:00Z');
        NVIS_LastGnd = new Date('2000-01-01T00:00:00Z');
        if (
          CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date == CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Initial_Date
        ) {
          NVIS_isNew = true;
        }
      }
      if (COUNTRY == 'UNITED STATES') {
        NVIS_Expires = '2000-01-01';
      }
    }
  } else {
    if (LOCALSTORAGE.FDT_Experience != undefined) {
      if (COUNTRY == 'CANADA') {
        NVIS_Expires = this.Get_Date_ValidTo(LOCALSTORAGE.FDT_Experience.NVIS_Date, 0, 12, false);
        NVIS_LastAnnual = new Date(LOCALSTORAGE.FDT_Experience.NVIS_Date + 'T00:00:00Z');
        NVIS_LastGnd = new Date(LOCALSTORAGE.FDT_Experience.NVIS_Gnd_Date + 'T00:00:00Z');
        if (LOCALSTORAGE.FDT_Experience.NVIS_Date == LOCALSTORAGE.FDT_Experience.NVIS_Initial_Date) {
          NVIS_isNew = true;
        }
      }
      if (COUNTRY == 'UNITED STATES') {
        NVIS_Expires = this.Get_Date_ValidTo(LOCALSTORAGE.FDT_Experience.NVIS_Date, 0, 7, -1);
      }
    }
  }

  let NVG_HadLapse = false;
  let NVG_Checked = false;
  let NVG_PaxOnlyChecked = false;
  let NVG_PaxOnly = false;
  let NVG_History = [];
  let ShiftDate = null;
  let NVG_Over120 = true;
  for (let i in r) {
    let R = r[i];
    T.HRS += parseFloat(ChecKNaN_Item(R.NVG_HRS));
    T.OPS += parseInt(ChecKNaN_Item(R.NVG_OPS));
    T.TO += parseInt(ChecKNaN_Item(R.NVG_TO));
    T.LD += parseInt(ChecKNaN_Item(R.NVG_LD));
    T.HOV += parseInt(ChecKNaN_Item(R.NVG_HOV));
    T.DEP += parseInt(ChecKNaN_Item(R.NVG_DEP));
    T.ARR += parseInt(ChecKNaN_Item(R.NVG_ARR));
    T.TRAN += parseInt(ChecKNaN_Item(R.NVG_TRAN));
    if (R.Class == 'Helicopter') {
      H.HRS += parseFloat(ChecKNaN_Item(R.NVG_HRS));
      H.OPS += parseInt(ChecKNaN_Item(R.NVG_OPS));
      H.TO += parseInt(ChecKNaN_Item(R.NVG_TO));
      H.LD += parseInt(ChecKNaN_Item(R.NVG_LD));
      H.HOV += parseInt(ChecKNaN_Item(R.NVG_HOV));
      H.DEP += parseInt(ChecKNaN_Item(R.NVG_DEP));
      H.ARR += parseInt(ChecKNaN_Item(R.NVG_ARR));
      H.TRAN += parseInt(ChecKNaN_Item(R.NVG_TRAN));
      NVG_HeliOps = true;
    }

    if (COUNTRY == 'CANADA') {
      if (R.NVG_OPS != 0) {
        NVG_History.push({ OPS: R.NVG_OPS, Date: R.Date });
      }
      if (T.OPS >= 3) {
        if (!NVG_Checked) {
          //check for most recent 90 day currency
          NVG_Report.ValidFrom = R.Date;
          NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, 90);
          if (NVG_Report.ValidTo != null) {
            NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
            if (NVG_Report.ValidState == 'Warning') {
              NVG_Report.ReasonTXT =
                '<b>' + iTrans('NVIS Passenger Carrying Lapses On') + ':</b> ' + NVG_Report.ValidTo;
            }
            NVG_Report.Current = true;
            NVG_Over120 = false;
          } else {
            NVG_Report.Current = false;
          }
          NVG_Checked = true;
        }
        if (!NVG_PaxOnlyChecked && !NVG_Report.Current) {
          //check for most recent 90 day currency
          NVG_Report.ValidFrom = R.Date;
          NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, 120);
          if (NVG_Report.ValidTo != null) {
            NVG_Report.ValidState = 'Warning';
            NVG_Report.Current = true;
            NVG_PaxOnly = true;
            NVG_Over120 = false;
          } else {
            NVG_Report.Current = false;
          }
          NVG_Checked = true;
          NVG_PaxOnlyChecked = true;
        }
        //Shift the history array
        if (NVG_History.length > 0) {
          let first = NVG_History.shift();
          T.OPS -= first.OPS;
          if (ShiftDate != null && NVG_HadLapse == false) {
            //within 90 days of pervious R.Date or NVG_HadLapse is true
            let Rdate = new Date(R.Date + 'T00:00:00Z');
            let diffDays = Math.ceil(Math.abs(ShiftDate - Rdate) / (1000 * 60 * 60 * 24));
            if (diffDays > 90) {
              NVG_HadLapse = true;
            }
          }
          ShiftDate = new Date(first.Date + 'T00:00:00Z');
          let daysSinceAnnual = Math.ceil(Math.abs(NVIS_LastAnnual - ShiftDate) / (1000 * 60 * 60 * 24));
          if (daysSinceAnnual <= 90 && NVG_HadLapse == false) {
            //within 90 days since annual training don't bother checking if no lapse just break and show currency
            break;
          }
        }
      }
    } else {
      if (NVG_Report.ValidFrom == '') {
        if (COUNTRY == 'KENYA') {
          if (T.TO >= 3 && T.LD >= 3 && NVG_Report.Current == false) {
            NVG_Report.ValidFrom = R.Date;
            NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, 90);
            if (NVG_Report.ValidTo != null) {
              NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
              NVG_Report.Current = true;
            } else {
              NVG_Report.Current = false;
            }
          }
        } else {
          //USA and all other countries
          //Fixed Wing NVG
          if (
            T.OPS >= 3 &&
            T.TO >= 3 &&
            T.LD >= 3 &&
            T.HOV >= 3 &&
            T.DEP >= 3 &&
            T.ARR >= 3 &&
            T.TRAN >= 3 &&
            NVG_HeliOps == false &&
            NVG_Report.Current == false
          ) {
            NVG_Report.ValidFrom = R.Date;
            NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, this.Last2CalendarMonths);
            if (NVG_Report.ValidTo != null) {
              NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
              NVG_Report.Current = true;
            } else {
              NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, this.Last4CalendarMonths);
              if (NVG_Report.ValidTo != null) {
                NVG_PaxOnly = true;
                NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
                NVG_Report.Current = true;
              } else {
                NVG_Report.Current = false;
              }
            }
          }
          //Helicopter NVG
          if (
            H.OPS >= 6 &&
            H.TO >= 3 &&
            H.LD >= 3 &&
            H.HOV >= 3 &&
            H.DEP >= 3 &&
            H.ARR >= 3 &&
            H.TRAN >= 3 &&
            NVG_HeliOps == true &&
            NVG_Report.Current == false
          ) {
            NVG_Report.ValidFrom = R.Date;
            NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, this.Last2CalendarMonths);
            if (NVG_Report.ValidTo != null) {
              NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
              NVG_Report.Current = true;
            } else {
              NVG_Report.ValidTo = this.Get_Date_ValidTo(R.Date, this.Last4CalendarMonths);
              if (NVG_Report.ValidTo != null) {
                NVG_PaxOnly = true;
                NVG_Report.ValidState = 'Warning';
                NVG_Report.Current = true;
              } else {
                NVG_Report.Current = false;
              }
            }
          }
        }
      }
    }
  }

  if (COUNTRY == 'CANADA') {
    let AnnualAge = Math.ceil(Math.abs(NVIS_LastAnnual - new Date()) / (1000 * 60 * 60 * 24));
    let AnnualGndAge = Math.ceil(Math.abs(NVIS_LastGnd - new Date()) / (1000 * 60 * 60 * 24));

    if (NVG_Report.Current == false) {
      if (AnnualAge <= 90) {
        //within 90 days since annual training user is NVG good regardless
        if (FDT_ADMIN_UPDATE) {
          if (CUR_ADMIN_FDT_DATA.CurUser.Experience != null) {
            NVG_Report.ValidFrom = CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date;
          } else {
            NVG_Report.ValidFrom = '2000-01-01';
          }
        } else {
          NVG_Report.ValidFrom = LOCALSTORAGE.FDT_Experience.NVIS_Date;
        }
        NVG_Report.ValidTo = this.Get_Date_ValidTo(NVG_Report.ValidFrom, 120);
        NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
        NVG_Report.Current = true;
        NVG_PaxOnly = false;
      }
      if (AnnualAge > 90 && AnnualAge <= 120) {
        //within 90 days since annual training user is NVG good regardless
        if (FDT_ADMIN_UPDATE) {
          if (CUR_ADMIN_FDT_DATA.CurUser.Experience != null) {
            NVG_Report.ValidFrom = CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date;
          } else {
            NVG_Report.ValidFrom = '2000-01-01';
          }
        } else {
          NVG_Report.ValidFrom = LOCALSTORAGE.FDT_Experience.NVIS_Date;
        }
        NVG_Report.ValidTo = this.Get_Date_ValidTo(NVG_Report.ValidFrom, 120);
        NVG_Report.ValidState = 'Warning';
        NVG_Report.Current = true;
        NVG_PaxOnly = true;
      }
    }
    if (NVG_Report.Current == false) {
      if (NVG_Over120) {
        NVG_Report.Reason =
          "<p class='yellowbg'><b>" +
          iTrans('Not NVIS Current') +
          ':</b> ' +
          iTrans('NVIS Competency flight required') +
          '</p>';
        NVG_Report.Totals = T;
        NVG_Report.ReasonTXT =
          '<b>' + iTrans('Not NVIS Current') + ':</b> ' + iTrans('NVIS Competency flight required');
      } else {
        NVG_Report.Reason =
          "<p class='yellowbg'><b>" +
          iTrans('Not NVIS Current') +
          ':</b> ' +
          iTrans('Less than 3 NVIS Events in last 120 days') +
          '</p>';
        NVG_Report.Totals = T;
        NVG_Report.ReasonTXT =
          '<b>' + iTrans('Not NVIS Current') + ':</b> ' + iTrans('Less than 3 NVIS Events in last 120 days');
      }
    } else {
      NVG_Report.Reason =
        "<b class='greenbg'>" +
        iTrans('NVIS Current. Valid From') +
        ': ' +
        NVG_Report.ValidFrom +
        ' ' +
        iTrans('Current Until') +
        ': ' +
        NVG_Report.ValidTo +
        '</b>';
      if (NVG_Report.ValidState == 'Warning' && NVG_Report.ReasonTXT !== '') {
        NVG_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('NVIS Valid From') +
          ': ' +
          NVG_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          NVG_Report.ValidTo +
          iTrans('Passenger Carrying Lapses Soon') +
          '</b>';
      }
      if (NVG_Report.ValidState == 'Warning' && NVG_Report.ReasonTXT == '') {
        NVG_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('NVIS Valid From') +
          ': ' +
          NVG_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          NVG_Report.ValidTo +
          (NVG_PaxOnly ? ' (' + iTrans('No Passengers or Essential Crew') + ')' : '') +
          '</b>';
        NVG_Report.ReasonTXT =
          '<b>' + iTrans('Past 90 Under 120 days') + ':</b> ' + iTrans('No Passengers or Essential Crew');
      }
      NVG_Report.Totals = T;

      if (AnnualAge >= 365 && NVIS_isNew) {
        NVG_Report.Current = false;
        NVIS_LastAnnual.setFullYear(NVIS_LastAnnual.getFullYear() + 1);
        NVG_Report.ValidTo = NVIS_LastAnnual.toISOString().substr(0, 10);
        NVG_Report.Reason =
          "<p class='yellowbg'><b>" +
          iTrans('Not NVIS Current') +
          ':</b> ' +
          iTrans('Newly trained. Annual training required.') +
          '</p>';
        NVG_Report.Totals = T;
        NVG_Report.ReasonTXT =
          '<b>' +
          iTrans('Not NVIS Current') +
          ':</b> ' +
          iTrans('Newly trained. Annual NVIS Competency Flight Required.');
      }
      if (AnnualAge < 365 && AnnualAge > 335 && NVIS_isNew) {
        NVIS_LastAnnual.setFullYear(NVIS_LastAnnual.getFullYear() + 1);
        let curValidTo = new Date(NVG_Report.ValidTo + 'T00:00:00Z');
        if (curValidTo.getTime() > NVIS_LastAnnual.getTime()) {
          NVG_Report.ValidState = 'Warning';
          NVG_Report.ValidTo = NVIS_LastAnnual.toISOString().substr(0, 10);
          NVG_Report.Reason =
            "<p class='yellowbg'><b>" +
            iTrans('Newly trained') +
            ':</b> ' +
            iTrans('Annual training required.') +
            '</p>';
          NVG_Report.Totals = T;
          NVG_Report.ReasonTXT =
            '<b>' + iTrans('Newly trained') + ':</b> ' + iTrans('Newly trained. Annual training required.');
        }
      }
    }
  } else if (COUNTRY == 'KENYA') {
    if (NVG_Report.Current == false) {
      NVG_Report.Reason =
        "<p class='yellowbg'><b>" +
        iTrans('Not NVG Current') +
        ':</b> ' +
        iTrans('Less than 3 NVG Take-off and Landings in last 90 days') +
        '</p>';
      NVG_Report.Totals = T;
      NVG_Report.ReasonTXT =
        '<b>' + iTrans('Not NVG Current') + ':</b> ' + iTrans('Less than 3 NVG Take-off and Landings in last 90 days');
    } else {
      NVG_Report.Reason =
        "<b class='greenbg'>" +
        iTrans('NVG Current. Valid From') +
        ': ' +
        NVG_Report.ValidFrom +
        ' ' +
        iTrans('Current Until') +
        ': ' +
        NVG_Report.ValidTo +
        '</b>';
      if (NVG_Report.ValidState == 'Warning') {
        NVG_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('NVG Valid From') +
          ': ' +
          NVG_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          NVG_Report.ValidTo +
          '</b>';
      }
      NVG_Report.Totals = T;
    }
  } else {
    //USA and all other IFR countries
    if (NVG_Report.Current == false) {
      //Chec if NVG check ride done with last 2 calendar months
      if (NVG_Report.ValidFrom != '') {
        let reportFrom = new Date(NVG_Report.ValidFrom + 'T00:00:00Z');
        reportFrom.setDate(1);
        reportFrom.setMonth(reportFrom.getMonth() + 3);
        reportFrom.setDate(reportFrom.getDate() - 1);
        let checkValidTo = this.Get_Date_ValidTo(reportFrom.toISOString().substr(0, 10), 90);
        if (checkValidTo != null) {
          NVG_Report.ValidTo = checkValidTo;
          if (FDT_ADMIN_UPDATE) {
            if (CUR_ADMIN_FDT_DATA.CurUser.Experience != null) {
              NVG_Report.ValidFrom = CUR_ADMIN_FDT_DATA.CurUser.Experience.NVIS_Date;
            } else {
              NVG_Report.ValidFrom = '2000-01-01';
            }
          } else {
            NVG_Report.ValidFrom = LOCALSTORAGE.FDT_Experience.NVIS_Date;
          }
          NVG_Report.ValidState = this.Check_Date_ValidTo(NVG_Report.ValidTo, 14);
          NVG_Report.Current = true;
          NVG_PaxOnly = false;
        }
      }
    }
    if (NVG_Report.Current == false) {
      let TXT = '';
      if (!NVG_HeliOps) {
        if (T.OPS < 3) {
          TXT = '<br />' + iTrans('Less than 3 operations in 4 calendar months');
        }
        if (T.TO < 3) {
          TXT += '<br />' + iTrans('Less than 3 takeoffs in 4 calendar months');
        }
        if (T.LD < 3) {
          TXT += '<br />' + iTrans('Less than 3 landings in 4 calendar months');
        }
        if (T.HOV < 3) {
          TXT += '<br />' + iTrans('Less than 3 hovering tasks in 4 calendar months');
        }
        if (T.DEP < 3) {
          TXT += '<br />' + iTrans('Less than 3 area departure tasks in 4 calendar months');
        }
        if (T.ARR < 3) {
          TXT += '<br />' + iTrans('Less than 3 area arrival tasks in 4 calendar months');
        }
        if (T.TRAN < 3) {
          TXT += '<br />' + iTrans('Less than 3 transitions in 4 calendar months');
        }
      } else {
        if (H.OPS < 6) {
          TXT = '<br />' + iTrans('Less than 6 operations in 4 calendar months');
        }
        if (H.TO < 3) {
          TXT += '<br />' + iTrans('Less than 3 takeoffs in 4 calendar months');
        }
        if (H.LD < 3) {
          TXT += '<br />' + iTrans('Less than 3 landings in 4 calendar months');
        }
        if (H.HOV < 3) {
          TXT += '<br />' + iTrans('Less than 3 hovering tasks in 4 calendar months');
        }
        if (H.DEP < 3) {
          TXT += '<br />' + iTrans('Less than 3 area departure tasks in 4 calendar months');
        }
        if (H.ARR < 3) {
          TXT += '<br />' + iTrans('Less than 3 area arrival tasks in 4 calendar months');
        }
        if (H.TRAN < 3) {
          TXT += '<br />' + iTrans('Less than 3 transitions in 4 calendar months');
        }
      }
      NVG_Report.ReasonTXT = '<b>' + iTrans('Not NVG Current') + '</b>: ' + TXT;
      NVG_Report.Reason = "<p class='yellowbg'><b>" + iTrans('Not NVG Current') + '</b>' + TXT + '</p>';
      NVG_Report.Totals = T;
    } else {
      NVG_Report.Reason =
        "<b class='greenbg'>" +
        iTrans('NVG Current. Valid From') +
        ': ' +
        NVG_Report.ValidFrom +
        ' ' +
        iTrans('Current Until') +
        ': ' +
        NVG_Report.ValidTo +
        '</b>';
      if (NVG_Report.ValidState == 'Warning') {
        NVG_Report.Reason =
          "<b class='yellowbg'>" +
          iTrans('NVG Current. Valid From') +
          ': ' +
          NVG_Report.ValidFrom +
          ' ' +
          iTrans('Current Until') +
          ': ' +
          NVG_Report.ValidTo +
          '</b>: ' +
          (NVG_PaxOnly ? +' (' + iTrans('Current Between 2 and 4 calendar months. No Passengers Allowed') + ')' : '');
      }
      NVG_Report.Totals = T;
    }
  }
  if (!init && DUTYTIME != 'REPORT') {
    //$('#NVG_CURRENCY_TXT').html(NVG_Report.Reason);
    document.getElementById('NVG_CURRENCY_TXT').innerHTML = NVG_Report.Reason;
  }
  if (DUTYTIME == 'REPORT') {
    return JSON.parse(JSON.stringify(NVG_Report));
  }
}

function Get_Date_ValidTo(recDate, NumDays = 90, NumMonths = null, firstDay = false) {
  let DATE = new Date(recDate + 'T00:00:00Z');
  if (NumMonths == null) {
    DATE.setUTCDate(DATE.getUTCDate() + NumDays);
  } else {
    if (firstDay === true) {
      DATE.setUTCMonth(DATE.getUTCMonth() + NumMonths);
      DATE.setUTCDate(1);
    } else if (firstDay < 0) {
      DATE.setUTCDate(1);
      DATE.setUTCMonth(DATE.getUTCMonth() + NumMonths);
      DATE.setUTCDate(DATE.getUTCDate() - 1);
    } else {
      DATE.setUTCMonth(DATE.getUTCMonth() + NumMonths);
    }
  }
  let Syear = DATE.getUTCFullYear();
  let SMonth = DATE.getUTCMonth() + 1;
  let Sday = DATE.getUTCDate();
  let now = new Date();
  now.setUTCHours(0);
  now.setUTCMinutes(0);
  now.setUTCMilliseconds(0);
  if (now.getTime() > DATE.getTime()) {
    return null;
  }
  if (SMonth < 10) {
    SMonth = '0' + SMonth;
  }
  if (Sday < 10) {
    Sday = '0' + Sday;
  }
  let Sdate = Syear + '-' + SMonth + '-' + Sday;
  return Sdate;
}

function Check_Date_ValidTo(ValidTo, WarnWithinDays = 14) {
  var timestamp = new Date().getTime() + WarnWithinDays * 24 * 60 * 60 * 1000;
  var ValidToDate = new Date(ValidTo + 'T00:00:00Z').getTime();
  if (timestamp > ValidToDate) {
    return 'Warning';
  } else {
    return 'Good';
  }
}

/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 *
 * @param Rule
 * @param init
 */
function GetCurrency_RecData(Rule, init = false) {
  VueBridge.deprecationUtils.markDeprecated('menu-Duty-SHARED.GetCurrency_RecData', '2024-06-26', 'bldarosa');
  var DATE = new Date();
  var NumDays = 365; //default 1 yeardays back to get

  /* Get all days because we want to know what aircraft they were current on in the past year
   if (Rule == 95 || Rule == 135 || Rule == 131) NumDays = 183; //USA
   else if (Rule == 702 || Rule == 703 || Rule == 704 || Rule == 705 || Rule == 604) NumDays = 90; //Canada
   else if (Rule == 3) NumDays = 120; //Indonesia
   */

  DATE.setDate(DATE.getDate() - NumDays);

  var Syear = DATE.getFullYear();
  var SMonth = DATE.getMonth() + 1;
  var Sday = DATE.getDate();
  if (SMonth < 10) {
    SMonth = '0' + SMonth;
  }
  if (Sday < 10) {
    Sday = '0' + Sday;
  }
  var Sdate = Syear + '-' + SMonth + '-' + Sday;

  cirroDB.query(
    'FDT_RecsV3',
    "Date >= ? AND DELETED = 0 AND IDENT != 'No Ident' ORDER BY Date DESC",
    [Sdate],
    function (Recs) {
      //console.log(Recs);
      try {
        AC_CURRENCY = CheckAC_Currency(Recs, Rule);
        //console.log(AC_CURRENCY);
        if (!init) {
          $('#ACTYPE_CURRENCY_TXT').html(getAC_CurrencyHTML(AC_CURRENCY));
          //document.getElementById('ACTYPE_CURRENCY_TXT').innerHTML = getAC_CurrencyHTML(AC_CURRENCY);
        }
      } catch (e) {
        console.log(e);
      }
    }
  );
}

function getAC_CurrencyHTML(CR) {
  var FloatCurOn = false;
  var NightCurOn = false;
  var DayCurOn = false;
  if (DUTYTIME == 'REPORT') {
    if (REPORT_FloatCur == 1) {
      FloatCurOn = true;
    }
    if (REPORT_NightCur == 1) {
      NightCurOn = true;
    }
    if (REPORT_DayCur == 1) {
      DayCurOn = true;
    }
  } else {
    if (FDT_ADMIN_UPDATE) {
      if (ADMIN_FDT_DATA.CurUser.FDT_FloatRating == 1) {
        FloatCurOn = true;
      }
      if (ADMIN_FDT_DATA.CurUser.FDT_DayCur == 1) {
        DayCurOn = true;
      }
      if (ADMIN_FDT_DATA.CurUser.FDT_NightCur == 1) {
        NightCurOn = true;
      }
    } else {
      if (USER_RIGHTS.FDT_FloatRating == 1) {
        FloatCurOn = true;
      }
      if (USER_RIGHTS.FDT_DayCur == 1) {
        DayCurOn = true;
      }
      if (USER_RIGHTS.FDT_NightCur == 1) {
        NightCurOn = true;
      }
    }
  }
  var html = '';
  if (Object.keys(CR).length == 0) {
    html = '<h3>' + iTrans('No Aircraft Experience Recorded for Type Currency') + '</h3>';
  } else {
    html +=
      '<table class="limit-table"><thead><tr><th>' +
      iTrans('Aircraft') +
      '</th><th>' +
      iTrans('Currency') +
      '</th><th>' +
      iTrans('Since') +
      '</th><th>' +
      iTrans('Valid Until') +
      '</th></tr></thead><tbody>';
    for (var c in CR) {
      var C = CR[c];
      if (c == 'Rotorcraft helicopter' && COUNTRY != 'UNITED STATES') {
        continue;
      }
      var crewT = '<b>' + c + '</b>';
      var dayT = '';
      var nightT = '';
      var floatT = '';
      var rgrT = '';
      var AcValid = 'greenbg';
      if ((C.Float_ValidToState == 'Warning' && C.Float) || C.ValidToState == 'Warning') {
        AcValid = 'yellowbg';
      }
      if (C.Float_ValidTo == null && C.Float) {
        AcValid = 'redbg';
      }
      if (C.ValidTo == null) {
        AcValid = 'redbg';
      }

      if (C.ValidToState != 'Not Used') {
        var CurValid = 'greenbg';
        if (C.ValidToState == 'Warning') {
          CurValid = 'yellowbg';
        }
        if (C.ValidToState == 'Violation') {
          CurValid = 'redbg';
        }
        if (C.ValidToState == 'Violation') {
          html +=
            '<tr><td class="' +
            CurValid +
            '" style="text-align:left;">' +
            crewT +
            '</td><td class="' +
            CurValid +
            '">' +
            iTrans('Flight Crew') +
            '</td><td class="' +
            CurValid +
            '">N/A</td><td class="' +
            CurValid +
            '">' +
            iTrans('Not Current Since') +
            ' ' +
            C.ValidTo +
            '</td></tr>';
        } else {
          html +=
            '<tr><td class="' +
            CurValid +
            '" style="text-align:left;">' +
            crewT +
            '</td><td class="' +
            CurValid +
            '">' +
            iTrans('Flight Crew') +
            '</td><td class="' +
            CurValid +
            '">' +
            C.ValidFrom +
            '</td><td class="' +
            CurValid +
            '">' +
            C.ValidTo +
            '</td></tr>';
        }
      } else {
        dayT = crewT;
      }

      if (DayCurOn && C.ValidToState_Day != 'Not Used') {
        var CurValid_Day = 'greenbg';
        if (C.ValidToState_Day == 'Warning') {
          CurValid_Day = 'yellowbg';
        }
        if (C.ValidToState_Day == 'Violation') {
          CurValid_Day = 'redbg';
        }
        if (C.ValidToState_Day == 'Violation') {
          html +=
            '<tr><td class="' +
            CurValid_Day +
            '" style="text-align:left;">' +
            dayT +
            '</td><td class="' +
            CurValid_Day +
            '">' +
            iTrans('Pax Day') +
            '</td><td class="' +
            CurValid_Day +
            '">N/A</td><td class="' +
            CurValid_Day +
            '">' +
            iTrans('Not Current Since') +
            ' ' +
            C.ValidTo_Day +
            '</td></tr>';
        } else {
          html +=
            '<tr><td class="' +
            CurValid_Day +
            '" style="text-align:left;">' +
            dayT +
            '</td><td class="' +
            CurValid_Day +
            '">' +
            iTrans('Pax Day') +
            '</td><td class="' +
            CurValid_Day +
            '">' +
            C.ValidFrom_Day +
            '</td><td class="' +
            CurValid_Day +
            '">' +
            C.ValidTo_Day +
            '</td></tr>';
        }
      } else {
        nightT = dayT;
      }
      if (NightCurOn && C.ValidToState_Night != 'Not Used') {
        var CurValid_Night = 'greenbg';
        if (C.ValidToState_Night == 'Warning') {
          CurValid_Night = 'yellowbg';
        }
        if (C.ValidToState_Night == 'Violation') {
          CurValid_Night = 'redbg';
        }
        if (C.ValidToState_Night == 'Violation') {
          html +=
            '<tr><td class="' +
            CurValid_Night +
            '" style="text-align:left;">' +
            nightT +
            '</td><td class="' +
            CurValid_Night +
            '">' +
            iTrans('Pax Night') +
            '</td><td class="' +
            CurValid_Night +
            '">N/A</td><td class="' +
            CurValid_Night +
            '">' +
            iTrans('Not Current Since') +
            ' ' +
            C.ValidTo_Night +
            '</td></tr>';
        } else {
          html +=
            '<tr><td class="' +
            CurValid_Night +
            '" style="text-align:left;">' +
            nightT +
            '</td><td class="' +
            CurValid_Night +
            '">' +
            iTrans('Pax Night') +
            '</td><td class="' +
            CurValid_Night +
            '">' +
            C.ValidFrom_Night +
            '</td><td class="' +
            CurValid_Night +
            '">' +
            C.ValidTo_Night +
            '</td></tr>';
        }
      } else {
        floatT = nightT;
      }

      if (C.Float == true && FloatCurOn) {
        var FloatValid = 'greenbg';
        if (C.Float_ValidToState == 'Warning') {
          FloatValid = 'yellowbg';
        }
        if (C.Float_ValidToState == 'Violation') {
          FloatValid = 'redbg';
        }
        if (C.Float_ValidToState == 'Violation') {
          html +=
            '<tr><td class="' +
            FloatValid +
            '" style="text-align:left;">' +
            floatT +
            '</td><td class="' +
            FloatValid +
            '">' +
            iTrans('Float') +
            '</td><td class="' +
            FloatValid +
            '">N/A</td><td class="' +
            FloatValid +
            '">' +
            (C.Float_ValidTo == undefined ? '' : iTrans('Not Current Since')) +
            ' ' +
            (C.Float_ValidTo == undefined ? iTrans('Never Current') : C.Float_ValidTo) +
            '</td></tr>';
        } else {
          html +=
            '<tr><td class="' +
            FloatValid +
            '" style="text-align:left;">' +
            floatT +
            '</td><td class="' +
            FloatValid +
            '">' +
            iTrans('Float') +
            '</td><td class="' +
            FloatValid +
            '">' +
            C.Float_ValidFrom +
            '</td><td class="' +
            FloatValid +
            '">' +
            C.Float_ValidTo +
            '</td></tr>';
        }
      } else {
        rgrT = floatT;
      }

      if (C.RGR) {
        if (C.RGR_ValidToState == 'Violation') {
          html +=
            '<tr class="redbg"><td style="text-align:left;">' +
            rgrT +
            '</td><td>RGR</td><td>N/A</td><td>' +
            iTrans('Not Current Since') +
            ' ' +
            C.RGR_ValidTo +
            '</td></tr>';
        } else {
          html +=
            '<tr><td style="text-align:left;">' +
            rgrT +
            '</td><td>RGR</td><td>' +
            C.RGR_ValidFrom +
            '</td><td>' +
            C.RGR_ValidTo +
            '</td></tr>';
        }
      }
      html += '<tr style="height:3px;"></tr>';
    }
    html += '</tbody></table>';
  }
  return html;
}

var FDT_AC_TYPE_DATA = [];

function CheckAC_Currency(data, Rule) {
  var List_TypeKey = {};
  var FloatCurOn = false;
  if (DUTYTIME == 'REPORT') {
    if (REPORT_FloatCur == 1) {
      FloatCurOn = true;
    }
  } else {
    if (FDT_ADMIN_UPDATE) {
      if (ADMIN_FDT_DATA.CurUser.FDT_FloatRating == 1) {
        FloatCurOn = true;
      }
    } else {
      if (USER_RIGHTS.FDT_FloatRating == 1) {
        FloatCurOn = true;
      }
    }
  }
  var RecDate = '';
  var RecDateCount = 0;
  for (var a in FDT_AC_TYPE_DATA) {
    var A = FDT_AC_TYPE_DATA[a];
    if (A.Date != RecDate) {
      RecDateCount++;
      RecDate = A.Date;
    }
    if (A.AcTypes == 'Bell 206L4T') {
      A.List_Type = 'Bell 206L4T Series';
    } else if (A.List_Type == 'Bell 206 Series' || A.List_Type == 'Bell 206L Series') {
      A.List_Type = 'Bell 206/206L Series';
    } else if (
      (A.AcType == 'King Air 300' || A.AcType == 'King Air B300' || A.AcType == 'King Air B200') &&
      COUNTRY == 'UNITED STATES'
    ) {
      A.List_Type = A.AcTypes + ' Series';
    }

    List_TypeKey[A.List_Type] = A;
  }
  var CR = {};
  for (var i in data) {
    var I = data[i];
    if (I.AcType == 'Bell 206L4T') {
      I.List_Type = 'Bell 206L4T Series';
    } else if (I.List_Type == 'Bell 206 Series' || I.List_Type == 'Bell 206L Series') {
      I.List_Type = 'Bell 206/206L Series';
    } else if (
      (I.AcType == 'King Air 300' || I.AcType == 'King Air B300' || I.AcType == 'King Air B200') &&
      COUNTRY == 'UNITED STATES'
    ) {
      I.List_Type = I.AcType + ' Series';
    }

    var IK = List_TypeKey[I.List_Type];
    if (IK == undefined) {
      continue;
    }

    var MTOW12500 = IK.MTOW12500 == 1 ? true : false;

    if (IK.Class == 'Helicopter') {
      IK.Class = 'Rotorcraft';
      IK.Class2 = 'helicopter';
    }

    if (COUNTRY == 'CANADA' || COUNTRY == 'KENYA' || COUNTRY == 'UNITED STATES') {
      //Must have currency on Category and Class

      if (CR[IK.Class + ' ' + IK.Class2] == undefined) {
        var RGR = false;
        if (
          IK.List_Type == 'DHC-6 Series' &&
          (Rule == 702 || Rule == 703 || Rule == 704 || Rule == 705 || Rule == 604)
        ) {
          RGR = true;
        }

        CR[IK.Class + ' ' + IK.Class2] = {
          TypeRatingReq: 0,
          FTT: I.FTT,
          DR: I.DR,
          PIC: I.PIC,
          SIC: I.SIC,
          Float_TO: I.FTO,
          Float_LD: I.FLD,
          Float: FloatCurOn,
          Float_ValidToState: 'Violation',
          RGR: RGR,
          RGR_TO: I.RGR,
          RGR_ValidFrom: null,
          RGR_ValidTo: null,
          RGR_ValidToState: 'Violation',
          Takeoffs: I.DAT + I.NT,
          Takeoffs_Day: I.DAT + I.NT,
          Takeoffs_Night: I.NT,
          Landings: I.DL + I.NL,
          Landings_Day: I.DL + I.NL,
          Landings_Night: I.NL,
          Category: IK.Class,
          Class: IK.Class2,
          ValidFrom: null,
          ValidTo: null,
          ValidToState: 'Violoation',
          ValidFrom_Day: null,
          ValidTo_Day: null,
          ValidToState_Day: 'Violation',
          ValidFrom_Night: null,
          ValidTo_Night: null,
          ValidToState_Night: 'Violation',
        };
      } else {
        //For BARS Custom Rule
        if (RecDateCount < 90) {
          CR[IK.Class + ' ' + IK.Class2].FTT += I.FTT;
          CR[IK.Class + ' ' + IK.Class2].DR += I.DR;
          CR[IK.Class + ' ' + IK.Class2].PIC += I.PIC;
          CR[IK.Class + ' ' + IK.Class2].SIC += I.SIC;
        }

        CR[IK.Class + ' ' + IK.Class2].RGR_TO += I.RGR;
        CR[IK.Class + ' ' + IK.Class2].Float_TO += I.FTO;
        CR[IK.Class + ' ' + IK.Class2].Float_LD += I.FLD;

        CR[IK.Class + ' ' + IK.Class2].Takeoffs += I.DAT + I.NT;
        CR[IK.Class + ' ' + IK.Class2].Takeoffs_Day += I.DAT + I.NT;
        CR[IK.Class + ' ' + IK.Class2].Takeoffs_Night += I.NT;
        CR[IK.Class + ' ' + IK.Class2].Landings += I.DL + I.NL;
        CR[IK.Class + ' ' + IK.Class2].Landings_Day += I.DL + I.NL;
        CR[IK.Class + ' ' + IK.Class2].Landings_Night += I.NL;
      }

      if (
        (Rule != '604' &&
          Rule != '702' &&
          Rule != '91' &&
          Rule != '131' &&
          Rule != '133' &&
          Rule != '135' &&
          Rule != '137') ||
        (IK.Class == 'Rotorcraft' && COUNTRY == 'UNITED STATES') ||
        (IK.Class == 'Aeroplane' && COUNTRY == 'UNITED STATES')
      ) {
        //703.88 [Flight Crew]

        if (
          CR[IK.Class + ' ' + IK.Class2].Takeoffs >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].Landings >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].ValidFrom == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].ValidFrom = I.Date;
          CR[IK.Class + ' ' + IK.Class2].ValidTo = GetCurrency_ValidTo(I.Date);
          CR[IK.Class + ' ' + IK.Class2].ValidToState = CheckState_ValidTo(CR[IK.Class + ' ' + IK.Class2].ValidTo);
        }
      } else {
        CR[IK.Class + ' ' + IK.Class2].ValidToState = 'Not Used';
      }

      if (CR[IK.Class + ' ' + IK.Class2].RGR) {
        if (CR[IK.Class + ' ' + IK.Class2].RGR_TO >= 3 && CR[IK.Class + ' ' + IK.Class2].RGR_ValidFrom == null) {
          CR[IK.Class + ' ' + IK.Class2].RGR_ValidFrom = I.Date;
          CR[IK.Class + ' ' + IK.Class2].RGR_ValidTo = GetCurrency_ValidTo(I.Date);
          CR[IK.Class + ' ' + IK.Class2].RGR_ValidToState = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].RGR_ValidTo
          );
        }
      }

      //Day and Night passenger Currency
      if (Rule != '91' && Rule != '131' && Rule != '133' && Rule != '135' && Rule != '137') {
        //For Canada and everyone that isnt USA
        if (
          CR[IK.Class + ' ' + IK.Class2].Takeoffs_Day >= 5 &&
          CR[IK.Class + ' ' + IK.Class2].Landings_Day >= 5 &&
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Day == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Day = I.Date;
          CR[IK.Class + ' ' + IK.Class2].ValidTo_Day = GetCurrency_ValidTo(I.Date, 180);
          CR[IK.Class + ' ' + IK.Class2].ValidToState_Day = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].ValidTo_Day
          );
        }

        if (
          CR[IK.Class + ' ' + IK.Class2].Takeoffs_Night >= 5 &&
          CR[IK.Class + ' ' + IK.Class2].Landings_Night >= 5 &&
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Night == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Night = I.Date;
          CR[IK.Class + ' ' + IK.Class2].ValidTo_Night = GetCurrency_ValidTo(I.Date, 180);
          CR[IK.Class + ' ' + IK.Class2].ValidToState_Night = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].ValidTo_Night
          );
        }
        if (
          CR[IK.Class + ' ' + IK.Class2].Float_TO >= 5 &&
          CR[IK.Class + ' ' + IK.Class2].Float_LD >= 5 &&
          CR[IK.Class + ' ' + IK.Class2].Float_ValidFrom == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].Float_ValidFrom = I.Date;
          CR[IK.Class + ' ' + IK.Class2].Float_ValidTo = GetCurrency_ValidTo(I.Date, 180);
          CR[IK.Class + ' ' + IK.Class2].Float_ValidToState = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].Float_ValidTo
          );
        }
      } else {
        //For USA Day and Night passenger currency
        if (
          CR[IK.Class + ' ' + IK.Class2].Takeoffs_Day >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].Landings_Day >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Day == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Day = I.Date;
          CR[IK.Class + ' ' + IK.Class2].ValidTo_Day = GetCurrency_ValidTo(I.Date, 90);
          CR[IK.Class + ' ' + IK.Class2].ValidToState_Day = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].ValidTo_Day
          );
        }

        if (
          CR[IK.Class + ' ' + IK.Class2].Takeoffs_Night >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].Landings_Night >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Night == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].ValidFrom_Night = I.Date;
          CR[IK.Class + ' ' + IK.Class2].ValidTo_Night = GetCurrency_ValidTo(I.Date, 90);
          CR[IK.Class + ' ' + IK.Class2].ValidToState_Night = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].ValidTo_Night
          );
        }
        if (
          CR[IK.Class + ' ' + IK.Class2].Float_TO >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].Float_LD >= 3 &&
          CR[IK.Class + ' ' + IK.Class2].Float_ValidFrom == null
        ) {
          CR[IK.Class + ' ' + IK.Class2].Float_ValidFrom = I.Date;
          CR[IK.Class + ' ' + IK.Class2].Float_ValidTo = GetCurrency_ValidTo(I.Date, 90);
          CR[IK.Class + ' ' + IK.Class2].Float_ValidToState = CheckState_ValidTo(
            CR[IK.Class + ' ' + IK.Class2].Float_ValidTo
          );
        }
      }
    }

    if ((IK.TypeRatingReq == 1 && COUNTRY != 'UNITED STATES') || (COUNTRY == 'UNITED STATES' && MTOW12500)) {
      //Must have currency on each List_Type ie: Bell 205 Series
      if (IK.AcTypes == 'Bell 206L4T') {
        IK.List_Type = 'Bell 206L4T Series';
      }
      if (IK.List_Type == 'Bell 206 Series' || IK.List_Type == 'Bell 206L Series') {
        IK.List_Type = 'Bell 206/206L Series';
      }
      if (CR[IK.List_Type] == undefined) {
        CR[IK.List_Type] = {
          TypeRatingReq: 1,
          FTT: I.FTT,
          DR: I.DR,
          PIC: I.PIC,
          SIC: I.SIC,
          List_type: IK.List_Type,
          Float_TO: I.FTO,
          Float_LD: I.FLD,
          Float: false,
          Float_ValidFrom: null,
          Float_ValidTo: null,
          Float_ValidToState: 'Violation',
          RGR: false,
          RGR_TO: 0,
          RGR_ValidFrom: null,
          RGR_ValidTo: null,
          Takeoffs: I.DAT + I.NT,
          Takeoffs_Day: I.DAT + I.NT,
          Takeoffs_Night: I.NT,
          Landings: I.DL + I.NL,
          Landings_Day: I.DL + I.NL,
          Landings_Night: I.NL,
          Category: IK.Class,
          Class: IK.Class2,
          ValidFrom: null,
          ValidTo: null,
          ValidToState: 'Violoation',
          ValidFrom_Day: null,
          ValidTo_Day: null,
          ValidToState_Day: 'Violation',
          ValidFrom_Night: null,
          ValidTo_Night: null,
          ValidToState_Night: 'Violation',
        };
        if (IK.Class == 'Rotorcraft') {
          CR[IK.List_Type].Float = false;
        }
      } else {
        //For BARS Custom Rule
        if (RecDateCount < 90) {
          CR[IK.List_Type].FTT += I.FTT;
          CR[IK.List_Type].DR += I.DR;
          CR[IK.List_Type].PIC += I.PIC;
          CR[IK.List_Type].SIC += I.SIC;
        }

        CR[IK.List_Type].RGR_TO += I.RGR;
        CR[IK.List_Type].Float_TO += I.FTO;
        CR[IK.List_Type].Float_LD += I.FLD;

        CR[IK.List_Type].Takeoffs += I.DAT + I.NT;
        CR[IK.List_Type].Takeoffs_Day += I.DAT + I.NT;
        CR[IK.List_Type].Takeoffs_Night += I.NT;
        CR[IK.List_Type].Landings += I.DL + I.NL;
        CR[IK.List_Type].Landings_Day += I.DL + I.NL;
        CR[IK.List_Type].Landings_Night += I.NL;
      }
      if (
        Rule != '604' &&
        Rule != '702' &&
        Rule != '91' &&
        Rule != '131' &&
        Rule != '133' &&
        Rule != '135' &&
        Rule != '137'
      ) {
        //703.88 [Flight Crew]
        if (CR[IK.List_Type].Takeoffs >= 3 && CR[IK.List_Type].Landings >= 3 && CR[IK.List_Type].ValidFrom == null) {
          CR[IK.List_Type].ValidFrom = I.Date;
          CR[IK.List_Type].ValidTo = GetCurrency_ValidTo(I.Date);
          CR[IK.List_Type].ValidToState = CheckState_ValidTo(CR[IK.List_Type].ValidTo);
        }
      } else {
        CR[IK.List_Type].ValidToState = 'Not Used';
      }
      //Day and Night passenger Currency
      if (Rule != '91' && Rule != '131' && Rule != '133' && Rule != '135' && Rule != '137') {
        //For Canada and all other NON US operators
        if (
          CR[IK.List_Type].Float_TO >= 5 &&
          CR[IK.List_Type].Float_LD >= 5 &&
          CR[IK.List_Type].Float_ValidFrom == null
        ) {
          CR[IK.List_Type].Float_ValidFrom = I.Date;
          CR[IK.List_Type].Float_ValidTo = GetCurrency_ValidTo(I.Date, 180);
          CR[IK.List_Type].Float_ValidToState = CheckState_ValidTo(CR[IK.List_Type].Float_ValidTo);
        }

        if (
          CR[IK.List_Type].Takeoffs_Night >= 5 &&
          CR[IK.List_Type].Landings_Night >= 5 &&
          CR[IK.List_Type].ValidFrom_Night == null
        ) {
          CR[IK.List_Type].ValidFrom_Night = I.Date;
          CR[IK.List_Type].ValidTo_Night = GetCurrency_ValidTo(I.Date, 180);
          CR[IK.List_Type].ValidToState_Night = CheckState_ValidTo(CR[IK.List_Type].ValidTo_Night);
        }
        if (
          CR[IK.List_Type].Takeoffs_Day >= 5 &&
          CR[IK.List_Type].Landings_Day >= 5 &&
          CR[IK.List_Type].ValidFrom_Day == null
        ) {
          CR[IK.List_Type].ValidFrom_Day = I.Date;
          CR[IK.List_Type].ValidTo_Day = GetCurrency_ValidTo(I.Date, 180);
          CR[IK.List_Type].ValidToState_Day = CheckState_ValidTo(CR[IK.List_Type].ValidTo_Day);
        }
      } else {
        //For US Operators
        if (
          CR[IK.List_Type].Float_TO >= 3 &&
          CR[IK.List_Type].Float_LD >= 3 &&
          CR[IK.List_Type].Float_ValidFrom == null
        ) {
          CR[IK.List_Type].Float_ValidFrom = I.Date;
          CR[IK.List_Type].Float_ValidTo = GetCurrency_ValidTo(I.Date, 90);
          CR[IK.List_Type].Float_ValidToState = CheckState_ValidTo(CR[IK.List_Type].Float_ValidTo);
        }

        if (
          CR[IK.List_Type].Takeoffs_Night >= 3 &&
          CR[IK.List_Type].Landings_Night >= 3 &&
          CR[IK.List_Type].ValidFrom_Night == null
        ) {
          CR[IK.List_Type].ValidFrom_Night = I.Date;
          CR[IK.List_Type].ValidTo_Night = GetCurrency_ValidTo(I.Date, 90);
          CR[IK.List_Type].ValidToState_Night = CheckState_ValidTo(CR[IK.List_Type].ValidTo_Night);
        }
        if (
          CR[IK.List_Type].Takeoffs_Day >= 3 &&
          CR[IK.List_Type].Landings_Day >= 3 &&
          CR[IK.List_Type].ValidFrom_Day == null
        ) {
          CR[IK.List_Type].ValidFrom_Day = I.Date;
          CR[IK.List_Type].ValidTo_Day = GetCurrency_ValidTo(I.Date, 90);
          CR[IK.List_Type].ValidToState_Day = CheckState_ValidTo(CR[IK.List_Type].ValidTo_Day);
        }
      }
    }
  }

  if ((COUNTRY == 'CANADA' && Rule != '604' && Rule != '702') || COUNTRY == 'KENYA') {
    for (var i in CR) {
      if (CR[i].Category == 'Rotorcraft') {
        CR[i].ValidFrom_Night = CR['Rotorcraft helicopter'].ValidFrom_Night;
        CR[i].ValidTo_Night = CR['Rotorcraft helicopter'].ValidTo_Night;
        CR[i].ValidToState_Night = CR['Rotorcraft helicopter'].ValidToState_Night;

        CR[i].ValidFrom_Day = CR['Rotorcraft helicopter'].ValidFrom_Day;
        CR[i].ValidTo_Day = CR['Rotorcraft helicopter'].ValidTo_Day;
        CR[i].ValidToState_Day = CR['Rotorcraft helicopter'].ValidToState_Day;
      }
    }
  }

  return CR;
}

function CheckState_ValidTo(ValidTo, NumDays = 30) {
  var timestamp = new Date().getTime() + NumDays * 24 * 60 * 60 * 1000;
  var ValidToDate = new Date(ValidTo + 'T23:59:59').getTime();
  var now = new Date();
  if (now.getTime() > ValidToDate) {
    return 'Violation';
  }
  if (timestamp > ValidToDate) {
    return 'Warning';
  } else {
    return 'Good';
  }
}

function GetCurrency_ValidTo(recDate, NumDays = 90) {
  var DATE = new Date(recDate + 'T23:59:59');
  DATE.setDate(DATE.getDate() + NumDays);
  var Syear = DATE.getFullYear();
  var SMonth = DATE.getMonth() + 1;
  var Sday = DATE.getDate();
  var now = new Date();
  now.setHours(0);
  now.setMinutes(0);
  now.setMilliseconds(0);
  if (SMonth < 10) {
    SMonth = '0' + SMonth;
  }
  if (Sday < 10) {
    Sday = '0' + Sday;
  }
  var Sdate = Syear + '-' + SMonth + '-' + Sday;
  return Sdate;
}

function CheckReserveStatus(rec, prec) {
  var Reserve = false;
  var Reserve2 = false;
  if (rec.Standby == null) {
    rec.Standby = 0;
  }
  switch (rec.Standby.toString()) {
    case '2':
    case '5':
      Reserve = true;
      break;
    case '6':
    case '7':
      Reserve2 = true;
      break;
    case '8':
      Reserve = true;
      Reserve2 = true;
      break;
    case '19':
      Reserve2 = true;
      break;
    case '35':
      Reserve = true;
      break;
  }
  if (Reserve) {
    //Check for 10 hours without duty prior to Reserve Start
    var ReserveRestTime = 0;
    if (prec == null > 1) {
      if (prec.End == 2359 && rec.Start == 0) {
        ReserveRestTime = round10(dutytime_Calc(0000, prec.Start));
      } else if (prec.End2 == 2359 && (prec.Split == 1 || prec.Split == 3) && rec.Start == 0) {
        ReserveRestTime = round10(dutytime_Calc(prec.End, prec.Start2));
      } else {
        if (prec.Split == 0 || prec.Split == 2) {
          if (prec.End != 0) {
            ReserveRestTime = round10(dutytime_Calc(prec.End, 2359) + dutytime_Calc(0000, rec.Start));
          }
        } else {
          if (prec.End2 != 0) {
            ReserveRestTime = round10(dutytime_Calc(prec.End2, 2359) + dutytime_Calc(0000, rec.Start));
          }
        }
      }
      if (
        ReserveRestTime >= 10 &&
        rec.Start > 600 &&
        prec.End <= 2200 &&
        (prec.Split == 0 || prec.Split == 3) &&
        prec.End2 <= 2200 &&
        (prec.Split == 1 || prec.Split == 3)
      ) {
        Reserve = false;
      }
    } else {
      ReserveRestTime = 24;
      if (ReserveRestTime >= 10 && rec.Start > 600) {
        Reserve = false;
      }
    }
  }

  if (Reserve2) {
    var ReserveRestTime2 = 0;
    ReserveRestTime2 = round10(dutytime_Calc(rec.End, rec.Start2));
    if (ReserveRestTime2 >= 10 && rec.Start2 > 600 && rec.End2 <= 2200) {
      Reserve2 = false;
    }
  }
  return { Reserve: Reserve, Reserve2: Reserve2 };
}
function getTimezoneOffset(dt, timezone) {
  if (timezone == 'UTC') {
    return 0;
  }
  timezone = timezone.trim();
  let getItem = function (format) {
    format.timeZone = timezone;
    return parseInt(dt.toLocaleString('en-US', format));
  };

  let adjDate = new Date(
    getItem({ year: 'numeric' }),
    getItem({ month: 'numeric' }) - 1, // months are zero based
    getItem({ day: 'numeric' }),
    getItem({ hour: 'numeric', hour12: false }),
    getItem({ minute: 'numeric' })
  );
  let noSecs = new Date(dt.getTime());
  noSecs.setSeconds(0, 0);
  let diff = Math.round((adjDate.getTime() - noSecs.getTime()) / 60000);

  return dt.getTimezoneOffset() - diff;
}

var TZ_DIFF_CACHE = {};
function Get_TZ_Difference(OtherTZ, NowTZ = null, minutes = false) {
  let CurTZ = CurrentTimeZone;
  OtherTZ = OtherTZ.trim();

  if (NowTZ != null) {
    NowTZ = NowTZ.trim();
    CurTZ = NowTZ;
  }
  if (OtherTZ == CurTZ) {
    return 0;
  }
  try {
    let difference = 0;
    if (TZ_DIFF_CACHE[CurTZ + OtherTZ + minutes] == undefined) {
      let CurOffset = 0;
      if (CurTZ != 'UTC') {
        CurOffset = getTimezoneOffset(new Date(), CurTZ);
      }
      let OldTZ = 0;
      if (OtherTZ != 'UTC') {
        OldTZ = getTimezoneOffset(new Date(), OtherTZ);
      }

      if (minutes) {
        TZ_DIFF_CACHE[CurTZ + OtherTZ + minutes] = OldTZ - CurOffset;
        return OldTZ - CurOffset;
      } else {
        difference = this.round10((OldTZ - CurOffset) / 60);
        TZ_DIFF_CACHE[CurTZ + OtherTZ + minutes] = difference;
        return difference;
      }
    } else {
      return TZ_DIFF_CACHE[CurTZ + OtherTZ + minutes];
    }
  } catch (e) {
    console.log(e); //debug it
    return 0;
  }
}

/**
 * @Deprecated 2024-06-26 This class was used in legacy fdt, we're a revamp fdt crew now.
 */
class fdtImport {
  constructor(TimezoneHB, FDT_REPORT, prevEvent, curEvent, nextEvent, aircraft) {
    this.TimezoneHB = TimezoneHB;
    this.fdtFlightReport = FDT_REPORT;
    this.importDate = FDT_REPORT.Date;
    this.prevEvent = prevEvent;
    this.curEvent = curEvent;
    this.nextEvent = nextEvent;
    let aircraftLookup = {};
    aircraftLookup['No Ident'] = {
      AcType: '',
      Class: '',
      Class2: '',
      List_Type: '',
      ident: 'No Ident',
    };

    for (let i in aircraft) {
      aircraftLookup[aircraft[i].ident] = aircraft[i].AC_data;
    }
    this.aircraftLookup = aircraftLookup;
  }
  fdtFlightReport = false;
  prevEvent = null;
  curEvent = null;
  nextEvent = null;
  aircraftLookup = {};
  TimezoneHB = 'UTC';
  importDate = '2000-01-01';

  updateReportLeg(L, curRec, curTotals, curExp) {
    VueBridge.deprecationUtils.markDeprecated('menu-Duty-SHARED.fdtImport.updateReportLeg', '2024-06-26', 'bldarosa');
    let LegFT = 0;
    if (L.CalcLegHours === true) {
      LegFT = FDT_LIB.dutytime_Calc(parseInt(L.Time_Start), parseInt(L.Time_Stop));
    } else {
      LegFT = L.LegHours;
    }
    if (curTotals.count == 0 || (curTotals.isSplit && curRec.On2 == undefined)) {
      if (parseInt(FDT_LIB.subDecToTime(L.Time_Start, parseFloat(GROUP_DATA.FDT_StartBuff))) < parseInt(L.Time_Start)) {
        if (curTotals.isSplit) {
          curRec.On2 = FDT_LIB.subDecToTime(L.Time_Start, parseFloat(GROUP_DATA.FDT_StartBuff));
          curRec.Start2 = curRec.On2;
          curRec.delayedRep2 = curRec.On2;
        } else {
          curRec.On = FDT_LIB.subDecToTime(L.Time_Start, parseFloat(GROUP_DATA.FDT_StartBuff));
          curRec.Start = curRec.On;
          curRec.delayedRep = curRec.On;
        }
      } else {
        if (curTotals.isSplit) {
          curRec.On2 = 0;
          curRec.Start2 = curRec.On2;
          curRec.delayedRep2 = curRec.On2;
        } else {
          curRec.On = 0;
          curRec.Start = curRec.On;
          curRec.delayedRep = curRec.On;
        }
      }
    }

    if (parseInt(FDT_LIB.subDecToTime(L.Time_Stop, -parseFloat(GROUP_DATA.FDT_EndBuff))) < 2359) {
      if (curTotals.isSplit) {
        curRec.Off2 = FDT_LIB.subDecToTime(L.Time_Stop, -parseFloat(GROUP_DATA.FDT_EndBuff));
        curRec.End2 = L.Time_Stop;
      } else {
        curRec.Off = FDT_LIB.subDecToTime(L.Time_Stop, -parseFloat(GROUP_DATA.FDT_EndBuff));
        curRec.End = L.Time_Stop;
      }
    } else {
      if (curTotals.isSplit) {
        curRec.Off2 = 2359;
        curRec.End2 = curRec.Off2;
      } else {
        curRec.Off = 2359;
        curRec.End = L.Time_Stop;
      }
    }
    if (curExp[L.ident] == undefined) {
      curExp[L.ident] = {
        DFR: {},
        DUAL: 0,
        PIC: 0,
        SIC: 0,
        Total: 0,
      };
    }
    if (curExp[L.ident].DFR[L.FlightReportPK] == undefined) {
      curExp[L.ident].DFR[L.FlightReportPK] = true;
    }
    curExp[L.ident].Total += LegFT;

    if (FDT_ADMIN_UPDATE) {
      if (L.Pilot_PK == FDT_OPK) {
        curExp[L.ident].PIC += LegFT;
      } else {
        curExp[L.ident].SIC += LegFT;
      }
    } else {
      if (L.Pilot_PK == USER_PK) {
        curExp[L.ident].PIC += LegFT;
      }
      curExp[L.ident].SIC += LegFT;
    }

    if (curTotals.isSplit) {
      curRec.Split = 1;
      curTotals.FT2 += LegFT;
    } else {
      curRec.Split = 0;
      curTotals.FT1 += LegFT;
    }
    curRec.EXTRA.FDT135.push({
      Start: L.Time_Start,
      FT: LegFT,
      Reg: this.Rule,
      Dual: L.SIC_PK == 0 ? 0 : 1,
      IFR: IFR,
    });
    curTotals.count++;
    if (curTotals.overnight) {
      curRec.Overnight = 1;
    } else {
      curRec.Overnight = 0;
    }
  }
  encodeExperienceDFRs(curExp) {
    VueBridge.deprecationUtils.markDeprecated(
      'menu-Duty-SHARED.fdtImport.encodeExperienceDFRs',
      '2024-06-26',
      'bldarosa'
    );
    for (let i in curExp) {
      let DFRs = '';
      let I = curExp[i];
      for (let k in I.DFR) {
        DFRs += (DFRs.length == 0 ? '' : ', ') + k;
      }
      curExp[i].DFR = DFRs;
    }
  }
  confirmImportFlightReports() {
    VueBridge.deprecationUtils.markDeprecated(
      'menu-Duty-SHARED.fdtImport.confirmImportFlightReports',
      '2024-06-26',
      'bldarosa'
    );
    let FDT_REPORT = this.fdtFlightReport;
    //Start LegV2 FDT Import
    //Report times have already been converted Server Side from Entry timezone to the Start Timezone of each record
    let Legs = FDT_REPORT.LegsV2;
    let ReportDate = new Date(FDT_REPORT.Date + 'T00:00:00Z');
    let prevEvent = this.prevEvent;
    let curEvent = this.curEvent;
    let nextEvent = this.nextEvent;
    let prevOvernight = false;
    let prevSplit = false;
    if (prevEvent != null) {
      prevOvernight = prevEvent.Overnight == 1 ? true : false;
      prevSplit = prevEvent.Split == 1 ? true : false;
    }
    let prevTotals = {
      count: 0,
      overnight: prevOvernight,
      isSplit: prevSplit,
      FT1: 0,
      FT2: 0,
      Timezone: '',
      TimezoneEnd: '',
      TimezoneEnd2: '',
    };
    let curTotals = {
      count: 0,
      overnight: false,
      isSplit: false,
      FT1: 0,
      FT2: 0,
      Timezone: '',
      TimezoneEnd: '',
      TimezoneEnd2: '',
    };
    let nextTotals = {
      count: 0,
      overnight: false,
      isSplit: false,
      FT1: 0,
      FT2: 0,
      Timezone: '',
      TimezoneEnd: '',
      TimezoneEnd2: '',
    };
    let prevDate = new Date(this.importDate + 'T00:00:00Z');
    prevDate.setDate(prevDate.getDate() - 1);
    prevDate = prevDate.toISOString().substr(0, 10);
    let nextDate = new Date(this.importDate + 'T00:00:00Z');
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate = nextDate.toISOString().substr(0, 10);
    let prevRec = {
      Date: prevDate,
      Modified: dateToStringObject(new Date()),
      DHC: DHC,
      Dual: Dual,
      Heli: Heli,
      IFR: IFR,
      Logging: Logging,
      AerialApp: AerialApp,
      EXTRA: { FDT135: [] },
    };
    let prevExp = {};
    let curRec = {
      Date: curEvent.Date,
      Modified: dateToStringObject(new Date()),
      DHC: DHC,
      Dual: Dual,
      Heli: Heli,
      IFR: IFR,
      Logging: Logging,
      AerialApp: AerialApp,
      EXTRA: { FDT135: [] },
    };
    let curExp = {};
    let nextRec = {
      Date: nextDate,
      Modified: dateToStringObject(new Date()),
      DHC: DHC,
      Dual: Dual,
      Heli: Heli,
      IFR: IFR,
      Logging: Logging,
      AerialApp: AerialApp,
      EXTRA: { FDT135: [] },
    };
    let nextExp = {};

    let curLastLegFinish = null;
    for (let l in Legs) {
      //All legs are sorted server side from date and leg Start times ASC
      let L = Legs[l];
      let LDate = new Date(L.Date + 'T00:00:00Z');
      if (LDate.getTime() < ReportDate.getTime()) {
        //This leg goes into the previous day record
        curTotals.overnight = true;
        if (prevTotals.Timezone == '') {
          prevTotals.Timezone = L.StartTimezone;
        }
        if (!prevTotals.isSplit) {
          prevTotals.TimezoneEnd = L.EndTimezone;
        } else {
          prevTotals.TimezoneEnd2 = L.EndTimezone;
        }
        this.updateReportLeg(L, prevRec, prevTotals, prevExp);
      }
      if (LDate.getTime() == ReportDate.getTime()) {
        //This leg goes into today's record

        if (curTotals.Timezone == '' && prevTotals.TimezoneEnd != '' && prevTotals.isSplit == false) {
          curTotals.Timezone = prevTotals.TimezoneEnd;
          prevTotals.TimezoneEnd = prevTotals.Timezone;
        }
        if (curTotals.Timezone == '' && prevTotals.TimezoneEnd2 != '' && prevTotals.isSplit == true) {
          curTotals.Timezone = prevTotals.TimezoneEnd2;
          prevTotals.TimezoneEnd2 = prevTotals.Timezone;
        }
        if (curTotals.Timezone == '') {
          curTotals.Timezone = L.StartTimezone;
        }
        if (!curTotals.isSplit) {
          curTotals.TimezoneEnd = L.EndTimezone;
        } else {
          curTotals.TimezoneEnd2 = L.EndTimezone;
        }
        if (curLastLegFinish != null && curTotals.isSplit == false) {
          let timeBetweenLegs = FDT_LIB.dutytime_Calc(parseInt(curLastLegFinish), parseInt(L.Time_Start));
          if (timeBetweenLegs >= 8) {
            curTotals.isSplit = true;
          }
        }
        this.updateReportLeg(L, curRec, curTotals, curExp);
        curLastLegFinish = L.Time_Stop;
      }
      if (LDate.getTime() > ReportDate.getTime()) {
        //This leg goes into the next days record
        nextTotals.overnight = true;
        if (nextTotals.Timezone == '' && curTotals.TimezoneEnd != '' && curTotals.isSplit == false) {
          nextTotals.Timezone = curTotals.TimezoneEnd;
          curTotals.TimezoneEnd = curTotals.Timezone;
        }
        if (nextTotals.Timezone == '' && curTotals.TimezoneEnd2 != '' && curTotals.isSplit == true) {
          nextTotals.Timezone = curTotals.TimezoneEnd2;
          curTotals.TimezoneEnd2 = curTotals.Timezone;
        }
        if (nextTotals.Timezone == '') {
          nextTotals.Timezone = L.StartTimezone;
        }
        if (!nextTotals.isSplit) {
          nextTotals.TimezoneEnd = L.EndTimezone;
        } else {
          nextTotals.TimezoneEnd2 = L.EndTimezone;
        }
        this.updateReportLeg(L, nextRec, nextTotals, nextExp);
      }
    }
    prevRec.EXTRA = JSON.stringify(prevRec.EXTRA);
    this.encodeExperienceDFRs(prevExp);
    curRec.EXTRA = JSON.stringify(curRec.EXTRA);
    this.encodeExperienceDFRs(curExp);
    nextRec.EXTRA = JSON.stringify(nextRec.EXTRA);
    this.encodeExperienceDFRs(nextExp);
    //todo actually put this stuff into the records and see if it works
    console.log(curRec);
    console.log(curTotals);
    console.log(curExp);
    console.log(Object.keys(prevExp).length);
    console.log(Object.keys(curExp).length);
    console.log(Object.keys(nextExp).length);
    this.importFR_FDT(prevEvent, prevRec, prevTotals, prevExp);
    this.importFR_FDT(curEvent, curRec, curTotals, curExp);
    this.importFR_FDT(nextEvent, nextRec, nextTotals, nextExp);
    update_Sync('LastFDTsync', new Date());
    FDT_MODULE_VERSION = 3;
    goToPage('#DutyTimes');
    initDutyTimes(); //menu-Duty.js
  }
  importFR_FDT(curEvent, curRec, curTotals, curExp) {
    VueBridge.deprecationUtils.markDeprecated('menu-Duty-SHARED.fdtImport.importFR_FDT', '2024-06-26', 'bldarosa');
    if (curTotals.count == 0) {
      return;
    }
    let record = {};
    if (curEvent == null) {
      if (FDT_ADMIN_UPDATE) {
        record = Blank_FDT_DaysV4(FDT_OPK, this.importDate);
      } else {
        record = Blank_FDT_DaysV4(USER_PK, this.importDate);
      }
    }
    if (curTotals.Timezone != '') {
      record.Timezone = curTotals.Timezone;
    }
    console.log(curTotals.TimezoneEnd);
    console.log(this.TimezoneHB);
    if (curTotals.TimezoneEnd != '') {
      record.TimezoneEnd = curTotals.TimezoneEnd;
      if (record.TimezoneEnd == this.TimezoneHB) {
        record.homeBase = 1;
      } else {
        record.homeBase = 0;
      }
    }
    if (curTotals.TimezoneEnd2 != '') {
      record.TimezoneEnd2 = curTotals.TimezoneEnd2;
      if (record.TimezoneEnd2 == this.TimezoneHB) {
        record.homeBase2 = 1;
      } else {
        record.homeBase2 = 0;
      }
    }
    record.Sectors = curTotals.count;
    for (var i in curRec) {
      record[i] = curRec[i];
    }
    record.FT = FDT_LIB.round10(curTotals.FT1);
    record.FT2 = FDT_LIB.round10(curTotals.FT2);

    console.log(record);
    cirroDB.insert('FDT_DaysV4', record, null, function (e) {
      console.log('Imported FDT Rec: ' + curRec.Date);
    });
    this.importFR_Experience(curExp, curRec.Date);
  }
  importFR_Experience(curExp, expDate) {
    VueBridge.deprecationUtils.markDeprecated(
      'menu-Duty-SHARED.fdtImport.importFR_Experience',
      '2024-06-26',
      'bldarosa'
    );
    for (let I in curExp) {
      let record = {};
      if (FDT_ADMIN_UPDATE) {
        record = Blank_FDT_RecV3(FDT_OPK, expDate);
      } else {
        record = Blank_FDT_RecV3(USER_PK, expDate);
      }
      record.FTT = I.Total;
      record.PIC = I.PIC;
      record.IDENT = I;
      record.DELETED = 0;
      record.List_Type = this.aircraftLookup[I].List_Type;
      record.AcType = this.aircraftLookup[I].AcType;
      record.Class = this.aircraftLookup[I].Class;
      record.Class2 = this.aircraftLookup[I].Class2;
      record.Modified = dateToStringObject(new Date());
      cirroDB.insert('FDT_RecsV3', record, null, function (e) {
        console.log('Imported Experience: ' + I);
      });
    }
  }
}
