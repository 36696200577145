/**
 * Handles Navigation throughout SAMA
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 */

class cSAMA_Navigator {
  constructor() {
    this.currentPage = null;
    this.previousPage = null;
    this.leaving = false;
    this.offline = false;
    this.dom = {};
  }

  /**
   * Switches to a certain Page.
   * @param  {pSAMA_AbstractPage|function}  _targetPage The page we wish to switch to
   * @param  {*}                   [_preseed]  Options the target page needs to operate
   */
  GoPage(_targetPage, _preseed) {
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateLoading);
    if (this.currentPage == null) {
      return;
    }

    let targetFunction = null;
    if (typeof _targetPage === 'function') {
      targetFunction = _targetPage;
      _targetPage = SAMA.pages.NotInSama;
    }

    this.previousPage = this.currentPage;
    this.currentPage = _targetPage;

    try {
      this.previousPage.LeavePage();
    } catch (_e) {
      console.error(_e);
      cSAMA_Dialog.ShowDialog({
        title: 'Error',
        message: 'There was a non-critical error. Please notify AirSuite with this error code: SAMA 34 GD O2 4U.',
      });
      SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
    }

    this.currentPage.EnterPage(_preseed);

    if (targetFunction != null) {
      targetFunction();
    }
  }

  /**
   * Provide Compatibility with the FRA page
   */
  TransitionFromFRA(_prevPage) {
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateLoading);

    let targetPageId = _prevPage.GetPageId();

    goToPage(targetPageId);

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  /**
   * Returns to the page defined as the current page's default
   */
  GoBack() {
    let nextpage = this.currentPage.GetPrevPage();

    if (nextpage == null) {
      // If we're nowhere but this is still called, act like we're going back from SMS home
      this.currentPage = SAMA.pages.MenuHome;
      nextpage = this.currentPage.GetPrevPage();
    }

    this.leaving = true;
    this.GoPage(nextpage);
  }

  /**
   * Called when pages are completed loading / cleaning up and are ready to be switched to.
   * If Leaving is true, the animation will be reversed.
   * !NOTE! This should be called BEFORE any changes are made to the page that require it to be enhanced.
   * @param {cSAMA_PageHeader} [_newHeader] Required when called from EnterPage
   */
  FinishedLoading(_newHeader) {
    // Wait till both the enter and leave page have loaded.
    if (_newHeader == null) {
      return;
    }

    let tarGetPageId = this.currentPage.GetPageId();

    this.currentPage.loaded = true;

    if (this.currentPage.EqualTo(SAMA.pages.NotInSama)) {
      kbdShrinkView(true);
      tarGetPageId = '#MainMenu';

      if (this.previousPage === SAMA.pages.MenuAdmin || this.previousPage === SAMA.pages.DepartmentEditor) {
        tarGetPageId = '#Administration_Page';
      }
    } else if (VueBridge.canAccessAdminToolMenu() && this.currentPage.EqualTo(SAMA.pages.MenuAdmin)) {
      kbdShrinkView(true);
      VueBridge.enterAdminToolMenu();
    } else {
      // Shrinking the View breaks the SAMA header stickyness for some dumb reason. So we unshrink it.
      kbdShrinkView(false);
    }

    $.mobile.changePage($(tarGetPageId), {
      reverse: this.leaving,
      changeHash: false,
    });

    this.leaving = false;

    if (SAMA.PageHeader != null) {
      SAMA.PageHeader.Reset();
    }
    SAMA.PageHeader = _newHeader;

    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }

  Offline() {
    this.offline = true;
    if (this.currentPage.EqualTo(SAMA.pages.NotInSama)) {
      return;
    }

    if (this.dom.offlineModal == null) {
      this.dom.offlineModal = $('<div/>').addClass('sama-offline-modal sama-tooltip-modal sama-modal-page');
      this.dom.modalContainer = $('<div/>')
        .addClass('sama-lg-tooltip grid-container-fluid')
        .appendTo(this.dom.offlineModal);

      $('<button/>')
        .data('mini', true)
        .addClass('sama-btn-delete')
        .html('<i class="fal fa-times"></i> Discard changes')
        .appendTo(this.dom.modalContainer)
        .on('click', () => this.BackToMainMenu());

      let row = $('<div/>').addClass('row').appendTo(this.dom.modalContainer);

      $('<div/>')
        .addClass('grid-col-xs-12')
        .appendTo(row)
        .html(
          '<h2 class="sama-spacer-line-top"><i style="margin-right: 0.7em" class="fal fa-cog fa-spin"></i> Waiting for Connection.</h2>'
        );

      row = $('<div/>').addClass('row').appendTo(this.dom.modalContainer);

      $('<div/>')
        .addClass('grid-col-xs-12')
        .appendTo(row)
        .html(
          "Cirro is now in offline mode. To preserve changes you've made since you last saved, find a stable internet connection.<br/>" +
            'You can also go back to the main menu and lose all changes.'
        );

      this.dom.offlineModal.enhanceWithin();
    }

    this.dom.offlineModal.hide().appendTo('body').fadeIn(150);
  }

  BackToMainMenu() {
    this.previousPage = this.currentPage;
    this.currentPage = SAMA.pages.NotInSama;

    this.previousPage.LeavePage();
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateLoading);

    $.mobile.changePage($('#MainMenu'), {
      reverse: true,
      changeHash: false,
    });

    if (this.dom.offlineModal != null) {
      this.dom.offlineModal.fadeOut(150);
    }
  }

  Online() {
    if (this.currentPage.EqualTo(SAMA.pages.NotInSama)) {
      return;
    }

    if (this.dom.offlineModal != null) {
      this.offline = false;
      this.dom.offlineModal.fadeOut(150);
    }
  }

  /**
   * Shows a confirm dialog before GoBack()
   */
  DiscardChanges() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Really discard?',
      message: 'All unsaved changes will be lost.',
      yesFunc: () => this.GoBack(),
    });
  }

  SetInitPage(_page) {
    this.currentPage = SAMA.pages.NotInSama;
  }

  /**
   * Triggers the current page's save handler, shows the
   * loading indicator and cleans the state of the page on success.
   */
  SaveChanges() {
    this.currentPage.SaveHandler();
  }
}
