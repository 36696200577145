/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

var SCHEDULE_COMPARE = {};
var SCHEDULE_COMPARE_INDIVIDUAL = {};
var SCHEDULE_DATA = [];
var LIVE_SCH_SYNC = false;
var BOOKING_QUE = [];
var BOOKING_QUE_INDIVIDUAL = [];
var SCH_Syncfailed = false;
var SCHEDULE_MAX_RECORDS = 10;
function Manual_ResyncSCHEDULE() {
  BOOKING_QUE = [];
  BOOKING_QUE_INDIVIDUAL = [];
  if (dp != null) {
    dp.events.list = [];
    dp.update();
  }
  if (ONLINE) {
    $('#SCHManSyncBTN').prop('disabled', true);
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'sync-AJAX.php?action=getLastSync',
      data: {
        SCHfilters: LOCALSTORAGE.SCHfilters,
      },
      timeout: 10000, //10 seconds
    })
      .success(function (json_data) {
        if (json_data[0].ServerResponse == 'Offline') {
          ResyncSCHEDULE();
          // get last sync dates from local database and return
          return;
        }
        if (json_data[0].ServerResponse == 'Success') {
          IOS_postMessage({
            Message: 'SyncStatus',
            status: 'Received Server Sync Dates',
          });
          LastSCHEDULEsync = stringToDateObject(json_data[0].LastSCHEDULEsync);
          ResyncSCHEDULE();
        } else {
          ResyncSCHEDULE();
        }
      })
      .fail(function (data) {
        ResyncSCHEDULE();
      });
  } else {
    ResyncSCHEDULE();
  }
}
function ResyncSCHEDULE() {
  $('#SCHManSyncBTN').prop('disabled', true);
  LIVE_SCH_SYNC = true;
  syncSCHEDULE(true);
}
//Clear out all tasks, and reload all from the server
function loadTasksForSchedule() {
  //Try and get all tasks from server
  SyncTable.query()
    .select('SyncItem = ?', 'LastTASKSync')
    .then((data) => {
      let lastSync = data[0];
      if (lastSync == null) {
        lastSync = '1970-01-01T00:00:00.000Z';
      } else {
        lastSync = lastSync.Date;
      }
      $.ajax({
        type: 'GET',
        url: BASEURL + `api/v2/groups/${GROUP_DATA.PrimaryKey}/tasks/users/${USER_PK}?timestamp=${lastSync}`,
      })
        .success(function (data) {
          //Delete all tasks locally after we have data because it's bad to empty all you eggs from the basket without ensuring you have new one
          const timeStamp = data.timeStamp;
          update_Sync('LastTASKSync', new Date(timeStamp));
          if (data.hasOwnProperty('deletedTasks')) {
            handleDeletedTasks(data.deletedTasks);
          }
          if (data.hasOwnProperty('tasks')) {
            var taskCount = 0;
            var taskTotal = data.tasks.length;
            if (taskTotal == 0) {
              loadObjectivesForSchedule();
              return;
            }
            syncInsertTask(data.tasks, taskCount, taskTotal);
          } else {
            loadObjectivesForSchedule();
          }
        })
        .fail(function (jqXHR, status, error) {
          window.toaster.show(
            `<i class="fal fa-2x fa-exclamation-triangle mr-5 error--text"></i> ${iTrans('failedTask')}`
          );
          loadObjectivesForSchedule();
        });
    });
}
function handleDeletedTasks(deletedTasks) {
  for (const task of Object.values(deletedTasks)) {
    const likeQueryString = `'${task.id}-%'`;
    cirroDB.Delete('Tasks', 'PrimaryKey = ? OR PrimaryKey LIKE ' + likeQueryString, task.id, function (e) {});
  }
}
function syncInsertTask(tasks, taskCount, taskTotal) {
  var task = tasks[taskCount];
  if (task == null) {
    console.log('Failed tasks', tasks);
    console.log('Failed task', task);
    console.log('Failed taskcount', taskCount);
    console.log('failed tasktotal', taskTotal);
  }
  task.Associated = JSON.stringify(task.Associated);

  cirroDB.query('Tasks', 'PrimaryKey = ?', [task.PrimaryKey], function (e) {
    taskCount++;
    if (e.length === 0) {
      cirroDB.insert('Tasks', task, null, function (e) {
        IOS_postMessage({
          Message: 'SCHtext',
          text: 'Task ' + taskCount + ' of ' + taskTotal,
        });
        if (taskCount == taskTotal) {
          //All tasks have been inserted lets do the next one now
          loadObjectivesForSchedule();
        } else {
          if (MOBILE && taskCount % 500 === 0) {
            setTimeout(function () {
              syncInsertTask(tasks, taskCount, taskTotal);
            }, 1);
          } else {
            syncInsertTask(tasks, taskCount, taskTotal);
          }
        }
      });
    } else {
      cirroDB.Delete('Tasks', 'PrimaryKey = ?', task.PrimaryKey, function (e) {
        cirroDB.insert('Tasks', task, null, function (e) {
          IOS_postMessage({
            Message: 'SCHtext',
            text: 'Task ' + taskCount + ' of ' + taskTotal,
          });
          if (taskCount == taskTotal) {
            //All tasks have been inserted lets do the next one now
            loadObjectivesForSchedule();
          } else {
            if (MOBILE && taskCount % 500 === 0) {
              setTimeout(function () {
                syncInsertTask(tasks, taskCount, taskTotal);
              }, 1);
            } else {
              syncInsertTask(tasks, taskCount, taskTotal);
            }
          }
        });
      });
    }
  });
}
function loadObjectivesForSchedule() {
  //Try and update all objectives
  SyncTable.query()
    .select('SyncItem = ?', 'LastOBJECTIVESync')
    .then((data) => {
      let lastSync = data[0];
      if (lastSync == null) {
        lastSync = '1970-01-01T00:00:00.000Z';
      } else {
        lastSync = lastSync.Date;
      }

      $.ajax({
        type: 'GET',
        url:
          BASEURL +
          `api/v2/groups/${GROUP_DATA.PrimaryKey}/safety/objectives/objectivesForScheduler/${USER_PK}?timestamp=${lastSync}`,
      })
        .success(function (objectives) {
          //Delete all objectives locally actually for real this time
          const timeStamp = objectives.timeStamp;
          update_Sync('LastOBJECTIVESync', new Date(timeStamp));
          if (objectives.hasOwnProperty('deletedObjectives')) {
            handleDeletedObjectives(objectives.deletedObjectives);
          }
          if (objectives.hasOwnProperty('objectives')) {
            var objCount = 0;
            var objTotal = objectives.objectives.length;
            if (objTotal == 0) {
              getBookingData();
              return;
            }
            syncInsertObjective(objectives.objectives, objCount, objTotal);
          } else {
            getBookingData();
          }
        })
        .fail(function (jqXHR, status, error) {
          getBookingData();
          window.toaster.show(
            `<i class="fal fa-2x fa-exclamation-triangle mr-5 error--text"></i> ${iTrans('failedObjective')}`
          );
        });
    });
}

function handleDeletedObjectives(deletedObjectives) {
  for (const objective of Object.values(deletedObjectives)) {
    cirroDB.Delete('Objectives', 'PrimaryKey = ?', objective.id, function (e) {});
  }
}
function syncInsertObjective(objectives, objCount, objTotal) {
  var objective = objectives[objCount];
  objective.Associated = JSON.stringify(objective.Associated);

  cirroDB.insert('Objectives', objective, null, function (e) {
    objCount++;
    IOS_postMessage({
      Message: 'SCHtext',
      text: 'Objective ' + objCount + ' of ' + objTotal,
    });
    if (objCount == objTotal) {
      //All objectives have been inserted lets do the booking data now
      getBookingData();
    } else {
      if (MOBILE && objCount % 500 === 0) {
        setTimeout(function () {
          syncInsertObjective(objectives, objCount, objTotal);
        }, 1);
      } else {
        syncInsertObjective(objectives, objCount, objTotal);
      }
    }
  });
}
function syncSCHEDULE(showLoading = false) {
  if (showLoading) {
    $.mobile.loading('show');
  }
  loadTasksForSchedule();
}
function getBookingData() {
  BOOKING_QUE = [];
  BOOKING_QUE_INDIVIDUAL = [];
  SCH_Syncfailed = false;
  IOS_postMessage({
    Message: 'SCHtext',
    text: 'Getting Journal',
  });
  if (LOCALSTORAGE.SyncLength == undefined) LOCALSTORAGE.SyncLength = 2;
  $.getJSON(
    BASEURL + 'menu-Scheduler-AJAX.php?action=getSCHRecords&SyncLength=' + LOCALSTORAGE.SyncLength,
    function (json_data) {
      if (json_data.length != 0) {
        if (json_data[0].ServerResponse == 'Offline') {
          SCH_Syncfailed = true;
          SCHEDULE_SYNCING = false;
          exitSCH_Sync();
        } else {
          if (json_data[0].ServerResponse != 'Uncaught Exception') {
            IOS_postMessage({
              Message: 'SCHtext',
              text: 'Comparing',
            });
            SCHEDULE_DATA = json_data[0];
            openSCH_DB();
          } else {
            SCH_Syncfailed = true;
            console.log('Uncaught Exception:' + json_data[0].Exception);
            SCHEDULE_SYNCING = false;
            exitSCH_Sync();
          }
        }
      } else {
        IOS_postMessage({
          Message: 'SCHtext',
          text: 'Receive Error!',
        });
        SCH_Syncfailed = true;
        SCHEDULE_SYNCING = false;
        exitSCH_Sync();
      }
    }
  ).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'SCHtext',
      text: 'Receive Error!',
    });
    SCH_Syncfailed = true;
    SCHEDULE_SYNCING = false;
    exitSCH_Sync();
  });
}
function openSCH_DB() {
  cirroDB.query('Scheduler_Bookings', '', ['SYNC'], function (e) {
    SCHEDULE_COMPARE = e;
    if (e === false) SCHEDULE_COMPARE = {};
    console.log(SCHEDULE_COMPARE);
    compare_Bookings(0); //first check
  });
}

/**
 *
 * @param i
 */
function compare_Bookings(i) {
  var Bookings = SCHEDULE_DATA.Bookings;
  if (i > Bookings.length - 1) {
    ProcessBookingQue(0);
    return;
  }
  if (Bookings.length <= 0) {
    checkDeleteBookings();
    return;
  }

  var SCHO = 'PK' + Bookings[i].PrimaryKey;
  if (!SCHEDULE_COMPARE.hasOwnProperty(SCHO)) {
    //Insert new scheduled item
    UpdateBookingQue(Bookings[i].PrimaryKey, i);
    return;
  }

  //compare date of item and update if necessary
  SCHEDULE_COMPARE[SCHO].Exists = true; //mark local record as existing
  if (SCHEDULE_COMPARE[SCHO].Updated_Date != Bookings[i].Updated_Date) {
    //update existing scheduler item
    UpdateBookingQue(Bookings[i].PrimaryKey, i);
    return;
  }

  if (i < Bookings.length - 1) {
    i++;
    compare_Bookings(i);
    return;
  }

  ProcessBookingQue(0);
}

/**
 *
 */
function UpdateBookingQue(PK, i) {
  BOOKING_QUE.push(PK);
  i++;
  compare_Bookings(i);
}

function ProcessBookingQue(index, numProcessed = 0) {
  var que = [];
  var count = 0;
  for (var i in BOOKING_QUE) {
    if (i < index) {
      continue;
    }
    count++;
    que.push(BOOKING_QUE[i]);
    //get in chunks of X bookings;
    if (count >= SCHEDULE_MAX_RECORDS) {
      break;
    }
  }

  if (que.length == 0) {
    checkDeleteBookings();
  } else {
    GetBookingRecs(i, que, numProcessed);
  }
}

function GetBookingRecs(i, que, numProcessed) {
  //Reset sync timer for large synces on first logins when NOT in scheduler mode
  if (!LIVE_SCH_SYNC) {
    if (syncTimer != null) clearTimeout(syncTimer);
    syncTimer = setTimeout('SyncToolLong()', 120000);
  }

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Scheduler-AJAX.php?action=getBookings',
    data: {
      bookings: que,
    },
    timeout: 30000, //10 seconds
  })
    .success(function (data) {
      if (data.hasOwnProperty(0) && data[0].ServerResponse === 'Uncaught Exception') {
        //stop sync and conting other stuff
        IOS_postMessage({
          Message: 'SCHtext',
          text: 'Failed: Uncaught',
        });
        SCH_Syncfailed = true;
        UpdateBookingRecs([], i, numProcessed);
      }

      UpdateBookingRecs(data, i, numProcessed);
    })
    .fail(function (data) {
      //stop sync and conting other stuff
      IOS_postMessage({
        Message: 'SCHtext',
        text: 'Failed: Connection',
      });
      SCH_Syncfailed = true;
      UpdateBookingRecs([], i, numProcessed);
    });
}

function UpdateBookingRecs(recs, i, numProcessed) {
  if (!recs || recs.length <= 0) {
    checkDeleteBookings();
    return;
  }

  for (const rec of recs) {
    numProcessed++;
    rec.Associated = JSON.stringify(rec.Associated);
  }

  cirroDB.insert('Scheduler_Bookings', recs, null, function (e) {
    IOS_postMessage({
      Message: 'SCHtext',
      text: `Booking ${numProcessed} of ${BOOKING_QUE.length}`,
    });

    i++;
    ProcessBookingQue(i, numProcessed);
  });
}

function checkDeleteBookings() {
  for (const i in SCHEDULE_COMPARE) {
    const I = SCHEDULE_COMPARE[i];
    if (!I.hasOwnProperty('Exists')) {
      //delete this item pk from local DB
      cirroDB.Delete('Scheduler_Bookings', 'PrimaryKey = ?', parseInt(I.PrimaryKey), function (e) {
        if (!e) {
          console.log('Failed to delete SCH: ' + I.PrimaryKey);
        } else {
          console.log('Deleted SCH: ' + I.PrimaryKey);
        }
      });
    }
  }
  openSCH_Ind_DB(); //sync individual bookings now
}

// ****************************************************** Do it all over again for individuals ***************/

function openSCH_Ind_DB() {
  BOOKING_QUE_INDIVIDUAL = [];
  cirroDB.query('Scheduler_Individual', '', ['SYNC'], function (e) {
    SCHEDULE_COMPARE_INDIVIDUAL = e;
    if (e === false) SCHEDULE_COMPARE_INDIVIDUAL = {};
    console.log(SCHEDULE_COMPARE_INDIVIDUAL);
    compare_Bookings2(0); //first check
  });
}

function compare_Bookings2(i) {
  var Bookings = SCHEDULE_DATA.Individual;
  if (i >= Bookings.length) {
    ProcessBookingQue2(0);
    return;
  }

  if (Bookings.length == 0) {
    checkDeleteBookings2();
  } else {
    var SCHO = 'PK' + Bookings[i].PrimaryKey;
    if (SCHEDULE_COMPARE_INDIVIDUAL[SCHO] != undefined) {
      //compare date of item and update if necessary
      SCHEDULE_COMPARE_INDIVIDUAL[SCHO].Exists = true; //mark local record as existing
      if (SCHEDULE_COMPARE_INDIVIDUAL[SCHO].modified != Bookings[i].modified) {
        //update existing scheduler item
        UpdateBookingQue2(Bookings[i].PrimaryKey, i);
      } else {
        if (i < Bookings.length - 1) {
          i++;
          compare_Bookings2(i);
        } else ProcessBookingQue2(0);
      }
    } else {
      //Insert new scheduled item
      UpdateBookingQue2(Bookings[i].PrimaryKey, i);
    }
  }
}

function UpdateBookingQue2(PK, i) {
  BOOKING_QUE_INDIVIDUAL.push(PK);
  i++;
  compare_Bookings2(i);
}

function ProcessBookingQue2(index, numProcessed = 0) {
  var que = [];
  var count = 0;
  for (var i in BOOKING_QUE_INDIVIDUAL) {
    if (i < index) continue;
    count++;
    que.push(BOOKING_QUE_INDIVIDUAL[i]);
    if (count == SCHEDULE_MAX_RECORDS) break; //get in chunks of X bookings;
  }
  if (que.length == 0) {
    checkDeleteBookings2();
  } else {
    console.log(i);
    GetBookingRecs2(i, que, numProcessed);
  }
}

function GetBookingRecs2(i, que, numProcessed) {
  //Reset sync timer for large synces on first logins when NOT in scheduler mode
  if (!LIVE_SCH_SYNC) {
    if (syncTimer != null) clearTimeout(syncTimer);
    syncTimer = setTimeout('SyncToolLong()', 120000);
  }
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Scheduler-AJAX.php?action=getBookings2',
    data: {
      bookings: que,
    },
    timeout: 30000, //10 seconds
  })
    .success(function (data) {
      UpdateBookingRecs2(data, i, numProcessed);
    })
    .fail(function (data) {
      //stop sync and conting other stuff
      IOS_postMessage({
        Message: 'SCHtext',
        text: 'Failed: Connection',
      });
      SCH_Syncfailed = true;
      UpdateBookingRecs2([], i, numProcessed);
    });
}

function UpdateBookingRecs2(recs, i, numProcessed) {
  var progress = {};
  var writeCount = 0;
  if (recs.length == 0) {
    numProcessed++;
    i++;
    ProcessBookingQue2(i, numProcessed);
    return;
  }
  for (var r in recs) {
    var rec = recs[r];
    numProcessed++;
    progress[rec.PrimaryKey] = numProcessed;
    //rec.Associated = JSON.stringify(rec.Associated);
    cirroDB.insert('Scheduler_Individual', rec, null, function (e) {
      IOS_postMessage({
        Message: 'SCHtext',
        text: 'Individual Booking: ' + (parseInt(progress[rec.PrimaryKey]) + 1) + ' / ' + BOOKING_QUE_INDIVIDUAL.length,
      });
      writeCount++;
      if (writeCount == recs.length) {
        i++;
        ProcessBookingQue2(i, numProcessed);
      }
    });
  }
}
function checkDeleteBookings2() {
  for (var i in SCHEDULE_COMPARE_INDIVIDUAL) {
    var I = SCHEDULE_COMPARE_INDIVIDUAL[i];
    if (I.Exists == undefined) {
      //delete this item pk from local DB
      cirroDB.Delete(
        'Scheduler_Individual',
        'PrimaryKey = ? OR PrimaryKey LIKE ?',
        [I.PrimaryKey, I.PrimaryKey + '_%'],
        function (e) {
          if (e === false) console.log('Failed to delete SCH: ' + I.PrimaryKey);
          else console.log('Deleted SCH: ' + I.PrimaryKey);
        }
      );
    }
  }

  if (SCH_Syncfailed == false) update_Sync('LastSCHEDULEsync', LastSCHEDULEsync);
  IOS_postMessage({
    Message: 'SCHtext',
    text: 'Complete',
  });
  exitSCH_Sync();
}
function exitSCH_Sync() {
  SCHEDULE_SYNCING = false;
  if (LIVE_SCH_SYNC) {
    LIVE_SCH_SYNC = false;
    if (dp != null) {
      SCH_PopulateSCH_Aircraft();
      SCH_LoadEvents(); //reload events after sync
    } else {
      $.mobile.loading('hide');
    }
  } else {
    SYNC_STATUS('exitSCH_Sync');
  }
  //Cleanup old events outside of sync range
  if (LOCALSTORAGE.SyncLength == undefined) LOCALSTORAGE.SyncLength = 2;
  var now = new Date();
  now.setMonth(now.getMonth() - LOCALSTORAGE.SyncLength);
  var end = now.toISOString().substr(0, 10) + ' 00:00:00';
  cirroDB.Delete('Scheduler_Individual', 'end < ?', [end], function (e) {
    console.log('Cleaned up Old Individual Scheduler Events');
  });
  cirroDB.Delete('Scheduler_Bookings', 'end < ?', [end], function (e) {
    console.log('Cleaned up Old Scheduler Bookings');
  });
}
