/* Quoting viewer shared methods below here */

var CURRENT_QUOTE;
var QUOTE_CURRENT_LEG;
var QuotesignaturePad;

function roundNum(Num, Places = 0) {
  //  Rounding function
  if (Places > 0) {
    if (Num.toString().length - Num.toString().lastIndexOf('.') > Places + 1) {
      var Rounder = Math.pow(10, Places);
      return Math.round(Num * Rounder) / Rounder;
    } else return Num;
  } else return Math.round(Num);
}

function BILLING_getTimezoneHTML(item, index = false) {
  if (!index) {
    var timezoneHTML =
      '<h3>' +
      iTrans('Start Timezone') +
      ': ' +
      item.StartTimezone +
      ' --> ' +
      iTrans('End Timezone') +
      ': ' +
      item.EndTimezone +
      '</h3>';
  } else {
    var TZList = getTimezoneList();
    var StartTimezoneOptions = '';
    var EndTimezoneOptions = '';
    for (var i in TZList) {
      var I = TZList[i];
      StartTimezoneOptions +=
        '<option value="' +
        I.value +
        '" ' +
        (item.StartTimezone == I.value ? 'selected' : '') +
        '>' +
        I.label +
        '</option>';
      EndTimezoneOptions +=
        '<option value="' +
        I.value +
        '" ' +
        (item.StartTimezone == I.value ? 'selected' : '') +
        '>' +
        I.label +
        '</option>';
    }

    var timezoneHTML =
      '<table class="limit-table"><tr><td><input type="text" placeholder="' +
      iTrans('Search') +
      ': ' +
      iTrans('Start Timezone') +
      '" id="BMQ_StartTimezone-' +
      index +
      '_TZSearch"/><label for="BMQ_StartTimezone-' +
      index +
      '" v-t="menu_Quoting_LANG.BMQ_StartTimezone" >Start Timezone</label>' +
      '<select data-mini="true" onChange="BILLING_UpdateACTimezone(' +
      index +
      ')" id="BMQ_StartTimezone-' +
      index +
      '">' +
      StartTimezoneOptions +
      '</select></td><td><input type="text" placeholder="' +
      iTrans('Search') +
      ': ' +
      iTrans('End Timezone') +
      '" id="BMQ_EndTimezone-' +
      index +
      '_TZSearch"/>' +
      '<label for="BMQ_EndTimezone-' +
      index +
      '" v-t="menu_Quoting_LANG.BMQ_EndTimezone" >End Timezone</label>' +
      '<select data-mini="true" onChange="BILLING_UpdateACTimezone(' +
      index +
      ')" id="BMQ_EndTimezone-' +
      index +
      '">' +
      EndTimezoneOptions +
      '</select></td></tr></table>';
  }
  return timezoneHTML;
}
function getTimezoneList() {
  let aryIannaTimeZones = Intl.supportedValuesOf('timeZone');
  let date = new Date();
  let TZList = [];
  TZList.push({ value: 'UTC', label: 'Coordinated Universal Time (UTC)' });
  aryIannaTimeZones.forEach((timeZone) => {
    try {
      let rawOffsetInMinutes = parseInt(this.getTimezoneOffset(date, timeZone));
      //console.log(rawOffsetInMinutes + ' ' + timeZone);
      rawOffsetInMinutes = rawOffsetInMinutes * -1;
      let labelOffset =
        ' (GMT' +
        (rawOffsetInMinutes >= 0 ? '+' : '') +
        rawOffsetInMinutes / 60 +
        (rawOffsetInMinutes % 60 > 0 ? ':' + (rawOffsetInMinutes % 60) : '') +
        ')';
      if (rawOffsetInMinutes == 0) labelOffset = ' (GMT)';
      let label = timeZone + labelOffset;
      TZList.push({ value: timeZone, label: label, labelOffset: labelOffset });
    } catch (e) {
      console.log(e);
    }
  });
  return TZList;
}
function BILLING_CreateQuoteHTML(showCommonLegItems, showUniqueLegItems, showAllPricing) {
  var html = '<div id="BILLING_QUOTE_HEADER-' + CURRENT_QUOTE.PrimaryKey + '"></div>';
  var Quote_Sub = 0;
  var Quote_Tax = 0;
  var Quote_Total = 0;
  html += '<h2>' + iTrans('Travel Details') + '</h2>';
  //Create commong leg details
  if (CURRENT_QUOTE.CommonLegs.length > 0) {
    var CommonAClist = '';
    var HasCommonAC = false;
    for (var a in CURRENT_QUOTE.Aircraft) {
      var A = CURRENT_QUOTE.Aircraft[a];
      if (A.UniqueLegs == 0) {
        HasCommonAC = true;
        CommonAClist += A.AC_Ident + ' (' + A.AcType + ') ';
      }
    }
    if (HasCommonAC) {
      CommonAClist = '<h4>' + CommonAClist + '</h4>';
      var CommonLegList = BILLING_GenerateLegList(
        CURRENT_QUOTE,
        BILLING_getTimezoneHTML(CURRENT_QUOTE),
        CURRENT_QUOTE.CommonLegs,
        false,
        false,
        CURRENT_QUOTE.Pax_Manifest.Pax_Legs
      );
      html += CommonAClist + CommonLegList;
    }
  }

  //Create Unique Leg Details
  if (CURRENT_QUOTE.Aircraft.length > 0) {
    var UniqueACIdent = '';
    var UniqueLegList = '';
    for (var a in CURRENT_QUOTE.Aircraft) {
      var A = CURRENT_QUOTE.Aircraft[a];
      if (A.UniqueLegs == 1) {
        if (A.Legs.length > 0) {
          UniqueACIdent += '<h4>' + A.AC_Ident + ' (' + A.AcType + ')</h4>';
          var UniqueLegList = BILLING_GenerateLegList(
            CURRENT_QUOTE,
            BILLING_getTimezoneHTML(A),
            A.Legs,
            false,
            false,
            A.Pax_Manifest.Pax_Legs
          );
          html += UniqueACIdent + UniqueLegList;
        }
      }
    }
  }

  html += BILLING_popQuoteCfields(CURRENT_QUOTE, false);
  html +=
    '<div id="BILLING_COMPANY_NOTES-' +
    CURRENT_QUOTE.PrimaryKey +
    '"><h3>' +
    iTrans('Notes') +
    '</h3>' +
    CURRENT_QUOTE.Notes.replace(/\n/g, '<br />') +
    '</div>';
  if (CURRENT_QUOTE.ClientNotes == null) html += '<div id="BILLING_CLIENT_NOTES"></div>';
  else
    html +=
      '<div id="BILLING_CLIENT_NOTES-' +
      CURRENT_QUOTE.PrimaryKey +
      '"><h3>' +
      iTrans('Client Notes') +
      '</h3>' +
      CURRENT_QUOTE.ClientNotes.replace(/\n/g, '<br />') +
      '</div>';
  html += '<div id="BILLING_QUOTE_TERMS-' + CURRENT_QUOTE.PrimaryKey + '"></div>';
  html +=
    '<div data-testid="biling-viewer-shared-client-signature" id="BILLING_CLIENT_SIGNATURE-' +
    CURRENT_QUOTE.PrimaryKey +
    '"></div>';
  return html;
}
function BILLING_popQuoteCfields(CURRENT_QUOTE, edit = true) {
  var Cfields = CURRENT_QUOTE.Cfields;
  if (edit) {
    var html =
      '<table class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Item') +
      '</th><th>' +
      iTrans('Quantity') +
      '</th><th style="text-align:right">' +
      iTrans('Unit Price') +
      '</th><th>' +
      iTrans('Tax Rate') +
      '</th><th>' +
      iTrans('Amount') +
      '</th></thead><tbody>';
    for (var i in Cfields) {
      var F = Cfields[i];
      var id = '-BMQ_CF-' + i;
      var disabletax = '';
      if (F.Taxable == 0) disabletax = 'disabled';
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_deleteBMQ_CF(" +
        i +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button> " +
        F.Name +
        '</div>';
      if (F.PerPax != 1 && F.PerDay != 1) {
        html +=
          '<tr><td>' +
          actions +
          '</td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultQty +
          '" id="DefaultQty' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate(this);" data-role="none" onClick="this.select();" /></td><td><input style="width:100px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultPrice +
          '" id="DefaultPrice' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate(this);" data-role="none" onClick="this.select();" /></td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.Tax +
          '" id="DefaultTax' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate(this);" data-role="none" onClick="this.select();" ' +
          disabletax +
          '/></td><td style="text-align:right"><div id="Amount' +
          id +
          '">' +
          F.Amount.toLocaleString('en-US', CUR) +
          '</div></td></tr>';
      } else {
        html +=
          '<tr><td>' +
          actions +
          '</td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultQty +
          '" id="DefaultQty' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate(this);" data-role="none" onClick="this.select();" disabled/></td><td><input style="width:100px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultPrice +
          '" id="DefaultPrice' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate(this);" data-role="none" onClick="this.select();" /></td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.Tax +
          '" id="DefaultTax' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate(this);" data-role="none" onClick="this.select();" ' +
          disabletax +
          '/></td><td style="text-align:right"><div id="Amount' +
          id +
          '">' +
          F.Amount.toLocaleString('en-US', CUR) +
          '</div></td></tr>';
      }
    }

    html += '<tr><td>' + BILLING_GetCFselector(0) + '</td><td></td><td></td><td></td><td></td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td>' +
      iTrans('Sub Total') +
      '</td><td style="text-align:right"><div id="Sub-BMQ_CF"></div></td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td>' +
      iTrans('Tax') +
      '</td><td style="text-align:right"><div id="Tax-BMQ_CF"></div></td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td><b>' +
      iTrans('Total') +
      '</b></td><td style="text-align:right"><div id="Total-BMQ_CF"></div></td></tr>';
    html += '</tbody></table>';
  } else {
    var html = '<quote><table class="limit-table first-td-fill"><tbody>';
    html +=
      '<tr><td></td><td><h2>' + iTrans('Summary of Costs') + '</h2></td><td></td><td></td><td></td><td></td></tr>';

    var Total = 0;
    var Sub = 0;
    var Tax = 0;

    var FC = BILLING_TotalLegValues(CURRENT_QUOTE);
    console.log('FC', FC);
    var rateLBL = FC.Rate_Unit;
    switch (FC.Rate_Unit) {
      case 'litres':
        rateLBL = 'Litre';
        break;
      case 'gallonsus':
        rateLBL = 'US Gallon';
        break;
      case 'gallonsimp':
        rateLBL = 'Imp Gallon';
        break;
    }
    if (FC.TotalCF != 0) {
      html +=
        '<tr><td></td><td style="border-bottom: thin solid #afafaf; font-size: 110%"><b>' +
        iTrans('Item') +
        '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
        '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
        iTrans('Price') +
        '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
        iTrans('Tax') +
        '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
        iTrans('Amount') +
        '</b></td></tr>';
      html +=
        '<tr><td></td><td><b>' +
        iTrans('Additional Flight Charges') +
        '</b></td><td></td><td>' +
        FC.SubCF.toLocaleString('en-US', CUR) +
        '</td><td style="text-align:right">' +
        FC.TaxCF.toLocaleString('en-US', CUR) +
        '</td><td style="text-align:right"><b>' +
        FC.TotalCF.toLocaleString('en-US', CUR) +
        '</b></td></tr>';
    }
    html +=
      '<tr><td></td><td style="border-bottom: thin solid #afafaf; font-size: 110%"><b>' +
      iTrans('Item') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Quantity') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Unit Price') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Tax Rate') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Amount') +
      '</b></td></tr>';

    html += FC.htmlAC;

    Sub += FC.Sub + FC.SubCF;
    Tax += FC.Tax + FC.TaxCF;

    for (var i in Cfields) {
      var F = Cfields[i];
      var extras = '';
      if (isNaN(F.Amount)) F.Amount = 0;
      if (F.PerPax == 1 && F.PerLeg == 0) {
        extras = ' [Per Passenger]';
        F.DefaultQty = FC.Pax;
        F.Amount = F.DefaultQty * F.DefaultPrice;
      }

      if (F.PerLeg == 1 && F.PerPax == 0) {
        extras = ' [Per Leg]';
        F.DefaultQty = FC.Legs;
      }
      if (F.PerPax == 1 && F.PerLeg == 1) {
        extras = ' [Per Passenger/Leg]';
        F.DefaultQty = FC.Legs * FC.Pax;
      }
      Sub += F.Amount;
      if (F.Taxable == 1) Tax += (parseFloat(F.Tax) * F.Amount) / 100;
      else F.Tax = 0;
      html +=
        '<tr><td></td><td>' +
        F.Name +
        extras +
        '</td><td style="text-align:right;">' +
        F.DefaultQty +
        '</td><td style="text-align:right">' +
        parseFloat(F.DefaultPrice).toLocaleString('en-US', CUR) +
        '</td><td style="text-align:right">' +
        F.Tax +
        '%</td><td style="text-align:right">' +
        parseFloat(F.Amount).toLocaleString('en-US', CUR) +
        '</td></tr>';
    }
    Total = Sub + Tax;
    Sub = Math.round(Sub * 100) / 100;
    Tax = Math.round(Tax * 100) / 100;
    Total = Math.round(Total * 100) / 100;
    html +=
      '<tr><td></td><td style="border-top: thin solid #afafaf;"></td><td style="border-top: thin solid #afafaf;"></td><td style="border-top: thin solid #afafaf;"></td><td style="text-align:right;border-top: thin solid #afafaf;">' +
      iTrans('Sub Total') +
      '</td><td style="text-align:right; border-top: thin solid #afafaf;">' +
      Sub.toLocaleString('en-US', CUR) +
      '</td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td></td><td style="text-align:right;">' +
      iTrans('Tax') +
      '</td><td style="text-align:right">' +
      Tax.toLocaleString('en-US', CUR) +
      '</td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td></td><td style="text-align:right;border-top: thin solid #afafaf;"><b>' +
      iTrans('Total') +
      '</b></td><td style="text-align:right; border-top: thin solid #afafaf;"><b>' +
      Total.toLocaleString('en-US', CUR) +
      '</b><br /></td></tr>';

    html += '</tbody></table></quote>';
    CURRENT_QUOTE.QuoteSub = Sub;
    CURRENT_QUOTE.QuoteTax = Tax;
    CURRENT_QUOTE.QuoteTotal = Total;
  }
  return html;
}
function BILLING_popQuoteCfields_Legs(CURRENT_QUOTE, Leg, index, legindex, edit = true, visible = false) {
  var Cfields = Leg.Cfields;
  if (edit) {
    var html =
      '<table class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Item') +
      '</th><th>' +
      iTrans('Quantity') +
      '</th><th style="text-align:right">' +
      iTrans('Unit Price') +
      '</th><th>' +
      iTrans('Tax Rate') +
      '</th><th>' +
      iTrans('Amount') +
      '</th></thead><tbody>';

    for (var i in Cfields) {
      var F = Cfields[i];
      var id = '-BMQ_CFL-' + i;
      var disabletax = '';
      if (F.Taxable == 0) {
        disabletax = 'disabled';
        F.Tax = 0;
      }
      var extras = '';
      if (F.PerPax == 1) {
        F.DefaultQty = Leg.PaxNum;
        extras = ' [' + iTrans('Per Passenger') + ']';
      }
      console.log(Leg);
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_deleteBMQ_Leg_CF(" +
        i +
        ', ' +
        legindex +
        ', ' +
        index +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button> " +
        F.Name +
        extras +
        '</div>';
      if (F.PerPax != 1) {
        html +=
          '<tr><td>' +
          actions +
          '</td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultQty +
          '" id="DefaultQty' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate_Leg(this,' +
          index +
          ',' +
          legindex +
          ');" data-role="none" onClick="this.select();" /></td><td><input style="width:100px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultPrice +
          '" id="DefaultPrice' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate_Leg(this,' +
          index +
          ',' +
          legindex +
          ');" data-role="none" onClick="this.select();" /></td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.Tax +
          '" id="DefaultTax' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate_Leg(this,' +
          index +
          ',' +
          legindex +
          ');" data-role="none" onClick="this.select();" ' +
          disabletax +
          '/></td><td style="text-align:right"><div id="Amount' +
          id +
          '">' +
          F.Amount.toLocaleString('en-US', CUR) +
          '</div></td></tr>';
      } else {
        html +=
          '<tr><td>' +
          actions +
          '</td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultQty +
          '" id="DefaultQty' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate_Leg(this,' +
          index +
          ',' +
          legindex +
          ');" data-role="none" onClick="this.select();" disabled/></td><td><input style="width:100px;" type="number" name="' +
          i +
          '" value="' +
          F.DefaultPrice +
          '" id="DefaultPrice' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate_Leg(this,' +
          index +
          ',' +
          legindex +
          ');" data-role="none" onClick="this.select();" /></td><td><input style="width:50px;" type="number" name="' +
          i +
          '" value="' +
          F.Tax +
          '" id="DefaultTax' +
          id +
          '" onChange="BILLING_QuoteCfieldUpdate_Leg(this,' +
          index +
          ',' +
          legindex +
          ');" data-role="none" onClick="this.select();" ' +
          disabletax +
          '/></td><td style="text-align:right"><div id="Amount' +
          id +
          '">' +
          F.Amount.toLocaleString('en-US', CUR) +
          '</div></td></tr>';
      }
    }

    html +=
      '<tr><td>' + BILLING_GetCFselector_Legs(1, index, legindex) + '</td><td></td><td></td><td></td><td></td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td>' +
      iTrans('Sub Total') +
      '</td><td style="text-align:right"><div id="Sub-BMQ_CFL"></div></td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td>' +
      iTrans('Tax') +
      '</td><td style="text-align:right"><div id="Tax-BMQ_CFL"></div></td></tr>';
    html +=
      '<tr><td></td><td></td><td></td><td><b>' +
      iTrans('Total') +
      '</b></td><td style="text-align:right"><div id="Total-BMQ_CFL"></div></td></tr>';
    html += '</tbody></table>';
  } else {
    var LegNum = parseInt(legindex) + 1;
    var html = '';
    var Sub = 0;
    var Tax = 0;
    var Total = 0;

    html +=
      '<tr class="quotedetailpricing"><td></td><td></td><td></td><td><b>' +
      iTrans('Detailed Costs') +
      '</b></td><td></td><td></td><td></td><td></td>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<td></td>' : '') +
      '</tr>';
    html +=
      '<tr class="quotedetailpricing"><td></td><td></td><td></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Item') +
      '</b></td>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<td style="border-bottom: thin solid #afafaf;"></td>' : '') +
      '<td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Quantity') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Unit Price') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Tax Rate') +
      '</b></td><td style="border-bottom: thin solid #afafaf;"><b>' +
      iTrans('Amount') +
      '</b></td></tr>';

    var L = Leg;

    var A = CURRENT_QUOTE.Aircraft;
    for (var i in A) {
      if (L.QACPK == 0) {
        if (A[i].UniqueLegs == 0) {
          var C = BILLING_getQuoteAC_Costs(CURRENT_QUOTE, L, A[i]);
          html += C.html;
          Sub += C.Amount;
          Tax += C.Tax;
        }
      } else {
        if (A[i].PrimaryKey == L.QACPK) {
          var U = BILLING_getQuoteAC_Costs(CURRENT_QUOTE, L, A[i]);
          html += U.html;
          Sub += U.Amount;
          Tax += U.Tax;
        }
      }
    }

    for (var i in Cfields) {
      var F = Cfields[i];
      var extras = '';
      if (F.PerPax == 1) {
        extras = ' [Per Passenger]';
      }
      Sub += F.Amount;
      Tax += (parseFloat(F.Tax) * F.Amount) / 100;
      html +=
        '<tr class="quotedetailpricing"><td></td><td></td><td></td><td>' +
        F.Name +
        extras +
        '</td>' +
        (GROUP_DATA.FlightCategories.length > 0 ? '<td></td>' : '') +
        '<td>' +
        F.DefaultQty +
        '</td><td style="text-align:right">' +
        parseFloat(F.DefaultPrice).toLocaleString('en-US', CUR) +
        '</td><td style="text-align:right">' +
        parseFloat(F.Tax).toFixed(2) +
        '%</td><td style="text-align:right">' +
        F.Amount.toLocaleString('en-US', CUR) +
        '</td></tr>';
    }
    Total = Sub + Tax;
    Sub = Math.round(Sub * 100) / 100;
    Tax = Math.round(Tax * 100) / 100;
    Total = Math.round(Total * 100) / 100;
    html +=
      '<tr class="quotedetailpricing"><td></td><td></td><td></td>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<td style="border-top: thin solid #afafaf;"></td>' : '') +
      '<td style="border-top: thin solid #afafaf;"></td><td style="border-top: thin solid #afafaf;"></td><td style="border-top: thin solid #afafaf;"></td><td style="border-top: thin solid #afafaf;">' +
      iTrans('Sub Total') +
      '</td><td style="text-align:right; border-top: thin solid #afafaf;">' +
      Sub.toLocaleString('en-US', CUR) +
      '</td></tr>';
    html +=
      '<tr class="quotedetailpricing"><td></td>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<td></td>' : '') +
      '<td></td><td></td><td></td><td></td><td></td><td>' +
      iTrans('Tax') +
      '</td><td style="text-align:right">' +
      Tax.toLocaleString('en-US', CUR) +
      '</td></tr>';
    html +=
      '<tr class="quotedetailpricing"><td></td>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<td></td>' : '') +
      '<td></td><td></td><td></td><td></td><td></td><td style="border-top: thin solid #afafaf;"><b>' +
      iTrans('Total') +
      '</b></td><td style="text-align:right; border-top: thin solid #afafaf;"><b>' +
      Total.toLocaleString('en-US', CUR) +
      '</b><br /></td></tr>';
  }
  return html;
}
function BILLING_getQuoteAC_Costs(Q, L, A) {
  var html = '';
  var Amount = parseFloat(L.Amount);
  var Weight = parseFloat(L.Weight);
  var QuantityTxt = '';
  var amountTax = 0;
  if (L.Billable) {
    switch (A.Rate_Unit) {
      case 'Hour':
        var Hours = roundNum(L.Distance / L.Cruise + (parseInt(L.TaxiTimeDest) + parseInt(L.TaxiTimeOrigin)) / 60, 1);
        if (Q.Job != undefined) {
          if (Q.Job.BlockTime == 0) {
            Hours = roundNum(L.Distance / L.Cruise, 1);
          }
        }
        QuantityTxt = Hours + ' ' + iTrans('Hours');
        Amount = roundNum(Hours * L.Rate, 2);
        break;
      case 'nm':
        L.Distance = roundNum(L.Distance, 1);
        var convDistance = roundNum(L.Distance * 1.15078, 1);
        QuantityTxt = convDistance + ' nm';
        Amount = roundNum(convDistance * L.Rate, 2);
        break;
      case 'sm':
        L.Distance = roundNum(L.Distance, 1);
        var convDistance = roundNum(L.Distance * 1.15078, 1);
        QuantityTxt = convDistance + ' sm';
        Amount = roundNum(convDistance * L.Rate, 2);
        break;
      case 'km':
        L.Distance = roundNum(L.Distance, 1);
        var convDistance = roundNum(L.Distance * 1.852, 1);
        QuantityTxt = convDistance + ' km';
        Amount = roundNum(convDistance * L.Rate, 2);
        break;
      case 'kg':
        Weight = roundNum(Weight, 1);
        QuantityTxt = roundNum(Weight, 1) + ' kg';
        Amount = roundNum(Weight * L.Rate, 2);
        break;
      case 'lb':
        Weight = roundNum(Weight, 1);
        QuantityTxt = roundNum(Weight, 1) + ' lb';
        Amount = roundNum(Weight * L.Rate, 2);
        break;
      case 'litres':
        rateLBL = 'Litre';
        Weight = roundNum(Weight, 1);
        QuantityTxt = roundNum(Weight, 1) + ' Litres';
        Amount = roundNum(Weight * L.Rate, 2);
        break;
      case 'gallonsus':
        rateLBL = 'US Gallon';
        Weight = roundNum(Weight, 1);
        QuantityTxt = roundNum(Weight, 1) + ' US Gallons';
        Amount = roundNum(Weight * L.Rate, 2);
        break;
      case 'gallonsimp':
        rateLBL = 'Imp Gallon';
        Weight = roundNum(Weight, 1);
        QuantityTxt = roundNum(Weight, 1) + ' Imp Gallons';
        Amount = roundNum(Weight * L.Rate, 2);
        break;
    }
    amountTax = roundNum((Amount * parseFloat(A.Tax)) / 100, 2);
  } else {
    QuantityTxt = iTrans('N/A - Non Billable');
    Amount = 0;
    amountTax = 0;
  }
  if (isNaN(Amount)) Amount = 0;
  var rateLBL = A.Rate_Unit;

  switch (A.Rate_Unit) {
    case 'litres':
      rateLBL = 'Litre';
      break;
    case 'gallonsus':
      rateLBL = 'US Gallon';
      break;
    case 'gallonsimp':
      rateLBL = 'Imp Gallon';
      break;
  }
  html +=
    '<tr class="quotedetailpricing"><td></td><td></td><td></td><td>' +
    A.AC_Ident +
    ' - ' +
    A.Currency +
    '/' +
    rateLBL +
    '</td>' +
    (GROUP_DATA.FlightCategories.length > 0 ? '<td></td>' : '') +
    '<td>' +
    QuantityTxt +
    '</td><td style="text-align:right">' +
    (getDecimals(L.Rate) > 2 ? '$' + L.Rate : L.Rate.toLocaleString('en-US', CUR)) +
    '</td><td style="text-align:right">' +
    A.Tax +
    '%</td><td style="text-align:right">' +
    Amount.toLocaleString('en-US', CUR) +
    '</td></tr>';
  return { html: html, Amount: Amount, Tax: amountTax };
}
function BILLING_convertDistance(Distance, Rate_Unit) {
  switch (Rate_Unit) {
    case 'nm':
      return roundNum(Distance, 1) + 'nm';
      break;
    case 'sm':
      return roundNum(Distance * 1.15078, 1) + 'sm';
      break;
    case 'km':
      return roundNum(Distance * 1.852, 1) + 'km';
      break;
    default:
      return roundNum(Distance, 1) + 'nm';
      break;
  }
}
function BILLING_MakeQuoteHeader(data, CURRENT_QUOTE) {
  var SoldTo;
  var Q = CURRENT_QUOTE;

  SoldTo = Q.Client_Text + '<br />';
  SoldTo += Q.AddressedTo.replace(/\n/g, '<br />') + '<br />';
  SoldTo += '<br />Attn: ' + Q.ContactName + '<br />';
  SoldTo += Q.QuoteEmail.replace(/;/g, '<br />');

  var CompanyInfo = '<b>' + data.Company_Name + '</b><br />';
  CompanyInfo += data.address.address_one + '<br />';
  if (data.address.address_two != '') CompanyInfo += data.address.address_two + '<br />';
  CompanyInfo += data.address.city + ', ' + data.address.province + ' ' + data.address.postal_code + '<br />';
  CompanyInfo += data.address.country + '<br /><br />';
  CompanyInfo += data.address.contact_phone + '<br />';
  CompanyInfo += data.address.contact_email;

  var html = '<quote><table class="limit-table wrap">';
  if (CURRENT_QUOTE.Completed == 1) {
    html +=
      '<tr class="hideable"><td><img style="width:350px; height:auto;" src="data:' +
      data.address.type +
      ';base64,' +
      data.address.image +
      '" /></td><td style="text-align:right; vertical-align:top; "><h3>' +
      iTrans('Invoice For Quote ') +
      '# ' +
      Q.PrimaryKey +
      '<br />' +
      iTrans('Date') +
      ': ' +
      Q.Date +
      '<br />' +
      iTrans('Expires') +
      ': ' +
      Q.Expires +
      '</h3></td></tr></table>';
  } else {
    html +=
      '<tr class="hideable"><td><img style="width:300px; height:auto;" src="data:' +
      data.address.type +
      ';base64,' +
      data.address.image +
      '" /></td><td style="text-align:right; vertical-align:top; "><h3>' +
      iTrans('Quote') +
      ' # ' +
      Q.PrimaryKey +
      '<br />' +
      iTrans('Date') +
      ': ' +
      Q.Date +
      '<br />' +
      iTrans('Expires') +
      ': ' +
      Q.Expires +
      '</h3></td></tr></table>';
  }

  html += '<table class="limit-table wrap">';
  html +=
    '<tr class="hideable"><td style="vertical-align:top;">' +
    CompanyInfo +
    '</td><td style="vertical-align:top;"><b>' +
    iTrans('Sold to') +
    '</b><br />' +
    SoldTo +
    '</td><td style="vertical-align:top;"><b>' +
    iTrans('Flight Description') +
    '</b><br />' +
    Q.Job_Text.replace(/\n/g, '<br />') +
    '</td></tr></table>';
  html += '<br />';

  $('#BILLING_QUOTE_HEADER-' + CURRENT_QUOTE.PrimaryKey).html(html);
  $('#BILLING_QUOTE_TERMS-' + CURRENT_QUOTE.PrimaryKey).html(
    '<h3>' + iTrans('Terms and Conditions') + '</h3>' + Q.Terms.replace(/\n/g, '<br />')
  );
}
function BILLING_TotalCfieldValues(Cfields) {
  var Sub = 0;
  var Tax = 0;
  for (var i in Cfields) {
    var F = Cfields[i];
    if (!isNaN(F.Amount)) Sub += F.Amount;
    if (F.Taxable == 1 && !isNaN(F.Tax)) Tax += roundNum((parseFloat(F.Tax) * F.Amount) / 100, 2);
  }
  return { Sub: Sub, Tax: Tax };
}
function BILLING_PopulateSignatureData(CURRENT_QUOTE) {
  var Q = CURRENT_QUOTE;
  var html = '';
  if (Q.signature == null) {
    html =
      '<center><h4>' +
      iTrans('I have reviewed all relevant information outlined in this quotation') +
      ';<br>' +
      iTrans('I hereby agree to the terms and conditions above and approve this quote') +
      '.</h4></center><button data-testid="billing-viewer-shared-sign-quote" data-icon="check" class="greenbg" onClick="BILLING_SignQuoteNow();">' +
      iTrans('Agree and sign quote') +
      '</button>';
  } else {
    html =
      '<center><h4>' +
      iTrans('I have reviewed all relevant information outlined in this quotation') +
      ';<br>' +
      iTrans('I hereby agree to the terms and conditions above and approve this quote') +
      '.</h4><img width="300px;" src="' +
      Q.signature +
      '"><h3>' +
      Q.signed_by +
      '</h3><div style="font-size: 8pt; !important">' +
      iTrans('Signature Timestamp') +
      ': ' +
      Q.AcceptedDateTime +
      'Z</div></center>';
  }
  $('#BILLING_CLIENT_SIGNATURE-' + Q.PrimaryKey).html(html);
  $('#BILLING_CLIENT_SIGNATURE-' + Q.PrimaryKey).enhanceWithin();
}

function BILLING_getQuantity(Q, L, Rate_Unit) {
  var NumAircraft = 1;

  switch (Rate_Unit) {
    case 'Hour':
      L.Distance = roundNum(L.Distance, 1);
      var Hours = roundNum(L.Distance / L.Cruise + (L.TaxiTimeOrigin + L.TaxiTimeDest) / 60, 1);
      if (Q.Job != undefined) {
        if (Q.Job.BlockTime == 0) {
          Hours = roundNum(L.Distance / L.Cruise, 1);
        }
      }
      return roundNum(NumAircraft * Hours, 1);
      break;
    case 'nm':
      L.Distance = roundNum(L.Distance, 1);
      return roundNum(NumAircraft * L.Distance, 1);
      break;
    case 'sm':
      L.Distance = roundNum(L.Distance, 1);
      var convDistance = roundNum(L.Distance * 1.15078, 1);
      return roundNum(NumAircraft * convDistance, 1);
      break;
    case 'km':
      L.Distance = roundNum(L.Distance, 1);
      var convDistance = roundNum(L.Distance * 1.852, 1);
      return roundNum(NumAircraft * convDistance, 1);
      break;
    case 'litres':
    case 'gallonsus':
    case 'gallonsimp':
    case 'kg':
    case 'lb':
      var convWeight = roundNum(L.Weight, 1);
      return roundNum(NumAircraft * convWeight, 1);
      break;
  }
  return 0;
}

function BILLING_TotalLegValues(CURRENT_QUOTE) {
  var Sub = 0;
  var Tax = 0;
  var SubCF = 0;
  var TaxCF = 0;
  var Pax = 0;
  var Legs = 0;
  var Quantity = 0;
  var Rate = 0;
  var Rate_Unit = 'nm';
  var TaxRate = 0;
  var htmlAC = '';

  for (var a in CURRENT_QUOTE.Aircraft) {
    var A = CURRENT_QUOTE.Aircraft[a];
    var acIdent = A.AC_Ident;
    var amountAC = 0;
    var amount = 0;
    var rate = A.Rate;
    var variableRate = false;
    var taxRate = A.Tax;
    var variableTaxRate = false;
    var quantityAC = 0;
    if (A.UniqueLegs == 0) {
      for (var i in CURRENT_QUOTE.CommonLegs) {
        var C = CURRENT_QUOTE.CommonLegs[i];
        Rate = C.Rate;
        if (i == 0) {
          rate = C.Rate;
        }
        if (C.Rate != rate) {
          variableRate = true;
        }
        if (C.Tax != taxRate) {
          variableTaxRate = true;
        }
        Rate_Unit = C.Rate_Unit;
        amount = BILLING_getQuantity(CURRENT_QUOTE, C, Rate_Unit);
        quantityAC += amount;
        Quantity += amount;
        Pax += parseInt(C.PaxNum);
        Legs++;

        if (!isNaN(amount)) {
          amountAC += amount * Rate;
        }

        if (!isNaN(A.Tax)) {
          Tax += (parseFloat(A.Tax) * amount * Rate) / 100;
        }
        TaxRate = parseFloat(C.Tax);
        var CF = BILLING_TotalCfieldValues(C.Cfields);
        SubCF += CF.Sub;
        TaxCF += CF.Tax;
      }
      var rateDecimals = getDecimals(Rate);
      if (rateDecimals < 2) {
        Rate = parseFloat(Rate).toFixed(2);
      }
      Sub += amountAC;
      htmlAC +=
        '<tr><td></td><td><b>' +
        A.AC_Ident +
        ' - ' +
        A.Currency +
        '/' +
        Rate_Unit +
        '</b></td><td>' +
        roundQuantity(quantityAC, Rate_Unit) +
        '</td><td style="text-align:right">' +
        (variableRate
          ? iTrans('Variable')
          : getDecimals(A.Rate) > 2
          ? '$' + A.Rate
          : Rate.toLocaleString('en-US', CUR)) +
        '</td><td style="text-align:right">' +
        (variableTaxRate ? iTrans('Variable') : TaxRate.toFixed(2) + '%') +
        '</td><td style="text-align:right"><b>' +
        amountAC.toLocaleString('en-US', CUR) +
        '</b></td></tr>';
    } else {
      var rate = A.Rate;
      var variableRate = false;
      var taxRate = A.Tax;
      var variableTaxRate = false;
      for (var i in A.Legs) {
        var C = A.Legs[i];
        Rate = C.Rate;
        if (i == 0) {
          rate = C.Rate;
        }
        if (Rate != rate) {
          variableRate = true;
        }
        if (C.Tax != taxRate) {
          variableTaxRate = true;
        }
        Rate_Unit = A.Rate_Unit;
        amount = BILLING_getQuantity(CURRENT_QUOTE, C, Rate_Unit);
        quantityAC += amount;
        Quantity += amount;
        Pax += parseInt(C.PaxNum);
        Legs++;

        if (!isNaN(amount)) {
          amountAC += amount * Rate;
        }

        if (!isNaN(A.Tax)) {
          Tax += (parseFloat(A.Tax) * amount * Rate) / 100;
        }
        TaxRate = parseFloat(C.Tax);
        var CF = BILLING_TotalCfieldValues(C.Cfields);
        SubCF += CF.Sub;
        TaxCF += CF.Tax;
      }
      var rateDecimals = getDecimals(Rate);
      if (rateDecimals < 2) {
        Rate = parseFloat(Rate).toFixed(2);
      }
      Sub += amountAC;
      htmlAC +=
        '<tr><td></td><td><b>' +
        A.AC_Ident +
        ' - ' +
        A.Currency +
        '/' +
        Rate_Unit +
        '</b></td><td>' +
        roundQuantity(quantityAC, Rate_Unit) +
        '</td><td style="text-align:right">' +
        (variableRate
          ? iTrans('Variable')
          : getDecimals(A.Rate) > 2
          ? '$' + A.Rate
          : Rate.toLocaleString('en-US', CUR)) +
        '</td><td style="text-align:right">' +
        (variableTaxRate ? iTrans('Variable') : TaxRate.toFixed(2) + '%') +
        '</td><td style="text-align:right"><b>' +
        amountAC.toLocaleString('en-US', CUR) +
        '</b></td></tr>';
    }
  }
  var Total = Sub + Tax;
  Sub = roundNum(Sub, 2);
  Tax = roundNum(Tax, 2);
  Total = roundNum(Total, 2);
  var TotalCF = SubCF + TaxCF;
  SubCF = roundNum(SubCF, 2);
  TaxCF = roundNum(TaxCF, 2);
  TotalCF = roundNum(TotalCF, 2);
  return {
    Pax: Pax,
    Legs: Legs,
    Sub: Sub,
    Tax: Tax,
    Total: Total,
    SubCF: SubCF,
    TaxCF: TaxCF,
    TotalCF: TotalCF,
    Quantity: roundNum(Quantity, 2),
    Rate: Rate,
    Rate_Unit: Rate_Unit,
    TaxRate: TaxRate,
    htmlAC: htmlAC,
  };
}
function roundQuantity(Quantity, Rate_Unit) {
  switch (Rate_Unit) {
    case 'Hour':
      return roundNum(Quantity, 1);
      break;
    case 'nm':
      return roundNum(Quantity, 1);
      break;
    case 'sm':
      return roundNum(Quantity, 1);
      break;
    case 'km':
      return roundNum(Quantity, 1);
      break;
    case 'litres':
    case 'gallonsus':
    case 'gallonsimp':
    case 'kg':
    case 'lb':
      return roundNum(Quantity, 1);
      break;
  }
  return Quantity;
}
function getDecimals(num) {
  try {
    var converted = num.toString();
    if (converted.includes('.')) {
      return converted.split('.')[1].length;
    }
    return 0;
  } catch (e) {
    return 0;
  }
}
function BILLING_ShowLegCharges(e) {
  if (e.checked) {
    $('.quotedetailpricing').show();
  } else {
    $('.quotedetailpricing').hide();
  }
}
function BILLING_ShowCompanyNotes(e) {
  if (e.checked) {
    $('#BILLING_COMPANY_NOTES').show();
  } else {
    $('#BILLING_COMPANY_NOTES').hide();
  }
}

function BILLING_ShowClientNotes(e) {
  if (e.checked) {
    $('#BILLING_CLIENT_NOTES').show();
  } else {
    $('#BILLING_CLIENT_NOTES').hide();
  }
}
function BILLING_SignQuoteNow() {
  $.mobile.changePage($('#BILLING_QuoteSignature'), {
    transition: 'slide',
    changeHash: false,
    reverse: false,
  });
  $('#BILLING_QuoteSignatureName').val('');

  //init signature page
  $('#quote-signature-pad-wrapper').html('');
  $('#quote-signature-pad-wrapper').html(
    '<canvas data-testid="billing-viewer-shared-signature-pad" id="quote-signature-pad" class="signature-pad"></canvas>'
  );
  var canvas = document.getElementById('quote-signature-pad');
  QuotesignaturePad = new SignaturePad(canvas, {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    penColor: 'rgb(0, 0, 0)',
  });

  function resizeCanvas() {
    var data = QuotesignaturePad.toDataURL();
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    QuotesignaturePad.fromDataURL(data);
    //QuotesignaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }
  window.addEventListener('resize', resizeCanvas);
  BILLING_ClearQuoteSignature();
  resizeCanvas();
}

function BILLING_ClearQuoteSignature() {
  QuotesignaturePad.clear();
  var Q = CURRENT_QUOTE;
  Q.signature = null;
  Q.signed_by = '';
}

function quoteLegDateTime(L) {
  let dt = new Date(L.DepartDate + 'T' + L.DepartTime + ':00Z');
  let min = getTimezoneOffset(dt, L.Timezone);
  dt.setMinutes(dt.getMinutes() + min);
  return dt.toISOString().substr(0, 16).replace('T', ' ');
}
function sortQuoteLegs(a, b) {
  let dateTimeA = quoteLegDateTime(a);
  let dateTimeB = quoteLegDateTime(b);
  if (dateTimeA < dateTimeB) {
    return -1;
  }
  if (dateTimeA > dateTimeB) {
    return 1;
  }
  return 0;
}
function BILLING_GenerateLegList(
  CURRENT_QUOTE,
  timezoneHTML,
  Legs,
  index,
  actionBTN = true,
  Pax_Legs = [],
  showPaxMan = false
) {
  //sort Legs to departdate and time
  Legs.sort(sortQuoteLegs);
  var Rate_Unit = 'nm';
  if (CURRENT_QUOTE.Aircraft.length > 0) {
    Rate_Unit = CURRENT_QUOTE.Aircraft[0].Rate_Unit;
  }
  var html = timezoneHTML;

  if (actionBTN) {
    html +=
      '<table class="limit-table wrap"><thead><tr><th>' +
      iTrans('Leg') +
      '</th><th>' +
      iTrans('From') +
      '</th><th>' +
      iTrans('To') +
      '</th><th>ETD</th><th>ETE</th><th>ETA</th>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<th>CatCode</th>' : '') +
      '<th>' +
      iTrans('Pax') +
      '</th><th>' +
      iTrans('Distance') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in Legs) {
      var L = Legs[i];
      var Leg = parseInt(i) + 1;
      var actions =
        "<div class='vertical-center-container'><button onClick='BILLING_editQuoteLeg(" +
        i +
        ', ' +
        index +
        ")' data-mini='true' class='action-btn'><img src='./images/edit.svg' /></button><button onClick='BILLING_deleteQuoteLeg(" +
        i +
        ', ' +
        index +
        ")' data-mini='true' class='action-btn'><img src='./images/delete.svg' /></button></div>";
      var FromTxt = L.FromICAO + '<br />' + L.FromICAO_Name;
      if (L.FromICAO == '') FromTxt = L.FromDESC;
      var ToTxt = L.ToICAO + '<br />' + L.ToICAO_Name;
      if (L.ToICAO == '') ToTxt = L.ToDESC;
      var TimezoneLBL = ' (UTC)';
      var timeDiff = Get_TZ_Difference('UTC', L.Timezone);
      if (timeDiff > 0) TimezoneLBL = ' (GMT+' + timeDiff + ')';
      if (timeDiff < 0) TimezoneLBL = ' (GMT' + timeDiff + ')';
      html +=
        '<tr><td>' +
        Leg +
        '</td><td>' +
        FromTxt +
        '</td><td>' +
        ToTxt +
        '</td><td>' +
        L.DepartDate +
        '<br />' +
        L.DepartTime +
        TimezoneLBL +
        '</td><td>' +
        L.ETE +
        '</td><td>' +
        L.ArrivalDate +
        '<br />' +
        L.ArrivalTime +
        TimezoneLBL +
        '</td>' +
        (GROUP_DATA.FlightCategories.length > 0 ? '<td>' + getflightCatID(L.flightCatID).fltcatcode + '</td>' : '') +
        '<td>' +
        L.PaxNum +
        '</td><td>' +
        BILLING_convertDistance(L.Distance, Rate_Unit) +
        '</td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html +=
      '<quote><table class="limit-table wrap" style="border: 1px solid #afafaf;"><thead><tr><th>' +
      iTrans('Leg') +
      '</th><th>' +
      iTrans('From') +
      '</th><th>' +
      iTrans('To') +
      '</th><th>ETD (' +
      iTrans('local') +
      ')</th><th>ETE</th><th>ETA (' +
      iTrans('local') +
      ')</th>' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<th>CatCode</th>' : '') +
      '<th>' +
      iTrans('Pax') +
      '</th><th>' +
      iTrans('Distance') +
      '</th></tr></thead><tbody>';
    var Total_Hours = 0;
    var Total_Minutes = 0;
    var Total_Pax = 0;
    var Total_Distance = 0;
    for (var i in Legs) {
      var L = Legs[i];
      var TimezoneLBL = ' (UTC)';
      var timeDiff = Get_TZ_Difference('UTC', L.Timezone);
      if (timeDiff > 0) TimezoneLBL = ' (GMT+' + timeDiff + ')';
      if (timeDiff < 0) TimezoneLBL = ' (GMT' + timeDiff + ')';
      var Leg = parseInt(i) + 1;
      var FromTxt = L.FromICAO + '<br />' + L.FromICAO_Name;
      if (L.FromICAO == '') FromTxt = L.FromDESC;
      var ToTxt = L.ToICAO + '<br />' + L.ToICAO_Name;
      if (L.ToICAO == '') ToTxt = L.ToDESC;
      var legPaxTotalWeight = 0;
      var paxSummary = '';
      if (Pax_Legs[i] != undefined) {
        var PaxLeg = Pax_Legs[i];
        if (PaxLeg.length > 0) {
          for (var p in PaxLeg) {
            let weightUnit = 'Imperial';
            if (PaxLeg[p].units != 'imperial') {
              weightUnit = 'Metric';
            }
            if (CURRENT_QUOTE.Job.showPaxManLegs == 1 || showPaxMan == true) {
              let paxVIPclass = '';
              if (PaxLeg[p].VIP == 1) paxVIPclass = 'yellowbg';
              paxSummary +=
                '<span class="' +
                paxVIPclass +
                '">' +
                PaxLeg[p].lastname +
                ', ' +
                PaxLeg[p].firstname +
                (CURRENT_QUOTE.Job.showPaxWeights == 1
                  ? ' (' + PaxLeg[p].weight + (PaxLeg[p].units == 'imperial' ? ' lbs' : ' kg') + ')'
                  : '') +
                '</span><br />';
            }
            legPaxTotalWeight += parseInt(convertToUserUNITS(PaxLeg[p].weight, 'Weight', weightUnit, 'Imperial'));
          }
          if (CURRENT_QUOTE.Job.showPaxManLegs == 1 || showPaxMan == true) {
            FromTxt +=
              '<br /><b>' +
              iTrans('Pax Manifest') +
              ': ' +
              (CURRENT_QUOTE.Job.showPaxWeights == 1 || showPaxMan == true ? '(' + legPaxTotalWeight + ' lbs ' : '') +
              ' ' +
              iTrans('Total') +
              ')</b><br />';
            FromTxt += paxSummary;
          }
        }
      }
      if (Leg > 1)
        html +=
          '</tbody><thead><tr class="quotedetailpricing"><th>' +
          iTrans('Leg') +
          '</th><th>' +
          iTrans('From') +
          '</th><th>' +
          iTrans('To') +
          '</th><th>ETD (' +
          iTrans('local') +
          ')</th><th>ETE</th><th>ETA (' +
          iTrans('local') +
          ')</th>' +
          (GROUP_DATA.FlightCategories.length > 0 ? '<th>CatCode</th>' : '') +
          '<th>' +
          iTrans('Pax') +
          '</th><th>' +
          iTrans('Distance') +
          '</th></tr></thead><tbody>';
      html +=
        '<tr style="border-top: thin solid #afafaf;"><td style="vertical-align: top">' +
        Leg +
        '</td><td style="vertical-align: top">' +
        FromTxt +
        '</td><td style="vertical-align: top">' +
        ToTxt +
        '</td><td style="vertical-align: top">' +
        L.DepartDate +
        '<br />' +
        L.DepartTime +
        TimezoneLBL +
        '</td><td style="vertical-align: top">' +
        L.ETE +
        '</td><td style="vertical-align: top">' +
        L.ArrivalDate +
        '<br />' +
        L.ArrivalTime +
        TimezoneLBL +
        '</td style="vertical-align: top">' +
        (GROUP_DATA.FlightCategories.length > 0
          ? '<td style="vertical-align: top">' + getflightCatID(L.flightCatID).fltcatcode + '</td>'
          : '') +
        '<td style="vertical-align: top">' +
        L.PaxNum +
        '</td><td style="vertical-align: top">' +
        BILLING_convertDistance(L.Distance, Rate_Unit) +
        '</td></tr>';
      Total_Hours += parseInt(L.ETE.substr(0, 2));
      Total_Minutes += parseInt(L.ETE.substr(3, 2));
      Total_Pax += parseInt(L.PaxNum);
      Total_Distance += parseFloat(L.Distance);

      //Per Leg Details hidden tr hopefully
      html += BILLING_popQuoteCfields_Legs(CURRENT_QUOTE, L, false, i, false, true);
    }
    while (Total_Minutes > 59) {
      Total_Hours += 1;
      Total_Minutes -= 60;
    }
    if (Total_Minutes < 10) Total_Minutes = '0' + Total_Minutes;
    if (Total_Hours < 10) Total_Hours = '0' + Total_Hours;
    html +=
      '<tr><td></td><td></td><td></td><td style="border-top: thin solid #afafaf;"><b>Totals</b></td><td style="border-top: thin solid #afafaf;"><b>' +
      Total_Hours +
      ':' +
      Total_Minutes +
      '</b></td><td style="border-top: thin solid #afafaf;"></td> ' +
      (GROUP_DATA.FlightCategories.length > 0 ? '<td style="border-top: thin solid #afafaf;"></td>' : '') +
      '<td style="border-top: thin solid #afafaf;"><b>' +
      Total_Pax +
      '</b></td><td style="border-top: thin solid #afafaf;"><b>' +
      BILLING_convertDistance(Total_Distance, Rate_Unit) +
      '</b></td></tr>';
    html += '</tbody></table></quote>';
  }
  return html;
}

/* Flight Reports viewer shared methods below */

var BILLING_CLIENTS;
var BILLING_CLIENT_INDEX;
var BILLING_JOB_INDEX;
var BILLING_JOBS;
var REPORT_JOBS = [];
var BILLING_EXPENSE_CLIENTPK;
var BILLING_EXPENSE_JOBID;
var BILLING_EXPENSE_BUSINESS;
var BILLING_FLIGHTREPORTS;
var BILLING_FLIGHTREPORT_INDEX;
var BILLING_FLIGHTLEGS = [];
var BILLING_FLIGHTREPORT_CREW = [];
var BILLING_REVIEW;
var EDIT_INVOICE = false;
var BILLING_CLIENT_CFIELD_INDEX = null;
var BILLING_CLIENT_CFIELD = {};
var CURRENT_LEG_CFIELDS = [];
var CURRENT_FLIGHT_REPORT = {};
var BILLING_RETURN_TO;
var ARCHIVED_FLIGHTREPORTS = [];
var BILLING_OFFLINE_REPORTS = [];
var BILLING_COMPANY_ADJUSTMENTS = [];
var BILLING_JOB_PK = 0;
var CUR = {
  style: 'currency',
  currency: 'USD',
};
var NUMBER_FMT = {
  style: 'decimal',
  useGrouping: true,
  minimumIntegerDigits: 1,
  minimumFractionDigits: 1,
  minimumSignificantDigits: 1,
  maximumSignificantDigits: 3,
};
function br2nl(str) {
  return str.replace(/<br\s*\/?>/gm, '\n');
}
function nl2br(str) {
  str.toString();
  return str.replace(/\r\n|\r|\n/g, '<br />');
}
function secondsToAge(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? ' h, ' : ' h, ') : '';
  var mDisplay = m > 0 ? m + (m == 1 ? ' m' : ' m') : '';
  var sDisplay = s > 0 ? s + (s == 1 ? ' s' : ' s') : '';
  if (h == Infinity) return 'N/A';
  return hDisplay + mDisplay + sDisplay;
}

function getDecimalTime(start, end, system) {
  let hours = 0;
  switch (system) {
    case 'Hour2':
      hours = DecimalSystem2(start, end);
      break;
    case 'Minute':
    case 'HourMinute':
      return getMinuteDifference(start, end);
      break;
    default: //in menu-Duty-SHARED.js
      hours = dutytime_Calc(start, end);
      break;
  }
  if (hours < 0) hours += 24;
  return hours;
}

function formatLegTime(time, system) {
  switch (system) {
    case 'Minute':
      return time;
      break;
    case 'HourMinute':
      time = Math.round(time);
      let hour = Math.floor(time / 60);
      let minute = time % 60;
      return hour + ':' + (minute > 9 ? minute : '0' + minute);
      break;
    default:
      return FormatNumber(time, 0, 1);
      break;
  }
  return time;
}

function LegTime_ToHours(time, system) {
  switch (system) {
    case 'Minute':
      return round10(time / 60);
      break;
    case 'HourMinute':
      return Math.round((time / 60) * 100) / 100;
      break;
    default:
      return round10(time);
      break;
  }
}

function getMinuteDifference(start, end) {
  start = formatTime(start);
  end = formatTime(end);
  let startDate = new Date('2000-01-01T' + start.substr(0, 2) + ':' + start.substr(2, 2) + ':00');
  let endDate = new Date('2000-01-01T' + end.substr(0, 2) + ':' + end.substr(2, 2) + ':00');
  let diff = (endDate.getTime() - startDate.getTime()) / 60000;
  if (diff < 0) diff += 1440;
  return diff;
}

function formatTime(time) {
  try {
    let str = time.toString();
    if (str.length == 1) {
      return '000' + str;
    }
    if (str.length == 2) {
      return '00' + str;
    }
    if (str.length == 3) {
      return '0' + str;
    }
    return str;
  } catch (e) {
    return time;
  }
}

function DecimalSystem2(start, end) {
  try {
    start = parseInt(start).toString();
  } catch (e) {
    start = '0000';
  }
  try {
    end = parseInt(end).toString();
  } catch (e) {
    end = '0000';
  }
  var H, H1, H2, M, M1, M2, DT;
  ////console.log(start);
  ////console.log(end);
  if (start.length < 3) {
    H1 = 0;
    M1 = parseInt(start);
  }
  if (end.length < 3) {
    H2 = 0;
    M2 = parseInt(end);
  }
  if (start.length == 3) {
    H1 = parseInt(start.substr(0, 1));
    M1 = parseInt(start.substr(1, 2));
  }
  if (end.length == 3) {
    H2 = parseInt(end.substr(0, 1));
    M2 = parseInt(end.substr(1, 2));
  }
  if (start.length == 4) {
    H1 = parseInt(start.substr(0, 2));
    M1 = parseInt(start.substr(2, 2));
  }
  if (end.length == 4) {
    H2 = parseInt(end.substr(0, 2));
    M2 = parseInt(end.substr(2, 2));
  }
  ////console.log("H2:"+H2 +" M2:"+M2);
  ////console.log("H1:"+H1 +" M1:"+M1);
  if ((H2 == 0 && M2 == 0 && H1 > 0) || (H2 == 0 && M2 == 0 && M1 > 0)) H2 = 24; //if old duty time ends at 0000 and start > 0
  H = H2 - H1;
  M = M2 - M1;
  ////console.log("M:"+M);
  if (M !== 0) {
    //M = Math.round(M/6)/10;
    if (M >= 60) {
      H++;
      M -= 60;
    }
    if (M < 0) {
      H--;
      M += 60;
    }
    ////console.log(M);
    if (M >= 7 && M <= 12) M = 0.2;
    if (M >= 13 && M <= 18) M = 0.3;
    if (M >= 19 && M <= 24) M = 0.4;
    if (M >= 25 && M <= 30) M = 0.5;
    if (M >= 31 && M <= 36) M = 0.6;
    if (M >= 37 && M <= 42) M = 0.7;
    if (M >= 43 && M <= 48) M = 0.8;
    if (M >= 49 && M <= 54) M = 0.9;
    ////console.log(M);
    if (H == 0) {
      if (M >= 1 && M <= 6) M = 0.1;
      if (M >= 55) {
        H++;
        M = 0;
      }
    } else {
      if (M >= 1 && M <= 6) {
        M = 0.1;
      } else if (M >= 55) {
        H++;
        M = 0;
      }
    }
    ////console.log(M);
    DT = H + M;
  } else DT = H;
  if (isNaN(DT)) {
    //console.log("H:" + H + " M:" + M);
    //console.log("H2:" + H2 + " M2:" + M2);
    //console.log("Length2: " + end.length);
    //console.log("H1:" + H1 + " M1:" + M1);
    //console.log("Length1: " + start.length);
    //console.log("start: " + start);
  }
  DT = Math.round(DT * 10) / 10;
  return DT;
}

function BILLING_FLIGHT_REPORT_HTML(data, showNonBillable, showCompanyOnly, blurb, header, icount, makingInvoice) {
  //var C = BILLING_CLIENTS[BILLING_CLIENT_INDEX];
  ////console.log(C);
  var Header = header || {
    CompanyInfo: '',
    SoldTo: '',
    Logo: '',
    Type: '',
  };
  var footer = blurb || '';
  var count = icount || '';
  var MakingInvoice = makingInvoice | false;
  var HideLegTimes = false;
  if ($('#BILLING_HIDELEGTOTALS').is(':checked') && MakingInvoice) HideLegTimes = true;
  //console.log(data);
  var J = data.Job;

  var reportnum = '{----}';
  var headerHTML = '';
  var companyInfoHTML = '';
  var soldToHTML = '';
  var legDetailsHTML = '';
  var clientJobHTML = '';
  var jobFlightDetailsHTML = '';
  var hobbsTimesHTML = '';
  var signatureHTML = '';
  var recportCfieldsHTML = '';

  if (data.PrimaryKey != 'None') reportnum = data.PrimaryKey;
  if (Header.Logo != '') {
    headerHTML = '<table class="limit-table">';
    headerHTML +=
      '<tr class="hideable"><td><img style="width:350px; height:auto;" src="data:' +
      Header.Type +
      ';base64,' +
      Header.Logo +
      '" /></td><td style="text-align:right; vertical-align:top; "><h3>' +
      iTrans('Daily Flight Report') +
      ' ' +
      reportnum +
      '<br />' +
      data.Date +
      '</h3></td></tr></table>';
  } else {
    headerHTML = '<table class="limit-table">';
    headerHTML +=
      '<tr class="hideable"><td></td><td style="text-align:right; vertical-align:top;"><h3>' +
      iTrans('Daily Flight Report') +
      ' ' +
      reportnum +
      '<br />' +
      data.Date +
      '</h3></td></tr></table>';
  }

  if (Header.CompanyInfo != '') {
    companyInfoHTML =
      '<table class="limit-table"><tr class="hideable"><td style="vertical-align:top;">' +
      (J.showAddress == 0 ? '' : Header.CompanyInfo) +
      '</td><td style="vertical-align:top;">' +
      (J.showClientDetails == 0 ? '' : '<b>' + iTrans('Sold To') + ':</b><br />' + Header.SoldTo) +
      '</td></tr></table>';
  }
  //Flight report legs in table
  var TimeUpLBL = GLOBAL_LABELS.LegTimes.LegStart;
  var TimeDownLBL = GLOBAL_LABELS.LegTimes.LegEnd;
  if (J.BlockTime == 1) {
    TimeUpLBL = GLOBAL_LABELS.LegTimes.LegStartE;
    TimeDownLBL = GLOBAL_LABELS.LegTimes.LegEndE;
  }
  var showAirBlock = J.BlockTime == 1 && J.showAirBlock == 1;

  if (showAirBlock) {
    legDetailsHTML +=
      '<br /><table style="border: 1px solid #afafaf;" class="first-td-fill wrap"><thead><tr class="hideable"><th style="width:250px; font-weight:bold;">' +
      iTrans('Leg Description') +
      '</th><th style="width:70px; font-weight:bold;">' +
      GLOBAL_LABELS.LegTimes.LegStartE +
      '</th><th style="width:70px; font-weight:bold;">' +
      GLOBAL_LABELS.LegTimes.LegStart +
      '</th><th style="width:70px; font-weight:bold;">' +
      GLOBAL_LABELS.LegTimes.LegEnd +
      '</th>' +
      '<th style="width:70px; font-weight:bold;">' +
      GLOBAL_LABELS.LegTimes.LegEndE +
      (J.showRIM == 1 ? '</th><th style="width:60px; font-weight:bold;">' + iTrans('RIM') : '') +
      '</th><th style="width:60px; font-weight:bold;">' +
      iTrans('FLT') +
      '</th><th style="width:60px; font-weight:bold;">' +
      iTrans('BLK') +
      '</th></tr></thead><tbody>';
  } else {
    legDetailsHTML +=
      '<br /><table style="border: 1px solid #afafaf;" class="wrap"><thead><tr class="hideable"><th style="width:270px; font-weight:bold;">' +
      iTrans('Leg Description') +
      '</th><th style="width:70px; font-weight:bold;">' +
      TimeUpLBL +
      '</th><th style="width:70px; font-weight:bold;">' +
      TimeDownLBL +
      '</th><th style="width:60px; font-weight:bold;">' +
      iTrans('Total') +
      '</th></tr></thead><tbody>';
  }

  var FT_Total = 0;
  var blkTotal = 0;
  var fltTotal = 0;
  var rimTotal = 0;
  var BillByVAL = 0;
  var paxTotalWeight = 0;
  var paxTotal = 0;
  var cargoTotalWeight = 0;
  var Unit = '';
  switch (J.BillByOPT) {
    case 'KM':
      Unit = '(km)';
      break;
    case 'NM':
      Unit = '(NM)';
      break;
    case 'SM':
      Unit = '(sm)';
      break;
    case 'LB':
      Unit = '(lb)';
      break;
    case 'KG':
      Unit = '(kg)';
      break;
  }
  var lastStart = '';
  var blkStart = null;
  for (var l in data.Legs) {
    if (showAirBlock) {
      legDetailsHTML +=
        '<tr class="hideable"><td style="border-top: thin solid #afafaf"></td><td></td><td></td><td></td><td></td><td></td><td></td></tr>';
    } else {
      legDetailsHTML +=
        '<tr class="hideable"><td style="border-top: thin solid #afafaf"></td><td></td><td></td><td></td></tr>';
    }

    var L = data.Legs[l];
    if ((showNonBillable && L.Billable == 0) || L.Billable == 1) {
      //show only billable legs or not
      //use this for future proffing
      //todo talked with Matt and Erika about this and they said it was fine continue doing not this yet
    }
    var LegDesc = 'Leg #' + (parseInt(l) + 1) + '<br />';
    if (L.PaxNum > 0) LegDesc += '(' + L.PaxNum + ' Pax) ';
    paxTotal += parseInt(L.PaxNum);
    var legPaxTotalWeight = 0;
    var paxSummary = '';
    if (data.Pax_Manifest != undefined) {
      if (data.Pax_Manifest.Pax_Legs != undefined) {
        if (data.Pax_Manifest.Pax_Legs[l] != undefined) {
          var PaxLeg = data.Pax_Manifest.Pax_Legs[l];
          if (PaxLeg.length > 0) {
            for (var p in PaxLeg) {
              let weightUnit = 'Imperial';
              if (PaxLeg[p].units != 'imperial') {
                weightUnit = 'Metric';
              }
              if (J.showPaxManLegs == 1) {
                let paxVIPclass = '';
                if (PaxLeg[p].VIP == 1) paxVIPclass = 'yellowbg';
                paxSummary +=
                  '<span class="' +
                  paxVIPclass +
                  '">' +
                  PaxLeg[p].lastname +
                  ', ' +
                  PaxLeg[p].firstname +
                  (J.showPaxWeights == 1
                    ? ' (' + PaxLeg[p].weight + (PaxLeg[p].units == 'imperial' ? ' lbs' : ' kg') + ')'
                    : '') +
                  '</span><br />';
              }
              legPaxTotalWeight += parseInt(convertToUserUNITS(PaxLeg[p].weight, 'Weight', weightUnit, 'Imperial'));
            }
            if (J.showPaxManLegs == 1) {
              LegDesc +=
                '<br /><b>' +
                iTrans('Pax Manifest') +
                ': ' +
                (J.showPaxWeights == 1 ? '(' + legPaxTotalWeight + ' lbs ' : '') +
                ' ' +
                iTrans('Total') +
                ')</b><br />';
              LegDesc += paxSummary;
            }
          }
        }
      }
    }
    if (J.showFuel == 1) {
      LegDesc += BILLING_get_FuelUpliftHTML(l, data);
    }
    if (J.showCargoWeights == 1) {
      LegDesc += '<p><b>' + iTrans('Cargo Weight: ') + ': (' + L.cargoWeight + ' lbs)' + '</b></p>';
      cargoTotalWeight += round10(L.cargoWeight);
    }
    paxTotalWeight += legPaxTotalWeight;
    if (L.Details_Up != '' || L.Details_Down != '')
      LegDesc += L.Details_Up + ' to ' + L.Details_Down + '<br />' + nl2br(L.Description);
    else LegDesc += nl2br(L.Description);
    var FT = 0;
    var fltTime = getDecimalTime(L.Time_Up, L.Time_Down, J.BillBy);
    if (L.Billable == 1) fltTotal += fltTime;
    fltTotal = round10(fltTotal);
    var blkTime = getDecimalTime(L.Time_Start, L.Time_Stop, J.BillBy);
    if (L.Billable == 1) blkTotal += blkTime;
    blkTotal = round10(blkTotal);
    var FT_TXT = 0;
    var rimTime = getDecimalTime(L.Time_Start, L.Time_Up, 'Minute');
    var hideStop = false;
    var hideStart = false;

    if (l > 0) {
      if (data.Legs[parseInt(l) - 1].noShutdown == 1) {
        hideStart = true;
        rimTime = 0;
      } else {
        blkStart = L.Time_Start;
      }
    } else {
      blkStart = L.Time_Start;
    }
    if (L.noShutdown == 1) {
      hideStop = true;
      if (data.Legs[parseInt(l) + 1] != undefined) {
        rimTime += getDecimalTime(L.Time_Down, data.Legs[parseInt(l) + 1].Time_Up, 'Minute');
      }
    } else {
      rimTime += getDecimalTime(L.Time_Down, L.Time_Stop, 'Minute');
      blkTime = getDecimalTime(blkStart, L.Time_Stop, J.BillBy);
      blkStart = L.Time_Start;
    }
    rimTotal += rimTime;
    rimTotal = round10(rimTotal);
    L.rimTime = rimTime;
    data.rimTotal = rimTotal;

    if (J.BlockTime == 1) {
      if (L.Time_Start != '') {
        lastStart = L.Time_Start;
        if (L.Time_Stop != '') {
          FT = getDecimalTime(L.Time_Start, L.Time_Stop, J.BillBy);
        }
      } else {
        if (L.Time_Stop != '' && lastStart != '') {
          FT = getDecimalTime(lastStart, L.Time_Stop, J.BillBy);
        }
      }

      FT = round10(FT);
      if (L.Time_Start == '' || L.Time_Stop == '') FT_TXT = '';
      else FT_TXT = formatLegTime(FT, J.BillBy);
    } else {
      if (L.Time_Up != '') {
        lastStart = L.Time_Up;
        if (L.Time_Down != '') {
          FT = getDecimalTime(L.Time_Up, L.Time_Down, J.BillBy);
        }
      } else {
        if (L.Time_Down != '' && lastStart != '') {
          FT = getDecimalTime(lastStart, L.Time_Down, J.BillBy);
        }
      }

      if (FT < 0) FT += 24;
      FT = round10(FT);
      if (L.Time_Up == '' || L.Time_Down == '') FT_TXT = '';
      else FT_TXT = formatLegTime(FT, J.BillBy);
    }
    if (L.Cfields != '') {
      for (var c in L.Cfields) {
        var C = L.Cfields[c];
        /*
         if (C.Aircraft != data.Aircraft && C.Aircraft !== '') {
         continue;
         }
         */
        //Hide all aircraft specific Cfields on main FR since they are for custom processing.
        if (C.Aircraft !== '' && C.Aircraft != undefined) {
          continue;
        }
        if (
            !checkCfieldType('FR', C) ||
          (C.hideDefault == 1 &&
            (C.Value == 0 || C.Value == C.default || C.Value == '' || (C.Value == 'false' && C.type == 'signature')))
        )
          continue;
        if ((C.ClientPK == 0 && showCompanyOnly) || C.ClientPK != 0) {
          //show only client custom fields per leg or not

          if (C.Bill_Item == 0) {
            if (C.type == 'checkbox') {
              var checked = 'no';
              if (C.Value == 1) checked = 'yes';
              LegDesc += '<br/>' + C.Label + ': ';
              LegDesc += checked;
            } else if (C.type == 'signature') {
              if (MakingInvoice) {
                if (
                  (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C.Value == undefined) ||
                  (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C.Value == '')
                )
                  continue;
              }
              LegDesc += '<p><b>' + C.Label + '</b>: ';
              try {
                var SigValue = JSON.parse(C.Value);
                if (SigValue.SignatureName != undefined) {
                  LegDesc +=
                    '<div style="display: inline-block;"><p class="wrap">' +
                    JSON.parse(C.options).SignatureDescription +
                    '</p>';
                  LegDesc += '<img src="' + SigValue.data + '" width="321px" height="150px"/><br />';
                  LegDesc += '<center><b>' + SigValue.SignatureName + '</b></center></div></p>';
                } else {
                  LegDesc += iTrans('Unsigned') + '</p>';
                }
              } catch (e) {
                LegDesc += iTrans('Unsigned') + '</p>';
              }
            } else {
              if (MakingInvoice) {
                if (
                  (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C.Value == 0) ||
                  (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C.Value == '')
                )
                  continue;
              }
              LegDesc += '<br/>' + C.Label + ': ';
              LegDesc += C.Value;
            }
          } else {
            //billable item
            var BIOL = JSON.parse(C.Bill_Item_options);
            if (C.type == 'checkbox') {
              if (C.Value == 0) {
                if (MakingInvoice) {
                  if (BILLING_INVOICE_DATA.HideEmptyCfields == 1) continue;
                }
                LegDesc += '<br/>' + C.Label + ': ';
                LegDesc += iTrans('Not Charged');
              } else {
                LegDesc += '<br/>' + C.Label + ': ';
                LegDesc += BIOL.Multiple.toLocaleString('en-US', CUR);
              }
            } else {
              if (L.Billable == 1 && !(BIOL.Unit == 'BillByVal' && J.BillBy == 'HourPR')) {
                var Total = 0;
                var BIOLUnit = '';
                var Multiple = parseFloat(BIOL.Multiple);
                if (BIOL.Unit == 'BillByVal') {
                  if (J.BillBy == 'Distance' || J.BillBy == 'Weight' || J.BillBy == 'Volume') {
                    Multiple = parseFloat(L.BillByVAL);
                    BIOLUnit = Unit;
                  }
                  if (J.BillBy != 'HourPL') {
                    Multiple = parseFloat(L.BillByVAL);
                    BIOLUnit = 'Leg Hr';
                  }
                  if (J.BillBy == 'HourPR') {
                    Multiple = 0;
                    BIOLUnit = 'ERROR';
                  }
                  if (J.BillBy == 'Hour' || J.BillBy == 'Hour2' || J.BillBy == 'Minute' || J.BillBy == 'HourMinute') {
                    Multiple = FT;
                    BIOLUnit = 'Leg Hr';
                  }
                }
                var iValue = parseFloat(C.Value);

                if (BIOL.Unit != undefined && BIOL.Unit != 'BillByVal') BIOLUnit = BIOL.Unit;
                if (isNaN(iValue)) iValue = 0;
                if (isNaN(Multiple)) Multiple = 0;
                Total = iValue * Multiple;
                Total = Math.round(Total * 100) / 100;
                if (MakingInvoice) {
                  if (
                    (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && Total == 0) ||
                    (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && Total == '')
                  )
                    continue;
                }
                if (BIOL.Unit == 'BillByVal') {
                  LegDesc += '<br/>' + C.Label + ': ';
                  LegDesc +=
                    round10(parseFloat(iValue)).toLocaleString('en-US', CUR) +
                    ' X ' +
                    Multiple +
                    ' ' +
                    iTrans(BIOLUnit) +
                    ' = <b>' +
                    Total.toLocaleString('en-US', CUR) +
                    '</b>';
                } else {
                  LegDesc += '<br/>' + C.Label + ': ';
                  LegDesc +=
                    iValue +
                    iTrans(BIOLUnit) +
                    ' @ ' +
                    Multiple.toLocaleString('en-US', CUR) +
                    ' = <b>' +
                    Total.toLocaleString('en-US', CUR) +
                    '</b>';
                }
              }
            }
          }
        }
      }
    }

    if (L.Billable == 1) {
      FT_Total += FT;
      BillByVAL += parseFloat(L.BillByVAL);
    } else LegDesc = '<b>' + iTrans('Non Billable Flight Leg') + ': </b>' + LegDesc;
    var TotalOther = '';
    if (J.BillBy == 'Distance' || J.BillBy == 'Weight' || J.BillBy == 'Volume')
      TotalOther = '<br />' + L.BillByVAL + ' ' + Unit;
    if (J.BillBy != 'HourPL' && J.BillBy != 'HourPR') {
      if (J.BlockTime == 1) {
        if (showAirBlock) {
          if (!HideLegTimes) {
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              (hideStart ? '----' : L.Time_Start) +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Up +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Down +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              (hideStop ? '----' : L.Time_Stop) +
              (J.showRIM == 1
                ? '</td><td style="width:60px; vertical-align: text-top;">' + formatLegTime(rimTime, J.BillBy)
                : '') +
              '</td><td style="width:60px; vertical-align: text-top;">' +
              formatLegTime(fltTime, J.BillBy) +
              '</td><td style="width:60px; vertical-align: text-top;">' +
              (hideStop ? '----' : formatLegTime(blkTime, J.BillBy)) +
              TotalOther +
              '</td></tr>';
          } else {
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td>' +
              (J.showRIM == 1 ? '<td style="width:70px; vertical-align: text-top;"></td>' : '') +
              '<td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">' +
              TotalOther +
              '</td></tr>';
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"> </td><td style="width:60px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">' +
              TotalOther +
              '</td></tr>';
          }
        } else {
          if (!HideLegTimes) {
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Start +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Stop +
              '</td><td style="width:60px; vertical-align: text-top;">' +
              FT_TXT +
              TotalOther +
              '</td></tr>';
          } else {
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">' +
              TotalOther +
              '</td></tr>';
          }
        }
      } else {
        if (!HideLegTimes)
          legDetailsHTML +=
            '<tr class="hideable"><td style="width:210px;">' +
            LegDesc +
            '</td><td style="width:70px; vertical-align: text-top;">' +
            L.Time_Up +
            '</td><td style="width:70px; vertical-align: text-top;">' +
            L.Time_Down +
            '</td><td style="width:60px; vertical-align: text-top;">' +
            FT_TXT +
            TotalOther +
            '</td></tr>';
        else
          legDetailsHTML +=
            '<tr class="hideable"><td style="width:210px;">' +
            LegDesc +
            '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">' +
            TotalOther +
            '</td></tr>';
      }
    } else {
      if (J.BillBy == 'HourPL') {
        if (J.BlockTime == 1) {
          if (!HideLegTimes)
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Start +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Stop +
              '</td><td style="width:60px; vertical-align: text-top;">' +
              FormatNumber(L.BillByVAL, 0, 1) +
              '</td></tr>';
          else
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">' +
              L.BillByVAL +
              '</td></tr>';
        } else {
          if (!HideLegTimes)
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Up +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Down +
              '</td><td style="width:60px; vertical-align: text-top;">' +
              FormatNumber(L.BillByVAL, 0, 1) +
              '</td></tr>';
          else
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">' +
              L.BillByVAL +
              '</td></tr>';
        }
      }
      if (J.BillBy == 'HourPR') {
        if (J.BlockTime == 1) {
          if (!HideLegTimes)
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Start +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Stop +
              '</td><td style="width:60px; vertical-align: text-top;">-</td></tr>';
          else
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">-</td></tr>';
        } else {
          if (!HideLegTimes)
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Up +
              '</td><td style="width:70px; vertical-align: text-top;">' +
              L.Time_Down +
              '</td><td style="width:60px; vertical-align: text-top;">-</td></tr>';
          else
            legDetailsHTML +=
              '<tr class="hideable"><td style="width:210px;">' +
              LegDesc +
              '</td><td style="width:70px; vertical-align: text-top;"></td><td style="width:70px; vertical-align: text-top;"></td><td style="width:60px; vertical-align: text-top;">-</td></tr>';
        }
      }
    }
  }
  if (data.Passengers < paxTotal) {
    data.Passengers = paxTotal;
  }
  BillByVAL = Math.round(BillByVAL * 10) / 10;
  if (data.Legs.length == 0)
    if (showAirBlock) {
      legDetailsHTML +=
        '<tr class="hideable"><td><h3>' +
        iTrans('No flights were conducted') +
        '</h3></td><td>----</td><td>----</td><td>----</td><td>----</td>' +
        (J.showRIM == 1 ? '<td>-</td>' : '') +
        '<td>-</td><td>-</td></tr>';
    } else {
      legDetailsHTML +=
        '<tr class="hideable"><td><h3>' +
        iTrans('No flights were conducted') +
        '</h3></td><td>----</td><td>----</td><td>-</td></tr>';
    }

  FT_Total = Math.round(FT_Total * 10) / 10;
  if (J.BillBy == undefined) J.BillBy = 'Hour'; //for legacy invoice data data
  if (J.BillByOPT == undefined) J.BillByOPT = ''; //for legacy invoice data data
  var BillByUnit = 'Hour';
  var BillByMult = 0;
  switch (J.BillBy) {
    case 'Hour':
    case 'Hour2':
    case 'Minute':
    case 'HourMinute':
      if (showAirBlock) {
        let fltTotal_Hrs = fltTotal;
        let blkTotal_Hrs = blkTotal;
        if (J.BillBy == 'Minute') {
          fltTotal_Hrs = round10(fltTotal / 60);
          blkTotal_Hrs = round10(blkTotal / 60);
          FT_Total = round10(blkTotal / 60);
          legDetailsHTML +=
            '<tr class="hideable"><td><b>' +
            iTrans('Billable Minutes') +
            '</b></td><td></td><td></td><td></td>' +
            (J.showRIM == 1 ? '<td></td>' : '') +
            '<td style="text-align:right; border-top: thin solid #afafaf"><b>' +
            iTrans('Total') +
            ' </b></td><td style="border-top: thin solid #afafaf">' +
            formatLegTime(fltTotal, J.BillBy) +
            ' </td><td style="border-top: thin solid #afafaf">' +
            formatLegTime(blkTotal, J.BillBy) +
            '</td></tr>';
        }
        if (J.BillBy != 'HourMinute') {
          legDetailsHTML +=
            '<tr class="hideable"><td><b>' +
            iTrans('Billable Hours') +
            '</b></td><td></td><td></td><td></td>' +
            (J.showRIM == 1 ? '<td></td>' : '') +
            '<td style="text-align:right; border-top: thin solid #afafaf"><b>' +
            iTrans('Total') +
            ' </b></td><td style="border-top: thin solid #afafaf">' +
            FormatNumber(fltTotal_Hrs, 0, 1) +
            ' </td><td style="border-top: thin solid #afafaf">' +
            FormatNumber(FT_Total, 0, 1) +
            '</td></tr>';
          BillByUnit = iTrans('Billable Flight Report Hours');
          BillByMult = FT_Total;
        } else {
          legDetailsHTML +=
            '<tr class="hideable"><td><b>' +
            iTrans('Billable Hours:Minutes') +
            '</b></td><td></td><td></td><td></td>' +
            (J.showRIM == 1 ? '<td></td>' : '') +
            '<td style="text-align:right; border-top: thin solid #afafaf"><b>' +
            iTrans('Total') +
            ' </b></td><td style="border-top: thin solid #afafaf">' +
            formatLegTime(fltTotal, J.BillBy) +
            ' </td><td style="border-top: thin solid #afafaf">' +
            formatLegTime(blkTotal, J.BillBy) +
            '</td></tr>';
          BillByUnit = iTrans('Billable Flight Report Hours:Minutes');
          BillByMult = FT_Total;
        }
      } else {
        if (J.BillBy != 'HourMinute') {
          legDetailsHTML +=
            '<tr class="hideable"><td><b>' +
            iTrans('Billable Hours') +
            '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
            iTrans('Total') +
            ' </b></td><td style="border-top: thin solid #afafaf">' +
            FormatNumber(FT_Total, 0, 1) +
            '</td></tr>';
          BillByUnit = iTrans('Billable Flight Report Hours');
          BillByMult = FT_Total;
        } else {
          legDetailsHTML +=
            '<tr class="hideable"><td><b>' +
            iTrans('Billable Hours') +
            '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
            iTrans('Total') +
            ' </b></td><td style="border-top: thin solid #afafaf">' +
            formatLegTime(FT_Total, J.BillBy) +
            '</td></tr>';
          BillByUnit = iTrans('Billable Flight Report Hours:Minutes');
          BillByMult = FT_Total;
        }
      }
      break;
    case 'HourPL':
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Billable Flight Report Hours') +
        '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
        iTrans('Total') +
        ' </b></td><td style="border-top: thin solid #afafaf">' +
        FormatNumber(BillByVAL, 0, 1) +
        '</td></tr>';
      FT_Total = BillByVAL;
      BillByUnit = iTrans('Billable Flight Report Hours');
      BillByMult = FT_Total;
      break;
    case 'HourPR':
      if (data.BillByVAL == undefined) data.BillByVAL = 0;
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Billable Hours') +
        '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
        iTrans('Total') +
        ' </b></td><td style="border-top: thin solid #afafaf">' +
        FormatNumber(data.BillByVAL, 0, 1) +
        '</td></tr>';
      FT_Total = Math.round(data.BillByVAL * 10) / 10;
      BillByUnit = 'Billable Hours';
      BillByMult = FT_Total;
      break;
    case 'Hobbs':
      if (!HideLegTimes)
        legDetailsHTML +=
          '<tr class="hideable"><td><b>' +
          iTrans('Total Leg Hours') +
          '</b></td><td></td><td></td><td>' +
          FT_Total +
          '</td></tr>';
      var H = data.HOBBS;
      if (J.BillByOPT == 'Primary' || J.BillByOPT == '') {
        if (H.Primary == undefined) H.Primary = BILLING_getHobbsDefault('Primary');
        legDetailsHTML +=
          '<tr class="hideable"><td><b>' +
          iTrans('Billable Hobbs Hours') +
          '</b></td><td style="border-top: thin solid #afafaf"><b>' +
          iTrans('Start') +
          '</b></td><td style="border-top: thin solid #afafaf"><b>' +
          iTrans('End') +
          '</b></td><td style="border-top: thin solid #afafaf"><b>' +
          iTrans('Total') +
          '</b></td></tr>';
        legDetailsHTML +=
          '<tr class="hideable"><td></td><td>' +
          H.Primary.PrevReading.Total +
          '</td><td>' +
          H.Primary.Reported.Total +
          '</td><td>' +
          round10(H.Primary.Reported.Total - H.Primary.PrevReading.Total) +
          '</td></tr>';
        BillByMult = round10(H.Primary.Reported.Total - H.Primary.PrevReading.Total);
      }
      if (J.BillByOPT == 'Secondary') {
        if (H.Secondary == undefined) H.Secondary = BILLING_getHobbsDefault('Secondary');
        legDetailsHTML +=
          '<tr class="hideable"><td><b>' +
          iTrans('Billable Hobbs Hours') +
          '</b></td><td style="border-top: thin solid #afafaf"><b>' +
          iTrans('Start') +
          '</b></td><td style="border-top: thin solid #afafaf"><b>' +
          iTrans('End') +
          '</b></td><td style="border-top: thin solid #afafaf"><b>' +
          iTrans('Total') +
          '</b></td></tr>';
        legDetailsHTML +=
          '<tr class="hideable"><td></td><td>' +
          H.Secondary.PrevReading.Total +
          '</td><td>' +
          H.Secondary.Reported.Total +
          '</td><td>' +
          round10(H.Secondary.Reported.Total - H.Secondary.PrevReading.Total) +
          '</td></tr>';
        BillByMult = round10(H.Secondary.Reported.Total - H.Secondary.PrevReading.Total);
      }
      BillByUnit = iTrans('Billable Hobbs Hours');
      break;
    case 'Distance':
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Total Leg Hours') +
        '</b></td><td></td><td></td><td>' +
        FT_Total +
        '</td></tr>';
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Billable Distance') +
        ' ' +
        Unit +
        '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
        iTrans('Total') +
        ' </b></td><td style="border-top: thin solid #afafaf">' +
        BillByVAL.toLocaleString('en-US') +
        '</td></tr>';
      BillByUnit = iTrans('Billable Distance');
      BillByMult = BillByVAL;
      break;
    case 'Weight':
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Total Leg Hours') +
        '</b></td><td></td><td></td><td>' +
        FT_Total +
        '</td></tr>';
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Billable Weight') +
        ' ' +
        Unit +
        '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
        iTrans('Total') +
        ' </b></td><td style="border-top: thin solid #afafaf">' +
        BillByVAL.toLocaleString('en-US') +
        '</td></tr>';
      BillByUnit = iTrans('Billable Weight');
      BillByMult = BillByVAL;
      break;
    case 'Volume':
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Total Leg Hours') +
        '</b></td><td></td><td></td><td>' +
        FT_Total +
        '</td></tr>';
      legDetailsHTML +=
        '<tr class="hideable"><td><b>' +
        iTrans('Billable Volume') +
        ' ' +
        Unit +
        '</b></td><td></td><td style="text-align:right; border-top: thin solid #afafaf"><b>' +
        iTrans('Total') +
        ' </b></td><td style="border-top: thin solid #afafaf">' +
        BillByVAL.toLocaleString('en-US') +
        '</td></tr>';
      BillByUnit = iTrans('Billable Weight');
      BillByMult = BillByVAL;
      break;
  }

  legDetailsHTML += '</tbody></table>';

  var TDG = iTrans('no');
  if (data.TDG == 1) {
    TDG = iTrans('Dangerous Goods Transported');
  }
  //create job summary in right hand column
  //job summary with per report custom fields
  clientJobHTML += '<table class="last-td-fill" style="margin-left: 5px;">';
  if (APP_ENVIRONMENT == 'local' || APP_ENVIRONMENT == 'staging' || GROUP_DATA.group == 'greatslave') {
    if (OPERATOR_LOOKUP[data.operatorPK] != undefined) {
      clientJobHTML +=
        '<tr class="hideable"><td style="vertical-align: top;"><b>' +
        iTrans('Operator') +
        '</b></td><td style="text-align:right;">' +
        OPERATOR_LOOKUP[data.operatorPK] +
        '</td></tr>';
    }
  }
  if (J.JobNum != undefined)
    clientJobHTML +=
      J.showJobNum == 0
        ? ''
        : '<tr class="hideable"><td><b>' +
          iTrans('Job') +
          '#</b></td><td style="text-align:right;">' +
          J.JobNum +
          '</td></tr>';
  clientJobHTML +=
    '<tr class="hideable"><td style="vertical-align: top;"><b>' +
    iTrans('Client') +
    '</b></td><td style="text-align:right;">' +
    J.Business +
    '</td></tr>';
  clientJobHTML +=
    '<tr class="hideable"><td style="vertical-align: top;"><b>' +
    iTrans('Job Name') +
    '</b></td><td style="text-align:right;">' +
    J.Name +
    '</td></tr>';
  clientJobHTML += '<tr class="hideable"><td style="vertical-align: top;"><b>';
  clientJobHTML += iTrans('Aircraft Ident') + '</b></td><td style="text-align:right;">' + data.Aircraft + '</td></tr>';
  if (J.showFuel == 1) {
    var initFuel = BILLING_get_FuelUpliftHTML(-1);
    clientJobHTML +=
      '<tr class="hideable"><td style="vertical-align: top;"><b>' +
      iTrans('Fuel Loaded') +
      '</b></td><td style="text-align:right;">' +
      initFuel +
      '</td></tr>';
  }
  var acType = ALLAC_data.filter((obj) => {
    return obj.ident === data.Aircraft;
  });
  if (acType.length > 0) {
    clientJobHTML +=
      '<tr class="hideable"><td style="white-space: nowrap;"><b>' +
      iTrans('Aircraft Type') +
      '</b></td><td style="text-align:right;">' +
      acType[0].AcType +
      '</td></tr>';
  }
  clientJobHTML +=
    J.showTZ == 0
      ? ''
      : '<tr class="hideable"><td style="white-space: nowrap;"><b>' +
        iTrans('Timezone') +
        '</b></td><td style="text-align:right;">' +
        data.EntryTimezone +
        '</td></tr>';
  if (data.FuelProvided)
    clientJobHTML +=
      J.showClientFuel == 0
        ? ''
        : '<tr class="hideable"><td style="white-space: nowrap;"><b>' +
          iTrans('Fuel') +
          '</b></td><td style="text-align:right;">' +
          iTrans('Provided by Client') +
          '</td></tr>';
  clientJobHTML +=
    J.showTDG == 0 ? '' : '<tr class="hideable"><td><b>TDG</b></td><td style="text-align:right;">' + TDG + '</td></tr>';
  clientJobHTML += '</table>';
  if (J.showJobDetails == 1) {
    jobFlightDetailsHTML += '<p><b>' + iTrans('Job Details') + '</b><br />';
    jobFlightDetailsHTML += nl2br(J.Description) + '</p>';
  }
  if (J.showDetails == 1) {
    jobFlightDetailsHTML += '<p><b>' + iTrans('Flight Details') + '</b><br />';
    jobFlightDetailsHTML += nl2br(data.Details) + '</p>';
  }
  jobFlightDetailsHTML += '<p><b>' + iTrans('Crew') + '</b><br />';
  jobFlightDetailsHTML += '<b>PIC: </b>' + data.Pilot + '<br />';

  if (data.SIC != '') jobFlightDetailsHTML += '<b>SIC: </b>' + data.SIC + '</p>';
  if (data.Crew != '') {
    jobFlightDetailsHTML += '<b>' + iTrans('Other Crew') + '</b><br />';

    var CrewList = data.Crew;
    for (var c in CrewList) {
      var C = CrewList[c];
      jobFlightDetailsHTML += '<b>' + C.staffType + ':</b> ' + C.chatname + '<br />';
    }
  }
  if (data.Job.showCargoWeights == 1) {
    jobFlightDetailsHTML += '<p><b>' + iTrans('Total Cargo Weight') + ': ' + cargoTotalWeight + ' lbs</b></p>';
  }
  if (data.Job.showPaxWeights == 1) {
    jobFlightDetailsHTML += '<p><b>' + iTrans('Total Pax Weight') + ': ' + paxTotalWeight + ' lbs</b></p>';
  }
  jobFlightDetailsHTML += '<p><b>' + iTrans('Total Passengers') + ': ' + data.Passengers + '</b></p>';
  if (data.Pax_Manifest != undefined) {
    if (data.Pax_Manifest.DefaultList.length != 0 && data.Job.showPaxManReport == 1) {
      var PaxReport = data.Pax_Manifest.DefaultList;
      if (PaxReport.length > 0) {
        jobFlightDetailsHTML += '<b>' + iTrans('Pax Manifest') + ':</b><br />';
        for (var p in PaxReport) {
          let paxVIPclass = '';
          if (PaxReport[p].VIP == 1) paxVIPclass = 'yellowbg';
          jobFlightDetailsHTML +=
            '<span class="' +
            paxVIPclass +
            '">' +
            PaxReport[p].lastname +
            ', ' +
            PaxReport[p].firstname +
            (data.Job.showPaxWeights == 1
              ? '(' + PaxReport[p].weight + (PaxReport[p].units == 'imperial' ? ' lbs' : ' kg') + ')'
              : '') +
            '</span><br />';
        }
      }
    }
  }

  if (HOBBS == 1 && data.HOBBS != undefined) {
    if (J.P_Hobbs == 1) {
      hobbsTimesHTML +=
        '<p><b>' +
        iTrans('Flight Hobbs') +
        ' -</b> ' +
        iTrans('Start') +
        ': ' +
        data.HOBBS.Primary.PrevReading.Total +
        ', ' +
        iTrans('End') +
        ': ' +
        data.HOBBS.Primary.Reported.Total +
        '</p><br />';
    }
    if (J.S_Hobbs == 1) {
      hobbsTimesHTML +=
        '<p><b>' +
        iTrans('Maint Hobbs') +
        ' -</b> ' +
        iTrans('Start') +
        ': ' +
        data.HOBBS.Secondary.PrevReading.Total +
        ', ' +
        iTrans('End') +
        ': ' +
        data.HOBBS.Secondary.Reported.Total +
        '</p><br />';
    }
  }

  if (data.signature != '' || signatureDATA.data != '') {
    if (signatureDATA.data != '') {
      data.signature = signatureDATA.data;
      data.signed_by = signatureDATA.text;
    }
    signatureHTML +=
      '<br /><center><b>' +
      iTrans('Flight Report Signed By') +
      '</b></center><br /><img src="' +
      data.signature +
      '" width="321px" height="150px"/><br />';
    signatureHTML += '<b><center>' + data.signed_by + '</center></b>';
  } else {
    signatureHTML +=
      J.SignatureReq == 0
        ? '<br /><center><b>*** ' + iTrans('Signature Not Required') + ' ***</b></center>'
        : '<br /><center><b>*** ' + iTrans('Flight Report Unsigned') + ' ***</b></center>';
  }
  data.TotalPaxWeight = paxTotalWeight;
  recportCfieldsHTML = GetCustomFieldHTML(
    J,
    data.Cfields,
    showCompanyOnly,
    MakingInvoice,
    BillByMult,
    BillByUnit,
    data.Aircraft
  );
  //end report
  if (GROUP_DATA.FR_Template == undefined) {
    GROUP_DATA.FR_Template = 0;
  }
  var template = GROUP_DATA.FR_Template;
  if (showAirBlock) {
    template = 2;
  }
  if (GROUP_DATA.FR_Template == 3) {
    template = 3;
  }
  // Apply template layouts here and put the pieces together.
  var html = '<page contenteditable="false"><flightreport>';
  switch (parseInt(template)) {
    case 0:
      html += headerHTML;
      html += '<table class="limit-table"><tr class="hideable"><td style="vertical-align:top; width:477px;">';
      html += companyInfoHTML;
      html += legDetailsHTML;
      html += '</td><td style="vertical-align:top;">';
      html += clientJobHTML;
      html += jobFlightDetailsHTML;
      html += hobbsTimesHTML;
      html += recportCfieldsHTML;
      html += signatureHTML;
      html += '</td></tr></table><br /><div style="text-align:center;">' + footer + '</div>';
      break;
    case 1:
      html += headerHTML;
      html += '<table class="limit-table"><tr class="hideable"><td style="vertical-align:top; width:477px;">';
      html += companyInfoHTML;
      html += legDetailsHTML;
      html += recportCfieldsHTML;
      html += '</td><td style="vertical-align:top;">';
      html += clientJobHTML;
      html += jobFlightDetailsHTML;
      html += hobbsTimesHTML;
      html += signatureHTML;
      html += '</td></tr></table><br /><div style="text-align:center;">' + footer + '</div>';
      break;
    case 2:
      html += headerHTML;
      html += '<table class="limit-table"><tr class="hideable"><td style="vertical-align:top; width:477px;">';
      html += companyInfoHTML;
      html += '</td><td style="vertical-align:top;">';
      html += clientJobHTML;
      html += '</td></tr></table>';
      html += legDetailsHTML;
      html += '<table class="limit-table"><tr class="hideable"><td style="vertical-align:top; width:300px;">';
      html += jobFlightDetailsHTML;
      html += hobbsTimesHTML;
      html += '</td><td style="vertical-align:top;width:200px;">';
      html += recportCfieldsHTML;
      html += '</td><td style="vertical-align:bottom;">';
      html += signatureHTML;
      html += '</td></tr></table><br /><div style="text-align:center;">' + footer + '</div>';
      break;
    case 3:
      $('#BILLING_FLIGHT_REPORT_REVIEW').removeClass('limit-table');
      var AC_DATA = ALLAC_data.find(({ ident }) => ident === data.Aircraft);
      data.AcType = AC_DATA.AcType;
      var D = {
        data: data,
        header: header,
        acData: AC_DATA,
      };
      console.log(D);
      return BILLING_Gen_OilGas_FR_Page(D);
      +'</flightreport></page>';
      break;
  }

  html += '</flightreport></page><div id="BILLING_FR_Script-' + data.PrimaryKey + '"></div>';
  BILLING_Append_FlightReport(data);
  return html;
}

function GetCustomFieldHTML(J, R, showCompanyOnly, MakingInvoice, BillByMult, BillByUnit, Aircraft) {
  var Custom = '';
  if (R != '' && R != undefined) {
    for (var c1 in R) {
      var C1 = R[c1];
      if (C1.Aircraft != Aircraft && C1.Aircraft !== '' && C1.Aircraft != undefined) {
        continue;
      }
      if (
          !checkCfieldType('FR', C1) ||
        (C1.hideDefault == 1 &&
          (C1.Value == 0 ||
            C1.Value == C1.default ||
            C1.Value == '' ||
            (C1.Value == 'false' && C1.type == 'signature')))
      )
        continue;
      if ((C1.ClientPK == 0 && showCompanyOnly) || C1.ClientPK != 0) {
        //show only client custom fields per leg or not

        if (C1.Bill_Item == 1) {
          var BIO = JSON.parse(C1.Bill_Item_options);
        }
        if (C1.type == 'checkbox') {
          var checked = iTrans('no');
          if (C1.Value == 1) {
            checked = iTrans('yes');
            if (C1.Bill_Item == 1) {
              if (MakingInvoice) {
                if (
                  (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && BIO.Multiple == 0) ||
                  (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && BIO.Multiple == '')
                )
                  continue;
              }
              checked = BIO.Multiple.toLocaleString('en-US', CUR);
              Custom += '<p><b>' + C1.Label + '</b>: ' + checked;
            } else {
              Custom += '<p><b>' + C1.Label + '</b>: ' + checked;
            }
          } else {
            if (C1.Bill_Item == 1) {
              if (MakingInvoice) {
                if (BILLING_INVOICE_DATA.HideEmptyCfields == 1) continue;
              }
              checked = iTrans('Not Charged');
              Custom += '<p><b>' + C1.Label + '</b>: ' + checked;
            } else {
              checked = iTrans('No');
              Custom += '<p><b>' + C1.Label + '</b>: ' + checked;
            }
          }
        } else if (C1.type == 'signature') {
          if (MakingInvoice) {
            if (
              (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C1.Value == undefined) ||
              (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C1.Value == '')
            )
              continue;
          }
          Custom += '<p><b>' + C1.Label + '</b>: ';
          try {
            var SigValue = JSON.parse(C1.Value);
            if (SigValue.SignatureName != undefined) {
              Custom +=
                '<div style="display: inline-block;"><p class="wrap">' +
                JSON.parse(C1.options).SignatureDescription +
                '</p>';
              Custom += '<img src="' + SigValue.data + '" width="321px" height="150px"/><br />';
              Custom += '<center><b>' + SigValue.SignatureName + '</b></center></div></p>';
            } else {
              Custom += 'Unsigned</p>';
            }
          } catch (e) {
            Custom += 'Unsigned</p>';
          }
        } else {
          if (C1.Bill_Item == 1) {
            var Total = 0;
            var Multiple = parseFloat(BIO.Multiple);
            console.log('CustomBillable');
            console.log(BIO);
            console.log(J.BillBy);
            if (BIO.Unit == 'BillByVal') Multiple = BillByMult;
            var iValue = parseFloat(C1.Value);
            if (isNaN(iValue)) iValue = 0;
            if (isNaN(Multiple)) Multiple = 0;
            Total = iValue * Multiple;
            Total = Math.round(Total * 100) / 100;
            if (MakingInvoice) {
              if (
                (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && Total == 0) ||
                (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && Total == '')
              )
                continue;
            }
            var BIOUnit = '';
            if (BIO.Unit != undefined) BIOUnit = BIO.Unit;

            Custom += '<p><b>' + C1.Label + '</b>: ';

            if (BIO.Unit == 'BillByVal') {
              BIOUnit = BillByUnit;
              Custom +=
                round10(parseFloat(C1.Value)).toLocaleString('en-US', CUR) +
                ' X ' +
                Multiple +
                ' ' +
                iTrans(BIOUnit) +
                ' = <b>' +
                Total.toLocaleString('en-US', CUR) +
                '</b>';
            } else {
              Custom += C1.Value;
              Custom +=
                iTrans(BIOUnit) +
                ' @ ' +
                Multiple.toLocaleString('en-US', CUR) +
                ' = <b>' +
                Total.toLocaleString('en-US', CUR) +
                '</b>';
            }
          } else {
            if (MakingInvoice) {
              if (
                (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C1.Value == 0) ||
                (BILLING_INVOICE_DATA.HideEmptyCfields == 1 && C1.Value == '')
              )
                continue;
            }
            Custom += '<p><b>' + C1.Label + '</b>: ';
            Custom += C1.Value;
          }
        }

        Custom += '</p>';
      }
    }
  }
  return Custom;
}

function validateEmail(email) {
  if (typeof email != 'string') return false;
  email = email.trim();
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/********************************** Start Custom Code *******************************/
function BILLING_LoadScripts() {
  BILLING_SCRIPTS_LOADED = true;
  BillingDB('SELECT', 'Scripts', null, function (Items) {
    for (var i in Items) {
      var I = Items[i];
      ('use strict');
      var script = document.createElement('script');
      script.innerHTML = 'function BILLING_Script_' + I.type + '(data){' + I.script + '}';
      document.body.appendChild(script);
      console.log('Loaded ' + I.name + ' Billing Script');
    }
  });
}
function BILLING_Append_FlightReport(data) {
  if (typeof BILLING_Script_FlightReport === 'function') {
    window['BILLING_Script_FlightReport'](data);
  }
}

function BILLING_OnEmail_FlightReport(data) {
  if (typeof BILLING_Script_EmailFlightReport === 'function') {
    window['BILLING_Script_EmailFlightReport'](data);
  }
}

function PDF_CustomDesc() {
  GENERATE_PDF.filename = 'CustomDescForm';
  GENERATE_PDF.showPDF = 1;
  GENERATE_PDF.subject = '';
  GENERATE_PDF.email = '';
  GENERATE_PDF.div = 'BILLING_DescrepenciesHLDR';
  GENERATE_PDF.ClientEmail = '0';
  GeneratePDF();
}

function BILLING_get_FuelUpliftHTML(legIndex, CFR = null) {
  if (CFR != null) CURRENT_FLIGHT_REPORT = CFR;
  if (CURRENT_FLIGHT_REPORT.FC_Uplifts == null) {
    if (legIndex == -1) return iTrans('None');
    return '';
  }
  if (CURRENT_FLIGHT_REPORT.FC_Uplifts[legIndex] != undefined) {
    var FC = CURRENT_FLIGHT_REPORT.FC_Uplifts[legIndex];

    var units = getFCUnitText(FC.Units);

    var html = '<p><b>' + iTrans('Fuel') + ':</b> ' + FC.location_name + ' [';
    html +=
      parseFloat(FC.Amount).toLocaleString('en-US') +
      ' ' +
      units +
      '] ' +
      (FC.convertTo == ''
        ? ''
        : ' -> [' +
          convertFC_ToUnits(FC.FuelType, FC.Amount, FC.Units, FC.convertTo) +
          ' ' +
          getFCUnitText(FC.convertTo) +
          ']') +
      (FC.comment == '' ? '' : '<br />' + FC.comment) +
      '</p>';
    if (legIndex == -1) {
      html = FC.location_name + ' [';
      html +=
        parseFloat(FC.Amount).toLocaleString('en-US') +
        ' ' +
        units +
        '] ' +
        (FC.convertTo == ''
          ? ''
          : ' -> [' +
            convertFC_ToUnits(FC.FuelType, FC.Amount, FC.Units, FC.convertTo) +
            ' ' +
            getFCUnitText(FC.convertTo) +
            ']') +
        (FC.comment == '' ? '' : '<br />' + FC.comment);
    }
    return html;
  } else {
    if (legIndex == -1) return iTrans('None');
    return '';
  }
}
function BILLING_Gen_OilGas_FR_Page(data) {
  var header = data.header;
  var FR = data.data;
  var acGraph = data.acData.rawData.WB_Data.Graph[0];
  var CfieldValues = BILLING_Script_OilGasCFields(FR.Cfields);
  //FR.Job.showRIM = 1;
  //console.log('acGraph', acGraph);
  $('#CustomDescFromName').html('Company Name' + ' Custom Form');
  var Legs = FR.Legs;
  var StartBase = '';
  var EndBase = '';
  var Starts = 0;
  var Minutes = 0;
  var totalCargoWeight = 0;
  var offshoreHours = 0;
  var inLA = 0;
  var outLA = 0;
  if (Legs.length > 0) {
    StartBase = Legs[0].Details_Up;
    EndBase = Legs[Legs.length - 1].Details_Down;
    for (var i in Legs) {
      var L = Legs[i];
      if (L.noShutdown == 0) Starts++;
      Minutes += getMinuteDifference(L.Time_Start, L.Time_Stop);
      totalCargoWeight += parseFloat(L.cargoWeight);
    }
  }
  var Hours = round10(Minutes / 60);
  totalCargoWeight = round10(totalCargoWeight);
  var crewWeight = 0;

  var PIC = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === FR.Pilot_PK);
  var PIC_unionNumber = '';
  var PIC_Name = FR.Pilot;
  if (PIC != undefined) {
    if (PIC.bodyWeight != '') {
      crewWeight = PIC.bodyWeight;
    }
    PIC_unionNumber = PIC.unionNumber;
  }
  var SIC = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === FR.SIC_PK);
  var SIC_unionNumber = '';
  var SIC_Name = FR.SIC;
  if (SIC != undefined) {
    if (SIC.bodyWeight != '') {
      crewWeight += SIC.bodyWeight;
    }
    SIC_unionNumber = SIC.unionNumber;
  }
  var emptyWeight = parseFloat(data.acData.Weight);
  if (data.data.WB[0] != undefined) {
    emptyWeight = parseFloat(data.data.WB[0].Eweight);
  }
  emptyWeight = Math.round(emptyWeight);
  crewWeight = Math.round(crewWeight);
  var opsWeight = Math.round(parseFloat(emptyWeight) + crewWeight);
  var maxGross = parseFloat(acGraph.maxweight);

  var usefulLoad = Math.round(maxGross - opsWeight);
  for (var c in FR.Cfields) {
    var C = FR.Cfields[c];
  }
  var html =
    '<page contenteditable="false"><table class="limit-table-landscape" style="border-collapse: collapse;border: thin solid black;">';
  html += '<tr><td>';
  html += `<table class="last-td-fill" style="border-collapse: collapse;">
  <tr class="hideable">
    <td>
      <img src="data:${header.type};base64,${header.Logo}" style="width:100px;"/>
    </td>
    <td style="width:100%; text-align:center; vertical-align:middle;">
      <h1>${GROUP_DATA.Company_Name}</h1>
      </td>
    <td>
      <div style="width:100px;"></div>
    </td>
  </tr>
</table>`;

  html += '</td></tr><tr><td>';

  // Flight Info
  html +=
    '<table class="limit-table-landscape tableboarders" style="border-collapse: collapse;"><tbody><tr style="background-color: lightgrey;font-weight: bold;"><td>DATE</td><td>TAIL NUMBER</td><td>MODEL</td><td>CUSTOMER</td><td>CONTRACT</td><td>RPT #</td></tr>';
  html +=
    '<tr style="background-color: white;"><td>' +
    FR.Date +
    '</td><td>' +
    FR.Aircraft +
    '</td><td>' +
    FR.AcType +
    '</td><td>' +
    FR.Job.Business +
    '</td><td>' +
    FR.Job.Name +
    '</td><td>' +
    FR.PrimaryKey +
    '</td></tr>';
  html += '</tbody></table></td></tr><tr><td>';

  html +=
    '<table class="limit-table-landscape" style="border-collapse: collapse;"><tr><td style="vertical-align: top;width:100%;">';

  html +=
    '<table class="limit-table-landscape tableboarders" style="border-collapse: collapse;"><tbody><tr style="background-color: lightgrey;font-weight: bold;"><td>DUTY</td><td>EMP NBR</td><td>NAME</td><td>FLIGHT CODE</td></tr>';
  html +=
    '<tr style="background-color: white;"><td>PIC</td><td>' +
    PIC_unionNumber +
    '</td><td>' +
    PIC_Name +
    '</td><td>' +
    CfieldValues.FlightCode +
    '</td></tr>';
  if (FR.SIC_PK != 0) {
    html +=
      '<tr style="background-color: white;"><td>SIC</td><td>' +
      SIC_unionNumber +
      '</td><td>' +
      SIC_Name +
      '</td><td></td></tr>';
  }
  if (CfieldValues.AltCrewName != '') {
    html +=
      '<tr style="background-color: white;"><td>' +
      CfieldValues.AltCrewPosition +
      '</td><td></td><td>' +
      CfieldValues.AltCrewName +
      '</td><td></td></tr>';
  }
  for (var c in FR.Crew) {
    var C = FR.Crew[c];
    var cData = STAFF_LIST.find(({ PrimaryKey }) => PrimaryKey === C.PrimaryKey);
    var cWeight = 0;
    var unionNumber = '';
    if (cData != undefined) {
      cWeight = cData.bodyWeight;
      crewWeight += cWeight;
      unionNumber = cData.unionNumber;
    }
    html +=
      '<tr style="background-color: white;"><td>CREW</td><td>' +
      unionNumber +
      '</td><td>' +
      C.last_name +
      ', ' +
      C.first_name +
      '</td><td></td></tr>';
  }

  html += '</tbody></table>';

  html += '</td><td style="vertical-align: top;">';

  html +=
    '<table class="limit-table-landscape tableboarders" style="border-collapse: collapse;max-width:250px;""><tr style="background-color: white;"><td style="background-color: lightgrey;font-weight: bold;">BASIC WT</td><td>' +
    emptyWeight +
    '</td><td style="background-color: lightgrey;font-weight: bold;">MAX GROSS</td><td>' +
    maxGross +
    '</td></tr>';
  html +=
    '<tr style="background-color: white;"><td style="background-color: lightgrey;font-weight: bold;">CREW WT</td><td>' +
    crewWeight +
    '</td><td style="background-color: lightgrey;font-weight: bold;">- OPER WT</td><td>' +
    opsWeight +
    '</td></tr>';
  html +=
    '<tr style="background-color: white;"><td style="background-color: lightgrey;font-weight: bold;">OPER WT</td><td>' +
    opsWeight +
    '</td><td style="background-color: lightgrey;font-weight: bold;">USEFUL LOAD</td><td>' +
    usefulLoad +
    '</td></tr></table></td>';

  html +=
    '<td class="tableboarders" style="width:100px;background-color: lightgrey;vertical-align: top;"><div style="text-align: center;font-weight: bold;">TOTAL TIME</div><table style="border-collapse: collapse; min-width:110px;"><tr class="tabletoarders" style="background-color: white;"><td style="background-color: lightgrey;font-weight: bold;">MINUTES</td><td>' +
    Minutes +
    '</td></tr>' +
    '<tr class="tableboarders" style="background-color: white;"><td style="background-color: lightgrey;font-weight: bold;">HRS/TENTHS</td><td>' +
    Hours.toFixed(1) +
    '</td></tr></table></td></tr>';

  html += '</td></tr></table>';

  html += '</td></tr>';

  html += '<tr><td>';

  var totalPassengers = 0;
  for (var i in FR.Pax_Manifest.Pax_Legs) {
    var legPaxs = FR.Pax_Manifest.Pax_Legs[i];
    totalPassengers += legPaxs.length;
  }
  //start end base
  var Starts2 = '';
  if (data.acData.List_Type == 'S-76 Series') {
    Starts2 = Starts;
  }
  var inLA = parseFloat(CfieldValues.InLA);
  if (isNaN(inLA)) inLA = 0;
  var outOfLA = parseFloat(CfieldValues.OutOfLA);
  if (isNaN(outOfLA)) outOfLA = 0;
  html +=
    '<table class="limit-table-landscape tableboarders" style="border-collapse: collapse;"><tr style="background-color: lightgrey;font-weight: bold;"><td>START BASE</td><td>END BASE</td><td>ENG 1 ST </td><td>ENG 2 ST</td><td>FLIGHTS</td><td>PAX#</td><td>PAX WT</td><td>CARGO</td><td>IN LA</td><td>OUT OF LA</td><td>OFFSHORE</td></tr>';
  html +=
    '<tr style="background-color: white;"><td style="width:200px;">' +
    StartBase +
    '</td><td style="width:200px">' +
    EndBase +
    '</td><td style="width:80px;">' +
    Starts +
    '</td><td style="width:80px;">' +
    Starts2 +
    '</td><td style="width:80px;">' +
    Legs.length +
    '</td><td style="width:80px;">' +
    totalPassengers +
    '</td><td style="width:80px;">' +
    FR.TotalPaxWeight +
    '</td><td style="width:80px;">' +
    parseFloat(totalCargoWeight).toFixed(0) +
    '</td><td>' +
    inLA.toFixed(1) +
    '</td><td>' +
    outOfLA.toFixed(1) +
    '</td><td>' +
    round10(Hours - inLA - outOfLA).toFixed(1) +
    '</td></tr>';
  html += '</table>';
  html += '</td></tr><tr><td>';

  //Remarks
  html +=
    '<table class="limit-table-landscape" style="border-collapse: collapse;"><tr style="background-color: white;"><td style="width:60px;font-weight: bold;">Remarks:</td><td style="white-space: normal !important; text-align: left;">' +
    FR.Details +
    '</td></tr></table>';

  //Legs
  html += '</td></tr><tr><td>';
  for (var i in Legs) {
    var L = Legs[i];
    var newStart = true;
    var prevLeg = null;
    if (i > 0) {
      prevLeg = Legs[parseInt(i) - 1];
      if (Legs[parseInt(i) - 1].noShutdown == 1) {
        newStart = false;
      }
    }
    var nextLeg = null;
    if (Legs[parseInt(i) + 1] != undefined) {
      nextLeg = Legs[parseInt(i) + 1];
    }
    html += BILLING_Gen_OilGas_FR_Legs(
      L,
      prevLeg,
      nextLeg,
      i,
      FR.WB,
      FR.Pax_Manifest.Pax_Legs,
      FR.FC_Uplifts,
      data.acData,
      FR.Job,
      newStart,
      opsWeight
    );
  }

  html += '</td></tr>';

  //Finish Big Table
  html += '</table></page>';

  return html;
}
function BILLING_Gen_OilGas_FR_Legs(
  L,
  prevLeg,
  nextLeg,
  index,
  legWB,
  paxMan,
  FC_Uplifts,
  AC,
  Job,
  newStart,
  opsWeight
) {
  var legNum = parseInt(index) + 1;
  var timeOff = L.Time_Start;
  var timeOn = L.Time_Stop;
  var rimTime = getMinuteDifference(L.Time_Start, L.Time_Up) + getMinuteDifference(L.Time_Down, L.Time_Stop);
  if (L.noShutdown == 1 && nextLeg != null) {
    timeOn = nextLeg.Time_Up;
    rimTime = getMinuteDifference(L.Time_Start, L.Time_Up) + getMinuteDifference(L.Time_Down, nextLeg.Time_Up);
  }
  if (prevLeg != null) {
    if (prevLeg.noShutdown == 1) {
      timeOff = L.Time_Up;
      rimTime = getMinuteDifference(L.Time_Down, L.Time_Stop);
    }
  }
  var Time = getMinuteDifference(timeOff, timeOn);

  if (Job.showRIM == 0) {
    rimTime = null;
  }

  var Uplifts = '';
  if (FC_Uplifts != null) {
    if (FC_Uplifts[index] != undefined) {
      var FC = FC_Uplifts[index];
      Uplifts = convertFC_ToUnits(FC.FuelType, FC.Amount, FC.Units, 'usgallons');
    }
  }
  var fuel = '';
  var towt = '';
  var fwd = '';
  var aft = '';
  var cg = '';
  if (legWB[index] != undefined) {
    var WB = legWB[index];
    fuel = parseFloat(WB.fuelwt).toFixed(0);
    var Summary = WB.Summary;
    var Items = Summary.split(',');
    var showLimits = false;
    for (var i in Items) {
      var I = Items[i].split(':');
      var I_position = I[0];
      var I_weight = I[1];
      if (I_position != 'Pilot') {
        if (I_weight > 0) {
          showLimits = true;
        }
      }
    }
    if (showLimits) {
      towt = WB.acwt;
      var limits = GetWB_FwdAft(AC.rawData.WB_Data.Graph, towt, AC.SN, 'Standard');
      fwd = parseFloat(limits.fwdlimit).toFixed(1);
      aft = parseFloat(limits.aftlimit).toFixed(1);
      cg = parseFloat(WB.cofglong).toFixed(1);
    }
  }

  /*
   var limits = GetWB_FwdAft(AC.rawData.WB_Data.Graph, 3400, AC.SN, 'Standard');
   fwd = limits.fwdlimit;
   cg = 120.1;
   aft = limits.aftlimit;
   fuel = 628;
   towt = 3400;
   */
  L.passengerWeight = BILLING_getPaxLegWeight(paxMan, index);
  var TOweight = parseFloat(opsWeight) + parseFloat(fuel) + parseFloat(L.passengerWeight) + parseFloat(L.cargoWeight);
  var LegHTML = `<table class="limit-table-landscape" style="border-collapse: collapse;">
      <tr style="background-color: lightgrey;font-weight: bold;">
        <td>FLT#</td>
        <td>FROM</td>
        <td>TO</td>
        <td>OFF</td>
        <td>ON</td>
        <td>TIME</td>
        ${rimTime !== null ? '<td>RIM</td>' : ''}
        <td>PAX#</td>
        <td>PAX WT</td>
        <td>CARGO</td>
        <td>FUEL</td>
        <td>T/O WT</td>
        <td>FWD</td>
        <td>CG</td>
        <td>AFT</td>
        <td>UPLIFT</td>
       </tr>`;

  LegHTML += `<tr style="background-color: white;">
                <td style="width:30px;">${legNum}</td>
                <td style="width:150px;">${L.Details_Up}</td>
                <td style="width:150px;">${L.Details_Down}</td>
                <td style="width:40px;">${timeOff}</td>
                <td style="width:40px;">${timeOn}</td>
                <td style="width:40px;">${Time}</td>
                ${rimTime !== null ? '<td style="width:40px;">' + rimTime.toFixed(0) + '</td>' : ''}
                <td style="width:40px;">${L.PaxNum}</td>
                <td style="width:80px;">${parseFloat(L.passengerWeight).toFixed(0)}</td>
                <td style="width:80px;">${parseFloat(L.cargoWeight).toFixed(0)}</td>
                <td style="width:80px;">${fuel}</td>
                <td style="width:80px;">${TOweight.toFixed(0)}</td>
                <td style="width:40px;">${fwd}</td>
                <td style="width:40px;">${cg}</td>
                <td style="width:40px;">${aft}</td>
                <td style="width:50px;">${Uplifts}</td>
              </tr>`;
  LegHTML += '</table>';

  const passengerList = paxMan[index];

  const remarksHeaderHTML = '<b>Remarks:</b>';
  const remarksDescriptionHTML = L.Description === '' || L.Description === null ? '\xA0' : L.Description;
  const elementClass =
    passengerList !== undefined && passengerList.length > 0
      ? 'limit-table-landscape tableboarders flight-spec-table'
      : 'limit-table-landscape tableboarders flight-spec-table no-passengers';
  var html = `<div style="break-inside: avoid;">
                      <table class="${elementClass}" style="border-collapse: collapse;">
                        <tr style="background-color: white;">
                          <td>${LegHTML}</td>
                          <td>
                            <table id="remarksTable">
                              <tr style="background-color: white;">
                                <td>${remarksHeaderHTML}</td>
                              </tr>
                              <tr style="background-color: white;">
                                <td>${remarksDescriptionHTML}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>`;

  if (passengerList !== undefined) {
    html += BILLING_Gen_OilGas_FR_LegPax(paxMan[index]);
  }

  return `${html}</div>`;
}

function BILLING_Gen_OilGas_FR_LegPax(paxs) {
  var html =
    '<table class="limit-table-landscape" id="passengerManifestSection"><tr style="border-collapse: collapse;background-color: white;">';
  var filler = paxs.length % 3;
  var paxHTML = '';
  var legWeight = 0;
  for (var i in paxs) {
    var P = paxs[i];
    legWeight += parseInt(convertToUserUNITS(P.weight, 'Weight', P.units, 'Imperial'));
    paxHTML += `<td>
                  <div class="passenger-details-container">
                    <span class="full-name">${P.lastname}, ${P.firstname}</span>
                    <span class="company">${P.company}</span>
                    <span class="weight">${P.weight}${P.units == 'imperial' ? ' lbs' : ' kg'}</span>
                  </div>
                </td>`;
    if ((parseInt(i) + 1) % 3 == 0 && i != 0) {
      html += `<tr style="background-color: white;">
                <td>
                  <table class="limit-table-landscape">
                    <tr style="border-collapse: collapse;background-color: white;">${paxHTML}</tr>
                  </table>
                </td>
               </tr>`;
      paxHTML = '';
    }
  }
  if (filler != 0) {
    for (var i = 0; i < 3 - filler; i++) {
      paxHTML +=
        '<td><div class="passenger-details-container"><span class="full-name"></span><span class="company"></span><span class="weight"></span></div></td>';
    }
    html += `<tr style="background-color: white;">
              <td>
                <table class="limit-table-landscape">
                  <tr style="border-collapse: collapse;background-color: white;">${paxHTML}</tr>
                </table>
              </td>
             </tr>`;
  }
  html += '</tr></table>';
  return html;
}

function GetWB_FwdAft(graphObject, weight, serial, graphType) {
  serial = parseFloat(serial);
  var fwdlimit = '';
  var aftlimit = '';
  for (var a in graphObject) {
    if (graphObject[a].Type !== graphType) continue;
    //x and y coords array of longitudinal graph
    var gLength = graphObject[a].LongGraph.length;
    var i = 1;
    for (var b in graphObject[a].LongGraph) {
      var x1, y1, x2, y2;
      if (i != gLength) {
        x1 = parseFloat(graphObject[a].LongGraph[b].x);
        y1 = parseFloat(graphObject[a].LongGraph[b].y);
        x2 = parseFloat(graphObject[a].LongGraph[i].x);
        y2 = parseFloat(graphObject[a].LongGraph[i].y);
      } else {
        x1 = parseFloat(graphObject[a].LongGraph[b].x);
        y1 = parseFloat(graphObject[a].LongGraph[b].y);
        x2 = parseFloat(graphObject[a].LongGraph[0].x);
        y2 = parseFloat(graphObject[a].LongGraph[0].y);
      }
      //get line equations and get intercepts
      if (
        doLineSegmentsIntersect(
          {
            x: x1,
            y: y1,
          },
          {
            x: x2,
            y: y2,
          },
          {
            x: -1000,
            y: weight,
          },
          {
            x: 1000,
            y: weight,
          }
        ) &&
        y1 < y2
      ) {
        //var intersection = require('intersection');
        seg2 = {
          start: {
            x: 0,
            y: weight,
          },
          end: {
            x: 1000,
            y: weight,
          },
        };
        seg3 = {
          start: {
            x: x1,
            y: y1,
          },
          end: {
            x: x2,
            y: y2,
          },
        };
        fwdlimit = round10(intersection.intersect(seg2, seg3).x);
      }
      if (
        doLineSegmentsIntersect(
          {
            x: x1,
            y: y1,
          },
          {
            x: x2,
            y: y2,
          },
          {
            x: -1000,
            y: weight,
          },
          {
            x: 1000,
            y: weight,
          }
        ) &&
        y1 > y2
      ) {
        seg2 = {
          start: {
            x: 0,
            y: weight,
          },
          end: {
            x: 1000,
            y: weight,
          },
        };
        seg3 = {
          start: {
            x: x1,
            y: y1,
          },
          end: {
            x: x2,
            y: y2,
          },
        };
        aftlimit = round10(intersection.intersect(seg2, seg3).x);
      }
      i++;
    }
  }
  return { fwdlimit: fwdlimit, aftlimit: aftlimit };
}

function BILLING_getPaxLegWeight(paxMan, legIndex) {
  var weight = 0;
  try {
    if (paxMan[legIndex] != undefined) {
      var paxs = paxMan[legIndex];
      for (var i in paxs) {
        var P = paxs[i];
        console.log('P', P);
        weight += parseInt(convertToUserUNITS(P.weight, 'Weight', P.units, 'Imperial'));
      }
    }
  } catch (e) {
    //do nothing;
  }
  return weight;
}

function getFCUnitText(Units) {
  switch (Units) {
    case 'pounds':
      units = iTrans('lbs');
      break;
    case 'litres':
      units = iTrans('L');
      break;
    case 'usgallons':
      units = iTrans('US Gal');
      break;
    case 'gallons':
      units = iTrans('IMP Gal');
      break;
    case 'kg':
      units = iTrans('kg');
      break;
    case 'drums':
      units = iTrans('Drums');
      break;
  }
  return units;
}
function convertFC_ToUnits(FuelType, fromValue, fromUnits, toUnits) {
  if (FuelType == 'Uknown' && (fromUnits == 'pounds' || fromUnits == 'kg' || toUnits == 'pounds' || toUnits == 'kg')) {
    return FALSE;
  }
  var fromLitres = 0;
  switch (fromUnits) {
    case 'litres':
      fromLitres = fromValue;
      break;
    case 'usgallons':
      fromLitres = fromValue * 3.78541;
      break;
    case 'gallons':
      fromLitres = fromValue * 4.54609;
      break;
    case 'pounds':
      switch (FuelType) {
        case 'JET-A':
          fromLitres = fromValue / 1.85;
          break;
        case 'JET-B':
          fromLitres = fromValue / 1.77;
          break;
        case '100LL':
          fromLitres = fromValue / 1.59;
          break;
        case 'Diesel':
          fromLitres = fromValue / 1.88;
          break;
        case 'Gasoline':
          fromLitres = fromValue / 1.65;
      }
      break;
    case 'kg':
      switch (FuelType) {
        case 'JET-A':
          fromLitres = (fromValue * 2.20462) / 1.85;
          break;
        case 'JET-B':
          fromLitres = (fromValue * 2.20462) / 1.77;
          break;
        case '100LL':
          fromLitres = (fromValue * 2.20462) / 1.59;
          break;
        case 'Diesel':
          fromLitres = (fromValue * 2.20462) / 1.88;
          break;
        case 'Gasoline':
          fromLitres = (fromValue * 2.20462) / 1.65;
      }
      break;
    case 'drums':
      fromLitres = fromValue * 205;
      break;
  }
  switch (toUnits) {
    case 'litres':
      return round10(fromLitres);
      break;
    case 'usgallons':
      return round10(fromLitres * 0.264172);
      break;
    case 'gallons':
      return round10(fromLitres * 0.219969);
      break;
    case 'pounds':
      switch (FuelType) {
        case 'JET-A':
          return round10(fromLitres * 1.85);
          break;
        case 'JET-B':
          return round10(fromLitres * 1.77);
          break;
        case '100LL':
          return round10(fromLitres * 1.59);
          break;
        case 'Diesel':
          return round10(fromLitres * 1.88);
          break;
        case 'Gasoline':
          return round10(fromLitres * 1.65);
          break;
      }
      break;
    case 'kg':
      switch (FuelType) {
        case 'JET-A':
          return round10((fromLitres * 1.85) / 2.20462);
          break;
        case 'JET-B':
          return round10((fromLitres * 1.77) / 2.20462);
          break;
        case '100LL':
          return round10((fromLitres * 1.59) / 2.20462);
          break;
        case 'Diesel':
          return round10((fromLitres * 1.88) / 2.20462);
          break;
        case 'Gasoline':
          return round10((fromLitres * 1.65) / 2.20462);
          break;
      }
      break;
    case 'drums':
      return round10(fromLitres / 205);
      break;
  }
  return false;
}

function checkCfieldType(type = 'ALL', cField, isSchAmin = false){
  if (cField.usedFor == 'ALL'){
    return true;
  }
  if (cField.usedFor.indexOf('SCA') != -1){
    return isSchAmin;
  }
  if (cField.usedFor.indexOf(type) != -1){
    return true;
  }
  return false;
}

function checkCfieldCollectedFor(cField){
  var Collected = iTrans('Itin & FR & Sched');
  if (cField.usedFor.indexOf('ITIN') != -1) Collected = iTrans('Itin');
  else if (cField.usedFor.indexOf('FR') != -1) Collected = iTrans('FR');
  else if (cField.usedFor.indexOf('SCH') != -1) Collected = iTrans('Sched');
  else if (cField.usedFor.indexOf('SCA') != -1) Collected = iTrans('Sched Admin');
  return Collected;
}
