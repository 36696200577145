/**
 * Class handling basic interactions with content agnostic, full-page modals.
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @abstract
 */
class vSAMA_AbstractModal extends vSAMA_AbstractEditor {
  constructor() {
    super();
    let modal = $(SAMA.Templates.tpl.modal.wrapper);

    this.dom = {
      wrapper: modal,
      content: modal.find('.sama-modal-container'),
      bar: modal.find('.sama-modal-bar'),
    };
  }

  /**
   * Finishes the GUI setup
   */
  SetUpGui() {
    this.dom.wrapper.enhanceWithin();
  }

  /**
   * Shows the modal
   * @abstract
   */
  Open() {
    this.dom.wrapper.fadeIn(100);
  }

  /**
   * Closes the modal
   * @abstract
   */
  Close() {
    this.dom.wrapper.fadeOut(100);
    this.UpdateObservers('Close', this.HasError());
  }

  HasError() {
    return this.dom.content.find('.sama-form-error').length > 0;
  }

  /**
   * Clears the dom modifications
   */
  Destroy() {
    this.dom.wrapper.remove();
  }

  GetDom() {
    return this.dom.wrapper;
  }
}
