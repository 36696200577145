/**
 *
 */
class SyncTable extends AbstractDatabaseTable {
  constructor() {
    super('Sync', 'SyncItem');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            SyncItem text primary key not null, 
            Date text
        )
    `;
    //#endregion
  }
}
