/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var Assets;
var Assets_Filtered;
var Assets_Avail;
var AssetIndex;
var AssetStaffList;
var AssetCategory;
var LastAssetCategory = 'null';
var LastAssetBase = '';

function init_Assets() {
  $('#FilterAssetTXT').prop('disabled', true);
  if (LOCALSTORAGE.AssetTemplates != undefined) {
    AssetTemplates = LOCALSTORAGE.AssetTemplates;
  } else {
    AssetTemplates = [];
  }
  $.getJSON(BASEURL + 'menu-Assets-AJAX.php?action=getTemplates', function (json_data) {
    AssetTemplates = json_data;
    localStorageDB.setItem('AssetTemplates', JSON.stringify(AssetTemplates));
  }).fail(function (jqXHR, status, error) {
    //do nothing
  });
  $.getJSON(BASEURL + 'menu-Assets-AJAX.php?action=getGroup', function (json_data) {
    Assets = json_data[0];
    var Category = json_data[2];
    AssetCategory = Category;
    $('#AssetCategorySelector').empty();
    $('#AssetCategorySelector').append(
      '<option selected="selected" value="null">' + iTrans('All Categories') + '</option>'
    );
    $('#AssetTemplateCategorySelector').empty();
    $('#AssetTemplateCategorySelector').append(
      '<option selected="selected" value="null">' + iTrans('All Categories') + '</option>'
    );
    for (var i in Category) {
      $('#AssetCategorySelector').append(
        '<option value="' + Category[i].Category + '">' + Category[i].Category + '</option>'
      );
      $('#AssetTemplateCategorySelector').append(
        '<option value="' + Category[i].Category + '">' + Category[i].Category + '</option>'
      );
    }
    $('#AssetCategorySelector').val(LastAssetCategory).selectmenu('refresh');

    $('#AssetBaseSelector').empty();
    $('#AssetBaseSelector').append('<option selected="selected" value="">' + iTrans('All Bases') + '</option>');
    for (var i in GROUP_DATA.Bases) {
      $('#AssetBaseSelector').append(
        $('<option>', { value: GROUP_DATA.Bases[i].name, text: GROUP_DATA.Bases[i].name })
      );
    }
    $('#AssetBaseSelector').val(LastAssetBase).selectmenu('refresh');

    AssetStaffList = json_data[1];
    filterAssetArray(Assets);
    console.log('init_Assets');
    $('#FilterAssetTXT').prop('disabled', false);
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
    $('#FilterAssetTXT').prop('disabled', false);
  });
}

var filterAssetArray_Timeout = null;

function filterAssetArray() {
  if (filterAssetArray_Timeout != null) {
    clearTimeout(filterAssetArray_Timeout);
  }
  filterAssetArray_Timeout = setTimeout(function () {
    filterAssetArray_Timeout = null;
    var keywords = $('#FilterAssetTXT').val().toLowerCase();
    var Category = $('#AssetCategorySelector').val();
    var Base = $('#AssetBaseSelector').val();
    LastAssetCategory = Category;
    LastAssetBase = Base;
    for (var i in Assets) {
      Assets[i].ARR_INDEX = i;
    }

    Assets_Filtered = FilterAssetTypeArray(Assets, keywords, Category, Base);
    displayAssets(Assets_Filtered);
  }, 500);
}

function FilterAssetTypeArray(OrigArray, keywords, Category, Base) {
  var baseFiltered = OrigArray;
  if (Base != '') {
    baseFiltered = OrigArray.filter(function (item) {
      if (Base == item.Base) {
        return true;
      } else {
        return false;
      }
    });
  }

  var Assets_Filtered = baseFiltered.filter(function (item) {
    if (keywords != '' && Category == 'null') {
      if (
        item.Name.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
        item.SN.toLowerCase().indexOf(keywords.toLowerCase()) > -1
      ) {
        return true;
      }
    }
    if (Category != 'null' && keywords == '') {
      if (Category == item.Category) {
        return true;
      }
    }
    if (Category != 'null' && keywords != '') {
      return (
        (Category == item.Category && item.Name.toLowerCase().indexOf(keywords.toLowerCase()) > -1) ||
        (Category == item.Category && item.SN.toLowerCase().indexOf(keywords.toLowerCase()) > -1)
      );
    }
    if (keywords == '' && Category == 'null') {
      return true;
    } else {
      return false;
    }
  });
  return Assets_Filtered;
}

function clearAssetFilter() {
  $('#FilterAssetTXT').val('');
  filterAssetArray();
}

function nextAssetPage(curpage) {
  curpage++;
  displayAssets(Assets_Filtered, curpage);
}

function prevAssetPage(curpage) {
  if (curpage != 1) {
    curpage--;
    displayAssets(Assets_Filtered, curpage);
  }
}

function displayAssets(Assets, page = 1) {
  console.log(Assets);
  $('#Asset_Content').html('');
  var AssetsHTML = '';
  var pageBTNS = "<div class='limit-table'>";
  if (Assets.length > 100) {
    var min = parseInt(page + '01') - 100;
    var max = page * 100;
    var showNext = true;
    if (max > Assets.length) {
      max = Assets.length;
      showNext = false;
    }
    pageBTNS +=
      "<h4>Showing <button onClick='prevAssetPage(" +
      page +
      ");' data-role='none' style='padding: 0px 8px;border-radius: 10%; background-color: #f1f1f1;'>« " +
      iTrans('prev') +
      '</button> ' +
      min +
      ' - ' +
      max;
    if (showNext) {
      pageBTNS +=
        " <button onClick='nextAssetPage(" +
        page +
        ");' data-role='none' style='padding: 0px 8px;border-radius: 10%; background-color: #f1f1f1;'>" +
        iTrans('next') +
        ' »</button>';
    }
    pageBTNS += ' ' + iTrans('of') + ' ' + Assets.length + ' ' + iTrans('matching items') + '</h4>';
  } else {
    pageBTNS += '<h4>' + iTrans('Showing') + ' ' + Assets.length + ' ' + iTrans('matching items') + '</h4>';
  }
  pageBTNS += '</div>';
  if (Assets.length > 0) {
    console.log('populating assets');
    var count = 0;
    AssetsHTML +=
      '<div style="width: 100%; overflow: auto"><table id="Assets_Table" class="wrap" >' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Name') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Category') +
      '</th>' +
      '<th data-priority="0">SN</th>' +
      '<th data-priority="1">' +
      iTrans('Changed') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('By') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Status') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Location') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Updated') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Action') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var disabled = 'disabled';
    if (USER_RIGHTS.AssetAccess == 1 || USER_RIGHTS.SuperUser == 1) {
      disabled = '';
    }
    for (var i in Assets) {
      if (i < min - 1) {
        continue;
      } else {
        count++;
      }
      var lineBG = '';
      var Asset = Assets[i];

      var By = iTrans('Unknown');
      for (s in AssetStaffList) {
        if (AssetStaffList[s].PrimaryKey == Asset.UpdatedBy) {
          By = AssetStaffList[s].chatname;
        }
      }
      var time = null;
      if (Asset.Updated !== null) {
        time = getLocalDateTime(Asset.Updated, null, false);
        time = time.text;
      } else {
        time = iTrans('Never');
      }
      var time2 = null;
      if (Asset.Datetime !== null) {
        time2 = getLocalDateTime(Asset.Datetime, null, false);
        time2 = time2.text;
      } else {
        time2 = 'Never';
      }
      switch (Asset.Status) {
        case 'Expired':
          lineBG = 'redbg';
          break;
        case 'Inspection Due':
          lineBG = 'orangebg';
          break;
        case 'Defective':
          lineBG = 'redbg';
          break;
        case 'Pending':
          lineBG = 'yellowbg';
          break;
        case 'Inspected':
          lineBG = 'greenbg';
          break;
      }
      var lineBG2 = '';
      switch (Asset.Status_Cycles) {
        case 'Approaching Cycle Limit':
          if (lineBG2 != 'greenbg' && lineBG2 != 'orangebg' && lineBG2 != 'redbg') lineBG2 = 'yellowbg';
          break;
        case 'Cycle Limit Reached':
          if (lineBG2 != 'greenbg' && lineBG2 != 'orangebg') lineBG2 = 'redbg';
          break;
      }
      switch (Asset.Status_Usage) {
        case 'Approaching Hour Limit':
          if (lineBG2 != 'greenbg' && lineBG2 != 'orangebg' && lineBG2 != 'redbg') lineBG2 = 'yellowbg';
          break;
        case 'Hour Limit Reached':
          if (lineBG2 != 'greenbg' && lineBG2 != 'orangebg') lineBG2 = 'redbg';
          break;
      }
      var MapBTN = '';
      if (Asset.Long != null && Asset.Lat != null) {
        MapBTN =
          '<button class="action-btn" value="' +
          Asset.ARR_INDEX +
          '" ><a href="http://maps.google.com/maps?saddr=Current+Location&daddr=' +
          Asset.Lat +
          ',' +
          Asset.Long +
          ' " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>';
        if (IOS) {
          MapBTN =
            '<button class="action-btn" value="' +
            Asset.ARR_INDEX +
            '" ><a href="http://maps.apple.com/?saddr=Current+Location&daddr=' +
            Asset.Lat +
            ',' +
            Asset.Long +
            ' " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>';
        }
      }
      AssetsHTML +=
        '<tr class="' +
        lineBG +
        '"><td>' +
        Asset.Name +
        (Asset.Base == '' ? '' : '<br />Base: ' + Asset.Base + '') +
        '</td><td>' +
        Asset.Category +
        '</td><td>' +
        Asset.SN +
        '</td><td>' +
        time +
        '</td><td>' +
        By +
        '</td><td class="' +
        lineBG2 +
        '">' +
        Asset.Status +
        (Asset.Status_Usage == '' ? '' : '<br />' + Asset.Status_Usage) +
        (Asset.Status_Cycles == '' ? '' : '<br />' + Asset.Status_Cycles) +
        '</td><td>' +
        MapBTN +
        Asset.Location +
        '<br />' +
        AssetTemplateName(Asset.Template) +
        '</td><td>' +
        time2 +
        '</td><td><div class="vertical-center-container"><button onClick="editAssetPK(this);" id="AssetEdit-' +
        Asset.ARR_INDEX +
        '" data-mini="true" class="action-btn" ' +
        disabled +
        '><img src="./images/edit.svg" /></button><button onClick="getAssetHistory(this);" id="AssetH-' +
        Asset.ARR_INDEX +
        '" data-mini="true" class="action-btn"><img src="./images/inspect.svg" /> </button><button onClick="deleteAsset(this);" id="AssetDEL-' +
        Asset.ARR_INDEX +
        '" data-mini="true" class="action-btn" ' +
        disabled +
        '><img src="./images/delete.svg" /></button></div></td></tr>';

      if (count == 100) {
        break;
      }
      //end loop
    }

    AssetsHTML += '</tbody></table></div>';
    AssetsHTML = pageBTNS + AssetsHTML + pageBTNS;
    $('#Asset_Content').html(AssetsHTML);

    $('#Asset_Content').enhanceWithin();
    AddTableSorter('Assets_Table');
  } else {
    $('#Asset_Content').html('<h3>' + iTrans('No Assets') + '</h3>');
  }
}

function exitAssetHistory() {
  $.mobile.changePage($('#AssetPage'), {
    reverse: true,
    changeHash: false,
  });
  init_Assets();
}

function exitnewAssetHistory() {
  $.mobile.changePage($('#AssetHistoryPage'), {
    reverse: true,
    changeHash: false,
  });
  $('#exitnewAssetHistoryPage').unbind('click', exitnewAssetHistory);
}

function deleteAsset(item) {
  console.log('Delete: ' + item.id);
  var deleteItem = item.id;
  var index = deleteItem.split('-');
  index = index[1];
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Delete this item?'),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=deleteAsset&PK=' + Assets[index].PrimaryKey,
            function (json_data) {
              Assets.splice(index, 1);
              displayAssets(Assets);
            }
          ).fail(function (jqXHR, status, error) {
            console.log('Failed to Delete template');
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function deleteAssetHistory(AssetPK, HistoryPK) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Delete this History item?'),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=deleteAssetHistory&PK=' + AssetPK + '&HistoryPK=' + HistoryPK,
            function (json_data) {
              getAssetHistory({ id: 'AssetH-' + AssetIndex });
            }
          ).fail(function (jqXHR, status, error) {
            console.log('Failed to Delete template');
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function newAsset() {
  console.log('Creating New Asset');
  $.getJSON(BASEURL + 'menu-Assets-AJAX.php?action=newAsset', function (json_data) {
    $('#Asset_IntervalADD').hide();
    AssetPK = json_data[0].AssetPK;
    editAsset(AssetPK);
    refreshAssets();
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
  });
}

function refreshAssets() {
  $.getJSON(BASEURL + 'menu-Assets-AJAX.php?action=getGroup', function (json_data) {
    Assets = json_data[0];
    var Category = json_data[2];
    AssetCategory = Category;
    $('#Asset_Category').empty();
    $('#Asset_Category').append(
      '<option disabled="disabled" selected="selected" value="null">' + iTrans('Select Category') + '</option>'
    );
    for (var i in Category) {
      $('#Asset_Category').append('<option value="' + Category[i].Category + '">' + Category[i].Category + '</option>');
    }
    AssetStaffList = json_data[1];
    //displayAssets(Assets);
    console.log('init_Assets');
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
  });
}

function editAsset(AssetPK) {
  console.log('Edit: ' + AssetPK);
  $.mobile.changePage($('#newAssetPage'), {
    changeHash: false,
  });
  $('#Asset_Category').selectmenu('refresh');
  $.getJSON(BASEURL + 'menu-Assets-AJAX.php?action=editAsset&AssetPK=' + AssetPK, function (json_data) {
    //populate update form
    var Asset = json_data[0];
    var User = iTrans('Unknown');
    var time = Asset.Updated.split(' ');
    time = new Date(time[0] + 'T' + time[1] + 'Z');
    for (var i in AssetStaffList) {
      if (AssetStaffList[i].PrimaryKey == Asset.By) {
        User = AssetStaffList[i].chatname;
      }
    }
    var AssetHTML = '<h3>' + iTrans('Updated') + ': ' + time + '<br />' + iTrans('By') + ': ' + User + '</h3>';

    if (Asset.Manual_FileName !== '') {
      AssetHTML +=
        '<p><h3>' +
        iTrans('Manual') +
        ': <a href="' +
        BASEURL +
        'menu-Assets-AJAX.php?action=getManual&Asset_PK=' +
        Asset.PrimaryKey +
        '" data-ajax="false" target="_blank">' +
        Asset.Manual_FileName +
        '</a></h3></p>';
    } else {
      AssetHTML += '<h3>' + iTrans('No Manual Uploaded') + '</h3>';
    }

    if (Asset.Certificate_FileName !== '') {
      AssetHTML +=
        '<p><h3>' +
        iTrans('Certificate') +
        ': <a href="' +
        BASEURL +
        'menu-Assets-AJAX.php?action=getCert&Asset_PK=' +
        Asset.PrimaryKey +
        '" data-ajax="false" target="_blank">' +
        Asset.Certificate_FileName +
        '</a></h3></p>';
    } else {
      AssetHTML += '<h3>' + iTrans('No Certificate Uploaded') + '</h3>';
    }

    var DivID = 'EDITASSETPK-' + Asset.PrimaryKey;
    if (Asset.Picture_FileName !== '') {
      AssetHTML += '<p><div id="' + DivID + '"></div>';
    } else {
      AssetHTML += '<h3>' + iTrans('No Picture Uploaded') + '</h3>';
    }
    AssetHTML +=
      '<button onClick="DeleteAssetFileData(' +
      Asset.PrimaryKey +
      ')" data-mini-"true" data-icon="delete" class="redbg">' +
      iTrans('Delete [Manual / Certificate / Picture]') +
      '</button>';

    $('#Asset_Base').empty();
    $('#Asset_Base').append($('<option>', { value: '', text: 'None' }));
    for (var i in GROUP_DATA.Bases) {
      $('#Asset_Base').append($('<option>', { value: GROUP_DATA.Bases[i].name, text: GROUP_DATA.Bases[i].name }));
    }
    $('#Asset_Base').val(Asset.Base).selectmenu('refresh');
    $('#newHistory_Content').html('<table class="limit-table"><tr><td>' + AssetHTML + '</td></tr></table>');
    LoadAssetImage(
      DivID,
      BASEURL + 'menu-Assets-AJAX.php?action=getPicture&PK=' + Asset.PrimaryKey + '&table=Assets&base64=true'
    );
    $('#Asset_PK').val(Asset.PrimaryKey);
    $('#Asset_Name ').val(Asset.Name);
    $('#Asset_SN').val(Asset.SN);
    $('#Asset_Comments').val(Asset.Comments);
    if (Asset.Category != '') $('#Asset_Category').val(Asset.Category).selectmenu('refresh');
    $('#Asset_NewCategory').val('');
    $('#Asset_Inspection').val(Asset.Inspection);
    $('#Asset_Manufactured').val(Asset.Manufactured);
    $('#Asset_Purchased').val(Asset.Purchased);
    $('#Asset_Interval').val(Asset.Interval).selectmenu('refresh');

    $('#Asset_Usage_Limit').val(Asset.Usage_Limit);
    $('#Asset_Usage_Alarm').val(Asset.Usage_Alarm);
    $('#Asset_cycle_Limit').val(Asset.cycle_Limit);
    $('#Asset_cycle_Alarm').val(Asset.cycle_Alarm);

    if (Asset.ExpiryOn == 1) {
      $('#Asset_ExpiryOn').attr('checked', true).checkboxradio('refresh');
    } else {
      $('#Asset_ExpiryOn').attr('checked', false).checkboxradio('refresh');
    }

    if (Asset.IntervalOn == 1) {
      $('#Asset_IntervalOn').attr('checked', true).checkboxradio('refresh');
    } else {
      $('#Asset_IntervalOn').attr('checked', false).checkboxradio('refresh');
    }

    if (Asset.IntervalOnFull == 1) {
      $('#Asset_IntervalOnFull').attr('checked', true).checkboxradio('refresh');
    } else {
      $('#Asset_IntervalOnFull').attr('checked', false).checkboxradio('refresh');
    }

    if (Asset.IntervalOnPreFlight == 1) {
      $('#Asset_IntervalOnPreFlight').attr('checked', true).checkboxradio('refresh');
    } else {
      $('#Asset_IntervalOnPreFlight').attr('checked', false).checkboxradio('refresh');
    }
    console.log(Asset);
    $('#newAssetPage').enhanceWithin();
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
  });
}

function DeleteAssetFileData(AssetPK) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('CONFIRM'),
    headerClose: false,
    buttonPrompt: '<b>' + iTrans('Which file would you like to delete?.') + '</b>',
    buttons: {
      [iTrans('Delete Manual')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=deleteAssetFileData&type=manual&AssetPK=' + AssetPK,
            function (json_data) {
              editAsset(AssetPK);
            }
          ).fail(function (jqXHR, status, error) {
            alert('Failed to delete Asset File Data.  Try again');
          });
        },
      },
      [iTrans('Delete Certificate')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=deleteAssetFileData&type=certificate&AssetPK=' + AssetPK,
            function (json_data) {
              editAsset(AssetPK);
            }
          ).fail(function (jqXHR, status, error) {
            alert('Failed to delete Asset File Data.  Try again');
          });
        },
      },
      [iTrans('Delete Picture')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=deleteAssetFileData&type=picture&AssetPK=' + AssetPK,
            function (json_data) {
              editAsset(AssetPK);
            }
          ).fail(function (jqXHR, status, error) {
            alert('Failed to delete Asset File Data.  Try again');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function LoadAssetImage(id, URL) {
  $('#' + id).html('Retrieving...');
  $.getJSON(URL, function (json_data) {
    $('#' + id).html('<img src="data:image/jpeg;base64,' + json_data.response + '" width="200"/>');
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to Load Image');
    $('#' + id).html(iTrans('Failed to Load Image'));
  });
}

function editAssetPK(item) {
  var index = item.id;
  index = index.split('-');
  index = index[1];
  AssetIndex = index;
  var AssetPK = Assets[index].PrimaryKey;
  if (Assets[index].IntervalOn == 1) {
    $('#Asset_IntervalADD').show();
  } else {
    $('#Asset_IntervalADD').hide();
  }
  $('#Asset_Category').empty();
  $('#Asset_Category').append(
    '<option disabled="disabled" selected="selected" value="null">' + iTrans('Select Category') + '</option>'
  );
  for (var i in AssetCategory) {
    $('#Asset_Category').append(
      '<option value="' + AssetCategory[i].Category + '">' + AssetCategory[i].Category + '</option>'
    );
  }
  editAsset(AssetPK);
}

function Asset_ToggleInterval(e) {
  if (e.checked) {
    $('#Asset_IntervalADD').show();
  } else {
    $('#Asset_IntervalADD').hide();
  }
}

function updateAsset() {
  var AssetPK = $('#Asset_PK').val();
  var Name = $('#Asset_Name').val();
  var SN = $('#Asset_SN').val();
  var Comments = $('#Asset_Comments').val();
  var Category = $('#Asset_Category').val();
  var NewCategory = $('#Asset_NewCategory').val();
  if (NewCategory !== '') {
    Category = NewCategory;
  }
  if (Category == null && NewCategory == '') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Missing Category'),
      headerClose: false,
      buttonPrompt: '<b>' + iTrans('Please select a Category or create a new one.') + '</b>',
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
    return;
  }
  var Inspection = $('#Asset_Inspection').val();
  var Manufactured = $('#Asset_Manufactured').val();
  var Purchased = $('#Asset_Purchased').val();
  var Interval = $('#Asset_Interval').val();
  var ExpiryOn = 0;
  if ($('#Asset_ExpiryOn').is(':checked')) {
    ExpiryOn = 1;
  }
  var IntervalOn = 0;
  if ($('#Asset_IntervalOn').is(':checked')) {
    IntervalOn = 1;
  }
  if (IntervalOn && Interval == null) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Missing Interval'),
      headerClose: false,
      buttonPrompt: '<b>' + iTrans('Please select an Inspection Interval.') + '</b>',
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
    return;
  }
  var IntervalOnFull = 0;
  if ($('#Asset_IntervalOnFull').is(':checked')) {
    IntervalOnFull = 1;
  }
  var IntervalOnPreFlight = 0;
  if ($('#Asset_IntervalOnPreFlight').is(':checked')) {
    IntervalOnPreFlight = 1;
  }

  var UsageLimit = parseFloat($('#Asset_Usage_Limit').val());
  if (UsageLimit < 0) {
    UsageLimit = 0;
  }
  var UsageAlarm = parseFloat($('#Asset_Usage_Alarm').val());
  if (UsageAlarm > UsageLimit) {
    UsageAlarm = UsageLimit;
  }
  var cycleLimit = parseFloat($('#Asset_cycle_Limit').val());
  if (cycleLimit < 0) {
    cycleLimit = 0;
  }
  var cycleAlarm = parseFloat($('#Asset_cycle_Alarm').val());
  if (cycleAlarm > cycleLimit) {
    cycleAlarm = cycleLimit;
  }

  var Base = $('#Asset_Base').val();

  $.getJSON(
    BASEURL +
      'menu-Assets-AJAX.php?action=updateAsset&AssetPK=' +
      AssetPK +
      '&Name=' +
      encodeURIComponent(Name) +
      '&Category=' +
      encodeURIComponent(Category) +
      '&Comments=' +
      encodeURIComponent(Comments) +
      '&SN=' +
      encodeURIComponent(SN) +
      '&Inspection=' +
      encodeURIComponent(Inspection) +
      '&Manufactured=' +
      encodeURIComponent(Manufactured) +
      '&Purchased=' +
      encodeURIComponent(Purchased) +
      '&Interval=' +
      encodeURIComponent(Interval) +
      '&ExpiryOn=' +
      encodeURIComponent(ExpiryOn) +
      '&IntervalOn=' +
      encodeURIComponent(IntervalOn) +
      '&IntervalOnFull=' +
      encodeURIComponent(IntervalOnFull) +
      '&IntervalOnPreFlight=' +
      encodeURIComponent(IntervalOnPreFlight) +
      '&Usage_Limit=' +
      UsageLimit +
      '&Usage_Alarm=' +
      UsageAlarm +
      '&cycle_Limit=' +
      cycleLimit +
      '&cycle_Alarm=' +
      cycleAlarm +
      '&Base=' +
      encodeURIComponent(Base),
    function (json_data) {
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed'),
          headerClose: false,
          buttonPrompt:
            '<p>' +
            iTrans('Server Side Error') +
            '.  ' +
            iTrans('Try again or please report this problem if it presists') +
            '. </p>' +
            iTrans('Error') +
            ': ' +
            json_data[0].Exception,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      } else {
        var Updated = json_data[0].Updated;
        var time = Updated.split(' ');
        time = new Date(time[0] + 'T' + time[1] + 'Z');
        $('#newHistory_Content').html("<h3 class='limit-table'>Updated: " + time + ' By: ' + chatname + '</h3>');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Success',
          headerClose: false,
          buttonPrompt: '<p>' + iTrans('Asset Updated') + '!</p>',
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                $.mobile.changePage($('#AssetPage'), {
                  reverse: true,
                  changeHash: false,
                });
                init_Assets();
              },
            },
          },
        });
      }
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
  });
}

function getAssetHistory(item) {
  var index = item.id;
  index = index.split('-');
  index = index[1];
  AssetIndex = index;
  $.mobile.changePage($('#AssetHistoryPage'), {
    changeHash: false,
  });
  $('#exitAssetHistoryPage').unbind('click', exitAssetHistory);
  $('#exitAssetHistoryPage').click(exitAssetHistory);
  $('#addnewAssetHistoryPage').unbind('click', newAssetHistory);
  $('#addnewAssetHistoryPage').click(newAssetHistory);
  $('#AssetHistoryPageTXT').html(Assets[index].Name + ' ' + iTrans('History'));
  $.getJSON(
    BASEURL + 'menu-Assets-AJAX.php?action=getAssetHistory&Asset_PK=' + Assets[index].PrimaryKey,
    function (json_data) {
      AssetHistory = json_data;
      displayAssetHistory(AssetHistory, index);
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Stafflist');
    AJAXFAIL(error);
  });
}

function displayAssetHistory(AssetHistory, index) {
  var AssetHTML = '<div class="limit-table">';
  var Asset = Assets[index];
  if (Asset.Manual_FileName !== '') {
    AssetHTML +=
      '<p><h3>' +
      iTrans('Manual') +
      ': <a href="' +
      BASEURL +
      'menu-Assets-AJAX.php?action=getManual&Asset_PK=' +
      Asset.PrimaryKey +
      '" data-ajax="false" target="_blank">' +
      Asset.Manual_FileName +
      '</a></h3></p>';
  } else {
    AssetHTML += '<b>Manual: </b>' + iTrans('No Manual Uploaded') + '<br />';
  }

  if (Asset.Certificate_FileName !== '') {
    AssetHTML +=
      '<p><h3>' +
      iTrans('Certificate') +
      ': <a href="' +
      BASEURL +
      'menu-Assets-AJAX.php?action=getCert&Asset_PK=' +
      Asset.PrimaryKey +
      '" data-ajax="false" target="_blank">' +
      Asset.Certificate_FileName +
      '</a></h3></p>';
  } else {
    AssetHTML += '<b>Certificate: </b>' + iTrans('No Certificate Uploaded') + '<br />';
  }

  var picDivID = 'ASSETPICPK-' + Asset.PrimaryKey;
  if (Asset.Picture_FileName !== '') {
    AssetHTML += '<div id="' + picDivID + '"></div>';
  } else {
    AssetHTML += '<b>Picture: </b>' + iTrans('No Picture Uploaded') + '<br />';
  }
  AssetHTML += '<div id="ASSET_UsageStats"></div>';
  AssetHTML += '</div>';

  $('#AssetHistory_Content').html('');
  if (AssetHistory.length > 0) {
    AssetHTML += `
    <div style="width: 100%; overflow: auto">
    <table class="limit-table" id="AssetHistory_Table">
    <thead>
      <tr>
        <th></th>
        <th data-priority="0">${iTrans('Status')}</th>
        <th data-priority="0">${iTrans('Comment')}</th>
        <th data-priority="0">${iTrans('Location Details')}</th>
        <th>${iTrans('Hrs')}</th>
        <th>${iTrans('Cycles')}</th>
        <th data-priority="0">${iTrans('On')}</th>
        <th data-priority="1">${iTrans('By')}</th>
        <th data-priority="2">${iTrans('Picture')}</th>
        <th data-priority="2">${iTrans('Other Attachment')}</th>
      </tr>
    </thead>
    <tbody>`;
    var totalCycles = 0;
    var totalUsage = 0;
    var totalCycles_E = 0;
    var totalUsage_E = 0;
    for (var i in AssetHistory) {
      var H = AssetHistory[i];
      if (H.estimated == 0) {
        totalCycles += parseInt(H.cycles);
        totalUsage += parseFloat(H.Usage);
      } else {
        totalCycles_E += parseInt(H.cycles);
        totalUsage_E += parseFloat(H.Usage);
      }

      var ActionBTN = '';
      if (window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, 'admin.assets.administrate')) {
        ActionBTN = `
        <div class="vertical-center-container">
          <button 
            onclick="deleteAssetHistory(${H.Assets_PK},${H.PrimaryKey2});" 
            id="AssetDELHistory-${i}" 
            data-mini="true" 
            class="action-btn ui-btn ui-shadow ui-corner-all ui-mini">
              <img src="./images/delete.svg">
          </button>
        </div>`;
      }
      var By2 = 'Unknown';
      if (H.UpdatedBy == 0) {
        By2 = 'System';
      }
      for (s in AssetStaffList) {
        if (AssetStaffList[s].PrimaryKey == H.UpdatedBy) {
          By2 = AssetStaffList[s].chatname;
        }
      }
      //AssetHTML += '<tr><td>'+H.Status+'</td><td>'+H.Comment+'</td><td>'+H.Location+'</td><td>'+H.Datetime+'</td><td>'+H.UpdatedBy+'</td><td><img src="data:image/png;base64,'+H.Picture+'" width="200" height="175"/></td></tr>';
      var ImageSrc = iTrans('No Image');
      var DivID = 'ASSETHISTPK-' + H.PrimaryKey2;
      //$("#newHistory_Content").html('<table class="limit-table"><tr><td>' + AssetHTML + '</td></tr></table>');
      if (H.Picture_FileName !== '') {
        ImageSrc = '<div id="' + DivID + '"></div>';
      }
      var MapBTN = '';
      if (H.Long != null && H.Lat != null) {
        MapBTN = `<button class="action-btn" value="${i}" ><a href="http://maps.google.com/maps?saddr=Current+Location&daddr=${H.Lat},${H.Long} " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>`;
        if (IOS) {
          MapBTN = `<button class="action-btn" value="${i}" ><a href="http://maps.apple.com/?saddr=Current+Location&daddr=${H.Lat},${H.Long} " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>`;
        }
      }
      var Location =
        "<table class='last-td-fil'><tr><td><div class='vertical-center-container'>" +
        iTrans('Location') +
        '  ' +
        MapBTN +
        '</div></td><td>' +
        H.Location +
        '</td></tr>';
      Location += '<tr><td>' + iTrans('Latitude') + '</td><td>' + ConvertDD_User(H.Lat, 'Lat') + '</td></tr>';
      Location +=
        '<tr><td>' + iTrans('Longitude') + '</td><td>' + ConvertDD_User(H.Long, 'Long') + '</td></tr></table>';
      AssetHTML +=
        '<tr><td>' +
        ActionBTN +
        '</td><td>' +
        H.Status +
        '</td><td>' +
        H.Comment +
        '</td><td>' +
        Location +
        '</td><td>' +
        H.Usage +
        '</td><td>' +
        H.cycles +
        '</td><td>' +
        H.Datetime +
        '</td><td>' +
        By2 +
        '</td><td>' +
        ImageSrc +
        '</td><td><a href="' +
        BASEURL +
        'menu-Assets-AJAX.php?action=getOther&Asset_PK=' +
        H.PrimaryKey2 +
        '" data-ajax="false" target="_blank">' +
        H.InspectDoc_Filename +
        '</a></td></tr>';
    }
    AssetHTML += '</tbody></table></div>';
    $('#AssetHistory_Content').html(AssetHTML);
    var UsageStats =
      '<table class="limit-table"><thead><tr><th>' +
      iTrans('Usage Type') +
      '</th><th>' +
      iTrans('Hours') +
      '</th><th>' +
      iTrans('Cycles') +
      '</th></tr></thead><tbody>';
    UsageStats +=
      '<tr><td>' +
      iTrans('Automated') +
      '</td><td>' +
      round10(totalUsage_E).toFixed(1) +
      '</td><td>' +
      Math.round(totalCycles_E) +
      '</td></tr>';
    UsageStats +=
      '<tr><td>' +
      iTrans('Reported') +
      '</td><td>' +
      round10(totalUsage).toFixed(1) +
      '</td><td>' +
      Math.round(totalCycles) +
      '</td></tr>';
    UsageStats += '</tbody></table>';
    $('#ASSET_UsageStats').html('<h3>' + iTrans('Usage Totals') + '</h3>' + UsageStats);
    $('#AssetHistory_Content').enhanceWithin();
    if (Asset.Picture_FileName !== '') {
      LoadAssetImage(
        picDivID,
        BASEURL + 'menu-Assets-AJAX.php?action=getPicture&PK=' + Asset.PrimaryKey + '&table=Assets&base64=true'
      );
    }
    for (var i in AssetHistory) {
      var H = AssetHistory[i];
      var DivID = 'ASSETHISTPK-' + H.PrimaryKey2;
      if (H.Picture_FileName !== '') {
        LoadAssetImage(
          DivID,
          BASEURL + 'menu-Assets-AJAX.php?action=getPicture&PK=' + H.PrimaryKey2 + '&table=History&base64=true'
        );
      }
    }
    AddTableSorter('AssetHistory_Table');
  } else {
    $('#AssetHistory_Content').html(
      AssetHTML + "<h3 class='limit-table'>" + iTrans('No history for this asset') + '</h3>'
    );
    if (Asset.Picture_FileName !== '') {
      LoadAssetImage(
        picDivID,
        BASEURL + 'menu-Assets-AJAX.php?action=getPicture&PK=' + Asset.PrimaryKey + '&table=Assets&base64=true'
      );
    }
  }
}

var overrideDatePicker = null;

function newAssetHistory() {
  $.mobile.changePage($('#newAssetHistoryPage'), {
    changeHash: false,
  });
  $('#Asset_File').prop('disabled', false);
  if (!ANDROID) {
    $('#AndroidAssetHistoryPIC').hide();
  }
  var AssetItem = Assets[AssetIndex];
  console.log(AssetItem);
  if (AssetItem.Manual_FileName != '' && AssetItem.Manual_FileName != 'N/A') {
    $('#Asset_ManualHLDR').html(
      '<h2>' +
        iTrans('Manual') +
        ': <a href="' +
        BASEURL +
        'menu-Assets-AJAX.php?action=getManual&Asset_PK=' +
        AssetItem.PrimaryKey +
        '" data-ajax="false" target="_blank">' +
        AssetItem.Manual_FileName +
        '</a></h2>'
    );
  } else {
    $('#Asset_ManualHLDR').html('');
  }

  if (USER_RIGHTS['AssetAccess'] == 1 || USER_RIGHTS['SuperUser'] == 1) {
    if (overrideDatePicker == null) {
      var minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 2);
      overrideDatePicker = new Pikaday({
        field: $('#Asset_DateOverride')[0],
        firstDay: 1,
        minDate: minDate,
        maxDate: new Date(),
        yearRange: [2014, 2114],
        defaultDate: new Date(),
        setDefaultDate: true,
      });
    } else {
      overrideDatePicker.maxDate = new Date();
    }
    $('#Asset_DateOverrideHLDR').show();
  } else {
    $('#Asset_DateOverrideHLDR').hide();
  }
  $('#Asset_Usage').val(0);
  if (AssetItem.Usage_Alarm != 0 || AssetItem.Usage_Limit != 0) {
    $('#Asset_UsageHLDR').show();
  } else {
    $('#Asset_UsageHLDR').hide();
  }

  $('#Asset_cycles').val(0);
  if (AssetItem.cycle_Alarm != 0 || AssetItem.cycle_Limit != 0) {
    $('#Asset_cycleHLDR').show();
  } else {
    $('#Asset_cycleHLDR').hide();
  }

  $('#exitnewAssetHistoryPage').unbind('click', exitnewAssetHistory);
  $('#exitnewAssetHistoryPage').click(exitnewAssetHistory);
  $('#Asset_Status').val('Unknown').selectmenu('refresh');
  $('#Asset_Comment').val('');
  $('#Asset_Location').val('');
  getDeviceLocation();
  cert_base64File2 = null;
  cert_Filename2 = 'N/A';
  cert_base64File = null;
  cert_Filename = 'N/A';
}

function updateAssetHistory() {
  var overrideDate = '0';
  if (USER_RIGHTS['AssetAccess'] == 1 || USER_RIGHTS['SuperUser'] == 1) {
    var ReturnDate = overrideDatePicker.getDate();

    var ReturnMonth = ReturnDate.getUTCMonth() + 1;
    var ReturnDay = ReturnDate.getUTCDate();
    var today = new Date();

    if (
      today.getFullYear() == ReturnDate.getFullYear() &&
      today.getUTCMonth() == ReturnDate.getUTCMonth() &&
      today.getUTCDate() == ReturnDate.getUTCDate()
    ) {
      overrideDate = '0';
    } else {
      if (ReturnMonth < 10) {
        ReturnMonth = '0' + ReturnMonth;
      }
      if (ReturnDay < 10) {
        ReturnDay = '0' + ReturnDay;
      }
      overrideDate = ReturnDate.getFullYear() + '-' + ReturnMonth + '-' + ReturnDay + 'T00:00:00Z';
      overrideDate = new Date(overrideDate);
      overrideDate.setMinutes(overrideDate.getMinutes() + overrideDate.getTimezoneOffset());
      overrideDate = overrideDate.toISOString().substr(0, 19).replace('T', ' ');
    }
  }
  console.log('Updating Asset History');
  var AssetItem = Assets[AssetIndex];
  console.log(AssetItem.PrimaryKey);
  var Status = $('#Asset_Status').val();
  if (Status == null || Status == 'Unknown') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Status Error'),
      headerClose: false,
      buttonPrompt: '<b>' + iTrans('Please select a [Status] for this record') + '.</b>',
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
      },
    });
    return;
  }
  var Comment = $('#Asset_Comment').val();
  var Location = $('#Asset_Location').val();
  var Lat = UserDevice.Lat;
  var Long = UserDevice.Long;
  var File = cert_base64File;
  var Filename = cert_Filename;
  if (Filename == 'N/A') {
    Filename = '';
  }
  var File2 = cert_base64File2;
  var Filename2 = cert_Filename2;
  if (Filename2 == 'N/A') {
    Filename2 = '';
  }
  var PK = AssetItem.PrimaryKey; //Primary Key of AssetFile to update
  var Usage = $('#Asset_Usage').val();
  var cycles = $('#Asset_cycles').val();
  if (USER_RIGHTS['AssetAccess'] == 0 && USER_RIGHTS['SuperUser'] == 0) {
    if (Usage < 0) {
      Usage = 0;
    }
    if (cycles < 0) {
      cycles = 0;
    }
  }
  if (!IOS) {
    progressLoader(0);
  } else {
    $.mobile.loading('show', {
      theme: 'a',
    });
  }
  $.ajax({
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      if (!IOS) {
        xhr.upload.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              //console.log(percentComplete);
              progressLoader(percentComplete);
              $.mobile.loading('show', {
                theme: 'a',
              });
            }
          },
          false
        );
        xhr.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              console.log('Received: ' + percentComplete);
            }
          },
          false
        );
      }
      return xhr;
    },
    timeout: 120000,
    type: 'POST',
    url:
      BASEURL +
      'menu-Assets-AJAX.php?action=newHistory&PK=' +
      PK +
      '&Status=' +
      encodeURIComponent(Status) +
      '&Comment=' +
      encodeURIComponent(Comment) +
      '&Location=' +
      encodeURIComponent(Location) +
      '&Lat=' +
      encodeURIComponent(Lat) +
      '&Long=' +
      encodeURIComponent(Long) +
      '&overrideDate=' +
      encodeURIComponent(overrideDate) +
      '&Usage=' +
      Usage +
      '&cycles=' +
      cycles +
      ' &estimated=0',
    data: {
      File: File,
      Filename: Filename,
      InspectDoc: File2,
      InspectDoc_Filename: Filename2,
    },
    success: function (data) {
      data = jQuery.parseJSON(data);
      if (!IOS) {
        progressLoader(100);
      }

      if (data[0].ServerResponse == 'Success' || data[0].ServerResponse == 'Nothing Changed') {
        console.log('Asset Successfully Updated');

        //$.mobile.changePage( $("#groupCertPage"), {  reverse:true, changeHash: false });
        $.getJSON(
          BASEURL + 'menu-Assets-AJAX.php?action=getAssetHistory&Asset_PK=' + Assets[AssetIndex].PrimaryKey,
          function (json_data) {
            AssetHistory = json_data;
            $.mobile.loading('hide');
            $.mobile.changePage($('#AssetHistoryPage'), {
              changeHash: false,
            });
            displayAssetHistory(AssetHistory, AssetIndex);
          }
        ).fail(function (jqXHR, status, error) {
          $.mobile.loading('hide');
          console.log('Failed to get Asset History');
          AJAXFAIL(error);
        });
        cert_base64File = null;
        cert_Filename = 'N/A';
      } else {
        //insert failed server error
        $.mobile.loading('hide');
        console.log('Asset Update Failed: ' + data[0].ServerResponse);
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'asset update');
        }
        if (data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      }
    },
    fail: function (jqXHR, status, error) {
      $.mobile.loading('hide');
      if (!IOS) {
        progressLoader(100);
      } //to close progress bar on failure
      AJAXFAIL(error);
    },
  });
}

function addAssetFileData() {
  var AssetFile =
    '<h2>' +
    iTrans('PDF or Images only') +
    '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true"><legend>' +
    iTrans('File Type') +
    '</legend><input type="radio" name="FILETYPE" id="ManualType" value="Manual" ><label for="ManualType">' +
    iTrans('Manual') +
    '</label><input type="radio" name="FILETYPE" id="CertificateType" value="Certificate" ><label for="CertificateType">' +
    iTrans('Certificate') +
    '</label><input type="radio" name="FILETYPE" id="PictureType" value="Picture" checked><label for="PictureType">' +
    iTrans('Picture') +
    '</label></fieldset>';
  AssetFile +=
    "<label for='Asset_File3'>" +
    iTrans('Filename') +
    "</label><input type='file' id='Asset_File3' accept='application/pdf,image/*,capture=camera' onChange='base64File(\"Asset\")' />";

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Upload File'),
    headerClose: false,
    buttonPrompt: AssetFile,
    buttons: {
      [iTrans('Upload')]: {
        click: function () {
          var Item = $('input:radio[name=FILETYPE]:checked').val();
          AssetFileData(Item);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function AssetFileData(Item) {
  console.log('Sending File Data for: ' + Item);
  if (Assets[AssetIndex] != undefined) {
    var PK = Assets[AssetIndex].PrimaryKey; //Primary Key of AssetFile to update
  } else {
    var PK = AssetPK;
  }
  var File = cert_base64File;
  var Filename = cert_Filename;
  if (!IOS) {
    progressLoader(0);
  }
  $.ajax({
    xhr: function () {
      var xhr = new window.XMLHttpRequest();
      if (!IOS) {
        xhr.upload.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = (evt.loaded / evt.total) * 100;
              //console.log(percentComplete);
              progressLoader(percentComplete);
            }
          },
          false
        );
        xhr.addEventListener(
          'progress',
          function (evt) {
            if (evt.lengthComputable) {
              var percentComplete = evt.loaded / evt.total;
              console.log('Received: ' + percentComplete);
            }
          },
          false
        );
      }
      return xhr;
    },
    timeout: 120000,
    type: 'POST',
    url: BASEURL + 'menu-Assets-AJAX.php?action=setAssetFile&PK=' + PK + '&Item=' + Item,
    data: {
      File: File,
      Filename: Filename,
    },
    success: function (data) {
      data = jQuery.parseJSON(data);
      if (data[0].ServerResponse == 'Success' || data[0].ServerResponse == 'Nothing Changed') {
        console.log('Asset File Successfully Updated');
        cert_base64File = null;
        cert_Filename = 'N/A';
        if (Assets[AssetIndex] != undefined) {
          editAsset(Assets[AssetIndex].PrimaryKey);
        } else {
          editAsset(PK);
        }
      } else {
        //insert failed server error
        console.log('Asset Update Failed: ' + data[0].ServerResponse);
        if (data[0].ServerResponse == 'Uncaught Exception') {
          ServerResponse(data[0], 'AssetFileData');
        }
        if (data[0].ServerResponse == 'Not Logged In') {
          NOTLOGGEDIN();
        }
      }
    },
    fail: function (jqXHR, status, error) {
      if (!IOS) {
        progressLoader(100);
      } //to close progress bar on failure
      AJAXFAIL(error);
    },
  });
}

function init_AssetTemplates(init = false) {
  if (!init) {
    $.mobile.changePage($('#AssetTemplatePage'), {
      changeHash: false,
    });
  }
  $.getJSON(BASEURL + 'menu-Assets-AJAX.php?action=getTemplates', function (json_data) {
    $('#exitAssetTemplatePage').unbind('click', exitAssetTemplatePage);
    $('#exitAssetTemplatePage').click(exitAssetTemplatePage);
    $('#newAssetTemplatePage').unbind('click', newAssetTemplate);
    $('#newAssetTemplatePage').click(newAssetTemplate);
    if (TemplateIndex != -1) {
      for (i in json_data) {
        if (json_data[i].AssetPK == TemplateIndex) {
          TemplateIndex = i;
        }
      }
    }
    console.log('Tindex: ' + TemplateIndex);
    if (!init) populateAssetTemplates(json_data, 0);
    else AssetTemplates = json_data;
  }).fail(function (jqXHR, status, error) {
    console.log('Failed to get AssetTemplates');
    AJAXFAIL(error);
  });
}

function exitAssetTemplatePage() {
  $.mobile.changePage($('#AssetPage'), {
    reverse: true,
    changeHash: false,
  });
  //reload asset page
  init_Assets();
}

var AssetTemplates;

function populateAssetTemplates(Templates, Filter) {
  AssetTemplates = Templates;
  $('#Asset_Templates').empty();
  $('#Asset_Templates').append(
    '<option selected="selected" value="-1">' + iTrans('No Template Selected') + '</option>'
  );
  for (i in Templates) {
    $('#Asset_Templates').append('<option value="' + Templates[i].PrimaryKey + '">' + Templates[i].Name + '</option>');
  }
  $('#Asset_Templates').enhanceWithin();
  $('#Asset_Templates').val(TemplateIndex).selectmenu('refresh');
  filterAssetTemplateItemArray();
}

function AssetTemplateChange() {
  TemplateIndex = $('#Asset_Templates').val();
  filterAssetTemplateItemArray();
}

function filterAssetTemplateItemArray() {
  if (USER_RIGHTS.modFG_Templates == 0) {
    $('#newAssetTemplatePage').prop('disabled', true);
  }
  var keywords = $('#FilterAssetTemplateTXT').val();
  var Category = $('#AssetTemplateCategorySelector').val();
  for (var i in Assets) {
    Assets[i].ARR_INDEX = i;
  }

  var AssetsTemplate_Filtered = Assets.filter(function (item) {
    if (item.Template == TemplateIndex) {
      return true;
    } else {
      return false;
    }
  });

  if (AssetsTemplate_Filtered == undefined) {
    AssetsTemplate_Filtered = [];
  }
  if (TemplateIndex == -1) {
    $('#AssetTemplateItems_Content').html(
      "<h2 class='limit-table'>" + iTrans('Please either select a template or create a new one') + '</h2>'
    );
    $('#AssetTemplate_Content').html('');
  } else {
    displayAssetTemplate(AssetsTemplate_Filtered);
    filterAssetTemplateArray();
  }
}

function AssetUpdatedBy(ByPK) {
  var user = AssetStaffList.find((element) => element.PrimaryKey == ByPK);
  if (user == undefined) {
    return 'Unknown';
  } else {
    return user.chatname;
  }
}

function AssetTemplateName(TemplatePK) {
  if (TemplatePK == 0) {
    return 'None';
  }
  var Template = AssetTemplates.find((element) => element.PrimaryKey == TemplatePK);
  if (Template == undefined) {
    return 'Unknown';
  } else {
    return Template.Name;
  }
}

function displayAssetTemplate(Assets) {
  var CanChange = '';
  if (USER_RIGHTS.modFG_Templates == 0) {
    CanChange = 'disabled';
  }
  $('#AssetTemplateItems_Content').html('');
  if (Assets.length > 0) {
    var AssetsHTML = '';
    AssetsHTML =
      '<h2 class="limit-table">Template Items</h2><div style="width: 100%; overflow: auto"><table class="wrap" id="AssetTemplate2_Table" data-filter="true" >' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Del') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Category') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Name') +
      '</th>' +
      '<th data-priority="0">SN</th>' +
      '<th data-priority="0">' +
      iTrans('Status') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Template') +
      '</th>' +
      '<th data-priority="1">' +
      iTrans('By') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Updated') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';

    for (var i in Assets) {
      var lineBG = '';
      var Item = Assets[i];
      switch (Item.Status) {
        case 'Expired':
          lineBG = 'redbg';
          break;
        case 'Inspection Due':
          lineBG = 'orangebg';
          break;
        case 'Defective':
          lineBG = 'redbg';
          break;
        case 'Pending':
          lineBG = 'yellowbg';
          break;
        case 'Inspected':
          lineBG = 'greenbg';
          break;
      }
      AssetsHTML +=
        '<tr class="' +
        lineBG +
        '"><td><button class="cirro-btn"  data-mini="true" id="delAS-' +
        Item.ARR_INDEX +
        '" onClick="removeAssetTemplate(this);" ' +
        CanChange +
        '><div class="vertical-center-container"><img src="./images/delete.svg" /> Remove</div></button></td><td>' +
        Item.Category +
        '</td><td>' +
        Item.Name +
        '</td><td>' +
        Item.SN +
        '</td><td>' +
        iTrans(Item.Status) +
        '</td><td>' +
        AssetTemplateName(Item.Template) +
        '</td><td>' +
        AssetUpdatedBy(Item.By) +
        '</td><td>' +
        Item.Updated +
        '</td></tr>';
    }
    AssetsHTML += '</tbody></table></div>';
    AssetsHTML +=
      '<button onClick="exitAssetTemplatePage()" data-icon="check" ' +
      CanChange +
      '>' +
      iTrans('Save Template') +
      '</button>';
    AssetsHTML +=
      "<button onClick='renameTemplate()' data-icon='star' class='greenbg' " +
      CanChange +
      '>' +
      iTrans('Rename Template') +
      '</button>';
    AssetsHTML +=
      "<button onClick='removeTemplate()' data-icon='delete' class='redbg' " +
      CanChange +
      '>' +
      iTrans('Delete Template') +
      '</button>';
    AssetsHTML += "<h1 class='limit-table'>" + iTrans('Add Items To Template') + '</h1>';
    $('#AssetTemplateItems_Content').html(AssetsHTML);
    $('#AssetTemplateItems_Content').enhanceWithin();
    AddTableSorter('AssetTemplate2_Table');
  } else {
    $('#AssetTemplateItems_Content').html("<h3 class='limit-table'>" + iTrans('No Items Added To Template') + '</h3>');
    $('#AssetTemplateItems_Content').enhanceWithin();
  }
}

function clearAssetTemplateFilter() {
  $('#FilterAssetTemplateTXT').val('');
  filterAssetTemplateArray();
}

function nextAssetTemplatePage(curpage) {
  curpage++;
  displayAssetsT(curpage);
}

function prevAssetTemplatePage(curpage) {
  if (curpage != 1) {
    curpage--;
    displayAssetsT(curpage);
  }
}

var filterAssetTemplateArray_Timeout = null;

function filterAssetTemplateArray() {
  if (filterAssetTemplateArray_Timeout != null) {
    clearTimeout(filterAssetTemplateArray_Timeout);
  }

  filterAssetTemplateArray_Timeout = setTimeout(function () {
    filterAssetTemplateArray_Timeout = null;
    for (var i in Assets) {
      Assets[i].ARR_INDEX = i;
    }
    var AvailAssets = Assets.filter(function (item) {
      if (item.Template != TemplateIndex) {
        return true;
      } else {
        return false;
      }
    });

    var keywords = $('#FilterAssetTemplateTXT').val();
    var Category = $('#AssetTemplateCategorySelector').val();

    Assets_Avail = FilterAssetTypeArray(AvailAssets, keywords, Category, '');
    displayAssetsT();
  }, 500);
}

function displayAssetsT(page = 1) {
  var CanChange = '';
  if (USER_RIGHTS.modFG_Templates == 0) {
    CanChange = 'disabled';
  }
  $('#AssetTemplate_Content').html('');

  var pageBTNS = "<div class='limit-table'>";
  if (Assets_Avail.length > 100) {
    var min = parseInt(page + '01') - 100;
    var max = page * 100;
    if (max > Assets_Avail.length) {
      max = Assets_Avail.length;
    }
    pageBTNS +=
      "<h4 class='limit-table'>" +
      iTrans('Showing') +
      " <button onClick='prevAssetTemplatePage(" +
      page +
      ");' data-role='none'> < </button> " +
      min +
      ' - ' +
      max +
      " <button onClick='nextAssetTemplatePage(" +
      page +
      ");' data-role='none'> > </button> " +
      iTrans('of') +
      ' ' +
      Assets_Avail.length +
      ' ' +
      iTrans('matching items') +
      '</h4>';
  } else {
    pageBTNS +=
      "<h4 class='limit-table'>" +
      iTrans('Showing') +
      ' ' +
      Assets_Avail.length +
      ' ' +
      iTrans('matching items') +
      '</h4>';
  }
  pageBTNS += '</div>';
  if (Assets_Avail.length > 0) {
    var TemplateHTML =
      '<div style="width: 100%; overflow: auto"><table class="wrap" id="AssetTemplate_Table">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Sel') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Category') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Name') +
      '</th>' +
      '<th data-priority="0">SN</th>' +
      '<th data-priority="0">' +
      iTrans('Status') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Template') +
      '</th>' +
      '<th data-priority="1">' +
      iTrans('By') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Updated') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    var count = 0;
    for (var i in Assets_Avail) {
      if (i < min - 1) {
        continue;
      } else {
        count++;
      }
      var lineBG = '';
      var Item = Assets_Avail[i];

      switch (Item.Status) {
        case 'Expired':
          lineBG = 'redbg';
          break;
        case 'Inspection Due':
          lineBG = 'orangebg';
          break;
        case 'Defective':
          lineBG = 'redbg';
          break;
        case 'Pending':
          lineBG = 'yellowbg';
          break;
        case 'Inspected':
          lineBG = 'greenbg';
          break;
      }
      //console.log("Item Template: "+Item.Template);

      TemplateHTML +=
        '<tr class="' +
        lineBG +
        '"><td><button class="cirro-btn" data-mini="ture" id="As-' +
        Item.ARR_INDEX +
        '" name="' +
        i +
        '" onClick="updateAssetTemplate(this);" ' +
        CanChange +
        '><div class="vertical-center-container"><img src="./images/Check_mark_green.svg" /> Add to Template</div></button></td><td>' +
        Item.Category +
        '</td><td>' +
        Item.Name +
        '</td><td>' +
        Item.SN +
        '</td><td>' +
        iTrans(Item.Status) +
        '</td><td>' +
        AssetTemplateName(Item.Template) +
        '</td><td>' +
        AssetUpdatedBy(Item.By) +
        '</td><td>' +
        Item.Updated +
        '</td></tr>';
      if (count == 100) {
        break;
      }
    }
    TemplateHTML += '</tbody></table></div>';
    TemplateHTML = pageBTNS + TemplateHTML + pageBTNS;
    $('#AssetTemplate_Content').html(TemplateHTML);
    $('#AssetTemplate_Content').enhanceWithin();

    AddTableSorter('AssetTemplate_Table');
  }
}

var TemplateIndex = -1;
var TemplateFilter = 0;

function newAssetTemplate() {
  var Template =
    '<p>' +
    iTrans('Enter a name for the new template') +
    '</p><label for="ATname">' +
    iTrans('Template Name') +
    '</label><input type="text" id="ATname" />';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('New Template'),
    headerClose: false,
    buttonPrompt: Template,
    buttons: {
      [iTrans('Create Template')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=newTemplate&Name=' + encodeURIComponent($('#ATname').val()),
            function (json_data) {
              TemplateIndex = json_data[0].AssetPK;
              init_AssetTemplates();
            }
          ).fail(function (jqXHR, status, error) {
            console.log('Failed to get AssetTemplates');
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function removeAssetTemplate(item) {
  console.log(item.id);
  var index = item.id;
  index = index.split('-');
  index = parseInt(index[1]);
  $.getJSON(
    BASEURL + 'menu-Assets-AJAX.php?action=updateTemplate&Index=0&AssetPK=' + Assets[index].PrimaryKey,
    function (json_data) {
      Assets[index].Template = 0;
      filterAssetTemplateItemArray();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get AssetTemplates');
    AJAXFAIL(error);
  });
}

function updateAssetTemplate(item) {
  console.log(item.id);
  var index = item.id;
  index = index.split('-');
  index = parseInt(index[1]);

  var Asset = Assets[index].Template;
  if (Asset == 0) {
    $.getJSON(
      BASEURL +
        'menu-Assets-AJAX.php?action=updateTemplate&Index=' +
        $('#Asset_Templates').val() +
        '&AssetPK=' +
        Assets[index].PrimaryKey,
      function (json_data) {
        Assets[index].Template = $('#Asset_Templates').val();
        filterAssetTemplateItemArray();
      }
    ).fail(function (jqXHR, status, error) {
      console.log('Failed to get AssetTemplates');
      AJAXFAIL(error);
    });
  } else {
    var conflict = 'Unknown';
    for (i in AssetTemplates) {
      if (AssetTemplates[i].PrimaryKey == Assets[index].Template) {
        conflict = AssetTemplates[i].Name;
      }
    }
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Conflict'),
      headerClose: false,
      buttonPrompt:
        iTrans('This Asset currently belongs to the Template') +
        ': ' +
        conflict +
        '<br />' +
        iTrans('Do you wish to re-assign the asset to this template') +
        '?',
      buttons: {
        [iTrans('Yes')]: {
          click: function () {
            $.getJSON(
              BASEURL +
                'menu-Assets-AJAX.php?action=updateTemplate&Index=' +
                $('#Asset_Templates').val() +
                '&AssetPK=' +
                Assets[index].PrimaryKey,
              function (json_data) {
                Assets[index].Template = $('#Asset_Templates').val();
                filterAssetTemplateItemArray();
              }
            ).fail(function (jqXHR, status, error) {
              console.log('Failed to get AssetTemplates');
              AJAXFAIL(error);
            });
          },
        },
        [iTrans('Cancel')]: {
          click: function () {},
        },
      },
    });
  }
  //check if item belongs to another template and warn

  //if no other template update asset template on server and array

  //if on template and user acknowledges update asset template on server and array.  call populateAssetTemplates to refresh list ELSE uncheck checkbox and cancel
}

function removeTemplate() {
  var val = $('#Asset_Templates').val();
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('This Template will be permanently deleted') + '!<br />' + iTrans('Are you sure?'),
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          $.getJSON(
            BASEURL + 'menu-Assets-AJAX.php?action=removeTemplate&Index=' + $('#Asset_Templates').val(),
            function (json_data) {
              for (i in Assets) {
                if (Assets[i].Template == val) {
                  Assets[i].Template = 0;
                }
              }
              TemplateIndex = -1;
              init_AssetTemplates();
            }
          ).fail(function (jqXHR, status, error) {
            console.log('Failed to get AssetTemplates');
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}

function renameTemplate() {
  var val = $('#Asset_Templates').val();
  var html = '<h3>' + iTrans('Enter a new name for the template') + "</h3><input type='text' id='NewTname' value='' />";
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Rename Template'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          $.getJSON(
            BASEURL +
              'menu-Assets-AJAX.php?action=renameTemplate&Index=' +
              $('#Asset_Templates').val() +
              '&Name=' +
              $('#NewTname').val(),
            function (json_data) {
              TemplateIndex = $('#Asset_Templates').val();
              init_AssetTemplates();
            }
          ).fail(function (jqXHR, status, error) {
            console.log('Failed to get AssetTemplates');
            AJAXFAIL(error);
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}
