let OFFLINE_BILLING_SYNCING_Flightreports = false;
let OFFLINE_BILLING_SYNCING_Expenses = false;
let OFFLINE_BILLING_SYNCING_BillingData = false;

function BillingDB(action, key, data, callback) {
  const tRef = BillingDataTable.query();

  if (typeof callback != 'function') {
    callback = () => {};
  }

  switch (action) {
    case 'DROP':
      tRef.dropTable().then(
        () => callback(true),
        () => callback(false)
      );
      return;
    case 'INSERT':
      tRef.insert(key, data).then(
        () => callback(true),
        () => callback(false)
      );
      return;
    case 'SELECT':
      tRef.select(key).then(
        (res) => callback(res),
        () => callback(false)
      );
      return;
    case 'DELETE':
      tRef.delete(key).then(
        () => callback(true),
        () => callback(false)
      );
      return;
  }
}

function StoredConfirm(e) {
  verbose.log('BILLING', 'StoredConfirm', e);
}

function BILLING_sync_data() {
  if (BILLING_EDITING_OFFLINE) return; //Do not interrupt user with toasts or syncing if they are editing an offline flight report
  if (!OFFLINE_BILLING_SYNCING_BillingData) BILLING_SYNC_BillingData();

  if (!OFFLINE_BILLING_SYNCING_Flightreports) {
    // Check for offline flight reports to sync.
    BillingDB('SELECT', 'OFFLINE_SYNC', null, (e) => {
      if (!OFFLINE_BILLING_SYNCING_Expenses) {
        // Check for offline flight reports to sync.
        BillingDB('SELECT', 'OFFLINE_SYNC_EXPENSE', null, OfflineExpenseSending);
      }

      OfflineReportSending(e);
    });
  }

  toaster.remove(toasterID_OfflineBillingData);
}

function BILLING_SYNC_BillingData() {
  $('#Itinerary').prop('disabled', true);
  $('#BILLING_NewFlightReport').prop('disabled', true);
  OFFLINE_BILLING_SYNCING_BillingData = true;
  // Attempt to update Itinerary List
  var getAdmin = 1;
  var fromdate = new Date();
  if (LOW_BANDWIDTH) {
    fromdate.setMonth(fromdate.getMonth() - 1);
    getAdmin = 0;
    fromdate = new Date();
    fromdate.setDate(fromdate.getDate() - 7);
  }
  fromdate = fromdate.toISOString().substring(0, 10);
  $.getJSON(
    BASEURL + 'menu-Billing-AJAX.php?action=getItinerary&Start=' + fromdate + '&getAdmin=' + getAdmin,
    function (json_data) {
      if (json_data[0].ServerResponse == null) {
        json_data[0].timestamp = new Date().getTime();
        BillingDB('INSERT', 'Itinerary', json_data, StoredConfirm); //Store Itinerary Data
        BILLING_SYNC_AvailableJobs(false);
      }
    }
  ).fail((jqXHR, status, error) => {
    BILLING_SYNC_AvailableJobs(true);
    verbose.error('BILLING', 'Ajax:', error);
  });
}

function BILLING_SYNC_AvailableJobs(failed) {
  // Populate list of available jobs
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobsCrew', function (json_data) {
    if (json_data[0].ServerResponse == null) {
      BillingDB('INSERT', 'JobsCrew', json_data, StoredConfirm); // Store Jobs Crew Data
      BILLING_SYNC_AllJobs(failed);
    }
  }).fail((jqXHR, status, error) => {
    BILLING_SYNC_AllJobs(true);
    verbose.error('BILLING', 'Ajax:', error);
  });
}

function BILLING_SYNC_AllJobs(failed) {
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getJobsAll', function (json_data) {
    if (json_data[0].ServerResponse == null) {
      BillingDB('INSERT', 'JobsAll', json_data, StoredConfirm); // Store Jobs All Data
      BILLING_SYNC_AllClients(failed);
    }
  }).fail((jqXHR, status, error) => {
    BILLING_SYNC_AllClients(true);
    verbose.error('BILLING', 'Ajax:', error);
  });
}

function BILLING_SYNC_AllClients(failed) {
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getClients', function (json_data) {
    if (json_data[0].ServerResponse == null) {
      BillingDB('INSERT', 'Clients', json_data, StoredConfirm); // Store Jobs All Data
      BILLING_SYNC_Scripts(failed);
    }
  }).fail((jqXHR, status, error) => {
    BILLING_SYNC_Scripts(true);
    verbose.error('BILLING', 'Ajax:', error);
  });
}

function BILLING_SYNC_Scripts(failed) {
  $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=getScripts', function (json_data) {
    if (json_data.length > 0) {
      if (json_data[0].ServerResponse == null) {
        BillingDB('INSERT', 'Scripts', json_data, StoredConfirm); // Store Jobs All Data
        BILLING_SYNC_CompanyData(failed);
      } else {
        BILLING_SYNC_CompanyData(true);
      }
    } else {
      BillingDB('INSERT', 'Scripts', [], StoredConfirm); // Store Jobs All Data
      BILLING_SYNC_CompanyData(failed);
    }
  }).fail((jqXHR, status, error) => {
    BILLING_SYNC_CompanyData(true);
    verbose.error('BILLING', 'Ajax:', error);
  });
}

function BILLING_SYNC_CompanyData(failed) {
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getSettingsV2', function (json_data) {
    if (json_data[0].ServerResponse == null) {
      cirroDB.clearAll('BillingClient', function (e) {
        for (var i in json_data) {
          var I = json_data[i];
          var C = {
            clientPK: I.clientPK,
            data: I,
          };
          cirroDB.insert('BillingClient', C, '', StoredConfirm);
        }
        BILLING_SYNC_ToastInfo(failed);
      });
    } else {
      BILLING_SYNC_ToastInfo(true);
    }
  }).fail((jqXHR, status, error) => {
    verbose.error('BILLING', 'Ajax:', error);
    BILLING_SYNC_ToastInfo(true);
  });
}

var toasterID_OfflineBillingData;
function BILLING_SYNC_ToastInfo(failed) {
  OFFLINE_BILLING_SYNCING_BillingData = false;
  if (failed && ONLINE) {
    toasterID_OfflineBillingData = window.toaster.show(
      iTrans('Failed to download Billing data.') +
        " <a href='#' onClick='BILLING_sync_data();'>" +
        iTrans('Try Again Now') +
        '</a>',
      0
    );
  }
  $('#Itinerary').prop('disabled', false);
  $('#BILLING_NewFlightReport').prop('disabled', false);
  if (!BILLING_SCRIPTS_LOADED) BILLING_LoadScripts();
  CheckOfflineSendItems();
}
var BILLING_SCRIPTS_LOADED = false;
function OfflineReportSending(Reports) {
  if (Reports.length > 0) {
    if (CurPageID() == 'BILLING_FlightReportsPage') {
      $.mobile.loading('show', {
        theme: 'a',
      });
    }
    OFFLINE_BILLING_SYNCING_Flightreports = true;
    Sync_Offline_FlightReports(Reports, 0);
    return;
  }

  OFFLINE_BILLING_SYNCING_Flightreports = false;
}

function RefreshFlightReportListings() {
  let page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  OFFLINE_BILLING_SYNCING_Flightreports = false;

  if (page === 'BILLING_FlightReportsPage') {
    // Refresh Current Lists if viewable to reflect newley sent flight reports
    if (CurPageID() == 'BILLING_FlightReportsPage') BILLING_NewFlightReport(false, true);
  }
}

var toasterID_Offline_FlightReports = null;

function Sync_Offline_FlightReports(Reports, index) {
  if (index == Reports.length) {
    // Synchronization completed successfully delete local offline sync data and refresh lists
    window.toaster.show(Reports.length + ' ' + iTrans('Offline Flight Reports Reports Successfully Uploaded.'));
    OFFLINE_BILLING_SYNCING_Flightreports = false;
    BillingDB('DELETE', 'OFFLINE_SYNC', null, RefreshFlightReportListings);
    return;
  }
  toasterID_Offline_FlightReports = window.toaster.show(
    iTrans('Uploading Offline Flight Report') + ' ' + (parseInt(index) + 1) + ' ' + iTrans('of') + ' ' + Reports.length
  );

  // Send the next report
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Billing-AJAX.php?action=SubmitFlightReport&OFFLINE=1',
    data: Reports[index],
    timeout: 120000, // 2 minutes
  })
    .success(function (json_data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      const status = json_data[0].ServerResponse;

      switch (status) {
        case 'Offline':
          OFFLINE_BILLING_SYNCING_Flightreports = false;
          //this should never happen
          $.mobile.loading('hide');
          billingErrorMessage(
            iTrans('There was a problem sending your offline flight reports to the server. ') +
              ' ' +
              iTrans(
                'Would you like to try and resend the report(s) or wait for a more stable connection?  This should not happen by the way.'
              ),
            () => Sync_Offline_FlightReports(Reports, index),
            () => (OFFLINE_BILLING_SYNCING_Flightreports = false)
          );
          break;

        case 'Uncaught Exception':
          ServerResponse(json_data[0], 'Sync_Offline_FlightReports');
          OFFLINE_BILLING_SYNCING_Flightreports = false;
          $.mobile.loading('hide');
          break;

        case 'Not Logged In':
          NOTLOGGEDIN();
          $.mobile.loading('hide');
          OFFLINE_BILLING_SYNCING_Flightreports = false;
          break;

        case 'Success':
          let next = parseInt(index) + 1;
          window.toaster.remove(toasterID_Offline_FlightReports);
          Sync_Offline_FlightReports(Reports, next);
          break;
      }
    })
    .fail(() => {
      $.mobile.loading('hide');
      OFFLINE_BILLING_SYNCING_Flightreports = false;
      billingErrorMessage(
        iTrans('There was a problem sending your offline flight reports to the server. ') +
          ' ' +
          iTrans('Would you like to try and resend the report(s) or wait for a more stable connection?'),
        () => Sync_Offline_FlightReports(Reports, index)
      );
    });
}

//Offline expense synchronization
function OfflineExpenseSending(Expenses) {
  if (Expenses.length > 0) {
    OFFLINE_BILLING_SYNCING_Expenses = true;
    Sync_Offline_Expenses(Expenses, 0);
    return;
  }

  OFFLINE_BILLING_SYNCING_Expenses = false;
}

function RefreshExpenseListings() {
  $('#BILLING_EXPENSES_OFFLINE').html('');
  OFFLINE_BILLING_SYNCING_Expenses = false;
}

var toasterID_Offline_Expenses = null;

function Sync_Offline_Expenses(Expenses, index) {
  if (index == Expenses.length) {
    window.toaster.show(Expenses.length + ' ' + iTrans('Offline Expenses Successfully Uploaded.'));
    OFFLINE_BILLING_SYNCING_Expenses = false;
    BillingDB('DELETE', 'OFFLINE_SYNC_EXPENSE', null, RefreshExpenseListings); // Synchronization completed successfully delete local offline sync data and refresh lists
  } else {
    //Send the next report
    toasterID_Offline_Expenses = window.toaster.show(
      iTrans('Uploading Offline Expense') + ' ' + (parseInt(index) + 1) + ' ' + iTrans('of') + ' ' + Expenses.length
    );

    let JobID = Expenses[index].JobID;
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: BASEURL + 'menu-Billing-AJAX.php?action=createExpense&JobID=' + JobID,
      data: Expenses[index],
      timeout: 30000, //30 seconds
    })
      .success(function (json_data) {
        $.mobile.loading('hide');
        //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
        const status = json_data[0].ServerResponse;

        switch (status) {
          //status could be offline/Not Logged In/Uncaught Exception
          case 'Offline':
            OFFLINE_BILLING_SYNCING_Expenses = false;
            billingErrorMessage(
              iTrans('There was a problem sending your offline Expenses to the server.') +
                ' ' +
                iTrans('Would you like to try and resend the report(s) or wait for a more stable connection'),
              Expenses,
              index,
              () => Sync_Offline_Expenses(Expenses, index),
              () => (OFFLINE_BILLING_SYNCING_Expenses = false)
            );
            break;

          case 'Uncaught Exception':
            ServerResponse(json_data[0], 'Sync_Offline_Expenses');
            OFFLINE_BILLING_SYNCING_Expenses = false;
            break;

          case 'Not Logged In':
            NOTLOGGEDIN();
            OFFLINE_BILLING_SYNCING_Expenses = false;
            break;

          case 'Success':
            let next = parseInt(index) + 1;
            window.toaster.remove(toasterID_Offline_Expenses);
            Sync_Offline_Expenses(Expenses, next);
            break;
        }
      })
      .fail(() => {
        $.mobile.loading('hide');
        OFFLINE_BILLING_SYNCING_Expenses = false;
        billingErrorMessage(
          iTrans('There was a problem sending your offline Expenses to the server.') +
            ' ' +
            iTrans('Would you like to try and resend the report(s) or wait for a more stable connection'),
          () => Sync_Offline_Expenses(Expenses, index)
        );
      });
  }
}

var billingErrorMessage_ACK = false;
function billingErrorMessage(buttonPrompt, retryCallback = null, cancelCallback = null) {
  if (retryCallback == null) {
    retryCallback = () => {};
  }

  if (cancelCallback == null) {
    cancelCallback = () => {};
  }
  if (!billingErrorMessage_ACK) {
    billingErrorMessage_ACK = true;
    $('<div>').simpledialog2({
      mode: 'button',
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt,
      buttons: {
        [iTrans('Resend Now')]: {
          click: function () {
            billingErrorMessage_ACK = false;
            retryCallback;
          },
        },
        [iTrans('Try Again Later')]: {
          click: function () {
            billingErrorMessage_ACK = false;
            cancelCallback;
          },
        },
      },
    });
  } else {
    return;
  }
}
