class CirroTableData
{
    /**
     * Instantiate a new TableData object
     * @param {string} _id The CSS ID for the table
     */
    constructor(_id)
    {
        this.id          = _id;
        this.grid        = [];
        this.headRows    = [];
        this.cellPointer = {r: 0, c: 0};
        this.rowIds      = {};
    }

    /**
     * Start a new row
     */
    AddRow(_rowId)
    {
        this.cellPointer.r++;
        this.cellPointer.c = 0;

        if (_rowId != null)
        {
            this.rowIds[this.cellPointer.r] = _rowId;
        }
    }

    /**
     * Add a cell to the table
     * @param {string}   _text
     * @param {boolean} [_isHeader]
     * @param {int}      _colspan
     */
    AddCell(_text, _isHeader = false, _colspan = 1)
    {
        this._Cell(_text, _isHeader, false, _colspan);
    }

    /**
     * Add a cell to the table
     * @param {jQuery} _jquery
     * @param {boolean} [_isHeader]
     * @param {int}      _colspan
     */
    AddHtmlCell(_jquery, _isHeader = false, _colspan = 1)
    {
        this._Cell(_jquery, _isHeader, true, _colspan);
    }

    _Cell(_value, _isHeader, _asHtml, _colspan)
    {
        if (!this.grid.hasOwnProperty(this.cellPointer.r))
        {
            this.grid[this.cellPointer.r] = [];
        }

        if (this.grid[this.cellPointer.r].hasOwnProperty(this.cellPointer.c))
        {
            console.warn('UTIL', `Overwrote existing ${this.id}'s cell ${this.cellPointer.r}/${this.cellPointer.c}!`);
        }

        this.grid[this.cellPointer.r][this.cellPointer.c] = {v: _value, h: _isHeader, j: _asHtml, c: _colspan};
        this.cellPointer.c++;
    }

    /**
     * Gets the Markup of the table as a jQuery object
     * @returns {jQuery}
     */
    GetTable(_repeatHeaderAfter)
    {
        let tableDom    = $('<table/>').attr('id', this.id);
        let tableHeader = $('<thead/>');
        let tableBody   = $('<tbody/>');

        tableDom.append(tableHeader);

        let stdRowLength    = 0;
        let rowLength       = 0;
        let runningRowCount = 0;
        for (let r = 0; r < this.grid.length; r++)
        {
            if (!this.grid.hasOwnProperty(r))
            {
                continue;
            }

            let row = this.grid[r];

            let rowDom = $('<tr />');
            for (let c = 0; c < row.length; c++)
            {
                if (!row.hasOwnProperty(c))
                {
                    row[c] = {h: false, v: '', j: false, c: 1};
                }

                let cell    = row[c];
                let cellDom = (cell.h === true) ? $('<th/>') : $('<td/>');
                rowLength += cell.c;

                if (cell.j === true)
                {
                    cellDom.append(cell.v);
                }
                else
                {
                    cellDom.text(cell.v);
                }

                if (cell.c > 1)
                {
                    cellDom.attr('colspan', cell.c);
                }

                cellDom.appendTo(rowDom);
            }

            if (this.rowIds.hasOwnProperty(r))
            {
                rowDom.attr('id', `${this.id}-row-${this.rowIds[r]}`);
            }

            // Header row
            if (this.headRows.hasOwnProperty(r))
            {
                rowDom.appendTo(tableHeader);
                continue;
            }

            rowDom.appendTo(tableBody);
            runningRowCount++;

            if (_repeatHeaderAfter != null && runningRowCount >= _repeatHeaderAfter)
            {
                tableBody.appendTo(tableDom);
                tableBody = $('<tbody/>');
                tableHeader.clone().appendTo(tableDom);
                runningRowCount = 0;
            }

            if (stdRowLength !== 0 && stdRowLength !== rowLength)
            {
                console.warn('UTIL', `Malformed: ${this.id}'s row ${r} has a different number of columns than another row.`);
                stdRowLength = rowLength;
            }
        }

        tableBody.appendTo(tableDom);
        return tableDom;
    }

    AsThead()
    {
        this.headRows.push(this.cellPointer.r);
    }
}

class CirroRequest
{
    /**
     * Instantiate a new request object
     * @param {string}    _p.name
     * @param {string}    _p.script
     * @param {object}   [_p.args]
     * @param {function} [_p.fnSuccess]
     * @param {function} [_p.fnFail]
     */
    constructor(_p)
    {
        this._name   = _p.name || '';
        this._script = _p.script || '';
        this._args   = _p.args || null;

        this._fnSuccess = _p.fnSuccess || null;
        this._fnFail    = _p.fnFail || null;
    }

    /**
     * Executes the request and triggers the callbacks
     */
    Execute()
    {
        if (this._name.length <= 0 || this._script.length <= 0)
        {
            throw 'Invalid Request!';
        }

        $.post(BASEURL +  this._script, this._args, (_data) =>
        {
            let jsonData = JSON.parse(_data);
            let sr       = jsonData[0].ServerResponse;
            if (sr == null)
            {
                console.error('Unknown Server Error in request ' + this._name);
                if (this._fnFail != null)
                {
                    this._fnFail();
                }

                return;
            }

            switch (sr)
            {
                case 'Offline':
                    console.warn('Offline');
                    break;
                case 'Not Logged In':
                    NOTLOGGEDIN();
                    break;
                case 'Uncaught Exception':
                    ServerResponse(jsonData[0], this._name);
                    break;
                default:
                    if (this._fnSuccess != null)
                    {
                        this._fnSuccess(sr, jsonData);
                    }
                    break;
            }
        })
         .fail((jqXHR, status, error) =>
         {
             AJAXFAIL(error);
             if (this._fnFail != null)
             {
                 this._fnFail();
             }
         });
    }
}
