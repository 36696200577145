/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var SYNC_FOLLOW = false;
var WB_INIT = false;
var MAP_NEW_LEG = false; //global used in mapb.js
var FOLLOW_TIMER = null;

function toggleEditItineraryTZ(e) {
  if (e.value == ITIN_TIMEZONE_UTCmode) {
    return;
  }
  var itinDate = $('#edit_DateBack').val();
  var itinTime = $('#edit_TimeBack').val();
  if (itinTime != '') {
    var curDateTime = itinDate + ' ' + itinTime.substr(0, 2) + ':' + itinTime.substr(2, 2) + ':00';
  }
  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    ITIN_TIMEZONE_UTCmode = 'Local';
    var now = new Date();
    ITIN_TIMEZONE = now.getTimezoneOffset();
    if (itinTime != '') {
      var newDateTime = toUTCmode_offset(curDateTime, ITIN_TIMEZONE);
      $('#edit_DateBack').val(newDateTime.substr(0, 10));
      $('#edit_TimeBack').val(newDateTime.substr(11, 2) + newDateTime.substr(14, 2));
    }
    $('#returnEditTimeTXT').html(iTrans('ETA Time') + ' - ' + getLocalTZLabel());
  } else {
    ITIN_TIMEZONE_UTCmode = 'UTC';
    if (itinTime != '') {
      var now = new Date();
      ITIN_TIMEZONE = now.getTimezoneOffset();
      var newDateTime = toUTCmode_offset(curDateTime, -ITIN_TIMEZONE);
      $('#edit_DateBack').val(newDateTime.substr(0, 10));
      $('#edit_TimeBack').val(newDateTime.substr(11, 2) + newDateTime.substr(14, 2));
    }
    ITIN_TIMEZONE = 0;
    $('#returnEditTimeTXT').html(iTrans('ETA Time') + ' - UTC (GMT+00)');
  }
}

function FollowingArray(AC, Itin) {
  let now = new Date();

  var data = {
    AC: {
      AcType: AC.AcType,
      StartStop: AC.StartStop,
      esn: AC.esn,
      group: AC.group,
      ident: AC.ident,
      satphone: AC.satphone,
    },
    Age: '',
    Client: null,
    ClientName: Itin_ClientName,
    Close: 1,
    FuelPlan: FuelPlans,
    GPS: {
      Lat: 0,
      Long: 0,
      altitude: 0,
      dateTime: dateToStringObject(new Date()),
      elevation: 0,
      heading: 0,
      speed: 0,
      telemetry: 'none',
    },
    Itin: {
      AssetTracking: Itin.ASSET,
      Billable: 1,
      Billed: 0,
      Cfields: Itin.Cfields,
      ClientPK: Itin.ClientPK,
      Details: Itin.Details,
      Hash: '',
      JobID: Itin.JobID,
      OfflinePK: Itin.OfflinePK,
      PrimaryKey: 0,
      RA: Itin.RA,
      RoutePlan: Itin.RoutePlan,
      TDG: Itin.TDG,
      TDGRevampManifestId: Itin.TDGRevampManifestId,
      WB: Itin.WB,
      callStatus: '',
      check: '',
      closedby: '',
      dfr: 0,
      editedby: '',
      emailStatus: '',
      esn: AC.esn,
      filed: Itin.filed,
      flight_report_id: null,
      group: AC.group,
      group_pk: AC.group_pk,
      ident: AC.ident,
      ResponsablePK: Itin.ResponsablePK,
      liferaft: Itin.liferaft,
      paxnum: Itin.paxnum,
      pilot: Itin.pilot,
      report_not_required: 0,
      searchtime: Itin.searchtime,
      sic: Itin.sic,
      smsStatus: '',
      status: 'OFFLINE',
      survivalkit: Itin.survivalkit,
      timeback: Itin.searchtime,
      timeleft: '',
      RA_Data: Itin.RA_Data,
      Pax_Manifest: Itin.Pax_Manifest,
    },
    JobName: Itin_JobName,
    Logs: null,
    QA: 0,
    Route: Itin.Route,
    Schedule: null,
    TDG: null,
    WB: null,
  };
  if (Itin.TDG == 1) {
    data.TDG = [Itin.TDGdata];
  }
  if (Itin.WB == 1) {
    data.WB = [];
    if (SCH_DATA.LEGS.length > 0) {
      for (var i in SCH_DATA.LEGS) {
        var L = SCH_DATA.LEGS[i];
        var Active = 0;
        if (i == 0) Active = 1;
        var WB = {
          Cfields: L.Cfields,
          WB_PK: 0,
          WB_OfflinePK: Itin.OfflinePK,
          LegNum: L.LegNum,
          timezone: ITIN_TIMEZONE,
          LegStartE: L.LegStartE,
          LegStart: L.LegStart,
          LegEnd: L.LegEnd,
          LegEndE: L.LegEndE,
          LegETA: L.LegETA,
          LegOrigin: L.LegOrigin,
          LegRouting: L.LegRouting,
          LegDest: L.LegDest,
          Pilot: L.Pilot,
          sic: L.sic,
          SIC: L.sic,
          flightCatID: L.flightCatID,
          acwt: L.acwt,
          fuelwt: L.fuelwt,
          otherwt: L.otherwt,
          CargoWeight: L.CargoWeight,
          cofglat: L.cofglat,
          cofglong: L.cofglong,
          tempconfig: L.tempconfig,
          wbDate: L.wbDate,
          Eweight: L.Eweight,
          maxperfweight: Itin.maxperfweight,
          Ehmom: L.Ehmom,
          Elmom: L.Elmom,
          TCdesc: L.TCdesc,
          Summary: L.Summary,
          GRAPH: Itin.GRAPH,
          PaxNum: Itin.paxnum,
          Description: L.Description,
          Changed: dateToStringObject(new Date()),
          Active: Active,
        };
        data.WB.push(WB);
      }
    } else {
      var WB = {
        Cfields: ITIN_Cfields,
        WB_PK: 0,
        WB_OfflinePK: Itin.OfflinePK,
        LegNum: 0,
        timezone: ITIN_TIMEZONE,
        LegStartE: '0',
        LegStart: '0',
        LegEnd: '0',
        LegEndE: '0',
        LegETA: '0000',
        LegOrigin: '',
        LegRouting: '',
        LegDest: '',
        Pilot: Itin.pilot,
        sic: Itin.sic,
        SIC: Itin.sic,
        flightCatID: 0,
        acwt: Itin.acwt,
        fuelwt: Itin.fuelwt,
        otherwt: Itin.otherwt,
        CargoWeight: Itin.CargoWeight,
        cofglat: Itin.cofglat,
        cofglong: Itin.cofglong,
        tempconfig: Itin.tempconfig,
        wbDate: Itin.wbDate,
        Eweight: Itin.Eweight,
        maxperfweight: Itin.maxperfweight,
        Ehmom: Itin.Ehmom,
        Elmom: Itin.Elmom,
        TCdesc: Itin.TCdesc,
        Summary: Itin.Summary,
        GRAPH: Itin.GRAPH,
        PaxNum: Itin.paxnum,
        Description: '',
        Changed: dateToStringObject(new Date()),
        Active: Active,
      };
      data.WB.push(WB);
    }
  }
  return data;
}

function resetFollowUpdate() {
  if (FOLLOW_TIMER != null) clearTimeout(FOLLOW_TIMER);
  //WB_INIT = true; //test fix
  FOLLOW_TIMER = setTimeout('getFollowData()', 120000); //1000 = 1 second default 120000 or 2 minutes
}
var WAIT_FOR_OFFLINE_UPLOAD = false;
function getFollowData(force = false) {
  if (LOW_BANDWIDTH) $('#followRefreshBTN').html('<img src="./images/refreshNOW.svg" width="30px">');
  else $('#followRefreshBTN').html('<img src="./images/refresh.svg" width="30px">');
  if (WAIT_FOR_OFFLINE_UPLOAD && window.ONLINE) {
    $('#FollowingTitle').html(iTrans('Waiting for offline data to upload'));
    if (Follow_Data.length == 0) dbFollowData();
    return;
  }

  if (!force && CurPageID() == 'WB_Page2') {
    //To prevent overwritting leg data while editing
    resetFollowUpdate();
    return;
  }
  if (LOW_BANDWIDTH && !force) {
    dbFollowData();
    resetFollowUpdate();
    return;
  }
  //console.log("GetFollowData");
  if (window.ONLINE && window.vueApp.authTools.authUserHasSession()) {
    $.getJSON(BASEURL + 'menu-Following-AJAX.php?action=getfollowData&timezone=' + ITIN_TIMEZONE, function (json_data) {
      if (json_data[0].ServerResponse != undefined) {
        //status could be offline/Not Logged In/Uncaught Exception
        if (json_data[0].ServerResponse == 'No aircraft in database') {
          $('#showFollowOpen').html('<h4>' + iTrans('There are no aircraft for your group in the system') + '</h4>');
          $('#showFollowAirborne').html('');
          $('#showFollowStatus').html('');
          refreshFollowData([]);
        }
        if (json_data[0].ServerResponse == 'Offline') {
          //console.log("Offline getting local follow data");
          dbFollowData(); //retrieve old data from database
        }
        if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getFollowData');
        if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      } else {
        //console.log("Received Following Data");
        refreshFollowData(json_data);
      }
    }).fail(function (jqXHR, status, error) {
      //console.log("Communication Error getting following data attempting local load");
      if (Follow_Data.length == 0) dbFollowData();
      OfflineTriggered('Failed to get follow data');
    });
  } else {
    dbFollowData();
  }
  resetFollowUpdate();
}

function refreshFollowData(data) {
  createFollowDB(data); //no longer delete database just allow for updating
}

function createFollowDB(data) {
  updateFollowData(data); //first check
}

function updateFollowData(data) {
  var date = new Date();
  var Fdata = {
    PrimaryKey: 1,
    date: dateToStringObject(date),
    data: JSON.stringify(data),
  };
  $('#FollowingTitle').html(iTrans('Updated') + ': ' + Fdata.date + 'Z');
  cirroDB.insert('Following', Fdata, null, function (e) {
    Follow_Data = data;
    if (WB_INIT) {
      WB_fileaclist();
      WB_INIT = false;
      if (!SYNC_FOLLOW) reloadFollow();
    } else {
      if (!SYNC_FOLLOW) reloadFollow();
      WB_fileaclist(true); //to enable new leg button in map if necessary
    }
    SYNC_FOLLOW = false;
    //console.log("Local Following Database Updated");
  });
}

function dbFollowData() {
  //retrieve flight following data from database and send to createFollowPages
  cirroDB.query('Following', 'PrimaryKey = ?', 1, function (e) {
    if (!e) {
      return;
    }

    Follow_Data = e.data;
    $('#FollowingTitle').html(iTrans('Offline') + ': ' + e.date + 'Z');
    if (e === false) Follow_Data = [];
    if (WB_INIT) {
      WB_fileaclist(true);
      WB_INIT = false;
      if (!SYNC_FOLLOW) reloadFollow();
    } else {
      if (!SYNC_FOLLOW) reloadFollow();
      WB_fileaclist(true); //to enable new leg button in map if necessary
    }
    SYNC_FOLLOW = false;
  });
}
var LastFollowPage = 'Status';

function reloadFollow() {
  switch (LastFollowPage) {
    case 'Open':
      showFollowOpen();
      break;
    case 'Airborne':
      showFollowAirborne();
      break;
    case 'Status':
      showFollowStatus();
      break;
    case 'Maps':
      showFollowMaps();
      break;
  }
}
var Follow_Data = [];
var FollowDate;

function clear_FollowMaps_STATE() {
  for (var i in FollowMaps_STATE) {
    FollowMaps_STATE[i].remove();
  }
  FollowMaps_STATE = [];
}
function showFollowOpen() {
  clear_FollowMaps_STATE();
  $('#showFollowMaps').html('');

  document.getElementById('FollowStatus').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowAirborne').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowOpen').style.background = 'rgba(99,99,99,0.1)';
  document.getElementById('FollowMaps').style.background = 'rgba(99,99,99,0)';
  //console.log("showFollowOpen");
  $('#showFollowAirborne').hide('fast');
  $('#showFollowStatus').hide('fast');
  $('#showFollowMaps').hide('fast');
  $('#showFollowOpen').show('fast');
  LastFollowPage = 'Open';
  UpdateFollowStatus('showFollowOpen');
}

function showFollowAirborne() {
  clear_FollowMaps_STATE();
  $('#showFollowMaps').html('');

  document.getElementById('FollowStatus').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowAirborne').style.background = 'rgba(99,99,99,0.1)';
  document.getElementById('FollowOpen').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowMaps').style.background = 'rgba(99,99,99,0)';
  $('#showFollowAirborne').show('fast');
  $('#showFollowOpen').hide('fast');
  $('#showFollowMaps').hide('fast');
  $('#showFollowStatus').hide('fast');
  LastFollowPage = 'Airborne';
  UpdateFollowStatus('showFollowAirborne');
}

function showFollowStatus() {
  clear_FollowMaps_STATE();
  $('#showFollowMaps').html('');

  document.getElementById('FollowStatus').style.background = 'rgba(99,99,99,0.1)';
  document.getElementById('FollowAirborne').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowOpen').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowMaps').style.background = 'rgba(99,99,99,0)';
  //console.log("ShowFollowStatus");
  $('#showFollowAirborne').hide('fast');
  $('#showFollowOpen').hide('fast');
  $('#showFollowMaps').hide('fast');
  $('#showFollowStatus').show('fast');
  LastFollowPage = 'Status';

  UpdateFollowStatus('showFollowStatus');
}

function showFollowMaps() {
  document.getElementById('FollowStatus').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowAirborne').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowOpen').style.background = 'rgba(99,99,99,0)';
  document.getElementById('FollowMaps').style.background = 'rgba(99,99,99,0.1)';
  //console.log("ShowFollowStatus");
  $('#showFollowAirborne').hide('fast');
  $('#showFollowOpen').hide('fast');
  $('#showFollowStatus').hide('fast');
  $('#showFollowMaps').show('fast');
  LastFollowPage = 'Maps';

  UpdateFollowStatus('showFollowMaps');
}
var FollowMaps_STATE = [];
function MakeFollowMaps(Data) {
  var HeadingColour = LOCALSTORAGE.GPSHEADINGCOLOUR;
  if (HeadingColour != undefined) MAPSTATE.HeadingColour = HeadingColour;
  var baseType = 'Satellite';
  if (LOCALSTORAGE.MapType != undefined) {
    baseType = LOCALSTORAGE.MapType;
    MAPSTATE.Type = baseType;
  }
  mapboxgl.accessToken = MAPBOX_TOKEN;
  /*
  var double = JSON.parse(JSON.stringify(Data));
  for(var i in double){
    Data.push(double[i]);
  }
  */
  var count = 0;
  var F = $('#FollowingStatus_Map_Filter').val();
  for (var i in Data) {
    var I = Data[i];
    if (I.GPS == null) continue;
    if (F == 'airborne') {
      if (checkAirbornStatus(I.GPS.dateTime) == 'Landed') continue;
    }
    if (F == 'itinerary' && I.Itin == null) continue;
    if (
      (F == 'airborne_itinerary' && I.Itin == null) ||
      (F == 'airborne_itinerary' && checkAirbornStatus(I.GPS.dateTime) == 'Landed')
    )
      continue;
    count++;
    console.log(I);
    if (count < 15) MakeFollowMap(i, I);
  }
  if (count > 14) {
    alert(iTrans('Over Maximum of 14 aircraft, showing first 14 only'));
  }
}

function checkAirbornStatus(GPS_UTC) {
  var gpsTime = GPS_UTC.split(' ');
  gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
  var now = new Date();
  var gpsElapsed = Math.round((now - gpsTime) / 1000);
  var Fstatus = iTrans('Landed');
  if (gpsElapsed < 900) {
    Fstatus = iTrans('Airborne');
  }
  return Fstatus;
}

function MakeFollowMap(i, I, divHLDR = 'showFollowMaps') {
  var Fstatus = checkAirbornStatus(I.GPS.dateTime);
  if (I.Itin == null) {
    Fstatus += iTrans('Closed');
  } else {
    switch (I.Itin.status) {
      case 'CLOSED':
        Fstatus += iTrans('Closed');
        break;
      case 'OPEN':
        Fstatus += iTrans('Open');
        break;
      case 'PENDING':
        Fstatus += iTrans('PENDING');
        break;
      case 'OFFLINE':
        fstatus += iTrans('Offline');
        break;
      case 'OVERDUE':
        Fstatus += iTrans('Overdue');
        break;
      case 'ALARM-T1':
        Fstatus += iTrans('Overdue');
        break;
      case 'ALARM-T2':
        Fstatus += iTrans('Overdue');
        break;
      case 'ALARM-T3':
        Fstatus += iTrans('Overdue');
        break;
      case 'SYSOP':
        Fstatus += iTrans('Overdue');
        break;
      case 'WARNING':
        Fstatus += iTrans('Overdue');
        break;
    }
  }
  var point = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [I.GPS.Long, I.GPS.Lat],
    },
  };
  var distance = 1; //distance for heading line to extend;
  var heading = I.GPS.heading;
  var speed = I.GPS.speed;
  if (isNaN(speed)) speed = 0;
  if (isNaN(heading)) heading = 0;
  if (speed > 0) {
    distance = speed * 60 * 10;
  }
  var units = { units: 'meters' };
  var destination = turf.destination(point, distance, heading, units);
  //console.log(distance);
  //console.log(Fstatus);
  var json_data = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {
          Ident: I.AC.ident,
          Heading: I.GPS.heading,
          Speed: I.GPS.speed,
          Icon: Fstatus,
          Index: i,
          Distance: distance,
        },
        geometry: {
          type: 'Point',
          coordinates: [I.GPS.Long, I.GPS.Lat],
        },
      },
      {
        type: 'Feature',
        properties: {
          label: iTrans('Distance in 10 minutes'),
          distance: round10(distance * 0.000539957),
        },
        geometry: {
          type: 'LineString',
          coordinates: [[I.GPS.Long, I.GPS.Lat, 0], destination.geometry.coordinates],
        },
      },
    ],
  };
  var MapContainer = 'FollowMaps_' + i;
  if (FollowMaps_STATE[i] != undefined) {
    if (FollowMaps_STATE[i].getSource('AIRCRAFT') != undefined)
      FollowMaps_STATE[i].getSource('AIRCRAFT').setData(json_data);
    FFmapInfo(i, 'INFO_' + MapContainer);
    followMapFlyTo(i, I.GPS.Lat, I.GPS.Long, false);
    return;
  }

  $('#' + divHLDR).append('<div class="mapb-flex-item" id="' + MapContainer + '"></div>');
  FollowMaps_STATE[i] = new mapboxgl.Map({
    container: MapContainer, // container id
    style: getCirroStyle(MAPSTATE.Type, true),
    center: [0, 0], // starting position
    zoom: 0, // starting zoom
    minzoom: 0,
    pitch: 0,
    bearing: 0,
  });

  if (IOS || ANDROID) {
    //use two finger drag on mini maps so that we don't have issues for following maps.
    var isTouchEvent = (e) => e.originalEvent && 'touches' in e.originalEvent;
    var isTwoFingerTouch = (e) => e.originalEvent.touches.length >= 2;
    FollowMaps_STATE[i].on('dragstart', (event) => {
      if (isTouchEvent(event) && !isTwoFingerTouch(event)) {
        FollowMaps_STATE[i].dragPan.disable();
      }
    });

    // Drag events not emited after dragPan disabled, so I use touch event here
    FollowMaps_STATE[i].on('touchstart', (event) => {
      if (isTouchEvent(event) && isTwoFingerTouch(event)) {
        FollowMaps_STATE[i].dragPan.enable();
      }
    });
  }
  FollowMaps_STATE[i].on('load', function () {
    if (FollowMaps_STATE[i].getSource('AIRCRAFT') == undefined) {
      FollowMaps_STATE[i].addSource('AIRCRAFT', {
        type: 'geojson',
        data: json_data,
      });
      FollowMaps_STATE[i].addLayer({
        id: 'HEADING_DIST',
        type: 'line',
        source: 'AIRCRAFT',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': MAPSTATE.HeadingColour,
          'line-width': 4,
          'line-opacity': 0.7,
        },
      });
      FollowMaps_STATE[i].addLayer({
        id: 'AIRCRAFT',
        type: 'symbol',
        source: 'AIRCRAFT',
        layout: {
          'icon-image': '{Icon}',
          'icon-size': 0.4,
          'icon-rotate': {
            property: 'Heading',
            type: 'identity',
          },
          visibility: 'visible',
          'icon-padding': 0,
          'icon-offset': [0, 0],
          'icon-rotation-alignment': 'map',
          'icon-ignore-placement': true,
          'icon-allow-overlap': true,
          'symbol-placement': 'point',
          'text-field': '{Ident}',
          'text-size': TEXT_SIZE,
          'text-justify': 'center',
          'text-anchor': 'right',
          'text-optional': true,
          'text-offset': [5, 0],
        },
        paint: {
          'icon-opacity': 0.8,
          'text-halo-color': TEXT_HALO_COLOR,
          'text-halo-width': TEXT_HALO_WIDTH,
          'text-halo-blur': TEXT_HALO_BLUR,
          'text-color': TEXT_COLOR,
        },
      });

      function infoControl() {}
      infoControl.prototype.onAdd = function (mapb) {
        this._map = FollowMaps_STATE[i];
        this._container = document.createElement('div');
        this._container.className = 'transbg';
        this._container.innerHTML = '<div id="INFO_' + MapContainer + '"></div>';
        return this._container;
      };
      infoControl.prototype.onRemove = function () {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
      };
      FollowMaps_STATE[i].addControl(new infoControl(), 'top-left');
      FFmapInfo(i, 'INFO_' + MapContainer);
      FollowMaps_STATE[i].on('click', function (e) {
        if (divHLDR == 'showTrackerMaps') {
          getTrackingInfoPopup(e, FollowMaps_STATE[i]);
        } else {
          getFollowInfoPopup(e, FollowMaps_STATE[i]);
        }
      });

      //$("#INFO_" + MapContainer).html("Test Info Data");
    } else {
      FollowMaps_STATE[i].getSource('AIRCRAFT').setData(json_data);
    }
    FollowMaps_STATE[i].resize();
    followMapFlyTo(i, I.GPS.Lat, I.GPS.Long, true);
  });
}

function getFollowInfoPopup(e, mapb) {
  var features = mapb.queryRenderedFeatures(e.point, {
    layers: ['AIRCRAFT'],
  });
  if (!features.length) {
    return;
  }
  var O = features[0].properties;
  FFpopup(O.Index);
}

function followMapFlyTo(i, Lat, Long, init) {
  var point = new mapboxgl.LngLat(Long, Lat);
  if (init) {
    FollowMaps_STATE[i].flyTo({
      center: point,
      zoom: 8,
    });
  } else {
    FollowMaps_STATE[i].flyTo({
      center: point,
    });
  }
}

function RefreshFollowMaps() {
  clear_FollowMaps_STATE();
  $('#showFollowMaps').html('');
  showFollowMaps();
}

function UpdateFollowStatus(div) {
  $('#showFollowAirborne').html('');
  $('#showFollowOpen').html('');
  $('#showFollowStatus').html('');
  $('#FollowingStatusHeader').show();
  $('#FollowingStatusFilters').hide();
  clearMapElapsedTimer();
  clearElapsedTimer();
  clearCountDownTimer();
  if (div == 'showFollowMaps') {
    $('#FollowingStatusHeader').hide();
    $('#FollowingStatusFilters').show();
    MakeFollowMaps(Follow_Data);
    return;
  }
  $('#' + div).html('<h3>All Aircraft</h3>');
  if (LOW_BANDWIDTH) {
    $('#' + div).html(
      "<h3><span class='yellowbg'>Low Bandwidth Enabled.  Click Refresh to see updates.</span><br />All Aircraft</h3>"
    );
  }
  $('#' + div).append(
    '<div style="width: 100%; overflow: auto"><table id="FollowStatusTable" data-filter="true" class="limit-table last-td-fill">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Ident') +
      '</th>' +
      '<th data-priority="1">' +
      iTrans('Telemetry') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Position Age') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Itinerary') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Time Left') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Pilot') +
      '</th>' +
      '<th data-priority="3">' +
      iTrans('Action') +
      '</th>' +
      '<th></th>' +
      '</tr>' +
      '</thead>' +
      '<tbody><tr>'
  );
  var count = 0;
  //console.log(Follow_Data);

  for (var i in Follow_Data) {
    //create html for each page
    var AFF = Follow_Data[i];

    var Satphone =
      '<button data-mini="true" class="action-btn" disabled><img src="./images/mail-black.png" width="70%" /></button>';
    if (AFF.AC.satphone != null && AFF.AC.satphone != '') {
      Satphone =
        '<button data-mini="true" class="action-btn" onClick="SMS_Messenger(\'' +
        AFF.AC.satphone +
        '\')"><img src="./images/mail-black.png" width="70%" /></button>';
    }
    //console.log("Index: "+i + ": "+AFF.AC.ident);
    var gpsTime = 'N/A';
    if (AFF.AC.maskingON) {
      AFF.GPS = null;
      gpsTime = '<span class="redbg">' + iTrans('GPS Tracking Masked') + '</span>';
    }
    if (AFF.GPS != null) {
      gpsTime = AFF.GPS.dateTime.split(' ');
      gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
    }
    var Itin = 'CLOSED';
    var lineClass = '';
    if (AFF.Itin != null) {
      Itin = AFF.Itin.status;
      switch (AFF.Itin.status) {
        case 'WARNING':
          Itin =
            '<button data-testid="following-itininerary-flight-warning-button" class="cirro-btn yellowbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('WARNING') +
            '</button>';
          break;
        case 'ALARM-T1':
          Itin =
            '<button data-testid="following-itininerary-flight-alarmt1-button" class="cirro-btn redbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('OVERDUE') +
            '-T1</button>';
          break;
        case 'ALARM-T2':
          Itin =
            '<button data-testid="following-itininerary-flight-alarmt2-button" class="cirro-btn redbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('OVERDUE') +
            '-T2</button>';
          break;
        case 'ALARM-T3':
          Itin =
            '<button data-testid="following-itininerary-flight-alarmnt3-button" class="cirro-btn redbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('OVERDUE') +
            '-T3</button>';
          break;
        case 'SYSOP':
          Itin =
            '<button data-testid="following-itininerary-flight-sysop-button" class="cirro-btn redbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('OVERDUE') +
            '-EMERG</button>';
          break;
        case 'OPEN':
          Itin =
            '<button data-testid="following-itininerary-flight-open-button-' +
            AFF.AC.ident +
            '" class="cirro-btn greenbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('OPEN') +
            '</button>';
          break;
        case 'PENDING':
          Itin =
            '<button data-testid="following-itininerary-flight-pending-button-' +
            AFF.AC.ident +
            '" class="cirro-btn yellowbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('PENDING') +
            '</button>';
          break;
        case 'OFFLINE':
          Itin =
            '<button data-testid="following-itininerary-flight-offline-button" class="cirro-btn yellowbg" onClick="FFpopup(' +
            i +
            ')">' +
            iTrans('OFFLINE') +
            '</button>';
          break;
      }
    }
    var now = new Date();
    var gpsElapsed = Math.round((now - gpsTime) / 1000);
    var Fstatus = 'Landed';
    var abClass = '';
    var maskFF_BTN = '';
    if (
      window.vueApp.authTools.authIsSuperUser(window.vueApp.groupKey) ||
      VueBridge.hasPermission('flights.itineraries.administrate')
    ) {
      if (APP_ENVIRONMENT == 'local' || APP_ENVIRONMENT == 'staging' || GROUP_DATA.group == 'transportcanada') {
        maskFF_BTN =
          '<button id="maskFF_AC-' +
          i +
          '" data-mini="true" class="action-btn" onClick="getFF_AC_Settings(' +
          i +
          ')"><img src="./images/nogps.jpeg" width="35px"/></button>';
      }
    }
    if (gpsElapsed < 900) {
      Fstatus = 'Airborne';
      abClass = 'greenbg';
    }
    if (div == 'showFollowAirborne' && Fstatus != 'Airborne') continue; //if showing only airborn aircraft skip if not airborne
    if (div == 'showFollowOpen' && Itin == 'CLOSED') continue; //if showing only airborn aircraft skip if not airborne

    if (Itin == 'CLOSED') {
      Itin =
        "<button data-testid='following-itininerary-flight-close-" +
        AFF.AC.ident +
        "' class='cirro-btn' onClick='FFpopup(" +
        i +
        ")'>" +
        iTrans('CLOSED') +
        '</button>';
      if (AFF.GPS == null) {
        $('#' + div + ' tr:last').after(
          '<tr><td>' +
            AFF.AC.ident +
            '</td><td>N/A</td><td>' +
            gpsTime +
            '</td><td>' +
            Itin +
            '</td><td><div id="FF_TIMELEFT' +
            i +
            '"></div></td><td>N/A</td><td><div class="vertical-center-container"><button class="action-btn" value="' +
            i +
            '" onClick="showACMAP(' +
            i +
            ')" disabled><img src="./images/ffmapIcon.svg" /></button><button class="action-btn" value="' +
            i +
            '"  disabled><img src="./images/DirectTo.svg" /></button><button id="statusAC-' +
            i +
            '" data-mini="true" class="action-btn" disabled><img src="./images/edit.svg" /></button>' +
            Satphone +
            maskFF_BTN +
            '</div></td><td></td></tr>'
        );
      } else {
        if (IOS) {
          $('#' + div + ' tr:last').after(
            '<tr><td>' +
              AFF.AC.ident +
              '</td><td class="' +
              abClass +
              '">' +
              Fstatus +
              '</td><td><div id="FF_GPS_TIME' +
              i +
              '"></div></td><td>' +
              Itin +
              '</td><td><div id="FF_TIMELEFT' +
              i +
              '"></div></td><td>N/A</td><td><div class="vertical-center-container"><button class="action-btn" value="' +
              i +
              '" onClick="showACMAP(' +
              i +
              ')"><img src="./images/ffmapIcon.svg" /></button><button class="action-btn" value="' +
              i +
              '" ><a href="http://maps.apple.com/?saddr=Current+Location&daddr=' +
              AFF.GPS.Lat +
              ',' +
              AFF.GPS.Long +
              ' " data-ajax="false" target="_system" ><img src="./images/DirectTo.svg" /></a></button><button id="statusAC-' +
              i +
              '" data-mini="true" class="action-btn" disabled><img src="./images/edit.svg" /> </button>' +
              Satphone +
              maskFF_BTN +
              '</div></td><td></td></tr>'
          );
        } else {
          $('#' + div + ' tr:last').after(
            '<tr><td>' +
              AFF.AC.ident +
              '</td><td class="' +
              abClass +
              '">' +
              Fstatus +
              '</td><td><div id="FF_GPS_TIME' +
              i +
              '"></div></td><td>' +
              Itin +
              '</td><td><div id="FF_TIMELEFT' +
              i +
              '"></div></td><td>N/A</td><td><div class="vertical-center-container"><button class="action-btn" value="' +
              i +
              '" onClick="showACMAP(' +
              i +
              ')"><img src="./images/ffmapIcon.svg" /></button><button class="action-btn" value="' +
              i +
              '" ><a href="http://maps.google.com/maps?saddr=Current+Location&daddr=' +
              AFF.GPS.Lat +
              ',' +
              AFF.GPS.Long +
              ' " data-ajax="false" target="_blank" ><img src="./images/DirectTo.svg" /></a></button><button id="statusAC-' +
              i +
              '" data-mini="true" class="action-btn" disabled><img src="./images/edit.svg" /> </button>' +
              Satphone +
              maskFF_BTN +
              '</div></td><td></td></tr>'
          );
        }
        ElapsedTimer(gpsTime, 'FF_GPS_TIME' + i);
      }
    } else {
      //console.log("Index: "+i + ": "+AFF.AC.ident);
      var VIP_CLASS = '';
      var VIP_TXT = '';
      if (AFF.Itin.IsVIP == 1 || AFF.Itin.VIP == 1) {
        VIP_CLASS = 'vip-following';
        VIP_TXT = '<br />[VIP ' + iTrans('On Board') + ']';
      }
      if (AFF.GPS == null) {
        $('#' + div + ' tr:last').after(
          '<tr class="' +
            VIP_CLASS +
            '"><td>' +
            AFF.AC.ident +
            '</td><td>N/A</td><td>' +
            gpsTime +
            '</td><td>' +
            Itin +
            '</td><td><div id="FF_TIMELEFT' +
            i +
            '"></div></td><td>' +
            AFF.Itin.pilot +
            VIP_TXT +
            '</td><td><div class="vertical-center-container"><button class="action-btn" value="' +
            i +
            '" onClick="showACMAP(' +
            i +
            ')" disabled><img src="./images/ffmapIcon.svg" /></button><button class="action-btn" value="' +
            i +
            '"  disabled><img src="./images/DirectTo.svg" /></button><button data-testid="following-edit-itin-' +
            AFF.AC.ident +
            '" id="statusAC-' +
            i +
            '" data-mini="true" class="action-btn" value="' +
            AFF.Itin.PrimaryKey +
            '" onClick="EditItin(' +
            i +
            ', ' +
            AFF.Itin.PrimaryKey +
            ', true)"><img src="./images/edit.svg" /></button>' +
            Satphone +
            maskFF_BTN +
            '</div></td><td></td></tr>'
        );
      } else {
        if (IOS) {
          $('#' + div + ' tr:last').after(
            '<tr class="' +
              VIP_CLASS +
              '"><td>' +
              AFF.AC.ident +
              '</td><td class="' +
              abClass +
              '">' +
              Fstatus +
              '</td><td><div id="FF_GPS_TIME' +
              i +
              '"></div></td><td>' +
              Itin +
              '</td><td><div id="FF_TIMELEFT' +
              i +
              '"></div></td><td>' +
              AFF.Itin.pilot +
              VIP_TXT +
              '</td><td><div class="vertical-center-container"><button class="action-btn" value="' +
              i +
              '" onClick="showACMAP(' +
              i +
              ')"><img src="./images/ffmapIcon.svg" /></button><button class="action-btn" value="' +
              i +
              '" ><a href="http://maps.apple.com/?saddr=Current+Location&daddr=' +
              AFF.GPS.Lat +
              ',' +
              AFF.GPS.Long +
              ' " data-ajax="false" target="_system" ><img src="./images/DirectTo.svg" /></a></button><button data-testid="following-edit-itin-' +
              AFF.AC.ident +
              '" id="statusAC-' +
              i +
              '" data-mini="true" class="action-btn" value="' +
              AFF.Itin.PrimaryKey +
              '"onClick="EditItin(' +
              i +
              ', ' +
              AFF.Itin.PrimaryKey +
              ', true)"><img src="./images/edit.svg" /></button>' +
              Satphone +
              maskFF_BTN +
              '</div></td><td></td></tr>'
          );
        } else {
          $('#' + div + ' tr:last').after(
            '<tr class="' +
              VIP_CLASS +
              '"><td>' +
              AFF.AC.ident +
              '</td><td class="' +
              abClass +
              '">' +
              Fstatus +
              '</td><td><div id="FF_GPS_TIME' +
              i +
              '"></div></td><td>' +
              Itin +
              '</td><td><div id="FF_TIMELEFT' +
              i +
              '"></div></td><td>' +
              AFF.Itin.pilot +
              VIP_TXT +
              '</td><td><div class="vertical-center-container"><button class="action-btn" value="' +
              i +
              '" onClick="showACMAP(' +
              i +
              ')"><img src="./images/ffmapIcon.svg" /></button><button class="action-btn" value="' +
              i +
              '" ><a href="http://maps.google.com/maps?saddr=Current+Location&daddr=' +
              AFF.GPS.Lat +
              ',' +
              AFF.GPS.Long +
              ' " data-ajax="false" target="_blank" ><img src="./images/DirectTo.svg" /></a></button><button data-testid="following-edit-itin-' +
              AFF.AC.ident +
              '" id="statusAC-' +
              i +
              '" data-mini="true" class="action-btn" value="' +
              AFF.Itin.PrimaryKey +
              '" onClick="EditItin(' +
              i +
              ', ' +
              AFF.Itin.PrimaryKey +
              ', true)"><img src="./images/edit.svg" /></button>' +
              Satphone +
              maskFF_BTN +
              '</div></td><td></td></tr>'
          );
        }
        ElapsedTimer(gpsTime, 'FF_GPS_TIME' + i);
        if (AFF.Close !== 1) $('#statusAC-' + i).prop('disabled', true);
      }
    }
    if (AFF.Itin != null) {
      if (AFF.Itin.timeback == undefined) AFF.Itin.timeback = AFF.Itin.searchtime;
      var time = AFF.Itin.timeback.split(' ');
      var timeback = new Date(time[0] + 'T' + time[1] + 'Z');
      CountDownTimer(timeback, 'FF_TIMELEFT' + i);
    } else {
      $('#FF_TIMELEFT' + i).html('N/A');
    }
    count++;
  }
  $('#' + div + ' tr:last').after('</tbody></table></div>');
  if (count == 0) $('#' + div).html('<h3>' + iTrans('There are no aircraft to list here') + '</h3>');
  else {
    $('#' + div).enhanceWithin();
    AddTableSorter('FollowStatusTable');
    /*$("#FollowStatusTable").tablesorter({
        	theme: 'grey',
        	headerTemplate:'{icon}{content}',
        	cssIcon: 'tablesorter-icon'
        	});*/
  }
  //alert(count);
}

function CountDownTimer(dt, id) {
  var end = new Date(dt);

  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var timer;

  function showRemaining() {
    var now = new Date();
    var distance = end - now;
    if (distance < 0) {
      //clearInterval(timer);
      distance = now - end;

      try {
        document.getElementById(id).innerHTML = '<span class="sama-form-error">' + iTrans('Overdue') + '! </span>';
      } catch (e) {
        return;
      }
    } else {
      try {
        document.getElementById(id).innerHTML = '';
      } catch (e) {
        console.log('CountDownTimer Id Error');
        return;
      }
    }
    var days = Math.floor(distance / _day);
    var hours = Math.floor((distance % _day) / _hour);
    var minutes = Math.floor((distance % _hour) / _minute);
    var seconds = Math.floor((distance % _minute) / _second);

    if (days != 0) document.getElementById(id).innerHTML += days + 'd ';
    if (hours != 0) document.getElementById(id).innerHTML += hours + 'h ';
    if (minutes != 0) document.getElementById(id).innerHTML += minutes + 'm ';
    try {
      document.getElementById(id).innerHTML += seconds + 's';
    } catch (e) {
      return;
    }
  }
  Countdowns.push(setInterval(showRemaining, 500));
}

function clearElapsedTimer() {
  for (i in Timers) {
    clearInterval(Timers[i]);
  }
  Timers = [];
}
var Timers = [];
var Countdowns = [];

function clearCountDownTimer() {
  for (i in Countdowns) {
    clearInterval(Countdowns[i]);
  }
  Countdowns = [];
}

function ElapsedTimer(dt, id) {
  var end = new Date(dt);
  var _second = 1000;
  var _minute = _second * 60;
  var _hour = _minute * 60;
  var _day = _hour * 24;
  var timer;

  function showRemaining() {
    var now = new Date();
    var distance = now - end;
    var days = Math.floor(distance / _day);
    var hours = Math.floor((distance % _day) / _hour);
    var minutes = Math.floor((distance % _hour) / _minute);
    var seconds = Math.floor((distance % _minute) / _second);

    document.getElementById(id).innerHTML = days + 'd ';
    document.getElementById(id).innerHTML += hours + 'h ';
    document.getElementById(id).innerHTML += minutes + 'm ';
    document.getElementById(id).innerHTML += seconds + 's';
  }
  Timers.push(setInterval(showRemaining, 500));
}
var EditItinPK;
var EditItinChatname;
var EditWBFromItin = false;
var EditItinACTION = '';
var EditItinDirty = false;

function exitItinEditPage() {
  const navigateToPreviousPage = () => {
    if (SCH_FOLLOWING_EDIT.isEditing) {
      $.mobile.changePage($('#Schedule_Page'), {
        reverse: true,
        changeHash: false,
      });
      SCH_PopulateSCH_Aircraft();
    } else {
      clickHandler('Following');
    }
  };

  if (EditItinDirty) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Confirm',
      headerClose: false,
      buttonPrompt: iTrans('You have unsaved changes on this itinerary'),
      buttons: {
        [iTrans('Save')]: {
          click: function () {
            SubmitItinEdit();
          },
        },
        [iTrans('Discard Changes')]: {
          click: function () {
            navigateToPreviousPage();
          },
        },
        [iTrans('Cancel')]: {
          click: function () {
            // Do nothing
          },
        },
      },
    });
  } else {
    navigateToPreviousPage();
  }
}

function EditItin(i, itinPK, fromFF) {
  $('#Edit_Itin_Page input, #Edit_Itin_Page textarea, #Edit_Itin_Page text, #Edit_Itin_Page select').off(
    'change',
    markItinPageAsDirty
  );
  $('#Edit_Itin_Page button:not(.sama-header button)').off('click', markItinPageAsDirty);
  EditItinDirty = false;
  //console.log("Edit Primary Key" + this.value);
  if (fromFF) {
    SCH_FOLLOWING_EDIT.isEditing = false;
  }
  EditItinPK = itinPK;

  FOLLOW_INDEX = i;
  var I = Follow_Data[i];
  if (I.Itin.timeback == undefined) I.Itin.timeback = I.Itin.searchtime;
  EditItinChatname = I.Itin.pilot;

  document.getElementById('ITINERARY_Edit_Attachment').value = '';
  if (ONLINE) {
    $('#ITINERARY_Edit_Attachment').prop('disabled', false);
    $('#ITINERARY_Edit_Attachment_Private').prop('disabled', false);
    $('#ITINERARY_Edit_RemoveAttachmentBTN').prop('disabled', false);
    $('#itineraryEditFormPickerHolder').show();
  } else {
    if (I.Itin.status == 'OFFLINE') {
      $('#edit_DateBack').prop('disabled', false);
      $('#edit_TimeBack').prop('disabled', false);
    } else {
      $('#edit_DateBack').prop('disabled', true);
      $('#edit_TimeBack').prop('disabled', true);
    }
    $('#ITINERARY_Edit_Attachment').prop('disabled', true);
    $('#ITINERARY_Edit_Attachment_Private').prop('disabled', true);
    $('#ITINERARY_Edit_RemoveAttachmentBTN').prop('disabled', true);
    $('#itineraryEditFormPickerHolder').hide();
  }
  cert_base64File = null;
  cert_Filename = '';
  //console.log(Follow_Data[i]);
  $.mobile.changePage($('#Edit_Itin_Page'), {
    changeHash: false,
  });

  EditWBFromItin = true;
  var EditOnly = '';
  if (
    USER_RIGHTS.EditItinAccess == 0 &&
    USER_RIGHTS.SuperUser == 0 &&
    I.Itin.pilot != chatname &&
    I.Itin.sic != chatname
  ) {
    $('#UpdateItinDiv').hide();
    $('#UpdateItinDiv2').show();
    $('#editClose').prop('disabled', true);
    $('#EditLegInfoBTN_HLDR').html('');
  } else {
    $('#UpdateItinDiv').show();
    $('#UpdateItinDiv2').hide();
    $('#editClose').prop('disabled', false);
    if (WANDB == 1) {
      $('#EditLegInfoBTN_HLDR').html(
        `<button data-mini='true' id='EditACBTN' name='UPDATE-${i}' value='${I.Itin.ident}' class='greenbg'>
        <i class="fas fa-balance-scale"></i>
        <span>${iTrans('Update W&B and Leg details')}</span>
        </button>`
      );
      $('#EditACBTN').click(WB_fileaclistSelect);
    }
    $('#EditLegInfoBTN_HLDR').enhanceWithin();
  }
  $('#editClose').unbind('click', EditClose);
  $('#editClose').click(EditClose);
  var TimeExpire = '<b>Time Left: </b>';
  if (
    I.Itin.status !== 'OPEN' &&
    I.Itin.status !== 'WARNING' &&
    I.Itin.status !== 'OFFLINE' &&
    I.Itin.status !== 'PENDING'
  ) {
    EditItinACTION = 'Action';
    $('#editClose').html(`<i class="fal fa-bullseye-pointer"></i>${iTrans('Action')}`); //Itinerary must be overdue change close button to action
    $('#editClose').prop('disabled', false);
    TimeExpire =
      '<b>Email ' +
      iTrans('Status') +
      ': </b>' +
      I.Itin.emailStatus +
      '<br />' +
      '<b>SMS ' +
      iTrans('Status') +
      ': </b>' +
      I.Itin.smsStatus +
      '<br />' +
      '<b>PBX ' +
      iTrans('Status') +
      ': </b>' +
      I.Itin.callStatus +
      '<br />' +
      '<b>' +
      iTrans('Expired') +
      ': </b>';
    $('#editsubmitBTN').prop('disabled', true);
  } else {
    EditItinACTION = 'Close';
    $('#editClose').html(`<i class="fal fa-times"></i>${iTrans('Close')}`); //Itinerary must be overdue change close button to action
    $('#editsubmitBTN').prop('disabled', false);
  }
  var detailsHTML =
    '<p><b>' +
    iTrans('Status') +
    ': </b>' +
    I.Itin.status +
    '<br />' +
    '<b>' +
    iTrans('Pilot') +
    ': </b>' +
    I.Itin.pilot +
    '<br />' +
    '<b>' +
    iTrans('Res Person') +
    ': </b>' +
    I.Itin.ResponsiblePerson +
    '<br />' +
    '<b>' +
    iTrans('Passengers') +
    ': </b>' +
    I.Itin.paxnum +
    '<br />' +
    '<b>' +
    iTrans('Filed') +
    ': </b>' +
    I.Itin.filed +
    '<br />' +
    '<b>' +
    iTrans('Due Back') +
    ': </b>' +
    I.Itin.timeback +
    '</p>' +
    TimeExpire +
    '<div id="edit_TimeLeft" style="display:inline;"></div><br /><br />';
  detailsHTML += '<div id="EditItinPaxManifestHLDR"></div>';
  $('#editFlightDetails').val(I.Itin.Details);
  $('#edit_Details').html(detailsHTML);
  if (GROUP_DATA.Pax_Man == 1) {
    console.log(I);
    cirroDB.query('AC', 'ident = ?', I.AC.ident, function (e) {
      var data = e[0];
      AC_data = data.AC_data;
      ITIN_Pax_Manifest = JSON.parse(JSON.stringify(I.Itin.Pax_Manifest));
      ITIN_Pax_Manifest.CurLeg = -1;
      ITIN_Pax_Manifest.Editing = true;
      ITIN_Pax_Manifest.Scheduler = false;
      ITIN_Pax_Manifest.ClientPK = I.ClientPK;
      PAX_Init_ITIN_Manifesting();
      $('#EditItinPaxManifestHLDR button').on('click', markItinPageAsDirty);
    });
  }

  var time = I.Itin.timeback.split(' ');
  var timeback = new Date(time[0] + 'T' + time[1] + 'Z');
  clearCountDownTimer();
  CountDownTimer(timeback, 'edit_TimeLeft');
  var returnHours = timeback.getHours();
  var returnMinutes = timeback.getMinutes();
  if (returnHours < 10) returnHours = '0' + returnHours;
  if (returnMinutes < 10) returnMinutes = '0' + returnMinutes;
  returnHours = returnHours.toString();
  returnMinutes = returnMinutes.toString();
  $('#edit_TimeBack').val(returnHours + returnMinutes);
  editminTime = $('#edit_TimeBack').val();
  if (I.Itin.Attachments == undefined) I.Itin.Attachments = [];
  if (I.Itin.Attachments.length > 0) {
    let attachmentList = '<ul data-role="listview" data-inset="true">';
    for (let A in I.Itin.Attachments) {
      const Att = I.Itin.Attachments[A];
      const cssClass = Att.Private <= 0 ? '' : 'class="adminbg"';
      const iconClass = Att.Private <= 0 ? 'fa-eye' : 'fa-low-vision';
      attachmentList += `<li>
          <a ${cssClass} href="${BASEURL}menu-Following-AJAX.php?action=getItinAtt&PK=${Att.PrimaryKey}" data-ajax="false" target="_blank">
            <i class="fal ${iconClass}"></i>
            <span>${Att.Filename}</span>
          </a>
        </li>`;
    }
    $('#ItinAttachments')
      .html(attachmentList + '</ul>')
      .enhanceWithin();
  } else {
    $('#ItinAttachments').html('<em>Nothing is attached.</em>');
  }
  $('#editItinCfields').html('');
  $('#CompanyCustomFieldsContent').html('');
  $('#ClientCustomFieldsContent').html('');
  $('#ITINERARY_CUSTOM_FILEDS_PR').html('');
  var cfieldhtml = '';
  if (I.Itin.Cfields == null) I.Itin.Cfields = '[]';
  var CF = JSON.parse(I.Itin.Cfields);
  if (CF.length > 0) {
    ITIN_Cfields = CF;
    cfieldhtml = '<h2>' + iTrans('Additional Fields') + '</h2>';
    for (var i in CF) {
      var I2 = CF[i];
      if (I2.PerLeg == 0) {
        cfieldhtml += createCustomField('', I2, I2.Value);
      }
    }
    $('#editItinCfields').html(cfieldhtml);
    $('#editItinCfields').enhanceWithin();
  }

  if (I.Itin.survivalkit == 1) $('#edit_survival').prop('checked', true).checkboxradio('refresh');
  else $('#edit_survival').prop('checked', false).checkboxradio('refresh');
  if (I.Itin.liferaft == 1) $('#edit_liferaft').prop('checked', true).checkboxradio('refresh');
  else $('#edit_liferaft').prop('checked', false).checkboxradio('refresh');
  if (I.Itin.lifejackets == 1) $('#edit_lifejackets').prop('checked', true).checkboxradio('refresh');
  else $('#edit_lifejackets').prop('checked', false).checkboxradio('refresh');
  if (I.Itin.RoutePlan == 0) $('#edit_RoutePlan').html('<h2>Route Plan</h2><em>Not Filed<em>');
  else {
    if (EDITMAP != null) {
      EDITMAP.remove();
      EDITMAP = null;
    }
    $('#edit_RoutePlan').html('<h2>Route Plan</h2><div id="EDITMAP"></div>');
    mapboxgl.accessToken = MAPBOX_TOKEN;
    EDITMAP = new mapboxgl.Map({
      container: 'EDITMAP', // container id
      style: 'mapbox://styles/airsuite/cizg733jt00fi2sqiskxfjukc',
      center: [-89.2461, 48.3822], // starting position
      zoom: 3, // starting zoom
      minzoom: 1,
      pitch: 0,
      bearing: 0,
    });
    EDITMAP.on('load', function () {
      var pathnum = 1;
      var path = [];
      var lines = {
        type: 'FeatureCollection',
        features: [],
      };
      getRoutePlanLines(I.FuelPlan, lines, I.AC.ident);

      if (EDITMAP.getSource('ROUTEPLAN-LINES') != undefined) {
        EDITMAP.getSource('ROUTEPLAN-LINES').setData(lines);
      } else {
        EDITMAP.addSource('ROUTEPLAN-LINES', {
          type: 'geojson',
          data: lines,
        });
        EDITMAP.addLayer({
          id: 'ROUTEPLAN-LINES',
          type: 'line',
          source: 'ROUTEPLAN-LINES',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#ff7800',
            'line-width': 4,
            'line-opacity': 0.7,
          },
        });
      }

      if (EDITMAP.getSource('ROUTEPLAN-ACTIVE') == undefined) {
        EDITMAP.on('click', showFollowingFuelPlan);
        EDITMAP.addSource('ROUTEPLAN-ACTIVE', {
          type: 'geojson',
          data: lines,
        });
        EDITMAP.addLayer({
          id: 'ROUTEPLAN-ACTIVE',
          type: 'circle',
          source: 'ROUTEPLAN-ACTIVE',
          paint: {
            'circle-radius': 10,
            'circle-color': '#ff0000',
            'circle-stroke-color': '#000000',
            'circle-stroke-width': 2,
            'circle-stroke-opacity': 0.8,
            'circle-opacity': 0.7,
          },
        });
        EDITMAP.addLayer({
          id: 'measure-labels',
          type: 'symbol',
          source: 'ROUTEPLAN-ACTIVE',
          layout: {
            'symbol-placement': 'line-center',
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
            'text-field': '{LegNum}-{SegmentNum} {ORIGIN}-->{DESTINATION} {Distance} {Bearing}°T',
            'text-size': 16,
            'text-ignore-placement': true,
            'text-allow-overlap': true,
          },
          paint: {
            'text-color': '#ffffff',
            'text-halo-color': '#000000',
            'text-halo-width': 1.5,
          },
        });
      } else {
        EDITMAP.getSource('ROUTEPLAN-ACTIVE').setData(lines);
      }

      var bbox = turf.bbox(lines);
      EDITMAP.fitBounds(bbox, {
        padding: 50,
      });
    });
  }
  if (I.Itin.WB == 0) $('#edit_WB').html(iTrans('Weight and Balance: Not Filed'));
  else {
    var WBindex = I.WB.length;
  }
  let tdgString = '<h2>TDG</h2>';
  if (I.Itin.TDG == 0 && I.Itin.TDGRevamp == 0)
    $('#edit_TDG').html(`${tdgString}<em>${iTrans('No Dangerous Goods Carried')}</em>`);
  else {
    $('#edit_TDG').html(tdgString + getTDGRevampHTML(I, 'ui-btn ui-shadow ui-corner-all ui-mini', 'Edit_Itin_Page'));
  }

  if (editDatePicker != null) {
    editDatePicker.destroy();
    editDatePicker = null;
  }
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  editDatePicker = new Pikaday({
    field: $('#edit_DateBack')[0],
    firstDay: 1,
    minDate: new Date(),
    maxDate: maxDate,
    yearRange: [2014, 2114],
    defaultDate: timeback,
    setDefaultDate: true,
    onSelect: editdateChanged,
  });
  editdateChanged();

  $('#edit_WB').enhanceWithin();
  //$("#editsubmitBTN").button('refresh');
  if (FORMS > 0) {
    FormsInItineraryEditor.clearInstance();
    FormsInItineraryEditor.instance(
      FormsInItineraryEditor.PAGE,
      '#itineraryEditFormPickerHolder',
      [],
      EditItinPK,
      FormsInItineraryEditor.EL_ADMIN
    );
  }
  if (SCH_FOLLOWING_EDIT.isEditing && SCH_FOLLOWING_EDIT.editLegs) {
    WB_fileaclistSelect({ index: FOLLOW_INDEX });
  }

  if (ITIN_TIMEZONE_UTCmode == 'UTC') {
    $('#returnEditTimeTXT').html(iTrans('ETA Time') + ' - UTC (GMT+00)');
    ITIN_TIMEZONE_UTCmode = 'Local';
    $('#FOLLOWING_UTCmode_Local').prop('checked', false).checkboxradio('refresh');
    $('#FOLLOWING_UTCmode_UTC').prop('checked', true).checkboxradio('refresh');
    toggleEditItineraryTZ({ value: 'UTC' });
  } else {
    var now = new Date();
    ITIN_TIMEZONE = now.getTimezoneOffset();
    $('#returnEditTimeTXT').html(iTrans('ETA Time') + ' - ' + getLocalTZLabel());
    $('#FOLLOWING_UTCmode_Local').prop('checked', true).checkboxradio('refresh');
    $('#FOLLOWING_UTCmode_UTC').prop('checked', false).checkboxradio('refresh');
  }
  $('#FOLLOWING_UTCmode_LocalLBL').html(getLocalTZLabel());

  $('#Edit_Itin_Page input, #Edit_Itin_Page textarea, #Edit_Itin_Page text, #Edit_Itin_Page select').on(
    'change',
    markItinPageAsDirty
  );

  $('#Edit_Itin_Page button:not(.sama-header button)').on('click', markItinPageAsDirty);
}

function markItinPageAsDirty() {
  EditItinDirty = true;
  $('#exitItinEditPage').off('click', hrefHandler);
}

function showFollowingFuelPlan(e) {
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var features = EDITMAP.queryRenderedFeatures(bbox, {
    layers: ['ROUTEPLAN-LINES'],
  });
  if (!features.length) {
    return;
  }
  var f = features[0].properties;
  console.log(f);
  MEASURE.geojson = JSON.parse(f.LegFuelPlan);

  $.mobile.changePage($('#FollowingRoutePlan_Details'), {
    reverse: false,
    changeHash: false,
  });
  populateLegDetails('FollowingFuelPlanDetails');
}

function returnFollowDetails() {
  $.mobile.changePage($('#Edit_Itin_Page'), {
    reverse: true,
    changeHash: false,
  });
}
var EDITMAP = null;
var editDatePicker = null;

function edittimeChanged() {
  //alert("timeChanged");
  var Diff = time_Diff(editminTime, $('#edit_TimeBack').val());
  //console.log("Diff: " + Diff);
  //console.log("Mintime: " + editminTime);
  if (Diff < 0) {
    $('#edit_TimeBack').val(editminTime);
    $('#edit_TimeBack').focus();
    //disable update button
    return;
  }
  //enabled update button
}
var editminTime;

function editdateChanged() {
  var dateObj = new Date();
  var timeHours = dateObj.getHours();
  var timeMinutes = dateObj.getMinutes();
  if (timeHours < 10) timeHours = '0' + timeHours;
  if (timeMinutes < 10) timeMinutes = '0' + timeMinutes;
  timeHours = timeHours.toString();
  timeMinutes = timeMinutes.toString();
  var todaytime = timeHours + timeMinutes;
  //var selectedDate = $('#returnDate').val();
  var selectedDate = editDatePicker.getDate();
  //console.log(selectedDate.getDate());
  //console.log(dateObj.getDate());
  if (
    selectedDate.getMonth() > dateObj.getMonth() ||
    (selectedDate.getMonth() == dateObj.getMonth() && selectedDate.getDate() > dateObj.getDate())
  ) {
    editminTime = '0000';
  } else {
    var returnTime = $('#edit_TimeBack').val();
    if (returnTime !== '') {
      //only change time if returnTime is less than todaytime
      var Diff = time_Diff(todaytime, $('#edit_TimeBack').val());
      if (Diff < 0) {
        $('#edit_TimeBack').val(todaytime);
        $('#edit_TimeBack').focus();
        return;
      }
    }
    editminTime = todaytime;
  }
}

function SubmitItinEdit(closeItinAfterSave = false) {
  var Details = $('#editFlightDetails').val();
  var ReturnDate = editDatePicker.getDate();
  var ReturnTime = $('#edit_TimeBack').val();
  var ReturnMonth = ReturnDate.getMonth() + 1;

  if (ReturnMonth < 10) ReturnMonth = '0' + ReturnMonth;
  var ReturnDay = ReturnDate.getDate();
  if (ReturnDay < 10) ReturnDay = '0' + ReturnDay;
  var ReturnHour = ReturnTime.substr(0, 2);
  var ReturnMin = ReturnTime.substr(2, 2);
  var timeback =
    ReturnDate.getFullYear() + '-' + ReturnMonth + '-' + ReturnDay + ' ' + ReturnHour + ':' + ReturnMin + ':00';
  var survivalkit = 0;
  var timezone = ITIN_TIMEZONE;
  if ($('#edit_survival').is(':checked')) survivalkit = 1;
  var liferaft = 0;
  if ($('#edit_liferaft').is(':checked')) liferaft = 1;
  var lifejackets = 0;
  if ($('#edit_lifejackets').is(':checked')) lifejackets = 1;
  $('#editsubmitBTN').prop('disabled', true);

  //$("#editsubmitBTN").button('refresh');
  var Cfields = [];
  var CF = JSON.parse(JSON.stringify(ITIN_Cfields));
  if (CF.length > 0) {
    for (var i in CF) {
      var I = CF[i];
      if (I.PerLeg == 0) {
        var checked = 0;
        if (I.type == 'checkbox') {
          if ($('#BI_CF_' + I.PrimaryKey).is(':checked')) checked = 1;
          I.Value = checked;
        } else {
          I.Value = $('#BI_CF_' + I.PrimaryKey).val();
        }
      }
      Cfields.push(I);
    }
  }
  var I = Follow_Data[FOLLOW_INDEX];
  if (I.Itin.status == 'OFFLINE') {
    var PK = I.Itin.OfflinePK;

    cirroDB.query('Itinerary', 'OfflinePK = ?', PK, function (e) {
      if (e.length == 1) {
        var newTimeBack = new Date(timeback.replace(' ', 'T') + 'Z');
        if (ITIN_TIMEZONE != 0) {
          newTimeBack.setMinutes(newTimeBack.getMinutes() + newTimeBack.getTimezoneOffset());
        }
        newTimeBack = dateToStringObject(newTimeBack);
        I.Itin.timeback = newTimeBack;
        I.Itin.searchtime = newTimeBack;
        I.Itin.Details = Details;
        I.Itin.survivalkit = survivalkit;
        I.Itin.liferaft = liferaft;
        I.Itin.lifejackets = lifejackets;
        I.Itin.Cfields = JSON.stringify(Cfields);
        I.Itin.Pax_Manifest = JSON.parse(JSON.stringify(ITIN_Pax_Manifest));
        var Itin = JSON.parse(e[0].data);
        Itin.timeback = timeback;
        Itin.searchtime = timeback;
        Itin.Details = Details;
        Itin.survivalkit = survivalkit;
        Itin.liferaft = liferaft;
        Itin.lifejackets = lifejackets;
        Itin.Cfields = JSON.stringify(Cfields);
        Itin.Pax_Manifest = JSON.parse(JSON.stringify(ITIN_Pax_Manifest));
        ItineraryTable.query()
          .raw({
            q: 'UPDATE Itinerary SET searchtime = ?, data = ? WHERE OfflinePK = ?',
            v: [newTimeBack, JSON.stringify(Itin), PK],
          })
          .then((result) => {
            //Updated to close remove from flight following and proceed
            updateFollowData(Follow_Data);
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Success',
              headerClose: false,
              buttonPrompt: iTrans('Offline itinerary has been updated successfully'),
              buttons: {
                OK: {
                  click: function () {
                    if (SCH_FOLLOWING_EDIT.isEditing) {
                      $.mobile.changePage($('#Schedule_Page'), {
                        reverse: true,
                        changeHash: false,
                      });
                      SCH_PopulateSCH_Aircraft();
                    } else {
                      $.mobile.changePage($('#Following_Page'), {
                        reverse: true,
                        changeHash: false,
                      });
                    }
                  },
                },
              },
            });
          })
          .catch((e) => {
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: 'Failed',
              headerClose: false,
              buttonPrompt: iTrans('Error Editing offline itinerary: ') + JSON.stringify(e),
              buttons: {
                OK: {
                  click: function () {
                    //reload user list
                  },
                },
              },
            });
          });
      } else {
        //alert not closed could have synchronized in background already
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Failed',
          headerClose: false,
          buttonPrompt: 'Offline itinerary no longer exists',
          buttons: {
            OK: {
              click: function () {
                //reload user list
              },
            },
          },
        });
      }
    });
  } else {
    if (ONLINE) {
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url:
          BASEURL +
          'menu-Following-AJAX.php?action=editItin&ItinPK=' +
          EditItinPK +
          '&Details=' +
          encodeURIComponent(Details) +
          '&timeback=' +
          encodeURIComponent(timeback) +
          '&timezone=' +
          ITIN_TIMEZONE +
          '&survivalkit=' +
          survivalkit +
          '&liferaft=' +
          liferaft +
          '&lifejackets=' +
          lifejackets,
        timeout: 120000,
        data: {
          File: cert_base64File,
          Filename: cert_Filename,
          Private: $('#ITINERARY_Edit_Attachment_Private').is('checked') ? '1' : '0',
          Cfields: JSON.stringify(Cfields),
          Pax_Manifest: JSON.parse(JSON.stringify(ITIN_Pax_Manifest)),
        },
      })
        .success(function (json_data) {
          if (json_data[0].ServerResponse != undefined) {
            //status could be offline/Not Logged In/Uncaught Exception
            if (json_data[0].ServerResponse == 'Success') {
              //console.log("Itinerary Editied Succesfully");
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: 'Success',
                headerClose: false,
                buttonPrompt: 'Updated Successfully.',
                buttons: {
                  OK: {
                    click: function () {
                      if (closeItinAfterSave) {
                        dialogCloseItin();
                        return;
                      }
                      //reload user list
                      if (SCH_FOLLOWING_EDIT.isEditing) {
                        $.mobile.changePage($('#Schedule_Page'), {
                          reverse: true,
                          changeHash: false,
                        });
                        SCH_PopulateSCH_Aircraft();
                      } else {
                        $.mobile.changePage($('#Following_Page'), {
                          reverse: true,
                          changeHash: false,
                        });
                        getFollowData();
                      }
                    },
                  },
                },
              });
            }
            if (json_data[0].ServerResponse == 'Already Closed') {
              //console.log(json_data[0].Message);
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: 'Failed',
                headerClose: false,
                buttonPrompt: json_data[0].Message,
                buttons: {
                  OK: {
                    click: function () {
                      //reload user list
                      $.mobile.changePage($('#Following_Page'), {
                        reverse: true,
                        changeHash: false,
                      });
                      getFollowData();
                    },
                  },
                },
              });
            }
            if (json_data[0].ServerResponse == 'Offline') {
              //show offling message
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: 'Failed',
                headerClose: false,
                buttonPrompt: iTrans('Offline.  Please wait and try again'),
                buttons: {
                  OK: {
                    click: function () {
                      //reload user list
                      $('#editsubmitBTN').prop('disabled', false);
                      $('#editsubmitBTN').button('refresh');
                    },
                  },
                },
              });
            }
            if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'SubmitItinEdit');
            if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
            if (json_data[0].ServerResponse == 'Error') {
              //Itinerary Primary Key Not found
              $.mobile.changePage($('#Following_Page'), {
                reverse: true,
                changeHash: false,
              }); //should never happen, silently fail and return to previous page
            }
            if (json_data[0].ServerResponse == 'Error2') {
              //pilot chatname not found for itinerary cannot get pilot pk
              $.mobile.changePage($('#Following_Page'), {
                reverse: true,
                changeHash: false,
              }); //should never happen, silently fail and return to previous page
            }
            if (json_data[0].ServerResponse == 'Invalid Timeback') {
              //timeback is before current server time
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: 'Failed',
                headerClose: false,
                buttonPrompt: iTrans('The ETA selected has already elapsed!.  Please select a new time and try again.'),
                buttons: {
                  OK: {
                    click: function () {
                      //reload user list
                      $('#editsubmitBTN').prop('disabled', false);
                      //$("#editsubmitBTN").button('refresh');
                    },
                  },
                },
              });
            }
          } else {
            //console.log("Submit Edit Response Not Recognized!");
            $.mobile.changePage($('#Following_Page'), {
              reverse: true,
              changeHash: false,
            }); //should never happen, silently fail and return to previous page
          }
        })
        .error(function (jqXHR, status, error) {
          $('#editsubmitBTN').prop('disabled', false);
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Connection Error!'),
            headerClose: false,
            buttonPrompt: iTrans('There was a connection problem while updating your itinerary') + '.',
            buttons: {
              [iTrans('Try Again')]: {
                click: function () {
                  SubmitItinEdit();
                },
              },
              [iTrans('Store Offline')]: {
                click: function () {
                  I.Itin.Details = Details;
                  I.Itin.survivalkit = survivalkit;
                  I.Itin.liferaft = liferaft;
                  I.Itin.lifejackets = lifejackets;
                  I.Itin.Cfields = JSON.stringify(Cfields);
                  I.Itin.Pax_Manifest = JSON.parse(JSON.stringify(ITIN_Pax_Manifest));
                  I.Itin.timezone = ITIN_TIMEZONE;
                  I.Itin.offlineEdit = true;
                  storeOfflineItinerary(I);
                  updateFollowData(Follow_Data);
                },
              },
              [iTrans('Discard Changes')]: {
                click: function () {
                  $.mobile.changePage($('#Following_Page'), {
                    reverse: true,
                    changeHash: false,
                  });
                },
              },
            },
          });
        });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('OFFLINE'),
        headerClose: false,
        buttonPrompt:
          iTrans('You are currently OFFLINE. Itinerary Edits will be stored offline and sent later') +
          '. <br /><b>' +
          iTrans('NOTE: Any Updates to ETA Date, ETA Time, File Attachments and Forms will be discarded.') +
          '</b>',
        buttons: {
          [iTrans('OK')]: {
            click: function () {
              I.Itin.Details = Details;
              I.Itin.survivalkit = survivalkit;
              I.Itin.liferaft = liferaft;
              I.Itin.lifejackets = lifejackets;
              I.Itin.Cfields = JSON.stringify(Cfields);
              I.Itin.Pax_Manifest = JSON.parse(JSON.stringify(ITIN_Pax_Manifest));
              I.Itin.timezone = ITIN_TIMEZONE;
              I.Itin.offlineEdit = true;
              storeOfflineItinerary(I);
              updateFollowData(Follow_Data);
            },
          },
        },
      });
    }
  }
}

function storeOfflineItinerary(I) {
  var data = {
    pilot: I.Itin.pilot,
    OfflinePK: I.Itin.PrimaryKey,
    filed: I.Itin.filed,
    ident: I.Itin.ident,
    status: I.Itin.status,
    searchtime: I.Itin.searchtime,
    data: JSON.stringify(I.Itin),
    PrimaryKey: I.Itin.PrimaryKey,
  };
  console.log(data);
  cirroDB.insert('Itinerary', data, null, function (e) {
    //Updated to close remove from flight following and proceed
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Success',
      headerClose: false,
      buttonPrompt: iTrans('Itinerary Edits have been stored offline and will be sent later.') + '. <b>',
      buttons: {
        OK: {
          click: function () {
            if (SCH_FOLLOWING_EDIT.isEditing) {
              $.mobile.changePage($('#Schedule_Page'), {
                reverse: true,
                changeHash: false,
              });
              SCH_PopulateSCH_Aircraft();
            } else {
              $.mobile.changePage($('#Following_Page'), {
                reverse: true,
                changeHash: false,
              });
            }
          },
        },
      },
    });
  });
}

function EditWB() {
  var Opendata = [];
  var Index = this.value;
  for (var i in Follow_Data) {
    var I = Follow_Data[i];
    if (I.Itin != null) {
      var tcnum = 0;
      var WB = null;
      var Fuel = null;
      var WBPK = null;
      if (I.WB != null) {
        for (var b in I.WB) {
          tcnum = I.WB[b].tempconfig;
          WB = I.WB[b].Summary;
          Fuel = parseFloat(I.WB[b].fuelwt);
          WBPK = I.WB[b].WB_PK;
        }
      }
      Opendata.push({
        ident: I.AC.ident,
        status: 'OPEN',
        tcnum: tcnum,
        pilot: I.Itin.pilot,
        Loading: WB,
        Fuel: Fuel,
        WBPK: WBPK,
      });
    }
  }
  WB_OPEN_DATA = Opendata;

  WB_UPDATE = true;
  acIdent = Follow_Data[Index].AC.ident;
  for (x in Opendata) {
    if (acIdent == Opendata[x].ident) {
      WB_LOAD = x;
    }
  }
  //console.log(acIdent);
  AC_data = '';
  TC_data = '';
  WB_data = '';
  PILOT_data = [];
  LOADABLE_data = '';
  FUEL_data = '';
  eacweight = '';
  eLat = '';
  eLong = '';
  ecofgLat = '';
  ecofgLong = '';
  error = '';
  searchtime = '';
  FuelType = 'Standard';
  FuelStandard = 'Standard';
  SeatType = 'Standard';
  SeatStandard = 'Standard';
  GraphName = 'Standard';
  GraphStandard = 'Standard';
  Loading = [];
  optLoad = [];
  tcNum = '';
  tcIndex = '';
  PaXnum = 0;
  SN = '';

  $('#WB_OPT2').html('');
  $('#TCNUM2').html('');
  $('#LongCanvas2').html('');
  $('#LatCanvas2').html('');
  $('#Seating2').html('');
  $('#fuelLoad2').html('');
  $('#bagLoad2').html('');
  $('#optLoad2').html('');
  $('#Pilots2').empty();
  $('#PaX2').html('');

  $.mobile.changePage($('#WB_Page2'), {
    changeHash: false,
  });
  $('#exitWBPage2').click(exitFollowWB);
  $('#WB_Ident2').html(acIdent);

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    ALLAC_data = [];
    var data;
    if (e === false) ALLAC_data = [];
    else {
      for (var i in e) {
        if (e[i].ident == acIdent) data = e[i];
        ALLAC_data.push(e[i].AC_data);
      }
    }

    //console.log(data);
    TC_data = data.TC_data;
    WB_data = data.WB_Data;
    LOADABLE_data = data.Loadable_data;
    AC_data = data.AC_data;
    WB_getActiveTC(acIdent);
  });
}

function exitFollowWB() {
  $('#exitWBPage2').unbind('click', exitFollowWB);
  $.mobile.changePage($('#Edit_Itin_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function dialogCloseItin() {
  const showDialog = (headerText = '', buttonPrompt = '', buttons = {}) => {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: headerText,
      headerClose: false,
      buttonPrompt: buttonPrompt,
      buttons: buttons,
    });
  };
  const navigateToFollowingPage = () => {
    $.mobile.changePage($('#Following_Page'), {
      reverse: true,
      changeHash: false,
    });

    getFollowData(true);
  };
  const navigateToSchedulePage = () => {
    $.mobile.changePage($('#Schedule_Page'), {
      reverse: true,
      changeHash: false,
    });

    SCH_PopulateSCH_Aircraft();
  };
  const isActioning = $('#editClose').html() == 'Action';
  const ajaxAction = isActioning ? 'actionItin' : 'closeItin';

  $.getJSON(`${BASEURL}menu-Following-AJAX.php?action=${ajaxAction}&ItinPK=${EditItinPK}`, function (json_data) {
    const serverResponse = json_data?.[0]?.ServerResponse;

    if (serverResponse == null || serverResponse == 'Error') {
      $.mobile.changePage($('#Following_Page'), {
        reverse: true,
        changeHash: false,
      }); //should never happen, silently fail and return to previous page
      return;
    }

    if (serverResponse == 'Not Logged In') {
      NOTLOGGEDIN();
      return;
    }

    if (serverResponse == 'Uncaught Exception') {
      ServerResponse(json_data[0], 'EditClose');
      return;
    }

    if (serverResponse == 'Offline') {
      showDialog('Failed', iTrans('Offline.  Please wait and try again'), {
        OK: {
          click: function () {},
        },
      });
      return;
    }

    if (serverResponse == 'Already Closed') {
      showDialog('Failed', json_data[0].Message, {
        OK: {
          click: function () {
            navigateToFollowingPage();
          },
        },
      });
      return;
    }

    if (serverResponse != 'Success') {
      // Somehow the server response is not recognized
      showDialog('Failed', iTrans('An error occurred. Please try again.'), {
        OK: {
          click: function () {
            navigateToFollowingPage();
          },
        },
      });
      return;
    }

    if (isActioning) {
      VueBridge.CloseOpenManifest(EditItinPK);
    }

    const headerText = isActioning ? 'ACTIONED!' : 'Success';
    const buttonPrompt = isActioning
      ? iTrans(
          'Itinerary has been successfully actioned. Appropriate staff have been notified.  You are now responsible for this occurrance.'
        )
      : iTrans('Itinerary has been successfully closed.');

    showDialog(headerText, buttonPrompt, {
      OK: {
        click: function () {
          if (SCH_FOLLOWING_EDIT.isEditing) {
            navigateToSchedulePage();
          } else {
            navigateToFollowingPage();
          }
        },
      },
    });
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function EditClose() {
  //console.log("Closing Itinerary from Edit");
  var action = EditItinACTION;
  if (ONLINE) {
    var Text = '';
    const buttons = {};

    if (EditItinDirty) {
      Text += iTrans('You have unsaved changes on this itinerary') + '<br />';
      buttons[iTrans('Save and Close')] = {
        click: function () {
          SubmitItinEdit(true);
        },
      };
    }

    buttons[iTrans('Close')] = {
      click: function () {
        dialogCloseItin();
      },
    };
    buttons[iTrans('Cancel')] = {
      click: function () {
        //File bug report
      },
    };

    if (chatname != EditItinChatname) {
      Text +=
        '<h3>' +
        iTrans('This is not your Itinerary') +
        '!</h3>' +
        iTrans('Are you sure you want to') +
        ' [' +
        iTrans(action) +
        '] ' +
        iTrans('the itinerary for') +
        ' <b>' +
        EditItinChatname +
        '</b>?';
    } else {
      Text +=
        iTrans('Are you sure you want to') +
        ' [' +
        iTrans(action) +
        '] your itinerary <b>' +
        EditItinChatname +
        '</b>?';
    }

    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Confirm',
      headerClose: false,
      buttonPrompt: Text,
      buttons: buttons,
      callbackOpen: function () {
        setTimeout(function () {
          $('a.ui-btn:contains("Close")').attr('data-testid', 'following-close-confirmation-dialog-close-button');
          $('a.ui-btn:contains("Cancel")').attr('data-testid', 'following-close-confirmation-dialog-cancel-button');
          $('a.ui-btn:contains("OK")').attr('data-testid', 'following-close-confirmation-dialog-ok-button');
        }, 100);
      },
    });
  } else {
    //check if offline itinerary and close if it is.  otherwise warn user they are offline and can't do it
    var I = Follow_Data[FOLLOW_INDEX];
    if (I.Itin.status == 'OFFLINE') {
      var PK = I.Itin.OfflinePK;
      cirroDB.query('Itinerary', 'OfflinePK = ?', PK, function (e) {
        if (e.length == 1) {
          var Itin = e[0];
          ItineraryTable.query()
            .raw({
              q: 'UPDATE Itinerary SET status = ? WHERE OfflinePK = ?',
              v: ['CLOSED', PK],
            })
            .then((result) => {
              I.Itin = null;
              updateFollowData(Follow_Data);
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: 'Success',
                headerClose: false,
                buttonPrompt: iTrans('Offline itinerary has been closed successfully.'),
                buttons: {
                  OK: {
                    click: function () {
                      if (SCH_FOLLOWING_EDIT.isEditing) {
                        $.mobile.changePage($('#Schedule_Page'), {
                          reverse: true,
                          changeHash: false,
                        });
                        SCH_PopulateSCH_Aircraft();
                      } else {
                        $.mobile.changePage($('#Following_Page'), {
                          reverse: true,
                          changeHash: false,
                        });
                      }
                    },
                  },
                },
              });
            })
            .catch((e) => {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: 'Failed',
                headerClose: false,
                buttonPrompt: iTrans('Offline itinerary no longer exists.'),
                buttons: {
                  OK: {
                    click: function () {
                      //reload user list
                    },
                  },
                },
              });
            });
        } else {
          //alert not closed could have synchronized in background already
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: 'Failed',
            headerClose: false,
            buttonPrompt: iTrans('Offline itinerary no longer exists.'),
            buttons: {
              OK: {
                click: function () {
                  //reload user list
                },
              },
            },
          });
        }
      });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Failed',
        headerClose: false,
        buttonPrompt: iTrans('Offline.  Please wait and try again'),
        buttons: {
          OK: {
            click: function () {
              //reload user list
            },
          },
        },
        callbackOpen: function () {
          setTimeout(function () {
            $('a.ui-btn:contains("Close")').attr('data-testid', 'following-close-confirmation-dialog-close-button');
            $('a.ui-btn:contains("Cancel")').attr('data-testid', 'following-close-confirmation-dialog-cancel-button');
          }, 100);
        },
      });
    }
  }
}
var gotoAC = null;
var RETURN_FOLLOWING = false;
function showACMAP(index) {
  //console.log(Follow_Data[index].AC.ident);
  RETURN_FOLLOWING = true;
  if (mapb != null) {
    $.mobile.changePage($('#Map_Page'), {
      reverse: false,
      changeHash: false,
    });
    INIT_MAPB();
    for (var i in mapData.features) {
      if (Follow_Data[index].AC.ident == mapData.features[i].properties.Ident) {
        //$("#Aircraft option:contains(" + Follow_Data[index].AC.ident + ")").attr('selected', 'selected');
        $('#Aircraft').val(i);
        $('#Aircraft').selectmenu('refresh', true);
        curAC = i;
        filterAircraft(i, true);
      }
    }
  } else {
    $.mobile.changePage($('#Map_Page'), {
      reverse: false,
      changeHash: false,
    });
    gotoAC = Follow_Data[index].AC.ident;
    INIT_MAPB();
  }
}

function followingBack() {
  RETURN_FOLLOWING = false;
  $.mobile.changePage($('#Following_Page'), {
    reverse: true,
    changeHash: false,
  });
  $('#NWInfo').hide();
  $('#SWInfo').hide();
  $('#NEInfo').hide();
  $('#SEInfo').hide();
  $('#NNInfo').hide();
  $('#Compass').hide();
  $('#coordinates').hide();
  $('#crosshair').hide();
  if (updateTimeout != null) {
    clearTimeout(updateTimeout);
    updateTimeout = null;
  }
  FuelPlans = [];
  RoutePlans = [];
  PostRoute = null;
}

function getRoutePlanDest(Leg, CurPos, CurSpeed) {
  var coords = Leg.features[Leg.features.length - 1].geometry.coordinates;
  var pcoords = Leg.features[0].geometry.coordinates;
  var LineProperties = Leg.features[Leg.features.length - 1].properties;
  if (Leg.features[Leg.features.length - 1].properties.isAlternate == true) {
    coords = Leg.features[Leg.features.length - 2].geometry.coordinates;
    LineProperties = Leg.features[Leg.features.length - 2].properties;
  }

  var CurPos = turf.point(CurPos);
  var point1 = turf.point(pcoords);
  var point2 = turf.point(coords);
  var distanceFrom = turf.distance(CurPos, point1, { units: 'meters' });
  var distanceTo = turf.distance(CurPos, point2, { units: 'meters' });

  var bearing = turf.bearing(point1, point2).toFixed(0);
  if (bearing < 0) bearing = 360 + parseInt(bearing);

  var Props = {
    Origin: getDestSummaryTXT(Leg.features[0].properties),
    OriginDist: getDisplayDistance(distanceFrom),
    OriginETE: secondsToHms(distanceFrom / (CurSpeed * 0.514444)),
    Dest: getDestSummaryTXT(LineProperties),
    DestDist: getDisplayDistance(distanceTo),
    DestETE: secondsToHms(distanceTo / (CurSpeed * 0.514444)),
  };
  return Props;
}

function ShowLegDetails(dir, ac, index) {
  var FD = Follow_Data[ac];
  var NumLegs = FD.WB.length;
  if (dir == 'prev') {
    if (index > 0) index--;
  }
  if (dir == 'next') {
    if (index + 1 < NumLegs) index++;
  }
  var curLeg = parseInt(index) + 1;

  var L = FD.WB[index]; //display most recent active leg in list or use Last leg by default;
  var Origin = '';
  var Dest = '';
  if (FD.FuelPlan != 'None' && FD.FuelPlan != null && FD.GPS != null) {
    if (FD.FuelPlan[index] != undefined) {
      FP = getRoutePlanDest(FD.FuelPlan[index], [FD.GPS.Long, FD.GPS.Lat], FD.GPS.speed);
      Origin = '<br />' + FP.OriginDist + ' ~ ' + FP.OriginETE + ' (' + iTrans('From Cur Pos') + ')';
      Dest = '<br />' + FP.DestDist + ' ~ ' + FP.DestETE + ' (' + iTrans('From Cur Pos') + ')';
    }
  }
  var legHTML = '<h4>' + iTrans('Leg') + ' ' + curLeg + '/' + NumLegs + ' ' + iTrans('Details') + '</h4>';
  legHTML += "<table style='min-width:150px !important;' class='wrap'>";
  legHTML +=
    "<tr><td><button class='cirro-btn' onClick='ShowLegDetails(\"prev\"," +
    ac +
    ' , ' +
    index +
    ")'><div class='vertical-center-container'><i class='icon-margins fal fa-chevron-left'></i>" +
    iTrans('Prev Leg') +
    "</div></button></td><td>   </td><td><button class='cirro-btn' onClick='ShowLegDetails(\"next\"," +
    ac +
    ' , ' +
    index +
    ")'><div class='vertical-center-container'>" +
    iTrans('Next Leg') +
    "<i class='icon-margins fal fa-chevron-right'></i></div></button></td></tr>";
  legHTML += '</table>';
  legHTML += "<table style='min-width:250px !important;' class='wrap'>";
  legHTML += '<tr><td>' + iTrans('Pilot') + '</td><td>' + L.Pilot + '</td></tr>';
  legHTML += '<tr><td>' + iTrans('SIC') + '</td><td>' + L.sic + '</td></tr>';
  legHTML += '<tr><td>' + iTrans('Origin') + '</td><td>' + L.LegOrigin + Origin + '</td></tr>';
  legHTML += '<tr><td>' + iTrans('Up Time') + '</td><td>' + timeToTimeString(L.LegStart) + ' L</td></tr>';
  legHTML += '<tr><td>' + iTrans('Dest') + '</td><td>' + L.LegDest + Dest + '</td></tr>';
  legHTML += '<tr><td>' + iTrans('Down Time') + '</td><td>' + timeToTimeString(L.LegEnd) + ' L</td></tr>';
  legHTML += '<tr><td>' + iTrans('Pax') + ' #</td><td>' + L.PaxNum + '</td></tr>';
  legHTML += '<tr><td>' + iTrans('Planned ETA') + '</td><td>' + timeToTimeString(L.LegETA) + ' Z</td></tr>';
  legHTML += '<tr><td>' + iTrans('Planned ETE') + '</td><td><div id="eteTimerFF' + FD.AC.ident + '"></div></td></tr>';
  legHTML += '<tr><td>' + iTrans('Updated') + '</td><td>' + L.Changed + 'Z</td></tr>';

  legHTML += '</table>';
  legHTML += '<b>' + iTrans('Details') + ': </b>' + L.Description + '<br />';
  legHTML += '</div>';
  $('#Following_Leg_Details').html(legHTML);
  $('#Following_Leg_Details').enhanceWithin();
  if (L.LegETA == 0) {
    $('#eteTimerFF' + FD.AC.ident).html(iTrans('Unknown'));
  } else {
    var changed = L.Changed.split(' ');
    var now = new Date();
    var nowHours = now.getUTCHours();
    var nowMin = now.getUTCMinutes();
    if (nowMin < 10) nowMin = '0' + nowMin;
    var NowETA = nowHours + '' + nowMin;

    var then = new Date(changed[0] + 'T' + changed[1] + 'Z');
    var thenHours = then.getUTCHours();
    var thenMin = then.getUTCMinutes();
    if (thenMin < 10) thenMin = '0' + thenMin;
    var thenETA = thenHours + '' + thenMin;

    var ChangedDate = changed[0];

    if (parseInt(L.LegETA) < parseInt(thenETA)) {
      //Changed Date needs to be incremented by 1 day
      ChangedDate = new Date(changed[0] + 'T00:00:00Z');
      ChangedDate.setUTCDate(ChangedDate.getUTCDate() + 1);
      ChangedDate = ChangedDate.toISOString().substr(0, 10);
    }

    var ETA = timeToTimeString(L.LegETA);
    //console.log(ChangedDate);
    var ETAtime = new Date(ChangedDate + 'T' + ETA + 'Z');
    CountDownTimer(ETAtime, 'eteTimerFF' + FD.AC.ident); //method in menu-following.js
  }
}
function FFmapInfo(ac, div) {
  var FD = Follow_Data[ac];
  //clearMapElapsedTimer();
  $('#' + div).html('');
  if (FD.GPS != null) {
    var gpsTime = FD.GPS.dateTime.split(' ');
    gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
    var now = new Date();
    var gpsElapsed = Math.round((now - gpsTime) / 1000);

    $('#' + div).append("GPS: <div id='TimerMap" + FD.AC.ident + "' class='divoneline'></div><br />");
    var gpsTime = FD.GPS.dateTime.split(' ');
    gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
    MapElapsedTimer(gpsTime, 'TimerMap' + FD.AC.ident);
  }
  var itinStatus = 'CLOSED';
  if (FD.Itin != null) {
    itinStatus = FD.Itin.status;
  }
  //clearCountDownTimer();
  if (FD.Itin !== null) {
    var Itinerary = FD.Itin;
    $('#' + div).append("Expires: <div id='itinTimerMap" + FD.AC.ident + "' class='divoneline'></div><br />");
    var itinTime = Itinerary.timeback.split(' ');
    itinTime = new Date(itinTime[0] + 'T' + itinTime[1] + 'Z');
    CountDownTimer(itinTime, 'itinTimerMap' + FD.AC.ident); //method in menu-following.js
  }

  if (WANDB == 1 && GROUP_DATA.FlightReports == 1 && FD.WB != null) {
    var L = FD.WB[0]; //get last leg from wb data
    var NumLegs = FD.WB.length;
    var curLeg = 1;
    var index = 0;
    for (var l in FD.WB) {
      if (FD.WB[l].Active == 1) {
        L = FD.WB[l]; //display most recent active leg in list or use Last leg by default;
        curLeg = l + 1;
        index = l;
      }
    }

    var curLeg = parseInt(index) + 1;

    var L = FD.WB[index]; //display most recent active leg in list or use Last leg by default;

    var legHTML = iTrans('Leg') + ' ' + curLeg + '/' + NumLegs + '<br />';

    legHTML += L.LegOrigin + ' - ' + L.LegDest + '<br />';

    legHTML += 'Pax # ' + L.PaxNum + '<br />';
    legHTML += 'ETA: ' + timeToTimeString(L.LegETA) + ' Z<br />';
    legHTML += 'ETE: <div id="eteTimerFFMap' + FD.AC.ident + '" class="divoneline"></div><br />';

    $('#' + div).append(legHTML);
    $('#' + div).enhanceWithin();

    //if (L.LegStart == 0) $('#eteTimerFFMap' + FD.AC.ident).html('N/A');
    if (L.LegETA == 0) {
      $('#eteTimerFFMap' + FD.AC.ident).html(iTrans('Unknown'));
    } else {
      var changed = L.Changed.split(' ');
      var now = new Date();
      var nowHours = now.getUTCHours();
      var nowMin = now.getUTCMinutes();
      if (nowMin < 10) nowMin = '0' + nowMin;
      var NowETA = nowHours + '' + nowMin;

      var then = new Date(changed[0] + 'T' + changed[1] + 'Z');
      var thenHours = then.getUTCHours();
      var thenMin = then.getUTCMinutes();
      if (thenMin < 10) thenMin = '0' + thenMin;
      var thenETA = thenHours + '' + thenMin;

      var ChangedDate = changed[0];

      if (parseInt(L.LegETA) < parseInt(thenETA)) {
        //Changed Date needs to be incremented by 1 day
        ChangedDate = new Date(changed[0] + 'T00:00:00Z');
        ChangedDate.setUTCDate(ChangedDate.getUTCDate() + 1);
        ChangedDate = ChangedDate.toISOString().substr(0, 10);
      }

      var ETA = timeToTimeString(L.LegETA);
      //console.log(ChangedDate);
      var ETAtime = new Date(ChangedDate + 'T' + ETA + 'Z');
      CountDownTimer(ETAtime, 'eteTimerFFMap' + FD.AC.ident); //method in menu-following.js
    }
  }
}
function FFpopup(ac) {
  var FD = Follow_Data[ac];
  clearMapElapsedTimer();
  if (FD.GPS != null) {
    var gpsTime = FD.GPS.dateTime.split(' ');
    gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
    var now = new Date();
    var gpsElapsed = Math.round((now - gpsTime) / 1000);
    var acStatus = 'Landed';
    if (gpsElapsed < 900) acStatus = iTrans('Airborne');
    var AGL = parseInt(FD.GPS.altitude) - parseInt(FD.GPS.elevation);
    AGL = Math.round(AGL * 3.28084);
    $('#FFitinDetails').html('');
    $('#FFitinDetails').append(
      '<h4>' + FD.AC.ident + ' - ' + acStatus + "<div id='Timer" + FD.AC.ident + "'></div></h4>"
    );
    var gpsTime = FD.GPS.dateTime.split(' ');
    gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
    MapElapsedTimer(gpsTime, 'Timer' + FD.AC.ident);
    var Sun = calculateSunriseSunset(FD.GPS.Lat, FD.GPS.Long);
    $('#FFitinDetails').append(
      "<table style='min-width:250px !important;'><tr><td>" +
        iTrans('Type') +
        ':</td><td>' +
        FD.AC.AcType +
        '</td></tr>'
    );
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Phone') + '#</td><td>' + FD.AC.satphone + '</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('UTC Time') + ':</td><td>' + FD.GPS.dateTime + '</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Speed') + ':</td><td>' + FD.GPS.speed + ' Knt</td></tr>');
    $('#FFitinDetails tr:last').after(
      '<tr><td>' + iTrans('Heading') + ':</td><td>' + FD.GPS.heading + ' deg true</td></tr>'
    );
    $('#FFitinDetails tr:last').after(
      '<tr><td>' + iTrans('Altitude') + ':</td><td>' + Math.round(FD.GPS.altitude * 3.28084) + ' ft ASL</td></tr>'
    );
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('AGL') + ':</td><td>' + AGL + ' ft</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Lat') + ':</td><td>' + FD.GPS.Lat + '</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Long') + ':</td><td>' + FD.GPS.Long + '</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Sunrise') + '</td><td>' + Sun.Sunrise + '</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Sunset') + '</td><td>' + Sun.Sunset + '</td></tr>');
    $('#FFitinDetails tr:last').after('</table>');
  } else {
    $('#FFitinDetails').html('<h4>' + iTrans('No GPS Tracker') + '</h4>');
  }
  var itinStatus = 'CLOSED';
  if (FD.Itin != null) {
    itinStatus = FD.Itin.status;
  }
  $('#FFitinDetails').append('<h4>' + iTrans('Itinerary') + ': ' + itinStatus + '</h4>');
  clearCountDownTimer();
  if (FD.Itin !== null) {
    var Itinerary = FD.Itin;
    $('#FFitinDetails').append(
      "<table style='min-width:250px !important;' class='wrap'><tr><td>" +
        iTrans('Time Left') +
        "</td><td><div id='itinTimer" +
        FD.AC.ident +
        "'></div></td></tr>"
    );
    var itinTime = Itinerary.timeback.split(' ');
    itinTime = new Date(itinTime[0] + 'T' + itinTime[1] + 'Z');
    CountDownTimer(itinTime, 'itinTimer' + FD.AC.ident); //method in menu-following.js
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Pilot') + ':</td><td>' + Itinerary.pilot + '</td></tr>');
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('SIC') + ':</td><td>' + Itinerary.sic + '</td></tr>');
    $('#FFitinDetails tr:last').after(
      '<tr><td>' + iTrans('Res Person') + ':</td><td>' + Itinerary.ResponsiblePerson + '</td></tr>'
    );
    $('#FFitinDetails tr:last').after(
      '<tr><td>' + iTrans('Passengers') + ':</td><td>' + Itinerary.paxnum + '</td></tr>'
    );
    $('#FFitinDetails tr:last').after('<tr><td>UTC ' + iTrans('Filed') + ':</td><td>' + Itinerary.filed + '</td></tr>');
    $('#FFitinDetails tr:last').after(
      '<tr><td>UTC ' + iTrans('Due Back') + ':</td><td>' + Itinerary.timeback + '</td></tr>'
    );
    $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Details') + ':</td><td>' + Itinerary.Details + '</td></tr>');
    if (Itinerary.TDG == 1)
      $('#FFitinDetails tr:last').after('<tr><td>TDG:</td><td>' + iTrans('Carried') + '</td></tr>');
    else $('#FFitinDetails tr:last').after('<tr><td>TDG:</td><td>' + iTrans('None') + '</td></tr>');
    if (Itinerary.RoutePlan == 1)
      $('#FFitinDetails tr:last').after(
        '<tr><td>' + iTrans('Route Plan') + '</td><td>' + iTrans('Available') + '</td></tr>'
      );
    else
      $('#FFitinDetails tr:last').after(
        '<tr><td>' + iTrans('Route Plan') + '</td><td>' + iTrans('') + 'None</td></tr>'
      );

    if (GROUP_DATA.FlightReports == 1) {
      $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Client') + ': </td><td>' + FD.ClientName + '</td></tr>');
      $('#FFitinDetails tr:last').after('<tr><td>' + iTrans('Job') + ': </td><td>' + FD.JobName + '</td></tr>');
    }
    $('#FFitinDetails tr:last').after('</table>');
    if (Itinerary.Attachments.length > 0) {
      var Ahtml = "<h4>Attachments</h4><table style='min-width:250px !important;' class='wrap'><tbody>";
      for (var A in Itinerary.Attachments) {
        var Att = Itinerary.Attachments[A];
        Ahtml +=
          '<tr><td><a href="' +
          BASEURL +
          'menu-Following-AJAX.php?action=getItinAtt&PK=' +
          Att.PrimaryKey +
          '" data-ajax="false" target="_blank" class="ui-link">' +
          Att.Filename +
          '</a></td></tr>';
      }
      Ahtml += '</tbody></table>';
      $('#FFitinDetails').append(Ahtml);
    }

    if (GROUP_DATA.Pax_Man == 1) {
      cirroDB.query('AC', 'ident = ?', FD.AC.ident, function (e) {
        var data = e[0];
        AC_data = data.AC_data;
        ITIN_Pax_Manifest = JSON.parse(JSON.stringify(FD.Itin.Pax_Manifest));
        ITIN_Pax_Manifest.CurLeg = -1;
        ITIN_Pax_Manifest.Editing = true;
        ITIN_Pax_Manifest.Popup = 1;
        $('#FFitinDetails').append(PAX_GetPaxListHTML(ITIN_Pax_Manifest, 'Following_Page'));
        $('#FFitinDetails').enhanceWithin();
      });
    }
    if (Itinerary.OFP != 0) {
      $('#FFitinDetails').append(GetOFP_Html(Itinerary.OFP));
    }
  }
  if (WANDB == 1 && GROUP_DATA.FlightReports == 1 && FD.WB != null) {
    var L = FD.WB[0]; //get last leg from wb data
    var NumLegs = FD.WB.length;
    var curLeg = 1;
    var index = 0;
    for (var l in FD.WB) {
      if (FD.WB[l].Active == 1) {
        L = FD.WB[l]; //display most recent active leg in list or use Last leg by default;
        curLeg = l + 1;
        index = l;
      }
    }

    var legHTML = "<div id='Following_Leg_Details'></div>";
    $('#FFitinDetails').append(legHTML);
    ShowLegDetails('load', ac, index);
  }

  if (FD.TDG != null) {
    var ManifestHTML = '';
    for (T in FD.TDG) {
      TDG_Manifest = FD.TDG[T].Data;
      ManifestHTML =
        '<div style="width: 100%; overflow: auto"><table data-filter="true" class="limit-table">' +
        '<thead>' +
        '<tr>' +
        '<th data-priority="1">' +
        iTrans('Consignor') +
        '</th>' +
        '<th data-priority="2">' +
        iTrans('Shipping Name') +
        '</th>' +
        '<th data-priority="3">UN</th>' +
        '<th data-priority="2">' +
        iTrans('Class') +
        '</th>' +
        '<th data-priority="2">' +
        iTrans('Quantity') +
        '</th>' +
        '<th data-priority="2">' +
        iTrans('Location') +
        '</th>' +
        '<th data-priority="2">CAO</th>' +
        '<th data-priority="2">' +
        iTrans('Exemption') +
        '</th>' +
        '<th data-priority="2">ERG Code</th>' +
        '</tr>' +
        '</thead>' +
        '<tbody>';
      for (i in TDG_Manifest) {
        var lineClass = '';
        //check for segregation between packages
        for (s in TDG_Manifest) {
          if (s !== i) {
            //don't check against self
            var mClass = TDG_Manifest[i].Class_Div;
            mClass = mClass.split(' ');
            mClass = mClass[0];
            var cClass = TDG_Manifest[s].Class_Div;
            cClass = cClass.split(' ');
            cClass = cClass[0];
            if ((mClass == '3' && cClass == '5.1') || (mClass == '5.1' && cClass == '3')) lineClass = 'yellowRecord';
            if ((mClass == '4.2' && cClass == '5.1') || (mClass == '5.1' && cClass == '4.2'))
              lineClass = 'yellowRecord';
            if ((mClass == '4.3' && cClass == '8') || (mClass == '8' && cClass == '4.3')) lineClass = 'yellowRecord';
            var emClass = mClass.split('.');
            var ecClass = cClass.split('.');

            if (emClass[0] == '1' && ecClass[0] == '1') {
              //comparing two explosive class substances
              if (
                (emClass[1] == '4B' && ecClass[1] !== '4B' && ecClass[1] !== '4S') ||
                (emClass[1] !== '4S' && emClass[1] !== '4B' && ecClass[1] == '4B')
              )
                lineClass = 'yellowRecord';
            }
            //All explosives not 1.4S are not compatable with any other class
            if (emClass[0] == '1' && ecClass[0] !== '1') {
              if (emClass[1] !== '4S') lineClass = 'yellowRecord';
            }
            if (ecClass[0] == '1' && emClass[0] !== '1') {
              if (ecClass[1] !== '4S') lineClass = 'yellowRecord';
            }
          }
        }

        var Item = TDG_Manifest[i];
        ManifestHTML +=
          "<tr class='" +
          lineClass +
          "'><td>" +
          Item.Consignor +
          '</td><td>' +
          Item.Description +
          '</td><td>' +
          Item.UN +
          '</td><td>' +
          Item.Class_Div +
          '</td><td>' +
          Item.pkgQTY +
          'x' +
          Item.QTY +
          '</td><td>' +
          Item.Location +
          '</td><td>' +
          Item.CAO +
          '</td><td>' +
          Item.Exemption +
          '</td><td>' +
          Item.ERG_Num +
          '</td></tr>';
      }
      ManifestHTML += '</tbody></table></div>';
    }
    $('#FFitinDetails').append('<h4>TDG ' + iTrans('Manifest' + '</h4>'));
    $('#FFitinDetails').append(ManifestHTML);
  } else if (FD.Itin.TDGRevamp == 1) {
    $('#FFitinDetails').append(getTDGRevampHTML(FD, 'cirro-btn', 'Following_Page'));
  } else {
    if (itinStatus != 'CLOSED') $('#FFitinDetails').append('<h4>' + iTrans('No TDG Carried') + '</h4>');
  }
  if (FD.Schedule != null) {
    $('#FFitinDetails').append('<h4>' + iTrans('Scheduled Items') + '</h4>');
    var schedHTML = '';
    for (var S in FD.Schedule) {
      schedHTML +=
        "<table class='limit-table wrap second-td-fill'><thead><tr><th>" +
        iTrans('Item') +
        '</th><th>' +
        iTrans('Schedule Details') +
        '</th></thead>';
      var si = FD.Schedule[S];
      var StatusClass = 'greenbg';
      if (si.Status == 'Tentative') StatusClass = 'yellowbg';
      if (si.Status == 'Cancelled') StatusClass = 'redbg';
      schedHTML +=
        "<tr class='" +
        StatusClass +
        "'><td><b>" +
        iTrans('Status') +
        '</b></td><td>' +
        iTrans(si.Status) +
        '</td></tr>';
      schedHTML += '<tr><td><b>' + iTrans('Client') + '</b></td><td>' + si.Client_Text + '</td></tr>';
      schedHTML += '<tr><td><b>' + iTrans('Job') + '</b></td><td>' + si.Job_Text + '</td></tr>';
      var BookedStaff = '';
      for (var s in si.Associated.BookedStaff) {
        BookedStaff += si.Associated.BookedStaff[s].chatname + '<br />';
      }
      schedHTML += '<tr><td><b>' + iTrans('Staff') + '</b></td><td>' + BookedStaff + '</td></tr>';
      schedHTML += '<tr><td><b>' + iTrans('Details') + '</b></td><td>' + si.description + '</td></tr>';
      schedHTML += '</table><br />';
    }
    $('#FFitinDetails').append(schedHTML);
  } else {
    $('#FFitinDetails').append('<h4>Nothing Scheduled</h4>');
  }
  if (itinStatus !== 'CLOSED' && itinStatus !== 'OPEN' && itinStatus !== 'PENDING') {
    var Logs = FD.Logs;
    //console.log(Logs);
    var LogTable = "<table style='min-width:250px !important;'>";
    for (var i in Logs) {
      var Log = 'Nothing';
      switch (i) {
        case '0':
          Log = 'Email';
          break;
        case '1':
          Log = 'PBX';
          break;
        case '2':
          Log = 'SMS';
          break;
        case '3':
          Log = 'SMS Inbound';
          break;
      }
      //console.log(Log);
      for (var b in Logs[i]) {
        var Item = Logs[i][b];
        if (Item.Type == 'WARNING') {
          var Status;
          var logInfo = '';
          switch (Log) {
            case 'Email':
              if (Item.Success == 1) Status = iTrans('Sent');
              else Status = iTrans('Failed') + ': ' + Item.Reason;
              logInfo = Item.Recipient;
              break;
            case 'PBX':
              if (Item.Success == 1) Status = iTrans('Success');
              if (Item.Dialing == 1) Status = iTrans('Dialing');
              if (Item.Hangup == 1) Status = iTrans('Failed') + ': ' + Item.Reason;
              logInfo = Item.Recipient;
              break;
            case 'SMS':
              if (Item.Sent == 1) Status = iTrans('Sent');
              if (Item.Delivered == 1) Status = iTrans('Delivered');
              if (Item.Failed == 1) Status = iTrans('Failed') + ': ' + Item.Reason;
              logInfo = Item.Recipient;
              break;
            case 'SMS Inbound':
              Status = Item.Response;
              logInfo = 'SMS Response';
              break;
          }
          LogTable += '<tr><td>' + Log + '</td><td>' + logInfo + '</td><td>' + Status + '</td></tr>';
        }
      }
    }
    LogTable += '</table>';
    $('#FFitinDetails').append('<h4>' + iTrans('Alert Logs') + '</h4>' + LogTable);
  }
  //TODO Add Itinerary Information when filed
  //TODO Add TDG Information when filed
  $('#FFitinInfo').popup('open');
  $('#FFitinInfo').enhanceWithin();
}
function SMS_Messenger(number) {
  var html =
    '<h4>Number: ' +
    number +
    '</h4><label for="SMS_MESSENGER_TEXT">' +
    iTrans('Message Text') +
    '</label><textarea id="SMS_MESSENGER_TEXT" onKeyup="SMS_CheckLength(this)" ></textarea><div id="SMS_MESSENGER_COUNT">0/160</div>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Send SMS',
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      'Send Now': {
        click: function () {
          //todo save waypoint to database
          //console.log("Save Marker");
          SMS_SendMessage(number, $('#SMS_MESSENGER_TEXT').val());
        },
      },

      Cancel: {
        click: function () {
          //Cancel edit
        },
      },
    },
  });
  $('.ui-popup').popup('close');
}
function SMS_CheckLength(e) {
  var text = $('#' + e.id).val();
  var length = text.length;
  $('#SMS_MESSENGER_COUNT').html(length + '/160');
}
function SMS_SendMessage(number, text) {
  $.getJSON(BASEURL + 'SMS_Messenger.php?Number=' + number + '&Text=' + encodeURIComponent(text), function (json_data) {
    if (json_data.ServerResponse == 'Success') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Success',
        headerClose: false,
        buttonPrompt: '<center><h4>' + iTrans('Message successfully sent') + '</h4></center>',
        buttons: {
          Ok: {
            click: function () {
              //Cancel edit
            },
          },
        },
      });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Failed',
        headerClose: false,
        buttonPrompt:
          '<center><h4>' +
          iTrans('There was an error sending your SMS message') +
          ': ' +
          json_data.Reason +
          '</h4></center>',
        buttons: {
          Ok: {
            click: function () {
              //Cancel edit
            },
          },
        },
      });
    }
  }).fail(function (jqXHR, status, error) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Failed',
      headerClose: false,
      buttonPrompt:
        '<center><h4>' +
        iTrans('There was an problem contacting the SMS server please try again later') +
        '</h4></center>',
      buttons: {
        Ok: {
          click: function () {
            //Cancel edit
          },
        },
      },
    });
  });
}

function getTDGRevampHTML(follow_data, buttonClasses, legacyPageId) {
  let html = `<h4>${i18n.t('menu_Billing_LANG.BILLING_FPTDGTXT')}</h4>`;

  // if a manifest does't exist but we are making goods as transported
  if (follow_data.TDGRevampManifestId == null) {
    return html;
  }

  return (html += `<button class="${buttonClasses}" style="width: auto;"
        onclick="VueBridge.enterVue(
          'groups/${GROUP_DATA.group}/reports/dangerous-goods/manifest/${
    follow_data.TDGRevampManifestId
  }?from-legacy=${legacyPageId}')">${i18n.t('View Manifest')}</button>`);
}

function getFF_AC_Settings(index) {
  FF_Mask_ReturnPage = '#Following_Page';
  getFF_MaskSettings(Follow_Data[index].AC.PrimaryKey, Follow_Data[index].AC.ident);
}

function getFF_MaskSettings(AC_PK, ident) {
  $.mobile.loading('show', { theme: 'a' });
  $.getJSON(
    BASEURL + 'menu-Maintenance-AJAX.php?action=getAC_Masking&AC_PK=' + AC_PK + '&futureOnly=1',
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data[0].ServerResponse == 'Success') {
        showFF_MaskSettings(json_data[0].Masks, AC_PK, ident);
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'ERROR',
          headerClose: false,
          buttonPrompt:
            '<center><h4>' +
            iTrans('There was an error receiving Aircraft Flight following Masking') +
            ': ' +
            json_data[0].Exception +
            '</h4></center>',
          buttons: {
            Ok: {
              click: function () {
                //Cancel edit
              },
            },
          },
        });
      }
    }
  ).fail(function (jqXHR, status, error) {
    $.mobile.loading('hide');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'ERROR',
      headerClose: false,
      buttonPrompt:
        '<center><h4>' +
        iTrans('There was an unknown error receiving Aircraft Flight following Masking') +
        '</h4></center>',
      buttons: {
        Ok: {
          click: function () {
            //Cancel edit
          },
        },
      },
    });
  });
}

var FF_MaskData = [];
var FF_Mask_ReturnPage = '#Following_Page';
function showFF_MaskSettings(data, AC_PK, ident) {
  $.mobile.changePage($('#aircraftFF_MaskingPage'), {
    reverse: true,
    changeHash: false,
  });
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    PAX_STAFF_LOOKUP[I.PrimaryKey] = I;
  }
  FF_MaskData = data;
  var html = '<h3>' + iTrans('Aircraft FF Masking') + ': ' + ident + '</h3>';
  if (data.length > 0) {
    html +=
      '<table class="limit-table"><thead><tr><th>' +
      iTrans('Start UTC') +
      '</th><th>' +
      iTrans('End UTC') +
      '</th><th>' +
      iTrans('Created UTC') +
      '</th><th>' +
      iTrans('By') +
      '</th><th>' +
      iTrans('Modified UTC') +
      '</th><th>' +
      iTrans('By') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in data) {
      var mask = data[i];
      var actions = iTrans('DELETED');
      if (mask.DELETED == 0) {
        actions =
          '<div class="vertical-center-container"><button class="action-btn"  onClick="FF_MaskEdit(' +
          i +
          ')"><img src="./images/edit.svg"</button><button class="action-btn"  onClick="FF_MaskDelete(' +
          i +
          ')"><img src="./images/delete.svg"</button></div>';
      }
      html +=
        '<tr><td>' +
        mask.start_Period +
        '</td><td>' +
        mask.end_Period +
        '</td><td>' +
        mask.created +
        '</td><td>' +
        (PAX_STAFF_LOOKUP[mask.createdBy] != undefined ? PAX_STAFF_LOOKUP[mask.createdBy].chatname : 'Unknown') +
        '</td><td>' +
        mask.modified +
        '</td><td>' +
        (PAX_STAFF_LOOKUP[mask.createdBy] != undefined ? PAX_STAFF_LOOKUP[mask.modifiedBy].chatname : 'Unknown') +
        '</td><td>' +
        actions +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html += '<h3>' + iTrans('No Current or future masks') + '</h3>';
  }

  html +=
    '<button data-mini="true" data-icon="plus" class="greenbg" onClick="newFF_Mask(' +
    AC_PK +
    ",'" +
    ident +
    '\')">' +
    iTrans('New Mask') +
    '</button>';
  $('#aircraftFF_MaskingHLDR').html(html).enhanceWithin();
}

function returnMaskingFF() {
  $.mobile.changePage($(FF_Mask_ReturnPage), {
    reverse: true,
    changeHash: false,
  });
}

function newFF_Mask(AC_PK, ident) {
  FF_MaskData.push({
    PrimaryKey: null,
    AC_PK: AC_PK,
    ident: ident,
    startPeriod: '',
    end_Period: '',
    comments: '',
    created: null,
    createdBy: null,
    modified: null,
    modifiedBy: null,
    History: [],
  });
  FF_MaskEdit(FF_MaskData.length - 1);
}

function FF_MaskDelete(index) {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm Delete'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this mask?'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          //acknolwedge
          FF_MaskData[index].DELETED = 1;
          saveFF_Mask(index);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function FF_MaskEdit(index) {
  var M = FF_MaskData[index];
  var html = '<h3>' + iTrans('Flight Following Masking') + ': ' + M.ident + '</h3>';

  html += '<label for="FF-start_Period">' + iTrans('UTC Start Period');
  html += '<input type="datetime-local" id="FF-start_Period" value="' + M.start_Period + '">';

  html += '<label for="FF-end_Period">' + iTrans('UTC End Period');
  html += '<input type="datetime-local" id="FF-end_Period" value="' + M.end_Period + '">';

  html += '<label for="FF-comments">' + iTrans('Comment');
  html += '<input type="text" id="FF-comments" value="' + M.comments + '">';
  html +=
    '<button data-mini="true" data-icon="plus" class="greenbg" id="saveFF_MastBTN" onClick="saveFF_Mask(' +
    index +
    ')">' +
    iTrans('Save Flight Following Mask') +
    '</button>';

  if (M.History.length > 0) {
    html += '<h4>' + iTrans('Masking History') + '</h4>';
    html +=
      '<table class="limit-table"><thead><tr><th>' +
      iTrans('Start UTC') +
      '</th><th>' +
      iTrans('End UTC') +
      '</th><th>' +
      iTrans('Created UTC') +
      '</th><th>' +
      iTrans('By') +
      '</th><th>' +
      iTrans('Modified UTC') +
      '</th><th>' +
      iTrans('By') +
      '</th></tr></thead><tbody>';
    for (var i in M.History) {
      var mask = M.History[i];
      html +=
        '<tr><td>' +
        mask.start_Period +
        '</td><td>' +
        mask.end_Period +
        '</td><td>' +
        mask.created +
        '</td><td>' +
        (PAX_STAFF_LOOKUP[mask.createdBy] != undefined ? PAX_STAFF_LOOKUP[mask.createdBy].chatname : 'Unknown') +
        '</td><td>' +
        mask.modified +
        '</td><td>' +
        (PAX_STAFF_LOOKUP[mask.modifiedBy] != undefined ? PAX_STAFF_LOOKUP[mask.modifiedBy].chatname : 'Unknown') +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html += '<h4>' + iTrans('No Masking History') + '</h4>';
  }

  $('#aircraftFF_MaskingHLDR').html(html).enhanceWithin();
}

function saveFF_Mask(index) {
  var M = FF_MaskData[index];
  M.start_Period = $('#FF-start_Period').val() + ':00';
  M.end_Period = $('#FF-end_Period').val() + ':00';
  M.start_Period = M.start_Period.replace('T', ' ');
  M.end_Period = M.end_Period.replace('T', ' ');
  M.comments = $('#FF-comments').val();
  $.mobile.loading('show', { theme: 'a' });
  $.post(
    BASEURL + 'menu-Maintenance-AJAX.php?action=setAC_Masking',
    {
      Mask: JSON.stringify(M),
    },
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        $('#saveFF_MastBTN').prop('disabled', false);
        AJAXFAIL(json_data[0].data);
      }
      if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Saved'),
          headerClose: false,
          buttonPrompt: iTrans('Masking Settings Saved'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //acknolwedge
                getFF_MaskSettings(M.AC_PK, M.ident);
              },
            },
          },
        });
      }
      if (json_data[0].ServerResponse == 'Error') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Message'),
          headerClose: false,
          buttonPrompt: json_data[0].Message,
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //acknolwedge
                $('#saveFF_MastBTN').prop('disabled', false);
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $.mobile.loading('hide');
    $('#saveFF_MastBTN').prop('disabled', false);
    AJAXFAIL(error);
  });
}
