/**
 * @abstract
 */
class AbstractDatabaseTable {
  static tableInstances = new Map();

  /** @type {SqliteConnection} connection */
  connection;

  /** @type {null|Promise<void>} tableCreating */
  tableCreating = null;

  /** @type {string|string[]} primaryKeyColumns */
  primaryKeyColumns;

  /**
   * @param {string} tableName
   * @param {string|string[]} primaryKeyColumns
   */
  constructor(tableName, primaryKeyColumns) {
    this.tableName = tableName;
    this.primaryKeyColumns = primaryKeyColumns;
  }

  /**
   * @returns {AbstractDatabaseTable}
   */
  static query() {
    let i = AbstractDatabaseTable.tableInstances.get(this);
    if (i == null) {
      i = new this();
      AbstractDatabaseTable.tableInstances.set(this, i);
    }

    return i;
  }

  async openConnection() {
    verbose.log('DEBUG', 'DB  connection: ' + this.constructor.name);
    if (this.connection == null) {
      if (window.CORDOVA) {
        this.connection = await window.vueApp.utilities.sqlite.open('CirroDB.db');
      } else {
        this.connection = await window.vueApp.utilities.sqlite.open(this.constructor.name + '_CirroDB.db');
      }
    }

    return this.connection;
  }

  /**
   * @returns {Promise<void>}
   */
  async createTable() {
    await this.openConnection();

    if (this.tableCreating == null) {
      const tableExists = await this.connection.tableExists(this.tableName);
      if (tableExists) {
        this.tableCreating = Promise.resolve();
      } else {
        // This prevents multiple table creation queries from being run at once,
        // which could be a cause for corruption
        verbose.log('SQL', `Creating table ${this.tableName}`);
        this.tableCreating = this.connection.query(this.tableCreationQuery());
      }
    }

    try {
      await this.tableCreating;
    } catch (e) {
      this.tableCreating = null;
      throw e;
    }
  }

  /**
   * @async
   * @protected
   * @returns {string}
   */
  tableCreationQuery() {
    throw new Error('tableCreationQuery() must be implemented');
  }

  /**
   *
   * @returns {Promise<void>}
   */
  async dropTable() {
    await this.openConnection();
    this.connection.dropTableIfExists(this.tableName);
    this.tableCreating = null;
  }

  /**
   *
   * @param data
   * @returns {Promise<void>}
   */
  async insert(data) {
    await this.createTable();

    if (!Array.isArray(data)) {
      data = [data];
    }

    const chunked = chunkArray(data, 50);

    window.vueApp.sentry.addBreadcrumb({
      category: 'sqlite',
      message: 'Insert',
      level: 'info',
      data: { table: this.tableName, rows: data.length },
    });

    for (let chunk of chunked) {
      const queries = this.buildInsertUpdateQuery(chunk);

      try {
        await this.connection.transaction(queries);
      } catch (e) {
        verbose.error('SQL', 'Failed to insert', e);
        throw e;
      }
    }
  }

  /**
   *
   * @param {string|null} whereClause
   * @param {any[]|null} values
   * @param {string[]|null} columns
   * @param {string} orderByClause
   * @returns {Promise<any[]>}
   */
  async select(whereClause = null, values = null, columns = null, orderByClause = '') {
    await this.createTable();

    let wc = ' where ' + whereClause;
    if (whereClause == null || whereClause === '') {
      wc = '';
    }

    if (values == null) {
      values = [];
    }

    let columnString = '*';

    if (columns !== null) {
      columnString = columns.map((c) => `"${c}"`).join(', ');
    }

    const q = {
      q: `select ${columnString} from ${this.tableName} ${wc} ${orderByClause}`,
      v: values,
    };

    window.vueApp.sentry.addBreadcrumb({
      category: 'sqlite',
      message: 'select',
      level: 'info',
      data: { table: this.tableName },
    });

    try {
      return await this.connection.query(q);
    } catch (e) {
      verbose.warn('SQL', 'Failed to select', e);
      return [];
    }
  }

  /**
   * Deletes rows from the table
   * @param whereClause
   * @param values
   * @returns {Promise<void>}
   */
  async delete(whereClause, values) {
    await this.createTable();

    const q = {
      q: `delete from ${this.tableName} where ${whereClause}`,
      v: values,
    };

    await this.connection.query(q);
  }

  /**
   *
   * @param {{}[]} data
   * @returns {*[]}
   */
  buildInsertUpdateQuery(data) {
    const updateWhereClause = this.#getUpdateWhereClause();

    const columns = Object.keys(data[0]).map((k) => `"${k}"`);
    let rowPlaceholders = Array(columns.length).fill('?').join(',');
    rowPlaceholders = `(${rowPlaceholders})`;

    let iq = `insert or ignore into ${this.tableName} (${columns.join(', ')}) values ${rowPlaceholders}`;
    let uq = `update ${this.tableName} set ${columns.map((c) => `${c} = ?`).join(', ')} where ${updateWhereClause}`;

    let queries = [];
    for (let row of data) {
      let v = Object.values(row).map((val) => {
        if (val instanceof Date) {
          return val.toISOString();
        }

        if (val != null && (typeof val === 'object' || Array.isArray(val))) {
          throw new Error(`Can't serialize data: ${JSON.stringify(val)}`);
        }

        return val;
      });

      queries.push({ q: iq, v });
      queries.push({ q: uq, v: v.concat(this.#getUpdateWhereValues(row)) });
    }

    return queries;
  }

  /**
   *
   * @param row
   * @returns {string[]}
   */
  #getUpdateWhereValues(row) {
    let pkValues = [];
    let pkCols = this.primaryKeyColumns;
    if (!Array.isArray(this.primaryKeyColumns)) {
      pkCols = [this.primaryKeyColumns];
    }

    for (let c of pkCols) {
      pkValues.push(row[c]);
    }

    return pkValues;
  }

  /**
   *
   * @returns {string}
   */
  #getUpdateWhereClause() {
    let columns = [];

    let pkCols = this.primaryKeyColumns;
    if (!Array.isArray(this.primaryKeyColumns)) {
      pkCols = [this.primaryKeyColumns];
    }

    for (let c of pkCols) {
      columns.push(`"${c}" = ?`);
    }

    return `${columns.join(' and ')}`;
  }

  /**
   * If the column data is null, return null, otherwise parse the JSON.
   * If the JSON is invalid, return the fallback.
   * @param {string} columnData
   * @param fallback
   * @returns {any|null}
   */
  parseJson(columnData, fallback = null) {
    if (columnData == null) {
      return null;
    }

    let result = fallback;
    try {
      result = JSON.parse(columnData);
    } catch (e) {
      // This is so ubiquitous that it's not worth logging
      //verbose.warn('SQL', 'JSON.parse failed:', e, columnData);
    }

    return result;
  }

  /**
   * Since legacy code is not consistent with how it stores JSON,
   * we need to check if the data is already a string.
   * @param columnData
   * @param fallback
   * @returns {*|null|string}
   */
  encodeJsonIfNotString(columnData, fallback = '[]') {
    if (typeof columnData === 'string') {
      return columnData;
    }

    return this.encodeJson(columnData, fallback);
  }

  /**
   * Encode the column, if possible. Otherwise return the fallback.
   * @param {string} columnData
   * @param {string} fallback
   * @returns {any|null}
   */
  encodeJson(columnData, fallback = '[]') {
    let result = fallback;

    try {
      result = JSON.stringify(columnData);
    } catch (e) {
      verbose.error('SQL', 'JSON.stringify failed:', e, columnData);
    }

    return result;
  }

  /**
   *
   * @param {ISqliteQuery} query
   * @returns {Promise<any[]>}
   */
  async raw(query) {
    window.vueApp.sentry.addBreadcrumb({
      category: 'sqlite',
      message: 'raw query',
      level: 'info',
      data: { table: this.tableName },
    });

    await this.openConnection();
    return this.connection.query(query);
  }
}
