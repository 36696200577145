/**
 *
 */
class TdgGroupDataTable extends AbstractDatabaseTable {
  constructor() {
    super('TDG_Group_Data', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey primary key,
            data text
        )
    `;
    //#endregion
  }

  async insert(data) {
    data = { PrimaryKey: 1, data: this.encodeJson(data) };

    return super.insert(data);
  }

  /**
   *
   * @param whereClause
   * @param values
   * @param columns
   * @param orderByClause
   * @returns {Promise<{}>}
   */
  async select(whereClause, values, columns = null, orderByClause = '') {
    const rows = await super.select(whereClause, values, columns, orderByClause);

    if (rows === false) {
      return false;
    }

    if (rows.length <= 0) {
      return {};
    }

    const row = rows[0];
    return this.parseJson(row.data);
  }
}
