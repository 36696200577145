/**
 * Get category and departments list then
 * show the report using the default filters
 */
function getReportData() {
  // Get the filters
  const getReportCategoryReq = new cSAMA_Request('ReportCategory', 'getData');
  const getReportDepartmentReq = new cSAMA_Request('Departments', 'getData');

  sendSMSRequest('GetReportCategories', getReportCategoryReq, vReportCategoryFilter);
  sendSMSRequest('GetReportDepartments', getReportDepartmentReq, vReportDepartmentFilter);

  // Set the from date filter to one month ago
  setFromDateToOneMonthAgo();
  // Set the to date filter to today
  setToDateToToday();

  // Get the report templates
  getReportFilterTemplates();

  // Hide download CSV on initial load
  $('#downloadCsvBtn').hide();

  // Get the reports
  const isPreviewActive = false;
  filterReportData(isPreviewActive);
}

/**
 * Gets all the reports using the
 * selected filters
 */
function filterReportData(isPreviewActive = true) {
  if (!isPreviewActive) {
    showReportPreview([], isPreviewActive);
    return;
  }

  // Get the reports
  const reportFilters = getSelectedFilters();

  const getReportReq = new cSAMA_Request('SafetyManagementReport', 'getData');
  getReportReq.Set('reportFilters', reportFilters);
  sendSMSRequest('GetSMSReport', getReportReq, showReportPreview);
}

/**
 * Sets the table headers and
 * populate the table rows
 *
 * @param _smsReportData
 * @param isPreviewActive
 */
function showReportPreview(_smsReportData, isPreviewActive = true) {
  const smsReportData = _smsReportData.reportData;
  const headers = getReportHeaders();

  // Populate the table
  const tableBody = $('#previewSMSReport');
  const smsReportHtml = formatReport(headers, smsReportData, isPreviewActive);
  tableBody.html(smsReportHtml);
  AddTableSorter('SMS_Report_Table');

  // Handle CSV download
  $('#downloadCsvBtn')
    .off('click')
    .on('click', function () {
      exportCSV(smsReportData);
    });

  // Show the modal
  $('#reportModal').show();
  $('#sms-report-overlay').show();
}

/**
 * Get the headers for the report
 * @returns {string[]}
 */
function getReportHeaders() {
  return [
    'Report ID', // rep_groupReportId
    'Title', // rep_summary
    'Report Status',
    'Reporter ID', // rep_reporterPk
    'Reporter', // rep_reporterName
    'Submitted Date', // rep_submittedDate
    'Approved Date', // rep_approvedDate
    'Flight Information',
    'Flight Date',
    'Itin / FR Number', // rep_itinPk
    'Flight Leg',
    'Report Type', // rep_isProactive 1 - Hazard - 0 Occurence
    'Report Category', // rep_catPk
    'Observed Date', // rep_dateTime
    'Observed Time', // rep_dateTime
    'Location', // rep_tarmac
    'Department', // rep_depPk
    'Anonymous Submission', // rep_isAnonymous
    'Hazard Description', // rep_problemDesc
    'Suggestions and Actions Taken', // rep_suggestions
    'Initial Risk', // rep_riskScoreInitial
    'Mitigated Risk', // rep_riskScoreCurrent
    'Photos',
    'Documents',
    'Keywords', // rep_keywordPks
    'Approving Admin User', //
  ];
}

/**
 * Hide the report modal
 */
function hideReportPreview() {
  $('#reportModal').hide();
  $('#sms-report-overlay').hide();
}

/**
 * Create the html table for the report
 * @param headers
 * @param data
 * @param isPreviewActive
 * @returns {string}
 */
function formatReport(headers = null, data = null, isPreviewActive = true) {
  const propAutoWidth = ['flightDate', 'flightLeg'];
  let table = '<div class="table-container"><table id="SMS_Report_Table" data-filter-"true" class="wrap"><thead><tr>';

  if (headers) {
    headers.forEach((header) => {
      table += '<th>' + iTrans(header) + '</th>';
    });
  }

  table += '</thead><tbody>';

  // Return this immediately on initial load
  if (!isPreviewActive) {
    table += '<tr>';
    table += '<td colspan=24>Click "Generate Report"</td>';
    table += '</tr>';
    table += '</tbody></table></div>';
    return table;
  }

  if (data?.length > 0) {
    // Create table td from the data
    table += cSAMA_Utils.GenerateTableContent(data, propAutoWidth);
    $('#downloadCsvBtn').show();
  } else {
    table += '<tr>';
    table += '<td colspan=24>No results found.</td>';
    table += '</tr>';
    $('#downloadCsvBtn').hide();
  }

  table += '</tbody></table></div>';

  return table;
}

/**
 * Handles the exporting of Report CSV
 * @param reportData
 */
function exportCSV(reportData = null) {
  if (!reportData || reportData.length === 0) {
    return;
  }

  const formData = reportData.map((data) => {
    let repPhotoNames = '';
    if (data.photoPks.length > 0) {
      data.photoPks.forEach((photo) => {
        repPhotoNames += photo.repPhotoName + '\n';
      });
    }

    let repDocuments = '';
    if (data.documents.length > 0) {
      data.documents.forEach((document) => {
        repDocuments += document.repDocumentName + '\n';
      });
    }

    return {
      [iTrans('Report ID')]: data.reportId,
      [iTrans('Title')]: data.reportTitle,
      [iTrans('Report Status')]: data.reportStatus,
      [iTrans('Reporter ID')]: data.reporterId,
      [iTrans('Reporter')]: data.reporter,
      [iTrans('Submitted Date')]: data.submittedDate,
      [iTrans('Approved Date')]: data.approvedDate,
      [iTrans('Flight Information (Itin or Flight Report)')]: data.flightInfo,
      [iTrans('Flight Date')]: data.flightDate,
      [iTrans('Itin / FR Number')]: data.itinFRNum,
      [iTrans('Flight Leg')]: cSAMA_Utils.ReplaceNewline(data.flightLeg),
      [iTrans('Report Type')]: data.reportType,
      [iTrans('Report Category')]: data.reportCategory,
      [iTrans('Observed Date')]: data.observedDate,
      [iTrans('Observed Time')]: data.observedTime,
      [iTrans('Location')]: data.location,
      [iTrans('Department')]: data.department,
      [iTrans('Anonymous Submission')]: data.anonymousSubmission,
      [iTrans('Hazard Description')]: data.hazardDescription,
      [iTrans('Suggestions and Actions Taken')]: data.suggestionsAndActions,
      [iTrans('Initial Risk')]: data.initialRisk,
      [iTrans('Mitigated Risk')]: data.mitigatedRisk,
      [iTrans('Photos')]: repPhotoNames,
      [iTrans('Documents')]: repDocuments,
      [iTrans('Keywords')]: data.reportKeywords,
      [iTrans('Approving Admin User')]: data.adminApprover,
    };
  });
  window.vueApp.utilities.blobUtils.downloadAsCsv(formData, 'Cirro_Safety_Management_Report');
}

/**
 * Populate the category options filter
 * @param repCategory
 */
function vReportCategoryFilter(repCategory) {
  const reportCatSel = $('#report-category');
  const reportCatVal = reportCatSel.val();

  reportCatSel.empty().append(
    $('<option>', {
      value: 0,
      text: 'Any',
    })
  );

  repCategory.forEach((category) => {
    reportCatSel.append(
      $('<option>', {
        value: category.rc_primaryKey,
        text: category.rc_name,
      })
    );
  });

  if (reportCatVal === null) {
    reportCatSel.val(0);
  } else {
    reportCatSel.val(reportCatVal);
  }
}

/**
 * Populate the department options filter
 * @param repDepartment
 */
function vReportDepartmentFilter(repDepartment) {
  const reportDepSel = $('#report-department');
  const reportDepVal = reportDepSel.val();

  reportDepSel.empty().append(
    $('<option>', {
      value: 0,
      text: 'Any',
    })
  );

  repDepartment.forEach((repDepartment) => {
    reportDepSel.append(
      $('<option>', {
        value: repDepartment.dep_primaryKey,
        text: repDepartment.dep_name,
      })
    );
  });

  if (reportDepVal === null) {
    reportDepSel.val(0);
  } else {
    reportDepSel.val(reportDepVal);
  }
}

/**
 * Sends request to the server
 * @param _reqName
 * @param _request
 * @param _callback
 */
function sendSMSRequest(_reqName, _request, _callback) {
  cSAMA_Request.Dispatch({
    name: _reqName,
    reqs: _request,
    clientErrorCode: 'SAMA 34 22 CC EE',
    fnSuccess: (_result) => {
      _callback(_result[0]);
    },
    fnFail: (_error) => {
      cSAMA_Dialog.ShowDialog({
        title: 'Error',
        message: 'Failed to generate Safety Management Report.',
      });
    },
  });
}

/**
 * Get the selected filters
 * @returns {{
 * fromDate: (*|string|jQuery)
 * sortByRisk: (*|string|jQuery),
 * toDate: (*|string|jQuery),
 * id: (*|string|jQuery),
 * type: (*|string|jQuery),
 * category: (*|string|jQuery),
 * department: (*|string|jQuery),
 * status: (*|string|jQuery),
 * sortByDate: (*|string|jQuery)
 * }}
 */
function getSelectedFilters() {
  let reportFilters;

  reportFilters = {
    type: $('#report-type').val(),
    category: $('#report-category').val(),
    department: $('#report-department').val(),
    status: $('#report-status').val(),
    fromDate: $('#from-date').val(),
    toDate: $('#to-date').val(),
    id: $('#id-name').val(),
    sortByDate: $('#sort-date').val(),
    sortByRisk: $('#sort-risk').val(),
  };

  return reportFilters;
}

/**
 * Report Filter Template Section
 */

/**
 * Set the filter from the selected template
 * @param templateFilter
 */
function setFilterFromTemplate(templateFilter) {
  const selectedFilter = templateFilter.filter;

  // Validate if selectedFilter is empty or undefined
  if (!selectedFilter || Object.keys(selectedFilter).length === 0) {
    return;
  }

  const fieldMapping = {
    type: '#report-type',
    category: '#report-category',
    department: '#report-department',
    status: '#report-status',
    fromDate: '#from-date',
    toDate: '#to-date',
    id: '#id-name',
    sortByDate: '#sort-date',
    sortByRisk: '#sort-risk',
  };

  // Iterate and populate fields
  Object.entries(fieldMapping).forEach(([key, selector]) => {
    $(selector)
      .val(selectedFilter[key] || '')
      .trigger('change');
  });
}

/**
 * Create or update a template
 */
function showSaveNewTemplateModal() {
  $('#reportNewTemplateModal').show();
  $('#reportModal').hide();
}

/**
 * Show the delete template modal
 */
function showDeleteTemplateModal() {
  $('#reportDeleteTemplateModal').show();
  $('#reportModal').hide();
}

/**
 * Hide the report template modal
 * and show the report modal
 */
function hideSaveNewTemplateModal() {
  $('#reportNewTemplateModal').hide();
  $('#reportModal').show();
}

/**
 * Hide the delete template modal
 */
function hideDeleteTemplateModal() {
  $('#reportDeleteTemplateModal').hide();
  $('#reportModal').show();
}

/**
 * Handle the selection of a template
 * @param _templateId
 */
function handleSelectTemplate(_templateId) {
  if (!_templateId || _templateId === '0') {
    return;
  }

  const getSelectedTemplateReq = new cSAMA_Request('ReportFilterTemplate', 'getSelectedTemplateFilter');
  getSelectedTemplateReq.Set('templateId', _templateId);

  sendSMSRequest('GetSelectedFilterTemplate', getSelectedTemplateReq, setFilterFromTemplate);
}

/**
 * Get the report filter templates
 */
function getReportFilterTemplates() {
  const getReportFilterTemplateReq = new cSAMA_Request('ReportFilterTemplate', 'getFilters');
  sendSMSRequest('GetReportFilterTemplates', getReportFilterTemplateReq, vReportFilterTemplate);
}

/**
 * Save the selected filters as a template
 * @returns {Promise<void>}
 */
function saveReportFilterTemplate() {
  const templateName = $('#templateName').val();
  const reportFilters = getSelectedFilters();

  const saveReportFilterTemplateReq = new cSAMA_Request('ReportFilterTemplate', 'saveFilter');
  saveReportFilterTemplateReq.Set('templateName', templateName);
  saveReportFilterTemplateReq.Set('filter', reportFilters);

  sendSMSRequest('SaveReportFilterTemplate', saveReportFilterTemplateReq, hideSaveNewTemplateModal);
  getReportFilterTemplates();
}

// Add delete deleteReportFilterTemplate function
function deleteReportFilterTemplate() {
  const templateId = $('#report-filter-template').val();

  if (templateId === '0') {
    cSAMA_Dialog.ShowDialog({
      title: 'Error',
      message: 'Please select a template to delete.',
    });
    return;
  }

  const deleteReportFilterTemplateReq = new cSAMA_Request('ReportFilterTemplate', 'deleteFilter');
  deleteReportFilterTemplateReq.Set('templateId', templateId);

  sendSMSRequest('DeleteReportFilterTemplate', deleteReportFilterTemplateReq, hideDeleteTemplateModal);
  getReportFilterTemplates();
}

/**
 * Populate the template options filter
 * @param repFilterTemplate
 */
function vReportFilterTemplate(repFilterTemplate) {
  const reportFilterTemplateSel = $('#report-filter-template');
  const reportFilterTemplateVal = reportFilterTemplateSel.val();

  reportFilterTemplateSel.empty().append(
    $('<option>', {
      value: 0,
      text: 'Select Template',
    })
  );

  repFilterTemplate.forEach((template) => {
    reportFilterTemplateSel.append(
      $('<option>', {
        value: template.id,
        text: template.name,
      })
    );
  });

  if (reportFilterTemplateVal === null) {
    reportFilterTemplateSel.val(0);
  } else {
    reportFilterTemplateSel.val(reportFilterTemplateVal);
  }

  reportFilterTemplateSel.trigger('change');
}

/**
 * Set the from date filter to one month ago
 */
function setFromDateToOneMonthAgo() {
  const oneMonthAgoString = getOneMonthAgoDate();
  $('#from-date').val(oneMonthAgoString).trigger('change'); // Set the value and trigger change
}

/**
 * Set the to date filter to today
 */
function setToDateToToday() {
  const todayString = getTodayDate();
  $('#to-date').val(todayString).trigger('change'); // Set the value and trigger change
}

/**
 * Toggle Filter for mobile view
 */
function toggleFilter() {
  const filterContainer = $('.smsReportModal .filter-container');
  filterContainer.toggleClass('collapsed');
}

/**
 * Hide report modal onload
 */
$(document).ready(function () {
  $('#reportModal').hide();
  $('#downloadCsvBtn').hide();
  $('#reportNewTemplateModal').hide();
  $('#reportDeleteTemplateModal').hide();
  $('#sms-report-overlay').hide();

  const $templateDropdown = $('#report-filter-template');
  const $deleteButton = $('#deleteTemplateBtn');

  // Function to toggle the delete button state
  const toggleDeleteButton = () => {
    $deleteButton.prop('disabled', !$templateDropdown.val() || $templateDropdown.val() === '0');
  };

  $templateDropdown.on('change', toggleDeleteButton).trigger('change');
});
