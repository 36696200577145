/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */

var INITIAL_SYNC = false;

function SyncIOS(e) {
  switch (e) {
    case 'STOP':
      //self.close(); not compatable
      break;
    case 'DutySync':
      ProgressBar = e.data.ProgressBar;
      break;
    case 'ProgressBarON':
      ProgressBar = true;
      break;
    case 'ProgressBarOFF':
      ProgressBar = false;
      break;
    case 'FullSync':
      getSyncStatus('All');
      break;
    case 'FCsync':
      getSyncStatus('FCsync');
      break;
    case 'WPsync':
      getSyncStatus('WPsync');
      break;
    case 'FDTsync':
      getSyncStatus('FDTsync');
      break;
    case 'FCsync':
      getSyncStatus('FCsync');
      break;
    case 'GROUPsync':
      getSyncStatus('GROUPsync');
      break;
    case 'RULEsync':
      getSyncStatus('RULEsync');
      break;
    case 'SYSTEMsync':
      getSyncStatus('SYSTEMsync');
      break;
  }
}

function getSyncStatus(action) {
  // if no documents or memos module access, drop the sync text row
  if (!(DOCUMENTS > 0 || MEMOS > 0)) {
    $('#PDFtextRow').remove();
  }

  $.getJSON(BASEURL + 'sync-AJAX.php?action=getLastSync', function (json_data) {
    //local database is empty and offline fail with message
    if (json_data[0].ServerResponse == 'Offline') {
      IOS_postMessage({
        Message: 'SyncStatus',
        status: 'BadConnection',
      });
      return;
    }
    if (json_data[0].ServerResponse == 'Success') {
      IOS_postMessage({
        Message: 'SyncStatus',
        status: 'Received Server Sync Dates',
      });
      checkLastSyncDates(json_data, action);
    } else {
      //some other error from the server
      IOS_postMessage({
        Message: 'SyncStatus',
        status: 'BadConnection',
      });
    }
  }).fail(function (jqXHR, status, error) {
    Con_Flag = false;

    IOS_postMessage({
      Message: 'SyncStatus',
      status: 'BadConnection',
    });

    // get last sync dates from local database and return
  });
}

var LastFDTsync, LastWPsync, LastFCsync, LastGROUPsync, LastRULEsync, LastSYSTEMsync, LastSCHEDULEsync, LastLIBRARYsync;
var PDF_SYNC_ERROR = false;
function checkLastSyncDates(data, action) {
  LastFCsync = stringToDateObject(data[0].LastFCsync);
  LastWPsync = stringToDateObject(data[0].LastWPsync);
  LastFDTsync = stringToDateObject(data[0].LastFDTsync);
  LastGROUPsync = stringToDateObject(data[0].LastGROUPsync);
  LastRULEsync = stringToDateObject(data[0].LastRULEsync);
  LastSYSTEMsync = stringToDateObject(data[0].LastSYSTEMsync);
  LastSCHEDULEsync = stringToDateObject(data[0].LastSCHEDULEsync);
  LastLIBRARYsync = stringToDateObject(data[0].LastLIBRARYsync);

  LastMANIFESTsync = stringToDateObject(data[0].LastMANIFESTsync);

  LastPASSENGERsync = stringToDateObject(data[0].LastPASSENGERsync);

  SyncTable.query()
    .createTable()
    .then(() => {
      local_Sync(action);
    });
}

var SYNCING = false;
var GROUP_SYNCING = false;
var FDT_SYNCING = false;
var FC_SYNCING = false;
var WP_SYNCING = false;
var RULE_SYNCING = false;
var SYSTEM_SYNCING = false;
var PDF_SYNCING = false;
var PASSENGER_SYNCING = false;
var MANIFEST_SYNCING = false;
var SCHEDULE_SYNCING = true; //by default because it doesnt' exists in the DB
var CurMANIFESTsync = '1980-01-01 00:00:00';
var CurPASSENGERsync = '1980-01-01 00:00:00';
var syncStart = null;

function local_Sync(action) {
  // get local sync records
  verbose.log('SYNC', 'Starting Local Sync', action);
  let curDate = new Date();
  curDate.setUTCHours(12, 0, 0, 0);
  syncStart = new Date();
  SyncTable.query()
    .raw({ q: 'select * from Sync order by SyncItem asc' })
    .then((rows) => {
      console.log('SYNC: Local Sync Records - ', rows);

      const SyncItems = [
        'LastFDTsync',
        'LastGROUPsync',
        'LastFCsync',
        'LastWPsync',
        'LastRULEsync',
        'LastSCHEDULEsync',
        'LastLIBRARYsync',
        'LastSYSTEMsync',
        'LastMANIFESTsync',
        'LastPASSENGERsync',
      ];
      for (var s in SyncItems) {
        var S = SyncItems[s];
        var Sfound = false;

        for (var i in rows) {
          if (rows[i].SyncItem == S) Sfound = true;
          if (rows[i].SyncItem == 'CurPASSENGERsync') {
            CurPASSENGERsync = rows[i].Date;
            continue;
          }
          if (rows[i].SyncItem == 'CurMANIFESTsync') {
            CurMANIFESTsync = rows[i].Date;
            continue;
          }
        }
        if (!Sfound && S.SyncItem != 'CurMANIFESTsync' && S.SyncItem != 'CurPASSENGERsync') {
          rows.push({ rowid: rows.length, SyncItem: S, Date: '1980-01-01 00:00:00' });
        }
      }

      for (var i in rows) {
        var Item = rows[i];
        var date = stringToDateObject(Item.Date);
        IOS_postMessage({
          Message: 'SyncDate',
          SyncItem: Item.SyncItem,
          date: Item.Date,
        });
        console.log('SYNC: Item - ', Item.SyncItem);
        if (action == 'FDTsync' && Item.SyncItem != 'LastFDTsync') continue;
        if (action == 'FCsync' && Item.SyncItem != 'LastFCsync') continue;
        switch (Item.SyncItem) {
          case 'LastFDTsync':
            ////console.log(date);
            ////console.log(LastFDTsync);
            var date2 = new Date(Item.Date.substr(0, 10) + 'T12:00:00Z');
            //date2.setDate(date2.getDate() + 1);
            //alert(curDate);
            //alert(date2);

            if (date.getTime() != LastFDTsync.getTime() || date2.getTime() < curDate.getTime()) {
              //alert(date2.getTime() + " " + curDate.getTime());

              if (action == 'All' || action == 'FDTsync') {
                if ((userShouldHaveFDT(USERTYPE) && DUTYTIMES == '1') || TIMESHEETS == '1') {
                  IOS_postMessage({
                    Message: 'FDTtext',
                    text: 'Sync Queued',
                  });
                  SYNCING = true;
                  VueBridge.LegacyAppSyncing(SYNCING);
                  FDT_SYNCING = true;
                } else {
                  IOS_postMessage({
                    Message: 'FDTtext',
                    text: 'N/A',
                  });
                }
              }
            } else {
              if (DUTYTIMES == '1' || TIMESHEETS == '1') {
                IOS_postMessage({
                  Message: 'FDTtext',
                  text: 'Complete',
                });
                if (FDT_ADMIN_UPDATE)
                  $('#Duty_Status').html(iTrans('Admin Update') + ': ' + ADMIN_FDT_DATA.CurUser.chatname);
                else $('#Duty_Status').html('Data: Synced ' + LastFDTsync);
              } else {
                IOS_postMessage({
                  Message: 'FDTtext',
                  text: 'N/A',
                });
              }
            }
            break;
          case 'LastGROUPsync':
            if (date.getTime() != LastGROUPsync.getTime()) {
              if (action == 'All' || action == 'GROUPsync') {
                SYNCING = true;
                VueBridge.LegacyAppSyncing(SYNCING);
                GROUP_SYNCING = true;

                IOS_postMessage({
                  Message: 'ACtext',
                  text: 'Sync Queued',
                });
              }
            } else {
              IOS_postMessage({
                Message: 'ACtext',
                text: 'Complete',
              });
            }
            break;
          case 'LastFCsync':
            if (date.getTime() != LastFCsync.getTime() || action == 'FCsync') {
              if (action == 'All' || action == 'FCsync') {
                SYNCING = true;
                VueBridge.LegacyAppSyncing(SYNCING);
                FC_SYNCING = true;
                IOS_postMessage({
                  Message: 'FCtext',
                  text: 'Sync Queued',
                });
              }
            } else {
              IOS_postMessage({
                Message: 'FCtext',
                text: 'Complete',
              });
            }
            break;
          case 'LastWPsync':
            if (date.getTime() != LastWPsync.getTime()) {
              if (action == 'All' || action == 'WPsync') {
                SYNCING = true;
                VueBridge.LegacyAppSyncing(SYNCING);
                WP_SYNCING = true;
                IOS_postMessage({
                  Message: 'WPtext',
                  text: 'Sync Queued',
                });
              }
            } else {
              IOS_postMessage({
                Message: 'WPtext',
                text: 'Complete',
              });
            }
            break;
          case 'LastRULEsync':
            if (date.getTime() != LastRULEsync.getTime()) {
              if (action == 'All' || action == 'RULEsync') {
                SYNCING = true;
                VueBridge.LegacyAppSyncing(SYNCING);
                RULE_SYNCING = true;
                IOS_postMessage({
                  Message: 'Rtext',
                  text: 'Sync Queued',
                });
              }
            } else {
              IOS_postMessage({
                Message: 'Rtext',
                text: 'Complete',
              });
            }
            break;
          case 'LastSYSTEMsync':
            if (date.getTime() != LastSYSTEMsync.getTime()) {
              if (action == 'All' || action == 'SYSTEMsync') {
                SYNCING = true;
                VueBridge.LegacyAppSyncing(SYNCING);
                SYSTEM_SYNCING = true;
                SystemSyncError = true; //set to error state unless completed successfully
                IOS_postMessage({
                  Message: 'Stext',
                  text: 'Sync Queued',
                });
                IOS_postMessage({
                  Message: 'Ptext',
                  text: 'Sync Queued',
                });
              }
            } else {
              IOS_postMessage({
                Message: 'Stext',
                text: 'Complete',
              });
              IOS_postMessage({
                Message: 'Ptext',
                text: 'Complete',
              });
            }
            break;
          case 'LastSCHEDULEsync':
            if (SCHEDULER > 0) {
              if (date.getTime() != LastSCHEDULEsync.getTime()) {
                IOS_postMessage({
                  Message: 'SCHtext',
                  text: 'Sync Queued',
                });
                SYNCING == true;
                SCHEDULE_SYNCING = true;
              } else {
                IOS_postMessage({
                  Message: 'SCHtext',
                  text: 'Complete',
                });
                SCHEDULE_SYNCING = false; //true to force sync always
              }
            } else {
              IOS_postMessage({
                Message: 'SCHtext',
                text: 'N/A',
              });
              SCHEDULE_SYNCING = false;
            }
            break;
          case 'LastLIBRARYsync':
            if (!(DOCUMENTS > 0 || MEMOS > 0)) {
              break;
            }

            if (date.getTime() != LastLIBRARYsync.getTime() && (action == 'All' || action == 'SYSTEMsync')) {
              PDF_SYNCING = true;
              IOS_postMessage({
                Message: 'PDFtext',
                text: 'Sync Queued',
              });
              SYNCING = true;
              VueBridge.LegacyAppSyncing(SYNCING);
            } else {
              IOS_postMessage({
                Message: 'PDFtext',
                text: 'Complete',
              });
            }
            break;
          case 'LastMANIFESTsync':
            if (GROUP_DATA.Pax_Man > 0) {
              if (date.getTime() != LastMANIFESTsync.getTime() || CurMANIFESTsync != '') {
                if (CurMANIFESTsync == '') {
                  CurMANIFESTsync = dateToStringObject(date);
                }
                IOS_postMessage({
                  Message: 'MANIFESTtext',
                  text: 'Sync Queued',
                });
                SYNCING == true;
                MANIFEST_SYNCING = true;
              } else {
                IOS_postMessage({
                  Message: 'MANIFESTtext',
                  text: 'Complete',
                });
                MANIFEST_SYNCING = false; //true to force sync always
              }
            } else {
              IOS_postMessage({
                Message: 'MANIFESTtext',
                text: 'N/A',
              });
              MANIFEST_SYNCING = false;
            }
            break;
          case 'LastPASSENGERsync':
            if (GROUP_DATA.Pax_Man > 0) {
              if (date.getTime() != LastPASSENGERsync.getTime() || CurPASSENGERsync != '') {
                if (CurPASSENGERsync == '') {
                  CurPASSENGERsync = dateToStringObject(date);
                }
                IOS_postMessage({
                  Message: 'PAXtext',
                  text: 'Sync Queued',
                });
                SYNCING == true;
                PASSENGER_SYNCING = true;
              } else {
                IOS_postMessage({
                  Message: 'PAXtext',
                  text: 'Complete',
                });
                PASSENGER_SYNCING = false; //true to force sync always
              }
            } else {
              IOS_postMessage({
                Message: 'PAXtext',
                text: 'N/A',
              });
              PASSENGER_SYNCING = false;
            }
            break;
        }
      }

      if (rows.length == 0 || rows.length < 10) {
        //No Sync Dates

        //no local records update all and sync all || missing sync record trigger fully sync to fix issue
        //console.log("No Local Databases: BLACK SYNC");
        SYNCING = true;
        VueBridge.LegacyAppSyncing(SYNCING);
        INITIAL_SYNC = true;

        GROUP_SYNCING = true;
        IOS_postMessage({
          Message: 'Ptext',
          text: 'Sync Queued',
        });
        IOS_postMessage({
          Message: 'ACtext',
          text: 'Sync Queued',
        });

        FDT_SYNCING = true;
        IOS_postMessage({
          Message: 'FDTtext',
          text: 'Sync Queued',
        });

        if (DOCUMENTS > 0 || MEMOS > 0) {
          PDF_SYNCING = true;
          IOS_postMessage({
            Message: 'PDFtext',
            text: 'Sync Queued',
          });
        }
        if (GROUP_DATA.Pax_Man > 0) {
          IOS_postMessage({
            Message: 'MANIFESTtext',
            text: 'Sync Queued',
          });
          MANIFEST_SYNCING = true;

          IOS_postMessage({
            Message: 'PAXtext',
            text: 'Sync Queued',
          });
          PASSENGER_SYNCING = true;
        }

        FC_SYNCING = true;
        IOS_postMessage({
          Message: 'FCtext',
          text: 'Sync Queued',
        });

        WP_SYNCING = true;
        IOS_postMessage({
          Message: 'WPtext',
          text: 'Sync Queued',
        });

        RULE_SYNCING = true;
        IOS_postMessage({
          Message: 'Rtext',
          text: 'Sync Queued',
        });

        SYSTEM_SYNCING = true;
        IOS_postMessage({
          Message: 'Stext',
          text: 'Sync Queued',
        });
        if ((userShouldHaveFDT(USERTYPE) && DUTYTIMES == '1') || TIMESHEETS == '1') {
        } else {
          update_Sync('LastFDTsync', LastFDTsync); //update last sync date
          FDT_SYNCING = false;
          IOS_postMessage({
            Message: 'FDTtext',
            text: 'N/A',
          });
        }
        if (SCHEDULER > 0) {
          IOS_postMessage({
            Message: 'SCHtext',
            text: 'Queued',
          });
          SCHEDULE_SYNCING = true;
        } else {
          IOS_postMessage({
            Message: 'SCHtext',
            text: 'N/A',
          });
          SCHEDULE_SYNCING = false;
        }

        SYNC_STATUS('local_Sync');
        return;
      } else {
        if (rows.length >= 8 && SYNCING == false) {
          SYNC_STATUS('local_Sync');
        } else if (rows.length >= 8 && SYNCING == true) {
          SYNC_STATUS('local_Sync');
        }
      }
    });
}

/* ***********************************Working syncFDT with progress bar if user accessing while sycn is ongoing ************************* */
var toasterID_SynchronizationError = null;
let FDT_SyncFailedShowToast = false;
function SYNC_STATUS(caller) {
  console.log(caller);
  /*
  console.log(caller);
  console.log("SYNC_STATUS Updating...");
  console.log("SYSTEM_SYNCING: " + SYSTEM_SYNCING);
  console.log("FDT_SYNCING: " + FDT_SYNCING);
  console.log("FC_SYNCING: " + FC_SYNCING);
  console.log("WP_SYNCING: " + WP_SYNCING);
  console.log("GROUP_SYNCING: " + GROUP_SYNCING);
  console.log("RULE_SYNCING: " + RULE_SYNCING);
  console.log("SCHEDULE_SYNCING: " + SCHEDULE_SYNCING);
  console.log("PDF_SYNCING: " + PDF_SYNCING);
   console.log("PASSENGER_SYNCING: " + PASSENGER_SYNCING);
   console.log("MANIFEST_SYNCING: " + MANIFEST_SYNCING);
  */

  if (
    !WP_SYNCING &&
    !FC_SYNCING &&
    !FDT_SYNCING &&
    !RULE_SYNCING &&
    !GROUP_SYNCING &&
    !SYSTEM_SYNCING &&
    !PDF_SYNCING &&
    !SCHEDULE_SYNCING &&
    !MANIFEST_SYNCING &&
    !PASSENGER_SYNCING
  ) {
    if (APP_ENVIRONMENT !== 'production') {
      const currentTime = new Date();
      const syncTime = (currentTime - syncStart) / 1000;
      $('#syncTime').html(`<p>Sync took: ${syncTime} seconds`);
    }

    if (FDT_ADMIN_UPDATE) {
      $('#DutyTimes').addClass('greybg');
      $('#Duty_Status').html(iTrans('Admin Update') + ': ' + ADMIN_FDT_DATA.CurUser.chatname);
      $.mobile.loading('hide');
      if (FDT_MODULE_VERSION == 4) {
        ForceStart_FDTCalendar = true;
        initDutyTimesV4();
      } else {
        if (CurPageID() != 'DutyTimes') {
          $.mobile.changePage($('#DutyTimes'), {
            changeHash: false,
          });
          initDutyTimes(); //menu-Duty.js
        }
      }

      IOS_postMessage({
        Message: 'SyncStatus',
        status: 'COMPLETED',
      });

      return;
    }
    IOS_postMessage({
      Message: 'SyncText',
      text: 'Complete! Launching...',
    });
    if (FDT_MODULE_CALLBACK != null) {
      FDT_MODULE_CALLBACK = null;
    } else {
      IOS_postMessage({
        Message: 'SyncStatus',
        status: 'COMPLETED',
      });
    }

    if (SystemSyncError == false) {
      update_Sync('LastSYSTEMsync', LastSYSTEMsync);
    } //update sync only if no error

    var ToastSyncErrorText = '';
    if (SystemSyncError) {
      ToastSyncErrorText += 'Pilot List Failed to Sync!';
    }

    if (GroupSyncERROR) {
      if (ToastSyncErrorText != '') ToastSyncErrorText += '<br />';
      ToastSyncErrorText += 'Aircraft Data Failed to Sync!';
    }

    if (FC_SyncError) {
      if (ToastSyncErrorText != '') ToastSyncErrorText += '<br />';
      ToastSyncErrorText += 'Fuel Cache Data Failed to Sync!';
    }

    if (FDT_SyncFailed) {
      FDT_SyncFailedShowToast = true;
    }

    if (FDT_RuleSyncfailed) {
      if (ToastSyncErrorText != '') ToastSyncErrorText += '<br />';
      ToastSyncErrorText += 'Duty Times Custom Rules Failed to Sync!';
    }

    if (SCH_Syncfailed) {
      if (ToastSyncErrorText != '') ToastSyncErrorText += '<br />';
      ToastSyncErrorText += 'Scheduler Data Failed to Sync!';
    }

    if (WPT_SOMETHING_FAILED) {
      if (ToastSyncErrorText != '') ToastSyncErrorText += '<br />';
      ToastSyncErrorText += 'Waypoint Data Failed to Sync!';
    }

    if (ToastSyncErrorText != '') {
      toasterID_SynchronizationError = window.toaster.show(
        ToastSyncErrorText + "<br /><a href='#' onClick='BackgroundSync()'>Retry Synchronization Now</a><br />",
        0
      );
    }
    if (!PDF_SYNC_ERROR) {
      update_Sync('LastLIBRARYsync', LastLIBRARYsync);
    }
    SystemSyncError = false;
    GroupSyncERROR = false;
    FC_SyncError = false;
    FDT_SyncFailed = false;
    FDT_RuleSyncfailed = false;
    SCH_Syncfailed = false;
    WPT_SOMETHING_FAILED = false;
    PDF_SYNC_ERROR = false;
  } else {
    if (SYSTEM_SYNCING) {
      syncSYSTEM();
    } else if (FDT_SYNCING) {
      syncFDT();
    } else if (FC_SYNCING) {
      syncFC();
    } else if (WP_SYNCING) {
      syncWP();
    } else if (GROUP_SYNCING) {
      syncGROUP();
    } else if (RULE_SYNCING) {
      syncRULE();
    } else if (SCHEDULE_SYNCING) {
      syncSCHEDULE();
    } else if (PASSENGER_SYNCING) {
      PAX_syncPassengers();
    } else if (MANIFEST_SYNCING) {
      PAX_syncManifests();
    } else if (PDF_SYNCING) {
      VueBridge.getOfflineDocuments();
    }
  }
}

var OFFLINEDBLIST = [
  {
    File: 'AirportIndex',
    Label: 'Airport Database',
    Size: '27MB',
    Index: 0,
    Required: 1,
    Version: 'Unknown',
    web: true,
  },
  { File: 'Navaids', Label: 'Navaids Map Layer', Size: '3MB', Index: 1, Required: 1, web: true },
  { File: 'NavaidDB', Label: 'Navaid Database', Size: '1MB', Index: 2, Required: 1, web: true },
  { File: 'naturalearth', Label: 'Basic Offline Country Outlines', Size: '9MB', Index: 3, Required: 1, web: true },
  { File: 'Satellite', Label: 'Basic Offline Satellite', Size: '28MB', Index: 4, Required: 1, web: true },
  { File: 'POI_Index', Label: 'North America POI Database', Size: '180MB', Index: 5, Required: 0, web: true },
  { File: 'TDG_2021', Label: 'Dangerous Goods Database & ERG', Size: '47MB', Index: 6, Required: 0, web: false },
];

function SetAirportIndexCurVersion(AirportIndexVersion) {
  if (OFFLINEDBLIST[0].File == 'AirportIndex') {
    OFFLINEDBLIST[0].Version = AirportIndexVersion;
  }
}

function ResetOfflineDatabases() {
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'CONFIRM',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to re-download mandatory Cirro databases?',
    buttons: {
      Yes: {
        click: function () {
          confirm_ResetOfflineDatabases();
        },
      },
      Cancel: {
        click: function () {
          return;
        },
      },
    },
  });
}

function confirm_ResetOfflineDatabases() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $('#redownloadDatabases').prop('disabled', true);
  for (var i in OFFLINEDBLIST) {
    var FItem = 'OFFLINE_DB_' + OFFLINEDBLIST[i].File;
    localStorageDB.clearItem(FItem, null);
    removeMbtileFile(OFFLINEDBLIST[i].File);
  }
  setTimeout(function () {
    clickHandler('ManualSync');
  }, 2000);
}

function isAirportIndex(offlineDbEntry) {
  return offlineDbEntry.File === 'AirportIndex';
}

function isUpdateRequired(offlineDbEntry) {
  const offlineDbEntryKey = 'OFFLINE_DB_' + offlineDbEntry.File;
  const offlineDbEntryState = LOCALSTORAGE[offlineDbEntryKey];

  if (isAirportIndex(offlineDbEntry)) {
    if (offlineDbEntry.Version === 'Unknown') {
      verbose.error('API', 'Airport Index version was unset');
      return false;
    }

    return LOCALSTORAGE.AirportIndexVersion !== +offlineDbEntry.Version;
  }

  return offlineDbEntryState !== 'Available' && offlineDbEntryState !== 'Online Only';
}

function CheckOfflineDatabases(index) {
  console.log('CheckOfflineDatabases: ' + index);
  if (index > OFFLINEDBLIST.length - 1) {
    return;
  }

  const currentDb = OFFLINEDBLIST[index];

  if (!isUpdateRequired(currentDb) || (!currentDb.web && !CORDOVA)) {
    index++;
    CheckOfflineDatabases(index);
    return;
  }

  if (isAirportIndex(currentDb)) {
    if (IOS) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Required Offline Content',
        headerClose: false,
        buttonPrompt: `<p>This file is required for using the maps and charts module: "${currentDb.Label}" ${currentDb.Size}.</p>`,
        buttons: {
          Download: {
            click: function () {
              download_Database(currentDb, Download_DatabaseCallback);
            },
          },
          'No Thanks': {
            click: function () {
              index++;
              CheckOfflineDatabases(index);
            },
          },
        },
      });
    } else {
      download_Database(currentDb, Download_DatabaseCallback);
    }
    return;
  }

  check_Database_Exists(currentDb, CheckOfflineDatabaseCallback);
}

function CheckOfflineDatabaseCallback(result, item) {
  const fileIdent = 'OFFLINE_DB_' + item.File;
  if (result != null && result !== false) {
    //Downloaded; Mark it in the database
    localStorageDB.setItem(fileIdent, 'Available');
    LOCALSTORAGE[fileIdent] = 'Available';
    CheckOfflineDatabases(item.Index + 1);
    return;
  }
  //prompt user to download file
  if (item.Required > 0) {
    if (IOS) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Required Offline Content',
        headerClose: false,
        buttonPrompt: `<p>This file is required for using the maps and charts module: "${item.Label}" ${item.Size}.</p>`,
        buttons: {
          Download: {
            click: function () {
              download_Database(item, Download_DatabaseCallback);
            },
          },
          'No Thanks': {
            click: function () {
              localStorageDB.setItem(fileIdent, 'Online Only');
              LOCALSTORAGE[fileIdent] = 'Online Only';
              CheckOfflineDatabases(item.Index + 1);
            },
          },
        },
      });
    } else {
      download_Database(item, Download_DatabaseCallback);
    }
    return;
  }

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'New Offline Content',
    headerClose: false,
    buttonPrompt: `<p>Would you like to download "${item.Label}" ${item.Size} for Offline Use?</p>`,
    buttons: {
      Download: {
        click: function () {
          download_Database(item, Download_DatabaseCallback);
        },
      },
      'No Thanks': {
        click: function () {
          localStorageDB.setItem(fileIdent, 'Online Only');
          LOCALSTORAGE[fileIdent] = 'Online Only';
          CheckOfflineDatabases(item.Index + 1);
        },
      },
    },
  });
}

function Download_DatabaseCallback(result, Item) {
  if (!result) {
    //download failed prompt to try again
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Download Error',
      headerClose: false,
      buttonPrompt: '<p>"' + Item.Label + '" Failed to download would you like to try again?</p>',
      buttons: {
        Retry: {
          click: function () {
            download_Database(Item, Download_DatabaseCallback);
          },
        },
        'Try Later': {
          click: function () {
            var index = Item.Index;
            index++;
            //localStorageDB.setItem(FItem, "Online Only");
            CheckOfflineDatabases(index);
          },
        },
      },
    });
  } else {
    // downloaded increment index and next
    var FItem = 'OFFLINE_DB_' + Item.File;
    LOCALSTORAGE[FItem] = 'Available';
    localStorageDB.setItem(FItem, 'Available');
    if (Item.File == 'AirportIndex') {
      localStorageDB.setItem('AirportIndexVersion', Item.Version);
      LOCALSTORAGE.AirportIndexVersion = Item.Version;
    }
    var index = Item.Index;
    index++;
    CheckOfflineDatabases(index);
  }
}

function check_Database_Exists(Item, callback) {
  //console.log("Checking for existing File");
  var fileURL;
  var File = Item.File;
  var Label = Item.Label;

  if (CORDOVA) {
    fileURL = `${NATIVEFILES}/${File}.mbtiles`;
  } else {
    check_Database_Exists_Web(Item, callback);
    return;
  }

  window.resolveLocalFileSystemURL(
    fileURL,
    function (fileEntry) {
      //console.log("Found existing mbtile");
      let FItem = 'OFFLINE_DB_' + File;
      LOCALSTORAGE[FItem] = 'Available';
      localStorageDB.setItem(FItem, 'Available');
      callback(true, Item);
    },
    function (e) {
      callback(false, Item);
    }
  );
}

async function check_Database_Exists_Web(Item, callback) {
  fileURL = window.vueApp.utilities.file.dirDb + Item.File + '.mbtiles';
  var checkFile = await window.vueApp.utilities.file.driver.exists(fileURL);
  callback(checkFile, Item);
}

function download_Database(Item, callback) {
  let File = Item.File;
  let Label = Item.Label;

  if (Item.Required > 0) {
    Label += ' - REQUIRED';
  }

  let fileURL = null;
  if (ANDROID) {
    fileURL = cordova.file.applicationStorageDirectory + 'databases/' + File + '.mbtiles';
  } else if (IOS) {
    fileURL = cordova.file.applicationStorageDirectory + 'Library/LocalDatabase/' + File + '.mbtiles';
  } else {
    download_Databasse_Web(Item, callback);
    return;
  }

  let fromURL = MBTILE_URL + '/' + File + '.mbtiles';

  if (CORDOVA) {
    //in App.html sets FREE_SPACE
    getFreeSpace();
  }

  progressDialog.show(Label);

  let fileTransfer = new FileTransfer();
  fileTransfer.onprogress = function (progressEvent) {
    if (progressEvent.lengthComputable) {
      let FreeSpace = FREE_SPACE - progressEvent.loaded;
      if (progressEvent.total - progressEvent.loaded > FreeSpace) {
        fileTransfer.abort();

        progressDialog.hide();

        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: 'Error',
          headerClose: false,
          buttonPrompt:
            'Your device is running low on storage!<br />Please free up space<br /><b>' +
            Label +
            ' failed to download!</b>',
          buttons: {
            OK: {
              click: function () {},
            },
          },
        });
        return;
      }
      let percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      let stats = humanBytes(progressEvent.loaded) + ' / ' + humanBytes(progressEvent.total);

      FreeSpace = '[' + humanBytes(FreeSpace) + ' available]';
      stats += FreeSpace;

      progressDialog.update(stats, percent);
    } else {
      progressDialog.update('Downloading Unknown Amount');
    }
  };
  fileTransfer.download(
    fromURL,
    fileURL,
    function (entry) {
      progressDialog.hide();
      callback(true, Item);
    },
    function (error) {
      verbose.error('SYNC', error);

      progressDialog.hide();

      callback(false, Item);
    },
    true,
    null
  );
}

async function download_Databasse_Web(Item, callback) {
  //todo figure out how error handling works here
  try {
    $('#downloadProgress').show();
    $('#downloadProgressTXT').show();
    $('#downloadProgress').css('width', '0%');
    $('#downloadProgressTXT').html('Initializing Download...');
    await window.vueApp.utilities.download
      .fromUrl(MBTILE_URL + '/' + Item.File + '.mbtiles')
      .onProgress((chunk) => {
        const progressPercent = Math.round((chunk.loaded / chunk.total) * 100);
        var stats =
          'Downloading: ' +
          Item.Label +
          ' ' +
          Math.round(chunk.loaded / 1000000) +
          'MB / ' +
          Math.round(chunk.total / 1000000) +
          'MB - ' +
          progressPercent +
          '%';
        $('#downloadProgressTXT').html(stats);
        $('#downloadProgress').css('width', progressPercent + '%');
      })
      .toFileSystem(window.vueApp.utilities.file.dirDb, Item.File + '.mbtiles');
    $('#downloadProgress').hide();
    $('#downloadProgressTXT').hide();
    callback(true, Item);
  } catch (e) {
    $('#downloadProgress').hide();
    $('#downloadProgressTXT').hide();
    console.error(e);
    callback(false, Item);
  }
}

function getflightCatID(id) {
  if (GROUP_DATA.FlightCategories.length > 0) {
    for (var i in GROUP_DATA.FlightCategories) {
      var fc = GROUP_DATA.FlightCategories[i];
      if (fc.PrimaryKey == id) {
        return fc;
      }
    }
  }
  return {
    Base: '',
    PrimaryKey: 0,
    client: '',
    fltcatcode: '[N/A]',
    fltcatdesc: '[Not Assigned]',
    group: '',
    inactive: 0,
    modUserPK: 0,
    modified: '2000-01-01 00:00:00',
  };
}

function getdepartmentID(id) {
  if (GROUP_DATA.Departments.length > 0) {
    for (var i in GROUP_DATA.Departments) {
      var fc = GROUP_DATA.Departments[i];
      if (fc.id == id) return fc;
    }
  }
  return {
    billing_job: 0,
    color: '#ffffff',
    created_at: '2000-01-01 00:00:00',
    dept_code: null,
    group_id: 0,
    icao: '',
    id: 0,
    inactive: 0,
    name: '[N/A]',
    updated_at: '2000-01-01 00:00:00',
  };
}

var TimezoneSearchList = null;
var TIMEZONE_Input_List = {};
function makeTimezoneInput(divID, inputID, label, enhance, value = 'UTC', onChange = false) {
  if (TIMEZONE_Input_List[divID] !== undefined) {
    return;
  }
  console.log('Making Timezone Input for: ' + inputID);
  var html =
    '<input type="text" placeholder="' + iTrans('Search') + ': ICAO/' + label + '" id="' + inputID + '_TZSearch"/>';

  html += '<label for="' + inputID + '">' + label + '</label>';
  html += '<select data-mini="true" id="' + inputID + '">';
  if (TimezoneSearchList == null) {
    TimezoneSearchList = getTimezoneList();
  }
  for (let i in TimezoneSearchList) {
    let tz = TimezoneSearchList[i];
    html += '<option value="' + tz.value + '" ' + (value == tz.value ? 'selected' : '') + '>' + tz.label + '</option>';
  }
  html += '</select>';

  $('#' + divID).html(html);
  if (enhance) $('#' + divID).enhanceWithin();
  if (onChange !== false) {
    $('#' + inputID).change(onChange);
  }
  $('#' + inputID + '_TZSearch').autocomplete({
    select: TimezoneInputSearchSelect,
    source: TimezoneInputSearch,
  });
  TIMEZONE_Input_List[divID] = true;
}

function TimezoneInputSearch(term, callback) {
  var txt = String(term.term).toLowerCase();

  var options = [];
  if (TimezoneSearchList == null) {
    TimezoneSearchList = getTimezoneList();
  }
  for (var J in TimezoneSearchList) {
    var I = TimezoneSearchList[J];

    if (I.label.toLowerCase().indexOf(txt) != -1) {
      options.push({ label: I.label, value: I.value });
    }
  }
  if (txt.length == 4) {
    if (CORDOVA) SearchMobileDB(term, callback, 'TZSearch', options);
    else BILLING_SearchAirports_Type(term, callback, 'ICAO_Q', 'TZSearch', options);
  } else {
    callback(options);
  }
}
function TimezoneInputSearchSelect(event, ui) {
  event.preventDefault();
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  id = id.replace('_TZSearch', '');
  $('#' + id)
    .val(value)
    .selectmenu('refresh');
  if (id == 'BILLING_FP_EntryTimezone') {
    BILLING_ChangeEntryTimezoneTo(value);
  }
}

function popTimezoneList(input) {
  $(input).empty();
  let TZList = getTimezoneList();
  for (let i in TZList) {
    let tz = TZList[i];
    $(input).append('<option value="' + tz.value + '">' + tz.label + '</option>');
  }
}

function showFailedFDTSyncToast() {
  if (!FDT_SyncFailedShowToast) {
    return;
  }
  const logoutLink = `<a href="#" id="LogoutBTNToast" onClick="hrefHandler.call(this); removeFDTSyncFailedToast();">
      ${iTrans('Click here logout')}</a>`;

  const offlineMessage = `${iTrans('You are offline, please try logging out once back online.')}`;
  let errorMessage = `<div><b>${iTrans('Warning:')}&nbsp</b>${iTrans('FDT Failed to Sync!')}
  ${iTrans('Any input FDT data will not be saved correctly. Logout and back in to resolve the issue.')}&nbsp ${
    ONLINE ? logoutLink : offlineMessage
  }
  </div>`;

  toasterID_SynchronizationError = window.toaster.show(errorMessage, 0);
}

function removeFDTSyncFailedToast() {
  window.toaster.remove(toasterID_SynchronizationError);
}
