/**
 *
 */
class ItineraryTable extends AbstractDatabaseTable {
  constructor() {
    super('Itinerary', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey integer primary key,
            pilot      text,
            OfflinePK  numeric,
            ident      text,
            filed      text,
            searchtime text,
            status     text,
            data       text
        )
    `;
    //#endregion
  }

  async select(whereClause, values, columns = null, orderByClause = '') {
    if (values == 0) {
      columns = ['PrimaryKey', 'status', 'pilot', 'OfflinePK', 'filed', 'ident', 'searchtime'];
    }

    return super.select(whereClause, values, columns, orderByClause);
  }
}
