class vSAMA_TabPolicyObjective extends vSAMA_AbstractTab {
  constructor(_objective, _readOnly) {
    super(_objective);

    super.SetUpGui('<li><a data-oref="pog_name"></a></li>', SAMA.Templates.tpl.objectives.editorTab, _readOnly);

    if (_readOnly) {
      cSAMA_GeneralInputs.FillInputs(this.model, this.dom.combined);
    } else {
      cSAMA_GeneralInputs.LinkInputs(this.model, this.dom.combined, this);
    }

    this.dom.body.enhanceWithin();

    cSAMA_GeneralInputs.Date(this.dom.body.find('input[type="date"]'), true, false);
    this.dom.body.find('.sama-complete-btn').on('click', () => this.OuterCompleteObjective());

    this.dom.taskContainer = this.dom.body.find('.sama-taskContainer');
    this.dom.newTaskButton = this.dom.body.find('.sama-btn-newTaskButton');
    this.dom.newTaskButton.on('click.samaevt', () => this.AddTaskEditor());

    let tasks = _objective.tasks;
    for (let tsk = 0; tsk < tasks.length; tsk++) {
      let taskEdit = new vSAMA_PolicyObjectiveTaskEditor(tasks[tsk]);
      this.Observe(taskEdit, 'Deleted', () => this.RemovedTaskEditor());

      this.dom.taskContainer.append(taskEdit.GetDom());
    }

    this.dom.taskContainer.enhanceWithin();
  }

  /**
   * Button Handler for the Add Custom Reporting Field button in the tabbed editor
   */
  AddTaskEditor() {
    let newTask = new mSAMA_PolicyObjectiveTask(this.model);
    let taskEdit = new vSAMA_PolicyObjectiveTaskEditor(newTask);

    this.Observe(taskEdit, 'Deleted', () => this.RemovedTaskEditor());
    this.dom.taskContainer.append(taskEdit.GetDom().enhanceWithin());

    this.model.SetModified(true);
    this.model.AddTask(newTask);
    this.owner.Refresh();
  }

  RemovedTaskEditor() {
    this.owner.Refresh();
  }

  /**
   * Completes the objective when the user clicks the complete button
   */
  OuterCompleteObjective() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Complete Objective',
      message:
        'Marking this objective as completed will remove it from the spotlight on the Policies and Objectives page.<br/>' +
        'All its uncompleted tasks will be marked as completed.<br/>' +
        'The objective will then be visible in the "Completed Objectives" section. No more memos will be sent out for it.<br/>' +
        'Do you want to continue?',
      yesFunc: () => this.CompleteObjective(),
    });
  }

  /**
   * Removes the objective from the editor and moves it into the card view
   */
  CompleteObjective() {
    // Mark objective as completed
    this.model.SetCompleted();

    // Remove the tab
    this.Destroy();

    // Notify observers about the objective's completion
    this.UpdateObservers('PogCompleted', this.model);
  }

  OnDelete() {
    cSAMA_Dialog.ShowConfirm({
      title: 'Really delete?',
      message:
        'If you delete this objective, it will no longer be shown here or on the "Policies and Objectives" page. ' +
        'There will be no more memos sent out for it.<br/>' +
        'Do you really want to delete the objective?',
      yesFunc: () => {
        SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateDirty);
        super.OnDelete();
      },
    });
  }
}
