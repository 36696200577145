/**
 *
 */
class BillingDataTable extends AbstractDatabaseTable {
  constructor() {
    super('BillingData', 'id');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('Billing.db');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "id"   text primary key,
            "text" text
        )
    `;
    //#endregion
  }

  async insert(key, data) {
    data = this.encodeJson(data);

    return super.insert({ id: key, text: data });
  }

  async select(key) {
    const whereClause = key ? 'id = ?' : '';
    const values = key ? [key] : null;

    const rows = await super.select(whereClause, values, ['text'], 'limit 1');

    if (rows === false || rows.length <= 0) {
      return false;
    }

    if (rows.length > 1) {
      console.warn('BillingDataTable.select() returned more than one row... returning first row only');
    }

    return this.parseJson(rows[0].text);
  }

  /**
   *
   * @param key
   * @returns {Promise<boolean>}
   */
  async delete(key) {
    const whereClause = key ? 'id = ?' : '';
    const values = key ? [key] : null;

    return super.delete(whereClause, values);
  }
}
