/**
 * Helper class for forms integration in itineraries
 */
class FormsInItineraryEditor extends FormBridge {
  constructor(sourcePage, editLevel, htmlContainerId, forms, itineraryId) {
    super(sourcePage, editLevel, htmlContainerId, forms, itineraryId, FormsInItineraryCreator.LINK_TYPE);

    this.picker.AddObserver(null, 'linkFilled', () => this.linkFilledForm());
    this.picker.AddObserver(null, 'linkNew', () => this.linkNewForm());
    this.picker.AddObserver(null, 'viewItem', (formId) => this.viewForm(formId));
    this.picker.AddObserver(null, 'editItem', (formId) => this.editForm(formId));
    this.picker.AddObserver(null, 'unlinkItem', (formId) => this.confirmUnlink(() => this.unlinkForm(formId)));
  }

  static handleReturnToEditPage() {
    verbose.log('Itin', 'Back to itin edit page');
    this.INSTANCE.clear();
    this.INSTANCE.loadLinkedForms().then((loaded) => this.INSTANCE.addLoadedFormsToPicker(loaded));
    goToPage('#Edit_Itin_Page');
    this.returnToPreSwitchPosition();
  }
}

FormsInItineraryEditor.LINK_TYPE = 'itineraries';
FormsInItineraryEditor.PAGE = 'itineraryEditPage';
