var VehicleList = [];
var TrackerItins = [];
var TrackerList = [];
function ExitPersonalItinDetails() {
  $.mobile.changePage($('#PersonalItin_Page'), {
    reverse: false,
    changeHash: false,
  });
}

function InitPersonalItin() {
  $.mobile.changePage($('#PersonalItin_Page'), {
    reverse: false,
    changeHash: false,
  });
  RefreshPersonalItins();
}
function RefreshPersonalItins() {
  $('#PersonalItinStaffList').html(
    "<h3 class='limit-table'>" + iTrans('Refreshing Personal Itineraries List') + '</h3>'
  );
  $.getJSON(BASEURL + 'sync-AJAX.php?action=getStaffList', function (json_data) {
    if (json_data[0].ServerResponse == 'Offline') {
      //lets do nothing here
    } else {
      if (json_data[0].ServerResponse == 'No Staff') {
        STAFF_LIST = [];
      } else {
        STAFF_LIST = json_data;
      }
      localStorageDB.setItem('STAFF_LIST', JSON.stringify(STAFF_LIST), function (e) {
        localStorageDB.getItem('STAFF_LIST', LoadStaffList);
      });
      ShowTrackerList(TRACKER_filter);
    }
  }).fail(function (jqXHR, status, error) {
    localStorageDB.getItem('STAFF_LIST', ShowTrackerList);
  });
  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=getVehicles', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data.ServerResponse == 'No Records') {
      $('#VehicleList').html('');
      $('#VehicleList').html('<h3>' + iTrans('There are no Vehicles in the system') + '</h3>');
    }
    if (json_data.ServerResponse == 'Uncaught Exception') ServerResponse(json_data, 'Init_VehicleAdmin');
    if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    if (json_data.ServerResponse == 'Success') {
      console.log('Received Vehicles');
      VehicleList = json_data.data;
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });

  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=getTrackers', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data.ServerResponse == 'No Records') {
      $('#VehicleList').html('');
      $('#VehicleList').html('<h3>' + iTrans('There are no Trackers in the system') + '</h3>');
    }
    if (json_data.ServerResponse == 'Uncaught Exception') ServerResponse(json_data, 'Init_VehicleAdmin');
    if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    if (json_data.ServerResponse == 'Success') {
      console.log('Received Trackers');
      TrackerList = json_data.data;
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}
var TRACKER_filter = 'all';
function ShowTrackerList(filter) {
  clearMapElapsedTimer();
  clearCountDownTimer();
  clearElapsedTimer();
  document.getElementById('TrackerStatus').style.background = 'rgba(99,99,99,0)';
  document.getElementById('TrackerMoving').style.background = 'rgba(99,99,99,0)';
  document.getElementById('TrackerOpen').style.background = 'rgba(99,99,99,0)';
  document.getElementById('TrackerMaps').style.background = 'rgba(99,99,99,0)';
  $('#TrackerStatusHeader').show();
  $('#TrackerStatusFilters').hide();
  $('#PersonalItinStaffList').show();
  $('#showTrackerMaps').html('');
  TRACKER_filter = filter;
  var html = '';
  let isSuper = window.vueApp.authTools.authIsSuperUser(window.vueApp.groupKey);
  let canUsePersonalItin = window.vueApp.authTools.authHasPermission(
    window.vueApp.groupKey,
    'flights.personalItineraries.use'
  );
  switch (filter) {
    case 'all':
      document.getElementById('TrackerStatus').style.background = 'rgba(99,99,99,0.1)';
      html = '<h3>' + iTrans('All Personnel') + '</h3>';
      break;
    case 'moving':
      document.getElementById('TrackerMoving').style.background = 'rgba(99,99,99,0.1)';
      html = '<h3>' + iTrans('Moving Personnel') + '</h3>';
      break;
    case 'open':
      document.getElementById('TrackerOpen').style.background = 'rgba(99,99,99,0.1)';
      html = '<h3>' + iTrans('Open Personal Itinerary') + '</h3>';
      break;
  }
  html +=
    '<table class="limit-table last-td-fill" data-filter="true"><thead><tr><th>' +
    iTrans('Staff Name') +
    '</th><th>' +
    iTrans('Position Age') +
    '</th><th>' +
    iTrans('Itinerary') +
    '</th><th>' +
    iTrans('Time Left') +
    '</th><th>' +
    iTrans('Res Person') +
    '</th><th>' +
    iTrans('Vehicle') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  var userItem =
    '<table class="limit-table last-td-fill"><thead><tr><th>' +
    iTrans('Staff Name') +
    '</th><th>' +
    iTrans('Position Age') +
    '</th><th>' +
    iTrans('Itinerary') +
    '</th><th>' +
    iTrans('Time Left') +
    '</th><th>' +
    iTrans('Res Person') +
    '</th><th>' +
    iTrans('Vehicle') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    if (filter == 'moving') {
      if (I.PersonalItin == null) continue;
      if (I.PersonalItin.GPS.length == 0) continue;
    }
    if (filter == 'open') {
      if (I.PersonalItin == null) continue;
    }
    var Tstatus = 'No Tracking';
    if (I.PersonalItin != null) {
      if (I.PersonalItin.GPS.length > 0) {
        var gpsTime = I.PersonalItin.GPS[0].dateTime.split(' ');
        gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
        var now = new Date();
        var gpsElapsed = Math.round((now - gpsTime) / 1000);
        var TClass = '';
        if (gpsElapsed < 900) {
          Tstatus = 'Moving';
          TClass = 'greenbg';
        } else {
          Tstatus = 'Stationary';
          if (filter == 'moving') {
            continue;
          }
        }
      } else {
        if (filter == 'moving') {
          continue;
        }
      }
    }

    var message = '';
    if (
      I.Verified == 1 &&
      window.vueApp.authTools.authHasPermission(window.vueApp.groupKey, 'flights.personalItineraries.administrate')
    )
      message =
        '<button data-mini="true" class="action-btn" onClick="SMS_Messenger(\'' +
        I.phone +
        '\')"><img src="./images/mail-black.png" width="70%"></button>';
    var Itin = '<button class="cirro-btn">CLOSED</button>';
    var lineClass = '';
    if (I.PersonalItin != null) {
      switch (I.PersonalItin.status) {
        case 'WARNING':
          Itin =
            '<button class="cirro-btn yellowbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('WARNING') +
            '</button>';
          break;
        case 'ALARM-T1':
          Itin =
            '<button class="cirro-btn redbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('OVERDUE') +
            '-T1</button>';
          break;
        case 'ALARM-T2':
          Itin =
            '<button class="cirro-btn redbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('OVERDUE') +
            '-T2</button>';
          break;
        case 'ALARM-T3':
          Itin =
            '<button class="cirro-btn redbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('OVERDUE') +
            '-T3</button>';
          break;
        case 'SYSOP':
          Itin =
            '<button class="cirro-btn redbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('OVERDUE') +
            '-EMERG</button>';
          break;
        case 'OPEN':
          Itin =
            '<button class="cirro-btn greenbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('OPEN') +
            '</button>';
          break;
        case 'PENDING':
          Itin =
            '<button class="cirro-btn yellowbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('PENDING') +
            '</button>';
          break;
        case 'OFFLINE':
          Itin =
            '<button class="cirro-btn yellowbg" onClick="TIpopup(' +
            i +
            ')">#' +
            I.PersonalItin.PrimaryKey +
            ' ' +
            iTrans('OFFLINE') +
            '</button>';
          break;
      }
    }

    if (I.PersonalItin != null) {
      if (I.PersonalItin.GPS.length > 0) {
        var now = new Date();
        var gpsTime = I.PersonalItin.GPS[0].dateTime.split(' ');
        gpsTime = new Date(gpsTime[0] + 'T' + gpsTime[1] + 'Z');
        var gpsElapsed = Math.round((now - gpsTime) / 1000);
        ElapsedTimer(gpsTime, 'TITime1-' + i);
      }

      var time = I.PersonalItin.timeback.split(' ');
      var timeback = new Date(time[0] + 'T' + time[1] + 'Z');
      CountDownTimer(timeback, 'TITime2-' + i);
      var GPSbtn = '';
      if (I.PersonalItin.GPS.length > 0) {
        var GPSlat = I.PersonalItin.GPS[0].Lat;
        var GPSlong = I.PersonalItin.GPS[0].Long;
        GPSbtn =
          '<button class="action-btn"><a href="http://maps.google.com/maps?saddr=Current+Location&amp;daddr=' +
          GPSlat +
          ',' +
          GPSlong +
          '" data-ajax="false" target="_blank" class="ui-link"><img src="./images/DirectTo.svg"></a></button>';
      }
      var editButton = '';
      if (I.PrimaryKey == USER_RIGHTS.PrimaryKey || canUsePersonalItin)
        editButton =
          '<button data-testid="tracker-personal-itin-edit-' +
          I.chatname +
          '" onClick="editTracker(' +
          i +
          ')" data-mini="true" class="action-btn" ><img src="./images/edit.svg">&nbsp;</button>';
      var buttons = '<div class="vertical-center-container">' + GPSbtn + editButton + message + '</div>';
      var Name = I.chatname;
      var Vehicle = 'N/A';
      if (I.PersonalItin.Vehicle != null)
        Vehicle = I.PersonalItin.Vehicle.Label + ' [' + I.PersonalItin.Vehicle.licenceplate + ']';
      html +=
        '<tr><td>' +
        Name +
        '</td><td><div id="TITime1-' +
        i +
        '">N/A</div></td><td>' +
        Itin +
        '</td><td><div id="TITime2-' +
        i +
        '">N/A</div></td><td>' +
        I.PersonalItin.ResponsiblePerson +
        '</td><td>' +
        Vehicle +
        '</td><td>' +
        buttons +
        '</td></tr>';
      if (I.PrimaryKey == USER_RIGHTS.PrimaryKey) {
        userItem +=
          '<tr><td>' +
          Name +
          '</td><td><div id="TITime1-' +
          i +
          '">N/A</div></td><td>' +
          Itin +
          '</td><td><div id="TITime2p-' +
          i +
          '">N/A</div></td><td>' +
          I.PersonalItin.ResponsiblePerson +
          '</td><td>' +
          Vehicle +
          '</td><td>' +
          buttons +
          '</td></tr>';
        CountDownTimer(timeback, 'TITime2p-' + i);
      }
    } else {
      var buttons = '<div class="vertical-center-container">' + message + '</div>';
      var Name = I.chatname;
      if (I.PrimaryKey == USER_RIGHTS.PrimaryKey || canUsePersonalItin)
        Name =
          '<button class="cirro-btn" data-testid="select-personal-itin-name-' +
          I.chatname +
          '" onClick="CreatePersonalItin(' +
          i +
          ')"><div class="vertical-center-container"><img src="./images/Check_mark_green.svg">' +
          I.chatname +
          '</div></button>';
      html +=
        '<tr><td>' +
        Name +
        '</td><td>N/A</td><td>' +
        Itin +
        '</td><td>N/A</td><td>N/A</td><td>N/A</td><td>' +
        buttons +
        '</td></tr>';
      if (I.PrimaryKey == USER_RIGHTS.PrimaryKey)
        userItem +=
          '<tr><td>' +
          Name +
          '</td><td>N/A</td><td>' +
          Itin +
          '</td><td>N/A</td><td>N/A</td><td>N/A</td><td>' +
          buttons +
          '</td></tr>';
    }
  }
  html += '</tbody></table>';
  userItem += '</tbody></table>';
  if (filter == 'all') html = userItem + html;
  $('#PersonalItinStaffList').html(html);
  $('#PersonalItinStaffList').enhanceWithin();
}

function TRACKER_showMaps() {
  document.getElementById('TrackerStatus').style.background = 'rgba(99,99,99,0)';
  document.getElementById('TrackerMoving').style.background = 'rgba(99,99,99,0)';
  document.getElementById('TrackerOpen').style.background = 'rgba(99,99,99,0)';
  document.getElementById('TrackerMaps').style.background = 'rgba(99,99,99,0.1)';
  $('#TrackerStatusHeader').hide();
  $('#TrackerStatusFilters').show();
  $('#PersonalItinStaffList').hide();
  var Data = STAFF_LIST;
  var HeadingColour = LOCALSTORAGE.GPSHEADINGCOLOUR;
  if (HeadingColour != undefined) MAPSTATE.HeadingColour = HeadingColour;
  var baseType = 'Satellite';
  if (LOCALSTORAGE.MapType != undefined) {
    baseType = LOCALSTORAGE.MapType;
    MAPSTATE.Type = baseType;
  }
  mapboxgl.accessToken = MAPBOX_TOKEN;
  /*
   var double = JSON.parse(JSON.stringify(Data));
   for(var i in double){
   Data.push(double[i]);
   }
   */
  var count = 0;
  var F = $('#TrackerStatus_Map_Filter').val();
  for (var i in Data) {
    var I = JSON.parse(JSON.stringify(Data[i].PersonalItin));
    if (I == null) continue;
    if (I.GPS == null) continue;
    if (I.GPS.length == 0) continue;
    I.GPS = I.GPS[0];
    if (F == 'moving') {
      if (checkAirbornStatus(I.GPS.dateTime) == 'Landed') continue;
    }
    if (F == 'itinerary' && I.Itin == null) continue;
    if (
      (F == 'moving_itinerary' && I.Itin == null) ||
      (F == 'moving_itinerary' && checkAirbornStatus(I.GPS.dateTime) == 'Landed')
    )
      continue;
    count++;
    if (count < 15) MakeFollowMap(i, I, 'showTrackerMaps');
  }
  if (count > 14) {
    alert(iTrans('Over Maximum of 14 aircraft, showing first 14 only'));
  }
  if (count == 0) {
    $('#showTrackerMaps').html('<h3>' + iTrans('No Active Positions For Personal Itinerary') + '</h3>');
  }
}

function getTrackingInfoPopup(e, mapb) {
  var features = mapb.queryRenderedFeatures(e.point, {
    layers: ['AIRCRAFT'],
  });
  if (!features.length) {
    return;
  }
  var O = features[0].properties;
  TIpopup(O.Index);
}

function TIpopup(index) {
  //popup personal itin info

  var spot = STAFF_LIST[index];
  console.log(spot);

  var Itin = spot.PersonalItin;
  var Name = spot.chatname;

  var text = spot.messageContent;
  clearMapElapsedTimer();
  clearCountDownTimer();
  clearElapsedTimer();
  $('#acDetails').html('');
  var html = '<h4>' + Name + '</h4>';

  if (Itin.GPS.length > 0) {
    var Lat = Itin.GPS[0].Lat;
    var Long = Itin.GPS[0].Long;
    var timestamp = Itin.GPS[0].dateTime;
    html += "<table style='min-width:250px !important;'>";
    html += '<tr><td>Date:</td><td>' + timestamp + '</td></tr>';
    html += '<tr><td>Lat:</td><td>' + ConvertDD_User(Lat, 'Lat') + '</td></tr>';
    html += '<tr><td>Long:</td><td>' + ConvertDD_User(Long, 'Long') + '</td></tr>';
    html += '<tr><td>Message:</td><td>' + Itin.GPS[0].telemetry + '</td></tr>';
    html += '</table><br />';
    html +=
      '<img src="./images/DirectTo.png" width="35" height="35" onClick="navigateToMarker(\'' +
      Name +
      "'," +
      Lat +
      ',' +
      Long +
      ", 'Spot')\"/>";
    if (RoutePlanningON != false)
      html +=
        '<img src="./images/routeplan.png" width="35" height="35" onClick="routeToMarker(\'' +
        Name +
        "'," +
        Lat +
        ',' +
        Long +
        ')"/>';
  }

  html += '<h4>' + iTrans('Itinerary Status') + '</h4>';
  html += "<table style='min-width:250px !important;'>";
  html += '<tr><td>User:</td><td>' + spot.chatname + '</td></tr>';
  html += '<tr><td>Filed:</td><td>' + Itin.filed + '</td></tr>';
  html += '<tr><td>Timeback:</td><td>' + Itin.timeback + '</td></tr>';
  html += '<tr><td>Status:</td><td>' + Itin.status + '</td></tr>';
  html += '<tr><td>Resp Person:</td><td>' + Itin.ResponsiblePerson + '</td></tr>';
  html += '<tr><td>Details:</td><td>' + Itin.Details + '</td></tr>';

  CurMarkerProperties = { NavType: 'Waypoint' };

  $('#TrackeritinDetails').html(html);
  $('#TrackeritinDetails').enhanceWithin();
  $('#TrackeritinInfo').popup('open');
}

function editTracker(index) {
  $.mobile.changePage($('#PersonalItinDetails_Page'), {
    reverse: false,
    changeHash: false,
  });
  editTracker;
  var I = STAFF_LIST[index];
  var html = '<h3>' + I.chatname + ' ' + iTrans('Itinerary Details') + '</h3>';
  html += '<input type="hidden" id="Tracker_ItinPrimaryKey" value="' + I.PersonalItin.PrimaryKey + '" />';
  html +=
    '<table class="limit-table"><tbody><tr><td>' +
    '<label for="Tracker_returnDate"><b>' +
    iTrans('ETA Date') +
    '</b></label>' +
    '<input name="Tracker_return Date" id="Tracker_returnDate" type="text" onfocus="blur();" data-mini="true">' +
    '</td><td>' +
    ' <label for="Tracker_returnTime"><b>' +
    iTrans('ETA Time') +
    '(' +
    CurrentTimeZone +
    ')</b></label>' +
    '<input name="return Time" type="text" id="Tracker_returnTime" onchange="validateTime(this.value,this.id,true);Tracker_timeChanged()" data-mini="true">' +
    '</td></tr></tbody></table>';

  html +=
    '<table class="limit-table"><tbody><tr><td>' +
    '<label for="Tracker_ResponsiblePerson"><b>' +
    iTrans('Responsible Person') +
    '</b></label>' +
    '<select id="Tracker_ResponsiblePerson" data-mini="true"></select>' +
    '</td><td>' +
    ' <label for="Tracker_ResponsiblePersonSearch"><b>' +
    iTrans('Search Responsible Person') +
    '</b></label>' +
    '<input type="text" id="Tracker_ResponsiblePersonSearch" data-mini="true">' +
    '</td></tr></tbody></table>';

  html +=
    '<label for="Tracker_Details">' +
    iTrans('Details') +
    '</label><textarea id="Tracker_Details">' +
    escapeHtml(I.PersonalItin.Details) +
    '</textarea>';

  html +=
    '<button onClick="UpdatePersonalItin()" data-mini="true" id="UpdatePersonalItinBTN" class="greenbg" data-icon="check" >' +
    iTrans('Update Personal Itinerary') +
    '</button>';

  html +=
    '<button data-testid="tracker-personal-itin-close-button" onClick="ClosePersonalItin()" data-mini="true" id="ClosePersonalItinBTN" class="redbg" data-icon="delete" >' +
    iTrans('Close / Action Personal Itinerary') +
    '</button>';

  $('#PersonalItinDetails').html(html);
  $('#PersonalItinDetails').enhanceWithin();
  initTrackerRP(I.PersonalItin.ResponsablePK);
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  var time = I.PersonalItin.timeback.split(' ');
  var timeback = new Date(time[0] + 'T' + time[1] + 'Z');
  Tracker_returnDatePicker = new Pikaday({
    field: $('#Tracker_returnDate')[0],
    firstDay: 1,
    minDate: new Date(),
    maxDate: maxDate,
    yearRange: [2014, 2114],
    defaultDate: timeback,
    setDefaultDate: true,
    onSelect: Tracker_dateChanged,
  });

  Tracker_dateChanged();
  var type = LOCALSTORAGE.TimeInput;
  if (type == undefined) type = 'Clockpicker';

  if (type == 'Clockpicker') {
    $('#Tracker_returnTime').clockpicker({
      autoclose: true,
      align: 'right',
    });
  }
  if (type == 'Keyboard') {
    $('#Tracker_returnTime').focus(ClockPickerFocus);
  }

  if (type == 'Keypad') {
    $.fn.numpad.defaults.gridTpl = '<table class="numpad"></table>';
    $.fn.numpad.defaults.backgroundTpl = '<div class="ui-popup-screen ui-overlay-a"></div>';
    $.fn.numpad.defaults.displayTpl = '<input data-theme="b" type="text"/>';
    $.fn.numpad.defaults.buttonNumberTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.buttonFunctionTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.onKeypadCreate = function () {
      $(this).enhanceWithin();
    };
    $('#Tracker_returnTime').numpad({
      hidePlusMinusButton: true,
      hideDecimalButton: true,
    });
  }

  var returnHours = timeback.getHours();
  var returnMinutes = timeback.getMinutes();
  if (returnHours < 10) returnHours = '0' + returnHours;
  if (returnMinutes < 10) returnMinutes = '0' + returnMinutes;
  returnHours = returnHours.toString();
  returnMinutes = returnMinutes.toString();
  $('#Tracker_returnTime').val(returnHours + returnMinutes);
}

function initTrackerRP(curPK = 0) {
  $('#Tracker_ResponsiblePerson').empty();
  $('#Tracker_ResponsiblePerson').append($('<option />').val('0').text(iTrans('System Tier 1 Users')));
  for (var r in STAFF_LIST) {
    $('#Tracker_ResponsiblePerson').append($('<option />').val(STAFF_LIST[r].PrimaryKey).text(STAFF_LIST[r].chatname));
  }
  $('#Tracker_ResponsiblePerson').val(curPK).selectmenu('refresh');
  $('#Tracker_ResponsiblePersonSearch').autocomplete({
    select: trackerResponsiblePersonSelect,
    source: searchTrackerResponsiblePerson,
  });
}

function searchTrackerResponsiblePerson(term, callback) {
  var text = term.term;
  var Users = FilterTracker_ResponsiblePerson(STAFF_LIST, text, callback);
}
function FilterTracker_ResponsiblePerson(OrigArray, keywords, callback) {
  var userFiltered = OrigArray;
  userFiltered = OrigArray.filter(function (item) {
    if (item.chatname.toLowerCase().indexOf(keywords.toLowerCase()) > -1) return true;
    else return false;
  });
  var options = [];
  for (var i in userFiltered) {
    var I = userFiltered[i];
    options.push({
      label: '[' + escapeHtml(I.staffType) + '] - ' + escapeHtml(I.chatname),
      value: I.PrimaryKey,
    });
  }
  callback(options);
}
function trackerResponsiblePersonSelect(event, ui) {
  $('#Tracker_ResponsiblePerson').val(ui.item.value).selectmenu('refresh');
  setTimeout(function () {
    $('#Tracker_ResponsiblePersonSearch').val('');
    $('#Tracker_ResponsiblePersonSearch').blur();
  }, 500);
}

function CreatePersonalItin(index) {
  $.mobile.changePage($('#PersonalItinDetails_Page'), {
    reverse: false,
    changeHash: false,
  });
  var I = STAFF_LIST[index];
  var html = '<h3>' + I.chatname + ' ' + iTrans('Itinerary Details') + '</h3>';
  html += '<input type="hidden" id="Tracker_User_PK" value="' + I.PrimaryKey + '" />';

  html +=
    '<table class="limit-table"><tbody><tr><td>' +
    '<label for="Tracker_returnDate"><b>' +
    iTrans('ETA Date') +
    '</b></label>' +
    '<input name="Tracker_return Date" id="Tracker_returnDate" type="text" onfocus="blur();" data-mini="true">' +
    '</td><td>' +
    ' <label for="Tracker_returnTime"><b>' +
    iTrans('ETA Time') +
    '(' +
    CurrentTimeZone +
    ')</b></label>' +
    '<input name="return Time" type="text" id="Tracker_returnTime" data-testid="tracker-personal-itin-return-time" onchange="validateTime(this.value,this.id,true);Tracker_timeChanged()" data-mini="true">' +
    '</td></tr></tbody></table>';

  html +=
    '<table class="limit-table"><tbody><tr><td>' +
    '<label for="Tracker_ResponsiblePerson"><b>' +
    iTrans('Responsible Person') +
    '</b></label>' +
    '<select id="Tracker_ResponsiblePerson" data-mini="true"></select>' +
    '</td><td>' +
    ' <label for="Tracker_ResponsiblePersonSearch"><b>' +
    iTrans('Search Responsible Person') +
    '</b></label>' +
    '<input data-testid="tracker-personal-itin-new-responsible-person" type="text" id="Tracker_ResponsiblePersonSearch" data-mini="true">' +
    '</td></tr></tbody></table>';

  html +=
    '<label for="Tracker_Details">' +
    iTrans('Details') +
    '</label><textarea data-testid="tracker-personal-itin-details" id="Tracker_Details"></textarea>';

  html +=
    '<label for="Tracker_Select">' +
    iTrans('Select GPS Tracker') +
    '</label><select id="Tracker_Select" data-mini="true">';
  html += '<option value="None">N/A</option>';
  for (var i in TrackerList) {
    var I = TrackerList[i];
    html += '<option value="' + I.PrimaryKey + '">' + I.Name + '</option>';
  }
  html += '</select>';

  html +=
    '<label for="Tracker_Vehicle">' +
    iTrans('Select Vehicle') +
    '</label><select id="Tracker_Vehicle" data-mini="true" onChange="showVehicleStatus(this);">';
  html += '<option value="0">N/A</option>';
  for (var i in VehicleList) {
    var I = VehicleList[i];
    html += '<option value="' + I.PrimaryKey + '">' + I.Label + ' [' + I.licenceplate + ']</option>';
  }
  html += '</select><div id="Tracker_Vehicle_Status"></div>';

  var Ccount = 0;
  if (LOCALSTORAGE.itinClients != undefined) {
    var itinClients = LOCALSTORAGE.itinClients;
    var options = '';
    for (var i in itinClients) {
      if (itinClients[i].Enabled == 0) continue;
      Ccount++;
      options += '<option value="' + itinClients[i].ClientPK + '">' + itinClients[i].Client + '</option>';
    }
  }
  if (Ccount > 0) {
    html +=
      '<label for="Tracker_Client">' +
      iTrans('Client Data Feed') +
      '</label><select id="Tracker_Client" data-mini="true" >';

    html += '<option value="0">' + iTrans('Not Enabled') + '</option>';
    html += options;
    html += '</select>';
  } else {
    html += '<input type="hidden" value="0" id="Tracker_Client" />';
  }

  html +=
    '<button onClick="FilePersonalItin()" data-mini="true" data-testid="tracker-personal-itin-file-new" id="FilePersonalItinBTN" class="greenbg" data-icon="check" disabled>' +
    iTrans('File Personal Itinerary') +
    '</button>';

  $('#PersonalItinDetails').html(html);
  $('#PersonalItinDetails').enhanceWithin();
  initTrackerRP(0);
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  Tracker_returnDatePicker = new Pikaday({
    field: $('#Tracker_returnDate')[0],
    firstDay: 1,
    minDate: new Date(),
    maxDate: maxDate,
    yearRange: [2014, 2114],
    defaultDate: new Date(),
    setDefaultDate: true,
    onSelect: Tracker_dateChanged,
  });

  Tracker_dateChanged();
  var type = LOCALSTORAGE.TimeInput;
  if (type == undefined) type = 'Clockpicker';

  if (type == 'Clockpicker') {
    $('#Tracker_returnTime').clockpicker({
      autoclose: true,
      align: 'right',
    });
  }
  if (type == 'Keyboard') {
    $('#Tracker_returnTime').focus(ClockPickerFocus);
  }

  if (type == 'Keypad') {
    $.fn.numpad.defaults.gridTpl = '<table class="numpad"></table>';
    $.fn.numpad.defaults.backgroundTpl = '<div class="ui-popup-screen ui-overlay-a"></div>';
    $.fn.numpad.defaults.displayTpl = '<input data-theme="b" type="text"/>';
    $.fn.numpad.defaults.buttonNumberTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.buttonFunctionTpl = '<button data-role="button" data-theme="a"></button>';
    $.fn.numpad.defaults.onKeypadCreate = function () {
      $(this).enhanceWithin();
    };
    $('#Tracker_returnTime').numpad({
      hidePlusMinusButton: true,
      hideDecimalButton: true,
    });
  }
}

function showVehicleStatus(e) {
  if (e.value == '0') {
    $('#Tracker_Vehicle_Status').html('');
    return;
  }
  var I = '';
  for (var i in VehicleList) {
    I = VehicleList[i];
    if (I.PrimaryKey == e.value) break;
  }
  if (I != '') {
    var html = '<h3>' + iTrans('Vehicle Status') + '</h3>';
    html += 'Serviceable: ' + (I.Serviceable == 1 ? 'Yes' : 'No') + '<br />';
    html == 'Service Note: ' + I.ServiceReason + '<br />';
    html += 'Odometer: ' + I.odometer + '<br />';
    html += 'Service Due at: ' + I.odometer_Service + '<br />';
    html += 'Calendar Inspection Due: ' + I.inspection_Due + '<br /><br />';
    $('#Tracker_Vehicle_Status').html(html);
  } else {
    $('#Tracker_Vehicle_Status').html('');
  }
}

var Tracker_returnDatePicker;
function Tracker_timeChanged() {
  //alert("timeChanged");
  var Diff = time_Diff(minTime, $('#Tracker_returnTime').val());
  //console.log("Diff: " + Diff);
  //console.log("Mintime: " + minTime);
  if (Diff < 0) {
    $('#Tracker_returnTime').val(minTime);
    $('#Tracker_returnTime').focus();
    $('#FilePersonalItinBTN').prop('disabled', false);
    return;
  }
  $('#FilePersonalItinBTN').prop('disabled', false);
}
function Tracker_dateChanged() {
  var dateObj = new Date();
  var timeHours = dateObj.getHours();
  var timeMinutes = dateObj.getMinutes();
  if (timeHours < 10) timeHours = '0' + timeHours;
  if (timeMinutes < 10) timeMinutes = '0' + timeMinutes;
  timeHours = timeHours.toString();
  timeMinutes = timeMinutes.toString();
  var todaytime = timeHours + timeMinutes;
  //var selectedDate = $('#returnDate').val();
  var selectedDate = Tracker_returnDatePicker.getDate();
  //console.log(selectedDate.getDate());
  //console.log(dateObj.getDate());
  if (
    selectedDate.getMonth() > dateObj.getMonth() ||
    (selectedDate.getMonth() == dateObj.getMonth() && selectedDate.getDate() > dateObj.getDate())
  ) {
    minTime = '0000';
  } else {
    var returnTime = $('#Tracker_returnTime').val();
    if (returnTime !== '') {
      //only change time if returnTime is less than todaytime
      var Diff = time_Diff(todaytime, $('#Tracker_returnTime').val());
      if (Diff < 0) {
        $('#Tracker_returnTime').val(todaytime);
        $('#Tracker_returnTime').focus();
        return;
      }
    }
    minTime = todaytime;
  }
}

function ClosePersonalItin() {
  $('#ClosePersonalItinBTN').prop('disabled', true);
  $('#UpdatePersonalItinBTN').prop('disabled', true);
  var itinData = {
    PrimaryKey: $('#Tracker_ItinPrimaryKey').val(),
  };
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Tracker-AJAX.php?action=closeItinerary',
    timeout: 120000,
    data: itinData,
  })
    .success(function (data) {
      $('#ClosePersonalItinBTN').prop('disabled', false);
      $('#UpdatePersonalItinBTN').prop('disabled', false);
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      //console.log("ServerResponse: check");
      //console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data.ServerResponse == 'Uncaught Exception') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem closing your itinerary') + '.  Error: ' + data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      }
      if (data.ServerResponse == 'Success') {
        if (data.data == 'Itinerary Actioned') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt: iTrans('The Overdue Itinerary has been successfully Actioned.'),
            buttons: {
              [iTrans('Main Menu')]: {
                click: function () {
                  cirroHOME();
                },
              },
            },
          });
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt: iTrans('The Itinerary has been successfully closed.'),
            buttons: {
              [iTrans('Main Menu')]: {
                click: function () {
                  cirroHOME();
                },
              },
            },
            callbackOpen: function () {
              setTimeout(function () {
                $('a.ui-btn:contains("Main Menu")').attr(
                  'data-testid',
                  'tracker-personal-itin-confirmation-dialog-main-menu-button'
                );
                $('.ui-title:contains("Success")').attr(
                  'data-testid',
                  'tracker-personal-itin-confirmation-dialog-sucess-message-title'
                );
              }, 100);
            },
          });
        }
      }
      if (data.ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem closing your itinerary') + '.  Error: ' + data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('#ClosePersonalItinBTN').prop('disabled', false);
      $('#UpdatePersonalItinBTN').prop('disabled', false);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt:
          iTrans('There was a network problem closing your itinerary') +
          '.  ' +
          iTrans('Please try again') +
          '. ' +
          iTrans('If this continues to happen please submit a bug report') +
          '.',
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    });
}

function UpdatePersonalItin() {
  $('#UpdatePersonalItinBTN').prop('disabled', true);
  $('#ClosePersonalItinBTN').prop('disabled', true);
  var ReturnDate = Tracker_returnDatePicker.getDate();
  var ReturnTime = $('#Tracker_returnTime').val();
  var timeback = null; // create a timeback datetime string from returndate and returntime
  //console.log(ReturnDate);
  var ReturnMonth = ReturnDate.getMonth() + 1;
  if (ReturnMonth < 10) ReturnMonth = '0' + ReturnMonth;
  var ReturnDay = ReturnDate.getDate();
  if (ReturnDay < 10) ReturnDay = '0' + ReturnDay;
  var ReturnHour = ReturnTime.substr(0, 2);
  var ReturnMin = ReturnTime.substr(2, 2);
  var timeback =
    ReturnDate.getFullYear() + '-' + ReturnMonth + '-' + ReturnDay + ' ' + ReturnHour + ':' + ReturnMin + ':00';
  var now = new Date();
  var timezone = now.getTimezoneOffset(); //returns time in minutes ie: GMT+2,  -120
  var itinData = {
    timeback: timeback,
    Details: $('#Tracker_Details').val(),
    timezone: timezone,
    PrimaryKey: $('#Tracker_ItinPrimaryKey').val(),
    ResponsablePK: $('#Tracker_ResponsiblePerson').val(),
  };

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Tracker-AJAX.php?action=updateItinerary',
    timeout: 120000,
    data: itinData,
  })
    .success(function (data) {
      $('#UpdatePersonalItinBTN').prop('disabled', false);
      $('#ClosePersonalItinBTN').prop('disabled', false);
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      //console.log("ServerResponse: check");
      //console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data.ServerResponse == 'Uncaught Exception') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem updating your itinerary') + '.  Error: ' + data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      }
      if (data.ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('The Itinerary has been successfully updated.'),
          buttons: {
            [iTrans('Main Menu')]: {
              click: function () {
                cirroHOME();
              },
            },
          },
        });
      }
      if (data.ServerResponse == 'Failed') {
        if (data.data == 'Invalid Timeback') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Error'),
            headerClose: false,
            buttonPrompt: iTrans('ETA selected has already expired!'),
            buttons: {
              [iTrans('Main Menu')]: {
                click: function () {},
              },
            },
          });
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Failed!'),
            headerClose: false,
            buttonPrompt: iTrans('There was a problem updating your itinerary') + '.  Error: ' + data.data,
            buttons: {
              [iTrans('Close')]: {
                click: function () {},
              },
            },
          });
        }
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('#UpdatePersonalItinBTN').prop('disabled', false);
      $('#ClosePersonalItinBTN').prop('disabled', false);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt:
          iTrans('There was a network problem Updating your itinerary') +
          '.  ' +
          iTrans('Please try again') +
          '. ' +
          iTrans('If this continues to happen please submit a bug report') +
          '.',
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    });
}

function FilePersonalItin() {
  $('#FilePersonalItinBTN').prop('disabled', true);

  var ReturnDate = Tracker_returnDatePicker.getDate();
  var ReturnTime = $('#Tracker_returnTime').val();
  var timeback = null; // create a timeback datetime string from returndate and returntime
  //console.log(ReturnDate);
  var ReturnMonth = ReturnDate.getMonth() + 1;
  if (ReturnMonth < 10) ReturnMonth = '0' + ReturnMonth;
  var ReturnDay = ReturnDate.getDate();
  if (ReturnDay < 10) ReturnDay = '0' + ReturnDay;
  var ReturnHour = ReturnTime.substr(0, 2);
  var ReturnMin = ReturnTime.substr(2, 2);
  var timeback =
    ReturnDate.getFullYear() + '-' + ReturnMonth + '-' + ReturnDay + ' ' + ReturnHour + ':' + ReturnMin + ':00';
  var now = new Date();
  var timezone = now.getTimezoneOffset(); //returns time in minutes ie: GMT+2,  -120
  var itinData = {
    timeback: timeback,
    Details: $('#Tracker_Details').val(),
    Tracker_PK: $('#Tracker_Select').val(),
    Vehicle_PK: $('#Tracker_Vehicle').val(),
    Client_PK: $('#Tracker_Client').val(),
    User_PK: $('#Tracker_User_PK').val(),
    ResponsablePK: $('#Tracker_ResponsiblePerson').val(),
    timezone: timezone,
  };

  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Tracker-AJAX.php?action=fileItinerary',
    timeout: 120000,
    data: itinData,
  })
    .success(function (data) {
      $('#FilePersonalItinBTN').prop('disabled', false);
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      //console.log("ServerResponse: check");
      //console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data.ServerResponse == 'Uncaught Exception') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem filing your itinerary') + '.  Error: ' + data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      }
      if (data.ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('The Itinerary has been successfully filed.'),
          buttons: {
            [iTrans('Main Menu')]: {
              click: function () {
                cirroHOME();
              },
            },
          },
          callbackOpen: function () {
            setTimeout(function () {
              $('a.ui-btn:contains("Main Menu")').attr(
                'data-testid',
                'tracker-personal-itin-confirmation-dialog-main-menu-button'
              );
              $('.ui-title:contains("Success")').attr(
                'data-testid',
                'tracker-personal-itin-confirmation-dialog-sucess-message-title'
              );
            }, 100);
          },
        });
      }
      if (data.ServerResponse == 'Failed') {
        if (data.data == 'Already Open') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Error'),
            headerClose: false,
            buttonPrompt: iTrans('There is already an open itinerary for this person.'),
            buttons: {
              [iTrans('Main Menu')]: {
                click: function () {},
              },
            },
          });
        }
        if (data.data == 'Invalid Timeback') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Error'),
            headerClose: false,
            buttonPrompt: iTrans('ETA selected has already expired!'),
            buttons: {
              [iTrans('Main Menu')]: {
                click: function () {},
              },
            },
          });
        }
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans('There was a problem filing your itinerary') + '.  Error: ' + data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      }
    })
    .fail(function (data) {
      //data.status, data.readyState, data.responseText
      //console.log(data);
      $('#FilePersonalItinBTN').prop('disabled', false);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt:
          iTrans('There was a network problem filing your itinerary') +
          '.  ' +
          iTrans('Please try again') +
          '. ' +
          iTrans('If this continues to happen please submit a bug report') +
          '.',
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    });
}

/********************************************* VEHICLE STUFF **********************************/
function ExitVehicleAdmin() {
  $.mobile.changePage($('#Administration_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function ExitEditVehicle() {
  $.mobile.changePage($('#Vehicleadmin_Page'), {
    reverse: true,
    changeHash: false,
  });
}

function Init_VehicleAdmin() {
  $.mobile.changePage($('#Vehicleadmin_Page'), {
    changeHash: false,
  });
  $('#VehicleList').html("<h3 class='limit-table'>" + iTrans('Getting Vehicle List') + '</h3>');

  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=getVehicles', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data.ServerResponse == 'No Records') {
      $('#VehicleList').html('');
      $('#VehicleList').html('<h3>' + iTrans('There are no Vehicles in the system') + '</h3>');
    }
    if (json_data.ServerResponse == 'Uncaught Exception') ServerResponse(json_data, 'Init_VehicleAdmin');
    if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    if (json_data.ServerResponse == 'Success') {
      console.log('Received Vehicles');
      VehicleList = json_data.data;
      displayVehicles(VehicleList);
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function displayVehicles(data) {
  if (data.length == 0) {
    $('#VehicleList').html('<h3>' + iTrans('No Vehicles') + '</h3>');
    return;
  } else {
    $('#VehicleList').html('');
    var html =
      '<h2 class="limit-table">' +
      iTrans('Vehicles') +
      '</h2><div style="width: 100%; overflow: auto"><table id="FCList_Drum" data-filter="true" class="limit-table second-td-fill" >' +
      '<thead>' +
      '<tr>' +
      '<th>' +
      iTrans('Unique ID') +
      '</th><th>' +
      iTrans('Label') +
      '</th><th>' +
      iTrans('License Plate') +
      '</th><th>' +
      iTrans('Serviceable') +
      '</th><th>' +
      iTrans('Last Updated') +
      '</th><th>' +
      iTrans('Updated By') +
      '</th><th>Action</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    for (var i in data) {
      var I = data[i];
      var buttons =
        "<div class='vertical-center-container'><button onClick='EditVehicleIndex(" +
        i +
        ");' name='" +
        i +
        "' class='action-btn' data-mini='true'><img src='./images/edit.svg' /></button><button onClick='deleteVehicle(" +
        I.PrimaryKey +
        ");' name='" +
        i +
        "' PrimaryKey = 'test'  class='action-btn' data-mini='true'><img src='./images/delete.svg' /></button></div>";
      html +=
        '<tr><td>' +
        I.PrimaryKey +
        '</td><td>' +
        I.Label +
        '</td><td>' +
        I.licenceplate +
        '</td><td>' +
        (I.Serviceable == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        I.modified +
        '</td><td>' +
        I.UpdatedBy +
        '</td><td>' +
        buttons +
        '</td></tr>';
    }
    html += '</tbody></table>';
    $('#VehicleList').html(html);
    $('#VehicleList').enhanceWithin();
    AddTableSorter('VehicleList');
  }
}

function EditVehicleIndex(index) {
  $.mobile.changePage($('#Edit_Vehicle_Page'), {
    reverse: true,
    changeHash: false,
  });
  EditVehicle(VehicleList[index]);
}

function NewVehicle() {
  $.mobile.changePage($('#Edit_Vehicle_Page'), {
    reverse: true,
    changeHash: false,
  });
  var date = new Date();

  var Item = {
    PrimaryKey: 'NEW',
    BASED_IN: '',
    licenceplate: '',
    Label: '',
    description: '',
    SN: '',
    odometer: 0,
    odometer_Service: 0,
    modified: '2020-01-01 00:00:00',
    Units: 'Metric',
    FuelType: 'Unleaded',
    fuelRemain: 0,
    esn: '',
    satphone: '',
    inspection_Due: date.getFullYear() + 1 + '-01-01',
    GrossWeight: 0,
    TowWeight: 0,
    hourly_rate_cents: 0,
    mileage_rate_cents: 0,
    daily_rate_cents: 0,
    rate_currency: 'CAD',
    speed_Alert: 0,
    noItinAlert: 1,
    SilenceAlerts: 0,
    Serviceable: 1,
    ServiceReason: '',
    Tracker_Type: '',
    Dynamic_Tracker: 0,
    showOnMap: 0,
    UpdatedBy: chatname,
    DELETED: 0,
  };
  if (COUNTRY != 'CANADA') Item.rate_currency = 'USD';
  EditVehicle(Item);
}

function EditVehicle(Item) {
  var html = '';

  html +=
    '<label for="TV-PrimaryKey">' +
    iTrans('Unique ID') +
    '</label><input type="text" id="TV-PrimaryKey" value="' +
    escapeHtml(Item.PrimaryKey) +
    '" disabled/>';
  html +=
    '<label for="TV-modified">' +
    iTrans('Modified') +
    '</label><input type="text" id="TV-modified" value="' +
    escapeHtml(Item.modified) +
    '" disabled/>';
  html +=
    '<label for="TV-UpdatedBy">' +
    iTrans('Updated By') +
    '</label><input type="text" id="TV-UpdatedBy" value="' +
    escapeHtml(Item.UpdatedBy) +
    '" disabled/>';
  html +=
    '<label for="TV-Label">' +
    iTrans('Label') +
    '</label><input type="text" id="TV-Label" value="' +
    escapeHtml(Item.Label) +
    '"/>';
  html +=
    '<label for="TV-description">' +
    iTrans('Description') +
    '</label><input type="text" id="TV-description" value="' +
    escapeHtml(Item.description) +
    '"/>';

  html += '<div id="vehicleDepartmentAssociations"></div>';

  html += '<label for="TV-BASED_IN">' + iTrans('Base') + '</label><select id="TV-BASED_IN" >';
  html += '<option value="">' + iTrans('N/A') + '</option>';
  for (var i in GROUP_DATA.Bases) {
    html +=
      '<option value="' +
      escapeHtml(GROUP_DATA.Bases[i].name) +
      '">' +
      escapeHtml(GROUP_DATA.Bases[i].name) +
      '</option>';
  }
  html += '</select>';
  html +=
    '<label for="TV-licenceplate">' +
    iTrans('Licence Plate') +
    '</label><input type="text" id="TV-licenceplate" value="' +
    escapeHtml(Item.licenceplate) +
    '"/>';

  html +=
    '<label for="TV-SN">' +
    iTrans('SN') +
    '</label><input type="text" id="TV-SN" value="' +
    escapeHtml(Item.SN) +
    '"/>';
  html +=
    '<label for="TV-odometer">' +
    iTrans('Odometer') +
    '</label><input type="number" id="TV-odometer" value="' +
    escapeHtml(Item.odometer) +
    '"/>';
  html +=
    '<label for="TV-odometer_Service">' +
    iTrans('Odometer Next Service') +
    '</label><input type="number" id="TV-odometer_Service" value="' +
    escapeHtml(Item.odometer_Service) +
    '"/>';

  html += '<label for="TV-FuelType">' + iTrans('FuelType') + '</label><select id="TV-FuelType">';
  html += '<option value="Unleaded">Unleaded</option>';
  html += '<option value="Diesel">Diesel</option>';
  html += '<option value="Electric">Electric</option>';
  html += '</select>';
  html += '<label for="TV-fuelRemain">' + iTrans('% Fuel Remaining') + '</label>';
  html += '<input type="number" max="100" min="0" id="TV-fuelRemain" value="' + Item.fuelRemain + '"/>';
  html +=
    '<label for="TV-satphone">' +
    iTrans('Satphone Number') +
    '</label><input type="text" id="TV-satphone" value="' +
    escapeHtml(Item.satphone) +
    '"/>';
  html +=
    '<label for="TV-inspection_Due">' +
    iTrans('Inspection Due Date') +
    '</label><input type="date" id="TV-inspection_Due" value="' +
    escapeHtml(Item.inspection_Due) +
    '"/>';
  html +=
    '<label for="TV-GrossWeight">' +
    iTrans('Gross Weight') +
    '</label><input type="number" id="TV-GrossWeight" value="' +
    escapeHtml(Item.GrossWeight) +
    '"/>';
  html +=
    '<label for="TV-TowWeight">' +
    iTrans('Towing Weight') +
    '</label><input type="number" id="TV-TowWeight" value="' +
    escapeHtml(Item.TowWeight) +
    '"/>';

  html += '<h3>' + iTrans('Billing Rates') + '</h3>';
  html += '<label for="TV-rate_currency">' + iTrans('Rate Currency') + '</label><select id="TV-rate_currency" >';
  html += '<option value="CAD">CAD</option>';
  html += '<option value="USD">USD</option>';
  html += '<option value="AUD">AUD</option>';
  html += '<option value="CLP">CLP</option>';
  html += '</select>';
  html +=
    '<label for="TV-hourly_rate_cents">' +
    iTrans('Hourly Rate') +
    '</label><input type="number" id="TV-hourly_rate_cents" value="' +
    escapeHtml(Item.hourly_rate_cents) +
    '"/>';
  html +=
    '<label for="TV-mileage_rate_cents">' +
    iTrans('Mileage Rate (Cents)') +
    '</label><input type="number" id="TV-mileage_rate_cents" value="' +
    escapeHtml(Item.mileage_rate_cents) +
    '"/>';
  html +=
    '<label for="TV-daily_rate_cents">' +
    iTrans('Daily Rate') +
    '</label><input type="number" id="TV-daily_rate_cents" value="' +
    escapeHtml(Item.daily_rate_cents) +
    '"/>';

  html += '<label for="TV-Units">' + iTrans('Units') + '</label><select id="TV-Units">';
  html += '<option value="Metric">km | kg</option>';
  html += '<option value="Imperial">mi | lb</option>';
  html += '</select>';

  html += '<h3>' + iTrans('Alerting Options') + '</h3>';
  html +=
    '<label for="TV-speed_Alert">' +
    iTrans('Alert Speed (0 to disable)') +
    '</label><input type="number" id="TV-speed_Alert" value="' +
    escapeHtml(Item.speed_Alert) +
    '"/>';
  html +=
    '<label for="TV-noItinAlert">' +
    iTrans('Silence No Itin Alerts') +
    '</label><input type="checkbox" id="TV-noItinAlert" ' +
    (Item.noItinAlert == 1 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="TV-SilenceAlerts">' +
    iTrans('Silence Speeding Alerts') +
    '</label><input type="checkbox" id="TV-SilenceAlerts" ' +
    (Item.SilenceAlerts == 1 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="TV-Serviceable">' +
    iTrans('Serviceable') +
    '</label><input type="checkbox" id="TV-Serviceable" ' +
    (Item.Serviceable == 1 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="TV-ServiceReason">' +
    iTrans('Service Reason') +
    '</label><input type="text" id="TV-ServiceReason" value="' +
    escapeHtml(Item.ServiceReason) +
    '"/>';

  html += '<h3>' + iTrans('Tracking Options') + '</h3>';
  html +=
    '<label for="TV-showOnMap">' +
    iTrans('Show On Map') +
    '</label><input type="checkbox" id="TV-showOnMap" ' +
    (Item.showOnMap == 1 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="TV-Tracker_Type">' +
    iTrans('GPS Tracker Type') +
    '</label><select id="TV-Tracker_Type" data-mini="true">';
  html += '<option value="">' + iTrans('No Tracker') + '</option>';
  html += '<option value="InReach">InReach</option>';
  html += '<option value="SPOT">Spot</option>';
  html += '</select>';
  html +=
    '<label for="TV-esn">' +
    iTrans('Tracker ESN | Spot Share ID') +
    '</label><input type="text" id="TV-esn" value="' +
    escapeHtml(Item.esn) +
    '"/>';
  html +=
    '<label for="TV-Dynamic_Tracker">' +
    iTrans('Dynamic Tracker') +
    '</label><input type="checkbox" id="TV-Dynamic_Tracker" />';
  html += '<input type="hidden" id="TV-DELETED" value="' + escapeHtml(Item.DELETED) + '"/>';

  html +=
    '<td><button data-mini="true" onClick="saveVehicle()" id="saveVehicleBTN">' +
    iTrans('Save Vehicle') +
    '</button></td></tr></table>';

  html += ShowVehicle_History(Item);

  $('#Vehicle_EditHLDR').html(html);
  $('#Vehicle_EditHLDR').enhanceWithin();
  $('#TV-BASED_IN').val(Item.BASED_IN).selectmenu('refresh');
  $('#TV-Units').val(Item.Units).selectmenu('refresh');
  $('#TV-rate_currency').val(Item.rate_currency).selectmenu('refresh');
  $('#TV-Tracker_Type').val(Item.Tracker_Type).selectmenu('refresh');
  getDepartmentHTML('Vehicle', Item.PrimaryKey, 'vehicleDepartmentAssociations');
}

function ShowVehicle_History(Item) {
  try {
    var HistoryList = JSON.parse(JSON.stringify(Item.History));
  } catch (e) {
    var HistoryList = [];
  }
  var Keys = Object.keys(Item);
  //loop through and highligh changed fields
  if (HistoryList.length == 0) return '<h3>' + iTrans('No Vehicle History') + '</h3>';
  for (var i in HistoryList) {
    var P = HistoryList[i];
    var C = Item;
    if (i == 0) continue;
    if (i > 0) C = HistoryList[parseInt(i) - 1];
    for (var k in Keys) {
      var K = Keys[k];
      switch (K) {
        case 'DELETED':
        case 'PrimaryKey':
        case 'modified':
          continue;
          break;
        case 'SilenceAlerts':
        case 'Serviceable':
        case 'noItinAlert':
        case 'showOnMap':
        case 'Dynamic_Tracker':
          if (P[K] != C[K]) C[K] = '<span class="yellowbg">' + (C[K] == 1 ? 'Yes' : 'No') + '</span>';
          else C[K] = C[K] == 1 ? 'Yes' : 'No';
          continue;
          break;
      }
      if (P[K] != C[K]) C[K] = '<span class="yellowbg">' + C[K] + '</span>';
    }
  }
  var html =
    '<div style="width: 100%; overflow: auto"><table id="Vehicle_History" data-filter="true" class="limit-table first-td-fill" >' +
    '<thead>' +
    '<tr>' +
    '<th>' +
    iTrans('Label') +
    '</th><th>' +
    iTrans('Description') +
    '</th><th>' +
    iTrans('Base') +
    '</th><th>' +
    iTrans('Licence Plate') +
    '</th><th>' +
    iTrans('VIN') +
    '</th><th>' +
    iTrans('Odometer') +
    '</th><th>' +
    iTrans('Odo Next Service') +
    '</th><th>' +
    iTrans('Fuel Type') +
    '</th><th>' +
    iTrans('Fuel Remain') +
    '</th><th>' +
    iTrans('Sat Phone') +
    '</th><th>' +
    iTrans('Inspection Due') +
    '</th><th>' +
    iTrans('GVWR') +
    '</th><th>' +
    iTrans('Tow Weight') +
    '</th><th>' +
    iTrans('Rate Cur') +
    '</th><th>' +
    iTrans('Hr Rate') +
    '</th><th>' +
    iTrans('Mileage Rate') +
    '</th><th>' +
    iTrans('Daily Rate') +
    '</th><th>' +
    iTrans('Alert Speed') +
    '</th><th>' +
    iTrans('No Itin Alert') +
    '</th><th>' +
    iTrans('Silence Alerts') +
    '</th><th>' +
    iTrans('Serviceable') +
    '</th><th>' +
    iTrans('Service Note') +
    '</th><th>' +
    iTrans('Show On Map') +
    '</th><th>' +
    iTrans('Tracker Type') +
    '</th><th>' +
    iTrans('Tracker ESN') +
    '</th><th>' +
    iTrans('Dynamic Tracker') +
    '</th><th>' +
    iTrans('Itin ID') +
    '</th><th>' +
    iTrans('Updated By') +
    '</th>';
  '</tr>' + '</thead>' + '<tbody>';

  for (var i in HistoryList) {
    var I = HistoryList[i];

    html +=
      '<tr ' +
      (I.trackerItin == 0 ? '' : 'class="greenRecord"') +
      '><td>' +
      I.Label +
      '</td><td>' +
      I.description +
      '</td><td>' +
      I.BASED_IN +
      '</td><td>' +
      I.licenceplate +
      '</td><td>' +
      I.SN +
      '</td><td>' +
      I.odometer +
      '</td><td>' +
      I.odometer_Service +
      '</td><td>' +
      I.FuelType +
      '</td><td>' +
      I.fuelRemain +
      '%</td><td>' +
      I.satphone +
      '</td><td>' +
      I.inspection_Due +
      '</td><td>' +
      I.GrossWeight +
      '</td><td>' +
      I.TowWeight +
      '</td><td>' +
      I.rate_currency +
      '</td><td>' +
      I.hourly_rate_cents +
      '</td><td>' +
      I.mileage_rate_cents +
      '</td><td>' +
      I.daily_rate_cents +
      '</td><td>' +
      I.speed_Alert +
      '</td><td>' +
      I.noItinAlert +
      '</td><td>' +
      I.SilenceAlerts +
      '</td><td>' +
      I.Serviceable +
      '</td><td>' +
      I.ServiceReason +
      '</td><td>' +
      I.showOnMap +
      '</td><td>' +
      I.Tracker_Type +
      '</td><td>' +
      I.esn +
      '</td><td>' +
      I.Dynamic_Tracker +
      '</td><td>' +
      (I.trackerItin == 0 ? 'N/A' : I.trackerItin) +
      '</td><td>' +
      I.UpdatedBy +
      '</td></tr>';
  }
  html += '</tbody></table></div>';

  return html;
}

function deleteVehicle(index) {
  console.log('deleteVehicle ');
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Delete Vehicle'),
    headerClose: false,
    buttonPrompt: iTrans('Vehicle will be permanently Deleted! Are you Sure?'),
    buttons: {
      [iTrans('Confirm')]: {
        click: function () {
          $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=deleteVehicle&VehiclePK=' + index, function (json_data) {
            if (json_data.ServerResponse == 'Success') {
              Init_VehicleAdmin();
              BackgroundSync(false, true);
            } else {
              ServerResponse(json_data.data, 'Deleting Vehicle');
            }
          }).fail(function (jqXHR, status, error) {
            ServerResponse('Connection Failed', 'Deleting Vehicle');
          });
        },
      },
      [iTrans('CANCEL')]: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}

function saveVehicle() {
  $('#saveVehicleBTN').prop('disabled', true);
  var Vehicle = {
    PrimaryKey: $('#TV-PrimaryKey').val(),
    BASED_IN: $('#TV-BASED_IN').val(),
    licenceplate: $('#TV-licenceplate').val(),
    Label: $('#TV-Label').val(),
    description: $('#TV-description').val(),
    SN: $('#TV-SN').val(),
    odometer: $('#TV-odometer').val(),
    odometer_Service: $('#TV-odometer_Service').val(),
    modified: $('#TV-modified').val(),
    Units: $('#TV-Units').val(),
    FuelType: $('#TV-FuelType').val(),
    fuelRemain: $('#TV-fuelRemain').val(),
    esn: $('#TV-esn').val(),
    satphone: $('#TV-satphone').val(),
    inspection_Due: $('#TV-inspection_Due').val(),
    GrossWeight: $('#TV-GrossWeight').val(),
    TowWeight: $('#TV-TowWeight').val(),
    hourly_rate_cents: $('#TV-hourly_rate_cents').val(),
    mileage_rate_cents: $('#TV-mileage_rate_cents').val(),
    daily_rate_cents: $('#TV-daily_rate_cents').val(),
    rate_currency: $('#TV-rate_currency').val(),
    speed_Alert: $('#TV-speed_Alert').val(),
    noItinAlert: $('#TV-noItinAlert').is(':checked') ? 1 : 0,
    SilenceAlerts: $('#TV-SilenceAlerts').is(':checked') ? 1 : 0,
    Serviceable: $('#TV-Serviceable').is(':checked') ? 1 : 0,
    ServiceReason: $('#TV-ServiceReason').val(),
    Tracker_Type: $('#TV-Tracker_Type').val(),
    Dynamic_Tracker: $('#TV-Dynamic_Tracker').val(),
    showOnMap: $('#TV-showOnMap').is(':checked') ? 1 : 0,
    DELETED: $('#TV-DELETED').val(),
    UpdatedBy: chatname,
  };
  console.log(Vehicle);
  $.mobile.loading('show', { theme: 'a' });
  $.post(
    BASEURL + 'menu-Tracker-AJAX.php?action=editVehicle',
    {
      data: Vehicle,
    },
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.ServerResponse == 'Uncaught Exception') {
        $('#saveVehicleBTN').prop('disabled', false);
        AJAXFAIL(json_data.data);
      }
      if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data.ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Successfully Added/Updated'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //acknolwedge
                Init_VehicleAdmin();
                BackgroundSync(false, true);
              },
            },
          },
        });
      }
      if (json_data.ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Message'),
          headerClose: false,
          buttonPrompt: json_data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //acknolwedge
                $('#saveVehicleBTN').prop('disabled', false);
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $.mobile.loading('hide');
    $('#saveVehicleBTN').prop('disabled', false);
    AJAXFAIL(error);
  });
}

function TrackerReport() {
  $.mobile.changePage($('#Report_Vehicle_Page'), {
    reverse: true,
    changeHash: false,
  });
  PAX_PopulateLookups();
  $('#tracker_ItinReports').html('<h3>' + iTrans('Retreiving List of Unreported Vehicles') + '</h3>');
  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=getItins', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data.ServerResponse == 'No Records') {
      $('#tracker_ItinReports').html('<h3>' + iTrans('There are no pending Vehicle Reports') + '</h3>');
    }
    if (json_data.ServerResponse == 'Uncaught Exception') ServerResponse(json_data, 'TrackerReport');
    if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    if (json_data.ServerResponse == 'Success') {
      console.log('Received Itins');
      TrackerItins = json_data.data;
      Tracker_PopItinList();
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
  $('#tracker_Vehicles').html('<h3>' + iTrans('Retreiving Vehicle List...') + '</h3>');
  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=getVehicles', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data.ServerResponse == 'No Records') {
      $('#tracker_Vehicles').html('<h3>' + iTrans('There are no Vehicles in the system') + '</h3>');
    }
    if (json_data.ServerResponse == 'Uncaught Exception') ServerResponse(json_data, 'TrackerReport');
    if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    if (json_data.ServerResponse == 'Success') {
      VehicleList = json_data.data;
      Tracker_PopVehicleList();
    }
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
  });
}

function Tracker_PopItinList() {
  var html = '<h3>' + iTrans('Unreported Vehicles') + '</h3>';
  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Itin ID') +
    '</th><th>' +
    iTrans('Filed') +
    '</th><th>' +
    iTrans('Details') +
    '</th><th>' +
    iTrans('Client') +
    '</th><th>' +
    iTrans('Label') +
    '</th><th>' +
    iTrans('Plate') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';

  for (var i in TrackerItins) {
    var I = TrackerItins[i];
    actions =
      '<div class="vertical-center-container"><button onclick="Tracker_ItinSelected(' +
      i +
      ')" data-mini="true" class="action-btn"><img src="./images/Check_mark_green.svg"></button><button onclick="Tracker_ItinNonReportable(' +
      i +
      ')" data-mini="true" class="action-btn"><img src="./images/delete.svg"></button></div>';
    html +=
      '<tr><td>' +
      I.PrimaryKey +
      '</td><td>' +
      I.filed +
      '</td><td>' +
      I.Details +
      '</td><td>' +
      (PAX_CLIENT_LOOKUP[I.ClientPK] != undefined ? PAX_CLIENT_LOOKUP[I.ClientPK].Business : 'Unknown') +
      '</td><td>' +
      I.Vehicle.Label +
      '</td><td>' +
      I.Vehicle.licenceplate +
      '</td><td>' +
      actions +
      '</td></tr>';
  }

  html += '</tbody></table>';
  $('#tracker_ItinReports').html(html).enhanceWithin();
}
var TRACKER_CUR_REPORT = {};
function Tracker_ItinSelected(index) {
  var I = TrackerItins[index];
  TRACKER_CUR_REPORT = I;
  TrackerReportVehicle(TRACKER_CUR_REPORT.Vehicle);
}

function Tracker_ItinNonReportable(index) {
  var PK = TrackerItins[index].PrimaryKey;
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to mark this item as Non-reportable') + '?',
    buttons: {
      [iTrans('Yes')]: {
        click: function () {
          //acknolwedge
          $.mobile.loading('show');
          Tracker_ConfirmNonReportable(PK);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function Tracker_ConfirmNonReportable(PK) {
  $.getJSON(BASEURL + 'menu-Tracker-AJAX.php?action=marknonreportable&PK=' + PK, function (json_data) {
    $.mobile.loading('hide');
    if (json_data.ServerResponse == 'Success') {
      cirroHOME();
    } else {
      alert('Error could not mark non reportable.  try again');
    }
  }).fail(function (jqXHR, status, error) {
    $.mobile.loading('hide');
    alert('Error could not mark non reportable.  try again');
  });
}

function Tracker_VehicleSelected(index) {
  TRACKER_CUR_REPORT = {};
  var I = VehicleList[index];
  TRACKER_CUR_REPORT.Vehicle = I;
  TRACKER_CUR_REPORT.PrimaryKey = 0;
  TrackerReportVehicle(TRACKER_CUR_REPORT.Vehicle);
}
function Tracker_PopVehicleList() {
  var html = '<h3>' + iTrans('Vehicles') + '</h3>';
  html +=
    '<table class="limit-table"><thead><tr><th>' +
    iTrans('Label') +
    '</th><th>' +
    iTrans('Plate') +
    '</th><th>' +
    iTrans('Odometer') +
    '</th><th>' +
    iTrans('Next Service') +
    '</th><th>' +
    iTrans('Fuel') +
    '</th><th>' +
    iTrans('Updated By') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in VehicleList) {
    var I = VehicleList[i];
    var actions =
      '<div class="vertical-center-container"><button onclick="Tracker_VehicleSelected(' +
      i +
      ')" data-mini="true" class="action-btn"><img src="./images/Check_mark_green.svg"></button></div>';
    html +=
      '<tr><td>' +
      I.Label +
      '</td><td>' +
      I.licenceplate +
      '</td><td>' +
      I.odometer +
      '</td><td>' +
      I.odometer_Service +
      '</td><td>' +
      I.fuelRemain +
      '%</td><td>' +
      I.UpdatedBy +
      '</td><td>' +
      actions +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#tracker_Vehicles').html(html).enhanceWithin();
}

function TrackerReportVehicle(Item) {
  $('#Vehicle_EditHLDR').html(''); //to prevent duplicate DIV id's
  $('#trackerVehicleLabel').html(iTrans('Vehicle Report') + ': ' + Item.Label);
  $.mobile.changePage($('#EnterReport_Vehicle_Page'), {
    reverse: true,
    changeHash: false,
  });
  var html = '';

  html +=
    '<label for="TV-odometer">' +
    iTrans('Odometer') +
    '</label><input type="number" id="TV-odometer" value="' +
    escapeHtml(Item.odometer) +
    '"/>';

  html += '<label for="TV-fuelRemain">' + iTrans('% Fuel Remaining') + '</label>';
  html += '<input type="number" max="100" min="0" id="TV-fuelRemain" value="' + Item.fuelRemain + '"/>';

  html +=
    '<label for="TV-Serviceable">' +
    iTrans('Serviceable') +
    '</label><input type="checkbox" id="TV-Serviceable" ' +
    (Item.Serviceable == 1 ? 'checked' : '') +
    '/>';
  html +=
    '<label for="TV-ServiceReason">' +
    iTrans('Service Reason') +
    '</label><input type="text" id="TV-ServiceReason" value="' +
    escapeHtml(Item.ServiceReason) +
    '"/>';

  html +=
    '<td><button data-mini="true" onClick="saveVehicleReport()" id="saveVehicleReportBTN" class="greenbg" data-icon="check">' +
    iTrans('Save Vehicle Report') +
    '</button></td></tr></table>';

  html += ShowVehicle_History(Item);

  $('#trackerReportHLDR').html(html);
  $('#trackerReportHLDR').enhanceWithin();
}

function saveVehicleReport() {
  var I = JSON.parse(JSON.stringify(TRACKER_CUR_REPORT.Vehicle));
  if (TRACKER_CUR_REPORT.PrimaryKey != undefined) I.trackerItin = TRACKER_CUR_REPORT.PrimaryKey;
  else I.trackerItin = 0;
  I.odometer = parseInt($('#TV-odometer').val());
  if (I.odometer < TRACKER_CUR_REPORT.Vehicle.odometer || $('#TV-odometer').val() == '') {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('ERROR'),
      headerClose: false,
      buttonPrompt: iTrans('Odometer reading cannot be less than ') + TRACKER_CUR_REPORT.Vehicle.odometer,
      buttons: {
        [iTrans('Reset Reading')]: {
          click: function () {
            //acknolwedge
            $('#TV-odometer').val(TRACKER_CUR_REPORT.Vehicle.odometer);
          },
        },
        [iTrans('Close')]: {
          click: function () {
            //acknolwedge
          },
        },
      },
    });
    return;
  }
  I.fuelRemain = $('#TV-fuelRemain').val();
  I.Serviceable = $('#TV-Serviceable').is(':checked') ? 1 : 0;
  I.ServiceReason = $('#TV-ServiceReason').val();
  $('#saveVehicleReportBTN').prop('disabled', true);
  $.mobile.loading('show');
  $.post(
    BASEURL + 'menu-Tracker-AJAX.php?action=editVehicle',
    {
      data: I,
    },
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data.ServerResponse == 'Uncaught Exception') {
        $('#saveVehicleReportBTN').prop('disabled', false);
        AJAXFAIL(json_data.data);
      }
      if (json_data.ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      if (json_data.ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Successfully Updated'),
          buttons: {
            [iTrans('OK')]: {
              click: function () {
                //acknolwedge
                TrackerReport();
                updateMenuIconBadges();
                BackgroundSync(false, true);
              },
            },
          },
        });
      }
      if (json_data.ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Message'),
          headerClose: false,
          buttonPrompt: json_data.data,
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //acknolwedge
                $('#saveVehicleReportBTN').prop('disabled', false);
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $.mobile.loading('hide');
    $('#saveVehicleReportBTN').prop('disabled', false);
    AJAXFAIL(error);
  });
}
