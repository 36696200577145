/**
 *
 */
class UsAmericanIfrInfoTable extends AbstractDatabaseTable {
  constructor() {
    super('pdf', 'icao');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('US_IFR_INDEX.mbtiles');
  }

  tableCreationQuery() {
    //#region return ...
    return 'select true;';
    //#endregion
  }
}
