/**
 * @typedef HintFormObject
 * @type {object}
 * @property {number} form_data_id
 * @property {string} title
 * @property {string|null} status
 * @property {string|null} locked_at
 */

/**
 * Encapsulates all data exchange with Vue
 */
class FormBridge {
  /**
   * @param {string} sourcePage
   * @param {string} htmlContainerId
   * @param {HintFormObject[]} forms
   * @param {number} linkTargetId
   * @param {number} editLevel
   */
  static instance(sourcePage, htmlContainerId, forms, linkTargetId, editLevel = this.EL_VIEW) {
    if (this.INSTANCE == null) {
      this.INSTANCE = new this(sourcePage, editLevel, htmlContainerId, forms, linkTargetId);
    }

    return this.INSTANCE;
  }

  /**
   * Cleans up the current instance so it can be reinitialized
   */
  static clearInstance() {
    if (this.INSTANCE == null) {
      return;
    }

    this.INSTANCE.cleanUp();
    this.INSTANCE = null;
  }

  /**
   *
   * @param sourcePage
   * @param editLevel
   * @param htmlContainerId
   * @param forms
   * @param linkTargetId
   * @param linkTargetType
   * @private
   */
  constructor(sourcePage, editLevel, htmlContainerId, forms, linkTargetId, linkTargetType) {
    this.sourcePage = sourcePage;
    this.editLevel = editLevel;
    this.linkTargetId = linkTargetId;
    this.linkTargetType = linkTargetType;

    this.formHolder = $(htmlContainerId);

    this.picker = new FormPickerHelper(this.editLevel, this.linkTargetType, this.linkTargetId);
    this.formHolder.append(this.picker.dom.body).enhanceWithin();

    this.addLoadedFormsToPicker(forms);

    if (this.linkTargetId > 0) {
      this.loadLinkedForms().then((loaded) => this.addLoadedFormsToPicker(loaded));
    }

    verbose.log('Forms', 'Created FormBridge', this);
  }
  /**
   *
   * @param {HintFormObject[]} forms
   */
  async addLoadedFormsToPicker(forms) {
    for (const fKey in forms) {
      if (!forms.hasOwnProperty(fKey)) {
        continue;
      }

      const form = forms[fKey];
      this.picker.addForm(form.form_data_id, form.title, form.status, form.locked_at);
      verbose.log('Forms', 'Added Form: ', form);
    }
  }

  /**
   *
   */
  cleanUp() {
    if (this.picker != null) {
      this.picker.destroy();
    }
  }

  /**
   * @return {Promise<void>}
   */
  async viewForm(id) {
    this._enterGroupPath(`formData/${id}`, {
      'from-legacy': this.sourcePage,
      start: true,
      'form-picker': 1,
    });
  }

  /**
   *
   * @param formId
   * @return {Promise<void>}
   */
  async editForm(formId) {
    this.viewForm(formId, this.sourcePage);
  }

  /**
   * @return {Promise<void>}
   */
  async linkNewForm() {
    this._enterGroupPath('forms', {
      'from-legacy': this.sourcePage,
      'link-target': this.linkTargetType,
      'link-target-id': this.linkTargetId,
      'form-picker': 1,
    });
  }

  /**
   * @return {Promise<void>}
   */
  async linkFilledForm() {
    this._enterGroupPath('forms', {
      'from-legacy': this.sourcePage,
      'link-target': this.linkTargetType,
      'link-target-id': this.linkTargetId,
      'filled-form-picker': 1,
    });
  }

  /**
   * @param formDataId
   * @return {Promise<void>}
   */
  async unlinkForm(formDataId) {
    if (this.linkTargetId < 0) {
      // Itin's not saved yet, so we simply pop it off the floating forms
      this.picker.removeForm(formDataId);
      return;
    }

    try {
      await RemoteData.delete(
        `api/v2/groups/${window.vueApp.groupKey}/forms/filled/${formDataId}/links/${this.linkTargetType}/${this.linkTargetId}`
      );
      this.picker.removeForm(formDataId);
    } catch (e) {
      VueBridge.showDialog(
        'Failed',
        'There was a problem unlinking this Form. Please try again. ' +
          'If this continues to happen, please notify support.'
      );
    }
  }

  confirmUnlink(callback) {
    VueBridge.showConfirmDialog('Confirm', 'Are you sure you want to unlink this Filled Form?', callback);
  }

  clear() {
    this.picker.clear();
  }

  static returnToPreSwitchPosition() {
    window.scrollTo(0, this.INSTANCE.preSwitchScrollPosition);
  }

  /**
   * Prepends the group path, then enters Vue
   * @param path
   * @param args
   * @return {Promise<void>}
   * @private
   */
  async _enterGroupPath(path, args) {
    this.preSwitchScrollPosition = window.scrollY;

    const query = RemoteData.buildQueryString(args);
    return VueBridge.enterVue(`/groups/${window.vueApp.groupKey}/${path}${query}`);
  }

  async loadLinkedForms() {
    let result = [];

    try {
      result = await RemoteData.get(
        `api/v2/groups/${window.vueApp.groupKey}/forms/filled/links/${this.linkTargetType}/${this.linkTargetId}`
      );
    } catch (e) {}
    return result;
  }
}

// View: Forms can only be viewed
FormBridge.EL_VIEW = 1;
// Fill: Forms can be filled, but not unlinked or linked
FormBridge.EL_FILL = 2;
// Admin: Forms can be filled, unlinked, and linked
FormBridge.EL_ADMIN = 3;
