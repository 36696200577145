class vSAMA_AbstractTab extends iSAMA_AbstractObservable {
  constructor(_model) {
    super();
    this.id = cSAMA_Utils.GetUID();
    this.model = _model;
    this.dom = {};
  }

  /**
   *
   * @param {vSAMA_TabbedEditor} _owner
   */
  SetOwner(_owner) {
    this.owner = _owner;
  }

  SetUpGui(_riderTemplate, _bodyTemplate, _readOnly) {
    this.dom.rider = $(_riderTemplate);
    this.dom.body = $(_bodyTemplate);

    this.readOnly = _readOnly;

    this.dom.title = this.dom.rider.find('a');
    this.dom.deleteButton = this.dom.body.find('button.sama-btn-delete');

    this.dom.body.attr('id', 'tab' + this.id);
    this.dom.title.attr('href', '#tab' + this.id);

    if (!_readOnly) {
      this.dom.deleteButton.on('click.samaevt', () => {
        this.OnDelete();
      });
    } else {
      this.dom.deleteButton.prop('disabled', true);
    }

    this.dom.combined = this.dom.body.add(this.dom.rider);
  }

  GetModel() {
    return this.model;
  }

  /**
   * Instruct the system to delete the model's data
   */
  OnDelete() {
    this.model.SetDeleted(true);
    this.Destroy();
  }

  /**
   * Removes dom modifications and notifies owner
   */
  Destroy() {
    this.DestroySilently();
    this.owner.TabDestroyed(this.id);
  }

  /**
   * Removes dom modifications
   */
  DestroySilently() {
    this.model.EndValidation();
    this.dom.rider.remove();
    this.dom.body.remove();
  }

  get rider() {
    return this.dom.rider;
  }

  get body() {
    return this.dom.body;
  }
}
