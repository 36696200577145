/**
 *
 */
class WaypointFeaturesTable extends AbstractDatabaseTable {
  constructor() {
    super('WaypointFeatures', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey     integer primary key,
            "group"        text,
            "WP_Groups_PK" numeric,
            "featureType"  text,
            "Label"        text,
            "feature"      text,
            "centerLat"    numeric,
            "centerLong"   numeric,
            "created"      text,
            "modified"     text,
            "whoupdate"    text,
            "isLocked"     integer,
            "isShared"     integer,
            "forJob_PK"    integer,
            "Icon"         text,
            "Point"        text,
            "Point_Fill",
            "Polyline"     text,
            "Polygon"      text,
            "Polygon_Fill" text,
            "DELETED"      integer
        )
    `;
    //#endregion
  }
}
