/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
var WP_data;
var remote_WP_Update = [];
var remote_WP_Feature_Update = [];
var local_WP_Update = [];
var local_WP_Feature_Update = [];
var remote_WP_Send = false;
var WPT_COMPARE = {};
var WPT_SOMETHING_FAILED = false;
var SYNCHRONIZING_WP_DATA = false;

var WP_V3 = false;
var WP_SILENT = false;
function syncWP(silent = false) {
  WP_SILENT = silent;
  if (WP_V3) {
    syncWP_V3();
    return;
  }
  WPT_SOMETHING_FAILED = false;
  WB_Syncfailed = false;
  console.log('Syncing Waypoints');
  IOS_postMessage({
    Message: 'WPtext',
    text: 'Downloading',
  });
  getWPdata(); //first check
}

function getWpDataFail() {
  WPT_SOMETHING_FAILED = true;
  WP_SYNCING = false;
  if (WP_SILENT) {
    return;
  }
  IOS_postMessage({
    Message: 'WPRemoteConFail',
  });
  SYNC_STATUS('WP');
}

async function getWPdata() {
  console.log('Getting WP Data');

  let json_data = null;

  try {
    json_data = await RemoteData.get('sync-AJAX.php?action=wpDataV2');
  } catch (e) {
    getWpDataFail();
    return;
  }

  const serverResponse = json_data[0].ServerResponse;

  if (serverResponse === 'Uncaught Exception') {
    getWpDataFail();
    return;
  }

  if (serverResponse === 'No Waypoints in Database' || serverResponse === 'Offline') {
    IOS_postMessage({
      Message: 'WPtext',
      text: 'Complete',
    });

    WP_SYNCING = false;
    if (WP_SILENT) {
      return;
    }

    SYNC_STATUS('WP');
    update_Sync('LastWPsync', LastWPsync);
    return;
  }

  WP_data = {};
  for (const row of json_data) {
    WP_data[row.PrimaryKey] = row;
  }

  console.log('Received WP Data');

  const existingRecords = await WaypointsTable.query().select();

  for (const row of existingRecords) {
    WPT_COMPARE[row.PrimaryKey] = row;
  }

  compare_WP(WP_data);
}

async function compare_WP(WP_data) {
  console.log('Comparing WP Data');
  IOS_postMessage({
    Message: 'WPtext',
    text: 'Comparing',
  });

  remote_WP_Update = [];
  remote_WP_Send = false;

  for (let i of Object.values(WP_data)) {
    await getWPsync(i);
  }

  for (let i of Object.values(WPT_COMPARE)) {
    deleteWPsync(i);
  }

  if (remote_WP_Send) {
    setTimeout(function () {
      remote_updateWP(); //lets give an arbatrary 1 second for Retrieving outgoing array elements before attempting send.
    }, 1000);
  } else {
    WP_SYNCING = false;
    WP_data = null;
    if (!WPT_SOMETHING_FAILED) {
      update_Sync('LastWPsync', LastWPsync);
    }

    if (WP_SILENT) {
      return;
    }

    setTimeout(function () {
      IOS_postMessage({
        Message: 'WPtext',
        text: 'Complete',
      });
    }, 4000);

    SYNC_STATUS('WP');
  }
}

function deleteWPsync(WP_Exists) {
  if (WP_data[WP_Exists.PrimaryKey] == null) {
    deleteWP(WP_Exists);
  }
}

async function getWPsync(WP_rec) {
  if (WPT_COMPARE[WP_rec.PrimaryKey] == null) {
    await updateWP(WP_rec, null, 'Insert');
    return;
  }

  let e = WPT_COMPARE[WP_rec.PrimaryKey];

  const datetime = stringToDateObject(e.datetime);
  const Recdatetime = stringToDateObject(WP_rec.datetime);
  if (datetime.getTime() > Recdatetime.getTime()) {
    // Local Waypoint data is newer upate remote record
    remote_WP_Send = true; //set update flag
    let result = await WaypointsTable.query().raw({
      q: 'select * from Waypoints where PrimaryKey = ?',
      v: [WP_rec.PrimaryKey],
    });

    if (result.length <= 0) {
      console.log('Waypoint not found in local database');
      return;
    }

    remote_WP_Update.push(result[0]); //get local Data attribute!!!
    return;
  }

  if (datetime.getTime() <= Recdatetime.getTime()) {
    //remote data is newer update local record
    await updateWP(WP_rec, e.PrimaryKey, 'Update'); //get Data Attribute
  }
}

function deleteWP(WP_rec) {
  //update or insert local WP record
  cirroDB.Delete('Waypoints', 'PrimaryKey = ?', WP_rec.PrimaryKey, function (e) {
    IOS_postMessage({
      Message: 'WPtext',
      text: 'Deleting: ' + WP_rec.Name,
    });
  });
}

async function updateWP(WP_rec, id, Action) {
  let json_data;
  try {
    json_data = await $.getJSON(BASEURL + 'sync-AJAX.php?action=wpDataV2_Data&PK=' + WP_rec.PrimaryKey);
  } catch (_) {
    IOS_postMessage({
      Message: 'WPRemoteConFail',
    });
    WPT_SOMETHING_FAILED = true;
    WP_SYNCING = false;
    SYNC_STATUS('WP');
    return;
  }

  //you overwrote a global here and it all fell apart because of it
  var WP_data = json_data[0];
  if (WP_data.ServerResponse == 'Offline' || WP_data.ServerResponse == 'Uncaught Exception') {
    WPT_SOMETHING_FAILED = true;
    WP_SYNCING = false;
    SYNC_STATUS('WP');
    return;
  }

  IOS_postMessage({
    Message: 'WPtext',
    text: 'Updating: ' + WP_rec.Name,
  });

  const startTime = performance.now();
  const perf = {};

  let Waypoint = {
    PrimaryKey: WP_rec.PrimaryKey,
    Name: WP_rec.Name,
    datetime: WP_rec.datetime,
    data: JSON.stringify(WP_data),
  };

  perf.jsonification = performance.now() - startTime;

  try {
    await WaypointsTable.query().insert(Waypoint);
  } catch (e) {
    verbose.error('SYNC', 'Waypoint sync failed', e);
  }

  perf.database = performance.now() - startTime;

  verbose.log('SYNC', WP_rec.Name, perf);

  IOS_postMessage({
    Message: 'WPtext',
    text: 'Finished Updating ' + WP_rec.Name,
  });
}

function remote_updateWP() {
  delete WP_data;
  IOS_postMessage({
    Message: 'WPtext',
    text: 'Sending Data',
  });
  var updateString = JSON.stringify(remote_WP_Update);
  console.log('Updating: ' + remote_WP_Update.length + ' WP Remote Records');
  console.log(remote_WP_Update);
  var updateString64 = btoa(updateString);
  //send data from remote_WP_Update array to sync-AJAX.php?action=fcUpdate
  //confirmation of data received with new LocalWPsync datetime else update with original received from server
  $.post(
    BASEURL + 'sync-AJAX.php?action=wpUpdate',
    {
      update: updateString64,
    },
    function (data) {
      //console.log(data);
      if (data[0].ServerResponse != 'Success' || data[0].ServerResponse == 'Offline') {
        console.log('Remote update Failed on Server Side: ' + data[0].ServerResponse);
        WPT_SOMETHING_FAILED = true;
        WP_SYNCING = false;
        SYNC_STATUS('WP');
        IOS_postMessage({
          Message: 'WPSyncFail',
        });
        //reset fc syncing status, do not update syncdatetime because sync failed
      }
      if (data[0].ServerResponse == 'Success') {
        WPsync = new Date(data[0].LastWPsync);
        console.log('Remote WP update completed Successfully');
        //Update loacal sync database with new FDT sync datetime
        WP_SYNCING = false;
        update_Sync('LastWPsync', WPsync);
        if (WP_SILENT) {
          return;
        }
        SYNC_STATUS('WP');
        IOS_postMessage({
          Message: 'WP_Complete',
        });
        setTimeout(function () {
          IOS_postMessage({
            Message: 'WPtext',
            text: 'Complete',
          });
        }, 4000);
      }

      //dbWP.close();
      console.log('Closing WP Database after Syncing / sync attemtps');
      //Ensure Complete synce success by verifying Con_Flag = true; if false local data was not updated!
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    console.log(error);
    //dbWP.close();

    console.log('Closing WP Database after Failed Sync');
    WPT_SOMETHING_FAILED = true;
    WP_SYNCING = false;
    if (WP_SILENT) {
      return;
    }
    IOS_postMessage({
      Message: 'WPtext',
      text: 'Complete: Send Failed',
    });
    SYNC_STATUS('WP');
    //Update local sync database with incremented sync attempt
    IOS_postMessage({
      Message: 'WPSyncFail',
    });
  });
}

/*************************** Waypoint V3 Sync framework ********************/
var OFFLINE_WPTS_SENT = false;
function syncWP_V3() {
  WPT_SOMETHING_FAILED = false;
  WB_Syncfailed = false;
  IOS_postMessage({
    Message: 'WPtext',
    text: 'Downloading',
  });
  MigrateDeviceWPTS();
}

function MigrateDeviceWPTS() {
  localStorageDB.getItem('DeviceWPTS', function (e) {
    if (e != null) {
      OFFLINE_WPTS_SENT = true;
      var DeviceWPTS = JSON.parse(e);
      createDeviceWPTS(DeviceWPTS);
    } else {
      getWP_Groups(); //first check
    }
  });
}

function createDeviceWPTS(DeviceWPTS) {
  var WP_rec = New_WP_Group_Rec('Device Waypoints', 'Device');
  WP_rec.PrimaryKey = -1;

  cirroDB.insert('WaypointGroups', WP_rec, null, function (e) {
    IOS_postMessage({
      Message: 'WPtext',
      text: 'Migrating Device Waypoints',
    });
    for (var i in DeviceWPTS) {
      var I = DeviceWPTS[i];
      var feature = New_WP_Feature_Rec(I.Name, -1, 'Point');
      feature.PrimaryKey = '-' + (i + 1);
      feature.feature =
        '{"type":"Feature","properties":{"Name":"' +
        I.Name +
        '","Lat":"' +
        I.Lat +
        '","Long":"' +
        I.Long +
        '"},"geometry":{"type":"Point","coordinates":["' +
        I.Long +
        '","' +
        I.Lat +
        '"]}}';
      feature.centerLat = I.Lat;
      feature.centerLong = I.Long;
      InsertDeviceWPT(feature, i == DeviceWPTS.length - 1);
    }
  });
}

function InsertDeviceWPT(feature, last) {
  cirroDB.insert('WaypointFeatures', feature, null, function (e) {
    if (last) {
      //localStorageDB.clearItem('DeviceWPTS', function(e){
      LOCALSTORAGE.DeviceWPTS = undefined;
      getWP_Groups();
      //});
    }
  });
}

function getWP_Groups() {
  //$("#info").html("Getting Duty Times From Server...");
  $.getJSON(BASEURL + 'sync-AJAX.php?action=getWP_Groups', function (json_data) {
    if (json_data[0].ServerResponse != 'Uncaught Exception') {
      WP_data = {};
      for (var i in json_data) {
        WP_data[json_data[i].PrimaryKey] = json_data[i];
      }

      if (json_data[0].ServerResponse == 'No Waypoints in Database') {
        IOS_postMessage({
          Message: 'WPtext',
          text: 'Complete',
        });
        WP_SYNCING = false;
        SYNC_STATUS('WP1');
        update_Sync('LastWPsync', LastWPsync);
        //dbWP.close();
      } else compare_WP_Groups(WP_data);
    } else {
      IOS_postMessage({
        Message: 'WPRemoteConFail',
      });
      WPT_SOMETHING_FAILED = true;
      WP_SYNCING = false;
      SYNC_STATUS('WP2');
    }
  }).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'WPRemoteConFail',
    });
    WPT_SOMETHING_FAILED = true;
    WP_SYNCING = false;
    SYNC_STATUS('WP3');
  });
}

function compare_WP_Groups(WP_data) {
  cirroDB.query('WaypointGroups', 'PrimaryKey != ?', '', function (e) {
    var Items = e;
    if (e === false) {
      Items = [];
    }
    WPT_COMPARE = {};
    for (var i in Items) {
      if (Items[i].PrimaryKey < 0) OFFLINE_WPTS_SENT = true;
      WPT_COMPARE[Items[i].PrimaryKey] = Items[i];
    }
    Compare_and_Update_WPgroups(WP_data); //first check
  });
}
function Compare_and_Update_WPgroups(WP_data) {
  remote_WP_Update = [];
  //Check server data and Insert New Local or send new/updated to server

  for (var i in WP_data) {
    var S = WP_data[i];
    var Smod = stringToDateObject(S.modified);
    if (WPT_COMPARE[i] == undefined) {
      //Not on device save it and add to GetFeaturesArray
      updateWaypointGroup(S);
      local_WP_Update.push(S);
    } else {
      var Lmod = stringToDateObject(WPT_COMPARE[i].modified);
      if (Lmod.getTime() > Smod.getTime()) {
        //Send updated group data to server
        remote_WP_Update.push(WPT_COMPARE[i]);
        local_WP_Update.push(S);
      }
      if (Lmod.getTime < Smod.getTime()) {
        //Update Local Waypoint
        updateWaypointGroup(S);
        local_WP_Update.push(S);
      }
    }
  }
  //check for missing local data that needs remote inserting
  for (var i in WPT_COMPARE) {
    var L = WPT_COMPARE[i];
    var Smod = stringToDateObject(S.modified);
    if (WP_data[i] == undefined) {
      //Not on server send it there
      remote_WP_Update.push(WPT_COMPARE[i]);
    }
  }

  remote_WP_Feature_Update = [];
  local_WP_Feature_Update = [];
  cirroDB.query('WaypointFeatures', 'WP_Groups_PK < ?', 0, function (e) {
    WPT_COMPARE = {};

    var Items = e;
    if (e === false) {
      Items = [];
    }

    remote_WP_Feature_Update = Items;

    CheckWP_Features(0);
  });
}

function CheckWP_Features(index) {
  if (local_WP_Update[index] == undefined) {
    //done feature update loop

    updateWaypointFeatures();
    return;
  }

  var PK = local_WP_Update[index];
  cirroDB.query('WaypointFeatures', 'WP_Groups_PK = ?', PK, function (e) {
    WPT_COMPARE = {};
    var Items = e;
    if (e === false) {
      Items = [];
    }
    for (var i in Items) {
      if (Items[i].PrimaryKey < 0) OFFLINE_WPTS_SENT = true;
      WPT_COMPARE[Items[i].PrimaryKey] = Items[i];
    }
    getWP_Features(index, PK);
  });
}

function getWP_Features(index) {
  var PK = local_WP_Update[index].PrimaryKey;
  $.getJSON(BASEURL + 'sync-AJAX.php?action=getWP_Features&PK=' + PK, function (json_data) {
    if (json_data[0].ServerResponse != 'Uncaught Exception') {
      WP_data = {};
      if (json_data[0].ServerResponse == 'No Features in Database') json_data = [];
      for (var i in json_data) {
        WP_data[json_data[i].PrimaryKey] = json_data[i];
      }
      Compare_and_Update_WPfeatures(WP_data, index);
    } else {
      IOS_postMessage({
        Message: 'WPRemoteConFail',
      });
      WPT_SOMETHING_FAILED = true;
      WP_SYNCING = false;
      SYNC_STATUS('WP4');
    }
  }).fail(function (jqXHR, status, error) {
    IOS_postMessage({
      Message: 'WPRemoteConFail',
    });
    WPT_SOMETHING_FAILED = true;
    WP_SYNCING = false;
    SYNC_STATUS('WP5');
  });
}

function Compare_and_Update_WPfeatures(WP_data, index) {
  //Check server data and Insert New Local or send new/updated to server
  for (var i in WP_data) {
    var S = WP_data[i];
    var Smod = stringToDateObject(S.modified);
    if (WPT_COMPARE[i] == undefined) {
      //Not on device save it and add to GetFeaturesArray
      local_WP_Feature_Update.push(S);
    } else {
      var Lmod = stringToDateObject(WPT_COMPARE[i].modified);
      if (Lmod.getTime() > Smod.getTime()) {
        //Send updated group data to server
        remote_WP_Feature_Update.push(WPT_COMPARE[i]);
      }
      if (Lmod.getTime < Smod.getTime()) {
        //Update Local Waypoint
        local_WP_Feature_Update.push(S);
      }
    }
  }
  index++;
  CheckWP_Features(index);
}

function updateWaypointGroup(WP_rec) {
  cirroDB.insert('WaypointGroups', WP_rec, null, function (e) {
    IOS_postMessage({
      Message: 'WPtext',
      text: 'Updating: ' + WP_rec.Name,
    });
  });
}

function updateWaypointFeatures() {
  if (local_WP_Feature_Update.length > 0) {
    remote_updateWaypointData();
    return;
  }

  WaypointFeaturesTable.query()
    .insert(local_WP_Feature_Update)
    .catch((e) => {
      console.log('Database Error 32: ' + e.message);
    });

  remote_updateWaypointData();
}

function deleteWaypointGroup(WP_rec) {
  //update or insert local WP record
  IOS_postMessage({
    Message: 'WPtext',
    text: 'Deleting: ' + WP_rec.Name,
  });
  cirroDB.Delete('WaypointGroups', 'PrimaryKey = ?', WP_rec.PrimaryKey, function (e) {
    cirroDB.Delete('WaypointFeatures', 'WP_Groups_PK = ?', WP_rec.PrimaryKey, function (e) {
      //Waypoint Group  and Features Deleted
    });
  });
}
function deleteWaypointFeature(WP_feature) {
  //update or insert local WP record
  IOS_postMessage({
    Message: 'WPtext',
    text: 'Deleting: ' + WP_feature.Label,
  });
  cirroDB.Delete('WaypointFeatures', 'PrimaryKey = ?', WP_feature.PrimaryKey, function (e) {
    //Waypoint Feature Deleted
  });
}

function remote_updateWaypointData() {
  delete WP_data;
  if (remote_WP_Update.length > 0 || remote_WP_Feature_Update.length > 0) {
    IOS_postMessage({
      Message: 'WPtext',
      text: 'Sending Data',
    });
    var updateString = JSON.stringify(remote_WP_Update);
    console.log('Updating: ' + remote_WP_Update.length + ' WP Groups Records');
    console.log('Updating: ' + remote_WP_Feature_Update.length + ' WP Feature Records');

    //confirmation of data received with new LocalWPsync datetime else update with original received from server
    $.post(
      BASEURL + 'sync-AJAX.php?action=WaypointData',
      {
        data: { WaypointGroups: remote_WP_Update, WaypointFeatures: remote_WP_Feature_Update },
      },
      function (data) {
        //console.log(data);
        if (data[0].ServerResponse != 'Success' || data[0].ServerResponse == 'Offline') {
          console.log('Remote update Failed on Server Side: ' + data[0].ServerResponse);

          WPT_SOMETHING_FAILED = true;
          WP_SYNCING = false;
          SYNC_STATUS('WP6');
          IOS_postMessage({
            Message: 'WPSyncFail',
          });
        }
        if (data[0].ServerResponse == 'Success') {
          if (OFFLINE_WPTS_SENT) {
            //Delete any waypoints with negative primary keys
            cirroDB.Delete('WaypointGroups', 'PrimaryKey < ?', 0, function (e) {
              cirroDB.Delete('WaypointFeatures', 'WP_Groups_PK < ?', 0, function (e) {
                //Client side new Waypoint Group  and Features Deleted
                //Run sync again since we need to ensure the newley added offline items are refreshed with their assigned primary key
                localStorageDB.clearItem('DeviceWPTS', function (e) {
                  OFFLINE_WPTS_SENT = false;
                  syncWP_V3();
                });
              });
            });
          } else {
            WPsync = new Date(data[0].LastWPsync);
            console.log('Remote WP update completed Successfully');
            //Update loacal sync database with new FDT sync datetime
            WP_SYNCING = false;
            SYNC_STATUS('WP7');
            update_Sync('LastWPsync', WPsync);
            IOS_postMessage({
              Message: 'WPtext',
              text: 'Complete',
            });
          }
        }

        //Ensure Complete synce success by verifying Con_Flag = true; if false local data was not updated!
      },
      'json'
    ).fail(function (jqXHR, status, error) {
      //alert("Failed Remote update Request!");
      console.log(error);
      //dbWP.close();
      console.log('Closing WP Database after Failed Sync');
      WPT_SOMETHING_FAILED = true;
      IOS_postMessage({
        Message: 'WPtext',
        text: 'Complete: Send Failed',
      });
      WP_SYNCING = false;
      SYNC_STATUS('WP8');
      //Update local sync database with incremented sync attempt
      IOS_postMessage({
        Message: 'WPSyncFail',
      });
    });
  } else {
    WP_SYNCING = false;
    SYNC_STATUS('WP9');
    update_Sync('LastWPsync', WPsync);

    IOS_postMessage({
      Message: 'WPtext',
      text: 'Complete',
    });
  }
}

function New_WP_Group_Rec(Name, type) {
  var item = {
    PrimaryKey: null,
    group: GROUP_DATA.group,
    datasetType: type,
    Name: Name,
    Description: '',
    created: dateToStringObject(new Date()),
    modified: dateToStringObject(new Date()),
    whoupdate: USER_RIGHTS['chatname'],
    ownerPK: USER_RIGHTS['PrimaryKey'],
    isLocked: 0,
    isShared: 0,
    forJob_PK: 0,
    featureCount: 0,
    minLat: 0,
    maxLat: 0,
    minLong: 0,
    maxLong: 0,
    Icon: 'circle-15.svg',
    Point: 'rgba(255,255,255,1)',
    Point_Fill: 'rgba(255,255,255,1)',
    Polyline: 'rgba(255,255,255,1)',
    Polygon: 'rgba(255,255,255,1)',
    Polygon_Fill: 'rgba(255,255,255,1)',
    DELETED: 0,
  };
  return item;
}

function New_WP_Feature_Rec(Label, WP_Groups_PK, featureType) {
  var item = {
    PrimaryKey: null,
    group: GROUP_DATA.group,
    WP_Groups_PK: WP_Groups_PK,
    featureType: featureType,
    Label: Label,
    feature: 0,
    centerLat: 0,
    centerLong: 0,
    created: dateToStringObject(new Date()),
    modified: dateToStringObject(new Date()),
    whoupdate: USER_RIGHTS['chatname'],
    isLocked: 0,
    isShared: 0,
    forJob_PK: 0,
    Icon: 'circle-15.svg',
    Point: 'rgba(255,255,255,1)',
    Point_Fill: 'rgba(255,255,255,1)',
    Polyline: 'rgba(255,255,255,1)',
    Polygon: 'rgba(255,255,255,1)',
    Polygon_Fill: 'rgba(255,255,255,1)',
    DELETED: 0,
  };
  return item;
}
