var AssetInspected;
function initReport_Assets() {
  Assets = Report_Data[0];
  AssetStaffList = Report_Data[1];
  AssetCategory = Report_Data[2];
  AssetInspected = Report_Data[3];
  makeAssetReportControls();
  ADMIN_filterAssetArray();
}

function makeAssetReportControls() {
  var html = '<div class="limit-table" id="ADMIN_FilterAssetHLDR">';
  html += '<table class="limit-table first-td-fill"><tr><td>';
  html += '<label for="ADMIN_FilterAssetTXT">' + iTrans('Search By Name/SN') + '</label>';
  html += '<input type="text" data-mini="true" id="ADMIN_FilterAssetTXT" onKeyUp="ADMIN_filterAssetArray()"/>';
  html +=
    '</td><td><button onclick="ADMIN_clearAssetFilter();" class="directto-btn"><img src="./images/cleartxt.png" width="30px"></button></td></tr></table>';
  html += '<label for="ADMIN_AssetCategorySelector">' + iTrans('Category') + '</label>';
  html += '<select id="ADMIN_AssetCategorySelector" onChange="ADMIN_filterAssetArray();" data-mini="true">';
  html += '<option value="null" selected>' + iTrans('All') + '</option>';
  for (var i in AssetCategory)
    html += '<option value="' + AssetCategory[i].Category + '">' + AssetCategory[i].Category + '</option>';
  html += '</select>';
  html += '<label for="ADMIN_AssetStatusSelector">' + iTrans('Status') + '</label>';
  html += '<select id="ADMIN_AssetStatusSelector" onChange="ADMIN_filterAssetArray();" data-mini="true">';
  html += '<option value="" selected>' + iTrans('Any') + '</option>';
  html += '<option value="Inspected">' + iTrans('Inspected') + '</option>';
  html += '<option value="InspectedPreFlight">' + iTrans('Pre-Flight Inspected') + '</option>';
  html += '<option value="Pending">' + iTrans('Pending') + '</option>';
  html += '<option value="Defective">' + iTrans('Defective') + '</option>';
  html += '<option value="No History">' + iTrans('No Inspection History') + '</option>';
  html += '</select>';
  html += '</div>';
  $('#RPT_PageControl').html(html);
  $('#RPT_PageControl').enhanceWithin();
}

function ADMIN_filterAssetArray() {
  $('#ADMIN_FilterAssetHLDR').show();
  $('#Report_Content').html("<h3 class='limit-table'>" + iTrans('Populating Results') + '</h3>');
  if (filterAssetArray_Timeout != null) clearTimeout(filterAssetArray_Timeout);
  filterAssetArray_Timeout = setTimeout(function () {
    filterAssetArray_Timeout = null;
    var keywords = $('#ADMIN_FilterAssetTXT').val().toLowerCase();
    var Category = $('#ADMIN_AssetCategorySelector').val();
    var Status = $('#ADMIN_AssetStatusSelector').val();
    for (var i in Assets) {
      Assets[i].ARR_INDEX = i;
    }

    Assets_Filtered = ADMIN_FilterAssetTypeArray(Assets, keywords, Category, Status);
    Report_Assets(Assets_Filtered);
  }, 500);
}

function ADMIN_FilterAssetTypeArray(OrigArray, keywords, Category, Status) {
  var Assets_Filtered = OrigArray.filter(function (item) {
    var keywordMatch = false;
    var CategoryMatch = false;
    var StatusMatch = false;
    if (item.Status == null) item.Status = iTrans('No History');
    if (keywords != '') {
      if (
        item.Name.toLowerCase().indexOf(keywords.toLowerCase()) > -1 ||
        item.SN.toLowerCase().indexOf(keywords.toLowerCase()) > -1
      )
        keywordMatch = true;
    } else keywordMatch = true;
    if (Category != 'null') {
      if (Category == item.Category) CategoryMatch = true;
    } else CategoryMatch = true;
    if (Status != '') {
      if (Status == item.Status) StatusMatch = true;
    } else StatusMatch = true;
    return keywordMatch && CategoryMatch && StatusMatch;
  });

  return Assets_Filtered;
}
function ADMIN_clearAssetFilter() {
  $('#ADMIN_FilterAssetTXT').val('');
  ADMIN_filterAssetArray();
}
function ADMIN_nextAssetPage(curpage) {
  curpage++;
  Report_Assets(Assets_Filtered, curpage);
}
function ADMIN_prevAssetPage(curpage) {
  if (curpage != 1) {
    curpage--;
    Report_Assets(Assets_Filtered, curpage);
  }
}
function Report_Assets(Assets, page = 1) {
  $('#Title_Report_Name').html(iTrans('Assets Report'));

  $('#Report_Content').html('');
  if (Assets.length > 0) {
    console.log('populating assets');
    var pageBTNS = '';
    if (Assets.length > 100) {
      var min = parseInt(page + '01') - 100;
      var max = page * 100;
      var showNext = true;
      if (max > Assets.length) {
        max = Assets.length;
        showNext = false;
      }
      pageBTNS =
        "<h4 class='limit-table'>" +
        iTrans('Showing') +
        " <button onClick='ADMIN_prevAssetPage(" +
        page +
        ");' data-role='none' style='padding: 0px 8px;border-radius: 10%; background-color: #f1f1f1;'>« " +
        iTrans('prev') +
        '</button> ' +
        min +
        ' - ' +
        max;
      if (showNext)
        pageBTNS +=
          " <button onClick='ADMIN_nextAssetPage(" +
          page +
          ");' data-role='none' style='padding: 0px 8px;border-radius: 10%; background-color: #f1f1f1;'>" +
          iTrans('next') +
          ' »</button>';
      pageBTNS += ' ' + iTrans('of') + ' ' + Assets.length + ' ' + iTrans('matching items') + '</h4>';
    } else {
      pageBTNS = "<h4 class='limit-table'>Showing " + Assets.length + ' ' + iTrans('matching items') + '</h4>';
    }
    var AssetsHTML =
      '<div style="overflow: auto"><table id="AdminAssets_Table" style="width: 100%">' +
      '<thead>' +
      '<tr>' +
      '<th data-priority="0">' +
      iTrans('Name') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('Category') +
      '</th>' +
      '<th data-priority="0">' +
      iTrans('SN') +
      '</th>' +
      '<th data-priority="1">' +
      iTrans('Date Info') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Status') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Updated By') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Location') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Updated') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Last Inspection') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('Inspected By') +
      '</th>' +
      '<th data-priority="2">' +
      iTrans('History') +
      '</th>' +
      '</tr>' +
      '</thead>' +
      '<tbody>';
    AssetsHTML +=
      '<tr class="static">' +
      '<td colspan="4" style="font-weight: bold; padding: 2px 0;">' +
      iTrans('Asset Information') +
      '</td>' +
      '<td colspan="4" style="font-weight: bold; padding: 2px 0;">' +
      iTrans('Current Status') +
      '</td>' +
      '<td colspan="3" style="font-weight: bold; padding: 2px 0;">' +
      iTrans('Inspection Status') +
      '</td>' +
      '</tr>';
    var count = 0;
    for (var i in Assets) {
      if (i < min - 1) continue;
      else count++;
      var Asset = Assets[i];
      const assetInspection = AssetInspected?.find((inspected) => inspected.PrimaryKey === Asset.PrimaryKey);
      if (Asset.Category == filterAssets || filterAssets == 'null') {
        const By = AssetStaffList.find((staff) => staff.PrimaryKey === Asset.UpdatedBy)?.chatname ?? 'Unknown';
        let By2 =
          AssetStaffList?.find((staff) => staff.PrimaryKey === assetInspection?.UpdatedBy)?.chatname ?? 'Unknown';

        if (assetInspection.UpdatedBy == 0) {
          By2 = 'System';
        }

        var time = null;
        if (Asset.Updated !== null) {
          time = getLocalDateTime(Asset.Updated);
          time = time.text;
        }
        var time2 = null;
        if (Asset.Datetime !== null) {
          time2 = getLocalDateTime(Asset.Datetime);
          time2 = time2.text;
        }
        var statusClass = '';
        if (Asset.Status == 'Pending') statusClass = 'yellowRecord';
        if (Asset.Status == 'Defective') statusClass = 'redRecord';
        if (Asset.Status == 'Missing') statusClass = 'orangeRecord';
        var Idate = Report_InspectDate(
          assetInspection?.Datetime,
          Asset.ExpiryOn,
          Asset.Inspection,
          Asset.Interval,
          Asset.IntervalOn
        );
        var dateInfo = '<table style="margin: 0;"><tr><td>' + iTrans('Changed') + '</td><td>' + time + '</td></tr>';
        var Expires = getLocalDateTime(Asset.Inspection);
        Expires = Expires.text;
        if (Asset.ExpiryOn == 1) dateInfo += '<tr><td>' + iTrans('Expires') + '</td><td>' + Expires + '</td></tr>';
        var Man = getLocalDateTime(Asset.Manufactured);
        Man = Man.text;
        dateInfo += '<tr><td>' + iTrans('Manufactured') + '</td><td>' + Man + '</td></tr>';
        var Pur = getLocalDateTime(Asset.Purchased);
        Pur = Pur.text;
        dateInfo += '<tr><td>' + iTrans('Purchased') + '</td><td>' + Pur + '</td></tr></table>';
        points = [];
        points.push(parseFloat(Asset.Long));
        points.push(parseFloat(Asset.Lat));
        features = [];
        features.push(MakeFeature(points, 'Point', '#ff0000', 'marker'));
        //var MapHTML = getMapURL(features, 400, 300, Asset.Lat, Asset.Long, 8);
        var MapBTN = '';
        if (Asset.Long != null && Asset.Lat != null) {
          MapBTN =
            '<button class="action-btn" value="' +
            Asset.ARR_INDEX +
            '"><a href="http://maps.google.com/maps?saddr=Current+Location&daddr=' +
            Asset.Lat +
            ',' +
            Asset.Long +
            ' " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>';
          if (IOS) {
            MapBTN =
              '<button class="action-btn" value="' +
              Asset.ARR_INDEX +
              '" ><a href="http://maps.apple.com/?saddr=Current+Location&daddr=' +
              Asset.Lat +
              ',' +
              Asset.Long +
              ' " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>';
          }
        }
        if (Asset.Status != 'No History') {
          AssetsHTML +=
            '<tr><td>' +
            Asset.Name +
            '</td><td>' +
            Asset.Category +
            '</td><td>' +
            Asset.SN +
            '</td><td>' +
            dateInfo +
            '</td><td class="' +
            statusClass +
            '">' +
            Asset.Status +
            '</td><td>' +
            By +
            '</td><td>' +
            Asset.Location +
            '<br />Lat: ' +
            Asset.Lat +
            '<br />Long: ' +
            Asset.Long +
            '</td><td>' +
            time2 +
            '</td><td class="' +
            Idate.Class +
            '">' +
            Idate.LocalString +
            '<br />' +
            Idate.Text +
            '</td><td>' +
            By2 +
            '</td><td><div class="vertical-center-container"><button id="AssetH-' +
            Asset.ARR_INDEX +
            '" class="action-btn" onClick="getRPTAssetHistory(this);"><img src="./images/inspect.svg" /></button>' +
            MapBTN +
            '</div></td></tr>';
        } else {
          AssetsHTML +=
            '<tr><td>' +
            Asset.Name +
            '</td><td>' +
            Asset.Category +
            '</td><td>' +
            Asset.SN +
            '</td><td>' +
            dateInfo +
            '</td><td class="' +
            statusClass +
            '">' +
            Asset.Status +
            '</td><td style="text-align: center;">-</td><td style="text-align: center;">-</td><td style="text-align: center;">-</td><td class="' +
            Idate.Class +
            '">' +
            Idate.LocalString +
            '<br />' +
            Idate.Text +
            '</td><td>' +
            By +
            '</td><td style="text-align: center;">-</td></tr>';
        }
      }
      if (count == 100) break;
    }
    AssetsHTML += '</tbody></table></div>';
    AssetsHTML = pageBTNS + AssetsHTML + pageBTNS;
    $('#Report_Content').html(AssetsHTML);
    $('#Report_Content').enhanceWithin();
    AddTableSorter('AdminAssets_Table', null, null);
  } else {
    $('#Report_Content').html("<h3 class='limit-table'>" + iTrans('No Assets') + '</h3>');
  }
  //$("#Report_Content").html("<h3>DataCallback</h3>");
}

function Report_InspectDate(recdate, ExpiryOn, Inspection, Interval, IntervalOn) {
  var now = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(moment.utc());
  var future = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(moment.utc());
  if (Interval != 0) future.set('month', future.month() + Interval);
  var date = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(recdate);
  var Diff = {};
  Diff.Class = '';
  if (Inspection != null) {
    Inspection = moment.utc(Inspection);
    Inspection = VueBridge.dateTimeUtils.convertUtcToUsersTimezone(Inspection);
  }
  if (date != null) {
    if (recdate.split(' ')[1] != undefined) {
      Diff.LocalString = date.format(
        `${VueBridge.dateTimeUtils.formattableDateFormats[0]}` + '<br />' + `${VueBridge.dateTimeUtils.timeFormat}`
      );
    } else {
      Diff.LocalString = date.format(`${VueBridge.dateTimeUtils.formattableDateFormats[0]}`);
    }

    Diff.Datetime = date;
    Diff.Expired = false;
    Diff.Inspect = false;
    if (IntervalOn == 0) {
      if (now > date) {
        const diff = Math.round(now.diff(date, 'days', true));

        Diff.Text = iTrans('Inspected ') + diff + ' Days ago';
        Diff.Diff = diff;
        Diff.Inspect = false;
      }
      if (date >= now) {
        //Diff.Text = Math.round((date-now)/(1000*60*60*24)) + " Days";
        Diff.Text = iTrans('No Inspection Interval');
        Diff.Diff = Math.round(date.diff(now, 'days', true));
        Diff.Inspect = false;
      }
    } else {
      if (now > future) {
        const diff = Math.round(now.diff(future, 'days', true));

        Diff.Text = iTrans('Intervals On') + '<br />' + iTrans('Due') + ' ' + diff + ' ' + iTrans('Days ago');
        Diff.Diff = diff;
        Diff.Inspect = true;
        Diff.Class = 'orangeRecord';
      }
      if (future >= now) {
        const diff = Math.round(future.diff(now, 'days', true));

        Diff.Text = iTrans('Intervals On') + '<br />' + iTrans('Inspect In') + ' ' + diff + ' ' + iTrans('Days');
        Diff.Diff = diff;
        if (Diff.Diff == 0) Diff.Class = 'orangeRecord';
      }
    }
    if (ExpiryOn == 1) {
      Diff.Text += '<br />' + iTrans('Expiry On');
      if (Inspection == null) Diff.Text += '<br />' + iTrans('Expiry Date Not Set!');
      else {
        if (now > Inspection) {
          const diff = Math.round(now.diff(Inspection, 'days', true));

          Diff.Text += '<br />' + iTrans('Expired') + ' ' + diff + ' ' + iTrans('Days ago');
          Diff.Diff = diff;
          Diff.Expired = true;
          Diff.Class = 'redRecord';
        }
        if (Inspection >= now) {
          const diff = Math.round(Inspection?.diff(now, 'days', true) ?? 0);

          Diff.Text += '<br />' + iTrans('Expires In') + ' ' + diff + ' ' + iTrans('Days');
          Diff.Diff = diff;
        }
      }
    }
    if (Diff.Diff != undefined) {
      return Diff;
    } else {
      Diff.Diff = 0;
      Diff.Text = iTrans('Unknown');
      Diff.Expired = null;
      return Diff;
    }
  } else {
    Diff.Diff = 0;
    Diff.Text = iTrans('Never Inspected');
    Diff.Expired = null;
    Diff.LocalString = iTrans('Unknown');
    Diff.Datetime = null;
    return Diff;
  }
}

function getRPTAssetHistory(e) {
  $('#ADMIN_FilterAssetHLDR').hide();
  var index = e.id;
  index = index.split('-');
  index = index[1];
  AssetIndex = index;
  var Name = Assets[index].Name;
  var SN = Assets[index].SN;
  $.getJSON(
    BASEURL + 'menu-Administration-Reports-AJAX.php?report=getAssetHistory&Asset_PK=' + Assets[index].PrimaryKey,
    function (json_data) {
      AssetHistory = json_data;
      var Usage = 0;
      var eUsage = 0;
      var eCycles = 0;
      var Cycles = 0;
      $('#Report_Content').html('');
      if (AssetHistory.length > 0) {
        var AssetHTML =
          "<button id='RPT_ExitAsset' data-icon='back' data-mini='true' onClick='ADMIN_filterAssetArray();'>" +
          iTrans('Return to Asset List') +
          '</button>' +
          '<h2 id="assetUsage" class="limit-table">' +
          iTrans('Total Accumulated Usage: Calculating') +
          '...</h2>' +
          '<div style="width: 100%; overflow: auto"><table id="AssetHistory_Table" data-filter="true">' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="0">' +
          iTrans('Status') +
          '</th>' +
          '<th data-priority="0">' +
          iTrans('Comment') +
          '</th>' +
          '<th data-priority="0">' +
          iTrans('Details') +
          '</th>' +
          '<th data-priority="0">' +
          iTrans('On') +
          '</th>' +
          '<th data-priority="1">' +
          iTrans('By') +
          '</th>' +
          '<th data-priority="2">' +
          iTrans('Picture') +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody>';

        const dateFormat = `${VueBridge.dateTimeUtils.formattableDateFormats[0]} ${VueBridge.dateTimeUtils.timeFormat}`;

        for (var i in AssetHistory) {
          H = AssetHistory[i];
          var By2 = 'Unknown';
          if (H.UpdatedBy == 0) By2 = iTrans('System');
          for (s in AssetStaffList) if (AssetStaffList[s].PrimaryKey == H.UpdatedBy) By2 = AssetStaffList[s].chatname;
          //AssetHTML += '<tr><td>'+H.Status+'</td><td>'+H.Comment+'</td><td>'+H.Location+'</td><td>'+H.Datetime+'</td><td>'+H.UpdatedBy+'</td><td><img src="data:image/png;base64,'+H.Picture+'" width="200" height="175"/></td></tr>';
          var ImageSrc = iTrans('No Image');
          var MapBTN = '';
          if (H.Long != null && H.Lat != null) {
            MapBTN =
              '<button class="action-btn" value="' +
              i +
              '" ><a href="http://maps.google.com/maps?saddr=Current+Location&daddr=' +
              H.Lat +
              ',' +
              H.Long +
              ' " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>';
            if (IOS) {
              MapBTN =
                '<button class="action-btn" value="' +
                i +
                '" ><a href="http://maps.apple.com/?saddr=Current+Location&daddr=' +
                H.Lat +
                ',' +
                H.Long +
                ' " data-ajax="false" target="_blank" ><img src="./images/ffmapIcon.svg" /></a></button>';
            }
          }
          var Location =
            "<table class='last-td-fil'><tr><td><div class='vertical-center-container'>Location  " +
            MapBTN +
            '</div></td><td>' +
            H.Location +
            '</td></tr>';
          Location += '<tr><td>' + iTrans('Latitude') + '</td><td>' + H.Lat + '</td></tr>';
          Location += '<tr><td>' + iTrans('Longitude') + '</td><td>' + H.Long + '</td></tr>';
          Location +=
            '<tr><td>' +
            iTrans('Usage Tracking') +
            '</td><td>' +
            (H.estimated == 1 ? 'Automated' : 'Actual') +
            '</td></tr>';
          Location += '<tr><td>' + iTrans('Hrs') + '</td><td>' + H.Usage + '</td></tr>';
          Location += '<tr><td>' + iTrans('Cycles') + '</td><td>' + H.cycles + '</td></tr>';
          Location += '</table>';
          var IUsage = Math.round(parseFloat(H.Usage) * 10) / 10;
          var Icycles = H.cycles;
          if (H.estimated == 1) {
            eUsage = eUsage + IUsage;
            eCycles = eCycles + Icycles;
          } else {
            Usage = Usage + IUsage;
            Cycles = Cycles + Icycles;
          }

          var lineClass = '';
          if (H.Status == 'Pending') lineClass = 'yellowRecord';
          if (H.Status == 'Defective') lineClass = 'redRecord';
          if (H.Status == 'Missing') lineClass = 'orangeRecord';
          if (H.Picture_FileName !== '')
            ImageSrc =
              '<img src="' +
              BASEURL +
              'menu-Assets-AJAX.php?action=getPicture&PK=' +
              H.PrimaryKey2 +
              '&table=History" width="200" height="175"/>';
          AssetHTML +=
            '<tr class="' +
            lineClass +
            '"><td>' +
            H.Status +
            '</td><td>' +
            H.Comment +
            '</td><td>' +
            Location +
            '</td><td>' +
            VueBridge.dateTimeUtils.convertUtcToUsersTimezone(H.Datetime)?.format(dateFormat) +
            '</td><td>' +
            By2 +
            '</td><td>' +
            ImageSrc +
            '</td></tr>';
        }
        AssetHTML += '</tbody></table></div>';
        $('#Report_Content').html(AssetHTML);
        $('#Report_Content').enhanceWithin();
        for (i in AssetHistory) $('#AssetMapBTN-' + i).click(getAssetMapURL);
        eUsage = round10(eUsage);
        Usage = round10(Usage);
        var UsageHTML = iTrans('Total Automated Hrs') + ': ' + eUsage + ' Hrs<br />';
        UsageHTML += iTrans('Total Automated Cycles') + ': ' + eCycles + '<br />';

        UsageHTML += iTrans('Total Actual Hrs') + ': ' + Usage + ' Hrs<br />';
        UsageHTML += iTrans('Total Actual Cycles') + ': ' + Cycles + '<br />';

        $('#assetUsage').html(iTrans('Name') + ': ' + Name + '<br />S/N: ' + SN + '<br />' + UsageHTML);
      } else {
        $('#Report_Content').html(
          "<div class='limit-table'><h3>" +
            iTrans('No history for this asset') +
            "</h3><button id='RPT_ExitAsset' data-icon='back' data-mini='true' onClick='ADMIN_filterAssetArray();'>" +
            iTrans('Return to Asset List') +
            '</button></div>'
        );
      }
      $('#Report_Content').enhanceWithin();
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get History');
    AJAXFAIL(error);
  });
}

function getAssetMapURL() {
  //console.log(this.name);

  var index = this.id;
  index = index.split('-');
  index = index[1];
  console.log('GetMapURL');
  $('#AssetMap-' + index).html("<img src='" + this.name + "' />");
}
