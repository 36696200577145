/**
 * Class for static pages that do not require loading
 * @param {pSAMA_AbstractPage} _backToPage
 * @param {string}             _selectorID
 * @param {string}             _title
 * @param {string|null}       [_icon]
 * @param {string|null}       [_moduleName]
 */

class pSAMA_GenericView extends pSAMA_AbstractPage {
  constructor(_selectorID, _backToPage, _title, _icon, _moduleName) {
    super(_selectorID, _backToPage);
    this.header = new cSAMA_PageHeader(_title, this.dom.page, _icon, _moduleName);
  }

  EnterPage() {
    SAMA.Navigator.FinishedLoading(this.header);
  }
}
