class cSAMA_FilterReports extends cSAMA_AbstractFilter {
  constructor(_view) {
    super({
      view: _view,
      filterTemplate: SAMA.Templates.tpl.reports.filterForm,
      orderTerms: {
        rep_riskScoreCurrent: null,
        rep_riskScoreInitial: null,
        rep_dateTime: null,
      },
      showTerms: {
        rep_dateTime: null,
        rep_riskScoreCurrent: null,
        rep_riskScoreInitial: null,
        rep_isProactive: null,
        rep_summary: null,
        rep_catPk: null,
        dep_name: null,
        rep_keywords: null,
      },
    });

    let dateInputs = this.dom.filters.find('input[type="date"]').toArray();
    for (let di = 0; di < dateInputs.length; di++) {
      cSAMA_GeneralInputs.Date($(dateInputs[di]), true, true);
    }
  }

  /**
   *
   * @param {object} _inputValues
   */
  GenerateTerms(_inputValues) {
    // ------------------
    // Show by Report Type
    // ------------------
    switch (_inputValues.type) {
      case 0:
        this.showTerms.rep_isProactive = null;
        break;
      case 1:
        // Show if proactive
        this.showTerms.rep_isProactive = new cSAMA_FilterTerm.Show.Boolean();
        break;
      case 2:
        // Show if not proactive
        this.showTerms.rep_isProactive = new cSAMA_FilterTerm.Show.Boolean(true);
        break;
    }

    // ------------------
    // Show by Summary
    // ------------------
    if (_inputValues.summary.length > 0) {
      this.showTerms.rep_summary = new cSAMA_FilterTerm.Show.StringContains(_inputValues.summary);
    } else {
      this.showTerms.rep_summary = null;
    }

    // ------------------
    // Show by Date
    // ------------------
    if (_inputValues.dateFrom !== false && _inputValues.dateTo !== false) {
      this.showTerms.rep_dateTime = new cSAMA_FilterTerm.Show.BetweenDates(_inputValues.dateFrom, _inputValues.dateTo);
    } else {
      this.showTerms.rep_dateTime = null;
    }

    // ------------------
    // Show by Department
    // ------------------
    if (_inputValues.department.length > 0) {
      this.showTerms.dep_name = new cSAMA_FilterTerm.Show.StringContains(_inputValues.department);
    } else {
      this.showTerms.dep_name = null;
    }

    // ------------------
    // Show by Category
    // ------------------
    if(_inputValues.reportCategory > 0){
      this.showTerms.rep_catPk = new cSAMA_FilterTerm.Show.Exactly(_inputValues.reportCategory);
    }else{
      this.showTerms.rep_catPk = null;
    }

    // ------------------
    // Order by Date
    // ------------------
    switch (_inputValues.sortDate) {
      case 0:
        // Off
        this.orderTerms.rep_dateTime = null;
        break;

      case 1:
        // New First
        this.orderTerms.rep_dateTime = new cSAMA_FilterTerm.Order.Date();
        break;

      case 2:
        // Old First
        this.orderTerms.rep_dateTime = new cSAMA_FilterTerm.Order.Date(true);
        break;
    }

    // ------------------
    // Order by risk
    // ------------------
    let riskOrderFn = null;
    this.orderTerms.rep_riskScoreCurrent = null;
    this.orderTerms.rep_riskScoreInitial = null;

    // Is the Sort by Risk selector on 'Off'?
    if (_inputValues.sortRiskType > 0) {
      switch (_inputValues.sortRisk) {
        case 0:
          // High Risk first
          riskOrderFn = new cSAMA_FilterTerm.Order.GreaterThan();
          break;

        case 1:
          // Low Risk First
          riskOrderFn = new cSAMA_FilterTerm.Order.GreaterThan(true);
          break;
      }

      switch (_inputValues.sortRiskType) {
        case 1:
          // Current Risk
          this.orderTerms.rep_riskScoreCurrent = riskOrderFn;
          break;
        case 2:
          // Initial Risk
          this.orderTerms.rep_riskScoreInitial = riskOrderFn;
          break;
      }
    }
  }

  GetReportCategory(){
    const getCategoryReq = new cSAMA_Request('ReportCategory', 'getData');

    cSAMA_Request.Dispatch({
      name: 'GetReportCategories',
      reqs: getCategoryReq,
      clientErrorCode: 'SAMA 34 22 CC EE',
      fnSuccess: (_r) => {
        this.AppendCategoryFilter(_r[0]);
      },
      goBackOnError: false,
    });
  }

  AppendCategoryFilter(repCategories)
  {
    const reportCatSel = $('#reportCategory');
    const reportCatVal = reportCatSel.val();

    reportCatSel.empty().append($('<option>', {
      value: 0,
      text : 'Any'
    }));

    repCategories.forEach(category =>
    {
      reportCatSel.append($('<option>', {
        value: category.rc_primaryKey,
        text : category.rc_name
      }));
    });

    if (reportCatVal === null)
    {
      reportCatSel.val(0);
    }
    else
    {
      reportCatSel.val(reportCatVal);
    }
  }
}
