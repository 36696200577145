function getOnlineRasterLayer() {
  return {
    id: 'ONLINE',
    type: 'raster',
    source: 'ONLINE',
    minzoom: 0,
    layout: {
      visibility: 'visible',
    },
    paint: {},
  };
}
function addStandardLayers(Layers) {
  var StandardLayers = [
    {
      id: 'RasterBefore',
      type: 'background',
      minzoom: 13,
      paint: {
        'background-color': '#FFFFFF',
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'TopLevelOverview',
      type: 'background',
      minzoom: 13,
      paint: {
        'background-color': '#FFFFFF',
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'CustomLayerBefore',
      type: 'background',
      minzoom: 13,
      paint: {
        'background-color': '#FFFFFF',
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'NOTAMS',
      type: 'fill-extrusion',
      source: 'NOTAMS',
      'source-layer': 'notams',
      maxzoom: 16,
      layout: {
        visibility: 'none',
      },
      paint: {
        'fill-extrusion-color': '#ff0000',

        'fill-extrusion-height': {
          // Get fill-extrusion-height from the source 'height' property.
          property: 'valDistVerUpper',
          type: 'identity',
        },
        'fill-extrusion-base': {
          // Get fill-extrusion-base from the source 'base_height' property.
          property: 'valDistVerLower',
          type: 'identity',
        },
        // Make extrusions slightly opaque for see through indoor walls.
        'fill-extrusion-opacity': 0.7,
      },
    },
    {
      id: 'Obstacles3d',
      type: 'fill-extrusion',
      source: 'Obstacles',
      'source-layer': 'obstacles3d',
      layout: {
        visibility: 'none',
      },
      paint: {
        'fill-extrusion-color': ['get', 'color'],
        'fill-extrusion-height': ['*', ['get', 'Height'], 0.3048],
        'fill-extrusion-opacity': 0.8,
      },
    },
    {
      id: 'Obstacles',
      type: 'symbol',
      source: 'Obstacles',
      'source-layer': 'obstacles3d',
      layout: {
        'icon-image': '{icon}',
        'icon-size': { type: 'identity', property: 'size' },
        visibility: 'none',
      },
      paint: {},
    },
    {
      id: 'Airports-Balloonport',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'Type', 'balloonport']],
      layout: {
        'icon-offset': [0, 0],
        'icon-size': 1,
        'icon-image': 'balloonport',
        visibility: 'none',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Airports-Heliport',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'Type', 'heliport']],
      layout: {
        'icon-offset': [0, 0],
        'icon-size': 1,
        'icon-image': 'heliport',
        visibility: 'none',
        'icon-rotation-alignment': 'map',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Airports-Seaplane',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'Type', 'seaplane_base']],
      layout: {
        'icon-offset': [0, 0],
        'icon-size': 1,
        'icon-image': 'seaplane_base',
        visibility: 'none',
        'icon-rotation-alignment': 'map',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Airports-Small',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      maxzoom: 22,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'Type', 'small_airport']],
      layout: {
        'icon-image': 'small_airport-{FC}',
        'icon-size': 1,
        'icon-offset': [0, 0],
        visibility: 'none',
        'icon-rotation-alignment': 'map',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Airports-Medium',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'Type', 'medium_airport']],
      layout: {
        'icon-image': 'medium_airport-{FC}',
        'icon-size': 1,
        'icon-offset': [0, 0],
        visibility: 'none',
        'icon-rotation-alignment': 'map',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Airports-Large',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 2,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'Type', 'large_airport']],
      layout: {
        'icon-image': 'large_airport-{FC}',
        'icon-size': 1,
        'icon-rotate': 0,
        visibility: 'none',
        'icon-rotation-alignment': 'map',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Weather-Small-Gusts',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'small_airport']]],
      layout: {
        'icon-image': '{gustICON}',
        'icon-size': 1,
        'icon-rotate': {
          property: 'barbDir',
          type: 'identity',
        },
        'icon-offset': [-4, 32],
        'icon-rotation-alignment': 'map',
        visibility: 'none',
        'icon-padding': 0,
        'icon-ignore-placement': true,
      },
      paint: {},
    },
    {
      id: 'Weather-Small-Wind',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'small_airport']]],
      layout: {
        'icon-image': '{windICON}',
        'icon-size': 1,
        'icon-rotate': {
          property: 'barbDir',
          type: 'identity',
        },
        'icon-offset': [-4, 32],
        'icon-rotation-alignment': 'map',
        visibility: 'none',
        'icon-padding': 0,
        'icon-ignore-placement': true,
      },
      paint: {},
    },
    {
      id: 'Weather-Medium-Gusts',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'medium_airport']]],
      layout: {
        'icon-image': '{gustICON}',
        'icon-size': 1,
        'icon-rotate': {
          property: 'barbDir',
          type: 'identity',
        },
        'icon-offset': [-4, 32],
        'icon-rotation-alignment': 'map',
        visibility: 'none',
        'icon-padding': 0,
        'icon-ignore-placement': true,
      },
      paint: {},
    },
    {
      id: 'Weather-Medium-Wind',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'medium_airport']]],
      layout: {
        'icon-image': '{windICON}',
        'icon-size': 1,
        'icon-rotate': {
          property: 'barbDir',
          type: 'identity',
        },
        'icon-offset': [-4, 32],
        'icon-rotation-alignment': 'map',
        visibility: 'none',
        'icon-padding': 0,
        'icon-ignore-placement': true,
      },
      paint: {},
    },
    {
      id: 'Weather-Large-Gusts',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 3,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'large_airport']]],
      layout: {
        'icon-image': '{gustICON}',
        'icon-size': 1,
        'icon-rotate': {
          property: 'barbDir',
          type: 'identity',
        },
        visibility: 'none',
        'icon-padding': 0,
        'icon-offset': [-4, 32],
        'icon-rotation-alignment': 'map',
        'icon-ignore-placement': true,
      },
      paint: {},
    },
    {
      id: 'Weather-Large-Wind',
      type: 'symbol',
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 3,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'large_airport']]],
      layout: {
        'icon-image': '{windICON}',
        'icon-size': 1,
        'icon-rotate': {
          property: 'barbDir',
          type: 'identity',
        },
        visibility: 'none',
        'icon-padding': 0,
        'icon-offset': [-4, 32],
        'icon-rotation-alignment': 'map',
        'icon-ignore-placement': true,
      },
      paint: {},
    },
    {
      id: 'Weather-Small-Cover',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 7,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'small_airport']]],
      layout: {
        'icon-image': '{cover}-{FC}',
        'icon-size': 1,
        'icon-offset': [0, 0],
        visibility: 'none',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Weather-Medium-Cover',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'medium_airport']]],
      layout: {
        'icon-image': '{cover}-{FC}',
        'icon-size': 1,
        'icon-offset': [0, 0],
        visibility: 'none',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'Weather-Large-Cover',
      type: 'symbol',
      metadata: {},
      source: 'Airports',
      'source-layer': 'airports',
      minzoom: 3,
      filter: ['all', ['==', '$type', 'Point'], ['all', ['!=', 'FC', 0], ['==', 'Type', 'large_airport']]],
      layout: {
        'icon-image': '{cover}-{FC}',
        'icon-size': 1,
        'icon-rotate': 0,
        visibility: 'none',
        'icon-padding': 0,
      },
      paint: {},
    },
    {
      id: 'USclassB',
      type: 'fill-extrusion',
      source: 'US_Airspace',
      'source-layer': 'class_b',
      paint: {
        'fill-extrusion-color': 'GOLDENROD',

        'fill-extrusion-height': {
          property: 'HIGHALT',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'LOWALT',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.4,
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'Airports-Apr',
      type: 'fill',
      metadata: { 'mapbox:group': '3a504fcce8847965eadba151fd22e0f7' },
      source: 'Airports',
      'source-layer': 'RWYA',
      minzoom: 8,
      layout: {
        visibility: 'none',
      },
      paint: {
        'fill-color': 'hsla(39, 100%, 39%, 0.3)',
        'fill-outline-color': '#c78100',
      },
    },
    {
      id: 'Airports-Apr-Labels',
      type: 'symbol',
      metadata: { 'mapbox:group': '3a504fcce8847965eadba151fd22e0f7' },
      source: 'Airports',
      'source-layer': 'RWYA',
      layout: {
        'text-field': ['to-string', ['get', 'Rwy']],
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
        visibility: 'none',
        'text-pitch-alignment': 'map',
      },
      paint: {
        'text-color': '#ffffff',
        'text-halo-color': '#c78100',
        'text-halo-width': 1,
        'text-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 9, 1],
      },
    },
    {
      id: 'USclassC',
      type: 'fill-extrusion',
      source: 'US_Airspace',
      'source-layer': 'class_c',
      paint: {
        'fill-extrusion-color': 'MAROON',

        'fill-extrusion-height': {
          property: 'HIGHALT',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'LOWALT',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'CAclassC',
      type: 'fill-extrusion',
      source: 'CA_Airspace',
      'source-layer': 'Airspace',
      paint: {
        'fill-extrusion-color': 'MAROON',

        'fill-extrusion-height': {
          property: 'CEILING',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'FLOOR',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
      filter: ['all', ['==', 'CLASS', 'C']],
    },
    {
      id: 'USclassD',
      type: 'fill-extrusion',
      source: 'US_Airspace',
      'source-layer': 'class_d',
      paint: {
        'fill-extrusion-color': 'MIDNIGHTBLUE',

        'fill-extrusion-height': {
          property: 'HIGHALT',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'LOWALT',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'CAclassD',
      type: 'fill-extrusion',
      source: 'CA_Airspace',
      'source-layer': 'Airspace',
      paint: {
        'fill-extrusion-color': 'MIDNIGHTBLUE',

        'fill-extrusion-height': {
          property: 'CEILING',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'FLOOR',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
      filter: ['all', ['==', 'CLASS', 'D']],
    },
    {
      id: 'USclassE0',
      type: 'fill-extrusion',
      source: 'US_Airspace',
      'source-layer': 'class_e0',
      paint: {
        'fill-extrusion-color': 'YELLOWGREEN',

        'fill-extrusion-height': {
          property: 'HIGHALT',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'LOWALT',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'CAclassE',
      type: 'fill-extrusion',
      source: 'CA_Airspace',
      'source-layer': 'Airspace',
      paint: {
        'fill-extrusion-color': 'YELLOWGREEN',

        'fill-extrusion-height': {
          property: 'CEILING',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'FLOOR',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
      filter: ['all', ['==', 'CLASS', 'E']],
    },
    {
      id: 'USclassE5',
      type: 'fill-extrusion',
      source: 'US_Airspace',
      'source-layer': 'class_e5',
      paint: {
        'fill-extrusion-color': 'YELLOWGREEN',

        'fill-extrusion-height': {
          property: 'HIGHALT',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'LOWALT',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.2,
      },
      layout: {
        visibility: 'none',
      },
    },
    {
      id: 'CAclassF',
      type: 'fill-extrusion',
      source: 'CA_Airspace',
      'source-layer': 'Airspace',
      paint: {
        'fill-extrusion-color': 'RED',

        'fill-extrusion-height': {
          property: 'CEILING',
          type: 'identity',
        },
        'fill-extrusion-base': {
          property: 'FLOOR',
          type: 'identity',
        },
        'fill-extrusion-opacity': 0.5,
      },
      layout: {
        visibility: 'none',
      },
      filter: ['all', ['==', 'CLASS', 'F']],
    },
  ];
  return Layers.concat(StandardLayers);
}
function getMapboxTerrainLayers() {
  return [
    {
      id: 'background',
      type: 'background',
      layout: {},
      paint: {
        'background-color': {
          base: 1,
          stops: [
            [11, 'hsl(35, 32%, 91%)'],
            [13, 'hsl(35, 12%, 89%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'landcover_crop',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855831248.8289',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 12,
      filter: ['==', 'class', 'crop'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 62%, 81%)',
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [12, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'landcover_grass',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855831248.8289',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 12,
      filter: ['==', 'class', 'grass'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 62%, 81%)',
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [12, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'landcover_scrub',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855831248.8289',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 12,
      filter: ['==', 'class', 'scrub'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 62%, 81%)',
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [12, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'landcover_wood',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855831248.8289',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 12,
      filter: ['==', 'class', 'wood'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 62%, 81%)',
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [12, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'landcover_snow',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855831248.8289',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      filter: ['==', 'class', 'snow'],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 100%)',
        'fill-opacity': 0.2,
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'national_park',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse_overlay',
      filter: ['==', 'class', 'national_park'],
      layout: {},
      paint: {
        'fill-color': 'hsl(100, 59%, 76%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [5, 0],
            [5.5, 0.75],
            [9, 0.75],
            [10, 0.35],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'scrub',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 9,
      filter: ['==', 'class', 'scrub'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 41%, 74%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [9, 0],
            [15, 0.2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'grass',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 9,
      filter: ['==', 'class', 'grass'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 41%, 74%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [9, 0],
            [15, 0.4],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'wood',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 6,
      filter: ['==', 'class', 'wood'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 41%, 74%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [7, 0],
            [15, 0.5],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'agriculture',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 11,
      filter: ['==', 'class', 'agriculture'],
      layout: {},
      paint: {
        'fill-opacity': {
          base: 1,
          stops: [
            [11, 0],
            [14, 0.75],
          ],
        },
        'fill-color': 'hsl(75, 37%, 81%)',
        'fill-outline-color': 'hsl(75, 32%, 68%)',
      },
      interactive: true,
    },
    {
      id: 'national_park-tint-band',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'landuse_overlay',
      minzoom: 9,
      filter: ['==', 'class', 'national_park'],
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(100, 62%, 74%)',
        'line-width': {
          base: 1.4,
          stops: [
            [9, 1],
            [14, 8],
          ],
        },
        'line-offset': {
          base: 1.4,
          stops: [
            [9, 0],
            [14, -2.5],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [9, 0],
            [10, 0.75],
          ],
        },
        'line-blur': 3,
      },
      interactive: true,
    },
    {
      id: 'national_park-outline',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'landuse_overlay',
      minzoom: 9,
      filter: ['==', 'class', 'national_park'],
      layout: {},
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [12, 'hsl(100, 49%, 71%)'],
            [14, 'hsl(100, 40%, 67%)'],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [9, 0.75],
            [12, 1],
          ],
        },
        'line-offset': 0,
        'line-opacity': {
          base: 1,
          stops: [
            [9, 0],
            [10, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'hospital',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'hospital'],
      layout: {},
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [15.5, 'hsl(340, 37%, 87%)'],
            [16, 'hsl(340, 63%, 89%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'school',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'school'],
      layout: {},
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [15.5, 'hsl(50, 47%, 81%)'],
            [16, 'hsl(50, 63%, 84%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'park',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['all', ['!in', 'type', 'garden', 'golf_course', 'playground', 'zoo'], ['==', 'class', 'park']],
      layout: {},
      paint: {
        'fill-color': 'hsl(100, 59%, 76%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'other-green-areas',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['all', ['==', 'class', 'park'], ['in', 'type', 'garden', 'golf_course', 'playground', 'zoo']],
      layout: {},
      paint: {
        'fill-color': 'hsl(100, 59%, 81%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'glacier',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 9,
      filter: ['==', 'class', 'glacier'],
      layout: {},
      paint: {
        'fill-color': 'hsl(196, 71%, 93%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [9, 0],
            [10, 0.5],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'pitch',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'pitch'],
      layout: {},
      paint: {
        'fill-color': 'hsl(100, 57%, 72%)',
      },
      interactive: true,
    },
    {
      id: 'pitch-line',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 15,
      filter: ['==', 'class', 'pitch'],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-color': 'hsl(75, 57%, 84%)',
      },
      interactive: true,
    },
    {
      id: 'cemetery',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'cemetery'],
      layout: {},
      paint: {
        'fill-color': 'hsl(75, 37%, 81%)',
      },
      interactive: true,
    },
    {
      id: 'industrial',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'industrial'],
      layout: {},
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [15.5, 'hsl(230, 15%, 86%)'],
            [16, 'hsl(230, 29%, 89%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'sand',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'sand'],
      layout: {},
      paint: {
        'fill-color': 'hsl(60, 46%, 87%)',
      },
      interactive: true,
    },
    {
      id: 'contour-line',
      type: 'line',
      metadata: {
        'mapbox:group': '1444933837268.9458',
      },
      source: 'ONLINE',
      'source-layer': 'contour',
      minzoom: 11,
      filter: ['!in', 'index', 10, 5],
      layout: {},
      paint: {
        'line-opacity': {
          base: 1,
          stops: [
            [11, 0.15],
            [12, 0.3],
          ],
        },
        'line-color': 'hsl(100, 100%, 20%)',
        'line-width': {
          base: 1,
          stops: [
            [13, 0.5],
            [16, 0.8],
          ],
        },
        'line-offset': {
          base: 1,
          stops: [
            [13, 1],
            [16, 1.6],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'contour-line-index',
      type: 'line',
      metadata: {
        'mapbox:group': '1444933837268.9458',
      },
      source: 'ONLINE',
      'source-layer': 'contour',
      minzoom: 11,
      filter: ['in', 'index', 10, 5],
      layout: {},
      paint: {
        'line-opacity': {
          base: 1,
          stops: [
            [11, 0.25],
            [12, 0.5],
          ],
        },
        'line-color': 'hsl(100, 100%, 20%)',
        'line-width': {
          base: 1,
          stops: [
            [13, 0.6],
            [16, 1.2],
          ],
        },
        'line-offset': {
          base: 1,
          stops: [
            [13, 0.6],
            [16, 1.2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'hillshade_highlight_bright',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855815295.714',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 18,
      filter: ['==', 'level', 94],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 100%)',
        'fill-opacity': {
          stops: [
            [15, 0.15],
            [18, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_highlight_med',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855815295.714',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      filter: ['==', 'level', 90],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 100%)',
        'fill-opacity': {
          stops: [
            [15, 0.15],
            [18, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_faint',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855815295.714',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 17,
      filter: ['==', 'level', 89],
      layout: {},
      paint: {
        'fill-color': 'hsl(56, 59%, 22%)',
        'fill-opacity': {
          stops: [
            [15, 0.07],
            [17, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_med',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855815295.714',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      filter: ['==', 'level', 78],
      layout: {},
      paint: {
        'fill-color': 'hsl(56, 59%, 22%)',
        'fill-opacity': {
          stops: [
            [15, 0.07],
            [17, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_dark',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855815295.714',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      filter: ['==', 'level', 67],
      layout: {},
      paint: {
        'fill-color': 'hsl(56, 59%, 22%)',
        'fill-opacity': {
          stops: [
            [15, 0.08],
            [17, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_extreme',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855815295.714',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 17,
      filter: ['==', 'level', 56],
      layout: {},
      paint: {
        'fill-color': 'hsl(56, 59%, 22%)',
        'fill-opacity': {
          stops: [
            [15, 0.08],
            [17, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'waterway-river-canal-shadow',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'waterway',
      minzoom: 8,
      filter: ['in', 'class', 'canal', 'river'],
      layout: {
        'line-cap': {
          base: 1,
          stops: [
            [0, 'butt'],
            [11, 'round'],
          ],
        },
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(215, 84%, 69%)',
        'line-width': {
          base: 1.3,
          stops: [
            [8.5, 0.4],
            [20, 8],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [8, 0],
            [8.5, 1],
          ],
        },
        'line-translate': {
          base: 1.2,
          stops: [
            [7, [0, 0]],
            [16, [-1, -1]],
          ],
        },
        'line-translate-anchor': 'viewport',
      },
      interactive: true,
    },
    {
      id: 'waterway-river-canal',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'waterway',
      minzoom: 8,
      filter: ['in', 'class', 'canal', 'river'],
      layout: {
        'line-cap': {
          base: 1,
          stops: [
            [0, 'butt'],
            [11, 'round'],
          ],
        },
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(205, 87%, 76%)',
        'line-width': {
          base: 1.3,
          stops: [
            [8.5, 0.4],
            [20, 8],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [8, 0],
            [8.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'waterway-small',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'waterway',
      minzoom: 13,
      filter: ['!in', 'class', 'canal', 'river'],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(205, 87%, 76%)',
        'line-width': {
          base: 1.35,
          stops: [
            [13.5, 0.4],
            [20, 3],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'water-shadow',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'water',
      layout: {},
      paint: {
        'fill-color': 'hsl(215, 84%, 69%)',
        'fill-translate': {
          base: 1.2,
          stops: [
            [7, [0, 0]],
            [16, [-1, -1]],
          ],
        },
        'fill-translate-anchor': 'viewport',
        'fill-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'water',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'water',
      layout: {},
      paint: {
        'fill-color': 'hsl(196, 80%, 70%)',
      },
      interactive: true,
    },
    {
      id: 'wetlands',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444934749452.0452',
      },
      source: 'ONLINE',
      'source-layer': 'landuse_overlay',
      filter: ['in', 'class', 'wetland', 'wetland_noveg'],
      layout: {},
      paint: {
        'fill-color': 'hsl(185, 43%, 74%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [10, 0.25],
            [10.5, 0.15],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'wetlands-pattern',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444934749452.0452',
      },
      source: 'ONLINE',
      'source-layer': 'landuse_overlay',
      filter: ['in', 'class', 'wetland', 'wetland_noveg'],
      layout: {},
      paint: {
        'fill-color': 'hsl(185, 43%, 74%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [10, 0],
            [10.5, 1],
          ],
        },
        'fill-pattern': 'wetland',
        'fill-translate-anchor': 'viewport',
      },
      interactive: true,
    },
    {
      id: 'barrier_line-land-polygon',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'land']],
      layout: {},
      paint: {
        'fill-color': 'hsl(35, 12%, 89%)',
      },
      interactive: true,
    },
    {
      id: 'barrier_line-land-line',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'land']],
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-width': {
          base: 1.99,
          stops: [
            [14, 0.75],
            [20, 40],
          ],
        },
        'line-color': 'hsl(35, 12%, 89%)',
      },
      interactive: true,
    },
    {
      id: 'aeroway-polygon',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444934828655.3389',
      },
      source: 'ONLINE',
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['!=', 'type', 'apron'], ['==', '$type', 'Polygon']],
      layout: {},
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [15, 'hsl(230, 23%, 82%)'],
            [16, 'hsl(230, 37%, 84%)'],
          ],
        },
        'fill-opacity': {
          base: 1,
          stops: [
            [11, 0],
            [11.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'aeroway-runway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934828655.3389',
      },
      source: 'ONLINE',
      'source-layer': 'aeroway',
      minzoom: 9,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'runway']],
      layout: {},
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [15, 'hsl(230, 23%, 82%)'],
            [16, 'hsl(230, 37%, 84%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [9, 1],
            [18, 80],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'aeroway-taxiway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934828655.3389',
      },
      source: 'ONLINE',
      'source-layer': 'aeroway',
      minzoom: 9,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'taxiway']],
      layout: {},
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [15, 'hsl(230, 23%, 82%)'],
            [16, 'hsl(230, 37%, 84%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [10, 0.5],
            [18, 20],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'building-line',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'building',
      minzoom: 15,
      filter: ['all', ['!=', 'type', 'building:part'], ['==', 'underground', 'false']],
      layout: {},
      paint: {
        'line-color': 'hsl(35, 6%, 79%)',
        'line-width': {
          base: 1.5,
          stops: [
            [15, 0.75],
            [20, 3],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [15.5, 0],
            [16, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'building',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'building',
      minzoom: 15,
      filter: ['all', ['!=', 'type', 'building:part'], ['==', 'underground', 'false']],
      layout: {},
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [15, 'hsl(35, 11%, 88%)'],
            [16, 'hsl(35, 8%, 85%)'],
          ],
        },
        'fill-opacity': {
          base: 1,
          stops: [
            [15.5, 0],
            [16, 1],
          ],
        },
        'fill-outline-color': 'hsl(35, 6%, 79%)',
      },
      interactive: true,
    },
    {
      id: 'tunnel-street-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street_limited-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-track-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'track'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 12],
          ],
        },
        'line-dasharray': [3, 3],
      },
      interactive: true,
    },
    {
      id: 'tunnel-service-link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!=', 'type', 'trunk_link'], ['==', 'structure', 'tunnel'], ['in', 'class', 'link', 'service']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 19%, 75%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-dasharray': [3, 3],
      },
      interactive: true,
    },
    {
      id: 'tunnel-street_limited-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 19%, 75%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 19%, 75%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-secondary-tertiary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.2,
          stops: [
            [10, 0.75],
            [18, 2],
          ],
        },
        'line-dasharray': [3, 3],
        'line-gap-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-color': 'hsl(230, 19%, 75%)',
      },
      interactive: true,
    },
    {
      id: 'tunnel-primary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'primary'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-dasharray': [3, 3],
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(230, 19%, 75%)',
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'trunk']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [3, 3],
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [3, 3],
      },
      interactive: true,
    },
    {
      id: 'tunnel-construction',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'construction'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [0.4, 0.8]],
            [15, [0.3, 0.6]],
            [16, [0.2, 0.3]],
            [17, [0.2, 0.25]],
            [18, [0.15, 0.15]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-path',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'type', 'cycleway', 'piste', 'steps'], ['==', 'class', 'path'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [3, 0.4]],
            [16, [3, 0.35]],
            [17, [3, 0.35]],
          ],
        },
        'line-color': 'hsl(35, 26%, 95%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-cycleway-piste',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'path'], ['==', 'structure', 'tunnel'], ['in', 'type', 'cycleway', 'piste']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [3, 0.4]],
            [16, [3, 0.35]],
            [17, [3, 0.35]],
          ],
        },
        'line-color': 'hsl(35, 26%, 95%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-steps',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'steps']]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [16, 1.6],
            [18, 6],
          ],
        },
        'line-color': 'hsl(35, 26%, 95%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [1.75, 0.4]],
            [16, [0.75, 0.4]],
            [17, [0.3, 0.3]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(46, 77%, 78%)',
        'line-opacity': 1,
        'line-dasharray': [1, 0],
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(26, 74%, 81%)',
        'line-opacity': 1,
        'line-dasharray': [1, 0],
      },
      interactive: true,
    },
    {
      id: 'tunnel-pedestrian',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'pedestrian'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': 1,
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [1, 0]],
            [15, [1.5, 0.4]],
            [16, [1, 0.2]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'track'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
      },
      interactive: true,
    },
    {
      id: 'tunnel-service-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!=', 'type', 'trunk_link'], ['==', 'structure', 'tunnel'], ['in', 'class', 'link', 'service']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': [1, 0],
      },
      interactive: true,
    },
    {
      id: 'tunnel-street_limited',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(35, 14%, 93%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': 1,
        'line-dasharray': [1, 0],
        'line-blur': 0,
      },
      interactive: true,
    },
    {
      id: 'tunnel-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'primary'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': 1,
        'line-dasharray': [1, 0],
        'line-blur': 0,
      },
      interactive: true,
    },
    {
      id: 'tunnel-oneway-arrows-blue-minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'link', 'path', 'pedestrian', 'service', 'track'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [17, 'oneway-small'],
            [18, 'oneway-large'],
          ],
        },
        'symbol-spacing': 200,
        'icon-padding': 2,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'tunnel-oneway-arrows-blue-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'primary', 'secondary', 'street', 'street_limited', 'tertiary'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'symbol-spacing': 200,
        'icon-padding': 2,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'tunnel-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'trunk'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(46, 77%, 78%)',
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-dasharray': [1, 0],
        'line-opacity': 1,
        'line-color': 'hsl(26, 74%, 81%)',
        'line-blur': 0,
      },
      interactive: true,
    },
    {
      id: 'tunnel-oneway-arrows-white',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'type', 'primary_link', 'secondary_link', 'tertiary_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'link', 'trunk'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-white-small'],
            [17, 'oneway-white-large'],
          ],
        },
        'symbol-spacing': 200,
        'icon-padding': 2,
      },
      paint: {
        'icon-opacity': 0.5,
      },
      interactive: true,
    },
    {
      id: 'cliffs',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      minzoom: 15,
      filter: ['==', 'class', 'cliff'],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-opacity': {
          base: 1,
          stops: [
            [15, 0],
            [15.25, 1],
          ],
        },
        'line-width': 10,
        'line-pattern': 'cliff',
      },
      interactive: true,
    },
    {
      id: 'ferry',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'ferry']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [15, 'hsl(205, 73%, 63%)'],
            [17, 'hsl(230, 73%, 63%)'],
          ],
        },
        'line-opacity': 1,
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [12, [1, 0]],
            [13, [12, 4]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'ferry-auto',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'ferry_auto']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [15, 'hsl(205, 73%, 63%)'],
            [17, 'hsl(230, 73%, 63%)'],
          ],
        },
        'line-opacity': 1,
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-path-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['!in', 'type', 'corridor', 'crossing', 'piste', 'sidewalk', 'steps'],
          ['==', 'class', 'path'],
        ],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2.5],
            [18, 8],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-blur': {
          base: 1,
          stops: [
            [14, 0],
            [17, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-piste-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'path'], ['==', 'type', 'piste']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2],
            [18, 7],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
        'line-color': 'hsl(230, 85%, 67%)',
        'line-blur': 0,
      },
      interactive: true,
    },
    {
      id: 'road-sidewalk-corridor-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'type', 'corridor', 'crossing', 'sidewalk']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2],
            [18, 7],
          ],
        },
        'line-dasharray': [1, 0],
        'line-color': 'hsl(230, 17%, 82%)',
        'line-blur': 0,
        'line-opacity': {
          base: 1,
          stops: [
            [16, 0],
            [16.25, 0.25],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-steps-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'type', 'steps']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2.5],
            [18, 8],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-blur': {
          base: 1,
          stops: [
            [14, 0],
            [17, 1],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 0.25],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-pedestrian-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'pedestrian'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 2],
            [18, 14.5],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': 0,
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-street-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'none']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          stops: [
            [11, 0],
            [11.25, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-street_limited-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          stops: [
            [11, 0],
            [11.25, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-track-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'track']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 12],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-service-link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['in', 'class', 'link', 'service'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-street_limited-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-street-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'none']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-secondary-tertiary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.2,
          stops: [
            [10, 0.75],
            [18, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [9.99, 0],
            [10, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-primary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'primary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [9.99, 0],
            [10, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-motorway_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 10,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'motorway_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [10.99, 0],
            [11, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-trunk_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [10.99, 0],
            [11, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-trunk-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'trunk']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [6, 0],
            [6.1, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-motorway-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-construction',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'construction'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [0.4, 0.8]],
            [15, [0.3, 0.6]],
            [16, [0.2, 0.3]],
            [17, [0.2, 0.25]],
            [18, [0.15, 0.15]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-sidewalk-corridor',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'type', 'corridor', 'crossing', 'sidewalk']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [3, 0.4]],
            [16, [3, 0.35]],
            [17, [3, 0.35]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [16, 0],
            [16.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-path-smooth',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['==', 'class', 'path'],
          ['in', 'type', 'bridleway', 'footway', 'path'],
        ],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [3, 0.4]],
            [16, [3, 0.35]],
            [17, [3, 0.35]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-path-rough',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['==', 'class', 'path'],
          ['in', 'type', 'hiking', 'mountain_bike', 'trail'],
        ],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [1.75, 0.4]],
            [16, [1, 0.4]],
            [17, [1, 0.35]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-cycleway-piste',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'path'], ['in', 'type', 'cycleway', 'piste']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-steps',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'type', 'steps']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [16, 1.6],
            [18, 6],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [1.75, 0.4]],
            [16, [0.75, 0.4]],
            [17, [0.3, 0.3]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-trunk_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(46, 69%, 68%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-motorway_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 10,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'motorway_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(26, 67%, 70%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-pedestrian',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'pedestrian'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': 1,
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [1, 0]],
            [15, [1.5, 0.4]],
            [16, [1, 0.2]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-pedestrian-polygon-fill',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['all', ['==', 'structure', 'none'], ['in', 'class', 'path', 'pedestrian']],
      ],
      layout: {},
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [16, 'hsl(230, 16%, 94%)'],
            [16.25, 'hsl(230, 50%, 98%)'],
          ],
        },
        'fill-outline-color': 'hsl(230, 26%, 88%)',
        'fill-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-pedestrian-polygon-pattern',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['all', ['==', 'structure', 'none'], ['in', 'class', 'path', 'pedestrian']],
      ],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 100%)',
        'fill-outline-color': 'hsl(35, 10%, 83%)',
        'fill-pattern': 'pedestrian-polygon',
        'fill-opacity': {
          base: 1,
          stops: [
            [16, 0],
            [16.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-polygon',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['all', ['!in', 'class', 'motorway', 'path', 'pedestrian', 'trunk'], ['!in', 'structure', 'bridge', 'tunnel']],
      ],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 100%)',
        'fill-outline-color': 'hsl(0, 0%, 100%)',
      },
      interactive: true,
    },
    {
      id: 'road-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'track']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
      },
      interactive: true,
    },
    {
      id: 'road-service-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['in', 'class', 'link', 'service'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
      },
      interactive: true,
    },
    {
      id: 'road-street_limited',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(35, 14%, 93%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-street',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'none']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-color': {
          base: 1,
          stops: [
            [5, 'hsl(35, 32%, 91%)'],
            [8, 'hsl(0, 0%, 100%)'],
          ],
        },
        'line-opacity': {
          base: 1.2,
          stops: [
            [5, 0],
            [5.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'primary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': {
          base: 1,
          stops: [
            [5, 'hsl(35, 32%, 91%)'],
            [8, 'hsl(0, 0%, 100%)'],
          ],
        },
        'line-opacity': {
          base: 1.2,
          stops: [
            [5, 0],
            [5.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-oneway-arrows-blue-minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['==', 'oneway', 'true'],
          ['in', 'class', 'link', 'path', 'pedestrian', 'service', 'track'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [17, 'oneway-small'],
            [18, 'oneway-large'],
          ],
        },
        'icon-rotation-alignment': 'map',
        'icon-padding': 2,
        'symbol-spacing': 200,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'road-oneway-arrows-blue-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['==', 'oneway', 'true'],
          ['in', 'class', 'primary', 'secondary', 'street', 'street_limited', 'tertiary'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'icon-rotation-alignment': 'map',
        'icon-padding': 2,
        'symbol-spacing': 200,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'road-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'trunk']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': {
          base: 1,
          stops: [
            [6, 'hsl(0, 0%, 100%)'],
            [6.1, 'hsl(46, 80%, 60%)'],
            [9, 'hsl(46, 69%, 68%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': {
          base: 1,
          stops: [
            [8, 'hsl(26, 87%, 62%)'],
            [9, 'hsl(26, 67%, 70%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-rail',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(50, 17%, 82%)'],
            [16, 'hsl(230, 10%, 74%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-rail-tracks',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(50, 17%, 82%)'],
            [16, 'hsl(230, 10%, 74%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 4],
            [20, 8],
          ],
        },
        'line-dasharray': [0.1, 15],
        'line-opacity': {
          base: 1,
          stops: [
            [13.75, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'level-crossing',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'level_crossing']],
      layout: {
        'icon-allow-overlap': true,
        'icon-size': 1,
        'icon-image': 'level-crossing',
      },
      paint: {
        'icon-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-oneway-arrows-white',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['!in', 'type', 'primary_link', 'secondary_link', 'tertiary_link'],
          ['==', 'oneway', 'true'],
          ['in', 'class', 'link', 'trunk'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-white-small'],
            [17, 'oneway-white-large'],
          ],
        },
        'icon-padding': 2,
        'symbol-spacing': 200,
      },
      paint: {
        'icon-opacity': 0.5,
      },
      interactive: true,
    },
    {
      id: 'hedges',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      minzoom: 16,
      filter: ['==', 'class', 'hedge'],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(100, 59%, 70%)',
        'line-width': {
          base: 1,
          stops: [
            [16, 1],
            [20, 3],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [1, 2, 5, 2, 1, 2],
      },
      interactive: true,
    },
    {
      id: 'fences',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      minzoom: 16,
      filter: ['==', 'class', 'fence'],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(46, 17%, 76%)',
        'line-width': {
          base: 1,
          stops: [
            [16, 1],
            [20, 3],
          ],
        },
        'line-opacity': 1,
        'line-dasharray': [1, 2, 5, 2, 1, 2],
      },
      interactive: true,
    },
    {
      id: 'gates',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      minzoom: 17,
      filter: ['==', 'class', 'gate'],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(46, 17%, 76%)',
        'line-width': {
          base: 1,
          stops: [
            [16, 1],
            [20, 3],
          ],
        },
        'line-opacity': 0.5,
        'line-dasharray': [1, 2, 5, 2, 1, 2],
      },
      interactive: true,
    },
    {
      id: 'bridge-path-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'type', 'piste', 'steps'], ['==', 'class', 'path'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2.5],
            [18, 8],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-blur': {
          base: 1,
          stops: [
            [14, 0],
            [17, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-piste-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'path'], ['==', 'structure', 'bridge'], ['==', 'type', 'piste']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2],
            [18, 7],
          ],
        },
        'line-dasharray': [1, 0],
        'line-color': 'hsl(205, 63%, 60%)',
        'line-blur': 0,
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-steps-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'steps']]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 2.5],
            [18, 8],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
        'line-blur': {
          base: 1,
          stops: [
            [14, 0],
            [17, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-pedestrian-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'pedestrian'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 2],
            [18, 14.5],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': 0,
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street_limited-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-track-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'track'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(50, 100%, 40%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 12],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-service-link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!=', 'type', 'trunk_link'], ['==', 'structure', 'bridge'], ['in', 'class', 'link', 'service']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street_limited-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-secondary-tertiary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.2,
          stops: [
            [10, 0.75],
            [18, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-translate': [0, 0],
      },
      interactive: true,
    },
    {
      id: 'bridge-primary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'primary'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-translate': [0, 0],
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [10.99, 0],
            [11, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'trunk'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-construction',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'construction'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(230, 24%, 87%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [0.4, 0.8]],
            [15, [0.3, 0.6]],
            [16, [0.2, 0.3]],
            [17, [0.2, 0.25]],
            [18, [0.15, 0.15]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-path',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'type', 'cycleway', 'piste', 'steps'], ['==', 'class', 'path'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [3, 0.4]],
            [16, [3, 0.35]],
            [17, [3, 0.35]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-cycleway-piste',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'path'], ['==', 'structure', 'bridge'], ['in', 'type', 'cycleway', 'piste']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 4],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-steps',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'steps']]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [16, 1.6],
            [18, 6],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [4, 0.4]],
            [15, [1.75, 0.4]],
            [16, [0.75, 0.4]],
            [17, [0.3, 0.3]],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(46, 69%, 68%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(26, 67%, 70%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-pedestrian',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'pedestrian'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': 1,
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [1, 0]],
            [15, [1.5, 0.4]],
            [16, [1, 0.2]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'track'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [15, 1],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-service-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'service', 'track'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 12],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-street_limited',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(35, 14%, 93%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'type', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1.2,
          stops: [
            [5, 0],
            [5.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'primary']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-opacity': {
          base: 1.2,
          stops: [
            [5, 0],
            [5.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-oneway-arrows-blue-minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'path', 'pedestrian', 'service', 'track'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [17, 'oneway-small'],
            [18, 'oneway-large'],
          ],
        },
        'symbol-spacing': 200,
        'icon-rotation-alignment': 'map',
        'icon-padding': 2,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'bridge-oneway-arrows-blue-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'primary', 'secondary', 'street', 'street_limited', 'tertiary'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'symbol-spacing': 200,
        'icon-rotation-alignment': 'map',
        'icon-padding': 2,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'bridge-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'trunk'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(46, 69%, 68%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(26, 67%, 70%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-rail',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(50, 17%, 82%)'],
            [16, 'hsl(230, 10%, 74%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-rail-tracks',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(50, 17%, 82%)'],
            [16, 'hsl(230, 10%, 74%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 4],
            [20, 8],
          ],
        },
        'line-dasharray': [0.1, 15],
        'line-opacity': {
          base: 1,
          stops: [
            [13.75, 0],
            [20, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [10.99, 0],
            [11, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'trunk'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(0, 0%, 100%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(46, 69%, 68%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(26, 67%, 70%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'trunk'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(46, 69%, 68%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(26, 67%, 70%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-oneway-arrows-white',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'type', 'primary_link', 'secondary_link', 'tertiary_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'trunk'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-white-small'],
            [17, 'oneway-white-large'],
          ],
        },
        'symbol-spacing': 200,
        'icon-padding': 2,
      },
      paint: {
        'icon-opacity': 0.5,
      },
      interactive: true,
    },
    {
      id: 'aerialway-bg',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'aerialway']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 100%)',
        'line-width': {
          base: 1.5,
          stops: [
            [14, 2.5],
            [20, 3],
          ],
        },
        'line-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'aerialway',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'aerialway']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(230, 4%, 29%)',
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-3-4-boundaries-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      filter: ['all', ['==', 'maritime', 0], ['>=', 'admin_level', 3]],
      layout: {
        'line-join': 'bevel',
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [8, 'hsl(35, 12%, 89%)'],
            [16, 'hsl(230, 49%, 90%)'],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [7, 3.75],
            [12, 5.5],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [7, 0],
            [8, 0.75],
          ],
        },
        'line-dasharray': [1, 0],
        'line-translate': [0, 0],
        'line-blur': {
          base: 1,
          stops: [
            [3, 0],
            [8, 3],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-2-boundaries-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      minzoom: 1,
      filter: ['all', ['==', 'admin_level', 2], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1,
          stops: [
            [3, 3.5],
            [10, 8],
          ],
        },
        'line-color': {
          base: 1,
          stops: [
            [6, 'hsl(35, 12%, 89%)'],
            [8, 'hsl(230, 49%, 90%)'],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [3, 0],
            [4, 0.5],
          ],
        },
        'line-translate': [0, 0],
        'line-blur': {
          base: 1,
          stops: [
            [3, 0],
            [10, 2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-3-4-boundaries',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      filter: ['all', ['==', 'maritime', 0], ['>=', 'admin_level', 3]],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-dasharray': {
          base: 1,
          stops: [
            [6, [2, 0]],
            [7, [2, 2, 6, 2]],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [7, 0.75],
            [12, 1.5],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [2, 0],
            [3, 1],
          ],
        },
        'line-color': {
          base: 1,
          stops: [
            [3, 'hsl(230, 14%, 77%)'],
            [7, 'hsl(230, 8%, 62%)'],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-2-boundaries',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      minzoom: 1,
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 0], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(230, 8%, 51%)',
        'line-width': {
          base: 1,
          stops: [
            [3, 0.5],
            [10, 2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-2-boundaries-dispute',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      minzoom: 1,
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 1], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-dasharray': [1.5, 1.5],
        'line-color': 'hsl(230, 8%, 51%)',
        'line-width': {
          base: 1,
          stops: [
            [3, 0.5],
            [10, 2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'housenum-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'housenum_label',
      minzoom: 17,
      layout: {
        'text-field': '{house_num}',
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-padding': 4,
        'text-max-width': 7,
        'text-size': 9.5,
      },
      paint: {
        'text-color': 'hsl(35, 2%, 69%)',
        'text-halo-color': 'hsl(35, 8%, 85%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0,
      },
      interactive: true,
    },
    {
      id: 'contour-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'contour',
      minzoom: 11,
      filter: ['in', 'index', 10, 5],
      layout: {
        'text-field': '{ele} m',
        'symbol-placement': 'line',
        'text-pitch-alignment': 'viewport',
        'text-max-angle': 25,
        'text-padding': 5,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [15, 9.5],
            [20, 12],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(100, 60%, 28%)',
        'text-halo-width': 1,
        'text-halo-blur': 0,
        'text-halo-color': 'hsla(0, 0%, 100%, 0.5)',
      },
      interactive: true,
    },
    {
      id: 'waterway-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'waterway_label',
      minzoom: 12,
      filter: ['in', 'class', 'canal', 'river'],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-pitch-alignment': 'viewport',
        'text-max-angle': 30,
        'text-size': {
          base: 1,
          stops: [
            [13, 12],
            [18, 16],
          ],
        },
      },
      paint: {
        'text-halo-width': 0.5,
        'text-halo-color': 'hsl(196, 80%, 70%)',
        'text-color': 'hsl(230, 48%, 44%)',
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-relevant-scalerank4-l15',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933456003.5437',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      minzoom: 17,
      filter: [
        'all',
        ['==', 'scalerank', 4],
        ['>=', 'localrank', 15],
        [
          'in',
          'maki',
          'amusement-park',
          'aquarium',
          'attraction',
          'bakery',
          'bank',
          'bar',
          'beer',
          'bus',
          'cafe',
          'castle',
          'college',
          'doctor',
          'fast-food',
          'ferry',
          'fire-station',
          'fuel',
          'grocery',
          'harbor',
          'hospital',
          'ice-cream',
          'lodging',
          'marker',
          'monument',
          'museum',
          'pharmacy',
          'police',
          'post',
          'restaurant',
          'rocket',
          'stadium',
          'swimming',
        ],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{maki}-11',
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 1,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(25, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-relevant-scalerank4-l1',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933456003.5437',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      minzoom: 15,
      filter: [
        'all',
        ['<=', 'localrank', 14],
        ['==', 'scalerank', 4],
        [
          'in',
          'maki',
          'amusement-park',
          'aquarium',
          'attraction',
          'bakery',
          'bank',
          'bar',
          'beer',
          'bus',
          'cafe',
          'castle',
          'college',
          'doctor',
          'fast-food',
          'ferry',
          'fire-station',
          'fuel',
          'grocery',
          'harbor',
          'hospital',
          'ice-cream',
          'lodging',
          'marker',
          'monument',
          'museum',
          'pharmacy',
          'police',
          'post',
          'restaurant',
          'rocket',
          'stadium',
          'swimming',
        ],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{maki}-11',
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 1,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(25, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-parks_scalerank4',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933456003.5437',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      minzoom: 15,
      filter: [
        'all',
        ['==', 'scalerank', 4],
        [
          'in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{maki}-11',
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 1,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(100, 100%, 20%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-scalerank3',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933372896.5967',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        [
          '!in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
        ['==', 'scalerank', 3],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{maki}-11',
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 1,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(25, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-parks-scalerank3',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933372896.5967',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        ['==', 'scalerank', 3],
        [
          'in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{maki}-11',
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(100, 100%, 20%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'road-label-small',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933721429.3076',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      minzoom: 15,
      filter: [
        'all',
        [
          '!in',
          'class',
          'aerialway',
          'golf',
          'link',
          'motorway',
          'path',
          'pedestrian',
          'primary',
          'secondary',
          'street',
          'street_limited',
          'tertiary',
          'trunk',
        ],
        ['==', '$type', 'LineString'],
      ],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [15, 10],
            [20, 13],
          ],
        },
        'text-max-angle': 30,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1.25,
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'road-label-medium',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933721429.3076',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['in', 'class', 'aerialway', 'link', 'path', 'pedestrian', 'street', 'street_limited'],
      ],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [11, 10],
            [20, 14],
          ],
        },
        'text-max-angle': 30,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'road-label-large',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933721429.3076',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      filter: ['in', 'class', 'motorway', 'primary', 'secondary', 'tertiary', 'trunk'],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [9, 10],
            [20, 16],
          ],
        },
        'text-max-angle': 30,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'rgba(255,255,255, 0.75)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'road-shields-black',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933575858.6992',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      filter: [
        'all',
        [
          '!in',
          'shield',
          'at-expressway',
          'at-motorway',
          'at-state-b',
          'bg-motorway',
          'bg-national',
          'ch-main',
          'ch-motorway',
          'cz-motorway',
          'cz-road',
          'de-motorway',
          'e-road',
          'fi-main',
          'gr-motorway',
          'gr-national',
          'hr-motorway',
          'hr-state',
          'hu-main',
          'hu-motorway',
          'nz-state',
          'pl-expressway',
          'pl-motorway',
          'pl-national',
          'ro-county',
          'ro-motorway',
          'ro-national',
          'rs-motorway',
          'rs-state-1b',
          'se-main',
          'si-expressway',
          'si-motorway',
          'sk-highway',
          'sk-road',
          'us-interstate',
          'us-interstate-business',
          'us-interstate-duplex',
          'us-interstate-truck',
          'za-metropolitan',
          'za-national',
          'za-provincial',
          'za-regional',
        ],
        ['<=', 'reflen', 6],
      ],
      layout: {
        'text-size': 9,
        'icon-image': '{shield}-{reflen}',
        'icon-rotation-alignment': 'viewport',
        'text-max-angle': 38,
        'symbol-spacing': {
          base: 1,
          stops: [
            [11, 150],
            [14, 200],
          ],
        },
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'text-padding': 2,
        'text-rotation-alignment': 'viewport',
        'text-field': '{ref}',
        'text-letter-spacing': 0.05,
        'icon-padding': 2,
      },
      paint: {
        'text-color': 'hsl(230, 21%, 37%)',
        'icon-halo-color': 'rgba(0, 0, 0, 1)',
        'icon-halo-width': 1,
        'text-opacity': 1,
        'icon-color': 'white',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0,
      },
      interactive: true,
    },
    {
      id: 'road-shields-white',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933575858.6992',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      filter: [
        'all',
        ['<=', 'reflen', 6],
        [
          'in',
          'shield',
          'at-expressway',
          'at-motorway',
          'at-state-b',
          'bg-motorway',
          'bg-national',
          'ch-main',
          'ch-motorway',
          'cz-motorway',
          'cz-road',
          'de-motorway',
          'e-road',
          'fi-main',
          'gr-motorway',
          'gr-national',
          'hr-motorway',
          'hr-state',
          'hu-main',
          'hu-motorway',
          'nz-state',
          'pl-expressway',
          'pl-motorway',
          'pl-national',
          'ro-county',
          'ro-motorway',
          'ro-national',
          'rs-motorway',
          'rs-state-1b',
          'se-main',
          'si-expressway',
          'si-motorway',
          'sk-highway',
          'sk-road',
          'us-interstate',
          'us-interstate-business',
          'us-interstate-duplex',
          'us-interstate-truck',
          'za-metropolitan',
          'za-national',
          'za-provincial',
          'za-regional',
        ],
      ],
      layout: {
        'text-size': 9,
        'icon-image': '{shield}-{reflen}',
        'icon-rotation-alignment': 'viewport',
        'text-max-angle': 38,
        'symbol-spacing': {
          base: 1,
          stops: [
            [11, 150],
            [14, 200],
          ],
        },
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'text-padding': 2,
        'text-rotation-alignment': 'viewport',
        'text-field': '{ref}',
        'text-letter-spacing': 0.05,
        'icon-padding': 2,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'icon-halo-color': 'rgba(0, 0, 0, 1)',
        'icon-halo-width': 1,
        'text-opacity': 1,
        'icon-color': 'white',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0,
      },
      interactive: true,
    },
    {
      id: 'motorway-junction',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933575858.6992',
      },
      source: 'ONLINE',
      'source-layer': 'motorway_junction',
      minzoom: 14,
      filter: ['all', ['<=', 'reflen', 9], ['>', 'reflen', 0]],
      layout: {
        'text-field': '{ref}',
        'text-size': 9,
        'icon-image': 'motorway-exit-{reflen}',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
      },
      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-translate': [0, 0],
      },
      interactive: true,
    },
    {
      id: 'poi-outdoor-features',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: ['in', 'maki', 'bicycle', 'bicycle-share', 'drinking-water', 'information', 'picnic-site', 'toilet'],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{maki}-11',
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(25, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'mountain-peak-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'mountain_peak_label',
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 14],
          ],
        },
        'icon-image': '{maki}-15',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.65],
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(100, 100%, 20%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'mountain-peak-label-with-elevation',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'mountain_peak_label',
      filter: ['>', 'elevation_m', 0],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 14],
          ],
        },
        'icon-image': '{maki}-15',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.65],
        'text-anchor': 'top',
        'text-field': '{name_en}, {elevation_m}m',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(100, 100%, 20%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-scalerank2',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933358918.2366',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        [
          '!in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
        ['==', 'scalerank', 2],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [14, 11],
            [20, 14],
          ],
        },
        'icon-image': {
          stops: [
            [14, '{maki}-11'],
            [15, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(25, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-parks-scalerank2',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933358918.2366',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        ['==', 'scalerank', 2],
        [
          'in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [14, 11],
            [20, 14],
          ],
        },
        'icon-image': {
          stops: [
            [14, '{maki}-11'],
            [15, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(100, 100%, 20%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'rail-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'rail_station_label',
      minzoom: 12,
      filter: ['!=', 'maki', 'entrance'],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{network}',
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.85],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': {
          base: 1,
          stops: [
            [0, ''],
            [13, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.01,
        'icon-padding': 0,
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(230, 48%, 44%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'icon-halo-width': 4,
        'icon-halo-color': '#fff',
        'text-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'water-label-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933808272.805',
      },
      source: 'ONLINE',
      'source-layer': 'water_label',
      minzoom: 15,
      filter: ['<=', 'area', 10000],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-max-width': 7,
        'text-size': {
          base: 1,
          stops: [
            [16, 13],
            [20, 16],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(230, 48%, 44%)',
      },
      interactive: true,
    },
    {
      id: 'water-label',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933808272.805',
      },
      source: 'ONLINE',
      'source-layer': 'water_label',
      minzoom: 5,
      filter: ['>', 'area', 10000],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-max-width': 7,
        'text-size': {
          base: 1,
          stops: [
            [13, 13],
            [18, 18],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(230, 48%, 44%)',
      },
      interactive: true,
    },
    {
      id: 'poi-parks-scalerank1',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933322393.2852',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        ['<=', 'scalerank', 1],
        [
          'in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 14],
          ],
        },
        'icon-image': {
          stops: [
            [13, '{maki}-11'],
            [14, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(100, 100%, 20%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-scalerank1',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933322393.2852',
      },
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        [
          '!in',
          'maki',
          'campsite',
          'cemetery',
          'dog-park',
          'garden',
          'golf',
          'park',
          'picnic-site',
          'playground',
          'zoo',
        ],
        ['<=', 'scalerank', 1],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 14],
          ],
        },
        'icon-image': {
          stops: [
            [13, '{maki}-11'],
            [14, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(25, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'airport-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'airport_label',
      minzoom: 9,
      filter: ['<=', 'scalerank', 2],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 12],
            [18, 18],
          ],
        },
        'icon-image': {
          stops: [
            [12, '{maki}-11'],
            [13, '{maki}-15'],
          ],
        },
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.75],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': {
          stops: [
            [11, '{ref}'],
            [12, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.01,
        'text-max-width': 9,
      },
      paint: {
        'text-color': 'hsl(230, 48%, 44%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'place-islet-archipelago-aboriginal',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 16,
      filter: ['in', 'type', 'aboriginal_lands', 'archipelago', 'islet'],
      layout: {
        'text-line-height': 1.2,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 16],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0],
        'text-rotation-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(230, 29%, 35%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'place-residential',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 18,
      filter: [
        'all',
        ['all', ['<=', 'localrank', 10], ['==', 'type', 'residential']],
        ['in', '$type', 'LineString', 'Point', 'Polygon'],
      ],
      layout: {
        'text-line-height': 1.2,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 14],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0],
        'text-rotation-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(26, 25%, 32%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'place-neighbourhood',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 10,
      maxzoom: 16,
      filter: ['==', 'type', 'neighbourhood'],
      layout: {
        'text-field': '{name_en}',
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.1,
        'text-max-width': 7,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-padding': 3,
        'text-size': {
          base: 1,
          stops: [
            [12, 11],
            [16, 16],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-color': 'hsl(230, 29%, 35%)',
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'place-suburb',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 10,
      maxzoom: 16,
      filter: ['==', 'type', 'suburb'],
      layout: {
        'text-field': '{name_en}',
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-letter-spacing': 0.15,
        'text-max-width': 7,
        'text-padding': 3,
        'text-size': {
          base: 1,
          stops: [
            [11, 11],
            [15, 18],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'text-color': 'hsl(230, 29%, 35%)',
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'place-hamlet',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 10,
      maxzoom: 16,
      filter: ['==', 'type', 'hamlet'],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [12, 11.5],
            [15, 16],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1.25,
        'text-color': 'hsl(0, 0%, 0%)',
      },
      interactive: true,
    },
    {
      id: 'place-village',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 8,
      maxzoom: 15,
      filter: ['==', 'type', 'village'],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-max-width': 7,
        'text-size': {
          base: 1,
          stops: [
            [10, 11.5],
            [16, 18],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1.25,
        'text-color': 'hsl(0, 0%, 0%)',
      },
      interactive: true,
    },
    {
      id: 'place-town',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 6,
      maxzoom: 15,
      filter: ['==', 'type', 'town'],
      layout: {
        'icon-image': 'dot-9',
        'text-font': {
          base: 1,
          stops: [
            [11, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [12, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7, [0, -0.15]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 1,
          stops: [
            [7, 11.5],
            [15, 20],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1.25,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'place-island',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 16,
      filter: ['==', 'type', 'island'],
      layout: {
        'text-line-height': 1.2,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 16],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0],
        'text-rotation-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(230, 29%, 35%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'place-city-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 14,
      filter: ['all', ['!in', 'scalerank', 0, 1, 2, 3, 4, 5], ['==', 'type', 'city']],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [6, 12],
            [14, 22],
          ],
        },
        'icon-image': 'dot-9',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, -0.2]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1.25,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'place-city-md-s',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 14,
      filter: ['all', ['==', 'type', 'city'], ['in', 'ldir', 'E', 'S', 'SE', 'SW'], ['in', 'scalerank', 3, 4, 5]],
      layout: {
        'text-field': '{name_en}',
        'icon-image': 'dot-10',
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'top'],
            [8, 'center'],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, 0.1]],
            [8, [0, 0]],
          ],
        },
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-size': {
          base: 0.9,
          stops: [
            [5, 12],
            [12, 22],
          ],
        },
      },
      paint: {
        'text-halo-width': 1,
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-blur': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'place-city-md-n',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 14,
      filter: ['all', ['==', 'type', 'city'], ['in', 'ldir', 'N', 'NE', 'NW', 'W'], ['in', 'scalerank', 3, 4, 5]],
      layout: {
        'icon-image': 'dot-10',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, -0.25]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 0.9,
          stops: [
            [5, 12],
            [12, 22],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'place-city-lg-s',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 1,
      maxzoom: 14,
      filter: ['all', ['<=', 'scalerank', 2], ['==', 'type', 'city'], ['in', 'ldir', 'E', 'S', 'SE', 'SW']],
      layout: {
        'icon-image': 'dot-11',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, 0.15]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'top'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 0.9,
          stops: [
            [4, 12],
            [10, 22],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'place-city-lg-n',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 1,
      maxzoom: 14,
      filter: ['all', ['<=', 'scalerank', 2], ['==', 'type', 'city'], ['in', 'ldir', 'N', 'NE', 'NW', 'W']],
      layout: {
        'icon-image': 'dot-11',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, -0.25]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 0.9,
          stops: [
            [4, 12],
            [10, 22],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-opacity': 1,
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'marine-label-sm-ln',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856087950.3635',
      },
      source: 'ONLINE',
      'source-layer': 'marine_label',
      minzoom: 3,
      maxzoom: 10,
      filter: ['all', ['==', '$type', 'LineString'], ['>=', 'labelrank', 4]],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [3, 12],
            [6, 16],
          ],
        },
        'symbol-spacing': {
          base: 1,
          stops: [
            [4, 100],
            [6, 400],
          ],
        },
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.1,
        'text-max-width': 5,
      },
      paint: {
        'text-color': 'hsl(205, 83%, 88%)',
      },
      interactive: true,
    },
    {
      id: 'marine-label-sm-pt',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856087950.3635',
      },
      source: 'ONLINE',
      'source-layer': 'marine_label',
      minzoom: 3,
      maxzoom: 10,
      filter: ['all', ['==', '$type', 'Point'], ['>=', 'labelrank', 4]],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': 5,
        'text-letter-spacing': 0.1,
        'text-line-height': 1.5,
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [3, 12],
            [6, 16],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(205, 83%, 88%)',
      },
      interactive: true,
    },
    {
      id: 'marine-label-md-ln',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856087950.3635',
      },
      source: 'ONLINE',
      'source-layer': 'marine_label',
      minzoom: 2,
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'labelrank', 2, 3]],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1.1,
          stops: [
            [2, 12],
            [5, 20],
          ],
        },
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.15,
        'text-max-width': 5,
      },
      paint: {
        'text-color': 'hsl(205, 83%, 88%)',
      },
      interactive: true,
    },
    {
      id: 'marine-label-md-pt',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856087950.3635',
      },
      source: 'ONLINE',
      'source-layer': 'marine_label',
      minzoom: 2,
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Point'], ['in', 'labelrank', 2, 3]],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': 5,
        'text-letter-spacing': 0.15,
        'text-line-height': 1.5,
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1.1,
          stops: [
            [2, 14],
            [5, 20],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(205, 83%, 88%)',
      },
      interactive: true,
    },
    {
      id: 'marine-label-lg-ln',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856087950.3635',
      },
      source: 'ONLINE',
      'source-layer': 'marine_label',
      minzoom: 1,
      maxzoom: 4,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'labelrank', 1]],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': 4,
        'text-letter-spacing': 0.25,
        'text-line-height': 1.1,
        'symbol-placement': 'line',
        'text-pitch-alignment': 'viewport',
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [1, 14],
            [4, 30],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(205, 83%, 88%)',
      },
      interactive: true,
    },
    {
      id: 'marine-label-lg-pt',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856087950.3635',
      },
      source: 'ONLINE',
      'source-layer': 'marine_label',
      minzoom: 1,
      maxzoom: 4,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'labelrank', 1]],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': 4,
        'text-letter-spacing': 0.25,
        'text-line-height': 1.5,
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [1, 14],
            [4, 30],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(205, 83%, 88%)',
      },
      interactive: true,
    },
    {
      id: 'state-label-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856151690.9143',
      },
      source: 'ONLINE',
      'source-layer': 'state_label',
      minzoom: 3,
      maxzoom: 9,
      filter: ['<', 'area', 20000],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [6, 10],
            [9, 14],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': {
          base: 1,
          stops: [
            [0, '{abbr}'],
            [6, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.15,
        'text-max-width': 5,
      },
      paint: {
        'text-opacity': 1,
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'state-label-md',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856151690.9143',
      },
      source: 'ONLINE',
      'source-layer': 'state_label',
      minzoom: 3,
      maxzoom: 8,
      filter: ['all', ['<', 'area', 80000], ['>=', 'area', 20000]],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [5, 10],
            [8, 16],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': {
          base: 1,
          stops: [
            [0, '{abbr}'],
            [5, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.15,
        'text-max-width': 6,
      },
      paint: {
        'text-opacity': 1,
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'state-label-lg',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856151690.9143',
      },
      source: 'ONLINE',
      'source-layer': 'state_label',
      minzoom: 3,
      maxzoom: 7,
      filter: ['>=', 'area', 80000],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [4, 10],
            [7, 18],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-padding': 1,
        'text-field': {
          base: 1,
          stops: [
            [0, '{abbr}'],
            [4, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.15,
        'text-max-width': 6,
      },
      paint: {
        'text-opacity': 1,
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsl(0, 0%, 100%)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'country-label-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856144497.7825',
      },
      source: 'ONLINE',
      'source-layer': 'country_label',
      minzoom: 1,
      maxzoom: 10,
      filter: ['>=', 'scalerank', 5],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': 6,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 0.9,
          stops: [
            [5, 14],
            [9, 22],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': {
          base: 1,
          stops: [
            [2, 'rgba(255,255,255,0.75)'],
            [3, 'hsl(0, 0%, 100%)'],
          ],
        },
        'text-halo-width': 1.25,
      },
      interactive: true,
    },
    {
      id: 'country-label-md',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856144497.7825',
      },
      source: 'ONLINE',
      'source-layer': 'country_label',
      minzoom: 1,
      maxzoom: 8,
      filter: ['in', 'scalerank', 3, 4],
      layout: {
        'text-field': {
          base: 1,
          stops: [
            [0, '{code}'],
            [2, '{name_en}'],
          ],
        },
        'text-max-width': 6,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [3, 10],
            [8, 24],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': {
          base: 1,
          stops: [
            [2, 'rgba(255,255,255,0.75)'],
            [3, 'hsl(0, 0%, 100%)'],
          ],
        },
        'text-halo-width': 1.25,
      },
      interactive: true,
    },
    {
      id: 'country-label-lg',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856144497.7825',
      },
      source: 'ONLINE',
      'source-layer': 'country_label',
      minzoom: 1,
      maxzoom: 7,
      filter: ['in', 'scalerank', 1, 2],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': {
          base: 1,
          stops: [
            [0, 5],
            [3, 6],
          ],
        },
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [1, 10],
            [6, 24],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': {
          base: 1,
          stops: [
            [2, 'rgba(255,255,255,0.75)'],
            [3, 'hsl(0, 0%, 100%)'],
          ],
        },
        'text-halo-width': 1.25,
      },
      interactive: true,
    },
  ];
}
function getMapboxNightLayers() {
  return [
    {
      id: 'background',
      type: 'background',
      layout: {},
      paint: {
        'background-color': 'hsl(215, 7%, 5%)',
      },
      interactive: true,
    },
    {
      id: 'landcover_snow',
      type: 'fill',
      metadata: {
        'mapbox:group': '614faed0ce6b42e1bbd6b90027560439',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      filter: ['==', 'class', 'snow'],
      layout: {},
      paint: {
        'fill-opacity': 0.2,
        'fill-color': 'hsl(0, 0%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'landcover_wood',
      type: 'fill',
      metadata: {
        'mapbox:group': '614faed0ce6b42e1bbd6b90027560439',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 14,
      filter: ['==', 'class', 'wood'],
      layout: {},
      paint: {
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [7, 0],
          ],
        },
        'fill-color': 'hsl(180, 18%, 10%)',
      },
      interactive: true,
    },
    {
      id: 'landcover_scrub',
      type: 'fill',
      metadata: {
        'mapbox:group': '614faed0ce6b42e1bbd6b90027560439',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 14,
      filter: ['==', 'class', 'scrub'],
      layout: {},
      paint: {
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [7, 0],
          ],
        },
        'fill-color': 'hsl(180, 18%, 10%)',
      },
      interactive: true,
    },
    {
      id: 'landcover_grass',
      type: 'fill',
      metadata: {
        'mapbox:group': '614faed0ce6b42e1bbd6b90027560439',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 14,
      filter: ['==', 'class', 'grass'],
      layout: {},
      paint: {
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [7, 0],
          ],
        },
        'fill-color': 'hsl(180, 18%, 10%)',
      },
      interactive: true,
    },
    {
      id: 'landcover_crop',
      type: 'fill',
      metadata: {
        'mapbox:group': '614faed0ce6b42e1bbd6b90027560439',
      },
      source: 'ONLINE',
      'source-layer': 'landcover',
      maxzoom: 14,
      filter: ['==', 'class', 'crop'],
      layout: {},
      paint: {
        'fill-opacity': {
          base: 1.5,
          stops: [
            [2, 0.3],
            [7, 0],
          ],
        },
        'fill-color': 'hsl(180, 18%, 10%)',
      },
      interactive: true,
    },
    {
      id: 'national_park',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse_overlay',
      filter: ['==', 'class', 'national_park'],
      layout: {},
      paint: {
        'fill-color': 'hsl(180, 18%, 10%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [5, 0],
            [6, 0.5],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'school',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'school'],
      layout: {},
      paint: {
        'fill-color': 'hsl(74, 10%, 11%)',
      },
      interactive: true,
    },
    {
      id: 'hospital',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'hospital'],
      layout: {},
      paint: {
        'fill-color': 'hsl(340, 8%, 11%)',
      },
      interactive: true,
    },
    {
      id: 'park',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'park'],
      layout: {},
      paint: {
        'fill-color': 'hsl(180, 18%, 10%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'pitch',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'pitch'],
      layout: {},
      paint: {
        'fill-color': 'hsl(180, 16%, 12%)',
      },
      interactive: true,
    },
    {
      id: 'cemetery',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'cemetery'],
      layout: {},
      paint: {
        'fill-color': 'hsl(140, 7%, 11%)',
      },
      interactive: true,
    },
    {
      id: 'industrial',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'industrial'],
      layout: {},
      paint: {
        'fill-color': 'hsl(230, 8%, 10%)',
      },
      interactive: true,
    },
    {
      id: 'sand',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      filter: ['==', 'class', 'sand'],
      layout: {},
      paint: {
        'fill-color': 'hsl(100, 1%, 10%)',
      },
      interactive: true,
    },
    {
      id: 'parking',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'landuse',
      minzoom: 15,
      filter: ['==', 'class', 'parking'],
      layout: {},
      paint: {
        'fill-color': 'hsl(215, 15%, 12%)',
        'fill-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'hillshade_highlight_bright',
      type: 'fill',
      metadata: {
        'mapbox:group': '1456969573402.7817',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 16,
      filter: ['==', 'level', 94],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 27%)',
        'fill-opacity': {
          stops: [
            [14, 0.12],
            [16, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_highlight_med',
      type: 'fill',
      metadata: {
        'mapbox:group': '1456969573402.7817',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 16,
      filter: ['==', 'level', 90],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 27%)',
        'fill-opacity': {
          stops: [
            [14, 0.12],
            [16, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_faint',
      type: 'fill',
      metadata: {
        'mapbox:group': '1456969573402.7817',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 16,
      filter: ['==', 'level', 89],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 0%)',
        'fill-opacity': {
          stops: [
            [14, 0.05],
            [16, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_med',
      type: 'fill',
      metadata: {
        'mapbox:group': '1456969573402.7817',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 16,
      filter: ['==', 'level', 78],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 0%)',
        'fill-opacity': {
          stops: [
            [14, 0.05],
            [16, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_dark',
      type: 'fill',
      metadata: {
        'mapbox:group': '1456969573402.7817',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 16,
      filter: ['==', 'level', 67],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 0%)',
        'fill-opacity': {
          stops: [
            [14, 0.06],
            [16, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'hillshade_shadow_extreme',
      type: 'fill',
      metadata: {
        'mapbox:group': '1456969573402.7817',
      },
      source: 'ONLINE',
      'source-layer': 'hillshade',
      maxzoom: 16,
      filter: ['==', 'level', 56],
      layout: {},
      paint: {
        'fill-color': 'hsl(0, 0%, 0%)',
        'fill-opacity': {
          stops: [
            [14, 0.06],
            [16, 0],
          ],
        },
        'fill-antialias': false,
      },
      interactive: true,
    },
    {
      id: 'waterway-river-canal',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'waterway',
      minzoom: 8,
      filter: ['in', 'class', 'canal', 'river'],
      layout: {
        'line-cap': {
          base: 1,
          stops: [
            [0, 'butt'],
            [11, 'round'],
          ],
        },
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(205, 35%, 17%)',
        'line-opacity': {
          base: 1,
          stops: [
            [8, 0],
            [8.5, 1],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [8.5, 0.1],
            [20, 8],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'waterway-small',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'waterway',
      minzoom: 13,
      filter: ['!in', 'class', 'canal', 'river'],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(205, 35%, 17%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [13.5, 1],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [13.5, 0.1],
            [20, 3],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'water-shadow',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'water',
      layout: {},
      paint: {
        'fill-color': 'hsl(215, 35%, 10%)',
        'fill-translate': {
          base: 1,
          stops: [
            [7, [0, 0]],
            [16, [-1, -1]],
          ],
        },
        'fill-translate-anchor': 'viewport',
      },
      interactive: true,
    },
    {
      id: 'water',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'water',
      layout: {},
      paint: {
        'fill-color': 'hsl(205, 35%, 17%)',
      },
      interactive: true,
    },
    {
      id: 'barrier_line-land-polygon',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'land']],
      layout: {},
      paint: {
        'fill-color': 'hsl(215, 7%, 5%)',
      },
      interactive: true,
    },
    {
      id: 'barrier_line-land-line',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'barrier_line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'land']],
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-width': {
          base: 1.99,
          stops: [
            [14, 0.75],
            [20, 40],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
      },
      interactive: true,
    },
    {
      id: 'aeroway-polygon',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444934828655.3389',
      },
      source: 'ONLINE',
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['!=', 'type', 'apron'], ['==', '$type', 'Polygon']],
      layout: {},
      paint: {
        'fill-color': 'hsl(230, 15%, 8%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [11, 0],
            [11.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'aeroway-runway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934828655.3389',
      },
      source: 'ONLINE',
      'source-layer': 'aeroway',
      minzoom: 9,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'runway']],
      layout: {},
      paint: {
        'line-color': 'hsl(230, 15%, 8%)',
        'line-width': {
          base: 1.5,
          stops: [
            [9, 1],
            [18, 80],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'aeroway-taxiway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934828655.3389',
      },
      source: 'ONLINE',
      'source-layer': 'aeroway',
      minzoom: 9,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'taxiway']],
      layout: {},
      paint: {
        'line-color': 'hsl(230, 15%, 8%)',
        'line-width': {
          base: 1.5,
          stops: [
            [10, 0.5],
            [18, 20],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'building',
      type: 'fill',
      source: 'ONLINE',
      'source-layer': 'building',
      minzoom: 15,
      filter: ['all', ['!=', 'type', 'building:part'], ['==', 'underground', 'false']],
      layout: {},
      paint: {
        'fill-color': 'hsl(215, 10%, 7%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [15.5, 0],
            [16, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'building-line',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'building',
      minzoom: 15,
      filter: ['all', ['!=', 'type', 'building:part'], ['==', 'underground', 'false']],
      layout: {},
      paint: {
        'line-color': 'hsl(215, 7%, 11%)',
        'line-width': {
          base: 1.5,
          stops: [
            [15, 0.75],
            [20, 2],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [15.5, 0],
            [16, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street_limited-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-service-link-track-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'link', 'service', 'track'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 25%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 5],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-street_limited-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 25%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 25%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-secondary-tertiary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.2,
          stops: [
            [10, 0.75],
            [18, 2],
          ],
        },
        'line-dasharray': [3, 3],
        'line-gap-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(215, 7%, 25%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-primary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'primary'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-dasharray': [3, 3],
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(215, 7%, 25%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 35%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.75],
            [20, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 35%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-dasharray': [3, 3],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'trunk']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-dasharray': [3, 3],
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(215, 7%, 35%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-dasharray': [3, 3],
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(215, 7%, 35%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-construction',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'construction'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(210, 7%, 15%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [0.4, 0.8]],
            [15, [0.3, 0.6]],
            [16, [0.2, 0.3]],
            [17, [0.2, 0.25]],
            [18, [0.15, 0.15]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(65, 30%, 30%)',
        'line-dasharray': [1, 0],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(50, 30%, 28%)',
        'line-dasharray': [1, 0],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-service-link-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'link', 'service', 'track'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 1],
            [19, 6],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-dasharray': [1, 0],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-street_limited',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-street',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'tunnel-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [16, 14.55],
            [17, 21.53],
            [18, 32],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-blur': 0,
        'line-dasharray': [1, 0],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'primary'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(215, 7%, 20%)',
        'line-blur': 0,
        'line-dasharray': [1, 0],
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-oneway-arrows-minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'link', 'primary', 'secondary', 'service', 'street', 'street_limited', 'tertiary', 'track'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'symbol-spacing': 250,
        'icon-padding': 2,
      },
      paint: {
        'icon-opacity': 0.5,
      },
      interactive: true,
    },
    {
      id: 'tunnel-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'trunk'], ['==', 'structure', 'tunnel']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(65, 30%, 25%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'tunnel-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'tunnel']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-dasharray': [1, 0],
        'line-opacity': 1,
        'line-color': 'hsl(50, 30%, 28%)',
      },
      interactive: true,
    },
    {
      id: 'tunnel-oneway-arrows-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855769305.6016',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'type', 'primary_link', 'secondary_link', 'tertiary_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'tunnel'],
          ['in', 'class', 'link', 'motorway', 'motorway_link', 'trunk'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'symbol-spacing': 250,
        'icon-padding': 2,
      },
      paint: {
        'icon-opacity': 0.5,
      },
      interactive: true,
    },
    {
      id: 'ferry_auto',
      type: 'line',
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'type', 'ferry_auto']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(215, 50%, 30%)',
        'line-opacity': 1,
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-construction',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'construction'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(215, 5%, 18%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [0.4, 0.8]],
            [15, [0.3, 0.4]],
            [16, [0.2, 0.3]],
            [17, [0.2, 0.25]],
            [18, [0.15, 0.15]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-trunk_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(65, 35%, 20%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-motorway_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 10,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'motorway_link']],
      ],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(50, 25%, 20%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-pedestrian',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'structure', 'tunnel'],
          ['!=', 'type', 'platform'],
          ['!=', 'type', 'subway'],
          ['==', 'class', 'pedestrian'],
          ['==', 'structure', 'none'],
        ],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [19, 4],
          ],
        },
        'line-color': 'hsl(215, 5%, 18%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-polygon',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 12,
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        [
          'all',
          ['!=', 'type', 'service:driveway'],
          ['!in', 'class', 'motorway', 'path', 'pedestrian', 'trunk'],
          ['!in', 'structure', 'bridge', 'tunnel'],
        ],
      ],
      layout: {},
      paint: {
        'fill-color': 'hsl(205, 7%, 17%)',
      },
      interactive: true,
    },
    {
      id: 'road-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!=', 'type', 'trunk_link'], ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'link']],
      ],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
      },
      interactive: true,
    },
    {
      id: 'road-service-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'service:driveway'],
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['!in', 'type', 'track:grade1', 'trunk_link'],
          ['in', 'class', 'service', 'track'],
        ],
      ],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 1],
            [19, 6],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
      },
      interactive: true,
    },
    {
      id: 'road-street_limited',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'none']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-street',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'none']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          base: 1,
          stops: [
            [11, 0],
            [11.25, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [16, 14.55],
            [17, 21.53],
            [18, 32],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          base: 1.2,
          stops: [
            [5, 0],
            [5.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'road-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'primary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-oneway-arrows-minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['==', 'oneway', 'true'],
          ['in', 'class', 'link', 'primary', 'secondary', 'service', 'street', 'street_limited', 'tertiary', 'track'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'icon-rotation-alignment': 'map',
        'icon-padding': 2,
        'symbol-spacing': 250,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'road-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'trunk']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(65, 35%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'road-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(50, 25%, 20%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-rail',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(197, 10%, 12%)'],
            [16, 'hsl(197, 7%, 14%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
        'line-offset': 0,
      },
      interactive: true,
    },
    {
      id: 'road-rail-tracks',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'structure', 'bridge', 'tunnel'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(197, 10%, 12%)'],
            [16, 'hsl(197, 7%, 14%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 4],
            [20, 8],
          ],
        },
        'line-dasharray': [0.1, 15],
        'line-opacity': {
          base: 1,
          stops: [
            [13.75, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'level-crossings',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'level_crossing']],
      layout: {
        'icon-size': 1,
        'icon-allow-overlap': true,
        'icon-image': 'level-crossing',
      },
      paint: {
        'icon-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'road-oneway-arrows-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'structure', 'bridge', 'tunnel'],
          ['!in', 'type', 'primary_link', 'secondary_link', 'tertiary_link'],
          ['==', 'oneway', 'true'],
          ['in', 'class', 'link', 'motorway', 'motorway_link', 'trunk'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-large'],
            [17, 'oneway-small'],
          ],
        },
        'icon-padding': 2,
        'symbol-spacing': 250,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'turning-features',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855786460.0557',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['in', 'class', 'turning_circle', 'turning_loop']],
      layout: {
        'icon-image': 'turning-circle',
        'icon-size': {
          base: 1.5,
          stops: [
            [14, 0.095],
            [18, 1],
          ],
        },
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
        'icon-padding': 0,
        'icon-rotation-alignment': 'map',
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'bridge-street-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street_limited-low',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          stops: [
            [11.5, 0],
            [12, 1],
            [14, 1],
            [14.01, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-service-link-track-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!=', 'type', 'trunk_link'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'service', 'track'],
        ],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [18, 4],
            [19, 5],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street_limited-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-gap-width': {
          base: 1.5,
          stops: [
            [13, 0],
            [14, 2],
            [18, 18],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-secondary-tertiary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [18, 26],
          ],
        },
        'line-translate': [0, 0],
      },
      interactive: true,
    },
    {
      id: 'bridge-primary-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'primary'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-translate': [0, 0],
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [10.99, 0],
            [11, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'trunk'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-construction',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'construction'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(215, 5%, 18%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'line-dasharray': {
          base: 1,
          stops: [
            [14, [0.4, 0.8]],
            [15, [0.3, 0.4]],
            [16, [0.2, 0.3]],
            [17, [0.2, 0.25]],
            [18, [0.15, 0.15]],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(65, 35%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(50, 25%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-pedestrian',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'pedestrian'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [19, 4],
          ],
        },
        'line-color': 'hsl(215, 5%, 18%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'bridge-service-link-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 14,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'type', 'track:grade1', 'trunk_link'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'service', 'track'],
        ],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 1],
            [19, 6],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-street_limited',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'street_limited'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-street',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'street'], ['==', 'structure', 'bridge']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12.5, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'type', 'secondary', 'tertiary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [8.5, 0.5],
            [10, 0.75],
            [16, 14.55],
            [17, 21.53],
            [18, 32],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': {
          base: 1.2,
          stops: [
            [5, 0],
            [5.5, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'primary']]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(205, 7%, 17%)',
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'bridge-oneway-arrows-blue-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 17,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'primary', 'secondary', 'service', 'street', 'street_limited', 'tertiary', 'track'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'symbol-spacing': 250,
        'icon-rotation-alignment': 'map',
        'icon-padding': 2,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'bridge-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'trunk'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(65, 35%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'layer', 2, 3, 4, 5], ['==', 'class', 'motorway'], ['==', 'structure', 'bridge']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(50, 25%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-rail',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(197, 10%, 12%)'],
            [16, 'hsl(197, 7%, 14%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 0.5],
            [20, 1],
          ],
        },
        'line-offset': 0,
      },
      interactive: true,
    },
    {
      id: 'bridge-rail-tracks',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['in', 'class', 'major_rail', 'minor_rail']],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': {
          stops: [
            [13, 'hsl(197, 10%, 12%)'],
            [16, 'hsl(197, 7%, 14%)'],
          ],
        },
        'line-width': {
          base: 1.5,
          stops: [
            [14, 4],
            [20, 8],
          ],
        },
        'line-dasharray': [0.1, 15],
        'line-opacity': {
          base: 1,
          stops: [
            [13.75, 0],
            [14, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [10.99, 0],
            [11, 1],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-opacity': 1,
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'trunk'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway-2-case',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [10, 1],
            [16, 2],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-gap-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk_link-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'structure', 'bridge'], ['==', 'type', 'trunk_link'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(65, 35%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway_link-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 13,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway_link'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [12, 0.5],
            [14, 2],
            [18, 18],
          ],
        },
        'line-color': 'hsl(50, 25%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-trunk-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'trunk'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(65, 35%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-motorway-2',
      type: 'line',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['==', 'class', 'motorway'], ['==', 'structure', 'bridge'], ['>=', 'layer', 2]],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.5,
          stops: [
            [5, 0.75],
            [18, 32],
          ],
        },
        'line-color': 'hsl(50, 25%, 20%)',
      },
      interactive: true,
    },
    {
      id: 'bridge-oneway-arrows-major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444855799204.86',
      },
      source: 'ONLINE',
      'source-layer': 'road',
      minzoom: 16,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        [
          'all',
          ['!in', 'type', 'primary_link', 'secondary_link', 'tertiary_link'],
          ['==', 'oneway', 'true'],
          ['==', 'structure', 'bridge'],
          ['in', 'class', 'link', 'motorway', 'motorway_link', 'trunk'],
        ],
      ],
      layout: {
        'symbol-placement': 'line',
        'icon-image': {
          base: 1,
          stops: [
            [16, 'oneway-small'],
            [17, 'oneway-large'],
          ],
        },
        'symbol-spacing': 250,
        'icon-padding': 2,
      },
      paint: {},
      interactive: true,
    },
    {
      id: 'admin-3-4-boundaries-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      filter: ['all', ['==', 'maritime', 0], ['>=', 'admin_level', 3]],
      layout: {
        'line-join': 'bevel',
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [8, 'hsl(215, 33%, 26%)'],
            [16, 'hsl(215, 33%, 16%)'],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [7, 3.75],
            [12, 5.5],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [7, 0],
            [8, 0.75],
          ],
        },
        'line-dasharray': [1, 0],
        'line-translate': [0, 0],
        'line-blur': {
          base: 1,
          stops: [
            [3, 0],
            [8, 3],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-2-boundaries-bg',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      minzoom: 1,
      filter: ['all', ['==', 'admin_level', 2], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'miter',
      },
      paint: {
        'line-width': {
          base: 1,
          stops: [
            [3, 3.5],
            [10, 8],
          ],
        },
        'line-color': 'hsl(215, 7%, 5%)',
        'line-opacity': {
          base: 1,
          stops: [
            [3, 0],
            [4, 0.5],
          ],
        },
        'line-translate': [0, 0],
        'line-blur': {
          base: 1,
          stops: [
            [3, 0],
            [10, 2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-3-4-boundaries',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      filter: ['all', ['==', 'maritime', 0], ['>=', 'admin_level', 3]],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-dasharray': {
          base: 1,
          stops: [
            [6, [2, 0]],
            [7, [2, 2, 6, 2]],
          ],
        },
        'line-width': {
          base: 1,
          stops: [
            [7, 0.75],
            [12, 1.5],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [2, 0],
            [3, 1],
          ],
        },
        'line-color': 'hsl(230, 8%, 51%)',
      },
      interactive: true,
    },
    {
      id: 'admin-2-boundaries',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      minzoom: 1,
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 0], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(230, 8%, 51%)',
        'line-width': {
          base: 1,
          stops: [
            [3, 0.5],
            [10, 2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'admin-2-boundaries-dispute',
      type: 'line',
      metadata: {
        'mapbox:group': '1444934295202.7542',
      },
      source: 'ONLINE',
      'source-layer': 'admin',
      minzoom: 1,
      filter: ['all', ['==', 'admin_level', 2], ['==', 'disputed', 1], ['==', 'maritime', 0]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-dasharray': [1.5, 1.5],
        'line-color': 'hsl(230, 8%, 51%)',
        'line-width': {
          base: 1,
          stops: [
            [3, 0.5],
            [10, 2],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'poi-scalerank3',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        ['<=', 'scalerank', 3],
        ['in', 'maki', 'campsite', 'cemetery', 'college', 'garden', 'hospital', 'park', 'zoo'],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 14],
          ],
        },
        'icon-image': {
          stops: [
            [13, '{maki}-11'],
            [14, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': {
          base: 1,
          type: 'categorical',
          property: 'maki',
          stops: [
            ['park', 'hsl(96, 20%, 45%)'],
            ['cemetery', 'hsl(96, 20%, 45%)'],
            ['campsite', 'hsl(96, 20%, 45%)'],
            ['garden', 'hsl(96, 20%, 45%)'],
            ['zoo', 'hsl(96, 20%, 45%)'],
            ['college', 'hsl(44, 20%, 54%)'],
            ['hospital', 'hsl(319, 25%, 55%)'],
          ],
          default: 'hsl(215, 10%, 50%)',
        },
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-blur': 0,
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'road-label-small',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933721429.3076',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'service']],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [16, 13],
            [20, 18],
          ],
        },
        'text-max-angle': 30,
        'symbol-spacing': {
          base: 1,
          stops: [
            [17, 250],
            [20, 480],
          ],
        },
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'road-label-medium',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933721429.3076',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      minzoom: 14,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'link', 'street', 'street_limited']],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [11, 10],
            [20, 18],
          ],
        },
        'text-max-angle': 30,
        'symbol-spacing': {
          base: 1,
          stops: [
            [17, 250],
            [20, 480],
          ],
        },
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'road-label-large',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933721429.3076',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      filter: ['in', 'class', 'motorway', 'primary', 'secondary', 'tertiary', 'trunk'],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [9, 10],
            [20, 20],
          ],
        },
        'text-max-angle': 30,
        'symbol-spacing': {
          base: 1,
          stops: [
            [17, 250],
            [20, 480],
          ],
        },
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        'text-padding': 1,
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
      interactive: true,
    },
    {
      id: 'road-shields-black',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933575858.6992',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      filter: [
        'all',
        [
          '!in',
          'shield',
          'at-expressway',
          'at-motorway',
          'at-state-b',
          'bg-motorway',
          'bg-national',
          'ch-main',
          'ch-motorway',
          'cz-motorway',
          'cz-road',
          'de-motorway',
          'e-road',
          'fi-main',
          'gr-motorway',
          'gr-national',
          'hr-motorway',
          'hr-state',
          'hu-main',
          'hu-motorway',
          'nz-state',
          'pl-expressway',
          'pl-motorway',
          'pl-national',
          'ro-county',
          'ro-motorway',
          'ro-national',
          'rs-motorway',
          'rs-state-1b',
          'se-main',
          'si-expressway',
          'si-motorway',
          'sk-highway',
          'sk-road',
          'us-interstate',
          'us-interstate-business',
          'us-interstate-duplex',
          'us-interstate-truck',
          'za-metropolitan',
          'za-national',
          'za-provincial',
          'za-regional',
        ],
        ['<=', 'reflen', 6],
      ],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [6, 10.25],
            [11, 13.5],
          ],
        },
        'icon-image': '{shield}-{reflen}',
        'icon-rotation-alignment': 'viewport',
        'text-max-angle': 38,
        'symbol-spacing': {
          base: 1,
          stops: [
            [11, 150],
            [14, 500],
          ],
        },
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'text-padding': 2,
        'text-rotation-alignment': 'viewport',
        'icon-size': {
          base: 1,
          stops: [
            [6, 0.75],
            [11, 1],
          ],
        },
        'text-field': '{ref}',
        'text-letter-spacing': 0.05,
        'icon-padding': 2,
      },
      paint: {
        'text-color': 'hsl(0, 0%, 7%)',
        'icon-halo-color': 'rgba(0, 0, 0, 1)',
        'icon-halo-width': 1,
        'text-opacity': 1,
        'icon-color': 'white',
      },
      interactive: true,
    },
    {
      id: 'road-shields-white',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933575858.6992',
      },
      source: 'ONLINE',
      'source-layer': 'road_label',
      filter: [
        'all',
        ['<=', 'reflen', 6],
        [
          'in',
          'shield',
          'at-expressway',
          'at-motorway',
          'at-state-b',
          'bg-motorway',
          'bg-national',
          'ch-main',
          'ch-motorway',
          'cz-motorway',
          'cz-road',
          'de-motorway',
          'e-road',
          'fi-main',
          'gr-motorway',
          'gr-national',
          'hr-motorway',
          'hr-state',
          'hu-main',
          'hu-motorway',
          'nz-state',
          'pl-expressway',
          'pl-motorway',
          'pl-national',
          'ro-county',
          'ro-motorway',
          'ro-national',
          'rs-motorway',
          'rs-state-1b',
          'se-main',
          'si-expressway',
          'si-motorway',
          'sk-highway',
          'sk-road',
          'us-interstate',
          'us-interstate-business',
          'us-interstate-duplex',
          'us-interstate-truck',
          'za-metropolitan',
          'za-national',
          'za-provincial',
          'za-regional',
        ],
      ],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [6, 10.25],
            [11, 13.5],
          ],
        },
        'icon-image': '{shield}-{reflen}',
        'icon-rotation-alignment': 'viewport',
        'text-max-angle': 38,
        'symbol-spacing': {
          base: 1,
          stops: [
            [11, 150],
            [16, 300],
            [18, 1000],
          ],
        },
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'text-padding': 2,
        'text-rotation-alignment': 'viewport',
        'icon-size': {
          base: 1,
          stops: [
            [6, 0.75],
            [11, 1],
          ],
        },
        'text-field': '{ref}',
        'text-letter-spacing': 0.05,
        'icon-padding': 2,
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'icon-halo-color': 'rgba(0, 0, 0, 1)',
        'icon-halo-width': 1,
        'text-opacity': 1,
        'icon-color': 'white',
      },
      interactive: true,
    },
    {
      id: 'motorway-junction',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444933575858.6992',
      },
      source: 'ONLINE',
      'source-layer': 'motorway_junction',
      minzoom: 14,
      filter: ['all', ['<', 'reflen', 9], ['>', 'reflen', 0]],
      layout: {
        'text-field': '{ref}',
        'text-size': {
          base: 1,
          stops: [
            [6, 10.25],
            [11, 13.5],
          ],
        },
        'icon-image': 'motorway-exit-{reflen}',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'icon-size': {
          base: 1,
          stops: [
            [6, 0.75],
            [11, 1],
          ],
        },
        'text-letter-spacing': 0.05,
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-translate': [0, 0],
      },
      interactive: true,
    },
    {
      id: 'poi-scalerank2',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        ['<=', 'scalerank', 2],
        ['in', 'maki', 'campsite', 'cemetery', 'college', 'garden', 'hospital', 'park', 'zoo'],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': {
          stops: [
            [13, '{maki}-11'],
            [14, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': {
          base: 1,
          type: 'categorical',
          property: 'maki',
          stops: [
            ['park', 'hsl(96, 20%, 45%)'],
            ['cemetery', 'hsl(96, 20%, 45%)'],
            ['campsite', 'hsl(96, 20%, 45%)'],
            ['garden', 'hsl(96, 20%, 45%)'],
            ['zoo', 'hsl(96, 20%, 45%)'],
            ['college', 'hsl(44, 20%, 54%)'],
            ['hospital', 'hsl(319, 25%, 55%)'],
          ],
          default: 'hsl(215, 10%, 50%)',
        },
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'text-halo-blur': 0,
      },
      interactive: true,
    },
    {
      id: 'rail-label-minor',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'rail_station_label',
      minzoom: 15,
      filter: ['!in', 'maki', 'entrance', 'rail'],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{network}',
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.85],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': {
          base: 1,
          stops: [
            [0, ''],
            [13, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.01,
        'icon-padding': 0,
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(230, 43%, 65%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 0.5,
        'icon-halo-width': 4,
        'icon-halo-color': '#fff',
        'text-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'rail-label-major',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'rail_station_label',
      minzoom: 14,
      filter: ['all', ['!=', 'maki', 'entrance'], ['!in', 'maki', 'rail-light', 'rail-metro']],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': '{network}',
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-offset': [0, 0.85],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': {
          base: 1,
          stops: [
            [0, ''],
            [13, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.01,
        'icon-padding': 0,
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(230, 43%, 65%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 0.5,
        'icon-halo-width': 4,
        'icon-halo-color': '#fff',
        'text-opacity': {
          base: 1,
          stops: [
            [13.99, 0],
            [14, 1],
          ],
        },
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'poi-scalerank1',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'poi_label',
      filter: [
        'all',
        ['<=', 'scalerank', 1],
        ['in', 'maki', 'campsite', 'cemetery', 'college', 'garden', 'hospital', 'park', 'zoo'],
      ],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [16, 11],
            [20, 13],
          ],
        },
        'icon-image': {
          stops: [
            [13, '{maki}-11'],
            [14, '{maki}-15'],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.65],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': {
          base: 1,
          type: 'categorical',
          property: 'maki',
          stops: [
            ['park', 'hsl(96, 20%, 45%)'],
            ['cemetery', 'hsl(96, 20%, 45%)'],
            ['campsite', 'hsl(96, 20%, 45%)'],
            ['garden', 'hsl(96, 20%, 45%)'],
            ['zoo', 'hsl(96, 20%, 45%)'],
            ['college', 'hsl(44, 20%, 54%)'],
            ['hospital', 'hsl(319, 25%, 55%)'],
          ],
          default: 'hsl(215, 10%, 50%)',
        },
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'airport-label',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'airport_label',
      minzoom: 9,
      filter: ['<=', 'scalerank', 2],
      layout: {
        'text-line-height': 1.1,
        'text-size': {
          base: 1,
          stops: [
            [10, 12],
            [18, 18],
          ],
        },
        'icon-image': {
          stops: [
            [12, '{maki}-11'],
            [13, '{maki}-15'],
          ],
        },
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0.75],
        'text-rotation-alignment': 'viewport',
        'text-anchor': 'top',
        'text-field': {
          stops: [
            [11, '{ref}'],
            [12, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.01,
        'text-max-width': 9,
      },
      paint: {
        'text-color': 'hsl(230, 43%, 65%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 0.5,
        'text-halo-blur': 0.5,
      },
      interactive: true,
    },
    {
      id: 'place-islet-archipelago-aboriginal',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 16,
      filter: ['in', 'type', 'aboriginal_lands', 'archipelago', 'islet'],
      layout: {
        'text-line-height': 1.2,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 16],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0],
        'text-rotation-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 8,
      },
      paint: {
        'text-color': 'hsl(230, 20%, 57%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'place-neighbourhood',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 10,
      maxzoom: 14,
      filter: ['==', 'type', 'neighbourhood'],
      layout: {
        'text-field': '{name_en}',
        'text-transform': 'uppercase',
        'text-letter-spacing': 0.1,
        'text-max-width': 7,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 3,
        'text-size': {
          base: 1,
          stops: [
            [12, 11],
            [16, 16],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'text-color': 'hsl(230, 20%, 57%)',
      },
      interactive: true,
    },
    {
      id: 'place-suburb',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 10,
      maxzoom: 13,
      filter: ['==', 'type', 'suburb'],
      layout: {
        'text-field': '{name_en}',
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-letter-spacing': 0.15,
        'text-max-width': 7,
        'text-padding': 3,
        'text-size': {
          base: 1,
          stops: [
            [11, 11],
            [15, 18],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'text-color': 'hsl(230, 20%, 57%)',
      },
      interactive: true,
    },
    {
      id: 'place-hamlet',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 10,
      maxzoom: 13,
      filter: ['==', 'type', 'hamlet'],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [12, 11.5],
            [15, 16],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
        'text-color': 'hsl(230, 2%, 75%)',
      },
      interactive: true,
    },
    {
      id: 'place-village',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 8,
      maxzoom: 13,
      filter: ['==', 'type', 'village'],
      layout: {
        'text-field': '{name_en}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-max-width': 7,
        'text-size': {
          base: 1,
          stops: [
            [10, 11.5],
            [16, 18],
          ],
        },
      },
      paint: {
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
        'text-color': 'hsl(230, 2%, 75%)',
      },
      interactive: true,
    },
    {
      id: 'place-town',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 6,
      maxzoom: 13,
      filter: ['==', 'type', 'town'],
      layout: {
        'icon-image': 'dot-9',
        'text-font': {
          base: 1,
          stops: [
            [11, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [12, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7, [0, -0.15]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 1,
          stops: [
            [7, 11.5],
            [15, 20],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(230, 2%, 75%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
      },
      interactive: true,
    },
    {
      id: 'place-island',
      type: 'symbol',
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 13,
      filter: ['==', 'type', 'island'],
      layout: {
        'text-line-height': 1.2,
        'text-size': {
          base: 1,
          stops: [
            [10, 11],
            [18, 16],
          ],
        },
        'text-max-angle': 38,
        'symbol-spacing': 250,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-padding': 2,
        'text-offset': [0, 0],
        'text-rotation-alignment': 'viewport',
        'text-field': '{name_en}',
        'text-letter-spacing': 0.01,
        'text-max-width': 7,
      },
      paint: {
        'text-color': 'hsl(230, 20%, 57%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'place-city-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 14,
      filter: ['all', ['!in', 'scalerank', 0, 1, 2, 3, 4, 5], ['==', 'type', 'city']],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [6, 12],
            [14, 22],
          ],
        },
        'icon-image': 'dot-9',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, -0.2]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
      },
      paint: {
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'text-color': 'hsl(230, 2%, 69%)',
      },
      interactive: true,
    },
    {
      id: 'place-city-md-s',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 14,
      filter: ['all', ['==', 'type', 'city'], ['in', 'ldir', 'E', 'S', 'SE', 'SW'], ['in', 'scalerank', 3, 4, 5]],
      layout: {
        'text-field': '{name_en}',
        'icon-image': 'dot-10',
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'top'],
            [8, 'center'],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, 0.1]],
            [8, [0, 0]],
          ],
        },
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-size': {
          base: 0.9,
          stops: [
            [5, 12],
            [12, 22],
          ],
        },
      },
      paint: {
        'text-halo-width': 1,
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-halo-blur': 1,
        'text-color': 'hsl(230, 2%, 69%)',
      },
      interactive: true,
    },
    {
      id: 'place-city-md-n',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      maxzoom: 14,
      filter: ['all', ['==', 'type', 'city'], ['in', 'ldir', 'N', 'NE', 'NW', 'W'], ['in', 'scalerank', 3, 4, 5]],
      layout: {
        'icon-image': 'dot-10',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, -0.25]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 0.9,
          stops: [
            [5, 12],
            [12, 22],
          ],
        },
      },
      paint: {
        'text-halo-blur': 1,
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-color': 'hsl(230, 2%, 69%)',
      },
      interactive: true,
    },
    {
      id: 'place-city-lg-s',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 1,
      maxzoom: 14,
      filter: ['all', ['<=', 'scalerank', 2], ['==', 'type', 'city'], ['in', 'ldir', 'E', 'S', 'SE', 'SW']],
      layout: {
        'icon-image': 'dot-11',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, 0.15]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'top'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 0.9,
          stops: [
            [4, 12],
            [10, 22],
          ],
        },
      },
      paint: {
        'text-halo-blur': 1,
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-color': 'hsl(230, 2%, 69%)',
      },
      interactive: true,
    },
    {
      id: 'place-city-lg-n',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444862510685.128',
      },
      source: 'ONLINE',
      'source-layer': 'place_label',
      minzoom: 1,
      maxzoom: 14,
      filter: ['all', ['<=', 'scalerank', 2], ['==', 'type', 'city'], ['in', 'ldir', 'N', 'NE', 'NW', 'W']],
      layout: {
        'icon-image': 'dot-11',
        'text-font': {
          base: 1,
          stops: [
            [7, ['DIN Offc Pro Regular', 'Arial Unicode MS Regular']],
            [8, ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
          ],
        },
        'text-offset': {
          base: 1,
          stops: [
            [7.99, [0, -0.25]],
            [8, [0, 0]],
          ],
        },
        'text-anchor': {
          base: 1,
          stops: [
            [7, 'bottom'],
            [8, 'center'],
          ],
        },
        'text-field': '{name_en}',
        'text-max-width': 7,
        'text-size': {
          base: 0.9,
          stops: [
            [4, 12],
            [10, 22],
          ],
        },
      },
      paint: {
        'text-halo-blur': 1,
        'text-opacity': 1,
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
        'icon-opacity': {
          base: 1,
          stops: [
            [7.99, 1],
            [8, 0],
          ],
        },
        'text-color': 'hsl(230, 2%, 69%)',
      },
      interactive: true,
    },
    {
      id: 'state-label-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856151690.9143',
      },
      source: 'ONLINE',
      'source-layer': 'state_label',
      minzoom: 3,
      maxzoom: 9,
      filter: ['<', 'area', 20000],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [6, 10],
            [9, 14],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': {
          base: 1,
          stops: [
            [0, '{abbr}'],
            [6, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.15,
        'text-max-width': 5,
      },
      paint: {
        'text-opacity': 1,
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'state-label-md',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856151690.9143',
      },
      source: 'ONLINE',
      'source-layer': 'state_label',
      minzoom: 3,
      maxzoom: 8,
      filter: ['all', ['<', 'area', 80000], ['>=', 'area', 20000]],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [5, 10],
            [8, 16],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': {
          base: 1,
          stops: [
            [0, '{abbr}'],
            [5, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.15,
        'text-max-width': 6,
      },
      paint: {
        'text-opacity': 1,
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'state-label-lg',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856151690.9143',
      },
      source: 'ONLINE',
      'source-layer': 'state_label',
      minzoom: 3,
      maxzoom: 7,
      filter: ['>=', 'area', 80000],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [4, 10],
            [7, 18],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-padding': 1,
        'text-field': {
          base: 1,
          stops: [
            [0, '{abbr}'],
            [4, '{name_en}'],
          ],
        },
        'text-letter-spacing': 0.15,
        'text-max-width': 6,
      },
      paint: {
        'text-opacity': 1,
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1,
      },
      interactive: true,
    },
    {
      id: 'country-label-sm',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856144497.7825',
      },
      source: 'ONLINE',
      'source-layer': 'country_label',
      minzoom: 1,
      maxzoom: 10,
      filter: ['>=', 'scalerank', 5],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': 6,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 0.9,
          stops: [
            [5, 14],
            [9, 22],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
      },
      interactive: true,
    },
    {
      id: 'country-label-md',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856144497.7825',
      },
      source: 'ONLINE',
      'source-layer': 'country_label',
      minzoom: 1,
      maxzoom: 8,
      filter: ['in', 'scalerank', 3, 4],
      layout: {
        'text-field': {
          base: 1,
          stops: [
            [0, '{code}'],
            [2, '{name_en}'],
          ],
        },
        'text-max-width': 6,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [3, 10],
            [8, 24],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
      },
      interactive: true,
    },
    {
      id: 'country-label-lg',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444856144497.7825',
      },
      source: 'ONLINE',
      'source-layer': 'country_label',
      minzoom: 1,
      maxzoom: 7,
      filter: ['in', 'scalerank', 1, 2],
      layout: {
        'text-field': '{name_en}',
        'text-max-width': {
          base: 1,
          stops: [
            [0, 5],
            [3, 6],
          ],
        },
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1,
          stops: [
            [1, 10],
            [6, 24],
          ],
        },
      },
      paint: {
        'text-color': 'hsl(230, 2%, 69%)',
        'text-halo-color': 'hsla(215, 7%, 5%, 0.85)',
        'text-halo-width': 1.25,
      },
      interactive: true,
    },
  ];
}

function getMapboxTopoLayers(source) {
  //could be multiple copies of these layers so need unique id's
  //Open Tiles Bright Theme
  return [
    {
      id: source + 'landcover-glacier',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landcover',
      filter: ['==', 'subclass', 'glacier'],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#fff',
        'fill-opacity': {
          base: 1,
          stops: [
            [0, 0.9],
            [10, 0.3],
          ],
        },
      },
    },
    {
      id: source + 'landuse-residential',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'residential'],
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [12, 'hsla(30, 19%, 90%, 0.4)'],
            [16, 'hsla(30, 19%, 90%, 0.2)'],
          ],
        },
      },
    },
    {
      id: source + 'landuse-commercial',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'commercial']],
      paint: {
        'fill-color': 'hsla(0, 60%, 87%, 0.23)',
      },
    },
    {
      id: source + 'landuse-industrial',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'industrial']],
      paint: {
        'fill-color': 'hsla(49, 100%, 88%, 0.34)',
      },
    },
    {
      id: source + 'park',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'park',
      filter: ['==', '$type', 'Polygon'],
      paint: {
        'fill-color': '#d8e8c8',
        'fill-opacity': {
          base: 1.8,
          stops: [
            [9, 0.5],
            [12, 0.2],
          ],
        },
      },
    },
    {
      id: source + 'park-outline',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'park',
      filter: ['==', '$type', 'Polygon'],
      layout: {},
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [6, 'hsla(96, 40%, 49%, 0.36)'],
            [8, 'hsla(96, 40%, 49%, 0.66)'],
          ],
        },
        'line-dasharray': [3, 3],
      },
    },
    {
      id: source + 'landuse-cemetery',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'cemetery'],
      paint: {
        'fill-color': '#e0e4dd',
      },
    },
    {
      id: source + 'landuse-hospital',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'hospital'],
      paint: {
        'fill-color': '#fde',
      },
    },
    {
      id: source + 'landuse-school',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'school'],
      paint: {
        'fill-color': '#f0e8f8',
      },
    },
    {
      id: source + 'landuse-railway',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landuse',
      filter: ['==', 'class', 'railway'],
      paint: {
        'fill-color': 'hsla(30, 19%, 90%, 0.4)',
      },
    },
    {
      id: source + 'landcover-wood',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landcover',
      filter: ['==', 'class', 'wood'],
      paint: {
        'fill-color': '#6a4',
        'fill-opacity': 0.1,
        'fill-outline-color': 'hsla(0, 0%, 0%, 0.03)',
        'fill-antialias': {
          base: 1,
          stops: [
            [0, false],
            [9, true],
          ],
        },
      },
    },
    {
      id: source + 'landcover-grass',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'landcover',
      filter: ['==', 'class', 'grass'],
      paint: {
        'fill-color': '#d8e8c8',
        'fill-opacity': 1,
      },
    },
    {
      id: source + 'landcover-grass-park',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849388993.3071',
      },
      source: source,
      'source-layer': 'park',
      filter: ['==', 'class', 'public_park'],
      paint: {
        'fill-color': '#d8e8c8',
        'fill-opacity': 0.8,
      },
    },
    {
      id: source + 'contour_label',
      type: 'symbol',
      metadata: {},
      source: source,
      'source-layer': 'contour',
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'nth_line', 10, 5], ['>', 'height', 0]],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [15, 9.5],
            [20, 12],
          ],
        },
        'text-allow-overlap': false,
        'symbol-avoid-edges': true,
        'text-ignore-placement': false,
        'symbol-placement': 'line',
        'text-padding': 10,
        'text-rotation-alignment': 'map',
        'text-field': '{height} m',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
      },
      paint: {
        'text-color': 'hsl(0, 0%, 0%)',
        'text-halo-color': 'hsla(0, 0%, 100%, 0.7)',
        'text-halo-width': 1.5,
      },
    },
    {
      id: source + 'contour_index',
      type: 'line',
      source: source,
      'source-layer': 'contour',
      filter: ['all', ['>', 'height', 0], ['in', 'nth_line', 10, 5]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsl(0, 1%, 58%)',
        'line-opacity': 0.4,
        'line-width': 1.1,
      },
    },
    {
      id: source + 'contour',
      type: 'line',
      source: source,
      'source-layer': 'contour',
      filter: ['all', ['!in', 'nth_line', 10, 5], ['>', 'height', 0]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsl(0, 1%, 58%)',
        'line-opacity': 0.3,
        'line-width': 0.6,
      },
    },
    {
      id: source + 'waterway-other',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'waterway',
      filter: ['!in', 'class', 'canal', 'river', 'stream'],
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 2],
          ],
        },
      },
    },
    {
      id: source + 'waterway-stream-canal',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'waterway',
      filter: ['in', 'class', 'canal', 'stream'],
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.3,
          stops: [
            [13, 0.5],
            [20, 6],
          ],
        },
      },
    },
    {
      id: source + 'waterway-river',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'waterway',
      filter: ['==', 'class', 'river'],
      layout: {
        'line-cap': 'round',
      },
      paint: {
        'line-color': '#a0c8f0',
        'line-width': {
          base: 1.2,
          stops: [
            [10, 0.8],
            [20, 6],
          ],
        },
      },
    },
    {
      id: source + 'water-offset',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'water',
      maxzoom: 8,
      filter: ['==', '$type', 'Polygon'],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-opacity': 1,
        'fill-color': '#a0c8f0',
        'fill-translate': {
          base: 1,
          stops: [
            [6, [2, 0]],
            [8, [0, 0]],
          ],
        },
      },
    },
    {
      id: source + 'water',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'water',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'hsl(210, 67%, 85%)',
      },
    },
    {
      id: source + 'water-pattern',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'water',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-translate': [0, 2.5],
        'fill-pattern': 'wave',
      },
    },
    {
      id: source + 'landcover-ice-shelf',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849382550.77',
      },
      source: source,
      'source-layer': 'landcover',
      filter: ['==', 'subclass', 'ice_shelf'],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': '#fff',
        'fill-opacity': {
          base: 1,
          stops: [
            [0, 0.9],
            [10, 0.3],
          ],
        },
      },
    },
    {
      id: source + 'building',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849364238.8171',
      },
      source: source,
      'source-layer': 'building',
      paint: {
        'fill-color': {
          base: 1,
          stops: [
            [15.5, '#f2eae2'],
            [16, '#dfdbd7'],
          ],
        },
        'fill-antialias': true,
      },
    },
    {
      id: source + 'building-top',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849364238.8171',
      },
      source: source,
      'source-layer': 'building',
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-translate': {
          base: 1,
          stops: [
            [14, [0, 0]],
            [16, [-2, -2]],
          ],
        },
        'fill-outline-color': '#dfdbd7',
        'fill-color': '#f2eae2',
        'fill-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [16, 1],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-service-track-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#cfcdca',
        'line-dasharray': [0.5, 0.25],
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1],
            [16, 4],
            [20, 11],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-minor-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'minor']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#cfcdca',
        'line-opacity': {
          stops: [
            [12, 0],
            [12.5, 1],
          ],
        },
        'line-width': {
          base: 1.2,
          stops: [
            [12, 0.5],
            [13, 1],
            [14, 4],
            [20, 15],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-secondary-tertiary-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [8, 1.5],
            [20, 17],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-trunk-primary-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.6],
            [7, 1.5],
            [20, 22],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-motorway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'motorway']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-dasharray': [0.5, 0.25],
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.6],
            [7, 1.5],
            [20, 22],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-path',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'path']]],
      paint: {
        'line-color': '#cba',
        'line-dasharray': [1.5, 0.75],
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1.2],
            [20, 4],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-service-track',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'service', 'track']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff',
        'line-width': {
          base: 1.2,
          stops: [
            [15.5, 0],
            [16, 2],
            [20, 7.5],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-minor',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'minor_road']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff4c6',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 10],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-trunk-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff4c6',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'motorway']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#ffdaa6',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'tunnel-railway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849354174.1904',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'tunnel'], ['==', 'class', 'rail']],
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
        'line-dasharray': [2, 2],
      },
    },
    {
      id: source + 'ferry',
      type: 'line',
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['in', 'class', 'ferry']],
      layout: {
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(108, 159, 182, 1)',
        'line-width': 1.1,
        'line-dasharray': [2, 2],
      },
    },
    {
      id: source + 'aeroway-taxiway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 12,
      filter: ['all', ['in', 'class', 'taxiway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(153, 153, 153, 1)',
        'line-width': {
          base: 1.5,
          stops: [
            [11, 2],
            [17, 12],
          ],
        },
        'line-opacity': 1,
      },
    },
    {
      id: source + 'aeroway-runway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 12,
      filter: ['all', ['in', 'class', 'runway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(153, 153, 153, 1)',
        'line-width': {
          base: 1.5,
          stops: [
            [11, 5],
            [17, 55],
          ],
        },
        'line-opacity': 1,
      },
    },
    {
      id: source + 'aeroway-area',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Polygon'], ['in', 'class', 'runway', 'taxiway']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-opacity': {
          base: 1,
          stops: [
            [13, 0],
            [14, 1],
          ],
        },
        'fill-color': 'rgba(255, 255, 255, 1)',
      },
    },
    {
      id: source + 'aeroway-taxiway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 4,
      filter: ['all', ['in', 'class', 'taxiway'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          base: 1.5,
          stops: [
            [11, 1],
            [17, 10],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
      },
    },
    {
      id: source + 'aeroway-runway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 4,
      filter: ['all', ['in', 'class', 'runway'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(255, 255, 255, 1)',
        'line-width': {
          base: 1.5,
          stops: [
            [11, 4],
            [17, 50],
          ],
        },
        'line-opacity': {
          base: 1,
          stops: [
            [11, 0],
            [12, 1],
          ],
        },
      },
    },
    {
      id: source + 'highway-area',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['==', '$type', 'Polygon'],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'hsla(0, 0%, 89%, 0.56)',
        'fill-outline-color': '#cfcdca',
        'fill-opacity': 0.9,
        'fill-antialias': false,
      },
    },
    {
      id: source + 'highway-motorway-link-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 12,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway_link']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
      },
    },
    {
      id: source + 'highway-link-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 13,
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'primary_link', 'secondary_link', 'tertiary_link', 'trunk_link'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
      },
    },
    {
      id: source + 'highway-minor-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!=', 'brunnel', 'tunnel'], ['in', 'class', 'minor', 'service', 'track']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#cfcdca',
        'line-opacity': {
          stops: [
            [12, 0],
            [12.5, 1],
          ],
        },
        'line-width': {
          base: 1.2,
          stops: [
            [12, 0.5],
            [13, 1],
            [14, 4],
            [20, 15],
          ],
        },
      },
    },
    {
      id: source + 'highway-secondary-tertiary-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [8, 1.5],
            [20, 17],
          ],
        },
      },
    },
    {
      id: source + 'highway-primary-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 5,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'primary']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': {
          stops: [
            [7, 0],
            [8, 1],
          ],
        },
        'line-width': {
          base: 1.2,
          stops: [
            [7, 0],
            [8, 0.6],
            [9, 1.5],
            [20, 22],
          ],
        },
      },
    },
    {
      id: source + 'highway-trunk-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 5,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': {
          stops: [
            [5, 0],
            [6, 1],
          ],
        },
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0],
            [6, 0.6],
            [7, 1.5],
            [20, 22],
          ],
        },
      },
    },
    {
      id: source + 'highway-motorway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 4,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [4, 0],
            [5, 0.4],
            [6, 0.6],
            [7, 1.5],
            [20, 22],
          ],
        },
        'line-opacity': {
          stops: [
            [4, 0],
            [5, 1],
          ],
        },
      },
    },
    {
      id: source + 'highway-path',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'path']],
      ],
      paint: {
        'line-color': '#cba',
        'line-dasharray': [1.5, 0.75],
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1.2],
            [20, 4],
          ],
        },
      },
    },
    {
      id: source + 'highway-motorway-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 12,
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway_link']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
    },
    {
      id: source + 'highway-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 13,
      filter: [
        'all',
        ['!in', 'brunnel', 'bridge', 'tunnel'],
        ['in', 'class', 'primary_link', 'secondary_link', 'tertiary_link', 'trunk_link'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
    },
    {
      id: source + 'highway-minor',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!=', 'brunnel', 'tunnel'], ['in', 'class', 'minor', 'service', 'track']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fff',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [13.5, 0],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
    },
    {
      id: source + 'highway-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [8, 0.5],
            [20, 13],
          ],
        },
      },
    },
    {
      id: source + 'highway-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'primary']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [8.5, 0],
            [9, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'highway-trunk',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['in', 'class', 'trunk']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'highway-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 5,
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'motorway']],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'railway-transit',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsla(0, 0%, 73%, 0.77)',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [20, 1],
          ],
        },
      },
    },
    {
      id: source + 'railway-transit-hatching',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsla(0, 0%, 73%, 0.68)',
        'line-dasharray': [0.2, 8],
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 2],
            [20, 6],
          ],
        },
      },
    },
    {
      id: source + 'railway-service',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'rail'], ['has', 'service']]],
      paint: {
        'line-color': 'hsla(0, 0%, 73%, 0.77)',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [20, 1],
          ],
        },
      },
    },
    {
      id: source + 'railway-service-hatching',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'rail'], ['has', 'service']]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsla(0, 0%, 73%, 0.68)',
        'line-dasharray': [0.2, 8],
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 2],
            [20, 6],
          ],
        },
      },
    },
    {
      id: source + 'railway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!has', 'service'], ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'rail']],
      ],
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
      },
    },
    {
      id: source + 'railway-hatching',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['all', ['!has', 'service'], ['!in', 'brunnel', 'bridge', 'tunnel'], ['==', 'class', 'rail']],
      ],
      paint: {
        'line-color': '#bbb',
        'line-dasharray': [0.2, 8],
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 3],
            [20, 8],
          ],
        },
      },
    },
    {
      id: source + 'bridge-motorway-link-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway_link']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
      },
    },
    {
      id: source + 'bridge-link-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', 'brunnel', 'bridge'],
        ['in', 'class', 'primary_link', 'secondary_link', 'tertiary_link', 'trunk_link'],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [12, 1],
            [13, 3],
            [14, 4],
            [20, 15],
          ],
        },
      },
    },
    {
      id: source + 'bridge-secondary-tertiary-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-opacity': 1,
        'line-width': {
          base: 1.2,
          stops: [
            [8, 1.5],
            [20, 28],
          ],
        },
      },
    },
    {
      id: source + 'bridge-trunk-primary-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(28, 76%, 67%)',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.6],
            [7, 1.5],
            [20, 26],
          ],
        },
      },
    },
    {
      id: source + 'bridge-motorway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#e9ac77',
        'line-width': {
          base: 1.2,
          stops: [
            [5, 0.4],
            [6, 0.6],
            [7, 1.5],
            [20, 22],
          ],
        },
      },
    },
    {
      id: source + 'bridge-path-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'path']]],
      paint: {
        'line-color': '#f8f4f0',
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1.2],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'bridge-path',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'path']]],
      paint: {
        'line-color': '#cba',
        'line-width': {
          base: 1.2,
          stops: [
            [15, 1.2],
            [20, 4],
          ],
        },
        'line-dasharray': [1.5, 0.75],
      },
    },
    {
      id: source + 'bridge-motorway-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway_link']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
    },
    {
      id: source + 'bridge-link',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: [
        'all',
        ['==', 'brunnel', 'bridge'],
        ['in', 'class', 'primary_link', 'secondary_link', 'tertiary_link', 'trunk_link'],
      ],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [12.5, 0],
            [13, 1.5],
            [14, 2.5],
            [20, 11.5],
          ],
        },
      },
    },
    {
      id: source + 'bridge-secondary-tertiary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'secondary', 'tertiary']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 20],
          ],
        },
      },
    },
    {
      id: source + 'bridge-trunk-primary',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['in', 'class', 'primary', 'trunk']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fea',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'bridge-motorway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'motorway']],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#fc8',
        'line-width': {
          base: 1.2,
          stops: [
            [6.5, 0],
            [7, 0.5],
            [20, 18],
          ],
        },
      },
    },
    {
      id: source + 'bridge-railway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'rail']],
      paint: {
        'line-color': '#bbb',
        'line-width': {
          base: 1.4,
          stops: [
            [14, 0.4],
            [15, 0.75],
            [20, 2],
          ],
        },
      },
    },
    {
      id: source + 'bridge-railway-hatching',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849334699.1902',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', 'brunnel', 'bridge'], ['==', 'class', 'rail']],
      paint: {
        'line-color': '#bbb',
        'line-dasharray': [0.2, 8],
        'line-width': {
          base: 1.4,
          stops: [
            [14.5, 0],
            [15, 3],
            [20, 8],
          ],
        },
      },
    },
    {
      id: source + 'cablecar',
      type: 'line',
      source: source,
      'source-layer': 'transportation',
      minzoom: 13,
      maxzoom: 24,
      filter: ['==', 'class', 'cable_car'],
      layout: {
        visibility: 'visible',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 70%)',
        'line-width': {
          base: 1,
          stops: [
            [11, 1],
            [19, 2.5],
          ],
        },
      },
    },
    {
      id: source + 'cablecar-dash',
      type: 'line',
      source: source,
      'source-layer': 'transportation',
      minzoom: 13,
      maxzoom: 24,
      filter: ['==', 'class', 'cable_car'],
      layout: {
        visibility: 'visible',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 70%)',
        'line-width': {
          base: 1,
          stops: [
            [11, 3],
            [19, 5.5],
          ],
        },
        'line-dasharray': [2, 3],
      },
    },
    {
      id: source + 'boundary-land-level-4',
      type: 'line',
      source: source,
      'source-layer': 'boundary',
      filter: ['all', ['in', 'admin_level', 4, 6, 8], ['!=', 'maritime', 1]],
      layout: {
        'line-join': 'round',
      },
      paint: {
        'line-color': '#9e9cab',
        'line-dasharray': [3, 1, 1, 1],
        'line-width': {
          base: 1.4,
          stops: [
            [4, 0.4],
            [5, 1],
            [12, 3],
          ],
        },
      },
    },
    {
      id: source + 'boundary-land-level-2',
      type: 'line',
      source: source,
      'source-layer': 'boundary',
      filter: ['all', ['==', 'admin_level', 2], ['!=', 'maritime', 1], ['!=', 'disputed', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(248, 7%, 66%)',
        'line-width': {
          base: 1,
          stops: [
            [0, 0.6],
            [4, 1.4],
            [5, 2],
            [12, 8],
          ],
        },
      },
    },
    {
      id: source + 'boundary-land-disputed',
      type: 'line',
      source: source,
      'source-layer': 'boundary',
      filter: ['all', ['!=', 'maritime', 1], ['==', 'disputed', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(248, 7%, 70%)',
        'line-dasharray': [1, 3],
        'line-width': {
          base: 1,
          stops: [
            [0, 0.6],
            [4, 1.4],
            [5, 2],
            [12, 8],
          ],
        },
      },
    },
    {
      id: source + 'boundary-water',
      type: 'line',
      source: source,
      'source-layer': 'boundary',
      filter: ['all', ['in', 'admin_level', 2, 4], ['==', 'maritime', 1]],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'rgba(154, 189, 214, 1)',
        'line-width': {
          base: 1,
          stops: [
            [0, 0.6],
            [4, 1.4],
            [5, 2],
            [12, 8],
          ],
        },
        'line-opacity': {
          stops: [
            [6, 0.6],
            [10, 1],
          ],
        },
      },
    },
    {
      id: source + 'waterway-name',
      type: 'symbol',
      source: source,
      'source-layer': 'waterway',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['has', 'name']],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': 14,
        'text-field': '{name:latin} {name:nonlatin}',
        'text-max-width': 5,
        'text-rotation-alignment': 'map',
        'symbol-placement': 'line',
        'text-letter-spacing': 0.2,
        'symbol-spacing': 350,
      },
      paint: {
        'text-color': '#74aee9',
        'text-halo-width': 1.5,
        'text-halo-color': 'rgba(255,255,255,0.7)',
      },
    },
    {
      id: source + 'water-name-lakeline',
      type: 'symbol',
      source: source,
      'source-layer': 'water_name',
      filter: ['==', '$type', 'LineString'],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': 14,
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 5,
        'text-rotation-alignment': 'map',
        'symbol-placement': 'line',
        'symbol-spacing': 350,
        'text-letter-spacing': 0.2,
      },
      paint: {
        'text-color': '#74aee9',
        'text-halo-width': 1.5,
        'text-halo-color': 'rgba(255,255,255,0.7)',
      },
    },
    {
      id: source + 'water-name-ocean',
      type: 'symbol',
      source: source,
      'source-layer': 'water_name',
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'ocean']],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': 14,
        'text-field': '{name:latin}',
        'text-max-width': 5,
        'text-rotation-alignment': 'map',
        'symbol-placement': 'point',
        'symbol-spacing': 350,
        'text-letter-spacing': 0.2,
      },
      paint: {
        'text-color': '#74aee9',
        'text-halo-width': 1.5,
        'text-halo-color': 'rgba(255,255,255,0.7)',
      },
    },
    {
      id: source + 'water-name-other',
      type: 'symbol',
      source: source,
      'source-layer': 'water_name',
      filter: ['all', ['==', '$type', 'Point'], ['!in', 'class', 'ocean']],
      layout: {
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-size': {
          stops: [
            [0, 10],
            [6, 14],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 5,
        'text-rotation-alignment': 'map',
        'symbol-placement': 'point',
        'symbol-spacing': 350,
        'text-letter-spacing': 0.2,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#74aee9',
        'text-halo-width': 1.5,
        'text-halo-color': 'rgba(255,255,255,0.7)',
      },
    },
    {
      id: source + 'poi-level-3',
      type: 'symbol',
      source: source,
      'source-layer': 'poi',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'Point'], ['>=', 'rank', 25]],
      layout: {
        'text-padding': 2,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-anchor': 'top',
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-offset': [0, 0.6],
        'text-size': 12,
        'text-max-width': 9,
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#666',
        'text-halo-width': 1,
        'text-halo-color': '#ffffff',
      },
    },
    {
      id: source + 'poi-level-2',
      type: 'symbol',
      source: source,
      'source-layer': 'poi',
      minzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['<=', 'rank', 24], ['>=', 'rank', 15]],
      layout: {
        'text-padding': 2,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-anchor': 'top',
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-offset': [0, 0.6],
        'text-size': 12,
        'text-max-width': 9,
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#666',
        'text-halo-width': 1,
        'text-halo-color': '#ffffff',
      },
    },
    {
      id: source + 'poi-level-1',
      type: 'symbol',
      source: source,
      'source-layer': 'poi',
      minzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['<=', 'rank', 14], ['has', 'name']],
      layout: {
        'text-padding': 2,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-anchor': 'top',
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-offset': [0, 0.6],
        'text-size': 12,
        'text-max-width': 9,
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#666',
        'text-halo-width': 1,
        'text-halo-color': '#ffffff',
      },
    },
    {
      id: source + 'poi-railway',
      type: 'symbol',
      source: source,
      'source-layer': 'poi',
      minzoom: 13,
      maxzoom: 24,
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['has', 'name'],
        ['==', 'class', 'railway'],
        ['==', 'subclass', 'station'],
      ],
      layout: {
        'text-padding': 2,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-anchor': 'top',
        'icon-image': '{class}_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-offset': [0, 0.6],
        'text-size': 12,
        'text-max-width': 9,
        'icon-optional': false,
        'icon-ignore-placement': false,
        'icon-allow-overlap': false,
        'text-ignore-placement': false,
        'text-allow-overlap': false,
        'text-optional': true,
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#666',
        'text-halo-width': 1,
        'text-halo-color': '#ffffff',
      },
    },
    {
      id: source + 'highway-name-path',
      type: 'symbol',
      source: source,
      'source-layer': 'transportation_name',
      minzoom: 15.5,
      filter: ['==', 'class', 'path'],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [13, 12],
            [14, 13],
          ],
        },
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-field': '{name:latin} {name:nonlatin}',
        'symbol-placement': 'line',
        'text-rotation-alignment': 'map',
      },
      paint: {
        'text-halo-color': '#f8f4f0',
        'text-color': 'hsl(30, 23%, 62%)',
        'text-halo-width': 0.5,
      },
    },
    {
      id: source + 'highway-name-minor',
      type: 'symbol',
      source: source,
      'source-layer': 'transportation_name',
      minzoom: 15,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'minor', 'service', 'track']],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [13, 12],
            [14, 13],
          ],
        },
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-field': '{name:latin} {name:nonlatin}',
        'symbol-placement': 'line',
        'text-rotation-alignment': 'map',
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#765',
        'text-halo-width': 1,
      },
    },
    {
      id: source + 'highway-name-major',
      type: 'symbol',
      source: source,
      'source-layer': 'transportation_name',
      minzoom: 12.2,
      filter: ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk'],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [13, 12],
            [14, 13],
          ],
        },
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-field': '{name:latin} {name:nonlatin}',
        'symbol-placement': 'line',
        'text-rotation-alignment': 'map',
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#765',
        'text-halo-width': 1,
      },
    },
    {
      id: source + 'highway-shield',
      type: 'symbol',
      source: source,
      'source-layer': 'transportation_name',
      minzoom: 8,
      filter: [
        'all',
        ['<=', 'ref_length', 6],
        ['==', '$type', 'LineString'],
        ['!in', 'network', 'us-interstate', 'us-highway', 'us-state'],
      ],
      layout: {
        'text-size': 10,
        'icon-image': 'road_{ref_length}',
        'icon-rotation-alignment': 'viewport',
        'symbol-spacing': 200,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'text-rotation-alignment': 'viewport',
        'icon-size': 1,
        'text-field': '{ref}',
      },
      paint: {},
    },
    {
      id: source + 'highway-shield-us-interstate',
      type: 'symbol',
      source: source,
      'source-layer': 'transportation_name',
      minzoom: 7,
      filter: ['all', ['<=', 'ref_length', 6], ['==', '$type', 'LineString'], ['in', 'network', 'us-interstate']],
      layout: {
        'text-size': 10,
        'icon-image': '{network}_{ref_length}',
        'icon-rotation-alignment': 'viewport',
        'symbol-spacing': 200,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': {
          base: 1,
          stops: [
            [7, 'point'],
            [7, 'line'],
            [8, 'line'],
          ],
        },
        'text-rotation-alignment': 'viewport',
        'icon-size': 1,
        'text-field': '{ref}',
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
      },
    },
    {
      id: source + 'highway-shield-us-other',
      type: 'symbol',
      source: source,
      'source-layer': 'transportation_name',
      minzoom: 9,
      filter: [
        'all',
        ['<=', 'ref_length', 6],
        ['==', '$type', 'LineString'],
        ['in', 'network', 'us-highway', 'us-state'],
      ],
      layout: {
        'text-size': 10,
        'icon-image': '{network}_{ref_length}',
        'icon-rotation-alignment': 'viewport',
        'symbol-spacing': 200,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': {
          base: 1,
          stops: [
            [10, 'point'],
            [11, 'line'],
          ],
        },
        'text-rotation-alignment': 'viewport',
        'icon-size': 1,
        'text-field': '{ref}',
      },
      paint: {
        'text-color': 'rgba(0, 0, 0, 1)',
      },
    },
    {
      id: source + 'airport-label-major',
      type: 'symbol',
      source: source,
      'source-layer': 'aerodrome_label',
      minzoom: 10,
      filter: ['all', ['has', 'iata']],
      layout: {
        'text-padding': 2,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-anchor': 'top',
        'icon-image': 'airport_11',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-offset': [0, 0.6],
        'text-size': 12,
        'text-max-width': 9,
        visibility: 'visible',
        'icon-size': 1,
        'text-optional': true,
      },
      paint: {
        'text-halo-blur': 0.5,
        'text-color': '#666',
        'text-halo-width': 1,
        'text-halo-color': '#ffffff',
      },
    },
    {
      id: source + 'place-other',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['!in', 'class', 'city', 'town', 'village', 'country', 'continent'],
      layout: {
        'text-letter-spacing': 0.1,
        'text-size': {
          base: 1.2,
          stops: [
            [12, 10],
            [15, 14],
          ],
        },
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-transform': 'uppercase',
        'text-max-width': 9,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#633',
        'text-halo-width': 1.2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-village',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['==', 'class', 'village'],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [10, 12],
            [15, 22],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 8,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#333',
        'text-halo-width': 1.2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-town',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['==', 'class', 'town'],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [10, 14],
            [15, 24],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 8,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#333',
        'text-halo-width': 1.2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-city',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['all', ['!=', 'capital', 2], ['==', 'class', 'city']],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [7, 14],
            [11, 24],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 8,
        visibility: 'visible',
      },
      paint: {
        'text-color': '#333',
        'text-halo-width': 1.2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-city-capital',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['all', ['==', 'capital', 2], ['==', 'class', 'city']],
      layout: {
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-size': {
          base: 1.2,
          stops: [
            [7, 14],
            [11, 24],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-max-width': 8,
        'icon-image': 'star_11',
        'text-offset': [0.4, 0],
        'icon-size': 0.8,
        'text-anchor': 'left',
        visibility: 'visible',
      },
      paint: {
        'text-color': '#333',
        'text-halo-width': 1.2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-country-other',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['all', ['==', 'class', 'country'], ['>=', 'rank', 3], ['!has', 'iso_a2']],
      layout: {
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-field': '{name:latin}',
        'text-size': {
          stops: [
            [3, 11],
            [7, 17],
          ],
        },
        'text-transform': 'uppercase',
        'text-max-width': 6.25,
        visibility: 'visible',
      },
      paint: {
        'text-halo-blur': 1,
        'text-color': '#334',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-country-3',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['all', ['==', 'class', 'country'], ['>=', 'rank', 3], ['has', 'iso_a2']],
      layout: {
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': '{name:latin}',
        'text-size': {
          stops: [
            [3, 11],
            [7, 17],
          ],
        },
        'text-transform': 'uppercase',
        'text-max-width': 6.25,
        visibility: 'visible',
      },
      paint: {
        'text-halo-blur': 1,
        'text-color': '#334',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-country-2',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['all', ['==', 'class', 'country'], ['==', 'rank', 2], ['has', 'iso_a2']],
      layout: {
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': '{name:latin}',
        'text-size': {
          stops: [
            [2, 11],
            [5, 17],
          ],
        },
        'text-transform': 'uppercase',
        'text-max-width': 6.25,
        visibility: 'visible',
      },
      paint: {
        'text-halo-blur': 1,
        'text-color': '#334',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-country-1',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      filter: ['all', ['==', 'class', 'country'], ['==', 'rank', 1], ['has', 'iso_a2']],
      layout: {
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': '{name:latin}',
        'text-size': {
          stops: [
            [1, 11],
            [4, 17],
          ],
        },
        'text-transform': 'uppercase',
        'text-max-width': 6.25,
        visibility: 'visible',
      },
      paint: {
        'text-halo-blur': 1,
        'text-color': '#334',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
    {
      id: source + 'place-continent',
      type: 'symbol',
      metadata: {
        'mapbox:group': '1444849242106.713',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 1,
      filter: ['==', 'class', 'continent'],
      layout: {
        'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Bold'],
        'text-field': '{name:latin}',
        'text-size': 14,
        'text-max-width': 6.25,
        'text-transform': 'uppercase',
        visibility: 'visible',
      },
      paint: {
        'text-halo-blur': 1,
        'text-color': '#334',
        'text-halo-width': 2,
        'text-halo-color': 'rgba(255,255,255,0.8)',
      },
    },
  ];
}
function getMapboxDarkLayers(source) {
  //could be multiple copies of these layers so need unique id's
  return [
    {
      id: source + 'water',
      type: 'fill',
      source: source,
      'source-layer': 'water',
      filter: ['==', '$type', 'Polygon'],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'rgb(27 ,27 ,29)',
        'fill-antialias': false,
      },
    },
    {
      id: source + 'landcover_ice_shelf',
      type: 'fill',
      source: source,
      'source-layer': 'landcover',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'subclass', 'ice_shelf']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'rgb(12,12,12)',
        'fill-opacity': 0.7,
      },
    },
    {
      id: source + 'landcover_glacier',
      type: 'fill',
      source: source,
      'source-layer': 'landcover',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'subclass', 'glacier']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'hsl(0, 1%, 2%)',
        'fill-opacity': {
          base: 1,
          stops: [
            [0, 1],
            [8, 0.5],
          ],
        },
      },
    },
    {
      id: source + 'landuse_residential',
      type: 'fill',
      source: source,
      'source-layer': 'landuse',
      maxzoom: 9,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'residential']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'hsl(0, 2%, 5%)',
        'fill-opacity': 0.4,
      },
    },
    {
      id: source + 'landcover_wood',
      type: 'fill',
      source: source,
      'source-layer': 'landcover',
      minzoom: 10,
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'wood']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'rgb(32,32,32)',
        'fill-opacity': {
          base: 0.3,
          stops: [
            [8, 0],
            [10, 0.8],
            [13, 0.4],
          ],
        },
        'fill-translate': [0, 0],
        'fill-pattern': 'wood-pattern',
      },
    },
    {
      id: source + 'landuse_park',
      type: 'fill',
      source: source,
      'source-layer': 'landuse',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'class', 'park']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-color': 'rgb(32,32,32)',
      },
    },
    {
      id: source + 'contour_label',
      type: 'symbol',
      metadata: {},
      source: source,
      'source-layer': 'contour',
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'nth_line', 10, 5], ['>', 'height', 0]],
      layout: {
        'text-size': {
          base: 1,
          stops: [
            [15, 9.5],
            [20, 12],
          ],
        },
        'text-allow-overlap': false,
        'symbol-avoid-edges': true,
        'text-ignore-placement': false,
        'symbol-placement': 'line',
        'text-padding': 10,
        'text-rotation-alignment': 'map',
        'text-field': '{height} m',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
      },
      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-halo-color': 'hsla(0, 0%, 0%, 0.7)',
        'text-halo-width': 1.5,
      },
    },
    {
      id: source + 'contour_index',
      type: 'line',
      source: source,
      'source-layer': 'contour',
      filter: ['all', ['>', 'height', 0], ['in', 'nth_line', 10, 5]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 16%)',
        'line-opacity': 0.4,
        'line-width': 1.1,
      },
    },
    {
      id: source + 'contour',
      type: 'line',
      source: source,
      'source-layer': 'contour',
      filter: ['all', ['!in', 'nth_line', 10, 5], ['>', 'height', 0]],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 16%)',
        'line-opacity': 0.3,
        'line-width': 0.6,
      },
    },
    {
      id: source + 'waterway',
      type: 'line',
      source: source,
      'source-layer': 'waterway',
      filter: ['==', '$type', 'LineString'],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgb(27 ,27 ,29)',
      },
    },
    {
      id: source + 'water_name',
      type: 'symbol',
      source: source,
      'source-layer': 'water_name',
      filter: ['==', '$type', 'LineString'],
      layout: {
        'text-field': '{name:latin}\n{name:nonlatin}',
        'symbol-placement': 'line',
        'text-rotation-alignment': 'map',
        'symbol-spacing': 500,
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-size': 12,
      },
      paint: {
        'text-color': 'hsla(0, 0%, 0%, 0.7)',
        'text-halo-color': 'hsl(0, 0%, 27%)',
      },
    },
    {
      id: source + 'building',
      type: 'fill',
      source: source,
      'source-layer': 'building',
      minzoom: 12,
      filter: ['==', '$type', 'Polygon'],
      paint: {
        'fill-color': 'rgb(10,10,10)',
        'fill-outline-color': 'rgb(27 ,27 ,29)',
        'fill-antialias': true,
      },
    },
    {
      id: source + 'aeroway-taxiway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 12,
      filter: ['all', ['in', 'class', 'taxiway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#181818',
        'line-width': {
          base: 1.55,
          stops: [
            [13, 1.8],
            [20, 20],
          ],
        },
        'line-opacity': 1,
      },
    },
    {
      id: source + 'aeroway-runway-casing',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['in', 'class', 'runway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(60,60,60,0.8)',
        'line-width': {
          base: 1.5,
          stops: [
            [11, 5],
            [17, 55],
          ],
        },
        'line-opacity': 1,
      },
    },
    {
      id: source + 'aeroway-area',
      type: 'fill',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 4,
      filter: ['all', ['==', '$type', 'Polygon'], ['in', 'class', 'runway', 'taxiway']],
      layout: {
        visibility: 'visible',
      },
      paint: {
        'fill-opacity': 1,
        'fill-color': '#000',
      },
    },
    {
      id: source + 'aeroway-runway',
      type: 'line',
      metadata: {
        'mapbox:group': '1444849345966.4436',
      },
      source: source,
      'source-layer': 'aeroway',
      minzoom: 11,
      filter: ['all', ['in', 'class', 'runway'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#000',
        'line-width': {
          base: 1.5,
          stops: [
            [11, 4],
            [17, 50],
          ],
        },
        'line-opacity': 1,
      },
    },
    {
      id: source + 'highway_path',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'path']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgb(27 ,27 ,29)',
        'line-width': {
          base: 1.2,
          stops: [
            [13, 1],
            [20, 10],
          ],
        },
        'line-opacity': 0.9,
        'line-dasharray': [1.5, 1.5],
      },
    },
    {
      id: source + 'highway_minor',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 8,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'minor', 'service', 'track']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#181818',
        'line-width': {
          base: 1.55,
          stops: [
            [13, 1.8],
            [20, 20],
          ],
        },
        'line-opacity': 0.9,
      },
    },
    {
      id: source + 'highway_major_casing',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk']],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(60,60,60,0.8)',
        'line-dasharray': [12, 0],
        'line-width': {
          base: 1.3,
          stops: [
            [10, 3],
            [20, 23],
          ],
        },
      },
    },
    {
      id: source + 'highway_major_inner',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 7%)',
        'line-width': {
          base: 1.3,
          stops: [
            [10, 2],
            [20, 20],
          ],
        },
      },
    },
    {
      id: source + 'highway_major_subtle',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 6,
      maxzoom: 11,
      filter: ['all', ['==', '$type', 'LineString'], ['in', 'class', 'primary', 'secondary', 'tertiary', 'trunk']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#2a2a2a',
        'line-width': {
          stops: [
            [6, 0],
            [8, 2],
          ],
        },
      },
    },
    {
      id: source + 'highway_motorway_casing',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
      layout: {
        'line-cap': 'butt',
        'line-join': 'miter',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'rgba(60,60,60,0.8)',
        'line-width': {
          base: 1.4,
          stops: [
            [5.8, 0],
            [6, 3],
            [20, 40],
          ],
        },
        'line-dasharray': [2, 0],
        'line-opacity': 1,
      },
    },
    {
      id: source + 'highway_motorway_inner',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': {
          base: 1,
          stops: [
            [5.8, 'hsla(0, 0%, 85%, 0.53)'],
            [6, '#000'],
          ],
        },
        'line-width': {
          base: 1.4,
          stops: [
            [4, 2],
            [6, 1.3],
            [20, 30],
          ],
        },
      },
    },
    {
      id: source + 'highway_motorway_subtle',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      maxzoom: 6,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': '#181818',
        'line-width': {
          base: 1.4,
          stops: [
            [4, 2],
            [6, 1.3],
          ],
        },
      },
    },
    {
      id: source + 'railway_transit',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'rgb(35,35,35)',
        'line-width': 3,
      },
    },
    {
      id: source + 'railway_transit_dashline',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'transit'], ['!in', 'brunnel', 'tunnel']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'rgb(12,12,12)',
        'line-width': 2,
        'line-dasharray': [3, 3],
      },
    },
    {
      id: source + 'railway_minor',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'rail'], ['has', 'service']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'rgb(35,35,35)',
        'line-width': 3,
      },
    },
    {
      id: source + 'railway_minor_dashline',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 16,
      filter: ['all', ['==', '$type', 'LineString'], ['all', ['==', 'class', 'rail'], ['has', 'service']]],
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'rgb(12,12,12)',
        'line-width': 2,
        'line-dasharray': [3, 3],
      },
    },
    {
      id: source + 'railway',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'rail'], ['!has', 'service']],
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-width': {
          base: 1.3,
          stops: [
            [16, 3],
            [20, 7],
          ],
        },
        'line-color': 'rgb(35,35,35)',
      },
    },
    {
      id: source + 'railway_dashline',
      type: 'line',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation',
      minzoom: 13,
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'rail'], ['!has', 'service']],
      layout: {
        visibility: 'visible',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'rgb(12,12,12)',
        'line-width': {
          base: 1.3,
          stops: [
            [16, 2],
            [20, 6],
          ],
        },
        'line-dasharray': [3, 3],
      },
    },
    {
      id: source + 'highway_name_other',
      type: 'symbol',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation_name',
      filter: ['all', ['!=', 'class', 'motorway'], ['==', '$type', 'LineString']],
      layout: {
        'text-size': 10,
        'text-max-angle': 30,
        'text-transform': 'uppercase',
        'symbol-spacing': 350,
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        visibility: 'visible',
        'text-rotation-alignment': 'map',
        'text-pitch-alignment': 'viewport',
        'text-field': '{name:latin} {name:nonlatin}',
      },
      paint: {
        'text-color': 'rgba(80, 78, 78, 1)',
        'text-translate': [0, 0],
        'text-halo-color': 'rgba(0, 0, 0, 1)',
        'text-halo-width': 1,
        'text-halo-blur': 0,
      },
    },
    {
      id: source + 'highway_name_motorway',
      type: 'symbol',
      metadata: {
        'mapbox:group': 'b6371a3f2f5a9932464fa3867530a2e5',
      },
      source: source,
      'source-layer': 'transportation_name',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'class', 'motorway']],
      layout: {
        'text-size': 10,
        'symbol-spacing': 350,
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Regular'],
        'symbol-placement': 'line',
        visibility: 'visible',
        'text-rotation-alignment': 'viewport',
        'text-pitch-alignment': 'viewport',
        'text-field': '{ref}',
      },
      paint: {
        'text-color': 'hsl(0, 0%, 37%)',
        'text-translate': [0, 2],
      },
    },
    {
      id: source + 'boundary_state',
      type: 'line',
      metadata: {
        'mapbox:group': 'a14c9607bc7954ba1df7205bf660433f',
      },
      source: source,
      'source-layer': 'boundary',
      filter: ['==', 'admin_level', 4],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'visible',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 21%)',
        'line-width': {
          base: 1.3,
          stops: [
            [3, 1],
            [22, 15],
          ],
        },
        'line-blur': 0.4,
        'line-dasharray': [2, 2],
        'line-opacity': 1,
      },
    },
    {
      id: source + 'boundary_country',
      type: 'line',
      metadata: {
        'mapbox:group': 'a14c9607bc7954ba1df7205bf660433f',
      },
      source: source,
      'source-layer': 'boundary',
      filter: ['==', 'admin_level', 2],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': 'hsl(0, 0%, 23%)',
        'line-width': {
          base: 1.1,
          stops: [
            [3, 1],
            [22, 20],
          ],
        },
        'line-blur': {
          base: 1,
          stops: [
            [0, 0.4],
            [22, 4],
          ],
        },
        'line-opacity': 1,
      },
    },
    {
      id: source + 'place_other',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['in', 'class', 'hamlet', 'isolated_dwelling', 'neighbourhood']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['Metropolis Regular', 'Noto Sans Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-offset': [0.5, 0],
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}',
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
    },
    {
      id: source + 'place_suburb',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'suburb']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-justify': 'center',
        visibility: 'visible',
        'text-offset': [0.5, 0],
        'text-anchor': 'center',
        'text-field': '{name:latin}\n{name:nonlatin}',
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
    },
    {
      id: source + 'place_village',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'village']],
      layout: {
        'text-size': 10,
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-justify': 'left',
        visibility: 'visible',
        'text-offset': [0.5, 0.2],
        'icon-size': 0.4,
        'text-anchor': 'left',
        'text-field': '{name:latin}\n{name:nonlatin}',
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'icon-opacity': 0.7,
      },
    },
    {
      id: source + 'place_town',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 15,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'town']],
      layout: {
        'text-size': 10,
        'icon-image': {
          base: 1,
          stops: [
            [0, 'circle-11'],
            [9, ''],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-justify': 'left',
        visibility: 'visible',
        'text-offset': [0.5, 0.2],
        'icon-size': 0.4,
        'text-anchor': {
          base: 1,
          stops: [
            [0, 'left'],
            [8, 'center'],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'icon-opacity': 0.7,
      },
    },
    {
      id: source + 'place_city',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 14,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'city'], ['>', 'rank', 3]],
      layout: {
        'text-size': 10,
        'icon-image': {
          base: 1,
          stops: [
            [0, 'circle-11'],
            [9, ''],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-justify': 'left',
        visibility: 'visible',
        'text-offset': [0.5, 0.2],
        'icon-size': 0.4,
        'text-anchor': {
          base: 1,
          stops: [
            [0, 'left'],
            [8, 'center'],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'icon-opacity': 0.7,
      },
    },
    {
      id: source + 'place_city_large',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 12,
      filter: ['all', ['==', '$type', 'Point'], ['<=', 'rank', 3], ['==', 'class', 'city']],
      layout: {
        'text-size': 14,
        'icon-image': {
          base: 1,
          stops: [
            [0, 'circle-11'],
            [9, ''],
          ],
        },
        'text-transform': 'uppercase',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-justify': 'left',
        visibility: 'visible',
        'text-offset': [0.5, 0.2],
        'icon-size': 0.4,
        'text-anchor': {
          base: 1,
          stops: [
            [0, 'left'],
            [8, 'center'],
          ],
        },
        'text-field': '{name:latin}\n{name:nonlatin}',
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'icon-opacity': 0.7,
      },
    },
    {
      id: source + 'place_state',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 12,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'state']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}\n{name:nonlatin}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-transform': 'uppercase',
        'text-size': 10,
      },
      paint: {
        'text-color': 'rgb(101,101,101)',
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
    },
    {
      id: source + 'place_country_other',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'country'], ['!has', 'iso_a2']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}',
        'text-font': ['DIN Offc Pro Italic', 'Arial Unicode MS Regular'],
        'text-transform': 'uppercase',
        'text-size': {
          base: 1,
          stops: [
            [0, 9],
            [1, 11],
          ],
        },
      },
      paint: {
        'text-halo-width': 1.4,
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-color': 'rgb(101,101,101)',
      },
    },
    {
      id: source + 'place_country_minor',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 8,
      filter: ['all', ['==', '$type', 'Point'], ['==', 'class', 'country'], ['>=', 'rank', 2], ['has', 'iso_a2']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-transform': 'uppercase',
        'text-size': {
          base: 1,
          stops: [
            [0, 10],
            [6, 12],
          ],
        },
      },
      paint: {
        'text-halo-width': 1.4,
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-color': 'rgb(101,101,101)',
      },
    },
    {
      id: source + 'place_country_major',
      type: 'symbol',
      metadata: {
        'mapbox:group': '101da9f13b64a08fa4b6ac1168e89e5f',
      },
      source: source,
      'source-layer': 'place',
      maxzoom: 6,
      filter: ['all', ['==', '$type', 'Point'], ['<=', 'rank', 1], ['==', 'class', 'country'], ['has', 'iso_a2']],
      layout: {
        visibility: 'visible',
        'text-field': '{name:latin}',
        'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
        'text-transform': 'uppercase',
        'text-size': {
          base: 1.4,
          stops: [
            [0, 10],
            [3, 12],
            [4, 14],
          ],
        },
        'text-anchor': 'center',
      },
      paint: {
        'text-halo-width': 1.4,
        'text-halo-color': 'rgba(0,0,0,0.7)',
        'text-color': 'rgb(101,101,101)',
      },
    },
  ];
}
