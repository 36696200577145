class UsAmericanTerminalProceduresDatabase {
  static openVolumes = new Map();

  constructor(volume) {
    this.volume = volume;
  }

  static open(volume) {
    let c = this.openVolumes.get(volume);

    if (c == null) {
      c = new UsAmericanTerminalProceduresDatabase(volume);
      this.openVolumes.set(volume, c);
    }

    return c;
  }

  async openConnection() {
    if (this.connection == null) {
      this.connection = await window.vueApp.utilities.sqlite.open(`US_IFR_${volume}.mbtiles`);
    }

    return this.connection;
  }

  /**
   *
   * @param {ISqliteQuery} queryObject
   * @returns {Promise<any[]>}
   */
  async raw(queryObject) {
    await this.openConnection();
    return await this.connection.query(queryObject);
  }
}
