var ClientPK = -2;
function EXPENSES_SelClient(index, JobID = 0) {
  console.log(index);
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  var ClientPK = -2;
  $('#EXPENSES_Jobs_Filter').empty();
  if (index >= 0) {
    Jobs = Clients[index].JOBS;
    ClientPK = Clients[index].PrimaryKey;
  }
  if (index == -1) {
    ClientPK = -1;
    Jobs = [];
    $('#EXPENSES_Jobs_Filter').append($('<option />').val('0').text(iTrans('Non Revenue')));
  } else {
    $('#EXPENSES_Jobs_Filter').append($('<option />').val('0').text(iTrans('All')));
  }
  for (var c in Jobs) {
    $('#EXPENSES_Jobs_Filter').append($('<option />').val(Jobs[c].PrimaryKey).text(Jobs[c].Name));
  }
  $('#EXPENSES_Jobs_Filter').val(JobID);
  $('#EXPENSES_Jobs_Filter').selectmenu('refresh', true);
  $('#Title_Report_Name').html(iTrans('Expense Reports'));
  EXPENSES_CreateReport(1);
}

function EXPENSES_SelJob(pk) {
  var Client = $('#EXPENSES_Client_Filter').val();
  if (Client != -1 && Client != -2) ClientPK = Report_Data[0].Clients[Client].PrimaryKey;
  else ClientPK = Client;
  EXPENSES_CreateReport(1);
}

function Report_EXPENSES() {
  $('#Title_Report_Name').html(iTrans('Expense Reports'));
  var Clients = Report_Data[0].Clients;
  var Jobs = Report_Data[0].Jobs;
  const ClientJobsModuleStatus = Report_Data[0].ClientsJobsModuleStatus;

  var html = '';
  html += "<h1 id='EXPENSES_Rec_Count'>" + iTrans('Filters') + '</h1>';
  html += '<label for="showPaidExpenses">' + iTrans('Show Paid Expenses') + '</label>';
  html += '<input type="checkbox" id="showPaidExpenses" onClick="EXPENSES_CreateReport(1);" />';
  html += '<div class="ui-grid-a  noprint" id="RPT_TDG_Filter1">';
  html +=
    '<div class="ui-block-a"><label for="RPT_FromDate">' +
    iTrans('From Date') +
    '</label><input type="Date" id="RPT_FromDate" data-mini="true" onChange="EXPENSES_CreateReport(1);" /></div>';
  html +=
    '<div class="ui-block-b"><label for="RPT_ToDate">' +
    iTrans('To Date') +
    '</label><input type="Date" id="RPT_ToDate" data-mini="true" value="" onChange="EXPENSES_CreateReport(1);" /></div></div>';
  html += '<div class="ui-grid-a" id="RPT_TDG_Filter2">';
  html +=
    '<div class="ui-block-a"><label for="RPT_filtername">' +
    iTrans('Filter Staff Name') +
    '</label><select id="RPT_filtername" data-mini="true" onChange="EXPENSES_CreateReport(1);" ><option value="All" selected>' +
    iTrans('All') +
    '</option></select></div>';
  html +=
    '<div class="ui-block-b"><label for="RPT_Idents">Filter Ident</label><select id="RPT_Idents" data-mini="true" onChange="EXPENSES_CreateReport(1);" disabled><option value="All" selected>' +
    iTrans('All') +
    '</option></select></div></div>';
  // Filter related to Clients & Jobs
  if (ClientJobsModuleStatus) {
    html +=
      '<input type="text" placeholder="' +
      iTrans('Search Client / Job - Type Here') +
      '..." id="EXPENSES_SearchClientJob" />';
  }
  html += '<label for"EXPENSES_Client_Filter">' + iTrans('Client') + '</label>';
  html += '<select id="EXPENSES_Client_Filter" onChange="EXPENSES_SelClient(this.value)">';
  html += '<option value="-2" selected>' + iTrans('All') + '</option>';
  if (ClientJobsModuleStatus) {
    html += '<option value="-1">' + iTrans('Company Only') + '</option>';
    for (var c in Clients) {
      var C = Clients[c];
      html += '<option value="' + c + '">' + C.Business + '</option>';
    }
  }
  html += '</select>';

  html += '<label for="EXPENSES_Jobs_Filter">' + iTrans('Job') + '</label>';
  html += '<select id="EXPENSES_Jobs_Filter" onChange="EXPENSES_SelJob(this.value)">';
  html += '<option value="0" selected>' + iTrans('All') + '</option>';
  if (ClientJobsModuleStatus) {
    for (var j in Jobs) {
      var J = Jobs[j];
      html += '<option value="' + J.PrimaryKey + '">#' + J.JobNum + ': ' + J.Name + '</option>';
    }
  }
  html += '</select><br />';

  html +=
    '<button class="greenbg" onClick="EXPENSES_CreateReport(0)" data-icon="check">' +
    iTrans('Generate Report') +
    '</button>';

  $('#Report_Controls').html(html);
  $('#Report_Controls').enhanceWithin();

  var fromdate = new Date();
  fromdate.setDate(fromdate.getDate() - 30);
  //document.getElementById('RPT_FromDate').valueAsDate = fromdate;
  $('#RPT_FromDate').val(fromdate.toISOString().substring(0, 10));
  //document.getElementById('RPT_ToDate').valueAsDate = new Date();
  $('#RPT_ToDate').val(new Date().toISOString().substring(0, 10));
  for (var i in STAFF_LIST) {
    $('#RPT_filtername').append($('<option />').val(STAFF_LIST[i].PrimaryKey).text(STAFF_LIST[i].chatname));
  }
  for (i in ALLAC_data) {
    $('#RPT_Idents').append($('<option />').val(ALLAC_data[i].ident).text(ALLAC_data[i].ident));
  }

  $('#EXPENSES_SearchClientJob').autocomplete({
    select: EXPENSES_searchClientJobsSELECT,
    source: searchClientJobs,
  });
}

function EXPENSES_searchClientJobsSELECT(event, ui) {
  event.preventDefault();
  var label = ui.item.label;
  var value = ui.item.value;
  var id = event.target.id;
  $('#' + id).val('');
  var Item = SEARCH_data[value];
  var index = -2;
  var Clients = Report_Data[0].Clients;
  for (var i in Clients) {
    var I = Clients[i];
    if (I.PrimaryKey == Item.ClientPK) index = i;
  }
  $('#EXPENSES_Client_Filter').val(index);
  $('#EXPENSES_Client_Filter').selectmenu('refresh', true);
  EXPENSES_SelClient(index, Item.JobID);
}

function EXPENSES_CreateReport(CountOnly) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  //todo send selected report fields to server

  var JobPK = $('#EXPENSES_Jobs_Filter').val();
  var html = '';
  var checkVal = ''; // values: 0-Hide, 1-Show, 2-Sum, 3-Avg
  var showPaidExpenses = 0;
  if ($('#showPaidExpenses').is(':checked')) showPaidExpenses = 1;

  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();

  var filtername = $('#RPT_filtername').val();
  var ident = $('#RPT_Idents').val();
  var request = {
    CountOnly: CountOnly,
    ClientPK: ClientPK,
    JobPK: JobPK,
    FromDate: FromDate,
    ToDate: ToDate,
    filtername: filtername,
    ident: ident,
    showPaidExpenses: showPaidExpenses,
  };

  RemoteData.post('menu-Administration-Reports-AJAX.php?report=getExpenseReports', request)
    .then((data) => {
      $.mobile.loading('hide');
      //display report data in table
      $('#EXPENSES_Rec_Count').html('Filters: ' + data.NumRows + ' Records');
      if (CountOnly <= 0) {
        EXPENSES_Display_Report(data);
      } else {
        $('#Report_Content').html('');
      }
    })
    .catch((error) => {
      console.error(error);
      $.mobile.loading('hide');
      cSAMA_Dialog.ShowDialog({
        title: iTrans('Failed'),
        message: iTrans(
          'There was a problem generating the report.  Please try again. If this continues to happen please submit a bug report.'
        ),
      });
    });
  //save csv data or download from server?
}

function EXPENSES_Display_Report(data) {
  const contentWrapper = $('#Report_Content');
  if (!data.hasOwnProperty('HTML') || data.HTML == null) {
    contentWrapper.html('No data found.');
    return;
  }

  DownloadCSV_DATA = data.CSV;
  contentWrapper.html('');
  var button = `<div class="limit-table"><a href="#" data-role="button" data-icon="arrow-d" id="exportCSV_BTN">${iTrans(
    'Download CSV Data'
  )}</a></div>`;

  contentWrapper.html(
    `${button}<h1>${iTrans('Report Data Preview')}</h1><div style='overflow:scroll;'>${data.HTML}</div>`
  );
  contentWrapper.enhanceWithin();

  $('#exportCSV_BTN').on('click', function (event) {
    var data;
    var now = new Date();
    var filename = 'Cirro_Report_' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '.csv';
    if (device.platform.toUpperCase() === 'ANDROID') {
      console.log('Opening base64 Android');
      event.preventDefault();
      LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
    } else if (IOS) {
      console.log('Opening base64 IOS');
      event.preventDefault();
      LoadMobolizeFile(filename, btoa(DownloadCSV_DATA), 'text/csv');
    } else {
      console.log('Opening base64 Universal URL');
      var a = document.createElement('a');

      if (typeof a.download != 'undefined') {
        data = 'data:text/csv;charset=utf-8,' + encodeURIComponent(DownloadCSV_DATA);
        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_blank',
          download: filename,
        });
        // let the click go through
      } else {
        // download attribute is not supported
        data = 'data:text/csv;base64,' + btoa(DownloadCSV_DATA);

        // set attributes href and target in the <a> element (with id  exportGPX)
        $(this).attr({
          href: data,
          target: '_system',
        });
      }
    }
  });
}
