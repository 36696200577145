/**
 *
 */
class LocalStorageTable extends AbstractDatabaseTable {
  static IGNORE_KEYS = ['GPS_FLIGHTS', 'GPS_TRACKS', 'DeviceWPTS', 'DataList'];

  constructor() {
    super('LocalStorageData', 'id');
  }

  async openConnection() {
    this.connection = await window.vueApp.utilities.sqlite.open('LocalStorage.db');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "id"   text primary key,
            "text" text
        )
    `;
    //#endregion
  }

  async insert(key, data) {
    if (data === true) {
      data = 'true';
    } else if (data === false) {
      data = 'false';
    }

    return super.insert({ id: key, text: data });
  }

  async selectByKey(key) {
    const whereClause = key ? 'id = ?' : '';
    const values = key ? [key] : null;

    const rows = await this.select(whereClause, values, ['text'], 'limit 1');

    if (rows === false || rows.length <= 0) {
      return null;
    }

    return rows[0].text;
  }

  async selectAll() {
    const whereClause = 'true';

    const rows = await this.select(whereClause, []);

    if (rows === false) {
      return null;
    }

    const parsed = {};
    for (const row of rows) {
      if (row.text !== 'true' && row.text !== 'false') {
        row.text = this.parseJson(row.text, row.text);
      }

      if (LocalStorageTable.IGNORE_KEYS.indexOf(row.id) > -1) {
        continue;
      }

      parsed[row.id] = row.text;
    }

    return parsed;
  }

  /**
   *
   * @param key
   * @returns {Promise<boolean>}
   */
  async delete(key) {
    const whereClause = key ? 'id = ?' : '';
    const values = key ? [key] : null;

    return super.delete(whereClause, values);
  }
}
