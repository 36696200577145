function update_RA_Data() {
  $('#Report_Content').html('<h2>' + iTrans('Getting Records') + '...</h2>');
  var FromDate = new Date($('#RPT_FromDate').val());
  var ToDate = new Date($('#RPT_ToDate').val());
  FromDate = FromDate.getUTCFullYear() + '-' + (FromDate.getUTCMonth() + 1) + '-' + FromDate.getUTCDate();
  ToDate = ToDate.getUTCFullYear() + '-' + (ToDate.getUTCMonth() + 1) + '-' + ToDate.getUTCDate();
  var Pilot = $('#RPT_Pilots').val();
  var Status = $('#RPT_Status').val();
  var RiskLevel = $('#RPT_RiskLevel').val();
  const operationType = $('#RPT_OperationType').val();
  const formName = $('#RPT_FormName').val();

  $.ajaxSetup({
    timeout: 6000000,
  });
  $.getJSON(
    BASEURL +
      'menu-Administration-Reports-AJAX.php?report=Report_RA&FromDate=' +
      FromDate +
      '&ToDate=' +
      ToDate +
      '&Status=' +
      Status +
      '&RiskLevel=' +
      RiskLevel +
      '&Pilot=' +
      Pilot +
      '&OperationType=' +
      operationType +
      '&FormName=' +
      formName,
    function (json_data) {
      $.ajaxSetup({
        timeout: 120000,
      });
      if (json_data[0].ServerResponse == 'No Records') {
        $('#RPT_PageControl').html('');
        $('#Title_Report_Name').html(iTrans('Risk Assessment Reports'));
        $('#Report_Content').html('<h2>' + iTrans('No Records') + '</h2>');
      } else {
        Report_Data = json_data;
        Report_RA();
      }
    }
  ).fail(function (jqXHR, status, error) {
    console.log('Failed to get Report Data');
    $.ajaxSetup({
      timeout: 120000,
    });
    AJAXFAIL(error);
  });
}

function Report_RA() {
  $('#RPT_PageControl').html('');
  $('#Title_Report_Name').html(iTrans('Risk Assessment Reports'));

  var html =
    `<h2>${Report_Data.length} ${iTrans('Records')}</h2>` +
    '<div style="width: 100%; overflow: auto"><table id="Report_Table" data-filter-"true" class="wrap">' +
    '<thead>' +
    '<tr>' +
    '<th>RA#</th>' +
    '<th>' +
    iTrans('Name') +
    '</th>' +
    '<th>' +
    iTrans('Itin') +
    '#</th>' +
    '<th>' +
    iTrans('Operation') +
    '</th>' +
    '<th>' +
    iTrans('Form Name') +
    '</th>' +
    '<th>' +
    iTrans('Risk Level') +
    '</th>' +
    '<th>' +
    iTrans('Approval Status') +
    '</th>' +
    '<th>' +
    iTrans('Risk Total') +
    '</th>' +
    '<th>' +
    iTrans('Mitigated Total') +
    '</th>' +
    '<th>' +
    iTrans('Med Allowed') +
    '</th>' +
    '<th>' +
    iTrans('Max Allowed') +
    '</th>' +
    '<th>' +
    iTrans('Timestamp') +
    '</th>' +
    '<th>' +
    iTrans('Action') +
    '</th>' +
    '</tr>' +
    '</thead>' +
    '<tbody>';

  const dateFormat = `${VueBridge.dateTimeUtils.formattableDateFormats[0]} ${VueBridge.dateTimeUtils.timeFormat}`;

  for (var i in Report_Data) {
    var I = Report_Data[i];
    var Action =
      '<button id="RPT_RA-' +
      i +
      '" onClick="show_RA_Form(' +
      i +
      ');" data-mini="true" class="action-btn"><img src="./images/inspect.svg" /></button>';
    var lclass = '';
    if (I.Status == 'Passed') lclass = 'greenbg';
    if (I.Form_Data.Flight_Total >= I.Form_Data.Med_Risk_Value) lclass = 'yellowbg';
    if (I.Form_Data.Flight_Total >= I.Form_Data.Max_Risk_Value) lclass = 'redbg';
    if (I.Status == 'Denied') lclass = 'orangebg';
    if (I.ITIN_PK == 0) I.ITIN_PK = 'N/A';
    html +=
      '<tr class="' +
      lclass +
      '"><td>' +
      I.PrimaryKey +
      '<td>' +
      I.chatname +
      '</td><td>' +
      I.ITIN_PK +
      '</td><td>' +
      I.Operation +
      '</td><td>' +
      I.Form_Data.Name +
      '</td><td>' +
      iTrans(I.Status) +
      '</td><td>' +
      iTrans(I.ApprovalStatus) +
      '</td><td>' +
      I.Form_Data.Risk_Total +
      '</td><td>' +
      I.Form_Data.Flight_Total +
      '</td><td>' +
      I.Form_Data.Med_Risk_Value +
      '</td><td>' +
      I.Form_Data.Max_Risk_Value +
      '</td><td>' +
      VueBridge.dateTimeUtils.convertUtcToUsersTimezone(I.DateTime).format(dateFormat) +
      '</td><td>' +
      Action +
      '</td></tr>';
  }
  html += '</tbody></table></div>';

  $('#exportRACSV').prop('disabled', false);

  $('#exportRACSV').on('click', function () {
    const formData = Report_Data.map((data) => {
      return {
        'RA#': data.PrimaryKey,
        [iTrans('Name')]: data.chatname,
        [iTrans('Itin')]: data.ITIN_PK,
        [iTrans('Operation')]: data.Operation,
        [iTrans('Form Name')]: data.Form_Data.Name,
        [iTrans('Risk Level')]: iTrans(I.Status),
        [iTrans('Approval Status')]: iTrans(I.ApprovalStatus),
        [iTrans('Risk Total')]: data.Form_Data.Risk_Total,
        [iTrans('Mitigated Total')]: data.Form_Data.Flight_Total,
        [iTrans('Med Allowed')]: data.Form_Data.Med_Risk_Value,
        [iTrans('Max Allowed')]: data.Form_Data.Max_Risk_Value,
        Timestamp: VueBridge.dateTimeUtils.convertUtcToUsersTimezone(data.DateTime).format(dateFormat),
      };
    });
    window.vueApp.utilities.blobUtils.downloadAsCsv(formData, 'Cirro_Risk_Assesment');
  });

  $('#Report_Content').html(html);
  $('#Report_Content').enhanceWithin();
  AddTableSorter('Report_Table');
}
function exitshowRA_Form() {
  $.mobile.changePage($('#Report_Page'), {
    reverse: true,
    changeHash: false,
  });
  $('#exitSubReportPage').off('click', exitshowRA_Form);
}
function show_RA_Form(i) {
  console.log('show RA');
  $('#Sub_Report_Content').html('<h3>' + iTrans('Retrieving Data') + '...</h3>');
  $('#Title_Sub_Report_Name').html(iTrans('Risk Assessment') + '# ' + Report_Data[i].PrimaryKey);
  $('#exitSubReportPage').click(exitshowRA_Form);
  $.mobile.changePage($('#Sub_Report_Page'), {
    reverse: false,
    changeHash: false,
  });

  $('#Sub_Report_Content').html('');
  RAform = Report_Data[i].Form_Data;
  RAform.CurLeg = 0;
  RAform.HidePerLeg = 0;
  RAform.HidePerFlight = 0;
  RAform.isReport = true;
  var html = '';
  for (var i in RAform.LEGS) {
    RAform.CurLeg = i;
    html += RAoperation(true);
  }

  $('#Sub_Report_Content').html('<div class="limit-table">' + html + '</div>');
  $('#Sub_Report_Content').enhanceWithin();
}
