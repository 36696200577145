class cSAMA_ResourceLock {
  /**
   *
   * @param {string}      _p.what
   * @param {string}      _p.name
   * @param {int|string}  _p.pk
   * @param {function}   [_p.fnOpen]
   * @param {function}   [_p.fnLocked]
   */
  constructor(_p) {
    this.isInitial = true;
    this.fastChecking = false;
    this.resourceName = _p.name;
    this.resourceType = _p.what;
    this.resourcePk = _p.pk;
    this.callBackLocked = _p.fnLocked;
    this.callBackOpen = _p.fnOpen;
  }

  BeginLocking() {
    this.running = true;
    this.fastChecking = false;
    this.isInitial = true;
    this.TryLock();
  }

  StartTimer(_fast) {
    _fast = _fast == null ? false : _fast;
    let time = _fast === false ? 15000 : 1000; // 15s when not fast, 1s when fast
    this.timer = setTimeout(() => this.TryLock(), time);
  }

  StopLocking() {
    this.isInitial = true;
    this.fastChecking = false;
    this.running = false;
    clearTimeout(this.timer);
  }

  TryLock() {
    this.HandleLockRecord();
    // let r = new cSAMA_Request('Keepalive', 'lock');
    // r.Set('type', this.resourceType);
    // r.Set('pk', this.resourcePk);
    //
    // cSAMA_Request.Dispatch({
    //   name            : 'LockResource',
    //   reqs            : r,
    //   clientErrorCode : 'SAMA PO LC CC NB',
    //   fnSuccess       : (_r) => this.HandleLockRecord(_r),
    //   fnFail          : (_r, _jqXHR, _status, _error) => this.HandleConnectionFailure(_r, _jqXHR, _status, _error),
    //   goBackOnError   : false,
    //   keepStateOnError: true,
    //   showLoading     : this.isInitial
    // });
  }

  HandleConnectionFailure(_r, _jqXHR, _status, _error) {
    if (this.fastChecking === true && _jqXHR.status === 0) {
      // While offline, we're checking the lock state more frequently and it will fail until we're back in contact so
      // don't do anything
      this.StartTimer(true);
      return;
    }

    if (_status === 'error' && _jqXHR.status !== 0) {
      // Reached the server and died there
      cSAMA_Request.ServerSideError(_r, _jqXHR.responseJSON);
      return;
    }

    // Couldn't reach the server, we're either offline or have timed out.
    // Check for lock state more frequently (every 30s) but ignore successive failures
    this.fastChecking = true;
    this.StartTimer(true);
  }

  /**
   * @param {object} _result
   */
  HandleLockRecord(_result) {
    this.callBackOpen();
    // if (this.running !== true)
    // {
    //   return;
    // }
    //
    // let lockInfo = _result[0];
    // switch (lockInfo.lockState)
    // {
    //   case 'closed':
    //     // We cannot modify the resource
    //     //console.log('SAMA-RESOURCE-LOCKS', 'Lock closed');
    //     if (this.fastChecking === true)
    //     {
    //       // If lock closed by other user while we were offline, tell our user that they can ask the other
    //       // person to close the resource and override their changes or quit out of the resource.
    //       this.ShowCollisionDialog(lockInfo.chatname);
    //       return;
    //     }
    //
    //     this.StopLocking();
    //     if (this.callBackLocked != null)
    //     {
    //       this.callBackLocked(lockInfo);
    //     }
    //     this.ShowLockedDialog(lockInfo.chatname);
    //     break;
    //
    //   case 'taken':
    //     //console.log('SAMA-RESOURCE-LOCKS', 'Lock taken');
    //     if (this.fastChecking === true)
    //     {
    //       // If lock closed by other user while we were offline, tell our user that they can ask the other
    //       // person to close the resource and override their changes or quit out of the resource.
    //       this.ShowCollisionDialog('someone else');
    //       return;
    //     }
    //   case 'open':
    //     // We can modify the resource
    //     //console.log('SAMA-RESOURCE-LOCKS', 'Lock open');
    //     if (this.callBackOpen != null)
    //     {
    //       this.callBackOpen(lockInfo);
    //     }
    //     break;
    //
    //   case 'refreshed':
    //     // We're still locking the resource successfully
    //     //console.log('SAMA-RESOURCE-LOCKS', 'Lock still in place');
    //     if (this.callBackOpen != null && this.isInitial === true)
    //     {
    //       this.callBackOpen(lockInfo);
    //     }
    //     break;
    // }
    //
    // this.isInitial    = false;
    // this.fastChecking = false;
    // this.StartTimer();
  }

  ShowCollisionDialog(_who) {
    this.StopLocking();
    cSAMA_Dialog.ShowDialog({
      title: 'Resource "%" is colliding!'.tr(this.resourceName),
      message:
        'Unfortunately, % began editing this resource while you were offline.'.tr(_who) +
        ' Any changes you save now would override theirs.',
      buttons: {
        'Discard my changes': {
          click: () => {
            SAMA.Navigator.GoBack();
          },
        },
      },
    });
  }

  ShowLockedDialog(_who) {
    cSAMA_Dialog.ShowDialog({
      title: 'Resource "%" is locked'.tr(this.resourceName),
      message: 'This resource is being edited by % at this time. You can ask them to close it or try again later.'.tr(
        _who
      ),
    });
    SAMA.GeneralGui.SetPageState(cSAMA_GeneralGui.StateReady);
  }
}
