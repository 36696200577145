/**
 *
 */
class FdtStaffV3Table extends AbstractDatabaseTable {
  constructor() {
    super('FDT_StaffV3', 'Date');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            "group"        text,
            "User_PK"      integer,
            "Modified"     text,
            "Date"         text primary key ,
            "Start"        integer,
            "End"          integer,
            "Start2"       integer,
            "End2"         integer,
            "FT"           numeric,
            "FT2"          numeric,
            "DFR"          text,
            "IDENT"        text,
            "Comment"      text,
            "Split"        integer,
            "JobNums"      text,
            "idents"       text,
            "AdminComment" text,
            "Overtime"     numeric,
            "Unpaid"       numeric,
            "FieldPay"     integer,
            "SickDay"      integer,
            "ET1"          numeric,
            "ET2"          numeric,
            "ET3"          numeric,
            "ET4"          numeric,
            "ET5"          numeric,
            "EC1"          integer,
            "EC2"          integer,
            "EC3"          integer,
            "EC4"          integer,
            "EC5"          integer,
            "Approved"     integer,
            "Cfields"      text,
            "LOC"          text,
            "id"           integer
        )`;
    //#endregion
  }
}
