/**
 * Class describing departments
 * @param {object} _databaseData
 * @author falko@air-suite.com
 * @copyright (c) 2017 AirSuite, All Rights Reserved.
 * @constructor
 * @class
 */
class mSAMA_Department extends mSAMA_AbstractModel {
  constructor(_databaseData) {
    super('dep_primaryKey');

    this.sync = {
      dep_primaryKey: null,
      dep_name: null,
      dep_managerPk: null,
      dep_isManager: null,
      dep_managerName: null,
      dep_personnel: null,
      dep_personnelNames: null,
      dep_locked: false,
      dep_color: '#000000',
      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _databaseData);

    this.validateSettings = {
      dep_name: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 3,
        maxLength: SAMA.enums.TEXTLENGTHS.Small,
      }),
      dep_managerPk: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.KEYWORD,
        maxTags: 1,
        minTags: 1,
        tagName: 'employee',
      }),
    };

    // Get the manager's Pk and Name
    if (this.sync.dep_isManager != null) {
      let p = this.sync.dep_personnel.split(',');
      let m = this.sync.dep_isManager.split(',');
      let n = this.sync.dep_personnelNames.split(',');

      let pindex = m.indexOf('1');
      if (pindex > -1) {
        this.sync.dep_managerPk = p[pindex];
        this.sync.dep_managerName = n[pindex];
        p.splice(pindex, 1);
        n.splice(pindex, 1);
      }

      this.sync.dep_personnel = p.join(',');
      this.sync.dep_personnelNames = n.join(',');
    }

    if (this.sync.dep_managerPk == null) {
      this.sync.dep_managerName = 'Nobody';
    }

    if (this.IsNew()) {
      this.sync.dep_name = 'Untitled Department';
      this.sync.dep_primaryKey = cSAMA_Utils.GetUID();
      this.SetModified(true);
    }
  }
}
