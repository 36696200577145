/**
 *
 */
class RiskFormsTable extends AbstractDatabaseTable {
  constructor() {
    super('RiskForms', 'PrimaryKey');
  }

  tableCreationQuery() {
    //#region return ...
    return `
        create table if not exists ${this.tableName}
        (
            PrimaryKey text primary key,
            data text
        )`;
    //#endregion
  }
}
