/********************************************* BILLING AND INVOICING */
var BIFILE;
var BIDATA;

function exitAdminRevampFDT() {
  USERTYPE = USER_RIGHTS.staffType;
  if (FDT_OPK != 0) {
    if (SYNCING || FDT_SYNCING) {
      alert('Sync currently in progress. Please wait to try again');
      return;
    }
    update_Sync('LastFDTsync', '2000-01-01 00:00:00');
    cirroDB.clearAll('FDT_DaysV4', function (e) {
      cirroDB.clearAll('FDT_RecsV3', function (e) {
        cirroDB.clearAll('FDT_StaffV3', function (e) {
          console.log('ALL LOCAL FDT RECORDS DELETED FOR RESTORE');
          FDT_ADMIN_UPDATE = false;
          FDT_OPK = 0;
          localStorageDB.setItem('FDT_Defaults', JSON.stringify(null));
          localStorageDB.setItem('AdminFDT_Edit', FDT_OPK, function (e) {
            LOCALSTORAGE.FDT_Defaults = undefined;
            LOCALSTORAGE.AdminFDT_Edit = FDT_OPK;
            LOCALSTORAGE.INIT_Login = 1;
            localStorageDB.setItem('FDT_Sync_Error', '0');
            LOCALSTORAGE.FDT_Sync_Error = 0;
            BackgroundSync(true);
          });
        });
      });
    });
    $('#DutyTimes').removeClass('greybg');
  } else {
    FDT_ADMIN_UPDATE = false;
    FDT_OPK = 0;
  }
}
function exitFDTTimeList() {
  if (SchedulerBaseEdited) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Sync Required'),
      headerClose: false,
      buttonPrompt: iTrans('The changes made require a full syncronization to take affect.'),
      buttons: {
        [iTrans('Sync Now')]: {
          click: function () {
            restartCirro();
          },
        },
        [iTrans('Exit Without Syncing')]: {
          click: function () {
            VueBridge.enterCorrectAdminToolMenu();
          },
        },
      },
    });
  } else {
    USERTYPE = USER_RIGHTS.staffType;
    if (FDT_OPK != 0) {
      if (SYNCING || FDT_SYNCING) {
        alert('Sync currently in progress. Please wait to try again');
        return;
      }
      update_Sync('LastFDTsync', '2000-01-01 00:00:00');
      cirroDB.clearAll('FDT_DaysV4', function (e) {
        cirroDB.clearAll('FDT_RecsV3', function (e) {
          cirroDB.clearAll('FDT_StaffV3', function (e) {
            console.log('ALL LOCAL FDT RECORDS DELETED FOR RESTORE');
            FDT_ADMIN_UPDATE = false;
            FDT_OPK = 0;
            localStorageDB.setItem('FDT_Defaults', JSON.stringify(null));
            localStorageDB.setItem('AdminFDT_Edit', FDT_OPK, function (e) {
              LOCALSTORAGE.FDT_Defaults = undefined;
              LOCALSTORAGE.AdminFDT_Edit = FDT_OPK;
              LOCALSTORAGE.INIT_Login = 1;
              localStorageDB.setItem('FDT_Sync_Error', '0');
              LOCALSTORAGE.FDT_Sync_Error = 0;
              BackgroundSync(true);
            });
          });
        });
      });
      $('#DutyTimes').removeClass('greybg');
      const xit = $('#exitDutyTimesList');
      xit.unbind('click').click(hrefHandler);
      toggleBackButton(xit, true);
    } else {
      FDT_ADMIN_UPDATE = false;
      FDT_OPK = 0;
    }
    VueBridge.enterCorrectAdminToolMenu();
  }
}
function exitFDTDate() {
  $.mobile.changePage($('#UpdateFDTList'), {
    reverse: true,
    changeHash: false,
  });
  $('#FDT_ADMIN_FIELDS').html('');
}
function Admin_Update_FDT() {
  $.mobile.changePage($('#UpdateFDTList'), {
    reverse: false,
    changeHash: false,
  });
  if (LOCALSTORAGE.FutureFlightSetting != undefined) {
    $('#fdt_futureDates').val(LOCALSTORAGE.FutureFlightSetting).selectmenu('refresh');
  }
  var now = new Date();
  var tz = now.getTimezoneOffset();
  now.setMinutes(now.getMinutes() + tz);
  $('#FDT_ADMIN_Date').val(now.toISOString().substring(0, 10));
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=GetStaffFDT', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getpweight');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      popAdminPilotList(json_data[0].Pilots);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

function popAdminPilotList(list) {
  ADMIN_FDT_DATA.list = list;
  ADMIN_FDT_DATA.index = -1;
  $('#calendar').html('');
  $('#FDT_ADMIN_StaffList').html('');
  var html =
    "<h2>Pilot List</h2><table class='first-td-fill' data-filter='true'><thead><tr><th>" +
    iTrans('Chatname') +
    '</th><th>' +
    iTrans('Staff Type') +
    '</th><th>' +
    iTrans('Last Date') +
    '</th><th>' +
    iTrans('Action') +
    '</th></tr></thead><tbody>';
  for (var i in list) {
    var s = list[i];
    var Buttons =
      '<div class="vertical-center-container"><button onclick="Edit_FDT_PK(' +
      s.PrimaryKey +
      ',' +
      i +
      ')" data-mini="true" class="action-btn ui-btn"><img src="./images/inspect.svg"></button></div>';
    html +=
      '<tr><td>' +
      s.chatname +
      '</td><td>' +
      s.staffType +
      '</td><td>' +
      s.LastDate +
      '</td><td>' +
      Buttons +
      '</td></tr>';
  }
  html += '</tbody></table>';
  $('#FDT_ADMIN_FIELDS').html('');
  $('#FDT_ADMIN_StaffList').html(html);
  $('#FDT_ADMIN_StaffList').enhanceWithin();

  cirroDB.query('AC', 'ident != ?', '', function (e) {
    ALLAC_data = [];
    if (e === false) e = [];
    else {
      for (var i in e) {
        var item = e[i].AC_data;
        item.rawData = e[i];
        ALLAC_data.push(item);
      }
    }
    var AClist = '';
    for (i in ALLAC_data) {
      AClist += "<option value='" + ALLAC_data[i].ident + "' >" + ALLAC_data[i].ident + '</option>';
    }
    ADMIN_FDT_DATA.Aclist = AClist;
  });
}
var ADMIN_FDT_DATA = {
  list: [],
  data: {},
  index: -1,
  Aclist: '',
  LastData: null,
  futureMonths: 0,
  returnPage: '',
};

function saveFutureFlightSetting(e) {
  localStorageDB.setItem('FutureFlightSetting', e.value, function (e) {
    LOCALSTORAGE.FutureFlightSetting = e.value;
  });
}
function Edit_FDT_PK(index, i) {
  if (SYNCING || FDT_SYNCING) {
    alert('Sync currently in progress. Please wait to try again');
    return;
  }

  const xit = $('#exitDutyTimes');
  xit.unbind('click').click(hrefHandler);
  toggleBackButton(xit, false);
  FDT_ADMIN_UPDATE = true;
  LOCALSTORAGE.FDT_Defaults = undefined;
  localStorageDB.setItem('FDT_Defaults', JSON.stringify(null));
  ADMIN_FDT_DATA.returnpage = 'UpdateFDTList';
  ADMIN_FDT_DATA.futureMonths = $('#fdt_futureDates').val();
  ADMIN_FDT_DATA.index = index;
  ADMIN_FDT_DATA.LastData = ADMIN_FDT_DATA.list[i].LastData;
  ADMIN_FDT_DATA.CurUser = ADMIN_FDT_DATA.list[i];
  FDT_OPK = ADMIN_FDT_DATA.CurUser.PrimaryKey;
  USERTYPE = ADMIN_FDT_DATA.CurUser.staffType;
  $('#Duty_Status').html(iTrans('Admin Update') + ': ' + ADMIN_FDT_DATA.CurUser.chatname);
  localStorageDB.setItem('AdminFDT_Edit', FDT_OPK, function (e) {
    LOCALSTORAGE.AdminFDT_Edit = FDT_OPK;
  });
  update_Sync('LastFDTsync', '2000-01-01 00:00:00');
  localStorageDB.setItem('FDT_Sync_Error', '0');
  LOCALSTORAGE.FDT_Sync_Error = 0;
  localStorageDB.setItem('INIT_Login', '1');
  cirroDB.clearAll('FDT_DaysV4', function (e) {
    cirroDB.clearAll('FDT_RecsV3', function (e) {
      cirroDB.clearAll('FDT_StaffV3', function (e) {
        verbose.log('fdt', 'All local fdt records deleted for edit start.');
        loadFdtModule(() => {
          $.mobile.loading('show', { theme: 'a' });
          FDT_MODULE_VERSION = 4;
          BackgroundSync(true);
        });
        //
      });
    });
  });
}

function exitPassengerWeightsOptions() {
  VueBridge.enterCorrectAdminToolMenu();
}
function PassengerWeight_options() {
  $.mobile.changePage($('#PassengerWeightsPage'), {
    reverse: false,
    changeHash: false,
  });
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getPassengerWeights', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getpweight');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      popPaxWeightOptions(json_data[0]);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function popPaxWeightOptions(data) {
  $('#MalePaxStandardWeight').val(data.MaleWeight);
  $('#FemalePaxStandardWeight').val(data.FemaleWeight);
}
function UpdateStandardPaxWeights() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  var Male = parseInt($('#MalePaxStandardWeight').val());
  var Female = parseInt($('#FemalePaxStandardWeight').val());
  GROUP_DATA.FemaleWeight = Female;
  GROUP_DATA.MaleWeight = Male;
  $.getJSON(
    BASEURL +
      'menu-Administration-AJAX.php?action=updatePassengerWeights&MaleWeight=' +
      Male +
      '&FemaleWeight=' +
      Female,
    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      exitPassengerWeightsOptions();
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
var AdminSCHRoleList;
var AdminSCH_CategoryList;
var SchedulerBaseEdited = false;
function Scheduler_options() {
  var page = $(':mobile-pagecontainer').pagecontainer('getActivePage')[0].id;
  if (page != 'SchedulerOptionsPage') {
    SchedulerBaseEdited = false;
    $.mobile.changePage($('#SchedulerOptionsPage'), {
      reverse: false,
      changeHash: false,
    });
  }
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getSchedulerOptions', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getpweight');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      var setting = json_data[0].SCH_AssignOnly;
      var setting2 = json_data[0].SCH_StatusUpdate;
      if (setting == 1) $('#SCH_RestrictViewing').prop('checked', true).checkboxradio('refresh');
      else $('#SCH_RestrictViewing').prop('checked', false).checkboxradio('refresh');

      if (setting2 == 1) $('#SCH_RestrictBookingStatus').prop('checked', true).checkboxradio('refresh');
      else $('#SCH_RestrictBookingStatus').prop('checked', false).checkboxradio('refresh');

      var Bases = json_data[0].Bases;
      AdminSCHRoleList = json_data[0].SCH_Roles;
      if (AdminSCHRoleList == null) AdminSCHRoleList = [];
      showAdminSCHroles(AdminSCHRoleList);
      AdminSCH_CategoryList = json_data[0].SCH_Categories;
      showAdminSCH_Categories(AdminSCH_CategoryList);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

function showAdminSCHroles(Roles) {
  var html = '';
  if (Roles.length > 0) {
    html +=
      '<table class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Role') +
      '</th><th>' +
      iTrans('Required') +
      '</td><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in Roles) {
      var OrderBTN =
        '<button class="action-btn" data-mini="true" onClick="SCHRole_Order_UP(' +
        i +
        ')" ><img src="./images/Plus-icon.png" widht="20px" height="20px" /></button><button class="action-btn" data-mini="true" onClick="SCHRole_Order_DOWN(' +
        i +
        ')" ><img src="./images/Minus-icon.png" widht="20px" height="20px" /></button>';
      var checked = '';
      if (Roles[i].Req == true) checked = 'checked';
      var button =
        '<button onClick="AdminRemoveRole(' + i + ');" class="action-btn"><img src="./images/delete.svg" /></button>';
      html +=
        '<tr><td>' +
        Roles[i].Label +
        '</td><td><input type="checkbox" value="' +
        i +
        '" onClick="AdminUpdateRoleRequired(this);" ' +
        checked +
        '/><td><div class="vertical-center-container">' +
        button +
        OrderBTN +
        '</div></td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html = '<b>' + iTrans('No Scheduler Roles Defined') + '</b>';
  }
  html +=
    '<br /><button data-icon="plus" onClick="NewSCHRole();" data-mini="true">' +
    iTrans('Add Scheduler Role') +
    '</button><br />';
  $('#AdminSCHRoles').html(html);
  $('#AdminSCHRoles').enhanceWithin();
}

function AdminUpdateRoleRequired(e) {
  AdminSCHRoleList[e.value].Req = e.checked;
  AdminSaveRoleList();
}

function SCHRole_Order_DOWN(q) {
  if (q == AdminSCHRoleList.length - 1) return;
  q = parseInt(q);
  nq = q + 1;
  AdminSCHRoleList = SCHRole_array_move(AdminSCHRoleList, q, nq);
  AdminSaveRoleList();
}
function SCHRole_Order_UP(q) {
  if (q == 0) return;
  q = parseInt(q);
  nq = q - 1;
  AdminSCHRoleList = SCHRole_array_move(AdminSCHRoleList, q, nq);
  AdminSaveRoleList();
}
function SCHRole_array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

function AdminRemoveRole(index) {
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt:
      iTrans('Are you sure you want to remove the') +
      '"' +
      AdminSCHRoleList[index].Label +
      '"' +
      iTrans('Scheduler Role') +
      '?',
    buttons: {
      [iTrans('Remove')]: {
        click: function () {
          AdminSCHRoleList.splice(index, 1);
          AdminSaveRoleList();
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function showAdminSCH_Categories(Categories) {
  var html = '';
  if (Categories.length > 0) {
    html +=
      '<table class="limit-table first-td-fill"><thead><tr><th>' +
      iTrans('Category') +
      '</th><th>' +
      iTrans('Color') +
      '</td><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in Categories) {
      var C = Categories[i];
      var buttons =
        '<button onClick="AdminEditSCH_Cat(' +
        i +
        ');" class="action-btn"><img src="./images/edit.svg" /></button><button onClick="AdminRemoveSCH_Cat(' +
        i +
        ');" class="action-btn"><img src="./images/delete.svg" /></button>';
      html +=
        '<tr><td>' +
        C.category +
        '</td><td><input style="width:150px; height:3em;border-color:#FFF; background-color:' +
        C.color +
        ';" type="text" data-role="none" value="' +
        C.color +
        '" disabled/><td><div class="vertical-center-container">' +
        buttons +
        '</div></td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html = '<b>' + iTrans('No Scheduler Categories Defined') + '</b>';
  }
  html +=
    '<br /><button data-icon="plus" onClick="NewSCH_Cat();" data-mini="true">' +
    iTrans('Add Scheduler Category') +
    '</button><br />';
  $('#AdminSCH_Categories').html(html);
  $('#AdminSCH_Categories').enhanceWithin();
}

function NewSCH_Cat() {
  AdminSCH_CategoryList.push({ PrimaryKey: 0, group: USER_RIGHTS.group, category: '', color: '#ffffff' });
  AdminEditSCH_Cat(AdminSCH_CategoryList.length - 1);
}
function AdminRemoveSCH_Cat(index) {
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Confirm'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete') + ': ' + AdminSCH_CategoryList[index].category + '?',
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          AdminSCH_CategoryList[index].DELETED = 1;
          saveSCH_Category(AdminSCH_CategoryList[index]);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}
function AdminEditSCH_Cat(index) {
  var C = AdminSCH_CategoryList[index];
  var html =
    '<label for="AdminNewCategory">' +
    iTrans('Category') +
    '</label><input type="text" id="AdminNewCategory" placeholder="' +
    iTrans('Enter Category Name') +
    '"/>';
  html +=
    '<label for="AdminNewCategoryColor" v-t="menu_Administration_WP_LANG.editWPpointTXT" >Waypoint Colour</label>';
  html +=
    '<input class="jscolor {hash:true, width:243, height:150,borderColor:\'#FFF\', insetColor:\'#FFF\', backgroundColor:\'#666\'} jscolor-active"  type="text" id="AdminNewCategoryColor" data-role="none"/>';
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Create New Division'),
    headerClose: false,
    buttonPrompt: html,
    callbackOpen: function () {
      $('#AdminNewCategory').val(C.category);
      $('#AdminNewCategoryColor').val(C.color).css('background-color', C.color);
      jscolor.installByClassName('jscolor');
    },
    buttons: {
      [iTrans('Save Changes')]: {
        click: function () {
          C.category = $('#AdminNewCategory').val();
          C.color = $('#AdminNewCategoryColor').val();
          saveSCH_Category(C);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function saveSCH_Category(C) {
  $.post(
    BASEURL + 'menu-Administration-AJAX.php?action=saveSCH_Category',
    {
      Category: C,
    },
    function (data) {
      //console.log(data);
      if (data[0].ServerResponse == 'Success') {
        AdminSCH_CategoryList = data[0].SCH_Categories;
        showAdminSCH_Categories(AdminSCH_CategoryList);
      } else {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed'),
          headerClose: false,
          buttonPrompt: iTrans('Error saving scheduler category.'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //do nothing
              },
            },
          },
        });
      }
    },
    'json'
  ).fail(function (jqXHR, status, error) {
    //alert("Failed Remote update Request!");
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Failed'),
      headerClose: false,
      buttonPrompt: iTrans('Error saving scheduler category.'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {
            //do nothing
          },
        },
      },
    });
  });
}

function NewCompanyBase() {
  var html =
    '<label for="AdminNewBaseName">' + iTrans('Enter New Name') + '</label><input type="text" id="AdminNewBaseName" />';
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Create New Division'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          $.mobile.loading('show', {
            theme: 'a',
          });
          //acknolwedge
          $.getJSON(
            BASEURL +
              'menu-Administration-AJAX.php?action=addSchedulerBase&name=' +
              encodeURIComponent($('#AdminNewBaseName').val()),
            function (json_data) {
              //status could be offline/Not Logged In/Uncaught Exception
              SchedulerBaseEdited = true;
              Scheduler_options();
              $.mobile.loading('hide');
            }
          ).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
            $.mobile.loading('hide');
          });
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function NewSCHRole() {
  var html =
    '<label for="AdminNewSCHRoleName">' +
    iTrans('Enter Role Name') +
    '</label><input type="text" id="AdminNewSCHRoleName" data-mini="true" />';
  html +=
    '<label for="AdminNewSCHRoleReq">' +
    iTrans('Required') +
    '</label><input type="checkbox" id="AdminNewSCHRoleReq" data-mini="true" />';
  $('<div>').simpledialog2({
    themeDialog: 'a',
    mode: 'button',
    animate: false,
    headerText: iTrans('Create New Role'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          var Label = $('#AdminNewSCHRoleName').val();
          if (Label.length < 1) return;
          AdminSCHRoleList.push({
            Label: $('#AdminNewSCHRoleName').val(),
            Req: $('#AdminNewSCHRoleReq').is(':checked'),
            ClientID: 0,
          });

          AdminSaveRoleList();
          //acknolwedge
        },
      },
      Cancel: {
        click: function () {
          //acknolwedge
        },
      },
    },
  });
}

function AdminSaveRoleList() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'menu-Administration-AJAX.php?action=updateSchedulerRoles&List=' +
      encodeURIComponent(JSON.stringify(AdminSCHRoleList)),
    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      GROUP_DATA.SCH_Roles = JSON.parse(JSON.stringify(AdminSCHRoleList));
      SchedulerBaseEdited = true;
      Scheduler_options();
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

function UpdateSchedulerOptions() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  var setting = $('#SCH_RestrictViewing').is(':checked');
  if (setting) setting = 1;
  else setting = 0;

  var setting2 = $('#SCH_RestrictBookingStatus').is(':checked');
  if (setting2) setting2 = 1;
  else setting2 = 0;

  GROUP_DATA.SCH_AssignOnly = setting;
  GROUP_DATA.SCH_StatusUpdate = setting2;

  $.getJSON(
    BASEURL +
      'menu-Administration-AJAX.php?action=updateSchedulerOptions&setting=' +
      encodeURIComponent(setting) +
      '&setting2=' +
      encodeURIComponent(setting2),
    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      exitFDTTimeList();
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function exitAlertingOptions() {
  $.mobile.changePage($('#Administration_Page'), {
    reverse: true,
    changeHash: false,
  });
}
function Alerting_options() {
  $.mobile.changePage($('#adminAlertingOptions'), {
    reverse: false,
    changeHash: false,
  });
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getAlertingSettings', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      popAlertingOptions(json_data[0]);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function popAlertingOptions(S) {
  console.log(S);
  var Warning = S.Alert_Warning;
  var T1 = S.Alert_T1;
  var T2 = S.Alert_T2;
  var T3 = S.Alert_T3;
  $('[name="AlertO_W"]').removeAttr('checked').checkboxradio('refresh');
  $('[name="AlertO_T1"]').removeAttr('checked').checkboxradio('refresh');
  $('[name="AlertO_T2"]').removeAttr('checked').checkboxradio('refresh');
  $('[name="AlertO_T3"]').removeAttr('checked').checkboxradio('refresh');
  $('[name="FR_ITIN_showStartEndTZ_RDO"]').removeAttr('checked').checkboxradio('refresh');
  $('input[name=AlertO_W][value=' + Warning + ']')
    .prop('checked', true)
    .checkboxradio('refresh');
  $('input[name=AlertO_T1][value=' + T1 + ']')
    .prop('checked', true)
    .checkboxradio('refresh');
  $('input[name=AlertO_T2][value=' + T2 + ']')
    .prop('checked', true)
    .checkboxradio('refresh');
  $('input[name=AlertO_T3][value=' + T3 + ']')
    .prop('checked', true)
    .checkboxradio('refresh');

  $('input[name=FR_ITIN_showStartEndTZ_RDO][value=' + S.FR_ITIN_showStartEndTZ + ']')
    .prop('checked', true)
    .checkboxradio('refresh');

  $('#ITIN_showRespPerson')
    .prop('checked', S.ITIN_showRespPerson == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showFlightDetails')
    .prop('checked', S.ITIN_showFlightDetails == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showVIP')
    .prop('checked', S.ITIN_showVIP == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showGenFRNum')
    .prop('checked', S.ITIN_showGenFRNum == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showEmergEQ')
    .prop('checked', S.ITIN_showEmergEQ == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showTotalCargo')
    .prop('checked', S.ITIN_showTotalCargo == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showEngSS')
    .prop('checked', S.ITIN_showEngSS == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showETA_ETE')
    .prop('checked', S.ITIN_showETA_ETE == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_useRouting')
    .prop('checked', S.ITIN_useRouting == 1 ? true : false)
    .checkboxradio('refresh');
  $('#ITIN_showAutoLegUpdateOption')
    .prop('checked', VueBridge.getGroupPreference('itinerary.autoLegUpdate', '1') === '1')
    .checkboxradio('refresh');
}
function AlertO(e) {
  console.log(e);
  var Warning = parseInt($('input[name=AlertO_W]:checked').val());
  var T1 = parseInt($('input[name=AlertO_T1]:checked').val());
  var T2 = parseInt($('input[name=AlertO_T2]:checked').val());
  var T3 = parseInt($('input[name=AlertO_T3]:checked').val());
  //Don't allow other Alerting Tiers to be before others
  if (T1 <= Warning) T1 = Warning + 900;
  if (T2 <= T1) {
    if (T1 >= 3600) T2 = T1 + 1800;
    else T2 = T1 + 900;
  }
  if (T3 <= T2) {
    if (T2 >= 3600) T3 = T2 + 1800;
    else T3 = T2 + 900;
  }
  var S = { Alert_Warning: Warning, Alert_T1: T1, Alert_T2: T2, Alert_T3: T3 };
  console.log(S);
  setTimeout(function () {
    popAlertingOptions(S);
  }, 100);
}
function SaveAlertOptions() {
  $.mobile.loading('show', {
    theme: 'a',
  });
  var Warning = parseInt($('input[name=AlertO_W]:checked').val());
  var T1 = parseInt($('input[name=AlertO_T1]:checked').val());
  var T2 = parseInt($('input[name=AlertO_T2]:checked').val());
  var T3 = parseInt($('input[name=AlertO_T3]:checked').val());

  var FR_ITIN_showStartEndTZ = parseInt($('input[name=FR_ITIN_showStartEndTZ_RDO]:checked').val());
  const showAutoLegOption = $('#ITIN_showAutoLegUpdateOption:checked').is(':checked') ? 1 : 0;

  $.getJSON(
    BASEURL +
      'menu-Administration-AJAX.php?action=saveAlertingSettings&Warning=' +
      Warning +
      '&T1=' +
      T1 +
      '&T2=' +
      T2 +
      '&T3=' +
      T3 +
      '&FR_ITIN_showStartEndTZ=' +
      FR_ITIN_showStartEndTZ +
      '&ITIN_showRespPerson=' +
      ($('#ITIN_showRespPerson').is(':checked') ? 1 : 0) +
      '&ITIN_showFlightDetails=' +
      ($('#ITIN_showFlightDetails').is(':checked') ? 1 : 0) +
      '&ITIN_showVIP=' +
      ($('#ITIN_showVIP').is(':checked') ? 1 : 0) +
      '&ITIN_showGenFRNum=' +
      ($('#ITIN_showGenFRNum').is(':checked') ? 1 : 0) +
      '&ITIN_showEmergEQ=' +
      ($('#ITIN_showEmergEQ').is(':checked') ? 1 : 0) +
      '&ITIN_showTotalCargo=' +
      ($('#ITIN_showTotalCargo').is(':checked') ? 1 : 0) +
      '&ITIN_showEngSS=' +
      ($('#ITIN_showEngSS').is(':checked') ? 1 : 0) +
      '&ITIN_showETA_ETE=' +
      ($('#ITIN_showETA_ETE').is(':checked') ? 1 : 0) +
      '&ITIN_useRouting=' +
      ($('#ITIN_useRouting').is(':checked') ? 1 : 0),
    function (json_data) {
      RemoteData.patch(`api/v2/groups/${GROUP_DATA['group']}/preferences`, {
        prefsToUpdate: [
          {
            settingKey: 'itinerary.autoLegUpdate',
            settingValue: showAutoLegOption,
            noPreference: 0,
          },
        ],
      }).then((response) => {
        VueBridge.setGroupPreference('itinerary.autoLegUpdate', showAutoLegOption.toString());

        //status could be offline/Not Logged In/Uncaught Exception
        exitAlertingOptions();

        $.mobile.loading('hide');
      });
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function LabellingOptions() {
  $.mobile.changePage($('#LabellingOptionsPage'), {
    reverse: false,
    changeHash: false,
  });
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getLabelSettings', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      console.log(json_data);
      displayLabelOpts(json_data[0].Labels);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

var GLOBAL_LABELS = {
  LegTimes: {
    LegStartE: 'Start Time',
    LegStart2E: 'Start Time',
    LegStart: 'Up Time',
    LegStart2: 'Up Time',
    LegEnd: 'Down Time',
    LegEnd2: 'Down Time',
    LegEndE: 'Stop Time',
    LegEnd2E: 'Stop Time',
    LegStart2E_pop: 'Start Time',
    LegStart2_pop: 'Up Time',
    LegEnd2_pop: 'Stop Time',
    LegEnd2E_pop: 'Stop Time',
  },
  Itinerary: {
    ITINERARYbtn: 'Flight Itinerary',
    Title_Report_Name: 'Itinerary Reports',
  },
};
var LABEL_DEFAULTS = {
  LegTimes: {
    LegStartE: 'Start Time',
    LegStart: 'Up Time',
    LegEnd: 'Down Time',
    LegEndE: 'Stop Time',
  },
  Itinerary: {
    ITINERARYbtn: 'Flight Itinerary',
    Title_Report_Name: 'Itinerary Reports',
  },
};
function ReLabelItems(json) {
  var Labels = '';
  if (GROUP_DATA.LabelOptions != undefined) {
    try {
      Labels = JSON.parse(GROUP_DATA.LabelOptions);
    } catch (e) {
      Labels = '';
    }
  }
  if (Labels == '') return;
  GLOBAL_LABELS = Labels;
  for (var i in GLOBAL_LABELS) {
    if (i == 'LegTimes') {
      //LegTimes are all label id's
      for (var I in GLOBAL_LABELS[i]) $("label[for='" + I + "']").html(GLOBAL_LABELS[i][I]);
    }
    if (i == 'Itinerary') {
      //Itinerary are all regular id tags
      for (var I in GLOBAL_LABELS[i]) $('#' + I).html(GLOBAL_LABELS[i][I]);
    }
  }
}
function displayLabelOpts(data) {
  var html = '';
  if (data != '') {
    try {
      data = JSON.parse(data);
    } catch (e) {
      data = '';
    }
  }
  if (data == '') {
    //display defaults
    for (var i in LABEL_DEFAULTS) {
      html += '<h3>' + i + '</h3>';
      for (var I in LABEL_DEFAULTS[i]) {
        html += '<label for="LDO-' + I + '">' + GLOBAL_LABELS[i][I] + '</label>';
        html += '<input type="text" id="LDO-' + I + '" value="' + escapeHtml(GLOBAL_LABELS[i][I]) + '"/>';
      }
    }
  } else {
    //load options
    for (var i in LABEL_DEFAULTS) {
      html += '<h3>' + i + '</h3>';
      for (var I in LABEL_DEFAULTS[i]) {
        html += '<label for="LDO-' + I + '">' + LABEL_DEFAULTS[i][I] + '</label>';
        html += '<input type="text" id="LDO-' + I + '" value="' + escapeHtml(data[i][I]) + '"/>';
      }
    }
  }
  $('#LabelOptionsHLDR').html(html);
  $('#LabelOptionsHLDR').enhanceWithin();
}

function UpdateLabellingOptions() {
  var NewData = {};
  for (var i in LABEL_DEFAULTS) {
    NewData[i] = {};
    for (var I in LABEL_DEFAULTS[i]) {
      NewData[i][I] = $('#LDO-' + I).val();
    }
  }
  NewData.LegTimes.LegStart2E = NewData.LegTimes.LegStartE;
  NewData.LegTimes.LegStart2 = NewData.LegTimes.LegStart;
  NewData.LegTimes.LegEnd2 = NewData.LegTimes.LegEnd;
  NewData.LegTimes.LegEnd2E = NewData.LegTimes.LegEndE;
  NewData.LegTimes.LegStart2E_pop = NewData.LegTimes.LegStartE;
  NewData.LegTimes.LegStart2_pop = NewData.LegTimes.LegStart;
  NewData.LegTimes.LegEnd2_pop = NewData.LegTimes.LegEnd;
  NewData.LegTimes.LegEnd2E_pop = NewData.LegTimes.LegEndE;

  console.log(NewData);
  //stringify and send it!
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Administration-AJAX.php?action=setLabelSettings',
    data: {
      LabelOptions: JSON.stringify(NewData),
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'UpdateLabellingOptions()');
      }
      if (data[0].ServerResponse == 'Success') {
        exitAdminTemplates();
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function BI_options() {
  $.mobile.changePage($('#BIoptions'), {
    reverse: false,
    changeHash: false,
  });
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getSettings', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      BI_display(json_data[0]);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function FD_options() {
  window.open(BASEURL + 'FD.php', '_blank');
}

function WinAir_options() {
  $.mobile.changePage($('#WinAir_OptionsPage'), {
    changeHash: false,
    reverse: false,
  });

  //get aircraft list and display in $("#WinAiContentDIV").html();
  $.getJSON(BASEURL + 'WinAir-AJAX.php?action=GetVolumePageList', function (json_data) {
    if (json_data[0].ServerResponse == 'No Aircraft') {
      $('#WinAiContentDIV').html('<h1 class="limit-table">' + iTrans('There are no aircraft in the system') + '</h1>');
    } else {
      AC_data = json_data;
      $('#WinAiContentDIV').html('');
      EL_data = json_data;
      $('#WinAiContentDIV').append(
        '<div style="width: 100%; overflow: auto"><table id="AC_Table" data-filter="true" class="limit-table last-td-fill">' +
          '<thead>' +
          '<tr>' +
          '<th data-priority="1">' +
          iTrans('Ident') +
          '</th>' +
          '<th data-priority="2">' +
          iTrans('Model') +
          '</th>' +
          '<th data-priority="3">' +
          iTrans('Serial') +
          '</th>' +
          '<th>' +
          iTrans('Volume') +
          '</th>' +
          '<th>' +
          iTrans('Page') +
          '</th>' +
          '<th data-priority="3">' +
          iTrans('Service Note') +
          '</th>' +
          '</tr>' +
          '</thead>' +
          '<tbody><tr>'
      );
      for (i in AC_data) {
        var Serviceable = '';
        if (AC_data[i].Serviceable == 0) Serviceable = 'redbg';
        if (AC_data[i].Hibrenated == 0) {
          if (AC_data[i].AcType == 'CUSTOM')
            $('#WinAiContentDIV tr:last').after(
              "<tr><td><button onClick='WinAirUpdateAC(" +
                i +
                ");' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg'/> " +
                AC_data[i].ident +
                '</button></td><td>' +
                AC_data[i].Custom_Type +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].Volume +
                '</td><td>' +
                AC_data[i].Page +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
          else
            $('#WinAiContentDIV tr:last').after(
              "<tr><td><button onClick='WinAirUpdateAC(" +
                i +
                ");' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true'><div class='vertical-center-container'><img src='./images/Check_mark_green.svg'/> " +
                AC_data[i].ident +
                '</button></td><td>' +
                AC_data[i].AcType +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].Volume +
                '</td><td>' +
                AC_data[i].Page +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
        } else {
          if (AC_data[i].AcType == 'CUSTOM')
            $('#WinAiContentDIV tr:last').after(
              "<tr><td><button onClick='WinAirUpdateAC(" +
                i +
                ");' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true' disabled>" +
                AC_data[i].ident +
                ' (Hibrenated)</button></td><td>' +
                AC_data[i].Custom_Type +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].Volume +
                '</td><td>' +
                AC_data[i].Page +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
          else
            $('#WinAiContentDIV tr:last').after(
              "<tr><td><button onClick='WinAirUpdateAC(" +
                i +
                ");' value='" +
                i +
                "' class='cirro-btn " +
                Serviceable +
                "' data-mini='true' disabled> " +
                AC_data[i].ident +
                ' (Hibrenated)</button></td><td>' +
                AC_data[i].AcType +
                '</td><td>' +
                AC_data[i].SN +
                '</td><td>' +
                AC_data[i].Volume +
                '</td><td>' +
                AC_data[i].Page +
                '</td><td>' +
                AC_data[i].ServiceReason +
                '</td></tr>'
            );
        }
      }
      $('#WinAiContentDIV tr:last').after('</tbody></table></div>');
      $('#WinAiContentDIV').enhanceWithin();
    }
  });
}

function WinAirUpdateAC(index) {
  var Item = AC_data[index];
  var html = '<h3>Update ' + Item.ident + '</h3>';
  html += '<label for="WinAirNewVolume">' + iTrans('Enter Current Volume') + '</label>';
  html += '<input type="number" id="WinAirNewVolume" value="' + Item.Volume + '"/>';
  html += '<label for="WinAirNewPage">' + iTrans('Enter Current Page') + '</label>';
  html += '<input type="number" id="WinAirNewPage" value="' + Item.Page + '"/>';
  html +=
    '<button onClick="WinAirUpdateVolumePageNow(' +
    index +
    ');" data-icon="plus" class="greenbg" >' +
    iTrans('Save Settings') +
    '</button>';
  $('#WinAiContentDIV').html(html);
  $('#WinAiContentDIV').enhanceWithin();
}
function WinAirUpdateVolumePageNow(index) {
  var Item = AC_data[index];
  var Volume = parseInt($('#WinAirNewVolume').val());
  var Page = parseInt($('#WinAirNewPage').val());
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL +
      'WinAir-AJAX.php?action=UpdateVolumePage&Volume=' +
      Volume +
      '&Page=' +
      Page +
      '&Ident=' +
      encodeURIComponent(Item.ident),
    function (json_data) {
      $.mobile.loading('hide');
      if (json_data[0].ServerResponse == 'Uncaught Exception') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTranhs('Uncaught Exception'),
          headerClose: false,
          buttonPrompt: iTrans('There was an error updating the aircraft') + ': ' + json_data[0].Exception,
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      } else if (json_data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed'),
          headerClose: false,
          buttonPrompt: iTrans('Failed to update Aircraft Flight Log Volume / Page.'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      } else if (json_data[0].ServerResponse == 'Success') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Aircraft Flight Log Volume / Page updated successfully.'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                exitWinAirOptions();
              },
            },
          },
        });
      }
    }
  ).fail(function (jqXHR, status, error) {
    //AJAXFAIL(error);

    $.mobile.loading('hide');
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('Failed'),
      headerClose: false,
      buttonPrompt: iTrans('Failed to update Aircraft Flight Log Volume / Page: Connection problems'),
      buttons: {
        [iTrans('Close')]: {
          click: function () {},
        },
      },
    });
  });
}

function exitWinAirOptions() {
  VueBridge.enterCorrectAdminToolMenu();
}

function BI_displayOperators() {
  if (BILLING_CLIENTS.length != 0) {
    var html = '<h3>' + iTrans('Operators') + '</h3>';
    html +=
      '<table><thead><tr><th>' +
      iTrans('Operator Name') +
      '</th><th>' +
      iTrans('Modified') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in BILLING_CLIENTS) {
      var I = BILLING_CLIENTS[i];
      html +=
        '<tr><td>' +
        I.Company_Name +
        '</td><td><div>' +
        (I.updated_at == null ? I.created_at : I.updated_at) +
        '</div></td><td><div class="vertical-center-container"><button onclick="editOperatorIndex(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn"><img src="./images/edit.svg"></button><button onclick="BILLING_deleteCustomCompany(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn"><img src="./images/delete.svg"></button></div></td></tr>';
    }
    html += '</tbody></table>';
    $('#operatorListHLDR').html(html);
    $('#operatorListHLDR').enhanceWithin();
  } else {
    $('#operatorListHLDR').html('');
  }
}

function BI_display(data) {
  BIDATA = data;
  BILLING_CLIENTS = data.Operators;
  if (GROUP_DATA.group == 'greatslave' || APP_ENVIRONMENT == 'staging' || APP_ENVIRONMENT == 'local') {
    $('#newOperatorHLDR').show();
    BI_displayOperators();
  } else {
    $('#newOperatorHLDR').hide();
  }
  $('#BIname').val(data.Company_Name);
  if (data.address != null) {
    $('#BIaddress1').val(data.address.address_one);
    $('#BIaddress2').val(data.address.address_two);
    $('#BIcity').val(data.address.city);
    $('#BIprovince').val(data.address.province);
    $('#BIpostal').val(data.address.postal_code);
    $('#BIcountry').val(data.address.country);

    $('#BIfirst').val(data.address.contact_first_name);
    $('#BIlast').val(data.address.contact_last_name);
    $('#BIphone').val(data.address.contact_phone);
    $('#BIemail').val(data.address.contact_email);
  } else {
    $('#BIaddress1').val('');
    $('#BIaddress2').val('');
    $('#BIcity').val('');
    $('#BIprovince').val('');
    $('#BIpostal').val('');
    $('#BIcountry').val('');

    $('#BIfirst').val('');
    $('#BIlast').val('');
    $('#BIphone').val('');
    $('#BIemail').val('');
  }

  $('#FR_showClientFuel')
    .prop('checked', data.FR_showClientFuel == 1 ? true : false)
    .checkboxradio('refresh');
  $('#FR_showTZ')
    .prop('checked', data.FR_showTZ == 1 ? true : false)
    .checkboxradio('refresh');
  $('#FR_showTDG')
    .prop('checked', data.FR_showTDG == 1 ? true : false)
    .checkboxradio('refresh');
  $('#FR_showAddress')
    .prop('checked', data.FR_showAddress == 1 ? true : false)
    .checkboxradio('refresh');
  $('#FR_showClientDetails')
    .prop('checked', data.FR_showClientDetails == 1 ? true : false)
    .checkboxradio('refresh');
  $('#FR_showJobNum')
    .prop('checked', data.FR_showJobNum == 1 ? true : false)
    .checkboxradio('refresh');

  $('[name="FR_ITIN_showStartEndTZ_RD1"]').removeAttr('checked').checkboxradio('refresh');
  $('input[name=FR_ITIN_showStartEndTZ_RD1][value=' + data.FR_ITIN_showStartEndTZ + ']')
    .prop('checked', true)
    .checkboxradio('refresh');

  if (data.DisableFRemail == 1) $('#BIdisableFRemail').prop('checked', true).checkboxradio('refresh');
  else $('#BIdisableFRemail').prop('checked', false).checkboxradio('refresh');

  if (COUNTRY != 'CANADA') {
    $('#BIcurConversionOPT_HLDR').hide();
  } else {
    $('#BIcurConversionOPT')
      .prop('checked', data.CurConversion == 1)
      .checkboxradio('refresh');
  }
  FR_AlertList = data.AlertList;
  ShowFR_AlertList('BI_AdminAlertList');

  $('#BI_InvoiceNumber').val(data.LastInvoice.InvoiceNum);
  $('#BI_invoiceblurb').val(data.invoice_blurb).keyup();
  $('#BI_flightreportblurb').val(data.flight_report_blurb).keyup();

  $('#BI_QuoteTerms').val(data.QuoteTerms).keyup();

  ADMIN_CFIELDS = data.Cfields;

  BILLING_CompanyCF(data.Cfields);

  BILLING_CompanyAdjustments(data.AdjItems);
  if (data.address.image != '')
    $('#BI_logo').html(
      "<img style='display:block; width:50%; height:50%;' id='BIbase64image' src='data:" +
        data.address.type +
        ';base64,' +
        data.address.image +
        "' />"
    );
  else $('#BI_logo').html(iTrans('No uploaded logo images'));

  $('#BIoptionsTEMPLATE0').prop('checked', false).checkboxradio('refresh');
  $('#BIoptionsTEMPLATE1').prop('checked', false).checkboxradio('refresh');
  $('#BIoptionsTEMPLATE2').prop('checked', false).checkboxradio('refresh');
  if (APP_ENVIRONMENT == 'staging' || APP_ENVIRONMENT == 'local' || GROUP_DATA.group == 'westwindheli') {
    $('#BIoptionsTEMPLATE3').prop('checked', false).checkboxradio('refresh');
  } else {
    $('#BIoptionsTEMPLATE3').prop('disabled', true).prop('checked', false).checkboxradio('refresh');
  }

  $('#BIoptionsTEMPLATE' + data.FR_Template)
    .prop('checked', true)
    .checkboxradio('refresh');

  ChangeInvoiceTemplate({ value: data.FR_Template });
  $('#BIoptionsHLDR').enhanceWithin();
}
var FR_AlertList = [];
function ShowFR_AlertList(div = 'BI_AdminAlertList', client = 0, job = 0) {
  var html = '<h3>' + iTrans('Flight Report CC Email List') + '</h3>';
  if (FR_AlertList.length > 0) {
    html +=
      '<table><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Email') +
      '</th><th>' +
      iTrans('Phone') +
      '</th><th>' +
      iTrans('Saved') +
      '</th><th>' +
      iTrans('Completed') +
      '</th><th>' +
      iTrans('SignReq') +
      '</th><th>' +
      iTrans('Signed') +
      '</th><th>' +
      iTrans('Base') +
      '</th><th>' +
      iTrans('Type') +
      '</th></tr></thead><tbody>';
    for (var i in FR_AlertList) {
      var I = FR_AlertList[i];
      if (I.DELETED == 1) continue;
      var Type = 'Global';
      if (I.GPK != 0 && I.JPK == 0) Type = 'Client';
      if (I.GPK != 0 && I.JPK != 0) Type = 'Job';
      html +=
        '<tr><td>' +
        I.ExternalContact +
        '</td><td>' +
        I.ExternalEmail +
        '</td><td>' +
        I.ExternalPhone +
        '</td><td>' +
        (I.on_Save == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.on_Complete == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.on_SigRequest == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.on_Signed == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        (I.CurBaseOnly == 1 ? 'Yes' : 'No') +
        '</td><td>' +
        Type +
        '</td></tr>';
    }
    html += '</tbody></table>';
  } else {
    html = '<h3>' + iTrans('No Entries For Flight Report Email List') + '</h3>';
  }
  html += `<button data-mini="true" data-icon="edit" onClick="EditFR_AlertList('${div}','${client}','${job}');">${iTrans(
    'Edit Flight Report CC Email List'
  )}</button>`;
  $('#' + div).html(html);
  $('#' + div).enhanceWithin();
}

function EditFR_AlertList(div, client, job) {
  var html = '<h3>' + iTrans('Flight Report CC Email List') + '</h3>';
  if (FR_AlertList.length > 0) {
    html +=
      '<table><thead><tr><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Email') +
      '</th><th>' +
      iTrans('Phone') +
      '</th><th>' +
      iTrans('Saved') +
      '</th><th>' +
      iTrans('Completed') +
      '</th><th>' +
      iTrans('SignReq') +
      '</th><th>' +
      iTrans('Signed') +
      '</th><th>' +
      iTrans('Base') +
      '</th><th>' +
      iTrans('Type') +
      '</th></tr></thead><tbody>';
    for (var i in FR_AlertList) {
      var I = FR_AlertList[i];
      if (I.DELETED == 1) continue;
      var Type = 'Global';
      if (I.GPK != 0 && I.JPK == 0) Type = 'Client';
      if (I.GPK != 0 && I.JPK != 0) Type = 'Job';
      var disabledCHK = '';
      if ((div == 'BM_ClientAlertList' && Type != 'Client') || (div == 'BM_JobAlertList' && Type != 'Job'))
        disabledCHK = 'disabled';
      var disabledExternalCHK = disabledCHK;
      if (I.User_PK == 0) disabledExternalCHK = 'disabled';
      html += `<tr><td>${I.ExternalContact}</td><td>${I.ExternalEmail}</td><td>${I.ExternalPhone}</td><td>${
        I.on_Save == 1
          ? `<input type="checkbox" onclick="onSaveFRAlertListIndex(${i},'${div}','${client}','${job}', this);" checked ${disabledCHK}>`
          : `<input type="checkbox" onclick="onSaveFRAlertListIndex(${i},'${div}','${client}','${job}', this);" ${disabledCHK}>`
      }</td><td><center>${
        I.on_Complete == 1
          ? `<input type="checkbox" onclick="onCompleteFRAlertListIndex(${i},'${div}','${client}','${job}', this);" checked ${disabledCHK}>`
          : `<input type="checkbox" onclick="onCompleteFRAlertListIndex(${i},'${div}','${client}','${job}', this);" ${disabledCHK}>`
      }</center></td><td>${
        I.on_SigRequest == 1
          ? `<input type="checkbox" onclick="onSigRequestFRAlertListIndex(FRAlertListIndex(${i},'${div}','${client}','${job}', this);" checked ${disabledCHK}>`
          : `<input type="checkbox" onclick="onSigRequestFRAlertListIndex(${i},'${div}','${client}','${job}', this);" ${disabledCHK}>`
      }</td><td>${
        I.on_Signed == 1
          ? `<input type="checkbox" onclick="onSignedFRAlertListIndex(${i},'${div}','${client}','${job}', this);" checked ${disabledCHK}>`
          : `<input type="checkbox" onclick="onSignedFRAlertListIndex(${i},'${div}','${client}','${job}', this);" ${disabledCHK}>`
      }</td><td>${
        I.CurBaseOnly == 1
          ? `<input type="checkbox" onclick="onCurBaseFRAlertListIndex(${i},'${div}','${client}','${job}', this);" checked ${disabledExternalCHK}>`
          : `<input type="checkbox" onclick="onCurBaseFRAlertListIndex(${i},'${div}','${client}','${job}', this);" ${disabledExternalCHK}>`
      }</td><td>${Type}</td><td><div class="vertical-center-container"><button onclick="deleteFRAlertListIndex(${i},'${div}','${client}','${job}');" class="action-btn" data-mini="true" ${disabledCHK}><img src="./images/delete.svg"></button></div></td></tr>`;
    }
    html += '</tbody></table>';
  } else {
    html = '<h3>' + iTrans('No Entries For Flight Report CC Email List') + '</h3>';
  }
  html += `<table><tr><td><button data-mini="true" onClick="AddFR_AlertList_External('${div}','${client}','${job}')" class="greenbg" data-icon="plus">${iTrans(
    'External User'
  )}</button></td>`;
  html += `<td><button data-mini="true" onClick="AddFR_AlertList_System('${div}','${client}','${job}')" data-icon="plus" class="greenbg">${iTrans(
    'System User'
  )}</button></td>`;
  html += `<td><button data-icon="alert" data-mini="true" onClick="SaveFR_AlertList('${div}','${client}','${job}')" class="yellowbg">${iTrans(
    'Save Edits'
  )}</button></td></tr></table>`;
  $('#' + div).html(html);
  $('#' + div).enhanceWithin();
}

function onSaveFRAlertListIndex(index, div, client, job, e) {
  if (e.checked) {
    FR_AlertList[index].on_Save = 1;
  } else {
    FR_AlertList[index].on_Save = 0;
  }
  EditFR_AlertList(div, client, job);
}

function onCompleteFRAlertListIndex(index, div, client, job, e) {
  if (e.checked) {
    FR_AlertList[index].on_Complete = 1;
  } else {
    FR_AlertList[index].on_Complete = 0;
  }
  EditFR_AlertList(div, client, job);
}

function onSigRequestFRAlertListIndex(index, div, client, job, e) {
  if (e.checked) {
    FR_AlertList[index].on_SigRequest = 1;
  } else {
    FR_AlertList[index].on_SigRequest = 0;
  }
  EditFR_AlertList(div, client, job);
}

function onSignedFRAlertListIndex(index, div, client, job, e) {
  if (e.checked) {
    FR_AlertList[index].on_Signed = 1;
  } else {
    FR_AlertList[index].on_Signed = 0;
  }
  EditFR_AlertList(div, client, job);
}

function onCurBaseFRAlertListIndex(index, div, client, job, e) {
  if (e.checked) {
    FR_AlertList[index].CurBaseOnly = 1;
  } else {
    FR_AlertList[index].CurBaseOnly = 0;
  }
  EditFR_AlertList(div, client, job);
}

function SaveFR_AlertList(div, client, job) {
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Administration-AJAX.php?action=saveFRAlertList',
    data: {
      AlertList: JSON.stringify(FR_AlertList),
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'SaveFR_AlertList()');
      }
      if (data[0].ServerResponse == 'Success') {
        var FR_length = FR_AlertList.length;
        //remove deleted users from local list so they can be readded without going all the way out and back in
        while (FR_length > 0) {
          FR_length--;
          if (FR_AlertList[FR_length].DELETED == 1) {
            FR_AlertList.splice(FR_length, 1);
          }
        }
        ShowFR_AlertList(div, client, job);
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //Update File Now Text - W&B Problem
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}

function FR_UpdateRowClass(index, e) {
  if (e.checked) $('#FR_AlertRow-' + index).addClass('greenbg');
  else $('#FR_AlertRow-' + index).removeClass('greenbg');
}

function deleteFRAlertListIndex(index, div, client, job) {
  if (FR_AlertList[index].PrimaryKey == 'NEW') FR_AlertList.splice(index, 1);
  else FR_AlertList[index].DELETED = 1;
  EditFR_AlertList(div, client, job);
}

function AddFR_AlertList_System(div, client, job) {
  var html =
    '<div style="overflow:scroll; height: 300px;"><table class="limit-table" data-filter="true"><thead><tr><th>' +
    iTrans('Name') +
    '</th><th>' +
    iTrans('Type') +
    '</th><th>' +
    iTrans('Select') +
    '</th></tr></thead><tbody>';
  for (var i in STAFF_LIST) {
    var I = STAFF_LIST[i];
    var disabledCHK = '';
    var disbledClass = '';
    if (FR_AlertList.find((o) => o.User_PK == I.PrimaryKey) != undefined) {
      disabledCHK = 'disabled';
      disbledClass = 'yellowbg';
    }
    var Type = 'Global';
    if (I.GPK != 0 && I.JPK == 0) Type = 'Client';
    if (I.GPK != 0 && I.JPK != 0) Type = 'Job';

    var check =
      '<input type="checkbox" data-role="none" id="FR_AlertStaffSelect-' +
      i +
      '" onClick="FR_UpdateRowClass(' +
      i +
      ', this);" ' +
      disabledCHK +
      '/>';
    html +=
      '<tr id="FR_AlertRow-' +
      i +
      '" class="' +
      disbledClass +
      '"><td>' +
      I.chatname +
      '</td><td>' +
      I.staffType +
      '</td><td>' +
      check +
      '</td></tr>';
  }
  html += '</tbody></table>';
  html += '</div>';

  html +=
    '<label for="FR_Alert_on_Save">' +
    iTrans('On Save') +
    '</label><input type="checkbox" id="FR_Alert_on_Save" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_Complete">' +
    iTrans('On Completed') +
    '</label><input type="checkbox" id="FR_Alert_on_Complete" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_SigRequest">' +
    iTrans('On Signature Request') +
    '</label><input type="checkbox" id="FR_Alert_on_SigRequest" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_Signed">' +
    iTrans('On Signed') +
    '</label><input type="checkbox" id="FR_Alert_on_Signed" data-mini="true" />';

  html +=
    '<label for="FR_Alert_CurBaseOnly">' +
    iTrans('Associated to Base Only') +
    '</label><input type="checkbox" id="FR_Alert_CurBaseOnly" data-mini="true" />';

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Add System User'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          //acknolwedge
          var alert_on_Save = $('#FR_Alert_on_Save').is(':checked') ? 1 : 0;
          var alert_on_Complete = $('#FR_Alert_on_Complete').is(':checked') ? 1 : 0;
          var alert_on_SigRequest = $('#FR_Alert_on_SigRequest').is(':checked') ? 1 : 0;
          var alert_on_Signed = $('#FR_Alert_on_Signed').is(':checked') ? 1 : 0;
          var alert_CurBaseOnly = $('#FR_Alert_CurBaseOnly').is(':checked') ? 1 : 0;

          for (var i in STAFF_LIST) {
            var I = STAFF_LIST[i];
            if (FR_AlertList.find((o) => o.User_PK == I.PrimaryKey) != undefined) continue;

            if ($('#FR_AlertStaffSelect-' + i).is(':checked')) {
              FR_AlertList.push({
                PrimaryKey: 'NEW',
                group: GROUP_DATA.group,
                GPK: client,
                JPK: job,
                User_PK: I.PrimaryKey,
                ExternalContact: I.chatname,
                ExternalEmail: '[Linked]',
                ExternalPhone: '[Linked]',
                on_Save: alert_on_Save,
                on_Complete: alert_on_Complete,
                on_SigRequest: alert_on_SigRequest,
                on_Signed: alert_on_Signed,
                CurBaseOnly: alert_CurBaseOnly,
                DELETED: 0,
              });
            }
          }
          EditFR_AlertList(div, client, job);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
    width: 'auto',
  });
}

function AddFR_AlertList_External(div, client, job) {
  var html = '';
  html +=
    '<label for="FR_Alert_ExternalContact">' +
    iTrans('Name') +
    '</label><input type="text" id="FR_Alert_ExternalContact" data-mini="true" />';
  html +=
    '<label for="FR_Alert_ExternalEmail">' +
    iTrans('Email Address') +
    '</label><input type="text" id="FR_Alert_ExternalEmail" data-mini="true" />';
  html +=
    '<label for="FR_Alert_ExternalPhone">' +
    iTrans('Phone Number') +
    '</label><input type="text" id="FR_Alert_ExternalPhone" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_Save">' +
    iTrans('On Save') +
    '</label><input type="checkbox" id="FR_Alert_on_Save" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_Complete">' +
    iTrans('On Completed') +
    '</label><input type="checkbox" id="FR_Alert_on_Complete" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_SigRequest">' +
    iTrans('On Signature Request') +
    '</label><input type="checkbox" id="FR_Alert_on_SigRequest" data-mini="true" />';

  html +=
    '<label for="FR_Alert_on_Signed">' +
    iTrans('On Signed') +
    '</label><input type="checkbox" id="FR_Alert_on_Signed" data-mini="true" />';

  //leave this in since we can allow this to happen in the future
  /*
  html +=

      '<label for="FR_Alert_CurBaseOnly">' +
      iTrans('Associated to Base Only') +
      '</label><input type="checkbox" id="FR_Alert_CurBaseOnly" data-mini="true" />';;
  */
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Add External User'),
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      [iTrans('Add')]: {
        click: function () {
          //acknolwedge
          var alert_on_Save = $('#FR_Alert_on_Save').is(':checked') ? 1 : 0;
          var alert_on_Complete = $('#FR_Alert_on_Complete').is(':checked') ? 1 : 0;
          var alert_on_SigRequest = $('#FR_Alert_on_SigRequest').is(':checked') ? 1 : 0;
          var alert_on_Signed = $('#FR_Alert_on_Signed').is(':checked') ? 1 : 0;
          //var alert_CurBaseOnly = $('#FR_Alert_CurBaseOnly').is(':checked') ? 1 : 0;
          var alert_CurBaseOnly = 0;

          FR_AlertList.push({
            PrimaryKey: 'NEW',
            group: GROUP_DATA.group,
            GPK: client,
            JPK: job,
            User_PK: 0,
            ExternalContact: $('#FR_Alert_ExternalContact').val(),
            ExternalEmail: $('#FR_Alert_ExternalEmail').val(),
            ExternalPhone: $('#FR_Alert_ExternalPhone').val(),
            on_Save: alert_on_Save,
            on_Complete: alert_on_Complete,
            on_SigRequest: alert_on_SigRequest,
            on_Signed: alert_on_Signed,
            CurBaseOnly: alert_CurBaseOnly,
            DELETED: 0,
          });
          EditFR_AlertList(div, client, job);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {
          //acknolwedge
        },
      },
    },
    width: 'auto',
  });
}

function BILLING_CompanyAdjustments(AdjItems) {
  var html = '';
  if (AdjItems.length == 0) $('#BillingAdjustments').html(iTrans('No Adjustments'));
  else {
    html =
      "<table class='first-td-fill'><thead><tr><th>" +
      iTrans('Item') +
      '</th><th>Qty</th><th>' +
      iTrans('Unit') +
      '</th><th>' +
      iTrans('Amount') +
      '</th><th>' +
      iTrans('Action') +
      '</th></tr></thead><tbody>';
    for (var i in AdjItems) {
      var Buttons =
        '<div class="vertical-center-container"><button onclick="BILLING_RemoveCompanyAdjustment(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn"><img src="./images/delete.svg"></button></div>';
      var I = AdjItems[i];
      html +=
        '<tr><td>' +
        I.Desc +
        '</td><td>' +
        I.Qty +
        '</td><td>' +
        I.Unit +
        '</td><td>$' +
        I.Amount +
        '</td><td>' +
        Buttons +
        '</td></tr>';
    }
    html += '</tbody></table>';
    $('#BillingAdjustments').html(html);
  }
}

function AdminNewAdjustmentItem() {
  var Desc = '';
  var Value = 0;
  var Qty = 1;
  var html = '<label for="ADJ_DESC">' + iTrans('Description') + '</label>';
  html += '<input type="text" id="ADJ_DESC" value="' + Desc + '"/>';
  html += '<label for="ADJ_Qty">' + iTrans('Quantity') + '</label>';
  html += '<input type="number" id="ADJ_Qty" value="' + Qty + '"/>';
  html += '<label for="ADJ_Qty_Unit">' + iTrans('Unit') + '</label>';
  html += '<select id="ADJ_Qty_Unit">';
  html += '<option value="">' + iTrans('None') + '</option>';
  html += '<option value="NM">NM</option>';
  html += '<option value="sm">sm</option>';
  html += '<option value="km">km</option>';
  html += '<option value="kg">kg</option>';
  html += '<option value="lbs">lbs</option>';
  html += '<option value="L">Litres</option>';
  html += '<option value="Gal">Gallons</option>';
  html += '<option value="Days">' + iTrans('Days') + '</option>';
  html += '<option value="Hours">' + iTrans('Hours') + '</option>';
  html += '</select>';
  html += '<label for="ADJ_AMOUNT">' + iTrans('Amount') + '</label>';
  html += '<input type="number" id="ADJ_AMOUNT" value="' + Value + '"/>';
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Adjustments',
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      Add: {
        click: function () {
          //acknolwedge
          var Description = $('#ADJ_DESC').val();
          var Amount = $('#ADJ_AMOUNT').val();
          if (isNaN(Amount)) Amount = 0;
          var Qty = $('#ADJ_Qty').val();
          if (isNaN(Qty)) Qty = 0;
          var Unit = $('#ADJ_Qty_Unit').val();
          BILLING_AddAdj(Description, Qty, Amount, Unit);
        },
      },
      Cancel: {
        click: function () {
          //do nothing
        },
      },
    },
  });
}
function BILLING_AddAdj(Desc, Qty, Amount, Unit) {
  $.getJSON(
    BASEURL +
      'menu-Administration-AJAX.php?action=newAdj&Desc=' +
      encodeURIComponent(Desc) +
      '&Qty=' +
      Qty +
      '&Amount=' +
      Amount +
      '&Unit=' +
      encodeURIComponent(Unit),
    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'newAdj');
      else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      else if (json_data[0].ServerResponse == 'No Records') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('ERROR'),
          headerClose: false,
          buttonPrompt: iTrans('ERROR Contact site admin'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {},
            },
          },
        });
      } else {
        console.log(json_data);
        BI_display(json_data[0]);
      }
      $.mobile.loading('hide');
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function BILLING_RemoveCompanyAdjustment(index) {
  //pop confirmation dialog

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('Warning'),
    headerClose: false,
    buttonPrompt: iTrans('Are you sure you want to delete this Adjustment Item?  This cannot be undone.'),
    buttons: {
      [iTrans('Delete')]: {
        click: function () {
          BILLING_DeleteCompanyAdjustment(index);
        },
      },
      [iTrans('Cancel')]: {
        click: function () {},
      },
    },
  });
}
function BILLING_DeleteCompanyAdjustment(index) {
  var PrimaryKey = BIDATA.AdjItems[index].PrimaryKey;
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=deleteAdj&PK=' + PrimaryKey, function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'deleteAdj');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      console.log(json_data);
      BI_display(json_data[0]);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}
function BILLING_CompanyCF(CF) {
  //get custom fields for client
  //var CF = BILLING_CLIENTS[BILLING_CLIENT_INDEX].Cfields;
  if (CF.length > 0) {
    var html =
      "<table class='first-td-fill'><thead><tr><th>" +
      iTrans('Preview') +
      '</th><th>' +
      iTrans('Name') +
      '</th><th>' +
      iTrans('Type') +
      '</th><th>' +
      iTrans('Collected') +
      '</th><th>Req</th><th>' +
      iTrans('Action') +
      '</th><th>' +
      iTrans('Sort Order') +
      '</th></tr></thead><tbody>';
    for (var i in CF) {
      var I = CF[i];
      if (I.Aircraft !== '') {
        continue;
      }
      var Collected = checkCfieldCollectedFor(I);
      if (I.PerLeg == 1) Collected += ' (' + iTrans('Per Leg') + ')';
      var Req = checkCfieldRequiredFor(I);
      var Preview = createCustomField('', I, I.default);
      var Button =
        '<div class="vertical-center-container"><button onclick="BILLING_EditCompanyCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/edit.svg"></button><button onclick="BILLING_RemoveCompanyCF(' +
        i +
        ')" data-mini="true" class="action-btn ui-btn ui-shadow ui-corner-all ui-mini"><img src="./images/delete.svg"></button></div>';

      var SortOrder =
        '<div class="vertical-center-container"><button class="action-btn ui-btn" data-mini="true" onclick="ADMIN_CF_SortOrder_UP(' +
        i +
        ')"><img src="./images/Plus-icon.png" widht="20px" height="20px"></button><button class="action-btn" data-mini="true" onclick="ADMIN_CF_SortOrder_DOWN(' +
        i +
        ')"><img src="./images/Minus-icon.png" widht="20px" height="20px"></button></div>';

      html +=
        '<tr><td>' +
        Preview +
        '</td><td>' +
        I.Label +
        '</td><td>' +
        I.type +
        '</td><td>' +
        Collected +
        '</td><td>' +
        Req +
        '</td><td>' +
        Button +
        '</td><td>' +
        SortOrder +
        '</td></tr>';
    }
    html += '</tbody></table>';
    clearAllCfieldHolders();
    $('#CompanyCustomFieldsContent').html(html);
    $('#CompanyCustomFieldsContent').enhanceWithin();
  } else {
    $('#CompanyCustomFieldsContent').html('<h3>' + iTrans('No Custom Fields Found') + '</h3>');
  }
}
var ADMIN_BILLING_FIELDS = false;
var ADMIN_CFIELDS = [];
function ADMIN_CF_SortOrder_DOWN(q) {
  if (q == ADMIN_CFIELDS - 1) return;
  q = parseInt(q);
  nq = q + 1;
  ADMIN_CF_UpdateSortOrder(q, nq);
}
function ADMIN_CF_SortOrder_UP(q) {
  if (q == 0) return;
  q = parseInt(q);
  nq = q - 1;
  ADMIN_CF_UpdateSortOrder(q, nq);
}
function ADMIN_CF_UpdateSortOrder(q, nq) {
  var PK1 = ADMIN_CFIELDS[q].PrimaryKey;
  var PK2 = ADMIN_CFIELDS[nq].PrimaryKey;
  if (BILLING_RETURN_TO == 'Briefing') {
    $.getJSON(
      BASEURL + 'menu-PaxManifesting-AJAX.php?action=reorderCflieds&PK1=' + PK1 + '&PK2=' + PK2 + '&Briefing=1',
      function (json_data) {
        ADMIN_CFIELDS = CF_array_move(ADMIN_CFIELDS, q, nq);
        Pax_LoadCfields();
      }
    ).fail(function (jqXHR, status, error) {
      alert(iTrans('Connection Error. Sort order not updated'));
    });
  } else if (BILLING_RETURN_TO == 'Passenger') {
    $.getJSON(
      BASEURL + 'menu-PaxManifesting-AJAX.php?action=reorderCflieds&PK1=' + PK1 + '&PK2=' + PK2 + '&Briefing=0',
      function (json_data) {
        ADMIN_CFIELDS = CF_array_move(ADMIN_CFIELDS, q, nq);
        Pax_LoadCfields();
      }
    ).fail(function (jqXHR, status, error) {
      alert(iTrans('Connection Error. Sort order not updated'));
    });
  } else {
    $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=reorderCflieds&PK1=' + PK1 + '&PK2=' + PK2, function (json_data) {
      ADMIN_CFIELDS = CF_array_move(ADMIN_CFIELDS, q, nq);
      BILLING_CompanyCF(ADMIN_CFIELDS);
    }).fail(function (jqXHR, status, error) {
      alert(iTrans('Connection Error. Sort order not updated'));
    });
  }
}

function BILLING_EditCompanyCF(e) {
  ADMIN_BILLING_FIELDS = true;
  $('#BILLING_CANCEL_CF').removeAttr('onclick');
  $('#BILLING_CANCEL_CF').unbind('click');
  $('#BILLING_CANCEL_CF').click(AdminCancelCF);
  $('#BIlogofile').val(null);
  BILLING_EditClientCF(e);
}

function BILLING_RemoveCompanyCF(e) {
  //confirm delete before removing Cfield PrimaryKey
  var PK = BIDATA.Cfields[e].PrimaryKey;
  console.log(PK);

  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: iTrans('WARNING'),
    headerClose: false,
    buttonPrompt:
      '<center>' +
      iTrans('You are about to delete this custom field') +
      '</p>  <h3>' +
      iTrans('Are you Sure?') +
      '</h3></center>',
    buttons: {
      [iTrans('Cancel')]: {
        click: function () {
          //do nothing
        },
      },
      [iTrans('Delete')]: {
        click: function () {
          //warn user existing records for each user will be deleted
          $.getJSON(BASEURL + 'menu-Billing-AJAX.php?action=deleteCustomField&PK=' + PK, function (json_data) {
            //status could be offline/Not Logged In/Uncaught Exception
            if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
            else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
            else if (json_data[0].ServerResponse == 'No Records') {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('ERROR'),
                headerClose: false,
                buttonPrompt: iTrans('ERROR Contact site admin'),
                buttons: {
                  [iTrans('Close')]: {
                    click: function () {},
                  },
                },
              });
            } else {
              returnAdminBICF(); //reload admin display
            }
          }).fail(function (jqXHR, status, error) {
            AJAXFAIL(error);
          });
        },
      },
    },
  });
}

function BILLING_AddCompanyCF() {
  BILLING_RETURN_TO = 'Company'; //global var in billing.js
  $.mobile.changePage($('#BILLING_NewClientCFaddPage'), {
    changeHash: false,
  });
  $('#BILLING_CANCEL_CF').removeAttr('onclick');
  $('#BILLING_CANCEL_CF').unbind('click');
  $('#BILLING_CANCEL_CF').click(AdminCancelCF);
  ADMIN_BILLING_FIELDS = true;

  BILLING_CLIENT_CFIELD_INDEX = 0; //to indicate a new field for a client;
  BILLING_CLIENT_CFIELD.PrimaryKey = 0;
  BILLING_CLIENT_CFIELD.type = '';
  BILLING_CLIENT_CFIELD.options = [];
  BILLING_CLIENT_CFIELD.Bill_Item = 0;
  BILLING_CLIENT_CFIELD.Bill_Item_options = '';
  $('#BILLING_CF_CHK_OPTIONS_HLDR').hide();
  $('#BILLING_CF_BILLITEM').prop('checked', false);
  $('#BILLING_CF_BILLITEM').checkboxradio('refresh');
  BILLING_BILLITEM({ checked: false });
  //we're here at the page now what?  resetl inputs
  $('#BILLING_CF_Label').val('');
  $('#BILLING_CF_TYPE').val(null).selectmenu('refresh');

  $('#BILLING_CF_USEON_ALL').prop('checked', true).checkboxradio('refresh');
  $('#BILLING_CF_USEON_FR').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_ITIN').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_SCH').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_USEON_SCA').prop('checked', false).checkboxradio('refresh');
  $('#BILLING_CF_PH').val('');

  $('#BILLING_CF_REQ').prop('checked', false);
  $('#BILLING_CF_REQ').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_REQ_ITIN').prop('checked', false);
  $('#BILLING_CF_REQ_ITIN').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_REQ_SCH').prop('checked', false);
  $('#BILLING_CF_REQ_SCH').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_HIDEDEFAULT').prop('checked', false);
  $('#BILLING_CF_HIDEDEFAULT').checkboxradio('enable').checkboxradio('refresh');

  $('#BILLING_CF_PERLEG').prop('checked', false);
  $('#BILLING_CF_PERLEG').checkboxradio('refresh');
  $('#ClientCustomField').html('');
}

function AdminCancelCF() {
  $.mobile.changePage($('#BIoptions'), {
    reverse: true,
    changeHash: false,
  });
  $('#BILLING_CANCEL_CF').unbind('click');
  $('#BILLING_CANCEL_CF').click(BILLING_ClientCFreturn);
}

function returnAdminBICF() {
  $('#BILLING_CANCEL_CF').unbind('click', AdminCancelCF);
  $('#BILLING_CANCEL_CF').click(BILLING_ClientCFreturn);
  $.mobile.changePage($('#BIoptions'), {
    reverse: true,
    changeHash: false,
  });
  //reload custom fields
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(BASEURL + 'menu-Administration-AJAX.php?action=getSettings', function (json_data) {
    //status could be offline/Not Logged In/Uncaught Exception
    if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'getSettings');
    else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
    else if (json_data[0].ServerResponse == 'No Records') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('ERROR'),
        headerClose: false,
        buttonPrompt: iTrans('ERROR Contact site admin'),
        buttons: {
          [iTrans('Close')]: {
            click: function () {},
          },
        },
      });
    } else {
      console.log(json_data);
      BI_display(json_data[0]);
    }
    $.mobile.loading('hide');
  }).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

function BI_updateLogo() {
  var file = document.getElementById('BIlogofile').files[0];
  console.log(file);
  if (file != undefined) {
    $.mobile.loading('show', {
      theme: 'a',
    });
    var filename = file.name;
    var filesize = file.size;
    var file64;
    console.log(filesize);
    if (filesize > 13000000) {
      $('#BIlogofile').val('');
    } else {
      var reader = new FileReader();
      reader.onload = function (e) {
        if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/gif') {
          var img = document.createElement('img');
          img.onload = function () {
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            var MAX_WIDTH = MAX_PHOTO_RESOLUTION;
            var MAX_HEIGHT = MAX_PHOTO_RESOLUTION;
            var width = img.width;
            var height = img.height;
            console.log(width);
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            var dataurl = canvas.toDataURL(file.type);

            file64 = dataurl.split(',')[1];
            if (BIDATA.logo == '') {
              BIDATA.logo = {
                id: 0,
              };
              BIDATA.image = {};
            } else {
            }
            BIDATA.address.size = file.type;
            BIDATA.address.filename = filename;
            BIDATA.address.size = filesize;
            BIDATA.address.image = file64;
            $.mobile.loading('hide');
            $('#BI_logo').html(
              "<img style='display:block; width:50%; height:50%;' id='BIbase64image' src='" + dataurl + "' />"
            );
          };
          img.src = e.target.result;
        } else {
          console.log('Wrong filetype');
          $('#BIlogofile').val('');
        }
      };
      reader.onerror = function (e) {
        //reset file input
        $.mobile.loading('hide');
        console.log('Scheduler FileReader Error');
        $('#SCHfileprogress').html('<h2>' + iTrans('Error Reading File') + '!!!</h2>');
      };
      reader.readAsDataURL(file);
    }
  } else {
    //reset file input
    console.log('No Shceduler File Selected');
    $('#BIlogofile').val('');
  }
}

function Admin_UpdateInvoiceNum() {
  var InvoiceNum = $('#BI_InvoiceNumber').val();
  if (InvoiceNum < 0) {
    alert('' + iTrans('Invoice Number cannot be less than 0') + '!');
    return;
  }
  if (InvoiceNum.length > 20) {
    alert('' + iTrans('Invoice Number cannot be longer tha 24 numbers') + '');
    return;
  }
  if (InvoiceNum == '') {
    alert('' + iTrans('Invoice Number cannot be blank') + '');
    return;
  }
  $.mobile.loading('show', {
    theme: 'a',
  });
  $.getJSON(
    BASEURL + 'menu-Administration-AJAX.php?action=saveInvoiceNum&InvoiceNum=' + parseInt(InvoiceNum),
    function (json_data) {
      //status could be offline/Not Logged In/Uncaught Exception
      if (json_data[0].ServerResponse == 'Uncaught Exception') ServerResponse(json_data[0], 'updateinvoicenum');
      else if (json_data[0].ServerResponse == 'Not Logged In') NOTLOGGEDIN();
      else if (json_data[0].ServerResponse == 'Success') {
        $.mobile.loading('hide');
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Success'),
          headerClose: false,
          buttonPrompt: iTrans('Invoice Numbering Updated Successfully'),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                VueBridge.enterCorrectAdminToolMenu();
              },
            },
          },
        });
      }
    }
  ).fail(function (jqXHR, status, error) {
    AJAXFAIL(error);
    $.mobile.loading('hide');
  });
}

function BIsave() {
  $.mobile.loading('show', {
    theme: 'a',
  });

  BIDATA.Company_Name = $('#BIname').val();

  if (BIDATA.address == '') BIDATA.address = {};
  BIDATA.address.address_one = $('#BIaddress1').val();
  BIDATA.address.address_two = $('#BIaddress2').val();
  BIDATA.address.city = $('#BIcity').val();
  BIDATA.address.province = $('#BIprovince').val();
  BIDATA.address.postal_code = $('#BIpostal').val();
  BIDATA.address.country = $('#BIcountry').val();
  BIDATA.address.contact_first_name = $('#BIfirst').val();
  BIDATA.address.contact_last_name = $('#BIlast').val();
  BIDATA.address.contact_phone = $('#BIphone').val();
  BIDATA.address.contact_email = $('#BIemail').val();

  BIDATA.invoice_blurb = $('#BI_invoiceblurb').val();
  BIDATA.flight_report_blurb = $('#BI_flightreportblurb').val();
  BIDATA.QuoteTerms = $('#BI_QuoteTerms').val();
  BIDATA.FR_Template = $("input[name='BIoptionsTEMPLATE']:checked").val();
  if ($('#BIdisableFRemail').is(':checked')) BIDATA.DisableFRemail = 1;
  else BIDATA.DisableFRemail = 0;

  BIDATA.FR_showClientFuel = $('#FR_showClientFuel').is(':checked') ? 1 : 0;
  BIDATA.FR_showTZ = $('#FR_showTZ').is(':checked') ? 1 : 0;
  BIDATA.FR_showTDG = $('#FR_showTDG').is(':checked') ? 1 : 0;
  BIDATA.FR_showAddress = $('#FR_showAddress').is(':checked') ? 1 : 0;
  BIDATA.FR_showClientDetails = $('#FR_showClientDetails').is(':checked') ? 1 : 0;
  BIDATA.FR_showJobNum = $('#FR_showJobNum').is(':checked') ? 1 : 0;
  BIDATA.FR_ITIN_showStartEndTZ = $("input[name='FR_ITIN_showStartEndTZ_RD1']:checked").val();

  if (COUNTRY != 'CANADA') {
    BIDATA.CurConverstion = 0;
  } else {
    BIDATA.CurConverstion = $('#BIcurConversionOPT').is(':checked') ? 1 : 0;
  }

  $.mobile.loading('show', {
    theme: 'a',
  });
  $.ajax({
    type: 'POST',
    dataType: 'json',
    url: BASEURL + 'menu-Administration-AJAX.php?action=saveSettings',
    data: {
      BIDATA: JSON.stringify(BIDATA),
    },
  })
    .success(function (data) {
      //show success dialog only if server responds success otherwise show failed dialog with optional reporting - data.responseText
      console.log('ServerResponse: check');
      console.log(data);
      //ensure data[0].ServerResponse == Success
      if (data[0].ServerResponse == 'Uncaught Exception') {
        ServerResponse(data[0], 'BIsave()');
      }
      if (data[0].ServerResponse == 'Success') {
        GROUP_DATA.FR_Template = BIDATA.FR_Template;
        exitAdminTemplates();
      }
      if (data[0].ServerResponse == 'Failed') {
        $('<div>').simpledialog2({
          mode: 'button',
          animate: false,
          headerText: iTrans('Failed!'),
          headerClose: false,
          buttonPrompt: iTrans(
            'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
          ),
          buttons: {
            [iTrans('Close')]: {
              click: function () {
                //Update File Now Text - W&B Problem
              },
            },
            [iTrans('Report')]: {
              click: function () {
                //File bug report
              },
            },
          },
        });
      }
      $.mobile.loading('hide');
    })
    .fail(function (data) {
      $.mobile.loading('hide');
      //data.status, data.readyState, data.responseText
      console.log(data);
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Failed!'),
        headerClose: false,
        buttonPrompt: iTrans(
          'There was a problem saving.  Please try again. If this continues to happen please submit a bug report.'
        ),
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //Update File Now Text - W&B Problem
            },
          },
          [iTrans('Report')]: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    });
}
var REPORT_HTML_PDF;
function GenerateReportPDF() {
  //validate email list
  if (!ONLINE) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: iTrans('OFFLINE'),
      headerClose: false,
      buttonPrompt:
        '<b>' +
        iTrans('PDF Reports cannot be generated offline') +
        '.  ' +
        iTrans('If this is a flight report it will be emailed upon your next sync') +
        '.</b>',
      buttons: {
        Close: {
          click: function () {
            $('#FlightReportPDF_BTN').prop('disabled', false);
            $('#InvoicePDF_BTN').prop('disabled', false);
          },
        },
      },
    });
    return;
  }
  if (GENERATE_PDF.email != 0) {
    var EmailList = GENERATE_PDF.email.split(';');
    console.log(EmailList);
    if (EmailList[0] == '' && GENERATE_PDF.ClientEmail == '0') {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: iTrans(
          'No Valid email address to send to. Plese check the email list inputted / Client Email and try again.'
        ),
        buttons: {
          [iTrans('OK')]: {
            click: function () {
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
            },
          },
        },
      });
      return;
    }
    if (EmailList[0] != '') {
      for (var i in EmailList) {
        console.log(EmailList[i]);
        if (!validateEmail(EmailList[i]) && EmailList[i].trim() != '') {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Warning'),
            headerClose: false,
            buttonPrompt:
              iTrans('The email address') +
              ': [' +
              EmailList[i] +
              '] ' +
              iTrans('is invalid') +
              '. ' +
              iTrans('Plese check the email list inputted and try again') +
              '.',
            buttons: {
              [iTrans('OK')]: {
                click: function () {
                  $('#FlightReportPDF_BTN').prop('disabled', false);
                  $('#InvoicePDF_BTN').prop('disabled', false);
                },
              },
            },
          });
          return;
        }
      }
    }
  }
  var data = new FormData();
  data.append('html', encodeURI(REPORT_HTML_PDF));
  data.append('email', GENERATE_PDF.email);
  data.append('ClientEmail', GENERATE_PDF.ClientEmail);
  data.append('showPDF', GENERATE_PDF.showPDF);
  data.append('filename', GENERATE_PDF.filename);
  data.append('subject', GENERATE_PDF.subject);

  $.mobile.loading('show', {
    theme: 'a',
  });
  if (IOS || ANDROID) {
    var xhr = new XMLHttpRequest(),
      blob;
    xhr.timeout = 600000;
    xhr.open('POST', BASEURL + 'ToPDF.php?ts=0', true);
    // Set the responseType to blob
    xhr.responseType = 'blob';

    xhr.addEventListener(
      'load',
      function () {
        if (xhr.status === 200) {
          console.log('PDF retrieved');
          //var url = window.URL.createObjectURL(xhr.response);
          //window.open(url,'_blank');
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt: '<p>' + iTrans('PDF Successfully generated and sent') + '</p>',
            buttons: {
              [iTrans('Close')]: {
                click: function () {
                  //acknolwedge
                  $('#FlightReportPDF_BTN').prop('disabled', false);
                  $('#InvoicePDF_BTN').prop('disabled', false);
                },
              },
            },
          });
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Error'),
            headerClose: false,
            buttonPrompt: '<p>' + iTrans('PDF generated Failed') + '!!</p>',
            buttons: {
              [iTrans('Close')]: {
                click: function () {
                  //acknolwedge
                  $('#FlightReportPDF_BTN').prop('disabled', false);
                  $('#InvoicePDF_BTN').prop('disabled', false);
                },
              },
            },
          });
        }
        $.mobile.loading('hide');
      },
      false
    );

    xhr.addEventListener('error', function () {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: '<p>' + iTrans('PDF generated Failed') + '!!</p>',
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //acknolwedge
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
            },
          },
        },
      });
      $.mobile.loading('hide');
    });
    // Send XHR
    xhr.send(data);
  } else {
    var xhr = new XMLHttpRequest();
    xhr.timeout = 600000;
    xhr.open('POST', BASEURL + 'ToPDF.php', true);
    // Set the responseType to blob
    xhr.responseType = 'blob';

    xhr.addEventListener(
      'load',
      function () {
        if (xhr.status === 200) {
          console.log('PDF retrieved');
          var url = window.URL.createObjectURL(xhr.response);
          VueBridge.showPdfViewerDialog(url, GENERATE_PDF.filename);

          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Success'),
            headerClose: false,
            buttonPrompt:
              '<p>' +
              iTrans('PDF Successfully generated and sent') +
              '</p><p>' +
              iTrans(
                'If a new window displaying the generated Flight Report / Invoice did not appear you may need to disable your pop-up blocker'
              ) +
              '.</p>',
            buttons: {
              [iTrans('Close')]: {
                click: function () {
                  //acknolwedge
                  $('#FlightReportPDF_BTN').prop('disabled', false);
                  $('#InvoicePDF_BTN').prop('disabled', false);
                },
              },
            },
          });
        } else {
          $('<div>').simpledialog2({
            mode: 'button',
            animate: false,
            headerText: iTrans('Error'),
            headerClose: false,
            buttonPrompt:
              '<p>' +
              iTrans(
                'Sorry, the PDF generation failed. This is usually due to connectivity issues. Please try again.'
              ) +
              '.</p>',
            buttons: {
              [iTrans('Close')]: {
                click: function () {
                  //acknolwedge
                  $('#FlightReportPDF_BTN').prop('disabled', false);
                  $('#InvoicePDF_BTN').prop('disabled', false);
                },
              },
            },
          });
        }
        $('#FlightReportPDF_BTN').prop('disabled', false);
        $('#InvoicePDF_BTN').prop('disabled', false);
        $.mobile.loading('hide');
      },
      false
    );

    xhr.addEventListener('error', function () {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Error'),
        headerClose: false,
        buttonPrompt: '<p>' + iTrans('PDF generated Failed') + '!!</p>',
        buttons: {
          [iTrans('Close')]: {
            click: function () {
              //acknolwedge
              $('#FlightReportPDF_BTN').prop('disabled', false);
              $('#InvoicePDF_BTN').prop('disabled', false);
            },
          },
        },
      });
      $.mobile.loading('hide');
    });
    // Send XHR
    xhr.send(data);
  }
}
function ChangeInvoiceTemplate(e) {
  var temp = e.value;
  var Templates = {
    0: {
      en: 'FlightReportTemplates-0-EN.png',
      fr: 'FlightReportTemplates-0-FR.png',
    },
    1: {
      en: 'FlightReportTemplates-1-EN.png',
      fr: 'FlightReportTemplates-1-FR.png',
    },
    2: {
      en: 'FlightReportTemplates-2-EN.png',
      fr: 'FlightReportTemplates-2-FR.png',
    },
  };
  if (temp == 3) {
    $('#BIoptionsHLDRReportTemplateHLDR').html('');
  } else {
    UpdateInvoiceTemplateIMG(Templates[e.value][i18n.locale]);
  }
}
function UpdateInvoiceTemplateIMG(img) {
  $('#BIoptionsHLDRReportTemplateHLDR').html('<img width="300" src="./images/' + img + '" />');
}
