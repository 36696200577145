class cSAMA_TemplateManager {
  constructor() {
    this.tplWrapper = $('.sama-templates-container');

    this.tpl = {
      general: {
        header: this.tplWrapper.find('div[data-sama-template="header"]').html(),
        headerButton: this.tplWrapper.find('div[data-sama-template="headerButton"]').html(),
        timeLineListItem: $('div[data-sama-template="timeLineListItem"]').remove().html(),
        cards: {
          body: this.tplWrapper.find('div[data-sama-template="displayCardBody"]').html(),
          row: this.tplWrapper.find('div[data-sama-template="displayCardRow"]').html(),
          wideRow: this.tplWrapper.find('div[data-sama-template="displayCardRowWide"]').html(),
          button: this.tplWrapper.find('div[data-sama-template="displayCardButton"]').html(),
        },
      },

      dash: {
        filterForm: $('div[data-sama-template="dashboardFilter"]').remove().html(),
        listItem: $('div[data-sama-template="dashboardNewsItem"]').remove().html(),
      },

      reports: {
        filterForm: $('div[data-sama-template="reportCardFilters"]').remove().html(),
        listItem: $('div[data-sama-template="reportListItem"]').remove().html(),
      },

      riskFactor: {
        valueEdit: this.tplWrapper.find('div[data-sama-template="riskFactorValueEdit"]').html(),
        editorTab: this.tplWrapper.find('div[data-sama-template="riskFactorEditTab"]').html(),
        editorRider: this.tplWrapper.find('div[data-sama-template="riskFactorEditRider"]').html(),
      },

      customRepField: {
        text: this.tplWrapper.find('div[data-sama-template="riskFactorCustomReporting-plainText"]').html(),
        keyword: this.tplWrapper.find('div[data-sama-template="riskFactorCustomReporting-keywords"]').html(),
        editorTab: this.tplWrapper.find('div[data-sama-template="riskFactorCustomReportingField"]').html(),
      },

      riskMatrix: {
        view: this.tplWrapper.find('div[data-sama-template="riskMatrix"]').html(),
      },

      modal: {
        wrapper: $('div[data-sama-template="modalWrapper"]').remove().html(),
      },

      departments: {
        editorTab: $('div[data-sama-template="departmentEditorTab"]').remove().html(),
      },

      consequences: {
        editorTab: this.tplWrapper.find('div[data-sama-template="consequenceEditorTab"]').html(),
        editorRider: this.tplWrapper.find('div[data-sama-template="consequenceEditorRider"]').html(),
        freqDropdown: this.tplWrapper.find('div[data-sama-template="frequencySelect"]').html(),
        modalBar: $('div[data-sama-template="consequenceModalBar"]').remove().html(),
      },

      correctiveAction: {
        editorView: $('div[data-sama-template="editorCorrectiveActions"]').remove().html(),
        modalBar: $('div[data-sama-template="correctiveActionModalBar"]').remove().html(),
        filterForm: $('div[data-sama-template="correctiveActionsFilter"]').remove().html(),
        dayPilot: this.tplWrapper.find('div[data-sama-template="dayPilotCoa"]').html(),
        listItem: $('div[data-sama-template="correctiveActionListItem"]').remove().html(),
      },

      keywords: {
        categoryEdit: this.tplWrapper.find('div[data-sama-template="prefs-keyword-category"]').html(),
      },

      objectives: {
        filterForm: this.tplWrapper.find('div[data-sama-template="objectiveFilter"]').html(),
        editorTab: this.tplWrapper.find('div[data-sama-template="objectiveGoalEditorTab"]').html(),
        taskEdit: this.tplWrapper.find('div[data-sama-template="policyObjectiveTaskEdit"]').html(),
        view: this.tplWrapper.find('div[data-sama-template="objectiveGoalCard"]').html(),
        dayPilot: this.tplWrapper.find('div[data-sama-template="dayPilotPog"]').html(),
      },

      trends: {
        ruleTabEdit: this.tplWrapper.find('div[data-sama-template="trendRuleEditorTab"]').html(),
      },

      tabEditor: {
        frame: $('div[data-sama-template="tabEditorFrame"]').remove().html(),
      },

      reportCategory: {
        edit: this.tplWrapper.find('div[data-sama-template="prefs-report-category"]').html(),
        frame: $('div[data-sama-template="reportCategory"]').remove().html(),
      },
    };

    this.tplWrapper.remove();
  }
}
