/**
 * Class dealing with the report category
 * @param {object}           [_databaseData]
 * @class
 */

class mSAMA_ReportCategory extends mSAMA_AbstractModel {
  /**
   *
   * @param {object}  _databaseData
   * @param {int}     [_num] Optional, to create a new name with a number attached.
   */
  constructor(_databaseData, _num) {
    super('rc_primaryKey');

    this.sync = {
      rc_primaryKey: null,
      rc_name: null,

      modified: false,
      deleted: false,
    };

    $.extend(true, this.sync, _databaseData);

    this.validateSettings = {
      rc_name: new cSAMA_Validator({
        type: SAMA.enums.VALIDATORS.STRING,
        minLength: 3,
        maxLength: SAMA.enums.TEXTLENGTHS.Tiny,
        mustNotContain: 'WORDONLY',
      }),
    };

    if (this.IsNew()) {
      // Is new Category
      this.SetPk(cSAMA_Utils.GetUID());
      this.sync.rc_name = 'Category ' + _num;
      this.SetModified(true);
    }
  }
}
