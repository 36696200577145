/* ################################ Copyright © 2018 AirSuite Inc All Rights Reserved   ###################### */
/**
 * @Deprecated 2024-06-26 This function was used in legacy fdt, we're a revamp fdt crew now.
 */
(function ($) {
  $.jqmCalendar = function (element, options) {
    var CurrentRecTimeZone = '';
    var defaults = {
      // Array of events
      events: [],
      // Default properties for events
      AClist: 'AClist',
      begin: 'begin',
      finish: 'finish',
      Itin: 'Itin',
      GoTo: 'GoTo',
      IDENT: 'IDENT',
      Comment: 'Comment',
      Currency: 'Currency',
      EL: 'EL',
      Extend: 'Extend',
      ExtendON: 'ExtendON',
      ExtendAvail: 'ExtendAvail',
      ExtendReason: 'ExtendReason',
      CustomRule: 'CustomRule',
      CustomWarning: 'CustomWarning',
      CustomViolation: 'CustomViolation',
      Rule: 'Rule',
      ResetFT: 'ResetFT',
      Rule_ID: 'Rule_ID',
      Need5Off: 'Need5Off',
      ExtendNum: 'ExtendNum',
      Split: 'Split',
      Split2: 'Split2',
      Overnight: 'Overnight',
      Sectors: 'Sectors',
      CrewCount: 'CrewCount',
      restType: 'restType',
      Timezone: 'Timezone',
      begLat: 'begLat',
      begLong: 'begLong',
      endLat: 'endLat',
      endLong: 'endLong',
      SickDay: 'SickDay',
      Standby: 'Standby',
      IFR: 'IFR',
      Heli: 'Heli',
      DHC: 'DHC',
      Dual: 'Dual',
      Logging: 'Logging',
      AerialApp: 'AerialApp',
      id: 'id',
      Modified: 'Modified',
      Start: 'Start',
      End: 'End',
      Start2: 'Start2',
      End2: 'End2',
      FT: 'FT',
      FT2: 'FT2',
      DFR: 'DFR',
      RT: 'RT',
      DT: 'DT',
      DT7: 'DT7',
      DT28: 'DT28',
      DT365: 'DT365',
      ODT: 'ODT',
      L3: 'L3',
      L7: 'L7',
      L28: 'L28',
      L30: 'L30',
      L42: 'L42',
      L90: 'L90',
      L180: 'L180',
      L365: 'L365',
      Off30: 'Off30',
      Off90: 'Off90',
      prevSplit: 'prevSplit',
      MaxNextFltTimes: 'MaxNextFltTimes',
      Noff: 'Noff',
      Noff_Limit: 'Noff_Limit',
      lastQ: 'lastQ',
      last2Q: 'last2Q',
      CalYear: 'CalYear',
      Status: 'Status',
      Warning: 'Warning',
      Warning_Ack: 'Warning_Ack',
      Violation: 'Violation',
      Violation_Ack: 'Violation_Ack',
      EXTRA: 'EXTRA',
      JobNums: 'JobNums',
      idents: 'idents',
      AdminComment: 'AdminComment',
      Overtime: 'Overtime',
      Unpaid: 'Unpaid',
      FieldPay: 'FieldPay',
      Approved: 'Approved',
      Cfields: 'Cfields',
      LOC: 'LOC',
      TotalLast24: 'TotalLast24',
      dayFT: 'dayFT',
      icon: 'icon',
      url: 'url',
      UOC: 'UOC',
      FCP: 'FCP',
      RES_Notice: 'RES_Notice',
      RES_Notice2: 'RES_Notice2',
      CROFD: 'CROFD',
      LRF: 'LRF',
      maxDuty: 'maxDuty',
      maxFT: 'maxFT',
      ET2: 'ET2',
      AccTZ: 'AccTZ',
      AccHours: 'AccHours',
      AccDSTcorrected: 'AccDSTcorrected',
      Recs: 'Recs',

      // Sting to use when event is all day
      allDayTimeString: '',
      // Theme
      theme: 'c',
      // Date variable to determine which month to show and which date to select
      date: new Date(),
      db: 'db',
      // Version
      version: '1.0.1',
      // Array of month strings (calendar header)
      months: [
        iTrans('January'),
        iTrans('February'),
        iTrans('March'),
        iTrans('April'),
        iTrans('May'),
        iTrans('June'),
        iTrans('July'),
        iTrans('August'),
        iTrans('September'),
        iTrans('October'),
        iTrans('November'),
        iTrans('December'),
      ],
      // Array of day strings (calendar header)
      days: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // Most months contain 5 weeks, some 6. Set this to six if you don't want the amount of rows to change when switching months.
      weeksInMonth: undefined,
      // Start the week at the day of your preference, 0 for sunday, 1 for monday, and so on.
      startOfWeek: 0,
      // List Item formatter / displayRecord, allows a callback to be passed to alter the contect of the list item
      displayRecord: displayRecord,
    };
    var db; //global database object
    var duty_Data = [];
    var plugin = this,
      today = new Date();
    plugin.settings = null;
    var $element = $(element).addClass('jq-calendar-wrapper'),
      element = element,
      $table,
      $header,
      $tbody,
      $listview,
      $previous,
      $next;
    var AClist;

    function applyOptions() {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.applyOptions', '2024-06-26', 'bldarosa');
      console.log(EDITON);
      if (EDITON == false) {
      } else {
        $('#DutyCalendar').show();
        $('#Summary_HDLR').show();
        $('#Edit_HDLR').hide();
        refresh(refreshDATE);
        done = false;
      }
    }

    function init() {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.init', '2024-06-26', 'bldarosa');
      $('#exitDutyDefaults').unbind('click', applyOptions);
      $('#exitDutyDefaults').click(applyOptions);
      $('#CHK_DHC').unbind('click', changeDefault);
      $('#CHK_DHC').click(changeDefault);
      $('#CHK_Dual').unbind('click', changeDefault);
      $('#CHK_Dual').click(changeDefault);
      $('#CHK_Heli').unbind('click', changeDefault);
      $('#CHK_Heli').click(changeDefault);
      $('#CHK_IFR').unbind('click', changeDefault);
      $('#CHK_IFR').click(changeDefault);
      $('#CHK_Logging').unbind('click', changeDefault);
      $('#CHK_Logging').click(changeDefault);
      $('#CHK_AerialApp').unbind('click', changeDefault);
      $('#CHK_AerialApp').click(changeDefault);
      $('#SELECT_Rule').unbind('change', changeDefault);
      $('#SELECT_Rule').change(changeDefault);
      $('#SELECT_Rule_ID').unbind('change', changeDefault);
      $('#SELECT_Rule_ID').change(changeDefault);

      plugin.settings = $.extend({}, defaults, options);
      plugin.settings.theme = $.mobile.getInheritedTheme($element, plugin.settings.theme);
      db = plugin.settings.db;
      AClist = plugin.settings.AClist;
      $table = $("<table id='DutyCalendar'/>");

      // Build the header
      var $thead = $('<thead/>').appendTo($table),
        $tr = $('<tr/>').appendTo($thead),
        $th = $("<th class='ui-bar-" + plugin.settings.theme + " header' colspan='7'/>");

      $previous = $(
        "<a href='#' id='PrevMonth' data-role='button' data-icon='arrow-l' data-iconpos='notext' class='previous-btn'>" +
          iTrans('Previous') +
          '</a>'
      )
        .click(function (event) {
          $('#PrevMonth').addClass('ui-disabled');
          $('#NextMonth').addClass('ui-disabled');
          refresh(
            new Date(
              plugin.settings.date.getFullYear(),
              plugin.settings.date.getMonth() - 1,
              plugin.settings.date.getDate()
            )
          );
        })
        .appendTo($th);

      $header = $('<span/>').appendTo($th);

      $next = $(
        "<a href='#' id='NextMonth' data-role='button' data-icon='arrow-r' data-iconpos='notext' class='next-btn'>" +
          iTrans('Next') +
          '</a>'
      )
        .click(function (event) {
          $('#PrevMonth').addClass('ui-disabled');
          $('#NextMonth').addClass('ui-disabled');
          refresh(
            new Date(
              plugin.settings.date.getFullYear(),
              plugin.settings.date.getMonth() + 1,
              plugin.settings.date.getDate()
            )
          );
        })
        .appendTo($th);

      $th.appendTo($tr);

      $tr = $('<tr/>').appendTo($thead);

      // The way of determing the labels for the days is a bit awkward, but works.
      for (
        var i = 0, days = [].concat(plugin.settings.days, plugin.settings.days).splice(plugin.settings.startOfWeek, 7);
        i < 7;
        i++
      ) {
        $tr.append("<th class='ui-bar-" + plugin.settings.theme + "'><span class='darker'>" + days[i] + '</span></th>');
      }

      $tbody = $('<tbody/>').appendTo($table);

      $table.appendTo($element);
      $listview = $('<div></div>').insertAfter($table);

      // Call refresh to fill the calendar with dates
      if (!FDT_REPORT) {
        refresh(plugin.settings.date);
        //console.log(plugin.settings.date);
      } else {
        //console.log("Auto Populate from Flight Report");
        //console.log(FDT_REPORT);
        var FRdate = new Date(FDT_REPORT.Date + 'T12:00:00Z');
        refresh(FRdate);
      }
    }

    function _firstDayOfMonth(date) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.firstDayOfMonth', '2024-06-26', 'bldarosa');
      // [0-6] Sunday is 0, Monday is 1, and so on.
      return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    }

    function _daysBefore(date, fim) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.daysBefore', '2024-06-26', 'bldarosa');
      // Returns [0-6], 0 when firstDayOfMonth is equal to startOfWeek, else the amount of days of the previous month included in the week.
      var firstDayInMonth = fim || _firstDayOfMonth(date),
        diff = firstDayInMonth - plugin.settings.startOfWeek;
      return diff > 0 ? diff : 7 + diff;
    }

    function _daysInMonth(date) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.daysInMonth', '2024-06-26', 'bldarosa');
      // [1-31]
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    function _daysAfter(date, wim, dim, db) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.daysAfter', '2024-06-26', 'bldarosa');
      // Returns [0-6] amount of days from the next month
      return (wim || _weeksInMonth(date)) * 7 - (dim || _daysInMonth(date)) - (db || _daysBefore(date));
    }

    function _weeksInMonth(date, dim, db) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.weeksInMonth', '2024-06-26', 'bldarosa');
      // Returns [5-6];
      return plugin.settings.weeksInMonth
        ? plugin.settings.weeksInMonth
        : Math.ceil(((dim || _daysInMonth(date)) + (db || _daysBefore(date))) / 7);
    }

    function addCell($row, date, darker, selected) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.addCell', '2024-06-26', 'bldarosa');
      var dateText = date.getDate().toString();
      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
      ) {
        dateText = '[-' + dateText + '-]';
      }
      var $td = $("<td class='ui-body-" + plugin.settings.theme + "'/>").appendTo($row),
        $a = $("<a href='#' class='ui-btn ui-btn-up-" + plugin.settings.theme + "'/>")
          .html(dateText)
          .data('date', date)
          .click(cellClickHandler)
          .appendTo($td);

      if (selected) {
        $a.click();
      }

      if (darker) {
        $td.addClass('darker');
      }

      var importance = 0;

      // Find events for this date
      for (
        var i = 0,
          event,
          begin = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0),
          finish = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0);
        (event = plugin.settings.events[i]);
        i++
      ) {
        ////console.log("Event:"+event[plugin.settings.FT])

        if (event[plugin.settings.finish] >= begin && event[plugin.settings.begin] < finish) {
          if (event[plugin.settings.DT] == 0) {
            importance = 0;
          }
          if (event[plugin.settings.DT] > 0 && event[plugin.settings.FT] == 0) {
            importance = 1;
          }
          //Rule = event[plugin.settings.Rule];

          if (
            event[plugin.settings.Rule] == 702 ||
            event[plugin.settings.Rule] == 703 ||
            event[plugin.settings.Rule] == 704 ||
            event[plugin.settings.Rule] == 705
          ) {
            var StandbyOPT = event[plugin.settings.Standby];
            if (StandbyOPT == 2 || StandbyOPT == 5 || StandbyOPT == 6 || StandbyOPT == 7 || StandbyOPT == 8) {
              importance = 5;
            }
          }
          if (event[plugin.settings.Rule] == 4) {
            if (event[plugin.settings.EL] == 1) {
              $a.append('<span>😴</span>');
            }
          }
          if (event[plugin.settings.FT] > 0 || (event[plugin.settings.FT2] > 0 && event[plugin.settings.Split] == 1)) {
            importance = 2;
          }
          if (event[plugin.settings.Warning] == true && event[plugin.settings.DT] != 0) {
            importance = 4;
          }
          if (event[plugin.settings.Violation] == true && event[plugin.settings.DT] != 0) {
            importance = 3;
            $a.append('<span>*V*</span>');
          }

          // if (event[plugin.settings.Violation] == true) $a.append("<span>•</span>");
          if (
            event[plugin.settings.Rule] != 135 &&
            event[plugin.settings.Rule] != 131 &&
            event[plugin.settings.Rule] != 133
          ) {
            if (event[plugin.settings.Extend] == true) {
              $a.append('<span>[e]</span>');
            } else if (event[plugin.settings.ExtendON] == true) {
              $a.append('<span>*</span>');
            }
          }
          if (USERTYPE != 'Pilot') {
            if (event[plugin.settings.FieldPay] == 1) {
              importance = 1;
            }
            if (event[plugin.settings.Approved] == 1) {
              importance = 5;
            }
            if (event[plugin.settings.AdminComment] != '') {
              $a.append('<span>*</span>');
            }
          }
        }
      }
      $a.addClass('importance-' + importance.toString());
    }

    function cellClickHandler(event) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.cellClickHandler', '2024-06-26', 'bldarosa');
      var $this = $(this),
        date = $this.data('date');
      $tbody.find('a.ui-btn-active').removeClass('ui-btn-active');
      $this.addClass('ui-btn-active');
      ////console.log("Date Select:"+date);
      if (date.getMonth() !== plugin.settings.date.getMonth()) {
        // Go to previous/next month
        refresh(date);
      } else {
        // Select new Date
        //$element.trigger('change', date);
        showDate(date);
      }
    }

    function refresh(date) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.refresh', '2024-06-26', 'bldarosa');
      console.log('Refreshing Calendar');
      refreshDATE = new Date(date);
      if (!REC_EDITED) {
        $tbody.empty();
        $listview.empty();
      }
      //$listItem.empty();
      ////console.log("refresh");
      ////console.log(db);
      duty_Data = [];
      plugin.settings.date = date = date || plugin.settings.date || new Date();
      ////console.log("todayDate:"+date);
      var year = date.getFullYear(),
        month = date.getMonth(),
        daysBefore = _daysBefore(date),
        daysInMonth = _daysInMonth(date),
        weeksInMonth = plugin.settings.weeksInMonth || _weeksInMonth(date, daysInMonth, daysBefore);
      if ((daysInMonth + daysBefore) / 7 - weeksInMonth === 0) {
        weeksInMonth++;
      }
      //Get date range for database query
      var archive_Date = new Date(year, month, 1 - daysBefore - 365);
      var first_Date = new Date(year, month, 1 - daysBefore);
      var last_Date = new Date(year, month, -daysBefore + 7 * weeksInMonth);
      ////console.log("Archive Record: "+ archive_Date);
      ////console.log("First Record: "+first_Date);
      ////console.log("Last Record: "+last_Date);
      plugin.settings.events = [];

      var SAMEDATE = new Date();

      cirroDB.query(
        'FDT_RecsV3',
        'DELETED = 0 AND Date >= ? AND Date <= ? ORDER BY Date ASC',
        [archive_Date.toISOString().substr(0, 10), last_Date.toISOString().substr(0, 10)],
        function (r) {
          var Recs = [];
          if (r !== false) {
            Recs = r;
          }

          if (userShouldHaveFDT(USERTYPE)) {
            table = 'FDT_DaysV4';
          } else {
            table = 'FDT_StaffV3';
          }

          cirroDB.query(
            table,
            'Date >= ? AND Date <= ? ORDER BY Date ASC',
            [archive_Date.toISOString().substr(0, 10), last_Date.toISOString().substr(0, 10)],
            function (e) {
              console.log(e);
              var dates = e;
              if (e === false) {
                dates = [];
              }

              for (var i in dates) {
                var data = dates[i];
                data.Recs = [];
                if (userShouldHaveFDT(USERTYPE)) {
                  for (var r in Recs) {
                    if (data.Date == Recs[r].Date) {
                      data.Recs.push(Recs[r]);
                    }
                  }
                }

                duty_Data.push(data);
                var recdate = new Date(data.Date + 'T12:00:00Z');
                //recdate.setMinutes(recdate.getMinutes() + recdate.getTimezoneOffset());
                var offset = new Date(recdate.getUTCFullYear(), recdate.getUTCMonth(), recdate.getUTCDate());
                if (offset.getTime() == SAMEDATE.getTime()) {
                  //console.log("DUPLICATE DATES FOUND");
                  rebuildFDTdatabase();
                  return;
                } else {
                  SAMEDATE = new Date(offset);
                }
                if (first_Date.getTime() <= offset.getTime()) {
                  var Details;

                  if (userShouldHaveFDT(USERTYPE)) {
                    if (data.Date == '2021-09-09') {
                      //console.log('Inspect Here');
                      //console.log(data);
                      //console.log(duty_Data);
                    }
                    Details = day_Calc(duty_Data);

                    Details.CustomRule = null;
                    Details.CustomWarning = {
                      Status: '',
                      date: '',
                    };
                    Details.CustomViolation = {
                      Status: '',
                      date: '',
                    };
                    if (data.Rule_ID == null) {
                      data.Rule_ID = 0;
                    }
                    if (data.Rule_ID != 0) {
                      var method = 'FDTRULE';
                      if (parseFloat(data.FT) + parseFloat(data.FT2) > 0 || Details.DT > 0) {
                        if (window[method + data.Rule_ID] != undefined) {
                          Details = window[method + data.Rule_ID](Details, duty_Data);
                          ////console.log(Details.CustomRule);
                        }
                      }
                    }
                    if (GROUP_DATA.FDT_Crule_Alerts == 1) {
                      if (Details.CustomViolation.Status != '') {
                        Details.Violation = true;
                        Details.Status += '<p>' + Details.CustomViolation.Status + '</p>';
                      }
                      if (Details.CustomWarning.Status != '') {
                        Details.Warning = true;
                        Details.Status += '<p>' + Details.CustomWarning.Status + '</p>';
                      }
                      if (Details.CustomWarning.Status != '' || Details.CustomViolation.Status != '') {
                        markRec(data.Date, Details.Status, Details.Warning, Details.Violation);
                      }
                    }

                    var Overnight = 0;

                    plugin.settings.events.push({
                      PrimaryKey: data.PrimaryKey,
                      Modified: data.Modified,
                      Overnight: data.Overnight,
                      Sectors: data.Sectors,
                      CrewCount: data.CrewCount,
                      restType: data.restType,
                      Timezone: data.Timezone,
                      begLat: data.begLat,
                      begLong: data.begLong,
                      endLat: data.endLat,
                      endLong: data.endLong,
                      Split2: Details.Split2,
                      EL: data.EL,
                      Extend: data.Extend,
                      Split: Details.Split,
                      Standby: data.Standby,
                      IFR: data.IFR,
                      Heli: data.Heli,
                      DHC: data.DHC,
                      Dual: data.Dual,
                      Logging: data.Logging,
                      AerialApp: data.AerialApp,
                      Start: data.Start,
                      End: data.End,
                      Start2: data.Start2,
                      End2: data.End2,
                      FT: parseFloat(data.FT),
                      FT2: parseFloat(data.FT2),
                      Comment: data.Comment,
                      begin: offset,
                      finish: offset,
                      prevSplit: Details.prevSplit,
                      RT: Details.RT,
                      DT: Details.DT,
                      DT7: Details.DT7,
                      DT28: Details.DT28,
                      DT365: Details.DT365,
                      ODT: Details.ODT,
                      L3: Details.L3,
                      L7: Details.L7,
                      L28: Details.L28,
                      L30: Details.L30,
                      L42: Details.L42,
                      L90: Details.L90,
                      L180: Details.L180,
                      L365: Details.L365,
                      Off30: Details.Off30,
                      Off90: Details.Off90,
                      Noff: Details.Noff,
                      Noff_Limit: Details.Noff_Limit,
                      lastQ: Details.lastQ,
                      last2Q: Details.last2Q,
                      CalYear: Details.CalYear,
                      Status: Details.Status,
                      Warning: Details.Warning,
                      Warning_Ack: data.Warning_Ack,
                      Violation: Details.Violation,
                      Violation_Ack: data.Violation_Ack,
                      ExtendAvail: Details.ExtendAvail,
                      Rule: data.Rule,
                      Rule_ID: data.Rule_ID,
                      Need5Off: false,
                      ExtendNum: Details.ExtendNum,
                      ExtendReason: Details.ExtendReason,
                      ExtendON: Details.ExtendON,
                      EXTRA: data.EXTRA,
                      CustomRule: Details.CustomRule,
                      CustomWarning: Details.CustomWarning,
                      CustomViolation: Details.CustomViolation,
                      Currency: Details.Currency,
                      ResetFT: Details.ResetFT,
                      TotalLast24: Details.TotalLast24,
                      dayFT: Details.dayFT,
                      MaxNextFltTimes: Details.MaxNextFltTimes,
                      maxFT: Details.maxFT,
                      maxDuty: Details.maxDuty,
                      AccTZ: Details.AccTZ,
                      AccHours: Details.AccHours,
                      AccDSTcorrected: Details.AccDSTcorrected,
                      UOC: Details.UOC,
                      FCP: Details.FCP,
                      RES_Notice: Details.RES_Notice,
                      RES_Notice2: Details.RES_Notice2,
                      CROFD: Details.CROFD,
                      LRF: Details.LRF,
                      ET2: Details.ET2,
                    });
                  } else {
                    var DutyTime = dutytime_Calc(data.Start, data.End);
                    var dayFT = parseFloat(data.FT);
                    if (data.JobNums == undefined) {
                      data.JobNums = '';
                    }
                    if (data.Split == 1 || data.Split == 3) {
                      var DutyTime2 = dutytime_Calc(data.Start2, data.End2);
                      DutyTime += DutyTime2;
                      DutyTime = round10(DutyTime);
                      dayFT += parseFloat(data.FT2);
                    }
                    dayFT = round10(dayFT);

                    plugin.settings.events.push({
                      PrimaryKey: data.PrimaryKey,
                      Modified: data.Modified,
                      IDENT: data.IDENT,
                      Start: data.Start,
                      End: data.End,
                      Start2: data.Start2,
                      End2: data.End2,
                      FT: parseFloat(data.FT),
                      FT2: parseFloat(data.FT2),
                      Split: data.Split,
                      DFR: data.DFR,
                      DT: DutyTime,
                      dayFT: dayFT,
                      Comment: data.Comment,
                      JobNums: data.JobNums,
                      idents: data.idents,
                      AdminComment: data.AdminComment,
                      Overtime: data.Overtime,
                      Unpaid: data.Unpaid,
                      FieldPay: data.FieldPay,
                      SickDay: data.SickDay,
                      Approved: data.Approved,
                      Cfields: data.Cfields,
                      LOC: data.LOC,
                      begin: offset,
                      finish: offset,
                    });
                  }
                }
              }
              //All records processed

              $('#PrevMonth').removeClass('ui-disabled');
              $('#NextMonth').removeClass('ui-disabled');
              ////console.log(e);
              ////console.log("New Month Loaded from DB");
              ////console.log("Calendar Array Length: "+plugin.settings.events.length);
              ////console.log(plugin.settings.events[plugin.settings.events.length-1]);
              // Empty the table body, we start all over...
              // Change the header to match the current month
              $header.html(plugin.settings.months[month] + ' ' + year.toString());
              //find records for this month, query database and update events array
              for (var weekIndex = 0, daysInMonthCount = 1, daysAfterCount = 1; weekIndex < weeksInMonth; weekIndex++) {
                var daysInWeekCount = 0,
                  row = $('<tr/>').appendTo($tbody);

                // Previous month
                while (daysBefore > 0) {
                  addCell(row, new Date(year, month, 1 - daysBefore), true);
                  daysBefore--;
                  daysInWeekCount++;
                }

                // Current month
                while (daysInWeekCount < 7 && daysInMonthCount <= daysInMonth) {
                  addCell(row, new Date(year, month, daysInMonthCount), false, daysInMonthCount === date.getDate());
                  daysInWeekCount++;
                  daysInMonthCount++;
                }

                // Next month
                while (daysInMonthCount > daysInMonth && daysInWeekCount < 7) {
                  addCell(row, new Date(year, month, daysInMonth + daysAfterCount), true);
                  daysInWeekCount++;
                  daysAfterCount++;
                }
              }

              $element.trigger('create');

              ////console.log("Dataset:");
              ////console.log(duty_Data);

              //finished load new calendar
            }
          );
        }
      );
    } //End refresh calendar

    //registerFunction("fooBar(x, y) { return x + y; }");
    //fooBar(1, 2); // will give you 3

    //$element.bind('change', function(event, begin) {
    function showDate(begin) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.showDate', '2024-06-26', 'bldarosa');
      refreshDATE = new Date(begin);
      var end = new Date(begin.getFullYear(), begin.getMonth(), begin.getDate() + 1, 0, 0, 0, 0);
      // Empty the list
      if (!REC_EDITED) {
        $listview.empty();
      }

      // Find events for this date
      for (var i = 0, event; (event = plugin.settings.events[i]); i++) {
        if (event[plugin.settings.finish] >= begin && event[plugin.settings.begin] < end) {
          // Append matches to list
          var Comment = event[plugin.settings.Comment],
            beginTime = (event[plugin.settings.begin] > begin ? event[plugin.settings.begin] : begin)
              .toTimeString()
              .substr(0, 5),
            endTime = (event[plugin.settings.finish] < end ? event[plugin.settings.finish] : end)
              .toTimeString()
              .substr(0, 5),
            timeString = beginTime + '-' + endTime,
            $listItem = $('<div></div>').appendTo($listview);

          plugin.settings.displayRecord($listItem, event);
        }
      }

      //$listview.trigger('create').filter(".ui-listview").listview('refresh');
      $listview.enhanceWithin();
    }

    function displayRecord($listItem, event) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.displayRecord', '2024-06-26', 'bldarosa');
      var lastDay = false;
      var disableNEXT = '';
      if (
        refreshDATE.getFullYear() === today.getFullYear() &&
        refreshDATE.getMonth() === today.getMonth() &&
        refreshDATE.getDate() === today.getDate()
      ) {
        lastDay = true;
        disableNEXT = 'disabled';
      }
      var CustomRule = event[plugin.settings.CustomRule];
      if (CustomRule != null && CustomRule != '') {
        CustomRule = iTrans('Custom FDT Rule') + ': ' + CustomRule + '<br />';
      } else {
        CustomRule = '';
      }
      prevSplit = false;
      if (userShouldHaveFDT(USERTYPE)) {
        prevSplit = event[plugin.settings.prevSplit];

        Split = event[plugin.settings.Split];
        Split2 = event[plugin.settings.Split2];
        Extend = event[plugin.settings.Extend];
        ExtendAvail = event[plugin.settings.ExtendAvail];
        ResetFT = event[plugin.settings.ResetFT];

        ViolationON = event[plugin.settings.Violation];

        DHC = event[plugin.settings.DHC];
        Dual = event[plugin.settings.Dual];
        Heli = event[plugin.settings.Heli];
        IFR = event[plugin.settings.IFR];
        Logging = event[plugin.settings.Logging];
        AerialApp = event[plugin.settings.AerialApp];
        Standby = event[plugin.settings.Standby];
        record = Rule_ID = event[plugin.settings.Rule_ID];

        if (
          event[plugin.settings.dayFT] > event[plugin.settings.maxFT] ||
          (event[plugin.settings.DT] > event[plugin.settings.maxDuty] && event[plugin.settings.Overnight] == 0) ||
          (event[plugin.settings.ODT] > event[plugin.settings.maxDuty] && event[plugin.settings.Overnight] == 1)
        ) {
          $('#FDT_UOC_HLDR').show(); //Show UOC if silent save on duty time
        } else {
          try {
            if ($('#FDT_UOC').is(':checked')) {
              $('#FDT_UOC').prop('checked', false).checkboxradio('refresh');
            }
          } catch (e) {}
          $('#FDT_UOC_HLDR').hide(); //Hide UOC if silent save on duty time
        }
        var UOC =
          '<div id="FDT_UOC_HLDR" style="display: ' +
          (event[plugin.settings.dayFT] > event[plugin.settings.maxFT] ||
          (event[plugin.settings.DT] > event[plugin.settings.maxDuty] && event[plugin.settings.Overnight] == 0) ||
          (event[plugin.settings.ODT] > event[plugin.settings.maxDuty] && event[plugin.settings.Overnight] == 1)
            ? 'block'
            : 'none') +
          ';"><label for="FDT_UOC">' +
          iTrans('Unforseen Operation Circumstance') +
          '</label>';
        UOC +=
          '<input type="checkbox" data-mini="true" id="FDT_UOC" ' +
          (event[plugin.settings.UOC] == 1 ? 'checked' : '') +
          '/></div>';

        var ShowRes = false;
        var ShowRes2 = false;
        switch (Standby.toString()) {
          case '2':
          case '5':
            ShowRes = true;
            break;
          case '6':
          case '7':
            ShowRes2 = true;
            break;
          case '8':
            ShowRes = true;
            ShowRes2 = true;
            break;
          case '19':
            ShowRes2 = true;
            break;
          case '35':
            ShowRes = true;
            break;
        }
        try {
          if (ShowRes) {
            $('#FDT_RES_Notice_HLDR').show();
          } else {
            $('#FDT_RES_Notice').prop('checked', false).checkboxradio('refresh');
            $('#FDT_RES_Notice_HLDR').hide();
          }
        } catch (e) {}
        var RES_Notice =
          '<div id="FDT_RES_Notice_HLDR" style="display: ' +
          (ShowRes ? 'block' : 'none') +
          ';"><label for="FDT_RES_Notice">' +
          iTrans('Reserve NOA Given') +
          '</label>';
        RES_Notice +=
          '<input type="checkbox" id="FDT_RES_Notice" data-mini="true" ' +
          (event[plugin.settings.EC2] == 1 ? 'checked' : '') +
          '/></div>';

        try {
          if (ShowRes2) {
            $('#FDT_RES_Notice_HLDR2').show();
          } else {
            $('#FDT_RES_Notice2').prop('checked', false).checkboxradio('refresh');
            $('#FDT_RES_Notice_HLDR2').hide();
          }
        } catch (e) {}
        var RES_Notice2 =
          '<div id="FDT_RES_Notice_HLDR2" style="display: ' +
          (ShowRes2 ? 'block' : 'none') +
          ';"><label for="FDT_RES_Notice2">' +
          iTrans('Reserve NOA Given') +
          '</label>';
        RES_Notice2 +=
          '<input type="checkbox" id="FDT_RES_Notice2" data-mini="true" ' +
          (event[plugin.settings.EC3] == 1 ? 'checked' : '') +
          '/></div>';

        if (event[plugin.settings.Violation] == true) {
          $('<div>').simpledialog2({
            themeDialog: 'a',
            mode: 'button',
            animate: false,
            headerText: iTrans('Violation'),
            headerClose: false,
            buttonPrompt:
              '<b>' +
              refreshDATE.toDateString() +
              '<br />' +
              iTrans('Duty Status') +
              ': </b>' +
              event[plugin.settings.Status],
            buttons: {
              [iTrans('Acknowledge')]: {
                click: function () {
                  //informative event do nothing
                },
              },
            },
          });
        }
        if (wasSaved == true) {
          wasSaved = false;
          REC_EDITED = false;
          $('#FDT_ErrorStatus').html(event[plugin.settings.Status]);
          $('#FDT_CalendarStatus').html(event[plugin.settings.Status]);
          //Update NVG Currency status on save too
          if (
            (USER_RIGHTS.FDT_NvgCur == 1 && !FDT_ADMIN_UPDATE) ||
            (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)
          ) {
            CheckNVGCurrency(new Date());
          }
          if (
            (USER_RIGHTS.FDT_IfrCur == 1 && !FDT_ADMIN_UPDATE) ||
            (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1)
          ) {
            CheckIFRCurrency(new Date());
          }
          return;
        }

        var NewFDTFields = '';
        if (GROUP_DATA.PULSAR_FRMS == 1 || event[plugin.settings.Rule] == 705) {
          NewFDTFields = '<br /><table>';
          var FCP = '<label for="FDT_FCP">' + iTrans('Crew Positioning (hrs)') + '</label>';
          FCP +=
            '<input type="number" min="0" max="99.9" id="FDT_FCP" data-mini="true" value="' +
            event[plugin.settings.FCP] +
            '"/>';

          var LRF = '<label for="FDT_LRF">' + iTrans('Long Range Flight') + '</label>';
          LRF +=
            '<input type="checkbox" min="0" max="99.9" id="FDT_LRF" data-mini="true" ' +
            (event[plugin.settings.LRF] > 0 ? 'checked' : '') +
            '/>';

          var TOF = '<label for="FDT_TOF">' + iTrans('Transoceanic Flight') + '</label>';
          LRF +=
            '<input type="checkbox" min="0" max="99.9" id="FDT_TOF" data-mini="true" ' +
            (event[plugin.settings.LRF] == 2 ? 'checked' : '') +
            ' ' +
            (event[plugin.settings.LRF] == 0 ? 'disabled' : '') +
            '/>';

          var CROFD = '<label for="FDT_CROFD">' + iTrans('Rest on FD (Min)') + '</label>';
          CROFD +=
            '<input type="number" min="0" max="999" id="FDT_CROFD" data-mini="true" value="' +
            event[plugin.settings.CROFD] +
            '"/>';

          NewFDTFields += '<tr><td>' + FCP + '</td><td>' + LRF + TOF + '</td><td>' + CROFD + '</td></tr>';
          if (event[plugin.settings.FT] == 0) event[plugin.settings.Sectors] = 0;
          else if (event[plugin.settings.Sectors] < 1) event[plugin.settings.Sectors] = 1;
          NewFDTFields +=
            '<tr><td><label for="FDT_Sectors">' +
            iTrans('Sectors') +
            '</label><input type="number" min="0" max="10"  step="1" id="FDT_Sectors" onClick="ExperienceUpdate1(this);selectInputContent(this);" value="' +
            event[plugin.settings.Sectors] +
            '"/></td>';
          var CrewCount = event[plugin.settings.CrewCount];
          if (CrewCount < 1) {
            CrewCount = 1;
          }
          NewFDTFields +=
            '<td><label for="FDT_CrewCount">' +
            iTrans('Crew Count') +
            '</label><input type="number" min="1" max="10" step="1" id="FDT_CrewCount" onClick="ExperienceUpdate1(this);selectInputContent(this);" value="' +
            CrewCount +
            '"/></td>';

          NewFDTFields +=
            '<td><label for="FDT_RestType">' +
            iTrans('Rest Type') +
            '</label><select id="FDT_RestType" data-role="none">';
          NewFDTFields +=
            '<option value="0" ' +
            (event[plugin.settings.restType] == 0 ? 'selected' : '') +
            '>' +
            iTrans('N/A') +
            '</option>';
          NewFDTFields +=
            '<option value="0" ' +
            (event[plugin.settings.restType] == 1 ? 'selected' : '') +
            '>' +
            iTrans('Class 1') +
            '</option>';
          NewFDTFields +=
            '<option value="0" ' +
            (event[plugin.settings.restType] == 2 ? 'selected' : '') +
            '>' +
            iTrans('Class 2') +
            '</option>';
          NewFDTFields +=
            '<option value="0" ' +
            (event[plugin.settings.restType] == 3 ? 'selected' : '') +
            '>' +
            iTrans('Class 3') +
            '</option>';

          NewFDTFields += '</td></tr>';

          NewFDTFields += '<tr>';
          var GPS_SVG =
            '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 561 561" style="enable-background:new 0 0 561 561;" xml:space="preserve"><g>	<path d="M280.5,185.8c-52.1,0-94.7,42.6-94.7,94.7s42.6,94.7,94.7,94.7s94.7-42.6,94.7-94.7S332.6,185.8,280.5,185.8z M491.3,256.8		c-11.8-99.5-90-177.6-187.1-187.1V20h-47.4v49.7c-99.5,9.5-177.6,87.6-187.1,187.1H20v47.4h49.7c11.8,99.5,90,177.6,187.1,187.1		V541h47.4v-49.7c99.5-11.8,177.6-90,187.1-187.1H541v-47.4H491.3z M280.5,446.3c-92.4,0-165.8-73.4-165.8-165.8		s73.4-165.8,165.8-165.8s165.8,73.4,165.8,165.8S372.9,446.3,280.5,446.3z"></path></g><circle id="gpsONicon" cx="280.5" cy="280.5" r="75"></circle></svg>';

          NewFDTFields +=
            '<td><div class="vertical-center-container"><button data-role="none" title="Toggle GPS" class="mapboxgl-ctrl-icon" onclick="getFDTLocation(1)">' +
            GPS_SVG +
            '</button>';
          NewFDTFields +=
            '<button id="FDT_StartLocation" onClick="FDT_SelectLocation(1);">' +
            iTrans('Start Location') +
            '</button></div></td>';
          NewFDTFields +=
            '<td><div class="vertical-center-container"><button data-role="none" title="Toggle GPS" class="mapboxgl-ctrl-icon" onclick="getFDTLocation(2)">' +
            GPS_SVG +
            '</button>';
          NewFDTFields +=
            '<button id="FDT_SameLocation" onClick="FDT_SelectLocation(2);">' +
            iTrans('Start + End') +
            '</button></div></td>';
          NewFDTFields +=
            '<td><div class="vertical-center-container"><button data-role="none" title="Toggle GPS" class="mapboxgl-ctrl-icon" onclick="getFDTLocation(3)">' +
            GPS_SVG +
            '</button>';
          NewFDTFields +=
            '<button id="FDT_EndLocation" onClick="FDT_SelectLocation(3);">' +
            iTrans('End Location') +
            '</button></div></td></tr>';

          var LatCoordTXT;
          var LongCoordTXT;
          if (LOCALSTORAGE.CoordFormat == 'UTM') {
            var utmOK = getUTM(event[plugin.settings.begLat], event[plugin.settings.begLong]);
            LatCoordTXT = utmzone + ' 0' + utmlat;
            LongCoordTXT = 'UTM ' + utmlong;
          } else {
            LongCoordTXT = ConvertDD_User(event[plugin.settings.begLong], 'Long');
            LatCoordTXT = ConvertDD_User(event[plugin.settings.begLat], 'Lat');
          }
          NewFDTFields +=
            '<tr><td><div id="FDT_BegLocationTXT">' + LatCoordTXT + '<br >' + LongCoordTXT + '</div></td>';
          NewFDTFields += '<td></td>';
          if (LOCALSTORAGE.CoordFormat == 'UTM') {
            var utmOK = getUTM(event[plugin.settings.endLat], event[plugin.settings.endLong]);
            LatCoordTXT = utmzone + ' 0' + utmlat;
            LongCoordTXT = 'UTM ' + utmlong;
          } else {
            LongCoordTXT = ConvertDD_User(event[plugin.settings.endLong], 'Long');
            LatCoordTXT = ConvertDD_User(event[plugin.settings.endLat], 'Lat');
          }
          NewFDTFields +=
            '<td><div id="FDT_EndLocationTXT">' + LatCoordTXT + '<br >' + LongCoordTXT + '</div></td></tr>';

          NewFDTFields += '</table>';
          NewFDTFields += '<input type="hidden" id="FDT_begLat" value="' + event[plugin.settings.begLat] + '" />';
          NewFDTFields += '<input type="hidden" id="FDT_begLong" value="' + event[plugin.settings.begLong] + '" />';
          NewFDTFields += '<input type="hidden" id="FDT_endLat" value="' + event[plugin.settings.endLat] + '" />';
          NewFDTFields += '<input type="hidden" id="FDT_endLong" value="' + event[plugin.settings.endLong] + '" />';
        }

        if (event[plugin.settings.Rule] == 0) {
          Rule = 700;
        } //set default aviation regulation rule to 702 aerial work
        else {
          //Rule = event[plugin.settings.Rule];
        }
        //Rule = 135; //testing
        //alert(event[plugin.settings.Rule]);
        //$('#SELECT_Rule').val(event[plugin.settings.Rule]);
        $('#SELECT_Rule_ID').val(event[plugin.settings.Rule_ID]);
        if (event[plugin.settings.Rule] != 702) {
          $('#CHK_AerialApp').prop('disable', true);
          AerialApp = 0;
        }

        var RuleWarningTXT = '';
        if (event[plugin.settings.Rule] != $('#SELECT_Rule').val())
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('Rule will be updated to') +
            ' ' +
            FDT_RuleKEY[$('#SELECT_Rule').val()] +
            '</b>';

        if (event[plugin.settings.DHC] != ($('#CHK_DHC').is(':checked') ? 1 : 0))
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('deHavilland DHC-6 will be: ') +
            ' ' +
            iTrans($('#CHK_DHC').is(':checked') ? 'Enabled' : 'Disabled') +
            '</b>';

        if (event[plugin.settings.IFR] != ($('#CHK_IFR').is(':checked') ? 1 : 0))
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('IFR Will Be: ') +
            ' ' +
            iTrans($('#CHK_IFR').is(':checked') ? 'Enabled' : 'Disabled') +
            '</b>';

        if (event[plugin.settings.Logging] != ($('#CHK_Logging').is(':checked') ? 1 : 0))
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('Logging Will Be: ') +
            ' ' +
            iTrans($('#CHK_Logging').is(':checked') ? 'Enabled' : 'Disabled') +
            '</b>';

        if (event[plugin.settings.AerialApp] != ($('#CHK_AerialApp').is(':checked') ? 1 : 0))
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('Aerial Application Will Be: ') +
            ' ' +
            iTrans($('#CHK_AerialApp').is(':checked') ? 'Enabled' : 'Disabled') +
            '</b>';

        if (event[plugin.settings.Heli] != ($('#CHK_Heli').is(':checked') ? 1 : 0))
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('Helicopter Will Be: ') +
            ' ' +
            iTrans($('#CHK_Heli').is(':checked') ? 'Enabled' : 'Disabled') +
            '</b>';

        if (event[plugin.settings.Dual] != ($('#CHK_Dual').is(':checked') ? 1 : 0))
          RuleWarningTXT =
            '<br /><b class="redbg">' +
            iTrans('WARNING') +
            ' ' +
            iTrans('Dual Pilot Will Be: ') +
            ' ' +
            iTrans($('#CHK_Dual').is(':checked') ? 'Enabled' : 'Disabled') +
            '</b>';

        var AcclimatizedTZ = '';
        if (event[plugin.settings.Rule] == 705) {
          if (event[plugin.settings.AccHours] != 0)
            AcclimatizedTZ =
              '<br /><b class="yellowbg">' + iTrans('Acclimatized TZ') + ': ' + event[plugin.settings.AccTZ] + '</b>';
          else AcclimatizedTZ = '<br /><b>' + iTrans('Acclimatized TZ') + ': ' + event[plugin.settings.AccTZ] + '</b>';
        }

        var SplitStartTime = '';
        if (prevSplit !== false)
          SplitStartTime = '<br /><div class="yellowbg">Previous Split Duty Ended at: <b>(' + prevSplit + ')</b></div>';
        var nav =
          '<div class="itin-nav"><table class="limit-table first-td-fill"><tr><td style="text-align: left;"><h2>' +
          refreshDATE.toDateString() +
          '</h2><div style="font-size:+2;"><b>' +
          CustomRule +
          iTrans('Regulation') +
          ': ' +
          FDT_RuleKEY[event[plugin.settings.Rule]] +
          '</b>' +
          RuleWarningTXT +
          '<br /><b>' +
          iTrans('Timezone') +
          ': ' +
          event[plugin.settings.Timezone] +
          AcclimatizedTZ +
          SplitStartTime +
          '</b></div></td>' +
          '<td style="width: 90px; padding-right: 5px;"><button data-mini="true" class="cirro-btn" id="FDTPrevDay"><div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i>' +
          iTrans('Prev Day') +
          '</div></button></td>' +
          '<td style="width: 90px; padding-left: 5px;"><button data-mini="true" class="cirro-btn" id="FDTNextDay" ' +
          disableNEXT +
          '><div class="vertical-center-container">' +
          iTrans('Next Day') +
          '<i class="icon-margins fal fa-chevron-right"></i></div></button></td>' +
          '</tr></table>' +
          '</div>';
        CurrentRecTimeZone = event[plugin.settings.Timezone];
        var ExtendReason = event[plugin.settings.ExtendReason];
        var ExtendON = event[plugin.settings.ExtendON];
        var ExtendNum = event[plugin.settings.ExtendNum];

        /*
         var CustomWarning = event[plugin.settings.CustomWarning];
         if (CustomWarning.Status != "") CustomRule += CustomWarning.Status + "<br />";
         var CustomViolation = event[plugin.settings.CustomViolation];
         if (CustomViolation.Status != "") CustomRule += CustomViolation.Status + "<br />";
         */
        var Summary = '<div id="Summary_HDLR">';
        var SummaryComment = event[plugin.settings.Comment];
        if (SummaryComment != null && SummaryComment != '') {
          SummaryComment += '<br />';
        }
        Summary += SummaryComment + CustomRule;
        if (GROUP_DATA.PULSAR_FRMS == 1) {
          Summary += '<br /><div id="PULSAR_FDT_DAY"><i>Loading FRMS data...</i></div>';
          PULSAR_Get_Date(refreshDATE, 'PULSAR_FDT_DAY');
        }

        var MaxNextFltTimes = '';
        var AverageFlightDuration = '';
        if (event[plugin.settings.Rule] == 705) {
          AverageFlightDuration +=
            '<b>' +
            iTrans('Average Flight Time') +
            '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
            '<label for="FDT_AFD0">0-29</label>' +
            '<input type="radio" name="FDT_AFD" id="FDT_AFD0" value="0" ' +
            (event[plugin.settings.ET2] == 0 ? 'checked' : '') +
            '/>' +
            '<label for="FDT_AFD30">30-49</label>' +
            '<input type="radio" name="FDT_AFD" id="FDT_AFD30" value="30" ' +
            (event[plugin.settings.ET2] == 30 ? 'checked' : '') +
            '/>' +
            '<label for="FDT_AFD50">50+</label>' +
            '<input type="radio" name="FDT_AFD" id="FDT_AFD50" value="50" ' +
            (event[plugin.settings.ET2] == 50 ? 'checked' : '') +
            '/>' +
            '</fieldset>';
        }
        //var rec_FT = parseFloat(event[plugin.settings.FT]) + parseFloat(event[plugin.settings.FT2]);
        var rec_FT = parseFloat(event[plugin.settings.dayFT]);
        rec_FT = Math.round(rec_FT * 10) / 10;

        if (
          event[plugin.settings.Rule] == 702 ||
          event[plugin.settings.Rule] == 703 ||
          event[plugin.settings.Rule] == 704 ||
          event[plugin.settings.Rule] == 604 ||
          event[plugin.settings.Rule] == 700 ||
          event[plugin.settings.Rule] == 705
        ) {
          MaxNextFltTimes = event[plugin.settings.MaxNextFltTimes];
          if (MaxNextFltTimes.MaxTime < 0) {
            Summary +=
              '<br /><b>' +
              iTrans('Cannot Fly Tomorrow') +
              ':</b> ' +
              Math.abs(MaxNextFltTimes.MaxTime) +
              ' hrs ' +
              iTrans('beyond') +
              ' - ' +
              MaxNextFltTimes.Limiter +
              ' ' +
              iTrans('Limit') +
              '';
          } else {
            Summary +=
              '<br /><b>' +
              iTrans('Max FT Tomorrow') +
              ':</b> ' +
              MaxNextFltTimes.MaxTime +
              ' hrs - ' +
              MaxNextFltTimes.Limiter +
              ' ' +
              iTrans('Limit') +
              '';
          }

          Summary +=
            '<table class="wrap"><tr><td><div id="DayIdents"></div></td><td>7d: ' +
            event[plugin.settings.L7] +
            '</td><td>90d: ' +
            event[plugin.settings.L90] +
            '</td><td>Off 30d: ' +
            event[plugin.settings.Off30] +
            '</td></tr>';
          Summary +=
            '<tr><td>FT: ' +
            rec_FT +
            '</td><td>30d: ' +
            event[plugin.settings.L30] +
            '</td><td>180d: ' +
            event[plugin.settings.L180] +
            '</td><td>Off 90d: ' +
            event[plugin.settings.Off90] +
            '</td></tr>';

          if (Rule != 700) {
            Summary +=
              '<tr><td>DT: ' +
              event[plugin.settings.DT] +
              '</td><td>42d: ' +
              event[plugin.settings.L42] +
              '</td><td>365d: ' +
              event[plugin.settings.L365] +
              '</td><td>Noff: ' +
              FDT_formatDate_Time(event[plugin.settings.Noff]) +
              '</td></tr><tr><td>ODT: ' +
              event[plugin.settings.ODT] +
              '</td><td>RT: ' +
              event[plugin.settings.RT] +
              '</td><td>' +
              iTrans('Rule') +
              ': ' +
              event[plugin.settings.Rule] +
              '</td><td>Noff ' +
              iTrans('Limit') +
              ': ' +
              event[plugin.settings.Noff_Limit] +
              '</td></tr>';
            if (Rule == 705) {
              Summary +=
                '<tr><td>Hours Worked</td><td>L7: ' +
                round10(event[plugin.settings.DT7]) +
                '</td><td>L28: ' +
                round10(event[plugin.settings.DT28]) +
                '</td><td>L365: ' +
                round10(event[plugin.settings.DT365]) +
                '</td></tr>';
            }
            Summary += '</table>';
          } else {
            Summary +=
              '<tr><td>DT: ' +
              event[plugin.settings.DT] +
              '</td><td>42d: ' +
              event[plugin.settings.L42] +
              '</td><td>365d: ' +
              event[plugin.settings.L365] +
              '</td><td></td></tr></table>';
          }
          Summary += '<div id="FDT_CalendarStatus">' + event[plugin.settings.Status] + '</div>';
        }
        if (event[plugin.settings.Rule] == 4) {
          //Kenya FDT summary
          Summary +=
            '<table class="wrap"><tr><td><div id="DayIdents"></div></td><td>72h: ' +
            event[plugin.settings.L3] +
            '</td><td>365d: ' +
            event[plugin.settings.L365] +
            '</td><td></td></tr>';
          Summary +=
            '<tr><td>FT: ' + rec_FT + '</td><td>28d: ' + event[plugin.settings.L28] + '</td><td></td><td></td></tr>';

          Summary +=
            '<tr><td>DT: ' +
            event[plugin.settings.DT] +
            '</td><td>90d: ' +
            event[plugin.settings.L90] +
            '</td><td></td><td>' +
            '</td></tr><tr><td>ODT: ' +
            event[plugin.settings.ODT] +
            '</td><td>RT: ' +
            event[plugin.settings.RT] +
            '</td><td>' +
            iTrans('Rule') +
            ': ' +
            FDT_RuleKEY[event[plugin.settings.Rule]] +
            '</td><td></td></tr></table>';

          Summary += '<div id="FDT_CalendarStatus">' + event[plugin.settings.Status] + '</div>';
        }
        if (event[plugin.settings.Rule] == 5) {
          //DGAC DAN 137
          Summary +=
            '<table class="wrap"><tr><td><div id="DayIdents"></div></td><td>72h: ' +
            event[plugin.settings.L3] +
            '</td><td>365d: ' +
            event[plugin.settings.L365] +
            '</td><td></td></tr>';
          Summary +=
            '<tr><td>FT: ' + rec_FT + '</td><td>28d: ' + event[plugin.settings.L28] + '</td><td></td><td></td></tr>';

          Summary +=
            '<tr><td>DT: ' +
            event[plugin.settings.DT] +
            '</td><td>90d: ' +
            event[plugin.settings.L90] +
            '</td><td></td><td>' +
            '</td></tr><tr><td></td><td>RT: ' +
            event[plugin.settings.RT] +
            '</td><td>' +
            iTrans('Rule') +
            ': ' +
            FDT_RuleKEY[event[plugin.settings.Rule]] +
            '</td><td></td></tr></table>';

          Summary += '<div id="FDT_CalendarStatus">' + event[plugin.settings.Status] + '</div>';
        }
        if (
          event[plugin.settings.Rule] == 135 ||
          event[plugin.settings.Rule] == 131 ||
          event[plugin.settings.Rule] == 133
        ) {
          Summary +=
            '<table class="wrap"><tr><td><div id="DayIdents"></div></td><td>CurQ:' +
            event[plugin.settings.lastQ] +
            '</td></tr>';
          Summary +=
            '<tr><td>FT:' +
            rec_FT +
            '</td><td>' +
            iTrans('Last') +
            '2Q:' +
            event[plugin.settings.last2Q] +
            '</td></tr>';
          Summary +=
            '<tr><td>RT:' +
            event[plugin.settings.RT] +
            '</td><td>Cal ' +
            iTrans('Year') +
            ':' +
            event[plugin.settings.CalYear] +
            '</td></tr>';
          Summary += '</table>';
          Summary += '<div id="FDT_CalendarStatus">' + event[plugin.settings.Status] + '</div>';
        }
        if (event[plugin.settings.Rule] == 3 || event[plugin.settings.Rule] == 2 || event[plugin.settings.Rule] == 1) {
          Summary +=
            '<table class="wrap"><tr><td><div id="DayIdents"></div></td><td>7d: ' +
            event[plugin.settings.L7] +
            '</td><td>90d: ' +
            event[plugin.settings.L90] +
            '</td><td>Off 30d: ' +
            event[plugin.settings.Off30] +
            '</td></tr>';
          Summary +=
            '<tr><td>FT: ' +
            rec_FT +
            '</td><td>30d: ' +
            event[plugin.settings.L30] +
            '</td><td>180d: ' +
            event[plugin.settings.L180] +
            '</td><td>Off 90d: ' +
            event[plugin.settings.Off90] +
            '</td></tr>';
          if (Rule != 700) {
            Summary +=
              '<tr><td>DT: ' +
              event[plugin.settings.DT] +
              '</td><td>42d: ' +
              event[plugin.settings.L42] +
              '</td><td>365d: ' +
              event[plugin.settings.L365] +
              '</td><td>Noff: ' +
              FDT_formatDate_Time(event[plugin.settings.Noff]) +
              '</td></tr><tr><td>ODT: ' +
              event[plugin.settings.ODT] +
              '</td><td>RT: ' +
              event[plugin.settings.RT] +
              '</td><td>' +
              iTrans('Rule') +
              ': ' +
              Rule +
              '</td><td>Noff ' +
              iTrans('Limit') +
              ': ' +
              event[plugin.settings.Noff_Limit] +
              '</td></tr></table>';
          } else {
            Summary +=
              '<tr><td>DT: ' +
              event[plugin.settings.DT] +
              '</td><td>42d: ' +
              event[plugin.settings.L42] +
              '</td><td>365d: ' +
              event[plugin.settings.L365] +
              '</td><td></td></tr></table>';
          }
          Summary += event[plugin.settings.Status];
        }
        Summary +=
          '<button id="Edit_Rec" data-mini="true" class="greenbg" data-icon-"plus">' + iTrans('Update') + '</button>';
        /*
        if (event[plugin.settings.Currency] != '')
        {
          Summary += '<b>' + iTrans('Warning Not Current') + '</b><br />' + event[plugin.settings.Currency];
        }
        */
        Summary += `<h2>${iTrans('Currency Reports')}</h2>`;
        Summary += '<div id="IFR_CURRENCY_TXT"></div>';
        Summary += '<div id="NVG_CURRENCY_TXT"></div>';
        //Summary += '<div id="DAY_NIGHT_CURRENCY_TXT"></div>';
        Summary += '<div id="ACTYPE_CURRENCY_TXT"></div>';

        var html = Summary + '</div><div id="Edit_HDLR" style="display:none;">' + nav + '<table>';

        html += '<tr>';
        if (event[plugin.settings.Rule] == 702 || event[plugin.settings.Rule] == 703) {
          if (Extend == 1) {
            html +=
              '<td><label for="Extend">' +
              iTrans('Extend Duty') +
              ' ' +
              ExtendNum +
              '/6 ' +
              iTrans('Used') +
              '</label><input type="checkbox" id="Extend" data-mini="true" checked="checked"/>';
          } else {
            if (ExtendON == 1 && ExtendAvail == 0) {
              html +=
                '<td><label for="Extend">' +
                iTrans('Non-overlapping period') +
                ' (' +
                ExtendNum +
                '/6 ' +
                iTrans('Used') +
                ')</label><input type="checkbox" id="Extend" data-mini="true" checked="checked" disabled="disabled" />';
            }
            if (ExtendON == 0 && ExtendAvail == 1) {
              html +=
                '<td><label for="Extend">' +
                iTrans('Non-overlapping period') +
                ' (' +
                ExtendNum +
                '/6 ' +
                iTrans('Used') +
                ')</label><input type="checkbox" id="Extend" data-mini="true" />';
            }
            if (ExtendON == 0 && ExtendAvail == 0) {
              html += '<td><b>' + iTrans('Non-overlapping period Unavailable') + ': </b>' + ExtendReason + '';
            }
          }
          html += UOC;
          html += '</td>';
        } else if (
          event[plugin.settings.Rule] == 704 ||
          event[plugin.settings.Rule] == 705 ||
          event[plugin.settings.Rule] == 604
        ) {
          html += '<td>' + UOC + '</td>';
        }

        if (
          event[plugin.settings.Rule] == 702 ||
          event[plugin.settings.Rule] == 703 ||
          event[plugin.settings.Rule] == 704 ||
          event[plugin.settings.Rule] == 705
        ) {
          html +=
            '<td><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
            '<label for="FDTNormal">' +
            iTrans('Norm') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTNormal" value="0" />' +
            '<label for="FDTStandby">' +
            iTrans('Standby') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTStandby" value="1" />' +
            '<label for="FDTOnReserve">' +
            iTrans('Reserve') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTOnReserve" value="2" />' +
            '<label for="FDTOnCall">' +
            iTrans('On Call') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTOnCall" value="10" ' +
            (Rule == 705 ? 'disabled' : '') +
            '/>' +
            '</fieldset>';
          html += RES_Notice;
        }
        if (event[plugin.settings.Rule] == 604) {
          html +=
            '<td><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
            '<label for="FDTNormal">' +
            iTrans('Norm') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTNormal" value="0" />' +
            '<label for="FDTStandby">' +
            iTrans('Standby') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTStandby" value="1" />' +
            '<label for="FDTOnReserve">' +
            iTrans('Reserve') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTOnReserve" value="2" disabled/>' +
            '<label for="FDTOnCall">' +
            iTrans('On Call') +
            '</label>' +
            '<input type="radio" name="FDT_Reserve" id="FDTOnCall" value="10" disabled/>' +
            '</fieldset>';
        }
        if (
          event[plugin.settings.Rule] == 135 ||
          event[plugin.settings.Rule] == 131 ||
          event[plugin.settings.Rule] == 133
        ) {
          if (Extend == 1) {
            if (event[plugin.settings.Rule] == 135 || event[plugin.settings.Rule] == 133) {
              html +=
                '<td><label for="Extend">' +
                iTrans('Assigned Duty Period') +
                '</label><input type="checkbox" id="Extend" data-mini="true" checked="checked" onClick="toggleASDP(this)"/>';
            } else {
              html +=
                '<td><label for="Extend">' +
                iTrans('Over 48 Hour HEMS Assignment') +
                '</label><input type="checkbox" id="Extend" data-mini="true" checked="checked" onClick="toggleASDP(this)"/>';
            }
            $('#FT').prop('disabled', false);
          } else {
            //$("#FT").prop("disabled", true);
            html += '<td>Total FT Last 24hrs: ' + event[plugin.settings.TotalLast24];
            if (event[plugin.settings.Rule] == 135 || event[plugin.settings.Rule] == 133) {
              html +=
                '<label for="Extend">' +
                iTrans('Assigned Duty Period') +
                '</label><input type="checkbox" id="Extend" data-mini="true"  onClick="toggleASDP(this)"/>';
            } else {
              html +=
                '<label for="Extend">' +
                iTrans('Over 48 Hour HEMS Assignment') +
                '</label><input type="checkbox" id="Extend" data-mini="true"  onClick="toggleASDP(this)"/>';
            }
          }
        }

        html += '<tr><td><div data-role="fieldcontain">';
        html += '<label for="Start">Start</label>';

        html +=
          '<input id="Start" onChange="validateTime(this.value,this.id,true);" type="number" value="' +
          event[plugin.settings.Start] +
          '" /></div></td>';

        html += '<td><div data-role="fieldcontain"><label for="End">End</label>';
        html +=
          '<input id="End"  onChange="validateTime(this.value,this.id,true);" type="number" value="' +
          event[plugin.settings.End] +
          '" /></div></td></tr>';
        html += '<tr><td><div data-role="fieldcontain"><label for="FT">Flight</label>';
        html +=
          '<input id="FT" type="number" value="' +
          event[plugin.settings.FT] +
          '" /></div>' +
          AverageFlightDuration +
          '</td>';
        var DayOffCHK = '';
        if (Rule == 4) {
          if (
            event[plugin.settings.EL] == 1 ||
            (event[plugin.settings.Start] == 0 &&
              event[plugin.settings.End] == 0 &&
              REC_EDITED == false &&
              FDT_ADMIN_UPDATE == false)
          ) {
            DayOffCHK +=
              '<label for="DayOff">' +
              iTrans('Day Off') +
              '</label><input type="checkbox" id="DayOff" data-mini="true" checked/>';
            //Autosave now to change mod timestamp
            update_Sync('LastFDTsync', new Date());
            saveEdit('save'); //automatically save changed data silently
          } else {
            DayOffCHK +=
              '<label for="DayOff">' +
              iTrans('Day Off') +
              '</label><input type="checkbox" id="DayOff" data-mini="true" />';
          }
        }
        if (Rule != 700) {
          //commercial operators only
          html += '<td>';
          html += DayOffCHK;
          if (event[plugin.settings.Overnight] == 1) {
            html +=
              '<label for="Overnight">' +
              iTrans('Overnights') +
              '</label><input type="checkbox" id="Overnight" data-mini="true" checked="checked" onClick="FDT_CheckSplitDutylabel(this)"/>';
            html += '<label for="Split"><div id="SplitTXT">' + iTrans('Mid Day Rest') + '</div></label>';
          } else {
            html +=
              '<label for="Overnight">' +
              iTrans('Overnights') +
              '</label><input type="checkbox" id="Overnight" data-mini="true"  onClick="FDT_CheckSplitDutylabel(this)"/>';
            html += '<label for="Split"><div id="SplitTXT">' + iTrans('Split Duty') + '</div></label>';
          }

          if (Split == 1) {
            var html2 = '';
            if (
              event[plugin.settings.Rule] == 702 ||
              event[plugin.settings.Rule] == 703 ||
              event[plugin.settings.Rule] == 704 ||
              event[plugin.settings.Rule] == 705
            ) {
              html2 +=
                '<div id="FDT_Reserve2HLDR"><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
                '<label for="FDTNormal2">' +
                iTrans('Norm') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTNormal2" value="0" />' +
                '<label for="FDTStandby2">' +
                iTrans('Standby') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTStandby2" value="1" />' +
                '<label for="FDTOnReserve2">' +
                iTrans('Reserve') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnReserve2" value="2" />' +
                '<label for="FDTOnCall2">' +
                iTrans('On Call') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnCall2" value="11" ' +
                (Rule == 705 ? 'disabled' : '') +
                '/>' +
                '</fieldset></div>';
              html2 += RES_Notice2;
            }
            if (event[plugin.settings.Rule] == 604) {
              html2 +=
                '<div id="FDT_Reserve2HLDR"><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
                '<label for="FDTNormal2">' +
                iTrans('Norm') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTNormal2" value="0" />' +
                '<label for="FDTStandby2">' +
                iTrans('Standby') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTStandby2" value="1" />' +
                '<label for="FDTOnReserve2">' +
                iTrans('Reserve') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnReserve2" value="2" disabled>' +
                '<label for="FDTOnCall2">' +
                iTrans('On Call') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnCall2" value="11" disabled/>' +
                '</fieldset></div>';
            }
            if (event[plugin.settings.Rule] == 2) {
              //Ausie Ag Rules
              html2 +=
                '<div id="FDT_Reserve2HLDR"><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
                '<label for="FDTNormal2">' +
                iTrans('Suitable Sleeping') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTNormal2" value="0" />' +
                '<label for="FDTStandby2">' +
                iTrans('Suitable Resting') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTStandby2" value="1" />' +
                '</fieldset></div>';
            }
            html += '<input type="checkbox" id="Split" data-mini="true" checked="checked" />' + html2 + '</td></tr>';
            html +=
              '<tr><td><div id="E1" data-role="fieldcontain"><label for="Start2">' +
              iTrans('Start') +
              '2</label><input id="Start2" onChange="validateTime(this.value,this.id,true);" type="number" value="' +
              event[plugin.settings.Start2] +
              '" /></div></td>';
            html +=
              '<td><div id="E2" data-role="fieldcontain"><label for="End2">' +
              iTrans('End') +
              '2</label><input id="End2" onChange="validateTime(this.value,this.id,true);" type="number" value="' +
              event[plugin.settings.End2] +
              '" /></div></td></tr>';
            html += '<tr><td><div id="E3" data-role="fieldcontain"><label for="FT2">' + iTrans('Flight') + ' 2</label>';
            html += '<input id="FT2" type="number" value="' + event[plugin.settings.FT2] + '" /></div></td>';
            html +=
              '<td><div id="E4" style="display: ' +
              (event[plugin.settings.Overnight] == 1 ? 'block;' : 'none;') +
              '"><label for="Split2">' +
              iTrans('Split Duty') +
              '</label><input id="Split2" type="checkbox" data-mini="true" ' +
              (event[plugin.settings.Split2] == 1 ? 'checked' : '') +
              '/></div></td>';
          } else {
            var html2 = '';
            if (
              event[plugin.settings.Rule] == 702 ||
              event[plugin.settings.Rule] == 703 ||
              event[plugin.settings.Rule] == 704 ||
              event[plugin.settings.Rule] == 705
            ) {
              html2 +=
                '<div id="FDT_Reserve2HLDR" style="display:none;"><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
                '<label for="FDTNormal2">' +
                iTrans('Norm') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTNormal2" value="0" />' +
                '<label for="FDTStandby2">' +
                iTrans('Standby') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTStandby2" value="1" />' +
                '<label for="FDTOnReserve2">' +
                iTrans('Reserve') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnReserve2" value="2" />' +
                '<label for="FDTOnCall2">' +
                iTrans('On Call') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnCall2" value="10" ' +
                (Rule == 705 ? 'disabled' : '') +
                '/>' +
                '</fieldset></div>';
            }
            if (event[plugin.settings.Rule] == 604) {
              html2 +=
                '<div id="FDT_Reserve2HLDR" style="display:none;"><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
                '<label for="FDTNormal2">' +
                iTrans('Norm') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTNormal2" value="0" />' +
                '<label for="FDTStandby2">' +
                iTrans('Standby') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTStandby2" value="1" />' +
                '<label for="FDTOnReserve2">' +
                iTrans('Reserve') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnReserve2" value="2" disabled>' +
                '<label for="FDTOnCall2">' +
                iTrans('On Call') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTOnCall2" value="11" disabled/>' +
                '</fieldset></div>';
            }
            if (event[plugin.settings.Rule] == 2) {
              //Ausie Ag Rules
              html2 +=
                '<div id="FDT_Reserve2HLDR" style="display:none;"><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">' +
                '<label for="FDTNormal2">' +
                iTrans('Suitable Sleeping') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTNormal2" value="0" />' +
                '<label for="FDTStandby2">' +
                iTrans('Suitable Resting') +
                '</label>' +
                '<input type="radio" name="FDT_Reserve2" id="FDTStandby2" value="1" />' +
                '</fieldset></div>';
            }

            html += '<input type="checkbox" id="Split" data-mini="true" />' + html2 + '</td></tr>';
            html +=
              '<tr><td><div id="E1" data-role="fieldcontain" style="display:none;"><label for="Start2">' +
              iTrans('Start') +
              '2</label><input id="Start2" onChange="validateTime(this.value,this.id,true);" type="number" value="' +
              event[plugin.settings.Start2] +
              '" /></div></td>';
            html +=
              '<td><div id="E2" data-role="fieldcontain" style="display:none;"><label for="End2">' +
              iTrans('End') +
              '2</label><input id="End2" onChange="validateTime(this.value,this.id,true);" type="number" value="' +
              event[plugin.settings.End2] +
              '" /></div></td></tr>';
            html +=
              '<tr><td><div id="E3" data-role="fieldcontain" style="display:none;"><label for="FT2">' +
              iTrans('Flight') +
              ' 2</label>';
            html += '<input id="FT2" type="number" value="' + event[plugin.settings.FT2] + '" /></div></td>';
            html +=
              '<td><div id="E4" style="display:none;"><label for="Split2">' +
              iTrans('Split Duty') +
              '</label><input id="Split2" type="checkbox" data-mini="true" ' +
              (event[plugin.settings.Split2] == 1 ? 'checked' : '') +
              '/></div></td>';
            if (event[plugin.settings.Rule] == 3) {
              //CASR 135
              var EL_checked = '';
              if (event[plugin.settings.EL] == 1) {
                EL_checked = 'checked';
              }
              if (Heli == 1) {
                html +=
                  '<tr><td><td></td><label for="HeliExternalLoads">' +
                  iTrans('External Loads / Over 30 Landings') +
                  '</label><input type="checkbox" id="HeliExternalLoads" + ' +
                  EL_checked +
                  ' data-mini="true" /></td></tr>';
              } else {
                html +=
                  '<tr><td><td></td><label for="MCTOW_over_1250">' +
                  iTrans('Over 12500 lbs MCTOW') +
                  '</label><input type="checkbox" id="MCTOW_over_1250" + ' +
                  EL_checked +
                  ' data-mini="true" /></td></tr>';
              }
            }
          }
        }
        html += '</tr>';

        html += '</table>';
        html += NewFDTFields;
        html += '<label for="Comment">' + iTrans('Comment') + '</label>';
        html += '<textarea id="Comment">' + event[plugin.settings.Comment] + '</textarea>';

        ROLL24DATA = event[plugin.settings.EXTRA];
        html += "<div id='Roll24'></div>";

        if (GROUP_DATA.FlightReports == 1) {
          ////console.log(refreshDATE);
          var Sdate = GetCurFDT_Date_String();
          html += '<div id="TodayBillingData"><h3>' + iTrans('No Flight Report Data For This Date') + '</h3></div>';
        }
        html += '<h3>' + iTrans('Aircraft Experience Tracking') + '</h3>';
        if (
          (event[plugin.settings.End] != 0 &&
            parseFloat(event[plugin.settings.FT]) + parseFloat(event[plugin.settings.FT2]) > 0) ||
          GROUP_DATA.FDT_Valid_FT_Off == 1
        ) {
          html +=
            '<button data-mini="true" data-icon="plus" class="greenbg" id="AddFDTExperienceBTN" onClick="NewFDTrec();">' +
            iTrans('Add Aircraft for Experience Tracking') +
            '</button>';
        } else {
          html +=
            '<button data-mini="true" data-icon="plus" class="greenbg" id="AddFDTExperienceBTN" onClick="NewFDTrec();" disabled>' +
            iTrans('Add Aircraft for Experience Tracking') +
            '</button>';
        }

        html += '<div id="FDT_EXPERIENCE_INPUT"></div>';
        if (GROUP_DATA.FlightReports == 1) {
          html += '<div id="FDT_ExpenseView"></div><br />';
        }

        //populate_FDT_Rec(0);
        //html += "</div>";

        //html += "</div>";

        html += '<button id="Close_Edit" data-mini="true">' + iTrans('Save and Return to Calendar') + '</button>';
        html +=
          '<table><tr><td><button id="Reset_Rec" data-mini="true" class="redbg">' +
          iTrans('Reset Day') +
          '</button></td>';

        if (!lastDay) {
          html +=
            '<td><button id="Close_Edit_Next" data-mini="true" class="greenbg">' +
            iTrans('Save and Next') +
            '</button></td></tr></table>';
        }
        html += '<div id="FDT_ErrorStatus">' + event[plugin.settings.Status] + '</div></div>';

        if (!REC_EDITED) {
          $listItem.html(html);
        } //Add HTML and then listeners

        if (event[plugin.settings.End] == '2359') {
          $('#E1').hide();
          $('#E2').hide();
          $('#E3').hide();
          $('#E4').hide();
          $('#FDT_Reserve2HLDR').hide();
          $('#Start2').val(0);
          $('#End2').val(0);
          $('#FT2').val(0);
          $('#Split2').prop('checked', false);
        }
        if (
          (event[plugin.settings.Rule] == 135 && ExtendON == 0) ||
          event[plugin.settings.Rule] == 131 ||
          (event[plugin.settings.Rule] == 133 && ExtendON == 0)
        ) {
          if (!REC_EDITED) {
            addRoll24();
          }
        }

        if (
          event[plugin.settings.Rule] == 702 ||
          event[plugin.settings.Rule] == 703 ||
          event[plugin.settings.Rule] == 704 ||
          event[plugin.settings.Rule] == 705 ||
          event[plugin.settings.Rule] == 604
        ) {
          switch (Standby) {
            case 0:
              $('#FDTNormal').prop('checked', true);
              $('#FDTNormal2').prop('checked', true);
              break;
            case 1:
              $('#FDTStandby').prop('checked', true);

              break;
            case 2:
              $('#FDTOnReserve').prop('checked', true);
              $('#FDTNormal2').prop('checked', true);
              break;
            case 10:
              $('#FDTOnCall').prop('checked', true);
              $('#FDTNormal2').prop('checked', true);
              break;

            case 3:
              $('#FDTNormal').prop('checked', true);
              $('#FDTStandby2').prop('checked', true);
              break;
            case 4:
              $('#FDTStandby').prop('checked', true);
              $('#FDTStandby2').prop('checked', true);
              break;
            case 5:
              $('#FDTOnReserve').prop('checked', true);
              $('#FDTStandby2').prop('checked', true);
              break;
            case 16:
              $('#FDTOnCall').prop('checked', true);
              $('#FDTStandby2').prop('checked', true);
              break;

            case 6:
              $('#FDTNormal').prop('checked', true);
              $('#FDTOnReserve2').prop('checked', true);
              break;
            case 7:
              $('#FDTStandby').prop('checked', true);
              $('#FDTOnReserve2').prop('checked', true);
              break;
            case 8:
              $('#FDTOnReserve').prop('checked', true);
              $('#FDTOnReserve2').prop('checked', true);
              break;
            case 19:
              $('#FDTOnCall').prop('checked', true);
              $('#FDTOnReserve2').prop('checked', true);
              break;

            case 33:
              $('#FDTNormal').prop('checked', true);
              $('#FDTOnCall2').prop('checked', true);
              break;
            case 34:
              $('#FDTStandby').prop('checked', true);
              $('#FDTOnCall2').prop('checked', true);
              break;
            case 35:
              $('#FDTOnReserve').prop('checked', true);
              $('#FDTOnCall2').prop('checked', true);
              break;
            case 43:
              $('#FDTOnCall').prop('checked', true);
              $('#FDTOnCall2').prop('checked', true);
              break;
          }
          if (Split == 0) $('#FDTNormal2').prop('checked', true);

          var Standby1 = parseInt($('input[name=FDT_Reserve]:checked').val());
          if (Standby1 != 0 && Standby1 != 1 && Standby1 != 10) {
            $('#FT').prop('disabled', true);
            $('#FT').val('0');
          } else {
            $('#FT').prop('disabled', false);
          }

          var Standby2 = parseInt($('input[name=FDT_Reserve2]:checked').val()) * 3;
          if (Standby2 != 0 && Standby2 != 3 && Standby2 != 30) {
            $('#FT2').prop('disabled', true);
            $('#FT2').val(0);
          } else {
            $('#FT2').prop('disabled', false);
          }
        }
        if (event[plugin.settings.Rule] == 2) {
          switch (Standby) {
            case 0:
              $('#FDTNormal2').prop('checked', true);
              break;
            case 1:
              $('#FDTStandby2').prop('checked', true);
              break;
          }
        }

        if (
          (USER_RIGHTS.FDT_NvgCur == 1 && !FDT_ADMIN_UPDATE) ||
          (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)
        ) {
          CheckNVGCurrency(new Date());
        }
        if (
          (USER_RIGHTS.FDT_IfrCur == 1 && !FDT_ADMIN_UPDATE) ||
          (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1)
        ) {
          CheckIFRCurrency(new Date());
        }
        GetCurrency_RecData(Rule);
        //*************************************************************************************************************
        //End if USERTYPE == pilot
        if (GROUP_DATA.PULSAR_FRMS == 1) {
          /*
           //Lets not autopopulate for now since users might not like it
           if (event[plugin.settings.begLat] == 0 && event[plugin.settings.begLong] == 0 && event[plugin.settings.endLat] == 0 && event[plugin.settings.endLong] == 0) getFDTLocation(2);
           else if (event[plugin.settings.begLat] == 0 && event[plugin.settings.begLong] == 0) getFDTLocation(1);
           else if (event[plugin.settings.endLat] == 0 && event[plugin.settings.endLong] == 0) getFDTLocation(3);
           */
        }
      } else {
        //Not a pilot so time tracking sheet for all other staff types

        var nav =
          '<div class="itin-nav"><table class="limit-table first-td-fill"><tr><td style="text-align: left;"><h2>' +
          refreshDATE.toDateString() +
          '</h2><div style="font-size:+2;"></div></td>' +
          '<td style="width: 90px; padding-right: 5px;"><button data-mini="true" class="cirro-btn" id="FDTPrevDay"><div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i>' +
          iTrans('Prev Day') +
          '</div></button></td>' +
          '<td style="width: 90px; padding-left: 5px;"><button data-mini="true" class="cirro-btn" id="FDTNextDay" ' +
          disableNEXT +
          '><div class="vertical-center-container">' +
          iTrans('Next Day') +
          '<i class="icon-margins fal fa-chevron-right"></i></div></button></td>' +
          '</tr></table>' +
          '</div>';

        Split = event[plugin.settings.Split];
        Split2 = 0;
        FDT_IdentList = event[plugin.settings.idents];
        var Summary = '<div id="Summary_HDLR">';
        if (event[plugin.settings.AdminComment] != '') {
          Summary +=
            '<div class="yellowbg"><b>' +
            iTrans('Admin Comment') +
            ': </b>' +
            event[plugin.settings.AdminComment] +
            '</div>';
        }
        if (event[plugin.settings.SickDay] == 1) {
          Summary += '' + iTrans('Sick Day') + '<br />';
        }
        if (event[plugin.settings.FieldPay] == 1) {
          Summary += '' + iTrans('Field Pay / Flat Rate') + '<br />';
        }

        Summary += event[plugin.settings.Comment] + '<br />';
        Summary += '<div id="FDT_WorkStats2"></div>';
        Summary += '<b>' + iTrans('Flight Time') + ': </b>' + event[plugin.settings.dayFT] + '<br />';
        var rec_FT = parseFloat(event[plugin.settings.FT]) + parseFloat(event[plugin.settings.FT2]);
        rec_FT = Math.round(rec_FT * 10) / 10;

        Summary +=
          '<button id="Edit_Rec" data-mini="true" class="greenbg" data-icon-"plus">' + iTrans('Update') + '</button>';

        var html = Summary + '</div><div id="Edit_HDLR" style="display:none;">' + nav;

        var approved = '';
        if (event[plugin.settings.Approved] == 1) {
          approved = 'disabled';
          html += '<h3>' + iTrans('Approved for payroll, edits disabled') + '</h3>';
        }
        html += '<table>';
        html += '<tr><td><div data-role="fieldcontain"><label for="FDTIDENT">' + iTrans('Add A/C') + '</label>';
        html +=
          '<select id="FDTIDENT" data-mini="false" data-role="none" ' +
          approved +
          ' onChange="add_FDT_IdentList(this);"><option selected value="Select">' +
          iTrans('Select') +
          '</option>' +
          AClist +
          '</select></div><div id="FDT_IdentList"></div></td>';
        var locationSelector = getLocationSelector(approved);

        var SickDay = '';

        SickDay = '<label for="FDT_SickDay">' + iTrans('Sick Day') + '</label>';
        if (event[plugin.settings.SickDay] == 1) {
          SickDay += '<input type="checkbox" data-mini="true" id="FDT_SickDay" checked />';
        } else {
          SickDay += '<input type="checkbox" data-mini="true" id="FDT_SickDay"/>';
        }

        if (event[plugin.settings.FieldPay] == 0) {
          html +=
            '<td>' +
            SickDay +
            '<label for="FDT_FieldPay">' +
            iTrans('Field Pay / Flat Rate') +
            '</label><input type="checkbox" id="FDT_FieldPay" data-mini="true"  ' +
            approved +
            '/><label for="FDT_LOC">' +
            iTrans('Location Worked') +
            '</label>' +
            locationSelector +
            '<input type="text" id="FDT_LOC" value="' +
            escapeHtml(event[plugin.settings.LOC]) +
            '" ' +
            approved +
            '/></td></tr>';
        } else {
          html +=
            '<td>' +
            SickDay +
            '<label for="FDT_FieldPay">' +
            iTrans('Field Pay / Flat Rate') +
            '</label><input type="checkbox" id="FDT_FieldPay" checked ' +
            approved +
            '/><label for="FDT_LOC">' +
            iTrans('Location Worked') +
            '</label>' +
            locationSelector +
            '<input type="text" id="FDT_LOC" value="' +
            escapeHtml(event[plugin.settings.LOC]) +
            '" ' +
            approved +
            '/></td></tr>';
        }

        //html += '<tr><td></td><td></td></tr>';

        html += '<tr><td><div data-role="fieldcontain"><label for="Start">' + iTrans('Start') + '</label>';
        html +=
          '<input id="Start" onChange="validateTime(this.value,this.id,true);FDT_UpdateWorkStats();" type="number" value="' +
          event[plugin.settings.Start] +
          '"  ' +
          approved +
          '/></div></td>';

        html += '<td><div data-role="fieldcontain"><label for="End">' + iTrans('End') + '</label>';
        html +=
          '<input id="End"  onChange="validateTime(this.value,this.id,true);FDT_UpdateWorkStats();" type="number" value="' +
          event[plugin.settings.End] +
          '"  ' +
          approved +
          '/></div></td></tr>';
        html += '<tr><td><div data-role="fieldcontain"><label for="FT">' + iTrans('Flight') + '</label>';
        html +=
          '<input id="FT" type="number" value="' +
          event[plugin.settings.FT] +
          '"  ' +
          approved +
          '/></div>' +
          AverageFlightDuration +
          '</td>';

        html += '<td><label for="Split">' + iTrans('Split Duty / Overnights') + '</label>';

        if (Split == 1) {
          html += '<input type="checkbox" id="Split" data-mini="true" checked="checked"  ' + approved + '/></td></tr>';
          html +=
            '<tr><td><div id="E1" data-role="fieldcontain"><label for="Start2">' +
            iTrans('Start') +
            '2</label><input id="Start2" onChange="validateTime(this.value,this.id,true);FDT_UpdateWorkStats();" type="number" value="' +
            event[plugin.settings.Start2] +
            '"  ' +
            approved +
            '/></div></td>';
          html +=
            '<td><div id="E2" data-role="fieldcontain"><label for="End2">' +
            iTrans('End') +
            '2</label><input id="End2" onChange="validateTime(this.value,this.id,true);FDT_UpdateWorkStats();" type="number" value="' +
            event[plugin.settings.End2] +
            '"  ' +
            approved +
            '/></div></td></tr>';
          html += '<tr><td><div id="E3" data-role="fieldcontain"><label for="FT2">' + iTrans('Flight') + ' 2</label>';
          html +=
            '<input id="FT2" type="number" value="' + event[plugin.settings.FT2] + '"  ' + approved + '/></div></td>';
          //html += '<td><div id="E4"><label for="Split2">' + iTrans("Split Duty") + '</label><input id="Split2" type="checkbox" data-mini="true" ' +((event[plugin.settings.Split2] == 1) ? 'checked': '') + '/></div></td>';
        } else {
          html += '<input type="checkbox" id="Split" data-mini="true"  ' + approved + '/></td></tr>';
          html +=
            '<tr><td><div id="E1" data-role="fieldcontain" style="display:none;"><label for="Start2">' +
            iTrans('Start') +
            '2</label><input id="Start2" onChange="validateTime(this.value,this.id,true);FDT_UpdateWorkStats();" type="number" value="' +
            event[plugin.settings.Start2] +
            '"  ' +
            approved +
            '/></div></td>';
          html +=
            '<td><div id="E2" data-role="fieldcontain" style="display:none;"><label for="End2">' +
            iTrans('End') +
            '2</label><input id="End2" onChange="validateTime(this.value,this.id,true);FDT_UpdateWorkStats();" type="number" value="' +
            event[plugin.settings.End2] +
            '"  ' +
            approved +
            '/></div></td></tr>';
          html +=
            '<tr><td><div id="E3" data-role="fieldcontain" style="display:none;"><label for="FT2">' +
            iTrans('Flight') +
            ' 2</label>';
          html +=
            '<input id="FT2" type="number" value="' + event[plugin.settings.FT2] + '"  ' + approved + '/></div></td>';
        }

        html += '<td><div data-role="fieldcontain"><label for="DFR">DFR#</label>';
        html +=
          '<input type="text" id="DFR" data-mini="true" value="' +
          event[plugin.settings.DFR] +
          '"  ' +
          approved +
          '/></div></td></tr>';

        html +=
          '<tr><td><label for="FDT_Overtime">' +
          iTrans('Overtime') +
          ' Hrs</label><input type="number" id="FDT_Overtime" value="' +
          event[plugin.settings.Overtime] +
          '" onChange="validateHours(this);FDT_UpdateWorkStats();"  ' +
          approved +
          '/></td><td><label for="FDT_Unpaid">' +
          iTrans('Unpaid') +
          ' Hrs</label><input type="number" id="FDT_Unpaid" value="' +
          event[plugin.settings.Unpaid] +
          '" onChange="validateHours(this);FDT_UpdateWorkStats();" ' +
          approved +
          '/></td></tr>';

        html += '</table><br />';
        html += '<div id="FDT_WorkStats"></div><br />';
        html += '<label for="Comment">' + iTrans('Comment') + '</label>';
        html += '<textarea id="Comment" ' + approved + '>' + event[plugin.settings.Comment] + '</textarea>';

        if (event[plugin.settings.AdminComment] != '') {
          html +=
            '<div class="yellowbg"><b>' +
            iTrans('Admin Comment') +
            ': </b>' +
            event[plugin.settings.AdminComment] +
            '</div>';
        }
        //todo modify billing and invoicing to include search for crew and support staff names
        if (GROUP_DATA.FlightReports == 1) {
          ////console.log(refreshDATE);

          var Sdate = GetCurFDT_Date_String();

          html += '<div id="TodayBillingData"></div>';

          html += '<div id="TodayBillingJobNums">';
          var JobNumSearch =
            '<label for="TextBillingJobNum">' +
            iTrans('Lookup Job Number') +
            '</label><input type="text" placeholder="' +
            iTrans('Type here to search for job') +
            '..." id="TextBillingJobNum" data-mini="true"  ' +
            approved +
            '/>';
          if (event[plugin.settings.JobNums] != '') {
            FDT_JOBNUMS = JSON.parse(event[plugin.settings.JobNums]);
            html += FDT_MakeJobNumTable();
          } else {
            FDT_JOBNUMS = [];
            html += '<h3>' + iTrans('No Job Numbers For This Date') + '</h3>';
          }
          html += '</div>' + JobNumSearch;
          html += '<div id="FDT_ExpenseView"></div><br />';
        }

        html += '<button id="Close_Edit" data-mini="true">' + iTrans('Done') + '</button>';
        html +=
          '<table><tr><td><button id="Reset_Rec" data-mini="true" class="redbg" ' +
          approved +
          '>' +
          iTrans('Reset Day') +
          '</button></td>';

        if (!lastDay) {
          html +=
            '<td><button id="Close_Edit_Next" data-mini="true" class="greenbg">' +
            iTrans('Save and Next') +
            '</button></td></tr></table>';
        }

        if (!REC_EDITED) {
          $listItem.html(html);
        } //Add HTML and then listeners

        if (event[plugin.settings.End] == '2359') {
          $('#E1').hide();
          $('#E2').hide();
          $('#E3').hide();
          $('#E4').hide();
          $('#FDT_Reserve2HLDR').hide();
          $('#Start2').val(0);
          $('#End2').val(0);
          $('#FT2').val(0);
          $('#Split2').prop('checked', false);
        }
        if (
          (event[plugin.settings.Rule] == 135 && ExtendON == 0) ||
          event[plugin.settings.Rule] == 131 ||
          (event[plugin.settings.Rule] == 133 && ExtendON == 0)
        ) {
          if (!REC_EDITED) {
            addRoll24();
          }
        }
        if (!REC_EDITED) {
          FDT_UpdateWorkStats();
          pop_FDT_IdentList(approved);
        }
      }

      if (GROUP_DATA.FlightReports == 1 && !FDT_ADMIN_UPDATE) {
        FDT_LoadExpenses(refreshDATE);
        //give the FDT calendar time to update changes before adding autocomplete.  Sometimes it wasn't initializing so I'm trying this.
        $('#TextBillingJobNum').autocomplete({
          select: FDTJobNumAutoCompleteSelect,
          source: FDTJobNumAutoComplete,
          position: { my: 'left bottom-40' },
          collision: 'fit',
        });
      }

      if (!REC_EDITED) {
        //$("#FDTIDENT-button").html("<span>"+event[plugin.settings.IDENT]+"</span>");
        //$("#FDTIDENT").selectmenu('refresh',true);
        var type = LOCALSTORAGE.TimeInput;
        if (type == undefined) {
          type = 'Clockpicker';
        }

        if (type == 'Clockpicker') {
          $('#Start').clockpicker({
            autoclose: true,
          });
          $('#End').clockpicker({
            autoclose: true,
            align: 'right',
          });
          //if (Rule == 135 && Extend == 1 || Rule != 135) {
          $('#FT').clockpicker({
            autoclose: true,
            decimal: true,
          });
          //}
          $('#Start2').clockpicker({
            autoclose: true,
          });
          $('#End2').clockpicker({
            autoclose: true,
            align: 'right',
          });
          $('#FT2').clockpicker({
            autoclose: true,
            decimal: true,
          });

          $('#FDT_Overtime').clockpicker({
            autoclose: true,
            decimal: true,
          });
          $('#FDT_Unpaid').clockpicker({
            autoclose: true,
            decimal: true,
          });

          $('#Start').focus(ClockPickerBlur);
          $('#End').focus(ClockPickerBlur);
          $('#FT').focus(ClockPickerBlur);
          $('#Start2').focus(ClockPickerBlur);
          $('#End2').focus(ClockPickerBlur);
          $('#FT2').focus(ClockPickerBlur);
          $('#FDT_Overtime').focus(ClockPickerBlur);
          $('#FDT_Unpaid').focus(ClockPickerBlur);
        } else {
          if (type == 'Keyboard') {
            $('#Start').focus(ClockPickerFocus);
            $('#End').focus(ClockPickerFocus);
            $('#FT').focus(ClockPickerFocus);
            $('#Start2').focus(ClockPickerFocus);
            $('#End2').focus(ClockPickerFocus);
            $('#FT2').focus(ClockPickerFocus);
            $('#FDT_Overtime').focus(ClockPickerFocus);
            $('#FDT_Unpaid').focus(ClockPickerFocus);
          }
          if (type == 'Keypad') {
            $.fn.numpad.defaults.gridTpl = '<table class="numpad"></table>';
            $.fn.numpad.defaults.backgroundTpl = '<div class="ui-popup-screen ui-overlay-a"></div>';
            $.fn.numpad.defaults.displayTpl = '<input data-theme="b" type="text"/>';
            $.fn.numpad.defaults.buttonNumberTpl = '<button data-role="button" data-theme="a"></button>';
            $.fn.numpad.defaults.buttonFunctionTpl = '<button data-role="button" data-theme="a"></button>';
            $.fn.numpad.defaults.onKeypadCreate = function () {
              $(this).enhanceWithin();
            };
            $('#Start').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: true,
            });
            $('#End').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: true,
            });
            $('#FT').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: false,
            });
            $('#Start2').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: true,
            });
            $('#End2').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: true,
            });
            $('#FT2').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: false,
            });
            $('#FDT_Overtime').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: false,
            });
            $('#FDT_Unpaid').numpad({
              hidePlusMinusButton: true,
              hideDecimalButton: false,
            });
          }
        }

        function ClockPickerFocus() {
          this.select();
        }

        function ClockPickerBlur() {
          this.blur();
        }
        if (Rule == 4) {
          $('#DayOff').click(dataChange);
        }
        $('#Split').click(dataChange);
        $('#Split2').click(dataChange);
        $('#Overnight').click(dataChange);
        $('#Start').change(dataChange);
        $('#End').change(dataChange);
        $('#Start2').change(dataChange);
        $('#End2').change(dataChange);
        $('#FT').change(dataChange);
        $('#FT2').change(dataChange);
        $('#DFR').change(dataChange);
        $('#Comment').change(dataChange);
        $('#Close_Edit').click(function () {
          directSaveEdit('Exit');
        }); //Done Btn
        $('#Edit_Rec').click(openEdit); //Update Btn
        $('#Reset_Rec').click(ResetDay); //Reset Day Btn
        if (!lastDay) {
          $('#Close_Edit_Next').click(closeEditNext); //Save and Next Btn
          $('#FDTNextDay').click(closeEditNext);
        }
        $('#FDTPrevDay').click(closeEditPrev);
        id_Rec = event[plugin.settings.id];

        if (userShouldHaveFDT(USERTYPE)) {
          $('#Extend').click(dataChange);
          if (
            event[plugin.settings.Rule] == 702 ||
            event[plugin.settings.Rule] == 703 ||
            event[plugin.settings.Rule] == 704 ||
            event[plugin.settings.Rule] == 705 ||
            event[plugin.settings.Rule] == 604
          ) {
            $('#FDTNormal').click(dataChange);
            $('#FDTStandby').click(dataChange);
            $('#FDTOnReserve').click(dataChange);
            $('#FDTOnCall').click(dataChange);
            $('#FDTNormal2').click(dataChange);
            $('#FDTStandby2').click(dataChange);
            $('#FDTOnReserve2').click(dataChange);
            $('#FDTOnCall2').click(dataChange);
            $('#FDT_UOC').click(dataChange);
            if (event[plugin.settings.Rule] == 705) {
              $('#FDT_AFD0').click(dataChange);
              $('#FDT_AFD30').click(dataChange);
              $('#FDT_AFD50').click(dataChange);
            }
          }
          if (event[plugin.settings.Rule] == 2) {
            $('#FDTNormal2').click(dataChange);
            $('#FDTStandby2').click(dataChange);
          }

          Extern = event[plugin.settings.EL];
          $('#FDT_Sectors').change(dataChange);
          $('#FDT_CrewCount').change(dataChange);
          $('#FDT_RestType').change(dataChange);
          $('#FDT_FCP').change(dataChange);
          $('#FDT_LRF').click(dataChange);
          $('#FDT_TOF').click(dataChange);
          $('#FDT_CROFD').change(dataChange);
        } else {
          $('#FDT_FieldPay').click(dataChange);
          $('#FDT_SickDay').click(dataChange);
        }
      }
      //if (event[plugin.settings.Heli] == 1) $("#External").click(dataChange);

      if (EDITON) {
        openEdit();
        EDITON = false;
      }
      if (FDT_REPORT) {
        FDT_REPORT = false;
      }

      if (FDT_REPORT) {
        //console.log("Auto Populate and Display FDT Record");
        //console.log(FDT_REPORT);
        openEdit();
        var Rstart = '0000';
        var Rend = '0000';
        var Rstart2 = '0000';
        var Rend2 = '0000';
        var Rextra = '';
        var TotalFT = 0;
        var EndLoop = false;
        var BlockTime = false;
        var FT1 = 0;
        var FT2 = 0;
        //Check to make sure these setting exist.  maybe upgraded and then first app start was offline and couldn't retrieve new settings
        if (GROUP_DATA.FDT_StartBuff == undefined) {
          GROUP_DATA.FDT_StartBuff = 0.5;
          GROUP_DATA.FDT_EndBuff = 0.5;
        }
        for (var l in FDT_REPORT.Legs) {
          var L = FDT_REPORT.Legs[l];
          var FDTft;
          var FDTft2;
          if (L.Time_Start == '') {
            L.Time_Start = '0';
          }
          if (L.Time_Up == '') {
            L.Time_Up = '0';
          }
          if (L.Time_Down == '') {
            L.Time_Down = '0';
          }
          if (L.Time_Stop == '') {
            L.Time_Stop = '0';
          }
          if (L.Time_Start == 0 && L.Time_Stop == 0) {
            BlockTime = false;
          }
          if (L.Time_Start == 0 && L.Time_Up != 0) {
            L.Time_Start = L.Time_Up;
          }
          if (L.Time_Stop == 0 && L.Time_Down != 0) {
            L.Time_Stop = L.Time_Down;
          }

          if (l == 0) {
            if (parseInt(L.Time_Start) > 30) {
              Rstart = subDecToTime(L.Time_Start, parseFloat(GROUP_DATA.FDT_StartBuff));
            } else {
              Rstart = 0;
            }

            if (parseInt(L.Time_Stop) < 2329) {
              Rend = subDecToTime(L.Time_Stop, -parseFloat(GROUP_DATA.FDT_EndBuff));
            } else {
              Rend = 2359;
            }
          } else {
            console.log('PrevStop: ' + FDT_REPORT.Legs[parseInt(l) - 1].Time_Stop);
            console.log('Start: ' + L.Time_Start);
            var PrevLegStop = parseInt(FDT_REPORT.Legs[parseInt(l) - 1].Time_Stop);
            console.log(dutytime_Calc(PrevLegStop, parseInt(L.Time_Start)));

            if (dutytime_Calc(PrevLegStop, parseInt(L.Time_Start)) >= 9) {
              console.log('SetRstart2');
              Rstart2 = L.Time_Up;
            }

            if (Rstart2 == '0000') {
              if (parseInt(L.Time_Stop) < 2329) {
                Rend = subDecToTime(L.Time_Stop, -parseFloat(GROUP_DATA.FDT_EndBuff));
              } else {
                Rend = 2359;
              }
              if (parseInt(L.Time_Start) > parseInt(L.Time_Stop)) {
                L.Time_Stop = '2359';
                Rend = '2359';
                EndLoop = true;
              }
            } else {
              if (parseInt(L.Time_Stop) < 2329) {
                Rend2 = subDecToTime(L.Time_Stop, -parseFloat(GROUP_DATA.FDT_EndBuff));
              } else {
                Rend2 = 2359;
              }
              if (parseInt(L.Time_Start) > parseInt(L.Time_Stop)) {
                L.Time_Stop = '2359';
                Rend2 = '2359';
                EndLoop = true;
              }
            }
          }
          if (Rstart2 == '0000') {
            FT1 += dutytime_Calc(parseInt(L.Time_Up), parseInt(L.Time_Down));
          } else {
            FT2 += dutytime_Calc(parseInt(L.Time_Up), parseInt(L.Time_Down));
          }

          FDTft = dutytime_Calc(parseInt(L.Time_Start), parseInt(L.Time_Stop));
          TotalFT += FDTft;
          if (l == FDT_REPORT.Legs.length - 1 || EndLoop) {
            Rextra += '{"Start":"' + L.Time_Start + '", "FT":"' + FDTft + '"}';
          } else {
            Rextra += '{"Start":"' + L.Time_Start + '", "FT":"' + FDTft + '"},';
          }

          if (EndLoop) {
            break;
          }
        }
        if (FDT_REPORT.FR.HobbsHours != 0) {
          TotalFT = FDT_REPORT.FR.HobbsHours;
        }
        TotalFT = Math.round(TotalFT * 10) / 10;

        //console.log(Rstart);
        //console.log(Rend);

        ROLL24DATA = '{"FDT135":[' + Rextra + ']}';
        var totalFlt = FDT_REPORT.TOTAL;
        totalFlt = round10(totalFlt);
        if (Rstart2 == '0000') {
          if (
            ($('#Start').val() == 0 && $('#End').val() == 0 && !$('#Split').is(':checked')) ||
            (parseInt($('#End').val()) >= parseInt(Rstart) && parseInt($('#End').val()) >= parseInt(Rend)) ||
            $('#End').val() == '2359'
          ) {
            $('#Start').val(Rstart);
            $('#End').val(Rend);
            $('#FT').val(totalFlt);
            Split = 0;
          } else {
            if (parseInt($('#End').val()) > parseInt(Rstart)) {
              Rstart = $('#End').val();
            }
            if (parseInt($('#End').val()) > parseInt(Rend)) {
              Rend = $('#End').val();
            }
            $('#Split').prop('checked', true);
            $('#Start2').val(Rstart);
            $('#End2').val(Rend);
            $('#FT2').val(totalFlt);
            $('#E1').show();
            $('#E2').show();
            $('#E3').show();
            $('#E4').show();
            $('#FDT_Reserve2HLDR').show();
            Split = 1;
          }
        } else {
          //8 hours off middle of day flight report import
          $('#Start').val(Rstart);
          $('#End').val(Rend);
          $('#FT').val(FT1);
          $('#Split').prop('checked', true);
          $('#Start2').val(Rstart2);
          $('#End2').val(Rend2);
          $('#FT2').val(FT2);
          $('#E1').show();
          $('#E2').show();
          $('#E3').show();
          $('#E4').show();
          $('#FDT_Reserve2HLDR').show();
          Split = 1;
        }
        if (userShouldHaveFDT(USERTYPE)) {
          if (
            (event[plugin.settings.Rule] == 135 && ExtendON == 0) ||
            event[plugin.settings.Rule] == 131 ||
            (event[plugin.settings.Rule] == 133 && ExtendON == 0)
          ) {
            if (!REC_EDITED) {
              addRoll24();
            }
          }
        }

        //trigger record save and sync
        EDITON = true;
        REC_EDITED = true;
        update_Sync('LastFDTsync', new Date());
        NewFDTrec_IDENT('');
        setTimeout(function (e) {
          saveEdit('save');
          //Add save timeout so Experience data has enought time to save if the user happens to hit the back button.
        }, 250);
      } else {
        populate_FDT_Rec(0);
        REC_EDITED = false;
      }
      if (GROUP_DATA.FlightReports == 1 && !REC_EDITED && !FDT_ADMIN_UPDATE) {
        populateFlightReportDetails(Sdate);
      }

      //end displayRecord()
    }

    var id_Rec, Split, Split2, Extend, Extern, ResetFT, ExtendAvail, ViolationON, prevSplit; //global

    function dataChange(e) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.dataChange', '2024-06-26', 'bldarosa');
      $('#exitDutyTimes').prop('disabled', true);
      $('#FDTPrevDay').prop('disabled', true);
      $('#FDTNextDay').prop('disabled', true);
      $('#Close_Edit').prop('disabled', true);
      $('#Close_Edit_Next').prop('disabled', true);
      //console.log(e);
      EDITON = true;
      REC_EDITED = true;
      var field = e.currentTarget.id;

      if (
        field == 'FDTNormal' ||
        field == 'FDTStandby' ||
        field == 'FDTOnReserve' ||
        field == 'FDTOnCall' ||
        field == 'FDTNormal2' ||
        field == 'FDTStandby2' ||
        field == 'FDTOnReserve2' ||
        field == 'FDTOnCall2'
      ) {
        var Standby1 = parseInt($('input[name=FDT_Reserve]:checked').val());
        if (Rule != 2) {
          if (Standby1 != 0 && Standby1 != 10 && Standby1 != 2) {
            $('#FT').textinput('disable');
            $('#FT').val('0');
          } else {
            $('#FT').textinput('enable');
          }

          var Standby2 = parseInt($('input[name=FDT_Reserve2]:checked').val()) * 3;
          if (Standby2 != 0 && Standby2 != 33 && Standby2 != 6 && Standby2 != 30) {
            $('#FT2').textinput('disable');
            $('#FT2').val(0);
          } else {
            $('#FT2').textinput('enable');
          }

          Standby = Standby1 + Standby2;
        }
      }
      if (Rule == 2) {
        //aussie rest time stuff
        Standby = parseInt($('input[name=FDT_Reserve2]:checked').val());
      }
      var maxTime = parseFloat($('#FT').val()) + parseFloat($('#FT2').val());
      maxTime = Math.round(maxTime * 10) / 10;
      if (Rule == '700') {
        maxTime = 24;
      }

      if (field == 'External') {
        if (e.currentTarget.checked) {
          Extern = 1;
        } else {
          Extern = 0;
        }
      }

      if (field == 'Split') {
        if (e.currentTarget.checked) {
          if ($('#End').val() != '2359') {
            $('#E1').show();
            $('#E2').show();
            $('#E3').show();
            if ($('#Overnight').is(':checked')) $('#E4').show();
            else $('#E4').hide();
            $('#FDT_Reserve2HLDR').show();
          }
          Split = 1;
        } else {
          $('#E1').hide();
          $('#E2').hide();
          $('#E3').hide();
          $('#E4').hide();
          $('#FDT_Reserve2HLDR').hide();
          Split = 0;
          Split2 = 0;
          $('#Start2').val(0);
          $('#End2').val(0);
          $('#FT2').val(0);
          $('#Split2').prop('checked', false);
        }
      }
      if (field == 'Split2') {
        if (e.currentTarget.checked) {
          Split2 = 1;
        } else {
          Split2 = 0;
        }
      }
      if (field == 'Extend') {
        if (e.currentTarget.checked) {
          Extend = 1;
        } else {
          Extend = 0;
        }
      }

      function validateTime(e, id) {
        if (e.length > 4) {
          $('#' + id).val('0000');
          $('#' + id).focus();
          return false;
        }
        if (e.length == 0) {
          $('#' + id).val('0000');
          return;
        }
        if (e < 0) {
          $('#' + id).val('0000');
          $('#' + id).focus();
          return false;
        }
        //console.log(e.length);
        if (e.length == 4) {
          //console.log(e.substring(2, 4));
          //console.log(e.substring(0, 2));
          $('#' + id).val(validateF2(e.substring(0, 2)) + validateL2(e.substring(2, 4)));
        }
        if (e.length == 3) {
          $('#' + id).val(validateF2(e.substring(0, 1)) + validateL2(e.substring(1, 3)));
        }
        if (e.length == 2 || e.length == 1) {
          $('#' + id).val('00' + validateL2(e));
        }
        return true;
      }

      function validateF2(F2) {
        //console.log(F2);
        if (F2 > 23) {
          return '23';
        } else if (F2 < 10) {
          return '0' + parseInt(F2);
        } else if (F2 == 0) {
          return '00';
        } else {
          return F2.toString();
        }
      }

      function validateL2(L2) {
        //console.log(L2);
        if (L2 > 59) {
          return '59';
        } else if (L2 < 10 && L2 != 0) {
          return '0' + parseInt(L2);
        } else if (L2 == 0) {
          return '00';
        } else {
          return L2.toString();
        }
      }

      //todo add field validation prior to live updating
      var Start, End, Diff;
      if (field == 'Start' || field == 'Start2') {
        //validate start time string
        //ensure end time is after start time
        if (field == 'Start') {
          Start = $('#Start').val();
          if (!validateTime(Start, 'Start')) {
            return;
          }
          End = $('#End').val();
          if (End == '0000' || End == '0' || End == '') {
            $('#End').val($('#Start').val());
            if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
              $('#End').clockpicker('show');
            } else {
              $('#End').focus();
            }
            return;
          }
          Diff = dutytime_Calc(Start, End);
          $('#Start').blur();
          if (Diff < 0) {
            $('#End').val(Start);
            $('#End').focus();
            return;
          }
          if (LOCALSTORAGE.TimeInput != 'Keypad') {
            $('#End').focus();
          }
          //else $("#End").click();
          ROLL_MIN = Start;
          //return; //do not save changes
        }
        if (field == 'Start2') {
          Start = $('#Start2').val();
          if (!validateTime(Start, 'Start2')) {
            return;
          }
          Diff = dutytime_Calc($('#End').val(), Start);
          if (Diff < 0) {
            $('#Start2').val($('#End').val());
            $('#Start2').focus();
            return;
          }
          if (End == '0000' || End == '0' || End == '') {
            $('#End2').val($('#Start2').val());
            if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
              $('#End2').clockpicker('show');
            } else {
              $('#End2').focus();
            }
            return;
          }
          End = $('#End2').val();
          Diff = dutytime_Calc(Start, End);
          $('#Start2').blur();
          if (Diff < 0) {
            $('#End2').val(Start);
            $('#End2').focus();
            return;
          }
          if (LOCALSTORAGE.TimeInput != 'Keypad') {
            $('#End2').focus();
          }
          //else $("#End2").click();
          //return; //do not save changes
        }
      }
      if (field == 'End' || field == 'End2') {
        //validate end time string
        if (field == 'End') {
          Start = $('#Start').val();
          End = $('#End').val();
          //If End of non split at 2359 don't allow for split duty to be entered
          //console.log(End);
          if (End == '2359') {
            $('#E1').hide();
            $('#E2').hide();
            $('#E3').hide();
            $('#E4').hide();
            $('#FDT_Reserve2HLDR').hide();
            $('#Start2').val(0);
            $('#End2').val(0);
            $('#FT2').val(0);
            $('#Split2').prop('checked', false);
          } else {
            if (Split == 1) {
              $('#E1').show();
              $('#E2').show();
              $('#E3').show();
              $('#E4').show();
              $('#FDT_Reserve2HLDR').show();
            }
          }
          if (parseInt(End) < parseInt(Start)) {
            if (prevSplit === false) {
              $('#Start').val(End);
              Start = End;
            } else {
              if (Start < parseInt(prevSplit)) {
                $('#Start').val(prevSplit);
                Start = prevSplit;
              }
            }
          }

          if (!validateTime(End, 'End')) {
            return;
          }
          Diff = dutytime_Calc(Start, End);
          $('#End').blur();
          if (Diff < 0 && prevSplit === false) {
            $('#End').val(Start);
            if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
              $('#End').clockpicker('show');
            } else {
              $('#End').focus();
            }
            return; //do not save changes
          }
          if (Diff < 0 && prevSplit !== false) Diff = Diff + 24;
          if (parseFloat($('#FT').val()) > Diff && prevSplit === false) {
            $('#FT').val(Diff);
            if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
              $('#FT').clockpicker('show');
            } else {
              $('#FT').focus();
            }
            //return; //do not save changes
          }
        }
        //ensure end time is not before start time
        if (field == 'End2') {
          Start = $('#Start2').val();
          End = $('#End2').val();
          if (parseInt(End) < parseInt(Start)) {
            $('#Start').val(End);
            Start = End;
          }
          if (!validateTime(End, 'End2')) {
            return;
          }
          Diff = dutytime_Calc(Start, End);
          $('#End2').blur();
          if (Diff < 0) {
            $('#End2').val(Start);
            if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
              $('#End2').clockpicker('show');
            } else {
              $('#End2').focus();
            }
            return; //do not save changes
          }
          if (parseFloat($('#FT2').val()) > Diff) {
            $('#FT2').val(Diff);
            if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
              $('#FT2').clockpicker('show');
            } else {
              $('#FT2').focus();
            }
            return; //do not save changes
          }
        }
      }
      if (userShouldHaveFDT(USERTYPE)) {
        //Only check flight time max to duty for pilots in case support staff can claim if resting
        if (field == 'FT' || field == 'FT2') {
          //check that flight time is not larter than duty time
          if (Rule == '700') {
            $('#FT2').val('0');
            if ($('#FT').val() == '' || parseFloat($('#FT').val()) < 0) {
              $('#FT').val('0');
              return;
            }
            if (parseFloat($('#FT').val()) > 24) {
              $('#FT').val('24');
              //return;
            }
          } else {
            if (field == 'FT') {
              Start = $('#Start').val();
              End = $('#End').val();
              Diff = dutytime_Calc(Start, End);
              if (Diff < 0 && prevSplit !== false) Diff = Diff + 24;
              if ($('#FT').val() == '' || parseFloat($('#FT').val()) < 0) {
                $('#FT').val('0');
                //return;
              }
              if (parseFloat($('#FT').val()) > Diff) {
                $('#FT').val(Diff);
                if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
                  $('#FT').clockpicker('show');
                } else {
                  $('#FT').focus();
                }
                //return; //do not save changes
              }
              $('#FT').blur();
            }
            if (field == 'FT2') {
              Start = $('#Start2').val();
              End = $('#End2').val();
              Diff = dutytime_Calc(Start, End);
              if ($('#FT2').val() == '' || parseFloat($('#FT2').val()) < 0) {
                $('#FT2').val('0');
                return;
              }
              if (parseFloat($('#FT2').val()) > Diff) {
                $('#FT2').val(Diff);
                if (LOCALSTORAGE.TimeInput == 'Clockpicker') {
                  $('#FT2').clockpicker('show');
                } else {
                  $('#FT2').focus();
                }
                //return; //do not save changes
              }
              $('#FT2').blur();
            }
          }
        }
        if ($('#FT').val() > 0 || ($('#FT2').val() > 0 && $('#FDT_Sectors').val() == 0)) $('#FDT_Sectors').val(1);
      }
      if (field == 'FDT_LRF') {
        if ($('#FDT_LRF').is(':checked')) {
          $('#FDT_TOF').prop('disabled', false).checkboxradio('refresh');
        } else {
          $('#FDT_TOF').prop('checked', false);
          $('#FDT_TOF').prop('disabled', true).checkboxradio('refresh');
        }
      }
      if (Rule == '705') {
        if (
          ($('#FT').val() != 0 && $('#FDT_CrewCount').val() == 0) ||
          ($('#FT2').val() != 0 && $('#FDT_CrewCount').val() == 0)
        )
          $('#FDT_CrewCount').val(1);
        else $('#FDT_CrewCount').val(0);
      }
      ////console.log(e);
      ////console.log("id_Rec: "+id_Rec);

      // Done Btn Code here
      //end data change
      update_Sync('LastFDTsync', new Date());
      EnableExitControls();
      saveEdit('save'); //automatically save changed data silently
    }

    var directSaveEdit_Timeout = null;

    function EnableExitControls() {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.EnableExitControls', '2024-06-26', 'bldarosa');
      $('#exitDutyTimes').prop('disabled', false);
      $('#FDTPrevDay').prop('disabled', false);
      $('#Close_Edit').prop('disabled', false);
      if (
        refreshDATE.getFullYear() !== today.getFullYear() ||
        refreshDATE.getMonth() !== today.getMonth() ||
        refreshDATE.getDate() !== today.getDate()
      ) {
        $('#FDTNextDay').prop('disabled', false);
        $('#Close_Edit_Next').prop('disabled', false);
      }
    }

    function directSaveEdit(EditAction) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.directSaveEdit', '2024-06-26', 'bldarosa');
      if (directSaveEdit_Timeout != null) {
        return;
      }
      $.mobile.loading('show', {
        theme: 'a',
      });

      directSaveEdit_Timeout = setTimeout(function () {
        saveEdit(EditAction);
      }, 200);
    }

    function saveEdit(EditAction) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.saveEdit', '2024-06-26', 'bldarosa');
      var Sdate = GetCurFDT_Date_String();
      var record = {};
      record.Date = Sdate;
      if (REC_EDITED) {
        record.Modified = dateToStringObject(new Date());
      }
      //record.Modified = dateToStringObject(new Date());
      record.IDENT = $('#FDTIDENT').val();
      record.DFR = $('#DFR').val();
      record.Comment = $('#Comment').val();

      record.Start = $('#Start').val();
      record.End = $('#End').val();
      if (Split == 1) {
        record.FT2 = $('#FT2').val();
        record.Start2 = $('#Start2').val();
        record.End2 = $('#End2').val();
      } else {
        //save to database but do not update input fields in case user toggles the split time by mistake
        record.FT2 = 0;
        record.Start2 = 0;
        record.End2 = 0;
      }
      record.FT = $('#FT').val();
      //eliminate problems early by checking for unidentified ghosts that have cause historic sync problems
      if (record.FT == undefined || record.Start == undefined) {
        if (directSaveEdit_Timeout != null) {
          directSaveEdit_Timeout = null;
          $.mobile.loading('hide');
        }
        console.log('Invalid FDT Rec Detected');
        return;
      }
      //Checks Done

      if (Split == 0 && Split2 == 0) record.Split = 0;
      else if (Split == 1 && Split2 == 0) record.Split = 1;
      else if (Split == 0 && Split2 == 1) record.Split = 2;
      else if (Split == 1 && Split2 == 1) record.Split = 3;
      else record.Split = 0;
      if (userShouldHaveFDT(USERTYPE)) {
        record.Rule = $('#SELECT_Rule').val();
        record.Rule_ID = Rule_ID;
        if ($('#Overnight').is(':checked')) {
          record.Overnight = 1;
        } else {
          record.Overnight = 0;
        }
        record.Timezone = CurrentTimeZone;
        if (FDT_ADMIN_UPDATE) record.Timezone = CurrentRecTimeZone;
        if (GROUP_DATA.PULSAR_FRMS == 1 || record.Rule == 705) {
          record.begLat = $('#FDT_begLat').val();
          record.begLong = $('#FDT_begLong').val();
          record.endLat = $('#FDT_endLat').val();
          record.endLong = $('#FDT_endLong').val();
          record.Sectors = $('#FDT_Sectors').val();
          record.CrewCount = $('#FDT_CrewCount').val();
          record.ET1 = $('#FDT_FCP').val();
          record.EC4 = 0;
          if ($('#FDT_LRF').is(':checked')) {
            record.EC4 = 1;
          }
          if ($('#FDT_TOF').is(':checked')) {
            record.EC4 = 2;
          }
          record.EC5 = $('#FDT_CROFD').val();
          if (record.CrewCount < 1) {
            record.CrewCount = 1;
          }
          record.restType = $('#FDT_RestType').val();
        }
        if (record.Rule == 705) {
          record.ET2 = parseInt($('input[name=FDT_AFD]:checked').val());
        } else {
          record.ET2 = 0;
        }

        record.EC1 = 0;
        if ($('#FDT_UOC').is(':checked')) {
          record.EC1 = 1;
        }
        record.EC2 = 0;
        if ($('#FDT_RES_Notice').is(':checked')) {
          record.EC2 = 1;
        }
        record.EC3 = 0;
        if ($('#FDT_RES_Notice2').is(':checked')) {
          record.EC3 = 1;
        }

        if (record.End != 0 && parseFloat(record.FT) + parseFloat(record.FT2) > 0) {
          $('#AddFDTExperienceBTN').prop('disabled', false);
        }
        if ((record.Rule == '702' || record.Rule == '703' || record.Rule == '704') && !Setting_Edited) {
          var Standby1 = parseInt($('input[name=FDT_Reserve]:checked').val());
          var Standby2 = parseInt($('input[name=FDT_Reserve2]:checked').val());
          if (!isNaN(Standby1) && !isNaN(Standby2)) {
            if (
              (Standby1 != 0 && parseInt($('#Start').val()) == 0 && parseInt($('#End').val()) == 0) ||
              (Standby2 != 0 && parseInt($('#Start2').val()) == 0 && parseInt($('#End2').val()) == 0)
            ) {
              $('<div>').simpledialog2({
                mode: 'button',
                animate: false,
                headerText: iTrans('Standby/Reserve/On Call'),
                headerClose: false,
                buttonPrompt:
                  iTrans('You have selected the Standby, Reserve or OnCall option for this day') +
                  ' .  ' +
                  iTrans('Please enter the start and end times for which you were on Standby/Reserve') +
                  '.',
                buttons: {
                  [iTrans('Enter Times')]: {
                    click: function () {
                      //do nothing
                      var ShowRes = false;
                      var ShowRes2 = false;
                      switch (Standby.toString()) {
                        case '2':
                        case '5':
                          ShowRes = true;
                          break;
                        case '6':
                        case '7':
                          ShowRes2 = true;
                          break;
                        case '8':
                          ShowRes = true;
                          ShowRes2 = true;
                          break;
                        case '19':
                          ShowRes2 = true;
                          break;
                        case '35':
                          ShowRes = true;
                          break;
                      }
                      try {
                        if (ShowRes) {
                          $('#FDT_RES_Notice_HLDR').show();
                        } else {
                          $('#FDT_RES_Notice').prop('checked', false).checkboxradio('refresh');
                          $('#FDT_RES_Notice_HLDR').hide();
                        }
                      } catch (e) {}
                      try {
                        if (ShowRes2) {
                          $('#FDT_RES_Notice_HLDR2').show();
                        } else {
                          $('#FDT_RES_Notice2').prop('checked', false).checkboxradio('refresh');
                          $('#FDT_RES_Notice_HLDR2').hide();
                        }
                      } catch (e) {}
                      REC_EDITED = false;
                    },
                  },
                  [iTrans('Cancel Standby/Reserve')]: {
                    click: function () {
                      try {
                        $('#FDTOnReserve').prop('checked', false).checkboxradio('refresh');
                        $('#FDTStandby').prop('checked', false).checkboxradio('refresh');
                        $('#FDTNormal').prop('checked', true).checkboxradio('refresh');
                        if ($('#FDTOnReserve').is(':checked')) {
                          $('#FDT_RES_Notice').prop('checked', false).checkboxradio('refresh');
                          $('#FDT_RES_Notice_HLDR').hide();
                        }
                        if ($('#FDTOnReserve2').is(':checked')) {
                          $('#FDT_RES_Notice2').prop('checked', false).checkboxradio('refresh');
                          $('#FDT_RES_Notice_HLDR2').hide();
                        }
                        Standby = 0;
                        REC_EDITED = true;
                        saveEdit('save');
                        console.log('saved');
                      } catch (e) {
                        console.log(e);
                      }
                    },
                  },
                },
              });
              if (directSaveEdit_Timeout != null) {
                directSaveEdit_Timeout = null;
                $.mobile.loading('hide');
              }
              return;
            }
          }
        }

        record.EL = 0;
        if (record.Rule == 3 && Heli == 1) {
          if ($('#HeliExternalLoads').is(':checked')) {
            record.EL = 1;
          }
        }
        if (record.Rule == 3 && Heli == 1) {
          if ($('#MCTOW_over_1250').is(':checked')) {
            record.EL = 1;
          }
        }
        if (record.Rule == 4 && $('#DayOff').is(':checked')) record.EL = 1;
        record.Extend = Extend;
        record.Current = 0; //Current will be used for Split2 until future migration
        if (FDT_ADMIN_UPDATE) {
          record.User_PK = FDT_OPK;
        } else {
          record.User_PK = USER_RIGHTS.PrimaryKey;
        }
        record.group = USER_RIGHTS.group;

        //Following variables are global in duty.php
        record.DHC = $('#CHK_DHC').is(':checked') ? 1 : 0;
        record.Dual = $('#CHK_Dual').is(':checked') ? 1 : 0;
        record.Heli = $('#CHK_Heli').is(':checked') ? 1 : 0;
        record.IFR = $('#CHK_IFR').is(':checked') ? 1 : 0;
        record.Logging = $('#CHK_Logging').is(':checked') ? 1 : 0;
        record.AerialApp = $('#CHK_AerialApp').is(':checked') && record.Rule == 702 ? 1 : 0;
        record.Standby = Standby;

        record.Warning_Ack = 0;
        record.Warning = false;
        record.Violation_Ack = 0;
        record.EXTRA = ROLL24DATA;
        record.Violation = false;
        record.Status = '';
        FDT_LAST_IDENT = $('#FDTIDENT_exp').val();
        //SaveFDT_Exp($("#FDT_Exp_INDEX").val(),false); //to save experience edits too!
      } else {
        record.Modified = dateToStringObject(new Date()); //Set modified timestamp all the time for time sheet saves to ensure server sync
        record.JobNums = JSON.stringify(FDT_JOBNUMS);
        FDT_JOBNUMS = [];
        record.idents = FDT_IdentList;
        //record.AdminComment = "";
        record.Overtime = $('#FDT_Overtime').val();
        record.Unpaid = $('#FDT_Unpaid').val();
        var fieldpay = 0;
        if ($('#FDT_FieldPay').is(':checked')) {
          fieldpay = 1;
        }
        record.FieldPay = fieldpay;

        if ($('#FDT_SickDay').is(':checked')) {
          record.SickDay = 1;
        } else {
          record.SickDay = 0;
        }

        //record.Approved = 0;
        record.Cfields = '[]'; //not implemented yet
        record.LOC = $('#FDT_LOC').val();
      }
      //updateFDT_data[record.Date] = record;
      console.log(refreshDATE);
      //console.log(record);
      var table = '';

      if (userShouldHaveFDT(USERTYPE)) {
        table = 'FDT_DaysV4';
      } else {
        table = 'FDT_StaffV3';
      }

      cirroDB.insert(table, record, null, function (e) {
        var done = true;
        console.log('Local Record Updated Successfully');
        console.log(record);
        $('#Duty_Status').html(iTrans('Data: Synchronization Required *'));
        switch (EditAction) {
          case 'NextDay':
            done = false;
            EDITON = true;

            refreshDATE.setDate(refreshDATE.getDate() + 1);
            refresh(new Date(refreshDATE.getFullYear(), refreshDATE.getMonth(), refreshDATE.getDate()));
            if (userShouldHaveFDT(USERTYPE)) {
              if ($('#FDTIDENT_exp').val() == 'No Ident') {
                $('#FDTIDENT_exp').val(FDT_LAST_IDENT);
                //SaveFDT_Exp($("#FDT_Exp_INDEX").val());
              }
            }
            BackgroundSync(true); // to sync potential changes to the server;
            if (directSaveEdit_Timeout != null) {
              directSaveEdit_Timeout = null;
              $.mobile.loading('hide');
            }
            break;
          case 'PrevDay':
            done = false;
            EDITON = true;
            refreshDATE.setDate(refreshDATE.getDate() - 1);
            refresh(new Date(refreshDATE.getFullYear(), refreshDATE.getMonth(), refreshDATE.getDate()));
            BackgroundSync(true); // to sync potential changes to the server;
            if (directSaveEdit_Timeout != null) {
              directSaveEdit_Timeout = null;
              $.mobile.loading('hide');
            }

            break;
          case 'Exit':
            EDITON = false;
            FDT_LAST_IDENT = 'No Ident';
            $('#DutyCalendar').show();

            $('#Summary_HDLR').show();
            $('#Edit_HDLR').hide();
            /*
             $("#calendar").removeClass();
             $("#calendar").html("<h3 class='limit-table'>Syncing Standby...</h3>");
             */

            done = false;
            const xit = $('#exitDutyTimes');
            xit.unbind('click').click(hrefHandler);
            if (!FDT_ADMIN_UPDATE) toggleBackButton(xit, true);
            $('#DutyDefaultsPage').prop('disabled', true);
            BackgroundSync(true); // to sync potential changes to the server;
            if (directSaveEdit_Timeout != null) {
              directSaveEdit_Timeout = null;
              $.mobile.loading('hide');
            }
            $('#DutyCalendar > tbody').html('');

            if (
              (USER_RIGHTS.FDT_NvgCur == 1 && !FDT_ADMIN_UPDATE) ||
              (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)
            ) {
              CheckNVGCurrency(new Date(), true);
            }
            if (
              (USER_RIGHTS.FDT_IfrCur == 1 && !FDT_ADMIN_UPDATE) ||
              (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1)
            ) {
              CheckIFRCurrency(new Date(), true);
            }
            if (
              (USER_RIGHTS.FDT_IfrCur == 1 && !FDT_ADMIN_UPDATE) ||
              (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_IfrCur == 1) ||
              (USER_RIGHTS.FDT_NvgCur == 1 && !FDT_ADMIN_UPDATE) ||
              (FDT_ADMIN_UPDATE && ADMIN_FDT_DATA.CurUser.FDT_NvgCur == 1)
            ) {
              //Add small delay so IFR and/or NVG currency data can be pulled and repopulated
              setTimeout(function (e) {
                refresh(refreshDATE);
              }, 200);
            } else {
              refresh(refreshDATE);
            }

            break;
          case 'save':
            done = false;
            EDITON = true;
            done = false;
            wasSaved = true;
            refresh(refreshDATE); //needs to be triggered to show FDT violations before the sync happens
            break;
          case 'refresh':
            done = false;
            EDITON = true;
            $('#DutyCalendar > tbody').html('');
            done = false;
            break;
        }
      });
    }

    var EDITON = false;
    var EXITON = false;
    var wasSaved = false;

    function closeEditNext(e) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.closeEditNext', '2024-06-26', 'bldarosa');
      directSaveEdit('NextDay');
    }

    function closeEditPrev(e) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.closeEditPrev', '2024-06-26', 'bldarosa');
      directSaveEdit('PrevDay');
    }

    function ResetDay() {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.ResetDay', '2024-06-26', 'bldarosa');
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: iTrans('Confirm Reset'),
        headerClose: false,
        buttonPrompt: '<p>' + iTrans('Are you sure you want to reset this day') + '?</p> ',
        buttons: {
          [iTrans('Yes')]: {
            click: function () {
              $('#Duty_Status').html(iTrans('Data: Synchronization Required *'));
              EDITON = true;
              var record = {};
              var Sdate = GetCurFDT_Date_String();
              record.Date = Sdate;
              //console.log(record.Date);
              record.Modified = dateToStringObject(new Date());
              record.IDENT = 'No Ident';
              record.Start = 0;
              record.End = 0;
              record.FT2 = 0;
              record.Start2 = 0;
              record.End2 = 0;
              record.FT = 0;
              record.DFR = 0;
              record.Comment = '';

              if (userShouldHaveFDT(USERTYPE)) {
                record.Split = 0;
                record.Extend = 0;
                record.Current = 0;
                record.EL = 0;
                record.EC1 = 0;
                record.EC2 = 0;
                record.EC3 = 0;
                record.EC4 = 0;
                record.EC5 = 0;
                record.ET1 = 0;
                record.ET2 = 0;

                record.begLat = 0;
                record.begLong = 0;
                record.endLat = 0;
                record.endLong = 0;
                record.Sectors = 0;
                record.CrewCount = 1;

                //Following variables are global in duty.php
                record.DHC = DHC;
                record.Dual = Dual;
                record.Heli = Heli;
                record.IFR = IFR;
                record.Logging = Logging;
                record.AerialApp = AerialApp;
                record.Standby = Standby;

                record.Overnight = 0;

                record.Rule = $('#SELECT_Rule').val();
                record.Rule_ID = Rule_ID;
                record.Warning_Ack = 0;
                record.Warning = 0;
                record.Violation_Ack = 0;
                record.Violation = 0;
                record.Status = '';
                record.EXTRA = '';
              } else {
                record.JobNums = '[]';
                record.idents = '[]';
                //record.AdminComment = "";
                record.Overtime = 0;
                record.Unpaid = 0;
                record.FieldPay = 0;

                record.SickDay = 0;

                //record.Approved = 0;
                record.Cfields = '[]';
                record.LOC = '';
              }
              //updateFDT_data[record.Date] = record;
              ROLL_MIN = '0000';
              var table = '';

              if (userShouldHaveFDT(USERTYPE)) {
                table = 'FDT_DaysV4';
              } else {
                table = 'FDT_StaffV3';
              }

              cirroDB.insert(table, record, null, function (e) {
                //console.log("Local Record ResetSuccessfully");
                update_Sync('LastFDTsync', new Date());

                cirroDB.Delete('FDT_RecsV3', 'Date = ?', [Sdate], function (e) {
                  FDT_REPORT = false;
                  CurrentFDT_Recs = [];
                  refresh(refreshDATE);

                  BackgroundSync(true); // to sync potential changes to the server;
                });
              });
            },
          },
          [iTrans('Cancel')]: {
            click: function () {},
          },
        },
      });
    }

    function openEdit(e) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.openEdit', '2024-06-26', 'bldarosa');
      const xit = $('#exitDutyTimes');
      xit.unbind('click').click(function () {
        directSaveEdit('Exit');
      });
      $('#DutyDefaultsPage').prop('disabled', false);
      toggleBackButton(xit, false);
      $('#DutyCalendar').hide();
      $('#Summary_HDLR').hide();
      $('#Edit_HDLR').show();
      //$(document).scrollTop($(document).height());

      ////console.log(ExtendAvail);
      ////console.log(ResetFT);
      if (userShouldHaveFDT(USERTYPE)) {
        if (Rule == 702 || Rule == 703) {
          if (
            ExtendAvail == true &&
            ResetFT == true &&
            $('#Extend').is(':checked') == false &&
            ViolationON == false &&
            !REC_EDITED
          ) {
            //Popup diaglog suggesting to extend duty time and reset hours
            var promptText =
              '<p>' +
              iTrans('You are able to extend your flight times for the next 30 days starting from this date') +
              '.<br /' +
              iTrans('Selecting [Non-overlapping period] will') +
              '>:<br />- ' +
              iTrans('Reset your last 30, 42 and 90 cumulative flight times') +
              '<br />- ' +
              iTrans('Allow you to work 15 hour duty days (8 hours flight time maximum for duty days over 14 hours)') +
              '<br />- ' +
              iTrans(
                'Extend maximum flight times to: 150 hrs in 30 days, 210 hrs in 42 days, 450 hours in 90 days, 900 hrs in 180 days'
              ) +
              '';
            $('<div>').simpledialog2({
              mode: 'button',
              animate: false,
              headerText: iTrans('Extend?'),
              headerClose: false,
              buttonPrompt: promptText,
              buttons: {
                [iTrans('Extend Flight Time')]: {
                  click: function () {
                    //Check the extend checkbox for user
                    $('#Extend').attr('checked', true).checkboxradio('refresh');
                    Extend = 1;
                    REC_EDITED = true;
                    saveEdit('save');
                  },
                },
                [iTrans('Not Now')]: {
                  click: function () {
                    //Close Dialog
                  },
                },
              },
            });
          }
        }
      }
    }

    function rebuildFDTdatabase() {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.rebuildFDTdatabase', '2024-06-26', 'bldarosa');
      update_Sync('LastFDTsync', new Date());
      var table = '';

      if (userShouldHaveFDT(USERTYPE)) {
        table = 'FDT_DaysV4';
      } else {
        table = 'FDT_StaffV3';
      }

      cirroDB.clearAll(table, function (e) {
        //console.log("FDT Database Cleard ready for sync");
        $('#calendar').removeClass();
        $('#calendar').html("<h3 class='limit-table'>" + iTrans('Syncing Standby') + '...</h3>');
        BackgroundSync(true); // must use full sync when rebuilding FDT database
      });
    }

    var updateFDT_data = [];

    function SendFDTUpdate(done) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.SendFDTUpdate', '2024-06-26', 'bldarosa');
      if (ONLINE) {
        $('#Duty_Status').html(iTrans('Sending Data to Server'));
        ////console.log(Update_data);
        ////console.log(Insert_data);
        var Insert_data = [];
        var Update_data = [];
        for (var i in updateFDT_data) {
          Update_data.push(updateFDT_data[i]);
        }
        for (var b in missingFDT_data) {
          Insert_data.push(missingFDT_data[b]);
        }
        var num_Update = Update_data.length;
        var num_Insert = Insert_data.length;
        var updateString = JSON.stringify({ Days: Update_data, Recs: Update_recs });
        var updateString64 = btoa(updateString);
        var insertString = JSON.stringify({ Days: Insert_data, Recs: Insert_recs });
        var insertString64 = btoa(insertString);

        $.post(
          BASEURL + 'sync-AJAX.php?action=remoteSyncV2&Con_Flag=true',
          {
            update: updateString64,
            insert: insertString64,
          },
          function (data) {
            ////console.log(data);
            var FDTsync;
            if (data[0].ServerResponse != 'Success' || data[0].ServerResponse == 'Offline') {
              if (FDT_ADMIN_UPDATE)
                $('#Duty_Status').html(iTrans('Admin Update') + ': ' + ADMIN_FDT_DATA.CurUser.chatname);
              else $('#Duty_Status').html(iTrans('Data: Send Failed'));
              //Update local sync database with incremented sync attempt
            }
            if (data[0].ServerResponse == 'Success') {
              var time;
              missingFDT_data = [];
              updateFDT_data = [];
              time = data[0].LastFDTsync.split(' ');
              data[0].LastFDTsync = new Date(time[0] + 'T' + time[1] + 'Z');
              FDTsync = new Date(data[0].LastFDTsync);
              //Update loacal sync database with new FDT sync datetime
              if (FDT_ADMIN_UPDATE)
                $('#Duty_Status').html(iTrans('Admin Update') + ': ' + ADMIN_FDT_DATA.CurUser.chatname);
              else $('#Duty_Status').html(iTrans('Data: Synced'));
              update_Sync('LastFDTsync', FDTsync);
            }
            if (data[0].ServerResponse == 'Uncaught Exception') {
              alert('ERROR: ' + data[0].Exception);
            }
            if (done) {
              initDutyTimes();
            } //menu-Duty.js
          },
          'json'
        ).fail(function (jqXHR, status, error) {
          $('#Duty_Status').html(iTrans('Data: Send Failed'));
          if (done) {
            initDutyTimes();
          } //menu-Duty.js
        });
      } else {
        //console.log("Unable to sync with remote server! Working Offline...");
        $('#Duty_Status').html(iTrans('Offline Mode'));
        update_Sync('LastFDTsync', new Date());
        if (done) {
          initDutyTimes();
        } //menu-Duty.js
      }
      //console.log("FDT Sync from CAL Completed");
    }

    function changeDefault(e) {
      VueBridge.deprecationUtils.markDeprecated('menu-Duty-Cal.changeDefault', '2024-06-26', 'bldarosa');
      var id = e.currentTarget.id;
      //console.log(e);

      switch (id) {
        case 'CHK_IFR':
          if (this.checked) {
            IFR = 1;
          } else {
            IFR = 0;
          }
          break;
        case 'CHK_Heli':
          if (this.checked) {
            $('#CHK_DHC').attr('checked', false).checkboxradio('refresh');
            $('#CHK_DHC').checkboxradio('disable');

            Heli = 1;
            DHC = 0;
          } else {
            $('#CHK_DHC').checkboxradio('enable');
            Heli = 0;
          }
          break;
        case 'CHK_DHC':
          if (this.checked) {
            $('#CHK_Heli').attr('checked', false).checkboxradio('refresh');
            $('#CHK_Logging').attr('checked', false).checkboxradio('refresh');
            $('#CHK_Heli').checkboxradio('disable');
            $('#CHK_Logging').checkboxradio('disable');

            DHC = 1;
            Heli = 0;
            Logging = 0;
          } else {
            $('#CHK_Heli').checkboxradio('enable');
            $('#CHK_Logging').checkboxradio('enable');
            DHC = 0;
          }
          break;
        case 'CHK_Dual':
          if (this.checked) {
            Dual = 1;
          } else {
            Dual = 0;
          }
          break;
        case 'CHK_AerialApp':
          if (this.checked) {
            AerialApp = 1;
          } else {
            AerialApp = 0;
          }
          break;
        case 'CHK_Logging':
          if (this.checked) {
            $('#CHK_DHC').attr('checked', false).checkboxradio('refresh');
            $('#CHK_DHC').checkboxradio('disable');

            Logging = 1;
            DHC = 0;
          } else {
            $('#CHK_DHC').checkboxradio('enable');
            Logging = 0;
          }
          break;
        case 'SELECT_Rule':
          Rule = $('#SELECT_Rule').val();
          if (Rule != 702) {
            $('#CHK_AerialApp').prop('checked', false).checkboxradio('disable').checkboxradio('refresh');
          } else {
            $('#CHK_AerialApp').prop('checked', false).checkboxradio('enable').checkboxradio('refresh');
          }
          ////console.log("Rule:" +Rule);
          break;
        case 'SELECT_Rule_ID':
          Rule_ID = $('#SELECT_Rule_ID').val();
          break;
      }
      $('#DutyDefaults').enhanceWithin();
      REC_EDITED = true;
      //alert("Selected: " + Rule);
      //directSaveEdit("save");
      Setting_Edited = true;
      saveEdit('save');
    }
    var Setting_Edited = false;

    $element.bind('refresh', function (event, date) {
      refresh(date);
    });

    init();
  };
  $.fn.jqmCalendar = function (options) {
    return this.each(function () {
      //if (!$(this).data('jqmCalendar')) { //this edited out, prevents reload after returning to duty times
      $(this).data('jqmCalendar', new $.jqmCalendar(this, options));
      //}
    });
  };
})(jQuery);
