class vSAMA_RiskFactorValueEditor extends vSAMA_AbstractEditor {
  /**
   * @param {mSAMA_RiskFactorValue}         _riskFactorValue
   * @param {vSAMA_ModalConsequenceEditor}  _consequenceEditor
   * @param {boolean}                       _readOnly
   */
  constructor(_riskFactorValue, _consequenceEditor, _readOnly) {
    super();

    this.readOnly = _readOnly;
    this.riskFactor = _riskFactorValue.GetRiskFactor();
    this.riskFactorValue = _riskFactorValue;
    this.consequenceEditor = _consequenceEditor;
    this.custFieldValEditors = [];

    let template = $(SAMA.Templates.tpl.riskFactor.valueEdit.formatTemplate(this.riskFactor.sync));
    this.dom = {
      wrapper: $('<div />'),
      body: template,
      select: template.find('select'),
      hints: template.find('.sama-form-elaboration ul'),
    };

    this.dom.body.addClass('sama-linked').appendTo(this.dom.wrapper);

    this.MarkThresholdOption();

    // Handle showing the hints associated with a selection and set the validation mode of custom fields
    if (this.readOnly) {
      this.dom.select.prop('disabled', true);
    } else {
      this.dom.select.on('change', () => this.OnSelectionChanged());
    }

    this.LoadCustFieldValEditors();
    this.ReloadInputs();
  }

  MarkThresholdOption() {
    let options = this.dom.select.find('option').toArray();

    for (let o = 0; o < options.length; o++) {
      let threshold = +this.riskFactor.sync.mrf_riskThreshold;
      if (threshold === 1) {
        // Ignore the 'disabled' threshold
        return;
      }

      let el = $(options[o]);
      let elVal = +el.attr('value');

      if (elVal > 1 && elVal === threshold) {
        el.text(el.text() + ' (Threshold)');
      }
    }
  }

  /**
   * Reloads the values in the editor's inputs
   */
  ReloadInputs() {
    this.dom.select
      .attr('value', this.riskFactorValue.sync.mrfv_riskValue)
      .val(this.riskFactorValue.sync.mrfv_riskValue)
      .trigger('change');
  }

  /**
   * Discards changes made and resets to previous state
   */
  DiscardChanges() {
    this.riskFactorValue.DiscardValueChanges();
    for (let c = 0; c < this.custFieldValEditors.length; c++) {
      this.custFieldValEditors[c].DiscardChanges();
    }

    this.ReloadInputs();
  }

  /**
   * Confirms changes made
   */
  ConfirmChanges() {
    this.riskFactorValue.ConfirmValueChanges();

    for (let c = 0; c < this.custFieldValEditors.length; c++) {
      this.custFieldValEditors[c].ConfirmChanges();
    }
  }

  /**
   * Generates Editors for custom field values assigned to this risk factor value
   */
  LoadCustFieldValEditors() {
    let custFieldVals = this.riskFactorValue.GetCustomFieldValues();

    for (let cf = 0; cf < custFieldVals.length; cf++) {
      let newEdit = new vSAMA_CustomFieldValueEditor(custFieldVals[cf], this.readOnly);
      this.custFieldValEditors.push(newEdit);

      this.dom.wrapper.append(newEdit.GetDom());
    }
  }

  /**
   * Callback for a change in selection
   */
  OnSelectionChanged() {
    let value = +this.dom.select.val();

    this.dom.hints.find('li.sama-select').removeClass('sama-select');

    this.dom.hints.find('li[data-value="%"]'.tr(value)).addClass('sama-select');

    this.riskFactorValue.sync.mrfv_riskValue = value;
    this.riskFactorValue.SetModified(true);

    this.consequenceEditor.UpdateRisk();

    for (let cf = 0; cf < this.custFieldValEditors.length; cf++) {
      this.custFieldValEditors[cf].SetRequired(value);
    }
  }

  /**
   * @returns {jQuery}
   * @override
   */
  GetDom() {
    return this.dom.wrapper;
  }

  Destroy() {
    this.dom.wrapper.remove();
  }
}
