(function($){

/**
 *
 * @param _selection
 * @param _operation
 * @param _options
 * @return {*}
 */
var IterateMultiOperations = function (_selection, _operation, _options)
{
  /** @type {cttrCatTagger} ct */
  var ct = $(_selection).data('catTagger-plugin');

  if (ct == null)
  {
    throw 'Cannot call operations on uninitialized CatTagger.';
  }

  switch (_operation)
  {
    case 'destroy':
      ct.Destroy();
      break;
    case 'enable' :
      ct.EnableInput(_options);
      break;
    case 'flushCache' :
      ct.FlushCache();
      break;
    case 'clear' :
      ct.Clear();
      break;
    case 'refresh' :
      ct.Refresh();
      break;
    case 'updateRemoteKeys':
      ct.UpdateRemoteKeys(_options);
      break;
    case 'addTagToOutput' :
      ct.ExternalAddTag(_options);
      break;
    case 'removeTagFromOutput':
      ct.ExternalRemoveTag(_options);
      break;
    case 'addTagToCache' :
      ct.CacheTag(_options);
      break;
    case 'removeTagFromCache':
      ct.UnCacheTag(_options);
      break;
    case 'addCategoryToCache' :
      ct.CacheCat(_options);
      break;
    case 'removeCategoryFromCache':
      ct.UnCacheCat(_options);
      break;
    case 'getSelectedTags' :
      return ct.GetSelectedTagsAndIds();
    case 'setBuddies':
      return ct.SetBuddies(_options);
    case 'hasTagSelected':
      return ct.HasTagSelected(_options);
    case 'version':
      return '0.30.0.1528224105-debug';
    default:
      throw _operation + ' is not a valid CatTagger operation. Sorry.';
  }
  return _selection;
};

/**
 * Main Plugin function
 * @param {object|string} _sourceOrOperation Object containing tags, categories or an operation string
 * @param {*} [_options] Plugin options or options for an operation
 */
$.fn.catTagger = function (_sourceOrOperation, _options)
{
  if (_options == null)
  {
    _options = {
      remote: {
        url: null
      }
    };
  }

  if (typeof _sourceOrOperation === 'string')
  {
    var result = [];
    this.each(function ()
    {
      result.push(IterateMultiOperations(this, _sourceOrOperation, _options));
    });

    return result;
  }

  // Main Applier
  return this.each(function ()
  {
    if ($(this).data('catTagger-plugin') != null)
    {
      throw 'Already has an instance of catTagger';
    }

    var ct = new cttrCatTagger($(this), _sourceOrOperation, _options);
    $(this).data('catTagger-plugin', ct);
    ct.Construct();
    return this;
  });
};

// TODO disallow accepting tags while waiting for the remote (not typing though that'd be annoying!)
// TODO fix backspace behaviour on mobile -> Prevent deleting via BS on mobile | Use timer only and ignore the difference between held/pressed

/** @type {cSettings} */
$.fn.catTagger.defaults = {
  separator     : ',',
  use           : 'all', // Can take 'all', 'tags', 'categories'
  bannedWords   : [],
  ignoreCatCheck: false,
  cacheIDcheck  : true,
  showAllButton : false,
  acceptKeys    : [13, 188],
  defCatID      : -1,
  useIDs        : true,  // Use IDs rather than cat:tag pairs in the output
  acceptAsterisk: false, // If true, will allow tags to be entered with * as their category, i.e. ignoring categories. This is useful when you want to allow users to select tags across categories.
  forceColor    : null,
  permissions   : {
    allowNewCat    : false,
    allowNewTag    : false,
    blacklistedOnly: false
  },
  inputField    : {
    enabled    : true,
    maxTags    : 5,
    deleteDelay: 800
  },
  autocomplete  : {
    maxSuggestions: 5
  },
  remote        : {
    fetchOnce      : false,
    url            : '',
    method         : 'GET',
    payloadScope   : null, // The sub-object within the payload to use rather than the whole payload
    data           : {},
    timeout        : 10000,
    timeBetweenSync: 30000, // Time in which the tagger will use its cached values for showing available tags modal rather than redownloading the cache (in ms)
    waitBeforeQuery: 500 // Delay the requesting of more tags from the server until this time has passed since the user last entered something
  },
  lengths       : {
    tags: {
      maxChars: 15,
      minChars: 3
    },
    cats: {
      maxChars: 15,
      minChars: 3
    }
  },
  messages      : {
    category         : 'category',
    tag              : 'tag',
    invalidMaxLength : '%{catTag} names must have at most %{max} characters.',
    invalidMinLength : '%{catTag} names must have at least %{min} characters.',
    specialChars     : '%{catTag} names may not contain special characters.',
    placeholderCatTag: 'Enter a %{cat}:%{tag} combination.',
    placeholderSingle: 'Enter a %{catTag}.',
    addingNotAllowed : 'You cannot enter an unknown %{catTag}. Please select from existing ones.',
    tagBlacklisted   : 'This %{tag} is blacklisted. You can not use it.',
    catBlacklisted   : 'The %{cat} "%{catname}" is blacklisted. You can not use it or any of its %{tag}s.',
    tooManyColons    : 'Too many colons.',
    loadFail         : 'Can\'t reach remote.',
    tagNotBlacklisted: 'This %{tag} already exists and isn\'t blacklisted.',
    bannedWordUsed   : 'These words are banned: "%{wordList}".'
  }
}; // End Default Settings

/**
 * Class holding all functions for auto-completion
 * @param {cttrCatTagger} _owner
 * @class
 * @property {cttrTag[]} shownTags      Tags that are currently available in the suggester
 * @property {int}    selectedIndex  Suggester Index of the selected tag
 */
var cttrAutoComplete = function (_owner)
{
  var self          = this;
  var owner         = _owner;
  var shownTags     = [];
  var selectedIndex = -1;
  var dom           = null;
  var catHint       = [];

  dom = $('<div />')
  .addClass('cttr-tagList')
  .hide();

  /**
   * Returns the suggester's markup reference
   * @return {*}
   */
  this.GetDom = function ()
  {
    return dom;
  };

  this.ClearCached = function ()
  {
    selectedIndex = -1;
    shownTags     = [];
    catHint       = [];
  };

  /**
   * Gets the selected cttrTag
   * @returns {cttrTag|null|int} Null if on text field, -1 if category hint chosen
   */
  this.GetSelected = function ()
  {
    if (selectedIndex < 0)
    {
      return null;
    }

    var s = selectedIndex;
    if (owner.GetSettings().permissions.allowNewTag)
    {
      s--;

      if (shownTags.length <= 0 && catHint.length > 0)
      {
        // Only categories are being suggested
        owner.SetNewTagCategory(catHint[s + 1]);
        return -1;
      }
      else if (s === -1 && catHint.length === 1)
      {
        // One category and a buncha tags are being suggested
        owner.SetNewTagCategory(catHint[0]);
        return -1;
      }
    }

    return shownTags[s];
  };

  var ChangeSelected = function (_newIndex)
  {
    selectedIndex = _newIndex;
    dom.find('li')
       .removeClass('cttr-select')
       .filter(function ()
       {
         return $(this).data('sugg') === _newIndex;
       })
       .addClass('cttr-select');
  };

  /**
   * Snaps the suggester back to the input
   * @param {object} _newWidth
   * @param {object} _frameOffs
   */
  this.Realign = function (_newWidth, _frameOffs)
  {
    dom.css('width', _newWidth + 'px');
    dom.css('left', _frameOffs.left + 'px');
  };

  var GetHintedCategory = function (_catData)
  {
    var settings = owner.GetSettings();
    catHint.push(_catData);
    var catItem = $('<li />').addClass('cttr-catItem')
                             .css('color', _catData.color)
                             .data('sugg', catHint.length - 1)
                             .on('click', function ()
                             {
                               owner.SetNewTagCategory(_catData);
                               owner.ClearInput();
                               self.HideSuggester();
                             })
                             .on('mouseenter', function ()
                             {
                               // Check if the mouse pointer is hovering over an item and activate it so there's no confusion.
                               selectedIndex = catHint.length - 1;
                               ChangeSelected(catHint.length - 1);
                             });

    var label = 'Use ' + _catData.name;
    var cont  = cttrUtils.Format(
      'as default %{cat} for new %{tag}s ',
      {
        cat: settings.messages.category,
        tag: settings.messages.tag
      });

    $('<span />').addClass('cttr-category')
                 .text(label)
                 .appendTo(catItem);

    $('<span />').addClass('cttr-name')
                 .text(cont)
                 .appendTo(catItem);

    return catItem;
  };

  /**
   * Searches the cache for a cat:tag thats similar to _input value.
   * @param {string} _inputValue
   * @param {cSettings} _settings
   * @return {cttrTag[]}
   */
  var FindTagsWithInputValue = function (_inputValue, _settings)
  {
    var collectedTags = [];
    $.each(owner.GetCached().tags, function (_i, _tag)
    {
      // List either no or only blacklisted tags
      if (!cttrUtils.GetBlacklistStatus(_settings.permissions.blacklistedOnly, _tag))
      {
        return;
      }

      var ext = [];

      if (_settings.acceptAsterisk && _inputValue != null && _inputValue.indexOf('*') === 0)
      {
        // Remove the category from input entirely
        _inputValue = _inputValue.split(':')[1];
      }

      if (_settings.acceptAsterisk)
      {
        ext = owner.GetSelected().filter(function (_selectedTag)
        {
          // Find all the regular cat:tags
          var reg  = _selectedTag.Equal(_tag);
          // Find all the *:tags
          var star = _selectedTag.name === _tag.name;

          return reg || star;
        });
      }
      else
      {
        switch (_settings.use)
        {
          case 'tags':
            // Only check names, not categories
            ext = owner.GetSelected().filter(function (_selectedTag)
            {
              return _selectedTag.name === _tag.name;
            });
            break;
          case  'categories':
            ext = owner.GetSelected().filter(function (_selectedTag)
            {
              return _selectedTag.category.Equal(_tag.category);
            });
            break;
          default:
            ext = owner.GetSelected().filter(function (_selectedTag)
            {
              return _selectedTag.Equal(_tag);
            });
            break;
        }
      }

      if (ext.length > 0)
      {
        return;
      }

      var foundAt;
      var relativeIndex = 999;

      if (_settings.use === 'tags')
      {
        // Consider only tag names
        foundAt       = _tag.name.toLowerCase().indexOf(_inputValue);
        relativeIndex = _tag.name.length;
      }
      else if (_settings.use === 'categories')
      {
        // Consider only category names
        foundAt       = _tag.category.name.toLowerCase().indexOf(_inputValue);
        relativeIndex = _tag.category.name.length;

        var alreadyFound = collectedTags.filter(function (_e)
        {
          return _e.tag.category.name.toLowerCase() === _tag.category.name.toLowerCase();
        });

        if (alreadyFound.length > 0)
        {
          // Already got this one.
          return;
        }
      }
      else
      {
        foundAt       = _tag.combName.toLowerCase().indexOf(_inputValue);
        relativeIndex = _tag.combName.length;
      }

      if (foundAt === -1)
      {
        // String doesn't exist in tag
        return;
      }

      // Associate the tag with a measure of how close to the beginning
      // of its name we found the typed string
      collectedTags.push({
        tag  : _tag,
        index: foundAt / relativeIndex
      });
    });

    return collectedTags;
  };

  /**
   * Try to show the autocomplete suggester
   * @param {string} _inputValue
   */
  this.ShowSuggester = function (_inputValue)
  {
    _inputValue  = _inputValue.toLowerCase();
    var settings = owner.GetSettings();

    /** @type {cttrTag|null} Allows for the re-selection of this tag once the suggester is rebuilt. */
    var reselect = self.GetSelected();

    self.HideSuggester();
    selectedIndex        = -1;
    shownTags            = [];
    catHint              = [];
    var suggesterContent = null;
    var reselectID       = -1;

    // Find the tags that contain the typed string
    var collectedTags = FindTagsWithInputValue(_inputValue, settings);

    if (collectedTags.length <= 0)
    {
      if (settings.permissions.allowNewTag && settings.use === 'all')
      {
        // Gotta show empty categories
        suggesterContent = GetCategoryList(_inputValue);
      }
      else
      {
        // Nothing to show.
        return;
      }
    }
    else
    {
      suggesterContent = GetTagList(collectedTags, settings, reselect);
      reselectID       = suggesterContent.rid;
      suggesterContent = suggesterContent.lcontent;
    }

    if (suggesterContent.children().length <= 0)
    {
      // Nothing to show
      return;
    }

    dom.children('ul').remove();
    dom.append(suggesterContent)
       .show();

    $(window).trigger('resize');

    if (reselectID >= 0)
    {
      ChangeSelected(reselectID);
    }
  };

  var GetCategoryList = function (_inputValue)
  {
    var listContent = $('<ul/>');
    var foundCats   = [];
    var settings    = owner.GetSettings();
    catHint         = [];

    // Get matching categories
    $.each(owner.GetCached().cats, function (_e, _cat)
    {
      if (foundCats.length >= settings.autocomplete.maxSuggestions)
      {
        return;
      }

      var offs = _cat.name.toLowerCase().indexOf(_inputValue);
      if (offs > -1)
      {
        foundCats.push({
          offset: offs / _cat.name.length,
          cat   : _cat
        });
      }
    });

    // Sort the found categories by how close to the beginning of their name the input was found
    foundCats = foundCats.sort(function (_a, _b)
    {
      return _a.offset - _b.offset;
    });

    // Create a view for the categories
    $.each(foundCats, function (_i, _cat)
    {
      var catItem = GetHintedCategory(_cat.cat);
      listContent.append(catItem);
    });

    return listContent;
  };

  var GetTagList = function (_collectedTags, _settings, _reselect)
  {
    // Sort the contents according to how close the result
    // was to the start of the string
    _collectedTags.sort(function (a, b)
    {
      return a.index - b.index;
    });

    var listCont   = $('<ul />');
    var i          = 0;
    var reselectID = -1;

    while (shownTags.length < _settings.autocomplete.maxSuggestions)
    {
      if (_collectedTags[i] == null)
      {
        // Not enough tags to fill the list
        break;
      }

      (function (_i)
      {
        /** @type {cttrTag} */
        var tagData = _collectedTags[_i].tag;

        if (_settings.permissions.allowNewTag && _settings.use === 'all')
        {
          if (_i === 0)
          {
            catHint     = [];
            var catItem = GetHintedCategory(tagData.category);
            listCont.append(catItem);
          }
          _i++;
        }

        if (_reselect != null && _reselect !== -1 && _reselect.Equal(tagData))
        {
          reselectID = _i;
        }

        shownTags.push(tagData);
        listCont.append(tagData.suggDom);
        tagData.suggDom
               .data('sugg', _i)
               .off('')
               .on('click', function ()
               {
                 dom.trigger('tagClicked', tagData);
               })
               .on('mouseenter', function ()
               {
                 // Check if the mouse pointer is hovering over an item and activate it so there's no confusion.
                 selectedIndex = _i;
                 ChangeSelected(_i);
               });
      })(i);
      i++;
    }

    return {
      rid     : reselectID,
      lcontent: listCont
    };
  };

  /**
   * Hide the suggester List
   * @param {boolean} [_resetSelection] If true, clears the currently selected suggestion
   */
  this.HideSuggester = function (_resetSelection)
  {
    if (_resetSelection === true)
    {
      selectedIndex = -1;
    }

    dom.find('li')
       .removeClass('cttr-select')
       .data('sugg', null);

    dom.children('ul')
       .remove();

    dom.hide();
  };

  /**
   * Changes the selection in the suggester
   * @param {int} _dir
   */
  this.ChangeSuggesterSelection = function (_dir)
  {
    if (shownTags.length <= 0 && catHint.length < 0)
    {
      return;
    }

    var whichLength = Math.max(shownTags.length, catHint.length);

    selectedIndex += _dir;

    // Wrap the selection
    if (selectedIndex > whichLength)
    {
      selectedIndex = 0;
    }
    else if (selectedIndex < -1)
    {
      selectedIndex = whichLength - 1;
    }

    ChangeSelected(selectedIndex);
  };
};

/**
 * Generates the modal select that shows all available tags and categories.
 * @param _owner
 * @class
 * @constructor
 */
var cttrAvailablesModal = function (_owner)
{
  var self  = this;
  var owner = _owner;
  var dom   = {
    modal     : null,
    wrapper   : null,
    close     : null,
    categories: {}
  };

  this.Close = function ()
  {
    dom.modal.fadeOut(150, function ()
    {
      dom.modal.remove();
      owner.ClearInput();

      dom   = null;
      self  = null;
      owner = null;
    });
  };

  this.Show = function ()
  {
    dom.modal = $('<div />').addClass('cttr-modal');

    if (owner.GetSettings().permissions.allowNewTag)
    {
      dom.modal.addClass('cttr-allowNewTag');
    }

    dom.close = $('<button />').text('Close')
                               .addClass('cttr-modal-close')
                               .appendTo(dom.modal)
                               .on('click', self.Close);

    dom.wrapper = $('<div />').addClass('cttr-modal-wrapper')
                              .appendTo(dom.modal);

    var cached = owner.GetCached();

    $.each(cached.cats, function (_i, _cat)
    {
      if (_cat.id == null || (_cat.blacklisted && !owner.GetSettings().permissions.blacklistedOnly))
      {
        return;
      }

      var catDom = $('<div />').appendTo(dom.wrapper)
                               .addClass('cttr-empty');

      var catName = $('<h1 />').text(_cat.name)
                               .appendTo(catDom)
                               .css('background', _cat.color);

      if (owner.GetSettings().permissions.allowNewTag)
      {
        // Allow choosing the default category for new tags.
        var label = 'Click to set as default for new %{tag}s.';
        label     = cttrUtils.Format(label,
            {
              tag: owner.GetSettings().messages.tag
            });

        catName.attr('title', label)
               .on('click', function ()
               {
                 owner.SetNewTagCategory(_cat);
                 owner.ClearInput();
                 self.Close();
               });
      }

      $('<div/>').addClass('cttr-modal-tag-wrap')
                 .addClass('cid-' + _cat.id)
                 .appendTo(catDom);

      dom.categories[_cat.id] = catDom;
    });

    if (cached.tags.length === 0)
    {
      debugger;
    }

    $.each(cached.tags, function (_i, _tag)
    {
      // List either no or only blacklisted tags
      if (!cttrUtils.GetBlacklistStatus(owner.GetSettings().permissions.blacklistedOnly, _tag))
      {
        return;
      }

      var chosen = cttrUtils.IsSelected(owner.GetSettings(), owner.GetCached(), owner.GetSelected(), _tag);
      var inBuddies = cttrUtils.ExistsInBuddies(owner.GetBuddies(), _tag);

      if (chosen.length > 0 || inBuddies !== false)
      {
        // Already added -> no need to show it here.
        return;
      }

      var catDom = dom.categories[_tag.category.id].removeClass('cttr-empty')
                                                   .find('.cttr-modal-tag-wrap');

      var tagDom = $('<div />').addClass('cttr-modal-tag');

      tagDom.text(_tag.name)
            .appendTo(catDom);

      tagDom.on('click', function ()
      {
        owner.UseTag(_tag);
        tagDom.hide();
        if (owner.GetSelected().length >= owner.GetSettings().inputField.maxTags)
        {
          self.Close();
        }
      });
    });

    dom.modal.find('.cttr-empty').appendTo(dom.wrapper);

    dom.modal.hide()
       .appendTo('body')
       .fadeIn(150);
  };
};

/**
 * Category Class
 * @param {int} _iID
 * @param {string} _sName
 * @param {string} _sColor
 * @param {boolean} [_blacklisted]
 * @class
 * @constructor
 */
var cttrCategory = function (_iID, _sName, _sColor, _blacklisted)
{
  var self         = this;
  this.id          = +(_iID);
  this.name        = _sName;
  this.color       = _sColor;
  this.blacklisted = _blacklisted || false;

  // Generate a color from the name of the category if none was given
  if (_sColor === '?')
  {
    var hash = 0;
    for (var s = 0; s < this.name.length; s++)
    {
      hash = this.name.charCodeAt(s) + ((hash << 5) - hash);
    }

    var color = '#';
    for (var i = 0; i < 3; i++)
    {
      var value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }

    this.color = color;
  }

  /**
   * Checks whether this category is the same as another
   * @param {cttrCategory} _other
   * @returns {boolean}
   */
  this.Equal = function (_other)
  {
    return self.name.toLowerCase() === _other.name.toLowerCase();
  };

  /**
   * Destroys the internals of this object
   */
  this.Destroy = function ()
  {
    self = null;
  };
};
/**
 * Main plugin class
 * @param {jQuery} _target
 * @param {object} _tagSource
 * @param {object} _options
 * @class
 * @constructor
 */
var cttrCatTagger = function (_target, _tagSource, _options)
{
  var self     = this;
  /** @type {cSettings} */
  var settings = {};
  $.extend(true, settings, $.fn.catTagger.defaults, _options);

  var lastFullSyncTime = 0;
  var presetTags       = '';
  var hasError         = false;
  var errorLocked      = false;
  var defaultNewCat    = null;
  var utils            = new cttrUtils();
  var buddies          = [];

  var evtStarted = jQuery.Event('cttrStarted');
  var evtReady   = jQuery.Event('cttrReady');

  evtReady.taggerID = evtStarted.taggerID = (new Date()).getTime();
  evtReady.origin = _target;

  var inpGreyBecause = {
    full        : false,
    progDisabled: false,
    loading     : false
  };

  var cache = {
    cats  : [],
    tags  : [],
    banned: []
  };

  var auc = null;

  var dom = {
    origin : _target,
    frame  : null,
    input  : null,
    suggest: null
  };

  var bckspc = {
    timer   : -1,
    delSomth: false
  };

  var selectedTags = [];

  if (_tagSource == null && settings.remote.url.length <= 0)
  {
    throw 'CatTagger expects an initial cache of tags or a source URL during initialization.';
  }

  if (settings.acceptKeys.indexOf(8) >= 0)
  {
    throw 'CatTagger can\'t use backspace as an acceptKey!';
  }

  if (settings.acceptKeys.indexOf(27) >= 0)
  {
    throw 'CatTagger can\'t use escape as an acceptKey!';
  }

  if (
      settings.acceptKeys.indexOf(37) >= 0 ||
      settings.acceptKeys.indexOf(38) >= 0 ||
      settings.acceptKeys.indexOf(39) >= 0 ||
      settings.acceptKeys.indexOf(40) >= 0
  )
  {
    throw 'CatTagger can\'t use the arrow keys as acceptKeys!';
  }

  if (!settings.permissions.allowNewTag && settings.permissions.allowNewCat)
  {
    throw 'Allowing users to create categories but not tags is a non-sensical configuration!';
  }

  if (settings.lengths.cats.minChars <= 0 && settings.lengths.tags.minChars <= 0)
  {
    throw 'Setting the minimum lengths to less than one is a non-sensical configuration.';
  }

  if (settings.acceptAsterisk && settings.use !== 'all')
  {
    throw 'Accepting Asterisks in a different use mode than "all" is a non-sensical configuration.';
  }

  /**
   * Construct the DOM elements and settings for a specific instance of the plugin
   */
  this.Construct = function ()
  {
    $('body').trigger(evtStarted);
    _target.addClass('cttr-keyword-input-loading cttr-enhanced');

    GenerateDefaultCategories();
    GenerateMarkup();
    GenerateTagCache(_tagSource);

    $.extend(true, cache.banned, settings.bannedWords);

    GetInputPresets();

    auc = new cttrAutoComplete(self);
    auc.GetDom().appendTo(dom.wrapper);

    SetUpEventHandlers();

    dom.origin
       .hide()
       .before(dom.wrapper);
  };

  var GenerateDefaultCategories = function ()
  {
    // Reserve a spot for the default category
    cache.cats[0] = {
      id         : null,
      name       : '',
      color      : 'black',
      blacklisted: true
    };

    if (settings.acceptAsterisk)
    {
      // Store the asterisk category
      cache.cats[1] = new cttrCategory(-2, 'Any', '#555', false);
    }
  };

  /**
   * Destructs the object
   */
  this.Destroy = function ()
  {
    utils.CancelRequests();
    self.FlushCache();
    dom.wrapper.find('*').off('');
    dom.wrapper.remove();
    dom.origin
       .data('catTagger-plugin', null)
       .off('')
       .show();
    dom.origin = null;
    dom        = null;
    utils      = null;

    $(window).off('resize', HandleResizeEvent);
  };

  /**
   * Links events to plugin DOM
   */
  var SetUpEventHandlers = function ()
  {
    // Adjust element sizes when the window changes
    $(window).on('resize', HandleResizeEvent);

    // Focus on tags input when the frame is clicked
    dom.frame.on('click', function ()
    {
      dom.input.focus();
    });

    dom.input.on('keydown', OnKeyDown);
    dom.input.on('keyup', OnKeyUp);

    auc.GetDom().on('tagClicked', function (_e, _tag)
    {
      self.UseTag(_tag);
      self.ClearInput();
      auc.HideSuggester();
    });
  };

  /**
   * Generates needed DOM elements for the plugin's function
   */
  var GenerateMarkup = function ()
  {
    dom.wrapper = $('<div/>').addClass('cttr-wrapper');
    dom.frame   = $('<div />').addClass('cttr-frame')
                              .appendTo(dom.wrapper);

    if (settings.use === 'tags')
    {
      dom.wrapper.addClass('cttr-hideCats');
    }
    else if (settings.use === 'categories')
    {
      dom.wrapper.addClass('cttr-hideTags');
    }

    dom.input = $('<input />').addClass('cttr-input')
                              .attr('data-enhanced', true) // jQuery Mobile needs to leave this input alone
                              .attr('maxlength', settings.lengths.tags.maxChars + settings.lengths.cats.maxChars)
                              .attr('placeholder', GetPlaceholderText())
                              .prependTo(dom.frame);

    $('<div />').addClass('cttr-clear')
                .appendTo(dom.frame);

    dom.status = $('<div />').addClass('cttr-status')
                             .appendTo(dom.wrapper)
                             .hide();

    if (settings.showAllButton)
    {
      var label = (settings.permissions.blacklistedOnly) ? 'Show blacklisted %{tag}s' : 'Show available %{tag}s';

      label = cttrUtils.Format(label, {tag: settings.messages.tag});

      dom.allButton = $('<button />').addClass('cttr-showAll')
                                     .text(label)
                                     .attr('data-enhanced', true) // jQuery Mobile needs to leave this button alone
                                     .attr('data-role', 'none') // jQuery Mobile needs to SERIOUSLY leave this button alone
                                     .appendTo(dom.wrapper);

      dom.allButton.on('click', function ()
      {
        var modal    = new cttrAvailablesModal(self);
        var now      = (new Date()).getTime();
        var synctime = lastFullSyncTime + settings.remote.timeBetweenSync;

        if (settings.remote.url != null && settings.remote.url.length > 0 && synctime < now && !settings.remote.fetchOnce)
        {
          dom.allButton.prop('disabled', true)
             .text('Loading...');

          GetAllTags(
              function ()
              {
                modal.Show();
                dom.allButton.prop('disabled', false).text(label);
                lastFullSyncTime = (new Date()).getTime();

              }, function ()
              {
                $('body').trigger(evtReady);
                dom.allButton.prop('disabled', false)
                   .text('Could not reach remote. Try again?');
                SetLoading(false);
              }
          );
        }
        else
        {
          modal.Show();
        }
      });
    }

  };

  /**
   * Gets the placeholder text for the input
   * @return {string}
   */
  var GetPlaceholderText = function ()
  {
    var placeholder = 'Misconfigured!';

    if (settings.use === 'tags')
    {
      placeholder = cttrUtils.Format(settings.messages.placeholderSingle, {
        catTag: cttrUtils.Capitalize(settings.messages.tag)
      });
    }
    else if (settings.use === 'categories')
    {
      placeholder = cttrUtils.Format(settings.messages.placeholderSingle, {
        catTag: cttrUtils.Capitalize(settings.messages.category)
      });
    }
    else
    {
      placeholder = cttrUtils.Format(settings.messages.placeholderCatTag, {
        cat: cttrUtils.Capitalize(settings.messages.category),
        tag: cttrUtils.Capitalize(settings.messages.tag)
      });
    }

    return placeholder;
  };

  /**
   * Caches a tag
   * @param {JsnTag} _jsonTag
   */
  this.CacheTag = function (_jsonTag)
  {
    if (_jsonTag.name == null)
    {
      // Name is unset -> pointless
      return;
    }

    var cat = cttrUtils.GetByID(cache.cats, _jsonTag.catID);
    if (cat.length < 1)
    {
      // Category doesn't exist but the tag does?
      // assume default category instead.
      cat[0] = cache.cats[0];
    }

    // If we just want to cache all tags and don't care about duplicates, we can set this to false
    if (settings.cacheIDcheck)
    {
      var exTag = cttrUtils.GetByID(cache.tags, _jsonTag.id);
      if (exTag.length > 0)
      {
        // Don't re-cache
        return;
      }
    }

    cache.tags.push(new cttrTag(_jsonTag.id, _jsonTag.name, cat[0], _jsonTag.blacklisted));
  };

  /**
   * Removes a tag from the cache by name
   * @param {string} _tagName
   */
  this.UnCacheTag = function (_tagName)
  {
    auc.HideSuggester();
    var tag = cttrUtils.GetByName(cache.tags, _tagName);

    if (tag.length !== 1)
    {
      return;
    }

    cache.tags = cache.tags.filter(function (_tag)
    {
      return _tag.name !== _tagName;
    });

    selectedTags = selectedTags.filter(function (_tag)
    {
      if (_tag.name === _tagName)
      {
        UnuseTag(_tag);
        return false;
      }
      return true;
    });
  };

  /**
   * Generates and caches a category
   * @param {JsnCat} _jsonCat
   * @throws 1 If the category name is null
   */
  this.CacheCat = function (_jsonCat)
  {
    if (_jsonCat.name == null)
    {
      return;
    }

    var excat = cttrUtils.GetByName(cache.cats, _jsonCat.name);
    if (excat.length > 0)
    {
      // Don't cache category more than once!
      return;
    }

    var ncat = new cttrCategory(_jsonCat.id, _jsonCat.name, _jsonCat.color, _jsonCat.blacklisted);

    if (settings.defCatID === +(ncat.id) && defaultNewCat == null)
    {
      // found the default category!
      cache.cats[0] = ncat;
      self.SetNewTagCategory(cache.cats[0]);
      return ncat;
    }

    cache.cats.push(ncat);
    return ncat;
  };

  /**
   * Removes a category from the cache by name
   * @param {string} _catName
   */
  this.UnCacheCat = function (_catName)
  {
    auc.HideSuggester();
    var cat = cttrUtils.GetByName(cache.cats, _catName);

    if (cat.length !== 1)
    {
      return;
    }

    // Remove the category from the cache
    cache.cats = cache.cats.filter(function (_cat)
    {
      return _cat.name !== _catName;
    });

    // Remove all tags with this category from the cache
    cache.tags = cache.tags.filter(function (_tag)
    {
      return _tag.category.name !== _catName;
    });

    // Remove tags with this category from the selection
    selectedTags = selectedTags.filter(function (_tag)
    {
      if (_tag.category.name === _catName)
      {
        UnuseTag(_tag);
        self.UnCacheTag(_tag.name);
        return false;
      }
      return true;
    });

  };

  this.Refresh = function ()
  {
    // Store the value so we can savely rebuild the selected tags
    var val = dom.origin.val();
    $.each(selectedTags, function (_i, _tag)
    {
      UnuseTag(_tag);
    });

    dom.origin.val(val);
    if (!ValidatePresetTags())
    {
      if (settings.remote.url.length > 0)
      {
        // No data to validate against. Need to pull it first.
        ValidateRemote(presetTags);
      }
    }
  };

  this.Clear = function ()
  {
    $.each(selectedTags, function (_i, _tag)
    {
      _tag.Destroy();
    });
    selectedTags = [];
    UpdateOriginalInput();
  };

  /**
   * Clears the cache and the output
   */
  this.FlushCache = function ()
  {
    lastFullSyncTime = 0;
    auc.ClearCached();
    defaultNewCat = cache.cats[0];
    $.each(cache.tags, function (_i, _tag)
    {
      _tag.Destroy();
    });
    cache.tags = [];

    $.each(selectedTags, function (_i, _tag)
    {
      _tag.Destroy();
    });
    selectedTags = [];

    $.each(cache.cats, function (_i, _cat)
    {
      if (_cat.id > 0)
      {
        _cat.Destroy();
      }
    });
    cache.cats = [];

    utils.ClearQueryTimer();

    GenerateDefaultCategories();
    UpdateOriginalInput();
  };

  /**
   * Synchronizes the IDs of tags/categories in the selector with their server side versions and add them to the
   * backend cache so we don't get duplicates
   * @param {object} _newKeys
   */
  this.UpdateRemoteKeys = function (_newKeys)
  {
    if (!_newKeys.hasOwnProperty('cats') || !_newKeys.hasOwnProperty('tags'))
    {
      throw 'An object with both "cats" and "tags" properties is expected.';
    }

    for (var st = 0; st < selectedTags.length; st++)
    {
      /** @type cttrTag */
      var sTag = selectedTags[st];
      if (_newKeys.tags.hasOwnProperty(sTag.id))
      {
        sTag.id = _newKeys.tags[sTag.id];
        cache.tags.push(sTag);
      }

      if (_newKeys.tags.hasOwnProperty(sTag.category.id))
      {
        sTag.category.id = _newKeys.cats[sTag.category.id];
        cache.cats.push(sTag.category);
      }
    }
  };

  /**
   * Add a new tag, by its name or ID, from the outside
   * @param {string|int} _tagNameOrID
   */
  this.ExternalAddTag = function (_tagNameOrID)
  {
    var g = (settings.useIDs) ?
        cttrUtils.GetByID(cache.tags, _tagNameOrID)[0] :
        cttrUtils.GetTagFromString(_tagNameOrID, settings, cache);
    self.UseTag(g);
  };

  /**
   * Remove a tag, by its name or ID, from the outside
   * @param {string|int} _tagNameOrID
   */
  this.ExternalRemoveTag = function (_tagNameOrID)
  {
    var g = (settings.useIDs) ?
        cttrUtils.GetByID(cache.tags, _tagNameOrID)[0] :
        cttrUtils.GetTagFromString(_tagNameOrID, settings, cache);
    UnuseTag(g);
  };

  /**
   * Presets the category so all !!new!! tags that are entered will automatically be assigned to it.
   * @param {cttrCategory} _cCat
   */
  this.SetNewTagCategory = function (_cCat)
  {
    defaultNewCat = _cCat;

    var p = 'New %{tag}s are assigned to %{catName}';
    if (!defaultNewCat.Equal(cache.cats[0]))
    {
      p = cttrUtils.Format(p, {
        tag    : settings.messages.tag,
        catName: _cCat.name
      });
    }
    else
    {
      p = GetPlaceholderText();
    }
    dom.input.attr('placeholder', p);
  };

  this.ClearInput = function ()
  {
    dom.input.val('');
  };

  /**
   * Get the currently selected tags
   * @return {cttrTag[]}
   */
  this.GetSelected = function ()
  {
    return selectedTags;
  };

  this.GetSelectedTagsAndIds = function ()
  {
    var pairs = [];
    $.each(selectedTags, function (_i, _tag)
    {
      pairs.push({
        tagName     : _tag.name,
        tagID       : _tag.id,
        categoryName: _tag.category.name,
        categoryID  : _tag.category.id,
        new         : _tag.isNew
      });
    });

    return pairs;
  };

  this.GetBuddies = function ()
  {
    return buddies;
  };

  /**
   * Get the settings
   * @return {cSettings}
   */
  this.GetSettings = function ()
  {
    return settings;
  };

  /**
   * Get the cache
   * @return {{cats: Array, tags: Array}}
   */
  this.GetCached = function ()
  {
    return cache;
  };

  /**
   * Enables or disables Input
   * @param {boolean} _enable
   */
  this.EnableInput = function (_enable)
  {
    inpGreyBecause.progDisabled = !_enable;
    GreyOutInput();
  };

  /**
   * Visually represents a disabled state
   */
  var GreyOutInput = function ()
  {
    if (!IsEnabled())
    {
      dom.wrapper.addClass('cttr-disabled');

      if (dom.allButton != null)
      {
        dom.allButton.prop('disabled', true);
      }

      if (auc != null)
      {
        auc.HideSuggester();
      }
      dom.input.attr('placeholder', '');
    }
    else
    {
      if (dom.allButton != null)
      {
        dom.allButton.prop('disabled', false);
      }
      dom.wrapper.removeClass('cttr-disabled');
      dom.input.attr('placeholder', GetPlaceholderText());
    }
  };

  /**
   * Toggles the loading indicator, disables the input
   * @param {boolean} _isLoading
   */
  var SetLoading = function (_isLoading)
  {
    _isLoading = (_isLoading === true);

    if (_isLoading)
    {
      inpGreyBecause.loading = true;
      dom.wrapper.addClass('cttr-load');
    }
    else
    {
      inpGreyBecause.loading = false;
      dom.wrapper.removeClass('cttr-load');
    }

    GreyOutInput();
  };

  /**
   * Loads more tags (like _search) from the remote
   * @param {string}    _search
   * @param {function}  [_failFn]
   */
  var GetMoreTags = function (_search, _failFn)
  {
    if (_search == null ||
        settings.remote.url == null || settings.remote.url.length <= 0 ||
        _search.length < settings.lengths.tags.minChars)
    {
      return;
    }

    utils.MakeRequest({
      data    : {
        quest: _search,
        limit: settings.autocomplete.maxSuggestions
      },
      settings: settings,
      readyFn : DoneGettingMoreTags,
      failFn  : _failFn,
      afterFn : function ()
      {
        auc.ShowSuggester(_search);
      }
    });
  };

  /**
   * Get all tags from the remote
   * @param {function} [_afterFn]
   * @param {function} [_failFn]
   * @param {boolean}  [_force]
   */
  var GetAllTags = function (_afterFn, _failFn, _force)
  {
    _force = (_force == null) ? false : _force;

    SetLoading(true);
    utils.MakeRequest({
      data    : {quest: ''},
      settings: settings,
      force   : _force,
      readyFn : DoneGettingMoreTags,
      afterFn : _afterFn,
      failFn  : _failFn
    });
  };

  /**
   * Callback for when new Tags have been loaded from the remote
   * @param {{}} _json
   */
  var DoneGettingMoreTags = function (_json)
  {
    SetLoading(false);
    var payload = null;

    if (settings.remote.payloadScope != null && _json.hasOwnProperty(settings.remote.payloadScope))
    {
      payload = _json[settings.remote.payloadScope][0];
    }
    else
    {
      payload = _json[0];
    }

    if (payload == null)
    {
      SetStatus(settings.messages.loadFail);
      SetLoading(false);
      return;
    }
    else if (payload.length <= 0)
    {
      // Got a payload correctly, but it is empty.
      dom.origin.val(''); // Clear any presets
      SetLoading(false);
      return;
    }

    GenerateTagCache(payload);
  };

  /**
   * Generates a tag list from the input tag listing
   * @param {object} _tagSource
   */
  var GenerateTagCache = function (_tagSource)
  {
    if (_tagSource == null || !_tagSource.hasOwnProperty('categories') || !_tagSource.hasOwnProperty('tags'))
    {
      // We need to get the tag source from the remote, right now if we're only allowed to grab it once.
      // Otherwise we'll get it incrementally while typing.
      if (settings.remote.fetchOnce)
      {
        GetAllTags(null, function ()
        {
          $('body').trigger(evtReady);
          SetStatus(settings.messages.loadFail, true);
          SetLoading(false);
        });
      }

      return;
    }

    // If no default category was set, create one
    if (cache.cats[0].id == null)
    {
      if (_tagSource.hasOwnProperty('defaultCat') && _tagSource.defaultCat != null)
      {
        /** @namespace _tagSource.defaultCat */
        var dc        = _tagSource.defaultCat;
        cache.cats[0] = new cttrCategory(dc.id, dc.name, dc.color, dc.blacklisted);
      }
      else
      {
        var col       = (settings.forceColor == null) ? '#6e6e6e' : settings.forceColor;
        cache.cats[0] = new cttrCategory(-1, 'Default', col);
      }

      self.SetNewTagCategory(cache.cats[0]);
    }

    // Turn sparse json categories into richer category objects
    $.each(_tagSource.categories, function (_i, _cat)
    {
      self.CacheCat(_cat);
    });

    // Turn sparse json tags into richer tag objects
    $.each(_tagSource.tags, function (_i, _tag)
    {
      self.CacheTag(_tag);
    });

    $.extend(true, cache.banned, _tagSource.banned);
  };

  /**
   * Extracts preset Tags and other salient properties from the original input field for initialization
   */
  var GetInputPresets = function ()
  {
    if (dom.origin.prop('disabled'))
    {
      settings.inputField.enabled = false;
    }

    if (dom.origin.data('maxTags') != null)
    {
      settings.inputField.maxTags = +(dom.origin.data('maxTags'));
    }

    if (!ValidatePresetTags())
    {
      if (settings.remote.url.length > 0)
      {
        // No data to validate against. Need to pull it first.
        ValidateRemote(presetTags);
      }
    }

    inpGreyBecause.progDisabled = !settings.inputField.enabled;

    GreyOutInput();
  };

  /**
   * Validates and uses the tags in the originial input
   * @return {boolean}
   */
  var ValidatePresetTags = function ()
  {
    presetTags     = dom.origin.val();
    var splitPairs = presetTags.split(settings.separator).filter(function (_e)
    {
      return _e.length > 0;
    });

    if (splitPairs.length <= 0)
    {
      // Aint nothing there to validate -> don't care!
      $('body').trigger(evtReady);
      dom.origin.removeClass('cttr-keyword-input-loading');
      return true;
    }

    if (cache.tags.length < splitPairs.length)
    {
      return false;
    }

    // Probably got them tags already
    // Parse the tags from cat:tag<sep>cat:tag<sep>cat:tag
    // into array with cats and tags separated
    for (var i = 0; i < splitPairs.length; i++)
    {
      // If the pair is a number, we interpret it as the ID of a tag.
      // If its a string, we assume its a cat:tag pair.
      var t;
      if (isNaN(+splitPairs[i]))
      {
        t = cttrUtils.GetTagFromString(splitPairs[i], settings, cache);
      }
      else
      {
        t = cttrUtils.GetByID(cache.tags, +splitPairs[i]);
        t = t[0];
      }

      if (t == null)
      {
        // No such tag found in cache
        continue;
      }

      self.UseTag(t, true);
    }

    // Done? Trigger event.
    $('body').trigger(evtReady);
    dom.origin.removeClass('cttr-keyword-input-loading');
    return true;
  };

  var ValidateRemote = function (_presetTags)
  {
    settings.remote.fetchOnce = false;
    HideStatus();
    // TODO don't get all tags, rather send the tags and let the server check if they exist, then send the existing ones back.
    // TODO remove the force-setting from get all tags and make sure it doesn't collide with getting the freakin initial cache
    GetAllTags(
        GetInputPresets,
        function ()
        {
          $('body').trigger(evtReady);
          SetStatus(settings.messages.loadFail, true);
          SetLoading(false);
        },
        true
    );
  };

  /**
   * Places the Tag specified in the original input and in selectedTags
   * @param {cttrTag} _tag
   * @param {boolean} [_force]
   * @return {boolean} The tag is being used (true), the tag's already added (false)
   */
  this.UseTag = function (_tag, _force)
  {
    _force = (_force == null) ? false : _force;

    if (!_force && (_tag.hasOwnProperty('err') || !IsEnabled()))
    {
      return false; // Got tag generator error code.
    }

    var skipExistFilter = false;
    if (settings.acceptAsterisk && _tag.category.Equal(cache.cats[1]))
    {
      // Entered tag has star -> Nuke all the tags that have the same name and add this one instead
      $.each(selectedTags, function (_i, _selTag)
      {
        if (_selTag.name === _tag.name)
        {
          UnuseTag(_selTag);
        }
      });
      skipExistFilter = true;
    }

    if (!skipExistFilter)
    {
      var exTag = cttrUtils.IsSelected(settings, cache, selectedTags, _tag, true);

      if (exTag.length > 0)
      {
        exTag[0].Wow();
        return false;
      }

      var inBuddies = cttrUtils.ExistsInBuddies(buddies, _tag);
      if (inBuddies !== false)
      {
        // Return from the UseTag() function
        inBuddies.Wow();
        return false;
      }
    }

    _tag.dom.on('click', function ()
    {
      if (!inpGreyBecause.progDisabled && !inpGreyBecause.loading)
      {
        UnuseTag(_tag);
      }
    });

    if (settings.forceColor != null)
    {
      _tag.SetColor(settings.forceColor);
    }

    selectedTags.push(_tag);
    dom.input.before(_tag.dom);

    dom.origin.trigger('tagAdded', _tag);
    UpdateOriginalInput();
    HideStatus(); // Get rid of residual errors.
    return true;
  };

  /**
   * Returns true if the input isn't disabled because of settings,
   * because it was programmatically disabled or because its full
   * @return {boolean}
   */
  var IsEnabled = function ()
  {
    return !(inpGreyBecause.progDisabled || inpGreyBecause.loading || inpGreyBecause.full);
  };

  /**
   * Removes the Tag specified from the original input and from selectedTags
   * @param {cttrTag} _tag
   */
  var UnuseTag = function (_tag)
  {
    _tag.Detach();
    selectedTags = selectedTags.filter(function (_t)
    {
      return !(_t.Equal(_tag));
    });

    dom.origin.trigger('tagRemoved', _tag);
    UpdateOriginalInput();
  };

  /**
   * Updates the original output's contents
   */
  var UpdateOriginalInput = function ()
  {
    // Toggle inputs if max tags is reached
    inpGreyBecause.full = (selectedTags.length >= settings.inputField.maxTags);

    if (inpGreyBecause.full)
    {
      dom.input.attr('placeholder', 'Input is full.');
    }
    GreyOutInput();

    // Process selected tags into string
    var newVal = '';
    for (var t = 0; t < selectedTags.length; t++)
    {
      var tag = selectedTags[t];
      newVal += (settings.useIDs) ? tag.id : tag.category.name + ':' + tag.name;
      newVal += settings.separator;
    }

    newVal = newVal.slice(0, -settings.separator.length);
    dom.origin.val(newVal).trigger('change');
    dom.origin.val(newVal).trigger('input');
  };

  /**
   * Handle the pressing of an accept key
   * @param {string} _inputValue
   */
  var HandleAcceptKeys = function (_inputValue)
  {
    // Do nothing if the input is disabled or full.
    if (!IsEnabled())
    {
      console.log('Disabled in HandleAcceptKeys');
      return;
    }

    // User is trying to add a tag from suggester
    var sel = auc.GetSelected();

    // User chose the category hint.
    if (sel === -1)
    {
      self.ClearInput();
      auc.HideSuggester();
      return;
    }

    if (sel != null)
    {
      if (self.UseTag(sel))
      {
        self.ClearInput();
      }
      return;
    }

    // There is garbage data in the input
    if (hasError)
    {
      return;
    }

    // User is trying to add an asterisked tag
    if (settings.acceptAsterisk && _inputValue.indexOf('*') === 0)
    {
      var newName = _inputValue.split(':')[1];
      if (newName == null)
      {
        // Garbage data! Wtf are you doing, user?
        return;
      }
      var replTag = new cttrTag(cttrUtils.GetUID(), newName, cache.cats[1], false);
      self.UseTag(replTag);
      self.ClearInput();
      return;
    }

    var tag = cttrUtils.GetTagFromString(_inputValue, settings, cache, defaultNewCat);

    if (tag.hasOwnProperty('err'))
    {
      var msg;
      switch (tag.err)
      {
        case 1:
          msg = cttrUtils.Format(settings.messages.addingNotAllowed, {
            catTag: settings.messages.tag
          });
          SetStatus(msg, true);
          break;

        case 2:
          msg = cttrUtils.Format(settings.messages.addingNotAllowed, {
            catTag: settings.messages.category
          });
          SetStatus(msg, true);
          break;

        case 4:
          msg = cttrUtils.Format(settings.messages.tagBlacklisted, {
            tag: settings.messages.tag
          });
          SetStatus(msg, true);
          break;

        case 5:
          msg = cttrUtils.Format(settings.messages.catBlacklisted, {
            tag    : settings.messages.tag,
            cat    : settings.messages.category,
            catname: tag.ifo
          });
          SetStatus(msg, true);
          break;

        case 6:
          msg = cttrUtils.Format(settings.messages.tagNotBlacklisted, {
            tag: settings.messages.tag
          });
          SetStatus(msg, true);
          break;

        case 7:
          msg = cttrUtils.Format(settings.messages.bannedWordUsed, {
            wordList: tag.ifo
          });
          SetStatus(msg, true);
          break;
      }
      return;
    }

    if (self.UseTag(tag))
    {
      self.ClearInput();
    }
  };

  /**
   * Display a message to the user
   * @param {string} _message
   * @param {boolean} [_lock] Locks the error so the immediate next interaction (like an accept button press) will not erase it.
   */
  var SetStatus = function (_message, _lock)
  {
    errorLocked = _lock || false;

    var evt       = jQuery.Event('beforeSetStatus');
    evt.message   = _message;
    evt.catTagger = self;

    dom.origin.trigger(evt);

    if (!evt.isDefaultPrevented())
    {
      dom.status
         .stop(true, true)
         .delay(400)
         .fadeIn(100)
         .text(_message);
    }
  };

  /**
   * Hides the status bar
   */
  var HideStatus = function ()
  {
    if (!errorLocked)
    {
      dom.status
         .stop(true, true)
         .fadeOut(100);
    }
    else
    {
      errorLocked = false;
    }
  };

  /**
   * @param {Event} _event
   */
  var OnKeyUp = function (_event)
  {
    var key = _event.which || _event.keyCode;

    if (!IsEnabled() || key === 38 || key === 40 || key === 27)
    {
      // Ignore Up/Down Arrow, ESC keys or all keys if the field is disabled
      _event.preventDefault();
      return;
    }

    if (key === 8)
    {
      // If the timer has not expired
      // but the backspace key has been pressed
      if (!bckspc.delSomth &&
          bckspc.timer !== -1 &&
          dom.input.val().length <= 0)
      {
        // Immediately delete last tag
        HandleBackspace();
      }

      // Stop timer, backspace no longer down
      window.clearTimeout(bckspc.timer);
      bckspc.timer    = -1;
      bckspc.delSomth = false;
    }

    if (!IsEnabled())
    {
      _event.preventDefault();
      return;
    }

    // Check the sizes of the input 'parts'
    var ipVal   = dom.input.val();
    var tagName = ipVal;
    var message = [];

    if (ipVal.indexOf(':') > -1)
    {
      // Check cat
      var splitVal = ipVal.split(':');

      if (splitVal.length > 2)
      {
        message.push(settings.messages.tooManyColons);
      }

      tagName     = splitVal[1];
      var catName = splitVal[0];

      var clen = cttrUtils.ValidateLength(catName, 'cats', settings);

      if (settings.acceptAsterisk && catName === '*')
      {
        // Asterisk is an acceptable catname in this case.
        clen = 0;
      }

      if (clen < 0)
      {
        message.push(cttrUtils.Format(settings.messages.invalidMinLength, {
          catTag: cttrUtils.Capitalize(settings.messages.category),
          min   : settings.lengths.cats.minChars
        }));
      }
      else if (clen > 0)
      {
        message.push(cttrUtils.Format(settings.messages.invalidMaxLength, {
          catTag: cttrUtils.Capitalize(settings.messages.category),
          max   : settings.lengths.cats.maxChars
        }));
      }

      if (cttrUtils.HasSpecialChars(catName, settings.acceptAsterisk))
      {
        message.push(cttrUtils.Format(settings.messages.specialChars, {
          catTag: cttrUtils.Capitalize(settings.messages.category)
        }));
      }
    }

    // Check tag
    var tlen = cttrUtils.ValidateLength(tagName, 'tags', settings);
    if (tlen < 0)
    {
      message.push(cttrUtils.Format(settings.messages.invalidMinLength, {
        catTag: cttrUtils.Capitalize(settings.messages.tag),
        min   : settings.lengths.tags.minChars
      }));
    }
    else if (tlen > 0)
    {
      message.push(cttrUtils.Format(settings.messages.invalidMaxLength, {
        catTag: cttrUtils.Capitalize(settings.messages.tag),
        max   : settings.lengths.tags.maxChars
      }));
    }

    if (cttrUtils.HasSpecialChars(tagName))
    {
      message.push(cttrUtils.Format(settings.messages.specialChars, {
        catTag: cttrUtils.Capitalize(settings.messages.tag)
      }));
    }

    if (message.length > 0 && ipVal.length > 0)
    {
      SetStatus(message.join(' '));
      hasError = true;
    }
    else
    {
      HideStatus();
      hasError = false;
      if (
          (key >= 97 && key <= 122) || // Small Letters
          (key >= 48 && key <= 57) || // Numbers
          (key >= 65 && key <= 90) // Capital Letters
      )
      {
        GetMoreTags(ipVal, function ()
        {
          $('body').trigger(evtReady);
          SetStatus(settings.messages.loadFail, true);
        });
      }
    }

    // Autocomplete (not if accept key)
    var min = Math.min(settings.lengths.cats.minChars, settings.lengths.tags.minChars);
    if (ipVal.length > min && IsEnabled())
    {
      auc.ShowSuggester(ipVal);
    }
    else
    {
      auc.HideSuggester();
    }
  };

  /**
   * @param {Event} _event
   */
  var OnKeyDown = function (_event)
  {
    var key = _event.which || _event.keyCode;

    if (inpGreyBecause.loading || inpGreyBecause.progDisabled)
    {
      console.log('Disabled in OnKeyDown');
      _event.preventDefault();
      return;
    }

    // Backspace
    if (key === 8)
    {
      if (bckspc.timer === -1 &&
          dom.input.val().length <= 0 &&
          selectedTags.length > 0)
      {
        // If backspace was actually pressed, not held
        // and there are no characters in the input field
        // and there are tags in the input field
        bckspc.timer = window.setTimeout(function ()
        {
          HandleBackspace();
        }, settings.inputField.deleteDelay);
        return;
      }
    }

    // Escape Key
    if (key === 27)
    {
      auc.HideSuggester(true);
    }

    if (inpGreyBecause.full)
    {
      _event.preventDefault();
      return;
    }

    if (key === 38)
    {
      // Up-Key
      _event.preventDefault();
      auc.ChangeSuggesterSelection(-1);
    }
    else if (key === 40)
    {
      // Down-Key
      _event.preventDefault();
      auc.ChangeSuggesterSelection(1);
    }

    // Accept Keys
    var inputValue = dom.input.val();
    if (settings.acceptKeys.indexOf(key) > -1)
    {
      _event.preventDefault();
      HandleAcceptKeys(inputValue);
    }
  };

  /**
   * Deals with deleting tags when the backspace timer times out
   * or on intention
   */
  var HandleBackspace = function ()
  {
    var lastTag = selectedTags[selectedTags.length - 1];
    window.clearTimeout(bckspc.timer);
    bckspc.timer    = -1;
    bckspc.delSomth = true;
    UnuseTag(lastTag);
  };

  /**
   * Aligns the tag suggestions with the input visually
   */
  var HandleResizeEvent = function ()
  {
    var newWidth  = dom.wrapper.outerWidth(true);
    var frameOffs = dom.wrapper.position();

    auc.Realign(newWidth, frameOffs);
  };

  /**
   * Sets other tag inputs as buddies. Buddies are also checked when adding
   * tags, making them exclusive between the inputs.
   * @param {jQuery[]} _otherTaggers
   */
  this.SetBuddies = function (_otherTaggers)
  {
    buddies = _otherTaggers;
  };

  /**
   * Indicates whether the plugin has tag in its output
   * @param {cttrTag} _tag
   * @return {cttrTag[]}
   */
  this.HasTagSelected = function (_tag)
  {
    return cttrUtils.IsSelected(settings, cache, selectedTags, _tag, true);
  };
}
; // End Plugin Class

/**
 * Tag class
 * @param {int} _iID
 * @param {string} _sName
 * @param {cttrCategory} _cCategory
 * @param {boolean} [_blacklisted]
 * @param _isNew
 * @class
 * @constructor
 */
var cttrTag = function (_iID, _sName, _cCategory, _blacklisted, _isNew)
{
  var self         = this;
  this.id          = +(_iID);
  this.name        = _sName;
  this.category    = _cCategory;
  this.blacklisted = _blacklisted || false;
  this.dom         = $('<div />');
  this.combName    = _cCategory.name + ':' + _sName;
  this.suggDom     = $('<li />');
  this.isNew       = (_isNew == null) ? false : _isNew;

  self.dom
      .addClass('cttr-tag')
      .data('cttr-ref', self);

  $('<div />').appendTo(self.dom)
              .addClass('cttr-category')
              .text(self.category.name);

  $('<div />').appendTo(self.dom)
              .addClass('cttr-name')
              .text(self.name);

  $('<span />').appendTo(self.dom)
               .addClass('cttr-delete')
               .text('x');

  // SUGGESTER DOM
  $('<span />').addClass('cttr-category')
               .text(self.category.name)
               .appendTo(self.suggDom)
               .css('color', self.category.color);

  $('<span />').addClass('cttr-name')
               .text(self.name)
               .appendTo(self.suggDom);

  this.SetColor = function (_color)
  {
    self.dom.css('color', _color)
        .css('border-color', _color);
  };

  /**
   * Checks whether this tag is the same as another
   * @param {cttrTag} _other
   * @returns {boolean}
   */
  this.Equal = function (_other)
  {
    return self.name.toLowerCase() === _other.name.toLowerCase() && self.category.Equal(_other.category);
  };

  /**
   * Flash and highlight this tag
   */
  this.Wow = function ()
  {
    self.dom
        .stop(true, true)
        .css({
          'opacity': 0.2
        })
        .animate({
          'opacity': 1
        }, 500);
  };

  /**
   * Removes the tag from the DOM without destroying it
   */
  this.Detach = function ()
  {
    self.dom.remove();
  };

  /**
   * Destroys the internals of this object
   */
  this.Destroy = function ()
  {
    if (self == null)
    {
      // Already destroyed, just waiting for garbage collection
      return;
    }

    self.dom.remove();
    self.dom = null;

    self.suggDom.remove();
    self.suggDom = null;

    self = null;
  };

  self.SetColor(self.category.color);
};
var cttrUtils = function ()
{
  var remoteLocked    = false;
  var runningRequests = [];
  var queryTimer;

  var UnlockRemote = function ()
  {
    remoteLocked = false;
  };

  this.ClearQueryTimer = function()
  {
    if(queryTimer != null)
    {
      clearTimeout(queryTimer);
    }
  };

  /**
   *
   * @param {object} p.data
   * @param {cSettings} p.settings
   * @param {function} [p.readyFn]
   * @param {boolean}  [p.force]
   * @param {function} [p.afterFn]
   * @param {*[]}      [p.afterFnArgs]
   * @param {function} p.failFn
   */
  this.MakeRequest = function (p)
  {
    if (p.force !== true && (remoteLocked || p.settings.remote.url.length <= 0))
    {
      return;
    }
    remoteLocked = true;

    queryTimer = setTimeout(UnlockRemote, p.settings.remote.waitBeforeQuery);

    var fd   = new FormData();
    var rObj = {
      topic : p.settings.remote.data.topic,
      action: p.settings.remote.data.action,
      data  : {}
    };

    rObj.data = $.extend({}, p.settings.remote.data, p.data);

    fd.append('request[]', JSON.stringify(rObj));

    var r = $.ajax
             ({
               url        : p.settings.remote.url,
               method     : p.settings.remote.method,
               timeout    : p.settings.remote.timeout,
               data       : fd,
               cache      : false,
               contentType: false,
               processData: false
             })
             .success(function (_json)
                 {
                   if (p.readyFn != null)
                   {
                     p.readyFn(_json);
                   }

                   if (p.afterFn != null)
                   {
                     // Call afterFn with its arguments
                     p.afterFn.apply(null, p.afterFnArgs);
                   }
                 }
             )
             .fail(function ()
             {
               if (p.failFn != null)
               {
                 p.failFn();
               }
             })
             .done(function ()
             {
               runningRequests = runningRequests.filter(function (_or)
               {
                 return _or !== r;
               });
             });

    runningRequests.push(r);
  };

  this.CancelRequests = function ()
  {
    runningRequests.forEach(function (t)
    {
      t.abort();
    });

    runningRequests = null;
  };
};

/**
 * Gets a cttrTag from a string. If the string has a colon,
 * it also deals with the category
 * @param {string}                                                  _string
 * @param {cSettings}                                               _settings
 * @param {{cats:cttrCategory[], tags:cttrTag[], banned:string[]}}  _cache
 * @param {cttrCategory}                                            [_defaultNewCat]     Default category for new tags
 * @return {cttrTag|{err:int, ifo:string}}                          err                  Is 1 if the user can not make tags,
 *                                                                                       2 if they can not make cats, 3 if there was no tag,
 *                                                                                       4 tag is blacklisted, 5 cat is blacklisted
 *                                                                                       6 tag isn't blacklisted in blacklist only mode :C
 *                                                                                       7 tag or category contain a bad word.
 */
cttrUtils.GetTagFromString = function (_string, _settings, _cache, _defaultNewCat)
{
  _string    = _string.trim();
  var catTag = _string.split(':');
  var idx    = _string.indexOf(':');

  if (_cache.banned.length > 0)
  {
    // Check if we're using banned words
    var pattern = '\\b(' + _cache.banned.join('|') + ')\\b';

    var r       = new RegExp(pattern, 'ig');
    var results = _string.match(r);
    if (results != null && results.length > 0)
    {
      return {
        err: 7,
        ifo: results.join(', ')
      };
    }
  }

  if(_defaultNewCat == null)
  {
    _defaultNewCat = _cache.cats[0];
  }

  switch (idx)
  {
    case -1:
      // No colon
      catTag[1] = catTag[0];
      catTag[0] = _defaultNewCat.name;
      _string = _defaultNewCat.name + ':' + catTag[1];
      break;
    case 0 :
      // Colon at the start.
      catTag[0] = _defaultNewCat.name;
      _string = _defaultNewCat.name + ':' + catTag[1];
      break;
  }

  if (catTag[1].length <= 0)
  {
    return {
      err: 3,
      ifo: ''
    };
  }

  // Check if the tags exist in the _cache
  var existTag = cttrUtils.GetByCombinedName(_cache.tags, _string);
  if (existTag.length > 1)
  {
    // More than one tag -> ignore
    return {
      err: 0,
      ifo: ''
    };
  }
  else if (existTag.length === 1)
  {
    if (!_settings.permissions.blacklistedOnly && (existTag[0].blacklisted || existTag[0].category.blacklisted))
    {
      // The tag is blacklisted and only in the _cache to inform the user that it is, in fact, blacklisted.
      return {
        err: ((existTag[0].category.blacklisted) ? 5 : 4),
        ifo: existTag[0].category.name
      };
    }
    else if (_settings.permissions.blacklistedOnly && !(existTag[0].blacklisted || existTag[0].category.blacklisted))
    {
      // The tag isn't blacklisted but we're in blacklistedOnly mode -> *baby cries*
      return {
        err: 6,
        ifo: existTag[0].category.name
      };
    }

    // Exactly one tag -> use that one
    return existTag[0];
  }

  // Is the user allowed to make new tags?
  if (!_settings.permissions.allowNewTag)
  {
    return {
      err: 1,
      ifo: ''
    };
  }

  // If the tags dont show up in _cache.tags, check if the category exists in avlCat
  var cat = GetCatFromString(catTag[0], _settings, _cache, _defaultNewCat);

  if (cat === null)
  {
    // The category couldn't be created due to permissions D:
    // So there isn't a tag to be made either. Bummer.
    return {
      err: 2,
      ifo: ''
    };
  }

  // New Tag from strings :D
  return new cttrTag(cttrUtils.GetUID(), catTag[1], cat, false, true);
};

/**
 * Gets a cttrCategory from a string. If the category cannot be resolved,
 * it will be created if the user has the permissions to do so.
 * @param {string}                          _catName
 * @param {cSettings}                _settings
 * @param {{cats:cttrCategory[], tags:cttrTag[]}} _cache
 * @param {cttrCategory} [_defaultNewCat]      Default category for new tags
 * @return {cttrCategory|null}
 */
var GetCatFromString = function (_catName, _settings, _cache, _defaultNewCat)
{
  // No catname? Well, then default cat! meow.
  if (_catName == null)
  {
    return _defaultNewCat;
  }

  // Check if the category exist in the _cache
  var existCat = cttrUtils.GetByName(_cache.cats, _catName);

  if (existCat.length > 1)
  {
    // More than one tag is bad news
    throw 'There can\'t be two categories with the same name in the data source.';
  }
  else if (existCat.length === 1)
  {
    // The category exists, use it
    return existCat[0];
  }

  // The category doesnt exist yet. Can we make it?
  if (!_settings.permissions.allowNewCat)
  {
    // Narp.
    return null;
  }

  // Yarp.
  var c = new cttrCategory(cttrUtils.GetUID(), _catName, '?');
  _cache.cats.push(c);
  return c;
}
;

/**
 * Gets a negative number derived from the time to use as an ID
 * @return {number}
 */
cttrUtils.GetUID = function ()
{
  return -(new Date()).getTime();
};

/**
 * Check whether _target has special characters in it.
 * @param {string} _target
 * @param {boolean} [_allowAsterisk]
 * @return {boolean}
 */
cttrUtils.HasSpecialChars = function (_target, _allowAsterisk)
{
  _allowAsterisk = (_allowAsterisk === true);

  // Regexes aren't G because we only need a
  // single instance of a special char to call it
  if (_allowAsterisk)
  {
    return /[.,!@#$%^&();\\/|<>"'+=?]/.test(_target);
  }
  return /[*.,!@#$%^&();\\/|<>"'+=?]/.test(_target);
};

/**
 * Format a template string
 * "Hello, %{name}, are you feeling %{adjective}?".formatTemplate({name:"Mike", adjective: "OK"});
 * @param {string}  _template
 * @param {{}}      _data
 * @return {string}
 */
cttrUtils.Format = function (_template, _data)
{
  'use strict';
  var str = _template.toString();
  for (var key in _data)
  {
    if (_data.hasOwnProperty(key))
    {
      var r = new RegExp('\\\%{' + key + '\\}', 'gi');
      str   = str.replace(r, _data[key]);
    }
  }

  return str;
};

/**
 *
 * @param _string
 * @return {string}
 */
cttrUtils.Capitalize = function (_string)
{
  return _string.charAt(0).toUpperCase() + _string.slice(1);
};

/**
 * Get id'd resources from an array
 * @param {cttrTag[]|cttrCategory[]} _arr
 * @param {int} _id
 * @return {cttrTag[]|cttrCategory[]}
 */
cttrUtils.GetByID = function (_arr, _id)
{
  return _arr.filter(function (_element)
  {
    return _element.id === _id;
  });
};

/**
 * Get named resources from an array, ignores case
 * @param {cttrTag[]|cttrCategory[]} _arr
 * @param {string} _name
 * @return {cttrTag[]|cttrCategory[]}
 */
cttrUtils.GetByName = function (_arr, _name)
{
  _name = _name.toLowerCase();
  return _arr.filter(function (_element)
  {
    return _element.name.toLowerCase() === _name;
  });
};

/**
 * Get tags from an array using their (combined) names.
 * @param {cttrTag[]} _arr
 * @param {string} _name
 * @return {cttrTag[]}
 */
cttrUtils.GetByCombinedName = function (_arr, _name)
{
  if (_name.indexOf(':') > -1)
  {
    return _arr.filter(function (_element)
    {
      return _element.combName.toLowerCase() === _name.toLowerCase();
    });
  }

  return cttrUtils.GetByName(_arr, _name);
};

/**
 * Determines if a name is too short or too long to be valid
 * @param {string} _name
 * @param {string} _which Either 'tags' or 'cats'
 * @param {cSettings} _settings
 * @return {int} -1 if too short, 1 if too long, 0 if ok
 */
cttrUtils.ValidateLength = function (_name, _which, _settings)
{
  if (_name.length < _settings.lengths[_which].minChars)
  {
    return -1;
  }
  else if (_name.length > _settings.lengths[_which].maxChars)
  {
    return 1;
  }
  return 0;
};

/**
 * Determines if its okay to add/show/use a given tag
 * @param {boolean} _blacklistedOnly
 * @param {cttrTag} _tag
 * @return {boolean}
 */
cttrUtils.GetBlacklistStatus = function (_blacklistedOnly, _tag)
{
  if (_blacklistedOnly)
  {
    if (!(_tag.blacklisted || _tag.category.blacklisted))
    {
      return false;
    }
  }
  else if (_tag.blacklisted || _tag.category.blacklisted)
  {
    return false;
  }

  return true;
};

/**
 * Checks if the tag exists in buddied inputs
 * @param {jQuery[]} _buddies Buddied inputs
 * @param {cttrTag} _tag
 * @return {cttrTag|boolean} The foreign tag object or false if not found
 */
cttrUtils.ExistsInBuddies = function (_buddies, _tag)
{
  if (_buddies.length <= 0)
  {
    return false;
  }

  // Look within buddied inputs
  var inBuddies = false;
  $.each(_buddies, function (_e, _orig)
  {
    var results = _orig.catTagger('hasTagSelected', _tag);

    // Call to a cattagger operation will produce an array of results
    $.each(results, function (_i, _tagArray)
    {
      // If any of the arrays within the result array have contents
      if (_tagArray.length > 0)
      {
        // Got it once so we can stop looking
        inBuddies = _tagArray[0];
        return false;
      }
    });

    if (inBuddies !== false)
    {
      // Quit the loop
      return false;
    }
  });

  return inBuddies;
};

/**
 * Check if we've already added a tag with this name and/or category
 * @param {cSettings} _settings
 * @param {object}    _cache
 * @param {cttrTag[]} _selectedTags
 * @param {cttrTag}   _tag
 * @param {boolean}   [_useInputCriteria]   If the check is for adding the tag to the origin input
 * @return {cttrTag[]}
 */
cttrUtils.IsSelected = function (_settings, _cache, _selectedTags, _tag, _useInputCriteria)
{
  _useInputCriteria = _useInputCriteria === true;

  return _selectedTags.filter(function (_e)
  {
    if (_settings.acceptAsterisk && _e.category.Equal(_cache.cats[1]) || (_settings.ignoreCatCheck && _useInputCriteria))
    {
      // Selected tag has star -> check the name rather than the category:name combo
      return _e.name.toLowerCase() === _tag.name.toLowerCase();
    }
    return _e.combName.toLowerCase() === _tag.combName.toLowerCase();
  });
};

/**
 * @typedef {object} JsnCat
 * @property {int} id
 * @property {string|null} name
 * @property {string} color
 * @property {boolean} [blacklisted]
 */

/**
 * @typedef {object} JsnTag
 * @property {int} id
 * @property {int} catID
 * @property {string|null} name
 * @property {boolean} [blacklisted]
 */

/**
 * @typedef {object} JsnTagSource
 * @property {JsnCat[]} categories
 * @property {JsnTag[]} tags
 */

/**
 * @typedef {object} cSettings
 * @property {string}  separator
 * @property {string}  use
 * @property {string[]}   bannedWords
 * @property {boolean} ignoreCatCheck
 * @property {boolean} cacheIDcheck
 * @property {boolean} showAllButton
 * @property {int[]}   acceptKeys
 * @property {number}  defCatID
 *
 * @property {boolean} useIDs
 * @property {boolean} acceptAsterisk
 * @property {string|null} forceColor
 *
 * @property {boolean} permissions.allowNewCat
 * @property {boolean} permissions.allowNewTag
 * @property {boolean} permissions.blacklistedOnly
 *
 * @property {boolean} inputField.enabled
 * @property {number}  inputField.maxTags
 * @property {number}  inputField.deleteDelay
 *
 * @property {number}  autocomplete.maxSuggestions
 *
 * @property {string|null}  remote.url
 * @property {boolean}      remote.fetchOnce
 * @property {string}       remote.method
 * @property {string|null}  remote.payloadScope
 * @property {{}}           remote.data
 * @property {number}       remote.timeout
 * @property {number}       remote.waitBeforeQuery
 *
 * @property {int} lengths.tags.maxChars
 * @property {int} lengths.tags.minChars
 * @property {int} lengths.cats.maxChars
 * @property {int} lengths.cats.minChars
 *
 * @property {string} messages.category
 * @property {string} messages.tag
 * @property {string} messages.invalidMaxLength
 * @property {string} messages.invalidMinLength
 * @property {string} messages.specialChars
 * @property {string} messages.placeholderCatTag
 * @property {string} messages.placeholderSingle
 * @property {string} messages.addingNotAllowed
 * @property {string} messages.tagBlacklisted
 * @property {string} messages.catBlacklisted
 * @property {string} messages.tooManyColons
 * @property {string} messages.loadFail
 */

})(jQuery);
