var GPSactive = false;
var mouseMarker;
var GPS = {
  Active: false,
};
var Navigate = null;
var DirectToControl = null;
var CancelNavControl = null;
var ReCenterControl = null;
var ExitControl = null;
var CenterNav = true;
var throttleGPS = false;
var mapArea = null;
var Mrotate = 0;
var AIRPORT_SNAPSHOT = null;
var APlist = [];
var Slist = [];
var WPTlist = [];
var WPTshared = [];
var RECENTlist = [];
var TrackUP = false;
var HeadingLine = false;
var CrosshairInfo = true;
var ShowCompass = false;
var DisplayUnits = {
  Distance: 0.000539957,
  DistanceU: 'nm',
  Speed: 1.94384,
  SpeedU: 'kt',
  Altitude: 3.28084,
  AltitudeU: 'ft',
};

function coordinates_hide() {
  $('#coordinates').hide();
  $('#NWInfo').css('top', '0px');
  $('#NNInfo').css('top', '0px');
  $('#NEInfo').css('top', '0px');
}
function changeDisplayUnits(index) {
  //always from meters, meters/sec to new unit
  var toNM = 0.000539957;
  var toMH = 2.23694;
  var toft = 3.28084;
  var toMI = 0.000621371;
  var toKT = 1.94384;
  var toKM = 0.001;
  var toKMH = 3.6;
  $('#DisplayUnitsNM').prop('checked', false);
  $('#DisplayUnitsNM2').prop('checked', false);
  $('#DisplayUnitsStatute').prop('checked', false);
  $('#DisplayUnitsMetric').prop('checked', false);
  switch (index) {
    case '0':
    case 0:
      DisplayUnits = {
        Distance: toNM,
        DistanceU: 'nm',
        Speed: toKT,
        SpeedU: 'kt',
        Altitude: toft,
        AltitudeU: 'ft',
      };
      $('#DisplayUnitsNM').prop('checked', true);
      break;
    case '1':
    case 1:
      DisplayUnits = {
        Distance: toNM,
        DistanceU: 'nm',
        Speed: toKT,
        SpeedU: 'kt',
        Altitude: 1,
        AltitudeU: 'm',
      };
      $('#DisplayUnitsNM2').prop('checked', true);
      break;
    case '2':
    case 2:
      DisplayUnits = {
        Distance: toMI,
        DistanceU: 'mi',
        Speed: toMH,
        SpeedU: 'mh',
        Altitude: toft,
        AltitudeU: 'ft',
      };
      $('#DisplayUnitsStatute').prop('checked', true);
      break;
    case '3':
    case 3:
      DisplayUnits = {
        Distance: toKM,
        DistanceU: 'km',
        Speed: toKMH,
        SpeedU: 'kh',
        Altitude: 1,
        AltitudeU: 'm',
      };
      $('#DisplayUnitsMetric').prop('checked', true);
      break;
  }
  localStorageDB.setItem('DisplayUnitsIndex', index);
  LOCALSTORAGE.DisplayUnitsIndex = index;
  if (MAPSTATE.Measure) {
    UpdateMeasureWindow();
  }
}

function getDisplayDistance(meters, showUnit = true) {
  var distance = meters * DisplayUnits.Distance;
  if (meters < 500) {
    if (DisplayUnits.DistanceU == 'nm') {
      if (showUnit) return Math.round(distance * 2025.37) + 'yd';
      else return Math.round(distance * 2025.37);
    }
    if (DisplayUnits.DistanceU == 'mi') {
      if (showUnit) return Math.round(distance * 1760) + 'yd';
      else return Math.round(distance * 1760);
    }
    if (DisplayUnits.DistanceU == 'km') {
      if (showUnit) return Math.round(distance * 1000) + 'm';
      else return Math.round(Distance * 1000);
    }
  } else {
    if (meters >= 500) {
      if (showUnit) return round10(distance) + DisplayUnits.DistanceU;
      else return round10(distance);
    } else {
      if (showUnit) return Math.round(distance) + DisplayUnits.DistanceU;
      else return Math.round(distance);
    }
  }
}
function getDisplaySpeed(mps, showUnit = true) {
  var speed = mps * DisplayUnits.Speed;
  if (speed > 100) {
    if (showUnit) return Math.round(speed) + DisplayUnits.SpeedU;
    else return Math.round(speed);
  } else {
    if (showUnit) return round10(speed) + DisplayUnits.SpeedU;
    else return round10(speed);
  }
}
function getDisplayAlt(meters, showUnit = true) {
  var alt = meters * DisplayUnits.Altitude;
  if (showUnit) return Math.round(alt) + DisplayUnits.AltitudeU;
  else return Math.round(alt);
}

function getcenterxy() {
  var screenX = Math.round(mapb.getContainer().clientWidth / 2);
  var screenY = Math.round(mapb.getContainer().clientHeight / 2);

  return [screenX, screenY];
}
function GetCrosshairInfo() {
  if (confirmHideMapInfo()) return;

  MAPSTATE.navigateTo = null;
  var point = getcenterxy();
  //update drag info window
  var buffersize = 15;
  var bounds = [
    [point[0] - buffersize, point[1] - buffersize],
    [point[0] + buffersize, point[1] + buffersize],
  ];
  if (CurPageID() == 'Map_Page') $('#coordinates').show();
  var features = mapb.queryRenderedFeatures(bounds);
  var F = null;
  var L = null;
  var FeatureName =
    '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./SpritesPNG/Crosshairs.png" width="15px" /> Crosshair';
  var Alt = '';
  if (features.length) {
    for (var i in features) {
      var I = features[i];
      L = I.layer.source;
      if (F != null) {
        if (F.layer['source-layer'] == 'airports') break;
      }
      switch (L) {
        case 'GPS':
        case 'GPS-NAV':
          continue;
          break;
        case 'WPTS':
          if (F == null) {
            F = I;
          } else if (
            F.layer.source != 'Airports' &&
            F.layer.source != 'Navaids' &&
            F.layer.source != 'AIRCRAFT' &&
            F.layer.source != 'Obstacles'
          )
            F = I;
          continue;
          break;
        case 'Obstacles':
          if (F == null) F = I;
          else if (F.layer.source != 'Airports' && F.layer.source != 'Navaids' && F.layer.source != 'AIRCRAFT') F = I;
          continue;
          break;
        case 'AIRCRAFT':
          if (F == null) F = I;
          else if (F.layer.source != 'Airports' && F.layer.source != 'Navaids') F = I;
          continue;
          break;
        case 'Navaids':
          if (F == null) F = I;
          else if (F.layer.source != 'Airports') F = I;
          continue;
          break;
        case 'Airports':
          if (I.layer['source-layer'] == 'airports') {
            F = I;
          }
          if (I.layer['source-layer'] == 'RWYA' && F == null) {
            F = I;
          }
          continue;
          break;
      }
      break;
    }
    var sourceStarts = features[0].layer.source.substr(0, 4);
    if (sourceStarts == 'wpts' && F == null) {
      F = I;
      FeatureName = 'Unknown Shared';
      if (F.properties.Label != undefined) FeatureName = F.properties.Label;
      else if (F.properties.label != undefined) FeatureName = F.properties.label;
      else if (F.properties.Name != undefined) FeatureName = F.properties.Name;
      else if (F.properties.name != undefined) FeatureName = F.properties.name;
      F.properties.CirroLabel = FeatureName;
      FeatureName =
        '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./images/CircleGreen.png" height="15px"> ' +
        FeatureName +
        ' [Shared]';
      F.properties.CirroLayerType = 'Shared';
    } else if (F == null) {
      //do nothing lets leave it for now
    } else {
      switch (F.layer.source) {
        case 'Obstacles':
          FeatureName =
            '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./images/Tower1.svg" height="15px"> ' +
            F.properties.Name +
            ' - [' +
            F.properties.Type +
            ']';
          F.properties.CirroLayerType = 'Obstacle';
          break;
        case 'WPTS':
          FeatureName =
            '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./images/CirclePurple.png" width="15px"> ' +
            F.properties.Name +
            ' [Device]';
          F.properties.CirroLayerType = 'Waypoint';
          break;
        case 'AIRCRAFT':
          FeatureName =
            '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./images/LC.png" height="15px">  ' +
            F.properties.Ident +
            ' - [Company Aircraft]';
          Alt = F.properties.Altitude + 'ft MSL';
          F.properties.CirroLayerType = 'Aircraft';
          break;
        case 'Airports':
          if (F.layer['source-layer'] == 'RWYA') {
            FeatureName =
              F.properties.Ident +
              ' RWY ' +
              F.properties.Rwy +
              ' - ' +
              F.properties.Surface +
              ' ' +
              parseInt(F.properties.Length) +
              'ft';
            F.properties.CirroLayerType = 'Runway';
          }
          if (F.layer['source-layer'] == 'airports') {
            FeatureName =
              '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./images/AptHardSvcCT.png" width="15px">  ' +
              F.properties.local_code +
              ' ' +
              F.properties.FreqType +
              ': ' +
              F.properties.Freq +
              ' - ' +
              F.properties.Name;
            Alt = F.properties.Altitude + 'ft MSL';
            F.properties.CirroLayerType = 'Airport';
          }
          break;
        case 'Navaids':
          var freq = 'Mhz';
          if (F.properties.Type == 'NDB' || F.properties.Type == 'NDB-DME') freq = 'kHz';

          FeatureName =
            '<img style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.5), rgba(255,255,255,0));" src="./images/' +
            F.properties.Type +
            '.png" height="15px">  ' +
            F.properties.ID +
            ' - ' +
            F.properties.Type +
            ': ' +
            F.properties.Freq +
            freq +
            ' - ' +
            F.properties.Name;
          F.properties.CirroLayerType = 'Navaid';
          break;
      }
    }
  }
  CrosshairDATA.feature = F;
  CrosshairDATA.FeatureName = FeatureName;
  CrosshairDATA.Alt = Alt;
  CrosshairDATA.cleardata = false;

  //UpdateCrosshairInfo();
  var center = mapb.getCenter();
  var html = '';
  var LongCoord = center.lng;
  var LongCoordTXT;
  var LatCoordTXT;
  var LatCoord = center.lat;
  var point1 = turf.point([GPS.lnglat.lng, GPS.lnglat.lat]);
  var point2 = turf.point([center.lng, center.lat]);
  if (F != null) {
    if (F.geometry.type == 'Point') {
      point2 = turf.point(F.geometry.coordinates);
      LongCoord = F.geometry.coordinates[0];
      LatCoord = F.geometry.coordinates[1];
      if (F.properties.Long == undefined) F.properties.Long = LongCoord;
      if (F.properties.Lat == undefined) F.properties.Lat = LatCoord;
    } else {
      if (F.properties.Lat == undefined || F.properties.Long == undefined) {
        F.properties.Lat = LatCoord;
        F.properties.Long = LongCoord;
      }
    }
  }
  var bearing = turf.bearing(point1, point2).toFixed(0);
  var distance = turf.distance(point1, point2, { units: 'meters' });
  if (F != null) {
    F.properties.distance = distance;
    F.properties.bearing = bearing;
  }
  if (bearing < 0) bearing = 360 + parseInt(bearing);

  if (LOCALSTORAGE.CoordFormat == 'UTM') {
    var utmOK = getUTM(LatCoord, LongCoord);
    LatCoordTXT = utmzone + ' 0' + utmlat;
    LongCoordTXT = 'UTM ' + utmlong;
  } else {
    LongCoordTXT = ConvertDD_User(LongCoord, 'Long');
    LatCoordTXT = ConvertDD_User(LatCoord, 'Lat');
  }

  var ETE = getETA(distance, GPS.speed);
  html +=
    '<div style="background-image: radial-gradient(rgba(255,255,255,1), rgba(255,255,255,0.3), rgba(255,255,255,0)); float: right; cursor:pointer;" onClick="directToCrosshairs();"><img src="./images/DirectTo.png" width="40" height="40" /></div><table class="first-td-fill"><tr><td class="coordinatetext">' +
    FeatureName +
    '<br /><div id="crosshairDATA1">' +
    getDisplayDistance(distance) +
    ' ' +
    FormatNumber(bearing, 3, 0) +
    '&degT ' +
    Alt +
    '<div style="float:right;"> ETE:' +
    ETE +
    '</div></div></td><td class="nowrap coordinatetext">' +
    LatCoordTXT +
    '<br />' +
    LongCoordTXT +
    '</td></tr></table>';

  //$("#coordinates").html(JSON.stringify(F, null, 2));
  var debug = '';
  //debug = JSON.stringify(F, null, 2);
  $('#coordinates').html(html + debug);
  //navigateTo(lnglat, WPTlist[e].Name, "Device");
  MAPSTATE.navigateTo = F;
  if (GPS.Active) {
    var height = $('#coordinates').height() + 5;
    $('#NWInfo').css('top', height + 'px');
    $('#NNInfo').css('top', height + 'px');
    $('#NEInfo').css('top', height + 'px');
  } else {
    $('#NWInfo').css('top', '0px');
    $('#NNInfo').css('top', '0px');
    $('#NEInfo').css('top', '0px');
  }
}

var CrosshairDATA = {
  feature: null,
  FeatureName: null,
  Alt: '',
  cleardata: true,
  Timeout: null,
};

function UpdateCrosshairInfo() {
  if (confirmHideMapInfo() || CrosshairDATA.cleardata || MAPSTATE.Busy) return;

  var F = CrosshairDATA.feature;
  var FeatureName = CrosshairDATA.FeatureName;
  var Alt = CrosshairDATA.Alt;

  var center = mapb.getCenter();
  var html = '';
  var LongCoord = center.lng;
  var LongCoordTXT;
  var LatCoordTXT;
  var LatCoord = center.lat;
  var point1 = turf.point([GPS.lnglat.lng, GPS.lnglat.lat]);
  var point2 = turf.point([center.lng, center.lat]);
  if (F != null) {
    if (F.geometry.type == 'Point') {
      point2 = turf.point(F.geometry.coordinates);
      LongCoord = F.geometry.coordinates[0];
      LatCoord = F.geometry.coordinates[1];
      if (F.properties.Long == undefined) F.properties.Long = LongCoord;
      if (F.properties.Lat == undefined) F.properties.Lat = LatCoord;
    } else {
      if (F.properties.Lat == undefined || F.properties.Long == undefined) {
        F.properties.Lat = LatCoord;
        F.properties.Long = LongCoord;
      }
    }
  }
  var bearing = turf.bearing(point1, point2).toFixed(0);
  var distance = turf.distance(point1, point2, { units: 'meters' });
  if (F != null) {
    F.properties.distance = distance;
    F.properties.bearing = bearing;
  }
  if (bearing < 0) bearing = 360 + parseInt(bearing);

  if (LOCALSTORAGE.CoordFormat == 'UTM') {
    var utmOK = getUTM(LatCoord, LongCoord);
    LatCoordTXT = utmzone + ' 0' + utmlat;
    LongCoordTXT = 'UTM ' + utmlong;
  } else {
    LongCoordTXT = ConvertDD_User(LongCoord, 'Long');
    LatCoordTXT = ConvertDD_User(LatCoord, 'Lat');
  }

  var ETE = getETA(distance, GPS.speed);
  $('#crosshairDATA1').html(
    getDisplayDistance(distance) +
      ' ' +
      FormatNumber(bearing, 3, 0) +
      '&degT ' +
      Alt +
      '<div style="float:right;"> ETE:' +
      ETE +
      '</div>'
  );

  MAPSTATE.navigateTo = F;
  if (GPS.Active) {
    var height = $('#coordinates').height() + 5;

    $('#NWInfo').css('top', height + 'px');
    $('#NNInfo').css('top', height + 'px');
    $('#NEInfo').css('top', height + 'px');
  } else {
    $('#NWInfo').css('top', '0px');
    $('#NNInfo').css('top', '0px');
    $('#NEInfo').css('top', '0px');
  }
}

function navigatesdaf() {
  navigateToMarker('CYQT', 48.371996694444, -89.321706027778, 'Airport');
}
function directToCrosshairs() {
  if (MAPSTATE.navigateTo == null) {
    localStorageDB.getItem('DeviceWPTS', function (e) {
      var Index = 0;
      var DeviceWPTS = e;
      if (e != null && e != 'false') {
        DeviceWPTS = JSON.parse(e);
        Index = DeviceWPTS.length;
      } else {
        DeviceWPTS = [];
      }
      var point = mapb.getCenter();
      DeviceWPTS.push({
        Index: Index,
        Name: Index,
        Lat: point.lat,
        Long: point.lng,
      });
      localStorageDB.setItem('DeviceWPTS', JSON.stringify(DeviceWPTS));
      WP_Filter({
        id: 'deviceON',
      });
      navigateToMarker(Index, point.lat, point.lng, 'Device');
    });
  } else {
    var P = MAPSTATE.navigateTo.properties;
    switch (P.CirroLayerType) {
      case 'Waypoint':
        navigateToMarker(P.Name, P.Lat, P.Long, 'Device');
        break;
      case 'Airport':
        var Label;
        if (P.ICAO.length == 4) Label = P.ICAO;
        if (P.ICAO.length == 3) {
          Label = P.local_code;
        }
        var ICAO = Label.substring(0, 4);
        if ($('#searchAirportICAO').val() != ICAO) {
          $('#searchAirportICAO').val(ICAO);
          $('#AirportTabDirectTo').click();
          searchICAO_Airport(ICAO);
        }
        coordinates_hide();
        $('#gpsMenu').tabs();
        $('#gpsMenu').show();
        break;
      case 'Runway':
        navigateToMarker(P.Ident + ' RWY ' + P.Rwy, P.Lat, P.Long, 'Runway');
        break;
      case 'Obstacle':
        navigateToMarker(P.Name, P.Lat, P.Long, 'Obstacle');
        break;
      case 'Navaid':
        //navigateToMarker('UAU - MCKAY',48.373636,-89.328958, 'TACAN')
        var ID = P.ID.substring(0, 3);
        if ($('#searchNavaidIdent').val() != ID) {
          $('#searchNavaidIdent').val(ID);
          $('#NavaidTabDirectTo').click();
          searchNavaids(ID);
        }
        coordinates_hide();
        $('#gpsMenu').tabs();
        $('#gpsMenu').show();
        break;
      case 'Aircraft':
        navigateToMarker(P.Ident, P.Lat, P.Long, 'Aircraft');
        break;
      case 'Shared':
        navigateToMarker(P.CirroLabel, P.Lat, P.Long, 'Shared');
        break;
    }
  }
}

function gpsMenu() {
  if (MAPSTATE.directTo_type == 'Airport') {
    $('#AirportTabDirectTo').click();
    if ($('#searchAirportICAO').val() != MAPSTATE.directTo_Label) {
      $('#searchAirportICAO').val(MAPSTATE.directTo_Label);
      searchICAO_Airport(MAPSTATE.directTo_Label);
    }
  }
  if (
    MAPSTATE.directTo_type == 'VOR' ||
    MAPSTATE.directTo_type == 'NDB' ||
    MAPSTATE.directTo_type == 'VORTAC' ||
    MAPSTATE.directTo_type == 'TACAN' ||
    MAPSTATE.directTo_type == 'VOR-DME' ||
    MAPSTATE.directTo_type == 'DME' ||
    MAPSTATE.directTo_type == 'NDB-DME'
  ) {
    $('#NavaidTabDirectTo').click();
    if ($('#searchNavaidIdent').val() != MAPSTATE.directTo_Label) {
      $('#searchNavaidIdent').val(MAPSTATE.directTo_Label);
      searchNavaids(MAPSTATE.directTo_Label);
    }
  }
  $('#gpsSearchMenu').hide();
  coordinates_hide();
  $('#acPanel').hide();
  $('#gpsMenu').tabs();
  $('#gpsMenu').show();

  if (mapb != null) mapb.on('click', closeDirectoTo);
  //if (!GPS.Active && !RoutePlanningON) toggleGPSactive();
  if (!GPS.Active) {
    if (LOCALSTORAGE.GPS_LastPos != undefined) GPS.lnglat = LOCALSTORAGE.GPS_LastPos;
    else GPS.lnglat = mapb.getCenter();
    GPS.heading = 0;
    GPS.mheading = 0;
    //if routeplanning use routeplanning speed???
  }
  getRecentWaypoints(false);
  directToWaypoints(false);
  if (NavaidPreviewMap != null) {
    NavaidPreviewMap.resize();
    NavaidPreviewMap.setCenter(MAPSTATE.NavaidPreviewCenter);
  }
  if (AirportPreviewMap != null) {
    AirportPreviewMap.resize();
    AirportPreviewMap.setCenter(MAPSTATE.AirportPreviewCenter);
  }
}
function closeDirectoTo() {
  mapb.on('click', closeDirectoTo);
  $('#gpsMenu').hide();
}
function closeSearchMenu() {
  mapb.on('click', closeSearchMenu);
  $('#gpsSearchMenu').hide();
}
function SearchMapMenu() {
  $('#gpsMenu').hide();
  coordinates_hide();
  $('#acPanel').hide();
  $('#gpsSearchMenu').tabs();
  $('#gpsSearchMenu').show();
  if (mapb != null) mapb.on('click', closeSearchMenu);
  if (!GPS.Active) {
    if (LOCALSTORAGE.GPS_LastPos != undefined) GPS.lnglat = LOCALSTORAGE.GPS_LastPos;
    else GPS.lnglat = mapb.getCenter();
    GPS.heading = 0;
    GPS.mheading = 0;
    //if routeplanning use routeplanning speed???
  }
  searchArea_AirportMOBILE();
  searchNavaidsDB('');
  directToWaypoints(true);
  getRecentWaypoints(true);
  searchPOI(true);
}

function DTsearch(e) {
  $('#searchAirportResults').html('');
}
var GCresults = [];

function searchAddress(e) {
  if (ONLINE) {
    var text = $('#search-waypoint-text').val();
    var center = {
      lat: 0,
      lng: 0,
    };
    if (mapb != null) mapb.getBounds().getCenter();
    if (GPS.Active) center = GPS.lnglat;
    var Lat = center.lat;
    var Long = center.lng;
    var search = encodeURIComponent(e.value);
    if (search.length < 3) return;
    GCresults = [];
    $.getJSON(
      'https://api.mapbox.com/geocoding/v5/mapbox.places/' + search + '.json?access_token=' + MAPBOX_TOKEN,
      function (data) {
        localStorageDB.getItem('DeviceWPTS', function (e) {
          var items = 0;
          var DeviceWPTS = e;
          if (e != null && e != 'false') {
            DeviceWPTS = JSON.parse(e);
          } else {
            DeviceWPTS = [];
          }
          $('#searchAirportResults').html('');
          var htmld =
            '<table class="first-td-fill gpsmenuResult" ><thead><tr><th>Address</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
          for (var i in data.features) {
            var D = data.features[i];
            var point1 = turf.point([Long, Lat]);
            var point2 = turf.point(D.center);
            var bearing = turf.bearing(point1, point2).toFixed(0);
            var distance = turf.distance(point1, point2, { units: 'meters' });
            if (bearing < 0) bearing = 360 + parseInt(bearing);
            D.bearing = bearing;
            D.distance = distance;
            GCresults.push(D);
          }
          GCresults.sort(function (a, b) {
            return parseFloat(a.distance) - parseFloat(b.distance);
          });
          for (var b in GCresults) {
            htmld +=
              '<tr onClick="GCnavTo(' +
              b +
              ')" class="selectable"><td>' +
              GCresults[b].place_name +
              '</td><td>' +
              GCresults[b].bearing +
              '&degT</td><td>' +
              getDisplayDistance(GCresults[b].distance) +
              '</td></tr>';
          }
          htmld += '</tbody></table>';
          $('#AddressSearchResults').html(htmld);
        });
      }
    )
      .success(function () {
        verbose.log('DEBUG', 'Success');
      })
      .error(function (jqXHR, textStatus, errorThrown) {
        verbose.log('DEBUG', 'Success');
      })
      .complete(function () {
        verbose.log('DEBUG', 'Complete');
      });
  } else {
    $('#searchAirportResults').html('<h3>Not Available Offline.</h3>');
    //do nothing offline can't get
  }
}

function GCnavTo(N) {
  if (RoutePlanningON == false) $('#gpsMenu').hide();
  var N = GCresults[N];
  var lnglat = new mapboxgl.LngLat(N.center[0], N.center[1]);
  navigateTo(lnglat, N.place_name, 'Address');
}

var searchICAO_timeout = null;
function searchICAO(e) {
  if (e.length < 2) {
    $('#searchAirportResults').html('<h3>Enter at least 2 characters to search.</h3>');
    return;
  }

  //searchAirports(,"ICAO",filterLast);
  if (searchICAO_timeout != null) clearTimeout(searchICAO_timeout);
  searchICAO_timeout = setTimeout(function () {
    searchICAO_timeout = null;
    searchICAO_Airport(e.toUpperCase(), 'ICAO');
  }, 750);
}
var searchName2_timeout = null;
function searchName2() {
  if (searchName2_timeout != null) clearTimeout(searchName2_timeout);
  searchName2_timeout = setTimeout(function () {
    searchName2_timeout = null;
    searchName($('#search-airport-text').val());
  }, 750);
}

function searchName(e) {
  if (e.length < 3) {
    $('#searchAirportResults').html('<h3>Enter at least 3 characters to search.</h3>');
    return;
  }
  searchText_Airport(e);
}

function searchNameWaypoint() {
  var text = $('#search-waypoint-text').val();
  var center = {
    lat: 0,
    lng: 0,
  };
  if (mapb != null) mapb.getBounds().getCenter();
  if (GPS.Active) center = GPS.lnglat;
  var Lat = center.lat;
  var Long = center.lng;
  var items = 0;
  localStorageDB.getItem('DeviceWPTS', function (e) {
    $('#searchAirportResults').html('');
    var htmld =
      '<table class="first-td-fill gpsmenuResult" ><thead><tr><th>Waypoint</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
    if (e != null && e != 'false') {
      var DeviceWPTS = JSON.parse(e);
      if (DeviceWPTS.length) {
        for (var u in DeviceWPTS) {
          var D = DeviceWPTS[u];
          var point1 = turf.point([Long, Lat]);
          var point2 = turf.point([D.Long, D.Lat]);
          var bearing = turf.bearing(point1, point2).toFixed(0);
          var distance = turf.distance(point1, point2, { units: 'meters' });
          if (bearing < 0) bearing = 360 + parseInt(bearing);
          D.bearing = bearing;
          D.distance = distance;
          var n = D.Name.match(new RegExp(text, 'i'));
          if (n) {
            htmld +=
              '<tr onClick="DnavTo(' +
              u +
              ')"><td>' +
              D.Name +
              '<div style="font-size:10pt;">Device Waypoint</td><td>' +
              D.bearing +
              '&degT</td><td>' +
              getDisplayDistance(D.distance) +
              '</td></tr>';
            items++;
          }
        }
      }
    }
    for (var s in WPTshared) {
      for (var S in WPTshared[s]) {
        var N = WPTshared[s][S];
        var point1 = turf.point([Long, Lat]);
        var point2 = turf.point([N.geometry.coordinates[0], N.geometry.coordinates[1]]);
        var bearing = turf.bearing(point1, point2).toFixed(0);
        var distance = turf.distance(point1, point2, { units: 'meters' });
        if (bearing < 0) bearing = 360 + parseInt(bearing);
        N.properties.bearing = bearing;
        N.properties.distance = distance;
        var n = N.properties.Name.match(new RegExp(text, 'i'));
        if (n) {
          htmld +=
            '<tr onClick="ShnavTo(\'' +
            s +
            "'," +
            S +
            ')"><td>' +
            N.Name +
            '<div style="font-size:10pt;">' +
            N.CirroLayer +
            '</td><td>' +
            N.properties.bearing +
            '&degT</td><td>' +
            getDisplayDistance(N.properties.distance) +
            '</td></tr>';
          items++;
        }
      }
    }
    if (items > 0) {
      htmld += '</tbody></table>';
      $('#searchAirportResults').html(htmld);
    } else {
      $('#searchAirportResults').html('<h3>Nothing Found</h3>');
    }
  });
}

function directToWaypoints(nearest) {
  if (nearest) {
    var center = {
      lat: 0,
      lng: 0,
    };
    if (mapb != null) mapb.getBounds().getCenter();
    if (GPS.Active) center = GPS.lnglat;
    var Lat = center.lat;
    var Long = center.lng;
    var items = 0;
    localStorageDB.getItem('DeviceWPTS', function (e) {
      WPTlist = [];
      $('#searchtabs-User').html('');
      var htmld = '';

      htmld +=
        '<table class="second-td-fill gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Waypoint</th><th>Type</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
      if (e != null && e != 'false') {
        var DeviceWPTS = JSON.parse(e);
        if (DeviceWPTS.length) {
          for (var u in DeviceWPTS) {
            var D = DeviceWPTS[u];
            var point1 = turf.point([Long, Lat]);
            var point2 = turf.point([D.Long, D.Lat]);
            var bearing = turf.bearing(point1, point2).toFixed(0);
            var distance = turf.distance(point1, point2, { units: 'meters' });
            if (bearing < 0) bearing = 360 + parseInt(bearing);
            D.bearing = bearing;
            D.distance = distance;
            D.Type = 'Device';
            //htmld += '<tr onClick="DnavTo(' + u + ')" class="selectable"><td>' + D.Name + '</td><td>' + D.Type + '</td><td>' + D.bearing + '&degT</td><td>' + D.distance + ' nm</td></tr>';
            WPTlist.push(D);
          }
        }
      }

      for (var s in WPTshared) {
        for (var S in WPTshared[s]) {
          var N = WPTshared[s][S];
          var point1 = turf.point([Long, Lat]);
          var point2 = turf.point([N.geometry.coordinates[0], N.geometry.coordinates[1]]);
          var bearing = turf.bearing(point1, point2).toFixed(0);
          var distance = turf.distance(point1, point2, { units: 'meters' });
          if (bearing < 0) bearing = 360 + parseInt(bearing);
          N.properties.bearing = bearing;
          N.properties.distance = distance;
          var Name = 'N/A';
          if (N.properties.Name != undefined) Name = N.properties.Name;
          if (N.properties.name != undefined) Name = N.properties.name;
          if (N.properties.Label != undefined) Name = N.properties.Label;
          if (N.properties.label != undefined) Name = N.properties.label;
          if (Name == 'N/A') N.properties.Name = 'N/A';
          N.properties.Lat = N.geometry.coordinates[1];
          N.properties.Long = N.geometry.coordinates[0];
          N.properties.Type = 'Shared';
          WPTlist.push(N.properties);
          //htmld += '<tr onClick="ShnavTo(\'' + s + '\',' + S + ')" class="selectable"><td>' + Name  + '</td><td>Shared</td><td>' + N.properties.bearing + '&degT</td><td>' + N.properties.distance + ' nm</td></tr>';
        }
      }
      //sort WPTlist by name
      WPTlist.sort(function (a, b) {
        return parseFloat(a.distance) - parseFloat(b.distance);
      });
      var count = 0;
      for (var i in WPTlist) {
        var I = WPTlist[i];
        var Name2 = '';
        var Icon = 'CircleRed.png';
        if (I.Type == 'Shared') {
          Name2 = '<div style="font-size:10pt;">' + I.CirroLayer + '</div>';
          Icon = 'CircleGreen.png';
        }
        htmld +=
          '<tr onClick="UserNavTo(' +
          i +
          ')" class="selectable"><td><img src="./images/' +
          Icon +
          '" width="20px;"</td><td class="directToTxt">' +
          I.Name +
          Name2 +
          '</td><td>' +
          I.Type +
          '</td><td>' +
          I.bearing +
          '&degT</td><td>' +
          getDisplayDistance(I.distance) +
          '</td></tr>';
        count++;
        if (count > 200) {
          break;
        }
      }

      if (count > 0) {
        htmld += '</tbody></table>';
        $('#searchtabs-User').html(htmld);
        $('#searchtabs-User').enhanceWithin();
      } else {
        $('#searchtabs-User').html('<h3>No user waypoints</h3>');
      }
    });
  } else {
    var center = {
      lat: 0,
      lng: 0,
    };
    if (mapb != null) mapb.getBounds().getCenter();
    if (GPS.Active) center = GPS.lnglat;
    var Lat = center.lat;
    var Long = center.lng;
    var items = 0;
    localStorageDB.getItem('DeviceWPTS', function (e) {
      WPTlist = [];
      $('#directTotabs-User').html('');
      var htmld = '';

      htmld +=
        '<table class="second-td-fill gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Waypoint</th><th>Type</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
      if (e != null && e != 'false') {
        var DeviceWPTS = JSON.parse(e);
        if (DeviceWPTS.length) {
          for (var u in DeviceWPTS) {
            var D = DeviceWPTS[u];
            var point1 = turf.point([Long, Lat]);
            var point2 = turf.point([D.Long, D.Lat]);
            var bearing = turf.bearing(point1, point2).toFixed(0);
            var distance = turf.distance(point1, point2, { units: 'meters' });
            if (bearing < 0) bearing = 360 + parseInt(bearing);
            D.bearing = bearing;
            D.distance = distance;
            D.Type = 'Device';
            //htmld += '<tr onClick="DnavTo(' + u + ')" class="selectable"><td>' + D.Name + '</td><td>' + D.Type + '</td><td>' + D.bearing + '&degT</td><td>' + D.distance + ' nm</td></tr>';
            WPTlist.push(D);
          }
        }
      }

      for (var s in WPTshared) {
        for (var S in WPTshared[s]) {
          var N = WPTshared[s][S];
          var point1 = turf.point([Long, Lat]);
          var point2 = turf.point([N.geometry.coordinates[0], N.geometry.coordinates[1]]);
          var bearing = turf.bearing(point1, point2).toFixed(0);
          var distance = turf.distance(point1, point2, { units: 'meters' });
          if (bearing < 0) bearing = 360 + parseInt(bearing);
          N.properties.bearing = bearing;
          N.properties.distance = distance;
          var Name = 'N/A';
          if (N.properties.Name != undefined) Name = N.properties.Name;
          if (N.properties.name != undefined) Name = N.properties.name;
          if (N.properties.Label != undefined) Name = N.properties.Label;
          if (N.properties.label != undefined) Name = N.properties.label;
          if (Name == 'N/A') N.properties.Name = 'N/A';
          N.properties.Lat = N.geometry.coordinates[1];
          N.properties.Long = N.geometry.coordinates[0];
          N.properties.Type = 'Shared';
          WPTlist.push(N.properties);
          //htmld += '<tr onClick="ShnavTo(\'' + s + '\',' + S + ')" class="selectable"><td>' + Name  + '</td><td>Shared</td><td>' + N.properties.bearing + '&degT</td><td>' + N.properties.distance + ' nm</td></tr>';
        }
      }
      //sort WPTlist by name
      WPTlist.sort(function (a, b) {
        if (a.Name < b.Name) {
          return -1;
        }
        if (a.Name > b.Name) {
          return 1;
        }
        return 0;
      });
      var count = 0;
      for (var i in WPTlist) {
        var I = WPTlist[i];
        var Name2 = '';
        var Icon = 'CircleRed.png';
        if (I.Type == 'Shared') {
          Name2 = '<div style="font-size:10pt;">' + I.CirroLayer + '</div>';
          Icon = 'CircleGreen.png';
        }
        htmld +=
          '<tr onClick="UserNavTo(' +
          i +
          ')" class="selectable"><td><img src="./images/' +
          Icon +
          '" width="20px;"</td><td class="directToTxt">' +
          I.Name +
          Name2 +
          '</td><td>' +
          I.Type +
          '</td><td>' +
          I.bearing +
          '&degT</td><td>' +
          getDisplayDistance(I.distance) +
          '</td></tr>';
        count++;
        /*
                if (count > 200) {
                    break;
                }
				*/
      }

      if (count > 0) {
        htmld += '</tbody></table>';
        $('#directTotabs-User').html(htmld);
        $('#directTotabs-User').enhanceWithin();
      } else {
        $('#directTotabs-User').html('<h3>No user waypoints</h3>');
      }
    });
  }
}

function getRecentWaypoints(nearest = false) {
  if (nearest) {
    $('#searchtabs-Recent').html('<h3>Refreshing List...</h3>');
    localStorageDB.getItem('RECENT', function (e) {
      if (e == null) {
        $('#searchtabs-Recent').html('<h3>No recent waypoints</h3>');
        return;
      }
      var DeviceWPTS = e;
      if (e != null && e != 'false') {
        DeviceWPTS = JSON.parse(e);
      } else {
        DeviceWPTS = [];
      }
      RECENTlist = [];
      if (DeviceWPTS.length) {
        var center = {
          lat: 0,
          lng: 0,
        };
        if (mapb != null) mapb.getBounds().getCenter();
        if (GPS.Active) center = GPS.lnglat;
        var Lat = center.lat;
        var Long = center.lng;

        $('#searchtabs-Recent').html('');
        var htmld =
          '<table class="second-td-fill gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Waypoint</th><th>Type</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
        var count = 0;

        for (var u in DeviceWPTS) {
          var D = DeviceWPTS[u];
          var point1 = turf.point([Long, Lat]);
          var point2 = turf.point([D.Long, D.Lat]);
          var bearing = turf.bearing(point1, point2).toFixed(0);
          var distance = turf.distance(point1, point2, { units: 'meters' });
          if (bearing < 0) bearing = 360 + parseInt(bearing);
          D.bearing = bearing;
          D.distance = distance;
        }
        //do the sort

        DeviceWPTS.sort(function (a, b) {
          return parseFloat(a.distance) - parseFloat(b.distance);
        });
        for (var u in DeviceWPTS) {
          var D = DeviceWPTS[u];
          if (D.Label != undefined) {
            var Icon = 'CirclePurple.png';
            switch (D.type) {
              case 'Airport':
                Icon = 'AptHardSvcCT.png';
                break;
              case 'Device':
                Icon = 'CircleRed.png';
                break;
              case 'Shared':
                Icon = 'CircleGreen.png';
                break;
              case 'NDB':
              case 'TACAN':
              case 'VOR-DME':
              case 'DME':
              case 'VOR':
              case 'VORTAC':
              case 'NDB-DME':
                Icon = D.type + '.png';
                break;
              case 'Obstacle':
                Icon = 'Tower1.svg';
                break;
              case 'Aircraft':
                Icon = 'LC.png';
                break;
              case 'Shared':
                Icon = 'CircleGreen.png';
                break;
            }
            htmld +=
              '<tr onClick="RnavTo(' +
              count +
              ')" class="selectable"><td><img src="./images/' +
              Icon +
              '" width="20px" /></td><td height="50">' +
              D.Label +
              '</td><td>' +
              D.type +
              '</td><td>' +
              D.bearing +
              '&degT</td><td>' +
              getDisplayDistance(D.distance) +
              '</td></tr>';
            RECENTlist.push(D);
            count++;
            if (count > 50) break; //don't load / save more than 25 newest
          }
        }
        htmld += '</tbody></table>';
        $('#searchtabs-Recent').html(htmld);
        $('#searchtabs-Recent').enhanceWithin();
      } else {
        $('#searchtabs-Recent').html('<h3>No recent waypoints</h3>');
      }
    });
  } else {
    $('#directTotabs-Recent').html('<h3>Refreshing List...</h3>');
    localStorageDB.getItem('RECENT', function (e) {
      if (e == null) {
        $('#directTotabs-Recent').html('<h3>No recent waypoints</h3>');
        return;
      }
      var DeviceWPTS = e;
      if (e != null && e != 'false') {
        DeviceWPTS = JSON.parse(e);
      } else {
        DeviceWPTS = [];
      }
      RECENTlist = [];
      if (DeviceWPTS.length) {
        var center = {
          lat: 0,
          lng: 0,
        };
        if (mapb != null) mapb.getBounds().getCenter();
        if (GPS.Active) center = GPS.lnglat;
        var Lat = center.lat;
        var Long = center.lng;

        $('#directTotabs-Recent').html('');
        var htmld =
          '<table class="second-td-fill gpsmenuResult" data-filter="true"><thead><tr><th></th><th>Waypoint</th><th>Type</th><th>Bearing</th><th>Dist</th></tr></thead><tbody>';
        var count = 0;
        for (var u in DeviceWPTS) {
          var D = DeviceWPTS[u];
          var point1 = turf.point([Long, Lat]);
          var point2 = turf.point([D.Long, D.Lat]);
          var bearing = turf.bearing(point1, point2).toFixed(0);
          var distance = turf.distance(point1, point2, { units: 'meters' });
          if (bearing < 0) bearing = 360 + parseInt(bearing);
          D.bearing = bearing;
          D.distance = distance;
        }
        //do the sort

        //Disable sort fo rnow
        /*
                DeviceWPTS.sort(function(a, b) {
                    if(a.Label < b.Label) { return -1; }
                    if(a.Label > b.Label) { return 1; }
                    return 0;
                });
				*/
        DeviceWPTS.reverse(); //show most recent used first
        for (var u in DeviceWPTS) {
          var D = DeviceWPTS[u];
          if (D.Label != undefined) {
            var Icon = 'CirclePurple.png';
            switch (D.type) {
              case 'Airport':
                Icon = 'AptHardSvcCT.png';
                break;
              case 'Device':
                Icon = 'CircleRed.png';
                break;
              case 'Shared':
                Icon = 'CircleGreen.png';
                break;
              case 'NDB':
              case 'TACAN':
              case 'VOR-DME':
              case 'DME':
              case 'VOR':
              case 'VORTAC':
              case 'NDB-DME':
                Icon = D.type + '.png';
                break;
              case 'Obstacle':
                Icon = 'Tower1.svg';
                break;
              case 'Aircraft':
                Icon = 'LC.png';
                break;
              case 'Shared':
                Icon = 'CircleGreen.png';
                break;
              case 'POI':
                Icon = 'circle-11.png';
                break;
            }
            htmld +=
              '<tr onClick="RnavTo(' +
              count +
              ')" class="selectable"><td><img src="./images/' +
              Icon +
              '" width="20px" /></td><td height="50">' +
              D.Label +
              '</td><td>' +
              D.type +
              '</td><td>' +
              D.bearing +
              '&degT</td><td>' +
              getDisplayDistance(D.distance) +
              '</td></tr>';
            RECENTlist.push(D);
            count++;
            if (count > 50) break; //don't load / save more than 25 newest
          }
        }
        htmld += '</tbody></table>';
        $('#directTotabs-Recent').html(htmld);
        $('#directTotabs-Recent').enhanceWithin();
      } else {
        $('#directTotabs-Recent').html('<h3>No recent waypoints</h3>');
      }
    });
  }
}

function closeDirectoTo() {
  mapb.off('click', closeDirectoTo);
  $('#gpsMenu').hide();
}

function closegpsSearchMenu() {
  mapb.off('click', closegpsSearchMenu);
  $('#gpsSearchMenu').hide();
}

function navTo(e) {
  mapb.off('click', closegpsSearchMenu);
  if (RoutePlanningON == false) $('#gpsMenu').hide();
  var lnglat = new mapboxgl.LngLat(APlist[e].Long, APlist[e].Lat);
  navigateTo(lnglat, APlist[e].ICAO + ' - ' + APlist[e].Name, 'Airport');
}

function SnavTo(e) {
  mapb.off('click', closeDirectoTo);
  if (RoutePlanningON == false) $('#gpsMenu').hide();
  var lnglat = new mapboxgl.LngLat(Slist[e].Long, Slist[e].Lat);
  navigateTo(lnglat, Slist[e].ICAO + ' - ' + Slist[e].Name, 'Airport');
}

function DnavTo(e) {
  mapb.off('click', closeDirectoTo);
  if (RoutePlanningON == false) $('#gpsMenu').hide();
  var lnglat = new mapboxgl.LngLat(WPTlist[e].Long, WPTlist[e].Lat);
  navigateTo(lnglat, WPTlist[e].Name, 'Device');
}

function RnavTo(e) {
  mapb.off('click', closeDirectoTo);
  if (RoutePlanningON == false) $('#gpsMenu').hide();
  var a = RECENTlist.splice(e, 1); // removes the item
  var lnglat = new mapboxgl.LngLat(a[0].Long, a[0].Lat);
  $('#gpsMenu').hide();
  $('#gpsSearchMenu').hide();
  //navigateTo(lnglat, a[0].Label, a[0].type);
  navigateToMarker(a[0].Label, a[0].Lat, a[0].Long, a[0].type);
  //RECENTlist.reverse();
  RECENTlist.unshift(a[0]);
  localStorageDB.setItem('RECENT', JSON.stringify(RECENTlist));
}

function POInavTo(e) {
  var a = POIlist[e];
  var lnglat = new mapboxgl.LngLat(a.Long, a.Lat);
  $('#gpsMenu').hide();
  $('#gpsSearchMenu').hide();
  navigateToMarker(a.Name, a.Lat, a.Long, 'POI');

  //RECENTlist.reverse();
  RECENTlist.unshift(a[0]);
  localStorageDB.setItem('RECENT', JSON.stringify(RECENTlist));
}

function ShnavTo(s, S) {
  if (RoutePlanningON == false) $('#gpsMenu').hide();
  var N = WPTshared[s][S];

  var lnglat = new mapboxgl.LngLat(N.geometry.coordinates[0], N.geometry.coordinates[1]);
  navigateTo(lnglat, N.properties.Name, 'Shared');
}

function UserNavTo(index) {
  var N = WPTlist[index];
  var lnglat = new mapboxgl.LngLat(N.Long, N.Lat);
  $('#gpsMenu').hide();
  $('#gpsSearchMenu').hide();
  navigateTo(lnglat, N.Name, N.Type);
}
var Sim_Timer = null;
var GPS_DEBUG = false;
var SIM = {
  coords: {
    latitude: 48.37063,
    longitude: -89.331622,
    altitude: 200,
    accuracy: 1,
    altitudeAccuracy: 1,
    heading: 0,
    speed: 50,
  },
  timestamp: Date.now(),
};
function SIM_GPS() {
  Sim_Timer = setTimeout(function () {
    /*
		var Lat = getRandomArbitrary(40,55);
		var Long = getRandomArbitrary(-95,-85);
		var Alt = getRandomArbitrary(100,2000);
		var Heading = getRandomArbitrary(0,359);
		var Speed = getRandomArbitrary(0, 120);
		*/

    var Alt = 10 * mapb.getPitch();
    var Bearing = mapb.getBearing();
    var Zoom = mapb.getZoom();
    var Pitch = mapb.getPitch();
    if (Pitch == 0) Pitch = 0.1;
    else Pitch = Pitch * 0.1;

    var Heading = Bearing;
    if (Heading < 0) Heading = 360 + Bearing;
    if (Heading < 0) Heading = 0;
    var Speed = 50 * Pitch;
    if (SIM.coords.longitude == undefined) SIM.coords.longitude = -89.331622;
    if (SIM.coords.latitude == undefined) SIM.coords.latitude = 48.37063;
    var destination = turf.destination([SIM.coords.longitude, SIM.coords.latitude], Speed, Heading, 'meters');

    var Lat = destination.geometry.coordinates[1];
    var Long = destination.geometry.coordinates[0];

    SIM = {
      coords: {
        latitude: Lat,
        longitude: Long,
        altitude: Alt,
        accuracy: 1,
        altitudeAccuracy: 1,
        heading: Heading,
        speed: Speed,
      },
      timestamp: Date.now(),
    };
    gpsPosUpdate(SIM);
    SIM_GPS();
  }, 1000);
}
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
var IHadagoodGPSposition = false;
function toggleGPSactive() {
  if (navigator.geolocation && GPS.Active == false && !MAPSTATE.ChartSelect) {
    if (LOCALSTORAGE.elevationGraph == 1) $('#elevation-container').show();
    localStorageDB.setItem('GPS', 'true');
    GPS.Active = true;
    mapb.addSource('GPS', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0, 0],
        },
        properties: {
          Heading: 0,
        },
      },
    });
    mapb.addSource('GPS3D', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [create3dAcMarker([0, 0], 0, 1)],
        },
        properties: {
          Heading: 0,
          Alt: 0,
        },
      },
    });
    var p = mapb.getPitch();
    var visible = 'none';
    if (p > 10 && MAPSTATE.Render3D == true) visible = 'visible';
    mapb.addLayer({
      id: 'GPS',
      type: 'symbol',
      source: 'GPS',
      layout: {
        'icon-image': 'GPS',
        'icon-size': 0.4,
        'icon-rotate': {
          property: 'Heading',
          type: 'identity',
        },
        visibility: 'visible',
        'icon-padding': 0,
        'icon-offset': [0, 0],
        'icon-rotation-alignment': 'map',
        'icon-ignore-placement': true,
        'icon-allow-overlap': true,
        'symbol-placement': 'point',
      },
      paint: {
        'icon-opacity': 0.8,
      },
    });
    mapb.addLayer({
      id: 'GPS3D',
      type: 'fill-extrusion',
      source: 'GPS3D',
      layout: {
        visibility: visible,
      },
      paint: {
        'fill-extrusion-color': '#00FFFF',
        'fill-extrusion-height': ['+', ['*', ['get', 'Alt'], 1], 10],
        'fill-extrusion-base': ['-', ['*', ['get', 'Alt'], 1], 10],
        'fill-extrusion-opacity': 0.8,
      },
    });
    if (GPS.lnglat == undefined) {
      if (LOCALSTORAGE.GPS_LastPos != undefined) GPS.lnglat = LOCALSTORAGE.GPS_LastPos;
      else GPS.lnglat = new mapboxgl.LngLat(0, 0);
    }
    if (CurPageID() == 'Map_Page') {
      $('#NWInfo').show();
      $('#SWInfo').show();
      $('#NEInfo').show();
      $('#SEInfo').show();
      $('#NNInfo').show();
    }

    CenterNav = true;
    gpsONicon.style.fill = 'red';
    mapb.on('dragstart', MapMoved);

    var geo_options = {
      enableHighAccuracy: true,
      maximumAge: 30000,
      timeout: 3000,
    };
    if (GPS_DEBUG) {
      SIM_GPS();
    } else {
      MAPSTATE.watchID = navigator.geolocation.watchPosition(
        gpsPosUpdate,
        function (e) {
          if (IHadagoodGPSposition) return;
          //make a false position report for now in case we're demoing
          if (LOCALSTORAGE.LastMapView != undefined) {
            var MV = LOCALSTORAGE.LastMapView;
            var Heading = MV.bearing;
            if (Heading < 0) Heading = 360 + Heading;
            if (Heading < 0) Heading = 0;
            gpsPosUpdate({
              coords: {
                latitude: MV.center[1],
                longitude: MV.center[0],
                altitude: 200,
                accuracy: 1,
                altitudeAccuracy: 1,
                heading: Heading,
                speed: 0,
              },
              timestamp: Date.now(),
            });
            LOCALSTORAGE.LastMapView = undefined;
          }
        },
        geo_options
      );
    }
  } else {
    $('#elevation-container').hide();
    localStorageDB.setItem('GPS', 'false');
    localStorageDB.setItem('GPS_LastPos', JSON.stringify(GPS.lnglat));
    LOCALSTORAGE.GPS_LastPos = GPS.lnglat;
    gpsONicon.style.fill = 'black';
    GPS.Active = false;
    MAPSTATE.ObstacleWarning = false;
    $('#NWInfo').hide();
    $('#SWInfo').hide();
    $('#NEInfo').hide();
    $('#SEInfo').hide();
    $('#NNInfo').hide();
    coordinates_hide();
    $('#crosshair').hide();
    if (GPS_DEBUG) {
      clearTimeout(Sim_Timer);
    } else {
      navigator.geolocation.clearWatch(MAPSTATE.watchID);
    }
    if (Navigate == null) {
      if (cancelnav != undefined) mapb.removeControl(cancelnav);
      cancelnav = undefined;
    }
    mapb.removeLayer('GPS');
    mapb.removeLayer('GPS3D');
    mapb.removeSource('GPS');
    mapb.removeSource('GPS3D');

    if (CenterNav == false) {
      CenterNav = true;
      if (recenter != undefined) mapb.removeControl(recenter);
      recenter = undefined;
    }
    updateHeadingLine();
    mapb.off('dragstart', MapMoved);
  }
}
var GPS_TRACK_LINE = null;
var GPS_TRACK_POSITION = null;

function LOG_GPS_TRACK(e) {
  GPS_TRACK_POSITION = new GPS_ITEM(e.coords, e.timestamp);
  if (GPS_TRACK_LINE == null)
    cirroDB.query('GPS_Tracks', 'timestamp != ? ORDER BY timestamp DESC LIMIT 10000', [''], populateGPSJSON);
  else AddGPS_TRACK_LINE();
}

function populateGPSJSON(e) {
  if (LOCALSTORAGE.GPSTRACK_DISABLE == 1) return;
  var track = [];
  for (var i in e) {
    track.push(e[i].data.geometry.coordinates);
  }
  GPS_TRACK_LINE = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: track,
    },
    properties: {
      Label: 'GPS Tracklog',
    },
  };
  AddGPS_TRACK_LINE();
}
function AddGPS_TRACK_LINE() {
  if (LOCALSTORAGE.GPSTRACK_DISABLE == 1 || GPS_TRACK_LINE == null) return;

  if (GPS_TRACK_POSITION != null) {
    var feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [GPS_TRACK_POSITION.longitude, GPS_TRACK_POSITION.latitude],
      },
      properties: GPS_TRACK_POSITION,
    };
    var Distance = -1;
    if (GPS_TRACK_LINE.geometry.coordinates.length > 0) {
      var Last = GPS_TRACK_LINE.geometry.coordinates[GPS_TRACK_LINE.geometry.coordinates.length - 1];
      var point = {
        longitude: Last[0],
        latitude: Last[1],
      };
      Distance = GPS_CheckDistance(point, GPS_TRACK_POSITION);
    }

    GPS_TRACK_LINE.geometry.coordinates.push([GPS_TRACK_POSITION.longitude, GPS_TRACK_POSITION.latitude]); //so trackline isn't choppy

    if (
      (Distance > 25 && GPS_TRACK_POSITION.speed <= 30) ||
      (Distance > 100 && GPS_TRACK_POSITION.speed > 30 && GPS_TRACK_POSITION.speed <= 40) ||
      (Distance > 250 && GPS_TRACK_POSITION.speed > 40) ||
      Distance == -1
    ) {
      //if over 10 meters from last position or is first position then add to tracklog
      GPS_TRACK_LINE.geometry.coordinates.push([GPS_TRACK_POSITION.longitude, GPS_TRACK_POSITION.latitude]);
      var feature = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [GPS_TRACK_POSITION.longitude, GPS_TRACK_POSITION.latitude],
        },
        properties: GPS_TRACK_POSITION,
      };
      LogGPS_Track(feature);
    }
  }

  updateGPS_TRACK();
}
function MigrateGPS_Log() {
  if (LOCALSTORAGE.GPS_TRACKS != undefined) {
    var Tracks = LOCALSTORAGE.GPS_TRACKS;
    if (Tracks.features != undefined) {
      for (var i in Tracks.features) {
        LogGPS_Track(Tracks.features[i]);
      }
    }
    localStorageDB.clearItem('GPS_TRACKS', null);
    LOCALSTORAGE.GPS_TRACKS = undefined;
  }
}
function LogGPS_Track(feature) {
  var rec = {
    timestamp: feature.properties.timestamp,
    data: JSON.stringify(feature),
  };
  cirroDB.insert('GPS_Tracks', rec, null, function (e) {
    verbose.log('DEBUG', 'Log Updated');
  });
}

function updateGPS_TRACK_Colour(val) {
  MAPSTATE.TrackColour = val;
  localStorageDB.setItem('GPSTRACKCOLOUR', val);
  if (mapb.getLayer('GPSTRACK') != undefined) {
    //update track colour
    mapb.setPaintProperty('GPSTRACK', 'line-color', val);
  }
}
function updateGPS_TRACK() {
  if (LOCALSTORAGE.GPSTRACK_DISABLE == 1 || LOCALSTORAGE.GPSTRACK == 0) return;
  if (mapb.getSource('GPSTRACK') == undefined) {
    mapb.addSource('GPSTRACK', {
      type: 'geojson',
      data: GPS_TRACK_LINE,
    });
    mapb.addLayer(
      {
        id: 'GPSTRACK',
        type: 'line',
        source: 'GPSTRACK',
        layout: {
          'line-join': 'bevel',
          'line-cap': 'butt',
        },
        paint: {
          'line-color': MAPSTATE.TrackColour,
          'line-width': 1.5,
          'line-opacity': 0.7,
        },
      },
      'AIRCRAFT'
    );
    /*
		mapb.addLayer({
			'id': "GPSTRACK-LABELS",
			'type': 'symbol',
			'source': "GPSTRACK",
			"layout": {
				"visibility": "visible",
				"symbol-placement": "line",
				"text-field": "{Label}",
				"text-size": TEXT_SIZE,
				"text-justify": "center",
				"text-anchor": "top",
				"text-offset": [0, 1]
			},
			"paint": {
				"text-halo-color": TEXT_HALO_COLOR,
				"text-halo-width": TEXT_HALO_WIDTH,
				"text-halo-blur": TEXT_HALO_BLUR,
				"text-color": TEXT_COLOR
			}
		});
		*/
  } else {
    if (CenterNav) mapb.getSource('GPSTRACK').setData(GPS_TRACK_LINE);
  }
  if (GPS_TRACK_LINE != null && GPS_TRACK_POSITION != null) GPS_TRACK_LINE.geometry.coordinates.pop();
}
function TrackON(e) {
  if (e.checked) {
    localStorageDB.setItem('GPSTRACK', 1);
    LOCALSTORAGE.GPSTRACK = 1;
    if (GPS_TRACK_LINE == null) {
      cirroDB.query('GPS_Tracks', 'timestamp != ? ORDER BY timestamp DESC LIMIT 10000', [''], populateGPSJSON);
    } else {
      updateGPS_TRACK();
    }
  } else {
    localStorageDB.setItem('GPSTRACK', 0);
    LOCALSTORAGE.GPSTRACK = 0;
    if (mapb.getSource('GPSTRACK') != undefined) {
      mapb.removeLayer('GPSTRACK');
      mapb.removeSource('GPSTRACK');
    }
  }
}

function DisableTrackON(e) {
  if (e.checked) {
    localStorageDB.setItem('GPSTRACK_DISABLE', 1);
    if (mapb.getSource('GPSTRACK') != undefined) {
      mapb.removeLayer('GPSTRACK');
      mapb.removeSource('GPSTRACK');
    }
    LOCALSTORAGE.GPSTRACK_DISABLE = 1;
    $('#TrackON').prop('disabled', true).checkboxradio('refresh');
    clearedGPSTRACK(null);
    GPS_TRACK_LINE == null;
  } else {
    localStorageDB.setItem('GPSTRACK_DISABLE', 0);
    LOCALSTORAGE.GPSTRACK_DISABLE = 0;
    $('#TrackON').prop('disabled', false).checkboxradio('refresh');
  }
  var checked = {
    checked: $('#TrackON').is(':checked'),
  };
  TrackON(checked);
}

function clearGPSTRACK() {
  $('#acPanel').hide();
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Warning',
    headerClose: false,
    buttonPrompt: 'Are you sure you want to delete your entire GPS Tracklog?',
    buttons: {
      Delete: {
        click: function () {
          //localStorageDB.clearItem("GPS_TRACKS", clearedGPSTRACK);
          cirroDB.clearAll('GPS_Tracks', clearedGPSTRACK);
        },
      },
      Cancel: {
        click: function () {
          //File bug report
        },
      },
    },
  });
}
function clearedGPSTRACK(e) {
  GPS_TRACK_LINE = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
    properties: {
      Label: 'GPS Tracklog',
    },
  };
  if (mapb.getSource('GPSTRACK') != undefined) {
    mapb.getSource('GPSTRACK').setData(GPS_TRACK_LINE);
    updateGPS_TRACK();
  }
}
function exportGPSTRACK() {
  cirroDB.query('GPS_Tracks', 'timestamp != ? ORDER BY timestamp DESC LIMIT 10000', [''], exportGPSTRACK2);
}
function exportGPSTRACK2(e) {
  var GPS_TRACK = {
    type: 'FeatureCollection',
    features: [],
  };
  if (e.length == 0) {
    alert('No GPS Tracks');
    return;
  }
  var track = [];
  for (var i in e) {
    track.push(e[i].data.geometry.coordinates);
    GPS_TRACK.features.push(e[i].data);
  }
  var now = new Date().toISOString();
  var feature = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: track,
    },
    properties: {
      Name: 'GPS Tracklog: ' + now,
    },
  };
  GPS_TRACK.features.push(feature);
  fileGEOJSON(GPS_TRACK);
  $('#acPanel').hide();
  setTimeout(function () {
    saveWPTS();
    setTimeout(function () {
      $('#wptName').val('GPS-Tracklog-' + now);
    }, 1000);
  }, 2000);
}

function gpsPosUpdate(e) {
  var gps = e.coords;
  try {
    var test = new mapboxgl.LngLat(gps.longitude, gps.latitude);
    LOG_GPS_TRACK(e);
    GPS.lnglat = test;
    IHadagoodGPSposition = true;
  } catch (e) {
    //Bad gps lat lng
  }
  if (MAPSTATE.Busy && MAPSTATE.MaximizePerformance) return;

  if (mapb.getSource('GPS') == undefined) return;

  GPS.accuracy = gps.accuracy;
  GPS.altitude = gps.altitude;
  GPS.altitudeAccuracy = gps.altitudeAccuracy;
  if (isNaN(GPS.speed)) GPS.speed = 0;
  else if (GPS.speed < 0) GPS.speed = 0;
  if (isNaN(gps.speed)) gps.speed = 0;
  else if (gps.speed > 1) GPS.heading = gps.heading;
  else if (gps.speed < 0) gps.speed = 0;
  GPS.speed = gps.speed;
  GPS.longitude = gps.longitude;
  GPS.latitude = gps.latitude;

  GPS.heading = Math.round(GPS.heading * 100) / 100;
  if (isNaN(GPS.heading)) GPS.heading = 0;
  else if (GPS.heading < 0) GPS.heading = 0;
  if (GPS.altitude == null) GPS.altitude = 0;

  var wdt = new Date();
  var wmmDate = wdt.getFullYear() + (wdt.getMonth() + 1) / 12.0;
  var Mdev = MAPSTATE.wmm.declination(0, GPS.latitude, GPS.longitude, wmmDate);

  MAPSTATE.MagDev = Mdev;
  var mheading = GPS.heading - Mdev;
  if (mheading >= 360) mheading -= 360;
  if (mheading < 0) mheading += 360;
  mheading = Math.round(mheading);
  GPS.mheading = mheading;
  var bounds = mapb.getBounds();
  var old = bounds.toString();
  bounds.extend(GPS.lnglat);
  var skipit = false;
  if (old != bounds.toString()) skipit = true;
  if ((!CenterNav && !MAPSTATE.MaximizePerformance && !skipit) || (CenterNav && !skipit)) {
    if (TrackUP && CenterNav) {
      var gpsHeading = GPS.heading;
      if (MAPSTATE.HeadingMag == 1) gpsHeading = GPS.mheading;
      var res = -gpsHeading;
      if (res > 180) res = 360 - res;
      if (res < -180) res = 360 + res;
      Mrotate = res;
      //Mrotate = "rotate(-45deg)"; // For Testing;
      $('.rotatemap').css('transform', 'rotate(' + Mrotate + 'deg)');
    }
    //GPS.lnglat = new mapboxgl.LngLat(gps.longitude, gps.latitude);

    var data = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [gps.longitude, gps.latitude, gps.altitude],
      },
      properties: {
        Heading: GPS.heading,
      },
    };

    mapb.getSource('GPS').setData(data);

    if (mapb.getPitch() > 10 && MAPSTATE.Render3D == true) {
      var Poly = create3dAcMarker([gps.longitude, gps.latitude], gps.heading, 1);
      var GPS3D = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [Poly],
        },
        properties: {
          Alt: GPS.altitude,
          Heading: GPS.heading,
        },
      };
      mapb.getSource('GPS3D').setData(GPS3D);
    }
  }
  if (HeadingLine) updateHeadingLine();

  //var heading = GPS.heading;
  //mapb.setLayoutProperty("GPS", 'icon-rotate', heading);

  //center, zoom, pitch, bearing
  //TrackUP = true;
  if (CenterNav) {
    if (TrackUP) {
      var center = GPS.lnglat;
      var Lat = center.lat;
      var Long = center.lng;
      var point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [Long, Lat],
        },
      };

      //var distance = smallestMapEdge(); //smallest distance from edge of map
      //var Pitch = mapb.getPitch();
      //if (Pitch > 10) distance = distance / 2;
      //GPS.heading = 45;
      var heading = GPS.heading;
      var units = 'meters';
      //var destination = turf.destination(point, distance, heading, units);
      mapb.easeTo({
        center: GPS.lnglat,
        bearing: heading,
        duration: 250,
      });

      setTimeout(function () {
        var heading = GPS.heading;
        if (MAPSTATE.HeadingMag == 1) heading -= MAPSTATE.MagDev;
        $('#CompassIMG').css('transform', 'rotate(' + -heading + 'deg)');
        rotateTimeout = null;
      }, 100);
      saveCurMapView(GPS.lnglat);
    } else {
      mapb.panTo(GPS.lnglat, { duration: 250 });
      saveCurMapView(GPS.lnglat);
    }
    //}
    if (MAPSTATE.OfflineDataOn && MAPSTATE.OfflineStyleTimer == null) {
      MAPSTATE.OfflineStyleTimer = setTimeout(function () {
        if (MAPSTATE.OfflineDataOn) setOfflineStyle(); //so other offline layers get added to map with user interaction dragend events
        MAPSTATE.OfflineStyleTimer = null;
      }, 60000);
    }
  }
  updateGPSinfo();
  if (MAPSTATE.goto != null) {
    //restore last navigation from possible crash
    navigateTo(MAPSTATE.goto.e, MAPSTATE.goto.Label, MAPSTATE.goto.type);
    MAPSTATE.goto = null;
  }
  if ($('#coordinates').is(':visible') && !MAPSTATE.ObstacleWarning) {
    UpdateCrosshairInfo();
  }
  //setTimeout(function(){
  //   throttleGPS = false;
  // }, 250);
  //}
}
function saveCurMapView(center) {
  if (mapb == null) return;
  var Bearing = mapb.getBearing();
  var Zoom = mapb.getZoom();
  var Pitch = mapb.getPitch();
  var MapView = {
    center: center,
    bearing: Bearing,
    zoom: Zoom,
    pitch: Pitch,
    CurDirectTo: MAPSTATE.CurDirectTo,
  };
  localStorageDB.setItem('LastMapView', JSON.stringify(MapView));
}
function smallestMapEdge() {
  return 0; //Disable smallest edge entirely.  Something else is happening here making this return incorrect data while the map is panning or zooming.
  var bounds = mapb.getBounds();
  var center = mapb.getCenter();
  var smallest = getDistance(bounds.getSouthWest(), center);
  var distance = getDistance(bounds.getNorthEast(), center);
  if (distance < smallest) smallest = distance;
  distance = getDistance(bounds.getNorthWest(), center);
  if (distance < smallest) smallest = distance;
  distance = getDistance(bounds.getSouthEast(), center);
  if (distance < smallest) smallest = distance;
  if (isNaN(smallest)) smallest = 0;
  return smallest;
}

function getDistance(point, center) {
  var point1 = turf.point([center.lng, center.lat]);
  var point2 = turf.point([point.lng, point.lat]);
  var bearing = turf.bearing(point1, point2).toFixed(0);
  var distance = turf.distance(point1, point2, { units: 'meters' });

  if (mapb.getZoom() > 18) return distance / 2.8;
  else return distance / 2.6;
}

function updateGPSinfo() {
  var speed = Math.round(GPS.speed * 1.94384 * 10) / 10;
  if (speed >= 100) speed = Math.round(speed);
  var heading = Math.round(GPS.heading);
  var altitude = Math.round(GPS.altitude * 3.28084);
  $('#NWInfo').html(
    '<infoLabel>Speed</infoLabel><br />' +
      getDisplaySpeed(GPS.speed, false) +
      ' <infoLabel>' +
      DisplayUnits.SpeedU +
      '</infoLabel>'
  );
  var H = 'T';
  if (MAPSTATE.HeadingMag == 1) {
    H = 'M';
    heading = GPS.mheading;
  }
  $('#NNInfo').html(
    '<infoLabel>' + heading + '°' + H + '<br />' + Math.round(GPS.altitude * 3.28084) + 'ft</infoLabel>'
  );
  if (Navigate != null) navigateTo(Navigate.LngLat);
}
var rotateTimeout = null;

function rotateCompass(e) {
  var bearing = e.target.transform.bearing;
  if (MAPSTATE.HeadingMag == 1) bearing -= MAPSTATE.MagDev;
  if (rotateTimeout != null) clearTimeout(rotateTimeout);
  rotateTimeout = setTimeout(function () {
    $('#CompassIMG').css('transform', 'rotate(' + -bearing + 'deg)');
    rotateTimeout = null;
  }, 100);
}
var WPT_MARKER = null;
var WPT_MARKER_NAME = null;
var WPT_LAYERS = null;
var WPT_OLD_POS = null;
function setMapZoom(z) {
  mapb.setZoom(z);
}
function createWaypoint(point) {
  var DeviceWPTS = [];
  var Index = 0;
  if (LOCALSTORAGE.CoordFormat == undefined) LOCALSTORAGE.CoordFormat = 'DD';
  localStorageDB.getItem('DeviceWPTS', function (e) {
    if (e != null && e != 'false') {
      DeviceWPTS = JSON.parse(e);
      Index = DeviceWPTS.length;
    }
    if (e == 'false') {
      DeviceWPTS = [];
      Index = 0;
    }
    var name = Index;
    if (point.properties.Name == undefined) point.properties.Name = name;
    WAYPOINT_MASTER.properties.Name = point.properties.Name;
    var html = '';
    //html += '<label for="WptZoomTool">Map Zoom</label>';
    //html += '<input id="WptZoomTool" type="number" data-type="range" value="' + round10(mapb.getZoom()) + '" min="0" max="22" step="0.1" onChange="setMapZoom(this.value);" />';
    if (GPS.Active) {
      //create waypoint on gps position
      var center = GPS.lnglat;
      var Lat = center.lat;
      var Long = center.lng;
      var point1 = turf.point([Long, Lat]);
      var point2 = turf.point([point.geometry.coordinates[0], point.geometry.coordinates[1]]);
      var bearing = turf.bearing(point1, point2).toFixed(0);
      var Distance = turf.distance(point1, point2, { units: 'meters' }).toFixed(1);

      html +=
        "<table><tr><td>Name</td><td><input type='text' id='WPT_name' value='" +
        point.properties.Name +
        "' /></td></tr>";

      //html += "<tr><td>Lat</td><td><input type='text' id='WPT_Lat' value='" + point.geometry.coordinates[1] + "' /></td></tr>";
      //html += "<tr><td>Long</td><td><input type='text' id='WPT_Long' value='" + point.geometry.coordinates[0] + "' /></td></tr>";
      html += '</table>';
      html += createCoordInput(
        'WPT_Lat',
        'WPT_Long',
        LOCALSTORAGE.CoordFormat,
        point.geometry.coordinates[1],
        point.geometry.coordinates[0]
      );
      html += '<table>';
      html += '<tr><td>Dist</td><td>' + getDisplayDistance(Distance) + '</td></tr>';
      html +=
        "<tr><td><button id='WPT_GPS' onClick='createWaypointGPS()' data-mini='true' >Use GPS</button></td><td><button id='WPT_GOTO' onClick='gotoWaypoint(" +
        Index +
        ',1,"' +
        point.id +
        "\")' data-mini='true' >Show On Map</button></td></tr></table>";
    } else {
      //create waypoint on current position
      html +=
        "<table><tr><td>Name</td><td><input type='text' id='WPT_name' value='" +
        point.properties.Name +
        "' /></td></tr>";

      //html += "<tr><td>Lat</td><td><input type='text' id='WPT_Lat' value='" + point.geometry.coordinates[1] + "' /></td></tr>";
      //html += "<tr><td>Long</td><td><input type='text' id='WPT_Long' value='" + point.geometry.coordinates[0] + "' /></td></tr>";
      html += '</table>';
      html += createCoordInput(
        'WPT_Lat',
        'WPT_Long',
        LOCALSTORAGE.CoordFormat,
        point.geometry.coordinates[1],
        point.geometry.coordinates[0]
      );
      html += '<table>';

      html +=
        "<tr><td><button id='WPT_GPS' onClick='createWaypointGPS()' data-mini='true'  disabled>Use GPS</button></td><td><button id='WPT_GOTO' onClick='gotoWaypoint(" +
        Index +
        ',1,"' +
        point.id +
        "\")' data-mini='true' >Show On Map</button></td></tr></table>";
    }
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'Waypoint',
      headerClose: false,
      buttonPrompt: html,
      buttons: {
        Save: {
          click: function () {
            //todo save waypoint to database

            ChangeCoordFormat('WPT_Lat', 'WPT_Long', LOCALSTORAGE.CoordFormat, true); //to ensure new coordinates are calculated
            if (deviceON) {
              $('#deviceWPTsave').show();
              $('#deviceWPTdelete').show();
            }
            name = $('#WPT_name').val();
            DeviceWPTS.push({
              Index: Index,
              Name: name,
              Lat: $('#WPT_Lat').val(),
              Long: $('#WPT_Long').val(),
            });
            localStorageDB.setItem('DeviceWPTS', JSON.stringify(DeviceWPTS));
            drawWaypoint.delete(point.id);
            WP_Filter({
              id: 'deviceON',
            });
          },
        },
        Cancel: {
          click: function () {
            drawWaypoint.delete(point.id);
          },
        },
      },
    });
  });
}

function createWaypointGPS() {
  $('#WPT_Lat').val(GPS.lnglat.lat);
  $('#WPT_Long').val(GPS.lnglat.lng);
  ChangeCoordFormat('WPT_Lat', 'WPT_Long', 'GPS', false); //to ensure new coordinates are calculated
}

function gotoWaypoint(Index, NewPoint, id) {
  ChangeCoordFormat('WPT_Lat', 'WPT_Long', LOCALSTORAGE.CoordFormat, true); //to ensure new coordinates are calculated
  if (deviceON) {
    $('#deviceWPTsave').show();
    $('#deviceWPTdelete').show();
  }
  localStorageDB.getItem('DeviceWPTS', function (e) {
    var DeviceWPTS = e;
    if (e != null && e != 'false') {
      DeviceWPTS = JSON.parse(e);
    } else {
      Index = 0;
      DeviceWPTS = [];
    }
    if (NewPoint == 1) {
      name = $('#WPT_name').val();
      DeviceWPTS.push({
        Index: Index,
        Name: name,
        Lat: $('#WPT_Lat').val(),
        Long: $('#WPT_Long').val(),
      });
      localStorageDB.setItem('DeviceWPTS', JSON.stringify(DeviceWPTS));
      drawWaypoint.delete(id);
      WP_Filter({
        id: 'deviceON',
      });
    } else {
      var editWPT = {
        Index: Index,
        Name: $('#WPT_name').val(),
        Lat: parseFloat($('#WPT_Lat').val()),
        Long: parseFloat($('#WPT_Long').val()),
      };
      DeviceWPTS[Index] = editWPT;
      localStorageDB.setItem('DeviceWPTS', JSON.stringify(DeviceWPTS));
      WP_Filter({
        id: 'deviceON',
      });
    }

    var center = new mapboxgl.LngLat(parseFloat($('#WPT_Long').val()), parseFloat($('#WPT_Lat').val()));
    if (GPS.Active) {
      if (CenterNav == true) {
        recenter = new recenterControl();
        mapb.addControl(recenter, 'top-left');
      }
      CenterNav = false;
    }

    $(document).trigger('simpledialog', {
      method: 'close',
    });
    flyToMapPos(center);
  });
}

function openWaypoint(e) {
  if (e.features.length > 0) WAYPOINT_MASTER.geometry.coordinates = e.features[0].geometry.coordinates;
  mapb.off('draw.selectionchange', openWaypoint);
  mapb.off('draw.update', openWaypoint);
  createWaypoint(WAYPOINT_MASTER);
}
var WPeditIndex;

function editWaypoint(e) {
  if (MAPSTATE.Measuring || MAPSTATE.ChartSelect) return;
  if (LOCALSTORAGE.CoordFormat == undefined) LOCALSTORAGE.CoordFormat = 'DD';
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var features = mapb.queryRenderedFeatures(bbox, {
    layers: ['WPTS'],
  });

  if (!features.length) {
    return;
  }

  var f = features[0].properties;

  var html = '';
  var route = '';
  if (MAPSTATE.Measuring) {
    route =
      '<img src="./images/routeplan.png" width="35" height="35" onClick="routeToMarker(\'' +
      f.Name +
      "'," +
      f.Lat +
      ',' +
      f.Long +
      ')"/>';
    CurMarkerProperties = { NavType: 'Waypoint' };
  }
  if (GPS.Active) {
    //edit waypoint on gps position
    var center = GPS.lnglat;
    var Lat = center.lat;
    var Long = center.lng;
    var point1 = turf.point([Long, Lat]);
    var point2 = turf.point([f.Long, f.Lat]);
    var bearing = turf.bearing(point1, point2).toFixed(0);
    var Distance = turf.distance(point1, point2, { units: 'meters' }).toFixed(1);
    var DistanceNM = Distance * 0.000539957; // meters to nautical miltes
    DistanceNM = Math.round(DistanceNM * 10) / 10;
    html += "<table><tr><td>Name</td><td><input type='text' id='WPT_name' value='" + f.Name + "' /></td></tr>";

    //html += "<tr><td>Lat</td><td><input type='text' id='WPT_Lat' value='" + f.Lat + "' /></td></tr>";
    //html += "<tr><td>Long</td><td><input type='text' id='WPT_Long' value='" + f.Long + "' /></td></tr>";
    html += '</table>';
    html += createCoordInput('WPT_Lat', 'WPT_Long', LOCALSTORAGE.CoordFormat, f.Lat, f.Long);
    html += '<table>';

    html += '<tr><td>Dist</td><td>' + DistanceNM + ' NM</td></tr>';
    html +=
      "<tr><td><button id='WPT_GPS' onClick='createWaypointGPS()' data-mini='true' >Use GPS</button></td><td><button id='WPT_GOTO' onClick='gotoWaypoint(" +
      f.Index +
      ",0,null)' data-mini='true' >Show On Map</button></td></tr>";

    html += '<tr><td>' + route + '</td>';
    html +=
      '<td><center><img src="./images/DirectTo.png" width="35" height="35" onClick="navigateToMarker(\'' +
      f.Name +
      "'," +
      f.Lat +
      ',' +
      f.Long +
      ", 'Device')\"/></center></td></tr></table>";
  } else {
    //edit waypoint on current position
    html += "<table><tr><td>Name</td><td><input type='text' id='WPT_name' value='" + f.Name + "' /></td></tr>";

    //html += "<tr><td>Lat</td><td><input type='text' id='WPT_Lat' value='" + f.Lat + "' /></td></tr>";
    //html += "<tr><td>Long</td><td><input type='text' id='WPT_Long' value='" + f.Long + "' /></td></tr>";

    html += '</table>';
    html += createCoordInput('WPT_Lat', 'WPT_Long', LOCALSTORAGE.CoordFormat, f.Lat, f.Long);
    html += '<table>';

    html +=
      "<tr><td><button id='WPT_GPS' onClick='createWaypointGPS()' data-mini='true' disabled>Use GPS</button></td><td><button id='WPT_GOTO' onClick='gotoWaypoint(" +
      f.Index +
      ",0,null)' data-mini='true' >Show On Map</button></td></tr>";
    if (MAPSTATE.Measuring) {
      html += '<tr><td>' + route + '</td><td></td></tr>';
    }
    html += '</table>';
  }
  $('<div>').simpledialog2({
    mode: 'button',
    animate: false,
    headerText: 'Waypoint',
    headerClose: false,
    buttonPrompt: html,
    buttons: {
      Save: {
        click: function () {
          ChangeCoordFormat('WPT_Lat', 'WPT_Long', LOCALSTORAGE.CoordFormat, true); //to ensure new coordinates are calculated
          var editWPT = {
            Index: f.Index,
            Name: $('#WPT_name').val(),
            Lat: parseFloat($('#WPT_Lat').val()),
            Long: parseFloat($('#WPT_Long').val()),
          };
          localStorageDB.getItem('DeviceWPTS', function (e) {
            var DeviceWPTS = [];
            try {
              DeviceWPTS = JSON.parse(e);
              DeviceWPTS[editWPT.Index] = editWPT;
            } catch (e) {
              alert('ERROR WAYPOINTS HAVE BEEN LOST!');
              DeviceWPTS = [];
              DeviceWPTS.push(editWPT);
            }
            localStorageDB.setItem('DeviceWPTS', JSON.stringify(DeviceWPTS));
            $(document).trigger('simpledialog', {
              method: 'close',
            });
            WP_Filter({
              id: 'deviceON',
            });
          });
        },
      },
      Delete: {
        click: function () {
          localStorageDB.getItem('DeviceWPTS', function (e) {
            var DeviceWPTS = JSON.parse(e);
            DeviceWPTS.splice(f.Index, 1);
            for (var i in DeviceWPTS) {
              DeviceWPTS[i].Index = i;
            }
            localStorageDB.setItem('DeviceWPTS', JSON.stringify(DeviceWPTS));
            $(document).trigger('simpledialog', {
              method: 'close',
            });

            WP_Filter({
              id: 'deviceON',
            }); //Reload user waypoints
          });
        },
      },
      Cancel: {
        click: function () {
          //Cancel edit
          $(document).trigger('simpledialog', {
            method: 'close',
          });
        },
      },
    },
  });
}

function toggleCompass(e) {
  if (e.checked) {
    $('#compassToggle').prop('checked', true).checkboxradio('refresh');
    ShowCompass = true;
    localStorageDB.setItem('ShowCompass', 1);
    $('#Compass').show();
    mapb.on('rotate', rotateCompass);
  } else {
    ShowCompass = false;
    localStorageDB.setItem('ShowCompass', 0);
    $('#Compass').hide();
    mapb.off('rotate', rotateCompass);
  }
}

function toggleTrackUP(e) {
  if (e.checked) {
    TrackUP = true;
    localStorageDB.setItem('TrackUP', 1);
  } else {
    TrackUP = false;
    localStorageDB.setItem('TrackUP', 0);
  }
}

function toggleRender3D(e) {
  if (e.checked) {
    MAPSTATE.Render3D = true;
    localStorageDB.setItem('Render3D', 1);
  } else {
    MAPSTATE.Render3D = false;
    localStorageDB.setItem('Render3D', 0);
  }
  var p = mapb.getPitch();
  if (p > 10 || MAPSTATE.Render3D == false) {
    if (track != 0 || trackAll != 0) mapb.setLayoutProperty('AIRCRAFT3D-HISTORY-POINT', 'visibility', 'none');
    mapb.setLayoutProperty('Obstacles', 'visibility', 'visible');
    mapb.setLayoutProperty('Obstacles3d', 'visibility', 'none');
  } else {
    if (track != 0 || trackAll != 0) mapb.setLayoutProperty('AIRCRAFT3D-HISTORY-POINT', 'visibility', 'visible');
    mapb.setLayoutProperty('Obstacles', 'visibility', 'none');
    mapb.setLayoutProperty('Obstacles3d', 'visibility', 'visible');
  }
}

function toggleHeading(e) {
  if (e.checked) {
    HeadingLine = true;
    localStorageDB.setItem('HeadingLine', 1);
  } else {
    HeadingLine = false;
    localStorageDB.setItem('HeadingLine', 0);
  }
  updateHeadingLine();
}

function toggleCrosshairInfo(e) {
  if (e.checked) {
    CrosshairInfo = true;
    localStorageDB.setItem('CrosshairInfo', 1);
  } else {
    CrosshairInfo = false;
    localStorageDB.setItem('CrosshairInfo', 0);
  }
}

function toggleMagHeading(e) {
  if (e.checked) {
    localStorageDB.setItem('HeadingLineMag', 1);
    MAPSTATE.HeadingMag = 1;
  } else {
    localStorageDB.setItem('HeadingLineMag', 0);
    MAPSTATE.HeadingMag = 0;
  }
}

function MapMoved(e) {
  if (CenterNav == true) {
    recenter = new recenterControl();
    mapb.addControl(recenter, 'top-left');
    $('#crosshair').fadeIn(500);
  }
  CenterNav = false;
}
var mouseDownOrigin;

function MouseDown(e) {
  var originalEvent = e.originalEvent;
  mouseDownOrigin = L.point(originalEvent.clientX, originalEvent.clientY);
}

function MouseUp(e) {
  if (mouseDownOrigin) {
    // We detect clicks within a certain tolerance, otherwise let it
    // be interpreted as a drag by the map
    var distance = L.point(e.originalEvent.clientX, e.originalEvent.clientY).distanceTo(mouseDownOrigin);
    if (Math.abs(distance) < 9 * (window.devicePixelRatio || 1)) {
      navigateTo(e.latlng);
    }
  }
  mouseDownOrigin = null;
}

function MouseMove(e) {
  var newPos = e.layerPoint,
    latlng = e.latlng;
  mouseMarker.setLatLng(latlng);
  //Update line if implemented
}

function navigateToMarker(Label, lat, lng, type = 'Other') {
  try {
    $('#airportInfo').popup('close');
  } catch (e) {
    verbose.log('DEBUG', e);
  }
  try {
    $('#fireInfo').popup('close');
  } catch (e) {
    verbose.log('DEBUG', e);
  }
  try {
    $('#acInfo').popup('close');
  } catch (e) {
    verbose.log('DEBUG', e);
  }
  try {
    $(document).trigger('simpledialog', {
      method: 'close',
    });
  } catch (e) {
    verbose.log('DEBUG', e);
  }
  $('#gpsMenu').hide();
  var lnglat = new mapboxgl.LngLat(lng, lat);

  navigateTo(lnglat, Label, type);
  ReCenterNav();
  WPT_MARKER = null;
  MAPSTATE.directTo_type = type;
  MAPSTATE.directTo_Label = Label;
}

function navigateTo(e, Label, type) {
  if (MAPSTATE.Measuring != false) {
    CurMarkerProperties = { NavType: 'Waypoint' };
    routeToMarker(Label, e.lat, e.lng);
    mapb.on('click', closeDirectoTo);
    return;
  }
  //if (type != "RECENT") {
  localStorageDB.getItem('RECENT', function (E) {
    var recent = E;
    if (recent != null) {
      recent = JSON.parse(recent);
      if (recent.length > 200) {
        recent.shift();
      }
    } else {
      recent = [];
    }
    var NewRecent = {
      Label: Label,
      Lat: e.lat,
      Long: e.lng,
      type: type,
    };

    for (var i in recent) {
      if (recent[i].Label == NewRecent.Label) {
        recent.splice(i, 1);
        break;
      }
    }
    recent.push(NewRecent);
    //results.reverse();
    var replacer = function (k, v) {
      if (v === undefined) {
        return null;
      }
      return v;
    };
    var ToSave = JSON.stringify(recent, replacer);
    if (ToSave == '' || ToSave == '[]') {
      alert(
        "Something bad happened while updating the Recent List. To ensure other items don't get cleared we didn't save this last navigation point to it.  Please let us know what it was so we can fix this issue.  Kind regards AirSuite Dev..."
      );
    } else {
      localStorageDB.setItem('RECENT', ToSave);
    }
  });
  //}
  var SaveNav = {
    e: e,
    Label: Label,
    type: type,
  };
  localStorageDB.setItem('LastNav', JSON.stringify(SaveNav));

  var center = mapb.getBounds().getCenter();
  if (GPS.Active) center = GPS.lnglat;
  var Lat = center.lat;
  var Long = center.lng;
  var point1 = turf.point([Long, Lat]);
  var point2 = turf.point([e.lng, e.lat]);
  var bearing = turf.bearing(point1, point2).toFixed(0);
  var Distance = turf.distance(point1, point2, { units: 'meters' }).toFixed(1);
  if (bearing < 0) bearing = 360 + parseInt(bearing);
  2;
  var DistanceTXT = getDisplayDistance(Distance, true);

  var ETA = getETA(Distance, GPS.speed);
  //if (GPS.speed == null) ETA = "__:__";
  $('#SEInfo').html('<infoLabel>Dist Dest</infoLabel><br />' + DistanceTXT);
  $('#SWInfo').html('<infoLabel>Bearing</infoLabel><br />' + bearing + '<infoLabel>°T</infoLabel>');
  $('#NEInfo').html('<infoLabel>ETE</infoLabel><br />' + ETA);
  var pointA = GPS.lnglat;
  var pointB = e;
  var pointList = [pointA, pointB];
  var data = {
    type: 'LineString',
    coordinates: [
      [Long, Lat, 0],
      [e.lng, e.lat],
    ],
  };
  if (mapb.getSource('GPS-NAV') == undefined) {
    mapb.addSource('GPS-NAV', {
      type: 'geojson',
      data: data,
    });
    if (mapb.getLayer('GPS') != undefined) {
      mapb.addLayer(
        {
          id: 'GPS-NAV',
          type: 'line',
          source: 'GPS-NAV',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#DD33FF',
            'line-width': 6,
            'line-opacity': 0.7,
          },
        },
        'GPS'
      );
    } else {
      mapb.addLayer({
        id: 'GPS-NAV',
        type: 'line',
        source: 'GPS-NAV',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': '#DD33FF',
          'line-width': 6,
          'line-opacity': 0.7,
        },
      });
    }
  } else {
    mapb.getSource('GPS-NAV').setData(data);
  }
  if (Navigate == null) {
    cancelnav = new cancelnavControl();
    mapb.addControl(cancelnav, 'bottom-left');
  }
  Navigate = {
    LngLat: e,
  };
}

function update_HEADING_Colour(val) {
  MAPSTATE.HeadingColour = val;
  localStorageDB.setItem('GPSHEADINGCOLOUR', val);
  if (mapb.getLayer('GPS-HEADING') != undefined) {
    //update track colour
    mapb.setPaintProperty('GPS-HEADING', 'line-color', val);
    mapb.setPaintProperty('GPS3D-HEADING', 'line-color', val);
  }
}

function updateHeadingLine() {
  if (GPS.Active && HeadingLine) {
    var center = GPS.lnglat;
    var Lat = center.lat;
    var Long = center.lng;
    var point = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [Long, Lat],
      },
    };
    var distance = MAPSTATE.HeadingDistance * 1852; //distance for heading line to extend in meters;
    var heading = GPS.heading;
    if (heading == null) heading = 0;
    var units = { units: 'meters' };
    var destination = turf.destination(point, distance, heading, units);
    var destination2 = turf.destination(point, 60, heading, units);

    var data = {
      type: 'LineString',
      coordinates: [[Long, Lat, 0], destination.geometry.coordinates],
    };
    var lineData = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [[Long, Lat, 0], destination.geometry.coordinates],
      },
      properties: {},
    };
    var data2 = {
      type: 'LineString',
      coordinates: [destination2.geometry.coordinates, destination.geometry.coordinates],
    };
    var data3d = turf.buffer(data2, 33, { units: 'meters' });
    data3d.properties = {
      Alt: GPS.altitude,
    };
    var ObstacleSearch = turf.buffer(data2, 165, { units: 'meters' });
    ObstacleSearch.properties = {
      Alt: GPS.altitude,
    };
    var p = mapb.getPitch();
    var visible = 'none';
    var visible2 = 'visible';
    if (p > 10 && MAPSTATE.Render3D == true) {
      visible = 'visible';
      visible2 = 'none';
    }
    if (mapb.getSource('GPS-HEADING') == undefined) {
      mapb.addSource('GPS-HEADING', {
        type: 'geojson',
        data: data,
      });
      mapb.addLayer(
        {
          id: 'GPS-HEADING',
          type: 'line',
          source: 'GPS-HEADING',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
            visibility: 'visible',
          },
          paint: {
            'line-color': MAPSTATE.HeadingColour,
            'line-width': 4,
            'line-opacity': 0.7,
          },
        },
        'GPS'
      );
      mapb.addSource('GPS3D-HEADING', {
        type: 'geojson',
        data: data3d,
      });
      mapb.addLayer({
        id: 'GPS3D-HEADING',
        type: 'fill-extrusion',
        source: 'GPS3D-HEADING',
        layout: {
          visibility: visible,
        },
        paint: {
          'fill-extrusion-color': MAPSTATE.HeadingColour,
          'fill-extrusion-height': ['+', ['*', ['get', 'Alt'], 1], 10],
          'fill-extrusion-base': ['-', ['*', ['get', 'Alt'], 1], 10],
          'fill-extrusion-opacity': 0.8,
        },
      });
    } else {
      mapb.getSource('GPS-HEADING').setData(data);
      if (mapb.getPitch() > 10 && MAPSTATE.Render3D == true) {
        mapb.getSource('GPS3D-HEADING').setData(data3d);
      }
    }
    //check for obstcle colissions along heading line
    CheckObstacleColission(ObstacleSearch);
  } else {
    //remove bearing line;
    if (mapb.getSource('GPS-HEADING') != undefined) {
      mapb.removeLayer('GPS-HEADING');
      mapb.removeSource('GPS-HEADING');
    }
    if (mapb.getSource('GPS3D-HEADING') != undefined) {
      mapb.removeLayer('GPS3D-HEADING');
      mapb.removeSource('GPS3D-HEADING');
    }
    MAPSTATE.ObstacleWarning = false;
  }
  if (LOCALSTORAGE.elevationGraph == 1) {
    updateElevationProfile(lineData);
  }
}

function updateElevationProfile(lineData) {
  // split the line into 1km segments
  const chunks = turf.lineChunk(lineData, 30, { units: 'meters' }).features;

  // get the elevation for the leading coordinate of each segment
  let distance = 0;
  let elevations = [
    ...chunks.map((feature) => {
      distance += 30;
      return [distance, Math.round(mapb.queryTerrainElevation(feature.geometry.coordinates[0]) * 3.28084)];
    }),
    // do not forget the last coordinate
    mapb.queryTerrainElevation(chunks[chunks.length - 1].geometry.coordinates[1]),
  ];
  elevations.pop();

  mapElevationChart.data.labels = elevations.map((elevation) => {
    return getDisplayDistance(elevation[0], true);
  });
  var altitudes = elevations.map((alt) => {
    return [alt[0], Math.round(GPS.altitude * 3.28084)];
  });

  mapElevationChart.data.datasets[0] = {
    data: elevations,
    fill: false,
    tension: 0.4,
  };
  mapElevationChart.data.datasets[1] = {
    data: altitudes,
    fill: false,
    tension: 0.4,
  };
  mapElevationChart.update();
}

function CheckObstacleColission(userPolygon) {
  if (confirmHideMapInfo() || MAPSTATE.Busy) return;
  // generate bounding box from polygon the user drew
  var polygonBoundingBox = turf.bbox(userPolygon);

  var southWest = [polygonBoundingBox[0], polygonBoundingBox[1]];
  var northEast = [polygonBoundingBox[2], polygonBoundingBox[3]];
  var northEastPointPixel = mapb.project(northEast);
  var southWestPointPixel = mapb.project(southWest);
  var features = mapb.queryRenderedFeatures([southWestPointPixel, northEastPointPixel], {
    layers: ['Obstacles3d', 'Obstacles'],
  });

  var WarningItems = [];
  var point1 = turf.point([GPS.longitude, GPS.latitude]);
  for (var i in features) {
    var F = features[i];
    if (!(null === turf.intersect(F, userPolygon))) {
      var ObstacleElev = (F.properties.Elevation + 100) * 0.3048; //to meters
      if (ObstacleElev > GPS.altitude) {
        var point2 = turf.point([F.properties.Long, F.properties.Lat]);
        F.properties.distance = turf.distance(point1, point2, { units: 'meters' });
        WarningItems.push(F);
        MAPSTATE.ObstacleWarning = true;
      }
    }
  }
  if (WarningItems.length && GPS.speed > 0) {
    WarningItems.sort(function (a, b) {
      return parseFloat(a.distance) - parseFloat(b.distance);
    });
    var O = WarningItems[0];
    var GPSft = Math.round(GPS.altitude * 3.28084);
    var html =
      "<table><tr><td><b style='color:red;'>OBSTACLE WARNING</b><br /><b>Height: <br />Elevation: <br />Current Alt:</b></td><td><b style='color:red;'>" +
      getDisplayDistance(O.properties.distance) +
      ' Ahead</b><br />' +
      Math.round(O.properties.Height) +
      'ft AGL<br />' +
      Math.round(O.properties.Elevation) +
      'ft ASL<br />' +
      GPSft +
      'ft ASL</td></tr></table>';
    var TimeToColission = O.properties.distance / GPS.speed;
    //if impact within 60 seconds warn pilot
    if (TimeToColission < 60) {
      $('#coordinates').html(html);
      if (CurPageID() == 'Map_Page') $('#coordinates').show();
    }
  } else {
    if (MAPSTATE.ObstacleWarning && CenterNav) coordinates_hide();
    MAPSTATE.ObstacleWarning = false;
  }
}
function ReCenterNav() {
  if (CenterNav == false) {
    $('#crosshair').fadeOut(500);
    coordinates_hide();
    CenterNav = true;
    mapb.removeControl(recenter);
    recenter = undefined;
    if (TrackUP) {
      var center = GPS.lnglat;
      var Lat = center.lat;
      var Long = center.lng;
      var point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [Long, Lat],
        },
      };
      //var distance = smallestMapEdge(); //smallest distance from edge of map
      //var Pitch = mapb.getPitch();
      //if (Pitch > 10) distance = distance / 2;
      //GPS.heading = 45;
      var heading = GPS.heading;
      if (heading == null) heading = 0;

      flyToMapPos(GPS.lnglat, heading);
    } else flyToMapPos(GPS.lnglat);
    //ensure to check offline style in case high res charts not present for current area
    if (MAPSTATE.OfflineStyleTimer != null) clearTimeout(MAPSTATE.OfflineStyleTimer);
    if (MAPSTATE.OfflineDataOn) {
      MAPSTATE.OfflineStyleTimer = setTimeout(function () {
        if (MAPSTATE.OfflineDataOn) setOfflineStyle(); //so other offline layers get added to map with user interaction dragend events
        MAPSTATE.OfflineStyleTimer = null;
      }, 3000);
    }
  }
  MAPSTATE.actionFirst = null;
}

function CancelNav() {
  if (Navigate != null) {
    mapb.removeControl(cancelnav);
    Navigate = null;
    cancelnav = undefined;

    mapb.removeLayer('GPS-NAV');
    mapb.removeSource('GPS-NAV');
    localStorageDB.clearItem('LastNav', null);
    MAPSTATE.CurDirectTo = null;
  }
  //CancelNavControl.removeFrom(map);
  $('#NEInfo').html('');
  $('#SEInfo').html('');
  $('#SWInfo').html('');
  CurrentDirectToItem = {
    type: '',
    label: '',
  };
}

function getETA(distance, speed) {
  if (speed != null && speed != 0) {
    var ETA;
    var distance;

    var decimalETA = distance / speed / 60 / 60;
    if (decimalETA == null) decimalETA = '0.0';
    decimalETA = String(decimalETA + '.0');
    var arrayETA = decimalETA.split('.');
    var ETAm = arrayETA[1];
    ETAm = '0.' + ETAm;
    ETAm = ETAm * 60;
    var hours = arrayETA[0];
    var days = '';
    if (hours > 24) {
      days = Math.floor(hours / 24);
      hours = hours - days * 24;
      days = days + 'd ';
    }
    if (days == '' && hours == '0') {
      hours = Math.floor(ETAm);
      var minutes = String(ETAm);
      minutes = minutes.split('.');
      minutes = '0.' + minutes[1];
      ETAm = Math.round(minutes * 60);
      if (isNaN(ETAm)) ETAm = '00';
      ETAm = ETAm + 's';
    } else {
      ETAm = Math.round(ETAm);
      if (ETAm < 10) ETAm = '0' + ETAm;
    }
    //if (isNaN(ETAm)) ETAm = "__";

    //if (isNaN(hours)) hours = "__";
    //if (isNaN(days)) days = "";
    ETA = days + FormatNumber(hours, 2, 0) + ':' + FormatNumber(ETAm, 2, 0);
  } else {
    ETA = '__:__';
  }
  return ETA;
}

function createCoordInput(lat, lng, format, latVAL, lngVAL) {
  var html = '<center><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';

  if (format == 'DD')
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_DD" value="DD" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'DD',false)\" checked>";
  else
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_DD" value="DD" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'DD',false)\">";
  html += '<label for="CoordFormat_DD">DD</label>';

  if (format == 'DMS')
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_DMS" value="DMS" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'DMS',false)\" checked>";
  else
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_DMS" value="DMS" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'DMS',false)\">";
  html += '<label for="CoordFormat_DMS">DMS</label>';

  if (format == 'DDM')
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_DDM" value="DDM" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'DDM',false)\" checked>";
  else
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_DDM" value="DDM" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'DDM',false)\">";
  html += '<label for="CoordFormat_DDM">DDM</label>';

  if (format == 'UTM')
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_UTM" value="UTM" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'UTM',false)\" checked>";
  else
    html +=
      '<input type="radio" name="CoordFormat" id="CoordFormat_UTM" value="UTM" onClick="ChangeCoordFormat(\'' +
      lat +
      "', '" +
      lng +
      "', 'UTM',false)\">";
  html += '<label for="CoordFormat_UTM">UTM</label>';

  html += '</fieldset></center>';

  html +=
    '<input type="hidden" id="' +
    lat +
    '" value="' +
    latVAL +
    '"/><input type="hidden" id="' +
    lng +
    '" value="' +
    lngVAL +
    '"/>';
  html += '<div id="' + lat + lng + '">' + createCoordInputSelected(lat, lng, format, latVAL, lngVAL) + '</div>';
  return html;
}

function ChangeCoordFormat(lat, lng, format, closing) {
  var from = LOCALSTORAGE.CoordFormat;
  if (from == null || from == undefined) from = 'DD';
  if (format == 'GPS') {
    var latDD = parseFloat($('#WPT_Lat').val());
    var lngDD = parseFloat($('#WPT_Long').val());
    format = from;
  } else {
    switch (from) {
      case 'DD':
        var latDD = parseFloat($('#DD_' + lat).val());
        var lngDD = parseFloat($('#DD_' + lng).val());
        break;
      case 'DMS':
        var latDD = ConvertDMS_DD(
          $('#DD_' + lat).val(),
          $('#MM_' + lat).val(),
          $('#SS_' + lat).val(),
          $('#NS_' + lat).val()
        );
        var lngDD = ConvertDMS_DD(
          $('#DD_' + lng).val(),
          $('#MM_' + lng).val(),
          $('#SS_' + lng).val(),
          $('#EW_' + lng).val()
        );
        break;
      case 'DDM':
        var latDD = ConvertDDM_DD($('#DD_' + lat).val(), $('#DM_' + lat).val(), $('#NS_' + lat).val());
        var lngDD = ConvertDDM_DD($('#DD_' + lng).val(), $('#DM_' + lng).val(), $('#EW_' + lng).val());
        break;
      case 'UTM':
        var hemi = false;
        if ($('#NS_' + lat).val() == 'S') hemi = true;
        var zone = $('#UTM_Z' + lat).val();
        var Easting = $('#UTM_E' + lat).val();
        var Northing = $('#UTM_N' + lat).val();
        var latlon = [];

        UTMXYToLatLon(Easting, Northing, zone, hemi, latlon);
        var latDD = RadToDeg(latlon[0]);
        var lngDD = RadToDeg(latlon[1]);
        break;
    }
    latDD = Math.round(latDD * 1000000) / 1000000;
    lngDD = Math.round(lngDD * 1000000) / 1000000;
    $('#' + lat).val(latDD);
    $('#' + lng).val(lngDD);
  }

  if (!closing) {
    var html = createCoordInputSelected(lat, lng, format, latDD, lngDD);
    $('#' + lat + lng).html(html);
    $('#' + lat + lng).enhanceWithin();
    ChangeUserCoordType(format);
  }
}

function createCoordInputSelected(lat, lng, format, latVAL, lngVAL) {
  var html = '';
  switch (format) {
    case 'DD':
      html += '<table><thead><tr><th>Item</th><th>Decimal Degrees</th></tr></thead><tbody>';
      html += '<tr><td>Lat</td><td><input type="number" id="DD_' + lat + '" value="' + latVAL + '"/></td></tr>';
      html += '<tr><td>Long</td><td><input type="number" id="DD_' + lng + '" value="' + lngVAL + '"/></td></tr>';
      html += '</tbody></table>';
      break;
    case 'DMS':
      var NS =
        '<select id="NS_' +
        lat +
        '" data-role="none"><option value="N">N</option><option value="S" selected>S</option></select>';
      var latDMS = ConvertDD_DMS('N', latVAL);

      if (latVAL >= 0) {
        NS =
          '<select id="NS_' +
          lat +
          '" data-role="none"><option value="N" selected>N</option><option value="S">S</option></select>';
        latDMS = ConvertDD_DMS('S', latVAL);
      }
      var EW =
        '<select id="EW_' +
        lng +
        '" data-role="none"><option value="W" selected>W</option><option value="E">E</option></select>';
      var lngDMS = ConvertDD_DMS('W', lngVAL);
      if (lngVAL >= 0) {
        EW =
          '<select id="EW_' +
          lng +
          '" data-role="none"><option value="W">W</option><option value="E" selected>E</option></select>';
        lngDMS = ConvertDD_DMS('E', lngVAL);
      }
      html += '<table><thead><tr><th>Item</th><th>H</th><th>DD</th><th>MM</th><th>SS</th></tr></thead><tbody>';
      html +=
        '<tr><td>Lat</td><td>' +
        NS +
        '</td><td><input data-role="none" type="number" id="DD_' +
        lat +
        '" value="' +
        latDMS.DD +
        '" style="width:40px;"/></td><td><input data-role="none" type="number" id="MM_' +
        lat +
        '" value="' +
        latDMS.MM +
        '" style="width:40px;"/></td><td><input data-role="none" type="number" id="SS_' +
        lat +
        '" value="' +
        latDMS.SS +
        '" style="width:50px;"/></td></tr>';
      html +=
        '<tr><td>Long</td><td>' +
        EW +
        '</td><td><input data-role="none" type="number" id="DD_' +
        lng +
        '" value="' +
        lngDMS.DD +
        '" style="width:40px;"/></td><td><input data-role="none" type="number" id="MM_' +
        lng +
        '" value="' +
        lngDMS.MM +
        '" style="width:40px;"/></td><td><input data-role="none" type="number" id="SS_' +
        lng +
        '" value="' +
        lngDMS.SS +
        '" style="width:50px;"/></td></tr>';
      html += '</tbody></table>';
      break;
    case 'DDM':
      var NS =
        '<select id="NS_' +
        lat +
        '" data-role="none"><option value="N">N</option><option value="S" selected>S</option></select>';
      var latDMS = ConvertDD_DDM('N', latVAL);
      if (latVAL >= 0) {
        NS =
          '<select id="NS_' +
          lat +
          '" data-role="none"><option value="N" selected>N</option><option value="S">S</option></select>';
        latDMS = ConvertDD_DDM('S', latVAL);
      }
      var EW =
        '<select id="EW_' +
        lng +
        '" data-role="none"><option value="W" selected>W</option><option value="E">E</option></select>';
      var lngDMS = ConvertDD_DDM('W', lngVAL);
      if (lngVAL >= 0) {
        EW =
          '<select id="EW_' +
          lng +
          '" data-role="none"><option value="W">W</option><option value="E" selected>E</option></select>';
        lngDMS = ConvertDD_DDM('E', lngVAL);
      }
      html += '<table><thead><tr><th>Item</th><th>H</th><th>DD</th><th>DM</th></tr></thead><tbody>';
      html +=
        '<tr><td>Lat</td><td>' +
        NS +
        '</td><td><input data-role="none" type="number" id="DD_' +
        lat +
        '" value="' +
        latDMS.DD +
        '" style="width:40px;"/></td><td><input data-role="none" type="number" id="DM_' +
        lat +
        '" value="' +
        latDMS.DM +
        '" style="width:80px;"/></td></tr>';
      html +=
        '<tr><td>Long</td><td>' +
        EW +
        '</td><td><input data-role="none" type="number" id="DD_' +
        lng +
        '" value="' +
        lngDMS.DD +
        '" style="width:40px;"/></td><td><input data-role="none" type="number" id="DM_' +
        lng +
        '" value="' +
        lngDMS.DM +
        '" style="width:80px;"/></td></tr>';
      html += '</tbody></table>';
      break;
    case 'UTM':
      var utmOK = getUTM(parseFloat(latVAL), parseFloat(lngVAL));

      var NS =
        '<select id="NS_' +
        lat +
        '" data-role="none"><option value="N">N</option><option value="S" selected>S</option></select>';
      if (utmhemi == 'N')
        NS =
          '<select id="NS_' +
          lat +
          '" data-role="none"><option value="N" selected>N</option><option value="S">S</option></select>';
      if (utmzone.length == 2) utmzone = utmzone.substr(0, 1);
      if (utmzone.length == 3) utmzone = utmzone.substr(0, 2);
      html += '<table><thead><tr><th>Item</th><th>Value</th></tr></thead><tbody>';
      html += '<tr><td>Hemisphere</td><td>' + NS + '</td></tr>';
      html += '<tr><td>Zone</td><td><input type="text" id="UTM_Z' + lat + '" value="' + utmzone + '"></td></tr>';
      html += '<tr><td>Easting</td><td><input type="number" id="UTM_E' + lat + '" value="' + utmlat + '"></td></tr>';
      html += '<tr><td>Northing</td><td><input type="number" id="UTM_N' + lat + '" value="' + utmlong + '"></td></tr>';
      html += '</tbody></table>';

      break;
  }
  return html;
}
function ConvertDMS_DD(degrees, minutes, seconds, direction) {
  var dd = parseInt(degrees) + parseInt(minutes) / 60 + parseFloat(seconds) / (60 * 60);

  if (direction == 'S' || direction == 'W') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}
function ConvertDD_DMS(lng, D) {
  var dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';
  var DD = 0 | (D < 0 ? (D = -D) : D);
  var MM = 0 | ((D % 1) * 60);
  var SS = (0 | (((D * 60) % 1) * 6000)) / 100;
  return {
    dir: lng,
    DD: DD,
    MM: MM,
    SS: SS,
  };
}
function ConvertDDM_DD(degrees, minutes, direction) {
  var dd = parseInt(degrees) + parseFloat(minutes) / 60;

  if (direction == 'S' || direction == 'W') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
}
function ConvertDD_DDM(lng, D) {
  var dir = D < 0 ? (lng ? 'W' : 'S') : lng ? 'E' : 'N';
  var DD = 0 | (D < 0 ? (D = -D) : D);
  var MM = 0 | ((D % 1) * 60);
  var SS = (0 | (((D * 60) % 1) * 6000)) / 100;
  SS = SS / 60;
  var DM = MM + SS;
  DM = Math.round(DM * 10000) / 10000;
  return {
    dir: lng,
    DD: DD,
    DM: DM,
  };
}

function ConvertDD_User(coord, type) {
  var D = 'N';
  coord = parseFloat(coord);
  switch (type) {
    case 'Lat':
      if (coord >= 0) D = 'N';
      else D = 'S';
      break;
    case 'Long':
      if (coord >= 0) D = 'E';
      else D = 'W';
      break;
  }

  var To = LOCALSTORAGE.CoordFormat;
  if (To == null || To == 'DD' || To == undefined) {
    if (D == 'E' || D == 'W') {
      if (Math.floor(coord) >= 100) return D + FormatNumber(Math.abs(coord), 2, 5) + '°';
      else return D + ' ' + FormatNumber(Math.abs(coord), 2, 5) + '°';
    } else return D + '' + FormatNumber(coord, 3, 5) + '°';
  }
  switch (To) {
    case 'DMS':
      var DMS = ConvertDD_DMS(D, coord);
      if (D == 'E' || D == 'W') {
        if (Math.floor(DMS.DD >= 100))
          return (
            DMS.dir +
            '' +
            FormatNumber(DMS.DD, 3, 0) +
            '° ' +
            FormatNumber(DMS.MM, 2, 0) +
            "' " +
            FormatNumber(DMS.SS, 2, 2) +
            '"'
          );
        else
          return (
            DMS.dir +
            ' ' +
            FormatNumber(DMS.DD, 3, 0) +
            '° ' +
            FormatNumber(DMS.MM, 2, 0) +
            "' " +
            FormatNumber(DMS.SS, 2, 2) +
            '"'
          );
      } else
        return (
          DMS.dir +
          ' ' +
          FormatNumber(DMS.DD, 2, 0) +
          '° ' +
          FormatNumber(DMS.MM, 2, 0) +
          "' " +
          FormatNumber(DMS.SS, 2, 2) +
          '"'
        );
      break;
    case 'DDM':
      var DDM = ConvertDD_DDM(D, coord);
      if (D == 'E' || D == 'W') {
        if (Math.floor(DDM.DD) >= 100)
          return DDM.dir + '' + FormatNumber(DDM.DD, 3, 0) + '° ' + FormatNumber(DDM.DM, 2, 4) + "'";
        else return DDM.dir + ' ' + FormatNumber(DDM.DD, 3, 0) + '° ' + FormatNumber(DDM.DM, 2, 4) + "'";
      } else return DDM.dir + ' ' + FormatNumber(DDM.DD, 2, 0) + '° ' + FormatNumber(DDM.DM, 2, 4) + "'";
      break;
    default:
      return coord + '°';
      break;
  }
}
function FormatNumber(num, leading, decnum) {
  if (isNaN(num)) return num;
  num = parseFloat(num);
  var M = Math.pow(10, decnum);
  var dec = parseFloat(Math.round(num * M) / M).toFixed(decnum);
  var dec = dec.split('.')[1];
  if (dec == undefined) dec = '';
  else dec = '.' + dec;
  var s = parseInt(num) + '';
  while (s.length < leading) s = '0' + s;
  return s + dec;
}
function ChangeUserCoordType(type) {
  if (type == null || type == undefined) {
    type = 'DD';
    localStorageDB.setItem('CoordFormat', 'DD');
    LOCALSTORAGE.CoordFormat = 'DD';
  }
  if (type != 'init') {
    localStorageDB.setItem('CoordFormat', type);
    LOCALSTORAGE.CoordFormat = type;
  }

  switch (type) {
    case 'DD':
      $('#UserCoordType_DD').prop('checked', true).checkboxradio('refresh');
      $('#UserCoordType_DMS').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DDM').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_UTM').prop('checked', false).checkboxradio('refresh');
      break;
    case 'DMS':
      $('#UserCoordType_DD').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DMS').prop('checked', true).checkboxradio('refresh');
      $('#UserCoordType_DDM').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_UTM').prop('checked', false).checkboxradio('refresh');
      break;
    case 'DDM':
      $('#UserCoordType_DD').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DMS').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DDM').prop('checked', true).checkboxradio('refresh');
      $('#UserCoordType_UTM').prop('checked', false).checkboxradio('refresh');
      break;
    case 'UTM':
      $('#UserCoordType_DD').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DMS').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DDM').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_UTM').prop('checked', true).checkboxradio('refresh');
      break;
    default:
      $('#UserCoordType_DD').prop('checked', true).checkboxradio('refresh');
      $('#UserCoordType_DMS').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_DDM').prop('checked', false).checkboxradio('refresh');
      $('#UserCoordType_UTM').prop('checked', false).checkboxradio('refresh');
      break;
  }
}

function StopMeasure(clearData = true) {
  if (mapb.getSource('ROUTEPLAN-RADIUS') != undefined) {
    mapb.removeLayer('ROUTEPLAN-RADIUS');
    mapb.removeLayer('ROUTEPLAN-RADIUS-LABELS');
    mapb.removeSource('ROUTEPLAN-RADIUS');
  }
  if (!IOS && !ANDROID) {
    mapb.off('click', MeasureClick);
    mapb.off('mousedown', MeasureMouseDown);
    mapb.off('mouseup', MeasureMouseUp);
    mapb.off('mousemove', MeasureMove);
  } else {
    mapb.off('touchstart', MeasureTouchStart);
  }

  mapb.removeLayer('measure-points');
  mapb.removeLayer('measure-lines');
  mapb.removeLayer('measure-labels');

  mapb.removeSource('MeasureGEOJSON');
  mapb.removeSource('MeasureLABELS');
  if (mapb.getLayer('MOVE-measure') != undefined) {
    mapb.removeLayer('MOVE-measure');
    mapb.removeSource('MOVE-measure');
  }
  coordinates_hide();
  MAPSTATE.Measuring = false;
  $('#MeasureControlIMG2').hide();
  $('#MeasureControlIMG').show();
  mapb.getCanvas().style.cursor = '';
  $('#NewLegControl').prop('disabled', false);
  $('#StartStopControl').prop('disabled', false);
  if (clearData && MEASURE.EditExisting && WANDB == 1) {
    LoadFollowDataFuelPlan();
  } else {
    if (!MEASURE.EditExisting) resetMeasureData();
  }
}
function ClearMeasureData() {
  MEASURE.geojson = {
    type: 'FeatureCollection',
    features: [],
  };
  MEASURE.linestring = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
  };
  MEASURE.labels = {
    type: 'FeatureCollection',
    features: [],
  };
  MEASURE.LEGS = [];
  MEASURE.CurLeg = 0;
}
function StartMeasure() {
  if (MAPSTATE.Measuring == true) {
    if (MEASURE.EditExisting == false) {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Warning',
        headerClose: false,
        buttonPrompt: 'This will remove all measuring data currently displayed.',
        buttons: {
          'Stop Measuring': {
            click: function () {
              StopMeasure();
              PLANAC = null;
              LoadFollowDataFuelPlan();
            },
          },
          Cancel: {
            click: function () {
              //File bug report
            },
          },
        },
      });
    } else {
      $('<div>').simpledialog2({
        mode: 'button',
        animate: false,
        headerText: 'Save Edits',
        headerClose: false,
        buttonPrompt: 'Would you like to save your Fuel Plan Edits.',
        buttons: {
          'Save Fuel Plan Edits': {
            click: function () {
              StopMeasure(false);

              WB_LEG_INDEX = MEASURE.OrigLeg;
              SCH_DATA.LegIndex = MEASURE.OrigLeg;
              MEASURE.CurLeg = MEASURE.OrigLeg;
              WB_fileaclistSelect({ index: MEASURE.EditIndex });
            },
          },
          'Discard Changes': {
            click: function () {
              //File bug report
              StopMeasure(true);
              WB_LEG_INDEX = MEASURE.OrigLeg;
              SCH_DATA.LegIndex = MEASURE.OrigLeg;
              MEASURE.CurLeg = MEASURE.OrigLeg;
            },
          },
        },
      });
    }
    return;
  }
  if (!IOS && !ANDROID) {
    mapb.on('click', MeasureClick);
    mapb.on('mousedown', MeasureMouseDown);
    mapb.on('mouseup', MeasureMouseUp);
    mapb.on('mousemove', MeasureMove);
  } else {
    mapb.on('touchstart', MeasureTouchStart);
  }

  $('#MeasureControlIMG').hide();
  $('#MeasureControlIMG2').show();
  MAPSTATE.Measuring = true;

  if (MEASURE.EditExisting) {
    $('<div>').simpledialog2({
      mode: 'button',
      animate: false,
      headerText: 'New / Edit',
      headerClose: false,
      buttonPrompt: 'You have an existing Itinerary Fuel Plan available for editing.<br />What would you like to do?',
      buttons: {
        'Start Measure Tool': {
          click: function () {
            resetMeasureData();
            PLANAC = null;
            FinishINIT_Measure();
          },
        },
        'Edit Itinerary Fuel Plan': {
          click: function () {
            if (MEASURE.LEGS[MEASURE.CurLeg] != undefined) {
              MEASURE.geojson = MEASURE.LEGS[MEASURE.CurLeg];
              if (MEASURE.geojson.features == undefined) {
                MEASURE.geojson = {
                  type: 'FeatureCollection',
                  features: [],
                };
              }
              if (MEASURE.geojson.features.length == 0) {
                MEASURE.geojson = {
                  type: 'FeatureCollection',
                  features: [],
                };
              }
            } else {
              MEASURE.geojson = {
                type: 'FeatureCollection',
                features: [],
              };
            }
            MEASURE.OrigLeg = MEASURE.CurLeg;
            try {
              LoadFPsettings(MEASURE.geojson.features[0].properties.FuelPlan);
            } catch (e) {
              LoadACconfig(Follow_Data[MEASURE.EditIndex].AC.ident);
            }
            FinishINIT_Measure();
          },
        },
      },
    });
  } else {
    FinishINIT_Measure();
  }
}
function FinishINIT_Measure() {
  mapb.addSource('MeasureGEOJSON', {
    type: 'geojson',
    data: MEASURE.geojson,
  });

  // Add styles to the map
  mapb.addLayer({
    id: 'measure-points',
    type: 'circle',
    source: 'MeasureGEOJSON',
    paint: {
      'circle-radius': 12,
      'circle-color': MAPSTATE.HeadingColour,
      'circle-opacity': 0.7,
      'circle-stroke-color': '#FF08FA',
      'circle-stroke-width': 3,
      'circle-stroke-opacity': 0.3,
    },
    filter: ['in', '$type', 'Point'],
  });
  mapb.addSource('MeasureLABELS', {
    type: 'geojson',
    data: MEASURE.labels,
  });
  mapb.addLayer({
    id: 'measure-lines',
    type: 'line',
    source: 'MeasureLABELS',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': MAPSTATE.HeadingColour,
      'line-width': 2.5,
    },
  });
  mapb.addLayer({
    id: 'measure-labels',
    type: 'symbol',
    source: 'MeasureLABELS',
    layout: {
      'symbol-placement': 'line-center',
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Regular'],
      'text-field': '{label}',
      'text-size': 16,
      'text-ignore-placement': true,
      'text-allow-overlap': true,
    },
    paint: {
      'text-color': '#ffffff',
      'text-halo-color': '#000000',
      'text-halo-width': 1.5,
    },
  });
  $('#NewLegControl').prop('disabled', true);
  $('#StartStopControl').prop('disabled', true);
  MeasureUpdateDraw();
}
var MEASURE = {
  touchTimer: null,
  linestring: {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
  },
  geojson: {
    type: 'FeatureCollection',
    features: [],
  },
  labels: {
    type: 'FeatureCollection',
    features: [],
  },
  dragoff: false,
  moving: false,
  featureDragging: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [0, 0],
        },
        properties: {
          id: 0,
          type: 'point',
        },
      },
    ],
  },
  CurLeg: 0,
  LEGS: [],
  dialog: false,
  EditExisting: false,
  EditIndex: null,
};

function resetMeasureData() {
  MEASURE = {
    touchTimer: null,
    linestring: {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    },
    geojson: {
      type: 'FeatureCollection',
      features: [],
    },
    labels: {
      type: 'FeatureCollection',
      features: [],
    },
    dragoff: false,
    moving: false,
    featureDragging: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [0, 0],
          },
          properties: {
            id: 0,
            type: 'point',
          },
        },
      ],
    },
    CurLeg: 0,
    LEGS: [
      {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [0, 0],
            },
            properties: {
              id: 0,
              type: 'point',
            },
          },
        ],
      },
    ],
    dialog: false,
    EditExisting: false,
    EditIndex: null,
  };
}
function MeasureMove(e) {
  var coords = e.lngLat;
  if (MEASURE.moving) {
    var bbox = [
      [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
      [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
    ];
    var layers = [
      'Airports-Large',
      'Airports-Medium',
      'Airports-Small',
      'Airports-Seaplane',
      'Airports-Heliport',
      'Airports-Balloonport',
      'Navaids',
    ];
    if (!$('#navaidlayer').is(':checked')) layers.pop();
    layers = layers.concat(getSelectableUserLayers());

    var features = mapb.queryRenderedFeatures(bbox, { layers: layers });
    //currently moving existing point
    var featureIndex = null;
    for (var i in features) {
      if (features[i].geometry.type == 'Point') {
        featureIndex = i;
        break;
      }
    }
    var prop = MEASURE.featureDragging.features[0].properties;
    if (features.length > 0 && featureIndex != null) {
      MEASURE.featureDragging.features[0].geometry.coordinates = features[featureIndex].geometry.coordinates;
      MEASURE.featureDragging.features[0].properties = features[featureIndex].properties;
      MEASURE.featureDragging.features[0].properties.id = parseInt(prop.id);
      MEASURE.featureDragging.features[0].properties.type = prop.type;
      if (features[0].source == 'Navaids') MEASURE.featureDragging.features[0].properties.NavType = 'Navaid';
      else if (features[0].source == 'Airports') MEASURE.featureDragging.features[0].properties.NavType = 'Airport';
      else {
        MEASURE.featureDragging.features[0].properties.NavType = 'Waypoint';
        MEASURE.featureDragging.features[0].properties.Long = features[featureIndex].geometry.coordinates[0];
        MEASURE.featureDragging.features[0].properties.Lat = features[featureIndex].geometry.coordinates[1];
      }
      MEASURE.featureDragging.features[0].properties.isAlternate = prop.isAlternate;
    } else {
      MEASURE.featureDragging.features[0].geometry.coordinates = [coords.lng, coords.lat];
      MEASURE.featureDragging.features[0].properties = {
        id: parseInt(prop.id),
        type: prop.type,
        NavType: 'Waypoint',
        Lat: coords.lat,
        Long: coords.lng,
        isAlternate: prop.isAlternate,
      };
    }

    mapb.getSource('MOVE-measure').setData(MEASURE.featureDragging);
    UpdateMeasureData(e);
  } else {
    var bbox = [
      [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
      [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
    ];
    var features = mapb.queryRenderedFeatures(bbox, { layers: ['measure-points', 'measure-lines'] });
    // UI indicator for clicking/hovering a point on the map
    mapb.getCanvas().style.cursor = features.length ? 'pointer' : 'crosshair';
    if (features.length > 0) {
      var id = 0;
      if (features.length > 1) {
        for (var i in features) if (features[i].properties.type == 'point') id = i;
      }
      MEASURE.dragoff = true;
      MEASURE.featureDragging.features[0].geometry.coordinates = [coords.lng, coords.lat];
      MEASURE.featureDragging.features[0].properties = features[id].properties;
    } else {
      MEASURE.dragoff = false;
    }
  }
}

function MeasuretouchMove(e) {
  if (MEASURE.dragoff == false) return;
  var coords = e.lngLat;
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var layers = [
    'Airports-Large',
    'Airports-Medium',
    'Airports-Small',
    'Airports-Seaplane',
    'Airports-Heliport',
    'Airports-Balloonport',
    'Navaids',
  ];
  if (!$('#navaidlayer').is(':checked')) layers.pop();
  layers = layers.concat(getSelectableUserLayers());
  var features = mapb.queryRenderedFeatures(bbox, { layers: layers });
  var featureIndex = null;
  for (var i in features) {
    if (features[i].geometry.type == 'Point') {
      featureIndex = i;
      break;
    }
  }
  var prop = MEASURE.featureDragging.features[0].properties;
  if (features.length > 0 && featureIndex != null) {
    MEASURE.featureDragging.features[0].geometry.coordinates = features[featureIndex].geometry.coordinates;
    MEASURE.featureDragging.features[0].properties = features[featureIndex].properties;
    MEASURE.featureDragging.features[0].properties.id = parseInt(prop.id);
    MEASURE.featureDragging.features[0].properties.type = prop.type;
    if (features[0].source == 'Navaids') MEASURE.featureDragging.features[0].properties.NavType = 'Navaid';
    else if (features[0].source == 'Airports') MEASURE.featureDragging.features[0].properties.NavType = 'Airport';
    else {
      MEASURE.featureDragging.features[0].properties.NavType = 'Waypoint';
      MEASURE.featureDragging.features[0].properties.Long = features[featureIndex].geometry.coordinates[0];
      MEASURE.featureDragging.features[0].properties.Lat = features[featureIndex].geometry.coordinates[1];
    }
    MEASURE.featureDragging.features[0].properties.isAlternate = prop.isAlternate;
  } else {
    MEASURE.featureDragging.features[0].geometry.coordinates = [coords.lng, coords.lat];
    MEASURE.featureDragging.features[0].properties = {
      id: parseInt(prop.id),
      type: prop.type,
      NavType: 'Waypoint',
      Lat: coords.lat,
      Long: coords.lng,
      isAlternate: prop.isAlternate,
    };
  }
  if (mapb.getLayer('MOVE-measure') != undefined) mapb.getSource('MOVE-measure').setData(MEASURE.featureDragging);
  UpdateMeasureData(e);
}

function MeasureTouchStart(e) {
  if (e.points.length > 1) {
    if (MEASURE.touchTimer != null) {
      clearTimeout(MEASURE.touchTimer);
      MEASURE.touchTimer = null;
      MEASURE.moving = false;
      MEASURE.dragoff = false;
      mapb.dragPan.enable();
      mapb.off('touchend', MeasureTouchEnd);
      return;
    }
  }
  var coords = e.lngLat;
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var features = mapb.queryRenderedFeatures(bbox, { layers: ['measure-points', 'measure-lines'] });
  // UI indicator for clicking/hovering a point on the map

  if (MEASURE.touchTimer != null) clearTimeout(MEASURE.touchTimer);
  MEASURE.touchTimer = null;

  if (features.length > 0) {
    MEASURE.dragoff = true;
    mapb.dragPan.disable();
    mapb.on('touchend', MeasureTouchEnd);
    MEASURE.touchTimer = setTimeout(function () {
      var id = 0;
      if (features.length > 1) {
        for (var i in features) if (features[i].properties.type == 'point') id = i;
      }
      MEASURE.featureDragging.features[0].geometry.coordinates = [coords.lng, coords.lat];
      MEASURE.featureDragging.features[0].properties = features[id].properties;

      MEASURE.touchTimer = null;
      //mapb.off('click', MeasureClick);
      mapb.on('touchmove', MeasuretouchMove);
      document.addEventListener('touchmove', cancelOverScroll);
      mapb.addSource('MOVE-measure', {
        type: 'geojson',
        data: MEASURE.featureDragging,
      });

      mapb.addLayer({
        id: 'MOVE-measure',
        type: 'circle',
        source: 'MOVE-measure',
        paint: {
          'circle-radius': 12,
          'circle-color': '#3887be',
        },
      });
    }, 200);
  } else {
    MEASURE.dragoff = false;
    mapb.on('touchend', MeasureTouchEnd);
    MEASURE.touchTimer = setTimeout(function () {
      MEASURE.touchTimer = null;
      mapb.off('touchend', MeasureTouchEnd);
    }, 200);
  }
}
function MeasureMouseDown(e) {
  if (MEASURE.dragoff) {
    mapb.dragPan.disable();
    MEASURE.moving = true;
    mapb.getCanvas().style.cursor = 'move';
    mapb.addSource('MOVE-measure', {
      type: 'geojson',
      data: MEASURE.featureDragging,
    });

    mapb.addLayer({
      id: 'MOVE-measure',
      type: 'circle',
      source: 'MOVE-measure',
      paint: {
        'circle-radius': 12,
        'circle-color': '#3887be',
      },
    });
  }
}
function MeasureTouchEnd(e) {
  mapb.off('touchmove', MeasuretouchMove);
  mapb.off('touchend', MeasureTouchEnd);
  document.removeEventListener('touchmove', cancelOverScroll);
  if (MEASURE.touchTimer != null) {
    clearTimeout(MEASURE.touchTimer);
    MEASURE.touchTimer = null;
    MEASURE.moving = false;
    MEASURE.dragoff = false;
    mapb.dragPan.enable();

    if (mapb.getLayer('MOVE-measure') != undefined) {
      mapb.removeLayer('MOVE-measure');
      mapb.removeSource('MOVE-measure');
    }
    MeasureClick(e);
  } else {
    MeasureMouseUp(e);
  }
}
function MeasureMouseUp(e) {
  if (MEASURE.dragoff == false) return;
  mapb.dragPan.enable();
  MEASURE.moving = false;
  MEASURE.dragoff = false;
  if (mapb.getLayer('MOVE-measure') != undefined) {
    mapb.removeLayer('MOVE-measure');
    mapb.removeSource('MOVE-measure');
  }
  UpdateMeasureData(e);
}
function UpdateMeasureData(e) {
  var properties = MEASURE.featureDragging.features[0].properties;
  if (properties.type == 'point') {
    MEASURE.geojson.features[properties.id].geometry.coordinates =
      MEASURE.featureDragging.features[0].geometry.coordinates;
    MEASURE.geojson.features[properties.id].properties = MEASURE.featureDragging.features[0].properties;
  }
  if (properties.type == 'line') {
    var point = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: MEASURE.featureDragging.features[0].geometry.coordinates,
      },
      properties: MEASURE.featureDragging.features[0].properties,
    };
    point.properties.id = parseInt(properties.id);
    point.properties.type = 'point';
    point.properties.NavType = 'Waypoint';
    MEASURE.geojson.features.splice(properties.id, 0, point);

    for (var i in MEASURE.geojson.features) {
      MEASURE.geojson.features[i].properties.id = i;
    }
  }
  MeasureUpdateDraw();
}

function MeasureClick(e) {
  var bbox = [
    [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
    [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
  ];
  var features = mapb.queryRenderedFeatures(bbox, { layers: ['measure-points'] });

  // Remove the linestring from the group
  // So we can redraw it based on the points collection
  //if (MEASURE.geojson.features.length > 1) MEASURE.geojson.features.pop();

  // If a feature was clicked, remove it from the map
  if (features.length) {
    var id = features[0].properties.id;
    MEASURE.geojson.features.splice(id, 1);
    for (var i in MEASURE.geojson.features) {
      MEASURE.geojson.features[i].properties.id = i;
    }
  } else {
    var coords = e.lngLat;
    var bbox = [
      [e.point.x - MAPSTATE.ClickBuffer, e.point.y - MAPSTATE.ClickBuffer],
      [e.point.x + MAPSTATE.ClickBuffer, e.point.y + MAPSTATE.ClickBuffer],
    ];
    var layers = [
      'Airports-Large',
      'Airports-Medium',
      'Airports-Small',
      'Airports-Seaplane',
      'Airports-Heliport',
      'Airports-Balloonport',
      'Navaids',
    ];
    if (!$('#navaidlayer').is(':checked')) layers.pop();
    layers = layers.concat(getSelectableUserLayers());
    var features = mapb.queryRenderedFeatures(bbox, { layers: layers });

    //currently moving existing point
    var featureIndex = null;
    for (var i in features) {
      if (features[i].geometry.type == 'Point') {
        featureIndex = i;
        break;
      }
    }
    if (features.length > 0 && featureIndex != null) {
      var point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: features[featureIndex].geometry.coordinates,
        },
        properties: features[featureIndex].properties,
      };
      point.properties.id = MEASURE.geojson.features.length;
      point.properties.type = 'point';

      if (features[featureIndex].source == 'Navaids') point.properties.NavType = 'Navaid';
      else if (features[featureIndex].source == 'Airports') point.properties.NavType = 'Airport';
      else {
        point.properties.NavType = 'Waypoint';
        point.properties.Long = features[featureIndex].geometry.coordinates[0];
        point.properties.Lat = features[featureIndex].geometry.coordinates[1];
      }
    } else {
      var point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        properties: {
          id: MEASURE.geojson.features.length,
          type: 'point',
          NavType: 'Waypoint',
          Lat: e.lngLat.lat,
          Long: e.lngLat.lng,
        },
      };
    }
    MEASURE.geojson.features.push(point);
  }
  MeasureUpdateDraw();
}

function RouteToMarkerNew(prop, coordinates) {
  if (MAPSTATE.Measuring == false) return;
  var point = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: coordinates,
    },
    properties: prop,
  };

  point.properties.id = MEASURE.geojson.features.length;
  point.properties.NavType = 'Waypoint';
  point.properties.Lat = coordinates[1];
  point.properties.Long = coordinates[0];

  MEASURE.geojson.features.push(point);
  MeasureUpdateDraw();
}

function MeasureUpdateDraw() {
  if (!MEASURE.dragoff) UpdateFuelPlan();
  if (MEASURE.geojson.features.length > 1) {
    MEASURE.linestring.geometry.coordinates = MEASURE.geojson.features.map(function (point) {
      return point.geometry.coordinates;
    });
    MEASURE.labels = {
      type: 'FeatureCollection',
      features: [],
    };
    //MEASURE.geojson.features.push(MEASURE.linestring);
    var wdt = new Date();
    var wmmDate = wdt.getFullYear() + (wdt.getMonth() + 1) / 12.0;
    for (var i in MEASURE.geojson.features) {
      if (i == 0) continue;
      var P1 = MEASURE.geojson.features[parseInt(i) - 1].geometry.coordinates;
      var P2 = MEASURE.geojson.features[i].geometry.coordinates;
      var point1 = turf.point(P1);
      var point2 = turf.point(P2);
      var midpoint = turf.midpoint(point1, point2);
      var distance = getDisplayDistance(turf.distance(point1, point2, { units: 'meters' }));
      var bearing = turf.bearing(point1, point2).toFixed(0);
      if (bearing < 0) bearing = 360 + parseInt(bearing);
      var label = distance + '\n ' + bearing + '°T';

      var Mdev = MAPSTATE.wmm.declination(
        0,
        midpoint.geometry.coordinates[1],
        midpoint.geometry.coordinates[1],
        wmmDate
      );

      var mheading = bearing - Mdev;
      if (mheading >= 360) mheading -= 360;
      if (mheading < 0) mheading += 360;
      mheading = Math.round(mheading);
      if (MAPSTATE.HeadingMag) {
        label = distance + '\n ' + mheading + '°M';
      }

      var label = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [P1, P2],
        },
        properties: {
          distance: distance,
          bearing: bearing + '°T',
          bearingM: mheading,
          label: label,
          id: i,
          type: 'line',
        },
      };
      MEASURE.labels.features.push(label);
    }
  } else {
    MEASURE.labels.features = [];
    MEASURE.linestring.geometry.coordinates = [];
  }

  MEASURE.LEGS[MEASURE.CurLeg] = JSON.parse(JSON.stringify(MEASURE.geojson));
  mapb.getSource('MeasureGEOJSON').setData(MEASURE.geojson);
  mapb.getSource('MeasureLABELS').setData(MEASURE.labels);
  if (!MEASURE.dragoff) UpdateMeasureWindow();
}

function MakeNavAlternate() {
  MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties.isAlternate = true;
  MeasureUpdateDraw();
}
function RemoveNavAlternate() {
  MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties.isAlternate = false;
  MeasureUpdateDraw();
}
function getNavTypeSummary(p) {
  var txt = '';
  if (window.innerWidth < 500) {
    if (p.NavType == 'Airport') {
      txt += p.ICAO;
    } else if (p.NavType == 'Navaid') {
      txt += p.ID;
    } else {
      txt += 'Wpt';
    }
  } else {
    if (p.NavType == 'Airport') {
      txt += 'Apt:' + p.ICAO;
    } else if (p.NavType == 'Navaid') {
      txt += 'Nav:' + p.ID;
    } else {
      txt +=
        "<center>Waypoint<div style='font-size:small'>" +
        ConvertDD_User(Math.round(p.Lat * 1000) / 1000, 'Lat') +
        '<br />' +
        ConvertDD_User(Math.round(p.Long * 1000) / 1000, 'Long') +
        '</div></center>';
    }
  }
  return txt;
}
function NextNavLeg(synced = false) {
  if (!synced) {
    if (RoutePlanningON) {
      SCH_DATA.LegIndex = MEASURE.CurLeg;
      ITIN_PopLeg();
      ITIN_Save_Leg();
    }
    MEASURE.CurLeg++;
    SCH_DATA.LegIndex = MEASURE.CurLeg;
    WB_LEG_INDEX = MEASURE.CurLeg;
  }
  if (MEASURE.LEGS[MEASURE.CurLeg] != undefined) {
    MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[MEASURE.CurLeg]));
    if (MEASURE.geojson.features[0].properties.FuelPlan != undefined)
      LoadFPsettings(MEASURE.geojson.features[0].properties.FuelPlan);
  } else {
    MEASURE.geojson = {
      type: 'FeatureCollection',
      features: [],
    };
    var Item;
    if (
      MEASURE.LEGS[MEASURE.CurLeg - 1].features[MEASURE.LEGS[MEASURE.CurLeg - 1].features.length - 1].properties
        .isAlternate
    ) {
      Item = JSON.parse(
        JSON.stringify(MEASURE.LEGS[MEASURE.CurLeg - 1].features[MEASURE.LEGS[MEASURE.CurLeg - 1].features.length - 2])
      );
      Item.properties.isAlternate = false;
      Item.properties.id = 0;
    } else {
      Item = JSON.parse(
        JSON.stringify(MEASURE.LEGS[MEASURE.CurLeg - 1].features[MEASURE.LEGS[MEASURE.CurLeg - 1].features.length - 1])
      );
      Item.properties.isAlternate = false;
      Item.properties.id = 0;
    }
    MEASURE.geojson.features.push(Item);
    if (MEASURE.geojson.features[0].properties.FuelPlan != undefined) {
      if (!MANUAL_LEG) {
        LoadFPsettings(MEASURE.geojson.features[0].properties.FuelPlan);
        if (CHKAutoCalcFuel) {
          MEASURE.geojson.features[0].properties.FuelPlan.StartFuel = ACmaxFuel;
          FuelRemain = ACmaxFuel;
        }
      } else {
        if (CHKAutoCalcFuel) {
          MEASURE.geojson.features[0].properties.FuelPlan.StartFuel = ACmaxFuel;
          FuelRemain = ACmaxFuel;
        }
      }
    } else {
      if (CHKAutoCalcFuel) FuelRemain = ACmaxFuel;
    }
  }
  MeasureUpdateDraw();
  if (MEASURE.EditExisting) {
    if (RAform != null) {
      RAform.CurLeg++;
      RAoperation();
    }
    LoadWBLeg('next');
  }
}
function PrevNavLeg(synced = false) {
  if (MEASURE.CurLeg != 0 && !synced) {
    if (RoutePlanningON) {
      SCH_DATA.LegIndex = MEASURE.CurLeg;
      ITIN_PopLeg();
      ITIN_Save_Leg();
    }
    MEASURE.CurLeg--;
    SCH_DATA.LegIndex = MEASURE.CurLeg;
    WB_LEG_INDEX = MEASURE.CurLeg;
  }
  if (MEASURE.LEGS[MEASURE.CurLeg] != undefined) {
    MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[MEASURE.CurLeg]));
    if (MEASURE.geojson.features.length > 0) {
      if (MEASURE.geojson.features[0].properties.FuelPlan != undefined)
        LoadFPsettings(MEASURE.geojson.features[0].properties.FuelPlan);
    } else {
      MEASURE.geojson = {
        type: 'FeatureCollection',
        features: [],
      };
    }
  } else {
    MEASURE.geojson = {
      type: 'FeatureCollection',
      features: [],
    };
  }
  MeasureUpdateDraw();
  if (MEASURE.EditExisting) {
    if (RAform != null) {
      RAform.CurLeg++;
      RAoperation();
    }
    LoadWBLeg('prev');
  }
}

function UpdateMeasureWindow() {
  var html = '<table class="" style="float: center;">';
  var CurLeg = parseInt(MEASURE.CurLeg) + 1;
  var disableNext = false;
  if (MEASURE.linestring.geometry.coordinates.length == 0) {
    if (MEASURE.geojson.features.length > 0) {
      var From = getNavTypeSummary(MEASURE.geojson.features[0].properties);
      if (CurLeg > 1) html += '<tr><td>Leg# ' + CurLeg + ' From:</td><td>' + From + '</td></tr></table>';
      else html += '<tr><td>Leg# ' + CurLeg + ' From:</td><td>' + From + '</td></tr></table>';
    } else {
      if (CurLeg > 1) html += '<tr><td>No Points for Leg# ' + CurLeg + '</td></tr></table>';
      else html += '<tr><td>No Points for Leg# ' + CurLeg + '</td></tr></table>';
    }
  } else {
    var Distance = getDisplayDistance(turf.lineDistance(MEASURE.linestring, { units: 'meters' }));
    var DistanceTo = Distance;
    var From = getNavTypeSummary(MEASURE.geojson.features[0].properties);
    var Dest = MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties;
    var Alt = false;
    var ToAlt = '';
    if (Dest.isAlternate == true) {
      Dest = MEASURE.geojson.features[MEASURE.geojson.features.length - 2].properties;
      Alt = MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties;
      ToAlt = getNavTypeSummary(Alt);
      MEASURE.linestring.geometry.coordinates.pop();
      DistanceTo = getDisplayDistance(turf.lineDistance(MEASURE.linestring, { units: 'meters' }));
      Distance = DistanceTo;
    }
    var To = getNavTypeSummary(Dest);

    var DestLink = 'DEST';
    var AltLink = 'ALT';
    if (Alt !== false)
      html +=
        '<tr><td>' +
        From +
        '</td><td>DEST#' +
        CurLeg +
        '<br />ALT#1</td><td>' +
        To +
        '<br />' +
        ToAlt +
        '</td><td>' +
        DistanceTo +
        '<br />' +
        Distance +
        '</td></tr>';
    else
      html +=
        '<tr><td>' + From + '</td><td>DEST#' + CurLeg + '</td><td>' + To + '</td><td>' + DistanceTo + '</td></tr>';
    html += '</table>';
  }
  var button = '';
  if (window.innerWidth < 500) {
    var acbtn =
      '<button onclick="SetupRouteAircraftSEL();"><img src="./images/airplaneNONE.svg" width="20" height="20"></button>';
    if (PLANAC != null) {
      acbtn =
        '<button onclick="SetupRouteAircraftSEL();"><img src="./images/airplaneSEL.svg" width="20" height="20"></button>';
    }
    var fuelbtn =
      '<button onclick="CalcFuelNeeded();" disabled><img src="./images/fuel.gif" width="20" height="20"></button>';
    if (PLANAC != null && CHKAutoCalcFuel) {
      fuelbtn = '<button onclick="CalcFuelNeeded();"><img src="./images/fuel.gif" width="20" height="20"></button>';
    }
    var NAV = '<center><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';

    NAV += fuelbtn;

    if (CurLeg == 1)
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="PrevNavLeg()" disabled><div class="vertical-center-container"><i class="icon-margins fal fa-arrow-left"></i>Prev Leg</div></button>';
    else
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="PrevNavLeg()" ><div class="vertical-center-container"><i class="icon-margins fal fa-arrow-left"></i>Prev Leg</div></button>';

    if (MEASURE.geojson.features.length < 2)
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="NextNavLeg()" disabled><div class="vertical-center-container">Next Leg <i class="icon-margins fal fa-arrow-right"></i></div></button>';
    else
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="NextNavLeg()" ><div class="vertical-center-container">Next Leg <i class="icon-margins fal fa-arrow-right"></i></div></button>';

    NAV += acbtn;

    NAV += '</fieldset>';

    NAV += '<fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';
    if (MEASURE.geojson.features.length < 2)
      NAV += '<button style="padding:4px; color: black;" onClick="ViewLegDetails()" disabled>WPT List</button>';
    else NAV += '<button style="padding:4px; color: black;" onClick="ViewLegDetails()">WPT List</button>';

    if (RoutePlanningON)
      NAV +=
        '<button style="padding:4px; color: black;" onClick="finishFuelPlanLeg();"><div class="vertical-center-container"><img style="height: 15px;" src="./images/Check_mark_green.svg"/>Itin Details</div></button>';
    else
      NAV +=
        '<button style="padding:4px; color: black;" onClick="finishFuelPlanLeg();" disabled><div class="vertical-center-container"><img style="height: 15px;" src="./images/Check_mark_green.svg"/>Itin Details</div></button>';

    if (MEASURE.geojson.features.length < 2)
      NAV += '<button style="padding:4px; color: black;" onClick="MakeNavAlternate()" disabled>Make ALT</button>';
    else {
      if (Alt !== false)
        NAV += '<button style="padding:4px; color: black;" onClick="RemoveNavAlternate()">Remove ALT</button>';
      else NAV += '<button style="padding:4px; color: black;" onClick="MakeNavAlternate()">Make ALT</button>';
    }
    NAV += '</fieldset></center>';
  } else {
    button =
      '<div style="float: right; cursor:pointer;" onclick="SetupRouteAircraftSEL();"><img src="./images/airplaneNONE.svg" width="40" height="40"></div>';
    if (PLANAC != null) {
      button =
        '<div style="float: right; cursor:pointer;" onclick="SetupRouteAircraftSEL();"><img src="./images/airplaneSEL.svg" width="40" height="40"></div>';
    }
    if (PLANAC != null && CHKAutoCalcFuel) {
      button +=
        '<div style="float: left; cursor:pointer;" onclick="CalcFuelNeeded();"><img src="./images/fuel.gif" width="40" height="40"></div>';
    }
    var NAV = '<center><fieldset data-role="controlgroup" data-type="horizontal" data-mini="true">';

    if (CurLeg == 1)
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="PrevNavLeg()" disabled><div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i>Prev Leg</div></button>';
    else
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="PrevNavLeg()" ><div class="vertical-center-container"><i class="icon-margins fal fa-chevron-left"></i>Prev Leg</div></button>';

    if (MEASURE.geojson.features.length < 2)
      NAV += '<button style="padding:4px; color: black;" onClick="ViewLegDetails()" disabled>WPT List</button>';
    else NAV += '<button style="padding:4px; color: black;" onClick="ViewLegDetails()">WPT List</button>';

    if (RoutePlanningON)
      NAV +=
        '<button style="padding:4px; color: black;" onClick="finishFuelPlanLeg();"><div class="vertical-center-container"><img style="height: 15px;" src="./images/Check_mark_green.svg"/>Itin Details</div></button>';
    else
      NAV +=
        '<button style="padding:4px; color: black;" onClick="finishFuelPlanLeg();" disabled><div class="vertical-center-container"><img style="height: 15px;" src="./images/Check_mark_green.svg"/>Itin Details</div></button>';

    if (MEASURE.geojson.features.length < 2)
      NAV += '<button style="padding:4px; color: black;" onClick="MakeNavAlternate()" disabled>Make ALT</button>';
    else {
      if (Alt !== false)
        NAV += '<button style="padding:4px; color: black;" onClick="RemoveNavAlternate()">Remove ALT</button>';
      else NAV += '<button style="padding:4px; color: black;" onClick="MakeNavAlternate()">Make ALT</button>';
    }

    if (MEASURE.geojson.features.length < 2)
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="NextNavLeg()" disabled><div class="vertical-center-container">Next Leg<i class="icon-margins fal fa-chevron-right"></i></div></button>';
    else
      NAV +=
        '<button style="padding:4px; color: black;" data-mini="true" onclick="NextNavLeg()" ><div class="vertical-center-container">Next Leg<i class="icon-margins fal fa-chevron-right"></i></div></button>';

    NAV += '</fieldset></center>';
  }

  if (CurPageID() == 'Map_Page') $('#coordinates').show();
  $('#coordinates').html(NAV + button + html);
  $('#coordinates').enhanceWithin();
  populateLegDetails('FuelPlanDetails', true);
}
function ViewLegDetails() {
  UpdateNavVisibility(false);
  $.mobile.changePage($('#SetupRoutePlan_Details'), {
    changeHash: false,
    reverse: false,
  });
}

function getNavTypeSummaryTXT(p) {
  var txt = '';
  if (!MAPSTATE.Measuring) return getDestSummaryTXT(p);
  if (p.NavType == 'Airport') {
    var Nav = '';
    if (GPS.Active)
      Nav =
        '<button class="directto-btn ui-btn ui-shadow ui-corner-all" onclick="FuelPlanReturnMap();navigateToMarker(\'' +
        p.ICAO +
        "'," +
        p.Lat +
        ',' +
        p.Long +
        ', \'Airport\')"> <img src="./images/DirectTo.png" width="35" height="35"></button>';
    txt += 'Airport: <a href="#" onClick="FuelPlanGotoAirport(\'' + p.ICAO + '\')" >' + p.ICAO + '</a>' + Nav;
  } else if (p.NavType == 'Navaid') {
    var Nav = '';
    if (GPS.Active)
      Nav =
        '<button class="directto-btn ui-btn ui-shadow ui-corner-all" onclick="FuelPlanReturnMap();navigateToMarker(\'' +
        p.ID +
        "'," +
        p.Lat +
        ',' +
        p.Long +
        ", '" +
        p.Type +
        '\')"> <img src="./images/DirectTo.png" width="35" height="35"></button>';
    txt += 'Navaid: <a href="#" onClick="FuelPlanGotoNavaid(\'' + p.ID + '\')" >' + p.ID + '</a>' + Nav;
  } else {
    var Nav = '';
    if (GPS.Active)
      Nav =
        '<button class="directto-btn ui-btn ui-shadow ui-corner-all" onclick="FuelPlanReturnMap();FuelPlanGotoWaypoint(' +
        p.Lat +
        ',' +
        p.Long +
        ')"> <img src="./images/DirectTo.png" width="35" height="35"></button>';
    txt +=
      'Waypoint: (' +
      ConvertDD_User(Math.round(p.Lat * 1000) / 1000, 'Lat') +
      ', ' +
      ConvertDD_User(Math.round(p.Long * 1000) / 1000, 'Long') +
      ')' +
      Nav;
  }
  return txt;
}
function getDestSummaryTXT(p) {
  var txt = '';
  if (p.NavType == 'Airport') {
    txt += p.ICAO;
  } else if (p.NavType == 'Navaid') {
    txt += p.ID + ': ' + p.type;
  } else {
    txt +=
      '(' +
      ConvertDD_User(Math.round(p.Lat * 1000) / 1000, 'Lat') +
      ', ' +
      ConvertDD_User(Math.round(p.Long * 1000) / 1000, 'Long') +
      ')';
  }
  return txt;
}
function UpdateNavVisibility(show) {
  if (show && GPS.Active && CurPageID() == 'Map_Page') {
    $('#NWInfo').show();
    $('#SWInfo').show();
    $('#NEInfo').show();
    $('#SEInfo').show();
    $('#NNInfo').show();
    if (ShowCompass == true) $('#Compass').show();
  } else {
    $('#NWInfo').hide();
    $('#SWInfo').hide();
    $('#NEInfo').hide();
    $('#SEInfo').hide();
    $('#NNInfo').hide();
    $('#Compass').hide();
    coordinates_hide();
    $('#crosshair').hide();
  }
}
function FuelPlanGotoAirport(ICAO) {
  FuelPlanReturnMap();
  if ($('#searchAirportICAO').val() != ICAO) {
    $('#searchAirportICAO').val(ICAO);
    $('#AirportTabDirectTo').click();
    searchICAO_Airport(ICAO);
  }
  coordinates_hide();
  $('#gpsMenu').tabs();
  $('#gpsMenu').show();
  $('#AirportTabDirectTo').click();
}
function FuelPlanGotoNavaid(ID) {
  FuelPlanReturnMap();
  ID.substring(0, 3);
  if ($('#searchNavaidIdent').val() != ID) {
    $('#searchNavaidIdent').val(ID);
    $('#NavaidTabDirectTo').click();
    searchNavaids(ID);
  }
  coordinates_hide();
  $('#gpsMenu').tabs();
  $('#gpsMenu').show();
  $('#NavaidTabDirectTo').click();
}
function FuelPlanGotoWaypoint(lat, long) {
  navigateToMarker('Route Plan Waypoint', lat, long, 'Device');
}
function FuelPlanReturnMap() {
  $.mobile.changePage($('#Map_Page'), {
    changeHash: false,
    reverse: true,
  });
  UpdateNavVisibility(true);
}
function populateLegDetails(divID, editon = false) {
  if (MEASURE.LEGS[MEASURE.CurLeg + 1] == undefined) {
    $('#FP_NEXT_BTN').prop('disabled', true);
  } else {
    $('#FP_NEXT_BTN').prop('disabled', false);
  }
  if (MEASURE.CurLeg != 0) {
    $('#FP_PREV_BTN').prop('disabled', false);
  } else {
    $('#FP_PREV_BTN').prop('disabled', true);
  }
  $('#FP_LegNum').html('Leg Number ' + (MEASURE.CurLeg + 1));
  if (MEASURE.geojson.features == undefined) {
    MEASURE.geojson = {
      type: 'FeatureCollection',
      features: [],
    };
  }
  if (MEASURE.geojson.features.length == 0) return;
  if (MEASURE.geojson.features[0].properties.FuelPlan == undefined)
    $('#' + divID).html(getFlightPlanDetails2(MEASURE, editon));
  else $('#' + divID).html(getFlightPlanDetails(MEASURE, editon));
}

function getFlightPlanDetails(MEASURE, edit = false) {
  if (MAPSTATE.Measuring) edit = false;
  var html = '';
  var Routes = '';
  var Segs = [];
  var HasAlt = false;
  var Leg = {
    From: '',
    To: '',
    StartFuel: 0,
    StartFuel_Actual: 0,
    BurnRate: 0,
    BurnRate_Actual: 0,
    ReserveTime: 0,
    ReserveFuel: 0,
    ReserveFuel_Actual: 0,
    UseableFuel: 0,
    UseableFuel_Actual: 0,
    FuelUsed: 0,
    FuelUsed_Actual: 0,
    TotalDistance: 0,
    ETE: 0,
    ETE_Actual: 0,
  };

  var AltLeg = {
    From: '',
    To: '',
    StartFuel: 0,
    BurnRate: 0,
    ReserveTime: 0,
    ReserveFuel: 0,
    UseableFuel: 0,
    FuelUsed: 0,
    FuelUsed_Actual: 0,
    TotalDistance: 0,
    ETE: 0,
    ETE_Actual: 0,
  };

  var CurLeg = parseInt(MEASURE.CurLeg) + 1;
  var totals = '';
  var wdt = new Date();
  var wmmDate = wdt.getFullYear() + (wdt.getMonth() + 1) / 12.0;
  if (MAPSTATE.wmm == undefined) MAPSTATE.wmm = new WorldMagneticModel();
  for (var i in MEASURE.geojson.features) {
    var F = MEASURE.geojson.features[i];
    var FP = F.properties.FuelPlan;

    var CurSeg = i;
    //Set Default Actuals if unset
    if (FP.GroundSpeed_Actual == undefined) FP.GroundSpeed_Actual = FP.GroundSpeed;
    if (FP.AirSpeed_Actual == undefined) FP.AirSpeed_Actual = FP.AirSpeed;
    if (FP.inHg == undefined) {
      FP.inHg = 29.92;
      if (F.altimeter != undefined) {
        FP.inHg = parseFloat(F.altimeter.toString().substr(0, 2) + '.' + F.altimeter.toString().substr(2, 2));
      }
    }
    if (FP.IASpeed == undefined) FP.IASpeed = IAS_TAS_Calculator(false, FP.AirSpeed, FP.ACalt, FP.inHg, FP.Temp);
    if (FP.IASpeed_Actual == undefined)
      FP.IASpeed_Actual = IAS_TAS_Calculator(false, FP.AirSpeed, FP.ACalt, FP.inHg, FP.Temp);
    if (FP.StartFuel_Actual == undefined) FP.StartFuel_Actual = FP.StartFuel;
    if (FP.UseableFuel_Actual < 0) {
      FP.UseableFuel_Actual = 0;
    }
    if (FP.FuelUsed_Actual == undefined) FP.FuelUsed_Actual = FP.FuelUsed;
    if (FP.SegmentTime_Actual == undefined) FP.SegmentTime_Actual = FP.SegmentTime;
    if (FP.UseableFuel_Actual == undefined) FP.UseableFuel_Actual = FP.UseableFuel;
    if (FP.FuelRequired_Actual == undefined) FP.FuelRequired_Actual = FP.FuelRequired;
    FP.StartFuel = parseInt(FP.StartFuel);
    if (FP.StartFuel_Actual == undefined) FP.StartFuel_Actual = FP.StartFuel;
    if (FP.ReserveAmount_Actual == undefined) FP.ReserveAmount_Actual = FP.ReserveAmount;
    if (FP.TotalLegTime_Actual == undefined) FP.TotalLegTime_Actual = FP.TotalLegTime;
    if (FP.UseableFuel_Actual == undefined) FP.UseableFuel_Actual = FP.UseableFuel;
    if (FP.ReserveAmount_Actual == undefined) FP.ReserveAmount_Actual = FP.ReserveAmount;
    if (FP.BurnRate_Actual == undefined) FP.BurnRate_Actual = FP.BurnRate;

    var coords = F.geometry.coordinates;
    if (i == 0) {
      Leg.From = getNavTypeSummaryTXT(F.properties);
      Leg.StartFuel = FP.StartFuel;
      Leg.StartFuel_Actual = FP.StartFuel_Actual;
      Leg.BurnRate = FP.BurnRate;
      Leg.BurnRate_Actual = FP.BurnRate_Actual;
      Leg.ReserveTime = FP.ReserveTime;
      Leg.ReserveFuel = FP.ReserveAmount;
      Leg.ReserveFuel_Actual = FP.ReserveAmount_Actual;
      Leg.UseableFuel = FP.UseableFuel;
      Leg.UseableFuel_Actual = FP.UseableFuel_Actual;
      continue;
    }
    var PF = MEASURE.geojson.features[parseInt(i) - 1];

    var P1 = PF.geometry.coordinates;
    var Mdev1 = MAPSTATE.wmm.declination(0, P1[1], P1[0], wmmDate);
    var P2 = F.geometry.coordinates;
    var Mdev2 = MAPSTATE.wmm.declination(0, P2[1], P2[0], wmmDate);
    var point1 = turf.point(P1);
    var point2 = turf.point(P2);
    var midpoint = turf.midpoint(point1, point2);
    var bearing = turf.bearing(point1, point2).toFixed(0);
    if (bearing < 0) bearing = 360 + parseInt(bearing);
    var Mdev = MAPSTATE.wmm.declination(0, midpoint.geometry.coordinates[1], midpoint.geometry.coordinates[1], wmmDate);

    var mheading = bearing - Mdev;
    if (mheading >= 360) mheading -= 360;
    if (mheading < 0) mheading += 360;
    mheading = Math.round(mheading);
    var distance = turf.distance(point1, point2, { units: 'meters' });
    var directToTXT = bearing + '°T - ' + getDisplayDistance(distance);
    if (MAPSTATE.HeadingMag) {
      directToTXT = mheading + '°M - ' + getDisplayDistance(distance);
    }
    var directTo = '';

    switch (F.properties.NavType) {
      case 'Airport':
        directTo =
          '<button class="directto-btn" onclick="navigateToMarker(\'' +
          F.properties.ICAO +
          "'," +
          P2[1] +
          ',' +
          P2[0] +
          ", '" +
          F.properties.NavType +
          '\');UpdateWB();"><img src="./images/DirectTo.png" width="35" height="35"></button>';
        break;
      case 'Waypoint':
        directTo =
          '<button class="directto-btn" onclick="navigateToMarker(\'Waypoint-FP-' +
          CurLeg +
          '-' +
          CurSeg +
          "'," +
          P2[1] +
          ',' +
          P2[0] +
          ", '" +
          F.properties.NavType +
          '\');UpdateWB();"><img src="./images/DirectTo.png" width="35" height="35"></button>';
        break;
      case 'Navaid':
        directTo =
          '<button class="directto-btn" onclick="navigateToMarker(\'' +
          F.properties.ID +
          "'," +
          P2[1] +
          ',' +
          P2[0] +
          ", '" +
          F.properties.Type +
          '\');UpdateWB();"><img src="./images/DirectTo.png" width="35" height="35"></button>';
        break;
    }

    var AvgWind = AverageWind_Speed_Dir(
      PF.properties.FuelPlan.Wind,
      PF.properties.FuelPlan.WindDir,
      FP.Wind,
      FP.WindDir
    );

    var Seg = {
      Index: i,
      Type: 'Intermediate',
      CurLeg: CurLeg,
      From: getNavTypeSummaryTXT(PF.properties),
      To: getNavTypeSummaryTXT(F.properties),
      StartFuel: PF.properties.FuelPlan.StartFuel,
      StartFuel_Actual: PF.properties.FuelPlan.StartFuel_Actual,
      BurnRate: PF.properties.FuelPlan.BurnRate,
      BurnRate_Actual: PF.properties.FuelPlan.BurnRate_Actual,
      UseableFuel: PF.properties.FuelPlan.UseableFuel,
      Bearing: bearing,
      directTo: directTo,
      directToTXT: directToTXT,
      Mheading: mheading,
      Distance: distance,
      ETE: FP.SegmentTime,
      ETE_Actual: FP.SegmentTime_Actual,
      isAlternate: false,
      WindStart: PF.properties.FuelPlan.Wind,
      WindStartDir: PF.properties.FuelPlan.WindDir,
      WindEnd: FP.Wind,
      WindEndDir: FP.WindDir,
      WindAvg: AvgWind.Wind_Avg,
      WindAvgDir: AvgWind.WindDir_Avg,
      AirSpeed: FP.AirSpeed,
      AirSpeed_Actual: FP.AirSpeed_Actual,
      IASpeed_Actual: FP.IASpeed_Actual,
      IASpeed: FP.IASpeed,
      inHg: FP.inHg,
      GroundSpeed: FP.GroundSpeed,
      GroundSpeed_Actual: FP.GroundSpeed_Actual,
      ACalt: PF.properties.FuelPlan.ACalt,
      Temp: FP.Temp,
      ETE_Total: FP.TotalLegTime,
      ETE_Total_Actual: FP.TotalLegTime_Actual,
      TotalDistance: FP.TotalDistance,
      FuelUsed: FP.FuelUsed,
      FuelUsed_Actual: FP.FuelUsed_Actual,
    };

    if (i > MEASURE.geojson.features.length - 3) {
      if (F.properties.isAlternate === true && i == MEASURE.geojson.features.length - 1) {
        //to Alternate
        AltLeg.From = getNavTypeSummaryTXT(PF.properties);
        AltLeg.To = getNavTypeSummaryTXT(F.properties);
        AltLeg.TotalDistance = FP.TotalDistance;
        AltLeg.ETE = FP.TotalLegTime;
        AltLeg.ETE_Actual = FP.TotalLegTime_Actual;
        AltLeg.FuelUsed = Math.round(FP.FuelRequired - PF.properties.FuelPlan.ReserveAmount);
        AltLeg.FuelUsed_Actual = Math.round(FP.FuelRequired_Actual - PF.properties.FuelPlan.ReserveAmount_Actual);
        Seg.Type = 'Alternate';
        Routes += GetSegmentHtml(Seg, edit);
        HasAlt = true;
      } else {
        if (i == MEASURE.geojson.features.length - 1) {
          //To Destination
          Leg.To = getNavTypeSummaryTXT(F.properties);
          Leg.ETE = FP.TotalLegTime;
          Leg.ETE_Actual = FP.TotalLegTime_Actual;
          Leg.TotalDistance = FP.TotalDistance;
          Leg.FuelUsed = Math.round(FP.FuelRequired - PF.properties.FuelPlan.ReserveAmount);
          Leg.FuelUsed_Actual = Math.round(FP.FuelRequired_Actual - PF.properties.FuelPlan.ReserveAmount_Actual);
          Seg.Type = 'Destination';
          Routes += GetSegmentHtml(Seg, edit);
        } else {
          if (
            i == MEASURE.geojson.features.length - 2 &&
            MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties.isAlternate == true
          ) {
            //to Destination
            Leg.To = getNavTypeSummaryTXT(F.properties);
            Leg.ETE = FP.TotalLegTime;
            Leg.ETE_Actual = FP.TotalLegTime_Actual;
            Leg.TotalDistance = FP.TotalDistance;
            Leg.FuelUsed = Math.round(FP.FuelRequired - PF.properties.FuelPlan.ReserveAmount);
            Leg.FuelUsed_Actual = Math.round(FP.FuelRequired_Actual - PF.properties.FuelPlan.ReserveAmount_Actual);
            Seg.Type = 'Destination';
            Routes += GetSegmentHtml(Seg, edit);
          } else {
            //to intermediate point
            Seg.Type = 'Intermediate';
            Routes += GetSegmentHtml(Seg, edit);
          }
        }
      }
    } else {
      //to intermediate point
      Seg.Type = 'Intermediate';
      Routes += GetSegmentHtml(Seg, edit);
    }
    Segs.push(Seg);
  }

  var LegHTML =
    '<table class="limit-table"><thead><tr><th>Leg</th><th>From</th><th>To</th><th>Dist</th><th>Type</th><th>ETE</th><th>Fuel_Start</th><th>Fuel_Used<th>Fuel_Left</th><th>Fuel_Reserve</th></tr></thead><tbody>';
  var ReserveClass = '';
  if (
    Math.round(Leg.StartFuel - Leg.FuelUsed) < Leg.ReserveFuel ||
    Math.round(Leg.StartFuel_Actual - Leg.FuelUsed_Actual) < Leg.ReserveFuel
  )
    ReserveClass = 'redbg';
  if (HasAlt) {
    if (
      Math.round(Leg.StartFuel - AltLeg.FuelUsed) < Leg.ReserveFuel ||
      Math.round(Leg.StartFuel_Actual - AltLeg.FuelUsed_Actual) < Leg.ReserveFuel
    )
      ReserveClass = 'redbg';
    LegHTML +=
      '<tr><td>' +
      (parseInt(MEASURE.CurLeg) + 1) +
      '</td><td>' +
      Leg.From +
      '</td><td>' +
      Leg.To +
      ' <span class="yellowbg">[' +
      AltLeg.To +
      ']</span></td><td>' +
      getDisplayDistance(Leg.TotalDistance) +
      ' <span class="yellowbg">[' +
      getDisplayDistance(AltLeg.TotalDistance) +
      ']</span></td><td>Planned<br /><b>Actual</b></td><td>' +
      secondsToHms(Leg.ETE, true) +
      ' <span class="yellowbg">[' +
      secondsToHms(AltLeg.ETE, true) +
      ']</span><br /><b>' +
      secondsToHms(Leg.ETE_Actual, true) +
      '</b> <span class="yellowbg">[<b>' +
      secondsToHms(AltLeg.ETE_Actual, true) +
      '</b>]</span></td><td>' +
      ShowFuelDisplayUnits(Leg.StartFuel) +
      '<br /><b>' +
      ShowFuelDisplayUnits(Leg.StartFuel_Actual) +
      '</b></td><td>' +
      ShowFuelDisplayUnits(Math.round(Leg.FuelUsed)) +
      ' <span class="yellowbg">[' +
      ShowFuelDisplayUnits(Math.round(AltLeg.FuelUsed)) +
      ']</span><br /><b>' +
      ShowFuelDisplayUnits(Math.round(Leg.FuelUsed_Actual)) +
      '</b> <span class="yellowbg">[<b>' +
      ShowFuelDisplayUnits(Math.round(AltLeg.FuelUsed_Actual)) +
      '</b>]</span></td><td>' +
      ShowFuelDisplayUnits(Math.round(Leg.StartFuel - Leg.FuelUsed)) +
      ' <span class="yellowbg">[' +
      ShowFuelDisplayUnits(Math.round(Leg.StartFuel - AltLeg.FuelUsed)) +
      ']</span><br /><b>' +
      ShowFuelDisplayUnits(Math.round(Leg.StartFuel_Actual - Leg.FuelUsed_Actual)) +
      '</b> <span class="yellowbg">[<b>' +
      ShowFuelDisplayUnits(Math.round(Leg.StartFuel_Actual - AltLeg.FuelUsed_Actual)) +
      '</b>]</span></td><td class="' +
      ReserveClass +
      '">' +
      ShowFuelDisplayUnits(Leg.ReserveFuel) +
      ' ( ' +
      Leg.ReserveTime +
      'min)</td></tr>';
  } else {
    LegHTML +=
      '<tr><td>' +
      (parseInt(MEASURE.CurLeg) + 1) +
      '</td><td>' +
      Leg.From +
      '</td><td>' +
      Leg.To +
      '</td><td>' +
      getDisplayDistance(Leg.TotalDistance) +
      '</td><td>Planned<br /><b>Actual</b></td><td>' +
      secondsToHms(Leg.ETE, true) +
      '<br /><b>' +
      secondsToHms(Leg.ETE_Actual, true) +
      '</b></td><td>' +
      ShowFuelDisplayUnits(Leg.StartFuel) +
      '<br /><b>' +
      ShowFuelDisplayUnits(Leg.StartFuel_Actual) +
      '</b></td><td>' +
      ShowFuelDisplayUnits(Math.round(Leg.FuelUsed)) +
      '<br /><b>' +
      ShowFuelDisplayUnits(Math.round(Leg.FuelUsed_Actual)) +
      '</b></td><td>' +
      ShowFuelDisplayUnits(Math.round(Leg.StartFuel - Leg.FuelUsed)) +
      '<br /><b>' +
      ShowFuelDisplayUnits(Math.round(Leg.StartFuel_Actual - Leg.FuelUsed_Actual)) +
      '</b></span></td><td>' +
      ShowFuelDisplayUnits(Leg.ReserveFuel) +
      ' ( ' +
      Leg.ReserveTime +
      'min)</td></tr>';
  }

  LegHTML += '</tbody></table>';

  var Route =
    '<table class="limit-table"><thead><tr><th>Seg</th><th>To</th><th>Wind</th><th>Alt/Tmp/Hg</th><th>Dist (Total)</th><th>Type</th><th>ETE (Total)</th><th>IAS</th><th>TAS</th><th>GS</th><th>Burn</th><th>Fuel_Start</th><th>Fuel_Used</th><th>Fuel_Left</th></tr></thead><tbody>' +
    Routes +
    '</tbody></table>';

  html += LegHTML + Route;
  return html;
  //$("#FuelPlanDetails").html(html);
  //$("#FollowingFuelPlanDetails").html(html);
}
function GetSegmentHtml(Seg, edit = false) {
  var FuelUnits = 'lbs';
  if (LOCALSTORAGE.DisplayUnitsIndex == 3) {
    FuelUnits = 'kgs';
  }
  var To = '';
  var lineClass = '';
  if (Seg.Type == 'Destination') To = '<b>(Destination)</b><br />';
  if (Seg.Type == 'Alternate') {
    To = '<b>(Alternate)</b><br />';
    lineClass = 'yellowbg';
  }
  if (edit) {
    var actualIAS =
      '<input type="number" data-role="none" style="width: 40px; font-weight:bold; background: #d1f2ff" id="OFP_IASpeed_Actual_Seg-' +
      Seg.CurLeg +
      '-' +
      Seg.Index +
      '" value="' +
      Seg.IASpeed_Actual +
      '" onChange="UpdateFlightPlanData(this,' +
      Seg.Index +
      ',\'IASpeed_Actual\')" onClick="this.select();this.setSelectionRange(0, this.value.length)"/><b>kt</b>';

    var actualTAS =
      '<input type="number" data-role="none" style="width: 40px; font-weight:bold; background: #d1f2ff" id="OFP_AirSpeed_Actual_Seg-' +
      Seg.CurLeg +
      '-' +
      Seg.Index +
      '" value="' +
      Seg.AirSpeed_Actual +
      '" onChange="UpdateFlightPlanData(this,' +
      Seg.Index +
      ',\'AirSpeed_Actual\')" onClick="this.select();this.setSelectionRange(0, this.value.length)"/><b>kt</b>';

    var actualGS =
      '<input type="number" data-role="none" style="width: 40px; font-weight:bold; background: #d1f2ff" id="OFP_GroundSpeed_Actual_Seg-' +
      Seg.CurLeg +
      '-' +
      Seg.Index +
      '" value="' +
      Seg.GroundSpeed_Actual +
      '" onChange="UpdateFlightPlanData(this,' +
      Seg.Index +
      ',\'GroundSpeed_Actual\')" onClick="this.select();this.setSelectionRange(0, this.value.length)"/><b>kt</b>';

    var actualBurnRate =
      '<input type="number" data-role="none" style="width: 50px; font-weight:bold; background: #d1f2ff" id="OFP_BurnRate_Actual_Seg-' +
      Seg.CurLeg +
      '-' +
      Seg.Index +
      '" value="' +
      Seg.BurnRate_Actual +
      '" onChange="UpdateFlightPlanData(this,' +
      Seg.Index +
      ',\'BurnRate_Actual\')" onClick="this.select();this.setSelectionRange(0, this.value.length)"/><b>' +
      FuelUnits +
      '/hr</b>';

    var actualStartFuel =
      '<input type="number" data-role="none" style="width: 50px; font-weight:bold; background: #d1f2ff" id="OFP_StartFuel_Actual-' +
      Seg.CurLeg +
      '-' +
      Seg.Index +
      '" value="' +
      Seg.StartFuel_Actual +
      '" onChange="UpdateFlightPlanData(this,' +
      Seg.Index +
      ',\'StartFuel_Actual\')" onClick="this.select();this.setSelectionRange(0, this.value.length)"/><b>' +
      FuelUnits +
      '</b>';
    if (Seg.Index > 1) actualStartFuel = '<b>' + ShowFuelDisplayUnits(Seg.StartFuel_Actual) + '</b>';

    var actualFuelLeft =
      '<input type="number" data-role="none" style="width: 50px; font-weight:bold; background: #d1f2ff" id="OFP_FuelLeft_Actual-' +
      Seg.CurLeg +
      '-' +
      Seg.Index +
      '" value="' +
      Math.round(Seg.StartFuel_Actual - Seg.FuelUsed_Actual) +
      '" onChange="UpdateFlightPlanData(this,' +
      Seg.Index +
      ',\'FuelLeft_Actual\')" onClick="this.select();this.setSelectionRange(0, this.value.length)"/><b>' +
      FuelUnits +
      '</b>';
  } else {
    var actualTAS = '<b>' + getDisplaySpeed(Seg.IASpeed_Actual * 0.514444, true) + '</b>';

    var actualTAS = '<b>' + getDisplaySpeed(Seg.AirSpeed_Actual * 0.514444, true) + '</b>';

    var actualGS = '<b>' + getDisplaySpeed(Seg.GroundSpeed_Actual * 0.514444, true) + '</b>';

    var actualBurnRate = '<b>' + ShowFuelDisplayUnits(Seg.BurnRate_Actual) + '/hr</b>';

    var actualStartFuel = '<b>' + ShowFuelDisplayUnits(Seg.StartFuel_Actual) + '</b>';

    var actualFuelLeft = '<b>' + ShowFuelDisplayUnits(Math.round(Seg.StartFuel_Actual - Seg.FuelUsed_Actual)) + '</b>';
  }

  var wind = 'Org: ' + Seg.WindStartDir + ' @ ' + getDisplaySpeed(Seg.WindStart, true) + '<br />';
  wind += 'Avg: ' + Seg.WindAvgDir + ' @ ' + getDisplaySpeed(Seg.WindAvg, true) + '<br />';
  wind += 'Dst: ' + Seg.WindEndDir + ' @ ' + getDisplaySpeed(Seg.WindEnd, true);

  var directTo = '';
  if (RETURN_MAP) directTo = ' ' + Seg.directTo;

  var html =
    '<tr class="' +
    lineClass +
    '"><td><div class="vertical-center-container">' +
    Seg.CurLeg +
    '-' +
    Seg.Index +
    directTo +
    '</div></td><td>' +
    To +
    Seg.To +
    '<br />' +
    Seg.directToTXT +
    '</td><td>' +
    wind +
    '</td><td style="text-align:right;">' +
    Seg.ACalt +
    'ft<br />' +
    Seg.Temp +
    '°C<br />' +
    Seg.inHg +
    ' inHg</td><td>' +
    getDisplayDistance(Seg.Distance) +
    ' (' +
    getDisplayDistance(Seg.TotalDistance) +
    ')</td><td>Planned<br /><b>Actual</b></td><td>' +
    secondsToHms(Seg.ETE, true) +
    ' (' +
    secondsToHms(Seg.ETE_Total, true) +
    ')<br /><b>' +
    secondsToHms(Seg.ETE_Actual, true) +
    ' (<b>' +
    secondsToHms(Seg.ETE_Total_Actual, true) +
    '</b>)</td><td>' +
    getDisplaySpeed(Seg.IASpeed * 0.514444, true) +
    '<br />' +
    actualIAS +
    '</td><td>' +
    getDisplaySpeed(Seg.AirSpeed * 0.514444, true) +
    '<br />' +
    actualTAS +
    '</td><td>' +
    getDisplaySpeed(Seg.GroundSpeed * 0.514444, true) +
    '<br />' +
    actualGS +
    '</td><td>' +
    ShowFuelDisplayUnits(Seg.BurnRate) +
    '/hr<br />' +
    actualBurnRate +
    '</td><td>' +
    ShowFuelDisplayUnits(Seg.StartFuel) +
    '<br />' +
    actualStartFuel +
    '</td><td>' +
    ShowFuelDisplayUnits(Seg.FuelUsed) +
    '<br /><b>' +
    ShowFuelDisplayUnits(Seg.FuelUsed_Actual) +
    '</b></td><td>' +
    ShowFuelDisplayUnits(Math.round(Seg.StartFuel - Seg.FuelUsed)) +
    '<br />' +
    actualFuelLeft +
    '</td></tr>';
  return html;
}
function UpdateFlightPlanData(e, segment, field) {
  var F = MEASURE.geojson.features[parseInt(segment) - 1].properties.FuelPlan;
  if (parseInt(e.value) >= 0) {
    switch (field) {
      case 'StartFuel_Actual':
        F.StartFuel_Actual = parseInt(e.value);
        if (F.StartFuel_Actual > maxFuel) F.StartFuel_Actual = maxFuel;
        F.ReserveAmount_Actual = Math.round((F.ReserveTime / 60) * F.BurnRate_Actual);
        F.UseableFuel_Actual = Math.round(F.StartFuel_Actual - F.ReserveAmount_Actual);
        //F = MEASURE.geojson.features[parseInt(segment)].properties.FuelPlan;
        //FinishFuelPlan(F, false, true, parseInt(segment)-1);
        break;
      case 'BurnRate_Actual':
        F.BurnRate_Actual = parseInt(e.value);
        F.ReserveAmount_Actual = Math.round((F.ReserveTime / 60) * F.BurnRate_Actual);
        F.UseableFuel_Actual = Math.round(F.StartFuel_Actual - F.ReserveAmount_Actual);
        //F = MEASURE.geojson.features[parseInt(segment)].properties.FuelPlan;
        //FinishFuelPlan(F, false, true, parseInt(segment));
        break;
      case 'GroundSpeed_Actual':
        F = MEASURE.geojson.features[parseInt(segment)].properties.FuelPlan;
        F.GroundSpeed_Actual = parseInt(e.value);
        //FinishFuelPlan(F, false, true, parseInt(segment));
        break;
      case 'AirSpeed_Actual':
        F = MEASURE.geojson.features[parseInt(segment)].properties.FuelPlan;
        F.AirSpeed_Actual = parseInt(e.value);
        F.IASpeed_Actual = IAS_TAS_Calculator(false, F.AirSpeed_Actual, F.ACalt, F.inHg, F.Temp);
        //FinishFuelPlan(F, false, true, parseInt(segment));
        break;
      case 'IASpeed_Actual':
        F = MEASURE.geojson.features[parseInt(segment)].properties.FuelPlan;
        F.IASpeed_Actual = parseInt(e.value);
        F.IASpeed_Actual = IAS_TAS_Calculator(true, F.IASpeed_Actual, F.ACalt, F.inHg, F.Temp);
        //FinishFuelPlan(F, false, true, parseInt(segment));
        break;
      case 'FuelLeft_Actual':
        F = MEASURE.geojson.features[parseInt(segment)].properties.FuelPlan;
        var FuelUsed =
          MEASURE.geojson.features[parseInt(segment) - 1].properties.FuelPlan.StartFuel_Actual - parseInt(e.value);
        if (parseInt(e.value) > MEASURE.geojson.features[parseInt(segment) - 1].properties.FuelPlan.StartFuel_Actual)
          FuelUsed = 0;

        MEASURE.geojson.features[parseInt(segment) - 1].properties.FuelPlan.BurnRate_Actual =
          (FuelUsed / (F.SegmentTime_Actual / 60)) * 60;
        break;
    }
  }
  UpdateFuelPlan_Actual(parseInt(segment));

  $('#WB_OFP_LegHLDR').html(getFlightPlanDetails(MEASURE, true));
  $('#WB_OFP_LegHLDR').enhanceWithin();
}
function getFlightPlanDetails2(MEASURE, edit = false) {
  var html = '';
  var totals = '';
  var wdt = new Date();
  var wmmDate = wdt.getFullYear() + (wdt.getMonth() + 1) / 12.0;
  if (MAPSTATE.wmm == undefined) MAPSTATE.wmm = new WorldMagneticModel();
  for (var i in MEASURE.geojson.features) {
    var F = MEASURE.geojson.features[i];

    var coords = F.geometry.coordinates;
    if (i == 0) {
      continue;
    }
    var PF = MEASURE.geojson.features[parseInt(i) - 1];
    var P1 = PF.geometry.coordinates;
    var Mdev1 = MAPSTATE.wmm.declination(0, P1[1], P1[0], wmmDate);
    var P2 = F.geometry.coordinates;
    var Mdev2 = MAPSTATE.wmm.declination(0, P2[1], P2[0], wmmDate);
    var point1 = turf.point(P1);
    var point2 = turf.point(P2);
    var midpoint = turf.midpoint(point1, point2);
    var bearing = turf.bearing(point1, point2).toFixed(0);
    if (bearing < 0) bearing = 360 + parseInt(bearing);
    var Mdev = MAPSTATE.wmm.declination(0, midpoint.geometry.coordinates[1], midpoint.geometry.coordinates[1], wmmDate);

    var mheading = bearing - Mdev;
    if (mheading >= 360) mheading -= 360;
    if (mheading < 0) mheading += 360;
    mheading = Math.round(mheading);
    var distance = getDisplayDistance(turf.distance(point1, point2, { units: 'meters' }));
    var directTo = bearing + '°T - ' + distance;
    if (MAPSTATE.HeadingMag) {
      directTo = mheading + '°M - ' + distance;
    }

    if (i > MEASURE.geojson.features.length - 3) {
      if (F.properties.isAlternate === true && i == MEASURE.geojson.features.length - 1) {
        html += '<h3 class="yellowbg">Leg# ' + (MEASURE.CurLeg + 1) + ' Segment# ' + i + '</h3>';
        html +=
          '<b>From: </b>' +
          getNavTypeSummaryTXT(PF.properties) +
          '<br /><b>Bearing: </b>' +
          directTo +
          "<br /><b class='yellowbg'>Alt: </b>" +
          getNavTypeSummaryTXT(F.properties) +
          '<br />';
      } else {
        if (i == MEASURE.geojson.features.length - 1) {
          html += '<h3>Leg# ' + (MEASURE.CurLeg + 1) + ' Segment# ' + i + '</h3>';
          html +=
            '<b>From: </b>' +
            getNavTypeSummaryTXT(PF.properties) +
            '<br /><b>Bearing: </b>' +
            directTo +
            '<br /><b>Dest: </b>' +
            getNavTypeSummaryTXT(F.properties) +
            '<br />';
        } else {
          if (
            i == MEASURE.geojson.features.length - 2 &&
            MEASURE.geojson.features[MEASURE.geojson.features.length - 1].properties.isAlternate == true
          ) {
            html += '<h3>Leg# ' + (MEASURE.CurLeg + 1) + ' Segment# ' + i + '</h3>';
            html +=
              '<b>From: </b>' +
              getNavTypeSummaryTXT(PF.properties) +
              '<br /><b>Bearing: </b>' +
              directTo +
              '<br /><b>Dest: </b>' +
              getNavTypeSummaryTXT(F.properties) +
              '<br />';
          } else {
            html += '<h3>Leg# ' + (MEASURE.CurLeg + 1) + ' Segment# ' + i + '</h3>';
            html +=
              '<b>From: </b>' +
              getNavTypeSummaryTXT(PF.properties) +
              '<br /><b>Bearing: </b>' +
              directTo +
              '<br /><b>To: </b>' +
              getNavTypeSummaryTXT(F.properties) +
              '<br />';
          }
        }
      }
    } else {
      html += '<h3>Leg# ' + (MEASURE.CurLeg + 1) + ' Segment# ' + i + '</h3>';
      html +=
        '<b>From: </b>' +
        getNavTypeSummaryTXT(PF.properties) +
        '<br /><b>Bearing: </b>' +
        directTo +
        '<br /><b>To: </b>' +
        getNavTypeSummaryTXT(F.properties) +
        '<br />';
    }

    var FP = F.properties.FuelPlan;
    if (FP != undefined && PF.properties.FuelPlan != undefined) {
      var PFwind = Math.round(PF.properties.FuelPlan.Wind);
      var PFwindDir = Math.round(PF.properties.FuelPlan.WindDir) + '°T';
      if (MAPSTATE.HeadingMag == 1) PFwindDir = GetMagDir(PF.properties.FuelPlan.WindDir, Mdev1) + '°M';

      var FPwind = Math.round(FP.Wind);
      var FPwindDir = FP.WindDir + '°T';
      if (MAPSTATE.HeadingMag == 1) FPwindDir = GetMagDir(FP.WindDir, Mdev2) + '°M';

      var AverageWind = Math.round((PF.properties.FuelPlan.Wind + FP.Wind) / 2); // in mps
      var AverageDir = Math.round((PF.properties.FuelPlan.WindDir + FP.WindDir) / 2) + '°T';
      if (MAPSTATE.HeadingMag == 1)
        AverageDir = GetMagDir((PF.properties.FuelPlan.WindDir + FP.WindDir) / 2, Mdev) + '°M';
      html += '<table class="limit-table" border=1><tr><td>';
      html += '<br /><table style="margin-left:0;">';
      if (edit) {
        //if (PF.properties.FuelPlan.GroundSpeed_Actual == undefined) PF.properties.FuelPlan.GroundSpeed_Actual = PF.properties.FuelPlan.GroundSpeed;
        PF.properties.FuelPlan.StartFuel = parseInt(PF.properties.FuelPlan.StartFuel);
        if (PF.properties.FuelPlan.StartFuel_Actual == undefined)
          PF.properties.FuelPlan.StartFuel_Actual = PF.properties.FuelPlan.StartFuel;
        var actualStartFuel =
          '<input type="number" id="OFP_StartFuel_Actual-' +
          i +
          '" value="' +
          PF.properties.FuelPlan.StartFuel_Actual +
          '" onChange="UpdateFlightPlanData(this,' +
          i +
          ",'StartFuel_Actual')\"/>";

        if (PF.properties.FuelPlan.BurnRate_Actual == undefined)
          PF.properties.FuelPlan.BurnRate_Actual = PF.properties.FuelPlan.BurnRate;
        var actualBurnRate =
          '<input type="number" id="OFP_BurnRate_Actual_Seg-' +
          i +
          '" value="' +
          PF.properties.FuelPlan.BurnRate_Actual +
          '" onChange="UpdateFlightPlanData(this,' +
          i +
          ",'BurnRate_Actual')\"/>";

        if (FP.AirSpeed_Actual == undefined) FP.AirSpeed_Actual = FP.AirSpeed;
        var actualAirSpeed =
          '<input type="number" id="OFP_BurnRate_AirSpeed_Seg-' +
          i +
          '" value="' +
          FP.AirSpeed_Actual +
          '" onChange="UpdateFlightPlanData(this,' +
          i +
          ",'AirSpeed_Actual')\"/>";

        html += '<thead><tr><th>Details</th><th>Planned</th><th>Actual</th></tr></thead><tbody>';
        html +=
          '<tr><td><b>Start Fuel</b></td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.StartFuel) +
          '</td><td>' +
          actualStartFuel +
          '</td></tr>';
        html +=
          '<tr><td><b>Fuel Burn Rate</b></td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.BurnRate) +
          '/hr</td><td>' +
          actualBurnRate +
          '</td></tr>';
        html +=
          '<tr><td><b>Reserve Time</b></td><td>' +
          PF.properties.FuelPlan.ReserveTime +
          'min</td><td>' +
          PF.properties.FuelPlan.ReserveTime +
          'min</td></tr>';

        if (PF.properties.FuelPlan.ReserveAmount_Actual == undefined)
          PF.properties.FuelPlan.ReserveAmount_Actual = PF.properties.FuelPlan.ReserveAmount;
        html +=
          '<tr><td><b>Reserve Fuel</b></td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.ReserveAmount) +
          '</td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.ReserveAmount_Actual) +
          '</td></tr>';

        if (PF.properties.FuelPlan.UseableFuel_Actual == undefined)
          PF.properties.FuelPlan.UseableFuel_Actual = PF.properties.FuelPlan.UseableFuel;
        html +=
          '<tr><td><b>Useable Fuel</b></td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.UseableFuel) +
          '</td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.UseableFuel_Actual) +
          '</td></tr>';

        html +=
          '<tr><td><b>Distance</b></td><td>' +
          getDisplayDistance(FP.Distance) +
          '</td><td>' +
          getDisplayDistance(FP.Distance) +
          '</td></tr>';
        html +=
          '<tr><td><b>Air Speed</b></td><td>' +
          getDisplaySpeed(FP.AirSpeed * 0.514444, true) +
          '</td><td>' +
          actualAirSpeed +
          '</td></tr>';
        html += '<tr><td><b>Planned Alt</b></td><td>' + FP.ACalt + 'ft</td><td>' + FP.ACalt + 'ft</td></tr>';
      } else {
        html += '<thead><tr><th>Details</th><th></th></tr></thead><tbody>';
        html +=
          '<tr><td><b>Start Fuel</b></td><td>' + ShowFuelDisplayUnits(PF.properties.FuelPlan.StartFuel) + '</td></tr>';
        html += '<tr><td><b>Reserve Time</b></td><td>' + PF.properties.FuelPlan.ReserveTime + 'min</td></tr>';

        html +=
          '<tr><td><b>Reserve Fuel</b></td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.ReserveAmount) +
          '</td></tr>';
        html +=
          '<tr><td><b>Useable Fuel</b></td><td>' +
          ShowFuelDisplayUnits(PF.properties.FuelPlan.UseableFuel) +
          '</td></tr>';
        html += '<tr><td><b>Distance</b></td><td>' + getDisplayDistance(FP.Distance) + '</td></tr>';
        html += '<tr><td><b>Air Speed</b></td><td>' + getDisplaySpeed(FP.AirSpeed * 0.514444, true) + '</td></tr>';
        html += '<tr><td><b>Planned Alt</b></td><td>' + FP.ACalt + 'ft</td></tr>';
      }

      html += '</tbody></table><br />';

      html += '<table style="margin-left:0;">';
      if (edit) {
        if (FP.GroundSpeed_Actual == undefined) {
          FP.GroundSpeed_Actual = FP.GroundSpeed;
        }

        var actualGS =
          '<input type="number" id="OFP_GroundSpeed_Actual_Seg-' +
          i +
          '" value="' +
          FP.GroundSpeed_Actual +
          '" onChange="UpdateFlightPlanData(this,' +
          i +
          ",'GroundSpeed_Actual')\"/>";

        html += '<thead><tr><th>Wind and Ground Speed</th><th>Planned</th><th>Actual</th></tr></thead><tbody>';
        html +=
          '<tr><td><b>Start Wind/Dir</b></td><td>' +
          PFwindDir +
          ' @ ' +
          getDisplaySpeed(PFwind, true) +
          '</td><td>Unknown</td></tr>';
        html +=
          '<tr><td><b>Average Wind/Dir</b></td><td>' +
          AverageDir +
          ' @ ' +
          getDisplaySpeed(AverageWind, true) +
          '</td><td>Unknown</td></tr>';
        html +=
          '<tr><td><b>Dest Wind/Dir</b></td><td>' +
          FPwindDir +
          ' @ ' +
          getDisplaySpeed(FPwind, true) +
          '</td><td>Unknown</td></tr>';
        html +=
          '<tr><td><b>Ground Speed</b></td><td>' +
          getDisplaySpeed(FP.GroundSpeed * 0.514444, true) +
          '</td><td>' +
          actualGS +
          '</td></tr>';
      } else {
        html += '<thead><tr><th>Wind and Ground Speed</th><th></th></tr></thead><tbody>';
        html +=
          '<tr><td><b>Start Wind/Dir</b></td><td>' + PFwindDir + ' @ ' + getDisplaySpeed(PFwind, true) + '</td></tr>';
        html +=
          '<tr><td><b>Average Wind/Dir</b></td><td>' +
          AverageDir +
          ' @ ' +
          getDisplaySpeed(AverageWind, true) +
          '</td></tr>';
        html +=
          '<tr><td><b>Dest Wind/Dir</b></td><td>' + FPwindDir + ' @ ' + getDisplaySpeed(FPwind, true) + '</td></tr>';
        html +=
          '<tr><td><b>Ground Speed</b></td><td>' + getDisplaySpeed(FP.GroundSpeed * 0.514444, true) + '</td></tr>';
      }
      html += '</tbody></table><br />';

      html += '<table style="margin-left:0;">';

      var NoFuelReserve = '';
      var NoFuel = '';
      if (edit) {
        if (FP.StartFuel_Actual == undefined) FP.StartFuel_Actual = FP.StartFuel;
        if (FP.UseableFuel_Actual < 0) {
          NoFuelReserve = 'yellowbg';
          FP.UseableFuel_Actual = 0;
        }
        if (FP.StartFuel_Actual < 0) {
          NoFuel = 'redbg';
        }
        var actualStartFuel =
          '<input type="number" id="OFP_StartFuel_Actual_Seg-' +
          i +
          '" value="' +
          FP.StartFuel_Actual +
          '" onChange="UpdateFlightPlanData(this,' +
          i +
          ",'StartFuel_Actual')\"/>";

        if (FP.FuelUsed_Actual == undefined) FP.FuelUsed_Actual = FP.FuelUsed;
        html += '<thead><tr><th>Fuel Info</th><th>Planned</th><th>Actual</th></tr></thead><tbody>';
        html +=
          '<tr><td><b>Segment Fuel Required</b></td><td>' +
          ShowFuelDisplayUnits(FP.FuelUsed) +
          '</td><td>' +
          ShowFuelDisplayUnits(FP.FuelUsed_Actual) +
          '</td></tr>';

        if (FP.SegmentTime_Actual == undefined) FP.SegmentTime_Actual = FP.SegmentTime;
        html +=
          '<tr><td><b>Segment Travel Time</b></td><td>' +
          secondsToHms(FP.SegmentTime) +
          '</td><td>' +
          secondsToHms(FP.SegmentTime_Actual) +
          '</td></tr>';

        html +=
          '<tr class="' +
          NoFuel +
          '"><td><b>Fuel Remain</b></td><td>' +
          ShowFuelDisplayUnits(FP.StartFuel) +
          '</td><td>' +
          ShowFuelDisplayUnits(FP.StartFuel_Actual) +
          '</td></tr>';

        if (FP.UseableFuel_Actual == undefined) FP.UseableFuel_Actual = FP.UseableFuel;
        html +=
          '<tr class="' +
          NoFuelReserve +
          '"><td><b>Useable Fuel Remain</b></td><td>' +
          ShowFuelDisplayUnits(FP.UseableFuel) +
          '</td><td>' +
          ShowFuelDisplayUnits(FP.UseableFuel_Actual) +
          '</td></tr>';
      } else {
        html += '<thead><tr><th>Fuel Info</th><th></th></tr></thead><tbody>';
        html += '<tr><td><b>Segment Fuel Required</b></td><td>' + ShowFuelDisplayUnits(FP.FuelUsed) + '</td></tr>';
        html += '<tr><td><b>Segment Travel Time</b></td><td>' + secondsToHms(FP.SegmentTime) + '</td></tr>';
        html += '<tr><td><b>Fuel Remain</b></td><td>' + ShowFuelDisplayUnits(FP.StartFuel) + '</td></tr>';
        html +=
          '<tr class="' +
          NoFuel +
          '"><td><b>Useable Fuel Remain</b></td><td>' +
          ShowFuelDisplayUnits(FP.UseableFuel) +
          '</td></tr>';
      }
      html += '</tbody></table><br />';

      html += '<table style="margin-left:0;">';
      if (edit) {
        html += '<thead><tr><th>Summary</th><th>Planned</th><th>Actual</th></tr></thead><tbody>';

        if (PF.properties.FuelPlan.TotalLegTime_Actual == undefined) PF.properties.FuelPlan.TotalLegTime_Actual = 0;
        if (FP.TotalLegTime_Actual == undefined) FP.TotalLegTime_Actual = FP.TotalLegTime;
        html +=
          '<tr><td><b>Total Travel Time</b></td><td><b>' +
          secondsToHms(FP.TotalLegTime) +
          '</b></td><td><b>' +
          secondsToHms(FP.TotalLegTime_Actual) +
          '</b></td></tr>';

        if (FP.FuelRequired_Actual == undefined) FP.FuelRequired_Actual = FP.FuelRequired;
        if (PF.properties.FuelPlan.ReserveAmount_Actual == undefined)
          PF.properties.FuelPlan.ReserveAmount_Actual = PF.properties.FuelPlan.ReserveAmount;
        html +=
          '<tr><td><b>Total Fuel Used</b></td><td><b>' +
          ShowFuelDisplayUnits(Math.round(FP.FuelRequired - PF.properties.FuelPlan.ReserveAmount)) +
          '</b></td><td><b>' +
          ShowFuelDisplayUnits(Math.round(FP.FuelRequired_Actual - PF.properties.FuelPlan.ReserveAmount_Actual)) +
          '</b></td></tr>';

        html +=
          '<tr><td><b>Total Leg Distance</b></td><td><b>' +
          getDisplayDistance(FP.TotalDistance) +
          '</b></td><td><b>' +
          getDisplayDistance(FP.TotalDistance) +
          '</b</td></tr>';
      } else {
        html += '<thead><tr><th>Summary</th><th></th></tr></thead><tbody>';
        html += '<tr><td><b>Total Travel Time</b></td><td><b>' + secondsToHms(FP.TotalLegTime) + '</b></td></tr>';
        html +=
          '<tr><td><b>Total Fuel Used</b></td><td><b>' +
          ShowFuelDisplayUnits(Math.round(FP.FuelRequired - PF.properties.FuelPlan.ReserveAmount)) +
          '</b></td></tr>';
        html +=
          '<tr><td><b>Total Leg Distance</b></td><td><b>' + getDisplayDistance(FP.TotalDistance) + '</b></td></tr>';
      }

      html += '</tbody></table></td></tr></table><br />';
    }
  }
  return html;
  //$("#FuelPlanDetails").html(html);
  //$("#FollowingFuelPlanDetails").html(html);
}

function ShowFuelDisplayUnits(lbs) {
  if (LOCALSTORAGE.DisplayUnitsIndex == 3) {
    return Math.round(lbs * 0.453592) + 'kgs';
  } else {
    return lbs + 'lbs';
  }
}
function GetMagDir(bearing, Mdev) {
  var mheading = bearing - Mdev;
  if (mheading >= 360) mheading -= 360;
  if (mheading < 0) mheading += 360;
  mheading = Math.round(mheading);
  return mheading;
}
function secondsToHms(d, short = false) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);
  if (short) {
    if (h < 10) h = '0' + h;
    if (m < 10) m = '0' + m;
    if (h == Infinity) return 'N/A';
    return h + ':' + m;
  } else {
    var hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m == 1 ? ' minute' : ' minutes') : '';
    var sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
    if (h == Infinity) return 'N/A';
    return hDisplay + mDisplay;
  }
}

function LoadFollowDataFuelPlan() {
  if (RoutePlanningON) return;
  var disNewLeg = true;
  PLANAC = null;
  MEASURE.EditExisting = false;
  MEASURE.EditIndex = null;
  for (var f in Follow_Data) {
    if (Follow_Data[f].Itin != null) {
      if (Follow_Data[f].Itin.pilot == chatname || Follow_Data[f].Itin.sic == chatname) {
        $('#NewLegControl').prop('disabled', false);
        $('#StartStopControl').prop('disabled', false);
        disNewLeg = false;
        if (GPS_LOGGER_POINTER == null) $('#NewLegControlSVG').css('background-color', 'ff0000');
        else $('#NewLegControlSVG').css('background-color', '00ff00');
        if (Follow_Data[f].FuelPlan != null) {
          if (Follow_Data[f].WB != null) {
            MEASURE.LEGS = JSON.parse(JSON.stringify(Follow_Data[f].FuelPlan));
            var MeasureIndex = 0;
            SCH_DATA.LegIndex = 0;
            MEASURE.CurLeg = 0;
            SCH_DATA.LEGS = JSON.parse(JSON.stringify(Follow_Data[f].WB));
            for (var w in Follow_Data[f].WB) {
              if (Follow_Data[f].WB[w].Active == 1) MeasureIndex = w;
            }
            if (MEASURE.LEGS[MeasureIndex] != undefined) {
              MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[MeasureIndex]));
              MEASURE.CurLeg = parseInt(MeasureIndex);
              SCH_DATA.LegIndex = parseInt(MeasureIndex);
            } else {
              if (MEASURE.LEGS[0] != undefined) MEASURE.geojson = JSON.parse(JSON.stringify(MEASURE.LEGS[0]));
              else
                MEASURE.geojson = {
                  type: 'FeatureCollection',
                  features: [],
                };
            }
            PLANAC = Follow_Data[f].AC.ident;

            $('#LegOrigin2').autocomplete({
              select: AirportAutoCompleteSelect,
              source: AirportAutoComplete,
            });
            $('#LegDest2').autocomplete({
              select: AirportAutoCompleteSelect,
              source: AirportAutoComplete,
            });
            if (MEASURE.geojson.features != undefined) {
              if (MEASURE.geojson.features.length > 0) {
                try {
                  MEASURE.EditExisting = true;
                  MEASURE.EditIndex = f;
                  FOLLOW_INDEX = f;
                  LoadFPsettings(MEASURE.geojson.features[0].properties.FuelPlan);
                } catch (e) {
                  PLANAC = null;
                  if (!RoutePlanningON) resetMeasureData();
                }
              } else LoadFollowDataACweights(SCH_DATA.LegIndex);
            } else {
              LoadFollowDataACweights(SCH_DATA.LegIndex);
            }
          } else {
            //FuelPlan is Null populate it with blank data
            resetMeasureData();
          }
        } else {
          //FuelPlan is Null populate it with blank data
          resetMeasureData();
        }
      }
    }
  }
  if (disNewLeg) {
    $('#NewLegControl').prop('disabled', true);
    $('#NewLegControlSVG').css('background-color', 'none');
    $('#StartStopControl').prop('disabled', true);
    if (GPS_LOGGER_POINTER != null) END_FLIGHT(); //If there is no itinerary open for the pilot and gps hobbs is still active end it!
  }
}
function LoadFPsettings(F) {
  FuelRemain = F.StartFuel;
  ACFuelBurn = F.BurnRate;
  rpReserveAmount = F.ReserveTime;
  ACcruise = F.AirSpeed;
  ACground = F.GroundSpeed;
  ACalt = F.ACalt;
}
function LoadFollowDataACweights(index) {
  if (RoutePlanningON) return;
  var I = SCH_DATA.LEGS[index];
  if (I == undefined) return;
  facweight = I.facweight;
  fuelwt = I.fuelwt;
  FuelRemain = I.fuelwt;
  ACFuel = I.fuelwt;
  otherwt = I.otherwt;
  CargoWeight: I.CargoWeight;
  cofglat = I.cofglat;
  cofglong = I.cofglong;
  tempconfig = I.tempconfig;
  wbDate = I.wbDate;
  eacweight = I.Eweight;
  tcDesc = I.TCdesc;
  WBSummary = I.Summary;
  PerLegFields = JSON.parse(JSON.stringify(I.Cfields));
  TCNUM = I.tempconfig;
  try {
    LoadFPsettings(MEASURE.LEGS[index].features[0].properties.FuelPlan);
  } catch (e) {
    LoadACconfig();
  }
}
function LoadACconfig(overrideAC = false) {
  if (PLANAC == null && overrideAC == false) return;
  if (overrideAC != false) {
    var PLANAC = overrideAC;
  }
  ACground = ACcruise;
  cirroDB.query('AC', 'ident = ?', PLANAC, function (e) {
    var data = e[0];
    var TC_data = data.TC_data;
    var WB_data = data.WB_Data;
    var LOADABLE_data = data.Loadable_data;
    var AC_data = data.AC_data;
    var ac = AC_data;
    var acIdent = ac.ident;
    ACFuelBurn = AC_data.Consumption;
    ACcruise = AC_data.Cruise;
    $('#FuelPlanAircraft').html('Setup ' + PLANAC);
    if (ACFuelBurn == null || ACFuelBurn == '' || parseInt(ACFuelBurn) == 0) ACFuelBurn = 100;
    if (ACcruise == null || ACcruise == '' || parseInt(ACcruise) == 0) ACcruise = 100;

    //WB_initialize(TCNUM);
    var FuelStandard = ac.FuelType;
    var SN = ac.SN;

    var FUEL_data = WB_data.Fuel;
    for (f in FUEL_data) {
      if (
        FUEL_data[f].Type == FuelStandard &&
        SN >= parseInt(FUEL_data[f].SN_Low) &&
        SN <= parseInt(FUEL_data[f].SN_High)
      )
        ACmaxFuel = parseInt(FUEL_data[f].Capacity);
    }
    for (var i in TC_data) {
      if (TC_data[i].TCNum == TCNUM) {
        //find active temp configuration for initial setup
        for (var t in TC_data[i].Optional) {
          var opt = TC_data[i].Optional[t];
          if (opt.Fuel == 1) {
            for (var f in FUEL_data) {
              if (
                FUEL_data[f].Type == opt.Type &&
                SN >= parseInt(FUEL_data[f].SN_Low) &&
                SN <= parseInt(FUEL_data[f].SN_High)
              ) {
                ACmaxFuel = parseInt(FUEL_data[f].Capacity);
              }
            }
          }
        }
      }
    }
  });
}

function IAS_TAS_Calculator(toTAS, Knts, alt, inHg, temp) {
  var isFeet = true;
  var isInches = true;
  var isCelsius = true;
  var lapserate = 0.0019812; // degrees / foot std. lapse rate C� in to K� result
  var tempcorr = 273.15; // deg Kelvin
  var stdtemp0 = 288.15;

  var altFactor = isFeet ? 1 : 3.28084;
  var setFactor = isInches ? 1 : 0.02953;

  var IA = alt;

  var altstg = inHg;

  if (!isCelsius) temp = (temp + 40) * (5 / 9) - 40;

  var xx = (setFactor * altstg) / 29.92126;
  var PA = IA + 145442.2 * altFactor * (1 - Math.pow(xx, 0.190261));

  var stdtemp = stdtemp0 - PA * lapserate;

  var Tratio = stdtemp / altFactor / lapserate;
  xx = stdtemp / (temp + tempcorr); // for temp in deg C
  var DA = PA + Tratio * altFactor * (1 - Math.pow(xx, 0.234969));

  var aa = DA * lapserate; // Calculate DA temperature
  var bb = stdtemp0 - aa; // Correct DA temp to Kelvin
  var cc = bb / stdtemp0; // Temperature ratio
  var cc1 = 1 / 0.234969; // Used to find .235 root next
  var dd = Math.pow(cc, cc1); // Establishes Density Ratio
  var dd = Math.pow(dd, 0.5); // For TAS, square root of DR
  var ee = 1 / dd; // For TAS; 1 divided by above
  var ff = ee * Knts;
  var gg = Knts / ee;
  if (toTAS) return Math.round(ff);
  else return Math.round(gg);
}

var currentScreenOrientation = window.orientation || 0; // active default
function ArtificialHorizon(enabled) {
  // THIS ALLOWS YOU TO USE THE PHONE TO CONTROL THE CESIUM CAMERA VIEW
  if (enabled) {
    if (LOCALSTORAGE.CageValues != undefined) {
      cageRoll = LOCALSTORAGE.CageValues.cageRoll;
      cagePitch = LOCALSTORAGE.CageValues.cagePitch;
    }
    $('#ArtificialHorizon').show();
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === 'granted') {
            if (window.DeviceOrientationEvent) {
              window.addEventListener('deviceorientation', onDeviceOrientationChanged, false);
            }

            window.addEventListener(
              'orientationchange',
              function () {
                currentScreenOrientation = window.orientation * -1;
              },
              false
            );
            globalID = requestAnimationFrame(CheckGyroCrosshair);
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices

      if (window.DeviceOrientationEvent) {
        window.addEventListener('deviceorientation', onDeviceOrientationChanged, false);
      }

      window.addEventListener(
        'orientationchange',
        function () {
          currentScreenOrientation = window.orientation * -1;
        },
        false
      );
      globalID = requestAnimationFrame(CheckGyroCrosshair);
    }
  } else {
    $('#ArtificialHorizon').hide();
    if (window.DeviceOrientationEvent) {
      window.removeEventListener('deviceorientation', onDeviceOrientationChanged, false);
    }

    window.removeEventListener(
      'orientationchange',
      function () {
        currentScreenOrientation = window.orientation * -1;
      },
      false
    );
    cancelAnimationFrame(globalID);
  }
}
function CheckGyroCrosshair() {
  var canvas = 'mapOverlayCanvas';
  var position = {
    x: Math.round(canvas.clientWidth / 2),
    y: Math.round(canvas.clientHeight / 2),
  };
  globalID = requestAnimationFrame(CheckGyroCrosshair);
}
var degtorad = Math.PI / 180; // Degree-to-Radian conversion

//R.1: Converting deviceorientation angles to a Rotation Matrix representation
function getBaseRotationMatrix(alpha, beta, gamma) {
  var _x = beta ? beta * degtorad : 0; // beta value
  var _y = gamma ? gamma * degtorad : 0; // gamma value
  var _z = alpha ? alpha * degtorad : 0; // alpha value

  var cX = Math.cos(_x);
  var cY = Math.cos(_y);
  var cZ = Math.cos(_z);
  var sX = Math.sin(_x);
  var sY = Math.sin(_y);
  var sZ = Math.sin(_z);

  //
  // ZXY-ordered rotation matrix construction.
  //

  var m11 = cZ * cY - sZ * sX * sY;
  var m12 = -cX * sZ;
  var m13 = cY * sZ * sX + cZ * sY;

  var m21 = cY * sZ + cZ * sX * sY;
  var m22 = cZ * cX;
  var m23 = sZ * sY - cZ * cY * sX;

  var m31 = -cX * sY;
  var m32 = sX;
  var m33 = cX * cY;

  return [m11, m12, m13, m21, m22, m23, m31, m32, m33];
}

//R.2: Fixing our rotation matrix frame relative to the current screen orientation
function getScreenTransformationMatrix(screenOrientation) {
  var orientationAngle = screenOrientation ? screenOrientation * degtorad : 0;

  var cA = Math.cos(orientationAngle);
  var sA = Math.sin(orientationAngle);

  // Construct our screen transformation matrix
  var r_s = [cA, -sA, 0, sA, cA, 0, 0, 0, 1];

  return r_s;
}

//R.3: Fix our rotation matrix frame relative to our application’s world orientation (rotation around x-axis)
function getWorldTransformationMatrix() {
  var x = -90 * degtorad;

  var cA = Math.cos(x);
  var sA = Math.sin(x);

  // Construct our world transformation matrix
  var r_w = [1, 0, 0, 0, cA, -sA, 0, sA, cA];

  return r_w;
}

//R.4: Computing our final rotation matrix representation
function matrixMultiply(a, b) {
  var final = [];

  final[0] = a[0] * b[0] + a[1] * b[3] + a[2] * b[6];
  final[1] = a[0] * b[1] + a[1] * b[4] + a[2] * b[7];
  final[2] = a[0] * b[2] + a[1] * b[5] + a[2] * b[8];

  final[3] = a[3] * b[0] + a[4] * b[3] + a[5] * b[6];
  final[4] = a[3] * b[1] + a[4] * b[4] + a[5] * b[7];
  final[5] = a[3] * b[2] + a[4] * b[5] + a[5] * b[8];

  final[6] = a[6] * b[0] + a[7] * b[3] + a[8] * b[6];
  final[7] = a[6] * b[1] + a[7] * b[4] + a[8] * b[7];
  final[8] = a[6] * b[2] + a[7] * b[5] + a[8] * b[8];

  return final;
}

//Returns a 3 x 3 rotation matrix as an array
function computeMatrix(alpha, beta, gamma, currentScreenOrientation) {
  var rotationMatrix = getBaseRotationMatrix(alpha, beta, gamma); // R

  var screenTransform = getScreenTransformationMatrix(currentScreenOrientation); // r_s

  var screenAdjustedMatrix = matrixMultiply(rotationMatrix, screenTransform); // R_s

  var worldTransform = getWorldTransformationMatrix(); // r_w

  var finalMatrix = matrixMultiply(screenAdjustedMatrix, worldTransform); // R_w

  return finalMatrix; // [ m11, m12, m13, m21, m22, m23, m31, m32, m33 ]
}

function getYawPitchRoll(rotationMatrix) {
  var rm11 = rotationMatrix[0];
  var rm12 = rotationMatrix[1];
  var rm13 = rotationMatrix[2];
  var rm21 = rotationMatrix[3];
  var rm22 = rotationMatrix[4];
  var rm23 = rotationMatrix[5];
  var rm31 = rotationMatrix[6];
  var rm32 = rotationMatrix[7];
  var rm33 = rotationMatrix[8];

  var yaw = Math.atan2(rm21, rm11);
  var pitch = Math.atan2(rm32, rm33);
  var roll = Math.atan2(rm31, Math.sqrt(Math.pow(rm32, 2) + Math.pow(rm33, 2)));

  return [yaw, pitch, roll]; //[yaw, pitch, roll]
}

var cagePitch = 0;
var cageRoll = 0;
function CageHorizon(e) {
  if (!e.checked) return;
  cagePitch = -LastPitch;
  cageRoll = -LastRoll;
  localStorageDB.setItem('CageValues', JSON.stringify({ cagePitch: cagePitch, cageRoll: cageRoll }));
  setTimeout(function () {
    $('#ArtiHorzCage').prop('checked', false).checkboxradio('refresh');
  }, 500);
}
var LastPitch = 0;
var LastRoll = 0;
function onDeviceOrientationChanged(eventData) {
  var beta = eventData.beta;
  var gamma = eventData.gamma;
  var alpha = eventData.alpha;

  var matrix = computeMatrix(alpha, beta, gamma, currentScreenOrientation);

  var YawPitchRoll = getYawPitchRoll(matrix);

  var roll = (YawPitchRoll[2] * 180) / Math.PI;
  LastRoll = roll;
  var pitch = (YawPitchRoll[1] * 180) / Math.PI;
  LastPitch = pitch;
  roll += cageRoll;
  pitch += cagePitch;
  //$("#textstats").html("rotate(" + pitch +"%)");
  var top = -200 + pitch * 2.75;
  var left = -200 - pitch * roll * 0.05;

  document.getElementById('horizonrotate').style.transform = 'rotate(' + roll + 'deg)';
  document.getElementById('horizonelevate').style.transform = 'rotate(' + roll + 'deg)';
  document.getElementById('horizonelevate').style['margin-top'] = top + 'px';
  document.getElementById('horizonelevate').style['margin-left'] = left + 'px';
}
